import React, { Fragment, forwardRef, useContext } from 'react';
import { Pressable, ScrollView, TouchableOpacity, View } from 'react-native';

import { Button, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';

import { ChatPlanningCompletedItemModal } from './ChatPlanningCompletedItemModal';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../../common/style';
import { ConfirmModal } from '../../../../../components/common/modal/ConfirmModal';
import AppUserContext from '../../../../../contexts/AppUserContext';
import {
  AgencyJobPlanning,
  AgencyJobPlanningItem,
  AgencyJobPlanningLeg,
  OrganizationType,
} from '../../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../../hooks/useIsBackendReachable';
import { ChatTrackAndTracingFormModal } from '../ChatTrackAndTracingFormModal';
import { AgencyJobPlanningItemWithOffline } from '../types';

const checkResetDate = (actualDateTimeSetAt: DateTime) => {
  if (Math.abs(actualDateTimeSetAt.diff(DateTime.now()).as('seconds')) < 5 * 60) {
    return true;
  }
  return false;
};

export const ChatPlanningListModal = forwardRef(
  (
    {
      agencyJobId,
      agencyJobPlanning,
      chatTrackAndTracingOfflineFormRef,
      handleCancel,
      handleConfirmForm: handleConfirmFormProp,
      handleResetPress: handleResetPressProp,
      handleSelectListItem,
      handleSelectListItemOffline: handleSelectListItemOfflineProp,
      subtitle,
      title,
    }: {
      agencyJobId?: string;
      agencyJobPlanning: AgencyJobPlanning | null;
      chatTrackAndTracingOfflineFormRef: any;
      handleCancel: () => void;
      handleConfirmForm: (payloadFormData: any) => Promise<boolean>;
      handleResetPress: (
        agencyJobPlanningItem: AgencyJobPlanningItemWithOffline,
      ) => Promise<boolean>;
      handleSelectListItem: (agencyJobPlanningItem: AgencyJobPlanningItemWithOffline) => () => void;
      handleSelectListItemOffline: (
        agencyJobPlanningItem: AgencyJobPlanningItemWithOffline,
      ) => () => void;
      subtitle: string;
      title: string;
    },
    ref,
  ): React.ReactElement => {
    const isBackendReachable = useIsBackendReachable();
    const appUserContext = useContext(AppUserContext);
    const globalStyle = useStyleSheet(globalStyleCommon);
    const styles = useStyleSheet(themedStyles);
    const { t } = useTranslation();

    const isAgency =
      appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY;

    const {
      completedItemModalVisible,
      handleCancelCompletedItem,
      handleCancelForm,
      handleConfirmForm,
      handlePlanningPress,
      handleResetPress,
      handleResetCancel,
      handleResetConfirm,
      handleSelectListItemCompleted,
      handleSelectListItemOffline,
      isAgencyJobPlanningEmptyMessage,
      isLoading,
      selectedAgencyJobPlanningItem,
      selectedResetItem,
      selectedSyncItem,
      visible,
    } = useHook({
      agencyJobId,
      agencyJobPlanning,
      chatTrackAndTracingOfflineFormRef,
      handleCancel,
      handleConfirmForm: handleConfirmFormProp,
      handleResetPress: handleResetPressProp,
      handleSelectListItemOffline: handleSelectListItemOfflineProp,
      ref,
    });

    const renderItem = ({
      item,
      isLegItem = false,
      legIndex,
      legOrder,
    }: {
      index: number;
      item: AgencyJobPlanningItem & { offline?: boolean };
      isLegItem: any;
      legIndex?: number;
      legOrder?: number;
    }) => {
      if (!item.active) {
        return <Fragment key={item.id} />;
      }
      let targetDateTime: any = item.targetDateTime;
      if (typeof targetDateTime === 'string') {
        targetDateTime = DateTime.fromJSDate(new Date(targetDateTime));
      } else if (targetDateTime?.toJSDate) {
        targetDateTime = DateTime.fromJSDate(targetDateTime.toJSDate());
      }

      let actualDateTime: any = item.actualDateTime;
      if (typeof actualDateTime === 'string') {
        actualDateTime = DateTime.fromJSDate(new Date(actualDateTime));
      } else if (actualDateTime?.toJSDate) {
        actualDateTime = DateTime.fromJSDate(actualDateTime.toJSDate());
      }

      const isCompleted =
        !!item.actualDateTime && !!item.location?.formattedAddress && !item.offline;
      let label = '';
      if (isLegItem && typeof legIndex === 'number') {
        label = t('jobs.legWithColon', {
          defaultValue: 'Leg {{order}}: ',
          order: legOrder,
        });
      }
      label += item.label;

      let onSelect;
      if (isCompleted) {
        onSelect = handleSelectListItemCompleted(item as AgencyJobPlanningItemWithOffline);
      } else if (!item.offline) {
        onSelect = handleSelectListItem(item as AgencyJobPlanningItemWithOffline);
      }
      return (
        <Pressable
          key={item.id}
          style={[
            globalStyle.flexRow,
            globalStyle.justifyContentBetween,
            globalStyle.alignItemsCenter,
            globalStyle.padding10,
            globalStyle.paddingVertical15,
            globalStyle.marginBottom2,
            globalStyle.backgroundColorBasic2,
            globalStyle.borderRadius8,
            globalStyle.overflowHidden,
            item.offline && styles.offlineItem,
          ]}
          onPress={onSelect}
        >
          <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.justifyContentStart]}>
            <View style={[globalStyle.flex1, globalStyle.flexColumn, globalStyle.alignItemsStart]}>
              <Text
                style={[
                  globalStyle.fontSize14,
                  globalStyle.fontLatoBold,
                  globalStyle.paddingLeft10,
                ]}
              >
                {label}
              </Text>
              {!!targetDateTime && (
                <View
                  style={[
                    globalStyle.marginLeft5,
                    globalStyle.marginTop5,
                    globalStyle.padding5,
                    globalStyle.paddingHorizontal10,
                    globalStyle.backgroundColorBasic4,
                  ]}
                >
                  <Text style={globalStyle.fontSize13}>
                    {t('common.targetTime', { defaultValue: 'Target time' })}:{' '}
                    {targetDateTime.weekdayShort},{' '}
                    {targetDateTime?.setZone('utc').toFormat('dd MMM yyyy')}{' '}
                    {t('common.at', { defaultValue: 'at' })}{' '}
                    {targetDateTime?.setZone('utc').toFormat('HH:mm')} LT
                  </Text>
                </View>
              )}
              {!!actualDateTime && (
                <View
                  style={[
                    globalStyle.marginLeft5,
                    globalStyle.marginTop5,
                    globalStyle.padding5,
                    globalStyle.paddingHorizontal10,
                    globalStyle.backgroundColorBasic3,
                  ]}
                >
                  <Text style={globalStyle.fontSize13}>
                    {t('common.actualTime', { defaultValue: 'Actual time' })}:{' '}
                    {actualDateTime.weekdayShort},{' '}
                    {actualDateTime?.setZone('utc').toFormat('dd MMM yyyy')}{' '}
                    {t('common.at', { defaultValue: 'at' })}{' '}
                    {actualDateTime?.setZone('utc').toFormat('HH:mm')} LT
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
            {!isAgency &&
              !!item.actualDateTimeSetAt &&
              !item.offline &&
              checkResetDate(item.actualDateTimeSetAt) && (
                <Button
                  appearance="filled"
                  status="warning"
                  size="small"
                  onPress={handleResetPress({ ...item, label } as AgencyJobPlanningItemWithOffline)}
                  style={[globalStyle.marginRight10, globalStyle.width100]}
                >
                  {t('common.reset', { defaultValue: 'Reset' })}
                </Button>
              )}
            {isCompleted && (
              <Icon fill="green" name="checkmark-square-2" style={globalStyle.size30} />
            )}
          </View>
          {!!item.offline && (
            <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
              {!!isBackendReachable && (
                <Button
                  disabled={!isBackendReachable}
                  size="small"
                  status="danger"
                  style={[globalStyle.marginRight10]}
                  onPress={handleSelectListItemOffline(item as AgencyJobPlanningItemWithOffline)}
                >
                  {t('common.sync', { defaultValue: 'Sync' })}
                </Button>
              )}

              <Icon fill="#ff000099" name="alert-triangle-outline" style={globalStyle.size30} />
            </View>
          )}
        </Pressable>
      );
    };

    const renderLegItem = ({ item }: { item: any }) => {
      let departureDate: any = item.departureDate;
      if (typeof departureDate === 'string') {
        departureDate = DateTime.fromJSDate(new Date(departureDate));
      } else if (departureDate?.toJSDate) {
        departureDate = DateTime.fromJSDate(departureDate.toJSDate());
      }
      return (
        <Pressable
          style={[
            globalStyle.flexRow,
            globalStyle.justifyContentBetween,
            globalStyle.alignItemsCenter,
            globalStyle.padding15,
            globalStyle.marginBottom2,
            globalStyle.backgroundColorBasic2,
            globalStyle.borderRadius8,
            globalStyle.backgroundColorBasic4,
          ]}
          onPress={undefined}
        >
          <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
            <Text
              style={[globalStyle.fontSize14, globalStyle.fontLatoBold, globalStyle.paddingLeft10]}
            >
              {item.label}: {}
            </Text>

            {(!!item.flightCarrierDesignation || !!item.flightNumber) && (
              <>
                {!!item.flightCarrierDesignation && (
                  <Text style={globalStyle.fontSize13}>{item.flightCarrierDesignation}</Text>
                )}
                {!!item.flightCarrierDesignation && !!item.flightNumber && <Text> </Text>}
                {!!item.flightNumber && (
                  <Text style={globalStyle.fontSize13}>{item.flightNumber} - </Text>
                )}
              </>
            )}
            {!!departureDate && (
              <Text style={globalStyle.fontSize13}>
                {departureDate?.weekdayShort}, {departureDate?.toFormat('dd MMM yyyy')}
              </Text>
            )}
          </View>
        </Pressable>
      );
    };

    const renderLegList = (agencyJobPlanningLegs: AgencyJobPlanningLeg[]) => (
      <View>
        {agencyJobPlanningLegs?.map((leg: AgencyJobPlanningLeg, legIndex: number) => {
          // DO NOT SHOW IF ANY ITEM INSIDE LEG IS NOT ACTIVE
          if (!leg.active && leg.agencyJobPlanningItems.every((item) => !item.active)) {
            return <Fragment key={leg.id} />;
          }

          let departureDate: any = leg.departureDate;
          if (typeof departureDate === 'string') {
            departureDate = DateTime.fromJSDate(new Date(departureDate));
          } else if (departureDate?.toJSDate) {
            departureDate = DateTime.fromJSDate(departureDate.toJSDate());
          }
          return (
            <View key={leg.id}>
              {renderLegItem({
                item: {
                  checked: true,
                  label: t('jobs.leg', {
                    defaultValue: 'Leg {{order}}',
                    order: leg.order,
                  }),
                  departureDate,
                  flightCarrierDesignation: leg.flightCarrierDesignation,
                  flightNumber: leg.flightNumber,
                  flightTickets: leg.flightTickets,
                  value: `leg-${legIndex}`,
                },
              })}
              {renderContent({
                data: leg.agencyJobPlanningItems,
                isLegItem: true,
                legIndex,
                legOrder: leg.order,
              })}
            </View>
          );
        })}
      </View>
    );

    const renderContent = ({
      data,
      isLegItem,
      legIndex,
      legOrder,
    }: {
      data: any;
      isLegItem?: boolean;
      legIndex?: number;
      legOrder?: number;
    }) => {
      return (
        <View style={globalStyle.width100Percent}>
          {data?.map((item: AgencyJobPlanningItem, index: number) => {
            if (item.name === 'travelAtDropOffDeliveryLocation') {
              return (
                <Fragment key={item.id}>
                  {agencyJobPlanning?.agencyJobPlanningLegs &&
                    renderLegList(agencyJobPlanning.agencyJobPlanningLegs)}

                  <View style={globalStyle.marginBottom20} />
                  {!!item.active && renderItem({ index, item, isLegItem, legIndex, legOrder })}
                </Fragment>
              );
            }
            return renderItem({ index, item, isLegItem, legIndex, legOrder });
          })}
        </View>
      );
    };

    const renderScrollView = () => (
      <ScrollView contentContainerStyle={styles.inner}>
        <View
          style={[globalStyle.width100Percent, globalStyle.flexRow, globalStyle.alignItemsStart]}
        >
          <View style={globalStyle.width50} />
          <View style={globalStyle.flex1}>
            <Text category="h6" style={styles.title}>
              {title}
            </Text>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
          {isAgency ? (
            <Button
              style={styles.planningButton}
              size="tiny"
              appearance="filled"
              onPress={handlePlanningPress}
            >
              {t('jobs.planning', { defaultValue: 'Planning' })}
            </Button>
          ) : (
            <View style={globalStyle.width50} />
          )}
        </View>

        {!isAgencyJobPlanningEmptyMessage &&
          renderContent({ data: agencyJobPlanning?.agencyJobPlanningItems })}
        {isAgencyJobPlanningEmptyMessage && (
          <View style={[globalStyle.padding30, globalStyle.paddingBottom20]}>
            <Text>{isAgencyJobPlanningEmptyMessage}</Text>
          </View>
        )}
      </ScrollView>
    );

    return (
      <Modal
        animationIn="fadeIn"
        animationOut="fadeOut"
        avoidKeyboard
        isVisible={visible}
        backdropOpacity={0.8}
        useNativeDriver
        hideModalContentWhileAnimating
      >
        <Pressable style={globalStyle.absoluteFull} onPress={handleCancel} />
        <View style={styles.wrapper}>
          {renderScrollView()}
          <TouchableOpacity
            style={[
              styles.closeButton,
              isAgency ? styles.closeButtonLeft : styles.closeButtonRight,
            ]}
            onPress={handleCancel}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <Icon
              fill={(globalStyle.colorTextBasicColor as any).color}
              name="close"
              style={globalStyle.size30}
            />
          </TouchableOpacity>
        </View>

        <ChatPlanningCompletedItemModal
          agencyJobPlanningItem={selectedAgencyJobPlanningItem}
          handleCancel={handleCancelCompletedItem}
          visible={completedItemModalVisible}
        />

        <ChatTrackAndTracingFormModal
          ref={chatTrackAndTracingOfflineFormRef}
          handleConfirm={handleConfirmForm}
          handleCancel={handleCancelForm}
          title={selectedSyncItem?.label || ''}
        />

        {selectedResetItem && (
          <ConfirmModal
            confirmButtonStatus="danger"
            confirmButtonAppearance="filled"
            confirmButtonText={t('common.reset', {
              defaultValue: 'Reset',
            })}
            cancelButtonText={t('common.cancel', { defaultValue: 'Cancel' })}
            title={t('common.reset', { defaultValue: 'Reset' })}
            text={t('common.areYouSureYouWantToReset', {
              defaultValue: 'Are you sure you want to reset "{{label}}"?',
              label: selectedResetItem?.label,
            })}
            loading={isLoading}
            handleCancel={handleResetCancel}
            handleConfirm={handleResetConfirm}
            visible={!!selectedResetItem}
          />
        )}
      </Modal>
    );
  },
);
