export default {
  translation: {
    activation: {
      errorMessage: 'Aktivierungsfehler',
      activateAccount: 'Account aktivieren',
      activationSuccess: 'Aktivierung erfolgreich',
      activationSuccessDescription: 'Account wurde erfolgreich aktiviert',
      pleaseWait: 'Bitte warten',
      errorRetryMessage: 'Fehler bei der Aktivierung. Bitte erneut versuchen',
      enterActivationCode: '8-stelligen Aktivierungscode eingeben',
      noAgencyNameGiven: 'Kein Agentur-Name angegeben',
      activationCode: 'Aktivierungscode',
      verifyActivationCode: 'Aktivierungscode verifizieren',
      connectWithAgency: 'Mit der Agentur verbinden',
      connectionSuccessful: 'Verbindung erfolgreich',
      verifyingActivationCode: 'Aktivierungscode verifizieren',
      connectingWithAgency: 'Verbindung mit Agentur herstellen...',
      enterAValidActivationCode: 'Geben Sie einen gültigen Aktivierungscode ein',
      invalidActivationCode: 'Ungültiger Aktivierungscode',
      addAgencyIntroText:
        'Sie benötigen eine Einladung, um sich mit einer Agentur zu verbinden. Wenden Sie sich bitte an Ihre Agenturen, um einen Aktivierungscode zu erhalten. Geben Sie den Aktivierungscode, den Sie per SMS erhalten haben, unten ein.',
      connectWithAgencyExplanation:
        'Drücken Sie unten auf die Schaltfläche <strong>Verbinden mit Agentur</strong>, um Ihr Kurierunternehmen mit der oben angezeigten Agentur zu verbinden. Nach erfolgreicher Verbindung hat die Agentur Zugriff auf Ihre Profildaten und Dokumente, damit sie Ihnen OBC-Jobs anbieten kann, die zu Ihrem Profil passen.',
      errorDescription:
        'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut oder kontaktieren Sie support@priojet.com',
    },
    activityNotification: {
      newActivity: 'Neue Aktivität',
      recentActivity: 'Jüngste Aktivität ({{displayed}}/{{total}})',
      noRecentActivity: 'Jüngste Aktivität',
      markAllAsRead: 'Alles als gelesen markieren',
      noRecentActivityInTheLastNDays: 'Keine aktuelle Aktivität in den letzten 14 Tagen',
      seeAllActivity: 'Alle Aktivitäten ansehen',
      activityAndNotifications: 'Aktivitäten & Benachrichtigungen',
      noNewNotifications:
        'Es gibt derzeit keine Systembenachrichtigungen oder Aktivitäten, die weniger als 14 Tage alt oder ungelesen sind.',
    },
    agencyCourierOpenInvitations: {
      courierInvitations: 'Kurier-Einladungen',
      orgInvitationDescription_canBeUsedToJoin: 'Kann verwendet werden, um zu verbinden',
      orgInvitationDescription_withRole: 'mit Rolle',
      orgInvitationDescription_canBeUsedToConnect: 'Kann verwendet werden, um zu verbinden',
      contactDetails: 'Kontaktdetails',
      description: 'Beschreibung',
      orgInvitationDescription_activationCodeCanBeUsedToActivateANew:
        'Aktivierungscode kann verwendet werden, um eine neue Einladung zu bestätigen',
      orgInvitationDescription_onThePlatformAndConnectToIt:
        'ist auf der Plattform, jetzt verbinden.',
      orgInvitationDescription_theActivationCodeWasSentToAPhoneBook:
        'Der Aktivierungscode wurde an einen Telefonbuchkontakt gesendet. \nDieser kann verwendet werden, um eine Organisation zu aktivieren',
      other: 'andere...',
      contactPriojetToSendCode:
        '*** kontaktiere PRIOjet, um den Code erneut an den Kurier zu senden.',
      orgInvitationDescription_noOrganizationInvitationsFound:
        'Keine Organisationseinladungen gefunden.',
    },
    agencyPhoneBookContactsList: {
      invalidDataTitle: 'Ungültige Daten',
      invalidDataDescription:
        'Es müssen sowohl gültige internationale Telefonnummern mit Ländervorwahl (z.B. +49) als auch E-Mail-Adressen für jeden Kontakt vorhanden sein.',
      success: 'Erfolgreich',
      error: 'Fehler',
      onSelectPhoneOnlyMessage:
        'Möchten Sie den Kontakt {{ item.name }} mit der Telefonnummer {{phoneNumber}}',
      onSelectWithEmailMessage: ' und E-Mail {{email}}?',
      Import: 'Importieren',
      invalidPhoneNumberFormatTitle: 'Ungültiges Telefonnummernformat',
      invalidPhoneNumberFormatDescription:
        'Einige Telefonnummern des Kontaktes {{item.name}} haben nicht das richtige Format. Bitte fügen Sie die Landesvorwahl zur Telefonnummer hinzu.\n------------\n{{number}}\n------------\nDie folgende deutsche Nummer 01234567890 würde zu +491234567890 werden (Entfernen Sie die 0 am Beginn und fügen Sie + plus Landesvorwahl hinzu).\n\nBitte gehen Sie in Ihr Telefonbuch oder Ihre Kontakt-App und ändern Sie die Telefonnummern dort.  ',
      filterBoxPlaceholder: 'Filter nach Name, E-Mail, Nummer, Adresse, Firma oder Job',
      noContactsWith: 'Keine Kontakte mit',
      foundOnThisDevice: 'auf diesem Gerät gefunden',
      'Import ': 'Import',
    },
    agencyProfile: {
      error: 'Fehler',
      someFieldAreNotValid: 'Einige Eingaben sind nicht gültig',
      headquartersAddressIsNotValid: 'Die Adresse des Hauptsitzes ist nicht gültig',
      invoicingAddressIsNotValid: 'Die Rechnungsadresse ist nicht gültig',
      success: 'Erfolgreich',
      profileUpdated: 'Agenturprofil erfolgreich aktualisiert',
      somethingWentWrong: 'Etwas ging schief',
      requiredField: 'notwendiges Feld',
      noCameraPermissions:
        'Tut uns leid, wir benötigen die Berechtigungen zum Zugriff auf die Kamera, damit dies funktioniert!',
      errorUploadingLogoImage: 'Beim Hochladen des Logobildes ist ein Fehler aufgetreten',
      errorDeletingLogoImage: 'Beim Löschen des Logobildes ist etwas schief gelaufen',
      agencyName: 'Agentur-Name',
      agencyNamePlaceholder: 'Der Agentur-Name',
      agencyShortName: 'Agentur-Kurzname',
      agencyShortNamePlaceholder: 'Geben Sie die Kurzbezeichnung der Agentur ein',
      primaryEmailAddress: 'Primäre E-Mail Adresse der Agentur',
      primaryEmailPlaceholder: 'Geben Sie die primäre E-Mail-Adresse der Agentur ein',
      primaryPhoneNumber: 'Primäre Telefonnummer der Agentur',
      primaryPhonePlaceholder: 'Geben Sie die primäre Telefonnummer für die Agentur ein',
      agencyLegalName: 'kompletter rechtlicher Name der Agentur',
      agencyLegalNamePlaceholder: 'Geben Sie den kompletten rechtlichen Namen der Agentur ein',
      headquartersAgencyAddress: 'Adresse des Hauptsitzes der Agentur',
      addressName: 'Adresse-Name',
      headquartersAddressPlaceholder:
        'Suchen Sie hier nach der Adresse des Hauptsitzes der Agentur',
      headquartersAddress: 'Adresse des Hauptsitzes',
      companyRegistrationNumber: 'Registrierungsnummer des Unternehmens',
      companyRegistrationNumberPlaceholder:
        'Unternehmensregistrierungsnummer eingeben, falls vorhanden',
      vatId: 'Steuerregistrierungsnummer (Umsatzsteuer-ID)',
      vatIdPlaceholder: 'Steuerregistrierungsnummer (USt-IdNr.) eingeben, falls vorhanden',
      officialPostalInvoiceAddress: 'Offizielle Rechnungsadresse',
      officialPostalInvoiceAddressPlaceholder:
        'Suchen Sie hier nach der offiziellen Postanschrift für die Rechnungsadresse der Agentur',
      invoiceEmail: 'Rechnungs-E-Mail-Adresse',
      invoiceEmailPlaceholder: 'Rechnungs-E-Mail-Adresse eingeben',
      descriptionPlaceholder:
        'Geben Sie einige Informationen über Ihre Agentur an (Gründungsjahr, Anzahl der Kuriere, durchgeführte Aufträge, ...)',
      myAgencyProfile: 'Mein Agenturprofil',
      generalInformation: 'Allgemeine Informationen',
      legalInformation: 'Rechtliche Informationen',
      invoiceInformation: 'Rechnungsinformationen',
      socialMedia: 'Social Media',
      aboutYourAgency: 'Über Ihre Agentur',
    },
    appUpdate: {
      Titel: 'Es ist Zeit für die Aktualisierung',
      textMandatory:
        'Bitte aktualisieren Sie die App. Die aktuell installierte Version wird nicht mehr unterstützt.',
      text: 'Wir haben viele neue Funktionen hinzugefügt und Unklarheiten behoben, um Ihre Nutzung von PRIOjet so reibungslos wie möglich zu gestalten.',
      update: 'Update',
      notNow: 'Nicht jetzt',
      title: 'Es ist Zeit für die Aktualisierung',
    },
    common: {
      badInput: 'ungenügende Eingabe',
      Copied: 'Kopiert',
      'Email:': 'Email:',
      firstNames: 'Vornamen',
      lastName: 'Nachname',
      email: 'E-Mail',
      phoneNumber: 'Telefonnummer',
      gender: 'Geschlecht',
      dateOfBirth: 'Geburtsdatum',
      placeOfBirth: 'Geburtsort',
      countryOfBirth: 'Geburtsland',
      nationality: 'Staatsangehörigkeit',
      taxCountry: 'Steuerland',
      languagesKnown: 'Bekannte Sprachen',
      address: 'Adresse',
      emergencyContact: 'Notfall-Kontakt',
      noNameProvided: 'Kein Name angegeben',
      CARDS: 'Karten',
      creditCard: 'Kreditkarte',
      debitCard: 'Debitkarte',
      creditLimit: 'Kreditkartenlimit:',
      notSpecified: 'Nicht angegeben',
      socialMedia: 'Soziale Medien',
      continue: 'Weiter',
      cancel: 'Abbrechen',
      startAirport: 'Startflughafen',
      No: 'Nein',
      Yes: 'Ja',
      importAndInviteCourierContact: 'Importiere und lade \ncourier-Kontakt',
      alreadyImportedAndInvited: 'Bereits importiert und eingeladen',
      noInvitationWasUsed: 'Es wurde keine bisher Einladung gesendet',
      courierJobUpdated: 'Kurierauftrag aktualisiert',
      changeToRelationshipType: 'Wechsel zu {{relationshipType}}',
      dailyRate: 'Tagessatz',
      fixedRate: 'Fester Satz',
      kickOffFee: 'Kickoff Zahlung',
      hotelBudget: 'Hotelbudget',
      new: 'NEU',
      running: 'LAUFEND',
      finished: 'BEENDET',
      cancelled: 'ABGESAGT',
      createJob: 'Job erstellen',
      seeAllJobs: 'Alle Jobs anzeigen',
      currentlyLoggedInAs: 'Derzeit eingeloggt als',
      yourAccounts: 'Ihre Accounts',
      deleteDocument: 'Dokument löschen',
      deleteDocumentMessage: 'Möchten Sie dieses Dokument löschen?',
      'Copied!': 'Kopiert',
      copiedTextMessage: 'Kopiert {{copyableTextName}} in die Zwischenablage',
      emailCopySuccessMessageDescription: 'Kopierte E-Mail {{email}} in die Zwischenablage.',
      noEmailProvided: 'Keine E-Mail angegeben',
      Copy: 'Kopieren',
      sendEmail: 'E-Mail',
      typeHere: 'Klicken Sie hier',
      success: 'Erfolgreich',
      error: 'Fehler',
      successfullySaved: 'erfolgreich gespeichert',
      somethingWentWrongSendingMessage:
        'Beim Senden der Nachricht ist etwas schief gelaufen. Sie können versuchen, Ihre Nachricht erneut zu senden.',
      addImage: 'Bild hinzufügen',
      addPdfDocument: 'PDF-Dokument hinzufügen',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      messageAlreadyRepliedCannotBeEdited:
        'Eswurde bereits auf Ihre Nachricht geantwortet. Frühere Nachrichten können dann nicht mehr bearbeitet oder gelöscht werden.',
      chatDisabledBackendNotReachable:
        'Versenden von Nachrichten ohne Netzwerkverbindung deaktiviert...',
      addressCopySuccessMessageDescription:
        'Adresse {{formattedAddress}} in die Zwischenablage kopiert.',
      noAddressProvided: 'Keine Adresse angegeben',
      noAddressFound: 'Keine Adresse gefunden',
      phoneNumberCopySuccessMessageDescription:
        'Telefonnummer {{phoneNumber}} in die Zwischenablage kopieren.',
      signUpNotSuccessful: 'Die Anmeldung war nicht erfolgreich.',
      signUpNotSuccessfulDescription:
        'Anmeldung war nicht erfolgreich. Bitte versuchen Sie es erneut oder wenden Sie sich an den PRIOjet-Support support@priojet.com.',
      signUpAsAgency: 'Registrieren Sie sich als Agentur',
      signUpAsCourier: 'Registrieren Sie sich als Kurier',
      signUp: 'Registrierung',
      signUpCancelledVerificationNeeded:
        'Anmeldung erfolgreich, zusätzliche Überprüfung erforderlich',
      signUpCancelledPhoneNumberDescription:
        'Ihr Accounts wurde erstellt, ohne dass Ihre Telefonnummer verifiziert wurde. Um Ihr Accounts zu nutzen, klicken Sie auf die Schaltfläche Anmelden weiter unten',
      signUpCancelled: 'Anmeldung abgebrochen',
      signUpCancelledMessageDescription:
        "Anmeldung abgebrochen. Um es erneut zu versuchen, klicken Sie auf die Schaltfläche 'Anmelden' weiter unten. Falls sich kein Popup öffnet, starten Sie die App bitte erneut.",
      flashMessageProblemDuringSignUp:
        'Unsere Server sind derzeit nicht erreichbar. Bitte überprüfen Sie Ihre Internetverbindung. Wir können mit der Anmeldung nicht fortfahren.',
      problemOccurredDuringSignUpNotSuccessful:
        'Während der Anmeldung ist ein Problem aufgetreten. Anmeldung nicht erfolgreich.',
      signUpSuccessful: 'Anmeldung erfolgreich',
      flashMessageSignUpSuccessful:
        'Wir haben Ihr Accounts erfolgreich erstellt. Sie müssen es erst aktivieren, bevor Sie es benutzen können.',
      tryAgain: 'Versuchen Sie es bitte erneut',
      days: 'Tage',
      hours: 'Stunden',
      minutes: 'Minuten',
      in: 'in',
      availabilityTZ: 'Verfügbarkeit TZ',
      yourTZ: 'Ihre TZ',
      Profile: 'Profil',
      startTime: 'Startzeit',
      endTime: 'Endzeit',
      createdAt: 'Erstellt am',
      yourLocalTime: 'Ihre Ortszeit:',
      utcGmtTime: 'UTC / GMT Zeit:',
      decimalSeparator: ',',
      digitGroupSeparator: '.',
      selectHour: 'Stunde auswählen',
      selectMinute: 'Minute auswählen',
      loadingSpinnerTextWithSubject: 'Loading {{subject}}...',
      loadingSpinnerText: 'Loading...',
      organizationInvitationVoided: 'Organisationseinladung storniert',
      English: 'Englisch',
      German: 'Deutsch',
      Spanish: 'Spanisch',
      French: 'Französisch',
      statusBarReconnecting: 'STATUS_BAR_RECONNECTING',
      statusBarApiNotReachable: 'API {{apiEndpoint}} ist nicht erreichbar ...',
      noAgencyConnectionsForFilterTerm:
        'Keine verbundenen Agenturen für den Filterbegriff gefunden.',
      nameOfTheDocument: 'NAME DES DOKUMENTS',
      issuingInstitution: 'AUSSTELLENDE INSTITUTION',
      dateOfIssue: 'DATUM DER AUSSTELLUNG',
      dateOfExpiry: 'DATUM DES ABLAUFS',
      countryOfIssue: 'LAND DER AUSSTELLUNG',
      documentDescription: 'BESCHREIBUNG DES DOKUMENTS',
      placeOfIssue: 'ORT DER AUSSTELLUNG',
      frequentFlyerNumber: 'VIELFLIEGER-NUMMER',
      airline: 'AIRLINE',
      airlineStatus: 'STATUS DER FLUGGESELLSCHAFT',
      airlineAllianceType: 'AIRLINE ALLIANCE',
      airlineAllianceStatusType: 'AIRLINE ALLIANCE STATUS',
      diseaseOrPathogen: 'KRANKHEIT ODER ERREGER',
      testType: 'TEST TYP',
      testDetails: 'TEST DETAILS',
      dateOfTest: 'DATUM DES TESTS',
      testCenter: 'TESTZENTRUM',
      cityOfTest: 'STADT DES TESTS',
      countryOfTest: 'LAND DES TESTS',
      resultIsPositive: 'ERGEBNIS IST POSITIV',
      documentType: 'DOKUMENTTYP',
      documentNumber: 'DOKUMENTENNUMMER',
      documentFeature: 'DOKUMENT-MERKMAL',
      sex: 'Geschlecht',
      chooseOption: 'Option wählen',
      associatedIdentityDocument: 'ZUGEHÖRIGES IDENTITÄTSDOKUMENT',
      vaccineManufacturer: 'Hersteller des Impfstoffs',
      vaccinationType: 'Art der Impfung',
      vaccinationDescription: 'Beschreibung der Impfung',
      dateOfVaccination: 'DATUM DER IMPFUNG',
      vaccinationCenter: 'IMPFZENTRUM',
      cityOfVaccination: 'STADT DER IMPFUNG',
      countryOfVaccination: 'LAND DER IMPFUNG',
      vaccinationCompleted: 'IMPFUNG ABGESCHLOSSEN',
      loading: 'Laden',
      setUnavailable: 'als nicht-verfügbar setzen',
      setAvailable: 'als verfügbar setzen',
      setAvailableLocationDisabled: 'als Verfügbar setzen (Standort deaktiviert)',
      available: 'verfügbar',
      unavailable: 'nicht verfügbar',
      setAvailabilityDates: 'Verfügbarkeitsdaten festlegen',
      pickupLocation: 'Abholort',
      pickupTime: 'Abholzeit',
      dropoffLocation: 'Absetzort',
      returnTimeAtStartAirport: 'Rückkehrzeit am Startflughafen',
      jobUpdated: 'Auftrag aktualisiert',
      documentDetails: 'DOKUMENTENDETAILS',
      licenceClass: 'LIZENZKLASSE',
      airlineType: 'FLUGGESELLSCHAFT',
      diseasePathogen: 'KRANKHEIT/ERREGER',
      documentName: 'DOKUMENTENNAME',
      testResult: 'TESTERGEBNIS',
      typeOfVaccination: 'ART DER IMPFUNG',
      validUntil: 'GÜLTIG BIS',
      notificationSettings: 'Benachrichtigungseinstellungen',
      notificationsEnabled:
        'Benachrichtigungen sind auf diesem Gerät aktiviert. Um Benachrichtigungen zu deaktivieren, gehen Sie zu den Einstellungen.',
      notificationsDisabled:
        'Benachrichtigungen sind auf diesem Gerät deaktiviert. Um Benachrichtigungen zu aktivieren, gehen Sie zu den Einstellungen',
      flashMessageProblemDuringSignIn:
        'Unsere Server sind derzeit nicht erreichbar. Bitte überprüfen Sie Ihre Internetverbindung. Sie können sich derzeit nicht anmelden.',
      signIn: 'Login',
      signInInfoText:
        'Es wird ein Fenster geöffnet, in dem Sie Ihre Anmeldedaten eingeben müssen, um sich anzumelden.',
      signUpAsAnAgency: 'Registrierung als Agentur',
      signUpAsACourier: 'Registrierung als Kurier',
      selectLanguageLabel: 'Hier können Sie die Sprache der App ändern.',
      selectLanguageCaption: 'Die Standardsprache basiert auf Ihren Systemeinstellungen.',
      changeLanguage: 'Sprache ändern',
      toggleTheme: 'Design umschalten',
      activeTheme: 'Aktives Design',
      removeUserFromAccountList: 'Benutzer aus der Nutzerliste entfernen',
      flashMessageProblemSignInUserContext:
        'Wir konnten Ihr Benutzer-Account nicht mit der E-Mail {{userEmail}} anmelden',
      downloadNotPossibleWithoutPermission:
        'Der Download ist derzeit nicht möglich. Um ihn zu ermöglichen, aktivieren Sie die Berechtigung in den Geräte- und App-Einstellungen.',
      somethingWentWrongWithFileDownload: 'Beim Dateidownload ist etwas schief gelaufen.',
      screenNameIsComingSoon: '{{screenName}} kommt bald',
      activateAccount: 'Accounts aktivieren',
      login: 'Anmelden',
      home: 'Home',
      availabilities: 'Verfügbarkeiten',
      welcome: 'Willkommen',
      setupDevice: 'Gerät einrichten',
      couriersForJobSuccessfullyChanged: 'Kuriere für diesen Job erfolgreich geändert',
      changedCouriersForAgencyJobDescription:
        'Die für diesen Auftrag ausgewählten Kuriere wurden erfolgreich geändert.',
      courierAvailabilities: 'Kurierverfügbarkeiten',
      courierName: 'Kurier-Name',
      manageCouriersPriojetJobIdentifier: 'Kuriere verwalten ({{priojetJobIdentifier}})',
      courierProfile: 'Kurier-Profil',
      save: 'Speichern',
      newJob: 'Neuer Job',
      jobs: 'Jobs',
      chooseFromGallery: 'Aus der Galerie wählen',
      takePicture: 'Ein Foto aufnehmen',
      doYouWantToReload: 'Möchten Sie das Profil neu laden?',
      unsavedDataWillBeLost: 'Nicht gespeicherte Daten werden verloren gehen',
      refresh: 'Aktualisieren',
      documentSuccessfullyDownloaded: 'Dokument erfolgreich heruntergeladen',
      timeOfTest: 'ZEITPUNKT DER PRÜFUNG',
      upcoming: 'Demnächst',
      addressName: 'Adressenname',
      street: 'Straße',
      houseNumber: 'Hausnummer',
      postalCode: 'Postleitzahl',
      city: 'Stadt',
      state: 'Staat',
      country: 'Land',
      agencyProfile: 'Agenturprofil',
      Length: 'Länge',
      filterAgencyByTerms: 'Agentur nach Name, Stadt oder Land filtern',
      viewJob: 'JOB ANSEHEN',
      couldNotCopyPhoneNumberNoPhoneNumberGiven:
        'Die Telefonnummer konnte nicht kopiert werden. Keine Telefonnummer angegeben.',
      cannotOpenPhoneApp: 'Die Telefon-App kann nicht geöffnet werden, um einen Anruf zu tätigen.',
      noPhoneNumberProvided: 'Keine Telefonnummer angegeben',
      pickupTimeText: 'Abhol-/Übernahmezeit <TimeAgoPopover></TimeAgoPopover>',
      returnTimeText: 'Rückkehrzeit  <TimeAgoPopover></TimeAgoPopover>',
      connectedAtByUserTextTimeAgo: 'verbunden <TimeAgoPopover></TimeAgoPopover>',
      formattedDuration: '<0>Duration</0>: {{duration}}.',
      formattedSince: '<0>Since</0> {{duration}}.',
      destinationLocation: 'Zielort',
      createdAtByUserText:
        'erstellt <TimeAgoPopover></TimeAgoPopover> by {{firstNames}} {{lastName}} ({{email}})',
      endedTimeAgo: 'Beended {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      startedTimeAgo: 'Beginn {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeframesTimeAgoTextStartToEnd:
        'gestartet <TimeAgoPopoverStart></TimeAgoPopoverStart>. Beendet <TimeAgoPopoverEnd></TimeAgoPopoverEnd>.',
      timeframesTimeAgoTextStartOpenEnd: '<TimeAgoPopoverStart></TimeAgoPopoverStart>',
      updatedAtText: 'Zuletzt geändert <TimeAgoPopover></TimeAgoPopover>.',
      timeAgoPopOverTextStartFuture: 'Beginnt {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextStartPast: 'Gestartet {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextCreatedFuture:
        'Ungültiger Zeitstempel. Kann nur in der Vergangenheit liegen.',
      timeAgoPopOverTextCreatedPast: 'Erstellt {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextEndFuture: 'Endet {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextEndPast: 'Endet {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      accountDeleted: 'Accounts gelöscht',
      accountDeletedExplanation:
        'Das Benutzer-Account ist gelöscht und kann nicht mehr verwendet werden. Wir entschuldigen uns für etwaige Unannehmlichkeiten.',
      somethingWentWrong:
        'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut oder kontaktieren Sie support@priojet.com',
      agencies: 'Agenturen',
      organizationDetails: 'Organisationsdetails',
      responsibleUser: 'Verantwortlicher Benutzer',
      restore: 'Wiederherstellen',
      deleted: 'Gelöscht ',
      couriers: 'Kuriere',
      chatDisabledCourierDeleted:
        'Neue Nachrichten können nicht erstellt werden, da der verbundene Kurier sein Accounts gelöscht hat.',
      chatDisabledAgencyDeleted:
        'Neue Nachrichten können nicht erstellt werden, da die verbundene Agentur ihr Accounts gelöscht hat.',
      validForCountry: 'GÜLTIG FÜR LAND',
      FEMALE: 'Female',
      MALE: 'Male',
      OTHER: 'Other',
      timeAgoPopOverTextEndedPast: 'Beendet {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
    },
    connectedAgencies: {
      noConnectedAgencies:
        'Du hast bisher keine verbundenen Agenturen. Du kannst dich mit einer Agentur verknüpfen indem du von einer Agentur eingeladen wirst.',
    },
    connectedCouriers: {
      noConnectedCouriersFound:
        "Es wurden keine verbundenen Kuriere für den Filterbegriff '{{filterTerm}}' gefunden.",
      filterPlaceholder: 'Kurier nach Name, E-Mail oder Telefon filtern',
      screenTitle: 'Verbundene Kuriere',
    },
    courierAgencyProfile: {
      agencyName: 'Agentur-Name',
      agencyShortName: 'Agentur-Kurzname',
      primaryEmailAddress: 'Primäre E-Mail Adresse der Agentur',
      emailNotProvided: 'E-Mail nicht angegeben',
      primaryPhoneNumber: 'Telefonnr. der primären Agentur',
      phoneNotProvided: 'Telefonnr. nicht angegeben',
      agencyLegalName: 'Rechtlicher Name der Agentur',
      headquartersAgencyAddress: 'Adresse des Hauptsitzes der Agentur',
      companyRegistrationNumber: 'Registrierungsnummer des Unternehmens',
      vatId: 'Steuerregistrierungsnummer (Umsatzsteuer-ID)',
      officialPostalInvoiceAddress: 'Offizielle Rechnungsanschrift',
      invoiceEmail: 'Rechnungs-E-Mail-Adresse',
      description: 'Beschreibung',
      generalInformation: 'Allgemeine Informationen',
      legalInformation: 'Rechtliche Informationen',
      invoicingDetails: 'Details zur Rechnungsstellung',
      socialMedia: 'Social Media',
    },
    courierAvailability: {
      availableFromStartDayOnly:
        'Starttag: <strong>{{startDateString}}</strong>. \nWählen Sie oben ein Datum.',
      availableFromToDate:
        'Von <strong>{{startDateString}}</strong> bis <strong>{{endDateString}}</strong>',
      locationLocalTimeInfo:
        'Bitte beachten Sie, dass Sie die lokale Zeit an Ihrem ausgewählten Standort <strong>{{locationName}}</strong> angeben müssen. Die Zeitzonendifferenz zu Ihrer lokalen Zeitzone <strong>{{currentTimeZoneName}} </strong> beträgt <strong>{{timeZoneOffsetInHours}}</strong> Stunden ({{locationTimeZoneName}}). Daher ist {{timeZoneEqualToLaterThanOrEarlierThan}} Ihre aktuelle Ortszeit. \n\nBeispiel: {{localTimeExample}} hier ist dort {{locationTimeExampleDateTime}}. Sie müssten also unten auch {{locationTimeExampleTime}} setzen.',
      newAvailabilityCreated: 'Neue Verfügbarkeit für {{locationName}} erstellt.',
      selectStartAndEndDateAbove: 'Wählen Sie oben das Start- und Enddatum aus.',
      createAvailability: 'Verfügbarkeit erstellen',
      '1ChooseAvailabilityLocation':
        'An welchem Standort sind Sie verfügbar? Suchen Sie den Namen des Standortes unten und wählen Sie ihn entsprechend aus.',
      locationSearchPlaceholderText: 'Geben Sie den Ort ein, an dem Sie verfügbar sind',
      currentLocation: 'Aktueller Standort',
      '2SelectDateRange':
        'wann sind Sie verfügbar? Wählen Sie im unten stehenden Kalender die Tage aus, an denen Sie verfügbar sind.',
      '3ChooseAvailabilityTime':
        'In welchem Zeitrahmen sind Sie verfügbar?\n Wählen Sie die Anfangs- und Endzeit für Ihre Verfügbarkeit.',
      isEqualTo: 'ist gleich Ihrem',
      ahead: 'voraus',
      behind: 'hinterher',
      appearsEqualTo: 'scheint gleich zu sein',
      appearsLaterThan: 'erscheint bereits später als',
      appearsEarlierThan: 'erscheint noch früher als',
      pleaseSelectStartTimeForPlaceOfAvailability:
        'Bitte wählen Sie die Startzeit, ab der Sie am {{startDateString}} in {{locationName}} verfügbar sind.',
      pleaseSelectStartTimeForAvailabilityLocationOnly:
        'Bitte wählen Sie die Startzeit aus, ab der Sie in {{locationName}} verfügbar sind.',
      pleaseSelectStartTimeForAvailabilityDateTimeOnly:
        'Bitte wählen Sie die Startzeit aus, ab der Sie am {{startDateString}} verfügbar sind.',
      pleaseSelectStartTime: 'Bitte wählen Sie eine Startzeit aus, ab der Sie verfügbar sind.',
      pleaseSelectEndTimeForPlaceOfAvailability:
        'Bitte wählen Sie die Endzeit, bis zu der Sie am {{endDateString}} in {{locationName}} verfügbar sind.',
      pleaseSelectEndTimeForAvailabilityLocationOnly:
        'Bitte wählen Sie die Endzeit, bis zu der Sie in {{locationName}} verfügbar sind.',
      pleaseSelectEndTimeForAvailabilityDateTimeOnly:
        'Bitte wählen Sie die Endzeit, bis zu der Sie am {{endDateString}} verfügbar sind.',
      pleaseSelectEndTime: 'Bitte wählen Sie die Endzeit, bis zu der Sie verfügbar sind.',
      couldNotSaveAvailabilityErrorMessage:
        'Diese Verfügbarkeit konnte nicht gespeichert werden. Bitte beheben Sie die folgenden Fehler:',
      saveAndScheduleAvailability: 'Verfügbarkeit speichern und anwenden',
      availabilityCreated: 'Availability created',
    },
    courierInvitations: {
      deviceHasContactsMessageTop:
        'Dieses Gerät hat ein Telefonbuch mit Kontakten, die Sie einladen können.',
      allowAccessToContactsMessage:
        'Anscheinend erlauben Sie den Zugriff auf Ihre Kontakte in den Einstellungen nicht. Um Ihre Kontakte hier zu laden, klicken Sie bitte auf Einstellungen öffnen und erlauben Sie den Zugriff auf Ihre Kontakte. Keine Sorge, nur die Kontakte, die Sie aktiv einladen, durch Drücken der Schaltfläche "Einladen" nach dem Laden der Kontakte, werden an unser System übermittelt.',
      openSettingsButton: 'Einstellungen öffnen',
      displayLoadContactsMessage:
        'Um Ihre Kontakte hier anzuzeigen, klicken Sie bitte auf Kontakte laden und erlauben Sie den Zugriff auf Ihre Kontakte. Keine Sorge, nur die Kontakte, die Sie aktiv einladen, indem Sie nach dem Laden der Kontakte auf die Schaltfläche "Einladen" klicken, werden an das System übermittelt.',
      errorOccurredMessage:
        'Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den PRIOjet-Support.',
      cannotAccessContactsMessage:
        'Wir können auf diesem Gerät nicht auf Kontakte zugreifen. Bitte verwenden Sie ein Mobiltelefon.',
    },
    courierUserProfile: {
      companyName: 'Firmenname',
      invalidEmail: 'Es muss eine gültige E-Mail sein',
      max9Characters: 'Maximal 9 Zeichen',
      error: 'Fehler',
      errorUploadingLogoImage: 'Etwas ist beim Hochladen des Logobildes schief gelaufen',
      errorDeletingProfilePicture: 'Beim Löschen des Profilbildes ist etwas schief gelaufen',
      someFieldAreNotValid: 'Einige Felder sind ungültig',
      success: 'Erfolg',
      profileUpdated: 'Kurierprofil erfolgreich aktualisiert',
      somethingWentWrong: 'Etwas ist schief gelaufen',
      emailAlreadyInUse: 'E-Mail wird bereits verwendet',
      requiredField: 'Muss gesetzt werden',
      invalidUrl: 'Es muss eine gültige URL sein',
      numericalValue: 'Bitte einen numerischen Wert setzen',
      noCameraPermissions:
        'Entschuldigung, wir benötigen die Berechtigung zum Kamerazugriff, damit das funktioniert!',
      wantToReloadPart1: 'Möchten Sie das Profil neu laden?',
      wantToReloadPart2: 'Nicht gespeicherte Daten gehen verloren',
      cancel: 'stornieren',
      refresh: 'Aktualisieren',
      language: 'Sprache',
      level: 'Ebene',
      currency: 'Währung',
      maximumCreditLimit: 'Maximales Kreditkartenlimit (nur Zahlen)',
      cards: 'Karten',
      creditCard: 'Kreditkarte',
      debitCard: 'Debitkarte',
      obcExperience: 'OBC-Erfahrung',
      firstObcJobDateAt: 'Datum des ersten OBC-Jobs',
      obcJobsDone: 'Anzahl der bisher absolvierten OBC-Jobs',
      medicalObcExperience: 'Medizin/Stammzellen-OBC-Erfahrung',
      myProfile: 'Mein Profil',
      email: 'E-mail',
      phoneNumber: 'Telefonnummer',
      personalDetails: 'Persönliche Daten',
      forChangesContactSupport:
        'Bei Änderungen in diesen Feldern wenden Sie sich an den PRIOjet-Support',
      firstNames: 'Vornamen',
      lastName: 'Nachname',
      sex: 'Geschlecht',
      dateOfBirth: 'Geburtsdatum',
      placeOfBirth: 'Geburtsort',
      countryOfBirth: 'Geburtsland',
      nationality: 'Nationalität',
      secondEmail: 'Zweite E-Mail',
      secondPhone: 'Zweites Telefon',
      baseAirports: 'Basisflughäfen',
      residenceAddress: 'Wohnadresse',
      addressName: 'Adresse-Name',
      address: 'Adresse',
      enterResidenceAddress: 'Geben Sie Ihre Wohnadresse ein',
      currentLocation: 'Aktueller Standort',
      businessInformation: 'Geschäftsinformationen',
      companyNameLabel: 'Firmenname',
      companyNamePlaceholder: 'Firmenname',
      taxCountry: 'Steuerland',
      taxCountryCaption: 'In welchem Land ist mein Unternehmen steuerlich registriert?',
      addressSameAsResidence: 'Firmenadresse gleich Wohnadresse',
      companyAddress: 'Firmenadresse',
      enterCompanyAddress: 'Firmenadresse eingeben',
      emergencyContact: 'Notfallkontakt',
      fullName: 'Vollständiger Name',
      relationship: 'Beziehung zu Kontakt',
      emailAddress: 'E-Mail-Adresse',
      contactNumber: 'Kontaktnummer',
      socialMedia: 'Social Media',
      languagesKnownMultipleAllowed: 'Gesprochene Sprachen (mehrere erlaubt)',
    },
    deleteAccount: {
      confirmModal: {
        title: 'Account löschen?',
        text: 'Willst du deinen Account wirklich löschen? \n\n Du kannst deinen Account innerhalb von 15 Tagen wieder reaktivieren. Danach musst du einen neuen Account erstellen!',
        inputLabel: 'Gib DELETE ein um die Accountlöschung zu bestätigen.',
        confirmButtonText: 'Löschen',
        cancelButtonText: 'Abbrechen',
        inputErrorMessage: 'Accountlöschung bestätigen',
        inputErrorDescription: 'Bitte DELETE eingeben um die Accountlöschung zu bestätigen.',
      },
      deleteSuccess: {
        message: 'Der Account ist jetzt gelöscht',
        description:
          'Schade, dass du von Bord gehst! Wenn du deine Meinung änderst, kannst du innerhalb von 15 Tagen eine E-Mail an support@priojet.com schicken um deinen Account wieder zu reaktivieren. Andererseits wird dein Account endgültig gelöscht und du musst einen neuen Account erstellen und erneut aktivieren.',
      },
      forbiddenAction: {
        message: 'Nicht erlaubte Aktion',
        description: 'Nur Agentur- und Kurieraccounts können gelöscht werden.',
      },
      restoreAccount: {
        confirmModal: {
          title: 'Account wiederherstellen',
          text: 'Willst du den ausgewählten Account wirklich wiederherstellen?',
          confirmButtonText: 'Wiederherstellen',
          cancelButtonText: 'Abbrechen',
        },
        restoreSuccess: {
          message: 'Account wiederhergestellt',
          description: 'Account erfolgreich wiederhergestellt.',
        },
      },
      noDeletedAccount: 'Keine gelöschten Accounts.',
      deletedAccountsPriojet: 'PRIOjet - Gelöschte Accounts',
      viewAgencies: 'Agenturen anzeigen',
      viewCouriers: 'Kuriere anzeigen',
      chatDisabledAgencyDeleted:
        'Neue Nachrichten können nicht erstellt werden, weil die verbundene Agentur das Konto gelöscht hat.',
      deletedAccounts: 'Gelöschte Accounts',
    },
    error: {
      internalCode: {
        '40002': {
          message: 'Es ist ein Fehler mit dem Aktivierungscode aufgetreten',
          description:
            'Der eingeladene Organisationstyp stimmt nicht mit dem Aktivierungscode überein. Bitte fordern Sie einen neuen an oder wenden Sie sich an support@priojet.com',
        },
        '44009': {
          message: 'Aktivierungsproblem ist aufgetreten',
          description:
            'Der angegebene Aktivierungscode ist ungültig oder kann nicht verwendet werden, um dieser Organisation beizutreten',
        },
        '44011': {
          message: 'Dieser Aktivierungscode wurde bereits verwendet',
          description:
            'Dieser Aktivierungscode wurde bereits verwendet. Bitte fordern Sie einen neuen an oder wenden Sie sich an support@priojet.com',
        },
        '44020': {
          message: 'Aktivierungsproblem ist aufgetreten',
          description:
            'Der angegebene Aktivierungscode ist ungültig oder kann nicht verwendet werden, um dieser Organisation beizutreten',
        },
      },
    },
    jobs: {
      startJob: 'Job starten',
      agencyJobStatusTypeChangeDescription:
        'Status für Job {{courierJobIdentifier}} geändert zu {{agencyJobStatusType}}.',
      startJobConfirmationPopupText:
        'Möchten Sie wirklich den Job {{priojetJobIdentifier}} in {{dropOffLocationName}} starten und die bestätigten Kuriere benachrichtigen?',
      confirmAndFinishJob: 'Job abgeschlossen und beenden',
      confirmAndFinishJobConfirmationPopupText:
        'Möchten Sie den Auftrag {{priojetJobIdentifier}} an {{dropOffLocationName}} wirklich  abschließen und beenden, sowie und die bestätigten Kuriere benachrichtigen?',
      resetJobAsNew: 'Auftrag als NEU zurücksetzen',
      resetJobAsNewConfirmationPopupText:
        'Wollen Sie den Auftrag {{priojetJobIdentifier}} an {{dropOffLocationName}} wirklich wieder auf Neu setzen? Dies wird die zuvor ausgewählten Kuriere benachrichtigen.',
      cancelJob: 'Auftrag abbrechen',
      cancelJobConfirmationPopupText:
        'Möchten Sie den Job {{priojetJobIdentifier}} für {{dropOffLocationName}} wirklich stornieren und die bestätigten Kuriere benachrichtigen?',
      changeJobToStartedAgain: 'Auftrag erneut auf GESTARTET setzen',
      pending: 'ausstehend',
      requested: 'angefragt',
      accepted: 'akzeptiert',
      declined: 'abgelehnt',
      manageCouriers: 'Kuriere verwalten',
      manageCouriers_plural: 'Kuriere verwalten',
      addOrRemoveCouriers: 'Kuriere hinzufügen/entfernen',
      viewOrEditJob: 'Jobdetails verwalten',
      pickupTime: 'Abhol-/Übernahmezeit',
      checkInTimeAtStartAirport: 'Check-in-Zeit am Startflughafen',
      dropOffTime: 'Abgabezeit',
      checkInAtEndAirport: 'Check-in-Zeit am Endflughafen',
      returnTimeAtEndAirport: 'Rückkehrzeit am Endflughafen',
      returnTimeAtStartAirport: 'Rückkehrzeit am Startflughafen',
      courierJobRelationshipTypeChangeForCourierMessage:
        'Auftrag {{priojetJobIdentifier}} wurde auf {{relationshipType}} gesetzt.',
      courierJobRelationshipTypeChangeForAgencyPrompt:
        'Job {{priojetJobIdentifier}} auf {{relationshipType}} ändern.',
      waitingForApprovalFromCourier: 'Warten auf Bestätigung vom Kurier',
      widthDrawAndDecline: 'zurückziehen & verwerfen',
      cancelWithdrawAndDecline: 'Stornieren, zurückziehen & verwerfen',
      acceptOrDeclineCourierForJob:
        'Diesen Kurier für den gegebenen Auftrag annehmen oder ablehnen',
      decline: 'Ablehnen',
      accept: 'bestätigen',
      proposeJobToCourierAgainRequestAgain: 'Kurier für diesen Job erneut anfragen',
      proposeAgain: 'Erneut anfragen',
      priojetJobIdentifier: 'PRIOjet Job-ID',
      jobTitleRefNumber: 'Auftragsnummer / Ref. Nr.',
      pickupLocation: 'Abholort',
      startAirport: 'Startflughafen',
      pickupAddressDescription: 'Beschreibung der Abholadresse',
      dropOffLocation: 'Abgabeort',
      endAirport: 'Endflughafen',
      additionalDetailsAndJobDescription: 'Zusätzliche Details und Anweisungen',
      dropOffAddressDescription: 'Beschreibung der Abgabeadresse',
      typeOfTransportJob: 'Art des Transportauftrages',
      carRequiredForPickup: 'Auto zur Abholung erforderlich',
      drivingLicenseRequiredForPickup: 'Führerschein für Abholung erforderlich',
      transportGoodsContents: 'Inhalt der Transportgüter',
      weightOfItemsKG: 'Gewicht der Artikel (KG)',
      numOfItems: 'Anzahl der Artikel',
      numOfCouriersRequired: 'Anzahl der benötigten Kuriere',
      currencyOfPayment: 'Zahlungswährung',
      hotelBudget: 'Hotelbudget',
      fixedRate: 'Festpreis',
      dailyRate: 'Tagespreis',
      kickOffFee: 'KickOff Zahlung',
      isAccommodationBookedByCourier: 'Über Kurier gebuchte Unterkunft',
      isAccommodationPaidByCourier: 'Unterkunft wird vom Kurier bezahlt',
      isAdditionalCostPaidByCourier: 'Zusätzliche Kosten bezahlt der Kurier',
      additionalCostInformation: 'Zusätzliche Informationen zur Kostenübernahme',
      new: 'Neu',
      running: 'laufend',
      past: 'Vorbei',
      numOfCouriersConnectedToAgencyJob:
        'Für diesen Job sind derzeit {{count}} Kuriere ausgewählt.',
      numOfCouriersConnectedToAgencyJobPlural:
        'Für diesen Job sind derzeit {{count}} Kuriere ausgewählt.',
      clientCurrencyErrorMessage: 'Sie müssen eine Währung auswählen',
      currencyErrorMessage: 'Sie müssen eine Währung auswählen',
      organizationNameErrorMessage: 'Sie müssen den Organisationsnamen Ihres Kunden angeben',
      returnTimeAtStartAirportSummary: 'Geschätzte Rückkehrzeit am Startflughafen',
      jobTitle: 'Auftragsnummer / Ref. Nr.',
      jobTitlePlaceholder: 'Ihre Auftragsnummer / Ihr Titel',
      externalJobReference:
        'Geben Sie diesem neuen Job eine Auftragsnummer (z. B. "{{organizationName}}-ABC123") oder einen Titel ("{{shortDate}} Paris to New York"). Dieser wird neben der PRIOjet Auftrags-ID verwendet(z. B. PJ-XXXXX).',
      Abholung: 'Abholung',
      pickupAtDoor: 'Abholung an der Tür',
      pickupAtAirport: 'Übernahme am Flughafen',
      pickupLocationCaption:
        'Wählen Sie den Abholort aus (Adresse, Ort oder nur den Namen einer Stadt/eines Stadtteils, falls der Ort noch nicht feststeht).',
      pickupLocationPlaceholder: 'Suchen Sie hier nach der Abholadresse',
      startAirportCaption:
        'Wählen Sie den Flughafen aus, von dem aus der Kurier seinen Kurierauftrag beginnt.',
      startAirportPlaceholder: 'Suche hier nach dem Startflughafen',
      currentLocation: 'Aktueller Standort',
      startDate: 'Startdatum',
      startDateLabel: 'Wählen Sie das Datum, an dem die Waren/Artikel abgeholt werden sollen.',
      hourLabel: 'Stunde',
      pickupHourCaption: 'Wählen Sie die Uhrzeit, zu der die Artikel/Waren abgeholt werden sollen.',
      minuteLabel: 'Minute',
      pickupMinuteCaption:
        'Wählen Sie die Uhrzeit und den Tag, an dem die Artikel/Waren abgeholt werden sollen.',
      pickupAddressDescriptionCaption:
        'Geben Sie weitere Informationen darüber an, wo und wie der Kurier die Artikel / Waren finden und abholen kann.',
      isCarRequiredForJobLabel: 'Auto zur Abholung erforderlich',
      isCarRequiredForJobCaption:
        'Wählen Sie aus, ob der Kurier für diesen Job ein Fahrzeug zur Verfügung haben muss.',
      isDrivingLicenseRequiredForJobLabel: 'Führerschein für diesen Job erforderlich',
      isDrivingLicenseRequiredForJobCaption:
        'Wählen Sie aus, ob dieser Job einen Kurier mit einem gültigen Führerschein erfordert',
      deliveryAndReturnTitle: 'Lieferung und Zustellung',
      deliveryAtDoor: 'Lieferung an der Tür',
      deliveryAtAirport: 'Übergabe am Flughafen',
      dropOffLocationLabel: 'Abgabeort',
      dropOffLocationCaption:
        'Wählen Sie den Abgabeort aus (Adresse, Ort oder nur den Namen einer Stadt/eines Stadtteils, falls der Ort noch nicht feststeht).',
      dropOffLocationPlaceholder: 'Suche hier nach der Abgabeadresse',
      endAirportLabel: 'Endflughafen',
      endAirportCaption: 'Wählen Sie den Endflughafen für Reise des Kuriers aus.',
      endAirportPlaceholder: 'Suche hier nach dem Endflughafen',
      returnTimeAtStartAirportDateLabel: 'Rückkehrzeit am Startflughafen',
      returnTimeAtStartAirportDateCaption:
        'Wählen Sie das Datum, an dem der Kurier zum Startflughafen zurückkehren wird.',
      deliveryHourCaption:
        'Schätzen Sie die Uhrzeit, zu der der Kurier zum Startflughafen zurückkehren wird.',
      deliveryMinuteCaption:
        'Schätzen Sie die Uhrzeit, in der der Kurier zum Startflughafen zurückkehren wird.',
      dropOffAddressDescriptionLabel: 'Beschreibung des Abgabe-/Zustellortes',
      dropOffAddressDescriptionCaption:
        'Geben Sie weitere Informationen darüber an, wo die Sendung übergeben oder abgegeben werden soll.',
      itemsGoodsTitle: 'Einzelheiten zu Artikeln und Waren der Sendung',
      transportGoodsContentsLabel: 'INHALT DER SENDUNG',
      weightOfItemsLabel: 'Gesamtgewicht aller Artikel',
      weightOfItemsCaption: 'Gesamtgewicht aller zu transportierenden Gegenstände.',
      numOfItemsLabel: 'Anzahl der zu transportierenden Einheiten',
      numOfItemsCaption:
        'Geben Sie die Anzahl der zu transportierenden Artikel, Taschen, Kisten etc. an.',
      numOfCouriersLabel: 'Anzahl der benötigten Kuriere',
      numOfCouriersCaption:
        'Geben Sie die Anzahl der Kuriere an, die für diese Aufgabe benötigt werden.',
      feesPaymentsTitle: 'Gebühren & Zahlungen',
      clientAgencyCurrencyLabel: 'Währung des Kunden',
      clientAgencyCurrencyCaption: 'Die Währung, in der der Kunde bezahlen wird.',
      agencyCourierCurrencyLabel: 'Währung des Kuriers',
      agencyCourierCurrencyCaption: 'Die Währung, mit der die Kuriere bezahlt werden.',
      hotelBudgetLabel: 'Hotelbudget',
      hotelBudgetCaption: 'Geben Sie das Hotelbudget an, das an den Kurier gezahlt wird.',
      fixedRateLabel: 'Festpreis',
      fixedRateCaption:
        'Geben Sie einen Festpreis / eine pauschale Entschädigung an, die dem Kurier für diesen Job angeboten wird.',
      dailyRateLabel: 'Vergütung pro Tag',
      dailyRateCaption:
        'Geben Sie einen Betrag an, den der Kurier pro angefangenen Kalendertag für diesen Job erhält.',
      kickOffFeeLabel: 'KickOff Zahlung',
      kickOffFeeCaption:
        'Geben Sie eine Kickoff Zahlung an, die dem Kurier für die Annahme dieses Jobs angeboten wird.',
      isAccommodationBookedByCourierLabel: 'Wer bucht die Unterkunft?',
      isAccommodationBookedByCourierCaption: 'Wählen Sie aus, welche Partei die Unterkunft bucht.',
      isAccommodationPaidByCourierLabel: 'Wer bezahlt die Unterkunft?',
      isAccommodationPaidByCourierCaption:
        'Wählen Sie aus, welche Partei für die Unterkunft bezahlt.',
      isAdditionalCostPaidByCourierLabel: 'Wer zahlt für zusätzliche Kosten?',
      isAdditionalCostPaidByCourierCaption:
        'Die zusätzlichen Kosten wie Abholung, Lieferung, Transfer etc.',
      additionalCostInformationLabel: 'Zusätzliche Informationen zur Kostenübernahme',
      additionalCostInformationCaption:
        'Geben Sie weitere Informationen zur Deckung zusätzlicher Kosten an',
      'Additional Details': 'Zusätzliche Details',
      additionalDetailsAndJobDescriptionLabel: 'Zusätzliche Details und Anweisungen an den Kurier',
      additionalDetailsAndJobDescriptionCaption:
        'Geben Sie weitere Informationen zum Job, sowie alle notwendigen Informationen für den Kurier an.',
      createNewJob: 'Neuen Job erstellen',
      overview: 'Übersicht',
      details: 'Einzelheiten',
      journey: 'Reise',
      doorToDoor: 'Tür zu Tür',
      airportToDoor: 'Flughafen zu Tür',
      doorToAirport: 'Tür zu Flughafen',
      airportToAirport: 'Flughafen zu Flughafen',
      numOfCouriersConnectedToAgencyJob_plural: 'Derzeit gibt es {{count}} Kuriere für diesen Job.',
      Pickup: 'Abholung',
      isAccommodationBookedByCourierFalse: 'Gebucht von der Agentur',
      isAccommodationBookedByCourierTrue: 'Gebucht vom Kurier',
      isAccommodationPaidByCourierFalse: 'Bezahlt von der Agentur',
      isAccommodationPaidByCourierTrue: 'Bezahlt vom Kurier',
      isAdditionalCostPaidByCourierFalse: 'Bezahlt vom Kurier',
      isAdditionalCostPaidByCourierTrue: 'Bezahlt von der Agentur durch Quittungen',
      agencyJobRateCardDescription:
        'Die nachstehenden Konditionen wurden angeboten, als Sie als Agentur den Kurier zunächst über eine Anfrage zum Auftrag hinzufügten. Alle danach per E-Mail, Telefon, Chat usw. vereinbarten Preise sind verbindlich.',
      courierJobRateCardDescription:
        'Die nachstehenden Konditionen wurden angeboten, als die Agentur Sie als Kurier zunächst über eine Anfrage zum Auftrag hinzufügte. Alle danach per E-Mail, Telefon, Chat usw. vereinbarten Preise sind verbindlich. Wenn Sie die Konditionen nachträglich ändern, indem Sie den Auftrag bearbeiten, werden die neuen Konditionen nicht in diesen Auftrag übernommen.',
      agencyJobsManageCouriersTabPending: 'OFFEN ({{courierJobs}})',
      agencyJobsManageCouriersTabRequested: 'ANGEFRAGT ({{courierJobs}})',
      agencyJobsManageCouriersTabAccepted: 'ANGENOMMEN ({{courierJobs}})',
      agencyJobsManageCouriersTabDeclined: 'ABGELEHNT ({{courierJobs}})',
      agencyJobsTabNew: 'NEU ({{agencyJobsNewCount}})',
      agencyJobsTabStarted: 'GESTARTET ({{agencyJobsStartedCount}})',
      agencyJobsTabFinished: 'BEENDET ({{agencyJobsFinishedCount}})',
      agencyJobsTabCancelled: 'STORNIERT ({{agencyJobsCancelledCount}})',
      AOG: 'AOG',
      AUTOMOTIVE_PARTS: 'Kraftfahrzeugteile',
      DOCUMENTS: 'Dokumente',
      MEDICAL: 'Medizinisch',
      OTHERS: 'Andere',
      PRODUCTION_MATERIALS: 'Produktionsmaterialien',
      SPARE_PARTS: 'Ersatzteile',
      agencyJobsPendingButton: 'offen',
      agencyJobsRequestedButton: 'angefragt',
      agencyJobsAcceptedButton: 'angenommen',
      agencyJobsDeclinedButton: 'abgelehnt',
      agencyJobsViewAllCouriers: 'Alle Kuriere anzeigen',
    },
    location: {
      street: 'Straße',
      houseNumber: 'Hausnummer',
      apartmentOrSuite: 'Wohnung/Sonstiges',
      postalCode: 'Postleitzahl',
      city: 'Stadt',
      stateRegion: 'Staat/Region',
      country: 'Land',
      requiredField: 'Muss gesetzt werden',
      enterDataManually: 'Drücken Sie hier, um Daten manuell einzugeben',
      addressNotFoundEnterDataManually:
        'Adresse nicht gefunden. Klicken Sie hier, um die Daten manuell einzugeben',
    },
    menuItems: {
      courierDocumentsPassportsEtc: 'Kurierdokumente (Pässe usw.)',
      generalSettingsAndUserAccount: 'Allgemeine Einstellungen und Benutzer-Account',
      appSettings: 'App-Einstellungen',
      switchAccount: 'Account wechseln',
      logOut: 'Abmelden',
      Other: 'Sonstiges',
      FAQ: 'FAQ',
      featureRequest: 'weitere Funktionen anfragen',
      voteForNewFeatures: 'Für neue Funktionen abstimmen',
      reportABug: 'Einen Fehler melden',
      getSupport: 'Unterstützung erhalten',
      Changelog: 'Änderungsbericht',
      Roadmap: 'Ausblick',
      Legal: 'Rechtliches',
      dataPrivacyPolicy: 'Datenschutzrichtlinie',
      Imprint: 'Impressum',
      appInformation: 'App-Informationen',
      deleteAccount: 'Account löschen',
      Jobs: 'Jobs',
      Availabilities: 'Verfügbarkeiten',
      Profile: 'Profil',
      PRIOjetProfile: 'PRIOjet-Profil',
      identityDocuments: 'Identitätsdokumente',
      travelPermits: 'Reiseerlaubnisse',
      frequentFlyerProgramDocuments: 'Vielflieger-Dokumente',
      vaccinationCertificates: 'Impfbescheinigungen',
      healthTestReports: 'Berichte über Gesundheitstests',
      backgroundDocuments: 'Hintergrunddokumente',
      otherDocuments: 'Sonstige Dokumente',
      courierDocuments: 'Kurierdokumente',
      contacts: 'Kontakte',
      courierInvitations: 'Kurier-Einladungen',
      backgroundDocument: 'Hintergrunddokumente',
      frequentFlyerDocuments: 'Vielflieger-Dokumente',
      identityDocument: 'Identitätsdokumente',
      otherDocument: 'Sonstige Dokumente',
      travelDocuments: 'Reisedokumente',
      vaccinationDocument: 'Impfungsdokumente',
      manageAgencyUsers: 'Agentur-Benutzer verwalten',
      AgencyProfile: 'Agenturprofil',
      userManagement: 'Benutzerverwaltung',
      agencyProfile: 'Agenturprofil',
      courierManagement: 'Kurier-Verwaltung',
      connectedCouriers: 'Angeschlossene Kuriere',
      connections: 'Verbindungen',
      addAgency: 'Agentur hinzufügen',
      connectedAgencies: 'Verbundene Agenturen',
      courierProfile: 'Kurier-Profil',
      myProfileMyAgencies: 'Mein Profil & meine Agenturen',
      myAgencies: 'Meine Agenturen',
      myDocuments: 'Meine Dokumente',
      Notifications: 'Benachrichtigungen',
      organizationInvitations: 'Organisationseinladungen',
      UserManagement: 'Benutzerverwaltung',
      managePRIOjetUsers: 'PRIOjet-Benutzer verwalten',
      manageUsers: 'Benutzer verwalten',
      organizationManagement: 'Organisation verwalten',
      manageOrganizations: 'Organisationen verwalten',
      myProfile: 'Mein Profil',
      courierProfileDocumentsButton: 'Kurierdokumente (Pässe usw.)',
    },
    organizationInvitations: {
      activationCodeShareMessageTitle: 'Sie wurden eingeladen, der PRIOjet-Plattform beizutreten.',
      activationCodeShareMessage:
        'Hallo!\n\n Wir freuen uns, Sie zur PRIOjet-Plattform (iOS, Android, Web) einladen zu können.\n Bitte melden Sie sich über die iOS-App ( https://apps.apple.com/kn/app/priojet-logistics/id1540437650 ), Android-App ( https://play.google.com/store/apps/details?id=com.priojet.app ) oder über https://app.priojet.com an und verwenden Sie den Code\n \n {{ActivationCode}}\n \n, um Ihr Account nach erfolgreicher Registrierung zu aktivieren.\n Wenn Sie Fragen haben, wenden Sie sich an support@priojet.com.\n \n Wir freuen uns, Sie an Bord begrüßen zu dürfen!\n Ihr PRIOjet-Team.',
      copiedActivationCodeClipboardMessage:
        'Aktivierungscode {{activationCode}} in die Zwischenablage kopiert.',
      sharingItem:
        'Geteilter <TimeAgoPopover></TimeAgoPopover> in {{language}} über {{appName}} ({{platform}} / {{platformVersion}}) durch {{firstNames}} {{lastName}} ({{email}}).',
    },
    i18nKey: '__STRING_NOT_TRANSLATED__',

    signUp: {
      termsAndConditions: 'Allgemeine Geschäftsbedingungen (T&Cs)',
      termsAndConditionsURL: 'https://www.priojet.com/',
      dataProcessingAgreement: 'Datenverarbeitungsvertrag',
      dataProcessingAgreementURL: 'https://www.priojet.com/',
      privacyPolicy: 'Datenschutzrichtlinie',
      privacyPolicyURL: 'https://www.priojet.com/datenschutzerklaerung/',
      termsAndConditionsCheckboxText:
        '<3>Ich stimme den <1>Geschäftsbedingungen (T&Cs)</1> zu und habe die <2>Datenschutzrichtlinie</2> zur Kenntnis genommen.</3>',
      finishYourSignupInThePopUpWindow: 'Schließen Sie Ihre Anmeldung im Popup-Fenster ab.',
      introAgencyText1:
        'Die innovativsten Logistikunternehmen nutzen PRIOjet, um ihre Prozesse zu optimieren und ihre zeitkritischen Transportaufträge gemeinsam mit ihren Kurieren zu verwalten.',
      introAgencyText2:
        'Sprechen Sie mit uns, um mehr darüber zu erfahren, wie Sie die PRIOjet Plattform unterstützt, Ihr Geschäft besser zu verwalten und dabei Zeit und Geld zu sparen. Die Zukunft des OBC-Transports ist digital, mobil, plattformübergreifend und kommuniziert in Echtzeit.',
      introAgencyText3: 'SIE SIND EINE LOGISTIKAGENTUR?',
      introAgencyText4:
        'Als Logistik-Agentur können Sie sich hier anmelden und direkt mit ihrem kostenfreien 14-Tage Textaccount starten (Kreditkarte erforderlich).',
      introCourierWarning:
        'WICHTIG: Sie benötigen einen Einladungscode, den Sie von Ihrer Logistik-Agentur anfordern können, wenn Sie Ihn noch nicht haben.',
      introCourierText1:
        'OBC-Kuriere nutzen PRIOjet, um sich mit ihren Agenturen zu verbinden, neue Aufträge zu finden und die entsprechenden Informationen zu verwalten, damit ihre Auftragsabwicklung reibungslos verläuft.',
      introCourierText2:
        'Sprechen Sie mit Ihren Agenturen oder PRIOjet, um mehr über unsere Plattform zu erfahren und wie wir Ihnen helfen können, Ihre OBC-Aufträge besser zu verwalten, sowie Zeit und Geld zu sparen. Die Zukunft des OBC-Transports ist digital, mobil, plattformübergreifend und kommuniziert in Echtzeit.',
      introCourierText3: 'SIE SIND EIN ON-BOARD COURIER (OBC)?',
      introCourierText4:
        'Melden Sie sich kostenlos an und verbinden Sie sich mit Ihren Agenturen, indem Sie Ihren Einladungscode verwenden.',
    },
  },
};
