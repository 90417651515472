import { useImperativeHandle, useMemo, useState } from 'react';

import { useNavigation, useRoute } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';

import { AgencyJobPlanning } from '../../../../../generated-graphql-types';
import { AgencyJobPlanningItemWithOffline } from '../types';
import { getStorageDataAgencyJobPlanning, saveStorageDataAgencyJobPlanning } from '../util';

export const useHook = ({
  agencyJobId,
  agencyJobPlanning,
  chatTrackAndTracingOfflineFormRef,
  handleCancel,
  handleConfirmForm: handleConfirmFormProp,
  handleResetPress: handleResetPressProp,
  handleSelectListItemOffline: handleSelectListItemOfflineProp,
  ref,
}: {
  agencyJobId?: string;
  agencyJobPlanning: AgencyJobPlanning | null;
  chatTrackAndTracingOfflineFormRef: any;
  handleCancel: () => void;
  handleConfirmForm: (payloadFormData: any) => Promise<boolean>;
  handleResetPress: (agencyJobPlanningItem: AgencyJobPlanningItemWithOffline) => Promise<boolean>;
  handleSelectListItemOffline: (
    agencyJobPlanningItem: AgencyJobPlanningItemWithOffline,
  ) => () => void;
  ref: any;
}) => {
  const { t } = useTranslation();

  const navigation = useNavigation();
  const route = useRoute<any>();

  const [completedItemModalVisible, setCompletedItemModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgencyJobPlanningItem, setSelectedAgencyJobPlanningItem] =
    useState<AgencyJobPlanningItemWithOffline | null>(null);
  const [selectedSyncItem, setSelectedSyncItem] = useState<AgencyJobPlanningItemWithOffline | null>(
    null,
  );
  const [selectedResetItem, setSelectedResetItem] =
    useState<AgencyJobPlanningItemWithOffline | null>(null);
  const [visible, setVisible] = useState(false);

  const handleResetPress = (item: AgencyJobPlanningItemWithOffline) => () => {
    setSelectedResetItem(item);
  };

  const handleResetConfirm = async () => {
    if (selectedResetItem) {
      setIsLoading(true);
      const res = await handleResetPressProp(selectedResetItem);
      if (res) {
        setSelectedResetItem(null);
      }
      setIsLoading(false);
    }
  };

  const handleResetCancel = () => {
    setSelectedResetItem(null);
  };

  const handleSelectListItemCompleted = (item: AgencyJobPlanningItemWithOffline) => () => {
    setCompletedItemModalVisible(true);
    setSelectedAgencyJobPlanningItem(item);
  };

  const handleSelectListItemOffline = (item: AgencyJobPlanningItemWithOffline) => () => {
    setSelectedSyncItem(item);
    handleSelectListItemOfflineProp(item)();
  };

  const handleCancelCompletedItem = () => {
    setCompletedItemModalVisible(false);
    setSelectedAgencyJobPlanningItem(null);
  };

  const handleConfirmForm = async (payloadFormData: any) => {
    const res = await handleConfirmFormProp(payloadFormData);
    if (res) {
      const agencyJobPlanningId = agencyJobPlanning?.id;
      const agencyJobPlanningItemId = selectedSyncItem?.id;
      const { storage } = await getStorageDataAgencyJobPlanning();
      if (
        agencyJobPlanningId &&
        agencyJobPlanningItemId &&
        storage[agencyJobPlanningId][agencyJobPlanningItemId]
      ) {
        delete storage[agencyJobPlanningId][agencyJobPlanningItemId];
        if (!Object.keys(storage[agencyJobPlanningId]).length) {
          delete storage[agencyJobPlanningId];
        }
        await saveStorageDataAgencyJobPlanning(JSON.stringify(storage));
      }
    }
    setSelectedSyncItem(null);
    chatTrackAndTracingOfflineFormRef.current?.toggleVisibility(false);
  };

  const handleCancelForm = () => {
    chatTrackAndTracingOfflineFormRef.current?.toggleVisibility(false);
  };

  const handlePlanningPress = () => {
    handleCancel();
    if (agencyJobId) {
      navigation.navigate('AgencyAgencyJobPlanningScreen', {
        agencyJobId: agencyJobId,
        previousScreenName: route.params.previousScreenName
          ? (`${route.params.previousScreenName},AgencyAgencyJobChatScreen` as any)
          : 'AgencyAgencyJobChatScreen',
        previousTabScreenName: route.params?.previousTabScreenName,
      });
    }
  };

  const isAgencyJobPlanningEmptyMessage = useMemo(() => {
    if (
      agencyJobPlanning &&
      agencyJobPlanning.agencyJobPlanningItems.every((item) => !item.active) &&
      agencyJobPlanning.agencyJobPlanningLegs.every((leg) =>
        leg.agencyJobPlanningItems.every((item) => !item.active),
      )
    ) {
      return t('jobs.nothingToShowNoTrackingPointsPlanned', {
        defaultValue: 'Nothing to show: No tracking points planned.',
      });
    }
  }, [agencyJobPlanning, t]);

  const toggleVisibility = (value: boolean) => {
    setVisible(value);
  };

  useImperativeHandle(ref, () => ({
    toggleVisibility,
  }));

  return {
    completedItemModalVisible,
    handleCancelForm,
    handleConfirmForm,
    handleCancelCompletedItem,
    handlePlanningPress,
    handleResetPress,
    handleResetCancel,
    handleResetConfirm,
    handleSelectListItemCompleted,
    handleSelectListItemOffline,
    isAgencyJobPlanningEmptyMessage,
    isLoading,
    selectedAgencyJobPlanningItem,
    selectedResetItem,
    selectedSyncItem,
    visible,
  };
};
