import * as AuthSession from 'expo-auth-session';
import * as Contacts from 'expo-contacts';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  AirlineAllianceStatusType,
  AirlineAllianceType,
  AirlineStatusType,
  AirlineType,
  Currency,
  DeviceInput,
  DocumentFeature,
  Gender,
  HealthTestType,
  IdentityDocumentSubType,
  IdentityDocumentType,
  JobTypeOfDelivery,
  LanguageSkillLevel,
  Organization,
  OrganizationSubscriptionPlan,
  OrganizationType,
  OrganizationUser,
  OrganizationUserRoleType,
  StoredAsset,
  TravelDocumentType,
  User,
  UserBackgroundDocument,
  UserFrequentFlyerDocument,
  UserHealthReport,
  UserIdentityDocument,
  UserOtherDocument,
  UserTravelDocument,
  UserTravelDocumentSubTypeType,
  UserVaccinationDocument,
  VaccinationTargetDisease,
} from './generated-graphql-types';
import i18n from './i18n/i18n';

export type SasTokenResponse = {
  version: string;
  signature: string;
  expiresOn: string;
  permissions: string;
  resource: string;
};
export type StoredAssetMetadata = {
  fileName: string;
  height: number;
  size: number;
  width: number;
  mimeType: string;
};

export type UserDocument2 =
  | UserIdentityDocument
  | UserTravelDocument
  | UserFrequentFlyerDocument
  | UserVaccinationDocument
  | UserHealthReport
  | UserBackgroundDocument
  | UserOtherDocument;

export class UserDocument {
  id?: string;
  firstNames?: string;
  lastName?: string;
  placeOfBirth?: string;
  sex?: string;
  dateOfBirth?: DateTime;
  documentType?: string;
  documentSubType?: string;
  description?: string;
  documentFeature?: string;
  documentNumber?: string;
  dateOfIssue?: DateTime;
  countryOfIssue?: string;
  validForCountry?: string;
  placeOfIssue?: string;
  expiresOn?: DateTime;
  nationality?: string;
  documentContainerName?: string;
  originalDocumentFrontFilePath?: string;
  compressedDocumentFrontFilePath?: string;
  originalDocumentBackFilePath?: string;
  compressedDocumentBackFilePath?: string;
  originalDocumentFrontMetadata?: StoredAssetMetadata;
  compressedDocumentFrontMetadata?: StoredAssetMetadata;
  originalDocumentBackMetadata?: StoredAssetMetadata;
  compressedDocumentBackMetadata?: StoredAssetMetadata;
  documentFrontFromAzure?: StoredAsset;
  documentBackFromAzure?: StoredAsset;
  multipleEntriesAllowed?: boolean;
}

export type Token = {
  iss: string;
  exp: number;
  nbf: number;
  aud: string;
  oid: string;
  sub: string;
  city: string;
  country: string;
  name: string;
  givenName: string;
  familyName: string;
  emails: string[];
  tfp: string;
  azp: string;
  ver: string;
  iat: number;
};
export type LocalUserContext = {
  sub?: string;
  user?: User;
  lastSyncedAt?: DateTime | null;
  tokenResponse?: AuthSession.TokenResponse;
  userContextId?: string;
  role?: string;
  refreshTokenExpired?: boolean;
  organizationActivatedAt?: DateTime | null;
  deviceId?: string | undefined;
  token?: Token;
  organizationUser?: OrganizationUser | null;
  organization?: Organization | null;
  organizationType?: OrganizationType | null;
  roleType?: OrganizationUserRoleType | null;
  signUpOrganizationType?: OrganizationType;
  device?: Device;
  organizationSubscriptionPlan?: OrganizationSubscriptionPlan;
};

export type LocalUserContextTokenResponseOnly = LocalUserContext & {
  user?: undefined;
  lastSyncedAt: null;
  tokenResponse: AuthSession.TokenResponse;
  userContextId: string;
  role: '/USER/';
  refreshTokenExpired: boolean;
  organizationActivatedAt: null;
  deviceId: string;
  token?: Token;
  organizationUser?: null;
  organization?: null;
  organizationType?: null;
  roleType?: null;
  signUpOrganizationType?: OrganizationType;
  device: Device;
};

export type LocalUserContexts = Record<string, LocalUserContext>;

export type Device = DeviceInput & {
  isSynchronizedWithBackend: boolean;
};

export type UserProfile = {
  id: string;
  firstNames?: string;
  lastName?: string;
  sex?: GenderType;
  dateOfBirth?: DateTime;
  nationality?: string;
  language?: string;
  email?: string;
  phone?: number;
  email2?: string;
  phone2?: number;
  placeOfBirth?: string;
};

export const IdentityDocumentSubTypeList = [
  { value: IdentityDocumentSubType.PASSPORT, name: 'Passport: Normal', code: 0 },
  {
    value: IdentityDocumentSubType.CONSULAR_PASSPORT,
    name: 'Passport: Consular',
    code: 1,
  },
  {
    value: IdentityDocumentSubType.DIPLOMATIC_PASSPORT,
    name: 'Passport: Diplomatic',
    code: 2,
  },
  {
    value: IdentityDocumentSubType.HONGKONG_SAR_CHINA_PASSPORT,
    name: 'Passport: Hong Kong (SAR China)',
    code: 3,
  },
  {
    value: IdentityDocumentSubType.MACAO_SAR_CHINA_PASSPORT,
    name: 'Passport: Macao (SAR China)',
    code: 4,
  },
  {
    value: IdentityDocumentSubType.OFFICIAL_PASSPORT,
    name: 'Passport: Official',
    code: 5,
  },
  { value: IdentityDocumentSubType.SERVICE_PASSPORT, name: 'Passport: Service', code: 6 },
  { value: IdentityDocumentSubType.SPECIAL_PASSPORT, name: 'Passport: Special', code: 7 },
  { value: IdentityDocumentSubType.ALIENS_PASSPORT, name: "Alien's Passport", code: 8 },
  {
    value: IdentityDocumentSubType.FORM_I512,
    name: 'Authorization for Parole of an Alien into the United States',
    code: 9,
  },
  {
    value: IdentityDocumentSubType.BIRTH_CERTIFICATE,
    name: 'Birth Certificate',
    code: 10,
  },
  {
    value: IdentityDocumentSubType.BRITISH_CITIZEN,
    name: 'British Citizen passport',
    code: 11,
  },
  {
    value: IdentityDocumentSubType.BRITISH_DEPENDENT_TERRITORIES_CITIZEN,
    name: 'British Dependent Territories Citizen passport',
    code: 12,
  },
  {
    value: IdentityDocumentSubType.BRITISH_NATIONAL_OVERSEAS,
    name: 'British National (Overseas) passport',
    code: 13,
  },
  {
    value: IdentityDocumentSubType.BRITSH_OVERSEAS_CITIZEN,
    name: 'British Overseas Citizen passport',
    code: 14,
  },
  {
    value: IdentityDocumentSubType.BRITISH_OVERSEAS_TERRITORIES_CITIZEN,
    name: 'British Overseas Territories Citizen passport',
    code: 15,
  },
  {
    value: IdentityDocumentSubType.BRITISH_PROTECTED_PERSON,
    name: 'British Protected Person passport',
    code: 16,
  },
  {
    value: IdentityDocumentSubType.BRITISH_SUBJECT,
    name: 'British Subject passport',
    code: 17,
  },
  {
    value: IdentityDocumentSubType.CDB,
    name: 'Caribbean Development Bank Travel Certificate',
    code: 18,
  },
  {
    value: IdentityDocumentSubType.CERTIFICATEOF_CITIZENSHIP,
    name: 'Certificate of Citizenship',
    code: 19,
  },
  {
    value: IdentityDocumentSubType.CERTIFICATEOF_IDENTITY,
    name: 'Certificate of Identity',
    code: 20,
  },
  {
    value: IdentityDocumentSubType.CERTIFICATEOF_NATURALIZATION,
    name: 'Certificate of Naturalization',
    code: 21,
  },
  {
    value: IdentityDocumentSubType.CNEXITAND_ENTRY_PERMIT,
    name: 'CN Exit and Entry Permit',
    code: 22,
  },
  {
    value: IdentityDocumentSubType.CNONE_WAY_PERMIT,
    name: 'CN Travel Permit to HK and MO (One-way Permit)',
    code: 23,
  },
  {
    value: IdentityDocumentSubType.CREW_GENERAL_DECLARATION_FORM,
    name: 'Crew General Declaration Form',
    code: 24,
  },
  {
    value: IdentityDocumentSubType.CREW_MEMBER_CERTIFICATE,
    name: 'Crew Member Certificate',
    code: 25,
  },
  {
    value: IdentityDocumentSubType.CREW_MEMBER_IDCARD,
    name: 'Crew Member ID Card',
    code: 26,
  },
  {
    value: IdentityDocumentSubType.CREW_MEMBER_LICENCE,
    name: 'Crew Member Licence',
    code: 27,
  },
  {
    value: IdentityDocumentSubType.DOCUMENTOF_IDENTITY,
    name: 'Document of Identity',
    code: 28,
  },
  {
    value: IdentityDocumentSubType.EMERGENCY_PASSPORT,
    name: 'Emergency Passport',
    code: 29,
  },
  {
    value: IdentityDocumentSubType.EEPTW,
    name: 'Exit &amp; Entry Permit Taiwan Republic of China',
    code: 30,
  },
  {
    value: IdentityDocumentSubType.GRPOLICE_ID,
    name: 'Hellenic Police ID Card',
    code: 31,
  },
  { value: IdentityDocumentSubType.HUI_XIANG_ZHENG, name: 'Hui Xiang Zheng', code: 32 },
  { value: IdentityDocumentSubType.INTERPOL_IDCARD, name: 'Interpol IDCard', code: 33 },
  {
    value: IdentityDocumentSubType.INTERPOL_PASSPORT,
    name: 'Interpol Passport',
    code: 34,
  },
  { value: IdentityDocumentSubType.LAISSEZ_PASSER, name: 'Laissez-Passer', code: 35 },
  {
    value: IdentityDocumentSubType.UN_LAISSEZ_PASSER,
    name: 'Laissez-Passer issued by the United Nations',
    code: 36,
  },
  { value: IdentityDocumentSubType.LU_BAO_ZHENG, name: 'Lu Bao Zheng', code: 37 },
  { value: IdentityDocumentSubType.LU_XING_ZHENG, name: 'Lu Xing Zheng', code: 38 },
  {
    value: IdentityDocumentSubType.VISIT_PERMIT_FOR_RESIDENTS_OF_MACAOTO_HKSAR,
    name: 'Macao (SAR China) Visit Permit for Residents of Macao to HKSAR',
    code: 39,
  },
  { value: IdentityDocumentSubType.MILITARY_ID_CARD, name: 'Military ID Card', code: 40 },
  {
    value: IdentityDocumentSubType.MILITARY_IDENTITY_DOCUMENT,
    name: 'Military Identity Document',
    code: 41,
  },
  { value: IdentityDocumentSubType.NATIONAL_ID_CARD, name: 'National ID Card', code: 42 },
  { value: IdentityDocumentSubType.NEXUS_CARD, name: 'NEXUS Card', code: 43 },
  { value: IdentityDocumentSubType.NONE, name: 'No Document Type held', code: 44 },
  {
    value: IdentityDocumentSubType.NOTARIZED_AFFIDAVITOF_CITIZENSHIP,
    name: 'Notarized Affidavit of Citizenship',
    code: 45,
  },
  {
    value: IdentityDocumentSubType.OASOFFICIAL_TRAVEL_DOCUMENT,
    name: 'OAS Official Travel Document',
    code: 46,
  },
  {
    value: IdentityDocumentSubType.OFFICIAL_PHOTO_ID,
    name: 'Official Photo ID',
    code: 47,
  },
  { value: IdentityDocumentSubType.PASSPORT_CARD, name: 'Passport Card', code: 48 },
  {
    value: IdentityDocumentSubType.FORM_I327,
    name: 'Permit to Re-Enter the USA (Form I-327)',
    code: 49,
  },
  {
    value: IdentityDocumentSubType.PUBLIC_AFFAIRS_HK_MO_TRAVEL_PERMIT,
    name: 'Public Affairs HK and MO Travel Permit',
    code: 50,
  },
  { value: IdentityDocumentSubType.SEAMAN_BOOK, name: 'Seaman Book', code: 51 },
  { value: IdentityDocumentSubType.TAI_BAO_ZHENG, name: 'Tai Bao Zheng', code: 52 },
  {
    value: IdentityDocumentSubType.TEMPORARY_PASSPORT,
    name: 'Temporary passport',
    code: 53,
  },
  {
    value: IdentityDocumentSubType.TEMPORARY_TRAVEL_DOCUMENT,
    name: 'Temporary Travel Document',
    code: 54,
  },
  { value: IdentityDocumentSubType.TITREDE_VOYAGE, name: 'Titre de Voyage', code: 55 },
  {
    value: IdentityDocumentSubType.TRANSPORTATION_LETTER,
    name: 'Transportation Letter',
    code: 56,
  },
  {
    value: IdentityDocumentSubType.TRAVEL_CERTIFICATE,
    name: 'Travel Certificate',
    code: 57,
  },
  {
    value: IdentityDocumentSubType.CTD1951,
    name: 'Travel Document Convention 1951',
    code: 58,
  },
  {
    value: IdentityDocumentSubType.CTD1954,
    name: 'Travel Document Convention 1954',
    code: 59,
  },
  {
    value: IdentityDocumentSubType.PSTD,
    name: 'Travel Document Palestinian Refugees',
    code: 60,
  },
  { value: IdentityDocumentSubType.ICRCTD, name: 'Travel Document Red Cross', code: 61 },
  { value: IdentityDocumentSubType.TRAVEL_PERMIT, name: 'Travel Permit', code: 62 },
  {
    value: IdentityDocumentSubType.TRAVEL_PERMIT_HKMO,
    name: 'Travel Permit to/from HK and MO',
    code: 63,
  },
  {
    value: IdentityDocumentSubType.FORM_I571,
    name: 'US Refugee Travel Document (Form I-571)',
    code: 64,
  },
  {
    value: IdentityDocumentSubType.UNMIKTRAVEL_DOCUMENT,
    name: 'UNMIK Travel Document',
    code: 65,
  },
  {
    value: IdentityDocumentSubType.VOTERS_REGISTRATION_CARD,
    name: "Voter's Registration Card",
    code: 66,
  },
  { value: IdentityDocumentSubType.CAR_LICENSE, name: 'Valid for Cars', code: 67 },
  { value: IdentityDocumentSubType.TRUCK_LICENSE, name: 'Valid for Trucks', code: 68 },
  {
    value: IdentityDocumentSubType.OTHER_LICENSE,
    name: 'Valid for other vehicles',
    code: 69,
  },
  { value: IdentityDocumentSubType.APEC_CARD, name: 'APEC Card', code: 70 },
];

export const PassportTypeList = (() => {
  const data = IdentityDocumentSubTypeList.filter(
    (obj) => !obj.value.endsWith('LICENSE') && obj.value !== IdentityDocumentSubType.NONE,
  ).sort((a: any, b: any): any => {
    return (
      b.name.startsWith('Passport:') - a.name.startsWith('Passport:') ||
      a.name.localeCompare(b.name)
    );
  });
  return data;
})();
export const LicenseTypeSubList = (() => {
  const data = IdentityDocumentSubTypeList.filter((obj) => obj.value.endsWith('LICENSE'));
  return data;
})();
export const UserIdentityDocumentTypeList = [
  { name: "Driver's License", value: IdentityDocumentType.DRIVERS_LICENSE, code: 0 },
  { name: 'National ID/Passport', value: IdentityDocumentType.NATIONAL_ID, code: 1 },
];
export const UserTravelDocumentTypeList = [
  {
    name: 'Fiktionsbescheinigung',
    value: TravelDocumentType.FIKTIONSBESCHEINIGUNG,
    code: 0,
  },
  { name: 'Visa', value: TravelDocumentType.VISA, code: 1 },
];
export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export const sexList = [
  { name: Gender.FEMALE, code: Gender.FEMALE },
  { name: Gender.MALE, code: Gender.MALE },
  { name: Gender.OTHER, code: Gender.OTHER },
];

export const VaccinationTargetDiseaseList = [
  { name: 'Cholera', value: VaccinationTargetDisease.CHOLERA, code: 1 },
  { name: 'COVID-19 (corona virus)', value: VaccinationTargetDisease.COVID19, code: 2 },
  { name: 'Dengue', value: VaccinationTargetDisease.DENGUE, code: 3 },
  { name: 'Diphtheria', value: VaccinationTargetDisease.DIPHTHERIA, code: 4 },
  {
    name: 'Haemophilus influenzae type b (Hib)',
    value: VaccinationTargetDisease.HIB,
    code: 5,
  },
  { name: 'Hepatitis A', value: VaccinationTargetDisease.HEPATITIS_A, code: 6 },
  { name: 'Hepatitis B', value: VaccinationTargetDisease.HEPATITIS_B, code: 7 },
  { name: 'Hepatitis E', value: VaccinationTargetDisease.HEPATITIS_E, code: 8 },
  { name: 'Human papillomavirus (HPV)', value: VaccinationTargetDisease.HPV, code: 9 },
  { name: 'Influenza', value: VaccinationTargetDisease.INFLUENZA, code: 10 },
  {
    name: 'Japanese encephalitis',
    value: VaccinationTargetDisease.JAPANESE_ENCEPHALITIS,
    code: 11,
  },
  { name: 'Malaria', value: VaccinationTargetDisease.MALARIA, code: 12 },
  { name: 'Measles', value: VaccinationTargetDisease.MEASLES, code: 13 },
  {
    name: 'Meningococcal meningitis',
    value: VaccinationTargetDisease.MENINGITIS,
    code: 14,
  },
  { name: 'Mumps', value: VaccinationTargetDisease.MUMPS, code: 15 },
  { name: 'Pertussis', value: VaccinationTargetDisease.PERTUSSIS, code: 16 },
  {
    name: 'Pneumococcal disease',
    value: VaccinationTargetDisease.PNEUMOCOCCAL_DISEASE,
    code: 17,
  },
  { name: 'Poliomyelitis', value: VaccinationTargetDisease.POLIO, code: 18 },
  { name: 'Rabies', value: VaccinationTargetDisease.RABIES, code: 19 },
  { name: 'Rotavirus', value: VaccinationTargetDisease.ROTAVIRUS, code: 20 },
  { name: 'Rubella', value: VaccinationTargetDisease.RUBELLA, code: 21 },
  { name: 'Tetanus', value: VaccinationTargetDisease.TETANUS, code: 22 },
  {
    name: 'Tick-borne encephalitis',
    value: VaccinationTargetDisease.TICK_BORNE_ENCEPHALITIS,
    code: 23,
  },
  { name: 'Tuberculosis', value: VaccinationTargetDisease.TB, code: 24 },
  { name: 'Typhoid', value: VaccinationTargetDisease.TYPHOID, code: 25 },
  { name: 'Varicella', value: VaccinationTargetDisease.VARICELLA, code: 26 },
  { name: 'Yellow Fever', value: VaccinationTargetDisease.YELLOW_FEVER, code: 27 },
];
export const LanguageSkillLevelList = [
  LanguageSkillLevel.BASIC,
  LanguageSkillLevel.ADVANCED,
  LanguageSkillLevel.NATIVE,
];

export class TLanguageKnown {
  __typename!: 'TLanguageKnown';
  language!: string;
  level!: LanguageSkillLevel;
}
export const DocumentFeatureList = [
  { value: DocumentFeature.BIOMETRIC, name: 'Biometric' },
  { value: DocumentFeature.DIGITAL_PHOTO, name: 'Digital Photo' },
  { value: DocumentFeature.MACHINE_READABLE, name: 'Machine Readable' },
];

export const AirlineTypeList = [
  { key: 'Aegean Airlines', name: 'Aegean Airlines', value: AirlineType.A3 },
  { key: 'Aer Lingus', name: 'Aer Lingus', value: AirlineType.EI },
  { key: 'Aeroflot', name: 'Aeroflot', value: AirlineType.SU },
  { key: 'Aerolíneas Argentinas', name: 'Aerolíneas Argentinas', value: AirlineType.AR },
  { key: 'AeroMexico', name: 'AeroMexico', value: AirlineType.AM },
  { key: 'Air Canada', name: 'Air Canada', value: AirlineType.AC },
  { key: 'Air China', name: 'Air China', value: AirlineType.CA },
  { key: 'Air Dolomiti', name: 'Air Dolomiti ', value: AirlineType.EN },
  { key: 'Air Europa', name: 'Air Europa', value: AirlineType.UX },
  { key: 'Air France', name: 'Air France', value: AirlineType.AF },
  { key: 'Air India', name: 'Air India', value: AirlineType.AI },
  { key: 'Air New Zealand', name: 'Air New Zealand', value: AirlineType.NZ },
  { key: 'Alaskan Air', name: 'Alaskan Air', value: AirlineType.AS },
  { key: 'Alitalia', name: 'Alitalia', value: AirlineType.AZ },
  { key: 'American Airlines', name: 'American Airlines', value: AirlineType.AA },
  { key: 'ANA', name: 'ANA', value: AirlineType.NH },
  { key: 'Asiana Airlines', name: 'Asiana Airlines', value: AirlineType.OZ },
  { key: 'Austrian', name: 'Austrian', value: AirlineType.OS },
  { key: 'Avianca', name: 'Avianca', value: AirlineType.AV },
  { key: 'British Airways', name: 'British Airways', value: AirlineType.BA },
  { key: 'Brussels Airlines', name: 'Brussels Airlines', value: AirlineType.SN },
  { key: 'Cathay Pacific', name: 'Cathay Pacific', value: AirlineType.CX },
  { key: 'China Airlines', name: 'China Airlines', value: AirlineType.CI },
  { key: 'China Eastern', name: 'China Eastern', value: AirlineType.MU },
  { key: 'Copa Airlines', name: 'Copa Airlines', value: AirlineType.CM },
  { key: 'Croatia Airlines', name: 'Croatia Airlines', value: AirlineType.OU },
  { key: 'Czech Airlines', name: 'Czech Airlines', value: AirlineType.OK },
  { key: 'Delta Airlines', name: 'Delta Airlines', value: AirlineType.DL },
  { key: 'Egypt Air', name: 'Egypt Air', value: AirlineType.MS },
  { key: 'Emirates', name: 'Emirates', value: AirlineType.EK },
  { key: 'Ethiopian', name: 'Ethiopian', value: AirlineType.ET },
  { key: 'Etihad', name: 'Etihad', value: AirlineType.EY },
  { key: 'EVA Air', name: 'EVA Air', value: AirlineType.BR },
  { key: 'Finnair', name: 'Finnair', value: AirlineType.AY },
  { key: 'Garuda', name: 'Garuda', value: AirlineType.GA },
  { key: 'Iberia', name: 'Iberia', value: AirlineType.IB },
  { key: 'Japan Airlines', name: 'Japan Airlines', value: AirlineType.JL },
  { key: 'JetBlue', name: 'JetBlue', value: AirlineType.B6 },
  { key: 'Kenya Airways', name: 'Kenya Airways', value: AirlineType.KQ },
  { key: 'KLM', name: 'KLM', value: AirlineType.KL },
  { key: 'Korean Air', name: 'Korean Air', value: AirlineType.KE },
  { key: 'LOT Polish Airlines', name: 'LOT Polish Airlines', value: AirlineType.LO },
  { key: 'Lufthansa', name: 'Lufthansa', value: AirlineType.LH },
  { key: 'Malaysia Airlines', name: 'Malaysia Airlines', value: AirlineType.MH },
  { key: 'Middle East Airlines', name: 'Middle East Airlines', value: AirlineType.ME },
  { key: 'Philippine Airline', name: 'Philippine Airline', value: AirlineType.PR },
  { key: 'Qatar Airways', name: 'Qatar Airways', value: AirlineType.QR },
  { key: 'Quantas', name: 'Quantas', value: AirlineType.QF },
  { key: 'Royal Air Maroc', name: 'Royal Air Maroc', value: AirlineType.AT },
  { key: 'Royal Jordanian', name: 'Royal Jordanian', value: AirlineType.RJ },
  { key: 'S7', name: 'S7', value: AirlineType.S7 },
  {
    key: 'SAS Scandinavian Airlines',
    name: 'SAS Scandinavian Airlines',
    value: AirlineType.SK,
  },
  {
    key: 'Saudi Arabian Airlines',
    name: 'Saudi Arabian Airlines',
    value: AirlineType.SV,
  },
  { key: 'Shenzhen Airlines', name: 'Shenzhen Airlines', value: AirlineType.ZH },
  { key: 'Singapore Airlines', name: 'Singapore Airlines', value: AirlineType.SQ },
  { key: 'South African Airways', name: 'South African Airways', value: AirlineType.SA },
  { key: 'Southwest', name: 'Southwest', value: AirlineType.WN },
  { key: 'SriLankan Airlines', name: 'SriLankan Airlines', value: AirlineType.UL },
  { key: 'Swiss', name: 'Swiss', value: AirlineType.LX },
  { key: 'TAP Portugal', name: 'TAP Portugal', value: AirlineType.TP },
  { key: 'TAROM', name: 'TAROM', value: AirlineType.RO },
  { key: 'Thai Airways', name: 'Thai Airways', value: AirlineType.TG },
  { key: 'TU Tunis Air', name: 'TU Tunis Air', value: AirlineType.TU },
  { key: 'Turkish Airlines', name: 'Turkish Airlines', value: AirlineType.TK },
  { key: 'United Airlines', name: 'United Airlines', value: AirlineType.UA },
  { key: 'Vietnam Airlines', name: 'Vietnam Airlines', value: AirlineType.VN },
  { key: 'Xiamen Airlines', name: 'Xiamen Airlines', value: AirlineType.MF },
];
export const AirlineStatusTypeList = [
  { key: '1K', name: '1K', value: AirlineStatusType.K1, link: AirlineType.UA + '|' },
  { key: '25K', name: '25K', value: AirlineStatusType.K25, link: AirlineType.AC + '|' },
  { key: '35K', name: '35K', value: AirlineStatusType.K25, link: AirlineType.AC + '|' },
  { key: '50K', name: '50K', value: AirlineStatusType.K25, link: AirlineType.AC + '|' },
  { key: '75K', name: '75K', value: AirlineStatusType.K25, link: AirlineType.AC + '|' },
  {
    key: 'AList',
    name: 'AList',
    value: AirlineStatusType.ALIST,
    link: AirlineType.WN + '|',
  },

  {
    key: 'AList Preferred',
    name: 'AList Preferred',
    value: AirlineStatusType.ALIST_PREFERRED,
    link: AirlineType.WN + '|',
  },
  {
    key: 'Ambassador',
    name: 'Ambassador',
    value: AirlineStatusType.AMBASSADOR,
    link: AirlineType.AT + '|',
  },
  {
    key: 'Ana Million Miler',
    name: 'Ana Million Miler',
    value: AirlineStatusType.ANA_MILLION_MILER,
    link: AirlineType.NH + '|',
  },
  {
    key: 'Basic',
    name: 'Basic',
    value: AirlineStatusType.BASIC,
    link: AirlineType.AY + '|' + AirlineType.TG + '|',
  },
  {
    key: 'Blue',
    name: 'Blue',
    value: AirlineStatusType.BLUE,
    link:
      AirlineType.A3 +
      '|' +
      AirlineType.BA +
      '|' +
      AirlineType.MS +
      '|' +
      AirlineType.EK +
      '|' +
      AirlineType.ET +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.MH +
      '|' +
      AirlineType.AT +
      '|' +
      AirlineType.SV +
      '|' +
      AirlineType.SA +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.MH +
      '|',
  },
  {
    key: 'Bronze',
    name: 'Bronze',
    value: AirlineStatusType.BRONZE,
    link: AirlineType.BA + '|' + AirlineType.NH + '|',
  },
  {
    key: 'Burgundy',
    name: 'Burgundy',
    value: AirlineStatusType.BURGUNDY,
    link: AirlineType.QR + '|',
  },
  {
    key: 'Classic',
    name: 'Classic',
    value: AirlineStatusType.CLASSIC,
    link: AirlineType.UL + '|' + AirlineType.TU + '|' + AirlineType.TK + '|',
  },
  {
    key: 'Classic Plus',
    name: 'Classic Plus',
    value: AirlineStatusType.CLASSIC_PLUS,
    link: AirlineType.TK + '|',
  },
  {
    key: 'Concierge',
    name: 'Concierge',
    value: AirlineStatusType.CONCIERGE,
    link: AirlineType.EI + '|',
  },
  {
    key: 'Concierge Key',
    name: 'Concierge Key',
    value: AirlineStatusType.CONCIERGE_KEY,
    link: AirlineType.AA + '|',
  },
  {
    key: 'Diamond',
    name: 'Diamond',
    value: AirlineStatusType.DIAMOND,
    link:
      AirlineType.NH +
      '|' +
      AirlineType.OZ +
      '|' +
      AirlineType.CX +
      '|' +
      AirlineType.SK +
      '|' +
      AirlineType.MF +
      '|',
  },
  {
    key: 'Diamond Plus',
    name: 'Diamond Plus',
    value: AirlineStatusType.DIAMOND_PLUS,
    link: AirlineType.OZ + '|',
  },
  {
    key: 'Diamond Medallion',
    name: 'Diamond Medallion',
    value: AirlineStatusType.DIAMOND_MEDALLION,
    link: AirlineType.DL + '|',
  },
  {
    key: 'Dynasty',
    name: 'Dynasty',
    value: AirlineStatusType.DYNASTY,
    link: AirlineType.CI + '|',
  },
  {
    key: 'Elite',
    name: 'Elite',
    value: AirlineStatusType.ELITE,
    link: AirlineType.NZ + '|' + AirlineType.MS + '|' + AirlineType.PR + '|' + AirlineType.TK + '|',
  },
  {
    key: 'Emerald',
    name: 'Emerald',
    value: AirlineStatusType.EMERALD,
    link: AirlineType.CI + '|',
  },
  {
    key: 'Executive Platinum',
    name: 'Executive Platinum',
    value: AirlineStatusType.EXECUTIVE_PLATINUM,
    link: AirlineType.AA + '|',
  },
  {
    key: 'Explorer',
    name: 'Explorer',
    value: AirlineStatusType.EXPLORER,
    link: AirlineType.AF + '|' + AirlineType.KL + '|',
  },
  {
    key: 'Frequent Traveller',
    name: 'Frequent Traveller',
    value: AirlineStatusType.FREQUENT_TRAVELLER,
    link:
      AirlineType.EN +
      '|' +
      AirlineType.OS +
      '|' +
      AirlineType.SN +
      '|' +
      AirlineType.OU +
      '|' +
      AirlineType.LO +
      '|' +
      AirlineType.LH +
      '|' +
      AirlineType.LX +
      '|',
  },
  {
    key: 'Freccia Alata Club',
    name: 'Freccia Alata Club',
    value: AirlineStatusType.FRECCIA_ALATA_CLUB,
    link: AirlineType.AZ + '|',
  },
  {
    key: 'Freccia Alata Plus Club',
    name: 'Freccia Alata Plus Club',
    value: AirlineStatusType.FRECCIA_ALATA_PLUS_CLUB,
    link: AirlineType.AZ + '|',
  },
  {
    key: 'Freccia Alata Plus Per Sempre Card',
    name: 'Freccia Alata Plus Per Sempre Card',
    value: AirlineStatusType.FRECCIA_ALATA_PLUS_PER_SEMPRE_CARD,
    link: AirlineType.AZ + '|',
  },

  {
    key: 'Global Services',
    name: 'Global Services',
    value: AirlineStatusType.GLOBAL_SERVICES,
    link: AirlineType.UA + '|',
  },
  {
    key: 'Gold',
    name: 'Gold',
    value: AirlineStatusType.GOLD,
    link:
      AirlineType.AY +
      '|' +
      AirlineType.A3 +
      '|' +
      AirlineType.SU +
      '|' +
      AirlineType.AR +
      '|' +
      AirlineType.AM +
      '|' +
      AirlineType.CA +
      '|' +
      AirlineType.AF +
      '|' +
      AirlineType.NZ +
      '|' +
      AirlineType.AA +
      '|' +
      AirlineType.OZ +
      '|' +
      AirlineType.AV +
      AirlineType.BA +
      '|' +
      AirlineType.CX +
      '|' +
      AirlineType.CI +
      '|' +
      AirlineType.MU +
      '|' +
      AirlineType.CM +
      '|' +
      AirlineType.MS +
      '|' +
      AirlineType.EK +
      '|' +
      AirlineType.ET +
      '|' +
      AirlineType.EY +
      '|' +
      AirlineType.BR +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.IB +
      '|' +
      AirlineType.KQ +
      '|' +
      AirlineType.KL +
      '|' +
      AirlineType.MH +
      '|' +
      AirlineType.ME +
      '|' +
      AirlineType.QR +
      '|' +
      AirlineType.QF +
      '|' +
      AirlineType.ZH +
      '|' +
      AirlineType.SA +
      '|' +
      AirlineType.UL +
      '|' +
      AirlineType.RO +
      '|' +
      AirlineType.TG +
      '|' +
      AirlineType.UA +
      '|' +
      AirlineType.VN +
      '|' +
      AirlineType.AT +
      '|' +
      AirlineType.SK +
      '|' +
      AirlineType.SV +
      '|' +
      AirlineType.TP +
      '|' +
      AirlineType.TU +
      '|' +
      AirlineType.MF +
      '|',
  },
  {
    key: 'Gold Medallion',
    name: 'Gold Medallion',
    value: AirlineStatusType.GOLD_MEDALLION,
    link: AirlineType.DL + '|',
  },
  {
    key: 'Golden Edge Club',
    name: 'Golden Edge Club',
    value: AirlineStatusType.GOLDEN_EDGE_CLUB,
    link: AirlineType.AI + '|',
  },
  {
    key: 'Green',
    name: 'Green',
    value: AirlineStatusType.GREEN,
    link: AirlineType.EI + '|' + AirlineType.CX + '|' + AirlineType.BR + '|',
  },
  {
    key: 'HON Circle',
    name: 'HON Circle',
    value: AirlineStatusType.HON_CIRCLE,
    link:
      AirlineType.EN +
      '|' +
      AirlineType.OS +
      '|' +
      AirlineType.SN +
      '|' +
      AirlineType.OU +
      '|' +
      AirlineType.LO +
      '|' +
      AirlineType.LH +
      '|' +
      AirlineType.LX +
      '|',
  },
  {
    key: 'JMB Crystal',
    name: 'JMB Crystal',
    value: AirlineStatusType.JMB_CRYSTAL,
    link: AirlineType.JL + '|',
  },
  {
    key: 'JMB Diamond',
    name: 'JMB Crystal',
    value: AirlineStatusType.JMB_DIAMOND,
    link: AirlineType.JL + '|',
  },
  {
    key: 'JGC Premier',
    name: 'JMB Crystal',
    value: AirlineStatusType.JGC_PREMIER,
    link: AirlineType.JL + '|',
  },
  {
    key: 'JMB Sapphire',
    name: 'JMB Crystal',
    value: AirlineStatusType.JMB_SAPPHIRE,
    link: AirlineType.JL + '|',
  },
  {
    key: 'KrisFlyer',
    name: 'KrisFlyer',
    value: AirlineStatusType.KRISFLYER,
    link: AirlineType.SQ + '|',
  },
  {
    key: 'Elite Plus',
    name: 'Elite Plus',
    value: AirlineStatusType.ELITE_PLUS,
    link: AirlineType.TK + '|',
  },
  {
    key: 'Elite Silver',
    name: 'Elite Silver',
    value: AirlineStatusType.ELITE_SILVER,
    link: AirlineType.SQ + '|',
  },
  {
    key: 'Elite Gold',
    name: 'Elite Gold',
    value: AirlineStatusType.ELITE_GOLD,
    link: AirlineType.SQ + '|',
  },
  {
    key: 'Member',
    name: 'Member',
    value: AirlineStatusType.MEMBER,
    link:
      AirlineType.WN +
      '|' +
      AirlineType.AC +
      '|' +
      AirlineType.AY +
      '|' +
      AirlineType.A3 +
      '|' +
      AirlineType.SU +
      '|' +
      AirlineType.AR +
      '|' +
      AirlineType.EI +
      '|' +
      AirlineType.AM +
      '|' +
      AirlineType.CA +
      '|' +
      AirlineType.EN +
      '|' +
      AirlineType.UX +
      '|' +
      AirlineType.AF +
      '|' +
      AirlineType.AI +
      '|' +
      AirlineType.NZ +
      '|' +
      AirlineType.AS +
      '|' +
      AirlineType.AZ +
      '|' +
      AirlineType.AA +
      '|' +
      AirlineType.OZ +
      '|' +
      AirlineType.OS +
      '|' +
      AirlineType.AV +
      AirlineType.BA +
      '|' +
      AirlineType.SN +
      '|' +
      AirlineType.CX +
      '|' +
      AirlineType.CI +
      '|' +
      AirlineType.MU +
      '|' +
      AirlineType.CM +
      '|' +
      AirlineType.OU +
      '|' +
      AirlineType.OK +
      '|' +
      AirlineType.DL +
      '|' +
      AirlineType.MS +
      '|' +
      AirlineType.EK +
      '|' +
      AirlineType.ET +
      '|' +
      AirlineType.EY +
      '|' +
      AirlineType.BR +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.IB +
      '|' +
      AirlineType.KQ +
      '|' +
      AirlineType.KL +
      '|' +
      AirlineType.MH +
      '|' +
      AirlineType.ME +
      '|' +
      AirlineType.QR +
      '|' +
      AirlineType.QF +
      '|' +
      AirlineType.ZH +
      '|' +
      AirlineType.SA +
      '|' +
      AirlineType.UL +
      '|' +
      AirlineType.RO +
      '|' +
      AirlineType.TG +
      '|' +
      AirlineType.UA +
      '|' +
      AirlineType.VN +
      '|' +
      AirlineType.JL +
      '|' +
      AirlineType.B6 +
      '|' +
      AirlineType.KE +
      '|' +
      AirlineType.LO +
      '|' +
      AirlineType.LH +
      '|' +
      AirlineType.PR +
      '|' +
      AirlineType.AT +
      '|' +
      AirlineType.RJ +
      '|' +
      AirlineType.S7 +
      '|' +
      AirlineType.SK +
      '|' +
      AirlineType.SV +
      '|' +
      AirlineType.SQ +
      '|' +
      AirlineType.WN +
      '|' +
      AirlineType.LX +
      '|' +
      AirlineType.TP +
      '|' +
      AirlineType.TU +
      '|' +
      AirlineType.TK +
      '|' +
      AirlineType.MF +
      '|',
  },
  {
    key: 'Morning Calm Club',
    name: 'Morning Calm Club',
    value: AirlineStatusType.MORNING_CALM_CLUB,
    link: AirlineType.KE + '|',
  },
  {
    key: 'Morning Calm Premium Club',
    name: 'Morning Calm Premium Club',
    value: AirlineStatusType.MORNING_CALM_PREMIUM_CLUB,
    link: AirlineType.KE + '|',
  },
  {
    key: 'Million Miler Club',
    name: 'Million Miler Club',
    value: AirlineStatusType.MILLION_MILER_CLUB,
    link: AirlineType.KE + '|',
  },
  {
    key: 'Mosaic',
    name: 'Mosaic',
    value: AirlineStatusType.MOSAIC,
    link: AirlineType.B6 + '|',
  },
  { key: 'MVP', name: 'MVP', value: AirlineStatusType.MVP, link: AirlineType.AS + '|' },
  {
    key: 'MVP Gold',
    name: 'MVP Gold',
    value: AirlineStatusType.MVP_GOLD,
    link: AirlineType.AS + '|',
  },
  {
    key: 'MVP Gold 75',
    name: 'MVP Gold 75',
    value: AirlineStatusType.MVP_GOLD_75,
    link: AirlineType.AS + '|',
  },
  {
    key: 'OK Plus Gold',
    name: 'OK Plus Gold',
    value: AirlineStatusType.OK_PLUS_GOLD,
    link: AirlineType.OK + '|',
  },
  {
    key: 'OK Plus Platinum',
    name: 'OK Plus Platinum',
    value: AirlineStatusType.OK_PLUS_PLATINUM,
    link: AirlineType.OK + '|',
  },
  {
    key: 'OK Plus Silver',
    name: 'OK Plus Silver',
    value: AirlineStatusType.OK_PLUS_SILVER,
    link: AirlineType.OK + '|',
  },
  { key: 'One', name: 'One', value: AirlineStatusType.ONE, link: AirlineType.AM + '|' },
  {
    key: 'Paragon',
    name: 'Paragon',
    value: AirlineStatusType.PARAGON,
    link: AirlineType.CI + '|',
  },
  {
    key: 'Platinum',
    name: 'Platinum',
    value: AirlineStatusType.PLATINUM,
    link:
      AirlineType.AY +
      '|' +
      AirlineType.EI +
      '|' +
      AirlineType.SU +
      '|' +
      AirlineType.AR +
      '|' +
      AirlineType.AM +
      '|' +
      AirlineType.CA +
      '|' +
      AirlineType.AF +
      '|' +
      AirlineType.AA +
      '|' +
      AirlineType.NH +
      '|' +
      AirlineType.OZ +
      '|' +
      AirlineType.AV +
      '|' +
      AirlineType.MU +
      '|' +
      AirlineType.CM +
      '|' +
      AirlineType.MS +
      '|' +
      AirlineType.EK +
      '|' +
      AirlineType.ET +
      '|' +
      AirlineType.EY +
      '|' +
      AirlineType.BR +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.IB +
      '|' +
      AirlineType.KQ +
      '|' +
      AirlineType.KL +
      '|' +
      AirlineType.MH +
      '|' +
      AirlineType.ME +
      '|' +
      AirlineType.QR +
      '|' +
      AirlineType.QF +
      '|' +
      AirlineType.ZH +
      '|' +
      AirlineType.SA +
      '|' +
      AirlineType.UL +
      '|' +
      AirlineType.RO +
      '|' +
      AirlineType.TG +
      '|' +
      AirlineType.UA +
      '|' +
      AirlineType.VN +
      '|',
  },

  {
    key: 'Platinum Lumo',
    name: 'Platinum Lumo',
    value: AirlineStatusType.PLATINUM_LUMO,
    link: AirlineType.AY + '|',
  },
  {
    key: 'Platinum Medallion',
    name: 'Platinum Medallion',
    value: AirlineStatusType.PLATINUM_MEDALLION,
    link: AirlineType.DL + '|',
  },
  {
    key: 'Platinum One',
    name: 'Platinum One',
    value: AirlineStatusType.PLATINUM_ONE,
    link: AirlineType.QF + '|',
  },
  {
    key: 'Platinum Pro',
    name: 'Platinum Pro',
    value: AirlineStatusType.PLATINUM_PRO,
    link: AirlineType.AA + '|',
  },
  {
    key: 'Presidents Club',
    name: 'Presidents Club',
    value: AirlineStatusType.PRESIDENTS_CLUB,
    link: AirlineType.ME + '|',
  },
  {
    key: 'Red Plus',
    name: 'Red Plus',
    value: AirlineStatusType.RED_PLUS,
    link: AirlineType.AV + '|',
  },
  {
    key: 'Residential',
    name: 'Residential',
    value: AirlineStatusType.RESIDENTIAL,
    link: AirlineType.CM + '|',
  },
  {
    key: 'S7 Priority Gold',
    name: 'S7 Priority Gold',
    value: AirlineStatusType.S7_PRIORITY_GOLD,
    link: AirlineType.S7 + '|',
  },
  {
    key: 'S7 Priority Platinum',
    name: 'S7 Priority Platinum',
    value: AirlineStatusType.S7_PRIORITY_PLATINUM,
    link: AirlineType.S7 + '|',
  },
  {
    key: 'S7 Priority Silver',
    name: 'S7 Priority Silver',
    value: AirlineStatusType.S7_PRIORITY_SILVER,
    link: AirlineType.S7 + '|',
  },
  {
    key: 'Senator',
    name: 'Senator',
    value: AirlineStatusType.SENATOR,
    link:
      AirlineType.EN +
      '|' +
      AirlineType.OS +
      '|' +
      AirlineType.SN +
      '|' +
      AirlineType.OU +
      '|' +
      AirlineType.LO +
      '|' +
      AirlineType.LH +
      '|' +
      AirlineType.LX +
      '|',
  },
  {
    key: 'Silver',
    name: 'Silver',
    value: AirlineStatusType.SILVER,
    link:
      AirlineType.AY +
      '|' +
      AirlineType.A3 +
      '|' +
      AirlineType.EI +
      '|' +
      AirlineType.SU +
      '|' +
      AirlineType.CA +
      '|' +
      AirlineType.AF +
      '|' +
      AirlineType.NZ +
      '|' +
      AirlineType.AV +
      AirlineType.BA +
      '|' +
      AirlineType.CX +
      '|' +
      AirlineType.MU +
      '|' +
      AirlineType.CM +
      '|' +
      AirlineType.MS +
      '|' +
      AirlineType.EK +
      '|' +
      AirlineType.ET +
      '|' +
      AirlineType.EY +
      '|' +
      AirlineType.BR +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.IB +
      '|' +
      AirlineType.KQ +
      '|' +
      AirlineType.KL +
      '|' +
      AirlineType.MH +
      '|' +
      AirlineType.ME +
      '|' +
      AirlineType.QR +
      '|' +
      AirlineType.QF +
      '|' +
      AirlineType.ZH +
      '|' +
      AirlineType.SA +
      '|' +
      AirlineType.UL +
      '|' +
      AirlineType.RO +
      '|' +
      AirlineType.TG +
      '|' +
      AirlineType.UA +
      '|' +
      AirlineType.VN +
      '|' +
      AirlineType.AT +
      '|' +
      AirlineType.SK +
      '|' +
      AirlineType.SV +
      '|' +
      AirlineType.TP +
      '|' +
      AirlineType.TU +
      '|' +
      AirlineType.MF +
      '|',
  },
  {
    key: 'Silver Edge Club',
    name: 'Silver Edge Club',
    value: AirlineStatusType.SILVER_EDGE_CLUB,
    link: AirlineType.AI + '|',
  },
  {
    key: 'Silver JAY',
    name: 'Silver JAY',
    value: AirlineStatusType.SILVER_JAY,
    link: AirlineType.RJ + '|',
  },
  {
    key: 'Gold SPARROW',
    name: 'Gold SPARROW',
    value: AirlineStatusType.GOLD_SPARROW,
    link: AirlineType.RJ + '|',
  },
  {
    key: 'Platinum HAWK',
    name: 'Platinum HAWK',
    value: AirlineStatusType.PLATINUM_HAWK,
    link: AirlineType.RJ + '|',
  },

  {
    key: 'Silver Medallion',
    name: 'Silver Medallion',
    value: AirlineStatusType.SILVER_MEDALLION,
    link: AirlineType.DL + '|',
  },
  {
    key: 'SKYPASS',
    name: 'SKYPASS',
    value: AirlineStatusType.SKYPASS,
    link: AirlineType.KE + '|',
  },
  {
    key: 'Suma Gold',
    name: 'Suma Gold',
    value: AirlineStatusType.SUMA_GOLD,
    link: AirlineType.UX + '|',
  },
  {
    key: 'Suma Platinum',
    name: 'Suma Platinum',
    value: AirlineStatusType.SUMA_PLATINUM,
    link: AirlineType.UX + '|',
  },
  {
    key: 'Suma Silver',
    name: 'Suma Silver',
    value: AirlineStatusType.SUMA_SILVER,
    link: AirlineType.UX + '|',
  },
  {
    key: 'Super Elite',
    name: 'Super Elite',
    value: AirlineStatusType.SUPER_ELITE,
    link: AirlineType.AC + '|',
  },
  {
    key: 'Premier Elite',
    name: 'Premier Elite',
    value: AirlineStatusType.PREMIER_ELITE,
    link: AirlineType.PR + '|',
  },
  {
    key: 'Super Flyer',
    name: 'Super Flyer',
    value: AirlineStatusType.SUPER_FLYER,
    link: AirlineType.NH + '|',
  },
  {
    key: 'Titanium',
    name: 'Titanium',
    value: AirlineStatusType.TITANIUM,
    link: AirlineType.AM + '|' + AirlineType.VN + '|' + AirlineType.MF + '|',
  },
  {
    key: 'The Maharajah Club',
    name: 'The Maharajah Club',
    value: AirlineStatusType.THE_MAHARAJAH_CLUB,
    link: AirlineType.AI + '|',
  },
  {
    key: 'Ulisse',
    name: 'Ulisse',
    value: AirlineStatusType.ULISSE,
    link: AirlineType.AZ + '|',
  },
];

export const TravelDocumentSubTypeOptions = [
  {
    value: UserTravelDocumentSubTypeType.TOURIST,
    name: i18n.t(`common.${UserTravelDocumentSubTypeType.TOURIST}`, { defaultValue: 'Tourist' }),
  },
  {
    value: UserTravelDocumentSubTypeType.RESIDENCE_PERMIT,
    name: i18n.t(`common.${UserTravelDocumentSubTypeType.RESIDENCE_PERMIT}`, {
      defaultValue: 'Residence permit',
    }),
  },
  {
    value: UserTravelDocumentSubTypeType.BUSINESS,
    name: i18n.t(`common.${UserTravelDocumentSubTypeType.BUSINESS}`, { defaultValue: 'Business' }),
  },
  {
    value: UserTravelDocumentSubTypeType.OTHER,
    name: i18n.t(`common.${UserTravelDocumentSubTypeType.OTHER}`, { defaultValue: 'Other' }),
  },
];

export const AirlineAllianceTypeList = [
  {
    key: 'StarAlliance',
    name: 'StarAlliance',
    value: AirlineAllianceType.STAR_ALLIANCE,
    link:
      AirlineType.A3 +
      '|' +
      AirlineType.AC +
      '|' +
      AirlineType.CA +
      '|' +
      AirlineType.EN +
      '|' +
      AirlineType.AI +
      '|' +
      AirlineType.NZ +
      '|' +
      AirlineType.NH +
      '|' +
      AirlineType.OZ +
      '|' +
      AirlineType.OS +
      '|' +
      AirlineType.AV +
      '|' +
      AirlineType.SN +
      '|' +
      AirlineType.CM +
      '|' +
      AirlineType.OU +
      '|' +
      AirlineType.MS +
      '|' +
      AirlineType.ET +
      '|' +
      AirlineType.BR +
      '|' +
      AirlineType.LO +
      '|' +
      AirlineType.LH +
      '|' +
      AirlineType.SK +
      '|' +
      AirlineType.ZH +
      '|' +
      AirlineType.SQ +
      '|' +
      AirlineType.SA +
      '|' +
      AirlineType.LX +
      '|' +
      AirlineType.TP +
      '|' +
      AirlineType.TG +
      '|' +
      AirlineType.TK +
      '|' +
      AirlineType.UA +
      '|',
  },
  {
    key: 'SkyTeam',
    name: 'SkyTeam',
    value: AirlineAllianceType.SKY_TEAM,
    link:
      AirlineType.SU +
      '|' +
      AirlineType.AR +
      '|' +
      AirlineType.AM +
      '|' +
      AirlineType.UX +
      '|' +
      AirlineType.AF +
      '|' +
      AirlineType.AZ +
      '|' +
      AirlineType.CI +
      '|' +
      AirlineType.MU +
      '|' +
      AirlineType.OK +
      '|' +
      AirlineType.DL +
      '|' +
      AirlineType.GA +
      '|' +
      AirlineType.KQ +
      '|' +
      AirlineType.KL +
      '|' +
      AirlineType.KE +
      '|' +
      AirlineType.ME +
      '|' +
      AirlineType.SV +
      '|' +
      AirlineType.RO +
      '|' +
      AirlineType.VN +
      '|' +
      AirlineType.MF +
      '|',
  },
  {
    key: 'OneWorld',
    name: 'OneWorld',
    value: AirlineAllianceType.ONE_WORLD,
    link:
      AirlineType.AS +
      '|' +
      AirlineType.AA +
      '|' +
      AirlineType.BA +
      '|' +
      AirlineType.CX +
      '|' +
      AirlineType.AY +
      '|' +
      AirlineType.IB +
      '|' +
      AirlineType.JL +
      '|' +
      AirlineType.MH +
      '|' +
      AirlineType.QR +
      '|' +
      AirlineType.QF +
      '|' +
      AirlineType.AT +
      '|' +
      AirlineType.RJ +
      '|' +
      AirlineType.S7 +
      '|' +
      AirlineType.UL +
      '|',
  },
];

export const AirlineAllianceStatusTypeList = [
  {
    key: 'OneWorld Emerald',
    name: 'OneWorld Emerald',
    value: AirlineAllianceStatusType.ONEWORLD_EMERALD,
    link: AirlineAllianceType.ONE_WORLD,
  },
  {
    key: 'OneWorld Ruby',
    name: 'OneWorld Ruby',
    value: AirlineAllianceStatusType.ONEWORLD_RUBY,
    link: AirlineAllianceType.ONE_WORLD,
  },
  {
    key: 'OneWorld Sapphire',
    name: 'OneWorld Sapphire',
    value: AirlineAllianceStatusType.ONEWORLD_SAPPHIRE,
    link: AirlineAllianceType.ONE_WORLD,
  },
  {
    key: 'SkyTeam Elite',
    name: 'SkyTeam Elite',
    value: AirlineAllianceStatusType.SKYTEAM_ELITE,
    link: AirlineAllianceType.SKY_TEAM,
  },
  {
    key: 'SkyTeam Elite Plus',
    name: 'SkyTeam Elite Plus',
    value: AirlineAllianceStatusType.SKYTEAM_ELITE_PLUS,
    link: AirlineAllianceType.SKY_TEAM,
  },
  {
    key: 'Star Alliance Gold',
    name: 'Star Alliance Gold',
    value: AirlineAllianceStatusType.STAR_ALLIANCE_GOLD,
    link: AirlineAllianceType.STAR_ALLIANCE,
  },
  {
    key: 'Star Alliance Silver',
    name: 'Star Alliance Silver',
    value: AirlineAllianceStatusType.STAR_ALLIANCE_SILVER,
    link: AirlineAllianceType.STAR_ALLIANCE,
  },
  {
    key: 'None',
    name: 'None',
    value: AirlineAllianceStatusType.NONE,
    link:
      AirlineAllianceType.STAR_ALLIANCE +
      '|' +
      AirlineAllianceType.SKY_TEAM +
      '|' +
      AirlineAllianceType.ONE_WORLD +
      '|',
  },
];

export const HealthTestTypeList = [
  { name: 'Antigen', value: HealthTestType.ANTIGEN },
  { name: 'PCR', value: HealthTestType.PCR },
  { name: 'PCR Rapid', value: HealthTestType.PCR_RAPID },
  { name: 'Rapid', value: HealthTestType.RAPID },
];
export const jobTypeOfDeliveryList = (t: any) => [
  {
    value: JobTypeOfDelivery.DOOR_DOOR,
    name: t('jobs.doorToDoor', { defaultValue: 'Door to Door' }),
  },
  {
    value: JobTypeOfDelivery.AIRPORT_DOOR,
    name: t('jobs.airportToDoor', { defaultValue: 'Airport to Door' }),
  },
  {
    value: JobTypeOfDelivery.DOOR_AIRPORT,
    name: t('jobs.doorToAirport', { defaultValue: 'Door to Airport' }),
  },
  {
    value: JobTypeOfDelivery.AIRPORT_AIRPORT,
    name: t('jobs.airportToAirport', { defaultValue: 'Airport to Airport' }),
  },
  {
    value: 'PICKUP_ADDRESS',
    name: t('jobs.doorToDoor', { defaultValue: 'Door to Door' }),
  },
  {
    value: 'HANDOVER_DEPARTURE_AIRPORT',
    name: t('jobs.doorToAirport', { defaultValue: 'Door to Airport' }),
  },
];

export const CurrencyTypeList = [
  { value: Currency.EUR, name: 'Euro' },
  {
    value: Currency.USD,
    name: 'United States Dollar',
  },
];

// Uses the Expo Contacts Contat and extends it with a state (loaded (default), invited, or imported).
export type AppUserContact = Contacts.Contact & {
  name: string;
  state?: 'loaded' | 'invited' | 'imported';
};

export const minutes = ['00', '15', '30', '45'];

export const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

// Custom Types for generated-graphql-types.ts (Auto-generated Apollo GraphQL Types)

export type JSONObject =
  | string
  | number
  | boolean
  | { [x: string]: JSONObject }
  | Array<JSONObject>;

export type DevicePermissionExpiration = 'never' | number;

export interface ITranslate {
  translate: ReturnType<typeof useTranslation>['t'];
}
