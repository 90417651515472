// import { ApolloLink } from '@apollo/client';
// import { ApolloLink, createHttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import Constants from 'expo-constants';

export const httpLink = createUploadLink({
  uri: Constants.expoConfig?.extra?.priojetBackendGraphqlApiEndpoint,
  credentials: 'include',
}) as any;
// using the BatchHttpLink instead (batching queries within 10 ms)
/* export const httpLink: ApolloLink = new BatchHttpLink({
  uri: Constants.expoConfig.extra?.priojetBackendGraphqlApiEndpoint,
  credentials: 'include',
  batchInterval: undefined,
  batchMax: undefined,
}); */
