import { useCallback, useEffect, useRef, useState } from 'react';
import { BackHandler, NativeEventSubscription } from 'react-native';

import { DateTime } from 'luxon';

export const useBackHandler = (cb: any): void => {
  const backHandlerRef = useRef<NativeEventSubscription | null>(null);

  const [back, setBack] = useState(DateTime.now());

  const handleBackPress = useCallback(() => {
    if (back.diffNow().toMillis() > 500) {
      cb();
      setBack(DateTime.now());
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [back]);

  useEffect((): any => {
    backHandlerRef.current = BackHandler.addEventListener('hardwareBackPress', handleBackPress);
    return () => backHandlerRef.current?.remove();
  }, [handleBackPress]);
};
