import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { useMutation } from '@apollo/client';
import { Button, Divider, StyleService, Text } from '@ui-kitten/components';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';

import { CREATE_USER_BACKGROUND_DOCUMENT_FRAGMENT } from '../../apollo/graphql-fragments';
import { CREATE_USER_BACKGROUND_DOCUMENT_MUTATION } from '../../apollo/graphql-mutations';
import { CountryListData } from '../../assets/constants/country.list.data';
import { globalStyle } from '../../common/style';
import {
  CreateUserBackgroundDocumentVariables,
  UserBackgroundDocumentInput,
  User_user,
} from '../../graphql-types';
import { UserDocument } from '../../types';
import { uploadDocumentsToServer } from '../../utils/file-upload.util';
import { CourierAddDocumentFile } from '../common/courier.add-document-image-pdf';
import { FormAutocomplete } from '../common/form/form-autocomplete.component';
import { FormTextDatepicker } from '../common/form/form-text-datepicker.component';
import { FormTextInputTopLabel } from '../common/form/form-text-input-top-label.component';
import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const now = new Date();
const minimumBirthDay = new Date(1900, 1, 1);
const maximumBirthDay = new Date(2100, 1, 1);

export const CourierAddUserBackgroundDocumentComponent = ({
  navigation,
  title,
}: {
  navigation: any;
  title: string;
  user?: User_user;
}): React.ReactElement => {
  const { t } = useTranslation();

  const [hasOnlyFrontPage, setHasOnlyFrontPage] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [uploadUserBackgroundDocument] = useMutation<
    { createUserBackgroundDocument: UserDocument },
    CreateUserBackgroundDocumentVariables
  >(CREATE_USER_BACKGROUND_DOCUMENT_MUTATION, {
    async onCompleted(data) {
      if (data.createUserBackgroundDocument) {
        showMessage({
          message: t('document.success', { defaultValue: 'Success' }),
          description: t('document.documentCreatedSuccessfully', {
            defaultValue: 'Document created successfully.',
          }) as string,
          type: 'success',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
        navigation.navigate('CourierProfileNavigator', {
          screen: 'CourierBackgroundDocumentsScreen',
        });
      } else {
        showMessage({
          message: t('document.error', { defaultValue: 'Error' }),
          description: t('document.somethingWentWrongWithSaving', {
            defaultValue: 'Sorry, something went wrong with saving document.',
          }) as string,
          type: 'danger',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    },
    update(cache, { data }) {
      if (data?.createUserBackgroundDocument) {
        cache.modify({
          fields: {
            userBackgroundDocuments(existingUserBackgroundDocuments = []) {
              const newUserBackgroundDocument = cache.writeFragment({
                data: data.createUserBackgroundDocument,
                fragment: CREATE_USER_BACKGROUND_DOCUMENT_FRAGMENT,
              });
              return [...existingUserBackgroundDocuments, newUserBackgroundDocument];
            },
          },
        });
      }
    },
    onError() {
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    },
  });

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('document.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description:
          Object.keys(errors).length > 5
            ? (t('document.multipleInvalidField', {
                defaultValue:
                  'Multiple required fields are not filled out properly. Please check and try again.',
              }) as string)
            : Object.keys(errors)
                .map((key) => errors[key])
                .join('\n'),
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const { values } = formik;
    let userBackgroundDocumentInput: UserBackgroundDocumentInput = {
      name: values.name,
      issuingInstitution: values.issuingInstitution,
      dateOfIssue: values.dateOfIssue,
      expiresOn: values.expiresOn,
      countryOfIssue: values.countryOfIssue,
    };

    setIsSaving(true);
    let documentsToUpload = [
      { key: 'front', fieldKey: 'Front', document: formik.values.documentFront },
    ];
    if (formik.values.documentBack) {
      documentsToUpload.push({
        key: 'back',
        fieldKey: 'Back',
        document: formik.values.documentBack,
      });
    }
    try {
      const documentRes = await uploadDocumentsToServer(documentsToUpload);
      if (documentRes?.length) {
        documentRes.forEach((item) => {
          userBackgroundDocumentInput = { ...userBackgroundDocumentInput, ...item };
        });
        await uploadUserBackgroundDocument({
          variables: { userBackgroundDocumentInput },
        });
      }
    } catch (e) {
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    setIsSaving(false);
  };

  const validationSchema = Yup.object().shape({
    documentFront: Yup.object()
      .nullable()
      .required(
        t('document.requiredFieldDocumentFront', {
          defaultValue: 'Front side of the document must be set',
        }) as string,
      ),
    documentBack: !hasOnlyFrontPage
      ? Yup.object()
          .nullable()
          .required(
            t('document.requiredFieldDocumentBack', {
              defaultValue: 'Back side of the document must be set',
            }) as string,
          )
      : Yup.object().nullable(),
    name: Yup.string().required(
      t('document.requiredFieldName', {
        defaultValue: 'Name must be set',
      }) as string,
    ),
    issuingInstitution: Yup.string().required(
      t('document.requiredFieldDocumentIssuingInstitution', {
        defaultValue: 'Issuing institution must be set',
      }) as string,
    ),
    dateOfIssue: Yup.string()
      .nullable()
      .required(
        t('document.requiredFieldDateOfIssue', {
          defaultValue: 'Date of issue must be set',
        }) as string,
      ),
    expiresOn: Yup.string()
      .nullable()
      .required(
        t('document.requiredFieldDateOfExpiry', {
          defaultValue: 'Date of expiry must be set',
        }) as string,
      ),
    countryOfIssue: Yup.string().required(
      t('document.requiredFieldCountryOfIssue', {
        defaultValue: 'Country of issue must be set',
      }) as string,
    ),
  });

  const initialValues = {
    documentFront: null,
    documentBack: null,
    name: '',
    issuingInstitution: '',
    expiresOn: null,
    dateOfIssue: null,
    countryOfIssue: '',
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const handleSelectDocument = (side: 'front' | 'back', result: any) => {
    if (side === 'front') {
      formik?.setFieldValue('documentFront', result);
    } else {
      formik?.setFieldValue('documentBack', result);
    }
  };

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);
    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: formik.touched[field] ? fieldError : '',
        onChange,
        status: formik.touched[field] && fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      errorMessage: fieldError || '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const handleBackPageChange = () => {
    setHasOnlyFrontPage((prev) => {
      if (!prev) {
        formik.setFieldValue('documentBack', null);
      }
      return !prev;
    });
    formik.validateField('documentFront');
    formik.validateField('documentBack');
  };

  const onSelect =
    ({ valueKey }: any) =>
    (value: any) => {
      formik?.setFieldValue(valueKey, value);
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const renderSelectAutocomplete = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormAutocomplete
        appearance="default"
        containerStyle={styles.input}
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey })}
        items={options}
        value={formik.values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={formik?.errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderDatePicker = ({
    label,
    min,
    max,
    valueKey,
  }: {
    label: string;
    min: any;
    max: any;
    valueKey: string;
  }) => (
    <FormTextDatepicker
      label={label}
      containerStyle={styles.input}
      min={min}
      max={max}
      date={formik.values[valueKey] && formik.values[valueKey]?.toJSDate()}
      onChange={(value) => {
        formik?.setFieldValue(valueKey, DateTime.fromJSDate(value));
        setTimeout(() => {
          if (formik?.errors[valueKey]) {
            formik.validateField(valueKey);
          }
        }, 100);
      }}
      errorMessage={formik?.errors[valueKey]}
      isMandatory
    />
  );

  return (
    <>
      <TopNavigationWithBackButton
        accessoryRight={() => (
          <Button onPress={handleSave} appearance="filled" style={styles.saveBtn}>
            {isSaving ? (
              <ActivityIndicator />
            ) : (
              (t('common.save', { defaultValue: 'Save' }) as string)
            )}
          </Button>
        )}
        title={title}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierProfileNavigator', {
            screen: 'CourierBackgroundDocumentsScreen',
          });
        }}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={styles.contentContainer}
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
      >
        <Text style={styles.descriptionText}>
          {
            t('documents.backgroundDescriptionText', {
              defaultValue:
                'Add official documents here that refers to background checks for identity verification processes. i.e. Police Clearance Certificates',
            }) as string
          }
        </Text>
        <CourierAddDocumentFile
          allowBackPage
          documentBack={formik.values.documentBack}
          documentFront={formik.values.documentFront}
          hasBackPage={!hasOnlyFrontPage}
          onBackPageChange={handleBackPageChange}
          onSelectDocument={handleSelectDocument}
        />
        <Divider style={globalStyle.marginBottom10} />

        <View style={globalStyle.padding20}>
          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={
              t('document.nameOfTheDocument', { defaultValue: 'NAME OF THE DOCUMENT' }) as string
            }
            isMandatory
            {...fieldProps('name')}
          />
          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={
              t('document.issuingInstitution', { defaultValue: 'ISSUING INSTITUTION' }) as string
            }
            isMandatory
            {...fieldProps('issuingInstitution')}
          />

          {renderDatePicker({
            label: t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }) as string,
            min: minimumBirthDay,
            max: now,
            valueKey: 'dateOfIssue',
          })}
          {renderDatePicker({
            label: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }) as string,
            min: now,
            max: maximumBirthDay,
            valueKey: 'expiresOn',
          })}

          {renderSelectAutocomplete({
            label: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }) as string,
            valueKey: 'countryOfIssue',
            options: CountryListData,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}
        </View>
      </KeyboardAwareScrollView>
    </>
  );
};
const styles = StyleService.create({
  scrollView: {},
  descriptionText: {
    fontSize: 14,
    paddingHorizontal: 12,
    paddingBottom: 10,
    opacity: 0.9,
  },
  contentContainer: {
    paddingVertical: 24,
  },

  input: {
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  saveBtn: {
    height: 44,
    width: 75,
  },
});
