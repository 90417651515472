import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import * as ExpoLocation from 'expo-location';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import * as Sentry from 'sentry-expo';

import { DeviceLocation, DeviceLocationInput } from '../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../globals';
import { addDeviceLocation } from '../modules/device-location/device-location.module';

type WatchPositionSubscriberType = {
  remove: () => void;
};

export const useLocationMonitoring = (): {
  location: ExpoLocation.LocationObject | null;
  lastDeviceLocationWithLocation: Partial<DeviceLocation> | null;
  startLocationMonitoring: () => Promise<void>;
  initializeLocationMonitoring: () => Promise<void>;
  deInitializeLocationMonitoring: () => Promise<void>;
  watchPositionSubscriber: WatchPositionSubscriberType | undefined;
} => {
  const [watchPositionSubscriber, setWatchPositionSubscriber] =
    useState<WatchPositionSubscriberType>();

  const [location, setLocation] = useState<ExpoLocation.LocationObject | null>(null);
  const [lastDeviceLocationWithLocation, setLastDeviceLocationWithLocation] =
    useState<Partial<DeviceLocation> | null>(null);
  const [lastDeviceLocationDate, setLastDeviceLocationDate] = useState<DateTime | null>(null);

  useEffect(() => {
    return () => watchPositionSubscriber?.remove();
  }, [watchPositionSubscriber]);

  const addDeviceLocationCallback: ExpoLocation.LocationCallback = async (
    _location: ExpoLocation.LocationObject,
  ) => {
    if (
      !lastDeviceLocationDate ||
      DateTime.now().toSeconds() - lastDeviceLocationDate.toSeconds() > 10
    ) {
      if (_location?.coords) {
        setLocation(_location);
        setLastDeviceLocationDate(DateTime.now());
        try {
          const res = await addDeviceLocation({
            accuracy: _location.coords.accuracy,
            altitude: _location.coords.altitude,
            altitudeAccuracy: _location.coords.altitudeAccuracy,
            heading: _location.coords.heading,
            latitude: _location.coords.latitude,
            longitude: _location.coords.longitude,
            speed: _location.coords.latitude,
            timestampInMs: _location.timestamp,
          } as DeviceLocationInput);
          setLastDeviceLocationWithLocation(res);
        } catch (e) {
          console.warn(e);
        }
      }
    }
  };

  const [addDeviceLocationCallbackDebounced] = useState(() =>
    debounce(addDeviceLocationCallback, 1000),
  );

  // Not being used as of 14/06/2022 - probably will be used in the future
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getLocationInBackground = async () => {
    if (watchPositionSubscriber && watchPositionSubscriber.remove) {
      try {
        watchPositionSubscriber.remove(); // remove the foreground location subscriber if it exists.
      } catch (error) {
        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.Native.captureException(error);
        }
      }
    }
    if (
      !(await ExpoLocation.hasStartedLocationUpdatesAsync(
        GLOBAL_CONSTANTS.BACKGROUND_LOCATION_TASK_NAME,
      ))
    ) {
      ExpoLocation.startLocationUpdatesAsync(GLOBAL_CONSTANTS.BACKGROUND_LOCATION_TASK_NAME, {
        accuracy: ExpoLocation.LocationAccuracy.Balanced,
        timeInterval: 1000 * 10 * 5, // Location updates every 5 minutes.
        distanceInterval: 1000,
        pausesUpdatesAutomatically: true,
      });
    }
  };

  const createWatchPositionAsyncSubscription = async () => {
    if (Platform.OS === 'web') {
      if (navigator.geolocation) {
        await navigator.geolocation.watchPosition(async (_location) => {
          if (_location?.coords?.latitude) {
            await addDeviceLocationCallback({
              coords: _location.coords,
              timestamp: _location.timestamp,
            });
          }
        });
      }
    } else {
      ExpoLocation.watchPositionAsync(
        {
          accuracy: ExpoLocation.LocationAccuracy.BestForNavigation,
          timeInterval: 1000 * 60, // Location updates every 60 seconds.
          distanceInterval: 1000,
        },
        addDeviceLocationCallbackDebounced,
      )
        .then((_watchPositionSubscriber) => {
          setWatchPositionSubscriber(_watchPositionSubscriber);
        })
        .catch((error) => {
          if (Platform.OS === 'web') {
            Sentry.Browser.captureException(error);
          } else {
            Sentry.Native.captureException(error);
          }
        });
    }
  };

  const getAndProcessCurrentLocation = async () => {
    if (Platform.OS === 'web') {
      await navigator.geolocation.getCurrentPosition(async (_location) => {
        if (_location?.coords?.latitude) {
          await addDeviceLocationCallback({
            coords: _location.coords,
            timestamp: _location.timestamp,
          });
        }
      });
    } else {
      const _location = await ExpoLocation.getCurrentPositionAsync({
        accuracy: ExpoLocation.LocationAccuracy.BestForNavigation,
      });
      if (_location) {
        await addDeviceLocationCallback(_location);
      }
    }
  };

  const getLocationInForeground = async (forceCurrentLocation: boolean = true) => {
    if (!watchPositionSubscriber && Platform.OS !== 'web') {
      await createWatchPositionAsyncSubscription();
    }
    if (forceCurrentLocation) {
      await getAndProcessCurrentLocation();
    }
  };

  const getLocation = async () => {
    let foregroundPermissionResponse;
    const hasServicesEnabled = await ExpoLocation.hasServicesEnabledAsync();
    try {
      if (hasServicesEnabled) {
        foregroundPermissionResponse = await ExpoLocation.getForegroundPermissionsAsync();
      } else {
        foregroundPermissionResponse = undefined;
        if (Platform.OS !== 'web') {
          Sentry.Browser.captureMessage('No Location Services available.');
        } else {
          Sentry.Native.captureMessage('No Location Services available.');
        }
      }
    } catch (error) {
      if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error);
      } else {
        Sentry.Native.captureException(error);
      }
      foregroundPermissionResponse = undefined;
    }
    if (foregroundPermissionResponse && foregroundPermissionResponse.granted) {
      await getLocationInForeground();
    } else {
      console.warn('useLocationMonitoring(): No permissions are granted.');
    }
  };

  const startLocationMonitoring = async () => {
    await getLocation();
  };

  const initializeLocationMonitoring = async () => {
    return getLocationInForeground();
  };

  const deInitializeLocationMonitoring = async () => {
    if (watchPositionSubscriber && watchPositionSubscriber.remove) {
      try {
        watchPositionSubscriber.remove(); // remove the foreground location subscriber if it exists.
      } catch (error) {
        if (Platform.OS === 'web') {
          Sentry.Browser.captureException(error);
        } else {
          Sentry.Native.captureException(error);
        }
      }
    }
  };

  return {
    deInitializeLocationMonitoring,
    initializeLocationMonitoring,
    location,
    lastDeviceLocationWithLocation,
    startLocationMonitoring,
    watchPositionSubscriber,
  };
};
