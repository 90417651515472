import React from 'react';
import { Modal, TouchableOpacity } from 'react-native';

import { Icon, StyleService, useStyleSheet } from '@ui-kitten/components';
import Constants from 'expo-constants';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import PDFReader from 'rn-pdf-reader-js';

import { globalStyle } from '../../common/style';

interface PDFViewerProps {
  onClose: () => void;
  uri: string;
  visible: boolean;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ onClose, uri, visible }) => {
  const { top } = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);

  return (
    <Modal animationType="fade" visible={visible} hardwareAccelerated>
      {!!uri && <PDFReader source={{ uri }} style={globalStyle.absoluteFull} />}
      <TouchableOpacity onPress={onClose} style={[styles.closeModal, { top }]}>
        <Icon name="close-outline" fill="black" height={30} width={30} />
      </TouchableOpacity>
    </Modal>
  );
};

const themedStyles = StyleService.create({
  container: {
    borderWidth: 1,
  },
  content: {
    flex: 1,
    paddingTop: Constants.statusBarHeight,
  },
  closeModal: {
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 5,
    borderRadius: 2,
    backgroundColor: 'border-basic-color-3',
  },
});

export default PDFViewer;
