import { Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  flex1: {
    flex: 1,
  },
  font16Bold: {
    fontFamily: 'Lato_700Bold',
    fontSize: 16,
  },
  container: {
    maxWidth: Platform.OS === 'web' ? 600 : 'auto',
  },
  saveButton: {
    marginTop: 20,
  },
  divider: {
    marginVertical: 30,
  },
  inputField: {
    marginVertical: 5,
  },
  inputFieldNoBorder: {
    borderWidth: 0,
    outline: Platform.OS === 'web' ? 'none !important' : 'none',
    outlineWidth: Platform.OS === 'web' ? '0 !important' : '0',
    boxshadow: 'none',
    MozBoxShadow: 'none',
    WebkitBoxShadow: 'none',
    '::-moz-focus-inner': {
      borderWidth: 0,
      outline: Platform.OS === 'web' ? 'none !important' : 'none',
      outlineWidth: Platform.OS === 'web' ? '0 !important' : '0',
      boxshadow: 'none',
      MozBoxShadow: 'none',
      WebkitBoxShadow: 'none',
    },
  },
  captionContainer: {
    paddingTop: 5,
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
  },
  captionText: {
    fontSize: 12,
    paddingLeft: 5,
  },
  inputElement: {
    marginBottom: 20,
  },
  button: {
    maxWidth: Platform.OS === 'web' ? 500 : 'auto',
  },
  fadingContainer: {
    paddingVertical: 20,
  },
  dayContainer: {
    justifyContent: 'center',
    backgroundColor: 'red',
    alignItems: 'center',
  },
  dayValue: {
    paddingTop: 5,
  },
  flexRow: {
    flexDirection: 'row',
  },
  padding5: {
    padding: 5,
  },
  padding10: {
    padding: 10,
  },
  errorMessageText: {
    flex: 1,
    paddingLeft: 5,
    color: 'darkred',
  },
  darkRedText: {
    color: 'darkred',
  },
  fontBold: {
    fontFamily: 'Lato_700Bold',
  },
  locationHeader: {
    flex: 1,
    paddingBottom: 20,
    paddingRight: 25,
    flexDirection: 'row',
  },
  datesHeader: {
    marginTop: 30,
    marginBottom: 15,
    paddingRight: 25,
    flex: 1,
    flexDirection: 'row',
  },
  timeLabelColor: {
    color: 'text-basic-color',
    fontFamily: 'Lato_700Bold',
    fontSize: 15,
  },

  padding15: {
    padding: 15,
  },
  alignCenter: {
    alignItems: 'center',
  },
  googleMap: {
    height: 150,
    marginTop: 20,
  },
  width100: { width: '100%' },
  timeHeader: {
    marginTop: 30,
    marginBottom: 0,
    flex: 1,
    paddingRight: 25,
    flexDirection: 'row',
  },
  errorContainer: {
    marginTop: 10,
    borderColor: '#ee2200',
    borderWidth: 1,
    borderRadius: 5,
    padding: 12,
    backgroundColor: '#ee220011',
  },
  alertContainer: {
    marginTop: 10,
    flexDirection: 'row',
    borderColor: '#ee2200',
    borderWidth: 1,
    borderRadius: 5,
    padding: 8,
    backgroundColor: 'pink',
  },
  alertIcon: {
    height: 20,
    width: 20,
    marginRight: 5,
  },
  font15Black: {
    color: 'black',
    fontSize: 15,
  },
  marginTop10: {
    marginTop: 10,
  },
  selectContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  select: {
    margin: 0,
    marginBottom: 15,
  },
  inputContainerStyle: {
    width: Platform.OS !== 'ios' ? 160 : 'auto',
    marginLeft: Platform.OS === 'web' ? 0 : -12,
  },
  icon: {
    height: 25,
    width: 25,
    marginRight: 10,
  },
  height45: {
    height: 45,
  },
});
