import React from 'react';
import { Platform } from 'react-native';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { Button, Icon } from '@ui-kitten/components';

import { PriojetCreateOrganizationInvitationScreen } from '../screens/priojet/priojet.create-organization-invitation.screen';
import { PriojetEditOrganizationInvitationScreen } from '../screens/priojet/priojet.edit-organization-invitation.screen';
import { PriojetOrganizationInvitationsScreen } from '../screens/priojet/priojet.organization-invitations.screen';

const StackNavigator = createStackNavigator();

const PriojetOrganizationInvitationsNavigator = () => {
  return (
    <StackNavigator.Navigator initialRouteName="PriojetOrganizationInvitationsScreen">
      <StackNavigator.Group>
        <StackNavigator.Screen
          name="PriojetOrganizationInvitationsScreen"
          component={PriojetOrganizationInvitationsScreen}
          options={{
            headerShown: false,
          }}
        />
      </StackNavigator.Group>

      <StackNavigator.Group
        screenOptions={({ navigation }) => ({
          cardShadowEnabled: false,
          headerShown: false,
          cardOverlayEnabled: false,
          presentation: 'transparentModal',
          animationEnabled: true,
          headerRight: () => (
            <Button
              onPress={() => navigation.goBack()}
              appearance="ghost"
              accessoryLeft={<Icon name="close-outline" />}
            />
          ),
        })}
      >
        <StackNavigator.Screen
          options={{
            ...TransitionPresets.ModalSlideFromBottomIOS,
            headerTitle: 'Edit Organization Invitation',
          }}
          name="PriojetEditOrganizationInvitationScreen"
          component={PriojetEditOrganizationInvitationScreen}
        />
        <StackNavigator.Screen
          options={{
            ...TransitionPresets.ModalSlideFromBottomIOS,
            headerTitle: 'Create Organization Invitation',
          }}
          name="PriojetCreateOrganizationInvitationScreen"
          component={PriojetCreateOrganizationInvitationScreen}
        />
      </StackNavigator.Group>
    </StackNavigator.Navigator>
  );
};

export { PriojetOrganizationInvitationsNavigator };
