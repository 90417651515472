import React from 'react';
import { ColorSchemeName } from 'react-native';

export const ThemeContext = React.createContext({
  theme: 'light',
   
  toggleTheme: () => {},
  backgroundColor: '#ffffff',
});

export const ThemeContextProvider = ({
  theme,
  setTheme,
  children,
}: {
  theme: ColorSchemeName;
  setTheme: React.Dispatch<React.SetStateAction<'light' | 'dark' | undefined>>;
  children: any;
}) => {
  const toggleTheme = () => {
    const nextTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(nextTheme);
  };

  const backgroundColor = theme === 'light' ? '#ffffff' : '#222B45';

  return (
    <ThemeContext.Provider
      value={{ theme: theme || 'light', toggleTheme, backgroundColor }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
