import FingerprintJS from '@fingerprintjs/fingerprintjs';
import Constants from 'expo-constants';
import * as ExpoDevice from 'expo-device';

import { DevicePropertiesInput } from '../../graphql-types';

export const getDeviceProperties = async (): Promise<DevicePropertiesInput> => {
  let isRooted = null;
  try {
    isRooted = await ExpoDevice.isRootedExperimentalAsync();
  } catch (e) {
    console.warn(">> couldn't read isRootedExperimentalAsync");
  }

  let platformFeatures = null;
  try {
    platformFeatures = await ExpoDevice.getPlatformFeaturesAsync();
  } catch (e) {
    console.warn(">> couldn't read getPlatformFeaturesAsync");
  }

  let maxMemory = null;
  try {
    maxMemory = await ExpoDevice.getMaxMemoryAsync();
  } catch (e) {
    console.warn(">> couldn't read getMaxMemoryAsync");
  }

  let isSideLoadingEnabled = null;
  try {
    isSideLoadingEnabled = await ExpoDevice.isSideLoadingEnabledAsync();
  } catch (e) {
    console.warn(">> couldn't read isSideLoadingEnabled");
  }

  const fp = await FingerprintJS.load();
  const fpResult = await fp.get();

  let screenResolution: [number | null, number | null] | number[] | undefined;
  try {
    const _screenResolution = fpResult.components.screenResolution.value;
    if (_screenResolution) {
      screenResolution = [];
      if (_screenResolution[0] !== null) {
        screenResolution.push(_screenResolution[0]);
      }
      if (_screenResolution[1] !== null) {
        screenResolution.push(_screenResolution[1]);
      }
    }
  } catch (e) {
    console.warn('screenResolution', e);
  }

  const deviceProperties: DevicePropertiesInput = {
    isDevice: ExpoDevice.isDevice,
    brand: ExpoDevice.brand,
    manufacturer: ExpoDevice.manufacturer,
    modelName: ExpoDevice.modelName,
    modelId: ExpoDevice.modelId,
    designName: ExpoDevice.designName,
    productName: ExpoDevice.productName,
    deviceYearClass: ExpoDevice.deviceYearClass,
    totalMemory: ExpoDevice.totalMemory, // device memory
    supportedCpuArchitectures: ExpoDevice.supportedCpuArchitectures,
    osName: ExpoDevice.osName,
    osVersion: ExpoDevice.osVersion,
    osBuildId: ExpoDevice.osBuildId,
    osInternalBuild: ExpoDevice.osInternalBuildId,
    osBuildFingerprint: ExpoDevice.osBuildFingerprint,
    platformApiLevel: ExpoDevice.platformApiLevel,
    deviceName: ExpoDevice.deviceName,
    maxMemory,
    isRooted,
    platformFeatures,
    isSideLoadingEnabled,
    isLimitAdTrackingEnabled: false,
    userAgent: await Constants.getWebViewUserAgentAsync(),
    webdriver: false,
    languages: fpResult.components.languages.value || null,
    colorDepth: fpResult.components.colorDepth.value || null,
    hardwareConcurrency: fpResult.components.hardwareConcurrency.value || null,
    screenResolution: screenResolution || null,
    timezone: fpResult.components.timezone.value || null,
    sessionStorage: fpResult.components.sessionStorage.value || null,
    localStorage: fpResult.components.localStorage.value || null,
    indexedDb: fpResult.components.indexedDB.value || null,
    openDatabase: fpResult.components.openDatabase.value || null,
    cpuClass: fpResult.components.cpuClass.value || null,
    platform: fpResult.components.platform.value || null,
    canvas: fpResult.components.canvas.value || null,
    audio: fpResult.components.audio.value || null,
    fonts: fpResult.components.fonts.value || null,
    osCpu: fpResult.components.osCpu.value || null,
    plugins: fpResult.components.plugins.value || null,
    touchSupport: fpResult.components.touchSupport.value || null,
    vendor: fpResult.components.vendor.value || null,
    cookiesEnabled: fpResult.components.cookiesEnabled.value || null,
    expoVersion: Constants.expoVersion || null,
  };
  return deviceProperties;
};
