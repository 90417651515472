import React, { useContext, useMemo } from 'react';
import { View } from 'react-native';

import { Menu, MenuItem } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { AgencyProfileTopNavigationBar } from '../../../components/agency/agency-profile-top-navigation-bar.component';
import { ForwardIcon } from '../../../components/icons.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { OrganizationUserRoleType } from '../../../generated-graphql-types';

export const AgencyManageAgencyUsersScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();

  const appUserContext = useContext(AppUserContext);

  const menuItems = useMemo(() => {
    const items = [
      {
        title: t('common:viewAgencyUsers', { defaultValue: 'View Agency Users' }) as string,
        screen: 'AgencyViewAgencyUsersScreen',
      },
    ];
    if (
      appUserContext.currentUserContext?.organizationUser?.roleType &&
      [OrganizationUserRoleType.OWNER, OrganizationUserRoleType.ADMIN].includes(
        appUserContext.currentUserContext?.organizationUser?.roleType,
      )
    ) {
      items.push({
        title: t('common:inviteAgencyEmployee', {
          defaultValue: 'Invite Agency Employee',
        }) as string,
        screen: 'AgencyAgencyUserInvitationsScreen',
      });
    }
    return items;
  }, [appUserContext.currentUserContext?.organizationUser?.roleType, t]);

  const navigateToScreen = (screen: string) => () => {
    navigation.navigate('AgencyProfileNavigator', {
      screen,
      params: {
        previousScreenName: 'AgencyManageAgencyUsersScreen',
        previousNavigator: 'AgencyProfileNavigator',
      },
    });
  };

  return (
    <>
      <AgencyProfileTopNavigationBar
        title={t('menuItems.manageAgencyUsers', { defaultValue: 'Manage Agency Users' })}
        navigation={navigation}
      />
      <View>
        <Menu>
          {menuItems.map((item) => (
            <MenuItem
              key={item.screen}
              title={item.title}
              accessoryRight={ForwardIcon}
              onPress={navigateToScreen(item.screen)}
            />
          ))}
        </Menu>
      </View>
    </>
  );
};
