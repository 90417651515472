import { DescriptionRow, GooglePlaceDetail } from 'react-native-google-places-autocomplete';

export const getAddressDetailsFromGooglePlaces = ({
  data,
  details,
}: {
  data: DescriptionRow;
  details: GooglePlaceDetail | null;
}) => {
  const location = {
    addressName: '',
    city: '',
    state: '',
    country: '',
    countryCode: '',
    streetName: '',
    houseNumber: '',
    postalCode: '',
    formattedAddress: '',
  };
  if (details?.address_components?.length) {
    if (data.types.includes('establishment') && data.structured_formatting.main_text) {
      location.addressName = data.structured_formatting.main_text;
    }
    details.address_components.forEach((place) => {
      if (place.types.includes('locality')) {
        location.city = place.long_name;
      }
      if (place.types.includes('administrative_area_level_1')) {
        location.state = place.long_name;
      }

      if (place.types.includes('country')) {
        location.country = place.long_name;
        location.countryCode = place.short_name;
      }
      if (place.types.includes('route')) {
        location.streetName = place.long_name;
      }
      if (place.types.includes('street_number')) {
        location.houseNumber = place.long_name;
      }
      if (place.types.includes('postal_code')) {
        location.postalCode = place.long_name;
      }

      // CALCULATING DESCRIPTION
      if (data.description) {
        location.formattedAddress = data.description;
      } else {
        location.formattedAddress = getFormattedAddressFromLocation(location);
      }
    });
  }
  return location;
};

export const getFormattedAddressFromLocation = (location: {
  [key: string]: string | undefined;
}) => {
  if (location.description) {
    return location.description;
  }
  let formattedAddress = '';
  if (location.streetName) {
    formattedAddress += `${location.streetName}`;
  }
  if (location.houseNumber) {
    formattedAddress += `${location.houseNumber}`;
  }
  if (location.postalCode) {
    if (formattedAddress.length) {
      formattedAddress += ', ';
    }
    formattedAddress += `${location.postalCode}`;
  }
  if (location.city) {
    formattedAddress += `${location.city}`;
  }
  if (location.country) {
    if (formattedAddress.length) {
      formattedAddress += ', ';
    }
    formattedAddress += `${location.country}`;
  }
  return formattedAddress;
};
