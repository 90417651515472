import React from 'react';
import { Animated, Pressable, StyleSheet, View } from 'react-native';

import { useCardAnimation } from '@react-navigation/stack';
import { Button, IndexPath, Select, SelectItem, Text } from '@ui-kitten/components';
import { BlurView } from 'expo-blur';

import { globalStyle } from '../../common/style';
import { OrganizationUserRoleType } from '../../graphql-types';

export const PriojetEditOrganizationInvitationScreen = ({
  // route,
  navigation,
}: {
  route: any;
  navigation: any;
}) => {
  // const { organizationInvitationId, otherParam, refetch } = route.params;
  const { current } = useCardAnimation();

  const organizationUserRoleTypeData = Object.keys(OrganizationUserRoleType);

  const [organizationUserRoleTypeSelectedIndex, setOrganizationUserRoleTypeSelectedIndex] =
    React.useState(new IndexPath(0));

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Pressable
        style={[
          StyleSheet.absoluteFill,
          globalStyle.alignItemsCenter,
          globalStyle.justifyContentCenter,
        ]}
        onPress={() => {
          navigation.goBack();
        }}
      >
        <BlurView
          tint="light"
          intensity={50}
          style={[StyleSheet.absoluteFill, { alignItems: 'center', justifyContent: 'center' }]}
        >
          <Animated.View
            style={{
              padding: 16,
              width: '90%',
              maxWidth: 400,
              borderRadius: 10,
              backgroundColor: '#ccc',
              transform: [
                {
                  scale: current.progress.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0.9, 1],
                    extrapolate: 'clamp',
                  }),
                },
              ],
            }}
          >
            <Text selectable={true}>
              Mise en place is a French term that literally means “put in place.” It also refers to
              a way cooks in professional kitchens and restaurants set up their work stations—first
              by gathering all ingredients for a recipes, partially preparing them (like measuring
              out and chopping), and setting them all near each other. Setting up mise en place
              before cooking is another top tip for home cooks, as it seriously helps with
              organization. It’ll pretty much guarantee you never forget to add an ingredient and
              save you time from running back and forth from the pantry ten times.
            </Text>
            <Select value="test3" selectedIndex={new IndexPath(3)}>
              <SelectItem title="test" key="test1" />
              <SelectItem title="test" key="test2" />
              <SelectItem title="test" key="test3" />
              <SelectItem title="test" key="test4" />
            </Select>

            <Select
              disabled={false}
              placeholder="PRIOjet User RoleType"
              value={organizationUserRoleTypeData[organizationUserRoleTypeSelectedIndex.row]}
              selectedIndex={organizationUserRoleTypeSelectedIndex}
              onSelect={(index) => setOrganizationUserRoleTypeSelectedIndex(index as IndexPath)}
            >
              {organizationUserRoleTypeData.map((title) => (
                <SelectItem title={title} key={title} />
              ))}
            </Select>
            <Button style={{ alignSelf: 'flex-end' }} onPress={navigation.goBack}>
              Okay
            </Button>
          </Animated.View>
        </BlurView>
      </Pressable>
    </View>
  );
};
