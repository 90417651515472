import React from 'react';
import { View } from 'react-native';

import { Button, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation, Trans } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';

import { useHook } from './hook';
import { themedStyle } from './styles';
import { SubscriptionPlanOptions } from './SubscriptionPlanOptions';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { OrganizationType } from '../../../generated-graphql-types';

interface Props {
  organizationType: OrganizationType;
}

export const SubscriptionPlanChoose: React.FC<Props> = ({ organizationType }) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyle);
  const globalStyle = useStyleSheet(globalStyleCommon);
  const {
    agencyPlans,
    handleActivationCodePress,
    handleBookNowPress,
    handleDashboardPress,
    handleSelectPlan,
    handleSelectPeriod,
    hasTrialPeriod,
    seconds,
    selectedPlan,
    selectedPeriod,
    successMessage,
  } = useHook();

  if (organizationType === OrganizationType.COURIER) {
    return <></>;
  }
  return (
    <>
      <ScrollView contentContainerStyle={styles.container} showsVerticalScrollIndicator={false}>
        <SubscriptionPlanOptions
          data={agencyPlans}
          hasTrialPeriod={hasTrialPeriod}
          onSelect={handleSelectPlan}
          onSelectPeriod={handleSelectPeriod}
          selected={selectedPlan}
          selectedPeriod={selectedPeriod}
        />

        {!!successMessage && (
          <View style={styles.success}>
            <Text style={styles.successText}>{successMessage}</Text>
          </View>
        )}

        {!successMessage && (
          <View
            style={[
              globalStyle.alignItemsCenter,
              hasTrialPeriod ? globalStyle.marginTop20 : globalStyle.marginTop30,
            ]}
          >
            <Button onPress={handleBookNowPress} style={globalStyle.width250}>
              {t('common.bookNow', { defaultValue: 'Book now' }) as string}
            </Button>
          </View>
        )}
        {!!successMessage && (seconds || seconds === 0) && seconds <= 5 && (
          <View style={[globalStyle.alignItemsCenter, globalStyle.marginTop30]}>
            <Button onPress={handleDashboardPress} style={globalStyle.width250}>
              {t('common.dashboard', { defaultValue: 'Dashboard' }) as string}
            </Button>
          </View>
        )}

        <View
          style={[
            globalStyle.flexRow,
            globalStyle.marginVertical30,
            globalStyle.paddingTop10,
            globalStyle.alignItemsCenter,
          ]}
        >
          <View style={[globalStyle.flex1, styles.line]} />
          <Text
            style={[
              globalStyle.paddingHorizontal15,
              globalStyle.fontLatoBold,
              globalStyle.fontSize12,
            ]}
          >
            {t('common.or', { defaultValue: 'or' }).toUpperCase()}
          </Text>
          <View style={[globalStyle.flex1, styles.line]} />
        </View>
        <Text style={[globalStyle.textAlignCenter, globalStyle.paddingBottom30]}>
          <Trans
            i18nKey="common.iHaveActivationCode"
            defaults="I have an <0>activation code</0>"
            components={[
              <Text
                style={[globalStyle.fontLatoBold, globalStyle.textDecorationUnderline]}
                onPress={handleActivationCodePress}
              />,
            ]}
          />
        </Text>
      </ScrollView>
    </>
  );
};
