import { Linking, Platform } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import * as ExpoDevice from 'expo-device';
import { getDeviceTypeAsync } from 'expo-device';
import * as ExpoLocalization from 'expo-localization';
import * as ExpoNotifications from 'expo-notifications';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import { getDeviceProperties } from './device-properties';
import { createApolloClientPersistAsync } from '../../apollo/create-apollo-client-persist-async';
import {
  GET_OR_CREATE_DEVICE_MUTATION,
  UPDATE_DEVICE_MUTATION,
} from '../../apollo/graphql-mutations';
import {
  DeviceCategoryType,
  DeviceInput,
  GetOrCreateDeviceMutation,
  GetOrCreateDeviceMutationVariables,
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables,
  DevicePermissionStatus,
} from '../../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../../globals';
import { Device } from '../../types';
import { getIsBackendReachable } from '../../utils/get-is-backend-reachable';
import { convertExpoNotificationPermissionToDeviceNotificationPermissionsInput } from '../device-notification/device-notification.module';
import { reloadApp } from '../helper/app-reload.helper';
import { uuidValidateV4 } from '../helper/helper.module';

export const updateDevice = async ({
  device,
  forceSyncWithApi = true,
  rebuildAndOverwriteDeviceProperties = false,
}: {
  device?: Device | undefined;
  forceSyncWithApi?: boolean;
  rebuildAndOverwriteDeviceProperties?: boolean;
} = {}): Promise<Device | null> => {
  if (!device || rebuildAndOverwriteDeviceProperties) {
    device = await getDevice();
  }

  const { apolloClient } = await createApolloClientPersistAsync();
  const deviceInput: DeviceInput = {
    id: device.id,
    deviceType: device.deviceType, // represent it as a string.
    deviceProperties: device.deviceProperties,
    expoPushToken: device.expoPushToken,
    permissions: device.permissions,
    localization: device.localization,
    appSettings: device.appSettings,
  };

  const isIsBackendReachable = await getIsBackendReachable();
  if (!forceSyncWithApi && !isIsBackendReachable) {
    return device;
  }

  try {
    const result = await apolloClient.mutate<UpdateDeviceMutation, UpdateDeviceMutationVariables>({
      mutation: UPDATE_DEVICE_MUTATION,
      variables: { deviceInput },
    });

    if (result?.data?.updateDevice?.id === device.id) {
      await AsyncStorage.setItem(GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY, 'true');
      await AsyncStorage.setItem(GLOBAL_CONSTANTS.DEVICE_ID_STORAGE_KEY, device.id);
      device.isSynchronizedWithBackend = true;
      return device;
    }
    // const _msg = `Error: device module: Device ID of updated device (${device.id}) does not match the response's device ID (${result.data.updateDevice.id}).`;
    // throw new Error(_msg);
  } catch (e) {
    setTimeout(reloadApp, 5000);
  }
  return device;
};

export const findOrCreateDevice = async (): //apolloClient: ApolloClient<NormalizedCacheObject>,
Promise<Device | null> => {
  const device = await getDevice();
  const { apolloClient } = await createApolloClientPersistAsync();

  const variables = {
    deviceInput: {
      id: device.id,
      deviceType: device.deviceType, // represent it as a string.
      deviceProperties: device.deviceProperties,
      expoPushToken: device.expoPushToken,
      permissions: device.permissions,
      localization: device.localization,
      appSettings: device.appSettings,
    },
  };

  try {
    const result = await apolloClient.mutate<
      GetOrCreateDeviceMutation,
      GetOrCreateDeviceMutationVariables
    >({
      mutation: GET_OR_CREATE_DEVICE_MUTATION,
      variables,
    });

    if (result?.data?.getOrCreateDevice?.id === device.id) {
      await AsyncStorage.setItem(GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY, 'true');
      await AsyncStorage.setItem(GLOBAL_CONSTANTS.DEVICE_ID_STORAGE_KEY, device.id);
      device.isSynchronizedWithBackend = true;
      return device;
    }
  } catch (e) {
    console.warn('!!!ERRROR', e);
  }

  return null;
};

export const getDevice = async (): Promise<Device> => {
  let devicePermissions: any = {
    notifications: {
      android: { importance: 0, interruptionFilter: null },
      canAskAgain: false,
      expires: 'never',
      granted: false,
      ios: null,
      status: DevicePermissionStatus.UNDETERMINED,
    },
  };
  if (Platform.OS !== 'web') {
    const notificationPermissions = await ExpoNotifications.getPermissionsAsync();
    devicePermissions = {
      notifications:
        convertExpoNotificationPermissionToDeviceNotificationPermissionsInput(
          notificationPermissions,
        ),
    };
  }

  let expoPushToken: string | undefined;

  if (Platform.OS !== 'web' && ExpoDevice.isDevice && devicePermissions.notifications.granted) {
    const expoPushTokenResponse = await ExpoNotifications.getExpoPushTokenAsync({
      // experienceId: Constants.expoConfig?.extra?.priojetPushTokenExperienceId || '@priojet/priojet',
      projectId: Constants.expoConfig?.extra?.eas.projectId || '',
    });
    expoPushToken = expoPushTokenResponse?.data;
  }

  const deviceLocalization = await ExpoLocalization.getLocalizationAsync();
  const appLanguage: string | null = await AsyncStorage.getItem(GLOBAL_CONSTANTS.APP_LANGUAGE_KEY);
  const theme: string | null = await AsyncStorage.getItem(
    GLOBAL_CONSTANTS.ACTIVE_THEME_NAME_STORAGE_KEY,
  );

  const device: Device = {
    id: await getOrCreateDeviceId(),
    deviceProperties: await getDeviceProperties(),
    deviceType: ExpoDevice.DeviceType[await getDeviceTypeAsync()] as unknown as DeviceCategoryType,
    permissions: devicePermissions,
    expoPushToken,
    isSynchronizedWithBackend: false,
    localization: {
      ...deviceLocalization,
    },
    appSettings: {
      appLanguage: appLanguage,
      theme,
    },
  };

  return device;
};

export const getDeviceIdFromStorage = async (): Promise<string | null> => {
  const deviceId: string | null = await AsyncStorage.getItem(
    GLOBAL_CONSTANTS.DEVICE_ID_STORAGE_KEY,
  );
  if (deviceId && uuidValidateV4(deviceId)) {
    return deviceId;
  }
  return null;
};

export const getOrCreateDeviceId = async (): Promise<string> => {
  const deviceId: string | null = await getDeviceIdFromStorage();
  if (deviceId) {
    return deviceId;
  }
  await AsyncStorage.setItem(GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY, 'false');
  return await setAndGetNewDeviceId();
};

export const getDeviceFromStorageWithoutCreatingNew = async (): Promise<Device | null> => {
  const deviceId = await getDeviceIdFromStorage();
  return deviceId !== null ? getDevice() : null;
};

export const setAndGetNewDeviceId = async (): Promise<string> => {
  const deviceId: string = uuidv4();
  if (deviceId && uuidValidateV4(deviceId)) {
    await AsyncStorage.setItem(GLOBAL_CONSTANTS.DEVICE_ID_STORAGE_KEY, deviceId);
  }
  return deviceId;
};

export const openAppSettings = () => {
  Linking.openSettings();
};
