import { Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    minHeight: 48,
    flexDirection: 'row',
    backgroundColor: 'background-basic-color-1',
    shadowColor: 'border-basic-color-5',
    shadowOffset: { width: 0, height: -3 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  chatDisabled: {
    padding: 15,
  },

  textInputContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  textInput: {
    paddingHorizontal: 16,
    minHeight: 44,
    maxHeight: 200,
    paddingVertical: Platform.OS === 'web' ? 10 : 6,
    paddingBottom: Platform.OS === 'web' ? 2 : 6,
    marginBottom: Platform.OS === 'web' ? 0 : 0,
    marginTop: Platform.OS === 'web' ? 6 : 0,
    paddingTop: 12,
    verticalAlign: 'top',
    color: 'text-basic-color',
    outlineStyle: 'none',
  },

  actionButton: {
    margin: 6,
    marginBottom: 12,
    marginLeft: 10,
  },
  sendButton: {
    margin: 6,
    marginBottom: 12,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonDisabled: {
    opacity: 0.4,
  },
  icon: {
    color: 'color-primary-600',
  },
  iconStyle: {
    height: 24,
    width: 24,
  },

  maxLengthContainer: {
    alignItems: 'flex-end',
    paddingRight: 18,
    paddingBottom: 2,
    paddingTop: 8,
  },
  maxLengthText: {
    fontSize: 12,
    color: 'color-danger-500',
  },
});
