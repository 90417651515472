import React, { useContext, useMemo } from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Card,
  Divider,
  Icon,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import * as ExpoLocalization from 'expo-localization';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { NumericFormat } from 'react-number-format';

import {
  ActionButtonsAccepted,
  ActionButtonsDeclined,
  ActionButtonsPending,
  ActionButtonsRequested,
} from './agency-courier-jobs-card-item-buttons.component';
import {
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
  COURIER_JOBS_FOR_AGENCY_JOB_QUERY,
} from '../../../../apollo/graphql-queries';
import { globalStyle } from '../../../../common/style';
import AppUserContext from '../../../../contexts/AppUserContext';
import {
  CourierJob,
  CourierJobAgencyRelationshipType,
  OrganizationType,
  useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
} from '../../../../generated-graphql-types';
import { ConfirmModalContext } from '../../../common/modal/ConfirmModal';

const AgencyCourierJobsCardItemFooter = ({
  footerProps,
  courierJob,
}: {
  footerProps: any;
  courierJob: CourierJob;
}) => {
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const relationshipTypeStyle = useMemo(() => {
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED
    ) {
      return styles.relationshipTypeConfirmed;
    }
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED
    ) {
      return styles.relationshipTypeDeclined;
    }
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED
    ) {
      return styles.relationshipTypeRequested;
    }
    return styles.relationshipTypeUndefined;
  }, [courierJob?.relationshipType, styles]);

  const handleChatPress = () => {
    navigation.navigate('AgencyCourierJobAgencyJobChatScreen', {
      agencyJobId: courierJob?.agencyJob?.id || '',
      chatId: courierJob?.courierJobAgencyJobChat?.id || '',
      courierJobId: courierJob?.id || '',
      userId: courierJob?.responsibleCourierUser?.id || '',
      previousScreenName: 'AgencyCourierJobsScreen',
      previousNavigator: 'AgencyHomeNavigator',
    });
  };

  const handleProfilePress = () => {
    navigation.navigate('AgencyCourierUserProfileScreen', {
      userId: courierJob?.responsibleCourierUser?.id as string,
      agencyJobId: courierJob?.agencyJob?.id,
      previousScreenName: 'AgencyCourierJobsScreen',
      previousNavigator: 'AgencyHomeNavigator',
    });
  };

  return (
    <View
      {...footerProps}
      style={[
        globalStyle.paddingVertical5,
        globalStyle.flexRow,
        globalStyle.alignItemsEnd,
        globalStyle.alignContentBetween,
      ]}
    >
      <View
        style={[
          globalStyle.flex1,
          globalStyle.flexRow,
          globalStyle.alignItemsCenter,
          globalStyle.justifyContentBetween,
        ]}
      >
        {!!courierJob?.relationshipType && (
          <View style={styles.relationshipType}>
            <View style={[styles.relationShipTypeIcon, relationshipTypeStyle]} />

            <Text style={styles.relationshipTypeText}>
              {courierJob?.relationshipType !== undefined
                ? capitalize(
                    (
                      CourierJobAgencyRelationshipType[courierJob?.relationshipType]?.replace(
                        /_/g,
                        ' ',
                      ) || ''
                    ).toLowerCase(),
                  )
                : 'UNKNOWN'}
            </Text>
          </View>
        )}
        <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.justifyContentEnd]}>
          <Button
            size="tiny"
            appearance="filled"
            style={styles.actionButton}
            onPress={handleChatPress}
          >
            {t('common.chat', { defaultValue: 'Chat' }) as string}
          </Button>
          <Button
            size="tiny"
            appearance="filled"
            style={styles.actionButton}
            onPress={handleProfilePress}
          >
            {t('common.profile', { defaultValue: 'Profile' }) as string}
          </Button>
        </View>
      </View>
    </View>
  );
};

export const AgencyCourierJobsCardItem = ({
  courierJob,
  refetchJob,
}: {
  courierJob: Partial<CourierJob>;
  refetchJob?: any;
}) => {
  const { t } = useTranslation();

  const styles = useStyleSheet(themedStyles);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const appUserContext = useContext(AppUserContext);

  const [updateRelationshipTypeForCourierJobAsAgencyOrganizationMutation] =
    useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation({
      refetchQueries:
        appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
          ? [COURIER_JOBS_FOR_AGENCY_JOB_QUERY]
          : [AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY],
    });

  const updateRelationshipTypeForCourierJobAsAgencyOrganization = (
    relationshipType: CourierJobAgencyRelationshipType,
  ) => {
    const translationData = {
      dropOffLocation: courierJob.job?.dropOffLocation.formattedShortAddress,
      priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
      firstNames: courierJob.responsibleCourierUser?.firstNames,
      lastName: courierJob.responsibleCourierUser?.lastName,
      email: courierJob.responsibleCourierUser?.email,
      relationshipType,
    };

    let confirmButtonStatus = 'primary';
    let title = '';
    let text = '';
    let confirmButtonText = '';
    let description = t('JOBS.COURIER_JOB_RELATIONSHIP_TYPE_CHANGE_FOR_AGENCY_MESSAGE', {
      defaultValue:
        'Courier Job for {{firstNames}} {{lastName}} ({{email}}) was set to {{relationshipType}}.',
      ...translationData,
    });

    if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED) {
      title = t('jobs.acceptCourierForJob', { defaultValue: 'Accept courier for job' });
      text = t('jobs.confirmAcceptJobForCourier', {
        ...translationData,
        defaultValue:
          'Are you sure you want to confirm courier {{firstNames}} {{lastName}} ({{email}}) for job - {{priojetJobIdentifier}}?',
      });
      confirmButtonText = t('common.confirm', { defaultValue: 'Confirm' });
      description = t('jobs.courierJoProposedToCourier', {
        defaultValue:
          'Courier Job {{priojetJobIdentifier}} to {{dropOffLocation}} accepted for {{firstNames}} {{lastName}} ({{email}}).',
        ...translationData,
      });
    }
    if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED) {
      title = t('jobs.declineCourierForJob', { defaultValue: 'Decline courier for job' });
      text = t('jobs.confirmDeclineJobForCourier', {
        ...translationData,
        defaultValue:
          'Are you sure you want to decline courier {{firstNames}} {{lastName}} ({{email}}) for job {{priojetJobIdentifier}}?',
      });
      description = t('jobs.courierJoProposedToCourier', {
        defaultValue:
          'Courier Job {{priojetJobIdentifier}} to {{dropOffLocation}} declined for {{firstNames}} {{lastName}} ({{email}}).',
        ...translationData,
      });
      confirmButtonStatus = 'danger';
      confirmButtonText = t('common.decline', { defaultValue: 'Decline' });
    }
    if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED) {
      title = t('jobs.proposeJobToCourier', { defaultValue: 'Propose job to courier' });
      text = t('jobs.', {
        ...translationData,
        defaultValue:
          'Are you sure you want to propose job {{priojetJobIdentifier}} to {{dropOffLocation}} to {{firstNames}} {{lastName}} again?',
      });
      confirmButtonText = t('common.propose', { defaultValue: 'Propose' });
      description = t('jobs.courierJoProposedToCourier', {
        ...translationData,
        defaultValue:
          'Courier Job {{priojetJobIdentifier}} to {{dropOffLocation}} was proposed to {{firstNames}} {{lastName}} ({{email}}).',
      });
    }

    showConfirmModal({
      confirmButtonStatus,
      confirmButtonAppearance: 'filled',
      confirmButtonText: confirmButtonText,
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title,
      text,
      onConfirmPress: async () => {
        await updateRelationshipTypeForCourierJobAsAgencyOrganizationMutation({
          variables: {
            agencyJobId: courierJob?.agencyJob?.id as string,
            id: courierJob.id as string,
            relationshipType,
          },
        }).then(() => {
          showMessage({
            message: t('common.courierJobUpdated', {
              defaultValue: 'Courier Job updated',
            }),
            description,
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
            type: 'success',
          });
          if (refetchJob) {
            refetchJob();
          }
        });
        return true;
      },
      visible: true,
    });
  };

  const handleButtonPress = (status: CourierJobAgencyRelationshipType) => () => {
    updateRelationshipTypeForCourierJobAsAgencyOrganization(status);
  };

  const renderButton = () => {
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED) {
      return <ActionButtonsAccepted onPress={handleButtonPress} />;
    }
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED
    ) {
      return <ActionButtonsDeclined onPress={handleButtonPress} />;
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED) {
      return <ActionButtonsRequested onPress={handleButtonPress} />;
    }
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED
    ) {
      return <ActionButtonsPending onPress={handleButtonPress} />;
    }

    return <></>;
  };

  const renderFooter = (footerProps: any) => (
    <AgencyCourierJobsCardItemFooter {...footerProps} courierJob={courierJob} />
  );

  const getCourierJobCardContent = (): React.ReactNode => {
    return (
      <>
        <Text category="h6" selectable>
          {`${courierJob.responsibleCourierUser?.firstNames} ${courierJob.responsibleCourierUser?.lastName}`}
        </Text>
        <Text category="h6" selectable>
          {courierJob.responsibleCourierUser?.email}
        </Text>
        <Divider style={globalStyle.marginVertical10} />
        <View
          style={[
            globalStyle.marginBottom5,
            globalStyle.flexRow,
            globalStyle.flexShrink1,
            globalStyle.flexWrap,
          ]}
        >
          <Text
            category="label"
            style={[
              globalStyle.flexRow,
              globalStyle.flexShrink1,
              globalStyle.flexWrap,
              // eslint-disable-next-line react-native/no-inline-styles
              { color: '#ccc' },
            ]}
          >
            {
              t('jobs.agencyJobRateCardDescription', {
                defaultValue:
                  'The rates below were proposed when you, as the Agency, added the Courier initially to the Job via a request. Whatever rates were agreed upon after that via Email, Phone, Chat, etc. are binding.',
              }) as string
            }
          </Text>
        </View>
        <View style={styles.courierJobInfoView}>
          <View style={styles.courierJobInfoAttributeView}>
            <Text>{t('common.dailyRate', { defaultValue: 'Daily Rate' }) as string}</Text>
          </View>
          <NumericFormat
            value={courierJob.dailyRate}
            className="foo"
            displayType="text"
            thousandSeparator={ExpoLocalization.digitGroupingSeparator}
            decimalSeparator={ExpoLocalization.decimalSeparator}
            decimalScale={2}
            fixedDecimalScale
            prefix={courierJob.agencyJob?.currency + ' '}
            renderText={(formattedValue) => <Text>{formattedValue}</Text>}
          />
        </View>
        <View style={styles.courierJobInfoView}>
          <View style={styles.courierJobInfoAttributeView}>
            <Text>{t('common.fixedRate', { defaultValue: 'Fixed Rate' }) as string}</Text>
          </View>
          <NumericFormat
            value={courierJob.fixedRate}
            className="foo"
            displayType="text"
            thousandSeparator={ExpoLocalization.digitGroupingSeparator}
            decimalSeparator={ExpoLocalization.decimalSeparator}
            decimalScale={2}
            fixedDecimalScale
            prefix={courierJob.agencyJob?.currency + ' '}
            renderText={(formattedValue) => <Text>{formattedValue}</Text>}
          />
        </View>
        <View style={styles.courierJobInfoView}>
          <View style={styles.courierJobInfoAttributeView}>
            <Text>{t('common.kickOffFee', { defaultValue: 'Kick-off fee' }) as string}</Text>
          </View>
          <NumericFormat
            value={courierJob.kickOffFee}
            className="foo"
            displayType="text"
            thousandSeparator={ExpoLocalization.digitGroupingSeparator}
            decimalSeparator={ExpoLocalization.decimalSeparator}
            decimalScale={2}
            fixedDecimalScale
            prefix={courierJob.agencyJob?.currency + ' '}
            renderText={(formattedValue) => <Text>{formattedValue}</Text>}
          />
        </View>
        <View style={styles.courierJobInfoView}>
          <View style={styles.courierJobInfoAttributeView}>
            <Text>{t('common.hotelBudget', { defaultValue: 'Hotel budget' }) as string}</Text>
          </View>
          <NumericFormat
            value={courierJob.hotelBudget}
            className="foo"
            displayType="text"
            thousandSeparator={ExpoLocalization.digitGroupingSeparator}
            decimalSeparator={ExpoLocalization.decimalSeparator}
            decimalScale={2}
            fixedDecimalScale
            prefix={courierJob.agencyJob?.currency + ' '}
            renderText={(formattedValue) => <Text>{formattedValue}</Text>}
          />
        </View>

        <View style={styles.actionButtonsContainer}>
          <View style={styles.actionButtonsWrapperRight}>{renderButton()}</View>
        </View>
      </>
    );
  };

  return (
    <Card key={courierJob.id} style={styles.card} footer={renderFooter}>
      {getCourierJobCardContent()}
    </Card>
  );
};

const themedStyles = StyleService.create({
  relationshipType: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    padding: 3,
  },
  relationshipTypeText: {
    fontSize: 10,
  },
  relationShipTypeIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    margin: 10,
  },
  relationshipTypeConfirmed: {
    backgroundColor: 'color-success-500',
  },
  relationshipTypeRequested: {
    backgroundColor: 'color-warning-500',
  },
  relationshipTypeDeclined: {
    backgroundColor: 'color-danger-500',
  },
  relationshipTypeUndefined: {
    backgroundColor: 'text-disabled-color',
  },
  relationshipTypeInfoText: {
    paddingHorizontal: 10,
    paddingTop: 6,
    fontSize: 12,
    color: 'color-basic-500',
    textAlign: 'right',
  },
  container: {
    flex: 1,
  },
  card: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
    paddingHorizontal: 10,
    paddingTop: 10,
    marginBottom: 10,
  },
  actionButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  },
  actionButtonsWrapperRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: 10,
  },
  courierJobInfoView: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 5,
  },
  courierJobInfoAttributeView: {
    width: 100,
  },
});
