import React, { useContext } from 'react';
import { ImageProps, Platform, Share, View } from 'react-native';

import {
  Button,
  Icon,
  IndexPath,
  Layout,
  MenuItem,
  OverflowMenu,
  StyleService,
  Text,
} from '@ui-kitten/components';
import { RenderProp } from '@ui-kitten/components/devsupport';
import * as Clipboard from 'expo-clipboard';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Flag from 'react-native-flags';
import { showMessage } from 'react-native-flash-message';

import { createApolloClientPersistAsync } from '../../../../apollo/create-apollo-client-persist-async';
import { CREATE_ORGANIZATION_INVITATION_SHARING_MUTATION } from '../../../../apollo/graphql-mutations';
import { ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY } from '../../../../apollo/graphql-queries';
import { globalStyle } from '../../../../common/style';
import AppUserContext from '../../../../contexts/AppUserContext';
import {
  CreateOrganizationInvitationSharingMutation,
  CreateOrganizationInvitationSharingMutationVariables,
  OrganizationInvitation,
  OrganizationInvitationSharingInput,
  OrganizationType,
} from '../../../../generated-graphql-types';
import { getI18nInstance } from '../../../../i18n/i18n';

const onShareOrganizationInvitation = async (
  organizationInvitation: OrganizationInvitation,
  language = 'en',
) => {
  const { apolloClient } = await createApolloClientPersistAsync();

  const createOrganizationInvitationSharing = async (
    organizationInvitationSharingInput: OrganizationInvitationSharingInput,
  ) => {
    await apolloClient.mutate<
      CreateOrganizationInvitationSharingMutation,
      CreateOrganizationInvitationSharingMutationVariables
    >({
      variables: {
        organizationInvitationSharingInput,
      },
      awaitRefetchQueries: true,
      refetchQueries: [ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY],
      mutation: CREATE_ORGANIZATION_INVITATION_SHARING_MUTATION,
    });
  };

  const i18nNextInstance = getI18nInstance(language);

  const messageTitle: string = i18nNextInstance.t(
    'organizationInvitations.activationCodeShareMessageTitle',
    { defaultValue: 'You have been invited to join the PRIOjet platform.' },
  );
  const message = i18nNextInstance.t('organizationInvitations.activationCodeShareMessage', {
    activationCode: organizationInvitation.activationCode,
    defaultValue: `Hello!

      We are pleased to be able to invite you to the PRIOjet platform (iOS, Android, Web).
      Please signup using the iOS app ( https://apps.apple.com/kn/app/priojet-logistics/id1540437650 ), Android app ( https://play.google.com/store/apps/details?id=com.priojet.app ) or via https://app.priojet.com and use the code
      
      {{activationCode}}
      
      to activate your account after a successful registration.
      If you have questions, reach out to support@priojet.com.
      
      Happy to have you on board!
      Your PRIOjet team.`,
  });

  try {
    const result = await Share.share(
      {
        message: message,
        title: messageTitle,
      },
      {
        dialogTitle: messageTitle,
        excludedActivityTypes: [],
        tintColor: '#ff0000',
        subject: messageTitle,
      },
    );

    if (result) {
      if (result.action === Share.sharedAction) {
        let appName = '[unknown]';
        if (result.activityType) {
          if (result.activityType.toLowerCase().search('.mail') > 0) {
            appName = 'Mail';
          } else if (result.activityType.toLowerCase().search('outlook') > 0) {
            appName = 'Outlook';
          } else if (result.activityType.toLowerCase().search('whatsapp') > 0) {
            appName = 'Whatsapp';
          } else if (result.activityType.toLowerCase().search('telegram') > 0) {
            appName = 'Telegram';
          } else if (result.activityType.toLowerCase().search('message') > 0) {
            appName = 'SMS';
          } else if (result.activityType.toLowerCase().search('signal') > 0) {
            appName = 'Signal';
          } else if (result.activityType.toLowerCase().search('skype.team') > 0) {
            appName = 'Microsoft Teams';
          } else if (result.activityType.toLowerCase().search('skype') > 0) {
            appName = 'Skype';
          } else if (result.activityType.toLowerCase().search('copytopasteboard') > 0) {
            appName = 'Clipboard';
          } else {
            appName = result.activityType;
          }
          showMessage({
            message: `Shared & sent!`,
            description: `The Organization Invitation with code ${organizationInvitation.activationCode} was successfully shared via ${appName} (${Platform.OS}: ${Platform.Version}).`,
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
            type: 'success',
          });
        } else {
          showMessage({
            message: `Shared & sent!`,
            description: `The Organization Invitation with code ${organizationInvitation.activationCode} was successfully shared.`,
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
            type: 'success',
          });
        }

        const organizationInvitationSharingVariables: OrganizationInvitationSharingInput = {
          organizationInvitationId: organizationInvitation.id,
          appName: appName,
          platformVersion: Platform.Version?.toString() || undefined,
          platform: Platform.OS,
          sharedAt: DateTime.now(),
          language: language,
        };

        await createOrganizationInvitationSharing(organizationInvitationSharingVariables);
      }
      // iOS only
      else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    }
  } catch (error) {
    if (error) {
      alert(error);
    }
    const organizationInvitationSharingInput: OrganizationInvitationSharingInput = {
      organizationInvitationId: organizationInvitation.id,
      appName: 'testtest',
      platformVersion: Platform.Version ? Platform.Version.toString() : undefined,
      platform: Platform.OS,
      sharedAt: DateTime.now(),
      language: language,
    };

    await createOrganizationInvitationSharing(organizationInvitationSharingInput);
  }
};

const copyOrganizationInvitationToClipboard = (organizationInvitation: OrganizationInvitation) => {
  const i18nNextInstance = getI18nInstance();

  Clipboard.setStringAsync(organizationInvitation?.activationCode as string);
  showMessage({
    message: i18nNextInstance.t('common.Copied!'),
    description: i18nNextInstance.t(
      'organizationInvitations.copiedActivationCodeClipboardMessage',
      {
        activationCode: organizationInvitation.activationCode,
        defaultValue: 'Copied activation code {{activationCode}} to the clipboard.',
      },
    ) as string,
    renderFlashMessageIcon: () => <Icon name="check-outline" />,
    type: 'success',
  });
};

const ActionButtons = ({
  organizationInvitation,
}: {
  organizationInvitation: OrganizationInvitation;
}) => {
  const appUserContext = useContext(AppUserContext);

  const [visible, setVisible] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<IndexPath | undefined>(undefined);
  const [t] = useTranslation();

  const languages: {
    [key: string]: { lng: string; countryFlag: string; title: string };
  } = {
    '1': {
      lng: 'en',
      countryFlag: 'US',
      title: t('common.English', { defaultValue: 'English' }),
    },
    '2': {
      lng: 'de',
      countryFlag: 'DE',
      title: t('common.German', { defaultValue: 'German' }),
    },
    '3': {
      lng: 'es',
      countryFlag: 'ES',
      title: t('common.Spanish', { defaultValue: 'Spanish' }),
    },
    '4': {
      lng: 'fr',
      countryFlag: 'FR',
      title: t('common.French', { defaultValue: 'French' }),
    },
  };

  const onSelect = (index: IndexPath) => {
    setSelectedIndex(index);
    onShareOrganizationInvitation(organizationInvitation, languages[index.toString()].lng);
    setVisible(false);
  };

  const FlagIcon = (country: string): RenderProp<Partial<ImageProps>> => {
    return () => <Flag code={country} type="flat" size={32} />;
  };

  if (appUserContext.currentUserContext?.organizationType !== OrganizationType.PRIOJET) {
    return null;
  } else if (
    appUserContext.currentUserContext?.organizationType === OrganizationType.PRIOJET &&
    !organizationInvitation.voidedAt &&
    !organizationInvitation.usedAt
  ) {
    return (
      <Layout style={styles.actionButtonsContainer}>
        <OverflowMenu
          style={globalStyle.marginTop0}
          anchor={() => (
            <Button
              size="tiny"
              style={styles.actionButton}
              onPress={() => {
                setVisible(true);
              }}
              accessoryLeft={(props: any) => <Icon {...props} name="share-outline" />}
            />
          )}
          visible={visible}
          selectedIndex={selectedIndex}
          onSelect={onSelect}
          onBackdropPress={() => setVisible(false)}
        >
          {Object.values(languages).map((language) => (
            <MenuItem
              key={`${organizationInvitation.id}-${language.title}`}
              title={language.title}
              accessoryLeft={FlagIcon(language.countryFlag)}
            />
          ))}
        </OverflowMenu>

        <Button
          size="tiny"
          style={styles.actionButton}
          onPress={() => copyOrganizationInvitationToClipboard(organizationInvitation)}
          accessoryLeft={(props: any) => <Icon {...props} name="copy-outline" />}
        />
      </Layout>
    );
  }
  return (
    <Layout style={styles.actionButtonsContainer}>
      <Button
        size="tiny"
        style={styles.actionButton}
        onPress={() => copyOrganizationInvitationToClipboard(organizationInvitation)}
        accessoryLeft={(props: any) => <Icon {...props} name="copy-outline" />}
      />
    </Layout>
  );
};

export const OrganizationInvitationCardItemHeader = ({
  headerProps,
  organizationInvitation,
}: {
  headerProps: any;
  organizationInvitation: OrganizationInvitation;
}) => {
  const appUserContext = useContext(AppUserContext);

  const renderIcon = () => {
    if (
      organizationInvitation.organizationInvitationSharings &&
      organizationInvitation.organizationInvitationSharings.length > 0 &&
      !organizationInvitation.usedAt
    ) {
      return <Icon height={24} width={24} name="checkmark" fill="green" />;
    }
    if (organizationInvitation.usedAt) {
      return <Icon height={24} width={24} name="done-all" fill="green" />;
    }
    return <></>;
  };

  if (appUserContext.currentUserContext?.organizationType !== OrganizationType.PRIOJET) {
    return (
      <View
        {...headerProps}
        style={[
          globalStyle.flex1,
          globalStyle.alignItemsCenter,
          globalStyle.alignContentBetween,
          globalStyle.flexRow,
          globalStyle.justifyContentStart,
          globalStyle.paddingRight20,
          { minHeight: 40 },
        ]}
      >
        <View>
          <Text category="h5" style={globalStyle.fontLatoBold}>
            {organizationInvitation?.activationCode?.slice(0, -3) as string}***
          </Text>
        </View>
        <View
          style={[globalStyle.justifyContentCenter, globalStyle.flexRow, globalStyle.marginLeft10]}
        >
          {renderIcon()}
        </View>

        <ActionButtons organizationInvitation={organizationInvitation} />
      </View>
    );
  }
  return (
    <View
      {...headerProps}
      style={[
        globalStyle.flex1,
        globalStyle.alignItemsCenter,
        globalStyle.alignContentBetween,
        globalStyle.flexRow,
        globalStyle.justifyContentStart,
        globalStyle.paddingRight20,
        { minHeight: 40 },
      ]}
    >
      <View>
        <Text category="h4">{organizationInvitation.activationCode as string}</Text>
      </View>
      <View
        style={[globalStyle.justifyContentCenter, globalStyle.flexRow, globalStyle.marginLeft10]}
      >
        {renderIcon()}
      </View>

      <ActionButtons organizationInvitation={organizationInvitation} />
    </View>
  );
};

const styles = StyleService.create({
  container: {
    flex: 1,
  },
  actionButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButtonWrapper: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  actionButton: {
    marginLeft: 10,
  },
  list: {
    width: '100%',
    height: 'auto',
    padding: 10,
  },
  card: {
    margin: 10,
  },
  tabContainer: {
    height: 'auto',
    width: '100%',
    marginBottom: 180,
    alignItems: 'center',
  },
  noResultsLayout: {
    paddingTop: 20,
  },
});
