import React, { forwardRef } from 'react';
import {
  ActivityIndicator,
  Platform,
  Pressable,
  ScrollView,
  TouchableOpacity,
  View,
} from 'react-native';

import { Button, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import { FormDatepicker } from '../../../../components/common/form/form-datepicker.component';
import { FormTextInputTopLabel } from '../../../../components/common/form/form-text-input-top-label.component';
import { FormTimeSelect } from '../../../../components/common/form/FormTimeSelect/form-time-select.component';
import { ConfirmModal } from '../../../../components/common/modal/ConfirmModal';
import useDimensions from '../../../../hooks/useDimensions';
import { useFileUploadActionSheet } from '../../../../hooks/useFileUploadActionSheet';

export const PlanningLegModal = forwardRef(
  ({ handleCancel, handleConfirm, loading, title, visible }: any, ref): React.ReactElement => {
    const { t } = useTranslation();
    const globalStyle = useStyleSheet(globalStyleCommon);
    const styles = useStyleSheet(themedStyles);
    const { isSmallDevice, dimensions } = useDimensions();

    const {
      flightTicketDeleteModalVisible,
      dataChangedConfirmationModalVisible,
      deleteFlightTicketSelected,
      departureDate,
      departureTime,
      errorFlightNumber,
      errorDepartureDate,
      errorFlightCarrierDesignation,
      flightCarrierDesignation,
      flightNumber,
      flightTickets,
      handleCancelDataChangedPress,
      handleConfirmDataChangedPress,
      handleCarrierDesignationChange,
      handleCancelDeleteTicketModalPress,
      handleConfirmDeleteTicketModalPress,
      handleClose,
      handleDepartureDateChange,
      handleDepartureTimeChange,
      handleFlightNumberChange,
      handleRemoveFlightTicket,
      handleSave,
      handleSelectImage,
      handleSelectPDF,
      isSaveDisabled,
    } = useHook({ ref, handleCancel, handleConfirm });
    const { handleUploadFilesPress, renderModal: renderFileUploadModal } = useFileUploadActionSheet(
      {
        isModal: true,
        onSelectImage: handleSelectImage,
        onSelectPDF: handleSelectPDF,
      },
    );

    const renderTicketItem = (item: any, index: number) => {
      let name = item.name;
      if (item.storedAsset?.metadata?.fileName) {
        name = item.storedAsset?.metadata?.fileName;
      }
      return (
        <View
          key={item.name}
          style={[
            globalStyle.width100Percent,
            globalStyle.flexRow,
            globalStyle.alignItemsCenter,
            globalStyle.marginTop5,
            globalStyle.backgroundColorBasic4,
            globalStyle.padding10,
          ]}
        >
          <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsStart]}>
            <Icon
              fill={(globalStyle.colorTextBasicColor as any).color}
              name="attach-2-outline"
              style={globalStyle.size16}
            />
            <Text
              style={[globalStyle.flex1, globalStyle.marginLeft5, globalStyle.paddingRight15]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {name}
            </Text>
          </View>
          <Pressable onPress={handleRemoveFlightTicket(item, index)}>
            <Icon
              fill={(globalStyle.colorTextBasicColor as any).color}
              name="close"
              style={globalStyle.size16}
            />
          </Pressable>
        </View>
      );
    };

    const renderScrollView = () => {
      let width = dimensions.screen.width - 30;
      let diff = Platform.OS === 'ios' ? 45 : 35;
      if (width > 800) {
        width = 800 - diff;
      } else if (width > 700 && width <= 800) {
        width = 700 - diff;
      } else if (width > 600 && width <= 700) {
        width = 600 - diff;
      } else if (width <= 600) {
        width = dimensions.screen.width - diff;
      }

      let containerStyle = [globalStyle.flex0, { minWidth: 163 }, globalStyle.marginTop10];
      if (Platform.OS === 'android') {
        containerStyle = [{ minWidth: 90 }, globalStyle.marginTop5];
      } else if (Platform.OS === 'ios') {
        containerStyle = [globalStyle.marginTop5];
      }
      return (
        <ScrollView contentContainerStyle={[styles.inner, { width }]} horizontal={false}>
          <Text category="h6" style={styles.title}>
            {title}
          </Text>
          <Text style={styles.subtitle}>
            {t('jobs.enterFlightDetailsForThisLeg', {
              defaultValue: 'Enter flight details for this leg',
            })}
            :
          </Text>

          <View style={globalStyle.width100Percent}>
            <View
              style={[
                globalStyle.flex1,
                isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                isSmallDevice ? globalStyle.alignItemsStart : globalStyle.alignItemsCenter,
              ]}
            >
              <Text style={globalStyle.width150}>
                {t('jobs.flight', { defaultValue: 'Flight' })}
              </Text>
              <View
                style={[
                  !isSmallDevice && globalStyle.flex1,
                  isSmallDevice && globalStyle.width100Percent,
                  isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                ]}
              >
                <FormTextInputTopLabel
                  containerStyle={[globalStyle.flex1, globalStyle.marginTop5]}
                  errorMessage={errorFlightCarrierDesignation}
                  label="e.g. LH"
                  onChangeText={handleCarrierDesignationChange}
                  value={flightCarrierDesignation}
                />
                <FormTextInputTopLabel
                  containerStyle={[
                    globalStyle.flex1,
                    globalStyle.marginTop5,
                    !isSmallDevice && globalStyle.marginLeft5,
                  ]}
                  errorMessage={errorFlightNumber}
                  label="e.g. 400"
                  onChangeText={handleFlightNumberChange}
                  value={flightNumber}
                />
              </View>
            </View>

            <View
              style={[
                globalStyle.flex1,
                isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                !isSmallDevice && globalStyle.alignItemsCenter,
                globalStyle.marginTop20,
                globalStyle.marginBottom20,
              ]}
            >
              <Text style={globalStyle.width150}>
                {t('jobs.departureDate', { defaultValue: 'Departure date' })}
              </Text>
              <View style={[globalStyle.flex1, globalStyle.flexRow]}>
                <FormDatepicker
                  containerStyle={[globalStyle.flex1, globalStyle.marginTop10] as any}
                  date={departureDate?.toJSDate()}
                  error={!!errorDepartureDate}
                  errorMessage={errorDepartureDate}
                  label=""
                  isMandatory
                  onSelect={handleDepartureDateChange}
                  placement="top start"
                />
                <FormTimeSelect
                  containerStyle={containerStyle as any}
                  onChange={handleDepartureTimeChange}
                  value={departureTime}
                />
              </View>
            </View>
          </View>

          <View
            style={[
              globalStyle.width100Percent,
              globalStyle.marginBottom30,
              globalStyle.flexColumn,
            ]}
          >
            <Button
              appearance="outline"
              status="primary"
              size="small"
              onPress={handleUploadFilesPress}
            >
              {t('jobs.uploadFlightTicket', { defaultValue: 'Upload flight ticket' })}
            </Button>

            {!!flightTickets?.length && (
              <View style={globalStyle.marginTop10}>{flightTickets.map(renderTicketItem)}</View>
            )}
          </View>

          <View style={styles.buttonsContainer}>
            <Button
              appearance="filled"
              status="primary"
              size="medium"
              disabled={isSaveDisabled}
              onPress={handleSave}
              style={globalStyle.marginBottom10}
            >
              {loading ? (
                <ActivityIndicator color={(styles.loading as any).color} />
              ) : (
                t('common.save', { defaultValue: 'Save' })
              )}
            </Button>
          </View>
        </ScrollView>
      );
    };

    return (
      <>
        <Modal
          animationIn="fadeIn"
          animationOut="fadeOut"
          avoidKeyboard
          isVisible={visible}
          backdropOpacity={0.8}
          useNativeDriver
          style={
            Platform.OS === 'web' && [
              globalStyle.justifyContentCenter,
              globalStyle.alignItemsCenter,
            ]
          }
        >
          <Pressable style={globalStyle.absoluteFull} onPress={handleCancel} />
          <View style={styles.wrapper}>
            {renderScrollView()}
            <TouchableOpacity
              style={styles.closeButton}
              onPress={handleClose}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <Icon
                fill={(globalStyle.colorTextBasicColor as any).color}
                name="close"
                style={globalStyle.size30}
              />
            </TouchableOpacity>
          </View>

          {flightTicketDeleteModalVisible && (
            <ConfirmModal
              confirmButtonStatus="danger"
              confirmButtonAppearance="filled"
              confirmButtonText={t('common.delete', {
                defaultValue: 'Delete',
              })}
              cancelButtonText={t('common.cancel', { defaultValue: 'Cancel' })}
              title={t('common.deleteFlightTicket', { defaultValue: 'Delete flight ticket' })}
              text={t('common.youHaveUnsavedChanges', {
                defaultValue: 'Are you sure you want to delete flight ticket "{{name}}"',
                name: deleteFlightTicketSelected?.storedAsset?.metadata?.fileName,
              })}
              handleCancel={handleCancelDeleteTicketModalPress}
              handleConfirm={handleConfirmDeleteTicketModalPress}
              visible={flightTicketDeleteModalVisible}
            />
          )}

          {dataChangedConfirmationModalVisible && (
            <ConfirmModal
              confirmButtonStatus="danger"
              confirmButtonAppearance="filled"
              confirmButtonText={t('common.saveAndClose', {
                defaultValue: 'Save and close',
              })}
              cancelButtonText={t('common.close', { defaultValue: 'Close' })}
              title={t('common.unsavedChanges', { defaultValue: 'Unsaved changes' })}
              text={t('common.youHaveUnsavedChanges', {
                defaultValue: 'You have unsaved changes. Do you want to save them first?',
              })}
              handleCancel={handleCancelDataChangedPress}
              handleConfirm={handleConfirmDataChangedPress}
              visible={dataChangedConfirmationModalVisible}
            />
          )}

          {renderFileUploadModal()}
        </Modal>
      </>
    );
  },
);
