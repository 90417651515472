import React from 'react';

import { Button } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../../../common/style';
import { CourierJobAgencyRelationshipType } from '../../../../../generated-graphql-types';
import { CommonJobRelationshipTypeActionButtonLayer } from '../../../../common/jobs/common.job-relationship-type-action-button-layer.component';

export const ActionButtonsRequested = ({
  onPress,
}: {
  onPress: (status: CourierJobAgencyRelationshipType) => (e: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CommonJobRelationshipTypeActionButtonLayer
      description={t('jobs.waitingForApprovalFromCourier', {
        defaultValue: 'Waiting for approval from Courier',
      })}
      Buttons={
        <Button
          size="tiny"
          appearance="outline"
          status="danger"
          onPress={onPress(CourierJobAgencyRelationshipType.AGENCY_DECLINED)}
        >
          {
            t('jobs.widthDrawAndDecline', {
              defaultValue: 'Withdraw & Decline',
            }) as string
          }
        </Button>
      }
    />
  );
};

export const ActionButtonsAccepted = ({
  onPress,
}: {
  onPress: (status: CourierJobAgencyRelationshipType) => (e: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CommonJobRelationshipTypeActionButtonLayer
      description={t('jobs.cancelWithdrawAndDecline', {
        defaultValue: 'Cancel, withdraw & decline',
      })}
      Buttons={
        <>
          <Button
            size="tiny"
            appearance="outline"
            status="danger"
            onPress={onPress(CourierJobAgencyRelationshipType.AGENCY_DECLINED)}
          >
            {
              t('JOBS.cancelWithdrawAndDecline', {
                defaultValue: 'Cancel, withdraw & decline',
              }) as string
            }
          </Button>
        </>
      }
    />
  );
};

export const ActionButtonsPending = ({
  onPress,
}: {
  onPress: (status: CourierJobAgencyRelationshipType) => (e: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CommonJobRelationshipTypeActionButtonLayer
      description={t('jobs.acceptOrDeclineCourierForJob', {
        defaultValue: 'Accept or decline this Courier for the given Job',
      })}
      Buttons={
        <>
          <Button
            size="tiny"
            appearance="outline"
            status="danger"
            onPress={onPress(CourierJobAgencyRelationshipType.AGENCY_DECLINED)}
            style={globalStyle.marginRight5}
          >
            {
              t('jobs.decline', {
                defaultValue: 'Decline',
              }) as string
            }
          </Button>
          <Button
            size="tiny"
            appearance="outline"
            status="success"
            onPress={onPress(CourierJobAgencyRelationshipType.AGENCY_ACCEPTED)}
          >
            {
              t('jobs.accept', {
                defaultValue: 'Accept',
              }) as string
            }
          </Button>
        </>
      }
    />
  );
};

export const ActionButtonsDeclined = ({
  onPress,
}: {
  onPress: (status: CourierJobAgencyRelationshipType) => (e: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <CommonJobRelationshipTypeActionButtonLayer
      description={t('jobs.proposeJobToCourier', {
        defaultValue: 'Propose Job to Courier again / request again',
      })}
      Buttons={
        <Button
          size="tiny"
          appearance="outline"
          status="success"
          onPress={onPress(CourierJobAgencyRelationshipType.AGENCY_REQUESTED)}
        >
          {
            t('jobs.proposeAgain', {
              defaultValue: 'Propose again',
            }) as string
          }
        </Button>
      }
    />
  );
};
