import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  termsAndConditionsView: {
    borderColor: 'color-success-400',
    borderWidth: 1,
    marginTop: 30,
  },
  padding20: {
    padding: 20,
  },
  label: {
    fontSize: 18,
    paddingRight: 20,
    paddingBottom: 5,
    fontFamily: 'Lato_700Bold',
  },
  text: {
    fontSize: 17,
  },
  textBold: {
    fontFamily: 'Lato_700Bold',
    fontSize: 17,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: 20,
  },
  checkbox: {
    color: 'text-basic-color',
  },
  checkboxMarked: {
    color: 'text-primary-color',
  },
  marginTop10: {
    marginTop: 10,
  },
  buttonContainer: {
    marginTop: 30,
  },
});
