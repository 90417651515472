import React from 'react';
import {
  Platform,
  Pressable,
  StyleProp,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

import {
  ButtonElement,
  Icon,
  Popover,
  StyleService,
  Text,
  TextElement,
  useStyleSheet,
  PopoverPlacements,
} from '@ui-kitten/components';
import { LiteralUnion } from '@ui-kitten/components/devsupport/typings';
import TimeAgo, { LocaleData } from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import fr from 'javascript-time-ago/locale/fr';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

TimeAgo.addLocale(de as LocaleData);
TimeAgo.addLocale(en as LocaleData);
TimeAgo.addLocale(fr as LocaleData);
TimeAgo.addLocale(es as LocaleData);

const TimeAgoPopover = ({
  date,
  language,
  inititalVisibility = false,
  size = 16,
  textStyle,
  style,
  textCategory,
}: {
  date: DateTime;
  language: string;
  inititalVisibility?: boolean;
  size?: number;
  textStyle?: TextStyle;
  style?: StyleProp<ViewStyle>;
  textCategory?: LiteralUnion<
    'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 's1' | 's2' | 'p1' | 'p2' | 'c1' | 'c2' | 'label'
  >;
}) => {
  const styles = useStyleSheet(themedStyles);

  const { i18n } = useTranslation();
  const timeAgo = new TimeAgo(language || i18n.language);
  const [visible, setVisible] = React.useState<boolean>(inititalVisibility || false);

  const togglePopover = (): void => {
    setVisible(!visible);
  };

  const timeAgoString: string =
    date && date instanceof DateTime ? timeAgo?.format(date.toMillis()) : 'n/a';

  const PopoverContent = (): TextElement => {
    return (
      <Pressable
        style={styles.popoverContentView}
        onPress={() => setTimeout(() => setVisible(!visible), 100)}
      >
        <Text style={styles.text}>
          {date
            ? date.setLocale(language).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS)
            : 'n/a'}
        </Text>
      </Pressable>
    );
  };

  const renderToggleButton = (): ButtonElement => (
    <View style={[styles.toggleButton, style]}>
      <TouchableWithoutFeedback
        hitSlop={{ top: 50, bottom: 50, right: 50, left: 50 }}
        onPress={togglePopover}
      >
        <View style={styles.content}>
          <Text style={[styles.text, textStyle]} category={textCategory}>
            {timeAgoString as string}
          </Text>
          <Icon
            name="clock-outline"
            height={size}
            width={size}
            fill={styles.icon.backgroundColor}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );

  return (
    <Popover
      placement={PopoverPlacements.BOTTOM_END}
      contentContainerStyle={styles.contentContainerStyle}
      visible={visible}
      anchor={renderToggleButton}
      onBackdropPress={togglePopover}
      fullWidth={false}
    >
      <PopoverContent />
    </Popover>
  );
};

export { TimeAgoPopover };

const themedStyles = StyleService.create({
  icon: {
    backgroundColor: 'text-basic-color',
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainerStyle: {
    marginTop: Platform.OS === 'web' ? 10 : -40,
    maxWidth: 200,
  },
  popoverContentView: {
    padding: 10,
  },
  text: {
    color: 'text-basic-color',
    marginRight: 2,
  },
  toggleButton: {
    alignSelf: 'baseline',
    marginBottom: -3,
  },
});
