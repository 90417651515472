import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { AgencyOrganizationUsersList } from './AgencyOrganizationUsersList';
import { ORGANIZATION_ORGANIZATION_USERS_QUERY } from '../../../apollo/graphql-queries';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  Organization,
  OrganizationOrganizationUsersQuery,
  OrganizationOrganizationUsersQueryVariables,
} from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const AgencyViewAgencyUsersScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);

  const { data, loading, refetch } = useQuery<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >(ORGANIZATION_ORGANIZATION_USERS_QUERY, {
    variables: { id: appUserContext.currentUserContext?.organization?.id as string },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const handleBackPress = () => {
    navigation.navigate('AgencyProfileNavigator', {
      screen: 'AgencyManageAgencyUsersScreen',
      params: {
        previousScreenName: 'AgencyProfileScreen',
        previousNavigator: 'AgencyProfileNavigator',
      },
    });
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common:viewAgencyUsers', { defaultValue: 'View Agency Users' }) as string}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />
      {!!data?.organization && (
        <AgencyOrganizationUsersList
          loading={loading}
          organization={data.organization as Organization}
          refetch={refetch}
        />
      )}
    </>
  );
};
