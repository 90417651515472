export const PLANNING_ITEMS_LEG = [
  {
    active: true,
    name: 'checkInShipment',
    label: 'Check-in shipment',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'goToExportCustoms',
    label: 'Go to export customs',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'finishExportCustoms',
    label: 'Finish export customs',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'handoverShipmentAtExternalLuggageCounter',
    label: 'Handover shipment at external luggage counter',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'finishAirportSecurityCheckpoint',
    label: 'Finish airport security checkpoint',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'arriveAtBoardingGate',
    label: 'Arrive at boarding gate',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'startBoarding',
    label: 'Start boarding',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'boardingCompleted',
    label: 'Boarding Completed',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'takeOf',
    label: 'Take-off',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'landingAtDestination',
    label: 'Landing at destination',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'disembarkTheAirplane',
    label: 'Disembark the airplane',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'gotToImmigrationPassportControl',
    label: 'Go to immigration/passport control',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'finishImmigrationCheckpoint',
    label: 'Finish immigration checkpoint',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'pickupShipmentFromBaggageCarousel',
    label: 'Pick up shipment from baggage carousel',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'goToImportCustoms',
    label: 'Go to import customs',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
  {
    active: true,
    name: 'finishImportCustoms',
    label: 'Finish import customs',
    actualDateTime: null,
    targetDateTime: null,
    actualDateTimeSetAt: null,
  },
];

export const PLANNING_ITEM_DEFAULT_LABEL = {
  travelToPickupLocationOrStartAirport: 'Travel to pick-up location or start airport',
  arriveAtPickupLocation: 'Arrive at pickup location',
  pickupShipment: 'Pick up shipment',
  travelToStartAirport: 'Travel to Start Airport',
  arriveAtStartAirport: 'Arrive at Start Airport',
  passengerCheckInAtStartAirport: 'Passenger check-in at Start Airport',
  checkInShipment: 'Check-in shipment',
  goToExportCustoms: 'Go to export customs',
  finishExportCustoms: 'Finish export customs',
  handoverShipmentAtExternalLuggageCounter: 'Handover shipment at external luggage counter',
  finishAirportSecurityCheckpoint: 'Finish airport security checkpoint',
  arriveAtBoardingGate: 'Arrive at boarding gate',
  startBoarding: 'Start boarding',
  boardingCompleted: 'Boarding Completed',
  takeOf: 'Take-off',
  landingAtDestination: 'Landing at destination',
  disembarkTheAirplane: 'Disembark the airplane',
  gotToImmigrationPassportControl: 'Go to immigration/passport control',
  finishImmigrationCheckpoint: 'Finish immigration checkpoint',
  pickupShipmentFromBaggageCarousel: 'Pick up shipment from baggage carousel',
  goToImportCustoms: 'Go to import customs',
  finishImportCustoms: 'Finish import customs',
  travelAtDropOffDeliveryLocation: 'Travel to drop-off/delivery location',
  arriveAtDropOffDeliveryLocation: 'Arrive at drop-off/delivery location',
  waitForTheConsignee: 'Wait for the consignee',
  meetTheConsignee: 'Meet the consignee',
  handoverOfTheShipment: 'Handover of the shipment ',
  confirmTransportCompletion: 'Confirm transport completion',
};
