import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  textLeft: {
    textAlign: 'left',
    color: 'text-basic-color',
  },
  textRight: {
    textAlign: 'right',
    color: 'text-basic-color',
  },
  messageText: {},
});
