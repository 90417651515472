import { useContext, useMemo, useState, useRef } from 'react';
import { Platform } from 'react-native';

import { useQuery, useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useFormik } from 'formik';
import pick from 'lodash/pick';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import * as Yup from 'yup';

import { CREATE_OR_UPDATE_JOB_FOR_AGENCY_ORGANIZATION_MUTATION } from '../../../apollo/graphql-mutations';
import {
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
  ORGANIZATION_ORGANIZATION_USERS_QUERY,
  AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY,
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
} from '../../../apollo/graphql-queries';
import { GooglePlacesAutocompleteLocation } from '../../../components/common/google-places-autocomplete.component';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  AgencyJob,
  OrganizationOrganizationUsersQuery,
  OrganizationOrganizationUsersQueryVariables,
} from '../../../generated-graphql-types';
import {
  AgencyJobInput,
  ClientJobByAgencyInput,
  Currency,
  JobInput,
  JobType,
  JobTypeOfDelivery,
  TransportGoodsContentType,
} from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { resolveTimeZoneForLocation } from '../../../lib/google-maps-timezone-api';
import { RootStackParamList } from '../../../navigation/app.navigator';

export const useHook = () => {
  const { i18n, t } = useTranslation();
  const navigation: any = useNavigation();
  const route: RouteProp<RootStackParamList, 'AgencyCreateJobScreen'> = useRoute();
  const { courierOrganizationUserId } = route.params;
  const language = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;

  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const internalNotesModalRef = useRef<any>(null);

  const [createOrUpdateJobForAgencyOrganizationMutation] = useMutation(
    CREATE_OR_UPDATE_JOB_FOR_AGENCY_ORGANIZATION_MUTATION,
    {
      refetchQueries: [
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
      ],
    },
  );

  const [loading, setLoading] = useState(false);
  const [isStateVariablesSet, setIsStateVariablesSet] = useState<boolean>(
    !route.params?.agencyJobId,
  );
  const [selectedPickupModeIndex, setSelectedPickupModeIndex] = useState<number>(0);
  const [selectedDropOffModeIndex, setSelectedDropOffModeIndex] = useState<number>(0);

  const [agencyJobId, setAgencyJobId] = useState<string | null>(null);
  const [completedQueries, setCompletedQueries] = useState<number>(0);
  const [priojetJobIdentifier, setPriojetJobIdentifier] = useState<string | null>(null);

  const handleSave = async () => {
    setLoading(true);
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('common.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description:
          Object.keys(errors).length > 5
            ? (t('common.multipleInvalidField', {
                defaultValue:
                  'Multiple required fields are not filled out properly. Please check and try again.',
              }) as string)
            : Object.keys(errors)
                .map((key) => errors[key])
                .join('\n'),
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      setLoading(false);
      return;
    }

    const { values } = formik;
    if (values.pickupTimeDate > values.returnTimeAtStartAirportDate) {
      showMessage({
        message: t('common.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description: t('common.startTimeAfterReturnTime', {
          defaultValue: 'Start time cannot be after return time at start airport.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      setLoading(false);
      return;
    }

    let typeOfDelivery = JobTypeOfDelivery.DOOR_DOOR;
    if (values.selectedPickupModeIndex === 1 && values.selectedDropOffModeIndex === 0) {
      typeOfDelivery = JobTypeOfDelivery.AIRPORT_DOOR;
    } else if (values.selectedPickupModeIndex === 1 && values.selectedDropOffModeIndex === 1) {
      typeOfDelivery = JobTypeOfDelivery.AIRPORT_AIRPORT;
    } else if (values.selectedPickupModeIndex === 0 && values.selectedDropOffModeIndex === 1) {
      typeOfDelivery = JobTypeOfDelivery.DOOR_AIRPORT;
    }

    const startAirportSuggestedLocationInput = values.startAirport
      ? {
          ...pick(values.startAirport, ['id', 'formattedAddress', 'placeId']),
          latitude:
            values.startAirport?.geometry?.location?.lat ||
            values.startAirport?.locationGeometry?.location?.lat,
          longitude:
            values.startAirport?.geometry?.location?.lng ||
            values.startAirport?.locationGeometry?.location?.lng,
          types: values.startAirport?.locationTypes || ['airport'],
        }
      : undefined;
    const endAirportSuggestedLocationInput = values.endAirport
      ? {
          ...pick(values.endAirport, ['id', 'formattedAddress', 'placeId']),
          latitude:
            values.endAirport?.geometry?.location?.lat ||
            values.endAirport?.locationGeometry?.location?.lat,
          longitude:
            values.endAirport?.geometry?.location?.lng ||
            values.endAirport?.locationGeometry?.location?.lng,
          types: values.endAirport?.locationTypes || ['airport'],
        }
      : undefined;

    let pickupLocationSuggestedLocationInput = values.pickupLocation
      ? {
          ...pick(values.pickupLocation, ['id', 'formattedAddress', 'placeId']),
          latitude:
            values.pickupLocation?.geometry?.location?.lat ||
            values.pickupLocation?.locationGeometry?.location?.lat,
          longitude:
            values.pickupLocation?.geometry?.location?.lng ||
            values.pickupLocation?.locationGeometry?.location?.lng,
          types: values.pickupLocation?.locationTypes,
        }
      : undefined;
    let dropOffLocationSuggestedLocationInput = values.dropOffLocation
      ? {
          ...pick(values.dropOffLocation, ['id', 'formattedAddress', 'placeId']),
          latitude:
            values.dropOffLocation?.geometry?.location?.lat ||
            values.dropOffLocation?.locationGeometry?.location?.lat,
          longitude:
            values.dropOffLocation?.geometry?.location?.lng ||
            values.dropOffLocation?.locationGeometry?.location?.lng,
          types: values.dropOffLocation?.locationTypes,
        }
      : undefined;

    if (values.selectedPickupModeIndex === 1 && startAirportSuggestedLocationInput) {
      pickupLocationSuggestedLocationInput = startAirportSuggestedLocationInput;
    }
    if (values.selectedDropOffModeIndex === 1 && endAirportSuggestedLocationInput) {
      dropOffLocationSuggestedLocationInput = endAirportSuggestedLocationInput;
    }

    let _pickupTime;
    if (values.pickupTimeDate && values.pickupTime) {
      const valueHour = values.pickupTime.split(':')[0];
      const valueMinute = values.pickupTime.split(':')[1];
      _pickupTime = DateTime.fromObject(
        {
          year: values.pickupTimeDate.getFullYear(),
          month: values.pickupTimeDate.getMonth() + 1, // Date.getUTCMonth() is 0 for January and has a range from 0 to 11
          day: values.pickupTimeDate.getDate(),
          hour: valueHour ? parseInt(valueHour, 10) : 0,
          minute: valueMinute ? parseInt(valueMinute, 10) : 0,
          second: 0,
        },
        {
          zone:
            values.pickupLocationTimeZoneResponse?.timeZoneId ||
            values.pickupLocationTimeZoneIdentifier,
        },
      );
    }
    let _returnTimeAtStartAirport;
    if (values.returnTimeAtStartAirportDate && values.returnTimeAtStartAirport) {
      const valueHour = values.returnTimeAtStartAirport.split(':')[0];
      const valueMinute = values.returnTimeAtStartAirport.split(':')[1];
      _returnTimeAtStartAirport = DateTime.fromObject(
        {
          year: values.returnTimeAtStartAirportDate.getFullYear(),
          month: values.returnTimeAtStartAirportDate.getMonth() + 1,
          day: values.returnTimeAtStartAirportDate.getDate(),
          hour: valueHour ? parseInt(valueHour, 10) : 0,
          minute: valueMinute ? parseInt(valueMinute, 10) : 0,
        },
        {
          zone:
            values.startAirportLocationTimeZoneResponse?.timeZoneId ||
            values.startAirportLocationTimeZoneIdentifier,
        },
      );
    }

    let _returnTimeAtEndAirport;
    if (
      values.returnTimeAtEndAirportDate &&
      values.returnTimeAtEndAirportHour &&
      values.returnTimeAtEndAirportMinute
    ) {
      _returnTimeAtEndAirport = DateTime.fromObject(
        {
          year: values.returnTimeAtEndAirportDate.getFullYear(),
          month: values.returnTimeAtEndAirportDate.getMonth() + 1,
          day: values.returnTimeAtEndAirportDate.getDate(),
          hour: values.returnTimeAtEndAirportHour
            ? parseInt(values.returnTimeAtEndAirportHour, 10)
            : 0,
          minute: values.returnTimeAtEndAirportMinute
            ? parseInt(values.returnTimeAtEndAirportMinute, 10)
            : 0,
        },
        {
          zone:
            values.endAirportLocationTimeZoneResponse?.timeZoneId ||
            values.endAirportLocationTimeZoneIdentifier,
        },
      );
    }

    if (
      _pickupTime &&
      _returnTimeAtStartAirport &&
      _pickupTime?.toMillis() > _returnTimeAtStartAirport.toMillis()
    ) {
      showMessage({
        message: t('common.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description: t('common.startTimeCannotBeBeforeReturnTime', {
          defaultValue: 'Start time cannot be before return time at start airport',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      setLoading(false);
      return;
    }

    const jobInput: JobInput = {
      description: values.description,
      jobType: values.jobType,
      pickupLocationSuggestedLocationInput,
      dropOffLocationSuggestedLocationInput,
      pickupTime: _pickupTime as DateTime,
      descriptionOfItems: values.descriptionOfItems,
      numOfItems: values.numOfItems || 1,
      weightOfItems: values.weightOfItems,
      pickupAddressDescription: values.pickupAddressDescription,
      dropOffAddressDescription: values.dropOffAddressDescription,
      dropOffTime: values.dropOffTime,
      typeOfDelivery,
      transportedGoodsContentType: values.transportedGoodsContentType,
    };
    const clientJobByAgencyInput: ClientJobByAgencyInput = {
      organizationName: values.organizationName || 'Client Name',
      organizationLegalName: 'Client Organization Legal Name',
      organizationPhone: '+49 123 4567890',
      organizationEmail: 'client.test@test.obc.express',
      externalJobReference: 'Client Delivery Job',
      fixedRate: 0,
      currency: Currency.EUR,
    };
    const agencyJobInput: AgencyJobInput = {
      responsibleAgencyOrganizationUserIds: values.responsibleAgencyOrganizationUsers,
      fixedRate: values.fixedRate,
      dailyRate: values.dailyRate,
      kickOffFee: values.kickOffFee,
      hotelBudget: values.hotelBudget,
      currency: values.currency,
      isAccommodationBookedByCourier: values.isAccommodationBookedByCourier,
      isAccommodationPaidByCourier: values.isAccommodationPaidByCourier,
      isAdditionalCostPaidByCourier: values.isAdditionalCostPaidByCourier,
      additionalCostInformation: values.additionalCostInformation,
      isDrivingLicenseRequiredForJob: values.isDrivingLicenseRequiredForJob,
      isCarRequiredForJob: values.isCarRequiredForJob,
      numOfItems: values.numOfItems || 1,
      returnTimeAtStartAirport: _returnTimeAtStartAirport,
      returnTimeAtEndAirport: _returnTimeAtEndAirport,
      weightOfItems: values.weightOfItems,
      jobDetails: values.jobDetails,
      externalJobReference: values.externalJobReference as string,
      internalNotes: values.internalNotes || '',
      numOfCouriersRequired: values.numOfCouriersRequired,
      endAirportSuggestedLocationInput,
      startAirportSuggestedLocationInput,
    };
    if (agencyJobId) {
      agencyJobInput.id = agencyJobId;
    }

    await createOrUpdateJobForAgencyOrganizationMutation({
      variables: {
        agencyJobInput,
        clientJobByAgencyInput,
        courierOrganizationUserIds: courierOrganizationUserId
          ? [courierOrganizationUserId]
          : undefined,
        jobInput,
      },
    })
      .then((result) => {
        if (result?.data?.createOrUpdateJobForAgencyOrganization) {
          setAgencyJobId(result.data.createOrUpdateJobForAgencyOrganization.id);
          navigation.navigate('AgencyJobsScreen', {
            previousTabScreenName: route.params?.previousTabScreenName,
          });
        }
      })
      .catch((e) => {
        const err = e as ErrorResponse;
        const error = err.graphQLErrors?.[0] as any;
        showMessage({
          message: t(`error.internalCode.${error?.extensions?.internalCode}.message`, {
            defaultValue: t('common.error', { defaultValue: 'Error' }),
          }),
          description: t(`error.internalCode.${error?.extensions?.internalCode}.description`, {
            defaultValue:
              error?.message ||
              t('common.somethingWentWrong', {
                defaultValue:
                  'Something went wrong. Please try again, or contact support@priojet.com',
              }),
          }) as string,
          type: 'danger',
          autoHide: true,
          hideOnPress: true,
          duration: 8000,
        });
      });
    setLoading(false);
  };

  const initialValues = {
    responsibleAgencyOrganizationUsers: [],
    jobType: JobType.EMERGENCY,

    externalJobReference: '',
    internalNotes: '',

    selectedDropOffModeIndex: 0,
    pickupTimeDate: null,
    pickupTime: Platform.OS !== 'web' ? '00:00' : null,
    pickupLocation: null,
    pickupLocationText: '',
    pickupLocationTimeZoneResponse: null,
    pickupLocationTimeZoneIdentifier: null,
    pickupAddressDescription: '',
    dropOffTime: null,
    dropOffTimeDate: null,
    dropOffTimeHour: null,
    dropOffTimeMinute: null,
    dropOffLocation: null,
    dropOffLocationText: '',
    dropOffLocationTimeZoneResponse: null,
    dropOffLocationTimeZoneIdentifier: null,
    dropOffAddressDescription: '',

    numOfItems: 1,
    weightOfItems: 0,
    descriptionOfItems: '',
    description: '',
    transportedGoodsContentType: TransportGoodsContentType.OTHERS,

    organizationName: '',
    organizationLegalName: '',
    organizationPhone: '',
    organizationEmail: '',
    clientJobExternalJobReference: '',
    clientJobFixedRate: 0,
    clientJobCurrency: Currency.EUR,

    selectedPickupModeIndex: 0,
    startAirport: null,
    startAirportText: '',
    startAirportLocationTimeZoneResponse: null,
    startAirportLocationTimeZoneIdentifier: null,
    endAirport: null,
    endAirportText: '',
    endAirportLocationTimeZoneResponse: null,
    endAirportLocationTimeZoneIdentifier: null,
    returnTimeAtStartAirportDate: null,
    returnTimeAtStartAirport: Platform.OS !== 'web' ? '00:00' : null,
    returnTimeAtEndAirport: null,
    returnTimeAtEndAirportDate: null,
    returnTimeAtEndAirportHour: null,
    returnTimeAtEndAirportMinute: null,

    currency: Currency.EUR,
    hotelBudget: 0,
    fixedRate: 0,
    dailyRate: 0,
    kickOffFee: 0,

    isAccommodationBookedByCourier: undefined,
    isAccommodationPaidByCourier: undefined,
    isDrivingLicenseRequiredForJob: false,
    isCarRequiredForJob: false,
    jobDetails: '',
    isAdditionalCostPaidByCourier: undefined,
    additionalCostInformation: '',

    numOfCouriersRequired: 1,
  };

  const validationSchema = Yup.object().shape({
    responsibleAgencyOrganizationUsers: Yup.array()
      .required(
        t('jobs.jobAgentRequiredErrorMessage', {
          defaultValue: 'At least one job agent has to be selected',
        }) as string,
      )
      .min(
        1,
        t('jobs.jobAgentRequiredErrorMessage', {
          defaultValue: 'At least one job agent has to be selected',
        }) as string,
      ),
    clientJobCurrency: Yup.string().required(
      t('jobs.clientCurrencyErrorMessage', {
        defaultValue: 'You must select a currency',
      }) as string,
    ),
    currency: Yup.string().required(
      t('jobs.clientCurrencyErrorMessage', {
        defaultValue: 'You must select a currency',
      }) as string,
    ),
    externalJobReference: Yup.string().required(
      t('jobs.externalJobReferenceMustBeSet', {
        defaultValue: 'External Job Reference must be set (no less than 4 characters).',
      }) as string,
    ),
    pickupTimeDate: Yup.string()
      .nullable()
      .required(
        t('jobs.pickupTimeDateMustBeSet', {
          defaultValue: 'Pickup time date must be set.',
        }) as string,
      ),
    pickupTime: Yup.string()
      .nullable()
      .required(
        t('jobs.pickupTimeMustBeSet', { defaultValue: 'Pickup time must be set.' }) as string,
      ),
    pickupLocation:
      selectedPickupModeIndex === 0
        ? Yup.object()
            .nullable()
            .required(
              t('jobs.pickupLocationMustBeSet', {
                defaultValue: 'Pickup location must be set.',
              }) as string,
            )
        : Yup.object().nullable().notRequired(),
    startAirport: Yup.object()
      .nullable()
      .required(
        t('jobs.startAirportMustBeSet', { defaultValue: 'Start airport must be set.' }) as string,
      ),
    dropOffLocation:
      selectedDropOffModeIndex === 0
        ? Yup.object()
            .nullable()
            .nullable()
            .required(
              t('jobs.dropOffLocationMustBeSet', {
                defaultValue: 'Drop-off location must be set.',
              }) as string,
            )
        : Yup.object().nullable().notRequired(),
    endAirport: Yup.object()
      .nullable()
      .required(
        t('jobs.endAirportMustBeSet', { defaultValue: 'End airport must be set.' }) as string,
      ),
    returnTimeAtStartAirportDate: Yup.string()
      .nullable()
      .required(
        t('jobs.returnTimeDateMustBeSet', {
          defaultValue: 'Return time date at start airport must be set.',
        }) as string,
      ),

    returnTimeAtStartAirport: Yup.string()
      .nullable()
      .required(
        t('jobs.returnTimeMustBeSet', {
          defaultValue: 'Return time at start airport must be set.',
        }) as string,
      ),
    numOfCouriersRequired: Yup.number().required(
      t('jobs.numberOfCouriersMustBeSet', {
        defaultValue: 'Number of couriers must be set.',
      }) as string,
    ),
    transportedGoodsContentType: Yup.string().required(
      t('jobs.numberOfCouriersMustBeSet', {
        defaultValue: 'Number of couriers must be set.',
      }) as string,
    ),
    isAccommodationBookedByCourier: Yup.bool().required(
      t('jobs.whoBooksTheAccommodationMustBeSet', {
        defaultValue: 'Who books the accommodation must be set.',
      }) as string,
    ),
    isAccommodationPaidByCourier: Yup.bool().required(
      t('jobs.whoPaysForAccommodationMusBeSet', {
        defaultValue: 'Who pays for accommodation must be set.',
      }) as string,
    ),
    isAdditionalCostPaidByCourier: Yup.bool().required(
      t('jobs.whoPaysForAdditionalCostsMusBeSet', {
        defaultValue: 'Who pays for additional costs must be set.',
      }) as string,
    ),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: !route.params?.agencyJobId,
    onSubmit: handleSave,
  });

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);

    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: fieldError,
        onChange,
        status: fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      errorMessage: fieldError,
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const handleChangeDate = (field: string) => (value: any) => {
    formik.setFieldValue(field, value);
    if (formik.errors[field]) {
      setTimeout(() => {
        formik.validateField(field);
      }, 50);
    }
  };

  const handleSelectAirport =
    (field: 'startAirport' | 'endAirport') => async (value: any, option: any) => {
      if (field === 'startAirport') {
        formik.setFieldValue(field, option);
        if (formik.errors[field]) {
          setTimeout(() => {
            formik.validateField(field);
          }, 100);
        }
        const index = formik.values.selectedPickupModeIndex;
        if (index === 1) {
          formik.setFieldValue('pickupLocation', option);
          formik.setFieldValue('pickupLocationText', option.description);
        }
        if (option.timeZoneIdentifier) {
          formik.setFieldValue('startAirportLocationTimeZoneResponse', {
            timeZoneId: option.timeZoneIdentifier,
            rawOffset: option.timeZoneRawOffset,
          });
          formik.setFieldValue('pickupLocationTimeZoneResponse', {
            timeZoneId: option.timeZoneIdentifier,
            rawOffset: option.timeZoneRawOffset,
          });
        } else {
          await resolveAndSaveTimezoneForLocation({
            timezoneKey: ['startAirportLocationTimeZoneResponse', 'pickupLocationTimeZoneResponse'],
            value: { ...option, geometry: option.locationGeometry },
          });
        }
      } else if (field === 'endAirport') {
        formik.setFieldValue(field, option);
        if (formik.errors[field]) {
          setTimeout(() => {
            formik.validateField(field);
          }, 100);
        }
        if (option.timeZoneIdentifier) {
          formik.setFieldValue('endAirportLocationTimeZoneResponse', {
            timeZoneId: option.timeZoneIdentifier,
            rawOffset: option.timeZoneRawOffset,
          });
        } else {
          await resolveAndSaveTimezoneForLocation({
            timezoneKey: ['endAirportLocationTimeZoneResponse'],
            value: { ...option, geometry: option.locationGeometry },
          });
        }

        const index = formik.values.selectedDropOffModeIndex;
        if (index === 1) {
          formik.setFieldValue('dropOffLocation', option);
        }
      }
    };

  const resolveAndSaveTimezoneForLocation = ({
    timezoneKey,
    timeVariable,
    value,
  }: {
    timezoneKey: string[];
    timeVariable?: any;
    value: GooglePlacesAutocompleteLocation | undefined;
  }) => {
    const now: DateTime = DateTime.now();
    if (!value) {
      return null;
    }
    return resolveTimeZoneForLocation(
      value.geometry,
      timeVariable?.toMillis ? timeVariable?.toMillis() / 1000 : now.toMillis() / 1000,
    )
      .then((timeZoneResponse) => {
        if (timeZoneResponse && timeZoneResponse.status === 'OK') {
          timezoneKey.forEach((key) => {
            formik.setFieldValue(key, timeZoneResponse);
          });
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  const handleSelectLocation =
    (key: string, keyTextField: string, timeVariable?: any, timezoneKey?: string) =>
    async (value: GooglePlacesAutocompleteLocation | undefined) => {
      if (value) {
        formik.setFieldValue(key, value);
        if (value.description) {
          formik.setFieldValue(keyTextField, value.description);
        }
        setTimeout(() => {
          formik.validateField(key);
        }, 200);

        await resolveAndSaveTimezoneForLocation({
          timezoneKey: [timezoneKey as string],
          timeVariable,
          value,
        });
      }
    };

  const onCompletedAgencyJob = (agencyJob: Partial<AgencyJob>) => {
    setPriojetJobIdentifier(agencyJob.job?.priojetJobIdentifier || null);
    if (route.params?.agencyJobId && agencyJob.id) {
      setIsStateVariablesSet(true);
    }

    const _dropOffTime = agencyJob.job?.dropOffTime
      ? agencyJob.job.dropOffTime.setZone(
          agencyJob.job.dropOffLocation.timeZoneIdentifier || undefined,
        )
      : undefined;
    const _pickupTime = agencyJob.job?.pickupTime
      ? agencyJob.job?.pickupTime.setZone(
          agencyJob.job?.pickupLocation.timeZoneIdentifier || undefined,
        )
      : undefined;

    const _returnTimeAtStartAirport = agencyJob.returnTimeAtStartAirport
      ? agencyJob.returnTimeAtStartAirport.setZone(
          agencyJob.startAirport?.timeZoneIdentifier || undefined,
        )
      : undefined;

    const _returnTimeAtEndAirport = agencyJob.returnTimeAtEndAirport
      ? agencyJob.returnTimeAtEndAirport.setZone(
          agencyJob.endAirport?.timeZoneIdentifier || undefined,
        )
      : undefined;

    let returnTimeAtStartAirport = formik.values.returnTimeAtStartAirport;
    let pickupTime = formik.values.pickupTime;

    if (_pickupTime) {
      let hour = _pickupTime.hour.toString();
      let minute = _pickupTime.minute.toString();
      if (hour.length === 1) {
        hour = `0${hour}`;
      }
      if (minute.length === 1) {
        minute = `0${minute}`;
      }
      pickupTime = `${hour}:${minute}`;
    }
    if (_returnTimeAtStartAirport) {
      let hour = _returnTimeAtStartAirport.hour.toString();
      let minute = _returnTimeAtStartAirport.minute.toString();
      if (hour.length === 1) {
        hour = `0${hour}`;
      }
      if (minute.length === 1) {
        minute = `0${minute}`;
      }

      returnTimeAtStartAirport = `${hour}:${minute}`;
    }

    const newValues = {
      ...formik.values,
      responsibleAgencyOrganizationUsers:
        agencyJob.responsibleAgencyOrganizationUsers?.map((item) => item.id) || [],
      externalJobReference: agencyJob.externalJobReference || null,
      internalNotes: agencyJob.internalNotes || '',
      fixedRate: agencyJob.fixedRate || formik.values.fixedRate,
      dailyRate: agencyJob.dailyRate || formik.values.dailyRate,
      kickOffFee: agencyJob.kickOffFee || formik.values.kickOffFee,
      hotelBudget: agencyJob.hotelBudget || formik.values.hotelBudget,
      currency: agencyJob.currency || formik.values.currency,
      transportedGoodsContentType:
        agencyJob.job?.transportedGoodsContentType || formik.values.transportedGoodsContentType,
      isAccommodationBookedByCourier: agencyJob
        ? agencyJob.isAccommodationBookedByCourier
        : formik.values.isAccommodationBookedByCourier,
      isAccommodationPaidByCourier: agencyJob
        ? agencyJob.isAccommodationPaidByCourier
        : formik.values.isAccommodationPaidByCourier,
      isDrivingLicenseRequiredForJob:
        !!agencyJob.isDrivingLicenseRequiredForJob || formik.values.isDrivingLicenseRequiredForJob,
      isCarRequiredForJob: !!agencyJob.isCarRequiredForJob || formik.values.isCarRequiredForJob,
      additionalCostInformation:
        agencyJob.additionalCostInformation || formik.values.additionalCostInformation,
      isAdditionalCostPaidByCourier: agencyJob
        ? agencyJob.isAdditionalCostPaidByCourier
        : formik.values.isAdditionalCostPaidByCourier,

      pickupLocation: agencyJob.job?.pickupLocation
        ? {
            id: agencyJob.job?.pickupLocation?.id,
            placeId: agencyJob.job?.pickupLocation?.placeId,
            formattedAddress: agencyJob.job?.pickupLocation?.formattedAddress,
            formattedShortAddress: agencyJob.job?.pickupLocation?.formattedShortAddress,
            description: agencyJob.job?.pickupLocation?.formattedAddress,
            locationTypes: agencyJob.job?.pickupLocation?.types,
            geometry: {
              location: {
                lat: agencyJob.job?.pickupLocation?.locationGeometry?.location?.lat,
                lng: agencyJob.job?.pickupLocation?.locationGeometry?.location?.lng,
              },
            },
          }
        : formik.values.pickupLocation,
      pickupLocationText:
        agencyJob.job?.pickupLocation?.formattedAddress || formik.values.pickupLocationText,

      pickupLocationTimeZoneIdentifier:
        agencyJob.job?.pickupLocation.timeZoneIdentifier ||
        formik.values.pickupLocationTimeZoneIdentifier,

      dropOffLocation: agencyJob.job?.dropOffLocation
        ? {
            id: agencyJob.job?.dropOffLocation?.id,
            placeId: agencyJob.job?.dropOffLocation?.placeId,
            formattedAddress: agencyJob.job?.dropOffLocation?.formattedAddress,
            formattedShortAddress: agencyJob.job?.dropOffLocation?.formattedShortAddress,
            description: agencyJob.job?.dropOffLocation?.formattedAddress,
            locationTypes: agencyJob.job?.dropOffLocation?.types,
            geometry: {
              location: {
                lat: agencyJob.job?.dropOffLocation?.locationGeometry?.location?.lat,
                lng: agencyJob.job?.dropOffLocation?.locationGeometry?.location?.lng,
              },
            },
          }
        : formik.values.dropOffLocation,
      dropOffLocationText:
        agencyJob.job?.dropOffLocation?.formattedAddress || formik.values.dropOffLocationText,
      dropOffLocationTimeZoneIdentifier:
        agencyJob.job?.dropOffLocation.timeZoneIdentifier ||
        formik.values.dropOffLocationTimeZoneIdentifier,

      startAirport: agencyJob.startAirport
        ? {
            id: agencyJob.startAirport?.id,
            placeId: agencyJob.startAirport?.placeId,
            formattedAddress: agencyJob.startAirport?.formattedAddress,
            formattedShortAddress: agencyJob.startAirport?.formattedShortAddress,
            description: agencyJob.startAirport?.formattedAddress,
            locationTypes: agencyJob.startAirport?.types,
            geometry: {
              location: {
                lat: agencyJob.startAirport?.locationGeometry?.location?.lat,
                lng: agencyJob.startAirport?.locationGeometry?.location?.lng,
              },
            },
          }
        : formik.values.startAirport,
      startAirportText: agencyJob.startAirport?.formattedAddress || formik.values.startAirportText,
      startAirportLocationTimeZoneIdentifier:
        agencyJob.startAirport?.timeZoneIdentifier ||
        formik.values.startAirportLocationTimeZoneIdentifier,

      returnTimeAtStartAirportDate: _returnTimeAtStartAirport
        ? new Date(_returnTimeAtStartAirport.toMillis())
        : undefined,
      returnTimeAtStartAirport,

      // // End airport
      endAirport: agencyJob.endAirport
        ? {
            id: agencyJob.endAirport?.id,
            placeId: agencyJob.endAirport?.placeId,
            formattedAddress: agencyJob.endAirport?.formattedAddress,
            formattedShortAddress: agencyJob.endAirport?.formattedShortAddress,
            description: agencyJob.endAirport?.formattedAddress,
            locationTypes: agencyJob.endAirport?.types,
            geometry: {
              location: {
                lat: agencyJob.endAirport?.locationGeometry?.location?.lat,
                lng: agencyJob.endAirport?.locationGeometry?.location?.lng,
              },
            },
          }
        : formik.values.endAirport,
      endAirportText: agencyJob.endAirport?.formattedAddress || formik.values.endAirportText,
      endAirportLocationTimeZoneIdentifier:
        agencyJob.endAirport?.timeZoneIdentifier ||
        formik.values.endAirportLocationTimeZoneIdentifier,

      returnTimeAtEndAirport: _returnTimeAtEndAirport,
      returnTimeAtEndAirportHour: _returnTimeAtEndAirport
        ? _returnTimeAtEndAirport.hour.toString()
        : formik.values.returnTimeAtEndAirportHour,
      returnTimeAtEndAirportMinute: _returnTimeAtEndAirport
        ? _returnTimeAtEndAirport.minute.toString()
        : formik.values.returnTimeAtEndAirportMinute,

      weightOfItems: agencyJob.job?.weightOfItems || formik.values.weightOfItems,
      jobDetails: agencyJob.jobDetails || formik.values.jobDetails,
      numOfCouriersRequired: agencyJob.numOfCouriersRequired || formik.values.numOfCouriersRequired,
      numOfItems: agencyJob.job?.numOfItems || formik.values.numOfItems,
      selectedPickupModeIndex:
        !agencyJob.job?.typeOfDelivery ||
        ['PICKUP_ADDRESS', JobTypeOfDelivery.DOOR_DOOR, JobTypeOfDelivery.DOOR_AIRPORT].includes(
          agencyJob.job?.typeOfDelivery,
        )
          ? 0
          : 1,

      selectedDropOffModeIndex:
        !agencyJob.job?.typeOfDelivery ||
        [
          'HANDOVER_DEPARTURE_AIRPORT',
          JobTypeOfDelivery.AIRPORT_DOOR,
          JobTypeOfDelivery.DOOR_DOOR,
        ].includes(agencyJob.job?.typeOfDelivery)
          ? 0
          : 1,
      // Job values
      description: agencyJob.job?.description || formik.values.description,
      jobType: agencyJob.job?.jobType || formik.values.jobType,

      // Pickup time
      pickupTime,
      pickupTimeDate: _pickupTime ? new Date(_pickupTime.toMillis()) : formik.values.pickupTimeDate,

      descriptionOfItems: agencyJob.job?.descriptionOfItems || formik.values.descriptionOfItems,
      pickupAddressDescription:
        agencyJob.job?.pickupAddressDescription || formik.values.pickupAddressDescription,
      dropOffAddressDescription:
        agencyJob.job?.dropOffAddressDescription || formik.values.dropOffAddressDescription,

      dropOffTime: _dropOffTime || formik.values.dropOffTime,
      dropOffTimeDate: _dropOffTime ? _dropOffTime.toJSDate() : formik.values.dropOffTimeDate,
      dropOffTimeHour: _dropOffTime ? _dropOffTime.hour.toString() : formik.values.dropOffTimeHour,
      dropOffTimeMinute: _dropOffTime
        ? _dropOffTime.minute.toString()
        : formik.values.dropOffTimeMinute,
      organizationName: agencyJob.clientJob?.organizationName || formik.values.organizationName,
      organizationLegalName:
        agencyJob.clientJob?.organizationLegalName || formik.values.organizationLegalName,
      organizationPhone: agencyJob.clientJob?.organizationPhone || formik.values.organizationPhone,
      organizationEmail: agencyJob.clientJob?.organizationEmail || formik.values.organizationEmail,
      clientJobExternalJobReference:
        agencyJob.clientJob?.externalJobReference || formik.values.clientJobExternalJobReference,
      clientJobFixedRate: agencyJob.clientJob?.fixedRate || formik.values.clientJobFixedRate,
      clientJobCurrency: agencyJob.clientJob?.currency || formik.values.clientJobCurrency,
    };
    formik.setValues(newValues);
    setTimeout(() => {
      formik.validateForm();
    }, 100);
  };

  const {
    data: agencyJobForAgencyOrganizationData,
    loading: agencyJobForAgencyOrganizationLoading,
    error: agencyJobForAgencyOrganizationError,
    refetch: agencyJobForAgencyOrganizationRefetch,
  } = useQuery(AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY, {
    variables: { agencyJobId: route.params?.agencyJobId as string },
    skip: !route.params?.agencyJobId,
    fetchPolicy: isBackendReachable ? 'network-only' : 'cache-first',
    onCompleted: (data) => {
      if (data.agencyJobForAgencyOrganization && completedQueries < 2) {
        setAgencyJobId(route.params?.agencyJobId || null);
        setCompletedQueries((prev) => prev + 1);
        onCompletedAgencyJob(data.agencyJobForAgencyOrganization as Partial<AgencyJob>);
      }
    },
  });

  const { data: agencyOrganizationProfileData } = useQuery<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >(ORGANIZATION_ORGANIZATION_USERS_QUERY, {
    variables: { id: appUserContext.currentUserContext?.organization?.id as string },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    onCompleted: (data) => {
      if (!route.params.agencyJobId) {
        const orgUser = data.organization.organizationUsers?.find(
          (item) => item.user.id === appUserContext.currentUserContext?.user?.id,
        );
        if (orgUser) {
          formik.setFieldValue('responsibleAgencyOrganizationUsers', [orgUser.id]);
          if (formik.errors.responsibleAgencyOrganizationUsers) {
            setTimeout(() => {
              formik.validateField('responsibleAgencyOrganizationUsers');
            }, 100);
          }
        }
      }
    },
  });

  const handleSelectRadioGroup = (field: string) => (index: any) => {
    if (field === 'selectedDropOffModeIndex') {
      if (index !== selectedDropOffModeIndex) {
        formik.setFieldValue(field, index);
        setSelectedDropOffModeIndex((prev) => {
          if (prev === 0 && index === 1 && formik.values.endAirport) {
            formik.setFieldValue('dropOffLocation', formik.values.endAirport);
          } else if (prev === 1 && index === 0 && formik.values.dropOffLocation) {
            formik.setFieldValue('dropOffLocation', null);
            setTimeout(() => {
              formik.validateField('dropOffLocation');
            }, 200);
          }
          return index;
        });
      }
    } else if (field === 'selectedPickupModeIndex') {
      if (index !== selectedPickupModeIndex) {
        formik.setFieldValue(field, index);
        setSelectedPickupModeIndex((prev) => {
          if (prev === 0 && index === 1 && formik.values.startAirport) {
            formik.setFieldValue('pickupLocation', formik.values.startAirport);
          } else if (prev === 1 && index === 0 && formik.values.pickupLocation) {
            formik.setFieldValue('pickupLocation', null);
            setTimeout(() => {
              formik.validateField('pickupLocation');
            }, 200);
          }
          return index;
        });
      }
    }
  };

  const handleSelectJobAgent = (value: any) => {
    if (Platform.OS === 'web') {
      let values = [...formik.values.responsibleAgencyOrganizationUsers];
      if (values.includes(value)) {
        values = values.filter((item) => item !== value);
      } else {
        values = [...values, value];
      }
      formik?.setFieldValue('responsibleAgencyOrganizationUsers', values);
    } else {
      formik?.setFieldValue('responsibleAgencyOrganizationUsers', value);
    }
    setTimeout(() => {
      if (formik.errors.responsibleAgencyOrganizationUsers) {
        formik.validateField('responsibleAgencyOrganizationUsers');
      }
    }, 100);
  };

  const handleShowInternalNotesModal = () => {
    internalNotesModalRef.current?.showInternalNotesModal({
      agencyJobId,
      value: formik.values.internalNotes,
    });
  };

  const handlePickupLocationTextChange = (text: string) => {
    formik.setFieldValue('pickupLocationText', text);
  };

  const handleDropOffLocationTextChange = (text: string) => {
    formik.setFieldValue('dropOffLocationText', text);
  };

  const jobAgentsListData = useMemo(() => {
    if (agencyOrganizationProfileData?.organization?.organizationUsers?.length) {
      return agencyOrganizationProfileData?.organization?.organizationUsers.map((item) => ({
        label: `${item.user.firstNames} ${item.user.lastName}`,
        value: item.id,
      }));
    }
    return [];
  }, [agencyOrganizationProfileData?.organization?.organizationUsers]);

  const transportGoodsContentTypeList = useMemo(
    () =>
      Object.keys(TransportGoodsContentType).map((item) => ({
        value: item,
        name: t(`jobs.${item}`, { defaultValue: item }),
      })),
    [t],
  );

  const currencyList = useMemo(
    () => Object.keys(Currency).map((item) => ({ value: item, name: item })),
    [],
  );

  const pickupTime = useMemo(() => {
    if (!formik?.values) {
      return '';
    }
    const {
      pickupTimeDate,
      pickupTime: pickupTimeValue,
      pickupLocationTimeZoneResponse,
      pickupLocationTimeZoneIdentifier,
    } = formik.values;
    if (!pickupTimeDate || !pickupTimeValue) {
      return '';
    }

    const valueHour = pickupTimeValue.split(':')[0];
    const valueMinute = pickupTimeValue.split(':')[1];
    const _pickupTime = DateTime.fromObject(
      {
        year: pickupTimeDate.getFullYear(),
        month: pickupTimeDate.getMonth() + 1, // Date.getUTCMonth() is 0 for January and has a range from 0 to 11
        day: pickupTimeDate.getDate(),
        hour: valueHour ? parseInt(valueHour, 10) : 0,
        minute: valueMinute ? parseInt(valueMinute, 10) : 0,
        second: 0,
      },
      {
        zone: pickupLocationTimeZoneResponse?.timeZoneId || pickupLocationTimeZoneIdentifier,
      },
    );
    return `${_pickupTime.setLocale(language || 'en').toFormat('ccc, DD')} ${t('common.at', {
      defaultValue: ' at ',
    })} ${_pickupTime?.setLocale(language).toFormat('HH:mm')}`;
  }, [formik?.values, language, t]);

  const returnTimeAtStartAirport = useMemo(() => {
    if (!formik?.values) {
      return '';
    }
    const {
      returnTimeAtStartAirportDate,
      returnTimeAtStartAirport: returnTimeAtStartAirportValue,
      startAirportLocationTimeZoneResponse,
      startAirportLocationTimeZoneIdentifier,
    } = formik.values;

    if (!returnTimeAtStartAirportDate || !returnTimeAtStartAirportValue) {
      return null;
    }

    const valueHour = returnTimeAtStartAirportValue.split(':')[0];
    const valueMinute = returnTimeAtStartAirportValue.split(':')[1];
    const _returnTimeAtStartAirport = DateTime.fromObject(
      {
        year: returnTimeAtStartAirportDate.getFullYear(),
        month: returnTimeAtStartAirportDate.getMonth() + 1,
        day: returnTimeAtStartAirportDate.getDate(),
        hour: valueHour ? parseInt(valueHour, 10) : 0,
        minute: valueMinute ? parseInt(valueMinute, 10) : 0,
      },
      {
        zone:
          startAirportLocationTimeZoneResponse?.timeZoneId ||
          startAirportLocationTimeZoneIdentifier,
      },
    );
    return `${_returnTimeAtStartAirport.setLocale(language).toFormat('ccc, DD')} ${t('common.at', {
      defaultValue: ' at ',
    })} ${_returnTimeAtStartAirport?.setLocale(language).toFormat('HH:mm')}`;
  }, [formik?.values, language, t]);

  return {
    agencyJobForAgencyOrganizationData,
    agencyJobForAgencyOrganizationLoading,
    agencyJobForAgencyOrganizationError,
    agencyJobForAgencyOrganizationRefetch,
    agencyJobId,
    currencyList,
    fieldProps,
    formik,
    handlePickupLocationTextChange,
    handleDropOffLocationTextChange,
    handleChangeDate,
    handleShowInternalNotesModal,
    handleSave,
    handleSelectAirport,
    handleSelectLocation,
    handleSelectJobAgent,
    handleSelectRadioGroup,
    internalNotesModalRef,
    isStateVariablesSet,
    loading,
    priojetJobIdentifier,
    jobAgentsListData,
    transportGoodsContentTypeList,
    pickupTime,
    returnTimeAtStartAirport,
  };
};
