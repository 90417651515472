import { Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginTop: 44,
  },
  scrollToBottomButton: {
    height: 'auto',
    width: 'auto',
    padding: Platform.OS === 'web' ? 16 : 12,
    borderRadius: 24,
    opacity: 1,
    backgroundColor: 'background-basic-color-2',
  },
  scrollDown: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollIcon: {
    width: 20,
    height: 20,
  },
  footer: {
    paddingTop: 10,
    paddingBottom: 20,
  },
});
