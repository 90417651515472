import React, { useContext } from 'react';

import { useMutation } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from 'expo-auth-session';
import * as jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { createApolloClientNoPersist } from '../../apollo/create-apollo-client-no-persist';
import { getAuthorizationHeaders } from '../../apollo/create-auth-link-with-headers';
import { MUTATION_SIGN_OUT_USER_CONTEXT } from '../../apollo/graphql-mutations';
import { SIGN_IN_USER_CONTEXT_QUERY } from '../../apollo/graphql-queries';
import { ConfirmModalContext } from '../../components/common/modal/ConfirmModal';
import { IAppUserContext } from '../../contexts/AppUserContext';
import {
  OrganizationType,
  SignInUserContextQuery,
  SignInUserContextQueryVariables,
  UserContext,
} from '../../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../../globals';
import {
  LocalUserContext,
  LocalUserContexts,
  LocalUserContextTokenResponseOnly,
} from '../../types';
import { parseJsonWithDateTime } from '../../utils/json-parse-with-date-time';
import { getDevice } from '../device/device.module';
import { sha256Hash } from '../helper/helper.module';

export const decryptJwtToken = (token: string): any => {
  return jwtDecode.jwtDecode(token);
};

// TODO: move to service
export const removeUserContextFromUserContexts = async ({
  userContext,
  userContexts,
  setUserContexts,
  appUserContext,
}: {
  userContext: LocalUserContext;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  appUserContext: IAppUserContext;
}) => {
  if (userContexts !== undefined) {
    const newUserContexts = Object.fromEntries(
      Object.entries(userContexts).filter((entry) => {
        return userContext.sub && !entry[0].includes(userContext.sub);
      }),
    );
    if (
      appUserContext.currentUserContext &&
      appUserContext.currentUserContext?.sub &&
      userContext?.sub &&
      appUserContext.currentUserContext?.sub?.startsWith(userContext?.sub)
    ) {
      await storeUserContexts(newUserContexts).then(() => appUserContext.purgeCurrentUserContext());
    } else {
      setUserContexts(newUserContexts);
    }

    showMessage({
      message: `${userContext.user?.email} removed from login`,
      description: `The user accounts for the user ${userContext.user?.email} were removed from the logins`,
      type: 'danger',
    });
  }
};

// TODO: move to util module
export const filterTypenameKeyFromObject = <T extends object>(data: T): Omit<T, '__typename'> => {
  const filtered = filterKeyFromObject<T, '__typename'>(data, '__typename');
  return filtered;
};

// TODO: move to util module
export const filterKeyFromObject = <T extends { [s: string]: any }, K extends string>(
  data: T,
  key: K,
): Omit<T, K> => {
  const r = Object.entries(data).filter((entry) => {
    if (entry[0] && entry[0] === '__typename') {
      return false;
    }
    if (
      !['number', 'string', 'boolean', 'function'].includes(typeof entry[1]) &&
      !Array.isArray(entry[1]) &&
      entry[1] !== undefined &&
      entry[1] !== null &&
      !(entry[1] instanceof DateTime) &&
      !(entry[1] instanceof Date)
    ) {
      entry[1] = filterKeyFromObject<(typeof entry)[1], K>(entry[1], key) as Omit<T, K>;
    }
    return true;
  });
  return Object.fromEntries(r) as T;
};

const mergeSignInUserContextWithUserContext = (
  userContext: LocalUserContext,
  signInUserContext: SignInUserContextQuery,
): LocalUserContext => {
  if (signInUserContext) {
    return { ...userContext, ...signInUserContext } as LocalUserContext;
  }
  return userContext;
};

export const signInUserContext = async ({
  userContext,
  userContexts,
  setUserContexts,
  appUserContext,
}: {
  userContext: LocalUserContext;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  appUserContext: IAppUserContext;
}) => {
  const apolloClient = createApolloClientNoPersist();

  return await apolloClient
    .query<SignInUserContextQuery, SignInUserContextQueryVariables>({
      query: SIGN_IN_USER_CONTEXT_QUERY,
      context: {
        headers: await getAuthorizationHeadersWithSignInToken(userContext),
        exceptionOnUnauthenticated: false, // Make it try to refresh the token.
      },
      fetchPolicy: 'network-only',
    })
    .then((_signInUserContext) => {
      if (_signInUserContext?.data?.signInUserContext) {
        appUserContext.setCurrentUserContext(
          mergeSignInUserContextWithUserContext(userContext, _signInUserContext.data),
        );
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.warn(
        'Login expired. Not changing CurrentUserContext. exceptionOnUnauthenticated.',
        error,
      );
      removeUserContextFromUserContexts({
        userContext,
        userContexts,
        setUserContexts,
        appUserContext,
      });
      return false;
    });
};

export const getAuthorizationHeadersWithSignInToken = async (
  userContext: LocalUserContext,
): Promise<Record<string, string | number | boolean>> => {
  const date = new Date();
  const millis = date.getTime();
  const timestampPartial = Math.floor(millis / (1000 * 60 * 5)); // cropped five minutes

  const signInTokenRaw = `${userContext.tokenResponse?.accessToken}:${userContext.user?.id}:${userContext.organizationUser?.id}:${userContext?.device?.id}:${timestampPartial}`;

  const hash = await sha256Hash(signInTokenRaw);
  const authHeaders = await getAuthorizationHeaders(userContext);
  return {
    ...authHeaders,
    'x-sign-in-token': hash,
  };
};

export const mergeUserContext = (
  userContext: LocalUserContext,
  userContexts: LocalUserContexts,
): LocalUserContexts => {
  const _userContexts: LocalUserContexts = userContexts ? { ...userContexts } : {};
  // ! The condition check below is important, as without a user, we cannot generate a userContextId.
  // ! But there is a currentUserContext with deviceId and device set always.
  if (userContext && userContext.sub) {
    const _newUserContext = {
      ...userContexts[getUserContextId(userContext)],
      ...userContext,
    };
    _userContexts[getUserContextId(_newUserContext)] = _newUserContext;
  }
  return _userContexts;
};

export const useLogout = (appUserContext: IAppUserContext): (() => Promise<void>) => {
  const [authSignOutUserContext] = useMutation(MUTATION_SIGN_OUT_USER_CONTEXT);

  const logout = async () => {
    if (appUserContext.currentUserContext?.deviceId && appUserContext.currentUserContext.user?.id) {
      await authSignOutUserContext({
        variables: {
          data: {
            deviceId: appUserContext.currentUserContext?.deviceId,
            userId: appUserContext.currentUserContext.user?.id,
          },
        },
      });
    }
    if (appUserContext?.currentUserContext?.userContextId) {
      await deleteUserContextWithUserContextIdFromUserContextsInStorage(
        appUserContext?.currentUserContext?.userContextId,
      );
    }
    await deleteCurrentUserContextFromStorage();
    appUserContext.purgeCurrentUserContext();
  };
  return logout;
};

export const useLogoutConfirmation = (appUserContext: IAppUserContext): (() => Promise<void>) => {
  const { t } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const logout = useLogout(appUserContext);

  const logoutConfirmation = async () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('authentication.logout', {
        defaultValue: 'Logout',
      }),
      cancelButtonText: t('authentication.cancel', { defaultValue: 'Cancel' }),
      title: t('authentication.logout', {
        defaultValue: 'Logout',
      }),
      text: t('authentication.logoutText', {
        defaultValue: `Do you want to logout the currently active user with email {{email}} and all its associated accounts?`,
        email: appUserContext?.currentUserContext?.user?.email,
      }),
      onConfirmPress: async () => {
        await logout();
      },
      visible: true,
    });
  };

  return logoutConfirmation;
};

export const createLocalUserContextTokenResponseOnly = async (
  tokenResponse: AuthSession.TokenResponse,
  organizationType?: OrganizationType,
): Promise<LocalUserContextTokenResponseOnly | null> => {
  const { accessToken } = tokenResponse;
  const decryptedToken = decryptJwtToken(accessToken);
  const { sub } = decryptedToken;

  const device = await getDevice();
  const role = '/USER/';
  if (sub && decryptedToken) {
    const userContext: LocalUserContextTokenResponseOnly = {
      sub,
      tokenResponse,
      lastSyncedAt: null,
      userContextId: sub + '/',
      role,
      refreshTokenExpired: false,
      organizationActivatedAt: null,
      deviceId: device.id,
      device,
      signUpOrganizationType: organizationType,
    };
    return userContext;
  }
  return null;
};

export const deleteUserContextsFromStorage = async (): Promise<void> => {
  return AsyncStorage.multiRemove([
    GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
    GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER,
    GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
  ]);
};

export const storeCurrentUserContext = async (
  userContext: LocalUserContext,
  userContextStorageKey: string = GLOBAL_CONSTANTS.CURRENT_USER_CONTEXT_STORAGE_KEY,
) => {
  if (userContext && userContextStorageKey) {
    AsyncStorage.setItem(userContextStorageKey, JSON.stringify(userContext))
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updateMergeAndStoreUserContextWithUserContexts(userContext).then((success) => {
          // if (success) {
          // }
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }
};

export const setUserContextsLastUpdate = async () => {
  await AsyncStorage.setItem(
    GLOBAL_CONSTANTS.USER_CONTEXTS_LAST_UPDATE_STORAGE_KEY,
    new Date().toUTCString(),
  );
};

export const getUserContextsLastUpdate = async (): Promise<Date | undefined> => {
  const timestamp = await AsyncStorage.getItem(
    GLOBAL_CONSTANTS.USER_CONTEXTS_LAST_UPDATE_STORAGE_KEY,
  );
  if (timestamp) {
    return new Date(timestamp);
  }
  return undefined;
};

export const storeUserContexts = async (userContexts: LocalUserContexts) => {
  if (!GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY) {
    console.warn('USER_CONTEXTS_STORAGE_KEY is not defined');
  }
  if (userContexts && Object.keys(userContexts).length > 0) {
    userContexts = Object.fromEntries(
      Object.entries(userContexts).filter(
        (entry) =>
          entry[0] &&
          entry[1].sub !== undefined &&
          entry[1].device !== undefined &&
          entry[1].deviceId !== undefined,
      ),
    );
    await AsyncStorage.setItem(
      GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
      JSON.stringify(userContexts),
    );
  } else {
    await AsyncStorage.setItem(GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY, JSON.stringify({}));
  }
};

export const deleteCurrentUserContextFromStorage = (
  storageKey: string = GLOBAL_CONSTANTS.CURRENT_USER_CONTEXT_STORAGE_KEY,
): Promise<void> => {
  return AsyncStorage.multiRemove([
    storageKey,
    GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER,
    GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
  ]);
};

export const deleteUserContexts = (
  storageKey: string = GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
): Promise<void> => {
  return AsyncStorage.multiRemove([
    storageKey,
    GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER,
    GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
  ]);
};

export const loadCurrentUserContext = async (): Promise<LocalUserContext | null> => {
  const userContext = await AsyncStorage.getItem(GLOBAL_CONSTANTS.CURRENT_USER_CONTEXT_STORAGE_KEY);
  if (userContext) {
    return parseUserContext(userContext);
  }
  return null;
};

export const updateCurrentUserContextTokenResponse = (
  currentUserContext: LocalUserContext,
  setCurrentUserContext: React.Dispatch<React.SetStateAction<LocalUserContext | null>>,
  tokenResponse: AuthSession.TokenResponse,
): boolean => {
  if (currentUserContext && tokenResponse) {
    currentUserContext.tokenResponse = tokenResponse;
    storeCurrentUserContext(currentUserContext).then(() =>
      setCurrentUserContext(currentUserContext),
    );
    return true;
  }
  return false;
};

export const deleteUserContextWithUserContextIdFromUserContextsInStorage = async (
  userContextId: string,
) => {
  const userContexts = await loadUserContexts();
  const newUserContexts: LocalUserContexts = { ...userContexts };
  if (userContexts && userContextId) {
    const _parts = userContextId.split('/');
    const userId = _parts && _parts.length > 0 ? _parts[1] : undefined;
    if (!userId) {
      return;
    }
    for (const [_userContextId, userContext] of Object.entries(userContexts)) {
      if (userContext && _userContextId && _userContextId.includes(userId)) {
        delete newUserContexts[_userContextId];
      }
    }
    await storeUserContexts(newUserContexts);
  }
};

export const deleteUserContextWithAccessTokenFromUserContextsInStorage = async (
  accessToken: string,
) => {
  const userContexts = await loadUserContexts();
  const newUserContexts: LocalUserContexts = { ...userContexts };
  if (userContexts) {
    for (const [userContextId, userContext] of Object.entries(userContexts)) {
      if (
        userContext &&
        userContext.tokenResponse &&
        userContext.tokenResponse?.accessToken === accessToken
      ) {
        delete newUserContexts[userContextId];
      }
    }
    await storeUserContexts(newUserContexts);
  }
};

export const updateUserContext = async (newLocalUserContext: LocalUserContext | null) => {
  if (!newLocalUserContext) {
    return false;
  }
  const currentUserContext = await loadCurrentUserContext();
  // If the given and updated UserContext is equal to the currentUserContext, update it in the storage.
  if (
    currentUserContext &&
    newLocalUserContext.userContextId === currentUserContext.userContextId
  ) {
    await storeCurrentUserContext(newLocalUserContext);
  }
  return await updateMergeAndStoreUserContextWithUserContexts(newLocalUserContext);
};

export const updateMergeAndStoreUserContextWithUserContexts = async (
  newLocalUserContext: LocalUserContext | null,
): Promise<boolean> => {
  if (!newLocalUserContext) {
    return false;
  }
  let userContexts: LocalUserContexts = (await loadUserContexts()) || {};
  userContexts = mergeUserContext(newLocalUserContext, userContexts);
  await storeUserContexts(userContexts);
  return true;
};

export const loadUserContexts = async (
  storageKey: string = GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
): Promise<LocalUserContexts | undefined> => {
  const userContexts = await AsyncStorage.getItem(storageKey);
  if (userContexts) {
    try {
      const _userContexts: LocalUserContexts = parseJsonWithDateTime(
        userContexts,
      ) as LocalUserContexts;

      let entries: [string, LocalUserContext];
      for (entries of Object.entries(_userContexts)) {
        // Filter out the bad ones that are not actually valid UserContext by skipping the iteration
        if (
          entries[0] === undefined ||
          entries[0] === 'undefined' ||
          entries[0] === null ||
          entries[0] === '' ||
          entries[1].sub === undefined ||
          entries[1].sub === 'undefined' ||
          entries[1].sub === null ||
          entries[1].sub === ''
        ) {
          continue;
        }

        if (!entries[1].tokenResponse) {
          if (entries[1].userContextId) {
            deleteUserContextWithUserContextIdFromUserContextsInStorage(entries[1].userContextId);
          }
          continue;
        }
        entries[1].tokenResponse = new AuthSession.TokenResponse(entries[1].tokenResponse);
        entries[1].lastSyncedAt = entries[1].lastSyncedAt
          ? DateTime.fromISO(entries[1].lastSyncedAt.toString())
          : null;
      }
      return _userContexts;
    } catch (e) {
      console.warn('Cannot load and parse UserContexts', e);
      return undefined;
    }
  }
  return undefined;
};

const parseUserContext = (userContext: string | null): LocalUserContext | null => {
  if (userContext && userContext != '') {
    try {
      const parsedUserContext = parseJsonWithDateTime(userContext) as LocalUserContext;

      if (parsedUserContext.userContextId) {
        parsedUserContext.userContextId = getUserContextId(parsedUserContext);
      }

      if (parsedUserContext.tokenResponse) {
        parsedUserContext.tokenResponse = new AuthSession.TokenResponse(
          parsedUserContext.tokenResponse,
        );
        parsedUserContext.lastSyncedAt =
          parsedUserContext.lastSyncedAt && !(parsedUserContext instanceof DateTime)
            ? DateTime.fromISO(parsedUserContext.lastSyncedAt.toString())
            : null;
      }
      return parsedUserContext;
    } catch (e) {
      console.warn('Could not parse UserContext catch(e) ', JSON.stringify(userContext));
      console.warn('Cannot load and parse UserContext', e);
      return null;
    }
  }
  return null;
};

export const getUserContextId = (userContext: LocalUserContext | UserContext): string => {
  return (
    userContext.sub + '/' + (!userContext.organizationUser ? '' : userContext.organizationUser?.id)
  );
};

export const isLoggedIn = (tokenResponse: AuthSession.TokenResponse): boolean => {
  // This should actually check whether the token is expired and then refresh the token if possible.
  if (tokenResponse?.shouldRefresh() !== false) {
    return true;
  }
  return false;
};
