import { useContext, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { useLazyQuery } from '@apollo/client';
import Constants from 'expo-constants';
import { DateTime } from 'luxon';

import { UseHookProps } from './types';
import { QUERY_SUBSCRIPTION_PLANS_COURIER } from '../../../../../apollo/graphql-queries';
import AppUserContext from '../../../../../contexts/AppUserContext';
import ThemeContext from '../../../../../contexts/ThemeContext';
import { SubscriptionPlanPaddleType } from '../../../../../generated-graphql-types';

export const useHook = ({
  onClose,
  onBookSuccess,
  onBookToggle,
  refetchOrganizationSubscriptionPlans,
  visibleModal,
}: UseHookProps) => {
  const appUserContext = useContext(AppUserContext);
  const theme = useContext(ThemeContext);

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanPaddleType | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>('month');
  const [courierPlansData, setCourierPlansData] = useState<any>({});
  const [organizationId, setOrganizationId] = useState<any>();

  const [fetchData] = useLazyQuery(QUERY_SUBSCRIPTION_PLANS_COURIER, {
    fetchPolicy: 'network-only',
  });

  const fetchPlansData = async (attempt = 0) => {
    try {
      const res = await fetchData();
      if (res?.data?.subscriptionPlansCourier) {
        setCourierPlansData(res.data.subscriptionPlansCourier);
        setSelectedPeriod('month');
        setSelectedPlan(res.data.subscriptionPlansCourier.month[0]);
      }
    } catch {
      if (attempt === 0) {
        fetchPlansData(1);
      }
    }
  };

  useEffect(() => {
    if (
      appUserContext.currentUserContext?.organization?.id &&
      appUserContext.currentUserContext?.organization?.id !== organizationId
    ) {
      setOrganizationId(appUserContext.currentUserContext?.organization?.id);
      fetchPlansData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserContext.currentUserContext?.organization?.id]);

  const { courierPlans, hasTrialPeriod } = useMemo(() => {
    if (courierPlansData && courierPlansData[selectedPeriod]) {
      const plans = courierPlansData[selectedPeriod];
      const hasTrial = plans[0]?.trialPeriod;
      return { courierPlans: plans, hasTrialPeriod: hasTrial };
    }
    return { courierPlans: [], hasTrialPeriod: false };
  }, [courierPlansData, selectedPeriod]);

  const handleSelectPeriod = (period: any, _index: number) => {
    setSelectedPlan(undefined);
    setSelectedPeriod(period.value);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setTimeout(() => {
      setSelectedPeriod('month');
      setSelectedPlan(undefined);
    }, 200);
  };

  const handleSelectPlan = (_item: any) => {
    setSelectedPlan(_item);
  };

  const handleBookPress = async () => {
    onClose();
    setTimeout(() => {
      onBookToggle(true);
      if (Platform.OS === 'web') {
        const win: any = window;
        const Paddle = win.Paddle;
        if (appUserContext?.currentUserContext?.organization?.id) {
          Paddle.Checkout.open({
            product: selectedPlan?.planId,
            email: appUserContext.currentUserContext?.user?.email,
            passthrough: JSON.stringify({
              initialCreationDate: DateTime.now().toUTC().toString(),
              organizationId: appUserContext.currentUserContext?.organization?.id,
              userId: appUserContext.currentUserContext?.user?.id,
            }),
            marketingConsent: '1',
            displayModeTheme: theme.theme,
          });
        }
      }
    }, 200);
  };

  const eventCallback = (e: any) => {
    switch (e.event) {
      case 'Checkout.Customer.Details':
        break;
      case 'Checkout.Loaded':
        break;
      case 'Checkout.Vat.Applied':
        break;
      case 'Checkout.Complete':
        refetchOrganizationSubscriptionPlans();
        appUserContext.refetchCurrentUserContext();
        onBookSuccess(e.eventData.product.name);
        break;
      case 'Checkout.Close':
        onBookToggle(false);
        setSelectedPlan(undefined);
        setSelectedPeriod('month');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const lazyLoadFromCDN = (callback: Function) => {
        const mathJax = document.createElement('script');
        mathJax.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js');
        mathJax.addEventListener('load', () => callback());
        document.head.appendChild(mathJax);
      };
      const onLoad = () => {
        const win = window as any;
        const Paddle = win.Paddle;
        Paddle.Environment.set(Constants.expoConfig?.extra?.paddleEnvironment);
        Paddle.Setup({
          vendor: Number(Constants.expoConfig?.extra?.paddleVendorId),
          eventCallback,
        });
      };
      lazyLoadFromCDN(onLoad);
    }
  }, []);

  useEffect(() => {
    if (!selectedPlan && courierPlans[0]) {
      setSelectedPlan(courierPlans[0]);
    }
  }, [courierPlans, selectedPlan, visibleModal]);

  return {
    courierPlans,
    handleBookPress,
    handleClose,
    handleSelectPlan,
    handleSelectPeriod,
    hasTrialPeriod,
    selectedPlan,
    selectedPeriod,
  };
};
