import { useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('screen');

export const isVerySmallDevice = (screenWidth?: number) =>
  screenWidth ? screenWidth <= 375 : width <= 375;

export const isSmallDevice = (screenWidth?: number) =>
  screenWidth ? screenWidth <= 569 : width <= 569;

export const isMediumDevice = (screenWidth?: number) =>
  screenWidth ? screenWidth > 569 && screenWidth <= 850 : width > 569 && width <= 850;

export const isLargeDevice = (screenWidth?: number) =>
  screenWidth ? screenWidth > 850 : width > 850;

export const isExtraLargeDevice = (screenWidth?: number) =>
  screenWidth ? screenWidth > 1200 : width > 1200;

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    window: Dimensions.get('window'),
    screen: Dimensions.get('screen'),
  });

  useEffect(() => {
    Dimensions.addEventListener('change', ({ window, screen }) => {
      setDimensions({ window, screen });
    });
  }, []);

  const isVerySmall = useMemo(() => isVerySmallDevice(dimensions.screen.width), [dimensions]);

  const isSmall = useMemo(() => isSmallDevice(dimensions.window.width), [dimensions]);

  const isMedium = useMemo(() => isMediumDevice(dimensions.window.width), [dimensions]);

  const isLarge = useMemo(() => isLargeDevice(dimensions.window.width), [dimensions]);

  const isExtraLarge = useMemo(() => isExtraLargeDevice(dimensions.window.width), [dimensions]);

  return {
    dimensions,
    isVerySmallDevice: isVerySmall,
    isSmallDevice: isSmall,
    isMediumDevice: isMedium,
    isLargeDevice: isLarge,
    isExtraLargeDevice: isExtraLarge,
  };
};

export default useDimensions;
