import React from 'react';
import { KeyboardAvoidingView, Platform, Pressable, Text, TextInput, View } from 'react-native';

import { Icon, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'react-native-elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { themedStyles } from './styles';
import { Props } from './types';

const MAX_MESSAGE_LENGTH = 1000;

export const ChatInput: React.FC<Props> = ({
  bottomTabBarHeight = 0,
  disableInput,
  disableInputMessage,
  handleTextChange,
  isSending,
  onActionPress,
  onSend,
  messageText,
}) => {
  const { bottom, top } = useSafeAreaInsets();
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { theme } = useTheme();

  const handleChange = (text: string) => {
    handleTextChange(text.slice(0, MAX_MESSAGE_LENGTH));
  };

  let keyboardVerticalOffset = bottomTabBarHeight + (bottom || 24);
  if (bottom) {
    keyboardVerticalOffset = keyboardVerticalOffset - (55 - top);
  }
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={keyboardVerticalOffset}
      style={styles.container}
    >
      <View style={styles.content}>
        {!disableInput && !messageText && (
          <Pressable
            onPress={!disableInput ? onActionPress : undefined}
            style={styles.actionButton}
          >
            <Icon
              fill={(styles.icon as { color: string }).color}
              name="plus-outline"
              style={styles.iconStyle}
            />
          </Pressable>
        )}
        <View style={styles.textInputContainer}>
          {messageText.length > MAX_MESSAGE_LENGTH - 100 && (
            <View style={styles.maxLengthContainer}>
              <Text style={styles.maxLengthText}>
                {t('CHAT.messageMaxLength', { defaultValue: 'Message max length: ' })}{' '}
                {messageText.length}/1000
              </Text>
            </View>
          )}
          <TextInput
            onChangeText={handleChange}
            multiline
            editable={!disableInput}
            placeholder={
              disableInput
                ? disableInputMessage
                : (t('common.typeHere', { defaultValue: 'Type here' }) as string)
            }
            placeholderTextColor={theme.colors?.grey4}
            value={messageText}
            style={styles.textInput}
          />
        </View>
        {!disableInput && (
          <Pressable
            onPress={!isSending ? onSend : undefined}
            style={[styles.sendButton, isSending && styles.sendButtonDisabled]}
          >
            <Icon
              fill={(styles.icon as { color: string }).color}
              name="paper-plane-outline"
              style={styles.iconStyle}
            />
          </Pressable>
        )}
      </View>
    </KeyboardAvoidingView>
  );
};

export default ChatInput;
