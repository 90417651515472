import React, { useContext, useImperativeHandle, useMemo, useState, forwardRef } from 'react';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';

import { Icon, Layout, StyleService, Text } from '@ui-kitten/components';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { globalStyle } from '../../../common/style';
import Colors from '../../../constants/Colors';
import ThemeContext from '../../../contexts/ThemeContext';
import useDimensions from '../../../hooks/useDimensions';

export const AnimatedFullscreenModal = forwardRef(
  (
    {
      children,
      customHeadline,
      headline,
      fullHeight = true,
      fullWidth = true,
      onClose,
      onLayout,
      onModalHide,
      onModalShow,
      reverseHeader,
    }: {
      children?: React.ReactNode;
      customHeadline?: JSX.Element;
      headline?: string | JSX.Element;
      fullHeight?: boolean;
      fullWidth?: boolean;
      onLayout?: any;
      onModalHide?: () => void | undefined;
      onModalShow?: () => void | undefined;
      onClose?: () => void;
      reverseHeader?: boolean;
    },
    ref,
  ): React.ReactElement => {
    const [isVisible, setIsVisible] = useState(false);

    const { top, bottom } = useSafeAreaInsets();
    const { theme } = useContext(ThemeContext);
    const { dimensions } = useDimensions();

    const modalWidth = useMemo(() => {
      if (!fullWidth) {
        return 'auto';
      }
      if (dimensions.window.width > 1170) {
        return 1170;
      }
      return dimensions.window.width - 30;
    }, [dimensions.window, fullWidth]);

    const toggleModal = () => {
      setIsVisible((prev) => !prev);
    };

    const hideModal = () => {
      setIsVisible(false);
    };

    const showModal = () => {
      setIsVisible(true);
    };

    useImperativeHandle(ref, () => ({
      hideModal,
      toggleModal,
      showModal,
    }));

    return (
      <Modal
        animationIn={Platform.OS === 'web' ? 'fadeIn' : 'slideInUp'}
        animationOut={Platform.OS === 'web' ? 'fadeOut' : 'slideOutDown'}
        isVisible={isVisible}
        onBackdropPress={toggleModal}
        onModalHide={onModalHide}
        onModalShow={onModalShow}
        style={styles.modalStyle}
      >
        <Layout
          style={[
            styles.modalView,
            fullHeight && styles.modalViewFullHeight,
            { marginTop: top, marginBottom: (bottom || 20) + 10, width: modalWidth },
          ]}
          onLayout={onLayout}
        >
          <View style={[styles.header, reverseHeader && globalStyle.flexRowReverse]}>
            {!customHeadline && (
              <Text style={styles.headerTitle} selectable={true} category="h6">
                {headline ? headline : ''}
              </Text>
            )}
            {!!customHeadline && customHeadline}
            <TouchableWithoutFeedback
              hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
              style={styles.closeButton}
              onPress={onClose ? onClose : toggleModal}
            >
              <Icon
                name="close-outline"
                height={28}
                width={28}
                fill={theme === 'light' ? Colors.dark.background : Colors.light.background}
              />
            </TouchableWithoutFeedback>
          </View>
          <ScrollView
            style={[
              fullHeight && globalStyle.flex1,
              globalStyle.paddingHorizontal20,
              { paddingBottom: bottom },
            ]}
            contentContainerStyle={[fullHeight && globalStyle.flex1, globalStyle.marginBottom20]}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
          >
            {children}
          </ScrollView>
        </Layout>
      </Modal>
    );
  },
);

const styles = StyleService.create({
  container: {
    flex: 1,
    flexGrow: 1,
    maxWidth: Platform.OS === 'web' ? 1200 : '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    backgroundColor: 'transparent',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    paddingVertical: 10,
  },
  headerTitle: {
    flex: 1,
    marginLeft: 30,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
  },
  closeButton: {
    width: 'auto',
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalStyle: {
    ...StyleSheet.absoluteFillObject,
    marginBottom: Platform.OS === 'web' ? '10%' : 0,
    marginHorizontal: 0,
    height: Platform.OS === 'web' ? 'auto' : '100%',
    maxHeight: Platform.OS === 'web' ? '90%' : '100%',
  },
  modalView: {
    alignSelf: 'center',
    borderRadius: 10,
  },
  modalViewFullHeight: {
    flex: 1,
    flexGrow: 1,
  },
});
