import { Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  wrapper: {
    borderRadius: 6,
    overflow: 'hidden',
    maxHeight: Platform.OS === 'web' ? '100%' : '90%',
    backgroundColor: 'background-basic-color-1',
    maxWidth: 600,
  },
  scrollWrapper: {
    width: '100%',
    height: '100%',
    borderRadius: 6,
    overflow: 'hidden',
  },
  inner: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: 'background-basic-color-1',
    padding: 20,
    paddingTop: 14,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 15,
    height: 34,
    width: 34,
    borderRadius: 17,
    marginTop: -3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background-basic-color-1',
  },
  closeIcon: {
    color: 'text-basic-color',
  },
  title: {
    marginTop: 5,
    marginBottom: 20,
    paddingRight: 40,
    paddingLeft: 40,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
  },
  subtitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
    marginBottom: 30,
  },
  loading: {
    color: 'white',
  },
  buttonsContainer: {
    width: '100%',
    marginTop: 15,
  },

  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  label: {},
  maxLength: {
    fontSize: 13,
  },
  maxLengthError: {
    color: 'color-danger-600',
    fontSize: 13,
  },

  errorContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: 2,
  },
  errorMessageText: {
    fontSize: 13,
    color: 'color-danger-500',
  },
});
