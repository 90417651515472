import React from 'react';
import { Dimensions, Platform } from 'react-native';

import { Text, TopNavigation } from '@ui-kitten/components/ui';

export const TopNavigationWithoutAccessories = ({ title }: { title: string }) => {
  return (
    <TopNavigation
      alignment="center"
      title={(props) => (
        <Text
          selectable={true}
          {...props}
          style={{
            fontFamily: 'Lato_700Bold',
            fontSize:
              Platform.OS === 'web' && Dimensions.get('window').width > 400 ? 18 : 16,
          }}
        >
          {title.toUpperCase()}
        </Text>
      )}
    />
  );
};
