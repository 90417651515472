import { useContext } from 'react';

import AppContext from '../contexts/AppContext';

export const useIsBackendReachable = (): boolean => {
  const appContext = useContext(AppContext);

  return (
    (appContext?.connectionState?.isConnected === true &&
      appContext?.connectionState?.isInternetReachable === true &&
      appContext?.isBackendReachable === true) ||
    false
  );
};
