import React, { useContext, useMemo } from 'react';
import { Platform, View } from 'react-native';

import { useMutation } from '@apollo/client';
import { A } from '@expo/html-elements';
import { useNavigation } from '@react-navigation/native';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { MenuItem, Text } from '@ui-kitten/components/ui';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { MenuCategory } from './menu-category.component';
import {
  MUTATION_DELETE_AGENCY_ACCOUNT,
  MUTATION_DELETE_COURIER_ACCOUNT,
  MUTATION_USER_DELETE_ACCOUNT,
} from '../../../apollo/graphql-mutations';
import { globalStyle } from '../../../common/style';
import AppUserContext from '../../../contexts/AppUserContext';
import { OrganizationType, OrganizationUserRoleType } from '../../../generated-graphql-types';
import {
  useLogout,
  useLogoutConfirmation,
} from '../../../modules/authentication/authentication.module';
import { getMinimumAvailableUserRoles } from '../../../utils/role.util';
import { HasPermissions } from '../HasPermissions';
import { ConfirmModalContext } from '../modal/ConfirmModal';

const Link: any = A;

export const CommonMenuItems = () => {
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const themedStyles = useStyleSheet(styles);
  const appUserContext = useContext(AppUserContext);
  const logoutConfirmation = useLogoutConfirmation(appUserContext);
  const { showConfirmModal, updateConfirmModal } = useContext(ConfirmModalContext);
  const logout = useLogout(appUserContext);

  const [deleteAgencyAccount] = useMutation(MUTATION_DELETE_AGENCY_ACCOUNT);
  const [deleteCourierAccount] = useMutation(MUTATION_DELETE_COURIER_ACCOUNT);
  const [userDeleteAccount] = useMutation(MUTATION_USER_DELETE_ACCOUNT);

  const faqUrl = useMemo(() => {
    if (appUserContext?.currentUserContext?.organizationType === OrganizationType.COURIER) {
      return Constants.expoConfig?.extra?.priojetCourierFaqUrl;
    }
    if (appUserContext?.currentUserContext?.organizationType === OrganizationType.AGENCY) {
      return Constants.expoConfig?.extra?.priojetAgencyFaqUrl;
    }
    return Constants.expoConfig?.extra?.priojetGeneralFaqUrl;
  }, [appUserContext?.currentUserContext?.organizationType]);

  const handleSuccessMessage = () => {
    showMessage({
      message: t('deleteAccount.deleteSuccess.message', {
        defaultValue: 'Account is now deleted',
      }),
      description: t('deleteAccount.deleteSuccess.description', {
        defaultValue:
          "It's unfortunate to see you go! But if you change your mind, you can contact support@priojet.com within 30 days to re-activate your account. Otherwise, the account will be permanently deleted and will require you to create and activate a new account again.",
      }) as string,
      type: 'success',
      autoHide: true,
      hideOnPress: true,
      duration: 5000,
    });
  };

  const handleErrorMessage = ({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors) {
      showMessage({
        message: 'Error',
        description:
          (graphQLErrors as any)?.[0]?.message ||
          t('common.somethingWentWrong', {
            defaultValue: 'Something went wrong. Please try again, or contact support@priojet.com',
          }),
        type: 'danger',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
    } else if (networkError) {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }),
        description: t('common.somethingWentWrong', {
          defaultValue: 'Something went wrong. Please try again, or contact support@priojet.com',
        }) as string,
        type: 'danger',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
    }
  };

  const handleDeleteAccountPress =
    (organizationType: OrganizationType, type?: 'organization' | 'user') => () => {
      let title = t('deleteAccount.confirmModal.title', { defaultValue: 'Delete account?' });
      let text = t('deleteAccount.confirmModal.text', {
        defaultValue:
          'Are you sure you want to delete your account? \n\nYou will be able to re-activate your account within 30 days, after you will need to create a new one!',
      });

      if (organizationType === OrganizationType.AGENCY && type === 'organization') {
        title = t('deleteAccount.confirmModal.deleteAgencyAccount', {
          defaultValue: 'Delete agency account?',
        });
        if (
          appUserContext.currentUserContext?.organization?.organizationUsers &&
          appUserContext.currentUserContext?.organization?.organizationUsers?.length > 1
        ) {
          text = t('deleteAccount.confirmModal.text', {
            defaultValue:
              'Are you sure you want to delete your agency account? Deleting your agency account will also remove any connections with your employees, and couriers, delete any existing future jobs etc. \n\nYou will be able to re-activate your account within 30 days, after you will need to create a new one!',
          });
        } else {
          text = t('deleteAccount.confirmModal.text', {
            defaultValue:
              'Are you sure you want to delete your agency account? Deleting your agency account will also delete all connected references to your agency (like existing future jobs). \n\nYou will be able to re-activate your account within 30 days, after you will need to create a new one!',
          });
        }
      }
      showConfirmModal({
        confirmButtonStatus: 'danger',
        confirmButtonAppearance: 'filled',
        confirmButtonText: t('deleteAccount.confirmModal.confirmButtonText', {
          defaultValue: 'Deleted',
        }),
        title,
        text,
        cancelButtonText: t('deleteAccount.confirmModal.cancelButtonText', {
          defaultValue: 'Account is now deleted',
        }),
        inputLabel: t('deleteAccount.confirmModal.inputLabel', {
          defaultValue: 'Enter DELETE to confirm account deletion.',
        }),
        showInput: true,
        onConfirmPress: async ({ text: confirmationText }: { text: string }) => {
          if (confirmationText !== 'DELETE') {
            updateConfirmModal({
              errorText: t('deleteAccount.forbiddenAction.inputErrorDescription', {
                defaultValue: 'Please enter DELETE to confirm account deletion.',
              }),
            });
            return false;
          }

          let mutation = deleteCourierAccount;
          if (organizationType === OrganizationType.AGENCY) {
            if (type === 'organization') {
              mutation = deleteAgencyAccount;
            } else {
              mutation = userDeleteAccount;
            }
          }
          try {
            if (mutation) {
              await mutation({
                variables: { userId: appUserContext.currentUserContext?.user?.id },
              });
              await logout();
              handleSuccessMessage();
            }
          } catch (e) {
            handleErrorMessage(e);
          }
        },
        visible: true,
      });
    };

  const renderDeleteButtons = () => {
    if (appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY) {
      return (
        <>
          <MenuItem
            title={(evaProps) => (
              <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
                {t('menuItems.deleteMyAccount', { defaultValue: 'Delete my account' }) as string}
              </Text>
            )}
            onPress={handleDeleteAccountPress(OrganizationType.AGENCY, 'user')}
          />
          <HasPermissions
            organizationType={[OrganizationType.AGENCY]}
            organizationUserRoleType={getMinimumAvailableUserRoles(OrganizationUserRoleType.OWNER)}
          >
            <MenuItem
              title={(evaProps) => (
                <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
                  {
                    t('menuItems.deleteAgencyAccount', {
                      defaultValue: 'Delete agency account',
                    }) as string
                  }
                </Text>
              )}
              onPress={handleDeleteAccountPress(OrganizationType.AGENCY, 'organization')}
            />
          </HasPermissions>
        </>
      );
    }
    if (appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER) {
      return (
        <MenuItem
          title={(evaProps) => (
            <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
              {
                t('menuItems.deleteAccount', {
                  defaultValue: 'Delete account',
                }) as string
              }
            </Text>
          )}
          onPress={handleDeleteAccountPress(OrganizationType.COURIER, 'organization')}
        />
      );
    }
    return <></>;
  };

  const renderLegalSection = () => (
    <>
      <MenuItem
        onPress={() => {
          WebBrowser.openBrowserAsync(Constants.expoConfig?.extra?.priojetDataPrivacyEndpoint);
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {
              t('menuItems.dataPrivacyPolicy', {
                defaultValue: 'Data Privacy Policy',
              }) as string
            }
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          WebBrowser.openBrowserAsync(Constants.expoConfig?.extra?.priojetDataImprintEndpoint);
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.Imprint', { defaultValue: 'Imprint' }) as string}
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonAppInformationScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {
              t('menuItems.appInformation', {
                defaultValue: 'App Information',
              }) as string
            }
          </Text>
        )}
      />

      {renderDeleteButtons()}
    </>
  );

  return (
    <>
      <MenuCategory
        title={
          t('menuItems.generalSettingsAndUserAccount', {
            defaultValue: 'General Settings and User Account',
          }) as string
        }
      />

      {appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY &&
        appUserContext.currentUserContext?.organizationUser?.roleType ===
          OrganizationUserRoleType.OWNER && (
          <MenuItem
            title={(evaProps) => (
              <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
                {
                  t('menuItems.agencyAccountSettings', {
                    defaultValue: 'Agency Account Settings',
                  }) as string
                }
              </Text>
            )}
            onPress={() => {
              navigation.navigate('CommonProfileNavigator', {
                screen: 'CommonAgencyAccountSettingsScreen',
              });
            }}
          />
        )}

      {appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER &&
        appUserContext.currentUserContext?.organizationUser?.roleType ===
          OrganizationUserRoleType.OWNER && (
          <MenuItem
            title={(evaProps) => (
              <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
                {
                  t('menuItems.courierAccountSettings', {
                    defaultValue: 'Courier Account Settings',
                  }) as string
                }
              </Text>
            )}
            onPress={() => {
              navigation.navigate('CommonProfileNavigator', {
                screen: 'CommonCourierAccountSettingsScreen',
              });
            }}
          />
        )}

      <MenuItem
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.appSettings', { defaultValue: 'App Settings' }) as string}
          </Text>
        )}
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonAppSettingsScreen',
          });
        }}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonSwitchAccountScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.switchAccount', { defaultValue: 'Switch Account' }) as string}
          </Text>
        )}
      />

      <MenuItem
        onPress={logoutConfirmation}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.logOut', { defaultValue: 'Log out' }) as string}
          </Text>
        )}
      />

      <MenuCategory title={t('menuItems.Other', { defaultValue: 'Other' }) as string} />

      <MenuItem
        title={(evaProps) => (
          <Link
            {...evaProps}
            style={[evaProps?.style, themedStyles.otherMenuItems, globalStyle.paddingTop5]}
            href={faqUrl}
            hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
          >
            {t('menuItems.FAQ', { defaultValue: 'FAQ' }) as string}
          </Link>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonFeatureHelloNextScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {
              t('menuItems.featureRequest', {
                defaultValue: 'Feature Request',
              }) as string
            }
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonFeatureHelloNextScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {
              t('menuItems.voteForNewFeatures', {
                defaultValue: 'Vote for new features',
              }) as string
            }
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonBugHelloNextScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.reportABug', { defaultValue: 'Report a bug' }) as string}
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonChatSupportPipedriveScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.getSupport', { defaultValue: 'Get Support' }) as string}
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonChangelogHelloNextScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.Changelog', { defaultValue: 'Changelog' }) as string}
          </Text>
        )}
      />

      <MenuItem
        onPress={() => {
          navigation.navigate('CommonProfileNavigator', {
            screen: 'CommonRoadmapHelloNextScreen',
          });
        }}
        title={(evaProps) => (
          <Text {...evaProps} style={[evaProps?.style, themedStyles.otherMenuItems]}>
            {t('menuItems.Roadmap', { defaultValue: 'Roadmap' }) as string}
          </Text>
        )}
      />

      <MenuCategory title={t('menuItems.Legal', { defaultValue: 'Legal' }) as string} />

      {renderLegalSection()}

      <View style={globalStyle.marginTop20} />
      <MenuItem
        style={globalStyle.marginTop30}
        title={() => (
          <View style={themedStyles.bottomInfoLayer}>
            <Text selectable={true} style={themedStyles.bottomInfoLayerText}>
              App Version: {Constants.expoConfig?.version as string}
              {'\n'}
              Build:{' '}
              {Platform.OS === 'ios'
                ? (Constants.expoConfig?.ios?.buildNumber as string)
                : (Constants.expoConfig?.android?.versionCode as unknown as string)}
            </Text>
          </View>
        )}
      />
    </>
  );
};

const styles = StyleService.create({
  bottomInfoLayerText: {
    textAlign: 'center',
    color: 'color-basic-transparent-600',
  },
  bottomInfoLayer: {
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
    paddingTop: 20,
    paddingBottom: 30,
    backgroundColor: 'transparent',
  },
  otherMenuItems: {
    color: 'color-primary-default',
    textAlign: 'center',
  },
  menu: {
    flex: 1,
    margin: 0,
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
});
