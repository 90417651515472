import { StyleService } from '@ui-kitten/components';

export const globalStyle = StyleService.create({
  width25Percent: { width: '25%' },
  width50Percent: { width: '50%' },
  width75Percent: { width: '75%' },
  width90Percent: { width: '90%' },
  width100Percent: { width: '100%' },
  width25: { width: 25 },
  width50: { width: 50 },
  width75: { width: 75 },
  width100: { width: 100 },
  width150: { width: 150 },
  width200: { width: 200 },
  width250: { width: 250 },
  width300: { width: 300 },
  height25Percent: { height: '25%' },
  height50Percent: { height: '50%' },
  height100Percent: { height: '100%' },
  height16: { height: 16 },
  height25: { height: 25 },
  height30: { height: 30 },
  height50: { height: 50 },
  height75: { height: 75 },
  height100: { height: 100 },
  height150: { height: 150 },
  height200: { height: 200 },
  height250: { height: 250 },
  height300: { height: 300 },
  flex0: { flex: 0 },
  flex1: { flex: 1 },
  flexColumn: { flexDirection: 'column' },
  flexRow: { flexDirection: 'row' },
  flexRowReverse: { flexDirection: 'row-reverse' },
  flexWrap: { flexWrap: 'wrap' },
  flexShrink0: { flexShrink: 0 },
  flexShrink1: { flexShrink: 1 },
  flexGrow0: { flexGrow: 0 },
  flexGrow1: { flexGrow: 1 },
  alignItemsStart: { alignItems: 'flex-start' },
  alignItemsCenter: { alignItems: 'center' },
  alignItemsBaseline: { alignItems: 'baseline' },
  alignItemsEnd: { alignItems: 'flex-end' },
  alignItemsStretch: { alignItems: 'stretch' },
  justifyContentStart: { justifyContent: 'flex-start' },
  justifyContentCenter: { justifyContent: 'center' },
  justifyContentBetween: { justifyContent: 'space-between' },
  justifyContentAround: { justifyContent: 'space-around' },
  justifyContentEvenly: { justifyContent: 'space-evenly' },
  justifyContentEnd: { justifyContent: 'flex-end' },
  alignContentStretch: { alignContent: 'stretch' },
  alignContentStart: { alignContent: 'flex-start' },
  alignContentCenter: { alignContent: 'center' },
  alignContentAround: { alignContent: 'space-around' },
  alignContentBetween: { alignContent: 'space-between' },
  alignContentEnd: { alignContent: 'flex-end' },
  alignSelfStart: { alignSelf: 'flex-start' },
  alignSelfBaseline: { alignSelf: 'baseline' },
  alignSelfCenter: { alignSelf: 'center' },
  alignSelfAuto: { alignSelf: 'auto' },
  alignSelfStretch: { alignSelf: 'stretch' },
  alignSelfEnd: { alignSelf: 'flex-end' },

  relative: { position: 'relative' },
  absolute: { position: 'absolute' },
  verticalAlignTop: { verticalAlign: 'top' },
  verticalAlignMiddle: { verticalAlign: 'middle' },
  verticalAlignBottom: { verticalAlign: 'bottom' },
  absoluteFull: { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 },
  overflowHidden: { overflow: 'hidden' },
  overflowScroll: { overflow: 'scroll' },

  // PADDING
  padding0: { padding: 0 },
  padding2: { padding: 2 },
  padding5: { padding: 5 },
  padding10: { padding: 10 },
  padding15: { padding: 15 },
  padding20: { padding: 20 },
  padding30: { padding: 30 },
  paddingVertical0: { paddingVertical: 0 },
  paddingVertical2: { paddingVertical: 2 },
  paddingVertical5: { paddingVertical: 5 },
  paddingVertical10: { paddingVertical: 10 },
  paddingVertical15: { paddingVertical: 15 },
  paddingVertical20: { paddingVertical: 20 },
  paddingVertical30: { paddingVertical: 30 },
  paddingHorizontal0: { paddingHorizontal: 0 },
  paddingHorizontal2: { paddingHorizontal: 2 },
  paddingHorizontal5: { paddingHorizontal: 5 },
  paddingHorizontal10: { paddingHorizontal: 10 },
  paddingHorizontal15: { paddingHorizontal: 15 },
  paddingHorizontal20: { paddingHorizontal: 20 },
  paddingHorizontal30: { paddingHorizontal: 30 },
  paddingLeft0: { paddingLeft: 0 },
  paddingLeft2: { paddingLeft: 2 },
  paddingLeft5: { paddingLeft: 5 },
  paddingLeft10: { paddingLeft: 10 },
  paddingLeft15: { paddingLeft: 15 },
  paddingLeft20: { paddingLeft: 20 },
  paddingLeft30: { paddingLeft: 30 },
  paddingTop0: { paddingTop: 0 },
  paddingTop2: { paddingTop: 2 },
  paddingTop5: { paddingTop: 5 },
  paddingTop10: { paddingTop: 10 },
  paddingTop15: { paddingTop: 15 },
  paddingTop20: { paddingTop: 20 },
  paddingTop30: { paddingTop: 30 },
  paddingTop50: { paddingTop: 50 },
  paddingRight0: { paddingRight: 0 },
  paddingRight2: { paddingRight: 2 },
  paddingRight5: { paddingRight: 5 },
  paddingRight10: { paddingRight: 10 },
  paddingRight15: { paddingRight: 15 },
  paddingRight20: { paddingRight: 20 },
  paddingRight30: { paddingRight: 30 },
  paddingBottom0: { paddingBottom: 0 },
  paddingBottom2: { paddingBottom: 2 },
  paddingBottom5: { paddingBottom: 5 },
  paddingBottom10: { paddingBottom: 10 },
  paddingBottom15: { paddingBottom: 15 },
  paddingBottom20: { paddingBottom: 20 },
  paddingBottom25: { paddingBottom: 25 },
  paddingBottom30: { paddingBottom: 30 },
  // MARGIN
  margin0: { margin: 0 },
  margin2: { margin: 2 },
  margin5: { margin: 5 },
  margin10: { margin: 10 },
  margin15: { margin: 15 },
  margin20: { margin: 20 },
  margin30: { margin: 30 },
  marginVertical0: { marginVertical: 0 },
  marginVertical2: { marginVertical: 2 },
  marginVertical5: { marginVertical: 5 },
  marginVertical10: { marginVertical: 10 },
  marginVertical15: { marginVertical: 15 },
  marginVertical20: { marginVertical: 20 },
  marginVertical30: { marginVertical: 30 },
  marginHorizontal0: { marginHorizontal: 0 },
  marginHorizontal2: { marginHorizontal: 2 },
  marginHorizontal5: { marginHorizontal: 5 },
  marginHorizontal10: { marginHorizontal: 10 },
  marginHorizontal15: { marginHorizontal: 15 },
  marginHorizontal20: { marginHorizontal: 20 },
  marginHorizontal30: { marginHorizontal: 30 },
  marginLeft0: { marginLeft: 0 },
  marginLeft2: { marginLeft: 2 },
  marginLeft5: { marginLeft: 5 },
  marginLeft10: { marginLeft: 10 },
  marginLeft15: { marginLeft: 15 },
  marginLeft20: { marginLeft: 20 },
  marginLeft30: { marginLeft: 30 },
  marginTop0: { marginTop: 0 },
  marginTop2: { marginTop: 2 },
  marginTop5: { marginTop: 5 },
  marginTop10: { marginTop: 10 },
  marginTop15: { marginTop: 15 },
  marginTop20: { marginTop: 20 },
  marginTop30: { marginTop: 30 },
  marginRight0: { marginRight: 0 },
  marginRight2: { marginRight: 2 },
  marginRight5: { marginRight: 5 },
  marginRight10: { marginRight: 10 },
  marginRight15: { marginRight: 15 },
  marginRight20: { marginRight: 20 },
  marginRight30: { marginRight: 30 },
  marginBottom0: { marginBottom: 0 },
  marginBottom2: { marginBottom: 2 },
  marginBottom5: { marginBottom: 5 },
  marginBottom10: { marginBottom: 10 },
  marginBottom15: { marginBottom: 15 },
  marginBottom20: { marginBottom: 20 },
  marginBottom30: { marginBottom: 30 },
  // TEXT
  fontLatoLight: { fontFamily: 'Lato_300Light' },
  fontLatoRegular: { fontFamily: 'Lato_400Regular' },
  fontLatoBold: { fontFamily: 'Lato_700Bold' },
  fontLatoBlack: { fontFamily: 'Lato_900Black' },
  fontSansLight: { fontFamily: 'OpenSans-Light' },
  fontSansRegular: { fontFamily: 'OpenSans-Regular' },
  fontSansBold: { fontFamily: 'OpenSans-Bold' },
  fontSansBlack: { fontFamily: 'OpenSans-ExtraBold' },
  fontItalic: { fontStyle: 'italic' },
  fontSize10: { fontSize: 10 },
  fontSize11: { fontSize: 11 },
  fontSize12: { fontSize: 12 },
  fontSize13: { fontSize: 13 },
  fontSize14: { fontSize: 14 },
  fontSize15: { fontSize: 15 },
  fontSize16: { fontSize: 16 },
  fontSize17: { fontSize: 17 },
  fontSize18: { fontSize: 18 },
  fontSize19: { fontSize: 19 },
  fontSize20: { fontSize: 20 },
  fontSize22: { fontSize: 22 },
  fontSize24: { fontSize: 24 },
  fontSize26: { fontSize: 26 },
  fontSize28: { fontSize: 28 },
  fontSize30: { fontSize: 30 },
  textTransformUppercase: { textTransform: 'uppercase' },
  textAlignLeft: { textAlign: 'left' },
  textAlignCenter: { textAlign: 'center' },
  textAlignRight: { textAlign: 'right' },
  textDecorationUnderline: { textDecorationLine: 'underline' },

  // BORDER
  borderWidth0: { borderWidth: 0 },
  borderWidth1: { borderWidth: 1 },
  borderWidth2: { borderWidth: 2 },
  borderWidth3: { borderWidth: 3 },
  borderWidth4: { borderWidth: 4 },
  borderWidth5: { borderWidth: 5 },
  borderTopWidth1: { borderWidth: 1 },
  borderTopWidth2: { borderWidth: 2 },
  borderTopWidth3: { borderWidth: 3 },
  borderTopWidth4: { borderWidth: 4 },
  borderTopWidth5: { borderWidth: 5 },
  borderLeftWidth1: { borderLeftWidth: 1 },
  borderLeftWidth2: { borderLeftWidth: 2 },
  borderLeftWidth3: { borderLeftWidth: 3 },
  borderLeftWidth4: { borderLeftWidth: 4 },
  borderLeftWidth5: { borderLeftWidth: 5 },
  borderBottomWidth1: { borderBottomWidth: 1 },
  borderBottomWidth2: { borderBottomWidth: 2 },
  borderBottomWidth3: { borderBottomWidth: 3 },
  borderBottomWidth4: { borderBottomWidth: 4 },
  borderBottomWidth5: { borderBottomWidth: 5 },
  borderRightWidth1: { borderRightWidth: 1 },
  borderRightWidth2: { borderRightWidth: 2 },
  borderRightWidth3: { borderRightWidth: 3 },
  borderRightWidth4: { borderRightWidth: 4 },
  borderRightWidth5: { borderRightWidth: 5 },
  borderBasicColor1: { borderColor: 'border-basic-color-1' },
  borderBasicColor2: { borderColor: 'border-basic-color-2' },
  borderBasicColor3: { borderColor: 'border-basic-color-3' },
  borderBasicColor4: { borderColor: 'border-basic-color-4' },
  borderBasicColor5: { borderColor: 'border-basic-color-5' },
  borderTopBasicColor1: { borderTopColor: 'border-basic-color-1' },
  borderTopBasicColor2: { borderTopColor: 'border-basic-color-2' },
  borderTopBasicColor3: { borderTopColor: 'border-basic-color-3' },
  borderTopBasicColor4: { borderTopColor: 'border-basic-color-4' },
  borderTopBasicColor5: { borderTopColor: 'border-basic-color-5' },
  borderBottomBasicColor1: { borderBottomColor: 'border-basic-color-1' },
  borderBottomBasicColor2: { borderBottomColor: 'border-basic-color-2' },
  borderBottomBasicColor3: { borderBottomColor: 'border-basic-color-3' },
  borderBottomBasicColor4: { borderBottomColor: 'border-basic-color-4' },
  borderBottomBasicColor5: { borderBottomColor: 'border-basic-color-5' },
  borderLeftBasicColor1: { borderLeftColor: 'border-basic-color-1' },
  borderLeftBasicColor2: { borderLeftColor: 'border-basic-color-2' },
  borderLeftBasicColor3: { borderLeftColor: 'border-basic-color-3' },
  borderLeftBasicColor4: { borderLeftColor: 'border-basic-color-4' },
  borderLeftBasicColor5: { borderLeftColor: 'border-basic-color-5' },
  borderRightBasicColor1: { borderRightColor: 'border-basic-color-1' },
  borderRightBasicColor2: { borderRightColor: 'border-basic-color-2' },
  borderRightBasicColor3: { borderRightColor: 'border-basic-color-3' },
  borderRightBasicColor4: { borderRightColor: 'border-basic-color-4' },
  borderRightBasicColor5: { borderRightColor: 'border-basic-color-5' },
  borderBgColor1: { borderColor: 'background-basic-color-1' },
  borderBgColor2: { borderColor: 'background-basic-color-2' },
  borderBgColor3: { borderColor: 'background-basic-color-3' },
  borderBgColor4: { borderColor: 'background-basic-color-4' },
  borderBgColor5: { borderColor: 'background-basic-color-5' },
  borderDangerColor100: { borderColor: 'color-danger-100' },
  borderDangerColor200: { borderColor: 'color-danger-200' },
  borderDangerColor300: { borderColor: 'color-danger-300' },
  borderDangerColor400: { borderColor: 'color-danger-400' },
  borderDangerColor500: { borderColor: 'color-danger-500' },
  borderDangerColor600: { borderColor: 'color-danger-600' },
  borderDangerColor700: { borderColor: 'color-danger-700' },
  borderDangerColor800: { borderColor: 'color-danger-800' },
  borderDangerColor900: { borderColor: 'color-danger-900' },
  borderColorTransparent: { borderColor: 'transparent' },
  borderTopBgColor1: { borderTopColor: 'background-basic-color-1' },
  borderTopBgColor2: { borderTopColor: 'background-basic-color-2' },
  borderTopBgColor3: { borderTopColor: 'background-basic-color-3' },
  borderTopBgColor4: { borderTopColor: 'background-basic-color-4' },
  borderTopBgColor5: { borderTopColor: 'background-basic-color-5' },
  borderBottomBgColor1: { borderBottomColor: 'background-basic-color-1' },
  borderBottomBgColor2: { borderBottomColor: 'background-basic-color-2' },
  borderBottomBgColor3: { borderBottomColor: 'background-basic-color-3' },
  borderBottomBgColor4: { borderBottomColor: 'background-basic-color-4' },
  borderBottomBgColor5: { borderBottomColor: 'background-basic-color-5' },
  borderLeftBgColor1: { borderLeftColor: 'background-basic-color-1' },
  borderLeftBgColor2: { borderLeftColor: 'background-basic-color-2' },
  borderLeftBgColor3: { borderLeftColor: 'background-basic-color-3' },
  borderLeftBgColor4: { borderLeftColor: 'background-basic-color-4' },
  borderLeftBgColor5: { borderLeftColor: 'background-basic-color-5' },
  borderRightBgColor1: { borderRightColor: 'background-basic-color-1' },
  borderRightBgColor2: { borderRightColor: 'background-basic-color-2' },
  borderRightBgColor3: { borderRightColor: 'background-basic-color-3' },
  borderRightBgColor4: { borderRightColor: 'background-basic-color-4' },
  borderRightBgColor5: { borderRightColor: 'background-basic-color-5' },
  borderBgAlternativeColor1: { borderColor: 'background-alternative-color-1' },
  borderBgAlternativeColor2: { borderColor: 'background-alternative-color-2' },
  borderBgAlternativeColor3: { borderColor: 'background-alternative-color-3' },
  borderBgAlternativeColor4: { borderColor: 'background-alternative-color-4' },
  borderBgAlternativeColor5: { borderColor: 'background-alternative-color-5' },
  borderTopBgAlternativeColor1: { borderTopColor: 'background-alternative-color-1' },
  borderTopBgAlternativeColor2: { borderTopColor: 'background-alternative-color-2' },
  borderTopBgAlternativeColor3: { borderTopColor: 'background-alternative-color-3' },
  borderTopBgAlternativeColor4: { borderTopColor: 'background-alternative-color-4' },
  borderTopBgAlternativeColor5: { borderTopColor: 'background-alternative-color-5' },
  borderBottomBgAlternativeColor1: { borderBottomColor: 'background-alternative-color-1' },
  borderBottomBgAlternativeColor2: { borderBottomColor: 'background-alternative-color-2' },
  borderBottomBgAlternativeColor3: { borderBottomColor: 'background-alternative-color-3' },
  borderBottomBgAlternativeColor4: { borderBottomColor: 'background-alternative-color-4' },
  borderBottomBgAlternativeColor5: { borderBottomColor: 'background-alternative-color-5' },
  borderLeftBgAlternativeColor1: { borderLeftColor: 'background-alternative-color-1' },
  borderLeftBgAlternativeColor2: { borderLeftColor: 'background-alternative-color-2' },
  borderLeftBgAlternativeColor3: { borderLeftColor: 'background-alternative-color-3' },
  borderLeftBgAlternativeColor4: { borderLeftColor: 'background-alternative-color-4' },
  borderLeftBgAlternativeColor5: { borderLeftColor: 'background-alternative-color-5' },
  borderRightBgAlternativeColor1: { borderRightColor: 'background-alternative-color-1' },
  borderRightBgAlternativeColor2: { borderRightColor: 'background-alternative-color-2' },
  borderRightBgAlternativeColor3: { borderRightColor: 'background-alternative-color-3' },
  borderRightBgAlternativeColor4: { borderRightColor: 'background-alternative-color-4' },
  borderRightBgAlternativeColor5: { borderRightColor: 'background-alternative-color-5' },

  borderPrimaryColor100: { borderColor: 'color-primary-100' },
  borderPrimaryColor200: { borderColor: 'color-primary-200' },
  borderPrimaryColor300: { borderColor: 'color-primary-300' },
  borderPrimaryColor400: { borderColor: 'color-primary-400' },
  borderPrimaryColor500: { borderColor: 'color-primary-500' },
  borderPrimaryColor600: { borderColor: 'color-primary-600' },
  borderPrimaryColor700: { borderColor: 'color-primary-700' },
  borderPrimaryColor800: { borderColor: 'color-primary-800' },
  borderPrimaryColor900: { borderColor: 'color-primary-900' },
  borderTopPrimaryColor100: { borderTopColor: 'color-primary-100' },
  borderTopPrimaryColor200: { borderTopColor: 'color-primary-200' },
  borderTopPrimaryColor300: { borderTopColor: 'color-primary-300' },
  borderTopPrimaryColor400: { borderTopColor: 'color-primary-400' },
  borderTopPrimaryColor500: { borderTopColor: 'color-primary-500' },
  borderTopPrimaryColor600: { borderTopColor: 'color-primary-600' },
  borderTopPrimaryColor700: { borderTopColor: 'color-primary-700' },
  borderTopPrimaryColor800: { borderTopColor: 'color-primary-800' },
  borderTopPrimaryColor900: { borderTopColor: 'color-primary-900' },
  borderBottomPrimaryColor100: { borderBottomColor: 'color-primary-100' },
  borderBottomPrimaryColor200: { borderBottomColor: 'color-primary-200' },
  borderBottomPrimaryColor300: { borderBottomColor: 'color-primary-300' },
  borderBottomPrimaryColor400: { borderBottomColor: 'color-primary-400' },
  borderBottomPrimaryColor500: { borderBottomColor: 'color-primary-500' },
  borderBottomPrimaryColor600: { borderBottomColor: 'color-primary-600' },
  borderBottomPrimaryColor700: { borderBottomColor: 'color-primary-700' },
  borderBottomPrimaryColor800: { borderBottomColor: 'color-primary-800' },
  borderBottomPrimaryColor900: { borderBottomColor: 'color-primary-900' },
  borderLeftPrimaryColor100: { borderLeftColor: 'color-primary-100' },
  borderLeftPrimaryColor200: { borderLeftColor: 'color-primary-200' },
  borderLeftPrimaryColor300: { borderLeftColor: 'color-primary-300' },
  borderLeftPrimaryColor400: { borderLeftColor: 'color-primary-400' },
  borderLeftPrimaryColor500: { borderLeftColor: 'color-primary-500' },
  borderLeftPrimaryColor600: { borderLeftColor: 'color-primary-600' },
  borderLeftPrimaryColor700: { borderLeftColor: 'color-primary-700' },
  borderLeftPrimaryColor800: { borderLeftColor: 'color-primary-800' },
  borderLeftPrimaryColor900: { borderLeftColor: 'color-primary-900' },
  borderRightPrimaryColor100: { borderRightColor: 'color-primary-100' },
  borderRightPrimaryColor200: { borderRightColor: 'color-primary-200' },
  borderRightPrimaryColor300: { borderRightColor: 'color-primary-300' },
  borderRightPrimaryColor400: { borderRightColor: 'color-primary-400' },
  borderRightPrimaryColor500: { borderRightColor: 'color-primary-500' },
  borderRightPrimaryColor600: { borderRightColor: 'color-primary-600' },
  borderRightPrimaryColor700: { borderRightColor: 'color-primary-700' },
  borderRightPrimaryColor800: { borderRightColor: 'color-primary-800' },
  borderRightPrimaryColor900: { borderRightColor: 'color-primary-900' },
  borderRadius0: { borderRadius: 0 },
  borderRadius2: { borderRadius: 2 },
  borderRadius4: { borderRadius: 4 },
  borderRadius6: { borderRadius: 6 },
  borderRadius8: { borderRadius: 8 },
  borderRadius10: { borderRadius: 10 },
  borderRadius12: { borderRadius: 12 },
  borderRadius15: { borderRadius: 15 },
  borderRadius20: { borderRadius: 20 },
  borderRadius25: { borderRadius: 25 },
  borderRadius30: { borderRadius: 30 },
  // SIZE
  size12: { height: 12, width: 12 },
  size16: { height: 16, width: 16 },
  size20: { height: 20, width: 20 },
  size24: { height: 24, width: 24 },
  size30: { height: 30, width: 30 },
  size40: { height: 40, width: 40 },
  size50: { height: 50, width: 50 },
  //OPACITY
  opacity1: { opacity: 0.1 },
  opacity2: { opacity: 0.2 },
  opacity3: { opacity: 0.3 },
  opacity4: { opacity: 0.4 },
  opacity5: { opacity: 0.5 },
  opacity6: { opacity: 0.6 },
  opacity7: { opacity: 0.7 },
  opacity8: { opacity: 0.8 },
  opacity9: { opacity: 0.9 },
  opacity10: { opacity: 1 },
  // COLOR
  colorTextBasicColor: { color: 'text-basic-color' },
  colorTextHintColor: { color: 'text-hint-color' },
  colorBasic100: { color: 'color-basic-100' },
  colorBasic200: { color: 'color-basic-200' },
  colorBasic300: { color: 'color-basic-300' },
  colorBasic400: { color: 'color-basic-400' },
  colorBasic500: { color: 'color-basic-500' },
  colorBasic600: { color: 'color-basic-600' },
  colorBasic700: { color: 'color-basic-700' },
  colorBasic800: { color: 'color-basic-800' },
  colorBasic900: { color: 'color-basic-900' },
  colorPrimary100: { color: 'color-primary-100' },
  colorPrimary200: { color: 'color-primary-200' },
  colorPrimary300: { color: 'color-primary-300' },
  colorPrimary400: { color: 'color-primary-400' },
  colorPrimary500: { color: 'color-primary-500' },
  colorPrimary600: { color: 'color-primary-600' },
  colorPrimary700: { color: 'color-primary-700' },
  colorPrimary800: { color: 'color-primary-800' },
  colorPrimary900: { color: 'color-primary-900' },

  colorSuccess100: { color: 'color-success-100' },
  colorSuccess200: { color: 'color-success-200' },
  colorSuccess300: { color: 'color-success-300' },
  colorSuccess400: { color: 'color-success-400' },
  colorSuccess500: { color: 'color-success-500' },
  colorSuccess600: { color: 'color-success-600' },
  colorSuccess700: { color: 'color-success-700' },
  colorSuccess800: { color: 'color-success-800' },
  colorSuccess900: { color: 'color-success-900' },

  colorDanger100: { color: 'color-danger-100' },
  colorDanger200: { color: 'color-danger-200' },
  colorDanger300: { color: 'color-danger-300' },
  colorDanger400: { color: 'color-danger-400' },
  colorDanger500: { color: 'color-danger-500' },
  colorDanger600: { color: 'color-danger-600' },
  colorDanger700: { color: 'color-danger-700' },
  colorDanger800: { color: 'color-danger-800' },
  colorDanger900: { color: 'color-danger-900' },

  lineHeight10: { lineHeight: 10 },
  lineHeight11: { lineHeight: 11 },
  lineHeight12: { lineHeight: 12 },
  lineHeight13: { lineHeight: 13 },
  lineHeight14: { lineHeight: 14 },
  lineHeight15: { lineHeight: 15 },
  lineHeight16: { lineHeight: 16 },
  lineHeight18: { lineHeight: 18 },
  lineHeight20: { lineHeight: 20 },
  lineHeight22: { lineHeight: 22 },

  // BACKGROUND COLOR
  backgroundColorBlack: { backgroundColor: 'black' },
  backgroundColorBasic1: { backgroundColor: 'background-basic-color-1' },
  backgroundColorBasic2: { backgroundColor: 'background-basic-color-2' },
  backgroundColorBasic3: { backgroundColor: 'background-basic-color-3' },
  backgroundColorBasic4: { backgroundColor: 'background-basic-color-4' },
  backgroundColorBasic5: { backgroundColor: 'background-basic-color-5' },

  errorContainer: {
    paddingTop: 5,
  },
  errorMessageText: {
    color: 'color-danger-500',
    paddingBottom: 5,
    fontSize: 13,
  },
});
