import React from 'react';
import { ActivityIndicator, Image, Platform, View } from 'react-native';

import { Button, RadioGroup, Text, useStyleSheet } from '@ui-kitten/components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { Props } from './types';
import { CopyableText } from '../../../components/common/common.copyable-text.component';
import { FormDatepicker } from '../../../components/common/form/form-datepicker.component';
import { FormTextInputTopLabel } from '../../../components/common/form/form-text-input-top-label.component';
import { FormSelect } from '../../../components/common/form/FormSelect';
import ContentBoxTextTitle from '../../../components/content-box-text-title.component';
import { DeleteIcon, EditIcon } from '../../../components/icons.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';

export const AgencyAgencyUserMyProfileScreen = ({ navigation }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    fieldProps,
    formik,
    handleAvatarButtonVisibility,
    handleBackPress,
    handleDateChange,
    handleDeleteUserProfilePicture,
    handleSave,
    isCurrentOrganizationUser,
    isRoleEditEnable,
    loading,
    loadingDeletePicture,
    onSelect,
    pickImageMobile,
    pickImageWeb,
    profilePicture,
    radioGroupVisible,
    roleTypeData,
    sexListData,
    userProfilePicture,
  } = useHook();

  return (
    <>
      <TopNavigationWithBackButton
        title={
          isCurrentOrganizationUser
            ? t('agencyUserProfile.myProfile', {
                defaultValue: 'My Profile',
              }).toUpperCase()
            : t('agencyUserProfile.myProfile', {
                defaultValue: 'Agency user profile',
              }).toUpperCase()
        }
        navigation={navigation}
        onPressLeft={handleBackPress}
        accessoryRight={
          isCurrentOrganizationUser || isRoleEditEnable
            ? () => (
                <Button
                  onPress={loading ? undefined : handleSave}
                  appearance="filled"
                  style={styles.saveBtn}
                >
                  {loading ? <ActivityIndicator color="white" /> : 'Save'}
                </Button>
              )
            : () => <></>
        }
      />
      <KeyboardAwareScrollView
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
        keyboardShouldPersistTaps="handled"
      >
        <Image
          style={themedStyles.avatar}
          source={
            profilePicture
              ? profilePicture
              : require('../../../assets/images/avatar_placeholder.png')
          }
          resizeMode="cover"
        />

        {isCurrentOrganizationUser && (
          <View style={styles.editAvatarRow}>
            {Platform.OS === 'web' && (
              <Button
                onPress={pickImageWeb}
                style={styles.editAvatarButton}
                appearance="outline"
                accessoryLeft={EditIcon}
              />
            )}
            {Platform.OS !== 'web' && (
              <Button
                onPress={handleAvatarButtonVisibility}
                style={styles.editAvatarButton}
                appearance="outline"
                accessoryLeft={EditIcon}
              />
            )}
            {userProfilePicture && !!userProfilePicture.sasUrl && (
              <Button
                onPress={handleDeleteUserProfilePicture}
                style={styles.editAvatarButton}
                appearance="outline"
                accessoryLeft={loadingDeletePicture ? <ActivityIndicator /> : DeleteIcon}
              />
            )}
          </View>
        )}

        {radioGroupVisible && (
          <RadioGroup style={styles.editAvatarRow}>
            <Button style={styles.chooseImageBtn} onPress={pickImageWeb}>
              {
                t('common.chooseFromGallery', {
                  defaultValue: 'Choose from Gallery',
                }) as string
              }
            </Button>
            <Button style={styles.chooseImageBtn} onPress={pickImageMobile}>
              {
                t('common.takePicture', {
                  defaultValue: 'Take a Picture',
                }) as string
              }
            </Button>
          </RadioGroup>
        )}
        <View style={styles.nameContainer}>
          <View>
            <Text selectable={true} style={styles.nameText}>
              {formik.values.firstNames ? `${formik.values.firstNames} ` : ''}
              {formik.values.lastName || ''}
            </Text>
          </View>

          <CopyableText
            copyableTextName={t('agencyUserProfile.email', {
              defaultValue: 'Email',
            }).toLowerCase()}
            style={styles.emailText}
            text={formik.values.email || ''}
          />
          {!!formik.values.phone && (
            <CopyableText
              copyableTextName={t('agencyUserProfile.phoneNumber', {
                defaultValue: 'Phone number',
              }).toLowerCase()}
              style={styles.emailText}
              text={formik.values.phone || ''}
            />
          )}
        </View>

        <ContentBoxTextTitle
          title={t('agencyUserProfile.personalDetails', {
            defaultValue: 'Personal Details',
          }).toUpperCase()}
          subtitle={
            '(1)' +
            t('agencyUserProfile.forChangesContactSupport', {
              defaultValue: 'For any changes in these fields contact PRIOjet Support',
            })
          }
          Content={
            <>
              <FormTextInputTopLabel
                containerStyle={styles.inputElement}
                disabled
                label={t('agencyUserProfile.firstNames', { defaultValue: 'First Names' })}
                labelExtra="(1)"
                placeholder={
                  t('agencyUserProfile.firstNames', { defaultValue: 'First Names' }) as string
                }
                isMandatory
                {...fieldProps('firstNames')}
              />
              <FormTextInputTopLabel
                containerStyle={styles.inputElement}
                disabled
                label={t('agencyUserProfile.lastName', { defaultValue: 'Last Name' })}
                labelExtra="(1)"
                placeholder={
                  t('agencyUserProfile.lastName', { defaultValue: 'Last Name' }) as string
                }
                isMandatory
                {...fieldProps('lastName')}
              />

              <FormSelect
                containerStyle={styles.inputElement}
                disabled={!isCurrentOrganizationUser}
                appearance="default"
                label={t('agencyUserProfile.sex', { defaultValue: 'Sex' }) as string}
                onSelect={onSelect('sex')}
                items={sexListData}
                value={formik.values.sex}
                fieldTitleKey="name"
                fieldValueKey="code"
                errorMessage={formik?.errors.sex}
                isMandatory
              />

              <FormDatepicker
                containerStyle={styles.inputElement}
                disabled={!isCurrentOrganizationUser}
                label={
                  t('agencyUserProfile.dateOfBirth', {
                    defaultValue: 'Date of Birth',
                  }) as string
                }
                isMandatory
                min={new Date(dayjs().subtract(80, 'years').format())}
                max={new Date()}
                onSelect={handleDateChange('dateOfBirth')}
                date={formik.values.dateOfBirth ? new Date(formik.values.dateOfBirth) : undefined}
                errorMessage={formik?.errors.dateOfBirth}
              />

              <FormSelect
                containerStyle={styles.inputElement}
                disabled={!isRoleEditEnable}
                appearance="default"
                label={t('agencyUserProfile.roleType', { defaultValue: 'Role' }) as string}
                onSelect={onSelect('roleType')}
                items={roleTypeData}
                value={formik.values.roleType}
                fieldTitleKey="label"
                fieldValueKey="value"
                errorMessage={formik?.errors.roleType}
                isMandatory
              />
            </>
          }
          style={styles.contentBox}
        />
      </KeyboardAwareScrollView>
    </>
  );
};
