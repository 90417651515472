import { useState } from 'react';

import { GooglePlacesAutocompleteLocation } from '../../../../../components/common/google-places-autocomplete.component';
import { AvailabilityFilterType } from '../../../../../generated-graphql-types';

export const useHook = ({ onFilterSelect, onLocationSelect }: any) => {
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [text, setText] = useState('');

  const handleChangeText = (val: string) => {
    setText(val);
  };

  const handlePress = (
    googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
  ) => {
    if (googlePlacesAutocompleteLocation && googlePlacesAutocompleteLocation.formattedAddress) {
      setText(googlePlacesAutocompleteLocation.formattedAddress);
    }
    if (onLocationSelect) {
      onLocationSelect({
        latitude: googlePlacesAutocompleteLocation?.geometry.location.lat,
        longitude: googlePlacesAutocompleteLocation?.geometry.location.lng,
      });
    }
  };

  const handleFilterPress = () => {
    setIsFilterModalVisible((prev) => !prev);
  };

  const handleFilterConfirm = (value: AvailabilityFilterType[]) => {
    onFilterSelect(value);
    handleFilterPress();
  };

  return {
    handleChangeText,
    handleFilterConfirm,
    handleFilterPress,
    handlePress,
    isFilterModalVisible,
    text,
  };
};
