import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { CourierProfileNavigatorParamList } from './app.navigator';
import { CommonProfileNavigator } from './common.profile.navigator';
import { CommonCourierAccountSettingsScreen } from '../screens/common/CommonCourierAccountSettingsScreen';
import CommonNotificationPermissionsScreen from '../screens/common/CommonNotificationPermissionsScreen';
import { CommonUserDocumentDetailScreen } from '../screens/courier/common.user-document.detail.screen';
import { CourierBackgroundDocumentsScreen } from '../screens/courier/courier.background-documents.screen';
import { CourierConnectedAgenciesScreen } from '../screens/courier/courier.connected-agencies.screen';
import { CourierFrequentFlyerDocumentsScreen } from '../screens/courier/courier.frequent-flyer-documents.screen';
import { CourierHealthTestReportsScreen } from '../screens/courier/courier.health-test-reports.screen';
import { CourierOtherDocumentsScreen } from '../screens/courier/courier.other-documents.screen';
import { CourierSettingsScreen } from '../screens/courier/courier.settings.screen';
import { CourierUserIdentityDocumentsScreen } from '../screens/courier/courier.user-identity-documents.screen';
import { CourierUserTravelPermitsScreen } from '../screens/courier/courier.user-travel-permits.screen';
import { CourierVaccinationCertificatesScreen } from '../screens/courier/courier.vaccination-certificates.screen';
import { CourierAgencyProfileScreen } from '../screens/courier/CourierAgencyProfileScreen';
import { CourierProfileEditScreen } from '../screens/courier/CourierProfileEditScreen';
import { CourierProfileScreen } from '../screens/courier/CourierProfileScreen';

const StackNavigator = createStackNavigator<CourierProfileNavigatorParamList>();

const CourierProfileNavigator = () => (
  <StackNavigator.Navigator
    screenOptions={{ headerShown: false }}
    initialRouteName="CourierProfileScreen"
  >
    <StackNavigator.Screen name="CourierProfileScreen" component={CourierProfileScreen} />
    <StackNavigator.Screen name="CourierProfileEditScreen" component={CourierProfileEditScreen} />
    <StackNavigator.Screen
      name="CourierUserIdentityDocumentsScreen"
      component={CourierUserIdentityDocumentsScreen}
    />
    <StackNavigator.Screen
      name="CommonUserDocumentDetailScreen"
      component={CommonUserDocumentDetailScreen}
    />
    <StackNavigator.Screen
      name="CourierUserTravelPermitsScreen"
      component={CourierUserTravelPermitsScreen}
    />
    <StackNavigator.Screen
      name="CourierFrequentFlyerDocumentsScreen"
      component={CourierFrequentFlyerDocumentsScreen}
    />
    <StackNavigator.Screen
      name="CourierVaccinationCertificatesScreen"
      component={CourierVaccinationCertificatesScreen}
    />
    <StackNavigator.Screen
      name="CourierHealthTestReportsScreen"
      component={CourierHealthTestReportsScreen}
    />

    <StackNavigator.Screen
      name="CourierBackgroundDocumentsScreen"
      component={CourierBackgroundDocumentsScreen}
    />
    <StackNavigator.Screen
      name="CourierOtherDocumentsScreen"
      component={CourierOtherDocumentsScreen}
    />
    <StackNavigator.Screen
      name="CourierConnectedAgenciesScreen"
      component={CourierConnectedAgenciesScreen}
    />
    <StackNavigator.Screen
      name="CourierAgencyProfileScreen"
      component={CourierAgencyProfileScreen}
    />
    <StackNavigator.Screen name="CourierSettingsScreen" component={CourierSettingsScreen} />
    <StackNavigator.Screen
      name="CommonNotificationPermissionsScreen"
      component={CommonNotificationPermissionsScreen}
      initialParams={{ canGoBack: true }}
    />
    <StackNavigator.Screen name="CommonProfileNavigator" component={CommonProfileNavigator} />
    <StackNavigator.Screen
      name="CommonCourierAccountSettingsScreen"
      component={CommonCourierAccountSettingsScreen}
    />
  </StackNavigator.Navigator>
);

export { CourierProfileNavigator };
