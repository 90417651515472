import { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Platform } from 'react-native';

import { useQuery, useMutation } from '@apollo/client';
import { PermissionStatus } from 'expo-modules-core';
import * as ExpoNotifications from 'expo-notifications';

import { MUTATION_NOTIFICATION_SETTING_UPDATE } from '../../../apollo/graphql-mutations';
import { QUERY_NOTIFICATION_SETTING_CURRENT_USER } from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import { useAppState } from '../../../hooks/useAppState';
import { updateDevice } from '../../../modules/device/device.module';

export const useHook = () => {
  const appUserContext = useContext(AppUserContext);
  const { appState } = useAppState();

  const [notificationSettings, setNotificationSettings] = useState<any>({
    emailConnection: true,
    emailJob: true,
    pushAvailability: true,
    pushChat: true,
    pushConnection: true,
    pushJob: true,
    systemAvailability: true,
    systemChat: true,
    systemConnection: true,
    systemJob: true,
  });
  const [notificationPermissions, setNotificationPermissions] = useState<any>();

  useQuery(QUERY_NOTIFICATION_SETTING_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNotificationSettings({
        emailConnection: data.notificationSettingCurrentUser.emailConnection,
        emailJob: data.notificationSettingCurrentUser.emailJob,
        pushAvailability: data.notificationSettingCurrentUser.pushAvailability,
        pushChat: data.notificationSettingCurrentUser.pushChat,
        pushConnection: data.notificationSettingCurrentUser.pushConnection,
        pushJob: data.notificationSettingCurrentUser.pushJob,
        systemAvailability: data.notificationSettingCurrentUser.systemAvailability,
        systemChat: data.notificationSettingCurrentUser.systemChat,
        systemConnection: data.notificationSettingCurrentUser.systemConnection,
        systemJob: data.notificationSettingCurrentUser.systemJob,
      });
    },
  });
  const [notificationSettingUpdate] = useMutation(MUTATION_NOTIFICATION_SETTING_UPDATE);

  const { iconFill, iconName } = useMemo(() => {
    let _iconName = 'bell-outline';
    let _iconFill = 'lightgray';
    if (notificationPermissions?.granted === true) {
      _iconName = 'bell-outline';
    } else if (notificationPermissions?.granted === false) {
      _iconName = 'bell-off-outline';
      _iconFill = '#C6141B';
    }
    return { iconName: _iconName, iconFill: _iconFill };
  }, [notificationPermissions]);

  const handleNotificationSettingChange = (key: string) => () => {
    setNotificationSettings((prev: any) => {
      const newData: any = { ...prev };
      newData[key] = !newData[key];
      notificationSettingUpdate({ variables: { data: newData } });
      return newData;
    });
  };

  const requestPermissionsAsync = async () => {
    if (Platform.OS === 'web') {
      return null;
    }
    const result: any = await ExpoNotifications.requestPermissionsAsync(
      Platform.OS === 'ios'
        ? {
            ios: {
              allowAlert: true,
              allowBadge: true,
              allowSound: true,
              allowAnnouncements: true,
              allowDisplayInCarPlay: true,
              allowCriticalAlerts: true,
            },
          }
        : {
            android: {},
          },
    );
    setNotificationPermissions(result);
    if (result) {
      const device = await updateDevice({});
      if (device && appUserContext) {
        appUserContext.setDevice(device);
      } else {
        Alert.alert(
          'There was a problem updating the device settings. Please restart the app and otherwise contact the PRIOjet support. Device ID: ' +
            device?.id,
        );
      }
    }
    return result;
  };

  const updateNotificationPermissionStatus = async () => {
    if (Platform.OS !== 'web') {
      let status: any = await ExpoNotifications.getPermissionsAsync();
      if (status.status === PermissionStatus.UNDETERMINED) {
        status = await requestPermissionsAsync();
      }
      setNotificationPermissions(status);
    }
  };

  useEffect(() => {
    if (appState === 'active' && Platform.OS !== 'web') {
      updateNotificationPermissionStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);

  return {
    iconFill,
    iconName,
    handleNotificationSettingChange,
    notificationPermissions,
    notificationSettings,
    requestPermissionsAsync,
  };
};
