import { DateTime } from 'luxon';

import { PLANNING_ITEMS_LEG } from './constants';
import {
  AgencyJobPlanning,
  AgencyJobPlanningItem,
  AgencyJobPlanningLeg,
} from '../../../generated-graphql-types';

type AgencyJobPlanningAllItemsWithLegItem = {
  active: boolean;
  completed: boolean;
  name: string;
  legIndex?: number;
  legItemIndex?: number;
  index?: number;
};

export interface State {
  agencyJobPlanning?: AgencyJobPlanning | null;
  agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[];
  currentIndex?: number;
  currentLegIndex?: number;
  currentLegItemIndex?: number;
  isAllSelected: boolean;
  agencyJobPlanningLegIdsToDelete: string[];
}

type Action = {
  type:
    | 'addAnotherLeg'
    | 'changeCurrentIndexes'
    | 'changeFieldActive'
    | 'changeFieldTargetTime'
    | 'changeLegData'
    | 'removeLegItem'
    | 'selectDeselectAll'
    | 'syncAPIData';
  payload: {
    agencyJobPlanning?: AgencyJobPlanning;
    currentIndex?: number;
    currentLegIndex?: number;
    currentLegItemIndex?: number;
    legData?: any;
    targetDateTime?: DateTime;
  };
};

export const INITIAL_STATE: State = {
  agencyJobPlanning: null,
  agencyJobPlanningAllItemsWithLegItems: [],
  isAllSelected: false,
  agencyJobPlanningLegIdsToDelete: [],
};

const getAgencyJobPlanningAllItemsWithLegItems = (agencyJobPlanning: AgencyJobPlanning) => {
  const items: AgencyJobPlanningAllItemsWithLegItem[] = [];

  agencyJobPlanning.agencyJobPlanningItems.forEach((item, index) => {
    if (item?.name === 'travelAtDropOffDeliveryLocation') {
      agencyJobPlanning.agencyJobPlanningLegs.forEach((leg: AgencyJobPlanningLeg, legIndex) => {
        items.push({
          active: leg.active,
          completed: !!leg.flightNumber && !!leg.flightCarrierDesignation,
          name: `LEG-${legIndex}`,
          legIndex,
          legItemIndex: undefined,
          index: undefined,
        });
        leg.agencyJobPlanningItems.forEach((legItem, legItemIndex) => {
          items.push({
            active: legItem.active,
            completed: !!legItem.targetDateTime,
            name: legItem.name,
            legIndex,
            legItemIndex,
            index: undefined,
          });
        });
      });
      items.push({
        active: item.active,
        completed: !!item.targetDateTime,
        name: item.name,
        legIndex: undefined,
        legItemIndex: undefined,
        index,
      });
    } else {
      items.push({
        active: item.active,
        completed: !!item.targetDateTime,
        name: item.name,
        legIndex: undefined,
        legItemIndex: undefined,
        index,
      });
    }
  });
  return items;
};

const checkIfAllSelected = (agencyJobPlanning: AgencyJobPlanning) =>
  agencyJobPlanning?.agencyJobPlanningItems.every((item) => item.active) &&
  agencyJobPlanning?.agencyJobPlanningLegs.every((item) =>
    item.agencyJobPlanningItems.every((legItem) => legItem.active),
  );

export const reducer = (state: State, action: Action) => {
  const { type, payload } = action;

  if (action.type === 'addAnotherLeg') {
    if (state.agencyJobPlanning) {
      const newAgencyJobPlanning = {
        ...state.agencyJobPlanning,
        agencyJobPlanningLegs: [
          ...state.agencyJobPlanning.agencyJobPlanningLegs,
          {
            id: null,
            active: true,
            departureDate: undefined,
            flightCarrierDesignation: '',
            flightNumber: '',
            flightTickets: [],
            agencyJobPlanningItems: JSON.parse(JSON.stringify(PLANNING_ITEMS_LEG)),
          },
        ],
      } as AgencyJobPlanning;
      const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
        getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);

      return {
        ...state,
        agencyJobPlanning: newAgencyJobPlanning,
        agencyJobPlanningAllItemsWithLegItems,
      };
    }
  } else if (action.type === 'removeLegItem') {
    if (state.agencyJobPlanning) {
      const agencyJobPlanningLegIdsToDelete = state.agencyJobPlanningLegIdsToDelete;
      const agencyJobPlanningLegs: AgencyJobPlanningLeg[] =
        state.agencyJobPlanning.agencyJobPlanningLegs.filter(
          (agencyJobPlanningLeg: AgencyJobPlanningLeg, index: number) => {
            if (payload.currentLegIndex === index) {
              if (agencyJobPlanningLeg.id) {
                agencyJobPlanningLegIdsToDelete.push(agencyJobPlanningLeg.id);
              }
              return false;
            }
            return true;
          },
        );

      const newAgencyJobPlanning = {
        ...state.agencyJobPlanning,
        agencyJobPlanningLegs,
      } as AgencyJobPlanning;
      const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
        getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);

      return {
        ...state,
        agencyJobPlanning: newAgencyJobPlanning,
        agencyJobPlanningAllItemsWithLegItems,
        agencyJobPlanningLegIdsToDelete,
      };
    }
  } else if (type === 'changeCurrentIndexes') {
    return {
      ...state,
      currentIndex: payload.currentIndex,
      currentLegIndex: payload.currentLegIndex,
      currentLegItemIndex: payload.currentLegItemIndex,
    };
  } else if (type === 'selectDeselectAll') {
    let isActive = true;
    if (state.isAllSelected) {
      isActive = false;
    }

    let agencyJobPlanningItems: AgencyJobPlanningItem[] = [];
    let agencyJobPlanningLegs: AgencyJobPlanningLeg[] = [];
    if (state.agencyJobPlanning && state.agencyJobPlanning?.agencyJobPlanningItems) {
      agencyJobPlanningItems = state.agencyJobPlanning?.agencyJobPlanningItems.map(
        (agencyJobPlanningItem: any) => {
          if (state.agencyJobPlanning && state.agencyJobPlanning?.agencyJobPlanningItems) {
            return { ...agencyJobPlanningItem, active: isActive };
          }
          return agencyJobPlanningItem;
        },
      );
    }
    if (state.agencyJobPlanning && state.agencyJobPlanning?.agencyJobPlanningItems) {
      agencyJobPlanningLegs = state.agencyJobPlanning.agencyJobPlanningLegs.map(
        (agencyJobPlanningLeg: AgencyJobPlanningLeg) => ({
          ...agencyJobPlanningLeg,
          active: isActive,
          agencyJobPlanningItems: agencyJobPlanningLeg.agencyJobPlanningItems.map(
            (agencyJobPlanningItem: AgencyJobPlanningItem) => ({
              ...agencyJobPlanningItem,
              active: isActive,
            }),
          ),
        }),
      );
    }
    const newAgencyJobPlanning = {
      ...state.agencyJobPlanning,
      agencyJobPlanningItems,
      agencyJobPlanningLegs,
    } as AgencyJobPlanning;
    const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
      getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);
    return {
      ...state,
      agencyJobPlanning: newAgencyJobPlanning,
      agencyJobPlanningAllItemsWithLegItems,
      isAllSelected: isActive,
    };
  } else if (type === 'changeLegData') {
    let agencyJobPlanningLegs = [];
    if (
      state.agencyJobPlanning &&
      typeof state.currentLegIndex === 'number' &&
      state.agencyJobPlanning?.agencyJobPlanningItems
    ) {
      agencyJobPlanningLegs = state.agencyJobPlanning.agencyJobPlanningLegs.map(
        (agencyJobPlanningLeg: AgencyJobPlanningLeg, index: number) => {
          if (state.currentLegIndex === index) {
            return { ...agencyJobPlanningLeg, ...payload.legData };
          }
          return agencyJobPlanningLeg;
        },
      );
      const newAgencyJobPlanning = {
        ...state.agencyJobPlanning,
        agencyJobPlanningLegs,
      } as AgencyJobPlanning;
      const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
        getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);
      return {
        ...state,
        agencyJobPlanning: newAgencyJobPlanning,
        agencyJobPlanningAllItemsWithLegItems,
        currentIndex: undefined,
        currentLegIndex: undefined,
        currentLegItemIndex: undefined,
      };
    }
  } else if (action.type === 'changeFieldActive') {
    if (typeof payload.currentLegIndex === 'number') {
      if (typeof payload.currentLegItemIndex === 'number') {
        const newAgencyJobPlanning = {
          ...state.agencyJobPlanning,
          agencyJobPlanningLegs: state.agencyJobPlanning?.agencyJobPlanningLegs.map(
            (agencyJobPlanningLeg: AgencyJobPlanningLeg, index: number) => {
              if (index === payload.currentLegIndex) {
                const agencyJobPlanningItems = agencyJobPlanningLeg.agencyJobPlanningItems.map(
                  (agencyJobPlanningItem: AgencyJobPlanningItem, indexItem: number) => {
                    if (indexItem === payload.currentLegItemIndex) {
                      return {
                        ...agencyJobPlanningItem,
                        active: !agencyJobPlanningItem.active,
                      };
                    }
                    return agencyJobPlanningItem;
                  },
                );
                const isSomeItemActive = agencyJobPlanningItems.some((item) => item.active);
                const isAllItemsInactive = agencyJobPlanningItems.every((item) => !item.active);
                let isActive = agencyJobPlanningLeg.active;
                if (isSomeItemActive && !isActive) {
                  isActive = true;
                } else if (isAllItemsInactive && isActive) {
                  isActive = false;
                }
                return { ...agencyJobPlanningLeg, active: isActive, agencyJobPlanningItems };
              }
              return agencyJobPlanningLeg;
            },
          ),
        } as AgencyJobPlanning;
        const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
          getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);
        return {
          ...state,
          agencyJobPlanning: newAgencyJobPlanning,
          agencyJobPlanningAllItemsWithLegItems,
        };
      }

      const newAgencyJobPlanning = {
        ...state.agencyJobPlanning,
        agencyJobPlanningLegs: state.agencyJobPlanning?.agencyJobPlanningLegs.map(
          (agencyJobPlanningLeg: AgencyJobPlanningLeg, index: number) => {
            if (index === payload.currentLegIndex) {
              const isActive = !agencyJobPlanningLeg.active;
              return {
                ...agencyJobPlanningLeg,
                active: isActive,
                agencyJobPlanningItems: agencyJobPlanningLeg.agencyJobPlanningItems.map(
                  (agencyJobPlanningItem: AgencyJobPlanningItem) => {
                    return { ...agencyJobPlanningItem, active: isActive };
                  },
                ),
              };
            }
            return agencyJobPlanningLeg;
          },
        ),
      } as AgencyJobPlanning;
      const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
        getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);
      return {
        ...state,
        agencyJobPlanning: newAgencyJobPlanning,
        agencyJobPlanningAllItemsWithLegItems,
      };
    }
    return {
      ...state,
      agencyJobPlanning: {
        ...state.agencyJobPlanning,
        agencyJobPlanningItems: state.agencyJobPlanning?.agencyJobPlanningItems.map(
          (agencyJobPlanningItem: AgencyJobPlanningItem, index: number) => {
            if (index === payload.currentIndex) {
              return { ...agencyJobPlanningItem, active: !agencyJobPlanningItem.active };
            }
            return agencyJobPlanningItem;
          },
        ),
      } as AgencyJobPlanning,
    };
  } else if (type === 'changeFieldTargetTime') {
    if (typeof state.currentLegIndex === 'number') {
      if (typeof state.currentLegItemIndex === 'number') {
        const newAgencyJobPlanning = {
          ...state.agencyJobPlanning,
          agencyJobPlanningLegs: state.agencyJobPlanning?.agencyJobPlanningLegs.map(
            (agencyJobPlanningLeg: AgencyJobPlanningLeg, index: number) => {
              if (index === state.currentLegIndex) {
                const agencyJobPlanningItems = agencyJobPlanningLeg.agencyJobPlanningItems.map(
                  (agencyJobPlanningItem: AgencyJobPlanningItem, indexItem: number) => {
                    if (indexItem === state.currentLegItemIndex) {
                      return {
                        ...agencyJobPlanningItem,
                        targetDateTime: payload.targetDateTime,
                      };
                    }
                    return agencyJobPlanningItem;
                  },
                );

                return { ...agencyJobPlanningLeg, agencyJobPlanningItems };
              }
              return agencyJobPlanningLeg;
            },
          ),
        } as AgencyJobPlanning;
        const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
          getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);
        return {
          ...state,
          agencyJobPlanning: newAgencyJobPlanning,
          agencyJobPlanningAllItemsWithLegItems,
          currentIndex: undefined,
          currentLegIndex: undefined,
          currentLegItemIndex: undefined,
        };
      }
    }
    const newAgencyJobPlanning = {
      ...state.agencyJobPlanning,
      agencyJobPlanningItems: state.agencyJobPlanning?.agencyJobPlanningItems.map(
        (agencyJobPlanningItem: AgencyJobPlanningItem, index: number) => {
          if (index === state.currentIndex) {
            return { ...agencyJobPlanningItem, targetDateTime: payload.targetDateTime };
          }
          return agencyJobPlanningItem;
        },
      ),
    } as AgencyJobPlanning;
    const agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] =
      getAgencyJobPlanningAllItemsWithLegItems(newAgencyJobPlanning);
    return {
      ...state,
      agencyJobPlanning: newAgencyJobPlanning,
      agencyJobPlanningAllItemsWithLegItems,
      currentIndex: undefined,
      currentLegIndex: undefined,
      currentLegItemIndex: undefined,
    };
  } else if (type === 'syncAPIData') {
    let agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[] = [];
    if (payload.agencyJobPlanning) {
      agencyJobPlanningAllItemsWithLegItems = getAgencyJobPlanningAllItemsWithLegItems(
        payload.agencyJobPlanning,
      );
    }
    if (payload?.agencyJobPlanning) {
      return {
        ...state,
        agencyJobPlanningAllItemsWithLegItems,
        agencyJobPlanning: {
          ...payload.agencyJobPlanning,
          agencyJobPlanningLegs: payload.agencyJobPlanning.agencyJobPlanningLegs.map((item) => ({
            ...item,
            departureDate: item.departureDate
              ? DateTime.fromJSDate(item.departureDate?.toJSDate())
              : null,
          })),
        },
        isAllSelected: checkIfAllSelected(payload.agencyJobPlanning),
      };
    }
  }
  return { ...state };
};
