import React from 'react';
import { StyleSheet, View } from 'react-native';

import MapView from 'react-native-maps'; // remove PROVIDER_GOOGLE import if not using Google Maps

export const MapViewComponent = (props: {
  latitude: number;
  longitude: number;
  height: number | string;
  width?: number | string;
  showMarker?: boolean;
  latitudeDelta?: number;
  longitudeDelta?: number;
}) => {
  return (
    <View style={styles.container}>
      <MapView
        provider="google"
        style={{ ...styles.map, height: props.height || '100%', width: '100%' }}
        region={{
          latitude: props.latitude,
          longitude: props.longitude,
          latitudeDelta: props.latitudeDelta || 3.0,
          longitudeDelta: props.latitudeDelta || 3.0,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  map: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});
