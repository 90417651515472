import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  avatarImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  avatarText: {
    fontSize: 20,
  },

  textLeft: {
    textAlign: 'left',
    color: 'text-basic-color',
  },
  textRight: {
    textAlign: 'right',
    color: 'text-basic-color',
  },
});
