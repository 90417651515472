import React, {
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { TextInputProps, View } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  DescriptionRow,
  GooglePlaceDetail,
  Language,
  Place,
} from 'react-native-google-places-autocomplete';
import * as Yup from 'yup';

import { FormAutocomplete } from './form/form-autocomplete.component';
import { FormTextInputTopLabel } from './form/form-text-input-top-label.component';
import {
  GooglePlacesAutocompleteLocation,
  ThemedGooglePlacesAutocomplete,
} from './google-places-autocomplete.component';
import { CountryListData } from '../../assets/constants/country.list.data';
import i18n from '../../i18n/i18n';
import { ReverseGeocodeLocationType } from '../../lib/google-maps-reverse-geocode-location-api';
import {
  getAddressDetailsFromGooglePlaces,
  getFormattedAddressFromLocation,
} from '../../utils/google-places.util';

export const LOCATION_AUTOCOMPLETE_INITIAL_FIELDS = [
  {
    field: 'streetName',
    label: i18n.t('location.street', { defaultValue: 'Street' }),
    type: 'text',
  },
  {
    field: 'houseNumber',
    label: i18n.t('location.houseNumber', { defaultValue: 'House number' }),
    type: 'text',
  },
  {
    field: 'apartmentOrSuite',
    label: i18n.t('location.apartmentOrSuite', { defaultValue: 'APT/SUITE/OTHERS' }),
    type: 'text',
  },
  {
    field: 'postalCode',
    label: i18n.t('location.postalCode', { defaultValue: 'Postal code' }),
    type: 'text',
  },
  {
    field: 'city',
    label: i18n.t('location.city', { defaultValue: 'City' }),
    type: 'text',
  },
  {
    field: 'state',
    label: i18n.t('location.stateRegion', { defaultValue: 'State/Region' }),
    type: 'text',
  },
  {
    field: 'countryCode',
    label: i18n.t('location.country', { defaultValue: 'Country' }),
    type: 'autocomplete',
  },
];

export const LOCATION_AUTOCOMPLETE_INITIAL_FIELD_NAMES = [
  'addressName',
  'formattedAddress',
  'streetName',
  'houseNumber',
  'apartmentOrSuite',
  'city',
  'postalCode',
  'state',
  'country',
];

export const REQUIRED_FIELDS = ['streetName', 'city', 'postalCode', 'state', 'country'];

const COMPARE_FIELDS = [
  'formattedAddress',
  'streetName',
  'houseNumber',
  'city',
  'postalCode',
  'state',
  'countryCode',
];

const initialValues = {
  addressName: '',
  formattedAddress: '',
  streetName: '',
  houseNumber: '',
  state: '',
  apartmentOrSuite: '',
  city: '',
  country: '',
  postalCode: '',
  placeId: '',
  types: [],
  latitude: '',
  longitude: '',
};

export interface Field {
  field: string;
  label: string;
  type: string;
}

interface Props {
  caption?: string;
  onChangeText?: (text: string) => void;
  onPress?: (
    googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
    locationDetails: any,
  ) => void;
  currentLocationText?: string;
  disableManualForm?: boolean;
  fields?: Field[];
  language: Language;
  isCurrentLocationEnabled?: boolean;
  initialValues?: { [key: string]: string } | null;
  isMandatory?: boolean;
  label?: string;
  placeholderText?: string;
  predefinedPlaces?: Place[];
  reverseGeocodeLocationTypes?: ReverseGeocodeLocationType[];
  textInputProps?: TextInputProps;
  onFieldChange?: (field: string, value: any, index?: any) => void;
  validationSchema?: any;
}

const LocationAutocomplete = forwardRef((props: Props, ref) => {
  const {
    caption,
    disableManualForm,
    fields = LOCATION_AUTOCOMPLETE_INITIAL_FIELDS,
    language = 'en',
    currentLocationText = 'currentLocationText',
    isCurrentLocationEnabled = true,
    isMandatory = false,
    initialValues: initialValuesProp = {},
    label = '',
    onChangeText,
    onFieldChange,
    onPress,
    reverseGeocodeLocationTypes = ['airports', 'localities', 'small_regions', 'large_regions'],
    placeholderText = "'Search location'",
    textInputProps,
    validationSchema: validationSchemaProp,
  } = props;
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const countryRef = useRef<any>();

  const [addressData, setAddressData] = useState<{ [key: string]: string } | undefined>();
  const [formVisible, setFormVisible] = useState(false);
  const [text, setText] = useState('');
  const [initialValuesSet, setInitialValuesSet] = useState(false);
  const [validate, setValidate] = useState(false);

  const getValues = async () => {
    const errors = await formik.validateForm();
    let values = { ...formik.values };
    let placeId = values.placeId || '';
    COMPARE_FIELDS.forEach((key) => {
      if (values[key] !== addressData?.[key]) {
        placeId = '';
      }
    });
    const formattedAddress = getFormattedAddressFromLocation(values);
    if (!Object.keys(errors).length && formattedAddress && !text) {
      setText(formattedAddress);
    }

    let isValid = true;
    let isEmpty = true;
    if (Object.keys(errors).length) {
      isValid = false;
    }
    if (REQUIRED_FIELDS.every((key) => !values[key].length)) {
      isValid = false;
    }
    if (REQUIRED_FIELDS.some((key) => values[key].length)) {
      isEmpty = false;
    }

    return { errors, values: { ...values, formattedAddress, placeId }, isEmpty, isValid };
  };

  const resetValues = async (data: any) => {
    formik.setValues({ ...formik.values, ...data });
  };

  const setValues = async (data: any) => {
    formik.setValues({ ...initialValues, ...data });
    if (data?.formattedAddress && data?.formattedAddress !== text) {
      setText(data.formattedAddress);
    }
  };

  const validationSchema = useMemo(() => {
    if (validationSchemaProp) {
      return validationSchemaProp;
    }
    if (validate) {
      return Yup.object().shape({
        streetName: Yup.string().required(
          t('location.requiredField', { defaultValue: 'Must be set' }) as string,
        ),
        city: Yup.string().required(
          t('location.requiredField', { defaultValue: 'Must be set' }) as string,
        ),
        postalCode: Yup.string().required(
          t('location.requiredField', { defaultValue: 'Must be set' }) as string,
        ),
        state: Yup.string().required(
          t('location.requiredField', { defaultValue: 'Must be set' }) as string,
        ),
        countryCode: Yup.string().required(
          t('location.requiredField', { defaultValue: 'Must be set' }) as string,
        ),
      });
    }
    return Yup.object().shape({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, validate, validationSchemaProp]);

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: () => {},
  });

  useImperativeHandle(ref, () => ({
    getValues,
    resetValues,
    setValues,
  }));

  useEffect(() => {
    if (
      !formVisible &&
      REQUIRED_FIELDS.some(
        (key) =>
          (key === 'types' && formik.values[key].length) || (key !== 'types' && formik.values[key]),
      )
    ) {
      setFormVisible(true);
    }
  }, [formVisible, formik.values]);

  useEffect(() => {
    if (formVisible && !validate && REQUIRED_FIELDS.some((key) => formik.values[key])) {
      setValidate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, formik.values]);

  useEffect(() => {
    if (validate && !REQUIRED_FIELDS.some((key) => formik.values[key])) {
      setValidate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, formik.values]);

  useEffect(() => {
    if (!initialValuesSet) {
      if (
        initialValuesProp &&
        ((initialValuesProp &&
          initialValuesProp?.formattedAddress &&
          initialValuesProp.formattedAddress !== formik.values.formattedAddress) ||
          Object.keys(initialValuesProp).some(
            (key) =>
              (key === 'types' && initialValuesProp[key].length) ||
              (key !== '__typename' && initialValuesProp[key]),
          ))
      ) {
        let newValues = { ...initialValuesProp };

        if (initialValuesProp.country) {
          const country = CountryListData.find(
            (item) =>
              item.code === initialValuesProp.country || item.name === initialValuesProp.country,
          );
          if (country) {
            newValues.country = country.name;
            newValues.countryCode = country.code;
            if (countryRef.current?.setTextValue) {
              countryRef.current?.setTextValue(country.name);
            }
          }
        }
        formik.setValues({ ...formik.values, ...newValues });
        setAddressData({ ...formik.values, ...newValues });
        setInitialValuesSet(true);

        formik.setErrors({});
        if (initialValuesProp?.formattedAddress && initialValuesProp?.formattedAddress !== text) {
          setText(initialValuesProp.formattedAddress);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, initialValuesProp, text]);

  const predefinedPlaces = useMemo(() => {
    if (formVisible) {
      return [];
    }
    let description = t('location.enterDataManually', {
      defaultValue: 'Press here to enter data manually',
    });
    if (text.length) {
      description = t('location.addressNotFoundEnterDataManually', {
        defaultValue: 'Address not found. Press here to enter data manually',
      });
    }
    return [
      {
        description,
        geometry: { location: { lat: 0.0, lng: 0.0 } },
        type: 'manual_data',
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, text]);

  const handleChangeText = (val: string) => {
    setText(val);
    if (onChangeText) {
      onChangeText(val);
    }
  };

  const handlePress = (
    googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
    raw?: { data: DescriptionRow; details: GooglePlaceDetail | null },
  ) => {
    setFormVisible(true);
    if (
      googlePlacesAutocompleteLocation &&
      googlePlacesAutocompleteLocation?.description !== (predefinedPlaces?.[0] || {}).description &&
      raw?.data
    ) {
      const location = getAddressDetailsFromGooglePlaces(raw);
      if (location) {
        if (countryRef.current?.setTextValue) {
          countryRef.current?.setTextValue(location.country || '');
        }
        formik.setValues({
          ...formik.values,
          ...location,
          addressName: location.addressName || formik.values.addressName,
        });
      }
      if (location.formattedAddress) {
        setText(location.formattedAddress);
      }

      if (onPress) {
        onPress(googlePlacesAutocompleteLocation, location);
      }
    }
  };

  const fieldProps = (field: any) => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);

    const handleChange = (v: any): void => {
      formik.handleChange(field)({
        target: { value: v },
      });
      if (onFieldChange) {
        onFieldChange(field, v);
      }
      if (fieldError) {
        formik.validateField(field);
      }
    };
    return {
      caption: '',
      errorMessage: fieldError,
      onChangeText: handleChange,
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
  };

  const handleSelectCountry = (value: string, option: any) => {
    formik.setFieldValue('country', option?.name);
    formik.setFieldValue('countryCode', value);
  };

  const renderTextInput = ({ field, label: _label }: Field) => (
    <FormTextInputTopLabel key={field} label={_label} placeholder={_label} {...fieldProps(field)} />
  );

  const renderCountry = ({ field, label: _label }: Field) => (
    <FormAutocomplete
      ref={countryRef}
      key={field}
      label={_label}
      fieldTitleKey="name"
      fieldValueKey="code"
      value={formik.values[field]}
      items={CountryListData}
      onSelect={handleSelectCountry as any}
    />
  );

  const renderField = (item: Field) => {
    if (item.field === 'countryCode') {
      return renderCountry(item);
    }
    return renderTextInput(item);
  };

  return (
    <>
      <ThemedGooglePlacesAutocomplete
        caption={caption}
        label={label}
        isMandatory={isMandatory}
        onPress={handlePress}
        language={language}
        predefinedPlaces={predefinedPlaces}
        placeholderText={placeholderText}
        currentLocationText={currentLocationText}
        reverseGeocodeLocationTypes={reverseGeocodeLocationTypes}
        isCurrentLocationEnabled={isCurrentLocationEnabled}
        onChangeText={handleChangeText}
        textInputProps={{ ...textInputProps, value: text }}
      />
      {formVisible && !disableManualForm && (
        <View style={styles.form}>{fields.map(renderField)}</View>
      )}
    </>
  );
});

export { LocationAutocomplete };

const themedStyles = StyleService.create({
  form: {
    marginTop: 10,
  },
});
