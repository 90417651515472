import React from 'react';
import { ActivityIndicator, Image, Platform, RefreshControl, View } from 'react-native';

import { Button, CheckBox, RadioGroup, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useHook } from './hook';
import OBCBaseAirportsEdit from './OBCBaseAirportsEdit';
import OBCCreditCardEdit from './OBCCreditCardEdit';
import OBCEmergencyContactEdit from './OBCEmergencyContactEdit';
import OBCExperienceEdit from './OBCExperienceEdit';
import OBCLanguagesEdit from './OBCLanguagesEdit';
import OBCPersonalDetailsEdit from './OBCPersonalDetails';
import OBCSocialProfileEdit from './OBCSocialProfileEdit';
import { themedStyles } from './styles';
import { CountryListData } from '../../../assets/constants/country.list.data';
import { globalStyle } from '../../../common/style';
import { CopyableText } from '../../../components/common/common.copyable-text.component';
import { FormAutocomplete } from '../../../components/common/form/form-autocomplete.component';
import { FormTextInputTopLabel } from '../../../components/common/form/form-text-input-top-label.component';
import {
  LocationAutocomplete,
  LOCATION_AUTOCOMPLETE_INITIAL_FIELDS,
} from '../../../components/common/location-autocomplete.component';
import ContentBoxTextTitle from '../../../components/content-box-text-title.component';
import { DeleteIcon, EditIcon } from '../../../components/icons.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';

export const CourierProfileEditScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    addCourierUserProfileLanguagesKnown,
    fieldProps,
    formik,
    handleObcExperiencePress,
    handleRemoveLanguage,
    handleSameHeadquartersAddress,
    handleAvatarButtonVisibility,
    handleDeleteUserProfilePicture,
    handleHeadquartersAddressChange,
    handleRefresh,
    handleSelect,
    handleSelectAirport,
    handleSave,
    headquartersAddressLocationRef,
    isDataLoaded,
    languagesList,
    loading,
    loadingDeletePicture,
    nationalities,
    organizationData,
    pickImageMobile,
    pickImageWeb,
    profilePicture,
    radioGroupVisible,
    refreshing,
    residenceAddressRef,
    selectedLanguage,
    setSelectedLanguage,
    setSelectedLanguageLevel,
    sameLocation,
    selectedLanguages,
    selectedLanguageLevel,
    user,
    userProfilePicture,
  } = useHook();

  const renderRefreshControl = () => (
    <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
  );

  const renderBaseAirports = () => {
    if (!isDataLoaded) {
      return <></>;
    }
    return (
      <OBCBaseAirportsEdit
        errors={formik?.errors}
        onSelectAirport={handleSelectAirport}
        values={formik.values}
      />
    );
  };

  const renderResidenceAddress = () => (
    <ContentBoxTextTitle
      title={t('courierUserProfile.residenceAddress', {
        defaultValue: 'Residence Address',
      }).toUpperCase()}
      Content={
        <View style={styles.locationAutocomplete}>
          <LocationAutocomplete
            ref={residenceAddressRef}
            fields={[
              {
                field: 'addressName',
                label: t('courierUserProfile.addressName', {
                  defaultValue: 'Address name',
                }),
                type: 'text',
              },
              ...LOCATION_AUTOCOMPLETE_INITIAL_FIELDS,
            ]}
            label={t('courierUserProfile.address', { defaultValue: 'Address' }) as string}
            language="en"
            placeholderText={
              t('courierUserProfile.enterResidenceAddress', {
                defaultValue: 'Enter your residence address',
              }) as string
            }
            currentLocationText={
              t('courierUserProfile.currentLocation', {
                defaultValue: 'Current location',
              }) as string
            }
            initialValues={user?.userRegisteredAddressLocation}
            onPress={(_, location) =>
              formik.setFieldValue('userRegisteredAddressLocation', location)
            }
            onFieldChange={(field, value) => {
              formik.setFieldValue('userRegisteredAddressLocation', {
                ...formik.values.userRegisteredAddressLocation,
                [field]: value,
              });
            }}
          />
        </View>
      }
      style={styles.contentBox}
    />
  );

  const renderBusinessInformation = () => (
    <ContentBoxTextTitle
      title={t('courierUserProfile.businessInformation', {
        defaultValue: 'Business Information',
      }).toUpperCase()}
      Content={
        <>
          <FormTextInputTopLabel
            label={t('courierUserProfile.companyNameLabel', {
              defaultValue: 'Company Name',
            })}
            placeholder={
              t('courierUserProfile.companyNamePlaceholder', {
                defaultValue: 'Company Name',
              }) as string
            }
            {...fieldProps('legalName')}
          />
          <FormAutocomplete
            label={
              t('courierUserProfile.taxCountry', {
                defaultValue: 'Tax Country',
              }) as string
            }
            appearance="default"
            items={CountryListData}
            fieldTitleKey="name"
            fieldValueKey="code"
            onSelect={
              handleSelect({
                autocomplete: true,
                options: CountryListData,
                fieldValueKey: 'code',
                valueKey: 'taxCountry',
              }) as any
            }
            value={formik.values.taxCountry}
            isMandatory
          />
          <Text selectable={true} style={styles.taxCountryCaption}>
            {
              t('courierUserProfile.taxCountryCaption', {
                defaultValue: 'In which country is my business registered for tax purposes?',
              }) as string
            }
          </Text>

          <View style={styles.companyAddressContainer}>
            {sameLocation && (
              <View style={styles.companyAddressCheckbox}>
                <CheckBox
                  checked={formik.values.headquartersAddressSame}
                  onChange={handleSameHeadquartersAddress}
                  status="primary"
                >
                  {
                    t('courierUserProfile.addressSameAsResidence', {
                      defaultValue: 'Company address same as residence address',
                    }) as string
                  }
                </CheckBox>
              </View>
            )}
            <LocationAutocomplete
              ref={headquartersAddressLocationRef}
              label={
                t('courierUserProfile.companyAddress', {
                  defaultValue: 'Company Address',
                }) as string
              }
              language="en"
              placeholderText={
                t('courierUserProfile.enterCompanyAddress', {
                  defaultValue: 'Enter company address',
                }) as string
              }
              currentLocationText={
                t('courierUserProfile.companyAddress', {
                  defaultValue: 'Company Address',
                }) as string
              }
              initialValues={organizationData?.organization?.headquartersAddressLocation}
              disableManualForm={formik.values.headquartersAddressSame}
              onPress={handleHeadquartersAddressChange}
            />
          </View>
        </>
      }
      style={styles.contentBox}
    />
  );

  const renderAccessoryRight = () => (
    <Button onPress={loading ? undefined : handleSave} appearance="filled" style={styles.saveBtn}>
      {loading ? <ActivityIndicator color="white" /> : 'Save'}
    </Button>
  );

  return (
    <>
      <TopNavigationWithBackButton
        title={t('courierUserProfile.myProfile', {
          defaultValue: 'My Profile',
        }).toUpperCase()}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierProfileNavigator', {
            screen: 'CourierProfileScreen',
          });
        }}
        accessoryRight={renderAccessoryRight}
      />

      <KeyboardAwareScrollView
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
        keyboardShouldPersistTaps="handled"
        refreshControl={renderRefreshControl()}
      >
        <Image
          style={themedStyles.avatar}
          source={
            profilePicture
              ? profilePicture
              : require('../../../assets/images/avatar_placeholder.png')
          }
          resizeMode="cover"
        />

        <View style={styles.editAvatarRow}>
          {Platform.OS === 'web' && (
            <Button
              onPress={pickImageWeb}
              style={styles.editAvatarButton}
              appearance="outline"
              accessoryLeft={EditIcon}
            />
          )}
          {Platform.OS !== 'web' && (
            <Button
              onPress={handleAvatarButtonVisibility}
              style={styles.editAvatarButton}
              appearance="outline"
              accessoryLeft={EditIcon}
            />
          )}
          {userProfilePicture && !!userProfilePicture.sasUrl && (
            <Button
              onPress={handleDeleteUserProfilePicture}
              style={styles.editAvatarButton}
              appearance="outline"
              accessoryLeft={loadingDeletePicture ? <ActivityIndicator /> : DeleteIcon}
            />
          )}
        </View>

        {radioGroupVisible && (
          <RadioGroup style={styles.editAvatarRow}>
            <Button style={styles.chooseImageBtn} onPress={pickImageWeb}>
              {
                t('common.chooseFromGallery', {
                  defaultValue: 'Choose from Gallery',
                }) as string
              }
            </Button>
            <Button style={styles.chooseImageBtn} onPress={pickImageMobile}>
              {
                t('common.takePicture', {
                  defaultValue: 'Take a Picture',
                }) as string
              }
            </Button>
          </RadioGroup>
        )}
        <View style={styles.nameContainer}>
          <View>
            <Text selectable={true} style={styles.nameText}>
              {user.firstNames ? `${user.firstNames} ` : ''}
              {user.lastName || ''}
            </Text>
          </View>

          <CopyableText
            copyableTextName={t('courierUserProfile.email', {
              defaultValue: 'Email',
            }).toLowerCase()}
            style={styles.emailText}
            text={user.email || ''}
          />
          {!!user.phone && (
            <CopyableText
              copyableTextName={t('courierUserProfile.phoneNumber', {
                defaultValue: 'Phone number',
              }).toLowerCase()}
              style={styles.emailText}
              text={user.phone || ''}
            />
          )}
        </View>

        <OBCPersonalDetailsEdit
          errors={formik?.errors}
          fieldProps={fieldProps}
          onSelect={handleSelect}
          nationalities={nationalities}
          setFieldValue={formik.setFieldValue}
          validateField={formik.validateField}
          values={formik.values}
        />

        {renderBaseAirports()}

        {isDataLoaded ? (
          <>
            {renderResidenceAddress()}
            {renderBusinessInformation()}
            <OBCEmergencyContactEdit
              fieldProps={fieldProps}
              setFieldValue={formik.setFieldValue}
              values={formik.values}
            />
            <OBCSocialProfileEdit
              errors={formik?.errors}
              fieldProps={fieldProps}
              values={formik.values}
            />
            <OBCCreditCardEdit
              errors={formik?.errors}
              fieldProps={fieldProps}
              onSelect={handleSelect}
              values={formik.values}
            />
            <OBCExperienceEdit
              errors={formik?.errors}
              fieldProps={fieldProps}
              handleObcExperiencePress={handleObcExperiencePress}
              setFieldValue={formik.setFieldValue}
              values={formik.values}
            />
            <OBCLanguagesEdit
              addCourierUserProfileLanguagesKnown={addCourierUserProfileLanguagesKnown}
              handleRemoveLanguage={handleRemoveLanguage}
              languagesList={languagesList}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              setSelectedLanguageLevel={setSelectedLanguageLevel}
              selectedLanguages={selectedLanguages}
              selectedLanguageLevel={selectedLanguageLevel}
            />
          </>
        ) : (
          <View
            style={[
              globalStyle.alignItemsCenter,
              globalStyle.padding20,
              globalStyle.paddingBottom30,
            ]}
          >
            <Text>{t('common.loadingOtherData', { defaultValue: 'Loading other data...' })}</Text>
          </View>
        )}
      </KeyboardAwareScrollView>
    </>
  );
};
