import React from 'react';
import { Platform } from 'react-native';

import {
  Icon,
  Popover,
  Text,
  StyleService,
  useStyleSheet,
  PopoverPlacements,
} from '@ui-kitten/components';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';

import { globalStyle } from '../../../common/style';

export const IconWithTextPopover = ({
  text,
  hitSlop = { top: 5, bottom: 5, right: 0, left: 0 },
}: {
  text: string;
  hitSlop?: { top: number; bottom: number; right: number; left: number };
}) => {
  const styles = useStyleSheet(themedStyles);

  const [visible, setVisible] = React.useState<boolean>(false);

  const togglePopover = (): void => {
    setVisible(!visible);
  };

  if (hitSlop === undefined) {
    hitSlop = { top: 5, bottom: 5, right: 0, left: 0 };
  }

  const renderPopoverAnchor = (): JSX.Element => (
    <TouchableWithoutFeedback hitSlop={hitSlop} onPress={() => setVisible(!visible)}>
      <Icon name="alert-triangle" fill={(styles.icon as any).color} style={globalStyle.size24} />
    </TouchableWithoutFeedback>
  );

  return (
    <Popover
      placement={PopoverPlacements.TOP_START}
      contentContainerStyle={styles.contentContainer}
      visible={visible}
      anchor={renderPopoverAnchor}
      onBackdropPress={togglePopover}
      fullWidth={false}
      style={styles.popover}
    >
      <Text>{text}</Text>
    </Popover>
  );
};

export const themedStyles = StyleService.create({
  contentContainer: {
    marginBottom: Platform.OS === 'web' ? 10 : -40,
    maxWidth: 240,
  },
  popover: {
    padding: 10,
    paddingHorizontal: 15,
    borderColor: 'border-basic-color-5',
  },
  icon: {
    color: 'color-warning-500',
  },
});
