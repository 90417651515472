import React, { useMemo, useState } from 'react';
import { StyleProp, TextStyle, View } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as ExpoLocalization from 'expo-localization';
import { useTranslation } from 'react-i18next';
import CurrencyInput, { CurrencyInputProps } from 'react-native-currency-input';

import { Currency } from '../../../graphql-types';
import { ITranslate } from '../../../types';

const converter = require('number-to-words');

export const FormMoneyInput = (
  props: Omit<CurrencyInputProps, 'value' | 'onChangeValue'> & {
    onChangeValue: (value: number | undefined) => void;
    value: number | undefined;
    label: ITranslate | string;
    targetCurrency: Currency;
    caption?: ITranslate | string;
    isMandatory?: boolean;
    isDisabled?: boolean;
    style?: StyleProp<TextStyle>;
    displayNumberToWords?: boolean;
    maxLength?: number;
  },
): React.ReactElement => {
  const { t } = useTranslation();

  const styles = useStyleSheet(themedStyles);

  const prefixes: { [key: string]: string } = {
    USD: 'USD',
    EUR: 'EUR',
    GBP: 'GBP',
    JPY: 'YEN',
  };

  const prefix = prefixes[props.targetCurrency];

  const [numInWords, setNumInWords] = useState<string | undefined>();

  const { delimiter, placeholder, separator } = useMemo(() => {
    if (
      props.targetCurrency.toLowerCase() === 'eur' ||
      ExpoLocalization.currency?.toLocaleLowerCase() === 'eur'
    ) {
      return { separator: ',', placeholder: 'EUR 0,00', delimiter: '.' };
    }
    if (
      props.targetCurrency.toLowerCase() === 'usd' ||
      ExpoLocalization.currency?.toLocaleLowerCase() === 'usd'
    ) {
      return { separator: '.', placeholder: '$ 0.00', delimiter: ',' };
    }
    return {
      separator: t('common.decimalSeparator', {
        defaultValue: ExpoLocalization.decimalSeparator,
      }) as string,
      delimiter: t('common.digitGroupSeparator', {
        defaultValue: ExpoLocalization.digitGroupingSeparator,
      }) as string,
      placeholder: '0.00',
    };
  }, [props.targetCurrency, t]);

  const setNumToWords = (value: number | undefined) => {
    if (value) {
      const naturalNumberInWords = converter.toWords(Math.floor(value));
      const firstDecimalInWords = converter.toWords(Math.floor(value * 10) % 10);
      const secondDecimalInWords = converter.toWords(Math.floor(value * 100) % 10);
      let numberInWords = naturalNumberInWords;
      if (secondDecimalInWords !== 'zero') {
        numberInWords =
          naturalNumberInWords + ' point ' + firstDecimalInWords + ' - ' + secondDecimalInWords;
      } else if (firstDecimalInWords !== 'zero') {
        numberInWords = naturalNumberInWords + ' point ' + firstDecimalInWords;
      }
      setNumInWords(numberInWords);
    } else {
      setNumInWords(undefined);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <Text selectable={true} style={styles.label}>
          {props.label as string}
          {props.isMandatory ? (
            <Text selectable={true} style={styles.mandatoryStar}>
              *
            </Text>
          ) : (
            <></>
          )}
        </Text>
      </View>
      <CurrencyInput
        {...props}
        maxLength={props.maxLength ? props.maxLength : (prefix?.length || 1) + 1 + 10}
        value={props.value || null}
        onChangeValue={(value) => {
          if (props.displayNumberToWords) {
            setNumToWords(value || undefined);
          }
          props.onChangeValue(value || undefined);
        }}
        style={[
          props.style,
          styles.unitInput,
          props.isDisabled ? styles.unitInputDisabled : undefined,
        ]}
        placeholder={placeholder}
        prefix={prefix + ' '}
        separator={separator}
        delimiter={delimiter}
        editable={!props.isDisabled}
      />
      {props.caption || (props.displayNumberToWords && numInWords) ? (
        <View style={styles.captionWrapperContainer}>
          {props.displayNumberToWords && numInWords ? (
            <View style={styles.numInWordsContainer}>
              <Text selectable={true} style={styles.numInWordsText}>
                [ {numInWords} ]
              </Text>
            </View>
          ) : (
            <></>
          )}
          {props.caption ? (
            <View style={styles.captionContainer}>
              <Text selectable={true} style={styles.caption}>
                {props.caption as string}
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  captionWrapperContainer: {
    paddingTop: 0,
  },
  numInWordsContainer: {
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    flex: 1,
    paddingRight: 10,
    paddingLeft: 10,
  },
  numInWordsText: {
    color: 'color-basic-600',
    fontSize: 10,
  },
  container: {
    marginTop: 20,
  },
  captionContainer: {
    paddingTop: 5,
    paddingLeft: 10,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  labelContainer: {
    paddingLeft: 10,
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: { color: 'color-danger-600' },
  checkBox: {
    marginLeft: 10,
  },
  unitInput: {
    backgroundColor: 'background-basic-color-2',
    borderColor: 'border-basic-color-4',
    color: 'text-basic-color',
    borderRadius: 4,
    borderWidth: 1,
    fontSize: 16,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 5,
  },
  unitInputDisabled: {
    backgroundColor: 'background-basic-color-2',
    borderColor: 'border-basic-color-4',
    color: 'text-disabled-color',
  },
});
