import React, { createContext } from 'react';
import { ActivityIndicator, Keyboard, TouchableOpacity, View } from 'react-native';

import { Button, Input, Text, useStyleSheet } from '@ui-kitten/components';
import Modal from 'react-native-modal';
import Icon from 'react-native-vector-icons/Ionicons';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { ConfirmModalProps } from './types';
import { globalStyle } from '../../../../common/style';

export const ConfirmModalContext = createContext({
  cancelButtonAppearance: 'outline',
  cancelButtonStatus: 'primary',
  cancelButtonText: '',
  confirmButtonAppearance: 'filled',
  confirmButtonStatus: 'primary',
  confirmButtonText: '',
  inputLabel: false,
  showInput: false,
  onCancelPress: () => {},
  onConfirmPress: () => {},
  renderCustomButtons: null,
  errorText: '',
  text: '',
  title: '',
  visible: false,
  hideConfirmModal: () => {},
  showConfirmModal: (_: any) => {},
  updateConfirmModal: (_: any) => {},
});

export const ConfirmModal = ({
  children,
  cancelButtonAppearance = 'outline',
  cancelButtonStatus = 'primary',
  cancelButtonText = '',
  confirmButtonAppearance = 'filled',
  confirmButtonStatus = 'primary',
  confirmButtonText,
  handleCancel,
  handleConfirm,
  loading,
  renderCustomButtons,
  title,
  text,
  visible,
}: any) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Modal
      animationIn="fadeIn"
      animationOut="fadeOut"
      avoidKeyboard
      isVisible={visible}
      backdropOpacity={0.8}
      useNativeDriver
    >
      <View style={styles.wrapper}>
        <TouchableOpacity style={styles.background} onPress={Keyboard.dismiss} />
        <View style={styles.inner}>
          <Text category="h5" style={styles.title}>
            {title}
          </Text>
          <Text style={styles.subtitle}>{text}</Text>

          {!!children && children}

          <View style={styles.buttonsContainer}>
            {!!confirmButtonText && !renderCustomButtons && (
              <Button
                appearance={confirmButtonAppearance}
                status={confirmButtonStatus}
                size="medium"
                onPress={handleConfirm}
                style={globalStyle.marginBottom10}
              >
                {loading ? (
                  <ActivityIndicator color={(styles.loading as any).color} />
                ) : (
                  confirmButtonText
                )}
              </Button>
            )}
            {!!cancelButtonText && !renderCustomButtons && (
              <Button
                appearance={cancelButtonAppearance}
                status={cancelButtonStatus}
                size="medium"
                onPress={handleCancel}
              >
                {cancelButtonText}
              </Button>
            )}

            {!!renderCustomButtons && renderCustomButtons()}
          </View>

          <TouchableOpacity
            style={styles.closeButton}
            onPress={handleCancel}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <Icon color={(styles.closeIcon as any).color} name="close" size={26} />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export const ConfirmModalProvider = ({ children }: ConfirmModalProps) => {
  const styles = useStyleSheet(themedStyles);

  const {
    data,
    handleCancel,
    handleConfirm,
    handleTextChange,
    hideConfirmModal,
    loading,
    showConfirmModal,
    updateConfirmModal,
    textValue,
  } = useHook();

  const {
    cancelButtonAppearance,
    cancelButtonStatus,
    cancelButtonText,
    confirmButtonAppearance,
    confirmButtonStatus,
    confirmButtonText,
    errorText,
    inputLabel,
    showInput,
    renderCustomButtons,
    text,
    title,
    visible,
  } = data;

  const renderInput = () => {
    if (!showInput) {
      return null;
    }
    return (
      <>
        <View style={styles.inputContainer}>
          <Input label={inputLabel} value={textValue} onChangeText={handleTextChange} />
        </View>
        {!!errorText && <Text style={styles.errorText}>{errorText}</Text>}
      </>
    );
  };

  return (
    <ConfirmModalContext.Provider
      value={{ ...data, showConfirmModal, hideConfirmModal, updateConfirmModal }}
    >
      {children}
      <ConfirmModal
        cancelButtonAppearance={cancelButtonAppearance}
        cancelButtonStatus={cancelButtonStatus}
        cancelButtonText={cancelButtonText}
        confirmButtonAppearance={confirmButtonAppearance}
        confirmButtonStatus={confirmButtonStatus}
        confirmButtonText={confirmButtonText}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        loading={loading}
        renderCustomButtons={renderCustomButtons}
        text={text}
        title={title}
        visible={visible}
      >
        {renderInput()}
      </ConfirmModal>
    </ConfirmModalContext.Provider>
  );
};
