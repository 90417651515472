import { Alert, Platform } from 'react-native';

import * as ExpoLinking from 'expo-linking';
import { createMapLink } from 'react-native-open-maps';

const useOpenMapsLink = (): ((props: {
  latitude?: number;
  longitude?: number;
  destinationAddress?: string | null;
  zoom?: number;
}) => void) => {
  return async (props: {
    latitude?: number;
    longitude?: number;
    destinationAddress?: string | null;
    zoom?: number;
  }): Promise<void> => {
    if (props.latitude && props.longitude) {
      const mapsUrl = createMapLink({
        latitude: props.latitude,
        longitude: props.longitude,
        end: props.destinationAddress || undefined,
        zoom: 10,
      });
      if (Platform.OS === 'web') {
        window.open(mapsUrl, '_blank', 'noopener');
      } else if (await ExpoLinking.canOpenURL(mapsUrl)) {
        await ExpoLinking.openURL(mapsUrl);
      } else {
        Alert.alert('Cannot open url' + mapsUrl);
      }
    }
  };
};

export default useOpenMapsLink;
