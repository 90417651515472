/* tslint:disable */
import { DevicePermissionExpiration } from './types';
import { DateTime } from 'luxon';
import { JSONObject } from './types';
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkAllNewSystemNotificationsAsReadForOrganizationUser
// ====================================================

export interface MarkAllNewSystemNotificationsAsReadForOrganizationUser {
  markAllNewSystemNotificationsAsReadForOrganizationUser: number;
}

export interface MarkAllNewSystemNotificationsAsReadForOrganizationUserVariables {
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkSystemNotificationAsReadForOrganizationUser
// ====================================================

export interface MarkSystemNotificationAsReadForOrganizationUser {
  markSystemNotificationAsReadForOrganizationUser: number;
}

export interface MarkSystemNotificationAsReadForOrganizationUserVariables {
  notificationId: string;
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectWithAgencyOrganizationAsCourierOrganization
// ====================================================

export interface ConnectWithAgencyOrganizationAsCourierOrganization_connectWithAgencyOrganizationAsCourierOrganization_connectingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  shortName: string | null; // Short name of the Organization
}

export interface ConnectWithAgencyOrganizationAsCourierOrganization_connectWithAgencyOrganizationAsCourierOrganization_connectedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  shortName: string | null; // Short name of the Organization
}

export interface ConnectWithAgencyOrganizationAsCourierOrganization_connectWithAgencyOrganizationAsCourierOrganization {
  __typename: 'OrganizationOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  createdAt: DateTime;
  updatedAt: DateTime;
  connectingOrganization: ConnectWithAgencyOrganizationAsCourierOrganization_connectWithAgencyOrganizationAsCourierOrganization_connectingOrganization;
  connectedOrganization: ConnectWithAgencyOrganizationAsCourierOrganization_connectWithAgencyOrganizationAsCourierOrganization_connectedOrganization;
}

export interface ConnectWithAgencyOrganizationAsCourierOrganization {
  connectWithAgencyOrganizationAsCourierOrganization: ConnectWithAgencyOrganizationAsCourierOrganization_connectWithAgencyOrganizationAsCourierOrganization;
}

export interface ConnectWithAgencyOrganizationAsCourierOrganizationVariables {
  courierOrganizationId: string;
  activationCode: string;
  agencyOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddDeviceLogUnauthenticated
// ====================================================

export interface AddDeviceLogUnauthenticated_addDeviceLogUnauthenticated_lastDeviceLog_data {
  __typename: 'DeviceLogData';
  uptime: number | null;
}

export interface AddDeviceLogUnauthenticated_addDeviceLogUnauthenticated_lastDeviceLog {
  __typename: 'DeviceLogOutput';
  id: string;
  data: AddDeviceLogUnauthenticated_addDeviceLogUnauthenticated_lastDeviceLog_data | null; // JSON device log data
}

export interface AddDeviceLogUnauthenticated_addDeviceLogUnauthenticated {
  __typename: 'AddDeviceLogOutput';
  deviceLogIds: string[];
  lastDeviceLog: AddDeviceLogUnauthenticated_addDeviceLogUnauthenticated_lastDeviceLog | null;
}

export interface AddDeviceLogUnauthenticated {
  addDeviceLogUnauthenticated: AddDeviceLogUnauthenticated_addDeviceLogUnauthenticated;
}

export interface AddDeviceLogUnauthenticatedVariables {
  addDeviceLogInputs: AddDeviceLogInput[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddDeviceLog
// ====================================================

export interface AddDeviceLog_addDeviceLog_lastDeviceLog_data {
  __typename: 'DeviceLogData';
  uptime: number | null;
}

export interface AddDeviceLog_addDeviceLog_lastDeviceLog {
  __typename: 'DeviceLogOutput';
  id: string;
  data: AddDeviceLog_addDeviceLog_lastDeviceLog_data | null; // JSON device log data
}

export interface AddDeviceLog_addDeviceLog {
  __typename: 'AddDeviceLogOutput';
  deviceLogIds: string[];
  lastDeviceLog: AddDeviceLog_addDeviceLog_lastDeviceLog | null;
}

export interface AddDeviceLog {
  addDeviceLog: AddDeviceLog_addDeviceLog;
}

export interface AddDeviceLogVariables {
  addDeviceLogInputs: AddDeviceLogInput[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ActivateAccount
// ====================================================

export interface ActivateAccount_activateAccount_token {
  __typename: 'AzureADB2CToken';
  iss: string;
  exp: number;
  nbf: number;
  aud: string;
  oid: string;
  sub: string;
  city: string;
  country: string;
  name: string;
  givenName: string;
  familyName: string;
  emails: string[];
  tfp: string;
  azp: string;
  ver: string;
  iat: number;
}

export interface ActivateAccount_activateAccount_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface ActivateAccount_activateAccount_organizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
}

export interface ActivateAccount_activateAccount_organization_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
}

export interface ActivateAccount_activateAccount_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  usedOrganizationInvitation: ActivateAccount_activateAccount_organization_usedOrganizationInvitation | null;
}

export interface ActivateAccount_activateAccount_device {
  __typename: 'Device';
  id: string;
  deviceType: DeviceCategoryType; // DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV)
}

export interface ActivateAccount_activateAccount_organizationSubscriptionPlan_prices {
  __typename: 'SubscriptionPlanPrice';
  currency: string;
  value: string;
}

export interface ActivateAccount_activateAccount_organizationSubscriptionPlan_subscriptionPlan_planName {
  __typename: 'SubscriptionPlanName';
  key: string;
  defaultValue: string;
}

export interface ActivateAccount_activateAccount_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures {
  __typename: 'SubscriptionFeature';
  id: string;
  name: string; // name
  count: number; // feature max count
  value: subscriptionFeatureType; // (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
}

export interface ActivateAccount_activateAccount_organizationSubscriptionPlan_subscriptionPlan {
  __typename: 'SubscriptionPlan';
  id: string;
  planName: ActivateAccount_activateAccount_organizationSubscriptionPlan_subscriptionPlan_planName;
  subscriptionFeatures:
    | ActivateAccount_activateAccount_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures[]
    | null;
}

export interface ActivateAccount_activateAccount_organizationSubscriptionPlan {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
  isActive: boolean;
  endTime: DateTime;
  prices: ActivateAccount_activateAccount_organizationSubscriptionPlan_prices[] | null;
  subscriptionPlan: ActivateAccount_activateAccount_organizationSubscriptionPlan_subscriptionPlan;
}

export interface ActivateAccount_activateAccount {
  __typename: 'UserContext';
  sub: string;
  token: ActivateAccount_activateAccount_token;
  user: ActivateAccount_activateAccount_user | null;
  organizationUser: ActivateAccount_activateAccount_organizationUser | null;
  organization: ActivateAccount_activateAccount_organization | null;
  roleType: OrganizationUserRoleType | null;
  organizationType: OrganizationType | null;
  role: string;
  organizationActivatedAt: DateTime | null;
  device: ActivateAccount_activateAccount_device;
  deviceId: string;
  userContextId: string;
  organizationSubscriptionPlan: ActivateAccount_activateAccount_organizationSubscriptionPlan | null;
}

export interface ActivateAccount {
  activateAccount: ActivateAccount_activateAccount;
}

export interface ActivateAccountVariables {
  activationCode: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FindOrCreateAdHocAvailability
// ====================================================

export interface FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability_lastLocationForDevice_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability_lastLocationForDevice_locationGeometry {
  __typename: 'LocationGeometry';
  location: FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability_lastLocationForDevice_locationGeometry_location;
}

export interface FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability_lastLocationForDevice {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  locationGeometry: FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability_lastLocationForDevice_locationGeometry;
}

export interface FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability {
  __typename: 'Availability';
  id: string;
  lastLocationForDevice: FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability_lastLocationForDevice | null;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
}

export interface FindOrCreateAdHocAvailability {
  findOrCreateAdHocAvailability: FindOrCreateAdHocAvailability_findOrCreateAdHocAvailability;
}

export interface FindOrCreateAdHocAvailabilityVariables {
  deviceLocationId?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrUpdateCourierUserContact
// ====================================================

export interface CreateOrUpdateCourierUserContact_createOrUpdateCourierUserContact {
  __typename: 'UserContact';
  id: string;
  expoContactId: string | null; // The contact ID from the address book
  phone: string; // Phone Number
  email: string | null; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
}

export interface CreateOrUpdateCourierUserContact {
  createOrUpdateCourierUserContact: CreateOrUpdateCourierUserContact_createOrUpdateCourierUserContact;
}

export interface CreateOrUpdateCourierUserContactVariables {
  userContactInput: UserContactInput;
  inviteUserContact: boolean;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAvailability
// ====================================================

export interface CreateAvailability_createAvailability_availabilityLocations_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CreateAvailability_createAvailability_availabilityLocations_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: CreateAvailability_createAvailability_availabilityLocations_location_locationGeometry_location;
}

export interface CreateAvailability_createAvailability_availabilityLocations_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  locationGeometry: CreateAvailability_createAvailability_availabilityLocations_location_locationGeometry;
}

export interface CreateAvailability_createAvailability_availabilityLocations {
  __typename: 'AvailabilityLocation';
  id: string;
  location: CreateAvailability_createAvailability_availabilityLocations_location;
}

export interface CreateAvailability_createAvailability_user {
  __typename: 'User';
  id: string;
  email: string; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
}

export interface CreateAvailability_createAvailability_lastDeviceLocationForDevice_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationForDevice_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: CreateAvailability_createAvailability_lastDeviceLocationForDevice_location_locationGeometry_location;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationForDevice_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  locationGeometry: CreateAvailability_createAvailability_lastDeviceLocationForDevice_location_locationGeometry;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationForDevice {
  __typename: 'DeviceLocation';
  id: string;
  timestamp: DateTime;
  location: CreateAvailability_createAvailability_lastDeviceLocationForDevice_location | null;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser_location_locationGeometry_location;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  locationGeometry: CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser_location_locationGeometry;
}

export interface CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser {
  __typename: 'DeviceLocation';
  id: string;
  timestamp: DateTime;
  location: CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser_location | null;
}

export interface CreateAvailability_createAvailability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  availabilityLocations: CreateAvailability_createAvailability_availabilityLocations[] | null;
  user: CreateAvailability_createAvailability_user;
  lastDeviceLocationForDevice: CreateAvailability_createAvailability_lastDeviceLocationForDevice | null;
  lastDeviceLocationWithLocationForUser: CreateAvailability_createAvailability_lastDeviceLocationWithLocationForUser | null;
}

export interface CreateAvailability {
  createAvailability: CreateAvailability_createAvailability;
}

export interface CreateAvailabilityVariables {
  suggestedLocationInput: SuggestedLocationInput;
  startTime: DateTime;
  endTime: DateTime;
  timeZoneIdentifier: string;
  timeZoneDestinationOffset: number;
  timeZoneRawOffset: number;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAvailability
// ====================================================

export interface DeleteAvailability_deleteAvailability {
  __typename: 'Availability';
  id: string;
}

export interface DeleteAvailability {
  deleteAvailability: DeleteAvailability_deleteAvailability | null;
}

export interface DeleteAvailabilityVariables {
  availabilityId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisableAdHocAvailability
// ====================================================

export interface DisableAdHocAvailability {
  disableAdHocAvailability: boolean;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegisterExistingUserWithOwnedOrganization
// ====================================================

export interface RegisterExistingUserWithOwnedOrganization_registerExistingUserWithOwnedOrganization_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface RegisterExistingUserWithOwnedOrganization_registerExistingUserWithOwnedOrganization_organization {
  __typename: 'Organization';
  id: string;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface RegisterExistingUserWithOwnedOrganization_registerExistingUserWithOwnedOrganization {
  __typename: 'OrganizationUser';
  id: string;
  user: RegisterExistingUserWithOwnedOrganization_registerExistingUserWithOwnedOrganization_user;
  organization: RegisterExistingUserWithOwnedOrganization_registerExistingUserWithOwnedOrganization_organization;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  createdAt: DateTime;
}

export interface RegisterExistingUserWithOwnedOrganization {
  registerExistingUserWithOwnedOrganization: RegisterExistingUserWithOwnedOrganization_registerExistingUserWithOwnedOrganization;
}

export interface RegisterExistingUserWithOwnedOrganizationVariables {
  organizationType: OrganizationType;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VoidOrganizationInvitation
// ====================================================

export interface VoidOrganizationInvitation_voidOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  activationCode: string | null;
  voidedAt: DateTime | null;
  updatedAt: DateTime;
}

export interface VoidOrganizationInvitation {
  voidOrganizationInvitation: VoidOrganizationInvitation_voidOrganizationInvitation;
}

export interface VoidOrganizationInvitationVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganizationInvitationSharing
// ====================================================

export interface CreateOrganizationInvitationSharing_createOrganizationInvitationSharing_sharedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CreateOrganizationInvitationSharing_createOrganizationInvitationSharing_organizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  activationCode: string | null;
}

export interface CreateOrganizationInvitationSharing_createOrganizationInvitationSharing {
  __typename: 'OrganizationInvitationSharing';
  id: string;
  appName: string | null;
  platformVersion: string | null;
  platform: string;
  language: string | null;
  sharedAt: DateTime;
  sharedByUser: CreateOrganizationInvitationSharing_createOrganizationInvitationSharing_sharedByUser | null;
  organizationInvitation: CreateOrganizationInvitationSharing_createOrganizationInvitationSharing_organizationInvitation;
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface CreateOrganizationInvitationSharing {
  createOrganizationInvitationSharing: CreateOrganizationInvitationSharing_createOrganizationInvitationSharing;
}

export interface CreateOrganizationInvitationSharingVariables {
  organizationInvitationSharingInput: OrganizationInvitationSharingInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrganizationInvitationAsPriojet
// ====================================================

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_bitrix24Deal {
  __typename: 'OrganizationInvitationBitrix24Deal';
  id: number;
  bitrixDealCategoryId: number;
  bitrixInvitedCompanyId: number;
  bitrixInvitedContactId: number;
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedForOrganizationOrganization_connectedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedForOrganizationOrganization_connectingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedForOrganizationOrganization {
  __typename: 'OrganizationOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  connectedOrganization: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedForOrganizationOrganization_connectedOrganization;
  connectingOrganization: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedForOrganizationOrganization_connectingOrganization;
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationContact_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationContact {
  __typename: 'OrganizationContact';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  phone: string; // Phone Number
  email: string | null; // Email address
  isPriojetContact: boolean; // Is this contact a PRIOjet Contact
  isCourierContact: boolean; // Is this contact a Courier Contact
  isAgencyContact: boolean; // Is this contact an Agency Contact
  isClientContact: boolean; // Is this contact a Client Contact
  isTravelerContact: boolean; // Is this contact a Traveler Contact
  isServiceProviderContact: boolean; // Is this contact a ServiceProvider Contact
  isOtherContact: boolean; // Is this contact an Other Contact
  organization: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationContact_organization;
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_invitedUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_invitedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_invitingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_createdByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationInvitationSharings_sharedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationInvitationSharings {
  __typename: 'OrganizationInvitationSharing';
  id: string;
  appName: string | null;
  platformVersion: string | null;
  platform: string;
  language: string | null;
  sharedAt: DateTime;
  sharedByUser: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationInvitationSharings_sharedByUser | null;
}

export interface CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet {
  __typename: 'OrganizationInvitation';
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  activationCode: string | null;
  reservedComment: string | null;
  bitrix24Deal: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_bitrix24Deal | null; // OrganizationInvitationBitrix24Deal Data
  usedForOrganizationOrganization: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedForOrganizationOrganization | null;
  organizationContact: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationContact | null;
  invitedOrganizationType: OrganizationType | null; // Invited Organization Type (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5). The OrganizationType of the Organization that can be activated with this activationCode.
  roleType: OrganizationUserRoleType | null; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST). The RoleType of the OrganizationUser that can be created with this activationCode, if applicable.
  invitedUser: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_invitedUser | null;
  invitedOrganization: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_invitedOrganization | null;
  invitingOrganization: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_invitingOrganization | null;
  usedByUser: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_usedByUser | null;
  createdByUser: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_createdByUser | null;
  voidedAt: DateTime | null;
  usedAt: DateTime | null;
  organizationInvitationSharings: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet_organizationInvitationSharings[];
}

export interface CreateOrganizationInvitationAsPriojet {
  createOrganizationInvitationAsPriojet: CreateOrganizationInvitationAsPriojet_createOrganizationInvitationAsPriojet;
}

export interface CreateOrganizationInvitationAsPriojetVariables {
  reservedComment: string;
  organizationInvitationType: OrganizationInvitationType;
  invitedOrganizationId?: string | null;
  invitingOrganizationId?: string | null;
  roleType?: OrganizationUserRoleType | null;
  invitedOrganizationType?: OrganizationType | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditOrganizationInvitation
// ====================================================

export interface EditOrganizationInvitation_voidOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  activationCode: string | null;
  voidedAt: DateTime | null;
  updatedAt: DateTime;
}

export interface EditOrganizationInvitation {
  voidOrganizationInvitation: EditOrganizationInvitation_voidOrganizationInvitation;
}

export interface EditOrganizationInvitationVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GetOrCreateDevice
// ====================================================

export interface GetOrCreateDevice_getOrCreateDevice {
  __typename: 'Device';
  id: string;
  deviceType: DeviceCategoryType; // DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV)
}

export interface GetOrCreateDevice {
  getOrCreateDevice: GetOrCreateDevice_getOrCreateDevice;
}

export interface GetOrCreateDeviceVariables {
  deviceInput: DeviceInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDevice
// ====================================================

export interface UpdateDevice_updateDevice {
  __typename: 'Device';
  id: string;
  deviceType: DeviceCategoryType; // DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV)
}

export interface UpdateDevice {
  updateDevice: UpdateDevice_updateDevice;
}

export interface UpdateDeviceVariables {
  deviceInput: DeviceInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDeviceLocations
// ====================================================

export interface CreateDeviceLocations_createDeviceLocations_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
}

export interface CreateDeviceLocations_createDeviceLocations {
  __typename: 'DeviceLocation';
  id: string;
  location: CreateDeviceLocations_createDeviceLocations_location | null;
  latitude: number;
  longitude: number;
  altitude: number | null;
  createdAt: DateTime;
}

export interface CreateDeviceLocations {
  createDeviceLocations: CreateDeviceLocations_createDeviceLocations | null;
}

export interface CreateDeviceLocationsVariables {
  deviceLocationInputs: DeviceLocationInput[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserIdentityDocument
// ====================================================

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserIdentityDocument_createUserIdentityDocument {
  __typename: 'UserIdentityDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: IdentityDocumentType; // IdentityDocumentType
  documentSubType: IdentityDocumentSubType | null; // IdentityDocumentSubType
  documentSubTypeMulti: IdentityDocumentSubType[] | null; // IdentityDocumentSubType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  countryOfIssue: string | null; // country issuing the document
  expiresOn: DateTime | null; // expiry date as per the document
  nationality: string | null; // Nationality as per the document ISO 3166-1 alpha-3 code
  documentFrontFromAzureCompressed: CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserIdentityDocument_createUserIdentityDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzureOriginal | null;
}

export interface CreateUserIdentityDocument {
  createUserIdentityDocument: CreateUserIdentityDocument_createUserIdentityDocument;
}

export interface CreateUserIdentityDocumentVariables {
  userIdentityDocumentInput: UserIdentityDocumentInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCourierUserProfile
// ====================================================

export interface UpdateCourierUserProfile_updateCourierUserProfile_languagesKnown {
  __typename: 'TLanguageKnown';
  language: string;
  level: LanguageSkillLevel;
}

export interface UpdateCourierUserProfile_updateCourierUserProfile_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface UpdateCourierUserProfile_updateCourierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  emergencyContactRelationship: string | null; // Emergency Contact Relationship
  emergencyContactPhone: string | null; // Emergency Contact Phone Number
  emergencyContactName: string | null; // Emergency Contact Name
  emergencyContactEmail: string | null; // Emergency Contact Email
  emergencyContactAddress: string | null; // Emergency Contact Address
  hasAmexCreditCard: boolean | null; // American Express Credit Card
  hasDinerCreditCard: boolean | null; // Diner's Credit Card
  hasMasterCreditCard: boolean | null; // Master Credit Card
  hasVisaCreditCard: boolean | null; // Visa Credit Card
  hasAmexDebitCard: boolean | null; // American Express debit/prepaid Card
  hasDinerDebitCard: boolean | null; // Diner's debit/prepaid Card
  hasMasterDebitCard: boolean | null; // Master Debit/Prepaid Card
  hasVisaDebitCard: boolean | null; // Visa Debit/Prepaid Card
  amexCreditCardLimit: number | null; // American Express Credit Card Limit
  dinerCreditCardLimit: number | null; // Diner's Credit Card Limit
  masterCreditCardLimit: number | null; // Master Credit Card Limit
  visaCreditCardLimit: number | null; // Visa Credit Card Limit
  amexCreditCardCurrency: Currency | null; // American Express Credit Card Currency (EUR=0, USD=1)
  dinerCreditCardCurrency: Currency | null; // Diners Credit Card Currency (EUR=0, USD=1)
  masterCreditCardCurrency: Currency | null; // Master Credit Card Currency (EUR=0, USD=1)
  visaCreditCardCurrency: Currency | null; // Visa Credit Card Currency (EUR=0, USD=1)
  hasObcExperience: boolean | null; // Prior OBC Experience (Yes/No)
  firstObcJobDateAt: DateTime | null; // First OBC job date
  numberOfPastObcJobs: number | null; // Number of Prior OBC Jobs
  hasMedicalObcExperience: boolean | null; // Prior Medical (Stem cell) OBC Experience (Yes/No)
  languagesKnown: UpdateCourierUserProfile_updateCourierUserProfile_languagesKnown[] | null; // Languages known
  onboardExperiences: string[] | null; // On Board Experiences
  taxCountry: string | null; // In which country is my business registered for tax purposes, ISO 3166 Alpha-3 code
  socialProfiles: UpdateCourierUserProfile_updateCourierUserProfile_socialProfiles[]; // Social profiles
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface UpdateCourierUserProfile {
  updateCourierUserProfile: UpdateCourierUserProfile_updateCourierUserProfile;
}

export interface UpdateCourierUserProfileVariables {
  courierUserProfileInput: CourierUserProfileInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserProfile
// ====================================================

export interface UpdateUserProfile_updateUserProfile_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateUserProfile_updateUserProfile_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateUserProfile_updateUserProfile_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateUserProfile_updateUserProfile_profilePicture_sasToken;
  metadata: UpdateUserProfile_updateUserProfile_profilePicture_metadata;
}

export interface UpdateUserProfile_updateUserProfile_userRegisteredAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface UpdateUserProfile_updateUserProfile {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  email2: string | null; // Secondary Email address
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  phone: string | null; // Phone Number
  phone2: string | null; // Secondary Phone Number
  profilePicture: UpdateUserProfile_updateUserProfile_profilePicture | null;
  userRegisteredAddressLocation: UpdateUserProfile_updateUserProfile_userRegisteredAddressLocation | null;
}

export interface UpdateUserProfile {
  updateUserProfile: UpdateUserProfile_updateUserProfile;
}

export interface UpdateUserProfileVariables {
  userProfileInput: UserProfileInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteUserProfilePicture
// ====================================================

export interface deleteUserProfilePicture_deleteUserProfilePicture {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
}

export interface deleteUserProfilePicture {
  deleteUserProfilePicture: deleteUserProfilePicture_deleteUserProfilePicture;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserTravelDocument
// ====================================================

export interface CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserTravelDocument_createUserTravelDocument {
  __typename: 'UserTravelDocument';
  id: string;
  documentSubType: string | null; // Travel Document Sub Type
  documentSubTypeMulti: UserTravelDocumentSubTypeType[] | null; // UserTravelDocumentSubTypeType
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: TravelDocumentType; // documentType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  countryOfIssue: string | null; // country issuing the document
  validForCountry: string | null; // Country where document is valid
  expiresOn: DateTime | null; // expiry date as per the document
  nationality: string | null; // nationality as on document
  documentFrontFromAzureCompressed: CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserTravelDocument_createUserTravelDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserTravelDocument_createUserTravelDocument_documentBackFromAzureOriginal | null;
  multipleEntriesAllowed: boolean | null; // are multiple entries possible using this document
}

export interface CreateUserTravelDocument {
  createUserTravelDocument: CreateUserTravelDocument_createUserTravelDocument;
}

export interface CreateUserTravelDocumentVariables {
  userTravelDocumentInput: UserTravelDocumentInput;
  mappedUserIdentityDocumentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserFrequentFlyerDocument
// ====================================================

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument {
  __typename: 'UserFrequentFlyerDocument';
  id: string;
  airlineType: AirlineType | null; // Frequent Flyer Airline
  airlineStatusType: AirlineStatusType | null; // Status of the airline
  airlineAllianceStatusType: AirlineAllianceStatusType | null; // Airline Alliance Status
  airlineAllianceType: AirlineAllianceType | null; // Airline Alliance
  documentNumber: string | null; // document number as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument_documentBackFromAzureOriginal | null;
}

export interface CreateUserFrequentFlyerDocument {
  createUserFrequentFlyerDocument: CreateUserFrequentFlyerDocument_createUserFrequentFlyerDocument;
}

export interface CreateUserFrequentFlyerDocumentVariables {
  userFrequentFlyerDocumentInput: UserFrequentFlyerDocumentInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserVaccinationDocument
// ====================================================

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserVaccinationDocument_createUserVaccinationDocument {
  __typename: 'UserVaccinationDocument';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that vaccination is for
  description: string | null; // Description of Vaccine
  vaccinationType: string | null; // Vaccination Document Type/Method (example SARS-CoV-mRNA for COVID19
  vaccinationManufacturer: string | null; // Vaccination Manufacturer (example PFIZER-BIOnTech for COVID19
  dateOfVaccination: DateTime | null; // Date of Vaccination as per the document
  isVaccinationComplete: boolean | null; // is vaccination complete
  vaccinationCenter: string | null; // Name of Vaccination Centre as per the document
  cityOfVaccination: string | null; // City of Vaccination as per the document
  countryOfVaccination: string | null; // country of vaccination
  expiresOn: DateTime | null; // expiry date as per the document
  documentNumber: string | null; // document number as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserVaccinationDocument_createUserVaccinationDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserVaccinationDocument_createUserVaccinationDocument_documentBackFromAzureOriginal | null;
}

export interface CreateUserVaccinationDocument {
  createUserVaccinationDocument: CreateUserVaccinationDocument_createUserVaccinationDocument;
}

export interface CreateUserVaccinationDocumentVariables {
  userVaccinationDocumentInput: UserVaccinationDocumentInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserHealthReport
// ====================================================

export interface CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserHealthReport_createUserHealthReport_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserHealthReport_createUserHealthReport_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserHealthReport_createUserHealthReport_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserHealthReport_createUserHealthReport_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserHealthReport_createUserHealthReport_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserHealthReport_createUserHealthReport_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserHealthReport_createUserHealthReport {
  __typename: 'UserHealthReport';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that test is for
  description: string | null; // Description of test
  testType: HealthTestType | null; // Test Document Type/Method PCR/ Rapid/ Antigen/ PCR-Rapid for COVID 19
  dateTimeOfTest: DateTime | null; // Date of Test as per the document
  isResultPositive: boolean | null; // is test complete
  testCenter: string | null; // Name of Test Centre as per the document
  cityOfTest: string | null; // City of Test as per the document
  countryOfTest: string | null; // country of test
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserHealthReport_createUserHealthReport_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserHealthReport_createUserHealthReport_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserHealthReport_createUserHealthReport_documentBackFromAzureOriginal | null;
}

export interface CreateUserHealthReport {
  createUserHealthReport: CreateUserHealthReport_createUserHealthReport;
}

export interface CreateUserHealthReportVariables {
  userHealthReportInput: UserHealthReportInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserBackgroundDocument
// ====================================================

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserBackgroundDocument_createUserBackgroundDocument {
  __typename: 'UserBackgroundDocument';
  id: string;
  name: string | null; // Name of the document
  issuingInstitution: string | null; // Name of the the institution issuing the document
  countryOfIssue: string | null; // country issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserBackgroundDocument_createUserBackgroundDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserBackgroundDocument_createUserBackgroundDocument_documentBackFromAzureOriginal | null;
}

export interface CreateUserBackgroundDocument {
  createUserBackgroundDocument: CreateUserBackgroundDocument_createUserBackgroundDocument;
}

export interface CreateUserBackgroundDocumentVariables {
  userBackgroundDocumentInput: UserBackgroundDocumentInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateUserOtherDocument
// ====================================================

export interface CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureCompressed_metadata;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureOriginal_metadata;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureCompressed_metadata;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureOriginal_metadata;
}

export interface CreateUserOtherDocument_createUserOtherDocument {
  __typename: 'UserOtherDocument';
  id: string;
  name: string | null; // Name of the document
  description: string | null; // Description of the document
  countryOfIssue: string | null; // country issuing the document
  placeOfIssue: string | null; // place/city issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: CreateUserOtherDocument_createUserOtherDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: CreateUserOtherDocument_createUserOtherDocument_documentBackFromAzureOriginal | null;
}

export interface CreateUserOtherDocument {
  createUserOtherDocument: CreateUserOtherDocument_createUserOtherDocument;
}

export interface CreateUserOtherDocumentVariables {
  userOtherDocumentInput: UserOtherDocumentInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRelationshipTypeForCourierJobAsCourierOrganization
// ====================================================

export interface UpdateRelationshipTypeForCourierJobAsCourierOrganization_updateRelationshipTypeForCourierJobAsCourierOrganization_agencyJob {
  __typename: 'AgencyJob';
  id: string;
}

export interface UpdateRelationshipTypeForCourierJobAsCourierOrganization_updateRelationshipTypeForCourierJobAsCourierOrganization {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  updatedAt: DateTime;
  agencyJob: UpdateRelationshipTypeForCourierJobAsCourierOrganization_updateRelationshipTypeForCourierJobAsCourierOrganization_agencyJob;
}

export interface UpdateRelationshipTypeForCourierJobAsCourierOrganization {
  updateRelationshipTypeForCourierJobAsCourierOrganization: UpdateRelationshipTypeForCourierJobAsCourierOrganization_updateRelationshipTypeForCourierJobAsCourierOrganization;
}

export interface UpdateRelationshipTypeForCourierJobAsCourierOrganizationVariables {
  id: string;
  relationshipType: CourierJobAgencyRelationshipType;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateRelationshipTypeForCourierJobAsAgencyOrganization
// ====================================================

export interface UpdateRelationshipTypeForCourierJobAsAgencyOrganization_updateRelationshipTypeForCourierJobAsAgencyOrganization_agencyJob {
  __typename: 'AgencyJob';
  id: string;
}

export interface UpdateRelationshipTypeForCourierJobAsAgencyOrganization_updateRelationshipTypeForCourierJobAsAgencyOrganization {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  updatedAt: DateTime;
  agencyJob: UpdateRelationshipTypeForCourierJobAsAgencyOrganization_updateRelationshipTypeForCourierJobAsAgencyOrganization_agencyJob;
}

export interface UpdateRelationshipTypeForCourierJobAsAgencyOrganization {
  updateRelationshipTypeForCourierJobAsAgencyOrganization: UpdateRelationshipTypeForCourierJobAsAgencyOrganization_updateRelationshipTypeForCourierJobAsAgencyOrganization;
}

export interface UpdateRelationshipTypeForCourierJobAsAgencyOrganizationVariables {
  id: string;
  agencyJobId: string;
  relationshipType: CourierJobAgencyRelationshipType;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddOrDisableCourierJob
// ====================================================

export interface AddOrDisableCourierJob_addOrDisableCourierJob_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  phone: string | null; // Phone Number
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_organizationUsers_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_organizationUsers_user {
  __typename: 'User';
  id: string;
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  organization: AddOrDisableCourierJob_addOrDisableCourierJob_organizationUsers_organization;
  user: AddOrDisableCourierJob_addOrDisableCourierJob_organizationUsers_user;
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_courierJob {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  isActive: boolean; // Whether the CourierJob is currently activated for the CourierOrganization
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance_availabilityLocation_availability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance_availabilityLocation_location {
  __typename: 'Location';
  id: string;
  formattedShortAddress: string | null;
  formattedMinimalAddress: string | null;
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance_availabilityLocation {
  __typename: 'AvailabilityLocation';
  id: string;
  timestamp: DateTime | null;
  availability: AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance_availabilityLocation_availability;
  location: AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance_availabilityLocation_location;
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance {
  __typename: 'AvailabilityLocationWithDistance';
  availabilityLocation: AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance_availabilityLocation;
  distance: number;
}

export interface AddOrDisableCourierJob_addOrDisableCourierJob {
  __typename: 'AvailableCourierOrganizationUsersWithDistance';
  user: AddOrDisableCourierJob_addOrDisableCourierJob_user;
  organizationUsers: AddOrDisableCourierJob_addOrDisableCourierJob_organizationUsers[];
  courierJob: AddOrDisableCourierJob_addOrDisableCourierJob_courierJob | null;
  availabilityLocationsWithDistance: AddOrDisableCourierJob_addOrDisableCourierJob_availabilityLocationsWithDistance[];
}

export interface AddOrDisableCourierJob {
  addOrDisableCourierJob: AddOrDisableCourierJob_addOrDisableCourierJob[];
}

export interface AddOrDisableCourierJobVariables {
  courierOrganizationUserIds: string[];
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddOrDisableCourierForCourierJob
// ====================================================

export interface AddOrDisableCourierForCourierJob_addOrDisableCourierForCourierJob {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  isActive: boolean; // Whether the CourierJob is currently activated for the CourierOrganization
}

export interface AddOrDisableCourierForCourierJob {
  addOrDisableCourierForCourierJob: AddOrDisableCourierForCourierJob_addOrDisableCourierForCourierJob[];
}

export interface AddOrDisableCourierForCourierJobVariables {
  courierOrganizationUserIds: string[];
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangeAgencyJobStatusTypeForAgencyOrganization
// ====================================================

export interface ChangeAgencyJobStatusTypeForAgencyOrganization_changeAgencyJobStatusTypeForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
}

export interface ChangeAgencyJobStatusTypeForAgencyOrganization {
  changeAgencyJobStatusTypeForAgencyOrganization: ChangeAgencyJobStatusTypeForAgencyOrganization_changeAgencyJobStatusTypeForAgencyOrganization;
}

export interface ChangeAgencyJobStatusTypeForAgencyOrganizationVariables {
  agencyJobId: string;
  agencyJobStatusType: AgencyJobStatusType;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrUpdateJobForAgencyOrganization
// ====================================================

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  numOfItems: number; // The number of items transported in the job
  weightOfItems: number | null; // The total weights of items transported in the job
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_job_pickupLocation;
  dropOffLocation: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_job_dropOffLocation;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_clientJob {
  __typename: 'ClientJob';
  id: string;
  organizationName: string; // Name of the Client Organization
  organizationLegalName: string | null; // Legal Name of the Client Organization.
  organizationPhone: string | null; // Primary phone number of the Client Organization.
  organizationEmail: string | null; // Primary email address of the Client Organization.
  externalJobReference: string | null; // External Job Reference from the Client
  comment: string | null; // Any comment about the Job from the Client
  fixedRate: number | null; // The fixed rate for the job as proposed by Client.
  currency: Currency; // Currency in which the Client pays the Agency. (EUR=0, USD=1)
  hasIncident: boolean; // Whether an incident has occurred on this Job for the Client
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_sasToken;
  metadata: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_metadata;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture | null;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  responsibleCourierOrganization: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierOrganization;
  responsibleCourierUser: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs_responsibleCourierUser;
}

export interface CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  internalNotes: string | null; // Internal agency job notes
  comment: string | null; // Any comment about the Job from the Agency
  fixedRate: number | null; // The fixed rate for the Job for the Courier as proposed and payable by Agency.
  dailyRate: number | null; // The daily rate for the Job for the Courier as proposed and payable by Agency.
  kickOffFee: number | null; // The kick off fee for the Job for the Courier as proposed and payable by Agency.
  hotelBudget: number | null; // The hotel budget for the Job for the Courier as proposed and payable by Agency.
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isAccommodationBookedByCourier: boolean; // Wether the accommodation booked by the Courier as proposed by Agency.
  isAccommodationPaidByCourier: boolean; // Whether the accommodation paid by the Courier as proposed by Agency.
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  startAirport: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_startAirport | null;
  endAirport: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_endAirport | null;
  job: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_job;
  clientJob: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_clientJob;
  courierJobs:
    | CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization_courierJobs[]
    | null;
}

export interface CreateOrUpdateJobForAgencyOrganization {
  createOrUpdateJobForAgencyOrganization: CreateOrUpdateJobForAgencyOrganization_createOrUpdateJobForAgencyOrganization;
}

export interface CreateOrUpdateJobForAgencyOrganizationVariables {
  agencyJobInput: AgencyJobInput;
  clientJobByAgencyInput: ClientJobByAgencyInput;
  jobInput: JobInput;
  courierOrganizationUserIds?: string[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateChatMessage
// ====================================================

export interface CreateChatMessage_createChatMessage_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CreateChatMessage_createChatMessage_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateChatMessage_createChatMessage_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CreateChatMessage_createChatMessage_node_chatFile_sasToken;
  metadata: CreateChatMessage_createChatMessage_node_chatFile_metadata;
}

export interface CreateChatMessage_createChatMessage_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CreateChatMessage_createChatMessage_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateChatMessage_createChatMessage_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CreateChatMessage_createChatMessage_node_sendingUser_profilePicture_sasToken;
  metadata: CreateChatMessage_createChatMessage_node_sendingUser_profilePicture_metadata;
}

export interface CreateChatMessage_createChatMessage_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: CreateChatMessage_createChatMessage_node_sendingUser_profilePicture | null;
}

export interface CreateChatMessage_createChatMessage_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  chatFile: CreateChatMessage_createChatMessage_node_chatFile | null;
  sendingUser: CreateChatMessage_createChatMessage_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface CreateChatMessage_createChatMessage {
  __typename: 'ChatMessageCreateEdge';
  node: CreateChatMessage_createChatMessage_node;
  cursor: string;
}

export interface CreateChatMessage {
  createChatMessage: CreateChatMessage_createChatMessage;
}

export interface CreateChatMessageVariables {
  createChatMessageInput: CreateChatMessageInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateChatMessage
// ====================================================

export interface UpdateChatMessage_updateChatMessage_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateChatMessage_updateChatMessage_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateChatMessage_updateChatMessage_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateChatMessage_updateChatMessage_node_chatFile_sasToken;
  metadata: UpdateChatMessage_updateChatMessage_node_chatFile_metadata;
}

export interface UpdateChatMessage_updateChatMessage_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateChatMessage_updateChatMessage_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateChatMessage_updateChatMessage_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateChatMessage_updateChatMessage_node_sendingUser_profilePicture_sasToken;
  metadata: UpdateChatMessage_updateChatMessage_node_sendingUser_profilePicture_metadata;
}

export interface UpdateChatMessage_updateChatMessage_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: UpdateChatMessage_updateChatMessage_node_sendingUser_profilePicture | null;
}

export interface UpdateChatMessage_updateChatMessage_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  chatFile: UpdateChatMessage_updateChatMessage_node_chatFile | null;
  sendingUser: UpdateChatMessage_updateChatMessage_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface UpdateChatMessage_updateChatMessage {
  __typename: 'ChatMessageCreateEdge';
  node: UpdateChatMessage_updateChatMessage_node;
  cursor: string;
}

export interface UpdateChatMessage {
  updateChatMessage: UpdateChatMessage_updateChatMessage;
}

export interface UpdateChatMessageVariables {
  updateChatMessageInput: UpdateChatMessageInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteChatMessage
// ====================================================

export interface DeleteChatMessage_deleteChatMessage {
  __typename: 'ChatMessage';
  id: string;
}

export interface DeleteChatMessage {
  deleteChatMessage: DeleteChatMessage_deleteChatMessage;
}

export interface DeleteChatMessageVariables {
  deleteChatMessageInput: DeleteChatMessageInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAgencyOrganizationProfile
// ====================================================

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_headquartersAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_headquartersAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_headquartersAddressLocation_locationGeometry_location;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_headquartersAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_headquartersAddressLocation_locationGeometry;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_invoicingAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_invoicingAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_invoicingAddressLocation_locationGeometry_location;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_invoicingAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_invoicingAddressLocation_locationGeometry;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_registrationAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_registrationAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_registrationAddressLocation_locationGeometry_location;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_registrationAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_registrationAddressLocation_locationGeometry;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_logo_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_logo_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_logo_sasToken;
  metadata: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_logo_metadata;
}

export interface UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  socialProfiles: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_socialProfiles[]; // Social profiles
  headquartersAddressLocation: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_headquartersAddressLocation | null;
  invoicingAddressLocation: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_invoicingAddressLocation | null;
  registrationAddressLocation: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_registrationAddressLocation | null;
  invoiceEmail: string | null; // Invoice email address of the Organization.
  logo: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile_logo | null;
}

export interface UpdateAgencyOrganizationProfile {
  updateAgencyOrganizationProfile: UpdateAgencyOrganizationProfile_updateAgencyOrganizationProfile;
}

export interface UpdateAgencyOrganizationProfileVariables {
  agencyOrganizationProfileInput: AgencyOrganizationProfileInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAgencyOrganizationVatId
// ====================================================

export interface UpdateAgencyOrganizationVatId_updateAgencyOrganizationVatId {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
}

export interface UpdateAgencyOrganizationVatId {
  updateAgencyOrganizationVatId: UpdateAgencyOrganizationVatId_updateAgencyOrganizationVatId;
}

export interface UpdateAgencyOrganizationVatIdVariables {
  agencyOrganizationVatIdInput: AgencyOrganizationVatIdInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteOrganizationLogo
// ====================================================

export interface deleteOrganizationLogo_deleteOrganizationLogo_logo_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface deleteOrganizationLogo_deleteOrganizationLogo_logo_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface deleteOrganizationLogo_deleteOrganizationLogo_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: deleteOrganizationLogo_deleteOrganizationLogo_logo_sasToken;
  metadata: deleteOrganizationLogo_deleteOrganizationLogo_logo_metadata;
}

export interface deleteOrganizationLogo_deleteOrganizationLogo {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  logo: deleteOrganizationLogo_deleteOrganizationLogo_logo | null;
}

export interface deleteOrganizationLogo {
  deleteOrganizationLogo: deleteOrganizationLogo_deleteOrganizationLogo;
}

export interface deleteOrganizationLogoVariables {
  organizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCourierOrganizationProfile
// ====================================================

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_headquartersAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_headquartersAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_headquartersAddressLocation_locationGeometry_location;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_headquartersAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_headquartersAddressLocation_locationGeometry;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_invoicingAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_invoicingAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_invoicingAddressLocation_locationGeometry_location;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_invoicingAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_invoicingAddressLocation_locationGeometry;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_registrationAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_registrationAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_registrationAddressLocation_locationGeometry_location;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_registrationAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_registrationAddressLocation_locationGeometry;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_logo_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_logo_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_logo_sasToken;
  metadata: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_logo_metadata;
}

export interface UpdateCourierOrganizationProfile_updateCourierOrganizationProfile {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  socialProfiles: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_socialProfiles[]; // Social profiles
  headquartersAddressLocation: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_headquartersAddressLocation | null;
  invoicingAddressLocation: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_invoicingAddressLocation | null;
  registrationAddressLocation: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_registrationAddressLocation | null;
  logo: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile_logo | null;
}

export interface UpdateCourierOrganizationProfile {
  updateCourierOrganizationProfile: UpdateCourierOrganizationProfile_updateCourierOrganizationProfile;
}

export interface UpdateCourierOrganizationProfileVariables {
  courierOrganizationProfileInput: CourierOrganizationProfileInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserBackgroundDocument
// ====================================================

export interface DeleteUserBackgroundDocument_deleteUserBackgroundDocument {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface DeleteUserBackgroundDocument {
  deleteUserBackgroundDocument: DeleteUserBackgroundDocument_deleteUserBackgroundDocument;
}

export interface DeleteUserBackgroundDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserFrequentFlyerDocument
// ====================================================

export interface DeleteUserFrequentFlyerDocument_deleteUserFrequentFlyerDocument {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface DeleteUserFrequentFlyerDocument {
  deleteUserFrequentFlyerDocument: DeleteUserFrequentFlyerDocument_deleteUserFrequentFlyerDocument;
}

export interface DeleteUserFrequentFlyerDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserHealthReport
// ====================================================

export interface DeleteUserHealthReport_deleteUserHealthReport {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface DeleteUserHealthReport {
  deleteUserHealthReport: DeleteUserHealthReport_deleteUserHealthReport;
}

export interface DeleteUserHealthReportVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserIdentityDocument
// ====================================================

export interface DeleteUserIdentityDocument_deleteUserIdentityDocument {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface DeleteUserIdentityDocument {
  deleteUserIdentityDocument: DeleteUserIdentityDocument_deleteUserIdentityDocument;
}

export interface DeleteUserIdentityDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserOtherDocumentDelete
// ====================================================

export interface UserOtherDocumentDelete_deleteUserOtherDocument {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface UserOtherDocumentDelete {
  deleteUserOtherDocument: UserOtherDocumentDelete_deleteUserOtherDocument;
}

export interface UserOtherDocumentDeleteVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserTravelDocument
// ====================================================

export interface DeleteUserTravelDocument_deleteUserTravelDocument {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface DeleteUserTravelDocument {
  deleteUserTravelDocument: DeleteUserTravelDocument_deleteUserTravelDocument;
}

export interface DeleteUserTravelDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUserVaccinationDocument
// ====================================================

export interface DeleteUserVaccinationDocument_deleteUserVaccinationDocument {
  __typename: 'EntityDeletionReturnType';
  id: string;
}

export interface DeleteUserVaccinationDocument {
  deleteUserVaccinationDocument: DeleteUserVaccinationDocument_deleteUserVaccinationDocument;
}

export interface DeleteUserVaccinationDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAgencyAccount
// ====================================================

export interface DeleteAgencyAccount {
  deleteAgencyAccount: boolean;
}

export interface DeleteAgencyAccountVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteCourierAccount
// ====================================================

export interface DeleteCourierAccount {
  deleteCourierAccount: boolean;
}

export interface DeleteCourierAccountVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserDeleteAccount
// ====================================================

export interface UserDeleteAccount {
  userDeleteAccount: boolean;
}

export interface UserDeleteAccountVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreAgencyAccount
// ====================================================

export interface RestoreAgencyAccount_restoreAgencyAccount {
  __typename: 'User';
  id: string;
}

export interface RestoreAgencyAccount {
  restoreAgencyAccount: RestoreAgencyAccount_restoreAgencyAccount;
}

export interface RestoreAgencyAccountVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RestoreCourierAccount
// ====================================================

export interface RestoreCourierAccount_restoreCourierAccount {
  __typename: 'User';
  id: string;
}

export interface RestoreCourierAccount {
  restoreCourierAccount: RestoreCourierAccount_restoreCourierAccount;
}

export interface RestoreCourierAccountVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserAccountRestore
// ====================================================

export interface UserAccountRestore_userAccountRestore {
  __typename: 'User';
  id: string;
}

export interface UserAccountRestore {
  userAccountRestore: UserAccountRestore_userAccountRestore;
}

export interface UserAccountRestoreVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: organizationUserUpdateProfile
// ====================================================

export interface organizationUserUpdateProfile_organizationUserUpdateProfile_user_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface organizationUserUpdateProfile_organizationUserUpdateProfile_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: organizationUserUpdateProfile_organizationUserUpdateProfile_user_profilePicture_sasToken;
}

export interface organizationUserUpdateProfile_organizationUserUpdateProfile_user {
  __typename: 'User';
  firstNames: string; // First names
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  email: string; // Email address
  phone: string | null; // Phone Number
  profilePicture: organizationUserUpdateProfile_organizationUserUpdateProfile_user_profilePicture | null;
}

export interface organizationUserUpdateProfile_organizationUserUpdateProfile_organization {
  __typename: 'Organization';
  id: string;
}

export interface organizationUserUpdateProfile_organizationUserUpdateProfile {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: organizationUserUpdateProfile_organizationUserUpdateProfile_user;
  organization: organizationUserUpdateProfile_organizationUserUpdateProfile_organization;
}

export interface organizationUserUpdateProfile {
  organizationUserUpdateProfile: organizationUserUpdateProfile_organizationUserUpdateProfile;
}

export interface organizationUserUpdateProfileVariables {
  organizationUserUpdateProfileInput: OrganizationUserUpdateProfileInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateOrUpdateAgencyEmployeeUserContact
// ====================================================

export interface CreateOrUpdateAgencyEmployeeUserContact_createOrUpdateAgencyEmployeeUserContact {
  __typename: 'UserContact';
  id: string;
  expoContactId: string | null; // The contact ID from the address book
  phone: string; // Phone Number
  email: string | null; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
}

export interface CreateOrUpdateAgencyEmployeeUserContact {
  createOrUpdateAgencyEmployeeUserContact: CreateOrUpdateAgencyEmployeeUserContact_createOrUpdateAgencyEmployeeUserContact;
}

export interface CreateOrUpdateAgencyEmployeeUserContactVariables {
  userContactInput: UserContactInput;
  inviteUserContact: boolean;
  organizationUserRole: OrganizationUserRoleType;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrganizationInvitationDelete
// ====================================================

export interface OrganizationInvitationDelete_organizationInvitationDelete {
  __typename: 'OrganizationInvitation';
  id: string;
}

export interface OrganizationInvitationDelete {
  organizationInvitationDelete: OrganizationInvitationDelete_organizationInvitationDelete;
}

export interface OrganizationInvitationDeleteVariables {
  organizationInvitationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrganizationUserRemoveOrganizationUserFromOrganization
// ====================================================

export interface OrganizationUserRemoveOrganizationUserFromOrganization {
  organizationUserRemoveOrganizationUserFromOrganization: boolean;
}

export interface OrganizationUserRemoveOrganizationUserFromOrganizationVariables {
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrganizationSubscriptionPlanCancel
// ====================================================

export interface OrganizationSubscriptionPlanCancel_organizationSubscriptionPlanCancel {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
}

export interface OrganizationSubscriptionPlanCancel {
  organizationSubscriptionPlanCancel: OrganizationSubscriptionPlanCancel_organizationSubscriptionPlanCancel;
}

export interface OrganizationSubscriptionPlanCancelVariables {
  data: OrganizationSubscriptionPlanSingleInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AirportLocationUpdateTimezone
// ====================================================

export interface AirportLocationUpdateTimezone_airportLocationUpdateTimezone {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  country: string | null;
  iataCode: string | null;
  formattedAddress: string | null; // The formatted address of the location
  city: string | null;
}

export interface AirportLocationUpdateTimezone {
  airportLocationUpdateTimezone: AirportLocationUpdateTimezone_airportLocationUpdateTimezone;
}

export interface AirportLocationUpdateTimezoneVariables {
  data: AirportLocationUpdateTimezoneInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: organizationCourierCategoriesUpdate
// ====================================================

export interface organizationCourierCategoriesUpdate_organizationCourierCategoriesUpdate {
  __typename: 'OrganizationCourierCategory';
  id: string;
  order: number; // Category order
  label: string; // Default label
}

export interface organizationCourierCategoriesUpdate {
  organizationCourierCategoriesUpdate: organizationCourierCategoriesUpdate_organizationCourierCategoriesUpdate[];
}

export interface organizationCourierCategoriesUpdateVariables {
  data: OrganizationCourierCategoriesUpdateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: agencyCourierUserProfileCreateOrUpdate
// ====================================================

export interface agencyCourierUserProfileCreateOrUpdate_agencyCourierUserProfileCreateOrUpdate {
  __typename: 'AgencyCourierUserProfile';
  id: string;
  internalComment: string | null; // Internal comment about Courier
  markedForAttention: boolean; // Internal comment about Courier
}

export interface agencyCourierUserProfileCreateOrUpdate {
  agencyCourierUserProfileCreateOrUpdate: agencyCourierUserProfileCreateOrUpdate_agencyCourierUserProfileCreateOrUpdate;
}

export interface agencyCourierUserProfileCreateOrUpdateVariables {
  data: AgencyCourierUserProfileCreateOrUpdateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: agencyJobUpdateInternalNotes
// ====================================================

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  numOfItems: number; // The number of items transported in the job
  weightOfItems: number | null; // The total weights of items transported in the job
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_job_pickupLocation;
  dropOffLocation: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_job_dropOffLocation;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_clientJob {
  __typename: 'ClientJob';
  id: string;
  organizationName: string; // Name of the Client Organization
  organizationLegalName: string | null; // Legal Name of the Client Organization.
  organizationPhone: string | null; // Primary phone number of the Client Organization.
  organizationEmail: string | null; // Primary email address of the Client Organization.
  externalJobReference: string | null; // External Job Reference from the Client
  comment: string | null; // Any comment about the Job from the Client
  fixedRate: number | null; // The fixed rate for the job as proposed by Client.
  currency: Currency; // Currency in which the Client pays the Agency. (EUR=0, USD=1)
  hasIncident: boolean; // Whether an incident has occurred on this Job for the Client
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser_profilePicture_sasToken;
  metadata: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser_profilePicture_metadata;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser_profilePicture | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_courierJobAgencyJobChat {
  __typename: 'Chat';
  id: string;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  responsibleCourierOrganization: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierOrganization;
  responsibleCourierUser: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_responsibleCourierUser;
  courierJobAgencyJobChat: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs_courierJobAgencyJobChat | null;
}

export interface agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  comment: string | null; // Any comment about the Job from the Agency
  fixedRate: number | null; // The fixed rate for the Job for the Courier as proposed and payable by Agency.
  dailyRate: number | null; // The daily rate for the Job for the Courier as proposed and payable by Agency.
  kickOffFee: number | null; // The kick off fee for the Job for the Courier as proposed and payable by Agency.
  hotelBudget: number | null; // The hotel budget for the Job for the Courier as proposed and payable by Agency.
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isAccommodationBookedByCourier: boolean; // Wether the accommodation booked by the Courier as proposed by Agency.
  isAccommodationPaidByCourier: boolean; // Whether the accommodation paid by the Courier as proposed by Agency.
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  internalNotes: string | null; // Internal agency job notes
  startAirport: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_startAirport | null;
  endAirport: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_endAirport | null;
  job: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_job;
  clientJob: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_clientJob;
  courierJobs: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes_courierJobs[] | null;
}

export interface agencyJobUpdateInternalNotes {
  agencyJobUpdateInternalNotes: agencyJobUpdateInternalNotes_agencyJobUpdateInternalNotes;
}

export interface agencyJobUpdateInternalNotesVariables {
  agencyJobId: string;
  internalNotes: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: notificationSettingUpdate
// ====================================================

export interface notificationSettingUpdate_notificationSettingUpdate {
  __typename: 'NotificationSetting';
  id: string;
  emailConnection: boolean;
  emailJob: boolean;
  pushAvailability: boolean;
  pushChat: boolean;
  pushConnection: boolean;
  pushJob: boolean;
  systemAvailability: boolean;
  systemChat: boolean;
  systemConnection: boolean;
  systemJob: boolean;
}

export interface notificationSettingUpdate {
  notificationSettingUpdate: notificationSettingUpdate_notificationSettingUpdate;
}

export interface notificationSettingUpdateVariables {
  data: NotificationSettingUpdateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: authSignOutUserContext
// ====================================================

export interface authSignOutUserContext {
  authSignOutUserContext: boolean;
}

export interface authSignOutUserContextVariables {
  data: AuthSignOutUserContextInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AgencyJobPlanningForAgencyJobUpdate
// ====================================================

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_files_storedAsset_metadata;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_files_storedAsset | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems {
  __typename: 'AgencyJobPlanningItem';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  actualDateTimeSetAt: DateTime | null;
  createdAt: DateTime;
  files: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_files[];
  formType: AgencyJobPlanningFormType;
  inactivatedAt: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  location: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems_location | null;
  name: string; // Name of the Organization
  order: number;
  targetDateTime: DateTime | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_flightTickets_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_flightTickets_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_flightTickets_storedAsset_metadata;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_flightTickets {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_flightTickets_storedAsset | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset_metadata;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems {
  __typename: 'AgencyJobPlanningItem';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  actualDateTimeSetAt: DateTime | null;
  createdAt: DateTime;
  files: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_files[];
  formType: AgencyJobPlanningFormType;
  inactivatedAt: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  location: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems_location | null;
  name: string; // Name of the Organization
  order: number;
  targetDateTime: DateTime | null;
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs {
  __typename: 'AgencyJobPlanningLeg';
  id: string;
  active: boolean;
  departureDate: DateTime | null;
  order: number;
  flightCarrierDesignation: string | null;
  flightNumber: string | null;
  flightTickets: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_flightTickets[];
  agencyJobPlanningItems: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs_agencyJobPlanningItems[];
}

export interface AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate {
  __typename: 'AgencyJobPlanning';
  id: string;
  agencyJobPlanningItems: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningItems[];
  agencyJobPlanningLegs: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate_agencyJobPlanningLegs[];
}

export interface AgencyJobPlanningForAgencyJobUpdate {
  agencyJobPlanningForAgencyJobUpdate: AgencyJobPlanningForAgencyJobUpdate_agencyJobPlanningForAgencyJobUpdate;
}

export interface AgencyJobPlanningForAgencyJobUpdateVariables {
  agencyJobId: string;
  agencyJobPlanningId: string;
  agencyJobPlanningForAgencyJobUpdateInput: AgencyJobPlanningForAgencyJobUpdateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: agencyJobPlanningItemUpdate
// ====================================================

export interface agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_files_storedAsset_metadata;
}

export interface agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_files_storedAsset | null;
}

export interface agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate {
  __typename: 'AgencyJobPlanningItem';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  actualDateTimeSetAt: DateTime | null;
  createdAt: DateTime;
  files: agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_files[];
  formType: AgencyJobPlanningFormType;
  inactivatedAt: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  location: agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate_location | null;
  name: string; // Name of the Organization
  order: number;
  targetDateTime: DateTime | null;
  updatedAt: DateTime;
}

export interface agencyJobPlanningItemUpdate {
  agencyJobPlanningItemUpdate: agencyJobPlanningItemUpdate_agencyJobPlanningItemUpdate;
}

export interface agencyJobPlanningItemUpdateVariables {
  agencyJobPlanningId: string;
  agencyJobPlanningItemUpdateInput: AgencyJobPlanningItemUpdateInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: agencyJobPlanningItemReset
// ====================================================

export interface agencyJobPlanningItemReset_agencyJobPlanningItemReset_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface agencyJobPlanningItemReset_agencyJobPlanningItemReset_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: agencyJobPlanningItemReset_agencyJobPlanningItemReset_files_storedAsset_metadata;
}

export interface agencyJobPlanningItemReset_agencyJobPlanningItemReset_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: agencyJobPlanningItemReset_agencyJobPlanningItemReset_files_storedAsset | null;
}

export interface agencyJobPlanningItemReset_agencyJobPlanningItemReset_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface agencyJobPlanningItemReset_agencyJobPlanningItemReset {
  __typename: 'AgencyJobPlanningItem';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  actualDateTimeSetAt: DateTime | null;
  createdAt: DateTime;
  files: agencyJobPlanningItemReset_agencyJobPlanningItemReset_files[];
  formType: AgencyJobPlanningFormType;
  inactivatedAt: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  location: agencyJobPlanningItemReset_agencyJobPlanningItemReset_location | null;
  name: string; // Name of the Organization
  order: number;
  targetDateTime: DateTime | null;
  updatedAt: DateTime;
}

export interface agencyJobPlanningItemReset {
  agencyJobPlanningItemReset: agencyJobPlanningItemReset_agencyJobPlanningItemReset;
}

export interface agencyJobPlanningItemResetVariables {
  agencyJobPlanningId: string;
  agencyJobPlanningItemId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateChatMessageForAgency
// ====================================================

export interface CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg {
  __typename: 'AgencyJobPlanningLeg';
  id: string;
  flightCarrierDesignation: string | null;
  flightNumber: string | null;
  order: number;
}

export interface CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  metadata: CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata;
}

export interface CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog {
  __typename: 'AgencyJobPlanningItemHistoryLog';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  targetDateTime: DateTime | null;
  note: string | null;
  agencyJobPlanningLeg: CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg | null;
  files: CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_files[];
  location: CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog_location | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CreateChatMessageForAgency_createChatMessage_node_chatFile_sasToken;
  metadata: CreateChatMessageForAgency_createChatMessage_node_chatFile_metadata;
}

export interface CreateChatMessageForAgency_createChatMessage_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CreateChatMessageForAgency_createChatMessage_node_sendingUser_profilePicture_sasToken;
  metadata: CreateChatMessageForAgency_createChatMessage_node_sendingUser_profilePicture_metadata;
}

export interface CreateChatMessageForAgency_createChatMessage_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: CreateChatMessageForAgency_createChatMessage_node_sendingUser_profilePicture | null;
}

export interface CreateChatMessageForAgency_createChatMessage_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  agencyJobPlanningItemHistoryLog: CreateChatMessageForAgency_createChatMessage_node_agencyJobPlanningItemHistoryLog | null;
  chatFile: CreateChatMessageForAgency_createChatMessage_node_chatFile | null;
  sendingUser: CreateChatMessageForAgency_createChatMessage_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface CreateChatMessageForAgency_createChatMessage {
  __typename: 'ChatMessageCreateEdge';
  node: CreateChatMessageForAgency_createChatMessage_node;
  cursor: string;
}

export interface CreateChatMessageForAgency {
  createChatMessage: CreateChatMessageForAgency_createChatMessage;
}

export interface CreateChatMessageForAgencyVariables {
  createChatMessageInput: CreateChatMessageInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateChatMessageForAgency
// ====================================================

export interface UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg {
  __typename: 'AgencyJobPlanningLeg';
  id: string;
  flightCarrierDesignation: string | null;
  flightNumber: string | null;
  order: number;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  metadata: UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_files_storedAsset | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog {
  __typename: 'AgencyJobPlanningItemHistoryLog';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  targetDateTime: DateTime | null;
  note: string | null;
  agencyJobPlanningLeg: UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg | null;
  files: UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_files[];
  location: UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog_location | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateChatMessageForAgency_updateChatMessage_node_chatFile_sasToken;
  metadata: UpdateChatMessageForAgency_updateChatMessage_node_chatFile_metadata;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UpdateChatMessageForAgency_updateChatMessage_node_sendingUser_profilePicture_sasToken;
  metadata: UpdateChatMessageForAgency_updateChatMessage_node_sendingUser_profilePicture_metadata;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: UpdateChatMessageForAgency_updateChatMessage_node_sendingUser_profilePicture | null;
}

export interface UpdateChatMessageForAgency_updateChatMessage_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  agencyJobPlanningItemHistoryLog: UpdateChatMessageForAgency_updateChatMessage_node_agencyJobPlanningItemHistoryLog | null;
  chatFile: UpdateChatMessageForAgency_updateChatMessage_node_chatFile | null;
  sendingUser: UpdateChatMessageForAgency_updateChatMessage_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface UpdateChatMessageForAgency_updateChatMessage {
  __typename: 'ChatMessageCreateEdge';
  node: UpdateChatMessageForAgency_updateChatMessage_node;
  cursor: string;
}

export interface UpdateChatMessageForAgency {
  updateChatMessage: UpdateChatMessageForAgency_updateChatMessage;
}

export interface UpdateChatMessageForAgencyVariables {
  updateChatMessageInput: UpdateChatMessageInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConnectedAgencyOrganizationForCourierOrganization
// ====================================================

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_headquartersAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_headquartersAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_headquartersAddressLocation_locationGeometry_location;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_headquartersAddressLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_headquartersAddressLocation_locationGeometry;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_invoicingAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_invoicingAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_invoicingAddressLocation_locationGeometry_location;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_invoicingAddressLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  locationGeometry: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_invoicingAddressLocation_locationGeometry;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_registrationAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_registrationAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_registrationAddressLocation_locationGeometry_location;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_registrationAddressLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  locationGeometry: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_registrationAddressLocation_locationGeometry;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_logo_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_logo_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_logo_sasToken;
  metadata: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_logo_metadata;
}

export interface ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  phone: string | null; // Primary phone number of the Organization.
  email: string | null; // Primary email address of the Organization.
  description: string | null; // Description and intro text of the Organization.
  headquartersAddressLocation: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_headquartersAddressLocation | null;
  invoicingAddressLocation: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_invoicingAddressLocation | null;
  invoiceEmail: string | null; // Invoice email address of the Organization.
  registrationAddressLocation: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_registrationAddressLocation | null;
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  socialProfiles: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_socialProfiles[]; // Social profiles
  logo: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization_logo | null;
}

export interface ConnectedAgencyOrganizationForCourierOrganization {
  connectedAgencyOrganizationForCourierOrganization: ConnectedAgencyOrganizationForCourierOrganization_connectedAgencyOrganizationForCourierOrganization;
}

export interface ConnectedAgencyOrganizationForCourierOrganizationVariables {
  agencyOrganizationId: string;
  courierOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConnectedCourierOrganizationsForAgencyOrganization
// ====================================================

export interface ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_usedOrganizationInvitation_usedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
  usedByUser: ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_usedOrganizationInvitation_usedByUser | null;
}

export interface ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_organization_organizationUserOwners_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  phone: string | null; // Phone Number
}

export interface ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_organization_organizationUserOwners {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_organization_organizationUserOwners_user;
}

export interface ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  phone: string | null; // Primary phone number of the Organization.
  email: string | null; // Primary email address of the Organization.
  organizationUserOwners: ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_organization_organizationUserOwners[];
}

export interface ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization {
  __typename: 'ConnectedOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  createdAt: DateTime;
  updatedAt: DateTime;
  usedOrganizationInvitation: ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_usedOrganizationInvitation;
  organization: ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization_organization;
}

export interface ConnectedCourierOrganizationsForAgencyOrganization {
  connectedCourierOrganizationsForAgencyOrganization: ConnectedCourierOrganizationsForAgencyOrganization_connectedCourierOrganizationsForAgencyOrganization[];
}

export interface ConnectedCourierOrganizationsForAgencyOrganizationVariables {
  agencyOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConnectedAgencyOrganizationsForCourierOrganization
// ====================================================

export interface ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_usedOrganizationInvitation_usedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
  usedByUser: ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_usedOrganizationInvitation_usedByUser | null;
}

export interface ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_organization_organizationUserOwners_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  phone: string | null; // Phone Number
}

export interface ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_organization_organizationUserOwners {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_organization_organizationUserOwners_user;
}

export interface ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  phone: string | null; // Primary phone number of the Organization.
  email: string | null; // Primary email address of the Organization.
  organizationUserOwners: ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_organization_organizationUserOwners[];
}

export interface ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization {
  __typename: 'ConnectedOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  createdAt: DateTime;
  updatedAt: DateTime;
  usedOrganizationInvitation: ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_usedOrganizationInvitation;
  organization: ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization_organization;
}

export interface ConnectedAgencyOrganizationsForCourierOrganization {
  connectedAgencyOrganizationsForCourierOrganization: ConnectedAgencyOrganizationsForCourierOrganization_connectedAgencyOrganizationsForCourierOrganization[];
}

export interface ConnectedAgencyOrganizationsForCourierOrganizationVariables {
  courierOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Organizations
// ====================================================

export interface Organizations_organizations_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
  organizationInvitationType: OrganizationInvitationType; // The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. (ACTIVATE_NEW_OWN_ORGANIZATION=0, ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION=1, CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION=2, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE=3, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION=4, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION=5, OTHER_ORGANIZATION_INVITATION=6, DEPRECATED_OLD_ORGANIZATION_INVITATION=7)
}

export interface Organizations_organizations_organizationUsers_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  createdAt: DateTime;
}

export interface Organizations_organizations_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  createdAt: DateTime;
  user: Organizations_organizations_organizationUsers_user;
}

export interface Organizations_organizations {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  usedOrganizationInvitation: Organizations_organizations_usedOrganizationInvitation | null;
  organizationUsers: Organizations_organizations_organizationUsers[];
}

export interface Organizations {
  organizations: Organizations_organizations[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationInvitationByActivationCodeForCourierOrganization
// ====================================================

export interface OrganizationInvitationByActivationCodeForCourierOrganization_organizationInvitationByActivationCodeForCourierOrganization_invitingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  phone: string | null; // Primary phone number of the Organization.
  email: string | null; // Primary email address of the Organization.
}

export interface OrganizationInvitationByActivationCodeForCourierOrganization_organizationInvitationByActivationCodeForCourierOrganization {
  __typename: 'OrganizationInvitation';
  id: string;
  activationCode: string | null;
  organizationInvitationType: OrganizationInvitationType; // The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. (ACTIVATE_NEW_OWN_ORGANIZATION=0, ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION=1, CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION=2, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE=3, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION=4, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION=5, OTHER_ORGANIZATION_INVITATION=6, DEPRECATED_OLD_ORGANIZATION_INVITATION=7)
  invitingOrganization: OrganizationInvitationByActivationCodeForCourierOrganization_organizationInvitationByActivationCodeForCourierOrganization_invitingOrganization | null;
}

export interface OrganizationInvitationByActivationCodeForCourierOrganization {
  organizationInvitationByActivationCodeForCourierOrganization: OrganizationInvitationByActivationCodeForCourierOrganization_organizationInvitationByActivationCodeForCourierOrganization;
}

export interface OrganizationInvitationByActivationCodeForCourierOrganizationVariables {
  organizationId: string;
  activationCode: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableCourierUsersByDistanceForAgencyJob
// ====================================================

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_user_agencyCourierUserProfile_organizationCourierCategories {
  __typename: 'OrganizationCourierCategory';
  id: string;
  order: number; // Category order
  label: string; // Default label
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_user_agencyCourierUserProfile {
  __typename: 'AgencyCourierUserProfile';
  internalComment: string | null; // Internal comment about Courier
  markedForAttention: boolean; // Internal comment about Courier
  organizationCourierCategories:
    | AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_user_agencyCourierUserProfile_organizationCourierCategories[]
    | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  phone: string | null; // Phone Number
  agencyCourierUserProfile: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_user_agencyCourierUserProfile | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_organizationUsers_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_organizationUsers_user {
  __typename: 'User';
  id: string;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  organization: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_organizationUsers_organization;
  user: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_organizationUsers_user;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_courierJob {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  isActive: boolean; // Whether the CourierJob is currently activated for the CourierOrganization
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance_availabilityLocation_availability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance_availabilityLocation_location {
  __typename: 'Location';
  id: string;
  formattedShortAddress: string | null;
  formattedMinimalAddress: string | null;
  formattedAddress: string | null; // The formatted address of the location
  timeZoneIdentifier: string | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance_availabilityLocation {
  __typename: 'AvailabilityLocation';
  id: string;
  timestamp: DateTime | null;
  availability: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance_availabilityLocation_availability;
  location: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance_availabilityLocation_location;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance {
  __typename: 'AvailabilityLocationWithDistance';
  availabilityLocation: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance_availabilityLocation;
  distance: number;
}

export interface AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob {
  __typename: 'AvailableCourierOrganizationUsersWithDistance';
  user: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_user;
  organizationUsers: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_organizationUsers[];
  courierJob: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_courierJob | null;
  availabilityLocationsWithDistance: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob_availabilityLocationsWithDistance[];
}

export interface AvailableCourierUsersByDistanceForAgencyJob {
  availableCourierUsersByDistanceForAgencyJob: AvailableCourierUsersByDistanceForAgencyJob_availableCourierUsersByDistanceForAgencyJob[];
}

export interface AvailableCourierUsersByDistanceForAgencyJobVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllActiveAndUpcomingAvailabilitiesForPriojet
// ====================================================

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastLocation_locationGeometry_location;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastLocation_locationGeometry;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_user_courierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_user {
  __typename: 'User';
  id: string;
  email: string; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
  courierUserProfile: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_user_courierUserProfile | null;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser_location_locationGeometry_location;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser_location_locationGeometry;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser {
  __typename: 'DeviceLocation';
  id: string;
  timestamp: DateTime;
  location: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser_location | null;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  createdAt: DateTime;
  lastLocation: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastLocation | null;
  user: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_user;
  lastDeviceLocationWithLocationForUser: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet_lastDeviceLocationWithLocationForUser | null;
}

export interface AllActiveAndUpcomingAvailabilitiesForPriojet {
  allActiveAndUpcomingAvailabilitiesForPriojet: AllActiveAndUpcomingAvailabilitiesForPriojet_allActiveAndUpcomingAvailabilitiesForPriojet[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations
// ====================================================

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry_location;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user_profilePicture_metadata;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user_courierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user {
  __typename: 'User';
  id: string;
  email: string; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user_profilePicture | null;
  courierUserProfile: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user_courierUserProfile | null;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser_location_locationGeometry_location;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  timeZoneIdentifier: string | null;
  locationGeometry: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser_location_locationGeometry;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser {
  __typename: 'DeviceLocation';
  id: string;
  timestamp: DateTime;
  location: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser_location | null;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  createdAt: DateTime;
  lastLocation: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastLocation | null;
  user: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_user;
  lastDeviceLocationWithLocationForUser: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations_lastDeviceLocationWithLocationForUser | null;
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations {
  activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations_activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations[];
}

export interface ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsVariables {
  agencyOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailabilitiesForUser
// ====================================================

export interface AvailabilitiesForUser_availabilitiesForUser_lastLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AvailabilitiesForUser_availabilitiesForUser_lastLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: AvailabilitiesForUser_availabilitiesForUser_lastLocation_locationGeometry_location;
}

export interface AvailabilitiesForUser_availabilitiesForUser_lastLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: AvailabilitiesForUser_availabilitiesForUser_lastLocation_locationGeometry;
}

export interface AvailabilitiesForUser_availabilitiesForUser_user_courierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface AvailabilitiesForUser_availabilitiesForUser_user {
  __typename: 'User';
  id: string;
  email: string; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
  courierUserProfile: AvailabilitiesForUser_availabilitiesForUser_user_courierUserProfile | null;
}

export interface AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry_location;
}

export interface AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry;
}

export interface AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser {
  __typename: 'DeviceLocation';
  id: string;
  timestamp: DateTime;
  location: AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser_location | null;
}

export interface AvailabilitiesForUser_availabilitiesForUser {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  createdAt: DateTime;
  lastLocation: AvailabilitiesForUser_availabilitiesForUser_lastLocation | null;
  user: AvailabilitiesForUser_availabilitiesForUser_user;
  lastDeviceLocationWithLocationForUser: AvailabilitiesForUser_availabilitiesForUser_lastDeviceLocationWithLocationForUser | null;
}

export interface AvailabilitiesForUser {
  availabilitiesForUser: AvailabilitiesForUser_availabilitiesForUser[];
}

export interface AvailabilitiesForUserVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActiveAvailabilitiesForUser
// ====================================================

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastLocation_locationGeometry_location;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastLocation_locationGeometry;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_user {
  __typename: 'User';
  id: string;
  email: string; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry {
  __typename: 'LocationGeometry';
  location: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry_location;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  locationGeometry: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser_location_locationGeometry;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser {
  __typename: 'DeviceLocation';
  id: string;
  timestamp: DateTime;
  location: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser_location | null;
}

export interface ActiveAvailabilitiesForUser_activeAvailabilitiesForUser {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  createdAt: DateTime;
  lastLocation: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastLocation | null;
  user: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_user;
  lastDeviceLocationWithLocationForUser: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser_lastDeviceLocationWithLocationForUser | null;
}

export interface ActiveAvailabilitiesForUser {
  activeAvailabilitiesForUser: ActiveAvailabilitiesForUser_activeAvailabilitiesForUser[];
}

export interface ActiveAvailabilitiesForUserVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserIdentityDocuments
// ====================================================

export interface UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureCompressed_metadata;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureOriginal_metadata;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureCompressed_metadata;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureOriginal_metadata;
}

export interface UserIdentityDocuments_userIdentityDocuments {
  __typename: 'UserIdentityDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  countryOfBirth: string | null; // Country of birth
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: IdentityDocumentType; // IdentityDocumentType
  description: string | null; // Description (for  licenses classes initially as text box in front end)
  documentFeature: DocumentFeature | null; // DocumentFeature
  documentSubType: IdentityDocumentSubType | null; // IdentityDocumentSubType
  documentSubTypeMulti: IdentityDocumentSubType[] | null; // IdentityDocumentSubType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  placeOfIssue: string | null; // Place of issue of the document
  countryOfIssue: string | null; // country issuing the document
  expiresOn: DateTime | null; // expiry date as per the document
  isInternationalDrivingPermit: boolean;
  expiresOnInternational: DateTime | null; // expiry date as per the document - for international driving permit
  nationality: string | null; // Nationality as per the document ISO 3166-1 alpha-3 code
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserIdentityDocuments_userIdentityDocuments_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserIdentityDocuments_userIdentityDocuments_documentBackFromAzureOriginal | null;
}

export interface UserIdentityDocuments {
  userIdentityDocuments: UserIdentityDocuments_userIdentityDocuments[];
}

export interface UserIdentityDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserIdentityDocument
// ====================================================

export interface UserIdentityDocument_userIdentityDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocument_userIdentityDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocument_userIdentityDocument_documentFrontFromAzureCompressed_metadata;
}

export interface UserIdentityDocument_userIdentityDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocument_userIdentityDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocument_userIdentityDocument_documentFrontFromAzureOriginal_metadata;
}

export interface UserIdentityDocument_userIdentityDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocument_userIdentityDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocument_userIdentityDocument_documentBackFromAzureCompressed_metadata;
}

export interface UserIdentityDocument_userIdentityDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserIdentityDocument_userIdentityDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserIdentityDocument_userIdentityDocument_documentBackFromAzureOriginal_metadata;
}

export interface UserIdentityDocument_userIdentityDocument {
  __typename: 'UserIdentityDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  countryOfBirth: string | null; // Country of birth
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: IdentityDocumentType; // IdentityDocumentType
  documentFeature: DocumentFeature | null; // DocumentFeature
  description: string | null; // Description (for  licenses classes initially as text box in front end)
  documentSubType: IdentityDocumentSubType | null; // IdentityDocumentSubType
  documentSubTypeMulti: IdentityDocumentSubType[] | null; // IdentityDocumentSubType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  placeOfIssue: string | null; // Place of issue of the document
  countryOfIssue: string | null; // country issuing the document
  expiresOn: DateTime | null; // expiry date as per the document
  isInternationalDrivingPermit: boolean;
  expiresOnInternational: DateTime | null; // expiry date as per the document - for international driving permit
  nationality: string | null; // Nationality as per the document ISO 3166-1 alpha-3 code
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserIdentityDocument_userIdentityDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserIdentityDocument_userIdentityDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserIdentityDocument_userIdentityDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserIdentityDocument_userIdentityDocument_documentBackFromAzureOriginal | null;
}

export interface UserIdentityDocument {
  userIdentityDocument: UserIdentityDocument_userIdentityDocument;
}

export interface UserIdentityDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTravelDocuments
// ====================================================

export interface UserTravelDocuments_userTravelDocuments_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocuments_userTravelDocuments_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocuments_userTravelDocuments_documentFrontFromAzureCompressed_metadata;
}

export interface UserTravelDocuments_userTravelDocuments_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocuments_userTravelDocuments_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocuments_userTravelDocuments_documentFrontFromAzureOriginal_metadata;
}

export interface UserTravelDocuments_userTravelDocuments_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocuments_userTravelDocuments_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocuments_userTravelDocuments_documentBackFromAzureCompressed_metadata;
}

export interface UserTravelDocuments_userTravelDocuments_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocuments_userTravelDocuments_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocuments_userTravelDocuments_documentBackFromAzureOriginal_metadata;
}

export interface UserTravelDocuments_userTravelDocuments_mappedIdentityDocument {
  __typename: 'UserIdentityDocument';
  id: string;
  nationality: string | null; // Nationality as per the document ISO 3166-1 alpha-3 code
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  countryOfBirth: string | null; // Country of birth
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentNumber: string | null; // document number as per the document
}

export interface UserTravelDocuments_userTravelDocuments {
  __typename: 'UserTravelDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: TravelDocumentType; // documentType
  documentSubType: string | null; // Travel Document Sub Type
  description: string | null; // Description (for visa type initially as text box in front end)
  documentSubTypeMulti: UserTravelDocumentSubTypeType[] | null; // UserTravelDocumentSubTypeType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  placeOfIssue: string | null; // Place of issue of the document
  countryOfIssue: string | null; // country issuing the document
  validForCountry: string | null; // Country where document is valid
  expiresOn: DateTime | null; // expiry date as per the document
  nationality: string | null; // nationality as on document
  countryOfBirth: string | null; // country of birth
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserTravelDocuments_userTravelDocuments_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserTravelDocuments_userTravelDocuments_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserTravelDocuments_userTravelDocuments_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserTravelDocuments_userTravelDocuments_documentBackFromAzureOriginal | null;
  multipleEntriesAllowed: boolean | null; // are multiple entries possible using this document
  mappedIdentityDocument: UserTravelDocuments_userTravelDocuments_mappedIdentityDocument | null;
}

export interface UserTravelDocuments {
  userTravelDocuments: UserTravelDocuments_userTravelDocuments[];
}

export interface UserTravelDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTravelDocument
// ====================================================

export interface UserTravelDocument_userTravelDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocument_userTravelDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocument_userTravelDocument_documentFrontFromAzureCompressed_metadata;
}

export interface UserTravelDocument_userTravelDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocument_userTravelDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocument_userTravelDocument_documentFrontFromAzureOriginal_metadata;
}

export interface UserTravelDocument_userTravelDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocument_userTravelDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocument_userTravelDocument_documentBackFromAzureCompressed_metadata;
}

export interface UserTravelDocument_userTravelDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserTravelDocument_userTravelDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserTravelDocument_userTravelDocument_documentBackFromAzureOriginal_metadata;
}

export interface UserTravelDocument_userTravelDocument_mappedIdentityDocument {
  __typename: 'UserIdentityDocument';
  id: string;
  documentNumber: string | null; // document number as per the document
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  countryOfBirth: string | null; // Country of birth
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  nationality: string | null; // Nationality as per the document ISO 3166-1 alpha-3 code
}

export interface UserTravelDocument_userTravelDocument {
  __typename: 'UserTravelDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  description: string | null; // Description (for visa type initially as text box in front end)
  documentType: TravelDocumentType; // documentType
  documentSubType: string | null; // Travel Document Sub Type
  documentSubTypeMulti: UserTravelDocumentSubTypeType[] | null; // UserTravelDocumentSubTypeType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  placeOfIssue: string | null; // Place of issue of the document
  countryOfIssue: string | null; // country issuing the document
  validForCountry: string | null; // Country where document is valid
  expiresOn: DateTime | null; // expiry date as per the document
  nationality: string | null; // nationality as on document
  countryOfBirth: string | null; // country of birth
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserTravelDocument_userTravelDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserTravelDocument_userTravelDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserTravelDocument_userTravelDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserTravelDocument_userTravelDocument_documentBackFromAzureOriginal | null;
  mappedIdentityDocument: UserTravelDocument_userTravelDocument_mappedIdentityDocument | null;
  multipleEntriesAllowed: boolean | null; // are multiple entries possible using this document
}

export interface UserTravelDocument {
  userTravelDocument: UserTravelDocument_userTravelDocument;
}

export interface UserTravelDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NumberOfNewNotificationsForOrganizationUser
// ====================================================

export interface NumberOfNewNotificationsForOrganizationUser {
  numberOfNewNotificationsForOrganizationUser: number;
}

export interface NumberOfNewNotificationsForOrganizationUserVariables {
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LatestOrNewSystemNotificationsForOrganizationUser
// ====================================================

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_job {
  __typename: 'Job';
  id: string;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_agencyJob_courierJobs_responsibleCourierUser {
  __typename: 'User';
  id: string;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_agencyJob_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  responsibleCourierUser: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_agencyJob_courierJobs_responsibleCourierUser;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  courierJobs:
    | LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_agencyJob_courierJobs[]
    | null;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_courierJob_agencyJob {
  __typename: 'AgencyJob';
  id: string;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_courierJob {
  __typename: 'CourierJob';
  id: string;
  agencyJob: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_courierJob_agencyJob;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_chatMessage_chat_agencyJob {
  __typename: 'AgencyJob';
  id: string;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_chatMessage_chat {
  __typename: 'Chat';
  id: string;
  agencyJob: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_chatMessage_chat_agencyJob | null; // A singular AgencyJob to AgencyJobChat
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_chatMessage {
  __typename: 'ChatMessage';
  id: string;
  chat: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_chatMessage_chat; // The chat reference
}

export interface LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser {
  __typename: 'Notification';
  id: string;
  notificationType: NotificationType; // NotificationType (see documentation)
  notificationMeansType: NotificationMeansType; // The means type of the Notification, like how it was sent (EXPO_PUSH, EMAIL, SMS,...)
  organizationContextType: OrganizationType | null; // The context in which this Organization was sent to the device. (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  language: NotificationLanguage; // The language of the Notification (EN, DE, FR, EN, ...)
  batchId: string;
  job: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_job | null;
  agencyJob: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_agencyJob | null;
  courierJob: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_courierJob | null;
  chatMessage: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser_chatMessage | null;
  title: string | null; // Title of the Notification
  content: string | null; // The content of the Notification
  contentType: string | null; // The mime/type content type of the notification
  data: JSONObject | null; // Additional data being used to create or used for the Notification
  sentAt: DateTime | null; // Sent time (null if unknown)
  receivedAt: DateTime | null; // Received time (null if unknown)
  firstDisplayedAt: DateTime | null; // When this notification was first displayed in the app
  markedAsReadAt: DateTime | null; // When this notification was marked as read
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface LatestOrNewSystemNotificationsForOrganizationUser {
  latestOrNewSystemNotificationsForOrganizationUser: LatestOrNewSystemNotificationsForOrganizationUser_latestOrNewSystemNotificationsForOrganizationUser[];
}

export interface LatestOrNewSystemNotificationsForOrganizationUserVariables {
  filterType?: NotificationSystemFilterType[] | null;
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SignInUserContext
// ====================================================

export interface SignInUserContext_signInUserContext_token {
  __typename: 'AzureADB2CToken';
  iss: string;
  exp: number;
  nbf: number;
  aud: string;
  oid: string;
  sub: string;
  city: string;
  country: string;
  name: string;
  givenName: string;
  familyName: string;
  emails: string[];
  tfp: string;
  azp: string;
  ver: string;
  iat: number;
}

export interface SignInUserContext_signInUserContext_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface SignInUserContext_signInUserContext_organizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
}

export interface SignInUserContext_signInUserContext_organization_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
}

export interface SignInUserContext_signInUserContext_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  usedOrganizationInvitation: SignInUserContext_signInUserContext_organization_usedOrganizationInvitation | null;
}

export interface SignInUserContext_signInUserContext_device {
  __typename: 'Device';
  id: string;
  deviceType: DeviceCategoryType; // DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV)
}

export interface SignInUserContext_signInUserContext_organizationSubscriptionPlan_prices {
  __typename: 'SubscriptionPlanPrice';
  currency: string;
  recurringInterval: string;
  value: string;
}

export interface SignInUserContext_signInUserContext_organizationSubscriptionPlan_subscriptionPlan_planName {
  __typename: 'SubscriptionPlanName';
  key: string;
  defaultValue: string;
}

export interface SignInUserContext_signInUserContext_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures {
  __typename: 'SubscriptionFeature';
  id: string;
  name: string; // name
  count: number; // feature max count
  value: subscriptionFeatureType; // (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
}

export interface SignInUserContext_signInUserContext_organizationSubscriptionPlan_subscriptionPlan {
  __typename: 'SubscriptionPlan';
  id: string;
  planName: SignInUserContext_signInUserContext_organizationSubscriptionPlan_subscriptionPlan_planName;
  value: SubscriptionPlanType; // (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5)
  subscriptionFeatures:
    | SignInUserContext_signInUserContext_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures[]
    | null;
}

export interface SignInUserContext_signInUserContext_organizationSubscriptionPlan {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
  isActive: boolean;
  endTime: DateTime;
  prices: SignInUserContext_signInUserContext_organizationSubscriptionPlan_prices[] | null;
  subscriptionPlan: SignInUserContext_signInUserContext_organizationSubscriptionPlan_subscriptionPlan;
}

export interface SignInUserContext_signInUserContext {
  __typename: 'UserContext';
  sub: string;
  token: SignInUserContext_signInUserContext_token;
  user: SignInUserContext_signInUserContext_user | null;
  organizationUser: SignInUserContext_signInUserContext_organizationUser | null;
  organization: SignInUserContext_signInUserContext_organization | null;
  roleType: OrganizationUserRoleType | null;
  organizationType: OrganizationType | null;
  role: string;
  organizationActivatedAt: DateTime | null;
  device: SignInUserContext_signInUserContext_device;
  deviceId: string;
  userContextId: string;
  organizationSubscriptionPlan: SignInUserContext_signInUserContext_organizationSubscriptionPlan | null;
}

export interface SignInUserContext {
  signInUserContext: SignInUserContext_signInUserContext;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationInvitationsForPriojetOrganization
// ====================================================

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_bitrix24Deal {
  __typename: 'OrganizationInvitationBitrix24Deal';
  id: number;
  bitrixDealCategoryId: number;
  bitrixInvitedCompanyId: number;
  bitrixInvitedContactId: number;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationContact_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationContact {
  __typename: 'OrganizationContact';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  phone: string; // Phone Number
  email: string | null; // Email address
  isPriojetContact: boolean; // Is this contact a PRIOjet Contact
  isCourierContact: boolean; // Is this contact a Courier Contact
  isAgencyContact: boolean; // Is this contact an Agency Contact
  isClientContact: boolean; // Is this contact a Client Contact
  isTravelerContact: boolean; // Is this contact a Traveler Contact
  isServiceProviderContact: boolean; // Is this contact a ServiceProvider Contact
  isOtherContact: boolean; // Is this contact an Other Contact
  organization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationContact_organization;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_invitedUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_invitedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationUser_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationUser_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationUser_user;
  organization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationUser_organization;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  createdAt: DateTime;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationOrganization_connectingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  createdAt: DateTime;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationOrganization_connectedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  createdAt: DateTime;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationOrganization {
  __typename: 'OrganizationOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  connectingOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationOrganization_connectingOrganization;
  connectedOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationOrganization_connectedOrganization;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_invitingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  createdAt: DateTime;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
  createdAt: DateTime;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_createdByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
  createdAt: DateTime;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationInvitationSharings_sharedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationInvitationSharings {
  __typename: 'OrganizationInvitationSharing';
  id: string;
  appName: string | null;
  platformVersion: string | null;
  platform: string;
  language: string | null;
  sharedAt: DateTime;
  sharedByUser: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationInvitationSharings_sharedByUser | null;
}

export interface OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization {
  __typename: 'OrganizationInvitation';
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  voidedAt: DateTime | null;
  usedAt: DateTime | null;
  reservedComment: string | null;
  activationCode: string | null;
  organizationInvitationType: OrganizationInvitationType; // The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. (ACTIVATE_NEW_OWN_ORGANIZATION=0, ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION=1, CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION=2, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE=3, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION=4, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION=5, OTHER_ORGANIZATION_INVITATION=6, DEPRECATED_OLD_ORGANIZATION_INVITATION=7)
  invitedOrganizationType: OrganizationType | null; // Invited Organization Type (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5). The OrganizationType of the Organization that can be activated with this activationCode.
  roleType: OrganizationUserRoleType | null; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST). The RoleType of the OrganizationUser that can be created with this activationCode, if applicable.
  bitrix24Deal: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_bitrix24Deal | null; // OrganizationInvitationBitrix24Deal Data
  organizationContact: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationContact | null;
  invitedUser: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_invitedUser | null;
  invitedOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_invitedOrganization | null;
  usedForOrganizationUser: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationUser | null;
  usedForOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganization | null;
  usedForOrganizationOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedForOrganizationOrganization | null;
  invitingOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_invitingOrganization | null;
  usedByUser: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_usedByUser | null;
  createdByUser: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_createdByUser | null;
  organizationInvitationSharings: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization_organizationInvitationSharings[];
}

export interface OrganizationInvitationsForPriojetOrganization {
  organizationInvitationsForPriojetOrganization: OrganizationInvitationsForPriojetOrganization_organizationInvitationsForPriojetOrganization[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierOrganizationInvitationsForAgencyOrganization
// ====================================================

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_organizationContact_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_organizationContact_userContactsForUser {
  __typename: 'UserContact';
  id: string;
  expoContactId: string | null; // The contact ID from the address book
  phone: string; // Phone Number
  phoneUnformatted: string; // Phone Number (unformatted)
  email: string | null; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
  isCourierContact: boolean; // Is this contact a Courier Contact
  isAgencyContact: boolean; // Is this contact an Agency Contact
  isClientContact: boolean; // Is this contact a Client Contact
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_organizationContact {
  __typename: 'OrganizationContact';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  phone: string; // Phone Number
  email: string | null; // Email address
  isCourierContact: boolean; // Is this contact a Courier Contact
  isAgencyContact: boolean; // Is this contact an Agency Contact
  isClientContact: boolean; // Is this contact a Client Contact
  organization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_organizationContact_organization;
  userContactsForUser: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_organizationContact_userContactsForUser[];
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_invitedUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_invitedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationUser_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationUser_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationUser_user;
  organization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationUser_organization;
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationOrganization {
  __typename: 'OrganizationOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  connectingOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectingOrganization;
  connectedOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectedOrganization;
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_invitingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_createdByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization {
  __typename: 'OrganizationInvitation';
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  voidedAt: DateTime | null;
  usedAt: DateTime | null;
  reservedComment: string | null;
  activationCode: string | null;
  organizationInvitationType: OrganizationInvitationType; // The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. (ACTIVATE_NEW_OWN_ORGANIZATION=0, ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION=1, CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION=2, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE=3, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION=4, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION=5, OTHER_ORGANIZATION_INVITATION=6, DEPRECATED_OLD_ORGANIZATION_INVITATION=7)
  invitedOrganizationType: OrganizationType | null; // Invited Organization Type (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5). The OrganizationType of the Organization that can be activated with this activationCode.
  roleType: OrganizationUserRoleType | null; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST). The RoleType of the OrganizationUser that can be created with this activationCode, if applicable.
  hasInvitationDeal: boolean;
  organizationContact: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_organizationContact | null;
  invitedUser: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_invitedUser | null;
  invitedOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_invitedOrganization | null;
  usedForOrganizationUser: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationUser | null;
  usedForOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganization | null;
  usedForOrganizationOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedForOrganizationOrganization | null;
  invitingOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_invitingOrganization | null;
  usedByUser: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_usedByUser | null;
  createdByUser: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization_createdByUser | null;
}

export interface CourierOrganizationInvitationsForAgencyOrganization {
  courierOrganizationInvitationsForAgencyOrganization: CourierOrganizationInvitationsForAgencyOrganization_courierOrganizationInvitationsForAgencyOrganization[];
}

export interface CourierOrganizationInvitationsForAgencyOrganizationVariables {
  agencyOrganizationId: string;
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EmployeeInvitationsForAgencyOrganization
// ====================================================

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_organizationContact_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_organizationContact_userContactsForUser {
  __typename: 'UserContact';
  id: string;
  expoContactId: string | null; // The contact ID from the address book
  phone: string; // Phone Number
  phoneUnformatted: string; // Phone Number (unformatted)
  email: string | null; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
  isCourierContact: boolean; // Is this contact a Courier Contact
  isAgencyContact: boolean; // Is this contact an Agency Contact
  isClientContact: boolean; // Is this contact a Client Contact
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_organizationContact {
  __typename: 'OrganizationContact';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  phone: string; // Phone Number
  email: string | null; // Email address
  isCourierContact: boolean; // Is this contact a Courier Contact
  isAgencyContact: boolean; // Is this contact an Agency Contact
  isClientContact: boolean; // Is this contact a Client Contact
  organization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_organizationContact_organization;
  userContactsForUser: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_organizationContact_userContactsForUser[];
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_invitedUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_invitedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationUser_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationUser_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationUser_user;
  organization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationUser_organization;
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectedOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationOrganization {
  __typename: 'OrganizationOrganization';
  id: string;
  connectionType: OrganizationOrganizationConnectionType; // OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY)
  connectingOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectingOrganization;
  connectedOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationOrganization_connectedOrganization;
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_invitingOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_createdByUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization {
  __typename: 'OrganizationInvitation';
  id: string;
  createdAt: DateTime;
  updatedAt: DateTime;
  voidedAt: DateTime | null;
  usedAt: DateTime | null;
  reservedComment: string | null;
  activationCode: string | null;
  organizationInvitationType: OrganizationInvitationType; // The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. (ACTIVATE_NEW_OWN_ORGANIZATION=0, ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION=1, CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION=2, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE=3, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION=4, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION=5, OTHER_ORGANIZATION_INVITATION=6, DEPRECATED_OLD_ORGANIZATION_INVITATION=7)
  invitedOrganizationType: OrganizationType | null; // Invited Organization Type (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5). The OrganizationType of the Organization that can be activated with this activationCode.
  roleType: OrganizationUserRoleType | null; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST). The RoleType of the OrganizationUser that can be created with this activationCode, if applicable.
  hasInvitationDeal: boolean;
  organizationContact: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_organizationContact | null;
  invitedUser: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_invitedUser | null;
  invitedOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_invitedOrganization | null;
  usedForOrganizationUser: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationUser | null;
  usedForOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganization | null;
  usedForOrganizationOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedForOrganizationOrganization | null;
  invitingOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_invitingOrganization | null;
  usedByUser: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_usedByUser | null;
  createdByUser: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization_createdByUser | null;
}

export interface EmployeeInvitationsForAgencyOrganization {
  employeeInvitationsForAgencyOrganization: EmployeeInvitationsForAgencyOrganization_employeeInvitationsForAgencyOrganization[];
}

export interface EmployeeInvitationsForAgencyOrganizationVariables {
  agencyOrganizationId: string;
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierUserProfileByUserId
// ====================================================

export interface CourierUserProfileByUserId_courierUserProfileByUserId_languagesKnown {
  __typename: 'TLanguageKnown';
  language: string;
  level: LanguageSkillLevel;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_baseAirportLocations {
  __typename: 'Location';
  id: string;
  iataCode: string | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CourierUserProfileByUserId_courierUserProfileByUserId_user_profilePicture_sasToken;
  metadata: CourierUserProfileByUserId_courierUserProfileByUserId_user_profilePicture_metadata;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_userRegisteredAddressLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_organizationUsers_organization_headquartersAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_organizationUsers_organization {
  __typename: 'Organization';
  id: string;
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  name: string; // Name of the Organization
  email: string | null; // Primary email address of the Organization.
  phone: string | null; // Primary phone number of the Organization.
  headquartersAddressLocation: CourierUserProfileByUserId_courierUserProfileByUserId_user_organizationUsers_organization_headquartersAddressLocation | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  organization: CourierUserProfileByUserId_courierUserProfileByUserId_user_organizationUsers_organization;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_agencyCourierUserProfile_organizationCourierCategories {
  __typename: 'OrganizationCourierCategory';
  id: string;
  label: string; // Default label
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user_agencyCourierUserProfile {
  __typename: 'AgencyCourierUserProfile';
  internalComment: string | null; // Internal comment about Courier
  markedForAttention: boolean; // Internal comment about Courier
  organizationCourierCategories:
    | CourierUserProfileByUserId_courierUserProfileByUserId_user_agencyCourierUserProfile_organizationCourierCategories[]
    | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  email2: string | null; // Secondary Email address
  phone: string | null; // Phone Number
  phone2: string | null; // Secondary Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  createdAt: DateTime;
  updatedAt: DateTime;
  inactivatedAt: DateTime | null;
  profilePicture: CourierUserProfileByUserId_courierUserProfileByUserId_user_profilePicture | null;
  userRegisteredAddressLocation: CourierUserProfileByUserId_courierUserProfileByUserId_user_userRegisteredAddressLocation | null;
  organizationUsers: CourierUserProfileByUserId_courierUserProfileByUserId_user_organizationUsers[];
  agencyCourierUserProfile: CourierUserProfileByUserId_courierUserProfileByUserId_user_agencyCourierUserProfile | null;
}

export interface CourierUserProfileByUserId_courierUserProfileByUserId {
  __typename: 'CourierUserProfile';
  id: string;
  inactivatedAt: DateTime | null;
  emergencyContactRelationship: string | null; // Emergency Contact Relationship
  emergencyContactPhone: string | null; // Emergency Contact Phone Number
  emergencyContactName: string | null; // Emergency Contact Name
  emergencyContactEmail: string | null; // Emergency Contact Email
  emergencyContactAddress: string | null; // Emergency Contact Address
  hasAmexCreditCard: boolean | null; // American Express Credit Card
  hasDinerCreditCard: boolean | null; // Diner's Credit Card
  hasMasterCreditCard: boolean | null; // Master Credit Card
  hasVisaCreditCard: boolean | null; // Visa Credit Card
  hasAmexDebitCard: boolean | null; // American Express debit/prepaid Card
  hasDinerDebitCard: boolean | null; // Diner's debit/prepaid Card
  hasMasterDebitCard: boolean | null; // Master Debit/Prepaid Card
  hasVisaDebitCard: boolean | null; // Visa Debit/Prepaid Card
  amexCreditCardLimit: number | null; // American Express Credit Card Limit
  dinerCreditCardLimit: number | null; // Diner's Credit Card Limit
  masterCreditCardLimit: number | null; // Master Credit Card Limit
  visaCreditCardLimit: number | null; // Visa Credit Card Limit
  amexCreditCardCurrency: Currency | null; // American Express Credit Card Currency (EUR=0, USD=1)
  dinerCreditCardCurrency: Currency | null; // Diners Credit Card Currency (EUR=0, USD=1)
  masterCreditCardCurrency: Currency | null; // Master Credit Card Currency (EUR=0, USD=1)
  visaCreditCardCurrency: Currency | null; // Visa Credit Card Currency (EUR=0, USD=1)
  hasObcExperience: boolean | null; // Prior OBC Experience (Yes/No)
  firstObcJobDateAt: DateTime | null; // First OBC job date
  numberOfPastObcJobs: number | null; // Number of Prior OBC Jobs
  hasMedicalObcExperience: boolean | null; // Prior Medical (Stem cell) OBC Experience (Yes/No)
  languagesKnown: CourierUserProfileByUserId_courierUserProfileByUserId_languagesKnown[] | null; // Languages known
  onboardExperiences: string[] | null; // On Board Experiences
  taxCountry: string | null; // In which country is my business registered for tax purposes, ISO 3166 Alpha-3 code
  socialProfiles: CourierUserProfileByUserId_courierUserProfileByUserId_socialProfiles[]; // Social profiles
  baseAirports: string[] | null; // Base airports, 3 capital letters
  baseAirportLocations: CourierUserProfileByUserId_courierUserProfileByUserId_baseAirportLocations[];
  user: CourierUserProfileByUserId_courierUserProfileByUserId_user;
}

export interface CourierUserProfileByUserId {
  courierUserProfileByUserId: CourierUserProfileByUserId_courierUserProfileByUserId;
}

export interface CourierUserProfileByUserIdVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserContext
// ====================================================

export interface CurrentUserContext_currentUserContext_token {
  __typename: 'AzureADB2CToken';
  iss: string;
  exp: number;
  nbf: number;
  aud: string;
  oid: string;
  sub: string;
  city: string;
  country: string;
  name: string;
  givenName: string;
  familyName: string;
  emails: string[];
  tfp: string;
  azp: string;
  ver: string;
  iat: number;
}

export interface CurrentUserContext_currentUserContext_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface CurrentUserContext_currentUserContext_organizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
}

export interface CurrentUserContext_currentUserContext_organization_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
}

export interface CurrentUserContext_currentUserContext_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  usedOrganizationInvitation: CurrentUserContext_currentUserContext_organization_usedOrganizationInvitation | null;
}

export interface CurrentUserContext_currentUserContext_device {
  __typename: 'Device';
  id: string;
  deviceType: DeviceCategoryType; // DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV)
}

export interface CurrentUserContext_currentUserContext_organizationSubscriptionPlan_prices {
  __typename: 'SubscriptionPlanPrice';
  currency: string;
  recurringInterval: string;
  value: string;
}

export interface CurrentUserContext_currentUserContext_organizationSubscriptionPlan_subscriptionPlan_planName {
  __typename: 'SubscriptionPlanName';
  key: string;
  defaultValue: string;
}

export interface CurrentUserContext_currentUserContext_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures {
  __typename: 'SubscriptionFeature';
  id: string;
  name: string; // name
  count: number; // feature max count
  value: subscriptionFeatureType; // (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
}

export interface CurrentUserContext_currentUserContext_organizationSubscriptionPlan_subscriptionPlan {
  __typename: 'SubscriptionPlan';
  id: string;
  planName: CurrentUserContext_currentUserContext_organizationSubscriptionPlan_subscriptionPlan_planName;
  value: SubscriptionPlanType; // (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5)
  subscriptionFeatures:
    | CurrentUserContext_currentUserContext_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures[]
    | null;
}

export interface CurrentUserContext_currentUserContext_organizationSubscriptionPlan {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
  isActive: boolean;
  startTime: DateTime;
  endTime: DateTime;
  isMain: boolean;
  recurringInterval: string | null; // Recurring interval - month, year
  status: string;
  prices: CurrentUserContext_currentUserContext_organizationSubscriptionPlan_prices[] | null;
  subscriptionPlan: CurrentUserContext_currentUserContext_organizationSubscriptionPlan_subscriptionPlan;
}

export interface CurrentUserContext_currentUserContext {
  __typename: 'UserContext';
  sub: string;
  token: CurrentUserContext_currentUserContext_token;
  user: CurrentUserContext_currentUserContext_user | null;
  organizationUser: CurrentUserContext_currentUserContext_organizationUser | null;
  organization: CurrentUserContext_currentUserContext_organization | null;
  roleType: OrganizationUserRoleType | null;
  organizationType: OrganizationType | null;
  role: string;
  organizationActivatedAt: DateTime | null;
  device: CurrentUserContext_currentUserContext_device;
  deviceId: string;
  userContextId: string;
  organizationSubscriptionPlan: CurrentUserContext_currentUserContext_organizationSubscriptionPlan | null;
}

export interface CurrentUserContext {
  currentUserContext: CurrentUserContext_currentUserContext;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserContexts
// ====================================================

export interface UserContexts_userContexts_token {
  __typename: 'AzureADB2CToken';
  iss: string;
  exp: number;
  nbf: number;
  aud: string;
  oid: string;
  sub: string;
  city: string;
  country: string;
  name: string;
  givenName: string;
  familyName: string;
  emails: string[];
  tfp: string;
  azp: string;
  ver: string;
  iat: number;
}

export interface UserContexts_userContexts_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  lastName: string; // Last name
}

export interface UserContexts_userContexts_organizationUser {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
}

export interface UserContexts_userContexts_organization_usedOrganizationInvitation {
  __typename: 'OrganizationInvitation';
  id: string;
  usedAt: DateTime | null;
}

export interface UserContexts_userContexts_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  usedOrganizationInvitation: UserContexts_userContexts_organization_usedOrganizationInvitation | null;
}

export interface UserContexts_userContexts_device {
  __typename: 'Device';
  id: string;
  deviceType: DeviceCategoryType; // DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV)
}

export interface UserContexts_userContexts_organizationSubscriptionPlan_prices {
  __typename: 'SubscriptionPlanPrice';
  currency: string;
  recurringInterval: string;
  value: string;
}

export interface UserContexts_userContexts_organizationSubscriptionPlan_subscriptionPlan_planName {
  __typename: 'SubscriptionPlanName';
  key: string;
  defaultValue: string;
}

export interface UserContexts_userContexts_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures {
  __typename: 'SubscriptionFeature';
  id: string;
  count: number; // feature max count
  name: string; // name
  value: subscriptionFeatureType; // (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
}

export interface UserContexts_userContexts_organizationSubscriptionPlan_subscriptionPlan {
  __typename: 'SubscriptionPlan';
  id: string;
  planName: UserContexts_userContexts_organizationSubscriptionPlan_subscriptionPlan_planName;
  value: SubscriptionPlanType; // (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5)
  subscriptionFeatures:
    | UserContexts_userContexts_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures[]
    | null;
}

export interface UserContexts_userContexts_organizationSubscriptionPlan {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
  isActive: boolean;
  endTime: DateTime;
  prices: UserContexts_userContexts_organizationSubscriptionPlan_prices[] | null;
  subscriptionPlan: UserContexts_userContexts_organizationSubscriptionPlan_subscriptionPlan;
}

export interface UserContexts_userContexts {
  __typename: 'UserContext';
  sub: string;
  token: UserContexts_userContexts_token;
  user: UserContexts_userContexts_user | null;
  organizationUser: UserContexts_userContexts_organizationUser | null;
  organization: UserContexts_userContexts_organization | null;
  roleType: OrganizationUserRoleType | null;
  organizationType: OrganizationType | null;
  role: string;
  organizationActivatedAt: DateTime | null;
  device: UserContexts_userContexts_device;
  deviceId: string;
  userContextId: string;
  organizationSubscriptionPlan: UserContexts_userContexts_organizationSubscriptionPlan | null;
}

export interface UserContexts {
  userContexts: UserContexts_userContexts[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierUserProfileForCourierOrganizationUser
// ====================================================

export interface CourierUserProfileForCourierOrganizationUser_courierUserProfileForCourierOrganizationUser_languagesKnown {
  __typename: 'TLanguageKnown';
  language: string;
  level: LanguageSkillLevel;
}

export interface CourierUserProfileForCourierOrganizationUser_courierUserProfileForCourierOrganizationUser_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface CourierUserProfileForCourierOrganizationUser_courierUserProfileForCourierOrganizationUser {
  __typename: 'CourierUserProfile';
  id: string;
  emergencyContactRelationship: string | null; // Emergency Contact Relationship
  emergencyContactPhone: string | null; // Emergency Contact Phone Number
  emergencyContactName: string | null; // Emergency Contact Name
  emergencyContactEmail: string | null; // Emergency Contact Email
  emergencyContactAddress: string | null; // Emergency Contact Address
  hasAmexCreditCard: boolean | null; // American Express Credit Card
  hasDinerCreditCard: boolean | null; // Diner's Credit Card
  hasMasterCreditCard: boolean | null; // Master Credit Card
  hasVisaCreditCard: boolean | null; // Visa Credit Card
  hasAmexDebitCard: boolean | null; // American Express debit/prepaid Card
  hasDinerDebitCard: boolean | null; // Diner's debit/prepaid Card
  hasMasterDebitCard: boolean | null; // Master Debit/Prepaid Card
  hasVisaDebitCard: boolean | null; // Visa Debit/Prepaid Card
  amexCreditCardLimit: number | null; // American Express Credit Card Limit
  dinerCreditCardLimit: number | null; // Diner's Credit Card Limit
  masterCreditCardLimit: number | null; // Master Credit Card Limit
  visaCreditCardLimit: number | null; // Visa Credit Card Limit
  amexCreditCardCurrency: Currency | null; // American Express Credit Card Currency (EUR=0, USD=1)
  dinerCreditCardCurrency: Currency | null; // Diners Credit Card Currency (EUR=0, USD=1)
  masterCreditCardCurrency: Currency | null; // Master Credit Card Currency (EUR=0, USD=1)
  visaCreditCardCurrency: Currency | null; // Visa Credit Card Currency (EUR=0, USD=1)
  hasObcExperience: boolean | null; // Prior OBC Experience (Yes/No)
  firstObcJobDateAt: DateTime | null; // First OBC job date
  numberOfPastObcJobs: number | null; // Number of Prior OBC Jobs
  hasMedicalObcExperience: boolean | null; // Prior Medical (Stem cell) OBC Experience (Yes/No)
  languagesKnown:
    | CourierUserProfileForCourierOrganizationUser_courierUserProfileForCourierOrganizationUser_languagesKnown[]
    | null; // Languages known
  onboardExperiences: string[] | null; // On Board Experiences
  taxCountry: string | null; // In which country is my business registered for tax purposes, ISO 3166 Alpha-3 code
  socialProfiles: CourierUserProfileForCourierOrganizationUser_courierUserProfileForCourierOrganizationUser_socialProfiles[]; // Social profiles
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface CourierUserProfileForCourierOrganizationUser {
  courierUserProfileForCourierOrganizationUser: CourierUserProfileForCourierOrganizationUser_courierUserProfileForCourierOrganizationUser;
}

export interface CourierUserProfileForCourierOrganizationUserVariables {
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: User
// ====================================================

export interface User_user_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface User_user_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface User_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: User_user_profilePicture_sasToken;
  metadata: User_user_profilePicture_metadata;
}

export interface User_user_userRegisteredAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface User_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  email: string; // Email address
  phone: string | null; // Phone Number
  email2: string | null; // Secondary Email address
  phone2: string | null; // Secondary Phone Number
  profilePicture: User_user_profilePicture | null;
  userRegisteredAddressLocation: User_user_userRegisteredAddressLocation | null;
}

export interface User {
  user: User_user;
}

export interface UserVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserMinimal
// ====================================================

export interface UserMinimal_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
}

export interface UserMinimal {
  user: UserMinimal_user;
}

export interface UserMinimalVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserFrequentFlyerDocuments
// ====================================================

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureCompressed_metadata;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureOriginal_metadata;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureCompressed_metadata;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureOriginal_metadata;
}

export interface UserFrequentFlyerDocuments_userFrequentFlyerDocuments {
  __typename: 'UserFrequentFlyerDocument';
  id: string;
  airlineType: AirlineType | null; // Frequent Flyer Airline
  airlineStatusType: AirlineStatusType | null; // Status of the airline
  airlineAllianceStatusType: AirlineAllianceStatusType | null; // Airline Alliance Status
  airlineAllianceType: AirlineAllianceType | null; // Airline Alliance
  documentNumber: string | null; // document number as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserFrequentFlyerDocuments_userFrequentFlyerDocuments_documentBackFromAzureOriginal | null;
}

export interface UserFrequentFlyerDocuments {
  userFrequentFlyerDocuments: UserFrequentFlyerDocuments_userFrequentFlyerDocuments[];
}

export interface UserFrequentFlyerDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserFrequentFlyerDocument
// ====================================================

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureCompressed_metadata;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureOriginal_metadata;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureCompressed_metadata;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureOriginal_metadata;
}

export interface UserFrequentFlyerDocument_userFrequentFlyerDocument {
  __typename: 'UserFrequentFlyerDocument';
  id: string;
  airlineType: AirlineType | null; // Frequent Flyer Airline
  airlineStatusType: AirlineStatusType | null; // Status of the airline
  airlineAllianceStatusType: AirlineAllianceStatusType | null; // Airline Alliance Status
  airlineAllianceType: AirlineAllianceType | null; // Airline Alliance
  documentNumber: string | null; // document number as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserFrequentFlyerDocument_userFrequentFlyerDocument_documentBackFromAzureOriginal | null;
}

export interface UserFrequentFlyerDocument {
  userFrequentFlyerDocument: UserFrequentFlyerDocument_userFrequentFlyerDocument;
}

export interface UserFrequentFlyerDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserVaccinationDocuments
// ====================================================

export interface UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureCompressed_metadata;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureOriginal_metadata;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureCompressed_metadata;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureOriginal_metadata;
}

export interface UserVaccinationDocuments_userVaccinationDocuments {
  __typename: 'UserVaccinationDocument';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that vaccination is for
  description: string | null; // Description of Vaccine
  vaccinationType: string | null; // Vaccination Document Type/Method (example SARS-CoV-mRNA for COVID19
  vaccinationManufacturer: string | null; // Vaccination Manufacturer (example PFIZER-BIOnTech for COVID19
  dateOfVaccination: DateTime | null; // Date of Vaccination as per the document
  isVaccinationComplete: boolean | null; // is vaccination complete
  vaccinationCenter: string | null; // Name of Vaccination Centre as per the document
  cityOfVaccination: string | null; // City of Vaccination as per the document
  countryOfVaccination: string | null; // country of vaccination
  expiresOn: DateTime | null; // expiry date as per the document
  documentNumber: string | null; // document number as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserVaccinationDocuments_userVaccinationDocuments_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserVaccinationDocuments_userVaccinationDocuments_documentBackFromAzureOriginal | null;
}

export interface UserVaccinationDocuments {
  userVaccinationDocuments: UserVaccinationDocuments_userVaccinationDocuments[];
}

export interface UserVaccinationDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserVaccinationDocument
// ====================================================

export interface UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureCompressed_metadata;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureOriginal_metadata;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureCompressed_metadata;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureOriginal_metadata;
}

export interface UserVaccinationDocument_userVaccinationDocument {
  __typename: 'UserVaccinationDocument';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that vaccination is for
  description: string | null; // Description of Vaccine
  vaccinationType: string | null; // Vaccination Document Type/Method (example SARS-CoV-mRNA for COVID19
  vaccinationManufacturer: string | null; // Vaccination Manufacturer (example PFIZER-BIOnTech for COVID19
  dateOfVaccination: DateTime | null; // Date of Vaccination as per the document
  isVaccinationComplete: boolean | null; // is vaccination complete
  vaccinationCenter: string | null; // Name of Vaccination Centre as per the document
  cityOfVaccination: string | null; // City of Vaccination as per the document
  countryOfVaccination: string | null; // country of vaccination
  expiresOn: DateTime | null; // expiry date as per the document
  documentNumber: string | null; // document number as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserVaccinationDocument_userVaccinationDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserVaccinationDocument_userVaccinationDocument_documentBackFromAzureOriginal | null;
}

export interface UserVaccinationDocument {
  userVaccinationDocument: UserVaccinationDocument_userVaccinationDocument;
}

export interface UserVaccinationDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHealthReports
// ====================================================

export interface UserHealthReports_userHealthReports_cityOfTestLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UserHealthReports_userHealthReports_cityOfTestLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UserHealthReports_userHealthReports_cityOfTestLocation_locationGeometry_location;
}

export interface UserHealthReports_userHealthReports_cityOfTestLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: UserHealthReports_userHealthReports_cityOfTestLocation_locationGeometry;
}

export interface UserHealthReports_userHealthReports_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReports_userHealthReports_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReports_userHealthReports_documentFrontFromAzureCompressed_metadata;
}

export interface UserHealthReports_userHealthReports_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReports_userHealthReports_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReports_userHealthReports_documentFrontFromAzureOriginal_metadata;
}

export interface UserHealthReports_userHealthReports_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReports_userHealthReports_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReports_userHealthReports_documentBackFromAzureCompressed_metadata;
}

export interface UserHealthReports_userHealthReports_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReports_userHealthReports_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReports_userHealthReports_documentBackFromAzureOriginal_metadata;
}

export interface UserHealthReports_userHealthReports {
  __typename: 'UserHealthReport';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that test is for
  description: string | null; // Description of test
  testType: HealthTestType | null; // Test Document Type/Method PCR/ Rapid/ Antigen/ PCR-Rapid for COVID 19
  dateTimeOfTest: DateTime | null; // Date of Test as per the document
  isResultPositive: boolean | null; // is test complete
  testCenter: string | null; // Name of Test Centre as per the document
  cityOfTest: string | null; // City of Test as per the document
  cityOfTestLocation: UserHealthReports_userHealthReports_cityOfTestLocation | null;
  countryOfTest: string | null; // country of test
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserHealthReports_userHealthReports_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserHealthReports_userHealthReports_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserHealthReports_userHealthReports_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserHealthReports_userHealthReports_documentBackFromAzureOriginal | null;
}

export interface UserHealthReports {
  userHealthReports: UserHealthReports_userHealthReports[];
}

export interface UserHealthReportsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHealthReport
// ====================================================

export interface UserHealthReport_userHealthReport_cityOfTestLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface UserHealthReport_userHealthReport_cityOfTestLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: UserHealthReport_userHealthReport_cityOfTestLocation_locationGeometry_location;
}

export interface UserHealthReport_userHealthReport_cityOfTestLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: UserHealthReport_userHealthReport_cityOfTestLocation_locationGeometry;
}

export interface UserHealthReport_userHealthReport_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReport_userHealthReport_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReport_userHealthReport_documentFrontFromAzureCompressed_metadata;
}

export interface UserHealthReport_userHealthReport_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReport_userHealthReport_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReport_userHealthReport_documentFrontFromAzureOriginal_metadata;
}

export interface UserHealthReport_userHealthReport_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReport_userHealthReport_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReport_userHealthReport_documentBackFromAzureCompressed_metadata;
}

export interface UserHealthReport_userHealthReport_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserHealthReport_userHealthReport_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserHealthReport_userHealthReport_documentBackFromAzureOriginal_metadata;
}

export interface UserHealthReport_userHealthReport {
  __typename: 'UserHealthReport';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that test is for
  description: string | null; // Description of test
  testType: HealthTestType | null; // Test Document Type/Method PCR/ Rapid/ Antigen/ PCR-Rapid for COVID 19
  dateTimeOfTest: DateTime | null; // Date of Test as per the document
  isResultPositive: boolean | null; // is test complete
  testCenter: string | null; // Name of Test Centre as per the document
  cityOfTest: string | null; // City of Test as per the document
  cityOfTestLocation: UserHealthReport_userHealthReport_cityOfTestLocation | null;
  countryOfTest: string | null; // country of test
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserHealthReport_userHealthReport_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserHealthReport_userHealthReport_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserHealthReport_userHealthReport_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserHealthReport_userHealthReport_documentBackFromAzureOriginal | null;
}

export interface UserHealthReport {
  userHealthReport: UserHealthReport_userHealthReport;
}

export interface UserHealthReportVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBackgroundDocuments
// ====================================================

export interface UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureCompressed_metadata;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureOriginal_metadata;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureCompressed_metadata;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureOriginal_metadata;
}

export interface UserBackgroundDocuments_userBackgroundDocuments {
  __typename: 'UserBackgroundDocument';
  id: string;
  name: string | null; // Name of the document
  issuingInstitution: string | null; // Name of the the institution issuing the document
  countryOfIssue: string | null; // country issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserBackgroundDocuments_userBackgroundDocuments_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserBackgroundDocuments_userBackgroundDocuments_documentBackFromAzureOriginal | null;
}

export interface UserBackgroundDocuments {
  userBackgroundDocuments: UserBackgroundDocuments_userBackgroundDocuments[];
}

export interface UserBackgroundDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBackgroundDocument
// ====================================================

export interface UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureCompressed_metadata;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureOriginal_metadata;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureCompressed_metadata;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureOriginal_metadata;
}

export interface UserBackgroundDocument_userBackgroundDocument {
  __typename: 'UserBackgroundDocument';
  id: string;
  name: string | null; // Name of the document
  issuingInstitution: string | null; // Name of the the institution issuing the document
  countryOfIssue: string | null; // country issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserBackgroundDocument_userBackgroundDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserBackgroundDocument_userBackgroundDocument_documentBackFromAzureOriginal | null;
}

export interface UserBackgroundDocument {
  userBackgroundDocument: UserBackgroundDocument_userBackgroundDocument;
}

export interface UserBackgroundDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserOtherDocuments
// ====================================================

export interface UserOtherDocuments_userOtherDocuments_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocuments_userOtherDocuments_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocuments_userOtherDocuments_documentFrontFromAzureCompressed_metadata;
}

export interface UserOtherDocuments_userOtherDocuments_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocuments_userOtherDocuments_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocuments_userOtherDocuments_documentFrontFromAzureOriginal_metadata;
}

export interface UserOtherDocuments_userOtherDocuments_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocuments_userOtherDocuments_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocuments_userOtherDocuments_documentBackFromAzureCompressed_metadata;
}

export interface UserOtherDocuments_userOtherDocuments_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocuments_userOtherDocuments_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocuments_userOtherDocuments_documentBackFromAzureOriginal_metadata;
}

export interface UserOtherDocuments_userOtherDocuments {
  __typename: 'UserOtherDocument';
  id: string;
  name: string | null; // Name of the document
  description: string | null; // Description of the document
  countryOfIssue: string | null; // country issuing the document
  placeOfIssue: string | null; // place/city issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserOtherDocuments_userOtherDocuments_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserOtherDocuments_userOtherDocuments_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserOtherDocuments_userOtherDocuments_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserOtherDocuments_userOtherDocuments_documentBackFromAzureOriginal | null;
}

export interface UserOtherDocuments {
  userOtherDocuments: UserOtherDocuments_userOtherDocuments[];
}

export interface UserOtherDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserOtherDocument
// ====================================================

export interface UserOtherDocument_userOtherDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocument_userOtherDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocument_userOtherDocument_documentFrontFromAzureCompressed_metadata;
}

export interface UserOtherDocument_userOtherDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocument_userOtherDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocument_userOtherDocument_documentFrontFromAzureOriginal_metadata;
}

export interface UserOtherDocument_userOtherDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocument_userOtherDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocument_userOtherDocument_documentBackFromAzureCompressed_metadata;
}

export interface UserOtherDocument_userOtherDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserOtherDocument_userOtherDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: UserOtherDocument_userOtherDocument_documentBackFromAzureOriginal_metadata;
}

export interface UserOtherDocument_userOtherDocument {
  __typename: 'UserOtherDocument';
  id: string;
  name: string | null; // Name of the document
  description: string | null; // Description of the document
  countryOfIssue: string | null; // country issuing the document
  placeOfIssue: string | null; // place/city issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: UserOtherDocument_userOtherDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: UserOtherDocument_userOtherDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: UserOtherDocument_userOtherDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: UserOtherDocument_userOtherDocument_documentBackFromAzureOriginal | null;
}

export interface UserOtherDocument {
  userOtherDocument: UserOtherDocument_userOtherDocument;
}

export interface UserOtherDocumentVariables {
  documentId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: userWithAllUserDocuments
// ====================================================

export interface userWithAllUserDocuments_userWithAllUserDocuments_userBackgroundDocument {
  __typename: 'UserBackgroundDocument';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments_userFrequentFlyerDocuments {
  __typename: 'UserFrequentFlyerDocument';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments_userHealthReports {
  __typename: 'UserHealthReport';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments_userIdentityDocuments {
  __typename: 'UserIdentityDocument';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments_userOtherDocuments {
  __typename: 'UserOtherDocument';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments_userTravelDocuments {
  __typename: 'UserTravelDocument';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments_userVaccinationDocuments {
  __typename: 'UserVaccinationDocument';
  id: string;
}

export interface userWithAllUserDocuments_userWithAllUserDocuments {
  __typename: 'User';
  id: string;
  userBackgroundDocument: userWithAllUserDocuments_userWithAllUserDocuments_userBackgroundDocument[];
  userFrequentFlyerDocuments: userWithAllUserDocuments_userWithAllUserDocuments_userFrequentFlyerDocuments[];
  userHealthReports: userWithAllUserDocuments_userWithAllUserDocuments_userHealthReports[];
  userIdentityDocuments: userWithAllUserDocuments_userWithAllUserDocuments_userIdentityDocuments[];
  userOtherDocuments: userWithAllUserDocuments_userWithAllUserDocuments_userOtherDocuments[];
  userTravelDocuments: userWithAllUserDocuments_userWithAllUserDocuments_userTravelDocuments[];
  userVaccinationDocuments: userWithAllUserDocuments_userWithAllUserDocuments_userVaccinationDocuments[];
}

export interface userWithAllUserDocuments {
  userWithAllUserDocuments: userWithAllUserDocuments_userWithAllUserDocuments;
}

export interface userWithAllUserDocumentsVariables {
  userId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierJobForCourierOrganization
// ====================================================

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser_profilePicture_sasToken;
  metadata: CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser_profilePicture_metadata;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser_profilePicture | null;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_startAirport_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_startAirport_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_startAirport_locationGeometry_location;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_startAirport_locationGeometry;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_endAirport_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_endAirport_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_endAirport_locationGeometry_location;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_endAirport_locationGeometry;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganizationUsers_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganizationUsers_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  organization: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganizationUsers_organization;
  user: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganizationUsers_user;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  numOfItems: number; // The number of items transported in the job
  weightOfItems: number | null; // The total weights of items transported in the job
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  startAirport: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_startAirport | null;
  endAirport: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_endAirport | null;
  responsibleAgencyOrganization: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganization;
  responsibleAgencyOrganizationUsers: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob_responsibleAgencyOrganizationUsers[];
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job_pickupLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job_pickupLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForCourierOrganization_courierJobForCourierOrganization_job_pickupLocation_locationGeometry_location;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForCourierOrganization_courierJobForCourierOrganization_job_pickupLocation_locationGeometry;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job_dropOffLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job_dropOffLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForCourierOrganization_courierJobForCourierOrganization_job_dropOffLocation_locationGeometry_location;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForCourierOrganization_courierJobForCourierOrganization_job_dropOffLocation_locationGeometry;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: CourierJobForCourierOrganization_courierJobForCourierOrganization_job_pickupLocation;
  dropOffLocation: CourierJobForCourierOrganization_courierJobForCourierOrganization_job_dropOffLocation;
}

export interface CourierJobForCourierOrganization_courierJobForCourierOrganization {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  relationshipTypeChangedAt: DateTime | null;
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  responsibleCourierOrganization: CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierOrganization;
  responsibleCourierUser: CourierJobForCourierOrganization_courierJobForCourierOrganization_responsibleCourierUser;
  agencyJob: CourierJobForCourierOrganization_courierJobForCourierOrganization_agencyJob;
  job: CourierJobForCourierOrganization_courierJobForCourierOrganization_job;
}

export interface CourierJobForCourierOrganization {
  courierJobForCourierOrganization: CourierJobForCourierOrganization_courierJobForCourierOrganization;
}

export interface CourierJobForCourierOrganizationVariables {
  courierOrganizationId: string;
  courierJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierJobForAgencyOrganization
// ====================================================

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser_profilePicture_sasToken;
  metadata: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser_profilePicture_metadata;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser_profilePicture | null;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_startAirport_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_startAirport_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_startAirport_locationGeometry_location;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_startAirport_locationGeometry;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_endAirport_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_endAirport_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_endAirport_locationGeometry_location;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_endAirport_locationGeometry;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_responsibleAgencyOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  numOfItems: number; // The number of items transported in the job
  weightOfItems: number | null; // The total weights of items transported in the job
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  startAirport: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_startAirport | null;
  endAirport: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_endAirport | null;
  responsibleAgencyOrganization: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob_responsibleAgencyOrganization;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_pickupLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_pickupLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_pickupLocation_locationGeometry_location;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_pickupLocation_locationGeometry;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_dropOffLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_dropOffLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_dropOffLocation_locationGeometry_location;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_dropOffLocation_locationGeometry;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_pickupLocation;
  dropOffLocation: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job_dropOffLocation;
}

export interface CourierJobForAgencyOrganization_courierJobForAgencyOrganization {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  relationshipTypeChangedAt: DateTime | null;
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  responsibleCourierOrganization: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierOrganization;
  responsibleCourierUser: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_responsibleCourierUser;
  agencyJob: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_agencyJob;
  job: CourierJobForAgencyOrganization_courierJobForAgencyOrganization_job;
}

export interface CourierJobForAgencyOrganization {
  courierJobForAgencyOrganization: CourierJobForAgencyOrganization_courierJobForAgencyOrganization;
}

export interface CourierJobForAgencyOrganizationVariables {
  agencyOrganizationId: string;
  courierJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierJobsForCourierOrganization
// ====================================================

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser_profilePicture_sasToken;
  metadata: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser_profilePicture_metadata;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser_profilePicture | null;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob_responsibleAgencyOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  relationshipType: AgencyJobClientRelationshipType; // AgencyJob to Client relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, CLIENT_REQUESTED=3, CLIENT_ACCEPTED=4, CLIENT_DECLINED=5)
  startAirport: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob_startAirport | null;
  endAirport: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob_endAirport | null;
  responsibleAgencyOrganization: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob_responsibleAgencyOrganization;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_pickupLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_pickupLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_pickupLocation_locationGeometry_location;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_pickupLocation_locationGeometry;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_dropOffLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_dropOffLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_dropOffLocation_locationGeometry_location;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_dropOffLocation_locationGeometry;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_pickupLocation;
  dropOffLocation: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job_dropOffLocation;
}

export interface CourierJobsForCourierOrganization_courierJobsForCourierOrganization {
  __typename: 'CourierJob';
  id: string;
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  relationshipTypeChangedAt: DateTime | null;
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  responsibleCourierOrganization: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierOrganization;
  responsibleCourierUser: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_responsibleCourierUser;
  agencyJob: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_agencyJob;
  job: CourierJobsForCourierOrganization_courierJobsForCourierOrganization_job;
}

export interface CourierJobsForCourierOrganization {
  courierJobsForCourierOrganization: CourierJobsForCourierOrganization_courierJobsForCourierOrganization[];
}

export interface CourierJobsForCourierOrganizationVariables {
  courierOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierJobsForCourierOrganizationMinimal
// ====================================================

export interface CourierJobsForCourierOrganizationMinimal_courierJobsForCourierOrganizationMinimal_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
}

export interface CourierJobsForCourierOrganizationMinimal_courierJobsForCourierOrganizationMinimal_job {
  __typename: 'Job';
  id: string;
}

export interface CourierJobsForCourierOrganizationMinimal_courierJobsForCourierOrganizationMinimal {
  __typename: 'CourierJob';
  id: string;
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  agencyJob: CourierJobsForCourierOrganizationMinimal_courierJobsForCourierOrganizationMinimal_agencyJob;
  job: CourierJobsForCourierOrganizationMinimal_courierJobsForCourierOrganizationMinimal_job;
}

export interface CourierJobsForCourierOrganizationMinimal {
  courierJobsForCourierOrganizationMinimal: CourierJobsForCourierOrganizationMinimal_courierJobsForCourierOrganizationMinimal[];
}

export interface CourierJobsForCourierOrganizationMinimalVariables {
  courierOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobsForAgencyOrganizationPaginated
// ====================================================

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  pickupLocation: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_job_pickupLocation;
  dropOffLocation: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_job_dropOffLocation;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_responsibleAgencyOrganization {
  __typename: 'Organization';
  id: string;
  linkCO2Emissions: string | null; // Link CO2 emissions of the Organization.
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  internalNotes: string | null; // Internal agency job notes
  startAirport: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_startAirport | null;
  endAirport: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_endAirport | null;
  job: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_job;
  courierJobs:
    | AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_courierJobs[]
    | null;
  responsibleAgencyOrganization: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node_responsibleAgencyOrganization;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges {
  __typename: 'AgencyJobEdge';
  node: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges_node;
}

export interface AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated {
  __typename: 'AgencyJobConnection';
  edges:
    | AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated_edges[]
    | null;
}

export interface AgencyJobsForAgencyOrganizationPaginated {
  agencyJobsForAgencyOrganizationPaginated: AgencyJobsForAgencyOrganizationPaginated_agencyJobsForAgencyOrganizationPaginated;
}

export interface AgencyJobsForAgencyOrganizationPaginatedVariables {
  data: AgencyJobsForAgencyOrganizationInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobsForAgencyOrganizationMinimal
// ====================================================

export interface AgencyJobsForAgencyOrganizationMinimal_agencyJobsForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
}

export interface AgencyJobsForAgencyOrganizationMinimal {
  agencyJobsForAgencyOrganization: AgencyJobsForAgencyOrganizationMinimal_agencyJobsForAgencyOrganization[];
}

export interface AgencyJobsForAgencyOrganizationMinimalVariables {
  agencyOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobsForAgencyOrganization
// ====================================================

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  pickupLocation: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_job_pickupLocation;
  dropOffLocation: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_job_dropOffLocation;
}

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
}

export interface AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  internalNotes: string | null; // Internal agency job notes
  startAirport: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_startAirport | null;
  endAirport: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_endAirport | null;
  job: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_job;
  courierJobs: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization_courierJobs[] | null;
}

export interface AgencyJobsForAgencyOrganization {
  agencyJobsForAgencyOrganization: AgencyJobsForAgencyOrganization_agencyJobsForAgencyOrganization[];
}

export interface AgencyJobsForAgencyOrganizationVariables {
  agencyOrganizationId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierJobsForAgencyJob
// ====================================================

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  shortName: string | null; // Short name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser_profilePicture_sasToken;
  metadata: CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser_profilePicture_metadata;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser_profilePicture | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_agencyJob_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_agencyJob_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  startAirport: CourierJobsForAgencyJob_courierJobsForAgencyJob_agencyJob_startAirport | null;
  endAirport: CourierJobsForAgencyJob_courierJobsForAgencyJob_agencyJob_endAirport | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: CourierJobsForAgencyJob_courierJobsForAgencyJob_job_pickupLocation;
  dropOffLocation: CourierJobsForAgencyJob_courierJobsForAgencyJob_job_dropOffLocation;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob_courierJobAgencyJobChat {
  __typename: 'Chat';
  id: string;
}

export interface CourierJobsForAgencyJob_courierJobsForAgencyJob {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  responsibleCourierOrganization: CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierOrganization;
  responsibleCourierUser: CourierJobsForAgencyJob_courierJobsForAgencyJob_responsibleCourierUser;
  agencyJob: CourierJobsForAgencyJob_courierJobsForAgencyJob_agencyJob;
  job: CourierJobsForAgencyJob_courierJobsForAgencyJob_job;
  courierJobAgencyJobChat: CourierJobsForAgencyJob_courierJobsForAgencyJob_courierJobAgencyJobChat | null;
}

export interface CourierJobsForAgencyJob {
  courierJobsForAgencyJob: CourierJobsForAgencyJob_courierJobsForAgencyJob[];
}

export interface CourierJobsForAgencyJobVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CourierJobsCourierOrganizationUsersForAgencyJob
// ====================================================

export interface CourierJobsCourierOrganizationUsersForAgencyJob_courierJobsForAgencyJob_responsibleCourierOrganization_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
}

export interface CourierJobsCourierOrganizationUsersForAgencyJob_courierJobsForAgencyJob_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  organizationUsers: CourierJobsCourierOrganizationUsersForAgencyJob_courierJobsForAgencyJob_responsibleCourierOrganization_organizationUsers[];
}

export interface CourierJobsCourierOrganizationUsersForAgencyJob_courierJobsForAgencyJob {
  __typename: 'CourierJob';
  id: string;
  responsibleCourierOrganization: CourierJobsCourierOrganizationUsersForAgencyJob_courierJobsForAgencyJob_responsibleCourierOrganization;
}

export interface CourierJobsCourierOrganizationUsersForAgencyJob {
  courierJobsForAgencyJob: CourierJobsCourierOrganizationUsersForAgencyJob_courierJobsForAgencyJob[];
}

export interface CourierJobsCourierOrganizationUsersForAgencyJobVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobForAgencyOrganization
// ====================================================

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_startAirport_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_startAirport_locationGeometry {
  __typename: 'LocationGeometry';
  location: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_startAirport_locationGeometry_location;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedAirportAddress: string | null;
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  types: string[]; // JSON Places Geocoding API types
  locationGeometry: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_startAirport_locationGeometry;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_responsibleAgencyOrganizationUsers_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_responsibleAgencyOrganizationUsers_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_responsibleAgencyOrganizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  organization: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_responsibleAgencyOrganizationUsers_organization;
  user: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_responsibleAgencyOrganizationUsers_user;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_endAirport_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_endAirport_locationGeometry {
  __typename: 'LocationGeometry';
  location: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_endAirport_locationGeometry_location;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  types: string[]; // JSON Places Geocoding API types
  locationGeometry: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_endAirport_locationGeometry;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_pickupLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_pickupLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_pickupLocation_locationGeometry_location;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_pickupLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  types: string[]; // JSON Places Geocoding API types
  locationGeometry: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_pickupLocation_locationGeometry;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_dropOffLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_dropOffLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_dropOffLocation_locationGeometry_location;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_dropOffLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  types: string[]; // JSON Places Geocoding API types
  locationGeometry: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_dropOffLocation_locationGeometry;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
  typeOfDelivery: JobTypeOfDelivery; // Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3)
  jobType: JobType; // Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2)
  pickupTime: DateTime; // Local (pickupLocation) pickup time in UTC
  pickupAddressDescription: string | null; // Pickup Address Description
  dropOffTime: DateTime | null; // Local (dropOffLocation) drop-off time in UTC
  dropOffAddressDescription: string | null; // Drop-off Address Description
  numOfItems: number; // The number of items transported in the job
  weightOfItems: number | null; // The total weights of items transported in the job
  descriptionOfItems: string | null; // The description of the items
  description: string | null; // The description of the Job
  transportedGoodsContentType: TransportGoodsContentType; // The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6)
  pickupLocation: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_pickupLocation;
  dropOffLocation: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job_dropOffLocation;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_clientJob {
  __typename: 'ClientJob';
  id: string;
  organizationName: string; // Name of the Client Organization
  organizationLegalName: string | null; // Legal Name of the Client Organization.
  organizationPhone: string | null; // Primary phone number of the Client Organization.
  organizationEmail: string | null; // Primary email address of the Client Organization.
  externalJobReference: string | null; // External Job Reference from the Client
  comment: string | null; // Any comment about the Job from the Client
  fixedRate: number | null; // The fixed rate for the job as proposed by Client.
  currency: Currency; // Currency in which the Client pays the Agency. (EUR=0, USD=1)
  hasIncident: boolean; // Whether an incident has occurred on this Job for the Client
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_courierJobAgencyJobChat {
  __typename: 'Chat';
  id: string;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_sasToken;
  metadata: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture_metadata;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  nationalities: string[] | null; // Nationalities, ISO 3166 Alpha-3 code
  profilePicture: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser_profilePicture | null;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  courierJobStatusType: CourierJobStatusType; // CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  fixedRate: number | null; // The fixed rate for the Job for the Courier payable by Agency
  dailyRate: number | null; // The daily rate for the Job for the Courier payable by Agency
  hotelBudget: number | null; // The hotel budget for the Job for the Courier payable by Agency
  kickOffFee: number | null; // The kick off fee for the Job for the Courier payable by Agency
  isAccommodationBookedByCourier: boolean; // Wether the accommodation is booked by the Courier
  isAccommodationPaidByCourier: boolean; // Whether the accommodation is paid by the Courier
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Courier
  responsibleCourierOrganization: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierOrganization;
  courierJobAgencyJobChat: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_courierJobAgencyJobChat | null;
  responsibleCourierUser: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs_responsibleCourierUser;
}

export interface AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  returnTimeAtStartAirport: DateTime | null; // Local (startAirport) return time in UTC after finishing the job
  checkInTimeAtStartAirport: DateTime | null; // Local (startAirport) latest check-in time in UTC
  returnTimeAtEndAirport: DateTime | null; // Local (endAirport) return time in UTC after finishing the job
  checkInTimeAtEndAirport: DateTime | null; // Local (endAirport) latest check-in time in UTC for the return flight
  externalJobReference: string; // External Job Reference from the Agency
  comment: string | null; // Any comment about the Job from the Agency
  fixedRate: number | null; // The fixed rate for the Job for the Courier as proposed and payable by Agency.
  dailyRate: number | null; // The daily rate for the Job for the Courier as proposed and payable by Agency.
  kickOffFee: number | null; // The kick off fee for the Job for the Courier as proposed and payable by Agency.
  hotelBudget: number | null; // The hotel budget for the Job for the Courier as proposed and payable by Agency.
  currency: Currency; // Currency in which the Agency pays the Courier. (EUR=0, USD=1)
  isAccommodationBookedByCourier: boolean; // Wether the accommodation booked by the Courier as proposed by Agency.
  isAccommodationPaidByCourier: boolean; // Whether the accommodation paid by the Courier as proposed by Agency.
  isAdditionalCostPaidByCourier: boolean; // Whether the additional costs are paid by the Courier
  additionalCostInformation: string | null; // Additional cost information more information details
  isDrivingLicenseRequiredForJob: boolean; // is driving license required for the job
  isCarRequiredForJob: boolean; // is car required for the job
  jobDetails: string | null; // Specific instructions or details provided by the Agency for the Courier
  numOfCouriersRequired: number; // The number of Couriers required to do the job
  hasIncident: boolean; // Whether an incident has occurred for on Job for the Agency
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  internalNotes: string | null; // Internal agency job notes
  startAirport: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_startAirport | null;
  responsibleAgencyOrganizationUsers: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_responsibleAgencyOrganizationUsers[];
  endAirport: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_endAirport | null;
  job: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_job;
  clientJob: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_clientJob;
  courierJobs: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization_courierJobs[] | null;
}

export interface AgencyJobForAgencyOrganization {
  agencyJobForAgencyOrganization: AgencyJobForAgencyOrganization_agencyJobForAgencyOrganization;
}

export interface AgencyJobForAgencyOrganizationVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobForAgencyOrganizationForPlanning
// ====================================================

export interface AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization_startAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedAirportAddress: string | null;
  formattedShortAddress: string | null;
  iataCode: string | null;
}

export interface AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization_endAirport {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedAirportAddress: string | null;
  formattedShortAddress: string | null;
  iataCode: string | null;
}

export interface AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
}

export interface AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  externalJobReference: string; // External Job Reference from the Agency
  startAirport: AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization_startAirport | null;
  endAirport: AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization_endAirport | null;
  job: AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization_job;
}

export interface AgencyJobForAgencyOrganizationForPlanning {
  agencyJobForAgencyOrganization: AgencyJobForAgencyOrganizationForPlanning_agencyJobForAgencyOrganization;
}

export interface AgencyJobForAgencyOrganizationForPlanningVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobForAgencyOrganizationInternalNotes
// ====================================================

export interface AgencyJobForAgencyOrganizationInternalNotes_agencyJobForAgencyOrganization {
  __typename: 'AgencyJob';
  id: string;
  internalNotes: string | null; // Internal agency job notes
}

export interface AgencyJobForAgencyOrganizationInternalNotes {
  agencyJobForAgencyOrganization: AgencyJobForAgencyOrganizationInternalNotes_agencyJobForAgencyOrganization;
}

export interface AgencyJobForAgencyOrganizationInternalNotesVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyCourierJobChat
// ====================================================

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_responsibleCourierOrganization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  createdAt: DateTime;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_responsibleCourierUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  email: string; // Email address
  phone: string | null; // Phone Number
  lastName: string; // Last name
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob_agencyJobChat {
  __typename: 'Chat';
  id: string;
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob_endAirport {
  __typename: 'Location';
  id: string;
  formattedShortAddress: string | null;
  formattedAirportAddress: string | null;
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob_responsibleAgencyOrganization {
  __typename: 'Organization';
  id: string;
  shortName: string | null; // Short name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  startedByAgencyAt: DateTime | null;
  agencyJobChat: AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob_agencyJobChat | null;
  endAirport: AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob_endAirport | null;
  responsibleAgencyOrganization: AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob_responsibleAgencyOrganization;
}

export interface AgencyCourierJobChat_agencyCourierJobChat_courierJob {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  responsibleCourierOrganization: AgencyCourierJobChat_agencyCourierJobChat_courierJob_responsibleCourierOrganization;
  responsibleCourierUser: AgencyCourierJobChat_agencyCourierJobChat_courierJob_responsibleCourierUser;
  job: AgencyCourierJobChat_agencyCourierJobChat_courierJob_job;
  agencyJob: AgencyCourierJobChat_agencyCourierJobChat_courierJob_agencyJob;
}

export interface AgencyCourierJobChat_agencyCourierJobChat {
  __typename: 'Chat';
  id: string;
  inactivatedAt: DateTime | null;
  courierJob: AgencyCourierJobChat_agencyCourierJobChat_courierJob | null; // A singular CourierJob to AgencyJob Chat
}

export interface AgencyCourierJobChat {
  agencyCourierJobChat: AgencyCourierJobChat_agencyCourierJobChat;
}

export interface AgencyCourierJobChatVariables {
  agencyCourierJobChatInput: AgencyCourierJobChatInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobChat
// ====================================================

export interface AgencyJobChat_agencyJobChat_agencyJob_courierJobs_responsibleCourierUser {
  __typename: 'User';
  id: string;
}

export interface AgencyJobChat_agencyJobChat_agencyJob_courierJobs {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  responsibleCourierUser: AgencyJobChat_agencyJobChat_agencyJob_courierJobs_responsibleCourierUser;
}

export interface AgencyJobChat_agencyJobChat_agencyJob_startAirport {
  __typename: 'Location';
  id: string;
  iataCode: string | null;
}

export interface AgencyJobChat_agencyJobChat_agencyJob_endAirport {
  __typename: 'Location';
  id: string;
  iataCode: string | null;
}

export interface AgencyJobChat_agencyJobChat_agencyJob_responsibleAgencyOrganization {
  __typename: 'Organization';
  id: string;
  shortName: string | null; // Short name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AgencyJobChat_agencyJobChat_agencyJob_job {
  __typename: 'Job';
  id: string;
  priojetJobIdentifier: string; // Unique Job Identifier generated by PRIOjet
}

export interface AgencyJobChat_agencyJobChat_agencyJob {
  __typename: 'AgencyJob';
  id: string;
  agencyJobStatusType: AgencyJobStatusType; // AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4)
  externalJobReference: string; // External Job Reference from the Agency
  courierJobs: AgencyJobChat_agencyJobChat_agencyJob_courierJobs[] | null;
  startAirport: AgencyJobChat_agencyJobChat_agencyJob_startAirport | null;
  endAirport: AgencyJobChat_agencyJobChat_agencyJob_endAirport | null;
  responsibleAgencyOrganization: AgencyJobChat_agencyJobChat_agencyJob_responsibleAgencyOrganization;
  job: AgencyJobChat_agencyJobChat_agencyJob_job;
}

export interface AgencyJobChat_agencyJobChat {
  __typename: 'Chat';
  id: string;
  inactivatedAt: DateTime | null;
  agencyJob: AgencyJobChat_agencyJobChat_agencyJob | null; // A singular AgencyJob to AgencyJobChat
}

export interface AgencyJobChat {
  agencyJobChat: AgencyJobChat_agencyJobChat;
}

export interface AgencyJobChatVariables {
  agencyJobChatInput: AgencyJobChatInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChatMessagesByChatId
// ====================================================

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessagesByChatId_chatMessagesByChatId_edges_node_chatFile_sasToken;
  metadata: ChatMessagesByChatId_chatMessagesByChatId_edges_node_chatFile_metadata;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser_profilePicture_sasToken;
  metadata: ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser_profilePicture_metadata;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser_profilePicture | null;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  chatFile: ChatMessagesByChatId_chatMessagesByChatId_edges_node_chatFile | null;
  sendingUser: ChatMessagesByChatId_chatMessagesByChatId_edges_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface ChatMessagesByChatId_chatMessagesByChatId_edges {
  __typename: 'ChatMessageEdge';
  node: ChatMessagesByChatId_chatMessagesByChatId_edges_node;
  cursor: string;
}

export interface ChatMessagesByChatId_chatMessagesByChatId {
  __typename: 'ChatMessageConnection';
  edges: ChatMessagesByChatId_chatMessagesByChatId_edges[] | null;
  totalCount: number;
}

export interface ChatMessagesByChatId {
  chatMessagesByChatId: ChatMessagesByChatId_chatMessagesByChatId;
}

export interface ChatMessagesByChatIdVariables {
  chatMessagesByChatIdInput: ChatMessagesByChatIdInput;
  pagination?: PaginationArgs | null;
  orderBy?: OrderArgs | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChatMessageEdge
// ====================================================

export interface ChatMessageEdge_chatMessageEdge_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessageEdge_chatMessageEdge_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessageEdge_chatMessageEdge_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessageEdge_chatMessageEdge_node_chatFile_sasToken;
  metadata: ChatMessageEdge_chatMessageEdge_node_chatFile_metadata;
}

export interface ChatMessageEdge_chatMessageEdge_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessageEdge_chatMessageEdge_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessageEdge_chatMessageEdge_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessageEdge_chatMessageEdge_node_sendingUser_profilePicture_sasToken;
  metadata: ChatMessageEdge_chatMessageEdge_node_sendingUser_profilePicture_metadata;
}

export interface ChatMessageEdge_chatMessageEdge_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: ChatMessageEdge_chatMessageEdge_node_sendingUser_profilePicture | null;
}

export interface ChatMessageEdge_chatMessageEdge_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  chatFile: ChatMessageEdge_chatMessageEdge_node_chatFile | null;
  sendingUser: ChatMessageEdge_chatMessageEdge_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface ChatMessageEdge_chatMessageEdge {
  __typename: 'ChatMessageCreateEdge';
  node: ChatMessageEdge_chatMessageEdge_node;
  cursor: string;
}

export interface ChatMessageEdge {
  chatMessageEdge: ChatMessageEdge_chatMessageEdge;
}

export interface ChatMessageEdgeVariables {
  chatMessageId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationProfile
// ====================================================

export interface OrganizationProfile_organization_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface OrganizationProfile_organization_headquartersAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface OrganizationProfile_organization_headquartersAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: OrganizationProfile_organization_headquartersAddressLocation_locationGeometry_location;
}

export interface OrganizationProfile_organization_headquartersAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: OrganizationProfile_organization_headquartersAddressLocation_locationGeometry;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface OrganizationProfile_organization_invoicingAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface OrganizationProfile_organization_invoicingAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: OrganizationProfile_organization_invoicingAddressLocation_locationGeometry_location;
}

export interface OrganizationProfile_organization_invoicingAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: OrganizationProfile_organization_invoicingAddressLocation_locationGeometry;
  addressName: string | null;
  apartmentOrSuite: string | null;
  streetName: string | null;
  houseNumber: string | null;
  city: string | null;
  postalCode: string | null;
  state: string | null;
  country: string | null;
}

export interface OrganizationProfile_organization_registrationAddressLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface OrganizationProfile_organization_registrationAddressLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: OrganizationProfile_organization_registrationAddressLocation_locationGeometry_location;
}

export interface OrganizationProfile_organization_registrationAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
  formattedShortAddress: string | null;
  locationGeometry: OrganizationProfile_organization_registrationAddressLocation_locationGeometry;
}

export interface OrganizationProfile_organization_logo_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface OrganizationProfile_organization_logo_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface OrganizationProfile_organization_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: OrganizationProfile_organization_logo_sasToken;
  metadata: OrganizationProfile_organization_logo_metadata;
}

export interface OrganizationProfile_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  shortName: string | null; // Short name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  invoiceEmail: string | null; // Invoice email address of the Organization.
  socialProfiles: OrganizationProfile_organization_socialProfiles[]; // Social profiles
  headquartersAddressLocation: OrganizationProfile_organization_headquartersAddressLocation | null;
  invoicingAddressLocation: OrganizationProfile_organization_invoicingAddressLocation | null;
  registrationAddressLocation: OrganizationProfile_organization_registrationAddressLocation | null;
  logo: OrganizationProfile_organization_logo | null;
}

export interface OrganizationProfile {
  organization: OrganizationProfile_organization;
}

export interface OrganizationProfileVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationOrganizationUsers
// ====================================================

export interface OrganizationOrganizationUsers_organization_organizationUsers_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  phone: string | null; // Phone Number
  createdAt: DateTime;
}

export interface OrganizationOrganizationUsers_organization_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  createdAt: DateTime;
  user: OrganizationOrganizationUsers_organization_organizationUsers_user;
}

export interface OrganizationOrganizationUsers_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  shortName: string | null; // Short name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  organizationUsers: OrganizationOrganizationUsers_organization_organizationUsers[];
}

export interface OrganizationOrganizationUsers {
  organization: OrganizationOrganizationUsers_organization;
}

export interface OrganizationOrganizationUsersVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserById
// ====================================================

export interface UserById_user_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface UserById_user_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface UserById_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: UserById_user_profilePicture_sasToken;
  metadata: UserById_user_profilePicture_metadata;
}

export interface UserById_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: UserById_user_profilePicture | null;
}

export interface UserById {
  user: UserById_user;
}

export interface UserByIdVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MinSupportedAppVersion
// ====================================================

export interface MinSupportedAppVersion_minSupportedAppVersion {
  __typename: 'MinSupportedVersion';
  currentAppVersion: string;
  minSupportedAppVersion: string;
}

export interface MinSupportedAppVersion {
  minSupportedAppVersion: MinSupportedAppVersion_minSupportedAppVersion;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DeletedAccountsPriojetOnly
// ====================================================

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_user {
  __typename: 'User';
  id: string;
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization_organizationUsers_user {
  __typename: 'User';
  id: string;
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  user: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization_organizationUsers_user;
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization {
  __typename: 'Organization';
  id: string;
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  name: string; // Name of the Organization
  phone: string | null; // Primary phone number of the Organization.
  inactivatedAt: DateTime | null;
  logo: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization_logo | null;
  organizationUsers: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization_organizationUsers[];
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  user: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_user;
  organization: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers_organization;
}

export interface DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  dateOfBirth: DateTime | null; // Date of birth
  profilePicture: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_profilePicture | null;
  inactivatedAt: DateTime | null;
  organizationUsers: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly_organizationUsers[];
}

export interface DeletedAccountsPriojetOnly {
  deletedAccountsPriojetOnly: DeletedAccountsPriojetOnly_deletedAccountsPriojetOnly[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: organizationUserById
// ====================================================

export interface organizationUserById_organizationUserById_user_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface organizationUserById_organizationUserById_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: organizationUserById_organizationUserById_user_profilePicture_sasToken;
}

export interface organizationUserById_organizationUserById_user {
  __typename: 'User';
  firstNames: string; // First names
  lastName: string; // Last name
  sex: Gender | null; // Male/Female/Other
  dateOfBirth: DateTime | null; // Date of birth
  placeOfBirth: string | null; // City of birth
  countryOfBirth: string | null; // Country of birth
  language: string | null; // Preferred Language
  email: string; // Email address
  phone: string | null; // Phone Number
  profilePicture: organizationUserById_organizationUserById_user_profilePicture | null;
}

export interface organizationUserById_organizationUserById_organization_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
}

export interface organizationUserById_organizationUserById_organization {
  __typename: 'Organization';
  id: string;
  organizationUsers: organizationUserById_organizationUserById_organization_organizationUsers[];
}

export interface organizationUserById_organizationUserById {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  user: organizationUserById_organizationUserById_user;
  organization: organizationUserById_organizationUserById_organization;
}

export interface organizationUserById {
  organizationUserById: organizationUserById_organizationUserById;
}

export interface organizationUserByIdVariables {
  organizationUserId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subscriptionPlansAgency
// ====================================================

export interface subscriptionPlansAgency_subscriptionPlansAgency_month {
  __typename: 'SubscriptionPlanPaddleType';
  description: JSONObject; // JSON Object of the attributes that are affected
  featureDescription: JSONObject[]; // JSON Object of the attributes that are affected
  name: JSONObject; // JSON Object of the attributes that are affected
  planId: number;
  prices: JSONObject[]; // JSON Object of the attributes that are affected
  recurringInterval: JSONObject; // JSON Object of the attributes that are affected
  trialPeriod: number;
  isMain: boolean;
  subscriptionPlanType: number;
}

export interface subscriptionPlansAgency_subscriptionPlansAgency_year {
  __typename: 'SubscriptionPlanPaddleType';
  description: JSONObject; // JSON Object of the attributes that are affected
  featureDescription: JSONObject[]; // JSON Object of the attributes that are affected
  name: JSONObject; // JSON Object of the attributes that are affected
  planId: number;
  prices: JSONObject[]; // JSON Object of the attributes that are affected
  recurringInterval: JSONObject; // JSON Object of the attributes that are affected
  trialPeriod: number;
  isMain: boolean;
  subscriptionPlanType: number;
}

export interface subscriptionPlansAgency_subscriptionPlansAgency {
  __typename: 'SubscriptionPlanPaddle';
  month: subscriptionPlansAgency_subscriptionPlansAgency_month[];
  year: subscriptionPlansAgency_subscriptionPlansAgency_year[];
}

export interface subscriptionPlansAgency {
  subscriptionPlansAgency: subscriptionPlansAgency_subscriptionPlansAgency;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: subscriptionPlansCourier
// ====================================================

export interface subscriptionPlansCourier_subscriptionPlansCourier_month {
  __typename: 'SubscriptionPlanPaddleType';
  description: JSONObject; // JSON Object of the attributes that are affected
  featureDescription: JSONObject[]; // JSON Object of the attributes that are affected
  name: JSONObject; // JSON Object of the attributes that are affected
  planId: number;
  prices: JSONObject[]; // JSON Object of the attributes that are affected
  recurringInterval: JSONObject; // JSON Object of the attributes that are affected
  trialPeriod: number;
  isMain: boolean;
  subscriptionPlanType: number;
}

export interface subscriptionPlansCourier_subscriptionPlansCourier_year {
  __typename: 'SubscriptionPlanPaddleType';
  description: JSONObject; // JSON Object of the attributes that are affected
  featureDescription: JSONObject[]; // JSON Object of the attributes that are affected
  name: JSONObject; // JSON Object of the attributes that are affected
  planId: number;
  prices: JSONObject[]; // JSON Object of the attributes that are affected
  recurringInterval: JSONObject; // JSON Object of the attributes that are affected
  trialPeriod: number;
  isMain: boolean;
  subscriptionPlanType: number;
}

export interface subscriptionPlansCourier_subscriptionPlansCourier {
  __typename: 'SubscriptionPlanPaddle';
  month: subscriptionPlansCourier_subscriptionPlansCourier_month[];
  year: subscriptionPlansCourier_subscriptionPlansCourier_year[];
}

export interface subscriptionPlansCourier {
  subscriptionPlansCourier: subscriptionPlansCourier_subscriptionPlansCourier;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationSubscriptionPlans
// ====================================================

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_prices {
  __typename: 'SubscriptionPlanPrice';
  currency: string;
  recurringInterval: string;
  value: string;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_subscriptionPlan_planName {
  __typename: 'SubscriptionPlanName';
  key: string;
  defaultValue: string;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures {
  __typename: 'SubscriptionFeature';
  id: string;
  name: string; // name
  count: number; // feature max count
  value: subscriptionFeatureType; // (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_subscriptionPlan {
  __typename: 'SubscriptionPlan';
  id: string;
  planName: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_subscriptionPlan_planName;
  value: SubscriptionPlanType; // (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5)
  subscriptionFeatures:
    | OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_subscriptionPlan_subscriptionFeatures[]
    | null;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
  isActive: boolean;
  startTime: DateTime;
  endTime: DateTime;
  isMain: boolean;
  recurringInterval: string | null; // Recurring interval - month, year
  status: string;
  previousPaymentSubscriptionId: string;
  prices: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_prices[] | null;
  subscriptionPlan: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan_subscriptionPlan;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_prices {
  __typename: 'SubscriptionPlanPrice';
  currency: string;
  recurringInterval: string;
  value: string;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_subscriptionPlan_planName {
  __typename: 'SubscriptionPlanName';
  key: string;
  defaultValue: string;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_subscriptionPlan_subscriptionFeatures {
  __typename: 'SubscriptionFeature';
  id: string;
  name: string; // name
  count: number; // feature max count
  value: subscriptionFeatureType; // (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_subscriptionPlan {
  __typename: 'SubscriptionPlan';
  id: string;
  planName: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_subscriptionPlan_planName;
  value: SubscriptionPlanType; // (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5)
  subscriptionFeatures:
    | OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_subscriptionPlan_subscriptionFeatures[]
    | null;
}

export interface OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans {
  __typename: 'OrganizationSubscriptionPlan';
  id: string;
  isActive: boolean;
  startTime: DateTime;
  endTime: DateTime;
  isMain: boolean;
  recurringInterval: string | null; // Recurring interval - month, year
  status: string;
  prices: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_prices[] | null;
  subscriptionPlan: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans_subscriptionPlan;
}

export interface OrganizationSubscriptionPlans_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  shortName: string | null; // Short name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  organizationSubscriptionPlan: OrganizationSubscriptionPlans_organization_organizationSubscriptionPlan | null;
  organizationSubscriptionPlans:
    | OrganizationSubscriptionPlans_organization_organizationSubscriptionPlans[]
    | null;
}

export interface OrganizationSubscriptionPlans {
  organization: OrganizationSubscriptionPlans_organization;
}

export interface OrganizationSubscriptionPlansVariables {
  id: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AirportLocationListPaginated
// ====================================================

export interface AirportLocationListPaginated_airportLocationListPaginated_edges_node_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AirportLocationListPaginated_airportLocationListPaginated_edges_node_locationGeometry {
  __typename: 'LocationGeometry';
  location: AirportLocationListPaginated_airportLocationListPaginated_edges_node_locationGeometry_location;
}

export interface AirportLocationListPaginated_airportLocationListPaginated_edges_node {
  __typename: 'Location';
  id: string;
  iataCode: string | null;
  formattedAddress: string | null; // The formatted address of the location
  placeId: string | null; // The place_id from the Geocoding API
  locationGeometry: AirportLocationListPaginated_airportLocationListPaginated_edges_node_locationGeometry;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AirportLocationListPaginated_airportLocationListPaginated_edges {
  __typename: 'LocationEdge';
  node: AirportLocationListPaginated_airportLocationListPaginated_edges_node;
}

export interface AirportLocationListPaginated_airportLocationListPaginated {
  __typename: 'AirportLocationConnection';
  totalCount: number;
  edges: AirportLocationListPaginated_airportLocationListPaginated_edges[] | null;
}

export interface AirportLocationListPaginated {
  airportLocationListPaginated: AirportLocationListPaginated_airportLocationListPaginated;
}

export interface AirportLocationListPaginatedVariables {
  data?: AirportLocationSearchInput | null;
  orderBy?: OrderArgs | null;
  pagination?: PaginationArgs | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AirportLocationList
// ====================================================

export interface AirportLocationList_airportLocationList_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AirportLocationList_airportLocationList_locationGeometry {
  __typename: 'LocationGeometry';
  location: AirportLocationList_airportLocationList_locationGeometry_location;
}

export interface AirportLocationList_airportLocationList {
  __typename: 'Location';
  id: string;
  iataCode: string | null;
  formattedAddress: string | null; // The formatted address of the location
  placeId: string | null; // The place_id from the Geocoding API
  locationGeometry: AirportLocationList_airportLocationList_locationGeometry;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AirportLocationList {
  airportLocationList: AirportLocationList_airportLocationList[];
}

export interface AirportLocationListVariables {
  data?: AirportLocationListInput | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AirportLocationSearch
// ====================================================

export interface AirportLocationSearch_airportLocationSearch_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AirportLocationSearch_airportLocationSearch_locationGeometry {
  __typename: 'LocationGeometry';
  location: AirportLocationSearch_airportLocationSearch_locationGeometry_location;
}

export interface AirportLocationSearch_airportLocationSearch {
  __typename: 'Location';
  id: string;
  iataCode: string | null;
  formattedAddress: string | null; // The formatted address of the location
  placeId: string | null; // The place_id from the Geocoding API
  locationGeometry: AirportLocationSearch_airportLocationSearch_locationGeometry;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
}

export interface AirportLocationSearch {
  airportLocationSearch: AirportLocationSearch_airportLocationSearch[];
}

export interface AirportLocationSearchVariables {
  data: AirportLocationSearchInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailabilitiesForConnectedCourierOrganizations
// ====================================================

export interface AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry_location;
}

export interface AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_lastLocation {
  __typename: 'Location';
  id: string;
  timeZoneIdentifier: string | null;
  locationGeometry: AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_lastLocation_locationGeometry;
}

export interface AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
}

export interface AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  createdAt: DateTime;
  lastLocation: AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_lastLocation | null;
  user: AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations_user;
}

export interface AvailabilitiesForConnectedCourierOrganizations {
  availabilitiesForConnectedCourierOrganizations: AvailabilitiesForConnectedCourierOrganizations_availabilitiesForConnectedCourierOrganizations[];
}

export interface AvailabilitiesForConnectedCourierOrganizationsVariables {
  agencyOrganizationId: string;
  filterType?: AvailabilityFilterType[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Availability
// ====================================================

export interface Availability_availability_lastLocation_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface Availability_availability_lastLocation_locationGeometry {
  __typename: 'LocationGeometry';
  location: Availability_availability_lastLocation_locationGeometry_location;
}

export interface Availability_availability_lastLocation {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  timeZoneIdentifier: string | null;
  timeZoneRawOffset: number | null;
  locationGeometry: Availability_availability_lastLocation_locationGeometry;
}

export interface Availability_availability_user_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface Availability_availability_user_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: Availability_availability_user_profilePicture_metadata;
}

export interface Availability_availability_user_courierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface Availability_availability_user_organizationUsers_organization {
  __typename: 'Organization';
  id: string;
}

export interface Availability_availability_user_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  organization: Availability_availability_user_organizationUsers_organization;
}

export interface Availability_availability_user_agencyCourierUserProfile_organizationCourierCategories {
  __typename: 'OrganizationCourierCategory';
  id: string;
  label: string; // Default label
  order: number; // Category order
}

export interface Availability_availability_user_agencyCourierUserProfile {
  __typename: 'AgencyCourierUserProfile';
  internalComment: string | null; // Internal comment about Courier
  markedForAttention: boolean; // Internal comment about Courier
  organizationCourierCategories:
    | Availability_availability_user_agencyCourierUserProfile_organizationCourierCategories[]
    | null;
}

export interface Availability_availability_user {
  __typename: 'User';
  id: string;
  email: string; // Email address
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: Availability_availability_user_profilePicture | null;
  courierUserProfile: Availability_availability_user_courierUserProfile | null;
  organizationUsers: Availability_availability_user_organizationUsers[];
  agencyCourierUserProfile: Availability_availability_user_agencyCourierUserProfile | null;
}

export interface Availability_availability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
  timeZoneDestinationOffset: number | null; // The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime.
  timeZoneRawOffset: number | null; // Raw offset to UTC time in seconds
  createdAt: DateTime;
  lastLocation: Availability_availability_lastLocation | null;
  user: Availability_availability_user;
}

export interface Availability {
  availability: Availability_availability;
}

export interface AvailabilityVariables {
  availabilityId: string;
  agencyOrganizationId?: string | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationCourierCategories
// ====================================================

export interface OrganizationCourierCategories_organizationCourierCategories {
  __typename: 'OrganizationCourierCategory';
  id: string;
  order: number; // Category order
  label: string; // Default label
}

export interface OrganizationCourierCategories {
  organizationCourierCategories: OrganizationCourierCategories_organizationCourierCategories[];
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: organizationInvitationUploadCSV
// ====================================================

export interface organizationInvitationUploadCSV_organizationInvitationUploadCSV_contacts {
  __typename: 'OrganizationInvitationUploadCSVContact';
  firstName: string;
  firstNameError: string | null;
  lastName: string;
  lastNameError: string | null;
  fullName: string;
  email: string;
  emailError: string | null;
  phoneNumber: string;
  phoneNumberError: string | null;
  status: string;
}

export interface organizationInvitationUploadCSV_organizationInvitationUploadCSV {
  __typename: 'OrganizationInvitationUploadCSV';
  contacts: organizationInvitationUploadCSV_organizationInvitationUploadCSV_contacts[];
  organizationInvitationsNotUsed: string[];
  organizationInvitationsUsed: string[];
}

export interface organizationInvitationUploadCSV {
  organizationInvitationUploadCSV: organizationInvitationUploadCSV_organizationInvitationUploadCSV;
}

export interface organizationInvitationUploadCSVVariables {
  data: OrganizationInvitationUploadCSVInput;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: notificationSettingCurrentUser
// ====================================================

export interface notificationSettingCurrentUser_notificationSettingCurrentUser {
  __typename: 'NotificationSetting';
  id: string;
  emailConnection: boolean;
  emailJob: boolean;
  pushAvailability: boolean;
  pushChat: boolean;
  pushConnection: boolean;
  pushJob: boolean;
  systemAvailability: boolean;
  systemChat: boolean;
  systemConnection: boolean;
  systemJob: boolean;
}

export interface notificationSettingCurrentUser {
  notificationSettingCurrentUser: notificationSettingCurrentUser_notificationSettingCurrentUser;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: availabilityFindActiveAdhocCurrentUser
// ====================================================

export interface availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser_lastLocationForDevice_locationGeometry_location {
  __typename: 'LocationGeometryLocation';
  lat: number;
  lng: number;
}

export interface availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser_lastLocationForDevice_locationGeometry {
  __typename: 'LocationGeometry';
  location: availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser_lastLocationForDevice_locationGeometry_location;
}

export interface availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser_lastLocationForDevice {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
  formattedMinimalAddress: string | null;
  locationGeometry: availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser_lastLocationForDevice_locationGeometry;
}

export interface availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser {
  __typename: 'Availability';
  id: string;
  lastLocationForDevice: availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser_lastLocationForDevice | null;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  updatedAt: DateTime;
}

export interface availabilityFindActiveAdhocCurrentUser {
  availabilityFindActiveAdhocCurrentUser: availabilityFindActiveAdhocCurrentUser_availabilityFindActiveAdhocCurrentUser | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableCourierUsersByDistanceForAgencyJobFiltered
// ====================================================

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user_courierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  baseAirports: string[] | null; // Base airports, 3 capital letters
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user_agencyCourierUserProfile_organizationCourierCategories {
  __typename: 'OrganizationCourierCategory';
  id: string;
  order: number; // Category order
  label: string; // Default label
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user_agencyCourierUserProfile {
  __typename: 'AgencyCourierUserProfile';
  internalComment: string | null; // Internal comment about Courier
  markedForAttention: boolean; // Internal comment about Courier
  organizationCourierCategories:
    | AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user_agencyCourierUserProfile_organizationCourierCategories[]
    | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  email: string; // Email address
  phone: string | null; // Phone Number
  courierUserProfile: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user_courierUserProfile | null;
  agencyCourierUserProfile: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user_agencyCourierUserProfile | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_organizationUsers_organization {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  shortName: string | null; // Short name of the Organization
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_organizationUsers_user {
  __typename: 'User';
  id: string;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_organizationUsers {
  __typename: 'OrganizationUser';
  id: string;
  roleType: OrganizationUserRoleType; // Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST)
  organization: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_organizationUsers_organization;
  user: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_organizationUsers_user;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_courierJob {
  __typename: 'CourierJob';
  id: string;
  relationshipType: CourierJobAgencyRelationshipType; // CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5)
  declineReasonType: AgencyDeclinedReasonType | null; // Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0)
  isActive: boolean; // Whether the CourierJob is currently activated for the CourierOrganization
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance_availabilityLocation_availability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance_availabilityLocation_location {
  __typename: 'Location';
  id: string;
  formattedShortAddress: string | null;
  formattedMinimalAddress: string | null;
  formattedAddress: string | null; // The formatted address of the location
  timeZoneIdentifier: string | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance_availabilityLocation {
  __typename: 'AvailabilityLocation';
  id: string;
  timestamp: DateTime | null;
  availability: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance_availabilityLocation_availability;
  location: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance_availabilityLocation_location;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance {
  __typename: 'AvailabilityLocationWithDistance';
  availabilityLocation: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance_availabilityLocation;
  groupType: string | null;
  distance: number;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance_availabilityLocation_availability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance_availabilityLocation_location {
  __typename: 'Location';
  id: string;
  formattedShortAddress: string | null;
  formattedMinimalAddress: string | null;
  formattedAddress: string | null; // The formatted address of the location
  timeZoneIdentifier: string | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance_availabilityLocation {
  __typename: 'AvailabilityLocation';
  id: string;
  timestamp: DateTime | null;
  availability: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance_availabilityLocation_availability;
  location: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance_availabilityLocation_location;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance {
  __typename: 'AvailabilityLocationWithDistance';
  availabilityLocation: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance_availabilityLocation;
  distance: number;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance_availabilityLocation_availability {
  __typename: 'Availability';
  id: string;
  isAdHocAvailability: boolean; // Is AdHoc Availability
  startTime: DateTime; // Start Time
  endTime: DateTime | null; // End Time
  timeZoneIdentifier: string | null; // Intl Time Zone Identifier
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance_availabilityLocation_location {
  __typename: 'Location';
  id: string;
  formattedShortAddress: string | null;
  formattedMinimalAddress: string | null;
  formattedAddress: string | null; // The formatted address of the location
  timeZoneIdentifier: string | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance_availabilityLocation {
  __typename: 'AvailabilityLocation';
  id: string;
  timestamp: DateTime | null;
  availability: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance_availabilityLocation_availability;
  location: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance_availabilityLocation_location;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance {
  __typename: 'AvailabilityLocationWithDistance';
  availabilityLocation: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance_availabilityLocation;
  distance: number;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered {
  __typename: 'AvailableCourierOrganizationUsersWithDistance';
  user: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_user;
  organizationUsers: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_organizationUsers[];
  courierJob: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_courierJob | null;
  availabilityLocationsWithDistance: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_availabilityLocationsWithDistance[];
  displayAvailabilityLocationWithDistance: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_displayAvailabilityLocationWithDistance | null;
  lastAvailabilityLocationWithDistance: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered_lastAvailabilityLocationWithDistance | null;
}

export interface AvailableCourierUsersByDistanceForAgencyJobFiltered {
  availableCourierUsersByDistanceForAgencyJobFiltered: AvailableCourierUsersByDistanceForAgencyJobFiltered_availableCourierUsersByDistanceForAgencyJobFiltered[];
}

export interface AvailableCourierUsersByDistanceForAgencyJobFilteredVariables {
  agencyJobId: string;
  filterType?: JobAvailabilityFilterType[] | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AgencyJobPlanningForAgencyJob
// ====================================================

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_files_storedAsset_metadata;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_files_storedAsset | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems {
  __typename: 'AgencyJobPlanningItem';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  actualDateTimeSetAt: DateTime | null;
  updatedAt: DateTime;
  files: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_files[];
  formType: AgencyJobPlanningFormType;
  inactivatedAt: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  location: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems_location | null;
  name: string; // Name of the Organization
  order: number;
  targetDateTime: DateTime | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_flightTickets_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_flightTickets_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_flightTickets_storedAsset_metadata;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_flightTickets {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_flightTickets_storedAsset | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset_metadata;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_files_storedAsset | null;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems {
  __typename: 'AgencyJobPlanningItem';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  actualDateTimeSetAt: DateTime | null;
  createdAt: DateTime;
  files: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_files[];
  formType: AgencyJobPlanningFormType;
  inactivatedAt: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  location: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems_location | null;
  name: string; // Name of the Organization
  order: number;
  targetDateTime: DateTime | null;
  updatedAt: DateTime;
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs {
  __typename: 'AgencyJobPlanningLeg';
  id: string;
  active: boolean;
  departureDate: DateTime | null;
  flightCarrierDesignation: string | null;
  flightNumber: string | null;
  order: number;
  updatedAt: DateTime;
  flightTickets: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_flightTickets[];
  agencyJobPlanningItems: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs_agencyJobPlanningItems[];
}

export interface AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob {
  __typename: 'AgencyJobPlanning';
  id: string;
  agencyJobPlanningItems: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningItems[];
  agencyJobPlanningLegs: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob_agencyJobPlanningLegs[];
}

export interface AgencyJobPlanningForAgencyJob {
  agencyJobPlanningForAgencyJob: AgencyJobPlanningForAgencyJob_agencyJobPlanningForAgencyJob;
}

export interface AgencyJobPlanningForAgencyJobVariables {
  agencyJobId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChatMessagesByChatIdForAgency
// ====================================================

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg {
  __typename: 'AgencyJobPlanningLeg';
  id: string;
  flightCarrierDesignation: string | null;
  flightNumber: string | null;
  order: number;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  metadata: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_files_storedAsset | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog {
  __typename: 'AgencyJobPlanningItemHistoryLog';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  targetDateTime: DateTime | null;
  note: string | null;
  agencyJobPlanningLeg: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg | null;
  files: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_files[];
  location: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog_location | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_chatFile_sasToken;
  metadata: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_chatFile_metadata;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser_profilePicture_sasToken;
  metadata: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser_profilePicture_metadata;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser_profilePicture | null;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  agencyJobPlanningItemHistoryLog: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_agencyJobPlanningItemHistoryLog | null;
  chatFile: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_chatFile | null;
  sendingUser: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges {
  __typename: 'ChatMessageEdge';
  node: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges_node;
  cursor: string;
}

export interface ChatMessagesByChatIdForAgency_chatMessagesByChatId {
  __typename: 'ChatMessageConnection';
  edges: ChatMessagesByChatIdForAgency_chatMessagesByChatId_edges[] | null;
  totalCount: number;
}

export interface ChatMessagesByChatIdForAgency {
  chatMessagesByChatId: ChatMessagesByChatIdForAgency_chatMessagesByChatId;
}

export interface ChatMessagesByChatIdForAgencyVariables {
  chatMessagesByChatIdInput: ChatMessagesByChatIdInput;
  pagination?: PaginationArgs | null;
  orderBy?: OrderArgs | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ChatMessageEdgeForAgency
// ====================================================

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg {
  __typename: 'AgencyJobPlanningLeg';
  id: string;
  flightCarrierDesignation: string | null;
  flightNumber: string | null;
  order: number;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_files_storedAsset {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  metadata: ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_files_storedAsset_metadata;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_files {
  __typename: 'AgencyJobPlanningFile';
  id: string;
  storedAsset: ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_files_storedAsset | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_location {
  __typename: 'Location';
  id: string;
  formattedAddress: string | null; // The formatted address of the location
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog {
  __typename: 'AgencyJobPlanningItemHistoryLog';
  id: string;
  active: boolean;
  actualDateTime: DateTime | null;
  label: string; // Label of the Courier job planning leg item
  targetDateTime: DateTime | null;
  note: string | null;
  agencyJobPlanningLeg: ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_agencyJobPlanningLeg | null;
  files: ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_files[];
  location: ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog_location | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessageEdgeForAgency_chatMessageEdge_node_chatFile_sasToken;
  metadata: ChatMessageEdgeForAgency_chatMessageEdge_node_chatFile_metadata;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser_profilePicture_sasToken;
  metadata: ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser_profilePicture_metadata;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser_profilePicture | null;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge_node {
  __typename: 'ChatMessage';
  id: string;
  message: string | null; // Message Text
  messageType: MessageType; // MessageType
  agencyJobPlanningItemHistoryLog: ChatMessageEdgeForAgency_chatMessageEdge_node_agencyJobPlanningItemHistoryLog | null;
  chatFile: ChatMessageEdgeForAgency_chatMessageEdge_node_chatFile | null;
  sendingUser: ChatMessageEdgeForAgency_chatMessageEdge_node_sendingUser; // The sender of chat
  createdAt: DateTime;
  updatedAt: DateTime;
}

export interface ChatMessageEdgeForAgency_chatMessageEdge {
  __typename: 'ChatMessageCreateEdge';
  node: ChatMessageEdgeForAgency_chatMessageEdge_node;
  cursor: string;
}

export interface ChatMessageEdgeForAgency {
  chatMessageEdge: ChatMessageEdgeForAgency_chatMessageEdge;
}

export interface ChatMessageEdgeForAgencyVariables {
  chatMessageId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: OnChatMessageSent
// ====================================================

export interface OnChatMessageSent_chatMessageSent_chatFile_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface OnChatMessageSent_chatMessageSent_chatFile_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface OnChatMessageSent_chatMessageSent_chatFile {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: OnChatMessageSent_chatMessageSent_chatFile_sasToken;
  metadata: OnChatMessageSent_chatMessageSent_chatFile_metadata;
}

export interface OnChatMessageSent_chatMessageSent_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface OnChatMessageSent_chatMessageSent_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface OnChatMessageSent_chatMessageSent_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: OnChatMessageSent_chatMessageSent_sendingUser_profilePicture_sasToken;
  metadata: OnChatMessageSent_chatMessageSent_sendingUser_profilePicture_metadata;
}

export interface OnChatMessageSent_chatMessageSent_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: OnChatMessageSent_chatMessageSent_sendingUser_profilePicture | null;
}

export interface OnChatMessageSent_chatMessageSent {
  __typename: 'ChatMessage';
  id: string;
  messageType: MessageType; // MessageType
  createdAt: DateTime;
  message: string | null; // Message Text
  chatFile: OnChatMessageSent_chatMessageSent_chatFile | null;
  sendingUser: OnChatMessageSent_chatMessageSent_sendingUser; // The sender of chat
}

export interface OnChatMessageSent {
  chatMessageSent: OnChatMessageSent_chatMessageSent;
}

export interface OnChatMessageSentVariables {
  chatId: string;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserTravelDocument
// ====================================================

export interface NewUserTravelDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserTravelDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserTravelDocument_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserTravelDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserTravelDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserTravelDocument_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserTravelDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserTravelDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserTravelDocument_documentBackFromAzureCompressed_metadata;
}

export interface NewUserTravelDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserTravelDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserTravelDocument_documentBackFromAzureOriginal_metadata;
}

export interface NewUserTravelDocument {
  __typename: 'UserTravelDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: TravelDocumentType; // documentType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  countryOfIssue: string | null; // country issuing the document
  expiresOn: DateTime | null; // expiry date as per the document
  nationality: string | null; // nationality as on document
  multipleEntriesAllowed: boolean | null; // are multiple entries possible using this document
  documentFrontFromAzureCompressed: NewUserTravelDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserTravelDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserTravelDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserTravelDocument_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserIdentityDocument
// ====================================================

export interface NewUserIdentityDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserIdentityDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserIdentityDocument_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserIdentityDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserIdentityDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserIdentityDocument_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserIdentityDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserIdentityDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserIdentityDocument_documentBackFromAzureCompressed_metadata;
}

export interface NewUserIdentityDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserIdentityDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserIdentityDocument_documentBackFromAzureOriginal_metadata;
}

export interface NewUserIdentityDocument {
  __typename: 'UserIdentityDocument';
  id: string;
  firstNames: string | null; // First names and middle names  as per the document
  lastName: string | null; // Last name as per the document
  placeOfBirth: string | null; // Place of birth as per the document
  sex: Gender | null; // sex/gender as per the document
  dateOfBirth: DateTime | null; // Date of Birth as per the document
  documentType: IdentityDocumentType; // IdentityDocumentType
  documentSubType: IdentityDocumentSubType | null; // IdentityDocumentSubType
  documentSubTypeMulti: IdentityDocumentSubType[] | null; // IdentityDocumentSubType
  documentNumber: string | null; // document number as per the document
  dateOfIssue: DateTime | null; // issued date as per the document
  countryOfIssue: string | null; // country issuing the document
  expiresOn: DateTime | null; // expiry date as per the document
  nationality: string | null; // Nationality as per the document ISO 3166-1 alpha-3 code
  documentFrontFromAzureCompressed: NewUserIdentityDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserIdentityDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserIdentityDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserIdentityDocument_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserFrequentFlyerDocument
// ====================================================

export interface NewUserFrequentFlyerDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserFrequentFlyerDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserFrequentFlyerDocument_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserFrequentFlyerDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserFrequentFlyerDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserFrequentFlyerDocument_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserFrequentFlyerDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserFrequentFlyerDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserFrequentFlyerDocument_documentBackFromAzureCompressed_metadata;
}

export interface NewUserFrequentFlyerDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserFrequentFlyerDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserFrequentFlyerDocument_documentBackFromAzureOriginal_metadata;
}

export interface NewUserFrequentFlyerDocument {
  __typename: 'UserFrequentFlyerDocument';
  id: string;
  airlineType: AirlineType | null; // Frequent Flyer Airline
  airlineStatusType: AirlineStatusType | null; // Status of the airline
  airlineAllianceType: AirlineAllianceType | null; // Airline Alliance
  airlineAllianceStatusType: AirlineAllianceStatusType | null; // Airline Alliance Status
  documentNumber: string | null; // document number as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: NewUserFrequentFlyerDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserFrequentFlyerDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserFrequentFlyerDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserFrequentFlyerDocument_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserVaccinationDocument
// ====================================================

export interface NewUserVaccinationDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserVaccinationDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserVaccinationDocument_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserVaccinationDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserVaccinationDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserVaccinationDocument_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserVaccinationDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserVaccinationDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserVaccinationDocument_documentBackFromAzureCompressed_metadata;
}

export interface NewUserVaccinationDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserVaccinationDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserVaccinationDocument_documentBackFromAzureOriginal_metadata;
}

export interface NewUserVaccinationDocument {
  __typename: 'UserVaccinationDocument';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that vaccination is for
  description: string | null; // Description of Vaccine
  vaccinationType: string | null; // Vaccination Document Type/Method (example SARS-CoV-mRNA for COVID19
  vaccinationManufacturer: string | null; // Vaccination Manufacturer (example PFIZER-BIOnTech for COVID19
  dateOfVaccination: DateTime | null; // Date of Vaccination as per the document
  isVaccinationComplete: boolean | null; // is vaccination complete
  vaccinationCenter: string | null; // Name of Vaccination Centre as per the document
  cityOfVaccination: string | null; // City of Vaccination as per the document
  countryOfVaccination: string | null; // country of vaccination
  expiresOn: DateTime | null; // expiry date as per the document
  documentNumber: string | null; // document number as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: NewUserVaccinationDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserVaccinationDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserVaccinationDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserVaccinationDocument_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserHealthReport
// ====================================================

export interface NewUserHealthReport_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserHealthReport_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserHealthReport_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserHealthReport_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserHealthReport_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserHealthReport_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserHealthReport_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserHealthReport_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserHealthReport_documentBackFromAzureCompressed_metadata;
}

export interface NewUserHealthReport_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserHealthReport_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserHealthReport_documentBackFromAzureOriginal_metadata;
}

export interface NewUserHealthReport {
  __typename: 'UserHealthReport';
  id: string;
  diseaseType: VaccinationTargetDisease | null; // Disease that test is for
  description: string | null; // Description of test
  testType: HealthTestType | null; // Test Document Type/Method PCR/ Rapid/ Antigen/ PCR-Rapid for COVID 19
  dateTimeOfTest: DateTime | null; // Date of Test as per the document
  isResultPositive: boolean | null; // is test complete
  testCenter: string | null; // Name of Test Centre as per the document
  cityOfTest: string | null; // City of Test as per the document
  countryOfTest: string | null; // country of test
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: NewUserHealthReport_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserHealthReport_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserHealthReport_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserHealthReport_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserBackgroundDocument
// ====================================================

export interface NewUserBackgroundDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserBackgroundDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserBackgroundDocument_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserBackgroundDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserBackgroundDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserBackgroundDocument_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserBackgroundDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserBackgroundDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserBackgroundDocument_documentBackFromAzureCompressed_metadata;
}

export interface NewUserBackgroundDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserBackgroundDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserBackgroundDocument_documentBackFromAzureOriginal_metadata;
}

export interface NewUserBackgroundDocument {
  __typename: 'UserBackgroundDocument';
  id: string;
  name: string | null; // Name of the document
  issuingInstitution: string | null; // Name of the the institution issuing the document
  countryOfIssue: string | null; // country issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: NewUserBackgroundDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserBackgroundDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserBackgroundDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserBackgroundDocument_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewUserOtherDocument
// ====================================================

export interface NewUserOtherDocument_documentFrontFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserOtherDocument_documentFrontFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserOtherDocument_documentFrontFromAzureCompressed_metadata;
}

export interface NewUserOtherDocument_documentFrontFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserOtherDocument_documentFrontFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserOtherDocument_documentFrontFromAzureOriginal_metadata;
}

export interface NewUserOtherDocument_documentBackFromAzureCompressed_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserOtherDocument_documentBackFromAzureCompressed {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserOtherDocument_documentBackFromAzureCompressed_metadata;
}

export interface NewUserOtherDocument_documentBackFromAzureOriginal_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewUserOtherDocument_documentBackFromAzureOriginal {
  __typename: 'StoredAsset';
  sasUrl: string;
  metadata: NewUserOtherDocument_documentBackFromAzureOriginal_metadata;
}

export interface NewUserOtherDocument {
  __typename: 'UserOtherDocument';
  id: string;
  name: string | null; // Name of the document
  description: string | null; // Description of the document
  countryOfIssue: string | null; // country issuing the document
  placeOfIssue: string | null; // place/city issuing the document
  dateOfIssue: DateTime | null; // issued date as per the document
  expiresOn: DateTime | null; // expiry date as per the document
  documentContainerName: string | null; // container name in azure storage
  originalDocumentFrontFilePath: string | null; // original front page blob name in azure storage
  originalDocumentBackFilePath: string | null; // original back page blob name in azure storage
  compressedDocumentFrontFilePath: string | null; // compressed front page blob name in azure storage
  compressedDocumentBackFilePath: string | null; // compressed back page blob name in azure storage
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn: DateTime | null; // The date of verification of the document
  verificationStatus: DocumentVerificationStatus | null; // DocumentVerificationStatus
  verificationComments: string | null; // verification comments
  documentFrontFromAzureCompressed: NewUserOtherDocument_documentFrontFromAzureCompressed | null;
  documentFrontFromAzureOriginal: NewUserOtherDocument_documentFrontFromAzureOriginal | null;
  documentBackFromAzureCompressed: NewUserOtherDocument_documentBackFromAzureCompressed | null;
  documentBackFromAzureOriginal: NewUserOtherDocument_documentBackFromAzureOriginal | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewChatMessage
// ====================================================

export interface NewChatMessage_sendingUser_profilePicture_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface NewChatMessage_sendingUser_profilePicture_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewChatMessage_sendingUser_profilePicture {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: NewChatMessage_sendingUser_profilePicture_sasToken;
  metadata: NewChatMessage_sendingUser_profilePicture_metadata;
}

export interface NewChatMessage_sendingUser {
  __typename: 'User';
  id: string;
  firstNames: string; // First names
  lastName: string; // Last name
  profilePicture: NewChatMessage_sendingUser_profilePicture | null;
}

export interface NewChatMessage {
  __typename: 'ChatMessage';
  id: string;
  messageType: MessageType; // MessageType
  createdAt: DateTime;
  message: string | null; // Message Text
  sendingUser: NewChatMessage_sendingUser; // The sender of chat
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewAgencyOrganizationProfile
// ====================================================

export interface NewAgencyOrganizationProfile_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface NewAgencyOrganizationProfile_headquartersAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
}

export interface NewAgencyOrganizationProfile_invoicingAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
}

export interface NewAgencyOrganizationProfile_registrationAddressLocation {
  __typename: 'Location';
  id: string;
  placeId: string | null; // The place_id from the Geocoding API
  formattedAddress: string | null; // The formatted address of the location
}

export interface NewAgencyOrganizationProfile_logo_sasToken {
  __typename: 'SasTokenResponse';
  version: string | null;
  signature: string | null;
  expiresOn: string | null;
  permissions: string | null;
  resource: string | null;
}

export interface NewAgencyOrganizationProfile_logo_metadata {
  __typename: 'StoredAssetMetadata';
  fileName: string | null;
  mimeType: string | null;
  height: number | null;
  width: number | null;
  size: number | null;
}

export interface NewAgencyOrganizationProfile_logo {
  __typename: 'StoredAsset';
  sasUrl: string;
  cacheKey: string;
  cacheTTL: DateTime | null;
  sasToken: NewAgencyOrganizationProfile_logo_sasToken;
  metadata: NewAgencyOrganizationProfile_logo_metadata;
}

export interface NewAgencyOrganizationProfile {
  __typename: 'Organization';
  id: string;
  name: string; // Name of the Organization
  legalName: string; // Legal Name of the Organization. For private individuals it's most of the times their name.
  organizationType: OrganizationType; // The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
  description: string | null; // Description and intro text of the Organization.
  email: string | null; // Primary email address of the Organization.
  legalEntityType: string | null; // Legal type of the organization or business entity. Can be blank.
  phone: string | null; // Primary phone number of the Organization.
  vatId: string | null; // VAT ID usually for Agency Organizations
  registrationNumber: string | null; // Registration number of the Organization.
  socialProfiles: NewAgencyOrganizationProfile_socialProfiles[]; // Social profiles
  headquartersAddressLocation: NewAgencyOrganizationProfile_headquartersAddressLocation | null;
  invoicingAddressLocation: NewAgencyOrganizationProfile_invoicingAddressLocation | null;
  registrationAddressLocation: NewAgencyOrganizationProfile_registrationAddressLocation | null;
  logo: NewAgencyOrganizationProfile_logo | null;
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewCourierUserProfile
// ====================================================

export interface NewCourierUserProfile_languagesKnown {
  __typename: 'TLanguageKnown';
  language: string;
  level: LanguageSkillLevel;
}

export interface NewCourierUserProfile_socialProfiles {
  __typename: 'SocialProfile';
  platform: string;
  type: string;
  value: string;
}

export interface NewCourierUserProfile {
  __typename: 'CourierUserProfile';
  id: string;
  emergencyContactRelationship: string | null; // Emergency Contact Relationship
  emergencyContactPhone: string | null; // Emergency Contact Phone Number
  emergencyContactName: string | null; // Emergency Contact Name
  emergencyContactEmail: string | null; // Emergency Contact Email
  emergencyContactAddress: string | null; // Emergency Contact Address
  hasAmexCreditCard: boolean | null; // American Express Credit Card
  hasDinerCreditCard: boolean | null; // Diner's Credit Card
  hasMasterCreditCard: boolean | null; // Master Credit Card
  hasVisaCreditCard: boolean | null; // Visa Credit Card
  hasAmexDebitCard: boolean | null; // American Express debit/prepaid Card
  hasDinerDebitCard: boolean | null; // Diner's debit/prepaid Card
  hasMasterDebitCard: boolean | null; // Master Debit/Prepaid Card
  hasVisaDebitCard: boolean | null; // Visa Debit/Prepaid Card
  masterCreditCardLimit: number | null; // Master Credit Card Limit
  visaCreditCardLimit: number | null; // Visa Credit Card Limit
  dinerCreditCardLimit: number | null; // Diner's Credit Card Limit
  amexCreditCardLimit: number | null; // American Express Credit Card Limit
  languagesKnown: NewCourierUserProfile_languagesKnown[] | null; // Languages known
  onboardExperiences: string[] | null; // On Board Experiences
  address: string | null; // City of birth
  street: string | null; // Street
  apartmentOrSuite: string | null; // Apartment/Suite/Other address attributes
  city: string | null; // City
  zipcode: string | null; // Zip Code
  country: string | null; // Country of residence, ISO 3166 Alpha-3 code
  socialProfiles: NewCourierUserProfile_socialProfiles[]; // Social profiles
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

// The Gender of the User
export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

// The category type of the Travel Document.
export enum TravelDocumentType {
  FIKTIONSBESCHEINIGUNG = 'FIKTIONSBESCHEINIGUNG',
  VISA = 'VISA',
}

// The category type of the Identity Document.
export enum IdentityDocumentType {
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  NATIONAL_ID = 'NATIONAL_ID',
}

// The subcategory type of the Travel Document.
export enum IdentityDocumentSubType {
  ALIENS_PASSPORT = 'ALIENS_PASSPORT',
  APEC_CARD = 'APEC_CARD',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  BRITISH_CITIZEN = 'BRITISH_CITIZEN',
  BRITISH_DEPENDENT_TERRITORIES_CITIZEN = 'BRITISH_DEPENDENT_TERRITORIES_CITIZEN',
  BRITISH_NATIONAL_OVERSEAS = 'BRITISH_NATIONAL_OVERSEAS',
  BRITISH_OVERSEAS_TERRITORIES_CITIZEN = 'BRITISH_OVERSEAS_TERRITORIES_CITIZEN',
  BRITISH_PROTECTED_PERSON = 'BRITISH_PROTECTED_PERSON',
  BRITISH_SUBJECT = 'BRITISH_SUBJECT',
  BRITSH_OVERSEAS_CITIZEN = 'BRITSH_OVERSEAS_CITIZEN',
  CAR_LICENSE = 'CAR_LICENSE',
  CDB = 'CDB',
  CERTIFICATEOF_CITIZENSHIP = 'CERTIFICATEOF_CITIZENSHIP',
  CERTIFICATEOF_IDENTITY = 'CERTIFICATEOF_IDENTITY',
  CERTIFICATEOF_NATURALIZATION = 'CERTIFICATEOF_NATURALIZATION',
  CNEXITAND_ENTRY_PERMIT = 'CNEXITAND_ENTRY_PERMIT',
  CNONE_WAY_PERMIT = 'CNONE_WAY_PERMIT',
  CONSULAR_PASSPORT = 'CONSULAR_PASSPORT',
  CREW_GENERAL_DECLARATION_FORM = 'CREW_GENERAL_DECLARATION_FORM',
  CREW_MEMBER_CERTIFICATE = 'CREW_MEMBER_CERTIFICATE',
  CREW_MEMBER_IDCARD = 'CREW_MEMBER_IDCARD',
  CREW_MEMBER_LICENCE = 'CREW_MEMBER_LICENCE',
  CTD1951 = 'CTD1951',
  CTD1954 = 'CTD1954',
  DIPLOMATIC_PASSPORT = 'DIPLOMATIC_PASSPORT',
  DOCUMENTOF_IDENTITY = 'DOCUMENTOF_IDENTITY',
  EEPTW = 'EEPTW',
  EMERGENCY_PASSPORT = 'EMERGENCY_PASSPORT',
  FORM_I327 = 'FORM_I327',
  FORM_I512 = 'FORM_I512',
  FORM_I571 = 'FORM_I571',
  GRPOLICE_ID = 'GRPOLICE_ID',
  HONGKONG_SAR_CHINA_PASSPORT = 'HONGKONG_SAR_CHINA_PASSPORT',
  HUI_XIANG_ZHENG = 'HUI_XIANG_ZHENG',
  ICRCTD = 'ICRCTD',
  INTERPOL_IDCARD = 'INTERPOL_IDCARD',
  INTERPOL_PASSPORT = 'INTERPOL_PASSPORT',
  LAISSEZ_PASSER = 'LAISSEZ_PASSER',
  LU_BAO_ZHENG = 'LU_BAO_ZHENG',
  LU_XING_ZHENG = 'LU_XING_ZHENG',
  MACAO_SAR_CHINA_PASSPORT = 'MACAO_SAR_CHINA_PASSPORT',
  MILITARY_IDENTITY_DOCUMENT = 'MILITARY_IDENTITY_DOCUMENT',
  MILITARY_ID_CARD = 'MILITARY_ID_CARD',
  NATIONAL_ID_CARD = 'NATIONAL_ID_CARD',
  NEXUS_CARD = 'NEXUS_CARD',
  NONE = 'NONE',
  NOTARIZED_AFFIDAVITOF_CITIZENSHIP = 'NOTARIZED_AFFIDAVITOF_CITIZENSHIP',
  OASOFFICIAL_TRAVEL_DOCUMENT = 'OASOFFICIAL_TRAVEL_DOCUMENT',
  OFFICIAL_PASSPORT = 'OFFICIAL_PASSPORT',
  OFFICIAL_PHOTO_ID = 'OFFICIAL_PHOTO_ID',
  OTHER_LICENSE = 'OTHER_LICENSE',
  PASSPORT = 'PASSPORT',
  PASSPORT_CARD = 'PASSPORT_CARD',
  PSTD = 'PSTD',
  PUBLIC_AFFAIRS_HK_MO_TRAVEL_PERMIT = 'PUBLIC_AFFAIRS_HK_MO_TRAVEL_PERMIT',
  SEAMAN_BOOK = 'SEAMAN_BOOK',
  SERVICE_PASSPORT = 'SERVICE_PASSPORT',
  SPECIAL_PASSPORT = 'SPECIAL_PASSPORT',
  TAI_BAO_ZHENG = 'TAI_BAO_ZHENG',
  TEMPORARY_PASSPORT = 'TEMPORARY_PASSPORT',
  TEMPORARY_TRAVEL_DOCUMENT = 'TEMPORARY_TRAVEL_DOCUMENT',
  TITREDE_VOYAGE = 'TITREDE_VOYAGE',
  TRANSPORTATION_LETTER = 'TRANSPORTATION_LETTER',
  TRAVEL_CERTIFICATE = 'TRAVEL_CERTIFICATE',
  TRAVEL_PERMIT = 'TRAVEL_PERMIT',
  TRAVEL_PERMIT_HKMO = 'TRAVEL_PERMIT_HKMO',
  TRUCK_LICENSE = 'TRUCK_LICENSE',
  UNMIKTRAVEL_DOCUMENT = 'UNMIKTRAVEL_DOCUMENT',
  UN_LAISSEZ_PASSER = 'UN_LAISSEZ_PASSER',
  VISIT_PERMIT_FOR_RESIDENTS_OF_MACAOTO_HKSAR = 'VISIT_PERMIT_FOR_RESIDENTS_OF_MACAOTO_HKSAR',
  VOTERS_REGISTRATION_CARD = 'VOTERS_REGISTRATION_CARD',
}

// The type of Airline.
export enum AirlineType {
  A3 = 'A3',
  AA = 'AA',
  AC = 'AC',
  AF = 'AF',
  AI = 'AI',
  AM = 'AM',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AV = 'AV',
  AY = 'AY',
  AZ = 'AZ',
  B6 = 'B6',
  BA = 'BA',
  BR = 'BR',
  CA = 'CA',
  CI = 'CI',
  CM = 'CM',
  CX = 'CX',
  DL = 'DL',
  EI = 'EI',
  EK = 'EK',
  EN = 'EN',
  ET = 'ET',
  EY = 'EY',
  GA = 'GA',
  IB = 'IB',
  JL = 'JL',
  KE = 'KE',
  KL = 'KL',
  KQ = 'KQ',
  LH = 'LH',
  LO = 'LO',
  LX = 'LX',
  ME = 'ME',
  MF = 'MF',
  MH = 'MH',
  MS = 'MS',
  MU = 'MU',
  NH = 'NH',
  NZ = 'NZ',
  OK = 'OK',
  OS = 'OS',
  OU = 'OU',
  OZ = 'OZ',
  PR = 'PR',
  QF = 'QF',
  QR = 'QR',
  RJ = 'RJ',
  RO = 'RO',
  S7 = 'S7',
  SA = 'SA',
  SK = 'SK',
  SN = 'SN',
  SQ = 'SQ',
  SU = 'SU',
  SV = 'SV',
  TG = 'TG',
  TK = 'TK',
  TP = 'TP',
  TU = 'TU',
  UA = 'UA',
  UL = 'UL',
  UX = 'UX',
  VN = 'VN',
  WN = 'WN',
  ZH = 'ZH',
}

// The type of Airline Status.
export enum AirlineStatusType {
  ALIST = 'ALIST',
  ALIST_PREFERRED = 'ALIST_PREFERRED',
  AMBASSADOR = 'AMBASSADOR',
  ANA_MILLION_MILER = 'ANA_MILLION_MILER',
  BASIC = 'BASIC',
  BLUE = 'BLUE',
  BRONZE = 'BRONZE',
  BURGUNDY = 'BURGUNDY',
  CLASSIC = 'CLASSIC',
  CLASSIC_PLUS = 'CLASSIC_PLUS',
  CONCIERGE = 'CONCIERGE',
  CONCIERGE_KEY = 'CONCIERGE_KEY',
  DIAMOND = 'DIAMOND',
  DIAMOND_MEDALLION = 'DIAMOND_MEDALLION',
  DIAMOND_PLUS = 'DIAMOND_PLUS',
  DYNASTY = 'DYNASTY',
  ELITE = 'ELITE',
  ELITE_GOLD = 'ELITE_GOLD',
  ELITE_PLUS = 'ELITE_PLUS',
  ELITE_SILVER = 'ELITE_SILVER',
  EMERALD = 'EMERALD',
  EXECUTIVE_PLATINUM = 'EXECUTIVE_PLATINUM',
  EXPLORER = 'EXPLORER',
  FRECCIA_ALATA_CLUB = 'FRECCIA_ALATA_CLUB',
  FRECCIA_ALATA_PLUS_CLUB = 'FRECCIA_ALATA_PLUS_CLUB',
  FRECCIA_ALATA_PLUS_PER_SEMPRE_CARD = 'FRECCIA_ALATA_PLUS_PER_SEMPRE_CARD',
  FREQUENT_TRAVELLER = 'FREQUENT_TRAVELLER',
  GLOBAL_SERVICES = 'GLOBAL_SERVICES',
  GOLD = 'GOLD',
  GOLDEN_EDGE_CLUB = 'GOLDEN_EDGE_CLUB',
  GOLD_MEDALLION = 'GOLD_MEDALLION',
  GOLD_SPARROW = 'GOLD_SPARROW',
  GREEN = 'GREEN',
  HON_CIRCLE = 'HON_CIRCLE',
  JGC_PREMIER = 'JGC_PREMIER',
  JMB_CRYSTAL = 'JMB_CRYSTAL',
  JMB_DIAMOND = 'JMB_DIAMOND',
  JMB_SAPPHIRE = 'JMB_SAPPHIRE',
  K1 = 'K1',
  K25 = 'K25',
  K35 = 'K35',
  K50 = 'K50',
  K75 = 'K75',
  KRISFLYER = 'KRISFLYER',
  MEMBER = 'MEMBER',
  MILLION_MILER_CLUB = 'MILLION_MILER_CLUB',
  MORNING_CALM_CLUB = 'MORNING_CALM_CLUB',
  MORNING_CALM_PREMIUM_CLUB = 'MORNING_CALM_PREMIUM_CLUB',
  MOSAIC = 'MOSAIC',
  MVP = 'MVP',
  MVP_GOLD = 'MVP_GOLD',
  MVP_GOLD_75 = 'MVP_GOLD_75',
  OK_PLUS_GOLD = 'OK_PLUS_GOLD',
  OK_PLUS_PLATINUM = 'OK_PLUS_PLATINUM',
  OK_PLUS_SILVER = 'OK_PLUS_SILVER',
  ONE = 'ONE',
  PARAGON = 'PARAGON',
  PLATINUM = 'PLATINUM',
  PLATINUM_HAWK = 'PLATINUM_HAWK',
  PLATINUM_LUMO = 'PLATINUM_LUMO',
  PLATINUM_MEDALLION = 'PLATINUM_MEDALLION',
  PLATINUM_ONE = 'PLATINUM_ONE',
  PLATINUM_PRO = 'PLATINUM_PRO',
  PREMIER_ELITE = 'PREMIER_ELITE',
  PRESIDENTS_CLUB = 'PRESIDENTS_CLUB',
  RED_PLUS = 'RED_PLUS',
  RESIDENTIAL = 'RESIDENTIAL',
  S7_PRIORITY_GOLD = 'S7_PRIORITY_GOLD',
  S7_PRIORITY_PLATINUM = 'S7_PRIORITY_PLATINUM',
  S7_PRIORITY_SILVER = 'S7_PRIORITY_SILVER',
  SENATOR = 'SENATOR',
  SILVER = 'SILVER',
  SILVER_EDGE_CLUB = 'SILVER_EDGE_CLUB',
  SILVER_JAY = 'SILVER_JAY',
  SILVER_MEDALLION = 'SILVER_MEDALLION',
  SKYPASS = 'SKYPASS',
  SUMA_GOLD = 'SUMA_GOLD',
  SUMA_PLATINUM = 'SUMA_PLATINUM',
  SUMA_SILVER = 'SUMA_SILVER',
  SUPER_ELITE = 'SUPER_ELITE',
  SUPER_FLYER = 'SUPER_FLYER',
  THE_MAHARAJAH_CLUB = 'THE_MAHARAJAH_CLUB',
  TITANIUM = 'TITANIUM',
  ULISSE = 'ULISSE',
}

// The type of Airline Alliance.
export enum AirlineAllianceType {
  NONE = 'NONE',
  ONE_WORLD = 'ONE_WORLD',
  SKY_TEAM = 'SKY_TEAM',
  STAR_ALLIANCE = 'STAR_ALLIANCE',
}

// The type of Airline Alliance Status.
export enum AirlineAllianceStatusType {
  NONE = 'NONE',
  ONEWORLD_EMERALD = 'ONEWORLD_EMERALD',
  ONEWORLD_RUBY = 'ONEWORLD_RUBY',
  ONEWORLD_SAPPHIRE = 'ONEWORLD_SAPPHIRE',
  SKYTEAM_ELITE = 'SKYTEAM_ELITE',
  SKYTEAM_ELITE_PLUS = 'SKYTEAM_ELITE_PLUS',
  STAR_ALLIANCE_GOLD = 'STAR_ALLIANCE_GOLD',
  STAR_ALLIANCE_SILVER = 'STAR_ALLIANCE_SILVER',
}

// The state of verification process
export enum DocumentVerificationStatus {
  FULLY_VERIFIED = 'FULLY_VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  PARTIALLY_VERIFIED = 'PARTIALLY_VERIFIED',
  REJECTED = 'REJECTED',
}

// The disease targeted by Vaccine
export enum VaccinationTargetDisease {
  CHOLERA = 'CHOLERA',
  COVID19 = 'COVID19',
  DENGUE = 'DENGUE',
  DIPHTHERIA = 'DIPHTHERIA',
  HEPATITIS_A = 'HEPATITIS_A',
  HEPATITIS_B = 'HEPATITIS_B',
  HEPATITIS_E = 'HEPATITIS_E',
  HIB = 'HIB',
  HPV = 'HPV',
  INFLUENZA = 'INFLUENZA',
  JAPANESE_ENCEPHALITIS = 'JAPANESE_ENCEPHALITIS',
  MALARIA = 'MALARIA',
  MEASLES = 'MEASLES',
  MENINGITIS = 'MENINGITIS',
  MUMPS = 'MUMPS',
  PERTUSSIS = 'PERTUSSIS',
  PNEUMOCOCCAL_DISEASE = 'PNEUMOCOCCAL_DISEASE',
  POLIO = 'POLIO',
  RABIES = 'RABIES',
  ROTAVIRUS = 'ROTAVIRUS',
  RUBELLA = 'RUBELLA',
  TB = 'TB',
  TETANUS = 'TETANUS',
  TICK_BORNE_ENCEPHALITIS = 'TICK_BORNE_ENCEPHALITIS',
  TYPHOID = 'TYPHOID',
  VARICELLA = 'VARICELLA',
  YELLOW_FEVER = 'YELLOW_FEVER',
}

// The category types of the Health Tests
export enum HealthTestType {
  ANTIGEN = 'ANTIGEN',
  PCR = 'PCR',
  PCR_RAPID = 'PCR_RAPID',
  RAPID = 'RAPID',
}

// The type of message text, file, location.
export enum MessageType {
  FILE = 'FILE',
  LOCATION = 'LOCATION',
  TEXT = 'TEXT',
  TRACKER_COURIER_JOINED = 'TRACKER_COURIER_JOINED',
  TRACKER_COURIER_LEFT = 'TRACKER_COURIER_LEFT',
  TRACKER_COURIER_TRACKING_POINT_COMPLETED = 'TRACKER_COURIER_TRACKING_POINT_COMPLETED',
  TRACKER_COURIER_TRACKING_POINT_RESET = 'TRACKER_COURIER_TRACKING_POINT_RESET',
  TRACKER_FLIGHT_DETAILS_CREATED = 'TRACKER_FLIGHT_DETAILS_CREATED',
  TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE = 'TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE',
  TRACKER_FLIGHT_DETAILS_REMOVED = 'TRACKER_FLIGHT_DETAILS_REMOVED',
  TRACKER_FLIGHT_DETAILS_UPDATED = 'TRACKER_FLIGHT_DETAILS_UPDATED',
}

// The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5)
export enum OrganizationType {
  AGENCY = 'AGENCY',
  CLIENT = 'CLIENT',
  COURIER = 'COURIER',
  PRIOJET = 'PRIOJET',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  TRAVELER = 'TRAVELER',
}

// The level of a language skill
export enum LanguageSkillLevel {
  ADVANCED = 'ADVANCED',
  BASIC = 'BASIC',
  NATIVE = 'NATIVE',
}

// The type of the Organization to Organization connection.
export enum OrganizationOrganizationConnectionType {
  COURIER_AGENCY = 'COURIER_AGENCY',
  CUSTOMER_AGENCY = 'CUSTOMER_AGENCY',
}

// (UNKNOWN=0, UNPLUGGED=1, CHARGING=2, FULL=3)
export enum DeviceLogBatteryStateType {
  CHARGING = 'CHARGING',
  FULL = 'FULL',
  UNKNOWN = 'UNKNOWN',
  UNPLUGGED = 'UNPLUGGED',
}

// NetworkStateType of the clients network connection.
export enum DeviceLogNetworkStateType {
  BLUETOOTH = 'BLUETOOTH',
  CELLULAR = 'CELLULAR',
  ETHERNET = 'ETHERNET',
  NONE = 'NONE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
  VPN = 'VPN',
  WIFI = 'WIFI',
  WIMAX = 'WIMAX',
}

// Type of the internet access point (private/residential, business/company, education/university).
export enum DeviceLogTriggerType {
  ACCOUNT_SWITCH = 'ACCOUNT_SWITCH',
  ADHOC_LOCATION_DISABLED = 'ADHOC_LOCATION_DISABLED',
  ADHOC_LOCATION_ENABLED = 'ADHOC_LOCATION_ENABLED',
  APP_STATE_CHANGE = 'APP_STATE_CHANGE',
  CURRENT_USER_CONTEXT_CHANGE = 'CURRENT_USER_CONTEXT_CHANGE',
  DEVICE_LOG_DATA_CHANGE = 'DEVICE_LOG_DATA_CHANGE',
  LOGIN = 'LOGIN',
  PERIODIC_PING = 'PERIODIC_PING',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN = 'UNKNOWN',
  USER_CONTEXTS_CHANGE = 'USER_CONTEXTS_CHANGE',
}

// The user role type of the user to organization relationship.
export enum OrganizationUserRoleType {
  ADMIN = 'ADMIN',
  FOLLOWER = 'FOLLOWER',
  GUEST = 'GUEST',
  OWNER = 'OWNER',
  STAFF = 'STAFF',
  TAX_LEGAL = 'TAX_LEGAL',
}

// The category type of the Device.
export enum DeviceCategoryType {
  DESKTOP = 'DESKTOP',
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  TV = 'TV',
  UNKNOWN = 'UNKNOWN',
}

// (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3)
export enum subscriptionFeatureType {
  AGENCY_AGENCY_JOB_INTERNAL_NOTES = 'AGENCY_AGENCY_JOB_INTERNAL_NOTES',
  AGENCY_COURIER_AVAILABILITY_MAP = 'AGENCY_COURIER_AVAILABILITY_MAP',
  AGENCY_ORGANIZATION_USER_COUNT_BASIC = 'AGENCY_ORGANIZATION_USER_COUNT_BASIC',
  COURIER_AVAILABILITY = 'COURIER_AVAILABILITY',
}

// Type of the input source when inviting courier/agency user.
export enum UserContactImportSourceType {
  CSV = 'CSV',
  PhoneBook = 'PhoneBook',
  PowerAutomate = 'PowerAutomate',
}

// The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected.
export enum OrganizationInvitationType {
  ACTIVATE_NEW_OWN_ORGANIZATION = 'ACTIVATE_NEW_OWN_ORGANIZATION',
  ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION = 'ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION',
  CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION = 'CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION',
  DEPRECATED_OLD_ORGANIZATION_INVITATION = 'DEPRECATED_OLD_ORGANIZATION_INVITATION',
  JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE = 'JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE',
  JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION = 'JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION',
  JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION = 'JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION',
  OTHER_ORGANIZATION_INVITATION = 'OTHER_ORGANIZATION_INVITATION',
}

// The release type of installed application on the Device.
export enum DeviceApplicationReleaseType {
  AD_HOC = 'AD_HOC',
  APP_STORE = 'APP_STORE',
  DEVELOPMENT = 'DEVELOPMENT',
  ENTERPRISE = 'ENTERPRISE',
  SIMULATOR = 'SIMULATOR',
  UNKNOWN = 'UNKNOWN',
}

// iOS Notifications Permission Alert Style
export enum DevicePermissionsNotificationsIosAlertStyle {
  ALERT = 'ALERT',
  BANNER = 'BANNER',
  NONE = 'NONE',
}

// Whether previews are allowed for iOS Notifications
export enum DevicePermissionsNotificationsIosAllowsPreviews {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  WHEN_AUTHENTICATED = 'WHEN_AUTHENTICATED',
}

// Whether previews are allowed for iOS Notifications
export enum DevicePermissionsNotificationsIosAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  EPHEMERAL = 'EPHEMERAL',
  NOT_DETERMINED = 'NOT_DETERMINED',
  PROVISIONAL = 'PROVISIONAL',
}

// The status of a device permission. (Note: strings are lowercase, e.g. GRANTED = granted)
export enum DevicePermissionStatus {
  DENIED = 'DENIED',
  GRANTED = 'GRANTED',
  UNDETERMINED = 'UNDETERMINED',
}

// The physical feature of the Document.
export enum DocumentFeature {
  BIOMETRIC = 'BIOMETRIC',
  DIGITAL_PHOTO = 'DIGITAL_PHOTO',
  MACHINE_READABLE = 'MACHINE_READABLE',
}

// The Currency used for money transactions.
export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}

// User travel document sub type
export enum UserTravelDocumentSubTypeType {
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  TOURIST = 'TOURIST',
}

// The Courier-Agency Relationship Type (Status) of this CourierJob.
export enum CourierJobAgencyRelationshipType {
  AGENCY_ACCEPTED = 'AGENCY_ACCEPTED',
  AGENCY_DECLINED = 'AGENCY_DECLINED',
  AGENCY_REQUESTED = 'AGENCY_REQUESTED',
  COURIER_ACCEPTED = 'COURIER_ACCEPTED',
  COURIER_DECLINED = 'COURIER_DECLINED',
  COURIER_REQUESTED = 'COURIER_REQUESTED',
}

// The State of the CourierJob.
export enum CourierJobStatusType {
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  FINISHED_AGENCY_CONFIRMED = 'FINISHED_AGENCY_CONFIRMED',
  NEW = 'NEW',
  STARTED = 'STARTED',
}

// Reason why this Job was declined by the Agency
export enum AgencyDeclinedReasonType {
  AGENCY_CANCELLED = 'AGENCY_CANCELLED',
}

// The State of the AgencyJob.
export enum AgencyJobStatusType {
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  FINISHED_CLIENT_CONFIRMED = 'FINISHED_CLIENT_CONFIRMED',
  NEW = 'NEW',
  STARTED = 'STARTED',
}

// The category type of the job.
export enum JobType {
  EMERGENCY = 'EMERGENCY',
  REGULAR = 'REGULAR',
  SHORT_NOTICE = 'SHORT_NOTICE',
}

// The content category of the transported goods.
export enum TransportGoodsContentType {
  AOG = 'AOG',
  AUTOMOTIVE_PARTS = 'AUTOMOTIVE_PARTS',
  DOCUMENTS = 'DOCUMENTS',
  MEDICAL = 'MEDICAL',
  OTHERS = 'OTHERS',
  PRODUCTION_MATERIALS = 'PRODUCTION_MATERIALS',
  SPARE_PARTS = 'SPARE_PARTS',
}

// pickup or handover.
export enum JobTypeOfDelivery {
  AIRPORT_AIRPORT = 'AIRPORT_AIRPORT',
  AIRPORT_DOOR = 'AIRPORT_DOOR',
  DOOR_AIRPORT = 'DOOR_AIRPORT',
  DOOR_DOOR = 'DOOR_DOOR',
}

export enum AgencyJobPlanningFormType {
  GENERIC_FORM_A = 'GENERIC_FORM_A',
  GENERIC_FORM_B = 'GENERIC_FORM_B',
}

// Filter types for system notification
export enum NotificationSystemFilterType {
  AVAILABILITY = 'AVAILABILITY',
  CHAT = 'CHAT',
  CONNECTION = 'CONNECTION',
  JOB = 'JOB',
}

// The type of the notification.
export enum NotificationType {
  AGENCY_CHAT_NEW_CHAT_MESSAGE = 'AGENCY_CHAT_NEW_CHAT_MESSAGE',
  AGENCY_COURIER_ACCOUNT_DELETION = 'AGENCY_COURIER_ACCOUNT_DELETION',
  AGENCY_JOB_PLANNING_ITEM_MESSAGE = 'AGENCY_JOB_PLANNING_ITEM_MESSAGE',
  AGENCY_JOB_PLANNING_LEG_MESSAGE = 'AGENCY_JOB_PLANNING_LEG_MESSAGE',
  AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT = 'AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT',
  AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER = 'AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER',
  CHAT_NEW_CHAT_MESSAGE = 'CHAT_NEW_CHAT_MESSAGE',
  CHAT_NEW_CHAT_MESSAGE_FOR_JOB = 'CHAT_NEW_CHAT_MESSAGE_FOR_JOB',
  COURIER_APP_REMINDER = 'COURIER_APP_REMINDER',
  JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER',
  JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER',
  JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER',
  JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_RESET_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_RESET_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY',
  ORGANIZATION_ORGANIZATION_NEW_COURIER_AGENCY_CONNECTION = 'ORGANIZATION_ORGANIZATION_NEW_COURIER_AGENCY_CONNECTION',
}

// The means of how the notification was sent (push, sms, email).
export enum NotificationMeansType {
  EMAIL = 'EMAIL',
  EXPO_PUSH = 'EXPO_PUSH',
  FAX = 'FAX',
  LETTER = 'LETTER',
  PHONE_CALL = 'PHONE_CALL',
  SMS = 'SMS',
  SYSTEM = 'SYSTEM',
}

// The language of the notification content that was sent out.
export enum NotificationLanguage {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
}

// (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5)
export enum SubscriptionPlanType {
  AGENCY01 = 'AGENCY01',
  AGENCY02 = 'AGENCY02',
  AGENCY03 = 'AGENCY03',
  AGENCY_ADDITIONAL_SEATS = 'AGENCY_ADDITIONAL_SEATS',
  COURIER01 = 'COURIER01',
  COURIER02 = 'COURIER02',
}

// The Client-Agency Relationship Type (Status) of this AgencyJob.
export enum AgencyJobClientRelationshipType {
  AGENCY_ACCEPTED = 'AGENCY_ACCEPTED',
  AGENCY_DECLINED = 'AGENCY_DECLINED',
  AGENCY_REQUESTED = 'AGENCY_REQUESTED',
  CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',
  CLIENT_DECLINED = 'CLIENT_DECLINED',
  CLIENT_REQUESTED = 'CLIENT_REQUESTED',
}

// Possible directions in which to order a list of items when provided an `orderBy` argument.
export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

// Order type - number, string or date
export enum OrderType {
  date = 'date',
  number = 'number',
  string = 'string',
}

// Filter types for courier availabilities
export enum AvailabilityFilterType {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  SCHEDULED = 'SCHEDULED',
}

// Filter types for courier availabilities for certain job
export enum JobAvailabilityFilterType {
  AVAILABLE = 'AVAILABLE',
  SCHEDULED = 'SCHEDULED',
  UNAVAILABLE = 'UNAVAILABLE',
}

// null
export interface AddDeviceLogInput {
  data?: DeviceLogDataInput | null;
  id: string;
  isCurrentUserContextAvailable: boolean;
  timestamp: DateTime;
  triggerType: DeviceLogTriggerType;
}

// null
export interface DeviceLogDataInput {
  androidId?: string | null;
  appLastUpdatedAt?: DateTime | null;
  appState?: string | null;
  appVersion?: string | null;
  applicationId?: string | null;
  availableLocationProviders?: JSONObject | null;
  batteryLevel?: number | null;
  batteryState?: DeviceLogBatteryStateType | null;
  carrier?: string | null;
  freeDiskStorage?: number | null;
  ipAddress: string;
  isAirplaneModeEnabled?: boolean | null;
  lowPowerMode?: boolean | null;
  maxMemory?: number | null;
  networkStateIsConnected?: boolean | null;
  networkStateIsInternetReachable?: boolean | null;
  networkStateType?: DeviceLogNetworkStateType | null;
  totalDiskCapacity?: number | null;
  uptime?: number | null;
}

// null
export interface UserContactInput {
  email?: string | null;
  expoContact?: ExpoContactInput | null;
  expoContactId?: string | null;
  firstNames: string;
  importSource?: UserContactImportSourceType | null;
  lastName: string;
  phone: string;
  phoneUnformatted: string;
}

// null
export interface ExpoContactInput {
  addresses?: ExpoContactAddressInput[] | null;
  birthday?: ExpoContactDateInput | null;
  company?: string | null;
  contactType: string;
  dates?: ExpoContactDateInput[] | null;
  department?: string | null;
  emails?: ExpoContactEmailInput[] | null;
  firstName: string;
  id: string;
  image?: ExpoContactImageInput | null;
  imageAvailable?: boolean | null;
  instantMessageAddresses?: ExpoContactInstantMessageAddressInput[] | null;
  jobTitle?: string | null;
  lastName: string;
  lookupKey?: string | null;
  maidenName?: string | null;
  middleName?: string | null;
  name: string;
  namePrefix?: string | null;
  nameSuffix?: string | null;
  nickname?: string | null;
  nonGregorianBirthday?: ExpoContactDateInput | null;
  note?: string | null;
  phoneNumbers?: ExpoContactPhoneNumberInput[] | null;
  phoneticFirstName?: string | null;
  phoneticLastName?: string | null;
  phoneticMiddleName?: string | null;
  rawImage?: ExpoContactImageInput | null;
  relationships?: ExpoContactRelationshipInput[] | null;
  socialProfiles?: ExpoContactSocialProfileInput[] | null;
  urlAddresses?: ExpoContactUrlAddressInput[] | null;
}

// null
export interface ExpoContactAddressInput {
  city?: string | null;
  country?: string | null;
  id: string;
  isoCountryCode?: string | null;
  label: string;
  neighborhood?: string | null;
  poBox?: string | null;
  postalCode?: string | null;
  region?: string | null;
  street?: string | null;
}

// null
export interface ExpoContactDateInput {
  day?: number | null;
  format?: string | null;
  id?: string | null;
  label?: string | null;
  month?: number | null;
  year?: number | null;
}

// null
export interface ExpoContactEmailInput {
  email?: string | null;
  id: string;
  isPrimary?: boolean | null;
  label: string;
  type?: string | null;
}

// null
export interface ExpoContactImageInput {
  base64?: string | null;
  height?: number | null;
  uri?: string | null;
  width?: number | null;
}

// null
export interface ExpoContactInstantMessageAddressInput {
  id: string;
  label: string;
  localizedService?: string | null;
  service?: string | null;
  username?: string | null;
}

// null
export interface ExpoContactPhoneNumberInput {
  countryCode?: string | null;
  digits?: string | null;
  id: string;
  isPrimary?: boolean | null;
  label: string;
  number?: string | null;
  type?: string | null;
}

// null
export interface ExpoContactRelationshipInput {
  id: string;
  label: string;
  name?: string | null;
}

// null
export interface ExpoContactSocialProfileInput {
  id: string;
  label: string;
  localizedProfile?: string | null;
  service?: string | null;
  url?: string | null;
  userId?: string | null;
  username?: string | null;
}

// null
export interface ExpoContactUrlAddressInput {
  id: string;
  label: string;
  url?: string | null;
}

// null
export interface SuggestedLocationInput {
  description?: string | null;
  formattedAddress: string;
  id?: string | null;
  latitude: number;
  longitude: number;
  placeId: string;
  types: string[];
}

// null
export interface OrganizationInvitationSharingInput {
  appName?: string | null;
  inactivatedAt?: DateTime | null;
  language?: string | null;
  organizationInvitationId: string;
  platform: string;
  platformVersion?: string | null;
  sharedAt: DateTime;
}

// null
export interface DeviceInput {
  appSettings?: DeviceAppSettingsInput | null;
  deviceProperties: DevicePropertiesInput;
  deviceType: DeviceCategoryType;
  expoPushToken?: string | null;
  id: string;
  localization?: DeviceLocalizationInput | null;
  permissions?: DevicePermissionsInput | null;
}

// null
export interface DeviceAppSettingsInput {
  appLanguage?: string | null;
  theme?: string | null;
}

// null
export interface DevicePropertiesInput {
  androidId?: string | null;
  applicationId?: string | null;
  applicationName?: string | null;
  audio?: number | null;
  brand?: string | null;
  canvas?: DeviceCanvasInput | null;
  chrome?: boolean | null;
  colorDepth?: number | null;
  cookiesEnabled?: boolean | null;
  cpuClass?: string | null;
  designName?: string | null;
  deviceName?: string | null;
  deviceYearClass?: number | null;
  emptyValLength?: number | null;
  errorFF?: boolean | null;
  expoDeviceId?: string | null;
  expoVersion?: string | null;
  fonts?: string[] | null;
  hardwareConcurrency?: number | null;
  indexedDb?: boolean | null;
  installReferrer?: string | null;
  installationTime?: DateTime | null;
  iosApplicationReleaseType?: DeviceApplicationReleaseType | null;
  iosIdForVendor?: string | null;
  iosPushNotificationServiceEnvironment?: string | null;
  isDevice: boolean;
  isLimitAdTrackingEnabled?: boolean | null;
  isRooted?: boolean | null;
  isSideLoadingEnabled?: boolean | null;
  languages?: string[][] | null;
  lastUpdateTime?: DateTime | null;
  localStorage?: boolean | null;
  manufacturer?: string | null;
  maxMemory?: number | null;
  modelId?: string | null;
  modelName?: string | null;
  nativeApplicationVersion?: string | null;
  nativeBuildVersion?: string | null;
  openDatabase?: boolean | null;
  osBuildFingerprint?: string | null;
  osBuildId?: string | null;
  osCpu?: string | null;
  osInternalBuild?: string | null;
  osName?: string | null;
  osVersion?: string | null;
  platform?: string | null;
  platformApiLevel?: number | null;
  platformFeatures?: string[] | null;
  pluginSupport?: boolean | null;
  plugins?: PluginDataInput[] | null;
  productName?: string | null;
  productSub?: string | null;
  screenResolution?: number[] | null;
  screenResolutionAvailable?: number[] | null;
  sessionStorage?: boolean | null;
  supportedCpuArchitectures?: string[] | null;
  timezone?: string | null;
  timezoneOffset?: number | null;
  totalMemory?: number | null;
  touchSupport?: TouchSupportInput | null;
  userAgent?: string | null;
  vendor?: string | null;
  webdriver?: boolean | null;
}

// null
export interface DeviceCanvasInput {
  geometry: string;
  text: string;
  winding: boolean;
}

// null
export interface PluginDataInput {
  description: string;
  mimeTypes: PluginMimeTypeDataInput[];
  name: string;
}

// null
export interface PluginMimeTypeDataInput {
  suffixes: string;
  type: string;
}

// null
export interface TouchSupportInput {
  maxTouchPoints: number;
  touchEvent: boolean;
  touchStart: boolean;
}

// null
export interface DeviceLocalizationInput {
  currency?: string | null;
  decimalSeparator: string;
  digitGroupingSeparator: string;
  isMetric: boolean;
  isRTL: boolean;
  isoCurrencyCodes: string[];
  locale: string;
  locales: string[];
  region?: string | null;
  timezone: string;
}

// null
export interface DevicePermissionsInput {
  notifications?: DevicePermissionsNotificationsInput | null;
}

// null
export interface DevicePermissionsNotificationsInput {
  android?: DevicePermissionsNotificationsAndroidInput | null;
  canAskAgain: boolean;
  expires: DevicePermissionExpiration;
  granted: boolean;
  ios?: DevicePermissionsNotificationsIosInput | null;
  status: DevicePermissionStatus;
}

// null
export interface DevicePermissionsNotificationsAndroidInput {
  importance: number;
  interruptionFilter?: number | null;
}

// null
export interface DevicePermissionsNotificationsIosInput {
  alertStyle: DevicePermissionsNotificationsIosAlertStyle;
  allowsAlert?: boolean | null;
  allowsAnnouncements?: boolean | null;
  allowsBadge?: boolean | null;
  allowsCriticalAlerts?: boolean | null;
  allowsDisplayInCarPlay?: boolean | null;
  allowsDisplayInNotificationCenter?: boolean | null;
  allowsDisplayOnLockScreen?: boolean | null;
  allowsPreviews?: DevicePermissionsNotificationsIosAllowsPreviews | null;
  allowsSound?: boolean | null;
  providesAppNotificationSettings?: boolean | null;
  status: DevicePermissionsNotificationsIosAuthorizationStatus;
}

// null
export interface DeviceLocationInput {
  accuracy?: number | null;
  altitude?: number | null;
  altitudeAccuracy?: number | null;
  heading?: number | null;
  latitude: number;
  longitude: number;
  speed?: number | null;
  timestampInMs: number;
}

// null
export interface UserIdentityDocumentInput {
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  countryOfBirth?: string | null;
  countryOfIssue?: string | null;
  dateOfBirth?: DateTime | null;
  dateOfIssue?: DateTime | null;
  description?: string | null;
  documentContainerName?: string | null;
  documentFeature?: DocumentFeature | null;
  documentNumber?: string | null;
  documentSubType?: IdentityDocumentSubType | null;
  documentSubTypeMulti?: IdentityDocumentSubType[] | null;
  documentType: IdentityDocumentType;
  expiresOn?: DateTime | null;
  expiresOnInternational?: DateTime | null;
  firstNames?: string | null;
  isInternationalDrivingPermit: boolean;
  lastName?: string | null;
  nationality?: string | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  placeOfBirth?: string | null;
  placeOfIssue?: string | null;
  sex?: Gender | null;
}

// null
export interface StoredAssetMetadataInput {
  fileName?: string | null;
  height?: number | null;
  mimeType?: string | null;
  size?: number | null;
  width?: number | null;
}

// null
export interface CourierUserProfileInput {
  address?: string | null;
  amexCreditCardCurrency?: Currency | null;
  amexCreditCardLimit?: number | null;
  apartmentOrSuite?: string | null;
  baseAirportLocationIds?: string[] | null;
  baseAirports?: string[] | null;
  city?: string | null;
  country?: string | null;
  dinerCreditCardCurrency?: Currency | null;
  dinerCreditCardLimit?: number | null;
  emergencyContactAddress?: string | null;
  emergencyContactEmail?: string | null;
  emergencyContactName?: string | null;
  emergencyContactPhone?: string | null;
  emergencyContactRelationship?: string | null;
  firstObcJobDateAt?: DateTime | null;
  hasAmexCreditCard?: boolean | null;
  hasAmexDebitCard?: boolean | null;
  hasDinerCreditCard?: boolean | null;
  hasDinerDebitCard?: boolean | null;
  hasMasterCreditCard?: boolean | null;
  hasMasterDebitCard?: boolean | null;
  hasMedicalObcExperience?: boolean | null;
  hasObcExperience?: boolean | null;
  hasVisaCreditCard?: boolean | null;
  hasVisaDebitCard?: boolean | null;
  id: string;
  inactivatedAt?: DateTime | null;
  languagesKnown?: LanguagesKnownInput[] | null;
  masterCreditCardCurrency?: Currency | null;
  masterCreditCardLimit?: number | null;
  numberOfPastObcJobs?: number | null;
  onboardExperiences?: string[] | null;
  socialProfiles: SocialProfileInput[];
  street?: string | null;
  taxCountry?: string | null;
  visaCreditCardCurrency?: Currency | null;
  visaCreditCardLimit?: number | null;
  zipcode?: string | null;
}

// null
export interface LanguagesKnownInput {
  language: string;
  level: LanguageSkillLevel;
}

// null
export interface SocialProfileInput {
  platform: string;
  type: string;
  value: string;
}

// null
export interface UserProfileInput {
  countryOfBirth?: string | null;
  dateOfBirth?: DateTime | null;
  email?: string | null;
  email2?: string | null;
  firstNames?: string | null;
  id: string;
  language?: string | null;
  lastName?: string | null;
  nationalities?: string[] | null;
  phone?: string | null;
  phone2?: string | null;
  placeOfBirth?: string | null;
  sex?: Gender | null;
  userRegisteredAddressLocation?: LocationManualInput | null;
}

// null
export interface LocationManualInput {
  addressName?: string | null;
  apartmentOrSuite?: string | null;
  city?: string | null;
  country?: string | null;
  dataLanguage?: string | null;
  formattedAddress?: string | null;
  houseNumber?: string | null;
  id?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  placeId?: string | null;
  postalCode?: string | null;
  state?: string | null;
  streetName?: string | null;
  types?: string[] | null;
}

// null
export interface UserTravelDocumentInput {
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  countryOfBirth?: string | null;
  countryOfIssue?: string | null;
  dateOfBirth?: DateTime | null;
  dateOfIssue?: DateTime | null;
  description?: string | null;
  documentContainerName?: string | null;
  documentNumber?: string | null;
  documentSubType?: string | null;
  documentSubTypeMulti?: UserTravelDocumentSubTypeType[] | null;
  documentType: TravelDocumentType;
  expiresOn?: DateTime | null;
  firstNames?: string | null;
  lastName?: string | null;
  multipleEntriesAllowed?: boolean | null;
  nationality?: string | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  placeOfBirth?: string | null;
  placeOfIssue?: string | null;
  sex?: Gender | null;
  validForCountry?: string | null;
}

// null
export interface UserFrequentFlyerDocumentInput {
  airlineAllianceStatusType?: AirlineAllianceStatusType | null;
  airlineAllianceType?: AirlineAllianceType | null;
  airlineStatusType?: AirlineStatusType | null;
  airlineType?: AirlineType | null;
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  documentContainerName?: string | null;
  documentNumber?: string | null;
  expiresOn?: DateTime | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
}

// null
export interface UserVaccinationDocumentInput {
  cityOfVaccination?: string | null;
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  countryOfVaccination?: string | null;
  dateOfVaccination?: DateTime | null;
  description?: string | null;
  diseaseType?: VaccinationTargetDisease | null;
  documentContainerName?: string | null;
  documentNumber?: string | null;
  expiresOn?: DateTime | null;
  isVaccinationComplete?: boolean | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  vaccinationCenter?: string | null;
  vaccinationManufacturer?: string | null;
  vaccinationType?: string | null;
}

// null
export interface UserHealthReportInput {
  cityOfTest?: string | null;
  cityOfTestLocationInput?: SuggestedLocationInput | null;
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  countryOfTest?: string | null;
  dateTimeOfTest?: DateTime | null;
  description?: string | null;
  diseaseType?: VaccinationTargetDisease | null;
  documentContainerName?: string | null;
  documentNumber?: string | null;
  isResultPositive?: boolean | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  testCenter?: string | null;
  testType?: HealthTestType | null;
}

// null
export interface UserBackgroundDocumentInput {
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  countryOfIssue?: string | null;
  dateOfIssue?: DateTime | null;
  documentContainerName?: string | null;
  documentNumber?: string | null;
  expiresOn?: DateTime | null;
  issuingInstitution?: string | null;
  name?: string | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
}

// null
export interface UserOtherDocumentInput {
  compressedDocumentBackFilePath?: string | null;
  compressedDocumentBackMetadata?: StoredAssetMetadataInput | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  countryOfIssue?: string | null;
  dateOfIssue?: DateTime | null;
  description?: string | null;
  documentContainerName?: string | null;
  documentNumber?: string | null;
  expiresOn?: DateTime | null;
  name?: string | null;
  originalDocumentBackFilePath?: string | null;
  originalDocumentBackMetadata?: StoredAssetMetadataInput | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentFrontMetadata?: StoredAssetMetadataInput | null;
  placeOfIssue?: string | null;
}

// null
export interface AgencyJobInput {
  additionalCostInformation?: string | null;
  checkInTimeAtEndAirport?: DateTime | null;
  checkInTimeAtStartAirport?: DateTime | null;
  clientFixedRate?: number | null;
  comment?: string | null;
  currency: Currency;
  dailyRate?: number | null;
  endAirportSuggestedLocationInput?: SuggestedLocationInput | null;
  externalJobReference: string;
  fixedRate?: number | null;
  hotelBudget?: number | null;
  id?: string | null;
  internalNotes?: string | null;
  isAccommodationBookedByCourier: boolean;
  isAccommodationPaidByCourier: boolean;
  isAdditionalCostPaidByCourier: boolean;
  isCarRequiredForJob: boolean;
  isDrivingLicenseRequiredForJob: boolean;
  jobDetails?: string | null;
  kickOffFee?: number | null;
  numOfCouriersRequired: number;
  numOfItems: number;
  responsibleAgencyOrganizationUserIds?: string[] | null;
  returnTimeAtEndAirport?: DateTime | null;
  returnTimeAtStartAirport?: DateTime | null;
  startAirportSuggestedLocationInput?: SuggestedLocationInput | null;
  weightOfItems?: number | null;
}

// null
export interface ClientJobByAgencyInput {
  currency: Currency;
  externalJobReference?: string | null;
  fixedRate?: number | null;
  organizationEmail?: string | null;
  organizationLegalName?: string | null;
  organizationName: string;
  organizationPhone?: string | null;
}

// null
export interface JobInput {
  description?: string | null;
  descriptionOfItems?: string | null;
  dropOffAddressDescription?: string | null;
  dropOffLocationSuggestedLocationInput?: SuggestedLocationInput | null;
  dropOffTime?: DateTime | null;
  jobType: JobType;
  numOfItems: number;
  pickupAddressDescription?: string | null;
  pickupLocationSuggestedLocationInput?: SuggestedLocationInput | null;
  pickupTime: DateTime;
  transportedGoodsContentType: TransportGoodsContentType;
  typeOfDelivery: JobTypeOfDelivery;
  weightOfItems?: number | null;
}

// null
export interface CreateChatMessageInput {
  chatFile?: ChatFileInput | null;
  chatId: string;
  message?: string | null;
  messageType?: MessageType | null;
  sendingUserId?: string | null;
}

// null
export interface ChatFileInput {
  fileBlobMetadata: StoredAssetMetadataInput;
  fileBlobPath: string;
  fileContainerName: string;
}

// null
export interface UpdateChatMessageInput {
  chatMessageId: string;
  message?: string | null;
}

// null
export interface DeleteChatMessageInput {
  chatMessageId: string;
}

// null
export interface AgencyOrganizationProfileInput {
  compressedLogoBlobName?: string | null;
  compressedLogoMetadata?: StoredAssetMetadataInput | null;
  description?: string | null;
  email?: string | null;
  headquartersAddressLocation?: LocationManualInput | null;
  id: string;
  invoiceEmail?: string | null;
  invoicingAddressLocation?: LocationManualInput | null;
  legalEntityType?: string | null;
  legalName: string;
  logoContainerName?: string | null;
  name: string;
  organizationType: OrganizationType;
  originalLogoBlobName?: string | null;
  originalLogoMetadata?: StoredAssetMetadataInput | null;
  phone?: string | null;
  registrationAddressLocation?: LocationManualInput | null;
  registrationNumber?: string | null;
  shortName?: string | null;
  socialProfiles?: SocialProfileInput[] | null;
  tinyLogoBlobName?: string | null;
  tinyLogoMetadata?: StoredAssetMetadataInput | null;
  vatId?: string | null;
}

// null
export interface AgencyOrganizationVatIdInput {
  id: string;
  vatId?: string | null;
}

// null
export interface CourierOrganizationProfileInput {
  headquartersAddressLocation?: LocationManualInput | null;
  id: string;
  invoicingAddressLocation?: LocationManualInput | null;
  legalName: string;
  name: string;
  registrationAddressLocation?: LocationManualInput | null;
  socialProfiles?: SocialProfileInput[] | null;
}

// null
export interface OrganizationUserUpdateProfileInput {
  countryOfBirth?: string | null;
  dateOfBirth?: DateTime | null;
  email2?: string | null;
  firstNames?: string | null;
  language?: string | null;
  lastName?: string | null;
  nationalities?: string[] | null;
  organizationUserId: string;
  phone?: string | null;
  phone2?: string | null;
  placeOfBirth?: string | null;
  roleType?: string | null;
  sex?: Gender | null;
}

// null
export interface OrganizationSubscriptionPlanSingleInput {
  id: string;
}

// null
export interface AirportLocationUpdateTimezoneInput {
  airportLocationId?: string | null;
  timeZoneIdentifier?: string | null;
  timeZoneRawOffset?: number | null;
}

// null
export interface OrganizationCourierCategoriesUpdateInput {
  categories: OrganizationCourierCategoryUpdate[];
}

// null
export interface OrganizationCourierCategoryUpdate {
  id: string;
  label?: string | null;
  order?: number | null;
}

// null
export interface AgencyCourierUserProfileCreateOrUpdateInput {
  internalComment?: string | null;
  markedForAttention: boolean;
  organizationCourierCategoryId: string;
  userId: string;
}

// null
export interface NotificationSettingUpdateInput {
  emailConnection: boolean;
  emailJob: boolean;
  pushAvailability: boolean;
  pushChat: boolean;
  pushConnection: boolean;
  pushJob: boolean;
  systemAvailability: boolean;
  systemChat: boolean;
  systemConnection: boolean;
  systemJob: boolean;
}

// null
export interface AuthSignOutUserContextInput {
  deviceId: string;
  userId: string;
}

// null
export interface AgencyJobPlanningForAgencyJobUpdateInput {
  agencyJobPlanningItems: AgencyJobPlanningItemInput[];
  agencyJobPlanningLegIdsToDelete: string[];
  agencyJobPlanningLegs: AgencyJobPlanningLegInput[];
}

// null
export interface AgencyJobPlanningItemInput {
  active: boolean;
  field: string;
  id?: string | null;
  targetDateTime?: DateTime | null;
}

// null
export interface AgencyJobPlanningLegInput {
  active: boolean;
  agencyJobPlanningItems: AgencyJobPlanningItemInput[];
  departureDate?: DateTime | null;
  flightCarrierDesignation: string;
  flightNumber: string;
  flightTicketIdsToDelete?: string[] | null;
  flightTickets?: FilesUploadInput[] | null;
  id?: string | null;
  order: number;
}

// null
export interface FilesUploadInput {
  base64: string;
  height?: number | null;
  mimeType: string;
  name: string;
  width?: number | null;
}

// null
export interface AgencyJobPlanningItemUpdateInput {
  actualDateTime?: DateTime | null;
  agencyJobPlanningItemId: string;
  files?: FilesUploadInput[] | null;
  isLegItem: boolean;
  location?: SuggestedLocationInput | null;
  note: string;
}

// null
export interface AgencyJobsForAgencyOrganizationInput {
  agencyJobStatusType?: AgencyJobStatusType[] | null;
  agencyOrganizationId: string;
}

// null
export interface AgencyCourierJobChatInput {
  courierJobId: string;
}

// null
export interface AgencyJobChatInput {
  agencyJobId: string;
}

// null
export interface ChatMessagesByChatIdInput {
  id?: string | null;
}

// null
export interface PaginationArgs {
  after?: string | null;
  before?: string | null;
  limit?: number | null;
}

// null
export interface OrderArgs {
  direction: OrderDirection;
  field: string;
  type: OrderType;
}

// null
export interface AirportLocationSearchInput {
  searchText: string;
}

// null
export interface AirportLocationListInput {
  limit?: number | null;
  offset?: number | null;
}

// null
export interface OrganizationInvitationUploadCSVInput {
  emailField: string;
  file: string;
  firstNameField: string;
  lastNameField: string;
  phoneNumberField: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
