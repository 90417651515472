import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  title: {
    marginTop: 20,
  },
  memberSinceContainer: {
    backgroundColor: 'background-basic-color-2',
    width: '100%',
    padding: 10,
  },
  card: {
    backgroundColor: 'background-basic-color-2',
    margin: 5,
    marginTop: 5,
    paddingTop: 5,
    borderRadius: 10,
    maxWidth: 500,
    width: '100%',
  },
  divider: {
    marginVertical: 10,
  },
  planTitle: {
    fontFamily: 'Lato_900Black',
    padding: 6,
    paddingLeft: 15,
  },
  cardContent: {
    padding: 15,
    paddingTop: 5,
  },
  footerContainer: {
    padding: 10,
    paddingTop: 8,
    paddingLeft: 15,
  },
  buttonsContainer: {
    paddingHorizontal: 10,
    maxWidth: 500,
    minWidth: 340,
    alignSelf: 'center',
  },
  warningIcon: {
    color: 'color-warning-500',
  },
});
