import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  wrapper: {
    width: '100%',
    borderRadius: 6,
    overflow: 'hidden',
    maxHeight: '90%',
    backgroundColor: 'background-basic-color-1',
    alignSelf: 'center',
    maxWidth: 1160,
  },
  inner: {
    width: '100%',
    backgroundColor: 'background-basic-color-1',
    padding: 10,
    paddingTop: 14,
    borderRadius: 6,
  },
  closeButton: {
    height: 44,
    width: 44,
    borderRadius: 22,
    padding: 5,
    marginTop: -3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background-basic-color-1',
  },
  closeButtonLeft: {
    position: 'absolute',
    top: 15,
    left: 15,
  },
  closeButtonRight: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  closeIcon: {
    color: 'text-basic-color',
  },
  planningButton: {
    marginTop: 10,
    marginRight: 10,
  },
  title: {
    marginTop: 5,
    paddingRight: 10,
    paddingLeft: 10,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
    width: '100%',
  },
  subtitle: {
    marginTop: 2,
    marginBottom: 20,
    paddingRight: 40,
    paddingLeft: 40,
    fontSize: 13,
    color: 'text-basic-color',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  marginTopNegative5: {
    marginTop: -5,
  },
  greenColor: {
    color: 'success',
  },
  loading: {
    color: 'white',
  },
  offlineItem: {
    backgroundColor: '#ff000011',
    borderWidth: 1,
    borderColor: '#ff000099',
  },
});
