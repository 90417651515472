import React from 'react';
import { FlatList, RefreshControl, TouchableOpacity, View } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Button, Icon, Text, useStyleSheet, Input } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { CheckBox } from 'react-native-elements';
// import { ScrollView } from 'react-native-gesture-handler';
// import JSONTree from 'react-native-json-tree';

import { AgencyAddOrDisableCourierCard } from './AgencyAddOrDisableCourierCard';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../common/style';
import { FormSelectModal } from '../../../components/common/form/FormSelectModal';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import {
  AvailableCourierOrganizationUsersWithDistance,
  JobAvailabilityFilterType,
} from '../../../generated-graphql-types';
// import { RootStackParamList } from '../../../navigation/app.navigator';

export const AgencyAddOrDisableCourierJobScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  // const route: any = useRoute();
  const navigation: any = useNavigation();

  const {
    agencyJobForAgencyOrganizationData,
    // availableCourierUsersByDistanceForAgencyJobError,
    addOrDisableCourierJob,
    availabilities,
    buttonData,
    handleBackPress,
    handleChangeSearchText,
    handleProfilePress,
    handleRefresh,
    handleResetSearchText,
    isRefreshing,
    isSelectAllSelected,
    loading,
    searchText,
    selectedCourierOrganizationUserIds,
    handleSelect,
    handleSelectAll,
    filter,
    filterOpened,
    handleCloseFilterPress,
    handleDoneFilterPress,
    handleOpenFilterPress,
    isFilterVisible,
    onSelectFilter,
  } = useHook();

  const renderFilter = () => (
    <>
      <TouchableOpacity onPress={handleOpenFilterPress} style={styles.filterButton}>
        <Icon
          name="funnel-outline"
          fill={(styles.filterIconColor as any).color}
          style={styles.filterIconSize}
          hitSlop={{ top: 5, left: 5, right: 5, bottom: 5 }}
        />
      </TouchableOpacity>
      {filterOpened && (
        <FormSelectModal
          items={[
            { label: 'Available', value: JobAvailabilityFilterType.AVAILABLE },
            { label: 'Matched planned availability', value: JobAvailabilityFilterType.SCHEDULED },
            { label: 'Unavailable', value: JobAvailabilityFilterType.UNAVAILABLE },
          ]}
          fieldTitleKey="label"
          fieldValueKey="value"
          handleClose={handleCloseFilterPress}
          handleDone={handleDoneFilterPress}
          multiSelect
          onSelect={onSelectFilter}
          value={filter}
          isVisible={isFilterVisible}
        />
      )}
    </>
  );

  const renderAccessoryRight = () => {
    if (searchText.length) {
      return (
        <TouchableOpacity onPress={handleResetSearchText}>
          <Icon
            name="close-circle"
            fill={(styles.resetIcon as any).color}
            style={[globalStyle.size24, globalStyle.opacity5]}
          />
        </TouchableOpacity>
      );
    }
    return <></>;
  };

  const renderSearchBar = () => (
    <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter, globalStyle.height50]}>
      <Input
        accessoryRight={renderAccessoryRight()}
        onChangeText={handleChangeSearchText}
        placeholder={t('common.searchByCouriersNameOrBaseAirportCode', {
          defaultValue: "Search by courier's name or base airport code",
        })}
        value={searchText}
        style={styles.textInput}
        textStyle={globalStyle.padding10}
      />
      {renderFilter()}
    </View>
  );

  const renderButton = () => (
    <Button {...buttonData} onPress={addOrDisableCourierJob} size="small" style={styles.btnUpdate}>
      {buttonData.text}
    </Button>
  );

  const renderItem = ({ item }: { item: AvailableCourierOrganizationUsersWithDistance }) => {
    const selected = selectedCourierOrganizationUserIds.includes(item.organizationUsers[0].id);
    return (
      <AgencyAddOrDisableCourierCard
        agencyJob={agencyJobForAgencyOrganizationData?.agencyJobForAgencyOrganization}
        availability={item}
        onProfilePress={handleProfilePress}
        onSelect={handleSelect(item)}
        selected={selected}
      />
    );
  };

  const renderListHeader = () => (
    <>
      <View>
        <View style={styles.alignItemsCenter}>
          <View style={[styles.flexRow, styles.alignItemsCenter, styles.alignContentBetween]}>
            {renderButton()}

            <CheckBox
              accessibilityLabel="Select all"
              checked={isSelectAllSelected}
              onPress={handleSelectAll}
            />
            <Text>Select all</Text>
          </View>
        </View>
      </View>
      {renderSearchBar()}
    </>
  );

  const renderListEmptyComponent = () => {
    if (loading) {
      return (
        <View style={[globalStyle.padding30, globalStyle.marginTop20]}>
          <LoadingSpinner />
        </View>
      );
    }
    if (agencyJobForAgencyOrganizationData && !availabilities?.length) {
      return (
        <View style={[globalStyle.padding30, globalStyle.marginTop20]}>
          <Text style={globalStyle.textAlignCenter}>
            {t('common.atThisMomentThereAreNoAvailableCouriers', {
              defaultValue: 'At this moment there are no available couriers.',
            })}
          </Text>
        </View>
      );
    }
    return <View style={[globalStyle.padding30, globalStyle.marginTop20]} />;
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('jobs.addOrRemoveCouriers', { defaultValue: 'Add/Remove Couriers' })}
        subtitle={
          agencyJobForAgencyOrganizationData?.agencyJobForAgencyOrganization.job
            .priojetJobIdentifier +
          ' (' +
          agencyJobForAgencyOrganizationData?.agencyJobForAgencyOrganization.externalJobReference +
          ')'
        }
        onPressLeft={handleBackPress}
        navigation={navigation}
      />

      <FlatList
        keyExtractor={(item) => 'flatlist' + item.user.id}
        contentContainerStyle={globalStyle.padding10}
        style={styles.height100Percent}
        data={availabilities}
        ListEmptyComponent={renderListEmptyComponent()}
        ListHeaderComponent={renderListHeader()}
        refreshControl={
          <RefreshControl
            onRefresh={handleRefresh}
            refreshing={isRefreshing}
            title="Loading available Couriers..."
          />
        }
        renderItem={renderItem}
      />
    </>
  );
};
