import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';

import { DOCUMENT_DATA } from './constants';
import { USER_QUERY } from '../../../apollo/graphql-queries';
import { User } from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { UserDocument2 } from '../../../types';

export const useHook = () => {
  const navigation: any = useNavigation();
  const route: any = useRoute();

  const isBackendReachable = useIsBackendReachable();
  const { data: userProfileQueryData } = useQuery<User>(USER_QUERY, {
    variables: { id: route.params?.courierUserId as string },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });
  const { data } = useQuery(DOCUMENT_DATA[route.params.userDocumentType].query, {
    variables: { userId: route.params?.courierUserId },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const navigateToDocument = (props: UserDocument2) => {
    const paramsData: any = {};
    const params: any = route.params;
    ['agencyJobId', 'courierUserId', 'parentScreenName', 'userId'].forEach((key) => {
      if (params?.[key]) {
        if (key === 'courierUserId') {
          paramsData.userId = params?.[key];
        }
        paramsData[key] = params?.[key];
      }
    });
    navigation.navigate('AgencyReadCourierUserDocumentScreen', {
      ...paramsData,
      userDocument: props,
      userDocumentId: props.id,
      courierUserId: route.params?.courierUserId || '',
      userDocumentType: route.params?.userDocumentType,
      title: DOCUMENT_DATA[route.params.userDocumentType].title,
      previousNavigator: route.params.previousNavigator || 'AgencyProfileNavigator',
      previousScreenName: 'AgencyCourierUserDocumentsScreen',
    } as any);
  };

  const handleBackPress = () => {
    if (route.params?.courierUserId && route.params?.userDocumentType) {
      navigation.navigate('AgencyCourierDocumentsMenuScreen', {
        courierUserId: route.params?.courierUserId || '',
        agencyJobId: route.params?.agencyJobId || '',
        previousScreenName: 'AgencyCourierUserProfileScreen',
        previousNavigator: route.params?.previousNavigator,
        parentScreenName: route.params.parentScreenName,
      } as any);
    } else {
      navigation.goBack();
    }
  };

  return { handleBackPress, navigateToDocument, data, userProfileQueryData };
};
