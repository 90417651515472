import React, { useMemo } from 'react';
import { TouchableOpacity, ScrollView, View, Pressable, Platform } from 'react-native';

import { Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';

import { useHook } from './hook';
import { styles as themedStyles } from './styles';
import { globalStyle } from '../../../../../../common/style';
import { ChatMessageImage } from '../../../../../../components/common/Chat/ChatMessage/ChatMessageImage';
import { ChatMessagePDF } from '../../../../../../components/common/Chat/ChatMessage/ChatMessagePDF';
import PDFViewer from '../../../../../../components/common/pdf-viewer.component';
import { AgencyJobPlanningFile } from '../../../../../../generated-graphql-types';

export const ChatPlanningCompletedItemModal: React.FC<any> = ({
  agencyJobPlanningItem,
  handleCancel,
  visible,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { handlePDFPress, pdfUri } = useHook();

  const {
    actualDateTimeText,
    files,
    label,
    locationText,
    note,
    targetDateTimeText,
  }: {
    actualDateTimeText: string;
    files: AgencyJobPlanningFile[];
    label: string;
    locationText: string;
    note: string;
    targetDateTimeText: string;
  } = useMemo(() => {
    const _label = agencyJobPlanningItem?.label || '';
    let _actualDateTimeText: string = '';
    let _actualDateTime = agencyJobPlanningItem?.actualDateTime;
    if (typeof _actualDateTime === 'string') {
      _actualDateTime = DateTime.fromJSDate(new Date(_actualDateTime));
    }
    if (_actualDateTime?.toFormat) {
      _actualDateTimeText =
        _actualDateTime.setZone('utc').toFormat('HH:mm') +
        ' LT, ' +
        _actualDateTime.setZone('utc').toFormat('dd MMM yyyy');
    }

    let _targetDateTimeText: string = '';
    let _targetDateTime = agencyJobPlanningItem?.targetDateTime;
    if (typeof _targetDateTime === 'string') {
      _targetDateTime = DateTime.fromJSDate(new Date(_targetDateTime));
    }
    if (_targetDateTime?.toFormat) {
      _targetDateTimeText =
        _targetDateTime.setZone('utc').toFormat('HH:mm') +
        ' LT, ' +
        _targetDateTime.setZone('utc').toFormat('dd MMM yyyy');
    }

    return {
      actualDateTimeText: _actualDateTimeText,
      files: agencyJobPlanningItem?.files || [],
      label: _label,
      locationText: agencyJobPlanningItem?.location?.formattedAddress || '',
      note: agencyJobPlanningItem?.note || '',
      targetDateTimeText: _targetDateTimeText,
    };
  }, [agencyJobPlanningItem]);

  const renderFiles = () => {
    if (files.length) {
      return (
        <View style={[globalStyle.marginTop5, globalStyle.alignItemsStart]}>
          {files.map((file) => {
            if (file.storedAsset && file.storedAsset?.metadata.mimeType?.includes('image')) {
              return (
                <ChatMessageImage
                  key={file.id}
                  chatFile={file.storedAsset}
                  downloadIconFill="white"
                  styleImage={globalStyle.marginTop5}
                />
              );
            }
            if (file.storedAsset && file.storedAsset?.metadata.mimeType?.includes('pdf')) {
              return (
                <ChatMessagePDF
                  key={file.id}
                  chatFile={file.storedAsset}
                  downloadIconFill="white"
                  handlePDFPress={handlePDFPress}
                  style={[globalStyle.justifyContentEnd, globalStyle.alignContentCenter]}
                  styleButton={[globalStyle.marginBottom0, globalStyle.marginTop5]}
                />
              );
            }
          })}
        </View>
      );
    }
    return <></>;
  };

  const renderScrollView = () => (
    <ScrollView style={styles.scrollWrapper} contentContainerStyle={styles.inner}>
      <Text style={[globalStyle.marginBottom10, styles.title]} selectable>
        {label}
      </Text>

      {!!targetDateTimeText && (
        <Text
          style={[
            styles.messageText,
            globalStyle.marginTop10,
            styles.textLeft,
            globalStyle.flexRow,
          ]}
          selectable
        >
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.targetTime', { defaultValue: 'Target time' })}:
          </Text>{' '}
          {targetDateTimeText}
        </Text>
      )}
      {!!actualDateTimeText && (
        <Text style={[styles.messageText, styles.textLeft, globalStyle.flexRow]} selectable>
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.actualTime', { defaultValue: 'Actual time' })}:
          </Text>{' '}
          {actualDateTimeText}
        </Text>
      )}
      {!!locationText && (
        <Text style={[styles.messageText, styles.textLeft, globalStyle.flexRow]} selectable>
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.location', { defaultValue: 'Location' })}:
          </Text>{' '}
          {locationText}
        </Text>
      )}
      {!!note && (
        <Text style={[styles.messageText, styles.textLeft, globalStyle.flexRow]} selectable>
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.note', { defaultValue: 'Note' })}:
          </Text>{' '}
          {note}
        </Text>
      )}
      {renderFiles()}
    </ScrollView>
  );

  return (
    <Modal
      animationIn="fadeIn"
      animationOut="fadeOut"
      avoidKeyboard
      isVisible={visible}
      backdropOpacity={0.8}
      useNativeDriver
      style={
        Platform.OS === 'web' && [globalStyle.justifyContentCenter, globalStyle.alignItemsCenter]
      }
    >
      <Pressable style={globalStyle.absoluteFull} onPress={handleCancel} />
      <View style={styles.wrapper}>
        {renderScrollView()}
        <TouchableOpacity
          style={styles.closeButton}
          onPress={handleCancel}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <Icon fill={(styles.messageText as any).color} name="close" style={globalStyle.size30} />
        </TouchableOpacity>
      </View>

      <PDFViewer onClose={handlePDFPress('')} uri={pdfUri} visible={!!pdfUri} />
    </Modal>
  );
};
