import React from 'react';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Button, Icon, Tab, TabBar } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { OrganizationInvitationList } from '../../components/common/Invitation/OrganizationInvitationList';
import { LoadingSpinner } from '../../components/common/loading-spinner.component';
import { PriojetProfileTopNavigationBar } from '../../components/priojet/priojet-profile-top-navigation-bar.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import {
  OrganizationInvitation,
  OrganizationInvitationsForPriojetOrganizationQuery,
  useOrganizationInvitationsForPriojetOrganizationQuery,
} from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export const PriojetOrganizationInvitationsScreen = ({ navigation }: { navigation: any }) => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();

  const {
    loading: organizationInvitationsLoading,
    data: organizationInvitationsData,
    refetch: organizationInvitationsRefetch,
  } = useOrganizationInvitationsForPriojetOrganizationQuery({
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const ReservedScreen = ({
    organizationInvitations,
    refetch,
    loading,
  }: {
    organizationInvitations: OrganizationInvitationsForPriojetOrganizationQuery['organizationInvitationsForPriojetOrganization'];
    refetch: (variables?: any) => Promise<any>;
    loading: boolean;
  }) => {
    return (
      <OrganizationInvitationList
        organizationInvitations={
          organizationInvitations?.filter(
            (e) => e.createdAt != null && e.usedAt == null && e.voidedAt == null,
          ) as Partial<OrganizationInvitation>[]
        }
        type="reserved"
        refetch={refetch}
        loading={loading}
      />
    );
  };

  const UsedScreen = ({
    organizationInvitations,
    refetch,
    loading,
  }: {
    organizationInvitations: OrganizationInvitationsForPriojetOrganizationQuery['organizationInvitationsForPriojetOrganization'];
    refetch: (variables?: any) => Promise<any>;
    loading: boolean;
  }) => {
    return (
      <OrganizationInvitationList
        organizationInvitations={
          organizationInvitations?.filter(
            (e) => e.usedAt != null && e.voidedAt == null,
          ) as Partial<OrganizationInvitation>[]
        }
        type="used"
        refetch={refetch}
        loading={loading}
      />
    );
  };

  const VoidedScreen = ({
    organizationInvitations,
    refetch,
    loading,
  }: {
    organizationInvitations: OrganizationInvitationsForPriojetOrganizationQuery['organizationInvitationsForPriojetOrganization'];
    refetch: (variables?: any) => Promise<any>;
    loading: boolean;
  }) => {
    return (
      <OrganizationInvitationList
        organizationInvitations={
          organizationInvitations?.filter(
            (e) => e.voidedAt != null,
          ) as Partial<OrganizationInvitation>[]
        }
        type="voided"
        refetch={refetch}
        loading={loading}
      />
    );
  };

  const renderTopBar = (data: { navigation: any; state: any }) => (
    <TabBar
      selectedIndex={data.state.index}
      onSelect={(index) => data.navigation.navigate(data.state.routeNames[index])}
      style={{ height: 50 }}
    >
      <Tab title="RESERVED" />
      <Tab title="USED" />
      <Tab title="VOIDED" />
    </TabBar>
  );

  if (organizationInvitationsData == undefined) {
    return (
      <>
        <TopNavigationWithBackButton
          title={
            t('menuItems.organizationInvitations', {
              defaultValue: 'Organization Invitations',
            }) as string
          }
          navigation={navigation}
        />
        <LoadingSpinner />
      </>
    );
  }

  return (
    <>
      <PriojetProfileTopNavigationBar
        title={
          t('menuItems.organizationInvitations', {
            defaultValue: 'Organization Invitations',
          }) as string
        }
        navigation={navigation}
        accessoryRight={
          <Button
            appearance="ghost"
            onPress={() => {
              navigation.navigate('PriojetCreateOrganizationInvitationScreen');
            }}
            accessoryLeft={<Icon name="plus-outline" />}
          />
        }
      />
      <Navigator tabBar={(props: any) => renderTopBar(props)}>
        <Screen name="Reserved">
          {(props) => (
            <ReservedScreen
              {...props}
              organizationInvitations={
                organizationInvitationsData.organizationInvitationsForPriojetOrganization
              }
              refetch={organizationInvitationsRefetch}
              loading={organizationInvitationsLoading}
            />
          )}
        </Screen>
        <Screen name="Used">
          {(props) => (
            <UsedScreen
              {...props}
              organizationInvitations={
                organizationInvitationsData.organizationInvitationsForPriojetOrganization
              }
              refetch={organizationInvitationsRefetch}
              loading={organizationInvitationsLoading}
            />
          )}
        </Screen>
        <Screen name="Voided">
          {(props) => (
            <VoidedScreen
              {...props}
              organizationInvitations={
                organizationInvitationsData.organizationInvitationsForPriojetOrganization
              }
              refetch={organizationInvitationsRefetch}
              loading={organizationInvitationsLoading}
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};
