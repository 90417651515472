import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  removeButton: {
    width: 20,
  },
  languageLayout: {
    flexDirection: 'row',
    alignContent: 'space-between',
    margin: 2,
  },
  languageSelect: {
    flex: 1,
    margin: 2,
  },
  marginVertical20: {
    marginVertical: 20,
  },
});
