import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { useQuery } from '@apollo/client';
import { useIsFocused } from '@react-navigation/core';

import { AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY } from '../../../apollo/graphql-queries';
import { globalStyle } from '../../../common/style';
import { AgencyJobsList } from '../../../components/agency/AgencyJobsList';
import { AgencyJobInternalNotesModal } from '../../../components/common/modal/AgencyJobInternalNotesModal';
import AppUserContext from '../../../contexts/AppUserContext';
import { AgencyJob } from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const AgencyJobsTabScreen = ({
  agencyJobStatusType,
  isActive,
  refetchCount,
  previousTabScreenName,
  ...props
}: any): React.ReactElement => {
  const internalNotesModalRef = useRef<any>(null);
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();

  const [agencyOrganizationId, setAgencyOrganizationId] = useState('');
  const [loading, setLoading] = useState(true);

  const { data, refetch } = useQuery(AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY, {
    variables: {
      data: { agencyOrganizationId, agencyJobStatusType },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-only',
    pollInterval: isFocused && isActive && isBackendReachable ? 60000 : undefined,
    onCompleted: () => {
      setLoading(false);
    },
    skip:
      !isFocused ||
      !isActive ||
      typeof agencyOrganizationId !== 'string' ||
      !agencyOrganizationId.length,
  });

  const agencyJobs = useMemo(() => {
    if (data?.agencyJobsForAgencyOrganizationPaginated?.edges?.length) {
      return data?.agencyJobsForAgencyOrganizationPaginated?.edges.map(
        (item: { node: AgencyJob }) => item.node,
      );
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (isActive && !agencyOrganizationId) {
      setTimeout(() => {
        if (appUserContext?.currentUserContext?.organization?.id) {
          setAgencyOrganizationId(appUserContext.currentUserContext.organization.id);
        }
      }, 0);
    } else if (!isActive && agencyOrganizationId) {
      setAgencyOrganizationId('');
    }
  }, [
    agencyOrganizationId,
    agencyJobStatusType,
    appUserContext.currentUserContext?.organization?.id,
    isActive,
  ]);

  const handleRefetch = () => {
    refetch();
    refetchCount();
  };

  const handleShowInternalNotesModal = ({
    agencyJobId,
    internalNotes,
  }: {
    agencyJobId: string;
    internalNotes: string;
  }) => {
    internalNotesModalRef.current?.showInternalNotesModal({ agencyJobId, value: internalNotes });
  };

  return (
    <View style={globalStyle.flex1}>
      <AgencyJobsList
        {...props}
        isLoading={loading}
        refetch={handleRefetch}
        jobs={agencyJobs}
        showInternalNotesModal={handleShowInternalNotesModal}
        previousScreenName="AgencyJobsScreen"
        previousTabScreenName={previousTabScreenName}
      />
      <AgencyJobInternalNotesModal ref={internalNotesModalRef} />
    </View>
  );
};
