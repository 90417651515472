import { useContext, useEffect, useMemo, useState, useRef } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { QUERY_AGENCY_JOB_CHAT } from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import { Chat, OrganizationType } from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const route: any = useRoute();
  const navigation: any = useNavigation();
  const appUserContext = useContext(AppUserContext);

  const chatRef = useRef<any>(null);
  const refetchInterval = useRef<NodeJS.Timer | null>(null);

  const [agencyJobId, setAgencyJobId] = useState<string>(route.params?.setAgencyJobId);
  const [chat, setChat] = useState<Chat | undefined>(undefined);
  const [errorChat, setErrorChat] = useState<string>('');
  const [headerHeight, setHeaderHeight] = useState<number>(57);
  const [loading, setLoading] = useState<boolean>(true);

  /*********************************************************************************************************************
   * DATA
   ********************************************************************************************************************/
  const errorCb = async (e: any) => {
    const error = e?.graphQLErrors?.[0];
    if (error.extensions.code === 'FORBIDDEN') {
      setErrorChat('FORBIDDEN');
    } else {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }) as string,
        description:
          error.message ||
          (t('common.somethingWentWrong', { defaultValue: 'Something went wrong' }) as string),
        type: 'danger',
        duration: 8000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const [agencyJobChat, { data }] = useLazyQuery<{
    agencyJobChat: Chat;
  }>(QUERY_AGENCY_JOB_CHAT, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    context: { cb: errorCb },
  });

  const getChatData = async (jobId?: string) => {
    try {
      const { data: agencyJobChatData } = await agencyJobChat({
        variables: { agencyJobChatInput: { agencyJobId: jobId || agencyJobId } },
      });
      if (agencyJobChatData?.agencyJobChat.id) {
        if (!route.params?.chatId) {
          navigation.setParams({ chatId: agencyJobChatData?.agencyJobChat.id });
        }
        setChat(agencyJobChatData.agencyJobChat);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    if (loading) {
      setLoading(false);
    }
  };

  const onPressLeft = () => {
    if (route.params.previousScreenName) {
      if (
        route.params.previousScreenName.includes(',') ||
        route.params.previousScreenName.includes('%2C')
      ) {
        let routes = [];
        if (route.params.previousScreenName.includes('%2C')) {
          routes = route.params.previousScreenName.split('%2C');
        } else {
          routes = route.params.previousScreenName.split(',');
        }
        let newPreviousScreenName = '';
        const previousScreenNames = routes.slice(0, -1);
        if (previousScreenNames.length) {
          newPreviousScreenName = previousScreenNames.join(',');
        }
        navigation.navigate(routes[routes.length - 1], {
          ...(route.params || {}),
          previousScreenName: newPreviousScreenName,
        });
      } else {
        const { previousScreenName, ...rest } = route.params;
        navigation.navigate(previousScreenName, { ...rest });
      }
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  const handleTitlePress = () => {
    const isAgency =
      appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY;
    const isCourier =
      appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER;

    let previousScreenName = route.params?.previousScreenName;
    let courierJobId = route.params?.courierJobId;
    let userId = route.params?.userId;
    if (isCourier) {
      userId = appUserContext.currentUserContext?.user?.id;
    }
    if (!courierJobId && userId) {
      courierJobId = data?.agencyJobChat.agencyJob?.courierJobs?.find(
        (item) => item.responsibleCourierUser.id === userId,
      )?.id;
    }
    if (
      previousScreenName.startsWith('AgencyJobsScreen') &&
      previousScreenName.includes('AgencyCourierJobOverviewScreen')
    ) {
      if (isCourier) {
        navigation.navigate('CourierCourierJobScreen', {
          agencyJobId,
          courierJobId,
          previousScreenName: 'AgencyJobsScreen',
          previousNavigator: 'CourierHomeNavigator',
        });
      } else if (isAgency) {
        if (agencyJobId) {
          navigation.navigate('AgencyViewCourierJobScreen', {
            agencyJobId,
            courierJobId,
            previousScreenName: 'AgencyJobsScreen',
            previousNavigator: 'AgencyHomeNavigator',
          });
        }
      }
    } else if (previousScreenName.endsWith('AgencyCourierJobOverviewScreen')) {
      onPressLeft();
    } else {
      if (isCourier) {
        navigation.navigate('CourierCourierJobScreen', {
          agencyJobId,
          courierJobId,
          previousScreenName: `${previousScreenName},CourierAgencyJobChatScreen`,
          previousNavigator: 'CourierHomeNavigator',
        });
      } else if (isAgency) {
        if (agencyJobId) {
          navigation.navigate('AgencyViewCourierJobScreen', {
            agencyJobId,
            courierJobId,
            previousScreenName: `${previousScreenName},AgencyAgencyJobChatScreen`,
            previousNavigator: 'AgencyHomeNavigator',
          });
        }
      }
    }
  };

  const onHeaderLayout = (event: any) => {
    if (event.nativeEvent.layout.height) {
      if (!headerHeight || headerHeight < event.nativeEvent.layout.height) {
        setHeaderHeight(event.nativeEvent.layout.height);
      }
    }
  };

  const { title, subtitle } = useMemo(() => {
    if (data?.agencyJobChat) {
      let _title = t('job', { defaultValue: 'Job ' });
      let _subtitle = '';

      if (data.agencyJobChat) {
        const { agencyJob } = data.agencyJobChat;
        if (agencyJob?.startAirport?.iataCode && agencyJob?.endAirport?.iataCode) {
          _title += `${agencyJob?.startAirport.iataCode}-${agencyJob?.endAirport.iataCode}`;
        }
        if (data.agencyJobChat.agencyJob?.job.priojetJobIdentifier) {
          _title += ` (${data.agencyJobChat.agencyJob?.job.priojetJobIdentifier})`;
        }
        if (agencyJob?.externalJobReference) {
          _subtitle = agencyJob.externalJobReference;
        }
      }
      return { title: _title, subtitle: _subtitle };
    }
    return { title: t('chat', { defaultValue: 'Chat' }), subtitle: '' };
  }, [data?.agencyJobChat, t]);

  const { disableInput, disableInputText } = useMemo(() => {
    if (data?.agencyJobChat?.inactivatedAt) {
      if (appUserContext?.currentUserContext?.organizationType === OrganizationType.COURIER) {
        return {
          disableInput: true,
          disableInputText: t('common.chatDisabledCourierDeleted', {
            defaultValue: 'New messages cannot be sent because connected courier deleted account.',
          }),
        };
      }
      return {
        disableInput: true,
        disableInputText: t('common.chatDisabledAgencyDeleted', {
          defaultValue: 'New messages cannot be sent because connected agency deleted account.',
        }),
      };
    }
    return { disableInput: false, disableInputText: '' };
  }, [appUserContext?.currentUserContext?.organizationType, data?.agencyJobChat?.inactivatedAt, t]);

  const handleGetLatestChatMessages = () => {
    if (chatRef.current?.getLatestMessages) {
      chatRef.current?.getLatestMessages();
    }
  };

  useEffect(() => {
    if (route.params?.refetchMessages && agencyJobId) {
      navigation.setParams({ refetchMessages: false });
      if (chatRef.current?.getLatestMessages) {
        chatRef.current?.getLatestMessages();
      }
    }
  }, [agencyJobId, navigation, route]);

  useEffect((): any => {
    if (chat?.id && !refetchInterval.current) {
      refetchInterval.current = setInterval(() => {
        getChatData();
      }, 60 * 1000);
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat?.id, refetchInterval]);

  useEffect((): any => {
    if (
      (route.params?.agencyJobId && route.params?.agencyJobId !== agencyJobId) ||
      (route.params?.chatId && route.params?.chatId !== chat?.id)
    ) {
      setLoading(true);
      setErrorChat('');
      setAgencyJobId(route.params?.agencyJobId);
      getChatData(route.params?.agencyJobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat?.id, agencyJobId, route.params?.chatId, route.params?.agencyJobId]);

  return {
    agencyJobId,
    chatId: chat?.id || route.params?.chatId,
    chatRef,
    disableInput,
    disableInputText,
    errorChat,
    handleGetLatestChatMessages,
    handleTitlePress,
    headerHeight,
    loading,
    onHeaderLayout,
    onPressLeft,
    subtitle,
    title,
  };
};
