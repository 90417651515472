import React from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';

import { useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';

import { CONNECTED_AGENCY_ORGANIZATION_FOR_COURIER_ORGANIZATION } from '../../../apollo/graphql-queries';
import { globalStyle } from '../../../common/style';
import { AgencyProfileReadyOnlyComponent } from '../../../components/common/AgencyProfileReadOnlyComponent';
import { TranslatedErrorText } from '../../../components/common/error/translated-error-text.component';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { Organization } from '../../../generated-graphql-types';
import {
  ConnectedAgencyOrganizationForCourierOrganization,
  ConnectedAgencyOrganizationForCourierOrganizationVariables,
} from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { RootStackParamList } from '../../../navigation/app.navigator';

export const CourierAgencyProfileScreen = (): React.ReactElement => {
  const appUserContext = React.useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, 'CourierAgencyProfileScreen'>>();
  const route = useRoute<RouteProp<RootStackParamList, 'CourierAgencyProfileScreen'>>();
  const { t } = useTranslation();

  const [refreshing, setRefreshing] = React.useState(false);

  const { loading, error, data, refetch } = useQuery<
    ConnectedAgencyOrganizationForCourierOrganization,
    ConnectedAgencyOrganizationForCourierOrganizationVariables
  >(CONNECTED_AGENCY_ORGANIZATION_FOR_COURIER_ORGANIZATION, {
    variables: {
      agencyOrganizationId: route.params.agencyOrganizationId,
      courierOrganizationId: appUserContext.currentUserContext?.organization?.id || 'NOT_DEFINED',
    },
    context: {
      exceptionOnError: true,
    },
    // We force network-only so that we can see the profile with implicit connections through OrganizationInvitations.
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const renderRefreshControlComponent = () => (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
        setRefreshing(false);
      }}
    />
  );

  const renderContent = () => {
    if (error) {
      return (
        <View style={globalStyle.padding20}>
          <TranslatedErrorText
            apolloError={error}
            variables={{ agencyOrganizationId: route.params.agencyOrganizationId }}
          />
        </View>
      );
    }
    if (loading) {
      return <LoadingSpinner />;
    }
    if (data?.connectedAgencyOrganizationForCourierOrganization) {
      return (
        <AgencyProfileReadyOnlyComponent
          organization={data?.connectedAgencyOrganizationForCourierOrganization as Organization}
        />
      );
    }
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common.agencyProfile', { defaultValue: 'Agency Profile' })}
        navigation={navigation}
        onPressLeft={() => {
          const { previousScreenName } = route.params || {};
          navigation.navigate('CourierProfileNavigator', {
            screen: previousScreenName || 'CourierConnectedAgenciesScreen',
          } as any);
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        refreshControl={renderRefreshControlComponent()}
      >
        {renderContent()}
      </ScrollView>
    </>
  );
};
