import React from 'react';
import { View } from 'react-native';

import {
  Button,
  Card,
  Icon,
  IconProps,
  ListItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import startCase from 'lodash/startCase';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../../common/style';
import { EmailTextWithContextMenu } from '../../../../components/common/common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../../../components/common/common.phone-text-with-context-menu';
import { HasPermissions } from '../../../../components/common/HasPermissions';
import {
  OrganizationType,
  OrganizationUser,
  OrganizationUserRoleType,
} from '../../../../generated-graphql-types';
import { getMinimumAvailableUserRoles } from '../../../../utils/role.util';

export const AgencyOrganizationUserCard = ({
  onDeleteOrganizationUserPress,
  onItemPress,
  organizationUser,
}: {
  onDeleteOrganizationUserPress: () => Promise<void>;
  onItemPress: () => void;
  organizationUser: OrganizationUser;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const renderListItemIcon = (props: IconProps) => <Icon {...props} name="person" />;

  const renderListItemTitle = (item: Partial<OrganizationUser>) => () =>
    (
      <Text
        style={[globalStyle.fontSize16, globalStyle.fontLatoBold]}
      >{`${item.user?.firstNames} ${item.user?.lastName}`}</Text>
    );

  const renderDeleteOrganizationUserButton = () => (
    <HasPermissions
      organizationType={[OrganizationType.AGENCY]}
      organizationUserRoleType={
        [OrganizationUserRoleType.OWNER, OrganizationUserRoleType.ADMIN].includes(
          organizationUser.roleType,
        )
          ? getMinimumAvailableUserRoles(OrganizationUserRoleType.OWNER)
          : getMinimumAvailableUserRoles(OrganizationUserRoleType.ADMIN)
      }
    >
      <Button size="tiny" onPress={onDeleteOrganizationUserPress} style={globalStyle.marginLeft5}>
        {t('common:removeUser', { defaultValue: 'Remove user' }) as string}
      </Button>
    </HasPermissions>
  );

  const renderViewOrganizationUserButton = () => (
    <Button size="tiny" onPress={onItemPress}>
      {t('common:viewProfile', { defaultValue: 'View profile' }) as string}
    </Button>
  );

  const renderFooter = () => (
    <View
      style={[
        globalStyle.paddingVertical5,
        globalStyle.flexRow,
        globalStyle.alignItemsCenter,
        globalStyle.justifyContentBetween,
      ]}
    >
      <Text selectable={true} style={styles.companyNameText}>
        <Text style={globalStyle.fontLatoBold}>
          {t('common:role', { defaultValue: 'Role' }) as string}:{' '}
        </Text>
        {startCase(organizationUser.roleType.toLowerCase())}
      </Text>

      <View style={globalStyle.flexRow}>
        {renderViewOrganizationUserButton()}
        {renderDeleteOrganizationUserButton()}
      </View>
    </View>
  );

  return (
    <Card key={organizationUser.id} style={styles.card} footer={renderFooter}>
      <ListItem
        title={renderListItemTitle(organizationUser)}
        description={
          <>
            <EmailTextWithContextMenu email={organizationUser.user?.email as string | undefined} />
            <PhoneTextWithContextMenu phone={organizationUser.user?.phone as string | undefined} />
          </>
        }
        accessoryLeft={renderListItemIcon}
        style={styles.listItemContent}
      />
    </Card>
  );
};

const themedStyles = StyleService.create({
  card: {
    margin: 5,
    paddingBottom: 5,
    backgroundColor: 'background-basic-color-2',
  },
  listItemContent: {
    backgroundColor: 'background-basic-color-2',
  },
  companyNameText: {
    fontStyle: 'italic',
    fontSize: 14,
    marginLeft: 48,
  },
});
