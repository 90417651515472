import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  obcCheckbox: {
    marginTop: 20,
    marginBottom: 10,
  },
  obcMedicalCheckbox: {
    marginTop: 5,
    marginBottom: 10,
  },
});
