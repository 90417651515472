import React, { useMemo } from 'react';
import { View } from 'react-native';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../common/style';
import { CommonJobJourneyMap } from '../../../components/common/jobs/common.job-journey-map.component';
import { JourneyLocationType } from '../../../components/common/jobs/common.job-journey-map.component/common.job-journey-map.component';
import { AgencyJob, Job, JobTypeOfDelivery } from '../../../generated-graphql-types';

export const CourierCourierJobJourneyScreen = ({
  active,
  agencyJob,
  job,
}: {
  active?: boolean;
  agencyJob: Partial<AgencyJob>;
  job: Partial<Job>;
}) => {
  const { t } = useTranslation();

  const deliveryLocations = useMemo((): JourneyLocationType[] => {
    const _markers: JourneyLocationType[] = [];
    let number = 1;
    if (job?.pickupLocation?.locationGeometry?.location?.lat) {
      let countDownTitle = t('jobs.arrivalAtPickupLocation', {
        defaultValue: 'Arrival at Pickup Location in',
      });
      let title = t('jobs.pickupLocation', { defaultValue: 'Pickup location' });
      if (
        job.typeOfDelivery !== JobTypeOfDelivery.DOOR_DOOR &&
        job.typeOfDelivery !== JobTypeOfDelivery.DOOR_AIRPORT
      ) {
        title = t('jobs.pickupLocationStartAirport', {
          defaultValue: 'Pickup location / start airport',
        });
        countDownTitle = t('jobs.arriveAtPickupLocationStartAirport', {
          defaultValue: 'Arrival at pickup location / start airport in',
        });
      }
      _markers.push({
        countDownTitle,
        dateTime: job?.pickupTime as DateTime,
        enumeration: `${number}`,
        location: job.pickupLocation,
        longitude: job.pickupLocation.locationGeometry.location.lng,
        latitude: job.pickupLocation.locationGeometry.location.lat,
        title,
        visible: true,
      });
      number++;
    }
    if (
      (job.typeOfDelivery === JobTypeOfDelivery.DOOR_DOOR ||
        job.typeOfDelivery === JobTypeOfDelivery.DOOR_AIRPORT) &&
      agencyJob?.startAirport?.locationGeometry?.location?.lat
    ) {
      _markers.push({
        countDownTitle: t('jobs.arriveAtStartAirport', {
          defaultValue: 'Arrival at start airport in',
        }),
        dateTime: agencyJob?.checkInTimeAtStartAirport,
        enumeration: `${number}`,
        location: agencyJob.startAirport,
        latitude: agencyJob.startAirport.locationGeometry.location.lat,
        longitude: agencyJob.startAirport.locationGeometry.location.lng,
        title: t('jobs.startAirport', { defaultValue: 'Start airport' }),
        visible: true,
      });
      number++;
    }
    if (agencyJob?.endAirport?.locationGeometry?.location?.lat) {
      let countDownTitle = t('jobs.arrivalAtEndAirportIn', {
        defaultValue: 'Arrival at end airport in',
      });
      let title = t('jobs.endAirport', { defaultValue: 'End airport' });
      if (
        job.typeOfDelivery !== JobTypeOfDelivery.DOOR_DOOR &&
        job.typeOfDelivery !== JobTypeOfDelivery.AIRPORT_DOOR
      ) {
        countDownTitle = t('jobs.arrivalAtEndAirportIn', {
          defaultValue: 'Arrival at end airport / drop-off location in',
        });
        title = t('jobs.endAirportDropOffLocation', {
          defaultValue: 'End airport / drop-off location',
        });
      }
      _markers.push({
        countDownTitle,
        dateTime: undefined,
        enumeration: `${number}`,
        location: agencyJob.endAirport,
        latitude: agencyJob.endAirport.locationGeometry.location.lat,
        longitude: agencyJob.endAirport.locationGeometry.location.lng,
        title,
        visible: true,
      });
      number++;
    }
    if (
      (job.typeOfDelivery === JobTypeOfDelivery.DOOR_DOOR ||
        job.typeOfDelivery === JobTypeOfDelivery.AIRPORT_DOOR) &&
      job?.dropOffLocation?.locationGeometry?.location?.lat
    ) {
      _markers.push({
        countDownTitle: t('jobs.dropOffDeliveryIn', { defaultValue: 'Drop-off delivery in' }),
        dateTime: job?.dropOffTime,
        enumeration: `${number}`,
        location: job.dropOffLocation,
        latitude: job.dropOffLocation.locationGeometry.location.lat,
        longitude: job.dropOffLocation.locationGeometry.location.lng,
        title: t('jobs.dropOffLocation', { defaultValue: 'Drop-off location' }),
        visible: true,
      });
      number++;
    }
    return _markers;
  }, [
    agencyJob?.checkInTimeAtStartAirport,
    agencyJob.endAirport,
    agencyJob.startAirport,
    job.typeOfDelivery,
    job.dropOffLocation,
    job?.dropOffTime,
    job.pickupLocation,
    job?.pickupTime,
    t,
  ]);

  const returnLocations = useMemo((): JourneyLocationType[] => {
    const _markers: JourneyLocationType[] = [];
    let number = 1;
    if (
      (job.typeOfDelivery === JobTypeOfDelivery.DOOR_DOOR ||
        job.typeOfDelivery === JobTypeOfDelivery.AIRPORT_DOOR) &&
      job?.dropOffLocation?.locationGeometry?.location?.lat
    ) {
      _markers.push({
        countDownTitle: 'Drop-off delivery in',
        dateTime: job?.dropOffTime,
        enumeration: `${number}`,
        location: job.dropOffLocation,
        latitude: job.dropOffLocation.locationGeometry.location.lat,
        longitude: job.dropOffLocation.locationGeometry.location.lng,
        title: t('jobs.dropOffLocation', { defaultValue: 'Drop-off location' }),
        visible: true,
      });
      number++;
    }
    if (agencyJob?.endAirport?.locationGeometry?.location?.lat) {
      let countDownTitle = t('jobs.returnAtEndAirport', {
        defaultValue: 'Return at the end airport again in',
      });
      let title = t('jobs.returnAtEndAirport', { defaultValue: 'Return at end airport' });
      if (
        job.typeOfDelivery !== JobTypeOfDelivery.DOOR_DOOR &&
        job.typeOfDelivery !== JobTypeOfDelivery.AIRPORT_DOOR
      ) {
        countDownTitle = t('jobs.returnFromEndAirportDropOffLocation', {
          defaultValue: 'Return at the end airport / drop-off location again in',
        });
        title = t('jobs.returnAtEndAirport', {
          defaultValue: 'Return from end airport / drop-off location',
        });
      }

      _markers.push({
        countDownTitle,
        dateTime: agencyJob?.returnTimeAtEndAirport,
        enumeration: `${number}`,
        location: agencyJob.endAirport,
        latitude: agencyJob.endAirport.locationGeometry.location.lat,
        longitude: agencyJob.endAirport.locationGeometry.location.lng,
        title,
        visible: true,
      });
      number++;
    }
    if (agencyJob?.startAirport?.locationGeometry?.location?.lat) {
      _markers.push({
        countDownTitle: t('jobs.returnAtStartAirportIn', {
          defaultValue: 'Likely to return at the start airport again in',
        }),
        dateTime: agencyJob?.returnTimeAtStartAirport,
        enumeration: `${number}`,
        location: agencyJob.startAirport,
        latitude: agencyJob.startAirport.locationGeometry.location.lat,
        longitude: agencyJob.startAirport.locationGeometry.location.lng,
        title: t('jobs.returnAtStartAirport', { defaultValue: 'Return at start airport' }),
        visible: true,
      });
    }
    return _markers;
  }, [
    agencyJob.endAirport,
    agencyJob?.returnTimeAtEndAirport,
    agencyJob?.returnTimeAtStartAirport,
    agencyJob.startAirport,
    job.dropOffLocation,
    job?.dropOffTime,
    job.typeOfDelivery,
    t,
  ]);

  return (
    <View style={globalStyle.height100Percent}>
      {!!deliveryLocations.length && !!returnLocations.length && (
        <CommonJobJourneyMap
          active={active}
          deliveryLocations={deliveryLocations}
          returnLocations={returnLocations}
        />
      )}
    </View>
  );
};
