import React from 'react';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { Button, Icon } from '@ui-kitten/components';

import { CourierAvailabilitiesScreen } from '../screens/courier/CourierAvailabilitiesScreen';
import { CourierCreateAvailabilityScreen } from '../screens/courier/CourierCreateAvailabilityScreen';

const StackNavigator = createStackNavigator();

const CourierAvailabilitiesNavigator = () => {
  return (
    <StackNavigator.Navigator
      initialRouteName="CourierAvailabilitiesScreen"
      screenOptions={{ headerShown: false }}
    >
      <StackNavigator.Screen
        name="CourierAvailabilitiesScreen"
        component={CourierAvailabilitiesScreen}
      />
      <StackNavigator.Group
        screenOptions={({ navigation }) => ({
          cardShadowEnabled: true,
          headerShown: false,
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
          animationEnabled: true,
          headerRight: () => (
            <Button
              onPress={() => navigation.goBack()}
              appearance="ghost"
              accessoryLeft={<Icon name="close-outline" />}
            />
          ),
        })}
      >
        <StackNavigator.Screen
          options={{
            ...TransitionPresets.ModalSlideFromBottomIOS,
            headerTitle: 'Create Availability',
          }}
          name="CourierCreateAvailabilityScreen"
          component={CourierCreateAvailabilityScreen}
        />
      </StackNavigator.Group>
    </StackNavigator.Navigator>
  );
};

export { CourierAvailabilitiesNavigator };
