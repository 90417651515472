import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { DateTime } from 'luxon';

import introspectionResult from './../../graphql.schema.json';

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);
// note: sometimes it seems to be needed to cast it as Introspection Query
// `const schema = buildClientSchema((introspectionResult as unknown) as IntrospectionQuery)`

const typesMap = {
  DateTime: {
    serialize: (parsed: unknown | DateTime): string | null => {
      if (parsed && parsed instanceof DateTime) {
        return parsed.toISO();
      } else if (parsed && parsed instanceof Date) {
        return DateTime.fromJSDate(parsed).toISO();
      }
      try {
        return String(parsed);
      } catch {
        return null;
      }
    },
    parseValue: (raw: unknown | string): DateTime | null => {
      if (!raw) {
        return null; // Treat the empty string as null.
      }
      if (typeof raw === 'string') {
        const _dateTime = DateTime.fromISO(raw);
        if (!_dateTime || !_dateTime.isValid) {
          return null;
          // throw new Error('invalid value to parse');
        }
        return _dateTime; // Only return valid DateTime objects.
      }
      throw new Error('invalid value to parse');
    },
  },
};

export const scalarsLink = withScalars({
  schema,
  typesMap,
});
