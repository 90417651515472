import React, { useContext } from 'react';
import { ActivityIndicator, RefreshControl, ScrollView, View, Platform } from 'react-native';

import {
  Entypo,
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
  Foundation,
} from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Button, Divider, Radio, RadioGroup, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { FormAddSubtractCounter } from '../../../components/common/form/form-add-subtract-counter.component';
import { FormBinaryRadioGroup } from '../../../components/common/form/form-binary-radio-group.component';
import { FormCheckBox } from '../../../components/common/form/form-check-box.component';
import { FormDatepicker } from '../../../components/common/form/form-datepicker.component';
import { FormLocationAutocomplete } from '../../../components/common/form/form-location-autocomplete.component';
import { FormMoneyInput } from '../../../components/common/form/form-money-input.component';
import { FormTextInputTopLabel } from '../../../components/common/form/form-text-input-top-label.component';
import { FormWeightInput } from '../../../components/common/form/form-weight-input.component';
import { FormAirportSelect } from '../../../components/common/form/FormAirportSelect';
import { FormSelect } from '../../../components/common/form/FormSelect';
import { FormTimeSelect } from '../../../components/common/form/FormTimeSelect/form-time-select.component';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { AgencyJobInternalNotesModal } from '../../../components/common/modal/AgencyJobInternalNotesModal';
import { SubscriptionPermissions } from '../../../components/common/SubscriptionPermissions';
import ContentBoxTextTitle from '../../../components/content-box-text-title.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  SubscriptionFeatureType,
  OrganizationUserRoleType,
} from '../../../generated-graphql-types';
import { RootStackParamList } from '../../../navigation/app.navigator';

const MAX_DATE = DateTime.now().plus({ year: 2 }).toJSDate();

export const AgencyCreateOrUpdateJobScreen = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const navigation: any = useNavigation();
  const route: RouteProp<RootStackParamList, 'AgencyCreateJobScreen'> = useRoute();
  const { courierOrganizationUserName } = route.params;

  const appUserContext = useContext(AppUserContext);
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);

  const {
    agencyJobForAgencyOrganizationData,
    agencyJobForAgencyOrganizationLoading,
    agencyJobForAgencyOrganizationError,
    agencyJobForAgencyOrganizationRefetch,
    agencyJobId,
    currencyList,
    fieldProps,
    formik,
    handlePickupLocationTextChange,
    handleDropOffLocationTextChange,
    handleChangeDate,
    handleShowInternalNotesModal,
    handleSelectAirport,
    handleSelectRadioGroup,
    handleSelectLocation,
    handleSelectJobAgent,
    handleSave,
    internalNotesModalRef,
    isStateVariablesSet,
    loading,
    priojetJobIdentifier,
    jobAgentsListData,
    transportGoodsContentTypeList,
    pickupTime,
    returnTimeAtStartAirport,
  } = useHook();

  const renderPickupFormPart = () => (
    <ContentBoxTextTitle
      style={styles.contentBoxContainer}
      title={t('jobs.Pickup', { defaultValue: 'Pickup' }) as string}
      Content={
        <>
          <RadioGroup
            style={styles.radioGroupContainer}
            selectedIndex={formik.values.selectedPickupModeIndex || 0}
            onChange={handleSelectRadioGroup('selectedPickupModeIndex')}
          >
            <Radio style={styles.radioInput} checked={true}>
              {t('jobs.pickupAtDoor', { defaultValue: 'Pick-up at Door' }) as string}
            </Radio>
            <Radio style={styles.radioInput}>
              {
                t('jobs.pickupAtAirport', {
                  defaultValue: 'Pick-up at Airport',
                }) as string
              }
            </Radio>
          </RadioGroup>

          {formik.values.selectedPickupModeIndex === 0 && (
            <FormLocationAutocomplete
              isCurrentLocationEnabled={false}
              label={t('jobs.pickupLocation', { defaultValue: 'Pickup Location' }) as string}
              caption={
                t('jobs.pickupLocationCaption', {
                  defaultValue:
                    'Select the pickup location (address, place, or a just a city/district name, if the location is not determined yet).',
                }) as string
              }
              placeholderText={
                formik.values.pickupLocation?.formattedAddress ||
                (t('jobs.pickupLocationPlaceholder', {
                  defaultValue: 'Search for the pickup address here',
                }) as string)
              }
              onPress={handleSelectLocation(
                'pickupLocation',
                'pickupLocationText',
                formik.values.pickupTime,
                'pickupLocationTimeZoneResponse',
              )}
              language={i18n.language}
              isMandatory
              reverseGeocodeLocationTypes={[
                'airports',
                'localities',
                'small_regions',
                'large_regions',
                'street_addresses',
              ]}
              onChangeText={handlePickupLocationTextChange}
              textInputProps={{
                // onChangeText: handlePickupLocationTextChange,
                value: formik.values.pickupLocationText,
              }}
              errorMessage={formik?.errors.pickupLocation}
            />
          )}
          <FormAirportSelect
            caption={
              t('jobs.startAirportCaption', {
                defaultValue:
                  'Select the Airport from which the Courier starts their delivery journey.',
              }) as string
            }
            errorMessage={formik?.errors.startAirport}
            isMandatory
            label={t('jobs.startAirport', { defaultValue: 'Start Airport' }) as string}
            loadOnOpen
            onSelect={handleSelectAirport('startAirport')}
            placeholder={t('jobs.selectAirport', { defaultValue: 'Select the airport' }) as string}
            value={formik.values.startAirport}
          />
          <View>
            <View style={globalStyle.flexRow}>
              <FormDatepicker
                label={t('jobs.startDate', { defaultValue: 'Start Date' }) as string}
                caption={
                  t('jobs.startDateLabel', {
                    defaultValue:
                      'Choose the date on which to the goods/items need to be picked up.',
                  }) as string
                }
                max={MAX_DATE}
                isMandatory
                onSelect={handleChangeDate('pickupTimeDate')}
                containerStyle={globalStyle.flex1}
                date={formik.values.pickupTimeDate}
                error={!!formik?.errors.pickupTimeDate}
              />
              <FormTimeSelect
                isMandatory
                containerStyle={globalStyle.marginLeft10}
                error={!!formik?.errors.pickupTime}
                label={t('jobs.startTimeLabel', { defaultValue: 'Start time' }) as string}
                onChange={(time: string) => {
                  formik.setFieldValue('pickupTime', time);
                  if (formik?.errors.pickupTime) {
                    setTimeout(() => {
                      formik.validateField('pickupTime');
                    }, 50);
                  }
                }}
                value={formik.values.pickupTime}
              />
            </View>
            {(formik?.errors.pickupTimeDate || formik?.errors.pickupTime) && (
              <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.errorContainer]}>
                {!!formik?.errors.pickupTimeDate && (
                  <Text style={globalStyle.errorMessageText}>{formik?.errors.pickupTimeDate}</Text>
                )}
                {!!formik?.errors.pickupTime && (
                  <Text style={globalStyle.errorMessageText}>
                    {!!formik?.errors.pickupTimeDate && ' '}
                    {formik?.errors.pickupTime}
                  </Text>
                )}
              </View>
            )}
          </View>

          <FormTextInputTopLabel
            label={t('jobs.pickupAddressDescription', {
              defaultValue: 'Pickup location description',
            })}
            caption={t('jobs.pickupAddressDescriptionCaption', {
              defaultValue:
                'Provide some more information about where and how to find and pick up the items / goods.',
            })}
            textStyle={styles.textarea}
            multiline
            {...fieldProps('pickupAddressDescription')}
          />

          <FormCheckBox
            label={
              t('jobs.isCarRequiredForJobLabel', {
                defaultValue: 'Car required for pickup',
              }) as string
            }
            caption={
              t('jobs.isCarRequiredForJobCaption', {
                defaultValue:
                  'Select if this job requires the Courier to have a vehicle at their disposal.',
              }) as string
            }
            {...fieldProps('isCarRequiredForJob', 'checkbox')}
          />

          <FormCheckBox
            label={
              t('jobs.isDrivingLicenseRequiredForJobLabel', {
                defaultValue: 'Driving License required for job',
              }) as string
            }
            caption={
              t('jobs.isDrivingLicenseRequiredForJobCaption', {
                defaultValue:
                  'Select if this job requires the Courier have a valid driving license that allows them to drive a car.',
              }) as string
            }
            {...fieldProps('isDrivingLicenseRequiredForJob', 'checkbox')}
          />
        </>
      }
    />
  );

  const renderDropOffFormPart = () => (
    <ContentBoxTextTitle
      style={styles.contentBoxContainer}
      title={
        t('jobs.deliveryAndReturnTitle', {
          defaultValue: 'Delivery and Return',
        }) as string
      }
      Content={
        <View>
          <View>
            <RadioGroup
              style={styles.radioGroupContainer}
              selectedIndex={formik.values.selectedDropOffModeIndex || 0}
              onChange={handleSelectRadioGroup('selectedDropOffModeIndex')}
            >
              <Radio style={styles.radioInput} checked={true}>
                {
                  t('jobs.deliveryAtDoor', {
                    defaultValue: 'Delivery at Door',
                  }) as string
                }
              </Radio>
              <Radio style={styles.radioInput}>
                {
                  t('jobs.deliveryAtAirport', {
                    defaultValue: 'Delivery at the Airport',
                  }) as string
                }
              </Radio>
            </RadioGroup>
          </View>

          {formik.values.selectedDropOffModeIndex === 0 && (
            <FormLocationAutocomplete
              isCurrentLocationEnabled={false}
              label={
                t('jobs.dropOffLocationLabel', {
                  defaultValue: 'Drop-off Location',
                }) as string
              }
              caption={
                t('jobs.dropOffLocationCaption', {
                  defaultValue:
                    'Select the drop-off location (address, place, or a just a city/district name, if the location is not determined yet).',
                }) as string
              }
              placeholderText={
                formik.values.dropOffLocation?.formattedAddress ||
                (t('jobs.dropOffLocationPlaceholder', {
                  defaultValue: 'Search for the drop-off address here',
                }) as string)
              }
              onPress={handleSelectLocation('dropOffLocation', 'dropOffLocationText')}
              language={i18n.language}
              isMandatory
              reverseGeocodeLocationTypes={[
                'airports',
                'localities',
                'small_regions',
                'large_regions',
                'street_addresses',
              ]}
              onChangeText={handleDropOffLocationTextChange}
              textInputProps={{
                value: formik.values.dropOffLocationText,
              }}
              errorMessage={formik?.errors.dropOffLocation}
            />
          )}

          <FormAirportSelect
            caption={
              t('jobs.endAirportCaption', {
                defaultValue: "Select the end airport of the Courier's journey.",
              }) as string
            }
            errorMessage={formik?.errors.endAirport}
            label={t('jobs.endAirportLabel', { defaultValue: 'End Airport' }) as string}
            loadOnOpen
            isMandatory
            onSelect={handleSelectAirport('endAirport')}
            placeholder={
              t('jobs.endAirportPlaceholder', {
                defaultValue: 'Search for the end airport here',
              }) as string
            }
            skipFetch
            value={formik.values.endAirport}
          />

          <View>
            <View style={globalStyle.flexRow}>
              <FormDatepicker
                containerStyle={globalStyle.flex1}
                label={
                  t('jobs.returnTimeAtStartAirportDateLabel', {
                    defaultValue: 'Return time at the start airport',
                  }) as string
                }
                caption={
                  t('jobs.returnTimeAtStartAirportDateCaption', {
                    defaultValue:
                      'Choose the date on which the Courier will be able to return to the start airport.',
                  }) as string
                }
                isMandatory
                max={MAX_DATE}
                onSelect={handleChangeDate('returnTimeAtStartAirportDate')}
                date={formik.values.returnTimeAtStartAirportDate}
                error={!!formik?.errors.returnTimeAtStartAirportDate}
              />
              <FormTimeSelect
                isMandatory
                containerStyle={globalStyle.marginLeft10}
                error={!!formik?.errors.returnTimeAtStartAirport}
                label={t('jobs.returnTime', { defaultValue: 'Return time' }) as string}
                onChange={(time: string) => {
                  formik.setFieldValue('returnTimeAtStartAirport', time);
                  if (formik?.errors.returnTimeAtStartAirport) {
                    setTimeout(() => {
                      formik.validateField('returnTimeAtStartAirport');
                    }, 50);
                  }
                }}
                value={formik.values.returnTimeAtStartAirport}
              />
            </View>
            {(formik?.errors.returnTimeAtStartAirportDate ||
              formik?.errors.returnTimeAtStartAirport) && (
              <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.errorContainer]}>
                {!!formik?.errors.returnTimeAtStartAirportDate && (
                  <Text style={globalStyle.errorMessageText}>
                    {formik?.errors.returnTimeAtStartAirportDate}
                  </Text>
                )}
                {!!formik?.errors.returnTimeAtStartAirport && (
                  <Text style={globalStyle.errorMessageText}>
                    {!!formik?.errors.returnTimeAtStartAirportDate && ' '}
                    {formik?.errors.returnTimeAtStartAirport}
                  </Text>
                )}
              </View>
            )}
          </View>

          <FormTextInputTopLabel
            label={t('jobs.dropOffAddressDescriptionLabel', {
              defaultValue: 'Drop-off / delivery location description',
            })}
            caption={t('jobs.dropOffAddressDescriptionCaption', {
              defaultValue:
                'Provide some more information about where to handover or drop off the items / goods.',
            })}
            textStyle={styles.textarea}
            multiline
            {...fieldProps('dropOffAddressDescription')}
          />
        </View>
      }
    />
  );

  const renderTransportGoods = () => (
    <ContentBoxTextTitle
      style={styles.contentBoxContainer}
      title={t('jobs.itemsGoodsTitle', { defaultValue: 'Items & Goods Details' }) as string}
      Content={
        <View>
          <FormSelect
            label={
              t('jobs.transportGoodsContentsLabel', {
                defaultValue: 'TRANSPORT GOODS CONTENTS',
              }) as string
            }
            fieldTitleKey="name"
            fieldValueKey="value"
            value={formik.values.transportedGoodsContentType}
            items={transportGoodsContentTypeList}
            onSelect={(value: any) => {
              formik.setFieldValue('transportedGoodsContentType', value);
            }}
            errorMessage={formik?.errors.transportedGoodsContentType}
          />

          <FormWeightInput
            label={
              t('jobs.weightOfItemsLabel', {
                defaultValue: 'Total weight of all items',
              }) as string
            }
            targetWeightUnit="KG"
            caption={
              t('jobs.weightOfItemsCaption', {
                defaultValue: 'Total weight of all items to be transported.',
              }) as string
            }
            displayNumberToWords
            onChangeValue={(value) => {
              formik.setFieldValue('weightOfItems', value);
            }}
            value={formik.values.weightOfItems}
          />

          <FormAddSubtractCounter
            onCountChange={(value) => {
              formik.setFieldValue('numOfItems', value);
            }}
            value={formik.values.numOfItems}
            maxCount={999}
            minCount={1}
            label={
              t('jobs.numOfItemsLabel', {
                defaultValue: 'Number of items to be transported',
              }) as string
            }
            caption={
              t('jobs.numOfItemsCaption', {
                defaultValue:
                  'Specify the number of items, bags, boxes etc. that need to be transported.',
              }) as string
            }
          />

          <FormAddSubtractCounter
            onCountChange={(value) => {
              formik.setFieldValue('numOfCouriersRequired', value);
            }}
            value={formik.values.numOfCouriersRequired}
            maxCount={10}
            minCount={1}
            label={
              t('jobs.numOfCouriersLabel', {
                defaultValue: 'Number of Couriers required',
              }) as string
            }
            caption={
              t('jobs.numOfCouriersCaption', {
                defaultValue: 'Specify the number Couriers that are required to fulfil this job.',
              }) as string
            }
            errorMessage={formik?.errors.numOfCouriersRequired}
          />
        </View>
      }
    />
  );

  const renderJobFees = () => (
    <ContentBoxTextTitle
      style={styles.contentBoxContainer}
      title={
        t('jobs.feesPaymentsTitle', {
          defaultValue: 'Fees & Payments',
        }) as string
      }
      Content={
        <View>
          <FormSelect
            label={
              t('jobs.clientAgencyCurrencyLabel', { defaultValue: 'Client Currency' }) as string
            }
            caption={
              t('jobs.clientAgencyCurrencyCaption', {
                defaultValue: 'The currency to get paid in by the Client.',
              }) as string
            }
            fieldTitleKey="name"
            fieldValueKey="value"
            value={formik.values.clientJobCurrency as string}
            items={currencyList}
            onSelect={(value: any) => {
              formik.setFieldValue('clientJobCurrency', value);
            }}
          />

          <FormSelect
            label={
              t('jobs.agencyCourierCurrencyLabel', { defaultValue: 'Courier Currency' }) as string
            }
            caption={
              t('jobs.agencyCourierCurrencyCaption', {
                defaultValue: 'The currency that is used to pay the Couriers with.',
              }) as string
            }
            fieldTitleKey="name"
            fieldValueKey="value"
            value={formik.values.currency as string}
            items={currencyList}
            onSelect={(value: any) => {
              formik.setFieldValue('currency', value);
            }}
          />

          <FormMoneyInput
            value={formik.values.hotelBudget}
            onChangeValue={(value) => {
              formik.setFieldValue('hotelBudget', value);
            }}
            label={
              t('jobs.hotelBudgetLabel', {
                defaultValue: 'Accommodation / hotel budget',
              }) as string
            }
            targetCurrency={formik.values.currency}
            caption={
              t('jobs.hotelBudgetCaption', {
                defaultValue:
                  'Specify the accommodation budget that is paid to the Courier if applicable.',
              }) as string
            }
            displayNumberToWords
          />

          <FormMoneyInput
            value={formik.values.fixedRate}
            onChangeValue={(value) => {
              formik.setFieldValue('fixedRate', value);
            }}
            label={
              t('jobs.fixedRateLabel', {
                defaultValue: 'Fixed rate / compensation',
              }) as string
            }
            targetCurrency={formik.values.currency}
            caption={
              t('jobs.fixedRateCaption', {
                defaultValue:
                  'Specify a fixed rate / lump sum compensation the Courier is offered for doing this Job.',
              }) as string
            }
            displayNumberToWords
          />

          <FormMoneyInput
            value={formik.values.dailyRate}
            onChangeValue={(value) => {
              formik.setFieldValue('dailyRate', value);
            }}
            label={
              t('jobs.dailyRateLabel', {
                defaultValue: 'Compensation per day',
              }) as string
            }
            targetCurrency={formik.values.currency}
            caption={
              t('jobs.dailyRateCaption', {
                defaultValue:
                  'Specify an amount the Courier is offered per started day for doing this Job.',
              }) as string
            }
            displayNumberToWords
          />

          <FormMoneyInput
            value={formik.values.kickOffFee}
            onChangeValue={(value) => {
              formik.setFieldValue('kickOffFee', value);
            }}
            label={
              t('jobs.kickOffFeeLabel', {
                defaultValue: 'Kick-off fee',
              }) as string
            }
            targetCurrency={formik.values.currency}
            caption={
              t('jobs.kickOffFeeCaption', {
                defaultValue:
                  'Specify a kick-off fee that the Courier is offered for accepting this Job.',
              }) as string
            }
            displayNumberToWords
          />
          <View style={styles.paddingBottom20}>
            <FormBinaryRadioGroup
              value={formik.values.isAccommodationBookedByCourier}
              onChange={(value) => {
                formik.setFieldValue('isAccommodationBookedByCourier', value);
                if (formik?.errors.isAccommodationBookedByCourier) {
                  setTimeout(() => {
                    formik.validateField('isAccommodationBookedByCourier');
                  }, 50);
                }
              }}
              label={
                t('jobs.isAccommodationBookedByCourierLabel', {
                  defaultValue: 'Who books the accommodation?',
                }) as string
              }
              caption={
                t('jobs.isAccommodationBookedByCourierCaption', {
                  defaultValue: 'Select which party books for the accommodation.',
                }) as string
              }
              falseOptionName={
                t('jobs.isAccommodationBookedByCourierFalse', {
                  defaultValue: 'Booked by the Agency',
                }) as string
              }
              trueOptionName={
                t('jobs.isAccommodationBookedByCourierTrue', {
                  defaultValue: 'Booked by the Courier',
                }) as string
              }
              keyPrefix="isAccommodationBookedByCourier"
              isMandatory
              errorMessage={formik?.errors.isAccommodationBookedByCourier}
            />
          </View>

          <View style={styles.paddingBottom20}>
            <FormBinaryRadioGroup
              value={formik.values.isAccommodationPaidByCourier}
              onChange={(value) => {
                formik.setFieldValue('isAccommodationPaidByCourier', value);
                if (formik?.errors.isAccommodationPaidByCourier) {
                  setTimeout(() => {
                    formik.validateField('isAccommodationPaidByCourier');
                  }, 50);
                }
              }}
              style={styles.paddingBottom20}
              label={
                t('jobs.isAccommodationPaidByCourierLabel', {
                  defaultValue: 'Who pays for accommodation?',
                }) as string
              }
              caption={
                t('jobs.isAccommodationPaidByCourierCaption', {
                  defaultValue: 'Select which party pays for the accommodation.',
                }) as string
              }
              falseOptionName={
                t('jobs.isAccommodationPaidByCourierFalse', {
                  defaultValue: 'Paid by the Agency',
                }) as string
              }
              trueOptionName={
                t('jobs.isAccommodationPaidByCourierTrue', {
                  defaultValue: 'Paid by the Courier',
                }) as string
              }
              keyPrefix="isAccommodationPaidByCourier"
              isMandatory
              errorMessage={formik?.errors.isAccommodationPaidByCourier}
            />
          </View>

          <FormBinaryRadioGroup
            value={formik.values.isAdditionalCostPaidByCourier}
            onChange={(value) => {
              formik.setFieldValue('isAdditionalCostPaidByCourier', value);
              if (formik?.errors.isAdditionalCostPaidByCourier) {
                setTimeout(() => {
                  formik.validateField('isAdditionalCostPaidByCourier');
                }, 50);
              }
            }}
            label={
              t('jobs.isAdditionalCostPaidByCourierLabel', {
                defaultValue: 'Who pays for additional costs?',
              }) as string
            }
            caption={
              t('jobs.isAdditionalCostPaidByCourierCaption', {
                defaultValue: 'The additional costs such as pickup, delivery, transfer, etc.',
              }) as string
            }
            trueOptionName={
              t('jobs.isAdditionalCostPaidByCourierFalse', {
                defaultValue: 'Paid by the courier',
              }) as string
            }
            falseOptionName={
              t('jobs.isAdditionalCostPaidByCourierTrue', {
                defaultValue: 'Paid by the Agency by receipts',
              }) as string
            }
            keyPrefix="isAdditionalCostPaidByCourier"
            isMandatory
            errorMessage={formik?.errors.isAdditionalCostPaidByCourier}
          />
          <FormTextInputTopLabel
            label={t('jobs.additionalCostInformationLabel', {
              defaultValue: 'Additional information on cost coverage',
            })}
            caption={t('jobs.additionalCostInformationCaption', {
              defaultValue: 'Provide some more information on additional cost coverage',
            })}
            textStyle={styles.textarea}
            multiline
            {...fieldProps('additionalCostInformation')}
          />
        </View>
      }
    />
  );

  const renderAdditionalDetails = () => (
    <ContentBoxTextTitle
      style={[styles.contentBoxContainer, styles.paddingBottom20, styles.marginBottom20]}
      title={
        t('jobs.Additional Details', {
          defaultValue: 'Additional Details',
        }) as string
      }
      Content={
        <FormTextInputTopLabel
          label={t('jobs.additionalDetailsAndJobDescriptionLabel', {
            defaultValue: 'Additional details and Job description',
          })}
          caption={t('jobs.additionalDetailsAndJobDescriptionCaption', {
            defaultValue:
              'Provide some more information the Job and provide all necessary information for the Courier.',
          })}
          textStyle={styles.textarea}
          multiline
          {...fieldProps('description')}
        />
      }
    />
  );

  const renderForm = () => (
    <KeyboardAwareScrollView
      enableAutomaticScroll
      enableOnAndroid
      style={styles.scrollView}
      extraScrollHeight={100}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      <View>
        <View style={styles.header}>
          <View style={[globalStyle.flexRow, globalStyle.justifyContentBetween]}>
            <Text selectable={true} style={styles.externalJobReference}>
              {formik.values.externalJobReference || ''}
            </Text>
            <SubscriptionPermissions
              warningContent={{
                cancelButtonText: t('common.close', { defaultValue: 'Close' }),
                confirmButtonText:
                  appUserContext.currentUserContext?.organizationUser?.roleType ===
                  OrganizationUserRoleType.OWNER
                    ? t('common.contactPriojet', {
                        defaultValue: 'Contact PRIOjet',
                      })
                    : '',
                title: '',
                text:
                  Platform.OS === 'web'
                    ? t('subscription.thisRunwayIsCloseAgencyGoldPlanContactSupportWeb', {
                        defaultValue:
                          'This runway is closed for you. This will be available if you have an Agency Gold Plan and up. Please contact PRIOjet support to upgrade.',
                      })
                    : t('subscription.thisRunwayIsCloseAgencyGoldPlanContactSupportMobile', {
                        defaultValue:
                          'This runway is closed for you. Contact PRIOjet support for more information.',
                      }),
                onConfirmPress: () => {
                  navigation.navigate('AgencyProfileNavigator', {
                    screen: 'CommonChatSupportPipedriveScreen',
                  });
                },
              }}
              feature={SubscriptionFeatureType.AGENCY_AGENCY_JOB_INTERNAL_NOTES}
              warningType="modal"
            >
              {({ checkPermissions, handleShowWarning }: any) => (
                <Button
                  appearance="outline"
                  onPress={async () => {
                    const hasPermission = await checkPermissions();
                    if (!hasPermission) {
                      handleShowWarning();
                    } else {
                      handleShowInternalNotesModal();
                    }
                  }}
                  style={globalStyle.height25}
                  size="small"
                >
                  <Foundation name="clipboard-pencil" size={24} color="black" />
                </Button>
              )}
            </SubscriptionPermissions>
          </View>

          {(!!formik.values.pickupTime || !!formik.values.pickupLocation) &&
            !!formik.values.startAirport && (
              <View>
                <Divider style={styles.marginVertical10} />
                <Text selectable={true} style={styles.label}>
                  {t('jobs.pickUpStart', { defaultValue: 'Pick-up / Start' }) as string}
                </Text>
              </View>
            )}

          {!!pickupTime && (
            <View style={styles.pickupTime}>
              <Entypo
                name="calendar"
                size={24}
                color={styles.iconColor.backgroundColor}
                style={styles.icon}
              />
              <Text selectable={true} style={[styles.flex1, styles.flexWrap]}>
                {pickupTime}
              </Text>
            </View>
          )}

          {!!formik.values.pickupLocation && (
            <View style={styles.locationLabel}>
              <MaterialCommunityIcons
                name="warehouse"
                size={24}
                color={styles.iconColor.backgroundColor}
                style={styles.icon}
              />
              <Text selectable={true} style={[styles.flex1, styles.flexWrap]}>
                {formik.values.pickupLocation.description ||
                  formik.values.pickupLocation.formattedAddress}
              </Text>
            </View>
          )}

          {!!formik.values.startAirport && (
            <>
              <Divider style={styles.marginVertical10} />
              <Text selectable={true} style={styles.label}>
                {t('jobs.startAirport', { defaultValue: 'Start Airport' }) as string}
              </Text>
            </>
          )}

          {!!formik.values.startAirport && (
            <View style={styles.locationLabel}>
              <Entypo
                name="aircraft-take-off"
                size={24}
                color={styles.iconColor.backgroundColor}
                style={styles.icon}
              />
              <Text selectable={true} style={styles.flex1}>
                {formik.values.startAirport.description ||
                  formik.values.startAirport.formattedAddress}
              </Text>
            </View>
          )}

          {!!formik.values.endAirport && (
            <>
              <Divider style={styles.marginVertical10} />
              <Text selectable={true} style={styles.label}>
                {t('jobs.endAirport', { defaultValue: 'End Airport' }) as string}
              </Text>
            </>
          )}

          {!!formik.values.endAirport && (
            <View style={styles.locationLabel}>
              <Entypo
                name="aircraft-landing"
                size={24}
                color={styles.iconColor.backgroundColor}
                style={styles.icon}
              />
              <Text selectable={true} style={styles.flex1}>
                {formik.values.endAirport.description || formik.values.endAirport.formattedAddress}
              </Text>
            </View>
          )}

          {!!formik.values.dropOffLocation && (
            <>
              <Divider style={styles.marginVertical10} />
              <Text selectable={true} style={styles.label}>
                {t('jobs.dropOffDelivery', { defaultValue: 'Drop-off / Delivery' }) as string}
              </Text>
            </>
          )}

          {!!formik.values.dropOffLocation && (
            <View style={styles.locationLabel}>
              <MaterialIcons
                name="location-pin"
                size={24}
                color={styles.iconColor.backgroundColor}
                style={styles.icon}
              />
              <Text selectable={true} style={[styles.flex1, styles.flexWrap]}>
                {formik.values.dropOffLocation.description ||
                  formik.values.dropOffLocation.formattedAddress}
              </Text>
            </View>
          )}

          {returnTimeAtStartAirport && !!formik.values.endAirport && (
            <>
              <Divider style={styles.marginVertical10} />
              <Text selectable={true} style={styles.label}>
                {
                  t('jobs.returnTimeAtStartAirportSummary', {
                    defaultValue: 'Estimated return time at the start airport',
                  }) as string
                }
              </Text>
              {returnTimeAtStartAirport && (
                <View style={styles.locationLabel}>
                  <FontAwesome
                    name="flag-checkered"
                    size={24}
                    color={styles.iconColor.backgroundColor}
                    style={styles.icon}
                  />
                  <Text selectable={true} style={[styles.flex1, styles.flexWrap]}>
                    {returnTimeAtStartAirport}
                  </Text>
                </View>
              )}
            </>
          )}
        </View>
      </View>

      <FormSelect
        appearance="default"
        label={t('jobs.jobAgent', { defaultValue: 'JOB AGENT/S' }) as string}
        containerStyle={globalStyle.marginBottom10}
        multiSelect
        onSelect={handleSelectJobAgent}
        fieldTitleKey="label"
        fieldValueKey="value"
        items={jobAgentsListData}
        errorMessage={formik?.errors.responsibleAgencyOrganizationUsers}
        value={formik.values.responsibleAgencyOrganizationUsers}
        isMandatory
      />

      <FormTextInputTopLabel
        label={t('jobs.jobTitle', { defaultValue: 'JOB TITLE/ REF. NO.' })}
        placeholder={
          t('jobs.jobTitlePlaceholder', {
            defaultValue: 'Your Job reference ID / Title',
          }) as string
        }
        caption={t('jobs.externalJobReference', {
          defaultValue: `Give this new Job a reference identifier (e.g. "{{organizationName}}-ABC123") or a title ("{{shortDate}} Paris to New York"). It will be used alongside with a PRIOjet Job identifier (e.g. PJ-XXXXX).`,
          shortDate: DateTime.now().setLocale(i18n.language).toLocaleString(DateTime.DATE_SHORT),
          organizationName: appUserContext.currentUserContext?.organization?.name || 'AGENCY-123',
        })}
        isMandatory
        maxLength={30}
        {...fieldProps('externalJobReference')}
      />

      {renderPickupFormPart()}
      {renderDropOffFormPart()}
      {renderTransportGoods()}
      {renderJobFees()}
      {renderAdditionalDetails()}
    </KeyboardAwareScrollView>
  );

  const renderContent = () => {
    if (agencyJobForAgencyOrganizationError) {
      return (
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={styles.padding10}
          keyboardShouldPersistTaps="handled"
          refreshControl={
            <RefreshControl
              refreshing={
                (agencyJobForAgencyOrganizationLoading || !isStateVariablesSet) &&
                (!route.params.agencyJobId || !!agencyJobForAgencyOrganizationData)
              }
              onRefresh={agencyJobForAgencyOrganizationRefetch}
            />
          }
        >
          <LoadingSpinner />
        </ScrollView>
      );
    }

    if (!isStateVariablesSet && agencyJobForAgencyOrganizationLoading) {
      return (
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          style={styles.padding10}
          keyboardShouldPersistTaps="handled"
        >
          <LoadingSpinner />
        </ScrollView>
      );
    }
    return renderForm();
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={
          agencyJobId && priojetJobIdentifier
            ? `${priojetJobIdentifier} / ${formik.values.externalJobReference}`
            : t('jobs.createNewJob', { defaultValue: 'Create new Job' })
        }
        navigation={navigation}
        onPressLeft={() => {
          if (navigation.canGoBack()) {
            navigation.goBack();
          } else {
            const { previousScreenName } = route.params;
            if (previousScreenName === 'AgencyJobsScreen') {
              navigation.navigate(previousScreenName, {
                previousTabScreenName: route.params?.previousTabScreenName,
              });
            } else {
              navigation.navigate(previousScreenName);
            }
          }
        }}
        accessoryRight={() => (
          <Button
            onPress={loading ? undefined : handleSave}
            appearance="filled"
            style={styles.saveBtn}
          >
            {loading ? (
              <ActivityIndicator color={(styles.loading as any).color} />
            ) : (
              (t('common.save', { defaultValue: 'Save' }) as string)
            )}
          </Button>
        )}
      />

      {!!courierOrganizationUserName && (
        <View style={styles.courierOrganizationUser}>
          <Text style={styles.courierOrganizationUserText}>
            {t('jobs.thisJobWillBeDirectlyOfferedTo', {
              defaultValue: 'This job will be directly offered to {{name}}',
              name: courierOrganizationUserName,
            })}
          </Text>
        </View>
      )}
      {renderContent()}

      <AgencyJobInternalNotesModal
        ref={internalNotesModalRef}
        onChangeText={(value: string) => {
          formik.setFieldValue('internalNotes', value);
        }}
      />
    </>
  );
};
