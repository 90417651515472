import React, { useState } from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';

import { CommonHomeTopNavigation } from '../../components/common/common.home-top-navigation.component';
import { CommonActivityBox } from '../../components/common/CommonActivityBox';
import { Greeting } from '../../components/common/greeting';
import { CourierAvailabilityBox } from '../../components/courier/courier.availability-box.component';
import { CourierJobsBox } from '../../components/courier/courier.jobs-box.component';

export const CourierHomeScreen = (): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  return (
    <>
      <CommonHomeTopNavigation />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              setTimeout(() => setRefreshing(false), 100);
            }}
          />
        }
      >
        <Greeting />
        <View key="layout-courierhomelayout" style={styles.container}>
          <CourierAvailabilityBox key="layout-CourierAvailabilityBox" refreshing={refreshing} />
          <CourierJobsBox key="layout-CourierJobsBox" refreshing={refreshing} />
          <CommonActivityBox key="layout-ActivityBox" refreshing={refreshing} />
        </View>
      </ScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 50,
    height: 13,
    width: '100%',
  },
  icon: {
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
