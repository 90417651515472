import React, { useEffect, useContext, createRef } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';

import { useApolloClient } from '@apollo/client';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import {
  CompositeNavigationProp,
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  NavigatorScreenParams,
} from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import { Text } from '@ui-kitten/components';
import * as Linking from 'expo-linking';
import * as ExpoNotifications from 'expo-notifications';

import { AccountActivationNavigator } from './account-activation.navigator';
import { AgencyNavigator } from './agency.navigator';
import { AgencySubscriptionAccountActivationNavigator } from './AgencySubscriptionActivationNavigator';
import { CourierNavigator } from './courier.navigator';
import { DefaultNavigator } from './default.navigator';
import { DeviceSetupNavigator } from './device-setup.navigator';
import { PriojetNavigator } from './priojet.navigator';
import { UnauthenticatedNavigator } from './unauthenticated.navigator';
import { globalStyle } from '../common/style';
import Colors from '../constants/Colors';
import AppContext from '../contexts/AppContext';
import AppUserContext from '../contexts/AppUserContext';
import ThemeContext from '../contexts/ThemeContext';
import { NotificationSystemFilterType } from '../generated-graphql-types';
import { DevicePermissionStatus, OrganizationType, User_user } from '../graphql-types';
import { InAppNotification, useInAppNotification } from '../hooks/useInAppNotification';
import { LocalUserContext, UserDocument, UserDocument2 } from '../types';

const StackNavigator = createStackNavigator<any>();

const prefixes: string[] = [
  Platform.select({
    web: Linking.createURL('/', { scheme: 'priojet' }),
    default: 'priojet://',
  }),
];

export type CommonProfileNavigatorParamList = {
  CommonAccountActivationScreen: undefined;
  CommonAppInformationScreen: undefined;
  CommonAppSettingsScreen: undefined;
  CommonAgencyAccountSettingsScreen: undefined;
  CommonCourierAccountSettingsScreen: undefined;
  CommonBugHelloNextScreen: undefined;
  CommonChangelogHelloNextScreen: undefined;
  CommonChatSupportPipedriveScreen: undefined;
  CommonCourierPastAvailabilitiesScreen: undefined;
  CommonCourierUpcomingAvailabilitiesScreen: undefined;
  CommonFeatureHelloNextScreen: undefined;
  CommonHelloNextScreen: undefined;
  CommonNotificationPermissionsScreen?: { canGoBack?: boolean; hideSettings?: boolean };
  CommonRoadmapHelloNextScreen: undefined;
  CommonSwitchAccountScreen: { reloadContext: boolean } | undefined;
  CourierTermsAndConditionsSignupScreen: undefined;
  AgencyTermsAndConditionsSignupScreen: undefined;
  CommonProfileScreen: undefined;
  CommonChangelogScreen: undefined;
  CommonRoadmapScreen: undefined;
  CommonGetSupportScreen: undefined;
};

export type CourierConnectedAgenciesScreenParamList = {
  CourierConnectedAgenciesConnectionsScreen: undefined;
  CourierAddAgencyConnectionScreen: undefined;
  AgencyCourierUserProfileScreen: undefined;
  AgencyCourierOrganizationProfileScreen: undefined;
};

export type CourierCourierJobNavigatorParamList = {
  CourierCourierLocationsScreen: {
    courierJobId: string;
  };
  CourierCourierDetailsScreen: {
    courierJobId: string;
  };
  CourierCourierJobsScreen: {
    courierJobId: string;
  };
  param: {
    courierJobId: string;
  };
};

export type CourierHomeNavigatorParamList = {
  CourierHomeScreen: undefined;
  CommonActivityScreen: {
    filter?: NotificationSystemFilterType[];
  };
  CourierCourierJobAgencyJobChatScreen: {
    previousScreenName: 'CourierCourierJobsScreen' | 'CourierHomeScreen';
    previousTabScreenName?:
      | 'CourierCourierJobsNewScreen'
      | 'CourierCourierJobsRunningScreen'
      | 'CourierCourierJobsPastScreen'
      | 'CourierCourierJobsCancelledScreen';
    previousNavigator?: 'CourierHomeNavigator';
    chatId?: string;
    courierJobId: string;
  };
  CourierAgencyJobChatScreen: {
    previousScreenName: 'CourierCourierJobsScreen' | 'CourierHomeScreen';
    previousTabScreenName?:
      | 'CourierCourierJobsNewScreen'
      | 'CourierCourierJobsRunningScreen'
      | 'CourierCourierJobsPastScreen'
      | 'CourierCourierJobsCancelledScreen';
    previousNavigator?: 'CourierHomeNavigator';
    chatId?: string;
    courierJobId: string;
  };
  CourierCourierJobScreen: {
    courierJobId: string;
    previousScreenName:
      | 'CourierCourierJobsScreen'
      | 'CourierHomeScreen'
      | 'CommonJobAgencyJobChatScreen';
    previousNavigator: 'CourierHomeNavigator';
  };
  CourierCourierJobsScreen: undefined;
  CommonProfileNavigator: undefined;
  CommonNotificationPermissionsScreen?: { canGoBack?: boolean; hideSettings?: boolean };
};

export type CourierProfileNavigatorParamList = Omit<
  CommonProfileNavigatorParamList,
  'CommonProfileScreen'
> & {
  CourierProfileScreen: undefined;
  CourierSettingsScreen: undefined;
  CourierProfileEditScreen: undefined;
  CourierAgencyProfileScreen: undefined;
  CourierConnectedAgenciesScreen: NavigatorScreenParams<CourierConnectedAgenciesScreenParamList>;
  CommonNotificationPermissionsScreen?: { canGoBack?: boolean; hideSettings?: boolean };
  CourierUserIdentityDocumentsScreen: {
    readOnly: boolean;
    userId: string;
  };
  CourierUserTravelPermitsScreen: undefined;
  CourierFrequentFlyerDocumentsScreen: undefined;
  CourierVaccinationCertificatesScreen: undefined;
  CourierHealthTestReportsScreen: undefined;
  CourierBackgroundDocumentsScreen: undefined;
  CourierOtherDocumentsScreen: undefined;
  CourierAddUserIdentityDocumentScreen: undefined;
  CommonUserDocumentDetailScreen: {
    readOnly: boolean;
    userDocument: UserDocument2 | undefined;
    userDocumentId: string | undefined;
    targetDocument: string | undefined;
    title: string;
    user: User_user | undefined;
    userIdentityDocuments: UserDocument2[] | undefined;
    previousScreenName?:
      | 'CourierUserTravelPermitsScreen'
      | 'CourierFrequentFlyerDocumentsScreen'
      | 'CourierHealthTestReportsScreen'
      | 'CourierVaccinationCertificatesScreen'
      | 'CourierUserIdentityDocumentsScreen'
      | 'CourierOtherDocumentsScreen';
  };
  CommonProfileNavigator: NavigatorScreenParams<CommonProfileNavigatorParamList>;
};

export type AgencyConnectedCourierOrganizationsScreenParamList = {
  AgencyConnectedCourierOrganizationConnectionsScreen: undefined;
  AgencyConnectedCourierOrganizationInvitationsScreen: undefined;
  AgencyCourierUserProfileScreen: {
    organizationId?: string;
    userId: string;
  };
  AgencyCourierOrganizationProfileScreen: {
    courierOrganizationId: string;
  };
};

export type AgencyProfileNavigatorParamList = Omit<
  CommonProfileNavigatorParamList,
  'CommonProfileScreen'
> & {
  AgencyProfileScreen: undefined;
  AgencyConnectedCourierOrganizationsScreen: NavigatorScreenParams<AgencyConnectedCourierOrganizationsScreenParamList>;
  AgencyAgencyProfileScreen: undefined;
  AgencyAgencyProfileReadOnlyScreen: {
    agencyOrganizationId: string;
    previousNavigator: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyProfileScreen';
  };
  AgencyManageCourierCategoriesScreen: {
    previousNavigator?: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyProfileScreen';
  };
  AgencyAgencyUserMyProfileScreen: {
    organizationUserId: string;
    previousNavigator?: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyProfileScreen' | 'AgencyViewAgencyUsersScreen';
  };
  AgencyCourierOrganizationInvitationsScreen: undefined;
  AgencyManageAgencyUsersScreen: undefined;
  AgencyOurProfile: undefined;
  AgencyReadCourierUserDocumentScreen: {
    previousNavigator?: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyCourierUserDocumentsScreen';
    userDocument: UserDocument2;
    userDocumentId: string;
    courierUserId: string;
    agencyJobId?: string;
    userDocumentType: string;
    title: string;
  };
  AgencyCourierUserProfileScreen: {
    organizationId?: string;
    userId: string;
    previousNavigator?: string;
    previousScreenName?: string;
  };
  AgencyCourierUserDocumentsScreen: {
    courierUserId: string;
    agencyJobId?: string;
    userDocumentType: string;
    previousNavigator?: string;
    previousScreenName?: string;
    parentScreenName?: string;
  };
  AgencyCourierDocumentsMenuScreen: {
    courierUserId: string;
    agencyJobId?: string;
    previousNavigator?: string;
    previousScreenName?: string;
    parentScreenName?: string;
  };
  CommonProfileNavigator: NavigatorScreenParams<CommonProfileNavigatorParamList>;
  AgencyViewAgencyUsersScreen: undefined;
  AgencyAgencyUserInvitationsScreen: undefined;
  CommonNotificationPermissionsScreen?: { canGoBack?: boolean; hideSettings?: boolean };
};

export type PriojetOrganizationInvitationsScreenParamList = {
  Reserved: undefined;
  Used: undefined;
  Voided: undefined;
};

export type PriojetHomeNavigatorParamList = {
  PriojetHomeScreen: undefined;
  PriojetDeletedAccountsScreen: {
    organizationType: OrganizationType;
    type: 'organization' | 'user';
  };
};

export type PriojetProfileNavigatorParamList = Omit<
  CommonProfileNavigatorParamList,
  'CommonProfileScreen'
> & {
  PriojetProfileScreen: undefined;
  PriojetOrganizationInvitationsNavigator: undefined;
  PriojetOrganizationInvitationsScreen: NavigatorScreenParams<PriojetOrganizationInvitationsScreenParamList>;
  PriojetManagePriojetUsersScreen: undefined;
  PriojetManageUsersScreen: undefined;
  PriojetManageOrganizationsUsersScreen: undefined;
  CommonProfileNavigator: NavigatorScreenParams<CommonProfileNavigatorParamList>;
};

export type DefaultProfileNavigatorParamList = {
  CommonAppInformationScreen: undefined;
  CommonSwitchAccountScreen: { reloadContext: boolean } | undefined;
  CourierTermsAndConditionsSignupScreen: undefined;
  AgencyTermsAndConditionsSignupScreen: undefined;
  CommonUnauthenticatedSwitchAccountScreen: undefined;
};

export type RootStackParamList = {
  AgencyConnectedCourierOrganizationsScreen: undefined;
  AgencyManageCourierCategoriesScreen: {
    previousNavigator?: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyProfileScreen';
  };
  AgencyReadCourierUserDocumentScreen: {
    previousNavigator: 'AgencyProfileNavigator';
    parentScreenName?: string;
    previousScreenName?: string;
    userDocument: UserDocument2;
    userDocumentId: string;
    courierUserId: string;
    agencyJobId?: string;
    userDocumentType: string;
    title: string;
  };
  AgencyCourierUserDocumentsScreen: {
    userDocumentType: string;
    courierUserId: string;
    agencyJobId?: string;
  };
  AgencyCourierDocumentsMenuScreen: {
    courierUserId: string;
    agencyJobId?: string;
    previousNavigator?: string;
    previousScreenName?: string;
  };
  AgencyCreateJobScreen: {
    agencyJobId?: string;
    courierOrganizationUserId?: string;
    courierOrganizationUserName?: string;
    previousScreenName: 'AgencyJobsScreen' | 'AgencyHomeScreen' | 'AgencyOBCAvailabilityMapScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
  };
  AgencyUpdateJobScreen: {
    agencyJobId: string;
    previousScreenName: 'AgencyJobsScreen' | 'AgencyHomeScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
  };
  AgencyAgencyJobPlanningScreen: {
    agencyJobId: string;
    previousScreenName:
      | 'AgencyJobsScreen'
      | 'AgencyHomeScreen'
      | 'AgencyAgencyJobChatScreen'
      | 'AgencyJobsScreen,AgencyAgencyJobChatScreen'
      | 'AgencyJobsScreen,AgencyJobsScreen,AgencyAgencyJobChatScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
  };

  AgencyViewCourierJobScreen: {
    agencyJobId: string;
    refetch?: boolean;
    previousScreenName: 'AgencyJobsScreen' | 'AgencyHomeScreen' | 'CommonJobAgencyJobChatScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
    previousNavigator: 'AgencyHomeNavigator';
  };
  AgencyCourierAvailabilitiesScreen: undefined;
  AgencyManageAgencyUsersScreen: undefined;
  CourierAgencyInvitationsScreen: undefined;
  AgencyJobsScreen: {
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
    refetch?: boolean;
  };
  AgencyOBCAvailabilityMapScreen: {
    previousScreenName: 'AgencyHomeScreen';
  };
  AddOrDisableCourierJobScreen: {
    agencyJobId: string;
    previousScreenName:
      | 'AgencyCourierJobsScreen'
      | 'AgencyJobsScreen'
      | 'AgencyViewCourierJobScreen';
    previousTabScreenName?:
      | 'AgencyCourierJobsPendingScreen'
      | 'AgencyCourierJobsRequestedScreen'
      | 'AgencyCourierJobsAcceptedScreen'
      | 'AgencyCourierJobsDeclinedScreen'
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
  };
  AgencyCourierJobsScreen: {
    agencyJobId: string;
    previousScreenName:
      | 'AgencyJobsScreen'
      | 'AgencyHomeScreen'
      | 'AgencyViewCourierJobScreen'
      | 'AgencyCourierJobOverviewScreen';
    tabScreenName?:
      | 'AgencyCourierJobsPendingScreen'
      | 'AgencyCourierJobsRequestedScreen'
      | 'AgencyCourierJobsAcceptedScreen'
      | 'AgencyCourierJobsDeclinedScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
  };
  AgencyCourierJobAgencyJobChatScreen: {
    previousScreenName: 'AgencyCourierJobsScreen' | 'AgencyHomeScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
    previousNavigator: 'AgencyHomeNavigator';
    agencyJobId: string;
    courierJobId: string;
    chatId?: string;
  };
  AgencyAgencyJobChatScreen: {
    previousScreenName: 'AgencyCourierJobsScreen' | 'AgencyHomeScreen';
    previousTabScreenName?:
      | 'AgencyNewJobsScreen'
      | 'AgencyStartedJobsScreen'
      | 'AgencyFinishedJobsScreen'
      | 'AgencyCancelledJobsScreen';
    previousNavigator: 'AgencyHomeNavigator';
    agencyJobId: string;
    courierJobId: string;
    chatId?: string;
  };
  AgencyNewJobsScreen: undefined;
  AgencyStartedJobsScreen: undefined;
  AgencyFinishedJobsScreen: undefined;
  AgencyCancelledJobsScreen: undefined;
  AgencyCourierAvailabilitiesNavigator: undefined;
  AgencyCourierOrganizationInvitationsScreen: undefined;
  AgencyCourierOrganizationProfileScreen: {
    courierOrganizationId: string;
  };
  AgencyCourierUserProfileScreen: {
    organizationId?: string;
    userId: string;
    agencyJobId?: string;
    previousNavigator?: string;
    previousScreenName?: string;
  };
  AgencyHomeNavigator: undefined;
  AgencyHomeScreen: undefined;
  AgencyProfileNavigator: NavigatorScreenParams<AgencyProfileNavigatorParamList>;
  AgencyProfileScreen: undefined;
  AgencyAgencyProfileScreen: undefined;
  AgencyAgencyUserMyProfileScreen: {
    organizationUserId: string;
    previousNavigator?: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyProfileScreen' | 'AgencyViewAgencyUsersScreen';
  };
  AgencyAgencyProfileReadOnlyScreen: {
    agencyOrganizationId: string;
    previousNavigator: 'AgencyProfileNavigator';
    previousScreenName?: 'AgencyProfileScreen';
  };
  AgencySubscriptionAccountActivation: undefined;
  AgencySubscriptionSelectPlan: undefined;
  AgencySubscriptionBookPlan: undefined;
  CommonProfileNavigator: NavigatorScreenParams<CommonProfileNavigatorParamList>;
  CommonAppLoadingScreen: undefined;
  DefaultProfileNavigator: NavigatorScreenParams<DefaultProfileNavigatorParamList>;
  CommonAccountActivationScreen: undefined;
  CommonAppInformationScreen: undefined;
  CommonCourierPastAvailabilitiesScreen: undefined;
  CommonCourierUpcomingAvailabilitiesScreen: undefined;
  CommonActivityScreen: {
    filter?: NotificationSystemFilterType[];
  };
  CommonNotificationPermissionsScreen?: { canGoBack?: boolean; hideSettings?: boolean };
  CommonUnauthenticatedSwitchAccountScreen: undefined;
  CommonSwitchAccountScreen: { reloadContext: boolean } | undefined;
  CourierTermsAndConditionsSignupScreen: undefined;
  AgencyTermsAndConditionsSignupScreen: undefined;
  CommonHelloNextScreen: undefined;
  CommonAppSettingsScreen: undefined;
  CommonAgencyAccountSettingsScreen: undefined;
  CommonCourierAccountSettingsScreen: undefined;
  CommonGetSupportScreen: undefined;
  CommonGiveFeedbackScreen: undefined;
  UnauthenticatedNavigator: NavigatorScreenParams<CommonProfileNavigatorParamList>;
  CourierAgencyProfileScreen: {
    agencyOrganizationId: string;
    previousScreenName?: 'CourierConnectedAgenciesScreen';
    previousScreenTabName?: 'CourierAddAgencyConnectionScreen';
  };
  CourierAvailabilitiesNavigator: any;
  CourierAvailabilitiesScreen?: { availabilityCreated?: boolean };
  CourierAvailabilityScreen: undefined;
  CourierAvailabilityNavigator: undefined;
  CourierCreateAvailabilityScreen: undefined;
  CourierHomeNavigator: NavigatorScreenParams<CourierHomeNavigatorParamList>;
  CourierHomeScreen: undefined;
  CourierCourierJobsScreen: {
    screen?:
      | 'CourierCourierJobsNewScreen'
      | 'CourierCourierJobsRunningScreen'
      | 'CourierCourierJobsPastScreen'
      | 'CourierCourierJobsDeclinedScreen';
  };
  CourierCourierJobScreen: {
    courierJobId: string;
    previousScreenName: 'CourierCourierJobsScreen' | 'CourierHomeScreen';
    previousNavigator: 'CourierHomeNavigator';
  };
  CourierCourierJobDetailsScreen: undefined;
  CourierCourierJobJourneyScreen: undefined;
  CourierCourierJobAgencyJobChatScreen: {
    previousScreenName: 'CourierCourierJobsScreen' | 'CourierHomeScreen';
    previousTabScreenName?:
      | 'CourierCourierJobsNewScreen'
      | 'CourierCourierJobsRunningScreen'
      | 'CourierCourierJobsPastScreen'
      | 'CourierCourierJobsCancelledScreen'
      | 'CourierHomeScreen';
    previousNavigator?: 'CourierHomeNavigator';
    chatId?: string;
    courierJobId: string;
  };
  CourierAgencyJobChatScreen: {
    previousScreenName: 'CourierCourierJobsScreen' | 'CourierHomeScreen';
    previousTabScreenName?:
      | 'CourierCourierJobsNewScreen'
      | 'CourierCourierJobsRunningScreen'
      | 'CourierCourierJobsPastScreen'
      | 'CourierCourierJobsCancelledScreen'
      | 'CourierHomeScreen';
    previousNavigator?: 'CourierHomeNavigator';
    chatId?: string;
    courierJobId: string;
  };
  CourierProfileEditScreen: undefined;
  CourierProfileNavigator: NavigatorScreenParams<CourierProfileNavigatorParamList>;
  CourierProfileScreen: undefined;
  CourierSettingsScreen: undefined;
  CourierUserIdentityDocumentsScreen: undefined;
  CourierUserTravelPermitsScreen: undefined;
  CourierFrequentFlyerDocumentsScreen: undefined;
  CourierVaccinationCertificatesScreen: undefined;
  CourierHealthTestReportsScreen: undefined;
  CourierBackgroundDocumentsScreen: undefined;
  CourierOtherDocumentsScreen: undefined;
  CourierAddUserIdentityDocumentScreen: {
    readOnly: boolean;
    userIdentityDocument: UserDocument;
  };
  CommonUserDocumentDetailScreen: {
    readOnly: boolean;
    userDocument: UserDocument2 | undefined;
    userDocumentId: string | undefined;
    targetDocument: string | undefined;
    title: string;
    user: User_user | undefined;
    userIdentityDocuments: UserDocument2[] | undefined;
    previousScreenName?: string;
  };
  CommonFeatureHelloNextScreen: undefined;
  CommonBugHelloNextScreen: undefined;
  CommonChangelogHelloNextScreen: undefined;
  initialRouteName: undefined;
  PriojetProfileScreen: undefined;
  PriojetHomeNavigator: NavigatorScreenParams<PriojetHomeNavigatorParamList>;
  PriojetProfileNavigator: NavigatorScreenParams<PriojetProfileNavigatorParamList>;
  PriojetEditOrganizationInvitationScreen: {
    organizationInvitationId: string;
  };
  PriojetCreateOrganizationInvitationScreen: {
    organizationInvitationId: string;
  };
  PriojetCourierAvailabilitiesNavigator: undefined;
  PriojetCourierAvailabilitiesScreen: undefined;
  PriojetManagePriojetUsersScreen: undefined;
  PriojetManageUsersScreen: undefined;
  PriojetManageOrganizationsScreen: undefined;

  PriojetHomeScreen: undefined;
  PriojetDeletedAccountsScreen: {
    organizationType: OrganizationType;
    type: 'organization' | 'user';
  };

  RequestedJobAgencyCourierOrganizationsScreen: undefined;
  RunningJobAgencyCourierOrganizationsScreen: undefined;
  UpcomingJobAgencyCourierOrganizationsScreen: undefined;
  PastJobAgencyCourierOrganizationsScreen: undefined;

  RootScreen: undefined;
  NotFound: undefined;
};

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export type AppNavigatorProp<RouteName extends keyof RootStackParamList = any> =
  StackNavigationProp<RootStackParamList, RouteName>;

export type CourierHomeScreenNavigationProp = CompositeNavigationProp<
  BottomTabNavigationProp<RootStackParamList, 'CourierHomeNavigator'>,
  StackNavigationProp<RootStackParamList>
>;

const linkingCommonNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      CommonProfileNavigator: {
        path: '/',
        screens: {
          CommonProfileScreen: 'profile',
          CommonAppInformationScreen: 'app-information',
          CommonSwitchAccountScreen: 'switch-account',
          CourierTermsAndConditionsSignupScreen: 'courier-signup',
          AgencyTermsAndConditionsSignupScreen: 'agency-signup',
          CommonAppSettingsScreen: 'app-settings',
          CommonAgencyAccountSettingsScreen: 'agency-account-settings',
          CommonCourierAccountSettingsScreen: 'courier-account-settings',
          CommonChatSupportPipedriveScreen: 'get-support',
        },
      },
    },
  },
};

const linkingDefaultNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      DefaultProfileNavigator: {
        screens: {
          CommonAppInformationScreen: '/app-information',
          CommonSwitchAccountScreen: '/switch-account',
          CourierTermsAndConditionsSignupScreen: '/courier-signup',
          AgencyTermsAndConditionsSignupScreen: '/agency-signup',
          CommonUnauthenticatedSwitchAccountScreen: '/',
        },
      },
    },
  },
};

const linkingCourierNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      RootScreen: {
        path: '/',
        screens: {
          CourierHomeNavigator: {
            path: 'courier',
            screens: {
              CourierHomeScreen: '/',
              CommonActivityScreen: '/activity',
              CourierCourierJobsScreen: {
                path: 'jobs',
                screens: {
                  CourierCourierJobsNewScreen: 'new',
                  CourierCourierJobsRunningScreen: 'running',
                  CourierCourierJobsPastScreen: 'past',
                  CourierCourierJobsDeclinedScreen: 'declined',
                },
              },
              CourierCourierJobAgencyJobChatScreen: 'jobs/courier-job/:courierJobId/chat',
              CourierAgencyJobChatScreen: 'jobs/agency-job/:agencyJobId/chat',
              CourierCourierJobScreen: {
                path: 'jobs/:courierJobId',
                screens: {
                  CourierJobDetailsScreen: '/',
                  CourierJobJourneyScreen: 'journey',
                },
              },
            },
          },
          CourierAvailabilitiesNavigator: {
            path: '/courier/availabilities',
            screens: {
              CourierCreateAvailabilityScreen: 'new',
              CourierAvailabilitiesScreen: {
                path: '/',
                screens: {
                  CommonCourierUpcomingAvailabilitiesScreen: 'upcoming',
                  CommonCourierPastAvailabilitiesScreen: 'past',
                },
              },
            },
          },
          CourierProfileNavigator: {
            path: 'courier/profile',
            screens: {
              CourierProfileScreen: '/',
              CourierSettingsScreen: 'settings',
              CourierProfileEditScreen: 'my-profile',
              CourierAgencyProfileScreen: 'agency-profile/:agencyOrganizationId',
              CourierConnectedAgenciesScreen: {
                path: 'connected-agencies',
                screens: {
                  CourierConnectedAgenciesConnectionsScreen: 'connections',
                  CourierAddAgencyConnectionScreen: 'add-agency',
                },
              },
              CommonNotificationPermissionsScreen: 'notifications',
              CourierUserIdentityDocumentsScreen: 'identity-documents',
              CourierUserTravelPermitsScreen: 'travel-documents',
              CourierFrequentFlyerDocumentsScreen: 'frequent-flyer-documents',
              CourierVaccinationCertificatesScreen: 'vaccination-documents',
              CourierHealthTestReportsScreen: 'health-reports',
              CourierBackgroundDocumentsScreen: 'background-documents',
              CourierOtherDocumentsScreen: 'other-documents',
              CourierAddUserIdentityDocumentScreen: 'add-identity-document',
              CommonUserDocumentDetailScreen: 'view-document',
              CommonProfileNavigator: {
                screens: {
                  CommonAppInformationScreen: 'app-information',
                  CommonSwitchAccountScreen: 'switch-account',
                  CourierTermsAndConditionsSignupScreen: 'courier-signup',
                  AgencyTermsAndConditionsSignupScreen: 'agency-signup',
                  CommonAppSettingsScreen: 'app-settings',
                  CommonAgencyAccountSettingsScreen: 'agency-account-settings',
                  CommonCourierAccountSettingsScreen: 'courier-account-settings',
                  CommonFeatureHelloNextScreen: 'feature-request',
                  CommonBugHelloNextScreen: 'bug-report',
                  CommonChangelogScreen: 'changelog',
                  CommonChatSupportPipedriveScreen: 'get-support',
                  CommonRoadmapScreen: 'roadmap',
                },
              },
            },
          },
        },
      },
    },
  },
};

const linkingSetupDeviceNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      CommonNotificationPermissionsScreen: 'notification-permissions',
      CommonAppInformationScreen: 'app-information',
      CommonUnauthenticatedSwitchAccountScreen: 'profile',
      CommonProfileNavigator: {
        path: '/',
        screens: {
          CommonSwitchAccountScreen: 'switch-account',
          CourierTermsAndConditionsSignupScreen: 'courier-signup',
          AgencyTermsAndConditionsSignupScreen: 'agency-signup',
          CommonAppSettingsScreen: 'app-settings',
          CommonAgencyAccountSettingsScreen: 'agency-account-settings',
          CommonCourierAccountSettingsScreen: 'courier-account-settings',
          CommonChatSupportPipedriveScreen: 'get-support',
        },
      },
    },
  },
};

const linkingAccountActivationNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      CommonAccountActivationScreen: 'activate-account',
      CommonProfileNavigator: {
        path: '/',
        screens: {
          CommonSwitchAccountScreen: 'switch-account',
          CourierTermsAndConditionsSignupScreen: 'courier-signup',
          AgencyTermsAndConditionsSignupScreen: 'agency-signup',
          CommonAppSettingsScreen: 'app-settings',
          CommonAgencyAccountSettingsScreen: 'agency-account-settings',
          CommonCourierAccountSettingsScreen: 'courier-account-settings',
          CommonGetSupportScreen: 'get-support',
          CommonAppInformationScreen: 'app-information',
        },
      },
    },
  },
};

const linkingAgencySubscriptionNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      AgencySubscriptionAccountActivation: {
        path: 'agency',
        screens: {
          AgencySubscriptionSelectPlan: 'choose-plan',
        },
      },
      CommonProfileNavigator: {
        screens: {
          CommonSwitchAccountScreen: 'switch-account',
          CourierTermsAndConditionsSignupScreen: 'courier-signup',
          AgencyTermsAndConditionsSignupScreen: 'agency-signup',
          CommonAppSettingsScreen: 'app-settings',
          CommonAgencyAccountSettingsScreen: 'agency-account-settings',
          CommonCourierAccountSettingsScreen: 'courier-account-settings',
          CommonGetSupportScreen: 'get-support',
          CommonAppInformationScreen: 'app-information',
        },
      },
    },
  },
};

const linkingAgencyNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      RootScreen: {
        path: '/',
        screens: {
          AgencyHomeNavigator: {
            path: 'agency',
            screens: {
              CommonActivityScreen: '/activity',
              AgencyHomeScreen: '/',
              AgencyJobsScreen: {
                path: 'jobs',
                screens: {
                  AgencyNewJobsScreen: 'new',
                  AgencyStartedJobsScreen: 'started',
                  AgencyFinishedJobsScreen: 'finished',
                  AgencyCancelledJobsScreen: 'cancelled',
                },
              },
              AgencyOBCAvailabilityMapScreen: {
                path: 'agency-obc-availability-map',
              },
              AgencyCourierJobsScreen: {
                path: 'jobs/:agencyJobId/couriers',
                screens: {
                  AgencyCourierJobsPendingScreen: 'pending',
                  AgencyCourierJobsRequestedScreen: 'requested',
                  AgencyCourierJobsAcceptedScreen: 'confirmed',
                  AgencyCourierJobsDeclinedScreen: 'declined',
                },
              },
              AgencyCreateJobScreen: 'jobs/create',
              AgencyViewJobScreen: {
                path: 'jobs/:agencyJobId',
                screens: {
                  CourierJobDetailsScreen: '/',
                  CourierJobJourneyScreen: 'journey',
                },
              },
              AgencyUpdateJobScreen: 'jobs/:agencyJobId/edit',
              AgencyAgencyJobPlanningScreen: 'jobs/:agencyJobId/planning',
              AgencyCourierJobAgencyJobChatScreen:
                'jobs/agency-job/:agencyJobId/courier-job/:courierJobId/chat',
              AgencyAgencyJobChatScreen: 'jobs/agency-job/:agencyJobId/chat',
              AddOrDisableCourierJobScreen: 'jobs/:agencyJobId/add-or-remove-couriers',
              AgencyJobCourierListScreen: 'proposed-couriers',
              AgencyReadCourierUserDocumentScreen: 'view-document',
              AgencyCourierUserDocumentsScreen: 'courier-documents/list',
              AgencyCourierDocumentsMenuScreen: 'courier-documents',
              AgencyCourierUserProfileScreen: {
                path: 'courier-profile/:userId',
              },
            },
          },
          AgencyProfileNavigator: {
            path: '/agency/profile',
            screens: {
              AgencyProfileScreen: '/',
              AgencyCourierUserProfileScreen: {
                path: 'courier-profile/:userId',
              },
              AgencyAgencyUserMyProfileScreen: 'agency-user-my-profile',
              AgencyConnectedCourierOrganizationsScreen: {
                path: 'connected-couriers/',
                screens: {
                  AgencyConnectedCourierOrganizationConnectionsScreen: '/',
                  AgencyConnectedCourierOrganizationInvitationsScreen: 'invitations',
                  AgencyCourierUserProfileScreen: 'courier-user-profile/:userId',
                  AgencyCourierOrganizationProfileScreen:
                    'courier-organization/:courierOrganizationId',
                },
              },
              AgencyManageCourierCategoriesScreen: 'agency-manage-courier-categories',
              AgencyCourierOrganizationInvitationsScreen: {
                path: 'courier-invitations',
                screens: {
                  AgencyPhoneBookContactsScreen: 'phone-book-contacts',
                  AgencyCourierInvitationPhoneBookContactsScreen: 'agency-courier-invitations',
                  AgencyOpenOrganizationInvitationsScreen: 'open-invitations',
                },
              },
              AgencyManageAgencyUsersScreen: {
                path: 'manage-users',
              },
              // AgencySettingsScreen: 'settings',
              AgencyOurProfile: 'our-profile',
              AgencyReadCourierUserDocumentScreen: 'view-document',
              AgencyCourierUserDocumentsScreen: 'courier-documents/list',
              AgencyCourierDocumentsMenuScreen: 'courier-documents',
              CommonProfileNavigator: {
                screens: {
                  CommonSwitchAccountScreen: 'switch-account',
                  CourierTermsAndConditionsSignupScreen: 'courier-signup',
                  AgencyTermsAndConditionsSignupScreen: 'agency-signup',
                  CommonAppSettingsScreen: 'app-settings',
                  CommonAgencyAccountSettingsScreen: 'agency-account-settings',
                  CommonCourierAccountSettingsScreen: 'courier-account-settings',
                  CommonAppInformationScreen: 'app-information',
                },
              },
              AgencyViewAgencyUsersScreen: 'agency-users',
              AgencyAgencyUserInvitationsScreen: {
                path: 'agency-user-invitations',
                screens: {
                  AgencyPhoneBookContactsScreen: 'phone-book-contacts',
                  AgencyOpenOrganizationInvitationsScreen: 'open-invitations',
                },
              },
              CommonNotificationPermissionsScreen: 'notifications',
            },
          },
          AgencyCourierAvailabilitiesNavigator: {
            path: 'agency/courier-availabilities',
            screens: {
              AgencyCourierAvailabilitiesScreen: {
                screens: {
                  CommonCourierUpcomingAvailabilitiesScreen: 'upcoming',
                  CommonCourierPastAvailabilitiesScreen: 'past',
                },
              },
              AgencyCourierUserProfileScreen: {
                path: 'courier-profile/:userId',
              },
              AgencyCourierUserDocumentsScreen: {
                path: 'courier-profile/:userId/courier-documents',
              },
            },
          },
        },
      },
    },
  },
};

const linkingPriojetNavigator: LinkingOptions<RootStackParamList> = {
  prefixes,
  config: {
    screens: {
      PriojetHomeNavigator: {
        path: 'priojet',
        screens: {
          PriojetHomeScreen: '/',
          PriojetDeletedAccountsScreen: {
            path: 'deleted-accounts',
          },
        },
      },

      PriojetProfileNavigator: {
        path: 'priojet/profile',
        screens: {
          PriojetProfileScreen: '/',
          PriojetOrganizationInvitationsNavigator: {
            path: 'organization-invitations',
            screens: {
              PriojetOrganizationInvitationsScreen: {
                path: '/',
                screens: {
                  Reserved: 'reserved',
                  Used: 'used',
                  Voided: 'voided',
                },
              },
              PriojetCreateOrganizationInvitationScreen: 'create',
              PriojetEditOrganizationInvitationScreen: 'edit',
            },
          },
          PriojetManagePriojetUsersScreen: 'manage-priojet-users',
          PriojetManageUsersScreen: 'manage-users',
          PriojetManageOrganizationsUsersScreen: 'manage-organizations',
          CommonProfileNavigator: {
            screens: {
              CommonSwitchAccountScreen: 'switch-account',
              CourierTermsAndConditionsSignupScreen: 'courier-signup',
              AgencyTermsAndConditionsSignupScreen: 'agency-signup',
              CommonAppSettingsScreen: 'app-settings',
              CommonAgencyAccountSettingsScreen: 'agency-account-settings',
              CommonCourierAccountSettingsScreen: 'courier-account-settings',
              CommonGetSupportScreen: 'get-support',
              CommonAppInformationScreen: 'app-information',
            },
          },
        },
      },
      PriojetCourierAvailabilitiesNavigator: {
        path: 'priojet/courier-availabilities',
        screens: {
          PriojetCourierAvailabilitiesScreen: {
            screens: {
              CommonCourierUpcomingAvailabilitiesScreen: 'upcoming',
              CommonCourierPastAvailabilitiesScreen: 'past',
            },
          },
          AgencyCourierUserProfileScreen: {
            path: 'courier/:userId',
          },
        },
      },
    },
  },
};

const linkingUnauthenticatedNavigator = { ...linkingCommonNavigator };

const NavigationLoadingComponent = () => {
  return (
    <View style={themedStyles.spinner}>
      <Image
        style={[globalStyle.width100, globalStyle.height100, globalStyle.alignSelfCenter]}
        source={require('../assets/images/animated_logo.gif')}
      />
      <Text selectable={true} style={themedStyles.text}>
        Just a moment...
      </Text>
    </View>
  );
};

const themedStyles = StyleSheet.create({
  text: { justifyContent: 'center', textAlign: 'center' },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 30,
    backgroundColor: '#ffffff',
    padding: 8,
  },
});

const documentTitleFormatter = (options: any, route: any) => {
  if (options?.title) {
    return `${options?.title} - PRIOjet`;
  } else if (route?.name) {
    const screenName = route.name;
    return (
      screenName
        .replace('Screen', '')
        .replace(/([A-Z])/g, ' $1')
        .trim() + ' - PRIOjet'
    );
  }
  return `PRIOjet`;
};

export const appLoadingNavigatorShouldBeActive = (
  userContext: LocalUserContext | undefined | null,
): boolean => {
  return userContext?.device === undefined;
};

export const accountActivationNavigatorShouldBeActive = (
  userContext: LocalUserContext | undefined | null,
): boolean => {
  let shouldBeActive: boolean = false;
  if (
    !!userContext &&
    userContext.organization?.organizationType === OrganizationType.AGENCY &&
    !userContext?.organizationSubscriptionPlan?.isActive
  ) {
    shouldBeActive = true;
  } else if (
    !!userContext &&
    userContext.organization?.organizationType === OrganizationType.COURIER &&
    !userContext.organizationActivatedAt
  ) {
    shouldBeActive = true;
  }

  return shouldBeActive;
};

export const deviceSetupNavigatorShouldBeActive = (
  userContext: LocalUserContext | undefined | null,
): boolean => {
  if (!userContext) {
    return false;
  }
  const shouldBeActive =
    userContext.user !== undefined &&
    userContext.user !== null &&
    userContext.device !== undefined &&
    Platform.OS !== 'web' &&
    userContext.device?.permissions?.notifications !== undefined &&
    (userContext.device?.permissions?.notifications?.status === undefined ||
      userContext.device?.permissions?.notifications?.status ===
        DevicePermissionStatus.UNDETERMINED);
  return shouldBeActive;
};

export const navigationRef = createRef<any>();

const NavContainer: React.FC<{
  children: React.ReactNode;
  linking: LinkingOptions<RootStackParamList>;
}> = ({ children, linking }) => {
  const themeContext = useContext(ThemeContext);
  const appUserContext = useContext(AppUserContext);

  const navigatorTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      // prevent layout blinking when performing navigation
      background: Colors[themeContext.theme as keyof typeof Colors].background,
    },
  };

  const {
    inAppNotification,
    notification,
    resetNotification,
    resetInAppNotification,
    setNotification,
  } = useInAppNotification();

  const apolloClient = useApolloClient();

  return (
    <NavigationContainer
      theme={navigatorTheme}
      linking={{
        ...linking,
        async getInitialURL() {
          // First, you may want to do the default deep link handling
          // Check if app was opened from a deep link

          // Handle URL from expo push notifications
          const response = await ExpoNotifications.getLastNotificationResponseAsync();
          if (response?.notification.request.content.data) {
            setNotification(response.notification);
          }
          if (response?.notification.request.content.data.url) {
            return response?.notification.request.content.data.url;
          }

          const url = await Linking.getInitialURL();
          if (url != null) {
            return url;
          }
        },
      }}
      fallback={<NavigationLoadingComponent />}
      documentTitle={{
        enabled: true,
        formatter: (options, route) => documentTitleFormatter(options, route),
      }}
      ref={navigationRef}
    >
      {children}
      <InAppNotification
        apolloClient={apolloClient}
        currentUserContext={appUserContext.currentUserContext}
        inAppNotification={inAppNotification}
        notification={notification}
        resetNotification={resetNotification}
        resetInAppNotification={resetInAppNotification}
      />
    </NavigationContainer>
  );
};

export const AppNavigator = (props: any): JSX.Element => {
  const appUserContext = useContext(AppUserContext);
  const appContext = useContext(AppContext);

  useEffect(() => {
    if (appContext.startLocationMonitoring) {
      appContext.startLocationMonitoring();
    }
  }, []);

  if (
    appUserContext.currentUserContext &&
    !appUserContext.currentUserContext.refreshTokenExpired &&
    appUserContext.currentUserContext.user &&
    appUserContext.currentUserContext.organization
  ) {
    if (deviceSetupNavigatorShouldBeActive(appUserContext.currentUserContext)) {
      return (
        <NavContainer linking={linkingSetupDeviceNavigator}>
          <DeviceSetupNavigator {...props} />
        </NavContainer>
      );
    }
    if (accountActivationNavigatorShouldBeActive(appUserContext.currentUserContext)) {
      if (
        appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY &&
        ((Platform.OS !== 'web' &&
          !!appUserContext.currentUserContext?.organizationSubscriptionPlan &&
          !appUserContext.currentUserContext?.organizationSubscriptionPlan?.isActive) ||
          (Platform.OS === 'web' &&
            !appUserContext.currentUserContext?.organizationSubscriptionPlan?.isActive))
      ) {
        return (
          <NavContainer linking={linkingAgencySubscriptionNavigator}>
            <AgencySubscriptionAccountActivationNavigator {...props} />
          </NavContainer>
        );
      }
      return (
        <NavContainer linking={linkingAccountActivationNavigator}>
          <AccountActivationNavigator {...props} />
        </NavContainer>
      );
    }

    if (appUserContext.currentUserContext.organizationType === OrganizationType.PRIOJET) {
      return (
        <NavContainer linking={linkingPriojetNavigator}>
          <PriojetNavigator {...props} />
        </NavContainer>
      );
    }

    if (appUserContext.currentUserContext.organizationType === OrganizationType.COURIER) {
      return (
        <NavContainer linking={linkingCourierNavigator}>
          <StackNavigator.Navigator screenOptions={{ headerShown: false }} initialRouteName="Root">
            <StackNavigator.Screen name="RootScreen" component={CourierNavigator} />
          </StackNavigator.Navigator>
        </NavContainer>
      );
    }

    if (appUserContext.currentUserContext.organizationType === OrganizationType.AGENCY) {
      return (
        <NavContainer linking={linkingAgencyNavigator}>
          <StackNavigator.Navigator
            screenOptions={{ headerShown: false }}
            initialRouteName="RootScreen"
          >
            <StackNavigator.Screen name="RootScreen" component={AgencyNavigator} />
          </StackNavigator.Navigator>
        </NavContainer>
      );
    }

    return (
      <NavContainer linking={linkingDefaultNavigator}>
        <DefaultNavigator {...props} />
      </NavContainer>
    );
  }

  return (
    <NavContainer linking={linkingUnauthenticatedNavigator}>
      <UnauthenticatedNavigator />
    </NavContainer>
  );
};
