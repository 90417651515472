import React, { useContext, useMemo } from 'react';

import { Props } from './types';
import AppContext from '../../../contexts/AppContext';

export const HasPermissions = ({
  children,
  organizationType,
  organizationUserRoleType,
}: Props): React.ReactElement => {
  const appUserContext = useContext(AppContext);

  const hasPermission = useMemo(() => {
    let check = true;
    if (
      !appUserContext.currentUserContext?.organizationType ||
      !organizationType.includes(appUserContext.currentUserContext?.organizationType)
    ) {
      check = false;
    }
    if (
      organizationUserRoleType &&
      Array.isArray(organizationUserRoleType) &&
      appUserContext.currentUserContext?.organizationUser?.roleType &&
      !organizationUserRoleType.includes(
        appUserContext.currentUserContext?.organizationUser?.roleType,
      )
    ) {
      check = false;
    }
    return check;
  }, [appUserContext, organizationType, organizationUserRoleType]);

  if (!hasPermission) {
    return <></>;
  }
  return children;
};
