import React, { memo } from 'react';
import { Platform, Pressable, View } from 'react-native';

import { Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { styles as themedStyles } from './styles';
import { DownloadIcon } from '../../../../icons.component';

const downloadFile =
  Platform.OS === 'web'
    ? require('../../../../../modules/helper/download.module').downloadFileWeb
    : require('../../../../../modules/helper/download.module').downloadFile;

export const ChatMessagePDFComponent: React.FC<any> = ({
  chatFile,
  downloadIconFill,
  handlePDFPress,
  isCurrentUser,
  style,
  styleButton,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { metadata, sasUrl } = chatFile;

  const saveFile = (type: 'Image' | 'PDF') => async () => {
    const { fileName, mimeType } = metadata;
    if (Platform.OS === 'web') {
      await downloadFile({ fileName, uri: sasUrl });
    } else {
      const {
        error,
        showMessage: showMessageValue,
        success,
      } = await downloadFile({ fileName, mimeType, uri: sasUrl });
      if (showMessageValue) {
        showMessage({
          message: success
            ? t('common.success', { defaultValue: 'Success' })
            : t('common.error', { defaultValue: 'Error' }),
          description: success
            ? (t('common.successfullySaved', {
                type,
                defaultValue: `${type} successfully saved`,
              }) as string)
            : error,
          type: success ? 'success' : 'danger',
          autoHide: true,
          hideOnPress: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Pressable style={[styles.pdfContainer, style]} onPress={handlePDFPress(sasUrl)}>
      <View style={[styles.pdfButton, styleButton]}>
        <Text style={[styles.messageText, isCurrentUser ? styles.textLeft : styles.textRight]}>
          {metadata.fileName}
        </Text>
      </View>
      <Pressable style={styles.downloadPdfButton} onPress={saveFile('PDF')}>
        <DownloadIcon
          fill={downloadIconFill || (styles.downloadIcon as any).color}
          style={styles.downloadIcon}
        />
      </Pressable>
    </Pressable>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps.chatFile?.sasUrl !== nextProps.chatFile?.sasUrl) {
    return false;
  }
  return true;
};

export const ChatMessagePDF = memo(ChatMessagePDFComponent, arePropsEqual);
