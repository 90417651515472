import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { ActivityIndicator, Keyboard, Platform, View } from 'react-native';

import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { Icon, Layout, useStyleSheet } from '@ui-kitten/components';
import { GiftedChat } from 'react-native-gifted-chat';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import ChatInput from './ChatInput';
import { ChatMessage } from './ChatMessage';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../common/style';
import Colors from '../../../constants/Colors';
import ThemeContext from '../../../contexts/ThemeContext';
import { ChatType } from '../../../generated-graphql-types';
import { CameraModal } from '../CameraModal';
import PDFViewer from '../pdf-viewer.component';

export const ChatComponent = forwardRef(
  (
    {
      chatId,
      chatType,
      disableInput: disableInputProp,
      disableInputMessage: disableInputMessageProp,
      listViewProps = {},
    }: {
      chatId: string;
      chatType: ChatType;
      disableInput?: boolean;
      disableInputMessage?: string;
      listViewProps?: { [key: string]: any };
    },
    chatRef: any,
  ): React.ReactElement => {
    const { bottom } = useSafeAreaInsets();
    const bottomTabBarHeight = useBottomTabBarHeight();
    const {
      canLoadMore,
      currentUser,
      disableInput,
      disableInputMessage,
      handleCloseCamera,
      handleMessageTextChange,
      handleMessageSend,
      handleMessageSendAgain,
      handlePDFPress,
      handleUploadCameraPicture,
      isSending,
      messageContainerRef,
      messages,
      messageText,
      loadingMoreMessages,
      loadingLatestMessages,
      onActionPress,
      onLoadMore,
      openMessageOptions,
      pdfUri,
      user,
      visibleCamera,
    } = useHook({ chatId, chatType, chatRef, disableInputMessage: disableInputMessageProp });
    const styles = useStyleSheet(themedStyles);
    const { theme } = useContext(ThemeContext);

    const [keyboardShown, setKeyboardShown] = useState(false);

    useEffect(() => {
      const showSubscription = Keyboard.addListener(
        Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow',
        () => {
          setKeyboardShown(true);
        },
      );
      const hideSubscription = Keyboard.addListener(
        Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide',
        () => {
          setKeyboardShown(false);
        },
      );

      return () => {
        showSubscription.remove();
        hideSubscription.remove();
      };
    }, []);

    const renderMessage = (props: any) => (
      <ChatMessage
        {...props}
        currentUser={currentUser}
        handleSendAgain={handleMessageSendAgain}
        handlePDFPress={handlePDFPress}
        openMessageOptions={openMessageOptions}
      />
    );

    const renderLoadEarlier = () => {
      if (!loadingMoreMessages) {
        return null;
      }
      return (
        <Layout
          style={[
            globalStyle.flex1,
            globalStyle.alignContentCenter,
            globalStyle.alignItemsCenter,
            globalStyle.justifyContentCenter,
            globalStyle.padding30,
            globalStyle.flexRowReverse,
          ]}
        >
          <ActivityIndicator />
        </Layout>
      );
    };

    const renderInputToolbar = (props: any) => (
      <ChatInput
        bottomTabBarHeight={bottomTabBarHeight}
        disableInput={disableInput || disableInputProp}
        disableInputMessage={disableInputMessage}
        handleTextChange={props.onInputTextChanged}
        isSending={isSending}
        messageText={messageText}
        onSend={props.onSend}
        onActionPress={onActionPress}
      />
    );

    const renderScrollDown = () => (
      <View style={styles.scrollDown}>
        <Icon
          name="arrow-downward-outline"
          fill={theme === 'light' ? Colors.light.text : Colors.dark.text}
          style={styles.scrollIcon}
        />
      </View>
    );

    const renderFooter = () => {
      if (loadingLatestMessages && messages.length) {
        return (
          <View style={styles.footer}>
            <ActivityIndicator />
          </View>
        );
      }
      return null;
    };

    const renderPDFViewer = () => {
      if (Platform.OS === 'web') {
        return null;
      }
      return <PDFViewer onClose={handlePDFPress('')} uri={pdfUri} visible={!!pdfUri} />;
    };

    return (
      <>
        <GiftedChat
          messageContainerRef={messageContainerRef as any}
          bottomOffset={1}
          inverted
          bottomTabBarHeight={bottomTabBarHeight}
          isLoadingEarlier={loadingMoreMessages}
          listViewProps={{
            ...listViewProps,
            marginBottom: keyboardShown ? bottom * -1 : 0,
            scrollEventThrottle: 400,
            onEndReached: onLoadMore,
            onEndReachedThreshold: 0.1,
            removeClippedSubviews: false,
          }}
          loadEarlier={canLoadMore}
          messages={messages}
          onInputTextChanged={handleMessageTextChange}
          onPressAvatar={undefined}
          onSend={handleMessageSend}
          renderAvatarOnTop
          renderFooter={renderFooter}
          renderInputToolbar={renderInputToolbar}
          renderLoadEarlier={renderLoadEarlier}
          renderMessage={renderMessage}
          renderUsernameOnMessage
          scrollToBottom
          scrollToBottomComponent={renderScrollDown}
          scrollToBottomStyle={[
            styles.scrollToBottomButton,
            // eslint-disable-next-line react-native/no-inline-styles
            { marginBottom: keyboardShown ? bottom * -1 : 0 },
          ]}
          text={messageText}
          user={user}
        />

        {loadingLatestMessages && !messages.length && (
          <View
            style={[
              globalStyle.absoluteFull,
              globalStyle.alignItemsCenter,
              globalStyle.justifyContentCenter,
            ]}
          >
            <ActivityIndicator />
          </View>
        )}
        {renderPDFViewer()}
        <CameraModal
          visible={visibleCamera}
          onClose={handleCloseCamera}
          onSelectPicture={handleUploadCameraPicture}
        />
      </>
    );
  },
);

export default ChatComponent;
