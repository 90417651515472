import React, { Fragment, useRef, useState } from 'react';
import { Animated, View } from 'react-native';

import { Button, Card, Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { CommonJobCountdown } from './common.job-countdown.component';
import { JourneyLocationType, MapViewJourney } from './common.job-journey-map.component';
import { globalStyle } from '../../../../common/style';
import { DEFAULT_MAP_LOCATION } from '../../../../constants/Map';
import { LocationTextWithContextMenu } from '../../common.location-text-with-context-menu';
import { TimeAgoWithTimeZonesPopover } from '../../time-ago-with-time-zones-popover.component';

interface Props {
  active?: boolean;
  deliveryLocations: JourneyLocationType[];
  returnLocations: JourneyLocationType[];
}

export const CommonJobJourneyMapComponent = ({
  active,
  deliveryLocations,
  returnLocations,
}: Props) => {
  const { i18n, t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const [initialLocation, setInitialLocation] = useState({
    latitude: deliveryLocations?.[0].latitude || DEFAULT_MAP_LOCATION.latitude,
    longitude: deliveryLocations?.[0].longitude || DEFAULT_MAP_LOCATION.longitude,
  });
  const [journeyType, setJourneyType] = useState<'delivery' | 'return'>('delivery');
  const [selected, setSelected] = useState<JourneyLocationType | undefined>(deliveryLocations[0]);

  const transform = useRef(new Animated.Value(1)).current;

  const markers = journeyType === 'delivery' ? deliveryLocations : returnLocations;
  const disabledBack = selected?.enumeration === markers[0].enumeration;
  const disabledForward = selected?.enumeration === markers[markers.length - 1].enumeration;

  const handleCloseBottom = () => {
    setSelected(undefined);
  };

  const handleBackBottom = () => {
    const index = markers.findIndex((item) => item.enumeration === selected?.enumeration);
    const item = markers[index - 1];
    handleMarkerPress(item);
  };

  const handleForwardBottom = () => {
    const index = markers.findIndex((item) => item.enumeration === selected?.enumeration);
    const item = markers[index + 1];
    handleMarkerPress(item);
  };

  const handleMarkerPress = (item: JourneyLocationType, ignoreEnumeration = false) => {
    if (ignoreEnumeration || selected?.enumeration !== item.enumeration) {
      Animated.timing(transform, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {});
      setTimeout(() => {
        setSelected(undefined);
      }, 200);
      setTimeout(() => {
        setSelected(item);
        setInitialLocation({ latitude: item.latitude, longitude: item.longitude });

        Animated.timing(transform, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start(() => {});
      }, 300);
    }
  };

  const handleChangeJourneyType = (type: 'delivery' | 'return') => () => {
    if (type !== journeyType) {
      setJourneyType(type);
      handleMarkerPress(type === 'delivery' ? deliveryLocations[0] : returnLocations[0], true);
    }
  };

  const transformY = transform.interpolate({
    inputRange: [0, 1],
    outputRange: [400, 0],
  });
  return (
    <View style={styles.map}>
      <MapViewJourney
        active={!!active}
        initialLocation={
          initialLocation
            ? { latitude: initialLocation.latitude, longitude: initialLocation.longitude }
            : { latitude: markers[0].latitude, longitude: markers[0].longitude }
        }
        lineColor={journeyType === 'delivery' ? 'rgb(51, 102, 255)' : '#008000'}
        onMarkerPress={handleMarkerPress}
        markers={markers}
        selectedMarker={selected}
      />

      <View style={[globalStyle.flexRow, styles.journeyTypeContainer]}>
        <Button
          appearance={journeyType === 'delivery' ? 'filled' : 'outline'}
          size="small"
          status={journeyType === 'delivery' ? 'primary' : 'control'}
          onPress={handleChangeJourneyType('delivery')}
          style={styles.shadow}
        >
          {(evaProps) => (
            <Text style={[evaProps?.style, globalStyle.fontLatoBold, styles.textWithShadow]}>
              {(t('common.delivery', { defaultValue: 'Delivery' }) as string).toUpperCase()}
            </Text>
          )}
        </Button>
        <Button
          appearance={journeyType === 'return' ? 'filled' : 'outline'}
          size="small"
          status={journeyType === 'return' ? 'primary' : 'control'}
          onPress={handleChangeJourneyType('return')}
          style={[globalStyle.marginLeft5, styles.shadow]}
        >
          {(evaProps) => (
            <Text style={[evaProps?.style, globalStyle.fontLatoBold, styles.textWithShadow]}>
              {(t('common.return', { defaultValue: 'Return' }) as string).toUpperCase()}
            </Text>
          )}
        </Button>
      </View>

      <Animated.View style={[styles.bottom, { transform: [{ translateY: transformY }] }]}>
        {selected && (
          <View style={[globalStyle.flexRow, globalStyle.justifyContentEnd]}>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.btnArrow}
              onPress={disabledBack ? undefined : handleBackBottom}
            >
              <View style={disabledBack && styles.btnArrowDisabled}>
                <Icon
                  name="arrow-back-outline"
                  fill={(styles.closeColor as any).color}
                  height={26}
                  width={26}
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={1}
              style={styles.btnArrow}
              onPress={disabledForward ? undefined : handleForwardBottom}
            >
              <View style={disabledForward && styles.btnArrowDisabled}>
                <Icon
                  name="arrow-forward-outline"
                  fill={(styles.closeColor as any).color}
                  height={26}
                  width={26}
                />
              </View>
            </TouchableOpacity>
          </View>
        )}
        <Card style={styles.bottomCard}>
          {!!selected && (
            <Fragment key={`${selected.latitude}-${selected.latitude}-selected`}>
              <View
                style={[
                  globalStyle.flex1,
                  globalStyle.flexRow,
                  globalStyle.alignItemsCenter,
                  globalStyle.justifyContentBetween,
                  globalStyle.marginLeft15,
                ]}
              >
                <View
                  style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsCenter]}
                >
                  <View style={styles.enumerationLayer}>
                    <Text
                      category="h6"
                      style={[globalStyle.fontLatoBold, globalStyle.paddingHorizontal10]}
                    >
                      {selected.enumeration[0]}
                    </Text>
                  </View>

                  <Text category="h6" style={[globalStyle.flex1, globalStyle.fontLatoBold]}>
                    {selected.title}
                  </Text>
                </View>
                <TouchableOpacity
                  style={globalStyle.paddingHorizontal15}
                  onPress={handleCloseBottom}
                >
                  <Icon
                    name="close-outline"
                    fill={(styles.closeColor as any).color}
                    height={30}
                    width={30}
                  />
                </TouchableOpacity>
              </View>

              <View style={[globalStyle.padding15, globalStyle.paddingBottom20]}>
                {selected.dateTime && (
                  <TimeAgoWithTimeZonesPopover
                    popoverContentTitle="Time"
                    i18nKey="common.timeAgoPopOverTextStart"
                    date={selected.dateTime}
                    targetLocationName={selected.location?.formattedShortAddress || ''}
                    targetTimeZoneIdentifier={selected.location?.timeZoneIdentifier || undefined}
                    hitSlop={{ top: 10, bottom: 3, left: 0, right: 0 }}
                  />
                )}
                <LocationTextWithContextMenu
                  location={selected.location}
                  style={globalStyle.marginTop5}
                />

                {selected.dateTime && (
                  <View style={[globalStyle.flexGrow1]}>
                    <Text>
                      {selected.dateTime?.toLocaleString(DateTime.DATETIME_FULL, {
                        locale: i18n.language,
                      })}
                    </Text>
                    {!!selected.countDownTitle &&
                      selected.dateTime.diffNow('milliseconds').milliseconds > 0 && (
                        <Text style={globalStyle.paddingTop10}>{selected.countDownTitle}</Text>
                      )}
                    <CommonJobCountdown
                      dateTime={selected.dateTime}
                      textStyle={[globalStyle.fontLatoBold, globalStyle.fontSize18]}
                    />
                  </View>
                )}
              </View>
            </Fragment>
          )}
        </Card>
      </Animated.View>
    </View>
  );
};

const themedStyles = StyleService.create({
  map: {
    flex: 1,
    height: '100%',
    width: '100%',
  },
  bottom: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
  },
  bottomCard: {
    borderRadius: 0,
  },
  enumerationLayer: {
    backgroundColor: 'background-basic-color-4',
    marginRight: 6,
    paddingHorizontal: 5,
    borderRadius: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  color: {
    color: '#000000',
  },
  closeColor: {
    color: 'text-basic-color',
  },
  journeyTypeContainer: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
  shadow: {
    shadowColor: '#888',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    shadowRadius: 1,
  },
  textWithShadow: {
    textShadowColor: '#888',
    textShadowOffset: { width: 0, height: 1 },
    textShadowOpacity: 0.1,
    textShadowRadius: 1,
  },
  btnArrow: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 42,
    backgroundColor: 'background-basic-color-1',
  },
  btnArrowDisabled: {
    opacity: 0.5,
  },
});

const compareMapViewWithMarkerProps = (prev: Props, next: Props) => {
  if (prev.active !== next.active) {
    return false;
  }
  if (prev.deliveryLocations.length !== next.deliveryLocations.length) {
    return false;
  }
  if (
    prev.deliveryLocations.some((item, index) => {
      if (
        item.latitude !== next.deliveryLocations[index].latitude ||
        item.longitude !== next.deliveryLocations[index].longitude ||
        item.enumeration !== next.deliveryLocations[index].enumeration
      ) {
        return false;
      }
      return false;
    })
  ) {
    return false;
  }

  if (prev.returnLocations.length !== next.returnLocations.length) {
    return false;
  }
  if (
    prev.returnLocations.some((item, index) => {
      if (
        item.latitude !== next.returnLocations[index].latitude ||
        item.longitude !== next.returnLocations[index].longitude ||
        item.enumeration !== next.returnLocations[index].enumeration
      ) {
        return false;
      }
      return false;
    })
  ) {
    return false;
  }

  return true;
};

export const CommonJobJourneyMap = React.memo(
  CommonJobJourneyMapComponent,
  compareMapViewWithMarkerProps,
);
