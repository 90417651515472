import React from 'react';
import { View, ViewStyle } from 'react-native';

import {
  CheckBox,
  CheckBoxProps,
  StyleService,
  useStyleSheet,
  Text,
} from '@ui-kitten/components';
import { TFunctionResult } from 'i18next';

export const FormCheckBox = (
  props: CheckBoxProps & {
    checked?: boolean;
    caption?: TFunctionResult | string;
    label: TFunctionResult | string;
    isMandatory?: boolean;
    style?: ViewStyle;
  },
): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={styles.container}>
      <CheckBox {...props} style={styles.checkBox} onChange={props.onChange}>
        <View style={styles.labelContainer}>
          <Text selectable={true} style={styles.label}>
            {props.label as string}
            {props.isMandatory ? (
              <Text selectable={true} style={styles.mandatoryStar}>
                *
              </Text>
            ) : (
              <></>
            )}
          </Text>
        </View>
      </CheckBox>
      {props.caption ? (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.caption}>
            {props.caption as string}
          </Text>
        </View>
      ) : (
        <></>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
  },
  captionContainer: {
    paddingLeft: 10,
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  labelContainer: {
    paddingLeft: 10,
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: { color: 'color-danger-600' },
  checkBox: {
    marginLeft: 10,
  },
});
