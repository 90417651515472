import { DateTime } from 'luxon';

import {
  AgencyJobPlanningLegWithOffline,
  AgencyJobPlanningWithOffline,
  AgencyJobPlanningItemWithOffline,
} from './types';

type AgencyJobPlanningAllItemsWithLegItem = {
  active: boolean;
  actualDateTimeSetAt: DateTime | null;
  name: string;
  id: string;
  isLegItem: boolean;
  legIndex?: number;
  legItemIndex?: number;
  index?: number;
  isCompleted: boolean;
};

type State = {
  agencyJobPlanning: AgencyJobPlanningWithOffline | null;
  agencyJobPlanningAllItemsWithLegItems: AgencyJobPlanningAllItemsWithLegItem[];
  currentIndex?: number;
  currentLegIndex?: number;
  currentLegItemIndex?: number;
  isCompleted: boolean;
  isEmpty: boolean;
};

type Action = {
  type:
    | 'changeDateAndGoToNextStep'
    | 'reset'
    | 'resetCurrentStep'
    | 'selectIndex'
    | 'syncAPIData'
    | 'syncAPIDataItem';
  payload: {
    agencyJobPlanning?: AgencyJobPlanningWithOffline;
    agencyJobPlanningItem?: AgencyJobPlanningItemWithOffline;
    calculateNextStep?: boolean;
    formData?: any;
    selectIndex?: {
      currentIndex?: number;
      currentLegIndex?: number;
      currentLegItemIndex?: number;
    };
  };
};

export const INITIAL_STATE: State = {
  agencyJobPlanning: null,
  agencyJobPlanningAllItemsWithLegItems: [],
  currentIndex: undefined,
  currentLegIndex: undefined,
  currentLegItemIndex: undefined,
  isCompleted: false,
  isEmpty: false,
};

const getAgencyJobPlanningAllItemsWithLegItems = (
  agencyJobPlanning: AgencyJobPlanningWithOffline,
) => {
  const items: AgencyJobPlanningAllItemsWithLegItem[] = [];

  agencyJobPlanning.agencyJobPlanningItems.forEach((item, index) => {
    if (item?.name === 'travelAtDropOffDeliveryLocation') {
      agencyJobPlanning.agencyJobPlanningLegs.forEach((leg, legIndex) => {
        leg.agencyJobPlanningItems.forEach((legItem, legItemIndex) => {
          items.push({
            active: legItem.active,
            actualDateTimeSetAt: legItem.actualDateTimeSetAt ? legItem.actualDateTimeSetAt : null,
            isCompleted: !!legItem.actualDateTime && !!legItem.location?.id,
            name: legItem.name,
            id: legItem.id,
            isLegItem: false,
            legIndex,
            legItemIndex,
            index: undefined,
          });
        });
      });
      items.push({
        active: item.active,
        actualDateTimeSetAt: item.actualDateTimeSetAt ? item.actualDateTimeSetAt : null,
        isCompleted: !!item.actualDateTime && !!item.location?.id,
        name: item.name,
        id: item.id,
        isLegItem: false,
        legIndex: undefined,
        legItemIndex: undefined,
        index,
      });
    } else {
      items.push({
        active: item.active,
        actualDateTimeSetAt: item.actualDateTimeSetAt ? item.actualDateTimeSetAt : null,
        isCompleted: !!item.actualDateTime && !!item.location?.id,
        name: item.name,
        id: item.id,
        isLegItem: false,
        legIndex: undefined,
        legItemIndex: undefined,
        index,
      });
    }
  });
  return items;
};

const checkIfAllStepsCompleted = (agencyJobPlanning: AgencyJobPlanningWithOffline) => {
  return (
    agencyJobPlanning.agencyJobPlanningItems.every(
      (item) => !item.active || (!!item.actualDateTime && !!item.location),
    ) &&
    agencyJobPlanning.agencyJobPlanningLegs.every((item) =>
      item.agencyJobPlanningItems.every(
        (legItem) => !legItem.active || (!!legItem.actualDateTime && !!legItem.location),
      ),
    )
  );
};

const checkIfIsEmpty = (agencyJobPlanning: AgencyJobPlanningWithOffline) => {
  return (
    agencyJobPlanning.agencyJobPlanningItems.every((item) => !item.active) &&
    agencyJobPlanning.agencyJobPlanningLegs.every((item) =>
      item.agencyJobPlanningItems.every((legItem) => !legItem.active),
    )
  );
};

const mergeAgencyJobPlanning = (
  oldData: AgencyJobPlanningWithOffline | null,
  newData: AgencyJobPlanningWithOffline,
) => {
  if (!oldData) {
    return newData;
  }

  return {
    ...oldData,
    agencyJobPlanningItems: newData.agencyJobPlanningItems.map((newItem) => {
      const foundItem = oldData.agencyJobPlanningItems.find((oldItem) => oldItem.id === newItem.id);
      if (foundItem?.offline) {
        return { ...newItem, ...foundItem };
      }
      return newItem;
    }),
    agencyJobPlanningLegs: newData.agencyJobPlanningLegs.map((newLeg) => {
      const foundLeg = oldData.agencyJobPlanningLegs.find((oldLeg) => oldLeg.id === newLeg.id);
      if (foundLeg) {
        return {
          ...newLeg,
          agencyJobPlanningItems: newLeg.agencyJobPlanningItems.map((newItem) => {
            const foundItem = foundLeg.agencyJobPlanningItems.find(
              (oldItem) => oldItem.id === newItem.id,
            );
            if (foundItem?.offline) {
              return { ...newItem, ...foundItem };
            }
            return newItem;
          }),
        };
      }
      return newLeg;
    }),
  };
};

const getStateData = (currentState: State, calculateNextStepParam: boolean): State => {
  const { agencyJobPlanning } = currentState;
  if (!agencyJobPlanning) {
    return currentState;
  }
  const agencyJobPlanningAllItemsWithLegItems =
    getAgencyJobPlanningAllItemsWithLegItems(agencyJobPlanning);

  const prevActiveCount = currentState.agencyJobPlanningAllItemsWithLegItems.reduce((curr, acc) => {
    if (acc.active) {
      return curr + 1;
    }
    return curr;
  }, 0);
  const newActiveCount = agencyJobPlanningAllItemsWithLegItems.reduce((curr, acc) => {
    if (acc.active) {
      return curr + 1;
    }
    return curr;
  }, 0);
  let calculateNextStep = !!calculateNextStepParam || prevActiveCount !== newActiveCount;

  const isCompleted = checkIfAllStepsCompleted(agencyJobPlanning);
  const isEmpty = checkIfIsEmpty(agencyJobPlanning);
  let currentIndex: number | undefined;
  let currentLegIndex: number | undefined;
  let currentLegItemIndex: number | undefined;

  if (isCompleted) {
    currentIndex = undefined;
    currentLegIndex = undefined;
    currentLegItemIndex = undefined;
  } else {
    // IF !calculateNextStep - CHECK IF CURRENT STEP IS isCompleted
    if (!calculateNextStep) {
      const foundCurrentItem = agencyJobPlanningAllItemsWithLegItems.find((item) => {
        if (typeof currentState.currentIndex === 'number') {
          return item.index === currentState.currentIndex;
        } else if (
          typeof currentState.currentLegIndex === 'number' &&
          typeof currentState.currentLegItemIndex === 'number'
        ) {
          return (
            item.legIndex === currentState.currentLegIndex &&
            item.legItemIndex === currentState.currentLegItemIndex
          );
        }
        return false;
      });
      // IF CURRENT STEP IS isCompleted, calculateNextStep IS TRUE
      if (foundCurrentItem?.isCompleted) {
        calculateNextStep = true;
      }
    }

    if (calculateNextStep) {
      let found: AgencyJobPlanningAllItemsWithLegItem | undefined;
      let foundCompletedIndex: number | undefined;
      let lastActualDateTime: DateTime | null = null;
      [...agencyJobPlanningAllItemsWithLegItems].forEach((item, index) => {
        if (item.active && item.isCompleted) {
          if (!lastActualDateTime && item.actualDateTimeSetAt) {
            lastActualDateTime = item.actualDateTimeSetAt;
            foundCompletedIndex = index;
          } else if (
            lastActualDateTime &&
            item.actualDateTimeSetAt &&
            item.actualDateTimeSetAt > lastActualDateTime
          ) {
            lastActualDateTime = item.actualDateTimeSetAt;
            foundCompletedIndex = index;
          }
        }
      });

      if (typeof foundCompletedIndex === 'number') {
        found = agencyJobPlanningAllItemsWithLegItems.find((item, index) => {
          if (typeof foundCompletedIndex === 'number') {
            return index > foundCompletedIndex && item.active && !item.isCompleted;
          }
          return false;
        });
      }
      if (!found) {
        found = found = agencyJobPlanningAllItemsWithLegItems.find((item) => {
          return item.active && !item.isCompleted;
        });
      }
      if (found) {
        currentIndex = found.index;
        currentLegIndex = found.legIndex;
        currentLegItemIndex = found.legItemIndex;
      }
    } else {
      currentIndex = currentState.currentIndex;
      currentLegIndex = currentState.currentLegIndex;
      currentLegItemIndex = currentState.currentLegItemIndex;
    }
  }
  return {
    agencyJobPlanning,
    agencyJobPlanningAllItemsWithLegItems,
    currentIndex,
    currentLegIndex,
    currentLegItemIndex,
    isCompleted,
    isEmpty,
  };
};

const getStateDataNextAvailableItem = (currentState: State): State => {
  const { agencyJobPlanning } = currentState;
  if (!agencyJobPlanning) {
    return currentState;
  }
  const agencyJobPlanningAllItemsWithLegItems =
    getAgencyJobPlanningAllItemsWithLegItems(agencyJobPlanning);

  const isCompleted = checkIfAllStepsCompleted(agencyJobPlanning);
  const isEmpty = checkIfIsEmpty(agencyJobPlanning);
  let currentIndex: number | undefined;
  let currentLegIndex: number | undefined;
  let currentLegItemIndex: number | undefined;
  if (isCompleted) {
    currentIndex = undefined;
    currentLegIndex = undefined;
    currentLegItemIndex = undefined;
  } else {
    let found: AgencyJobPlanningAllItemsWithLegItem | undefined;
    let foundCompletedIndex = agencyJobPlanningAllItemsWithLegItems.findIndex((item) => {
      if (typeof currentState.currentIndex === 'number') {
        return item.index === currentState.currentIndex;
      } else if (
        typeof currentState.currentLegIndex === 'number' &&
        typeof currentState.currentLegItemIndex === 'number'
      ) {
        return (
          item.legIndex === currentState.currentLegIndex &&
          item.legItemIndex === currentState.currentLegItemIndex
        );
      }
      return false;
    });
    if (typeof foundCompletedIndex === 'number') {
      found = agencyJobPlanningAllItemsWithLegItems.find((item, index) => {
        return index > foundCompletedIndex && item.active && !item.isCompleted;
      });
    }
    if (!found) {
      found = found = agencyJobPlanningAllItemsWithLegItems.find((item) => {
        return item.active && !item.isCompleted;
      });
    }
    if (found) {
      currentIndex = found.index;
      currentLegIndex = found.legIndex;
      currentLegItemIndex = found.legItemIndex;
    }
  }
  return {
    agencyJobPlanning,
    agencyJobPlanningAllItemsWithLegItems,
    currentIndex,
    currentLegIndex,
    currentLegItemIndex,
    isCompleted,
    isEmpty,
  };
};

export const reducer = (state: State, action: Action) => {
  if (action.type === 'changeDateAndGoToNextStep') {
    let nextIndex;
    if (state.currentIndex) {
      nextIndex = state.currentIndex + 1;
    }
    if (!nextIndex) {
      return { ...state };
    }
    return { ...state, currentIndex: nextIndex };
  }
  if (action.type === 'selectIndex') {
    return { ...state, ...action.payload.selectIndex };
  }
  if (action.type === 'syncAPIData') {
    if (action.payload.agencyJobPlanning) {
      const agencyJobPlanning: any = mergeAgencyJobPlanning(
        state.agencyJobPlanning,
        action.payload.agencyJobPlanning,
      );
      const calculateNextStep = !state.agencyJobPlanning || !!action.payload.calculateNextStep;
      const data = getStateData({ ...state, agencyJobPlanning }, calculateNextStep);
      return { ...state, ...data };
    }
  }
  if (action.type === 'resetCurrentStep') {
    const data = getStateData({ ...state }, true);
    return { ...state, ...data };
  }
  if (action.type === 'syncAPIDataItem') {
    if (!state.agencyJobPlanning) {
      return state;
    }
    let newAgencyJobPlanning = state.agencyJobPlanning;
    if (action.payload.agencyJobPlanningItem?.id) {
      newAgencyJobPlanning = {
        ...newAgencyJobPlanning,
        agencyJobPlanningItems: newAgencyJobPlanning.agencyJobPlanningItems.map(
          (item: AgencyJobPlanningItemWithOffline) => {
            if (item.id === action.payload.agencyJobPlanningItem?.id) {
              return action.payload.agencyJobPlanningItem as any;
            }
            return item;
          },
        ),
        agencyJobPlanningLegs: newAgencyJobPlanning.agencyJobPlanningLegs.map(
          (leg: AgencyJobPlanningLegWithOffline) => {
            return {
              ...leg,
              agencyJobPlanningItems: leg.agencyJobPlanningItems.map(
                (item: AgencyJobPlanningItemWithOffline) => {
                  if (item.id === action.payload.agencyJobPlanningItem?.id) {
                    return action.payload.agencyJobPlanningItem as any;
                  }
                  return item;
                },
              ),
            };
          },
        ),
      };
    }
    return {
      ...state,
      ...getStateDataNextAvailableItem({ ...state, agencyJobPlanning: newAgencyJobPlanning }),
    };
  }
  if (action.type === 'reset') {
    return { ...INITIAL_STATE };
  }
  return { ...state };
};
