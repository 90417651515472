import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  maxWidth300: {
    maxWidth: 300,
  },
  divider: {
    height: 1,
    backgroundColor: 'background-basic-color-4',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
  },
});
