import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  RefreshControl,
  ScrollView,
  TextInputChangeEventData,
  View,
} from 'react-native';

import { ApolloError } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import {
  Button,
  Icon,
  Input,
  InputProps,
  StyleService,
  Tab,
  TabBar,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { Trans, useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { CONNECTED_AGENCY_ORGANIZATIONS_FOR_COURIER_ORGANIZATION } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { EmailTextWithContextMenu } from '../../components/common/common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../components/common/common.phone-text-with-context-menu';
import { TranslatedErrorText } from '../../components/common/error/translated-error-text.component';
import { ConfirmModalContext } from '../../components/common/modal/ConfirmModal';
import { CourierConnectedAgenciesList } from '../../components/courier/connected-agencies/courier-connected-agencies-list.component';
import { TopNavigationBackToProfileButton } from '../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import {
  ConnectedOrganization,
  OrganizationInvitation,
  OrganizationOrganization,
  useConnectedAgencyOrganizationsForCourierOrganizationQuery,
  useConnectWithAgencyOrganizationAsCourierOrganizationMutation,
  useOrganizationInvitationByActivationCodeForCourierOrganizationLazyQuery,
} from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { RootStackParamList } from '../../navigation/app.navigator';

const { Navigator, Screen } = createMaterialTopTabNavigator();

const OrganizationInvitationPreview = ({
  organizationInvitation,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const navigation: NavigationProp<RootStackParamList> = useNavigation();

  const agencyName = useMemo(() => {
    let _agencyName: undefined | string;
    if (organizationInvitation?.invitingOrganization?.name) {
      _agencyName = organizationInvitation?.invitingOrganization?.name;
    }
    if (organizationInvitation?.invitingOrganization?.shortName) {
      _agencyName = ' (' + organizationInvitation?.invitingOrganization?.shortName + ')';
    }
    return _agencyName;
  }, [organizationInvitation]);

  return (
    <View style={styles.agencyPreviewLayer}>
      <Text style={styles.boldText}>
        {agencyName ||
          (t('activation.noAgencyNameGiven', {
            defaultValue: 'No Agency name given',
          }) as string)}
      </Text>
      {!!organizationInvitation?.invitingOrganization?.email && (
        <EmailTextWithContextMenu
          email={organizationInvitation?.invitingOrganization?.email as string}
        />
      )}
      {!!organizationInvitation?.invitingOrganization?.phone && (
        <PhoneTextWithContextMenu
          phone={organizationInvitation?.invitingOrganization?.phone as string}
        />
      )}
      <View style={styles.agencyProfileButtonView}>
        <Button
          size="tiny"
          appearance="outline"
          onPress={() =>
            navigation.navigate('CourierAgencyProfileScreen', {
              agencyOrganizationId: organizationInvitation?.invitingOrganization?.id as string,
              previousScreenTabName: 'CourierAddAgencyConnectionScreen',
            })
          }
        >
          Go to Agency Profile
        </Button>
      </View>
      <Text category="label" style={styles.connectWithAgencyInfoText}>
        <Trans
          t={t}
          i18nKey="activation.connectWithAgencyExplanation"
          defaults="Press button <strong>Connect with Agency</strong> below in order to connect your Courier Organization with the Agency displayed above. After successfully connecting, the Agency will have access to your profile data and documents so that it can offer you OBC jobs that fit your profile."
          components={{
            strong: <Text style={styles.boldText} category="label" />,
          }}
        />
      </Text>
    </View>
  );
};

const ActivationCodeInput = (
  props: InputProps & {
    activationCode: string;
    setActivationCode: Dispatch<SetStateAction<string>>;
    hasError: boolean;
  },
) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const inputStyle = useMemo(() => {
    if (props.activationCode.length === 8 && props.hasError === false) {
      return { borderColor: 'green' };
    }
    if (
      props.hasError === true ||
      (props.activationCode?.length && props.activationCode.length > 0)
    ) {
      return { borderColor: 'red' };
    }
    return {};
  }, [props.activationCode, props.hasError]);

  const onTextInputChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    if (e.nativeEvent.text !== props.activationCode) {
      const activationCode = e.nativeEvent.text
        ? e.nativeEvent.text.replace(/[^a-z0-9]/gi, '').toUpperCase()
        : '';
      props.setActivationCode(activationCode);
    }
  };

  const renderInputLabel = () => {
    return (
      <Text selectable={true} style={styles.activationCodeInputTitle}>
        {
          t('activation.activationCode', {
            defaultValue: 'Activation Code',
          }) as string
        }
      </Text>
    );
  };

  const renderInputCaption = () => {
    return (
      <View>
        <Text
          selectable={true}
          style={[globalStyle.textAlignRight, globalStyle.fontSize10, globalStyle.paddingRight5]}
        >
          {props.activationCode.length > 0
            ? t('common.Length', { defaultValue: 'Length' }) +
              ': ' +
              props.activationCode.length +
              '/8'
            : ''}
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.activationCodeInputInnerView}>
      <Input
        multiline={false}
        numberOfLines={1}
        style={[styles.activationCodeInput, inputStyle]}
        textStyle={styles.activationCodeInputTextStyle}
        value={props.activationCode}
        maxLength={8}
        label={renderInputLabel}
        caption={renderInputCaption}
        onChange={(e) => onTextInputChange(e)}
        placeholder={props.placeholder}
        onChangeText={props.onChangeText}
        {...props}
      />
    </View>
  );
};

const CourierAddAgencyConnectionScreen = () => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const [activationCode, setActivationCode] = useState<string>('');
  const [organizationInvitation, setOrganizationInvitation] =
    useState<Partial<OrganizationInvitation>>();
  const [organizationOrganization, setOrganizationOrganization] =
    useState<Partial<OrganizationOrganization>>();
  const [apolloError, setApolloError] = useState<ApolloError>();

  const [
    organizationInvitationByActivationCodeForCourierOrganizationQuery,
    { loading: organizationInvitationByActivationCodeForCourierOrganizationQueryLoading },
  ] = useOrganizationInvitationByActivationCodeForCourierOrganizationLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [connectWithAgencyOrganizationAsCourierOrganizationMutation, { loading: loadingConnect }] =
    useConnectWithAgencyOrganizationAsCourierOrganizationMutation({
      fetchPolicy: 'network-only',
    });

  const callConnectWithAgencyMutation = async ({
    activationCode: _activationCode,
    organizationInvitation: _organizationInvitation,
  }: {
    activationCode: string;
    organizationInvitation: OrganizationInvitation;
  }): Promise<boolean> => {
    await connectWithAgencyOrganizationAsCourierOrganizationMutation({
      variables: {
        courierOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
        agencyOrganizationId: _organizationInvitation.invitingOrganization?.id as string,
        activationCode: _activationCode,
      },
      refetchQueries: [CONNECTED_AGENCY_ORGANIZATIONS_FOR_COURIER_ORGANIZATION],
      context: { exceptionOnError: true },
      onCompleted: async (data) => {
        setActivationCode('');
        setOrganizationOrganization(
          data.connectWithAgencyOrganizationAsCourierOrganization as Partial<OrganizationOrganization>,
        );
        setOrganizationInvitation(undefined);
        setApolloError(undefined);
        showMessage({
          message: 'Successfully connected with Agency',
          description: `You successfully connected with the Agency ${_organizationInvitation
            ?.invitingOrganization?.legalName}${
            _organizationInvitation?.invitingOrganization?.shortName
              ? ' (' + _organizationInvitation.invitingOrganization?.shortName + ')'
              : ''
          }.`,
          type: 'success',
          autoHide: true,
          hideOnPress: true,
          duration: 8000,
        });
      },
      onError: (e) => {
        setApolloError(e);
      },
    });
    return true;
  };

  const connectWithAgency = async ({
    activationCode: _activationCode,
    organizationInvitation: _organizationInvitation,
  }: {
    activationCode: string;
    organizationInvitation: OrganizationInvitation;
  }) => {
    showConfirmModal({
      confirmButtonStatus: 'primary',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.confirm', {
        defaultValue: 'Confirm',
      }),
      title: `Connecting with Agency "${_organizationInvitation.invitingOrganization?.legalName}"`,
      text: `Do you want to connect with the Agency ${_organizationInvitation.invitingOrganization
        ?.legalName}${
        _organizationInvitation.invitingOrganization?.shortName
          ? ' (' + _organizationInvitation.invitingOrganization?.shortName + ')'
          : ''
      }?\nThe Agency will have access to your profile information and documents so that they can offer you OBC jobs, communicate with you via our platform, and much more.`,
      cancelButtonText: t('deleteAccount.confirmModal.cancelButtonText', {
        defaultValue: 'Account is now deleted',
      }),
      onConfirmPress: async () =>
        callConnectWithAgencyMutation({
          activationCode: _activationCode,
          organizationInvitation: _organizationInvitation,
        }),
      onCancelPress: () => {
        showMessage({
          message: 'Aborted',
          description: `The action to connect with the Agency "${_organizationInvitation.invitingOrganization?.legalName}" was aborted`,
          type: 'warning',
          autoHide: true,
          hideOnPress: true,
          duration: 8000,
        });
      },
      visible: true,
    });
  };

  const loadOrganizationInvitationByActivationCode = async (_activationCode: string) => {
    await organizationInvitationByActivationCodeForCourierOrganizationQuery({
      variables: {
        organizationId: appUserContext.currentUserContext?.organization?.id as string,
        activationCode: _activationCode,
      },
      context: { exceptionOnError: true },
      onCompleted: (data) => {
        Promise.all([
          setOrganizationInvitation(
            data.organizationInvitationByActivationCodeForCourierOrganization as Partial<OrganizationInvitation>,
          ),
          setApolloError(undefined),
        ]);
      },
      onError: (e) => {
        Promise.all([setOrganizationInvitation(undefined), setApolloError(e)]);
      },
    });
  };

  useEffect(() => {
    const func = async () => {
      if (activationCode.length === 8) {
        await loadOrganizationInvitationByActivationCode(activationCode);
      }
      if (activationCode.length !== 8 && (organizationInvitation || organizationOrganization)) {
        setApolloError(undefined);
        setOrganizationInvitation(undefined);
        setOrganizationOrganization(undefined);
      }
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode]);

  return (
    <ScrollView contentContainerStyle={styles.addAgencyConnectionScreenView}>
      <Text style={styles.introText}>
        {
          t('activation.addAgencyIntroText', {
            defaultValue:
              'You need an invitation to connect to an Agency. Reach out to your Agencies to obtain an Activation Code. Enter the Activation Code you have received via SMS below.',
          }) as string
        }
      </Text>
      <View style={styles.activationCodeInputView}>
        <ActivationCodeInput
          setActivationCode={setActivationCode}
          activationCode={activationCode}
          disabled={false}
          hasError={!!apolloError}
        />
        {!!organizationInvitation && (
          <OrganizationInvitationPreview organizationInvitation={organizationInvitation} />
        )}
        <TranslatedErrorText
          apolloError={apolloError}
          variables={{ activationCode }}
          errorViewStyle={styles.errorViewStyle}
        />
        <Button
          disabled={
            activationCode.length !== 8 ||
            organizationInvitationByActivationCodeForCourierOrganizationQueryLoading ||
            !!apolloError
          }
          style={styles.connectWithAgencyButton}
          onPress={() =>
            connectWithAgency({
              activationCode,
              organizationInvitation: organizationInvitation as OrganizationInvitation,
            })
          }
          accessoryLeft={
            activationCode.length === 8 && loadingConnect ? (
              <Icon name="loader-outline" height={16} width={16} fill="#fff" />
            ) : (
              <Icon name="link-2-outline" height={16} width={16} fill="#fff" />
            )
          }
        >
          {
            t('activation.connectWithAgency', {
              defaultValue: 'Connect with Agency',
            }) as string
          }
        </Button>
      </View>
    </ScrollView>
  );
};

const CourierConnectedAgencyOrganizationsScreen = () => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const [connectedOrganizations, setConnectedOrganizations] =
    useState<Array<ConnectedOrganization>>();
  const [connectedOrganizationsFiltered, setConnectedOrganizationsFiltered] =
    useState<Array<ConnectedOrganization>>();
  const [refreshing, setRefreshing] = useState(true);
  const [filtering, setFiltering] = useState(false);
  const [filterTimer, setFilterTimer] = useState<ReturnType<typeof setTimeout>>();
  const [filterTerm, setFilterTerm] = useState<string>();

  const {
    loading: connectedAgencyOrganizationsForCourierOrganizationLoading,
    refetch: connectedAgencyOrganizationsForCourierOrganizationRefetch,
    // networkStatus: connectedAgencyOrganizationsForCourierOrganizationNetworkStatus,
  } = useConnectedAgencyOrganizationsForCourierOrganizationQuery({
    variables: {
      courierOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any) => {
      if (data) {
        setConnectedOrganizations(
          data.connectedAgencyOrganizationsForCourierOrganization as Array<ConnectedOrganization>,
        );
      }
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  useEffect(() => {
    if (connectedOrganizations) {
      setRefreshing(false);
      setConnectedOrganizationsFiltered(
        getFilteredConnectedOrganizations(connectedOrganizations, filterTerm),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectedOrganizations]);

  useEffect(() => {
    if (connectedOrganizations) {
      setConnectedOrganizationsFiltered(
        getFilteredConnectedOrganizations(connectedOrganizations, filterTerm),
      );
      setFiltering(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTerm]);

  const triggerFilterConnectedOrganizations = (_filterTerm: string) => {
    if (filterTimer) {
      clearTimeout(filterTimer);
    }
    setFiltering(true);
    const _filterTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setFilterTerm(_filterTerm);
    }, 500);
    setFilterTimer(_filterTimer);
  };

  const getFilteredConnectedOrganizations = (
    _connectedOrganizations: Array<ConnectedOrganization>,
    _filterTerm: string | undefined,
  ): Array<ConnectedOrganization> => {
    if (!_connectedOrganizations) {
      return [];
    }
    if (!_filterTerm) {
      return _connectedOrganizations;
    }
    return Object.values(_connectedOrganizations).filter((co) => {
      try {
        const str = JSON.stringify(Object.values(co.organization)).toLowerCase();
        return str.includes(_filterTerm.toLowerCase());
      } catch (e) {
        return false;
      }
    });
  };

  return (
    <>
      <Input
        placeholder={
          t('common.filterAgencyByTerms', {
            defaultValue: 'Filter Agency by name, city, or country',
          }) as string
        }
        style={styles.filterAgencyInput}
        onChangeText={(_filterTerm) => {
          triggerFilterConnectedOrganizations(_filterTerm);
        }}
      />
      <CourierConnectedAgenciesList
        refreshControl={
          <RefreshControl
            refreshing={
              refreshing || filtering || connectedAgencyOrganizationsForCourierOrganizationLoading
            }
            onRefresh={() => connectedAgencyOrganizationsForCourierOrganizationRefetch()}
          />
        }
        filterTerm={filterTerm}
        connectedOrganizations={connectedOrganizationsFiltered || []}
      />
    </>
  );
};

export const CourierConnectedAgenciesScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  const TopTabBar = ({ navigation: _navigation, state }: { navigation: any; state: any }) => (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => {
        _navigation.navigate(state.routeNames[index]);
      }}
      style={globalStyle.height50}
    >
      <Tab title={t('menuItems.connections', { defaultValue: 'Connections' }) as string} />
      <Tab title={t('menuItems.addAgency', { defaultValue: 'Add Agency' }) as string} />
    </TabBar>
  );

  return (
    <>
      <TopNavigationBackToProfileButton
        title={t('menuItems.connectedAgencies', { defaultValue: 'Connected Agencies' })}
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />
      <Navigator tabBar={(props: any) => <TopTabBar {...props} />}>
        <Screen name="CourierConnectedAgenciesConnectionsScreen">
          {(props: any) => <CourierConnectedAgencyOrganizationsScreen {...props} />}
        </Screen>
        <Screen name="CourierAddAgencyConnectionScreen">
          {(props: any) => <CourierAddAgencyConnectionScreen {...props} />}
        </Screen>
      </Navigator>
    </>
  );
};

const themedStyles = StyleService.create({
  connectWithAgencyButton: {
    marginTop: 15,
    width: '100%',
    maxWidth: 500,
  },
  addAgencyConnectionScreenView: {
    paddingHorizontal: 20,
    textAlign: 'center',
    alignItems: 'center',
    contentAlign: 'center',
  },
  activationCodeInputInnerView: { alignItems: 'center', alignContent: 'center' },
  activationCodeInputView: {
    paddingVertical: 0,
    alignContent: 'center',
    alignItems: 'center',
    width: Platform.OS === 'web' ? 600 : '100%',
  },
  activationCodeInputTitle: {
    fontFamily: 'Lato_700Bold',
    marginBottom: 5,
    paddingLeft: 5,
  },
  activationCodeInput: {
    maxWidth: 600,
    padding: 20,
  },
  activationCodeInputTextStyle: {
    fontFamily: 'Lato_700Bold',
    fontSize: 24,
    textTransform: 'uppercase',
    letterSpacing: 4,
    textAlign: 'center',
  },
  connectWithAgencyInfoText: {
    marginTop: 15,
  },
  introText: {
    marginTop: 20,
    marginBottom: 10,
  },
  agencyProfileButtonView: {
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  filterAgencyInput: {
    marginTop: 10,
    marginBottom: 5,
    marginRight: 20,
    marginLeft: 20,
  },
  agencyPreviewLayer: {
    marginLeft: 0,
    marginBottom: 20,
    maxWidth: Platform.OS === 'web' ? '60%' : undefined,
  },
  boldText: {
    fontFamily: 'Lato_700Bold',
  },
  errorMessageText: {
    marginVertical: 5,
  },
  errorViewStyle: {
    width: '100%',
  },
});
