import React, { memo } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';

import { Entypo } from '@expo/vector-icons';
import { Text, useStyleSheet } from '@ui-kitten/components';
import dayjs from 'dayjs';

import { styles as themedStyles } from './styles';
import { globalStyle } from '../../../../../common/style';
import { MessageType } from '../../../../../generated-graphql-types';
import { RefreshOutlineIcon } from '../../../../icons.component';
import { ChatMessageProfileImage } from '../ChatMessageProfileImage';

export const ChatMessageWrapperComponent: React.FC<any> = ({
  children,
  currentMessage,
  handleSendAgain: handleSendAgainProp,
  isCurrentUser,
  nextMessage,
  openMessageOptions,
  showMessageOptionsMenu = true,
  styleDateText,
  styleNameText,
}) => {
  const styles = useStyleSheet(themedStyles);

  const handleSendAgain = () => {
    handleSendAgainProp(currentMessage);
  };

  const lastMessage = !Object.keys(nextMessage).length;
  const { createdAt, user } = currentMessage;

  let messageStyle = isCurrentUser ? styles.messageRight : styles.messageLeft;
  if (
    [
      MessageType.TRACKER_COURIER_TRACKING_POINT_COMPLETED,
      MessageType.TRACKER_COURIER_TRACKING_POINT_RESET,
      MessageType.TRACKER_FLIGHT_DETAILS_CREATED,
      MessageType.TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE,
      MessageType.TRACKER_FLIGHT_DETAILS_REMOVED,
      MessageType.TRACKER_FLIGHT_DETAILS_UPDATED,
    ].includes(currentMessage.messageType)
  ) {
    messageStyle = isCurrentUser ? styles.messageRightTracking : styles.messageLeftTracking;
  }
  return (
    <View style={isCurrentUser ? globalStyle.flexRowReverse : globalStyle.flexRow}>
      <View style={[styles.avatar, isCurrentUser ? styles.avatarRight : styles.avatarLeft]}>
        <ChatMessageProfileImage
          current={isCurrentUser}
          firstName={user?.firstNames || '-'}
          lastName={user?.lastName || '-'}
          uri={user?.profilePicture?.sasUrl}
        />
      </View>
      <View
        style={[
          styles.messageContainer,
          isCurrentUser ? styles.messageContainerRight : styles.messageContainerLeft,
          lastMessage && globalStyle.marginBottom5,
          lastMessage && globalStyle.paddingBottom25,
          !lastMessage && globalStyle.paddingBottom5,
        ]}
      >
        <View
          style={[
            styles.message,
            messageStyle,
            currentMessage.errorWhileSending && styles.messageError,
            isCurrentUser ? styles.borderBottomLeftRadius16 : styles.borderBottomRightRadius16,
          ]}
        >
          <View
            style={[
              globalStyle.flexRow,
              globalStyle.justifyContentBetween,
              globalStyle.alignItemsStart,
            ]}
          >
            {showMessageOptionsMenu && isCurrentUser && (
              <Pressable
                style={globalStyle.marginHorizontal5}
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={openMessageOptions(currentMessage)}
              >
                <Entypo
                  name="dots-three-horizontal"
                  size={14}
                  color={(styles.downloadIcon as any).color}
                />
              </Pressable>
            )}
            {!showMessageOptionsMenu && isCurrentUser && <View />}
            <View style={globalStyle.marginBottom5}>
              <Text
                style={[
                  styles.dateText,
                  isCurrentUser ? styles.textRight : styles.textLeft,
                  styleDateText,
                ]}
              >
                {dayjs(createdAt).format('DD.MM.YYYY. HH:mm')}
              </Text>

              <Text
                style={[
                  styles.nameText,
                  isCurrentUser ? styles.textRight : styles.textLeft,
                  styleNameText,
                ]}
              >
                {user.name?.trim()}
              </Text>
            </View>
          </View>
          {children}
        </View>
      </View>
      {!!currentMessage.errorWhileSending && (
        <Pressable
          style={[
            globalStyle.flexRow,
            globalStyle.alignItemsCenter,
            lastMessage && globalStyle.paddingBottom20,
          ]}
          onPress={handleSendAgain}
        >
          {currentMessage.resending ? (
            <ActivityIndicator />
          ) : (
            <RefreshOutlineIcon
              fill={(styles.downloadIcon as any).color}
              style={styles.downloadIcon}
            />
          )}
        </Pressable>
      )}
    </View>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps.currentMessage.id === nextProps.currentMessage.id) {
    if (
      prevProps.currentMessage.chatFile &&
      nextProps.currentMessage.chatFile &&
      prevProps.currentMessage.chatFile?.sasUrl !== nextProps.currentMessage.chatFile?.sasUrl
    ) {
      return false;
    }
    if (
      prevProps.currentMessage.messageText &&
      prevProps.currentMessage.text !== nextProps.currentMessage.text
    ) {
      return false;
    }
    if (prevProps.currentMessage.errorWhileSending !== nextProps.currentMessage.errorWhileSending) {
      return false;
    }
    if (prevProps.currentMessage.resending !== nextProps.currentMessage.resending) {
      return false;
    }
    if (prevProps.nextMessage?.id !== nextProps.nextMessage?.id) {
      return false;
    }
  }

  return true;
};

export const ChatMessageWrapper = memo(ChatMessageWrapperComponent, arePropsEqual);
