import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  locationAutocomplete: {
    marginTop: 20,
    marginBottom: 10,
  },
  emergencyAddressContainer: {
    marginTop: 20,
  },
});
