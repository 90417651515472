import { useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { IndexPath } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { Props } from './types';

export const useHook = (props: Props) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);
  const [opened, setOpened] = useState(false);

  const placeholder = useMemo(() => {
    let _placeholder = '';
    if (
      typeof props.placeholder === 'string' &&
      props.placeholder &&
      ((!props.multiSelect && !props.value) ||
        (props.multiSelect && !props.value?.length) ||
        props.forceCustomPlaceholder)
    ) {
      return props.placeholder;
    }

    if (props.multiSelect && props.value && Array.isArray(props.value)) {
      if (props.value?.length) {
        (props.initialItems || props.items).forEach((item: any) => {
          if (props.value?.includes(item[props.fieldValueKey])) {
            if (!_placeholder.length && item[props.fieldTitleKey]) {
              _placeholder = item[props.fieldTitleKey];
            } else if (item[props.fieldTitleKey]) {
              _placeholder += `, ${item[props.fieldTitleKey]}`;
            }
          }
        });
      }
    } else if (props.value) {
      const textField = (props.initialItems || props.items).find(
        (item) => item[props.fieldValueKey] === props.value,
      );
      if (textField) {
        _placeholder = textField[props.fieldTitleKey];
      }
    }
    return (
      _placeholder ||
      (props.placeholderDefault as string) ||
      (t('common.selectOption', { defaultValue: 'Select option' }) as string)
    );
  }, [
    props.placeholder,
    props.value,
    props.multiSelect,
    props.initialItems,
    props.items,
    props.fieldTitleKey,
    props.fieldValueKey,
    props.forceCustomPlaceholder,
    props.placeholderDefault,
    t,
  ]);

  const handleSelect = (index: IndexPath | IndexPath[]) => {
    if (Platform.OS !== 'web') {
      return;
    }
    let value;
    let disabled = false;
    if (index && Array.isArray(index)) {
      disabled = props.items?.[index[0].row]?.disabled;
      value = props.items?.[index[0].row]?.[props.fieldValueKey];
    } else if (index && !Array.isArray(index)) {
      disabled = props.items?.[index.row]?.disabled;
      value = props.items?.[index.row]?.[props.fieldValueKey];
    }

    if (!disabled && props.onSelect) {
      props.onSelect(value);
    }
  };

  const handleOpenPress = () => {
    if (props.disabled) {
      return;
    }
    setOpened(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  };

  const handleClosePress = () => {
    setIsVisible(false);
    setTimeout(() => {
      setOpened(false);
    }, 200);
  };

  return { handleClosePress, handleOpenPress, handleSelect, isVisible, opened, placeholder };
};
