import React, { memo, useState } from 'react';
import { Text } from 'react-native';

import { useStyleSheet } from '@ui-kitten/components';
import { Image } from 'expo-image';

import { themedStyles } from './styles';

export const ChatMessageProfileImageComponent: React.FC<any> = ({
  current,
  firstName,
  lastName,
  uri,
}) => {
  const styles = useStyleSheet(themedStyles);
  const [loaded, setLoaded] = useState('');

  const handleOnError = () => {
    setLoaded('error');
  };

  const handleOnLoad = () => {
    setLoaded('loaded');
  };

  if (uri && loaded !== 'error') {
    return (
      <Image
        style={themedStyles.avatarImage}
        source={uri}
        contentFit="cover"
        transition={1000}
        onError={handleOnError}
        onLoad={handleOnLoad}
      />
    );
  }
  return (
    <Text style={[styles.avatarText, current ? styles.textRight : styles.textLeft]}>
      {firstName[0]}
      {lastName[0]}
    </Text>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps.uri !== nextProps.uri) {
    return false;
  }
  if (prevProps.firstName !== nextProps.firstName) {
    return false;
  }
  if (prevProps.lastName !== nextProps.lastName) {
    return false;
  }
  return true;
};

export const ChatMessageProfileImage = memo(ChatMessageProfileImageComponent, arePropsEqual);
