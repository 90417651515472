import { Platform } from 'react-native';

import * as ExpoLinking from 'expo-linking';

export const openUrl = async (url: string) => {
  if (Platform.OS === 'web') {
    window.open(url, '_blank', 'noopener');
  } else {
    const supported = await ExpoLinking.canOpenURL(url);
    if (supported) {
      await ExpoLinking.openURL(url);
    }
  }
};
