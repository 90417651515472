/* eslint-disable no-case-declarations */
import { ApolloLink, Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { showMessage } from 'react-native-flash-message';

import { createApolloClientPersistAsync } from './create-apollo-client-persist-async';
import { getAuthorizationHeaders } from './create-auth-link-with-headers';
import { MUTATION_SIGN_OUT_USER_CONTEXT } from './graphql-mutations';
import i18n from '../i18n/i18n';
import {
  deleteCurrentUserContextFromStorage,
  deleteUserContextWithAccessTokenFromUserContextsInStorage,
  deleteUserContextWithUserContextIdFromUserContextsInStorage,
  getAuthorizationHeadersWithSignInToken,
  updateUserContext,
  loadCurrentUserContext,
} from '../modules/authentication/authentication.module';
import { reloadApp } from '../modules/helper/app-reload.helper';
import { getNewToken } from '../utils/get-new-token';

export const extractErrorMessage = (error: any) => {
  if (!error) {
    return {
      message: i18n.t('errors.somethingWentWrongTryAgain'),
      messages: [i18n.t('errors.somethingWentWrongTryAgain')],
    };
  }

  let message = '';
  const messages: string[] = [];
  let errorMessage = '';
  const errorMessages: string[] = [];

  if (error.graphQLErrors?.length) {
    if (error.graphQLErrors[0]?.extensions) {
      error.graphQLErrors.forEach(({ extensions: { exception, response } }: any) => {
        if (exception) {
          if (exception.response?.messageCode) {
            errorMessages.push(exception.response?.messageCode);
            messages.push(i18n.t(`errors.${exception.response?.messageCode}`));
          } else if (exception.message) {
            errorMessages.push(exception.message);
            messages.push(exception.message);
          }
        } else if (response) {
          if (Array.isArray(response.message)) {
            response.message.forEach((eMessage: any) => {
              errorMessages.push(eMessage);
              messages.push(i18n.t(`errors.${eMessage}`));
            });
          } else if (response.message.messageCode) {
            errorMessages.push(response.message.messageCode);
            messages.push(i18n.t(`errors.${response.message.messageCode}`));
          } else if (response.message) {
            errorMessages.push(response.message);
            messages.push(i18n.t(`errors.${response.message}`));
          }
        }
      });
    } else {
      errorMessage = error.graphQLErrors[0].message;
      message = error.graphQLErrors[0].message;
      error.graphQLErrors.forEach(({ message: eMessage }: any) => {
        errorMessages.push(eMessage);
        messages.push(eMessage);
      });
    }
  } else if (error?.response?.message) {
    const mess = error?.response?.message;
    return { errorMessage: mess, errorMessages: [mess], message: mess, messages: [mess] };
  }

  if (message && messages.length) {
    return { errorMessage, errorMessages, message, messages };
  } else if (!message && messages.length) {
    return { errorMessage: errorMessages[0], errorMessages, message: messages[0], messages };
  } else if (message && !messages.length) {
    return { errorMessage, message };
  } else if (error.code && i18n.exists(`errors.${error.code}`)) {
    return {
      errorMessage: i18n.t(`errors.${error.code}`),
      errorMessages: [i18n.t(`errors.${error.code}`)],
      message: i18n.t(`errors.${error.code}`),
      messages: [i18n.t(`errors.${error.code}`)],
    };
  } else if (error.message) {
    return {
      errorMessage: error.message,
      errorMessages: [error.message],
      message: error.message,
      messages: [error.message],
    };
  }
  return {
    errorMessage,
    errorMessages,
    message: i18n.t('errors.somethingWentWrongTryAgain'),
    messages: [i18n.t('errors.somethingWentWrongTryAgain')],
  };
};

const deleteCurrentUserContext = async ({
  accessToken,
  userContextId,
}: {
  accessToken?: string;
  userContextId?: string;
}) => {
  await deleteCurrentUserContextFromStorage();
  if (userContextId) {
    await deleteUserContextWithUserContextIdFromUserContextsInStorage(userContextId);
  }
  if (accessToken) {
    await deleteUserContextWithAccessTokenFromUserContextsInStorage(accessToken);
  }
};

const signOut = async ({ userContextId }: { userContextId: string }) => {
  const userContext = await loadCurrentUserContext();

  if (userContext) {
    const { apolloClient } = await createApolloClientPersistAsync({
      userContext: userContext || undefined,
    });
    try {
      await apolloClient.mutate({
        mutation: MUTATION_SIGN_OUT_USER_CONTEXT,
        variables: { data: { deviceId: userContext.deviceId, userId: userContext.user?.id } },
        context: {
          headers: await getAuthorizationHeaders(userContext),
          userContextId,
        },
        fetchPolicy: 'network-only',
      });
      // eslint-disable-next-line no-empty
    } catch {}
  }
};

export const deleteCurrentUserContextAndReload = async ({
  accessToken,
  userContextId,
  reload = false,
}: {
  accessToken?: string;
  userContextId?: string;
  reload?: boolean;
}) => {
  if (userContextId) {
    await signOut({ userContextId });
  }
  await deleteCurrentUserContext({ userContextId, accessToken });
  if (reload) {
    reloadApp({ reloadMessageType: 'LOGIN_EXPIRED', timeout: 8000 });
  }
};

const purgeAsyncStorageAndReload = async () => {
  AsyncStorage.getAllKeys((_, keys) => {
    if (keys) {
      AsyncStorage.multiRemove(keys)
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          reloadApp();
        });
    }
  });
};

// There seems to be a type error for using forward(operation)
// Hence the @ts-ignore-line below that omits the error inside onError((errorHandler) => ...)
// @ts-ignore-line
export const errorLink: ApolloLink = onError((e) => {
  const { graphQLErrors, networkError, operation, forward } = e;
  // const _parts = operation.getContext().headers['authorization']?.split(' ');
  // const currentAccessToken = _parts && _parts.length > 1 ? _parts[1] : null;
  // const userContextId = operation.getContext().headers['x-user-context-id'];

  if (networkError) {
    console.warn('errorLink: NETWORK ERROR', networkError);
  }
  if (graphQLErrors) {
    console.warn('errorLink: graphQLErrors', graphQLErrors);

    // NOTE: This is necessary to break the loop in case the error shall be published in the frontend.

    if (operation.getContext().exceptionOnError) {
      return;
    }
    const cb = operation.getContext()?.cb;
    if (cb) {
      cb(e);
      return;
    }

    for (const err of graphQLErrors) {
      switch (err.extensions?.code) {
        case 'UNAUTHENTICATED':
          const headers: Record<string, string> | undefined = {
            ...operation.getContext().headers,
          };
          const accessToken =
            headers && headers.authorization && headers.authorization?.split(' ')[1];
          const userContextId = headers && headers['x-user-context-id'];

          if ([41008].includes(err.extensions?.internalCode as number)) {
            deleteCurrentUserContextAndReload({
              accessToken,
              userContextId,
            });
            return;
          }

          if ([41006].includes(err.extensions?.internalCode as number)) {
            deleteCurrentUserContextAndReload({
              accessToken,
              userContextId,
            });
            return;
          }

          // NOTE: This is necessary to break the loop in case no retry shall be performed.
          if (operation.getContext().exceptionOnUnauthenticated) {
            return;
          }

          return new Observable((observer) => {
            getNewToken(headers)
              .then((newUserContext) => {
                if (newUserContext) {
                  updateUserContext(newUserContext).then((success) => {
                    if (success) {
                      if (process.env.EXPO_APP_ENV === 'development') {
                        showMessage({
                          message:
                            'Merged and stored refreshed UserContext ' + newUserContext.user?.email,
                          description: newUserContext.userContextId,
                          type: 'success',
                          autoHide: true,
                          hideOnPress: true,
                          duration: 5000,
                        });
                      }

                      if (operation.getContext().headers['x-sign-in-token'] && newUserContext) {
                        getAuthorizationHeadersWithSignInToken(newUserContext).then(
                          (newHeaders) => {
                            newHeaders = {
                              ...operation.getContext().headers, // get previous headers
                              ...newHeaders, // Overwrite with Authorization headers
                              'x-has-refreshed-token': 'true', // Mark as refreshed token request
                            };
                            operation.setContext({
                              headers: newHeaders,
                            });
                          },
                        );
                      } else {
                        getAuthorizationHeaders(newUserContext).then((newHeaders) => {
                          newHeaders = {
                            ...operation.getContext().headers, // get previous headers
                            ...newHeaders, // Overwrite with Authorization headers
                            'x-has-refreshed-token': 'true', // Mark as refreshed token request
                          };
                          operation.setContext({
                            headers: newHeaders,
                          });
                        });
                      }
                    }
                  });
                } else {
                  showMessage({
                    message: i18n.t('common.noLoggedInUser', {
                      defaultValue: 'Could not find logged in user',
                    }),
                    description: i18n.t('common.noLoggedInUserText', {
                      defaultValue:
                        'Could not generate credentials for current user. We are sorry for inconvenience, you will have to login again to use your account.',
                    }) as string,
                    type: 'danger',
                    autoHide: true,
                    hideOnPress: true,
                    duration: 8000,
                  });
                  return {};
                }
              })
              .then(() => {
                if (
                  headers &&
                  headers.authorization !== operation.getContext().headers.authorization
                ) {
                  if (process.env.EXPO_APP_ENV === 'development') {
                    showMessage({
                      message: 'ERROR errorLink authorization headers were updated',
                      description: operation.getContext().headers.authorization,
                      type: 'warning',
                      autoHide: false,
                      hideOnPress: true,
                      duration: 5000,
                    });
                  }
                  if (
                    headers &&
                    headers.authorization !== undefined &&
                    headers.authorization.length > 30 &&
                    headers.authorization === operation.getContext().headers.authorization
                  ) {
                    showMessage({
                      message: 'ERROR errorLink authorization headers are still equal',
                      description: 'If this error persists, re-login.',
                      type: 'warning',
                      autoHide: false,
                      hideOnPress: true,
                      duration: 5000,
                    });
                  }
                }

                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                if (operation.getContext().forbidRepeatRequest) {
                  return;
                }
                return forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                if (error?.message) {
                  showMessage({
                    message: i18n.t('common.error', { defaultValue: 'Error' }) as string,
                    description: error.message,
                    type: 'danger',
                    autoHide: false,
                    hideOnPress: true,
                    duration: 5000,
                  });
                } else {
                  showMessage({
                    message: i18n.t('common.noLoggedInUser', {
                      defaultValue: 'Could not find logged in user',
                    }),
                    description: i18n.t('common.noLoggedInUserText', {
                      defaultValue:
                        'Could not generate credentials for current user. We are sorry for inconvenience, you will have to login again to use your account.',
                    }) as string,
                    type: 'danger',
                    autoHide: true,
                    hideOnPress: true,
                    duration: 8000,
                  });
                }
                deleteCurrentUserContextAndReload({ accessToken, userContextId, reload: true });
              });
          });
        case 'BAD_USER_INPUT':
          if ((err.extensions?.response as any)?.message?.length) {
            let errorMessage = '';
            const messages = (err.extensions?.response as { message: string[] })?.message;
            messages.forEach((item) => {
              if (errorMessage.length) {
                errorMessage += '\n';
              }
              errorMessage = i18n.t(item);
            });
            showMessage({
              message: i18n.t('common.badInput', { defaultValue: 'Bad input' }),
              description: errorMessage,
              type: 'danger',
              autoHide: false,
              hideOnPress: true,
              duration: 30000,
            });
          } else {
            showMessage({
              message: i18n.t(`error.internalCode.${err.extensions.internalCode}.message`, {
                defaultValue: 'BAD USER INPUT',
              }),
              description: i18n.t(`error.internalCode.${err.extensions.internalCode}.description`, {
                defaultValue: err?.message,
              }) as string,
              type: 'danger',
              autoHide: false,
              hideOnPress: true,
              duration: 30000,
            });
          }
          break;
        case 'FORBIDDEN':
          if ((err.extensions.internalCode as number) === 43004) {
            showMessage({
              message: 'FORBIDDEN: ' + err.extensions.originalErrorClassName,
              description: err.extensions.detail as string,
              type: 'danger',
              autoHide: false,
              hideOnPress: true,
              duration: 30000,
            });
          }
          break;
        case 'NOT_FOUND':
          // If the deviceId is invalid, reload the entire application.
          // TODO: Find a solution for when DeviceLog and DeviceLocation are being sent.
          // This could happen while new device will be set up and will cause the app to reload.
          // Until the, the below stays out-commented.
          if ([44001, 44002, 44003].includes(err.extensions?.internalCode as number)) {
            setTimeout(() => purgeAsyncStorageAndReload(), 1000);
          }
          break;
        default:
          if (err.extensions.originalErrorClassName) {
            showMessage({
              message: 'Other error - ' + err.extensions.originalErrorClassName,
              description: JSON.stringify(err.extensions.detail) as string,
              type: 'danger',
              autoHide: true,
              hideOnPress: true,
              duration: 8000,
            });
          } else if ((err.extensions?.response as any)?.message?.length) {
            let errorMessage = '';
            const messages = (err.extensions?.response as { message: string[] })?.message;
            messages.forEach((item) => {
              if (errorMessage.length) {
                errorMessage += '\n';
              }
              errorMessage = i18n.t(item);
            });
            showMessage({
              message: i18n.t('common.otherError', { defaultValue: 'Other error' }),
              description: errorMessage,
              type: 'danger',
              autoHide: true,
              hideOnPress: true,
              duration: 8000,
            });
          }
      }
    }
  }
});
