import Constants from 'expo-constants';

import {
  GeocodeResult,
  LatLngLiteral,
  PlaceType1,
  PlaceType2,
} from '../types/google-maps.common.types';
import { GeocodeResponseData } from '../types/google-maps.geocode.types';
import { getGoogleMapsApiKey } from '../utils/google';

export type ReverseGeocodeLocationType =
  | 'localities'
  | 'small_regions'
  | 'large_regions'
  | 'airports'
  | 'street_addresses';

export const reverseGeocodeLocation = async (
  location: LatLngLiteral,
  types: ReverseGeocodeLocationType[],
): Promise<GeocodeResult[] | null> => {
  const url =
    Constants.expoConfig?.extra?.priojetGoogleMapsProxyUrl +
    `/geocode/json?latlng=${location.lat},${location.lng}&key=${getGoogleMapsApiKey('web')}`;

  const response = await fetch(url).catch((e) => {
    console.warn(e);
  });

  if (response) {
    const jsonResponse: GeocodeResponseData = await response.json();
    if (jsonResponse) {
      const localities: GeocodeResult[] = [];
      const smallRegions: GeocodeResult[] = [];
      const largeRegions: GeocodeResult[] = [];
      const airports: GeocodeResult[] = [];
      const streetAddresses: GeocodeResult[] = [];

      for (let i = 0; i < jsonResponse.results.length; i++) {
        if (jsonResponse.results[i].types.includes(PlaceType1.airport)) {
          airports.push(jsonResponse.results[i]);
          continue;
        }
        // Filter out localities
        if (
          jsonResponse.results[i].types.includes(PlaceType2.locality) ||
          jsonResponse.results[i].types.includes(PlaceType2.sublocality)
        ) {
          localities.push(jsonResponse.results[i]);
          continue;
        }
        // Filter out small regions
        if (
          jsonResponse.results[i].types.includes(PlaceType2.administrative_area_level_2) ||
          jsonResponse.results[i].types.includes(PlaceType2.administrative_area_level_3)
        ) {
          smallRegions.push(jsonResponse.results[i]);
          continue;
        }
        // Filter out large regions (state, country)
        if (
          jsonResponse.results[i].types.includes(PlaceType2.administrative_area_level_1) ||
          jsonResponse.results[i].types.includes(PlaceType2.country)
        ) {
          largeRegions.push(jsonResponse.results[i]);
          continue;
        }
        // Filter out large regions (state, country)
        if (jsonResponse.results[i].types.includes(PlaceType2.street_address)) {
          streetAddresses.push(jsonResponse.results[i]);
          continue;
        }
      }

      let geocodedResults: GeocodeResult[] | null = [];
      if (types.includes('airports')) {
        geocodedResults = geocodedResults.concat(airports);
      }
      if (types.includes('localities')) {
        geocodedResults = geocodedResults.concat(localities);
      }
      if (types.includes('small_regions')) {
        geocodedResults = geocodedResults.concat(smallRegions);
      }
      if (types.includes('large_regions')) {
        geocodedResults = geocodedResults.concat(largeRegions);
      }
      if (types.includes('street_addresses')) {
        geocodedResults = geocodedResults.concat(streetAddresses);
      }

      return geocodedResults;
    }
  }
  return null;
};
