import React from 'react';

import { Button, Layout, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { LanguageListData } from '../../../../assets/constants/language.list.data';
import { FormSelect } from '../../../../components/common/form/FormSelect';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';
import { RemoveIcon } from '../../../../components/icons.component';
import { LanguagesKnownInput } from '../../../../graphql-types';
import { LanguageSkillLevelList } from '../../../../types';

export const OBCLanguagesEdit = ({
  addCourierUserProfileLanguagesKnown,
  handleRemoveLanguage,
  languagesList,
  selectedLanguage,
  setSelectedLanguage,
  setSelectedLanguageLevel,
  selectedLanguages,
  selectedLanguageLevel,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const renderRemoveLanguageAccessory = (languageKnown: LanguagesKnownInput) => (
    <Button
      accessoryRight={RemoveIcon}
      appearance="ghost"
      onPress={handleRemoveLanguage(languageKnown)}
      style={styles.removeButton}
    />
  );

  const renderLanguageLevelComponent = (
    languageKnown?: LanguagesKnownInput & { code: string; name: string },
  ) => {
    return (
      <Layout
        key={languageKnown?.language || 'language' + 'Layout'}
        level="1"
        style={styles.languageLayout}
      >
        <FormSelect
          containerStyle={styles.languageSelect}
          field="language"
          disabled={languageKnown?.language !== undefined}
          appearance="default"
          placeholder={t('courierUserProfile.language', { defaultValue: 'Language' }) as string}
          // selectedIndex={indexData.languageIndex}
          value={languageKnown?.code || selectedLanguage?.code}
          onSelect={(value: any) => {
            const found = LanguageListData.find((item) => item.code === value);
            setSelectedLanguage(found);
            setSelectedLanguageLevel(undefined);
          }}
          initialItems={LanguageListData}
          items={languagesList}
          fieldTitleKey="name"
          fieldValueKey="code"
        />
        <FormSelect
          containerStyle={styles.languageSelect}
          disabled={languageKnown?.level !== undefined}
          appearance="default"
          placeholder={t('courierUserProfile.level', { defaultValue: 'Level' }) as string}
          // selectedIndex={indexData.languageLevelIndex}
          value={languageKnown ? languageKnown.level : selectedLanguageLevel}
          onSelect={(value: any) => {
            setSelectedLanguageLevel(value);
            addCourierUserProfileLanguagesKnown(value);
          }}
          items={LanguageSkillLevelList.map((item) => ({ name: item, value: item }))}
          fieldTitleKey="name"
          fieldValueKey="value"
        />
        {!!languageKnown && renderRemoveLanguageAccessory(languageKnown)}
      </Layout>
    );
  };

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.languagesKnownMultipleAllowed', {
        defaultValue: 'Languages known (multiple allowed)',
      }).toUpperCase()}
      Content={
        <>
          {selectedLanguages?.map(
            (languageKnown: LanguagesKnownInput & { code: string; name: string }) =>
              renderLanguageLevelComponent(languageKnown),
          )}
          {renderLanguageLevelComponent()}
        </>
      }
      style={styles.contentBox}
    />
  );
};

const arePropsEqual = (prev: any, next: any) => {
  if (next.languagesList !== prev.languagesList) {
    return false;
  }
  if (next.selectedLanguage !== prev.selectedLanguage) {
    return false;
  }
  if (next.selectedLanguages !== prev.selectedLanguages) {
    return false;
  }
  if (next.selectedLanguageLevel !== prev.selectedLanguageLevel) {
    return false;
  }
  return true;
};

export default React.memo(OBCLanguagesEdit, arePropsEqual);
