import React, { useCallback } from 'react';
import { Linking, TextStyle } from 'react-native';

import { TextElement, Text, StyleService, useStyleSheet } from '@ui-kitten/components';
import { LiteralUnion } from '@ui-kitten/components/devsupport/typings';
import { DefaultTFuncReturn } from 'i18next';
import { showMessage } from 'react-native-flash-message';

export const TextLink = ({
  text,
  url,
  style,
  onPress,
  textCategory,
}: {
  text: string;
  url?: string | DefaultTFuncReturn;
  style?: TextStyle;
  onPress?: () => void;
  textCategory?: LiteralUnion<
    'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 's1' | 's2' | 'p1' | 'p2' | 'c1' | 'c2' | 'label'
  >;
}): TextElement => {
  const styles = useStyleSheet(themedStyles);

  const handlePress = useCallback(async () => {
    if (!url) {
      console.warn('No url specified.');
      showMessage({
        message: '',
        description: 'No url specified.',
        type: 'warning',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
      return;
    }
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url as string);
    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url as string);
    } else {
      showMessage({
        message: '',
        description: `Don't know how to open this URL: ${url}`,
        type: 'warning',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
    }
  }, [url]);

  return (
    <Text style={[styles.link, style]} onPress={onPress || handlePress} category={textCategory}>
      {text}
    </Text>
  );
};

const themedStyles = StyleService.create({
  link: {
    color: 'color-primary-default',
  },
});
