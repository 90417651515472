import React from 'react';

import { useQuery } from '@apollo/client';
import { Button, Layout, StyleService } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { USER_BACKGROUND_DOCUMENTS_QUERY } from '../../apollo/graphql-queries';
import CommonUserDocumentsComponent from '../../components/common.user-documents.component';
import { CourierProfileTopNavigationBar } from '../../components/courier/courier-profile-top-navigation-bar.component';
import { AddIcon } from '../../components/icons.component';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import AppUserContext from '../../contexts/AppUserContext';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { UserDocument2 } from '../../types';

const AddNewDocument = ({ navigation }: any): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        accessoryLeft={AddIcon}
        onPress={() => {
          navigation.navigate('CommonUserDocumentDetailScreen', {
            targetDocument: 'UserBackgroundDocument',
            readOnly: false,
            title: 'Add Background Document',
          });
        }}
        appearance="ghost"
      >
        {t('document.addDocument', { defaultValue: 'Add document' }) as string}
      </Button>
    </>
  );
};

export const CourierBackgroundDocumentsScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const appUserContext = React.useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const { data: userBackgroundDocumentsData } = useQuery<{
    userBackgroundDocuments: UserDocument2[];
  }>(USER_BACKGROUND_DOCUMENTS_QUERY, {
    variables: { userId: appUserContext.currentUserContext?.user?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  return (
    <SafeAreaLayout style={styles.container}>
      <CourierProfileTopNavigationBar title="Background Documents" navigation={navigation} />
      <Layout style={styles.container}>
        <AddNewDocument navigation={navigation} />
        <CommonUserDocumentsComponent
          screenName="CourierBackgroundDocumentsScreen"
          targetDocument="UserBackgroundDocument"
          userDocumentList={userBackgroundDocumentsData?.userBackgroundDocuments}
        />
      </Layout>
    </SafeAreaLayout>
  );
};

const styles = StyleService.create({
  container: {
    flex: 1,
  },
});
