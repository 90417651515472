import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    borderWidth: 1,
  },
  imageModalContainer: {
    backgroundColor: 'black',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  imageModal: {
    resizeMode: 'cover',
    borderRadius: 3,
  },
  closeImageModal: {
    position: 'absolute',
    right: 20,
  },
});
