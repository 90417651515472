import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  activityOverviewLayer: {
    marginTop: 10,
  },
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },
  contentBoxTitleLayer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  titleText: {
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
  },
  textLink: {
    color: 'color-primary-default',
    marginLeft: 5,
  },
  filterContainer: {
    marginTop: 0,
    maxWidth: 170,
  },
  filterContainerMobile: {
    alignItems: 'flex-end',
    marginTop: 0,
    maxWidth: 170,
  },
  filterContainerMinWidth: {
    minWidth: 170,
  },
  filterIconSize: {
    width: 26,
    height: 30,
  },
  filterIconColor: {
    color: 'text-basic-color',
  },
  contentGoToLink: {
    color: 'color-primary-default',
  },
  doneIcon: {
    height: 16,
    width: 16,
    marginRight: 5,
  },
});
