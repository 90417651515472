import { Platform } from 'react-native';

import Constants from 'expo-constants';

export const getGoogleMapsApiKey = (plat?: 'android' | 'ios' | 'web') => {
  const platform = plat || Platform.OS;
  if (platform === 'web') {
    return Constants.expoConfig?.extra?.priojetGoogleMapsWebKey;
  }
  return Constants.expoConfig?.extra?.priojetGoogleMapsMobileKey;
};
