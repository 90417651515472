import React, { forwardRef } from 'react';
import { TouchableOpacity, View, ActivityIndicator } from 'react-native';

import { Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { FormAirportSelectProps } from './types';
import { globalStyle } from '../../../../common/style';
import { FormSelectModal } from '../FormSelectModal';

export const FormAirportSelect = forwardRef(
  (props: FormAirportSelectProps, ref): React.ReactElement => {
    const styles = useStyleSheet(themedStyles);
    const { t } = useTranslation();

    const {
      airportLocationList,
      filterDataCustom,
      handleChangeText,
      handleClosePress,
      handleOpenPress,
      handleReset,
      handleSelect,
      isVisible,
      loading,
      opened,
      text,
    } = useHook(props, ref);

    const fieldTitleKey = props.fieldTitleKey || 'formattedAddress';
    const fieldTitleKeyModal = props.fieldTitleKeyModal || 'formattedAddress';
    const fieldValueKey = props.fieldValueKey || 'id';

    const renderAccessoryRight = () => {
      if (props.loading) {
        return (
          <View style={styles.additionalAccessoryRight}>
            <ActivityIndicator />
          </View>
        );
      }
      if (text.length) {
        return (
          <TouchableOpacity onPress={handleReset}>
            <Icon
              name="close-circle"
              fill={(styles.icon as any).color}
              style={[globalStyle.size24, globalStyle.opacity5]}
            />
          </TouchableOpacity>
        );
      }
      return (
        <Icon
          name="arrow-ios-downward-outline"
          fill={(styles.icon as any).color}
          style={[globalStyle.size20, globalStyle.opacity5]}
        />
      );
    };

    const renderAdditionalAccessoryRight = () => {
      if (props.loading) {
        return (
          <View style={styles.additionalAccessoryRight}>
            <ActivityIndicator />
          </View>
        );
      }
      if (props.value && props.value[fieldValueKey]) {
        return (
          <TouchableOpacity style={styles.additionalAccessoryRight} onPress={handleReset}>
            <Icon
              name="close-circle"
              fill={(styles.icon as any).color}
              style={[globalStyle.size24, globalStyle.opacity5]}
            />
          </TouchableOpacity>
        );
      }
      return <></>;
    };

    return (
      <>
        <View style={[styles.container, props.containerStyle]}>
          <View style={styles.labelContainer}>
            {!!props.label && (
              <Text selectable={true} style={styles.label}>
                {props.label as string}
                {props.isMandatory ? (
                  <Text selectable={true} style={styles.mandatoryStar}>
                    *
                  </Text>
                ) : (
                  <></>
                )}
              </Text>
            )}
          </View>
          <View>
            <View style={[styles.textInput, !!props.errorMessage && styles.textInputError]}>
              {(props.value as any)?.[fieldTitleKey] ? (
                <Text style={[globalStyle.flex1, styles.textInputText]}>
                  {(props.value as any)?.[fieldTitleKey]}
                </Text>
              ) : (
                <Text style={[globalStyle.flex1, styles.textInputTextPlaceholder]}>
                  {props.placeholder}
                </Text>
              )}
              {!props.hideIcon && renderAccessoryRight()}
            </View>
            <TouchableOpacity
              style={styles.button}
              onPress={!props.disabled ? handleOpenPress : undefined}
            />
            {props.hideIcon && renderAdditionalAccessoryRight()}
          </View>
          {!!props.caption && (
            <View style={styles.captionContainer}>
              <Text selectable={true} style={styles.caption}>
                {props.caption as string}
              </Text>
            </View>
          )}
          {!!props.errorMessage && (
            <View style={styles.captionContainer}>
              <Text selectable={true} style={styles.errorMessageText}>
                {props.errorMessage}
              </Text>
            </View>
          )}
        </View>

        {opened && (
          <FormSelectModal
            items={airportLocationList}
            fieldTitleKey={fieldTitleKeyModal || fieldTitleKey}
            fieldValueKey={fieldValueKey}
            handleClose={handleClosePress}
            title={props.label as string}
            onChangeText={handleChangeText}
            onSelect={handleSelect as any}
            filterDataCustom={filterDataCustom}
            value={props.value?.id as string}
            isVisible={isVisible}
            placeholder={
              t('common.enterSearchTextToFindAirport', {
                defaultValue: 'Enter search text to find airport',
              }) as string
            }
            placeholderEmpty={
              text.length && !loading && !airportLocationList.length
                ? (t('common.noMatchingDataFound', {
                    defaultValue: 'No matching data found',
                  }) as string)
                : ''
            }
            loading={loading}
            searchable
            text={text}
          />
        )}
      </>
    );
  },
);
