import React, { forwardRef, useImperativeHandle, useEffect, useMemo, useState } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

import {
  Autocomplete,
  AutocompleteProps,
  IndexPath,
  SelectItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { TFunctionDetailedResult } from 'i18next';

import { FormSelectModal } from './FormSelectModal';
import { globalStyle } from '../../../common/style';

export const FormAutocomplete = forwardRef(
  (
    props: AutocompleteProps & {
      containerStyle?: StyleProp<ViewStyle>;
      fieldTitleKey: string;
      fieldValueKey: string;
      label: TFunctionDetailedResult | string;
      items: any;
      caption?: TFunctionDetailedResult | string;
      errorMessage?: string;
      isMandatory?: boolean;
      placeholderDefault?: string;
      style?: StyleProp<ViewStyle>;
      selectItemKeyPrefix?: string;
      onChangeText?: (query: any) => void;
      onSelect: (value: any, option: any, index: IndexPath | null) => void;
      query?: string;
    },
    ref,
  ): React.ReactElement => {
    const styles = useStyleSheet(themedStyles);

    const [initialized, setInitialized] = useState(false);
    const [text, setText] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [opened, setOpened] = useState(false);

    useEffect(() => {
      if (props.value && !initialized) {
        const item = props.items.find((i: any) => i[props.fieldValueKey] === props.value);
        if (item) {
          setText(item[props.fieldTitleKey]);
          setInitialized(true);
        }
      }
    }, [initialized, props.fieldTitleKey, props.fieldValueKey, props.items, props.value]);

    const items = useMemo(() => {
      if (text.length) {
        return props.items.filter((item: any) =>
          item[props.fieldTitleKey].toLowerCase().includes(text.toLowerCase()),
        );
      }
      return props.items;
    }, [props.fieldTitleKey, props.items, text]);

    const handleChangeText = (val: string) => {
      setText(val);
      if (!initialized) {
        setInitialized(true);
      }
    };

    const handleSelect = (index: any) => {
      const _index = new IndexPath(index) as IndexPath;
      if (items?.[_index.row]) {
        const option = items[_index.row];
        setText(option[props.fieldTitleKey]);
        if (props.onSelect) {
          props.onSelect(option[props.fieldValueKey], option, index);
        }
        if (props.onChangeText) {
          props.onChangeText(option[props.fieldTitleKey]);
        }
      }
    };

    // const handleReset = () => {
    //   setText('');
    //   if (props.onSelect) {
    //     props.onSelect(null, null, null);
    //   }
    //   if (props.onChangeText) {
    //     props.onChangeText('');
    //   }
    // };

    const handleOpenPress = () => {
      if (props.disabled) {
        return;
      }

      setOpened(true);
      setTimeout(() => {
        setIsVisible(true);
      }, 50);
    };

    const handleClosePress = () => {
      setIsVisible(false);
      setTimeout(() => {
        setOpened(false);
      }, 200);
    };

    useImperativeHandle(ref, () => ({
      setTextValue: handleChangeText,
    }));

    const renderOption = (object: any) => (
      <SelectItem
        style={[globalStyle.width100Percent]}
        title={object[props.fieldTitleKey]}
        selected={props.value === object[props.fieldValueKey]}
        key={object[props.fieldTitleKey] + object[props.fieldValueKey]}
      />
    );

    const renderChildren = (): JSX.Element => {
      if (props.children) {
        return props.children as JSX.Element;
      }
      if (items?.length) {
        return <View style={globalStyle.width100Percent}>{items.map(renderOption)}</View>;
      }
      return <></>;
    };

    return (
      <View>
        <View style={[styles.container, props.containerStyle]}>
          <Autocomplete
            {...props}
            caption={undefined} // disable default caption.
            appearance="default"
            onChangeText={handleChangeText}
            value={text}
            multiline={true}
            onSelect={handleSelect}
            style={globalStyle.width100Percent}
            textStyle={styles.textStyle}
            label={
              <View style={styles.labelContainer}>
                <Text selectable={true} style={styles.label}>
                  {props.label as string}
                  {props.isMandatory ? (
                    <Text selectable={true} style={styles.mandatoryStar}>
                      *
                    </Text>
                  ) : (
                    <></>
                  )}
                </Text>
              </View>
            }
          >
            {renderChildren()}
          </Autocomplete>
          {!!props.caption && (
            <View style={styles.captionContainer}>
              <Text selectable={true} style={styles.caption}>
                {props.caption as string}
              </Text>
            </View>
          )}
          {!!props.errorMessage && (
            <View style={styles.captionContainer}>
              <Text selectable={true} style={styles.errorMessageText}>
                {props.errorMessage}
              </Text>
            </View>
          )}
        </View>

        <TouchableOpacity style={styles.button} onPress={handleOpenPress} />

        {opened && (
          <FormSelectModal
            items={props.items}
            fieldTitleKey={props.fieldTitleKey}
            fieldValueKey={props.fieldValueKey}
            handleClose={handleClosePress}
            title={props.label as string}
            onChangeText={handleChangeText}
            onSelect={props.onSelect as any}
            value={props.value as string}
            isVisible={isVisible}
            searchable
            text={text}
          />
        )}
      </View>
    );
  },
);

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
  },
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  errorMessageText: {
    color: 'color-danger-500',
    paddingBottom: 5,
  },
  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  checkBox: {
    marginLeft: 10,
  },
  icon: {
    color: 'text-basic-color',
  },
  button: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  textStyle: {
    lineHeight: 14,
    paddingTop: 7,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
