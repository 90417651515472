import React from 'react';
import { Platform } from 'react-native';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { CourierHomeNavigatorParamList } from './app.navigator';
import { CommonProfileNavigator } from './common.profile.navigator';
import i18n from '../i18n/i18n';
import { CommonActivityScreen } from '../screens/common/common.activity.screen';
import { CommonAgencyJobChatScreen } from '../screens/common/CommonAgencyJobChatScreen';
import { CommonJobAgencyJobChatScreen } from '../screens/common/CommonCourierJobAgencyJobChatScreen';
import CommonNotificationPermissionsScreen from '../screens/common/CommonNotificationPermissionsScreen';
import { CourierCourierJobScreen } from '../screens/courier/courier.courier-job.screen';
import { CourierCourierJobsScreen } from '../screens/courier/courier.courier-jobs.screen';
import { CourierHomeScreen } from '../screens/courier/courier.home.screen';

const StackNavigator = createStackNavigator<CourierHomeNavigatorParamList>();

const CourierHomeNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="CourierHomeScreen"
    >
      <StackNavigator.Screen name="CourierHomeScreen" component={CourierHomeScreen} />
      <StackNavigator.Screen name="CourierCourierJobsScreen" component={CourierCourierJobsScreen} />
      <StackNavigator.Screen name="CourierCourierJobScreen" component={CourierCourierJobScreen} />
      <StackNavigator.Screen
        name="CommonNotificationPermissionsScreen"
        component={CommonNotificationPermissionsScreen}
        initialParams={{ hideSettings: true }}
      />
      <StackNavigator.Screen
        name="CourierCourierJobAgencyJobChatScreen"
        component={CommonJobAgencyJobChatScreen}
      />
      <StackNavigator.Screen
        name="CourierAgencyJobChatScreen"
        component={CommonAgencyJobChatScreen}
      />

      <StackNavigator.Group
        screenOptions={() => ({
          cardShadowEnabled: true,
          headerShown: false,
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
          animationEnabled: true,
        })}
      >
        <StackNavigator.Screen
          name="CommonActivityScreen"
          component={CommonActivityScreen}
          options={{
            ...(Platform.OS === 'web'
              ? TransitionPresets.ScaleFromCenterAndroid
              : TransitionPresets.ModalSlideFromBottomIOS),
            title: i18n.t('activityNotification.activityAndNotifications', {
              defaultValue: 'Activity & Notifications',
            }) as string,
          }}
        />
      </StackNavigator.Group>
      <StackNavigator.Screen name="CommonProfileNavigator" component={CommonProfileNavigator} />
    </StackNavigator.Navigator>
  );
};

export { CourierHomeNavigator };
