import React from 'react';
import { View } from 'react-native';

import { CheckBox, useStyleSheet } from '@ui-kitten/components';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { FormDatepicker } from '../../../../components/common/form/form-datepicker.component';
import { FormTextInputTopLabel } from '../../../../components/common/form/form-text-input-top-label.component';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';

export const OBCExperienceEdit = ({
  errors,
  fieldProps,
  handleObcExperiencePress,
  setFieldValue,
  values,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.obcExperience', {
        defaultValue: 'OBC Experience',
      }).toUpperCase()}
      style={styles.contentBox}
      Content={
        <>
          <View style={styles.obcCheckbox}>
            <CheckBox
              onChange={handleObcExperiencePress}
              checked={values.hasObcExperience}
              status={errors.hasObcExperience ? 'danger' : 'basic'}
            >
              {
                t('courierUserProfile.obcExperience', {
                  defaultValue: 'OBC Experience',
                }) as string
              }
            </CheckBox>
          </View>

          <FormDatepicker
            label={
              t('courierUserProfile.firstObcJobDateAt', {
                defaultValue: 'First OBC Job Date',
              }) as string
            }
            min={new Date(dayjs().subtract(80, 'years').format())}
            max={new Date()}
            onSelect={(date) => {
              setFieldValue('firstObcJobDateAt', DateTime.fromJSDate(date));
            }}
            date={values.firstObcJobDateAt ? new Date(values.firstObcJobDateAt) : undefined}
            errorMessage={errors.firstObcJobDateAt}
            disabled={!values.hasObcExperience}
          />

          <FormTextInputTopLabel
            label={t('courierUserProfile.obcJobsDone', {
              defaultValue: 'OBC Jobs done',
            })}
            placeholder={
              t('courierUserProfile.obcJobsDone', {
                defaultValue: 'OBC Jobs done',
              }) as string
            }
            isMandatory
            disabled={!values.hasObcExperience}
            keyboardType="number-pad"
            {...fieldProps('numberOfPastObcJobs', 'number')}
          />
          <View style={styles.obcMedicalCheckbox}>
            <CheckBox
              {...fieldProps('hasMedicalObcExperience', 'checkbox')}
              disabled={!values.hasObcExperience}
              status="primary"
            >
              {
                t('courierUserProfile.medicalObcExperience', {
                  defaultValue: 'Medical/stem cells OBC Experience',
                }) as string
              }
            </CheckBox>
          </View>
        </>
      }
    />
  );
};

const arePropsEqual = (prev: any, next: any) => {
  const keys: any[] = [
    'hasObcExperience',
    'numberOfPastObcJobs',
    'firstObcJobDateAt',
    'hasMedicalObcExperience',
  ];
  if (keys.some((key) => next?.values[key] !== prev?.values[key])) {
    return false;
  }
  if (keys.some((key) => next?.errors[key] !== prev?.errors[key])) {
    return false;
  }
  return true;
};

export default React.memo(OBCExperienceEdit, arePropsEqual);
