import React from 'react';
import { View } from 'react-native';

import { useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { Props } from './types';
import { globalStyle } from '../../../../common/style';
import { FormCheckBox } from '../../../../components/common/form/form-check-box.component';
import { FormTextInputTopLabel } from '../../../../components/common/form/form-text-input-top-label.component';
import { FormSelect } from '../../../../components/common/form/FormSelect';

export const AgencyCourierUserProfileCategoryEditComponent = ({
  handleInternalCommentChange,
  handleMarkedForAttention,
  handleSelectCategory,
  internalComment,
  markedForAttention,
  selectedCategory,
}: Props) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { organizationCourierCategories } = useHook();

  return (
    <View>
      <FormSelect
        appearance="default"
        label={t('common.courierCategory', { defaultValue: 'Courier category' })}
        placeholder={t('common.selectCourierCategory', { defaultValue: 'Select courier category' })}
        onSelect={handleSelectCategory}
        items={organizationCourierCategories}
        value={selectedCategory}
        fieldTitleKey="label"
        fieldValueKey="value"
      />
      <FormTextInputTopLabel
        label={t('common.internalComment', {
          defaultValue: 'Internal comment',
        })}
        textStyle={styles.textarea}
        multiline
        maxLength={500}
        onChangeText={handleInternalCommentChange}
        value={internalComment}
        verticalAlign="top"
      />

      {!!internalComment && (
        <FormCheckBox
          style={[globalStyle.flexGrow1, globalStyle.marginRight20, globalStyle.marginBottom10]}
          checked={markedForAttention}
          label={t('common.markCourierForAttention', {
            defaultValue: 'Mark courier for attention with important note',
          })}
          onChange={handleMarkedForAttention}
        />
      )}
    </View>
  );
};
