import React, { useEffect, useState } from 'react';
import {
  Image as ImageNative,
  Dimensions,
  Modal,
  View,
  ImageStyle,
  TouchableOpacity,
} from 'react-native';

import { Icon, useStyleSheet } from '@ui-kitten/components';
import { Image } from 'expo-image';
import ImageZoom from 'react-native-image-pan-zoom';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { themedStyles } from './styles';
import { ImageLightBoxProps } from './types';

const { height: cropHeight, width: cropWidth } = Dimensions.get('window');

const ImageLightBox: React.FC<ImageLightBoxProps> = ({
  customImage,
  imageStyle,
  uri,
  uriCompressed,
  height = 40,
  width = 40,
  expandable = true,
  resizeMode = 'contain',
  zoomEnabled = true,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { top } = useSafeAreaInsets();

  const [imageHeight, setImageHeight] = useState(0);
  const [showPhoto, setShowPhoto] = useState(false);

  const maxImageWidth = cropHeight < cropWidth ? cropHeight : cropWidth;

  useEffect(() => {
    if (!imageHeight && uri) {
      ImageNative.getSize(uri, (_width: number, _height: number) => {
        let maxHeight = (maxImageWidth / _width) * _height;
        if (maxHeight > _height) {
          maxHeight = _height;
        }
        setImageHeight(maxHeight);
      });
    }
  }, [imageHeight, maxImageWidth, uri]);

  const togglePhoto = (value: boolean) => (): void => {
    setShowPhoto(value);
  };

  const renderDefault = ({
    imageCustomStyle,
    expanded = false,
    type = 'lightbox',
  }: {
    imageCustomStyle?: ImageStyle;
    expanded?: boolean;
    type?: 'thumbnail' | 'lightbox';
  }): JSX.Element | null => {
    if (customImage && !expanded) {
      return customImage;
    }
    if (uri) {
      let imageUri = uri;
      if (type === 'thumbnail' && uriCompressed) {
        imageUri = uriCompressed;
      }
      return (
        <Image
          style={[styles.imageModal, !expanded && { width, height }, imageStyle, imageCustomStyle]}
          source={imageUri}
          contentFit={expanded ? 'contain' : resizeMode}
        />
      );
    }
    return null;
  };

  const renderZoomImage = ({ type }: { type: 'thumbnail' | 'lightbox' }) => (
    <ImageZoom
      cropWidth={cropWidth}
      cropHeight={cropHeight}
      imageWidth={maxImageWidth}
      imageHeight={imageHeight}
      enableSwipeDown
      useNativeDriver
      onSwipeDown={togglePhoto(false)}
    >
      {renderDefault({
        imageCustomStyle: { width: maxImageWidth, height: imageHeight },
        expanded: true,
        type,
      })}
    </ImageZoom>
  );

  const renderExpandable = (): JSX.Element => (
    <TouchableOpacity activeOpacity={0.9} onPress={togglePhoto(!showPhoto)} style={imageStyle}>
      {renderDefault({ type: 'thumbnail' })}

      {showPhoto && (
        <Modal animationType="fade" visible hardwareAccelerated>
          <View style={styles.imageModalContainer}>
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={togglePhoto(false)}
              style={styles.background}
            />
            {zoomEnabled
              ? renderZoomImage({ type: 'lightbox' })
              : renderDefault({
                  imageCustomStyle: { width: cropWidth, height: imageHeight },
                  expanded: true,
                  type: 'lightbox',
                })}
            <TouchableOpacity
              onPress={togglePhoto(false)}
              style={[styles.closeImageModal, { top: top || 20 }]}
            >
              <Icon name="close-outline" fill="white" height={30} width={30} />
            </TouchableOpacity>
          </View>
        </Modal>
      )}
    </TouchableOpacity>
  );

  if (expandable && uri) {
    return renderExpandable();
  }
  if (uri || customImage) {
    return renderDefault({ type: 'lightbox' });
  }
  return null;
};

export default ImageLightBox;
