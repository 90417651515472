import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

import { useMutation } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Constants from 'expo-constants';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import JSONTree, { Renderable } from 'react-native-json-tree';

import { LoadingIndicator } from './common/loading-indicator.component';
import { ConfirmModalContext } from './common/modal/ConfirmModal';
import RegisterExistingUserWithOwnedOrganizationButton from './register-existing-user-button.component';
import { MUTATION_SIGN_OUT_USER_CONTEXT } from '../apollo/graphql-mutations';
import { globalStyle as globalStyleOriginal } from '../common/style';
import AppUserContext from '../contexts/AppUserContext';
import { GLOBAL_CONSTANTS } from '../globals';
import { OrganizationType } from '../graphql-types';
import useDimensions from '../hooks/useDimensions';
import {
  removeUserContextFromUserContexts,
  storeCurrentUserContext,
  storeUserContexts,
} from '../modules/authentication/authentication.module';
import { AuthenticationService } from '../services/authentication.service';
import { LocalUserContext, LocalUserContexts } from '../types';
import { getIsBackendReachable } from '../utils/get-is-backend-reachable';

const RemoveAccountButton = ({
  userContext,
  userContexts,
  setUserContexts,
}: {
  navigation: any;
  userContext: LocalUserContext;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const [authSignOutUserContext] = useMutation(MUTATION_SIGN_OUT_USER_CONTEXT);

  const _handleOnPress = async () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.confirm', { defaultValue: 'Confirm' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('common.removeUserFromAccountList', {
        defaultValue: 'Remove user from account list',
      }),
      text: t(
        'Remove all accounts for user with email {{email}} from account list. If this user is currently active, you will be logged out.',
        { email: userContext.user?.email },
      ),
      onConfirmPress: async () => {
        if (userContext.deviceId && userContext.user?.id) {
          try {
            authSignOutUserContext({
              variables: { data: { deviceId: userContext.deviceId, userId: userContext.user?.id } },
            });
            // eslint-disable-next-line no-empty
          } catch {}
        }
        await removeUserContextFromUserContexts({
          userContext,
          userContexts,
          setUserContexts,
          appUserContext,
        });
        return true;
      },
      visible: true,
    });
  };

  return (
    <Button
      size="small"
      onPress={_handleOnPress}
      appearance="outline"
      status="danger"
      style={{
        width: 40,
        height: 36,
        marginLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon name="close-outline" height={20} width={20} fill="red" />
    </Button>
  );
};

const UseAccountButton = ({
  userContext,
  userContexts,
  setUserContexts,
}: {
  navigation: any;
  userContext: LocalUserContext;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const styles = useStyleSheet(accountButtonStyle);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(false);
    return () => {
      setIsLoading(false);
    };
  }, [appUserContext.currentUserContext]);

  const switchUserContextIfBackendIsReachable = async (
    _userContext: LocalUserContext,
    removeAdditionalStorageData = true,
  ) => {
    setIsLoading(true);
    const isBackendReachable = await getIsBackendReachable();
    if (!isBackendReachable) {
      setIsLoading(false);
      await appUserContext.setCurrentUserContext(_userContext);
      return;
    }

    const authenticationService = new AuthenticationService();

    const { localUserContexts, signedInLocalUserContext } =
      await authenticationService.signInLocalUserContextAsync({
        localUserContextToSignIn: _userContext,
        localUserContexts: userContexts,
      });

    if (signedInLocalUserContext) {
      const dataArray = [
        storeCurrentUserContext(signedInLocalUserContext),
        storeUserContexts(localUserContexts),
        appUserContext.setCurrentUserContext(signedInLocalUserContext),
        setUserContexts(localUserContexts),
      ];
      if (removeAdditionalStorageData) {
        dataArray.push(
          AsyncStorage.multiRemove([
            GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER,
            GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
          ]),
        );
      }
      await Promise.all(dataArray);

      // RESET STACK

      setIsLoading(false);
    } else {
      showMessage({
        message: 'Login failed',
        description: t('common.flashMessageProblemSignInUserContext', {
          userEmail: _userContext?.user?.email,
          defaultValue: 'We could not login your user account with email {{userEmail}}',
        }) as string,
        renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
        type: 'danger',
      });
      await Promise.all([
        removeUserContextFromUserContexts({
          userContext: _userContext,
          userContexts: localUserContexts,
          setUserContexts,
          appUserContext,
        }),
      ]);
    }
  };

  if (!userContext) {
    return <Text selectable={true}>Loading...</Text>;
  }
  if (appUserContext.currentUserContext?.userContextId === userContext.userContextId) {
    return (
      <Button
        appearance="outline"
        onPress={() => {
          if (!isLoading) {
            switchUserContextIfBackendIsReachable(userContext, false);
          }
        }}
        size="small"
        status="success"
        style={styles.btn}
      >
        {isLoading ? (
          <LoadingIndicator status="success" />
        ) : (
          (t('common.activeAccount', { defaultValue: 'Active Account' }) as string)
        )}
      </Button>
    );
  }
  if (appUserContext.currentUserContext?.userContextId !== userContext.userContextId) {
    return (
      <Button
        appearance="filled"
        onPress={() => {
          if (!isLoading) {
            switchUserContextIfBackendIsReachable(userContext, true);
          }
        }}
        size="small"
        status="primary"
        style={styles.btn}
      >
        {isLoading ? (
          <LoadingIndicator status="control" />
        ) : (
          (t('common.useThisAccount', { defaultValue: 'Use this Account' }) as string)
        )}
      </Button>
    );
  }
  return <Button status="outline">Your Login expired. Pleas sign in again.</Button>;
};

const UserContextItem = ({
  navigation,
  userContext,
  userContexts,
  setUserContexts,
  showAllUserContextJSON = false,
}: {
  navigation: any;
  userContext: LocalUserContext;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  showAllUserContextJSON: boolean;
}) => {
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleOriginal);
  const { isVerySmallDevice } = useDimensions();

  const [showUserContextJSON, setShowUserContextJSON] = useState<boolean>(showAllUserContextJSON);

  useEffect(() => {
    setShowUserContextJSON(showAllUserContextJSON);
  }, [showAllUserContextJSON]);

  const roleParts = userContext.role?.split('/');
  const role =
    userContext?.organizationType !== OrganizationType.COURIER &&
    roleParts &&
    roleParts.length > 0 &&
    roleParts[roleParts.length - 1] !== ''
      ? ` (${roleParts[roleParts.length - 1]})`
      : '';

  const UserContextPreview = ({ userContext: _userContext }: { userContext: LocalUserContext }) => (
    <View
      style={[
        globalStyle.borderBasicColor1,
        globalStyle.borderWidth1,
        globalStyle.padding10,
        globalStyle.marginBottom10,
      ]}
    >
      <Text style={globalStyle.colorBasic500}>
        !!! This is only showing with EXPO_APP_ENV == 'development'
      </Text>
      <JSONTree data={_userContext as Renderable} />
    </View>
  );

  if (userContext.user && !userContext.refreshTokenExpired) {
    return (
      <>
        <View style={styles.itemSeparator} />

        <View style={styles.userAccountWrapper}>
          <View style={styles.userAccountInfoLayer}>
            {Constants.expoConfig?.extra?.expoAppEnv === 'development' && (
              <View style={globalStyle.flex1}>
                <Button
                  size="tiny"
                  appearance="ghost"
                  onPress={() => setShowUserContextJSON(!showUserContextJSON)}
                >
                  {showUserContextJSON ? 'Hide UserContext' : 'Show UserContext'}
                </Button>
                <View
                  style={[
                    globalStyle.flex1,
                    isVerySmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                    globalStyle.justifyContentBetween,
                  ]}
                >
                  <View
                    style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsCenter]}
                  >
                    <Icon
                      name="alert-circle-outline"
                      fill="#ccc"
                      style={[globalStyle.size20, globalStyle.marginRight5]}
                    />
                    <Text style={globalStyle.fontSize13}>
                      {userContext.lastSyncedAt?.toLocaleString(
                        DateTime.DATETIME_FULL_WITH_SECONDS,
                      ) || 'not synced'}
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.organizationType,
                      isVerySmallDevice ? globalStyle.textAlignLeft : globalStyle.textAlignRight,
                    ]}
                  >
                    {userContext.organizationType as string}
                  </Text>
                </View>
              </View>
            )}
            {Constants.expoConfig?.extra?.expoAppEnv === 'development' &&
              showUserContextJSON === true && <UserContextPreview userContext={userContext} />}

            <Text>{userContext.organization?.name + role}</Text>
            <View style={globalStyle.flexRow}>
              <Text style={[globalStyle.fontLatoBold, globalStyle.paddingRight5]}>Email:</Text>
              <Text selectable={true} style={styles.userContextName}>
                {userContext.user.email}
              </Text>
            </View>
          </View>
          <View style={styles.accountButtonWrapper}>
            <UseAccountButton
              userContext={userContext}
              userContexts={userContexts}
              setUserContexts={setUserContexts}
              navigation={navigation}
            />
            <RemoveAccountButton
              userContext={userContext}
              userContexts={userContexts}
              setUserContexts={setUserContexts}
              navigation={navigation}
            />
          </View>
        </View>
      </>
    );
  }
  if (userContext.user && userContext.refreshTokenExpired) {
    return (
      <View style={styles.userAccountWrapper}>
        <View style={styles.userAccountInfoLayer}>
          <Text selectable={true} style={styles.organizationType}>
            {userContext.organizationType as string}
          </Text>
          <Text selectable={true} style={styles.userContextName}>
            {userContext.organization?.name + role + '\n'}
            User {userContext.user.email}
          </Text>
        </View>
        <Text selectable={true} status="danger" style={globalStyle.padding10}>
          Your login has expired. Please sign in again.
        </Text>
        <RemoveAccountButton
          userContext={userContext}
          userContexts={userContexts}
          setUserContexts={setUserContexts}
          navigation={navigation}
        />
      </View>
    );
  }

  return (
    <View style={styles.userAccountWrapper}>
      <Text selectable={true}>
        No user registered for {userContext?.sub || '{userContext.sub is undefined}'}
        <RegisterExistingUserWithOwnedOrganizationButton
          organizationType={OrganizationType.COURIER}
          userContext={userContext}
          userContexts={userContexts}
          setUserContexts={setUserContexts}
        />
        <RegisterExistingUserWithOwnedOrganizationButton
          organizationType={OrganizationType.AGENCY}
          userContext={userContext}
          userContexts={userContexts}
          setUserContexts={setUserContexts}
        />
      </Text>
    </View>
  );
};

const themedStyles = StyleService.create({
  userAccountWrapper: {
    padding: 0,
    paddingBottom: 10,
    borderBottom: 1,
    borderColor: 'border-basic-color-3',
  },
  userAccountInfoLayer: {
    paddingBottom: 15,
  },
  userContextName: {
    paddingBottom: 10,
    padding: 0,
    margin: 0,
  },
  organizationType: {
    fontFamily: 'Lato_900Black',
    textTransform: 'uppercase',
    fontSize: 20,
    color: 'color-primary-400',
    right: 0,
    padding: 0,
    marginBottom: 5,
  },
  itemSeparator: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: 'border-basic-color-3',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    marginRight: 0,
  },
  accountButtonWrapper: {
    textAlign: 'left',
    flexDirection: 'row',
  },
});

const accountButtonStyle = StyleService.create({
  btn: {
    height: 36,
    minWidth: 130,
    alignItems: 'center',
  },
});

export default UserContextItem;
