import React from 'react';
import { View } from 'react-native';

import { Text } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';


import { TimeAgoPopover } from './time-ago-popover.component';

export const UpdatedAtText = ({
  updatedAtDate,
  textColor,
}: {
  updatedAtDate: DateTime;
  textColor?: string;
}) => {
  const [t, i18n] = useTranslation();

  const textStyle = textColor ? { color: textColor } : undefined;

  return (
    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'baseline' }}>
      <Text
        selectable={true}
        style={[{ flexDirection: 'row', alignItems: 'baseline' }, textStyle]}
      >
        <Trans
          i18nKey="common.updatedAtText"
          t={t}
          style={{ alignItems: 'baseline' }}
          components={{
            TimeAgoPopover: (
              <TimeAgoPopover
                date={updatedAtDate}
                language={i18n.language}
                textStyle={textStyle}
              />
            ),
          }}
          defaults="Last modified <TimeAgoPopover></TimeAgoPopover>."
        />
      </Text>
    </View>
  );
};
