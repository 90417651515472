import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { View } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from './common/loading-spinner.component';
import ContentBoxTextTitle from './content-box-text-title.component';
import UserContextItem from './user-context-item.component';
import { globalStyle } from '../common/style';
import AppUserContext from '../contexts/AppUserContext';
import { LocalUserContexts } from '../types';

export const AvailableUserContexts = ({
  navigation,
  userContexts,
  setUserContexts,
  isLoadingMessage,
  setIsLoadingMessage,
}: {
  navigation: any;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  isLoadingMessage: string | undefined;
  setIsLoadingMessage: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  useEffect(() => {
    return () => {
      setIsLoadingMessage(undefined);
    };
  }, []);

  if (isLoadingMessage) {
    return (
      <ContentBoxTextTitle
        style={styles.content}
        title="Your Accounts"
        Content={
          <View style={globalStyle.marginBottom20}>
            <LoadingSpinner text={isLoadingMessage} />
          </View>
        }
      />
    );
  }

  if (!userContexts || (userContexts && Object.keys(userContexts).length < 1)) {
    return (
      <ContentBoxTextTitle
        style={styles.content}
        title="Your Accounts"
        Content={
          <View>
            <Text selectable={true}>
              Sign up or sign in so that your accounts can be listed here.
            </Text>
          </View>
        }
      />
    );
  }
  return (
    <>
      {appUserContext.currentUserContext?.user && (
        <ContentBoxTextTitle
          style={styles.content}
          title={t('common.currentlyLoggedInAs', {
            defaultValue: 'Currently logged in as',
          })}
          Content={
            <View>
              <Text style={globalStyle.fontLatoBold}>
                {appUserContext.currentUserContext?.user?.email as string}
              </Text>
              <Text>
                {appUserContext.currentUserContext?.user?.firstNames as string}{' '}
                {appUserContext.currentUserContext?.user?.lastName as string}
              </Text>
              <Text>
                {appUserContext.currentUserContext?.organization?.name as string} (
                {appUserContext.currentUserContext?.organizationType as string})
              </Text>
            </View>
          }
        />
      )}
      <ContentBoxTextTitle
        style={styles.content}
        title={t('common.yourAccounts', { defaultValue: 'Your Accounts' }) as string}
        Content={
          <View>
            {Object.entries(userContexts).map((userContext) => (
              <UserContextItem
                key={userContext[0]}
                navigation={navigation}
                userContext={userContext[1]}
                userContexts={userContexts}
                setUserContexts={setUserContexts}
                showAllUserContextJSON={false}
              />
            ))}
          </View>
        }
      />
    </>
  );
};

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },
});
