import React from 'react';
import { View, Pressable } from 'react-native';

import { Text, useStyleSheet, Icon } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../../common/style';
import { ThemedGooglePlacesAutocomplete } from '../../../../../components/common/google-places-autocomplete.component';
import { AvailabilityFilterModal } from '../AvailabilityFilterModal';

export const AvailabilityMapSearchBoxAndFilter = ({
  onLocationSelect,
  onFilterSelect,
  selectedFilterType,
}: any) => {
  const { i18n, t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    handleChangeText,
    handleFilterConfirm,
    handleFilterPress,
    handlePress,
    isFilterModalVisible,
    text,
  } = useHook({
    onFilterSelect,
    onLocationSelect,
  });

  return (
    <>
      <View style={styles.container}>
        <ThemedGooglePlacesAutocomplete
          caption=""
          onPress={handlePress}
          language={(i18n.language || 'en') as any}
          placeholderText={t('common.searchLocation', { defaultValue: 'Search location' })}
          inputContainerStyle={styles.textInput}
          onChangeText={handleChangeText}
          textInputProps={{
            value: text,
          }}
        />
        <Pressable
          onPress={handleFilterPress}
          style={[
            styles.filterContainer,
            !!selectedFilterType.length && styles.filterContainerSelected,
          ]}
        >
          <Icon
            name="options-2-outline"
            fill={(styles.filterIcon as any).color}
            style={globalStyle.size20}
          />
          <Text style={styles.filterText}>{t('common.filter', { defaultValue: 'Filter' })}</Text>
          {!!selectedFilterType.length && (
            <View style={styles.filterCount}>
              <Text style={styles.filterCountText}>{selectedFilterType.length}</Text>
            </View>
          )}
        </Pressable>
      </View>

      <AvailabilityFilterModal
        isVisible={isFilterModalVisible}
        onClose={handleFilterPress}
        onFilterConfirm={handleFilterConfirm}
        selectedFilterType={selectedFilterType}
      />
    </>
  );
};
