import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { View } from 'react-native';

import { Button, Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as AuthSession from 'expo-auth-session';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { LoadingIndicator } from './common/loading-indicator.component';
import { LoadingSpinner } from './common/loading-spinner.component';
import { ConfirmModalContext } from './common/modal/ConfirmModal';
import ContentBoxTextTitle from './content-box-text-title.component';
import { globalStyle } from '../common/style';
import { OrganizationType } from '../generated-graphql-types';
import { authRequestConfig, discoveryDocument } from '../globals';
import { useIsInternetAndBackendReachable } from '../hooks/useIsInternetAndBackendReachable';
import { storeUserContexts } from '../modules/authentication/authentication.module';
import { AuthenticationService } from '../services/authentication.service';
import { LocalUserContexts } from '../types';

// WebBrowser.maybeCompleteAuthSession(); // needs to be called so the browser popup closes

const SignInAgencyCourierButtons = ({
  authRequest,
  authSessionResult,
  hideConfirmModal,
  localUserContexts,
  setLocalUserContexts,
}: {
  authRequest: AuthSession.AuthRequest;
  authSessionResult: AuthSession.AuthSessionResult;
  hideConfirmModal: () => void;
  localUserContexts: LocalUserContexts;
  setLocalUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<OrganizationType | undefined>();

  const handleSignIn = (organizationType: OrganizationType) => async () => {
    setLoading(organizationType);

    const authenticationService = new AuthenticationService();
    const signupData = await authenticationService.signupLocalUser({
      authRequest,
      authSessionResult,
      localUserContexts,
      organizationType,
      isSignUp: false,
    });
    if (signupData.localUserContexts) {
      await storeUserContexts(signupData.localUserContexts);
      setLocalUserContexts(signupData.localUserContexts);
      setLoading(undefined);
      hideConfirmModal();
    } else {
      setLoading(undefined);
      hideConfirmModal();
      setTimeout(() => {
        showMessage({
          message: t('signIn.signInFailed.title', { defaultValue: 'Sign in failed' }),
          description: t('authentication.signIn.signInFailed.description', {
            defaultValue:
              'Something went wrong with signup. Please check your internet connection, if there are still some issues you can contact support@priojet.com.',
          }) as string,
          renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
          type: 'warning',
        });
      }, 300);
    }
  };

  return (
    <>
      <Button
        appearance="filled"
        status=""
        size="medium"
        onPress={handleSignIn(OrganizationType.AGENCY)}
        style={[globalStyle.marginBottom10, themedButtonStyles.btn]}
      >
        {loading === OrganizationType.AGENCY ? (
          <LoadingIndicator />
        ) : (
          (t('authentication.iAmAnAgency', { defaultValue: 'I am an Agency' }) as string)
        )}
      </Button>
      <Button
        appearance="filled"
        status=""
        size="medium"
        onPress={handleSignIn(OrganizationType.COURIER)}
        style={themedButtonStyles.btn}
      >
        {loading === OrganizationType.COURIER ? (
          <LoadingIndicator />
        ) : (
          (t('authentication.iAmACourier', { defaultValue: 'I am a Courier' }) as string)
        )}
      </Button>
    </>
  );
};

export const SigninBox = ({
  navigation,
  userContexts: localUserContexts,
  setUserContexts: setLocalUserContexts,
  isLoadingMessage,
  setIsLoadingMessage,
}: {
  navigation: any;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  isLoadingMessage: string | undefined;
  setIsLoadingMessage: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const { hideConfirmModal, showConfirmModal } = useContext(ConfirmModalContext);
  const { t } = useTranslation();
  const isInternetAndBackendReachable = useIsInternetAndBackendReachable();
  const styles = useStyleSheet(themedStyles);

  // Using the isLoadingMessage leads to _setAuthSessionResult being null. Hence, setAuthSessionResult is manually set after promptAsync
  const [authRequest, _setAuthSessionResult, promptAsync] = AuthSession.useAuthRequest(
    authRequestConfig,
    discoveryDocument,
  );

  const handleSignupUnknownOrganizationType = ({
    authRequest: _authRequest,
    authSessionResult: _authSessionResult,
  }: {
    authRequest: AuthSession.AuthRequest;
    authSessionResult: AuthSession.AuthSessionResult;
  }) => {
    showConfirmModal({
      title: t('authentication.almostThere', {
        defaultValue: 'Almost there!',
      }),
      text: t('authentication.signIn', {
        defaultValue:
          'Sorry, before signing you in completely for the first time, we just need to confirm one thing again.\nAre you an Agency or a Courier?',
      }),
      renderCustomButtons: () => (
        <SignInAgencyCourierButtons
          authRequest={_authRequest}
          authSessionResult={_authSessionResult}
          hideConfirmModal={hideConfirmModal}
          localUserContexts={localUserContexts}
          setLocalUserContexts={setLocalUserContexts}
        />
      ),
      visible: true,
    });
  };

  const handleAuthRequestResponse = async (
    _authRequest: AuthSession.AuthRequest,
    _authSessionResult: AuthSession.AuthSessionResult,
  ) => {
    if (!isInternetAndBackendReachable) {
      showMessage({
        message: 'Sign in failed',
        description: t('common.flashMessageProblemDuringSignIn', {
          defaultValue:
            'Our servers are currently not reachable. Please check your internet connection. Cannot sign in currently.',
        }) as string,
        renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
        type: 'warning',
      });
    }

    const authenticationService = new AuthenticationService();
    const { error, localUserContexts: _localUserContexts } =
      await authenticationService.getUpdatedLocalUserContextsFromAuthRequestAndAuthSessionResultAsync(
        {
          authRequest: _authRequest,
          authSessionResult: _authSessionResult,
          localUserContexts,
          isSignUp: false,
        },
      );

    if (error === 'notExistingUser') {
      handleSignupUnknownOrganizationType({
        authRequest: _authRequest,
        authSessionResult: _authSessionResult,
      });
    } else if (_localUserContexts) {
      await storeUserContexts(_localUserContexts);
      setLocalUserContexts(_localUserContexts);
    }
  };

  const handlePressLoginAsync = async () => {
    if (promptAsync) {
      try {
        const authSessionResult = await promptAsync();
        if (authSessionResult) {
          switch (authSessionResult.type) {
            case 'error':
              if (authSessionResult.error?.description?.includes('AADB2C90118')) {
                navigation.navigate('ResetPassword');
              }
              break;
            case 'cancel':
              setIsLoadingMessage(undefined);
              break;
            case 'dismiss':
              setIsLoadingMessage(undefined);
              break;
            default:
              setIsLoadingMessage('Verifying your account...');
              if (authRequest) {
                handleAuthRequestResponse(authRequest, authSessionResult);
              }
          }
        }
      } catch (err) {
        console.warn('error upon signin in', err);
      }
    }
  };

  if (isLoadingMessage) {
    return <LoadingSpinner text={isLoadingMessage} />;
  }

  return (
    <ContentBoxTextTitle
      style={styles.content}
      title={t('common.signIn', { defaultValue: 'Sign in' })}
      Content={
        <View>
          <Text style={styles.descriptionText}>
            {
              t('common.signInInfoText', {
                defaultValue:
                  'A window will open where you have to enter your credentials to sign in.',
              }) as string
            }
          </Text>
          <Button
            appearance="filled"
            onPress={handlePressLoginAsync}
            style={styles.maxWidth500}
            disabled={!isInternetAndBackendReachable}
          >
            <Text>
              {t('common.signIn', { defaultValue: 'Sign in' }) as string}
              {!isInternetAndBackendReachable ? ' (Connecting...)' : ''}
            </Text>
          </Button>
        </View>
      }
    />
  );
};

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },
  descriptionText: {
    marginBottom: 20,
    fontSize: 14,
    width: '80%',
  },
  maxWidth500: {
    maxWidth: 500,
  },
});

const themedButtonStyles = StyleService.create({
  btn: {
    height: 42,
  },
});
