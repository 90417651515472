import React from 'react';
import { Platform } from 'react-native';

import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { AccountActivationIcon, LoginIcon } from '../../../components/icons.component';

export const AgencySubscriptionAccountActivationBottomTabBar = ({
  navigation,
  state,
}: {
  navigation: any;
  state: any;
}) => {
  const { t } = useTranslation();

  return (
    <BottomNavigation
      selectedIndex={state.index}
      onSelect={(index) => navigation.navigate(state.routeNames[index])}
    >
      <BottomNavigationTab
        title={
          Platform.OS === 'web'
            ? t('common.selectSubscription', {
                defaultValue: 'Select subscription',
              }).toUpperCase()
            : t('common.activation', {
                defaultValue: 'Activation',
              }).toUpperCase()
        }
        icon={AccountActivationIcon}
      />
      <BottomNavigationTab
        title={t('common.login', { defaultValue: 'Login' }).toUpperCase()}
        icon={LoginIcon}
      />
    </BottomNavigation>
  );
};
