import { showMessage } from 'react-native-flash-message';

import { loadCurrentUserContext } from '../modules/authentication/authentication.module';
import { AuthenticationService } from '../services/authentication.service';
import { LocalUserContext, LocalUserContexts } from '../types';

export const getNewToken = async (
  headers?: Record<string, string>,
): Promise<LocalUserContext | null> => {
  const authenticationService = new AuthenticationService();
  let localUserContext: LocalUserContext | null = null;

  // let accessToken: string | undefined = undefined;
  // let organizationId: string | undefined = undefined;
  let userContextId: string | undefined;
  if (headers) {
    // accessToken = headers['authorization']?.split(' ')[1];
    // organizationId = headers['x-organization-id'];
    userContextId = headers['x-user-context-id'];
  }

  if (!userContextId) {
    showMessage({
      message: 'ERROR getNewToken() no userContextId',
      description: JSON.stringify(headers),
      type: 'danger',
      autoHide: false,
      hideOnPress: true,
      duration: 5000,
    });
  }

  if (userContextId) {
    const userContexts = await authenticationService.loadLocalUserContextsAsync();
    const matchingLocalUserContexts: LocalUserContexts = Object.fromEntries(
      Object.entries(userContexts).filter(
        (userContextEntry) =>
          userContextEntry[1] && userContextEntry[1].userContextId === userContextId,
      ),
    );

    if (matchingLocalUserContexts && Object.keys(matchingLocalUserContexts).length > 0) {
      userContextId = Object.entries(matchingLocalUserContexts)[0][0];
      localUserContext = Object.entries(matchingLocalUserContexts)[0][1];

      if (localUserContext == null) {
        localUserContext = await loadCurrentUserContext();
      }
    }
  }

  if (localUserContext === null) {
    throw new Error('Could not refresh token. No CurrentUserContext set.');
  }
  const newCurrentLocalUserContext =
    await authenticationService.getLocalUserContextWithRefreshedTokenResponseAsync({
      localUserContext: localUserContext as LocalUserContext,
    });
  if (newCurrentLocalUserContext == null) {
    throw new Error('Could not refresh token as token is likely expired.');
  }
  return newCurrentLocalUserContext;
};
