import React, { forwardRef } from 'react';
import {
  ActivityIndicator,
  Platform,
  Pressable,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';

import { Button, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';
import { Place } from 'react-native-google-places-autocomplete';
import Modal from 'react-native-modal';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../../common/style';
import { FormDatepicker } from '../../../../../components/common/form/form-datepicker.component';
import { FormTextInputTopLabel } from '../../../../../components/common/form/form-text-input-top-label.component';
import { FormTimeSelect } from '../../../../../components/common/form/FormTimeSelect/form-time-select.component';
import { ThemedGooglePlacesAutocomplete } from '../../../../../components/common/google-places-autocomplete.component';
import { AgencyJobPlanningFormType } from '../../../../../generated-graphql-types';
import useDimensions from '../../../../../hooks/useDimensions';
import { useFileUploadActionSheet } from '../../../../../hooks/useFileUploadActionSheet';
import { useIsBackendReachable } from '../../../../../hooks/useIsBackendReachable';

const MAX_LENGTH = 200;

export const ChatTrackAndTracingFormModal = forwardRef(
  ({ handleCancel, handleConfirm, title }: any, ref): React.ReactElement => {
    const { isSmallDevice, dimensions } = useDimensions();
    const { t } = useTranslation();
    const globalStyle = useStyleSheet(globalStyleCommon);
    const styles = useStyleSheet(themedStyles);
    const isBackendReachable = useIsBackendReachable();

    const {
      actualDate,
      actualTime,
      actualDateTimeError,
      files,
      formType,
      googlePlaceRef,
      handleClose,
      handleGooglePlacesAutocompleteOnChangeText,
      handleGooglePlacesAutocompleteOnPress,
      handleRemoveFileItem,
      handleSelectImage,
      handleSelectPDF,
      handleSave,
      handleDateChange,
      handleTimeChange,
      handleNoteChange,
      isLoadingCurrentLocation,
      isSaving,
      locationText,
      locationTextError,
      locationSelectedText,
      note,
      targetDateTime,
      visible,
    } = useHook({ handleCancel, handleConfirm, ref });
    const { handleUploadFilesPress, renderModal } = useFileUploadActionSheet({
      isModal: true,
      onSelectImage: handleSelectImage,
      onSelectPDF: handleSelectPDF,
    });

    const renderFileItem = (item: any, index: number) => {
      const name = item.storedAsset?.metadata?.fileName
        ? item.storedAsset?.metadata?.fileName
        : item.name;
      return (
        <View
          key={name + index}
          style={[
            globalStyle.width100Percent,
            globalStyle.flex1,
            globalStyle.flexRow,
            globalStyle.alignItemsCenter,
            globalStyle.marginTop5,
            globalStyle.backgroundColorBasic4,
            globalStyle.padding10,
          ]}
        >
          <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsCenter]}>
            <Icon
              fill={(globalStyle.colorTextBasicColor as any).color}
              name="attach-2-outline"
              style={globalStyle.size16}
            />
            <Text
              style={[globalStyle.flex1, globalStyle.marginLeft5, globalStyle.paddingRight10]}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {name}
            </Text>
          </View>
          <Pressable onPress={handleRemoveFileItem(item, index)}>
            <Icon
              fill={(globalStyle.colorTextBasicColor as any).color}
              name="close"
              style={globalStyle.size16}
            />
          </Pressable>
        </View>
      );
    };

    const renderLabel = () => (
      <View style={styles.labelContainer}>
        <Text style={styles.label} />
        <Text
          style={[
            styles.maxLength,
            globalStyle.colorTextBasicColor,
            note.length === MAX_LENGTH && styles.maxLengthError,
          ]}
        >
          {note.length || 0}/{MAX_LENGTH}
        </Text>
      </View>
    );

    const renderFileUpload = () => {
      return (
        <View
          style={[globalStyle.width100Percent, globalStyle.marginTop10, globalStyle.flexColumn]}
        >
          <Button
            appearance="outline"
            status="primary"
            size="small"
            onPress={handleUploadFilesPress}
          >
            {t('jobs.uploadFileOrTakeAPhoto', {
              defaultValue: 'Upload file or take a photo',
            })}
          </Button>
          {!!files?.length && (
            <View style={globalStyle.marginTop10}>{files.map(renderFileItem)}</View>
          )}
        </View>
      );
    };

    const renderScrollView = () => {
      const predefinedPlaces: Place[] = [];
      let targetTimeString = t('common.notSetByAgency', { defaultValue: 'Not set by agency' });
      if (targetDateTime) {
        targetTimeString = `${targetDateTime.toFormat('HH:mm')}, ${targetDateTime?.toFormat('dd MMM yyyy')} LT`;
      }

      let width = dimensions.screen.width - 30;
      let diff = Platform.OS === 'ios' ? 45 : 35;
      if (width > 800) {
        width = 800 - diff;
      } else if (width > 700 && width <= 800) {
        width = 700 - diff;
      } else if (width > 600 && width <= 700) {
        width = 600 - diff;
      } else if (width <= 600) {
        width = dimensions.screen.width - diff;
      }

      let containerStyle = [globalStyle.flex0, { minWidth: 163 }, globalStyle.marginTop10];
      if (Platform.OS === 'android') {
        containerStyle = [{ minWidth: 60 }, globalStyle.marginTop5];
      } else if (Platform.OS === 'ios') {
        containerStyle = [globalStyle.marginTop5];
      }
      return (
        <ScrollView
          contentContainerStyle={[styles.inner, { width }]}
          horizontal={false}
          keyboardShouldPersistTaps="always"
        >
          <Text category="h5" style={styles.title}>
            {title}
          </Text>
          <View style={globalStyle.width100Percent}>
            <View
              style={[
                globalStyle.flex1,
                isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                !isSmallDevice && globalStyle.alignItemsCenter,
              ]}
            >
              <Text style={globalStyle.width150}>
                {t('jobs.target', { defaultValue: 'Target' })}
              </Text>
              <FormTextInputTopLabel
                containerStyle={[globalStyle.flex1, globalStyle.marginTop10]}
                disabled
                label=""
                value={targetTimeString}
              />
            </View>
            <View
              style={[
                globalStyle.flex1,
                isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                !isSmallDevice && globalStyle.alignItemsCenter,
                globalStyle.marginTop20,
                globalStyle.marginBottom10,
              ]}
            >
              <Text style={globalStyle.width150}>
                {t('jobs.actual', { defaultValue: 'Actual' })}
              </Text>
              <View style={[globalStyle.flex1, globalStyle.flexRow]}>
                <FormDatepicker
                  containerStyle={[globalStyle.flex1, globalStyle.marginTop10] as any}
                  date={actualDate ? actualDate.toJSDate() : undefined}
                  label=""
                  isMandatory
                  onSelect={handleDateChange}
                />
                <FormTimeSelect
                  containerStyle={containerStyle as any}
                  minuteInterval={1}
                  onChange={handleTimeChange}
                  value={actualTime}
                />
              </View>
              {!!actualDateTimeError && (
                <View style={styles.errorContainer}>
                  <Text selectable={true} style={styles.errorMessageText}>
                    {actualDateTimeError}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View
            style={[
              globalStyle.width100Percent,
              globalStyle.marginTop10,
              globalStyle.marginBottom20,
            ]}
          >
            {!!locationSelectedText && (
              <Text style={[globalStyle.textAlignRight, globalStyle.marginBottom5]}>
                {locationSelectedText}
              </Text>
            )}
            {!locationSelectedText && isLoadingCurrentLocation && (
              <View
                style={[
                  globalStyle.flexRow,
                  globalStyle.justifyContentEnd,
                  globalStyle.alignItemsCenter,
                ]}
              >
                <Text
                  style={[
                    globalStyle.textAlignRight,
                    globalStyle.marginBottom5,
                    globalStyle.fontSize12,
                    globalStyle.marginRight5,
                  ]}
                >
                  {t('Loading current location')}
                </Text>
                <ActivityIndicator style={globalStyle.marginBottom5} />
              </View>
            )}
            <ThemedGooglePlacesAutocomplete
              ref={googlePlaceRef}
              onPress={handleGooglePlacesAutocompleteOnPress}
              language="en"
              predefinedPlaces={predefinedPlaces}
              placeholderText={
                t('jobs.enterTrackingPointLocation', {
                  defaultValue: 'Enter tracking point location',
                }) as string
              }
              currentLocationText={
                t('jobs.currentLocation', { defaultValue: 'Current location' }) as string
              }
              onChange={handleGooglePlacesAutocompleteOnChangeText}
              textInputProps={{
                value: locationText,
              }}
            />
            {!!locationTextError && (
              <View style={styles.errorContainer}>
                <Text selectable={true} style={styles.errorMessageText}>
                  {locationTextError}
                </Text>
              </View>
            )}
            {!isBackendReachable ? (
              <Text
                style={[
                  globalStyle.textAlignRight,
                  globalStyle.fontSize13,
                  globalStyle.marginTop2,
                  globalStyle.colorDanger500,
                  globalStyle.paddingLeft30,
                ]}
              >
                {t('common.pleaseEnterAsDetailedAddressAsPossible', {
                  defaultValue:
                    'Please enter as detailed an address as possible considering the lack of internet connection.',
                })}
              </Text>
            ) : (
              <Text
                style={[
                  globalStyle.textAlignRight,
                  globalStyle.fontSize13,
                  globalStyle.marginTop2,
                  globalStyle.paddingLeft30,
                  globalStyle.opacity8,
                ]}
              >
                {
                  t('jobs.captionIfLocationNotCorrectAbove', {
                    defaultValue: 'If location is not correct, enter the location manually above',
                  }) as string
                }
              </Text>
            )}
          </View>
          <View
            style={[
              globalStyle.width100Percent,
              globalStyle.marginBottom10,
              globalStyle.height150,
              globalStyle.marginBottom20,
            ]}
          >
            {renderLabel()}
            <TextInput
              onChangeText={handleNoteChange}
              style={[
                globalStyle.height100Percent,
                globalStyle.verticalAlignTop,
                globalStyle.padding10,
                globalStyle.verticalAlignTop,
                globalStyle.lineHeight20,
                globalStyle.colorTextBasicColor,
                globalStyle.borderWidth1,
                globalStyle.borderBasicColor4,
                globalStyle.backgroundColorBasic2,
                globalStyle.borderRadius4,
                Platform.OS === 'web' && ({ outlineStyle: 'none' } as any),
              ]}
              placeholderTextColor={(globalStyle.colorTextHintColor as any).color}
              multiline
              placeholder={t('common.notesAsReallyNeeded', {
                defaultValue: 'Notes as really needed',
              })}
              verticalAlign="top"
              value={note}
            />
          </View>
          {formType === AgencyJobPlanningFormType.GENERIC_FORM_B && renderFileUpload()}

          <View style={styles.buttonsContainer}>
            <Button
              appearance="filled"
              status="primary"
              size="medium"
              onPress={handleSave}
              style={globalStyle.marginBottom10}
            >
              {isSaving ? (
                <ActivityIndicator color={(styles.loading as any).color} />
              ) : (
                t('common.saveAndContinue', { defaultValue: 'Save & continue' })
              )}
            </Button>
          </View>
        </ScrollView>
      );
    };

    return (
      <>
        <Modal
          animationIn="fadeIn"
          animationOut="fadeOut"
          avoidKeyboard
          isVisible={visible}
          backdropOpacity={0.8}
          useNativeDriver
          hideModalContentWhileAnimating
          style={
            Platform.OS === 'web' && [
              globalStyle.justifyContentCenter,
              globalStyle.alignItemsCenter,
            ]
          }
        >
          <Pressable style={globalStyle.absoluteFull} onPress={handleCancel} />
          <View style={styles.wrapper}>
            {renderScrollView()}
            <TouchableOpacity
              style={styles.closeButton}
              onPress={handleClose}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <Icon
                fill={(globalStyle.colorTextBasicColor as any).color}
                name="close"
                style={globalStyle.size30}
              />
            </TouchableOpacity>
          </View>
          {renderModal()}
        </Modal>
      </>
    );
  },
);
