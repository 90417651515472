import React from 'react';

import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { Button, Icon } from '@ui-kitten/components';

import { AgencyTermsAndConditionsSignupScreen } from '../screens/agency/agency.terms-and-conditions-signup.screen';
import { CommonAppInformationScreen } from '../screens/common/common.app-information.screen';
import { CommonAppSettingsScreen } from '../screens/common/common.app-settings.screen';
import { CommonBugHelloNextScreen } from '../screens/common/common.bug.hellonext.screen';
import { CommonChangelogHelloNextScreen } from '../screens/common/common.changelog.hellonext.screen';
import { CommonChatSupportPipedriveScreen } from '../screens/common/common.chat-support.pipedrive.screen';
import { CommonFeatureHelloNextScreen } from '../screens/common/common.feature.hellonext.screen';
import { CommonRoadmapHelloNextScreen } from '../screens/common/common.roadmap.hellonext.screen';
import { CommonSwitchAccountScreen } from '../screens/common/common.switch-account.screen';
import { CommonAgencyAccountSettingsScreen } from '../screens/common/CommonAgencyAccountSettingsScreen';
import { CommonCourierAccountSettingsScreen } from '../screens/common/CommonCourierAccountSettingsScreen';
import { CourierTermsAndConditionsSignupScreen } from '../screens/courier/courier.terms-and-conditions-signup.screen';

const StackNavigator = createStackNavigator();

const CommonProfileNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="CommonSwitchAccountScreen"
    >
      <StackNavigator.Screen name="CommonAppSettingsScreen" component={CommonAppSettingsScreen} />
      <StackNavigator.Screen
        name="CommonAgencyAccountSettingsScreen"
        component={CommonAgencyAccountSettingsScreen}
      />
      <StackNavigator.Screen
        name="CommonCourierAccountSettingsScreen"
        component={CommonCourierAccountSettingsScreen}
      />
      <StackNavigator.Screen
        name="CommonAppInformationScreen"
        component={CommonAppInformationScreen}
      />
      <StackNavigator.Screen
        name="CommonSwitchAccountScreen"
        component={CommonSwitchAccountScreen}
      />

      <StackNavigator.Group
        screenOptions={({ navigation }) => ({
          cardShadowEnabled: true,
          headerShown: false,
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
          animationEnabled: true,
          headerRight: () => (
            <Button
              onPress={() => navigation.goBack()}
              appearance="ghost"
              accessoryLeft={<Icon name="close-outline" />}
            />
          ),
        })}
      >
        <StackNavigator.Screen
          options={{
            ...TransitionPresets.ModalSlideFromBottomIOS,
            headerTitle: 'Sign Up as Courier',
          }}
          name="AgencyTermsAndConditionsSignupScreen"
          component={AgencyTermsAndConditionsSignupScreen}
        />
        <StackNavigator.Screen
          options={{
            ...TransitionPresets.ModalSlideFromBottomIOS,
            headerTitle: 'Sign Up as Courier',
          }}
          name="CourierTermsAndConditionsSignupScreen"
          component={CourierTermsAndConditionsSignupScreen}
        />
      </StackNavigator.Group>

      <StackNavigator.Screen
        name="CommonFeatureHelloNextScreen"
        component={CommonFeatureHelloNextScreen}
      />
      <StackNavigator.Screen name="CommonBugHelloNextScreen" component={CommonBugHelloNextScreen} />
      <StackNavigator.Screen
        name="CommonChangelogHelloNextScreen"
        component={CommonChangelogHelloNextScreen}
      />
      <StackNavigator.Screen
        name="CommonChatSupportPipedriveScreen"
        component={CommonChatSupportPipedriveScreen}
      />
      <StackNavigator.Screen
        name="CommonRoadmapHelloNextScreen"
        component={CommonRoadmapHelloNextScreen}
      />
    </StackNavigator.Navigator>
  );
};

export { CommonProfileNavigator };
