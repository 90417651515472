import React from 'react';
import { View } from 'react-native';

import { Button, Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../../common/style';
import {
  OrganizationInvitation,
  OrganizationInvitationType,
  OrganizationType,
  OrganizationUserRoleType,
} from '../../../../generated-graphql-types';
import { HasPermissions } from '../../HasPermissions';

export const AgencyEmployeeInvitationCardDescription = ({
  onCancelInvitationPress,
  organizationInvitation,
}: {
  onCancelInvitationPress: () => Promise<void>;
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  const { t } = useTranslation();

  const renderCancelButton = () => (
    <HasPermissions
      organizationType={[OrganizationType.AGENCY]}
      organizationUserRoleType={[OrganizationUserRoleType.ADMIN, OrganizationUserRoleType.OWNER]}
    >
      <Button size="tiny" onPress={onCancelInvitationPress}>
        {t('common:cancelInvitation', { defaultValue: 'Cancel invitation' }) as string}
      </Button>
    </HasPermissions>
  );

  const { invitedOrganizationType, invitedOrganization, organizationInvitationType, roleType } =
    organizationInvitation;
  if (
    organizationInvitationType &&
    [
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE,
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
    ].includes(organizationInvitationType) &&
    roleType
  ) {
    return (
      <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsEnd]}>
        <View style={[globalStyle.flex1, globalStyle.marginRight15]}>
          <Text style={globalStyle.fontSize14}>
            {
              t('orgInvitationDescriptionCanBeUsedToJoin', {
                defaultValue:
                  'Can be used to join the {{invitedOrganizationType}} with role {{roleType}}.',
                invitedOrganizationType,
                roleType,
              }) as string
            }
          </Text>
          {!!invitedOrganization?.legalName && (
            <Text style={globalStyle.fontSize14}>
              {
                t('invitationCanBeUsedBy', {
                  defaultValue:
                    'Invitation can be used by {{invitedOrganizationType}} with role {{roleType}}',
                  invitedOrganizationType,
                  legalName: invitedOrganization?.legalName,
                  roleType: roleType,
                }) as string
              }
            </Text>
          )}
        </View>
        {renderCancelButton()}
      </View>
    );
  }
  return (
    <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsCenter]}>
      <Text style={[globalStyle.flex1, globalStyle.fontSize14]}>
        {
          t('agencyCourierOpenInvitations.other', {
            defaultValue: 'Other...',
          }) as string
        }
      </Text>
      {renderCancelButton()}
    </View>
  );
};
