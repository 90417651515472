import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { useQuery } from '@apollo/client';
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native';

import { AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY } from '../../../apollo/graphql-queries';
import { AgencyJob } from '../../../generated-graphql-types';
import { useAppState } from '../../../hooks/useAppState';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const route: any = useRoute();
  const navigation: any = useNavigation();
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();
  const { appState } = useAppState();

  const refetchInterval = useRef<NodeJS.Timer | null>(null);

  const { previousScreenName, agencyJobId } = route.params || {};

  const [agencyJob, setAgencyJob] = useState<AgencyJob | undefined>();
  const [activeTab, setActiveTab] = useState('AgencyCourierJobOverviewTab');
  const [refreshing, setRefreshing] = useState(false);

  const {
    error: agencyJobError,
    loading,
    refetch,
  } = useQuery(AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY, {
    variables: { agencyJobId },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    onCompleted: (data) => {
      if (data.agencyJobForAgencyOrganization) {
        setAgencyJob(data.agencyJobForAgencyOrganization);
      }
    },
    skip: !agencyJobId || !isFocused,
  });

  useEffect((): any => {
    if (agencyJobId && agencyJob && !refetchInterval.current) {
      refetchInterval.current = setInterval(() => {
        if (isFocused && appState === 'active') {
          refetch();
        }
      }, 30 * 1000);
    } else if (appState !== 'active') {
      clearInterval(refetchInterval.current as any);
      refetchInterval.current = null;
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyJob, agencyJobId, appState, isFocused, refetchInterval]);

  useEffect(() => {
    if (route.params?.refetch) {
      navigation.setParams({ refetch: false });
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const handleUpdateJob = (newAgencyJob: Partial<AgencyJob>) => {
    setAgencyJob((prev) => ({ ...prev, ...newAgencyJob }) as AgencyJob);
  };

  const handleBackPress = () => {
    if (Platform.OS === 'web') {
      if (route.params?.previousScreenName) {
        if (
          route.params.previousScreenName.includes(',') ||
          route.params.previousScreenName.includes('%2C')
        ) {
          let routes = [];
          if (route.params.previousScreenName.includes('%2C')) {
            routes = route.params.previousScreenName.split('%2C');
          } else {
            routes = route.params.previousScreenName.split(',');
          }
          let newPreviousScreenName = '';
          const previousScreenNames = routes.slice(0, -1);
          if (previousScreenNames.length) {
            newPreviousScreenName = previousScreenNames.join(',');
          }
          navigation.navigate(routes[routes.length - 1], {
            agencyJobId: route.params?.agencyJobId || '',
            courierJobId: route.params?.courierJobId || '',
            previousScreenName: newPreviousScreenName,
          });
        } else {
          navigation.navigate(route.params?.previousScreenName, {
            agencyJobId: route.params?.agencyJobId || '',
            courierJobId: route.params?.courierJobId || '',
          });
        }
      } else {
        navigation.navigate('AgencyHomeScreen');
      }
    } else {
      navigation.goBack();
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  return {
    activeTab,
    agencyJob,
    agencyJobError,
    handleBackPress,
    handleRefresh,
    handleUpdateJob,
    loading,
    previousScreenName,
    refreshing,
    setActiveTab,
  };
};
