import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  messageText: {
    color: 'white',
  },
  messageStatusText: {
    color: 'white',
    backgroundColor: 'color-success-700',
    padding: 12,
    paddingTop: 5,
    paddingBottom: 8,
    borderRadius: 8,
  },
});
