import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { PriojetDeletedAccountsScreen } from '../screens/priojet/priojet.deleted-accounts.screen';
import { PriojetHomeScreen } from '../screens/priojet/priojet.home.screen';

const StackNavigator = createStackNavigator();

const PriojetHomeNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="PriojetCourierAvailabilitiesScreen"
    >
      <StackNavigator.Screen name="PriojetHomeScreen" component={PriojetHomeScreen} />
      <StackNavigator.Screen
        name="PriojetDeletedAccountsScreen"
        component={PriojetDeletedAccountsScreen}
      />
    </StackNavigator.Navigator>
  );
};

export { PriojetHomeNavigator };
