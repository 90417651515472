import React, { useMemo } from 'react';
import { Image } from 'react-native';

import { CheckBox, Divider, Layout, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { FormMoneyInput } from '../../../../components/common/form/form-money-input.component';
import { FormSelect } from '../../../../components/common/form/FormSelect';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';
import { Currency } from '../../../../graphql-types';

const americanExpressImg = require('../../../../assets/images/american-express-logo.png');
const dinersImg = require('../../../../assets/images/diners-club-international-icon.png');
const masterImg = require('../../../../assets/images/mastercard-logo.png');
const visaImg = require('../../../../assets/images/visa-logo.png');

export const OBCCreditCardEdit = ({
  errors,
  fieldProps,
  onSelect,
  values,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const currencyList = useMemo(() => {
    return Object.keys(Currency).map((item) => ({ name: item, value: item }));
  }, []);

  const renderSelect = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormSelect
        appearance="default"
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey }) as any}
        items={options}
        value={values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderCreditCardLimitFields = (
    fieldLimit: string,
    fieldCurrency: string,
    fieldCheck: string,
  ) => (
    <>
      {renderSelect({
        disabled: !values[fieldCheck],
        label: t('courierUserProfile.currency', { defaultValue: 'Currency' }) as string,
        valueKey: fieldCurrency,
        options: currencyList,
        fieldTitleKey: 'name',
        fieldValueKey: 'value',
      })}

      <FormMoneyInput
        label={t('courierUserProfile.maximumCreditLimit', {
          defaultValue: 'Maximum Credit Limit (only numbers)',
        })}
        targetCurrency={(values[fieldCurrency] || Currency.EUR) as Currency}
        displayNumberToWords
        isDisabled={!values[fieldCheck]}
        {...(fieldProps(fieldLimit, 'money-input') as any)}
      />
    </>
  );

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.cards', { defaultValue: 'Cards' }).toUpperCase()}
      Content={
        <>
          <Layout key="amexCardsLayout" style={styles.cardsInternalLayout}>
            <Image
              style={styles.cardImage as any}
              source={americanExpressImg}
              resizeMode="contain"
            />
            <CheckBox {...fieldProps('hasAmexCreditCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.creditCard', {
                  defaultValue: 'Credit Card',
                }) as string
              }
            </CheckBox>
            <CheckBox {...fieldProps('hasAmexDebitCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.debitCard', {
                  defaultValue: 'Debit Card',
                }) as string
              }
            </CheckBox>
          </Layout>
          {renderCreditCardLimitFields(
            'amexCreditCardLimit',
            'amexCreditCardCurrency',
            'hasAmexCreditCard',
          )}

          <Divider style={styles.marginVertical20} />

          <Layout key="dinerClubCardLayout" style={styles.cardsInternalLayout}>
            <Image style={styles.cardImage as any} source={dinersImg} resizeMode="contain" />
            <CheckBox {...fieldProps('hasDinerCreditCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.creditCard', {
                  defaultValue: 'Credit Card',
                }) as string
              }
            </CheckBox>
            <CheckBox {...fieldProps('hasDinerDebitCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.debitCard', {
                  defaultValue: 'Debit Card',
                }) as string
              }
            </CheckBox>
          </Layout>
          {renderCreditCardLimitFields(
            'dinerCreditCardLimit',
            'dinerCreditCardCurrency',
            'hasDinerCreditCard',
          )}

          <Divider style={styles.marginVertical20} />
          <Layout key="masterCardLayout" style={styles.cardsInternalLayout}>
            <Image style={styles.cardImage as any} source={masterImg} resizeMode="contain" />
            <CheckBox {...fieldProps('hasMasterCreditCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.creditCard', {
                  defaultValue: 'Credit Card',
                }) as string
              }
            </CheckBox>
            <CheckBox {...fieldProps('hasMasterDebitCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.debitCard', {
                  defaultValue: 'Debit Card',
                }) as string
              }
            </CheckBox>
          </Layout>
          {renderCreditCardLimitFields(
            'masterCreditCardLimit',
            'masterCreditCardCurrency',
            'hasMasterCreditCard',
          )}

          <Divider style={styles.marginVertical20} />

          <Layout key="visaCardLayout" style={styles.cardsInternalLayout}>
            <Image style={styles.cardImage as any} source={visaImg} resizeMode="contain" />
            <CheckBox {...fieldProps('hasVisaCreditCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.creditCard', {
                  defaultValue: 'Credit Card',
                }) as string
              }
            </CheckBox>
            <CheckBox {...fieldProps('hasVisaDebitCard', 'checkbox')} status="primary">
              {
                t('courierUserProfile.debitCard', {
                  defaultValue: 'Debit Card',
                }) as string
              }
            </CheckBox>
          </Layout>
          {renderCreditCardLimitFields(
            'visaCreditCardLimit',
            'visaCreditCardCurrency',
            'hasVisaCreditCard',
          )}
        </>
      }
      style={styles.contentBox}
    />
  );
};

const arePropsEqual = (prev: any, next: any) => {
  const keys: any[] = [
    'hasAmexCreditCard',
    'hasAmexDebitCard',
    'amexCreditCardLimit',
    'amexCreditCardCurrency',
    'hasDinerCreditCard',
    'hasDinerDebitCard',
    'dinerCreditCardLimit',
    'dinerCreditCardCurrency',
    'hasMasterCreditCard',
    'hasMasterDebitCard',
    'masterCreditCardLimit',
    'masterCreditCardCurrency',
    'hasVisaCreditCard',
    'hasVisaDebitCard',
    'visaCreditCardLimit',
    'visaCreditCardCurrency',
  ];
  if (keys.some((key) => next?.values[key] !== prev?.values[key])) {
    return false;
  }
  if (keys.some((key) => next?.errors[key] !== prev?.errors[key])) {
    return false;
  }
  return true;
};

export default React.memo(OBCCreditCardEdit, arePropsEqual);
