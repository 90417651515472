import React from 'react';
import { View, ViewStyle } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';

const ContentBox = ({
  Title,
  Content,
  SubTitle,
  style,
}: {
  Title?: JSX.Element;
  Content: JSX.Element;
  SubTitle?: JSX.Element;
  style?: ViewStyle[] | ViewStyle;
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={[styles.contentBox, style]}>
      {!!Title && <View style={styles.contextBoxTitle}>{Title}</View>}
      {!!SubTitle && <View style={styles.contextBoxSubTitle}>{SubTitle}</View>}
      <View style={styles.contentBoxContent}>{Content}</View>
    </View>
  );
};

const themedStyles = StyleService.create({
  contentBox: {
    marginTop: 15,
    marginRight: 15,
    marginLeft: 15,
    borderWidth: 1,
    borderColor: 'border-basic-color-3',
    letterSpacing: 0.1,
  },
  contextBoxTitle: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  contextBoxSubTitle: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
  },
  contentBoxContent: {
    borderTopWidth: 0,
    borderTopColor: 'border-basic-color-5',
    paddingLeft: 10,
    paddingTop: 5,
    paddingRight: 10,
    paddingBottom: 5,
  },
});

export default ContentBox;
