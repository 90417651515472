import React, { useEffect, useState } from 'react';
import { View, ViewStyle } from 'react-native';

import {
  InputProps,
  Radio,
  RadioGroup,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { TFunctionDetailedResult } from 'i18next';

export const FormBinaryRadioGroup = (
  props: Omit<InputProps, 'value' | 'onChange'> & {
    value: boolean;
    onChange: (value: boolean) => void;
    label: TFunctionDetailedResult | string;
    caption: TFunctionDetailedResult | string;
    errorMessage?: TFunctionDetailedResult | string;
    falseOptionName: TFunctionDetailedResult | string;
    trueOptionName: TFunctionDetailedResult | string;
    keyPrefix: string;
    isMandatory?: boolean;
    containerStyle?: ViewStyle;
  },
): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(() => {
    if (props.value === undefined) {
      return undefined;
    }
    return props.value ? 1 : 0;
  });

  useEffect(() => {
    setSelectedIndex(() => {
      if (props.value === undefined) {
        return undefined;
      }
      return props.value ? 1 : 0;
    });
  }, [props.value]);

  const handleChangePress = (index: number) => {
    if (!props.disabled && props.onChange) {
      props.onChange(index !== 0);
    }
  };

  const renderCaption = () => {
    if (!props.caption && !props.errorMessage) {
      return <></>;
    }
    return (
      <View style={styles.captionContainer}>
        {!!props.caption && (
          <Text selectable={true} style={styles.caption}>
            {props.caption as string}
          </Text>
        )}
        {!!props.errorMessage && (
          <Text selectable={true} style={styles.errorMessageText}>
            {props.errorMessage as string}
          </Text>
        )}
      </View>
    );
  };

  return (
    <View style={[styles.containerStyle, props?.containerStyle]}>
      <View style={styles.labelContainer}>
        <Text selectable={true} style={styles.label}>
          <>
            {props.label as string}
            {!!props.isMandatory && (
              <Text selectable={true} style={styles.mandatoryStar}>
                *
              </Text>
            )}
          </>
        </Text>
      </View>
      <RadioGroup
        style={styles.radioGroupContainer}
        onChange={handleChangePress} // 0 is false, other is true
        selectedIndex={selectedIndex}
      >
        <Radio style={styles.radioInput} checked={true}>
          {props.falseOptionName as string}
        </Radio>
        <Radio style={styles.radioInput}>{props.trueOptionName as string}</Radio>
      </RadioGroup>
      {renderCaption()}
    </View>
  );
};

const themedStyles = StyleService.create({
  containerStyle: {
    marginTop: 20,
  },
  radioGroupContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  radioInput: {
    flex: 1,
    paddingHorizontal: 10,
  },
  captionContainer: {
    paddingLeft: 10,
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  labelContainer: {
    paddingLeft: 10,
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'red',
  },
  errorMessageText: {
    color: 'color-danger-500',
    fontSize: 13,
    paddingVertical: 5,
  },
});
