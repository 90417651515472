import React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { AgencyCourierAvailabilitiesNavigator } from './agency.courier-availabilities.navigator';
import { AgencyHomeNavigator } from './agency.home.navigator';
import { AgencyProfileNavigator } from './agency.profile.navigator';
import { AvailabilitiesIcon, HomeIcon, ProfileIcon } from '../components/icons.component';

const BottomTabNavigator = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const { t } = useTranslation();

  return (
    <>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={(index) => navigation.navigate(state.routeNames[index])}
      >
        <BottomNavigationTab
          title={t('common.cockpit', { defaultValue: 'Cockpit' }).toUpperCase()}
          icon={HomeIcon}
        />
        <BottomNavigationTab
          title={t('common.availabilities', {
            defaultValue: 'Availabilities',
          }).toUpperCase()}
          icon={AvailabilitiesIcon}
        />
        <BottomNavigationTab
          title={t('common.Profile', { defaultValue: 'Profile' }).toUpperCase()}
          icon={ProfileIcon}
        />
      </BottomNavigation>
    </>
  );
};

const AgencyNavigator = () => (
  <BottomTabNavigator.Navigator
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <BottomTabBar {...props} />}
  >
    <BottomTabNavigator.Screen
      name="AgencyHomeNavigator"
      component={AgencyHomeNavigator}
      options={{ title: 'Home' }}
    />
    <BottomTabNavigator.Screen
      name="AgencyCourierAvailabilitiesNavigator"
      component={AgencyCourierAvailabilitiesNavigator}
      options={{ title: 'Courier Availabilities' }}
    />
    <BottomTabNavigator.Screen
      name="AgencyProfileNavigator"
      component={AgencyProfileNavigator}
      options={{ title: 'Profile' }}
    />
  </BottomTabNavigator.Navigator>
);

export { AgencyNavigator };
