import AsyncStorage from '@react-native-async-storage/async-storage';

import { createApolloClientPersistAsync } from '../../apollo/create-apollo-client-persist-async';
import { CREATE_DEVICE_LOCATIONS_MUTATION } from '../../apollo/graphql-mutations';
import {
  CreateDeviceLocationsMutation,
  CreateDeviceLocationsMutationVariables,
  DeviceLocation,
  DeviceLocationInput,
} from '../../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../../globals';

const createDeviceLocations = async (
  deviceLocationInputs: Array<DeviceLocationInput>,
): Promise<DeviceLocation | null> => {
  const { apolloClient } = await createApolloClientPersistAsync();

  const result = await apolloClient.mutate<
    CreateDeviceLocationsMutation,
    CreateDeviceLocationsMutationVariables
  >({
    mutation: CREATE_DEVICE_LOCATIONS_MUTATION,
    variables: {
      deviceLocationInputs,
    },
  });

  if (result?.data?.createDeviceLocations) {
    return result.data?.createDeviceLocations as DeviceLocation;
  }
  return null;
};

export const addDeviceLocation = async (
  deviceLocationInput: DeviceLocationInput,
): Promise<DeviceLocation | null> => {
  // Don't send any location updates when the Device is not set up yet.
  // When the device ID is not registered with the backend, it will result in an Error where
  // the App will reload (see apollo errorLink).
  if (
    (await AsyncStorage.getItem(GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY)) === null
  ) {
    return null;
  }

  return createDeviceLocations([deviceLocationInput]);
};
