import React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { CourierAvailabilitiesNavigator } from './courier.availabilities.navigator';
import { CourierHomeNavigator } from './courier.home.navigator';
import { CourierProfileNavigator } from './courier.profile.navigator';
import { AvailabilitiesIcon, JobsIcon, ProfileIcon } from '../components/icons.component';

const BottomTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const { t } = useTranslation();

  return (
    <>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={(index: number) => navigation.navigate(state.routeNames[index])}
      >
        <BottomNavigationTab
          title={t('common.cockpit', { defaultValue: 'Cockpit' }).toUpperCase()}
          icon={JobsIcon}
        />
        <BottomNavigationTab
          title={t('menuItems.Availabilities', {
            defaultValue: 'Availabilities',
          }).toUpperCase()}
          icon={AvailabilitiesIcon}
        />
        <BottomNavigationTab
          title={t('menuItems.Profile', { defaultValue: 'Profile' }).toUpperCase()}
          icon={ProfileIcon}
        />
      </BottomNavigation>
    </>
  );
};

const BottomTabNavigator = createBottomTabNavigator();
const CourierNavigator = () => {
  return (
    <BottomTabNavigator.Navigator
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <BottomTabBar {...props} />}
    >
      <BottomTabNavigator.Screen name="CourierHomeNavigator" component={CourierHomeNavigator} />
      <BottomTabNavigator.Screen
        name="CourierAvailabilitiesNavigator"
        component={CourierAvailabilitiesNavigator}
      />
      <BottomTabNavigator.Screen
        name="CourierProfileNavigator"
        component={CourierProfileNavigator}
      />
    </BottomTabNavigator.Navigator>
  );
};

export { CourierNavigator };
