import { DateTime, IANAZone, Zone } from 'luxon';

export const getTimeZoneDifferenceInMinutesForTimestamp = (
  sourceZone: Zone | string,
  targetZone: Zone | string,
  timestampInMs: DateTime | number,
): undefined | number => {
  // Beware, !0 equals false even though it is a valid timestamp.
  if (!sourceZone || !targetZone || timestampInMs === undefined) {
    return undefined;
  }
  if (!(sourceZone instanceof Zone)) {
    if (!IANAZone.isValidZone(sourceZone)) {
      console.warn('Given sourceZone is not a valid IANA time zone.');
      return undefined;
    }
    sourceZone = IANAZone.create(sourceZone);
  }
  if (!(targetZone instanceof Zone)) {
    if (!IANAZone.isValidZone(targetZone)) {
      console.warn('Given targetZone is not a valid IANA time zone.');
      return undefined;
    }
    targetZone = IANAZone.create(targetZone);
  }
  if (timestampInMs instanceof DateTime) {
    timestampInMs = timestampInMs.toMillis();
  }
  const sourceZoneOffset = sourceZone.offset(timestampInMs);
  const targetZoneOffset = targetZone.offset(timestampInMs);
  return targetZoneOffset - sourceZoneOffset;
};

export const convertDateToLuxon = (date: DateTime | string | null | undefined) => {
  if (!date) {
    return undefined;
  }
  if (typeof date === 'string') {
    return DateTime.fromJSDate(new Date(date as any));
  }
  return date;
};
