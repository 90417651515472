import React from 'react';
import { Pressable, View } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import { Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { ChatFlightDetailsModal } from './ChatFlightDetailsModal';
import { ChatPlanningListModal } from './ChatPlanningListModal';
import { ChatTrackAndTracingFormModal } from './ChatTrackAndTracingFormModal';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../common/style';

export const TrackAndTracing = ({
  agencyJobId,
  getLatestChatMessages,
  headerHeight,
  subtitle,
  title,
}: {
  agencyJobId: string | undefined;
  getLatestChatMessages: () => void;
  headerHeight: number;
  subtitle: string;
  title: string;
}) => {
  const { t } = useTranslation();
  const globalStyle = useStyleSheet(globalStyleCommon);
  const styles = useStyleSheet(themedStyles);

  const {
    agencyJobPlanningLegsActive,
    currentItem,
    currentItemText,
    chatFlightDetailsModalVisible,
    chatTrackAndTracingFormRef,
    chatTrackAndTracingOfflineFormRef,
    chatPlanningListModalRef,
    countOfItemsOutOfSync,
    handleConfirmForm,
    handleCancelForm,
    handleCancelFlightDetails,
    handleCancelPlanningModal,
    handleOpenForm,
    handleOpenFlightDetails,
    handleOpenPlanningModal,
    handleResetPress,
    handleSelectListItem,
    handleSelectListItemOffline,
    isCourier,
    state,
  } = useHook({ agencyJobId, getLatestChatMessages });

  return (
    <View style={[globalStyle.absolute, styles.container, { top: headerHeight }]}>
      {!!countOfItemsOutOfSync && (
        <View style={styles.barStyle}>
          <Text style={styles.text}>
            {t('common.certainTrackingPointsHaveNotBeenSent', {
              defaultValue:
                "Certain tracking points haven't been sent. Once your internet connection is restored, review and verify the information from the list.",
            })}
          </Text>
        </View>
      )}
      <View style={[globalStyle.flexRow, globalStyle.marginVertical5]}>
        <Pressable
          style={[
            globalStyle.flex1,
            isCourier ? globalStyle.backgroundColorBasic3 : globalStyle.backgroundColorBasic1,
            globalStyle.borderWidth1,
            globalStyle.borderBasicColor3,
            globalStyle.padding10,
            globalStyle.paddingHorizontal15,
            globalStyle.borderRadius8,
            globalStyle.marginLeft5,
          ]}
          onPress={isCourier ? handleOpenForm : null}
        >
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.textAlignCenter, globalStyle.fontSize14]}
          >
            {isCourier
              ? t('jobs.sendYourTrackingPoint', { defaultValue: 'Send your tracking point' })
              : t('jobs.nextTrackingPoint', { defaultValue: 'Next tracking point' })}
            :
          </Text>
          <Text style={globalStyle.textAlignCenter}>{currentItemText || '...'}</Text>
        </Pressable>
        <Pressable
          style={[
            globalStyle.alignItemsCenter,
            globalStyle.justifyContentCenter,
            globalStyle.backgroundColorBasic3,
            globalStyle.borderWidth1,
            globalStyle.borderBasicColor3,
            globalStyle.marginLeft5,
            globalStyle.padding10,
            globalStyle.paddingHorizontal15,
            globalStyle.borderRadius8,
          ]}
          onPress={handleOpenFlightDetails}
        >
          <MaterialIcons
            color={(globalStyle.colorTextBasicColor as any).color}
            name="airplanemode-on"
            size={24}
          />
        </Pressable>
        <Pressable
          style={[
            globalStyle.alignItemsCenter,
            globalStyle.justifyContentCenter,
            globalStyle.backgroundColorBasic3,
            globalStyle.borderWidth1,
            globalStyle.borderBasicColor3,
            globalStyle.marginLeft5,
            globalStyle.marginRight5,
            globalStyle.padding10,
            globalStyle.paddingHorizontal15,
            globalStyle.borderRadius8,
          ]}
          onPress={handleOpenPlanningModal}
        >
          <MaterialIcons
            color={(globalStyle.colorTextBasicColor as any).color}
            name="menu"
            size={24}
          />
          {!!countOfItemsOutOfSync && (
            <View style={styles.countOutOfSync}>
              <Text style={styles.countOutOfSyncText}>{countOfItemsOutOfSync}</Text>
            </View>
          )}
        </Pressable>
      </View>

      <ChatTrackAndTracingFormModal
        ref={chatTrackAndTracingFormRef}
        handleConfirm={handleConfirmForm}
        handleCancel={handleCancelForm}
        title={currentItem && currentItem?.label ? currentItem.label : ''}
      />

      <ChatFlightDetailsModal
        handleCancel={handleCancelFlightDetails}
        agencyJobPlanningLegs={agencyJobPlanningLegsActive}
        visible={chatFlightDetailsModalVisible}
      />

      <ChatPlanningListModal
        ref={chatPlanningListModalRef}
        agencyJobId={agencyJobId}
        agencyJobPlanning={state.agencyJobPlanning}
        chatTrackAndTracingOfflineFormRef={chatTrackAndTracingOfflineFormRef}
        handleCancel={handleCancelPlanningModal}
        handleConfirmForm={handleConfirmForm}
        handleResetPress={handleResetPress}
        handleSelectListItem={handleSelectListItem}
        handleSelectListItemOffline={handleSelectListItemOffline}
        title={
          isCourier
            ? t('jobs.selectTheNextCurrentTrackingPoint', {
                defaultValue: 'Select the next current tracking point',
              })
            : title
        }
        subtitle={isCourier ? '' : subtitle}
      />
    </View>
  );
};
