import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  header: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: 'border-basic-color-4',
    borderBottomColor: 'border-basic-color-4',
    padding: 10,
    paddingHorizontal: 15,
    backgroundColor: 'background-basic-color-4',
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: 'border-basic-color-4',
    minHeight: 40,
    paddingHorizontal: 15,
  },
  rowEven: {
    backgroundColor: 'background-basic-color-2',
  },
  divider: {
    height: 1,
    backgroundColor: 'background-basic-color-4',
    width: '100%',
    marginTop: 20,
    marginBottom: 10,
  },

  card: {
    marginBottom: 5,
    backgroundColor: 'background-basic-color-2',
    borderRadius: 10,
    width: '100%',
  },
  cardInner: {
    padding: 10,
    paddingBottom: 15,
    paddingHorizontal: 15,
    borderRadius: 10,
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
  },
  cardInnerSelected: {
    borderLeftColor: 'border-primary-color-1',
  },
  cardStatus: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  width160: {
    width: 160,
  },
  minHeight40: {
    minHeight: 25,
  },
});
