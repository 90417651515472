import { getAuthorizationHeaders } from '../apollo/create-auth-link-with-headers';

export const generateHelloNextToken = async (restEndpointUrl: string): Promise<any | undefined> => {
  const authHeaders = await getAuthorizationHeaders();
  const response = await fetch(restEndpointUrl, {
    method: 'get',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
  });
  if (response) {
    if (response.status !== 200 && response.status !== 201) {
      return undefined;
    }
    return response.json();
  }
};
