import React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { PriojetCourierAvailabilitiesNavigator } from './priojet.courier-availabilities.navigator';
import { PriojetHomeNavigator } from './priojet.home.navigator';
import { PriojetProfileNavigator } from './priojet.profile.navigator';
import { AvailabilitiesIcon, HomeIcon, ProfileIcon } from '../components/icons.component';
import { PriojetHomeScreen } from '../screens/priojet/priojet.home.screen';

const BottomTabNavigator = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={(index: number) => navigation.navigate(state.routeNames[index])}
      >
        <BottomNavigationTab
          title={t('common.home', { defaultValue: 'Home' }).toUpperCase()}
          icon={HomeIcon}
        />
        <BottomNavigationTab
          title={t('common.availabilities', {
            defaultValue: 'Availabilities',
          }).toUpperCase()}
          icon={AvailabilitiesIcon}
        />
        <BottomNavigationTab
          title={t('common.Profile', { defaultValue: 'Profile' }).toUpperCase()}
          icon={ProfileIcon}
        />
      </BottomNavigation>
    </React.Fragment>
  );
};

export const PriojetNavigator = (props: any) => {
  return (
    <BottomTabNavigator.Navigator
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <BottomTabBar {...props} />}
    >
      <BottomTabNavigator.Screen
        name="PriojetHomeNavigator"
        component={PriojetHomeNavigator}
      />
      <BottomTabNavigator.Screen
        name="PriojetCourierAvailabilitiesNavigator"
        component={PriojetCourierAvailabilitiesNavigator}
      />
      <BottomTabNavigator.Screen
        name="PriojetProfileNavigator"
        component={PriojetProfileNavigator}
      />
    </BottomTabNavigator.Navigator>
  );
};
