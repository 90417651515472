import { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';

import { getApolloCacheInstance } from './apollo-cache';
import { getApolloCachePersistorInstance } from './apollo-cache-persistor';
import { createAuthLinkWithHeaders } from './create-auth-link-with-headers';
import { errorLink } from './error-link';
import { httpLink } from './http-link';
import { retryLink } from './retry-link';
import { scalarsLink } from './scalars-link';
import { LocalUserContext } from '../types';

export const createApolloClientPersistAsync = async (args?: {
  customHeaders?: object | undefined;
  userContext?: LocalUserContext | undefined;
}): Promise<{
  apolloClient: ApolloClient<NormalizedCacheObject>;
  apolloPersistor: CachePersistor<NormalizedCacheObject>;
}> => {
  const authLink = createAuthLinkWithHeaders(args?.userContext, args?.customHeaders);

  const cache = getApolloCacheInstance();
  const apolloPersistor = getApolloCachePersistorInstance(cache);
  await apolloPersistor.restore();

  const isDevelopmentAppEnv = process
    ? process.env.EXPO_APP_ENV === 'development' || process.env.NODE_ENV === 'development'
    : false;

  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    connectToDevTools: isDevelopmentAppEnv,
    link: scalarsLink
      .concat(authLink)
      .concat(errorLink)
      .concat(retryLink)
      .concat(httpLink) as ApolloLink,
    cache,
  });

  return { apolloClient, apolloPersistor };
};
