import Constants from 'expo-constants';
import * as Network from 'expo-network';

export const getIsBackendReachable = async (): Promise<boolean> => {
  try {
    const networkState = await Network.getNetworkStateAsync();
    if (!networkState.isInternetReachable) {
      return false;
    }

    const result = await Promise.race([
      await fetch(Constants.expoConfig?.extra?.priojetBackendHeartbeatEndpoint, {
        method: 'HEAD',
      }),
      new Promise((_, reject) =>
        setTimeout(
          () => reject(new Error('timeout')),
          Constants.expoConfig?.extra?.priojetBackendHeartbeatIntervalMs || 60000,
        ),
      ),
    ]);

    // @ts-ignore
    if (result && result.status && result.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.warn('Error while fetching backend priojetBackendHeartbeatEndpoint', e);
    return false;
  }
};
