import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    flex: 1,
    width: '100%',
    padding: 10,
  },
  relationshipType: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    padding: 3,
  },
  relationShipTypeIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    margin: 10,
  },
  relationshipTypeConfirmed: {
    backgroundColor: 'color-success-500',
  },
  relationshipTypeRequested: {
    backgroundColor: 'color-warning-500',
  },
  relationshipTypeDeclined: {
    backgroundColor: 'color-danger-500',
  },
  relationshipTypeUndefined: {
    backgroundColor: 'text-disabled-color',
  },
  jobDetailsView: {
    marginBottom: 10,
  },
  jobDetailsText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 18,
  },
  btnUpdate: {
    height: 40,
    width: 200,
  },
  padding10: {
    padding: 10,
  },
  flex1: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
  },
  alignContentBetween: {
    alignContent: 'space-between',
  },
  fontBold: {
    fontFamily: 'Lato_700Bold',
  },
  headerCheckBoxContainer: {
    flex: 1,
    flexShrink: 1,
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    maxWidth: '10%',
  },
  margin0: {
    margin: 0,
  },
  padding0: {
    padding: 0,
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsStretch: {
    alignItems: 'stretch',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  maxWidth100: {
    maxWidth: 100,
  },
  height100Percent: {
    height: '100%',
  },
  paddingVertical10: {
    paddingVertical: 10,
  },
  card: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
    paddingHorizontal: 0,
    marginTop: 10,
  },
  marginBottom5: {
    marginBottom: 5,
  },
  font13Upper: {
    textTransform: 'uppercase',
    fontSize: 13,
  },
  paddingBottom5: {
    paddingBottom: 5,
  },
  distanceContainer: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    alignContent: 'space-between',
    alignItems: 'stretch',
  },
  distanceText: {
    flex: 9,
    flexGrow: 1,
  },

  textInput: {
    flex: 1,
    verticalAlign: 'middle',
    color: 'text-basic-color',
    outlineStyle: 'none',
    backgroundColor: 'background-basic-color-2',
    borderRadius: 5,
  },
  resetIcon: {
    color: 'text-basic-color',
  },
  filterIconSize: {
    width: 26,
    height: 30,
  },
  filterIconColor: {
    color: 'text-basic-color',
  },
  filterButton: {
    backgroundColor: 'background-basic-color-2',
    height: 52,
    width: 52,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    borderRadius: 5,
    borderColor: 'border-basic-color-4',
    borderWidth: 1,
  },
});
