import React from 'react';

import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const CourierProfileTopNavigationBar = ({
  title,
  navigation,
  accessoryRight,
  onPressLeft,
}: {
  title: string;
  navigation: any;
  accessoryRight?: any;
  onPressLeft?: any;
}) => (
  <TopNavigationWithBackButton
    title={title}
    navigation={navigation}
    onPressLeft={
      onPressLeft
        ? onPressLeft
        : () =>
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierProfileScreen',
            })
    }
    accessoryRight={accessoryRight}
  />
);

export { CourierProfileTopNavigationBar };
