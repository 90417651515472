import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    left: 0,
    right: 0,
    backgroundColor: 'background-basic-color-1',
    paddingVertical: 5,
  },
  barStyle: {
    borderWidth: 1,
    borderColor: 'color-danger-600',
    backgroundColor: 'color-danger-600',
    borderRadius: 5,
    padding: 2,
    paddingHorizontal: 10,
    marginHorizontal: 6,
    marginTop: 5,
  },
  text: {
    color: 'white',
    padding: 2,
    paddingBottom: 3,
    textAlign: 'center',
    fontSize: 12,
  },
  countOutOfSync: {
    position: 'absolute',
    top: -2,
    right: -4,
    borderColor: 'color-danger-600',
    backgroundColor: 'color-danger-600',
    borderRadius: 20,
    minWidth: 20,
  },
  countOutOfSyncText: {
    fontFamily: 'Lato_900Black',
    color: 'white',
    fontSize: 11,
    padding: 4,
    paddingTop: 3,
    textAlign: 'center',
  },
});
