import React, { useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, View, RefreshControl } from 'react-native';

import { useQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { RouteProp, useNavigation, useRoute, useIsFocused } from '@react-navigation/native';
import { StyleService } from '@ui-kitten/components';
import { Tab, TabBar, Text } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { COURIER_JOB_FOR_COURIER_ORGANIZATION_QUERY } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { LoadingSpinner } from '../../components/common/loading-spinner.component';
import { CourierCourierJobComponent } from '../../components/courier/courier-jobs/courier.courier-job.component';
import { CourierCourierJobsCardItem } from '../../components/courier/courier.courier-jobs-card-item.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import { CourierJob } from '../../generated-graphql-types';
import { useAppState } from '../../hooks/useAppState';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { CourierHomeNavigatorParamList } from '../../navigation/app.navigator';
import { CourierCourierJobJourneyScreen } from '../common/CommonJobJourneyScreen';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export const CourierCourierJobScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const route = useRoute<RouteProp<CourierHomeNavigatorParamList, 'CourierCourierJobScreen'>>();
  const navigation: any = useNavigation();
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();
  const { appState } = useAppState();

  const refetchInterval = useRef<NodeJS.Timer | null>(null);

  const { previousScreenName, courierJobId } = route.params || {};

  const [activeTab, setActiveTab] = useState('AgencyCourierJobOverviewTab');
  const [courierJob, setCourierJob] = useState<CourierJob | undefined>();
  const [organizationId, setOrganizationId] = useState<string>(
    appUserContext.currentUserContext?.organization?.id || '',
  );
  const [refreshing, setRefreshing] = useState(false);

  const {
    error: courierJobCourierError,
    loading,
    refetch: courierJobCourierRefetch,
  } = useQuery(COURIER_JOB_FOR_COURIER_ORGANIZATION_QUERY, {
    variables: {
      courierOrganizationId: organizationId,
      courierJobId,
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    onCompleted: (data) => {
      if (data.courierJobForCourierOrganization) {
        setCourierJob(data.courierJobForCourierOrganization);
      }
    },
    skip: !organizationId,
  });

  const handleRefresh = async () => {
    setRefreshing(true);
    await courierJobCourierRefetch({
      variables: {
        courierOrganizationId: organizationId,
        courierJobId: route.params?.courierJobId as string,
      },
    });

    setRefreshing(false);
  };

  useEffect(() => {
    if (
      appUserContext.currentUserContext?.organizationType &&
      appUserContext.currentUserContext?.organization?.id &&
      organizationId !== appUserContext.currentUserContext?.organization?.id
    ) {
      setOrganizationId(appUserContext.currentUserContext.organization.id);
    }
  }, [
    appUserContext.currentUserContext?.organization?.id,
    appUserContext.currentUserContext?.organizationType,
    organizationId,
  ]);

  useEffect((): any => {
    if (courierJobId && courierJob && !refetchInterval.current) {
      refetchInterval.current = setInterval(() => {
        if (isFocused && appState === 'active') {
          courierJobCourierRefetch();
        }
      }, 30 * 1000);
    } else if (appState !== 'active') {
      clearInterval(refetchInterval.current as any);
      refetchInterval.current = null;
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierJob, courierJobId, appState, isFocused, refetchInterval]);

  const handleUpdateJob = (newAgencyJob: Partial<CourierJob>) => {
    setCourierJob((prev) => ({ ...prev, ...newAgencyJob }) as CourierJob);
  };

  const renderTopBar = ({ navigation: _navigation, state }: { navigation: any; state: any }) => (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => {
        _navigation.navigate(state.routeNames[index]);
        setActiveTab(state.routeNames[index]);
      }}
      style={globalStyle.height50}
    >
      <Tab
        title={t('jobs.overview', { defaultValue: 'Overview' }) as string}
        key="courierCourierJobOverviewTab"
      />
      <Tab
        title={t('jobs.details', { defaultValue: 'Details' }) as string}
        key="courierCourierJobDetailsTab"
      />
      <Tab
        title={t('jobs.journey', { defaultValue: 'Journey' }) as string}
        key="courierCourierJobJourneyTab"
      />
    </TabBar>
  );

  const renderContent = () => {
    if (courierJobCourierError) {
      return (
        <View style={[globalStyle.padding20, globalStyle.paddingTop50]}>
          <Text style={globalStyle.textAlignCenter}>
            Courier job not found or you do not have permissions to view.
          </Text>
          <Text style={[globalStyle.textAlignCenter, globalStyle.paddingTop10]}>
            Code:{' '}
            {(courierJobCourierError?.graphQLErrors?.[0].extensions?.internalCode as string) ||
              'Unknown internal code'}
          </Text>
        </View>
      );
    }

    if (loading && !courierJob) {
      return <LoadingSpinner />;
    }
    return (
      <Navigator
        tabBar={(props: any) => renderTopBar(props)}
        initialRouteName={
          previousScreenName.includes('CourierCourierJobsScreen')
            ? 'CourierCourierJobDetailsScreen'
            : 'CourierCourierJobOverviewScreen'
        }
        screenOptions={{ swipeEnabled: false }}
      >
        <Screen initialParams={{ ...(route.params || {}) }} name="CourierCourierJobOverviewScreen">
          {() => (
            <ScrollView
              style={themedStyles.flex1}
              refreshControl={<RefreshControl onRefresh={handleRefresh} refreshing={refreshing} />}
            >
              {!!courierJob && (
                <CourierCourierJobsCardItem
                  canViewDetails={false}
                  courierJob={courierJob as CourierJob}
                  refetch={courierJobCourierRefetch}
                  updateJob={handleUpdateJob}
                />
              )}
            </ScrollView>
          )}
        </Screen>
        <Screen initialParams={{ ...(route.params || {}) }} name="CourierCourierJobDetailsScreen">
          {(props: any) => (
            <CourierCourierJobComponent {...props} courierJob={courierJob as CourierJob} />
          )}
        </Screen>
        <Screen initialParams={{ ...(route.params || {}) }} name="CourierCourierJobJourneyScreen">
          {(props: any) => (
            <CourierCourierJobJourneyScreen
              {...props}
              active={activeTab === 'CourierCourierJobJourneyScreen'}
              agencyJob={courierJob?.agencyJob}
              job={courierJob?.job}
            />
          )}
        </Screen>
      </Navigator>
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t(
          `${courierJob?.job?.priojetJobIdentifier || '-'} / ${courierJob?.agencyJob?.externalJobReference || '-'}`,
        )}
        navigation={navigation}
        onPressLeft={() => {
          const params: any = route.params;
          if (params?.previousScreenName) {
            if (
              route.params.previousScreenName.includes(',') ||
              route.params.previousScreenName.includes('%2C')
            ) {
              let routes = [];
              if (route.params.previousScreenName.includes('%2C')) {
                routes = route.params.previousScreenName.split('%2C');
              } else {
                routes = route.params.previousScreenName.split(',');
              }
              let newPreviousScreenName = '';
              const previousScreenNames = routes.slice(0, -1);
              if (previousScreenNames.length) {
                newPreviousScreenName = previousScreenNames.join(',');
              }
              navigation.navigate(routes[routes.length - 1], {
                agencyJobId: params?.agencyJobId || '',
                courierJobId: params?.courierJobId || '',
                previousScreenName: newPreviousScreenName,
              });
            } else {
              navigation.navigate(route.params?.previousScreenName, {
                agencyJobId: params?.agencyJobId || '',
                courierJobId: params?.courierJobId || '',
              });
            }
          } else {
            navigation.navigate('AgencyHomeScreen');
          }
        }}
      />

      {renderContent()}
    </>
  );
};

const themedStyles = StyleService.create({
  flex1: { flex: 1 },
});
