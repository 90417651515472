import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import axios from 'axios';
import Constants from 'expo-constants';
import * as ExpoLocation from 'expo-location';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { getAuthorizationHeaders } from '../../../../../apollo/create-auth-link-with-headers';
import { GooglePlacesAutocompleteLocation } from '../../../../../components/common/google-places-autocomplete.component';
import { AgencyJobPlanningFormType } from '../../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../../hooks/useIsBackendReachable';
import { AddressGeometry } from '../../../../../types/google-maps.common.types';

const MAX_LENGTH = 200;

const timezoneDifference = new Date().getTimezoneOffset();

export const findCurrentLocationByCoordsAndTransform = async (location: {
  coords: { latitude: number; longitude: number };
  formattedAddress: string;
}) => {
  if ((location?.coords?.latitude && location?.coords?.longitude) || location.formattedAddress) {
    const authHeaders = await getAuthorizationHeaders();
    try {
      let url = `${Constants.expoConfig?.extra?.priojetBackendEndpoint}/location/reverse-geocode`;
      if (location?.coords?.latitude && location?.coords?.longitude) {
        url += `?latitude=${location?.coords?.latitude}&longitude=${location?.coords?.longitude}`;
      }
      if (location.formattedAddress) {
        if (location?.coords?.latitude && location?.coords?.longitude) {
          url += `&formattedAddress=${location.formattedAddress}`;
        } else {
          url += `?formattedAddress=${location.formattedAddress}`;
        }
      }
      const res = await axios(url, { headers: authHeaders });
      if (res.data && res.data.place_id && res.data.formatted_address) {
        return {
          locationText: res.data.formatted_address,
          googlePlacesAutocompleteLocation: {
            description: res.data.formatted_address,
            formattedAddress: res.data.formatted_address,
            geometry: res.data.geometry,
            placeId: res.data.place_id,
            language: 'en',
            locationTypes: res.data.location_type ? [res.data.location_type] : [],
          },
        };
      }
    } catch (e) {
      return {
        locationText: location.formattedAddress || '',
        googlePlacesAutocompleteLocation: {
          geometry: {
            location: {
              lat: location?.coords?.latitude,
              lng: location?.coords?.longitude,
            },
          },
        },
      };
    }
  }
  return {
    locationText: location.formattedAddress || '',
    googlePlacesAutocompleteLocation: undefined,
  };
};

export const useHook = ({ handleCancel, handleConfirm, ref }: any) => {
  const isBackendReachable = useIsBackendReachable();
  const { t } = useTranslation();

  const googlePlaceRef = useRef<any>(null);

  const [actualDate, setActualDate] = useState<DateTime | undefined>(undefined);
  const [actualTime, setActualTime] = useState<string>('');
  const [actualDateTimeError, setActualDateTimeError] = useState('');
  const [files, setFiles] = useState<any>([]);
  const [note, setNote] = useState('');
  const [agencyJobPlanningItemId, setAgencyJobPlanningItemId] = useState('');
  const [formType, setFormType] = useState(AgencyJobPlanningFormType.GENERIC_FORM_A);
  const [isLegItem, setIsLegItem] = useState(false);
  const [isLoadingCurrentLocation, setIsLoadingCurrentLocation] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [locationText, setLocationText] = useState('');
  const [locationTextError, setLocationTextError] = useState('');
  const [locationSelectedText, setLocationSelectedText] = useState('');
  const [googlePlacesAutocompleteLocation, setGooglePlacesAutocompleteLocation] = useState<
    GooglePlacesAutocompleteLocation | undefined
  >(undefined);
  const [targetDateTime, setTargetDateTime] = useState<DateTime | undefined>(undefined);
  const [visible, setVisible] = useState(false);

  const getCurrentLocation = async (
    coordsProp?: { latitude: number; longitude: number },
    formattedAddress?: string,
  ) => {
    let coords = coordsProp;
    if (!coords && !formattedAddress) {
      let permissions = await ExpoLocation.getForegroundPermissionsAsync();
      if (!permissions.granted && permissions.canAskAgain) {
        permissions = await ExpoLocation.requestForegroundPermissionsAsync();
      }
      if (permissions.granted) {
        const location = await ExpoLocation.getCurrentPositionAsync({
          accuracy: ExpoLocation.LocationAccuracy.Lowest,
        });
        coords = location.coords;
      }
    }
    setIsLoadingCurrentLocation(true);
    const locationData = await findCurrentLocationByCoordsAndTransform({
      coords: coords as any,
      formattedAddress: formattedAddress || '',
    });
    setIsLoadingCurrentLocation(false);
    if (!locationText) {
      if (!locationData.googlePlacesAutocompleteLocation?.placeId) {
        googlePlaceRef.current?.updateText(locationData.locationText);
        setLocationText(locationData.locationText);
      } else {
        setLocationSelectedText(locationData.locationText);
      }
      setGooglePlacesAutocompleteLocation(locationData?.googlePlacesAutocompleteLocation as any);
    }
  };

  useEffect(() => {
    getCurrentLocation;
  }, []);

  const handleSetData = async (data: any) => {
    getCurrentLocation(
      data?.location?.geometry?.location?.lat
        ? ({
            latitude: data.location.geometry.location.lat,
            longitude: data.location.geometry.location.lng,
          } as any)
        : null,
      data?.location?.formattedAddress || '',
    );
    if (data) {
      let _targetDateTime: any = data.targetDateTime;
      if (typeof _targetDateTime === 'string') {
        _targetDateTime = DateTime.fromJSDate(new Date(_targetDateTime));
      } else if (_targetDateTime?.toJSDate) {
        _targetDateTime = DateTime.fromJSDate(_targetDateTime.toJSDate());
      }

      let _actualDateTime: any = data.actualDateTime;
      if (typeof _actualDateTime === 'string') {
        _actualDateTime = DateTime.fromJSDate(new Date(_actualDateTime));
      } else if (_actualDateTime?.toJSDate) {
        _actualDateTime = DateTime.fromJSDate(_actualDateTime.toJSDate());
      }
      setActualDateTimeError('');
      setLocationTextError('');
      setAgencyJobPlanningItemId(data.id);
      setIsLegItem(data.isLegItem);
      setActualDate(
        _actualDateTime
          ? _actualDateTime?.setZone('utc')
          : DateTime.now().setZone('utc').minus({ minutes: timezoneDifference }),
      );
      setActualTime(
        _actualDateTime
          ? _actualDateTime?.setZone('utc').toFormat('HH:mm')
          : DateTime.now().setZone('utc').minus({ minutes: timezoneDifference }).toFormat('HH:mm'),
      );
      setFiles(data.files || []);
      setFormType(data.formType);
      setNote(data.note || '');
      setGooglePlacesAutocompleteLocation(data.location || undefined);
      setTargetDateTime(_targetDateTime ? _targetDateTime?.setZone('utc') : undefined);
    }
  };

  const toggleVisibility = (value: boolean) => {
    if (!value) {
      setActualDate(undefined);
      setActualTime('');
      setFiles([]);
      setNote('');
      setLocationText('');
      setActualDateTimeError('');
      setLocationTextError('');
      setLocationSelectedText('');
      setGooglePlacesAutocompleteLocation(undefined);
    }
    setVisible(value);
  };

  useImperativeHandle(ref, () => ({
    setData: handleSetData,
    toggleVisibility,
  }));

  const handleDateChange = (v: Date) => {
    if (actualDateTimeError) {
      setActualDateTimeError('');
    }
    setActualDate(DateTime.fromJSDate(v));
  };

  const handleTimeChange = (v: string) => {
    if (actualDateTimeError) {
      setActualDateTimeError('');
    }
    setActualTime(v);
  };

  const handleNoteChange = (val: string) => {
    if (val.length > MAX_LENGTH) {
      setNote(val.slice(0, MAX_LENGTH));
    } else {
      setNote(val);
    }
  };

  const handleGooglePlacesAutocompleteOnChangeText = (text: string) => {
    if (locationTextError && text?.length) {
      setLocationTextError('');
    }
    setLocationText(text);
  };

  const handleGooglePlacesAutocompleteOnPress = (
    v: GooglePlacesAutocompleteLocation | undefined,
  ) => {
    if (locationTextError) {
      setLocationTextError('');
    }
    setGooglePlacesAutocompleteLocation(v);
    if (v?.formattedAddress) {
      setLocationSelectedText(v.formattedAddress);
    }
  };

  const handleSelectImage = (asset: any) => {
    setFiles((prev: any) => [...prev, asset]);
  };

  const handleSelectPDF = (asset: any) => {
    setFiles((prev: any) => [...prev, asset]);
  };

  const handleRemoveFileItem = (_item: any, index: number) => () => {
    setFiles((prevState: any) => prevState.filter((__item: any, i: number) => index !== i));
  };

  const handleClose = () => {
    handleCancel();
  };

  const handleSave = async () => {
    let location:
      | GooglePlacesAutocompleteLocation
      | {
          geometry: AddressGeometry | undefined;
          formattedAddress: string;
          placeId: string | undefined;
        }
      | undefined = googlePlacesAutocompleteLocation;
    if (!isBackendReachable && locationText) {
      location = {
        formattedAddress:
          googlePlacesAutocompleteLocation?.placeId &&
          googlePlacesAutocompleteLocation?.formattedAddress
            ? googlePlacesAutocompleteLocation?.formattedAddress
            : locationText,
        geometry: googlePlacesAutocompleteLocation?.geometry,
        placeId: googlePlacesAutocompleteLocation?.placeId,
      };
    }

    let error = false;
    if (!actualDate || !actualTime) {
      setActualDateTimeError(t('common.requiredField', { defaultValue: 'Required field' }));
      error = true;
    }
    if (
      !location?.geometry?.location.lat &&
      !location?.geometry?.location.lat &&
      !location?.formattedAddress
    ) {
      setLocationTextError(t('common.requiredField', { defaultValue: 'Required field' }));
      error = true;
    }

    if (!error) {
      setIsSaving(true);
      const filesToUpload: any[] = [];
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.base64) {
            filesToUpload.push({
              base64: file.base64,
              height: file.height,
              mimeType: file.mimeType || file.fileType,
              name: file.name,
              width: file.width,
            });
          }
        }
      }
      try {
        const res = await handleConfirm({
          actualDateTime: actualDate
            ? actualDate?.setZone('utc').set({
                hour: parseInt(actualTime.split(':')[0], 10),
                minute: parseInt(actualTime.split(':')[1], 10),
              })
            : undefined,
          agencyJobPlanningItemId,
          files: filesToUpload,
          isLegItem,
          note,
          location,
        });
        if (res) {
          setActualDate(undefined);
          setActualTime('');
          setFiles([]);
          setNote('');
          setLocationText('');
          setActualDateTimeError('');
          setLocationTextError('');
          setLocationSelectedText('');
          setGooglePlacesAutocompleteLocation(undefined);
        }
        // eslint-disable-next-line no-empty
      } catch {}
      setIsSaving(false);
    }
  };

  return {
    actualDate,
    actualTime,
    actualDateTimeError,
    googlePlaceRef,
    handleClose,
    handleDateChange,
    handleGooglePlacesAutocompleteOnChangeText,
    handleGooglePlacesAutocompleteOnPress,
    handleNoteChange,
    handleRemoveFileItem,
    handleSave,
    handleSelectImage,
    handleSelectPDF,
    handleTimeChange,
    files,
    formType,
    isLoadingCurrentLocation,
    isSaving,
    locationText,
    locationTextError,
    locationSelectedText,
    note,
    targetDateTime,
    visible,
  };
};
