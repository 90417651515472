import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { MUTATION_ORGANIZATION_SUBSCRIPTION_PLAN_CANCEL } from '../../../../apollo/graphql-mutations';
import { QUERY_ORGANIZATION_SUBSCRIPTION_PLANS } from '../../../../apollo/graphql-queries';
import { ConfirmModalContext } from '../../../../components/common/modal/ConfirmModal';
import AppUserContext from '../../../../contexts/AppUserContext';
import {
  OrganizationSubscriptionPlan,
  SubscriptionPlanType,
} from '../../../../generated-graphql-types';
// import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  // const isBackendReachable = useIsBackendReachable();

  const [checkoutCompletedPlanName, setCheckoutCompletedPlanName] = useState<string>('');
  const [checkoutOpened, setCheckoutOpened] = useState(false);
  const [currentSubscriptionPlanId, setCurrentSubscriptionPlanId] = useState('');
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const { data: organizationData, refetch: refetchOrganizationSubscriptionPlans } = useQuery(
    QUERY_ORGANIZATION_SUBSCRIPTION_PLANS,
    {
      variables: { id: appUserContext.currentUserContext?.organization?.id as string },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      onCompleted: (data) => {
        +setCurrentSubscriptionPlanId(data?.organization.organizationSubscriptionPlan.id);
      },
    },
  );
  const [organizationSubscriptionPlanCancel] = useMutation(
    MUTATION_ORGANIZATION_SUBSCRIPTION_PLAN_CANCEL,
  );

  const organizationSubscriptionPlan = useMemo((): OrganizationSubscriptionPlan | null => {
    if (organizationData?.organization.organizationSubscriptionPlan) {
      return organizationData?.organization.organizationSubscriptionPlan;
    }
    return null;
  }, [organizationData]);

  const isUpgradeButtonAvailable = useMemo(() => {
    if (organizationSubscriptionPlan?.subscriptionPlan.value !== SubscriptionPlanType.COURIER02) {
      return true;
    }
    return false;
  }, [organizationSubscriptionPlan]);

  const handleCancelPress = (item: OrganizationSubscriptionPlan) => async () => {
    const name = t(item.subscriptionPlan.planName.key, {
      defaultValue: item.subscriptionPlan.planName.defaultValue,
    });
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.cancel', { defaultValue: 'Delete' }),
      cancelButtonText: t('common.close', { defaultValue: 'Close' }),
      title: t('common.cancelSubscription', { defaultValue: 'Cancel subscription' }),
      text: t('common.cancelSubscriptionModalText', {
        defaultValue: 'Are you sure want to cancel subscription "{{name}}"?',
        name,
      }),
      onConfirmPress: async () => {
        try {
          await organizationSubscriptionPlanCancel({ variables: { data: { id: item.id } } });
          await refetchOrganizationSubscriptionPlans();
        } catch (e) {
          return false;
        }
        return true;
      },
      onCancelPress: async () => {
        return false;
      },
      visible: true,
    });
  };

  const handleToggleUpgradeModalPress = (value: boolean) => () => {
    setUpgradeModalVisible(value);
  };

  const handleBookSuccess = (name: string) => {
    setCheckoutCompletedPlanName(name);
  };

  const refetchNewSubscriptionPlan = async (attempt = 0) => {
    setTimeout(async () => {
      try {
        const res = await refetchOrganizationSubscriptionPlans();

        if (
          res?.data?.organization?.organizationSubscriptionPlan?.id !== currentSubscriptionPlanId
        ) {
          appUserContext.refetchCurrentUserContext();
          setCurrentSubscriptionPlanId(res?.data?.organization?.organizationSubscriptionPlan?.id);
        } else if (attempt < 5) {
          refetchNewSubscriptionPlan(attempt + 1);
        }
      } catch {
        refetchNewSubscriptionPlan(attempt + 1);
      }
    }, 2000);
  };

  const handleBookToggle = (value: boolean) => {
    setCheckoutOpened(value);
  };

  useEffect(() => {
    if (checkoutCompletedPlanName && !checkoutOpened) {
      showMessage({
        message: t('common.success', { defaultValue: 'Success' }),
        description: t('common.bookAdditionalSeatsSuccessfulDescription', {
          defaultValue: `Thank you, you have successfully booked ${checkoutCompletedPlanName}`,
        }) as string,
        type: 'success',
        autoHide: true,
        hideOnPress: true,
        duration: 8000,
      });
      setCheckoutCompletedPlanName('');
      refetchNewSubscriptionPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutOpened, checkoutCompletedPlanName, t]);

  return {
    handleBookSuccess,
    handleBookToggle,
    handleCancelPress,
    handleToggleUpgradeModalPress,
    isUpgradeButtonAvailable,
    organizationSubscriptionPlan,
    refetchOrganizationSubscriptionPlans,
    upgradeModalVisible,
  };
};
