import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';

import { Icon, Popover, Text } from '@ui-kitten/components';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import * as Localization from 'expo-localization';
import TimeAgo from 'javascript-time-ago';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';

import { globalStyle } from '../../common/style';
import { convertDateToLuxon } from '../../utils/date-time.util';

export const TimeAgoWithTimeZonesPopover = ({
  i18nKey,
  date: dateProp,
  targetTimeZoneIdentifier,
  targetLocationName,
  popoverContentTitle,
  hitSlop,
}: {
  i18nKey: string;
  date: DateTime;
  targetTimeZoneIdentifier?: string | undefined;
  targetLocationName: string;
  popoverContentTitle: string;
  hitSlop?: undefined | { top: number; bottom: number; right: number; left: number };
}) => {
  const [t, i18n] = useTranslation();
  const [visible, setVisible] = React.useState<boolean>(false);

  let dateTime = useMemo(() => convertDateToLuxon(dateProp), [dateProp]);

  const togglePopover = (): void => {
    setVisible(!visible);
  };

  if (hitSlop === undefined) {
    hitSlop = { top: 5, bottom: 5, right: 0, left: 0 };
  }

  let timeAgoText: string | undefined;
  try {
    const timeAgo = new TimeAgo(i18n.language);
    timeAgoText = dateTime ? timeAgo.format(dateTime?.toMillis()) : '';
  } catch (e) {
    console.warn('could not create TimeAgo object', e);
  }

  const size = 16;

  if (dateTime && dateTime >= DateTime.now()) {
    i18nKey += 'Future';
  } else {
    i18nKey += 'Past';
  }

  const renderPopoverAnchor = (): JSX.Element => (
    <TouchableWithoutFeedback hitSlop={hitSlop} onPress={() => setVisible(!visible)}>
      <Text selectable={true}>
        <Trans
          i18nKey={i18nKey}
          style={[globalStyle.flex1, globalStyle.alignItemsBaseline, globalStyle.flexRow]}
          // This needs to be a string as the type for Property.Color could not be determined.
          values={{ timeAgoText }}
          defaultValue="{{timeAgoText}} <TimeAgoIcon></TimeAgoIcon>."
          components={{
            TimeAgoIcon: (
              <Icon
                name="clock-outline"
                height={size}
                width={size}
                fill="#ccc"
                style={[globalStyle.alignSelfBaseline, globalStyle.alignContentCenter]}
              />
            ),
          }}
        />
      </Text>
    </TouchableWithoutFeedback>
  );

  return (
    <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsBaseline]}>
      <Popover
        placement="bottom"
        contentContainerStyle={{
          marginTop: Platform.OS === 'web' ? 10 : -40,
          maxWidth: 200,
        }}
        visible={visible}
        anchor={renderPopoverAnchor}
        onBackdropPress={togglePopover}
        fullWidth={false}
      >
        <View style={globalStyle.padding10}>
          <Text
            selectable={true}
            style={[
              globalStyle.fontLatoBold,
              globalStyle.textAlignCenter,
              globalStyle.paddingVertical5,
            ]}
          >
            {popoverContentTitle}
          </Text>
          <Text selectable={true}>
            <Text selectable={true} style={globalStyle.fontLatoBold}>
              {t('common.yourLocalTime', { defaultValue: 'Your local time:' }) as string}{' '}
            </Text>
            {dateTime
              ? dateTime
                  ?.setZone(Localization.timezone)
                  .toLocaleString(DateTime.DATETIME_HUGE, { locale: i18n.language })
              : ''}
          </Text>
          {targetTimeZoneIdentifier ? (
            <Text selectable={true}>
              <Text selectable={true} style={globalStyle.fontLatoBold}>
                {
                  t('common.theTimeInLocation', {
                    defaultValue: 'The time in {{locationName}}:',
                    locationName: targetLocationName,
                  }) as string
                }{' '}
              </Text>
              {dateTime
                ? dateTime
                    ?.setZone(targetTimeZoneIdentifier)
                    .toLocaleString(DateTime.DATETIME_HUGE, { locale: i18n.language })
                : ''}
            </Text>
          ) : (
            <></>
          )}
          <Text selectable={true}>
            <Text selectable={true} style={globalStyle.fontLatoBold}>
              {t('common.utcGmtTime', { defaultValue: 'UTC / GMT time:' }) as string}{' '}
            </Text>
            {dateTime
              ? dateTime
                  ?.setZone('utc')
                  .toLocaleString(DateTime.DATETIME_HUGE, { locale: i18n.language })
              : ''}
          </Text>
        </View>
      </Popover>
    </View>
  );
};
