import React from 'react';
import { View } from 'react-native';

import { Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';


import { CourierProfileTopNavigationBar } from '../../components/courier/courier-profile-top-navigation-bar.component';

export const CourierSettingsScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <CourierProfileTopNavigationBar title="Settings" navigation={navigation} />
      <View style={{ padding: 10 }}>
        <Text selectable={true}>
          {
            t('common.screenNameIsComingSoon', {
              defaultValue: '{{screenName}} is coming soon',
              screenName: 'Courier Settings',
            }) as string
          }
        </Text>
      </View>
    </>
  );
};
