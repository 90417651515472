import React from 'react';

import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const PriojetProfileTopNavigationBar = ({
  title,
  navigation,
  accessoryRight,
}: {
  title: string;
  navigation: any;
  accessoryRight?: any;
}) => (
  <TopNavigationWithBackButton
    title={title}
    navigation={navigation}
    onPressLeft={() =>
      navigation.navigate('PriojetProfileNavigator', {
        screen: 'PriojetProfileScreen',
      })
    }
    accessoryRight={accessoryRight}
  />
);

export { PriojetProfileTopNavigationBar };
