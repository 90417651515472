import { useMemo, useState } from 'react';

import { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated';

import useDimensions from '../../../../hooks/useDimensions';

export const PLAN_PERIOD_ITEMS = [
  { label: 'Monthly', value: 'month' },
  { label: 'Yearly', value: 'year' },
];

export const calculatePrice = (value: number) => {
  let result = value.toString();
  if (result.length > 3) {
    const splitted = [result.slice(0, result.length - 3), result.slice(result.length - 3)];
    result = splitted.join('.');
  }
  return `${result},00`;
};

export const useHook = ({ onSelect, onSelectPeriod }: any) => {
  const { dimensions } = useDimensions();
  const offset = useSharedValue(0);

  const [height, setHeight] = useState<number | undefined>(undefined);
  const [selectedPeriod, setSelectedPeriod] = useState<{ label: string; value: string }>(
    PLAN_PERIOD_ITEMS[0],
  );

  const { width } = dimensions.window;

  const periodItemWidth = useMemo(() => {
    const maxWidth = width - 40 - 4;
    let _width = maxWidth / PLAN_PERIOD_ITEMS.length;
    if (_width > 200) {
      _width = 200;
    }
    return _width;
  }, [width]);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offset.value * periodItemWidth }],
    };
  });

  const handleSelect = (item: any, index: number) => () => {
    onSelect(item, index);
  };

  const measureView = (event: any) => {
    if (event.nativeEvent.layout.height) {
      if (!height || height < event.nativeEvent.layout.height) {
        setHeight(event.nativeEvent.layout.height);
      }
    }
  };

  const handleSelectItem = (value: any, index: number) => () => {
    onSelectPeriod(value);
    setSelectedPeriod(value);
    offset.value = withTiming(index);
  };
  return {
    animatedStyles,
    handleSelect,
    handleSelectItem,
    measureView,
    periodItemWidth,
    selectedPeriod,
  };
};
