import React, { useContext, useMemo, useState } from 'react';
import { RefreshControl, RefreshControlProps, ScrollView, View, Platform } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../common/style';
import { CourierAvailabilitiesCardItem } from '../../../components/common/courier-availabilities/courier-availabilities-card-item.component';
import { CourierAvailabilitiesList } from '../../../components/common/courier-availabilities/courier-availabilities-list.component';
import {
  MapViewCoordinate,
  MemoizedMapViewWithMarker,
} from '../../../components/common/map-view-with-marker.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { Availability, SubscriptionFeatureType } from '../../../generated-graphql-types';
import { OrganizationType } from '../../../graphql-types';
import { openUrl } from '../../../hooks/useOpenLink';
import { LocalUserContext } from '../../../types';
import { SubscriptionPermissions } from '../SubscriptionPermissions';

const CommonCourierAvailabilities = ({
  isLoading,
  availabilities,
  refetch,
  showCreateAvailabilityLayer,
}: {
  isLoading: boolean;
  availabilities: Partial<Availability>[];
  refetch: RefreshControlProps['onRefresh'];
  showCreateAvailabilityLayer: boolean;
}) => {
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const appUserContext = useContext(AppUserContext);
  const styles = useStyleSheet(themedStyle);

  const [mapViewRegion, setMapViewRegion] = useState<MapViewCoordinate | undefined>();

  const mapViewCoordinates: MapViewCoordinate[] = useMemo(() => {
    const _mapViewCoordinates: MapViewCoordinate[] = [];
    if (availabilities?.length) {
      availabilities.forEach((availability) => {
        if (
          appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER &&
          availability.isAdHocAvailability &&
          !availability.endTime &&
          availability.lastDeviceLocationWithLocationForUser?.location?.locationGeometry.location
        ) {
          _mapViewCoordinates.push({
            latitude: availability.lastDeviceLocationWithLocationForUser?.location?.locationGeometry
              .location.lat as number,
            longitude: availability.lastDeviceLocationWithLocationForUser?.location
              ?.locationGeometry.location.lng as number,
          });
        } else if (availability.lastLocation?.locationGeometry.location) {
          _mapViewCoordinates.push({
            latitude: availability.lastLocation?.locationGeometry.location.lat as number,
            longitude: availability.lastLocation?.locationGeometry.location.lng as number,
          });
        }
      });
    }
    return _mapViewCoordinates;
  }, [availabilities, appUserContext.currentUserContext?.organizationType]);

  const showScheduleAvailability = useMemo(
    () =>
      appUserContext.currentUserContext?.organization?.organizationType ===
        OrganizationType.COURIER &&
      showCreateAvailabilityLayer &&
      mapViewCoordinates &&
      availabilities?.length &&
      !availabilities.filter((a: Partial<Availability>) => !!a && !a.isAdHocAvailability)?.length,
    [
      appUserContext.currentUserContext?.organization?.organizationType,
      availabilities,
      mapViewCoordinates,
      showCreateAvailabilityLayer,
    ],
  );

  const renderCreateAvailabilityScreen = (): React.ReactElement => (
    <SubscriptionPermissions
      warningContent={{
        confirmButtonText:
          Platform.OS === 'web'
            ? t('common.courierAccountSettings', { defaultValue: 'Courier Account Settings' })
            : t('common.visitPriojetCom', { defaultValue: 'Visit https://app.priojet.com/' }),
        title: '',
        text:
          Platform.OS === 'web'
            ? t('subscription.thisRunwayIsCloseForYouWillBeAvailableIfYouHaveOBCBasicPlusWeb', {
                defaultValue:
                  'This runway is closed for you. This will be available if you have OBC Basic Plus. You can fly to the Courier Account Settings page from the Profile menu to upgrade.',
              })
            : t('subscription.thisRunwayIsCloseForYouWillBeAvailableIfYouHaveOBCBasicPlusMobile', {
                defaultValue:
                  'This runway is closed for you. Fly to https://app.priojet.com for more information.',
              }),
        onConfirmPress: () => {
          if (Platform.OS === 'web') {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CommonCourierAccountSettingsScreen',
            });
          } else {
            openUrl('https://app.priojet.com');
          }
        },
      }}
      feature={SubscriptionFeatureType.COURIER_AVAILABILITY}
      warningType="modal"
    >
      {({ checkPermissions, handleShowWarning }: any) => (
        <Button
          appearance="outline"
          onPress={async () => {
            const hasPermission = await checkPermissions();
            if (!hasPermission) {
              handleShowWarning();
            } else {
              navigation.navigate('CourierCreateAvailabilityScreen');
            }
          }}
        >
          Schedule an Availability
        </Button>
      )}
    </SubscriptionPermissions>
  );

  if (showScheduleAvailability) {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isLoading}
            title="Loading Courier Availabilities..."
          />
        }
      >
        <View style={styles.padding20}>
          {renderCreateAvailabilityScreen()}

          <Text selectable={true} style={styles.paddingTop20}>
            By pressing the{' '}
            <Text selectable={true} style={styles.bold}>
              + Button
            </Text>{' '}
            or the button above, you can create a scheduled Availability. A scheduled Availability
            describes a specific time frame when you are available at a certain location in the
            future.
          </Text>
        </View>
        {!!availabilities?.length && (
          <>
            {!!mapViewCoordinates && (
              <View style={globalStyle.marginHorizontal10}>
                <MemoizedMapViewWithMarker
                  height={150}
                  mapViewCoordinates={mapViewCoordinates}
                  mapViewRegion={mapViewRegion}
                />
              </View>
            )}
            <View style={globalStyle.paddingHorizontal10}>
              <CourierAvailabilitiesCardItem
                availability={availabilities[0]}
                currentUserContext={appUserContext.currentUserContext as LocalUserContext}
              />
            </View>
          </>
        )}
      </ScrollView>
    );
  }

  return (
    <View style={styles.container}>
      {!!mapViewCoordinates && (
        <View style={[globalStyle.marginHorizontal10, globalStyle.marginTop20]}>
          <MemoizedMapViewWithMarker
            height={150}
            mapViewCoordinates={mapViewCoordinates}
            mapViewRegion={mapViewRegion}
          />
        </View>
      )}
      <CourierAvailabilitiesList
        isLoading={isLoading}
        refetch={refetch}
        availabilities={availabilities}
        mapViewRegion={mapViewRegion}
        setMapViewRegion={setMapViewRegion}
        currentUserContext={appUserContext.currentUserContext as LocalUserContext}
      />
    </View>
  );
};

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  padding20: {
    padding: 20,
  },
  paddingTop20: {
    paddingTop: 20,
  },
  bold: {
    fontFamily: 'Lato_700Bold',
  },
});

export { CommonCourierAvailabilities as CourierAvailabilities };
