import React from 'react';
import { View } from 'react-native';

import { useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { FormTextInputTopLabel } from '../../../../components/common/form/form-text-input-top-label.component';
import { ThemedGooglePlacesAutocomplete } from '../../../../components/common/google-places-autocomplete.component';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';

export const OBCEmergencyContactEdit = ({
  fieldProps,
  setFieldValue,
  values,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.emergencyContact', {
        defaultValue: 'Emergency Contact',
      }).toUpperCase()}
      Content={
        <>
          <FormTextInputTopLabel
            label={t('courierUserProfile.fullName', {
              defaultValue: 'Full Name',
            })}
            placeholder={
              t('courierUserProfile.fullName', {
                defaultValue: 'Full Name',
              }) as string
            }
            {...fieldProps('emergencyContactName')}
          />
          <FormTextInputTopLabel
            label={t('courierUserProfile.relationship', {
              defaultValue: 'Relationship',
            })}
            placeholder={
              t('courierUserProfile.relationship', {
                defaultValue: 'Relationship',
              }) as string
            }
            {...fieldProps('emergencyContactRelationship')}
          />
          <FormTextInputTopLabel
            label={t('courierUserProfile.emailAddress', {
              defaultValue: 'Email Address',
            })}
            placeholder={
              t('courierUserProfile.emailAddress', {
                defaultValue: 'Email Address',
              }) as string
            }
            {...fieldProps('emergencyContactEmail')}
          />
          <FormTextInputTopLabel
            label={
              t('courierUserProfile.contactNumber', {
                defaultValue: 'Contact Number',
              }) as string
            }
            placeholder={
              t('courierUserProfile.contactNumber', {
                defaultValue: 'Contact Number',
              }) as string
            }
            {...fieldProps('emergencyContactPhone')}
          />
          <View style={styles.emergencyAddressContainer}>
            <ThemedGooglePlacesAutocomplete
              onPress={(googlePlacesAutocompleteLocation) => {
                setFieldValue(
                  'emergencyContactAddress',
                  googlePlacesAutocompleteLocation?.description,
                );
              }}
              language="en"
              placeholderText={values.emergencyContactAddress}
              currentLocationText={
                t('courierUserProfile.address', {
                  defaultValue: 'Address',
                }) as string
              }
              label={
                t('courierUserProfile.address', {
                  defaultValue: 'Address',
                }) as string
              }
              onChangeText={(description) => {
                setFieldValue('emergencyContactAddress', description);
              }}
              textInputProps={{ value: values.emergencyContactAddress || '' }}
              {...fieldProps('emergencyContactAddress')}
            />
          </View>
        </>
      }
      style={styles.contentBox}
    />
  );
};

const arePropsEqual = (prev: any, next: any) => {
  const keys: any[] = [
    'emergencyContactName',
    'emergencyContactRelationship',
    'emergencyContactEmail',
    'emergencyContactPhone',
    'emergencyContactAddress',
  ];
  if (keys.some((key) => next.values[key] !== prev.values[key])) {
    return false;
  }
  return true;
};

export default React.memo(OBCEmergencyContactEdit, arePropsEqual);
