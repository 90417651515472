import { useImperativeHandle, useMemo, useState } from 'react';

import { DateTime } from 'luxon';

import { AgencyJobPlanningFile } from '../../../../generated-graphql-types';

export const useHook = ({ ref, handleCancel, handleConfirm }: any) => {
  const [dataChangedConfirmationModalVisible, setDataChangedConfirmationModalVisible] =
    useState(false);
  const [flightCarrierDesignation, setFlightCarrierDesignation] = useState('');
  const [errorFlightNumber, setErrorFlightNumber] = useState<string>('');
  const [errorDepartureDate, setErrorDepartureDate] = useState<string>('');
  const [errorFlightCarrierDesignation, setErrorFlightCarrierDesignation] = useState<string>('');
  const [flightNumber, setFlightNumber] = useState('');
  const [flightTickets, setFlightTickets] = useState<any>([]);
  const [departureDate, setDepartureDate] = useState<DateTime | undefined>(undefined);
  const [departureTime, setDepartureTime] = useState<string>('');
  const [initialData, setInitialData] = useState<{
    flightCarrierDesignation: string;
    flightNumber: string;
    departureDate: DateTime | undefined;
    departureTime: string;
  }>({
    flightCarrierDesignation: '',
    flightNumber: '',
    departureDate: undefined,
    departureTime: '',
  });

  const [deleteFlightTicketSelected, setDeleteFlightTicketSelected] =
    useState<AgencyJobPlanningFile | null>(null);
  const [flightTicketDeleteModalVisible, setFlightTicketDeleteModalVisible] = useState(false);
  const [flightTicketIdsToDelete, setFlightTicketIdsToDelete] = useState<string[]>([]);

  const handleSelectImage = (asset: any) => {
    setFlightTickets((prev: any) => [...prev, asset]);
  };

  const handleSelectPDF = (asset: any) => {
    setFlightTickets((prev: any) => [...prev, asset]);
  };

  const handleSetData = (data: any) => {
    setFlightCarrierDesignation(data.flightCarrierDesignation || '');
    setFlightNumber(data.flightNumber || '');
    setDepartureDate(data.departureDate || undefined);
    setDepartureTime(data.departureDate ? data.departureDate.toFormat('HH:mm') : '');
    setInitialData({
      flightCarrierDesignation: data.flightCarrierDesignation || '',
      flightNumber: data.flightNumber || '',
      departureDate: data.departureDate || undefined,
      departureTime: data.departureDate ? data.departureDate.toFormat('HH:mm') : '',
    });
    setFlightTickets(data.flightTickets || []);
  };

  useImperativeHandle(ref, () => ({
    setData: handleSetData,
  }));

  const handleCarrierDesignationChange = (v: string) => {
    setFlightCarrierDesignation(v);
    if (errorFlightCarrierDesignation) {
      setErrorFlightCarrierDesignation('');
    }
  };

  const handleFlightNumberChange = (v: string) => {
    setFlightNumber(v);
    if (errorFlightNumber) {
      setErrorFlightNumber('');
    }
  };

  const handleDepartureDateChange = (v: Date) => {
    setDepartureDate(DateTime.fromJSDate(v));
    if (errorDepartureDate) {
      setErrorDepartureDate('');
    }
  };

  const handleDepartureTimeChange = (v: string) => {
    setDepartureTime(v);
    if (errorDepartureDate && departureDate) {
      setErrorDepartureDate('');
    }
  };

  const handleRemoveFlightTicket = (item: any, index: number) => () => {
    if (item.id) {
      setDeleteFlightTicketSelected(item);
      setFlightTicketDeleteModalVisible(true);
    } else {
      setFlightTickets((prevState: any) =>
        prevState.filter((_item: any, i: number) => index !== i),
      );
    }
  };

  const clearData = () => {
    setFlightCarrierDesignation('');
    setErrorFlightCarrierDesignation('');
    setErrorFlightNumber('');
    setErrorDepartureDate('');
    setFlightNumber('');
    setFlightTickets([]);
    setDepartureDate(undefined);
    setDepartureTime('');
    setFlightTicketIdsToDelete([]);
    setInitialData({
      flightCarrierDesignation: '',
      flightNumber: '',
      departureDate: undefined,
      departureTime: '',
    });
  };

  const checkDataChanged = () => {
    if (initialData.flightCarrierDesignation !== flightCarrierDesignation) {
      return true;
    }
    if (initialData.flightNumber !== flightNumber) {
      return true;
    }
    if (initialData.departureTime !== departureTime) {
      return true;
    }
    if (
      (initialData.departureDate && !departureDate) ||
      (!initialData.departureDate && departureDate) ||
      (initialData.departureDate &&
        departureDate &&
        initialData.departureDate.toFormat('dd MMM yyyy') !== departureDate.toFormat('dd MMM yyyy'))
    ) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    const check = checkDataChanged();
    if (check) {
      setDataChangedConfirmationModalVisible(true);
    } else {
      handleCancel();
      setTimeout(() => {
        clearData();
      }, 300);
    }
  };

  const handleSave = () => {
    let _departureDate = departureDate;
    if (departureTime) {
      const hour = parseInt(departureTime.split(':')[0], 10);
      const minute = parseInt(departureTime.split(':')[1], 10);
      _departureDate = _departureDate?.set({ hour, minute });
    }
    handleConfirm({
      departureDate: _departureDate,
      flightCarrierDesignation,
      flightNumber,
      flightTickets,
      flightTicketIdsToDelete,
    });
    setTimeout(() => {
      clearData();
    }, 300);
  };

  const handleCancelDeleteTicketModalPress = () => {
    setDeleteFlightTicketSelected(null);
    setFlightTicketDeleteModalVisible(false);
  };

  const handleConfirmDeleteTicketModalPress = () => {
    if (deleteFlightTicketSelected?.id) {
      setFlightTicketIdsToDelete((prev) => [...prev, deleteFlightTicketSelected.id]);
      setFlightTickets((prevState: any) =>
        prevState.filter((item: any) => item.id !== deleteFlightTicketSelected?.id),
      );
    }
    setDeleteFlightTicketSelected(null);
    setFlightTicketDeleteModalVisible(false);
  };

  const handleCancelDataChangedPress = () => {
    handleCancel();
    setDataChangedConfirmationModalVisible(false);
    setTimeout(() => {
      clearData();
    }, 300);
  };

  const handleConfirmDataChangedPress = () => {
    setDataChangedConfirmationModalVisible(false);
    handleSave();
  };

  const isSaveDisabled = useMemo(() => {
    if (!flightCarrierDesignation || !flightNumber || !departureDate || !departureTime) {
      return true;
    }
    return false;
  }, [flightCarrierDesignation, flightNumber, departureDate, departureTime]);

  return {
    dataChangedConfirmationModalVisible,
    departureDate,
    departureTime,
    deleteFlightTicketSelected,
    errorFlightNumber,
    errorDepartureDate,
    errorFlightCarrierDesignation,
    flightCarrierDesignation,
    flightNumber,
    flightTicketDeleteModalVisible,
    flightTickets,
    handleCancelDataChangedPress,
    handleConfirmDataChangedPress,
    handleCarrierDesignationChange,
    handleCancelDeleteTicketModalPress,
    handleConfirmDeleteTicketModalPress,
    handleClose,
    handleDepartureDateChange,
    handleDepartureTimeChange,
    handleFlightNumberChange,
    handleRemoveFlightTicket,
    handleSave,
    handleSelectImage,
    handleSelectPDF,
    isSaveDisabled,
  };
};
