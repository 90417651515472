const tintColorLight = '#2f95dc';
const tintColorDark = '#404040';

export default {
  light: {
    text: '#000000',
    textPrimary: 'rgb(51, 102, 255)',
    background: '#ffffff',
    tint: tintColorLight,
    tabIconDefault: '#cccccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#ffffff',
    textPrimary: 'rgb(51, 102, 255)',
    background: 'rgb(34, 43, 69)',
    tint: tintColorDark,
    tabIconDefault: '#cccccc',
    tabIconSelected: tintColorDark,
  },
};
