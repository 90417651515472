import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import {
  QUERY_USER_MINIMAL,
  QUERY_USER_WITH_ALL_USER_DOCUMENTS,
} from '../../../apollo/graphql-queries';
import { User } from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = ({ userId }: { userId?: string }) => {
  const isBackendReachable = useIsBackendReachable();

  const { data } = useQuery(QUERY_USER_WITH_ALL_USER_DOCUMENTS, {
    variables: { userId },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    skip: !userId,
  });

  const { data: userProfileQueryData, loading } = useQuery<{ user: User }>(QUERY_USER_MINIMAL, {
    variables: { id: userId },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const countData = useMemo(() => {
    return {
      userBackgroundDocuments: data?.userWithAllUserDocuments?.userBackgroundDocument.length || 0,
      userFrequentFlyerDocuments:
        data?.userWithAllUserDocuments?.userFrequentFlyerDocuments.length || 0,
      userHealthReports: data?.userWithAllUserDocuments?.userHealthReports.length || 0,
      userIdentityDocuments: data?.userWithAllUserDocuments?.userIdentityDocuments.length || 0,
      userOtherDocuments: data?.userWithAllUserDocuments?.userOtherDocuments.length || 0,
      userTravelDocuments: data?.userWithAllUserDocuments?.userTravelDocuments.length || 0,
      userVaccinationDocuments:
        data?.userWithAllUserDocuments?.userVaccinationDocuments.length || 0,
    };
  }, [data]);

  return { countData, loading, user: userProfileQueryData?.user };
};
