import { gql } from '@apollo/client';

export const CREATE_USER_TRAVEL_DOCUMENT_FRAGMENT = gql`
  fragment NewUserTravelDocument on UserTravelDocument {
    id
    firstNames
    lastName
    placeOfBirth
    sex
    dateOfBirth
    documentType
    documentNumber
    dateOfIssue
    countryOfIssue
    expiresOn
    nationality
    multipleEntriesAllowed
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const CREATE_USER_IDENTITY_DOCUMENT_FRAGMENT = gql`
  fragment NewUserIdentityDocument on UserIdentityDocument {
    id
    firstNames
    lastName
    placeOfBirth
    sex
    dateOfBirth
    documentType
    documentSubType
    documentSubTypeMulti
    documentNumber
    dateOfIssue
    countryOfIssue
    expiresOn
    nationality
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;

export const CREATE_USER_FREQUENT_FLYER_DOCUMENT_FRAGMENT = gql`
  fragment NewUserFrequentFlyerDocument on UserFrequentFlyerDocument {
    id
    airlineType
    airlineStatusType
    airlineAllianceType
    airlineAllianceStatusType
    documentNumber
    expiresOn
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const CREATE_USER_VACCINATION_DOCUMENT_FRAGMENT = gql`
  fragment NewUserVaccinationDocument on UserVaccinationDocument {
    id
    diseaseType
    description
    vaccinationType
    vaccinationManufacturer
    dateOfVaccination
    isVaccinationComplete
    vaccinationCenter
    cityOfVaccination
    countryOfVaccination
    expiresOn
    documentNumber
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const CREATE_USER_HEALTH_REPORT_FRAGMENT = gql`
  fragment NewUserHealthReport on UserHealthReport {
    id
    diseaseType
    description
    testType
    dateTimeOfTest
    isResultPositive
    testCenter
    cityOfTest
    countryOfTest
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const CREATE_USER_BACKGROUND_DOCUMENT_FRAGMENT = gql`
  fragment NewUserBackgroundDocument on UserBackgroundDocument {
    id
    name
    issuingInstitution
    countryOfIssue
    dateOfIssue
    expiresOn
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const CREATE_USER_OTHER_DOCUMENT_FRAGMENT = gql`
  fragment NewUserOtherDocument on UserOtherDocument {
    id
    name
    description
    countryOfIssue
    placeOfIssue
    dateOfIssue
    expiresOn
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;

export const CREATE_CHAT_MESSAGE_FRAGMENT = gql`
  fragment NewChatMessage on ChatMessage {
    id
    messageType
    createdAt
    message
    sendingUser {
      id
      firstNames
      lastName
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const UPDATE_AGENCY_ORGANIZATION_PROFILE_FRAGMENT = gql`
  fragment NewAgencyOrganizationProfile on Organization {
    id
    name
    legalName
    organizationType
    description
    email
    legalEntityType
    phone
    vatId
    registrationNumber
    socialProfiles {
      platform
      type
      value
    }
    headquartersAddressLocation {
      id
      placeId
      formattedAddress
    }
    invoicingAddressLocation {
      id
      placeId
      formattedAddress
    }
    registrationAddressLocation {
      id
      placeId
      formattedAddress
    }
    logo {
      sasUrl
      cacheKey
      cacheTTL
      sasToken {
        version
        signature
        expiresOn
        permissions
        resource
      }
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;

export const UPDATE_COURIER_USER_PROFILE_FRAGMENT = gql`
  fragment NewCourierUserProfile on CourierUserProfile {
    id
    emergencyContactRelationship
    emergencyContactPhone
    emergencyContactName
    emergencyContactEmail
    emergencyContactAddress
    hasAmexCreditCard
    hasDinerCreditCard
    hasMasterCreditCard
    hasVisaCreditCard
    hasAmexDebitCard
    hasDinerDebitCard
    hasMasterDebitCard
    hasVisaDebitCard
    masterCreditCardLimit
    visaCreditCardLimit
    dinerCreditCardLimit
    amexCreditCardLimit
    languagesKnown {
      language
      level
    }
    onboardExperiences
    address
    street
    apartmentOrSuite
    city
    zipcode
    country
    socialProfiles {
      platform
      type
      value
    }
  }
`;
