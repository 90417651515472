import { useEffect, useRef, useState } from 'react';
import { Platform, AppState } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import Constants from 'expo-constants';
import * as ExpoDevice from 'expo-device';
import { Subscription } from 'expo-modules-core/build/EventEmitter';
import * as ExpoNotifications from 'expo-notifications';
import * as TaskManager from 'expo-task-manager';
import { DateTime } from 'luxon';
import { showMessage } from 'react-native-flash-message';

import {
  LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
  NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
} from '../apollo/graphql-queries';
import { OrganizationType } from '../generated-graphql-types';
import { navigationRef } from '../navigation/app.navigator';

// Only enable Notifications for android and ios platforms.
if (Platform.OS !== 'web' && AppState.currentState === 'active') {
  ExpoNotifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: false,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  });
}

const registerForExpoPushNotificationsAsync = async () => {
  let token: string | undefined;

  if (Platform.OS === 'android') {
    ExpoNotifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: ExpoNotifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  if (ExpoDevice.isDevice) {
    const { status: existingStatus } = await ExpoNotifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (finalStatus !== 'granted') {
      return;
    }
    const tokenData = await ExpoNotifications.getExpoPushTokenAsync({
      // experienceId: Constants.expoConfig?.extra?.priojetPushTokenExperienceId || '@priojet/priojet',
      projectId: Constants.expoConfig?.extra?.eas.projectId || '',
    });
    token = tokenData.data;
  } else {
    // eslint-disable-next-line no-alert
    alert('Must use physical device for Push Notifications');
  }

  return token;
};

enum NotificationOrderType {
  AGENCY_COURIER_ACCOUNT_DELETION = 'AGENCY_COURIER_ACCOUNT_DELETION',
  CHAT_NEW_CHAT_MESSAGE_FOR_JOB = 'CHAT_NEW_CHAT_MESSAGE_FOR_JOB',
  CHAT_NEW_CHAT_MESSAGE = 'CHAT_NEW_CHAT_MESSAGE',
  JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY',
  JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER',
  JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER',
  JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER',
  AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER = 'AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER',
  JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY',
  ORGANIZATION_ORGANIZATION_NEW_COURIER_AGENCY_CONNECTION = 'ORGANIZATION_ORGANIZATION_NEW_COURIER_AGENCY_CONNECTION',
  JOB_COURIER_AGENCY_JOB_RESET_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_RESET_BY_AGENCY',
  AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT = 'AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT',
  COURIER_APP_REMINDER = 'COURIER_APP_REMINDER',
  AGENCY_CHAT_NEW_CHAT_MESSAGE = 'AGENCY_CHAT_NEW_CHAT_MESSAGE',
  AGENCY_JOB_PLANNING_ITEM_MESSAGE = 'AGENCY_JOB_PLANNING_ITEM_MESSAGE',
  AGENCY_JOB_PLANNING_LEG_MESSAGE = 'AGENCY_JOB_PLANNING_LEG_MESSAGE',
}

const getNavigationDataForNotification = (notification: ExpoNotifications.Notification) => {
  const { data } = notification.request.content;
  const keys = Object.keys(NotificationOrderType);
  if (data) {
    if (
      data.notificationType && [
        keys.indexOf(NotificationOrderType.AGENCY_CHAT_NEW_CHAT_MESSAGE),
        keys.indexOf(NotificationOrderType.AGENCY_JOB_PLANNING_ITEM_MESSAGE),
        keys.indexOf(NotificationOrderType.AGENCY_JOB_PLANNING_LEG_MESSAGE),
      ] &&
      data.chatMessage?.chat?.id
    ) {
      return { canNavigate: true, navigateTo: 'agencyChat' };
    }
    if (
      data &&
      data.notificationType &&
      [
        keys.indexOf(NotificationOrderType.CHAT_NEW_CHAT_MESSAGE),
        keys.indexOf(NotificationOrderType.CHAT_NEW_CHAT_MESSAGE_FOR_JOB),
      ].includes(data.notificationType) &&
      data.chatMessageId
    ) {
      return { canNavigate: true, navigateTo: 'chat' };
    }
    if (
      data &&
      data.courierJobId &&
      data.notificationType &&
      [
        keys.indexOf(NotificationOrderType.JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER),
        keys.indexOf(NotificationOrderType.JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER),
        keys.indexOf(NotificationOrderType.JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER),
        keys.indexOf(NotificationOrderType.JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY),
        keys.indexOf(NotificationOrderType.AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT),
      ].includes(data.notificationType)
    ) {
      return { canNavigate: true, navigateTo: 'courierJob' };
    } else if (
      data &&
      data.agencyJobId &&
      data.notificationType &&
      [
        keys.indexOf(NotificationOrderType.AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT),
      ].includes(data.notificationType)
    ) {
      return { canNavigate: true, navigateTo: 'courierJob' };
    } else if (
      data &&
      data.notificationType ===
        keys.indexOf(NotificationOrderType.AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER)
    ) {
      return { canNavigate: true, navigateTo: 'availability' };
    }
  }
  return { canNavigate: false, navigateTo: 'undef' };
};

export const useInAppNotification = () => {
  const notificationListenerRef = useRef<Subscription>();
  const responseListenerRef = useRef<Subscription>();

  const [inAppNotification, setInAppNotification] = useState<
    ExpoNotifications.Notification | undefined
  >();
  const [notification, setNotification] = useState<ExpoNotifications.Notification | undefined>();

  const resetNotification = () => {
    setNotification(undefined);
  };
  const resetInAppNotification = () => {
    setInAppNotification(undefined);
  };

  const handleSetNotification = (_notification: ExpoNotifications.Notification) => {
    setNotification(_notification);
  };

  useEffect(() => {
    const func = async () => {
      if (Platform.OS !== 'web') {
        const _token = await registerForExpoPushNotificationsAsync();
        if (_token) {
          notificationListenerRef.current = ExpoNotifications.addNotificationReceivedListener(
            (_notification) => {
              setInAppNotification(_notification);
            },
          );

          responseListenerRef.current = ExpoNotifications.addNotificationResponseReceivedListener(
            (response) => {
              setNotification(response.notification);
            },
          );
        }
      }
    };

    func();

    return () => {
      if (notificationListenerRef && notificationListenerRef.current) {
        ExpoNotifications.removeNotificationSubscription(notificationListenerRef.current);
      }
      if (responseListenerRef && responseListenerRef.current) {
        ExpoNotifications.removeNotificationSubscription(responseListenerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web' && DateTime.now().toFormat('yyyy-MM-dd') === '2024-02-14') {
      TaskManager.unregisterAllTasksAsync();
    }
  }, []);

  return {
    inAppNotification,
    notification,
    resetNotification,
    resetInAppNotification,
    setNotification: handleSetNotification,
  };
};

export const InAppNotification = ({
  apolloClient,
  currentUserContext,
  inAppNotification,
  notification: notificationProp,
  resetNotification,
  resetInAppNotification,
}: any) => {
  const navigation = useNavigation<any>();

  const handleNotificationPress = ({
    notification,
    type = 'PUSH',
  }: {
    notification: ExpoNotifications.Notification;
    type: 'IN_APP' | 'PUSH';
  }) => {
    const { canNavigate, navigateTo } = getNavigationDataForNotification(notification);

    if (canNavigate) {
      const isAgency = currentUserContext?.organizationType === OrganizationType.AGENCY;
      const isCourier = currentUserContext?.organizationType === OrganizationType.COURIER;

      const { data } = notification.request.content;
      if (navigateTo === 'agencyChat') {
        if (isAgency) {
          navigation.navigate('AgencyAgencyJobChatScreen', {
            agencyJobId: data.agencyJobId,
            chatId: data.chatId,
            previousScreenName: 'CourierCourierJobAgencyJobChatScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        } else {
          navigation.navigate('CourierAgencyJobChatScreen', {
            agencyJobId: data.agencyJobId,
            chatId: data.chatId,
            previousScreenName: 'CourierCourierJobAgencyJobChatScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        }
      } else if (navigateTo === 'chat') {
        if (isCourier) {
          navigation.navigate('CourierCourierJobAgencyJobChatScreen', {
            chatId: data.chatId,
            courierJobId: data.courierJobId,
            previousScreenName: 'CourierHomeScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        } else if (isAgency) {
          navigation.navigate('AgencyCourierJobAgencyJobChatScreen', {
            agencyJobId: data.agencyJobId,
            chatId: data.chatId,
            courierJobId: data.courierJobId,
            previousScreenName: 'AgencyHomeScreen',
            previousNavigator: 'AgencyHomeNavigator',
          });
        }
      } else if (navigateTo === 'courierJob') {
        if (isCourier) {
          navigation.navigate('CourierCourierJobScreen', {
            courierJobId: data.courierJobId,
            previousScreenName: 'CourierHomeScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        } else if (isAgency) {
          navigation.navigate('AgencyViewCourierJobScreen', {
            agencyJobId: data.agencyJobId,
            previousScreenName: 'AgencyHomeScreen',
            previousNavigator: 'AgencyHomeNavigator',
          });
        }
      } else if (navigateTo === 'availability') {
        if (isCourier) {
          navigation.navigate('CourierAvailabilitiesNavigator');
        } else {
          navigation.navigate('AgencyCourierAvailabilitiesNavigator');
        }
      }
    }
    if (type === 'PUSH') {
      resetNotification();
    } else {
      resetInAppNotification();
    }
  };

  const showNotification = (_notification: ExpoNotifications.Notification) => {
    const keys = Object.keys(NotificationOrderType);
    if (
      _notification.request?.content?.data?.notificationType ===
      keys.indexOf(NotificationOrderType.COURIER_APP_REMINDER)
    ) {
      return;
    }

    const currentRoute = navigation.getCurrentRoute();
    if (
      [
        'AgencyAgencyJobChatScreen',
        'CourierAgencyJobChatScreen',
        'CourierCourierJobAgencyJobChatScreen',
        'AgencyCourierJobAgencyJobChatScreen',
      ].includes(currentRoute.name) &&
      _notification.request.content.data.chatId === currentRoute.params.chatId
    ) {
      navigation?.setParams({ refetchMessages: true });
    } else {
      showMessage({
        message: _notification.request.content.title || 'New Notification',
        description: _notification.request.content.body || '',
        type: 'info',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
        onPress: () => {
          handleNotificationPress({ notification: _notification, type: 'IN_APP' });
        },
      });
    }

    if (apolloClient) {
      apolloClient.refetchQueries({
        include: [
          NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
          LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
        ],
      });
    }
  };

  useEffect(() => {
    if (currentUserContext?.user && navigationRef.current && notificationProp) {
      handleNotificationPress({ notification: notificationProp, type: 'PUSH' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserContext?.user, navigationRef.current, notificationProp]);

  useEffect(() => {
    if (inAppNotification) {
      showNotification(inAppNotification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inAppNotification]);

  return <></>;
};
