import React, { ReactNode } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useHook } from './hook';
import { PriojetLogoSvg } from '../../../../assets/images/priojet-logo';

interface AppUpdateProps {
  children?: ReactNode;
}

export const AppUpdate: React.FC<AppUpdateProps> = ({ children }) => {
  const { t } = useTranslation();
  const { bottom } = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);
  const {
    handleSkipUpdate,
    handleUpdate,
    needsUpdate,
    currentAppVersion,
    installedAppVersion,
    minSupportedAppVersion,
  } = useHook();

  return (
    <>
      {children}

      <Modal isVisible={!!needsUpdate} style={styles.modal}>
        <View style={styles.content}>
          <PriojetLogoSvg height={70} width={300} />

          <Text category="h4" style={styles.title}>
            {t('appUpdate.title', { defaultValue: 'Time to Update' }) as string}
          </Text>
          {needsUpdate === 'mandatory' && (
            <Text style={styles.textContent}>
              {
                t('appUpdate.textMandatory', {
                  defaultValue:
                    'Please update the app, the current installed version is no longer supported!',
                }) as string
              }
            </Text>
          )}
          <Text style={styles.textContent}>
            {
              t('appUpdate.text', {
                defaultValue:
                  'We added a lot of features and some important bug fixes to make your experience as smooth as possible.',
              }) as string
            }
          </Text>
        </View>

        {process.env.EXPO_APP_ENV !== 'production' && (
          <View style={styles.versionsLayer}>
            <Text>Current app version: {currentAppVersion}</Text>
            <Text>Min supported app version: {minSupportedAppVersion}</Text>
            <Text>Your installed app version: {installedAppVersion || 'unknown'}</Text>
            <Text>Needs update: {needsUpdate}</Text>
          </View>
        )}

        <View style={[styles.buttonContainer, { paddingBottom: bottom || 20 }]}>
          <Button
            onPress={handleUpdate}
            appearance="filled"
            style={{ marginBottom: 10 }}
            size="large"
            status="warning"
          >
            {(evaProps) => (
              <Text {...evaProps} style={[evaProps?.style, styles.updateButtonText]}>
                {t('appUpdate.update', { defaultValue: 'Update' }) as string}
              </Text>
            )}
          </Button>
          {!!needsUpdate && needsUpdate !== 'mandatory' && (
            <TouchableOpacity onPress={handleSkipUpdate} style={styles.skipButton}>
              <Text style={styles.skipButtonText}>
                {t('appUpdate.notNow', { defaultValue: 'Not now' }) as string}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </Modal>
    </>
  );
};

const themedStyles = StyleService.create({
  modal: {
    padding: 0,
    margin: 0,
    backgroundColor: 'background-basic-color-1',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Lato_700Bold',
    marginVertical: 20,
  },
  textContent: {
    maxWidth: 320,
    fontSize: 17,
    textAlign: 'center',
    marginBottom: 15,
  },
  icon: {
    color: 'color-basic-100',
  },
  buttonContainer: {
    padding: 20,
  },
  skipButton: {
    alignSelf: 'center',
    padding: 20,
  },
  updateButtonText: {
    fontFamily: 'Lato_700Bold',
    color: 'color-basic-900',
  },
  skipButtonText: {
    fontFamily: 'Lato_700Bold',
    opacity: 0.9,
  },
  versionsLayer: {
    padding: 20,
  },
});

const withPrerender =
  (Component: React.JSXElementConstructor<any>) =>
  ({ ...props }) => {
    if (__DEV__ || Platform.OS === 'web') {
      return props.children;
    }
    return <Component {...props} />;
  };

export default withPrerender(AppUpdate);
