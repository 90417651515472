import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  nameContainer: {
    margin: 10,
    paddingLeft: 10,
    paddingTop: 10,
    flex: 1,
    paddingRight: 10,
  },
  nameText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 24,
  },
  editAvatarRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  chooseImageBtn: {
    padding: 10,
    margin: 2,
    marginTop: 0,
  },
  icon: {
    backgroundColor: '#ccc',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  avatar: {
    width: 150,
    height: 150,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: 20,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 48,
    margin: 2,
  },

  headerContainer: {
    padding: 20,
  },
  headerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    marginBottom: 20,
  },
  headerName: {
    flex: 1,
    width: 150,
    height: 150,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  headerLogoContainer: {
    flex: 1,
    width: 150,
    height: 150,
    flexBasis: 150,
    flexShrink: 0,
    flexGrow: 0,
    alignItems: 'flex-end',
  },
  saveBtn: {
    height: 44,
    width: 80,
  },
  flex1: {
    flex: 1,
  },
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  agencyAddressContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  aboutInput: {
    height: 200,
  },
  socialRow: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
  },
  socialIconContainer: {
    marginTop: 50,
    paddingRight: 10,
  },
  socialCopyBtn: {
    margin: 2,
    height: 38,
    marginTop: 42,
  },
});
