import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Dimensions, Platform, RefreshControl, TouchableOpacity, View } from 'react-native';

import { useQuery } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RouteProp, useNavigation } from '@react-navigation/core';
import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native-gesture-handler';

import {
  LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
  NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
} from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { FormSelect } from '../../components/common/form/FormSelect';
import { FormSelectModal } from '../../components/common/form/FormSelectModal';
import { LoadingIndicator } from '../../components/common/loading-indicator.component';
import { LoadingSpinner } from '../../components/common/loading-spinner.component';
import { TransparentModal } from '../../components/common/modal/transparent-modal.component';
import { TextLink } from '../../components/common/text-link.component';
import { TimeAgoPopover } from '../../components/common/time-ago-popover.component';
import Colors from '../../constants/Colors';
import AppUserContext from '../../contexts/AppUserContext';
import ThemeContext from '../../contexts/ThemeContext';
import {
  Notification,
  NotificationSystemFilterType,
  NotificationType,
  OrganizationType,
  useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
  useMarkSystemNotificationAsReadForOrganizationUserMutation,
  CourierJobAgencyRelationshipType,
} from '../../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../../globals';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import i18n from '../../i18n/i18n';
import {
  CourierHomeNavigatorParamList,
  CourierHomeScreenNavigationProp,
  RootStackParamList,
} from '../../navigation/app.navigator';

interface Props {
  navigation: CourierHomeScreenNavigationProp;
  route: RouteProp<RootStackParamList | CourierHomeNavigatorParamList, 'CommonActivityScreen'>;
}

export const setInitialFilterNotificationType = async (
  organizationType: OrganizationType,
  values: NotificationSystemFilterType[],
) => {
  return AsyncStorage.setItem(
    GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER,
    JSON.stringify({ organizationType, data: values }),
  );
};

export const getInitialFilterNotificationType = async (organizationType: OrganizationType) => {
  let data = await AsyncStorage.getItem(GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER);

  if (data) {
    const parsedData = JSON.parse(data);
    if (
      parsedData.organizationType === organizationType &&
      parsedData.data &&
      Array.isArray(parsedData.data)
    ) {
      return parsedData.data;
    }
  }
  if (organizationType === OrganizationType.COURIER) {
    return [
      NotificationSystemFilterType.CHAT,
      NotificationSystemFilterType.CONNECTION,
      NotificationSystemFilterType.JOB,
    ];
  }
  return [
    NotificationSystemFilterType.AVAILABILITY,
    NotificationSystemFilterType.CHAT,
    NotificationSystemFilterType.CONNECTION,
    NotificationSystemFilterType.JOB,
  ];
};

export const getInitialEmptyFilterNotificationType = (organizationType: OrganizationType) => {
  if (organizationType === OrganizationType.COURIER) {
    return [
      NotificationSystemFilterType.CHAT,
      NotificationSystemFilterType.CONNECTION,
      NotificationSystemFilterType.JOB,
    ];
  }
  return [
    NotificationSystemFilterType.AVAILABILITY,
    NotificationSystemFilterType.CHAT,
    NotificationSystemFilterType.CONNECTION,
    NotificationSystemFilterType.JOB,
  ];
};

export const filterOptions = (organizationType?: OrganizationType | null) => {
  if (organizationType === OrganizationType.COURIER) {
    if (Platform.OS !== 'web') {
      return [
        {
          label: i18n.t('common:chat', { defaultValue: 'Chat' }),
          value: NotificationSystemFilterType.CHAT,
        },
        {
          label: i18n.t('common:connections', { defaultValue: 'Connections' }),
          value: NotificationSystemFilterType.CONNECTION,
        },
        {
          label: i18n.t('common:jobs', { defaultValue: 'Jobs' }),
          value: NotificationSystemFilterType.JOB,
        },
      ];
    }
    return [
      { label: i18n.t('common:all', { defaultValue: 'All' }), value: 'ALL' },
      {
        label: i18n.t('common:chat', { defaultValue: 'Chat' }),
        value: NotificationSystemFilterType.CHAT,
      },
      {
        label: i18n.t('common:connections', { defaultValue: 'Connections' }),
        value: NotificationSystemFilterType.CONNECTION,
      },
      {
        label: i18n.t('common:jobs', { defaultValue: 'Jobs' }),
        value: NotificationSystemFilterType.JOB,
      },
    ];
  }
  if (Platform.OS !== 'web') {
    return [
      {
        label: i18n.t('common:availabilities', { defaultValue: 'Availabilities' }),
        value: NotificationSystemFilterType.AVAILABILITY,
      },
      {
        label: i18n.t('common:chat', { defaultValue: 'Chat' }),
        value: NotificationSystemFilterType.CHAT,
      },
      {
        label: i18n.t('common:connections', { defaultValue: 'Connections' }),
        value: NotificationSystemFilterType.CONNECTION,
      },
      {
        label: i18n.t('common:jobs', { defaultValue: 'Jobs' }),
        value: NotificationSystemFilterType.JOB,
      },
    ];
  }
  return [
    { label: i18n.t('common:all', { defaultValue: 'All' }), value: 'ALL' },
    {
      label: i18n.t('common:availabilities', { defaultValue: 'Availabilities' }),
      value: NotificationSystemFilterType.AVAILABILITY,
    },
    {
      label: i18n.t('common:chat', { defaultValue: 'Chat' }),
      value: NotificationSystemFilterType.CHAT,
    },
    {
      label: i18n.t('common:connections', { defaultValue: 'Connections' }),
      value: NotificationSystemFilterType.CONNECTION,
    },
    {
      label: i18n.t('common:jobs', { defaultValue: 'Jobs' }),
      value: NotificationSystemFilterType.JOB,
    },
  ];
};

export const NotificationItem = ({
  notification,
  small,
}: {
  notification: Partial<Notification>;
  small?: boolean;
}) => {
  const { id, content, createdAt, markedAsReadAt, sentAt, title } = notification;

  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);
  const { theme } = useContext(ThemeContext);
  const navigation: any = useNavigation();

  const [markAsRead] = useMarkSystemNotificationAsReadForOrganizationUserMutation({
    variables: {
      notificationId: id as string,
      organizationUserId: appUserContext.currentUserContext?.organizationUser?.id as string,
    },
    refetchQueries: [
      LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
      NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
    ],
  });

  const { canNavigate, navigateTo } = useMemo((): {
    canNavigate: boolean;
    navigateTo?: 'agencyChat' | 'availability' | 'chat' | 'courierJob';
  } => {
    if (notification) {
      if (
        notification.notificationType &&
        [
          NotificationType.CHAT_NEW_CHAT_MESSAGE,
          NotificationType.CHAT_NEW_CHAT_MESSAGE_FOR_JOB,
        ].includes(notification.notificationType) &&
        notification.chatMessage?.chat?.id
      ) {
        return { canNavigate: true, navigateTo: 'chat' };
      }

      if (
        notification.notificationType &&
        [
          NotificationType.AGENCY_CHAT_NEW_CHAT_MESSAGE,
          NotificationType.AGENCY_JOB_PLANNING_ITEM_MESSAGE,
          NotificationType.AGENCY_JOB_PLANNING_LEG_MESSAGE,
        ].includes(notification.notificationType) &&
        notification.chatMessage?.chat?.id
      ) {
        let _canNavigate =
          appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY;
        const courierJobs = notification.agencyJob?.courierJobs;
        if (courierJobs?.length) {
          const courierJob = courierJobs.find(
            (item) =>
              item.responsibleCourierUser?.id === appUserContext.currentUserContext?.user?.id,
          );
          if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED) {
            _canNavigate = true;
          }
        }
        return { canNavigate: _canNavigate, navigateTo: 'agencyChat' };
      }
      if (
        notification.courierJob?.id &&
        notification.notificationType &&
        [
          NotificationType.JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY,
          NotificationType.JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY,
          NotificationType.JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY,
          NotificationType.JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER,
          NotificationType.JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER,
          NotificationType.JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER,
          NotificationType.JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY,
          NotificationType.JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY,
          NotificationType.JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY,
          NotificationType.JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY,
          NotificationType.AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT,
        ].includes(notification.notificationType)
      ) {
        return { canNavigate: true, navigateTo: 'courierJob' };
      } else if (
        notification.agencyJob?.id &&
        notification.notificationType &&
        [NotificationType.AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT].includes(
          notification.notificationType,
        )
      ) {
        return { canNavigate: true, navigateTo: 'courierJob' };
      } else if (
        notification.notificationType ===
        NotificationType.AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER
      ) {
        return { canNavigate: true, navigateTo: 'availability' };
      }
    }
    return { canNavigate: false };
  }, [notification]);

  const handleNotificationPress = () => {
    if (!notification.markedAsReadAt) {
      markAsRead();
    }

    if (canNavigate) {
      const isAgency =
        appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY;
      const isCourier =
        appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER;
      const agencyJobId =
        notification.agencyJob?.id ||
        notification.courierJob?.agencyJob?.id ||
        notification.chatMessage?.chat?.agencyJob?.id ||
        '';
      if (navigateTo === 'agencyChat') {
        if (isAgency) {
          navigation.navigate('AgencyAgencyJobChatScreen', {
            chatId: notification.chatMessage?.chat?.id,
            agencyJobId: notification?.agencyJob?.id as string,
            previousScreenName: 'AgencyHomeScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        } else {
          navigation.navigate('CourierAgencyJobChatScreen', {
            chatId: notification.chatMessage?.chat?.id,
            agencyJobId: notification?.agencyJob?.id as string,
            previousScreenName: 'CourierHomeScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        }
      } else if (navigateTo === 'chat') {
        if (isCourier) {
          navigation.navigate('CourierCourierJobAgencyJobChatScreen', {
            chatId: notification.chatMessage?.chat?.id,
            courierJobId: notification.courierJob?.id as string,
            previousScreenName: 'CourierHomeScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        } else if (isAgency) {
          navigation.navigate('AgencyCourierJobAgencyJobChatScreen', {
            agencyJobId,
            chatId: notification.chatMessage?.chat?.id,
            courierJobId: notification.courierJob?.id as string,
            previousScreenName: 'AgencyHomeScreen',
            previousNavigator: 'AgencyHomeNavigator',
          });
        }
      } else if (navigateTo === 'courierJob') {
        if (isCourier) {
          navigation.navigate('CourierCourierJobScreen', {
            courierJobId: notification.courierJob?.id || '',
            previousScreenName: 'CourierHomeScreen',
            previousNavigator: 'CourierHomeNavigator',
          });
        } else if (isAgency) {
          if (agencyJobId) {
            navigation.navigate('AgencyViewCourierJobScreen', {
              agencyJobId,
              previousScreenName: 'AgencyHomeScreen',
              previousNavigator: 'AgencyHomeNavigator',
            });
          }
        }
      } else if (navigateTo === 'availability') {
        if (isCourier) {
          navigation.navigate('CourierAvailabilitiesNavigator');
        } else {
          navigation.navigate('AgencyCourierAvailabilitiesNavigator');
        }
      }
    }
  };

  const renderNotificationContent = () => (
    <>
      <View style={styles.flex1}>
        <View style={[styles.header, styles.flexColumn]}>
          <View style={styles.titleLayer}>
            <Text style={styles.titleText}>{title as string}</Text>
          </View>
          <View style={styles.timeAgoLayer}>
            {!markedAsReadAt && <View style={styles.newNotificationDot} />}
            <TimeAgoPopover
              date={(sentAt || createdAt) as DateTime}
              language={i18n.language}
              textStyle={styles.timeAgoText}
            />
          </View>
        </View>

        {!!content && (
          <Text style={[styles.contentText, small && styles.contentTextSmall]} category="s1">
            {content}
          </Text>
        )}
      </View>
      {!!canNavigate && (
        <Icon
          name="arrow-ios-forward-outline"
          fill={theme === 'light' ? Colors.light.textPrimary : Colors.dark.textPrimary}
          style={[styles.doneIcon, globalStyle.marginLeft5]}
        />
      )}
    </>
  );

  return (
    <TouchableOpacity
      key={id}
      style={[
        styles.activityLayer,
        small && styles.activityLayerSmall,
        !markedAsReadAt && styles.activityLayerUnread,
      ]}
      onPress={handleNotificationPress}
      activeOpacity={canNavigate ? 0.8 : 1}
    >
      {renderNotificationContent()}
    </TouchableOpacity>
  );
};

export const CommonActivityScreen: React.FC<Props> = (): React.ReactElement => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const { theme } = useContext(ThemeContext);
  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);

  const [filterChanged, setFilterChanged] = useState(false);
  const [filter, setFilter] = useState<any>();
  const [initialFilter] = useState<any>(
    getInitialEmptyFilterNotificationType(
      appUserContext.currentUserContext?.organizationType as OrganizationType,
    ),
  );
  const [isVisible, setIsVisible] = useState(false);
  const [opened, setOpened] = useState(false);

  const loadFilterFromStorage = async () => {
    const data = await getInitialFilterNotificationType(
      appUserContext.currentUserContext?.organizationType as OrganizationType,
    );
    setFilter(data);
  };

  useEffect(() => {
    if (appUserContext.currentUserContext?.organizationType && !filter) {
      loadFilterFromStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserContext.currentUserContext?.organizationType, filter]);

  const { data, loading, refetch } = useQuery<{
    latestOrNewSystemNotificationsForOrganizationUser: Notification[];
  }>(LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER, {
    variables: {
      organizationUserId: appUserContext.currentUserContext?.organizationUser?.id as string,
      filterType: filter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable && filterChanged ? 'network-only' : 'cache-only',
    onCompleted: () => {
      setFilterChanged(false);
    },
    skip: !filter,
  });

  const [
    markAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    { loading: loadingMarkAll },
  ] = useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation({
    variables: {
      organizationUserId: appUserContext.currentUserContext?.organizationUser?.id as string,
    },
    refetchQueries: [
      LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
      NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
    ],
  });

  const onSelectFilter = (value: any) => {
    setFilter((prev: any) => {
      let values: any = [...prev];
      if (Platform.OS !== 'web') {
        values = value;
      } else {
        if (value === 'ALL') {
          if (values.length === initialFilter.length) {
            values = [];
          } else {
            values = initialFilter;
          }
        } else {
          if (values.includes(value)) {
            values = values.filter((item: any) => item !== value);
          } else {
            values = [...values, value];
          }
        }
      }
      setInitialFilterNotificationType(
        appUserContext.currentUserContext?.organizationType as OrganizationType,
        values,
      );
      return values;
    });

    setFilterChanged(true);
  };

  const showMarkAllAsRead = useMemo(() => {
    if (data?.latestOrNewSystemNotificationsForOrganizationUser) {
      return data.latestOrNewSystemNotificationsForOrganizationUser.some(
        (item) => !item.markedAsReadAt,
      );
    }
    return false;
  }, [data?.latestOrNewSystemNotificationsForOrganizationUser]);

  const handleOpenFilterPress = () => {
    setOpened(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  };

  const handleCloseFilterPress = () => {
    setIsVisible(false);
    setTimeout(() => {
      setOpened(false);
    }, 200);
  };

  const renderFilter = () => {
    if (Platform.OS !== 'web') {
      return (
        <>
          <TouchableOpacity onPress={handleOpenFilterPress}>
            <Icon
              name="funnel-outline"
              fill={(styles.filterIconColor as any).color}
              style={styles.filterIconSize}
            />
          </TouchableOpacity>
          {opened && (
            <FormSelectModal
              items={filterOptions(appUserContext.currentUserContext?.organizationType)}
              fieldTitleKey="label"
              fieldValueKey="value"
              handleClose={handleCloseFilterPress}
              multiSelect
              onSelect={onSelectFilter}
              value={filter}
              isVisible={isVisible}
            />
          )}
        </>
      );
    }

    let placeholder = '';
    if (filter?.length === 0) {
      placeholder = t('common.noFilter', { defaultValue: 'No filter' });
    }
    if (filter?.length === initialFilter.length) {
      placeholder = t('common.all', { defaultValue: 'All' });
    }
    return (
      <FormSelect
        containerStyle={styles.filterContainer}
        style={[globalStyle.paddingTop0, globalStyle.paddingBottom0]}
        items={filterOptions(appUserContext.currentUserContext?.organizationType)}
        fieldTitleKey="label"
        fieldValueKey="value"
        forceCustomPlaceholder={filter?.length === 0 || filter?.length === initialFilter?.length}
        multiSelect
        onSelect={onSelectFilter}
        placeholder={placeholder}
        value={filter?.length === initialFilter.length ? ['ALL', ...filter] : filter}
      />
    );
  };

  const renderListEmptyComponent = () => {
    if (loading && (!data || filterChanged)) {
      return (
        <View>
          <LoadingSpinner />
        </View>
      );
    }
    return (
      <View style={styles.emptyContainer}>
        <Text>
          {
            t('activityNotification.noNewNotifications', {
              defaultValue:
                'There are currently no system notifications or activities that are less than 14 days old or unread.',
            }) as string
          }
        </Text>
      </View>
    );
  };

  const renderListHeaderComponent = () => {
    if (!showMarkAllAsRead) {
      return null;
    }
    return (
      <View style={styles.markAllAsReadContainer}>
        {loadingMarkAll ? (
          <LoadingIndicator />
        ) : (
          <Icon
            name="done-all-outline"
            fill={theme === 'light' ? Colors.light.textPrimary : Colors.dark.textPrimary}
            style={[styles.doneIcon, globalStyle.marginRight5]}
          />
        )}
        <TextLink
          text={t('activityNotification.markAllAsRead', { defaultValue: 'Mark all as read' })}
          onPress={markAllNewSystemNotificationsAsReadForOrganizationUserMutation}
        />
      </View>
    );
  };

  return (
    <TransparentModal
      title={t('activityNotification.activityAndNotifications', {
        defaultValue: 'Activity & Notifications',
      })}
      rightButton={renderFilter()}
      useScrollView={false}
      fullHeight
    >
      <FlatList
        ListHeaderComponent={renderListHeaderComponent()}
        refreshControl={<RefreshControl refreshing={loading} onRefresh={refetch} />}
        data={data?.latestOrNewSystemNotificationsForOrganizationUser as Partial<Notification>[]}
        ListEmptyComponent={renderListEmptyComponent()}
        renderItem={(rest) => <NotificationItem {...rest} notification={rest.item} />}
      />
    </TransparentModal>
  );
};

const themedStyles = StyleService.create({
  container: {
    maxWidth: Platform.OS === 'web' ? 600 : 'auto',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  emptyContainer: {
    height: 100,
    alignItems: Platform.OS === 'web' ? 'center' : 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 15,
  },

  flex1: {
    flex: 1,
  },
  markAllAsReadContainer: {
    justifyContent: 'flex-end',
    marginBottom: 5,
    flexDirection: 'row',
  },
  activityLayer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 9,
    padding: 15,
    paddingRight: 10,
    borderRadius: 10,
    opacity: 0.9,
    borderColor: 'background-basic-color-3',
    borderWidth: 1,
  },
  activityLayerUnread: {
    backgroundColor: 'background-basic-color-3',
  },
  activityLayerSmall: {
    marginBottom: 5,
    padding: Platform.OS === 'web' ? 12 : 8,
    paddingRight: Platform.OS === 'web' ? 10 : 5,
    paddingLeft: Platform.OS === 'web' ? 15 : 12,
  },
  activityLayerUnreadSmall: {
    backgroundColor: 'background-basic-color-3',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleLayer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  titleText: {
    flex: 1,
    fontFamily: 'Lato_700Bold',
  },
  timeAgoLayer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  timeAgoText: {
    fontSize: 13,
  },
  contentText: {
    borderTopColor: 'color-basic-600',
    marginTop: 5,
    width: Platform.OS === 'web' ? '80%' : '100%',
  },
  contentTextSmall: {
    borderTopColor: 'color-basic-600',
    marginTop: 3,
    width: Platform.OS === 'web' ? '80%' : '100%',
    fontSize: 14,
  },
  contentButtonLayer: {
    flex: 1,
    marginTop: 2,
    alignItems: Dimensions.get('window').width < 400 ? 'stretch' : 'flex-end',
  },
  newNotificationDot: {
    height: 10,
    width: 10,
    backgroundColor: 'border-primary-color-1',
    borderRadius: 5,
    borderWidth: 0,
    colorColor: 'blue',
    marginRight: 10,
    marginTop: 6,
  },
  doneIcon: {
    height: 16,
    width: 16,
  },
  filterContainer: {
    flex: 1,
    marginTop: 0,
    width: 170,
  },
  filterIconSize: {
    width: 30,
    height: 35,
  },
  filterIconColor: {
    color: 'text-basic-color',
  },
});
