import { Platform } from 'react-native';

import { isString } from 'class-validator';
import * as Crypto from 'expo-crypto';
import * as FileSystem from 'expo-file-system';
import 'react-native-get-random-values';
import { validate as uuidValidate, version as uuidVersion } from 'uuid';

import { CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzure } from '../../graphql-types';

export const uuidValidateV4 = (uuidString: string | null | undefined): boolean => {
  if (uuidString && isString(uuidString) && uuidString != '') {
    return uuidValidate(uuidString) && uuidVersion(uuidString) === 4;
  }
  return false;
};

export const stripTypename = (obj: any) => {
  Object.entries(obj).forEach(([k, v]: [string, any]) => {
    if (v && typeof v === 'object') {stripTypename(v);}
  });
  if (obj.hasOwnProperty('__typename')) {delete obj.__typename;}
  return obj;
};

export const cleanObjectRecursive = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  Object.entries(obj).forEach(([k, v]: [string, any]) => {
    if (v && typeof v === 'object') {cleanObjectRecursive(v);}
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v.length === 0
    ) {
      if (Array.isArray(obj) && obj.length > 0) {obj.splice(0, 1);}
      else if (!(v instanceof Date)) {delete obj[k];}
    }
  });
  if (obj.hasOwnProperty('__persist')) {
    //var prop = '__persist';
    delete obj.__persist;
  }
  return obj;
};

export const filterObjectKey = (obj: { [key: string]: any }, key: string) => {
  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {continue;}
    if (typeof obj[i] === 'object') {
      filterObjectKey(obj[i], key);
    } else if (i == key) {
      delete obj[key];
    }
  }
  return obj;
};

export const writeDocumentToFileSystem = async (fileName: string, uri: any) => {
  if (Platform.OS !== 'web') {
    const path = `${FileSystem.cacheDirectory}${fileName}`;
    const newImage = await FileSystem.downloadAsync(uri, path);
  }
};

export const readDocumentFromFileSystem = async (
  documentFromServer: CreateUserIdentityDocument_createUserIdentityDocument_documentBackFromAzure,
) => {
  if (Platform.OS !== 'web') {
    const path = `${FileSystem.cacheDirectory}${documentFromServer.cacheKey}`;
    const document = await FileSystem.getInfoAsync(path);

    if (document.exists && document.modificationTime > new Date().valueOf() - 1) {
      return document.uri;
    }
    const newImage = await FileSystem.downloadAsync(documentFromServer.sasUrl, path);
  }
};

export const sha256Hash = async (message: string): Promise<string> => {
  return await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, message);
};
