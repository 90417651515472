import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Layout, Text, useTheme } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { ConnectedOrganization } from '../../../generated-graphql-types';
import { RootStackParamList } from '../../../navigation/app.navigator';
import { ConnectedAtByText } from '../../common/connected-at-from-text.component';

export const CardItemFooter = ({
  footerProps,
  connectedOrganization,
}: {
  footerProps: any;
  connectedOrganization: ConnectedOrganization;
}) => {
  const theme = useTheme();
  const [t, i18n] = useTranslation();
  const navigation = useNavigation() as StackNavigationProp<RootStackParamList>;

  if (!connectedOrganization.usedOrganizationInvitation) {
    return (
      <Layout
        {...footerProps}
        style={{
          paddingHorizontal: 20,
          paddingVertical: 5,
          flexDirection: 'row',
          alignItems: 'flex-end',
          alignContent: 'space-between',
        }}
      >
        <Text selectable={true}>
          {
            t('common.noInvitationWasUsed', {
              defaultValue: 'No invitation was used',
            }) as string
          }
        </Text>
      </Layout>
    );
  }

  return (
    <Layout
      {...footerProps}
      style={{
        paddingHorizontal: 20,
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignContent: 'space-between',
      }}
    >
      <ConnectedAtByText
        firstNames={
          connectedOrganization.usedOrganizationInvitation.usedByUser?.firstNames || ''
        }
        lastName={
          connectedOrganization.usedOrganizationInvitation.usedByUser?.lastName || ''
        }
        email={connectedOrganization.usedOrganizationInvitation.usedByUser?.email || ''}
        createdAt={connectedOrganization.createdAt}
      />
    </Layout>
  );
};
