import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { CommonProfileNavigator } from './common.profile.navigator';

const StackNavigator = createStackNavigator();

const UnauthenticatedNavigator = (props: any) => {
  return (
    <StackNavigator.Navigator
      initialRouteName="CommonProfileNavigator"
      screenOptions={{ headerShown: false, presentation: 'card' }}
    >
      <StackNavigator.Screen
        name="CommonProfileNavigator"
        component={CommonProfileNavigator}
      />
    </StackNavigator.Navigator>
  );
};

export { UnauthenticatedNavigator };
