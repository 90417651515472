import React, { useState } from 'react';
import { FlatList, RefreshControl, View } from 'react-native';

import { ApolloQueryResult } from '@apollo/client';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { CourierCourierJobsCardItem } from './courier.courier-jobs-card-item.component';
import { globalStyle } from '../../common/style';
import {
  CourierJob,
  CourierJobsForCourierOrganizationQuery,
  Exact,
} from '../../generated-graphql-types';

type Props = {
  courierJobs: Partial<CourierJob>[] | undefined;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            courierOrganizationId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<CourierJobsForCourierOrganizationQuery>>;
  filterTerm: string | undefined;
  isLoading: boolean;
  screenName:
    | 'CourierCourierJobsNewScreen'
    | 'CourierCourierJobsRunningScreen'
    | 'CourierCourierJobsUpcomingScreen'
    | 'CourierCourierJobsPastScreen';
};

export const CourierCourierJobsList = ({ courierJobs, refetch, filterTerm, screenName }: Props) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const [refreshing, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const scrollEnabled = true;
  const scrollToOverflowEnabled = true;

  const renderListEmptyComponent = () => {
    if (filterTerm?.length) {
      return (
        <View style={styles.noResultsView}>
          <View style={styles.invitationText}>
            <Text style={globalStyle.textAlignCenter}>
              {
                t('jobs.noCourierJobsForTerm', {
                  defaultValue: 'No Courier Jobs found for "{{filterTerm}}".',
                  filterTerm,
                }) as string
              }
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View style={styles.noResultsView}>
        <View style={styles.invitationText}>
          <Text style={globalStyle.textAlignCenter}>
            {
              t('jobs.noCouriersInThisStage', {
                defaultValue: 'There are no Courier Jobs in this stage.',
              }) as string
            }
          </Text>
        </View>
      </View>
    );
  };

  return (
    <FlatList
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={handleRefresh}
          title="Loading Jobs..."
          tintColor={(styles.refreshColor as Record<string, string>).color}
          titleColor={(styles.refreshColor as Record<string, string>).color}
        />
      }
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      ListEmptyComponent={renderListEmptyComponent()}
      scrollEnabled={scrollEnabled}
      scrollToOverflowEnabled={scrollToOverflowEnabled}
      contentContainerStyle={styles.extraBottom}
      style={styles.width100}
      data={courierJobs}
      renderItem={({ item }: { item: Partial<CourierJob> }) => (
        <CourierCourierJobsCardItem
          courierJob={item}
          refetch={refetch}
          tabScreenName={screenName}
        />
      )}
    />
  );
};

const themedStyles = StyleService.create({
  noResultsView: {
    marginTop: 10,
    padding: 10,
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  invitationText: {
    minHeight: 100,
    maxWidth: 220,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  width100: {
    width: '100%',
  },
  extraBottom: {
    paddingBottom: 20,
  },
  refreshColor: {
    color: 'text-basic-color',
  },
});
