import React, { useContext, useState } from 'react';
import { ActivityIndicator, Pressable, View, Platform } from 'react-native';

import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import {
  Icon,
  List,
  MenuItem,
  OverflowMenu,
  Text,
  PopoverPlacements,
} from '@ui-kitten/components/ui';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { ConfirmModalContext } from './common/modal/ConfirmModal';
import { FileIcon } from './icons.component';
import {
  DELETE_USER_BACKGROUND_DOCUMENT_MUTATION,
  DELETE_USER_FREQUENT_FLYER_DOCUMENT_MUTATION,
  DELETE_USER_HEALTH_REPORT_MUTATION,
  DELETE_USER_IDENTITY_DOCUMENT_MUTATION,
  DELETE_USER_OTHER_DOCUMENTS_MUTATION,
  DELETE_USER_TRAVEL_DOCUMENT_MUTATION,
  DELETE_USER_VACCINATION_DOCUMENT_MUTATION,
} from '../apollo/graphql-mutations';
import { CountryListData } from '../assets/constants/country.list.data';
import { globalStyle } from '../common/style';
import { UserTravelDocumentSubTypeType } from '../generated-graphql-types';
import {
  AirlineAllianceTypeList,
  AirlineStatusTypeList,
  AirlineTypeList,
  HealthTestTypeList,
  PassportTypeList,
  UserDocument2,
  VaccinationTargetDiseaseList,
} from '../types';

export default function CommonUserDocumentsComponent({
  screenName,
  targetDocument,
  userDocumentList,
}: {
  screenName: string;
  targetDocument: string;
  userDocumentList: UserDocument2[] | undefined;
}) {
  const navigation: any = useNavigation();
  const { t, i18n } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const themedStyles = useStyleSheet(styles);

  const [deleteDocumentId, setDeleteDocumentId] = useState('');
  const [isDeleting, setIsDeleting] = useState('');
  const [visible, setVisible] = useState(false);

  const errorCb = () => {
    setIsDeleting('');
  };

  const [deleteUserBackgroundDocument] = useMutation(DELETE_USER_BACKGROUND_DOCUMENT_MUTATION, {
    context: { cb: errorCb },
    update(cache, { data }) {
      const document = data?.deleteUserBackgroundDocument;
      if (document?.id) {
        cache.modify({
          fields: {
            userBackgroundDocuments(existingDocuments = [], { readField }) {
              return existingDocuments.filter((item: any) => document.id !== readField('id', item));
            },
          },
        });
      }
    },
  });
  const [deleteUserFrequentFlyerDocument] = useMutation(
    DELETE_USER_FREQUENT_FLYER_DOCUMENT_MUTATION,
    {
      context: { cb: errorCb },
      update(cache, { data }) {
        const document = data?.deleteUserFrequentFlyerDocument;
        if (document?.id) {
          cache.modify({
            fields: {
              userFrequentFlyerDocuments(existingDocuments = [], { readField }) {
                return existingDocuments.filter(
                  (item: any) => document.id !== readField('id', item),
                );
              },
            },
          });
        }
      },
    },
  );
  const [deleteUserIdentityDocument] = useMutation(DELETE_USER_IDENTITY_DOCUMENT_MUTATION, {
    context: { cb: errorCb },
    update(cache, { data }) {
      const document = data?.deleteUserIdentityDocument;
      if (document?.id) {
        cache.modify({
          fields: {
            userIdentityDocuments(existingDocuments = [], { readField }) {
              return existingDocuments.filter((item: any) => document.id !== readField('id', item));
            },
          },
        });
      }
    },
  });

  const [deleteUserHealthReport] = useMutation(DELETE_USER_HEALTH_REPORT_MUTATION, {
    context: { cb: errorCb },
    update(cache, { data }) {
      const document = data?.deleteUserHealthReport;
      if (document?.id) {
        cache.modify({
          fields: {
            userHealthReports(existingDocuments = [], { readField }) {
              return existingDocuments.filter((item: any) => document.id !== readField('id', item));
            },
          },
        });
      }
    },
  });
  const [deleteUserOtherDocument] = useMutation(DELETE_USER_OTHER_DOCUMENTS_MUTATION, {
    context: { cb: errorCb },
    update(cache, { data }) {
      const document = data?.deleteUserOtherDocument;
      if (document?.id) {
        cache.modify({
          fields: {
            userOtherDocuments(existingDocuments = [], { readField }) {
              return existingDocuments.filter((item: any) => document.id !== readField('id', item));
            },
          },
        });
      }
    },
  });
  const [deleteUserTravelDocument] = useMutation(DELETE_USER_TRAVEL_DOCUMENT_MUTATION, {
    context: { cb: errorCb },
    update(cache, { data }) {
      const document = data?.deleteUserTravelDocument;
      if (document?.id) {
        cache.modify({
          fields: {
            userTravelDocuments(existingDocuments = [], { readField }) {
              return existingDocuments.filter((item: any) => document.id !== readField('id', item));
            },
          },
        });
      }
    },
  });
  const [deleteUserVaccinationDocument] = useMutation(DELETE_USER_VACCINATION_DOCUMENT_MUTATION, {
    context: { cb: errorCb },
    update(cache, { data }) {
      const document = data?.deleteUserVaccinationDocument;
      if (document?.id) {
        cache.modify({
          fields: {
            userVaccinationDocuments(existingDocuments = [], { readField }) {
              return existingDocuments.filter((item: any) => document.id !== readField('id', item));
            },
          },
        });
      }
    },
  });

  const deleteDocument = async (documentId: string) => {
    try {
      setIsDeleting(documentId);
      switch (targetDocument) {
        case 'UserIdentityDocument':
          await deleteUserIdentityDocument({ variables: { documentId } });
          break;
        case 'UserTravelDocument':
          await deleteUserTravelDocument({ variables: { documentId } });
          break;
        case 'UserFrequentFlyerDocument':
          await deleteUserFrequentFlyerDocument({ variables: { documentId } });
          break;
        case 'UserVaccinationDocument':
          await deleteUserVaccinationDocument({ variables: { documentId } });
          break;
        case 'UserHealthReport':
          await deleteUserHealthReport({ variables: { documentId } });
          break;
        case 'UserBackgroundDocument':
          await deleteUserBackgroundDocument({ variables: { documentId } });
          break;
        case 'UserOtherDocument':
          await deleteUserOtherDocument({ variables: { documentId } });
          break;
        default:
          break;
      }
    } catch (e) {
      console.warn(e);
    }
    setIsDeleting('');
  };

  const deleteDocumentConfirmation = () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.delete', { defaultValue: 'Delete' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('common.deleteDocument', { defaultValue: 'Delete Document' }),
      text: t('common.deleteDocumentMessage', {
        defaultValue: 'Do you want to delete this document?',
      }),
      onConfirmPress: async () => {
        deleteDocument(deleteDocumentId);
        setDeleteDocumentId('');
        return true;
      },
      onCancelPress: async () => {
        setDeleteDocumentId('');
        return false;
      },
      visible: true,
    });
  };

  const getScreenTitle = (target: string, readOnly: boolean) => {
    if (readOnly) {
      switch (target) {
        case 'UserIdentityDocument':
          return 'View Identity Document';
        case 'UserTravelDocument':
          return 'View Travel Document';
        case 'UserFrequentFlyerDocument':
          return 'View Frequent Flyer Document';
        case 'UserVaccinationDocument':
          return 'View Vaccination Document';
        case 'UserHealthReport':
          return 'View Health Test Report';
        case 'UserBackgroundDocument':
          return 'View Background Document';
        default:
          return 'View Document';
      }
    }
    return 'Add Document';
  };

  const navigateToDocument = (props: UserDocument2, target: string, readOnly: boolean) => () => {
    navigation.navigate('CommonUserDocumentDetailScreen', {
      readOnly: readOnly,
      userDocument: props,
      targetDocument: target,
      userDocumentId: props.id,
      title: getScreenTitle(target, readOnly),
      user: undefined,
      userIdentityDocuments: undefined,
      previousScreenName: screenName,
    });
  };

  const onSelect = () => {
    setVisible(false);
    setTimeout(() => {
      deleteDocumentConfirmation();
    }, 300);
  };

  const handleMenuIconPress = (documentId: string) => () => {
    setVisible(true);
    setDeleteDocumentId((prevState) => (documentId === prevState ? '' : documentId));
  };

  const handleBackdropPress = () => {
    setDeleteDocumentId('');
    setVisible(false);
  };

  const renderToggleButton = (documentId: string) => () => {
    if (isDeleting === documentId) {
      return (
        <View style={themedStyles.moreButtonContainer}>
          <ActivityIndicator />
        </View>
      );
    }
    return (
      <Pressable onPress={handleMenuIconPress(documentId)} style={themedStyles.moreButtonContainer}>
        <Icon name="more-vertical" width={16} height={16} fill="#777" />
      </Pressable>
    );
  };

  const renderItemAccessory = (props: UserDocument2) => (
    <View style={[globalStyle.relative]}>
      <OverflowMenu
        anchor={renderToggleButton(props.id)}
        visible={visible && deleteDocumentId === props.id}
        onSelect={onSelect}
        placement={PopoverPlacements.RIGHT_START}
        onBackdropPress={handleBackdropPress}
        style={Platform.OS === 'ios' ? { top: -5 } : undefined}
      >
        <MenuItem title="Delete" />
      </OverflowMenu>
    </View>
  );

  const keyExtractor = (item: UserDocument2) => item.id;

  const renderListItem = ({
    description,
    item,
    title,
  }: {
    description: string;
    index: number;
    item: any;
    title: string;
  }) => (
    <View
      style={[
        globalStyle.flex1,
        globalStyle.flexRow,
        globalStyle.alignItemsCenter,
        globalStyle.justifyContentBetween,
        globalStyle.padding10,
        globalStyle.paddingLeft20,
      ]}
    >
      <Pressable
        onPress={navigateToDocument(item, targetDocument, true)}
        style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.alignItemsCenter]}
      >
        <FileIcon style={globalStyle.size24} fill="gray" />
        <View style={[globalStyle.flex1, globalStyle.paddingHorizontal15]}>
          <Text style={[globalStyle.flex1, globalStyle.fontSize13, globalStyle.fontLatoBold]}>
            {title}
          </Text>
          <Text style={globalStyle.fontSize12}>{description}</Text>
        </View>
      </Pressable>
      {renderItemAccessory(item)}
    </View>
  );

  const renderItem = ({ item, index }: { item: any; index: number }) => {
    if (targetDocument === 'UserIdentityDocument') {
      let title = '';

      let documentSubType = item.documentSubType;

      if (item.documentType === 'DRIVERS_LICENSE') {
        let countryOfIssue = '';
        if (item.countryOfIssue) {
          const country = CountryListData.find((c) => c.code === item.countryOfIssue);
          if (country) {
            countryOfIssue = country.name;
          }
        }
        title = `${t('common.driversLicense', {
          defaultValue: "Driver's License",
        })} | ${countryOfIssue}`;
      }
      if (item.documentType === 'NATIONAL_ID') {
        let nationality = '';
        if (item.nationality) {
          const country = CountryListData.find((c) => c.code === item.nationality);
          if (country) {
            nationality = country.name;
          }
        }
        documentSubType =
          PassportTypeList.find((x) => x.value === item.documentSubType)?.name || documentSubType;
        title = `${nationality} | ${documentSubType} | ${item?.documentNumber}`;
      }

      let expiresOn = '';
      if (item.expiresOn) {
        expiresOn = ` - ${item.expiresOn
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`;
      }
      const dateOfIssue = item.dateOfIssue
        ?.setLocale(i18n.language)
        .toLocaleString(DateTime.DATE_FULL, {
          locale: i18n.language,
        });
      return renderListItem({ description: `${dateOfIssue}${expiresOn}`, index, item, title });
    }
    if (targetDocument === 'UserTravelDocument') {
      let title = '';
      if (item?.validForCountry) {
        const validForCountryText = CountryListData.find(
          (object) => object.code === item?.validForCountry,
        )?.name;
        if (validForCountryText) {
          title = validForCountryText;
        }
      }
      if (item.description) {
        if (title.length) {
          title += ' | ';
        }
        title += item.description;
      }

      if (item.documentSubTypeMulti?.length) {
        if (title.length) {
          title += ' | ';
        }
        item.documentSubTypeMulti.forEach((sub: UserTravelDocumentSubTypeType) => {
          if (UserTravelDocumentSubTypeType[sub]) {
            if (!title.endsWith('| ')) {
              title += ', ';
            }
            title += t(`common.${UserTravelDocumentSubTypeType[sub]}`, {
              defaultValue: capitalize(
                (UserTravelDocumentSubTypeType[sub] || '').replace('_', ' ').toLowerCase(),
              ),
            });
          }
        });
      } else if (item.documentSubType) {
        if (title.length) {
          title += ' | ';
        }
        title += t(`common.${item.documentSubType}`, {
          defaultValue: capitalize((item.documentSubType || '').replace('_', ' ').toLowerCase()),
        });
      }
      return renderListItem({
        description: `${item.dateOfIssue
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, {
            locale: i18n.language,
          })} - ${item.expiresOn
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`,
        index,
        item,
        title,
      });
    }
    if (targetDocument === 'UserFrequentFlyerDocument') {
      let title = '';
      const airlineType = AirlineTypeList.find((x) => x.value === item?.airlineType);
      if (airlineType) {
        title = airlineType.name;
      }
      const airlineStatusType = AirlineStatusTypeList.find(
        (x) => x.value === item?.airlineStatusType,
      );
      if (airlineStatusType) {
        if (title.length) {
          title += ' | ';
        }
        title += airlineStatusType.name;
      }
      const airlineAllianceType = AirlineAllianceTypeList.find(
        (x) => x.value === item?.airlineAllianceType,
      );
      if (airlineAllianceType) {
        if (title.length) {
          title += ' | ';
        }
        title += airlineAllianceType.name;
      }
      return renderListItem({
        description: `${item.expiresOn
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`,
        index,
        item,
        title,
      });
    }
    if (targetDocument === 'UserVaccinationDocument') {
      let date = '';
      if (item.dateOfVaccination && DateTime.fromISO(item.dateOfVaccination).isValid) {
        date = DateTime.fromISO(item.dateOfVaccination).toLocaleString(DateTime.DATE_FULL, {
          locale: i18n.language,
        });
      }
      return renderListItem({
        description: `${
          date ? `${date} | ` : ''
        }${item?.description} | ${item?.vaccinationManufacturer}`,
        index,
        item,
        title: `${VaccinationTargetDiseaseList.find((x) => x.value === item?.diseaseType)?.name}${
          item?.vaccinationType ? ` | ${item?.vaccinationType}` : ''
        }${item?.documentNumber ? ` | ${item?.documentNumber}` : ''}`,
      });
    }
    if (targetDocument === 'UserHealthReport') {
      const diseaseType = VaccinationTargetDiseaseList.find(
        (object) => object.value === item?.diseaseType,
      )?.name;
      const testType = HealthTestTypeList.find((object) => object.value === item?.testType)?.name;
      const dateTimeOfTest = item.dateTimeOfTest
        ?.setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_FULL);
      return renderListItem({
        description: `${dateTimeOfTest} | ${item?.isResultPositive ? 'Positive' : 'Negative'}`,
        index,
        item,
        title: `${diseaseType} | ${testType}`,
      });
    }
    if (targetDocument === 'UserBackgroundDocument') {
      return renderListItem({
        description: `${item.dateOfIssue
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, {
            locale: i18n.language,
          })} - ${item.expiresOn
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`,
        index,
        item,
        title: `${item?.name} | ${CountryListData.find((x) => x.code === item?.countryOfIssue)
          ?.name}`,
      });
    }
    if (targetDocument === 'UserOtherDocument') {
      return renderListItem({
        description: `${item.dateOfIssue
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, {
            locale: i18n.language,
          })} - ${item.expiresOn
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`,
        index,
        item,
        title: `${item?.name} | ${CountryListData.find(
          (object) => object.code === item?.countryOfIssue,
        )?.name}`,
      });
    }
    return <></>;
  };

  if (!userDocumentList || userDocumentList.length === 0) {
    return (
      <View style={themedStyles.padding10}>
        <Text selectable={true}>Currently no documents.</Text>
      </View>
    );
  }
  return (
    <List
      style={themedStyles.fullWidth}
      data={userDocumentList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  );
}

const styles = StyleService.create({
  rightAccessoryLayout: {
    flexDirection: 'row',
  },
  spacedElement: {
    margin: 5,
  },
  moreButtonContainer: {
    height: 30,
    width: 30,
  },
  padding10: {
    padding: 10,
  },
  fullWidth: {
    width: '100%',
    height: '100%',
    backgroundColor: 'background-basic-color-1',
  },
});
