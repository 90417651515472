import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  wrapper: {
    borderRadius: 6,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '90%',
    backgroundColor: 'color-success-900',
    maxWidth: 600,
    marginHorizontal: 5,
  },
  scrollWrapper: {
    width: '100%',
    borderRadius: 6,
    overflow: 'hidden',
  },
  inner: {
    backgroundColor: 'color-success-900',
    maxWidth: 600,
    padding: 20,
    paddingTop: 14,
    borderRadius: 6,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 5,
    height: 44,
    width: 44,
    borderRadius: 22,
    padding: 5,
    marginTop: -3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'color-success-800',
  },
  closeIcon: {
    color: 'text-basic-color',
  },
  title: {
    marginTop: 5,
    marginBottom: 20,
    paddingRight: 40,
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'left',
    fontFamily: 'Lato_700Bold',
    color: 'white',
  },
  textLeft: {
    textAlign: 'left',
  },
  messageText: {
    color: 'white',
  },
});
