import React, { useEffect, useState } from 'react';
import { Platform, StyleProp, TextStyle, View } from 'react-native';

import {
  Button,
  Input,
  InputProps,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { TFunctionDetailedResult } from 'i18next';
import { useMediaQuery } from 'react-responsive';

import { globalStyle } from '../../../common/style';
import { AddIcon, SubtractIcon } from '../../icons.component';

export const FormAddSubtractCounter = (
  props: Omit<InputProps, 'value'> & {
    label: TFunctionDetailedResult | string;
    onCountChange: (value: number) => void;
    value?: number;
    maxCount?: number;
    minCount?: number;
    isMandatory?: boolean;
    caption?: TFunctionDetailedResult | string;
    style?: StyleProp<TextStyle>;
    errorMessage?: string;
  },
): React.ReactElement => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1224,
  });

  const styles = useStyleSheet(themedStyles);

  const [count, setCount] = useState<number | undefined>(0);

  useEffect(() => {
    if (props.value) {
      setCount(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (count) {
      props.onCountChange(count);
    }
  }, [count]);

  const SubtractButton = () => (
    <Button
      appearance="ghost"
      size="small"
      style={globalStyle.marginRight5}
      accessoryLeft={SubtractIcon}
      disabled={props.minCount && count ? count <= props.minCount : false}
      onPress={() => setCount(count ? Math.max(count - 1, props.minCount || 0) : props.minCount)}
    />
  );

  const AddButton = () => (
    <Button
      appearance="ghost"
      size="small"
      style={globalStyle.marginLeft5}
      accessoryLeft={AddIcon}
      disabled={props.maxCount && count ? count >= props.maxCount : false}
      onPress={() => setCount(count ? Math.min(count + 1, props.maxCount || 0) : props.minCount)}
    />
  );

  const parseInput = (text: string) => {
    if (text) {
      let newCount: undefined | number = text ? parseInt(text, 10) : 0;
      if (props.minCount && newCount < props.minCount) {
        return;
      }
      if (props.maxCount && newCount > props.maxCount) {
        return;
      }
      if (Number.isNaN(newCount)) {
        newCount = props.minCount ? props.minCount : undefined;
      }
      setCount(newCount);
    } else {
      setCount(undefined);
    }
  };

  return (
    <View
      style={[
        globalStyle.marginTop20,
        isTabletOrMobileDevice || Platform.OS !== 'web' ? styles.maxWidth100 : styles.maxWidth50,
      ]}
    >
      <View style={styles.labelContainer}>
        <Text style={styles.label}>
          {props.label as string}
          {props.isMandatory ? <Text style={styles.mandatoryStar}>*</Text> : <></>}
        </Text>
      </View>
      <View style={[globalStyle.flex1, globalStyle.flexRow]}>
        <SubtractButton />
        <Input
          {...props}
          style={[
            globalStyle.textAlignCenter,
            globalStyle.alignContentCenter,
            globalStyle.alignItemsCenter,
            props.style,
          ]}
          value={count?.toString()}
          onChangeText={parseInput}
          label={undefined}
          caption={undefined}
        />
        <AddButton />
      </View>
      {!!props.caption && (
        <View style={styles.captionContainer}>
          <Text style={styles.caption}>{props.caption as string}</Text>
        </View>
      )}
      {!!props.errorMessage && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.errorMessageText}>
            {props.errorMessage}
          </Text>
        </View>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  maxWidth100: {
    maxWidth: '100%',
  },
  maxWidth50: {
    maxWidth: '50%',
  },
  captionContainer: {
    marginTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  errorMessageText: {
    color: 'color-danger-500',
    paddingBottom: 5,
  },
});
