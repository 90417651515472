import React from 'react';
import { Pressable, View } from 'react-native';

import { FlashList } from '@shopify/flash-list';
import { Button, CheckBox, Text, useStyleSheet, Card } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { Input } from './Input';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../../common/style';
import { FormTextInputTopLabel } from '../../../../../components/common/form/form-text-input-top-label.component';
import useDimensions from '../../../../../hooks/useDimensions';
import { ImportContact } from '../types';

export const InviteCourierUploadCSVStepThree: React.FC<any> = ({
  data,
  handleImportContactsPress,
  handleResetDocument,
  handleSearchTextChange,
  searchText,
  handleCheckboxSelectAll,
  isAllSelected,
  tableWidth,
  handleCheckboxSelect,
  handleFieldTextChange,
  selectedInvites,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);
  const { dimensions, isSmallDevice, isLargeDevice, isExtraLargeDevice } = useDimensions();
  const isWebView = isLargeDevice || isExtraLargeDevice;

  const getItemStatus = (status: string) => {
    switch (status) {
      case 'alreadyConnected':
        return t('common:alreadyConnected', { defaultValue: 'Already connected' });
      case 'alreadyInvited':
        return t('common:alreadyInvited', { defaultValue: 'Already invited' });
      case 'invalidData':
        return t('common:invalidData', { defaultValue: 'Invalid data' });
      case 'inviteSuccess':
        return t('common:invited', { defaultValue: 'Invited' });
      case 'inviteFailed':
        return t('common:inviteFailed', { defaultValue: 'Invite failed' });
      default:
        return t('common:new', { defaultValue: 'New' });
    }
  };

  const keyExtractor = (item: ImportContact) => item.index.toString();

  const renderTableHeader = () => {
    if (!isWebView) {
      return (
        <Pressable
          style={[
            globalStyle.flexRow,
            globalStyle.justifyContentEnd,
            globalStyle.paddingTop5,
            globalStyle.paddingBottom15,
          ]}
          onPress={handleCheckboxSelectAll}
        >
          <Text
            style={[globalStyle.fontSize16, globalStyle.fontLatoBold, globalStyle.paddingRight10]}
          >
            {t('selectAll', { defaultValue: 'Select all' })}
          </Text>
          <CheckBox
            checked={isAllSelected}
            style={globalStyle.size20}
            onChange={handleCheckboxSelectAll}
          />
        </Pressable>
      );
    }
    return (
      <View
        style={[globalStyle.flex1, globalStyle.width100Percent, globalStyle.flexRow, styles.header]}
      >
        <View style={{ width: tableWidth[0] }}>
          <CheckBox
            checked={isAllSelected}
            style={globalStyle.size20}
            onChange={handleCheckboxSelectAll}
          />
        </View>
        <View style={{ width: tableWidth[1] }}>
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.paddingLeft5, globalStyle.fontSize14]}
          >
            {t('firstName', { defaultValue: 'First name' })}
          </Text>
        </View>
        <View style={{ width: tableWidth[2] }}>
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.paddingLeft5, globalStyle.fontSize14]}
          >
            {t('lastName', { defaultValue: 'Last name' })}
          </Text>
        </View>
        <View style={{ width: tableWidth[3] }}>
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.paddingLeft5, globalStyle.fontSize14]}
          >
            {t('email', { defaultValue: 'Email' })}
          </Text>
        </View>
        <View style={{ width: tableWidth[4] }}>
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.paddingLeft5, globalStyle.fontSize14]}
          >
            {t('phoneNumber', { defaultValue: 'Phone number' })}
          </Text>
        </View>
        <View style={{ width: tableWidth[5] }}>
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.paddingLeft5, globalStyle.fontSize14]}
          >
            {t('status', { defaultValue: 'Status' })}
          </Text>
        </View>
      </View>
    );
  };

  const renderTableItemWebView = ({ item, index }: { item: ImportContact; index: number }) => {
    const disabled = item.status !== 'new';
    const disabledError = item.status === 'alreadyConnected' || item.status === 'alreadyInvited';
    const checked = selectedInvites.includes(item.index);
    return (
      <View
        style={[
          globalStyle.flex1,
          globalStyle.width100Percent,
          globalStyle.flexRow,
          styles.row,
          index % 2 === 0 && styles.rowEven,
        ]}
      >
        <View style={[globalStyle.justifyContentCenter, { width: tableWidth[0] }]}>
          <CheckBox
            disabled={disabled}
            style={globalStyle.size20}
            onChange={handleCheckboxSelect(item)}
            checked={checked}
          />
        </View>
        <View style={{ width: tableWidth[1] }}>
          <Input
            error={!disabledError && !!item.firstNameError}
            handleFieldTextChange={handleFieldTextChange(item.index, 'firstName')}
            status={item.status}
            value={item.firstName}
          />
        </View>

        <View style={{ width: tableWidth[2] }}>
          <Input
            error={!disabledError && !!item.lastNameError}
            handleFieldTextChange={handleFieldTextChange(item.index, 'lastName')}
            status={item.status}
            value={item.lastName}
          />
        </View>

        <View style={{ width: tableWidth[3] }}>
          <Input
            error={!disabledError && !!item.emailError}
            handleFieldTextChange={handleFieldTextChange(item.index, 'email')}
            status={item.status}
            value={item.email}
          />
        </View>

        <View style={{ width: tableWidth[4] }}>
          <Input
            error={!disabledError && !!item.phoneNumberError}
            handleFieldTextChange={handleFieldTextChange(item.index, 'phoneNumber')}
            status={item.status}
            value={item.phoneNumber}
          />
        </View>

        <View
          style={[globalStyle.flex1, globalStyle.justifyContentCenter, { width: tableWidth[5] }]}
        >
          <Text
            style={[globalStyle.paddingRight10, globalStyle.fontSize14, globalStyle.lineHeight15]}
          >
            {getItemStatus(item.status) || '-'}
          </Text>
        </View>
      </View>
    );
  };

  const renderTableItemMobileView = ({ item }: { item: ImportContact }) => {
    const isSelected = selectedInvites.includes(item.index);
    const disabled = item.status !== 'new';
    const disabledError = item.status === 'alreadyConnected' || item.status === 'alreadyInvited';
    return (
      <Card key={item.index} style={styles.card} onPress={handleCheckboxSelect(item)}>
        <View style={[styles.cardInner, isSelected && styles.cardInnerSelected]}>
          <View style={[isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow]}>
            <View style={[globalStyle.flex1, globalStyle.flexColumn]}>
              <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
                <Text style={[styles.width160, globalStyle.fontLatoBold, globalStyle.fontSize16]}>
                  {t('firstName', { defaultValue: 'First name' })} {}
                </Text>
                <Input
                  error={!disabledError && !!item.firstNameError}
                  handleFieldTextChange={handleFieldTextChange(item.index, 'firstName')}
                  status={item.status}
                  value={item.firstName}
                />
              </View>

              <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
                <Text style={[styles.width160, globalStyle.fontLatoBold, globalStyle.fontSize16]}>
                  {t('lastName', { defaultValue: 'Last name' })}: {}
                </Text>
                <Input
                  error={!disabledError && !!item.lastNameError}
                  handleFieldTextChange={handleFieldTextChange(item.index, 'lastName')}
                  status={item.status}
                  value={item.lastName}
                />
              </View>

              <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
                <Text style={[styles.width160, globalStyle.fontLatoBold, globalStyle.fontSize16]}>
                  {t('email', { defaultValue: 'Email' })}: {}
                </Text>
                <Input
                  error={!disabledError && !!item.emailError}
                  handleFieldTextChange={handleFieldTextChange(item.index, 'email')}
                  status={item.status}
                  value={item.email}
                />
              </View>

              <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
                <Text style={[styles.width160, globalStyle.fontLatoBold, globalStyle.fontSize16]}>
                  {t('phoneNumber', { defaultValue: 'Phone number' })}: {}
                </Text>
                <Input
                  error={!disabledError && !!item.phoneNumberError}
                  handleFieldTextChange={handleFieldTextChange(item.index, 'phoneNumber')}
                  index={item.index}
                  status={item.status}
                  value={item.phoneNumber}
                />
              </View>
            </View>
          </View>
          <View
            style={[
              globalStyle.flexRow,
              globalStyle.alignItemsCenter,
              globalStyle.justifyContentEnd,
            ]}
          >
            <Text
              style={[
                globalStyle.width150,
                globalStyle.paddingRight10,
                globalStyle.textAlignRight,
                globalStyle.fontLatoBold,
                globalStyle.lineHeight14,
              ]}
            >
              {getItemStatus(item.status) || '-'}
            </Text>
            <CheckBox
              disabled={disabled}
              style={globalStyle.size20}
              onChange={handleCheckboxSelect(item)}
              checked={selectedInvites.includes(item.index)}
            />
          </View>
        </View>
      </Card>
    );
  };

  return (
    <View style={globalStyle.width100Percent}>
      <FormTextInputTopLabel
        containerStyle={[globalStyle.width75Percent, isWebView && globalStyle.marginBottom30]}
        label={t('common.searchContactsHere', {
          defaultValue: 'Search contacts here',
        })}
        onChangeText={handleSearchTextChange}
        value={searchText}
      />

      <FlashList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        ListHeaderComponent={renderTableHeader()}
        data={data || []}
        estimatedItemSize={40}
        estimatedListSize={{
          height: dimensions.window.height - 100,
          width: dimensions.window.width > 1200 ? 1200 : dimensions.window.width,
        }}
        renderItem={isWebView ? renderTableItemWebView : renderTableItemMobileView}
        keyExtractor={keyExtractor}
        extraData={selectedInvites}
      />
      <View
        style={[
          isWebView ? globalStyle.flexRow : globalStyle.flexColumn,
          globalStyle.justifyContentBetween,
          globalStyle.marginTop10,
          globalStyle.paddingBottom20,
        ]}
      >
        <Button
          appearance="filled"
          status="danger"
          size="medium"
          onPress={handleResetDocument}
          style={[globalStyle.marginTop10, globalStyle.width300]}
        >
          {t('common:uploadANewSCVFile', {
            defaultValue: 'Upload a new CSV file',
          })}
        </Button>
        <Button
          appearance="filled"
          status=""
          disabled={!selectedInvites.length}
          size="medium"
          onPress={handleImportContactsPress}
          style={[globalStyle.marginTop10, globalStyle.width300]}
        >
          {t('common:importAndInviteSelectedCouriers', {
            defaultValue: 'Import and invite selected Couriers',
          })}
        </Button>
      </View>
    </View>
  );
};
