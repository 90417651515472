import React from 'react';
import { StyleProp, TextStyle, View } from 'react-native';

import {
  Input,
  InputProps,
  LayoutProps,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';

import { ITranslate } from '../../../types';

export interface FormInputProps extends LayoutProps {
  hint: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string | undefined;
  isLoading?: boolean;
  isDisabled?: boolean;
  isEditMode?: boolean;
}

export const FormTextInputTopLabel = (
  props: Omit<InputProps, 'caption' | 'label'> & {
    caption?: JSX.Element | ITranslate | string;
    isMandatory?: boolean;
    label?: ITranslate | string;
    labelExtra?: string;
    containerStyle?: StyleProp<TextStyle>;
    style?: StyleProp<TextStyle>;
    errorMessage?: string;
    maxLength?: number;
  },
): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);

  const renderLabel = (evaProps: any) => {
    if (props.label) {
      return (
        <View style={styles.labelContainer}>
          <Text {...evaProps} style={styles.label}>
            {props.label as string}
            {!!props.isMandatory && (
              <Text selectable={true} style={styles.mandatoryStar}>
                *
              </Text>
            )}
            {!!props.labelExtra && (
              <Text {...evaProps} style={styles.labelExtra}>
                {props.labelExtra}
              </Text>
            )}
          </Text>
          {!!props.maxLength && (
            <Text
              style={[
                styles.maxLength,
                props.value?.length === props.maxLength && styles.maxLengthError,
              ]}
            >
              {props.value?.length || 0}/{props.maxLength}
            </Text>
          )}
        </View>
      );
    }
    if (!props.label && props.maxLength) {
      return (
        <View style={styles.labelContainer}>
          <Text {...evaProps} style={styles.label} />
          {!!props.maxLength && (
            <Text
              style={[
                styles.maxLength,
                props.value?.length === props.maxLength && styles.maxLengthError,
              ]}
            >
              {props.value?.length || 0}/{props.maxLength}
            </Text>
          )}
        </View>
      );
    }
    return <></>;
  };

  const renderCaption = (evaProps: any) => {
    if (!props.caption && !props.errorMessage) {
      return <></>;
    }
    return (
      <View style={styles.captionContainer}>
        {!!props.errorMessage && (
          <Text selectable={true} style={styles.errorMessageText}>
            {props.errorMessage}
          </Text>
        )}
        {!!props.caption && (
          <Text selectable={true} {...evaProps} style={styles.caption}>
            {props.caption as string}
          </Text>
        )}
      </View>
    );
  };

  return (
    <View style={[styles.container, props.containerStyle]}>
      <Input
        {...props}
        status={props.errorMessage ? 'danger' : 'basic'}
        style={props.style}
        label={renderLabel}
        caption={renderCaption}
      />
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
  },
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  errorMessageText: {
    color: 'color-danger-500',
    fontSize: 13,
    paddingBottom: 5,
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  maxLength: {
    fontSize: 13,
  },
  maxLengthError: {
    color: 'color-danger-600',
    fontSize: 13,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  labelExtra: {
    fontSize: 11,
    color: 'color-basic-300',
    marginLeft: 5,
  },
});
