const validUrlRegex = new RegExp(
  '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?',
);
const validUrlRegexWithSuffix =
  /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)+(\/\w+\/?)/;

const validEmailRegex = new RegExp('^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$');

export const isValidURL = ({
  canBeEmpty = false,
  hasToHaveSuffix,
  value,
}: {
  canBeEmpty: boolean;
  value: string | null | undefined;
  hasToHaveSuffix?: boolean;
}): boolean => {
  if (!value) {
    if (canBeEmpty) {
      return true;
    }
    return false;
  }
  if (hasToHaveSuffix) {
    return validUrlRegexWithSuffix.test(value);
  }
  return validUrlRegex.test(value);
};

export const isValidEmail = ({
  canBeEmpty = false,
  value,
}: {
  value: string | null | undefined;
  canBeEmpty: boolean;
}): boolean => {
  if (!value) {
    if (canBeEmpty) {
      return true;
    }
    return false;
  }
  return validEmailRegex.test(value);
};
