import { useCallback, useState } from 'react';
import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number): (() => void) => {
  const savedCallback = useRef<() => void>();
  const intervalId = useRef<ReturnType<typeof setInterval> | undefined>();
  const [currentDelay, setDelay] = useState<number | null>(delay);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleRunning = useCallback(
    () => setDelay((_currentDelay) => (_currentDelay === null ? delay : null)),
    [delay],
  );

  const clear = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (intervalId.current) {
      clear();
    }

    if (currentDelay !== null) {
      intervalId.current = setInterval(tick, currentDelay);
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);

  useEffect(() => {
    return () => clear();
  }, []);

  return clear;
};
