export const getNotificationSettingOptions = (isCourier: boolean, t: any) => {
  if (isCourier) {
    return {
      email: {
        title: t('common.emailNotifications', { defaultValue: 'Email notifications' }),
        items: [
          {
            key: 'emailJob',
            description: t('common.jobsNotificationDescription', {
              defaultValue: 'Get email notifications when there are job status updates.',
            }),
            isLast: true,
            title: t('common.jobsNotificationTitle', { defaultValue: 'Jobs' }),
          },
        ],
      },
      push: {
        title: t('common.pushNotifications', { defaultValue: 'Push notifications' }),
        items: [
          {
            key: 'pushChat',
            description: t('common.chatNotificationDescription', {
              defaultValue: 'Get notified when a new message arrives.',
            }),
            title: t('common.chatNotificationTitle', { defaultValue: 'Chat' }),
          },
          {
            key: 'pushJob',
            description: t('common.jobsNotificationDescription', {
              defaultValue: 'Get notified when there are job status updates.',
            }),
            isLast: true,
            title: t('common.jobsNotificationTitle', { defaultValue: 'Jobs' }),
          },
        ],
      },
      system: {
        title: t('common.systemNotifications', { defaultValue: 'System notifications' }),
        items: [
          {
            key: 'systemChat',
            description: t('common.chatNotificationDescription', {
              defaultValue: 'Get activity notifications in the app when a new message arrives.',
            }),
            title: t('common.chatNotificationTitle', { defaultValue: 'Chat' }),
          },
          {
            key: 'systemJob',
            description: t('common.jobsNotificationDescription', {
              defaultValue:
                'Get activity notifications in the app when there are job status updates.',
            }),
            isLast: true,
            title: t('common.jobsNotificationTitle', { defaultValue: 'Jobs' }),
          },
        ],
      },
    };
  }
  return {
    email: {
      title: t('common.emailNotifications', { defaultValue: 'Email notifications' }),
      items: [
        {
          key: 'emailJob',
          description: t('common.jobsNotificationDescription', {
            defaultValue: 'Get email notifications when there are job status updates.',
          }),
          isLast: true,
          title: t('common.jobsNotificationTitle', { defaultValue: 'Jobs' }),
        },
      ],
    },
    push: {
      title: t('common.pushNotifications', { defaultValue: 'Push notifications' }),
      items: [
        {
          key: 'pushAvailability',
          description: t('common.availabilitiesNotificationDescription', {
            defaultValue:
              'Get notified when a connected courier becomes available or schedules an availability.',
          }),
          title: t('common.availabilitiesNotificationTitle', { defaultValue: 'Availabilities' }),
        },
        {
          key: 'pushChat',
          description: t('common.chatNotificationDescription', {
            defaultValue: 'Get notified when a new message arrives.',
          }),
          title: t('common.chatNotificationTitle', { defaultValue: 'Chat' }),
        },
        {
          key: 'pushConnection',
          description: t('common.connectionsNotificationDescription', {
            defaultValue: 'Get notified when a new courier successfully connects with your agency.',
          }),
          title: t('common.connectionsNotificationTitle', { defaultValue: 'Connections' }),
        },
        {
          key: 'pushJob',
          description: t('common.jobsNotificationDescription', {
            defaultValue: 'Get notified when there are job status updates.',
          }),
          isLast: true,
          title: t('common.jobsNotificationTitle', { defaultValue: 'Jobs' }),
        },
      ],
    },
    system: {
      title: t('common.systemNotifications', { defaultValue: 'System notifications' }),
      items: [
        {
          key: 'systemAvailability',
          description: t('common.availabilitiesNotificationDescription', {
            defaultValue:
              'Get activity notifications in the app when a connected courier becomes available or schedules an availability.',
          }),
          title: t('common.availabilitiesNotificationTitle', { defaultValue: 'Availabilities' }),
        },
        {
          key: 'systemChat',
          description: t('common.chatNotificationDescription', {
            defaultValue: 'Get activity notifications in the app when a new message arrives.',
          }),
          title: t('common.chatNotificationTitle', { defaultValue: 'Chat' }),
        },
        {
          key: 'systemConnection',
          description: t('common.connectionsNotificationDescription', {
            defaultValue:
              'Get an activity notification in the app when a new courier successfully connects with your agency.',
          }),
          title: t('common.connectionsNotificationTitle', { defaultValue: 'Connections' }),
        },
        {
          key: 'systemJob',
          description: t('common.jobsNotificationDescription', {
            defaultValue:
              'Get activity notifications in the app when there are job status updates.',
          }),
          isLast: true,
          title: t('common.jobsNotificationTitle', { defaultValue: 'Jobs' }),
        },
      ],
    },
  };
};
