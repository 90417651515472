import React, { useContext, useEffect, useState } from 'react';
import { AppState, AppStateStatus, Platform, View } from 'react-native';

import { Button, Icon, Text, StyleService } from '@ui-kitten/components';
import { PermissionStatus } from 'expo-modules-core';
import * as ExpoNotifications from 'expo-notifications';
import { useTranslation } from 'react-i18next';

import ContentBox from './content-box.component';
import { globalStyle } from '../common/style';
import AppUserContext from '../contexts/AppUserContext';
import { openAppSettings, updateDevice } from '../modules/device/device.module';

const NotificationsSettingsBox = () => {
  const { t } = useTranslation();

  const appUserContext = useContext(AppUserContext);

  const [notificationPermissions, setNotificationPermissions] =
    useState<ExpoNotifications.NotificationPermissionsStatus>();

  const [appState, setAppState] = useState<AppStateStatus>(AppState.currentState);

  const updateNotificationPermissionStatus = async () => {
    setNotificationPermissions(await ExpoNotifications.getPermissionsAsync());
  };

  const _handleAppStateChange = async (nextAppState: AppStateStatus) => {
    setAppState(nextAppState);
    updateNotificationPermissionStatus();
  };

  useEffect(() => {
    setAppState(AppState.currentState);
    const subscription: any | { remove: () => void } = AppState.addEventListener(
      'change',
      _handleAppStateChange,
    );

    return () => {
      if (subscription instanceof Object) {
        subscription?.remove();
      }
    };
  }, [appState]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      updateNotificationPermissionStatus();
    }
  }, []);

  const requestPermissionsAsync = async () => {
    const result = await ExpoNotifications.requestPermissionsAsync(
      Platform.OS === 'ios'
        ? {
            ios: {
              allowAlert: true,
              allowBadge: true,
              allowSound: true,
              allowAnnouncements: true,
              allowDisplayInCarPlay: true,
              allowCriticalAlerts: true,
            },
          }
        : {
            android: {},
          },
    );
    const device = await updateDevice({
      rebuildAndOverwriteDeviceProperties: true,
    });
    appUserContext.setDevice(device);
    return result;
  };

  return (
    <ContentBox
      Title={
        <View>
          <Text
            selectable={true}
            style={[globalStyle.fontLatoBold, globalStyle.textTransformUppercase]}
          >
            {t('common.notificationSettings', { defaultValue: 'Notifications Settings' }) as string}
          </Text>
        </View>
      }
      Content={
        <View style={globalStyle.justifyContentCenter}>
          {notificationPermissions?.granted === true ? (
            <View>
              <Text selectable={true} style={styles.text}>
                <Icon name="checkmark-square-outline" fill="#2D7304" style={globalStyle.size24} />{' '}
                {
                  t('common.notificationsEnabled', {
                    defaultValue:
                      'Notifications are enabled on this device. To disable Notifications, go to settings.',
                  }) as string
                }
              </Text>

              <Button
                onPress={openAppSettings}
                appearance="outline"
                size="small"
                style={styles.marginHorizontal20Percent}
              >
                Go to settings
              </Button>
            </View>
          ) : (
            <>
              {notificationPermissions?.status === PermissionStatus.UNDETERMINED ? (
                <Button
                  onPress={requestPermissionsAsync}
                  appearance="outline"
                  size="small"
                  style={styles.marginHorizontal20Percent}
                >
                  Set up notification permissions
                </Button>
              ) : (
                <View style={globalStyle.paddingHorizontal20}>
                  <Text selectable={true} style={styles.text}>
                    <Icon name="close-circle-outline" fill="#C6141B" style={globalStyle.size24} />{' '}
                    {
                      t('common.notificationsDisabled', {
                        defaultValue:
                          'Notifications are disabled on this device. To enable Notifications, go to settings',
                      }) as string
                    }
                  </Text>
                  <Button
                    onPress={openAppSettings}
                    appearance="outline"
                    size="small"
                    style={styles.marginHorizontal20Percent}
                  >
                    Go to settings
                  </Button>
                </View>
              )}
            </>
          )}
        </View>
      }
    />
  );
};

export { NotificationsSettingsBox };

const styles = StyleService.create({
  marginHorizontal20Percent: {
    marginHorizontal: '20%',
  },
  text: {
    lineHeight: 25,
    textAlign: 'justify',
    marginBottom: 20,
  },
});
