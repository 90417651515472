import { RetryLink } from '@apollo/client/link/retry';

export const retryLink: RetryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 20,
    retryIf: (error, _operation) => {
      if (error && error.statusCode && [400, 403].includes(error.statusCode)) {
        return false;
      }
      return true;
    },
  },
});
