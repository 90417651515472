import { I18nConfig, NativeDateService } from '@ui-kitten/components';

const calendarTranslations: { [key: string]: I18nConfig } = {
  en: {
    dayNames: {
      short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      long: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    },
    monthNames: {
      short: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      long: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
  },
  de: {
    dayNames: {
      short: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      long: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ],
    },
    monthNames: {
      short: [
        'Jan',
        'Feb',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'Aug',
        'Sept',
        'Okt',
        'Nov',
        'Dez',
      ],
      long: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
    },
  },
};

const startDayOfWeek: { [key: string]: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined } = {
  en: 1,
  de: 1,
};

export const getLocaleDateService = (locale = 'en') => {
  if (!Object.keys(calendarTranslations).includes(locale)) {
    locale = 'en';
  }

  return new NativeDateService(locale, {
    i18n: calendarTranslations[locale],
    startDayOfWeek: startDayOfWeek[locale],
  });
};
