export default {
  translation: {
    activation: {
      errorMessage: 'Activation error',
      activateAccount: 'Activate Account',
      activationSuccess: 'Activation success',
      activationSuccessDescription: 'Account activated successfully',
      pleaseWait: 'Please wait',
      errorRetryMessage: 'Error during activation. Retry',
      enterActivationCode: 'Enter 8 digit activation code',
      noAgencyNameGiven: 'No Agency name given',
      activationCode: 'Activation Code',
      verifyActivationCode: 'Verify Activation Code',
      connectWithAgency: 'Connect with Agency',
      connectionSuccessful: 'Connection successful',
      verifyingActivationCode: 'Verifying Activation Code...',
      connectingWithAgency: 'Connecting with Agency...',
      enterAValidActivationCode: 'Enter a valid Activation Code',
      invalidActivationCode: 'Invalid Activation Code',
      addAgencyIntroText:
        'You need an invitation to connect to an Agency. Reach out to your Agencies to obtain an Activation Code. Enter the Activation Code you have received via SMS below.',
      connectWithAgencyExplanation:
        'Press button <strong>Connect with Agency</strong> below in order to connect your Courier Organization with the Agency displayed above. After successfully connecting, the Agency will have access to your profile data and documents so that it can offer you OBC jobs that fit your profile.',
      errorDescription:
        'Something went wrong with account activation. Please try again or reach out to support@priojet.com',
    },
    activityNotification: {
      newActivity: 'New Activity',
      recentActivity: 'Recent Activity ({{displayed}}/{{total}})',
      noRecentActivity: 'Recent activity',
      markAllAsRead: 'Mark all as read',
      noRecentActivityInTheLastNDays: 'No recent activity in the last 14 days.',
      seeAllActivity: 'See all Activity',
      activityAndNotifications: 'Activity & Notifications',
      noNewNotifications:
        'There are currently no system notifications or activities that are less than 14 days old or unread.',
    },
    agencyCourierOpenInvitations: {
      courierInvitations: 'Courier Invitations',
      orgInvitationDescription_canBeUsedToJoin: 'Can be used to join the ',
      orgInvitationDescription_withRole: 'with Role ',
      orgInvitationDescription_canBeUsedToConnect: 'Can be used to connect with a ',
      contactDetails: 'Contact Details',
      description: 'Description',
      orgInvitationDescription_activationCodeCanBeUsedToActivateANew:
        'Activation code can be used to activate a new ',
      orgInvitationDescription_onThePlatformAndConnectToIt: ' on the platform and connect to it.',
      orgInvitationDescription_theActivationCodeWasSentToAPhoneBook:
        'The activation code was sent to a phone book contact. \nThis can be used to activate an Organization while connecting to ',
      other: 'Other...',
      contactPriojetToSendCode: '*** contact PRIOjet to send the code again to the Courier.',
      orgInvitationDescription_noOrganizationInvitationsFound: 'No organization invitations found.',
    },
    agencyPhoneBookContactsList: {
      invalidDataTitle: 'Invalid Data',
      invalidDataDescription:
        'There must be both valid international phone numbers with a country code (e.g. +49) and email addresses present for each contact.',
      success: 'Success',
      error: 'Error',
      onSelectPhoneOnlyMessage:
        'Do you want to invite the Contact named {{ item.name }} with phone number {{phoneNumber}}',
      onSelectWithEmailMessage: ' and email {{email}}?',
      'Import ': 'Import',
      invalidPhoneNumberFormatTitle: 'Invalid phone number format',
      invalidPhoneNumberFormatDescription:
        'Some phone numbers of the contact {{item.name}} do not have the right format. Please add the country code to the phone number.\n------------\n{{number}}\n------------\nThe following German number 01234567890 would become +491234567890 (Remove any leading 0 and add + plus country code).\n\nPlease go to your phone book or contact app and change the phone numbers there.  ',
      filterBoxPlaceholder: 'Filter by name, email, number, address, company or job',
      noContactsWith: 'No contacts with',
      foundOnThisDevice: 'found on this device',
    },
    agencyProfile: {
      error: 'Error',
      someFieldAreNotValid: 'Some fields are not valid',
      headquartersAddressIsNotValid: 'Headquarters address is not valid',
      invoicingAddressIsNotValid: 'Invoicing address is not valid',
      success: 'Success',
      profileUpdated: 'Agency profile successfully updated',
      somethingWentWrong: 'Something went wrong',
      requiredField: 'Must be set',
      noCameraPermissions: 'Sorry, we need camera roll permissions to make this work!',
      errorUploadingLogoImage: 'Something went wrong with uploading logo image',
      errorDeletingLogoImage: 'Something went wrong with deleting logo image',
      agencyName: 'Agency name',
      agencyNamePlaceholder: 'The Name of the Agency',
      agencyShortName: 'Agency short name',
      agencyShortNamePlaceholder: 'Enter the short name of the agency',
      primaryEmailAddress: 'Primary agency email address',
      primaryEmailPlaceholder: 'Enter primary email address for the agency',
      primaryPhoneNumber: 'Primary agency phone number',
      primaryPhonePlaceholder: 'Enter primary phone number for the agency',
      agencyLegalName: 'Agency legal name',
      agencyLegalNamePlaceholder: 'Enter the legal name of the agency',
      headquartersAgencyAddress: 'Address of the agency headquarters',
      addressName: 'Address name',
      headquartersAddressPlaceholder: "Search for the Agency's headquarter address here",
      headquartersAddress: 'Headquarters Address',
      companyRegistrationNumber: 'Company registration number',
      companyRegistrationNumberPlaceholder: 'Enter company registration number if available',
      vatId: 'Tax registration number (VAT ID)',
      vatIdPlaceholder: 'Enter tax registration number (VAT ID) if available',
      officialPostalInvoiceAddress: 'Official postal invoice address',
      officialPostalInvoiceAddressPlaceholder: 'Search official postal invoice address here',
      invoiceEmail: 'Invoice email address',
      invoiceEmailPlaceholder: 'Enter invoice email address',
      descriptionPlaceholder:
        'Provide some information about your Agency (foundation year, number of couriers, accomplished jobs, ...)',
      myAgencyProfile: 'My Agency Profile',
      generalInformation: 'General information',
      legalInformation: 'Legal information',
      invoiceInformation: 'Invoice information',
      socialMedia: 'Social Media',
      aboutYourAgency: 'About your Agency',
    },
    appUpdate: {
      title: 'Time to Update',
      textMandatory: 'Please update the app, the current installed version is no longer supported!',
      text: 'We added a lot of features and some important bug fixes to make your experience as smooth as possible.',
      update: 'Update',
      notNow: 'Not now',
    },
    common: {
      badInput: 'Bad input',
      Copied: 'Copied!',
      'Email:': 'Email:',
      firstNames: 'First Names',
      lastName: 'Last Name',
      email: 'Email',
      phoneNumber: 'Phone Number',
      gender: 'Gender',
      dateOfBirth: 'Date of birth',
      placeOfBirth: 'Place of birth',
      countryOfBirth: 'Country of birth',
      nationality: 'Nationality',
      taxCountry: 'Tax Country',
      languagesKnown: 'Languages Known',
      address: 'Address',
      emergencyContact: 'Emergency Contact',
      noNameProvided: 'No name provided',
      CARDS: 'CARDS',
      creditCard: 'Credit Card',
      debitCard: 'Debit Card',
      creditLimit: 'Credit Limit: ',
      notSpecified: 'Not specified',
      socialMedia: 'Social Media',
      continue: 'Continue',
      cancel: 'Cancel',
      startAirport: 'Start Airport',
      No: 'No',
      Yes: 'Yes',
      importAndInviteCourierContact: 'Import and invite \ncourier contact',
      alreadyImportedAndInvited: 'Already imported and invited',
      noInvitationWasUsed: 'No invitation was used',
      courierJobUpdated: 'Courier Job updated',
      changeToRelationshipType: 'Change to {{relationshipType}}',
      dailyRate: 'Daily Rate',
      fixedRate: 'Fixed Rate',
      kickOffFee: 'Kick-off fee',
      hotelBudget: 'Hotel budget',
      new: 'NEW',
      running: 'RUNNING',
      finished: 'FINISHED',
      cancelled: 'CANCELLED',
      createJob: 'Create Job',
      seeAllJobs: 'See all Jobs',
      currentlyLoggedInAs: 'Currently logged in as',
      yourAccounts: 'Your Accounts',
      deleteDocument: 'Delete Document',
      deleteDocumentMessage: 'Do you want to delete this document?',
      'Copied!': 'Copied!',
      copiedTextMessage: 'Copied {{copyableTextName}} to Clipboard!',
      emailCopySuccessMessageDescription: 'Copied email {{email}} to clipbaord.',
      noEmailProvided: 'No email provided',
      Copy: 'Copy',
      sendEmail: 'Email',
      typeHere: 'Type here',
      success: 'Success',
      error: 'Error',
      successfullySaved: '{{type}} successfully saved',
      somethingWentWrongSendingMessage:
        'Something went wrong while sending message. You can try sending your message again.',
      addImage: 'Add image',
      addPdfDocument: 'Add PDF document',
      edit: 'Edit',
      delete: 'Delete',
      messageAlreadyRepliedCannotBeEdited:
        'Message already has reply. Past messages cannot be edited or deleted.',
      chatDisabledBackendNotReachable: 'Sending of messages disabled without network connection...',
      addressCopySuccessMessageDescription: 'Copied address {{formattedAddress}} to clipbaord.',
      noAddressProvided: 'No address provided',
      noAddressFound: 'No address found',
      phoneNumberCopySuccessMessageDescription: 'Copied phone number {{phoneNumber}} to clipbaord.',
      signUpNotSuccessful: 'Sign up was not successful.',
      signUpNotSuccessfulDescription:
        'Sign up not successful. Please try again or contact the PRIOjet support support@priojet.com.',
      signUpAsAgency: 'Sign up as Agency',
      signUpAsCourier: 'Sign up as Courier',
      signUp: 'Sign up',
      signUpCancelledVerificationNeeded: 'Sign up successful, extra verification needed',
      signUpCancelledPhoneNumberDescription:
        'Your account has been created without verifying your phone number. In order to use your account press Sign In button below.',
      signUpCancelled: 'Sign up cancelled',
      signUpCancelledMessageDescription:
        'Sign up cancelled. To try again, press the sign up button below. In case a popup does not open, please restart the app.',
      flashMessageProblemDuringSignUp:
        'Our servers are currently not reachable. Please check your internet connection. Cannot proceed with sign up.',
      problemOccurredDuringSignUpNotSuccessful:
        'A problem occurred during sign-up. Sign-up not successful.',
      signUpSuccessful: 'Sign-up successful',
      flashMessageSignUpSuccessful:
        'We successfully created your account. You must activate it first before you can use it.',
      tryAgain: 'Try again',
      days: 'days',
      hours: 'hours',
      minutes: 'minutes',
      in: 'in',
      availabilityTZ: 'Availability TZ',
      yourTZ: 'Your TZ',
      Profile: 'Profile',
      startTime: 'Start Time',
      endTime: 'End Time',
      createdAt: 'Created At',
      yourLocalTime: 'Your local time:',
      utcGmtTime: 'UTC / GMT time:',
      decimalSeparator: '.',
      digitGroupSeparator: ',',
      selectHour: 'Select hour',
      selectMinute: 'Select minute',
      loadingSpinnerTextWithSubject: 'Loading {{subject}}...',
      loadingSpinnerText: 'Loading...',
      organizationInvitationVoided: 'Organization Invitation voided',
      English: 'English',
      German: 'German',
      Spanish: 'Spanish',
      French: 'French',
      statusBarReconnecting: 'STATUS_BAR_RECONNECTING',
      statusBarApiNotReachable: 'API {{apiEndpoint}} is not reachable ...',
      noAgencyConnectionsForFilterTerm:
        'No connected Agencies found for filter term "{{filterTerm}}".',
      nameOfTheDocument: 'NAME OF THE DOCUMENT',
      issuingInstitution: 'ISSUING INSTITUTION',
      dateOfIssue: 'DATE OF ISSUE',
      dateOfExpiry: 'DATE OF EXPIRY',
      countryOfIssue: 'COUNTRY OF ISSUE',
      documentDescription: 'DOCUMENT DESCRIPTION',
      placeOfIssue: 'PLACE OF ISSUE',
      frequentFlyerNumber: 'FREQUENT FLYER NUMBER',
      airline: 'AIRLINE',
      airlineStatus: 'AIRLINE STATUS',
      airlineAllianceType: 'AIRLINE ALLIANCE TYPE',
      airlineAllianceStatusType: 'AIRLINE ALLIANCE STATUS TYPE',
      diseaseOrPathogen: 'DISEASE OR PATHOGEN',
      testType: 'TEST TYPE',
      testDetails: 'TEST DETAILS',
      dateOfTest: 'DATE OF TEST',
      testCenter: 'TEST CENTER',
      cityOfTest: 'CITY OF TEST',
      countryOfTest: 'COUNTRY OF TEST',
      resultIsPositive: 'RESULT IS POSITIVE',
      documentType: 'DOCUMENT TYPE',
      documentNumber: 'DOCUMENT NUMBER',
      documentFeature: 'DOCUMENT FEATURE',
      sex: 'SEX',
      chooseOption: 'Choose Option',
      associatedIdentityDocument: 'ASSOCIATED IDENTITY DOCUMENT',
      vaccineManufacturer: 'Vaccine Manufacturer',
      vaccinationType: 'Vaccination Type',
      vaccinationDescription: 'Vaccination Description',
      dateOfVaccination: 'DATE OF VACCINATION',
      vaccinationCenter: 'VACCINATION CENTER',
      cityOfVaccination: 'CITY OF VACCINATION',
      countryOfVaccination: 'COUNTRY OF VACCINATION',
      vaccinationCompleted: 'VACCINATION COMPLETED',
      loading: 'Loading',
      setUnavailable: 'Set unavailable',
      setAvailable: 'Set available',
      setAvailableLocationDisabled: 'Set available (Location disabled)',
      available: 'available',
      unavailable: 'unavailable',
      setAvailabilityDates: 'Set availability dates',
      pickupLocation: 'Pickup Location',
      pickupTime: 'Pickup time',
      dropoffLocation: 'Drop-off Location',
      returnTimeAtStartAirport: 'Return time at start airport',
      jobUpdated: 'Job updated',
      documentDetails: 'DOCUMENT DETAILS',
      licenceClass: 'LICENSE CLASS',
      airlineType: 'AIRLINE TYPE',
      diseasePathogen: 'DISEASE/PATHOGEN',
      documentName: 'DOCUMENT NAME',
      testResult: 'TEST RESULT',
      typeOfVaccination: 'TYPE OF VACCINATION',
      validUntil: 'VALID UNTIL',
      notificationSettings: 'Notifications Settings',
      notificationsEnabled:
        'Notifications are enabled on this device. To disable Notifications, go to settings.',
      notificationsDisabled:
        'Notifications are disabled on this device. To enable Notifications, go to settings',
      flashMessageProblemDuringSignIn:
        'Our servers are currently not reachable. Please check your internet connection. Cannot sign in currently.',
      signIn: 'Sign in',
      signInInfoText: 'A window will open where you have to enter your credentials to sign in.',
      signUpAsAnAgency: 'Sign up as an Agency',
      signUpAsACourier: 'Sign up as a Courier',
      selectLanguageLabel: 'Change the language of the app here.',
      selectLanguageCaption: 'The default language is based on your system settings.',
      changeLanguage: 'Change language',
      toggleTheme: 'Toggle theme',
      activeTheme: 'Active theme',
      removeUserFromAccountList: 'Remove user from account list',
      flashMessageProblemSignInUserContext:
        'We could not login your user account with email {{userEmail}}',
      downloadNotPossibleWithoutPermission:
        'The download is currently not possible. To enable it, enable permission in the device and app settings.',
      somethingWentWrongWithFileDownload: 'Something went wrong with file download.',
      screenNameIsComingSoon: '{{screenName}} is coming soon',
      activateAccount: 'Activate Account',
      login: 'Login',
      home: 'Home',
      availabilities: 'Availabilities',
      welcome: 'Welcome',
      setupDevice: 'Setup Device',
      couriersForJobSuccessfullyChanged: 'Couriers for Job successfully changed',
      changedCouriersForAgencyJobDescription:
        'Successfully changed the Couriers enabled for this Job.',
      courierAvailabilities: 'Courier Availabilities',
      courierName: 'Courier Name',
      manageCouriersPriojetJobIdentifier: 'Manage Couriers ({{priojetJobIdentifier}})',
      courierProfile: 'Courier Profile',
      save: 'Save',
      newJob: 'New Job',
      jobs: 'Jobs',
      chooseFromGallery: 'Choose from Gallery',
      takePicture: 'Take a Picture',
      doYouWantToReload: 'Do you want to reload the profile?',
      unsavedDataWillBeLost: 'Unsaved data will be lost',
      refresh: 'Refresh',
      documentSuccessfullyDownloaded: 'Document successfully downloaded',
      timeOfTest: 'TIME OF TEST',
      upcoming: 'UPCOMING',
      addressName: 'Address name',
      street: 'Street',
      houseNumber: 'House number',
      postalCode: 'Postal Code',
      city: 'City',
      state: 'State',
      country: 'Country',
      agencyProfile: 'Agency Profile',
      Length: 'Length',
      filterAgencyByTerms: 'Filter Agency by name, city, or country',
      viewJob: 'VIEW JOB',
      couldNotCopyPhoneNumberNoPhoneNumberGiven:
        'Could not copy phone number. No phone number given.',
      cannotOpenPhoneApp: 'Cannot open phone app to make a call.',
      noPhoneNumberProvided: 'No phone number provided',
      pickupTimeText: 'Pickup <TimeAgoPopover></TimeAgoPopover>',
      returnTimeText: 'Return  <TimeAgoPopover></TimeAgoPopover>',
      connectedAtByUserTextTimeAgo: 'Connected <TimeAgoPopover></TimeAgoPopover>',
      formattedDuration: '<0>Duration</0>: {{duration}}.',
      formattedSince: '<0>Since</0> {{duration}}.',
      destinationLocation: 'destination location',
      createdAtByUserText:
        'Created <TimeAgoPopover></TimeAgoPopover> by {{firstNames}} {{lastName}} ({{email}})',
      endedTimeAgo: 'Ended {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      startedTimeAgo: 'Started {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeframesTimeAgoTextStartToEnd:
        'Started <TimeAgoPopoverStart></TimeAgoPopoverStart>. Ended <TimeAgoPopoverEnd></TimeAgoPopoverEnd>.',
      timeframesTimeAgoTextStartOpenEnd: '<TimeAgoPopoverStart></TimeAgoPopoverStart>',
      updatedAtText: 'Last modified <TimeAgoPopover></TimeAgoPopover>.',
      timeAgoPopOverTextStartFuture: 'Starts {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextStartPast: 'Started {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextCreatedFuture: 'Invalid createdAt Timestamp. Can only be in the past.',
      timeAgoPopOverTextCreatedPast: 'Created {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextEndFuture: 'Ends {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      timeAgoPopOverTextEndPast: 'Ended {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
      accountDeleted: 'Account deleted',
      accountDeletedExplanation:
        'The user account is deleted and cannot be used anymore. We apologize for any inconvenience.',
      somethingWentWrong: 'Something went wrong. Please try again, or contact support@priojet.com',
      agencies: 'Agencies',
      organizationDetails: 'Organization details',
      responsibleUser: 'Responsible user',
      restore: 'Restore',
      deleted: 'Deleted ',
      couriers: 'Couriers',
      chatDisabledCourierDeleted:
        'New messages cannot be created because connected courier deleted account.',
      chatDisabledAgencyDeleted:
        'New messages cannot be created because connected agency deleted account.',
      validForCountry: 'VALID FOR COUNTRY',
      FEMALE: 'Female',
      MALE: 'Male',
      OTHER: 'Other',
      timeAgoPopOverTextEndedPast: 'Ended {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
    },
    connectedAgencies: {
      noConnectedAgencies:
        "You don't have Agency connections. You can only connect to an Agency by getting invited by the Agency.",
    },
    connectedCouriers: {
      noConnectedCouriersFound: 'No connected Couriers found for filter term "{{filterTerm}}".',
      filterPlaceholder: 'Filter Courier by name, email or phone',
      screenTitle: 'Connected Couriers',
    },
    courierAgencyProfile: {
      agencyName: 'Agency name',
      agencyShortName: 'Agency short name',
      primaryEmailAddress: 'Primary agency email address',
      emailNotProvided: 'Email not provided',
      primaryPhoneNumber: 'Primary agency phone number',
      phoneNotProvided: 'Phone not provided',
      agencyLegalName: 'Agency legal name',
      headquartersAgencyAddress: 'Address of the agency headquarters',
      companyRegistrationNumber: 'Company registration number',
      vatId: 'Tax registration number (VAT ID)',
      officialPostalInvoiceAddress: 'Official postal invoice address',
      invoiceEmail: 'Invoice Email Address',
      description: 'Description',
      generalInformation: 'General information',
      legalInformation: 'Legal information',
      invoicingDetails: 'Invoicing Details',
      socialMedia: 'Social Media',
    },
    courierAvailability: {
      availableFromStartDayOnly:
        'Start day: <strong>{{startDateString}}</strong>. \nChoose an end date above.',
      availableFromToDate:
        'From <strong>{{startDateString}}</strong> to <strong>{{endDateString}}</strong>',
      locationLocalTimeInfo:
        'Please be aware that you have to specify the local time at your selected location <strong>{{locationName}}</strong>. The time zone difference to your local time zone <strong>{{currentTimeZoneName}}</strong> is exactly <strong>{{timeZoneOffsetInHours}}</strong> hours ({{locationTimeZoneName}}). Therefore it {{timeZoneEqualToLaterThanOrEarlierThan}} your current local time. \n\nExample: {{localTimeExample}} here is {{locationTimeExampleDateTime}} there. So you would have to also set {{locationTimeExampleTime}} below.',
      newAvailabilityCreated: 'New Availability for {{locationName}} created.',
      selectStartAndEndDateAbove: 'Select the start and end date above.',
      createAvailability: 'Create Availability',
      '1ChooseAvailabilityLocation':
        '1. In which location are you available? Search the name of the location below and select it accordingly.',
      locationSearchPlaceholderText: 'Enter the location where you are available',
      currentLocation: 'Current location',
      '2SelectDateRange':
        '2. When are you available? Choose the days on which you are available in the calendar below.',
      '3ChooseAvailabilityTime':
        '3. In which time frame are you available?\n Select the starting and ending time for your Availability.',
      isEqualTo: 'and is equal to your',
      ahead: 'ahead',
      behind: 'behind',
      appearsEqualTo: 'appears equal to',
      appearsLaterThan: 'appears already later than',
      appearsEarlierThan: 'appears still earlier than',
      pleaseSelectStartTimeForPlaceOfAvailability:
        'Please select the start time from when you are available on the {{startDateString}} in {{locationName}}.',
      pleaseSelectStartTimeForAvailabilityLocationOnly:
        'Please select the start time from when you are available in {{locationName}}.',
      pleaseSelectStartTimeForAvailabilityDateTimeOnly:
        'Please select the start time from when you are available on the {{startDateString}}.',
      pleaseSelectStartTime: 'Please select a start time from when you are available.',
      pleaseSelectEndTimeForPlaceOfAvailability:
        'Please select the end time until you are available on the {{endDateString}} in {{locationName}}.',
      pleaseSelectEndTimeForAvailabilityLocationOnly:
        'Please select the end time until you are available in {{locationName}}.',
      pleaseSelectEndTimeForAvailabilityDateTimeOnly:
        'Please select the end time until you are available on the {{endDateString}}.',
      pleaseSelectEndTime: 'Please select the end time until you are available.',
      couldNotSaveAvailabilityErrorMessage:
        'Could not save this Availability. Please fix the errors below:',
      saveAndScheduleAvailability: 'Save and schedule Availability',
      availabilityCreated: 'Availability created',
    },
    courierInvitations: {
      deviceHasContactsMessageTop: 'This device has a phone book with contacts you could invite.',
      allowAccessToContactsMessage:
        'It seems like you do not allow accessing your Contacts in the Settings. To load your Contacts here, please click on Open Settings and allow access to your Contacts. Don\'t worry, only those contacts that you actively invite by pressing the "Invite" button after loading the Contacts are submitted to our system.',
      openSettingsButton: 'Open Settings',
      displayLoadContactsMessage:
        'To display your contacts here, please press Load Contacts and allow  access to your contacts. Don\'t worry, only those contacts that you actively invite by pressing the "Invite" button after loading the Contacts are submitted to our system.',
      errorOccurredMessage: 'An error occurred. Please contact the PRIOjet support.',
      cannotAccessContactsMessage:
        'We cannot access contacts on this device. Please use a mobile phone.',
    },
    courierUserProfile: {
      companyName: 'Company Name',
      invalidEmail: 'Must be a valid email',
      max9Characters: 'Maximum 9 characters',
      error: 'Error',
      errorUploadingLogoImage: 'Something went wrong with uploading logo image',
      errorDeletingProfilePicture: 'Something went wrong with deleting profile picture',
      someFieldAreNotValid: 'Some fields are not valid',
      success: 'Success',
      profileUpdated: 'Courier profile successfully updated',
      somethingWentWrong: 'Something went wrong',
      emailAlreadyInUse: 'Email already in use',
      requiredField: 'Must be set',
      invalidUrl: 'Must be a valid URL',
      numericalValue: 'Must set a numerical value',
      noCameraPermissions: 'Sorry, we need camera roll permissions to make this work!',
      wantToReloadPart1: 'Do you want to reload the profile?',
      wantToReloadPart2: 'Unsaved data will be lost',
      cancel: 'Cancel',
      refresh: 'Refresh',
      language: 'Language',
      level: 'Level',
      currency: 'Currency',
      maximumCreditLimit: 'Maximum Credit Limit (only numbers)',
      cards: 'Cards',
      creditCard: 'Credit Card',
      debitCard: 'Debit Card',
      obcExperience: 'OBC Experience',
      firstObcJobDateAt: 'First OBC Job Date',
      obcJobsDone: 'OBC Jobs done',
      medicalObcExperience: 'Medical/stem cells OBC Experience',
      myProfile: 'My Profile',
      email: 'Email',
      phoneNumber: 'Phone number',
      personalDetails: 'Personal Details',
      forChangesContactSupport: 'For any changes in these fields contact PRIOjet Support',
      firstNames: 'First Names',
      lastName: 'Last Name',
      sex: 'Sex',
      dateOfBirth: 'Date of Birth',
      placeOfBirth: 'Place of Birth',
      countryOfBirth: 'Country of Birth',
      nationality: 'Nationality',
      secondEmail: 'Second Email',
      secondPhone: 'Second Phone',
      baseAirports: 'Base Airports',
      residenceAddress: 'Residence Address',
      addressName: 'Address name',
      address: 'Address',
      enterResidenceAddress: 'Enter your residence address',
      currentLocation: 'Current location',
      businessInformation: 'Business Information',
      companyNameLabel: 'Company Name',
      companyNamePlaceholder: 'Company Name',
      taxCountry: 'Tax Country',
      taxCountryCaption: 'In which country is my business registered for tax purposes?',
      addressSameAsResidence: 'Company address same as residence address',
      companyAddress: 'Company Address',
      enterCompanyAddress: 'Enter company address',
      emergencyContact: 'Emergency Contact',
      fullName: 'Full Name',
      relationship: 'Relationship',
      emailAddress: 'Email Address',
      contactNumber: 'Contact Number',
      socialMedia: 'Social Media',
      languagesKnownMultipleAllowed: 'Languages known (multiple allowed)',
    },
    deleteAccount: {
      confirmModal: {
        title: 'Delete account?',
        text: 'Are you sure you want to delete your account? \n\nYou will be able to re-activate your account within 30 days, after you will need to create a new one!',
        inputLabel: 'Enter DELETE to confirm account deletion.',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        inputErrorMessage: 'Confirm account deletion',
        inputErrorDescription: 'Please enter DELETE to confirm account deletion.',
      },
      deleteSuccess: {
        message: 'Account is now deleted',
        description:
          "It's unfortunate to see you go! But if you change your mind, you can contact support@priojet.com within 30 days to re-activate your account. Otherwise, the account will be permanently deleted and will require you to create and activate a new account again.",
      },
      forbiddenAction: {
        message: 'Forbidden action',
        description: 'Only Agency or Courier accounts can be deleted',
      },
      restoreAccount: {
        confirmModal: {
          title: 'Restore account',
          text: 'Are you sure you want to restore selected account?',
          confirmButtonText: 'Restore',
          cancelButtonText: 'Cancel',
        },
        restoreSuccess: {
          message: 'Account restored',
          description: 'Account successfully restored.',
        },
      },
      noDeletedAccount: 'No deleted accounts to display.',
      deletedAccountsPriojet: 'PRIOjet - Deleted accounts',
      viewAgencies: 'View Agencies',
      viewCouriers: 'View Couriers',
      chatDisabledAgencyDeleted:
        'New messages cannot be created because connected agency deleted account.',
      deletedAccounts: 'Deleted accounts',
    },
    error: {
      internalCode: {
        '40002': {
          message: 'Activation code error',
          description:
            'Invited organization type does not match activation code. Please ask for a new one or reach out to support@priojet.com',
        },
        '44009': {
          message: 'Activation error',
          description:
            'The given activation code is invalid or cannot be used to join this organization',
        },
        '44011': {
          message: 'Activation code already used',
          description:
            'The activation code was already used. Please ask for a new one. Reach out to support@priojet.com',
        },
        '44020': {
          message: 'Activation error',
          description:
            'The given activation code is invalid or cannot be used to join this organization',
        },
      },
    },
    jobs: {
      startJob: 'Start Job',
      agencyJobStatusTypeChangeDescription:
        'Status for Job {{courierJobIdentifier}} changed to {{agencyJobStatusType}}.',
      startJobConfirmationPopupText:
        'Do you really want to start the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      confirmAndFinishJob: 'Confirm and finish Job',
      confirmAndFinishJobConfirmationPopupText:
        'Do you really want to confirm and finish the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      resetJobAsNew: 'Reset Job as NEW',
      resetJobAsNewConfirmationPopupText:
        'Do you really want to set the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} back to New again? This will notify previously selected couriers.',
      cancelJob: 'Cancel Job',
      cancelJobConfirmationPopupText:
        'Do you really want to cancel the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      changeJobToStartedAgain: 'Change Job to STARTED again',
      pending: 'pending',
      requested: 'requested',
      accepted: 'accepted',
      declined: 'declined',
      manageCouriers: 'Manage Couriers',
      manageCouriers_plural: 'Manage Couriers',
      addOrRemoveCouriers: 'Add/Remove Couriers',
      viewOrEditJob: 'View/Edit job details',
      pickupTime: 'Pickup time',
      checkInTimeAtStartAirport: 'Check-in time at start airport',
      dropOffTime: 'Drop-off time',
      checkInAtEndAirport: 'Check-in time at end airport',
      returnTimeAtEndAirport: 'Return time at end airport',
      returnTimeAtStartAirport: 'Return time at start airport',
      courierJobRelationshipTypeChangeForCourierMessage:
        'Job {{priojetJobIdentifier}} was set to {{relationshipType}}.',
      courierJobRelationshipTypeChangeForAgencyPrompt:
        'Change Job {{priojetJobIdentifier}} to {{relationshipType}}.',
      waitingForApprovalFromCourier: 'Waiting for approval from Courier',
      widthDrawAndDecline: 'Withdraw & Decline',
      cancelWithdrawAndDecline: 'Cancel, withdraw & decline',
      acceptOrDeclineCourierForJob: 'Accept or decline this Courier for the given Job',
      decline: 'Decline',
      accept: 'Accept',
      proposeJobToCourierAgainRequestAgain: 'Propose Job to Courier again / request again',
      proposeAgain: 'Propose again',
      priojetJobIdentifier: 'PRIOjet Job Identifier',
      jobTitleRefNumber: 'Job title/Ref. Nr.',
      pickupLocation: 'Pickup Location',
      startAirport: 'Start airport',
      pickupAddressDescription: 'Pickup Address Description',
      dropOffLocation: 'Drop-off Location',
      endAirport: 'End Airport',
      additionalDetailsAndJobDescription: 'Additional details and Job description',
      dropOffAddressDescription: 'Drop-off Address Description',
      typeOfTransportJob: 'Type of Transport job',
      carRequiredForPickup: 'Car required for Pickup',
      drivingLicenseRequiredForPickup: 'Driving license required for Pickup',
      transportGoodsContents: 'Transport goods contents',
      weightOfItemsKG: 'Weight of Items (KG)',
      numOfItems: 'Number of Items',
      numOfCouriersRequired: 'Number of Couriers required',
      currencyOfPayment: 'Currency of payment',
      hotelBudget: 'Hotel budget',
      fixedRate: 'Fixed rate',
      dailyRate: 'Daily rate',
      kickOffFee: 'Kick-off fee',
      isAccommodationBookedByCourier: 'Accommodation booked by Courier',
      isAccommodationPaidByCourier: 'Accommodation paid by Courier',
      isAdditionalCostPaidByCourier: 'Additional costs paid by Courier',
      additionalCostInformation: 'Additional information on cost coverage',
      new: 'new',
      running: 'running',
      past: 'past',
      numOfCouriersConnectedToAgencyJob:
        'There are currently {{count}} Couriers selected for this Job.',
      numOfCouriersConnectedToAgencyJobPlural:
        'There are currently {{count}} Couriers selected for this Job.',
      clientCurrencyErrorMessage: 'You must select a currency',
      currencyErrorMessage: 'You must select a currency',
      organizationNameErrorMessage: 'You must provide the organization name of your client',
      returnTimeAtStartAirportSummary: 'Estimated return time at the start airport',
      jobTitle: 'JOB TITLE/ REF. NO.',
      jobTitlePlaceholder: 'Your Job reference ID  / Title',
      externalJobReference:
        'Give this new Job a reference identifier (e.g. "{{organizationName}}-ABC123") or a title ("{{shortDate}} Paris to New York"). It will be used alongside with a PRIOjet Job identifier (e.g. PJ-XXXXX).',
      Pickup: 'Pickup',
      pickupAtDoor: 'Pick-up at Door',
      pickupAtAirport: 'Pick-up at Airport',
      pickupLocationCaption:
        'Select the pickup location (address, place, or a just a city/district name, if the location is not determined yet).',
      pickupLocationPlaceholder: 'Search for the pickup address here',
      startAirportCaption:
        'Select the Airport from which the Courier starts their delivery journey.',
      startAirportPlaceholder: 'Search for the start airport here',
      currentLocation: 'Current Location',
      startDate: 'Start Date',
      startDateLabel: 'Choose the date on which to the goods/items need to be picked up.',
      hourLabel: 'Hour',
      pickupHourCaption: 'Choose the hour of the day on which to pickup the items/goods.',
      minuteLabel: 'Minute',
      pickupMinuteCaption:
        'Choose the minute of the hour and day on which to pickup the items/goods.',
      pickupAddressDescriptionCaption:
        'Provide some more information about where and how to find and pick up the items / goods.',
      isCarRequiredForJobLabel: 'Car required for pickup',
      isCarRequiredForJobCaption:
        'Select if this job requires the Courier to have a vehicle at their disposal.',
      isDrivingLicenseRequiredForJobLabel: 'Driving License required for job',
      isDrivingLicenseRequiredForJobCaption:
        'Select if this job requires the Courier have a valid driving license that allows them to drive a car.',
      deliveryAndReturnTitle: 'Delivery and Return',
      deliveryAtDoor: 'Delivery at Door',
      deliveryAtAirport: 'Delivery at the Airport',
      dropOffLocationLabel: 'Drop-off Location',
      dropOffLocationCaption:
        'Select the drop-off location (address, place, or a just a city/district name, if the location is not determined yet).',
      dropOffLocationPlaceholder: 'Search for the drop-off address here',
      endAirportLabel: 'End Airport',
      endAirportCaption: "Select the end airport of the Courier's journey.",
      endAirportPlaceholder: 'Search for the end airport here',
      returnTimeAtStartAirportDateLabel: 'Return time at the start airport',
      returnTimeAtStartAirportDateCaption:
        'Choose the date on which the Courier will be able to return to the start airport.',
      deliveryHourCaption:
        'Estimate the latest hour of the day on which the Courier will be able to return to the start airport.',
      deliveryMinuteCaption:
        'Estimate the latest minute of the hour on which the Courier will be able to return to the start airport.',
      dropOffAddressDescriptionLabel: 'Drop-off / delivery location description',
      dropOffAddressDescriptionCaption:
        'Provide some more information about where to handover or drop off the items / goods.',
      itemsGoodsTitle: 'Items & Goods Details',
      transportGoodsContentsLabel: 'TRANSPORT GOODS CONTENTS',
      weightOfItemsLabel: 'Total weight of all items',
      weightOfItemsCaption: 'Total weight of all items to be transported.',
      numOfItemsLabel: 'Number of items to be transported',
      numOfItemsCaption:
        'Specify the number of items, bags, boxes etc. that need to be transported.',
      numOfCouriersLabel: 'Number of Couriers required',
      numOfCouriersCaption: 'Specify the number Couriers that are required to fulfil this job.',
      feesPaymentsTitle: 'Fees & Payments',
      clientAgencyCurrencyLabel: 'Client Currency',
      clientAgencyCurrencyCaption: 'The currency to get paid in by the Client.',
      agencyCourierCurrencyLabel: 'Courier Currency',
      agencyCourierCurrencyCaption: 'The currency that is used to pay the Couriers with.',
      hotelBudgetLabel: 'Accommodation / hotel budget',
      hotelBudgetCaption:
        'Specify the accommodation budget that is paid to the Courier if applicable.',
      fixedRateLabel: 'Fixed rate / compensation',
      fixedRateCaption:
        'Specify a fixed rate / lump sum compensation the Courier is offered for doing this Job.',
      dailyRateLabel: 'Compensation per day',
      dailyRateCaption:
        'Specify an amount the Courier is offered per started day for doing this Job.',
      kickOffFeeLabel: 'Kick-off fee',
      kickOffFeeCaption:
        'Specify a kick-off fee that the Courier is offered for accepting this Job.',
      isAccommodationBookedByCourierLabel: 'Who books the accommodation?',
      isAccommodationBookedByCourierCaption: 'Select which party books for the accommodation.',
      isAccommodationPaidByCourierLabel: 'Who pays for accommodation?',
      isAccommodationPaidByCourierCaption: 'Select which party pays for the accommodation.',
      isAdditionalCostPaidByCourierLabel: 'Who pays for additional costs?',
      isAdditionalCostPaidByCourierCaption:
        'The additional costs such as pickup, delivery, transfer, etc.',
      additionalCostInformationLabel: 'Additional information on cost coverage',
      additionalCostInformationCaption: 'Provide some more information on additional cost coverage',
      'Additional Details': 'Additional Details',
      additionalDetailsAndJobDescriptionLabel: 'Additional details and Job description',
      additionalDetailsAndJobDescriptionCaption:
        'Provide some more information the Job and provide all necessary information for the Courier.',
      createNewJob: 'Create new Job',
      overview: 'Overview',
      details: 'Details',
      journey: 'Journey',
      doorToDoor: 'Door to Door',
      airportToDoor: 'Airport to Door',
      doorToAirport: 'Door to Airport',
      airportToAirport: 'Airport to Airport',
      numOfCouriersConnectedToAgencyJob_plural:
        'There are currently {{count}} Couriers selected for this Job.',
      isAccommodationBookedByCourierFalse: 'Booked by the Agency',
      isAccommodationBookedByCourierTrue: 'Booked by the Courier',
      isAccommodationPaidByCourierFalse: 'Paid by the Agency',
      isAccommodationPaidByCourierTrue: 'Paid by the Courier',
      isAdditionalCostPaidByCourierFalse: 'Paid by the courier',
      isAdditionalCostPaidByCourierTrue: 'Paid by the Agency by receipts',
      agencyJobRateCardDescription:
        'The rates below were proposed when you, as the Agency, added the Courier initially to the Job via a request. Whatever rates were agreed upon after that via Email, Phone, Chat, etc. are binding.',
      courierJobRateCardDescription:
        'The rates below were proposed when the Agency added you, as the Courier, initially to the Job via a request. Whatever rates were agreed upon after that via Email, Phone, Chat, etc. are binding. If you change the rates afterwards by editing the job, note that the new rates will not be reflected in this job.',
      agencyJobsManageCouriersTabPending: 'PENDING ({{courierJobs}})',
      agencyJobsManageCouriersTabRequested: 'REQUESTED ({{courierJobs}})',
      agencyJobsManageCouriersTabAccepted: 'ACCEPTED ({{courierJobs}})',
      agencyJobsManageCouriersTabDeclined: 'DECLINED ({{courierJobs}})',
      agencyJobsTabNew: 'NEW ({{agencyJobsNewCount}})',
      agencyJobsTabStarted: 'STARTED ({{agencyJobsStartedCount}})',
      agencyJobsTabFinished: 'FINISHED ({{agencyJobsFinishedCount}})',
      agencyJobsTabCancelled: 'CANCELLED ({{agencyJobsCancelledCount}})',
      AOG: 'AOG',
      AUTOMOTIVE_PARTS: 'Automotive parts',
      DOCUMENTS: 'Documents',
      MEDICAL: 'Medical',
      OTHERS: 'Others',
      PRODUCTION_MATERIALS: 'Production materials',
      SPARE_PARTS: 'Spare parts',
      agencyJobsPendingButton: 'pending',
      agencyJobsRequestedButton: 'requested',
      agencyJobsAcceptedButton: 'accepted',
      agencyJobsDeclinedButton: 'declined',
      agencyJobsViewAllCouriers: 'View all couriers',
    },
    location: {
      street: 'Street',
      houseNumber: 'House number',
      apartmentOrSuite: 'APT/SUITE/OTHERS',
      postalCode: 'Postal code',
      city: 'City',
      stateRegion: 'State/Region',
      country: 'Country',
      requiredField: 'Must be set',
      enterDataManually: 'Press here to enter data manually',
      addressNotFoundEnterDataManually: 'Address not found. Press here to enter data manually',
    },
    menuItems: {
      courierDocumentsPassportsEtc: 'Courier Documents (Passports etc.)',
      generalSettingsAndUserAccount: 'General Settings and User Account',
      appSettings: 'App Settings',
      switchAccount: 'Switch Account',
      logOut: 'Log out',
      Other: 'Other',
      FAQ: 'FAQ',
      featureRequest: 'Feature Request',
      voteForNewFeatures: 'Vote for new features',
      reportABug: 'Report a bug',
      getSupport: 'Get Support',
      Changelog: 'Changelog',
      Roadmap: 'Roadmap',
      Legal: 'Legal',
      dataPrivacyPolicy: 'Data Privacy Policy',
      Imprint: 'Imprint',
      appInformation: 'App Information',
      deleteAccount: 'Delete Account',
      Jobs: 'Jobs',
      Availabilities: 'Availabilities',
      Profile: 'Profile',
      PRIOjetProfile: 'PRIOjet Profile',
      identityDocuments: 'Identity Documents',
      visaTravelAuthorization: 'Visa / Travel Authorization',
      visaTravelAuthorizations: 'Visa / Travel Authorizations',
      frequentFlyerProgramDocuments: 'Frequent Flyer Program Documents',
      vaccinationCertificates: 'Vaccination Certificates',
      healthTestReports: 'Health Test Reports',
      backgroundDocuments: 'Background Documents',
      otherDocuments: 'Other Documents',
      courierDocuments: 'Courier Documents',
      contacts: 'Contacts',
      courierInvitations: 'Courier Invitations',
      backgroundDocument: 'Background Documents',
      frequentFlyerDocuments: 'Frequent Flyer Documents',
      identityDocument: 'Identity Documents',
      otherDocument: 'Other Documents',
      travelDocuments: 'Travel Documents',
      vaccinationDocument: 'Vaccination Documents',
      manageAgencyUsers: 'Manage Agency Users',
      AgencyProfile: 'Agency Profile',
      userManagement: 'User Management',
      agencyProfile: 'Agency Profile',
      courierManagement: 'Courier Management',
      connectedCouriers: 'Connected Couriers',
      connections: 'Connections',
      addAgency: 'Add Agency',
      connectedAgencies: 'Connected Agencies',
      courierProfile: 'Courier Profile',
      myProfileMyAgencies: 'My Profile & My Agencies',
      myProfile: 'My Profile',
      myAgencies: 'My Agencies',
      myDocuments: 'My Documents',
      Notifications: 'Notifications',
      organizationInvitations: 'Organization Invitations',
      UserManagement: 'User Management',
      managePRIOjetUsers: 'Manage PRIOjet Users',
      manageUsers: 'Manage Users',
      organizationManagement: 'Organization Management',
      manageOrganizations: 'Manage Organizations',
      courierProfileDocumentsButton: 'Courier Documents (Passports, etc.)',
    },
    organizationInvitations: {
      activationCodeShareMessageTitle: 'You have been invited to join the PRIOjet platform.',
      activationCodeShareMessage:
        'Hello!\n\n      We are pleased to be able to invite you to the PRIOjet platform (iOS, Android, Web).\n      Please signup using the iOS app ( https://apps.apple.com/kn/app/priojet-logistics/id1540437650 ), Android app ( https://play.google.com/store/apps/details?id=com.priojet.app ) or via https://app.priojet.com and use the code\n      \n      {{activationCode}}\n      \n      to activate your account after a successful registration.\n      If you have questions, reach out to support@priojet.com.\n      \n      Happy to have you on board!\n      Your PRIOjet team.',
      copiedActivationCodeClipboardMessage:
        'Copied activation code {{activationCode}} to the clipboard.',
      sharingItem:
        'Shared <TimeAgoPopover></TimeAgoPopover> in {{language}} via {{appName}} ({{platform}} / {{platformVersion}}) by {{firstNames}} {{lastName}} ({{email}}).',
    },
    i18nKey: '__STRING_NOT_TRANSLATED__',

    signUp: {
      termsAndConditions: 'Terms and Conditions (T&Cs)',
      termsAndConditionsURL: 'https://www.priojet.com/',
      dataProcessingAgreement: 'Data Processing Agreement',
      dataProcessingAgreementURL: 'https://www.priojet.com/',
      privacyPolicy: 'Privacy Policy',
      privacyPolicyURL: 'https://www.priojet.com/datenschutzerklaerung/',
      termsAndConditionsCheckboxText:
        '<3>I agree to the <1>Terms and Conditions (T&Cs)</1> and have taken note of the <2>Privacy Policy</2>.</3>',
      finishYourSignupInThePopUpWindow: 'Finish your sign up in the popup window.',
      introAgencyText1:
        'The most innovative logistics agencies use PRIOjet to streamline their processes and manage their time-critical transportation jobs together with their couriers.',
      introAgencyText2:
        'Talk to PRIOjet to learn more about our platform and how we can help you to better manage your business to save time and money. The future for OBC transportation is digital, mobile, multi-platform and communicates in real-time.',
      introAgencyText3: 'YOU ARE A LOGISTICS AGENCY?',
      introAgencyText4:
        'As a logistics agency, you can sign up here and get started right away with your free 14-day trial account (credit card required).',
      introCourierWarning:
        "IMPORTANT: Invitation code is required, if you don't have it yet, get it from your Agency.",
      introCourierText1:
        'OBC Couriers use PRIOjet to connect to their agencies to find new jobs and manage the corresponding information and communication so that their job fulfillment runs smoothly.',
      introCourierText2:
        'Talk to PRIOjet to your Agencies or PRIOjet to learn more about our platform  and how we can help you to better manage your OBC jobs to save time and money. The future for OBC transportation is digital, mobile, multi-platform and communicates in real-time.',
      introCourierText3: 'YOU ARE AN ON-BOARD COURIER (OBC)?',
      introCourierText4:
        'Sign up for free and connect to your agencies by using your invitation code.',
    },
  },
};
