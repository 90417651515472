import React from 'react';
import { ActivityIndicator, TouchableOpacity, View } from 'react-native';

import { Button, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../../common/style';
import { FormTextInputTopLabel } from '../../../../../components/common/form/form-text-input-top-label.component';
import useDimensions from '../../../../../hooks/useDimensions';

export const InviteCourierUploadCSVStepTwo: React.FC<any> = ({
  handleLabelChange,
  handleResetDocument,
  handleUploadFilePress,
  labels,
  loading,
  selectedDocument,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);
  const { isLargeDevice, isExtraLargeDevice } = useDimensions();
  const isWebView = isLargeDevice || isExtraLargeDevice;

  return (
    <View style={[globalStyle.width100Percent, globalStyle.marginTop30]}>
      <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
        <View style={styles.documentName}>
          <Text>{selectedDocument?.name}</Text>
        </View>
        <TouchableOpacity
          style={styles.closeButton}
          onPress={handleResetDocument}
          hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
        >
          <Icon
            fill={(styles.closeIcon as any).color}
            name="close-circle-outline"
            height={22}
            width={22}
          />
        </TouchableOpacity>
      </View>
      <View style={isWebView ? globalStyle.flexRow : globalStyle.flexColumn}>
        <FormTextInputTopLabel
          containerStyle={[globalStyle.flex1, globalStyle.marginRight2]}
          label={t('common.firstNameFieldMapping', {
            defaultValue: 'First name field mapping',
          })}
          onChangeText={handleLabelChange('firstNameField')}
          value={labels.firstNameField}
        />
        <FormTextInputTopLabel
          containerStyle={[globalStyle.flex1, globalStyle.marginLeft2]}
          label={t('common.lastNameFieldMapping', {
            defaultValue: 'Last name field mapping',
          })}
          onChangeText={handleLabelChange('lastNameField')}
          value={labels.lastNameField}
        />
      </View>
      <View style={isWebView ? globalStyle.flexRow : globalStyle.flexColumn}>
        <FormTextInputTopLabel
          containerStyle={[globalStyle.flex1, globalStyle.marginRight2]}
          label={t('common.emailFieldMapping', {
            defaultValue: 'Email field mapping',
          })}
          onChangeText={handleLabelChange('emailField')}
          value={labels.emailField}
        />
        <FormTextInputTopLabel
          containerStyle={[globalStyle.flex1, globalStyle.marginLeft2]}
          label={t('common.phoneNumberFieldMapping', {
            defaultValue: 'Phone number field mapping',
          })}
          onChangeText={handleLabelChange('phoneNumberField')}
          value={labels.phoneNumberField}
        />
      </View>
      <Button
        appearance="filled"
        status=""
        size="medium"
        onPress={handleUploadFilePress}
        style={[globalStyle.marginVertical10, globalStyle.marginTop20, globalStyle.width300]}
      >
        {(evaProps) => (
          <View
            style={[
              globalStyle.flexRow,
              globalStyle.alignItemsCenter,
              globalStyle.justifyContentCenter,
            ]}
          >
            <View style={[globalStyle.width25, globalStyle.marginRight10]}>
              {loading && <ActivityIndicator color="white" size="small" />}
            </View>
            <Text {...evaProps}>{t('common:nextStep', { defaultValue: 'Next step' })}</Text>
            <View style={[globalStyle.width25, globalStyle.marginLeft10]} />
          </View>
        )}
      </Button>
    </View>
  );
};
