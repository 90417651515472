import React from 'react';

import { Icon, IconElement, IconProps } from '@ui-kitten/components/ui';

export const HomeIcon = (props: IconProps) => <Icon {...props} name="home-outline" />;

export const JobsIcon = (props: IconProps) => <Icon {...props} name="home-outline" />;

export const AvailabilitiesIcon = (props: IconProps) => (
  <Icon {...props} name="calendar-outline" />
);

export const PlaygroundIcon = (props: IconProps) => (
  <Icon {...props} name="play-circle-outline" />
);

export const ProfileIcon = (props: IconProps) => (
  <Icon {...props} name="person-outline" />
);

export const WelcomeIcon = (props: IconProps) => (
  <Icon {...props} name="stop-circle-outline" />
);

export const LoginIcon = (props: IconProps) => <Icon {...props} name="lock-outline" />;

export const AccountActivationIcon = (props: IconProps) => (
  <Icon {...props} name="lock-outline" />
);

export const DeviceSetupIcon = (props: IconProps) => (
  <Icon {...props} name="settings-outline" />
);

export const ArrowIosBackIcon = (props: IconProps) => (
  <Icon {...props} name="arrow-ios-back" />
);

export const ArrowIosForwardIcon = (props: IconProps) => (
  <Icon {...props} name="arrow-ios-forward" />
);

export const CameraIcon = (props: IconProps) => <Icon {...props} name="camera" />;

export const EditIcon = (props: IconProps): IconElement => (
  <Icon {...props} name="edit" />
);

export const CalendarIcon = (props: IconProps) => <Icon {...props} name="calendar" />;

export const ForwardIcon = (props: IconProps) => (
  <Icon {...props} name="arrow-ios-forward" />
);

export const UploadIcon = (props: IconProps) => <Icon {...props} name="upload" />;

export const RemoveIcon = (props: IconProps) => <Icon {...props} name="close" />;

export const RefreshOutlineIcon = (props: IconProps) => (
  <Icon {...props} name="refresh-outline" />
);

export const AddIcon = (props: IconProps) => <Icon {...props} name="plus" />;

export const NotVerifiedIcon = (props: IconProps) => (
  <Icon {...props} style={{ color: 'red' }} name="alert-circle-outline" />
);

export const PartiallyVerifiedIcon = (props: IconProps) => (
  <Icon {...props} style={{ color: 'yellow' }} name="alert-circle-outline" />
);

export const VerifiedIcon = (props: IconProps) => (
  <Icon {...props} style={{ color: 'green' }} name="done-all-outline" />
);

export const DownloadIcon = (props: any) => <Icon {...props} name="download" />;

export const SubtractIcon = (props: IconProps) => <Icon {...props} name="minus" />;

export const PinLocationIcon = (props: IconProps) => (
  <Icon
    {...props}
    color="rgb(247, 249, 252)"
    style={{ color: 'grey', width: 25, height: 25 }}
    name="pin-outline"
  />
);
export const FileIcon = (props: IconProps) => <Icon {...props} name="file" />;
export const PersonIcon = (props: IconProps) => <Icon {...props} name="person" />;
export const CarIcon = (props: IconProps) => <Icon {...props} name="car" />;
export const LocationPin = (props: IconProps) => (
  <Icon name="pin-outline" fill="#8F9BB3" {...props} />
);
export const CheckOutlineIcon = (props: any) => <Icon name="check-outline" />;

export const FaceBookIcon = (props: IconProps) => <Icon {...props} name="facebook" />;

export const TwitterIcon = (props: IconProps) => <Icon {...props} name="twitter" />;

export const LinkedinIcon = (props: IconProps) => <Icon {...props} name="linkedin" />;

export const ClockIcon = (props: IconProps) => <Icon {...props} name="clock-outline" />;

export const ViewIcon = (props: IconProps) => <Icon name="eye-outline" {...props} />;

export const DeleteIcon = (props: IconProps) => (
  <Icon name="trash-2-outline" fill="#ff0000" {...props} />
);
