import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from 'expo-auth-session';
import * as jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import 'core-js/stable/atob';

import { createApolloClientNoPersist } from '../apollo/create-apollo-client-no-persist';
import { createApolloClientPersistAsync } from '../apollo/create-apollo-client-persist-async';
import { getAuthorizationHeaders } from '../apollo/create-auth-link-with-headers';
import { REGISTER_EXISTING_USER_WITH_OWNED_ORGANIZATION } from '../apollo/graphql-mutations';
import { SIGN_IN_USER_CONTEXT_QUERY, USER_CONTEXTS_QUERY } from '../apollo/graphql-queries';
import {
  OrganizationType,
  OrganizationUser,
  SignInUserContextQuery,
  SignInUserContextQueryVariables,
  UserContext,
} from '../generated-graphql-types';
import { authRequestConfig, GLOBAL_CONSTANTS } from '../globals';
import { UserContexts } from '../graphql-types';
import { filterTypenameKeyFromObject } from '../modules/authentication/authentication.module';
import { getDevice } from '../modules/device/device.module';
import { sha256Hash } from '../modules/helper/helper.module';
import { LocalUserContext, LocalUserContexts, LocalUserContextTokenResponseOnly } from '../types';
import { getIsBackendReachable } from '../utils/get-is-backend-reachable';
import { parseJsonWithDateTime } from '../utils/json-parse-with-date-time';

export class AuthenticationService {
  /** Public methods */

  public async getUpdatedLocalUserContextsAsync({
    localUserContexts,
    forceUpdate = false,
  }: {
    localUserContexts: LocalUserContexts;
    forceUpdate: boolean;
  }): Promise<LocalUserContexts> {
    const isIsBackendReachable = await getIsBackendReachable();
    if (!isIsBackendReachable) {
      return localUserContexts;
    }

    if (!localUserContexts || Object.keys(localUserContexts).length === 0) {
      return {};
    }

    let newLocalUserContexts: LocalUserContexts = { ...localUserContexts };
    const refreshedUserIds: string[] = []; // List of refreshed users.
    for (const localUserContextEntry of Object.entries(localUserContexts)) {
      if (
        refreshedUserIds &&
        localUserContextEntry[1]?.sub &&
        refreshedUserIds.includes(localUserContextEntry[1]?.sub)
      ) {
        continue;
      }

      let refreshedLocalUserContext = null;
      try {
        refreshedLocalUserContext = await this.getLocalUserContextWithRefreshedTokenResponseAsync({
          localUserContext: localUserContextEntry[1],
          force: forceUpdate,
        });
      } catch (error) {
        refreshedLocalUserContext = null;
      }

      if (!refreshedLocalUserContext) {
        delete newLocalUserContexts[localUserContextEntry[0]];
        continue;
      }
      const availableUserContexts =
        await this.getAvailableLocalUserContextsFromBackendForLocalUserContextAsync(
          refreshedLocalUserContext,
        );

      if (availableUserContexts === null) {
        continue;
      }

      if (!availableUserContexts || availableUserContexts.length === 0) {
        delete newLocalUserContexts[localUserContextEntry[0]];
        continue;
      }

      const newLocalUserContextsPartial = this.mergeLocalUserContextsWithLocalUserContexts({
        localUserContexts: {},
        localUserContextArray: availableUserContexts,
      });

      if (newLocalUserContextsPartial) {
        newLocalUserContexts = {
          ...newLocalUserContexts,
          ...newLocalUserContextsPartial,
        };
        refreshedUserIds.push(localUserContextEntry[0]);
      }
    }

    return newLocalUserContexts;
  }

  public async getUpdatedLocalUserContextsFromMergedLocalUserContextWithOrganizationUserAsync({
    localUserContexts,
    localUserContext,
    organizationUser,
  }: {
    localUserContexts: LocalUserContexts;
    localUserContext: LocalUserContext;
    organizationUser: Partial<OrganizationUser>;
  }): Promise<LocalUserContexts> {
    // A complete OrganizationUser (it should represent a User with an Organization) and a localUserContext are required.
    // The sub needs to equal the organizationUser.user.id.
    if (
      organizationUser &&
      organizationUser.user &&
      organizationUser.organization &&
      organizationUser.organization.organizationType &&
      organizationUser.roleType &&
      localUserContext &&
      localUserContext.sub &&
      localUserContext.sub === organizationUser.user.id
    ) {
      const newLocalUserContexts = this.mergeLocalUserContextWithOrganizationUser({
        localUserContext,
        organizationUser,
      });

      if (newLocalUserContexts === null) {
        return localUserContexts;
      }

      const availableUserContexts =
        await this.getAvailableLocalUserContextsFromBackendForLocalUserContextAsync(
          localUserContext,
        );

      if (availableUserContexts === null) {
        return localUserContexts;
      }

      let _localUserContexts: LocalUserContexts = { ...localUserContexts };

      _localUserContexts = this.mergeLocalUserContextsWithLocalUserContexts({
        localUserContexts: _localUserContexts,
        localUserContextArray: availableUserContexts,
      });

      return _localUserContexts;
    }

    return localUserContexts;
  }

  public async createLocalUserContextFromAuthRequestAndAuthSessionResultAsync({
    authRequest,
    authSessionResult,
    isSignUp,
  }: {
    authRequest: AuthSession.AuthRequest;
    authSessionResult: AuthSession.AuthSessionResult;
    isSignUp: boolean;
  }): Promise<LocalUserContext | null> {
    const tokenResponse = await this.getTokenResponseFromAuthRequestAndAuthSessionResultAsync({
      authRequest,
      authSessionResult,
      isSignUp,
    });
    // If the tokenResponse is null (could not be obtained), return the existing localUserContexts.
    if (tokenResponse === null) {
      return null;
    }
    const localUserContext = await this.createLocalUserContextFromTokenResponseAsync({
      tokenResponse,
    });

    return localUserContext;
  }

  public async getUpdatedLocalUserContextsFromAuthRequestAndAuthSessionResultAsync({
    localUserContexts,
    authRequest,
    authSessionResult,
    isSignUp,
  }: {
    localUserContexts: LocalUserContexts;
    authRequest: AuthSession.AuthRequest;
    authSessionResult: AuthSession.AuthSessionResult;
    isSignUp: boolean;
  }): Promise<{ error?: string; localUserContexts: LocalUserContexts }> {
    const tokenResponse = await this.getTokenResponseFromAuthRequestAndAuthSessionResultAsync({
      authRequest,
      authSessionResult,
      isSignUp,
    });

    // If the tokenResponse is null (could not be obtained), return the existing localUserContexts.
    if (tokenResponse === null) {
      return { localUserContexts };
    }
    const localUserContext = await this.createLocalUserContextFromTokenResponseAsync({
      tokenResponse,
    });

    if (localUserContext === null) {
      return { localUserContexts };
    }

    const availableUserContexts =
      await this.getAvailableLocalUserContextsFromBackendForLocalUserContextAsync(localUserContext);
    if (!availableUserContexts?.length) {
      return { error: 'notExistingUser', localUserContexts };
    }

    const data = this.mergeLocalUserContextsWithLocalUserContexts({
      localUserContexts: { ...localUserContexts },
      localUserContextArray: availableUserContexts,
    });
    return { localUserContexts: data };
  }

  public async signupLocalUser({
    localUserContexts,
    authRequest,
    authSessionResult,
    organizationType,
    isSignUp,
  }: {
    localUserContexts: LocalUserContexts;
    authRequest: AuthSession.AuthRequest;
    authSessionResult: AuthSession.AuthSessionResult;
    organizationType: OrganizationType;
    isSignUp: boolean;
  }): Promise<{ error?: string; localUserContexts: LocalUserContexts }> {
    const localUserContext =
      await this.createLocalUserContextFromAuthRequestAndAuthSessionResultAsync({
        authRequest,
        authSessionResult,
        isSignUp,
      });

    if (localUserContext === null) {
      return { localUserContexts };
    }

    // Register the newLocalUserContext with the backend.
    const apolloClient = createApolloClientNoPersist();
    const authorizationHeaders = await getAuthorizationHeaders(localUserContext);
    const result = await apolloClient.mutate({
      mutation: REGISTER_EXISTING_USER_WITH_OWNED_ORGANIZATION,
      fetchPolicy: 'network-only',
      variables: { organizationType },
      context: {
        headers: authorizationHeaders,
      },
    });

    if (result?.data?.registerExistingUserWithOwnedOrganization) {
      const newLocalUserContexts =
        await this.getUpdatedLocalUserContextsFromMergedLocalUserContextWithOrganizationUserAsync({
          localUserContexts,
          localUserContext,
          organizationUser: result.data
            ?.registerExistingUserWithOwnedOrganization as Partial<OrganizationUser>,
        });
      return { localUserContexts: newLocalUserContexts };
    }

    return { localUserContexts };
  }

  public async signInLocalUserContextAsync({
    localUserContextToSignIn,
    localUserContexts,
  }: {
    localUserContextToSignIn: LocalUserContext;
    localUserContexts: LocalUserContexts;
  }): Promise<{
    localUserContexts: LocalUserContexts;
    signedInLocalUserContext: LocalUserContext | null;
  }> {
    // The check for isBackendReachable is done before calling this function to enable offline account switching.

    const newLocalUserContextToSignIn =
      await this.getLocalUserContextWithRefreshedTokenResponseAsync({
        localUserContext: localUserContextToSignIn,
      });

    localUserContextToSignIn = {
      ...localUserContextToSignIn,
      ...newLocalUserContextToSignIn,
    };

    const apolloClient = createApolloClientNoPersist();

    const headers =
      await this.getAuthorizationHeadersWithSignInTokenAsync(localUserContextToSignIn);

    return apolloClient
      .query<SignInUserContextQuery, SignInUserContextQueryVariables>({
        query: SIGN_IN_USER_CONTEXT_QUERY,
        context: { headers },
      })
      .then((result) => {
        if (result?.data?.signInUserContext) {
          const newLocalUserContexts = this.mergeSignInLocalUserContextWithLocalUserContexts({
            localUserContexts,
            localUserContextToSignIn,
            confirmedSignInUserContext: {
              ...localUserContextToSignIn,
              ...result.data.signInUserContext,
            } as UserContext, // Merging the new signInUserContext in.
          });

          const returnValues = {
            localUserContexts: newLocalUserContexts,
            signedInLocalUserContext:
              newLocalUserContexts[
                localUserContextToSignIn.userContextId ||
                  this.getUserContextId({ userContext: localUserContextToSignIn })
              ],
          };

          return returnValues;
        }
        return { localUserContexts, signedInLocalUserContext: null };
      })
      .catch((error) => {
        console.warn('########### signInLocalUserContextAsync==error', error);
        return { localUserContexts, signedInLocalUserContext: null };
      });
  }

  public async getLocalUserContextWithRefreshedTokenResponseAsync({
    localUserContext,
    force = false,
  }: {
    localUserContext: LocalUserContext;
    force?: boolean;
  }): Promise<LocalUserContext | null> {
    if (!localUserContext || !localUserContext.tokenResponse) {
      return null;
    } else if (
      localUserContext &&
      localUserContext.tokenResponse &&
      (localUserContext.tokenResponse.shouldRefresh() === true || force)
    ) {
      const newTokenResponse = await this.getRefreshedTokenResponseForTokenResponseAsync(
        localUserContext.tokenResponse,
      );

      if (newTokenResponse === null) {
        return null;
      }
      localUserContext = {
        ...localUserContext,
        device: await getDevice(),
        tokenResponse: newTokenResponse,
        refreshTokenExpired: false,
      };
      return localUserContext;
    }
    return localUserContext;
  }

  public async storeLocalUserContextsAsync({
    localUserContexts,
    storageKey = GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
  }: {
    localUserContexts: LocalUserContexts;
    storageKey?: string;
  }): Promise<boolean> {
    if (!storageKey) {
      return false;
    }

    if (!localUserContexts || Object.keys(localUserContexts).length === 0) {
      await AsyncStorage.setItem(storageKey, '{}');
      return true;
    }

    try {
      await AsyncStorage.setItem(storageKey, JSON.stringify(localUserContexts));
      return true;
    } catch (err) {
      console.warn(
        '&&& AuthenticationService :: storeLocalUserContextsAsync :: error while storing localUserContexts',
        err,
      );
      return false;
    }
  }

  public async loadLocalUserContextsAsync(
    storageKey: string = GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
  ): Promise<LocalUserContexts> {
    const userContexts = await AsyncStorage.getItem(storageKey);
    if (userContexts === null) {
      return {};
    }
    try {
      const parsedUserContexts: LocalUserContexts = parseJsonWithDateTime(
        userContexts,
      ) as LocalUserContexts;

      let entry: [string, LocalUserContext];
      const parsedUserContextEntries: [string, LocalUserContext][] = [];
      for (entry of Object.entries(parsedUserContexts)) {
        // Filter out the bad ones that are not actually valid UserContext by skipping the iteration
        if (
          entry[0] === undefined ||
          entry[0] === 'undefined' ||
          entry[0] === null ||
          entry[0] === '' ||
          entry[1].sub === undefined ||
          entry[1].sub === 'undefined' ||
          entry[1].sub === null ||
          entry[1].sub === '' ||
          !entry[1].tokenResponse
        ) {
          continue;
        }

        // Revive the tokenResponse.
        entry[1].tokenResponse = new AuthSession.TokenResponse(entry[1].tokenResponse);
        // Push the entry to the parsedUserContextEntries
        parsedUserContextEntries.push(entry);
      }
      // Return the loaded and parsed LocalUserContexts
      return Object.fromEntries(parsedUserContextEntries);
    } catch (e) {
      console.warn(
        '&&& AuthenticationService :: Cannot load and parse UserContexts from Storage',
        e,
      );
      return {};
    }
  }

  public parseUserContext = (userContext: string | null): LocalUserContext | null => {
    if (!userContext || userContext === '') {
      return null;
    }
    try {
      const parsedUserContext = parseJsonWithDateTime(userContext) as LocalUserContext;
      // Set an up to date userContextId.
      parsedUserContext.userContextId = this.getUserContextId({
        userContext: parsedUserContext,
      });

      // Make sure the tokenResponse is revived object.
      if (parsedUserContext.tokenResponse) {
        parsedUserContext.tokenResponse = new AuthSession.TokenResponse(
          parsedUserContext.tokenResponse,
        );
        parsedUserContext.lastSyncedAt = parsedUserContext.lastSyncedAt
          ? DateTime.fromISO(parsedUserContext.lastSyncedAt.toString())
          : null;
      }
      return parsedUserContext;
    } catch (e) {
      return null;
    }
  };

  /** Private methods */

  private mergeLocalUserContextWithOrganizationUser({
    localUserContext,
    organizationUser,
  }: {
    localUserContext: LocalUserContext;
    organizationUser: Partial<OrganizationUser>;
  }): LocalUserContext {
    const mergedLocalUserContext = {
      ...localUserContext,
      organizationUser: organizationUser,
      user: organizationUser.user,
      organization: organizationUser.organization,
      roleType: organizationUser.roleType,
    } as LocalUserContext;
    mergedLocalUserContext.role = this.getRoleFromLocalUserContext(mergedLocalUserContext);
    return mergedLocalUserContext;
  }

  private getRoleFromLocalUserContext(localUserContext: LocalUserContext): string {
    return localUserContext.organizationType + '/USER/' + localUserContext.roleType;
  }

  private mergeSignInLocalUserContextWithLocalUserContexts({
    localUserContexts,
    localUserContextToSignIn,
    confirmedSignInUserContext,
  }: {
    localUserContexts: LocalUserContexts;
    localUserContextToSignIn: LocalUserContext;
    confirmedSignInUserContext: UserContext;
  }): LocalUserContexts {
    if (confirmedSignInUserContext && localUserContextToSignIn) {
      const localUserContextArray = this.convertUserContextsToLocalUserContextArray({
        localUserContextUsedForQuerying: localUserContextToSignIn,
        userContexts: [confirmedSignInUserContext],
      });
      return this.mergeLocalUserContextsWithLocalUserContexts({
        localUserContexts,
        localUserContextArray,
      });
    }
    return localUserContexts;
  }

  private async getAuthorizationHeadersWithSignInTokenAsync(
    localUserContext: LocalUserContext,
  ): Promise<Record<string, any>> {
    const dateTime = DateTime.now();
    const millis = dateTime.toMillis();
    const timestampPartial = Math.floor(millis / (1000 * 60 * 5)); // cropped five minutes

    const signInTokenRaw = `${localUserContext.tokenResponse?.accessToken}:${localUserContext.user?.id}:${localUserContext.organizationUser?.id}:${localUserContext?.device?.id}:${timestampPartial}`;

    const hash = await sha256Hash(signInTokenRaw);
    const authHeaders = await getAuthorizationHeaders(localUserContext);
    return {
      ...authHeaders,
      'x-sign-in-token': hash,
    };
  }

  private async getAvailableLocalUserContextsFromBackendForLocalUserContextAsync(
    localUserContext: LocalUserContext,
  ): Promise<LocalUserContext[] | null> {
    if (!localUserContext) {
      return [];
    }
    const userContexts =
      await this.getAvailableUserContextsFromBackendForLocalUserContextAsync(localUserContext);

    if (userContexts === null) {
      return [localUserContext];
    }

    return this.convertUserContextsToLocalUserContextArray({
      localUserContextUsedForQuerying: localUserContext,
      userContexts,
    });
  }

  private convertUserContextsToLocalUserContextArray({
    localUserContextUsedForQuerying,
    userContexts,
    setLastSyncedAt = true,
  }: {
    localUserContextUsedForQuerying: LocalUserContext;
    userContexts: UserContext[];
    setLastSyncedAt?: boolean;
  }): LocalUserContext[] {
    if (userContexts && userContexts.length > 0) {
      const localUserContextArray: LocalUserContext[] = Object.values(userContexts).map(
        (userContext) => {
          const newLocaleUserContext = {
            ...localUserContextUsedForQuerying,
            ...userContext,
            userContextId: this.getUserContextId({ userContext }),
            refreshTokenExpired: false,
            device: {
              ...userContext.device,
              ...localUserContextUsedForQuerying.device,
              isSynchronizedWithBackend: true,
            },
          } as LocalUserContext;
          if (setLastSyncedAt) {
            newLocaleUserContext.lastSyncedAt = DateTime.now();
          }
          return newLocaleUserContext;
        },
      );

      return localUserContextArray;
    }

    return [];
  }

  private async getAvailableUserContextsFromBackendForLocalUserContextAsync(
    localUserContext: LocalUserContext,
  ): Promise<UserContext[] | null> {
    const isIsBackendReachable = await getIsBackendReachable();
    if (!isIsBackendReachable) {
      return null;
    }

    const refreshedLocalUserContext = await this.getLocalUserContextWithRefreshedTokenResponseAsync(
      { localUserContext },
    );

    if (refreshedLocalUserContext == null) {
      return [];
    }

    const { apolloClient } = await createApolloClientPersistAsync({
      userContext: refreshedLocalUserContext,
    });

    try {
      const userContextsQueryResult = await apolloClient.query<UserContexts>({
        query: USER_CONTEXTS_QUERY,
        context: {
          headers: await getAuthorizationHeaders(refreshedLocalUserContext),
          exceptionOnUnauthenticated: true,
        },
        fetchPolicy: 'no-cache',
      });

      if (!userContextsQueryResult?.data) {
        return [];
      }

      // If no UserContexts can be found in the backend (meaning the user is not registered).
      if (
        !userContextsQueryResult.data.userContexts ||
        (userContextsQueryResult.data.userContexts &&
          Object.keys(userContextsQueryResult.data.userContexts).length === 0)
      ) {
        return [];
      }

      const availableUserContexts: UserContext[] = userContextsQueryResult.data.userContexts.map(
        (uc) => filterTypenameKeyFromObject(uc) as UserContext,
      );

      return availableUserContexts;
    } catch (error) {
      console.warn('error', error);
      return [];
    }
  }

  private mergeLocalUserContextsWithLocalUserContexts({
    localUserContexts,
    localUserContextArray,
  }: {
    localUserContexts: LocalUserContexts;
    localUserContextArray: LocalUserContext[];
  }): LocalUserContexts {
    let newLocalUserContexts: LocalUserContexts = { ...localUserContexts };

    for (const localUserContext of localUserContextArray) {
      newLocalUserContexts = this.mergeLocalUserContextsWithLocalUserContext({
        localUserContexts: newLocalUserContexts,
        localUserContextToMerge: localUserContext,
      });
    }
    return newLocalUserContexts;
  }

  public mergeLocalUserContextsWithLocalUserContext({
    localUserContexts,
    localUserContextToMerge,
  }: {
    localUserContexts: LocalUserContexts;
    localUserContextToMerge: LocalUserContext;
  }): LocalUserContexts {
    if (!localUserContextToMerge || !localUserContextToMerge.userContextId) {
      return localUserContexts;
    }

    let newLocalUserContexts: LocalUserContexts = {};
    const userContextId = this.getUserContextId({ userContext: localUserContextToMerge });

    // If there a no localUserContexts, return LocalUserContexts with the localUserContextToMerge
    if (
      (!localUserContexts || Object.keys(localUserContexts).length === 0) &&
      localUserContextToMerge.userContextId
    ) {
      newLocalUserContexts[userContextId] = { ...localUserContextToMerge, userContextId };
      return newLocalUserContexts;
    }

    // If the user context to merge does not have a sub, it cannot be identified.
    // Hence, return the given localUserContexts.
    if (!localUserContextToMerge.sub) {
      return localUserContexts;
    }

    newLocalUserContexts = { ...localUserContexts };

    // If the localUserContextToMerge is a registered User with an Organization.
    // Make sure the UserContext with userContextId equal to `${localUserContextToMerge.sub}/` is deleted.
    if (
      localUserContextToMerge.user &&
      localUserContextToMerge.organization &&
      localUserContextToMerge.sub
    ) {
      const subOnlyUserContextId = this.getUserContextId({
        sub: localUserContextToMerge.sub,
      });
      delete newLocalUserContexts[subOnlyUserContextId];
    }

    newLocalUserContexts[userContextId] = { ...localUserContextToMerge, userContextId };
    return newLocalUserContexts;
  }

  private async verifyAuthRequestAsync(
    authRequest: AuthSession.AuthRequest,
  ): Promise<AuthSession.AuthRequest | null> {
    return authRequest;
  }

  private async verifyAuthSessionResultAsync(
    authSessionResult: AuthSession.AuthSessionResult,
  ): Promise<AuthSession.AuthSessionResult | null> {
    return authSessionResult;
  }

  private decryptJwtToken(token: string): any {
    return jwtDecode.jwtDecode(token);
  }

  private async createLocalUserContextFromTokenResponseAsync({
    tokenResponse,
    signUpOrganizationType,
  }: {
    tokenResponse: AuthSession.TokenResponse;
    signUpOrganizationType?: OrganizationType;
  }): Promise<LocalUserContext | null> {
    const { accessToken } = tokenResponse;
    const decryptedToken = this.decryptJwtToken(accessToken);
    const { sub } = decryptedToken;

    const device = await getDevice();
    const role = '/USER/';
    if (sub && decryptedToken) {
      const userContext: LocalUserContextTokenResponseOnly = {
        sub,
        tokenResponse,
        lastSyncedAt: null,
        userContextId: sub + '/',
        role,
        refreshTokenExpired: false,
        organizationActivatedAt: null,
        deviceId: device.id,
        device,
        signUpOrganizationType,
      };
      return userContext;
    }
    return null;
  }

  /**
   * Returns a userContextId to identify a UserContext. If sub is provided, the return value will be `${sub}/`
   * @param {Object} params - The params to be used.
   * @param {string} params.sub - The sub claim of the token (takes precedence when set).
   * @param {string} params.localUserContext - A LocalUserContext object where the sub property and organizationUserId is used (f set).
   * @return string userContextId in the pattern {sub}/{organizationUserId} or {sub}/ where {organizationUserId} can be empty.
   */
  private getUserContextId = ({
    sub,
    userContext,
  }: {
    userContext?: LocalUserContext | UserContext;
    sub?: string;
  }): string => {
    if (sub) {
      return sub + '/';
    }
    if (userContext) {
      return userContext.sub + '/' + (userContext.organizationUser?.id || '');
    }
    return 'not_available';
  };

  /* private async refreshTokenResponseWithAuthRequestAsync({
    authRequest,
    tokenResponse,
  }: {
    authRequest: AuthSession.AuthRequest;
    tokenResponse: AuthSession.TokenResponse;
  }): Promise<AuthSession.TokenResponse | null> {
    if (!tokenResponse || !authRequest) {
      return null;
    }

    // const tokenParameters = this.getTokenParameters({})

    const getTokenParameters = {
      client_id: authRequest.clientId,
      code_verifier: authRequest.codeVerifier,
      code_challenge_method: authRequest.codeChallengeMethod,
      code_challenge: authRequest.codeChallenge,
      scope: `${authRequest.clientId} offline_access`,
      refresh_token: tokenResponse.refreshToken,
      grant_type: AuthSession.GrantType.RefreshToken,
      p: 'b2c_1_app_signin',
    };

    const getUrl = (isSignUp = false) => {
      return `https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/oauth2/v2.0/token?p=${
        isSignUp ? 'b2c_1_app_signup' : 'b2c_1_app_signin'
      }`;
    };

    return null;
  } */

  /*   private async getLocalUserContextsWithRefreshedTokenResponseAsync(
    localUserContexts: LocalUserContexts,
  ): Promise<LocalUserContexts> {
    return localUserContexts;
  }

  private async getLocalUserContextWithRefreshedTokenResponseAsync(
    localUserContext: LocalUserContext,
  ): Promise<LocalUserContext | null> {
    return null;
  } */

  private async getRefreshedTokenResponseForTokenResponseAsync(
    tokenResponse: AuthSession.TokenResponse,
  ): Promise<AuthSession.TokenResponse | null> {
    // Don't try to update the userContextsToBeUpdated if the backend is not reachable.
    const isBackendReachable = await getIsBackendReachable();
    if (isBackendReachable === false) {
      return tokenResponse;
    }

    const url = this.getAzureB2CTokenResponseEndpointForPolicyName();
    const authRequest = new AuthSession.AuthRequest(authRequestConfig);
    const verifiedAuthRequest = await this.verifyAuthRequestAsync(authRequest);
    if (verifiedAuthRequest === null) {
      return null;
    }
    const tokenParameters = this.getParametersForRefreshTokenResponseRequest({
      authRequest: verifiedAuthRequest,
      tokenResponse,
    });

    if (tokenParameters === null) {
      return null;
    }

    const body = this.parametersToFormBodyString(tokenParameters);
    const azureB2CTokenResponseRaw = await this.fetchResponseWithBody({ url, body });

    if (azureB2CTokenResponseRaw) {
      return this.getTokenResponseFromAzureB2CTokenResponse(azureB2CTokenResponseRaw);
    }
    return null;
  }

  private async getTokenResponseFromAuthRequestAndAuthSessionResultAsync({
    authRequest,
    authSessionResult,
    isSignUp,
  }: {
    authRequest: AuthSession.AuthRequest;
    authSessionResult: AuthSession.AuthSessionResult;
    isSignUp: boolean;
  }): Promise<AuthSession.TokenResponse | null> {
    // Check authRequest
    if (!authRequest) {
      return null;
    }

    // Check authSessionResult
    if (!authSessionResult) {
      return null;
    } else if (authSessionResult.type !== 'success' || authSessionResult.params.code === null) {
      return null;
    }

    const url = this.getAzureB2CTokenResponseEndpointForPolicyName(isSignUp);

    const tokenParameters = this.getParametersForTokenResponseFromCodeRequest({
      authRequest,
      authSessionResult,
    });

    if (tokenParameters === null) {
      return null;
    }

    const body = this.parametersToFormBodyString(tokenParameters);
    const azureB2CTokenResponseRaw = await this.fetchResponseWithBody({ url, body });

    if (azureB2CTokenResponseRaw) {
      const res = await this.getTokenResponseFromAzureB2CTokenResponse(azureB2CTokenResponseRaw);
      return res;
    }
    return null;
  }

  private getAzureB2CTokenResponseEndpointForPolicyName(isSignUpRequest = false): string {
    const policyName = isSignUpRequest ? 'b2c_1_app_signup' : 'b2c_1_app_signin';
    return `https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/oauth2/v2.0/token?p=${policyName}`;
  }

  private getTokenResponseFromAzureB2CTokenResponse = (
    azureB2CTokenResponseRaw: Record<string, string>,
  ): AuthSession.TokenResponse | null => {
    if (!azureB2CTokenResponseRaw) {
      return null;
    }

    if (azureB2CTokenResponseRaw && azureB2CTokenResponseRaw.error) {
      if (azureB2CTokenResponseRaw.error === 'invalid_grant') {
        return null;
      }
      return null;
    }
    return AuthSession.TokenResponse.fromQueryParams(azureB2CTokenResponseRaw);
  };

  private getParametersForRefreshTokenResponseRequest({
    authRequest,
    tokenResponse,
  }: {
    authRequest: AuthSession.AuthRequest;
    tokenResponse: AuthSession.TokenResponse;
  }): Record<string, string | number | boolean | undefined | null> | null {
    if (!tokenResponse) {
      return null;
    }

    const parameters = {
      client_id: authRequest.clientId,
      code_verifier: authRequest.codeVerifier,
      code_challenge_method: authRequest.codeChallengeMethod,
      code_challenge: authRequest.codeChallenge,
      scope: `${authRequest.clientId} offline_access`,
      refresh_token: tokenResponse.refreshToken,
      grant_type: AuthSession.GrantType.RefreshToken,
      p: 'b2c_1_app_signin',
    };

    return parameters;
  }

  private getParametersForTokenResponseFromCodeRequest({
    authRequest,
    authSessionResult,
  }: {
    authRequest: AuthSession.AuthRequest;
    authSessionResult: AuthSession.AuthSessionResult;
  }): Record<string, string | number | boolean | undefined | null> | null {
    if (authSessionResult.type !== 'success') {
      return null;
    }

    return {
      client_id: authRequest.clientId,
      response_type: AuthSession.ResponseType.Token,
      code_verifier: authRequest.codeVerifier,
      code_challenge_method: authRequest.codeChallengeMethod,
      code_challenge: authRequest.codeChallenge,
      scope: authRequest.scopes?.join(' '),
      code: authSessionResult.params.code, // This is only set if ruthSessionResult is success or error.
      grant_type: AuthSession.GrantType.AuthorizationCode,
    };
  }

  private async fetchResponseWithBody({
    url,
    body,
  }: {
    url: string;
    body: string;
  }): Promise<Record<string, string> | null> {
    try {
      return fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body,
      }).then((response) => {
        if (response) {
          const jsonResponse = response.json();
          return jsonResponse;
        }
        return null;
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private parametersToFormBodyString = (
    parameters: Record<string, string | number | boolean | undefined | null>,
  ): string => {
    const formBodyValues: string[] = [];
    Object.entries(parameters).forEach(([key, value]) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(value !== null && value !== undefined ? value : '');
      formBodyValues.push(encodedKey + '=' + encodedValue);
    });
    return formBodyValues.join('&');
  };
}
