import { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { reducer, INITIAL_STATE } from './reducer';
import { AgencyJobPlanningItemWithOffline, AgencyJobPlanningWithOffline } from './types';
import { getStorageDataAgencyJobPlanning, saveStorageDataAgencyJobPlanning } from './util';
import {
  MUTATION_AGENCY_JOB_PLANNING_ITEM_UPDATE,
  MUTATION_AGENCY_JOB_PLANNING_ITEM_RESET,
} from '../../../../apollo/graphql-mutations';
import { QUERY_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB } from '../../../../apollo/graphql-queries';
import AppUserContext from '../../../../contexts/AppUserContext';
import {
  AgencyJobPlanning,
  AgencyJobPlanningItem,
  OrganizationType,
} from '../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';

export const useHook = ({
  agencyJobId,
  getLatestChatMessages,
}: {
  agencyJobId: string | undefined;
  getLatestChatMessages: () => void;
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route: any = useRoute();
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);

  const isCourier =
    appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER;

  const chatTrackAndTracingFormRef = useRef<any>(null);
  const chatTrackAndTracingOfflineFormRef = useRef<any>(null);
  const chatPlanningListModalRef = useRef<any>(null);
  const formModalRef = useRef<any>(null);
  const refetchInterval = useRef<NodeJS.Timer | null>(null);

  const errorCb = async (e: any) => {
    const message = e?.graphQLErrors?.[0]?.message;
    if (message) {
      if (message?.includes('The given value for the argument variable agencyJobPlanningItemId')) {
        chatTrackAndTracingFormRef.current?.toggleVisibility(false);
        showMessage({
          message: t('common.error', { defaultValue: 'Error' }) as string,
          description: t('jobs.planningItemRemovedPleaseCheckPlanningDetails', {
            defaultValue:
              'Planning item does not exist. Probably it was removed by your agency, please check planning details.',
          }),
          type: 'danger',
          duration: 8000,
          autoHide: true,
          hideOnPress: true,
        });
        await refetch();
        setTimeout(() => {
          dispatch({ type: 'resetCurrentStep', payload: {} });
        }, 500);
      } else {
        showMessage({
          message: t('common.error', { defaultValue: 'Error' }) as string,
          description:
            message ||
            (t('common.somethingWentWrong', { defaultValue: 'Something went wrong' }) as string),
          type: 'danger',
          duration: 8000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    }
  };

  const [agencyJobPlanningItemUpdate] = useMutation(MUTATION_AGENCY_JOB_PLANNING_ITEM_UPDATE, {
    context: { cb: errorCb },
  });
  const [agencyJobPlanningItemReset] = useMutation(MUTATION_AGENCY_JOB_PLANNING_ITEM_RESET);

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const [chatFlightDetailsModalVisible, setChatFlightDetailsModalVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<number>(0);

  /*********************************************************************************************************************
   * DATA
   ********************************************************************************************************************/
  const { refetch } = useQuery<{
    agencyJobPlanningForAgencyJob: AgencyJobPlanning;
  }>(QUERY_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB, {
    variables: { agencyJobId },
    skip: !agencyJobId,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-only',
    onCompleted: async (res: { agencyJobPlanningForAgencyJob: AgencyJobPlanning }) => {
      setIsLoading(false);
      if (res.agencyJobPlanningForAgencyJob) {
        let agencyJobPlanning = res.agencyJobPlanningForAgencyJob;
        if (
          !state.agencyJobPlanning ||
          state.agencyJobPlanning.id !== res.agencyJobPlanningForAgencyJob.id
        ) {
          const { storage } = await getStorageDataAgencyJobPlanning();
          const agencyJobPlanningId = agencyJobPlanning.id;

          if (
            agencyJobPlanningId &&
            storage[agencyJobPlanningId] &&
            Object.keys(storage[agencyJobPlanningId]).length
          ) {
            const agencyJobPlanningItemKeys = Object.keys(storage[agencyJobPlanningId]);
            agencyJobPlanning = {
              ...agencyJobPlanning,
              agencyJobPlanningItems: agencyJobPlanning.agencyJobPlanningItems.map((item) => {
                if (item.actualDateTimeSetAt) {
                  return item;
                }
                if (agencyJobPlanningItemKeys.includes(item.id)) {
                  return { ...item, ...storage[agencyJobPlanningId][item.id] };
                }
                return item;
              }),
              agencyJobPlanningLegs: agencyJobPlanning.agencyJobPlanningLegs.map((leg) => {
                return {
                  ...leg,
                  agencyJobPlanningItems: leg.agencyJobPlanningItems.map((item) => {
                    if (item.actualDateTimeSetAt) {
                      return item;
                    }
                    if (agencyJobPlanningItemKeys.includes(item.id)) {
                      return { ...item, ...storage[agencyJobPlanningId][item.id] };
                    }
                    return item;
                  }),
                };
              }),
            };
          }
        }
        dispatch({
          type: 'syncAPIData',
          payload: {
            calculateNextStep: !isCourier || loaded < 2,
            agencyJobPlanning: agencyJobPlanning as AgencyJobPlanningWithOffline,
          },
        });
      }
      if (loaded < 2) {
        setLoaded((prev) => prev + 1);
      }
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const agencyJobPlanningLegsActive = useMemo(() => {
    if (state.agencyJobPlanning?.agencyJobPlanningLegs?.length) {
      return state.agencyJobPlanning?.agencyJobPlanningLegs.filter((item) => item.active);
    }
    return [];
  }, [state.agencyJobPlanning]);

  const countOfItemsOutOfSync = useMemo(() => {
    let _countOfItemsOutOfSync = 0;
    if (state.agencyJobPlanning) {
      state.agencyJobPlanning.agencyJobPlanningItems.forEach((item) => {
        if (item.offline) {
          _countOfItemsOutOfSync++;
        }
      });
      state.agencyJobPlanning.agencyJobPlanningLegs.some((leg) =>
        leg.agencyJobPlanningItems.some((item) => {
          if (item.offline) {
            _countOfItemsOutOfSync++;
          }
        }),
      );
    }
    return _countOfItemsOutOfSync;
  }, [state.agencyJobPlanning]);

  const { currentItem, currentItemText } = useMemo(() => {
    let _currentItem;
    let _currentItemText = '';
    if (state.agencyJobPlanning && state.agencyJobPlanning?.agencyJobPlanningItems?.length) {
      if (typeof state.currentIndex === 'number') {
        _currentItem = state.agencyJobPlanning.agencyJobPlanningItems[state.currentIndex];
        _currentItemText = _currentItem.label;
      }
      if (
        typeof state.currentLegIndex === 'number' &&
        typeof state.currentLegItemIndex === 'number'
      ) {
        _currentItem =
          state.agencyJobPlanning.agencyJobPlanningLegs[state.currentLegIndex]
            ?.agencyJobPlanningItems[state.currentLegItemIndex];
        const _currentLeg = state.agencyJobPlanning.agencyJobPlanningLegs[state.currentLegIndex];
        if (_currentLeg) {
          _currentItemText = `Leg ${_currentLeg.order}: ${_currentItem.label}`;
        }
      }
    }

    if (
      state.isCompleted &&
      !state.isEmpty &&
      typeof state.currentIndex === 'undefined' &&
      typeof state.currentLegIndex === 'undefined'
    ) {
      if (countOfItemsOutOfSync) {
        _currentItemText = t('jobs.allTrackingPointsMustBeSentForFullCompletion', {
          defaultValue: 'All tracking points must be sent online for full completion',
        }).toUpperCase();
      } else {
        _currentItemText = t('jobs.allTrackingPointsAreDone', {
          defaultValue: 'All tracking points are done',
        }).toUpperCase();
      }
    }
    if (!_currentItemText && state.agencyJobPlanning?.agencyJobPlanningItems) {
      _currentItemText = t('jobs.noTrackingPointsPlanned', {
        defaultValue: 'No tracking points planned',
      });
    }
    return { currentItem: _currentItem, currentItemText: _currentItemText };
  }, [countOfItemsOutOfSync, state, t]);

  const handleOpenForm = () => {
    if (state.agencyJobPlanning) {
      if (
        !state.isCompleted ||
        typeof state.currentIndex !== 'undefined' ||
        typeof state.currentLegIndex !== 'undefined'
      ) {
        chatTrackAndTracingFormRef.current?.setData(currentItem);
        chatTrackAndTracingFormRef.current?.toggleVisibility(true);
      }
    }
  };

  const updateItemWhenOffline = async (payloadFormData: any) => {
    const { storage } = await getStorageDataAgencyJobPlanning();

    const agencyJobPlanningId = state.agencyJobPlanning?.id;
    if (agencyJobPlanningId) {
      if (!storage[agencyJobPlanningId]) {
        storage[agencyJobPlanningId] = {};
      }
      if (currentItem) {
        storage[agencyJobPlanningId][currentItem.id] = {
          ...currentItem,
          active: !!currentItem?.active,
          actualDateTime: payloadFormData.actualDateTime,
          actualDateTimeSetAt: DateTime.now(),
          location: payloadFormData.location,
          files: payloadFormData.files,
          note: payloadFormData.note,
          offline: true,
        };
        await saveStorageDataAgencyJobPlanning(JSON.stringify(storage));
      }
    }

    dispatch({
      type: 'syncAPIDataItem',
      payload: {
        agencyJobPlanningItem: {
          ...currentItem,
          active: !!currentItem?.active,
          actualDateTime: payloadFormData.actualDateTime,
          location: payloadFormData.location,
          files: payloadFormData.files,
          note: payloadFormData.note,
          offline: true,
        } as any,
      },
    });
  };

  const agencyJobPlanningItemRequest = async (agencyJobPlanningItemUpdateInput: any) => {
    try {
      const res = await agencyJobPlanningItemUpdate({
        variables: {
          agencyJobPlanningId: state.agencyJobPlanning?.id,
          agencyJobPlanningItemUpdateInput,
        },
      });
      if (res) {
        dispatch({
          type: 'syncAPIDataItem',
          payload: { agencyJobPlanningItem: res.data.agencyJobPlanningItemUpdate },
        });
        chatTrackAndTracingFormRef.current?.toggleVisibility(false);
        setTimeout(() => {
          getLatestChatMessages();
        }, 200);
        return true;
      }
    } catch {
      return false;
    }
    return false;
  };

  const handleConfirmForm = async (payloadFormData: any) => {
    if (isBackendReachable) {
      const agencyJobPlanningItemUpdateInput: {
        agencyJobPlanningItemId: string;
        actualDateTime: DateTime;
        isLegItem: boolean;
        location: any;
        files: any[];
        note: string;
      } = {
        agencyJobPlanningItemId: payloadFormData.agencyJobPlanningItemId,
        actualDateTime: payloadFormData.actualDateTime,
        isLegItem: payloadFormData.isLegItem,
        location: null,
        files: payloadFormData.files,
        note: payloadFormData.note,
      };
      if (payloadFormData.location?.geometry) {
        agencyJobPlanningItemUpdateInput.location = {
          description: payloadFormData.location.description,
          formattedAddress: payloadFormData.location.formattedAddress,
          latitude: payloadFormData.location.geometry.location.lat,
          longitude: payloadFormData.location.geometry.location.lng,
          types: payloadFormData.location.locationTypes,
          placeId: payloadFormData.location.placeId,
        };
      }
      const res = await agencyJobPlanningItemRequest(agencyJobPlanningItemUpdateInput);
      return res;
    }

    updateItemWhenOffline(payloadFormData);
    chatTrackAndTracingFormRef.current?.toggleVisibility(false);
    return true;
  };

  const handleCancelForm = () => {
    chatTrackAndTracingFormRef.current?.toggleVisibility(false);
  };

  const handleOpenFlightDetails = () => {
    if (state.agencyJobPlanning) {
      setChatFlightDetailsModalVisible(true);
    }
  };

  const handleCancelFlightDetails = () => {
    setChatFlightDetailsModalVisible(false);
  };

  const handleOpenPlanningModal = () => {
    if (state.agencyJobPlanning) {
      chatPlanningListModalRef.current?.toggleVisibility(true);
    }
  };

  const handleCancelPlanningModal = () => {
    chatPlanningListModalRef.current?.toggleVisibility(false);
  };

  const handleSelectListItem = (item: AgencyJobPlanningItemWithOffline) => () => {
    if (isCourier) {
      const found = state.agencyJobPlanningAllItemsWithLegItems.find((x) => item.id === x.id);
      if (found) {
        dispatch({
          type: 'selectIndex',
          payload: {
            selectIndex: {
              currentIndex: found.index,
              currentLegIndex: found.legIndex,
              currentLegItemIndex: found.legItemIndex,
            },
          },
        });
        handleCancelPlanningModal();
      }
    }
  };

  const handleSelectListItemOffline = (item: AgencyJobPlanningItemWithOffline) => () => {
    chatTrackAndTracingOfflineFormRef.current?.toggleVisibility(true);
    chatTrackAndTracingOfflineFormRef.current?.setData(item);
  };

  const handleResetPress = async (
    agencyJobPlanningItem: AgencyJobPlanningItem,
  ): Promise<boolean> => {
    try {
      const res = await agencyJobPlanningItemReset({
        variables: {
          agencyJobPlanningId: state.agencyJobPlanning?.id,
          agencyJobPlanningItemId: agencyJobPlanningItem.id,
        },
      });
      if (res) {
        getLatestChatMessages();
        dispatch({
          type: 'syncAPIDataItem',
          payload: {
            agencyJobPlanningItem: res.data.agencyJobPlanningItemReset,
          },
        });
        chatTrackAndTracingFormRef.current?.toggleVisibility(false);
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  useEffect((): any => {
    if (agencyJobId && !refetchInterval.current) {
      refetchInterval.current = setInterval(() => {
        refetch();
      }, 60 * 1000);
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyJobId, refetchInterval]);

  useEffect(() => {
    if (route.params?.refetch && agencyJobId) {
      navigation.setParams({ refetch: false });
      refetch();
      getLatestChatMessages();
    }
  }, [agencyJobId, getLatestChatMessages, navigation, refetch, route]);

  return {
    agencyJobPlanningLegsActive,
    formModalRef,
    currentItem,
    currentItemText,
    chatFlightDetailsModalVisible,
    chatTrackAndTracingFormRef,
    chatTrackAndTracingOfflineFormRef,
    chatPlanningListModalRef,
    countOfItemsOutOfSync,
    handleConfirmForm,
    handleCancelForm,
    handleCancelFlightDetails,
    handleCancelPlanningModal,
    handleOpenForm,
    handleOpenFlightDetails,
    handleOpenPlanningModal,
    handleResetPress,
    handleSelectListItem,
    handleSelectListItemOffline,
    isCourier,
    isLoading,
    loaded,
    state,
  };
};
