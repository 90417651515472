import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { SwitchAccount } from '../../components/common/switch-account.component';
import { TopNavigationBackToProfileButton } from '../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../contexts/AppUserContext';

export const CommonSwitchAccountScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  return (
    <>
      <TopNavigationBackToProfileButton
        title={t('menuItems.switchAccount', { defaultValue: 'Switch Account' }) as string}
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
        accessoryLeft={appUserContext.currentUserContext?.user ? undefined : () => <></>}
      />
      <SwitchAccount navigation={navigation} />
    </>
  );
};
