import React, { useContext, useMemo } from 'react';
import { View } from 'react-native';

import { Button, StyleService, useStyleSheet } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { Icon, Text, Spinner } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import {
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
  COURIER_JOBS_FOR_AGENCY_JOB_QUERY,
} from '../../../../apollo/graphql-queries';
import { globalStyle } from '../../../../common/style';
import AppUserContext from '../../../../contexts/AppUserContext';
import {
  AgencyJob,
  AgencyJobStatusType,
  CourierJobAgencyRelationshipType,
  OrganizationType,
  useChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
} from '../../../../generated-graphql-types';
import { ConfirmModalContext } from '../../../common/modal/ConfirmModal';
import { CheckOutlineIcon } from '../../../icons.component';

const AgencyJobStatusTypeChangeButton = ({
  accessoryLeft,
  disabled = false,
  isLoading = false,
  hasMarginRight = false,
  onPress,
  status = 'primary',
  text,
}: {
  accessoryLeft: any;
  disabled?: boolean;
  isLoading: boolean;
  hasMarginRight?: boolean;
  onPress?: any;
  status?: EvaStatus;
  text: string;
}) => {
  const renderAccessoryLeft = () => {
    if (isLoading) {
      return (
        <View
          style={[
            globalStyle.width25,
            globalStyle.justifyContentCenter,
            globalStyle.alignItemsCenter,
          ]}
        >
          <Spinner size="tiny" status="basic" />
        </View>
      );
    }
    return (
      <View
        style={[
          globalStyle.width25,
          globalStyle.justifyContentCenter,
          globalStyle.alignItemsCenter,
        ]}
      >
        {accessoryLeft}
      </View>
    );
  };

  return (
    <Button
      appearance="filled"
      disabled={disabled}
      accessoryLeft={renderAccessoryLeft}
      accessoryRight={<View style={globalStyle.width25} />}
      onPress={onPress}
      status={status}
      size="tiny"
      style={[
        hasMarginRight ? globalStyle.marginRight10 : globalStyle.marginRight0,
        globalStyle.marginTop10,
        globalStyle.alignItemsCenter,
        globalStyle.justifyContentCenter,
      ]}
    >
      <Text style={globalStyle.marginBottom2}>{text}</Text>
    </Button>
  );
};

const AgencyJobStatusChangeButtonsWithHint = ({
  children,
  hintText,
  showHintCondition = false,
}: {
  hintText: string;
  showHintCondition: boolean;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={styles.agencyJobStatusChangeLayer}>
      <View style={styles.agencyJobStatusChangeButtonLayer}>{children}</View>

      {!!showHintCondition && (
        <View style={styles.agencyJobStatusChangeHintLayer}>
          <Icon
            name="info-outline"
            width={20}
            height={20}
            fill="orange"
            style={globalStyle.marginRight5}
          />
          <Text style={styles.agencyJobStatusChangeHintText}>{hintText}</Text>
        </View>
      )}
    </View>
  );
};

const AgencyJobStatusTypeButtonStart = ({
  agencyJob,
  hasMarginRight = false,
  buttonText,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  hasMarginRight?: boolean;
  buttonText?: string;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const { t } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const appUserContext = useContext(AppUserContext);

  const numberOfAcceptedCourierJobs =
    agencyJob.courierJobs?.filter(
      (courierJob) =>
        courierJob.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED,
    ).length || 0;

  const [changeAgencyJobStatusTypeForAgencyOrganizationMutation, { loading }] =
    useChangeAgencyJobStatusTypeForAgencyOrganizationMutation({
      refetchQueries:
        appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
          ? [COURIER_JOBS_FOR_AGENCY_JOB_QUERY]
          : [
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
            ],
      onCompleted: (res) => {
        if (updateJob && res.changeAgencyJobStatusTypeForAgencyOrganization) {
          updateJob({
            ...agencyJob,
            agencyJobStatusType:
              res.changeAgencyJobStatusTypeForAgencyOrganization.agencyJobStatusType,
          });
        }
      },
    });

  const changeAgencyJobStatusTypeForAgencyOrganization = (
    agencyJobStatusType: AgencyJobStatusType,
  ) => {
    changeAgencyJobStatusTypeForAgencyOrganizationMutation({
      variables: {
        agencyJobId: agencyJob.id as string,
        agencyJobStatusType,
      },
    }).then(() => {
      showMessage({
        message: t('jobs.jobStarted', { defaultValue: 'Job started' }),
        description: t('jobs.jobStartedSuccessfully', {
          defaultValue: '{{courierJobIdentifier}} successfully started.',
          courierJobIdentifier: agencyJob.job?.priojetJobIdentifier,
        }) as string,
        renderFlashMessageIcon: CheckOutlineIcon,
        type: 'success',
      });
    });
  };

  const confirmationPopup = () => {
    showConfirmModal({
      confirmButtonStatus: 'primary',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.start', { defaultValue: 'Start' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('jobs.startJob', { defaultValue: 'Start Job' }),
      text: t('jobs.startJobConfirmationPopupText', {
        defaultValue:
          'Do you really want to start the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
        priojetJobIdentifier: agencyJob?.job?.priojetJobIdentifier,
        dropOffLocationName: agencyJob?.job?.dropOffLocation?.formattedShortAddress,
      }),
      onConfirmPress: async () => {
        await changeAgencyJobStatusTypeForAgencyOrganization(AgencyJobStatusType.STARTED);
        return true;
      },
      visible: true,
    });
  };

  const buttonProps = useMemo(() => {
    if (numberOfAcceptedCourierJobs >= 1) {
      return {
        accessoryLeft: <Icon fill="white" name="play-circle-outline" width={11} height={11} />,
        disabled: false,
        onPress: confirmationPopup,
        status: 'success',
        text: buttonText || (t('jobs.startJob', { defaultValue: 'Start Job' }) as string),
      };
    }
    return {
      accessoryLeft: <Icon fill="white" name="play-circle-outline" width={11} height={11} />,
      disabled: true,
      text: buttonText || (t('jobs.startJob', { defaultValue: 'Start Job' }) as string),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonText, numberOfAcceptedCourierJobs, t]);

  return (
    <AgencyJobStatusTypeChangeButton
      hasMarginRight={hasMarginRight}
      isLoading={loading}
      {...buttonProps}
    />
  );
};

const AgencyJobStatusTypeButtonFinish = ({
  agencyJob,
  hasMarginRight = false,
  buttonText,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  hasMarginRight?: boolean;
  buttonText?: string;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const { t } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const [changeAgencyJobStatusTypeForAgencyOrganizationMutation, { loading }] =
    useChangeAgencyJobStatusTypeForAgencyOrganizationMutation({
      refetchQueries: [
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
        COURIER_JOBS_FOR_AGENCY_JOB_QUERY,
      ],
      onCompleted: (res) => {
        if (updateJob && res.changeAgencyJobStatusTypeForAgencyOrganization) {
          updateJob({
            ...agencyJob,
            agencyJobStatusType:
              res.changeAgencyJobStatusTypeForAgencyOrganization.agencyJobStatusType,
          });
        }
      },
    });

  const changeAgencyJobStatusTypeForAgencyOrganization = (_: AgencyJobStatusType) => {
    changeAgencyJobStatusTypeForAgencyOrganizationMutation({
      variables: {
        agencyJobId: agencyJob.id as string,
        agencyJobStatusType: AgencyJobStatusType.FINISHED,
      },
    }).then(() => {
      showMessage({
        message: t('jobs.jobFinished', { defaultValue: 'Job finished' }),
        description: t('jobs.jobFinishedSuccessfully', {
          defaultValue: '{{courierJobIdentifier}} successfully finished.',
          courierJobIdentifier: agencyJob.job?.priojetJobIdentifier,
        }) as string,
        renderFlashMessageIcon: CheckOutlineIcon,
        type: 'success',
      });
    });
  };

  const confirmationPopup = () => {
    showConfirmModal({
      confirmButtonStatus: 'primary',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.confirm', { defaultValue: 'Confirm' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('jobs.confirmAndFinishJob', { defaultValue: 'Confirm and finish Job' }),
      text: t('jobs.confirmAndFinishJobConfirmationPopupText', {
        defaultValue:
          'Do you really want to confirm and finish the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
        priojetJobIdentifier: agencyJob?.job?.priojetJobIdentifier,
        dropOffLocationName: agencyJob?.job?.dropOffLocation?.formattedShortAddress,
      }),

      onConfirmPress: async () => {
        await changeAgencyJobStatusTypeForAgencyOrganization(AgencyJobStatusType.FINISHED);
        return true;
      },
      visible: true,
    });
  };

  return (
    <AgencyJobStatusTypeChangeButton
      hasMarginRight={hasMarginRight}
      isLoading={loading}
      disabled={false}
      status="success"
      accessoryLeft={<Icon name="checkmark-circle-2-outline" fill="white" width={11} height={11} />}
      text={
        buttonText ||
        (t('jobs.confirmAndFinishJob', {
          defaultValue: 'Confirm and finish Job',
        }) as string)
      }
      onPress={confirmationPopup}
    />
  );
};

const AgencyJobStatusTypeButtonNew = ({
  agencyJob,
  hasMarginRight = false,
  buttonText,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  hasMarginRight?: boolean;
  buttonText?: string;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const { t } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const appUserContext = useContext(AppUserContext);

  const [changeAgencyJobStatusTypeForAgencyOrganizationMutation, { loading }] =
    useChangeAgencyJobStatusTypeForAgencyOrganizationMutation({
      refetchQueries:
        appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
          ? [COURIER_JOBS_FOR_AGENCY_JOB_QUERY]
          : [
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
            ],
      onCompleted: (res) => {
        if (updateJob && res.changeAgencyJobStatusTypeForAgencyOrganization) {
          updateJob({
            ...agencyJob,
            agencyJobStatusType:
              res.changeAgencyJobStatusTypeForAgencyOrganization.agencyJobStatusType,
          });
        }
      },
    });

  const changeAgencyJobStatusTypeForAgencyOrganization = (
    agencyJobStatusType: AgencyJobStatusType,
  ) => {
    changeAgencyJobStatusTypeForAgencyOrganizationMutation({
      variables: {
        agencyJobId: agencyJob.id as string,
        agencyJobStatusType,
      },
    }).then(() => {
      showMessage({
        message: t('jobs.resetJob', { defaultValue: 'Reset job' }),
        description: t('jobs.jobMarkedAsNew', {
          defaultValue: '{{courierJobIdentifier}} successfully marked as New.',
          courierJobIdentifier: agencyJob.job?.priojetJobIdentifier,
        }) as string,
        renderFlashMessageIcon: CheckOutlineIcon,
        type: 'success',
      });
    });
  };

  const confirmationPopup = () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.reset', { defaultValue: 'Reset' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('jobs.resetJob', { defaultValue: 'Reset job' }),
      text: t('jobs.resetJobAsNewConfirmationPopupText', {
        defaultValue:
          'Do you really want to set the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} back to New again? This will notify previously selected couriers.',
        priojetJobIdentifier: agencyJob?.job?.priojetJobIdentifier,
        dropOffLocationName: agencyJob?.job?.dropOffLocation?.formattedShortAddress,
      }),

      onConfirmPress: async () => {
        await changeAgencyJobStatusTypeForAgencyOrganization(AgencyJobStatusType.NEW);
        return true;
      },
      visible: true,
    });
  };

  return (
    <AgencyJobStatusTypeChangeButton
      hasMarginRight={hasMarginRight}
      isLoading={loading}
      status="warning"
      accessoryLeft={<Icon name="star-outline" fill="white" width={11} height={11} />}
      text={buttonText || (t('jobs.resetJob', { defaultValue: 'Reset job' }) as string)}
      onPress={confirmationPopup}
    />
  );
};

const AgencyJobStatusTypeButtonCancel = ({
  agencyJob,
  hasMarginRight = false,
  buttonText,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  hasMarginRight?: boolean;
  buttonText?: string;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const { t } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const [changeAgencyJobStatusTypeForAgencyOrganizationMutation, { loading }] =
    useChangeAgencyJobStatusTypeForAgencyOrganizationMutation({
      refetchQueries: [
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_PAGINATED_QUERY,
        AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY,
        COURIER_JOBS_FOR_AGENCY_JOB_QUERY,
      ],
      onCompleted: (res) => {
        if (updateJob && res.changeAgencyJobStatusTypeForAgencyOrganization) {
          updateJob({
            ...agencyJob,
            agencyJobStatusType:
              res.changeAgencyJobStatusTypeForAgencyOrganization.agencyJobStatusType,
          });
        }
      },
    });

  const changeAgencyJobStatusTypeForAgencyOrganization = (
    agencyJobStatusType: AgencyJobStatusType,
  ) => {
    changeAgencyJobStatusTypeForAgencyOrganizationMutation({
      variables: {
        agencyJobId: agencyJob.id as string,
        agencyJobStatusType,
      },
    }).then(() => {
      showMessage({
        message: t('jobs.jobCancelled', { defaultValue: 'Job cancelled' }),
        description: t('jobs.jobCancelledSuccessfully', {
          defaultValue: 'Job {{courierJobIdentifier}} successfully cancelled.',
          courierJobIdentifier: agencyJob.job?.priojetJobIdentifier,
          agencyJobStatusType,
        }) as string,
        renderFlashMessageIcon: CheckOutlineIcon,
        type: 'success',
      });
    });
  };

  const confirmationPopup = () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.confirm', { defaultValue: 'Confirm' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('jobs.cancelJob', { defaultValue: 'Cancel Job' }),
      text: t('jobs.cancelJobConfirmationPopupText', {
        defaultValue:
          'Do you really want to cancel the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
        priojetJobIdentifier: agencyJob?.job?.priojetJobIdentifier,
        dropOffLocationName: agencyJob?.job?.dropOffLocation?.formattedShortAddress,
      }),

      onConfirmPress: async () => {
        await changeAgencyJobStatusTypeForAgencyOrganization(AgencyJobStatusType.CANCELLED);
        return true;
      },
      visible: true,
    });
  };

  return (
    <AgencyJobStatusTypeChangeButton
      hasMarginRight={hasMarginRight}
      isLoading={loading}
      status="danger"
      accessoryLeft={<Icon name="close-circle-outline" fill="white" width={11} height={11} />}
      text={buttonText || (t('jobs.cancelJob', { defaultValue: 'Cancel Job' }) as string)}
      onPress={confirmationPopup}
    />
  );
};

export const AgencyJobStatusTypeChangeNew = ({
  agencyJob,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const hintText =
    'You can only start this Job if you have at least one Courier Job confirmed/accepted for this Job. Starting this Job will set all non-accepted Courier Jobs to AGENCY_DECLINED.';
  const numberOfAcceptedCourierJobs: number =
    agencyJob.courierJobs?.filter(
      (courierJob) =>
        courierJob.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED,
    ).length || 0;
  const showHintCondition: boolean = numberOfAcceptedCourierJobs < 1;

  return (
    <AgencyJobStatusChangeButtonsWithHint hintText={hintText} showHintCondition={showHintCondition}>
      <AgencyJobStatusTypeButtonStart
        agencyJob={agencyJob}
        hasMarginRight={true}
        updateJob={updateJob}
      />
      <AgencyJobStatusTypeButtonCancel agencyJob={agencyJob} updateJob={updateJob} />
    </AgencyJobStatusChangeButtonsWithHint>
  );
};

export const AgencyJobStatusTypeChangeStarted = ({
  agencyJob,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const hintText =
    'If you set this Job to FINISHED, you will also set the Courier Jobs to FINISHED_AGENCY_CONFIRMED. \nResetting this Job as NEW will keep all the AGENCY_ACCEPTED Courier Jobs. \nCancelling this Job would set all Courier Jobs to AGENCY_DECLINED.';

  return (
    <AgencyJobStatusChangeButtonsWithHint hintText={hintText} showHintCondition={true}>
      <AgencyJobStatusTypeButtonFinish
        agencyJob={agencyJob}
        hasMarginRight={true}
        updateJob={updateJob}
      />
      <AgencyJobStatusTypeButtonNew
        agencyJob={agencyJob}
        hasMarginRight={true}
        updateJob={updateJob}
      />
      <AgencyJobStatusTypeButtonCancel agencyJob={agencyJob} updateJob={updateJob} />
    </AgencyJobStatusChangeButtonsWithHint>
  );
};

export const AgencyJobStatusTypeChangeFinished = ({
  agencyJob,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const { t } = useTranslation();

  const hintText =
    'Changing this Job to STARTED again will also reset the FINISHED_AGENCY_CONFIRMED status for all Courier Jobs and also set all non-accepted Courier Jobs to AGENCY_DECLINED.';

  return (
    <AgencyJobStatusChangeButtonsWithHint hintText={hintText} showHintCondition={true}>
      <AgencyJobStatusTypeButtonStart
        agencyJob={agencyJob}
        buttonText={
          t('jobs.changeJobToStartedAgain', {
            defaultValue: 'Change Job to STARTED again',
          }) as string
        }
        updateJob={updateJob}
      />
    </AgencyJobStatusChangeButtonsWithHint>
  );
};

export const AgencyJobStatusTypeChangeCancelled = ({
  agencyJob,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  return (
    <AgencyJobStatusChangeButtonsWithHint hintText="" showHintCondition={false}>
      <AgencyJobStatusTypeButtonNew agencyJob={agencyJob} updateJob={updateJob} />
    </AgencyJobStatusChangeButtonsWithHint>
  );
};

const themedStyles = StyleService.create({
  refreshColor: {
    color: 'text-basic-color',
  },
  cardHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    height: 30,
    alignContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: 'color-primary-500',
  },
  cardHeaderText: {
    fontFamily: 'Lato_700Bold',
    color: 'color-basic-100',
  },
  timeDistanceLayer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  incidentIconColor: {
    backgroundColor: 'color-primary-300',
  },
  incidentIcon: {
    marginLeft: 15,
  },
  card: {
    marginBottom: 10,
    paddingHorizontal: 0,
    marginTop: 10,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: 'color-primary-500',
  },
  cardFooter: {
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'border-basic-color-2',
    borderWidth: 0,
    borderColor: 'color-primary-500',
  },
  cardFooterText: {
    color: 'text-basic-color',
    fontSize: 12,
  },
  dateTimeWithTimeZoneWrapper: {
    paddingVertical: 2,
    paddingLeft: 10,
  },
  agencyJobStatusChangeLayer: {
    flex: 1,
    flexDirection: 'column',
  },
  agencyJobStatusChangeHintText: {
    flexDirection: 'row',
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: 'gray',
    fontSize: 12,
  },
  agencyJobStatusChangeHintLayer: {
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
    marginRight: 20,
  },
  agencyJobStatusChangeButtonLayer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  agencyJobStatusOutsideLayer: {
    marginHorizontal: 10,
    flexDirection: 'row',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 5,
  },
  agencyJobStatusLayer: {
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderBottomWidth: 0,
    borderColor: 'color-border-low-contrast',
    borderRadius: 0,
  },
  agencyJobStatusText: {
    fontSize: 10,
    color: 'color-text-low-contrast',
    textTransform: 'uppercase',
  },
  agencyJobStatusCountText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 10,
    color: 'color-text-low-contrast',
  },
  agencyJobMenuItemsWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingBottom: 5,
    marginBottom: 5,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  agencyJobMenuWrapperLeft: {
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  agencyJobStatusWrapper: {
    flexDirection: 'column',
  },
  spacedElement: {
    margin: 5,
  },
  icon: {
    backgroundColor: 'color-primary-300',
  },
  locationName: {
    fontSize: 16,
  },
  menuWrapper: {
    paddingTop: 10,
    marginBottom: 0,
    backgroundColor: 'border-basic-color-2',
  },
  locationsOutsideWrapper: {},
  locationsOutsideWrapperHeader: {
    fontFamily: 'Lato_700Bold',
    marginVertical: 7,
    fontSize: 10,
    textAlign: 'center',
    backgroundColor: 'border-basic-color-2',
  },
  locationWrapper: {
    borderWidth: 0,
    paddingHorizontal: 10,
  },
  iconLocationNameWrapper: {},
  startIcon: {
    marginRight: 15,
    paddingRight: 10,
  },
  endIcon: {
    marginRight: 15,
    paddingRight: 10,
  },
  arrowIcon: {
    marginHorizontal: 5,
  },
  locationBox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderWidth: 0,
  },
  locationBoxIconNameWrapper: {
    flex: 1,
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    alignItems: 'baseline',
    borderWidth: 0,
  },
  locationBoxRight: {
    paddingLeft: 15,
    flex: 1,
  },
  locationBoxLeft: {
    flex: 1,
  },
});
