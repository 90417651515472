import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useIsFocused, useNavigation, useRoute } from '@react-navigation/core';
import { Icon } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { MUTATION_ADD_OR_DISABLE_COURIER_FOR_COURIER_JOB } from '../../../apollo/graphql-mutations';
import {
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
  QUERY_AVAILABLE_COURIER_USERS_BY_DISTANCE_FOR_AGENCY_JOB_FILTERED,
  COURIER_JOBS_FOR_AGENCY_JOB_QUERY,
  AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY,
  QUERY_COURIER_JOBS_COURIER_ORGANIZATION_USERS_FOR_AGENCY_JOB,
} from '../../../apollo/graphql-queries';
import { LoadingIndicator } from '../../../components/common/loading-indicator.component';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  AvailableCourierOrganizationUsersWithDistance,
  OrganizationType,
  JobAvailabilityFilterType,
  CourierJobsCourierOrganizationUsersForAgencyJobQuery,
} from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { RootStackParamList } from '../../../navigation/app.navigator';

export const useHook = () => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const route: any = useRoute();
  const navigation: any = useNavigation();
  const refetchInterval = useRef<NodeJS.Timer | null>(null);
  const appUserContext = useContext(AppUserContext);
  const isFocused = useIsFocused();

  const [
    availableCourierUsersByDistanceForAgencyJobFiltered,
    setAvailableCourierUsersByDistanceForAgencyJobFiltered,
  ] = useState<AvailableCourierOrganizationUsersWithDistance[] | undefined>(undefined);
  const [
    initiallySelectedCourierOrganizationUserIds,
    setInitiallySelectedCourierOrganizationUserIds,
  ] = useState<string[]>([]);

  const [filter, setFilter] = useState<any>([
    JobAvailabilityFilterType.AVAILABLE,
    JobAvailabilityFilterType.SCHEDULED,
  ]);
  const [filterInitial, setFilterInitial] = useState<any>([
    JobAvailabilityFilterType.AVAILABLE,
    JobAvailabilityFilterType.SCHEDULED,
  ]);
  const [filterSearch, setFilterSearch] = useState<any>([
    JobAvailabilityFilterType.AVAILABLE,
    JobAvailabilityFilterType.SCHEDULED,
  ]);
  const [filterOpened, setFilterOpened] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState('');
  const [selectedCourierOrganizationUserIds, setSelectedCourierOrganizationUserIds] = useState<
    string[]
  >([]);

  const {
    data: agencyJobForAgencyOrganizationData,
    refetch: agencyJobForAgencyOrganizationRefetch,
  } = useQuery(AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY, {
    variables: { agencyJobId: route.params?.agencyJobId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const handleData = (data: any) => {
    const { availableCourierUsersByDistanceForAgencyJobFiltered: availableCourierUsers } = data;
    setAvailableCourierUsersByDistanceForAgencyJobFiltered(availableCourierUsers);
  };

  const { refetch } = useQuery(QUERY_AVAILABLE_COURIER_USERS_BY_DISTANCE_FOR_AGENCY_JOB_FILTERED, {
    variables: { agencyJobId: route.params?.agencyJobId, filterType: filterSearch },
    fetchPolicy: isBackendReachable ? 'network-only' : 'cache-first',
    onCompleted: (data) => {
      handleData(data);
      setLoading(false);
    },
  });

  useQuery<CourierJobsCourierOrganizationUsersForAgencyJobQuery>(
    QUERY_COURIER_JOBS_COURIER_ORGANIZATION_USERS_FOR_AGENCY_JOB,
    {
      variables: { agencyJobId: route.params?.agencyJobId },
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-first',
      onCompleted: (data) => {
        const organizationUserIds = data.courierJobsForAgencyJob.map(
          (item) => item.responsibleCourierOrganization.organizationUsers[0].id,
        );
        setSelectedCourierOrganizationUserIds(organizationUserIds);
        setInitiallySelectedCourierOrganizationUserIds(organizationUserIds);
      },
    },
  );

  const [addOrDisableCourierJobMutation, { loading: addOrDisableCourierJobMutationLoading }] =
    useMutation(MUTATION_ADD_OR_DISABLE_COURIER_FOR_COURIER_JOB, {
      refetchQueries:
        appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
          ? [COURIER_JOBS_FOR_AGENCY_JOB_QUERY]
          : [
              QUERY_AVAILABLE_COURIER_USERS_BY_DISTANCE_FOR_AGENCY_JOB_FILTERED,
              AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
            ],
      onCompleted: () => {
        showMessage({
          message: t('common.couriersForJobSuccessfullyChanged', {
            defaultValue: 'Couriers for Job successfully changed',
          }),
          description: t('common.changedCouriersForAgencyJobDescription', {
            defaultValue: 'Successfully changed the Couriers enabled for this Job.',
          }) as string,
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
          type: 'success',
        });
        setInitiallySelectedCourierOrganizationUserIds(selectedCourierOrganizationUserIds);
      },
      onError: (e) => {
        console.warn(e);
      },
    });

  const { allCourierOrganizationUserIds, availabilities } = useMemo((): {
    availabilities: AvailableCourierOrganizationUsersWithDistance[];
    allCourierOrganizationUserIds: string[];
  } => {
    let data = availableCourierUsersByDistanceForAgencyJobFiltered || [];
    if (searchText && data?.length) {
      const searchTextSmall = searchText.toLowerCase();
      data = [...data].filter((item: AvailableCourierOrganizationUsersWithDistance) => {
        const name = `${item.user.firstNames.toLowerCase()} ${item.user.lastName.toLowerCase()}`;
        if (
          item.user.courierUserProfile?.baseAirports?.length &&
          item.user.courierUserProfile?.baseAirports.some((base) =>
            base.toLowerCase().startsWith(searchTextSmall),
          )
        ) {
          return true;
        }
        if (
          item.user.firstNames.toLowerCase().includes(searchTextSmall) ||
          item.user.lastName.toLowerCase().includes(searchTextSmall) ||
          name.includes(searchTextSmall)
        ) {
          return true;
        }
        return false;
      });
    }
    const organizationUserId = data.map((item) => item.organizationUsers[0].id);
    return { allCourierOrganizationUserIds: organizationUserId, availabilities: data };
  }, [availableCourierUsersByDistanceForAgencyJobFiltered, searchText]);

  useEffect((): any => {
    if (availableCourierUsersByDistanceForAgencyJobFiltered && !refetchInterval.current) {
      refetchInterval.current = setInterval(async () => {
        if (isFocused) {
          const res = await refetch();
          handleData(res.data);
        }
      }, 15 * 1000);
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCourierUsersByDistanceForAgencyJobFiltered, refetchInterval]);

  const addOrDisableCourierJob = async () => {
    await addOrDisableCourierJobMutation({
      variables: {
        agencyJobId: route.params.agencyJobId,
        courierOrganizationUserIds: selectedCourierOrganizationUserIds,
      },
    });
  };

  const { isAddOrDisableCourierJobButtonDisabled, isSelectAllSelected } = useMemo(() => {
    return {
      isAddOrDisableCourierJobButtonDisabled:
        selectedCourierOrganizationUserIds.length ===
          initiallySelectedCourierOrganizationUserIds.length &&
        selectedCourierOrganizationUserIds.every((item) =>
          initiallySelectedCourierOrganizationUserIds.includes(item),
        ),
      isSelectAllSelected:
        !!selectedCourierOrganizationUserIds.length &&
        allCourierOrganizationUserIds.every((item) =>
          selectedCourierOrganizationUserIds.includes(item),
        ),
    };
  }, [
    allCourierOrganizationUserIds,
    initiallySelectedCourierOrganizationUserIds,
    selectedCourierOrganizationUserIds,
  ]);

  const buttonData = useMemo(() => {
    let accessoryLeft = () => <Icon name="sync-outline" height={16} width={16} fill="#ccc" />;
    let appearance = 'outline';
    let status = 'primary';
    const disabled = isAddOrDisableCourierJobButtonDisabled;
    let text = `Update couriers (${selectedCourierOrganizationUserIds.length})`;
    if (addOrDisableCourierJobMutationLoading) {
      text = `Updating couriers (${selectedCourierOrganizationUserIds.length})`;
      accessoryLeft = () => <LoadingIndicator />;
    }
    return { accessoryLeft, appearance, disabled, status, text };
  }, [
    addOrDisableCourierJobMutationLoading,
    isAddOrDisableCourierJobButtonDisabled,
    selectedCourierOrganizationUserIds.length,
  ]);

  const handleBackPress = () => {
    if (route.params.previousScreenName === 'AgencyCourierJobsScreen') {
      navigation.navigate('AgencyCourierJobsScreen', {
        agencyJobId: route.params.agencyJobId,
        previousScreenName: 'AgencyJobsScreen',
        previousTabScreenName: route.params
          ?.previousTabScreenName as RootStackParamList['AgencyCourierJobsScreen']['previousTabScreenName'],
      });
    } else {
      navigation.navigate('AgencyJobsScreen', {
        previousTabScreenName: route.params
          ?.previousTabScreenName as RootStackParamList['AgencyJobsScreen']['previousTabScreenName'],
      });
    }
  };

  const handleSelect = (item: AvailableCourierOrganizationUsersWithDistance) => () => {
    const organizationUserId = item.organizationUsers[0].id;
    if (
      selectedCourierOrganizationUserIds.find((value) => value === organizationUserId) !== undefined
    ) {
      const filtered = selectedCourierOrganizationUserIds.filter(
        (value) => value !== organizationUserId,
      );
      setSelectedCourierOrganizationUserIds(filtered);
    } else {
      const added = [...selectedCourierOrganizationUserIds, organizationUserId];
      setSelectedCourierOrganizationUserIds(added);
    }
  };

  const handleSelectAll = () => {
    if (isSelectAllSelected) {
      setSelectedCourierOrganizationUserIds([]);
    } else {
      setSelectedCourierOrganizationUserIds(allCourierOrganizationUserIds);
    }
  };

  const handleProfilePress = (item: AvailableCourierOrganizationUsersWithDistance) => () =>
    navigation.navigate('AgencyCourierUserProfileScreen', {
      userId: item.user.id,
      agencyJobId: route.params?.agencyJobId,
      previousScreenName: 'AddOrDisableCourierJobScreen',
      previousNavigator: 'AgencyHomeNavigator',
    });

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await agencyJobForAgencyOrganizationRefetch();
    await refetch();
    setIsRefreshing(false);
  };

  const handleResetSearchText = () => {
    setSearchText('');
  };

  const handleChangeSearchText = (val: string) => {
    setSearchText(val);
  };

  const handleOpenFilterPress = () => {
    setFilterOpened(true);
    setTimeout(() => {
      setIsFilterVisible(true);
    }, 50);
  };

  const handleCloseFilterPress = () => {
    setIsFilterVisible(false);
    setFilter(filterInitial);
    setFilterSearch(filterInitial);
    setTimeout(() => {
      setFilterOpened(false);
    }, 200);
  };

  const handleDoneFilterPress = () => {
    setIsFilterVisible(false);
    let changed = false;
    if (
      filter.length !== filterInitial.length ||
      filter.some((item: any) => !filterInitial.includes(item))
    ) {
      changed = true;
    }
    if (changed) {
      setLoading(true);
      setFilterInitial(filter);
      setFilterSearch(filter);
      setAvailableCourierUsersByDistanceForAgencyJobFiltered(undefined);
    } else {
      setFilter(filterInitial);
    }
    setTimeout(() => {
      setFilterOpened(false);
    }, 200);
  };

  const onSelectFilter = (value: any) => {
    setFilter((prev: any) => {
      let values: any = [...prev];
      values = value;
      return values;
    });
  };

  return {
    agencyJobForAgencyOrganizationData,
    addOrDisableCourierJob,
    availabilities,
    buttonData,
    handleBackPress,
    handleChangeSearchText,
    handleProfilePress,
    handleRefresh,
    handleResetSearchText,
    handleSelect,
    handleSelectAll,
    initiallySelectedCourierOrganizationUserIds,
    isRefreshing,
    isSelectAllSelected,
    loading,
    searchText,
    selectedCourierOrganizationUserIds,
    setSelectedCourierOrganizationUserIds,

    filter,
    filterOpened,
    handleCloseFilterPress,
    handleDoneFilterPress,
    handleOpenFilterPress,
    isFilterVisible,
    onSelectFilter,
  };
};
