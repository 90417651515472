import React from 'react';
import { Image, View, ActivityIndicator } from 'react-native';

import { useNavigation, useRoute } from '@react-navigation/native';
import {
  Avatar,
  Button,
  CheckBox,
  Divider,
  Icon,
  Layout,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { AgencyCourierUserProfileCategoryEditComponent } from './AgencyCourierUserProfileCategoryEditComponent';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { CountryListData } from '../../../assets/constants/country.list.data';
import { globalStyle } from '../../../common/style';
import { CopyableText } from '../../../components/common/common.copyable-text.component';
import { EmailTextWithContextMenu } from '../../../components/common/common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../../components/common/common.phone-text-with-context-menu';
import { FormTextEmailComponent } from '../../../components/common/form/form-text-email.component';
import { FormTextPhoneComponent } from '../../../components/common/form/form-text-phone.component';
import { FormTextTextComponent } from '../../../components/common/form/form-text-text.component';
import ImageLightBox from '../../../components/common/ImageLightBox';
import ContentBoxTextTitle from '../../../components/content-box-text-title.component';
import { SocialIcon, SocialKeysType } from '../../../components/social-icons.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { SocialProfile } from '../../../generated-graphql-types';
import { createSocialURL } from '../../../utils/social-media.util';

export const AgencyCourierUserProfileScreen = (): React.ReactElement => {
  const navigation: any = useNavigation();
  const route: any = useRoute();
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { organizationId, userId } = route.params;

  const {
    emergencyContact,
    handleDocumentsPress,
    handleMarkedForAttention,
    handleInternalCommentChange,
    handleSelectCategory,
    handleSaveData,
    internalComment,
    loadingSave,
    markedForAttention,
    selectedOrganizationCourierCategory,
    isSaveVisible,
    languagesKnown,
    nationalities,
    organization,
    profile,
    profileImage,
    socialProfiles,
    taxCountry,
  } = useHook({ organizationId, userId });

  const handleBackPress = () => {
    const paramsData: any = {};
    const params: any = route.params;
    ['agencyJobId', 'organizationId', 'parentScreenName'].forEach((key) => {
      if (params?.[key]) {
        paramsData[key] = params?.[key];
      }
    });
    if (route.params?.previousNavigator && route.params?.previousScreenName) {
      const { previousScreenName, previousNavigator, parentScreenName } = route.params;
      if (previousScreenName.includes(',') || previousScreenName.includes('%2C')) {
        let routes = [];
        if (previousScreenName.includes('%2C')) {
          routes = previousScreenName.split('%2C');
        } else {
          routes = previousScreenName.split(',');
        }
        let newPreviousScreenName = '';
        const previousScreenNames = routes.slice(0, -1);
        if (previousScreenNames.length) {
          newPreviousScreenName = previousScreenNames.join(',');
        }
        navigation.navigate(routes[routes.length - 1], {
          agencyJobId: route.params?.agencyJobId || '',
          courierJobId: route.params?.courierJobId || '',
          previousScreenName: newPreviousScreenName,
        });
      } else if (previousScreenName === 'AgencyOBCAvailabilityMapScreen') {
        navigation.navigate('AgencyProfileNavigator', {
          screen: 'AgencyProfileScreen',
          params: paramsData,
        });
        setTimeout(() => {
          navigation.navigate(previousNavigator, {
            screen: previousScreenName,
            params: paramsData,
          });
        }, 0);
      } else if (parentScreenName === 'AgencyOBCAvailabilityMapScreen') {
        navigation.navigate('AgencyProfileNavigator', {
          screen: 'AgencyProfileScreen',
          params: paramsData,
        });
        setTimeout(() => {
          navigation.navigate(previousNavigator, {
            screen: 'AgencyOBCAvailabilityMapScreen',
            params: paramsData,
          });
        }, 0);
      } else {
        navigation.navigate(previousNavigator, {
          screen: route.params?.previousScreenName,
          params: paramsData,
        });
      }
    } else {
      navigation.navigate('AgencyConnectedCourierOrganizationsScreen');
    }
  };

  const renderAccessoryRight = (): React.ReactElement => {
    if (!isSaveVisible) {
      return <></>;
    }
    return (
      <View style={styles.saveBtn}>
        <Button onPress={loadingSave ? undefined : handleSaveData} appearance="filled">
          {loadingSave ? <ActivityIndicator color="white" /> : 'Save'}
        </Button>
      </View>
    );
  };

  const renderBasicData = () => (
    <>
      <ContentBoxTextTitle
        title=""
        style={styles.contentBox}
        Content={
          <AgencyCourierUserProfileCategoryEditComponent
            handleMarkedForAttention={handleMarkedForAttention}
            handleInternalCommentChange={handleInternalCommentChange}
            handleSelectCategory={handleSelectCategory}
            internalComment={internalComment}
            markedForAttention={markedForAttention}
            selectedCategory={selectedOrganizationCourierCategory}
          />
        }
      />
      <View style={globalStyle.padding15}>
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.firstNames', { defaultValue: 'First Names' })}
          stringValue={profile?.user?.firstNames}
        />
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.lastName', { defaultValue: 'Last Name' })}
          stringValue={profile?.user?.lastName}
        />
        <FormTextEmailComponent
          style={[styles.formView, globalStyle.paddingBottom20]}
          hint={t('common.email', { defaultValue: 'Email' })}
          value={profile?.user?.email || ''}
        />
        <FormTextPhoneComponent
          style={[styles.formView, globalStyle.paddingBottom20]}
          hint={t('common.phoneNumber', { defaultValue: 'Phone Number' })}
          value={profile?.user?.phone}
        />
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.gender', { defaultValue: 'Gender' })}
          stringValue={profile?.user?.sex || ''}
        />

        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.dateOfBirth', { defaultValue: 'Date of birth' })}
          stringValue={
            profile?.user?.dateOfBirth
              ? DateTime.fromISO(profile.user.dateOfBirth.toString()).toLocaleString(
                  DateTime.DATE_SHORT,
                )
              : ''
          }
        />
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.placeOfBirth', { defaultValue: 'Place of birth' })}
          stringValue={profile?.user?.placeOfBirth || ''}
        />
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.countryOfBirth', { defaultValue: 'Country of birth' })}
          stringValue={
            CountryListData.find(
              (object) => object.code === profile?.user?.countryOfBirth?.toString(),
            )?.name || ''
          }
        />
        <FormTextEmailComponent
          style={[styles.formView]}
          hint={t('common.secondEmail', { defaultValue: 'Second email' })}
          value={profile?.user?.email2 || '-'}
        />
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.secondPhone', { defaultValue: 'Second phone' })}
          stringValue={profile?.user?.phone2 || '-'}
        />
        <FormTextTextComponent
          style={[styles.formView]}
          hint={t('common.nationalities', { defaultValue: 'Nationalities' })}
          stringValue={nationalities?.toString() || ''}
        />
      </View>
    </>
  );

  const renderDeletedAccount = () => (
    <View style={styles.alertContainer}>
      <Icon name="alert-triangle-outline" style={styles.alertIcon} fill="#ee2200" />
      <View style={globalStyle.flex1}>
        <Text selectable={true} style={styles.alertTitle} category="s1">
          {t('common.accountDeleted', { defaultValue: 'Account deleted' }) as string}
        </Text>
        <Text selectable={true} style={styles.alertText}>
          {
            t('common.accountDeletedExplanation', {
              defaultValue:
                'The user account is deleted and cannot be used anymore. We apologize for any inconvenience.',
            }) as string
          }
        </Text>
      </View>
    </View>
  );

  const renderDocuments = () => {
    if (profile?.inactivatedAt) {
      return <></>;
    }
    return (
      <Button style={globalStyle.margin20} appearance="filled" onPress={handleDocumentsPress}>
        {
          t('menuItems.courierProfileDocumentsButton', {
            defaultValue: 'Courier Documents (Passports, etc.)',
          }) as string
        }
      </Button>
    );
  };

  const renderLanguages = () => {
    if (!languagesKnown?.length) {
      return <></>;
    }
    return (
      <ContentBoxTextTitle
        title={t('common.languagesKnown', { defaultValue: 'Languages Known' })}
        style={styles.contentBox}
        Content={
          <>
            {languagesKnown?.map((language) => (
              <View
                key={language}
                style={[
                  globalStyle.flexRow,
                  globalStyle.alignItemsCenter,
                  styles.profileDescription,
                ]}
              >
                <View style={styles.listCircle} />
                <Text selectable={true}>{language}</Text>
              </View>
            ))}
          </>
        }
      />
    );
  };

  const renderBaseAirports = () => {
    if (!profile?.baseAirports?.length) {
      return <></>;
    }
    return (
      <ContentBoxTextTitle
        title={t('common.baseAirports', { defaultValue: 'Base airports' })}
        style={styles.contentBox}
        Content={
          <>
            {profile?.baseAirports?.map((airport) => (
              <View
                key={airport}
                style={[
                  globalStyle.flexRow,
                  globalStyle.alignItemsCenter,
                  styles.profileDescription,
                ]}
              >
                <View style={styles.listCircle} />
                <Text selectable={true}>{airport}</Text>
              </View>
            ))}
          </>
        }
      />
    );
  };

  const renderOBCExperience = () => {
    if (!profile?.hasObcExperience && !profile?.firstObcJobDateAt) {
      return <></>;
    }
    return (
      <ContentBoxTextTitle
        title={t('courierUserprofile?.obcExperience', { defaultValue: 'OBC Experience' })}
        style={styles.contentBox}
        Content={
          <>
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {
                  t('courierUserprofile?.firstObcJobDateAt', {
                    defaultValue: 'First OBC Job Date',
                  }) as string
                }
              </Text>
              {!!profile?.firstObcJobDateAt && (
                <Text>: {profile?.firstObcJobDateAt.toFormat('dd MMM yyyy')}</Text>
              )}
            </View>
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {t('courierUserprofile?.obcJobsDone', { defaultValue: 'OBC Jobs done' }) as string}
              </Text>
              {!!profile?.numberOfPastObcJobs && <Text>: {profile?.numberOfPastObcJobs}</Text>}
            </View>
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {
                  t('courierUserprofile?.medicalObcExperience', {
                    defaultValue: 'Medical/stem cells OBC Experience',
                  }) as string
                }
              </Text>
              <Text>
                :{' '}
                {profile?.hasObcExperience
                  ? (t('common.yes', { defaultValue: 'Yes' }) as string)
                  : (t('common.no', { defaultValue: 'No' }) as string)}
              </Text>
            </View>
          </>
        }
      />
    );
  };

  const renderResidenceAddress = () => {
    if (!profile?.user?.userRegisteredAddressLocation) {
      return <></>;
    }
    const { userRegisteredAddressLocation } = profile.user;
    let city = '';
    let postalCode = '';
    let street = '';
    let country = userRegisteredAddressLocation.country || '-';
    if (userRegisteredAddressLocation.country) {
      const found = CountryListData.find(
        (item) => item.code === userRegisteredAddressLocation.country,
      );
      if (found) {
        country = found.name;
      }
    }
    if (country === 'USA' || country === 'United States') {
      if (userRegisteredAddressLocation.city) {
        if (userRegisteredAddressLocation.postalCode) {
          city += `${userRegisteredAddressLocation.postalCode} ${userRegisteredAddressLocation.city}`;
        } else {
          city = userRegisteredAddressLocation.city;
        }
      }
      if (userRegisteredAddressLocation.streetName) {
        if (userRegisteredAddressLocation.houseNumber) {
          street += `${userRegisteredAddressLocation.houseNumber} ${userRegisteredAddressLocation.streetName}`;
        } else {
          street = userRegisteredAddressLocation.streetName;
        }
      }
    } else {
      if (userRegisteredAddressLocation.city) {
        city = userRegisteredAddressLocation.city;
        if (userRegisteredAddressLocation.postalCode) {
          city += ` ${userRegisteredAddressLocation.postalCode}`;
        }
      }
      if (userRegisteredAddressLocation.streetName) {
        street = userRegisteredAddressLocation.streetName;
        if (userRegisteredAddressLocation.houseNumber) {
          street += ` ${userRegisteredAddressLocation.houseNumber}`;
        }
      }
    }
    if (userRegisteredAddressLocation.postalCode) {
      postalCode += userRegisteredAddressLocation.postalCode;
    }
    return (
      <ContentBoxTextTitle
        title={t('common.residenceAddress', { defaultValue: 'Residence Address' })}
        style={styles.contentBox}
        Content={
          <>
            {!!userRegisteredAddressLocation.formattedAddress && (
              <Text
                selectable={true}
                style={[styles.profileDescription, globalStyle.paddingBottom10]}
              >
                {userRegisteredAddressLocation.formattedAddress}
              </Text>
            )}

            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text>{t('location.street', { defaultValue: 'Street' }) as string}: </Text>
              <Text>{street || '-'}</Text>
            </View>
            {!!city && (
              <View style={[globalStyle.flexRow, styles.profileDescription]}>
                <Text>{t('location.city', { defaultValue: 'City' }) as string}: </Text>
                <Text>{city}</Text>
              </View>
            )}
            {!!postalCode && !city && (
              <View style={[globalStyle.flexRow, styles.profileDescription]}>
                <Text>{t('location.postalCode', { defaultValue: 'Postal code' }) as string}: </Text>
                <Text>{postalCode}</Text>
              </View>
            )}
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text>{t('location.stateRegion', { defaultValue: 'State/Region' }) as string}: </Text>
              <Text>{userRegisteredAddressLocation.state || '-'}</Text>
            </View>
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text>{t('location.country', { defaultValue: 'Country' }) as string}: </Text>
              <Text>{country || '-'}</Text>
            </View>
          </>
        }
      />
    );
  };

  const renderBusinessInformation = () => {
    if (!organization?.headquartersAddressLocation) {
      return <></>;
    }
    const { headquartersAddressLocation } = organization;

    let city = '';
    let postalCode = '';
    let street = '';
    let country = headquartersAddressLocation?.country || '-';
    if (headquartersAddressLocation?.country) {
      const found = CountryListData.find(
        (item) => item.code === headquartersAddressLocation?.country,
      );
      if (found) {
        country = found.name;
      }
    }
    if (country === 'USA' || country === 'United States') {
      if (headquartersAddressLocation?.city) {
        if (headquartersAddressLocation?.postalCode) {
          city += `${headquartersAddressLocation?.postalCode} ${headquartersAddressLocation?.city}`;
        } else {
          city = headquartersAddressLocation?.city;
        }
      }
      if (headquartersAddressLocation?.streetName) {
        if (headquartersAddressLocation?.houseNumber) {
          street += `${headquartersAddressLocation?.houseNumber} ${headquartersAddressLocation?.streetName}`;
        } else {
          street = headquartersAddressLocation?.streetName;
        }
      }
    } else {
      if (headquartersAddressLocation?.city) {
        city = headquartersAddressLocation?.city;
        if (headquartersAddressLocation?.postalCode) {
          city += ` ${headquartersAddressLocation?.postalCode}`;
        }
      }
      if (headquartersAddressLocation?.streetName) {
        street = headquartersAddressLocation?.streetName;
        if (headquartersAddressLocation?.houseNumber) {
          street += ` ${headquartersAddressLocation?.houseNumber}`;
        }
      }
    }
    if (headquartersAddressLocation?.postalCode) {
      postalCode += headquartersAddressLocation?.postalCode;
    }
    return (
      <ContentBoxTextTitle
        title={t('common.businessInformation', { defaultValue: 'Business Information' })}
        style={styles.contentBox}
        Content={
          <>
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {t('common.companyName', { defaultValue: 'Company name' }) as string}:{' '}
              </Text>
              <Text selectable={true}>{organization.name}</Text>
            </View>
            <View
              style={[globalStyle.flexRow, styles.profileDescription, globalStyle.paddingBottom10]}
            >
              <Text style={globalStyle.fontLatoBold}>
                {t('common.taxCountry', { defaultValue: 'Tax country' }) as string}:{' '}
              </Text>
              <Text selectable={true}>{taxCountry}</Text>
            </View>

            {!!headquartersAddressLocation.formattedAddress && (
              <View style={[globalStyle.flexRow, styles.profileDescription]}>
                <Text style={globalStyle.fontLatoBold}>
                  {t('location.companyAddress', { defaultValue: 'Company address' }) as string}:{' '}
                </Text>
                <Text selectable={true}>{headquartersAddressLocation?.formattedAddress || ''}</Text>
              </View>
            )}

            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {t('location.street', { defaultValue: 'Street' }) as string}:{' '}
              </Text>
              <Text>{street || '-'}</Text>
            </View>
            {!!city && (
              <View style={[globalStyle.flexRow, styles.profileDescription]}>
                <Text style={globalStyle.fontLatoBold}>
                  {t('location.city', { defaultValue: 'City' }) as string}:{' '}
                </Text>
                <Text>{city}</Text>
              </View>
            )}
            {!!postalCode && !city && (
              <View style={[globalStyle.flexRow, styles.profileDescription]}>
                <Text style={globalStyle.fontLatoBold}>
                  {t('location.postalCode', { defaultValue: 'Postal code' }) as string}:{' '}
                </Text>
                <Text>{postalCode}</Text>
              </View>
            )}
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {t('location.stateRegion', { defaultValue: 'State/Region' }) as string}:{' '}
              </Text>
              <Text>{headquartersAddressLocation.state || '-'}</Text>
            </View>
            <View style={[globalStyle.flexRow, styles.profileDescription]}>
              <Text style={globalStyle.fontLatoBold}>
                {t('location.country', { defaultValue: 'Country' }) as string}:{' '}
              </Text>
              <Text>{country || '-'}</Text>
            </View>
          </>
        }
      />
    );
  };

  const renderEmergencyContact = () => {
    if (!emergencyContact) {
      return <></>;
    }
    return (
      <ContentBoxTextTitle
        title={t('common.emergencyContact', { defaultValue: 'Emergency Contact' })}
        style={styles.contentBox}
        Content={
          <>
            <Text selectable={true} style={styles.sectionEmergencyContactLabel} category="s1">
              {emergencyContact ||
                (t('common.noNameProvided', {
                  defaultValue: 'No name provided',
                }) as string)}
            </Text>
            <EmailTextWithContextMenu
              textStyle={styles.sectionEmergencyContactLabel}
              email={profile?.emergencyContactEmail || undefined}
            />
            <PhoneTextWithContextMenu
              textStyle={styles.sectionEmergencyContactLabel}
              phone={profile?.emergencyContactPhone || undefined}
            />
          </>
        }
      />
    );
  };

  const renderCreditCards = () => (
    <ContentBoxTextTitle
      title={t('common.CARDS', { defaultValue: 'CARDS' }) as string}
      style={styles.contentBox}
      Content={
        <>
          <Layout key="amexCardsLayout" style={styles.cardsInternalLayout}>
            <Image
              style={styles.cardImage as any}
              source={require('../../../assets/images/american-express-logo.png')}
              resizeMode="contain"
            />
            <CheckBox status="primary" checked={profile?.hasAmexCreditCard || false}>
              {t('common.creditCard', { defaultValue: 'Credit Card' }) as string}
            </CheckBox>
            <CheckBox status="primary" checked={profile?.hasAmexDebitCard || false}>
              {t('common.debitCard', { defaultValue: 'Debit Card' }) as string}
            </CheckBox>
          </Layout>
          <Text selectable={true} appearance="hint" category="s1" style={styles.formView}>
            {t('common.creditLimit', { defaultValue: 'Credit Limit: ' }) as string}
            {profile?.amexCreditCardLimit
              ? profile?.amexCreditCardLimit.toString() + ' €'
              : (t('common.notSpecified', { defaultValue: 'Not specified' }) as string)}
          </Text>
          <Divider style={globalStyle.marginVertical10} />
          <Layout key="dinerClubCardLayout" style={styles.cardsInternalLayout}>
            <Image
              style={styles.cardImage as any}
              source={require('../../../assets/images/diners-club-international-icon.png')}
              resizeMode="contain"
            />
            <CheckBox status="primary" checked={profile?.hasDinerCreditCard || false}>
              {t('common.creditCard', { defaultValue: 'Credit Card' }) as string}
            </CheckBox>
            <CheckBox status="primary" checked={profile?.hasDinerDebitCard || false}>
              {t('common.debitCard', { defaultValue: 'Debit Card' }) as string}
            </CheckBox>
          </Layout>
          <Text selectable={true} appearance="hint" category="s1" style={styles.formView}>
            {t('common.creditLimit', { defaultValue: 'Credit Limit: ' }) as string}
            {profile?.dinerCreditCardLimit
              ? profile?.dinerCreditCardLimit.toString() + ' €'
              : (t('common.notSpecified', { defaultValue: 'Not specified' }) as string)}
          </Text>
          <Divider style={globalStyle.marginVertical10} />
          <Layout key="masterCardLayout" style={styles.cardsInternalLayout}>
            <Image
              style={styles.cardImage as any}
              source={require('../../../assets/images/mastercard-logo.png')}
              resizeMode="contain"
            />
            <CheckBox status="primary" checked={profile?.hasMasterCreditCard || false}>
              {t('common.creditCard', { defaultValue: 'Credit Card' }) as string}
            </CheckBox>
            <CheckBox status="primary" checked={profile?.hasMasterDebitCard || false}>
              {t('common.debitCard', { defaultValue: 'Debit Card' }) as string}
            </CheckBox>
          </Layout>
          <Text selectable={true} appearance="hint" category="s1" style={styles.formView}>
            {t('common.creditLimit', { defaultValue: 'Credit Limit: ' }) as string}
            {profile?.masterCreditCardLimit
              ? profile?.masterCreditCardLimit.toString() + ' €'
              : (t('common.notSpecified', { defaultValue: 'Not specified' }) as string)}
          </Text>
          <Divider style={globalStyle.marginVertical10} />
          <Layout key="visaCardLayout" style={styles.cardsInternalLayout}>
            <Image
              style={styles.cardImage as any}
              source={require('../../../assets/images/visa-logo.png')}
              resizeMode="contain"
            />
            <CheckBox status="primary" checked={profile?.hasVisaCreditCard || false}>
              {t('common.creditCard', { defaultValue: 'Credit Card' }) as string}
            </CheckBox>
            <CheckBox status="primary" checked={profile?.hasVisaDebitCard || false}>
              {t('common.debitCard', { defaultValue: 'Debit Card' }) as string}
            </CheckBox>
          </Layout>
          <Text selectable={true} appearance="hint" category="s1" style={styles.formView}>
            {t('common.creditLimit', { defaultValue: 'Credit Limit: ' }) as string}
            {profile?.visaCreditCardLimit
              ? profile?.visaCreditCardLimit.toString() + ' €'
              : (t('common.notSpecified', { defaultValue: 'Not specified' }) as string)}
          </Text>
        </>
      }
    />
  );

  const renderSocialProfiles = () => {
    if (!socialProfiles.length || socialProfiles.every((item: SocialProfile) => !item.value)) {
      return <></>;
    }
    return (
      <ContentBoxTextTitle
        title={t('common.socialMedia', { defaultValue: 'Social Media' }) as string}
        style={styles.contentBox}
        Content={
          <View>
            {socialProfiles.map((item: any) => {
              if (!item.value) {
                return null;
              }
              return (
                <View key={item.platform} style={styles.socialRow}>
                  <View style={styles.socialIconContainer}>
                    <SocialIcon fill="#000000" name={item.platform as SocialKeysType} size={20} />
                  </View>
                  <CopyableText
                    copyableTextName={item.platform}
                    text={createSocialURL({
                      platform: item.platform,
                      value: item.value || '',
                    })}
                  />
                </View>
              );
            })}
          </View>
        }
      />
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common.courierProfile', {
          defaultValue: 'Courier profile',
        })}
        navigation={navigation}
        onPressLeft={handleBackPress}
        accessoryRight={renderAccessoryRight}
      />
      {!!route.params?.userId && (
        <KeyboardAwareScrollView
          contentContainerStyle={styles.contentContainer}
          showsVerticalScrollIndicator={false}
          enableAutomaticScroll
          enableOnAndroid
          extraScrollHeight={100}
        >
          {!!profile?.user?.inactivatedAt && renderDeletedAccount()}

          <ImageLightBox
            customImage={
              <Avatar
                size="large"
                style={styles.avatar as any}
                source={
                  profileImage
                    ? {
                        uri: profileImage,
                      }
                    : require('../../../assets/images/avatar_placeholder.png')
                }
              />
            }
            expandable={!!profile?.user?.profilePicture?.sasUrl}
            uri={profile?.user?.profilePicture?.sasUrl}
          />

          {renderBasicData()}
          {renderDocuments()}
          {renderBaseAirports()}
          {renderLanguages()}
          {renderOBCExperience()}
          {renderResidenceAddress()}
          {renderBusinessInformation()}
          {renderEmergencyContact()}
          {renderCreditCards()}
          {renderSocialProfiles()}
        </KeyboardAwareScrollView>
      )}
    </>
  );
};
