import { useContext, useEffect, useMemo, useState } from 'react';

import { useRoute, useNavigation } from '@react-navigation/core';
import { DateTime } from 'luxon';

import { ICourierAvailabilitiesData } from './types';
import AppUserContext from '../../../contexts/AppUserContext';
import { Availability, useAvailabilitiesForUserQuery } from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

const sortActiveAvailabilities = (a: Availability, b: Availability) => {
  if (a.isAdHocAvailability && !b.isAdHocAvailability) {
    return -1;
  } else if (!a.isAdHocAvailability && b.isAdHocAvailability) {
    return 1;
  } else if (Date.parse(a.startTime.toString()) < Date.parse(b.startTime.toString())) {
    return -1;
  }
  return 1;
};

export const useHook = () => {
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const navigation: any = useNavigation();
  const route: any = useRoute();

  const [currentTime, setCurrentTime] = useState(DateTime.now());
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, 1000 * 60);
    return () => clearInterval(interval);
  });

  const { error, data, loading, refetch } = useAvailabilitiesForUserQuery({
    variables: {
      userId: appUserContext.currentUserContext?.user?.id as string,
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  useEffect(() => {
    if (!!route.params?.availabilityCreated && !loading) {
      const params = { ...route.params };
      delete params.availabilityCreated;
      navigation.setParams({ ...params, availabilityCreated: false });
      refetch({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, route.params?.availabilityCreated]);

  const handleRefresh = async () => {
    setRefreshing(true);
    setCurrentTime(DateTime.now());
    await refetch();
    setRefreshing(false);
  };

  const {
    availabilitiesActive,
    availabilitiesUpcoming,
    availabilitiesPast,
  }: ICourierAvailabilitiesData = useMemo((): ICourierAvailabilitiesData => {
    if (data?.availabilitiesForUser?.length) {
      const availabilitiesForUser = data.availabilitiesForUser as Availability[];
      const unsortedData = availabilitiesForUser.reduce(
        (acc: ICourierAvailabilitiesData, current: Availability) => {
          if (!current.endTime || current.endTime >= currentTime) {
            if (current.isAdHocAvailability || current.startTime < currentTime) {
              acc.availabilitiesActive.push(current);
            } else {
              acc.availabilitiesUpcoming.push(current);
            }
          } else {
            acc.availabilitiesPast.push(current);
          }
          return acc;
        },
        {
          availabilitiesActive: [],
          availabilitiesUpcoming: [],
          availabilitiesPast: [],
        },
      ) as ICourierAvailabilitiesData;

      return {
        availabilitiesActive: unsortedData.availabilitiesActive.sort(sortActiveAvailabilities),
        availabilitiesUpcoming: unsortedData.availabilitiesUpcoming.sort(sortActiveAvailabilities),
        availabilitiesPast: unsortedData.availabilitiesPast.sort((a, b) => {
          if (a.isAdHocAvailability && !b.isAdHocAvailability) {
            return -1;
          } else if (!a.isAdHocAvailability && b.isAdHocAvailability) {
            return 1;
          } else if (
            Date.parse(a.endTime?.toString() as string) >
            Date.parse(b.endTime?.toString() as string)
          ) {
            return -1;
          }
          return 1;
        }),
      };
    }
    return { availabilitiesActive: [], availabilitiesUpcoming: [], availabilitiesPast: [] };
  }, [currentTime, data?.availabilitiesForUser]);

  return {
    availabilitiesActive,
    availabilitiesUpcoming,
    availabilitiesPast,
    error,
    handleRefresh,
    loading,
    refreshing,
  };
};
