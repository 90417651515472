import React from 'react';
import { StyleProp, TextStyle, View } from 'react-native';

import {
  Divider,
  Input,
  LayoutProps,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';

import { globalStyle } from '../../../common/style';
import { PhoneTextWithContextMenu } from '../common.phone-text-with-context-menu';

export interface FormTextPhoneComponentProps extends LayoutProps {
  hint: string;
  hintStyle?: StyleProp<TextStyle>;
  value?: string | null;
  writable?: boolean;
  onChange?: any;
}

export const FormTextPhoneComponent = (props: FormTextPhoneComponentProps): React.ReactElement => {
  const { style, hint, hintStyle, value } = props;
  const styles = useStyleSheet(themedStyles);

  return (
    <>
      <View style={[styles.container, style]}>
        <Text
          selectable={true}
          style={[
            globalStyle.flex1,
            globalStyle.justifyContentStart,
            globalStyle.alignItemsStart,
            styles.hint,
            hintStyle,
          ]}
        >
          {hint}
        </Text>
        {props.writable ? (
          <Input style={styles.flex3} value={value || ''} onChangeText={props.onChange} />
        ) : (
          <PhoneTextWithContextMenu
            textStyle={[
              globalStyle.flexWrap,
              globalStyle.justifyContentStart,
              globalStyle.alignItemsStart,
            ]}
            phone={value}
          />
        )}
      </View>
      <Divider />
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 20,
    minHeight: 60,
  },
  flex3: {
    flex: 3,
  },
  hint: {
    flex: 1,
    marginBottom: 4,
    color: 'text-basic-color',
    fontFamily: 'Lato_700Bold',
    alignSelf: 'flex-start',
  },
});
