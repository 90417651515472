import React, { useContext, useMemo } from 'react';
import { Platform, View } from 'react-native';

import { Foundation } from '@expo/vector-icons';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { Button, Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../../common/style';
import AppContext from '../../../../contexts/AppContext';
import {
  AgencyJob,
  AgencyJobStatusType,
  CourierJobAgencyRelationshipType,
  SubscriptionFeatureType,
  OrganizationUserRoleType,
} from '../../../../generated-graphql-types';
import { RootStackParamList } from '../../../../navigation/app.navigator';
import { SubscriptionPermissions } from '../../../common/SubscriptionPermissions';
import {
  AgencyJobStatusTypeChangeCancelled,
  AgencyJobStatusTypeChangeFinished,
  AgencyJobStatusTypeChangeNew,
  AgencyJobStatusTypeChangeStarted,
} from '../AgencyJobCardItemButtons';

export const AgencyJobCardItemMenu = ({
  agencyJob,
  onViewCouriersDetails,
  previousScreenName,
  previousTabScreenName,
  showInternalNotesModal,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  onViewCouriersDetails?: (agencyJob: AgencyJob) => void;
  previousScreenName: string;
  previousTabScreenName:
    | 'AgencyNewJobsScreen'
    | 'AgencyStartedJobsScreen'
    | 'AgencyFinishedJobsScreen'
    | 'AgencyCancelledJobsScreen';
  showInternalNotesModal?: (data: { agencyJobId: string; internalNotes: string }) => void;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppContext);
  // const navigation: NavigationProp<RootStackParamList> = useNavigation();
  const navigation: any = useNavigation();
  const route = useRoute<RouteProp<RootStackParamList, typeof previousTabScreenName>>();
  const styles = useStyleSheet(themedStyles);

  const {
    countOfCourierJobsForAgencyJob,
    countOfPendingCourierJobsForAgencyJob,
    countOfRequestedCourierJobsForAgencyJob,
    countOfAcceptedCourierJobsForAgencyJob,
    countOfDeclinedCourierJobsForAgencyJob,
  } = useMemo(() => {
    if (agencyJob?.courierJobs?.length) {
      return {
        countOfCourierJobsForAgencyJob: agencyJob?.courierJobs.length,
        ...agencyJob?.courierJobs.reduce(
          (acc, { relationshipType }) => {
            if (
              relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED ||
              relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED
            ) {
              acc.countOfPendingCourierJobsForAgencyJob =
                acc.countOfPendingCourierJobsForAgencyJob + 1;
            } else if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED) {
              acc.countOfRequestedCourierJobsForAgencyJob =
                acc.countOfRequestedCourierJobsForAgencyJob + 1;
            } else if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED) {
              acc.countOfAcceptedCourierJobsForAgencyJob =
                acc.countOfAcceptedCourierJobsForAgencyJob + 1;
            } else if (
              relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
              relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED
            ) {
              acc.countOfDeclinedCourierJobsForAgencyJob =
                acc.countOfDeclinedCourierJobsForAgencyJob + 1;
            }
            return acc;
          },
          {
            countOfPendingCourierJobsForAgencyJob: 0,
            countOfRequestedCourierJobsForAgencyJob: 0,
            countOfAcceptedCourierJobsForAgencyJob: 0,
            countOfDeclinedCourierJobsForAgencyJob: 0,
          },
        ),
      };
    }
    return {
      countOfCourierJobsForAgencyJob: 0,
      countOfPendingCourierJobsForAgencyJob: 0,
      countOfRequestedCourierJobsForAgencyJob: 0,
      countOfAcceptedCourierJobsForAgencyJob: 0,
      countOfDeclinedCourierJobsForAgencyJob: 0,
    };
  }, [agencyJob]);

  const handleViewOBCs = (tabScreenName: string) => () => {
    navigation.navigate('AgencyCourierJobsScreen', {
      agencyJobId: agencyJob.id,
      previousScreenName,
      tabScreenName,
      previousTabScreenName,
    });
  };

  const handleViewCouriersModal = () => {
    if (onViewCouriersDetails) {
      onViewCouriersDetails(agencyJob as AgencyJob);
    }
  };

  const handleJobChatAndTrackingPress = () => {
    navigation.navigate('AgencyAgencyJobChatScreen', {
      chatId: agencyJob.agencyJobChat?.id,
      agencyJobId: agencyJob.id as string,
      previousScreenName: 'AgencyJobsScreen',
      previousNavigator: 'AgencyHomeNavigator',
    });
  };

  const handleViewEditJobPress = () => {
    navigation.navigate('AgencyUpdateJobScreen', {
      agencyJobId: agencyJob.id as string,
      previousScreenName: 'AgencyJobsScreen',
      previousTabScreenName: previousTabScreenName || route.name,
    });
  };

  const handlePlanningPress = () => {
    navigation.navigate('AgencyAgencyJobPlanningScreen', {
      agencyJobId: agencyJob.id as string,
      previousScreenName: 'AgencyJobsScreen',
      previousTabScreenName: previousTabScreenName || route.name,
    });
  };

  const renderButton = () => {
    if (
      agencyJob.agencyJobStatusType === undefined ||
      agencyJob.agencyJobStatusType === AgencyJobStatusType.NEW
    ) {
      return <AgencyJobStatusTypeChangeNew agencyJob={agencyJob} updateJob={updateJob} />;
    }
    if (agencyJob.agencyJobStatusType === AgencyJobStatusType.STARTED) {
      return <AgencyJobStatusTypeChangeStarted agencyJob={agencyJob} updateJob={updateJob} />;
    }
    if (
      agencyJob.agencyJobStatusType === AgencyJobStatusType.FINISHED ||
      agencyJob.agencyJobStatusType === AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED
    ) {
      return <AgencyJobStatusTypeChangeFinished agencyJob={agencyJob} updateJob={updateJob} />;
    }
    if (agencyJob.agencyJobStatusType === AgencyJobStatusType.CANCELLED) {
      return <AgencyJobStatusTypeChangeCancelled agencyJob={agencyJob} updateJob={updateJob} />;
    }
    return <></>;
  };

  const renderButtons = () => (
    <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.justifyContentStart]}>
      <View
        style={[
          globalStyle.justifyContentStart,
          globalStyle.flexShrink1,
          globalStyle.alignItemsStart,
          // eslint-disable-next-line react-native/no-inline-styles
          { minWidth: 100 },
        ]}
      >
        {renderButton()}
      </View>
    </View>
  );

  return (
    <View>
      <View style={styles.agencyJobStatusOutsideLayer}>
        <Button
          onPress={handleViewOBCs('AgencyCourierJobsPendingScreen')}
          size="tiny"
          style={[styles.courierCountButton, globalStyle.marginRight5]}
        >
          {() => (
            <Text style={styles.agencyJobStatusCountText}>
              {t('jobs.agencyJobsPendingButton', { defaultValue: 'pending' }).toUpperCase()}
              {'  ' + countOfPendingCourierJobsForAgencyJob}
            </Text>
          )}
        </Button>

        <Button
          onPress={handleViewOBCs('AgencyCourierJobsRequestedScreen')}
          size="tiny"
          style={[styles.courierCountButton, globalStyle.marginRight5]}
        >
          {() => (
            <Text style={styles.agencyJobStatusCountText}>
              {t('jobs.agencyJobsRequestedButton', { defaultValue: 'requested' }).toUpperCase()}
              {'  ' + countOfRequestedCourierJobsForAgencyJob}
            </Text>
          )}
        </Button>
        <Button
          onPress={handleViewOBCs('AgencyCourierJobsAcceptedScreen')}
          size="tiny"
          style={[styles.courierCountButton, globalStyle.marginRight5]}
        >
          {() => (
            <Text style={styles.agencyJobStatusCountText}>
              {t('jobs.agencyJobsAcceptedButton', { defaultValue: 'accepted' }).toUpperCase()}
              {'  ' + countOfAcceptedCourierJobsForAgencyJob}
            </Text>
          )}
        </Button>
        <Button
          onPress={handleViewOBCs('AgencyCourierJobsDeclinedScreen')}
          size="tiny"
          style={[styles.courierCountButton, globalStyle.marginRight20]}
        >
          {() => (
            <Text style={styles.agencyJobStatusCountText}>
              {t('jobs.agencyJobsDeclinedButton', { defaultValue: 'declined' }).toUpperCase()}
              {'  ' + countOfDeclinedCourierJobsForAgencyJob}
            </Text>
          )}
        </Button>
        {!!countOfCourierJobsForAgencyJob && onViewCouriersDetails && (
          <Button
            onPress={handleViewCouriersModal}
            size="tiny"
            style={[styles.courierCountButton, globalStyle.marginRight20]}
          >
            {() => (
              <Text style={styles.agencyJobStatusCountText}>
                {t('jobs:agencyJobsViewAllCouriers', {
                  defaultValue: 'View all couriers',
                }).toUpperCase()}
              </Text>
            )}
          </Button>
        )}

        <Button
          appearance="filled"
          disabled={
            agencyJob.agencyJobStatusType === undefined ||
            agencyJob.agencyJobStatusType === AgencyJobStatusType.NEW
          }
          onPress={handleJobChatAndTrackingPress}
          status="success"
          size="tiny"
          style={globalStyle.marginTop5}
        >
          <Text>
            {t('jobs.jobChatAndTracking', { defaultValue: 'Job chat & tracking' }).toUpperCase()}
          </Text>
        </Button>
      </View>

      <View style={styles.agencyJobMenuItemsWrapper}>
        <View style={styles.agencyJobMenuWrapperLeft}>
          <Button
            style={[globalStyle.margin5, globalStyle.marginBottom0]}
            appearance="filled"
            size="tiny"
            accessoryLeft={<Icon name="people-outline" width={11} height={11} />}
            disabled={(agencyJob.courierJobs?.length || 0) < 1}
            onPress={() => {
              const { name } = route as any;
              if (name === 'AgencyCourierJobOverviewScreen') {
                navigation.navigate('AgencyCourierJobsScreen', {
                  previousScreenName: 'AgencyViewCourierJobScreen',
                  agencyJobId: agencyJob.id as string,
                });
              } else {
                navigation.navigate('AgencyCourierJobsScreen', {
                  previousScreenName: 'AgencyJobsScreen',
                  agencyJobId: agencyJob.id as string,
                  previousTabScreenName: route.name,
                });
              }
            }}
          >
            {
              t('jobs.manageCouriers', {
                defaultValue: 'Manage Couriers',
                count: agencyJob.courierJobs?.length || 0,
              }) as string
            }
          </Button>
          <Button
            style={[globalStyle.margin5, globalStyle.marginBottom0]}
            appearance={countOfCourierJobsForAgencyJob === 0 ? 'filled' : 'outline'}
            size="tiny"
            accessoryLeft={<Icon name="plus-outline" width={11} height={11} />}
            onPress={() => {
              const { name } = route as any;
              if (name === 'AgencyCourierJobOverviewScreen') {
                navigation.navigate('AddOrDisableCourierJobScreen', {
                  previousScreenName: 'AgencyViewCourierJobScreen',
                  agencyJobId: agencyJob.id as string,
                });
              } else {
                navigation.navigate('AddOrDisableCourierJobScreen', {
                  previousScreenName: 'AgencyJobsScreen',
                  agencyJobId: agencyJob.id as string,
                  previousTabScreenName: route.name,
                });
              }
            }}
          >
            {
              t('jobs.addOrRemoveCouriers', {
                defaultValue: 'Add/Remove Couriers',
              }) as string
            }
          </Button>
        </View>
        <View style={styles.agencyJobMenuWrapperLeft}>
          <Button
            style={[globalStyle.margin5, globalStyle.marginBottom0, globalStyle.marginRight0]}
            size="tiny"
            appearance="filled"
            onPress={handlePlanningPress}
          >
            {t('jobs.planning', { defaultValue: 'Planning' })}
          </Button>
          <Button
            style={[globalStyle.margin5, globalStyle.marginBottom0]}
            size="tiny"
            accessoryLeft={<Icon name="briefcase-outline" width={11} height={11} />}
            appearance="outline"
            onPress={handleViewEditJobPress}
          >
            {
              t('jobs.viewOrEditJob', {
                defaultValue: 'View/Edit job details',
              }) as string
            }
          </Button>
          {!!showInternalNotesModal && (
            <View style={globalStyle.marginTop5}>
              <SubscriptionPermissions
                warningContent={{
                  cancelButtonText: t('common.close', { defaultValue: 'Close' }),
                  confirmButtonText:
                    appUserContext.currentUserContext?.organizationUser?.roleType ===
                    OrganizationUserRoleType.OWNER
                      ? t('common.contactPriojet', {
                          defaultValue: 'Contact PRIOjet',
                        })
                      : '',
                  title: '',
                  text:
                    Platform.OS === 'web'
                      ? t('subscription.thisRunwayIsCloseAgencyGoldPlanContactSupportWeb', {
                          defaultValue:
                            'This runway is closed for you. This will be available if you have an Agency Gold Plan and up. Please contact PRIOjet support to upgrade.',
                        })
                      : t('subscription.thisRunwayIsCloseAgencyGoldPlanContactSupportMobile', {
                          defaultValue:
                            'This runway is closed for you. Contact PRIOjet support for more information.',
                        }),
                  onConfirmPress: () => {
                    navigation.navigate('AgencyProfileNavigator', {
                      screen: 'CommonChatSupportPipedriveScreen',
                    });
                  },
                }}
                feature={SubscriptionFeatureType.AGENCY_AGENCY_JOB_INTERNAL_NOTES}
                warningType="modal"
              >
                {({ checkPermissions, handleShowWarning }: any) => (
                  <Button
                    appearance="outline"
                    onPress={async () => {
                      const hasPermission = await checkPermissions();
                      if (!hasPermission) {
                        handleShowWarning();
                      } else if (showInternalNotesModal) {
                        showInternalNotesModal({
                          agencyJobId: agencyJob.id as string,
                          internalNotes: agencyJob.internalNotes || '',
                        });
                      }
                    }}
                    style={styles.internalNotesBtn}
                    size="tiny"
                  >
                    <Foundation name="clipboard-pencil" size={24} color="black" />
                  </Button>
                )}
              </SubscriptionPermissions>
            </View>
          )}
        </View>
      </View>
      <View style={[globalStyle.marginHorizontal10, globalStyle.marginBottom10]}>
        {renderButtons()}
      </View>
    </View>
  );
};

const themedStyles = StyleService.create({
  refreshColor: {
    color: 'text-basic-color',
  },
  cardHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    height: 30,
    alignContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: 'color-primary-500',
  },
  cardHeaderText: {
    fontFamily: 'Lato_700Bold',
    color: 'color-basic-100',
  },
  timeDistanceLayer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  incidentIconColor: {
    backgroundColor: 'color-primary-300',
  },
  incidentIcon: {
    marginLeft: 15,
  },
  card: {
    marginVertical: 10,
    padding: 10,
    paddingHorizontal: 0,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: 'color-primary-500',
    backgroundColor: 'background-basic-color-2',
  },
  cardFooter: {
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'border-basic-color-2',
    borderWidth: 0,
    borderColor: 'color-primary-500',
  },
  cardFooterText: {
    color: 'text-basic-color',
    fontSize: 12,
  },
  dateTimeWithTimeZoneWrapper: {
    paddingVertical: 2,
    paddingLeft: 10,
  },
  agencyJobStatusChangeLayer: {
    flex: 1,
    flexDirection: 'column',
  },
  agencyJobStatusChangeHintText: {
    flexDirection: 'row',
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: 'gray',
    fontSize: 12,
  },
  agencyJobStatusChangeHintLayer: {
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
    marginRight: 20,
  },
  agencyJobStatusChangeButtonLayer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  agencyJobStatusOutsideLayer: {
    flexDirection: 'row',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    marginBottom: 5,
    backgroundColor: 'background-basic-color-3',
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  agencyJobStatusLayer: {
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderBottomWidth: 0,
    borderColor: 'color-border-low-contrast',
    borderRadius: 0,
  },
  agencyJobStatusText: {
    fontSize: 10,
    color: 'text-basic-color',
  },
  agencyJobMenuItemsWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 10,
    paddingBottom: 5,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  agencyJobMenuWrapperLeft: {
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  agencyJobStatusWrapper: {
    flexDirection: 'column',
  },
  icon: {
    backgroundColor: 'color-primary-300',
  },
  locationName: {
    fontSize: 16,
  },
  menuWrapper: {
    paddingTop: 10,
    marginBottom: 0,
    backgroundColor: 'border-basic-color-2',
  },
  locationsOutsideWrapper: {},
  locationsOutsideWrapperHeader: {
    fontFamily: 'Lato_700Bold',
    marginBottom: 7,
    fontSize: 10,
    textAlign: 'center',
    backgroundColor: 'border-basic-color-2',
  },
  locationWrapper: {
    borderWidth: 0,
    paddingHorizontal: 10,
  },
  iconLocationNameWrapper: {},
  startIcon: {
    marginRight: 10,
  },
  endIcon: {
    marginRight: 15,
    paddingRight: 10,
  },
  arrowIcon: {
    marginHorizontal: 5,
  },
  locationBox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderWidth: 0,
  },
  locationBoxIconNameWrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    alignItems: 'baseline',
    borderWidth: 0,
  },
  locationBoxRight: {
    flex: 1,
  },
  locationBoxLeft: {
    flex: 1,
  },
  courierCountButton: {
    marginTop: 5,
    backgroundColor: 'background-basic-color-2',
    borderColor: 'background-basic-color-1',
  },
  agencyJobStatusCountText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 10,
    color: 'text-basic-color',
  },
  internalNotesBtn: {
    height: Platform.OS !== 'android' ? 26 : 28,
  },
});
