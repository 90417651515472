import React, { useContext, useMemo } from 'react';
import { View, ViewStyle } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Card,
  Divider,
  Icon,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import * as ExpoLocalization from 'expo-localization';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { NumericFormat } from 'react-number-format';

import { COURIER_JOBS_FOR_COURIER_ORGANIZATION_QUERY } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import {
  AgencyJobStatusType,
  CourierJob,
  CourierJobAgencyRelationshipType,
  CourierJobStatusType,
  useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
} from '../../generated-graphql-types';
import useDimensions from '../../hooks/useDimensions';
import { CopyableText } from '../common/common.copyable-text.component';
import { DateTimeWithTimeZonesPopover } from '../common/datetime-with-time-zones-popover.component';
import { MemoizedMapViewWithMarker } from '../common/map-view-with-marker.component';
import { ConfirmModalContext } from '../common/modal/ConfirmModal';

const CourierJobCardContent = ({ courierJob }: { courierJob: Partial<CourierJob> }) => {
  const [t] = useTranslation();
  // const navigation = useNavigation() as StackNavigationProp<RootStackParamList>;
  const styles = useStyleSheet(themedStyles);
  const { isLargeDevice } = useDimensions();

  const courierJobInfoViewColumnStyle: ViewStyle[] | ViewStyle = isLargeDevice
    ? [
        styles.courierJobInfoViewColumn,
        {
          width: '50%',
          flexBasis: '50%',
          maxWidth: '50%',
        },
      ]
    : [
        styles.courierJobInfoViewColumn,
        {
          width: '100%',
          flexBasis: '100%',
          maxWidth: '100%', // TODO: fix the overlapping collapsed layer.
        },
      ];

  const courierJobInfoViewStyle: ViewStyle[] | ViewStyle = isLargeDevice
    ? [styles.courierJobInfoView, { flexWrap: 'wrap', flex: 1, flexDirection: 'row' }]
    : [styles.courierJobInfoView, { flexWrap: 'wrap', flexDirection: 'row' }];

  const courierJobInfoViewColumnEvenStyle: ViewStyle[] | ViewStyle = isLargeDevice
    ? {}
    : { flexBasis: '100%' };

  const courierJobInfoViewColumnOddStyle = {};

  return (
    <View>
      <View>
        <View style={styles.contentHeader}>
          <View style={styles.flex1}>
            <CopyableText
              fontSize={18}
              text={courierJob.agencyJob?.externalJobReference as string}
              copyableTextName={
                t('jobs.externalJobReferencePlaceholder', {
                  defaultValue: 'External job reference',
                }) as string
              }
              style={styles.marginTop2}
            />
            <Text style={[styles.locationText, styles.marginTop2]}>
              {courierJob.job?.dropOffLocation.formattedShortAddress as string}
            </Text>
            <CopyableText
              fontSize={18}
              text={`${
                t('common.agency', {
                  defaultValue: 'Agency',
                }) as string
              }: ${courierJob.agencyJob?.responsibleAgencyOrganization?.shortName || '-'}`}
              copyableTextName={
                t('common.agency', {
                  defaultValue: 'Agency',
                }) as string
              }
              style={styles.marginTop2}
            />
          </View>

          <CopyableText
            fontSize={18}
            text={courierJob.job?.priojetJobIdentifier as string}
            overrideStyle={globalStyle.flexRow}
            copyableTextName={
              t('jobs.priojetJobIdentifier', {
                defaultValue: 'PRIOjet Job Identifier',
              }) as string
            }
            style={styles.marginTop2}
          />
        </View>
      </View>

      <Divider style={globalStyle.marginVertical10} />
      <MemoizedMapViewWithMarker
        height={100}
        pointerEvents="none"
        mapViewCoordinates={[
          {
            latitude: courierJob.job?.dropOffLocation.locationGeometry.location.lat as number,
            longitude: courierJob.job?.dropOffLocation.locationGeometry.location.lng as number,
          },
        ]}
        initialRegion={{
          latitude: courierJob.job?.dropOffLocation.locationGeometry.location.lat as number,
          longitude: courierJob.job?.dropOffLocation.locationGeometry.location.lng as number,
        }}
        mapViewRegion={{
          latitude: courierJob.job?.dropOffLocation.locationGeometry.location.lat as number,
          longitude: courierJob.job?.dropOffLocation.locationGeometry.location.lng as number,
        }}
      />
      <View style={courierJobInfoViewStyle}>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>{t('common.pickupLocation', { defaultValue: 'Pickup Location' }) as string}</Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            <Text>{courierJob.job?.pickupLocation.formattedShortAddress as string}</Text>
          </View>
        </View>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnEvenStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>{t('common.pickupTime', { defaultValue: 'Pickup time' }) as string}</Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            {!!courierJob.job?.pickupTime && (
              <DateTimeWithTimeZonesPopover
                dateTimeString={
                  DateTime.fromISO(courierJob.job.pickupTime.toString())
                    .setZone(courierJob.job.pickupLocation.timeZoneIdentifier as string)
                    .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                }
                date={courierJob.job.pickupTime}
                targetTimeZoneIdentifier={
                  courierJob.job.pickupLocation.timeZoneIdentifier as string
                }
                targetLocationName={
                  courierJob.job.pickupLocation.formattedShortAddress ||
                  t('common.pickupLocation', { defaultValue: 'Pickup Location' })
                }
                popoverContentTitle="Pickup time at start location"
              />
            )}
          </View>
        </View>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>
              {t('common.dropoffLocation', { defaultValue: 'Drop-off Location' }) as string}
            </Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            <Text>{courierJob.job?.dropOffLocation.formattedShortAddress as string}</Text>
          </View>
        </View>

        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>
              {
                t('common.returnTimeAtStartAirport', {
                  defaultValue: 'Return time at start airport',
                }) as string
              }
            </Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            {!!courierJob.agencyJob?.returnTimeAtStartAirport &&
              !!courierJob.agencyJob.startAirport && (
                <DateTimeWithTimeZonesPopover
                  dateTimeString={
                    DateTime.fromISO(courierJob.agencyJob.returnTimeAtStartAirport.toString())
                      .setZone(courierJob.agencyJob.startAirport.timeZoneIdentifier as string)
                      .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                  }
                  date={courierJob.agencyJob.returnTimeAtStartAirport}
                  targetTimeZoneIdentifier={
                    courierJob.agencyJob.startAirport.timeZoneIdentifier as string
                  }
                  targetLocationName={
                    courierJob.agencyJob.startAirport.formattedAirportAddress ||
                    t('common.returnTimeAtStartAirport', {
                      defaultValue: 'Return time at start airport',
                    })
                  }
                  popoverContentTitle="Return time at start airport"
                />
              )}
          </View>
        </View>
        <View
          style={[
            globalStyle.flexRow,
            globalStyle.flexShrink1,
            globalStyle.flexWrap,
            globalStyle.marginVertical5,
          ]}
        >
          <Text
            category="label"
            style={[
              globalStyle.flexRow,
              globalStyle.flexShrink1,
              globalStyle.flexWrap,
              globalStyle.opacity5,
            ]}
          >
            {
              t('jobs.courierJobRateCardDescription', {
                defaultValue:
                  'The rates below were proposed when the Agency added you, as the Courier, initially to the Job via a request. Whatever rates were agreed upon after that via Email, Phone, Chat, etc. are binding. If you change the rates afterwards by editing the job, note that the new rates will not be reflected in this job.',
              }) as string
            }
          </Text>
        </View>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>{t('common.dailyRate', { defaultValue: 'Daily Rate' }) as string}</Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            <NumericFormat
              value={courierJob.dailyRate}
              displayType="text"
              thousandSeparator={ExpoLocalization.digitGroupingSeparator}
              decimalSeparator={ExpoLocalization.decimalSeparator}
              decimalScale={2}
              fixedDecimalScale
              prefix={courierJob.agencyJob?.currency + ' '}
              renderText={(formattedValue) => <Text>{formattedValue}</Text>}
            />
          </View>
        </View>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>{t('common.kickOffFee', { defaultValue: 'Kick-off fee' }) as string}</Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            <NumericFormat
              value={courierJob.kickOffFee}
              displayType="text"
              thousandSeparator={ExpoLocalization.digitGroupingSeparator}
              decimalSeparator={ExpoLocalization.decimalSeparator}
              decimalScale={2}
              fixedDecimalScale
              prefix={courierJob.agencyJob?.currency + ' '}
              renderText={(formattedValue) => <Text>{formattedValue}</Text>}
            />
          </View>
        </View>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>{t('common.hotelBudget', { defaultValue: 'Hotel budget' }) as string}</Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            <NumericFormat
              value={courierJob.hotelBudget}
              displayType="text"
              thousandSeparator={ExpoLocalization.digitGroupingSeparator}
              decimalSeparator={ExpoLocalization.decimalSeparator}
              decimalScale={2}
              fixedDecimalScale
              prefix={courierJob.agencyJob?.currency + ' '}
              renderText={(formattedValue) => <Text>{formattedValue}</Text>}
            />
          </View>
        </View>
        <View style={[courierJobInfoViewColumnStyle, courierJobInfoViewColumnOddStyle]}>
          <View style={styles.courierJobInfoAttributeNameView}>
            <Text>{t('common.fixedRate', { defaultValue: 'Fixed Rate' }) as string}</Text>
          </View>
          <View style={styles.courierJobInfoAttributeContentView}>
            <NumericFormat
              value={courierJob.fixedRate}
              displayType="text"
              thousandSeparator={ExpoLocalization.digitGroupingSeparator}
              decimalSeparator={ExpoLocalization.decimalSeparator}
              decimalScale={2}
              fixedDecimalScale
              prefix={courierJob.agencyJob?.currency + ' '}
              renderText={(formattedValue) => <Text>{formattedValue}</Text>}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const CourierCourierJobsCardItemFooter = ({
  canViewDetails = true,
  footerProps,
  courierJob,
}: {
  canViewDetails?: boolean;
  footerProps: any;
  courierJob: Partial<CourierJob>;
}) => {
  const styles = useStyleSheet(themedStyles);
  // const navigation = useNavigation<CourierHomeScreenNavigationProp>();
  const navigation: any = useNavigation();

  const { style, textStyle } = useMemo(() => {
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED
    ) {
      return {
        style: styles.relationshipTypeConfirmed,
        textStyle: styles.relationshipTypeWhiteText,
      };
    }
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED
    ) {
      return { style: styles.relationshipTypeDeclined, textStyle: undefined };
    }
    if (
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED ||
      courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED
    ) {
      return { style: styles.relationshipTypeRequested, textStyle: undefined };
    }
    return { style: styles.relationshipTypeUndefined };
  }, [courierJob?.relationshipType, styles]);

  return (
    <View
      {...footerProps}
      style={[
        globalStyle.flexRow,
        globalStyle.alignItemsEnd,
        globalStyle.alignContentBetween,
        globalStyle.padding5,
      ]}
    >
      <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.justifyContentBetween]}>
        {courierJob?.relationshipType ? (
          <View style={[styles.relationshipType, style]}>
            <Text style={[styles.relationshipTypeText, globalStyle.fontLatoBold, textStyle]}>
              {courierJob?.relationshipType !== undefined
                ? capitalize(
                    (
                      CourierJobAgencyRelationshipType[courierJob?.relationshipType].replace(
                        /_/g,
                        ' ',
                      ) || ''
                    ).toLowerCase(),
                  )
                : 'UNKNOWN'}
              {courierJob?.agencyJob?.agencyJobStatusType !== undefined
                ? ' - ' +
                  capitalize(
                    (
                      AgencyJobStatusType[courierJob?.agencyJob?.agencyJobStatusType].replace(
                        /_/g,
                        ' ',
                      ) || ''
                    ).toLowerCase(),
                  )
                : ''}
              {courierJob?.courierJobStatusType !== undefined
                ? ' - ' +
                  capitalize(
                    (
                      CourierJobStatusType[courierJob?.courierJobStatusType].replace(/_/g, ' ') ||
                      ''
                    ).toLowerCase(),
                  )
                : ''}
            </Text>
          </View>
        ) : (
          <></>
        )}
        <View
          style={[globalStyle.flexRow, globalStyle.alignContentStart, globalStyle.alignItemsStart]}
        >
          <Button
            size="tiny"
            appearance="filled"
            style={styles.actionButton}
            onPress={() => {
              navigation.navigate('CourierCourierJobAgencyJobChatScreen', {
                chatId: courierJob.courierJobAgencyJobChat?.id,
                courierJobId: courierJob.id as string,
                previousScreenName: 'CourierCourierJobsScreen',
                previousNavigator: 'CourierHomeNavigator',
              });
            }}
          >
            Chat
          </Button>
          {!!canViewDetails && (
            <Button
              size="tiny"
              appearance="filled"
              style={styles.actionButton}
              onPress={() =>
                navigation.navigate('CourierCourierJobScreen', {
                  courierJobId: courierJob.id as string,
                  previousScreenName: 'CourierCourierJobsScreen',
                  previousNavigator: 'CourierHomeNavigator',
                })
              }
            >
              View Job
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};

export const CourierCourierJobsCardItem = ({
  canViewDetails = true,
  courierJob,
  refetch,
  tabScreenName,
  updateJob,
}: {
  canViewDetails?: boolean;
  courierJob: Partial<CourierJob>;
  refetch: () => void;
  tabScreenName?: string;
  updateJob?: (data: Partial<CourierJob>) => void;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const navigation: any = useNavigation();
  const { dimensions } = useDimensions();

  const [updateRelationshipTypeForCourierJobAsCourierOrganizationMutation] =
    useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation({
      refetchQueries: [COURIER_JOBS_FOR_COURIER_ORGANIZATION_QUERY],
      onCompleted: (res) => {
        if (updateJob) {
          updateJob({
            ...courierJob,
            relationshipType:
              res.updateRelationshipTypeForCourierJobAsCourierOrganization.relationshipType,
            courierJobStatusType:
              res.updateRelationshipTypeForCourierJobAsCourierOrganization.courierJobStatusType,
            updatedAt: res.updateRelationshipTypeForCourierJobAsCourierOrganization.updatedAt,
          });
        }
      },
    });

  const updateRelationshipTypeForCourierJobAsCourierOrganization =
    (relationshipType: CourierJobAgencyRelationshipType) => () => {
      let confirmButtonStatus = 'primary';
      let confirmButtonText = '';
      let title = '';
      let text = '';
      let description = '';
      if (relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED) {
        confirmButtonText = t('common.accept', { defaultValue: 'Accept' });
        title = t('jobs.acceptJob', { defaultValue: 'Accept job' });
        text = t('jobs.confirmAcceptJob', {
          defaultValue:
            'Are you sure you want to accept {{externalJobReference}} ({{priojetJobIdentifier}})?',
          priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
          externalJobReference: courierJob.agencyJob?.externalJobReference,
        });
        description = t('jobs.confirmAcceptJobSuccessMessage', {
          defaultValue: '{{externalJobReference}} ({{priojetJobIdentifier}}) accepted.',
          priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
          externalJobReference: courierJob.agencyJob?.externalJobReference,
        });
      } else if (relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED) {
        confirmButtonStatus = 'danger';
        confirmButtonText = t('common.decline', { defaultValue: 'Decline' });
        title = t('jobs.declineJob', { defaultValue: 'Decline job' });
        text = t('jobs.confirmDeclineJob', {
          defaultValue:
            'Are you sure you want to decline {{externalJobReference}} ({{priojetJobIdentifier}})?',
          priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
          externalJobReference: courierJob.agencyJob?.externalJobReference,
        });
        description = t('jobs.declineJobSuccessMessage', {
          defaultValue: '{{externalJobReference}} ({{priojetJobIdentifier}}) declined.',
          priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
          externalJobReference: courierJob.agencyJob?.externalJobReference,
        });
      } else if (relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED) {
        confirmButtonText = t('common.request', { defaultValue: 'Request' });
        title = t('jobs.requestJob', { defaultValue: 'Request job' });
        text = t('jobs.confirmRequestJob', {
          defaultValue:
            'Are you sure you want to request again {{externalJobReference}} ({{priojetJobIdentifier}})?',
          priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
          externalJobReference: courierJob.agencyJob?.externalJobReference,
        });
        description = t('jobs.confirmAcceptJobSuccessMessage', {
          defaultValue: '{{externalJobReference}} ({{priojetJobIdentifier}}) accepted.',
          priojetJobIdentifier: courierJob.job?.priojetJobIdentifier,
          externalJobReference: courierJob.agencyJob?.externalJobReference,
        });
      }
      showConfirmModal({
        confirmButtonStatus,
        confirmButtonAppearance: 'filled',
        confirmButtonText,
        cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
        title,
        text,

        onConfirmPress: async () => {
          await updateRelationshipTypeForCourierJobAsCourierOrganizationMutation({
            variables: {
              id: courierJob?.id as string,
              relationshipType,
            },
          }).then(() => {
            refetch();
            showMessage({
              message: t('common.jobUpdated', { defaultValue: 'Job updated' }),
              description,
              renderFlashMessageIcon: () => <Icon name="check-outline" />,
              type: 'success',
            });
          });
          return true;
        },
        visible: true,
      });
    };

  const handleJobChatAndTrackingPress = () => {
    navigation.navigate('CourierAgencyJobChatScreen', {
      chatId: courierJob.agencyJob?.agencyJobChat?.id,
      agencyJobId: courierJob.agencyJob?.id as string,
      previousScreenName: 'CourierCourierJobsScreen',
      previousNavigator: 'CourierHomeNavigator',
      previousTabScreenName: tabScreenName,
    });
  };

  const renderActionButton = () => {
    if (courierJob?.agencyJob?.agencyJobStatusType === AgencyJobStatusType.CANCELLED) {
      return null;
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED) {
      return (
        <View
          style={[
            globalStyle.flexGrow1,
            globalStyle.justifyContentEnd,
            dimensions.window.width > 370
              ? globalStyle.alignItemsCenter
              : globalStyle.alignItemsEnd,
            dimensions.window.width > 370 ? globalStyle.flexRow : globalStyle.flexColumn,
          ]}
        >
          <Text style={[styles.relationshipTypeInfoText, globalStyle.marginBottom5]}>
            Confirmed by you. Waiting for Agency.
          </Text>
          <Button
            size="tiny"
            appearance="outline"
            status="danger"
            onPress={updateRelationshipTypeForCourierJobAsCourierOrganization(
              CourierJobAgencyRelationshipType.COURIER_DECLINED,
            )}
            style={globalStyle.marginVertical5}
          >
            Withdraw & decline
          </Button>
        </View>
      );
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED) {
      if (
        courierJob?.agencyJob?.agencyJobStatusType === AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED
      ) {
        return null;
      }
      return (
        <View
          style={[
            globalStyle.flexGrow1,
            globalStyle.justifyContentEnd,
            dimensions.window.width > 370
              ? globalStyle.alignItemsCenter
              : globalStyle.alignItemsEnd,
            dimensions.window.width > 370 ? globalStyle.flexRow : globalStyle.flexColumn,
          ]}
        >
          <Text style={[styles.relationshipTypeInfoText, globalStyle.marginBottom5]}>
            Confirmed by Agency. Job can start.
          </Text>
          <Button
            size="tiny"
            appearance="outline"
            status="danger"
            onPress={updateRelationshipTypeForCourierJobAsCourierOrganization(
              CourierJobAgencyRelationshipType.COURIER_DECLINED,
            )}
            style={globalStyle.marginVertical5}
          >
            Cancel, withdraw & decline
          </Button>
        </View>
      );
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED) {
      return (
        <Text style={styles.relationshipTypeInfoText}>
          The Agency declined your Job offer. Good luck with the next Job.
        </Text>
      );
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED) {
      return (
        <View
          style={[
            globalStyle.flexGrow1,
            globalStyle.justifyContentEnd,
            globalStyle.alignItemsCenter,
            globalStyle.flexRow,
          ]}
        >
          <Text style={[styles.relationshipTypeInfoText, globalStyle.marginBottom5]}>
            Job cancelled by you. Try requesting again.
          </Text>
          <Button
            size="tiny"
            appearance="outline"
            status="success"
            onPress={updateRelationshipTypeForCourierJobAsCourierOrganization(
              CourierJobAgencyRelationshipType.COURIER_REQUESTED,
            )}
            style={globalStyle.marginVertical5}
          >
            Request again
          </Button>
        </View>
      );
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED) {
      return (
        <View
          style={[
            globalStyle.flexGrow1,
            globalStyle.justifyContentEnd,
            dimensions.window.width > 370
              ? globalStyle.alignItemsCenter
              : globalStyle.alignItemsEnd,
            dimensions.window.width > 370 ? globalStyle.flexRow : globalStyle.flexColumn,
          ]}
        >
          <Text style={[styles.relationshipTypeInfoText, globalStyle.marginBottom5]}>
            Waiting for your response.
          </Text>
          <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
            <Button
              size="tiny"
              appearance="outline"
              status="success"
              onPress={updateRelationshipTypeForCourierJobAsCourierOrganization(
                CourierJobAgencyRelationshipType.COURIER_ACCEPTED,
              )}
            >
              Accept
            </Button>
            <Button
              style={[styles.actionButton, globalStyle.marginVertical5]}
              size="tiny"
              appearance="outline"
              status="danger"
              onPress={updateRelationshipTypeForCourierJobAsCourierOrganization(
                CourierJobAgencyRelationshipType.COURIER_DECLINED,
              )}
            >
              Decline
            </Button>
          </View>
        </View>
      );
    }
    if (courierJob?.relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED) {
      if (
        courierJob?.agencyJob?.agencyJobStatusType === AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED
      ) {
        return null;
      }
      return (
        <View
          style={[
            globalStyle.flexGrow1,
            globalStyle.justifyContentEnd,
            globalStyle.alignItemsCenter,
            globalStyle.flexRow,
          ]}
        >
          <Text style={[styles.relationshipTypeInfoText, globalStyle.marginBottom5]}>
            Waiting for Agency.
          </Text>
          <Button
            size="tiny"
            appearance="outline"
            status="danger"
            onPress={updateRelationshipTypeForCourierJobAsCourierOrganization(
              CourierJobAgencyRelationshipType.COURIER_DECLINED,
            )}
            style={globalStyle.marginVertical5}
          >
            Withdraw & Decline
          </Button>
        </View>
      );
    }
    return <></>;
  };

  const renderFooter = (footerProps: any) => (
    <CourierCourierJobsCardItemFooter
      {...footerProps}
      canViewDetails={canViewDetails}
      courierJob={courierJob}
    />
  );

  const buttonDisabled =
    courierJob?.relationshipType !== 'AGENCY_ACCEPTED' ||
    courierJob.agencyJob?.agencyJobStatusType === 'NEW';
  return (
    <Card key={courierJob?.id} style={styles.card} footer={renderFooter}>
      <CourierJobCardContent courierJob={courierJob} />
      <View style={styles.actionButtonsContainer}>
        <Button
          appearance="filled"
          disabled={buttonDisabled}
          onPress={handleJobChatAndTrackingPress}
          status="success"
          size="tiny"
          style={[globalStyle.marginLeft5, globalStyle.marginVertical5]}
        >
          <Text>
            {t('jobs.jobChatAndTracking', { defaultValue: 'Job chat & tracking' }).toUpperCase()}
          </Text>
        </Button>

        {renderActionButton()}

        {/*
          // TODO: Activate once this feature is fully developed.
          <View style={styles.actionButtonsWrapperBottom}>
          <CourierJobStatusTypeChange courierJob={courierJob} />
        </View> */}
      </View>
    </Card>
  );
};

const themedStyles = StyleService.create({
  contentHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  relationshipType: {
    flex: 1,
    borderRadius: 3,
    alignContent: 'center',
    alignItems: 'center',
    padding: 3,
    maxWidth: 240,
    backgroundColor: 'gray',
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  relationshipTypeText: {
    fontSize: 10,
    color: 'black',
    textTransform: 'uppercase',
  },
  relationshipTypeWhiteText: {
    color: 'white',
  },
  relationshipTypeConfirmed: {
    backgroundColor: 'color-success-500',
  },
  relationshipTypeRequested: {
    backgroundColor: 'color-warning-500',
  },
  relationshipTypeDeclined: {
    backgroundColor: 'color-danger-500',
  },
  relationshipTypeUndefined: {
    backgroundColor: 'text-disabled-color',
  },
  relationshipTypeInfoText: {
    paddingHorizontal: 10,
    paddingTop: 6,
    fontSize: 12,
    color: 'color-basic-500',
  },
  flex1: {
    flex: 1,
  },
  card: {
    backgroundColor: 'background-basic-color-2',
    padding: 10,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 5,
  },
  actionButtonsContainer: {
    flexGrow: 1,
    flexWrap: 'wrap-reverse',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  },
  actionButtonsWrapperBottom: {
    marginTop: 10,
  },
  actionButton: {
    marginLeft: 10,
  },
  courierJobInfoView: {
    marginTop: 5,
    marginBottom: 5,
  },
  courierJobInfoViewColumn: {
    flex: 3,
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 2,
  },
  courierJobInfoAttributeContentView: {
    flexGrow: 1,
    flex: 2,
    minWidth: 0,
  },
  courierJobInfoAttributeNameView: {
    flex: 1,
    flexShrink: 1,
    minWidth: 0,
  },
  locationText: {
    flex: 1,
    fontSize: 18,
    paddingRight: 10,
  },
  marginTop2: {
    marginTop: 2,
  },
});
