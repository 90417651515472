import React, { useEffect, useState } from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';

import { RouteProp, useRoute } from '@react-navigation/core';
import { StyleService } from '@ui-kitten/components';
import { Button } from '@ui-kitten/components/ui';
import Constants from 'expo-constants';
import * as WebBrowser from 'expo-web-browser';

import { LoadingSpinner } from './loading-spinner.component';
import { RootStackParamList } from '../../navigation/app.navigator';
import { AuthenticationService } from '../../services/authentication.service';
import { LocalUserContexts } from '../../types';
import { AvailableUserContexts } from '../available-user-contexts.component';
import { SigninBox } from '../sign-in-box.component';
import { SignupBox } from '../sign-up-box.component';

export const SwitchAccount = ({ navigation }: { navigation: any }) => {
  const route: RouteProp<RootStackParamList> = useRoute();

  const [isLoadingMessage, setIsLoadingMessage] = useState<string | undefined>('Loading...');
  const [localUserContexts, setLocalUserContextsState] = useState<LocalUserContexts>({});

  // Local setter function.
  const setLocalUserContexts = async (newLocalUserContexts: LocalUserContexts): Promise<void> => {
    setLocalUserContextsState(newLocalUserContexts);
    const authenticationService = new AuthenticationService();
    await authenticationService
      .storeLocalUserContextsAsync({ localUserContexts: newLocalUserContexts })
      .then(() => {
        setIsLoadingMessage(undefined);
      });
  };

  useEffect(() => {
    setIsLoadingMessage(undefined);
  }, [localUserContexts]);

  useEffect(() => {
    loadUserContexts();
    return () => {
      setIsLoadingMessage(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (route.params?.reloadContext && !isLoadingMessage) {
      navigation.setParams({ reloadContext: false });
      loadUserContexts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMessage, route.params?.reloadContext]);

  const loadUserContexts = async () => {
    setIsLoadingMessage('Loading Accounts...');
    getUpdatedLocalUserContextsFromStorage();
  };

  const getUpdatedLocalUserContextsFromStorage = async (forceUpdate = false) => {
    const authenticationService = new AuthenticationService();
    let newLocalUserContexts = await authenticationService.loadLocalUserContextsAsync();
    setLocalUserContexts(newLocalUserContexts);
    setIsLoadingMessage(undefined);

    newLocalUserContexts = await authenticationService.getUpdatedLocalUserContextsAsync({
      localUserContexts: newLocalUserContexts,
      forceUpdate,
    });
    setLocalUserContexts(newLocalUserContexts);
  };

  if (isLoadingMessage) {
    return (
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <LoadingSpinner text={isLoadingMessage} />
      </ScrollView>
    );
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      refreshControl={
        <RefreshControl refreshing={isLoadingMessage !== undefined} onRefresh={loadUserContexts} />
      }
    >
      <View style={themedStyles.container}>
        <SignupBox
          userContexts={localUserContexts}
          setUserContexts={setLocalUserContexts}
          navigation={navigation}
          isLoadingMessage={isLoadingMessage}
          setIsLoadingMessage={setIsLoadingMessage}
        />
        <SigninBox
          userContexts={localUserContexts}
          setUserContexts={setLocalUserContexts}
          navigation={navigation}
          isLoadingMessage={isLoadingMessage}
          setIsLoadingMessage={setIsLoadingMessage}
        />
        <AvailableUserContexts
          userContexts={localUserContexts}
          setUserContexts={setLocalUserContexts}
          navigation={navigation}
          isLoadingMessage={isLoadingMessage}
          setIsLoadingMessage={setIsLoadingMessage}
        />
        <Button
          appearance="ghost"
          onPress={() => {
            navigation.navigate('CommonProfileNavigator', {
              screen: 'CommonAppInformationScreen',
            });
          }}
        >
          App Information
        </Button>
        <Button
          appearance="ghost"
          onPress={() => {
            WebBrowser.openBrowserAsync(Constants.expoConfig?.extra?.priojetGeneralFaqUrl);
          }}
        >
          FAQ
        </Button>
      </View>
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
});
