import React, { useContext } from 'react';

import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { Menu, MenuItem, Text } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { CommonMenuItems } from '../../components/common/menu/common.menu-items.component';
import { MenuCategory } from '../../components/common/menu/menu-category.component';
import { ForwardIcon } from '../../components/icons.component';
import { TopNavigationWithoutAccessories } from '../../components/top-navigation-without-accessories.component';
import AppUserContext from '../../contexts/AppUserContext';
import { OrganizationUserRoleType } from '../../generated-graphql-types';

export const AgencyProfileScreen = ({ navigation }: { navigation: any }): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const styles = useStyleSheet(themedStyles);

  const handleNavigatePress =
    (screen: string, params: { [key: string]: string | undefined }) => () => {
      navigation.navigate('AgencyProfileNavigator', {
        screen,
        params,
      });
    };

  return (
    <>
      <TopNavigationWithoutAccessories
        title={t('menuItems.AgencyProfile', { defaultValue: 'Agency Profile' }) as string}
      />
      <Menu>
        <MenuCategory
          title={t('menuItems.userManagement', { defaultValue: 'User Management' }) as string}
        />
        <MenuItem
          title={
            t('menuItems.myProfile', {
              defaultValue: 'My Profile',
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={handleNavigatePress('AgencyAgencyUserMyProfileScreen', {
            organizationUserId: appUserContext.currentUserContext?.organizationUser?.id || '',
            previousScreenName: 'AgencyProfileScreen',
            previousNavigator: 'AgencyProfileNavigator',
          })}
        />

        <MenuItem
          title={
            t('menuItems.manageAgencyUsers', {
              defaultValue: 'Manage Agency Users',
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={handleNavigatePress('AgencyManageAgencyUsersScreen', {
            previousScreenName: 'AgencyProfileScreen',
            previousNavigator: 'AgencyProfileNavigator',
          })}
        />
        <MenuItem
          title={t('menuItems.agencyProfile', { defaultValue: 'Agency Profile' }) as string}
          accessoryRight={ForwardIcon}
          onPress={() => {
            if (
              appUserContext.currentUserContext?.organizationUser?.roleType &&
              [OrganizationUserRoleType.OWNER, OrganizationUserRoleType.ADMIN].includes(
                appUserContext.currentUserContext?.organizationUser?.roleType,
              )
            ) {
              handleNavigatePress('AgencyAgencyProfileScreen', {
                previousScreenName: 'AgencyProfileScreen',
                previousNavigator: 'AgencyProfileNavigator',
              })();
            } else {
              handleNavigatePress('AgencyAgencyProfileReadOnlyScreen', {
                agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
                previousScreenName: 'AgencyProfileScreen',
                previousNavigator: 'AgencyProfileNavigator',
              })();
            }
          }}
        />
        <MenuCategory
          title={t('menuItems.courierManagement', { defaultValue: 'Courier Management' }) as string}
        />
        <MenuItem
          title={
            t('menuItems.courierInvitations', { defaultValue: 'Courier Invitations' }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={handleNavigatePress('AgencyCourierOrganizationInvitationsScreen', {
            previousScreenName: 'AgencyProfileScreen',
            previousNavigator: 'AgencyProfileNavigator',
          })}
        />
        <MenuItem
          title={
            t('menuItems.connectedCouriers', {
              defaultValue: 'Connected Couriers',
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={handleNavigatePress('AgencyConnectedCourierOrganizationsScreen', {
            previousScreenName: 'AgencyProfileScreen',
            previousNavigator: 'AgencyProfileNavigator',
          })}
        />
        {!!appUserContext.currentUserContext?.organizationUser?.roleType &&
        [OrganizationUserRoleType.OWNER, OrganizationUserRoleType.ADMIN].includes(
          appUserContext.currentUserContext?.organizationUser?.roleType,
        ) ? (
          <MenuItem
            title={
              t('menuItems.manageCourierCategories', {
                defaultValue: 'Manage Courier Categories',
              }) as string
            }
            accessoryRight={ForwardIcon}
            onPress={handleNavigatePress('AgencyManageCourierCategoriesScreen', {
              previousScreenName: 'AgencyProfileScreen',
              previousNavigator: 'AgencyProfileNavigator',
            })}
          />
        ) : (
          <></>
        )}

        <MenuCategory
          title={t('menuItems.Notifications', { defaultValue: 'Notifications' }) as string}
        />

        <MenuItem
          title={(evaProps) => (
            <Text {...evaProps} style={[evaProps?.style, styles.otherMenuItems]}>
              {t('menuItems.Notifications') as string}
            </Text>
          )}
          onPress={() => {
            navigation.navigate('AgencyProfileNavigator', {
              screen: 'CommonNotificationPermissionsScreen',
            });
          }}
        />

        <CommonMenuItems />
      </Menu>
    </>
  );
};

const themedStyles = StyleService.create({
  bottomInfoLayerText: {
    textAlign: 'center',
    color: 'color-basic-transparent-600',
  },
  bottomInfoLayer: {
    paddingVertical: 50,
    alignItems: 'center',
    alignContent: 'center',
    flex: 1,
    backgroundColor: 'transparent',
  },
  menu: {
    flex: 1,
    margin: 0,
    backgroundColor: 'transparent',
  },
  otherMenuItems: {
    color: 'color-primary-default',
    textAlign: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
});
