import React from 'react';
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  RefreshControl,
  StatusBar,
  View,
} from 'react-native';

import { Avatar, Button, Icon, RadioGroup, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { ThemedTextInput } from '../../../components/common/common.themed-text-input.component';
import { FormTextInputTopLabel } from '../../../components/common/form/form-text-input-top-label.component';
import {
  LocationAutocomplete,
  LOCATION_AUTOCOMPLETE_INITIAL_FIELDS,
} from '../../../components/common/location-autocomplete.component';
import ContentBoxTextTitle from '../../../components/content-box-text-title.component';
import { DeleteIcon, EditIcon } from '../../../components/icons.component';
import { SocialIcon, SocialKeysType } from '../../../components/social-icons.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { copySocialToClipboard, SOCIAL_OPTIONS } from '../../../utils/social-media.util';

export const AgencyAgencyProfileScreen = ({ navigation }: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    agencyOrganizationProfileData,
    agencyOrganizationProfileQueryRefetch,
    fieldProps,
    formik,
    handleAvatarButtonVisibility,
    handleDeleteOrganizationLogo,
    handleSave,
    headquartersAddressLocationRef,
    invoiceAddressLocationRef,
    loading,
    loadingDeletePicture,
    organizationLogo,
    pickImageMobile,
    profilePicture,
    pickImageWeb,
    radioGroupVisible,
    refreshing,
    setRefreshing,
  } = useHook();

  const renderHeader = () => {
    const { legalName } = agencyOrganizationProfileData?.organization || {};
    return (
      <>
        <Avatar
          size="large"
          style={themedStyles.avatar}
          resizeMode="contain"
          source={
            profilePicture ? profilePicture : require('../../../assets/images/logo_placeholder.png')
          }
        />

        <View style={styles.editAvatarRow}>
          {Platform.OS === 'web' && (
            <Button
              onPress={pickImageWeb}
              style={styles.editAvatarButton}
              appearance="outline"
              accessoryLeft={EditIcon}
            />
          )}
          {Platform.OS !== 'web' && (
            <Button
              onPress={handleAvatarButtonVisibility}
              style={styles.editAvatarButton}
              appearance="outline"
              accessoryLeft={EditIcon}
            />
          )}
          {organizationLogo && !!organizationLogo.sasUrl && (
            <Button
              onPress={handleDeleteOrganizationLogo}
              style={styles.editAvatarButton}
              appearance="outline"
              accessoryLeft={loadingDeletePicture ? <ActivityIndicator /> : DeleteIcon}
            />
          )}
        </View>

        {radioGroupVisible && (
          <RadioGroup style={styles.editAvatarRow}>
            <Button style={styles.chooseImageBtn} onPress={pickImageWeb}>
              {
                t('common.chooseFromGallery', {
                  defaultValue: 'Choose from Gallery',
                }) as string
              }
            </Button>
            <Button style={styles.chooseImageBtn} onPress={pickImageMobile}>
              {
                t('common.takePicture', {
                  defaultValue: 'Take a Picture',
                }) as string
              }
            </Button>
          </RadioGroup>
        )}
        <View style={styles.nameContainer}>
          <View>
            <Text selectable={true} style={styles.nameText}>
              {legalName}
            </Text>
          </View>
        </View>
      </>
    );
  };

  const renderRefreshControl = () => (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={async () => {
        setRefreshing(true);
        await agencyOrganizationProfileQueryRefetch();
        setRefreshing(false);
      }}
    />
  );

  const renderGeneralInfoSection = () => (
    <>
      <FormTextInputTopLabel
        label={
          t('agencyProfile.agencyName', {
            defaultValue: 'Agency name',
          }) as string
        }
        placeholder={
          t('agencyProfile.agencyNamePlaceholder', {
            defaultValue: 'The Name of the Agency',
          }) as string
        }
        isMandatory
        {...fieldProps('name')}
      />
      <FormTextInputTopLabel
        caption={
          t('courierUserProfile.max9Characters', {
            defaultValue: 'Maximum 9 characters',
          }) as string
        }
        label={
          t('agencyProfile.agencyShortName', {
            defaultValue: 'Agency short name',
          }) as string
        }
        placeholder={
          t('agencyProfile.agencyShortNamePlaceholder', {
            defaultValue: 'Enter the short name of the agency',
          }) as string
        }
        isMandatory
        maxLength={9}
        {...fieldProps('shortName')}
      />
      <FormTextInputTopLabel
        label={
          t('agencyProfile.primaryEmailAddress', {
            defaultValue: 'Primary agency email address',
          }) as string
        }
        placeholder={
          t('agencyProfile.primaryEmailPlaceholder', {
            defaultValue: 'Enter primary email address for the agency',
          }) as string
        }
        autoCapitalize="none"
        isMandatory
        {...fieldProps('email')}
      />
      <FormTextInputTopLabel
        label={
          t('agencyProfile.primaryPhoneNumber', {
            defaultValue: 'Primary agency phone number',
          }) as string
        }
        placeholder={
          t('agencyProfile.primaryPhonePlaceholder', {
            defaultValue: 'Enter primary phone number for the agency',
          }) as string
        }
        isMandatory
        {...fieldProps('phone')}
      />
    </>
  );

  const renderLegalInformationSection = () => (
    <>
      <FormTextInputTopLabel
        label={
          t('agencyProfile.agencyLegalName', {
            defaultValue: 'Agency legal name',
          }) as string
        }
        placeholder={
          t('agencyProfile.agencyLegalNamePlaceholder', {
            defaultValue: 'Enter the legal name of the agency',
          }) as string
        }
        isMandatory
        {...fieldProps('legalName')}
      />
      <View style={styles.agencyAddressContainer}>
        <LocationAutocomplete
          ref={headquartersAddressLocationRef}
          label={
            t('agencyProfile.headquartersAgencyAddress', {
              defaultValue: 'Address of the agency headquarters',
            }) as string
          }
          fields={[
            {
              field: 'addressName',
              label: t('agencyProfile.addressName', {
                defaultValue: 'Address name',
              }) as string,
              type: 'text',
            },
            ...LOCATION_AUTOCOMPLETE_INITIAL_FIELDS,
          ]}
          language="en"
          placeholderText={
            t('agencyProfile.headquartersAddressPlaceholder', {
              defaultValue: "Search for the Agency's headquarter address here",
            }) as string
          }
          currentLocationText={
            t('agencyProfile.headquartersAddress', {
              defaultValue: 'Headquarters Address',
            }) as string
          }
          initialValues={{
            addressName: agencyOrganizationProfileData?.organization.legalName || '',
            ...((agencyOrganizationProfileData?.organization?.headquartersAddressLocation ||
              {}) as any),
          }}
        />
      </View>
      <FormTextInputTopLabel
        label={
          t('agencyProfile.companyRegistrationNumber', {
            defaultValue: 'Company registration number',
          }) as string
        }
        placeholder={
          t('agencyProfile.companyRegistrationNumberPlaceholder', {
            defaultValue: 'Enter company registration number if available',
          }) as string
        }
        {...fieldProps('registrationNumber')}
      />
      <FormTextInputTopLabel
        label={
          t('agencyProfile.vatId', {
            defaultValue: 'Tax registration number (VAT ID)',
          }) as string
        }
        placeholder={
          t('agencyProfile.vatIdPlaceholder', {
            defaultValue: 'Enter tax registration number (VAT ID) if available',
          }) as string
        }
        {...fieldProps('vatId')}
      />
    </>
  );

  const renderInvoiceSection = () => (
    <>
      <View style={styles.agencyAddressContainer}>
        <LocationAutocomplete
          ref={invoiceAddressLocationRef}
          label={
            t('agencyProfile.officialPostalInvoiceAddress', {
              defaultValue: 'Official postal invoice address',
            }) as string
          }
          fields={[
            {
              field: 'addressName',
              label: t('agencyProfile.addressName', {
                defaultValue: 'Address name',
              }) as string,
              type: 'text',
            },
            ...LOCATION_AUTOCOMPLETE_INITIAL_FIELDS,
          ]}
          language="en"
          placeholderText={
            t('agencyProfile.officialPostalInvoiceAddressPlaceholder', {
              defaultValue: 'Search official postal invoice address here',
            }) as string
          }
          currentLocationText={
            t('agencyProfile.officialPostalInvoiceAddress', {
              defaultValue: 'Official postal invoice address',
            }) as string
          }
          initialValues={{
            addressName: agencyOrganizationProfileData?.organization.legalName || '',
            ...((agencyOrganizationProfileData?.organization?.invoicingAddressLocation ||
              {}) as any),
          }}
        />
      </View>

      <FormTextInputTopLabel
        label={
          t('agencyProfile.invoiceEmail', {
            defaultValue: 'Invoice email address',
          }) as string
        }
        placeholder={
          t('agencyProfile.invoiceEmailPlaceholder', {
            defaultValue: 'Enter invoice email address',
          }) as string
        }
        autoCapitalize="none"
        {...fieldProps('invoiceEmail')}
      />
    </>
  );

  const renderAboutSection = () => (
    <ThemedTextInput
      multiline
      maxLength={400}
      numberOfLines={5}
      textStyle={styles.aboutInput}
      labelText=""
      placeholder={
        t('agencyProfile.descriptionPlaceholder', {
          defaultValue:
            'Provide some information about your Agency (foundation year, number of couriers, accomplished jobs, ...)',
        }) as string
      }
      {...fieldProps('description')}
    />
  );

  const renderSocialSection = () => (
    <>
      {Object.keys(SOCIAL_OPTIONS).map((key) => {
        const item = SOCIAL_OPTIONS[key];
        return (
          <View style={styles.socialRow} key={key}>
            <View style={styles.socialIconContainer}>
              <SocialIcon fill="#000000" name={key as SocialKeysType} size={20} />
            </View>
            <FormTextInputTopLabel
              autoCapitalize="none"
              label={item.label}
              placeholder={item.placeholder}
              style={styles.flex1}
              containerStyle={styles.flex1}
              {...fieldProps(key)}
              errorMessage={formik?.errors?.[key]}
            />
            <Button
              disabled={!formik.values[key]}
              size="tiny"
              style={styles.socialCopyBtn}
              onPress={copySocialToClipboard({
                platform: key,
                value: formik.values[key],
              })}
              accessoryRight={(props) => <Icon {...props} name="copy-outline" />}
            />
          </View>
        );
      })}
    </>
  );

  return (
    <>
      <TopNavigationWithBackButton
        title={t('agencyProfile.myAgencyProfile', {
          defaultValue: 'My Agency Profile',
        }).toUpperCase()}
        navigation={navigation}
        accessoryRight={() => (
          <Button
            onPress={loading ? undefined : handleSave}
            appearance="filled"
            style={styles.saveBtn}
          >
            {loading ? <ActivityIndicator /> : 'Save'}
          </Button>
        )}
      />

      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        style={styles.flex1}
        keyboardVerticalOffset={Platform.OS === 'ios' ? StatusBar.currentHeight || 47 : 0}
      >
        <KeyboardAwareScrollView
          enableAutomaticScroll
          enableOnAndroid
          extraScrollHeight={100}
          keyboardShouldPersistTaps="handled"
          refreshControl={renderRefreshControl()}
        >
          {renderHeader()}

          <ContentBoxTextTitle
            title={t('agencyProfile.generalInformation', {
              defaultValue: 'General information',
            }).toUpperCase()}
            style={styles.contentBox}
            Content={renderGeneralInfoSection()}
          />
          <ContentBoxTextTitle
            title={t('agencyProfile.legalInformation', {
              defaultValue: 'Legal information',
            }).toUpperCase()}
            style={styles.contentBox}
            Content={renderLegalInformationSection()}
          />
          <ContentBoxTextTitle
            title={t('agencyProfile.invoiceInformation', {
              defaultValue: 'Invoice information',
            }).toUpperCase()}
            style={styles.contentBox}
            Content={renderInvoiceSection()}
          />
          <ContentBoxTextTitle
            title={t('agencyProfile.socialMedia', {
              defaultValue: 'Social Media',
            }).toUpperCase()}
            Content={renderSocialSection()}
            style={styles.contentBox}
          />
          <ContentBoxTextTitle
            title={t('agencyProfile.aboutYourAgency', {
              defaultValue: 'About your Agency',
            }).toUpperCase()}
            style={styles.contentBox}
            Content={renderAboutSection()}
          />
        </KeyboardAwareScrollView>
      </KeyboardAvoidingView>
    </>
  );
};
