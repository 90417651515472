import {
  AgencyJobStatusType,
  CourierJob,
  CourierJobAgencyRelationshipType,
  CourierJobStatusType,
} from '../../generated-graphql-types';
import { RootStackParamList } from '../../navigation/app.navigator';

export enum JOB_STATUS_FOR_COURIERS {
  NEW = 'NEW',
  RUNNING = 'RUNNING',
  PAST = 'PAST',
  DECLINED = 'DECLINED',
}

export const getFilteredCourierJobsByCourierJobStatusType = ({
  courierJobs,
  jobStatusForCouriers,
}: {
  courierJobs: Partial<CourierJob>[];
  jobStatusForCouriers: JOB_STATUS_FOR_COURIERS;
}): Partial<CourierJob>[] => {
  if (!courierJobs) {
    throw new Error('courierJobs is undefined');
  }
  if (!jobStatusForCouriers) {
    throw new Error('No courierJobStatus was provided');
  }

  return courierJobs.filter(
    (courierJob) => getJobStatusForCourierJob(courierJob) === jobStatusForCouriers,
  );
};

export const getJobStatusForCourierJob = (
  courierJob: Partial<CourierJob>,
): JOB_STATUS_FOR_COURIERS => {
  if (!courierJob) {
    throw new Error('courierJob is undefined');
  }
  if (!courierJob.job) {
    throw new Error('courierJob.job is undefined');
  }

  if (
    courierJob.courierJobStatusType === CourierJobStatusType.CANCELLED ||
    courierJob.agencyJob?.agencyJobStatusType === AgencyJobStatusType.CANCELLED ||
    (courierJob.relationshipType &&
      [
        CourierJobAgencyRelationshipType.AGENCY_DECLINED,
        CourierJobAgencyRelationshipType.COURIER_DECLINED,
      ].includes(courierJob.relationshipType))
  ) {
    return JOB_STATUS_FOR_COURIERS.DECLINED;
  } else if (
    courierJob.courierJobStatusType === CourierJobStatusType.STARTED ||
    courierJob.agencyJob?.agencyJobStatusType === AgencyJobStatusType.STARTED
  ) {
    return JOB_STATUS_FOR_COURIERS.RUNNING;
  } else if (
    courierJob.courierJobStatusType === CourierJobStatusType.FINISHED ||
    courierJob.courierJobStatusType === CourierJobStatusType.FINISHED_AGENCY_CONFIRMED ||
    courierJob.agencyJob?.agencyJobStatusType === AgencyJobStatusType.FINISHED ||
    courierJob.agencyJob?.agencyJobStatusType === AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED
  ) {
    return JOB_STATUS_FOR_COURIERS.PAST;
  }
  return JOB_STATUS_FOR_COURIERS.NEW;
};

export const getTabScreenNameForCourierCourierJobsScreen = (
  courierJob: Partial<CourierJob>,
): RootStackParamList['CourierCourierJobsScreen']['screen'] => {
  if (!courierJob) {
    throw new Error('courierJob is undefined');
  }
  if (!courierJob.job) {
    throw new Error('courierJob.job is undefined');
  }
  const courierJobStatus = getJobStatusForCourierJob(courierJob);
  if (courierJobStatus === JOB_STATUS_FOR_COURIERS.DECLINED) {
    return 'CourierCourierJobsDeclinedScreen';
  } else if (courierJobStatus === JOB_STATUS_FOR_COURIERS.RUNNING) {
    return 'CourierCourierJobsRunningScreen';
  } else if (courierJobStatus === JOB_STATUS_FOR_COURIERS.PAST) {
    return 'CourierCourierJobsPastScreen';
  }
  return 'CourierCourierJobsNewScreen';
};
