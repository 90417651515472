import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Platform, Text } from 'react-native';

import { useQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native';
import { Button, Tab, TabBar, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { AgencyJobsTabScreen } from './AgencyJobsTabScreen';
import { AgencyJobsType } from './types';
import { AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY } from '../../../apollo/graphql-queries';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { AgencyJobStatusType, AgencyJob } from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

const { Navigator, Screen } = createMaterialTopTabNavigator();

const TAB_NAMES = [
  'AgencyNewJobsScreen',
  'AgencyStartedJobsScreen',
  'AgencyFinishedJobsScreen',
  'AgencyCancelledJobsScreen',
];

export const AgencyJobsScreen = (): React.ReactElement => {
  const globalStyle = useStyleSheet(globalStyleCommon);
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const route: any = useRoute();
  const isFocused = useIsFocused();

  const [activeTab, setActiveTab] = useState(() => {
    if (route.params?.previousTabScreenName) {
      return route.params.previousTabScreenName;
    }

    if (Platform.OS === 'web') {
      if (typeof window !== 'undefined') {
        if (typeof window.location.pathname === 'string') {
          if (window.location.pathname.endsWith('started')) {
            return 'AgencyStartedJobsScreen';
          } else if (window.location.pathname.endsWith('finished')) {
            return 'AgencyFinishedJobsScreen';
          } else if (window.location.pathname.endsWith('cancelled')) {
            return 'AgencyCancelledJobsScreen';
          }
        }
        return 'AgencyNewJobsScreen';
      }
      return 'AgencyNewJobsScreen';
    }
    return 'AgencyNewJobsScreen';
  });
  const [agencyOrganizationId, setAgencyOrganizationId] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(() => {
    let screenName = 'AgencyNewJobsScreen';
    if (route.params?.previousTabScreenName) {
      screenName = route.params?.previousTabScreenName;
    }

    if (Platform.OS === 'web') {
      if (typeof window !== 'undefined') {
        if (typeof window.location.pathname === 'string') {
          if (window.location.pathname.endsWith('started')) {
            screenName = 'AgencyStartedJobsScreen';
          } else if (window.location.pathname.endsWith('finished')) {
            screenName = 'AgencyFinishedJobsScreen';
          } else if (window.location.pathname.endsWith('cancelled')) {
            screenName = 'AgencyCancelledJobsScreen';
          }
        }
      }
    }

    let index = TAB_NAMES.indexOf(screenName);
    if (index < 0) {
      index = 0;
    }
    return index;
  });

  const { data, refetch } = useQuery(AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY, {
    variables: { agencyOrganizationId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-first' : 'cache-only',
    pollInterval: isFocused && isBackendReachable ? 1000 * 30 : 0,
    skip: !agencyOrganizationId || !isFocused,
  });

  useEffect(() => {
    if (
      appUserContext.currentUserContext?.organization?.id &&
      agencyOrganizationId !== appUserContext.currentUserContext?.organization?.id
    ) {
      setTimeout(() => {
        setAgencyOrganizationId(appUserContext.currentUserContext?.organization?.id || '');
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserContext.currentUserContext?.organization?.id, agencyOrganizationId]);

  const handleTabChange = (tab: string) => () => {
    if (tab !== activeTab) {
      setActiveTab(tab);
      let index = TAB_NAMES.indexOf(tab);
      if (index < 0) {
        index = 0;
      }
      setSelectedIndex(index);
    }
  };

  const handleTabPress = (state: any) => (index: number) => {
    navigation.navigate(state.routeNames[index]);
    setActiveTab(state.routeNames[index]);
    setSelectedIndex(index);
  };

  const {
    agencyJobsNewCount,
    agencyJobsStartedCount,
    agencyJobsFinishedCount,
    agencyJobsCancelledCount,
  }: AgencyJobsType = useMemo(() => {
    if (data?.agencyJobsForAgencyOrganization?.length) {
      return data?.agencyJobsForAgencyOrganization?.reduce(
        (acc: AgencyJobsType, current: AgencyJob) => {
          const agencyJob = current as Partial<AgencyJob>;

          if (agencyJob.agencyJobStatusType) {
            if (agencyJob.agencyJobStatusType === AgencyJobStatusType.NEW) {
              acc.agencyJobsNewCount = acc.agencyJobsNewCount + 1;
            } else if (agencyJob.agencyJobStatusType === AgencyJobStatusType.STARTED) {
              acc.agencyJobsStartedCount = acc.agencyJobsStartedCount + 1;
            } else if (
              [
                AgencyJobStatusType.FINISHED,
                AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED,
              ].includes(agencyJob.agencyJobStatusType)
            ) {
              acc.agencyJobsFinishedCount = acc.agencyJobsFinishedCount + 1;
            } else if (agencyJob.agencyJobStatusType === AgencyJobStatusType.CANCELLED) {
              acc.agencyJobsCancelledCount = acc.agencyJobsCancelledCount + 1;
            }
          }
          return acc;
        },
        {
          agencyJobsNewCount: 0,
          agencyJobsStartedCount: 0,
          agencyJobsFinishedCount: 0,
          agencyJobsCancelledCount: 0,
        } as AgencyJobsType,
      );
    }
    return {
      agencyJobsNewCount: 0,
      agencyJobsStartedCount: 0,
      agencyJobsFinishedCount: 0,
      agencyJobsCancelledCount: 0,
    } as AgencyJobsType;
  }, [data?.agencyJobsForAgencyOrganization]);

  const handleButtonRightPress = () =>
    navigation.navigate('AgencyCreateJobScreen', {
      previousScreenName: 'AgencyJobsScreen',
    });

  const renderTopBar = ({ state }: any) => {
    return (
      <TabBar
        selectedIndex={selectedIndex}
        onSelect={handleTabPress(state)}
        style={globalStyle.height50}
      >
        <Tab
          style={globalStyle.paddingHorizontal5}
          title={() => (
            <Text
              style={[
                activeTab === 'AgencyNewJobsScreen'
                  ? globalStyle.colorPrimary500
                  : globalStyle.colorPrimary300,
                globalStyle.fontLatoBold,
                globalStyle.fontSize13,
                globalStyle.paddingRight5,
              ]}
            >
              {t('jobs.new', { defaultValue: 'NEW' }).toUpperCase() + ` (${agencyJobsNewCount})`}
            </Text>
          )}
          key="NEW"
        />
        <Tab
          style={globalStyle.paddingHorizontal5}
          title={() => (
            <Text
              style={[
                activeTab === 'AgencyStartedJobsScreen'
                  ? globalStyle.colorPrimary500
                  : globalStyle.colorPrimary300,
                globalStyle.fontLatoBold,
                globalStyle.fontSize13,
                globalStyle.paddingRight5,
              ]}
            >
              {t('jobs.started', { defaultValue: 'STARTED' }) + ` (${agencyJobsStartedCount})`}
            </Text>
          )}
          key="STARTED"
        />
        <Tab
          style={globalStyle.paddingHorizontal5}
          title={() => (
            <Text
              style={[
                activeTab === 'AgencyFinishedJobsScreen'
                  ? globalStyle.colorPrimary500
                  : globalStyle.colorPrimary300,
                globalStyle.fontLatoBold,
                globalStyle.fontSize13,
                globalStyle.paddingRight5,
              ]}
            >
              {t('jobs.finished', { defaultValue: 'FINISHED' }) + ` (${agencyJobsFinishedCount})`}
            </Text>
          )}
          key="FINISHED"
        />
        <Tab
          style={globalStyle.paddingHorizontal5}
          title={() => (
            <Text
              style={[
                activeTab === 'AgencyCancelledJobsScreen'
                  ? globalStyle.colorPrimary500
                  : globalStyle.colorPrimary300,
                globalStyle.fontLatoBold,
                globalStyle.fontSize13,
                globalStyle.paddingRight5,
              ]}
            >
              {t('jobs.cancelled', { defaultValue: 'CANCELLED' }) +
                ` (${agencyJobsCancelledCount})`}
            </Text>
          )}
          key="CANCELLED"
        />
      </TabBar>
    );
  };

  const renderContent = () => (
    <Navigator
      tabBar={renderTopBar}
      initialRouteName={route.params?.previousTabScreenName || 'AgencyNewJobsScreen'}
    >
      <Screen
        name="AgencyNewJobsScreen"
        listeners={() => ({
          focus: handleTabChange('AgencyNewJobsScreen'),
        })}
      >
        {(props: any) => (
          <AgencyJobsTabScreen
            {...props}
            isActive={!!agencyOrganizationId && activeTab === 'AgencyNewJobsScreen'}
            refetchCount={refetch}
            agencyJobStatusType={[AgencyJobStatusType.NEW]}
            previousTabScreenName="AgencyNewJobsScreen"
          />
        )}
      </Screen>
      <Screen
        name="AgencyStartedJobsScreen"
        listeners={() => ({ focus: handleTabChange('AgencyStartedJobsScreen') })}
      >
        {(props: any) => (
          <AgencyJobsTabScreen
            {...props}
            isActive={!!agencyOrganizationId && activeTab === 'AgencyStartedJobsScreen'}
            refetchCount={refetch}
            agencyJobStatusType={[AgencyJobStatusType.STARTED]}
            previousTabScreenName="AgencyStartedJobsScreen"
          />
        )}
      </Screen>
      <Screen
        name="AgencyFinishedJobsScreen"
        listeners={() => ({ focus: handleTabChange('AgencyFinishedJobsScreen') })}
      >
        {(props: any) => (
          <AgencyJobsTabScreen
            {...props}
            isActive={!!agencyOrganizationId && activeTab === 'AgencyFinishedJobsScreen'}
            refetchCount={refetch}
            agencyJobStatusType={[
              AgencyJobStatusType.FINISHED,
              AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED,
            ]}
            previousTabScreenName="AgencyFinishedJobsScreen"
          />
        )}
      </Screen>
      <Screen
        name="AgencyCancelledJobsScreen"
        listeners={() => ({ focus: handleTabChange('AgencyCancelledJobsScreen') })}
      >
        {(props: any) => (
          <AgencyJobsTabScreen
            {...props}
            isActive={!!agencyOrganizationId && activeTab === 'AgencyCancelledJobsScreen'}
            refetchCount={refetch}
            agencyJobStatusType={[AgencyJobStatusType.CANCELLED]}
            previousTabScreenName="AgencyCancelledJobsScreen"
          />
        )}
      </Screen>
    </Navigator>
  );

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common.jobs', { defaultValue: 'Jobs' })}
        navigation={navigation}
        onPressLeft={() => navigation.navigate('AgencyHomeScreen')}
        accessoryRight={() => (
          <Button onPress={handleButtonRightPress} appearance="filled">
            {t('common.newJob', { defaultValue: 'newJob' }) as string}
          </Button>
        )}
      />

      {renderContent()}
    </>
  );
};
