export default {
  translation: {
    '{{screenName}} is coming soon': '{{screenName}} sera bientôt disponible.',
    available: 'disponible',
    unavailable: 'indisponible',
    'Set available': 'Définir disponible',
    'Set unavailable': 'Définir indisponible',
    'Set availability dates': 'Définir les dates de disponibilité',
    'Change language': 'Changer de langue',
    'Already imported and invited': 'Déjà importés\net invités',
    'Import and invite \ncourier contact': 'Importer et inviter un contact\nen tant que coursier',
    SELECT_LANGUAGE_LABEL: "Changez la langue de l'application ici.",
    SELECT_LANGUAGE_CAPTION: 'La langue par défaut est basée sur vos paramètres système.',
    'Start Time': 'Heure de début',
    'destination location': 'Lieu de destination',
    'Your local time:': 'Votre heure locale:',
    'The time in {{locationName}}:': "L'heure en {{locationName}}:",
    'UTC / GMT time:': "L'heure UTC / GMT:",
    'Current location': 'Localisation actuelles',
    NEW_AVAILABILITY_CREATED: 'Nouvelle disponibilité créée pour {{locationName}}.',
    UPCOMING: 'ACTUELLES',
    PAST: 'PASSÉES',
    German: 'allemand',
    English: 'anglais',
    French: 'français',
    Spanish: 'espagnol',
    'Toggle theme': 'Basculer coloration',
    'Active theme': 'Coloration active',
    dark: 'foncé',
    light: 'claire',
    'No Email': "Pas d'email",
    Playground: 'Cour de récréation',
    'Sign in': 'Sign in',
    'Sign in with another account': 'Sign in with another account',
    'Sign up': 'Sign up',
    'Sign up as a Courier': 'Sign up as a Courier',
    'Your accounts': 'Your accounts',
    'No phone number provided': 'Pas de numéro de téléphone fourni',
    SIGN_UP_INFO_TEXT: 'A window will open where you can sign up with your credentials.',
    SIGN_IN_INFO_TEXT: 'A window will open where you have to enter your credentials to sign in.',
    activation: {
      enterActivationCode: 'Enter 8 digit activation code',
      activationScreenText:
        "To use the PRIOjet App, you need to activate your account. Please enter the 8-digit activation code that you've obtained in the form below.",
      activationScreenAgencyText:
        "To use the PRIOjet App, you need to activate your account. Please enter the 8-digit activation code that you've obtained from your agency in the form below.",
      activationScreenPriojetText:
        "To use the PRIOjet App, you need to activate your account. Please enter the 8-digit activation code that you've obtained from PRIOjet in the form below.",
      activationCodeUsed:
        'The activation code {{ activationCode }} was already used. Please ask for a new one. Reach out to support@priojet.com',
      activationCodeInvalidError:
        'The given activation code is invalid or cannot be used to join this organization',
      errorMessage: 'Activation error',
      errorDescription:
        'Something went wrong with account activation. Please try again or reach out to support@priojet.com',
      activationSuccess: 'Activation success',
      activationSuccessDescription: 'Account activated successfully',
    },
    ERROR: {
      INTERNAL_CODE: {
        40002: {
          message: 'Activation code error',
          description:
            'Invited organization type does not match activation code. Please ask for a new one or reach out to support@priojet.com',
        },
        44009: {
          message: 'Activation error',
          description:
            'The given activation code is invalid or cannot be used to join this organization',
        },
        44011: {
          message: 'Activation code already used',
          description:
            'The activation code was already used. Please ask for a new one. Reach out to support@priojet.com',
        },
      },
    },
    'Copied!': 'Copié !',
    UPDATED_AT_TEXT: 'Dernière modification <TimeAgoPopover></TimeAgoPopover>.',
    CONNECTED_AT_BY_USER_TEXT: 'Connecté $t(ACTION_AT_BY_USER_TEXT) ',
    CONNECTED_AT_BY_USER_TEXT_TIME_AGO: 'Connecté $t(ACTION_AT_BY_USER_TEXT_TIME_AGO) ',
    CREATED_AT_BY_USER_TEXT: 'Créé $t(ACTION_AT_BY_USER_TEXT) ',
    FORMATTED_DURATION: '<0>Durée</0>: {{duration}}.',
    FORMATTED_SINCE: '<0>Depuis</0> {{duration}}.',
    TIME_AGO_POPOVER_TEXT_START_PAST: 'Commencé {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
    TIME_AGO_POPOVER_TEXT_START_FUTURE: 'Commence {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
    TIME_AGO_POPOVER_TEXT_END_FUTURE: 'Se termine {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',
    TIME_AGO_POPOVER_TEXT_END_PAST: 'Terminé {{timeAgoText}}<TimeAgoIcon></TimeAgoIcon>.',

    ACTION_AT_BY_USER_TEXT:
      '<TimeAgoPopover></TimeAgoPopover> \npar {{firstNames}} {{lastName}} ({{email}}).',
    ACTION_AT_BY_USER_TEXT_TIME_AGO: '<TimeAgoPopover></TimeAgoPopover>.',
    ENDED_TIMEAGO: 'Ended {{timeAgoText}} <1></1>',
    INVITATIONS: {
      SUCCESSFUL_INVITATION_MESSAGE:
        '{{firstNames}} {{lastName}} (téléphone: {{phone}}, e-mail: {{email}}) a été invité avec succès',
    },
    JOBS: {
      NUM_OF_COURIERS_CONNECTED_TO_AGENCY_JOB_one:
        'There is currently only one Courier selected for this Job.',
      NUM_OF_COURIERS_CONNECTED_TO_AGENCY_JOB_other:
        'There are currently {{count}} Couriers selected for this Job.',
      manageCouriers_zero: 'Manage Couriers',
      manageCouriers_other: 'Manage Couriers ({{count}})',
      priojetJobIdentifier: 'PRIOjet Job Identifier',
      jobDescription: 'Job Description',
      additionalDetailsAndJobDescription: 'Additional details and Job description',
      jobTitleRefNumber: 'Job title/Ref. Nr.',
      pickupLocation: 'Pickup Location',
      pickupTime: 'Pickup Time',
      pickupTime_PAST: 'Pickup Time',
      pickupAddressDescription: 'Pickup Address Description',
      dropOffLocation: 'Drop-off Location',
      dropOffTime: 'Drop-off Time',
      dropOffTime_PAST: 'Drop-off Time',
      dropOffAddressDescription: 'Drop-off / Delivery Address Description',
      startAirport: 'Start Airport',
      endAirport: 'End Airport',
      description: 'Description',
      descriptionOfItems: 'Description of Items',
      numberOfItems: 'Number of Items',
      weightOfItems: 'Weight of Items',
      typeOfDelivery: 'Type of Delivery',
      typeOfTransportJob: 'Type of Transport job',
      jobStatusType: 'Job Status Type',
      jobType: 'Job Type',
      createdByOrganization: 'Created By Organization',
      createdByUser: 'Created By User',
      transportedGoodsContentType: 'Transported Goods Content Type',
      startJob: 'Start Job',
      confirmAndFinishJob: 'Confirm and finish Job',
      cancelJob: 'Cancel Job',
      resetJobAsNew: 'Reset Job as NEW',
      addOrRemoveCouriers: 'Add/Remove Couriers',
      viewOrEditJob: 'View/Edit Job',
      pending: 'pending',
      requested: 'requested',
      accepted: 'accepted',
      new: 'new',
      running: 'running',
      past: 'past',
      declined: 'declined',
      returnTimeAtEndAirport: 'Return time at end airport',
      returnTimeAtStartAirport: 'Return time at start airport',
      checkInAtEndAirport: 'Check-in at end airport',
      checkInAtStartAirport: 'Check-in at start airport',
      startJobConfirmationPopupText:
        'Do you really want to start the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      resetJobAsNewConfirmationPopupText:
        'Do you really want to set the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} back to New again? This will notify previously selected couriers.',
      confirmAndFinishJobConfirmationPopupText:
        'Do you really want to confirm and finish the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      cancelJobConfirmationPopupText:
        'Do you really want to cancel the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      doorToDoor: 'Door to Door',
      doorToAirport: 'Door to Airport',
      airportToDoor: 'Airport to Door',
      airportToAirport: 'Airport to Airport',
    },
    ORGANIZATION_INVITATIONS: {
      SHARING_ITEM:
        'Partagé <TimeAgoPopover></TimeAgoPopover> en {{language}} via {{appName}} ({{platform}} / {{platformVersion}}) par {{firstNames}} {{lastName}} {{email}}.',
      ACTIVATION_CODE_SHARE_MESSAGE_TITLE:
        'Vous avez été invité à rejoindre la plateforme PRIOjet.',
      ACTIVATION_CODE_SHARE_MESSAGE: `Bonjour!

Nous sommes heureux de pouvoir vous inviter sur la plateforme PRIOjet (iOS, Android, Web).
Veuillez vous inscrire en utilisant l'application iOS (https://apps.apple.com/kn/app/priojet-logistics/id1540437650 ), l'application Android (https://play.google.com/store/apps/details?id=com.priojet.app ) ou via https://app.priojet.com et utilisez le code

{{activationCode}}

pour activer votre compte après une inscription réussie.
Si vous avez des questions, contactez support@priojet.com.

Heureux de vous avoir à bord !
Votre équipe PRIOjet.`,

      COPIED_ACTIVATION_CODE_CLIPBOARD_MESSAGE:
        "Code d'activation copié {{activationCode}} dans le presse-papiers.",
    },
    MENU_ITEMS: {
      'App Settings': "Paramètres de l'application",
      Home: 'Home',
      Availabilities: 'Disponibilités',
      Profile: 'Profile',
      Jobs: 'Jobs',
      'Switch Account': 'Switch Account',
      'Log out': 'Se déconnecter',
      FAQ: 'Questions fréquemment posées (FAQ)',
      'Get Support': "Obtenir de l'aide",
      'Give Feedback': 'Give Feedback',
      'App Information': 'App Information',
      'Organization Invitations': 'Organization Invitations',
      'Manage PRIOjet Users': 'Manage PRIOjet Users',
      'User Management': 'User Management',
      'PRIOjet Profile': 'PRIOjet Profile',
      'Manage Users': 'Manage Users',
      'Manage Organizations': 'Manage Organizations',
      'Agency Profile': 'Agency Profile',
      'Courier Management': 'Courier Management',
      'Manage Agency Users': 'Manage Agency Users',
      'Connected Couriers': 'Connected Couriers',
      'Courier Invitations': 'Courier Invitations',
      'Job Management': 'Job Management',
      'Courier Jobs': 'Courier Jobs',
      contacts: 'Contacts',
      addAgency: 'Add Agency',
      Imprint: 'Imprint',
      'Data Privacy Policy': 'Data Privacy Policy',
      Changelog: 'Changelog',
      'Report a bug': 'Report a bug',
      'Vote for new features': 'Vote for new features',
      'Feature Request': 'Feature Request',
      Notifications: 'Notifications',
      Settings: 'Settings',
      'Other Documents': 'Other Documents',
      'Background Documents': 'Background Documents',
      'Health Test Reports': 'Health Test Reports',
      'Vaccination Certificates': 'Vaccination Certificates',
      'Frequent Flyer Program Documents': 'Frequent Flyer Program Documents',
      'Travel Permits': 'Travel Permits',
      'Identity Documents': 'Identity Documents',
      'My Documents': 'My Documents',
      'My Agencies': 'My Agencies',
      'My Profile': 'My Profile',
      'My Profile, My Agencies': 'My Profile, My Agencies',
      'Courier Profile': 'Courier Profile',
      'Organization Management': 'Organization Management',
      'Courier Documents (Passports etc.)': 'Courier Documents (Passports etc.)',
      'Courier Documents': 'Courier Documents',
      'Not Specified': 'Not Specified',
    },
    LANGUAGES: {
      en: '$t(English)',
      de: '$t(German)',
      fr: '$t(French)',
      es: '$t(Spanish)',
    },
    Email: 'Email',
    'Phone number': 'Phone number',
    chatDisabledAgencyDeleted:
      'New messages cannot be created because connected agency deleted account.',
    chatDisabledCourierDeleted:
      'New messages cannot be created because connected courier deleted account.',
    badInput: 'Bad input',
    appUpdate: {
      title: 'Time to Update',
      textMandatory:
        'We added a lot of features and some important bug fixes to make your experience as smooth as possible.',
      text: 'We added a lot of features and some important bug fixes to make your experience as smooth as possible.',
      update: 'Update',
      notNow: 'Not now',
    },
    deleteAccount: {
      confirmModal: {
        title: 'Delete account?',
        text: 'Are you sure you want to delete your account? \n\nYou will be able to re-activate your account within 30 days, after you will need to create a new one!',
        inputLabel: 'Enter DELETE to confirm account deletion.',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        inputErrorMessage: 'Confirm account deletion',
        inputErrorDescription: 'Please enter DELETE to confirm account deletion.',
      },
      deleteSuccess: {
        message: 'Account is now deleted',
        description:
          "It's unfortunate to see you go! But if you change your mind, you can contact support@priojet.com within 30 days to re-activate your account. Otherwise, the account will be permanently deleted and will require you to create and activate a new account again.",
      },
      forbiddenAction: {
        message: 'Forbidden action',
        description: 'Only Agency or Courier accounts can be deleted',
      },
      restoreAccount: {
        confirmModal: {
          title: 'Restore account',
          text: 'Are you sure you want to restore selected account?',
          confirmButtonText: 'Restore',
          cancelButtonText: 'Cancel',
        },
        restoreSuccess: {
          message: 'Account restored',
          description: 'Account successfully restored.',
        },
      },
      noDeletedAccount: 'No deleted accounts to display.',
      deletedAccountsPriojet: 'PRIOjet - Deleted accounts',
      viewAgencies: 'View Agencies',
      viewCouriers: 'View Couriers',
    },
    common: {
      agencies: 'Agencies',
      couriers: 'Couriers',
      organizationDetails: 'Organization details',
      responsibleUser: 'Responsible user',
      restore: 'Restore',
      deleted: 'Deleted',
      error: 'Error',
      somethingWentWrong: 'Something went wrong. Please try again, or contact support@priojet.com',
      FEMALE: 'Female',
      MALE: 'Male',
      OTHER: 'Other',
    },
  },
};
