import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  modal: {
    marginTop: 70,
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  modalWeb: {
    marginVertical: 30,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  modalContainer: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: 'background-basic-color-1',
    overflow: 'hidden',
    maxHeight: '100%',
    maxWidth: 1200,
    width: '100%',
  },
  modalContainerWeb: {
    borderRadius: 20,
    paddingBottom: 10,
    backgroundColor: 'background-basic-color-4',
    overflow: 'hidden',
    maxHeight: '100%',
    width: '100%',
    maxWidth: 700,
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    padding: 20,
    paddingRight: 20,
    paddingLeft: 20,
    borderBottomWidth: 1,
    borderBottomColor: 'border-basic-color-3',
  },
  titleText: {
    flex: 3,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
  },
  icon: {
    color: 'text-basic-color',
  },
  header: {
    width: '100%',
    padding: 15,
    backgroundColor: 'background-basic-color-2',
    fontFamily: 'Lato_700Bold',
  },
});
