import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
  },
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  errorMessageText: {
    color: 'color-danger-500',
    paddingBottom: 5,
  },
  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  textInput: {
    flexDirection: 'row',
    padding: 5,
    paddingHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 40,
    backgroundColor: 'background-basic-color-2',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'background-basic-color-4',
  },
  textInputError: {
    borderColor: 'color-danger-600',
  },
  textInputText: {},
  textInputTextPlaceholder: {
    opacity: 0.6,
  },
  item: {
    width: '100%',
    backgroundColor: 'background-basic-color-2',
    fontSize: 14,
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  checkBox: {
    marginLeft: 10,
  },
  icon: {
    color: 'text-basic-color',
  },

  additionalAccessoryRight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    paddingHorizontal: 16,
    justifyContent: 'center',
  },
  button: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
