import React from 'react';
import { View } from 'react-native';

import { EvaProp, Input, InputProps, Text, withStyles } from '@ui-kitten/components';

const TextInputComponent = (props: EvaProp & InputProps & { labelText: string }) => {
  const { style } = props;

  return (
    <View style={{ paddingVertical: 10 }}>
      <Input
        multiline={props.multiline}
        numberOfLines={props.numberOfLines}
        style={props.style}
        textStyle={props.textStyle}
        value={props.value}
        label={() => {
          if (!props.labelText) {
            return <></>;
          }
          return (
            <Text selectable={true} style={props.eva?.style?.label}>
              {props.labelText}
            </Text>
          );
        }}
        placeholder={props.placeholder}
        onChangeText={props.onChangeText}
      />
    </View>
  );
};

export const ThemedTextInput = withStyles(TextInputComponent, (theme) => ({
  label: {
    paddingBottom: 10,
    paddingLeft: 15,
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
    fontSize: 14,
    color: theme['color-basic-600'],
  },
}));
