import React from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';

import { AgencyJobsBox } from '../../../components/agency/jobs/AgencyJobsBoxComponent';
import { CommonHomeTopNavigation } from '../../../components/common/common.home-top-navigation.component';
import { CommonActivityBox } from '../../../components/common/CommonActivityBox';
import { Greeting } from '../../../components/common/greeting';

export const AgencyHomeScreen = () => {
  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const styles = useStyleSheet(themedStyles);

  return (
    <>
      <CommonHomeTopNavigation />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              setTimeout(() => setRefreshing(false), 100);
            }}
          />
        }
      >
        <Greeting />
        <View key="layout-courierhomelayout" style={styles.container}>
          <AgencyJobsBox />
          <CommonActivityBox key="layout-ActivityBox" refreshing={refreshing} />
        </View>
      </ScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
});
