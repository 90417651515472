import React, { useRef } from 'react';
import { FlatList, Platform, TouchableOpacity, View } from 'react-native';

import {
  Button,
  Icon,
  IndexPath,
  Input,
  SelectProps,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../common/style';
import useDimensions from '../../../../hooks/useDimensions';
import { LoadingIndicator } from '../../loading-indicator.component';

export const FormSelectModal = ({
  fieldTitleKey,
  fieldValueKey,
  filterDataCustom,
  handleClose,
  handleDone,
  items,
  isVisible,
  loading = false,
  multiSelect,
  onChangeText,
  onSelect,
  placeholder = '',
  placeholderEmpty = '',
  searchable,
  text,
  title,
  value,
}: SelectProps & {
  fieldTitleKey: string;
  fieldValueKey: string;
  filterDataCustom?: (items: any[], searchText: string) => any[];
  handleClose: any;
  handleDone?: any;
  items: Array<any>;
  multiSelect?: boolean;
  isMandatory?: boolean;
  isVisible: boolean;
  loading?: boolean;
  onChangeText?: (val: string) => void;
  onSelect: (value: any, index: IndexPath | IndexPath[], item: any) => void;
  placeholder?: string;
  placeholderEmpty?: string;
  searchable?: boolean;
  text?: string;
  title?: string;
  value?: string | string[];
}): React.ReactElement => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();
  const listRef = useRef<FlatList<any>>();
  const styles = useStyleSheet(themedStyles);
  const { isExtraLargeDevice } = useDimensions();

  const {
    data,
    handleChangeText,
    handleClosePress,
    handleDonePress,
    handleResetText,
    handlePress,
    searchText,
  } = useHook({
    fieldTitleKey,
    fieldValueKey,
    filterDataCustom,
    handleClose,
    handleDone,
    items,
    multiSelect,
    onChangeText,
    onSelect,
    searchable,
    text,
    value,
  });

  const keyExtractor = (item: any, index: number) => `${index}-${item[fieldValueKey]}`;

  const renderItem = ({ item, index }: { item: any; index: number }) => {
    let isSelected = false;
    if (multiSelect && value && value.includes(item[fieldValueKey])) {
      isSelected = true;
    } else if (!multiSelect && value === item[fieldValueKey]) {
      isSelected = true;
    }
    return (
      <TouchableOpacity
        activeOpacity={0.9}
        onPress={handlePress({ item, index })}
        style={[globalStyle.width100Percent, styles.item, isSelected && styles.itemSelected]}
      >
        <Text>{item[fieldTitleKey] || item.formattedAddress}</Text>
      </TouchableOpacity>
    );
  };

  const renderAccessoryRight = () => {
    if (searchText.length) {
      return (
        <TouchableOpacity onPress={handleResetText}>
          <Icon
            name="close-circle"
            fill={(styles.icon as any).color}
            style={[globalStyle.size24, globalStyle.opacity5]}
          />
        </TouchableOpacity>
      );
    }
    return <></>;
  };

  const renderListHeader = () => {
    if (!searchable) {
      return <></>;
    }
    return (
      <Input
        accessoryRight={renderAccessoryRight()}
        onChangeText={handleChangeText}
        placeholder={
          placeholder ? placeholder : (t('common.search', { defaultValue: 'Search' }) as string)
        }
        multiline={false}
        value={searchText}
        style={styles.textInput}
        textStyle={globalStyle.padding10}
      />
    );
  };

  const renderListEmpty = () => {
    if (loading) {
      return (
        <View
          style={[
            globalStyle.width100Percent,
            globalStyle.justifyContentCenter,
            globalStyle.alignItemsCenter,
            globalStyle.padding30,
            globalStyle.marginTop20,
          ]}
        >
          <LoadingIndicator />
        </View>
      );
    }
    return (
      <Text style={globalStyle.padding20}>
        {placeholderEmpty
          ? placeholderEmpty
          : (t('common.noMatchingData', { defaultValue: 'No matching data' }) as string)}
      </Text>
    );
  };

  const renderFlashList = () => (
    <FlatList
      ref={listRef as any}
      keyboardShouldPersistTaps="handled"
      data={data}
      extraData={value}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      // estimatedItemSize={50}
      // estimatedListSize={{ width: dimensions.window.width, height: 50 * data.length }}
      ListEmptyComponent={renderListEmpty()}
    />
  );

  const renderList = () => {
    if (Platform.OS === 'web') {
      return renderFlashList();
    }
    return renderFlashList();
  };

  return (
    <Modal
      animationIn="slideInUp"
      isVisible={isVisible}
      style={[
        globalStyle.paddingLeft0,
        globalStyle.margin0,
        Platform.OS === 'web' && isExtraLargeDevice ? styles.modalWeb : styles.modal,
      ]}
      useNativeDriver
    >
      <View
        style={[
          Platform.OS === 'web' && isExtraLargeDevice
            ? styles.modalContainerWeb
            : styles.modalContainer,
          searchable && globalStyle.height100Percent,
        ]}
      >
        <View style={styles.title}>
          <View style={globalStyle.flex1} />
          {!!title && (
            <Text category="s1" style={styles.titleText}>
              {title}
            </Text>
          )}

          <TouchableOpacity
            onPress={handleClosePress}
            style={[globalStyle.flex1, globalStyle.alignItemsEnd]}
          >
            <Icon name="close-outline" fill={(styles.icon as any).color} height={30} width={30} />
          </TouchableOpacity>
        </View>
        <View style={styles.textInputContainer}>{renderListHeader()}</View>
        {renderList()}

        {multiSelect ? (
          <Button
            onPress={handleDonePress}
            style={[bottom ? { paddingBottom: bottom } : globalStyle.paddingBottom15]}
          >
            {(props) => (
              <Text {...props} style={[props?.style, globalStyle.fontLatoBold]}>
                {t('common.done', { defaultValue: 'Done' }) as string}
              </Text>
            )}
          </Button>
        ) : (
          <View style={{ height: bottom }} />
        )}
      </View>
    </Modal>
  );
};
