import React from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { Text } from '@ui-kitten/components/ui';
import { DateTime, Duration } from 'luxon';

import { useCountdown } from '../../../../hooks/use-countdown';

export const CommonJobCountdown = ({
  dateTime,
  style,
  textStyle,
}: {
  dateTime: DateTime | undefined;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}) => {
  const duration: Duration | undefined = useCountdown(dateTime);

  let countDownString = '';

  if (duration) {
    const days = duration.get('days');
    const hours = duration.get('hours');
    const minutes = duration.get('minutes');
    const seconds = duration.get('seconds');

    if (duration.toMillis() < 1000 * 60 * 5) {
      countDownString =
        (days && days !== 0 ? days + 'd' : '') +
        (hours && hours !== 0 ? ' ' + hours + 'h' : '') +
        ' ago';
    } else {
      countDownString =
        (days && days !== 0 ? days + 'd' : '') +
        (hours && hours !== 0 ? ' ' + hours + 'h' : '') +
        (minutes && ' ' + minutes + 'm') +
        (seconds && ' ' + seconds + 's');
    }
  }

  if (!dateTime || dateTime.diffNow('milliseconds').milliseconds < 0) {
    return null;
  }
  let color = 'orange';
  if (duration && duration.toMillis() > 1000 * 60 * 60 * 3) {
    color = 'green';
  }
  return (
    <View style={style}>
      <Text style={[textStyle, { color }]}>{countDownString}</Text>
    </View>
  );
};
