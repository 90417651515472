import React from 'react';
import { Platform, ScrollView, View } from 'react-native';

import { Button, Card, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import AdditionalSeatsModal from './AdditionalSeatsModal';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../common/style';
import {
  OrganizationSubscriptionPlan,
  SubscriptionPlanPrice,
} from '../../../../generated-graphql-types';

export const CommonAgencyAccountSettingsWeb = (): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    additionalSeatsVisible,
    handleBookSuccess,
    handleBookToggle,
    handleCancelPress,
    handleToggleAdditionalSeatsPress,
    organizationSubscriptionPlan,
    organizationSubscriptionPlansRest,
    refetchOrganizationSubscriptionPlans,
  } = useHook();

  const renderHeader = (title: string) => () => (
    <Text category="h5" style={styles.planTitle}>
      {title}
    </Text>
  );

  const renderFooter = (item: OrganizationSubscriptionPlan) => () => {
    if (item.status === 'deleted' || item.status === 'cancelled') {
      return <></>;
    }
    return (
      <View style={styles.footerContainer}>
        <View style={[globalStyle.alignItemsStart, globalStyle.justifyContentCenter]}>
          <Button appearance="filled" size="tiny" status="danger" onPress={handleCancelPress(item)}>
            {t('common.cancelSubscription', { defaultValue: 'Cancel subscription' }) as string}
          </Button>
        </View>
        <View style={globalStyle.flex1} />
      </View>
    );
  };

  const renderMainPlan = () => {
    if (!organizationSubscriptionPlan) {
      return <></>;
    }

    const { recurringInterval, subscriptionPlan, prices, status } = organizationSubscriptionPlan;

    const dateFormatted = organizationSubscriptionPlan.endTime.toUTC().toFormat('MMMM dd, yyyy');
    const showButton = status !== 'cancelled' && status !== 'deleted';

    let subscriptionType = '';
    if (recurringInterval === 'month') {
      subscriptionType = t(`common.monthlySubscription`, { defaultValue: 'Monthly subscription' });
    } else if (recurringInterval === 'year') {
      subscriptionType = t(`common.annualSubscription`, { defaultValue: 'Annual subscription' });
    }

    let subscriptionStatus = t('common.statusActive', { defaultValue: 'Active' });
    if (status === 'trialing') {
      subscriptionStatus = t('common.trialPeriod', { defaultValue: '14 day trial period' });
    } else if (status === 'deleted') {
      subscriptionStatus = t('common.statusCancelled', { defaultValue: 'Cancelled' });
    } else if (status === 'cancelled') {
      subscriptionStatus = t('common.statusCancelled', { defaultValue: 'Cancelled' });
    }

    let dateText = t('common.nextBillingDate', {
      defaultValue: 'Your next billing date is {{date}}',
      date: dateFormatted,
    });
    if (status === 'deleted') {
      dateText = t('common.subscriptionValidUntil', {
        defaultValue: 'Valid until {{date}}',
        date: dateFormatted,
      });
    }
    if (status === 'cancelled') {
      dateText = t('common.subscriptionAccessUntilDate', {
        defaultValue: 'Access until {{date}}',
        date: dateFormatted,
      });
    }
    const title = t(subscriptionPlan.planName.key, {
      defaultValue: subscriptionPlan.planName.defaultValue,
    });
    let price;
    if (prices?.length) {
      price = prices.find((priceItem: SubscriptionPlanPrice) => priceItem.currency === 'EUR');
    }
    return (
      <Card
        style={styles.card}
        key={organizationSubscriptionPlan.id}
        header={renderHeader(title)}
        footer={renderFooter(organizationSubscriptionPlan)}
      >
        <View style={styles.cardContent}>
          {!!subscriptionStatus && (
            <Text style={[globalStyle.marginTop5, globalStyle.fontLatoBold]}>
              {subscriptionStatus}
            </Text>
          )}
          {!!subscriptionType && <Text style={globalStyle.fontLatoBold}>{subscriptionType}</Text>}
          {!!recurringInterval && (
            <Text style={globalStyle.marginTop2}>
              {price.currency} {price.value} / {price.recurringInterval || recurringInterval} (
              {t('common.taxExcluded', {
                defaultValue: 'tax excluded',
              })}
              )
            </Text>
          )}
          <Text style={globalStyle.marginTop2}>{dateText}</Text>

          {!showButton && (
            <View style={[globalStyle.flexRow, globalStyle.marginTop10]}>
              <Icon
                name="alert-triangle-outline"
                fill={(styles.warningIcon as any).color}
                height={22}
                width={22}
              />
              <Text style={[globalStyle.fontSize14, globalStyle.marginLeft5]}>
                {t('common.subscriptionMainCancelledWarning', {
                  defaultValue:
                    'Book a subscription before your access ends to continue using the platform. Contact PJ Support for more information.',
                })}
              </Text>
            </View>
          )}
        </View>
      </Card>
    );
  };

  const renderSeatsPlan = (item: OrganizationSubscriptionPlan) => {
    const { recurringInterval, subscriptionPlan, prices, status } = item;
    const count = subscriptionPlan.subscriptionFeatures?.[0]?.count;
    let subscriptionType = '';

    const dateFormatted = item.endTime.toUTC().toFormat('MMMM dd, yyyy');
    const showButton = status !== 'cancelled' && status !== 'deleted';

    if (item.recurringInterval === 'month') {
      subscriptionType = t(`common.monthlySubscription`, { defaultValue: 'Monthly subscription' });
    } else if (item.recurringInterval === 'year') {
      subscriptionType = t(`common.annualSubscription`, { defaultValue: 'Annual subscription' });
    }

    let subscriptionStatus = t('common.statusActive', { defaultValue: 'Active' });
    if (status === 'trialing') {
      subscriptionStatus = t('common.trialPeriod', { defaultValue: '14 day trial period' });
    } else if (status === 'deleted') {
      subscriptionStatus = t('common.statusCancelled', { defaultValue: 'Cancelled' });
    } else if (status === 'cancelled') {
      subscriptionStatus = t('common.statusCancelled', { defaultValue: 'Cancelled' });
    }

    let dateText = t('common.nextBillingDate', {
      defaultValue: 'Your next billing date is {{date}}',
      date: dateFormatted,
    });
    if (status === 'deleted') {
      dateText = t('common.subscriptionValidUntil', {
        defaultValue: 'Valid until {{date}}',
        date: dateFormatted,
      });
    }
    if (status === 'cancelled') {
      dateText = t('common.bookedSeatsAvailableUntil', {
        defaultValue: 'Booked seats are available for use until {{date}}',
        date: dateFormatted,
      });
    }
    const title = `+${count} ${t(subscriptionPlan.planName.key, {
      defaultValue: item.subscriptionPlan.planName.defaultValue,
    })}`;
    let price;
    if (prices?.length) {
      price = prices.find((priceItem) => priceItem.currency === 'EUR');
    }
    return (
      <Card
        key={item.id}
        style={styles.card}
        header={renderHeader(title)}
        footer={renderFooter(item)}
      >
        <View style={styles.cardContent}>
          {!!subscriptionStatus && (
            <Text style={[globalStyle.marginTop5, globalStyle.fontLatoBold]}>
              {subscriptionStatus}
            </Text>
          )}
          {!!subscriptionType && <Text style={globalStyle.fontLatoBold}>{subscriptionType}</Text>}
          {!!recurringInterval && price && (
            <Text style={globalStyle.marginTop2}>
              {price.currency} {price.value} / {price.recurringInterval || recurringInterval} (
              {t('common.taxExcluded', {
                defaultValue: 'tax excluded',
              })}
              )
            </Text>
          )}
          <Text style={globalStyle.marginTop2}>{dateText}</Text>
          {!showButton && (
            <View style={[globalStyle.flexRow, globalStyle.marginTop10]}>
              <Icon
                name="alert-triangle-outline"
                fill={(styles.warningIcon as any).color}
                height={22}
                width={22}
              />
              <Text style={[globalStyle.fontSize14, globalStyle.marginLeft5]}>
                {t('common.subscriptionSeatsCancelledWarning', {
                  defaultValue:
                    'Please manage the seat assignment until the date above. Access of the users that are assigned until after the date above will be disabled. Otherwise, please book another set of seats. Refer to the assigned seat counter from the Agency Employee screen.',
                })}
              </Text>
            </View>
          )}
        </View>
      </Card>
    );
  };

  const renderOrganizationPlans = () => {
    return organizationSubscriptionPlansRest?.map((item) => renderSeatsPlan(item));
  };

  return (
    <>
      <View style={styles.memberSinceContainer}>
        <Text
          style={[globalStyle.textAlignCenter, globalStyle.fontSize16, globalStyle.fontLatoBold]}
        >
          {t('common.memberSince', {
            defaultValue: 'Member since {{date}}',
            date: organizationSubscriptionPlan?.startTime?.toFormat('MMMM yyyy'),
          })}
        </Text>
      </View>
      <ScrollView contentContainerStyle={globalStyle.paddingBottom30}>
        <Text
          style={[
            globalStyle.fontSize16,
            globalStyle.textAlignCenter,
            globalStyle.marginTop10,
            globalStyle.marginBottom10,
          ]}
        >
          {t('common.agencyPlanDetails', { defaultValue: 'Agency plan details' }).toUpperCase()}
        </Text>
        <View
          style={[
            globalStyle.flexRow,
            globalStyle.flexWrap,
            globalStyle.alignItemsStart,
            globalStyle.justifyContentCenter,
            globalStyle.width100Percent,
            globalStyle.paddingHorizontal10,
          ]}
        >
          {renderMainPlan()}
        </View>
        <View
          style={[
            globalStyle.flexRow,
            globalStyle.flexWrap,
            globalStyle.alignItemsStart,
            globalStyle.justifyContentCenter,
            globalStyle.width100Percent,
            globalStyle.paddingHorizontal10,
          ]}
        >
          {renderOrganizationPlans()}
        </View>

        {Platform.OS === 'web' && (
          <View style={styles.buttonsContainer}>
            <Button
              appearance="filled"
              onPress={handleToggleAdditionalSeatsPress(true)}
              style={[globalStyle.marginLeft10, globalStyle.marginTop15]}
            >
              {t('common.bookAdditionalSeats', { defaultValue: 'Book additional seats' }) as string}
            </Button>
          </View>
        )}
      </ScrollView>
      <AdditionalSeatsModal
        onClose={handleToggleAdditionalSeatsPress(false)}
        onBookToggle={handleBookToggle}
        onBookSuccess={handleBookSuccess}
        refetchOrganizationSubscriptionPlans={refetchOrganizationSubscriptionPlans}
        visibleModal={additionalSeatsVisible}
      />
    </>
  );
};
