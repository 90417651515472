import React, { useState } from 'react';
import {
  Image,
  Linking,
  Platform,
  Pressable,
  ScrollView,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native';

import { useQuery } from '@apollo/client';
import { Button, Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  USER_BACKGROUND_DOCUMENT_QUERY,
  USER_FREQUENT_FLYER_DOCUMENT_QUERY,
  USER_HEALTH_REPORT_QUERY,
  USER_IDENTITY_DOCUMENT_QUERY,
  USER_OTHER_DOCUMENT_QUERY,
  USER_TRAVEL_DOCUMENT_QUERY,
  USER_VACCINATION_DOCUMENT_QUERY,
} from '../../apollo/graphql-queries';
import { CountryListData } from '../../assets/constants/country.list.data';
import { globalStyle } from '../../common/style';
import { FormTextTextComponent } from '../../components/common/form/form-text-text.component';
import PDFViewer from '../../components/common/pdf-viewer.component';
import { AirlineAllianceType, AirlineAllianceStatusType } from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import {
  AirlineAllianceStatusTypeList,
  AirlineAllianceTypeList,
  AirlineStatusTypeList,
  DocumentFeatureList,
  IdentityDocumentSubTypeList,
  UserDocument2,
  VaccinationTargetDiseaseList,
  HealthTestTypeList,
  AirlineTypeList,
  TravelDocumentSubTypeOptions,
} from '../../types';
import ImageLightBox from '../common/ImageLightBox';
import { LoadingSpinner } from '../common/loading-spinner.component';

const DOCUMENT_DATA: any = {
  UserBackgroundDocument: {
    query: USER_BACKGROUND_DOCUMENT_QUERY,
    queryKey: 'userBackgroundDocument',
  },
  UserFrequentFlyerDocument: {
    query: USER_FREQUENT_FLYER_DOCUMENT_QUERY,
    queryKey: 'userFrequentFlyerDocument',
  },
  UserHealthReport: {
    query: USER_HEALTH_REPORT_QUERY,
    queryKey: 'userHealthReport',
  },
  UserIdentityDocument: {
    query: USER_IDENTITY_DOCUMENT_QUERY,
    queryKey: 'userIdentityDocument',
  },
  UserOtherDocument: {
    query: USER_OTHER_DOCUMENT_QUERY,
    queryKey: 'userOtherDocument',
  },
  UserTravelDocument: {
    query: USER_TRAVEL_DOCUMENT_QUERY,
    queryKey: 'userTravelDocument',
  },
  UserVaccinationDocument: {
    query: USER_VACCINATION_DOCUMENT_QUERY,
    queryKey: 'userVaccinationDocument',
  },
};

export const CourierReadUserDocumentComponent = ({
  handleBack,
  userDocumentId,
  userDocumentType,
}: {
  handleBack: any;
  userDocumentId?: string;
  userDocumentType?: string;
  userDocument: UserDocument2;
}): React.ReactElement => {
  const { i18n, t } = useTranslation();
  const styles: any = useStyleSheet(themedStyles);
  const isBackendReachable = useIsBackendReachable();

  const [pdfUri, setPDFUri] = useState('');
  const [userDocument, setUserDocument] = useState<any>();

  const { error, loading } = useQuery(
    DOCUMENT_DATA[userDocumentType || 'UserBackgroundDocument'].query,
    {
      variables: { documentId: userDocumentId },
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
      onCompleted: (res) => {
        if (userDocumentType) {
          setUserDocument(res[DOCUMENT_DATA[userDocumentType].queryKey]);
        }
      },
    },
  );

  const handlePDFPress = (uri: string) => () => {
    if (Platform.OS === 'web') {
      Linking.openURL(uri);
    } else {
      setPDFUri(uri);
    }
  };

  const handleClose = () => {
    setPDFUri('');
  };

  const getCountry = (code: string, appendCode = true) => {
    const found = CountryListData.find((item) => item.code === code);
    if (found) {
      if (appendCode) {
        return `${found?.name} (${code})`;
      }
      return found.name;
    }
    return code;
  };

  const renderDocument = (imageCompressed: any, imageOriginal: any, side: string) => {
    if (!imageOriginal?.sasUrl) {
      return null;
    }
    if (imageOriginal.metadata?.mimeType === 'application/pdf') {
      return (
        <>
          <Text style={styles.captionText}>{side}</Text>
          <Pressable style={styles.pdfButton} onPress={handlePDFPress(imageOriginal.sasUrl)}>
            <Text style={styles.pdfText}>View PDF</Text>
          </Pressable>
          <Divider style={styles.divider} />
        </>
      );
    }
    return (
      <>
        <Text style={styles.captionText}>{side}</Text>
        <ImageLightBox
          customImage={<Image source={{ uri: imageOriginal.sasUrl }} style={styles.cardImage} />}
          expandable
          uri={imageOriginal.sasUrl}
          uriCompressed={imageCompressed.sassUrl}
        />
        <Divider style={styles.divider} />
      </>
    );
  };

  const renderPDFViewer = () => {
    if (Platform.OS === 'web') {
      return null;
    }
    return <PDFViewer onClose={handleClose} uri={pdfUri} visible={!!pdfUri} />;
  };

  const renderTextValue = ({
    date,
    time,
    hint,
    stringValue,
    style = {},
  }: {
    date?: boolean;
    time?: boolean;
    hint: string;
    stringValue?: string;
    style?: StyleProp<ViewStyle>;
  }) => {
    let text = stringValue;
    if (date) {
      if (stringValue && DateTime.fromISO(stringValue).isValid) {
        if (time) {
          text = DateTime.fromISO(stringValue).toLocaleString(DateTime.DATETIME_FULL, {
            locale: i18n.language,
          });
        } else {
          text = DateTime.fromISO(stringValue).toFormat('dd MMM yyyy');
        }
      }
    }
    return (
      <FormTextTextComponent
        copyable={!!text}
        style={[styles.formView, style]}
        hint={hint}
        stringValue={text || 'N/A'}
        textStyle={[globalStyle.justifyContentEnd, globalStyle.alignItemsCenter]}
      />
    );
  };

  const renderDescription = () => {
    if (!userDocument.description) {
      return null;
    }
    let hint = t('document.documentDetails', { defaultValue: 'DOCUMENT DETAILS' });
    if (userDocument.documentType === 'DRIVERS_LICENSE') {
      hint = t('document.licenceClass', { defaultValue: 'LICENSE CLASS' });
    }
    return renderTextValue({ hint, stringValue: userDocument.description });
  };

  /***************************** IDENTITY DOCUMENT *********************************/
  const renderDocumentType = () => {
    let text = userDocument.documentType;
    if (userDocument.documentType === 'DRIVERS_LICENSE') {
      text = "Driver's License";
    }
    if (userDocument.documentType === 'NATIONAL_ID') {
      text = 'National ID/Passport';
    }
    return renderTextValue({
      hint: t('document.documentType', { defaultValue: 'DOCUMENT TYPE' }),
      stringValue: text,
    });
  };

  const renderIdentityDocumentDescription = () => {
    if (userDocument.documentType === 'NATIONAL_ID') {
      const description = IdentityDocumentSubTypeList.find(
        (item) => item.value === userDocument.documentSubType,
      );
      return renderTextValue({
        hint: t('document.documentDescription', { defaultValue: 'DOCUMENT DESCRIPTION' }) as string,
        stringValue: description?.name,
      });
    }
    if (userDocument.documentSubTypeMulti?.length) {
      let text = '';
      userDocument.documentSubTypeMulti.forEach((sub: string) => {
        const description = IdentityDocumentSubTypeList.find((item) => item.value === sub);
        if (description) {
          if (text.length) {
            text += ', ';
          }
          text += t(`common.${description.value}`, { defaultValue: description.name });
        }
      });
      return renderTextValue({
        hint: t('document.documentDescription', { defaultValue: 'DOCUMENT DESCRIPTION' }) as string,
        stringValue: text,
      });
    }
    return null;
  };

  const renderIdentityDocumentFeature = () => {
    if (userDocument.documentType === 'DRIVERS_LICENSE') {
      return null;
    }
    const feature = DocumentFeatureList.find((item) => item.value === userDocument.documentFeature);
    return renderTextValue({
      hint: t('document.documentFeature', { defaultValue: 'DOCUMENT FEATURE' }) as string,
      stringValue: feature?.name,
    });
  };

  const renderUserIdentityDocument = () => (
    <>
      {renderTextValue({
        hint: t('document.documentNumber', { defaultValue: 'DOCUMENT NUMBER' }),
        stringValue: userDocument.documentNumber,
      })}
      {renderDocumentType()}
      {renderIdentityDocumentDescription()}
      {renderIdentityDocumentFeature()}
      {renderDescription()}

      {userDocument.documentType === 'NATIONAL_ID' &&
        renderTextValue({
          hint: t('document.nationality', { defaultValue: 'NATIONALITY' }),
          stringValue: getCountry(userDocument?.nationality),
        })}
      {renderTextValue({
        hint: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }),
        stringValue: getCountry(userDocument.countryOfIssue),
      })}
      {renderTextValue({
        hint: t('document.placeOfIssue', { defaultValue: 'PLACE OF ISSUE' }),
        stringValue: userDocument.placeOfIssue,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }),
        stringValue: userDocument.dateOfIssue,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }),
        stringValue: userDocument.expiresOn,
      })}
      {userDocument.documentType === 'DRIVERS_LICENSE' && (
        <>
          {renderTextValue({
            hint: t('document.internationalDrivingPermit', {
              defaultValue: 'INTERNATIONAL DRIVING PERMIT',
            }) as string,
            stringValue: userDocument.isInternationalDrivingPermit
              ? (t('document.yes', { defaultValue: 'Yes' }) as string)
              : (t('document.no', { defaultValue: 'No' }) as string),
          })}
          {renderTextValue({
            date: true,
            hint: t('document.dateOfExpiryInternational', {
              defaultValue: 'DATE OF EXPIRY (INTERNATIONAL)',
            }) as string,
            stringValue: userDocument.expiresOnInternational,
          })}
        </>
      )}

      {renderTextValue({
        hint: t('document.firstNames', { defaultValue: 'FIRST NAMES' }),
        stringValue: userDocument.firstNames,
      })}
      {renderTextValue({
        hint: t('document.lastName', { defaultValue: 'LAST NAME' }),
        stringValue: userDocument.lastName,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfBirth', { defaultValue: 'DATE OF BIRTH' }),
        stringValue: userDocument.dateOfBirth,
      })}
      {renderTextValue({
        hint: t('document.sex', { defaultValue: 'SEX' }),
        stringValue: t(`common.${userDocument.sex}`, {
          defaultValue: capitalize((userDocument.sex || '').toLowerCase()),
        }) as string,
      })}

      {userDocument.documentType === 'NATIONAL_ID' &&
        renderTextValue({
          hint: t('document.countryOfBirth', { defaultValue: 'COUNTRY OF BIRTH' }),
          stringValue: getCountry(userDocument.countryOfBirth),
        })}
      {renderTextValue({
        hint: t('document.placeOfBirth', { defaultValue: 'PLACE OF BIRTH' }),
        stringValue: userDocument.placeOfBirth,
      })}
    </>
  );

  /***************************** TRAVEL DOCUMENT *********************************/
  const renderTravelSubType = () => {
    if (userDocument.documentSubTypeMulti?.length) {
      let text = '';
      userDocument.documentSubTypeMulti.forEach((sub: string) => {
        const subType = TravelDocumentSubTypeOptions.find((item) => item.value === sub);
        if (subType) {
          if (text.length) {
            text += ', ';
          }
          text += t(`common.${subType.value}`, { defaultValue: subType.name });
        }
      });
      return renderTextValue({
        hint: t('document.documentDescription', { defaultValue: 'DOCUMENT DESCRIPTION' }) as string,
        stringValue: text,
      });
    } else if (userDocument.documentSubType) {
      const subType = TravelDocumentSubTypeOptions.find(
        (item) => item.value === userDocument.documentSubType,
      );
      if (subType) {
        return renderTextValue({
          hint: t('document.documentDescription', {
            defaultValue: 'DOCUMENT DESCRIPTION',
          }) as string,
          stringValue: subType.name,
        });
      }
    }
    return null;
  };

  const renderTravelDocument = () => {
    let mappedDocument = '';

    if (userDocument?.mappedIdentityDocument?.nationality) {
      const nationality = getCountry(userDocument.mappedIdentityDocument?.nationality, false);
      if (nationality) {
        mappedDocument = `${nationality} | `;
      }
    }
    mappedDocument += userDocument.mappedIdentityDocument?.documentNumber || '';
    return (
      <>
        {renderTextValue({
          hint: t('document.documentNumber', { defaultValue: 'DOCUMENT NUMBER' }),
          stringValue: userDocument.documentNumber,
        })}
        {renderTextValue({
          hint: t('document.documentTypeDetails', {
            defaultValue: 'DOCUMENT TYPE DETAILS',
          }) as string,
          stringValue: userDocument.description,
        })}
        {renderTravelSubType()}
        {renderTextValue({
          hint: t('document.multipleEntriesAllowed', {
            defaultValue: 'MULTIPLE ENTRIES ALLOWED',
          }) as string,
          stringValue: userDocument.multipleEntriesAllowed
            ? (t('document.yes', { defaultValue: 'Yes' }) as string)
            : (t('document.no', { defaultValue: 'No' }) as string),
        })}

        {renderTextValue({
          hint: t('document.associatedIdentityDocument', {
            defaultValue: 'ASSOCIATED IDENTITY DOCUMENT',
          }),
          stringValue: mappedDocument || '',
        })}

        {renderTextValue({
          hint: t('document.validForCountry', { defaultValue: 'VALID FOR COUNTRY' }),
          stringValue: getCountry(userDocument.validForCountry),
        })}
        {renderTextValue({
          date: true,
          hint: t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }),
          stringValue: userDocument.dateOfIssue,
        })}
        {renderTextValue({
          date: true,
          hint: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }),
          stringValue: userDocument.expiresOn,
        })}
        {renderTextValue({
          hint: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }),
          stringValue: getCountry(userDocument.countryOfIssue),
        })}
        {renderTextValue({
          hint: t('document.placeOfIssue', { defaultValue: 'PLACE OF ISSUE' }),
          stringValue: userDocument.placeOfIssue,
        })}

        {renderTextValue({
          hint: t('document.firstNames', { defaultValue: 'FIRST NAMES' }),
          stringValue: userDocument.mappedIdentityDocument?.firstNames || userDocument?.firstNames,
        })}
        {renderTextValue({
          hint: t('document.lastName', { defaultValue: 'LAST NAME' }),
          stringValue: userDocument.mappedIdentityDocument?.lastName || userDocument?.lastName,
        })}
        {renderTextValue({
          date: true,
          hint: t('document.dateOfBirth', { defaultValue: 'DATE OF BIRTH' }),
          stringValue:
            userDocument.mappedIdentityDocument?.dateOfBirth || userDocument?.dateOfBirth,
        })}
        {renderTextValue({
          hint: t('document.sex', { defaultValue: 'SEX' }),
          stringValue: t(`common.${userDocument.mappedIdentityDocument?.sex}`, {
            defaultValue: capitalize(
              (userDocument.mappedIdentityDocument?.sex || userDocument?.sex || '').toLowerCase(),
            ),
          }) as string,
        })}
        {renderTextValue({
          hint: t('document.placeOfBirth', { defaultValue: 'PLACE OF BIRTH' }),
          stringValue:
            userDocument.mappedIdentityDocument?.placeOfBirth || userDocument?.placeOfBirth,
        })}
        {renderTextValue({
          hint: t('document.countryOfBirth', { defaultValue: 'COUNTRY OF BIRTH' }),
          stringValue: getCountry(
            userDocument.mappedIdentityDocument?.countryOfBirth || userDocument?.countryOfBirth,
          ),
        })}
        {renderTextValue({
          hint: t('document.nationality', { defaultValue: 'NATIONALITY' }),
          stringValue: getCountry(
            userDocument.mappedIdentityDocument?.nationality || userDocument?.nationality,
          ),
        })}
      </>
    );
  };

  /***************************** FREQUENT FLYER DOCUMENT *********************************/
  const renderFrequentFlyerDocument = () => {
    const airlineCode = userDocument.airlineType;
    const airline = AirlineTypeList.find((item) => item.value === airlineCode);
    const airlineStatusType = AirlineStatusTypeList.find(
      (item) => item.value === userDocument.airlineStatusType,
    );

    let airlineAllianceType = AirlineAllianceTypeList.find(
      (item) => item.value === userDocument.airlineAllianceType,
    );
    if (airlineAllianceType?.value === 'NONE') {
      airlineAllianceType = {
        key: AirlineAllianceType.NONE,
        link: '',
        name: '',
        value: AirlineAllianceType.NONE,
      };
    }
    let airlineAllianceStatusType = AirlineAllianceStatusTypeList.find(
      (item) => item.value === userDocument.airlineAllianceStatusType,
    );
    if (airlineAllianceStatusType?.value === 'NONE') {
      airlineAllianceStatusType = {
        key: AirlineAllianceStatusType.NONE,
        link: '',
        name: '',
        value: AirlineAllianceStatusType.NONE,
      };
    }
    return (
      <>
        {renderTextValue({
          hint: t('document.documentNumber', { defaultValue: 'DOCUMENT NUMBER' }),
          stringValue: userDocument.documentNumber,
        })}
        {renderTextValue({
          date: true,
          hint: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }),
          stringValue: userDocument.expiresOn,
        })}
        {renderTextValue({
          hint: t('document.airline', { defaultValue: 'AIRLINE' }) as string,
          stringValue: airline?.name,
        })}
        {renderTextValue({
          hint: t('document.airlineCode', { defaultValue: 'AIRLINE CODE' }) as string,
          stringValue: airlineCode,
        })}
        {renderTextValue({
          hint: t('document.airlineStatus', { defaultValue: 'AIRLINE STATUS' }) as string,
          stringValue: airlineStatusType?.name,
        })}
        {renderTextValue({
          hint: t('document.airlineAlliance', {
            defaultValue: 'AIRLINE ALLIANCE',
          }) as string,
          stringValue: airlineAllianceType?.name,
        })}
        {renderTextValue({
          hint: t('document.airlineAllianceStatus', {
            defaultValue: 'AIRLINE ALLIANCE STATUS',
          }) as string,
          stringValue: airlineAllianceStatusType?.name,
        })}
      </>
    );
  };

  /***************************** VACCINATION DOCUMENT *********************************/
  const renderVaccinationDocument = () => {
    const diseaseType = VaccinationTargetDiseaseList.find(
      (item) => item.value === userDocument.diseaseType,
    );
    return (
      <>
        {renderTextValue({
          hint: t('document.diseaseOrPathogen', { defaultValue: 'DISEASE OR PATHOGEN' }) as string,
          stringValue: diseaseType?.name,
        })}
        {renderTextValue({
          hint: t('document.vaccinationManufacturer', {
            defaultValue: 'VACCINATION MANUFACTURER',
          }) as string,
          stringValue: userDocument?.vaccinationManufacturer,
        })}
        {renderTextValue({
          hint: t('document.vaccinationType', { defaultValue: 'VACCINATION TYPE' }) as string,
          stringValue: userDocument?.vaccinationType,
        })}
        {renderTextValue({
          hint: t('document.vaccinationDescription', {
            defaultValue: 'VACCINATION DESCRIPTION',
          }) as string,
          stringValue: userDocument?.description,
        })}
        {renderTextValue({
          date: true,
          hint: t('document.dateOfVaccination', { defaultValue: 'DATE OF VACCINATION' }) as string,
          stringValue: userDocument?.dateOfVaccination,
        })}
        {renderTextValue({
          date: true,
          hint: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }) as string,
          stringValue: userDocument?.expiresOn,
        })}

        {renderTextValue({
          hint: t('document.vaccinationCenter', { defaultValue: 'VACCINATION CENTER' }) as string,
          stringValue: userDocument?.vaccinationCenter,
        })}
        {renderTextValue({
          hint: t('document.cityOfVaccination', { defaultValue: 'CITY OF VACCINATION' }) as string,
          stringValue: userDocument?.cityOfVaccination,
        })}
        {renderTextValue({
          hint: t('document.countryOfVaccination', {
            defaultValue: 'COUNTRY OF VACCINATION',
          }) as string,
          stringValue: getCountry(userDocument?.countryOfVaccination),
        })}
        {renderTextValue({
          hint: t('document.vaccinationCompleted', {
            defaultValue: 'VACCINATION COMPLETED',
          }) as string,
          stringValue: userDocument.isVaccinationComplete
            ? (t('document.yes', { defaultValue: 'Yes' }) as string)
            : (t('document.no', { defaultValue: 'No' }) as string),
        })}
      </>
    );
  };

  /***************************** HEALTH DOCUMENT *********************************/
  const renderHealthReport = () => {
    const diseaseType = VaccinationTargetDiseaseList.find(
      (item) => item.value === userDocument.diseaseType,
    );
    const testType = HealthTestTypeList.find((item) => item.value === userDocument.testType);

    const dateTimeOfTest = userDocument.dateTimeOfTest
      ? userDocument.dateTimeOfTest.setZone(
          userDocument.cityOfTestLocation?.timeZoneIdentifier || undefined,
        )
      : undefined;
    return (
      <>
        {renderTextValue({
          hint: t('document.diseaseOrPathogen', { defaultValue: 'DISEASE OR PATHOGEN' }) as string,
          stringValue: diseaseType?.name,
        })}
        {renderTextValue({
          hint: t('document.testType', { defaultValue: 'TEST TYPE' }) as string,
          stringValue: testType?.name,
        })}
        {renderTextValue({
          date: true,
          time: true,
          hint: t('document.dateOfTest', { defaultValue: 'DATE OF TEST' }) as string,
          stringValue: dateTimeOfTest,
        })}
        {renderTextValue({
          hint: t('document.testDetails', { defaultValue: 'TEST DETAILS' }),
          stringValue: userDocument.description,
        })}
        {renderTextValue({
          hint: t('document.testCenter', { defaultValue: 'TEST CENTER' }),
          stringValue: userDocument.testCenter,
        })}
        {renderTextValue({
          hint: t('document.cityOfTest', { defaultValue: 'CITY OF TEST' }),
          stringValue: getCountry(userDocument.cityOfTest),
        })}
        {renderTextValue({
          hint: t('document.countryOfTest', { defaultValue: 'COUNTRY OF TEST' }),
          stringValue: getCountry(userDocument.countryOfTest),
        })}
        {renderTextValue({
          hint: t('document.resultIsPositive', {
            defaultValue: 'RESULT IS POSITIVE',
          }) as string,
          stringValue: userDocument.isResultPositive
            ? (t('document.yes', { defaultValue: 'Yes' }) as string)
            : (t('document.no', { defaultValue: 'No' }) as string),
        })}
      </>
    );
  };

  /***************************** BACKGROUND DOCUMENT *********************************/
  const renderBackgroundDocument = () => (
    <>
      {renderTextValue({
        hint: t('document.documentName', { defaultValue: 'DOCUMENT NAME' }),
        stringValue: userDocument.name,
      })}
      {renderTextValue({
        hint: t('document.issuingInstitution', { defaultValue: 'ISSUING INSTITUTION' }),
        stringValue: userDocument.issuingInstitution,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }),
        stringValue: userDocument.dateOfIssue,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }),
        stringValue: userDocument.expiresOn,
      })}
      {renderTextValue({
        hint: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }),
        stringValue: getCountry(userDocument.countryOfIssue),
      })}
    </>
  );

  /***************************** OTHER DOCUMENT *********************************/
  const renderOtherDocument = () => (
    <>
      {renderTextValue({
        hint: t('document.documentName', { defaultValue: 'DOCUMENT NAME' }),
        stringValue: userDocument.name,
      })}
      {renderTextValue({
        hint: t('document.documentDescription', { defaultValue: 'DOCUMENT DESCRIPTION' }),
        stringValue: userDocument.description,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }),
        stringValue: userDocument.dateOfIssue,
      })}
      {renderTextValue({
        date: true,
        hint: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }),
        stringValue: userDocument.expiresOn,
      })}
      {renderTextValue({
        hint: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }),
        stringValue: getCountry(userDocument.countryOfIssue),
      })}
      {renderTextValue({
        hint: t('document.placeOfIssue', { defaultValue: 'PLACE OF ISSUE' }),
        stringValue: userDocument.placeOfIssue,
      })}
    </>
  );

  const renderDocumentPerType = () => {
    if (userDocumentType === 'UserIdentityDocument') {
      return renderUserIdentityDocument();
    }
    if (userDocumentType === 'UserTravelDocument') {
      return renderTravelDocument();
    }
    if (userDocumentType === 'UserFrequentFlyerDocument') {
      return renderFrequentFlyerDocument();
    }
    if (userDocumentType === 'UserVaccinationDocument') {
      return renderVaccinationDocument();
    }
    if (userDocumentType === 'UserHealthReport') {
      return renderHealthReport();
    }
    if (userDocumentType === 'UserBackgroundDocument') {
      return renderBackgroundDocument();
    }
    if (userDocumentType === 'UserOtherDocument') {
      return renderOtherDocument();
    }

    return <></>;
  };

  if (loading || !userDocument) {
    return <LoadingSpinner />;
  }
  if (error) {
    return (
      <View style={globalStyle.paddingVertical20}>
        <Text style={globalStyle.marginBottom15}>
          Something went wrong with reading document data
        </Text>
        <Button onPress={handleBack} appearance="filled" style={styles.saveBtn}>
          {t('document.goBack', { defaultValue: 'Go back' }) as string}
        </Button>
      </View>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.contentContainer}>
      {renderDocument(
        userDocument.documentFrontFromAzureCompressed,
        userDocument.documentFrontFromAzureOriginal,
        'FRONT SIDE',
      )}
      {renderDocument(
        userDocument.documentBackFromAzureCompressed,
        userDocument.documentBackFromAzureOriginal,
        'BACK SIDE',
      )}
      {renderPDFViewer()}

      {renderDocumentPerType()}
    </ScrollView>
  );
};

const themedStyles = StyleService.create({
  cardImage: {
    width: 400,
    height: 300,
    marginVertical: 20,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  contentContainer: {
    paddingVertical: 24,
  },
  layoutSocialMediaContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  layoutCreditCardsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  formView: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
  avatar: {
    width: 150,
    height: 150,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  sectionLabel: {
    marginTop: 24,
    marginBottom: 8,
    marginHorizontal: 16,
  },
  profileDescription: {
    marginHorizontal: 16,
  },

  captionText: {
    fontSize: 14,
    fontFamily: 'Lato_700Bold',
    paddingLeft: 15,
  },
  pdfButton: {
    padding: 10,
    paddingLeft: 15,
  },
  pdfText: {
    color: 'color-primary-600',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
    paddingBottom: 5,
  },
  divider: {
    marginBottom: 20,
  },
});
