import React from 'react';
import { View, Pressable, ScrollView } from 'react-native';

import { Ionicons } from '@expo/vector-icons';
import { Card, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation, Trans } from 'react-i18next';
import Animated from 'react-native-reanimated';

import { calculatePrice, useHook, PLAN_PERIOD_ITEMS } from './hook';
import { themedStyle } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import { SubscriptionPlan } from '../../../../generated-graphql-types';
import useDimensions from '../../../../hooks/useDimensions';

export const SubscriptionPlanOptions = ({
  data,
  hasTrialPeriod,
  onSelect,
  onSelectPeriod,
  selected,
}: any) => {
  const { i18n, t } = useTranslation();
  const styles = useStyleSheet(themedStyle);
  const globalStyle = useStyleSheet(globalStyleCommon);
  const { dimensions } = useDimensions();
  const { width: PAGE_WIDTH } = dimensions.window;

  const {
    animatedStyles,
    handleSelect,
    handleSelectItem,
    measureView,
    periodItemWidth,
    selectedPeriod,
  } = useHook({
    onSelect,
    onSelectPeriod,
  });

  const renderCardData = ({ item, itemWidth }: any) => (
    <View style={[globalStyle.width100Percent, { width: itemWidth - 20 }]}>
      {!!item.description && (
        <Text style={styles.cardTitle}>
          {' '}
          {t(`common.${item.name.key}`, { defaultValue: item.name.defaultValue })}
        </Text>
      )}

      <View style={styles.separator} />

      {!!item.prices?.length && (
        <Text style={styles.cardPrice}>
          {item.prices[0].currencySymbol} {calculatePrice(item.prices[0].value)}
        </Text>
      )}
      <Text style={styles.taxText}>
        {t('common.taxExcluded', { defaultValue: '*tax excluded' })}
      </Text>

      {!!item.description && (
        <Text style={styles.cardDescriptionText}>
          {t(`common.${item.description.key}`, { defaultValue: item.description.defaultValue })}
        </Text>
      )}

      {!!item.featureDescription?.length && (
        <>
          <View style={styles.separator} />
          {item.featureDescription.map(
            (featureDescription: { key: string; defaultValue: string }, i: number) => (
              <View key={`option-${item.planId}-${i}`} style={styles.cardDescriptionListItem}>
                <Ionicons name="checkmark" size={22} color={(styles.icon as any).color} />
                <Text style={styles.cardDescriptionListItemText}>
                  {t(`common.${featureDescription.key}`, {
                    defaultValue: featureDescription.defaultValue,
                  })}
                </Text>
              </View>
            ),
          )}
        </>
      )}
    </View>
  );

  const renderCarouselCard = ({ item, index }: any) => (
    <Card
      key={item.planId}
      onLayout={measureView}
      style={[
        styles.cardCarousel,
        selected?.planId === item.planId && styles.cardCarouselSelected,
        globalStyle.marginRight10,
        index === 0 && globalStyle.marginLeft20,
        { width: PAGE_WIDTH - 70 },
      ]}
      onPress={handleSelect(item, index)}
    >
      {renderCardData({ item, index, itemWidth: PAGE_WIDTH - 30 })}
    </Card>
  );

  const renderCard = ({ item, index }: any) => {
    let itemWidth = PAGE_WIDTH / data.length - 30;
    if (data.length === 1) {
      itemWidth = PAGE_WIDTH / 2;
    }
    if (PAGE_WIDTH < 600) {
      itemWidth = PAGE_WIDTH - 100;
    }
    if (itemWidth > 380) {
      itemWidth = 380;
    }
    return (
      <Card
        key={item.planId}
        style={[
          styles.cardCarousel,
          selected?.planId === item.planId && styles.cardSelected,
          { width: itemWidth },
        ]}
        onPress={handleSelect(item, index)}
      >
        {renderCardData({ item, index, itemWidth })}
      </Card>
    );
  };

  const renderCarousel = () => (
    <ScrollView pagingEnabled horizontal showsHorizontalScrollIndicator={false}>
      {data.map((item: SubscriptionPlan, index: number) => renderCarouselCard({ item, index }))}
    </ScrollView>
  );

  const renderCards = () => (
    <View style={styles.cardsContainer}>
      {data.map((item: any, index: any) => renderCard({ item, index }))}
    </View>
  );

  return (
    <>
      <View style={[styles.group, { width: periodItemWidth * PLAN_PERIOD_ITEMS.length + 6 }]}>
        <View
          style={[
            styles.itemsContainer,
            {
              width: periodItemWidth * PLAN_PERIOD_ITEMS.length,
            },
          ]}
        >
          <Animated.View
            style={[styles.groupItemActive, { width: periodItemWidth }, animatedStyles]}
          />
          {PLAN_PERIOD_ITEMS.map((item, index) => (
            <Pressable
              key={item.value}
              style={styles.groupItem}
              onPress={handleSelectItem(item, index)}
            >
              <Text
                numberOfLines={1}
                style={[
                  styles.groupItemText,
                  item.value === selectedPeriod.value && styles.groupItemTextSelected,
                ]}
              >
                {item.label}
              </Text>
            </Pressable>
          ))}
        </View>
      </View>
      <View style={[globalStyle.marginTop20, globalStyle.width100Percent]}>
        {PAGE_WIDTH > 700 || data.length === 1 ? renderCards() : renderCarousel()}
      </View>
      {hasTrialPeriod && (
        <View style={globalStyle.width90Percent}>
          <Text
            style={[
              globalStyle.marginTop20,
              globalStyle.fontSize16,
              globalStyle.fontLatoBold,
              globalStyle.textAlignCenter,
            ]}
          >
            <Trans
              i18n={i18n}
              i18nKey="common.newSubscriptionGetsA14DayTrial"
              components={[
                <Text style={[globalStyle.colorSuccess600, globalStyle.fontLatoBlack]} />,
              ]}
              defaults="New subscriptions get a <0>14-DAY FREE TRIAL</0>!"
            />
          </Text>
        </View>
      )}
    </>
  );
};
