import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  saveBtn: {
    height: 44,
    width: 75,
  },
  marginTopNegative5: {
    marginTop: -5,
  },
});
