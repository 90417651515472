import React, { useContext, useMemo } from 'react';
import { FlatList, Platform, View } from 'react-native';

import { useMutation, useQuery } from '@apollo/client';
import { useIsFocused } from '@react-navigation/core';
import { Button, Card, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import {
  MUTATION_RESTORE_AGENCY_ACCOUNT,
  MUTATION_RESTORE_COURIER_ACCOUNT,
  MUTATION_USER_ACCOUNT_RESTORE,
} from '../../apollo/graphql-mutations';
import { QUERY_DELETED_ACCOUNTS_PRIOJET } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { ConfirmModalContext } from '../../components/common/modal/ConfirmModal';
import { TimeAgoPopover } from '../../components/common/time-ago-popover.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import { OrganizationType, OrganizationUser, User } from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';

export const PriojetDeletedAccountsScreen = ({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const { organizationType, type } = route.params || {};

  const isFocused = useIsFocused();
  const styles = useStyleSheet(themedStyles);
  const isBackendReachable = useIsBackendReachable();
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const { data } = useQuery(QUERY_DELETED_ACCOUNTS_PRIOJET, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    pollInterval: isFocused && isBackendReachable ? 1000 * 30 : 0,
    skip: !isFocused,
  });
  const [restoreAgencyAccount] = useMutation(MUTATION_RESTORE_AGENCY_ACCOUNT, {
    refetchQueries: [QUERY_DELETED_ACCOUNTS_PRIOJET],
  });
  const [restoreCourierAccount] = useMutation(MUTATION_RESTORE_COURIER_ACCOUNT, {
    refetchQueries: [QUERY_DELETED_ACCOUNTS_PRIOJET],
  });
  const [restoreUserAccount] = useMutation(MUTATION_USER_ACCOUNT_RESTORE, {
    refetchQueries: [QUERY_DELETED_ACCOUNTS_PRIOJET],
  });

  const deletedAccounts = useMemo(() => {
    if (data?.deletedAccountsPriojetOnly?.length) {
      return data.deletedAccountsPriojetOnly.filter((current: User) => {
        const organizationUser = current.organizationUsers.find(
          (orgUser: OrganizationUser) => orgUser.user.id === current.id,
        );
        const isAgencyDeactivated = organizationUser?.organization.inactivatedAt;

        if (!current.organizationUsers.length && type === 'user') {
          return true;
        }
        if (organizationUser?.organization.organizationType === organizationType) {
          if (organizationType === OrganizationType.COURIER) {
            return true;
          } else if (organizationType === OrganizationType.AGENCY) {
            if (type === 'organization') {
              return isAgencyDeactivated;
            }
            return !isAgencyDeactivated;
          }
        }
      });
    }
    return [];
  }, [data.deletedAccountsPriojetOnly, organizationType, type]);

  const handleRestorePress = (userId: string, _organizationType: string) => () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('deleteAccount.restoreAccount.confirmModal.confirmButtonText', {
        defaultValue: 'Restore',
      }),

      title: t('deleteAccount.restoreAccount.confirmModal.title', {
        defaultValue: 'Restore',
      }),
      text: t('deleteAccount.restoreAccount.confirmModal.text', {
        defaultValue: 'Are you sure you want to restore selected account?',
      }),
      cancelButtonText: t('deleteAccount.restoreAccount.confirmModal.cancelButtonText', {
        defaultValue: 'Cancel',
      }),

      onConfirmPress: async () => {
        try {
          let res;
          let mutation;
          if (_organizationType === OrganizationType.AGENCY) {
            if (type === 'organization') {
              mutation = restoreAgencyAccount;
            } else {
              mutation = restoreUserAccount;
            }
          } else if (_organizationType === OrganizationType.COURIER) {
            mutation = restoreCourierAccount;
          }

          if (mutation) {
            res = await mutation({
              variables: { userId },
            });
            showMessage({
              message: t('deleteAccount.restoreAccount.restoreSuccess.message', {
                defaultValue: 'Account restored',
              }),
              description: t('deleteAccount.restoreAccount.restoreSuccess.description', {
                defaultValue: 'Account successfully restored.',
              }) as string,
              type: 'success',
              autoHide: true,
              hideOnPress: true,
              duration: 8000,
            });
            return res;
          }
          return true;
        } catch ({ graphQLErrors, networkError }: any) {
          if (networkError) {
            showMessage({
              message: t('common.error', { defaultValue: 'Error' }),
              description: t('common.somethingWentWrong', {
                defaultValue:
                  'Something went wrong. Please try again, or contact support@priojet.com',
              }) as string,
              type: 'danger',
              autoHide: false,
              hideOnPress: true,
              duration: 5000,
            });
          } else if (graphQLErrors) {
            showMessage({
              message: 'Error',
              description:
                (graphQLErrors as any)?.[0]?.message ||
                t('common.somethingWentWrong', {
                  defaultValue:
                    'Something went wrong. Please try again, or contact support@priojet.com',
                }),
              type: 'danger',
              autoHide: false,
              hideOnPress: true,
              duration: 5000,
            });
          }
        }
      },
      visible: true,
    });
  };

  const handleBackPress = () => {
    navigation.navigate('PriojetHomeScreen');
  };

  const renderAgency = (item: User) => {
    const organizationUser = item.organizationUsers.find((i) => i.user.id === item.id);
    const showButton =
      item.inactivatedAt && DateTime.now().diff(item.inactivatedAt, 'days').days < 30;
    return (
      <>
        <Card key={item.id} style={styles.card}>
          <View style={[styles.flex1, styles.flexColumn]}>
            {!!organizationUser?.organization && (
              <>
                <Text style={styles.contentText} category="s1">
                  {
                    t('common.organizationDetails', {
                      defaultValue: 'Organization details',
                    }) as string
                  }
                </Text>

                <Text style={styles.contentText}>{organizationUser.organization.name}</Text>
              </>
            )}
            <Text>{item.email}</Text>
            <View style={styles.agencyHeader}>
              <Text>
                {
                  t('common.responsibleUser', {
                    defaultValue: 'Responsible user',
                  }) as string
                }
                : {}
                <Text style={styles.titleText}>
                  {item.firstNames} {item.lastName}
                </Text>
              </Text>
            </View>
            <View style={styles.timeAgoLayer}>
              <TimeAgoPopover
                date={item.inactivatedAt as DateTime}
                language={i18n.language}
                textStyle={styles.timeAgoText}
              />
            </View>
          </View>

          {showButton && (
            <Button
              onPress={handleRestorePress(item.id, OrganizationType.AGENCY)}
              size="medium"
              style={globalStyle.marginTop10}
            >
              {t('common.restore', { defaultValue: 'Restore' }) as string}
            </Button>
          )}
        </Card>
      </>
    );
  };

  const renderCourier = (item: User) => {
    const showButton =
      item.inactivatedAt && DateTime.now().diff(item.inactivatedAt, 'days').days < 30;
    return (
      <Card key={item.id} style={styles.card}>
        <View style={[styles.flex1, styles.flexColumn]}>
          <Text style={styles.titleText}>
            {item.firstNames} {item.lastName}
          </Text>
          <Text>{item.email}</Text>
          <View style={styles.timeAgoLayer}>
            <Text>
              {t('common.deleted', { defaultValue: 'Deleted ' }) as string} {}
            </Text>
            <TimeAgoPopover
              date={item.inactivatedAt as DateTime}
              language={i18n.language}
              textStyle={styles.timeAgoText}
            />
          </View>
        </View>
        {showButton ? (
          <Button
            onPress={handleRestorePress(item.id, OrganizationType.COURIER)}
            size="medium"
            style={globalStyle.marginTop10}
          >
            {t('common.restore', { defaultValue: 'Restore' }) as string}
          </Button>
        ) : (
          <View style={globalStyle.paddingBottom5} />
        )}
      </Card>
    );
  };

  const keyExtractor = (item: User) => item.id;

  const renderItem = ({ item }: { item: User; index: number }) => {
    if (organizationType === OrganizationType.AGENCY) {
      return renderAgency(item);
    }
    if (organizationType === OrganizationType.COURIER) {
      return renderCourier(item);
    }
    return <></>;
  };

  const renderEmpty = () => (
    <View
      style={[
        globalStyle.alignItemsCenter,
        globalStyle.justifyContentCenter,
        globalStyle.padding30,
      ]}
    >
      <Text style={globalStyle.textAlignCenter}>
        {
          t('deleteAccount.noDeletedAccount', {
            defaultValue: 'No deleted accounts to display.',
          }) as string
        }
      </Text>
    </View>
  );

  return (
    <>
      <TopNavigationWithBackButton
        title={t('deleteAccount.deletedAccountsPriojet', {
          defaultValue: 'PRIOjet - Deleted accounts',
        })}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />
      <FlatList
        data={deletedAccounts}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        contentContainerStyle={styles.flatList}
        ListEmptyComponent={renderEmpty()}
      />
    </>
  );
};

const themedStyles = StyleService.create({
  flatList: {
    padding: 10,
  },
  card: {
    flex: 1,
    padding: 12,
    paddingVertical: 15,
    marginBottom: 10,
    backgroundColor: 'background-basic-color-2',
  },
  flex1: {
    flex: 1,
  },
  flexColumn: {
    flexDirection: 'column',
  },
  flexRow: {
    flexDirection: 'row',
  },
  alignCenter: {
    alignItems: 'center',
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  agencyHeader: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  titleText: {
    flex: 1,
    fontFamily: 'Lato_700Bold',
  },
  timeAgoLayer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  timeAgoText: {
    fontSize: 15,
    fontFamily: 'Lato_700Bold',
  },
  contentText: {
    borderTopColor: 'color-basic-600',
    marginTop: 5,
    width: Platform.OS === 'web' ? '80%' : '100%',
  },
  divider: {
    marginTop: 20,
    marginBottom: 15,
  },
});
