import React from 'react';
import { Platform, Pressable, ScrollView, TouchableOpacity, View } from 'react-native';

import { Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import FlashMessage from 'react-native-flash-message';
import Modal from 'react-native-modal';

import { ChatFlightTicketItem } from './ChatFlightTicketItem';
import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../../common/style';
import PDFViewer from '../../../../../components/common/pdf-viewer.component';
import { AgencyJobPlanningLeg } from '../../../../../generated-graphql-types';
import useDimensions from '../../../../../hooks/useDimensions';

export const ChatFlightDetailsModal = ({
  handleCancel,
  agencyJobPlanningLegs = [],
  visible,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const globalStyle = useStyleSheet(globalStyleCommon);
  const styles = useStyleSheet(themedStyles);
  const { dimensions } = useDimensions();

  const { flashMessageRef, handlePDFPress, showMessage, pdfUri } = useHook();

  const renderTicketItem = (item: any, index: number) => {
    const name = item.storedAsset?.metadata?.fileName || '-';
    return (
      <ChatFlightTicketItem
        key={`${name}-${index}`}
        file={item.storedAsset}
        handlePDFPress={handlePDFPress}
        showMessage={showMessage}
      />
    );
  };

  const renderLegDetails = (leg: AgencyJobPlanningLeg, index: number) => {
    let departureDate: any = leg.departureDate;
    if (typeof departureDate === 'string') {
      departureDate = DateTime.fromJSDate(new Date(departureDate));
    } else if (departureDate?.toJSDate) {
      departureDate = DateTime.fromJSDate(departureDate.toJSDate());
    }
    let flightNumber = '';
    if (leg.flightCarrierDesignation) {
      flightNumber = leg.flightCarrierDesignation;
    }
    if (leg.flightNumber) {
      if (flightNumber) {
        flightNumber += ' ';
      }
      flightNumber += leg.flightNumber;
    }
    return (
      <View
        key={leg.id}
        style={[
          globalStyle.width100Percent,
          globalStyle.marginBottom20,
          globalStyle.flexColumn,
          globalStyle.backgroundColorBasic2,
          globalStyle.borderRadius8,
        ]}
      >
        <View style={globalStyle.padding15}>
          <Text
            style={globalStyle.fontLatoBlack}
          >{`${t('jobs.leg', { defaultValue: 'Leg ' })} ${index + 1}`}</Text>
        </View>
        <View
          style={[
            globalStyle.flexRow,
            globalStyle.padding15,
            globalStyle.paddingTop0,
            globalStyle.paddingBottom10,
          ]}
        >
          <Text style={globalStyle.fontLatoBold}>
            {t('jobs.flightNumber', { defaultValue: 'Flight number' })}: {}
          </Text>
          <Text>{flightNumber || '-'}</Text>
        </View>
        <View style={[globalStyle.flexRow, globalStyle.padding15, globalStyle.paddingTop0]}>
          <Text style={globalStyle.fontLatoBold}>
            {t('jobs.departureDate', { defaultValue: 'Departure date' })}: {}
          </Text>
          <Text>
            {departureDate ? `${departureDate?.toFormat('dd MMM yyyy')}` : '-'}
            {departureDate ? `, ${departureDate?.toFormat('HH:mm')}` : '-'}
          </Text>
        </View>
        <View
          style={[
            globalStyle.padding10,
            globalStyle.paddingHorizontal15,
            globalStyle.backgroundColorBasic4,
            styles.bottomBorderRadius8,
            !leg.flightTickets?.length && globalStyle.flexRow,
          ]}
        >
          <Text style={globalStyle.marginBottom5}>
            {t('jobs.tickets', { defaultValue: 'Tickets' })}:
          </Text>
          {leg.flightTickets?.length ? (
            <>{leg.flightTickets.map(renderTicketItem)}</>
          ) : (
            <Text>{} -</Text>
          )}
        </View>
      </View>
    );
  };

  const renderScrollView = () => (
    <ScrollView style={styles.scrollWrapper} contentContainerStyle={styles.inner}>
      <Text category="h5" style={styles.title}>
        {t('jobs.flightDetails', { defaultValue: 'Flight details' })}
      </Text>

      <View style={globalStyle.width100Percent}>{agencyJobPlanningLegs.map(renderLegDetails)}</View>

      <Text
        style={[
          globalStyle.fontSize14,
          globalStyle.opacity7,
          globalStyle.marginBottom5,
          globalStyle.marginLeft5,
        ]}
      >
        {t('jobs.canOnlyBeChangedByTheAgencyInPlanningView', {
          defaultValue: 'Can only be changed by the agency in Planning view.',
        })}
      </Text>
    </ScrollView>
  );

  return (
    <>
      <Modal
        animationIn="fadeIn"
        animationOut="fadeOut"
        avoidKeyboard
        isVisible={visible}
        backdropOpacity={0.8}
        useNativeDriver
        hideModalContentWhileAnimating
        style={
          Platform.OS === 'web' && [globalStyle.justifyContentCenter, globalStyle.alignItemsCenter]
        }
      >
        <Pressable style={globalStyle.absoluteFull} onPress={handleCancel} />
        <View style={[styles.wrapper, { width: dimensions.window.width - 30 }]}>
          {renderScrollView()}
          <TouchableOpacity
            style={styles.closeButton}
            onPress={handleCancel}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <Icon
              fill={(globalStyle.colorTextBasicColor as any).color}
              name="close"
              style={globalStyle.size30}
            />
          </TouchableOpacity>
        </View>

        <PDFViewer onClose={handlePDFPress('')} uri={pdfUri} visible={!!pdfUri} />

        <FlashMessage
          ref={flashMessageRef}
          position="top"
          duration={3000}
          style={[styles.flashMessage, Platform.OS === 'android' && styles.flashMessagePaddingTop]}
          titleStyle={styles.flashMessageText}
          textStyle={styles.flashMessageText}
        />
      </Modal>
    </>
  );
};
