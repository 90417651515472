import React, { memo } from 'react';
import { Platform, Pressable, View } from 'react-native';

import { useStyleSheet } from '@ui-kitten/components';
import { Image } from 'expo-image';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { styles as themedStyles } from './styles';
import { DownloadIcon } from '../../../../icons.component';
import ImageLightBox from '../../../ImageLightBox';

const downloadFile =
  Platform.OS === 'web'
    ? require('../../../../../modules/helper/download.module').downloadFileWeb
    : require('../../../../../modules/helper/download.module').downloadFile;

export const ChatMessageImageComponent: React.FC<any> = ({
  chatFile,
  downloadIconFill,
  style,
  styleImage,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { metadata, sasUrl } = chatFile;

  const saveFile = (type: 'Image' | 'PDF') => async () => {
    const { fileName, mimeType } = metadata;
    if (Platform.OS === 'web') {
      await downloadFile({ fileName, uri: sasUrl });
    } else {
      const {
        error,
        showMessage: showMessageValue,
        success,
      } = await downloadFile({ fileName, mimeType, uri: sasUrl });
      if (showMessageValue) {
        showMessage({
          message: success
            ? t('common.success', { defaultValue: 'Success' })
            : t('common.error', { defaultValue: 'Error' }),
          description: success
            ? (t('common.successfullySaved', {
                type,
                defaultValue: `${type} successfully saved`,
              }) as string)
            : error,
          type: success ? 'success' : 'danger',
          autoHide: true,
          hideOnPress: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <View style={[styles.imageContainer, style]}>
      <ImageLightBox
        customImage={<Image style={[styles.image, styleImage] as any} source={sasUrl} />}
        expandable
        uri={sasUrl}
      />
      <Pressable style={styles.downloadImageButton} onPress={saveFile('Image')}>
        <DownloadIcon
          fill={downloadIconFill || (styles.downloadIcon as any).color}
          style={styles.downloadIcon}
        />
      </Pressable>
    </View>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps.chatFile?.sasUrl !== nextProps.chatFile?.sasUrl) {
    return false;
  }
  return true;
};

export const ChatMessageImage = memo(ChatMessageImageComponent, arePropsEqual);
