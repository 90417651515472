import React, { useContext, useState } from 'react';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  Button,
  IndexPath,
  MenuItem,
  OverflowMenu,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import * as Contacts from 'expo-contacts';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { PhoneEmailPermutation } from './types';
import { CREATE_OR_UPDATE_COURIER_USER_CONTACT_MUTATION } from '../../../../apollo/graphql-mutations';
import { PRIOJET_SUPPORT_EMAIL } from '../../../../constants/Constants';
import {
  ExpoContactDateInput,
  ExpoContactEmailInput,
  ExpoContactInput,
} from '../../../../generated-graphql-types';
import { CreateOrUpdateCourierUserContactVariables } from '../../../../graphql-types';
import useDimensions from '../../../../hooks/useDimensions';
import { AppUserContact } from '../../../../types';
import { ConfirmModalContext } from '../../modal/ConfirmModal';

export const CourierInvitationButton = ({
  item,
  refetch,
}: {
  item: AppUserContact;
  refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyle);
  const { isVerySmallDevice } = useDimensions();
  const { hideConfirmModal, showConfirmModal } = useContext(ConfirmModalContext);

  const [createOrUpdateUserContactMutation] = useMutation(
    CREATE_OR_UPDATE_COURIER_USER_CONTACT_MUTATION,
  );

  const [overflowMenuVisible, setOverflowMenuVisible] = useState<boolean>(false);

  const phoneNumbersWithoutCountryCode: Contacts.PhoneNumber[] | undefined =
    item.phoneNumbers?.filter((phoneNumber) => !phoneNumber.number?.startsWith('+'));

  const phoneEmailPermutations: PhoneEmailPermutation[] = [];
  if (item.phoneNumbers) {
    for (let i = 0; i < item.phoneNumbers.length; i++) {
      if (item.emails && item.emails.length > 0) {
        for (let j = 0; j < item.emails.length; j++) {
          phoneEmailPermutations.push({
            phone: item.phoneNumbers[i],
            email: item.emails[j],
          });
        }
      } else {
        phoneEmailPermutations.push({
          phone: item.phoneNumbers[i],
          email: undefined,
        });
      }
    }
  }

  const importUserContact = async ({
    appUserContact,
    selectedPhoneEmail,
    inviteUserContact = true,
  }: {
    appUserContact: AppUserContact;
    selectedPhoneEmail: PhoneEmailPermutation;
    inviteUserContact: boolean;
  }): Promise<boolean> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state, ..._appUserContact } = { ...appUserContact };

    const phone: string | undefined = selectedPhoneEmail.phone.number;
    const email: string | undefined = selectedPhoneEmail.email?.email;
    const errors: any = [];
    const fields = [
      {
        field: 'email',
        label: t('agencyPhoneBookContactsList.emailAddress', { defaultValue: 'email address' }),
        value: email,
      },
      {
        field: 'phone',
        label: t('agencyPhoneBookContactsList.internationalPhoneNumberWithCountryCode', {
          defaultValue: 'international phone number with country code (e.g. + 49)',
        }),
        value: phone,
      },
      {
        field: 'firstName',
        label: t('agencyPhoneBookContactsList.firstName', { defaultValue: 'first name' }),
        value: _appUserContact.firstName,
      },
      {
        field: 'lastName',
        label: t('agencyPhoneBookContactsList.lastName', { defaultValue: 'last name' }),
        value: _appUserContact.lastName,
      },
    ];
    fields.forEach((field) => {
      if (!field.value) {
        errors.push(field);
      }
    });
    if (errors.length || !_appUserContact.firstName || !_appUserContact.lastName) {
      let errorMessage = t('agencyPhoneBookContactsList.thereMustBeValid', {
        defaultValue: 'There must be valid',
      }) as string;
      errors.forEach((error: any, index: number) => {
        if (errors.length > 2 && index + 1 === errors.length) {
          errorMessage += t('agencyPhoneBookContactsList.and', {
            defaultValue: ', and',
          }) as string;
        } else if (errors.length > 1 && index + 1 === errors.length) {
          errorMessage += t('agencyPhoneBookContactsList.and', {
            defaultValue: ' and',
          }) as string;
        }
        if (index > 0 && index + 1 !== errors.length) {
          errorMessage += ',';
        }
        errorMessage += ` ${error.label}`;
      });
      errorMessage += t('agencyPhoneBookContactsList.presentForContact', {
        defaultValue: ' present for contact.',
      }) as string;

      if (errorMessage) {
        showMessage({
          message: t('agencyPhoneBookContactsList.invalidDataTitle', {
            defaultValue: 'Invalid Data',
          }),
          description: errorMessage,
          type: 'warning',
          autoHide: false,
          hideOnPress: true,
          duration: 5000,
        });
        return false;
      }
    }
    if (!_appUserContact.firstName || !_appUserContact.lastName || !phone || !email) {
      showMessage({
        message: t('agencyPhoneBookContactsList.invalidDataTitle', {
          defaultValue: 'Invalid Data',
        }),
        description: t('agencyPhoneBookContactsList.invalidDataDescription', {
          defaultValue:
            'There must be both valid international phone numbers with a country code (e.g. +49) and email addresses present for each contact.',
        }) as string,
        type: 'warning',
        autoHide: false,
        hideOnPress: true,
        duration: 5000,
      });
      return false;
    }

    const expoContact: ExpoContactInput = {
      ..._appUserContact,
      addresses: _appUserContact.addresses,
      birthday: _appUserContact.birthday,
      company: _appUserContact.company,
      contactType: 'COURIER',
      dates: _appUserContact.dates as ExpoContactDateInput[],
      department: _appUserContact.department,
      // For some reason, Android returns an integer value for isPrimary, despite it being typed as boolean.
      phoneNumbers: _appUserContact.phoneNumbers?.map((e) => {
        if (e.isPrimary !== undefined) {
          return { ...e, isPrimary: (e.isPrimary as unknown as number) !== 0 };
        }
        return e;
      }),
      // For some reason, Android returns an integer value for isPrimary, despite it being typed as boolean.
      emails: _appUserContact.emails?.map((e) => {
        if (e.isPrimary !== undefined) {
          return {
            ...e,
            label: e.label || '',
            isPrimary: (e.isPrimary as unknown as number) !== 0,
          };
        }
        return { ...e, label: e.label || '' };
      }) as ExpoContactEmailInput[],
      firstName:
        _appUserContact.firstName +
        (_appUserContact.middleName ? ' ' + _appUserContact.middleName : ''),
      id: _appUserContact.id,
      image: _appUserContact.image,
      imageAvailable: _appUserContact.imageAvailable,
      instantMessageAddresses: _appUserContact.instantMessageAddresses,
      jobTitle: _appUserContact.jobTitle,
      lastName: _appUserContact.lastName,
      nonGregorianBirthday: _appUserContact.nonGregorianBirthday as ExpoContactDateInput,
    };

    let firstNames: string = _appUserContact.firstName;
    if (_appUserContact.middleName && _appUserContact.middleName !== '') {
      firstNames += appUserContact.middleName;
    }

    // eslint-disable-next-line no-useless-escape
    const regex = /[^\+^\d]+/gi; // Replacing all non numeric characters except for +

    const variables: CreateOrUpdateCourierUserContactVariables = {
      inviteUserContact,
      userContactInput: {
        expoContactId: appUserContact.id,
        phone: phone.replaceAll(regex, ''), // Remove all non-numeric characters except for the + in the beginning.
        phoneUnformatted: phone,
        email,
        firstNames,
        lastName: _appUserContact.lastName,
        expoContact,
      },
    };

    try {
      const result = await createOrUpdateUserContactMutation({
        variables,
        context: {
          cb: (err: any) => {
            const error = err.graphQLErrors?.[0] as any;
            let message = t('courierPhoneBookContactsList.error', { defaultValue: 'Error' });
            let description = t('courierPhoneBookContactsList.failedInvitationMessage', {
              defaultValue: 'An error occurred while trying to import your contact.',
            });

            if (error?.extensions?.internalCode === 44034) {
              message = t('error.courierAlreadyInvitedOrConnected.title', {
                defaultValue: 'Already invited or connected',
              });
              description = t('error.courierAlreadyInvitedOrConnected.text', {
                defaultValue:
                  'User with email "{{email}}" is already invited or connected. Because of that, user cannot be invited. If you believe this is not the case, please reach out to {{supportEmail}} for more information',
                supportEmail: PRIOJET_SUPPORT_EMAIL,
                email,
              });
            }

            hideConfirmModal();
            showMessage({
              message,
              description,
              type: 'danger',
              autoHide: true,
              hideOnPress: true,
              duration: 8000,
            });
          },
        },
      });
      if (result?.data) {
        refetch();

        showMessage({
          message: t('agencyPhoneBookContactsList.success', {
            defaultValue: 'Success',
          }),
          description: t('agencyPhonebookContactsList.successfulInvitationMessage', {
            firstNames,
            lastName: appUserContact.lastName,
            phone,
            email: email || 'no email',
            defaultValue:
              '{{firstNames}} {{lastName}} (Phone: {{phone}}, Email: {{email}}) successfully invited.',
          }) as string,
          type: 'success',
          autoHide: true,
          hideOnPress: true,
          duration: 8000,
        });
        return true;
      }
      // eslint-disable-next-line no-empty
    } catch {
      return false;
    }
    return false;
  };

  const onSelectPhoneEmail = async (index: IndexPath) => {
    let message = t('agencyPhoneBookContactsList.onSelectPhoneOnlyMessage', {
      item,
      phoneNumber: phoneEmailPermutations[index.row].phone.number,
      defaultValue:
        'Do you want to invite the Contact named {{ item.name }} with phone number "{{phoneNumber}}"',
    });
    if (phoneEmailPermutations[index.row].email?.email) {
      message += t('agencyPhoneBookContactsList.onSelectWithEmailMessage', {
        email: phoneEmailPermutations[index.row].email?.email,
        defaultValue: ' and email "{{email}}"?',
      });
    } else {
      message += '?';
    }

    setOverflowMenuVisible(false);
    showConfirmModal({
      confirmButtonStatus: 'primary',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.Yes', { defaultValue: 'Yes' }) as string,
      title: t('agencyPhoneBookContactsList.Import ', { defaultValue: 'Import' }) + ` ${item.name}`,
      text: message,
      cancelButtonText: t('common.No', { defaultValue: 'No' }) as string,

      onConfirmPress: async () => {
        try {
          const success = await importUserContact({
            appUserContact: item,
            selectedPhoneEmail: phoneEmailPermutations[index.row],
            inviteUserContact: true,
          });
          if (success) {
            item.state = 'invited';
            setOverflowMenuVisible(false);
          }
          return true;
        } catch (e) {
          setOverflowMenuVisible(false);
          return false;
        }
      },
      visible: true,
    });
  };

  const invalidDataAlert = async () => {
    showMessage({
      message: t('agencyPhoneBookContactsList.invalidDataTitle', {
        defaultValue: 'Invalid Data',
      }),
      description: t('agencyPhoneBookContactsList.invalidDataDescription', {
        defaultValue:
          'There must be both valid international phone numbers with a country code (e.g. +49) and email addresses present for each contact.',
      }) as string,
      type: 'warning',
      autoHide: false,
      hideOnPress: true,
      duration: 5000,
    });
  };

  const invalidPhoneNumbersAlert = async () => {
    let number =
      phoneNumbersWithoutCountryCode &&
      phoneNumbersWithoutCountryCode.map((pn) => pn.label + ': ' + pn.number);
    showMessage({
      message: t('agencyPhoneBookContactsList.invalidPhoneNumberFormatTitle', {
        defaultValue: 'Invalid phone number format',
      }),
      description: t('agencyPhoneBookContactsList.invalidPhoneNumberFormatDescription', {
        item,
        number: number?.join('\n') || '-',
        defaultValue:
          'Some phone numbers of the contact {{item.name}} do not have the right format. Please add the country code to the phone number.\n------------\n{{number}}\n------------\nThe following German number 01234567890 would become +491234567890 (Remove any leading 0 and add + plus country code).\n\nPlease go to your phone book or contact app and change the phone numbers there.  ',
      }) as string,
      type: 'warning',
      autoHide: false,
      hideOnPress: true,
      duration: 5000,
    });
  };

  const handleMenuItemSelect = async (index: IndexPath) => {
    await onSelectPhoneEmail(index);
  };

  const renderBtn = () => {
    if (item.state === 'invited') {
      return (
        <Text
          style={[
            styles.alreadyInvitedText,
            isVerySmallDevice && styles.itemContentMarginLeft,
            !isVerySmallDevice && styles.alreadyInvitedMaxWidth,
          ]}
        >
          {
            t('common.alreadyImportedAndInvited', {
              defaultValue: 'Already imported and invited',
            }) as string
          }
        </Text>
      );
    }
    return (
      <Button size="tiny" onPress={() => setOverflowMenuVisible(true)} style={styles.btn}>
        {
          t('common.importAndInviteCourierContact', {
            defaultValue: 'Import and invite \ncourier contact',
          }) as string
        }
      </Button>
    );
  };

  if (phoneNumbersWithoutCountryCode && phoneNumbersWithoutCountryCode.length > 0) {
    return (
      <Button size="tiny" onPress={invalidPhoneNumbersAlert} style={styles.btn}>
        {
          t('common.importAndInviteCourierContact', {
            defaultValue: 'Import and invite \ncourier contact',
          }) as string
        }
      </Button>
    );
  }
  if (!phoneEmailPermutations?.length) {
    return (
      <Button size="tiny" onPress={invalidDataAlert} style={styles.btn}>
        {
          t('common.importAndInviteCourierContact', {
            defaultValue: 'Import and invite \ncourier contact',
          }) as string
        }
      </Button>
    );
  }
  return (
    <OverflowMenu
      style={styles.overflowMenu}
      anchor={renderBtn}
      visible={overflowMenuVisible}
      onSelect={handleMenuItemSelect}
      onBackdropPress={() => setOverflowMenuVisible(false)}
    >
      {phoneEmailPermutations.map((phoneEmail) => {
        return (
          <MenuItem
            key={phoneEmail.phone.id + phoneEmail.email?.id}
            title={
              phoneEmail.email?.email
                ? phoneEmail.phone.number + '\n' + phoneEmail.email.email
                : phoneEmail.phone.number
            }
          />
        );
      })}
    </OverflowMenu>
  );
};

const themedStyle = StyleService.create({
  overflowMenu: {
    maxWidth: 400,
    width: 300,
    height: 'auto',
  },
  btn: {
    width: 120,
  },
  loading: {
    color: 'text-basic-color',
  },
  itemContentMarginLeft: {
    marginTop: 10,
    marginLeft: 44,
  },
  alreadyInvitedText: {
    fontSize: 12,
    paddingLeft: 15,
  },
  alreadyInvitedMaxWidth: {
    maxWidth: 120,
    textAlign: 'right',
  },
});
