import { Platform } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as FileSystem from 'expo-file-system';

import { GLOBAL_CONSTANTS } from '../../../../globals';

export const getStorageDataAgencyJobPlanning = async (): Promise<{
  path: string;
  storage: { [key: string]: any };
}> => {
  let path = '';
  let storage: { [key: string]: any } = {};
  if (Platform.OS === 'android') {
    path = `${FileSystem.cacheDirectory}${GLOBAL_CONSTANTS.AGENCY_JOB_TRACK_AND_TRACING}.json`;
    try {
      let res = await FileSystem.readAsStringAsync(path);
      if (res) {
        const resObject = JSON.parse(res);
        if (Object.keys(resObject).length) {
          storage = resObject;
        }
      }
      // eslint-disable-next-line no-empty
    } catch {}
  } else {
    const storageData = await AsyncStorage.getItem(GLOBAL_CONSTANTS.AGENCY_JOB_TRACK_AND_TRACING);
    if (storageData) {
      storage = JSON.parse(storageData);
    }
  }

  return { path, storage };
};

export const saveStorageDataAgencyJobPlanning = async (storage: string) => {
  let path = '';

  if (Platform.OS === 'android') {
    path = `${FileSystem.cacheDirectory}${GLOBAL_CONSTANTS.AGENCY_JOB_TRACK_AND_TRACING}.json`;
    try {
      await FileSystem.writeAsStringAsync(path, storage);
      // eslint-disable-next-line no-empty
    } catch {}
  } else {
    await AsyncStorage.setItem(GLOBAL_CONSTANTS.AGENCY_JOB_TRACK_AND_TRACING, storage);
  }
};
