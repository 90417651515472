import React, { useEffect, useState } from 'react';
import { View, ViewStyle } from 'react-native';

import { ApolloError } from '@apollo/client';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

type ErrorVariables = {
  title: string;
  description: string;
  code?: string;
  data?: object;
  internalCode?: number;
  httpStatus?: number;
};

export const TranslatedErrorText = ({
  apolloError,
  variables,
  showHttpStatus = false,
  showCode = false,
  showInternalCode = true,
  showData = true,
  errorViewStyle,
}: {
  apolloError: ApolloError | undefined;
  variables: Record<string, string | DateTime | number | boolean>;
  showHttpStatus?: boolean;
  showCode?: boolean;
  showInternalCode?: boolean;
  showData?: boolean;
  errorViewStyle?: ViewStyle;
}) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();

  const [errorVariablesList, setErrorVariablesList] = useState<ErrorVariables[]>([]);

  useEffect(() => {
    if (apolloError && apolloError.graphQLErrors.length > 0) {
      const _errorVariables = [];
      for (const graphQLError of apolloError.graphQLErrors) {
        _errorVariables.push({
          title: t('error.internalCode.' + graphQLError.extensions.internalCode + '.message', {
            defaultValue: graphQLError.extensions.message,
            activationCode: variables.activationCode,
          }) as string,
          description: t(
            'error.internalCode.' + graphQLError.extensions.internalCode + '.description',
            {
              defaultValue: graphQLError.extensions.detail,
              activationCode: variables.activationCode,
            },
          ) as string,
          code: graphQLError.extensions.code as string,
          data: (graphQLError.extensions?.data as object) || undefined,
          internalCode: graphQLError.extensions.internalCode as number,
          httpStatus: graphQLError.extensions.httpStatus as number,
        });
      }
      setErrorVariablesList(_errorVariables);
    } else if (apolloError && apolloError.graphQLErrors.length < 1) {
      setErrorVariablesList([
        {
          title: apolloError.name,
          description: apolloError.message,
        },
      ]);
    } else {
      setErrorVariablesList([]);
    }
  }, [apolloError]);

  if (errorVariablesList.length < 1) {
    return <></>;
  }

  return (
    <View style={[styles.errorView, errorViewStyle]}>
      {errorVariablesList.map((errorVariables) => (
        <View key={errorVariables.internalCode}>
          <Text status="danger" style={styles.errorMessageText}>
            {errorVariables.title}
          </Text>
          <Text category="label" style={styles.errorDescriptionText}>
            {errorVariables.description}
          </Text>
          {showCode && errorVariables.code !== undefined && (
            <Text category="label">CODE: {errorVariables.code}</Text>
          )}
          {showInternalCode && errorVariables.internalCode !== undefined && (
            <Text category="label">INTERNAL CODE: {errorVariables.internalCode}</Text>
          )}
          {showHttpStatus && errorVariables.httpStatus !== undefined && (
            <Text category="label">HTTP STATUS: {errorVariables.httpStatus}</Text>
          )}
          {showData &&
            errorVariables.data !== undefined &&
            Object.keys(errorVariables.data).length > 0 && (
              <Text category="label">CODE: {JSON.stringify(errorVariables.data)}</Text>
            )}
        </View>
      ))}
    </View>
  );
};

const themedStyles = StyleService.create({
  errorView: { marginVertical: 10 },
  addAgencyConnectionScreenView: {
    paddingHorizontal: 20,
    textAlign: 'center',
    alignItems: 'center',
    contentAlign: 'center',
  },
  errorDescriptionText: {
    marginBottom: 10,
  },
  boldText: {
    fontFamily: 'Lato_700Bold',
  },
  errorMessageText: {
    marginVertical: 5,
  },
});
