import React from 'react';
import { View } from 'react-native';

import { StyleService, Text } from '@ui-kitten/components';

const MenuCategory = ({ title }: { title: string }): JSX.Element => {
  return (
    <View style={themedStyles.container}>
      <Text selectable={true} style={themedStyles.title} category="s1">
        {title}
      </Text>
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 15,
    paddingTop: 30,
    paddingBottom: 10,
  },
  title: {
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
  },
});

export { MenuCategory };
