import React from 'react';

import { Card, Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../../common/style';
import { OrganizationInvitation } from '../../../../generated-graphql-types';
import { EmailTextWithContextMenu } from '../../common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../common.phone-text-with-context-menu';
import { AgencyEmployeeInvitationCardDescription } from '../AgencyEmployeeInvitationCardDescription';
import { OrganizationInvitationCardItemHeader } from '../OrganizationInvitationCardHeader';

export const AgencyEmployeeOrganizationInvitationCard = ({
  organizationInvitation,
  onCancelInvitationPress,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
  onCancelInvitationPress: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  return (
    <Card
      key={organizationInvitation.id}
      style={styles.card}
      header={(headerProps: any) => (
        <OrganizationInvitationCardItemHeader
          {...headerProps}
          organizationInvitation={organizationInvitation}
        />
      )}
    >
      {organizationInvitation.organizationContact?.firstNames && (
        <>
          <Text style={globalStyle.fontLatoBold}>
            {t('contactDetails', { defaultValue: 'Contact details' }) as string}:{' '}
          </Text>
          <Text selectable={true} style={styles.organizationName}>
            {`${organizationInvitation.organizationContact?.firstNames} ${organizationInvitation.organizationContact?.lastName}`}
          </Text>
        </>
      )}
      {!!organizationInvitation.organizationContact?.email && (
        <EmailTextWithContextMenu
          style={globalStyle.marginTop2}
          email={organizationInvitation.organizationContact.email}
        />
      )}
      {!!organizationInvitation.organizationContact?.phone && (
        <PhoneTextWithContextMenu
          style={globalStyle.marginTop2}
          phone={organizationInvitation.organizationContact.phone}
        />
      )}
      <Divider style={styles.divider} />
      <AgencyEmployeeInvitationCardDescription
        onCancelInvitationPress={onCancelInvitationPress}
        organizationInvitation={organizationInvitation}
      />
    </Card>
  );
};

const themedStyles = StyleService.create({
  card: {
    marginHorizontal: 10,
    marginTop: 10,
    padding: 10,
    backgroundColor: 'background-basic-color-2',
  },
  header: {
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  empty: {
    padding: 20,
    textAlign: 'center',
  },
  divider: {
    marginVertical: 5,
  },
  organizationName: {
    paddingTop: 5,
    fontWeight: 'bold',
  },
});
