import React from 'react';
import { Platform } from 'react-native';

import { RouteProp } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';

import { RootStackParamList } from './app.navigator';
import i18n from '../i18n/i18n';
import { AgencyCourierJobsScreen } from '../screens/agency/agency.courier-jobs.screen';
import { AgencyAddOrDisableCourierJobScreen } from '../screens/agency/AgencyAddOrDisableCourierJobScreen';
import { AgencyAgencyJobPlanningScreen } from '../screens/agency/AgencyAgencyJobPlanningScreen';
import { AgencyCourierDocumentsMenuScreen } from '../screens/agency/AgencyCourierDocumentsMenuScreen';
import { AgencyCourierJobScreen } from '../screens/agency/AgencyCourierJobScreen';
import { AgencyCourierUserDocumentsScreen } from '../screens/agency/AgencyCourierUserDocumentsScreen';
import { AgencyCourierUserProfileScreen } from '../screens/agency/AgencyCourierUserProfile';
import { AgencyCreateOrUpdateJobScreen } from '../screens/agency/AgencyCreateOrUpdateJobScreen';
import { AgencyHomeScreen } from '../screens/agency/AgencyHomeScreen';
import { AgencyJobsScreen } from '../screens/agency/AgencyJobsScreen';
import { AgencyOBCAvailabilityMapScreen } from '../screens/agency/AgencyOBCAvailabilityMapScreen';
import { AgencyReadCourierUserDocumentScreen } from '../screens/agency/AgencyReadCourierUserDocumentScreen';
import { CommonActivityScreen } from '../screens/common/common.activity.screen';
import { CommonAgencyJobChatScreen } from '../screens/common/CommonAgencyJobChatScreen';
import { CommonJobAgencyJobChatScreen } from '../screens/common/CommonCourierJobAgencyJobChatScreen';
import { CourierBackgroundDocumentsScreen } from '../screens/courier/courier.background-documents.screen';
import { CourierFrequentFlyerDocumentsScreen } from '../screens/courier/courier.frequent-flyer-documents.screen';
import { CourierHealthTestReportsScreen } from '../screens/courier/courier.health-test-reports.screen';
import { CourierOtherDocumentsScreen } from '../screens/courier/courier.other-documents.screen';
import { CourierUserTravelPermitsScreen } from '../screens/courier/courier.user-travel-permits.screen';
import { CourierVaccinationCertificatesScreen } from '../screens/courier/courier.vaccination-certificates.screen';

const StackNavigator = createStackNavigator<RootStackParamList>();

const AgencyHomeNavigator = ({ route }: { route: RouteProp<RootStackParamList> }) => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="AgencyHomeScreen"
    >
      <StackNavigator.Screen
        name="AgencyHomeScreen"
        component={AgencyHomeScreen}
        options={{ title: 'Home' }}
      />
      <StackNavigator.Screen name="AgencyJobsScreen" component={AgencyJobsScreen} />
      <StackNavigator.Screen
        name="AgencyCourierJobsScreen"
        component={AgencyCourierJobsScreen}
        initialParams={route.params}
      />
      <StackNavigator.Screen
        name="AgencyCourierDocumentsMenuScreen"
        component={AgencyCourierDocumentsMenuScreen}
      />
      <StackNavigator.Screen
        name="AgencyReadCourierUserDocumentScreen"
        component={AgencyReadCourierUserDocumentScreen}
      />
      <StackNavigator.Screen
        name="AgencyCourierUserDocumentsScreen"
        component={AgencyCourierUserDocumentsScreen}
      />
      <StackNavigator.Screen
        name="CourierUserTravelPermitsScreen"
        component={CourierUserTravelPermitsScreen}
      />
      <StackNavigator.Screen
        name="CourierFrequentFlyerDocumentsScreen"
        component={CourierFrequentFlyerDocumentsScreen}
      />
      <StackNavigator.Screen
        name="CourierVaccinationCertificatesScreen"
        component={CourierVaccinationCertificatesScreen}
      />
      <StackNavigator.Screen
        name="CourierHealthTestReportsScreen"
        component={CourierHealthTestReportsScreen}
      />
      <StackNavigator.Screen
        name="CourierBackgroundDocumentsScreen"
        component={CourierBackgroundDocumentsScreen}
      />
      <StackNavigator.Screen
        name="CourierOtherDocumentsScreen"
        component={CourierOtherDocumentsScreen}
      />
      <StackNavigator.Screen
        name="AddOrDisableCourierJobScreen"
        component={AgencyAddOrDisableCourierJobScreen}
      />
      <StackNavigator.Screen
        name="AgencyCreateJobScreen"
        component={AgencyCreateOrUpdateJobScreen}
      />
      <StackNavigator.Screen
        name="AgencyUpdateJobScreen"
        component={AgencyCreateOrUpdateJobScreen}
      />
      <StackNavigator.Screen
        name="AgencyAgencyJobPlanningScreen"
        component={AgencyAgencyJobPlanningScreen}
      />
      <StackNavigator.Screen name="AgencyViewCourierJobScreen" component={AgencyCourierJobScreen} />
      <StackNavigator.Screen
        name="AgencyCourierUserProfileScreen"
        component={AgencyCourierUserProfileScreen}
      />
      <StackNavigator.Screen
        name="AgencyOBCAvailabilityMapScreen"
        component={AgencyOBCAvailabilityMapScreen}
      />
      <StackNavigator.Screen
        name="AgencyCourierJobAgencyJobChatScreen"
        component={CommonJobAgencyJobChatScreen}
      />
      <StackNavigator.Screen
        name="AgencyAgencyJobChatScreen"
        component={CommonAgencyJobChatScreen}
      />

      <StackNavigator.Group
        screenOptions={() => ({
          cardShadowEnabled: true,
          headerShown: false,
          cardOverlayEnabled: true,
          presentation: 'transparentModal',
          animationEnabled: true,
        })}
      >
        <StackNavigator.Screen
          name="CommonActivityScreen"
          component={CommonActivityScreen}
          options={{
            ...(Platform.OS === 'web'
              ? TransitionPresets.ScaleFromCenterAndroid
              : TransitionPresets.ModalSlideFromBottomIOS),
            title: i18n.t('activityNotification.activityAndNotifications', {
              defaultValue: 'Activity & Notifications',
            }) as string,
          }}
        />
      </StackNavigator.Group>
    </StackNavigator.Navigator>
  );
};

export { AgencyHomeNavigator };
