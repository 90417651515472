import React from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import {
  Button,
  Card,
  Icon,
  IconProps,
  List,
  ListItem,
  StyleService,
  Text,
} from '@ui-kitten/components';

import { CardItemFooter } from './courier-connected-agencies-card-item-footer.component';
import { globalStyle } from '../../../common/style';
import { ConnectedOrganization, OrganizationUser } from '../../../generated-graphql-types';
import { RootStackParamList } from '../../../navigation/app.navigator';
import { EmailTextWithContextMenu } from '../../common/common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../common/common.phone-text-with-context-menu';

const OrganizationUsersList = ({ data }: { data: Array<Partial<OrganizationUser>> }) => {
  // const renderItemAccessory = (props: IconProps) => <Button size="tiny">PROFILE</Button>;

  const renderItemIcon = (props: IconProps) => <Icon {...props} name="person" />;

  const renderItem = ({ item, index }: { item: Partial<OrganizationUser>; index: number }) => (
    <ListItem
      key={index}
      title={`${item.user?.firstNames} ${item.user?.lastName} (${item.roleType})`}
      description={
        <View style={[globalStyle.flex1, globalStyle.flexRow]}>
          <EmailTextWithContextMenu email={item.user?.email as string | undefined} />
          <PhoneTextWithContextMenu phone={item.user?.phone as string | undefined} />
        </View>
      }
      accessoryLeft={renderItemIcon}
      // accessoryRight={renderItemAccessory}
    />
  );

  return (
    <List
      style={styles.organizationUsersList}
      data={data}
      renderItem={renderItem}
      contentContainerStyle={styles.organizationUsersListItem}
      ListEmptyComponent={
        <View>
          <Text>This Organization does not have any Users.</Text>
        </View>
      }
    />
  );
};

export const CourierConnectedAgenciesCardItem = ({
  connectedOrganization,
  showOrganizationOwners = false,
}: {
  connectedOrganization: ConnectedOrganization;
  showOrganizationOwners?: boolean;
}) => {
  const navigation = useNavigation() as StackNavigationProp<RootStackParamList>;

  return (
    <Card
      key={connectedOrganization.organization.id}
      style={styles.card}
      footer={(footerProps: any) => (
        <CardItemFooter {...footerProps} connectedOrganization={connectedOrganization} />
      )}
      onPress={() =>
        navigation.navigate('CourierAgencyProfileScreen', {
          agencyOrganizationId: connectedOrganization.organization.id,
          previousScreenName: 'CourierConnectedAgenciesScreen',
        })
      }
    >
      <View style={styles.contentWrapper}>
        <Text selectable={true} category="h6" style={globalStyle.marginBottom5}>
          {connectedOrganization.organization.name}
        </Text>
        <EmailTextWithContextMenu
          email={connectedOrganization.organization.email as string | undefined}
        />
        <PhoneTextWithContextMenu
          phone={connectedOrganization.organization.phone as string | undefined}
        />
        {!!showOrganizationOwners && (
          <OrganizationUsersList
            data={connectedOrganization.organization.organizationUserOwners || []}
          />
        )}
        <View style={styles.actionButtonsContainer}>
          <View style={styles.actionButtonsWrapperRight}>
            <Button
              size="tiny"
              appearance="filled"
              style={styles.actionButton}
              onPress={() =>
                navigation.navigate('CourierAgencyProfileScreen', {
                  agencyOrganizationId: connectedOrganization.organization.id,
                  previousScreenName: 'CourierConnectedAgenciesScreen',
                })
              }
            >
              Agency Profile
            </Button>
          </View>
        </View>
      </View>
    </Card>
  );
};

const styles = StyleService.create({
  container: {
    flex: 1,
  },
  organizationUsersList: {
    marginBottom: 10,
  },
  organizationUsersListItem: {},
  card: {
    margin: 10,
    padding: 10,
  },
  contentWrapper: {
    padding: 10,
    paddingBottom: 20,
  },
  actionButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'space-between',
  },
  actionButtonsWrapperRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: 10,
  },
});
