import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  bottomInfoLayerText: {
    textAlign: 'center',
    color: 'color-basic-transparent-600',
  },
  bottomInfoLayer: {
    paddingVertical: 50,
    alignItems: 'center',
    alignContent: 'center',
    flex: 1,
    backgroundColor: 'transparent',
  },
  menu: {
    flex: 1,
    margin: 0,
    backgroundColor: 'transparent',
  },
  otherMenuItems: {
    color: 'color-primary-default',
    textAlign: 'center',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
  },
});
