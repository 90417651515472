export default {
  translation: {
    '{{screenName}} is coming soon': '{{screenName}} estará disponible pronto.',
    available: 'disponible',
    unavailable: 'no disponible',
    'Set available': 'Conjunto disponible',
    'Set unavailable': 'Establecer no disponible',
    'Set availability dates': 'Establecer fechas de disponibilidad',
    'Change language': 'Cambiar idioma',
    'Already imported and invited': 'Ya importados\ne invitados',
    'Import and invite \ncourier contact': 'Importar e invitar a \nun contacto como mensajero',
    SELECT_LANGUAGE_LABEL: 'Cambiar el idioma de la aplicación aquí.',
    SELECT_LANGUAGE_CAPTION: 'El idioma predeterminado se basa en la configuración del sistema.',
    German: 'alemán',
    English: 'inglés',
    French: 'francés',
    Spanish: 'español',
    'Toggle theme': 'Alternar colorante',
    'Active theme': 'Coloración activa',
    dark: 'oscuro',
    light: 'brillante',
    'No Email': 'Sin Email',
    Playground: 'Patio de recreo',
    'Sign in': 'Sign in',
    'Sign in with another account': 'Sign in with another account',
    'Sign up': 'Sign up',
    'Sign up as a Courier': 'Sign up as a Courier',
    'Your accounts': 'Your accounts',
    'No phone number provided': 'No se proporciona ningún número de teléfono',
    SIGN_UP_INFO_TEXT: 'A window will open where you can sign up with your credentials.',
    SIGN_IN_INFO_TEXT: 'A window will open where you have to enter your credentials to sign in.',
    activation: {
      enterActivationCode: 'Enter 8 digit activation code',
      activationScreenText:
        "To use the PRIOjet App, you need to activate your account. Please enter the 8-digit activation code that you've obtained in the form below.",
      activationScreenAgencyText:
        "To use the PRIOjet App, you need to activate your account. Please enter the 8-digit activation code that you've obtained from your agency in the form below.",
      activationScreenPriojetText:
        "To use the PRIOjet App, you need to activate your account. Please enter the 8-digit activation code that you've obtained from PRIOjet in the form below.",
      activationCodeUsed:
        'The activation code {{ activationCode }} was already used. Please ask for a new one. Reach out to support@priojet.com',
      activationCodeInvalidError:
        'The given activation code is invalid or cannot be used to join this organization',
      errorMessage: 'Activation error',
      errorDescription:
        'Something went wrong with account activation. Please try again or reach out to support@priojet.com',
      activationSuccess: 'Activation success',
      activationSuccessDescription: 'Account activated successfully',
    },
    ERROR: {
      INTERNAL_CODE: {
        40002: {
          message: 'Activation code error',
          description:
            'Invited organization type does not match activation code. Please ask for a new one or reach out to support@priojet.com',
        },
        44009: {
          message: 'Activation error',
          description:
            'The given activation code is invalid or cannot be used to join this organization',
        },
        44011: {
          message: 'Activation code already used',
          description:
            'The activation code was already used. Please ask for a new one. Reach out to support@priojet.com',
        },
      },
    },
    'Copied!': '¡Copiado!',
    UPDATED_AT_TEXT: 'Última modificación <TimeAgoPopover></TimeAgoPopover>.',
    CONNECTED_AT_BY_USER_TEXT: 'Conectado $t(ACTION_AT_BY_USER_TEXT) ',
    CONNECTED_AT_BY_USER_TEXT_TIME_AGO: 'Conectado $t(ACTION_AT_BY_USER_TEXT_TIME_AGO) ',
    CREATED_AT_BY_USER_TEXT: 'Creado $t(ACTION_AT_BY_USER_TEXT) ',
    ACTION_AT_BY_USER_TEXT:
      '<TimeAgoPopover></TimeAgoPopover> \npor {{firstNames}} {{lastName}} ({{email}}).',
    ACTION_AT_BY_USER_TEXT_TIME_AGO: '<TimeAgoPopover></TimeAgoPopover>.',
    INVITATIONS: {
      SUCCESSFUL_INVITATION_MESSAGE:
        'Invitada correctamente {{firstNames}} {{lastName}} (teléfono: {{phone}}, Email: {{email}}).',
    },
    JOBS: {
      NUM_OF_COURIERS_CONNECTED_TO_AGENCY_JOB_one:
        'There is currently only one Courier selected for this Job.',
      NUM_OF_COURIERS_CONNECTED_TO_AGENCY_JOB_other:
        'There are currently {{count}} Couriers selected for this Job.',
      manageCouriers_zero: 'Manage Couriers',
      manageCouriers_other: 'Manage Couriers ({{count}})',
      priojetJobIdentifier: 'PRIOjet Job Identifier',
      jobDescription: 'Job Description',
      additionalDetailsAndJobDescription: 'Additional details and Job description',
      jobTitleRefNumber: 'Job title/Ref. Nr.',
      pickupLocation: 'Pickup Location',
      pickupTime: 'Pickup Time',
      pickupTime_PAST: 'Pickup Time',
      pickupAddressDescription: 'Pickup Address Description',
      dropOffLocation: 'Drop-off Location',
      dropOffTime: 'Drop-off Time',
      dropOffTime_PAST: 'Drop-off Time',
      dropOffAddressDescription: 'Drop-off / Delivery Address Description',
      startAirport: 'Start Airport',
      endAirport: 'End Airport',
      description: 'Description',
      descriptionOfItems: 'Description of Items',
      numberOfItems: 'Number of Items',
      weightOfItems: 'Weight of Items',
      typeOfDelivery: 'Type of Delivery',
      typeOfTransportJob: 'Type of Transport job',
      jobStatusType: 'Job Status Type',
      jobType: 'Job Type',
      createdByOrganization: 'Created By Organization',
      createdByUser: 'Created By User',
      transportedGoodsContentType: 'Transported Goods Content Type',
      startJob: 'Start Job',
      confirmAndFinishJob: 'Confirm and finish Job',
      cancelJob: 'Cancel Job',
      resetJobAsNew: 'Reset Job as NEW',
      addOrRemoveCouriers: 'Add/Remove Couriers',
      viewOrEditJob: 'View/Edit Job',
      pending: 'pending',
      requested: 'requested',
      accepted: 'accepted',
      new: 'new',
      running: 'running',
      past: 'past',
      declined: 'declined',
      returnTimeAtEndAirport: 'Return time at end airport',
      returnTimeAtStartAirport: 'Return time at start airport',
      checkInAtEndAirport: 'Check-in at end airport',
      checkInAtStartAirport: 'Check-in at start airport',
      startJobConfirmationPopupText:
        'Do you really want to start the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      resetJobAsNewConfirmationPopupText:
        'Do you really want to set the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} back to New again? This will notify previously selected couriers.',
      confirmAndFinishJobConfirmationPopupText:
        'Do you really want to confirm and finish the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      cancelJobConfirmationPopupText:
        'Do you really want to cancel the Job {{priojetJobIdentifier}} to {{dropOffLocationName}} and notify the accepted Couriers?',
      doorToDoor: 'Door to Door',
      doorToAirport: 'Door to Airport',
      airportToDoor: 'Airport to Door',
      airportToAirport: 'Airport to Airport',
    },
    ORGANIZATION_INVITATIONS: {
      SHARING_ITEM:
        'Compartido <TimeAgoPopover></TimeAgoPopover> en {{language}} a través de {{appName}} ({{platform}} / {{platformVersion}}) por {{firstNames}} {{lastName}} {{email}}.',
      ACTIVATION_CODE_SHARE_MESSAGE_TITLE: 'Le han invitado a unirse a la plataforma PRIOjet.',
      ACTIVATION_CODE_SHARE_MESSAGE: `¡Hola!

Nos complace poder invitarlo a la plataforma PRIOjet (iOS, Android, Web).
Regístrese utilizando la aplicación de iOS (https://apps.apple.com/kn/app/priojet-logistics/id1540437650 ), la aplicación de Android (https://play.google.com/store/apps/details?id=com.priojet.app ) oa través de https://app.priojet.com y use el código

{{activationCode}}

para activar su cuenta después de un registro exitoso.
Si tiene preguntas, comuníquese con support@priojet.com.

¡Feliz de tenerte a bordo!
Su equipo PRIOjet.`,
      COPIED_ACTIVATION_CODE_CLIPBOARD_MESSAGE:
        'Se copió el código de activación {{activationCode}} en el portapapeles.',
    },
    MENU_ITEMS: {
      'App Settings': 'Ajustes de Aplicacion',
      Home: 'Home',
      Availabilities: 'Disponibilidades',
      Profile: 'Profile',
      Jobs: 'Trabajos',
    },
    LANGUAGES: {
      en: '$t(English)',
      de: '$t(German)',
      fr: '$t(French)',
      es: '$t(Spanish)',
    },
    Email: 'Email',
    'Phone number': 'Phone number',
    chatDisabledAgencyDeleted:
      'New messages cannot be created because connected agency deleted account.',
    chatDisabledCourierDeleted:
      'New messages cannot be created because connected courier deleted account.',
    badInput: 'Bad input',
    appUpdate: {
      title: 'Time to Update',
      textMandatory:
        'We added a lot of features and some important bug fixes to make your experience as smooth as possible.',
      text: 'We added a lot of features and some important bug fixes to make your experience as smooth as possible.',
      update: 'Update',
      notNow: 'Not now',
    },
    ACTIVATION_CODE_SHARE_MESSAGE_TITLE: 'You have been invited to join the PRIOjet platform.',
    ACTIVATION_CODE_SHARE_MESSAGE:
      "Hello!\n\n      We're are pleased to be able to invite you to the PRIOjet platform (iOS, Android, Web).\n      Please signup using the iOS app ( https://apps.apple.com/kn/app/priojet-logistics/id1540437650 ), Android app ( https://play.google.com/store/apps/details?id=com.priojet.app ) or via https://app.priojet.com and use the code\n      \n      {{activationCode}}\n      \n      to activate your account after a successful registration.\n      If you have questions, reach out to support@priojet.com.\n      \n      Happy to have you on board!\n      Your PRIOjet team.",
    COPIED_ACTIVATION_CODE_CLIPBOARD_MESSAGE:
      'Copied activation code {{activationCode}} to the clipboard.',
    SHARING_ITEM:
      'Shared <TimeAgoPopover></TimeAgoPopover> in {{language}} via {{appName}} ({{platform}} / {{platformVersion}}) by {{firstNames}} {{lastName}} ({{email}}).',
    deleteAccount: {
      confirmModal: {
        title: 'Delete account?',
        text: 'Are you sure you want to delete your account? \n\nYou will be able to re-activate your account within 30 days, after you will need to create a new one!',
        inputLabel: 'Enter DELETE to confirm account deletion.',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        inputErrorMessage: 'Confirm account deletion',
        inputErrorDescription: 'Please enter DELETE to confirm account deletion.',
      },
      deleteSuccess: {
        message: 'Account is now deleted',
        description:
          "It's unfortunate to see you go! But if you change your mind, you can contact support@priojet.com within 30 days to re-activate your account. Otherwise, the account will be permanently deleted and will require you to create and activate a new account again.",
      },
      forbiddenAction: {
        message: 'Forbidden action',
        description: 'Only Agency or Courier accounts can be deleted',
      },
      restoreAccount: {
        confirmModal: {
          title: 'Restore account',
          text: 'Are you sure you want to restore selected account?',
          confirmButtonText: 'Restore',
          cancelButtonText: 'Cancel',
        },
        restoreSuccess: {
          message: 'Account restored',
          description: 'Account successfully restored.',
        },
      },
      noDeletedAccount: 'No deleted accounts to display.',
      deletedAccountsPriojet: 'PRIOjet - Deleted accounts',
      viewAgencies: 'View Agencies',
      viewCouriers: 'View Couriers',
    },
    common: {
      agencies: 'Agencies',
      couriers: 'Couriers',
      organizationDetails: 'Organization details',
      responsibleUser: 'Responsible user',
      restore: 'Restore',
      deleted: 'Deleted',
      error: 'Error',
      somethingWentWrong: 'Something went wrong. Please try again, or contact support@priojet.com',
      FEMALE: 'Female',
      MALE: 'Male',
      OTHER: 'Other',
    },
  },
};
