import React from 'react';
import { FlatList, RefreshControl, View } from 'react-native';

import { ApolloQueryResult } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { Button, StyleService, Text } from '@ui-kitten/components';
import { t } from 'i18next';

import { AgencyCourierJobsCardItem } from './agency-courier-jobs-card-item.component';
import { globalStyle } from '../../../common/style';
import { CourierJob, CourierJobsForAgencyJobQuery, Exact } from '../../../generated-graphql-types';

type Props = {
  courierJobs: Partial<CourierJob>[] | undefined;
  agencyJobId: string;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            agencyJobId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<CourierJobsForAgencyJobQuery>>;
  filterTerm: string | undefined;
  isLoading: boolean;
  screenName:
    | 'AgencyCourierJobsPendingScreen'
    | 'AgencyCourierJobsRequestedScreen'
    | 'AgencyCourierJobsAcceptedScreen'
    | 'AgencyCourierJobsDeclinedScreen';
};

export const AgencyCourierJobsList = ({
  agencyJobId,
  courierJobs,
  refetch,
  filterTerm,
  isLoading,
  screenName,
}: Props) => {
  const navigation = useNavigation();

  const scrollEnabled = true;
  const scrollToOverflowEnabled = true;

  const renderListEmptyComponent = () => {
    if (filterTerm?.length) {
      return (
        <View style={styles.noResultsView}>
          <View style={styles.invitationText}>
            <Text style={globalStyle.textAlignCenter}>
              {
                t('jobs.noCourierJobsFoundForTerm', {
                  defaultValue: 'No Courier Jobs found for "{{filterTerm}}".',
                  filterTerm,
                }) as string
              }
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View style={styles.noResultsView}>
        <View style={styles.invitationText}>
          <Text style={globalStyle.textAlignCenter}>
            {
              t('jobs.noCouriersForThisJobAgency', {
                defaultValue: 'There are no Courier Jobs in this stage for this Agency Job.',
              }) as string
            }
          </Text>
        </View>
        <Button
          style={styles.invitationButton}
          onPress={() =>
            navigation.navigate('AddOrDisableCourierJobScreen', {
              agencyJobId: agencyJobId,
              previousScreenName: 'AgencyCourierJobsScreen',
              previousTabScreenName: screenName,
            })
          }
        >
          {t('jobs.addCouriersToThisJob', { defaultValue: 'Add Couriers to this Job.' }) as string}
        </Button>
      </View>
    );
  };

  return (
    <View
      style={[
        globalStyle.flex1,
        globalStyle.justifyContentCenter,
        globalStyle.alignItemsCenter,
        globalStyle.flexGrow1,
        globalStyle.width100Percent,
        globalStyle.marginTop10,
        globalStyle.paddingHorizontal10,
      ]}
    >
      <FlatList
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refetch} />}
        ListEmptyComponent={renderListEmptyComponent()}
        scrollEnabled={scrollEnabled}
        scrollToOverflowEnabled={scrollToOverflowEnabled}
        style={globalStyle.width100Percent}
        data={courierJobs}
        renderItem={({ item }: { item: Partial<CourierJob> }) => (
          <AgencyCourierJobsCardItem courierJob={item} refetchJob={refetch} />
        )}
      />
    </View>
  );
};

const styles = StyleService.create({
  noResultsView: {
    marginTop: 10,
    padding: 10,
    maxWidth: 800,
    width: '100%',
    alignSelf: 'center',
  },
  invitationText: {
    minHeight: 100,
    maxWidth: 260,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  invitationButton: {
    marginTop: 10,
    width: '100%',
  },
});
