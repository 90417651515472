import { useMemo, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { MUTATION_ORGANIZATION_COURIER_CATEGORIES_UPDATE } from '../../../apollo/graphql-mutations';
import { QUERY_ORGANIZATION_COURIER_CATEGORIES } from '../../../apollo/graphql-queries';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();

  const [categories, setCategories] = useState<{ [key: string]: any }>({});
  const [categoriesOriginal, setCategoriesOriginal] = useState<{ [key: string]: any }>({});

  const { loading } = useQuery(QUERY_ORGANIZATION_COURIER_CATEGORIES, {
    fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
    onCompleted: (res) => {
      const data: { [key: string]: any } = {};
      res.organizationCourierCategories.forEach((item: any) => {
        data[item.order] = { ...item };
      });
      setCategories(data);
      setCategoriesOriginal({ ...data });
    },
  });
  const [organizationCourierCategoriesUpdate, { loading: loadingSave }] = useMutation(
    MUTATION_ORGANIZATION_COURIER_CATEGORIES_UPDATE,
  );

  const showButton = useMemo(() => {
    return Object.keys(categories).some((key) => {
      return categories?.[key]?.label !== categoriesOriginal?.[key]?.label;
    });
  }, [categories, categoriesOriginal]);

  const handleChangeText = (key: string) => (text: string) => {
    setCategories((prev) => {
      if (text.length > 40) {
        return prev;
      }
      return { ...prev, [key]: { ...prev[key], label: text } };
    });
  };

  const handleSave = async () => {
    try {
      const res = await organizationCourierCategoriesUpdate({
        variables: {
          data: {
            categories: Object.keys(categories).map((key) => ({
              id: categories[key].id,
              label: categories[key].label,
              order: categories[key].order,
            })),
          },
        },
      });
      const data: { [key: string]: any } = {};
      if (res?.data?.organizationCourierCategoriesUpdate) {
        res.data.organizationCourierCategoriesUpdate.forEach((item: any) => {
          data[item.order] = { ...item };
        });
        setCategoriesOriginal({ ...data });
        showMessage({
          message: t('common.success', { defaultValue: 'Success' }),
          description: t('common.courierCategoriesSavedSuccessfully', {
            defaultValue: 'Courier categories saved successfully.',
          }),
          type: 'success',
          autoHide: true,
          hideOnPress: true,
          duration: 5000,
        });
      }
    } catch {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }),
        description: t('common.somethingWentWrongWithSavingCourierCategories', {
          defaultValue: 'Sorry, something went wrong with saving courier categories.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  return { categories, handleChangeText, handleSave, loading, loadingSave, showButton };
};
