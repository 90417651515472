import React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { WelcomeIcon } from '../components/icons.component';
import { CommonAppInformationScreen } from '../screens/common/common.app-information.screen';
import { CommonUnauthenticatedSwitchAccountScreen } from '../screens/common/common.unauthenticated-switch-account.screen';

const BottomTabNavigator = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={(index) => navigation.navigate(state.routeNames[index])}
      >
        <BottomNavigationTab
          title={t('common.welcome', { defaultValue: 'Welcome' }).toUpperCase()}
          icon={WelcomeIcon}
        />
      </BottomNavigation>
    </React.Fragment>
  );
};

const StackNavigator = createStackNavigator();
const DefaultProfileNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="CommonUnauthenticatedSwitchAccountScreen"
    >
      <StackNavigator.Screen
        name="CommonUnauthenticatedSwitchAccountScreen"
        component={CommonUnauthenticatedSwitchAccountScreen}
      />
      <StackNavigator.Screen
        name="CommonAppInformationScreen"
        component={CommonAppInformationScreen}
      />
    </StackNavigator.Navigator>
  );
};

export const DefaultNavigator = (props: any) => {
  return (
    <BottomTabNavigator.Navigator
      tabBar={(props) => <BottomTabBar {...props} />}
      screenOptions={{ headerShown: false }}
    >
      <BottomTabNavigator.Screen
        name="DefaultProfileNavigator"
        component={DefaultProfileNavigator}
      />
    </BottomTabNavigator.Navigator>
  );
};
