import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Platform, TextInputProps, View, StyleProp, ViewStyle } from 'react-native';

import { Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Constants from 'expo-constants';
import {
  DescriptionRow,
  Geometry,
  GooglePlaceData,
  GooglePlaceDetail,
  GooglePlacesAutocomplete,
  Language,
  Place,
} from 'react-native-google-places-autocomplete';

import { useLocationMonitoring } from '../../hooks/useLocationMonitoring';
import {
  reverseGeocodeLocation,
  ReverseGeocodeLocationType,
} from '../../lib/google-maps-reverse-geocode-location-api';
import {
  AddressGeometry,
  GeocodeResult,
  LatLngBounds,
  LocationType,
} from '../../types/google-maps.common.types';
import { getGoogleMapsApiKey } from '../../utils/google';

export type GooglePlacesAutocompleteLocation = {
  description?: string;
  formattedAddress: string;
  language: Language;
  locationTypes: string[];
  geometry: AddressGeometry;
  placeId: string;
  utcOffset?: number;
};

export type ThemedGooglePlacesAutocompleteOnPress = (
  googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
  raw?: { data: DescriptionRow; details: GooglePlaceDetail | null },
) => void;

const ThemedGooglePlacesAutocomplete = forwardRef(
  (
    {
      caption,
      label,
      isMandatory,
      onChange,
      onChangeText,
      onPress,
      language = 'en',
      predefinedPlaces = [],
      reverseGeocodeLocationTypes = ['airports', 'localities', 'small_regions', 'large_regions'],
      placeholderText = 'Search location',
      currentLocationText = 'Current location',
      isCurrentLocationEnabled = true,
      textInputProps,
      style,
      inputContainerStyle,
      errorMessage,
    }: {
      caption?: string;
      label?: string;
      isMandatory?: boolean;
      onChange?: (text: string) => void;
      onChangeText?: (text: string) => void;
      onPress?: ThemedGooglePlacesAutocompleteOnPress;
      language: Language;
      predefinedPlaces?: Place[];
      reverseGeocodeLocationTypes?: ReverseGeocodeLocationType[];
      placeholderText?: string;
      currentLocationText?: string;
      isCurrentLocationEnabled?: boolean;
      style?: StyleProp<ViewStyle>;
      inputContainerStyle?: StyleProp<ViewStyle>;
      textInputProps?: TextInputProps;
      errorMessage?: string;
    },
    ref: any,
  ) => {
    const styles = useStyleSheet(themedStyles);

    const { location, lastDeviceLocationWithLocation } = useLocationMonitoring();
    const [currentLocationPlace, setCurrentLocationPlace] = useState<Place>();
    const [predefinedPlacesState, setPredefinedPlacesState] = useState<Place[]>(predefinedPlaces);
    const [text, setText] = useState(textInputProps?.value || '');
    const [loaded, setLoaded] = useState(false);

    const updateCurrentLocationPlace = () => {
      if (location) {
        const description = lastDeviceLocationWithLocation?.location?.formattedAddress
          ? lastDeviceLocationWithLocation?.location?.formattedAddress +
            ' (' +
            currentLocationText +
            ')'
          : (currentLocationText as string);

        setCurrentLocationPlace({
          description,
          geometry: {
            location: {
              lat: location.coords.latitude,
              lng: location.coords.longitude,
            },
          },
        });
      }
    };

    // Only update the the current location when there is an actual location update.
    // current location will not be available if location services are disabled.
    useEffect(() => {
      if ((location || lastDeviceLocationWithLocation) && isCurrentLocationEnabled) {
        updateCurrentLocationPlace();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, lastDeviceLocationWithLocation]);

    useEffect(() => {
      let newPredefinedPlaces: Place[] = [];
      if (predefinedPlaces) {
        newPredefinedPlaces = [...predefinedPlaces];
      }
      if (currentLocationPlace) {
        newPredefinedPlaces = [
          ...predefinedPlaces,
          ...predefinedPlaces.filter((pp) => pp.description === currentLocationText),
          currentLocationPlace,
        ];
      }
      setPredefinedPlacesState(newPredefinedPlaces);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocationPlace]);

    const getGooglePlacesAutocompleteLocation = (
      data: GooglePlaceData,
      details: GooglePlaceDetail | null,
      googleMapsGeocoderResult?: GeocodeResult,
    ): GooglePlacesAutocompleteLocation => {
      const googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation = {
        placeId: googleMapsGeocoderResult?.place_id || data.place_id,
        description: data.description,
        formattedAddress:
          googleMapsGeocoderResult?.formatted_address ||
          details?.formatted_address ||
          'Could not resolve formatted address',
        locationTypes: (googleMapsGeocoderResult?.types || details?.types) as string[],
        language,
        geometry: {
          location: {
            lat: (googleMapsGeocoderResult?.geometry.location.lat ||
              details?.geometry.location.lat) as number,
            lng: (googleMapsGeocoderResult?.geometry.location.lng ||
              details?.geometry.location.lng) as number,
          },
          location_type:
            googleMapsGeocoderResult?.geometry.location_type || LocationType.APPROXIMATE,
          bounds:
            googleMapsGeocoderResult?.geometry.bounds ||
            (details?.geometry.viewport as LatLngBounds),
          viewport: (googleMapsGeocoderResult?.geometry.viewport ||
            details?.geometry.viewport) as Geometry['viewport'],
        },
        utcOffset: details?.utc_offset,
      };

      return googlePlacesAutocompleteLocation;
    };

    const updateLocationData = (details: GooglePlaceDetail, data: GooglePlaceData) => {
      reverseGeocodeLocation(details?.geometry?.location, reverseGeocodeLocationTypes)
        .then((geocoderResults) => {
          if (geocoderResults && geocoderResults.length > 0) {
            setText(data.description);
            if (onPress) {
              onPress(getGooglePlacesAutocompleteLocation(data, details, geocoderResults[0]));
            }
          }
        })
        .catch((e) => {
          console.warn('error', e);
        });
    };

    const handleChange = (val: any) => {
      let newValue = val.target.value;
      if (Platform.OS !== 'web') {
        newValue = val.nativeEvent.text;
      }
      if (newValue !== text) {
        setText(newValue);
        if (onChange) {
          onChange(newValue);
        }
      }
    };

    const handleTextChange = (val: string) => {
      setText(val);
      if (val !== text) {
        if (onChangeText) {
          onChangeText(val);
        }
      }
    };

    const updateText = (_text: string) => {
      setText(_text);
    };

    useEffect(() => {
      if (!loaded && textInputProps?.value) {
        setLoaded(true);
        setText(textInputProps?.value);
      }
    }, [loaded, textInputProps?.value]);

    useImperativeHandle(ref, () => ({
      updateText,
    }));

    return (
      <>
        {!!label && (
          <View style={styles.labelContainer}>
            <Text selectable={true} style={styles.label}>
              <>
                {label as string}
                {!!isMandatory && (
                  <Text selectable={true} style={styles.mandatoryStar}>
                    *
                  </Text>
                )}
              </>
            </Text>
          </View>
        )}
        {!!caption && (
          <View style={styles.captionContainer}>
            <Text selectable={true} style={styles.caption}>
              {caption}
            </Text>
          </View>
        )}
        <GooglePlacesAutocomplete
          renderRightButton={() => (
            <Icon
              name="pin-outline"
              fill={styles.locationIconFillColor.backgroundColor}
              style={styles.locationIcon}
            />
          )}
          styles={{
            ...styles.autocompleteStyle,
            style,
            container: [styles.container, inputContainerStyle || {}],
            textInputContainer: [
              styles.textInputContainer,
              !!errorMessage && styles.textInputContainerError,
            ],
            textInput: styles.textInput,
            textInputPlaceholder: styles.textInputPlaceholder,
            autocompleteStyle: styles.autocompleteStyle,
            predefinedPlacesDescription: styles.predefinedPlacesDescription,
            listView: styles.listView,
            row: styles.row,
            separator: styles.separator,
            description: styles.description,
            loader: styles.loader,
          }}
          keyboardShouldPersistTaps="handled"
          //listViewDisplayed={true}
          placeholder={placeholderText as string}
          minLength={1}
          enablePoweredByContainer={false}
          fetchDetails // required
          GoogleReverseGeocodingQuery={{
            bounds: 10000,
            language,
          }}
          filterReverseGeocodingByTypes={[
            'administrative_area_level_3',
            'locality',
            'point_of_interest',
            'street_address',
          ]}
          predefinedPlaces={predefinedPlacesState}
          textInputProps={{
            ...textInputProps,
            onChange: onChange ? handleChange : undefined,
            onChangeText: onChangeText ? handleTextChange : undefined,
            placeholderTextColor: styles.placeholderColor.backgroundColor,
            clearButtonMode: 'always',
            value: text,
          }}
          onPress={(data, details = null) => {
            if (
              data &&
              details &&
              details.formatted_address === undefined &&
              details?.geometry.location !== undefined &&
              !data.description.includes('enter data manually')
            ) {
              updateLocationData(details, data);
            } else {
              if (onPress) {
                onPress(getGooglePlacesAutocompleteLocation(data, details), {
                  data: data as DescriptionRow,
                  details,
                });
                setText(data.description);
              }
            }
          }}
          query={{
            key: getGoogleMapsApiKey(),
            language,
            types: ['(cities)', 'geocode', 'airport'],
          }}
          requestUrl={{
            useOnPlatform: 'web', // or "all"
            url: Constants.expoConfig?.extra?.priojetGoogleMapsProxyUrl,
            headers: {
              // authorization: `an auth token`, // if required for your proxy
            },
          }}
        />
      </>
    );
  },
);

export { ThemedGooglePlacesAutocomplete };

const themedStyles = StyleService.create({
  // Basic styles for autocomplete.
  autocompleteStyle: {
    backgroundColor: 'background-basic-color-2',
    borderColor: 'color-basic-300',
    borderWidth: 1,
    paddingTop: 4,
  },
  // These are nested styles. See style attribute of <GooglePlacesAutocomplete>
  container: {
    flex: 1,
    borderRadius: 4,
  },
  textInputContainer: {
    flexDirection: 'row',
    padding: 0,
    backgroundColor: 'background-basic-color-2',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'border-basic-color-4',
  },
  textInputContainerError: {
    borderColor: 'color-danger-500',
  },
  textInput: {
    marginTop: 0,
    marginRight: 5,
    marginLeft: 5,
    marginBottom: 0,
    backgroundColor: 'background-basic-color-2',
    color: 'text-basic-color',
  },
  textInputPlaceholder: {
    color: 'red',
  },
  predefinedPlacesDescription: {
    color: 'text-basic-color',
  },
  listView: {},
  row: {
    backgroundColor: 'background-basic-color-2',
    color: 'text-basic-color',
    padding: 13,
    height: 44,
    flexDirection: 'row',
  },
  separator: {
    height: 0.5,
    backgroundColor: 'border-basic-color-4',
  },
  description: {
    color: 'text-basic-color',
  },
  loader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: 20,
  },
  // END
  locationIcon: {
    width: 25,
    height: 25,
    marginRight: 5,
    marginTop: 11,
  },
  locationIconFillColor: {
    backgroundColor: 'color-primary-300',
  },
  placeholderColor: {
    backgroundColor: 'text-hint-color', // use backgroundColor here to access it via styles.textInput.backgroundColor
  },

  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  captionContainer: {
    paddingBottom: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
});
