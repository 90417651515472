import { useNavigation } from '@react-navigation/core';

export const useHook = () => {
  const navigation: any = useNavigation();

  const handleBackPress = () => {
    navigation.navigate('AgencyHomeScreen');
  };

  return { handleBackPress };
};
