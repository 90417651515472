import React, { useContext } from 'react';
import { RefreshControl, ScrollView, Platform } from 'react-native';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Divider, Icon, Tab, TabBar, Text, TopNavigationAction } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { globalStyle } from '../../../common/style';
import { CourierAvailabilities } from '../../../components/common/courier-availabilities/courier-availabilities.component';
import { SubscriptionPermissions } from '../../../components/common/SubscriptionPermissions';
import { TopNavigationBarWithAccessoryRight } from '../../../components/top-navigation-with-accessory-right.component';
import { TopNavigationWithoutAccessories } from '../../../components/top-navigation-without-accessories.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { OrganizationType, SubscriptionFeatureType } from '../../../generated-graphql-types';
import { openUrl } from '../../../hooks/useOpenLink';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export const CourierAvailabilitiesScreen = ({
  navigation,
}: {
  navigation: any;
  route: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  const {
    availabilitiesActive,
    availabilitiesUpcoming,
    availabilitiesPast,
    error,
    handleRefresh,
    loading,
    refreshing,
  } = useHook();

  const renderTopTabBar = ({ navigation: _navigation, state }: { navigation: any; state: any }) => (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => _navigation.navigate(state.routeNames[index])}
      style={globalStyle.height50}
    >
      <Tab title={t('common.active', { defaultValue: 'ACTIVE' }) as string} />
      <Tab title={t('common.upcoming', { defaultValue: 'UPCOMING' }) as string} />
      <Tab title={t('common.finished', { defaultValue: 'FINISHED' }) as string} />
    </TabBar>
  );

  if (error) {
    return (
      <>
        <TopNavigationWithoutAccessories
          title={t('common.courierAvailabilities', {
            defaultValue: 'Courier Availabilities',
          })}
        />
        <Divider />
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              onRefresh={handleRefresh}
              refreshing={refreshing}
              title="Loading Courier Availabilities..."
            />
          }
          style={globalStyle.padding20}
        >
          <Text selectable={true}>An error occurred: {error.message}</Text>
          <Text>Please pull down to refresh. Otherwise, please restart the app and try again.</Text>
        </ScrollView>
      </>
    );
  }

  const renderCreateAvailabilityScreen = (): React.ReactElement => (
    <SubscriptionPermissions
      warningContent={{
        confirmButtonText:
          Platform.OS === 'web'
            ? t('common.courierAccountSettings', { defaultValue: 'Courier Account Settings' })
            : t('common.visitPriojetCom', { defaultValue: 'Visit https://app.priojet.com/' }),
        title: '',
        text:
          Platform.OS === 'web'
            ? t('subscription.thisRunwayIsCloseForYouWillBeAvailableIfYouHaveOBCBasicPlusWeb', {
                defaultValue:
                  'This runway is closed for you. This will be available if you have OBC Basic Plus. You can fly to the Courier Account Settings page from the Profile menu to upgrade.',
              })
            : t('subscription.thisRunwayIsCloseForYouWillBeAvailableIfYouHaveOBCBasicPlusMobile', {
                defaultValue:
                  'This runway is closed for you. Fly to https://app.priojet.com for more information.',
              }),
        onConfirmPress: () => {
          if (Platform.OS === 'web') {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CommonCourierAccountSettingsScreen',
            });
          } else {
            openUrl('https://app.priojet.com');
          }
        },
      }}
      feature={SubscriptionFeatureType.COURIER_AVAILABILITY}
      warningType="modal"
    >
      {({ checkPermissions, handleShowWarning }: any) => (
        <TopNavigationAction
          icon={<Icon name="plus-outline" />}
          onPress={async () => {
            const hasPermission = await checkPermissions();
            if (!hasPermission) {
              handleShowWarning();
            } else {
              navigation.navigate('CourierCreateAvailabilityScreen');
            }
          }}
        />
      )}
    </SubscriptionPermissions>
  );

  return (
    <>
      <TopNavigationBarWithAccessoryRight
        accessoryRight={renderCreateAvailabilityScreen}
        title={t('common.availabilities', { defaultValue: 'Availabilities' })}
      />
      <Navigator tabBar={renderTopTabBar}>
        <Screen name="CommonCourierActiveAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesActive}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={
                appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
              }
            />
          )}
        </Screen>
        <Screen name="CommonCourierUpcomingAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesUpcoming}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={
                appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
              }
            />
          )}
        </Screen>
        <Screen name="CommonCourierPastAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesPast}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={
                appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
              }
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};
