import { useState } from 'react';
import { Linking, Platform } from 'react-native';

export const useHook = () => {
  const [pdfUri, setPDFUri] = useState('');

  const handlePDFPress = (uri: string) => () => {
    if (Platform.OS === 'web' && uri) {
      Linking.openURL(uri);
    } else {
      setPDFUri(uri);
    }
  };

  return { handlePDFPress, pdfUri };
};
