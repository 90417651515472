import { useContext, useEffect, useState } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import AppUserContext from '../../../contexts/AppUserContext';
import { GLOBAL_CONSTANTS } from '../../../globals';
import { useAppState } from '../../../hooks/useAppState';
import i18n from '../../../i18n/i18n';

const periods: { type: string; end: DateTime; start: DateTime; good: (name: string) => string }[] =
  [
    {
      type: 'other',
      start: DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).setZone('UTC'),
      end: DateTime.now()
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .setZone('UTC'),
      good: (name) =>
        i18n.t('common:helloNiceToSeeYou', {
          defaultValue: 'Hi {{name}}, it is nice to see you again!',
          name,
        }) as string,
    },
    {
      type: 'morning',
      start: DateTime.now().set({ hour: 4, minute: 0, second: 0, millisecond: 0 }).setZone('UTC'),
      end: DateTime.now()
        .set({ hour: 11, minute: 59, second: 59, millisecond: 999 })
        .setZone('UTC'),
      good: (name) =>
        i18n.t('common:goodMorning', { defaultValue: 'Good morning, {{name}}!', name }) as string,
    },
    {
      type: 'afternoon',
      start: DateTime.now().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).setZone('UTC'),
      end: DateTime.now()
        .set({ hour: 17, minute: 59, second: 59, millisecond: 999 })
        .setZone('UTC'),
      good: (name) =>
        i18n.t('common:goodAfternoon', {
          defaultValue: 'Good afternoon, {{name}}!',
          name,
        }) as string,
    },
    {
      type: 'eveningBefore',
      start: DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).setZone('UTC'),
      end: DateTime.now().set({ hour: 3, minute: 59, second: 59, millisecond: 999 }).setZone('UTC'),
      good: (name) =>
        i18n.t('common:goodEvening', { defaultValue: 'Good evening, {{name}}!', name }) as string,
    },
    {
      type: 'eveningAfter',
      start: DateTime.now().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).setZone('UTC'),
      end: DateTime.now()
        .plus({ day: 1 })
        .set({ hour: 3, minute: 59, second: 59, millisecond: 999 })
        .setZone('UTC'),
      good: (name) =>
        i18n.t('common:goodEvening', { defaultValue: 'Good evening, {{name}}!', name }) as string,
    },
  ];

export const useHook = () => {
  const { t } = useTranslation('common');
  const { diffFirstActiveState, resetActiveStates } = useAppState();
  const appUserContext = useContext(AppUserContext);

  const [currentEmail, setCurrentEmail] = useState('');
  const [greeting, setGreeting] = useState('');
  const [loaded, setLoaded] = useState(false);

  const updateStorageAndGreeting = async ({
    newGreeting,
    storageData,
  }: {
    newGreeting: string;
    storageData: any;
  }) => {
    await AsyncStorage.setItem(
      GLOBAL_CONSTANTS.GREETING_DATE_STORAGE_KEY,
      JSON.stringify(storageData),
    );
    setGreeting(newGreeting);
    setLoaded(true);
  };

  const checkGreeting = async () => {
    const name = appUserContext.currentUserContext?.user?.firstNames || '';
    const email = appUserContext.currentUserContext?.user?.email;
    if (!email) {
      return;
    }
    setCurrentEmail(email);

    /**
     * FIND CURRENT PERIOD - morning, afternoon, evening
     */
    let periodKey = 'other';
    const currentMillis = DateTime.now().setZone('UTC').toMillis();
    periods.forEach((period) => {
      if (currentMillis >= period.start.toMillis() && currentMillis <= period.end.toMillis()) {
        periodKey = period.type;
      }
    });

    const now = DateTime.now().setZone('UTC').toISO();

    let storageDate;
    let storageDateData: any = await AsyncStorage.getItem(
      GLOBAL_CONSTANTS.GREETING_DATE_STORAGE_KEY,
    );
    let greetingDate = DateTime.now().setZone('UTC');

    if (storageDateData && email) {
      storageDateData = JSON.parse(storageDateData);
      if (storageDateData[email]) {
        storageDate = storageDateData[email];
      }
    }

    if (storageDate) {
      const foundPeriod = periods.find((item) => item.type === periodKey);
      greetingDate = DateTime.fromISO(storageDate).setZone('UTC');
      if (foundPeriod) {
        const greetingDateMillis = greetingDate.toMillis();

        /**
         * CHECKS IF STORAGE DATE FALLS IN CURRENT PERIOD
         * IF STORAGE DATE FALLS IN morning PERIOD AND CURRENT IS AFTERNOON, IT IS LOGICAL TO SHOW "Good afternoon" FIRST
         * "Nice to see you again" IS SHOWN ONLY IF USER OPENS APP IN SAME PERIOD
         */
        if (
          greetingDateMillis >= foundPeriod.start.toMillis() &&
          greetingDateMillis <= foundPeriod.end.toMillis()
        ) {
          await updateStorageAndGreeting({
            newGreeting: t('common:helloNiceToSeeYou', {
              defaultValue: 'Hi {{name}}, it is nice to see you again!',
              name,
            }) as string,
            storageData: { ...storageDateData, [email]: now },
          });
          return;
        }
        await updateStorageAndGreeting({
          newGreeting: foundPeriod.good(name),
          storageData: { ...storageDateData, [email]: now },
        });
        return;
      }
    } else {
      const foundPeriod = periods.find((item) => item.type === periodKey);
      if (foundPeriod) {
        await updateStorageAndGreeting({
          newGreeting: foundPeriod.good(name),
          storageData: { ...(storageDateData || {}), [email]: now },
        });
        return;
      }
    }

    /**
     * FALLBACK IF PERIOD NOT FOUND
     */
    await updateStorageAndGreeting({
      newGreeting: periods[0].good(name),
      storageData: { ...(storageDateData || {}), [email]: now },
    });
  };

  useEffect(() => {
    if (diffFirstActiveState.minutes >= 5) {
      setLoaded(false);
      resetActiveStates();
    }
  }, [diffFirstActiveState.minutes, resetActiveStates]);

  useEffect(() => {
    if (
      appUserContext.currentUserContext?.user?.email &&
      appUserContext.currentUserContext?.user?.email !== currentEmail
    ) {
      setLoaded(false);
    }
  }, [appUserContext.currentUserContext?.user?.email, currentEmail]);

  useEffect(() => {
    if (!loaded) {
      checkGreeting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return { greeting };
};
