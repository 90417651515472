import React, { useContext } from 'react';
import { TextInput, View, ActivityIndicator } from 'react-native';

import { Text, useStyleSheet, Button } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyle } from './styles';
import { globalStyle } from '../../../common/style';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationBackToProfileButton } from '../../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../../contexts/AppUserContext';

export const AgencyManageCourierCategoriesScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyle);
  const appUserContext = useContext(AppUserContext);

  const { categories, handleChangeText, handleSave, loading, loadingSave, showButton } = useHook();

  const renderHeader = () => (
    <View style={globalStyle.padding20}>
      <Text style={globalStyle.textAlignCenter}>
        {t('common.courierCategories.explanationPartOne', {
          defaultValue:
            'Use this page to define your Courier Categories that you can use to label your connected couriers.',
        })}
      </Text>
      <Text style={globalStyle.textAlignCenter}>
        {t('common.courierCategories.explanationPartTwo', {
          defaultValue:
            'You can change the default descriptions according to how you would like to use them with a max of 40 characters.',
        })}
      </Text>
      <Text style={[globalStyle.textAlignCenter, globalStyle.paddingBottom10]}>
        {t('common.courierCategories.explanationPartThree', {
          defaultValue: "The description for '0' and '5' cannot be changed.",
        })}
      </Text>
      <Text style={globalStyle.textAlignCenter}>
        {t('common.courierCategories.explanationPartFour', {
          defaultValue:
            'When these labels are attached to the courier profile, only you as the agency can see it.',
        })}
      </Text>
      <Text style={globalStyle.textAlignCenter}>
        {t('common.courierCategories.explanationPartFive', {
          defaultValue: 'No other agency or courier can see it.',
        })}
      </Text>
    </View>
  );

  const renderCategory = (key: any, index: number) => {
    const category = categories[key];
    const disabled = index === 0 || index + 1 === Object.keys(categories).length;
    return (
      <View key={key} style={styles.category}>
        <View style={styles.categoryNumber}>
          <Text>{index}</Text>
        </View>
        <TextInput
          onChangeText={handleChangeText(key)}
          editable={!disabled}
          value={category?.label || ''}
          style={[styles.textInput, disabled && styles.textInputDisabled]}
        />
      </View>
    );
  };

  const renderCategories = () => (
    <View style={styles.categories}>{Object.keys(categories).map(renderCategory)}</View>
  );

  const renderAccessoryRight = () => {
    if (!showButton) {
      return <></>;
    }
    return (
      <View style={styles.saveBtn}>
        <Button onPress={loadingSave ? undefined : handleSave} appearance="filled">
          {loadingSave ? <ActivityIndicator color="white" /> : 'Save'}
        </Button>
      </View>
    );
  };

  const renderLoading = () => (
    <View>
      <LoadingSpinner
        subject={t('common.courierCategories.categories', {
          defaultValue: 'categories',
        })}
      />
    </View>
  );

  return (
    <>
      <TopNavigationBackToProfileButton
        userContext={appUserContext.currentUserContext}
        title={t('manageCourierCategories.screenTitle', {
          defaultValue: 'Manage courier categories',
        })}
        navigation={navigation}
        accessoryRight={renderAccessoryRight()}
      />
      {renderHeader()}
      {!!loading && renderLoading()}
      {!loading && !!Object.keys(categories).length && renderCategories()}
    </>
  );
};
