import React, { useEffect, useState } from 'react';
import { AppStateStatus, Platform } from 'react-native';

import { useMutation } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo, { NetInfoState } from '@react-native-community/netinfo';
import * as ExpoApplication from 'expo-application';
import * as ExpoBattery from 'expo-battery';
import Constants from 'expo-constants';
import * as ExpoDevice from 'expo-device';
import * as ExpoFileSystem from 'expo-file-system';
import * as ExpoLocation from 'expo-location';
import * as ExpoNetwork from 'expo-network';
import { DateTime } from 'luxon';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import {
  ADD_DEVICE_LOG_MUTATION,
  ADD_DEVICE_LOG_UNAUTHENTICATED_MUTATION,
} from '../apollo/graphql-mutations';
import { DeviceLocation } from '../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../globals';
import {
  AddDeviceLog,
  AddDeviceLogInput,
  AddDeviceLogUnauthenticated,
  AddDeviceLogUnauthenticatedVariables,
  AddDeviceLogVariables,
  DeviceLogDataInput,
  DeviceLogNetworkStateType,
  DeviceLogTriggerType,
} from '../graphql-types';
import { useAppState } from '../hooks/useAppState';
import { useInterval } from '../hooks/useInterval';
import { useLocationMonitoring } from '../hooks/useLocationMonitoring';
import { LocalUserContext } from '../types';
import { getIsBackendReachable } from '../utils/get-is-backend-reachable';
import { parseJsonWithDateTime } from '../utils/json-parse-with-date-time';

export interface IAppContext {
  currentUserContext: LocalUserContext | null | undefined;
  connectionState: NetInfoState | null;
  isBackendReachable: boolean;
  location: ExpoLocation.LocationObject | null;
  lastDeviceLocationWithLocation: Partial<DeviceLocation> | null;
  startLocationMonitoring: () => void;
  initializeLocationMonitoring: () => void;
  deInitializeLocationMonitoring: () => void;
}

const AppContext = React.createContext<IAppContext | null>(
  null,
) as unknown as React.Context<IAppContext>;

export const AppContextProvider = ({
  currentUserContext,
  children,
}: {
  currentUserContext: LocalUserContext | null | undefined;
  children: any;
}) => {
  const {
    location,
    lastDeviceLocationWithLocation,
    startLocationMonitoring,
    initializeLocationMonitoring,
    deInitializeLocationMonitoring,
    watchPositionSubscriber,
  } = useLocationMonitoring();

  const handleAppStateChange = async (state: {
    diffFromLastActiveState?: { milliseconds: number; seconds: number; minutes: number };
    prevAppState?: AppStateStatus;
    nextAppState: AppStateStatus;
  }) => {
    if (state.prevAppState === 'background' && state.nextAppState === 'active') {
      await sendDeviceLog(DeviceLogTriggerType.APP_STATE_CHANGE);
    }
  };

  const { appState } = useAppState({ cb: handleAppStateChange });

  // States and hooks
  const [connectionState, setConnectionState] = useState<NetInfoState | null>(null);
  const [_deviceLogLastTriggerType, setDeviceLogLastTriggerType] =
    useState<DeviceLogTriggerType | null>();
  const [isBackendReachable, setIsBackendReachable] = useState<boolean>(true);
  const [deviceLogInputs, setDeviceLogInputs] = useState<AddDeviceLogInput[]>([]);
  const [deviceLogSent, setDeviceLogSent] = useState<boolean>(false);

  // Functions
  /**
   * This async function stores the current deviceLogInputs state variable in the AsyncStorage.
   * @returns void
   */
  const storeDeviceLogInputs = async (_deviceLogInputs: AddDeviceLogInput[]) => {
    if (deviceLogInputs !== null) {
      AsyncStorage.setItem(
        GLOBAL_CONSTANTS.DEVICE_LOG_INPUTS_STORAGE_KEY,
        JSON.stringify(_deviceLogInputs),
      );
    }
  };

  /**
   * This async function loads the deviceLogInputs from the AsyncStorage and stores them in the deviceLogInputs state variable.
   * @returns void
   */
  const loadDeviceLogInputs = async () => {
    const data: string | null = await AsyncStorage.getItem(
      GLOBAL_CONSTANTS.DEVICE_LOG_INPUTS_STORAGE_KEY,
    );
    if (data) {
      const _deviceLogInputs = parseJsonWithDateTime<AddDeviceLogInput[]>(data);
      if (_deviceLogInputs) {
        setDeviceLogInputs([..._deviceLogInputs, ...deviceLogInputs]);
        return;
      }
    }
  };

  const [addDeviceLogUnauthenticated] = useMutation<
    AddDeviceLogUnauthenticated,
    AddDeviceLogUnauthenticatedVariables
  >(ADD_DEVICE_LOG_UNAUTHENTICATED_MUTATION, {
    fetchPolicy: 'no-cache',
    context: {
      exceptionOnUnauthenticated: true,
    },
  });

  const [addDeviceLog] = useMutation<AddDeviceLog, AddDeviceLogVariables>(ADD_DEVICE_LOG_MUTATION, {
    fetchPolicy: 'no-cache',
    context: {
      exceptionOnUnauthenticated: true,
    },
  });

  const getAddDeviceLogInput = async (
    triggerType: DeviceLogTriggerType,
    timestamp: DateTime = DateTime.now(),
  ): Promise<AddDeviceLogInput> => {
    const ipAddress: string | null = null;
    const isCurrentUserContextAvailable = currentUserContext !== null;
    let batteryLevel: number | null = null;
    let carrier: string | null = null;
    let maxMemory: number | null = null;
    let powerState: ExpoBattery.PowerState | null = null;
    let isAirplaneModeEnabled: boolean | null = null;
    let freeDiskStorage: number | null = null;

    /* eslint-disable no-empty */
    try {
      carrier = await Constants.getCarrierNameAsync();
    } catch (e) {}
    try {
      batteryLevel = await ExpoBattery.getBatteryLevelAsync();
    } catch {}
    try {
      powerState = await ExpoBattery.getPowerStateAsync();
    } catch {}

    try {
      maxMemory = await ExpoDevice.getMaxMemoryAsync();
    } catch {}

    try {
      isAirplaneModeEnabled = await ExpoNetwork.isAirplaneModeEnabledAsync();
    } catch {}

    try {
      freeDiskStorage = await ExpoFileSystem.getFreeDiskStorageAsync();
    } catch {}

    let totalDiskCapacity: number | null = null;
    try {
      totalDiskCapacity = await ExpoFileSystem.getTotalDiskCapacityAsync();
    } catch {}
    /* eslint-enable no-empty */

    const networkState = await ExpoNetwork.getNetworkStateAsync();
    const data: DeviceLogDataInput = {
      appVersion: ExpoApplication.nativeApplicationVersion,
      androidId: Platform.OS === 'android' ? ExpoApplication.getAndroidId() : null,
      applicationId: ExpoApplication.applicationId,
      networkStateType: networkState.type
        ? DeviceLogNetworkStateType[
            ExpoNetwork.NetworkStateType[
              networkState.type
            ] as keyof typeof DeviceLogNetworkStateType
          ]
        : DeviceLogNetworkStateType.NONE,
      networkStateIsConnected: networkState.isConnected,
      networkStateIsInternetReachable: networkState.isInternetReachable,
      isAirplaneModeEnabled,
      ipAddress: ipAddress || '',
      carrier,
      freeDiskStorage,
      totalDiskCapacity,
      maxMemory,
      batteryLevel,
      batteryState: null,
      lowPowerMode: powerState?.lowPowerMode,
      appState: appState || 'unknown',
    };

    return { id: uuidv4(), data, triggerType, isCurrentUserContextAvailable, timestamp };
  };

  const sendDeviceLog = async (triggerType: DeviceLogTriggerType) => {
    setDeviceLogSent(true);
    const deviceLogInput: AddDeviceLogInput = await getAddDeviceLogInput(triggerType);
    let addDeviceLogInputs = [...deviceLogInputs, deviceLogInput];
    if (!isBackendReachable) {
      await storeDeviceLogInputs(addDeviceLogInputs);
      return;
    }

    // Sort array by timestamp desc and get the latest 10 deviceLogs.
    addDeviceLogInputs = addDeviceLogInputs
      .sort((a, b) => {
        if (a.timestamp < b.timestamp) {
          return -1;
        }
        if (a.timestamp > b.timestamp) {
          return 1;
        }
        return 0;
      })
      .splice(Math.max(deviceLogInputs.length, 10) * -1);

    if (addDeviceLogInputs.length) {
      if (currentUserContext && currentUserContext.organization) {
        setDeviceLogLastTriggerType(triggerType);

        await addDeviceLog({ variables: { addDeviceLogInputs } })
          .then((result) => {
            if (result?.data?.addDeviceLog) {
              setDeviceLogInputs([]);
              storeDeviceLogInputs([]);
            }
          })
          .catch((addDeviceLogError) => {
            if (addDeviceLogError) {
              addDeviceLogUnauthenticated({
                variables: { addDeviceLogInputs },
              }).then((res) => {
                if (res?.data?.addDeviceLogUnauthenticated) {
                  setDeviceLogInputs([]);
                  storeDeviceLogInputs([]);
                }
              });
            }
          });
      }
    }
  };

  //******************** ******************** useInterval functions ****************************************
  useInterval(() => {
    if (deviceLogSent) {
      sendDeviceLog(DeviceLogTriggerType.PERIODIC_PING);
    }
  }, Constants.expoConfig?.extra?.priojetDeviceLogIntervalMs || 300000);

  // Periodically check if backend is reachable
  useInterval(async () => {
    const _isBackendReachable = await getIsBackendReachable();
    if (_isBackendReachable !== isBackendReachable) {
      setIsBackendReachable(_isBackendReachable);
    }
  }, 10000);

  //******************** ******************** useEffect functions ****************************************
  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state: NetInfoState) => {
      if (typeof state.isConnected === 'boolean' && state.isConnected !== isBackendReachable) {
        setIsBackendReachable(state.isConnected);
      }
      setConnectionState(state);
    });
    loadDeviceLogInputs();
    sendDeviceLog(DeviceLogTriggerType.APP_STATE_CHANGE);
    getIsBackendReachable().then((value) => setIsBackendReachable(value));
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (watchPositionSubscriber?.remove) {
        watchPositionSubscriber.remove();
      }
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        currentUserContext,
        connectionState,
        isBackendReachable,
        location,
        lastDeviceLocationWithLocation,
        startLocationMonitoring,
        initializeLocationMonitoring,
        deInitializeLocationMonitoring,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
