import React, { memo } from 'react';

import { Text, useStyleSheet } from '@ui-kitten/components';

import { styles as themedStyles } from './styles';

export const ChatMessageTextComponent: React.FC<any> = ({ text, isCurrentUser }) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Text
      key={Math.random()}
      style={[styles.messageText, isCurrentUser ? styles.textRight : styles.textLeft]}
      selectable
    >
      {text}
    </Text>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps?.isCurrent !== nextProps?.isCurrent) {
    return false;
  }
  if (prevProps?.text !== nextProps?.text) {
    return false;
  }
  return true;
};

export const ChatMessageText = memo(ChatMessageTextComponent, arePropsEqual);
