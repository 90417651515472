import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { AgencyCourierUserProfileScreen } from '../screens/agency/AgencyCourierUserProfile';
import { PriojetCourierAvailabilitiesScreen } from '../screens/priojet/priojet.courier-availabilities.screen';

const StackNavigator = createStackNavigator();
const PriojetCourierAvailabilitiesNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="PriojetCourierAvailabilitiesScreen"
    >
      <StackNavigator.Screen
        name="PriojetCourierAvailabilitiesScreen"
        component={PriojetCourierAvailabilitiesScreen}
      />
      <StackNavigator.Screen
        name="AgencyCourierUserProfileScreen"
        component={AgencyCourierUserProfileScreen}
      />
    </StackNavigator.Navigator>
  );
};

export { PriojetCourierAvailabilitiesNavigator };
