import React, { useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { ApolloQueryResult } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Tab, TabBar, useStyleSheet, Text, StyleService } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle as globalStyleCommon } from '../../common/style';
import { AgencyCourierJobsList } from '../../components/agency/courier-jobs/agency-courier-jobs-list.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import {
  CourierJob,
  CourierJobAgencyRelationshipType,
  CourierJobsForAgencyJobQuery,
  Exact,
  useAgencyJobForAgencyOrganizationQuery,
  useCourierJobsForAgencyJobQuery,
} from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';

const { Navigator, Screen } = createMaterialTopTabNavigator();

interface CourierJobsType {
  courierJobsPending: Partial<CourierJob>[];
  courierJobsRequested: Partial<CourierJob>[];
  courierJobsAccepted: Partial<CourierJob>[];
  courierJobsDeclined: Partial<CourierJob>[];
}

export const AgencyCourierJobsScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();

  const route: any = useRoute();
  const navigation: any = useNavigation();
  const globalStyle = useStyleSheet(globalStyleCommon);

  const [courierJobs, setCourierJobs] = useState<Partial<CourierJob>[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);

  const { data: agencyJobForAgencyOrganizationData } = useAgencyJobForAgencyOrganizationQuery({
    variables: { agencyJobId: route.params?.agencyJobId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const { loading: courierJobsForAgencyJobLoading, refetch: courierJobsForAgencyJobRefetch } =
    useCourierJobsForAgencyJobQuery({
      variables: {
        agencyJobId: route.params?.agencyJobId as string, // will always be a string in this case.
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
      onCompleted: (data) => {
        if (data) {
          setCourierJobs(data.courierJobsForAgencyJob as Partial<CourierJob>[]);
        }
      },
    });

  const handleBackPress = () => {
    if (Platform.OS === 'web') {
      if (route.params?.previousScreenName === 'AgencyViewCourierJobScreen') {
        navigation.navigate(route.params?.previousScreenName, {
          agencyJobId: route.params?.agencyJobId,
          previousTabScreenName: route.params?.previousTabScreenName,
          refetch,
        });
      } else {
        navigation.navigate('AgencyJobsScreen', {
          previousTabScreenName: route.params?.previousTabScreenName,
        });
      }
    } else {
      navigation.goBack();
      setTimeout(() => {
        if (navigation?.setParams) {
          navigation.setParams({ refetch: true });
        }
      }, 300);
    }
  };

  const {
    courierJobsPending,
    courierJobsRequested,
    courierJobsAccepted,
    courierJobsDeclined,
  }: CourierJobsType = useMemo(() => {
    if (courierJobs?.length) {
      return courierJobs.reduce(
        (acc: any, current) => {
          const courierJob = current as Partial<CourierJob>;
          if (
            courierJob.relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED ||
            courierJob.relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED
          ) {
            acc.courierJobsPending.push(courierJob);
          } else if (
            courierJob.relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED
          ) {
            acc.courierJobsRequested.push(courierJob);
          } else if (
            courierJob.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED
          ) {
            acc.courierJobsAccepted.push(courierJob);
          } else if (
            courierJob.relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
            courierJob.relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED
          ) {
            acc.courierJobsDeclined.push(courierJob);
          }
          return acc;
        },
        {
          courierJobsPending: [],
          courierJobsRequested: [],
          courierJobsAccepted: [],
          courierJobsDeclined: [],
        } as CourierJobsType,
      );
    }
    return {
      courierJobsPending: [],
      courierJobsRequested: [],
      courierJobsAccepted: [],
      courierJobsDeclined: [],
    } as CourierJobsType;
  }, [courierJobs]);

  const handleRefetchJob = (
    variables?: Partial<Exact<{ agencyJobId: string }>> | undefined,
  ): Promise<ApolloQueryResult<CourierJobsForAgencyJobQuery>> => {
    if (!refetch) {
      setRefetch(true);
    }
    return courierJobsForAgencyJobRefetch(variables);
  };

  const renderTopTabBar = ({ navigation: _navigation, state }: { navigation: any; state: any }) => (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => _navigation.navigate(state.routeNames[index])}
      style={styles.minHeight50}
    >
      <Tab
        style={globalStyle.paddingHorizontal5}
        title={() => (
          <Text
            style={[
              state.index === 0 ? globalStyle.colorPrimary500 : globalStyle.colorPrimary300,
              globalStyle.fontLatoBold,
              globalStyle.fontSize13,
              globalStyle.paddingRight5,
            ]}
          >
            {
              t('jobs.agencyJobsManageCouriersTabPending', {
                courierJobs: courierJobsPending.length,
                defaultValue: 'PENDING ({{courierJobs}})',
              }) as string
            }
          </Text>
        )}
        key="NEW"
      />
      <Tab
        style={globalStyle.paddingHorizontal5}
        title={() => (
          <Text
            style={[
              state.index === 1 ? globalStyle.colorPrimary500 : globalStyle.colorPrimary300,
              globalStyle.fontLatoBold,
              globalStyle.fontSize13,
              globalStyle.paddingRight5,
            ]}
          >
            {
              t('jobs.agencyJobsManageCouriersTabRequested', {
                courierJobs: courierJobsRequested.length,
                defaultValue: 'REQUESTED ({{courierJobs}})',
              }) as string
            }
          </Text>
        )}
        key="AgencyCourierJobsRequestedScreen"
      />
      <Tab
        style={globalStyle.paddingHorizontal5}
        title={() => (
          <Text
            style={[
              state.index === 2 ? globalStyle.colorPrimary500 : globalStyle.colorPrimary300,
              globalStyle.fontLatoBold,
              globalStyle.fontSize13,
              globalStyle.paddingRight5,
            ]}
          >
            {
              t('jobs.agencyJobsManageCouriersTabAccepted', {
                courierJobs: courierJobsAccepted.length,
                defaultValue: 'ACCEPTED \n({{courierJobs}})',
              }) as string
            }
          </Text>
        )}
        key="AgencyCourierJobsAcceptedScreen"
      />
      <Tab
        style={globalStyle.paddingHorizontal5}
        title={() => (
          <Text
            style={[
              state.index === 3 ? globalStyle.colorPrimary500 : globalStyle.colorPrimary300,
              globalStyle.fontLatoBold,
              globalStyle.fontSize13,
              globalStyle.paddingRight5,
            ]}
          >
            {
              t('jobs.agencyJobsManageCouriersTabDeclined', {
                courierJobs: courierJobsDeclined.length,
                defaultValue: 'DECLINED \n({{courierJobs}})',
              }) as string
            }
          </Text>
        )}
        key="AgencyCourierJobsDeclinedScreen"
      />
    </TabBar>
  );

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common.manageCouriersPriojetJobIdentifier', {
          defaultValue: 'Manage Couriers ({{priojetJobIdentifier}})',
          priojetJobIdentifier:
            agencyJobForAgencyOrganizationData?.agencyJobForAgencyOrganization.job
              .priojetJobIdentifier,
        })}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />

      <Navigator
        tabBar={renderTopTabBar}
        initialRouteName={route.params.tabScreenName || 'AgencyCourierJobsPendingScreen'}
      >
        <Screen
          name="AgencyCourierJobsPendingScreen"
          initialParams={{
            agencyJobId: route.params?.agencyJobId || '',
            previousScreenName: route.params?.previousScreenName || '',
          }}
        >
          {() => (
            <AgencyCourierJobsList
              agencyJobId={route.params?.agencyJobId as string}
              courierJobs={courierJobsPending}
              filterTerm=""
              isLoading={courierJobsForAgencyJobLoading}
              refetch={handleRefetchJob}
              screenName="AgencyCourierJobsPendingScreen"
            />
          )}
        </Screen>
        <Screen
          name="AgencyCourierJobsRequestedScreen"
          initialParams={{
            agencyJobId: route.params?.agencyJobId || '',
            previousScreenName: route.params?.previousScreenName || '',
          }}
        >
          {() => (
            <AgencyCourierJobsList
              agencyJobId={route.params?.agencyJobId as string}
              courierJobs={courierJobsRequested}
              filterTerm=""
              isLoading={courierJobsForAgencyJobLoading}
              refetch={handleRefetchJob}
              screenName="AgencyCourierJobsRequestedScreen"
            />
          )}
        </Screen>
        <Screen
          name="AgencyCourierJobsAcceptedScreen"
          initialParams={{
            agencyJobId: route.params?.agencyJobId || '',
            previousScreenName: route.params?.previousScreenName || '',
          }}
        >
          {() => (
            <AgencyCourierJobsList
              agencyJobId={route.params?.agencyJobId as string}
              courierJobs={courierJobsAccepted}
              filterTerm=""
              isLoading={courierJobsForAgencyJobLoading}
              refetch={handleRefetchJob}
              screenName="AgencyCourierJobsAcceptedScreen"
            />
          )}
        </Screen>
        <Screen
          name="AgencyCourierJobsDeclinedScreen"
          initialParams={{
            agencyJobId: route.params?.agencyJobId || '',
            previousScreenName: route.params?.previousScreenName || '',
          }}
        >
          {() => (
            <AgencyCourierJobsList
              agencyJobId={route.params?.agencyJobId as string}
              courierJobs={courierJobsDeclined}
              filterTerm=""
              isLoading={courierJobsForAgencyJobLoading}
              refetch={handleRefetchJob}
              screenName="AgencyCourierJobsDeclinedScreen"
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};

const styles = StyleService.create({
  minHeight50: {
    minHeight: 50,
  },
});
