import React, { JSXElementConstructor, ReactElement } from 'react';
import { FlatList, RefreshControlProps, View } from 'react-native';

import { StyleService, Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { CourierConnectedAgenciesCardItem } from './courier-connected-agencies-card-item.component';
import { globalStyle } from '../../../common/style';
import { ConnectedOrganization } from '../../../generated-graphql-types';

type Props = {
  connectedOrganizations: Array<ConnectedOrganization>;
  refreshControl: ReactElement<RefreshControlProps, string | JSXElementConstructor<any>>;
  filterTerm: string | undefined;
};

export const CourierConnectedAgenciesList = ({
  connectedOrganizations,
  refreshControl,
  filterTerm,
}: Props) => {
  const { t } = useTranslation();

  const ListEmptyComponent = ({ filterTerm: _filterTerm }: { filterTerm: string | undefined }) =>
    _filterTerm ? (
      <View style={styles.noResultsView}>
        <Text selectable={true}>
          {
            t('common.noAgencyConnectionsForFilterTerm', {
              filterTerm,
              defaultValue: 'No connected Agencies found for filter term "{{filterTerm}}".',
            }) as string
          }
        </Text>
      </View>
    ) : (
      <View style={styles.noResultsView}>
        <Text selectable={true}>
          {
            t('connectedAgencies.noConnectedAgencies', {
              defaultValue:
                "You don't have Agency connections. You can only connect to an Agency by getting invited by the Agency.",
            }) as string
          }
        </Text>
      </View>
    );

  return (
    <FlatList
      refreshControl={refreshControl}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={globalStyle.width100Percent}
      ListEmptyComponent={<ListEmptyComponent filterTerm={filterTerm} />}
      data={connectedOrganizations}
      renderItem={({ item }: { item: ConnectedOrganization }) => (
        <CourierConnectedAgenciesCardItem connectedOrganization={item} />
      )}
    />
  );
};

const styles = StyleService.create({
  noResultsView: {
    padding: 20,
  },
});
