import React from 'react';
import { ScrollView } from 'react-native';

import { DeletedAccountsBoxComponent } from '../../components/priojet/deleted-accounts.component';
import { TopNavigationWithoutAccessories } from '../../components/top-navigation-without-accessories.component';

export const PriojetHomeScreen = (): React.ReactElement => (
  <>
    <TopNavigationWithoutAccessories title="PRIOjet Home" />
    <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
      <DeletedAccountsBoxComponent />
    </ScrollView>
  </>
);
