import { useState } from 'react';

import { AirportLocationList_airportLocationList } from '../../../../graphql-types';

export const useHook = () => {
  const [airportData, setAirportData] = useState<AirportLocationList_airportLocationList[]>([]);

  const handleAirportDataLoaded = (res: {
    airportLocationList: AirportLocationList_airportLocationList[];
  }) => {
    if (!airportData.length) {
      if (res.airportLocationList) {
        setAirportData(res.airportLocationList);
      }
    }
  };

  return {
    airportData,
    handleAirportDataLoaded,
  };
};
