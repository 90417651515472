import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { useMutation } from '@apollo/client';
import { Button, StyleService } from '@ui-kitten/components';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Divider } from 'react-native-elements';
import { showMessage } from 'react-native-flash-message';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';

import { CREATE_USER_FREQUENT_FLYER_DOCUMENT_FRAGMENT } from '../../apollo/graphql-fragments';
import { CREATE_USER_FREQUENT_FLYER_DOCUMENT_MUTATION } from '../../apollo/graphql-mutations';
import { globalStyle } from '../../common/style';
import {
  AirlineAllianceStatusType,
  AirlineAllianceType,
  UserFrequentFlyerDocument,
} from '../../generated-graphql-types';
import { CreateUserFrequentFlyerDocumentVariables, User_user } from '../../graphql-types';
import {
  AirlineAllianceStatusTypeList,
  AirlineAllianceTypeList,
  AirlineStatusTypeList,
  AirlineTypeList,
} from '../../types';
import { uploadDocumentsToServer } from '../../utils/file-upload.util';
import { CourierAddDocumentFile } from '../common/courier.add-document-image-pdf';
import { FormAutocomplete } from '../common/form/form-autocomplete.component';
import { FormTextDatepicker } from '../common/form/form-text-datepicker.component';
import { FormTextInputTopLabel } from '../common/form/form-text-input-top-label.component';
import { FormSelect } from '../common/form/FormSelect';
import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const now = new Date();
const maximumBirthDay = new Date(2100, 1, 1);

export const CourierAddUserFrequentFlyerDocumentComponent = ({
  navigation,
  title,
}: {
  navigation: any;
  title: string;
  user?: User_user;
}): React.ReactElement => {
  const [isSaving, setIsSaving] = useState(false);

  const [airlineStatusFilteredData, setAirlineStatusFilteredData] = useState(AirlineStatusTypeList);
  const [airlineAllianceTypeFilteredData, setAirlineAllianceTypeFilteredData] =
    useState(AirlineAllianceTypeList);
  const [airlineAllianceStatusTypeFilteredData, setAirlineAllianceStatusTypeFilteredData] =
    useState(AirlineAllianceStatusTypeList);

  const { t } = useTranslation();

  const [uploadUserFrequentFlyerDocument] = useMutation<
    { createUserFrequentFlyerDocument: UserFrequentFlyerDocument },
    CreateUserFrequentFlyerDocumentVariables
  >(CREATE_USER_FREQUENT_FLYER_DOCUMENT_MUTATION, {
    async onCompleted(data) {
      if (data.createUserFrequentFlyerDocument) {
        showMessage({
          message: t('document.success', { defaultValue: 'Success' }),
          description: t('document.documentCreatedSuccessfully', {
            defaultValue: 'Document created successfully.',
          }) as string,
          type: 'success',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
        navigation.navigate('CourierProfileNavigator', {
          screen: 'CourierFrequentFlyerDocumentsScreen',
        });
      }
    },
    update(cache, { data }) {
      if (data) {
        cache.modify({
          fields: {
            userFrequentFlyerDocuments(existingUserFrequentFlyerDocuments = []) {
              const newUserFrequentFlyerDocument = cache.writeFragment({
                data: data.createUserFrequentFlyerDocument,
                fragment: CREATE_USER_FREQUENT_FLYER_DOCUMENT_FRAGMENT,
              });
              return [...existingUserFrequentFlyerDocuments, newUserFrequentFlyerDocument];
            },
          },
        });
      }
    },
    onError() {
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    },
  });

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('document.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description:
          Object.keys(errors).length > 5
            ? (t('document.multipleInvalidField', {
                defaultValue:
                  'Multiple required fields are not filled out properly. Please check and try again.',
              }) as string)
            : Object.keys(errors)
                .map((key) => errors[key])
                .join('\n'),
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const { values } = formik;
    let userFrequentFlyerDocumentInput = {
      documentNumber: values.documentNumber,
      expiresOn: values.expiresOn,
      airlineType: values.airlineType,
      airlineStatusType: values.airlineStatusType,
      airlineAllianceType: values.airlineAllianceType,
      airlineAllianceStatusType: values.airlineAllianceStatusType,
    };
    setIsSaving(true);
    try {
      const documentsToUpload = [
        { key: 'front', fieldKey: 'Front', document: values.documentFront },
      ];
      const documentRes = await uploadDocumentsToServer(documentsToUpload);
      if (documentRes?.length) {
        documentRes.forEach((item) => {
          userFrequentFlyerDocumentInput = { ...userFrequentFlyerDocumentInput, ...item };
        });
        await uploadUserFrequentFlyerDocument({ variables: { userFrequentFlyerDocumentInput } });
      }
    } catch (e) {
      console.warn(e);
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }

    setIsSaving(false);
  };

  const validationSchema = Yup.object().shape({
    documentFront: Yup.object()
      .nullable()
      .required(
        t('document.requiredFieldDocumentFront', {
          defaultValue: 'Front side of the document must be set',
        }) as string,
      ),
    airlineType: Yup.string().required(
      t('document.requiredFieldAirline', {
        defaultValue: 'Airline must be set',
      }) as string,
    ),
    airlineStatusType: Yup.string().required(
      t('document.requiredFieldAirlineStatusType', {
        defaultValue: 'Airline status type must be set',
      }) as string,
    ),
    airlineAllianceType: Yup.string().required(
      t('document.requiredFieldAirlineAlliance', {
        defaultValue: 'Airline alliance must be set',
      }) as string,
    ),
    airlineAllianceStatusType: Yup.string().required(
      t('document.requiredFieldAirlineAllianceStatus', {
        defaultValue: 'Airline alliance status must be set',
      }) as string,
    ),
  });

  const initialValues = {
    documentFront: null,
    airlineType: '',
    airlineStatusType: '',
    airlineAllianceType: '',
    airlineAllianceStatusType: '',
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const handleSelectDocument = (_: 'front' | 'back', result: any) => {
    formik?.setFieldValue('documentFront', result);
  };

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);
    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: formik.touched[field] ? fieldError : '',
        onChange,
        status: formik.touched[field] && fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      errorMessage: fieldError || '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const onSelect =
    ({ valueKey }: any) =>
    (value: any) => {
      formik?.setFieldValue(valueKey, value);
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const onSelectAutocomplete =
    ({ valueKey }: any) =>
    (value: any) => {
      if (value) {
        formik?.setFieldValue(valueKey, value);

        const airlineStatusFiltered = AirlineStatusTypeList.filter((obj) =>
          obj.link.includes(value),
        );
        let airlineAllianceTypeFiltered = AirlineAllianceTypeList.filter((obj) =>
          obj.link.includes(value),
        );

        let airlineAllianceStatusTypeFiltered = [];
        if (airlineAllianceTypeFiltered?.length) {
          airlineAllianceStatusTypeFiltered = AirlineAllianceStatusTypeList.filter((obj) =>
            obj.link.includes(airlineAllianceTypeFiltered[0].value),
          );
        } else {
          airlineAllianceTypeFiltered = [
            { key: 'None', name: 'None', value: AirlineAllianceType.NONE, link: '' },
          ];
          airlineAllianceStatusTypeFiltered = [
            { key: 'None', name: 'None', value: AirlineAllianceStatusType.NONE, link: '' },
          ];
        }

        setAirlineStatusFilteredData(airlineStatusFiltered);
        setAirlineAllianceTypeFilteredData(airlineAllianceTypeFiltered);
        setAirlineAllianceStatusTypeFilteredData(airlineAllianceStatusTypeFiltered);
        if (airlineAllianceTypeFiltered.length === 1) {
          formik.setFieldValue('airlineAllianceType', airlineAllianceTypeFiltered[0].value);
        }
        if (airlineAllianceStatusTypeFiltered.length === 1) {
          formik.setFieldValue(
            'airlineAllianceStatusType',
            airlineAllianceStatusTypeFiltered[0].value,
          );
        }
      } else {
        setAirlineStatusFilteredData(AirlineStatusTypeList);
        setAirlineAllianceTypeFilteredData(AirlineAllianceTypeList);
        setAirlineAllianceStatusTypeFilteredData(AirlineAllianceStatusTypeList);
        formik.setValues({
          ...formik.values,
          airlineType: '',
          airlineStatusType: '',
          airlineAllianceType: '',
          airlineAllianceStatusType: '',
        });
      }

      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const renderSelect = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormSelect
        appearance="default"
        containerStyle={styles.input}
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey })}
        items={options}
        value={formik.values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={formik?.errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderDatePicker = ({
    label,
    min,
    max,
    valueKey,
  }: {
    label: string;
    min: any;
    max: any;
    valueKey: string;
  }) => (
    <FormTextDatepicker
      label={label}
      containerStyle={styles.input}
      min={min}
      max={max}
      date={formik.values[valueKey] && formik.values[valueKey]?.toJSDate()}
      onChange={(value) => {
        formik?.setFieldValue(valueKey, DateTime.fromJSDate(value));
        setTimeout(() => {
          if (formik?.errors[valueKey]) {
            formik.validateField(valueKey);
          }
        }, 100);
      }}
      errorMessage={formik?.errors[valueKey]}
      isMandatory
    />
  );

  return (
    <>
      <TopNavigationWithBackButton
        accessoryRight={() => (
          <Button onPress={handleSave} appearance="filled" style={styles.saveBtn}>
            {isSaving ? (
              <ActivityIndicator />
            ) : (
              (t('common.save', { defaultValue: 'Save' }) as string)
            )}
          </Button>
        )}
        title={title}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierProfileNavigator', {
            screen: 'CourierFrequentFlyerDocumentsScreen',
          });
        }}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={styles.contentContainer}
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
      >
        <>
          <CourierAddDocumentFile
            identityPlaceholder
            documentFront={formik.values.documentFront}
            hasBackPage={false}
            onSelectDocument={handleSelectDocument}
          />
          <Divider style={globalStyle.marginBottom10} />

          <View style={globalStyle.padding20}>
            <FormTextInputTopLabel
              containerStyle={styles.input}
              label={
                t('document.frequentFlyerNumber', {
                  defaultValue: 'FREQUENT FLYER NUMBER',
                }) as string
              }
              isMandatory
              {...fieldProps('documentNumber')}
            />

            {renderDatePicker({
              label: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }) as string,
              min: now,
              max: maximumBirthDay,
              valueKey: 'expiresOn',
            })}

            <FormAutocomplete
              appearance="default"
              label={t('document.airline', { defaultValue: 'AIRLINE' }) as string}
              containerStyle={styles.input}
              onChangeText={(val: string) => {
                formik.setFieldValue('airlineText', val);
              }}
              fieldTitleKey="name"
              fieldValueKey="value"
              value={formik.values.airlineType}
              onSelect={
                onSelectAutocomplete({ fieldValueKey: 'value', valueKey: 'airlineType' }) as any
              }
              items={AirlineTypeList}
              isMandatory
            />
            {renderSelect({
              disabled: !formik.values.airlineType,
              label: t('document.airlineStatus', { defaultValue: 'AIRLINE STATUS' }) as string,
              valueKey: 'airlineStatusType',
              options: airlineStatusFilteredData,
              fieldTitleKey: 'name',
              fieldValueKey: 'value',
            })}

            {renderSelect({
              disabled: !formik.values.airlineType || airlineAllianceTypeFilteredData?.length === 1,
              label: t('document.airlineAlliance', {
                defaultValue: 'AIRLINE ALLIANCE',
              }) as string,
              valueKey: 'airlineAllianceType',
              options: airlineAllianceTypeFilteredData,
              fieldTitleKey: 'name',
              fieldValueKey: 'value',
            })}

            {renderSelect({
              disabled:
                !formik.values.airlineType || airlineAllianceStatusTypeFilteredData?.length === 1,
              label: t('document.airlineAllianceStatus', {
                defaultValue: 'AIRLINE ALLIANCE STATUS ',
              }) as string,
              valueKey: 'airlineAllianceStatusType',
              options: airlineAllianceStatusTypeFilteredData,
              fieldTitleKey: 'name',
              fieldValueKey: 'value',
            })}
          </View>
        </>
      </KeyboardAwareScrollView>
    </>
  );
};
const styles = StyleService.create({
  contentContainer: {
    paddingVertical: 24,
  },
  descriptionText: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  saveBtn: {
    height: 44,
    width: 75,
  },
});
