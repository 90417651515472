import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },
  flex1: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  jobCountHeaderText: {
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
    color: 'text-primary-color',
    fontSize: 12,
  },
  jobCountNumberText: {
    fontSize: 30,
    fontFamily: 'Lato_700Bold',
    paddingTop: 10,
  },
  jobCountViewRunning: {
    borderBottomColor: 'color-info-400',
  },
  jobCountViewUpcoming: {
    borderBottomColor: 'color-danger-400',
  },
  jobCountViewNew: {
    borderBottomColor: 'color-warning-400',
  },
  jobCountViewPast: {
    borderBottomColor: 'color-success-400',
  },
  jobCountView: {
    flex: 1,
    alignItems: 'center',
    borderBottomWidth: 5,
    margin: 1,
  },
  buttonsContainer: {
    marginTop: 20,
  },
  buttonsRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonsColumn: {
    flexDirection: 'column',
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginLeft3: {
    marginLeft: 3,
  },
  marginRight3: {
    marginRight: 3,
  },
});
