import { DateTime } from 'luxon';

import {
  Organization,
  OrganizationInvitation,
  OrganizationOrganization,
  OrganizationUser,
  OrganizationUserRoleType,
  User,
} from '../../generated-graphql-types';

export const getOrganizationDescription = (
  organization: Partial<Organization> | undefined,
): string => {
  if (!organization) {return 'n/a';}
  let title = organization.organizationType + ' | ' + organization.legalName;
  if (organization.organizationUsers && organization.organizationUsers.length > 0) {
    title += ' (' + organization.organizationUsers?.length + ' users)';
    const owners = organization.organizationUsers?.filter(
      (organizationUser) => organizationUser.roleType == OrganizationUserRoleType.OWNER,
    );
    if (owners.length > 0) {
      title +=
        ' - owned by ' +
        owners[0].user.firstNames +
        ' ' +
        owners[0].user.lastName +
        ' (' +
        owners[0].user.email +
        ')';
    }
    if (organization?.usedOrganizationInvitation?.usedAt) {
      title +=
        ' (Activated at: ' +
        organization?.usedOrganizationInvitation?.usedAt.toLocaleString(
          DateTime.DATETIME_SHORT,
        ) +
        ')';
    } else {
      title += ' (NOT ACTIVATED)';
    }
  } else {
    title += ' (0 users)';
  }
  return title;
};

export const getUserDescription = (
  user: Partial<User> | undefined,
  organizationInvitation: Partial<OrganizationInvitation>,
): string => {
  if (!user) {return 'n/a';}
  let title =
    user?.firstNames +
    ' ' +
    user?.lastName +
    ' (' +
    user?.email +
    ') as ' +
    organizationInvitation.roleType;
  return title;
};

export const getOrganizationUserDescription = (
  organizationUser: Partial<OrganizationUser> | undefined,
  withOrganization: boolean = true,
): string => {
  if (!organizationUser) {return 'n/a';}
  let title =
    organizationUser.user?.firstNames +
    ' ' +
    organizationUser.user?.lastName +
    ' (' +
    organizationUser.user?.email +
    ') as ' +
    organizationUser.roleType +
    (withOrganization
      ? ' for organization ' + getOrganizationDescription(organizationUser.organization)
      : '');
  return title;
};

export const getOrganizationOrganizationDescription = (
  organizationOrganization: Partial<OrganizationOrganization> | undefined,
): string => {
  if (!organizationOrganization) {return 'n/a';}
  let title =
    organizationOrganization.connectionType +
    '-Connection between ' +
    getOrganizationDescription(organizationOrganization.connectedOrganization) +
    ' and ' +
    getOrganizationDescription(organizationOrganization.connectingOrganization);
  return title;
};
