import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { getApolloCacheInstance } from './apollo-cache';
import { createAuthLinkWithHeaders } from './create-auth-link-with-headers';
import { errorLink } from './error-link';
import { httpLink } from './http-link';
import { retryLink } from './retry-link';
import { scalarsLink } from './scalars-link';
import { IAppContext } from '../contexts/AppContext';
import { LocalUserContext } from '../types';

export const createApolloClientNoPersist = ({
  customHeaders,
  userContext,
  appContext,
}: {
  customHeaders?: object | undefined;
  userContext?: LocalUserContext | undefined;
  appContext?: IAppContext | undefined;
} = {}): ApolloClient<NormalizedCacheObject> => {
  const authLink = createAuthLinkWithHeaders(userContext, customHeaders, {
    appContext: appContext,
  });

  const cache = getApolloCacheInstance();

  const apolloClient = new ApolloClient({
    link: scalarsLink.concat(authLink).concat(errorLink).concat(retryLink).concat(httpLink),
    cache: cache,
  });

  return apolloClient;
};
