import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  messageText: {
    color: 'white',
  },
});
