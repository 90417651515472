import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  saveBtn: {
    height: 40,
    width: 80,
  },
  contentContainer: {
    paddingVertical: 24,
  },
  layoutSocialMediaContainer: {
    padding: 20,
  },
  contentBox: {
    paddingVertical: 10,
    paddingBottom: 15,
    marginBottom: 10,
  },
  socialRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  socialIconContainer: {
    width: 30,
    padding: 8,
    paddingLeft: 0,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  popoverLayoutstyle: {
    paddingHorizontal: 2,
    paddingVertical: 4,
  },
  layoutCreditCardsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  formView: {
    paddingVertical: 16,
  },
  section: {
    marginTop: 24,
  },
  avatar: {
    width: 150,
    height: 150,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  sectionEmergencyContactLabel: {
    paddingTop: 6,
    paddingBottom: 2,
  },
  sectionLabel: {
    marginTop: 24,
    marginBottom: 8,
    marginHorizontal: 16,
  },
  profileDescription: {
    paddingTop: 6,
    paddingBottom: 2,
  },
  cardImage: { width: 40, height: 40, marginRight: 15 },
  cardsInternalLayout: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
    alignContent: 'space-between',
    alignItems: 'stretch',
  },
  divider: {
    marginTop: 5,
    marginBottom: 10,
  },
  titleStyle: {
    fontSize: 14,
    color: 'color-primary-600',
  },

  alertContainer: {
    flexDirection: 'row',
    padding: 8,
    margin: 10,
    marginBottom: 20,
    borderColor: '#ee2200',
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: 'pink',
  },
  alertIcon: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  alertTitle: {
    color: 'black',
  },
  alertText: {
    color: 'black',
    fontSize: 15,
  },
  listCircle: {
    height: 6,
    width: 6,
    marginTop: 2,
    marginLeft: 4,
    marginRight: 6,
    borderRadius: 8,
    backgroundColor: 'text-basic-color',
  },
});
