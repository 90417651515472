import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  documentName: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'border-basic-color-4',
    backgroundColor: 'background-basic-color-4',
    borderRadius: 4,
    marginRight: 5,
    marginBottom: 10,
    padding: 15,
    paddingTop: 6,
    paddingBottom: 10,
    maxWidth: 300,
  },
  closeButton: {
    height: 34,
    width: 34,
    borderRadius: 17,
    marginTop: -3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '',
  },
  closeIcon: {
    color: 'text-basic-color',
  },
});
