import React, { useState } from 'react';
import { FlatList, Platform, RefreshControl, View } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { Text } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../common/style';
import { AgencyJob } from '../../../generated-graphql-types';
import { LoadingSpinner } from '../../common/loading-spinner.component';
import { AgencyJobCardItem } from '../AgencyJobCardItem';
import { AgencyJobViewCouriersModal } from '../AgencyJobCardItem/AgencyJobViewCouriersModal';

export const AgencyJobsList = ({
  isLoading,
  jobs,
  refetch,
  previousScreenName,
  previousTabScreenName,
  showInternalNotesModal,
}: {
  jobs: Partial<AgencyJob>[] | undefined;
  refetch: any;
  isLoading: boolean;
  previousScreenName: string;
  previousTabScreenName:
    | 'AgencyNewJobsScreen'
    | 'AgencyStartedJobsScreen'
    | 'AgencyFinishedJobsScreen'
    | 'AgencyCancelledJobsScreen';
  showInternalNotesModal: (data: { agencyJobId: string; internalNotes: string }) => void;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedJob, setSelectedJob] = useState<AgencyJob | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      setSelectedJob(undefined);
    }, 400);
  };

  const handleViewCouriersDetails = (agencyJob: AgencyJob) => {
    setSelectedJob(agencyJob);
    setTimeout(() => {
      setIsModalVisible(true);
    }, 600);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const keyExtractor = (item: AgencyJob) => item.id as string;

  const renderListEmptyComponent = () => {
    if (isLoading && !jobs?.length) {
      return (
        <View style={[globalStyle.flex1, globalStyle.height300]}>
          <LoadingSpinner subject="Agency Jobs" />
        </View>
      );
    }
    let name = 'cancelled';
    if (previousTabScreenName.toLowerCase().includes('new')) {
      name = 'new';
    } else if (previousTabScreenName.toLowerCase().includes('started')) {
      name = 'started';
    } else if (previousTabScreenName.toLowerCase().includes('finished')) {
      name = 'finished';
    }
    return (
      <Text style={[globalStyle.alignSelfCenter, globalStyle.padding20, globalStyle.paddingTop30]}>
        {
          t('There are currently no {{jobStatus}} jobs.', {
            defaultValue: 'There are currently no {{jobStatus}} jobs.',
            jobStatus: name,
          }) as string
        }
      </Text>
    );
  };

  const renderItem = ({ item }: { index: number; item: AgencyJob }) => (
    <AgencyJobCardItem
      agencyJob={item}
      onViewCouriersDetails={handleViewCouriersDetails}
      previousScreenName={previousScreenName}
      previousTabScreenName={previousTabScreenName}
      showInternalNotesModal={showInternalNotesModal}
    />
  );

  return (
    <>
      <FlatList
        showsHorizontalScrollIndicator={false}
        ListEmptyComponent={renderListEmptyComponent()}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            title="Loading Jobs..."
            tintColor={(styles.refreshColor as Record<string, string>).color}
            titleColor={(styles.refreshColor as Record<string, string>).color}
          />
        }
        data={jobs as any}
        renderItem={renderItem}
        contentContainerStyle={
          Platform.OS === 'web' ? globalStyle.paddingHorizontal10 : globalStyle.paddingHorizontal5
        }
        keyExtractor={keyExtractor}
      />
      <AgencyJobViewCouriersModal
        agencyJob={selectedJob}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        previousScreenName={previousScreenName}
        previousTabScreenName={previousTabScreenName}
      />
    </>
  );
};

const themedStyles = StyleService.create({
  refreshColor: {
    color: 'text-basic-color',
  },
  cardHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    height: 30,
    alignContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: 'color-primary-500',
  },
  cardHeaderText: {
    fontFamily: 'Lato_700Bold',
    color: 'color-basic-100',
  },
  timeDistanceLayer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  incidentIconColor: {
    backgroundColor: 'color-primary-300',
  },
  incidentIcon: {
    marginLeft: 15,
  },
  card: {
    marginBottom: 10,
    paddingHorizontal: 0,
    marginTop: 10,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: 'color-primary-500',
  },
  cardFooter: {
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'border-basic-color-2',
    borderWidth: 0,
    borderColor: 'color-primary-500',
  },
  cardFooterText: {
    color: 'text-basic-color',
    fontSize: 12,
  },
  dateTimeWithTimeZoneWrapper: {
    paddingVertical: 2,
    paddingLeft: 10,
  },
  agencyJobStatusChangeLayer: {
    flex: 1,
    flexDirection: 'column',
  },
  agencyJobStatusChangeHintText: {
    flexDirection: 'row',
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: 'gray',
    fontSize: 12,
  },
  agencyJobStatusChangeHintLayer: {
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
    marginRight: 20,
  },
  agencyJobStatusChangeButtonLayer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  agencyJobStatusOutsideLayer: {
    marginHorizontal: 10,
    flexDirection: 'row',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 5,
  },
  agencyJobStatusLayer: {
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderBottomWidth: 0,
    borderColor: 'color-border-low-contrast',
    borderRadius: 0,
    // marginRight: 8, // see element for MediaQuery switch.
  },
  agencyJobStatusText: {
    fontSize: 10,
    color: 'color-text-low-contrast',
    textTransform: 'uppercase',
  },
  agencyJobStatusCountText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 10,
    color: 'color-text-low-contrast',
  },
  agencyJobMenuItemsWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingBottom: 5,
    marginBottom: 5,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  agencyJobMenuWrapperLeft: {
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  agencyJobStatusWrapper: {
    flexDirection: 'column',
  },
  spacedElement: {
    margin: 5,
  },
  icon: {
    backgroundColor: 'color-primary-300',
  },
  locationName: {
    fontSize: 16,
  },
  menuWrapper: {
    paddingTop: 10,
    marginBottom: 0,
    backgroundColor: 'border-basic-color-2',
  },
  locationsOutsideWrapper: {},
  locationsOutsideWrapperHeader: {
    fontFamily: 'Lato_700Bold',
    marginVertical: 7,
    fontSize: 10,
    textAlign: 'center',
    backgroundColor: 'border-basic-color-2',
  },
  locationWrapper: {
    borderWidth: 0,
    paddingHorizontal: 10,
  },
  iconLocationNameWrapper: {},
  startIcon: {
    marginRight: 15,
    paddingRight: 10,
  },
  endIcon: {
    marginRight: 15,
    paddingRight: 10,
  },
  arrowIcon: {
    marginHorizontal: 5,
  },
  locationBox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderWidth: 0,
  },
  locationBoxIconNameWrapper: {
    flex: 1,
    // flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    alignItems: 'baseline',
    borderWidth: 0,
  },
  locationBoxRight: {
    paddingLeft: 15,
    flex: 1,
  },
  locationBoxLeft: {
    flex: 1,
  },
});

export default AgencyJobsList;
