import React from 'react';

import { Card, Divider, StyleService, Text, useStyleSheet } from '@ui-kitten/components';

import { OrganizationInvitation } from '../../../../generated-graphql-types';
import { CourierOrganizationInvitationCardDescription } from '../CourierOrganizationInvitationCardDescription';
import { OrganizationInvitationCardItemHeader } from '../OrganizationInvitationCardHeader';

export const CourierOrganizationInvitationCard = ({
  organizationInvitation,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <Card
      key={organizationInvitation.id}
      style={styles.card}
      header={(headerProps: any) => (
        <OrganizationInvitationCardItemHeader
          {...headerProps}
          organizationInvitation={organizationInvitation}
        />
      )}
    >
      {organizationInvitation.organizationContact?.firstNames && (
        <Text selectable={true} category="s1" style={styles.organizationName}>
          {organizationInvitation.organizationContact?.firstNames as string}{' '}
          {organizationInvitation.organizationContact?.lastName as string}
        </Text>
      )}
      <Divider style={styles.divider} />
      <CourierOrganizationInvitationCardDescription
        organizationInvitation={organizationInvitation}
      />
    </Card>
  );
};

const themedStyles = StyleService.create({
  card: {
    margin: 10,
    padding: 10,
    backgroundColor: 'background-basic-color-2',
  },
  header: {
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  empty: {
    padding: 20,
    textAlign: 'center',
  },
  divider: {
    marginVertical: 5,
  },
  organizationName: {
    paddingTop: 5,
    fontWeight: 'bold',
  },
});
