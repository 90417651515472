import React from 'react';
import { TextStyle } from 'react-native';

import { Text } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';

import { TimeAgoPopover } from './time-ago-popover.component';

export const ConnectedAtByText = ({
  createdAt,
  textStyle,
}: {
  firstNames: string;
  lastName: string;
  email: string;
  createdAt: DateTime;
  userId?: string;
  textStyle?: TextStyle;
}) => {
  const { i18n, t } = useTranslation();

  return (
    <Text style={textStyle}>
      <Trans
        i18nKey="common.connectedAtByUserTextTimeAgo"
        t={t}
        components={{
          TimeAgoPopover: (
            <TimeAgoPopover date={createdAt} language={i18n.language} textStyle={textStyle} />
          ),
        }}
        defaults="Connected <TimeAgoPopover></TimeAgoPopover>"
      />
    </Text>
  );
};
