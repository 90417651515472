import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  scrollView: {
    padding: 10,
  },
  flex1: {
    flex: 1,
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  padding10: {
    padding: 10,
  },
  courierOrganizationUser: {
    margin: 10,
    marginBottom: 0,
    marginTop: 20,
    padding: 15,
    borderRadius: 4,
    borderWidth: 2,
    borderColor: 'border-primary-color-1',
    backgroundColor: 'color-basic-transparent-200',
  },
  courierOrganizationUserText: {
    fontFamily: 'Lato_700Bold',
  },
  contentBoxContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop: 10,
  },
  radioGroupContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
  },
  radioInput: {
    flex: 1,
    paddingHorizontal: 10,
  },
  cardImage: {
    flex: 1,
    flexWrap: 'wrap',
    width: 400,
    height: 300,
    resizeMode: 'contain',
  },
  buttonBar: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  iconColor: {
    backgroundColor: 'color-primary-300',
  },
  icon: {
    marginRight: 15,
  },
  textarea: {
    height: 80,
  },
  saveBtn: {
    height: 44,
    width: 75,
  },
  loading: {
    color: 'color-primary-300',
  },
  paddingBottom20: {
    paddingBottom: 20,
  },
  marginBottom50: {
    marginBottom: 50,
  },
  marginVertical10: {
    marginVertical: 10,
  },
  externalJobReference: {
    flex: 1,
    fontFamily: 'Lato_700Bold',
    fontSize: 24,
  },
  header: {
    flex: 1,
    padding: 10,
  },
  label: {
    fontSize: 14,
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
  },
  pickupTime: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center',
  },
  marginBottom20: {
    marginBottom: 20,
  },
  locationLabel: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingVertical: 5,
  },
});
