import React from 'react';
import { Text, TouchableOpacity } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { Divider, TopNavigation, TopNavigationAction } from '@ui-kitten/components/ui';

import { ArrowIosBackIcon } from './icons.component';
import { globalStyle } from '../common/style';
import useDimensions from '../hooks/useDimensions';

export const TopNavigationWithBackButton = ({
  accessoryLeft,
  accessoryRight,
  alignment,
  navigation,
  title,
  subtitle,
  onLayout,
  onPressLeft,
  onTitlePress,
  onSubtitlePress,
}: {
  accessoryLeft?: () => React.ReactElement;
  accessoryRight?: () => React.ReactElement;
  alignment?: 'center' | 'start';
  navigation: any;
  title: string;
  subtitle?: string;
  onLayout?: (event: any) => void;
  onPressLeft?: () => void;
  onTitlePress?: () => void;
  onSubtitlePress?: () => void;
}) => {
  const styles = useStyleSheet(themedStyle);
  const { dimensions } = useDimensions();

  const handleBackButtonPress = () => {
    if (onPressLeft) {
      onPressLeft();
    } else {
      navigation.goBack();
    }
  };

  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction
      style={[globalStyle.paddingHorizontal10, globalStyle.paddingVertical5]}
      icon={ArrowIosBackIcon}
      onPress={handleBackButtonPress}
    />
  );

  const renderTitle = (props: any) => (
    <TouchableOpacity activeOpacity={0.9} onPress={onTitlePress} style={styles.titleContainer}>
      <Text
        numberOfLines={2}
        ellipsizeMode="tail"
        {...props}
        style={[styles.title, { maxWidth: dimensions.window.width / 2 }]}
      >
        {title.toUpperCase()}
      </Text>
    </TouchableOpacity>
  );

  const renderSubTitle = () => {
    if (subtitle) {
      return (
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={onSubtitlePress}
          style={styles.subtitleContainer}
        >
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            style={[styles.subtitle, { maxWidth: dimensions.window.width / 2 }]}
          >
            {subtitle}
          </Text>
        </TouchableOpacity>
      );
    }
    return;
  };

  return (
    <>
      <TopNavigation
        title={renderTitle}
        subtitle={renderSubTitle()}
        accessoryLeft={accessoryLeft || renderBackAction}
        alignment={alignment || 'center'}
        accessoryRight={accessoryRight}
        style={globalStyle.paddingLeft0}
        onLayout={onLayout}
      />
      <Divider />
    </>
  );
};

const themedStyle = StyleService.create({
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '50%',
    marginBottom: 2,
  },
  title: {
    fontFamily: 'Lato_700Bold',
    fontSize: 16,
    lineHeight: 16,
    color: 'text-basic-color',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  subtitleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '50%',
    minHeight: 20,
    marginBottom: 2,
  },
  subtitle: {
    fontSize: 13,
    color: 'text-basic-color',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});
