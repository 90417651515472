import React, { useMemo } from 'react';
import { View } from 'react-native';

import { Avatar, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { Location, Organization } from '../../../generated-graphql-types';
import i18n from '../../../i18n/i18n';
import { createSocialURL } from '../../../utils/social-media.util';
import ContentBoxTextTitle from '../../content-box-text-title.component';
import { MapViewComponent } from '../../map-view.component';
import { SocialIcon, SocialKeysType } from '../../social-icons.component';
import { CopyableText } from '../common.copyable-text.component';
import { EmailTextWithContextMenu } from '../common.email-text-with-context-menu';
import { LocationTextWithContextMenu } from '../common.location-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../common.phone-text-with-context-menu';
import ImageLightBox from '../ImageLightBox';

const ADDRESS_DETAILS_FIELDS = [
  {
    field: 'addressName',
    value: i18n.t('common.addressName', { defaultValue: 'Address name' }),
  },
  {
    field: 'streetName',
    value: i18n.t('common.street', { defaultValue: 'Street' }),
  },
  {
    field: 'houseNumber',
    value: i18n.t('common.houseNumber', { defaultValue: 'House number' }),
  },
  {
    field: 'postalCode',
    value: i18n.t('common.postalCode', { defaultValue: 'Postal Code' }),
  },
  {
    field: 'city',
    value: i18n.t('common.city', { defaultValue: 'City' }),
  },
  {
    field: 'state',
    value: i18n.t('common.state', { defaultValue: 'State' }),
  },
  {
    field: 'country',
    value: i18n.t('common.country', { defaultValue: 'Country' }),
  },
];

export const AgencyProfileReadyOnlyComponent = ({
  organization,
}: {
  organization: Organization;
}): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const socialProfiles = useMemo(() => {
    const socialData: any = organization?.socialProfiles || [];
    return socialData;
  }, [organization]);

  const headquartersAddress = useMemo(
    (): any => organization?.headquartersAddressLocation,
    [organization],
  );

  const headquartersAddressGeometry = useMemo(
    () => headquartersAddress?.locationGeometry,
    [headquartersAddress],
  );

  const invoicingAddress = useMemo(
    (): any => organization?.invoicingAddressLocation as Location,
    [organization],
  );

  const invoicingAddressGeometry = useMemo(
    () => invoicingAddress?.locationGeometry,
    [invoicingAddress],
  );

  const renderCopyableTextValue = (value?: string | null) => {
    if (value) {
      return <CopyableText copyableTextName={value} text={value} />;
    }
    return (
      <Text selectable={true} style={styles.valueTextMissing}>
        -
      </Text>
    );
  };

  const renderGeneralInformation = () => (
    <>
      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.agencyName', {
              defaultValue: 'Agency name',
            }) as string
          }
        </Text>
        {renderCopyableTextValue(organization?.name)}
      </View>

      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.agencyShortName', {
              defaultValue: 'Agency short name',
            }) as string
          }
        </Text>
        {renderCopyableTextValue(organization?.shortName)}
      </View>

      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.primaryEmailAddress', {
              defaultValue: 'Primary agency email address',
            }) as string
          }
        </Text>
        {organization?.email ? (
          <EmailTextWithContextMenu email={organization?.email} />
        ) : (
          <Text selectable={true} style={styles.valueTextMissing}>
            {
              t('courierAgencyProfile.emailNotProvided', {
                defaultValue: 'Email not provided',
              }) as string
            }
          </Text>
        )}
      </View>

      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.primaryPhoneNumber', {
              defaultValue: 'Primary agency phone number',
            }) as string
          }
        </Text>
        {organization?.phone ? (
          <PhoneTextWithContextMenu phone={organization?.phone} />
        ) : (
          <Text selectable={true} style={styles.valueTextMissing}>
            {
              t('courierAgencyProfile.phoneNotProvided', {
                defaultValue: 'Phone not provided',
              }) as string
            }
          </Text>
        )}
      </View>
    </>
  );

  const renderLegalInformation = () => (
    <>
      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.agencyLegalName', {
              defaultValue: 'Agency legal name',
            }) as string
          }
        </Text>
        {renderCopyableTextValue(organization?.legalName)}
      </View>

      {!!headquartersAddressGeometry?.location && (
        <View style={styles.value}>
          <Text selectable={true} style={styles.valueTitleText}>
            {
              t('courierAgencyProfile.headquartersAgencyAddress', {
                defaultValue: 'Address of the agency headquarters',
              }) as string
            }
          </Text>
          <View style={styles.value}>
            <LocationTextWithContextMenu
              location={headquartersAddress}
              textStyleNoAddress={styles.valueTextMissing}
            />
          </View>
        </View>
      )}

      {!!headquartersAddress &&
        ADDRESS_DETAILS_FIELDS.map((item) => (
          <View key={item.field} style={styles.value}>
            <Text selectable={true} style={styles.valueTitleText}>
              {t(item.field, { defaultValue: item.value }) as string}
            </Text>
            {renderCopyableTextValue(headquartersAddress?.[item.field])}
          </View>
        ))}

      {!!headquartersAddressGeometry && (
        <View style={themedStyles.mapContainer}>
          <MapViewComponent
            latitude={headquartersAddressGeometry?.location.lat}
            longitude={headquartersAddressGeometry?.location.lng}
            markerTitle={headquartersAddress?.formattedAddress || ''}
            height={150}
            showMarker
            latitudeDelta={5}
            longitudeDelta={5}
          />
        </View>
      )}

      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.companyRegistrationNumber', {
              defaultValue: 'Company registration number',
            }) as string
          }
        </Text>
        {renderCopyableTextValue(organization?.registrationNumber)}
      </View>
      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.vatId', {
              defaultValue: 'Tax registration number (VAT ID)',
            }) as string
          }
        </Text>
        {renderCopyableTextValue(organization?.vatId)}
      </View>
    </>
  );

  const renderInvoicingAddressContent = () => (
    <>
      {!!invoicingAddressGeometry?.location && (
        <View style={styles.value}>
          <Text selectable={true} style={styles.valueTitleText}>
            {
              t('courierAgencyProfile.officialPostalInvoiceAddress', {
                defaultValue: 'Official postal invoice address',
              }) as string
            }
          </Text>
          <View style={styles.value}>
            <LocationTextWithContextMenu
              location={invoicingAddress}
              textStyleNoAddress={styles.valueTextMissing}
            />
          </View>
        </View>
      )}

      {!!headquartersAddress &&
        ADDRESS_DETAILS_FIELDS.map((item) => (
          <View key={item.field} style={styles.value}>
            <Text selectable={true} style={styles.valueTitleText}>
              {t(item.field, { defaultValue: item.value }) as string}
            </Text>
            {renderCopyableTextValue(invoicingAddress?.[item.field])}
          </View>
        ))}

      {!!invoicingAddressGeometry && (
        <View style={themedStyles.mapContainer}>
          <MapViewComponent
            latitude={invoicingAddressGeometry?.location.lat}
            longitude={invoicingAddressGeometry?.location.lng}
            markerTitle={invoicingAddress?.formattedAddress || ''}
            height={150}
            showMarker
            latitudeDelta={5}
            longitudeDelta={5}
          />
        </View>
      )}

      <View style={styles.value}>
        <Text selectable={true} style={styles.valueTitleText}>
          {
            t('courierAgencyProfile.invoiceEmail', {
              defaultValue: 'Invoice Email Address',
            }) as string
          }
        </Text>
        <View style={styles.value}>
          {organization?.invoiceEmail ? (
            <EmailTextWithContextMenu email={organization?.invoiceEmail} />
          ) : (
            <Text selectable={true} style={styles.valueTextMissing}>
              {
                t('courierAgencyProfile.emailNotProvided', {
                  defaultValue: 'Email not provided',
                }) as string
              }
            </Text>
          )}
        </View>
      </View>
    </>
  );

  const renderSocialContent = () => (
    <>
      {socialProfiles.map((item: any) => {
        if (!item.value) {
          return null;
        }
        return (
          <View key={item.platform} style={themedStyles.socialRow}>
            <View style={themedStyles.socialIconContainer}>
              <SocialIcon fill="#000000" name={item.platform as SocialKeysType} size={20} />
            </View>
            <CopyableText
              copyableTextName={item.platform}
              text={createSocialURL({
                platform: item.platform,
                value: item.value || '',
              })}
            />
          </View>
        );
      })}
    </>
  );

  return (
    <>
      <View style={styles.headerContent}>
        <ImageLightBox
          customImage={
            <Avatar
              size="large"
              style={themedStyles.avatar}
              source={
                organization?.logo?.sasUrl
                  ? {
                      uri: organization?.logo?.sasUrl,
                    }
                  : require('../../../assets/images/logo_placeholder.png')
              }
            />
          }
          expandable={false}
          uri={organization?.logo?.sasUrl}
        />

        <CopyableText
          fontSize={26}
          lineHeight={26}
          copyableTextName={organization?.name}
          text={organization?.name || '-'}
          style={themedStyles.nameText}
        />

        <Text selectable={true} style={styles.valueTitleText}>
          {t('courierAgencyProfile.description', { defaultValue: 'Description' }) as string}
        </Text>
        <Text selectable={true} style={styles.value}>
          {organization?.description || '-'}
        </Text>
      </View>

      <ContentBoxTextTitle
        title={t('courierAgencyProfile.generalInformation', {
          defaultValue: 'General information',
        })}
        style={themedStyles.contentBox}
        Content={renderGeneralInformation()}
      />

      <ContentBoxTextTitle
        title={t('courierAgencyProfile.legalInformation', {
          defaultValue: 'Legal information',
        })}
        style={themedStyles.contentBox}
        Content={renderLegalInformation()}
      />

      <ContentBoxTextTitle
        title={t('courierAgencyProfile.invoicingDetails', {
          defaultValue: 'Invoicing Details',
        })}
        style={themedStyles.contentBox}
        Content={renderInvoicingAddressContent()}
      />

      {!!socialProfiles.length && (
        <ContentBoxTextTitle
          title={t('courierAgencyProfile.socialMedia', {
            defaultValue: 'Social Media',
          })}
          style={themedStyles.contentBox}
          Content={renderSocialContent()}
        />
      )}
    </>
  );
};

const themedStyles = StyleService.create({
  headerContent: {
    padding: 20,
  },
  avatar: {
    width: 150,
    height: 150,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  nameText: {
    marginBottom: 5,
  },
  content: {
    padding: 20,
    paddingTop: 10,
  },
  valueTitleText: {
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
    color: 'color-primary-300',
    marginTop: 10,
    marginBottom: 3,
  },
  value: {
    marginBottom: 12,
  },
  valueTextMissing: {
    color: 'text-disabled-color',
  },
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  socialRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  socialIconContainer: {
    width: 30,
    padding: 8,
    paddingLeft: 0,
  },
  padding20: {
    padding: 20,
  },
  mapContainer: {
    height: 150,
    marginVertical: 20,
  },
});
