import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  saveBtn: {
    height: 44,
    width: 80,
  },
  cardImage: { width: 40, height: 40, marginRight: 15 },

  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  radioGroupContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
  },
  radioInput: {
    flex: 1,
    paddingHorizontal: 10,
  },
  buttonBar: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  iconColor: {
    backgroundColor: 'color-primary-300',
  },

  cardsInternalLayout: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
    alignContent: 'space-between',
    alignItems: 'stretch',
  },

  nameContainer: {
    margin: 10,
    paddingLeft: 10,
    paddingTop: 10,
    flex: 1,
    paddingRight: 10,
  },
  nameText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 24,
  },

  editAvatarRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  chooseImageBtn: {
    padding: 10,
    margin: 2,
    marginTop: 0,
  },
  icon: {
    backgroundColor: '#ccc',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    margin: 10,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 48,
    margin: 2,
  },
  avatar: {
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 10,
    width: 150,
    height: 150,
    borderRadius: 75,
  },
  emailText: {
    padding: 3,
    paddingLeft: 0,
  },
  removeButton: {
    width: 20,
  },
  locationAutocomplete: {
    marginTop: 20,
    marginBottom: 10,
  },
  socialRow: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
  },
  socialIconContainer: {
    marginTop: 50,
    paddingRight: 10,
  },
  socialCopyBtn: {
    margin: 2,
    height: 38,
    marginTop: 42,
  },
  flex1: {
    flex: 1,
  },
  emergencyAddressContainer: {
    marginTop: 20,
  },
  companyAddressContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  companyAddressCheckbox: {
    marginTop: 20,
    marginBottom: 20,
  },
  obcCheckbox: {
    marginTop: 20,
    marginBottom: 10,
  },
  obcMedicalCheckbox: {
    marginTop: 5,
    marginBottom: 10,
  },
  languageLayout: {
    flexDirection: 'row',
    alignContent: 'space-between',
    margin: 2,
  },
  languageSelect: {
    flex: 1,
    margin: 2,
  },
  marginVertical20: {
    marginVertical: 20,
  },
  taxCountryCaption: {
    marginTop: 5,
    fontSize: 13,
  },
  baseAirportInput1: {
    flex: 1,
    marginRight: 2,
  },
  baseAirportInput2: {
    flex: 1,
    marginLeft: 2,
    marginRight: 2,
  },
  baseAirportInput3: {
    flex: 1,
    marginLeft: 2,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
});
