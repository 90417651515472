import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { Camera, PermissionStatus, PermissionResponse } from 'expo-camera';
import * as ImageManipulator from 'expo-image-manipulator';

import { Photo, Props } from './types';
import { useBackHandler } from '../../../hooks/useBackHandler';
import useDimensions from '../../../hooks/useDimensions';

export const useHook = ({ onClose, onSelectPicture, visible }: Props) => {
  const cameraRef = useRef<Camera>(null);
  const {
    dimensions: { window },
  } = useDimensions();

  const [aspectRatio, setAspectRatio] = useState('14:9');

  const [isRatioSet, setIsRatioSet] = useState(false);
  const [photo, setPhoto] = useState<Photo | undefined>(undefined);
  const [uploading, setUploading] = useState<boolean>(false);

  const { height, width } = window;
  const screenRatio = height / width;

  const [permission, setPermission] = useState<PermissionResponse | undefined>();

  // set the camera ratio and padding.
  // this code assumes a portrait mode screen
  const prepareRatio = async () => {
    let desiredRatio = '14:9'; // Start with the system default
    // This issue only affects Android
    if (Platform.OS === 'android') {
      const ratios: any = await cameraRef.current?.getSupportedRatiosAsync();

      // Calculate the width/height of each of the supported camera ratios
      // These width/height are measured in landscape mode
      // find the ratio that is closest to the screen ratio without going over
      let distances: any = {};
      let realRatios: any = {};
      let minDistance = null;
      for (const ratio of ratios) {
        const parts = ratio.split(':');
        const realRatio = parseInt(parts[0], 10) / parseInt(parts[1], 10);
        realRatios[ratio] = realRatio;
        // ratio can't be taller than screen, so we don't want an abs()
        const distance = screenRatio - realRatio;
        distances[ratio] = distance;
        if (minDistance == null) {
          minDistance = ratio;
        } else {
          if (distance >= 0 && distance < distances[minDistance]) {
            minDistance = ratio;
          }
        }
      }
      // set the best match
      desiredRatio = minDistance;
      setAspectRatio(desiredRatio);
      setIsRatioSet(true);
    }
  };

  const handleCameraReady = async () => {
    if (!isRatioSet) {
      await prepareRatio();
    }
  };

  const handleProcessImageWeb = async (res: any) => {
    let ratio = res.height / res.width;
    let size = { height: 2000 * ratio, width: 2000 };
    if (ratio < 1) {
      size = { height: 2000, width: 2000 / ratio };
    }
    return ImageManipulator.manipulateAsync(
      res.uri,
      [{ resize: size }, { rotate: 180 }, { flip: ImageManipulator.FlipType.Vertical }],
      {
        base64: true,
      },
    );
  };

  const handleTakePicture = async () => {
    const options = {
      base64: true,
      exif: true,
      fixOrientation: true,
      isImageMirror: false,
      skipProcessing: true,
    };
    const res = await cameraRef.current?.takePictureAsync(options);
    await cameraRef.current?.pausePreview();
    let resizedPhoto: any;
    if (res) {
      resizedPhoto = await handleProcessImageWeb(res);
      if (resizedPhoto?.base64) {
        setPhoto({
          base64: resizedPhoto.base64,
          exif: res.exif,
          height: resizedPhoto.height,
          fileType: 'image',
          uri: resizedPhoto.uri,
          width: resizedPhoto.width,
        });
      }
    }
  };

  const handleSend = async () => {
    if (photo) {
      setUploading(true);
      const res = await onSelectPicture(photo);
      if (res) {
        onClose();
        setPhoto(undefined);
      }
      setUploading(false);
    }
  };

  const handleClose = async () => {
    onClose();
  };

  const handleReset = () => {
    setPhoto(undefined);
    cameraRef.current?.resumePreview();
  };

  const handleBack = () => {
    if (photo) {
      handleReset();
    } else {
      handleClose();
    }
  };

  const askCameraPermissions = async () => {
    const status: PermissionResponse = await Camera.getCameraPermissionsAsync();
    if (!status.canAskAgain) {
      setPermission(status);
      return;
    }
    if (status.status !== PermissionStatus.UNDETERMINED) {
      setPermission(status);
      return;
    }
    const res = await Camera.requestCameraPermissionsAsync();
    setPermission(res);
  };

  useEffect(() => {
    if (!permission && visible) {
      askCameraPermissions();
    }
  }, [permission, visible]);

  useBackHandler(handleBack);

  return {
    aspectRatio,
    cameraRef,
    handleCameraReady,
    handleClose,
    handleReset,
    handleSend,
    handleTakePicture,
    permission,
    photo,
    uploading,
  };
};
