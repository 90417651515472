import React from 'react';
import { Alert, Platform, Pressable, StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  Icon,
  IconProps,
  IndexPath,
  MenuItem,
  OverflowMenu,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { Text } from '@ui-kitten/components';
import * as ExpoClipboard from 'expo-clipboard';
import * as ExpoLinking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { globalStyle } from '../../common/style';

const EmailIcon = (props: IconProps) => <Icon {...props} name="email-outline" />;
const CopyIcon = (props: IconProps) => <Icon {...props} name="copy-outline" />;

export const EmailTextWithContextMenu = ({
  email,
  subject,
  style,
  textStyle,
  textStyleNoValue,
}: {
  email?: string;
  subject?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textStyleNoValue?: StyleProp<TextStyle>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const [visible, setVisible] = React.useState(false);

  const onSelect = (index: IndexPath) => {
    if (index.row === 0) {
      if (email) {
        ExpoClipboard.setStringAsync(email);
        showMessage({
          message: t('common.Copied', { defaultValue: 'Copied!' }),
          description: t('common.emailCopySuccessMessageDescription', {
            defaultValue: 'Copied email {{email}} to clipbaord.',
            email: email,
          }) as string,
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
          type: 'success',
        });
      } else {
        Alert.alert(
          t('Could not copy email. No email given.', {
            defaultValue: 'Could not copy email. No email given.',
          }),
        );
      }
    } else if (index.row === 1) {
      const url = 'mailto:' + email + '?subject=' + subject || 'Hello';
      ExpoLinking.canOpenURL(url)
        .then(() => {
          ExpoLinking.openURL(url).catch((e) => {
            console.warn(e);
          });
        })
        .catch(() => {
          Alert.alert('Cannot open email client to send email.');
        });
    }
    setVisible(false);
  };

  const renderToggleButton = () => (
    <Pressable onPress={() => setVisible(true)}>
      <View style={[globalStyle.flexRow, style]}>
        <Text selectable={true} style={[styles.textStyle, textStyle]}>
          {email}
        </Text>
        <Icon
          name="diagonal-arrow-right-up-outline"
          width={16}
          height={16}
          style={globalStyle.marginLeft2}
          fill="#777"
        />
      </View>
    </Pressable>
  );

  if (!email) {
    return (
      <Text selectable={true} style={[styles.textStyle, textStyle, textStyleNoValue]}>
        {t('common.noEmailProvided', { defaultValue: 'No email provided' }) as string}
      </Text>
    );
  }
  return (
    <OverflowMenu
      anchor={renderToggleButton}
      visible={visible}
      onSelect={onSelect}
      placement="bottom start"
      onBackdropPress={() => {
        setVisible(false);
      }}
      style={styles.menu}
    >
      <MenuItem
        title={t('common.Copy', { defaultValue: 'Copy' }) as string}
        accessoryLeft={CopyIcon}
      />
      <MenuItem
        title={t('common.sendEmail', { defaultValue: 'Email' }) as string}
        accessoryLeft={EmailIcon}
      />
    </OverflowMenu>
  );
};

const themedStyles = StyleService.create({
  menu: {
    marginTop: Platform.select({ ios: 50, android: 70, web: 0 }),
    flex: 1,
    height: 90,
  },
  textStyle: {
    color: 'text-basic-color',
  },
});
