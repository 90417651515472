import React, { useContext } from 'react';
import { Text, View } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';

import { useHook } from './hook';
import AppUserContext from '../../../contexts/AppUserContext';

const GreetingComponent: React.FC = () => {
  const styles = useStyleSheet(themedStyles);

  const { greeting } = useHook();

  if (!greeting) {
    return <></>;
  }
  return (
    <View style={styles.container}>
      <View style={styles.inner} />
      <Text style={styles.text}>{greeting}</Text>
    </View>
  );
};

export const Greeting = () => {
  const appUserContext = useContext(AppUserContext);

  if (appUserContext.currentUserContext?.user?.firstNames) {
    return <GreetingComponent />;
  }
  return <></>;
};

const themedStyles = StyleService.create({
  container: {
    margin: 15,
    marginBottom: 5,
    padding: 15,
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'color-primary-600',
  },
  inner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'color-primary-600',
    opacity: 0.1,
  },
  text: {
    fontSize: 15,
    fontWeight: '600',
    color: 'text-basic-color',
  },
});
