import React from 'react';
import { View } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { IndexPath, Select, SelectItem, Text, TextProps } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Flag from 'react-native-flags';

import ContentBoxTextTitle from './content-box-text-title.component';
import { globalStyle } from '../common/style';
import { GLOBAL_CONSTANTS } from '../globals';
import { updateDevice } from '../modules/device/device.module';

type Languages = {
  [row: string]: Language;
};

type Language = {
  title: string;
  locale: string;
  code: string;
};

const SwitchLanguageBox = () => {
  const { t, i18n } = useTranslation();

  const languages: Languages = {
    '0': {
      title:
        'English' +
        (t('common.English', { defaultValue: 'English' }) !== 'English'
          ? ' / ' + t('common.English', { defaultValue: 'English' })
          : ''),
      locale: 'en',
      code: 'US',
    },
    '1': {
      title:
        'Deutsch' +
        (t('common.German', { defaultValue: 'German' }) !== 'Deutsch'
          ? ' / ' + t('common.German', { defaultValue: 'German' })
          : ''),
      locale: 'de',
      code: 'DE',
    },
    // '2': {
    //   title:
    //     'Français' +
    //     (t('common.French', { defaultValue: 'French' }) !== 'Français'
    //       ? ' / ' + t('common.French', { defaultValue: 'French' })
    //       : ''),
    //   locale: 'fr',
    //   code: 'FR',
    // },
    // '3': {
    //   title:
    //     'Español' +
    //     (t('common.Spanish', { defaultValue: 'Spanish' }) !== 'Español'
    //       ? ' / ' + t('common.Spanish', { defaultValue: 'Spanish' })
    //       : ''),
    //   locale: 'es',
    //   code: 'ES',
    // },
  };

  const [selectedIndex, setSelectedIndex] = React.useState<IndexPath>(() => {
    let indexPath: IndexPath = new IndexPath(0);
    if (i18n.language) {
      Object.entries(languages).forEach((entry) => {
        if (entry[1].locale === i18n.language) {
          indexPath = new IndexPath(parseInt(entry[0], 10));
        }
      });
    }
    return indexPath;
  });

  const displayValue = languages[selectedIndex.row.toString()].title;

  const onLanguageSelect = async (index: IndexPath) => {
    await AsyncStorage.setItem(
      GLOBAL_CONSTANTS.APP_LANGUAGE_KEY,
      languages[index.row.toString()].locale,
    );
    await updateDevice();
    await i18n.changeLanguage(languages[index.row.toString()].locale);
    setSelectedIndex(index);
  };

  const renderTitle = (language: Language) => (evaProps: TextProps | undefined) => (
    <Text selectable={true} {...evaProps}>
      {language.title}
    </Text>
  );

  const renderOption = (key: string | number, language: Language) => (
    <SelectItem
      key={key}
      title={renderTitle(language)}
      accessoryLeft={() => <Flag code={language.code} type="flat" size={32} />}
    />
  );

  return (
    <ContentBoxTextTitle
      title={t('common.changeLanguage', { defaultValue: 'Change language' })}
      Content={
        <View style={globalStyle.marginVertical5}>
          <Select
            label={(evaProps) => (
              <Text selectable={true} {...evaProps}>
                {
                  t('common.selectLanguageLabel', {
                    defaultValue: 'Change the language of the app here.',
                  }) as string
                }
              </Text>
            )}
            caption={(evaProps) => (
              <Text selectable={true} {...evaProps}>
                {
                  t('common.selectLanguageCaption', {
                    defaultValue: 'The default language is based on your system settings.',
                  }) as string
                }
              </Text>
            )}
            selectedIndex={selectedIndex}
            onSelect={(index) => onLanguageSelect(Array.isArray(index) ? index[0] : index)}
            value={displayValue}
            size="small"
          >
            {Object.entries(languages).map(([key, language]) => renderOption(key, language))}
          </Select>
        </View>
      }
    />
  );
};

export { SwitchLanguageBox };
