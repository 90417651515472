import React from 'react';

import { useQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { StyleService, Tab, TabBar, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { COURIER_ORGANIZATION_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY } from '../../../apollo/graphql-queries';
import { TopNavigationBackToProfileButton } from '../../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { AgencyCourierInvitationOpenInvitationsScreen } from '../AgencyCourierInvitationOpenInvitationsScreen';
import { AgencyCourierInvitationPhoneBookContactsScreen } from '../AgencyCourierInvitationPhoneBookContactsScreen';

const { Navigator, Screen } = createMaterialTopTabNavigator();

const TopTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const styles = useStyleSheet(topBarStyles);
  const { t } = useTranslation();

  return (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => navigation.navigate(state.routeNames[index])}
      style={styles.height50}
    >
      <Tab title={t('menuItems.contacts', { defaultValue: 'Contacts' }).toUpperCase()} />
      <Tab
        title={t('agencyCourierOpenInvitations.courierInvitations', {
          defaultValue: 'Courier Invitations',
        }).toUpperCase()}
      />
    </TabBar>
  );
};

export const AgencyCourierOrganizationInvitationsScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();

  const appUserContext = React.useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const { loading, data, refetch } = useQuery(
    COURIER_ORGANIZATION_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY,
    {
      variables: {
        agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
        userId: appUserContext.currentUserContext?.user?.id as string,
      },
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    },
  );

  return (
    <>
      <TopNavigationBackToProfileButton
        userContext={appUserContext.currentUserContext}
        title={t('menuItems.courierInvitations', {
          defaultValue: 'Courier Invitations',
        })}
        navigation={navigation}
      />
      <Navigator tabBar={(props: any) => <TopTabBar {...props} />}>
        <Screen name="AgencyCourierInvitationPhoneBookContactsScreen">
          {(props: any) => (
            <AgencyCourierInvitationPhoneBookContactsScreen
              isLoading={loading}
              refetch={refetch}
              organizationInvitations={data?.courierOrganizationInvitationsForAgencyOrganization}
              {...props}
            />
          )}
        </Screen>
        <Screen name="AgencyOpenOrganizationInvitationsScreen">
          {(props: any) => (
            <AgencyCourierInvitationOpenInvitationsScreen
              isLoading={loading}
              refetch={refetch}
              organizationInvitations={data?.courierOrganizationInvitationsForAgencyOrganization}
              {...props}
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};

const topBarStyles = StyleService.create({
  height50: { height: 50 },
});
