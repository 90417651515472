import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  card: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
    paddingHorizontal: 0,
    marginTop: 10,
  },
  maxWidth100: {
    maxWidth: 100,
  },
  header: {
    backgroundColor: 'background-basic-color-4',
  },

  footer: {
    minHeight: 40,
  },
  relationshipType: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  relationShipTypeIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginRight: 10,
  },
  relationshipTypeConfirmed: {
    backgroundColor: 'color-success-500',
  },
  relationshipTypeRequested: {
    backgroundColor: 'color-warning-500',
  },
  relationshipTypeDeclined: {
    backgroundColor: 'color-danger-500',
  },
  relationshipTypeUndefined: {
    backgroundColor: 'text-disabled-color',
  },
  distanceText: {
    flex: 9,
    flexGrow: 1,
  },

  successIcon: {
    color: 'color-success-500',
  },
  matchLabel: {
    backgroundColor: 'color-success-500',
    padding: 2,
    paddingLeft: 5,
    paddingRight: 7,
    borderRadius: 3,
  },
  positiveDayLabel: {
    backgroundColor: '#305597',
    padding: 2,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
  },
  negativeDayLabel: {
    backgroundColor: '#9db9e8',
    padding: 2,
    paddingHorizontal: 5,
    borderRadius: 3,
  },
  unavailableDayLabel: {
    backgroundColor: 'text-disabled-color',
    padding: 3,
    paddingHorizontal: 5,
    borderRadius: 3,
  },
  textWhite: {
    color: 'white',
  },
  paddingBottom8: {
    paddingBottom: 8,
  },

  currentlyAvailable: {
    backgroundColor: 'color-success-500',
    width: 10,
    height: 10,
    borderRadius: 5,
    marginTop: 3,
  },
  currentlyUnavailable: {
    backgroundColor: 'text-disabled-color',
    width: 10,
    height: 10,
    borderRadius: 5,
  },
});
