import React, { useContext } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import { Button, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../common/style';
import AppUserContext from '../../../contexts/AppUserContext';
import { Notification } from '../../../generated-graphql-types';
import { NotificationItem, filterOptions } from '../../../screens/common/common.activity.screen';
import ContentBox from '../../content-box.component';
import { FormSelect } from '../form/FormSelect';
import { FormSelectModal } from '../form/FormSelectModal';
import { LoadingIndicator } from '../loading-indicator.component';
import { LoadingSpinner } from '../loading-spinner.component';

export const CommonActivityBox = ({ refreshing }: { refreshing: boolean }) => {
  const { t } = useTranslation();

  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);
  const {
    data,
    filter,
    filterChanged,
    getActivityBoxTitle,
    handleCloseFilterPress,
    handleMarkAllPress,
    handleOpenFilterPress,
    handlesSeeAllPress,
    initialFilter,
    isVisible,
    loading,
    loadingMarkAll,
    notificationsData,
    numOfUnreadNotifications,
    opened,
    onSelectFilter,
  } = useHook({ refreshing });

  const renderFilter = () => {
    if (Platform.OS !== 'web') {
      return (
        <>
          <TouchableOpacity onPress={handleOpenFilterPress}>
            <Icon
              name="funnel-outline"
              fill={(styles.filterIconColor as any).color}
              style={styles.filterIconSize}
              hitSlop={{ top: 5, left: 5, right: 5, bottom: 5 }}
            />
          </TouchableOpacity>
          {opened && (
            <FormSelectModal
              items={filterOptions(appUserContext.currentUserContext?.organizationType)}
              fieldTitleKey="label"
              fieldValueKey="value"
              handleClose={handleCloseFilterPress}
              multiSelect
              onSelect={onSelectFilter}
              value={filter}
              isVisible={isVisible}
            />
          )}
        </>
      );
    }
    let placeholder = '';
    if (filter.length === 0) {
      placeholder = t('common.noFilter', { defaultValue: 'No filter' });
    }
    if (filter.length === initialFilter.length) {
      placeholder = t('common.all', { defaultValue: 'All' });
    }
    return (
      <FormSelect
        containerStyle={[
          styles.filterContainer,
          Platform.OS === 'web' && styles.filterContainerMinWidth,
        ]}
        style={[globalStyle.paddingTop0, globalStyle.paddingBottom0]}
        items={filterOptions(appUserContext.currentUserContext?.organizationType)}
        fieldTitleKey="label"
        fieldValueKey="value"
        multiSelect
        forceCustomPlaceholder={filter.length === 0 || filter.length === initialFilter.length}
        onSelect={onSelectFilter}
        placeholder={placeholder}
        value={filter.length === initialFilter.length ? ['ALL', ...filter] : filter}
      />
    );
  };

  const renderMarkAllAsReadLink = () => (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        globalStyle.justifyContentEnd,
        globalStyle.flexRow,
        globalStyle.alignItemsCenter,
        globalStyle.paddingTop5,
      ]}
      onPress={handleMarkAllPress}
    >
      {loadingMarkAll ? (
        <LoadingIndicator />
      ) : (
        <Icon
          name="done-all-outline"
          fill={(styles.textLink as any).color}
          style={styles.doneIcon}
        />
      )}
      <Text style={styles.textLink}>
        {t('activityNotification.markAllAsRead', { defaultValue: 'Mark all as read' }) as string}
      </Text>
    </TouchableOpacity>
  );

  const renderActivityOverview = () => {
    if (loading && (!data || filterChanged)) {
      return (
        <View>
          <LoadingSpinner />
        </View>
      );
    }
    return (
      <View style={styles.activityOverviewLayer}>
        {notificationsData.length ? (
          notificationsData.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification as Partial<Notification>}
              small
            />
          ))
        ) : (
          <Text>
            {t('activityNotification.noRecentActivityInTheLastNDays', {
              defaultValue: 'No recent activity in the last 14 days.',
            })}
          </Text>
        )}
        <Button appearance="ghost" onPress={handlesSeeAllPress}>
          {t('activityNotification.seeAllActivity', { defaultValue: 'See all Activity' }) as string}
        </Button>
      </View>
    );
  };

  return (
    <ContentBox
      key="activity"
      style={styles.content}
      Title={
        <View style={styles.contentBoxTitleLayer}>
          <Text style={styles.titleText}>{getActivityBoxTitle()}</Text>
          <View
            style={Platform.OS === 'web' ? styles.filterContainer : styles.filterContainerMobile}
          >
            {renderFilter()}
            {!!numOfUnreadNotifications && renderMarkAllAsReadLink()}
          </View>
        </View>
      }
      Content={renderActivityOverview()}
    />
  );
};
