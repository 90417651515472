import React, { useCallback, useContext, useMemo } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { SubscriptionPermissionsProps } from './types';
import AppUserContext from '../../../contexts/AppUserContext';
import { LocalUserContext } from '../../../types';
import { ConfirmModalContext } from '../modal/ConfirmModal';

export const SubscriptionPermissions = ({
  children,
  currentCount,
  feature,
  enableRefetch = true,
  warningContent: { cancelButtonText, confirmButtonText, onConfirmPress, title, text },
  warningType = 'message',
}: SubscriptionPermissionsProps): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const checkPermissionFunction = useCallback(
    (userContext: LocalUserContext | null | undefined) => {
      if (
        (userContext?.organizationSubscriptionPlan?.isActive,
        !!userContext?.organizationSubscriptionPlan?.subscriptionPlan?.subscriptionFeatures?.length)
      ) {
        const foundFeature =
          userContext?.organizationSubscriptionPlan?.subscriptionPlan.subscriptionFeatures?.find(
            (subscriptionFeature) => subscriptionFeature.value === feature,
          );

        if (
          foundFeature &&
          (!foundFeature.count ||
            (!!foundFeature.count && (!currentCount || currentCount < foundFeature?.count)))
        ) {
          return true;
        }
      }
      return false;
    },
    [currentCount, feature],
  );

  const hasPermission = useMemo(() => {
    return checkPermissionFunction(appUserContext.currentUserContext);
  }, [appUserContext.currentUserContext, checkPermissionFunction]);

  const handleShowWarning = () => {
    if (warningType === 'message') {
      showMessage({
        message: title,
        description: text,
        type: 'warning',
        autoHide: false,
        hideOnPress: true,
        duration: 8000,
      });
    } else {
      showConfirmModal({
        confirmButtonStatus: 'primary',
        confirmButtonAppearance: 'filled',
        confirmButtonText,
        cancelButtonText: cancelButtonText || t('common.cancel', { defaultValue: 'Cancel' }),
        title,
        text,
        onConfirmPress: onConfirmPress ? onConfirmPress : async () => {},
        visible: true,
      });
    }
  };

  const checkPermissions = async () => {
    if (
      enableRefetch &&
      appUserContext.lastReFetchedAt &&
      DateTime.now().diff(appUserContext.lastReFetchedAt).as('seconds') > 60
    ) {
      try {
        const res = await appUserContext.refetchCurrentUserContext();
        return checkPermissionFunction(res);
        // eslint-disable-next-line no-empty
      } catch {}
    }

    return hasPermission;
  };

  return children({ checkPermissions, hasPermission, handleShowWarning });
};
