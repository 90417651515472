import React from 'react';
import { View } from 'react-native';

import {
  Button,
  Layout,
  List,
  ListItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { DOCUMENT_DATA } from './constants';
import { useHook } from './hook';
import { AgencyCourierUserDocumentsScreenProps } from './types';
import { CountryListData } from '../../../assets/constants/country.list.data';
import { globalStyle } from '../../../common/style';
import { FileIcon, NotVerifiedIcon, VerifiedIcon } from '../../../components/icons.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { UserTravelDocumentSubTypeType } from '../../../generated-graphql-types';
import i18n from '../../../i18n/i18n';
import {
  AirlineAllianceTypeList,
  AirlineStatusTypeList,
  AirlineTypeList,
  HealthTestTypeList,
  PassportTypeList,
  UserDocument2,
  VaccinationTargetDiseaseList,
} from '../../../types';

export const AgencyCourierUserDocumentsScreen: React.FC<AgencyCourierUserDocumentsScreenProps> = ({
  navigation,
  route,
}) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();

  const { data, handleBackPress, navigateToDocument, userProfileQueryData } = useHook();

  const renderItemAccessory = (props: UserDocument2) => (
    <Layout style={styles.rightAccessoryLayout} level="1">
      <>
        {props.verifiedOn ? <VerifiedIcon /> : <NotVerifiedIcon />}
        <Button style={styles.spacedElement} size="tiny" onPress={() => navigateToDocument(props)}>
          View
        </Button>
      </>
    </Layout>
  );

  const renderHeader = () => (
    <View style={styles.header}>
      <Text selectable={true} style={styles.nameText}>
        {t('common.courierName', { defaultValue: 'Courier Name' }) as string}
      </Text>
      <Text selectable={true} category="h6">
        {userProfileQueryData?.user.firstNames + ' ' + userProfileQueryData?.user.lastName}
      </Text>
    </View>
  );

  const renderEmptyComponent = () => (
    <View style={styles.emptyContainer}>
      <Text selectable={true}>Currently no documents.</Text>
    </View>
  );

  const renderItem = ({ item }: { item: any; index: number }) => {
    if (route.params.userDocumentType === 'UserIdentityDocument') {
      let title = '';

      let documentSubType = item.documentSubType;

      if (item.documentType === 'DRIVERS_LICENSE') {
        let countryOfIssue = '';
        if (item.countryOfIssue) {
          const country = CountryListData.find((c) => c.code === item.countryOfIssue);
          if (country) {
            countryOfIssue = country.name;
          }
        }
        title = `${t('common.driversLicense', {
          defaultValue: "Driver's License",
        })} | ${countryOfIssue}`;
      }
      if (item.documentType === 'NATIONAL_ID') {
        let nationality = '';
        if (item.nationality) {
          const country = CountryListData.find((c) => c.code === item.nationality);
          if (country) {
            nationality = country.name;
          }
        }
        documentSubType =
          PassportTypeList.find((x) => x.value === item.documentSubType)?.name || documentSubType;
        title = `${nationality} | ${documentSubType} | ${item?.documentNumber}`;
      }

      let expiresOn = '';
      if (item.expiresOn) {
        expiresOn = ` - ${item.expiresOn
          ?.setLocale(i18n.language)
          .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`;
      }
      const dateOfIssue = item.dateOfIssue
        ?.setLocale(i18n.language)
        .toLocaleString(DateTime.DATE_FULL, {
          locale: i18n.language,
        });
      return (
        <ListItem
          onPress={() => navigateToDocument(item)}
          title={title}
          description={`${dateOfIssue}${expiresOn}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    if (route.params.userDocumentType === 'UserTravelDocument') {
      let title = '';
      if (item?.validForCountry) {
        const validForCountryText = CountryListData.find(
          (object) => object.code === item?.validForCountry,
        )?.name;
        if (validForCountryText) {
          title = validForCountryText;
        }
      }
      if (item.description) {
        if (title.length) {
          title += ' | ';
        }
        title += item.description;
      }

      if (item.documentSubTypeMulti?.length) {
        if (title.length) {
          title += ' | ';
        }
        item.documentSubTypeMulti.forEach((sub: UserTravelDocumentSubTypeType) => {
          if (UserTravelDocumentSubTypeType[sub]) {
            if (!title.endsWith('| ')) {
              title += ', ';
            }
            title += t(`common.${UserTravelDocumentSubTypeType[sub]}`, {
              defaultValue: capitalize(
                (UserTravelDocumentSubTypeType[sub] || '').replace('_', ' ').toLowerCase(),
              ),
            });
          }
        });
      } else if (item.documentSubType) {
        if (title.length) {
          title += ' | ';
        }
        title += t(`common.${item.documentSubType}`, {
          defaultValue: capitalize((item.documentSubType || '').replace('_', ' ').toLowerCase()),
        });
      }
      return (
        <ListItem
          onPress={() => navigateToDocument(item)}
          title={title}
          description={`${item.dateOfIssue
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, {
              locale: i18n.language,
            })} - ${item.expiresOn
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    if (route.params.userDocumentType === 'UserFrequentFlyerDocument') {
      return (
        <ListItem
          onPress={() => navigateToDocument(item)}
          title={`${AirlineTypeList.find((x) => x.value === item?.airlineType)?.name || '-'} | ${
            AirlineStatusTypeList.find((x) => x.value === item?.airlineStatusType)?.name || ''
          } | ${
            AirlineAllianceTypeList.find((x) => x.value === item?.airlineAllianceType)?.name || ''
          }`}
          description={`${item.expiresOn
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    if (route.params.userDocumentType === 'UserVaccinationDocument') {
      let date = '';
      if (item.dateOfVaccination && DateTime.fromISO(item.dateOfVaccination).isValid) {
        date = DateTime.fromISO(item.dateOfVaccination).toLocaleString(DateTime.DATE_FULL, {
          locale: i18n.language,
        });
      }
      return (
        <ListItem
          onPress={() => {
            navigateToDocument(item);
          }}
          title={`${VaccinationTargetDiseaseList.find((x) => x.value === item?.diseaseType)?.name}${
            item?.vaccinationType ? ` | ${item?.vaccinationType}` : ''
          }${item?.documentNumber ? ` | ${item?.documentNumber}` : ''}`}
          description={`${
            date ? `${date} | ` : ''
          }${item?.description} | ${item?.vaccinationManufacturer}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    if (route.params.userDocumentType === 'UserHealthReport') {
      const diseaseType = VaccinationTargetDiseaseList.find(
        (object) => object.value === item?.diseaseType,
      )?.name;
      const testType = HealthTestTypeList.find((object) => object.value === item?.testType)?.name;
      const dateTimeOfTest = item.dateTimeOfTest
        ?.setLocale(i18n.language)
        .toLocaleString(DateTime.DATETIME_FULL);
      return (
        <ListItem
          onPress={() => navigateToDocument(item)}
          title={`${diseaseType} | ${testType}`}
          description={`${dateTimeOfTest} | ${item?.isResultPositive ? 'Positive' : 'Negative'}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    if (route.params.userDocumentType === 'UserBackgroundDocument') {
      return (
        <ListItem
          onPress={() => navigateToDocument(item)}
          title={`${item?.name} | ${CountryListData.find((x) => x.code === item?.countryOfIssue)
            ?.name}`}
          description={`${item.dateOfIssue
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, {
              locale: i18n.language,
            })} - ${item.expiresOn
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    if (route.params.userDocumentType === 'UserOtherDocument') {
      return (
        <ListItem
          onPress={() => navigateToDocument(item)}
          title={`${item?.name} | ${CountryListData.find(
            (object) => object.code === item?.countryOfIssue,
          )?.name}`}
          description={`${item.dateOfIssue
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, {
              locale: i18n.language,
            })} - ${item.expiresOn
            ?.setLocale(i18n.language)
            .toLocaleString(DateTime.DATE_FULL, { locale: i18n.language })}`}
          accessoryLeft={FileIcon}
          accessoryRight={() => renderItemAccessory(item)}
        />
      );
    }
    return (
      <View style={globalStyle.padding10}>
        <Text selectable={true}>Currently no documents.</Text>
      </View>
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={DOCUMENT_DATA[route.params.userDocumentType].title}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />
      <View style={styles.container}>
        <List
          removeClippedSubviews={false}
          style={styles.listContainer}
          contentContainerStyle={styles.listContainer}
          data={data?.[DOCUMENT_DATA[route.params.userDocumentType].queryKey]}
          renderItem={renderItem}
          ListHeaderComponent={renderHeader()}
          ListEmptyComponent={renderEmptyComponent()}
        />
      </View>
    </>
  );
};

const themedStyles = StyleService.create({
  header: {
    marginTop: 5,
    padding: 20,
    paddingBottom: 30,
  },
  nameText: {
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
    color: 'color-primary-300',
    marginBottom: 3,
  },
  emptyContainer: {
    padding: 20,
    backgroundColor: 'border-basic-color-1',
  },
  container: {
    flex: 1,
    backgroundColor: 'border-basic-color-1',
  },
  listContainer: {
    backgroundColor: 'border-basic-color-1',
    height: '100%',
    width: '100%',
  },
  rightAccessoryLayout: {
    height: 30,
    flexDirection: 'row',
  },
  spacedElement: {
    margin: 5,
  },
  textHeader: {
    alignSelf: 'center',
    marginTop: 3,
    marginBottom: 5,
  },
});
