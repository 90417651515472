import React from 'react';
import { View } from 'react-native';

import { Card, Divider, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import TimeAgo from 'javascript-time-ago';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import JSONTree from 'react-native-json-tree';

import { globalStyle } from '../../../../common/style';
import {
  OrganizationInvitation,
  OrganizationInvitationSharing,
} from '../../../../generated-graphql-types';
import {
  getOrganizationDescription,
  getOrganizationOrganizationDescription,
  getOrganizationUserDescription,
  getUserDescription,
} from '../../../../modules/helper/entity-format.helper';
import { TimeAgoPopover } from '../../time-ago-popover.component';
import { OrganizationInvitationCardItemFooter } from '../OrganizationInvitationCardFooter';
import { OrganizationInvitationCardItemHeader } from '../OrganizationInvitationCardHeader';

const OrganizationInvitationSharingItem = (props: {
  organizationInvitationSharingItem: Partial<OrganizationInvitationSharing>;
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const textColor = theme['text-basic-400'];

  return (
    <View style={globalStyle.paddingVertical5}>
      <Text selectable={true} style={{ color: textColor, alignItems: 'baseline' }}>
        <Trans
          i18nKey="organizationInvitations.sharingItem"
          t={t}
          values={{
            firstNames: props.organizationInvitationSharingItem.sharedByUser?.firstNames,
            lastName: props.organizationInvitationSharingItem.sharedByUser?.lastName,
            email: props.organizationInvitationSharingItem.sharedByUser?.email,
            language: props.organizationInvitationSharingItem.language
              ? t('LANGUAGES.' + props.organizationInvitationSharingItem.language)
              : t('LANGUAGES.en'),
            appName: props.organizationInvitationSharingItem.appName,
            platform: props.organizationInvitationSharingItem.platform,
            platformVersion: props.organizationInvitationSharingItem.platformVersion,
          }}
          style={{ alignItems: 'baseline', color: textColor }}
          components={{
            TimeAgoPopover: (
              <TimeAgoPopover
                date={props.organizationInvitationSharingItem.sharedAt as DateTime}
                language={i18n.language}
                textStyle={{ color: textColor }}
              />
            ),
          }}
          defaults="Shared <TimeAgoPopover></TimeAgoPopover> in {{language}} via {{appName}} ({{platform}} / {{platformVersion}}) by {{firstNames}} {{lastName}} ({{email}})."
        />
      </Text>
    </View>
  );
};

const OrganizationInvitationSharingComponent = (props: {
  organizationInvitationSharings: OrganizationInvitationSharing[] | undefined;
}) => {
  if (!props.organizationInvitationSharings || props.organizationInvitationSharings.length == 0) {
    return <></>;
  }

  return (
    <View>
      <Divider style={globalStyle.marginVertical10} />
      {props.organizationInvitationSharings.map((organizationInvitationSharing) => (
        <OrganizationInvitationSharingItem
          key={organizationInvitationSharing.id}
          organizationInvitationSharingItem={organizationInvitationSharing}
        />
      ))}
    </View>
  );
};

const VoidedOrganizationInvitationDescription = ({
  voidedAt,
}: {
  voidedAt: DateTime | undefined | null;
}) => {
  const { i18n } = useTranslation();

  if (!voidedAt) {
    return <></>;
  }
  if (voidedAt instanceof Date) {
    voidedAt = DateTime.fromJSDate(voidedAt);
  }
  const timeAgo = new TimeAgo(i18n.language);
  return (
    <View>
      <Text selectable={true}>
        Voided {timeAgo.format(voidedAt.toJSDate()) as string} ({voidedAt.toISODate()}{' '}
        {voidedAt.toISOTime()})
      </Text>
      <Divider style={globalStyle.marginVertical10} />
    </View>
  );
};

const UsedOrganizationInvitationDescription = ({
  organizationInvitation,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  const { i18n } = useTranslation();
  const timeAgo = new TimeAgo(i18n.language);
  const styles = useStyleSheet(themedStyles);

  const renderText = () => {
    if (organizationInvitation.usedForOrganization) {
      return (
        <>
          <Text style={styles.verticallyPaddedText}>Used to activate the </Text>
          <View style={styles.labeledValue}>
            <Text style={styles.labeledValueText}>Organization</Text>
          </View>
          <Text style={styles.verticallyPaddedText}>
            {getOrganizationDescription(organizationInvitation.usedForOrganization)}
          </Text>
        </>
      );
    }
    if (organizationInvitation.usedForOrganizationUser) {
      return (
        <>
          <Text style={styles.verticallyPaddedText}>Used to create the </Text>
          <View style={styles.labeledValue}>
            <Text style={styles.labeledValueText}>OrganizationUser</Text>
          </View>
          <Text style={styles.verticallyPaddedText}>
            to join
            {getOrganizationUserDescription(organizationInvitation.usedForOrganizationUser)}
          </Text>
        </>
      );
    }
    if (organizationInvitation.usedForOrganizationOrganization) {
      return (
        <>
          <Text style={styles.verticallyPaddedText}>
            Used to connect via
            <View style={styles.labeledValue}>
              <Text style={styles.labeledValueText}>OrganizationOrganization</Text>
            </View>
            <Text style={styles.verticallyPaddedText}>
              {getOrganizationOrganizationDescription(
                organizationInvitation.usedForOrganizationOrganization,
              )}
            </Text>
          </Text>
        </>
      );
    }
    return <Text>n/a</Text>;
  };

  if (!organizationInvitation.usedAt) {
    return <></>;
  }
  return (
    <View style={styles.descriptionView}>
      <Text selectable={true}>
        Used {timeAgo.format(organizationInvitation.usedAt.toJSDate()) as string} (
        {organizationInvitation.usedAt.toISOTime()}) by{' '}
        {organizationInvitation.usedByUser?.firstNames as string}{' '}
        {organizationInvitation.usedByUser?.lastName as string}{' '}
        {organizationInvitation.usedByUser?.email as string}
      </Text>
      <View style={styles.labeledRow}>{renderText()}</View>
      <Divider />
    </View>
  );
};

const OrganizationInvitationDescription = ({
  organizationInvitation,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={styles.descriptionView}>
      <Text category="h6" style={{ fontFamily: 'Lato_700Bold' }}>
        {organizationInvitation.organizationInvitationType?.replaceAll('_', ' ') as string}
      </Text>
      <View style={styles.labeledRow}>
        <Text style={styles.verticallyPaddedText}>Invited Organization Type: </Text>
        <View style={styles.labeledValue}>
          <Text style={styles.labeledValueText}>
            {organizationInvitation.invitedOrganizationType || (
              <View style={styles.labeledValue}>
                <Text style={styles.labeledValueText}>n/a</Text>
              </View>
            )}
          </Text>
        </View>
      </View>
      <View style={styles.labeledRow}>
        <Text style={styles.reservedComment}>
          Reserved Comment:{' '}
          {organizationInvitation.reservedComment || (
            <View style={styles.labeledValue}>
              <Text style={styles.labeledValueText}>n/a</Text>
            </View>
          )}
        </Text>
      </View>
      <View style={styles.labeledRow}>
        <Text style={styles.verticallyPaddedText}>
          Inviting Organization:{' '}
          {organizationInvitation.invitingOrganization ? (
            getOrganizationDescription(organizationInvitation.invitingOrganization)
          ) : (
            <View style={styles.labeledValue}>
              <Text style={styles.labeledValueText}>n/a</Text>
            </View>
          )}
        </Text>
      </View>
      <View style={styles.labeledRow}>
        <Text style={styles.verticallyPaddedText}>
          Invited Organization:{' '}
          {organizationInvitation.invitedOrganization ? (
            getOrganizationDescription(organizationInvitation.invitedOrganization)
          ) : (
            <View style={styles.labeledValue}>
              <Text style={styles.labeledValueText}>n/a</Text>
            </View>
          )}
        </Text>
      </View>
      <View style={styles.labeledRow}>
        <Text style={styles.verticallyPaddedText}>
          Invited User:{' '}
          {organizationInvitation.invitedUser ? (
            getUserDescription(organizationInvitation.invitedUser, organizationInvitation)
          ) : (
            <View style={styles.labeledValue}>
              <Text style={styles.labeledValueText}>n/a</Text>
            </View>
          )}
        </Text>
      </View>
      <View style={styles.labeledRow}>
        <Text style={styles.verticallyPaddedText}>Role Type:</Text>
        <View style={styles.labeledValue}>
          <Text style={styles.labeledValueText}>{organizationInvitation.roleType || 'n/a'}</Text>
        </View>
      </View>

      <Text>Pipedrive Deal:</Text>
      <JSONTree data={organizationInvitation.pipedriveDeal || 'null'} />
    </View>
  );
};

export const OrganizationInvitationCardItem = ({
  organizationInvitation,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  return (
    <Card
      key={organizationInvitation.id}
      style={themedStyles.card}
      header={(headerProps: any) => (
        <OrganizationInvitationCardItemHeader
          {...headerProps}
          organizationInvitation={organizationInvitation}
        />
      )}
      footer={(footerProps: any) => (
        <OrganizationInvitationCardItemFooter
          {...footerProps}
          organizationInvitation={organizationInvitation}
        />
      )}
    >
      <UsedOrganizationInvitationDescription organizationInvitation={organizationInvitation} />
      <VoidedOrganizationInvitationDescription voidedAt={organizationInvitation.voidedAt} />
      <OrganizationInvitationDescription organizationInvitation={organizationInvitation} />
      <OrganizationInvitationSharingComponent
        organizationInvitationSharings={organizationInvitation.organizationInvitationSharings}
      />
    </Card>
  );
};

const themedStyles = StyleService.create({
  card: {
    margin: 10,
    padding: 10,
  },
  descriptionView: {
    marginTop: 10,
    alignItems: 'stretch',
  },
  organizationInvitationTypeBadgeView: {
    marginVertical: 10,
    alignSelf: 'center',
  },
  organizationInvitationTypeBadge: {
    borderRadius: 3,
    alignContent: 'center',
    padding: 3,
    backgroundColor: 'gray',
    borderColor: 'lightgray',
    borderWidth: 1,
    fontSize: 10,
    margin: 10,
  },
  verticallyPaddedText: {
    paddingVertical: 2,
  },
  labeledValue: {
    paddingBottom: 2,
    paddingHorizontal: 10,
    marginLeft: 5,
    backgroundColor: 'color-primary-300',
    borderColor: 'transparent',
    borderWidth: 1,
    borderRadius: 5,
    marginHorizontal: 5,
  },
  labeledValueText: {
    fontFamily: 'Lato_700Bold',
  },
  labeledRow: {
    paddingVertical: 2,
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexDirection: 'row',
  },
  reservedComment: {
    paddingVertical: 3,
    flex: 1,
    alignContent: 'stretch',
    fontFamily: 'Lato_700Bold',
  },
});
