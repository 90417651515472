import React, { useContext } from 'react';
import { Platform, View } from 'react-native';

import { NavigationProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { NotificationsSettingsBox } from '../../components/notifications-settings.box.component';
import { SwitchLanguageBox } from '../../components/switch-language-box.component';
import { ThemeToggleBox } from '../../components/theme-toggle.box.component';
import { TopNavigationBackToProfileButton } from '../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import { RootStackParamList } from '../../navigation/app.navigator';

export const CommonAppSettingsScreen = ({
  navigation,
}: {
  navigation: NavigationProp<RootStackParamList>;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  return (
    <>
      <TopNavigationBackToProfileButton
        title={t('menuItems.appSettings', { defaultValue: 'App Settings' })}
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />
      <View>
        <SwitchLanguageBox />
        <ThemeToggleBox />
        {(Platform.OS === 'ios' || Platform.OS === 'android') && <NotificationsSettingsBox />}
      </View>
    </>
  );
};
