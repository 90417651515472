import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { QUERY_ORGANIZATION_COURIER_CATEGORIES } from '../../../../apollo/graphql-queries';
import { OrganizationCourierCategory } from '../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();

  const { data: dataOrganizationCourierCategories } = useQuery(
    QUERY_ORGANIZATION_COURIER_CATEGORIES,
    {
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
    },
  );

  const organizationCourierCategories = useMemo(() => {
    if (dataOrganizationCourierCategories?.organizationCourierCategories?.length) {
      const data = dataOrganizationCourierCategories?.organizationCourierCategories.map(
        (item: OrganizationCourierCategory) => ({
          label: `${item.order} - ${item.label}`,
          value: item.id,
        }),
      );
      return [
        {
          value: undefined,
          label: t('common.selectCourierCategory', { defaultValue: 'Select courier category' }),
        },
        ...data,
      ];
    }
    return [];
  }, [dataOrganizationCourierCategories, t]);

  return {
    organizationCourierCategories,
  };
};
