import React from 'react';
import { ActivityIndicator, View } from 'react-native';

import AvailabilityMap from './AvailabilityMap/availabilityMapComponent';
import { AvailabilityMapBottomCard } from './AvailabilityMapBottomCard';
import { AvailabilityMapSearchBoxAndFilter } from './AvailabilityMapSearchBoxAndFilter';
import { useHook } from './hook';
import { globalStyle } from '../../../../common/style';

export const AgencyOBCAvailabilityMapComponent = () => {
  const {
    availabilities,
    handleFilterSelect,
    handleLocationSelect,
    handleResetAvailabilities,
    handleSelectAvailability,
    handleSelectAvailabilities,
    initialRegion,
    selectedAvailability,
    selectedAvailabilities,
    selectedFilterType,
  } = useHook();

  return (
    <>
      {initialRegion ? (
        <AvailabilityMap
          availabilities={availabilities}
          initialRegion={initialRegion}
          onSelectAvailabilities={handleSelectAvailabilities}
          selectedAvailability={selectedAvailability}
        />
      ) : (
        <View
          style={[
            globalStyle.flex1,
            globalStyle.height100,
            globalStyle.alignItemsCenter,
            globalStyle.justifyContentCenter,
          ]}
        >
          <ActivityIndicator />
        </View>
      )}
      <AvailabilityMapSearchBoxAndFilter
        onLocationSelect={handleLocationSelect}
        onResetAvailability={handleResetAvailabilities}
        onFilterSelect={handleFilterSelect}
        selectedFilterType={selectedFilterType}
      />
      <AvailabilityMapBottomCard
        onResetAvailability={handleResetAvailabilities}
        onSelectAvailability={handleSelectAvailability}
        selectedAvailabilities={selectedAvailabilities}
      />
    </>
  );
};
