import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  camera: {
    justifyContent: 'space-between',
  },
  topBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  flashButton: {
    height: 36,
    width: 36,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000CC',
    borderWidth: 1,
    borderRadius: 30,
    borderColor: '#ffffff77',
    borderStyle: 'dotted',
  },
  bottomBar: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    padding: 4,
    borderWidth: 4,
    borderRadius: 36,
    borderColor: '#fafafa',
  },
  buttonInner: {
    height: 52,
    width: 52,
    borderRadius: 26,
    backgroundColor: 'white',
    opacity: 0.9,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    left: 20,
  },
  bottomBarSave: {
    alignItems: 'flex-end',
    backgroundColor: '#000000CC',
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  buttonSave: {
    height: 48,
    width: 48,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 25,
    backgroundColor: 'color-primary-600',
    transform: [{ rotate: '-24deg' }],
  },
  buttonSavePadding: {
    paddingLeft: 5,
    paddingBottom: 4,
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
  },
  whiteText: {
    color: 'white',
  },
});
