import { useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Linking, TextInput, Platform } from 'react-native';

import { useMutation, useLazyQuery } from '@apollo/client';
import { useKeyboard } from '@react-native-community/hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { MAX_LENGTH } from './index';
import { Props } from './types';
import { MUTATION_AGENCY_JOB_UPDATE_INTERNAL_NOTES } from '../../../../apollo/graphql-mutations';
import {
  AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY,
  QUERY_AGENCY_JOB_INTERNAL_NOTES,
} from '../../../../apollo/graphql-queries';
import useDimensions from '../../../../hooks/useDimensions';

export const useHook = ({ onChangeText, ref }: Props & { ref: any }) => {
  const { top, bottom } = useSafeAreaInsets();
  const { dimensions } = useDimensions();
  const keyboard = useKeyboard();

  const [agencyJobUpdateInternalNotes] = useMutation(MUTATION_AGENCY_JOB_UPDATE_INTERNAL_NOTES, {
    refetchQueries: [AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_QUERY],
  });
  const [fetchInternalNotes] = useLazyQuery(QUERY_AGENCY_JOB_INTERNAL_NOTES, {
    fetchPolicy: 'network-only',
  });

  const textInputRef = useRef<TextInput>(null);

  const [currentAgencyJobId, setCurrentAgencyJobId] = useState<string | undefined>('');
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [focused, setFocused] = useState(false);
  const [selection, setSelection] = useState({ start: 0, end: 0 });
  const [text, setText] = useState('');
  const [textInitialValue, setTextInitialValue] = useState('');

  const modalWidth = useMemo(() => {
    if (dimensions.window.width > 1170) {
      return 1170;
    }
    return dimensions.window.width - 30;
  }, [dimensions.window]);

  const modalHeight = useMemo(() => {
    if (Platform.OS === 'android') {
      return 'auto';
    }
    const diff = top + bottom + 50;
    if (keyboard.keyboardShown) {
      return dimensions.window.height - diff - keyboard.keyboardHeight;
    }
    return dimensions.window.height - 100;
  }, [top, bottom, keyboard.keyboardShown, keyboard.keyboardHeight, dimensions.window.height]);

  const hideModalVisibility = () => {
    setModalVisible(false);
    setTimeout(() => {
      setCurrentAgencyJobId('');
      setShowModal(false);
      setText('');
    }, 300);
  };

  const showModalVisibility = () => {
    setModalVisible(true);
  };

  const showInternalNotesModal = async ({
    agencyJobId,
    value,
  }: {
    agencyJobId?: string;
    value?: string;
  }) => {
    setCurrentAgencyJobId(agencyJobId);
    setShowModal(true);
    setTimeout(() => {
      showModalVisibility();
    }, 300);

    const textValue = value || '';
    setText(textValue);
    setTextInitialValue(textValue);
    setSelection({ start: textValue.length, end: textValue.length });

    if (agencyJobId) {
      try {
        const res = await fetchInternalNotes({ variables: { agencyJobId } });
        if (res?.data?.agencyJobForAgencyOrganization) {
          const internalNotes = res.data.agencyJobForAgencyOrganization.internalNotes || '';
          setSelection({ start: internalNotes.length, end: internalNotes.length });
          setText(internalNotes);
          setTextInitialValue(internalNotes);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }
  };

  const handleCancelModalPress = async () => {
    let timeout = 0;
    if (confirmModalVisible) {
      setConfirmModalVisible(false);
      timeout = 400;
    }
    setTimeout(() => {
      hideModalVisibility();
      setTimeout(() => {
        setText(textInitialValue);
      }, 200);
    }, timeout);
  };

  const handleOpenConfirmModal = () => {
    setConfirmModalVisible(true);
  };

  const handleClose = () => {
    if (text !== textInitialValue) {
      handleOpenConfirmModal();
    } else {
      hideModalVisibility();
    }
  };

  const handleChangeText = (val: string) => {
    if (val.length > MAX_LENGTH) {
      setText(val.slice(0, 2000));
    } else {
      setText(val);
    }
  };

  const handleFocus = () => {
    if (textInputRef.current) {
      textInputRef.current.focus();
      setFocused(true);
      setSelection({ start: text.length, end: text.length });
    }
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleSave = async () => {
    if (currentAgencyJobId) {
      await agencyJobUpdateInternalNotes({
        variables: { agencyJobId: currentAgencyJobId, internalNotes: text },
      });
    }

    let timeout = 0;
    if (confirmModalVisible) {
      setConfirmModalVisible(false);
      timeout = 400;
    }
    setTimeout(async () => {
      if (onChangeText) {
        onChangeText(text || '');
      }
      setTextInitialValue(text);
      hideModalVisibility();
    }, timeout);
  };

  const handleLinkPress = (link: string) => {
    Linking.canOpenURL(link).then((): void => {
      Linking.openURL(link);
    });
  };

  const handleSelectionChange = ({ nativeEvent }: any) => {
    setSelection(nativeEvent.selection);
  };

  const isSaveDisabled = useMemo(() => {
    return text === textInitialValue;
  }, [text, textInitialValue]);

  useImperativeHandle(ref, () => ({
    hideModalVisibility,
    showInternalNotesModal,
  }));

  return {
    confirmModalVisible,
    focused,
    handleBlur,
    handleChangeText,
    handleCancelModalPress,
    handleClose,
    handleFocus,
    handleLinkPress,
    handleSave,
    handleSelectionChange,
    isSaveDisabled,
    modalHeight,
    modalWidth,
    modalVisible,
    showModal,
    selection,
    showInternalNotesModal,
    textInputRef,
    text,
  };
};
