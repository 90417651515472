import { Platform, StyleSheet } from 'react-native';

import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  label: {},
  maxLength: {
    fontSize: 13,
  },
  maxLengthError: {
    color: 'color-danger-600',
    fontSize: 13,
  },

  container: {
    flex: 1,
    flexGrow: 1,
    maxWidth: Platform.OS === 'web' ? 1200 : '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    backgroundColor: 'transparent',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    paddingVertical: 10,
    paddingTop: 0,
  },
  headerTitle: {
    flex: 1,
    marginLeft: 30,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
  },
  closeButton: {
    width: 'auto',
    marginTop: 10,
  },
  nonBlurredContent: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalStyle: {
    ...StyleSheet.absoluteFillObject,
    flex: 1,
    flexDirection: 'row',
    alignItems: Platform.OS !== 'web' ? 'flex-start' : 'center',
    justifyContent: 'center',
    margin: 0,
    height: '100%',
  },
  modalView: {
    borderRadius: 10,
  },
  modalViewFullHeight: {
    flex: 1,
    flexGrow: 1,
  },
});
