import React, { useMemo } from 'react';
import { View } from 'react-native';

import { Text, useStyleSheet } from '@ui-kitten/components';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { CountryListData } from '../../../../assets/constants/country.list.data';
import { globalStyle } from '../../../../common/style';
import { FormAutocomplete } from '../../../../components/common/form/form-autocomplete.component';
import { FormDatepicker } from '../../../../components/common/form/form-datepicker.component';
import { FormTextInputTopLabel } from '../../../../components/common/form/form-text-input-top-label.component';
import { FormSelect } from '../../../../components/common/form/FormSelect';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';
import { sexList as SEX_LIST } from '../../../../types';

export const OBCPersonalDetailsEdit = ({
  errors,
  fieldProps,
  onSelect,
  nationalities,
  setFieldValue,
  validateField,
  values,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const sexList = useMemo(() => {
    return SEX_LIST.map((sex) => ({
      ...sex,
      name: t(`common.${sex.name}`, { defaultValue: sex.name }),
    }));
  }, [t]);

  const renderSelect = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormSelect
        appearance="default"
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey }) as any}
        items={options}
        value={values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={errors[valueKey]}
        isMandatory
      />
    </>
  );

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.personalDetails', {
        defaultValue: 'Personal Details',
      }).toUpperCase()}
      subtitle={
        '(1)' +
        t('courierUserProfile.forChangesContactSupport', {
          defaultValue: 'For any changes in these fields contact PRIOjet Support',
        })
      }
      Content={
        <>
          <FormTextInputTopLabel
            label={t('courierUserProfile.firstNames', {
              defaultValue: 'First Names',
            })}
            labelExtra="(1)"
            placeholder={
              t('courierUserProfile.firstNames', {
                defaultValue: 'First Names',
              }) as string
            }
            isMandatory
            disabled
            {...fieldProps('firstNames')}
          />
          <FormTextInputTopLabel
            label={t('courierUserProfile.lastName', {
              defaultValue: 'Last Name',
            })}
            labelExtra="(1)"
            placeholder={
              t('courierUserProfile.lastName', {
                defaultValue: 'Last Name',
              }) as string
            }
            isMandatory
            disabled
            {...fieldProps('lastName')}
          />
          {renderSelect({
            label: t('courierUserProfile.sex', { defaultValue: 'Sex' }) as string,
            valueKey: 'sex',
            options: sexList,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}

          <FormDatepicker
            label={
              t('courierUserProfile.dateOfBirth', {
                defaultValue: 'Date of Birth',
              }) as string
            }
            isMandatory
            min={new Date(dayjs().subtract(80, 'years').format())}
            max={new Date()}
            onSelect={(date) => {
              setFieldValue('dateOfBirth', DateTime.fromJSDate(date));
              validateField('dateOfBirth');
            }}
            date={values.dateOfBirth ? new Date(values.dateOfBirth) : undefined}
            errorMessage={errors.dateOfBirth}
          />
          <FormTextInputTopLabel
            label={t('courierUserProfile.placeOfBirth', {
              defaultValue: 'Place of Birth',
            })}
            placeholder={
              t('courierUserProfile.placeOfBirth', {
                defaultValue: 'Place of Birth',
              }) as string
            }
            isMandatory
            {...fieldProps('placeOfBirth')}
          />

          <FormAutocomplete
            label={
              t('courierUserProfile.countryOfBirth', {
                defaultValue: 'Country of Birth',
              }) as string
            }
            appearance="default"
            fieldTitleKey="name"
            fieldValueKey="code"
            items={CountryListData}
            onSelect={
              onSelect({
                autocomplete: true,
                options: CountryListData,
                fieldValueKey: 'code',
                valueKey: 'countryOfBirth',
              }) as any
            }
            errorMessage={errors.countryOfBirth}
            isMandatory
            value={values.countryOfBirth}
          />

          <FormTextInputTopLabel
            autoCapitalize="none"
            label={t('courierUserProfile.secondEmail', {
              defaultValue: 'Second Email',
            })}
            placeholder={
              t('courierUserProfile.secondEmail', {
                defaultValue: 'Second Email',
              }) as string
            }
            {...fieldProps('email2')}
          />
          <FormTextInputTopLabel
            label={t('courierUserProfile.secondPhone', {
              defaultValue: 'Second Phone',
            })}
            placeholder={
              t('courierUserProfile.secondPhone', {
                defaultValue: 'Second Phone',
              }) as string
            }
            {...fieldProps('phone2')}
          />
          <View
            style={[
              globalStyle.marginTop20,
              globalStyle.paddingLeft10,
              globalStyle.paddingBottom15,
            ]}
          >
            <Text style={[globalStyle.marginTop10, styles.label]}>
              {
                t('courierUserProfile.nationalities', {
                  defaultValue: 'Nationalities',
                }) as string
              }
            </Text>
            <Text style={globalStyle.marginTop10}>{nationalities}</Text>
          </View>
        </>
      }
      style={styles.contentBox}
    />
  );
};

const arePropsEqual = (prev: any, next: any) => {
  const keys: any[] = [
    'firstNames',
    'lastName',
    'sex',
    'dateOfBirth',
    'placeOfBirth',
    'countryOfBirth',
    'email2',
    'phone2',
  ];
  if (keys.some((key) => next?.values[key] !== prev?.values[key])) {
    return false;
  }
  if (keys.some((key) => next?.errors[key] !== prev?.errors[key])) {
    return false;
  }
  if (next?.nationalities !== prev?.nationalities) {
    return false;
  }
  return true;
};

export default React.memo(OBCPersonalDetailsEdit, arePropsEqual);
