import React, { useCallback } from 'react';

import { Icon } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { showMessage } from 'react-native-flash-message';

import 'react-native-get-random-values';
import {
  deleteCurrentUserContextFromStorage,
  loadCurrentUserContext,
  storeCurrentUserContext,
} from '../modules/authentication/authentication.module';
import { Device, LocalUserContext } from '../types';

export interface IAppUserContext {
  currentUserContext: LocalUserContext | null | undefined;
  lastReFetchedAt: DateTime | undefined;
  purgeCurrentUserContext: () => void;
  refetchCurrentUserContext: () => Promise<LocalUserContext | undefined>;
  setCurrentUserContext: (CurrentUserContext: LocalUserContext | null) => void;
  setDevice: (device: Device | null) => void;
}

// Make sure the type for AppUserContext is never null.
const AppUserContext = React.createContext<IAppUserContext | null>(
  null,
) as unknown as React.Context<IAppUserContext>;

export const AppUserContextProvider = ({
  children,
  currentUserContext,
  lastReFetchedAt,
  refetchCurrentUserContext,
  setCurrentUserContextState,
}: {
  children: any;
  currentUserContext: LocalUserContext | null | undefined;
  lastReFetchedAt: DateTime | undefined;
  refetchCurrentUserContext: () => Promise<LocalUserContext | undefined>;
  setCurrentUserContextState: (userContext: LocalUserContext | null | undefined) => void;
}) => {
  const setCurrentUserContext = useCallback(
    (newCurrentUserContext: LocalUserContext | null) => {
      if (
        currentUserContext?.user !== undefined &&
        newCurrentUserContext?.user !== undefined &&
        currentUserContext?.user?.id !== newCurrentUserContext?.user?.id
      ) {
        if (process.env.APP_ENV === 'development') {
          showMessage({
            message: 'User Account switched',
            description: `Switched from ${currentUserContext.user.email} to ${newCurrentUserContext.user.email}`,
            type: 'info',
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
          });
        }
      } else if (
        currentUserContext?.user !== undefined &&
        newCurrentUserContext?.user === undefined
      ) {
        showMessage({
          message: 'User Account logged out',
          description: `Logged out from ${currentUserContext.user.email}`,
          type: 'success',
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
        });
      } else if (currentUserContext === null && newCurrentUserContext?.user !== undefined) {
        showMessage({
          message: 'User Account signed in',
          description: `Loggined in  ${newCurrentUserContext.user.email}`,
          type: 'success',
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
        });
      } else if (
        process.env.APP_ENV === 'development' &&
        currentUserContext &&
        newCurrentUserContext &&
        JSON.stringify(currentUserContext) !== JSON.stringify(newCurrentUserContext)
      ) {
        showMessage({
          message: 'Refreshed the User Context',
          description: `New User Context set to  ${
            newCurrentUserContext?.user ? newCurrentUserContext?.user?.email : 'null'
          }`,
          type: 'info',
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
        });
      } else if (
        process.env.APP_ENV === 'development' &&
        currentUserContext &&
        newCurrentUserContext &&
        JSON.stringify(currentUserContext) === JSON.stringify(newCurrentUserContext)
      ) {
        showMessage({
          message: 'No change in the User Context',
          description: `New User Context set to  ${
            newCurrentUserContext?.user ? newCurrentUserContext?.user?.email : 'null'
          }`,
          type: 'info',
          duration: 100,
          statusBarHeight: 20,
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
        });
      } else if (!currentUserContext?.device && newCurrentUserContext) {
        if (process.env.APP_ENV === 'development') {
          showMessage({
            message: 'Loaded the User Context',
            description: `User Context set to  ${
              newCurrentUserContext?.user ? newCurrentUserContext?.user?.email : 'null'
            }`,
            type: 'info',
            duration: 100,
            statusBarHeight: 20,
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
          });
        }
      }

      if (
        (!currentUserContext?.device && !newCurrentUserContext?.device) ||
        (currentUserContext &&
          JSON.stringify(currentUserContext) === JSON.stringify(newCurrentUserContext))
      ) {
        return;
      }

      // Always keep the device when logging out, hence, when the newCurrentUserContext is undefined,
      // we copy the device from the currentUserContext.
      const updatedCurrentUserContext =
        !newCurrentUserContext && currentUserContext?.device !== undefined
          ? {
              device: currentUserContext?.device,
              deviceId: currentUserContext?.device?.id,
            }
          : newCurrentUserContext;

      if (!updatedCurrentUserContext) {
        purgeCurrentUserContext();
      } else {
        storeCurrentUserContext(updatedCurrentUserContext).then(() => {
          setCurrentUserContextState(updatedCurrentUserContext);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUserContext],
  );

  const purgeCurrentUserContext = (): void => {
    deleteCurrentUserContextFromStorage().then(() => {
      setCurrentUserContextState(null);
    });
  };

  const setDevice = (device: Device | null) => {
    loadCurrentUserContext().then((loadedUserContext) => {
      if (loadedUserContext && loadedUserContext?.user && device) {
        setCurrentUserContext({ ...loadedUserContext, device, deviceId: device.id });
      } else if (device) {
        setCurrentUserContext({ ...loadedUserContext, device, deviceId: device.id });
      } else {
        setCurrentUserContext(null);
      }
    });
  };

  return (
    <AppUserContext.Provider
      value={{
        currentUserContext,
        lastReFetchedAt,
        purgeCurrentUserContext,
        refetchCurrentUserContext,
        setDevice,
        setCurrentUserContext,
      }}
    >
      {children}
    </AppUserContext.Provider>
  );
};

export default AppUserContext;
