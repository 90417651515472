import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { loadCurrentUserContext } from '../modules/authentication/authentication.module';
import { getOrCreateDeviceId } from '../modules/device/device.module';
import { LocalUserContext } from '../types';

export const getAuthorizationHeaders = async (
  localUserContext?: LocalUserContext | null,
  shouldLoadCurrentUserContext: boolean = true,
): Promise<{ [key: string]: any }> => {
  if (!localUserContext && shouldLoadCurrentUserContext) {
    localUserContext = await loadCurrentUserContext();
  }

  const authHeaders: Record<string, string | number> = {
    'x-device-id': localUserContext?.deviceId
      ? localUserContext.deviceId
      : await getOrCreateDeviceId(),
  };

  if (localUserContext?.userContextId) {
    authHeaders['x-user-context-id'] = localUserContext.userContextId;
  }
  if (localUserContext?.organization?.id) {
    authHeaders['x-organization-id'] = localUserContext.organization.id;
  }

  if (localUserContext?.tokenResponse?.accessToken) {
    authHeaders.authorization = `Bearer ${localUserContext.tokenResponse.accessToken}`;
  }
  return authHeaders;
};

export const createAuthLinkWithHeaders = (
  userContext?: LocalUserContext | null,
  customHeaders?: object,
  _context?: object,
): ApolloLink => {
  return setContext(async (request, context) => {
    // return the headers to the context so httpLink can read them
    const authHeaders = await getAuthorizationHeaders(
      context.userContext || userContext || undefined,
    );
    return {
      headers: {
        ...authHeaders,
        ...context.headers,
        ...customHeaders,
      },
      ...context,
    };
  });
};
