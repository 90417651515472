import React, { useMemo } from 'react';
import { View } from 'react-native';

import { useQuery } from '@apollo/client';
import { useIsFocused, useNavigation } from '@react-navigation/core';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { QUERY_DELETED_ACCOUNTS_PRIOJET } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { OrganizationType, OrganizationUser, User } from '../../generated-graphql-types';
import useDimensions from '../../hooks/useDimensions';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { AppNavigatorProp } from '../../navigation/app.navigator';
import { LoadingSpinner } from '../common/loading-spinner.component';
import ContentBoxTextTitle from '../content-box-text-title.component';

export const DeletedAccountsBoxComponent = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<AppNavigatorProp>();
  const isBackendReachable = useIsBackendReachable();
  const styles = useStyleSheet(themedStyles);
  const { isLargeDevice } = useDimensions();
  const isFocused = useIsFocused();

  const { data, loading } = useQuery(QUERY_DELETED_ACCOUNTS_PRIOJET, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    pollInterval: isBackendReachable && isFocused ? 10000 * 30 : 0,
    skip: !isFocused,
  });

  const handleOpenDeletedDetails =
    (organizationType: OrganizationType, type: 'organization' | 'user') => () => {
      navigation.navigate('PriojetDeletedAccountsScreen', { organizationType, type });
    };

  const { deletedAgencyAccounts, deletedCourierAccounts, deletedAgencyUserAccounts } =
    useMemo(() => {
      if (data?.deletedAccountsPriojetOnly?.length) {
        return data.deletedAccountsPriojetOnly.reduce(
          (
            acc: {
              deletedAgencyUserAccounts: User[];
              deletedAgencyAccounts: User[];
              deletedCourierAccounts: User[];
            },
            current: User,
          ) => {
            const organizationUser = current.organizationUsers.find(
              (orgUser: OrganizationUser) => orgUser.user.id === current.id,
            );
            if (!current.organizationUsers.length) {
              acc.deletedAgencyUserAccounts.push(current);
            } else if (
              organizationUser?.organization.organizationType === OrganizationType.AGENCY
            ) {
              if (organizationUser.organization.inactivatedAt) {
                acc.deletedAgencyAccounts.push(current);
              } else {
                acc.deletedAgencyUserAccounts.push(current);
              }
            } else if (
              organizationUser?.organization.organizationType === OrganizationType.COURIER
            ) {
              acc.deletedCourierAccounts.push(current);
            }
            return acc;
          },
          {
            deletedAgencyAccounts: [],
            deletedAgencyUserAccounts: [],
            deletedCourierAccounts: [],
          },
        );
      }
      return {
        deletedAgencyAccounts: [],
        deletedCourierAccounts: [],
        deletedAgencyUserAccounts: [],
      };
    }, [data]);

  const renderContent = () => {
    if (data?.deletedAccountsPriojetOnly && !data.deletedAccountsPriojetOnly.length) {
      return (
        <Text>
          {
            t('deleteAccount.noDeletedAccount', {
              defaultValue: 'No deleted accounts to display.',
            }) as string
          }
        </Text>
      );
    }
    if (loading || !data) {
      return <LoadingSpinner />;
    }

    return (
      <View>
        <View style={[styles.flex1, styles.row]}>
          <View style={[styles.countView, styles.countViewAgency]}>
            <Text style={styles.countHeaderText}>
              {t('common.users', { defaultValue: 'Users' }) as string}
            </Text>
            <Text style={styles.countNumberText}>{deletedAgencyUserAccounts?.length || 0}</Text>
          </View>
          <View style={[styles.countView, styles.countViewAgency]}>
            <Text style={styles.countHeaderText}>
              {t('common.agencies', { defaultValue: 'Agencies' }) as string}
            </Text>
            <Text style={styles.countNumberText}>{deletedAgencyAccounts?.length || 0}</Text>
          </View>
          <View style={[styles.countView, styles.countViewCourier]}>
            <Text style={styles.countHeaderText}>
              {t('common.couriers', { defaultValue: 'Couriers' }) as string}
            </Text>
            <Text style={styles.countNumberText}>{deletedCourierAccounts?.length || 0}</Text>
          </View>
        </View>

        <View
          style={[
            styles.buttonsContainer,
            isLargeDevice ? styles.buttonsRow : styles.buttonsColumn,
          ]}
        >
          <Button
            appearance="filled"
            disabled={!deletedAgencyUserAccounts.length}
            style={[
              styles.flex1,
              isLargeDevice && styles.marginRight3,
              !isLargeDevice && styles.marginBottom10,
            ]}
            onPress={handleOpenDeletedDetails(OrganizationType.AGENCY, 'user')}
          >
            {t('deleteAccount.viewUsers', { defaultValue: 'View Users' }) as string}
          </Button>
          <Button
            appearance="filled"
            disabled={!deletedAgencyAccounts.length}
            style={[
              styles.flex1,
              isLargeDevice && styles.marginRight3,
              !isLargeDevice && styles.marginBottom10,
            ]}
            onPress={handleOpenDeletedDetails(OrganizationType.AGENCY, 'organization')}
          >
            {t('deleteAccount.viewAgencies', { defaultValue: 'View Agencies' }) as string}
          </Button>
          <Button
            appearance="filled"
            disabled={!deletedCourierAccounts.length}
            style={[styles.flex1, isLargeDevice && styles.marginLeft3]}
            onPress={handleOpenDeletedDetails(OrganizationType.COURIER, 'organization')}
          >
            {t('deleteAccount.viewCouriers', { defaultValue: 'View Couriers' }) as string}
          </Button>
        </View>
      </View>
    );
  };

  return (
    <ContentBoxTextTitle
      style={[globalStyle.paddingTop5, globalStyle.paddingBottom15]}
      title={t('deleteAccount.deletedAccounts', { defaultValue: 'Deleted accounts' })}
      Content={renderContent()}
    />
  );
};

const themedStyles = StyleService.create({
  flex1: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  countHeaderText: {
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
    color: 'text-primary-color',
    fontSize: 12,
  },
  countNumberText: {
    fontSize: 30,
    fontFamily: 'Lato_700Bold',
    paddingTop: 10,
  },
  countViewAgency: {
    borderBottomColor: 'color-warning-400',
  },
  countViewCourier: {
    borderBottomColor: 'color-success-400',
  },
  countView: {
    flex: 1,
    alignItems: 'center',
    borderBottomWidth: 5,
    margin: 1,
  },
  buttonsContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  buttonsRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonsColumn: {
    flexDirection: 'column',
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginLeft3: {
    marginLeft: 3,
  },
  marginRight3: {
    marginRight: 3,
  },
});
