import React, { useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { useMutation } from '@apollo/client';
import {
  Button,
  CheckBox,
  Divider,
  Text,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { useFormik } from 'formik';
import pick from 'lodash/pick';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';

import { CREATE_USER_HEALTH_REPORT_FRAGMENT } from '../../apollo/graphql-fragments';
import { CREATE_USER_HEALTH_REPORT_MUTATION } from '../../apollo/graphql-mutations';
import { CountryListData } from '../../assets/constants/country.list.data';
import { globalStyle as globalS } from '../../common/style';
import { CreateUserHealthReportVariables, User_user } from '../../graphql-types';
import { resolveTimeZoneForLocation } from '../../lib/google-maps-timezone-api';
import { HealthTestTypeList, UserDocument, VaccinationTargetDiseaseList } from '../../types';
import { uploadDocumentsToServer } from '../../utils/file-upload.util';
import { getAddressDetailsFromGooglePlaces } from '../../utils/google-places.util';
import { CourierAddDocumentFile } from '../common/courier.add-document-image-pdf';
import { FormAutocomplete } from '../common/form/form-autocomplete.component';
import { FormLocationAutocomplete } from '../common/form/form-location-autocomplete.component';
import { FormTextDatepicker } from '../common/form/form-text-datepicker.component';
import { FormTextInputTopLabel } from '../common/form/form-text-input-top-label.component';
import { FormSelect } from '../common/form/FormSelect';
import { FormTimeSelect } from '../common/form/FormTimeSelect/form-time-select.component';
import { GooglePlacesAutocompleteLocation } from '../common/google-places-autocomplete.component';
import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const now = new Date();
const minimumBirthDay = new Date(1900, 1, 1);

export const CourierAddUserHealthReportComponent = ({
  navigation,
  title,
}: {
  navigation: any;
  title: string;
  user?: User_user;
}): React.ReactElement => {
  const { i18n, t } = useTranslation();
  const globalStyle = useStyleSheet(globalS);

  const [hasOnlyFrontPage, setHasOnlyFrontPage] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [uploadUserHealthReport] = useMutation<
    { createUserHealthReport: UserDocument },
    CreateUserHealthReportVariables
  >(CREATE_USER_HEALTH_REPORT_MUTATION, {
    async onCompleted(data) {
      if (data.createUserHealthReport) {
        showMessage({
          message: t('document.success', { defaultValue: 'Success' }),
          description: t('document.documentCreatedSuccessfully', {
            defaultValue: 'Document created successfully.',
          }) as string,
          type: 'success',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
        navigation.navigate('CourierProfileNavigator', {
          screen: 'CourierHealthTestReportsScreen',
        });
      }
    },
    update(cache, { data }) {
      if (data?.createUserHealthReport) {
        cache.modify({
          fields: {
            userHealthReports(existingUserHealthReports = []) {
              const newUserHealthReport = cache.writeFragment({
                data: data.createUserHealthReport,
                fragment: CREATE_USER_HEALTH_REPORT_FRAGMENT,
              });
              return [...existingUserHealthReports, newUserHealthReport];
            },
          },
        });
      }
    },
    onError() {
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    },
  });

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('document.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description:
          Object.keys(errors).length > 5
            ? (t('document.multipleInvalidField', {
                defaultValue:
                  'Multiple required fields are not filled out properly. Please check and try again.',
              }) as string)
            : Object.keys(errors)
                .map((key) => errors[key])
                .join('\n'),
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const { values } = formik;

    const cityOfTestLocationInput = {
      ...pick(values.cityOfTest, ['formattedAddress', 'placeId', 'description']),
      latitude: values.cityOfTest?.geometry?.location?.lat,
      longitude: values.cityOfTest?.geometry?.location?.lng,
      types: values.cityOfTest?.locationTypes,
    };

    let dateTimeOfTest;
    if (values.dateOfTest && values.timeOfTest) {
      const dateOfTest = new Date(values.dateOfTest);
      const valueHour = values.timeOfTest.split(':')[0];
      const valueMinute = values.timeOfTest.split(':')[1];
      dateTimeOfTest = DateTime.fromObject(
        {
          year: dateOfTest.getFullYear(),
          month: dateOfTest.getMonth() + 1, // Date.getUTCMonth() is 0 for January and has a range from 0 to 11
          day: dateOfTest.getDate(),
          hour: valueHour ? parseInt(valueHour, 10) : 0,
          minute: valueMinute ? parseInt(valueMinute, 10) : 0,
          second: 0,
        },
        {
          zone: values.cityOfTestTimeZoneResponse?.timeZoneId,
        },
      );
    }

    let userHealthReportInput = {
      diseaseType: values.diseaseType,
      testType: values.testType,
      description: values.description,
      dateTimeOfTest,
      testCenter: values.testCenter,
      cityOfTest: values.cityOfTestText,
      cityOfTestLocationInput: cityOfTestLocationInput,
      countryOfTest: values.countryOfTest,
      isResultPositive: !!values.isResultPositive,
    };

    setIsSaving(true);
    let documentsToUpload = [{ key: 'front', fieldKey: 'Front', document: values.documentFront }];
    if (values.documentBack) {
      documentsToUpload.push({
        key: 'back',
        fieldKey: 'Back',
        document: values.documentBack,
      });
    }

    try {
      const documentRes = await uploadDocumentsToServer(documentsToUpload);
      if (documentRes?.length) {
        documentRes.forEach((item) => {
          userHealthReportInput = { ...userHealthReportInput, ...item };
        });
        await uploadUserHealthReport({
          variables: { userHealthReportInput },
        });
      }
    } catch (e) {
      console.warn(e);
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }

    setIsSaving(false);
  };

  const handleSelectDocument = async (side: 'front' | 'back', result: any) => {
    if (side === 'front') {
      formik.setFieldValue('documentFront', result);
    } else {
      formik.setFieldValue('documentBack', result);
    }
  };

  const validationSchema = Yup.object().shape({
    documentFront: Yup.object()
      .nullable()
      .required(
        t('document.requiredFieldDocumentFront', {
          defaultValue: 'Front side of the document must be set',
        }) as string,
      ),
    documentBack: !hasOnlyFrontPage
      ? Yup.object()
          .nullable()
          .required(
            t('document.requiredFieldDocumentBack', {
              defaultValue: 'Back side of the document must be set',
            }) as string,
          )
      : Yup.object().nullable(),
    diseaseType: Yup.string().required(
      t('document.requiredFieldDiseaseType', {
        defaultValue: 'Disease type must be set',
      }) as string,
    ),
    testType: Yup.string().required(
      t('document.requiredFieldTestType', {
        defaultValue: 'Test type must be set',
      }) as string,
    ),
    description: Yup.string().required(
      t('document.requiredFieldDocumentTypeDetails', {
        defaultValue: 'Description must be set',
      }) as string,
    ),
    dateOfTest: Yup.string().required(
      t('document.requiredFieldDateOfTest', {
        defaultValue: 'Date of test must be set',
      }) as string,
    ),
    timeOfTest: Yup.string().required(
      t('document.requiredFieldTimeOfTest', {
        defaultValue: 'Time of test must be set',
      }) as string,
    ),
    testCenter: Yup.string().required(
      t('document.requiredFieldTestCenter', {
        defaultValue: 'Test center must be set',
      }) as string,
    ),
    cityOfTest: Yup.object()
      .nullable()
      .required(
        t('document.requiredFieldCityOfTest', {
          defaultValue: 'City of test must be set',
        }) as string,
      ),
    countryOfTest: Yup.string().required(
      t('document.requiredFieldCountryOfTest', {
        defaultValue: 'Country of test must be set',
      }) as string,
    ),
  });

  const initialValues = {
    documentFront: null,
    documentBack: null,
    diseaseType: VaccinationTargetDiseaseList[1].value,
    testType: '',
    description: '',
    dateOfTest: '',
    timeOfTest: '',
    testCenter: '',
    cityOfTest: '',
    cityOfTestText: '',
    cityOfTestTimeZoneResponse: null,
    countryOfTest: '',
    isResultPositive: false,
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);
    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: formik.touched[field] ? fieldError : '',
        onChange,
        status: formik.touched[field] && fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      errorMessage: fieldError || '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const handleBackPageChange = () => {
    setHasOnlyFrontPage((prev) => {
      if (!prev) {
        formik.setFieldValue('documentBack', null);
      }
      return !prev;
    });
    formik.validateField('documentFront');
    formik.validateField('documentBack');
  };

  const onSelect =
    ({ valueKey }: any) =>
    (value: any) => {
      formik?.setFieldValue(valueKey, value);
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const handleSelectLocation =
    (key: string, keyTextField: string, timeVariable?: any, timezoneKey?: string) =>
    (value: GooglePlacesAutocompleteLocation | undefined, details: any) => {
      if (value) {
        const addressData = getAddressDetailsFromGooglePlaces(details);

        formik.setFieldValue(key, value);
        formik.setFieldValue(keyTextField, addressData.city || addressData.formattedAddress);
        const country = CountryListData.find(
          (item) => item.name === addressData.country || item.code === addressData.countryCode,
        );
        if (country) {
          formik.setFieldValue('countryOfTest', country.code);
        }
        formik.validateField(key);
        if (timezoneKey) {
          const currentTime = DateTime.now();
          resolveTimeZoneForLocation(value.geometry, currentTime.toMillis() / 1000)
            .then((timeZoneResponse) => {
              if (timeZoneResponse && timeZoneResponse.status === 'OK') {
                formik.setFieldValue(timezoneKey, timeZoneResponse);
              }
            })
            .catch((e) => {
              throw e;
            });
        }
      }
    };

  const renderSelectAutocomplete = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormAutocomplete
        appearance="default"
        containerStyle={styles.input}
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey })}
        items={options}
        value={formik.values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={formik?.errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderSelect = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormSelect
        appearance="default"
        containerStyle={styles.input}
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey })}
        items={options}
        value={formik.values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={formik?.errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderLocation = () => (
    <FormLocationAutocomplete
      containerStyle={[globalStyle.marginTop10, globalStyle.marginBottom20, globalStyle.flexGrow0]}
      isCurrentLocationEnabled={false}
      label={t('document.cityOfTest', { defaultValue: 'CITY OF TEST' }) as string}
      placeholderText={
        formik.values.cityOfTest?.formattedAddress ||
        (t('document.cityOfTestPlaceholder', {
          defaultValue: 'Search for the city',
        }) as string)
      }
      onPress={
        handleSelectLocation(
          'cityOfTest',
          'cityOfTestText',
          formik.values.cityOfTest,
          'cityOfTestTimeZoneResponse',
        ) as any
      }
      language={i18n.language}
      isMandatory
      reverseGeocodeLocationTypes={['localities']}
      onChangeText={(text) => {
        formik.setFieldValue('cityOfTestText', text);
      }}
      textInputProps={{
        value: formik.values.cityOfTestText,
      }}
      errorMessage={formik?.errors.pickupLocation}
    />
  );

  const renderDatePicker = ({
    label,
    min,
    max,
    valueKey,
  }: {
    label: string;
    min: any;
    max: any;
    valueKey: string;
  }) => (
    <FormTextDatepicker
      label={label}
      containerStyle={[globalStyle.flex1, globalStyle.marginTop5]}
      min={min}
      max={max}
      date={formik.values[valueKey] && formik.values[valueKey]?.toJSDate()}
      onChange={(value) => {
        formik?.setFieldValue(valueKey, DateTime.fromJSDate(value));
        setTimeout(() => {
          if (formik?.errors[valueKey]) {
            formik.validateField(valueKey);
          }
        }, 100);
      }}
      errorMessage={formik?.errors[valueKey]}
      isMandatory
    />
  );

  const renderTimeSelect = () => (
    <FormTimeSelect
      isMandatory
      containerStyle={[globalStyle.marginLeft10, globalStyle.marginTop10]}
      errorMessage={formik?.errors.timeOfTest}
      label={t('document.timeOfTest', { defaultValue: 'Time of test' })}
      onChange={(time: string) => {
        formik.setFieldValue('timeOfTest', time);
        if (formik?.errors.pickupTime) {
          setTimeout(() => {
            formik.validateField('timeOfTest');
          }, 50);
        }
      }}
      value={formik.values.timeOfTest}
    />
  );

  return (
    <>
      <TopNavigationWithBackButton
        accessoryRight={() => (
          <Button onPress={handleSave} appearance="filled" style={styles.saveBtn}>
            {isSaving ? (
              <ActivityIndicator />
            ) : (
              (t('common.save', { defaultValue: 'Save' }) as string)
            )}
          </Button>
        )}
        title={title}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierProfileNavigator', {
            screen: 'CourierHealthTestReportsScreen',
          });
        }}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={styles.contentContainer}
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
        keyboardShouldPersistTaps="handled"
      >
        <CourierAddDocumentFile
          allowBackPage
          identityPlaceholder
          documentBack={formik.values.documentBack}
          documentFront={formik.values.documentFront}
          hasBackPage={!hasOnlyFrontPage}
          onBackPageChange={handleBackPageChange}
          onSelectDocument={handleSelectDocument}
        />

        <Divider style={globalStyle.marginBottom10} />

        <View style={globalStyle.padding20}>
          {renderSelect({
            disabled: true,
            label: t('document.diseaseOrPathogen', {
              defaultValue: 'DISEASE OR PATHOGEN',
            }) as string,
            valueKey: 'diseaseType',
            options: VaccinationTargetDiseaseList,
            fieldTitleKey: 'name',
            fieldValueKey: 'value',
          })}

          {renderSelect({
            label: t('document.testType', { defaultValue: 'TEST TYPE' }) as string,
            valueKey: 'testType',
            options: HealthTestTypeList,
            fieldTitleKey: 'name',
            fieldValueKey: 'value',
          })}

          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={t('document.testDetails', { defaultValue: 'TEST DETAILS' }) as string}
            isMandatory
            {...fieldProps('description')}
          />

          <View style={globalStyle.paddingBottom20}>
            <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
              {renderDatePicker({
                label: t('document.dateOfTest', { defaultValue: 'DATE OF TEST' }) as string,
                min: minimumBirthDay,
                max: now,
                valueKey: 'dateOfTest',
              })}
              {renderTimeSelect()}
            </View>
            {(formik?.errors.pickupTimeDate || formik?.errors.pickupTime) && (
              <View style={globalStyle.errorContainer}>
                {!!formik?.errors.pickupTimeDate && (
                  <Text style={globalStyle.errorMessageText}>{formik?.errors.pickupTimeDate}</Text>
                )}
                {!!formik?.errors.pickupTime && (
                  <Text style={globalStyle.errorMessageText}>{formik?.errors.pickupTime}</Text>
                )}
              </View>
            )}
          </View>

          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={t('document.testCenter', { defaultValue: 'TEST CENTER' }) as string}
            isMandatory
            {...fieldProps('testCenter')}
          />
          {renderLocation()}

          {renderSelectAutocomplete({
            label: t('document.countryOfTest', { defaultValue: 'COUNTRY OF TEST' }) as string,
            valueKey: 'countryOfTest',
            options: CountryListData,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}

          <CheckBox style={styles.checkBox} {...fieldProps('isResultPositive', 'checkbox')}>
            {t('document.resultIsPositive', { defaultValue: 'RESULT IS POSITIVE' }) as string}
          </CheckBox>
        </View>
      </KeyboardAwareScrollView>
    </>
  );
};
const styles = StyleService.create({
  scrollView: {},
  contentContainer: {
    paddingVertical: 24,
  },
  descriptionText: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  inputTime: {
    flex: 1,
    marginTop: 10,
    marginBottom: 25,
  },
  checkBox: {
    margin: 5,
    marginBottom: 20,
  },
  saveBtn: {
    height: 44,
    width: 75,
  },
});
