import React, { JSXElementConstructor, ReactElement } from 'react';
import { FlatList, RefreshControlProps } from 'react-native';

import { globalStyle } from '../../../../common/style';
import { ConnectedOrganization } from '../../../../generated-graphql-types';
import { AgencyConnectedCourierOrganizationsCardItem } from '../AgencyConnectedCourierCard';

type Props = {
  connectedOrganizations: Array<ConnectedOrganization>;
  refreshControl: ReactElement<RefreshControlProps, string | JSXElementConstructor<any>>;
  renderCustomHeader?: JSX.Element;
  renderCustomEmpty?: JSX.Element;
};

export const AgencyConnectedCourierOrganizationsList = ({
  connectedOrganizations,
  refreshControl,
  renderCustomEmpty,
  renderCustomHeader,
}: Props) => {
  const scrollEnabled = true;
  const scrollToOverflowEnabled = true;

  const renderItem = ({ item }: { item: ConnectedOrganization }) => (
    <AgencyConnectedCourierOrganizationsCardItem connectedOrganization={item} />
  );

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      refreshControl={refreshControl}
      scrollEnabled={scrollEnabled}
      scrollToOverflowEnabled={scrollToOverflowEnabled}
      style={globalStyle.width100Percent}
      ListEmptyComponent={renderCustomEmpty}
      data={connectedOrganizations}
      renderItem={renderItem}
      ListHeaderComponent={renderCustomHeader}
    />
  );
};
