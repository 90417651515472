import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  socialRow: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
  },
  socialIconContainer: {
    marginTop: 50,
    paddingRight: 10,
  },
  socialCopyBtn: {
    margin: 2,
    height: 38,
    marginTop: 42,
  },
  flex1: {
    flex: 1,
  },
  marginVertical20: {
    marginVertical: 20,
  },
});
