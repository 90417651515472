import { useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import * as Localization from 'expo-localization';
import { DateTime, IANAZone } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { AVAILABILITIES_FOR_USER_QUERY } from '../../../apollo/graphql-queries';
import { GooglePlacesAutocompleteLocation } from '../../../components/common/google-places-autocomplete.component';
import {
  CreateAvailabilityMutationVariables,
  useCreateAvailabilityMutation,
} from '../../../generated-graphql-types';
import {
  resolveTimeZoneForLocation,
  TimeZoneResponse,
} from '../../../lib/google-maps-timezone-api';
import { getTimeZoneDifferenceInMinutesForTimestamp } from '../../../utils/date-time.util';

export const useHook = () => {
  const { t } = useTranslation();

  const navigation: any = useNavigation();

  const [
    currentLocationToAvailabilityLocationTimeZoneDifference,
    setCurrentLocationToAvailabilityLocationTimeZoneDifference,
  ] = useState<number | undefined>();
  const [googlePlacesAutocompleteLocation, setGooglePlacesAutocompleteLocation] =
    useState<GooglePlacesAutocompleteLocation>();
  const [locationTimeZoneResponse, setLocationTimeZoneResponse] = useState<TimeZoneResponse>();
  const [locationIANAZone, setLocationIANAZone] = useState<IANAZone>();
  const [range, setRange] = useState<{ startDate?: Date; endDate?: Date }>({});
  const [selectedStart, setSelectedStart] = useState('00:00');
  const [selectedEnd, setSelectedEnd] = useState('00:00');
  const [rangeCalendarMinDate, setRangeCalendarMinDate] = useState<Date | undefined>();
  const [visible, setVisible] = useState<boolean>(true);

  const handleClose = () => {
    navigation.navigate('CourierAvailabilitiesScreen', {
      availabilityCreated: true,
    });

    setTimeout(() => {
      setVisible(false);
      setCurrentLocationToAvailabilityLocationTimeZoneDifference(undefined);
      setGooglePlacesAutocompleteLocation(undefined);
      setLocationTimeZoneResponse(undefined);
      setLocationIANAZone(undefined);
      setRange({});
      setSelectedStart('00:00');
      setSelectedEnd('00:00');
    }, 300);
    setTimeout(() => {
      setVisible(true);
    }, 400);
  };

  const [createAvailabilityMutation, { loading: loadingCreateAvailability }] =
    useCreateAvailabilityMutation({
      onError: () => {
        showMessage({
          message: t('common.error', { defaultValue: 'Error' }),
          description: t('common.somethingWentWrongCreatingAvailability', {
            defaultValue:
              'Something went wrong with creating availability. Please try again or contacting support@priojet.com',
          }) as string,
          type: 'danger',
          autoHide: true,
          hideOnPress: true,
          duration: 8000,
        });
      },
      onCompleted: (data) => {
        if (data.createAvailability) {
          showMessage({
            message: t('courierAvailability.availabilityCreated', {
              defaultValue: 'Availability created',
            }),
            description: t('courierAvailability.newAvailabilityCreated', {
              locationName: data.createAvailability.availabilityLocations
                ? data.createAvailability.availabilityLocations[0].location.formattedAddress
                : t('Determining location...'),
              defaultValue: 'New Availability for {{locationName}} created.',
            }) as string,
            type: 'success',
            autoHide: true,
            hideOnPress: true,
            duration: 8000,
          });
          handleClose();
        }
      },
      refetchQueries: [AVAILABILITIES_FOR_USER_QUERY],
    });

  const rangeCalendarMaxDate = DateTime.now().plus({ years: 1 }).toJSDate();

  useEffect(() => {
    let minDate: DateTime;
    if (locationIANAZone) {
      minDate = DateTime.now()
        .setZone(locationIANAZone)
        .set({ hour: 0, minute: 0, second: 0 })
        .setZone(locationIANAZone, { keepLocalTime: true });

      setCurrentLocationToAvailabilityLocationTimeZoneDifference(
        getTimeZoneDifferenceInMinutesForTimestamp(
          Localization.timezone,
          locationIANAZone,
          minDate,
        ),
      );
    } else {
      minDate = DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toLocal();
      setCurrentLocationToAvailabilityLocationTimeZoneDifference(undefined);
    }
    setRangeCalendarMinDate(minDate.setZone('utc', { keepLocalTime: true }).toJSDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Localization.timezone, locationIANAZone]);

  const { startTime, endTime } = useMemo(() => {
    let _startTime;
    let _endTime;

    const selectedStartHour = selectedStart.split(':')[0];
    const selectedStartMinute = selectedStart.split(':')[1];
    const selectedEndHour = selectedEnd.split(':')[0];
    const selectedEndMinute = selectedEnd.split(':')[1];
    if (range.startDate) {
      _startTime = DateTime.fromJSDate(range.startDate)
        .set({
          hour: parseInt(selectedStartHour, 10) || 0,
          minute: parseInt(selectedStartMinute, 10) || 0,
          second: 0,
        })
        .setZone(locationIANAZone, {
          keepLocalTime: true,
        });
    }
    if (range.endDate) {
      _endTime = DateTime.fromJSDate(range.endDate)
        .set({
          hour: parseInt(selectedEndHour, 10) || 0,
          minute: parseInt(selectedEndMinute, 10) || 0,
          second: 0,
        })
        .setZone(locationIANAZone, {
          keepLocalTime: true,
        });
    }

    return { startTime: _startTime, endTime: _endTime };
  }, [locationIANAZone, range, selectedStart, selectedEnd]);

  const { isValid, errorMessages } = useMemo(() => {
    let errors: string[] = [];
    if (googlePlacesAutocompleteLocation && range.startDate && range.endDate) {
      if (!locationTimeZoneResponse || !locationIANAZone) {
        errors = [...errors, t('Please wait for the location to resolve.')];
      }
      if (!selectedStart) {
        errors = [...errors, t('Choose a start time.')];
      }
      if (!selectedEnd) {
        errors = [...errors, t('Choose an end time.')];
      }

      if (startTime && endTime && endTime.diff(startTime, 'milliseconds').milliseconds <= 0) {
        errors = [...errors, t('Start time has to be before end time')];
      }
      if (
        startTime &&
        startTime.diff(DateTime.now().setZone(locationIANAZone), 'milliseconds').milliseconds <= 0
      ) {
        errors = [...errors, t('Start time cannot be in the past')];
      }
    }
    return { isValid: errors.length === 0, errorMessages: errors };
  }, [
    endTime,
    googlePlacesAutocompleteLocation,
    locationIANAZone,
    locationTimeZoneResponse,
    range,
    selectedEnd,
    selectedStart,
    startTime,
    t,
  ]);

  const isCreateAvailabilityButtonDisabled = useMemo((): boolean => {
    return (
      loadingCreateAvailability ||
      !isValid ||
      !googlePlacesAutocompleteLocation ||
      !range.startDate ||
      !range.endDate
    );
  }, [googlePlacesAutocompleteLocation, loadingCreateAvailability, isValid, range]);

  const handleCreateAvailabilityButtonPressOn = async () => {
    if (
      isValid &&
      googlePlacesAutocompleteLocation &&
      locationIANAZone &&
      locationTimeZoneResponse &&
      startTime &&
      endTime
    ) {
      const variables: CreateAvailabilityMutationVariables = {
        suggestedLocationInput: {
          description: googlePlacesAutocompleteLocation.description,
          formattedAddress: googlePlacesAutocompleteLocation.formattedAddress,
          latitude: googlePlacesAutocompleteLocation.geometry.location.lat,
          longitude: googlePlacesAutocompleteLocation.geometry.location.lng,
          types: googlePlacesAutocompleteLocation.locationTypes,
          placeId: googlePlacesAutocompleteLocation.placeId,
        },
        startTime,
        endTime,
        timeZoneIdentifier: locationIANAZone?.name,
        timeZoneDestinationOffset: locationIANAZone.offset(startTime.toMillis()),
        timeZoneRawOffset: locationTimeZoneResponse.rawOffset,
      };

      await createAvailabilityMutation({ variables });
    }
  };

  const googlePlacesAutocompleteOnChangeText = (text: string) => {
    if (!text || text === '') {
      setRange({});
      setLocationIANAZone(undefined);
      setSelectedStart('00:00');
      setSelectedEnd('00:00');
    }
  };

  const googlePlacesAutocompleteOnPress = (
    _googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
  ) => {
    setGooglePlacesAutocompleteLocation(_googlePlacesAutocompleteLocation);
    if (_googlePlacesAutocompleteLocation?.geometry) {
      resolveTimeZoneForLocation(
        _googlePlacesAutocompleteLocation.geometry,
        startTime
          ? Math.round(startTime.toMillis() / 1000)
          : Math.round((rangeCalendarMinDate?.getTime() || Date.now()) / 1000),
      )
        .then((timeZoneResponse) => {
          if (timeZoneResponse && timeZoneResponse.status === 'OK') {
            setLocationTimeZoneResponse(timeZoneResponse);
            if (!IANAZone.isValidZone(timeZoneResponse.timeZoneId)) {
              Alert.alert('Reveid an invalid time zone identifier ' + timeZoneResponse.timeZoneId);
            } else {
              setLocationIANAZone(new IANAZone(timeZoneResponse.timeZoneId));
            }
          }
        })
        .catch((e) => {
          throw e;
        });
    }
  };

  const handleSelectRange = (nextRange: any) => {
    setRange(nextRange);
    if (nextRange.endDate) {
      if (googlePlacesAutocompleteLocation && locationIANAZone) {
        const minStartDate = DateTime.now()
          .plus({ minutes: 15 })
          .setZone(locationIANAZone)
          .setZone(locationIANAZone, { keepLocalTime: true });
        let _startTime;
        let _endTime;
        const selectedStartHour = selectedStart.split(':')[0];
        const selectedStartMinute = selectedStart.split(':')[1];
        if (range.startDate) {
          _startTime = DateTime.fromJSDate(range.startDate)
            .set({
              hour: parseInt(selectedStartHour, 10) || 0,
              minute: parseInt(selectedStartMinute, 10) || 0,
              second: 0,
            })
            .setZone(locationIANAZone, {
              keepLocalTime: true,
            });

          if (_startTime.toFormat('yyyy-MM-dd') === minStartDate.toFormat('yyyy-MM-dd')) {
            let minHour = `${minStartDate.get('hour')}`;
            let minMinute = `${Math.floor((minStartDate.get('minute') + 1) / 15) * 15}`;
            if (minHour.length === 1) {
              minHour = `0${minHour}`;
            }
            if (minMinute.length === 1) {
              minMinute = `0${minMinute}`;
            }
            setSelectedStart(`${minHour}:${minMinute}`);
          }
        }

        const selectedEndHour = selectedStart.split(':')[0];
        const selectedEndMinute = selectedStart.split(':')[1];
        const minEndDate = DateTime.now()
          .plus({ minutes: 61 })
          .setZone(locationIANAZone)
          .setZone(locationIANAZone, { keepLocalTime: true });
        _endTime = DateTime.fromJSDate(nextRange.endDate)
          .set({
            hour: parseInt(selectedEndHour, 10) || 0,
            minute: parseInt(selectedEndMinute, 10) || 0,
            second: 0,
          })
          .setZone(locationIANAZone, {
            keepLocalTime: true,
          });
        if (_endTime.toFormat('yyyy-MM-dd') === minEndDate.toFormat('yyyy-MM-dd')) {
          let minHour = `${minEndDate.get('hour')}`;
          let minMinute = `${Math.floor((minEndDate.get('minute') + 1) / 15) * 15}`;
          if (minHour.length === 1) {
            minHour = `0${minHour}`;
          }
          if (minMinute.length === 1) {
            minMinute = `0${minMinute}`;
          }
          setSelectedEnd(`${minHour}:${minMinute}`);
        }
      }
    }
  };

  const handleChangeStart = (value: string) => {
    setSelectedStart(value);
  };

  const handleChangeEnd = (value: string) => {
    setSelectedEnd(value);
  };

  const { timezoneAheadOrBehind, timeZoneEqualToLaterThanOrEarlierThan } = useMemo(() => {
    let _timezoneAheadOrBehind = t('courierAvailability.behind', { defaultValue: 'behind' });
    let _timeZoneEqualToLaterThanOrEarlierThan = t('courierAvailability.appearsEarlierThan', {
      defaultValue: 'appears still earlier than',
    });

    if (currentLocationToAvailabilityLocationTimeZoneDifference === undefined) {
      _timezoneAheadOrBehind = '...';
      _timeZoneEqualToLaterThanOrEarlierThan = '...';
    }
    if (currentLocationToAvailabilityLocationTimeZoneDifference === 0) {
      _timezoneAheadOrBehind = t('courierAvailability.isEqualTo', {
        defaultValue: 'and is equal to your',
      });
      _timeZoneEqualToLaterThanOrEarlierThan = t('courierAvailability.appearsEqualTo', {
        defaultValue: 'appears equal to',
      });
    }
    if (
      currentLocationToAvailabilityLocationTimeZoneDifference &&
      currentLocationToAvailabilityLocationTimeZoneDifference > 0
    ) {
      _timezoneAheadOrBehind = t('courierAvailability.ahead', { defaultValue: 'ahead' });
      _timeZoneEqualToLaterThanOrEarlierThan = t('courierAvailability.appearsLaterThan', {
        defaultValue: 'appears already later than',
      });
    }

    return {
      timezoneAheadOrBehind: _timezoneAheadOrBehind,
      timeZoneEqualToLaterThanOrEarlierThan: _timeZoneEqualToLaterThanOrEarlierThan,
    };
  }, [currentLocationToAvailabilityLocationTimeZoneDifference, t]);

  const startTimePlaceholder = useMemo(() => {
    if (startTime && googlePlacesAutocompleteLocation?.formattedAddress) {
      return t('courierAvailability.pleaseSelectStartTimeForPlaceOfAvailability', {
        startDateString: startTime.toLocaleString(DateTime.DATETIME_FULL, {
          locale: Localization.locale,
        }),
        locationName: googlePlacesAutocompleteLocation?.formattedAddress,
        defaultValue:
          'Please select the start time from when you are available on the {{startDateString}} in {{locationName}}.',
      }) as string;
    }
    if (
      googlePlacesAutocompleteLocation?.formattedAddress &&
      googlePlacesAutocompleteLocation?.formattedAddress !== ''
    ) {
      return t('courierAvailability.pleaseSelectStartTimeForAvailabilityLocationOnly', {
        locationName: googlePlacesAutocompleteLocation?.formattedAddress,
        defaultValue:
          'Please select the start time from when you are available in {{locationName}}.',
      }) as string;
    }
    if (startTime) {
      return t('courierAvailability.pleaseSelectStartTimeForAvailabilityDateTimeOnly', {
        startDateString: startTime.toLocaleString(DateTime.DATETIME_FULL, {
          locale: Localization.locale,
        }),
        defaultValue:
          'Please select the start time from when you are available on the {{startDateString}}.',
      }) as string;
    }
    return t('courierAvailability.pleaseSelectStartTime', {
      defaultValue: 'Please select a start time from when you are available.',
    }) as string;
  }, [googlePlacesAutocompleteLocation?.formattedAddress, startTime, t]);

  const endTimePlaceholder = useMemo(() => {
    if (endTime && googlePlacesAutocompleteLocation?.formattedAddress) {
      return t('courierAvailability.pleaseSelectEndTimeForPlaceOfAvailability', {
        endDateString: endTime.toLocaleString(DateTime.DATETIME_FULL, {
          locale: Localization.locale,
        }),
        locationName: googlePlacesAutocompleteLocation?.formattedAddress,
        defaultValue:
          'Please select the end time until you are available on the {{endDateString}} in {{locationName}}.',
      }) as string;
    }
    if (
      googlePlacesAutocompleteLocation?.formattedAddress &&
      googlePlacesAutocompleteLocation?.formattedAddress !== ''
    ) {
      return t('courierAvailability.pleaseSelectEndTimeForAvailabilityLocationOnly', {
        locationName: googlePlacesAutocompleteLocation?.formattedAddress,
        defaultValue: 'Please select the end time until you are available in {{locationName}}.',
      }) as string;
    }
    if (endTime) {
      return t('courierAvailability.pleaseSelectEndTimeForAvailabilityDateTimeOnly', {
        endDateString: endTime.toLocaleString(DateTime.DATETIME_FULL, {
          locale: Localization.locale,
        }),
        defaultValue:
          'Please select the end time until you are available on the {{endDateString}}.',
      }) as string;
    }
    return t('courierAvailability.pleaseSelectEndTime', {
      defaultValue: 'Please select the end time until you are available.',
    }) as string;
  }, [googlePlacesAutocompleteLocation?.formattedAddress, endTime, t]);

  return {
    currentLocationToAvailabilityLocationTimeZoneDifference,
    endTime,
    endTimePlaceholder,
    errorMessages,
    googlePlacesAutocompleteLocation,
    googlePlacesAutocompleteOnChangeText,
    googlePlacesAutocompleteOnPress,
    handleCreateAvailabilityButtonPressOn,
    handleChangeEnd,
    handleChangeStart,
    handleClose,
    handleSelectRange,
    isCreateAvailabilityButtonDisabled,
    loadingCreateAvailability,
    locationIANAZone,
    range,
    rangeCalendarMinDate,
    rangeCalendarMaxDate,
    selectedEnd,
    selectedStart,
    startTime,
    startTimePlaceholder,
    timezoneAheadOrBehind,
    timeZoneEqualToLaterThanOrEarlierThan,
    visible,
  };
};
