import React from 'react';
import { Dimensions, StyleProp, TextStyle, View } from 'react-native';

import {
  Divider,
  Input,
  LayoutProps,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';

import { CopyableText } from '../common.copyable-text.component';

export interface FormTextTextComponentProps extends LayoutProps {
  copyable?: boolean;
  direction?: 'column' | 'row';
  hint: string;
  stringValue?: string;
  newLineAtCommaForStringValue?: boolean;
  jsxElementValue?: JSX.Element;
  writable?: boolean;
  onChange?: any;
  useTwoRows?: boolean;
  hintStyle?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const DisplayValue = ({
  stringValue,
  copyable,
  newLineAtCommaForStringValue,
  textStyle,
}: {
  stringValue?: string;
  copyable?: boolean;
  newLineAtCommaForStringValue?: boolean;
  textStyle?: StyleProp<TextStyle>;
}) => {
  const styles = useStyleSheet(themedStyles);

  if (newLineAtCommaForStringValue && stringValue) {
    stringValue = stringValue.split(',').join(',\n');
  }

  if (copyable) {
    return (
      <View style={styles.flex1}>
        <CopyableText
          copyableTextName={stringValue}
          text={stringValue || 'n/a'}
          style={[styles.textStyle, textStyle]}
          textStyle={textStyle}
        />
      </View>
    );
  }
  return (
    <View style={styles.textStyleContainer}>
      <Text style={[styles.textStyle, textStyle]} selectable={true}>
        {stringValue || 'n/a'}
      </Text>
    </View>
  );
};

export const FormTextTextComponent = (props: FormTextTextComponentProps): React.ReactElement => {
  const {
    copyable,
    style,
    hintStyle,
    textStyle,
    hint,
    stringValue,
    jsxElementValue,
    onChange,
    writable,
    newLineAtCommaForStringValue,
    direction = 'row',
  } = props;
  const styles = useStyleSheet(themedStyles);

  const styleSwitchCondition = direction
    ? direction
    : Dimensions.get('window').width > 300 && stringValue && stringValue.length < 20;

  const renderContent = () => {
    if (jsxElementValue) {
      return jsxElementValue;
    }
    if (writable) {
      return <Input value={stringValue} onChangeText={onChange} style={styles.input} />;
    }
    return (
      <DisplayValue
        copyable={copyable}
        newLineAtCommaForStringValue={newLineAtCommaForStringValue}
        stringValue={stringValue}
        textStyle={textStyle}
      />
    );
  };

  return (
    <>
      <View
        style={[styles.container, styleSwitchCondition ? styles.flexRow : styles.flexColumn, style]}
      >
        <Text selectable={true} style={[styles.hint, hintStyle]}>
          {hint}
        </Text>
        {renderContent()}
      </View>
      <Divider />
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  flex1: {
    flex: 1,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  hint: {
    flex: 1,
    marginBottom: 4,
    color: 'text-basic-color',
    fontFamily: 'Lato_700Bold',
    alignSelf: 'flex-start',
  },
  input: {
    flex: 1,
    flexGrow: 1,
  },
  textStyleContainer: {
    flex: 1,
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    flexDirection: 'row',
    maxWidth: 900,
  },
  textStyle: {
    alignSelf: 'flex-end',
    alignContent: 'flex-end',
    flexDirection: 'row',
    textAlign: 'right',
    color: 'text-basic-color',
    minWidth: 100,
  },
});
