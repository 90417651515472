import { Alert, Platform } from 'react-native';

const createInfoPopup = async (props: {
  title: string;
  message: string;
  onOKPressed: () => Promise<boolean>;
  okButtonText?: string;
}) =>
  new Promise<boolean>((resolve) => {
    if (Platform.OS === 'web') {
      if (confirm(props.title + '\n\n' + props.message)) {
        resolve(props.onOKPressed());
      }
    } else {
      Alert.alert(
        props.title,
        props.message,
        [
          {
            text: props.okButtonText || 'Continue',
            onPress: () => {
              resolve(props.onOKPressed());
            },
          },
        ],
        { cancelable: false },
      );
    }
  });

export { createInfoPopup };
