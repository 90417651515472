import { useImperativeHandle, useState } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export const useHook = ({ handleCancel, handleConfirm, handleConfirmAndNext, ref }: any) => {
  const { t } = useTranslation();

  const [dataChangedConfirmationModalVisible, setDataChangedConfirmationModalVisible] =
    useState(false);
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [time, setTime] = useState<string>('');
  const [errorDate, setErrorDate] = useState<string>('');
  const [errorTime, setErrorTime] = useState<string>('');
  const [initialData, setInitialData] = useState<{ date: Date | undefined; time: string }>({
    date: undefined,
    time: '',
  });

  const handleDateChange = (v: Date) => {
    setDate(v);
    if (errorDate) {
      setErrorDate('');
    }
  };

  const handleTimeChange = (v: string) => {
    setTime(v);
    if (errorTime) {
      setErrorTime('');
    }
  };

  const handleSave = () => {
    if (!date || !time) {
      if (!date) {
        setErrorDate(t('common.requiredField', { defaultValue: 'Required field' }));
      }
      if (!time) {
        setErrorTime(t('common.requiredField', { defaultValue: 'Required field' }));
      }
    } else {
      handleConfirm(
        DateTime.fromJSDate(date)
          .setZone('utc')
          .set({
            hour: parseInt(time.split(':')[0], 10),
            minute: parseInt(time.split(':')[1], 10),
            second: 0,
          }),
      );
      setInitialData({ date, time });
    }
  };

  const handleSaveAndNextStep = () => {
    if (!date || !time) {
      if (!date) {
        setErrorDate(t('common.requiredField', { defaultValue: 'Required field' }));
      }
      if (!time) {
        setErrorTime(t('common.requiredField', { defaultValue: 'Required field' }));
      }
    } else {
      handleConfirmAndNext(
        DateTime.fromJSDate(date)
          .setZone('utc')
          .set({
            hour: parseInt(time.split(':')[0], 10),
            minute: parseInt(time.split(':')[0], 10),
            second: 0,
          }),
      );
      setInitialData({ date, time });
    }
  };

  const handleSetData = (data: { dateTime: DateTime }) => {
    setDate(data.dateTime ? data.dateTime.setZone('utc').toJSDate() : undefined);
    setTime(data.dateTime ? data.dateTime.setZone('utc').toFormat('HH:mm') : time);
    setInitialData({
      date: data.dateTime ? data.dateTime.toJSDate() : date,
      time: data.dateTime ? data.dateTime.setZone('utc').toFormat('HH:mm') : time,
    });
  };

  const checkDataChanged = () => {
    if (!date || !time) {
      return false;
    }
    if (
      initialData.date &&
      date &&
      DateTime.fromJSDate(initialData.date).toFormat('dd MMM yyyy') !==
        DateTime.fromJSDate(date).toFormat('dd MMM yyyy')
    ) {
      return true;
    }
    if (initialData.time !== time) {
      return true;
    }
    return false;
  };

  const handleClose = () => {
    const check = checkDataChanged();
    if (check) {
      setDataChangedConfirmationModalVisible(true);
    } else {
      handleCancel();
    }
  };

  const handleCancelDataChangedPress = () => {
    handleCancel();
    setDataChangedConfirmationModalVisible(false);
  };

  const handleConfirmDataChangedPress = () => {
    setDataChangedConfirmationModalVisible(false);
    handleSave();
  };

  useImperativeHandle(ref, () => ({
    setData: handleSetData,
  }));

  return {
    date,
    dataChangedConfirmationModalVisible,
    time,
    errorDate,
    errorTime,
    handleClose,
    handleCancelDataChangedPress,
    handleConfirmDataChangedPress,
    handleDateChange,
    handleTimeChange,
    handleSave,
    handleSaveAndNextStep,
  };
};
