import React from 'react';

import { RouteProp } from '@react-navigation/core';

import { CourierProfileTopNavigationBar } from '../../components/courier/courier-profile-top-navigation-bar.component';
import { CourierAddUserBackgroundDocumentComponent } from '../../components/courier/courier.add-background-document.component';
import { CourierAddUserOtherDocumentComponent } from '../../components/courier/courier.add-other-document.component';
import { CourierAddUserFrequentFlyerDocumentComponent } from '../../components/courier/courier.add-user-frequent-flyer-document.component';
import { CourierAddUserHealthReportComponent } from '../../components/courier/courier.add-user-health-report.component';
import { CourierAddUserIdentityDocumentComponent } from '../../components/courier/courier.add-user-identity-document.component';
import { CourierAddUserTravelDocumentComponent } from '../../components/courier/courier.add-user-travel-document.component';
import { CourierAddUserVaccinationDocumentComponent } from '../../components/courier/courier.add-user-vaccination-document.component';
import { CourierReadUserDocumentComponent } from '../../components/courier/courier.read-user-document.component';
import { AppNavigatorProp, CourierProfileNavigatorParamList } from '../../navigation/app.navigator';

interface CourierUserIdentityDocumentsScreenProps {
  children?: React.ReactNode;
  navigation: AppNavigatorProp<'CommonUserDocumentDetailScreen'>;
  route: RouteProp<CourierProfileNavigatorParamList, 'CommonUserDocumentDetailScreen'>;
}

export const CommonUserDocumentDetailScreen: React.FC<CourierUserIdentityDocumentsScreenProps> = ({
  route,
  navigation,
}) => {
  if (route.params?.readOnly && route.params?.userDocument) {
    const handleBack = () => {
      if (route.params?.previousScreenName) {
        navigation.navigate(route.params.previousScreenName);
      } else {
        navigation.goBack();
      }
    };

    return (
      <>
        <CourierProfileTopNavigationBar
          title={route.params?.title}
          navigation={navigation}
          onPressLeft={handleBack}
        />
        <CourierReadUserDocumentComponent
          handleBack={handleBack}
          userDocument={route.params?.userDocument}
          userDocumentId={route.params?.userDocumentId}
          userDocumentType={route.params.targetDocument}
        />
      </>
    );
  }
  switch (route.params?.targetDocument) {
    case 'UserIdentityDocument':
      return (
        <CourierAddUserIdentityDocumentComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    case 'UserTravelDocument':
      return (
        <CourierAddUserTravelDocumentComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    case 'UserFrequentFlyerDocument':
      return (
        <CourierAddUserFrequentFlyerDocumentComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    case 'UserVaccinationDocument':
      return (
        <CourierAddUserVaccinationDocumentComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    case 'UserHealthReport':
      return (
        <CourierAddUserHealthReportComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    case 'UserBackgroundDocument':
      return (
        <CourierAddUserBackgroundDocumentComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    case 'UserOtherDocument':
      return (
        <CourierAddUserOtherDocumentComponent
          navigation={navigation}
          title={route.params?.title}
          user={route.params?.user}
        />
      );
    default:
      return <></>;
  }
};
