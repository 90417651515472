import { Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  textarea: {
    minHeight: Platform.OS === 'web' ? 160 : 120,
    paddingVertical: 10,
  },
});
