import React, { useContext } from 'react';
import { Platform } from 'react-native';

import { NavigationProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { CommonAgencyAccountSettingsMobile } from './CommonAgencyAccountSettingsMobile';
import { CommonAgencyAccountSettingsWeb } from './CommonAgencyAccountSettingsWeb';
import { TopNavigationBackToProfileButton } from '../../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { RootStackParamList } from '../../../navigation/app.navigator';

export const CommonAgencyAccountSettingsScreen = ({
  navigation,
}: {
  navigation: NavigationProp<RootStackParamList>;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  return (
    <>
      <TopNavigationBackToProfileButton
        title={t('menuItems.agencyAccountSettings', { defaultValue: 'Agency Account Settings' })}
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />

      {Platform.OS === 'web' ? (
        <CommonAgencyAccountSettingsWeb />
      ) : (
        <CommonAgencyAccountSettingsMobile />
      )}
    </>
  );
};
