import React from 'react';
import { Alert, Platform, Pressable, StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import {
  Icon,
  IconProps,
  IndexPath,
  MenuItem,
  OverflowMenu,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { Text } from '@ui-kitten/components';
import * as ExpoClipboard from 'expo-clipboard';
import * as ExpoLinking from 'expo-linking';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { globalStyle } from '../../common/style';

const PhoneIcon = (props: IconProps) => <Icon {...props} name="phone-call-outline" />;
const CopyIcon = (props: IconProps) => <Icon {...props} name="copy-outline" />;

export const PhoneTextWithContextMenu = ({
  style,
  phone,
  textStyle,
  textStyleNoAddress,
}: {
  phone?: string | null;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  textStyleNoAddress?: StyleProp<TextStyle>;
}) => {
  const { t } = useTranslation();

  const [visible, setVisible] = React.useState(false);

  const styles = useStyleSheet(themedStyles);

  const onSelect = (index: IndexPath) => {
    if (index.row === 0) {
      if (phone) {
        ExpoClipboard.setStringAsync(phone);
        showMessage({
          message: t('common.Copied', { defaultValue: 'Copied!' }),
          description: t('common.phoneNumberCopySuccessMessageDescription', {
            defaultValue: 'Copied phone number {{phoneNumber}} to clipbaord.',
            phoneNumber: phone,
          }) as string,
          renderFlashMessageIcon: () => <Icon name="check-outline" />,
          type: 'success',
        });
      } else {
        Alert.alert(
          t('common.couldNotCopyPhoneNumberNoPhoneNumberGiven', {
            defaultValue: 'Could not copy phone number. No phone number given.',
          }),
        );
      }
    } else if (index.row === 1) {
      const url = 'tel:' + phone;
      ExpoLinking.canOpenURL(url)
        .then(() => {
          ExpoLinking.openURL(url).catch((e) => {
            console.warn(e);
          });
        })
        .catch(() => {
          Alert.alert(
            t('common.cannotOpenPhoneApp', {
              defaultValue: 'Cannot open phone app to make a call.',
            }),
          );
        });
    }
    setVisible(false);
  };

  const renderToggleButton = () => (
    <Pressable onPress={() => setVisible(true)}>
      <View style={[globalStyle.flexRow, style]}>
        <Text selectable={true} style={[styles.textStyle, textStyle]}>
          {phone || ''}
        </Text>
        <Icon
          name="diagonal-arrow-right-up-outline"
          width={16}
          height={16}
          style={globalStyle.marginLeft2}
          fill="#777"
        />
      </View>
    </Pressable>
  );

  if (!phone) {
    return (
      <Text selectable={true} style={[styles.textStyle, textStyle, textStyleNoAddress]}>
        {
          t('common.noPhoneNumberProvided', {
            defaultValue: 'No phone number provided',
          }) as string
        }
      </Text>
    );
  }
  return (
    <OverflowMenu
      anchor={renderToggleButton}
      visible={visible}
      onSelect={onSelect}
      placement="bottom start"
      onBackdropPress={() => {
        setVisible(false);
      }}
      style={styles.menu}
    >
      <MenuItem title="Copy" accessoryLeft={CopyIcon} />
      <MenuItem title="Call" accessoryLeft={PhoneIcon} />
    </OverflowMenu>
  );
};

const themedStyles = StyleService.create({
  menu: {
    marginTop: Platform.select({ ios: 50, android: 70, web: 0 }),
    flex: 1,
    height: 90,
  },
  textStyle: {
    color: 'text-basic-color',
  },
});
