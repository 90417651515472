import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  textLeft: {
    textAlign: 'left',
    color: 'text-basic-color',
  },
  textRight: {
    textAlign: 'right',
    color: 'text-basic-color',
  },
  messageText: {
    maxWidth: 480,
  },
  pdfContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  pdfButton: {
    padding: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: 'border-basic-color-4',
    borderRadius: 6,
    backgroundColor: 'background-basic-color-4',
  },
  downloadPdfButton: {
    padding: 10,
    paddingRight: 0,
  },
  downloadIcon: {
    width: 20,
    height: 20,
    color: 'text-basic-color',
  },
});
