import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  seatCounterContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5,
    padding: 10,
    paddingHorizontal: 10,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'background-basic-color-3',
    backgroundColor: 'background-basic-color-3',
  },
  seatCounterContainerError: {
    borderWidth: 1,
    borderColor: 'color-danger-500',
    backgroundColor: '#E6241B22',
  },
  seatCounterText: {
    fontSize: 14,
    fontFamily: 'Lato_700Bold',
    textAlign: 'center',
  },
});
