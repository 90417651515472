import React, { useMemo, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CheckBox,
  Divider,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';

import { CREATE_USER_TRAVEL_DOCUMENT_FRAGMENT } from '../../apollo/graphql-fragments';
import { CREATE_USER_TRAVEL_DOCUMENT_MUTATION } from '../../apollo/graphql-mutations';
import { USER_IDENTITY_DOCUMENTS_QUERY } from '../../apollo/graphql-queries';
import { CountryListData } from '../../assets/constants/country.list.data';
import { globalStyle } from '../../common/style';
import AppUserContext from '../../contexts/AppUserContext';
import { IdentityDocumentType, UserIdentityDocument } from '../../generated-graphql-types';
import { User_user } from '../../graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { sexList, TravelDocumentSubTypeOptions, UserDocument } from '../../types';
import { uploadDocumentsToServer } from '../../utils/file-upload.util';
import { CourierAddDocumentFile } from '../common/courier.add-document-image-pdf';
import { FormAutocomplete } from '../common/form/form-autocomplete.component';
import { FormTextDatepicker } from '../common/form/form-text-datepicker.component';
import { FormTextInputTopLabel } from '../common/form/form-text-input-top-label.component';
import { FormSelect } from '../common/form/FormSelect';
import ContentBox from '../content-box.component';
import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const now = new Date();
const minimumBirthDay = new Date(1900, 1, 1);
const maximumBirthDay = new Date(2100, 1, 1);

export const CourierAddUserTravelDocumentComponent = ({
  navigation,
  title,
}: {
  navigation: any;
  title: string;
  user?: User_user;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = React.useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const styles = useStyleSheet(themedStyles);

  const { data: userIdentityDocumentsData } = useQuery<{
    userIdentityDocuments: UserIdentityDocument[];
  }>(USER_IDENTITY_DOCUMENTS_QUERY, {
    variables: { userId: appUserContext.currentUserContext?.user?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const [hasOnlyFrontPage, setHasOnlyFrontPage] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const userIdentityDocuments = useMemo(() => {
    if (userIdentityDocumentsData?.userIdentityDocuments?.length) {
      return userIdentityDocumentsData?.userIdentityDocuments
        ?.filter((item) => item.documentType === IdentityDocumentType.NATIONAL_ID)
        .map((item) => {
          const nationality = CountryListData.find((country) => country.code === item.nationality);
          return {
            ...item,
            name: `${nationality?.name || item.nationality} | ${item.documentNumber}`,
          };
        });
    }
    return [];
  }, [userIdentityDocumentsData]);

  const SexList = useMemo(() => {
    return sexList.map((sex) => ({
      ...sex,
      name: t(`common.${sex.name}`, { defaultValue: sex.name }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [uploadUserTravelDocument] = useMutation<
    { createUserTravelDocument: UserDocument },
    { userTravelDocumentInput: UserDocument; mappedUserIdentityDocumentId: string }
  >(CREATE_USER_TRAVEL_DOCUMENT_MUTATION, {
    async onCompleted(data) {
      if (data.createUserTravelDocument) {
        showMessage({
          message: t('document.success', { defaultValue: 'Success' }),
          description: t('document.documentCreatedSuccessfully', {
            defaultValue: 'Document created successfully.',
          }) as string,
          type: 'success',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
        navigation.navigate('CourierProfileNavigator', {
          screen: 'CourierUserTravelPermitsScreen',
        });
      }
    },
    update(cache, { data }) {
      if (data?.createUserTravelDocument) {
        cache.modify({
          fields: {
            userTravelDocuments(existingUserTravelDocuments = []) {
              const newUserTravelDocument = cache.writeFragment({
                data: data.createUserTravelDocument,
                fragment: CREATE_USER_TRAVEL_DOCUMENT_FRAGMENT,
              });
              return [...existingUserTravelDocuments, newUserTravelDocument];
            },
          },
        });
      }
    },
    onError() {
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    },
  });

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('document.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description:
          Object.keys(errors).length > 5
            ? (t('document.multipleInvalidField', {
                defaultValue:
                  'Multiple required fields are not filled out properly. Please check and try again.',
              }) as string)
            : Object.keys(errors)
                .map((key) => errors[key])
                .join('\n'),
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const { values } = formik;
    let userTravelDocumentInput = {
      documentType: 'VISA',
      documentTypeDetails: values.documentTypeDetails,
      documentSubType: values.documentSubType[0],
      documentSubTypeMulti: values.documentSubType,
      description: values.description,
      multipleEntriesAllowed: values.multipleEntriesAllowed,
      validForCountry: values.validForCountry,
      dateOfIssue: values.dateOfIssue,
      expiresOn: values.expiresOn,
      documentNumber: values.documentNumber,
      countryOfIssue: values.countryOfIssue,
      placeOfIssue: values.placeOfIssue,
      firstNames: values.firstNames,
      lastName: values.lastName,
      dateOfBirth: values.dateOfBirth,
      nationality: values.nationality,
      placeOfBirth: values.placeOfBirth,
      sex: values.sex,
      countryOfBirth: values.countryOfBirth,
    };
    setIsSaving(true);
    let documentsToUpload = [{ key: 'front', fieldKey: 'Front', document: values.documentFront }];
    if (values.documentBack) {
      documentsToUpload.push({ key: 'back', fieldKey: 'Back', document: values.documentBack });
    }
    try {
      const documentRes = await uploadDocumentsToServer(documentsToUpload);
      if (documentRes?.length) {
        documentRes.forEach((item) => {
          userTravelDocumentInput = {
            ...userTravelDocumentInput,
            ...item,
          };
        });
        await uploadUserTravelDocument({
          variables: {
            userTravelDocumentInput,
            mappedUserIdentityDocumentId: values.mappedUserIdentityDocumentId,
          },
        });
      }
    } catch (e) {
      console.warn(e);
      showMessage({
        message: t('document.error', { defaultValue: 'Error' }),
        description: t('document.somethingWentWrongWithSaving', {
          defaultValue: 'Sorry, something went wrong with saving document.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }

    setIsSaving(false);
  };

  const validationSchema = Yup.object().shape({
    documentFront: Yup.object()
      .nullable()
      .required(
        t('document.requiredFieldDocumentFront', {
          defaultValue: 'Front side of the document must be set',
        }) as string,
      ),
    documentBack: !hasOnlyFrontPage
      ? Yup.object()
          .nullable()
          .required(
            t('document.requiredFieldDocumentBack', {
              defaultValue: 'Back side of the document must be set',
            }) as string,
          )
      : Yup.object().nullable(),

    description: Yup.string().required(
      t('document.requiredFieldDocumentTypeDetails', {
        defaultValue: 'Document type details must be set',
      }) as string,
    ),
    documentSubType: Yup.array()
      .required(
        t('document.requiredFieldDocumentSubType', {
          defaultValue: 'Document sub type must be set',
        }) as string,
      )
      .min(
        1,
        t('document.requiredFieldDocumentSubTypeAtLeastOne', {
          defaultValue: 'Please select at least one sub type',
        }) as string,
      ),
    documentNumber: Yup.string().required(
      t('document.requiredFieldDocumentNumber', {
        defaultValue: 'Document number must be set',
      }) as string,
    ),
    validForCountry: Yup.string().required(
      t('document.requiredFieldValidForCountry', {
        defaultValue: 'Valid for counry must be set',
      }) as string,
    ),
    mappedUserIdentityDocumentId: Yup.string().required(
      t('document.requiredFieldAssociatedDocument', {
        defaultValue: 'Associated document must be set',
      }) as string,
    ),

    dateOfIssue: Yup.string().required(
      t('document.requiredFieldDateOfIssue', {
        defaultValue: 'Date of issue must be set',
      }) as string,
    ),
    expiresOn: Yup.string().required(
      t('document.requiredFieldDateOfIssue', {
        defaultValue: 'Date of expiry must be set',
      }) as string,
    ),
    placeOfIssue: Yup.string().required(
      t('document.requiredFieldPlaceOfIssue', {
        defaultValue: 'Place of issue must be set',
      }) as string,
    ),

    countryOfIssue: Yup.string().required(
      t('document.requiredFieldCountryOfIssue', {
        defaultValue: 'Country of issue must be set',
      }) as string,
    ),
  });

  const initialValues = {
    documentFront: null,
    documentBack: null,
    documentType: 'VISA',
    description: '',
    documentSubType: [],
    countryOfIssue: '',
    multipleEntriesAllowed: false,
    validForCountry: '',
    nationality: '',
    userIdentityDocument: '',
    sex: null,
    countryOfBirth: null,
    mappedUserIdentityDocumentId: '',
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const handleSelectDocument = (side: 'front' | 'back', result: any) => {
    if (side === 'front') {
      formik?.setFieldValue('documentFront', result);
    } else {
      formik?.setFieldValue('documentBack', result);
    }
  };

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);
    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: formik.touched[field] ? fieldError : '',
        onChange,
        status: formik.touched[field] && fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      errorMessage: fieldError || '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const handleBackPageChange = () => {
    setHasOnlyFrontPage((prev) => {
      if (!prev) {
        formik.setFieldValue('documentBack', null);
      }
      return !prev;
    });
    formik.validateField('documentFront');
    formik.validateField('documentBack');
  };

  const handleCheckboxMultiChange = (field: string, value: string) => () => {
    const options = formik.values[field] || [];
    if (options.includes(value)) {
      formik.setFieldValue(
        field,
        options.filter((item: string) => item !== value),
      );
    } else {
      formik.setFieldValue(field, [...options, value]);
    }
  };

  const onSelect =
    ({ options, fieldValueKey, valueKey }: any) =>
    (value: any) => {
      if (valueKey === 'mappedUserIdentityDocumentId') {
        const option = options.find((item: any) => item[fieldValueKey] === value);
        formik.setValues({
          ...formik.values,
          [valueKey]: value,
          firstNames: option.firstNames,
          lastName: option.lastName,
          sex: option.sex,
          nationality: option.nationality,
          placeOfBirth: option.placeOfBirth,
          dateOfBirth: option.dateOfBirth,
          countryOfBirth: option.countryOfBirth,
        });
      } else {
        formik?.setFieldValue(valueKey, value);
      }
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const renderSelectAutocomplete = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormAutocomplete
        appearance="default"
        containerStyle={styles.input}
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey })}
        items={options}
        value={formik.values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={formik?.errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderSelect = ({
    caption,
    disabled = false,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <>
      <FormSelect
        appearance="default"
        containerStyle={styles.input}
        caption={caption}
        disabled={disabled}
        label={label}
        onSelect={onSelect({ options, fieldValueKey, valueKey })}
        items={options}
        value={formik.values[valueKey]}
        fieldTitleKey={fieldTitleKey}
        fieldValueKey={fieldValueKey}
        errorMessage={formik?.errors[valueKey]}
        isMandatory
      />
    </>
  );

  const renderDatePicker = ({
    label,
    min,
    max,
    valueKey,
  }: {
    label: string;
    min: any;
    max: any;
    valueKey: string;
  }) => (
    <FormTextDatepicker
      label={label}
      containerStyle={styles.input}
      min={min}
      max={max}
      date={formik.values[valueKey] && formik.values[valueKey]?.toJSDate()}
      onChange={(value) => {
        formik?.setFieldValue(valueKey, DateTime.fromJSDate(value));
        setTimeout(() => {
          if (formik?.errors[valueKey]) {
            formik.validateField(valueKey);
          }
        }, 100);
      }}
      errorMessage={formik?.errors[valueKey]}
      isMandatory
    />
  );

  const renderProfileData = () => {
    if (!formik.values.mappedUserIdentityDocumentId) {
      return null;
    }
    return (
      <ContentBox
        Content={
          <>
            <Text
              selectable={true}
              style={[
                styles.descriptionText,
                globalStyle.marginVertical10,
                globalStyle.paddingTop10,
              ]}
              category="s1"
            >
              {
                t('document.informationFromAssociatedProfileDocument', {
                  defaultValue:
                    'The information below are from Associated Identity Document that you selected above.',
                }) as string
              }
            </Text>

            <FormTextInputTopLabel
              containerStyle={styles.input}
              disabled
              label={(
                t('document.firstNames', { defaultValue: 'FIRST NAMES' }) as string
              ).toUpperCase()}
              {...fieldProps('firstNames')}
            />
            <FormTextInputTopLabel
              containerStyle={styles.input}
              disabled
              label={(
                t('document.lastName', { defaultValue: 'LAST NAME' }) as string
              ).toUpperCase()}
              {...fieldProps('lastName')}
            />

            {renderSelect({
              disabled: true,
              label: (t('document.sex', { defaultValue: 'Sex' }) as string).toUpperCase(),
              valueKey: 'sex',
              options: SexList,
              fieldTitleKey: 'name',
              fieldValueKey: 'code',
            })}

            {renderSelectAutocomplete({
              disabled: true,
              label: t('document.nationality', { defaultValue: 'NATIONALITY' }) as string,
              valueKey: 'nationality',
              options: CountryListData,
              fieldTitleKey: 'name',
              fieldValueKey: 'code',
            })}

            <FormTextInputTopLabel
              containerStyle={styles.input}
              disabled
              label={(
                t('document.placeOfBirth', { defaultValue: 'PLACE OF BIRTH' }) as string
              ).toUpperCase()}
              {...fieldProps('placeOfBirth')}
            />
            <FormTextDatepicker
              isDisabled
              label={(
                t('document.dateOfBirth', { defaultValue: 'DATE OF BIRTH' }) as string
              ).toUpperCase()}
              containerStyle={styles.input}
              min={minimumBirthDay}
              max={now}
              date={formik.values.dateOfBirth && formik.values.dateOfBirth?.toJSDate()}
              onChange={(value: Date) => {
                formik?.setFieldValue('dateOfBirth', DateTime.fromJSDate(value));
                setTimeout(() => {
                  if (formik?.errors.dateOfBirth) {
                    formik.validateField('dateOfBirth');
                  }
                }, 100);
              }}
            />
          </>
        }
      />
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        accessoryRight={() => (
          <Button onPress={handleSave} appearance="filled" style={styles.saveBtn}>
            {isSaving ? (
              <ActivityIndicator />
            ) : (
              (t('common.save', { defaultValue: 'Save' }) as string)
            )}
          </Button>
        )}
        title={title}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierProfileNavigator', {
            screen: 'CourierUserTravelPermitsScreen',
          });
        }}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={styles.contentContainer}
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
      >
        <View style={globalStyle.padding10}>
          <CourierAddDocumentFile
            allowBackPage
            identityPlaceholder
            documentBack={formik.values.documentBack}
            documentFront={formik.values.documentFront}
            hasBackPage={!hasOnlyFrontPage}
            onBackPageChange={handleBackPageChange}
            onSelectDocument={handleSelectDocument}
          />
        </View>
        <Divider style={globalStyle.marginBottom10} />

        <View style={globalStyle.padding20}>
          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={
              t('document.travelSubType', {
                defaultValue: 'DOCUMENT TYPE DETAILS',
              }) as string
            }
            caption={
              <>
                <Text style={[globalStyle.marginTop5, styles.caption]}>
                  {
                    t('document.specifyTypeOfVisaTravelDocument', {
                      defaultValue: 'Specify the type of visa/travel document',
                    }) as string
                  }
                </Text>
                <Text style={[globalStyle.marginTop0, styles.caption]}>
                  {
                    t('document.specifyTypeOfVisaTravelDocumentTypes', {
                      defaultValue: 'i.e.: B1, B2, eTA, ESTA, etc.',
                    }) as string
                  }
                </Text>
                <Text style={[globalStyle.marginTop0, globalStyle.marginBottom10, styles.caption]}>
                  {
                    t('document.specifyTypeOfVisaTravelDocumentTypesDescription', {
                      defaultValue:
                        'The APEC card and special travel permits are available under Identity Documents',
                    }) as string
                  }
                </Text>
              </>
            }
            isMandatory
            {...fieldProps('description')}
          />
          <View style={globalStyle.size20} />
          <Text style={[globalStyle.marginTop10, styles.label]}>
            {
              t('document.selectSubtypesThatApply', {
                defaultValue: 'Select the subtype/s that apply to your travel document',
              }) as string
            }
            <Text selectable={true} style={styles.mandatoryStar}>
              *
            </Text>
          </Text>
          <View
            style={[
              globalStyle.flexRow,
              globalStyle.alignItemsCenter,
              globalStyle.flexWrap,
              globalStyle.marginTop15,
              globalStyle.marginBottom10,
            ]}
          >
            {TravelDocumentSubTypeOptions.map((item) => (
              <CheckBox
                key={item.value}
                style={[
                  globalStyle.flexGrow1,
                  globalStyle.marginRight20,
                  globalStyle.marginBottom10,
                ]}
                checked={formik.values.documentSubType.includes(item.value)}
                onChange={handleCheckboxMultiChange('documentSubType', item.value)}
              >
                {item.name}
              </CheckBox>
            ))}
          </View>
          {!!formik?.errors.documentSubType && (
            <Text style={styles.errorSubTypeMessageText}>{formik?.errors.documentSubType}</Text>
          )}

          <View style={globalStyle.paddingBottom20}>
            <Text style={[globalStyle.marginTop20, globalStyle.marginBottom20, styles.label]}>
              {
                t('document.selectIfMultipleEntriesAllowed', {
                  defaultValue: 'Select if multiple entries allowed in travel document',
                }) as string
              }
            </Text>
            <CheckBox
              style={globalStyle.marginRight20}
              {...fieldProps('multipleEntriesAllowed', 'checkbox')}
            >
              {
                t('document.multipleEntriesAllowed', {
                  defaultValue: 'Multiple entries allowed',
                }) as string
              }
            </CheckBox>
          </View>

          <Divider style={globalStyle.marginTop20} />

          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={t('document.documentNumber', { defaultValue: 'DOCUMENT NUMBER' }) as string}
            isMandatory
            {...fieldProps('documentNumber')}
          />

          {renderSelectAutocomplete({
            label: t('document.validForCountry', { defaultValue: 'VALID FOR COUNTRY' }) as string,
            valueKey: 'validForCountry',
            options: CountryListData,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}

          {renderSelect({
            label: t('document.associatedIdentityDocument', {
              defaultValue: 'ASSOCIATED IDENTITY DOCUMENT',
            }) as string,
            valueKey: 'mappedUserIdentityDocumentId',
            options: userIdentityDocuments,
            fieldTitleKey: 'name',
            fieldValueKey: 'id',
          })}

          {renderDatePicker({
            label: t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }) as string,
            min: minimumBirthDay,
            max: now,
            valueKey: 'dateOfIssue',
          })}
          {renderDatePicker({
            label: t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }) as string,
            min: now,
            max: maximumBirthDay,
            valueKey: 'expiresOn',
          })}

          {renderSelectAutocomplete({
            label: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }) as string,
            valueKey: 'countryOfIssue',
            options: CountryListData,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}

          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={t('document.placeOfIssue', { defaultValue: 'PLACE OF ISSUE' }) as string}
            isMandatory
            {...fieldProps('placeOfIssue')}
          />
        </View>

        {renderProfileData()}
      </KeyboardAwareScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  scrollView: {},
  contentContainer: {
    paddingVertical: 24,
  },
  descriptionText: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
  },
  input: {
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },

  cardImage: {
    flex: 1,
    flexWrap: 'wrap',
    width: 400,
    height: 300,
    resizeMode: 'contain',
  },
  checkBox: {
    margin: 5,
  },
  saveBtn: {
    height: 44,
    width: 75,
  },
  marginTop20: {
    marginTop: 20,
  },
  caption: {
    fontSize: 12,
    color: 'text-basic-color',
    opacity: 0.5,
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  errorMessageText: {
    color: 'color-danger-500',
    fontSize: 13,
    paddingBottom: 5,
  },
  errorSubTypeMessageText: {
    color: 'color-danger-500',
    fontSize: 13,
    paddingBottom: 5,
    marginTop: -10,
    marginBottom: 10,
  },
});
