import React, { forwardRef } from 'react';
import { Keyboard, Platform, TouchableOpacity, View } from 'react-native';

import { Button, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import { FormDatepicker } from '../../../../components/common/form/form-datepicker.component';
import { FormTimeSelect } from '../../../../components/common/form/FormTimeSelect/form-time-select.component';
import { ConfirmModal } from '../../../../components/common/modal/ConfirmModal';

export const PlanningDateTimePickerModal = forwardRef(
  ({ handleCancel, handleConfirm, handleConfirmAndNext, selectedItem, visible }: any, ref: any) => {
    const { t } = useTranslation();
    const globalStyle = useStyleSheet(globalStyleCommon);
    const styles = useStyleSheet(themedStyles);

    const {
      dataChangedConfirmationModalVisible,
      date,
      time,
      errorDate,
      errorTime,
      handleClose,
      handleCancelDataChangedPress,
      handleConfirmDataChangedPress,
      handleDateChange,
      handleTimeChange,
      handleSave,
      handleSaveAndNextStep,
    } = useHook({ handleCancel, handleConfirm, handleConfirmAndNext, ref });

    let containerStyle = [globalStyle.flex0, { minWidth: 163 }, globalStyle.marginTop10];
    if (Platform.OS === 'android') {
      containerStyle = [{ minWidth: 90 }, globalStyle.marginTop5];
    } else if (Platform.OS === 'ios') {
      containerStyle = [globalStyle.marginTop5];
    }
    return (
      <Modal
        animationIn="fadeIn"
        animationOut="fadeOut"
        avoidKeyboard
        isVisible={visible}
        backdropOpacity={0.8}
        useNativeDriver
      >
        <View style={styles.wrapper}>
          <TouchableOpacity onPress={Keyboard.dismiss} />
          <View style={styles.inner}>
            <Text category="h6" style={styles.title}>
              {selectedItem?.label}
            </Text>

            <View
              style={[
                globalStyle.width100Percent,
                globalStyle.flexRow,
                globalStyle.alignItemsCenter,
                globalStyle.marginBottom30,
                Platform.OS !== 'web' && globalStyle.paddingBottom10,
              ]}
            >
              <FormDatepicker
                containerStyle={[globalStyle.flex1, globalStyle.marginTop10] as any}
                date={date}
                error={!!errorDate}
                errorMessage={errorDate}
                isMandatory
                onSelect={handleDateChange}
              />
              <FormTimeSelect
                containerStyle={containerStyle as any}
                error={!!errorTime}
                errorMessage={errorTime}
                isMandatory
                onChange={handleTimeChange}
                value={time}
              />
            </View>

            <View style={styles.buttonsContainer}>
              <Button
                appearance="filled"
                status="primary"
                size="medium"
                onPress={handleSave}
                style={globalStyle.marginBottom10}
              >
                {t('common.save', { defaultValue: 'Save' })}
              </Button>
              <Button
                appearance="filled"
                status="primary"
                size="medium"
                onPress={handleSaveAndNextStep}
                style={globalStyle.marginBottom10}
              >
                {t('common.saveAndContinue', { defaultValue: 'Save & continue' })}
              </Button>
            </View>

            <TouchableOpacity
              style={styles.closeButton}
              onPress={handleClose}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <Icon
                fill={(globalStyle.colorTextBasicColor as any).color}
                name="close"
                style={globalStyle.size30}
              />
            </TouchableOpacity>
          </View>
        </View>

        {dataChangedConfirmationModalVisible && (
          <ConfirmModal
            confirmButtonStatus="danger"
            confirmButtonAppearance="filled"
            confirmButtonText={t('common.saveAndClose', {
              defaultValue: 'Save and close',
            })}
            cancelButtonText={t('common.close', { defaultValue: 'Close' })}
            title={t('common.unsavedChanges', { defaultValue: 'Unsaved changes' })}
            text={t('common.youHaveUnsavedChanges', {
              defaultValue: 'You have unsaved changes. Do you want to save them first?',
            })}
            handleCancel={handleCancelDataChangedPress}
            handleConfirm={handleConfirmDataChangedPress}
            visible={dataChangedConfirmationModalVisible}
          />
        )}
      </Modal>
    );
  },
);
