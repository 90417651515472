import React, { useContext } from 'react';
import { Platform } from 'react-native';

import { NavigationProp } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import { CommonCourierAccountSettingsMobile } from './CommonCourierAccountSettingsMobile';
import { CommonCourierAccountSettingsWeb } from './CommonCourierAccountSettingsWeb';
import { TopNavigationBackToProfileButton } from '../../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { RootStackParamList } from '../../../navigation/app.navigator';

export const CommonCourierAccountSettingsScreen = ({
  navigation,
}: {
  navigation: NavigationProp<RootStackParamList>;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  return (
    <>
      <TopNavigationBackToProfileButton
        title={t('menuItems.courierAccountSettings', { defaultValue: 'Courier Account Settings' })}
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />

      {Platform.OS === 'web' ? (
        <CommonCourierAccountSettingsWeb />
      ) : (
        <CommonCourierAccountSettingsMobile />
      )}
    </>
  );
};
