import React from 'react';
import { RefreshControl, ScrollView, View } from 'react-native';

import { useQuery } from '@apollo/client';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core';
import { StackNavigationProp } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';

import { ORGANIZATION_PROFILE_QUERY } from '../../../apollo/graphql-queries';
import { globalStyle } from '../../../common/style';
import { AgencyProfileReadyOnlyComponent } from '../../../components/common/AgencyProfileReadOnlyComponent';
import { TranslatedErrorText } from '../../../components/common/error/translated-error-text.component';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { Organization } from '../../../generated-graphql-types';
import { OrganizationProfile, OrganizationProfileVariables } from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { RootStackParamList } from '../../../navigation/app.navigator';

export const AgencyAgencyProfileReadOnlyScreen = (): React.ReactElement => {
  const appUserContext = React.useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const navigation =
    useNavigation<StackNavigationProp<RootStackParamList, 'AgencyAgencyProfileReadOnlyScreen'>>();
  const route = useRoute<RouteProp<RootStackParamList, 'AgencyAgencyProfileReadOnlyScreen'>>();
  const { t } = useTranslation();

  const [refreshing, setRefreshing] = React.useState(false);

  const { data, error, loading, refetch } = useQuery<
    OrganizationProfile,
    OrganizationProfileVariables
  >(ORGANIZATION_PROFILE_QUERY, {
    variables: { id: appUserContext.currentUserContext?.organization?.id as string },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const renderRefreshControlComponent = () => (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
        setRefreshing(false);
      }}
    />
  );

  const renderContent = () => {
    if (error) {
      return (
        <View style={globalStyle.padding20}>
          <TranslatedErrorText
            apolloError={error}
            variables={{ agencyOrganizationId: route.params.agencyOrganizationId }}
          />
        </View>
      );
    }
    if (loading) {
      return <LoadingSpinner />;
    }
    if (data?.organization) {
      return <AgencyProfileReadyOnlyComponent organization={data?.organization as Organization} />;
    }
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common.agencyProfile', { defaultValue: 'Agency Profile' })}
        navigation={navigation}
        onPressLeft={() => {
          const { previousScreenName } = route.params || {};
          navigation.navigate('AgencyProfileNavigator', {
            screen: previousScreenName || 'AgencyProfileScreen',
          } as any);
        }}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        refreshControl={renderRefreshControlComponent()}
      >
        {renderContent()}
      </ScrollView>
    </>
  );
};
