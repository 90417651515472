import React from 'react';

import { Button } from '@ui-kitten/components';

import { createApolloClientNoPersist } from '../apollo/create-apollo-client-no-persist';
import {
  OrganizationType,
  OrganizationUser,
  useRegisterExistingUserWithOwnedOrganizationMutation,
} from '../generated-graphql-types';
import { AuthenticationService } from '../services/authentication.service';
import { LocalUserContext, LocalUserContexts } from '../types';

const RegisterExistingUserWithOwnedOrganizationButton = ({
  userContext,
  userContexts: localUserContexts,
  setUserContexts,
  organizationType,
}: {
  userContext: LocalUserContext;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  organizationType: OrganizationType;
}) => {
  const apolloClient = createApolloClientNoPersist({ userContext: userContext });

  const [registerExistingUserWithOwnedOrganizationMutation] =
    useRegisterExistingUserWithOwnedOrganizationMutation({
      client: apolloClient,
      fetchPolicy: 'network-only',
    });

  const _handleRegisterExistingUserWithOwnedOrganizationButtonPress = async () => {
    const result = await registerExistingUserWithOwnedOrganizationMutation({
      variables: { organizationType: organizationType },
    });
    // No need to merge here, a full UserContext is fetched after registering anyways using getUpdatedUserContexts
    if (result?.data?.registerExistingUserWithOwnedOrganization) {
      const authenticationService = new AuthenticationService();
      const newLocalUserContexts =
        await authenticationService.getUpdatedLocalUserContextsFromMergedLocalUserContextWithOrganizationUserAsync(
          {
            localUserContexts: localUserContexts,
            localUserContext: userContext,
            organizationUser: result.data
              ?.registerExistingUserWithOwnedOrganization as Partial<OrganizationUser>,
          },
        );
      setUserContexts(newLocalUserContexts);
    }
  };

  return (
    <Button
      appearance="ghost"
      size="small"
      onPress={_handleRegisterExistingUserWithOwnedOrganizationButtonPress}
    >
      {`Create ${OrganizationType[organizationType]} Account`}
    </Button>
  );
};

export default RegisterExistingUserWithOwnedOrganizationButton;
