import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { NavigationProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { StyleService, Tab, TabBar, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { COURIER_JOBS_FOR_COURIER_ORGANIZATION_QUERY } from '../../apollo/graphql-queries';
import { LoadingSpinner } from '../../components/common/loading-spinner.component';
import { CourierCourierJobsList } from '../../components/courier/courier.courier-jobs-list.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import { CourierJob } from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { JOB_STATUS_FOR_COURIERS, getJobStatusForCourierJob } from '../../modules/job/job.module';
import { RootStackParamList } from '../../navigation/app.navigator';

interface CourierJobsType {
  courierJobs: Partial<CourierJob>[];
  courierJobsNew: Partial<CourierJob>[];
  courierJobsRunning: Partial<CourierJob>[];
  courierJobsPast: Partial<CourierJob>[];
  courierJobsDeclined: Partial<CourierJob>[];
}

const { Navigator, Screen } = createMaterialTopTabNavigator();

export const CourierCourierJobsScreen = (): React.ReactElement => {
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();

  const [courierOrganizationId, setCourierOrganizationId] = useState<string>(
    appUserContext.currentUserContext?.organization?.id || '',
  );

  const { loading, refetch, data } = useQuery<{ courierJobsForCourierOrganization: CourierJob[] }>(
    COURIER_JOBS_FOR_COURIER_ORGANIZATION_QUERY,
    {
      variables: { courierOrganizationId },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
      pollInterval: isFocused && isBackendReachable ? 1000 * 30 : 0,
      skip: !isFocused,
    },
  );

  useEffect(() => {
    if (
      appUserContext.currentUserContext?.organization?.id &&
      courierOrganizationId !== appUserContext.currentUserContext?.organization?.id
    ) {
      setCourierOrganizationId(appUserContext.currentUserContext?.organization?.id);
    }
  }, [appUserContext.currentUserContext?.organization?.id, courierOrganizationId]);

  const {
    courierJobs,
    courierJobsNew,
    courierJobsRunning,
    courierJobsPast,
    courierJobsDeclined,
  }: CourierJobsType = useMemo(() => {
    if (data?.courierJobsForCourierOrganization?.length) {
      return data?.courierJobsForCourierOrganization?.reduce(
        (acc, current) => {
          const courierJob = current as Partial<CourierJob>;
          const jobStatus = getJobStatusForCourierJob(courierJob);
          if (jobStatus === JOB_STATUS_FOR_COURIERS.NEW) {
            acc.courierJobsNew.push(courierJob);
          } else if (jobStatus === JOB_STATUS_FOR_COURIERS.RUNNING) {
            acc.courierJobsRunning.push(courierJob);
          } else if (jobStatus === JOB_STATUS_FOR_COURIERS.PAST) {
            acc.courierJobsPast.push(courierJob);
          } else if (jobStatus === JOB_STATUS_FOR_COURIERS.DECLINED) {
            acc.courierJobsDeclined.push(courierJob);
          }
          return acc;
        },
        {
          courierJobs: data?.courierJobsForCourierOrganization as Partial<CourierJob>[],
          courierJobsNew: [],
          courierJobsRunning: [],
          courierJobsPast: [],
          courierJobsDeclined: [],
        } as CourierJobsType,
      );
    }
    return {
      courierJobs: [],
      courierJobsNew: [],
      courierJobsRunning: [],
      courierJobsPast: [],
      courierJobsDeclined: [],
    } as CourierJobsType;
  }, [data?.courierJobsForCourierOrganization]);

  return (
    <CourierJobs
      courierJobs={courierJobs}
      courierJobsNew={courierJobsNew}
      courierJobsRunning={courierJobsRunning}
      courierJobsPast={courierJobsPast}
      courierJobsDeclined={courierJobsDeclined}
      isLoading={loading}
      refetch={refetch}
    />
  );
};

const TopTabBar = ({
  courierJobsNewCount = 0,
  courierJobsRunningCount = 0,
  courierJobsPastCount = 0,
  courierJobsDeclinedCount = 0,
  navigation,
  state,
}: {
  courierJobsNewCount: number;
  courierJobsRunningCount: number;
  courierJobsPastCount: number;
  courierJobsDeclinedCount: number;
  navigation: NavigationProp<RootStackParamList>;
  state: any;
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => navigation.navigate(state.routeNames[index])}
      style={styles.height50}
    >
      <Tab title={`NEW \n(${courierJobsNewCount})`} key="CourierCourierJobsNewScreen" />
      <Tab title={`RUNNING \n(${courierJobsRunningCount})`} key="CourierCourierJobsRunningScreen" />
      <Tab title={`PAST \n(${courierJobsPastCount})`} key="CourierCourierJobsPastScreen" />
      <Tab
        title={`DECLINED \n(${courierJobsDeclinedCount})`}
        key="CourierCourierJobDeclinedScreen"
      />
    </TabBar>
  );
};

const CourierJobs = ({
  isLoading,
  refetch,
  courierJobs,
  courierJobsNew,
  courierJobsRunning,
  courierJobsPast,
  courierJobsDeclined,
}: CourierJobsType & { isLoading: boolean; refetch: any }) => {
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const route: any = useRoute();

  const renderTopBar = (props: any) => (
    <TopTabBar
      {...props}
      courierJobsNewCount={courierJobsNew.length}
      courierJobsRunningCount={courierJobsRunning.length}
      courierJobsPastCount={courierJobsPast.length}
      courierJobsDeclinedCount={courierJobsDeclined.length}
    />
  );

  const renderContent = () => {
    if (isLoading && !courierJobs.length) {
      return <LoadingSpinner subject="Agency Jobs" />;
    }
    return (
      <Navigator
        tabBar={renderTopBar}
        initialRouteName={route.params?.previousTabScreenName || 'CourierCourierJobsNewScreen'}
      >
        <Screen name="CourierCourierJobsNewScreen">
          {(props: any) => (
            <CourierCourierJobsList
              {...props}
              isLoading={isLoading}
              refetch={refetch}
              courierJobs={courierJobsNew}
              screenName="CourierCourierJobsNewScreen"
            />
          )}
        </Screen>
        <Screen name="CourierCourierJobsRunningScreen">
          {(props: any) => (
            <CourierCourierJobsList
              {...props}
              isLoading={isLoading}
              refetch={refetch}
              courierJobs={courierJobsRunning}
              screenName="CourierCourierJobsRunningScreen"
            />
          )}
        </Screen>
        <Screen name="CourierCourierJobsPastScreen">
          {(props: any) => (
            <CourierCourierJobsList
              {...props}
              isLoading={isLoading}
              refetch={refetch}
              courierJobs={courierJobsPast}
              screenName="CourierCourierJobsPastScreen"
            />
          )}
        </Screen>
        <Screen name="CourierCourierJobsDeclinedScreen">
          {(props: any) => (
            <CourierCourierJobsList
              {...props}
              isLoading={isLoading}
              refetch={refetch}
              courierJobs={courierJobsDeclined}
              screenName="CourierCourierJobsDeclinedScreen"
            />
          )}
        </Screen>
      </Navigator>
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common.jobs', {
          defaultValue: 'Jobs',
        })}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierHomeScreen');
        }}
      />

      {renderContent()}
    </>
  );
};

const themedStyles = StyleService.create({
  height50: {
    height: 50,
  },
});
