import Constants from 'expo-constants';

import { AddressGeometry } from '../types/google-maps.common.types';
import { getGoogleMapsApiKey } from '../utils/google';

/** For the documentation see https://developers.google.com/maps/documentation/timezone/overview#maps_http_timezone-js */
export type TimeZoneResponse = {
  status:
    | 'OK'
    | 'INVALID_REQUEST'
    | 'OVER_DAILY_LIMIT'
    | 'OVER_QUERY_LIMIT'
    | 'REQUEST_DENIED'
    | 'UNKNOWN_ERROR'
    | 'ZERO_RESULTS';
  dstOffset: number;
  rawOffset: number;
  timeZoneId: string;
  timeZoneName: string;
};

export const resolveTimeZoneForLocation = async (
  geometry: AddressGeometry,
  timestamp: number, // seconds since midnight, January 1, 1970 UTC.
): Promise<TimeZoneResponse | null> => {
  const url =
    Constants.expoConfig?.extra?.priojetGoogleMapsProxyUrl +
    `/timezone/json?location=${geometry.location.lat},${
      geometry.location.lng
    }&timestamp=${timestamp}&key=${getGoogleMapsApiKey('web')}`;

  const response = await fetch(url);

  if (response) {
    const jsonResponse: TimeZoneResponse = await response.json();
    if (jsonResponse) {
      return jsonResponse;
    }
  }
  return null;
};
