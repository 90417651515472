import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { StyleService, Tab, TabBar, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import {
  EMPLOYEE_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY,
  ORGANIZATION_ORGANIZATION_USERS_QUERY,
} from '../../../apollo/graphql-queries';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { AgencyEmployeeInvitationOpenInvitationsScreen } from '../AgencyOrganizationUserInvitationOpenInvitationsScreen';
import { AgencyOrganizationUserInvitationPhoneContactsScreen } from '../AgencyOrganizationUserInvitationPhoneContactsScreen';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export const AgencyAgencyUserInvitationsScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();

  const appUserContext = React.useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const {
    loading,
    data: dataOrganizationInvitation,
    refetch,
  } = useQuery(EMPLOYEE_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY, {
    variables: {
      agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
      userId: appUserContext.currentUserContext?.user?.id as string,
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });
  const { data } = useQuery(ORGANIZATION_ORGANIZATION_USERS_QUERY, {
    variables: { id: appUserContext.currentUserContext?.organization?.id as string },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const currentOrganizationUserCount = useMemo(() => {
    let count = 0;
    if (dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization?.length) {
      count += dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization?.length;
    }
    if (data?.organization?.organizationUsers?.length) {
      count += data?.organization?.organizationUsers?.length;
    }
    return count;
  }, [dataOrganizationInvitation, data]);

  const handleBackPress = () => {
    navigation.navigate('AgencyProfileNavigator', {
      screen: 'AgencyManageAgencyUsersScreen',
      params: {
        previousScreenName: 'AgencyProfileScreen',
        previousNavigator: 'AgencyProfileNavigator',
      },
    });
  };

  const renderTobBar = ({
    navigation: tabNavigation,
    state: tabNavigationState,
  }: {
    navigation: any;
    state: any;
  }) => (
    <TabBar
      selectedIndex={tabNavigationState.index}
      onSelect={(index) => tabNavigation.navigate(tabNavigationState.routeNames[index])}
      style={styles.height50}
    >
      <Tab title={t('common:contacts', { defaultValue: 'Contacts' }).toUpperCase()} />
      <Tab
        title={t('common:employeeInvitations', {
          defaultValue: 'Employee Invitations',
        }).toUpperCase()}
      />
    </TabBar>
  );

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common:inviteAgencyEmployee', { defaultValue: 'Invite Agency Employee' })}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />
      <Navigator tabBar={renderTobBar}>
        <Screen name="AgencyEmployeePhoneBookContactsScreen">
          {() => (
            <AgencyOrganizationUserInvitationPhoneContactsScreen
              currentOrganizationUserCount={currentOrganizationUserCount}
              isLoading={loading}
              organizationInvitations={
                dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization
              }
              refetch={refetch}
            />
          )}
        </Screen>
        <Screen name="AgencyEmployeeInvitationsScreen">
          {() => (
            <AgencyEmployeeInvitationOpenInvitationsScreen
              isLoading={loading}
              organizationInvitations={
                dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization
              }
              refetch={refetch}
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};

const themedStyles = StyleService.create({
  height50: {
    height: 50,
  },
});
