import React from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { TrackAndTracing } from './ChatTrackAndTracing';
import { useHook } from './hook';
import { globalStyle } from '../../../common/style';
import Chat from '../../../components/common/Chat';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { ChatType } from '../../../generated-graphql-types';

export const CommonAgencyJobChatScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const {
    agencyJobId,
    chatId,
    chatRef,
    disableInput,
    disableInputText,
    errorChat,
    handleGetLatestChatMessages,
    handleTitlePress,
    headerHeight,
    loading,
    onHeaderLayout,
    onPressLeft,
    subtitle,
    title,
  } = useHook();

  const renderForbidden = () => {
    return (
      <View style={[globalStyle.padding20]}>
        <Text style={globalStyle.textAlignCenter}>
          {t('youDoNotHavePermissionToAccessThisChat', {
            defaultValue: 'You do not have permission to access this chat.',
          })}
        </Text>
      </View>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <View style={[globalStyle.flex1, globalStyle.height300]}>
          <LoadingSpinner subject="" />
        </View>
      );
    }
    if (errorChat === 'FORBIDDEN') {
      return renderForbidden();
    }
    return (
      <>
        <Chat
          ref={chatRef}
          chatId={chatId}
          chatType={ChatType.AGENCY_JOB_CHAT}
          disableInput={disableInput}
          disableInputMessage={disableInputText}
          listViewProps={{ contentContainerStyle: { paddingBottom: 140 } }}
        />
        <TrackAndTracing
          agencyJobId={agencyJobId}
          headerHeight={headerHeight}
          getLatestChatMessages={handleGetLatestChatMessages}
          title={title}
          subtitle={subtitle}
        />
      </>
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={title}
        subtitle={subtitle}
        navigation={navigation}
        onLayout={onHeaderLayout}
        onPressLeft={onPressLeft}
        onTitlePress={handleTitlePress}
        onSubtitlePress={handleTitlePress}
      />
      {renderContent()}
    </>
  );
};
