import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputContainer: {
    width: '100%',
    marginBottom: 20,
  },
  background: {},
  inner: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: 'background-basic-color-1',
    padding: 20,
    paddingTop: 14,
    borderRadius: 6,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 15,
    height: 34,
    width: 34,
    borderRadius: 17,
    marginTop: -3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '',
  },
  closeIcon: {
    color: 'text-basic-color',
  },
  title: {
    marginTop: 5,
    marginBottom: 20,
    paddingRight: 30,
    paddingLeft: 30,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
  },
  subtitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
    marginBottom: 30,
  },
  errorText: {
    color: 'color-danger-600',
    marginBottom: 20,
  },
  loading: {
    color: 'white',
  },
  buttonsContainer: {
    width: '100%',
  },
});
