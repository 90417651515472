import React from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Text, Button, StyleService, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { globalStyle } from '../../../common/style';
import Chat from '../../../components/common/Chat';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { ChatType } from '../../../generated-graphql-types';

export const CommonJobAgencyJobChatScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const {
    agencyButtonEnabled,
    agencyJobStatusStarted,
    chatRef,
    courierButtonEnabled,
    chatId,
    disableInput,
    disableInputText,
    errorChat,
    handleJobChatAndTrackingPress,
    handleTitlePress,
    loading,
    onPressLeft,
    title,
    startedByAgencyAt,
    subtitle,
  } = useHook();

  const renderForbidden = () => {
    return (
      <View style={[globalStyle.padding20]}>
        <Text style={globalStyle.textAlignCenter}>
          {t('youDoNotHavePermissionToAccessThisChat', {
            defaultValue: 'You do not have permission to access this chat.',
          })}
        </Text>
      </View>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <View style={[globalStyle.flex1, globalStyle.height300]}>
          <LoadingSpinner subject="" />
        </View>
      );
    }
    if (errorChat === 'FORBIDDEN') {
      return renderForbidden();
    }
    return (
      <View style={globalStyle.flex1}>
        <Chat
          ref={chatRef}
          chatId={chatId}
          chatType={ChatType.COURIER_JOB_AGENCY_JOB_CHAT}
          disableInput={disableInput}
          disableInputMessage={disableInputText}
        />

        {agencyJobStatusStarted && (
          <View style={styles.jobStartedWarning}>
            <View style={styles.jobStartedWarningContainer}>
              <Text
                style={[
                  globalStyle.width100Percent,
                  globalStyle.textAlignRight,
                  globalStyle.paddingRight15,
                  globalStyle.paddingTop5,
                  globalStyle.fontSize12,
                ]}
              >
                {startedByAgencyAt}
              </Text>

              <Text style={globalStyle.fontItalic}>
                {t('chat.theJobHasBeenStartedByTheAgency', {
                  defaultValue: 'The job has been started by the agency.',
                })}
              </Text>
              {agencyButtonEnabled || courierButtonEnabled ? (
                <Text style={[globalStyle.fontItalic, globalStyle.marginBottom5]}>
                  {t('chat.theJobAndTrackerIsNowAvailable', {
                    defaultValue: 'The job and tracker is now available.',
                  })}
                </Text>
              ) : (
                <Text style={[globalStyle.fontItalic, globalStyle.marginBottom5]}>
                  {t('chat.theJobAndTrackerIsAvailableForConfirmedOBCs', {
                    defaultValue: 'The job chat and tracker is available to confirmed OBCs.',
                  })}
                </Text>
              )}
              {(agencyButtonEnabled || courierButtonEnabled) && (
                <Button
                  appearance="filled"
                  onPress={handleJobChatAndTrackingPress}
                  status="success"
                  size="tiny"
                  style={globalStyle.marginLeft5}
                >
                  <Text>
                    {t('chat.jobChatAndTracking', {
                      defaultValue: 'Job chat & tracking',
                    }).toUpperCase()}
                  </Text>
                </Button>
              )}
            </View>
          </View>
        )}
      </View>
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={title}
        subtitle={subtitle}
        navigation={navigation}
        onPressLeft={onPressLeft}
        onTitlePress={handleTitlePress}
        onSubtitlePress={handleTitlePress}
      />
      {renderContent()}
    </>
  );
};

const themedStyles = StyleService.create({
  jobStartedWarning: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: 'background-basic-color-1',
  },
  jobStartedWarningContainer: {
    marginHorizontal: 10,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15,
    backgroundColor: 'background-basic-color-1',
    borderBottomWidth: 1,
    borderBottomColor: 'border-basic-color-4',
  },
});
