import React from 'react';
import { View } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import {
  OrganizationInvitation,
  OrganizationInvitationType,
  OrganizationType,
} from '../../../../generated-graphql-types';
import { EmailTextWithContextMenu } from '../../common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../common.phone-text-with-context-menu';

export const CourierOrganizationInvitationCardDescription = ({
  organizationInvitation,
}: {
  organizationInvitation: Partial<OrganizationInvitation>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  if (
    (organizationInvitation.organizationInvitationType ===
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE ||
      organizationInvitation.organizationInvitationType ===
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION ||
      organizationInvitation.organizationInvitationType ===
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION) &&
    organizationInvitation.roleType
  ) {
    return (
      <View>
        <View style={styles.organizationInvitationTypeBadgeView}>
          <Text style={styles.organizationInvitationTypeBadge} selectable={true}>
            {organizationInvitation.organizationInvitationType as string}
          </Text>
        </View>
        <Text selectable={true}>
          {
            t('agencyCourierOpenInvitations.orgInvitationDescription_canBeUsedToJoin', {
              defaultValue: 'Can be used to join the ',
            }) as string
          }
          <Text selectable={true} style={styles.bold}>
            {organizationInvitation?.invitedOrganizationType as string}
          </Text>
          -Organization{' '}
          <Text selectable={true} style={styles.bold}>
            {organizationInvitation.invitedOrganization?.legalName ||
              organizationInvitation.invitedOrganization?.name ||
              'NO NAME GIVEN'}
          </Text>{' '}
          {
            t('agencyCourierOpenInvitations.orgInvitationDescription_withRole', {
              defaultValue: 'with Role ',
            }) as string
          }
          <Text selectable={true} style={styles.bold}>
            {organizationInvitation.roleType}
          </Text>
          .
        </Text>
      </View>
    );
  }
  if (
    organizationInvitation.organizationInvitationType ===
    OrganizationInvitationType.CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION
  ) {
    return (
      <View>
        <View>
          <Text>
            {organizationInvitation?.organizationContact?.firstNames as string}{' '}
            {organizationInvitation?.organizationContact?.lastName as string}{' '}
            {organizationInvitation?.organizationContact?.email as string}{' '}
            {organizationInvitation?.organizationContact?.phone as string}
          </Text>
        </View>
        <View>
          <Text selectable={true}>
            {
              t('agencyCourierOpenInvitations.orgInvitationDescription_canBeUsedToConnect', {
                defaultValue: 'Can be used to connect with a ',
              }) as string
            }
            <Text selectable={true} style={styles.bold}>
              {organizationInvitation.invitedOrganizationType as string}
              {
                t('agencyCourierOpenInvitations.orgInvitationDescription_canBeUsedToConnect', {
                  defaultValue: 'Can be used to connect with a ',
                }) as string
              }
            </Text>
            .
          </Text>
        </View>
      </View>
    );
  }
  if (
    organizationInvitation.organizationInvitationType ===
    OrganizationInvitationType.ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION
  ) {
    return (
      <View>
        <View>
          <Text selectable={true} style={[styles.bold, styles.paddingTop5]}>
            {
              t('agencyCourierOpenInvitations.contactDetails', {
                defaultValue: 'Contact Details',
              }) as string
            }
            :
          </Text>
        </View>
        <View>
          {!!organizationInvitation?.organizationContact?.email && (
            <EmailTextWithContextMenu
              email={organizationInvitation.organizationContact.email}
              textStyle={styles.fontSize14}
            />
          )}
          {!!organizationInvitation?.organizationContact?.phone && (
            <PhoneTextWithContextMenu
              phone={organizationInvitation.organizationContact.phone}
              textStyle={styles.fontSize14}
            />
          )}
          <Text selectable={true} style={[styles.bold, styles.paddingTop5]}>
            {
              t('agencyCourierOpenInvitations.description', {
                defaultValue: 'Description',
              }) as string
            }
          </Text>
          <Text selectable={true} style={styles.fontSize13}>
            {
              t(
                'agencyCourierOpenInvitations.orgInvitationDescription_activationCodeCanBeUsedToActivateANew',
                { defaultValue: 'Activation code can be used to activate a new ' },
              ) as string
            }
            <Text selectable={true} style={[styles.fontSize13, styles.bold]}>
              {organizationInvitation.invitedOrganizationType as string}
            </Text>
            {
              t(
                'agencyCourierOpenInvitations.orgInvitationDescription_onThePlatformAndConnectToIt',
                { defaultValue: ' on the platform and connect to it.' },
              ) as string
            }
          </Text>
        </View>
      </View>
    );
  }
  if (
    organizationInvitation.organizationInvitationType !== undefined &&
    organizationInvitation.invitedOrganizationType === OrganizationType.COURIER &&
    organizationInvitation.invitingOrganization
  ) {
    let result = (
      <Text selectable={true}>
        {
          t(
            'agencyCourierOpenInvitations.orgInvitationDescription_theActivationCodeWasSentToAPhoneBook',
            {
              defaultValue:
                'The activation code was sent to a phone book contact. \nThis can be used to activate an Organization while connecting to ',
            },
          ) as string
        }
        {organizationInvitation.invitingOrganization.name} (
        {organizationInvitation.invitingOrganization.organizationType})
        {organizationInvitation.organizationContact?.id || ''}
      </Text>
    );

    if (organizationInvitation.organizationContact) {
      result = (
        <View>
          {result}
          <Text selectable={true}>
            by {organizationInvitation.organizationContact.firstNames}{' '}
            {organizationInvitation.organizationContact.lastName} (
            {organizationInvitation.organizationContact.phone} /{' '}
            {organizationInvitation.organizationContact.email || 'no email'})
          </Text>
        </View>
      );
    }
    return result;
  }
  return (
    <View>
      <Text selectable={true}>
        {
          t('agencyCourierOpenInvitations.other', {
            defaultValue: 'Other...',
          }) as string
        }
      </Text>
    </View>
  );
};

const themedStyles = StyleService.create({
  bold: {
    fontFamily: 'Lato_700Bold',
  },
  paddingTop5: {
    paddingTop: 5,
  },
  organizationInvitationTypeBadgeView: {
    marginVertical: 10,
    alignSelf: 'center',
  },
  organizationInvitationTypeBadge: {
    borderRadius: 3,
    alignContent: 'center',
    padding: 3,
    backgroundColor: 'gray',
    borderColor: 'lightgray',
    borderWidth: 1,
    fontSize: 10,
    margin: 10,
  },
  fontSize13: {
    fontSize: 13,
  },
  fontSize14: {
    fontSize: 14,
  },
});
