import React, { Fragment } from 'react';
import { ActivityIndicator, Pressable, ScrollView, View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import { Button, CheckBox, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { PlanningDateTimePickerModal } from './PlanningDateTimePickerModal';
import { PlanningLegModal } from './PlanningLegModal';
import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { AgencyJobPlanningLeg } from '../../../generated-graphql-types';
import useDimensions from '../../../hooks/useDimensions';

export const AgencyAgencyJobPlanningScreen = (): React.ReactElement => {
  const navigation: any = useNavigation();
  const globalStyle = useStyleSheet(globalStyleCommon);
  const styles = useStyleSheet(themedStyles);
  const { isSmallDevice } = useDimensions();
  const { t } = useTranslation();

  const {
    dateTimePickerModalRef,
    legModalRef,
    currentItem,
    handleAddAnotherLegPress,
    handleBackPress,
    handleChangeFieldValue,
    handleLegItemPress,
    handleLegMenuIconPress,
    handleMenuIconPress,
    handleLegModalCancel,
    handleLegModalConfirm,
    handleDateModalCancel,
    handleDateModalConfirm,
    handleDateModalAndNextConfirm,
    handleRemoveLegItem,
    handleSave,
    handleSelectAllPress,
    isLoading,
    isSaving,
    state,
    subtitle,
    title,
    visiblePlanningLegModal,
    visiblePlanningDateTimePicker,
  } = useHook();

  const renderItem = ({
    item,
    currentIndex,
    currentLegIndex,
    currentLegItemIndex,
  }: {
    item: any;
    currentIndex?: number;
    currentLegIndex?: number;
    currentLegItemIndex?: number;
  }) => {
    const isLegItem = typeof currentLegItemIndex === 'number';
    const isLegAndCheckboxDisabled = typeof currentLegIndex === 'number' && !isLegItem;
    let targetDateTime: any = item.targetDateTime;
    if (typeof targetDateTime === 'string') {
      targetDateTime = DateTime.fromJSDate(new Date(targetDateTime)).toUTC();
    } else if (targetDateTime?.toJSDate) {
      targetDateTime = DateTime.fromJSDate(targetDateTime.toJSDate()).toUTC();
    }
    return (
      <View
        key={item.id}
        style={[
          globalStyle.flexRow,
          globalStyle.justifyContentBetween,
          globalStyle.alignItemsCenter,
          globalStyle.padding15,
          globalStyle.marginBottom2,
          globalStyle.backgroundColorBasic2,
          globalStyle.borderRadius8,
          isLegItem && globalStyle.marginLeft20,
        ]}
      >
        <Pressable
          style={globalStyle.flexRow}
          onPress={handleChangeFieldValue({ currentIndex, currentLegIndex, currentLegItemIndex })}
        >
          <CheckBox
            checked={item.active}
            style={globalStyle.size20}
            onChange={
              !isLegAndCheckboxDisabled
                ? handleChangeFieldValue({ currentIndex, currentLegIndex, currentLegItemIndex })
                : undefined
            }
          />
          <View>
            <Text
              style={[globalStyle.fontSize14, globalStyle.fontLatoBold, globalStyle.paddingLeft10]}
            >
              {item.label}
            </Text>
            {!!targetDateTime && (
              <View
                style={[
                  globalStyle.marginLeft5,
                  globalStyle.marginTop5,
                  globalStyle.padding5,
                  globalStyle.paddingHorizontal10,
                  globalStyle.backgroundColorBasic4,
                ]}
              >
                <Text style={globalStyle.fontSize13}>
                  {t('common.targetTime', { defaultValue: 'Target time' })}:{' '}
                  {`${item.targetDateTime?.toLocaleString({ weekday: 'short' })}, ${item.targetDateTime?.toFormat('dd MMM yyyy')}`}{' '}
                  {t('common.at', { defaultValue: 'at' })} {targetDateTime?.toFormat('HH:mm')} LT
                </Text>
              </View>
            )}
          </View>
        </Pressable>

        <Pressable
          hitSlop={{ top: 5, bottom: 5, left: 10, right: 10 }}
          onPress={
            isLegAndCheckboxDisabled
              ? handleLegMenuIconPress({
                  item,
                  currentIndex,
                  currentLegIndex,
                  currentLegItemIndex,
                })
              : handleMenuIconPress({ item, currentIndex, currentLegIndex, currentLegItemIndex })
          }
          style={[globalStyle.padding10, globalStyle.paddingVertical5]}
        >
          <Icon name="more-vertical" width={16} height={16} fill="#777" />
        </Pressable>
      </View>
    );
  };

  const renderLegItem = ({ item, currentLegIndex }: { item: any; currentLegIndex: number }) => {
    return (
      <Pressable
        style={[
          globalStyle.flexRow,
          globalStyle.justifyContentBetween,
          globalStyle.alignItemsCenter,
          globalStyle.padding15,
          globalStyle.marginBottom2,
          globalStyle.backgroundColorBasic2,
          globalStyle.borderRadius8,
        ]}
        onPress={handleLegItemPress({ currentLegIndex })}
      >
        <View style={[globalStyle.flex1, globalStyle.flexRow]}>
          <CheckBox
            checked={item.checked}
            style={globalStyle.size20}
            onChange={handleLegItemPress({ currentLegIndex })}
          />
          <View
            style={[
              globalStyle.flex1,
              isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
            ]}
          >
            <Text
              style={[
                globalStyle.fontSize14,
                globalStyle.fontLatoBold,
                globalStyle.paddingHorizontal10,
              ]}
            >
              {item.label}
            </Text>
            <View
              style={[
                isSmallDevice ? globalStyle.flexColumn : globalStyle.flexRow,
                globalStyle.alignItemsStart,
              ]}
            >
              {(!!item.flightCarrierDesignation || !!item.flightNumber) && (
                <View
                  style={[
                    globalStyle.marginLeft5,
                    globalStyle.padding5,
                    globalStyle.paddingHorizontal10,
                    globalStyle.backgroundColorBasic4,
                    isSmallDevice && globalStyle.marginTop5,
                  ]}
                >
                  <Text
                    style={[
                      globalStyle.fontSize12,
                      globalStyle.fontLatoBold,
                      globalStyle.marginBottom2,
                    ]}
                  >
                    {t('jobs.flightNumber', { defaultValue: 'Flight number' }).toUpperCase()}:
                  </Text>
                  <View style={globalStyle.flexRow}>
                    {!!item.flightCarrierDesignation && (
                      <Text style={globalStyle.fontSize13}>{item.flightCarrierDesignation}</Text>
                    )}
                    {!!item.flightCarrierDesignation && !!item.flightNumber && <Text> </Text>}
                    {!!item.flightNumber && (
                      <Text style={globalStyle.fontSize13}>{item.flightNumber}</Text>
                    )}
                  </View>
                </View>
              )}
              {!!item.departureDate && (
                <View
                  style={[
                    globalStyle.marginLeft5,
                    globalStyle.padding5,
                    globalStyle.paddingHorizontal10,
                    globalStyle.backgroundColorBasic4,
                    isSmallDevice && globalStyle.marginTop5,
                  ]}
                >
                  <Text
                    style={[
                      globalStyle.fontSize12,
                      globalStyle.fontLatoBold,
                      globalStyle.marginBottom2,
                    ]}
                  >
                    {t('common.departureDate', { defaultValue: 'Departure date' }).toUpperCase()}:{' '}
                  </Text>
                  <Text style={globalStyle.fontSize13}>
                    {`${item.departureDate?.toLocaleString({ weekday: 'short' })}, ${item.departureDate?.toFormat('dd MMM yyyy')}`}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
        <View style={globalStyle.flexRow}>
          <Button
            appearance="filled"
            status="warning"
            size="small"
            onPress={handleRemoveLegItem({ currentLegIndex })}
            style={[globalStyle.marginRight10, globalStyle.width100]}
          >
            {t('common.remove', { defaultValue: 'Remove' })}
          </Button>
          <Pressable
            hitSlop={{ top: 5, bottom: 5, left: 10, right: 10 }}
            onPress={handleLegMenuIconPress({ item, currentLegIndex })}
            style={[globalStyle.padding10, globalStyle.paddingVertical5]}
          >
            <Icon name="more-vertical" width={16} height={16} fill="#777" />
          </Pressable>
        </View>
      </Pressable>
    );
  };

  const renderLegList = (item: any) => {
    return (
      <>
        <View>
          {item.map((agencyJobPlanningLeg: AgencyJobPlanningLeg, currentLegIndex: number) => {
            return (
              <Fragment key={agencyJobPlanningLeg.id}>
                {renderLegItem({
                  item: {
                    checked: agencyJobPlanningLeg.active,
                    label: t('jobs.leg', {
                      defaultValue: 'Leg {{legIndex}}',
                      legIndex: currentLegIndex + 1,
                    }),
                    departureDate: agencyJobPlanningLeg.departureDate,
                    flightCarrierDesignation: agencyJobPlanningLeg.flightCarrierDesignation,
                    flightNumber: agencyJobPlanningLeg.flightNumber,
                    flightTickets: agencyJobPlanningLeg.flightTickets,
                    value: `leg-${currentLegIndex}`,
                  },
                  currentLegIndex,
                })}
                {renderContent({
                  data: agencyJobPlanningLeg.agencyJobPlanningItems,
                  currentLegIndex,
                })}
              </Fragment>
            );
          })}
        </View>
        <Pressable
          style={[
            globalStyle.flex1,
            globalStyle.flexRow,
            globalStyle.justifyContentEnd,
            globalStyle.alignItemsCenter,
            globalStyle.width100Percent,
            globalStyle.padding10,
            globalStyle.marginBottom15,
          ]}
          onPress={handleAddAnotherLegPress}
        >
          <Text style={[globalStyle.paddingRight5, globalStyle.fontLatoBlack]}>
            {t('common.addAnotherLeg', { defaultValue: 'Add another leg' })}
          </Text>
          <Icon
            fill={(globalStyle.colorTextBasicColor as any).color}
            name="plus-square-outline"
            style={globalStyle.size24}
          />
        </Pressable>
      </>
    );
  };

  const renderContent = ({ data, currentLegIndex }: { data: any; currentLegIndex?: number }) => {
    const isLeg = typeof currentLegIndex === 'number';
    return (
      <>
        {typeof currentLegIndex !== 'number' && (
          <Pressable
            style={[
              globalStyle.flexRow,
              globalStyle.justifyContentEnd,
              globalStyle.padding15,
              globalStyle.paddingRight5,
            ]}
            onPress={handleSelectAllPress}
          >
            <Text style={globalStyle.paddingRight15}>
              {t('common.selectAll', { defaultValue: 'Select all' })}
            </Text>
            <CheckBox
              checked={state.isAllSelected}
              style={globalStyle.size20}
              onChange={handleSelectAllPress}
            />
          </Pressable>
        )}
        {data?.map((item: any, index: number) => {
          if (item.name === 'travelAtDropOffDeliveryLocation') {
            return (
              <Fragment key={item.id}>
                {state.agencyJobPlanning?.agencyJobPlanningLegs &&
                  renderLegList(state.agencyJobPlanning?.agencyJobPlanningLegs)}
                {renderItem({
                  item,
                  currentIndex: index,
                  currentLegIndex,
                  currentLegItemIndex: isLeg ? index : undefined,
                })}
              </Fragment>
            );
          }
          return renderItem({
            item,
            currentIndex: !isLeg ? index : undefined,
            currentLegIndex,
            currentLegItemIndex: isLeg ? index : undefined,
          });
        })}
      </>
    );
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={title}
        subtitle={subtitle}
        navigation={navigation}
        onPressLeft={handleBackPress}
        accessoryRight={() => (
          <Button onPress={handleSave} appearance="filled" style={styles.saveBtn}>
            {isSaving ? (
              <ActivityIndicator color="white" />
            ) : (
              (t('common.save', { defaultValue: 'Save' }) as string)
            )}
          </Button>
        )}
      />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ScrollView>
          {state.agencyJobPlanning?.agencyJobPlanningItems?.length ? (
            renderContent({ data: state.agencyJobPlanning?.agencyJobPlanningItems })
          ) : (
            <></>
          )}
        </ScrollView>
      )}

      <PlanningLegModal
        ref={legModalRef}
        handleCancel={handleLegModalCancel}
        handleConfirm={handleLegModalConfirm}
        loading={false}
        title={t('jobs.leg', {
          defaultValue: 'Leg {{legIndex}}',
          legIndex: (state.currentLegIndex || 0) + 1,
        })}
        visible={visiblePlanningLegModal}
      />
      <PlanningDateTimePickerModal
        ref={dateTimePickerModalRef}
        handleCancel={handleDateModalCancel}
        handleConfirm={handleDateModalConfirm}
        handleConfirmAndNext={handleDateModalAndNextConfirm}
        loading={false}
        selectedItem={currentItem}
        visible={visiblePlanningDateTimePicker}
      />
    </>
  );
};
