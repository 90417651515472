import { uniqBy } from 'lodash';

import { MutationUpdateType } from './types';
import {
  CHAT_MESSAGES_BY_CHAT_ID_QUERY,
  CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY,
} from '../../../apollo/graphql-queries';
import { ChatType } from '../../../generated-graphql-types';

export const updateChatMessageCacheCRUD = ({
  cache,
  chatType,
  messageConnection,
  initialVariables,
  messageEdge,
  type,
}: {
  chatType: ChatType;
  cache?: any;
  messageConnection?: { chatMessagesByChatId: any };
  initialVariables?: any;
  messageEdge: any;
  type: any;
}): { chatMessagesByChatId: any } | null => {
  const _cache = cache;

  let existingMessageConnection: { chatMessagesByChatId: any } = messageConnection || {
    chatMessagesByChatId: { edges: [], totalCount: 0 },
  };

  if (!messageConnection) {
    existingMessageConnection = _cache.readQuery({
      query:
        chatType === ChatType.AGENCY_JOB_CHAT
          ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY
          : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
      variables: initialVariables,
    });
  }

  let data = { chatMessagesByChatId: { edges: [], totalCount: 0 } } as {
    chatMessagesByChatId: any;
  };

  if (existingMessageConnection?.chatMessagesByChatId) {
    if (type === MutationUpdateType.DELETE) {
      data = {
        chatMessagesByChatId: {
          ...existingMessageConnection.chatMessagesByChatId,
          edges: sortMessageNodes(
            existingMessageConnection.chatMessagesByChatId.edges.filter(
              (item: any) => item.node.id !== messageEdge.node.id,
            ),
          ),
          totalCount: existingMessageConnection.chatMessagesByChatId.totalCount - 1,
        },
      };
    } else {
      let currentMessages = existingMessageConnection.chatMessagesByChatId.edges || [];
      let totalCount = existingMessageConnection.chatMessagesByChatId.totalCount;

      let current;
      if (messageEdge.node.id) {
        current = currentMessages.find(({ node }: any) => node.id === messageEdge.node.id);
      }

      if (current) {
        currentMessages = [...currentMessages].map((item: any) => {
          if (item.node.id === messageEdge.node.id) {
            return { ...item, node: { ...item.node }, __typename: 'ChatMessageEdge' };
          }
          return item;
        });
      } else {
        currentMessages = [{ ...messageEdge, __typename: 'ChatMessageEdge' }, ...currentMessages];
        totalCount++;
      }
      data = {
        chatMessagesByChatId: {
          ...existingMessageConnection.chatMessagesByChatId,
          edges: sortMessageNodes(currentMessages),
          totalCount,
        },
      };
    }

    if (!messageConnection) {
      _cache.writeQuery({
        query: CHAT_MESSAGES_BY_CHAT_ID_QUERY,
        variables: initialVariables,
        data,
      });
    }
  }
  return data;
};

export const sortMessageNodes = (newMessages: any[]): any[] => {
  return uniqBy(
    [...newMessages].sort(
      (a, b) => new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime(),
    ),
    'node.id',
  );
};

export const addFieldsToGiftedMessages = (message: any) => ({
  ...message,
  _id: message.id,
  text: message.message,
  createdAt: new Date(message.createdAt),
  user: {
    ...message.sendingUser,
    _id: message.sendingUser.id || '',
    name: `${message.sendingUser?.firstNames} ${message.sendingUser?.lastName}`,
    avatar: message.sendingUser?.profilePicture?.sasUrl,
  },
});
