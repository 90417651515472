import React, { useContext, useMemo, useState } from 'react';
import { RefreshControl, View } from 'react-native';

import { useQuery } from '@apollo/client';
import { Input, StyleService, Text, useTheme } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { AgencyConnectedCourierOrganizationsList } from './AgencyConnectedCourierOrganizationsList';
import { CONNECTED_COURIER_ORGANIZATIONS_FOR_AGENCY_ORGANIZATION } from '../../../apollo/graphql-queries';
import { globalStyle } from '../../../common/style';
import { TopNavigationBackToProfileButton } from '../../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { ConnectedOrganization } from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const AgencyConnectedCourierOrganizationsScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();

  const theme = useTheme();
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const [refreshing, setRefreshing] = useState(false);
  const [filterTerm, setFilterTerm] = useState<string>();

  const { data, refetch } = useQuery<{
    connectedCourierOrganizationsForAgencyOrganization: ConnectedOrganization[];
  }>(CONNECTED_COURIER_ORGANIZATIONS_FOR_AGENCY_ORGANIZATION, {
    variables: {
      agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const connectedOrganizations = useMemo(() => {
    if (data?.connectedCourierOrganizationsForAgencyOrganization) {
      if (!filterTerm) {
        return data?.connectedCourierOrganizationsForAgencyOrganization;
      }
      return Object.values(data?.connectedCourierOrganizationsForAgencyOrganization).filter(
        (co) => {
          try {
            const str = JSON.stringify(Object.values(co.organization)).toLowerCase();
            return str.includes(filterTerm.toLowerCase());
          } catch (e) {
            return false;
          }
        },
      );
    }

    return [];
  }, [data, filterTerm]);

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const renderCustomHeader = () => (
    <Input
      placeholder={
        t('connectedCouriers.filterPlaceholder', {
          defaultValue: 'Filter Courier by name, email or phone',
        }) as string
      }
      style={[globalStyle.marginTop10, globalStyle.marginBottom5, globalStyle.marginHorizontal10]}
      onChangeText={setFilterTerm}
    />
  );

  const renderCustomEmpty = () => {
    if (filterTerm) {
      return (
        <View style={styles.noResultsView}>
          <Text>
            {
              t('connectedCouriers.noConnectedCouriersFound', {
                filterTerm,
                defaultValue: 'No connected Couriers found for filter term "{{filterTerm}}".',
              }) as string
            }
          </Text>
        </View>
      );
    }
    return (
      <View style={styles.noResultsView}>
        <Text selectable={true}>
          You don't have Courier connections. To connect with a Courier, invite the Courier from
          your phone's address book.
        </Text>
      </View>
    );
  };

  const renderAgencyConnectedCourierOrganizationConnectionsScreen = () => (
    <>
      <AgencyConnectedCourierOrganizationsList
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            title={
              t('connectedCouriers.noConnectedCouriersFound', {
                defaltValue: 'Loading connected couriers',
              }) as string
            }
            titleColor={theme['text-basic-color']}
            tintColor={theme['text-basic-color']}
            colors={[theme['text-basic-color']]}
          />
        }
        connectedOrganizations={connectedOrganizations as ConnectedOrganization[]}
        renderCustomHeader={renderCustomHeader()}
        renderCustomEmpty={renderCustomEmpty()}
      />
    </>
  );

  return (
    <>
      <TopNavigationBackToProfileButton
        userContext={appUserContext.currentUserContext}
        title={`${t('connectedCouriers.screenTitle', {
          defaultValue: 'Connected Couriers',
        })}${connectedOrganizations?.length ? ` (${connectedOrganizations.length})` : ''}`}
        navigation={navigation}
      />
      {renderAgencyConnectedCourierOrganizationConnectionsScreen()}
    </>
  );
};

const styles = StyleService.create({
  noResultsView: {
    marginTop: 10,
    padding: 10,
  },
});
