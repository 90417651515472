import { ApolloCache, NormalizedCacheObject } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AsyncStorageWrapper, CachePersistor } from 'apollo3-cache-persist';

import { GLOBAL_CONSTANTS } from '../globals';
import { parseJsonWithDateTime } from '../utils/json-parse-with-date-time';

export const getApolloCachePersistorInstance = (cache: ApolloCache<NormalizedCacheObject>) =>
  new CachePersistor<NormalizedCacheObject>({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
    debug: process.env.EXPO_APP_ENV === 'development',
    trigger: 'write',
    key: GLOBAL_CONSTANTS.APOLLO_CACHE_PERSIST_STORAGE_KEY,
    // @ts-ignore
    jsonParser: parseJsonWithDateTime,
  });
