import { StyleService } from '@ui-kitten/components';

export const themedStyle = StyleService.create({
  success: {
    padding: 15,
    borderWidth: 1,
    borderColor: 'color-success-900',
    backgroundColor: 'color-success-transparent-200',
    borderRadius: 8,
    marginTop: 20,
    alignSelf: 'center',
  },
  successText: {
    fontWeight: 'bold',
  },
  error: {
    padding: 15,
    borderWidth: 1,
    borderColor: 'color-danger-900',
    backgroundColor: 'color-danger-transparent-200',
    borderRadius: 8,
    marginTop: 20,
    alignSelf: 'center',
  },
  container: {
    alignItems: 'center',
  },
  line: {
    backgroundColor: 'background-alternative-color-1',
    height: 1,
    width: 150,
    opacity: 0.2,
  },
});
