import React from 'react';
import { View } from 'react-native';

import { Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';


import { PriojetProfileTopNavigationBar } from '../../components/priojet/priojet-profile-top-navigation-bar.component';

export const PriojetManagePriojetUsersScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <PriojetProfileTopNavigationBar
        title="Manage PRIOjet Users"
        navigation={navigation}
      />
      <View style={{ padding: 10 }}>
        <Text selectable={true}>
          {
            t('common.screenNameIsComingSoon', {
              defaultValue: '{{screenName}} is coming soon',
              screenName: 'PriojetManagePriojetUsersScreen',
            }) as string
          }
        </Text>
      </View>
    </>
  );
};
