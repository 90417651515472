import React, { useContext, useMemo, Fragment } from 'react';
import { Platform, View, ScrollView } from 'react-native';

import { useRoute, useNavigation } from '@react-navigation/core';
import { Button, Icon, Text, useStyleSheet, Toggle, StyleService } from '@ui-kitten/components';
import { PermissionStatus } from 'expo-modules-core';
import { useTranslation } from 'react-i18next';

import { getNotificationSettingOptions } from './constants';
import { useHook } from './hook';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { TopNavigationWithoutAccessories } from '../../../components/top-navigation-without-accessories.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { OrganizationType } from '../../../generated-graphql-types';
import { openAppSettings } from '../../../modules/device/device.module';

const CommonNotificationPermissionsScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const appUserContext = useContext(AppUserContext);
  const globalStyle = useStyleSheet(globalStyleCommon);
  const styles = useStyleSheet(themedStyles);

  const NOTIFICATION_SETTINGS: any = useMemo(
    () =>
      getNotificationSettingOptions(
        appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER,
        t,
      ),
    [appUserContext.currentUserContext?.organizationType, t],
  );

  const {
    iconFill,
    iconName,
    handleNotificationSettingChange,
    notificationPermissions,
    notificationSettings,
    requestPermissionsAsync,
  } = useHook();

  const renderNotificationSettingHeader = ({ title }: { title: string }) => (
    <View
      style={[globalStyle.backgroundColorBasic3, globalStyle.padding15, globalStyle.marginTop15]}
    >
      <Text style={globalStyle.fontLatoBold}>{title}</Text>
    </View>
  );

  const renderNotificationSettingItem = (item: any) => (
    <View
      key={item.key}
      style={[
        globalStyle.flex1,
        globalStyle.flexRow,
        globalStyle.marginHorizontal15,
        globalStyle.paddingVertical15,
        !item.isLast && globalStyle.borderBottomBasicColor3,
        !item.isLast && globalStyle.borderBottomWidth1,
      ]}
    >
      <View style={globalStyle.flex1}>
        <Text style={globalStyle.fontLatoBold}>{item.title}</Text>
        <Text>{item.description}</Text>
      </View>
      <Toggle
        checked={notificationSettings[item.key]}
        onChange={handleNotificationSettingChange(item.key)}
        style={{ transform: [{ scale: 0.8 }] }}
      />
    </View>
  );

  const renderNotificationSettings = () => (
    <View style={globalStyle.marginBottom20}>
      {Object.keys(NOTIFICATION_SETTINGS).map((key) => {
        const settings = NOTIFICATION_SETTINGS[key];
        return (
          <Fragment key={key}>
            {renderNotificationSettingHeader({ title: settings.title })}
            {settings.items.map(renderNotificationSettingItem)}
          </Fragment>
        );
      })}
    </View>
  );

  const granted = notificationPermissions?.granted;
  const status = notificationPermissions?.status || 'UNDETERMINED';
  return (
    <>
      {route.params?.canGoBack ? (
        <TopNavigationWithBackButton navigation={navigation} title="Notifications" />
      ) : (
        <TopNavigationWithoutAccessories title="Notifications" />
      )}
      <ScrollView>
        <View style={[globalStyle.paddingHorizontal30, globalStyle.padding30]}>
          <View style={globalStyle.alignItemsCenter}>
            <Text style={[styles.descriptionText, globalStyle.lineHeight22]}>
              The PRIOjet app enables you to schedule your availabilities, find and manage OBC jobs,
              add and share your OBC documents &amp; profile information and much more related to
              the Onboard Courier (OBC) logistics space.
              {granted === false
                ? '\n\nTo be able to inform you whenever there are updates available, job requests coming in, etc., you should enable notifications so that we can reach you on your phone.'
                : ''}
            </Text>
            <View
              style={[
                globalStyle.flexRow,
                globalStyle.justifyContentCenter,
                globalStyle.paddingVertical10,
              ]}
            >
              <Icon
                name={iconName}
                fill={iconFill}
                style={[globalStyle.height75, globalStyle.width75]}
              />
              <Icon
                name="smartphone-outline"
                fill="lightgray"
                style={[globalStyle.height75, globalStyle.width75]}
              />
            </View>
          </View>

          <View>
            {Platform.OS !== 'web' && status === PermissionStatus.GRANTED && (
              <>
                <View style={globalStyle.flexRow}>
                  <Icon name="checkmark-square-outline" fill="#2D7304" style={globalStyle.size24} />
                  <Text
                    style={[
                      globalStyle.flex1,
                      globalStyle.lineHeight22,
                      globalStyle.marginBottom20,
                    ]}
                  >
                    Notifications are enabled on this device. To disable Notifications, go to
                    settings.
                  </Text>
                </View>
                <Button onPress={openAppSettings}>Go to settings</Button>
              </>
            )}

            {Platform.OS !== 'web' && status === PermissionStatus.DENIED && (
              <>
                <View style={globalStyle.flexRow}>
                  <Icon name="close-circle-outline" fill="#C6141B" style={globalStyle.size24} />
                  <Text
                    style={[
                      globalStyle.flex1,
                      globalStyle.lineHeight22,
                      globalStyle.marginBottom20,
                    ]}
                  >
                    Notifications are disabled on this device. To enable Notifications, go to
                    settings.
                  </Text>
                </View>
                <Button onPress={openAppSettings}>Go to settings</Button>
              </>
            )}
            {Platform.OS !== 'web' &&
              status !== PermissionStatus.DENIED &&
              status !== PermissionStatus.GRANTED && (
                <Button onPress={requestPermissionsAsync}>Set up notification permissions</Button>
              )}
          </View>
        </View>
        {!route.params?.hideSettings && renderNotificationSettings()}
      </ScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  descriptionText: {
    maxWidth: 500,
    textAlign: 'center',
  },
});

export default CommonNotificationPermissionsScreen;
