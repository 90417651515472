import React, { useContext, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Constants from 'expo-constants';
import * as ExpoImagePicker from 'expo-image-picker';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import * as Yup from 'yup';

import { getAuthorizationHeaders } from '../../../apollo/create-auth-link-with-headers';
import {
  DELETE_ORGANIZATION_LOGO_MUTATION,
  UPDATE_AGENCY_ORGANIZATION_PROFILE_MUTATION,
} from '../../../apollo/graphql-mutations';
import { ORGANIZATION_PROFILE_QUERY } from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import { LocationManualInput, SocialProfile, StoredAsset } from '../../../generated-graphql-types';
import {
  OrganizationProfile,
  OrganizationProfileVariables,
  UpdateAgencyOrganizationProfile,
  UpdateAgencyOrganizationProfileVariables,
} from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { SOCIAL_KEYS, SOCIAL_OPTIONS, createSocialURL } from '../../../utils/social-media.util';
import { isValidEmail, isValidURL } from '../../../utils/validate-field.util';

const organizationFields: string[] = [
  'description',
  'name',
  'shortName',
  'email',
  'phone',
  'legalName',
  'registrationNumber',
  'vatId',
  'invoiceEmail',
];

const locationFields: string[] = [
  'addressName',
  'formattedAddress',
  'streetName',
  'houseNumber',
  'apartmentOrSuite',
  'city',
  'postalCode',
  'state',
  'country',
  'formattedAddress',
  'id',
  'placeId',
];

const initialValues = {
  name: '',
  shortName: '',
  email: '',
  phone: '',
  legalName: '',
  registrationNumber: '',
  vatId: '',
  description: '',
  about: '',
  invoiceEmail: '',

  ...SOCIAL_KEYS,
};

export const useHook = () => {
  const { t } = useTranslation();

  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const headquartersAddressLocationRef: any = useRef(null);
  const invoiceAddressLocationRef: any = useRef(null);

  const [refreshing, setRefreshing] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [radioGroupVisible, setRadioGroupVisible] = useState<boolean>(false);
  const [temporaryProfilePicture, setTemporaryProfilePicture] = useState<
    ExpoImagePicker.ImagePickerAsset | undefined
  >();
  const [organizationLogo, setOrganizationLogo] = useState<StoredAsset | null | undefined>();

  const [updateAgencyOrganizationProfile] = useMutation<
    UpdateAgencyOrganizationProfile,
    UpdateAgencyOrganizationProfileVariables
  >(UPDATE_AGENCY_ORGANIZATION_PROFILE_MUTATION);
  const [deleteOrganizationLogoMutation, { loading: loadingDeletePicture }] = useMutation(
    DELETE_ORGANIZATION_LOGO_MUTATION,
  );

  const handleSave = async () => {
    const errors = await formik.validateForm();
    ['facebook', 'linkedin', 'youtube', 'xing'].forEach((field) => {
      const url = createSocialURL({ platform: field, value: formik.values[field] || '' });
      if (!isValidURL({ canBeEmpty: true, hasToHaveSuffix: true, value: url })) {
        const errorField = t('courierUserProfile.invalidUrl', {
          defaultValue: 'Must be a valid URL',
        });
        errors[field] = errorField;
      } else if (errors.hasOwnProperty(field)) {
        delete errors[field];
      }
    });
    // formik.setErrors(errors);
    if (Object.keys(errors).length) {
      showMessage({
        message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
        description:
          t('agencyProfile.someFieldAreNotValid', {
            defaultValue: 'Some fields are not valid',
          }) +
          `.\n\n${Object.keys(errors)
            .map((key) => `${key} - ${errors[key]}`)
            .join('\n')}`,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const agencyOrganizationProfileInput: any = {
      id: agencyOrganizationProfileData?.organization.id as string,
      organizationType: agencyOrganizationProfileData?.organization.organizationType,
    };

    const headquartersAddressLocationForm =
      await headquartersAddressLocationRef.current?.getValues();

    if (headquartersAddressLocationForm.isValid) {
      const headquartersAddressLocation: LocationManualInput = {};
      Object.keys(headquartersAddressLocationForm.values).forEach((locKey) => {
        const locationKey = locKey as keyof LocationManualInput;
        if (locationFields.includes(locationKey)) {
          headquartersAddressLocation[locationKey] =
            headquartersAddressLocationForm.values[locationKey];
        }
      });
      agencyOrganizationProfileInput.headquartersAddressLocation = headquartersAddressLocation;
    } else if (!headquartersAddressLocationForm.isEmpty) {
      showMessage({
        message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
        description: t('agencyProfile.headquartersAddressIsNotValid', {
          defaultValue: 'Headquarters address is not valid',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const invoicingAddressLocationForm = await invoiceAddressLocationRef.current?.getValues();
    if (invoicingAddressLocationForm.isValid) {
      const invoicingAddressLocation: LocationManualInput = {};
      Object.keys(invoicingAddressLocationForm.values).forEach((locKey) => {
        const locationKey = locKey as keyof LocationManualInput;
        if (locationFields.includes(locationKey)) {
          invoicingAddressLocation[locationKey] = invoicingAddressLocationForm.values[locationKey];
        }
      });
      agencyOrganizationProfileInput.invoicingAddressLocation = invoicingAddressLocation;
    } else if (!invoicingAddressLocationForm.isEmpty) {
      showMessage({
        message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
        description: t('agencyProfile.invoicingAddressIsNotValid', {
          defaultValue: 'Invoicing address is not valid',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const socialProfiles: SocialProfile[] = [];
    Object.keys(formik.values).forEach((key) => {
      if (organizationFields.includes(key)) {
        agencyOrganizationProfileInput[key] = formik.values[key];
      } else if (SOCIAL_KEYS.includes(key)) {
        socialProfiles.push({
          platform: key,
          value: formik.values[key],
          type: SOCIAL_OPTIONS[key].type,
        });
      }
    });
    agencyOrganizationProfileInput.socialProfiles = socialProfiles;

    setLoading(true);
    try {
      uploadProfilePicture(temporaryProfilePicture);
      await updateAgencyOrganizationProfile({
        variables: { agencyOrganizationProfileInput },
      });

      showMessage({
        message: t('agencyProfile.success', { defaultValue: 'Success' }) as string,
        description: t('agencyProfile.profileUpdated', {
          defaultValue: 'Agency profile successfully updated',
        }) as string,
        type: 'success',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    } catch (e) {
      showMessage({
        message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
        description: t('agencyProfile.somethingWentWrong', {
          defaultValue: 'Something went wrong',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(
      t('agencyProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    legalName: Yup.string().required(
      t('agencyProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    email: Yup.string().test(
      'test-valid-email',
      t('courierUserProfile.invalidEmail', {
        defaultValue: 'Must be a valid email',
      }) as string,
      (value) =>
        isValidEmail({
          value,
          canBeEmpty: false,
        }),
    ),
    phone: Yup.string().required(
      t('agencyProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    shortName: Yup.string()
      .required()
      .max(
        9,
        t('courierUserProfile.max9Characters', {
          defaultValue: 'Maximum 9 characters',
        }) as string,
      ),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const onCompletedOrganizationProfile = ({ organization }: any) => {
    const newValues: any = {};
    if (!formik.values.name) {
      Object.keys(organization).map((key) => {
        if (organizationFields.includes(key) && organization[key]) {
          newValues[key] = organization[key];
        } else if (key === 'socialProfiles') {
          if (organization.socialProfiles?.length) {
            organization.socialProfiles.forEach((item: { platform: string; value: string }) => {
              newValues[item.platform] = item.value;
            });
          }
        }
      });
      formik.setValues({ ...formik.values, ...newValues });
      if (organization.logo) {
        setOrganizationLogo(organization.logo);
      }
    }
  };

  const { data: agencyOrganizationProfileData, refetch: agencyOrganizationProfileQueryRefetch } =
    useQuery<OrganizationProfile, OrganizationProfileVariables>(ORGANIZATION_PROFILE_QUERY, {
      variables: { id: appUserContext.currentUserContext?.organization?.id as string },
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
      onCompleted: onCompletedOrganizationProfile,
    });

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    let { error: fieldError } = formik.getFieldMeta(field);

    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: fieldError,
        onChange,
        status: fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (['facebook', 'linkedin', 'youtube', 'xing'].includes(field)) {
        const url = createSocialURL({ platform: field, value: value || '' });
        if (!isValidURL({ canBeEmpty: true, hasToHaveSuffix: true, value: url })) {
          fieldError = t('agencyUserProfile.invalidUrl', {
            defaultValue: 'Must be a valid URL',
          }) as string;
        } else {
          fieldError = '';
        }
        formik.setFieldError(field, fieldError);
      } else if (fieldError) {
        setTimeout(() => {
          formik.validateField(field);
        }, 50);
      }
    };
    const common = {
      errorMessage: fieldError,
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  // Profile Picture
  const pickImageMobile = async () => {
    const { status } = await ExpoImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      showMessage({
        message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
        description: t('agencyProfile.noCameraPermissions', {
          defaultValue: 'Sorry, we need camera roll permissions to make this work!',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    const camPermission = await ExpoImagePicker.getCameraPermissionsAsync();
    if (camPermission.status === 'granted') {
      const result = await ExpoImagePicker.launchCameraAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
        base64: true,
      });
      if (!result.canceled) {
        setTemporaryProfilePicture(result.assets[0]);
      }
    }
  };
  const pickImageWeb = async () => {
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
      base64: true,
    });
    if (!result.canceled && result.assets?.length) {
      setTemporaryProfilePicture(result.assets[0]);
    }
  };

  const handleAvatarButtonVisibility = () => {
    setRadioGroupVisible((prevState) => !prevState);
  };

  const uploadProfilePicture = async (image: ExpoImagePicker.ImagePickerAsset | undefined) => {
    if (image) {
      const authHeaders = await getAuthorizationHeaders();
      const lastIndexOfDot = image.uri.lastIndexOf('.');
      const lastIndexOfSlash = image.uri.lastIndexOf('/');
      const fileType = image.uri.substring(lastIndexOfDot + 1);
      const fileName = image.uri.substring(lastIndexOfSlash + 1);
      try {
        const response = await fetch(
          Constants.expoConfig?.extra?.priojetBackendUploadOrganizationLogoEndpoint,
          {
            method: 'post',
            headers: {
              ...authHeaders,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file: image?.base64,
              fileType,
              fileName,
              uri: image?.uri,
              exif: image?.exif,
              height: image?.height,
              width: image?.width,
            }),
          },
        );

        if (response) {
          response.json().then(function (data) {
            setOrganizationLogo(data);
            setTemporaryProfilePicture(undefined);
          });
        }
      } catch {
        showMessage({
          message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
          description: t('agencyProfile.errorUploadingLogoImage', {
            defaultValue: 'Something went wrong with uploading logo image',
          }) as string,
          type: 'danger',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    }
  };

  const handleDeleteOrganizationLogo = async () => {
    try {
      await deleteOrganizationLogoMutation({
        variables: {
          organizationId: agencyOrganizationProfileData?.organization.id as string,
        },
      });
      setOrganizationLogo(undefined);
      setTemporaryProfilePicture(undefined);
    } catch {
      showMessage({
        message: t('agencyProfile.error', { defaultValue: 'Error' }) as string,
        description: t('agencyProfile.errorDeletingLogoImage', {
          defaultValue: 'Something went wrong with deleting logo image',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const profilePicture = useMemo(() => {
    if (temporaryProfilePicture) {
      return temporaryProfilePicture;
    }
    if (organizationLogo && organizationLogo.sasUrl) {
      return { uri: organizationLogo.sasUrl };
    }
  }, [temporaryProfilePicture, organizationLogo]);

  return {
    agencyOrganizationProfileData,
    agencyOrganizationProfileQueryRefetch,
    fieldProps,
    formik,
    handleAvatarButtonVisibility,
    handleDeleteOrganizationLogo,
    handleSave,
    headquartersAddressLocationRef,
    invoiceAddressLocationRef,
    loading,
    loadingDeletePicture,
    organizationLogo,
    pickImageMobile,
    profilePicture,
    pickImageWeb,
    radioGroupVisible,
    refreshing,
    setRefreshing,
  };
};
