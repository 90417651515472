import React, { useState } from 'react';
import { ActivityIndicator, Platform, Pressable, View } from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import { Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle as globalStyleCommon } from '../../../../../../common/style';
import ImageLightBox from '../../../../../../components/common/ImageLightBox';
import { StoredAsset } from '../../../../../../generated-graphql-types';

const downloadFile =
  Platform.OS === 'web'
    ? require('../../../../../../modules/helper/download.module').downloadFileWeb
    : require('../../../../../../modules/helper/download.module').downloadFile;

export const ChatFlightTicketItem = ({
  file,
  handlePDFPress,
  showMessage,
}: {
  file: StoredAsset;
  handlePDFPress: (uri: string) => () => void;
  showMessage: (data: { message: string; description: string; type: 'success' | 'danger' }) => void;
}): React.ReactElement => {
  const [isDownloading, setIsDownloading] = useState(false);

  const { t } = useTranslation();
  const globalStyle = useStyleSheet(globalStyleCommon);

  const saveFile = async () => {
    setIsDownloading(true);
    const { metadata, sasUrl } = file;
    const { fileName, mimeType } = metadata;
    if (Platform.OS === 'web') {
      await downloadFile({ fileName, uri: sasUrl });
    } else {
      const {
        error,
        showMessage: showMessageValue,
        success,
      } = await downloadFile({ fileName, mimeType, uri: sasUrl });
      if (showMessageValue) {
        showMessage({
          message: success
            ? t('common.success', { defaultValue: 'Success' })
            : t('common.error', { defaultValue: 'Error' }),
          description: success
            ? (t('common.successfullySaved', {
                type: t('common.flightTicket', { defaultValue: 'Flight ticket' }),
                defaultValue: `{{type}} successfully saved`,
              }) as string)
            : error,
          type: success ? 'success' : 'danger',
        });
      }
    }
    setIsDownloading(false);
  };

  const fileName = file.metadata.fileName;
  if (!fileName) {
    return <></>;
  }
  if (file?.metadata.mimeType?.includes('image')) {
    return (
      <View
        style={[
          globalStyle.flexRow,
          globalStyle.alignItemsCenter,
          globalStyle.backgroundColorBasic1,
          globalStyle.padding2,
          globalStyle.paddingLeft15,
          globalStyle.borderRadius6,
          globalStyle.marginBottom2,
        ]}
      >
        <ImageLightBox
          customImage={
            <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter]}>
              <Text
                style={[globalStyle.flex1, globalStyle.paddingRight15]}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {fileName}
              </Text>
            </View>
          }
          imageStyle={globalStyle.flex1}
          expandable
          uri={file.sasUrl}
        />
        <Pressable
          style={[
            globalStyle.backgroundColorBasic3,
            globalStyle.padding10,
            globalStyle.borderRadius6,
          ]}
          onPress={saveFile}
        >
          {isDownloading ? (
            <ActivityIndicator size="small" />
          ) : (
            <MaterialIcons
              color={(globalStyle.colorTextBasicColor as any).color}
              name="download"
              size={20}
            />
          )}
        </Pressable>
      </View>
    );
  }
  return (
    <Pressable
      style={[
        globalStyle.flexRow,
        globalStyle.alignItemsCenter,
        globalStyle.backgroundColorBasic1,
        globalStyle.padding2,
        globalStyle.paddingLeft15,
        globalStyle.borderRadius6,
        globalStyle.marginBottom2,
        globalStyle.width100Percent,
      ]}
      onPress={handlePDFPress(file.sasUrl)}
    >
      <Text
        style={[globalStyle.flex1, globalStyle.paddingRight15]}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {fileName}
      </Text>
      <Pressable
        style={[
          globalStyle.backgroundColorBasic3,
          globalStyle.padding10,
          globalStyle.borderRadius6,
        ]}
        onPress={saveFile}
      >
        {isDownloading ? (
          <ActivityIndicator size="small" />
        ) : (
          <MaterialIcons
            color={(globalStyle.colorTextBasicColor as any).color}
            name="download"
            size={20}
          />
        )}
      </Pressable>
    </Pressable>
  );
};
