import React, { useContext, useMemo, useState } from 'react';
import { FlatList, RefreshControl, View } from 'react-native';

import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { Input, Text, useTheme, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { themedStyles } from './styles';
import { extractErrorMessage } from '../../../../apollo/error-link';
import { MUTATION_ORGANIZATION_USER_REMOVE_ORGANIZATION_USER_FROM_ORGANIZATION } from '../../../../apollo/graphql-mutations';
import {
  ORGANIZATION_ORGANIZATION_USERS_QUERY,
  EMPLOYEE_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY,
} from '../../../../apollo/graphql-queries';
import { globalStyle } from '../../../../common/style';
import { LoadingSpinner } from '../../../../components/common/loading-spinner.component';
import { ConfirmModalContext } from '../../../../components/common/modal/ConfirmModal';
import AppUserContext from '../../../../contexts/AppUserContext';
import {
  Exact,
  Organization,
  OrganizationOrganizationUsersQuery,
  OrganizationUser,
  OrganizationUserRoleType,
  SubscriptionFeatureType,
} from '../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';
import { AgencyOrganizationUserCard } from '../AgencyOrganizationUserCard';

type Props = {
  loading: boolean;
  organization: Organization;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            id: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<OrganizationOrganizationUsersQuery>>;
};

export const AgencyOrganizationUsersList = ({ loading, organization, refetch }: Props) => {
  const navigation: any = useNavigation();
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const isBackendReachable = useIsBackendReachable();
  const { hideConfirmModal, showConfirmModal } = useContext(ConfirmModalContext);
  const appUserContext = useContext(AppUserContext);

  const { data: dataOrganizationInvitation } = useQuery(
    EMPLOYEE_INVITATIONS_FOR_AGENCY_ORGANIZATION_QUERY,
    {
      variables: {
        agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
        userId: appUserContext.currentUserContext?.user?.id as string,
      },
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    },
  );
  const { data } = useQuery(ORGANIZATION_ORGANIZATION_USERS_QUERY, {
    variables: { id: appUserContext.currentUserContext?.organization?.id as string },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const [organizationUserRemoveOrganizationUserFromOrganization] = useMutation(
    MUTATION_ORGANIZATION_USER_REMOVE_ORGANIZATION_USER_FROM_ORGANIZATION,
  );

  const [filterTerm, setFilterTerm] = useState<string>('');
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const {
    currentOrganizationUserCount,
    currentOrganizationInvitationCount,
    organizationUserPossibleCount,
    countHasError,
  } = useMemo(() => {
    let userCount = 0;
    let invitationCount = 0;
    let possibleCount = 0;

    const feature =
      appUserContext.currentUserContext?.organizationSubscriptionPlan?.subscriptionPlan?.subscriptionFeatures?.find(
        (item) => item.value === SubscriptionFeatureType.AGENCY_ORGANIZATION_USER_COUNT_BASIC,
      );
    if (feature?.count) {
      possibleCount = feature.count;
    }

    if (dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization?.length) {
      invitationCount +=
        dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization?.length;
      userCount += dataOrganizationInvitation?.employeeInvitationsForAgencyOrganization?.length;
    }
    if (data?.organization?.organizationUsers?.length) {
      userCount += data.organization.organizationUsers.length;
    }
    return {
      currentOrganizationUserCount: userCount,
      currentOrganizationInvitationCount: invitationCount,
      countHasError: userCount + invitationCount > possibleCount,
      organizationUserPossibleCount: possibleCount,
    };
  }, [
    appUserContext.currentUserContext?.organizationSubscriptionPlan?.subscriptionPlan
      ?.subscriptionFeatures,
    dataOrganizationInvitation,
    data,
  ]);

  const organizationUsers = useMemo(() => {
    if (organization.organizationUsers?.length) {
      let users = organization.organizationUsers;
      if (filterTerm) {
        users = organization.organizationUsers.filter((organizationUser) => {
          const { user } = organizationUser;
          const firstNames = (user.firstNames || '').replace(/\s/g, '').toLowerCase();
          const lastName = (user.lastName || '').replace(/\s/g, '').toLowerCase();
          const email = (user.email || '').replace(/\s/g, '').toLowerCase();
          const phoneNumber = (user.phone || '').replace(/\s/g, '').toLowerCase();
          const name = `${firstNames} ${lastName}`.replace(/\s/g, '').toLowerCase();
          const term = filterTerm.replace(/\s/g, '').toLowerCase();
          if (
            firstNames.includes(term) ||
            lastName.includes(term) ||
            name.includes(term) ||
            email.includes(term) ||
            phoneNumber.includes(term)
          ) {
            return true;
          }
          return false;
        });
      }
      return [...users]
        .sort((a, b) => {
          const nameA = `${a.user.firstNames} ${a.user.lastName}`.replace(/\s/g, '');
          const nameB = `${b.user.firstNames} ${b.user.lastName}`.replace(/\s/g, '');
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => {
          if (
            b.roleType === OrganizationUserRoleType.OWNER &&
            a.roleType !== OrganizationUserRoleType.OWNER
          ) {
            return 1;
          }
          if (
            a.roleType === OrganizationUserRoleType.OWNER &&
            b.roleType !== OrganizationUserRoleType.OWNER
          ) {
            return -1;
          }
          if (
            b.roleType === OrganizationUserRoleType.ADMIN &&
            a.roleType !== OrganizationUserRoleType.OWNER &&
            a.roleType !== OrganizationUserRoleType.ADMIN
          ) {
            return 1;
          }
          return 0;
        });
    }
    return [];
  }, [filterTerm, organization]);

  const handleItemPress = (organizationUser: OrganizationUser) => () => {
    navigation.navigate('AgencyAgencyUserMyProfileScreen', {
      organizationUserId: organizationUser?.id,
      previousScreenName: 'AgencyViewAgencyUsersScreen',
      previousNavigator: 'AgencyProfileNavigator',
    });
  };

  const handleDeleteOrganizationUserPress = (organizationUser: OrganizationUser) => async () => {
    if (
      organizationUser.roleType !== OrganizationUserRoleType.OWNER &&
      !organizationUsers.some(
        (item) =>
          item.id !== organizationUser.id && item.roleType === OrganizationUserRoleType.OWNER,
      )
    ) {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }) as string,
        description: t('common.userDeletionNotAvailableWithoutOtherAgencyOwnerError', {
          defaultValue:
            'Cannot remove user because agency will be without agency OWNER and that is not possible. Assign other employee as OWNER first and then you can remove employee from the agency.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    } else {
      showConfirmModal({
        confirmButtonStatus: 'danger',
        confirmButtonAppearance: 'filled',
        confirmButtonText: t('common.remove', { defaultValue: 'Remove' }),
        cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
        title: t('common.removeUser', { defaultValue: 'Remove user' }),
        text: t('common.removeUserConfirmModalText', {
          defaultValue: 'Are you sure you want to remove "{{name}}" from agency?',
          name: `${organizationUser.user.firstNames} ${organizationUser.user.lastName}`,
        }),
        onConfirmPress: async () => {
          try {
            await organizationUserRemoveOrganizationUserFromOrganization({
              variables: { organizationUserId: organizationUser.id },
            });
            showMessage({
              message: t('common.userRemoved', { defaultValue: 'User removed' }) as string,
              description: t('common.userSuccessfullyRemovedWithName', {
                defaultValue: 'User {{name}} successfully removed from agency',
                name: `${organizationUser.user.firstNames} ${organizationUser.user.lastName}`,
              }) as string,
              type: 'success',
              duration: 8000,
              autoHide: true,
              hideOnPress: true,
            });
            handleRefresh();
            return true;
          } catch (e) {
            console.warn(e);
            const { message } = extractErrorMessage(e);
            hideConfirmModal();
            showMessage({
              message: t('common.error', { defaultValue: 'Error' }) as string,
              description:
                message ||
                (t('common.somethingWentWrong', {
                  defaultValue: 'Something went wrong',
                }) as string),
              type: 'danger',
              duration: 5000,
              autoHide: true,
              hideOnPress: true,
            });
            return false;
          }
        },
        visible: true,
      });
    }
  };

  const handleRefresh = () => {
    setRefreshing(true);
    refetch();
    setRefreshing(false);
  };

  const renderEmpty = () => {
    if (organization?.organizationUsers?.length || !loading) {
      return <></>;
    }
    return (
      <View style={[globalStyle.flex1, globalStyle.paddingTop20, globalStyle.marginTop20]}>
        <LoadingSpinner
          text={t('loading', {
            defaultValue: 'Loading...',
          })}
        />
      </View>
    );
  };

  const renderCustomHeader = () => (
    <>
      <Input
        placeholder={
          t('common.filterByNameEmailPhone', {
            defaultValue: 'Filter by name, email or phone',
          }) as string
        }
        autoCapitalize="none"
        style={[globalStyle.marginTop10, globalStyle.marginBottom5, globalStyle.marginHorizontal10]}
        onChangeText={setFilterTerm}
      />
      {!!organizationUserPossibleCount && (
        <View
          style={[styles.seatCounterContainer, countHasError && styles.seatCounterContainerError]}
        >
          <Text style={styles.seatCounterText}>
            {t('common.seatsCounter', {
              defaultValue: 'Seats: {{count}}',
              count: `${currentOrganizationUserCount}/${organizationUserPossibleCount}`,
            })}
          </Text>
          {currentOrganizationInvitationCount === 1 && (
            <Text style={styles.seatCounterText}>
              {t('common.pendingInvitation', {
                defaultValue: 'Pending invitation: {{count}}',
                count: currentOrganizationInvitationCount,
              })}
            </Text>
          )}
          {currentOrganizationInvitationCount > 1 && (
            <Text style={styles.seatCounterText}>
              {t('common.pendingInvitations', {
                defaultValue: 'Pending invitations: {{count}}',
                count: currentOrganizationInvitationCount,
              })}
            </Text>
          )}
        </View>
      )}
    </>
  );

  const renderRefresh = () => (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={handleRefresh}
      titleColor={theme['text-basic-color']}
      tintColor={theme['text-basic-color']}
      colors={[theme['text-basic-color']]}
    />
  );

  const renderItem = ({ item }: { item: OrganizationUser }) => (
    <AgencyOrganizationUserCard
      onDeleteOrganizationUserPress={handleDeleteOrganizationUserPress(item)}
      onItemPress={handleItemPress(item)}
      organizationUser={item}
    />
  );

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      refreshControl={renderRefresh()}
      style={globalStyle.width100Percent}
      data={organizationUsers}
      renderItem={renderItem}
      ListHeaderComponent={renderCustomHeader()}
      ListEmptyComponent={renderEmpty()}
    />
  );
};
