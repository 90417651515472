import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { AgencyProfileNavigatorParamList } from './app.navigator';
import { CommonProfileNavigator } from './common.profile.navigator';
import { AgencyProfileScreen } from '../screens/agency/agency.profile.screen';
import { AgencyAgencyProfileReadOnlyScreen } from '../screens/agency/AgencyAgencyProfileReadOnlyScreen';
import { AgencyAgencyProfileScreen } from '../screens/agency/AgencyAgencyProfileScreen';
import { AgencyAgencyUserMyProfileScreen } from '../screens/agency/AgencyAgencyUserMyProfileScreen';
import { AgencyConnectedCourierOrganizationsScreen } from '../screens/agency/AgencyConnectedCourierOrganizationsScreen';
import { AgencyCourierDocumentsMenuScreen } from '../screens/agency/AgencyCourierDocumentsMenuScreen';
import { AgencyCourierOrganizationInvitationsScreen } from '../screens/agency/AgencyCourierInvitationsScreen';
import { AgencyCourierUserDocumentsScreen } from '../screens/agency/AgencyCourierUserDocumentsScreen';
import { AgencyCourierUserProfileScreen } from '../screens/agency/AgencyCourierUserProfile';
import { AgencyManageAgencyUsersScreen } from '../screens/agency/AgencyManageAgencyUsersScreen';
import { AgencyManageCourierCategoriesScreen } from '../screens/agency/AgencyManageCourierCategoriesScreen';
import { AgencyAgencyUserInvitationsScreen } from '../screens/agency/AgencyOrganizationUserInvitationScreen';
import { AgencyReadCourierUserDocumentScreen } from '../screens/agency/AgencyReadCourierUserDocumentScreen';
import { AgencyViewAgencyUsersScreen } from '../screens/agency/AgencyViewAgencyUsersScreen';
import { CommonChatSupportPipedriveScreen } from '../screens/common/common.chat-support.pipedrive.screen';
import CommonNotificationPermissionsScreen from '../screens/common/CommonNotificationPermissionsScreen';

const StackNavigator = createStackNavigator<AgencyProfileNavigatorParamList>();

const AgencyProfileNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName="AgencyProfileScreen"
    >
      <StackNavigator.Group>
        <StackNavigator.Screen name="AgencyProfileScreen" component={AgencyProfileScreen} />
        <StackNavigator.Screen
          name="AgencyAgencyProfileScreen"
          component={AgencyAgencyProfileScreen}
        />
        <StackNavigator.Screen
          name="AgencyAgencyUserMyProfileScreen"
          component={AgencyAgencyUserMyProfileScreen}
        />
        <StackNavigator.Screen
          name="AgencyConnectedCourierOrganizationsScreen"
          component={AgencyConnectedCourierOrganizationsScreen}
        />
        <StackNavigator.Screen
          name="AgencyManageCourierCategoriesScreen"
          component={AgencyManageCourierCategoriesScreen}
        />
        <StackNavigator.Screen
          name="AgencyManageAgencyUsersScreen"
          component={AgencyManageAgencyUsersScreen}
        />
        <StackNavigator.Screen
          name="AgencyCourierUserProfileScreen"
          component={AgencyCourierUserProfileScreen}
        />
        <StackNavigator.Screen
          name="AgencyCourierOrganizationInvitationsScreen"
          component={AgencyCourierOrganizationInvitationsScreen}
        />
        <StackNavigator.Screen
          name="AgencyCourierDocumentsMenuScreen"
          component={AgencyCourierDocumentsMenuScreen}
        />
        <StackNavigator.Screen
          name="AgencyReadCourierUserDocumentScreen"
          component={AgencyReadCourierUserDocumentScreen}
        />
        <StackNavigator.Screen
          name="AgencyCourierUserDocumentsScreen"
          component={AgencyCourierUserDocumentsScreen}
        />
        <StackNavigator.Screen
          name="AgencyViewAgencyUsersScreen"
          component={AgencyViewAgencyUsersScreen}
        />
        <StackNavigator.Screen
          name="AgencyAgencyUserInvitationsScreen"
          component={AgencyAgencyUserInvitationsScreen}
        />
        <StackNavigator.Screen
          name="AgencyAgencyProfileReadOnlyScreen"
          component={AgencyAgencyProfileReadOnlyScreen}
        />
        <StackNavigator.Screen
          name="CommonChatSupportPipedriveScreen"
          component={CommonChatSupportPipedriveScreen}
        />
        <StackNavigator.Screen
          name="CommonNotificationPermissionsScreen"
          component={CommonNotificationPermissionsScreen}
          initialParams={{ canGoBack: true }}
        />

        {/* TODO: check if needed/used */}
        <StackNavigator.Screen name="CommonProfileNavigator" component={CommonProfileNavigator} />
      </StackNavigator.Group>
    </StackNavigator.Navigator>
  );
};

export { AgencyProfileNavigator };
