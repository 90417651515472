import React from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { CommonTermsAndConditionsSignupComponent } from '../../components/common/CommonTermsAndConditionsSignupComponent';
import { TransparentModal } from '../../components/common/modal/transparent-modal.component';
import { OrganizationType } from '../../generated-graphql-types';
import { RootStackParamList } from '../../navigation/app.navigator';

export const AgencyTermsAndConditionsSignupScreen = (): React.ReactElement => {
  const navigation: any = useNavigation();
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const handleModalClose = (params?: { reloadContext?: boolean }) => {
    navigation.navigate(
      'CommonSwitchAccountScreen',
      params?.reloadContext ? { reloadContext: params.reloadContext } : undefined,
    );
  };

  return (
    <TransparentModal
      title={t('common.signUpAsAnAgency', { defaultValue: 'Sign up as an Agency' })}
      navigateToScreenName={'CommonSwitchAccountScreen' as keyof RootStackParamList}
      fullHeight={false}
    >
      <View style={styles.container}>
        <View>
          <Text style={styles.text}>
            {
              t('signUp.introAgencyText1', {
                defaultValue:
                  'The most innovative logistics agencies use PRIOjet to streamline their processes and manage their time-critical transportation jobs together with their couriers.',
              }) as string
            }
          </Text>
          <Text style={[styles.text, styles.marginTop30]}>
            {
              t('signUp.introAgencyText2', {
                defaultValue:
                  'Talk to PRIOjet to learn more about our platform and how we can help you to better manage your business to save time and money. The future for OBC transportation is digital, mobile, multi-platform and communicates in real-time.',
              }) as string
            }
          </Text>

          <Text category="h6" status="primary" style={[styles.text, styles.marginTop15]}>
            {
              t('signUp.introAgencyText3', {
                defaultValue: 'YOU ARE A LOGISTICS AGENCY?',
              }) as string
            }
          </Text>
          <Text style={[styles.text, styles.marginTop5]}>
            {
              t('signUp.introAgencyText4', {
                defaultValue:
                  'As a logistics agency, you can sign up here and get started right away with your free 14-day trial account (credit card required).',
              }) as string
            }
          </Text>
        </View>
        <CommonTermsAndConditionsSignupComponent
          organizationType={OrganizationType.AGENCY}
          handleModalClose={handleModalClose}
        />
      </View>
    </TransparentModal>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
    marginBottom: 15,
  },
  text: {
    fontSize: 18,
  },
  textWarning: {
    fontSize: 18,
    color: 'color-danger-500',
  },
  marginTop5: {
    marginTop: 5,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop15: {
    marginTop: 15,
  },
  marginTop30: {
    marginTop: 30,
  },
});
