import React from 'react';
import { Platform } from 'react-native';

import { CourierReadUserDocumentComponent } from '../../../components/courier/courier.read-user-document.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';

export const AgencyReadCourierUserDocumentScreen: React.FC<any> = ({ route, navigation }) => {
  const handleBack = () => {
    if (Platform.OS === 'web') {
      if (
        route.params?.previousNavigator &&
        route.params?.previousScreenName &&
        route.params?.previousScreenName &&
        route.params?.userDocumentType
      ) {
        const paramsData: any = {};
        const params: any = route.params;
        ['agencyJobId', 'courierUserId', 'parentScreenName', 'userId'].forEach((key) => {
          if (params?.[key]) {
            paramsData[key] = params?.[key];
          }
        });
        navigation.navigate(route.params?.previousNavigator, {
          screen: route.params?.previousScreenName,
          params: {
            ...paramsData,
            userDocumentType: route.params?.userDocumentType,
            previousNavigator: route.params?.previousNavigator,
          },
        });
      } else {
        navigation.goBack();
      }
    } else {
      navigation.goBack();
    }
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={route.params?.title}
        navigation={navigation}
        onPressLeft={handleBack}
      />
      <CourierReadUserDocumentComponent
        handleBack={handleBack}
        userDocument={route.params?.userDocument}
        userDocumentId={route.params?.userDocumentId}
        userDocumentType={route.params?.userDocumentType as string}
      />
    </>
  );
};
