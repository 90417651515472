import React, { useMemo } from 'react';
import { Platform, View } from 'react-native';

import { Icon, Popover, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TouchableWithoutFeedback } from '@ui-kitten/components/devsupport';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../common/style';

export const DateTimeWithTimeZonesPopover = ({
  label,
  dateTimeString,
  date: dateProp,
  targetTimeZoneIdentifier,
  targetLocationName,
  popoverContentTitle,
  hitSlop = { top: 5, bottom: 5, right: 0, left: 0 },
  iconSize = 16,
}: {
  label?: string;
  dateTimeString?: string;
  date: DateTime;
  targetTimeZoneIdentifier?: string | undefined;
  targetLocationName: string;
  popoverContentTitle: string;
  hitSlop?: undefined | { top: number; bottom: number; right: number; left: number };
  iconSize?: number;
}) => {
  const styles = useStyleSheet(themedStyle);

  const { t, i18n } = useTranslation();
  const [visible, setVisible] = React.useState<boolean>(false);

  const dateTimeUTC = useMemo(() => {
    let d = dateProp;
    if (dateProp && typeof dateProp === 'string') {
      d = DateTime.fromJSDate(new Date(dateProp as any));
    }
    return d.setZone('utc').setLocale(i18n.language);
  }, [dateProp, i18n.language]);

  const togglePopover = (): void => {
    setVisible(!visible);
  };

  const renderPopoverAnchor = () => (
    <TouchableWithoutFeedback
      hitSlop={hitSlop}
      onPress={() => setVisible(!visible)}
      style={globalStyle.flex1}
    >
      {(!!label || !!dateTimeString) && (
        <View style={[globalStyle.flex1, globalStyle.alignItemsStart, globalStyle.flexRow]}>
          <Text style={[globalStyle.fontSize14, globalStyle.lineHeight16]}>
            {label ? label + ' ' : ''}
            {dateTimeString as string}
            <View style={globalStyle.height16}>
              <Icon
                name="clock-outline"
                height={iconSize}
                width={iconSize}
                fill="#ccc"
                style={styles.clockIcon}
              />
            </View>
          </Text>
        </View>
      )}

      {!label && !dateTimeString && (
        <Icon
          name="clock-outline"
          height={iconSize}
          width={iconSize}
          fill="#ccc"
          style={styles.clockIcon}
        />
      )}
    </TouchableWithoutFeedback>
  );

  return (
    <View style={styles.container}>
      <Popover
        placement="bottom"
        contentContainerStyle={styles.popoverContentContainer}
        visible={visible}
        anchor={renderPopoverAnchor}
        onBackdropPress={togglePopover}
        fullWidth={false}
      >
        <View style={styles.padding10}>
          <Text
            style={[
              globalStyle.fontLatoBold,
              globalStyle.paddingVertical2,
              globalStyle.paddingBottom15,
            ]}
          >
            {popoverContentTitle}
          </Text>
          {!!targetTimeZoneIdentifier && (
            <View style={globalStyle.paddingBottom10}>
              <Text style={[globalStyle.fontLatoBold, globalStyle.paddingBottom5]}>
                {
                  t('common.theTimeInLocation', {
                    defaultValue: 'The time in {{locationName}}:',
                    locationName: targetLocationName,
                  }) as string
                }
              </Text>
              <Text style={globalStyle.paddingBottom5}>
                {dateTimeUTC.setZone(targetTimeZoneIdentifier).toFormat('dd MMM yyyy, HH:mm') +
                  ' LT '}
                ({dateTimeUTC.setZone(targetTimeZoneIdentifier).toFormat('ZZZZ')})
              </Text>
            </View>
          )}
          <View style={globalStyle.paddingBottom10}>
            <Text style={[globalStyle.fontLatoBold, globalStyle.paddingBottom5]}>
              {t('common.utcGmtTime', { defaultValue: 'UTC / GMT time:' }) as string}{' '}
            </Text>
            <Text>{dateTimeUTC.toFormat('dd MMM yyyy, HH:mm')}</Text>
          </View>
        </View>
      </Popover>
    </View>
  );
};

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  popoverContentContainer: {
    marginTop: Platform.OS === 'web' ? 10 : -40,
    maxWidth: 200,
  },
  padding10: {
    padding: 10,
  },
  clockIcon: {
    alignSelf: 'baseline',
    alignContent: 'center',
    textAlign: 'baseline',
    marginTop: 2,
    marginLeft: 2,
  },
});
