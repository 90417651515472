import React, { useContext } from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { CommonProfileNavigator } from './common.profile.navigator';
import { AccountActivationIcon, LoginIcon } from '../components/icons.component';
import AppUserContext from '../contexts/AppUserContext';
import { CommonAccountActivationScreen } from '../screens/common/common.account-activation.screen';
import CommonNotificationPermissionsScreen from '../screens/common/CommonNotificationPermissionsScreen';

const BottomTabNavigator = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const { t } = useTranslation();

  const appUserContext = useContext(AppUserContext);
  if (!appUserContext.currentUserContext?.organizationType) {
    return <></>;
  }
  return (
    <BottomNavigation
      selectedIndex={state.index}
      onSelect={(index) => navigation.navigate(state.routeNames[index])}
    >
      <BottomNavigationTab
        title={t('common.activateAccount', {
          defaultValue: 'Activate Account',
        }).toUpperCase()}
        icon={AccountActivationIcon}
      />
      <BottomNavigationTab
        title={t('common.login', { defaultValue: 'Login' }).toUpperCase()}
        icon={LoginIcon}
      />
    </BottomNavigation>
  );
};

const AccountActivationNavigator = () => (
  <BottomTabNavigator.Navigator
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <BottomTabBar {...props} />}
  >
    <BottomTabNavigator.Screen
      name="CommonAccountActivationScreen"
      component={CommonAccountActivationScreen}
    />
    <BottomTabNavigator.Screen name="CommonProfileNavigator" component={CommonProfileNavigator} />
    <BottomTabNavigator.Screen
      name="CommonNotificationPermissionsScreen"
      component={CommonNotificationPermissionsScreen}
      initialParams={{ hideSettings: true }}
    />
  </BottomTabNavigator.Navigator>
);

export { AccountActivationNavigator };
