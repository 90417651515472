import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Localization from 'expo-localization';
import i18next, { InitOptions } from 'i18next';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { initReactI18next } from 'react-i18next';

import { GLOBAL_CONSTANTS } from '../globals';
import { default as deTranslations } from '../i18n/de.translations';
import { default as enTranslations } from '../i18n/en.translations';
import { default as esTranslations } from '../i18n/es.translations';
import { default as frTranslations } from '../i18n/fr.translations';

const i18n: any = i18next;

export const languageDetector = {
  type: 'languageDetector' as const,
  async: true, // flags below detection to be async
  detect: async (callback: (language: string) => void) => {
    // Get the user's language setting.
    const appLanguage = await AsyncStorage.getItem(GLOBAL_CONSTANTS.APP_LANGUAGE_KEY);
    if (appLanguage) {
      return callback(appLanguage);
    }
    // Otherwise get it from the current locale (browser or app)
    return Localization.getLocalizationAsync().then(({ locale }) => {
      callback(locale);
    });
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export const i18nInitOptions = (lng?: string): InitOptions => {
  return {
    resources: {
      en: enTranslations,
      de: deTranslations,
      es: esTranslations,
      fr: frTranslations,
    },
    lng,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value: any, format: string, _lng: string) => {
        if (value instanceof Date) {
          const dateTimeFormatOption: DateTimeFormatOptions | undefined = DateTime[
            format as keyof typeof DateTime
          ] as DateTimeFormatOptions;
          return DateTime.fromJSDate(value).setLocale(_lng).toLocaleString(dateTimeFormatOption);
        }
        return value;
      },
    },
    cleanCode: true,
  } as InitOptions;
};

i18n.use(languageDetector).use(initReactI18next).init(i18nInitOptions);

export const getI18nInstance = (lng = 'en'): typeof i18n => {
  const _i18n = i18n
    .use(initReactI18next)
    .createInstance(i18nInitOptions(lng), (err: any, _t: any) => {
      if (err) {
        return console.warn('something went wrong with getI18nInstance', err);
      }
    });
  return _i18n;
};

export default i18n;
