import { useContext, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { useQuery } from '@apollo/client';
import { useIsFocused } from '@react-navigation/core';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import {
  LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
  NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
} from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
  OrganizationType,
  Notification,
} from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import {
  getInitialFilterNotificationType,
  setInitialFilterNotificationType,
  getInitialEmptyFilterNotificationType,
} from '../../../screens/common/common.activity.screen';

export const useHook = ({ refreshing }: { refreshing: boolean }) => {
  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const navigation: any = useNavigation();

  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);

  const [activityModalOpened, setActivityModalOpened] = useState(false);
  const [filterChanged, setFilterChanged] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(undefined);
  const [initialFilter] = useState<any>(
    getInitialEmptyFilterNotificationType(
      appUserContext.currentUserContext?.organizationType as OrganizationType,
    ),
  );
  const [isVisible, setIsVisible] = useState(false);
  const [opened, setOpened] = useState(false);

  const loadFilterFromStorage = async () => {
    const data = await getInitialFilterNotificationType(
      appUserContext.currentUserContext?.organizationType as OrganizationType,
    );
    setFilter(data);
  };

  const { data, loading, refetch } = useQuery<{
    latestOrNewSystemNotificationsForOrganizationUser: Notification[];
  }>(LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER, {
    variables: {
      organizationUserId: appUserContext.currentUserContext?.organizationUser?.id as string,
      filterType: filter,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-only',
    pollInterval: isBackendReachable && isFocused ? 30 * 1000 : 0,
    skip: !filter || !isFocused,
    onCompleted: () => {
      setFilterChanged(false);
    },
  });

  const [
    markAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    { loading: loadingMarkAll },
  ] = useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation({
    refetchQueries: [
      LATEST_OR_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
      NUMBER_OF_NEW_SYSTEM_NOTIFICATIONS_FOR_ORGANIZATION_USER,
    ],
  });

  const onSelectFilter = (value: any) => {
    setFilter((prev: any) => {
      let values: any = [...prev];
      if (Platform.OS !== 'web') {
        values = value;
      } else {
        if (value === 'ALL') {
          if (values.length === initialFilter.length) {
            values = [];
          } else {
            values = initialFilter;
          }
        } else {
          if (values.includes(value)) {
            values = values.filter((item: any) => item !== value);
          } else {
            values = [...values, value];
          }
        }
      }
      setInitialFilterNotificationType(
        appUserContext.currentUserContext?.organizationType as OrganizationType,
        values,
      );
      return values;
    });

    setFilterChanged(true);
  };

  const handleOpenFilterPress = () => {
    setOpened(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  };

  const handleCloseFilterPress = () => {
    setIsVisible(false);
    setTimeout(() => {
      setOpened(false);
    }, 200);
  };

  const handleMarkAllPress = () => {
    markAllNewSystemNotificationsAsReadForOrganizationUserMutation({
      variables: {
        organizationUserId: appUserContext.currentUserContext?.organizationUser?.id as string,
      },
    });
  };

  const handlesSeeAllPress = () => {
    setActivityModalOpened(true);
    navigation.navigate('CommonActivityScreen');
  };

  const { numOfUnreadNotifications, totalNumOfNotifications } = useMemo(() => {
    if (data?.latestOrNewSystemNotificationsForOrganizationUser?.length) {
      const unread = data.latestOrNewSystemNotificationsForOrganizationUser.filter(
        (n) => !n.markedAsReadAt,
      ).length;
      return {
        numOfUnreadNotifications: unread,
        totalNumOfNotifications: data.latestOrNewSystemNotificationsForOrganizationUser.length,
      };
    }
    return { numOfUnreadNotifications: 0, totalNumOfNotifications: 0 };
  }, [data?.latestOrNewSystemNotificationsForOrganizationUser]);

  const notificationsData = useMemo(() => {
    if (data?.latestOrNewSystemNotificationsForOrganizationUser?.length) {
      const max = numOfUnreadNotifications > 3 ? 5 : 4;
      return data?.latestOrNewSystemNotificationsForOrganizationUser?.slice(0, max);
    }
    return [];
  }, [data?.latestOrNewSystemNotificationsForOrganizationUser, numOfUnreadNotifications]);

  const getActivityBoxTitle = () => {
    let title = t('activityNotification.noRecentActivity', {
      defaultValue: 'Recent activity',
    }) as string;

    if (numOfUnreadNotifications > 0) {
      title = t('activityNotification.newActivity', { defaultValue: 'New Activity' }) as string;
    } else if (totalNumOfNotifications > 0) {
      title = t('activityNotification.recentActivity', {
        defaultValue: 'Recent Activity ({{displayed}}/{{total}})',
        displayed: Math.min(3, totalNumOfNotifications),
        total: totalNumOfNotifications,
      }) as string;
    }
    return title.toUpperCase();
  };

  useEffect(() => {
    if (appUserContext.currentUserContext?.organizationType && !filter) {
      loadFilterFromStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserContext.currentUserContext?.organizationType, filter, isFocused]);

  useEffect(() => {
    if (isFocused && activityModalOpened) {
      setActivityModalOpened(false);
      loadFilterFromStorage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityModalOpened, isFocused]);

  useEffect(() => {
    if (refreshing) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing]);

  return {
    data,
    filter: filter || [],
    filterChanged,
    getActivityBoxTitle,
    handleCloseFilterPress,
    handleMarkAllPress,
    handleOpenFilterPress,
    handlesSeeAllPress,
    initialFilter,
    isVisible,
    loading,
    loadingMarkAll,
    notificationsData,
    numOfUnreadNotifications,
    opened,
    onSelectFilter,
  };
};
