import React, { useCallback, useEffect, useState } from 'react';
import { ImageProps, StyleProp, View, ViewStyle } from 'react-native';

import { StyleService } from '@ui-kitten/components';
import { EvaSize, EvaStatus, RenderProp } from '@ui-kitten/components/devsupport';
import { Button, Spinner, Text } from '@ui-kitten/components/ui';

type StandardButtonState = {
  isActive: boolean;
  text?: string;
  style?: StyleProp<ViewStyle>;
  appearance?: 'ghost' | 'filled' | 'outline';
  status?: EvaStatus;
  onPress?: ((props?: any) => Promise<void>) | ((props?: any) => void);
  context?: { [key: string]: string | number | boolean | Date };
  accessoryLeft?: RenderProp<Partial<ImageProps>> | undefined;
};

export type StandardButtonStates = {
  on?: StandardButtonState;
  off?: StandardButtonState;
  disabled?: StandardButtonState;
  pending?: StandardButtonState;
  success?: StandardButtonState;
  loaded?: StandardButtonState;
  error?: StandardButtonState;
};

const StandardButton = (props: {
  states: StandardButtonStates;
  isLoading: boolean;
  style?: StyleProp<ViewStyle>;
  size?: EvaSize;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError] = useState<boolean>();
  const [stateName, setStateName] = useState<keyof StandardButtonStates>('on');
  const [currentState, setCurrentState] = useState<StandardButtonState>();
  const [states, setStates] = useState<StandardButtonStates>(props.states);

  const LoadingIndicator = (state?: StandardButtonState | undefined) => {
    let status: EvaStatus;
    if (
      !state ||
      (state &&
        (state.appearance === 'filled' || state.status === undefined || state.status !== 'primary'))
    ) {
      status = 'basic';
    } else {
      status = 'primary';
    }

    return (
      <View style={themedStyle.loadingContainer}>
        <Spinner size="tiny" status={status} />
      </View>
    );
  };

  const determineCurrentState = useCallback(() => {
    if (states && stateName) {
      const _state: StandardButtonState = states[stateName] as StandardButtonState;
      if (_state) {
        if (stateName == 'pending') {
          if (!_state.appearance) {
            _state.appearance = 'filled';
            _state.text = _state.text ?? (states.on?.text || states.off?.text) + ' (pending)';
          }
        } else if (stateName == 'disabled') {
          if (!_state.appearance) {
            _state.appearance = 'filled';
          }
        } else {
          if (!_state.appearance) {
            _state.appearance = 'ghost';
          }
        }
        setCurrentState(_state);
      }
    }
  }, [stateName]);

  const determineStateName = useCallback(() => {
    if (states) {
      if (states.disabled?.isActive) {
        setStateName('disabled');
      } else if (states.error?.isActive) {
        setStateName('error');
      } else if (states.pending?.isActive) {
        setStateName('pending');
      } else if (states.loaded?.isActive) {
        setStateName('loaded');
      } else if (states.success?.isActive) {
        setStateName('success');
      } else if (states.off?.isActive) {
        setStateName('off');
      } else {
        setStateName('on');
      }
    }
  }, [states]);

  useEffect(() => {
    determineCurrentState();
  }, [stateName]);

  useEffect(() => {
    determineStateName();
  }, [states]);

  useEffect(() => {
    setStates(props.states);
  }, [props.states]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  if (!currentState) {
    return (
      <Button accessoryLeft={LoadingIndicator()} size={props.size} style={props.style}>
        Loading ...
      </Button>
    );
  }

  return (
    <Button
      size={props.size}
      style={[props.style, currentState.style]}
      appearance={currentState.appearance}
      onPress={() => {
        const func = async () => {
          if (currentState.onPress) {
            await currentState.onPress();
          }
        };
        func();
      }}
      disabled={stateName === 'disabled'}
      accessoryLeft={isLoading ? LoadingIndicator(currentState) : currentState.accessoryLeft}
      status={currentState.status || 'primary'}
    >
      {(evaProps) => (
        <Text selectable={true} {...evaProps}>
          {currentState.text || '(no text)'}
          {isLoading ? '...' : ''}
          {isError ? ' (error)' : ''}
        </Text>
      )}
    </Button>
  );
};

export default StandardButton;

const themedStyle = StyleService.create({
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
  },
});
