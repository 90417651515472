import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  progressBarContainer: {
    borderWidth: 1,
    height: 20,
    borderRadius: 3,
  },
  progressBar: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    backgroundColor: 'green',
  },
});
