import React from 'react';
import { ScrollView, View, Platform } from 'react-native';

import { Button, Card, Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import UpgradePlanModal from './UpgradePlanModal';
import { globalStyle } from '../../../../common/style';
import {
  OrganizationSubscriptionPlan,
  SubscriptionPlanPrice,
} from '../../../../generated-graphql-types';

export const CommonCourierAccountSettingsWeb = (): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    handleBookSuccess,
    handleBookToggle,
    handleCancelPress,
    handleToggleUpgradeModalPress,
    organizationSubscriptionPlan,
    refetchOrganizationSubscriptionPlans,
    isUpgradeButtonAvailable,
    upgradeModalVisible,
  } = useHook();

  const renderHeader = (title: string) => () => (
    <Text category="h5" style={styles.planTitle}>
      {title}
    </Text>
  );

  const renderFooter = (item: OrganizationSubscriptionPlan) => () => {
    if (item.status === 'deleted' || item.status === 'cancelled') {
      return <></>;
    }
    return (
      <View style={styles.footerContainer}>
        <View style={[globalStyle.alignItemsStart, globalStyle.justifyContentCenter]}>
          <Button appearance="filled" size="tiny" status="danger" onPress={handleCancelPress(item)}>
            {t('common.cancelSubscription', { defaultValue: 'Cancel subscription' }) as string}
          </Button>
        </View>
        <View style={globalStyle.flex1} />
      </View>
    );
  };

  const renderMainPlan = () => {
    if (!organizationSubscriptionPlan) {
      return <></>;
    }

    const { recurringInterval, subscriptionPlan, prices, status } = organizationSubscriptionPlan;

    const title = t(subscriptionPlan.planName.key, {
      defaultValue: subscriptionPlan.planName.defaultValue,
    });
    let price;
    if (prices?.length) {
      price = prices.find((priceItem: SubscriptionPlanPrice) => priceItem?.currency === 'EUR');
    }
    const dateFormatted = organizationSubscriptionPlan.endTime.toUTC().toFormat('MMMM dd, yyyy');

    let subscriptionType = '';
    if (recurringInterval === 'month') {
      subscriptionType = t(`common.monthlySubscription`, { defaultValue: 'Monthly subscription' });
    } else if (recurringInterval === 'year') {
      subscriptionType = t(`common.annualSubscription`, { defaultValue: 'Annual subscription' });
    }

    let subscriptionStatus = t('common.statusActive', { defaultValue: 'Active' });
    if (status === 'trialing') {
      subscriptionStatus = t('common.trialPeriod', { defaultValue: '14 day trial period' });
    } else if (status === 'deleted') {
      subscriptionStatus = t('common.statusCancelled', { defaultValue: 'Cancelled' });
    } else if (status === 'cancelled') {
      subscriptionStatus = t('common.statusCancelled', { defaultValue: 'Cancelled' });
    }

    let dateText = t('common.nextBillingDate', {
      defaultValue: 'Your next billing date is {{date}}',
      date: dateFormatted,
    });
    if (status === 'deleted') {
      dateText = t('common.subscriptionValidUntil', {
        defaultValue: 'Valid until {{date}}',
        date: dateFormatted,
      });
    }
    if (status === 'cancelled') {
      dateText = t('common.subscriptionAccessUntilDate', {
        defaultValue: 'Access until {{date}}',
        date: dateFormatted,
      });
    }

    const showButton = status !== 'cancelled' && status !== 'deleted';
    const showDetails = !!price;
    return (
      <Card
        style={styles.card}
        key={organizationSubscriptionPlan.id}
        header={renderHeader(title)}
        footer={showDetails ? renderFooter(organizationSubscriptionPlan) : undefined}
      >
        <View style={styles.cardContent}>
          {!!subscriptionStatus && (
            <Text style={[globalStyle.marginTop5, globalStyle.fontLatoBold]}>
              {subscriptionStatus}
            </Text>
          )}
          {!!subscriptionType && showDetails && (
            <Text style={globalStyle.fontLatoBold}>{subscriptionType}</Text>
          )}
          {!!recurringInterval && !!price?.currency && (
            <Text style={globalStyle.marginTop2}>
              {price.currency} {price.value} / {price.recurringInterval || recurringInterval} (
              {t('common.taxExcluded', {
                defaultValue: 'tax excluded',
              })}
              )
            </Text>
          )}
          {!!showDetails && <Text style={globalStyle.marginTop2}>{dateText}</Text>}

          {!showButton && showDetails && (
            <View style={[globalStyle.flexRow, globalStyle.marginTop10]}>
              <Icon
                name="alert-triangle-outline"
                fill={(styles.warningIcon as any).color}
                height={22}
                width={22}
              />
              <Text style={[globalStyle.fontSize14, globalStyle.marginLeft5]}>
                {t('common.subscriptionMainCancelledCourierWarning', {
                  defaultValue:
                    'When your subscription ends, your account will go back to the free plan. Until then, you will enjoy the features of this plan.',
                })}
              </Text>
            </View>
          )}
        </View>
      </Card>
    );
  };

  return (
    <>
      <View style={styles.memberSinceContainer}>
        <Text
          style={[globalStyle.textAlignCenter, globalStyle.fontSize16, globalStyle.fontLatoBold]}
        >
          {t('common.memberSince', {
            defaultValue: 'Member since {{date}}',
            date: organizationSubscriptionPlan?.startTime?.toFormat('MMMM yyyy'),
          })}
        </Text>
      </View>
      <ScrollView contentContainerStyle={globalStyle.paddingBottom30}>
        <Text
          style={[
            globalStyle.fontSize16,
            globalStyle.textAlignCenter,
            globalStyle.marginTop10,
            globalStyle.marginBottom10,
          ]}
        >
          {t('common.courierPlanDetails', { defaultValue: 'Courier plan details' }).toUpperCase()}
        </Text>
        <View
          style={[
            globalStyle.flexRow,
            globalStyle.flexWrap,
            globalStyle.alignItemsStart,
            globalStyle.justifyContentCenter,
            globalStyle.width100Percent,
            globalStyle.paddingHorizontal10,
          ]}
        >
          {renderMainPlan()}
        </View>

        {Platform.OS === 'web' && isUpgradeButtonAvailable && (
          <View style={styles.buttonsContainer}>
            <Button
              appearance="filled"
              onPress={handleToggleUpgradeModalPress(true)}
              style={[globalStyle.marginLeft10, globalStyle.marginTop15]}
            >
              {
                t('common.upgradeMySubscription', {
                  defaultValue: 'Upgrade my subscription',
                }) as string
              }
            </Button>
          </View>
        )}
      </ScrollView>

      <UpgradePlanModal
        onClose={handleToggleUpgradeModalPress(false)}
        onBookToggle={handleBookToggle}
        onBookSuccess={handleBookSuccess}
        refetchOrganizationSubscriptionPlans={refetchOrganizationSubscriptionPlans}
        visibleModal={upgradeModalVisible}
      />
    </>
  );
};
