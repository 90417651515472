import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  saveBtn: {
    height: 44,
    width: 80,
  },

  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  nameContainer: {
    margin: 10,
    paddingLeft: 10,
    paddingTop: 10,
    flex: 1,
    paddingRight: 10,
  },
  nameText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 24,
  },

  editAvatarRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  chooseImageBtn: {
    padding: 10,
    margin: 2,
    marginTop: 0,
  },
  editAvatarButton: {
    aspectRatio: 1.0,
    height: 48,
    margin: 2,
  },
  avatar: {
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: 20,
    marginBottom: 10,
    width: 150,
    height: 150,
    borderRadius: 75,
  },
  emailText: {
    padding: 3,
    paddingLeft: 0,
  },
  inputElement: {
    marginBottom: 15,
  },
});
