import React, { useContext, useLayoutEffect } from 'react';
import { ColorValue, Image, Platform, StatusBar, View } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import Constants from 'expo-constants';
import { useTranslation } from 'react-i18next';

import AppContext from '../contexts/AppContext';
import ThemeContext from '../contexts/ThemeContext';
import { useIsBackendReachable } from '../hooks/useIsBackendReachable';

const ConnectionStatusBar = () => {
  const appContext = React.useContext(AppContext);
  const styles = useStyleSheet(themedStyles);
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const [text, setText] = React.useState<string>('');

  const isBackendReachable = useIsBackendReachable();

  const MyStatusBar = ({
    backgroundColor,
    ...props
  }: {
    backgroundColor: ColorValue | undefined;
  }) => {
    return <StatusBar translucent {...props} backgroundColor={backgroundColor} />;
  };

  React.useEffect(() => {
    if (
      !appContext ||
      !appContext.connectionState ||
      appContext?.isBackendReachable === undefined
    ) {
      return;
    }

    let _text = '';
    switch (appContext?.connectionState?.isConnected) {
      case true:
        _text = _text + ' CONNECTED: true; ';
        break;
      default:
        _text = _text + ' CONNECTED: false; ';
        break;
    }
    switch (appContext?.connectionState?.isInternetReachable) {
      case true:
        _text = _text + ' INTERNET: true; ';
        break;
      default:
        _text = _text + ' INTERNET: false; ';
        break;
    }

    switch (isBackendReachable) {
      case true:
        _text = _text + ' BACKEND: true; ';
        break;
      default:
        _text = _text + ' BACKEND: false; ';
        break;
    }
    // 'You are connected to the Internet and the Priojet Server are reachable.';
    switch (appContext?.connectionState?.type) {
      case 'unknown':
        _text = _text + ' (unknown connection type)';
        break;
      default:
        _text = _text + ' over ' + appContext.connectionState?.type;
        break;
    }
    setText(_text);
  }, [appContext?.connectionState, appContext?.isBackendReachable]);

  const NoConnectionBarProduction = () => (
    <View style={styles.noConnection}>
      <MyStatusBar backgroundColor={styles.barStyle.backgroundColor} />
      <Text selectable={true} style={styles.text}>
        {
          t('common.statusBarReconnecting', {
            defaultValue: 'STATUS_BAR_RECONNECTING',
          }) as string
        }
      </Text>
      <Image
        style={{ height: 15, width: 100, marginLeft: 10, marginTop: 2 }}
        source={require('../assets/images/heartbeat.gif')}
      />
    </View>
  );

  const NoConnectionBar = () =>
    process.env.EXPO_APP_ENV === 'production' ? (
      <NoConnectionBarProduction />
    ) : (
      <View style={styles.noConnection}>
        <MyStatusBar backgroundColor={styles.barStyle.backgroundColor} />
        <Text selectable={true} style={styles.text}>
          {text}
        </Text>
      </View>
    );

  const NoInternetBar = () =>
    process.env.EXPO_APP_ENV === 'production' ? (
      <NoConnectionBarProduction />
    ) : (
      <View style={styles.noConnection}>
        <MyStatusBar backgroundColor={styles.barStyle.backgroundColor} />
        <Text selectable={true} style={styles.text}>
          {text}
        </Text>
      </View>
    );

  const BackendNotReachableBar = () =>
    process.env.EXPO_APP_ENV === 'production' ? (
      <NoConnectionBarProduction />
    ) : (
      <View style={styles.noConnection}>
        <MyStatusBar backgroundColor={styles.barStyle.backgroundColor} />
        <Text selectable={true} style={styles.text}>
          {
            t('common.statusBarApiNotReachable', {
              apiEndpoint: Constants.expoConfig?.extra?.priojetBackendEndpoint,
              defaultValue: 'API {{apiEndpoint}} is not reachable ...',
            }) as string
          }
        </Text>
        <Image
          style={{ height: 15, width: 100, marginLeft: 10, marginTop: 2 }}
          source={require('../assets/images/heartbeat.gif')}
        />
      </View>
    );

  useLayoutEffect(() => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(theme.backgroundColor);
    }
    StatusBar.setBarStyle(theme.theme === 'dark' ? 'light-content' : 'dark-content');
  }, [theme]);

  if (!appContext?.connectionState?.isConnected) {
    return <NoConnectionBar />;
  }
  if (!appContext?.connectionState?.isInternetReachable) {
    return <NoInternetBar />;
  }
  if (!appContext?.isBackendReachable) {
    return <BackendNotReachableBar />;
  }

  return <MyStatusBar backgroundColor={theme.backgroundColor} />;
};

const themedStyles = StyleService.create({
  barStyle: {
    backgroundColor: 'color-danger-600',
  },
  text: {
    color: 'white',
    padding: 2,
    textAlign: 'center',
    fontSize: 12,
  },
  noConnection: {
    backgroundColor: 'color-danger-600',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default ConnectionStatusBar;
