import React from 'react';
import { View } from 'react-native';

import { Button, Card, Text, useStyleSheet, Divider, Icon } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { CheckBox } from 'react-native-elements';

import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import { IconWithTextPopover } from '../../../../components/common/IconWithTextPopover';
import {
  AvailableCourierOrganizationUsersWithDistance,
  CourierJobAgencyRelationshipType,
  AvailabilityLocationWithDistance,
  AgencyJob,
} from '../../../../generated-graphql-types';

interface Props {
  agencyJob: AgencyJob;
  availability: AvailableCourierOrganizationUsersWithDistance;
  onProfilePress: (item: AvailableCourierOrganizationUsersWithDistance) => () => void;
  onSelect: () => void;
  selected: boolean;
}

export const AgencyAddOrDisableCourierCard: React.FC<Props> = ({
  agencyJob,
  availability,
  onProfilePress,
  onSelect,
  selected,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);

  const renderItemHeader = (headerProps: any) => {
    const displayAvailabilityLocationWithDistance =
      availability?.displayAvailabilityLocationWithDistance;
    return (
      <View
        {...headerProps}
        style={[
          globalStyle.padding10,
          globalStyle.paddingBottom5,
          globalStyle.flexRow,
          styles.header,
        ]}
        key={'header' + availability.user.id}
      >
        <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.justifyContentBetween]}>
          <Text style={globalStyle.fontLatoBold}>
            {displayAvailabilityLocationWithDistance?.distance || '-'} km,{' '}
            {
              displayAvailabilityLocationWithDistance?.availabilityLocation.location
                .formattedShortAddress as string
            }
          </Text>
        </View>
        <View>
          <CheckBox
            checked={selected}
            containerStyle={[globalStyle.margin0, globalStyle.padding0]}
            onPress={onSelect}
          />
        </View>
      </View>
    );
  };

  const renderItemFooter = (footerProps: any) => {
    let itemFooterStyle = styles.relationshipTypeUndefined;
    if (
      availability.courierJob?.relationshipType ===
        CourierJobAgencyRelationshipType.AGENCY_ACCEPTED ||
      availability.courierJob?.relationshipType ===
        CourierJobAgencyRelationshipType.COURIER_ACCEPTED
    ) {
      itemFooterStyle = styles.relationshipTypeConfirmed;
    }
    if (
      availability.courierJob?.relationshipType ===
        CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
      availability.courierJob?.relationshipType ===
        CourierJobAgencyRelationshipType.COURIER_DECLINED
    ) {
      itemFooterStyle = styles.relationshipTypeDeclined;
    }
    if (
      availability.courierJob?.relationshipType ===
        CourierJobAgencyRelationshipType.AGENCY_REQUESTED ||
      availability.courierJob?.relationshipType ===
        CourierJobAgencyRelationshipType.COURIER_REQUESTED
    ) {
      itemFooterStyle = styles.relationshipTypeRequested;
    }
    return (
      <View
        {...footerProps}
        style={[
          globalStyle.flex1,
          globalStyle.flexRow,
          globalStyle.justifyContentBetween,
          globalStyle.alignItemsCenter,
          globalStyle.paddingHorizontal10,
          styles.footer,
        ]}
        key={'footer' + availability.user.id}
      >
        <View style={globalStyle.flex1}>
          {!!availability.courierJob?.relationshipType && (
            <View style={styles.relationshipType}>
              <View style={[styles.relationShipTypeIcon, itemFooterStyle]} />

              <Text style={globalStyle.fontLatoBold}>
                {availability.courierJob?.relationshipType !== undefined
                  ? CourierJobAgencyRelationshipType[
                      availability.courierJob?.relationshipType
                    ].replace('_', ' ')
                  : 'UNKNOWN'}
              </Text>
            </View>
          )}
        </View>

        <View style={[globalStyle.flex1, globalStyle.alignItemsEnd]}>
          <Button
            style={styles.maxWidth100}
            size="tiny"
            appearance="outline"
            onPress={onProfilePress(availability)}
          >
            <Text>Profile</Text>
          </Button>
        </View>
      </View>
    );
  };

  const renderAvailabilityIcon = (
    item: AvailabilityLocationWithDistance,
    isLastLocation: boolean,
  ) => {
    if (isLastLocation) {
      return <View style={styles.currentlyUnavailable} />;
    }
    if (item.groupType === 'now') {
      if (item.availabilityLocation.availability.isAdHocAvailability) {
        return <View style={styles.currentlyAvailable} />;
      }
      return <Icon name="calendar-outline" fill="#ccc" style={globalStyle.size16} />;
    } else if (item.groupType === 'match') {
      return (
        <Icon
          name="checkmark-outline"
          fill={styles.currentlyAvailable.backgroundColor}
          style={globalStyle.size16}
        />
      );
    }
    if (item.groupType === 'other') {
      return <Icon name="calendar-outline" fill="#ccc" style={globalStyle.size16} />;
    }

    return <View style={styles.currentlyUnavailable} />;
  };

  const renderAvailabilityLabel = (
    item: AvailabilityLocationWithDistance,
    isLastLocation: boolean,
  ) => {
    const { endTime, startTime } = item.availabilityLocation.availability;
    let difference = 0;

    if (isLastLocation && endTime) {
      difference = Math.round(DateTime.now().diff(endTime).as('days'));
      return (
        <View style={styles.unavailableDayLabel}>
          <Text style={[globalStyle.fontSize12, globalStyle.fontItalic]}>
            {difference === 1 || difference === -1
              ? t('availabilityLabelDaySingle', {
                  defaultValue: '{{difference}} day ago',
                  difference,
                })
              : t('availabilityLabelDayMultiple', {
                  defaultValue: '{{difference}} days ago',
                  difference,
                })}
          </Text>
        </View>
      );
    }

    if (item.groupType === 'now') {
      return (
        <View style={styles.matchLabel}>
          <Text style={[globalStyle.fontSize12, globalStyle.fontItalic]}>
            {t('Now', { defaultValue: 'Now' })}!
          </Text>
        </View>
      );
    } else if (item.groupType === 'match') {
      return (
        <View style={styles.matchLabel}>
          <Text style={[globalStyle.fontSize11, globalStyle.fontItalic]}>
            {t('match', { defaultValue: 'Match' })}!
          </Text>
        </View>
      );
    }
    if (agencyJob?.job?.pickupTime) {
      if (startTime.diff(agencyJob.job?.pickupTime).toMillis() > 0) {
        difference = Math.round(startTime.diff(agencyJob.job?.pickupTime).as('days'));
      } else if (endTime && endTime.diff(agencyJob.job?.pickupTime).toMillis() < 0) {
        difference = Math.round(endTime.diff(agencyJob.job?.pickupTime).as('days'));
      }
    }
    return (
      <View style={difference < 0 ? styles.negativeDayLabel : styles.positiveDayLabel}>
        <Text
          style={[
            globalStyle.fontSize12,
            globalStyle.lineHeight12,
            globalStyle.fontItalic,
            difference >= 0 && styles.textWhite,
          ]}
        >
          {difference === 1 || difference === -1
            ? t('availabilityLabelDaySingle', { defaultValue: '{{difference}} day', difference })
            : t('availabilityLabelDayMultiple', {
                defaultValue: '{{difference}} days',
                difference: difference > 0 ? `+${difference}` : difference,
              })}
        </Text>
      </View>
    );
  };

  const renderAvailabilityLocation =
    (isLastLocation: boolean) => (item: AvailabilityLocationWithDistance) => (
      <View
        style={[globalStyle.width100Percent, globalStyle.flexRow, styles.paddingBottom8]}
        key={`availability-${item.availabilityLocation.id}-${(Math.random() * 1000).toString()}`}
      >
        <View>{renderAvailabilityIcon(item, isLastLocation)}</View>
        <View style={[globalStyle.flex1, globalStyle.alignContentStart, globalStyle.marginLeft5]}>
          <View
            style={[
              globalStyle.flex1,
              globalStyle.flexRow,
              globalStyle.flexWrap,
              globalStyle.justifyContentStart,
              globalStyle.alignItemsCenter,
            ]}
          >
            <Text
              style={[globalStyle.fontSize14, globalStyle.lineHeight16, globalStyle.marginRight5]}
            >
              {item.availabilityLocation.location[
                item.availabilityLocation.availability.isAdHocAvailability
                  ? 'formattedMinimalAddress'
                  : 'formattedAddress'
              ] || ('NO SHORT ADDRESS' as string)}{' '}
              ({item.distance} km)
            </Text>
            {renderAvailabilityLabel(item, isLastLocation)}
          </View>
          <Text style={[globalStyle.fontSize14, globalStyle.lineHeight16, globalStyle.fontItalic]}>
            {item.availabilityLocation.availability.startTime ? (
              item.availabilityLocation.availability.startTime?.toFormat('dd.MMM., HH:mm')
            ) : (
              <></>
            )}
            {item.availabilityLocation.availability.startTime &&
            item.availabilityLocation.availability.endTime ? (
              ' - '
            ) : (
              <></>
            )}
            {item.availabilityLocation.availability.endTime ? (
              item.availabilityLocation.availability.endTime?.toFormat('dd.MMM., HH:mm')
            ) : (
              <></>
            )}
            {item.availabilityLocation.availability.startTime ||
            item.availabilityLocation.availability.endTime ? (
              ' LT'
            ) : (
              <></>
            )}
          </Text>
        </View>
      </View>
    );

  const user = availability?.user;
  const warningText = user.agencyCourierUserProfile?.internalComment;
  const showWarning = !!user.agencyCourierUserProfile?.markedForAttention && !!warningText;
  const category = user?.agencyCourierUserProfile?.organizationCourierCategories?.[0];
  const lastAvailabilityLocationWithDistance = availability.lastAvailabilityLocationWithDistance;
  return (
    <Card
      key={'card-' + availability.user.id}
      header={renderItemHeader}
      footer={renderItemFooter}
      style={styles.card}
    >
      <View style={globalStyle.padding10}>
        <View
          style={[globalStyle.flexRow, globalStyle.alignItemsCenter, globalStyle.paddingBottom5]}
        >
          {showWarning && (
            <View style={globalStyle.marginRight10}>
              <IconWithTextPopover text={warningText || ''} />
            </View>
          )}
          <Text style={[globalStyle.fontLatoBold, globalStyle.fontSize16]}>
            {user.firstNames} {user.lastName}
          </Text>
        </View>
        {/* <Text style={[globalStyle.fontLatoBold, globalStyle.fontSize16]}>{user.email}</Text> */}
        {!!user?.courierUserProfile?.baseAirports?.length && (
          <View style={[globalStyle.flexRow, globalStyle.paddingBottom5]}>
            <Text>{t('common.baseAirports', { defaultValue: 'Base airports' })}: </Text>
            <Text selectable={true}>{user?.courierUserProfile?.baseAirports.join(', ')}</Text>
          </View>
        )}
        {!!category?.label && (
          <Text>
            <Text style={globalStyle.fontLatoBold}>[{category.order}]</Text> {category.label}
          </Text>
        )}
      </View>
      <Divider
        style={[
          globalStyle.backgroundColorBasic4,
          globalStyle.marginTop5,
          globalStyle.marginBottom15,
        ]}
      />

      {!!lastAvailabilityLocationWithDistance && (
        <View style={[globalStyle.padding10, globalStyle.paddingTop0]}>
          <Text
            style={[
              globalStyle.fontLatoBold,
              globalStyle.marginBottom2,
              globalStyle.fontSize13,
              globalStyle.textTransformUppercase,
            ]}
          >
            {t('common.lastRealTimeLocation', { defaultValue: 'Last real-time location' })}:
          </Text>

          {renderAvailabilityLocation(true)(lastAvailabilityLocationWithDistance)}
        </View>
      )}
      {!!availability.availabilityLocationsWithDistance.length && (
        <View style={[globalStyle.padding10, globalStyle.paddingTop0, globalStyle.paddingBottom5]}>
          <Text
            style={[
              globalStyle.fontLatoBold,
              globalStyle.marginBottom5,
              globalStyle.fontSize13,
              globalStyle.textTransformUppercase,
            ]}
          >
            {t('common.availabilities', { defaultValue: 'Availabilities' })}:
          </Text>
          {availability.availabilityLocationsWithDistance.map(renderAvailabilityLocation(false))}
        </View>
      )}
    </Card>
  );
};
