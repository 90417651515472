import { useContext, useRef, useState, useEffect, useMemo } from 'react';
import { Animated } from 'react-native';

import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { useIsFocused } from '@react-navigation/native';
import { DateTime } from 'luxon';

import { QUERY_AVAILABILITY } from '../../../../../apollo/graphql-queries';
import AppUserContext from '../../../../../contexts/AppUserContext';
import { useIsBackendReachable } from '../../../../../hooks/useIsBackendReachable';
import { AvailabilityMapType } from '../../types';

export const useHook = ({
  onResetAvailability,
  onSelectAvailability,
  selectedAvailabilities,
}: {
  onResetAvailability: () => void;
  onSelectAvailability: (availability: AvailabilityMapType) => void;
  selectedAvailabilities?: AvailabilityMapType[];
}) => {
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const navigation: any = useNavigation();
  const isFocused = useIsFocused();

  const [loadAvailabilities, { loading }] = useLazyQuery(QUERY_AVAILABILITY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'network-only' : 'cache-first',
  });

  const transform = useRef(new Animated.Value(0)).current;
  const [availabilities, setAvailabilities] = useState<AvailabilityMapType[]>([]);
  const [availabilitiesIds, setAvailabilitiesIds] = useState<string[]>([]);
  const [dateScreenChanged, setDateScreenChanged] = useState<DateTime | undefined>(undefined);
  const [selectedAvailabilityIndex, setSelectedAvailabilityIndex] = useState<number | undefined>();

  const fetchSelectedAvailabilityDetails = async () => {
    if (selectedAvailabilities?.length) {
      try {
        const res = await Promise.all(
          selectedAvailabilities.map((availability) =>
            loadAvailabilities({
              variables: {
                agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
                availabilityId: availability.id,
              },
            }),
          ),
        );
        const selected = res.map((item, index) => {
          const a = { ...item.data.availability, type: selectedAvailabilities[index]?.type };
          return a;
        });
        setAvailabilities(selected);
        setAvailabilitiesIds(selected.map((item) => item.id));
        // eslint-disable-next-line no-empty
      } catch {}
    }
  };

  useEffect(() => {
    if (
      availabilities.length &&
      dateScreenChanged &&
      DateTime.now().toSeconds() - dateScreenChanged.toSeconds() > 5
    ) {
      setDateScreenChanged(undefined);
      fetchSelectedAvailabilityDetails();
    } else if (dateScreenChanged) {
      setDateScreenChanged(undefined);
    }
    if (!isFocused) {
      setDateScreenChanged(DateTime.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilities, isFocused]);

  const { hasNext, hasPrev, hasMultiple, selectedAvailability } = useMemo(() => {
    if (selectedAvailabilityIndex !== undefined) {
      const availability =
        availabilities[selectedAvailabilityIndex] ||
        selectedAvailabilities?.[selectedAvailabilityIndex];
      const availabilitiesLength = selectedAvailabilities?.length || 0;
      return {
        selectedAvailability: availability,
        hasNext: selectedAvailabilityIndex + 1 < availabilitiesLength,
        hasPrev: selectedAvailabilityIndex > 0,
        hasMultiple: availabilitiesLength > 1,
      };
    }
    return { selectedAvailability: null, hasNext: false, hasPrev: false, hasMultiple: false };
  }, [availabilities, selectedAvailabilities, selectedAvailabilityIndex]);

  useEffect(() => {
    if (!availabilities?.length && selectedAvailabilities?.length) {
      Animated.timing(transform, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {});
      fetchSelectedAvailabilityDetails();
      setSelectedAvailabilityIndex(0);
    } else if (availabilities?.length && !selectedAvailabilities?.length) {
      Animated.timing(transform, { toValue: 0, duration: 200, useNativeDriver: true }).start(
        () => {},
      );
      setTimeout(() => {
        setAvailabilities([]);
        setAvailabilitiesIds([]);
      }, 400);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilities, availabilitiesIds, selectedAvailabilities, transform]);

  const handleCourierProfilePress = (availability: AvailabilityMapType) => () => {
    navigation.navigate('AgencyProfileNavigator', {
      screen: 'AgencyCourierUserProfileScreen',
      params: {
        userId: availability?.user.id,
        previousScreenName: 'AgencyOBCAvailabilityMapScreen',
        previousNavigator: 'AgencyHomeNavigator',
      },
    });
  };

  const handleCreateJobAndRequestPress = (availability: AvailabilityMapType) => () => {
    navigation.navigate('AgencyCreateJobScreen', {
      previousScreenName: 'AgencyHomeScreen',
      courierOrganizationUserId: availability?.user?.organizationUsers?.[0]?.id,
      courierOrganizationUserName: `${availability?.user.firstNames} ${availability?.user.lastName}`,
    });
  };

  const handleClose = () => {
    Animated.timing(transform, { toValue: 0, duration: 200, useNativeDriver: true }).start();
    setTimeout(() => {
      onResetAvailability();
      setAvailabilities([]);
      setAvailabilitiesIds([]);
    }, 300);
  };

  const handleArrowAnimate = (index: number) => {
    Animated.timing(transform, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {});
    setTimeout(() => {
      setSelectedAvailabilityIndex(undefined);
    }, 200);
    setTimeout(() => {
      onSelectAvailability(availabilities[index]);
      setSelectedAvailabilityIndex(index);

      Animated.timing(transform, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
      }).start(() => {});
    }, 300);
  };

  const handleNextPress = () => {
    if (
      selectedAvailabilityIndex !== undefined &&
      selectedAvailabilities?.[selectedAvailabilityIndex + 1]
    ) {
      handleArrowAnimate(selectedAvailabilityIndex + 1);
    }
  };

  const handlePrevPress = () => {
    if (
      selectedAvailabilityIndex !== undefined &&
      selectedAvailabilities?.[selectedAvailabilityIndex - 1]
    ) {
      handleArrowAnimate(selectedAvailabilityIndex - 1);
    }
  };

  return {
    availabilities,
    handleClose,
    handleCourierProfilePress,
    handleCreateJobAndRequestPress,
    hasNext,
    hasPrev,
    hasMultiple,
    handleNextPress,
    handlePrevPress,
    loading,
    transform,
    selectedAvailability,
  };
};
