import { useContext, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import Constants from 'expo-constants';
import * as ExpoImagePicker from 'expo-image-picker';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import * as Yup from 'yup';

import { AgencyAgencyUserMyProfileScreenRoutePropType } from './types';
import { getAuthorizationHeaders } from '../../../apollo/create-auth-link-with-headers';
import { extractErrorMessage } from '../../../apollo/error-link';
import {
  DELETE_USER_PROFILE_PICTURE_MUTATION,
  MUTATION_ORGANIZATION_USER_UPDATE_PROFILE,
} from '../../../apollo/graphql-mutations';
import {
  ORGANIZATION_ORGANIZATION_USERS_QUERY,
  QUERY_ORGANIZATION_USER_BY_ID,
} from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  StoredAsset,
  OrganizationUser,
  OrganizationUserRoleType,
} from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { sexList } from '../../../types';
import { isValidEmail } from '../../../utils/validate-field.util';

const initialValues = {
  firstNames: '',
  lastName: '',
  email: '',
  phone: '',
  sex: null,
  dateOfBirth: null,
  role: '',
};

export const useHook = () => {
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const route: AgencyAgencyUserMyProfileScreenRoutePropType = useRoute();

  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const { organizationUserId } = route.params;

  const onCompletedUserProfile = ({
    organizationUserById,
  }: {
    organizationUserById: OrganizationUser;
  }) => {
    if (organizationUserById?.user && completedUserProfile < 2) {
      setCompletedUserProfile((prev) => prev + 1);
      const { user } = organizationUserById;
      formik.setValues({
        firstNames: user.firstNames || formik.values.firstNames,
        lastName: user.lastName || formik.values.lastName,
        email: user.email || formik.values.email,
        phone: user.phone || formik.values.phone,
        sex: user.sex || formik.values.sex,
        dateOfBirth: user.dateOfBirth || formik.values.dateOfBirth,
        roleType: organizationUserById.roleType,
      });
      if (user.profilePicture) {
        setUserProfilePicture(user.profilePicture);
      }
    }
  };

  const { data } = useQuery<any>(QUERY_ORGANIZATION_USER_BY_ID, {
    variables: { organizationUserId },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-only',
    onCompleted: onCompletedUserProfile,
  });

  const organizationUser = useMemo(() => data?.organizationUserById, [data]);

  const isCurrentOrganizationUser = useMemo(
    () => organizationUser?.id === appUserContext.currentUserContext?.organizationUser?.id,
    [organizationUser?.id, appUserContext.currentUserContext?.organizationUser?.id],
  );

  const isRoleEditEnable = useMemo(() => {
    if (appUserContext.currentUserContext?.organizationUser?.roleType) {
      const { roleType } = appUserContext.currentUserContext?.organizationUser;
      if (
        roleType === OrganizationUserRoleType.ADMIN &&
        [OrganizationUserRoleType.ADMIN, OrganizationUserRoleType.STAFF].includes(
          organizationUser?.roleType,
        )
      ) {
        return true;
      }
      if (roleType === OrganizationUserRoleType.OWNER) {
        return true;
      }
    }
    return false;
  }, [appUserContext.currentUserContext?.organizationUser, organizationUser?.roleType]);

  const roleTypeData: { label: string; value: string }[] = [
    OrganizationUserRoleType.OWNER,
    OrganizationUserRoleType.ADMIN,
    OrganizationUserRoleType.STAFF,
  ]
    .filter((item) => {
      if (
        isRoleEditEnable &&
        item === OrganizationUserRoleType.OWNER &&
        appUserContext.currentUserContext?.organizationUser?.roleType !==
          OrganizationUserRoleType.OWNER
      ) {
        return false;
      }
      return true;
    })
    .map((key) => ({ value: key, label: (key as any).replaceAll('_', ' ') }));

  const [completedUserProfile, setCompletedUserProfile] = useState(0);
  const [loading, setLoading] = useState(false);
  const [temporaryProfilePicture, setTemporaryProfilePicture] = useState<
    ExpoImagePicker.ImagePickerAsset | undefined
  >();
  const [radioGroupVisible, setRadioGroupVisible] = useState<boolean>(false);
  const [userProfilePicture, setUserProfilePicture] = useState<StoredAsset | null | undefined>();

  const [organizationUserUpdateProfile] = useMutation(MUTATION_ORGANIZATION_USER_UPDATE_PROFILE, {
    refetchQueries: [ORGANIZATION_ORGANIZATION_USERS_QUERY],
  });
  const [deleteUserProfilePictureMutation, { loading: loadingDeletePicture }] = useMutation(
    DELETE_USER_PROFILE_PICTURE_MUTATION,
  );

  // Profile Picture
  const handleAvatarButtonVisibility = () => {
    setRadioGroupVisible((prevState) => !prevState);
  };

  const uploadProfilePicture = async (image: ExpoImagePicker.ImagePickerAsset | undefined) => {
    if (image) {
      const authHeaders = await getAuthorizationHeaders();
      const lastIndexOfDot = image.uri.lastIndexOf('.');
      const lastIndexOfSlash = image.uri.lastIndexOf('/');
      const fileType = image.uri.substring(lastIndexOfDot + 1);
      const fileName = image.uri.substring(lastIndexOfSlash + 1);
      try {
        const response = await fetch(
          Constants.expoConfig?.extra?.priojetBackendUploadProfilePictureEndpoint,
          {
            method: 'post',
            headers: {
              ...authHeaders,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file: image?.base64,
              fileType,
              fileName,
              uri: image?.uri,
              exif: image?.exif,
              height: image?.height,
              width: image?.width,
            }),
          },
        );

        if (response) {
          response.json().then((_data) => {
            setUserProfilePicture(_data);
            setTemporaryProfilePicture(undefined);
          });
        }
      } catch {
        showMessage({
          message: t('agencyUserProfile.error', { defaultValue: 'Error' }),
          description: t('agencyUserProfile.errorUploadingLogoImage', {
            defaultValue: 'Something went wrong with uploading logo image',
          }) as string,
          type: 'danger',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    }
  };

  const handleDeleteUserProfilePicture = async () => {
    try {
      await deleteUserProfilePictureMutation();
      setUserProfilePicture(undefined);
      setTemporaryProfilePicture(undefined);
    } catch {
      showMessage({
        message: t('agencyUserProfile.error', { defaultValue: 'Error' }),
        description: t('agencyUserProfile.errorDeletingProfilePicture', {
          defaultValue: 'Something went wrong with deleting profile picture',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('agencyUserProfile.error', { defaultValue: 'Error' }),
        description:
          t('agencyUserProfile.someFieldAreNotValid', {
            defaultValue: 'Some fields are not valid',
          }) +
          `.\n${Object.keys(errors)
            .map((key) => `- ${errors[key]}`)
            .join('\n')}`,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }
    if (
      formik.values.roleType !== OrganizationUserRoleType.OWNER &&
      !data?.organizationUserById.organization.organizationUsers.some(
        (item: OrganizationUser) =>
          item.id !== organizationUser.id && item.roleType === OrganizationUserRoleType.OWNER,
      )
    ) {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }) as string,
        description: t('common.userDeletionNotAvailableWithoutOtherAgencyOwnerError', {
          defaultValue:
            'Cannot remove user because agency will be without agency OWNER and that is not possible. Assign other employee as OWNER first and then you can remove employee from the agency.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }
    setLoading(true);
    try {
      uploadProfilePicture(temporaryProfilePicture);
      await organizationUserUpdateProfile({
        variables: {
          organizationUserUpdateProfileInput: {
            organizationUserId: organizationUser?.id,
            sex: formik.values.sex,
            dateOfBirth: formik.values.dateOfBirth,
            roleType: formik.values.roleType,
          },
        },
      });
      showMessage({
        message: t('agencyUserProfile.success', { defaultValue: 'Success' }),
        description: t('agencyUserProfile.profileUpdated', {
          defaultValue: 'Profile successfully updated',
        }) as string,
        type: 'success',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      handleBackPress();
    } catch (e) {
      const { message } = extractErrorMessage(e);
      let description = t('agencyUserProfile.somethingWentWrong', {
        defaultValue: 'Something went wrong',
      });

      showMessage({
        message: t('agencyUserProfile.error', { defaultValue: 'Error' }),
        description: message || description,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().test(
      'test-valid-email',
      t('agencyUserProfile.invalidEmail', {
        defaultValue: 'Must be a valid email',
      }) as string,
      (value) =>
        isValidEmail({
          value,
          canBeEmpty: false,
        }),
    ),
    firstNames: Yup.string().required(
      t('agencyUserProfile.requiredFieldFirstName', {
        defaultValue: 'First name must be set',
      }) as string,
    ),
    lastName: Yup.string().required(
      t('agencyUserProfile.requiredFieldLastName', {
        defaultValue: 'Last name must be set',
      }) as string,
    ),
    sex: isCurrentOrganizationUser
      ? Yup.string()
          .nullable()
          .required(
            t('agencyUserProfile.requiredFieldSex', { defaultValue: 'Sex must be set' }) as string,
          )
      : Yup.string().nullable(),
    dateOfBirth: isCurrentOrganizationUser
      ? Yup.string()
          .nullable()
          .required(
            t('agencyUserProfile.requiredFieldDateOfBirth', {
              defaultValue: 'Date of birth must be set',
            }) as string,
          )
      : Yup.string().nullable(),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const fieldProps = (field: string, type = 'text') => {
    const formData: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);

    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        caption: '',
        checked: formData.value,
        errorMessage: fieldError,
        onChange,
        status: fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      if (type !== 'number' || !value.length || value.match(/^\d*(\.\d+)?$/)) {
        formik.handleChange(field)({
          target: { value },
        });
      }
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      caption: '',
      errorMessage: fieldError,
      status: fieldError ? 'danger' : 'basic',
      value: formData.value || '',
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const sexListData = useMemo(() => {
    return sexList.map((sex) => ({
      ...sex,
      name: t(`common.${sex.name}`, { defaultValue: sex.name }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // image picker
  const pickImageMobile = async () => {
    const { status } = await ExpoImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      showMessage({
        message: t('agencyUserProfile.error', { defaultValue: 'Error' }),
        description: t('agencyUserProfile.noCameraPermissions', {
          defaultValue: 'Sorry, we need camera roll permissions to make this work!',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    const camPermission = await ExpoImagePicker.getCameraPermissionsAsync();
    if (camPermission.status === 'granted') {
      const result = await ExpoImagePicker.launchCameraAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
        base64: true,
      });
      if (!result.canceled && result.assets?.length) {
        setTemporaryProfilePicture(result.assets[0]);
      }
    }
  };

  const pickImageWeb = async () => {
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
      base64: true,
    });
    if (!result.canceled && result.assets?.length) {
      setTemporaryProfilePicture(result.assets[0]);
    }
  };

  const onSelect = (valueKey: string) => (value: any) => {
    if (value) {
      formik?.setFieldValue(valueKey, value);
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    } else {
      formik?.setFieldValue(valueKey, null);
    }
  };

  const handleBackPress = () => {
    if (route.params.previousScreenName === 'AgencyViewAgencyUsersScreen') {
      navigation.navigate('AgencyProfileNavigator', {
        screen: 'AgencyViewAgencyUsersScreen',
        params: {
          previousScreenName: 'AgencyManageAgencyUsersScreen',
          previousNavigator: 'AgencyProfileNavigator',
        },
      });
    } else {
      navigation.navigate('AgencyProfileNavigator', {
        screen: 'AgencyProfileScreen',
        previousNavigator: 'AgencyProfileNavigator',
      });
    }
  };

  const handleDateChange = (field: string) => (date: Date) => {
    formik.setFieldValue(field, DateTime.fromJSDate(date));
    setTimeout(() => {
      formik.validateField(field);
    }, 50);
  };

  const profilePicture = useMemo(() => {
    if (temporaryProfilePicture) {
      return temporaryProfilePicture;
    }
    if (userProfilePicture && userProfilePicture.sasUrl) {
      return { uri: userProfilePicture.sasUrl };
    }
  }, [temporaryProfilePicture, userProfilePicture]);

  return {
    fieldProps,
    formik,
    handleAvatarButtonVisibility,
    handleBackPress,
    handleDateChange,
    handleDeleteUserProfilePicture,
    handleSave,
    isCurrentOrganizationUser,
    isRoleEditEnable,
    loading,
    loadingDeletePicture,
    onSelect,
    pickImageMobile,
    pickImageWeb,
    profilePicture,
    radioGroupVisible,
    roleTypeData,
    sexListData,
    userProfilePicture,
  };
};
