import { useMemo, useState } from 'react';

import { IndexPath } from '@ui-kitten/components';

export const useHook = ({
  fieldTitleKey,
  fieldValueKey,
  filterDataCustom,
  handleClose,
  handleDone,
  items,
  multiSelect,
  onChangeText,
  onSelect,
  searchable,
  text,
  value,
}: {
  fieldTitleKey: string;
  fieldValueKey: string;
  filterDataCustom?: (items: any[], searchText: string) => any[];
  handleClose: any;
  handleDone: any;
  items: Array<any>;
  multiSelect?: boolean;
  onChangeText?: (val: string) => void;
  onSelect: (value: any, index: IndexPath | IndexPath[], item: any) => void;
  searchable?: boolean;
  text?: string;
  value?: string | string[];
}) => {
  const [searchText, setSearchText] = useState(text || '');

  const handleClosePress = () => {
    handleClose();
  };

  const handleDonePress = () => {
    if (handleDone) {
      handleDone();
    } else if (handleClose) {
      handleClose();
    }
  };

  const handlePress =
    ({ item, index }: { item: any; index: number }) =>
    () => {
      if (multiSelect && Array.isArray(value)) {
        let newValue = [...value];
        if (newValue.includes(item[fieldValueKey])) {
          newValue = newValue.filter((x: any) => x !== item[fieldValueKey]);
        } else {
          newValue = [...newValue, item[fieldValueKey]];
        }
        onSelect(newValue, new IndexPath(index), item);
      } else {
        onSelect(item[fieldValueKey], new IndexPath(index), item);
        if (onChangeText && searchable) {
          onChangeText(item[fieldTitleKey]);
        }
        handleClose();
      }
    };

  const handleChangeText = (val: string) => {
    setSearchText(val);
    if (onChangeText && searchable) {
      onChangeText(val);
    }
  };

  const handleResetText = () => {
    setSearchText('');
  };

  const data = useMemo(() => {
    if (searchable && searchText.length) {
      const search = searchText.toLowerCase();
      if (filterDataCustom) {
        return filterDataCustom(items, search);
      }
      return items.filter((item) => item[fieldTitleKey].toLowerCase().includes(search));
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldTitleKey, items, searchText, searchable]);

  return {
    data,
    handleChangeText,
    handleClosePress,
    handleDonePress,
    handleResetText,
    handlePress,
    searchText,
  };
};
