import React, { useContext, useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { useQuery } from '@apollo/client';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { COURIER_JOBS_FOR_COURIER_ORGANIZATION_MINIMAL_QUERY } from '../../apollo/graphql-queries';
import AppUserContext from '../../contexts/AppUserContext';
import { CourierJob } from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import {
  JOB_STATUS_FOR_COURIERS,
  getFilteredCourierJobsByCourierJobStatusType,
} from '../../modules/job/job.module';
import { LoadingSpinner } from '../common/loading-spinner.component';
import ContentBoxTextTitle from '../content-box-text-title.component';

export const CourierJobsBox = ({ refreshing }: { refreshing: boolean }) => {
  const { t } = useTranslation();
  const navigation: any = useNavigation();
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();

  const styles = useStyleSheet(themedStyles);

  const {
    loading: courierJobsForCourierOrganizationLoading,
    refetch: courierJobsForCourierOrganizationRefetch,
    data: courierJobsForCourierOrganizationData,
  } = useQuery<{ courierJobsForCourierOrganizationMinimal: CourierJob[] }>(
    COURIER_JOBS_FOR_COURIER_ORGANIZATION_MINIMAL_QUERY,
    {
      variables: {
        courierOrganizationId: appUserContext.currentUserContext?.organization?.id as string, // will always be a string in this case.
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
      pollInterval: isBackendReachable && isFocused ? 10000 * 30 : 0,
      skip: !isFocused,
    },
  );

  useEffect(() => {
    if (refreshing) {
      courierJobsForCourierOrganizationRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing]);

  if (courierJobsForCourierOrganizationLoading) {
    <View>
      <LoadingSpinner />
    </View>;
  }

  const courierJobsNew: Partial<CourierJob>[] = useMemo(() => {
    if (!courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal) {
      return [];
    }
    return getFilteredCourierJobsByCourierJobStatusType({
      courierJobs:
        courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal as Partial<CourierJob>[],
      jobStatusForCouriers: JOB_STATUS_FOR_COURIERS.NEW,
    });
  }, [courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal]);

  const courierJobsRunning: Partial<CourierJob>[] = useMemo(() => {
    if (!courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal) {
      return [];
    }
    return getFilteredCourierJobsByCourierJobStatusType({
      courierJobs:
        courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal as Partial<CourierJob>[],
      jobStatusForCouriers: JOB_STATUS_FOR_COURIERS.RUNNING,
    });
  }, [courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal]);

  const courierJobsPast: Partial<CourierJob>[] = useMemo(() => {
    if (!courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal) {
      return [];
    }
    return getFilteredCourierJobsByCourierJobStatusType({
      courierJobs:
        courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal as Partial<CourierJob>[],
      jobStatusForCouriers: JOB_STATUS_FOR_COURIERS.PAST,
    });
  }, [courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal]);

  const courierJobsDeclined: Partial<CourierJob>[] = useMemo(() => {
    if (!courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal) {
      return [];
    }
    return getFilteredCourierJobsByCourierJobStatusType({
      courierJobs:
        courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal as Partial<CourierJob>[],
      jobStatusForCouriers: JOB_STATUS_FOR_COURIERS.DECLINED,
    });
  }, [courierJobsForCourierOrganizationData?.courierJobsForCourierOrganizationMinimal]);

  const renderJobsOverview = () => (
    <View style={styles.container}>
      <View style={styles.jobCountLayer}>
        <TouchableOpacity
          style={styles.jobCountTouchable}
          onPress={() =>
            navigation.navigate('CourierCourierJobsScreen', {
              screen: 'CourierCourierJobsNewScreen',
            })
          }
        >
          <View style={[styles.jobCountView, styles.jobCountViewNew]}>
            <Text style={styles.jobCountHeaderText}>
              {t('jobs.new', { defaultValue: 'new' }).toUpperCase()}
            </Text>
            <Text style={styles.jobCountNumberText}>{courierJobsNew.length}</Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.jobCountTouchable}
          onPress={() =>
            navigation.navigate('CourierCourierJobsScreen', {
              screen: 'CourierCourierJobsRunningScreen',
            })
          }
        >
          <View style={[styles.jobCountView, styles.jobCountViewRunning]}>
            <Text style={styles.jobCountHeaderText}>
              {t('jobs.running', { defaultValue: 'running' }).toUpperCase()}
            </Text>
            <Text style={styles.jobCountNumberText}>{courierJobsRunning.length}</Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.jobCountTouchable}
          onPress={() =>
            navigation.navigate('CourierCourierJobsScreen', {
              screen: 'CourierCourierJobsPastScreen',
            })
          }
        >
          <View style={[styles.jobCountView, styles.jobCountViewPast]}>
            <Text style={styles.jobCountHeaderText}>
              {t('jobs.past', { defaultValue: 'past' }).toUpperCase()}
            </Text>
            <Text style={styles.jobCountNumberText}>{courierJobsPast.length}</Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.jobCountTouchable}
          onPress={() =>
            navigation.navigate('CourierCourierJobsScreen', {
              screen: 'CourierCourierJobsDeclinedScreen',
            })
          }
        >
          <View style={[styles.jobCountView, styles.jobCountViewDeclined]}>
            <Text style={styles.jobCountHeaderText}>
              {t('jobs.declined', { defaultValue: 'declined' }).toUpperCase()}
            </Text>
            <Text style={styles.jobCountNumberText}>{courierJobsDeclined.length}</Text>
          </View>
        </TouchableOpacity>
      </View>
      <Button
        appearance="filled"
        onPress={() => {
          navigation.navigate('CourierCourierJobsScreen', {
            screen: 'CourierCourierJobsNewScreen',
          });
        }}
      >
        {t('common.seeAllJobs', { defaultValue: 'See all Jobs' }) as string}
      </Button>
    </View>
  );

  return (
    <ContentBoxTextTitle
      key="jobs"
      style={styles.content}
      title="Jobs"
      Content={renderJobsOverview()}
    />
  );
};

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },
  container: {
    paddingBottom: 10,
  },
  jobCountHeaderText: {
    textTransform: 'uppercase',
    fontFamily: 'Lato_700Bold',
    color: 'text-basic-color',
    fontSize: 12,
  },
  jobCountNumberText: {
    fontSize: 30,
    fontFamily: 'Lato_700Bold',
    color: 'text-basic-color',
    paddingTop: 10,
  },
  jobCountViewRunning: {
    borderBottomColor: 'blue',
  },
  jobCountViewDeclined: {
    borderBottomColor: 'red',
  },
  jobCountViewNew: {
    borderBottomColor: 'gold',
  },
  jobCountViewPast: {
    borderBottomColor: 'green',
  },
  jobCountLayer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    marginBottom: 15,
  },
  jobCountTouchable: {
    alignItems: 'center',
    height: 80,
  },
  jobCountView: {
    alignItems: 'center',
    height: 80,
    borderBottomWidth: 5,
    margin: 1,
    alignSelf: 'stretch',
    minWidth: 80,
    width: '25%',
    maxWidth: 200,
  },
});
