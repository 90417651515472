import React from 'react';

import { Layout, Text } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';

import { TimeAgoPopover } from './time-ago-popover.component';

export const CreatedAtByText = ({
  firstNames,
  lastName,
  email,
  createdAt,
  textColor,
}: {
  firstNames: string;
  lastName: string;
  email: string;
  createdAt: DateTime;
  userId?: string;
  textColor?: string;
}) => {
  const [t, i18n] = useTranslation();

  return (
    <Layout>
      <Text selectable={true} style={textColor ? { color: textColor } : {}}>
        <Trans
          i18nKey="common.createdAtByUserText"
          t={t}
          components={{
            TimeAgoPopover: (
              <TimeAgoPopover
                date={createdAt}
                language={i18n.language}
                textStyle={{ color: textColor }}
              />
            ),
          }}
          values={{
            firstNames,
            lastName,
            email,
          }}
          defaults="Created <TimeAgoPopover></TimeAgoPopover> by {{firstNames}} {{lastName}} ({{email}})"
        />
      </Text>
    </Layout>
  );
};
