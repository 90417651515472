import React, { useMemo } from 'react';
import { ScrollView, TextStyle, View, ViewStyle } from 'react-native';

import { StyleService, Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../common/style';
import { CourierJob, Location } from '../../../generated-graphql-types';
import useDimensions from '../../../hooks/useDimensions';
import { jobTypeOfDeliveryList } from '../../../types';
import { CopyableText } from '../../common/common.copyable-text.component';
import { LocationTextWithContextMenu } from '../../common/common.location-text-with-context-menu';
import { FormTextTextComponent } from '../../common/form/form-text-text.component';
import { LoadingSpinner } from '../../common/loading-spinner.component';
import { TimeAgoPopover } from '../../common/time-ago-popover.component';

export const CourierCourierJobComponent = ({
  courierJob,
}: {
  courierJob: Partial<CourierJob>;
}): React.ReactElement => {
  const { i18n, t } = useTranslation();
  const { isSmallDevice, isVerySmallDevice } = useDimensions();

  const jobTypeOfDelivery = useMemo(() => {
    if (courierJob?.job?.typeOfDelivery) {
      const type = jobTypeOfDeliveryList(t).find(
        (item) => item.value === courierJob.job?.typeOfDelivery,
      );
      if (type) {
        return type.name;
      }
      return 'n/a';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierJob?.job?.typeOfDelivery]);

  const textProps = (size: boolean) => ({
    style: themedStyles.formView,
    direction: (size ? 'column' : 'row') as 'column' | 'row',
    textStyle: {},
  });

  const style = isSmallDevice
    ? {
        styleContainer: {
          flex: 1,
        } as ViewStyle,
        style: { flex: 1, alignSelf: 'flex-end' } as ViewStyle,
        textStyle: { textAlign: 'right', justifyContent: 'flex-end' } as TextStyle,
        timeTextStyle: { textAlign: 'left', marginBottom: 4 } as TextStyle,
      }
    : {
        styleContainer: {
          flex: 1,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        } as ViewStyle,
        style: { alignSelf: 'flex-end' } as ViewStyle,
        textStyle: { textAlign: 'right', justifyContent: 'flex-end' } as TextStyle,
        timeTextStyle: { textAlign: 'right', marginBottom: 4 } as TextStyle,
      };

  if (!courierJob) {
    return (
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <LoadingSpinner subject="Courier Job" />
      </ScrollView>
    );
  }

  const description = courierJob.job?.description || 'n/a';

  const renderJobAgents = () => {
    if (courierJob.agencyJob?.responsibleAgencyOrganizationUsers?.length) {
      return (
        <View>
          {courierJob.agencyJob.responsibleAgencyOrganizationUsers.map((item) => (
            <View style={[globalStyle.flex1, globalStyle.marginBottom5]} key={item.id}>
              <CopyableText
                copyableTextName={`${item.user.firstNames} ${item.user.lastName}`}
                text={`${item.user.firstNames} ${item.user.lastName}`}
              />
            </View>
          ))}
        </View>
      );
    }
    return <></>;
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={themedStyles.contentContainer}
      >
        <>
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.jobAgent/s', {
              defaultValue: 'Job Agent/s',
            })}
            jsxElementValue={renderJobAgents()}
            textStyle={style.textStyle}
            copyable
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.priojetJobIdentifier', {
              defaultValue: 'PRIOjet Job Identifier',
            })}
            stringValue={courierJob.job?.priojetJobIdentifier || 'n/a'}
            textStyle={style.textStyle}
            copyable
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.jobTitleRefNumber', {
              defaultValue: 'Job title/Ref. Nr.',
            })}
            stringValue={courierJob.agencyJob?.externalJobReference || 'n/a'}
            textStyle={style.textStyle}
            copyable
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('common.agency', {
              defaultValue: 'Agency',
            })}
            stringValue={courierJob.agencyJob?.responsibleAgencyOrganization?.shortName || '-'}
            textStyle={style.textStyle}
            copyable
          />

          {!!courierJob.job?.pickupLocation && (
            <FormTextTextComponent
              {...textProps(isSmallDevice)}
              hint={t('jobs.pickupLocation', {
                defaultValue: 'Pickup Location',
              })}
              jsxElementValue={
                <View style={style.styleContainer}>
                  <LocationTextWithContextMenu
                    location={courierJob.job?.pickupLocation as Location}
                    textStyle={style.textStyle}
                  />
                </View>
              }
              useTwoRows
            />
          )}
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.startAirport', {
              defaultValue: 'Start airport',
            })}
            jsxElementValue={
              <View style={style.styleContainer}>
                <LocationTextWithContextMenu
                  location={courierJob.agencyJob?.startAirport as Location}
                  textStyle={style.textStyle}
                />
              </View>
            }
            useTwoRows
          />
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.pickupAddressDescription', {
              defaultValue: 'Pickup Address Description',
            })}
            stringValue={courierJob.job?.pickupAddressDescription || 'n/a'}
            useTwoRows
          />
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.pickupTime', {
              defaultValue: 'Pickup Time',
            })}
            jsxElementValue={
              <View style={style.styleContainer}>
                {!!courierJob.job?.pickupTime &&
                  !!courierJob.job?.pickupLocation?.timeZoneIdentifier && (
                    <>
                      <TimeAgoPopover
                        language={i18n.language}
                        date={courierJob.job.pickupTime?.setZone(
                          courierJob.job.pickupLocation.timeZoneIdentifier as string,
                        )}
                        style={style.style}
                        textStyle={style.timeTextStyle}
                      />
                      <Text style={style.textStyle}>
                        {courierJob.job?.pickupTime
                          ?.setZone(courierJob.job.pickupLocation.timeZoneIdentifier as string)
                          .toFormat('dd MMM yyyy, HH:mm') + ' LT'}
                      </Text>
                    </>
                  )}
              </View>
            }
          />
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.dropOffLocation', {
              defaultValue: 'Drop-off Location',
            })}
            jsxElementValue={
              <View style={style.styleContainer}>
                <LocationTextWithContextMenu
                  location={courierJob.job?.dropOffLocation as Location}
                  textStyle={style.textStyle}
                />
              </View>
            }
            useTwoRows
          />
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.endAirport', {
              defaultValue: 'End Airport',
            })}
            jsxElementValue={
              <View style={style.styleContainer}>
                <LocationTextWithContextMenu
                  location={courierJob.agencyJob?.endAirport as Location}
                  textStyle={style.textStyle}
                />
              </View>
            }
          />
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.returnTimeAtStartAirport', {
              defaultValue: 'Return time at start airport',
            })}
            jsxElementValue={
              <View style={style.styleContainer}>
                {!!courierJob.agencyJob?.returnTimeAtStartAirport &&
                  !!courierJob.agencyJob?.startAirport?.timeZoneIdentifier && (
                    <>
                      <TimeAgoPopover
                        language={i18n.language}
                        date={courierJob.agencyJob.returnTimeAtStartAirport?.setZone(
                          courierJob.agencyJob.startAirport.timeZoneIdentifier as string,
                        )}
                        style={style.style}
                        textStyle={style.timeTextStyle}
                      />

                      <Text style={style.textStyle}>
                        {courierJob.agencyJob?.returnTimeAtStartAirport
                          ?.setZone(courierJob.agencyJob.startAirport.timeZoneIdentifier as string)
                          .toFormat('dd MMM yyyy, HH:mm') + ' LT'}
                      </Text>
                    </>
                  )}
              </View>
            }
          />
          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.additionalDetailsAndJobDescription', {
              defaultValue: 'Additional details and Job description',
            })}
            stringValue={description}
            textStyle={style.textStyle}
            useTwoRows
          />

          <FormTextTextComponent
            {...textProps(isSmallDevice)}
            hint={t('jobs.dropOffAddressDescription', {
              defaultValue: 'Drop-off Address Description',
            })}
            stringValue={courierJob.job?.dropOffAddressDescription || 'n/a'}
            useTwoRows
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.typeOfTransportJob', {
              defaultValue: 'Type of Transport job',
            })}
            stringValue={jobTypeOfDelivery}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.carRequiredForPickup', {
              defaultValue: 'Car required for Pickup',
            })}
            stringValue={
              courierJob.agencyJob?.isCarRequiredForJob
                ? (t('common.Yes', { defaultValue: 'Yes' }) as string)
                : t('common.No', { defaultValue: 'No' }) || 'n/a'
            }
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.drivingLicenseRequiredForPickup', {
              defaultValue: 'Driving license required for Pickup',
            })}
            stringValue={
              courierJob.agencyJob?.isDrivingLicenseRequiredForJob
                ? (t('common.Yes', { defaultValue: 'Yes' }) as string)
                : (t('common.No', { defaultValue: 'No' }) as string)
            }
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.transportGoodsContents', {
              defaultValue: 'Transport goods contents',
            })}
            stringValue={t(`jobs.${courierJob.job?.transportedGoodsContentType}`) || 'n/a'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.weightOfItemsKG', {
              defaultValue: 'Weight of Items (KG)',
            })}
            stringValue={courierJob.agencyJob?.weightOfItems?.toString() || 'n/a'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.numOfItems', {
              defaultValue: 'Number of Items',
            })}
            stringValue={courierJob.agencyJob?.numOfItems?.toString() || 'n/a'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.numOfCouriersRequired', {
              defaultValue: 'Number of Couriers required',
            })}
            stringValue={courierJob.agencyJob?.numOfCouriersRequired?.toString() || 'n/a'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.currencyOfPayment', {
              defaultValue: 'Currency of payment',
            })}
            stringValue={courierJob.agencyJob?.currency?.toString() || 'n/a'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.hotelBudget', {
              defaultValue: 'Hotel budget',
            })}
            stringValue={courierJob.hotelBudget ? courierJob.hotelBudget?.toString() : '-'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.fixedRate', {
              defaultValue: 'Fixed rate',
            })}
            stringValue={courierJob.fixedRate ? courierJob.fixedRate?.toString() : '-'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.dailyRate', {
              defaultValue: 'Daily rate',
            })}
            stringValue={courierJob.dailyRate ? courierJob.dailyRate?.toString() : '-'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.kickOffFee', {
              defaultValue: 'Kick-off fee',
            })}
            stringValue={courierJob.kickOffFee ? courierJob.kickOffFee?.toString() : '-'}
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.isAccommodationBookedByCourier', {
              defaultValue: 'Accommodation booked by Courier',
            })}
            stringValue={
              courierJob.isAccommodationBookedByCourier
                ? (t('common.Yes', { defaultValue: 'Yes' }) as string)
                : (t('common.No', { defaultValue: 'No' }) as string)
            }
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.isAccommodationPaidByCourier', {
              defaultValue: 'Accommodation paid by Courier',
            })}
            stringValue={
              courierJob.isAccommodationPaidByCourier
                ? (t('common.Yes', { defaultValue: 'Yes' }) as string)
                : (t('common.No', { defaultValue: 'No' }) as string)
            }
          />
          <FormTextTextComponent
            {...textProps(isVerySmallDevice)}
            hint={t('jobs.isAdditionalCostPaidByCourier', {
              defaultValue: 'Additional costs paid by Courier',
            })}
            stringValue={
              courierJob.isAdditionalCostPaidByCourier
                ? (t('common.Yes', { defaultValue: 'Yes' }) as string)
                : (t('common.No', { defaultValue: 'No' }) as string)
            }
          />

          <FormTextTextComponent
            {...textProps(true)}
            hint={t('jobs.additionalCostInformation', {
              defaultValue: 'Additional information on cost coverage',
            })}
            stringValue={courierJob?.additionalCostInformation || ''}
            textStyle={style.textStyle}
            useTwoRows
          />
        </>
      </ScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  contentContainer: {
    paddingBottom: 20,
  },
  layoutSocialMediaContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  layoutCreditCardsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  formView: {
    padding: 16,
  },
  section: {
    marginTop: 24,
  },
  avatar: {
    width: 150,
    height: 150,
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  sectionLabel: {
    marginTop: 24,
    marginBottom: 8,
    marginHorizontal: 16,
  },
  profileDescription: {
    marginHorizontal: 16,
  },
});
