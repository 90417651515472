import React, { useContext, forwardRef } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  TextInput,
  View,
  TouchableOpacity,
} from 'react-native';

import { Icon, Layout, Text, useStyleSheet, Button } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Hyperlink from 'react-native-hyperlink';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { Props } from './types';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import Colors from '../../../../constants/Colors';
import ThemeContext from '../../../../contexts/ThemeContext';
import { ConfirmModal } from '../ConfirmModal';

export const MAX_LENGTH = 2000;

export const AgencyJobInternalNotesModal = forwardRef(
  ({ buttonSize, onChangeText }: Props, ref) => {
    const { t } = useTranslation();
    const globalStyle = useStyleSheet(globalStyleCommon);
    const styles = useStyleSheet(themedStyles);
    const { top, bottom } = useSafeAreaInsets();

    const { theme } = useContext(ThemeContext);

    const {
      confirmModalVisible,
      focused,
      handleBlur,
      handleCancelModalPress,
      handleChangeText,
      handleClose,
      handleFocus,
      handleLinkPress,
      handleSave,
      handleSelectionChange,
      modalHeight,
      modalWidth,
      isSaveDisabled,
      modalVisible,
      showModal,
      selection,
      textInputRef,
      text,
    } = useHook({
      buttonSize,
      onChangeText,
      ref,
    });

    const renderLabel = () => (
      <View style={styles.labelContainer}>
        <Text style={styles.label} />
        <Text
          style={[
            styles.maxLength,
            globalStyle.colorTextBasicColor,
            text.length === MAX_LENGTH && styles.maxLengthError,
          ]}
        >
          {text.length || 0}/{MAX_LENGTH}
        </Text>
      </View>
    );

    if (!showModal) {
      return <></>;
    }

    return (
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <Modal
          animationIn={Platform.OS === 'web' ? 'fadeIn' : 'slideInUp'}
          animationOut={Platform.OS === 'web' ? 'fadeOut' : 'slideOutDown'}
          isVisible={modalVisible}
          onBackdropPress={handleClose}
          style={styles.modalStyle}
        >
          <Layout
            style={[
              styles.modalView,
              Platform.OS === 'android' && globalStyle.height100Percent,
              Platform.OS === 'android' && globalStyle.flex1,
              Platform.OS === 'android' && globalStyle.flexGrow1,
              Platform.OS === 'android' && { bottom: bottom || 20 },
              Platform.OS !== 'web' && { marginTop: top ? top + 10 : 20 },
              { width: modalWidth as any, height: modalHeight as any },
            ]}
          >
            <View style={styles.header}>
              <TouchableOpacity
                hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
                style={styles.closeButton}
                onPress={handleClose}
              >
                <Icon
                  name="close-outline"
                  height={28}
                  width={28}
                  fill={theme === 'light' ? Colors.dark.background : Colors.light.background}
                />
              </TouchableOpacity>
              <Button
                disabled={isSaveDisabled}
                onPress={handleSave}
                size="small"
                style={globalStyle.marginTop15}
              >
                {t('common.saveNote', { defaultValue: 'Save note' })}
              </Button>
            </View>

            <ScrollView
              style={[
                globalStyle.flex1,
                globalStyle.paddingHorizontal20,
                globalStyle.paddingBottom5,
              ]}
              contentContainerStyle={[globalStyle.flex1, globalStyle.marginBottom20]}
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
            >
              {renderLabel()}
              <View style={globalStyle.flex1}>
                <TextInput
                  ref={textInputRef}
                  onBlur={handleBlur}
                  onChangeText={handleChangeText}
                  style={[
                    globalStyle.height100Percent,
                    globalStyle.verticalAlignTop,
                    globalStyle.padding15,
                    globalStyle.verticalAlignTop,
                    globalStyle.fontSize16,
                    globalStyle.lineHeight20,
                    globalStyle.colorTextBasicColor,
                    globalStyle.borderWidth1,
                    globalStyle.borderBasicColor4,
                    globalStyle.fontLatoRegular,
                    Platform.OS === 'web' && ({ outlineStyle: 'none' } as any),
                  ]}
                  multiline
                  verticalAlign="top"
                  value={text}
                  onSelectionChange={handleSelectionChange}
                  selection={selection}
                />
                {!focused && (
                  <Pressable
                    style={[
                      globalStyle.absoluteFull,
                      globalStyle.overflowScroll,
                      globalStyle.borderWidth1,
                      globalStyle.borderBasicColor4,
                      globalStyle.backgroundColorBasic1,
                      globalStyle.padding15,
                      globalStyle.fontLatoRegular,
                      globalStyle.colorTextBasicColor,
                    ]}
                    onPress={handleFocus}
                  >
                    <Hyperlink linkStyle={[globalStyle.colorPrimary400]} onPress={handleLinkPress}>
                      <Text
                        style={[
                          globalStyle.fontSize16,
                          globalStyle.lineHeight20,
                          globalStyle.colorTextBasicColor,
                        ]}
                      >
                        {text}
                      </Text>
                    </Hyperlink>
                  </Pressable>
                )}
              </View>
              <View style={globalStyle.alignItemsCenter}>
                <Text
                  style={[
                    globalStyle.textAlignCenter,
                    globalStyle.width300,
                    globalStyle.opacity9,
                    globalStyle.marginTop10,
                    globalStyle.fontSize14,
                    globalStyle.lineHeight14,
                    globalStyle.colorTextBasicColor,
                  ]}
                >
                  {t('agencyJobs.forInternalUseOnly', {
                    defaultValue:
                      'For internal use only.\nOBCs will not be able to see these job notes.',
                  })}
                </Text>
              </View>
            </ScrollView>
          </Layout>

          {confirmModalVisible && (
            <ConfirmModal
              confirmButtonStatus="danger"
              confirmButtonAppearance="filled"
              confirmButtonText={t('common.saveBeforeClosing', {
                defaultValue: 'Save before closing',
              })}
              cancelButtonText={t('common.closeNow', { defaultValue: 'Close now' })}
              title={t('common.unsavedChanges', { defaultValue: 'Unsaved changes' })}
              text={t('common.youHaveUnsavedChanges', {
                defaultValue: 'You have unsaved changes. Do you want to save them before closing?',
              })}
              handleCancel={handleCancelModalPress}
              handleConfirm={handleSave}
              visible={confirmModalVisible}
            />
          )}
        </Modal>
      </KeyboardAvoidingView>
    );
  },
);
