import React from 'react';
import { Keyboard, TouchableOpacity, View } from 'react-native';

import { Text, useStyleSheet, Button } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { AdditionalSeatsModalProps } from './types';
import { globalStyle } from '../../../../../common/style';
import { SubscriptionPlanOptions } from '../../../../../components/common/SubscriptionPlanChoose/SubscriptionPlanOptions';

export const AdditionalSeatsModal = ({
  onClose,
  refetchOrganizationSubscriptionPlans,
  onBookSuccess,
  onBookToggle,
  visibleModal,
}: AdditionalSeatsModalProps) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    agencyPlans,
    handleBookPress,
    handleClose,
    handleSelectPlan,
    handleSelectPeriod,
    selectedPlan,
    selectedPeriod,
  } = useHook({
    onClose,
    refetchOrganizationSubscriptionPlans,
    onBookSuccess,
    onBookToggle,
    visibleModal,
  });

  return (
    <Modal
      animationIn="fadeIn"
      animationOut="fadeOut"
      avoidKeyboard
      isVisible={visibleModal}
      backdropOpacity={0.8}
      useNativeDriver
    >
      <View style={styles.wrapper}>
        <TouchableOpacity style={styles.background} onPress={Keyboard.dismiss} />
        <View style={styles.inner}>
          <Text category="h5" style={styles.title}>
            {t('common.bookAdditionalSeats', { defaultValue: 'Book additional seats' }) as string}
          </Text>

          <SubscriptionPlanOptions
            data={agencyPlans}
            onSelect={handleSelectPlan}
            onSelectPeriod={handleSelectPeriod}
            selected={selectedPlan}
            selectedPeriod={selectedPeriod}
          />

          <Button appearance="filled" onPress={handleBookPress} style={globalStyle.marginTop30}>
            {t('common.bookAdditionalSeats', { defaultValue: 'Book additional seats' }) as string}
          </Button>

          <TouchableOpacity
            style={styles.closeButton}
            onPress={handleClose}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
          >
            <Icon color={(styles.closeIcon as any).color} name="window-close" size={26} />
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default AdditionalSeatsModal;
