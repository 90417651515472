import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { Spinner } from '@ui-kitten/components/ui';

export const LoadingIndicator = ({
  status,
  style,
}: {
  status?: EvaStatus;
  style?: StyleProp<ViewStyle>;
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={[styles.container, style]}>
      <Spinner size="small" status={status} />
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
  },
});
