import React from 'react';
import { ScrollView } from 'react-native';

import AccountActivationComponent from '../../components/account-activation.component';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import { TopNavigationWithoutAccessories } from '../../components/top-navigation-without-accessories.component';

export const CommonAccountActivationScreen = ({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}): React.ReactElement => {
  const renderNavigationHeader = () => {
    if (route.params?.previousScreenName) {
      return (
        <TopNavigationWithBackButton
          title="Account Activation"
          navigation={navigation}
          onPressLeft={() => {
            navigation.navigate(route.params?.previousScreenName);
          }}
        />
      );
    }
    return <TopNavigationWithoutAccessories title="Account Activation" />;
  };

  return (
    <>
      {renderNavigationHeader()}
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <AccountActivationComponent />
      </ScrollView>
    </>
  );
};
