import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { RootStackParamList } from './app.navigator';
import { AgencyCourierAvailabilitiesScreen } from '../screens/agency/agency.courier-availabilities.screen';
import { AgencyCourierDocumentsMenuScreen } from '../screens/agency/AgencyCourierDocumentsMenuScreen';
import { AgencyCourierUserDocumentsScreen } from '../screens/agency/AgencyCourierUserDocumentsScreen';
import { AgencyCourierUserProfileScreen } from '../screens/agency/AgencyCourierUserProfile';
import { AgencyReadCourierUserDocumentScreen } from '../screens/agency/AgencyReadCourierUserDocumentScreen';

const StackNavigator = createStackNavigator<RootStackParamList>();

export function AgencyCourierAvailabilitiesNavigator() {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="AgencyCourierAvailabilitiesScreen"
    >
      <StackNavigator.Screen
        name="AgencyCourierAvailabilitiesScreen"
        component={AgencyCourierAvailabilitiesScreen}
      />
      <StackNavigator.Screen
        name="AgencyCourierUserProfileScreen"
        component={AgencyCourierUserProfileScreen}
      />
      <StackNavigator.Screen
        name="AgencyCourierDocumentsMenuScreen"
        component={AgencyCourierDocumentsMenuScreen}
      />
      <StackNavigator.Screen
        name="AgencyReadCourierUserDocumentScreen"
        component={AgencyReadCourierUserDocumentScreen}
      />
      <StackNavigator.Screen
        name="AgencyCourierUserDocumentsScreen"
        component={AgencyCourierUserDocumentsScreen}
      />
    </StackNavigator.Navigator>
  );
}
