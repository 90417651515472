import React from 'react';
import { View } from 'react-native';

import { Divider, TopNavigation } from '@ui-kitten/components/ui';

import { PriojetLogoSvg } from '../assets/images/priojet-logo';
import ThemeContext from '../contexts/ThemeContext';

const renderTitle = ({ theme }: { theme: string }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <PriojetLogoSvg height={40} width={200} />
    </View>
  );
};

export const TopNavigationLogoOnly = () => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <>
      <TopNavigation
        title={renderTitle({ theme: themeContext.theme })}
        alignment="center"
      />
      <Divider />
    </>
  );
};
