import React, { useContext } from 'react';
import { Platform, RefreshControl, ScrollView, View } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Text } from '@ui-kitten/components';
import * as AuthSession from 'expo-auth-session';
import Constants from 'expo-constants';
import { useTranslation } from 'react-i18next';
import JSONTree, { Renderable } from 'react-native-json-tree';

import { globalStyle } from '../../common/style';
import { TopNavigationBackToProfileButton } from '../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import { GLOBAL_CONSTANTS } from '../../globals';
import { AddDeviceLogInput } from '../../graphql-types';
import { findOrCreateDevice, getDeviceIdFromStorage } from '../../modules/device/device.module';
import { reloadApp } from '../../modules/helper/app-reload.helper';
import { AuthenticationService } from '../../services/authentication.service';
import { parseJsonWithDateTime } from '../../utils/json-parse-with-date-time';

const wait = (timeout: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const CommonAppInformationScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  const [asyncStorageKeys, setAsyncStorageKeys] = React.useState<string[]>([]);
  const [asyncStorageItems, setAsyncStorageItems] = React.useState<Record<string, any>>();
  const [userContexts, setUserContexts] = React.useState<Record<string, any>>();
  const [deviceLogCount, setDeviceLogCount] = React.useState<number>(0);
  const [deviceId, setDeviceId] = React.useState<string | null>(null);

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    const authenticationService = new AuthenticationService();
    authenticationService.loadLocalUserContextsAsync().then((_userContexts) => {
      setUserContexts(_userContexts);
    });
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const setAsyncStorageData = async () => {
    const keys = await AsyncStorage.getAllKeys();
    const _asyncStorageItems: Record<string, any> = {};
    if (keys) {
      for (const key of keys) {
        let value = await AsyncStorage.getItem(key);
        if (value) {
          value = parseJsonWithDateTime(value);
        }
        _asyncStorageItems[key] = value;
      }
    }

    let _deviceLogCount = 0;
    await AsyncStorage.getItem(GLOBAL_CONSTANTS.DEVICE_LOG_INPUTS_STORAGE_KEY).then((data) => {
      if (data !== null && data != '') {
        const _deviceLogInputs: AddDeviceLogInput[] | null = JSON.parse(data);
        _deviceLogCount = _deviceLogInputs?.length || 0;
      }
    });

    Promise.all([
      setAsyncStorageItems(_asyncStorageItems),
      setAsyncStorageKeys([...keys]),
      setDeviceLogCount(_deviceLogCount),
    ]);
  };

  React.useEffect(() => {
    setAsyncStorageData();
    getDeviceIdFromStorage().then((_deviceId) => {
      setDeviceId(_deviceId);
    });
  }, []);

  React.useEffect(() => {
    if (refreshing) {
      setAsyncStorageData();
    }
  }, [refreshing]);

  const purgeSettingsAndCache = async () => {
    await AsyncStorage.getAllKeys((_, keys) => {
      if (keys) {
        keys = keys.filter(
          (value) =>
            value !== GLOBAL_CONSTANTS.DEVICE_ID_STORAGE_KEY &&
            value !== GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY,
        );
        AsyncStorage.multiRemove(keys);
        appUserContext.purgeCurrentUserContext();
      }
    });
    await AsyncStorage.getAllKeys((_, keys) =>
      keys ? setAsyncStorageKeys([...keys]) : setAsyncStorageKeys([]),
    );
  };

  return (
    <>
      <TopNavigationBackToProfileButton
        title={t('menuItems.appInformation', { defaultValue: 'App Information' }) as string}
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />
      <ScrollView
        style={globalStyle.padding10}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        <View>
          <Text selectable={true}>
            App Version: {Constants.expoConfig?.version as string}
            {'\n'}
            Build:{' '}
            {Platform.OS === 'ios'
              ? (Constants.expoConfig?.ios?.buildNumber as string)
              : (Constants.expoConfig?.android?.versionCode as unknown as string)}
            {'\n'}
            Device ID: {deviceId as string}
            {'\n'}
            API Server: {Constants?.expoConfig?.extra?.priojetBackendEndpoint}
          </Text>

          {/** Development App Information **/}

          {Constants?.expoConfig?.extra?.priojetAppEnv === 'development' ? (
            <View
              style={[
                globalStyle.borderWidth1,
                globalStyle.borderBgColor1,
                globalStyle.padding10,
                globalStyle.marginBottom10,
                globalStyle.marginVertical10,
              ]}
            >
              <Text style={{ color: 'gray', marginVertical: 10 }}>
                !!! This is only showing with EXPO_APP_ENV == 'development'
              </Text>
              <Text selectable={true}>NODE_ENV: {process.env.NODE_ENV as string}</Text>
              <Text selectable={true}>
                APP_ENV: {Constants?.expoConfig?.extra?.priojetAppEnv as string}
              </Text>
              <Text selectable={true}>
                __DEV__: {(process.env.__DEV__ === undefined ? 'false' : 'true') as string}
              </Text>
              <Text selectable={true}>
                Process ENV:{' '}
                {
                  Object.entries(process.env)
                    .map((entry) => `"${entry[0]}:${entry[1]}"`)
                    .join(' | ') as string
                }
              </Text>
              <Text selectable={true}>
                Active Directory return URI:{' '}
                {AuthSession.makeRedirectUri({
                  path: Platform.OS === 'web' ? '' : 'auth',
                })}
              </Text>
              <Text>appUserContext.currentUserContext:</Text>
              <JSONTree data={appUserContext.currentUserContext as Renderable} />
              <Text>User Contexts from Storage:</Text>
              <JSONTree data={userContexts as Renderable} />
              <Text>Constants.expoConfig.extra: </Text>
              <JSONTree data={Constants.expoConfig?.extra as Renderable} />
              {Object.entries(asyncStorageItems || {}).map((entry) => {
                let storageItemParsed: any;
                if (entry[1]) {
                  storageItemParsed = parseJsonWithDateTime(entry[1]);
                }
                return (
                  <>
                    <Text>AsyncStorage['{entry[0]}']:</Text>
                    <JSONTree
                      data={
                        (storageItemParsed !== 'undefined' ? storageItemParsed : {}) as Renderable
                      }
                    />
                  </>
                );
              })}
              <Text selectable={true}>Async Storage Keys: {asyncStorageKeys.join('\n')}</Text>
              <Text selectable={true}>Number of DeviceLogs: {deviceLogCount}</Text>
            </View>
          ) : (
            <></>
          )}

          <View>
            <Button
              appearance="ghost"
              size="small"
              onPress={() => {
                purgeSettingsAndCache().then(() => {
                  reloadApp();
                });
              }}
            >
              Reset App Storage and Cache
            </Button>
            <Button
              appearance="ghost"
              size="small"
              onPress={() => {
                const func = async () => {
                  await AsyncStorage.multiRemove([
                    GLOBAL_CONSTANTS.USER_CONTEXTS_STORAGE_KEY,
                    GLOBAL_CONSTANTS.CURRENT_USER_CONTEXT_STORAGE_KEY,
                    GLOBAL_CONSTANTS.DEVICE_ID_STORAGE_KEY,
                    GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY,
                    GLOBAL_CONSTANTS.RECENT_ACTIVITY_FILTER,
                    GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
                  ]);
                  const device = await findOrCreateDevice();
                  if (device) {
                    appUserContext.setDevice(device);
                  }
                };
                func().then(() => {
                  reloadApp();
                });
              }}
            >
              Reset Device ID
            </Button>
          </View>
          <View>
            <Text
              selectable={true}
              style={[
                globalStyle.fontLatoBold,
                globalStyle.marginBottom10,
                globalStyle.marginTop20,
              ]}
            >
              Copyright, Licenses and Packages / Software used
            </Text>
            <Text selectable={true}>
              This application (app) uses open-source software ("Packages") with the following
              licenses including, but not limited to, MIT, BSD-2-Clause, ISC, and others.{'\n'}
              {'\n'}
              In case you have questions regarding the software or licences used, please refer to
              support@priojet.com with due reason.
              {'\n'}
              {'\n'}
            </Text>
            <Text selectable={true}>
              Packages being used: {'\n'}
              {'\n'}
              @apollo/client @babel/core @babel/preset-env @eva-design/eva @expo/metro-config
              @expo/ngrok @expo/vector-icons @expo/webpack-config @fingerprintjs/fingerprintjs
              @react-native-async-storage/async-storage @react-native-community/netinfo
              @react-navigation/bottom-tabs @react-navigation/material-top-tabs
              @react-navigation/native @react-navigation/stack @types/javascript-time-ago
              @types/jwt-decode @types/lodash @types/luxon @types/react-native @types/react
              @types/traverse @types/uuid @typescript-eslint/eslint-plugin @typescript-eslint/parser
              @ui-kitten/components @ui-kitten/eva-icons apollo-link-offline apollo3-cache-persist
              axios babel-loader class-validator dotenv eslint-config-airbnb eslint-config-prettier
              eslint-config-universe eslint-plugin-import eslint-plugin-jsx-a11y
              eslint-plugin-prettier eslint-plugin-react-hooks eslint-plugin-react eslint
              expo-application expo-auth-session expo-battery expo-blur expo-clipboard
              expo-constants expo-contacts expo-device expo-document-picker expo-file-system
              expo-font expo-image-picker expo-linking expo-localization expo-location expo-network
              expo-notifications expo-secure-store expo-sharing expo-splash-screen expo-task-manager
              expo-updates expo-web-browser expo fbjs file-loaders fs@0.0.1-security graphql husky
              i18next intl javascript-time-ago jwt-decode lint-staged lodash luxon prettier radium
              react-devtools react-dom react-google-maps react-i18next react-native-calendars
              react-native-elements react-native-flags react-native-flash-message
              react-native-gesture-handler react-native-get-random-values react-native-gifted-chat
              react-native-google-places-autocomplete react-native-maps react-native-modal
              react-native-pager-view react-native-reanimated react-native-safe-area-context
              react-native-screens react-native-svg-transformer react-native-svg
              react-native-tab-view react-native-vector-icons react-native-web-hooks
              react-native-web-maps react-native-web react-native react-refresh react sentry-expo
              traverse typescript uuid @nestjs/cli @neuralegion/nexploit-cli @types/node
              apollo-codegen eas-cli husky jest nodemon npm react-devtools react-native sharp-cli
              tls-test ts-node typescript ...
            </Text>
          </View>
        </View>
      </ScrollView>
    </>
  );
};
