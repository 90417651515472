import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Divider, Tab, TabBar, Text } from '@ui-kitten/components';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { globalStyle } from '../../common/style';
import { CourierAvailabilities } from '../../components/common/courier-availabilities/courier-availabilities.component';
import { TopNavigationWithoutAccessories } from '../../components/top-navigation-without-accessories.component';
import {
  Availability,
  useAllActiveAndUpcomingAvailabilitiesForPriojetQuery,
} from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';

const { Navigator, Screen } = createMaterialTopTabNavigator();

interface IAvailabilitiesData {
  availabilitiesActive: Availability[];
  availabilitiesUpcoming: Availability[];
  availabilitiesPast: Availability[];
}

const sortActiveAvailabilities = (a: Availability, b: Availability) => {
  if (a.isAdHocAvailability && !b.isAdHocAvailability) {
    return -1;
  } else if (!a.isAdHocAvailability && b.isAdHocAvailability) {
    return 1;
  } else if (Date.parse(a.startTime.toString()) < Date.parse(b.startTime.toString())) {
    return -1;
  }
  return 1;
};

export const PriojetCourierAvailabilitiesScreen = (): React.ReactElement => {
  const isBackendReachable = useIsBackendReachable();

  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, 1000 * 60);
    return () => clearInterval(interval);
  });

  const { error, loading, data, refetch } = useAllActiveAndUpcomingAvailabilitiesForPriojetQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const { availabilitiesActive, availabilitiesUpcoming, availabilitiesPast }: IAvailabilitiesData =
    useMemo((): IAvailabilitiesData => {
      if (data?.allActiveAndUpcomingAvailabilitiesForPriojet?.length) {
        const allActiveAndUpcomingAvailabilitiesForPriojet =
          data.allActiveAndUpcomingAvailabilitiesForPriojet as Availability[];
        const unsortedData = allActiveAndUpcomingAvailabilitiesForPriojet.reduce(
          (acc: IAvailabilitiesData, current: Availability) => {
            if (!current.endTime || current.endTime >= currentTime) {
              if (current.isAdHocAvailability || current.startTime < currentTime) {
                acc.availabilitiesActive.push(current);
              } else {
                acc.availabilitiesUpcoming.push(current);
              }
            } else {
              acc.availabilitiesPast.push(current);
            }
            return acc;
          },
          {
            availabilitiesActive: [],
            availabilitiesUpcoming: [],
            availabilitiesPast: [],
          },
        ) as IAvailabilitiesData;

        return {
          availabilitiesActive: unsortedData.availabilitiesActive.sort(sortActiveAvailabilities),
          availabilitiesUpcoming:
            unsortedData.availabilitiesUpcoming.sort(sortActiveAvailabilities),
          availabilitiesPast: unsortedData.availabilitiesPast.sort((a, b) => {
            if (a.isAdHocAvailability && !b.isAdHocAvailability) {
              return -1;
            } else if (!a.isAdHocAvailability && b.isAdHocAvailability) {
              return 1;
            } else if (
              Date.parse(a.endTime?.toString() as string) >
              Date.parse(b.endTime?.toString() as string)
            ) {
              return -1;
            }
            return 1;
          }),
        };
      }
      return { availabilitiesActive: [], availabilitiesUpcoming: [], availabilitiesPast: [] };
    }, [currentTime, data?.allActiveAndUpcomingAvailabilitiesForPriojet]);

  const handleRefresh = () => {
    setCurrentTime(DateTime.now());
    refetch();
  };

  const renderTopBar = ({ navigation, state }: { navigation: any; state: any }) => {
    return (
      <TabBar
        selectedIndex={state.index}
        onSelect={(index) => navigation.navigate(state.routeNames[index])}
        style={globalStyle.height50}
      >
        <Tab title={t('common.active', { defaultValue: 'ACTIVE' }) as string} />
        <Tab title={t('common.upcoming', { defaultValue: 'UPCOMING' }) as string} />
        <Tab title={t('common.finished', { defaultValue: 'FINISHED' }) as string} />
      </TabBar>
    );
  };

  if (error) {
    return (
      <View>
        <Text selectable={true}>Error...{error.message}</Text>
      </View>
    );
  }

  return (
    <>
      <TopNavigationWithoutAccessories
        title={t('common.courierAvailabilities', {
          defaultValue: 'Courier Availabilities',
        })}
      />
      <Divider />
      <Navigator tabBar={renderTopBar}>
        <Screen name="PriojetCourierActiveAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesActive}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={false}
            />
          )}
        </Screen>
        <Screen name="PriojetCourierUpcomingAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesUpcoming}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={false}
            />
          )}
        </Screen>
        <Screen name="PriojetCourierPastAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesPast}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={false}
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};
