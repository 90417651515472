import { InMemoryCache } from '@apollo/client/cache';

import introspectionResult from '../generated-graphql-fragment-matcher';

export const getApolloCacheInstance = (): InMemoryCache => {
  return new InMemoryCache({
    // generated by Fragment Matcher plugin
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      Availability: {
        merge: true,
      },
    },
  });
};
