import { useContext, useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { useQuery } from '@apollo/client';
import Constants from 'expo-constants';
import { DateTime } from 'luxon';

import { UseHookProps } from './types';
import { QUERY_SUBSCRIPTION_PLANS_AGENCY } from '../../../../../apollo/graphql-queries';
import AppUserContext from '../../../../../contexts/AppUserContext';
import ThemeContext from '../../../../../contexts/ThemeContext';
import {
  SubscriptionPlanPaddleType,
  SubscriptionPlanType,
} from '../../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../../hooks/useIsBackendReachable';

export const useHook = ({
  onClose,
  onBookSuccess,
  onBookToggle,
  refetchOrganizationSubscriptionPlans,
  visibleModal,
}: UseHookProps) => {
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);
  const theme = useContext(ThemeContext);

  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanPaddleType | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>('month');

  const { data } = useQuery(QUERY_SUBSCRIPTION_PLANS_AGENCY, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const agencyPlans = useMemo(() => {
    if (data?.subscriptionPlansAgency && data.subscriptionPlansAgency?.[selectedPeriod]) {
      const plans = data?.subscriptionPlansAgency?.[selectedPeriod];
      const index = Object.values(SubscriptionPlanType).findIndex(
        (item) => item === SubscriptionPlanType.AGENCY_ADDITIONAL_SEATS,
      );
      if (plans?.length) {
        return plans.filter(
          (item: SubscriptionPlanPaddleType) => item.subscriptionPlanType === index + 1,
        );
      }
      return plans;
    }
    return [];
  }, [data, selectedPeriod]);

  const handleSelectPeriod = (period: any, _index: number) => {
    setSelectedPlan(undefined);
    setSelectedPeriod(period.value);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setTimeout(() => {
      setSelectedPeriod('month');
      setSelectedPlan(undefined);
    }, 200);
  };

  const handleSelectPlan = (_item: any) => {
    setSelectedPlan(_item);
  };

  const handleBookPress = async () => {
    onClose();
    setTimeout(() => {
      onBookToggle(true);
      if (Platform.OS === 'web') {
        const win: any = window;
        const Paddle = win.Paddle;
        if (appUserContext.currentUserContext?.organization?.id) {
          Paddle.Checkout.open({
            product: selectedPlan?.planId,
            email: appUserContext.currentUserContext?.user?.email,
            passthrough: JSON.stringify({
              initialCreationDate: DateTime.now().toUTC().toString(),
              organizationId: appUserContext.currentUserContext?.organization?.id,
              userId: appUserContext.currentUserContext?.user?.id,
            }),
            marketingConsent: '1',
            displayModeTheme: theme.theme,
          });
        }
      }
    }, 200);
  };

  const eventCallback = (e: any) => {
    switch (e.event) {
      case 'Checkout.Customer.Details':
        break;
      case 'Checkout.Loaded':
        break;
      case 'Checkout.Vat.Applied':
        break;
      case 'Checkout.Complete':
        refetchOrganizationSubscriptionPlans();
        appUserContext.refetchCurrentUserContext();
        onBookSuccess(e.eventData.product.name);
        break;
      case 'Checkout.Close':
        onBookToggle(false);
        setSelectedPlan(undefined);
        setSelectedPeriod('month');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const lazyLoadFromCDN = (callback: Function) => {
        const mathJax = document.createElement('script');
        mathJax.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js');
        mathJax.addEventListener('load', () => callback());
        document.head.appendChild(mathJax);
      };
      const onLoad = () => {
        const win = window as any;
        const Paddle = win.Paddle;
        Paddle.Environment.set(Constants.expoConfig?.extra?.paddleEnvironment);
        Paddle.Setup({
          vendor: Number(Constants.expoConfig?.extra?.paddleVendorId),
          eventCallback,
        });
      };
      lazyLoadFromCDN(onLoad);
    }
  }, []);

  useEffect(() => {
    if (!selectedPlan && agencyPlans[0]) {
      setSelectedPlan(agencyPlans[0]);
    }
  }, [agencyPlans, selectedPlan, visibleModal]);

  return {
    agencyPlans,
    handleBookPress,
    handleClose,
    handleSelectPlan,
    handleSelectPeriod,
    selectedPlan,
    selectedPeriod,
  };
};
