import { Dimensions, Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

const { width: screenWidth } = Dimensions.get('screen');

let width = screenWidth;
if (Platform.OS === 'web' && screenWidth > 1000) {
  width = 1000;
}

export const styles = StyleService.create({
  imageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    marginTop: 10,
    width: width * 0.6,
    height: width * 0.6 * 0.66,
    maxWidth: 480,
    maxHeight: 320,
    borderRadius: 8,
  },
  downloadImageButton: {
    padding: 10,
    paddingRight: 0,
  },
  downloadIcon: {
    width: 20,
    height: 20,
    color: 'text-basic-color',
  },
});
