import React, { useContext } from 'react';
import { Platform, View } from 'react-native';

import { useMutation } from '@apollo/client';
import { Button, Icon, Layout, StyleService, useTheme } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { VOID_ORGANIZATION_INVITATION_MUTATION } from '../../../../apollo/graphql-mutations';
import { ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY } from '../../../../apollo/graphql-queries';
import { globalStyle } from '../../../../common/style';
import { OrganizationInvitation } from '../../../../generated-graphql-types';
import { CreatedAtByText } from '../../created-at-by-text.component';
import { ConfirmModalContext } from '../../modal/ConfirmModal';
import { UpdatedAtText } from '../../updated-at-text.component';

export const OrganizationInvitationCardItemFooter = ({
  footerProps,
  organizationInvitation,
}: {
  footerProps: any;
  organizationInvitation: OrganizationInvitation;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const [voidOrganizationInvitation] = useMutation(VOID_ORGANIZATION_INVITATION_MUTATION, {
    refetchQueries: [ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY],
    onCompleted: (data) => {
      showMessage({
        message: t('common.organizationInvitationVoided', {
          defaultValue: 'Organization Invitation voided',
        }),
        description: `The Organization Invitation with activation code ${data.voidOrganizationInvitation.activationCode} was voided and cannot be used anymore.`,
        renderFlashMessageIcon: () => <Icon name="check-outline" />,
        type: 'success',
      });
    },
  });

  const handleVoidOrganizationInvitation =
    (_organizationInvitation: OrganizationInvitation) => () => {
      showConfirmModal({
        confirmButtonStatus: 'primary',
        confirmButtonAppearance: 'filled',
        confirmButtonText: t('common.void', { defaultValue: 'Void' }),
        cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
        title: 'Void Organization Invitation Activation Code',
        text: `Do you really want to void the Organization Invitation with the activation code ${_organizationInvitation.activationCode}?\n\nThis action cannot be undone and the activation code cannot be used after being voided, even if it was already sent to a user.`,
        onConfirmPress: async () => {
          await voidOrganizationInvitation({
            variables: { id: _organizationInvitation.id },
          });
          return true;
        },
        visible: true,
      });
    };

  return (
    <View {...footerProps} style={[globalStyle.paddingTop10, globalStyle.flexColumn]}>
      {!organizationInvitation.voidedAt && !organizationInvitation.usedAt && (
        <View style={styles.actionButtonsContainer}>
          <Button
            size="tiny"
            status="danger"
            style={styles.actionButton}
            onPress={handleVoidOrganizationInvitation(organizationInvitation)}
            accessoryLeft={(props: any) => <Icon {...props} name="trash-outline" />}
          />
        </View>
      )}
      <Layout
        style={[
          globalStyle.alignItemsCenter,
          globalStyle.flexColumn,
          globalStyle.justifyContentStart,
          Platform.OS === 'web' && globalStyle.marginTop10,
          Platform.OS === 'web' && styles.minHeight40,
        ]}
      >
        <CreatedAtByText
          firstNames={organizationInvitation?.createdByUser?.firstNames || 'n/a'}
          lastName={organizationInvitation?.createdByUser?.lastName || 'n/a'}
          email={organizationInvitation?.createdByUser?.email || 'n/a'}
          createdAt={organizationInvitation.createdAt}
          textColor={theme['text-basic-400']}
        />
        <UpdatedAtText
          updatedAtDate={organizationInvitation.updatedAt}
          textColor={theme['text-basic-400']}
        />
      </Layout>
    </View>
  );
};

const styles = StyleService.create({
  container: {
    flex: 1,
  },
  actionButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButtonWrapper: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  actionButton: {
    marginLeft: 10,
  },
  list: {
    width: '100%',
    height: 'auto',
    padding: 10,
  },
  item: {
    marginVertical: 10,
  },
  tabContainer: {
    height: 'auto',
    width: '100%',
    marginBottom: 180,
    alignItems: 'center',
  },
  noResultsView: {
    paddingTop: 20,
  },
  minHeightAuto: {
    minHeight: 'auto',
  },
  minHeight40: {
    minHeight: 40,
  },
});
