import React, { useEffect, useRef, useState } from 'react';

import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from 'react-google-maps';
import { compose, withProps } from 'recompose';

import { JourneyLocationType } from './common.job-journey-map.component';
import { getGoogleMapsApiKey } from '../../../../utils/google';

export const markerImages: any = {
  '1': require('../../../../assets/images/marker1.png'),
  '2': require('../../../../assets/images/marker2.png'),
  '3': require('../../../../assets/images/marker3.png'),
  '4': require('../../../../assets/images/marker4.png'),
};

const MapViewJourneyComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${getGoogleMapsApiKey(
      'web',
    )}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ active, initialLocation, lineColor, markers, onMarkerPress, selectedMarker }: any) => {
  const mapRef: any = useRef();

  const [activated, setActivated] = useState(false);
  const [zoom, setZoom] = useState(4);
  const [currentInitialLocation, setCurrentInitialLocation] = useState(initialLocation);

  useEffect(() => {
    if (active && !activated) {
      setActivated(true);
      setTimeout(() => {
        handleMarkerPress(selectedMarker, 0)();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, activated]);

  useEffect(() => {
    if (
      currentInitialLocation.latitude !== initialLocation.latitude ||
      currentInitialLocation.longitude !== initialLocation.longitude
    ) {
      setCurrentInitialLocation(initialLocation);
      mapRef.current.panTo({ lat: initialLocation.latitude, lng: initialLocation.longitude });
      setZoom(10);
    }
  }, [currentInitialLocation, initialLocation]);

  const handleMarkerPress =
    (item: JourneyLocationType, index: number, nextZoom = 10) =>
    () => {
      if (mapRef.current?.panTo) {
        mapRef.current.panTo({ lat: item.latitude, lng: item.longitude });
      }
      onMarkerPress(item, index);
      setZoom(nextZoom);
    };

  return (
    // @ts-ignore
    <GoogleMap
      ref={mapRef as any}
      defaultOptions={{
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: { position: 8.0 },
      }}
      options={{
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: { position: 8.0 },
        zoom,
        minZoom: 3,
      }}
      defaultCenter={{
        lat: currentInitialLocation.latitude,
        lng: currentInitialLocation.longitude,
      }}
    >
      {markers?.map((marker: any, index: number) => {
        if (!marker.visible) {
          return null;
        }
        return (
          <Marker
            key={`${marker.latitude}-${marker.longitude}-${index}`}
            onClick={handleMarkerPress(marker, index)}
            position={{ lat: marker.latitude, lng: marker.longitude }}
            icon={{
              url: markerImages[marker.enumeration],
              scaledSize: new window.google.maps.Size(30, 38, 'px', 'px'),
            }}
            zIndex={selectedMarker?.enumeration === marker.enumeration ? 10 : index * -1}
          />
        );
      })}
      <Polyline
        options={{ geodesic: true, strokeColor: lineColor }}
        path={markers.map((marker: any) => ({ lat: marker.latitude, lng: marker.longitude }))}
      />
    </GoogleMap>
  );
});

const compareMap = (prev: any, next: any) => {
  if (
    prev.initialLocation.latitude !== next.initialLocation.latitude ||
    prev.initialLocation.longitude !== next.initialLocation.longitude
  ) {
    return false;
  }
  if (
    (prev.selectedMarker && !next.selectedMarker) ||
    (!prev.selectedMarker && next.selectedMarker)
  ) {
    return false;
  }
  if (
    prev.selectedMarker &&
    next.selectedMarker &&
    (prev.selectedMarker.latitude !== next.selectedMarker.latitude ||
      prev.selectedMarker.longitude !== next.selectedMarker.longitude ||
      prev.selectedMarker.enumeration !== next.selectedMarker.enumeration)
  ) {
    return false;
  }
  if (prev.lineColor !== next.lineColor) {
    return false;
  }
  if (prev.active !== next.active) {
    return false;
  }
  return true;
};

export const MapViewJourney = React.memo(MapViewJourneyComponent, compareMap);
