import { Platform } from 'react-native';

import * as AuthSession from 'expo-auth-session';
import { generateRandom } from 'expo-auth-session/build/PKCE';

export const discoveryDocument = {
  authorizationEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/authorize',
  registrationEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/authorize',
  tokenEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/token',
  endSessionEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/logout',
  revocationEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/logout',
};

export const discoveryDocumentSignup = {
  authorizationEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signup/oauth2/v2.0/authorize',
  registrationEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/authorize',
  tokenEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/token',
  endSessionEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/logout',
  revocationEndpoint:
    'https://priojetapp.b2clogin.com/priojetapp.onmicrosoft.com/b2c_1_app_signin/oauth2/v2.0/logout',
};

export const authRequestConfig: AuthSession.AuthRequestConfig = {
  responseType: AuthSession.ResponseType.Code,
  clientId: 'efff5ff7-1634-43da-8cb6-4065ca259287',
  redirectUri: AuthSession.makeRedirectUri({
    // useProxy: false,
    path: Platform.OS === 'web' ? '' : 'auth',
  }),
  prompt: AuthSession.Prompt.Login,
  scopes: ['efff5ff7-1634-43da-8cb6-4065ca259287', 'offline_access'],
  state: generateRandom(32),
  usePKCE: true,
  extraParams: {
    show_dialog: 'true',
    p: 'b2c_1_app_signin',
    nonce: generateRandom(43),
  },
};

export const authRequestConfigSignup: AuthSession.AuthRequestConfig = {
  ...authRequestConfig,
  state: generateRandom(32),
  extraParams: {
    show_dialog: 'true',
    p: 'b2c_1_app_signup',
    nonce: generateRandom(43),
  },
};

export const authRequestConfigPasswordReset: AuthSession.AuthRequestConfig = {
  ...authRequestConfig,
  scopes: ['efff5ff7-1634-43da-8cb6-4065ca259287', 'openid'],
  state: generateRandom(32),
  extraParams: {
    p: 'B2C_1_resetpassword',
    nonce: generateRandom(43),
  },
};

export const GLOBAL_CONSTANTS = {
  DEVICE_ID_STORAGE_KEY: 'PRIOJET_DEVICE_ID',
  DEVICE_LOG_INPUTS_STORAGE_KEY: 'PRIOJET_DEVICE_LOG_INPUTS',
  BACKGROUND_LOCATION_TASK_NAME: 'PRIOJET_BACKGROUND_LOCATION_TASK',
  DEVICE_LOCATION_INPUTS_STORAGE_KEY: 'PRIOJET_DEVICE_LOCATION_INPUTS',
  USER_CONTEXTS_LAST_UPDATE_STORAGE_KEY: 'PRIOJET_USER_CONTEXTS_LAST_UPDATE',
  APP_LANGUAGE_KEY: 'PRIOJET_APP_LANGUAGE',
  ACTIVE_THEME_NAME_STORAGE_KEY: 'PRIOJET_THEME_NAME',
  APOLLO_CACHE_PERSIST_STORAGE_KEY: 'PRIOJET_APOLLO_CACHE_PERSIST',
  DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY: 'PRIOJET_INITIALIZED',
  USER_CONTEXTS_STORAGE_KEY: 'PRIOJET_USER_CONTEXTS',
  CURRENT_USER_CONTEXT_STORAGE_KEY: 'PRIOJET_CURRENT_USER_CONTEXT',
  MIN_SUPPORTED_VERSION: 'MIN_SUPPORTED_VERSION',
  GREETING_DATE_STORAGE_KEY: 'GREETING_DATE_STORAGE_KEY',
  COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS: 'COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS',
  RECENT_ACTIVITY_FILTER: 'RECENT_ACTIVITY_FILTER',
  AGENCY_JOB_TRACK_AND_TRACING: 'AGENCY_JOB_TRACK_AND_TRACING',
};
