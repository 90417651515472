import { useImperativeHandle, useEffect, useMemo, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { IndexPath } from '@ui-kitten/components';
import { DateTime } from 'luxon';

import { FormAirportSelectProps } from './types';
import { MUTATION_AIRPORT_LOCATION_UPDATE_TIMEZONE } from '../../../../apollo/graphql-mutations';
import { QUERY_AIRPORT_LOCATION_LIST } from '../../../../apollo/graphql-queries';
import { Location } from '../../../../generated-graphql-types';
import { resolveTimeZoneForLocation } from '../../../../lib/google-maps-timezone-api';

export const useHook = (props: FormAirportSelectProps, ref: any) => {
  const [initialized, setInitialized] = useState(false);
  const [text, setText] = useState('');

  const [isVisible, setIsVisible] = useState(false);
  const [opened, setOpened] = useState(false);

  const fieldTitleKey = props.fieldTitleKey || 'formattedAddress';
  const fieldValueKey = props.fieldValueKey || 'id';

  const { data, loading } = useQuery(QUERY_AIRPORT_LOCATION_LIST, {
    fetchPolicy: 'cache-first',
    onCompleted: props.onDataLoadCompleted,
    skip: !!props.data || (props.loadOnOpen && !opened),
  });
  const [airportLocationUpdateTimezone] = useMutation(MUTATION_AIRPORT_LOCATION_UPDATE_TIMEZONE, {
    refetchQueries: [QUERY_AIRPORT_LOCATION_LIST],
  });

  const airportLocationList = useMemo(() => {
    if (props.data) {
      return props.data;
    }
    if (data?.airportLocationList) {
      return data.airportLocationList;
    }
    return [];
  }, [data?.airportLocationList, props.data]);

  const updateLocationData = (value: string, option: Location, index: IndexPath) => {
    const currentTime = DateTime.now();
    resolveTimeZoneForLocation(
      {
        location: {
          lat: option.locationGeometry.location.lat,
          lng: option.locationGeometry.location.lng,
        },
      } as any,
      currentTime.toMillis() / 1000,
    )
      .then((timeZoneResponse) => {
        if (
          timeZoneResponse?.status === 'OK' &&
          timeZoneResponse.timeZoneId &&
          timeZoneResponse.rawOffset
        ) {
          airportLocationUpdateTimezone({
            variables: {
              data: {
                airportLocationId: option.id,
                timeZoneIdentifier: timeZoneResponse.timeZoneId,
                timeZoneRawOffset: timeZoneResponse.rawOffset,
              },
            },
          });
          if (props.onSelect) {
            props.onSelect(
              value,
              {
                ...option,
                timeZoneIdentifier: timeZoneResponse.timeZoneId,
                timeZoneRawOffset: timeZoneResponse.rawOffset,
              },
              index,
            );
          }
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  const handleChangeText = (val: string) => {
    setText(val);
    if (!initialized) {
      setInitialized(true);
    }
  };

  const handleSelect = (_: any, index: any, option: any) => {
    if (option) {
      setText(option.formattedAddress);
      if (props.onSelect) {
        props.onSelect(option[fieldValueKey], option, index);
      }
      if (props.onChangeText) {
        props.onChangeText(option.formattedAddress);
      }

      if (!option.timeZoneIdentifier || !option.timeZoneRawOffset) {
        updateLocationData(option[fieldValueKey], option, index);
      }
    }
  };

  const handleReset = () => {
    setText('');
    if (props.onSelect) {
      props.onSelect(null, null, null);
    }
    if (props.onChangeText) {
      props.onChangeText('');
    }
  };

  const handleOpenPress = () => {
    if (props.disabled) {
      return;
    }

    setOpened(true);
    setTimeout(() => {
      setIsVisible(true);
    }, 50);
  };

  const handleClosePress = () => {
    setIsVisible(false);
    setTimeout(() => {
      setOpened(false);
    }, 200);
  };

  const filterDataCustom = (items: Location[], searchText: string): Location[] => {
    if (searchText) {
      const search = searchText.toLowerCase();

      let filteredData = items.filter((item) =>
        item.formattedAddress?.toLowerCase().includes(search),
      );
      filteredData = [...filteredData].sort((a, b) => {
        const iataCodeA = (a.iataCode || '').toLowerCase();
        const iataCodeB = (b.iataCode || '').toLowerCase();
        if (iataCodeA.startsWith(searchText)) {
          return -1;
        }
        if (iataCodeB.startsWith(searchText)) {
          return 1;
        }
        return 0;
      });
      return filteredData;
    }
    return items;
  };

  useImperativeHandle(ref, () => ({
    setTextValue: handleChangeText,
  }));

  useEffect(() => {
    if (props.value?.[fieldValueKey] && airportLocationList.length && !initialized) {
      if (props.value.formattedAddress) {
        setText(props.value.formattedAddress);
        setInitialized(true);
      } else {
        const item = airportLocationList?.find(
          (i: any) => i[fieldValueKey] === props.value?.[fieldValueKey],
        );
        if (item) {
          setText(item.formattedAddress);
          setInitialized(true);
        }
      }
    }
  }, [airportLocationList, initialized, fieldTitleKey, fieldValueKey, props.value]);

  return {
    airportLocationList,
    handleChangeText,
    handleClosePress,
    handleOpenPress,
    handleReset,
    handleSelect,
    filterDataCustom,
    isVisible,
    loading,
    opened,
    text,
  };
};
