import React from 'react';
import { Platform, UIManager, View, Pressable } from 'react-native';

import { Button, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native-gesture-handler';

import { useHook } from './hook';
import { InviteCourierUploadCSVModal } from './InviteCourierUploadCSVModal';
import { InviteCourierUploadCSVStepThree } from './InviteCourierUploadCSVStepThree';
import { InviteCourierUploadCSVStepTwo } from './InviteCourierUploadCSVStepTwo';
import { themedStyles } from './styles';
import { Props } from './types';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import useDimensions from '../../../../hooks/useDimensions';

if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

export const InviteCourierUploadCSV: React.FC<Props> = ({ refetch }) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);
  const { isLargeDevice, isExtraLargeDevice } = useDimensions();
  const isWebView = isLargeDevice || isExtraLargeDevice;

  const {
    contactsToInvite,
    data,
    handleCheckboxSelect,
    handleCheckboxSelectAll,
    handleLabelChange,
    handleFieldTextChange,
    handleImportContactsPress,
    handleResetDocument,
    handleResetImportContacts,
    handleSearchTextChange,
    handleSelectFilePress,
    handleStepPress,
    handleUploadFilePress,
    isAllSelected,
    labels,
    loading,
    searchText,
    selectedDocument,
    selectedInvites,
    step,
    tableWidth,
  } = useHook({ refetch });

  const renderFirstStep = () => (
    <Button
      appearance="filled"
      status=""
      size="medium"
      onPress={handleSelectFilePress}
      style={[globalStyle.marginVertical10, globalStyle.width300]}
    >
      {t('common:selectCSVFile', { defaultValue: 'Select CSV file' })}
    </Button>
  );

  const renderSteps = () => {
    const showStep1 = isWebView || step === 1;
    const showStep2 = isWebView || step === 2;
    const showStep3 = isWebView || step === 3;
    return (
      <View style={[globalStyle.flexRow]}>
        {showStep1 && (
          <Pressable
            style={[globalStyle.flex1, globalStyle.alignItemsCenter]}
            onPress={handleStepPress(1)}
          >
            <Text
              style={[
                globalStyle.fontLatoBlack,
                step === 1 ? globalStyle.fontSize26 : globalStyle.fontSize18,
                step === 1 && globalStyle.colorPrimary600,
              ]}
            >
              {t('common:step', { defaultValue: 'Step' })} 1 {t('common:')}
              {!isWebView && ' / 3'}
            </Text>

            <Text
              style={[
                step === 1 ? globalStyle.fontSize14 : globalStyle.fontSize12,
                globalStyle.textAlignCenter,
                styles.maxWidth300,
              ]}
            >
              {t('common:uploadCSVStep1Subtitle', {
                defaultValue: 'Please select CSV file with contacts you want to invite',
              })}
            </Text>
          </Pressable>
        )}

        {showStep2 && (
          <Pressable
            style={[globalStyle.flex1, globalStyle.alignItemsCenter]}
            onPress={handleStepPress(2)}
          >
            <Text
              style={[
                globalStyle.fontLatoBlack,
                globalStyle.textAlignCenter,
                step === 2 ? globalStyle.fontSize26 : globalStyle.fontSize18,
                step === 2 && globalStyle.colorPrimary600,
              ]}
            >
              {t('common:step', { defaultValue: 'Step' })} 2 {t('common:')}
              {!isWebView && ' / 3'}
            </Text>
            <Text
              style={[
                globalStyle.textAlignCenter,
                styles.maxWidth300,
                step === 2 ? globalStyle.fontSize14 : globalStyle.fontSize12,
              ]}
            >
              {t('common:uploadCSVStep2Subtitle', {
                defaultValue:
                  'First name, last name, email and phone number are mandatory fields. Please add mapping for those fields from your CSV file',
              })}
            </Text>
          </Pressable>
        )}

        {showStep3 && (
          <Pressable
            style={[
              globalStyle.flex1,
              globalStyle.alignItemsCenter,
              globalStyle.justifyContentCenter,
            ]}
            onPress={handleStepPress(3)}
          >
            <Text
              style={[
                globalStyle.fontLatoBlack,
                step === 3 ? globalStyle.fontSize26 : globalStyle.fontSize18,
                step === 3 && globalStyle.colorPrimary600,
              ]}
            >
              {t('common:step', { defaultValue: 'Step' })} 3 {t('common:')}
              {!isWebView && ' / 3'}
            </Text>
            <Text
              style={[
                step === 3 ? globalStyle.fontSize14 : globalStyle.fontSize12,
                globalStyle.textAlignCenter,
                styles.maxWidth300,
              ]}
            >
              {t('common:uploadCSVStep2Subtitle', {
                defaultValue:
                  'Select contacts you want to invite, users will get activation code and will be able to connect with your agency.',
              })}
            </Text>
          </Pressable>
        )}
      </View>
    );
  };

  return (
    <>
      <ScrollView
        contentContainerStyle={[
          globalStyle.width100Percent,
          globalStyle.marginTop10,
          globalStyle.padding15,
        ]}
      >
        {renderSteps()}
        <View style={styles.divider} />

        {!selectedDocument && !data?.length && (
          <View style={globalStyle.marginBottom20}>{renderFirstStep()}</View>
        )}

        {step === 2 && (
          <InviteCourierUploadCSVStepTwo
            handleLabelChange={handleLabelChange}
            handleResetDocument={handleResetDocument}
            handleUploadFilePress={handleUploadFilePress}
            labels={labels}
            selectedDocument={selectedDocument}
          />
        )}
        {step === 3 && (
          <InviteCourierUploadCSVStepThree
            data={data}
            handleCheckboxSelect={handleCheckboxSelect}
            handleCheckboxSelectAll={handleCheckboxSelectAll}
            handleFieldTextChange={handleFieldTextChange}
            handleImportContactsPress={handleImportContactsPress}
            handleResetDocument={handleResetDocument}
            handleSearchTextChange={handleSearchTextChange}
            loading={loading}
            isAllSelected={isAllSelected}
            searchText={searchText}
            selectedInvites={selectedInvites}
            tableWidth={tableWidth}
          />
        )}
      </ScrollView>
      {contactsToInvite?.length && (
        <InviteCourierUploadCSVModal
          contacts={contactsToInvite}
          onResetContacts={handleResetImportContacts}
        />
      )}
    </>
  );
};
