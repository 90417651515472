import React, { memo, useMemo } from 'react';
import { View } from 'react-native';

import { Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { styles as themedStyles } from './styles';
import { globalStyle } from '../../../../../common/style';
import { AgencyJobPlanningFile, MessageType } from '../../../../../generated-graphql-types';
import { ChatMessageImage } from '../ChatMessageImage';
import { ChatMessagePDF } from '../ChatMessagePDF';

export const ChatMessageTrackingPointItemComponent: React.FC<any> = ({
  currentMessage,
  handlePDFPress,
  isCurrentUser,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    actualDateTimeText,
    files,
    label,
    locationText,
    note,
    status,
    targetDateTimeText,
  }: {
    actualDateTimeText: string;
    files: AgencyJobPlanningFile[];
    label: string;
    locationText: string;
    note: string;
    status: string;
    targetDateTimeText: string;
  } = useMemo(() => {
    let _status = t('jobs.trackingPointCompleted', { defaultValue: 'Tracking point completed' });

    if (currentMessage.messageType === MessageType.TRACKER_COURIER_TRACKING_POINT_RESET) {
      _status = t('jobs.trackingPointReset', { defaultValue: 'Tracking point reset' });
    }
    let _actualDateTimeText: string = '';
    let _actualDateTime = currentMessage.agencyJobPlanningItemHistoryLog?.actualDateTime;
    if (typeof _actualDateTime === 'string') {
      _actualDateTime = DateTime.fromJSDate(new Date(_actualDateTime));
    }
    if (_actualDateTime?.toFormat) {
      _actualDateTimeText =
        _actualDateTime.setZone('utc').toFormat('HH:mm') +
        ' LT, ' +
        _actualDateTime.setZone('utc').toFormat('dd MMM yyyy');
    }

    let _targetDateTimeText: string = '';
    let _targetDateTime = currentMessage.agencyJobPlanningItemHistoryLog?.targetDateTime;
    if (typeof _targetDateTime === 'string') {
      _targetDateTime = DateTime.fromJSDate(new Date(_targetDateTime));
    }
    if (_targetDateTime?.toFormat) {
      _targetDateTimeText =
        _targetDateTime.setZone('utc').toFormat('HH:mm') +
        ' LT, ' +
        _targetDateTime.setZone('utc').toFormat('dd MMM yyyy');
    }

    let _label = currentMessage.agencyJobPlanningItemHistoryLog?.label || '';
    if (currentMessage.agencyJobPlanningItemHistoryLog?.agencyJobPlanningLeg?.order) {
      _label = `LEG ${currentMessage.agencyJobPlanningItemHistoryLog?.agencyJobPlanningLeg?.order}`;
      if (
        currentMessage.agencyJobPlanningItemHistoryLog?.agencyJobPlanningLeg
          ?.flightCarrierDesignation &&
        currentMessage.agencyJobPlanningItemHistoryLog?.agencyJobPlanningLeg?.flightNumber
      ) {
        const { flightCarrierDesignation, flightNumber } =
          currentMessage.agencyJobPlanningItemHistoryLog.agencyJobPlanningLeg;
        _label += ` (${flightCarrierDesignation} ${flightNumber})`;
      }
      if (_label.length) {
        _label += ': ';
      }
      if (currentMessage.agencyJobPlanningItemHistoryLog?.label) {
        _label += currentMessage.agencyJobPlanningItemHistoryLog?.label;
      }
    }

    return {
      actualDateTimeText: _actualDateTimeText,
      files: currentMessage.agencyJobPlanningItemHistoryLog?.files || [],
      label: _label,
      locationText:
        currentMessage.agencyJobPlanningItemHistoryLog?.location?.formattedAddress || '',
      note: currentMessage.agencyJobPlanningItemHistoryLog?.note || '',
      status: _status,
      targetDateTimeText: _targetDateTimeText,
    };
  }, [currentMessage, t]);

  const renderFiles = () => {
    if (files.length) {
      return (
        <View
          style={[
            globalStyle.marginTop5,
            isCurrentUser ? globalStyle.alignItemsEnd : globalStyle.alignItemsStart,
          ]}
        >
          {files.map((file) => {
            if (file.storedAsset?.metadata.mimeType?.includes('image')) {
              return (
                <ChatMessageImage
                  key={file.id}
                  chatFile={file.storedAsset}
                  downloadIconFill="white"
                  styleImage={globalStyle.marginTop5}
                />
              );
            }
            if (file.storedAsset?.metadata.mimeType?.includes('pdf')) {
              return (
                <ChatMessagePDF
                  key={file.id}
                  chatFile={file.storedAsset}
                  downloadIconFill="white"
                  handlePDFPress={handlePDFPress}
                  style={[globalStyle.justifyContentEnd, globalStyle.alignContentCenter]}
                  styleButton={[globalStyle.marginBottom0, globalStyle.marginTop5]}
                />
              );
            }
          })}
        </View>
      );
    }
    return <></>;
  };

  return (
    <View key={Math.random()} style={globalStyle.marginTop5}>
      <Text
        style={[
          styles.messageText,
          globalStyle.fontLatoBold,
          isCurrentUser ? styles.textRight : styles.textLeft,
        ]}
        selectable
      >
        {status.toUpperCase()}
      </Text>
      <Text
        style={[
          styles.messageText,
          globalStyle.marginBottom10,
          isCurrentUser ? styles.textRight : styles.textLeft,
        ]}
        selectable
      >
        {label}
      </Text>
      {!!targetDateTimeText && (
        <Text
          style={[
            styles.messageText,
            globalStyle.marginTop10,
            isCurrentUser ? styles.textRight : styles.textLeft,
            isCurrentUser ? globalStyle.flexRowReverse : globalStyle.flexRow,
          ]}
          selectable
        >
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.targetTime', { defaultValue: 'Target time' })}:
          </Text>{' '}
          {targetDateTimeText}
        </Text>
      )}
      {!!actualDateTimeText && (
        <Text
          style={[
            styles.messageText,
            isCurrentUser ? styles.textRight : styles.textLeft,
            isCurrentUser ? globalStyle.flexRowReverse : globalStyle.flexRow,
          ]}
          selectable
        >
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.actualTime', { defaultValue: 'Actual time' })}:
          </Text>{' '}
          {actualDateTimeText}
        </Text>
      )}
      {!!locationText && (
        <Text
          style={[
            styles.messageText,
            isCurrentUser ? styles.textRight : styles.textLeft,
            isCurrentUser ? globalStyle.flexRowReverse : globalStyle.flexRow,
          ]}
          selectable
        >
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.location', { defaultValue: 'Location' })}:
          </Text>{' '}
          {locationText}
        </Text>
      )}
      {!!note && (
        <Text
          style={[
            styles.messageText,
            isCurrentUser ? styles.textRight : styles.textLeft,
            isCurrentUser ? globalStyle.flexRowReverse : globalStyle.flexRow,
          ]}
          selectable
        >
          <Text style={[globalStyle.fontLatoBold, styles.messageText]}>
            {t('jobs.note', { defaultValue: 'Note' })}:
          </Text>{' '}
          {note}
        </Text>
      )}
      {renderFiles()}
    </View>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps?.currentMessage?.messageType !== nextProps?.currentMessage?.messageType) {
    return false;
  }
  return true;
};

export const ChatMessageTrackingPointItem = memo(
  ChatMessageTrackingPointItemComponent,
  arePropsEqual,
);
