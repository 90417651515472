import { Currency } from '../../../generated-graphql-types';

export const COURIER_EDIT_PROFILE_INITIAL_VALUES = {
  firstNames: '',
  lastName: '',
  email: '',
  phone: '',
  email2: '',
  phone2: '',
  sex: '',
  dateOfBirth: '',
  placeOfBirth: '',
  countryOfBirth: '',
  language: '',

  onboardExperiences: '',
  emergencyContactAddress: '',
  emergencyContactEmail: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  emergencyContactRelationship: '',

  hasObcExperience: false,
  firstObcJobDateAt: null,
  numberOfPastObcJobs: '',
  hasMedicalObcExperience: false,

  linkedin: '',
  twitter: '',
  xing: '',
  facebook: '',
  instagram: '',
  wechat: '',
  telegram: '',
  tiktok: '',
  youtube: '',

  hasAmexCreditCard: false,
  hasAmexDebitCard: false,
  hasDinerCreditCard: false,
  hasDinerDebitCard: false,
  hasMasterCreditCard: false,
  hasMasterDebitCard: false,
  hasVisaCreditCard: false,
  hasVisaDebitCard: false,
  amexCreditCardLimit: '',
  dinerCreditCardLimit: '',
  masterCreditCardLimit: '',
  visaCreditCardLimit: '',
  amexCreditCardCurrency: Currency.EUR,
  dinerCreditCardCurrency: Currency.EUR,
  masterCreditCardCurrency: Currency.EUR,
  visaCreditCardCurrency: Currency.EUR,

  languagesKnown: [],

  baseAirport1: '',
  baseAirport2: '',
  baseAirport3: '',

  legalName: '',
  taxCountry: '',
  headquartersAddressSame: false,

  headquartersAddressLocation: {},
  userRegisteredAddressLocation: {},
};

export const COURIER_EDIT_PROFILE_USER_FIELDS: string[] = [
  'firstNames',
  'lastName',
  'email',
  'sex',
  'email2',
  'phone',
  'phone2',
  'dateOfBirth',
  'placeOfBirth',
  'countryOfBirth',
  'language',
];

export const COURIER_EDIT_PROFILE_LOCATION_FIELDS = [
  'addressName',
  'formattedAddress',
  'streetName',
  'houseNumber',
  'apartmentOrSuite',
  'city',
  'postalCode',
  'state',
  'country',
  'formattedAddress',
  'id',
  'placeId',
];

export const COURIER_EDIT_PROFILE_NUMBER_KEYS = [
  'masterCreditCardLimit',
  'visaCreditCardLimit',
  'dinerCreditCardLimit',
  'amexCreditCardLimit',
  'numberOfPastObcJobs',
];

export const COURIER_EDIT_PROFILE_NOT_UPLOADABLE_FIELDS = [
  '__typename',
  'userRegisteredAddressLocation',
  'headquartersAddressLocation',
  'profilePicture',
  'headquartersAddressSame',
  'user',
  'id',
  'nationalities',
];
