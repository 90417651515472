import { StyleService } from '@ui-kitten/components';

export const themedStyle = StyleService.create({
  paddingTop40: {
    paddingTop: 40,
  },
  itemsContainer: {
    flexDirection: 'row',
    height: 50,
  },
  cardsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 20,
  },
  card: {
    alignItems: 'center',
    justifyCenter: 'center',
    padding: 20,
    paddingBottom: 40,
    borderRadius: 16,
    backgroundColor: 'background-basic-color-2',
  },
  cardCarousel: {
    alignItems: 'center',
    justifyCenter: 'center',
    padding: 20,
    paddingBottom: 40,
    borderRadius: 16,
    backgroundColor: 'background-basic-color-2',
  },
  cardSelected: {
    backgroundColor: 'background-basic-color-3',
    transform: [{ scale: 1.05 }],
    zIndex: 5,
  },
  cardCarouselSelected: {
    backgroundColor: 'background-basic-color-3',
    zIndex: 5,
  },

  cardBtn: {
    alignSelf: 'center',
    minWidth: 200,
    marginTop: 10,
  },
  cardBtnText: {
    fontFamily: 'Lato_700Bold',
  },
  cardTitle: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Lato_900Black',
  },
  cardPrice: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
  },
  cardPeriod: {
    fontSize: 14,
    textAlign: 'center',
    marginTop: -2,
    marginBottom: 20,
    fontFamily: 'Lato_300Light',
  },
  cardDescriptionText: {
    marginTop: 8,
    fontSize: 15,
    textAlign: 'center',
    maxWidth: '92%',
  },
  taxText: {
    fontSize: 11,
    lineHeight: 12,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
  },
  cardDescriptionListItem: {
    flexDirection: 'row',
    paddingLeft: 20,
  },
  cardDescriptionListItemText: {
    fontSize: 14,
    marginTop: 4,
    marginLeft: 8,
    fontFamily: 'Lato_700Bold',
  },
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: 'text-basic-color',
    opacity: 0.1,
    marginTop: 20,
    marginBottom: 10,
  },

  group: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: 'border-basic-color-3',
    borderRadius: 10,
    paddingLeft: 2,
    paddingRight: 2,
    backgroundColor: 'background-basic-color-2',
  },
  groupItemActive: {
    position: 'absolute',
    top: 2,
    bottom: 2,
    borderRadius: 8,
    backgroundColor: 'color-primary-500',
  },
  groupItem: {
    flex: 1,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  groupItemText: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Lato_700Bold',
    color: 'text-basic-color',
  },
  groupItemTextSelected: {
    color: 'white',
  },
  icon: {
    color: 'text-basic-color',
  },
});
