import React, { SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { View, ViewStyle } from 'react-native';

import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { Button, Card, Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as ExpoLocalization from 'expo-localization';
import * as ExpoLocation from 'expo-location';
import { DateTime, Duration, UnitLength } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { DELETE_AVAILABILITY_MUTATION } from '../../../apollo/graphql-mutations';
import { globalStyle } from '../../../common/style';
import { Availability, Location } from '../../../generated-graphql-types';
import {
  DeleteAvailability,
  DeleteAvailabilityVariables,
  OrganizationType,
} from '../../../graphql-types';
import useDimensions from '../../../hooks/useDimensions';
import { LocalUserContext } from '../../../types';
import { MapViewCoordinate } from '../map-view-with-marker.component';
import { ConfirmModalContext } from '../modal/ConfirmModal';
import { TimeAgoWithTimeZonesPopover } from '../time-ago-with-time-zones-popover.component';

export const CourierAvailabilitiesCardItem = ({
  availability,
  setMapViewRegion,
  currentUserContext,
}: {
  availability: Partial<Availability>;
  setMapViewRegion?: React.Dispatch<SetStateAction<MapViewCoordinate | undefined>>;
  currentUserContext: LocalUserContext;
}) => {
  const { t, i18n } = useTranslation();
  const navigation: any = useNavigation();

  const { isVerySmallDevice } = useDimensions();
  const styles = useStyleSheet(themedStyles);
  const stylesCourierAvailability = useStyleSheet(courierAvailabilityCardItemStyles);
  const stylesAvailabilityTime = useStyleSheet(availabilityTimeFrameDatesStyles);
  const { showConfirmModal } = useContext(ConfirmModalContext);

  const [isLocationActive, setIsLocationActive] = useState<boolean>(
    availability.isAdHocAvailability || false,
  );
  const [location, setLocation] = useState<Partial<Location> | undefined>();

  const durationInMillis = useMemo(() => {
    if (availability.endTime instanceof DateTime && availability.startTime instanceof DateTime) {
      return availability.endTime.toMillis() - availability.startTime.toMillis();
    }
    if (availability.startTime instanceof DateTime) {
      return Date.now() - availability.startTime.toMillis();
    }
    return 0;
  }, [availability.endTime, availability.startTime]);

  const durationFormatted = useMemo(() => {
    let format = `m '${t('common.minutes', { defaultValue: 'minutes' })}'`;
    if (durationInMillis > 24 * 60 * 60 * 1000) {
      format = `d '${t('common.days', { defaultValue: 'days' })}', h:m '${t('common.hours', {
        defaultValue: 'hours',
      })}'`;
    } else if (durationInMillis > 60 * 60 * 1000) {
      `h '${t('common.hours', { defaultValue: 'hours' })}' m '${t('common.minutes', {
        defaultValue: 'minutes',
      })}'`;
    }
    return Duration.fromObject({ milliseconds: durationInMillis }).toFormat(format);
  }, [durationInMillis, t]);

  const [deleteAvailability] = useMutation<DeleteAvailability, DeleteAvailabilityVariables>(
    DELETE_AVAILABILITY_MUTATION,
    {
      onCompleted(_) {
        showMessage({
          message: t('common.availabilityDeleted', { defaultValue: 'Availability deleted' }),
          description: t('common.availabilityDeletedSuccess', {
            defaultValue: 'Availability successfully deleted',
          }) as string,
          type: 'success',
          autoHide: true,
          hideOnPress: true,
          duration: 5000,
        });
      },
      // Delete the deleted availability from the cache.
      update(cache, { data }) {
        if (data && data.deleteAvailability) {
          const normalizedId = cache.identify({
            id: data.deleteAvailability.id,
            __typename: 'Availability',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        } else {
          showMessage({
            message: t('common.error', { defaultValue: 'Error' }),
            description: t('common.availabilityDeletionError', {
              defaultValue:
                'Could not delete availability. Please try again or contact support@priojet.com',
            }) as string,
            type: 'warning',
            autoHide: true,
            hideOnPress: true,
            duration: 5000,
          });
        }
      },
      onError(e) {
        console.warn(e);
      },
    },
  );

  const handleDeletePress = async () => {
    if (!availability?.startTime || !location?.timeZoneIdentifier) {
      return null;
    }
    const localeStringObject: { [key: string]: UnitLength } = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    };
    const formattedStartTimeAtDeviceTimeZone = availability.startTime
      .setLocale(ExpoLocalization.locale)
      .toLocaleString(localeStringObject, { locale: i18n.language });
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.remove', { defaultValue: 'Remove' }),
      cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
      title: t('common.removeAvailability', { defaultValue: 'Remove availability' }),
      text: t('common.removeAvailabilityText', {
        defaultValue:
          'Are you sure you want to remove your availability in {{location}} on {{availabilityStartDate}}?',
        location: availability.lastLocation?.formattedAddress,
        availabilityStartDate: formattedStartTimeAtDeviceTimeZone,
      }),
      onConfirmPress: async () => {
        await deleteAvailability({
          variables: {
            availabilityId: availability.id as string,
          },
        });
        return true;
      },
      visible: true,
    });
  };

  const LocationIcon = ({ active, style }: { active: boolean; style: ViewStyle }) => (
    <Icon
      name={active ? 'navigation-2' : 'navigation-2-outline'}
      fill={active ? '#0022FF' : '#bbb'}
      style={[globalStyle.size20, style]}
    />
  );

  const viewCourierUserProfile = (userId: string) => {
    navigation.navigate('AgencyCourierUserProfileScreen', {
      userId,
      previousScreenName: 'AgencyCourierAvailabilitiesScreen',
      previousNavigator: 'AgencyCourierAvailabilitiesNavigator',
    });
  };

  useEffect(() => {
    if (availability.isAdHocAvailability) {
      ExpoLocation.hasServicesEnabledAsync().then((status) => setIsLocationActive(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Making sure, that the Courier always see his last location (by DeviceLocation)
    // Hence, the first if condition is not applicable for Courier Users.
    if (
      availability.isAdHocAvailability === true &&
      availability.endTime === null &&
      'lastDeviceLocationWithLocationForUser' in availability &&
      currentUserContext.organizationType === OrganizationType.COURIER
    ) {
      if (availability.lastLocation) {
        setLocation(availability.lastLocation);
      } else if (
        availability.lastDeviceLocationWithLocationForUser &&
        availability.lastDeviceLocationWithLocationForUser.location
      ) {
        setLocation(availability.lastDeviceLocationWithLocationForUser.location);
      }
    } else if ('lastLocation' in availability && availability.lastLocation !== null) {
      setLocation(availability.lastLocation);
    }
  }, [availability]);

  const locationName = useMemo(() => {
    if (
      location &&
      location.formattedMinimalAddress &&
      location.formattedMinimalAddress !== 'undefined'
    ) {
      return location.formattedMinimalAddress;
    }
    if (location && location.formattedShortAddress) {
      return location.formattedShortAddress;
    }
    if (location && location.formattedAddress) {
      return location.formattedAddress;
    }
    if (location && location.timeZoneIdentifier) {
      return location.timeZoneIdentifier;
    }
    return t('Determining location...') as string;
  }, [location]);

  const renderAvailabilityTimeFrameDates = () => {
    if (!availability?.startTime || !location?.timeZoneIdentifier) {
      return null;
    }

    const localeStringObject: { [key: string]: UnitLength } = {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    };
    let formattedStartTimeAtDeviceTimeZone = '';
    let formattedStartTimeAtLocationTimeZone = '';
    if (availability?.startTime?.setLocale) {
      formattedStartTimeAtDeviceTimeZone = availability.startTime
        .setLocale(ExpoLocalization.locale)
        .toLocaleString(localeStringObject, { locale: i18n.language });
      formattedStartTimeAtLocationTimeZone = availability.startTime
        .setZone(location.timeZoneIdentifier)
        .setLocale(ExpoLocalization.locale)
        .toLocaleString(localeStringObject, { locale: i18n.language });
    }
    let formattedEndTimeAtDeviceTimeZone: string | undefined;
    let formattedEndTimeAtLocationTimeZone: string | undefined;

    if (availability.endTime) {
      formattedEndTimeAtDeviceTimeZone = availability.endTime
        .setLocale(ExpoLocalization.locale)
        .toLocaleString(localeStringObject, { locale: i18n.language });
    }
    if (availability.endTime && location.timeZoneIdentifier) {
      formattedEndTimeAtLocationTimeZone = availability.endTime
        .setLocale(ExpoLocalization.locale)
        .setZone(location.timeZoneIdentifier)
        .toLocaleString(localeStringObject, { locale: i18n.language });
    }

    if (!formattedStartTimeAtDeviceTimeZone) {
      return null;
    }

    const inString = t('common.in', { defaultValue: 'in' }) as string;
    const currentZoneText = DateTime.now().get('zoneName');

    return (
      <View style={stylesAvailabilityTime.containerMargin}>
        <View
          style={
            isVerySmallDevice
              ? stylesAvailabilityTime.timezoneColumn
              : stylesAvailabilityTime.timezoneRow
          }
        >
          <Text
            style={[
              stylesAvailabilityTime.label,
              !isVerySmallDevice && stylesAvailabilityTime.labelMaxWidth,
            ]}
          >
            {t('common.availabilityTZ', { defaultValue: 'Availability TZ' }) as string}
          </Text>
          {!!formattedStartTimeAtLocationTimeZone && (
            <Text style={stylesAvailabilityTime.text}>
              {formattedStartTimeAtLocationTimeZone}

              {formattedEndTimeAtLocationTimeZone ? ` - ${formattedEndTimeAtLocationTimeZone}` : ''}
              {` ${inString} ${location?.timeZoneIdentifier}`}
            </Text>
          )}
        </View>

        <View
          style={
            isVerySmallDevice
              ? stylesAvailabilityTime.timezoneColumn
              : stylesAvailabilityTime.timezoneRow
          }
        >
          <Text
            style={[
              stylesAvailabilityTime.label,
              !isVerySmallDevice && stylesAvailabilityTime.labelMaxWidth,
            ]}
          >
            {t('common.yourTZ', { defaultValue: 'Your TZ' }) as string}
          </Text>

          <Text style={stylesAvailabilityTime.text}>
            {formattedStartTimeAtDeviceTimeZone}
            {formattedEndTimeAtDeviceTimeZone ? ` - ${formattedEndTimeAtDeviceTimeZone}` : ''}
            {` ${inString} ${currentZoneText}`}
          </Text>
        </View>
      </View>
    );
  };

  const renderCourierAvailabilityCartItemContentComponent = () => {
    const isPriojetOrAgency =
      currentUserContext.organizationType === OrganizationType.PRIOJET ||
      currentUserContext.organizationType === OrganizationType.AGENCY;

    const isActiveAvailability = availability.endTime && availability.endTime >= DateTime.now();

    return (
      <View style={stylesCourierAvailability.flex1}>
        <View style={stylesCourierAvailability.paddingBottom10}>
          <View style={[stylesCourierAvailability.flex1, stylesCourierAvailability.row]}>
            <Text selectable={true} category="h6">
              {locationName}
            </Text>
            {availability.isAdHocAvailability ? (
              <>
                <LocationIcon
                  active={
                    isLocationActive &&
                    (!availability.endTime ||
                      (availability.endTime &&
                        availability.endTime instanceof DateTime &&
                        availability.endTime.diffNow().toMillis() > 60000))
                  }
                  style={stylesCourierAvailability.marginLeft5}
                />
                {!availability.endTime && <View style={stylesCourierAvailability.emptyEndTime} />}
              </>
            ) : (
              <Icon
                name="calendar-outline"
                fill="#ccc"
                style={stylesCourierAvailability.calendarOutline}
              />
            )}
          </View>

          {renderAvailabilityTimeFrameDates()}
        </View>

        <View>
          {isPriojetOrAgency && availability.user?.id && (
            <View
              style={[
                stylesCourierAvailability.flex1,
                stylesCourierAvailability.row,
                stylesCourierAvailability.spaceBetween,
                stylesCourierAvailability.paddingBottom10,
              ]}
            >
              <View>
                <Text selectable={true} style={stylesCourierAvailability.textBold}>
                  {availability.user.firstNames} {availability?.user?.lastName}
                </Text>
                {!!availability.user?.courierUserProfile?.baseAirports?.length && (
                  <View style={stylesCourierAvailability.row}>
                    <Text style={stylesCourierAvailability.textBold}>Base airports: </Text>
                    <Text selectable={true}>
                      {availability.user?.courierUserProfile?.baseAirports.join(', ')}
                    </Text>
                  </View>
                )}
              </View>

              <Button
                style={stylesCourierAvailability.actionButton}
                size="tiny"
                appearance="outline"
                onPress={() => viewCourierUserProfile(availability?.user?.id as string)}
              >
                <Text selectable={true} numberOfLines={1}>
                  {t('common.Profile', { defaultValue: 'Profile' }) as string}
                </Text>
              </Button>
            </View>
          )}
        </View>

        <Text>
          {
            availability.user?.organizationUsers
              ?.map((ou) => ou.organization.id)
              .toString() as string
          }
        </Text>
        <Text selectable={true}>
          <Trans
            i18nKey={
              availability.endTime
                ? t('common.formattedDuration', {
                    defaultValue: '<0>Duration</0>: {{duration}}.',
                  })
                : t('common.formattedSince', {
                    defaultValue: '<0>Since</0> {{duration}}.',
                  })
            }
            values={{ duration: durationFormatted }}
            components={[<Text style={stylesCourierAvailability.textBold} />]}
          />
        </Text>
        <View>
          {!!availability.startTime && (
            <TimeAgoWithTimeZonesPopover
              popoverContentTitle={t('common.startTime', { defaultValue: 'Start Time' })}
              i18nKey="common.timeAgoPopOverTextStart"
              date={availability.startTime}
              targetLocationName={
                locationName ||
                t('common.destinationLocation', { defaultValue: 'destination location' })
              }
              targetTimeZoneIdentifier={availability.timeZoneIdentifier || undefined}
              hitSlop={{ top: 10, bottom: 3, left: 0, right: 0 }}
            />
          )}
          {!!availability.endTime && (
            <TimeAgoWithTimeZonesPopover
              popoverContentTitle={t('common.endTime', { defaultValue: 'End Time' })}
              i18nKey="common.timeAgoPopOverTextEnd"
              date={availability.endTime}
              targetLocationName={locationName}
              targetTimeZoneIdentifier={availability.timeZoneIdentifier || undefined}
              hitSlop={{ top: 3, bottom: 10, left: 0, right: 0 }}
            />
          )}
          {!isPriojetOrAgency && !!availability.createdAt && (
            <TimeAgoWithTimeZonesPopover
              popoverContentTitle={t('common.createdAt', { defaultValue: 'Created At' })}
              i18nKey="common.timeAgoPopOverTextCreated"
              date={availability.createdAt}
              targetLocationName={
                locationName ||
                t('common.destinationLocation', { defaultValue: 'destination location' })
              }
              targetTimeZoneIdentifier={availability.timeZoneIdentifier || undefined}
              hitSlop={{ top: 10, bottom: 3, left: 0, right: 0 }}
            />
          )}
        </View>
        {!isPriojetOrAgency &&
          !availability.isAdHocAvailability &&
          isActiveAvailability &&
          !!availability.endTime && (
            <Button
              style={stylesCourierAvailability.actionButtonsContainer}
              appearance="ghost"
              size="small"
              status="danger"
              onPress={handleDeletePress}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              {t('common.delete') as string}
            </Button>
          )}
      </View>
    );
  };

  return (
    <Card
      key={availability.id}
      style={[
        styles.card,
        availability?.startTime !== undefined &&
        availability?.startTime <= DateTime.now() &&
        (!availability.endTime || (availability.endTime && availability?.endTime >= DateTime.now()))
          ? styles.activeAvailabilitiesBackgroundColor
          : undefined,
      ]}
      status={undefined}
      onPress={() => {
        if (setMapViewRegion && location && location.locationGeometry) {
          const coordinates = {
            latitude: location.locationGeometry.location.lat,
            longitude: location.locationGeometry.location.lng,
          };
          setMapViewRegion(coordinates);
        }
      }}
    >
      {renderCourierAvailabilityCartItemContentComponent()}
    </Card>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  card: {
    marginTop: 10,
    padding: 10,
    backgroundColor: 'background-basic-color-2',
    borderRadius: 10,
  },
  activeAvailabilitiesBackgroundColor: {
    borderColor: 'color-success-600',
  },
});

const availabilityTimeFrameDatesStyles = StyleService.create({
  containerRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  timezoneColumn: {
    flexDirection: 'column',
    marginBottom: 2,
  },
  timezoneRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  containerMargin: {
    marginTop: 10,
  },
  label: {
    color: 'text-basic-color',
    fontSize: 14,
    fontFamily: 'Lato_700Bold',
    width: 140,
  },
  labelMaxWidth: {
    width: 110,
  },
  text: {
    flex: 1,
    fontSize: 14,
  },
});

const courierAvailabilityCardItemStyles = StyleService.create({
  flex1: {
    flex: 1,
  },
  paddingBottom10: {
    paddingBottom: 10,
  },
  row: {
    flexDirection: 'row',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  marginLeft5: {
    marginLeft: 5,
  },
  calendarOutline: {
    height: 20,
    width: 20,
    marginLeft: 5,
  },
  emptyEndTime: {
    height: 16,
    width: 16,
    backgroundColor: 'green',
    borderRadius: 16 / 2,
    marginLeft: 10,
    marginTop: 2,
  },
  textBold: {
    fontFamily: 'Lato_700Bold',
  },
  actionButtonsContainer: {
    position: 'absolute',
    right: -8,
    bottom: -5,
  },
  actionButton: {
    margin: 4,
  },
});

export const MemoizedCourierAvailabilitiesCardItem = React.memo(
  CourierAvailabilitiesCardItem,
  (prev, next) => {
    if (prev.availability.id === next.availability.id) {
      if (
        (!prev.currentUserContext && !next.currentUserContext) ||
        (prev.currentUserContext?.organizationUser?.id !== undefined &&
          prev.currentUserContext?.organizationUser?.id ===
            next.currentUserContext?.organizationUser?.id)
      ) {
        return false;
      }
      if (prev.availability.isAdHocAvailability && prev.availability.id && next.availability.id) {
        if (!prev.availability.lastLocation && next.availability.lastLocation) {
          return false;
        }
        if (
          prev.availability.lastLocation &&
          next.availability.lastLocation &&
          prev.availability.lastLocation.id !== next.availability.lastLocation.id
        ) {
          return false;
        }
      }
    }
    return true;
  },
);
