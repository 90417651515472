import React from 'react';
import { RefreshControl } from 'react-native';

import { ApolloQueryResult } from '@apollo/client';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native-gesture-handler';

import { globalStyle } from '../../../common/style';
import { CourierOrganizationInvitationCard } from '../../../components/common/Invitation/CourierOrganizationInvitationCard';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import {
  CourierOrganizationInvitationsForAgencyOrganizationQuery,
  Exact,
  OrganizationInvitation,
} from '../../../generated-graphql-types';

export const AgencyCourierInvitationOpenInvitationsScreen = ({
  organizationInvitations,
  isLoading,
  refetch,
}: {
  organizationInvitations: Array<Partial<OrganizationInvitation>>;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            agencyOrganizationId: string;
            userId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<CourierOrganizationInvitationsForAgencyOrganizationQuery>>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const renderListHeaderComponent = () => {
    if (!organizationInvitations?.length) {
      return <></>;
    }
    return (
      <Text style={[globalStyle.fontSize12, globalStyle.textAlignCenter]}>
        {
          t('agencyCourierOpenInvitations.contactPriojetToSendCode', {
            defaultValue: '*** contact PRIOjet to send the code again to the Courier.',
          }) as string
        }
      </Text>
    );
  };

  const renderListEmptyComponent = () => (
    <Text selectable={true} style={styles.empty}>
      {
        t('agencyCourierOpenInvitations.orgInvitationDescription_noOrganizationInvitationsFound', {
          defaultValue: 'No organization invitations found.',
        }) as string
      }
    </Text>
  );

  const keyExtractor = (item: Partial<OrganizationInvitation>) => item.id as string;

  const renderItem = ({ item }: { item: Partial<OrganizationInvitation> }) => (
    <CourierOrganizationInvitationCard organizationInvitation={item} />
  );

  if (isLoading || !organizationInvitations) {
    return <LoadingSpinner />;
  }
  return (
    <FlatList
      refreshControl={
        <RefreshControl
          refreshing={isLoading}
          onRefresh={refetch}
          title="Loading Organization Invitations..."
        />
      }
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      data={organizationInvitations}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeaderComponent()}
      ListEmptyComponent={renderListEmptyComponent()}
    />
  );
};

const themedStyles = StyleService.create({
  card: {
    margin: 10,
    padding: 10,
    backgroundColor: 'background-basic-color-2',
  },
  header: {
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'center',
    paddingHorizontal: 20,
  },
  empty: {
    padding: 20,
    paddingTop: 30,
    textAlign: 'center',
  },
  divider: {
    marginVertical: 5,
  },
  organizationName: {
    paddingTop: 5,
    fontWeight: 'bold',
  },
});
