import React from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/native';
import {
  Button,
  Card,
  Icon,
  IconProps,
  List,
  ListItem,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../../../common/style';
import { EmailTextWithContextMenu } from '../../../../components/common/common.email-text-with-context-menu';
import { PhoneTextWithContextMenu } from '../../../../components/common/common.phone-text-with-context-menu';
import { ConnectedAtByText } from '../../../../components/common/connected-at-from-text.component';
import { ConnectedOrganization, OrganizationUser } from '../../../../generated-graphql-types';

export const AgencyConnectedCourierOrganizationsCardItem = ({
  connectedOrganization,
}: {
  connectedOrganization: ConnectedOrganization;
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const handleButtonPress = (userId: string) => () => {
    navigation.navigate('AgencyCourierUserProfileScreen', {
      userId,
      organizationId: connectedOrganization.organization.id,
      previousScreenName: 'AgencyConnectedCourierOrganizationsScreen',
      previousNavigator: 'AgencyProfileNavigator',
    });
  };

  const renderListItemAccessory =
    ({ userId }: { userId: string }) =>
    (): JSX.Element => {
      return (
        <Button size="tiny" onPress={handleButtonPress(userId)}>
          Courier Profile
        </Button>
      );
    };

  const renderListItemIcon = (props: IconProps) => <Icon {...props} name="person" />;

  const renderListItemTitle = (item: Partial<OrganizationUser>) => () =>
    (
      <Text
        style={[globalStyle.fontSize16, globalStyle.fontLatoBold]}
      >{`${item.user?.firstNames} ${item.user?.lastName}`}</Text>
    );

  const renderListItem = ({ item, index }: { item: Partial<OrganizationUser>; index: number }) => (
    <ListItem
      key={index}
      title={renderListItemTitle(item)}
      description={
        <>
          <EmailTextWithContextMenu email={item.user?.email as string | undefined} />
          <PhoneTextWithContextMenu phone={item.user?.phone as string | undefined} />
        </>
      }
      accessoryLeft={renderListItemIcon}
      accessoryRight={renderListItemAccessory({ userId: item.user?.id as string })}
      style={styles.listItemContent}
    />
  );

  const renderFooter = (footerProps: any) => {
    if (!connectedOrganization.usedOrganizationInvitation) {
      return (
        <View {...footerProps} style={styles.footerContainer}>
          <Text selectable={true}>
            {t('common.noInvitationWasUsed', { defaultValue: 'No invitation was used' }) as string}
          </Text>
        </View>
      );
    }
    return (
      <View {...footerProps} style={styles.footerContainer}>
        <ConnectedAtByText
          firstNames={
            connectedOrganization.usedOrganizationInvitation.usedByUser?.firstNames || 'n/a'
          }
          lastName={connectedOrganization.usedOrganizationInvitation.usedByUser?.lastName || 'n/a'}
          email={connectedOrganization.usedOrganizationInvitation.usedByUser?.email || 'n/a'}
          createdAt={connectedOrganization.createdAt}
          textStyle={globalStyle.fontSize13}
        />
      </View>
    );
  };

  return (
    <Card key={connectedOrganization.organization.id} style={styles.card} footer={renderFooter}>
      <View style={styles.contentWrapper}>
        <List
          style={styles.organizationUsersList}
          data={connectedOrganization.organization.organizationUserOwners || []}
          renderItem={renderListItem}
          ListEmptyComponent={
            <View>
              <Text>This Organization does not have any Users.</Text>
            </View>
          }
        />

        <Text selectable={true} style={styles.companyNameText}>
          {t('courierUserProfile.companyName', { defaultValue: 'Company Name' }) as string}:{' '}
          {connectedOrganization.organization.name}
        </Text>
        <View style={styles.actionButtonsContainer}>
          <View style={styles.actionButtonsWrapperRight} />
        </View>
      </View>
    </Card>
  );
};

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  organizationUsersList: {
    marginBottom: 5,
  },
  card: {
    margin: 5,
    paddingBottom: 5,
    backgroundColor: 'background-basic-color-2',
  },
  contentWrapper: {
    paddingBottom: 10,
  },
  listItemContent: {
    backgroundColor: 'background-basic-color-2',
  },
  actionButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'space-between',
  },
  actionButtonsWrapperRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: 10,
  },
  companyNameText: {
    fontStyle: 'italic',
    fontSize: 14,
    marginLeft: 48,
  },
  footerContainer: {
    paddingHorizontal: 20,
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'flex-end',
    alignContent: 'space-between',
  },
});
