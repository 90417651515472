import { ReloadMessageTypes, showReloadMessage } from './app-reload-message.helper';

export const reloadApp = ({
  reloadMessageType = 'MISCONFIGURATION_DETECTED',
  timeout = 5000,
}: {
  reloadMessageType?: ReloadMessageTypes;
  timeout?: number;
} = {}) => {
  showReloadMessage({ reloadMessageType, timeout });
  if (typeof location !== 'undefined') {
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      location.reload();
    }, 5000);
  }
};
