import React from 'react';
import { View } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';

export const CommonJobRelationshipTypeActionButtonLayer = ({
  description,
  Buttons,
}: {
  description: string;
  Buttons: JSX.Element;
}) => {
  const themedStyles = useStyleSheet(styles);

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        flex: 2,
      }}
    >
      <View
        style={{
          flex: 1,
          flexGrow: 1,
          alignItems: 'center',
        }}
      >
        <Text style={[themedStyles.relationshipTypeActionButtonDescription]}>
          {description}
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'baseline',
          alignContent: 'center',
        }}
      >
        {Buttons}
      </View>
    </View>
  );
};

const styles = StyleService.create({
  relationshipTypeActionButtonDescription: {
    paddingHorizontal: 10,
    paddingTop: 6,
    fontSize: 12,
    color: 'color-basic-500',
    textAlign: 'right',
  },
  actionButtonsContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    marginBottom: 10,
    marginTop: 10,
  },
  actionButtonsWrapperRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: 10,
  },
});
