import { Dimensions } from 'react-native';

import { StyleService } from '@ui-kitten/components';

const { width } = Dimensions.get('screen');

export const themedStyles = StyleService.create({
  container: {
    alignItems: 'flex-start',
    position: 'absolute',
    left: 15,
    top: 80,
    borderRadius: 4,
  },
  textInput: {
    flex: 1,
    width: width - 30,
    maxWidth: 600,
    borderRadius: 4,
    backgroundColor: 'white',
  },
  filterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    borderRadius: 4,
    padding: 10,
    paddingHorizontal: 15,
    backgroundColor: 'background-basic-color-2',
    borderWidth: 1,
    borderColor: 'background-basic-color-2',
  },
  filterContainerSelected: {
    borderColor: 'red',
  },
  filterText: {
    marginLeft: 10,
    color: 'text-basic-color',
  },
  filterIcon: {
    color: 'text-basic-color',
  },
  filterCount: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: -10,
    top: -10,
    backgroundColor: 'red',
    borderRadius: 15,
    padding: 5,
    paddingBottom: 6,
    width: 24,
    height: 24,
    borderWidth: 1,
    borderColor: 'white',
  },
  filterCountText: {
    textAlign: 'center',
    fontFamily: 'Lato_900Black',
    fontSize: 13,
    lineHeight: 13,
    color: 'text-basic-color',
  },
});
