import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  row: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
  },
});
