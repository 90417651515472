import React, { useMemo, useRef, useState } from 'react';
import { Animated, Image, ImageStyle, Platform, View } from 'react-native';

import { Icon, Input, List, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Flag from 'react-native-flags';

import { Props } from './types';
import useDimensions from '../../../../hooks/useDimensions';
import { useKeyboard } from '../../../../hooks/useKeyboard';
import { AppUserContact } from '../../../../types';

export const PhoneBookList = ({
  data,
  renderInviteButton,
  refreshControl,
  refetch,
  importedContacts,
}: Props) => {
  const [filterTerm, setFilterTerm] = useState<string>();
  const [lastContentOffset, setLastContentOffset] = useState(0);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const { t } = useTranslation();
  const { isVerySmallDevice } = useDimensions();
  const styles = useStyleSheet(themedStyles);
  const { keyboardHeight, isKeyboardOpen } = useKeyboard();

  const handleFilterChange = (_filterTerm: string) => {
    setFilterTerm(_filterTerm);
  };

  const sortContacts = (a: AppUserContact, b: AppUserContact) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const filteredContacts = useMemo((): AppUserContact[] => {
    let contacts = [];
    if (filterTerm) {
      contacts = Object.values(data)
        .filter((contact: AppUserContact) => {
          try {
            const str = JSON.stringify(Object.values(contact)).toLowerCase();
            return str.includes(filterTerm.toLowerCase());
          } catch (e) {
            return false;
          }
        })
        .map((contact: AppUserContact) => {
          return {
            ...contact,
            state: 'loaded',
            name: `${contact.firstName ? contact.firstName : ''}${
              contact.middleName ? ` ${contact.middleName}` : ''
            }${contact.lastName ? ` ${contact.lastName}` : ''}`,
          } as AppUserContact;
        });
    } else {
      contacts = data.map(
        (contact: AppUserContact) => ({ ...contact, state: 'loaded' }) as AppUserContact,
      );
    }

    return contacts.sort(sortContacts);
  }, [data, filterTerm]);

  const onScrollEndDrag = (event: any) => {
    if (Platform.OS !== 'web') {
      setLastContentOffset(
        event.nativeEvent.contentOffset.y < 300 ? 300 : event.nativeEvent.contentOffset.y,
      );
    }
  };

  const onScroll = (event: any) => {
    if (Platform.OS !== 'web') {
      if (
        (lastContentOffset - 200 > event.nativeEvent.contentOffset.y ||
          event.nativeEvent.contentOffset.y < 300) &&
        isInputVisible
      ) {
        Animated.timing(fadeAnim, {
          toValue: 1,
          useNativeDriver: true,
          duration: 200,
        }).start();
        setIsInputVisible(false);
        setLastContentOffset(
          event.nativeEvent.contentOffset.y < 300 ? 300 : event.nativeEvent.contentOffset.y,
        );
      } else if (
        !isKeyboardOpen &&
        lastContentOffset < event.nativeEvent.contentOffset.y &&
        !isInputVisible &&
        !filterTerm?.length
      ) {
        setLastContentOffset(
          event.nativeEvent.contentOffset.y < 300 ? 300 : event.nativeEvent.contentOffset.y,
        );
        setIsInputVisible(true);
        Animated.timing(fadeAnim, {
          toValue: 0,
          useNativeDriver: true,
          duration: 200,
        }).start();
      }
    }
  };

  const renderItemIcon = ({ item }: { item: AppUserContact }) => {
    if (item.imageAvailable && item.image) {
      return <Image source={{ uri: item.image.uri }} style={styles.iconImage as ImageStyle} />;
    }
    return <Icon name="person-outline" style={styles.iconPerson} />;
  };

  const renderDescription = (item: AppUserContact) => {
    let companyAndDepartment = item.company || '';
    if (item.department) {
      if (companyAndDepartment) {
        companyAndDepartment += ` | ${item.department}`;
      } else {
        companyAndDepartment = item.department;
      }
    }
    return (
      <View style={styles.column} key={item.id + 'description'}>
        {item.phoneNumbers?.map((phoneNumber) => (
          <View style={styles.row} key={item.id + 'desription-phone' + phoneNumber.id}>
            <Flag
              code={phoneNumber.countryCode?.toUpperCase()}
              size={16}
              type="flat"
              style={styles.flag}
            />
            <Text selectable={true}>
              {phoneNumber.number as string}
              {phoneNumber.isPrimary ? '*' : ''}
            </Text>
          </View>
        ))}

        {item.emails?.map((emails) => (
          <View style={styles.row} key={item.id + 'desription-email' + emails.email}>
            <Text selectable={true} style={styles.emailText}>
              {emails.email as string}
              {emails.isPrimary ? ' (default)' : ''}
            </Text>
          </View>
        ))}

        {(!!item.company || !!item.department) && (
          <Text selectable={true}>{companyAndDepartment}</Text>
        )}

        {!!item.note && (
          <View>
            <Text selectable={true}>Notes: {item.note}</Text>
          </View>
        )}
      </View>
    );
  };

  const renderAccessoryRight = (item: AppUserContact) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /[^\+^\d]+/gi; // Replacing all non numeric characters except for +
    let phoneNumber = item.phoneNumbers?.find((phone) => {
      if (!phone.number || !importedContacts) {
        return false;
      }
      const phoneFormatted = phone.number.replaceAll(regex, '');
      let check = false;
      item.emails?.forEach(({ email }) => {
        const key = `${phoneFormatted}_${email}`;
        if (importedContacts[key]) {
          check = true;
        }
      });

      return check;
    });
    let phone: string | undefined;
    if (phoneNumber && phoneNumber.number) {
      phone = phoneNumber.number.replaceAll(regex, '');
    }
    return (
      <View style={isVerySmallDevice && styles.itemContentMarginLeft}>
        {renderInviteButton({
          item: { ...item, state: phone !== undefined ? 'invited' : '' },
          refetch,
        })}
      </View>
    );
  };

  const renderItem = ({ item }: { item: AppUserContact; index: number }) => (
    <>
      <View
        key={item.id}
        style={[
          styles.item,
          isVerySmallDevice ? styles.column : styles.row,
          isVerySmallDevice ? styles.alignStart : styles.alignCenter,
        ]}
      >
        <View style={styles.itemContent}>
          {renderItemIcon({ item })}
          <View style={styles.itemContentTextContainer}>
            <Text selectable={true} key={item.id + 'title'} style={styles.titleText}>
              {item.name}
            </Text>
            {renderDescription(item)}
          </View>
        </View>
        {renderAccessoryRight(item)}
      </View>
    </>
  );

  const renderHeader = () => (
    <Animated.View style={[styles.inputTextContainer, { opacity: fadeAnim }]}>
      <Input
        placeholder={
          t('common:filterBoxPlaceholder', {
            defaultValue: 'Filter by name, email, number, address, company or job',
          }) as string
        }
        style={styles.input}
        textStyle={styles.inputText}
        onChangeText={handleFilterChange}
      />
    </Animated.View>
  );

  const renderEmpty = () => (
    <Text style={styles.emptyText}>
      {
        t('common:noContactsWith', {
          defaultValue: 'No contacts with',
        }) as string
      }
      {filterTerm ? (
        <Text selectable={true} style={styles.bold}>
          {` ${filterTerm}`}
        </Text>
      ) : (
        <></>
      )}{' '}
      {
        t('common:foundOnThisDevice', {
          defaultValue: 'found on this device',
        }) as string
      }
    </Text>
  );

  const renderFooter = () => (isKeyboardOpen ? <View style={{ height: keyboardHeight }} /> : null);

  return (
    <>
      <List
        refreshControl={refreshControl}
        contentContainerStyle={styles.container}
        data={filteredContacts}
        renderItem={renderItem}
        onScroll={onScroll}
        onScrollEndDrag={onScrollEndDrag}
        ListEmptyComponent={renderEmpty()}
        ListFooterComponent={renderFooter()}
        ItemSeparatorComponent={() => <View style={styles.divider} />}
      />
      {renderHeader()}
    </>
  );
};

const themedStyles = StyleService.create({
  container: {
    paddingTop: 70,
    paddingBottom: 20,
  },
  item: {
    flex: 1,
    padding: 10,
  },
  flex1: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  alignCenter: {
    alignItems: 'center',
  },
  itemContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemContentTextContainer: {
    flex: 1,
    paddingLeft: 5,
  },
  itemContentMarginLeft: {
    marginTop: 10,
    marginLeft: 44,
  },
  iconImage: {
    width: 36,
    height: 36,
    borderRadius: 8,
    alignSelf: 'flex-start',
  },
  iconPerson: {
    height: 24,
    marginRight: 6,
    tintColor: '#8F9BB3',
    width: 24,
  },
  alreadyInvitedText: {
    fontSize: 14,
  },
  alreadyInvitedMaxWidth: {
    maxWidth: '30%',
    textAlign: 'right',
  },
  titleText: {
    fontSize: 15,
    marginBottom: 5,
    marginRight: 5,
    fontFamily: 'Lato_700Bold',
  },
  emailText: {
    paddingRight: 10,
  },
  flag: {
    marginTop: 3,
    marginRight: 10,
    opacity: 0.8,
  },
  divider: {
    height: 1,
    backgroundColor: 'color-primary-400',
    marginTop: 4,
    marginHorizontal: 10,
    opacity: 0.2,
  },
  input: {
    margin: 15,
    marginVertical: 5,
    marginBottom: 10,
    backgroundColor: 'background-basic-color-1',
  },
  inputTextContainer: {
    position: 'absolute',
    top: 10,
    left: 0,
    right: 0,
    height: 50,
  },
  inputText: {
    padding: 0,
    height: 40,
  },
  emptyText: {
    padding: 20,
  },
  bold: {
    fontFamily: 'Lato_700Bold',
  },
});
