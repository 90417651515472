import React from 'react';

import { useNavigation } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';

import { AgencyOBCAvailabilityMapComponent } from './AgencyOBCAvailabilityMapComponent';
import { useHook } from './hook';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';

export const AgencyOBCAvailabilityMapScreen = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { handleBackPress } = useHook();

  return (
    <>
      <TopNavigationWithBackButton
        title={t('common:obcAvailabilityMap', { defaultValue: 'OBC availability map' }) as string}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />
      <AgencyOBCAvailabilityMapComponent />
    </>
  );
};
