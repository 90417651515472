import React, { useMemo, ChangeEvent } from 'react';
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';

import { globalStyle } from '../../../../common/style';

type MinuteInterval = 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30;

export interface FormTimeSelectProps {
  caption?: TFunction | string;
  label?: TFunction | string;
  labelUpperCase?: boolean;
  labelStyle?: StyleProp<TextStyle>;
  isMandatory?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  inputContainerStyle?: StyleProp<ViewStyle>;
  error?: boolean;
  errorMessage?: string;
  minuteInterval?: MinuteInterval;
  onChange: (time: string) => void;
  value: string;
}

export const FormTimeSelect = ({
  caption,
  containerStyle,
  errorMessage,
  isMandatory,
  inputContainerStyle,
  label,
  labelUpperCase,
  labelStyle,
  minuteInterval = 1,
  onChange,
  value: valueProp,
}: FormTimeSelectProps): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);

  const value = useMemo(() => {
    if (valueProp) {
      const valueHour = valueProp.split(':')[0];
      const valueMinute = valueProp.split(':')[1];
      return DateTime.now()
        .minus({ day: 1 })
        .set({
          hour: parseInt(valueHour, 10),
          minute: parseInt(valueMinute, 10),
        })
        .toFormat('HH:mm');
    }
    return '';
  }, [valueProp]);

  const handleChange = (event: ChangeEvent<{ value: string }>) => {
    if (event?.target) {
      const time = event.target.value;
      onChange(time);
    }
  };

  const renderCaption = () => {
    if (caption) {
      return (
        <View style={styles.captionContainer}>
          <Text style={styles.caption}>{caption as string}</Text>
        </View>
      );
    }
    return <></>;
  };

  const renderLabel = () => {
    if (label) {
      return (
        <View style={styles.labelContainer}>
          <Text
            style={[styles.label, labelUpperCase && globalStyle.textTransformUppercase, labelStyle]}
          >
            <>
              {label}
              {!!isMandatory && <Text style={styles.mandatoryStar}>*</Text>}
            </>
          </Text>
        </View>
      );
    }
    return <></>;
  };

  return (
    <View style={[globalStyle.marginTop20, globalStyle.marginLeft10, containerStyle]}>
      <View style={globalStyle.flex1}>
        {renderLabel()}
        <View
          style={[
            styles.input,
            !!label && globalStyle.marginTop5,
            !!errorMessage && styles.inputError,
            inputContainerStyle,
          ]}
        >
          <input
            style={styles.inputTime as any}
            type="time"
            onChange={handleChange}
            value={value as any}
            step={60 * minuteInterval}
          />
        </View>
        {renderCaption()}
      </View>
      {!!errorMessage && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.errorMessageText}>
            {errorMessage}
          </Text>
        </View>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
    maxWidth: 160,
  },
  labelContainer: {},
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
  },
  mandatoryStar: {
    color: 'red',
  },
  input: {
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'background-basic-color-4',
  },
  inputError: {
    borderColor: 'color-danger-600',
  },
  inputTime: {
    width: 160,
    textAlign: 'center',
    height: 36,
    fontSize: 15,
    borderRadius: 0,
    borderWidth: 0,
    borderColor: 'border-basic-color-2',
    fontFamily: 'Lato_400Regular',
    backgroundColor: 'background-basic-color-3',
    color: 'text-basic-color',
  },
  errorMessageText: {
    color: 'color-danger-500',
    paddingBottom: 5,
    fontSize: 13,
  },
});
