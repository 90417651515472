import { useMemo, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { MUTATION_AGENCY_COURIER_USER_PROFILE_CREATE_OR_UPDATE } from '../../../apollo/graphql-mutations';
import { COURIER_USER_PROFILE_BY_USER_ID_QUERY } from '../../../apollo/graphql-queries';
import { CountryListData } from '../../../assets/constants/country.list.data';
import { LanguageListData } from '../../../assets/constants/language.list.data';
import { CourierUserProfileByUserIdQuery } from '../../../generated-graphql-types';
import { CourierUserProfileByUserId_courierUserProfileByUserId } from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { AppNavigatorProp } from '../../../navigation/app.navigator';

export const useHook = ({
  organizationId,
  userId,
}: {
  organizationId?: string;
  userId: string;
}) => {
  const { t } = useTranslation();
  const route: any = useRoute();
  const navigation = useNavigation<AppNavigatorProp>();
  const isBackendReachable = useIsBackendReachable();

  const [initialValues, setInitialValues] = useState({
    internalComment: '',
    markedForAttention: false,
    selectedOrganizationCourierCategory: '',
  });
  const [internalComment, setInternalComment] = useState('');
  const [profile, setProfile] = useState<
    CourierUserProfileByUserId_courierUserProfileByUserId | undefined
  >();
  const [markedForAttention, setMarkedForAttention] = useState(false);
  const [selectedOrganizationCourierCategory, setSelectedOrganizationCourierCategory] =
    useState('');

  useQuery<CourierUserProfileByUserIdQuery>(COURIER_USER_PROFILE_BY_USER_ID_QUERY, {
    fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
    variables: { userId },
    onCompleted: (res) => {
      if (!profile || res.courierUserProfileByUserId.id !== profile.id) {
        setProfile(
          res?.courierUserProfileByUserId as CourierUserProfileByUserId_courierUserProfileByUserId,
        );
        const agencyCourierUserProfile =
          res.courierUserProfileByUserId.user?.agencyCourierUserProfile;
        if (agencyCourierUserProfile) {
          setInternalComment(agencyCourierUserProfile.internalComment || '');
          setMarkedForAttention(!!agencyCourierUserProfile.markedForAttention);
          if (agencyCourierUserProfile.organizationCourierCategories?.[0]) {
            setSelectedOrganizationCourierCategory(
              agencyCourierUserProfile?.organizationCourierCategories?.[0]?.id || '',
            );
          }
          setInitialValues({
            internalComment: agencyCourierUserProfile.internalComment || '',
            markedForAttention: !!agencyCourierUserProfile.markedForAttention,
            selectedOrganizationCourierCategory:
              agencyCourierUserProfile?.organizationCourierCategories?.[0]?.id || '',
          });
        }
      }
    },
  });

  const [agencyCourierUserProfileCreateOrUpdate, { loading: loadingSave }] = useMutation(
    MUTATION_AGENCY_COURIER_USER_PROFILE_CREATE_OR_UPDATE,
  );

  const profileImage = useMemo(() => {
    return profile?.user?.profilePicture?.sasUrl && profile?.user?.profilePicture.sasUrl;
  }, [profile]);

  const organization = useMemo(() => {
    if (profile?.user?.organizationUsers.length) {
      if (organizationId) {
        const organizationUser = profile?.user.organizationUsers.find(
          (item) => item.organization.id === organizationId,
        );
        if (organizationUser) {
          return organizationUser.organization;
        }
      } else if (profile?.user?.organizationUsers.length === 1) {
        return profile?.user?.organizationUsers[0].organization;
      }
    }
    return null;
  }, [organizationId, profile]);

  const languagesKnown = useMemo(() => {
    return profile?.languagesKnown?.map((item: any) => {
      const found = LanguageListData.find((object) => object.code === item.language);
      return `${found?.name || ''} (${item.level})`;
    });
  }, [profile?.languagesKnown]);

  const nationalities = useMemo(() => {
    return profile?.user?.nationalities?.map((item: string) => {
      return (
        ' ' +
        CountryListData.filter((object) => {
          return object.code === item;
        })[0]?.name
      );
    });
  }, [profile?.user?.nationalities]);

  const emergencyContact = profile?.emergencyContactName
    ? [
        profile?.emergencyContactName,
        profile?.emergencyContactRelationship ? ' (' : '',
        profile?.emergencyContactRelationship,
        profile?.emergencyContactRelationship ? ')\n' : '',
        profile?.emergencyContactAddress,
      ].join('')
    : '';

  const socialProfiles = useMemo(() => {
    const socialData: any = profile?.socialProfiles || [];
    return socialData;
  }, [profile?.socialProfiles]);

  const taxCountry = useMemo(() => {
    if (profile?.taxCountry) {
      const found = CountryListData.find((object) => object.code === profile.taxCountry);
      if (found) {
        return found.name;
      }
      return profile.taxCountry;
    }
    return '';
  }, [profile?.taxCountry]);

  const handleDocumentsPress = () => {
    let parentScreenName;
    let previousScreenName = 'AgencyConnectedCourierOrganizationsScreen';
    if (
      route.params?.parentScreenName === 'AgencyOBCAvailabilityMapScreen' ||
      route.params?.previousScreenName === 'AgencyOBCAvailabilityMapScreen'
    ) {
      parentScreenName = 'AgencyOBCAvailabilityMapScreen';
      previousScreenName = 'AgencyCourierUserProfileScreen';
    } else if (route.params?.previousNavigator === 'AgencyCourierAvailabilitiesNavigator') {
      previousScreenName = 'AgencyCourierUserProfileScreen';
    } else if (route.params?.previousNavigator === 'AgencyHomeNavigator') {
      previousScreenName = 'AgencyCourierUserProfileScreen';
    }
    const params: any = route.params;

    if (profile?.id) {
      navigation.navigate('AgencyCourierDocumentsMenuScreen', {
        agencyJobId: params?.agencyJobId,
        courierUserId: userId,
        userId,
        organizationId,
        parentScreenName,
        previousScreenName,
        previousNavigator: route.params?.previousNavigator,
      } as any);
    }
  };

  const handleInternalCommentChange = (text: string) => {
    setInternalComment(text);
    if (!text.length && markedForAttention) {
      setMarkedForAttention(false);
    }
  };

  const handleSelectCategory = (value: any) => {
    setSelectedOrganizationCourierCategory(value);
  };

  const handleMarkedForAttention = (value: boolean) => {
    setMarkedForAttention(value);
  };

  const handleSaveData = async () => {
    try {
      await agencyCourierUserProfileCreateOrUpdate({
        variables: {
          data: {
            internalComment,
            markedForAttention,
            organizationCourierCategoryId: selectedOrganizationCourierCategory,
            userId,
          },
        },
      });
      setInitialValues({
        internalComment,
        markedForAttention,
        selectedOrganizationCourierCategory,
      });
      showMessage({
        message: t('common.success', { defaultValue: 'Success' }),
        description: t('common.courierProfileSavedSuccessfully', {
          defaultValue: 'Courier profile saved successfully.',
        }),
        type: 'success',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
    } catch {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }),
        description: t('common.somethingWentWrongWithSavingCourierProfile', {
          defaultValue: 'Sorry, something went wrong with saving courier profile.',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const isSaveVisible = useMemo(() => {
    if (
      initialValues?.internalComment !== internalComment ||
      initialValues?.markedForAttention !== markedForAttention ||
      initialValues?.selectedOrganizationCourierCategory !== selectedOrganizationCourierCategory
    ) {
      return true;
    }
    return false;
  }, [initialValues, internalComment, markedForAttention, selectedOrganizationCourierCategory]);

  return {
    emergencyContact,
    handleDocumentsPress,
    handleMarkedForAttention,
    handleInternalCommentChange,
    handleSelectCategory,
    handleSaveData,
    internalComment,
    isSaveVisible,
    languagesKnown,
    loadingSave,
    markedForAttention,
    nationalities,
    organization,
    profile,
    profileImage,
    selectedOrganizationCourierCategory,
    socialProfiles,
    taxCountry,
  };
};
