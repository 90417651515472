import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  warning: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 30,
  },
  warningTitle: {
    maxWidth: 400,
    color: 'text-basic-color',
    textAlign: 'center',
  },
  warningSubtitle: {
    fontSize: 20,
    maxWidth: 400,
    color: 'text-basic-color',
    textAlign: 'center',
    paddingBottom: 20,
  },
  warningText: {
    fontSize: 18,
    maxWidth: 440,
    paddingHorizontal: 20,
    color: 'text-basic-color',
    textAlign: 'center',
    paddingBottom: 10,
  },
});
