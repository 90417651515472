import React from 'react';
import { Pressable, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { Feather } from '@expo/vector-icons';
import { Icon, IconProps, StyleService, useStyleSheet } from '@ui-kitten/components';
import { Text } from '@ui-kitten/components';
import * as ExpoClipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

export const CopyableText = ({
  text,
  copyableTextName,
  overrideStyle,
  fontSize,
  lineHeight,
  textStyle,
  style,
}: {
  copyableTextName?: string;
  overrideStyle?: StyleProp<ViewStyle>;
  fontSize?: number;
  lineHeight?: number;
  style?: StyleProp<ViewStyle>;
  text: string;
  textStyle?: StyleProp<TextStyle>;
}) => {
  const { t } = useTranslation();

  const styles = useStyleSheet(themedStyles);

  const CopyIcon = (props: IconProps) => (
    <Feather
      {...props}
      name="copy"
      size={16}
      color={styles.iconColor.backgroundColor}
      style={styles.icon}
    />
  );

  const copyToClipboard = () => {
    ExpoClipboard.setStringAsync(text);
    showMessage({
      message: t('common.Copied!', { defaultValue: 'Copied!' }),
      description: t('common.copiedTextMessage', {
        copyableTextName,
        defaultValue: 'Copied {{copyableTextName}} to Clipboard!',
      }) as string,
      renderFlashMessageIcon: () => <Icon name="check-outline" />,
      type: 'success',
    });
  };

  return (
    <Pressable
      onPress={copyToClipboard}
      style={overrideStyle ? overrideStyle : [styles.pressable, style]}
    >
      <Text style={[styles.text, { fontSize, lineHeight }, textStyle]}>{text}</Text>
      <CopyIcon />
    </Pressable>
  );
};

const themedStyles = StyleService.create({
  pressable: {
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    color: 'text-basic-color',
    marginRight: 3,
  },
  icon: {
    marginLeft: 3,
    paddingLeft: 3,
  },
  iconColor: {
    backgroundColor: 'text-basic-color',
  },
});
