import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { RouteProp } from '@react-navigation/core';
import { Button, Layout, StyleService } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { USER_IDENTITY_DOCUMENTS_QUERY } from '../../apollo/graphql-queries';
import CommonUserDocumentsComponent from '../../components/common.user-documents.component';
import { CourierProfileTopNavigationBar } from '../../components/courier/courier-profile-top-navigation-bar.component';
import { AddIcon } from '../../components/icons.component';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import AppUserContext from '../../contexts/AppUserContext';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { CourierProfileNavigatorParamList } from '../../navigation/app.navigator';
import { UserDocument2 } from '../../types';

const AddNewDocument = (navigation: any): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  return (
    <>
      <Button
        accessoryLeft={AddIcon}
        onPress={() => {
          navigation.navigate('CommonUserDocumentDetailScreen', {
            targetDocument: 'UserIdentityDocument',
            readOnly: false,
            title: 'Add Identity Document',
            user: appUserContext.currentUserContext?.user,
          });
        }}
        appearance="ghost"
      >
        {t('document.addDocument', { defaultValue: 'Add document' }) as string}
      </Button>
    </>
  );
};

interface CourierUserIdentityDocumentsScreenProps {
  children?: React.ReactNode;
  navigation: any;
  route: RouteProp<CourierProfileNavigatorParamList, 'CourierUserIdentityDocumentsScreen'>;
}

export const CourierUserIdentityDocumentsScreen: React.FC<
  CourierUserIdentityDocumentsScreenProps
> = ({ navigation, route }) => {
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);

  const { data: userIdentityDocumentsData } = useQuery<{
    userIdentityDocuments: UserDocument2[];
  }>(USER_IDENTITY_DOCUMENTS_QUERY, {
    variables: { userId: appUserContext.currentUserContext?.user?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  return (
    <SafeAreaLayout style={styles.container}>
      <CourierProfileTopNavigationBar title="Identity Documents" navigation={navigation} />
      <Layout style={styles.container}>
        {route.params?.readOnly ? null : AddNewDocument(navigation)}
        <CommonUserDocumentsComponent
          screenName="CourierUserIdentityDocumentsScreen"
          targetDocument="UserIdentityDocument"
          userDocumentList={userIdentityDocumentsData?.userIdentityDocuments}
        />
      </Layout>
    </SafeAreaLayout>
  );
};

const styles = StyleService.create({
  container: {
    flex: 1,
  },
});
