import React, { useEffect, useState } from 'react';
import { Image, View } from 'react-native';

import { Text } from '@ui-kitten/components/ui';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';

import ThemeContext from '../../contexts/ThemeContext';

/**
 * Returns a Loading Spinner component that shows a Loading Bar.
 * @param   {<type>} text Text that would overwrite the default text "Loading..."
 * @param   {<type>} subject Subject that describes what is being loaded. If set without overwriting the text, it will translate to "Loading {subject}..."
 * @return  {<type>}        JSX.Element (animated and themed loading bar)
 */
export const LoadingSpinner = ({
  text,
  subject,
  noText = false,
}: {
  text?: string | DefaultTFuncReturn;
  subject?: string;
  noText?: boolean;
}) => {
  const themeContext = React.useContext(ThemeContext);
  const { t } = useTranslation();
  const [loadingText, setLoadingText] = useState<string>();

  useEffect(() => {
    if (text && noText === false) {
      setLoadingText(text);
    } else if (noText === false) {
      if (subject) {
        setLoadingText(
          t('common.loadingSpinnerTextWithSubject', {
            defaultValue: 'Loading {{subject}}...',
            subject,
          }) as string,
        );
      } else {
        setLoadingText(t('common.loadingSpinnerText', { defaultValue: 'Loading...' }) as string);
      }
    }
  }, [text, subject]);

  return (
    <View
      style={{
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Image
        source={
          themeContext.theme === 'dark'
            ? require('./../../assets/images/loading-bar-dark.gif')
            : require('./../../assets/images/loading-bar-light.gif')
        }
        style={{ marginTop: 20, height: 15, width: 128 }}
      />
      <View
        style={{
          height: 50,
          paddingTop: 10,
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        {noText === false ? <Text>{loadingText}</Text> : <></>}
      </View>
    </View>
  );
};
