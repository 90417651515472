import React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { CommonProfileNavigator } from './common.profile.navigator';
import { DeviceSetupIcon, LoginIcon } from '../components/icons.component';
import CommonNotificationPermissionsScreen from '../screens/common/CommonNotificationPermissionsScreen';

const BottomTabNavigator = createBottomTabNavigator();

const BottomTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
  const { t } = useTranslation();

  return (
    <>
      <BottomNavigation
        selectedIndex={state.index}
        onSelect={(index) => navigation.navigate(state.routeNames[index])}
      >
        <BottomNavigationTab
          title={t('common.setupDevice', { defaultValue: 'Setup Device' }).toUpperCase()}
          icon={DeviceSetupIcon}
        />
        <BottomNavigationTab
          title={t('common.login', { defaultValue: 'Login' }).toUpperCase()}
          icon={LoginIcon}
        />
      </BottomNavigation>
    </>
  );
};

const DeviceSetupNavigator = () => {
  return (
    <BottomTabNavigator.Navigator
      screenOptions={{ headerShown: false }}
      tabBar={(props) => <BottomTabBar {...props} />}
    >
      <BottomTabNavigator.Screen
        name="CommonNotificationPermissionsScreen"
        component={CommonNotificationPermissionsScreen}
        initialParams={{ hideSettings: true }}
      />
      <BottomTabNavigator.Screen name="CommonProfileNavigator" component={CommonProfileNavigator} />
    </BottomTabNavigator.Navigator>
  );
};

export { DeviceSetupNavigator };
