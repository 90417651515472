import React, { useRef, useState } from 'react';
import { Pressable, TextInput, View } from 'react-native';

import { useStyleSheet } from '@ui-kitten/components';

import { themedStyles } from './styles';
import { globalStyle as globalStyleCommon } from '../../../../../../common/style';
import useDimensions from '../../../../../../hooks/useDimensions';

export const Input = ({
  error,
  handleFieldTextChange: handleFieldTextChangeProp,
  status,
  value,
}: any) => {
  const inputRef = useRef<TextInput>(null);

  const globalStyle = useStyleSheet(globalStyleCommon);
  const { isLargeDevice, isExtraLargeDevice } = useDimensions();
  const isWebView = isLargeDevice || isExtraLargeDevice;
  const styles = useStyleSheet(themedStyles);

  const [text, setText] = useState(value);

  const handleInputFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFieldTextChange = (v: string) => {
    setText(v);
    handleFieldTextChangeProp(v);
  };

  const disabled =
    status === 'alreadyInvited' || status === 'alreadyConnected' || status === 'invited';
  return (
    <View style={isWebView ? globalStyle.absoluteFull : globalStyle.flex1}>
      <TextInput
        ref={inputRef}
        style={[
          globalStyle.paddingRight10,
          globalStyle.marginRight5,
          globalStyle.paddingHorizontal5,
          globalStyle.colorTextBasicColor,
          styles.minHeight40,
          isWebView && globalStyle.borderWidth1,
          isWebView && globalStyle.fontSize14,
          isWebView ? globalStyle.absoluteFull : globalStyle.width100Percent,
          !isWebView && !!error && globalStyle.borderWidth1,
          error ? globalStyle.borderDangerColor600 : globalStyle.borderColorTransparent,
        ]}
        editable={!disabled}
        onChangeText={handleFieldTextChange}
        value={text}
      />
      <Pressable style={globalStyle.absoluteFull} onPress={handleInputFocus} />
    </View>
  );
};
