import { useRef, useState } from 'react';
import { Linking, Platform } from 'react-native';

import FlashMessage from 'react-native-flash-message';

export const useHook = () => {
  const flashMessageRef = useRef<FlashMessage>(null);

  const [pdfUri, setPDFUri] = useState('');

  const showMessage = (data: {
    message: string;
    description: string;
    type: 'success' | 'danger';
  }) => {
    flashMessageRef.current?.showMessage(data);
  };

  const handlePDFPress = (uri: string) => () => {
    if (Platform.OS === 'web' && uri) {
      Linking.openURL(uri);
    } else {
      setPDFUri(uri);
    }
  };

  return { flashMessageRef, handlePDFPress, showMessage, pdfUri };
};
