import { DateTime } from 'luxon';

/**
 *
// These are the DateTime columns from the backend as of 15.07.2022.
// The function to find them are defined below.

returnTimeAtStartAirport?: Date
returnTimeAtEndAirport?: Date
checkInTimeAtStartAirport?: Date
checkInTimeAtEndAirport?: Date
timestamp?: Date
startTime: Date
endTime?: Date
createdAt: Date
updatedAt: Date
deletedAt?: Date
syncedAt: Date
createdAt: Date
updatedAt: Date
syncedAt: Date
createdAt: Date
updatedAt: Date
updatedAt: Date
syncedAt?: Date
relationshipTypeChangedAt: Date
timestamp: Date
timestamp: Date
appLastUpdatedAt?: Date
appLastUpdatedAt?: Date
timestamp: Date
timestamp: Date
createdAt: Date
pickupTime: Date
dropOffTime?: Date
sentAt?: Date
receivedAt?: Date
updatedAt: Date
syncedAt?: Date
sharedAt: Date
sharedAt: Date
syncedAt: Date
createdAt: Date
updatedAt: Date
usedAt?: Date
voidedAt?: Date
dateOfIssue?: Date
expiresOn?: Date
updatedAt: Date
lastUsedAt: Date
signedInAt: Date
signedOutAt: Date
lastUsedAt?: Date
signedInAt?: Date
signedOutAt?: Date
verifiedOn?: Date
expiresOn?: Date
dateTimeOfTest?: Date
expiresOn?: Date
dateOfIssue?: Date
dateOfBirth?: Date
dateOfIssue?: Date
expiresOn?: Date
expiresOn?: Date
dateOfIssue?: Date
dateOfBirth?: Date
dateOfVaccination?: Date
expiresOn?: Date
dateOfBirth?: Date
startTime: Date
endTime?: Date


  /*   entryCheckFunctions: Array<(key: string, value: any) => boolean> = [
    (key, value) => key === 'timestamp',
    (key, value) => key.endsWith('At'),
    (key, value) => key.endsWith('Time'),
    (key, value) => key.endsWith('On'),
    (key, value) => key.startsWith('dateOf'),
    (key, value) => key.startsWith('dateTimeOf'),
  ], */

const isoTimestampRegexPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/i;
const isoTimestampRegex = new RegExp(isoTimestampRegexPattern);

export const parseJsonWithDateTime = <T = any>(jsonString: string): T | null => {
  try {
    const parsedJson = JSON.parse(jsonString, (key, value) => {
      // ISO pattern to match timestamps.
      // ISO pattern "2022-02-04T09:51:38.000+01:00" (29 length) or "2022-02-04T09:51:38.000Z" (24 length)
      if (
        key &&
        value &&
        (value.length === 24 || value.length === 29) &&
        isoTimestampRegex.test(value)
      ) {
        const newValue: DateTime | undefined = DateTime.fromISO(value);
        return newValue && newValue.isValid ? newValue : null;
      }
      return value;
    });

    return parsedJson;
  } catch (e) {
    return jsonString as unknown as T;
  }
};
