import React from 'react';
import { Linking, Platform, TouchableOpacity, View } from 'react-native';

import { Entypo, FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { StyleService, useStyleSheet } from '@ui-kitten/components';
import { Card, Text, Divider, Button } from '@ui-kitten/components/ui';
import { t } from 'i18next';
import { DateTime } from 'luxon';

import { AgencyJobCardItemMenu } from './AgencyJobCardItemMenu';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { AgencyJob } from '../../../generated-graphql-types';
import i18n from '../../../i18n/i18n';
import { DateTimeWithTimeZonesPopover } from '../../common/datetime-with-time-zones-popover.component';
import { TimeAgoPopoverText } from '../../common/time-ago-popover-text.component';

export const AgencyJobCardItem = ({
  agencyJob,
  onViewCouriersDetails,
  previousScreenName,
  previousTabScreenName,
  showInternalNotesModal,
  updateJob,
}: {
  agencyJob: Partial<AgencyJob>;
  onLayout?: (event: any) => void;
  onViewCouriersDetails?: (agencyJob: AgencyJob) => void;
  previousScreenName: string;
  previousTabScreenName:
    | 'AgencyNewJobsScreen'
    | 'AgencyStartedJobsScreen'
    | 'AgencyFinishedJobsScreen'
    | 'AgencyCancelledJobsScreen';
  showInternalNotesModal?: (data: { agencyJobId: string; internalNotes: string }) => void;
  updateJob?: (data: Partial<AgencyJob>) => void;
}) => {
  const navigation: any = useNavigation();
  const globalStyle = useStyleSheet(globalStyleCommon);
  const styles = useStyleSheet(themedStyles);

  const handleHeaderPress = () => {
    if (previousScreenName) {
      navigation.navigate('AgencyViewCourierJobScreen', {
        agencyJobId: agencyJob?.id || '',
        previousScreenName: previousScreenName,
        // previousScreenName: 'AgencyJobsScreen',
        previousTabScreenName: previousTabScreenName,
        previousNavigator: 'AgencyHomeNavigator',
      });
    }
  };

  const handleCO2LinkPress = () => {
    if (agencyJob.responsibleAgencyOrganization?.linkCO2Emissions) {
      Linking.openURL(agencyJob.responsibleAgencyOrganization?.linkCO2Emissions);
    }
  };

  const renderCardHeader = (props: any) => (
    <TouchableOpacity
      {...props}
      style={styles.cardHeader}
      onPress={handleHeaderPress}
      activeOpacity={0.9}
    >
      <View style={[globalStyle.flexRow, globalStyle.alignItemsStretch]}>
        <Text category="h6" style={styles.cardHeaderText}>
          {agencyJob.job?.priojetJobIdentifier as string}{' '}
          {` (${agencyJob.externalJobReference})` || ''}
        </Text>
        {agencyJob.hasIncident && (
          <FontAwesome
            name="warning"
            size={20}
            color={styles.incidentIconColor.backgroundColor}
            style={styles.incidentIcon}
          />
        )}
      </View>
    </TouchableOpacity>
  );

  return (
    <Card style={styles.card} appearance="outline" key={agencyJob.id} header={renderCardHeader}>
      <View>
        <View style={styles.timeDistanceLayer}>
          {!!agencyJob.job?.pickupTime && (
            <TimeAgoPopoverText
              text="Pick up "
              date={agencyJob.job?.pickupTime}
              language={i18n.language}
              textStyle={globalStyle.fontSize13}
              style={[
                globalStyle.flexRow,
                globalStyle.alignSelfEnd,
                globalStyle.alignContentEnd,
                globalStyle.justifyContentEnd,
                globalStyle.paddingBottom5,
              ]}
            />
          )}
        </View>
        <View style={[styles.locationsOutsideWrapper]}>
          <Text style={styles.locationsOutsideWrapperHeader}>
            {t('common.start', { defaultValue: 'Start' }).toUpperCase()}
          </Text>
          <View style={[styles.locationWrapper]}>
            <View style={styles.locationBox}>
              <View style={styles.locationBoxLeft}>
                {!!agencyJob.job?.pickupLocation?.formattedShortAddress && (
                  <View style={styles.locationBoxIconNameWrapper}>
                    <MaterialCommunityIcons
                      name="warehouse"
                      size={24}
                      color={styles.icon.backgroundColor}
                      style={styles.startIcon}
                    />
                    <Text style={styles.locationName}>
                      {agencyJob.job.pickupLocation.formattedShortAddress ||
                        '[No short pickup location name]'}
                    </Text>
                  </View>
                )}
              </View>
              <View style={styles.locationBoxRight}>
                {!!agencyJob.startAirport?.formattedAirportAddress && (
                  <View style={styles.locationBoxIconNameWrapper}>
                    <Entypo
                      name="aircraft-take-off"
                      size={24}
                      color={styles.icon.backgroundColor}
                      style={styles.startIcon}
                    />
                    <Text style={styles.locationName}>
                      {agencyJob.startAirport.formattedAirportAddress ||
                        '[No short pickup location name]'}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>

          <View style={[globalStyle.flex1, globalStyle.padding5, globalStyle.marginBottom5]}>
            {!!agencyJob.job?.pickupLocation && agencyJob.job?.pickupTime && (
              <View style={styles.dateTimeWithTimeZoneWrapper}>
                <DateTimeWithTimeZonesPopover
                  label={t('jobs.pickupTime', { defaultValue: 'Pickup time' }) + ': '}
                  dateTimeString={
                    DateTime.fromISO(agencyJob.job.pickupTime.toString())
                      .setZone(agencyJob.job.pickupLocation.timeZoneIdentifier as string)
                      .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                  }
                  date={agencyJob.job.pickupTime}
                  targetTimeZoneIdentifier={
                    agencyJob.job.pickupLocation.timeZoneIdentifier as string
                  }
                  targetLocationName={
                    agencyJob.job.pickupLocation.formattedShortAddress ||
                    t('jobs.pickupTime', { defaultValue: 'Pickup time' })
                  }
                  popoverContentTitle={t('jobs.pickupTime', { defaultValue: 'Pickup time' })}
                />
              </View>
            )}
            {!!agencyJob.checkInTimeAtStartAirport && agencyJob.startAirport && (
              <View style={styles.dateTimeWithTimeZoneWrapper}>
                <DateTimeWithTimeZonesPopover
                  label={
                    t('jobs.checkInTimeAtStartAirport', {
                      defaultValue: 'Check-in time at start airport',
                    }) + ': '
                  }
                  dateTimeString={
                    DateTime.fromISO(agencyJob.checkInTimeAtStartAirport.toString())
                      .setZone(agencyJob.startAirport.timeZoneIdentifier as string)
                      .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                  }
                  date={agencyJob.checkInTimeAtStartAirport}
                  targetTimeZoneIdentifier={agencyJob.startAirport.timeZoneIdentifier as string}
                  targetLocationName={
                    agencyJob.startAirport.formattedShortAddress ||
                    t('common.startAirport', { defaultValue: 'Start Airport' })
                  }
                  popoverContentTitle={t('jobs.checkInTimeAtStartAirport', {
                    defaultValue: 'Check-in time at start airport',
                  })}
                />
              </View>
            )}
          </View>
        </View>
        <View style={[styles.locationsOutsideWrapper]}>
          <Text style={styles.locationsOutsideWrapperHeader}>
            {t('common.destination', { defaultValue: 'Destination' }).toUpperCase()}
          </Text>
          <View style={[styles.locationWrapper]}>
            <View style={styles.locationBox}>
              <View style={styles.locationBoxLeft}>
                {!!agencyJob?.endAirport?.formattedAirportAddress && (
                  <>
                    <View style={styles.locationBoxIconNameWrapper}>
                      <Entypo
                        name="aircraft-landing"
                        size={24}
                        color={styles.icon.backgroundColor}
                        style={styles.endIcon}
                      />
                      <Text style={styles.locationName}>
                        {agencyJob.endAirport.formattedAirportAddress ||
                          '[No end airport location name]'}
                      </Text>
                    </View>
                  </>
                )}
              </View>
              <View style={styles.locationBoxRight}>
                {!!agencyJob.job?.dropOffLocation?.formattedShortAddress && (
                  <View style={styles.locationBoxIconNameWrapper}>
                    <Ionicons
                      name="location-sharp"
                      size={24}
                      color={styles.icon.backgroundColor}
                      style={styles.startIcon}
                    />
                    <Text style={styles.locationName}>
                      {agencyJob.job.dropOffLocation.formattedShortAddress ||
                        '[No short dropOff location name]'}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>

          <Divider style={globalStyle.marginTop5} />
          <View style={globalStyle.padding5}>
            {!!agencyJob.job?.dropOffLocation && !!agencyJob.job?.dropOffTime && (
              <View style={styles.dateTimeWithTimeZoneWrapper}>
                <DateTimeWithTimeZonesPopover
                  label={t('jobs.dropOffTime', { defaultValue: 'Drop-off time' }) + ': '}
                  dateTimeString={
                    DateTime.fromISO(agencyJob.job.dropOffTime.toString())
                      .setZone(agencyJob.job.dropOffLocation.timeZoneIdentifier as string)
                      .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                  }
                  date={agencyJob.job.dropOffTime}
                  targetTimeZoneIdentifier={
                    agencyJob.job.dropOffLocation.timeZoneIdentifier as string
                  }
                  targetLocationName={
                    agencyJob.job.pickupLocation.formattedShortAddress ||
                    t('jobs.dropOffTime', { defaultValue: 'Drop-off time' })
                  }
                  popoverContentTitle="Drop-off time"
                />
              </View>
            )}
            {!!agencyJob.checkInTimeAtEndAirport && !!agencyJob?.endAirport && (
              <View style={styles.dateTimeWithTimeZoneWrapper}>
                <DateTimeWithTimeZonesPopover
                  label={
                    t('jobs.checkInAtEndAirport', {
                      defaultValue: 'Check-in time at end airport',
                    }) + ': '
                  }
                  dateTimeString={
                    DateTime.fromISO(agencyJob.checkInTimeAtEndAirport.toString())
                      .setZone(agencyJob.endAirport.timeZoneIdentifier as string)
                      .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                  }
                  date={agencyJob.checkInTimeAtEndAirport}
                  targetTimeZoneIdentifier={agencyJob.endAirport.timeZoneIdentifier as string}
                  targetLocationName={
                    agencyJob.endAirport.formattedShortAddress ||
                    t('jobs.checkInAtEndAirport', {
                      defaultValue: 'Check-in time at end airport',
                    })
                  }
                  popoverContentTitle={t('jobs.checkInAtEndAirport', {
                    defaultValue: 'Check-in time at end airport',
                  })}
                />
              </View>
            )}
            {!!agencyJob.returnTimeAtEndAirport && !!agencyJob?.endAirport && (
              <View style={styles.dateTimeWithTimeZoneWrapper}>
                <FontAwesome
                  name="flag-checkered"
                  size={24}
                  color={styles.icon.backgroundColor}
                  style={[styles.startIcon, globalStyle.marginRight10]}
                />
                <DateTimeWithTimeZonesPopover
                  label={
                    t('jobs.returnTimeAtEndAirport', {
                      defaultValue: 'Return time at end airport',
                    }) + ': '
                  }
                  dateTimeString={
                    DateTime.fromISO(agencyJob.returnTimeAtEndAirport.toString())
                      .setZone(agencyJob.endAirport.timeZoneIdentifier as string)
                      .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                  }
                  date={agencyJob.returnTimeAtEndAirport}
                  targetTimeZoneIdentifier={agencyJob.endAirport.timeZoneIdentifier as string}
                  targetLocationName={
                    agencyJob.endAirport.formattedShortAddress ||
                    t('jobs.returnTimeAtEndAirport', { defaultValue: 'Return time at end airport' })
                  }
                  popoverContentTitle="Return time at end airport"
                />
              </View>
            )}
            {!!agencyJob.returnTimeAtStartAirport && !!agencyJob.startAirport && (
              <View
                style={
                  Platform.OS === 'web'
                    ? [globalStyle.flexRow, globalStyle.justifyContentBetween, globalStyle.flexWrap]
                    : [globalStyle.flexColumn, globalStyle.alignItemsStart]
                }
              >
                <View style={[globalStyle.flexRow, styles.dateTimeWithTimeZoneWrapper]}>
                  <FontAwesome
                    name="flag-checkered"
                    size={24}
                    color={styles.icon.backgroundColor}
                    style={[styles.startIcon, globalStyle.marginRight10]}
                  />
                  <DateTimeWithTimeZonesPopover
                    label={
                      t('jobs.returnTimeAtStartAirport', {
                        defaultValue: 'Return time at start airport',
                      }) + ': '
                    }
                    dateTimeString={
                      DateTime.fromISO(agencyJob.returnTimeAtStartAirport.toString())
                        .setZone(agencyJob.startAirport.timeZoneIdentifier as string)
                        .toFormat('dd MMM yyyy, HH:mm') + ' LT'
                    }
                    date={agencyJob.returnTimeAtStartAirport}
                    targetTimeZoneIdentifier={agencyJob.startAirport.timeZoneIdentifier as string}
                    targetLocationName={
                      agencyJob.startAirport.formattedShortAddress ||
                      t('jobs.returnTimeAtStartAirport', {
                        defaultValue: 'Return time at start airport',
                      })
                    }
                    popoverContentTitle="Return time at start airport"
                  />
                </View>
                {!!agencyJob.responsibleAgencyOrganization?.linkCO2Emissions && (
                  <Button
                    onPress={handleCO2LinkPress}
                    appearance="outline"
                    status="success"
                    size="small"
                    accessoryLeft={() => (
                      <MaterialCommunityIcons
                        color={(globalStyle.colorSuccess500 as any).color}
                        name="leaf-circle"
                        size={24}
                      />
                    )}
                    style={[
                      globalStyle.paddingTop0,
                      globalStyle.paddingBottom0,
                      globalStyle.marginRight5,
                      globalStyle.marginLeft10,
                      globalStyle.marginTop10,
                    ]}
                  >
                    {(evaProps) => (
                      <Text
                        style={[evaProps?.style, globalStyle.fontSize11, globalStyle.marginBottom2]}
                      >
                        {t('jobs.compensateCO2Emissions', {
                          defaultValue: 'Compensate CO2 emissions',
                        })}
                      </Text>
                    )}
                  </Button>
                )}
              </View>
            )}
          </View>
        </View>
        <View style={styles.menuWrapper}>
          <AgencyJobCardItemMenu
            agencyJob={agencyJob}
            onViewCouriersDetails={onViewCouriersDetails}
            updateJob={updateJob}
            previousScreenName={previousScreenName}
            previousTabScreenName={previousTabScreenName}
            showInternalNotesModal={showInternalNotesModal}
          />
        </View>
      </View>
    </Card>
  );
};

const themedStyles = StyleService.create({
  refreshColor: {
    color: 'text-basic-color',
  },
  cardHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    height: 30,
    alignContent: 'space-between',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: 'color-primary-500',
  },
  cardHeaderText: {
    fontFamily: 'Lato_700Bold',
    color: 'color-basic-100',
  },
  timeDistanceLayer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  incidentIconColor: {
    backgroundColor: 'color-primary-300',
  },
  incidentIcon: {
    marginLeft: 15,
  },
  card: {
    marginVertical: 10,
    padding: 10,
    paddingHorizontal: 0,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: 'color-primary-500',
    backgroundColor: 'background-basic-color-2',
  },
  cardFooter: {
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: 'border-basic-color-2',
    borderWidth: 0,
    borderColor: 'color-primary-500',
  },
  cardFooterText: {
    color: 'text-basic-color',
    fontSize: 12,
  },
  dateTimeWithTimeZoneWrapper: {
    paddingVertical: 2,
    paddingLeft: 10,
  },
  agencyJobStatusChangeLayer: {
    flex: 1,
    flexDirection: 'column',
  },
  agencyJobStatusChangeHintText: {
    flexDirection: 'row',
    flexShrink: 1,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    color: 'gray',
    fontSize: 12,
  },
  agencyJobStatusChangeHintLayer: {
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: 10,
    marginRight: 20,
  },
  agencyJobStatusChangeButtonLayer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  agencyJobStatusOutsideLayer: {
    flexDirection: 'row',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginBottom: 5,
    backgroundColor: 'background-basic-color-3',
    padding: 10,
    paddingVertical: 5,
  },
  agencyJobStatusLayer: {
    paddingVertical: 3,
    paddingHorizontal: 8,
    borderBottomWidth: 0,
    borderColor: 'color-border-low-contrast',
    borderRadius: 0,
  },
  agencyJobStatusText: {
    fontSize: 10,
    color: 'color-text-low-contrast',
  },
  agencyJobStatusCountText: {
    fontFamily: 'Lato_700Bold',
    fontSize: 10,
    color: 'color-text-low-contrast',
  },
  agencyJobMenuItemsWrapper: {
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 10,
    paddingBottom: 5,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  agencyJobMenuWrapperLeft: {
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },

  agencyJobStatusWrapper: {
    flexDirection: 'column',
  },
  spacedElement: {
    margin: 5,
    marginBottom: 0,
  },
  icon: {
    backgroundColor: 'color-primary-300',
  },
  locationName: {
    fontSize: 16,
  },
  menuWrapper: {
    paddingTop: 10,
    marginBottom: 0,
    backgroundColor: 'border-basic-color-2',
  },
  locationsOutsideWrapper: {},
  locationsOutsideWrapperHeader: {
    fontFamily: 'Lato_700Bold',
    marginBottom: 7,
    fontSize: 10,
    textAlign: 'center',
    backgroundColor: 'border-basic-color-2',
  },
  locationWrapper: {
    borderWidth: 0,
    paddingHorizontal: 10,
  },
  iconLocationNameWrapper: {},
  startIcon: {
    marginRight: 10,
  },
  endIcon: {
    marginRight: 15,
    paddingRight: 10,
  },
  arrowIcon: {
    marginHorizontal: 5,
  },
  locationBox: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    borderWidth: 0,
  },
  locationBoxIconNameWrapper: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    alignItems: 'baseline',
    borderWidth: 0,
  },
  locationBoxRight: {
    flex: 1,
  },
  locationBoxLeft: {
    flex: 1,
  },
  courierCountButton: {
    backgroundColor: 'border-basic-color-1',
    borderColor: 'border-basic-color-1',
  },
});
