import React, { useRef, useState } from 'react';
import { RefreshControl, ScrollView } from 'react-native';

import { globalStyle } from '../../../common/style';
import { AgencyJobCardItem } from '../../../components/agency/AgencyJobCardItem';
import { AgencyJobViewCouriersModal } from '../../../components/agency/AgencyJobCardItem/AgencyJobViewCouriersModal';
import { AgencyJobInternalNotesModal } from '../../../components/common/modal/AgencyJobInternalNotesModal';
import { AgencyJob } from '../../../generated-graphql-types';

interface Props {
  agencyJob?: AgencyJob;
  handleUpdateJob: (agencyJob: Partial<AgencyJob>) => void;
  onRefresh: any;
  refreshing: boolean;
  previousScreenName: string;
  previousTabScreenName:
    | 'AgencyNewJobsScreen'
    | 'AgencyStartedJobsScreen'
    | 'AgencyFinishedJobsScreen'
    | 'AgencyCancelledJobsScreen';
}

export const AgencyCourierJobOverviewScreen: React.FC<Props> = ({
  agencyJob,
  handleUpdateJob,
  onRefresh,
  refreshing,
  previousScreenName,
  previousTabScreenName,
}) => {
  const internalNotesModalRef = useRef<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleShowInternalNotesModal = ({
    agencyJobId,
    internalNotes,
  }: {
    agencyJobId: string;
    internalNotes: string;
  }) => {
    internalNotesModalRef.current?.showInternalNotesModal({ agencyJobId, value: internalNotes });
  };

  return (
    <>
      <ScrollView
        style={globalStyle.flex1}
        contentContainerStyle={globalStyle.paddingHorizontal10}
        refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}
      >
        {!!agencyJob && (
          <AgencyJobCardItem
            agencyJob={agencyJob}
            updateJob={handleUpdateJob}
            onViewCouriersDetails={handleOpenModal}
            previousScreenName={'' as any}
            previousTabScreenName={'' as any}
            showInternalNotesModal={handleShowInternalNotesModal}
          />
        )}
      </ScrollView>
      <AgencyJobViewCouriersModal
        agencyJob={agencyJob}
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        previousScreenName={previousScreenName}
        previousTabScreenName={previousTabScreenName}
      />
      <AgencyJobInternalNotesModal ref={internalNotesModalRef} />
    </>
  );
};
