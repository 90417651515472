import React, { useContext } from 'react';
import { TouchableOpacity, View, Platform } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Button, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../common/style';
import AppContext from '../../../../contexts/AppContext';
import {
  SubscriptionFeatureType,
  OrganizationUserRoleType,
} from '../../../../generated-graphql-types';
import useDimensions from '../../../../hooks/useDimensions';
import { LoadingSpinner } from '../../../common/loading-spinner.component';
import { SubscriptionPermissions } from '../../../common/SubscriptionPermissions';
import ContentBoxTextTitle from '../../../content-box-text-title.component';

export const AgencyJobsBox = () => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { isLargeDevice } = useDimensions();
  const appUserContext = useContext(AppContext);
  const navigation: any = useNavigation();

  const {
    dataCount,
    handleCreateJobPress,
    handleOpenJobsPress,
    handleOpeOBCMapPress,
    isReady,
    loading,
  } = useHook();

  const renderShowOBCsMap = (): React.ReactElement => (
    <SubscriptionPermissions
      enableRefetch={false}
      warningContent={{
        cancelButtonText: t('common.close', { defaultValue: 'Close' }),
        confirmButtonText:
          appUserContext.currentUserContext?.organizationUser?.roleType ===
          OrganizationUserRoleType.OWNER
            ? t('common.contactPriojet', {
                defaultValue: 'Contact PRIOjet',
              })
            : '',
        title: '',
        text:
          Platform.OS === 'web'
            ? t('subscription.thisRunwayIsCloseAgencyGoldPlanContactSupportWeb', {
                defaultValue:
                  'This runway is closed for you. This will be available if you have an Agency Gold Plan and up. Please contact PRIOjet support to upgrade.',
              })
            : t('subscription.thisRunwayIsCloseAgencyGoldPlanContactSupportMobile', {
                defaultValue:
                  'This runway is closed for you. Contact PRIOjet support for more information.',
              }),
        onConfirmPress: () => {
          navigation.navigate('AgencyProfileNavigator', {
            screen: 'CommonChatSupportPipedriveScreen',
          });
        },
      }}
      feature={SubscriptionFeatureType.AGENCY_COURIER_AVAILABILITY_MAP}
      warningType="modal"
    >
      {({ checkPermissions, handleShowWarning }: any) => (
        <Button
          appearance="filled"
          style={[styles.flex1, isLargeDevice && styles.marginLeft3]}
          onPress={async () => {
            const hasPermission = await checkPermissions();
            if (!hasPermission) {
              handleShowWarning();
            } else {
              handleOpeOBCMapPress();
            }
          }}
        >
          {t('common.showMyOBCs', { defaultValue: 'Show my OBCs' }) as string}
        </Button>
      )}
    </SubscriptionPermissions>
  );

  if (loading && !isReady) {
    return <ContentBoxTextTitle key="jobs" title="Jobs" Content={<LoadingSpinner />} />;
  }
  return (
    <ContentBoxTextTitle
      key="jobs"
      style={styles.content}
      title={t('common.jobs', { defaultValue: 'Jobs' })}
      Content={
        <View style={globalStyle.paddingTop10}>
          <View style={[styles.flex1, styles.row]}>
            <TouchableOpacity
              onPress={handleOpenJobsPress('NEW')}
              style={[styles.jobCountView, styles.jobCountViewNew]}
            >
              <Text style={styles.jobCountHeaderText}>
                {t('common.new', { defaultValue: 'NEW' }) as string}
              </Text>
              <Text style={styles.jobCountNumberText}>{dataCount.newCount}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleOpenJobsPress('RUNNING')}
              style={[styles.jobCountView, styles.jobCountViewRunning]}
            >
              <Text style={styles.jobCountHeaderText}>
                {t('common.running', { defaultValue: 'RUNNING' }) as string}
              </Text>
              <Text style={styles.jobCountNumberText}>{dataCount.runningCount}</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={handleOpenJobsPress('FINISHED')}
              style={[styles.jobCountView, styles.jobCountViewPast]}
            >
              <Text style={styles.jobCountHeaderText}>
                {t('common.finished', { defaultValue: 'FINISHED' }) as string}
              </Text>
              <Text style={styles.jobCountNumberText}>{dataCount.finishedCount}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={handleOpenJobsPress('CANCELLED')}
              style={[styles.jobCountView, styles.jobCountViewUpcoming]}
            >
              <Text style={styles.jobCountHeaderText}>
                {t('common.cancelled', { defaultValue: 'CANCELLED' }) as string}
              </Text>
              <Text style={styles.jobCountNumberText}>{dataCount.cancelledCount}</Text>
            </TouchableOpacity>
          </View>

          <View
            style={[
              styles.buttonsContainer,
              isLargeDevice ? styles.buttonsRow : styles.buttonsColumn,
            ]}
          >
            <Button
              onPress={handleCreateJobPress}
              appearance="filled"
              style={[
                styles.flex1,
                isLargeDevice && styles.marginRight3,
                !isLargeDevice && styles.marginBottom10,
              ]}
            >
              {t('common.createJob', { defaultValue: 'Create Job' }) as string}
            </Button>
            <Button
              appearance="filled"
              style={[
                styles.flex1,
                isLargeDevice && styles.marginLeft3,
                isLargeDevice && styles.marginRight3,
                !isLargeDevice && styles.marginBottom10,
              ]}
              onPress={handleOpenJobsPress('NEW')}
            >
              {t('common.seeAllJobs', { defaultValue: 'See all Jobs' }) as string}
            </Button>
            {renderShowOBCsMap()}
          </View>
        </View>
      }
    />
  );
};
