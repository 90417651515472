import React from 'react';
import { Linking, TouchableOpacity, View } from 'react-native';

import { Text, StyleService } from '@ui-kitten/components';

import { globalStyle } from '../../../../common/style';

export const CommonAgencyAccountSettingsMobile = (): React.ReactElement => {
  const handleLinkPress = () => {
    const link = 'https://app.priojet.com/agency/profile/agency-account-settings';
    Linking.openURL(link);
  };

  return (
    <View
      style={[
        globalStyle.alignItemsCenter,
        globalStyle.justifyContentCenter,
        globalStyle.marginTop30,
        globalStyle.paddingTop30,
      ]}
    >
      <Text style={styles.text}>
        Please access the online web app to view and manage Agency account settings on {}
        <TouchableOpacity activeOpacity={0.9} onPress={handleLinkPress}>
          <Text>https://app.priojet.com/</Text>
        </TouchableOpacity>
      </Text>
    </View>
  );
};

const styles = StyleService.create({
  text: {
    maxWidth: 300,
    textAlign: 'center',
  },
});
