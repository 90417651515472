import { useEffect, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';

import { DateTime } from 'luxon';

export const useAppState = ({
  cb,
}: {
  cb?: (data: {
    diffFromLastActiveState?: { milliseconds: number; seconds: number; minutes: number };
    prevAppState?: AppStateStatus;
    nextAppState: AppStateStatus;
  }) => void;
} = {}) => {
  const [appState, setAppState] = useState<AppStateStatus>('active');
  const [lastAppState, setLastAppState] = useState<AppStateStatus>();
  const [firstActiveState, setFirstActiveState] = useState<DateTime>(DateTime.now());
  const [lastActiveState, setLastActiveState] = useState<DateTime>(DateTime.now());
  const [prevLastActiveState, setPrevLastActiveState] = useState<DateTime>(DateTime.now());

  useEffect(() => {
    const listener = AppState.addEventListener('change', handleChange);
    return () => {
      listener.remove();
    };
  }, []);

  const handleChange = (newState: AppStateStatus) => {
    setAppState((prev) => {
      if (cb) {
        let diffFromLastActiveState;
        if (newState === 'active') {
          diffFromLastActiveState = {
            milliseconds: DateTime.now().toMillis() - lastActiveState.toMillis(),
            seconds: Math.floor(DateTime.now().toSeconds() - lastActiveState.toSeconds()),
            minutes: Math.floor((DateTime.now().toSeconds() - lastActiveState.toSeconds()) / 60),
          };
        }
        cb({ diffFromLastActiveState, prevAppState: prev, nextAppState: newState });
      }
      setLastAppState(prev);
      return newState;
    });
    if (newState === 'active') {
      setLastActiveState(() => {
        setPrevLastActiveState(lastActiveState);
        return DateTime.now();
      });
    }
  };

  const resetActiveStates = () => {
    const newDate = DateTime.now();
    setFirstActiveState(newDate);
    setLastActiveState(newDate);
    setPrevLastActiveState(newDate);
  };

  return {
    appState,
    diffLastActiveState: {
      milliseconds: lastActiveState.toMillis() - prevLastActiveState.toMillis(),
      seconds: Math.floor(lastActiveState.toSeconds() - prevLastActiveState.toSeconds()),
      minutes: Math.floor((lastActiveState.toSeconds() - prevLastActiveState.toSeconds()) / 60),
    },
    diffFirstActiveState: {
      milliseconds: lastActiveState.toMillis() - firstActiveState.toMillis(),
      seconds: Math.floor(lastActiveState.toSeconds() - firstActiveState.toSeconds()),
      minutes: Math.floor((lastActiveState.toSeconds() - firstActiveState.toSeconds()) / 60),
    },
    lastAppState,
    lastActiveState,
    prevLastActiveState,
    resetActiveStates,
  };
};
