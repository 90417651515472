import React from 'react';
import { View } from 'react-native';

import { Button, Icon, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { FormTextInputTopLabel } from '../../../../components/common/form/form-text-input-top-label.component';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';
import { SocialIcon, SocialKeysType } from '../../../../components/social-icons.component';
import { copySocialToClipboard, SOCIAL_OPTIONS } from '../../../../utils/social-media.util';

export const OBCSocialProfileEdit = ({ errors, fieldProps, values }: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.socialMedia', {
        defaultValue: 'Social Media',
      }).toUpperCase()}
      Content={
        <>
          {Object.keys(SOCIAL_OPTIONS).map((key) => {
            const item = SOCIAL_OPTIONS[key];
            const fields = fieldProps(key);
            return (
              <View style={styles.socialRow} key={key}>
                <View style={styles.socialIconContainer}>
                  <SocialIcon fill="#000000" name={key as SocialKeysType} size={20} />
                </View>
                <FormTextInputTopLabel
                  autoCapitalize="none"
                  label={item.label}
                  placeholder={item.placeholder}
                  style={styles.flex1}
                  containerStyle={styles.flex1}
                  onChangeText={fields.onChangeText}
                  errorMessage={errors?.[key]}
                  value={fields.value}
                />
                <Button
                  disabled={!values[key]}
                  size="tiny"
                  style={styles.socialCopyBtn}
                  onPress={copySocialToClipboard({
                    platform: key,
                    value: values[key],
                  })}
                  accessoryRight={(props) => <Icon {...props} name="copy-outline" />}
                />
              </View>
            );
          })}
        </>
      }
      style={styles.contentBox}
    />
  );
};

const shouldComponentUpdate = (prev: any, next: any) => {
  const keys: any[] = Object.keys(SOCIAL_OPTIONS);
  if (
    keys.some(
      (key) => next.values[key] !== prev.values[key] || next.errors[key] !== prev.errors[key],
    )
  ) {
    return false;
  }
  return true;
};

export default React.memo(OBCSocialProfileEdit, shouldComponentUpdate);
