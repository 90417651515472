import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  wrapper: {
    borderRadius: 6,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '90%',
    backgroundColor: 'background-basic-color-1',
    maxWidth: 700,
  },
  scrollWrapper: {
    width: '100%',
    borderRadius: 6,
  },
  inner: {
    backgroundColor: 'background-basic-color-1',
    width: '100%',
    maxWidth: 700,
    padding: 10,
    paddingTop: 14,
    borderRadius: 6,
    overflow: 'hidden',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 15,
    height: 34,
    width: 34,
    borderRadius: 17,
    marginTop: -3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background-basic-color-1',
  },
  closeIcon: {
    color: 'text-basic-color',
  },
  title: {
    marginTop: 5,
    marginBottom: 20,
    paddingRight: 40,
    paddingLeft: 10,
    textAlign: 'center',
    fontFamily: 'Lato_700Bold',
  },
  subtitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 16,
    marginBottom: 30,
  },
  loading: {
    color: 'white',
  },
  buttonsContainer: {
    width: '100%',
  },

  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  label: {},
  maxLength: {
    fontSize: 13,
  },
  maxLengthError: {
    color: 'color-danger-600',
    fontSize: 13,
  },
  bottomBorderRadius8: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  flashMessage: {
    zIndex: 1001,
    margin: -20,
  },
  flashMessagePaddingTop: {
    paddingTop: 10,
  },
  flashMessageText: {
    maxWidth: 1200,
    alignSelf: 'center',
    width: '100%',
  },
});
