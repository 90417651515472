import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  map: {
    flex: 1,
    backgroundColor: 'red',
    height: '100%',
    width: '100%',
  },
  bottom: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
  },
  bottomCard: {
    flex: 1,
    justifyContent: 'space-between',
    borderRadius: 0,
    padding: 15,
  },

  imageContainer: {
    height: 60,
    width: 60,
    borderRadius: 30,
    resizeMode: 'contain',
    backgroundColor: 'lightgray',
    marginRight: 10,
  },
  locationIcon: {
    color: 'text-basic-color',
  },
  greenDot: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: 'green',
  },
  grayDot: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: 'gray',
  },

  buttonsContainer: {
    marginTop: 20,
  },
  buttonsRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonsColumn: {
    flexDirection: 'column',
  },

  icon: {
    color: 'text-basic-color',
  },
  close: {
    position: 'absolute',
    top: -10,
    right: 0,
    padding: 12,
  },

  btnArrow: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 42,
    backgroundColor: 'background-basic-color-1',
  },
  btnArrowDisabled: {
    opacity: 0.5,
  },
});
