import React from 'react';
import { ActivityIndicator, Image, Pressable, Text, View } from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Icon, useStyleSheet, Button } from '@ui-kitten/components';
import { Camera, PermissionStatus } from 'expo-camera';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';

import { useHook } from './hook.web';
import { themedStyles } from './styles';
import { Props } from './types';
import { globalStyle } from '../../../common/style';
import { openAppSettings } from '../../../modules/device/device.module';

export const CameraModal = ({ onClose, onSelectPicture, visible }: Props): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const {
    aspectRatio,
    cameraRef,
    handleCameraReady,
    handleClose,
    handleReset,
    handleSend,
    handleTakePicture,
    permission,
    photo,
    uploading,
  } = useHook({ onClose, onSelectPicture, visible });

  const renderPhoto = () => {
    if (!photo) {
      return <></>;
    }

    return (
      <View style={[globalStyle.absoluteFull, globalStyle.justifyContentBetween]}>
        <View style={styles.imageContainer}>
          <Image
            resizeMode="contain"
            style={globalStyle.absoluteFull}
            source={{ uri: photo.uri }}
          />
        </View>
        <Pressable
          hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
          style={[styles.closeButton, globalStyle.marginTop15]}
          onPress={handleReset}
        >
          <Icon name="close-outline" height={40} width={40} fill="white" />
        </Pressable>

        <View />
        <View style={[styles.bottomBarSave, globalStyle.paddingBottom15]}>
          <Pressable
            style={[styles.buttonSave, !uploading && styles.buttonSavePadding]}
            onPress={uploading ? undefined : handleSend}
          >
            {uploading ? (
              <ActivityIndicator color="white" size="small" />
            ) : (
              <MaterialCommunityIcons name="send" size={26} color="white" />
            )}
          </Pressable>
        </View>
      </View>
    );
  };

  const renderCamera = () => {
    return (
      <Camera
        ref={cameraRef}
        style={[globalStyle.absoluteFull, styles.camera]}
        autoFocus
        ratio={aspectRatio}
        onCameraReady={handleCameraReady}
        whiteBalance={0}
      >
        <View style={[styles.topBar, globalStyle.marginTop15]}>
          <Pressable hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }} onPress={handleClose}>
            <Icon name="close-outline" height={40} width={40} fill="white" />
          </Pressable>
        </View>
        <View style={[styles.bottomBar, globalStyle.marginBottom15]}>
          <Pressable style={styles.buttonContainer} onPress={handleTakePicture}>
            <View style={styles.buttonInner} />
          </Pressable>
        </View>
      </Camera>
    );
  };

  const renderContent = () => {
    if (permission?.status === PermissionStatus.GRANTED) {
      return renderCamera();
    }
    if (permission?.status === PermissionStatus.UNDETERMINED) {
      return (
        <View
          style={[
            globalStyle.absoluteFull,
            globalStyle.backgroundColorBlack,
            globalStyle.justifyContentCenter,
            globalStyle.alignItemsCenter,
          ]}
        >
          <Pressable
            hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
            style={[styles.closeButton, globalStyle.marginTop15]}
            onPress={handleReset}
          >
            <Icon name="close-outline" height={40} width={40} fill="white" />
          </Pressable>
        </View>
      );
    }
    return (
      <View
        style={[
          globalStyle.absoluteFull,
          globalStyle.backgroundColorBlack,
          globalStyle.justifyContentCenter,
          globalStyle.alignItemsCenter,
        ]}
      >
        <Pressable
          hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
          style={[styles.closeButton, globalStyle.marginTop15]}
          onPress={handleReset}
        >
          <Icon name="close-outline" height={40} width={40} fill="white" />
        </Pressable>
        <View style={[globalStyle.width300]}>
          <Text style={[styles.whiteText, globalStyle.marginBottom20]}>
            {t('common.cameraPermissionsDeniedOpenSettings', {
              defaultValue:
                'Camera permissions are disabled on this device. To enable Camera, go to settings.',
            })}
          </Text>
          <Button onPress={openAppSettings}>
            {t('common.goToSettings', { defaultValue: 'Go to settings' })}
          </Button>
        </View>
      </View>
    );
  };

  return (
    <Modal
      animationIn="slideInUp"
      animationOut="slideOutDown"
      isVisible={visible}
      style={[globalStyle.margin0]}
      useNativeDriver
    >
      <>
        {renderContent()}
        {renderPhoto()}
      </>
    </Modal>
  );
};
