import React, { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { Platform, View } from 'react-native';

import { useMutation, useQuery } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/core';
import { Button, Icon, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Spinner } from '@ui-kitten/components/ui';
import * as ExpoLocation from 'expo-location';
import { PermissionResponse } from 'expo-modules-core/build/PermissionsInterface';
import TimeAgo from 'javascript-time-ago';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { FIND_OR_CREATE_AD_HOC_AVAILABILITY_MUTATION } from '../../apollo/graphql-mutations';
import { QUERY_AVAILABILITY_FIND_ACTIVE_ADHOC_CURRENT_USER } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import AppContext from '../../contexts/AppContext';
import {
  AvailabilitiesForUserDocument,
  Location,
  useDisableAdHocAvailabilityMutation,
} from '../../generated-graphql-types';
import { GLOBAL_CONSTANTS } from '../../globals';
import useDimensions from '../../hooks/useDimensions';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { openAppSettings } from '../../modules/device/device.module';
import { LoadingSpinner } from '../common/loading-spinner.component';
import { createConfirmationPopup } from '../confirmation-popup.component';
import ContentBox from '../content-box.component';
import { createInfoPopup } from '../info-popup.component';
import { MapViewComponent } from '../map-view.component';

const LocationIcon = ({ active }: { active: boolean }) => {
  return active ? (
    <Icon name="navigation-2" fill="#0022FF" style={globalStyle.size20} />
  ) : (
    <Icon name="navigation-2-outline" fill="#bbb" style={globalStyle.size20} />
  );
};

const AtLocationIcon = ({ active }: { active: boolean }) => {
  return active ? (
    <Icon name="at-outline" fill="#0022FF" style={[globalStyle.size20, globalStyle.marginRight5]} />
  ) : (
    <Icon name="at-outline" fill="#fff" style={[globalStyle.size20, globalStyle.marginRight15]} />
  );
};

const CourierAvailabilityBox = ({ refreshing }: { refreshing: boolean }) => {
  const appContext = useContext(AppContext);
  const isBackendReachable = useIsBackendReachable();

  const navigation: any = useNavigation();
  const { t, i18n } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { isLargeDevice } = useDimensions();

  const timeAgo = new TimeAgo(i18n.language);

  const [adHocAvailabilityStatus, setAdHocAvailabilityStatus] = useState<boolean | undefined>(
    undefined,
  );
  const [shouldCreateAddHocAvailability, setShouldCreateAddHocAvailability] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>('');
  const [lastLocationForDevice, setLastLocationForDevice] = useState<
    Partial<Location> | null | undefined
  >();
  const [refetchRetries, setRefetchRetries] = useState<number>(0);
  const [locationForegroundPermission, setLocationForegroundPermission] =
    useState<ExpoLocation.LocationPermissionResponse>();

  const updateLocationPermissions = async (): Promise<boolean> => {
    try {
      if (locationForegroundPermission?.granted) {
        return true;
      }
      const foregroundPermissionsAsync = await ExpoLocation.getForegroundPermissionsAsync();
      setLocationForegroundPermission(foregroundPermissionsAsync);
      return true;
    } catch (e) {
      showMessage({
        message: 'Location permissions denied',
        description:
          'We cannot access your location from this Browser. Please check the permissions.',
        type: 'warning',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
      console.warn('Cannot access location services.', e);
    }
    return false;
  };

  const refetchOnErrorRef = useRef<any>();

  const refetchDataOnError = async () => {
    if (refetchRetries < 5) {
      setRefetchRetries((prev) => prev + 1);
      refetchOnErrorRef.current = setTimeout(async () => {
        await activeAvailabilitiesForUserRefetch();
        refetchOnErrorRef.current = null;
      }, 2000);
    }
  };

  /** GraphQL Queries */
  const {
    data: activeAvailabilitiesForUserData,
    error: activeAvailabilitiesForUserError,
    loading: loadingQuery,
    refetch: activeAvailabilitiesForUserRefetch,
  } = useQuery(QUERY_AVAILABILITY_FIND_ACTIVE_ADHOC_CURRENT_USER, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    onCompleted(data) {
      setIsLoading(false);
      if (data.availabilityFindActiveAdhocCurrentUser?.lastLocationForDevice) {
        setAdHocAvailabilityStatus(true);
        setLastLocationForDevice(data.availabilityFindActiveAdhocCurrentUser.lastLocationForDevice);
      } else {
        setLastLocationForDevice(null);
        setAdHocAvailabilityStatus(false);
      }
      setRefetchRetries(0);
      updateLocationPermissions();
    },
    onError: () => {
      // This solves the issue that sometimes the accessToken expires but is sucessfully refreshed.
      // This query however does not update again but throws an error instead.
      // This mechanims forces a refetch on Error at least 5 times.
      setIsLoading(false);
      refetchDataOnError();
    },
  });
  const [findOrCreateAdHocAvailability] = useMutation(FIND_OR_CREATE_AD_HOC_AVAILABILITY_MUTATION, {
    refetchQueries: [AvailabilitiesForUserDocument],
    onCompleted(data) {
      setIsLoading(false);
      setAdHocAvailabilityStatus(true);
      setLastLocationForDevice(data.findOrCreateAdHocAvailability.lastLocationForDevice);
    },
  });

  const [disableAdHocAvailability] = useDisableAdHocAvailabilityMutation({
    onCompleted() {
      setAdHocAvailabilityStatus(false);
      setIsLoading(false);
    },
  });

  const updateLocationAndAvailability = async () => {
    await appContext.initializeLocationMonitoring();
    setTimeout(() => {
      activeAvailabilitiesForUserRefetch();
    }, 5000);
  };

  useEffect(() => {
    if (refreshing) {
      updateLocationAndAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshing]);

  useEffect(() => {
    const getInitialLocationFromStorage = async () => {
      const res = await AsyncStorage.getItem(
        GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
      );
      if (res) {
        const locationData = JSON.parse(res);
        setAdHocAvailabilityStatus(locationData.adHocAvailabilityStatus);
        setLastLocationForDevice({
          ...locationData.lastLocationForDevice,
          createdAt: DateTime.fromJSDate(new Date(locationData.lastLocationForDevice.createdAt)),
        });
      }
    };

    if (appContext.lastDeviceLocationWithLocation?.location && !lastLocationForDevice) {
      setLastLocationForDevice(appContext.lastDeviceLocationWithLocation.location);
    }
    if (!lastLocationForDevice) {
      getInitialLocationFromStorage();
    }
  }, [appContext.lastDeviceLocationWithLocation?.location, lastLocationForDevice]);

  useEffect(() => {
    return () => {
      AsyncStorage.setItem(
        GLOBAL_CONSTANTS.COURIER_LAST_LOCATION_AVAILABILITY_AND_STATUS,
        lastLocationForDevice?.createdAt
          ? JSON.stringify({
              adHocAvailabilityStatus,
              lastLocationForDevice: {
                ...lastLocationForDevice,
                createdAt: lastLocationForDevice.createdAt.toJSDate(),
              },
            })
          : '',
      );
    };
  }, [adHocAvailabilityStatus, appContext?.lastDeviceLocationWithLocation, lastLocationForDevice]);

  const locationName = useMemo(() => {
    if (appContext.lastDeviceLocationWithLocation?.location?.formattedMinimalAddress) {
      return appContext.lastDeviceLocationWithLocation.location.formattedMinimalAddress;
    }
    if (appContext.lastDeviceLocationWithLocation?.location?.formattedAddress) {
      return appContext.lastDeviceLocationWithLocation.location.formattedAddress;
    }
    if (lastLocationForDevice?.formattedMinimalAddress) {
      return lastLocationForDevice.formattedMinimalAddress;
    }
    if (lastLocationForDevice?.formattedAddress) {
      return lastLocationForDevice.formattedAddress;
    }
    if (
      locationForegroundPermission?.hasOwnProperty('granted') &&
      !locationForegroundPermission?.granted &&
      !locationForegroundPermission?.canAskAgain
    ) {
      return t('Cannot determine location...');
    }
    return t('Determining location...');
  }, [
    appContext.lastDeviceLocationWithLocation?.location?.formattedMinimalAddress,
    appContext.lastDeviceLocationWithLocation?.location?.formattedAddress,
    lastLocationForDevice?.formattedMinimalAddress,
    lastLocationForDevice?.formattedAddress,
    locationForegroundPermission,
    t,
  ]);

  const availabilityButtonData = useMemo(() => {
    let isActive = true;
    let text = t('common.loading', { defaultValue: 'Loading' }) as string;
    if (!adHocAvailabilityStatus) {
      isActive = false;
    }
    if (adHocAvailabilityStatus) {
      text = t('common.setUnavailable', { defaultValue: 'Set unavailable' }) as string;
    }
    if (!adHocAvailabilityStatus) {
      text = t('common.setAvailable', { defaultValue: 'Set available' }) as string;
    }
    return { isActive, text };
  }, [adHocAvailabilityStatus, t]);

  /** Functions */

  const locationPermissionDeniedPopup = async (
    _requestPermissionType: 'background' | 'foreground' = 'foreground',
  ): Promise<boolean> => {
    return createConfirmationPopup({
      onCancelPressed: async () => {
        await updateLocationPermissions();
        return false;
      },
      onOKPressed: async () => {
        if (Platform.OS !== 'web') {
          openAppSettings();
        }
        await updateLocationPermissions();
        return true;
      },
      title: 'Accessing your location is denied',
      message: t(
        'Accessing your location is currently denied.\n\nIn order to use the ad-hoc availability functionality to share your current location with PRIOjet, your connected Agencies, or other connected users, to indicate that you are available at your current location for an on-board courier job, you have to grant permission for this app to access your location.\n\nPress "Go to Settings" to go to the app settings to change the location permissions in order to share your location with PRIOjet, your connected Agencies, or other connected users.\n\nYou can also press "Keep Settings" to to keep the location settings as denied and not use the ad-hoc availability functionality.',
      ),
      okButtonText: 'Go to Settings',
      cancelButtonText: 'Keep Settings',
    });
  };

  const requestLocationFunction = async (
    requestPermissionType: 'background' | 'foreground' = 'foreground',
  ): Promise<PermissionResponse> => {
    if (requestPermissionType === 'background') {
      return ExpoLocation.requestBackgroundPermissionsAsync();
    }
    return ExpoLocation.requestForegroundPermissionsAsync();
  };

  const createAdhocAvailability = async () => {
    await findOrCreateAdHocAvailability({
      variables: { deviceLocationId: appContext.lastDeviceLocationWithLocation?.id },
    });
  };

  useEffect(() => {
    if (
      appContext.lastDeviceLocationWithLocation?.id &&
      appContext.lastDeviceLocationWithLocation?.latitude &&
      shouldCreateAddHocAvailability
    ) {
      setShouldCreateAddHocAvailability(false);
      createAdhocAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appContext.lastDeviceLocationWithLocation?.id,
    appContext.lastDeviceLocationWithLocation?.latitude,
    shouldCreateAddHocAvailability,
  ]);

  const locationForegroundPermissionRequiredPopup = async (): Promise<boolean> => {
    return await createInfoPopup({
      onOKPressed: async () => {
        try {
          const permissions = await requestLocationFunction('foreground');
          if (permissions.granted) {
            await updateLocationPermissions();
            await appContext.initializeLocationMonitoring();

            setShouldCreateAddHocAvailability(true);
          } else {
            return locationPermissionDeniedPopup('foreground');
          }
        } catch (error) {
          setLocationErrorMessage('Location Services are not available.');
          setIsLoading(false);
          setAdHocAvailabilityStatus(false);
          return false;
        }
        // await updateLocationPermissions();
        return true;
      },

      okButtonText: 'Continue',
      title: 'Location permission required',
      message:
        'In order to use the ad-hoc availability functionality to share your location with PRIOjet, your connected Agencies, or other connected users, to indicate that you are available at your location for an on-board courier job, you have to grant permission for this app to access your location.\n\nPress "Continue" to set the permissions.',
    });
  };

  const handleAdHocAvailabilityButtonPressOff = async () => {
    setIsLoading(true);
    await appContext.deInitializeLocationMonitoring();
    await disableAdHocAvailability();
    setIsLoading(false);
  };

  const handleAdHocAvailabilityButtonPressOn = async () => {
    let permissions = locationForegroundPermission;
    if (!permissions) {
      permissions = await ExpoLocation.getForegroundPermissionsAsync();
    }

    if (locationForegroundPermission?.granted !== permissions.granted) {
      setLocationForegroundPermission(permissions);
    }
    if (permissions.granted) {
      setIsLoading(true);
      await appContext.initializeLocationMonitoring();
      setShouldCreateAddHocAvailability(true);
    } else if (!permissions.granted && permissions.canAskAgain) {
      await locationForegroundPermissionRequiredPopup();
    } else {
      let description =
        'Accessing your location is denied. In order to use the ad-hoc availability functionality you have to allow this app to access your location.';
      if (Platform.OS === 'android') {
        description =
          'Accessing your location is denied. In order to use the ad-hoc availability functionality you have to allow this app to access your location. Note that on Android the "Ask again" setting does not work.';
      }
      showMessage({
        message: 'Location permissions denied',
        description,
        type: 'warning',
        autoHide: true,
        hideOnPress: true,
        duration: 5000,
      });
    }
    setIsLoading(false);
  };

  if (activeAvailabilitiesForUserError) {
    return (
      <ContentBox
        Title={
          <View
            style={[
              globalStyle.flexRow,
              globalStyle.alignContentStretch,
              globalStyle.justifyContentBetween,
            ]}
          />
        }
        Content={<LoadingSpinner text="Please wait a few seconds or reload the app" />}
      />
    );
  }

  return (
    <ContentBox
      style={styles.content}
      Title={
        <View style={globalStyle.flexColumn}>
          <View style={[globalStyle.flexRow, globalStyle.justifyContentBetween]}>
            <View style={[globalStyle.flexRow, globalStyle.marginBottom10]}>
              <View
                style={[
                  styles.dot,
                  adHocAvailabilityStatus === undefined && styles.dotYellow,
                  adHocAvailabilityStatus === true && styles.dotGreen,
                  adHocAvailabilityStatus === false && styles.dotRed,
                ]}
              />
              <Text
                selectable={true}
                style={[globalStyle.paddingLeft20, globalStyle.textTransformUppercase]}
              >
                {adHocAvailabilityStatus
                  ? t('common.available', { defaultValue: 'available' }) + ''
                  : t('common.unavailable', { defaultValue: 'unavailable' }) + ''}
              </Text>
            </View>
            <View style={globalStyle.flexRow}>
              {!!lastLocationForDevice?.createdAt && (
                <Text
                  selectable={true}
                  style={[globalStyle.paddingRight10, globalStyle.fontLatoLight]}
                >
                  {timeAgo.format(lastLocationForDevice.createdAt?.toJSDate()) as string}
                </Text>
              )}
              <LocationIcon active={locationForegroundPermission?.granted === true} />
            </View>
          </View>
          <View
            style={[
              globalStyle.flexRow,
              globalStyle.alignItemsStart,
              globalStyle.alignContentEnd,
              globalStyle.alignSelfStart,
            ]}
          >
            <AtLocationIcon
              active={
                !!lastLocationForDevice?.createdAt &&
                lastLocationForDevice.createdAt?.diffNow('minutes').minutes < 5
              }
            />
            <Text selectable={true} style={globalStyle.fontLatoBold}>
              {locationName}
            </Text>
          </View>
        </View>
      }
      Content={
        <View
          style={[
            globalStyle.flex1,
            globalStyle.justifyContentCenter,
            globalStyle.alignItemsCenter,
          ]}
        >
          {(!!appContext.location?.coords?.latitude ||
            !!lastLocationForDevice?.locationGeometry?.location?.lat) && (
            <View style={styles.map}>
              <MapViewComponent
                width="100%"
                height={100}
                latitude={
                  (appContext.location?.coords?.latitude ||
                    lastLocationForDevice?.locationGeometry?.location?.lat) as number
                }
                longitude={
                  (appContext.location?.coords?.longitude ||
                    lastLocationForDevice?.locationGeometry?.location?.lng) as number
                }
                showMarker={false}
                showCircle={true}
                mapCircleProps={{
                  radius: 10000,
                  strokeWidth: 3,
                  strokeColor: '#0000ff',
                  fillColor: undefined,
                }}
                markerTitle={locationName || undefined}
                latitudeDelta={0.4}
                longitudeDelta={0.4}
              />
            </View>
          )}
          <View
            style={[
              styles.buttonsContainer,
              isLargeDevice ? styles.buttonsRow : styles.buttonsColumn,
            ]}
          >
            <Button
              appearance="filled"
              onPress={() => {
                if (adHocAvailabilityStatus) {
                  handleAdHocAvailabilityButtonPressOff();
                } else {
                  handleAdHocAvailabilityButtonPressOn();
                }
              }}
              style={[
                styles.flex1,
                isLargeDevice && styles.marginRight3,
                !isLargeDevice && styles.marginBottom10,
              ]}
              accessoryLeft={() =>
                (loadingQuery && !activeAvailabilitiesForUserData) || isLoading ? (
                  <Spinner size="tiny" status="basic" />
                ) : (
                  <></>
                )
              }
            >
              {availabilityButtonData.text}
            </Button>
            <Button
              appearance="filled"
              onPress={() => {
                if (!isLoading) {
                  navigation.navigate('CourierAvailabilitiesNavigator', {
                    screen: 'CourierAvailabilitiesScreen',
                  });
                }
              }}
              style={[styles.flex1, isLargeDevice && styles.marginLeft3]}
            >
              {t('common.setAvailabilityDates', {
                defaultValue: 'Set availability dates',
              }) + ''}
            </Button>

            {!!locationErrorMessage && (
              <Text selectable={true}>Location error: {locationErrorMessage + ''}</Text>
            )}
          </View>
        </View>
      }
    />
  );
};

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },
  flex1: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  buttonsContainer: {
    flex: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  buttonsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  buttonsColumn: {
    flexDirection: 'column',
  },
  marginLeft3: {
    marginLeft: 3,
  },
  marginRight3: {
    marginRight: 3,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  dot: {
    height: 18,
    width: 18,
    borderRadius: 9,
  },
  dotGreen: {
    backgroundColor: 'green',
  },
  dotRed: {
    backgroundColor: 'red',
  },
  dotYellow: {
    backgroundColor: '#F2C301',
  },
  map: {
    height: 100,
    width: '100%',
    borderRadius: 6,
    overflow: 'hidden',
  },
});

export { CourierAvailabilityBox };
