import React from 'react';
import { FlatList, RefreshControl, View } from 'react-native';

import { StyleService, Text, useTheme } from '@ui-kitten/components';

import { globalStyle } from '../../../../common/style';
import { OrganizationInvitation } from '../../../../generated-graphql-types';
import { OrganizationInvitationCardItem } from '../OrganizationInvitationCard';

export const OrganizationInvitationList = ({
  type,
  organizationInvitations,
  refetch,
  loading,
}: {
  type: 'reserved' | 'used' | 'voided';
  organizationInvitations: Partial<OrganizationInvitation>[];
  refetch: () => Promise<any>;
  loading: boolean;
}) => {
  const theme = useTheme();

  const scrollEnabled = true;
  const scrollToOverflowEnabled = true;

  const ListEmptyComponent = () => (
    <View style={styles.noResultsView}>
      <Text selectable={true}>No {type} Organization Invitations found.</Text>
    </View>
  );

  return (
    <FlatList
      refreshControl={
        <RefreshControl
          refreshing={loading}
          onRefresh={refetch}
          title="Loading Organization Invitations..."
          titleColor={theme['text-basic-color']}
          tintColor={theme['text-basic-color']}
          colors={[theme['text-basic-color']]}
        />
      }
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      ListEmptyComponent={<ListEmptyComponent />}
      scrollEnabled={scrollEnabled}
      scrollToOverflowEnabled={scrollToOverflowEnabled}
      style={globalStyle.width100Percent}
      data={organizationInvitations}
      renderItem={({ item }: { item: Partial<OrganizationInvitation> }) => (
        <OrganizationInvitationCardItem organizationInvitation={item} />
      )}
    />
  );
};

const styles = StyleService.create({
  noResultsView: {
    padding: 20,
  },
});
