import React, { useContext } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Layout, StyleService } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import {
  USER_IDENTITY_DOCUMENTS_QUERY,
  USER_QUERY,
  USER_TRAVEL_DOCUMENTS_QUERY,
} from '../../apollo/graphql-queries';
import CommonUserDocumentsComponent from '../../components/common.user-documents.component';
import { CourierProfileTopNavigationBar } from '../../components/courier/courier-profile-top-navigation-bar.component';
import { AddIcon } from '../../components/icons.component';
import { SafeAreaLayout } from '../../components/safe-area-layout.component';
import AppUserContext from '../../contexts/AppUserContext';
import { User } from '../../graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { UserDocument, UserDocument2 } from '../../types';

const AddNewDocument = (navigation: any): React.ReactElement => {
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const { t } = useTranslation();

  const { data: userIdentityDocumentsData } = useQuery<{
    userIdentityDocuments: UserDocument[];
  }>(USER_IDENTITY_DOCUMENTS_QUERY, {
    variables: { userId: appUserContext.currentUserContext?.user?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const { data: userQueryData } = useQuery<User>(USER_QUERY, {
    variables: {
      id: appUserContext.currentUserContext?.user?.id,
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  return (
    <>
      <Button
        accessoryLeft={AddIcon}
        onPress={() => {
          navigation.navigate('CommonUserDocumentDetailScreen', {
            targetDocument: 'UserTravelDocument',
            readOnly: false,
            title: 'Add Travel Document',
            userIdentityDocuments: userIdentityDocumentsData
              ? userIdentityDocumentsData.userIdentityDocuments
              : [],
            user: userQueryData?.user || undefined,
          });
        }}
        appearance="ghost"
      >
        {t('document.addDocument', { defaultValue: 'Add document' }) as string}
      </Button>
    </>
  );
};

export const CourierUserTravelPermitsScreen = ({ navigation }: { navigation: any }) => {
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = React.useContext(AppUserContext);
  const { t } = useTranslation();

  const { data: userTravelDocumentsData } = useQuery<{
    userTravelDocuments: UserDocument2[];
  }>(USER_TRAVEL_DOCUMENTS_QUERY, {
    variables: { userId: appUserContext.currentUserContext?.user?.id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  return (
    <SafeAreaLayout style={styles.container}>
      <CourierProfileTopNavigationBar
        title={t('common.visaTravelAuthorization', {
          defaultValue: 'Visa / Travel Authorization',
        })}
        navigation={navigation}
      />
      <Layout style={styles.container}>
        {AddNewDocument(navigation)}
        <CommonUserDocumentsComponent
          screenName="CourierUserTravelPermitsScreen"
          targetDocument="UserTravelDocument"
          userDocumentList={userTravelDocumentsData?.userTravelDocuments}
        />
      </Layout>
    </SafeAreaLayout>
  );
};

const styles = StyleService.create({
  container: {
    flex: 1,
  },
});
