import React from 'react';
import { View, ViewStyle } from 'react-native';

import {
  Datepicker,
  DatepickerProps,
  IconProps,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { EvaInputSize } from '@ui-kitten/components/devsupport';
import { PopoverPlacement } from '@ui-kitten/components/ui/popover/type';

import { globalStyle } from '../../../common/style';
import { ITranslate } from '../../../types';
import { CalendarIcon } from '../../icons.component';

const FormDatepicker = (
  props: DatepickerProps & {
    label?: ITranslate | string;
    caption?: ITranslate | string;
    error?: boolean;
    errorMessage?: string;
    isMandatory?: boolean;
    language?: string;
    containerStyle?: ViewStyle;
    placement?: PopoverPlacement | string;
    size?: EvaInputSize;
  },
) => {
  const styles = useStyleSheet(themedStyles);

  const { label, isMandatory, caption, ..._props } = props;

  const renderPlaceholder = (placeholderProps: any) => {
    <Text {...placeholderProps} style={[globalStyle.flex1]} />;
  };

  return (
    <View style={[styles.container, props?.containerStyle]}>
      {!!label && (
        <View style={styles.labelContainer}>
          <Text style={styles.label}>
            <>
              {label as string}
              {!!isMandatory && <Text style={styles.mandatoryStar}>*</Text>}
            </>
          </Text>
        </View>
      )}
      <Datepicker
        {..._props}
        placeholder={renderPlaceholder as any}
        style={styles.placeholder}
        controlStyle={[
          styles.datepicker,
          (!!props.errorMessage || !!props.error) && styles.datepickerError,
        ]}
      />
      {!!caption && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.caption}>
            {caption as string}
          </Text>
        </View>
      )}
      {!!props.errorMessage && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.errorMessageText}>
            {props.errorMessage}
          </Text>
        </View>
      )}
    </View>
  );
};

FormDatepicker.defaultProps = {
  language: 'en',
  isMandatory: false,
  accessoryRight: (props: IconProps) => <CalendarIcon {...props} />,
};

export { FormDatepicker };

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
  },
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
    paddingBottom: 5,
  },
  datepicker: {
    flex: 1,
    marginRight: 1,
  },
  datepickerError: {
    borderColor: 'color-danger-500',
  },
  errorMessageText: {
    color: 'color-danger-500',
  },
  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  placeholder: {
    color: 'text-basic-color',
  },
});
