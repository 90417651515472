import React, { useContext, useMemo } from 'react';

import { DateTime } from 'luxon';

import { Props } from './types';
import { AgencyEmployeeInviteButton } from '../../../components/common/Invitation/AgencyEmployeeInviteButton';
import { PhoneBook } from '../../../components/common/PhoneBook';
import { ImportedContactsType } from '../../../components/common/PhoneBook/types';
import AppUserContext from '../../../contexts/AppUserContext';
import { OrganizationUserRoleType } from '../../../generated-graphql-types';

export const AgencyOrganizationUserInvitationPhoneContactsScreen: React.FC<Props> = ({
  currentOrganizationUserCount,
  organizationInvitations,
  isLoading,
  refetch,
}) => {
  const appUserContext = useContext(AppUserContext);

  const importedContacts = useMemo(() => {
    if (organizationInvitations && organizationInvitations.length > 0) {
      const _importedContacts: ImportedContactsType = {};
      for (const organizationInvitation of organizationInvitations) {
        if (
          organizationInvitation.organizationContact &&
          organizationInvitation.organizationContact?.phone !== undefined
        ) {
          if (organizationInvitation.organizationContact?.userContactsForUser?.length) {
            organizationInvitation.organizationContact?.userContactsForUser.forEach(
              (userContact) => {
                const key = `${organizationInvitation.organizationContact?.phone}_${organizationInvitation.organizationContact?.email}`;
                _importedContacts[key] = {
                  expoContactId: userContact.expoContactId,
                  phone: userContact.phone,
                  email: userContact.email ? (userContact.email as string) : undefined,
                  organizationInvitation: {
                    id: organizationInvitation.id as string,
                    createdAt: organizationInvitation.createdAt as DateTime,
                  },
                };
              },
            );
          } else {
            const key = `${organizationInvitation.organizationContact?.phone}_${organizationInvitation.organizationContact?.email}`;
            _importedContacts[key] = {
              expoContactId: undefined,
              phone: organizationInvitation.organizationContact?.phone as string,
              email: organizationInvitation.organizationContact?.email as string,
              organizationInvitation: {
                id: organizationInvitation.id as string,
                createdAt: organizationInvitation.createdAt as DateTime,
              },
            };
          }
        }
      }
      return _importedContacts;
    }
  }, [organizationInvitations]);

  const renderInviteButton = ({ item }: any) => {
    if (
      appUserContext.currentUserContext?.roleType &&
      [OrganizationUserRoleType.OWNER, OrganizationUserRoleType.ADMIN].includes(
        appUserContext.currentUserContext?.roleType,
      )
    ) {
      return (
        <AgencyEmployeeInviteButton
          currentOrganizationUserCount={currentOrganizationUserCount}
          item={item}
          refetch={refetch}
        />
      );
    }
    return <></>;
  };

  return (
    <PhoneBook
      importedContacts={importedContacts}
      renderInviteButton={renderInviteButton}
      isLoading={isLoading}
      refetch={refetch}
    />
  );
};
