import React from 'react';
import { View } from 'react-native';

import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation, useRoute } from '@react-navigation/native';
import { StyleService } from '@ui-kitten/components';
import { Tab, TabBar, Text } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { globalStyle } from '../../../common/style';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import { AgencyCourierJobOverviewScreen } from '../../common/AgencyCourierJobOverviewScreen';
import { CourierCourierJobJourneyScreen } from '../../common/CommonJobJourneyScreen';

const { Navigator, Screen } = createMaterialTopTabNavigator();

export const AgencyCourierJobScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const route: any = useRoute();
  const navigation: any = useNavigation();

  const {
    activeTab,
    agencyJob,
    agencyJobError,
    handleUpdateJob,
    handleBackPress,
    loading,
    previousScreenName,
    handleRefresh,
    refreshing,
    setActiveTab,
  } = useHook();

  const renderTopBar = ({ navigation: _navigation, state }: { navigation: any; state: any }) => (
    <TabBar
      selectedIndex={state.index}
      onSelect={(index) => {
        _navigation.navigate(state.routeNames[index]);
        setActiveTab(state.routeNames[index]);
      }}
      style={themedStyles.height50}
    >
      <Tab
        title={t('jobs.overview', { defaultValue: 'Overview' }) as string}
        key="agencyCourierJobOverviewTab"
      />
      <Tab
        title={t('jobs.journey', { defaultValue: 'Journey' }) as string}
        key="agencyCourierJobJourneyTab"
      />
    </TabBar>
  );

  const renderContent = () => {
    if (agencyJobError) {
      return (
        <>
          <TopNavigationWithBackButton
            title={t('common.viewJob', { defaultValue: 'VIEW JOB' })}
            navigation={navigation}
            onPressLeft={handleBackPress}
          />
          <View style={[globalStyle.padding20, themedStyles.paddingTop50]}>
            <Text style={globalStyle.textAlignCenter}>
              Courier job not found or you do not have permissions to view.
            </Text>
            <Text style={[globalStyle.textAlignCenter, globalStyle.paddingTop10]}>
              Code:{' '}
              {(agencyJobError?.graphQLErrors?.[0].extensions?.internalCode as string) ||
                'Unknown internal code'}
            </Text>
          </View>
        </>
      );
    }
    if (loading && !agencyJob) {
      return <LoadingSpinner />;
    }
    return (
      <Navigator
        tabBar={(props: any) => renderTopBar(props)}
        initialRouteName={
          previousScreenName === 'AgencyCourierJobsScreen'
            ? 'AgencyCourierJobDetailsScreen'
            : 'CourierCourierJobOverviewScreen'
        }
        screenOptions={{ swipeEnabled: false }}
      >
        <Screen name="AgencyCourierJobOverviewScreen" initialParams={{ ...(route.params || {}) }}>
          {() => (
            <AgencyCourierJobOverviewScreen
              agencyJob={agencyJob}
              handleUpdateJob={handleUpdateJob}
              refreshing={refreshing}
              onRefresh={handleRefresh}
              previousScreenName="AgencyJobsScreen,AgencyCourierJobOverviewScreen"
              previousTabScreenName="AgencyCancelledJobsScreen"
            />
          )}
        </Screen>
        <Screen name="AgencyCourierJobJourneyScreen" initialParams={{ ...(route.params || {}) }}>
          {(props: any) => (
            <CourierCourierJobJourneyScreen
              {...props}
              active={activeTab === 'AgencyCourierJobJourneyScreen'}
              agencyJob={agencyJob}
              job={agencyJob?.job}
            />
          )}
        </Screen>
      </Navigator>
    );
  };

  return (
    <>
      {!!agencyJob && (
        <TopNavigationWithBackButton
          title={t(`${agencyJob?.job?.priojetJobIdentifier} / ${agencyJob?.externalJobReference}`)}
          navigation={navigation}
          onPressLeft={handleBackPress}
        />
      )}
      {renderContent()}
    </>
  );
};

const themedStyles = StyleService.create({
  flex1: {
    flex: 1,
  },
  paddingTop50: {
    paddingTop: 50,
  },
  height50: {
    height: 50,
  },
});
