import React from 'react';
import { ScrollView, StyleProp, TextInputProps, View, ViewStyle } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';

import { ReverseGeocodeLocationType } from '../../../lib/google-maps-reverse-geocode-location-api';
import { ITranslate } from '../../../types';
import {
  GooglePlacesAutocompleteLocation,
  ThemedGooglePlacesAutocomplete,
} from '../google-places-autocomplete.component';

const FormLocationAutocomplete = (props: {
  containerStyle?: StyleProp<ViewStyle>;
  label: ITranslate | string;
  caption?: ITranslate | string;
  isMandatory?: boolean;
  placeholderText?: string;
  onChangeText?: (nextValue: string) => void;
  onPress?: (
    googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
  ) => void;
  language?: string;
  currentLocationText?: string;
  reverseGeocodeLocationTypes?: ReverseGeocodeLocationType[];
  isCurrentLocationEnabled?: boolean;
  textInputProps?: TextInputProps;
  errorMessage?: string;
}) => {
  const styles = useStyleSheet(themedStyles);

  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      style={[styles.marginTop20, props.containerStyle]}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      <View style={styles.labelContainer}>
        <Text selectable={true} style={styles.label}>
          <>
            {props.label as string}
            {!!props.isMandatory && (
              <Text selectable={true} style={styles.mandatoryStar}>
                *
              </Text>
            )}
          </>
        </Text>
      </View>
      <ThemedGooglePlacesAutocomplete
        isCurrentLocationEnabled={props.isCurrentLocationEnabled}
        onChangeText={props.onChangeText}
        onPress={props.onPress}
        language="en"
        placeholderText={props.placeholderText}
        currentLocationText={props.currentLocationText}
        reverseGeocodeLocationTypes={props.reverseGeocodeLocationTypes}
        textInputProps={props.textInputProps}
        errorMessage={props.errorMessage}
      />
      {!!props.caption && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.caption}>
            {props.caption as string}
          </Text>
        </View>
      )}
      {!!props.errorMessage && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.errorMessageText}>
            {props.errorMessage}
          </Text>
        </View>
      )}
    </ScrollView>
  );
};

FormLocationAutocomplete.defaultProps = {
  language: 'en',
  currentLocationText: 'Current location',
  isMandatory: false,
  isCurrentLocationEnabled: true,
};

export { FormLocationAutocomplete };

const themedStyles = StyleService.create({
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  input: {
    padding: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  errorMessageText: {
    color: 'color-danger-500',
    paddingBottom: 5,
  },
});
