import React, { useEffect, useState } from 'react';

import MapView from 'react-native-web-maps';

import { DEFAULT_MAP_LOCATION } from '../../constants/Map';

export type MapViewCoordinate = { latitude: number; longitude: number };

export type MapViewWithMarkerProps = {
  height: number;
  initialRegion?: MapViewCoordinate & { latitudeDelta?: number; longitudeDelta?: number };
  mapViewCoordinates: MapViewCoordinate[];
  mapViewRegion: MapViewCoordinate | undefined;
  pointerEvents?: 'box-none' | 'none' | 'box-only' | 'auto' | undefined;
};

const MapViewWithMarker = ({
  height,
  initialRegion,
  mapViewCoordinates,
  mapViewRegion: mapViewRegionProp,
}: MapViewWithMarkerProps) => {
  const [mapViewRegion, setMapViewRegion] = useState<MapViewCoordinate | undefined>(
    mapViewRegionProp,
  );

  useEffect(() => {
    if (!mapViewRegion && !mapViewRegionProp && mapViewCoordinates?.length) {
      setMapViewRegion(mapViewCoordinates[0]);
    } else if (!mapViewRegion && mapViewRegionProp) {
      setMapViewRegion(mapViewRegionProp);
    } else if (
      mapViewRegion &&
      mapViewRegionProp &&
      (mapViewRegion.latitude !== mapViewRegionProp.latitude ||
        mapViewRegion.longitude !== mapViewRegionProp.longitude)
    ) {
      setMapViewRegion(mapViewRegionProp);
    }
  }, [mapViewRegion, mapViewRegionProp, mapViewCoordinates]);

  return (
    <MapView
      style={{ height, width: '100%' }}
      initialRegion={
        initialRegion
          ? {
              ...initialRegion,
              latitudeDelta: initialRegion.latitudeDelta || 100.0,
              longitudeDelta: initialRegion.longitudeDelta || 300.0,
            }
          : {
              latitude: DEFAULT_MAP_LOCATION.latitude,
              longitude: DEFAULT_MAP_LOCATION.longitude,
              latitudeDelta: 100.0,
              longitudeDelta: 300.0,
            }
      }
      region={{
        ...(mapViewRegion || {
          latitude: DEFAULT_MAP_LOCATION.latitude,
          longitude: DEFAULT_MAP_LOCATION.longitude,
        }),
        latitudeDelta: 6.0,
        longitudeDelta: 6.0,
      }}
      provider="google"
    >
      {mapViewCoordinates.map((mapViewCoordinate) => (
        <MapView.Marker
          coordinate={mapViewCoordinate}
          key={mapViewCoordinate.latitude + mapViewCoordinate.longitude}
        />
      ))}
    </MapView>
  );
};

const compareMapViewWithMarkerProps = (
  prev: MapViewWithMarkerProps,
  next: MapViewWithMarkerProps,
) => {
  if (next.height !== prev.height) {
    return false;
  }

  if (
    prev.mapViewRegion?.latitude !== next.mapViewRegion?.latitude ||
    prev.mapViewRegion?.longitude !== next.mapViewRegion?.longitude
  ) {
    return false;
  }
  if (prev.mapViewCoordinates?.length !== next.mapViewCoordinates?.length) {
    return false;
  }
  if (JSON.stringify(prev.mapViewCoordinates) !== JSON.stringify(next.mapViewCoordinates)) {
    return false;
  }

  return true;
};

export const MemoizedMapViewWithMarker = React.memo(
  MapViewWithMarker,
  compareMapViewWithMarkerProps,
);
