import { useMemo, useState, useContext } from 'react';

import { useLazyQuery } from '@apollo/client';
import * as ExpoDocumentPicker from 'expo-document-picker';
import { useTranslation } from 'react-i18next';
import { showMessage, MessageType } from 'react-native-flash-message';

import { ImportContact, UseHookProps } from './types';
import { QUERY_ORGANIZATION_INVITATION_UPLOAD_CSV } from '../../../../apollo/graphql-queries';
import { ConfirmModalContext } from '../../../../components/common/modal/ConfirmModal';
import { OrganizationInvitationUploadCsvContact } from '../../../../generated-graphql-types';
import useDimensions from '../../../../hooks/useDimensions';

const initialLabels = {
  firstNameField: 'First name',
  lastNameField: 'Last name',
  emailField: 'Email address',
  phoneNumberField: 'Phone number',
  // firstNameField: 'Given Name',
  // lastNameField: 'Family Name',
  // emailField: 'E-mail 1 - Value',
  // phoneNumberField: 'Phone 1 - Value',
};

export const useHook = ({ refetch }: UseHookProps) => {
  const { t } = useTranslation();
  const { hideConfirmModal, showConfirmModal } = useContext(ConfirmModalContext);
  const { dimensions } = useDimensions();

  const [organizationInvitationUploadCSV, { loading }] = useLazyQuery(
    QUERY_ORGANIZATION_INVITATION_UPLOAD_CSV,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [contactsToInvite, setContactsToInvite] = useState<any>();
  const [dataCSV, setDataCSV] = useState<any>();
  const [labels, setLabels] = useState(initialLabels);
  const [searchText, setSearchText] = useState('');
  const [organizationInvitationsUsed, setOrganizationInvitationsUsed] = useState<any>();
  const [organizationInvitationsNotUsed, setOrganizationInvitationsNotUsed] = useState<any>();
  const [selectedDocument, setSelectedDocument] =
    useState<ExpoDocumentPicker.DocumentPickerAsset | null>(null);
  const [selectedInvites, setSelectedInvites] = useState<number[]>([]);
  const [step, setStep] = useState(1);

  const handleUploadFilePress = async () => {
    try {
      const res = await organizationInvitationUploadCSV({
        variables: { data: { ...labels, file: selectedDocument?.uri } },
      });
      if (res?.data?.organizationInvitationUploadCSV?.contacts?.length) {
        setDataCSV(
          res.data.organizationInvitationUploadCSV.contacts.map(
            (item: OrganizationInvitationUploadCsvContact, index: number) => ({ ...item, index }),
          ),
        );
        setOrganizationInvitationsUsed(
          res.data.organizationInvitationUploadCSV.organizationInvitationsUsed,
        );
        setOrganizationInvitationsNotUsed(
          res.data.organizationInvitationUploadCSV.organizationInvitationsNotUsed,
        );
        setStep(3);
      } else {
        showMessage({
          message: t('common.importContactsNothingFound', { defaultValue: 'No contacts found' }),
          description: t('common.importContactsNothingFoundText', {
            defaultValue:
              'There were no contacts found in selected file. Could be that fields were not properly mapped or something else went wrong. Please try again or reach out to support@priojet.com',
          }),
          type: 'warning',
          duration: 8000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    } catch {
      showMessage({
        message: t('common.importContactsErrorUploadingFile', {
          defaultValue: 'Error uploading file',
        }),
        description: t('common.importContactsErrorUploadingFileText', {
          defaultValue:
            'There was some error uploading your CSV file. Could be that fields were not properly mapped, file is corrupted or something else went wrong. Please try again or reach out to support@priojet.com',
        }),
        type: 'warning',
        duration: 8000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const handleSelectFilePress = async () => {
    const result = await ExpoDocumentPicker.getDocumentAsync({
      type: 'text/csv',
      multiple: false,
    });
    if (!result.canceled) {
      const asset = result.assets[0];
      setStep(2);
      setSelectedDocument(asset);
    }
  };

  const handleResetDocument = () => {
    setDataCSV([]);
    setLabels(initialLabels);
    setSearchText('');
    setSelectedDocument(null);
    setSelectedInvites([]);
    setStep(1);
  };

  const handleSearchTextChange = (value: string) => {
    setSearchText(value);
  };

  const handleLabelChange = (field: string) => (value: string) => {
    setLabels((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckboxSelectAll = () => {
    if (isAllSelected) {
      setSelectedInvites([]);
    } else {
      setSelectedInvites(
        data
          .filter((item: ImportContact) => {
            if (
              item.firstNameError ||
              item.lastNameError ||
              item.emailError ||
              item.phoneNumberError ||
              item.status !== 'new'
            ) {
              return false;
            }
            return true;
          })
          .map((item: ImportContact) => item.index),
      );
    }
  };

  const handleCheckboxSelect = (item: ImportContact) => () => {
    if (
      item.firstNameError ||
      item.lastNameError ||
      item.emailError ||
      item.phoneNumberError ||
      item.status !== 'new'
    ) {
      return;
    }
    setSelectedInvites((prev) => {
      const state = [...prev];
      if (state.includes(item.index)) {
        return state.filter((index) => index !== item.index);
      }
      return [...state, item.index];
    });
  };

  const checkContactErrors = (contact: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }) => {
    const { email, firstName, lastName, phoneNumber } = contact;
    let status = 'new';
    if (email) {
      if (organizationInvitationsUsed?.length && organizationInvitationsUsed.includes(email)) {
        status = 'alreadyConnected';
      } else if (
        organizationInvitationsNotUsed?.length &&
        organizationInvitationsNotUsed.includes(email)
      ) {
        status = 'alreadyInvited';
      }
    }

    const regexPhone = /[^+^\d]+/gi; // Replacing all non numeric characters except for +
    const regexEmail = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/gi;
    const phoneFormatted = phoneNumber.replaceAll(regexPhone, '');

    const firstNameError = !firstName ? 'firstNameTooShort' : '';
    const lastNameError = !lastName ? 'lastNameTooShort' : '';
    const emailError = !email || !regexEmail.test(email) ? 'invalidEmail' : '';
    const phoneNumberError =
      !phoneFormatted.startsWith('+') || phoneFormatted.length < 8 ? 'invalidPhoneNumber' : '';
    if (firstNameError || lastNameError || emailError || phoneNumberError) {
      status = 'invalidData';
    }
    let fullName = firstName || '';
    if (fullName && lastName) {
      fullName += ` ${lastName}`;
    }
    const contactNew = {
      ...contact,
      fullName,
      phoneNumber: phoneFormatted,
      status,
      firstNameError,
      lastNameError,
      phoneNumberError,
      emailError,
    };
    return contactNew;
  };

  const handleFieldTextChange = (index: number, field: string) => (val: string) => {
    setDataCSV((prev: any[]) => {
      const newData = [...prev];
      if (index !== -1) {
        if (newData[index]) {
          newData[index] = checkContactErrors({ ...newData[index], [field]: val });
          if (newData[index].status !== 'new' && selectedInvites.includes(index)) {
            setSelectedInvites((state) => state.filter((selectedIndex) => selectedIndex !== index));
          }
        }
      }
      return newData;
    });
  };

  const handleStepPress = (newStep: number) => () => {
    if (newStep < step) {
      if (newStep === 1) {
        setDataCSV([]);
        setSearchText('');
        setLabels(initialLabels);
        setSelectedDocument(null);
        setSelectedInvites([]);
        setStep(1);
      } else if (newStep === 2) {
        setDataCSV([]);
        setSearchText('');
        setSelectedInvites([]);
        setStep(2);
      }
    }
  };

  const handleImportContactsPress = () => {
    const selectedContacts = dataCSV
      .filter((item: ImportContact) => selectedInvites.includes(item.index))
      .map((item: ImportContact) => ({
        index: item.index,
        email: item.email,
        firstName: item.firstName,
        lastName: item.lastName,
        phoneNumber: item.phoneNumber,
      }));
    if (selectedContacts.length) {
      showConfirmModal({
        confirmButtonStatus: 'primary',
        confirmButtonAppearance: 'filled',
        confirmButtonText: t('common.import', { defaultValue: 'Import' }),
        cancelButtonText: t('common.cancel', { defaultValue: 'Cancel' }),
        title: t('common.importContactsConfirmationPopupTitle', {
          defaultValue: 'Import contacts',
        }),
        text: t('common.importContactConfirmationPopupText', {
          defaultValue:
            'Do you really want to import {{count}} out of total {{totalCount}} from selected CSV?',
          count: selectedContacts.length,
          totalCount: dataCSV.length,
        }),
        onConfirmPress: async () => {
          hideConfirmModal();
          setTimeout(() => {
            setContactsToInvite(selectedContacts);
          }, 300);
          return true;
        },
        visible: true,
      });
    }
  };

  const handleResetImportContacts = (
    importData:
      | {
          contactsFailed: ImportContact[];
          contactsImported: ImportContact[];
          contactsSkipped: ImportContact[];
        }
      | undefined,
  ) => {
    setContactsToInvite(undefined);
    setSelectedInvites([]);

    if (importData && (importData.contactsImported?.length || importData.contactsFailed?.length)) {
      if (refetch) {
        refetch();
      }
      let message = '';
      let description = '';
      let type: MessageType = 'success';
      const { contactsFailed, contactsImported, contactsSkipped } = importData;
      setDataCSV((prev: ImportContact[]) => {
        const newData = [...prev];
        if (contactsFailed.length) {
          contactsFailed.forEach((contact) => {
            newData[contact.index] = contact;
          });
        }
        if (contactsImported.length) {
          contactsImported.forEach((contact) => {
            newData[contact.index] = contact;
          });
        }
        return newData;
      });
      if (contactsImported.length) {
        if (contactsFailed.length) {
          message = t('common.importSuccessPartial', {
            defaultValue: 'Import partially successful',
          });
          type = 'warning';
          if (contactsImported.length === 1) {
            description = t('common.importSuccessWithFailedWithCountOne', {
              defaultValue:
                'You have successfully imported {{countSuccess}} contact, but {{countFailed}} failed{{skippedText}}. You can edit your contacts and try again.',
              countFailed: contactsFailed.length,
              countSuccess: contactsImported.length,
              skippedText: contactsSkipped.length
                ? t('common.andImportContactSkippedCount', {
                    defaultValue: ', and skipped {{skipped}}',
                    skipped: contactsSkipped.length,
                  })
                : '',
            });
          } else {
            description = t('common.importSuccessWithFailedWithCountMultiple', {
              defaultValue:
                'You have successfully imported {{countSuccess}} contacts, but {{countFailed}} failed{{skippedText}}. You can edit your contacts and try again.',
              countFailed: contactsFailed.length,
              countSuccess: contactsImported.length,
              skippedText: contactsSkipped.length
                ? t('common.andImportContactSkippedCount', {
                    defaultValue: ', and skipped {{skipped}}',
                    skipped: contactsSkipped.length,
                  })
                : '',
            });
          }
        } else {
          message = t('common.importFailed', { defaultValue: 'Import successful' });
          if (contactsImported.length === 1) {
            description = t('common.importSuccessWithCountOne', {
              defaultValue:
                'You have successfully imported {{countSuccess}} contact{{skippedText}}.',
              countSuccess: contactsImported.length,
              skippedText: contactsSkipped.length
                ? t('common.andImportContactSkippedCount', {
                    defaultValue: ', and skipped {{skipped}}',
                    skipped: contactsSkipped.length,
                  })
                : '',
            });
          } else {
            description = t('common.importSuccessWithCountMultiple', {
              defaultValue:
                'You have successfully imported {{countSuccess}} contacts{{skippedText}}.',
              countSuccess: contactsImported.length,
              skippedText: contactsSkipped.length
                ? t('common.andImportContactSkippedCount', {
                    defaultValue: ', and skipped {{skipped}}',
                    skipped: contactsSkipped.length,
                  })
                : '',
            });
          }
        }
      } else if (contactsFailed.length) {
        message = t('common.importFailed', { defaultValue: 'Import failed' });
        type = 'danger';
        if (contactsImported.length === 1) {
          description = t('common.importFailedWithCountOne', {
            defaultValue:
              'Import of {{countFailed}} contact failed. You can edit your contacts and try again.',
            countFailed: contactsFailed.length,
            countSuccess: contactsImported.length,
          });
        } else {
          description = t('common.importFailedWithCountMultiple', {
            defaultValue:
              'Import of {{countFailed}} contacts failed. You can edit your contacts and try again.',
            countFailed: contactsFailed.length,
            countSuccess: contactsImported.length,
          });
        }
      }
      showMessage({
        message,
        description,
        type,
        duration: 8000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const isAllSelected = useMemo(() => {
    if (dataCSV?.length) {
      return (
        dataCSV.every(
          (item: ImportContact) => selectedInvites.includes(item.index) || item.status !== 'new',
        ) && dataCSV.some((item: ImportContact) => item.status === 'new')
      );
    }
    return false;
  }, [dataCSV, selectedInvites]);

  const tableWidth = useMemo(() => {
    const width =
      dimensions.window.width > 1200 ? 1200 - 40 - 40 : dimensions.window.width - 40 - 40;
    return {
      0: 40,
      1: (width / 20) * 3,
      2: (width / 20) * 4,
      3: (width / 20) * 6,
      4: (width / 20) * 4,
      5: (width / 20) * 4,
    };
  }, [dimensions]);

  const data = useMemo(() => {
    if (!dataCSV?.length) {
      return [];
    }
    if (searchText) {
      return dataCSV.filter(
        (item: ImportContact) =>
          item.firstName.includes(searchText) ||
          `${item.firstName} ${item.lastName}`.includes(searchText) ||
          item.lastName.includes(searchText) ||
          item.email.includes(searchText) ||
          item.phoneNumber.includes(searchText),
      );
    }
    return dataCSV;
  }, [dataCSV, searchText]);

  return {
    contactsToInvite,
    data,
    handleCheckboxSelect,
    handleCheckboxSelectAll,
    handleLabelChange,
    handleFieldTextChange,
    handleImportContactsPress,
    handleResetDocument,
    handleResetImportContacts,
    handleSearchTextChange,
    handleSelectFilePress,
    handleStepPress,
    handleUploadFilePress,
    isAllSelected,
    labels,
    loading,
    searchText,
    selectedDocument,
    selectedInvites,
    step,
    tableWidth,
  };
};
