import React, { useContext, useEffect } from 'react';
import { Dimensions, Platform, Pressable, StyleSheet, View } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { Icon, StyleService, Text, useStyleSheet, useTheme } from '@ui-kitten/components';
import { BlurTint, BlurView } from 'expo-blur';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { globalStyle } from '../../../common/style';
import ThemeContext from '../../../contexts/ThemeContext';
import useDimensions from '../../../hooks/useDimensions';
import { AppNavigatorProp, RootStackParamList } from '../../../navigation/app.navigator';

export const TransparentModal = ({
  title,
  navigateToScreenName,
  navigateToParams,
  children,
  closeModal = false,
  useScrollView = true,
  fullHeight = true,
  rightButton,
  onClose,
}: {
  title: string;
  navigateToScreenName?: keyof RootStackParamList;
  navigateToParams?: { [key: string]: string | number | boolean } | undefined;
  children: React.ReactNode | React.ReactNode[];
  closeModal?: boolean;
  useScrollView?: boolean;
  fullHeight?: boolean;
  rightButton?: JSX.Element;
  onClose?: any;
}) => {
  const { top } = useSafeAreaInsets();
  const styles = useStyleSheet(themedStyles);
  const { theme: themeContext } = useContext(ThemeContext);
  const theme = useTheme();

  const { dimensions } = useDimensions();

  const navigation = useNavigation<AppNavigatorProp<keyof RootStackParamList>>();

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    } else if (navigateToScreenName) {
      // @ts-ignore-line
      // Ignore this as the types don't seem to match.
      navigation.navigate(navigateToScreenName, navigateToParams);
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    if (closeModal === true) {
      handleCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal]);

  let modalContainerMarginTop = 40;
  if (top) {
    modalContainerMarginTop = top + 30;
  } else if (Platform.OS === 'web') {
    modalContainerMarginTop = 0;
  }

  return (
    <View style={StyleSheet.absoluteFill}>
      <BlurView
        tint={themeContext as BlurTint}
        intensity={40}
        style={[
          StyleSheet.absoluteFill,
          styles.blurView,
          Platform.OS === 'web' && dimensions.window.width > 400 && styles.padding40,
        ]}
      >
        <View
          style={[
            styles.modalContainer,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              maxWidth:
                Platform.OS === 'web' && dimensions.window.width > 400
                  ? dimensions.window.width - 100
                  : '100%',
              paddingBottom: 40,
              marginTop: modalContainerMarginTop,
            },
            fullHeight && styles.height100Percent,
          ]}
        >
          <View
            style={[
              styles.modalHeader,
              Platform.OS === 'web' && dimensions.window.width > 400
                ? globalStyle.paddingVertical20
                : globalStyle.paddingVertical15,
              Platform.OS === 'web' && dimensions.window.width > 400
                ? styles.paddingTop30
                : globalStyle.paddingTop15,
            ]}
          >
            <Pressable onPress={handleCloseModal}>
              <Icon
                name="close-outline"
                fill={theme['background-alternative-color-1']}
                style={styles.closeIcon}
              />
            </Pressable>
            <Text
              selectable={true}
              style={[
                styles.modalHeadlineText,
                Platform.OS === 'web' && dimensions.window.width > 400
                  ? globalStyle.fontSize18
                  : globalStyle.fontSize16,
              ]}
            >
              {title}
            </Text>
            <View>{rightButton}</View>
          </View>

          {useScrollView || Platform.OS === 'web' ? (
            <ScrollView
              showsVerticalScrollIndicator={false}
              showsHorizontalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              style={[styles.modalScrollView, fullHeight && styles.height100Percent]}
            >
              {children}

              <View style={styles.extraHeight} />
            </ScrollView>
          ) : (
            <View style={[styles.modalView, fullHeight && styles.height100Percent]}>
              {children}
            </View>
          )}
        </View>
      </BlurView>
    </View>
  );
};

const themedStyles = StyleService.create({
  blurView: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    width: '100%',
    backgroundColor: 'background-basic-color-1',
    borderWidth: 1,
    borderColor: 'border-basic-color-3',
    borderRadius: 20,
    boxShadow: Platform.OS === 'web' ? '4px 4px 10px rgba(0,0,0,0.04)' : 'none',
    overflow: 'hidden',
  },
  closeIcon: {
    ...Platform.select({
      ios: {
        width: 32,
        height: 32,
      },
      android: {
        width: 32,
        height: 32,
      },
      // other platforms, web for example
      default: {
        width: 32,
        height: 32,
      },
    }),
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  modalHeadlineText: {
    flex: 1,
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  modalClosePressable: {
    height: 32,
    width: 32,
  },

  modalView: {
    paddingHorizontal: 0,
  },
  modalScrollView: {
    paddingTop: 5,
    maxHeight: Platform.OS === 'web' ? Dimensions.get('window').height - 200 : '100%',
    height: Platform.OS === 'web' ? 'auto' : '100%',
    width: '100%',
    padding: 15,
  },
  height100Percent: {
    height: '100%',
  },
  extraHeight: {
    height: 40,
  },
  paddingTop30: {
    paddingTop: 30,
  },
  padding40: {
    padding: 40,
  },
});
