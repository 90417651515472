import { gql } from '@apollo/client';

export const MARK_ALL_NEW_SYSTEM_NOTIFICATIONS_AS_READ_FOR_ORGANIZATION_USER = gql`
  mutation MarkAllNewSystemNotificationsAsReadForOrganizationUser($organizationUserId: String!) {
    markAllNewSystemNotificationsAsReadForOrganizationUser(organizationUserId: $organizationUserId)
  }
`;

export const MARK_SYSTEM_NOTIFICATION_AS_READ_FOR_ORGANIZATION_USER = gql`
  mutation MarkSystemNotificationAsReadForOrganizationUser(
    $notificationId: String!
    $organizationUserId: String!
  ) {
    markSystemNotificationAsReadForOrganizationUser(
      notificationId: $notificationId
      organizationUserId: $organizationUserId
    )
  }
`;

export const CONNECT_WITH_AGENCY_ORGANIZATION_AS_COURIER_ORGANIZATION = gql`
  mutation ConnectWithAgencyOrganizationAsCourierOrganization(
    $courierOrganizationId: String!
    $activationCode: String!
    $agencyOrganizationId: String!
  ) {
    connectWithAgencyOrganizationAsCourierOrganization(
      courierOrganizationId: $courierOrganizationId
      activationCode: $activationCode
      agencyOrganizationId: $agencyOrganizationId
    ) {
      id
      connectionType
      createdAt
      updatedAt
      connectingOrganization {
        id
        name
        shortName
      }
      connectedOrganization {
        id
        name
        shortName
      }
    }
  }
`;

export const ADD_DEVICE_LOG_UNAUTHENTICATED_MUTATION = gql`
  mutation AddDeviceLogUnauthenticated($addDeviceLogInputs: [AddDeviceLogInput!]!) {
    addDeviceLogUnauthenticated(addDeviceLogInputs: $addDeviceLogInputs) {
      deviceLogIds
      lastDeviceLog {
        id
        data {
          uptime
        }
      }
    }
  }
`;

export const ADD_DEVICE_LOG_MUTATION = gql`
  mutation AddDeviceLog($addDeviceLogInputs: [AddDeviceLogInput!]!) {
    addDeviceLog(addDeviceLogInputs: $addDeviceLogInputs) {
      deviceLogIds
      lastDeviceLog {
        id
        data {
          uptime
        }
      }
    }
  }
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation ActivateAccount($activationCode: String!) {
    activateAccount(activationCode: $activationCode) {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        endTime
        prices {
          currency
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;

export const FIND_OR_CREATE_AD_HOC_AVAILABILITY_MUTATION = gql`
  mutation FindOrCreateAdHocAvailability($deviceLocationId: String) {
    findOrCreateAdHocAvailability(deviceLocationId: $deviceLocationId) {
      id
      lastLocationForDevice {
        id
        formattedAddress
        formattedMinimalAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      isAdHocAvailability
      startTime
      endTime
      updatedAt
    }
  }
`;

export const CREATE_OR_UPDATE_COURIER_USER_CONTACT_MUTATION = gql`
  mutation CreateOrUpdateCourierUserContact(
    $userContactInput: UserContactInput!
    $inviteUserContact: Boolean!
  ) {
    createOrUpdateCourierUserContact(
      userContactInput: $userContactInput
      inviteUserContact: $inviteUserContact
    ) {
      id
      expoContactId
      phone
      email
      firstNames
      lastName
    }
  }
`;

export const CREATE_AVAILABILITY_MUTATION = gql`
  mutation CreateAvailability(
    $suggestedLocationInput: SuggestedLocationInput!
    $startTime: DateTime!
    $endTime: DateTime!
    $timeZoneIdentifier: String!
    $timeZoneDestinationOffset: Float!
    $timeZoneRawOffset: Float!
  ) {
    createAvailability(
      suggestedLocationInput: $suggestedLocationInput
      startTime: $startTime
      endTime: $endTime
      timeZoneIdentifier: $timeZoneIdentifier
      timeZoneDestinationOffset: $timeZoneDestinationOffset
      timeZoneRawOffset: $timeZoneRawOffset
    ) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      availabilityLocations {
        id
        location {
          id
          formattedAddress
          formattedMinimalAddress
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
      }
      lastDeviceLocationForDevice {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;

export const DELETE_AVAILABILITY_MUTATION = gql`
  mutation DeleteAvailability($availabilityId: String!) {
    deleteAvailability(availabilityId: $availabilityId) {
      id
    }
  }
`;

export const DISABLE_AD_HOC_AVAILABILITY_MUTATION = gql`
  mutation DisableAdHocAvailability {
    disableAdHocAvailability
  }
`;

export const REGISTER_EXISTING_USER_WITH_OWNED_ORGANIZATION = gql`
  mutation RegisterExistingUserWithOwnedOrganization($organizationType: OrganizationType!) {
    registerExistingUserWithOwnedOrganization(organizationType: $organizationType) {
      id
      user {
        id
        firstNames
        lastName
        email
      }
      organization {
        id
        organizationType
      }
      roleType
      createdAt
    }
  }
`;
export const VOID_ORGANIZATION_INVITATION_MUTATION = gql`
  mutation VoidOrganizationInvitation($id: String!) {
    voidOrganizationInvitation(id: $id) {
      id
      activationCode
      voidedAt
      updatedAt
    }
  }
`;

export const CREATE_ORGANIZATION_INVITATION_SHARING_MUTATION = gql`
  mutation CreateOrganizationInvitationSharing(
    $organizationInvitationSharingInput: OrganizationInvitationSharingInput!
  ) {
    createOrganizationInvitationSharing(
      organizationInvitationSharingInput: $organizationInvitationSharingInput
    ) {
      id
      appName
      platformVersion
      platform
      language
      sharedAt
      sharedByUser {
        id
        firstNames
        email
        lastName
      }
      organizationInvitation {
        id
        activationCode
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_ORGANIZATION_INVITATION_AS_PRIOJET_MUTATION = gql`
  mutation CreateOrganizationInvitationAsPriojet(
    $reservedComment: String!
    $organizationInvitationType: OrganizationInvitationType!
    $invitedOrganizationId: String
    $invitingOrganizationId: String
    $roleType: OrganizationUserRoleType
    $invitedOrganizationType: OrganizationType
  ) {
    createOrganizationInvitationAsPriojet(
      reservedComment: $reservedComment
      invitedOrganizationType: $invitedOrganizationType
      invitedOrganizationId: $invitedOrganizationId
      invitingOrganizationId: $invitingOrganizationId
      roleType: $roleType
      organizationInvitationType: $organizationInvitationType
    ) {
      id
      createdAt
      updatedAt
      activationCode
      reservedComment
      bitrix24Deal {
        id
        bitrixDealCategoryId
        bitrixInvitedCompanyId
        bitrixInvitedContactId
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectedOrganization {
          id
          name
          organizationType
        }
        connectingOrganization {
          id
          name
          organizationType
        }
      }
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isPriojetContact
        isCourierContact
        isAgencyContact
        isClientContact
        isTravelerContact
        isServiceProviderContact
        isOtherContact
        organization {
          id
          name
        }
      }
      invitedOrganizationType
      roleType
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      invitingOrganization {
        id
        name
        organizationType
      }
      usedByUser {
        id
        firstNames
        email
        lastName
      }
      createdByUser {
        id
        firstNames
        email
        lastName
      }
      createdAt
      voidedAt
      usedAt
      organizationInvitationSharings {
        id
        appName
        platformVersion
        platform
        language
        sharedAt
        sharedByUser {
          id
          firstNames
          email
          lastName
        }
      }
    }
  }
`;

export const EDIT_ORGANIZATION_INVITATION_MUTATION = gql`
  mutation EditOrganizationInvitation($id: String!) {
    voidOrganizationInvitation(id: $id) {
      id
      activationCode
      voidedAt
      updatedAt
    }
  }
`;

export const GET_OR_CREATE_DEVICE_MUTATION = gql`
  mutation GetOrCreateDevice($deviceInput: DeviceInput!) {
    getOrCreateDevice(deviceInput: $deviceInput) {
      id
      deviceType
    }
  }
`;

export const UPDATE_DEVICE_MUTATION = gql`
  mutation UpdateDevice($deviceInput: DeviceInput!) {
    updateDevice(deviceInput: $deviceInput) {
      id
      deviceType
    }
  }
`;

export const CREATE_DEVICE_LOCATIONS_MUTATION = gql`
  mutation CreateDeviceLocations($deviceLocationInputs: [DeviceLocationInput!]!) {
    createDeviceLocations(deviceLocationInputs: $deviceLocationInputs) {
      id
      location {
        id
        formattedAddress
        formattedMinimalAddress
      }
      latitude
      longitude
      altitude
      createdAt
    }
  }
`;

export const CREATE_USER_IDENTITY_DOCUMENT_MUTATION = gql`
  mutation CreateUserIdentityDocument($userIdentityDocumentInput: UserIdentityDocumentInput!) {
    createUserIdentityDocument(userIdentityDocumentInput: $userIdentityDocumentInput) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      countryOfIssue
      expiresOn
      nationality
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const UPDATE_COURIER_USER_PROFILE_MUTATION = gql`
  mutation UpdateCourierUserProfile($courierUserProfileInput: CourierUserProfileInput!) {
    updateCourierUserProfile(courierUserProfileInput: $courierUserProfileInput) {
      id
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactAddress
      hasAmexCreditCard
      hasDinerCreditCard
      hasMasterCreditCard
      hasVisaCreditCard
      hasAmexDebitCard
      hasDinerDebitCard
      hasMasterDebitCard
      hasVisaDebitCard
      amexCreditCardLimit
      dinerCreditCardLimit
      masterCreditCardLimit
      visaCreditCardLimit
      amexCreditCardCurrency
      dinerCreditCardCurrency
      masterCreditCardCurrency
      visaCreditCardCurrency
      hasObcExperience
      firstObcJobDateAt
      numberOfPastObcJobs
      hasMedicalObcExperience
      languagesKnown {
        language
        level
      }
      onboardExperiences
      taxCountry
      socialProfiles {
        platform
        type
        value
      }
      baseAirports
      taxCountry
    }
  }
`;

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation UpdateUserProfile($userProfileInput: UserProfileInput!) {
    updateUserProfile(userProfileInput: $userProfileInput) {
      id
      firstNames
      lastName
      email
      email2
      sex
      dateOfBirth
      placeOfBirth
      countryOfBirth
      language
      nationalities
      phone
      phone2
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      userRegisteredAddressLocation {
        id
        placeId
        formattedAddress
        formattedMinimalAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
    }
  }
`;

export const DELETE_USER_PROFILE_PICTURE_MUTATION = gql`
  mutation deleteUserProfilePicture {
    deleteUserProfilePicture {
      id
      firstNames
      lastName
      email
      sex
      dateOfBirth
      placeOfBirth
      countryOfBirth
      language
      nationalities
    }
  }
`;

export const CREATE_USER_TRAVEL_DOCUMENT_MUTATION = gql`
  mutation CreateUserTravelDocument(
    $userTravelDocumentInput: UserTravelDocumentInput!
    $mappedUserIdentityDocumentId: String!
  ) {
    createUserTravelDocument(
      userTravelDocumentInput: $userTravelDocumentInput
      mappedUserIdentityDocumentId: $mappedUserIdentityDocumentId
    ) {
      id
      documentSubType
      documentSubTypeMulti
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentNumber
      dateOfIssue
      countryOfIssue
      validForCountry
      expiresOn
      nationality
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      multipleEntriesAllowed
    }
  }
`;
export const CREATE_USER_FREQUENT_FLYER_DOCUMENT_MUTATION = gql`
  mutation CreateUserFrequentFlyerDocument(
    $userFrequentFlyerDocumentInput: UserFrequentFlyerDocumentInput!
  ) {
    createUserFrequentFlyerDocument(
      userFrequentFlyerDocumentInput: $userFrequentFlyerDocumentInput
    ) {
      id
      airlineType
      airlineStatusType
      airlineAllianceStatusType
      airlineAllianceType
      documentNumber
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const CREATE_USER_VACCINATION_DOCUMENT_MUTATION = gql`
  mutation CreateUserVaccinationDocument(
    $userVaccinationDocumentInput: UserVaccinationDocumentInput!
  ) {
    createUserVaccinationDocument(userVaccinationDocumentInput: $userVaccinationDocumentInput) {
      id
      diseaseType
      description
      vaccinationType
      vaccinationManufacturer
      dateOfVaccination
      isVaccinationComplete
      vaccinationCenter
      cityOfVaccination
      countryOfVaccination
      expiresOn
      documentNumber
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const CREATE_USER_HEALTH_REPORT_MUTATION = gql`
  mutation CreateUserHealthReport($userHealthReportInput: UserHealthReportInput!) {
    createUserHealthReport(userHealthReportInput: $userHealthReportInput) {
      id
      diseaseType
      description
      testType
      dateTimeOfTest
      isResultPositive
      testCenter
      cityOfTest
      countryOfTest
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const CREATE_USER_BACKGROUND_DOCUMENT_MUTATION = gql`
  mutation CreateUserBackgroundDocument(
    $userBackgroundDocumentInput: UserBackgroundDocumentInput!
  ) {
    createUserBackgroundDocument(userBackgroundDocumentInput: $userBackgroundDocumentInput) {
      id
      name
      issuingInstitution
      countryOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const CREATE_USER_OTHER_DOCUMENT_MUTATION = gql`
  mutation CreateUserOtherDocument($userOtherDocumentInput: UserOtherDocumentInput!) {
    createUserOtherDocument(userOtherDocumentInput: $userOtherDocumentInput) {
      id
      name
      description
      countryOfIssue
      placeOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const UPDATE_RELATIONSHIP_TYPE_FOR_COURIER_JOB_AS_COURIER_ORGANIZATION_MUTATION = gql`
  mutation UpdateRelationshipTypeForCourierJobAsCourierOrganization(
    $id: String!
    $relationshipType: CourierJobAgencyRelationshipType!
  ) {
    updateRelationshipTypeForCourierJobAsCourierOrganization(
      id: $id
      relationshipType: $relationshipType
    ) {
      id
      relationshipType
      courierJobStatusType
      updatedAt
      agencyJob {
        id
      }
    }
  }
`;

export const UPDATE_RELATIONSHIP_TYPE_FOR_COURIER_JOB_AS_AGENCY_ORGANIZATION_MUTATION = gql`
  mutation UpdateRelationshipTypeForCourierJobAsAgencyOrganization(
    $id: String!
    $agencyJobId: String!
    $relationshipType: CourierJobAgencyRelationshipType!
  ) {
    updateRelationshipTypeForCourierJobAsAgencyOrganization(
      id: $id
      agencyJobId: $agencyJobId
      relationshipType: $relationshipType
    ) {
      id
      relationshipType
      courierJobStatusType
      updatedAt
      agencyJob {
        id
      }
    }
  }
`;

export const ADD_OR_DISABLE_COURIER_JOB_MUTATION = gql`
  mutation AddOrDisableCourierJob($courierOrganizationUserIds: [String!]!, $agencyJobId: String!) {
    addOrDisableCourierJob(
      courierOrganizationUserIds: $courierOrganizationUserIds
      agencyJobId: $agencyJobId
    ) {
      user {
        id
        firstNames
        lastName
        email
        phone
      }
      organizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
        }
      }
      courierJob {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        isActive
      }
      availabilityLocationsWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
          }
        }
        distance
      }
    }
  }
`;

export const MUTATION_ADD_OR_DISABLE_COURIER_FOR_COURIER_JOB = gql`
  mutation AddOrDisableCourierForCourierJob(
    $courierOrganizationUserIds: [String!]!
    $agencyJobId: String!
  ) {
    addOrDisableCourierForCourierJob(
      courierOrganizationUserIds: $courierOrganizationUserIds
      agencyJobId: $agencyJobId
    ) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      isActive
    }
  }
`;

export const CHANGE_AGENCY_JOB_STATUS_TYPE_FOR_AGENCY_ORGANIZATION = gql`
  mutation ChangeAgencyJobStatusTypeForAgencyOrganization(
    $agencyJobId: String!
    $agencyJobStatusType: AgencyJobStatusType!
  ) {
    changeAgencyJobStatusTypeForAgencyOrganization(
      agencyJobId: $agencyJobId
      agencyJobStatusType: $agencyJobStatusType
    ) {
      id
      agencyJobStatusType
    }
  }
`;

export const CREATE_OR_UPDATE_JOB_FOR_AGENCY_ORGANIZATION_MUTATION = gql`
  mutation CreateOrUpdateJobForAgencyOrganization(
    $agencyJobInput: AgencyJobInput!
    $clientJobByAgencyInput: ClientJobByAgencyInput!
    $jobInput: JobInput!
    $courierOrganizationUserIds: [String!]
  ) {
    createOrUpdateJobForAgencyOrganization(
      agencyJobInput: $agencyJobInput
      clientJobByAgencyInput: $clientJobByAgencyInput
      jobInput: $jobInput
      courierOrganizationUserIds: $courierOrganizationUserIds
    ) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      internalNotes
      comment
      fixedRate
      dailyRate
      kickOffFee
      hotelBudget
      currency
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isDrivingLicenseRequiredForJob
      isCarRequiredForJob
      jobDetails
      numOfCouriersRequired
      hasIncident
      isAdditionalCostPaidByCourier
      additionalCostInformation
      startAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        numOfItems
        weightOfItems
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      clientJob {
        id
        organizationName
        organizationLegalName
        organizationPhone
        organizationEmail
        externalJobReference
        comment
        fixedRate
        currency
        hasIncident
      }
      courierJobs {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        fixedRate
        dailyRate
        hotelBudget
        kickOffFee
        isAccommodationBookedByCourier
        isAccommodationPaidByCourier
        hasIncident
        isAdditionalCostPaidByCourier
        additionalCostInformation
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
          sex
          dateOfBirth
          placeOfBirth
          countryOfBirth
          language
          nationalities
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation CreateChatMessage($createChatMessageInput: CreateChatMessageInput!) {
    createChatMessage(createChatMessageInput: $createChatMessageInput) {
      node {
        id
        message
        messageType
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;

export const UPDATE_CHAT_MESSAGE_MUTATION = gql`
  mutation UpdateChatMessage($updateChatMessageInput: UpdateChatMessageInput!) {
    updateChatMessage(updateChatMessageInput: $updateChatMessageInput) {
      node {
        id
        message
        messageType
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;

export const DELETE_CHAT_MESSAGE_MUTATION = gql`
  mutation DeleteChatMessage($deleteChatMessageInput: DeleteChatMessageInput!) {
    deleteChatMessage(deleteChatMessageInput: $deleteChatMessageInput) {
      id
    }
  }
`;

export const UPDATE_AGENCY_ORGANIZATION_PROFILE_MUTATION = gql`
  mutation UpdateAgencyOrganizationProfile(
    $agencyOrganizationProfileInput: AgencyOrganizationProfileInput!
  ) {
    updateAgencyOrganizationProfile(
      agencyOrganizationProfileInput: $agencyOrganizationProfileInput
    ) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      socialProfiles {
        platform
        type
        value
      }
      headquartersAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoicingAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      registrationAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoiceEmail
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const UPDATE_AGENCY_ORGANIZATION_VAT_ID_MUTATION = gql`
  mutation UpdateAgencyOrganizationVatId(
    $agencyOrganizationVatIdInput: AgencyOrganizationVatIdInput!
  ) {
    updateAgencyOrganizationVatId(agencyOrganizationVatIdInput: $agencyOrganizationVatIdInput) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
    }
  }
`;

export const DELETE_ORGANIZATION_LOGO_MUTATION = gql`
  mutation deleteOrganizationLogo($organizationId: String!) {
    deleteOrganizationLogo(organizationId: $organizationId) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const UPDATE_COURIER_ORGANIZATION_PROFILE_MUTATION = gql`
  mutation UpdateCourierOrganizationProfile(
    $courierOrganizationProfileInput: CourierOrganizationProfileInput!
  ) {
    updateCourierOrganizationProfile(
      courierOrganizationProfileInput: $courierOrganizationProfileInput
    ) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      socialProfiles {
        platform
        type
        value
      }
      headquartersAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoicingAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      registrationAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;

export const DELETE_USER_BACKGROUND_DOCUMENT_MUTATION = gql`
  mutation DeleteUserBackgroundDocument($documentId: String!) {
    deleteUserBackgroundDocument(documentId: $documentId) {
      id
    }
  }
`;
export const DELETE_USER_FREQUENT_FLYER_DOCUMENT_MUTATION = gql`
  mutation DeleteUserFrequentFlyerDocument($documentId: String!) {
    deleteUserFrequentFlyerDocument(documentId: $documentId) {
      id
    }
  }
`;
export const DELETE_USER_HEALTH_REPORT_MUTATION = gql`
  mutation DeleteUserHealthReport($documentId: String!) {
    deleteUserHealthReport(documentId: $documentId) {
      id
    }
  }
`;
export const DELETE_USER_IDENTITY_DOCUMENT_MUTATION = gql`
  mutation DeleteUserIdentityDocument($documentId: String!) {
    deleteUserIdentityDocument(documentId: $documentId) {
      id
    }
  }
`;
export const DELETE_USER_OTHER_DOCUMENTS_MUTATION = gql`
  mutation UserOtherDocumentDelete($documentId: String!) {
    deleteUserOtherDocument(documentId: $documentId) {
      id
    }
  }
`;
export const DELETE_USER_TRAVEL_DOCUMENT_MUTATION = gql`
  mutation DeleteUserTravelDocument($documentId: String!) {
    deleteUserTravelDocument(documentId: $documentId) {
      id
    }
  }
`;
export const DELETE_USER_VACCINATION_DOCUMENT_MUTATION = gql`
  mutation DeleteUserVaccinationDocument($documentId: String!) {
    deleteUserVaccinationDocument(documentId: $documentId) {
      id
    }
  }
`;

export const MUTATION_DELETE_AGENCY_ACCOUNT = gql`
  mutation DeleteAgencyAccount($userId: String!) {
    deleteAgencyAccount(userId: $userId)
  }
`;

export const MUTATION_DELETE_COURIER_ACCOUNT = gql`
  mutation DeleteCourierAccount($userId: String!) {
    deleteCourierAccount(userId: $userId)
  }
`;

export const MUTATION_USER_DELETE_ACCOUNT = gql`
  mutation UserDeleteAccount($userId: String!) {
    userDeleteAccount(userId: $userId)
  }
`;

export const MUTATION_RESTORE_AGENCY_ACCOUNT = gql`
  mutation RestoreAgencyAccount($userId: String!) {
    restoreAgencyAccount(userId: $userId) {
      id
    }
  }
`;

export const MUTATION_RESTORE_COURIER_ACCOUNT = gql`
  mutation RestoreCourierAccount($userId: String!) {
    restoreCourierAccount(userId: $userId) {
      id
    }
  }
`;

export const MUTATION_USER_ACCOUNT_RESTORE = gql`
  mutation UserAccountRestore($userId: String!) {
    userAccountRestore(userId: $userId) {
      id
    }
  }
`;

export const MUTATION_ORGANIZATION_USER_UPDATE_PROFILE = gql`
  mutation organizationUserUpdateProfile(
    $organizationUserUpdateProfileInput: OrganizationUserUpdateProfileInput!
  ) {
    organizationUserUpdateProfile(
      organizationUserUpdateProfileInput: $organizationUserUpdateProfileInput
    ) {
      id
      roleType
      user {
        firstNames
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        email
        phone
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
        }
      }
      organization {
        id
      }
    }
  }
`;

export const MUTATION_CREATE_OR_UPDATE_AGENCY_EMPLOYEE_USER_CONTACT = gql`
  mutation CreateOrUpdateAgencyEmployeeUserContact(
    $userContactInput: UserContactInput!
    $inviteUserContact: Boolean!
    $organizationUserRole: OrganizationUserRoleType!
  ) {
    createOrUpdateAgencyEmployeeUserContact(
      userContactInput: $userContactInput
      inviteUserContact: $inviteUserContact
      organizationUserRole: $organizationUserRole
    ) {
      id
      expoContactId
      phone
      email
      firstNames
      lastName
    }
  }
`;

export const MUTATION_ORGANIZATION_INVITATION_DELETE = gql`
  mutation OrganizationInvitationDelete($organizationInvitationId: String!) {
    organizationInvitationDelete(organizationInvitationId: $organizationInvitationId) {
      id
    }
  }
`;

export const MUTATION_ORGANIZATION_USER_REMOVE_ORGANIZATION_USER_FROM_ORGANIZATION = gql`
  mutation OrganizationUserRemoveOrganizationUserFromOrganization($organizationUserId: String!) {
    organizationUserRemoveOrganizationUserFromOrganization(organizationUserId: $organizationUserId)
  }
`;

export const MUTATION_ORGANIZATION_SUBSCRIPTION_PLAN_CANCEL = gql`
  mutation OrganizationSubscriptionPlanCancel($data: OrganizationSubscriptionPlanSingleInput!) {
    organizationSubscriptionPlanCancel(data: $data) {
      id
    }
  }
`;

export const MUTATION_AIRPORT_LOCATION_UPDATE_TIMEZONE = gql`
  mutation AirportLocationUpdateTimezone($data: AirportLocationUpdateTimezoneInput!) {
    airportLocationUpdateTimezone(data: $data) {
      id
      placeId
      country
      iataCode
      formattedAddress
      city
    }
  }
`;

export const MUTATION_ORGANIZATION_COURIER_CATEGORIES_UPDATE = gql`
  mutation organizationCourierCategoriesUpdate($data: OrganizationCourierCategoriesUpdateInput!) {
    organizationCourierCategoriesUpdate(data: $data) {
      id
      order
      label
    }
  }
`;

export const MUTATION_AGENCY_COURIER_USER_PROFILE_CREATE_OR_UPDATE = gql`
  mutation agencyCourierUserProfileCreateOrUpdate(
    $data: AgencyCourierUserProfileCreateOrUpdateInput!
  ) {
    agencyCourierUserProfileCreateOrUpdate(data: $data) {
      id
      internalComment
      markedForAttention
    }
  }
`;

export const MUTATION_AGENCY_JOB_UPDATE_INTERNAL_NOTES = gql`
  mutation agencyJobUpdateInternalNotes($agencyJobId: ID!, $internalNotes: String!) {
    agencyJobUpdateInternalNotes(agencyJobId: $agencyJobId, internalNotes: $internalNotes) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      comment
      fixedRate
      dailyRate
      kickOffFee
      hotelBudget
      currency
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      isDrivingLicenseRequiredForJob
      isCarRequiredForJob
      jobDetails
      numOfCouriersRequired
      hasIncident
      agencyJobStatusType
      internalNotes
      startAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        numOfItems
        weightOfItems
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      clientJob {
        id
        organizationName
        organizationLegalName
        organizationPhone
        organizationEmail
        externalJobReference
        comment
        fixedRate
        currency
        hasIncident
      }
      courierJobs {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        fixedRate
        dailyRate
        hotelBudget
        kickOffFee
        isAccommodationBookedByCourier
        isAccommodationPaidByCourier
        isAdditionalCostPaidByCourier
        additionalCostInformation
        hasIncident
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
          sex
          dateOfBirth
          placeOfBirth
          countryOfBirth
          language
          nationalities
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        courierJobAgencyJobChat {
          id
        }
      }
    }
  }
`;

export const MUTATION_NOTIFICATION_SETTING_UPDATE = gql`
  mutation notificationSettingUpdate($data: NotificationSettingUpdateInput!) {
    notificationSettingUpdate(data: $data) {
      id
      emailConnection
      emailJob
      pushAvailability
      pushChat
      pushConnection
      pushJob
      systemAvailability
      systemChat
      systemConnection
      systemJob
    }
  }
`;

export const MUTATION_SIGN_OUT_USER_CONTEXT = gql`
  mutation authSignOutUserContext($data: AuthSignOutUserContextInput!) {
    authSignOutUserContext(data: $data)
  }
`;

export const MUTATION_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB_UPDATE = gql`
  mutation AgencyJobPlanningForAgencyJobUpdate(
    $agencyJobId: String!
    $agencyJobPlanningId: String!
    $agencyJobPlanningForAgencyJobUpdateInput: AgencyJobPlanningForAgencyJobUpdateInput!
  ) {
    agencyJobPlanningForAgencyJobUpdate(
      agencyJobId: $agencyJobId
      agencyJobPlanningId: $agencyJobPlanningId
      agencyJobPlanningForAgencyJobUpdateInput: $agencyJobPlanningForAgencyJobUpdateInput
    ) {
      id
      agencyJobPlanningItems {
        id
        active
        actualDateTime
        actualDateTimeSetAt
        createdAt
        files {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        formType
        id
        inactivatedAt
        label
        location {
          id
          formattedAddress
        }
        name
        order
        targetDateTime
      }
      agencyJobPlanningLegs {
        id
        active
        departureDate
        order
        flightCarrierDesignation
        flightNumber
        flightTickets {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        agencyJobPlanningItems {
          id
          active
          actualDateTime
          actualDateTimeSetAt
          createdAt
          files {
            id
            storedAsset {
              sasUrl
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          formType
          id
          inactivatedAt
          label
          location {
            id
            formattedAddress
          }
          name
          order
          targetDateTime
        }
      }
    }
  }
`;

export const MUTATION_AGENCY_JOB_PLANNING_ITEM_UPDATE = gql`
  mutation agencyJobPlanningItemUpdate(
    $agencyJobPlanningId: String!
    $agencyJobPlanningItemUpdateInput: AgencyJobPlanningItemUpdateInput!
  ) {
    agencyJobPlanningItemUpdate(
      agencyJobPlanningId: $agencyJobPlanningId
      agencyJobPlanningItemUpdateInput: $agencyJobPlanningItemUpdateInput
    ) {
      id
      active
      actualDateTime
      actualDateTimeSetAt
      createdAt
      files {
        id
        storedAsset {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      formType
      id
      inactivatedAt
      label
      location {
        id
        formattedAddress
      }
      name
      order
      targetDateTime
      updatedAt
    }
  }
`;

export const MUTATION_AGENCY_JOB_PLANNING_ITEM_RESET = gql`
  mutation agencyJobPlanningItemReset(
    $agencyJobPlanningId: String!
    $agencyJobPlanningItemId: String!
  ) {
    agencyJobPlanningItemReset(
      agencyJobPlanningId: $agencyJobPlanningId
      agencyJobPlanningItemId: $agencyJobPlanningItemId
    ) {
      id
      active
      actualDateTime
      actualDateTimeSetAt
      createdAt
      files {
        id
        storedAsset {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      formType
      id
      inactivatedAt
      label
      location {
        id
        formattedAddress
      }
      name
      order
      targetDateTime
      updatedAt
    }
  }
`;

export const CREATE_CHAT_MESSAGE_FOR_AGENCY_MUTATION = gql`
  mutation CreateChatMessageForAgency($createChatMessageInput: CreateChatMessageInput!) {
    createChatMessage(createChatMessageInput: $createChatMessageInput) {
      node {
        id
        message
        messageType
        agencyJobPlanningItemHistoryLog {
          id
          active
          actualDateTime
          label
          targetDateTime
          note
          agencyJobPlanningLeg {
            id
            flightCarrierDesignation
            flightNumber
            order
          }
          files {
            id
            storedAsset {
              sasUrl
              cacheKey
              cacheTTL
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          location {
            id
            formattedAddress
          }
        }
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;

export const UPDATE_CHAT_MESSAGE_FOR_AGENCY_MUTATION = gql`
  mutation UpdateChatMessageForAgency($updateChatMessageInput: UpdateChatMessageInput!) {
    updateChatMessage(updateChatMessageInput: $updateChatMessageInput) {
      node {
        id
        message
        messageType
        agencyJobPlanningItemHistoryLog {
          id
          active
          actualDateTime
          label
          targetDateTime
          note
          agencyJobPlanningLeg {
            id
            flightCarrierDesignation
            flightNumber
            order
          }
          files {
            id
            storedAsset {
              sasUrl
              cacheKey
              cacheTTL
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          location {
            id
            formattedAddress
          }
        }
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
