import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useNavigation, useIsFocused } from '@react-navigation/core';

import { AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY } from '../../../../apollo/graphql-queries';
import AppUserContext from '../../../../contexts/AppUserContext';
import { AgencyJobStatusType } from '../../../../generated-graphql-types';
import {
  AgencyJobsForAgencyOrganization,
  AgencyJobsForAgencyOrganizationVariables,
} from '../../../../graphql-types';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';

interface IDataCount {
  newCount: number;
  runningCount: number;
  finishedCount: number;
  cancelledCount: number;
}

export const useHook = () => {
  const navigation: any = useNavigation();
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();

  const appUserContext = React.useContext(AppUserContext);

  const { data, loading } = useQuery<
    AgencyJobsForAgencyOrganization,
    AgencyJobsForAgencyOrganizationVariables
  >(AGENCY_JOBS_FOR_AGENCY_ORGANIZATION_MINIMAL_QUERY, {
    variables: {
      agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    pollInterval: isFocused && isBackendReachable ? 30 * 1000 : 0,
    skip: !isFocused,
  });

  const handleCreateJobPress = () => {
    navigation.navigate('AgencyCreateJobScreen', {
      previousScreenName: 'AgencyHomeScreen',
    });
  };

  const handleOpenJobsPress = (type: 'NEW' | 'RUNNING' | 'FINISHED' | 'CANCELLED') => () => {
    switch (type) {
      case 'RUNNING':
        navigation.navigate('AgencyJobsScreen', {
          previousTabScreenName: 'AgencyStartedJobsScreen',
        });
        break;
      case 'FINISHED':
        navigation.navigate('AgencyJobsScreen', {
          previousTabScreenName: 'AgencyFinishedJobsScreen',
        });
        break;
      case 'CANCELLED':
        navigation.navigate('AgencyJobsScreen', {
          previousTabScreenName: 'AgencyCancelledJobsScreen',
        });
        break;
      default:
        navigation.navigate('AgencyJobsScreen', {
          previousTabScreenName: 'AgencyNewJobsScreen',
        });
        break;
    }
  };

  const handleOpeOBCMapPress = () => {
    navigation.navigate('AgencyOBCAvailabilityMapScreen', {
      previousScreenName: 'AgencyHomeScreen',
    });
  };

  const dataCount = useMemo((): IDataCount => {
    if (data?.agencyJobsForAgencyOrganization?.length) {
      return data.agencyJobsForAgencyOrganization.reduce(
        (acc: IDataCount, current) => {
          if (current.agencyJobStatusType === AgencyJobStatusType.NEW) {
            acc.newCount++;
          } else if (current.agencyJobStatusType === AgencyJobStatusType.STARTED) {
            acc.runningCount++;
          } else if (
            current.agencyJobStatusType === AgencyJobStatusType.FINISHED ||
            current.agencyJobStatusType === AgencyJobStatusType.FINISHED_CLIENT_CONFIRMED
          ) {
            acc.finishedCount++;
          } else if (current.agencyJobStatusType === AgencyJobStatusType.CANCELLED) {
            acc.cancelledCount++;
          }
          return acc;
        },
        {
          newCount: 0,
          runningCount: 0,
          finishedCount: 0,
          cancelledCount: 0,
        },
      );
    }
    return { newCount: 0, runningCount: 0, finishedCount: 0, cancelledCount: 0 };
  }, [data?.agencyJobsForAgencyOrganization]);

  return {
    dataCount,
    handleCreateJobPress,
    handleOpenJobsPress,
    handleOpeOBCMapPress,
    isReady: !!data,
    loading,
  };
};
