import React, { useMemo } from 'react';
import { Platform } from 'react-native';

import { ApolloQueryResult } from '@apollo/client';
import { DateTime } from 'luxon';

import { InviteCourierUploadCSV } from './InviteCourierUploadCSV';
import { CourierInvitationButton } from '../../../components/common/Invitation/CourierInviteButton';
import { PhoneBook } from '../../../components/common/PhoneBook';
import { ImportedContactsType } from '../../../components/common/PhoneBook/types';
import {
  CourierOrganizationInvitationsForAgencyOrganizationQuery,
  Exact,
  OrganizationInvitation,
} from '../../../generated-graphql-types';

export const AgencyCourierInvitationPhoneBookContactsScreen = ({
  organizationInvitations,
  isLoading,
  refetch,
}: {
  organizationInvitations: Array<Partial<OrganizationInvitation>>;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            agencyOrganizationId: string;
            userId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<CourierOrganizationInvitationsForAgencyOrganizationQuery>>;
}) => {
  const importedContacts = useMemo(() => {
    if (organizationInvitations && organizationInvitations.length > 0) {
      const _importedContacts: ImportedContactsType = {};
      for (const organizationInvitation of organizationInvitations) {
        if (
          organizationInvitation.organizationContact &&
          organizationInvitation.organizationContact?.phone !== undefined
        ) {
          if (organizationInvitation.organizationContact?.userContactsForUser?.length) {
            organizationInvitation.organizationContact?.userContactsForUser.forEach(
              (userContact) => {
                const key = `${organizationInvitation.organizationContact?.phone}_${organizationInvitation.organizationContact?.email}`;
                _importedContacts[key] = {
                  expoContactId: userContact.expoContactId || '',
                  phone: userContact.phone,
                  email: userContact.email ? (userContact.email as string) : undefined,
                  organizationInvitation: {
                    id: organizationInvitation.id as string,
                    createdAt: organizationInvitation.createdAt as DateTime,
                  },
                };
              },
            );
          } else {
            const key = `${organizationInvitation.organizationContact?.phone}_${organizationInvitation.organizationContact?.email}`;
            _importedContacts[key] = {
              expoContactId: undefined,
              phone: organizationInvitation.organizationContact?.phone as string,
              email: organizationInvitation.organizationContact?.email as string,
              organizationInvitation: {
                id: organizationInvitation.id as string,
                createdAt: organizationInvitation.createdAt as DateTime,
              },
            };
          }
        }
      }
      return _importedContacts;
    }
  }, [organizationInvitations]);

  const renderInviteButton = ({ item, refetch: _refetch }: any) => (
    <CourierInvitationButton item={item} refetch={_refetch} />
  );

  if (Platform.OS === 'web') {
    return (
      <InviteCourierUploadCSV
        importedContacts={importedContacts}
        isLoading={isLoading}
        refetch={refetch}
      />
    );
  }
  return (
    <PhoneBook
      importedContacts={importedContacts}
      isLoading={isLoading}
      renderInviteButton={renderInviteButton}
      refetch={refetch}
    />
  );
};
