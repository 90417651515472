import React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';

import { AgencySubscriptionAccountActivationBottomTabBar } from './AgencySubscriptionActivationBottomTabNavigator';
import { AgencySubscriptionSelectPlanScreen } from '../../screens/agency/AgencySubscriptionSelectPlanScreen';
import { CommonAccountActivationScreen } from '../../screens/common/common.account-activation.screen';
import CommonNotificationPermissionsScreen from '../../screens/common/CommonNotificationPermissionsScreen';
import { CommonProfileNavigator } from '../common.profile.navigator';

const BottomTabNavigator = createBottomTabNavigator();
const StackNavigator = createStackNavigator();

const AgencySubscriptionNavigator = () => {
  return (
    <StackNavigator.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="AgencySubscriptionSelectPlanScreen"
    >
      <StackNavigator.Screen
        name="AgencySubscriptionSelectPlanScreen"
        component={AgencySubscriptionSelectPlanScreen}
      />
      <StackNavigator.Screen
        name="CommonAccountActivationScreen"
        component={CommonAccountActivationScreen}
      />
    </StackNavigator.Navigator>
  );
};

const AgencySubscriptionAccountActivationNavigator = () => (
  <BottomTabNavigator.Navigator
    screenOptions={{ headerShown: false }}
    tabBar={(props) => <AgencySubscriptionAccountActivationBottomTabBar {...props} />}
  >
    <BottomTabNavigator.Screen
      name="AgencySubscriptionNavigator"
      component={AgencySubscriptionNavigator}
    />
    <BottomTabNavigator.Screen name="CommonProfileNavigator" component={CommonProfileNavigator} />
    <BottomTabNavigator.Screen
      name="CommonNotificationPermissionsScreen"
      component={CommonNotificationPermissionsScreen}
      initialParams={{ hideSettings: true }}
    />
  </BottomTabNavigator.Navigator>
);

export { AgencySubscriptionAccountActivationNavigator };
