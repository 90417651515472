import React, { useCallback, useEffect, useRef, useState, SetStateAction } from 'react';
import { RefreshControl, RefreshControlProps, View, ViewToken } from 'react-native';

import { StyleService, Text } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native-gesture-handler';

import { MemoizedCourierAvailabilitiesCardItem } from './courier-availabilities-card-item.component';
import { Availability, OrganizationType } from '../../../generated-graphql-types';
import { LocalUserContext } from '../../../types';
import { MapViewCoordinate } from '../map-view-with-marker.component';

type Props = {
  isLoading: boolean;
  availabilities: Partial<Availability>[];
  refetch: RefreshControlProps['onRefresh'];
  setMapViewRegion?: React.Dispatch<SetStateAction<MapViewCoordinate | undefined>>;
  mapViewRegion?: MapViewCoordinate;
  currentUserContext: LocalUserContext;
};

export const CourierAvailabilitiesList = ({
  isLoading,
  availabilities,
  refetch,
  mapViewRegion,
  setMapViewRegion,
  currentUserContext,
}: Props) => {
  const { t } = useTranslation();
  const [currentItem, setCurrentItem] = useState<Availability | null>(null);

  const findAvailability = useCallback(() => {
    if (currentItem) {
      const { endTime, isAdHocAvailability, lastDeviceLocationWithLocationForUser, lastLocation } =
        currentItem;

      if (
        currentUserContext.organizationType === OrganizationType.COURIER &&
        isAdHocAvailability &&
        endTime &&
        lastDeviceLocationWithLocationForUser?.location?.locationGeometry?.location &&
        (!mapViewRegion ||
          mapViewRegion.latitude !==
            lastDeviceLocationWithLocationForUser.location.locationGeometry.location.lat ||
          mapViewRegion.longitude !==
            lastDeviceLocationWithLocationForUser.location.locationGeometry.location.lng) &&
        setMapViewRegion
      ) {
        setMapViewRegion({
          latitude: lastDeviceLocationWithLocationForUser.location.locationGeometry.location.lat,
          longitude: lastDeviceLocationWithLocationForUser.location.locationGeometry.location.lng,
        });
      } else if (
        lastLocation?.locationGeometry?.location &&
        (!mapViewRegion ||
          mapViewRegion.latitude !== lastLocation.locationGeometry.location.lat ||
          mapViewRegion.longitude !== lastLocation.locationGeometry.location.lng) &&
        setMapViewRegion
      ) {
        setMapViewRegion({
          latitude: lastLocation.locationGeometry.location.lat,
          longitude: lastLocation.locationGeometry.location.lng,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, mapViewRegion]);

  useEffect(() => {
    findAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  const handleViewableItemsChanged = useCallback((data: any) => {
    const { viewableItems }: { viewableItems: ViewToken[] } = data;
    if (viewableItems?.length) {
      setCurrentItem(viewableItems[0].item);
    }
  }, []);

  const viewabilityConfigCallbackPairs = useRef([
    {
      viewabilityConfig: {
        itemVisiblePercentThreshold: 100,
      },
      onViewableItemsChanged: handleViewableItemsChanged,
    },
  ]);

  const renderItem = ({ item }: { item: Partial<Availability> }) => (
    <MemoizedCourierAvailabilitiesCardItem
      availability={item}
      setMapViewRegion={setMapViewRegion}
      currentUserContext={currentUserContext} // Is never undefined or null in this case.
    />
  );

  return (
    <View style={styles.container}>
      <FlatList
        refreshControl={
          <RefreshControl
            onRefresh={refetch}
            refreshing={isLoading}
            title="Courier Availabilities"
          />
        }
        ListEmptyComponent={
          <Text selectable={true} style={styles.emptyContainer}>
            {t('There are no Availabilities yet.') as string}
          </Text>
        }
        scrollEnabled
        scrollToOverflowEnabled
        style={styles.container}
        contentContainerStyle={styles.contentContainerStyle}
        data={availabilities}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
        renderItem={renderItem}
        keyExtractor={(item) => item.id as string}
      />
    </View>
  );
};

const styles = StyleService.create({
  container: {
    width: '100%',
    flex: 1,
  },
  contentContainerStyle: {
    paddingHorizontal: 10,
  },
  emptyContainer: {
    padding: 20,
  },
  noResultsView: {
    paddingTop: 20,
  },
});
