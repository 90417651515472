import React, { useContext, useEffect } from 'react';
import { Platform, View, Linking } from 'react-native';

import { useStyleSheet, Text } from '@ui-kitten/components';
import { useTranslation, Trans } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { themedStyles } from './styles';
import { globalStyle } from '../../../common/style';
import { SubscriptionPlanChoose } from '../../../components/common/SubscriptionPlanChoose';
import { TopNavigationWithoutAccessories } from '../../../components/top-navigation-without-accessories.component';
import AppUserContext from '../../../contexts/AppUserContext';
import { OrganizationUserRoleType } from '../../../generated-graphql-types';

export const AgencySubscriptionSelectPlanScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const styles = useStyleSheet(themedStyles);

  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      appUserContext.currentUserContext?.organizationSubscriptionPlan?.id &&
      appUserContext.currentUserContext?.organizationUser?.roleType ===
        OrganizationUserRoleType.OWNER
    ) {
      showMessage({
        message: t('common.subscriptionExpiredWarningMessageTitle', { defaultValue: 'Attention!' }),
        description: t('common.subscriptionExpiredWarningMessageTextOwner', {
          defaultValue:
            'The boarding gate is closed. This usually happens when the Agency does not have an active subscription.\n Please book a subscription plan to continue using the app.',
        }) as string,
        autoHide: true,
        hideOnPress: true,
        type: 'warning',
        duration: 8000,
      });
    }
  }, [
    appUserContext.currentUserContext?.organizationSubscriptionPlan?.id,
    appUserContext.currentUserContext?.organizationUser?.roleType,
    t,
  ]);

  const handleOpenWebApp = () => {
    Linking.openURL('https://app.priojet.com');
  };

  const renderNonOwnerWarning = () => (
    <View
      style={[
        styles.warning,
        globalStyle.flex1,
        globalStyle.paddingBottom30,
        globalStyle.marginBottom30,
      ]}
    >
      <Text category="h5" style={styles.warningTitle}>
        {t('common.subscriptionExpiredWarningMessageTitle', { defaultValue: 'Attention!' })}
      </Text>
      <Text style={styles.warningText}>
        {
          t('common.subscriptionExpiredWarningBoardingGate', {
            defaultValue: 'The boarding gate is closed.',
          }) as string
        }
      </Text>
      {Platform.OS === 'web' && (
        <Text style={styles.warningText}>
          {
            t('common.subscriptionExpiredWarningMessageText1', {
              defaultValue:
                'This usually happens when the Agency does not have an active subscription.',
            }) as string
          }
        </Text>
      )}
      <Text style={styles.warningText}>
        {
          t('common.subscriptionExpiredWarningMessageText2', {
            defaultValue: 'Contact your agency owner for more information.',
          }) as string
        }
      </Text>
    </View>
  );

  const renderMobileWarning = () => (
    <View
      style={[
        styles.warning,
        globalStyle.flex1,
        globalStyle.paddingBottom30,
        globalStyle.marginBottom30,
      ]}
    >
      <Text category="h5" style={styles.warningTitle}>
        {t('common.subscriptionExpiredWarningMessageTitle', { defaultValue: 'Attention!' })}
      </Text>
      <Text style={styles.warningSubtitle}>
        {
          t('common.subscriptionExpiredWarningBoardingGate', {
            defaultValue: 'The boarding gate is closed.',
          }) as string
        }
      </Text>
      <Text style={styles.warningText}>
        <Trans
          i18nKey="common.subscriptionExpiredWarningMobileMessageText1"
          defaults="Please access the online web app to view and manage Agency account settings on <0>https://app.priojet.com</0>"
          components={[
            <Text
              style={[globalStyle.fontSize18, globalStyle.fontLatoBold]}
              onPress={handleOpenWebApp}
            />,
          ]}
        />
      </Text>
    </View>
  );

  const renderContent = () => {
    if (Platform.OS === 'web') {
      if (
        !!appUserContext.currentUserContext?.organizationType &&
        appUserContext.currentUserContext?.organizationUser?.roleType ===
          OrganizationUserRoleType.OWNER
      ) {
        return (
          <SubscriptionPlanChoose
            organizationType={appUserContext.currentUserContext?.organizationType}
          />
        );
      }
      return renderNonOwnerWarning();
    }
    if (
      appUserContext.currentUserContext?.organizationUser?.roleType !==
      OrganizationUserRoleType.OWNER
    ) {
      return renderNonOwnerWarning();
    }
    return renderMobileWarning();
  };

  return (
    <>
      <TopNavigationWithoutAccessories
        title={Platform.OS === 'web' ? t('common.selectPlan', { defaultValue: 'Select plan' }) : ''}
      />
      {renderContent()}
    </>
  );
};
