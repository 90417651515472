import Constants from 'expo-constants';
import * as ExpoDocumentPicker from 'expo-document-picker';
import * as ExpoImagePicker from 'expo-image-picker';

import { getAuthorizationHeaders } from '../apollo/create-auth-link-with-headers';

export const uploadFileToServer = async ({
  side,
  document,
  documentCategory,
  documentType,
  objectId,
  restEndpointUrl,
}: {
  side?: string;
  document: ExpoImagePicker.ImagePickerAsset;
  restEndpointUrl: string;
  documentType: string;
  documentCategory: string;
  objectId?: string;
}): Promise<null | any> => {
  const authHeaders = await getAuthorizationHeaders();
  const lastIndexOfDot = document.uri.lastIndexOf('.');
  const lastIndexOfSlash = document.uri.lastIndexOf('/');
  const fileType = document.uri.substring(lastIndexOfDot + 1);
  const fileName = document.fileName || document.uri.substring(lastIndexOfSlash + 1);
  const response = await fetch(restEndpointUrl, {
    method: 'post',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      documentType,
      file: document?.base64,
      fileType,
      fileName,
      uri: document?.uri,
      exif: document?.exif,
      height: document?.height,
      width: document?.width,
      side,
      documentCategory,
      objectId,
    }),
  });
  if (response) {
    if (response.status !== 200 && response.status !== 201) {
      return null;
    }

    return response.json();
  }
};

export const pickImageOnFrontEnd = async () => {
  const result = await ExpoImagePicker.launchImageLibraryAsync({
    mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    quality: 1,
    base64: true,
  });
  if (!result.canceled && result.assets?.length) {
    return result.assets[0];
  }
  return null;
};

export const pickPDFOnFrontEnd = async () => {
  const result = await ExpoDocumentPicker.getDocumentAsync({
    type: 'application/pdf',
    multiple: false,
  });
  if (!result.canceled && result?.assets?.[0]) {
    const asset = result.assets[0];
    return asset;
  }
  return null;
};

export const getBase64EncodedFileString = async (
  file: File | undefined,
): Promise<string | undefined> => {
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = await function () {
      return reader.result;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reader.onerror = function (error) {};
  }
  return undefined;
};

/** UPLOAD DOCUMENTS  */
const uploadDocumentToServer = async (side: string, document: any) => {
  const restEndpointUrl = Constants.expoConfig?.extra?.priojetBackendUploadDocumentEndpoint;

  let payload = document;
  let documentType = 'IMAGE';
  if (document.mimeType === 'application/pdf') {
    payload = {
      cancelled: false,
      uri: document.uri,
      base64: document.base64,
      width: 1,
      height: 1,
      exif: {
        lastModified: document.lastModified,
        size: document.size,
        name: document.name,
        output: document.output,
        mimeType: 'application/pdf',
      },
    };
    documentType = 'PDF';
  }
  const uploadResponse: any = await uploadFileToServer({
    side,
    document: payload,
    restEndpointUrl,
    documentType,
    documentCategory: 'user-documents',
  });
  return uploadResponse;
};

export const uploadDocumentsToServer = async (documents: any[]): Promise<any[] | null> => {
  const uploaded = [];
  for (let i = 0; i < documents.length; i++) {
    const item = documents[i];
    const doc = await uploadDocumentToServer(item.key, item.document);
    uploaded.push(doc);
  }

  if (uploaded.length) {
    return uploaded.map((item, index) => {
      const key = documents[index].fieldKey;
      return {
        [`originalDocument${key}FilePath`]: item.originalBlobName,
        [`originalDocument${key}Metadata`]: item.originalStoredAssetMetadata,
        [`compressedDocument${key}FilePath`]: item.compressedBlobName,
        [`compressedDocument${key}Metadata`]: item.compressedStoredAssetMetadata,
        documentContainerName: item.containerName,
      };
    });
  }
  return null;
};
