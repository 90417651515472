import * as ExpoNotifications from 'expo-notifications';
import {
  IosAlertStyle,
  IosAllowsPreviews,
  IosAuthorizationStatus,
  NotificationPermissionsStatus,
} from 'expo-notifications';

import 'react-native-get-random-values';
import {
  DevicePermissionsNotificationsAndroidInput,
  DevicePermissionsNotificationsInput,
  DevicePermissionsNotificationsIosAlertStyle,
  DevicePermissionsNotificationsIosAllowsPreviews,
  DevicePermissionsNotificationsIosAuthorizationStatus,
  DevicePermissionStatus,
} from '../../graphql-types';

export const convertExpoNotificationPermissionToDeviceNotificationPermissionsInput = (
  notificationPermissions: NotificationPermissionsStatus,
): DevicePermissionsNotificationsInput => {
  return {
    ...notificationPermissions,
    status:
      DevicePermissionStatus[
        notificationPermissions.status.toUpperCase() as DevicePermissionStatus
      ],
    ios: notificationPermissions.ios
      ? {
          ...notificationPermissions.ios,
          alertStyle: IosAlertStyle[
            notificationPermissions.ios.alertStyle
          ] as DevicePermissionsNotificationsIosAlertStyle,
          allowsPreviews: notificationPermissions.ios.allowsPreviews
            ? (IosAllowsPreviews[
                notificationPermissions.ios.allowsPreviews
              ] as DevicePermissionsNotificationsIosAllowsPreviews)
            : undefined,
          status: IosAuthorizationStatus[
            notificationPermissions.ios.status
          ] as DevicePermissionsNotificationsIosAuthorizationStatus,
        }
      : undefined,
  };
};

/* @deprecated Use convertExpoNotificationPermissionToDeviceNotificationPermissionsInput instead. */
export const convertExpoNotificationPermissionToDeviceNotificationPermissionsInputOld = (
  expoDeviceNotificationPermissions: NotificationPermissionsStatus,
): DevicePermissionsNotificationsInput => {
  const deviceNotificationPermissions: any = {
    ...expoDeviceNotificationPermissions,
  };

  if (deviceNotificationPermissions.notifications.status) {
    deviceNotificationPermissions.notifications.status =
      deviceNotificationPermissions.notifications.status
        .toString()
        .toUpperCase() as DevicePermissionStatus;
  }

  if (
    deviceNotificationPermissions &&
    deviceNotificationPermissions.notifications &&
    deviceNotificationPermissions.notifications.android
  ) {
    deviceNotificationPermissions.notifications.android = deviceNotificationPermissions
      .notifications.android as DevicePermissionsNotificationsAndroidInput;
  }

  if (
    deviceNotificationPermissions &&
    deviceNotificationPermissions.notifications &&
    deviceNotificationPermissions.notifications.ios
  ) {
    deviceNotificationPermissions.notifications.ios.alertStyle = ExpoNotifications
      .IosAlertStyle[
      deviceNotificationPermissions.notifications.ios.alertStyle
    ] as DevicePermissionsNotificationsIosAlertStyle;
    deviceNotificationPermissions.notifications.ios.allowsPreviews = ExpoNotifications
      .IosAllowsPreviews[
      deviceNotificationPermissions.notifications.ios.allowsPreviews
    ] as DevicePermissionsNotificationsIosAllowsPreviews;
    deviceNotificationPermissions.notifications.ios.status = ExpoNotifications
      .IosAuthorizationStatus[
      deviceNotificationPermissions.notifications.ios.status
    ] as DevicePermissionsNotificationsIosAuthorizationStatus;
  }

  return deviceNotificationPermissions as DevicePermissionsNotificationsInput;
};
