import { useEffect, useMemo, useState, useContext, useRef } from 'react';

import { useQuery } from '@apollo/client';
import { useIsFocused } from '@react-navigation/core';
import * as Location from 'expo-location';
import { LocationAccuracy } from 'expo-location';
import { DateTime } from 'luxon';

import { QUERY_AVAILABILITIES_FOR_CONNECTED_COURIER_ORGANIZATIONS } from '../../../../apollo/graphql-queries';
import { DEFAULT_MAP_LOCATION } from '../../../../constants/Map';
import AppUserContext from '../../../../contexts/AppUserContext';
import { Availability, AvailabilityFilterType } from '../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';
import { AvailabilityMapType } from '../types';

export const useHook = () => {
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();
  const isFocused = useIsFocused();

  const timeIntervalRef = useRef<any>();

  const [currentTime, setCurrentTime] = useState(DateTime.now());
  const [initialRegion, setInitialRegion] = useState<any>();
  const [selectedAvailability, setSelectedAvailability] = useState<AvailabilityMapType | undefined>(
    undefined,
  );
  const [selectedAvailabilities, setSelectedAvailabilities] = useState<
    AvailabilityMapType[] | undefined
  >(undefined);
  const [selectedFilterType, setSelectedFilterType] = useState<AvailabilityFilterType[]>([]);

  const getCurrentLocation = async () => {
    setTimeout(() => {
      setInitialRegion((prev: any) =>
        prev
          ? prev
          : {
              ...DEFAULT_MAP_LOCATION,
              latitudeDelta: 6.0,
              longitudeDelta: 6.0,
            },
      );
    }, 1000);

    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      setInitialRegion({
        ...DEFAULT_MAP_LOCATION,
        latitudeDelta: 6.0,
        longitudeDelta: 6.0,
      });
      return;
    }

    let location = await Location.getCurrentPositionAsync({ accuracy: LocationAccuracy.Lowest });
    if (location?.coords?.latitude && location?.coords?.longitude) {
      setInitialRegion({
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        latitudeDelta: 6.0,
        longitudeDelta: 6.0,
      });
    }
  };

  const { data } = useQuery(QUERY_AVAILABILITIES_FOR_CONNECTED_COURIER_ORGANIZATIONS, {
    variables: {
      agencyOrganizationId: appUserContext.currentUserContext?.organization?.id,
      filterType: selectedFilterType?.length ? selectedFilterType : undefined,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    pollInterval: isFocused && isBackendReachable ? 1000 * 30 : 0,
    skip: !isFocused,
  });

  const availabilities: AvailabilityMapType[] = useMemo((): AvailabilityMapType[] => {
    if (data?.availabilitiesForConnectedCourierOrganizations?.length) {
      return data.availabilitiesForConnectedCourierOrganizations.map((current: Availability) => {
        if (current.startTime > currentTime) {
          return { ...current, type: 'upcoming' };
        }
        if (!current.endTime || current.endTime > currentTime) {
          return { ...current, type: 'active' };
        }
        return { ...current, type: 'past' };
      });
    }

    return [];
  }, [currentTime, data?.availabilitiesForConnectedCourierOrganizations]);

  const handleFilterSelect = (value: AvailabilityFilterType[]) => {
    setSelectedAvailabilities(undefined);
    setSelectedFilterType(value);
  };

  const handleSelectAvailabilities = (selected: AvailabilityMapType[]) => {
    const selectedAvailabilitiesIds = selectedAvailabilities?.map((item) => item.id);
    const selectedIds = selected?.map((item) => item.id);

    if (JSON.stringify(selectedAvailabilitiesIds) !== JSON.stringify(selectedIds)) {
      setSelectedAvailability(undefined);
      setSelectedAvailabilities([]);
      setTimeout(() => {
        setSelectedAvailability(selected?.[0]);
        setSelectedAvailabilities(selected);
      }, 300);
    } else {
      setSelectedAvailability(selected?.[0]);
      setSelectedAvailabilities(selected);
    }
  };

  const handleSelectAvailability = (availability: AvailabilityMapType) => {
    setSelectedAvailability(availability);
  };

  const handleLocationSelect = (location: { latitude: number; longitude: number }) => {
    setInitialRegion((prev: any) => ({ ...prev, ...location }));
    handleResetAvailabilities();
  };

  const handleResetAvailabilities = () => {
    setSelectedAvailability(undefined);
    setSelectedAvailabilities(undefined);
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    timeIntervalRef.current = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, 1000 * 5);
    return () => clearInterval(timeIntervalRef.current);
  }, []);

  return {
    availabilities,
    handleFilterSelect,
    handleLocationSelect,
    handleResetAvailabilities,
    handleSelectAvailability,
    handleSelectAvailabilities,
    initialRegion,
    selectedAvailability,
    selectedAvailabilities,
    selectedFilterType,
  };
};
