import React from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';

import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';

import ContentBox from './content-box.component';

const ContentBoxTextTitle = ({
  title,
  subtitle,
  Content,
  style,
  titleStyle,
}: {
  title: string;
  subtitle?: string;
  Content: JSX.Element;
  style?: ViewStyle[] | ViewStyle;
  titleStyle?: TextStyle[] | TextStyle;
}) => {
  const styles = useStyleSheet(themedStyles);

  const renderSubtitle = () => {
    if (subtitle) {
      return (
        <Text selectable={true} style={styles.subTitleText}>
          {subtitle}
        </Text>
      );
    }
  };

  return (
    <ContentBox
      style={style}
      Title={
        <View>
          <Text selectable={true} style={[styles.titleText, titleStyle]}>
            {title}
          </Text>
        </View>
      }
      SubTitle={renderSubtitle()}
      Content={Content}
    />
  );
};

const themedStyles = StyleService.create({
  titleText: {
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
  },
  subTitleText: {
    fontSize: 12,
  },
});

export default ContentBoxTextTitle;
