import { StyleService } from '@ui-kitten/components';

export const styles = StyleService.create({
  firstMessageContainer: {
    alignItems: 'center',
    padding: 40,
    backgroundColor: 'background-basic-color-1',
  },
  firstMessageTitle: {
    fontSize: 18,
    marginBottom: 6,
    color: 'text-basic-color',
    fontStyle: 'italic',
    fontWeight: 'bold',
  },
  firstMessageText: {
    color: 'text-basic-color',
    maxWidth: 240,
    marginBottom: 20,
    textAlign: 'center',
  },
  userJoinLeftMessageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  userJoinLeftMessageText: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'background-basic-color-3',
    borderRadius: 16,
    marginBottom: 5,
    padding: 10,
    paddingHorizontal: 20,
  },
  dayContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  dayLine: {
    flex: 1,
    backgroundColor: 'text-hint-color',
    height: 1,
    marginHorizontal: 15,
    opacity: 0.2,
  },
  dayText: {
    color: 'text-hint-color',
    fontWeight: '600',
    fontSize: 13,
  },
  whiteText: {
    color: 'white',
  },
});
