import React, { useMemo } from 'react';
import { Image, View } from 'react-native';

import { Button, CheckBox, StyleService, Text } from '@ui-kitten/components';
import * as ExpoDocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import * as ExpoImagePicker from 'expo-image-picker';

import { RemoveIcon, UploadIcon } from '../icons.component';

export const CourierAddDocumentFile = ({
  allowBackPage = false,
  allowPDF = true,
  identityPlaceholder = false,
  documentBack,
  documentFront,
  hasBackPage = false,
  labelImage: labelImageProp,
  labelPDF = 'Upload PDF',
  onBackPageChange,
  onSelectDocument,
}: {
  allowBackPage?: boolean;
  allowImage?: boolean;
  allowPDF?: boolean;
  identityPlaceholder?: boolean;
  documentBack?: any;
  documentFront?: any;
  hasBackPage?: boolean;
  labelImage?: string;
  labelPDF?: string;
  onBackPageChange?: (value: boolean) => void;
  onSelectDocument: (side: 'front' | 'back', result: any) => void;
}): React.ReactElement => {
  const handleCheckboxPress = () => {
    if (onBackPageChange) {
      onBackPageChange(!hasBackPage);
      onSelectDocument('back', null);
    }
  };

  const imageLabel = useMemo(() => {
    if (labelImageProp) {
      return labelImageProp;
    }
    if (!allowPDF) {
      return 'Upload Document (only image files allowed)';
    }
    return 'Upload Image';
  }, [allowPDF, labelImageProp]);

  const handlePickImage = (side: 'front' | 'back') => async () => {
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
      base64: true,
    });

    if (!result.canceled && result.assets?.length) {
      onSelectDocument(side, result.assets[0]);
    }
  };

  const handlePickPDF = (side: 'front' | 'back') => async () => {
    const result = await ExpoDocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      multiple: false,
    });
    if (!result.canceled && result?.assets?.[0]) {
      const asset = result.assets[0];
      let base64 = asset.uri;
      if (!asset.uri.startsWith('data:application')) {
        base64 = await FileSystem.readAsStringAsync(base64, {
          encoding: FileSystem.EncodingType.Base64,
        });
      }
      let document = {};
      if (asset.uri) {
        document = { ...asset, base64 };
      }
      onSelectDocument(side, document);
    }
  };

  const handleRemoveDocument = (side: 'front' | 'back') => () => {
    onSelectDocument(side, null);
  };

  const renderDocument = (document: any) => {
    if (!document) {
      return null;
    }
    if (document.mimeType === 'application/pdf') {
      return (
        <>
          <Text>
            <Text style={[styles.captionText, styles.marginTop10]}>Selected document: </Text>
            {document.name}
          </Text>
        </>
      );
    }
    if (document.uri) {
      return (
        <>
          <Image source={{ uri: document.uri }} style={styles.cardImage} />
        </>
      );
    }
    return null;
  };

  const renderSide = ({ label, side, document }: any) => (
    <>
      {allowBackPage && <Text style={styles.captionText}>{label}</Text>}
      {renderDocument(document)}

      {identityPlaceholder && !document && (
        <Image
          source={require('../../assets/images/placeholder-front-id.png')}
          style={styles.cardImage}
        />
      )}

      {document ? (
        <Button
          accessoryLeft={RemoveIcon}
          style={styles.button}
          onPress={handleRemoveDocument(side)}
        >
          Remove Document
        </Button>
      ) : (
        <>
          <Button accessoryLeft={UploadIcon} style={styles.button} onPress={handlePickImage(side)}>
            {imageLabel}
          </Button>
          {allowPDF && (
            <Button accessoryLeft={UploadIcon} style={styles.button} onPress={handlePickPDF(side)}>
              {labelPDF}
            </Button>
          )}
        </>
      )}
    </>
  );

  return (
    <View style={styles.container}>
      {renderSide({ label: 'FRONT SIDE', side: 'front', document: documentFront })}

      {allowBackPage && (
        <>
          <CheckBox style={styles.checkBox} checked={!hasBackPage} onChange={handleCheckboxPress}>
            My document has only one page.
          </CheckBox>
          {hasBackPage && (
            <View style={styles.marginTop20}>
              {renderSide({ label: 'BACK SIDE', side: 'back', document: documentBack })}
            </View>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleService.create({
  container: {
    margin: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  captionText: {
    fontSize: 14,
    fontFamily: 'Lato_700Bold',
  },
  cardImage: {
    marginTop: 10,
    marginBottom: 5,
    width: '100%',
    height: 300,
    resizeMode: 'contain',
  },
  button: {
    marginVertical: 5,
  },
  checkBox: {
    margin: 5,
  },
});
