import React, { useContext, useEffect, useState } from 'react';
import { ScrollView, View, ActivityIndicator } from 'react-native';

import { Text } from '@ui-kitten/components';
import Constants from 'expo-constants';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../common/style';
import { TopNavigationWithBackButton } from '../../components/top-navigation-back-button.component';
import { TopNavigationBackToProfileButton } from '../../components/top-navigation-back-to-profile-button.component';
import AppUserContext from '../../contexts/AppUserContext';
import { OrganizationType } from '../../graphql-types';
import { generateHelloNextToken } from '../../utils/hellonext.util';

export const CommonChangelogHelloNextScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string | undefined>();

  const organizationType = appUserContext?.currentUserContext?.organizationType;
  const miniAppUrl = Constants.expoConfig?.extra?.priojetHelloNextMiniAppEndpoint;
  const uri: string =
    miniAppUrl +
    'c/' +
    '?organization=' +
    organizationType +
    '&token=' +
    token +
    '&board=changelog';

  const func = async () => {
    try {
      const backendEndpointForJWTSign =
        Constants.expoConfig?.extra?.priojetBackendHelloNextJwtSignEndpoint;
      const res = await generateHelloNextToken(backendEndpointForJWTSign);
      setToken(res.jwtResponse);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    func();
  }, []);

  const renderSafariWarning = () => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
    if (isSafari) {
      return (
        <View style={[globalStyle.width100Percent, globalStyle.alignItemsCenter]}>
          <Text
            style={[
              globalStyle.padding20,
              globalStyle.paddingTop10,
              globalStyle.textAlignCenter,
              globalStyle.fontSize13,
              { maxWidth: 660 },
            ]}
          >
            {t('isSafariWarningFeatureRequest', {
              defaultValue:
                'If you do not see feature request properly or you see a login form, it could be an issue with privacy settings. You can disable "Prevent cross-site tracking" in your "Settings > Privacy" and reload this page.',
            })}
          </Text>
        </View>
      );
    }
    return <></>;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <View
          style={[
            globalStyle.flex1,
            globalStyle.justifyContentCenter,
            globalStyle.alignItemsCenter,
          ]}
        >
          <ActivityIndicator />
        </View>
      );
    }
    return (
      <>
        <iframe style={{ height: '100%', border: 'none', margin: 'none' }} src={uri} />
        {renderSafariWarning()}
      </>
    );
  };

  if (!uri) {
    return (
      <>
        <TopNavigationWithBackButton title="Changelog" navigation={navigation} />
        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
          <Text style={globalStyle.padding30}>
            This feature is not yet implemented for{' '}
            {organizationType
              ? OrganizationType[organizationType]
              : ' (unsupported Organization Type) '}{' '}
            users.
          </Text>
        </ScrollView>
      </>
    );
  }
  return (
    <>
      <TopNavigationBackToProfileButton
        title="Changelog"
        navigation={navigation}
        userContext={appUserContext.currentUserContext}
      />
      {renderContent()}
    </>
  );
};
