import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  cardImage: {
    width: 40,
    height: 40,
    marginRight: 15,
  },
  contentBox: {
    paddingVertical: 10,
    marginBottom: 10,
  },
  cardsInternalLayout: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
    alignContent: 'space-between',
    alignItems: 'stretch',
  },
  marginVertical20: {
    marginVertical: 20,
  },
});
