import { OrganizationUserRoleType } from '../generated-graphql-types';

export const getMinimumAvailableUserRoles = (
  min: OrganizationUserRoleType,
  roles?: OrganizationUserRoleType[],
) => {
  const availableRoles = roles
    ? [...roles]
    : [
        OrganizationUserRoleType.OWNER,
        OrganizationUserRoleType.ADMIN,
        OrganizationUserRoleType.STAFF,
      ];
  const index = availableRoles.findIndex((item) => item === min);
  return availableRoles.slice(0, index + 1);
};

export const getMaxAvailableUserRoles = (
  min: OrganizationUserRoleType,
  roles?: OrganizationUserRoleType[],
) => {
  const availableRoles = roles
    ? [...roles]
    : [
        OrganizationUserRoleType.OWNER,
        OrganizationUserRoleType.ADMIN,
        OrganizationUserRoleType.STAFF,
      ];
  const index = availableRoles.reverse().findIndex((item) => item === min);
  return availableRoles.slice(0, index + 1);
};
