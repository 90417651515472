import React, { useCallback, useEffect, useState, Fragment } from 'react';

import { GoogleMap, useJsApiLoader, Marker, MarkerClustererF } from '@react-google-maps/api';
import { StyleService } from '@ui-kitten/components';
import { DateTime } from 'luxon';

import { DEFAULT_MAP_LOCATION } from '../../../../../constants/Map';
import { getGoogleMapsApiKey } from '../../../../../utils/google';
import { AvailabilityMapType } from '../../types';
import './map.css';

export const markerAvailabilityImages: any = {
  active: require('../../../../../assets/images/markerGreen.png'),
  upcoming: require('../../../../../assets/images/markerBlue.png'),
  past: require('../../../../../assets/images/markerGray.png'),
  selected: require('../../../../../assets/images/markerRed.png'),
};

const AvailabilityMapComponent = ({
  availabilities,
  initialRegion: initialRegionProp,
  onSelectAvailabilities,
  selectedAvailability,
}: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getGoogleMapsApiKey('web'),
  });

  const [center, setCenter] = useState({
    lat: DEFAULT_MAP_LOCATION.latitude,
    lng: DEFAULT_MAP_LOCATION.longitude,
  });
  const [initialRegion, setInitialRegion] = useState(initialRegionProp);
  const [locationChangeTime] = useState<any>(DateTime.now());
  const [map, setMap] = useState<any>(null);
  const [zoom, setZoom] = useState(6);

  const handleMarkerPress =
    (availability: AvailabilityMapType, nextZoom = 10) =>
    () => {
      if (availability.lastLocation?.locationGeometry) {
        const { location } = availability.lastLocation?.locationGeometry;
        onSelectAvailabilities([availability]);
        setCenter({ lat: location.lat, lng: location.lng });
        let currentZoom = map?.zoom;
        setZoom(currentZoom < nextZoom ? nextZoom : currentZoom);
      }
    };

  const handleClusterPress = (data: any) => {
    const markers = data?.getMarkers();
    setCenter({ lat: markers[0].position.lat(), lng: markers[0].position.lng() });
    let currentZoom = map?.zoom || 14;
    if (currentZoom < 15) {
      if (currentZoom + 2 > 15) {
        currentZoom = 15;
      } else {
        currentZoom = currentZoom + 2;
      }
    }

    setZoom(currentZoom);
    if (currentZoom >= 15) {
      const selectedAvailabilities = markers.map((marker: any) => {
        const availability = availabilities.find(
          (item: AvailabilityMapType) => item.id === marker.label.availabilityId,
        );
        return availability;
      });
      onSelectAvailabilities(selectedAvailabilities);
    }
  };

  const onLoad = useCallback((instance: any) => {
    setMap(instance);
  }, []);

  const handleZoomChanged = () => {
    if (map?.zoom) {
      setZoom(map.zoom);
    }
  };

  useEffect(() => {
    if (
      initialRegion.latitude !== initialRegionProp.latitude ||
      initialRegion.longitude !== initialRegionProp.longitude
    ) {
      setInitialRegion(initialRegionProp);
      setCenter({ lat: initialRegionProp.latitude, lng: initialRegionProp.longitude });
      let currentZoom = map?.zoom || 6;
      if (currentZoom < 10 && locationChangeTime < DateTime.now().minus({ seconds: 5 })) {
        setZoom(10);
      }
    }
  }, [initialRegion, initialRegionProp, locationChangeTime, map]);

  if (isLoaded) {
    return (
      <GoogleMap
        mapContainerStyle={themedStyles.map}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onZoomChanged={handleZoomChanged}
        clickableIcons={false}
        options={{
          maxZoom: 14,
          minZoom: 4,
          fullscreenControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControlOptions: {
            position: 7.0,
          },
        }}
      >
        {availabilities && availabilities.length && (
          <MarkerClustererF
            averageCenter
            onClick={handleClusterPress}
            zoomOnClick={false}
            maxZoom={24}
          >
            {(clusterer) => {
              return (
                <Fragment>
                  {availabilities.map((availability: AvailabilityMapType, index: number) => {
                    const isSelected = selectedAvailability?.id === availability.id;
                    let iconUrl = markerAvailabilityImages.past;
                    if (isSelected) {
                      iconUrl = markerAvailabilityImages.selected;
                    } else if (markerAvailabilityImages[availability.type]) {
                      iconUrl = markerAvailabilityImages[availability.type];
                    }

                    return (
                      <Marker
                        position={{
                          lat: availability.lastLocation?.locationGeometry.location.lat || 0,
                          lng: availability.lastLocation?.locationGeometry.location.lng || 0,
                        }}
                        key={availability.id}
                        onClick={handleMarkerPress(availability)}
                        clusterer={clusterer}
                        label={
                          {
                            text: `${availability.user.firstNames[0]}${availability.user.lastName[0]}`,
                            color: 'white',
                            fontWeight: 'bold',
                            className: 'marker-label',
                            availabilityId: availability.id,
                          } as any
                        }
                        icon={{
                          url: iconUrl,
                          scaledSize: new window.google.maps.Size(28, 34, 'px', 'px'),
                        }}
                        zIndex={isSelected ? 10 : index * -1}
                      />
                    );
                  })}
                </Fragment>
              );
            }}
          </MarkerClustererF>
        )}
      </GoogleMap>
    );
  }
  return <></>;
};

const themedStyles = StyleService.create({
  map: {
    height: '100%',
    width: '100%',
  },
  marker: {
    width: 26,
    height: 34,
  },
  markerLabel: {
    position: 'absolute',
    top: 5,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    color: 'white',
    fontSize: 13,
    fontFamily: 'Lato_700Bold',
  },
});

const compareMap = (prev: any, next: any) => {
  if (
    prev.initialRegion &&
    next.initialRegion &&
    (prev.initialRegion.latitude !== next.initialRegion.latitude ||
      prev.initialRegion.longitude !== next.initialRegion.longitude)
  ) {
    return false;
  }
  if (
    (prev.selectedAvailability && !next.selectedAvailability) ||
    (!prev.selectedAvailability && next.selectedAvailability)
  ) {
    return false;
  }
  if (prev.selectedAvailability?.id !== next.selectedAvailability?.id) {
    return false;
  }

  if (prev.availabilities?.length !== next.availabilities?.length) {
    return false;
  }

  const prevSelectedAvailabilitiesIds = prev.selectedAvailabilities?.map(
    (item: AvailabilityMapType) => item.id,
  );
  const nextSelectedAvailabilitiesIds = next.selectedAvailabilities?.map(
    (item: AvailabilityMapType) => item.id,
  );
  if (
    JSON.stringify(prevSelectedAvailabilitiesIds) !== JSON.stringify(nextSelectedAvailabilitiesIds)
  ) {
    return false;
  }

  if (prev.selectedAvailability?.id !== next.selectedAvailability?.id) {
    return false;
  }

  return true;
};

export const AvailabilityMap = React.memo(AvailabilityMapComponent, compareMap);

export default AvailabilityMap;
