import { useEffect, useMemo, useState, useContext, useRef, useCallback } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import Constants from 'expo-constants';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { UPDATE_AGENCY_ORGANIZATION_VAT_ID_MUTATION } from '../../../apollo/graphql-mutations';
import { QUERY_SUBSCRIPTION_PLANS_AGENCY } from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import ThemeContext from '../../../contexts/ThemeContext';
import { SubscriptionPlanPaddleType } from '../../../generated-graphql-types';
import {
  UpdateAgencyOrganizationVatIdVariables,
  UpdateAgencyOrganizationVatId,
} from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const navigation: any = useNavigation();
  const appUserContext = useContext(AppUserContext);
  const theme = useContext(ThemeContext);
  const isBackendReachable = useIsBackendReachable();

  const intervalRef = useRef<any>();
  const { t } = useTranslation();

  const [checkoutCompletedPlanName, setCheckoutCompletedPlanName] = useState<string>('');
  const [checkoutOpened, setCheckoutOpened] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlanPaddleType | undefined>();
  const [selectedPeriod, setSelectedPeriod] = useState<string>('month');
  const [seconds, setSeconds] = useState<number | undefined>();

  const { data } = useQuery(QUERY_SUBSCRIPTION_PLANS_AGENCY, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });
  const [updateAgencyOrganizationVatId] = useMutation<
    UpdateAgencyOrganizationVatId,
    UpdateAgencyOrganizationVatIdVariables
  >(UPDATE_AGENCY_ORGANIZATION_VAT_ID_MUTATION);

  const { agencyPlans, hasTrialPeriod } = useMemo(() => {
    const hasInactivePlan =
      !!appUserContext.currentUserContext?.organizationSubscriptionPlan &&
      !appUserContext.currentUserContext?.organizationSubscriptionPlan.isActive;

    if (data?.subscriptionPlansAgency && data.subscriptionPlansAgency?.[selectedPeriod]) {
      if (hasInactivePlan) {
        const plans = data?.subscriptionPlansAgency?.[selectedPeriod];
        if (plans?.length) {
          return {
            agencyPlans: plans.filter((item: any) => !!item.isMain && item.trialPeriod === 0),
            hasTrialPeriod: !hasInactivePlan,
          };
        }
        return { agencyPlans: plans, hasTrialPeriod: !hasInactivePlan };
      }

      const plans = data?.subscriptionPlansAgency?.[selectedPeriod];
      if (plans?.length) {
        return {
          agencyPlans: plans.filter((item: any) => !!item.isMain && item.trialPeriod > 0),
          hasTrialPeriod: !hasInactivePlan,
        };
      }
      return { agencyPlans: plans, hasTrialPeriod: !hasInactivePlan };
    }
    return { agencyPlans: [], hasTrialPeriod: !hasInactivePlan };
  }, [appUserContext.currentUserContext?.organizationSubscriptionPlan, data, selectedPeriod]);

  const successMessage = useMemo(() => {
    if (checkoutCompletedPlanName && !checkoutOpened) {
      return t('common.bookPlanSuccessfulDescription', {
        defaultValue:
          'Thank you, you have successfully booked the "{{plan}}". \nIn {{seconds}} of seconds you will be redirected to Dashboard',
        plan: checkoutCompletedPlanName,
        type: 'monthly',
        seconds,
      }) as string;
    }
    return '';
  }, [checkoutOpened, checkoutCompletedPlanName, t, seconds]);

  const handleActivationCodePress = () => {
    navigation.navigate('CommonAccountActivationScreen', {
      previousScreenName: 'AgencySubscriptionSelectPlanScreen',
    });
  };

  const handleSelectPeriod = (period: any, _index: number) => {
    setSelectedPlan(undefined);
    setSelectedPeriod(period.value);
  };

  const handleSelectPlan = (_item: any) => {
    setSelectedPlan(_item);
  };

  const handleBookNowPress = async () => {
    const win: any = window;
    const Paddle = win.Paddle;
    if (appUserContext.currentUserContext?.organization?.id) {
      Paddle.Checkout.open({
        product: selectedPlan?.planId,
        email: appUserContext.currentUserContext?.user?.email,
        passthrough: JSON.stringify({
          initialCreationDate: DateTime.now().toUTC().toString(),
          organizationId: appUserContext.currentUserContext?.organization?.id,
          userId: appUserContext.currentUserContext?.user?.id,
        }),
        marketingConsent: '1',
        message: t('helloWeAreHappyYouAreJoiningPRIOjet', {
          defaultValue: 'Hello {{name}}, we are happy you are joining PRIOjet',
          name: appUserContext.currentUserContext.user?.firstNames,
        }),
        displayModeTheme: theme.theme,
      });
      setCheckoutOpened(true);
      setCheckoutCompletedPlanName('');
    }
  };

  const handleDashboardPress = useCallback(async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setSeconds(undefined);
    }
    if (checkoutCompletedPlanName) {
      setCheckoutCompletedPlanName('');
      const res = await appUserContext.refetchCurrentUserContext();
      if (!res) {
        showMessage({
          message: t('common.error', { defaultValue: 'Error' }),
          description: t('document.somethingWentWrongWithFetchingSubscriptionPlan', {
            defaultValue:
              'Sorry, something went wrong with fetching latest data. There could be an issue with processing payment data for couple of seconds. Please try again or contact support@priojet.com.',
          }) as string,
          type: 'danger',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    }
  }, [appUserContext, checkoutCompletedPlanName, t]);

  const eventCallback = (e: any) => {
    switch (e.event) {
      case 'Checkout.Vat.Applied':
        if (e.eventData.vat_number && appUserContext.currentUserContext?.organization?.id) {
          updateAgencyOrganizationVatId({
            variables: {
              agencyOrganizationVatIdInput: {
                id: appUserContext.currentUserContext.organization.id,
                vatId: e.eventData.vat_number,
              },
            },
          });
        }
        break;
      case 'Checkout.Complete':
        setCheckoutCompletedPlanName(e.eventData.product.name);
        break;
      case 'Checkout.Close':
        setCheckoutOpened(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (checkoutCompletedPlanName && !checkoutOpened) {
      intervalRef.current = setInterval(() => {
        setSeconds((prev) => {
          if (prev === undefined) {
            return 8;
          }
          if (prev === 1) {
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }
            handleDashboardPress();
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [checkoutOpened, checkoutCompletedPlanName, handleDashboardPress, t]);

  useEffect(() => {
    if (!selectedPlan && agencyPlans[0]) {
      setSelectedPlan(agencyPlans[0]);
    }
  }, [selectedPlan, agencyPlans]);

  useEffect(() => {
    const lazyLoadFromCDN = (callback: Function) => {
      const mathJax = document.createElement('script');
      mathJax.setAttribute('src', 'https://cdn.paddle.com/paddle/paddle.js');
      mathJax.addEventListener('load', () => callback());
      document.head.appendChild(mathJax);
    };
    const onLoad = () => {
      const win = window as any;
      const Paddle = win.Paddle;
      Paddle.Environment.set(Constants.expoConfig?.extra?.paddleEnvironment);
      Paddle.Setup({
        vendor: Number(Constants.expoConfig?.extra?.paddleVendorId),
        eventCallback,
      });
    };
    lazyLoadFromCDN(onLoad);
  }, []);

  return {
    agencyPlans,
    handleActivationCodePress,
    handleBookNowPress,
    handleDashboardPress,
    handleSelectPlan,
    handleSelectPeriod,
    hasTrialPeriod,
    seconds,
    selectedPlan,
    selectedPeriod,
    successMessage,
  };
};
