import React from 'react';
import { ActivityIndicator, Platform, ScrollView, View } from 'react-native';

import { RouteProp } from '@react-navigation/core';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { Divider, Layout, Menu, MenuItem } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { globalStyle as globalStyleCommon } from '../../../common/style';
import { ForwardIcon } from '../../../components/icons.component';
import { TopNavigationWithBackButton } from '../../../components/top-navigation-back-button.component';
import i18n from '../../../i18n/i18n';
import {
  AgencyProfileNavigatorParamList,
  AppNavigatorProp,
} from '../../../navigation/app.navigator';

const DOCUMENT_FIELDS = (countData: {
  userBackgroundDocuments: number;
  userFrequentFlyerDocuments: number;
  userHealthReports: number;
  userIdentityDocuments: number;
  userOtherDocuments: number;
  userTravelDocuments: number;
  userVaccinationDocuments: number;
}) => [
  {
    key: 'UserIdentityDocument',
    field: i18n.t('menuItems.identityDocumentsWithCount', {
      defaultValue: 'Identity Documents ({{count}})',
      count: countData.userIdentityDocuments,
    }),
  },
  {
    key: 'UserTravelDocument',
    field: i18n.t('common.visaTravelAuthorizationWithCount', {
      defaultValue: 'Visa / Travel Authorization ({{count}})',
      count: countData.userTravelDocuments,
    }),
  },
  {
    key: 'UserFrequentFlyerDocument',
    field: i18n.t('menuItems.frequentFlyerProgramDocumentsWithCount', {
      defaultValue: 'Frequent Flyer Program Documents ({{count}})',
      count: countData.userFrequentFlyerDocuments,
    }),
  },
  {
    key: 'UserVaccinationDocument',
    field: i18n.t('menuItems.vaccinationCertificatesWithCount', {
      defaultValue: 'Vaccination Certificates ({{count}})',
      count: countData.userVaccinationDocuments,
    }),
  },
  {
    key: 'UserHealthReport',
    field: i18n.t('menuItems.healthTestReportsWithCount', {
      defaultValue: 'Health Test Reports ({{count}})',
      count: countData.userHealthReports,
    }),
  },
  {
    key: 'UserBackgroundDocument',
    field: i18n.t('menuItems.backgroundDocumentsWithCount', {
      defaultValue: 'Background Documents ({{count}})',
      count: countData.userBackgroundDocuments,
    }),
  },
  {
    key: 'UserOtherDocument',
    field: i18n.t('menuItems.otherDocumentsWithCount', {
      defaultValue: 'Other Documents ({{count}})',
      count: countData.userOtherDocuments,
    }),
  },
];

interface AgencyCourierDocumentsMenuScreenProps {
  children?: React.ReactNode;
  navigation: AppNavigatorProp<'AgencyCourierDocumentsMenuScreen'>;
  route: RouteProp<AgencyProfileNavigatorParamList, 'AgencyCourierDocumentsMenuScreen'>;
}

export const AgencyCourierDocumentsMenuScreen: React.FC<AgencyCourierDocumentsMenuScreenProps> = ({
  navigation,
  route,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);

  const { countData, loading, user } = useHook({ userId: route.params?.courierUserId });

  const handleBackPress = () => {
    if (Platform.OS === 'web') {
      let previousScreenName = '';
      const params: any = route.params;
      if (route.params?.previousScreenName === 'AgencyOBCAvailabilityMapScreen') {
        previousScreenName = 'AgencyOBCAvailabilityMapScreen';
      } else if (route.params?.courierUserId) {
        previousScreenName = 'AgencyConnectedCourierOrganizationsScreen';
        if (params?.previousNavigator === 'AgencyCourierAvailabilitiesNavigator') {
          previousScreenName = 'AgencyCourierAvailabilitiesScreen';
        }
        if (params?.previousNavigator === 'AgencyHomeNavigator') {
          previousScreenName = 'AgencyCourierJobsScreen';
        }
      }
      if (previousScreenName) {
        navigation.navigate('AgencyCourierUserProfileScreen', {
          userId: params?.courierUserId || '',
          agencyJobId: params?.agencyJobId,
          organizationId: params?.organizationId || '',
          previousScreenName,
          parentScreenName: params?.parentScreenName,
          previousNavigator: params?.previousNavigator,
        } as any);
      } else {
        navigation.goBack();
      }
    } else {
      navigation.goBack();
    }
  };

  return (
    <>
      <TopNavigationWithBackButton
        title={t('menuItems.courierDocuments', { defaultValue: 'Courier Documents' }) as string}
        navigation={navigation}
        onPressLeft={handleBackPress}
      />
      <Divider />
      <ScrollView>
        <View style={styles.header}>
          <Text selectable={true} style={styles.nameText}>
            {t('common.courierName', { defaultValue: 'Courier Name' }) as string}
          </Text>
          <Text selectable={true} category="h6">
            {!loading && !!user ? (
              `${user.firstNames} ${user.lastName}`
            ) : (
              <ActivityIndicator
                color={(globalStyle.colorTextBasicColor as any).color}
                style={globalStyle.marginTop2}
              />
            )}
          </Text>
        </View>

        <Layout level="1">
          <Menu>
            {DOCUMENT_FIELDS(countData).map((item) => (
              <MenuItem
                key={item.key}
                title={item.field}
                accessoryRight={ForwardIcon}
                onPress={() => {
                  const paramsData: any = {};
                  const params: any = route.params;
                  ['courierUserId', 'agencyJobId', 'parentScreenName'].forEach((key) => {
                    if (params?.[key]) {
                      if (key === 'courierUserId') {
                        paramsData.userId = params?.[key];
                      }
                      paramsData[key] = params?.[key];
                    }
                  });
                  navigation.navigate('AgencyCourierUserDocumentsScreen', {
                    ...paramsData,
                    userDocumentType: item.key,
                    previousScreenName: 'AgencyCourierDocumentsMenuScreen',
                    previousNavigator: route.params?.previousNavigator,
                  } as any);
                }}
              />
            ))}
          </Menu>
        </Layout>
      </ScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  menu: {
    flex: 1,
    margin: 0,
    backgroundColor: 'transparent',
  },
  header: {
    marginTop: 5,
    padding: 20,
    paddingBottom: 30,
  },
  nameText: {
    fontFamily: 'Lato_700Bold',
    textTransform: 'uppercase',
    color: 'color-primary-300',
    marginBottom: 3,
  },
});
