import { Dimensions, Platform } from 'react-native';

import { StyleService } from '@ui-kitten/components';

const { width: screenWidth } = Dimensions.get('screen');

let width = screenWidth;
if (Platform.OS === 'web' && screenWidth > 1000) {
  width = 1000;
} else if (Platform.OS === 'web' && screenWidth < 500) {
  width = screenWidth;
}

export const styles = StyleService.create({
  avatar: {
    height: 50,
    width: 50,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  avatarLeft: {
    marginLeft: 6,
    backgroundColor: 'background-basic-color-2',
  },
  avatarRight: {
    marginRight: 6,
    backgroundColor: 'background-basic-color-3',
  },
  messageLeft: {
    backgroundColor: 'background-basic-color-2',
  },
  messageRight: {
    backgroundColor: 'background-basic-color-3',
  },
  messageLeftTracking: {
    backgroundColor: 'color-success-900',
  },
  messageRightTracking: {
    backgroundColor: 'color-success-900',
  },
  messageError: {
    borderColor: 'color-danger-600',
    borderWidth: 1,
  },
  messageContainer: {
    marginLeft: 5,
    marginRight: 5,
    paddingTop: 6,
  },
  messageContainerLeft: {
    alignItems: 'flex-start',
    paddingBottom: 6,
  },
  messageContainerRight: {
    alignItems: 'flex-end',
  },
  message: {
    padding: 12,
    paddingHorizontal: 20,
    borderRadius: 16,
    maxWidth: width - 80,
  },
  dateText: {
    fontSize: 11,
    marginRight: 3,
    color: 'text-basic-color',
  },
  textLeft: {
    textAlign: 'left',
  },
  textRight: {
    textAlign: 'right',
  },
  nameText: {
    fontSize: 13,
    fontWeight: '600',
    color: 'text-basic-color',
  },
  borderBottomLeftRadius16: {
    borderBottomLeftRadius: 16,
  },
  borderBottomRightRadius16: {
    borderBottomRightRadius: 16,
  },
  downloadIcon: {
    width: 20,
    height: 20,
    color: 'text-basic-color',
  },
});
