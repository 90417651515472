import React from 'react';
import {
  ActivityIndicator,
  Animated,
  View,
  Image,
  Pressable,
  TouchableOpacity,
} from 'react-native';

import { Card, Icon, Text, useStyleSheet, Button } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../../common/style';
import { IconWithTextPopover } from '../../../../../components/common/IconWithTextPopover';
import { TimeAgoWithTimeZonesPopover } from '../../../../../components/common/time-ago-with-time-zones-popover.component';
import useDimensions from '../../../../../hooks/useDimensions';
import { AvailabilityMapType } from '../../types';

export const AvailabilityMapBottomCard = ({
  onResetAvailability,
  onSelectAvailability,
  selectedAvailabilities,
}: {
  onResetAvailability: () => void;
  onSelectAvailability: (availbility: AvailabilityMapType) => void;
  selectedAvailabilities?: AvailabilityMapType[];
}) => {
  const { t, i18n } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { isLargeDevice } = useDimensions();

  const {
    handleClose,
    handleCourierProfilePress,
    handleCreateJobAndRequestPress,
    hasNext,
    hasPrev,
    hasMultiple,
    handleNextPress,
    handlePrevPress,
    loading,
    transform,
    selectedAvailability,
  } = useHook({ onResetAvailability, onSelectAvailability, selectedAvailabilities });

  const renderPrimaryData = (availability: AvailabilityMapType) => {
    const user = availability?.user;
    const warningText = user.agencyCourierUserProfile?.internalComment;
    const showWarning = !!user.agencyCourierUserProfile?.markedForAttention && !!warningText;
    return (
      <View
        style={[
          globalStyle.flex1,
          globalStyle.flexRow,
          globalStyle.alignItemsCenter,
          globalStyle.paddingRight30,
        ]}
      >
        <Image
          style={styles.imageContainer as any}
          source={
            user?.profilePicture?.sasUrl
              ? { uri: user?.profilePicture?.sasUrl }
              : require('../../../../../assets/images/avatar_placeholder.png')
          }
          resizeMode="cover"
        />
        <View style={globalStyle.flex1}>
          <View
            style={[globalStyle.flexRow, globalStyle.alignItemsCenter, globalStyle.paddingRight5]}
          >
            {showWarning && (
              <View style={globalStyle.marginRight10}>
                <IconWithTextPopover text={warningText || ''} />
              </View>
            )}
            {!!user?.firstNames && (
              <Text
                category="h6"
                selectable
                style={[globalStyle.flex1, globalStyle.fontLatoBold, globalStyle.paddingRight5]}
              >
                {user.firstNames} {user.lastName}
              </Text>
            )}
          </View>
          {!!user?.email && (
            <Text selectable style={[globalStyle.flex1, globalStyle.paddingRight5]}>
              {user.email}
            </Text>
          )}

          {!!user?.courierUserProfile?.baseAirports?.length && (
            <View style={globalStyle.flexRow}>
              <Text>{t('common.baseAirports', { defaultValue: 'Base airports' })}: </Text>
              <Text selectable={true}>{user?.courierUserProfile?.baseAirports.join(', ')}</Text>
            </View>
          )}
        </View>
      </View>
    );
  };

  const renderLocation = (availability: AvailabilityMapType) => {
    if (!availability?.lastLocation?.formattedMinimalAddress) {
      return <></>;
    }
    if (availability?.type === 'upcoming') {
      return (
        <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter, globalStyle.paddingTop10]}>
          <Icon
            name="calendar-outline"
            fill={(styles.locationIcon as any).color}
            style={globalStyle.size20}
          />
          <Text selectable style={globalStyle.marginLeft5}>
            {availability.lastLocation?.formattedMinimalAddress}
          </Text>
        </View>
      );
    }
    if (availability?.type === 'past') {
      return (
        <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter, globalStyle.paddingTop10]}>
          <View style={styles.grayDot} />
          <Text style={globalStyle.marginLeft5}>
            {availability.lastLocation?.formattedMinimalAddress}
          </Text>
        </View>
      );
    }
    return (
      <View style={[globalStyle.flexRow, globalStyle.alignItemsCenter, globalStyle.paddingTop10]}>
        <View style={styles.greenDot} />
        <Text selectable style={globalStyle.marginLeft5}>
          {availability.lastLocation?.formattedMinimalAddress}
        </Text>
      </View>
    );
  };

  const renderTime = (availability: AvailabilityMapType) => {
    if (availability?.type === 'upcoming') {
      if (!availability?.startTime || !availability?.endTime) {
        return <></>;
      }
      let dateTimeStartString = '';
      let dateTimeEndString = '';
      let prefix = '';

      if (availability.startTime) {
        if (
          availability.lastLocation?.timeZoneIdentifier &&
          availability.startTime?.setZone(
            availability.lastLocation?.timeZoneIdentifier || undefined,
          ).isValid
        ) {
          dateTimeStartString = availability.startTime
            ?.setZone(availability.lastLocation?.timeZoneIdentifier || undefined)
            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {
              locale: i18n.language,
            });
          prefix = 'LT: ';
        } else if (availability.lastLocation?.timeZoneRawOffset) {
          dateTimeStartString = availability.startTime
            ?.toUTC()
            .plus({ hours: availability.lastLocation?.timeZoneRawOffset / 60 / 60 })
            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {
              locale: i18n.language,
            });
          prefix = 'LT: ';
        } else {
          dateTimeStartString = availability.startTime.toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY,
            { locale: i18n.language },
          );
        }
      }

      if (availability.endTime) {
        if (
          availability.lastLocation?.timeZoneIdentifier &&
          availability.endTime?.setZone(availability.lastLocation?.timeZoneIdentifier || undefined)
            .isValid
        ) {
          dateTimeEndString = availability.endTime
            ?.setZone(availability.lastLocation?.timeZoneIdentifier || undefined)
            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {
              locale: i18n.language,
            });
          prefix = 'LT: ';
        } else if (availability.lastLocation?.timeZoneRawOffset) {
          dateTimeEndString = availability.endTime
            ?.toUTC()
            .plus({ hours: availability.lastLocation?.timeZoneRawOffset / 60 / 60 })
            .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, {
              locale: i18n.language,
            });
          prefix = 'LT: ';
        } else {
          dateTimeEndString = availability.endTime.toLocaleString(
            DateTime.DATETIME_MED_WITH_WEEKDAY,
            { locale: i18n.language },
          );
        }
      }
      return (
        <Text style={globalStyle.paddingTop5}>
          {prefix}
          {dateTimeStartString}
          {dateTimeEndString ? ' - ' : ''}
          {dateTimeEndString}
        </Text>
      );
    }
    if (availability?.type === 'past') {
      if (!availability?.startTime) {
        return <></>;
      }
      return (
        <View style={globalStyle.paddingTop5}>
          <TimeAgoWithTimeZonesPopover
            popoverContentTitle={t('common.endedAt', { defaultValue: 'Ended At' })}
            i18nKey="common.timeAgoPopOverTextEnded"
            date={availability?.startTime}
            targetLocationName={availability.lastLocation?.formattedShortAddress || ''}
            targetTimeZoneIdentifier={availability?.lastLocation?.timeZoneIdentifier || undefined}
            hitSlop={{ top: 10, bottom: 3, left: 0, right: 0 }}
          />
        </View>
      );
    }
    return <></>;
  };

  const renderAgencyCourierGroup = (availability: AvailabilityMapType) => {
    const category =
      availability?.user?.agencyCourierUserProfile?.organizationCourierCategories?.[0];
    if (!category) {
      return <></>;
    }
    return (
      <Text style={globalStyle.paddingTop5}>
        <Text style={globalStyle.fontLatoBold}>[{category.order}]</Text> {category.label}
      </Text>
    );
  };

  const renderButtons = (availability: AvailabilityMapType): JSX.Element => (
    <View
      style={[styles.buttonsContainer, isLargeDevice ? styles.buttonsRow : styles.buttonsColumn]}
    >
      <Button
        appearance="filled"
        style={[
          globalStyle.flex1,
          isLargeDevice && globalStyle.marginLeft2,
          !isLargeDevice && globalStyle.marginBottom10,
        ]}
        onPress={handleCourierProfilePress(availability)}
      >
        {t('common.courierProfile', { defaultValue: 'Courier profile' }) as string}
      </Button>
      <Button
        appearance="filled"
        style={[globalStyle.flex1, isLargeDevice && globalStyle.marginLeft2]}
        onPress={handleCreateJobAndRequestPress(availability)}
      >
        {t('common.createJobAndRequest', { defaultValue: 'Create job and request' }) as string}
      </Button>
    </View>
  );

  const renderAvailability = (availability: AvailabilityMapType) => (
    <Card style={styles.bottomCard}>
      {renderPrimaryData(availability)}
      {!availability?.lastLocation?.formattedAddress && loading && <ActivityIndicator />}
      {renderLocation(availability)}
      {renderTime(availability)}
      {renderAgencyCourierGroup(availability)}
      {renderButtons(availability)}

      <Pressable onPress={handleClose} style={styles.close}>
        <Icon name="close-outline" fill={(styles.icon as any).color} height={30} width={30} />
      </Pressable>
    </Card>
  );

  const transformY = transform.interpolate({
    inputRange: [0, 1],
    outputRange: [400, 0],
  });

  return (
    <Animated.View style={[styles.bottom, { transform: [{ translateY: transformY }] }]}>
      {hasMultiple && (
        <View style={[globalStyle.flexRow, globalStyle.justifyContentEnd]}>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.btnArrow}
            onPress={!hasPrev ? undefined : handlePrevPress}
          >
            <View style={!hasPrev && styles.btnArrowDisabled}>
              <Icon
                name="arrow-back-outline"
                fill={(styles.icon as any).color}
                height={26}
                width={26}
              />
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.btnArrow}
            onPress={!hasNext ? undefined : handleNextPress}
          >
            <View style={!hasNext && styles.btnArrowDisabled}>
              <Icon
                name="arrow-forward-outline"
                fill={(styles.icon as any).color}
                height={26}
                width={26}
              />
            </View>
          </TouchableOpacity>
        </View>
      )}

      {!!selectedAvailability && renderAvailability(selectedAvailability)}
    </Animated.View>
  );
};
