import React, { useEffect } from 'react';
import { StyleProp, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

import {
  Icon,
  IconProps,
  IndexPath,
  MenuItem,
  OverflowMenu,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import { Text } from '@ui-kitten/components';
import * as ExpoClipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { globalStyle } from '../../common/style';
import { Location } from '../../generated-graphql-types';
import useOpenMapsLink from '../../hooks/useOpenMapsLink';

const NavigateToIcon = (props: IconProps) => <Icon {...props} name="navigation-2-outline" />;
const CopyIcon = (props: IconProps) => <Icon {...props} name="copy-outline" />;

export const LocationTextWithContextMenu = ({
  location,
  newLineAtCommaForStringValue,
  style,
  textStyle,
  textStyleNoAddress,
}: {
  location: Partial<Location>;
  textStyle?: StyleProp<TextStyle>;
  textStyleNoAddress?: StyleProp<TextStyle>;
  newLineAtCommaForStringValue?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  const { t } = useTranslation();
  const openMapsLink = useOpenMapsLink();
  const styles = useStyleSheet(themedStyles);

  const [visible, setVisible] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState<IndexPath>();

  const formattedAddress = location?.formattedAddress || 'No address given';

  useEffect(() => {
    if (selectedIndex) {
      if (selectedIndex.row === 0) {
        if (location?.formattedAddress) {
          ExpoClipboard.setStringAsync(formattedAddress);
          showMessage({
            message: t('common.Copied', { defaultValue: 'Copied!' }),
            description: t('common.addressCopySuccessMessageDescription', {
              defaultValue: 'Copied address {{formattedAddress}} to clipbaord.',
              formattedAddress,
            }) as string,
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
            type: 'success',
          });
        } else {
          showMessage({
            message: t('common.error', { defaultValue: 'Error' }),
            description: t('Could not copy address. No address given.', {
              defaultValue: 'Could not copy address. No address given.',
            }) as string,
            renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
            type: 'danger',
          });
        }
      } else if (selectedIndex.row === 1) {
        openMapsLink({
          latitude: location?.locationGeometry?.location.lat,
          longitude: location?.locationGeometry?.location.lng,
          destinationAddress: formattedAddress,
        });
      }
      setSelectedIndex(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const onSelect = (index: IndexPath) => {
    setSelectedIndex(index);
    setVisible(false);
  };

  const renderToggleButton = () => {
    let formattedAddressMultiline: undefined | string;
    if (newLineAtCommaForStringValue && location?.formattedAddress) {
      formattedAddressMultiline = location?.formattedAddress.split(', ').join(',\n');
    }
    return (
      <TouchableOpacity
        onPress={() => setVisible(true)}
        style={[globalStyle.flex1, globalStyle.flexRow, style]}
      >
        <Text selectable={true} style={[globalStyle.flex1, styles.textStyle, textStyle]}>
          {newLineAtCommaForStringValue && formattedAddressMultiline
            ? formattedAddressMultiline
            : formattedAddress}
        </Text>
        <Icon
          name="diagonal-arrow-right-up-outline"
          width={16}
          height={16}
          style={globalStyle.marginLeft5}
          fill="#777"
        />
      </TouchableOpacity>
    );
  };

  if (!location?.formattedAddress) {
    return (
      <Text selectable={true} style={[styles.textStyle, textStyle, textStyleNoAddress]}>
        {t('common.noAddressProvided', { defaultValue: 'No address provided' }) as string}
      </Text>
    );
  }
  return (
    <OverflowMenu
      anchor={renderToggleButton}
      visible={visible}
      selectedIndex={selectedIndex}
      onSelect={onSelect}
      placement="bottom start"
      onBackdropPress={() => {
        setSelectedIndex(undefined);
        setVisible(false);
      }}
    >
      <MenuItem title="Copy" accessoryLeft={CopyIcon} />
      <MenuItem title="Navigate to" accessoryLeft={NavigateToIcon} />
    </OverflowMenu>
  );
};

const themedStyles = StyleService.create({
  textStyle: {
    color: 'text-basic-color',
  },
});
