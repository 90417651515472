import { useEffect, useState } from 'react';
import { EmitterSubscription, Keyboard, KeyboardEvent, Platform } from 'react-native';

export const useKeyboard = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const onKeyboardDidShow = (e: KeyboardEvent) => {
    if (Platform.OS === 'android') {
      setIsKeyboardOpen(true);
    }
    setKeyboardHeight(e.endCoordinates.height);
  };

  const onKeyboardWillShow = () => {
    setIsKeyboardOpen(true);
  };

  const onKeyboardDidHide = () => {
    if (Platform.OS === 'android') {
      setIsKeyboardOpen(false);
    }
    setKeyboardHeight(0);
  };

  const onKeyboardWillHide = () => {
    setIsKeyboardOpen(false);
  };

  useEffect(() => {
    const didShowSub: EmitterSubscription = Keyboard.addListener(
      'keyboardDidShow',
      onKeyboardDidShow,
    );
    const didHideSub: EmitterSubscription = Keyboard.addListener(
      'keyboardDidHide',
      onKeyboardDidHide,
    );
    let willShowSub: EmitterSubscription | undefined;
    let willHideSub: EmitterSubscription | undefined;

    if (Platform.OS === 'ios') {
      Keyboard.addListener('keyboardDidShow', onKeyboardWillShow);
      Keyboard.addListener('keyboardDidHide', onKeyboardWillHide);
    }
    return () => {
      didShowSub.remove();
      didHideSub.remove();
      if (Platform.OS === 'ios' && willShowSub?.remove && willHideSub?.remove) {
        willShowSub.remove();
        willHideSub.remove();
      }
    };
  }, []);

  return { keyboardHeight, isKeyboardOpen };
};
