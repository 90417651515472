import React, { useContext } from 'react';

import { Text, useStyleSheet } from '@ui-kitten/components';
import { Divider, Menu, MenuItem } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { CommonMenuItems } from '../../../components/common/menu/common.menu-items.component';
import { MenuCategory } from '../../../components/common/menu/menu-category.component';
import { ForwardIcon } from '../../../components/icons.component';
import { TopNavigationWithoutAccessories } from '../../../components/top-navigation-without-accessories.component';
import AppUserContext from '../../../contexts/AppUserContext';

export const CourierProfileScreen = ({ navigation }: { navigation: any }): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);

  const { countData } = useHook();

  return (
    <>
      <TopNavigationWithoutAccessories
        title={t('menuItems.courierProfile', { defaultValue: 'Courier Profile' }) as string}
      />
      <Divider />
      <Menu>
        <MenuCategory
          title={t('menuItems.myProfileMyAgencies', { defaultValue: 'My Profile & My Agencies' })}
        />
        <MenuItem
          title={t('menuItems.myProfile', { defaultValue: 'My Profile' }) as string}
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierProfileEditScreen',
            });
          }}
        />
        <MenuItem
          title={t('menuItems.myAgencies', { defaultValue: 'My Agencies' }) as string}
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierConnectedAgenciesScreen',
            });
          }}
        />
        <MenuCategory
          title={t('menuItems.myDocuments', { defaultValue: 'My Documents' }) as string}
        />
        <MenuItem
          title={
            t('menuItems.identityDocumentsWithCount', {
              defaultValue: 'Identity Documents ({{count}})',
              count: countData.userIdentityDocuments,
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierUserIdentityDocumentsScreen', {
              userId: appUserContext.currentUserContext?.user?.id,
              readOnly: false,
            });
          }}
        />
        <MenuItem
          title={
            t('menuItems.visaTravelAuthorizationWithCount', {
              defaultValue: 'Visa / Travel Authorization ({{count}})',
              count: countData.userTravelDocuments,
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierUserTravelPermitsScreen',
            });
          }}
        />
        <MenuItem
          title={
            t('menuItems.frequentFlyerProgramDocumentsWithCount', {
              defaultValue: 'Frequent Flyer Program Documents ({{count}})',
              count: countData.userFrequentFlyerDocuments,
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierFrequentFlyerDocumentsScreen',
            });
          }}
        />
        <MenuItem
          title={
            t('menuItems.vaccinationCertificatesWithCount', {
              defaultValue: 'Vaccination Certificates ({{count}})',
              count: countData.userVaccinationDocuments,
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierVaccinationCertificatesScreen',
            });
          }}
        />
        <MenuItem
          title={t('menuItems.healthTestReportsWithCount', {
            defaultValue: 'Health Test Reports ({{count}})',
            count: countData.userHealthReports,
          })}
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierHealthTestReportsScreen',
            });
          }}
        />
        <MenuItem
          title={
            t('menuItems.backgroundDocumentsWithCount', {
              defaultValue: 'Background Documents ({{count}})',
              count: countData.userBackgroundDocuments,
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierBackgroundDocumentsScreen',
            });
          }}
        />
        <MenuItem
          title={
            t('menuItems.otherDocumentsWithCount', {
              defaultValue: 'Other Documents ({{count}})',
              count: countData.userOtherDocuments,
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CourierOtherDocumentsScreen',
            });
          }}
        />

        <MenuCategory
          title={t('menuItems.Notifications', { defaultValue: 'Notifications' }) as string}
        />

        <MenuItem
          title={(evaProps) => (
            <Text {...evaProps} style={[evaProps?.style, styles.otherMenuItems]}>
              {t('menuItems.Notifications') as string}
            </Text>
          )}
          onPress={() => {
            navigation.navigate('CourierProfileNavigator', {
              screen: 'CommonNotificationPermissionsScreen',
            });
          }}
        />

        <CommonMenuItems />
      </Menu>
    </>
  );
};
