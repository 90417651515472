import React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import { Icon, Text, useStyleSheet, Button } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../../common/style';
import { AvailabilityFilterType } from '../../../../../generated-graphql-types';
import useDimensions from '../../../../../hooks/useDimensions';

export const AvailabilityFilterModal = ({
  isVisible,
  onClose,
  onFilterConfirm,
  selectedFilterType: activelySelectedFilterType,
}: {
  isVisible: boolean;
  onClose: () => void;
  onFilterConfirm: (value: AvailabilityFilterType[]) => void;
  selectedFilterType?: AvailabilityFilterType[];
}): React.ReactElement => {
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { isExtraLargeDevice } = useDimensions();

  const { handleFilterConfirm, handleFilterSelect, options, selectedFilterType } = useHook({
    activelySelectedFilterType,
    onFilterConfirm,
    isVisible,
  });

  const renderItem = (item: any) => (
    <TouchableOpacity
      key={item.value}
      activeOpacity={0.9}
      onPress={handleFilterSelect(item.value)}
      style={[
        globalStyle.width100Percent,
        styles.item,
        selectedFilterType?.includes(item.value) && styles.itemSelected,
      ]}
    >
      <Text>{item.label}</Text>
    </TouchableOpacity>
  );

  return (
    <Modal
      animationIn="slideInUp"
      isVisible={isVisible}
      style={[
        globalStyle.paddingLeft0,
        globalStyle.margin0,
        Platform.OS === 'web' && isExtraLargeDevice ? styles.modalWeb : styles.modal,
      ]}
      useNativeDriver
    >
      <View
        style={[
          Platform.OS === 'web' && isExtraLargeDevice
            ? styles.modalContainerWeb
            : styles.modalContainer,
        ]}
      >
        <View style={styles.title}>
          <View style={globalStyle.flex1} />
          <Text category="s1" style={styles.titleText}>
            {t('common.setYourFilters', { defaultValue: 'Set your filters' })}
          </Text>

          <TouchableOpacity
            onPress={onClose}
            style={[globalStyle.flex1, globalStyle.alignItemsEnd]}
          >
            <Icon name="close-outline" fill={(styles.icon as any).color} height={30} width={30} />
          </TouchableOpacity>
        </View>

        {options.map(renderItem)}

        <View style={[styles.buttonContainer, { paddingBottom: bottom || 20 }]}>
          <Button onPress={handleFilterConfirm} appearance="filled" size="large" status="primary">
            {(evaProps) => (
              <Text {...evaProps} style={[evaProps?.style, styles.buttonText]}>
                {t('common.filter', { defaultValue: 'Filter' }) as string}
              </Text>
            )}
          </Button>
        </View>
      </View>
    </Modal>
  );
};
