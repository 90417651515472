import React, { useMemo, useState, useEffect } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import { useLazyQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/core';
import { FlashList } from '@shopify/flash-list';
import { Icon, Text, useStyleSheet, Button } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { themedStyles } from './styles';
import { AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY } from '../../../../apollo/graphql-queries';
import { globalStyle as globalStyleCommon } from '../../../../common/style';
import {
  AgencyJob,
  CourierJob,
  CourierJobAgencyRelationshipType,
} from '../../../../generated-graphql-types';
import useDimensions from '../../../../hooks/useDimensions';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';
import { LoadingSpinner } from '../../../common/loading-spinner.component';

interface Props {
  agencyJob?: AgencyJob;
  isVisible: boolean;
  onClose: () => void;
  previousScreenName: string;
  previousTabScreenName:
    | 'AgencyNewJobsScreen'
    | 'AgencyStartedJobsScreen'
    | 'AgencyFinishedJobsScreen'
    | 'AgencyCancelledJobsScreen';
}

export const AgencyJobViewCouriersModal = ({
  agencyJob: agencyJobProp,
  isVisible,
  onClose,
  previousScreenName,
  previousTabScreenName,
}: Props): React.ReactElement => {
  const navigation: any = useNavigation();
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();
  const { isExtraLargeDevice, dimensions } = useDimensions();
  const isBackendReachable = useIsBackendReachable();
  const styles = useStyleSheet(themedStyles);
  const globalStyle = useStyleSheet(globalStyleCommon);

  const [agencyJob, setAgencyJob] = useState<AgencyJob | undefined>();
  const [loading, setLoading] = useState(false);

  const [fetchAgencyJob] = useLazyQuery(AGENCY_JOB_FOR_AGENCY_ORGANIZATION_QUERY, {
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const getAgencyJobData = async () => {
    if (agencyJobProp) {
      setLoading(true);
      const res = await fetchAgencyJob({ variables: { agencyJobId: agencyJobProp.id } });
      if (res?.data?.agencyJobForAgencyOrganization) {
        setAgencyJob(res.data?.agencyJobForAgencyOrganization);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!agencyJob && agencyJobProp?.id) {
      getAgencyJobData();
    } else if (agencyJob && !agencyJobProp) {
      setAgencyJob(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyJob, agencyJobProp]);

  const couriers = useMemo(() => {
    if (agencyJob?.courierJobs?.length) {
      const courierData = agencyJob?.courierJobs.reduce(
        (acc: any, curr: CourierJob) => {
          const { relationshipType } = curr;
          if (
            relationshipType === CourierJobAgencyRelationshipType.COURIER_REQUESTED ||
            relationshipType === CourierJobAgencyRelationshipType.COURIER_ACCEPTED
          ) {
            acc.pendingCourierJobsForAgencyJob.push(curr);
          } else if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_REQUESTED) {
            acc.requestedCourierJobsForAgencyJob.push(curr);
          } else if (relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED) {
            acc.acceptedCourierJobsForAgencyJob.push(curr);
          } else if (
            relationshipType === CourierJobAgencyRelationshipType.AGENCY_DECLINED ||
            relationshipType === CourierJobAgencyRelationshipType.COURIER_DECLINED
          ) {
            acc.declinedCourierJobsForAgencyJob.push(curr);
          }
          return acc;
        },
        {
          pendingCourierJobsForAgencyJob: [],
          requestedCourierJobsForAgencyJob: [],
          acceptedCourierJobsForAgencyJob: [],
          declinedCourierJobsForAgencyJob: [],
        } as {
          pendingCourierJobsForAgencyJob: CourierJob[];
          requestedCourierJobsForAgencyJob: CourierJob[];
          acceptedCourierJobsForAgencyJob: CourierJob[];
          declinedCourierJobsForAgencyJob: CourierJob[];
        },
      );
      const returnData = [];
      if (courierData.acceptedCourierJobsForAgencyJob.length) {
        returnData.push(t('common.accepted', { defaultValue: 'Accepted' }));
        returnData.push(...courierData.acceptedCourierJobsForAgencyJob);
      }
      if (courierData.requestedCourierJobsForAgencyJob.length) {
        returnData.push(t('common.requested', { defaultValue: 'Requested' }));
        returnData.push(...courierData.requestedCourierJobsForAgencyJob);
      }
      if (courierData.pendingCourierJobsForAgencyJob.length) {
        returnData.push(t('common.pending', { defaultValue: 'Pending' }));
        returnData.push(...courierData.pendingCourierJobsForAgencyJob);
      }
      if (courierData.declinedCourierJobsForAgencyJob.length) {
        returnData.push(t('common.declined', { defaultValue: 'Declined' }));
        returnData.push(...courierData.declinedCourierJobsForAgencyJob);
      }
      return returnData;
    }

    return [];
  }, [agencyJob, t]);

  const estimatedListSize = useMemo(() => {
    if (dimensions.window.width >= 1200) {
      return { height: couriers.length * 46, width: 1200 };
    }
    return { height: couriers.length * 46, width: dimensions.window.width };
  }, [dimensions.window, couriers.length]);

  const stickyHeaderIndices = couriers
    .map((item, index) => {
      if (typeof item === 'string') {
        return index;
      }
      return null;
    })
    .filter((item) => item !== null) as number[];

  const handleChatPress = (courierJob: CourierJob) => () => {
    onClose();
    setTimeout(() => {
      navigation.navigate('AgencyCourierJobAgencyJobChatScreen', {
        agencyJobId: courierJob?.agencyJob?.id || '',
        chatId: courierJob?.courierJobAgencyJobChat?.id || '',
        courierJobId: courierJob?.id || '',
        userId: courierJob?.responsibleCourierUser?.id || '',
        previousScreenName: previousScreenName,
        previousTabScreenName: previousTabScreenName,
        previousNavigator: 'AgencyHomeNavigator',
      });
    }, 300);
  };

  const handleProfilePress = (courierJob: CourierJob) => () => {
    onClose();
    setTimeout(() => {
      navigation.navigate('AgencyCourierUserProfileScreen', {
        userId: courierJob?.responsibleCourierUser?.id as string,
        previousNavigator: 'AgencyHomeNavigator',
        agencyJobId: agencyJob?.id as string,
        previousScreenName: previousScreenName,
        previousTabScreenName: previousTabScreenName,
      });
    }, 300);
  };

  const keyExtractor = (item: CourierJob | string) => {
    if (typeof item === 'string') {
      return item;
    }
    return item.id;
  };

  const renderItem = ({ item, index }: { index: number; item: CourierJob | 'string' }) => {
    if (typeof item === 'string') {
      // Rendering header
      return <Text style={styles.header}>{item}</Text>;
    }
    return (
      <View
        style={[
          globalStyle.flexRow,
          globalStyle.alignItemsCenter,
          globalStyle.paddingLeft15,
          globalStyle.paddingRight5,
          globalStyle.paddingVertical5,
          index > 1 && globalStyle.borderTopWidth1,
          index > 1 && globalStyle.borderBasicColor3,
        ]}
      >
        <Text>
          {item.responsibleCourierUser?.firstNames || 'First name'}{' '}
          {item.responsibleCourierUser?.lastName || 'Last name'}
        </Text>
        <View style={[globalStyle.flex1, globalStyle.flexRow, globalStyle.justifyContentEnd]}>
          <Button
            size="small"
            appearance="filled"
            style={globalStyle.marginRight5}
            onPress={handleChatPress(item)}
          >
            {t('common.chat', { defaultValue: 'Chat' }) as string}
          </Button>
          <Button size="small" appearance="filled" onPress={handleProfilePress(item)}>
            {t('common.profile', { defaultValue: 'Profile' }) as string}
          </Button>
        </View>
      </View>
    );
  };

  const renderSectionList = () => (
    <FlashList
      data={couriers}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      stickyHeaderIndices={stickyHeaderIndices}
      getItemType={(item) => {
        return typeof item === 'string' ? 'sectionHeader' : 'row';
      }}
      estimatedItemSize={46}
      estimatedListSize={estimatedListSize}
    />
  );

  const renderList = () => {
    if (Platform.OS === 'web') {
      return renderSectionList();
    }
    return <View style={[globalStyle.flexGrow1, globalStyle.flexRow]}>{renderSectionList()}</View>;
  };

  const renderLoading = () => {
    return (
      <View style={[globalStyle.height100]}>
        <LoadingSpinner
          subject={t('common.loadingCouriersData', { defaultValue: 'couriers data' })}
        />
      </View>
    );
  };

  return (
    <Modal
      animationIn="slideInUp"
      isVisible={isVisible}
      style={[
        globalStyle.paddingLeft0,
        globalStyle.margin0,
        Platform.OS === 'web' && isExtraLargeDevice ? styles.modalWeb : styles.modal,
      ]}
      backdropOpacity={0.8}
      useNativeDriver
    >
      <View
        style={[
          Platform.OS === 'web' && isExtraLargeDevice
            ? styles.modalContainerWeb
            : styles.modalContainer,
        ]}
      >
        <View style={styles.title}>
          <View style={globalStyle.flex1} />

          <Text category="s1" style={styles.titleText}>
            {t('common.viewCouriers', { defaultValue: 'View couriers' })}
            <Text> ({agencyJob?.job?.priojetJobIdentifier || ''})</Text>
          </Text>

          <TouchableOpacity
            onPress={onClose}
            style={[globalStyle.flex1, globalStyle.alignItemsEnd]}
          >
            <Icon name="close-outline" fill={(styles.icon as any).color} height={30} width={30} />
          </TouchableOpacity>
        </View>

        {loading ? renderLoading() : renderList()}

        <View style={{ height: bottom + 20 }} />
      </View>
    </Modal>
  );
};
