import { StyleService } from '@ui-kitten/components';

export const themedStyle = StyleService.create({
  saveBtn: {
    height: 40,
    width: 80,
  },
  noResultsView: {
    marginTop: 10,
    padding: 10,
  },
  categories: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    paddingHorizontal: 15,
  },
  category: {
    width: '100%',
    maxWidth: 600,
    flexDirection: 'row',
    marginBottom: 5,
  },
  categoryNumber: {
    width: 32,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'color-basic-600',
    borderRadius: 6,
    marginRight: 5,
  },
  textInput: {
    flex: 1,
    paddingHorizontal: 16,
    minHeight: 38,
    color: 'text-basic-color',
    borderWidth: 1,
    borderColor: 'color-basic-600',
    borderRadius: 6,
  },
  textInputDisabled: {
    backgroundColor: 'color-basic-400',
  },
});
