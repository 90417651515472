import React, { memo } from 'react';
import { View } from 'react-native';

import { Text, useStyleSheet, Icon } from '@ui-kitten/components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-native-gifted-chat';

import { ChatMessageImage } from './ChatMessageImage';
import { ChatMessagePDF } from './ChatMessagePDF';
import { ChatMessageText } from './ChatMessageText';
import { ChatMessageTrackingPointItem } from './ChatMessageTrackingPointItem';
import { ChatMessageTrackingPointLeg } from './ChatMessageTrackingPointLeg';
import { ChatMessageWrapper } from './ChatMessageWrapper';
import { styles as themedStyles } from './styles';
import { globalStyle } from '../../../../common/style';
import { MessageType } from '../../../../graphql-types';

const { isSameDay } = utils;

export const ChatMessageComponent: React.FC<any> = ({
  currentMessage,
  currentUser,
  nextMessage,
  handlePDFPress,
  handleSendAgain: handleSendAgainProp,
  openMessageOptions,
  previousMessage,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const handleSendAgain = () => {
    handleSendAgainProp(currentMessage);
  };

  const renderDay = (): React.ReactNode => {
    if (currentMessage?.createdAt && !isSameDay(currentMessage, previousMessage)) {
      return (
        <View style={styles.dayContainer}>
          <View style={styles.dayLine} />
          <Text style={styles.dayText}>
            {dayjs(currentMessage.createdAt).locale('en').format('MMM DD, YYYY.')}
          </Text>
          <View style={styles.dayLine} />
        </View>
      );
    }
    return <></>;
  };

  const renderFirstMessage = () => (
    <View style={styles.firstMessageContainer}>
      <Text style={styles.firstMessageTitle}>
        {t('CHAT.firstMessageTitle', { defaultValue: 'Greetings' })}
      </Text>
      <Text style={styles.firstMessageText}>
        {t('chat.firstMessageText', {
          defaultValue: 'You can send your first message here',
        })}
      </Text>
    </View>
  );

  const renderUserJoinedLeftMessage = () => {
    const { messageType, text } = currentMessage;
    const lastMessage = !Object.keys(nextMessage).length;
    return (
      <View
        key={Math.random()}
        style={[styles.userJoinLeftMessageContainer, lastMessage && globalStyle.marginBottom30]}
      >
        <View style={styles.userJoinLeftMessageText}>
          {messageType === MessageType.TRACKER_COURIER_JOINED ? (
            <Icon
              fill="green"
              name="diagonal-arrow-right-down-outline"
              style={globalStyle.size16}
            />
          ) : (
            <Icon fill="red" name="diagonal-arrow-left-up-outline" style={globalStyle.size16} />
          )}
          <Text style={globalStyle.marginLeft10}>{text}</Text>
        </View>
      </View>
    );
  };

  const renderMessage = () => {
    const isCurrentUser = currentUser._id === currentMessage.user._id;
    if (currentMessage.messageType === MessageType.TEXT) {
      return (
        <ChatMessageWrapper
          currentMessage={currentMessage}
          handleSendAgain={handleSendAgain}
          isCurrentUser={isCurrentUser}
          nextMessage={nextMessage}
          openMessageOptions={openMessageOptions}
        >
          <ChatMessageText isCurrentUser={isCurrentUser} text={currentMessage?.message} />
        </ChatMessageWrapper>
      );
    }
    if (
      [
        MessageType.TRACKER_COURIER_TRACKING_POINT_RESET,
        MessageType.TRACKER_COURIER_TRACKING_POINT_COMPLETED,
      ].includes(currentMessage.messageType)
    ) {
      return (
        <ChatMessageWrapper
          currentMessage={currentMessage}
          handleSendAgain={handleSendAgain}
          isCurrentUser={isCurrentUser}
          nextMessage={nextMessage}
          openMessageOptions={openMessageOptions}
          showMessageOptionsMenu={false}
          styleDateText={styles.whiteText}
          styleNameText={styles.whiteText}
        >
          <ChatMessageTrackingPointItem
            currentMessage={currentMessage}
            handlePDFPress={handlePDFPress}
            isCurrentUser={isCurrentUser}
          />
        </ChatMessageWrapper>
      );
    }
    if (
      [
        MessageType.TRACKER_FLIGHT_DETAILS_CREATED,
        MessageType.TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE,
        MessageType.TRACKER_FLIGHT_DETAILS_REMOVED,
        MessageType.TRACKER_FLIGHT_DETAILS_UPDATED,
      ].includes(currentMessage.messageType)
    ) {
      return (
        <ChatMessageWrapper
          currentMessage={currentMessage}
          handleSendAgain={handleSendAgain}
          isCurrentUser={isCurrentUser}
          nextMessage={nextMessage}
          openMessageOptions={openMessageOptions}
          showMessageOptionsMenu={false}
          styleDateText={styles.whiteText}
          styleNameText={styles.whiteText}
        >
          <ChatMessageTrackingPointLeg
            currentMessage={currentMessage}
            isCurrentUser={isCurrentUser}
          />
        </ChatMessageWrapper>
      );
    }

    if (
      [MessageType.TRACKER_COURIER_JOINED, MessageType.TRACKER_COURIER_LEFT].includes(
        currentMessage.messageType,
      )
    ) {
      return renderUserJoinedLeftMessage();
    }
    if (currentMessage.chatFile?.sasUrl) {
      if (currentMessage.chatFile.metadata.mimeType === 'application/pdf') {
        return (
          <ChatMessageWrapper
            currentMessage={currentMessage}
            handleSendAgain={handleSendAgain}
            isCurrentUser={isCurrentUser}
            nextMessage={nextMessage}
            openMessageOptions={openMessageOptions}
          >
            <ChatMessagePDF
              chatFile={currentMessage.chatFile}
              handlePDFPress={handlePDFPress}
              isCurrentUser={isCurrentUser}
            />
          </ChatMessageWrapper>
        );
      }
      return (
        <ChatMessageWrapper
          currentMessage={currentMessage}
          handleSendAgain={handleSendAgain}
          isCurrentUser={isCurrentUser}
          nextMessage={nextMessage}
          openMessageOptions={openMessageOptions}
        >
          <ChatMessageImage chatFile={currentMessage.chatFile} />
        </ChatMessageWrapper>
      );
    }
    return null;
  };

  return (
    <View>
      {renderDay()}
      {currentMessage._id === 'FIRST_MESSAGE' ? renderFirstMessage() : renderMessage()}
    </View>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps.currentMessage.id === nextProps.currentMessage.id) {
    if (
      prevProps.currentMessage.chatFile &&
      nextProps.currentMessage.chatFile &&
      prevProps.currentMessage.chatFile?.sasUrl !== nextProps.currentMessage.chatFile?.sasUrl
    ) {
      return false;
    }
    if (
      prevProps.currentMessage.messageText &&
      prevProps.currentMessage.text !== nextProps.currentMessage.text
    ) {
      return false;
    }
    if (prevProps.currentMessage.errorWhileSending !== nextProps.currentMessage.errorWhileSending) {
      return false;
    }
    if (prevProps.currentMessage.resending !== nextProps.currentMessage.resending) {
      return false;
    }
    if (prevProps.nextMessage?.id !== nextProps.nextMessage?.id) {
      return false;
    }
  }

  return true;
};

export const ChatMessage = memo(ChatMessageComponent, arePropsEqual);
