import { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { MUTATION_ORGANIZATION_SUBSCRIPTION_PLAN_CANCEL } from '../../../../apollo/graphql-mutations';
import { QUERY_ORGANIZATION_SUBSCRIPTION_PLANS } from '../../../../apollo/graphql-queries';
import { ConfirmModalContext } from '../../../../components/common/modal/ConfirmModal';
import AppUserContext from '../../../../contexts/AppUserContext';
import { OrganizationSubscriptionPlan } from '../../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const appUserContext = useContext(AppUserContext);
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const isBackendReachable = useIsBackendReachable();

  const [additionalSeatsVisible, setAdditionalSeatsVisible] = useState(false);
  const [checkoutCompletedPlanName, setCheckoutCompletedPlanName] = useState<string>('');
  const [checkoutOpened, setCheckoutOpened] = useState(false);

  const { data: organizationData, refetch: refetchOrganizationSubscriptionPlans } = useQuery(
    QUERY_ORGANIZATION_SUBSCRIPTION_PLANS,
    {
      variables: { id: appUserContext.currentUserContext?.organization?.id as string },
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-first',
      nextFetchPolicy: 'network-only',
    },
  );
  const [organizationSubscriptionPlanCancel] = useMutation(
    MUTATION_ORGANIZATION_SUBSCRIPTION_PLAN_CANCEL,
  );

  const organizationSubscriptionPlan = useMemo(() => {
    if (organizationData?.organization.organizationSubscriptionPlan) {
      return organizationData?.organization.organizationSubscriptionPlan;
    }
    return null;
  }, [organizationData]);

  const organizationSubscriptionPlansRest = useMemo((): OrganizationSubscriptionPlan[] => {
    if (organizationData?.organization.organizationSubscriptionPlans) {
      const data = organizationData?.organization.organizationSubscriptionPlans
        .filter((item: OrganizationSubscriptionPlan) => !item.isMain)
        .sort((a: OrganizationSubscriptionPlan, b: OrganizationSubscriptionPlan) => {
          if (a.status < b.status) {
            return -1;
          }
          if (a.status > b.status) {
            return 1;
          }
          return 0;
        });
      return data as OrganizationSubscriptionPlan[];
    }
    return [];
  }, [organizationData]);

  const handleToggleAdditionalSeatsPress = (value: boolean) => () => {
    setAdditionalSeatsVisible(value);
  };

  const handleCancelPress = (item: OrganizationSubscriptionPlan) => async () => {
    const name = t(item.subscriptionPlan.planName.key, {
      defaultValue: item.subscriptionPlan.planName.defaultValue,
    });
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.cancel', { defaultValue: 'Delete' }),
      cancelButtonText: t('common.close', { defaultValue: 'Close' }),
      title: t('common.cancelSubscription', { defaultValue: 'Cancel subscription' }),
      text: t('common.cancelSubscriptionModalText', {
        defaultValue: 'Are you sure want to cancel subscription "{{name}}"?',
        name,
      }),
      onConfirmPress: async () => {
        try {
          await organizationSubscriptionPlanCancel({ variables: { data: { id: item.id } } });
          await refetchOrganizationSubscriptionPlans();
        } catch (e) {
          return false;
        }
        return true;
      },
      onCancelPress: async () => {
        return false;
      },
      visible: true,
    });
  };

  const handleBookSuccess = (name: string) => {
    setCheckoutCompletedPlanName(name);
  };

  const handleBookToggle = (value: boolean) => {
    if (!value) {
      refetchOrganizationSubscriptionPlans();
      appUserContext.refetchCurrentUserContext();
    }
    setCheckoutOpened(value);
  };

  useEffect(() => {
    if (checkoutCompletedPlanName && !checkoutOpened) {
      showMessage({
        message: t('common.success', { defaultValue: 'Success' }),
        description: t('common.bookAdditionalSeatsSuccessfulDescription', {
          defaultValue: 'Thank you, you have successfully booked additional seats.',
        }) as string,
        type: 'success',
        autoHide: true,
        hideOnPress: true,
        duration: 8000,
      });
      setCheckoutCompletedPlanName('');
    }
  }, [checkoutOpened, checkoutCompletedPlanName, t]);

  return {
    additionalSeatsVisible,
    handleBookSuccess,
    handleBookToggle,
    handleCancelPress,
    handleToggleAdditionalSeatsPress,
    organizationSubscriptionPlan,
    organizationSubscriptionPlansRest,
    refetchOrganizationSubscriptionPlans,
  };
};
