import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Layout, StyledComponentProps, useTheme } from '@ui-kitten/components';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

type Inset = 'top' | 'bottom';

export interface SafeAreaLayoutProps extends StyledComponentProps {
  insets?: Inset;
  children?: React.ReactNode;
  level?: string;
  style?: StyleProp<ViewStyle>;
}

export const SafeAreaLayout: React.FC<SafeAreaLayoutProps> = ({ insets, ...props }) => {
  const theme = useTheme();
  const insetsConfig = useSafeAreaInsets();

  const backgroundColor: string = theme[`background-basic-color-${props.level}`] || '';

  return (
    <Layout
      {...props}
      style={[
        props.style,
        { backgroundColor },
        {
          paddingTop: insets === 'top' ? insetsConfig.top : 0,
          paddingBottom: insets === 'bottom' ? insetsConfig.bottom : 0,
        },
      ]}
    />
  );
};
