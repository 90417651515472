import React from 'react';
import { View } from 'react-native';

import { A } from '@expo/html-elements';
import { Icon, Text, useStyleSheet } from '@ui-kitten/components';
import { Trans, useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../common/style';
import { OrganizationType } from '../../../generated-graphql-types';
import StandardButton from '../../standard-button.component';
import { LoadingSpinner } from '../loading-spinner.component';
import { TextLink } from '../text-link.component';

const Link: any = A;

export const CommonTermsAndConditionsSignupComponent = ({
  organizationType,
  handleModalClose,
}: {
  organizationType: OrganizationType;
  handleModalClose: (params?: { reloadContext?: boolean }) => void;
}) => {
  const { t, i18n }: any = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    handleLinkPress,
    handleTermsAndConditionsChange,
    isSignUpPopupActive,
    linkTermAndConditions,
    linkPrivacyPolicyPress,
    setTermsAndConditionsAccepted,
    setIsSignUpPopupActive,
    signUpButtonStates,
    termsAndConditionsAccepted,
  } = useHook({ organizationType, handleModalClose });

  return (
    <>
      <View style={styles.termsAndConditionsView}>
        <View style={styles.checkboxContainer}>
          <TouchableOpacity onPress={handleTermsAndConditionsChange}>
            {termsAndConditionsAccepted ? (
              <Icon
                fill={(styles.checkboxMarked as any).color}
                name="checkmark-square-2"
                style={globalStyle.size24}
              />
            ) : (
              <Icon
                fill={(styles.checkbox as any).color}
                name="square"
                style={globalStyle.size24}
              />
            )}
          </TouchableOpacity>
          <Text
            style={[globalStyle.fontLatoBold, globalStyle.fontSize18, globalStyle.paddingLeft5]}
          >
            <Trans
              i18n={i18n}
              i18nKey="signUp.termsAndConditionsCheckboxText"
              components={{
                3: <Text style={styles.text} />,
                1: (
                  <Link
                    style={styles.textBold}
                    href={linkTermAndConditions}
                    hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
                    onPress={handleLinkPress}
                  />
                ),
                2: (
                  <Link
                    style={styles.textBold}
                    href={linkPrivacyPolicyPress}
                    hrefAttrs={{ target: '_blank', rel: 'noopener noreferrer' }}
                    onPress={handleLinkPress}
                  />
                ),
              }}
              defaults="<3>I agree to the <1>Terms and Conditions (T&Cs)</1> and have taken note of the <2>Privacy Policy</2>.</3>"
            />
          </Text>
        </View>
      </View>

      <View style={styles.buttonContainer}>
        {!!isSignUpPopupActive && (
          <LoadingSpinner
            text={t('signUp.finishYourSignupInThePopUpWindow', {
              defaultValue: 'Finish your sign up in the popup window.',
            })}
          />
        )}
        <StandardButton size="large" states={signUpButtonStates} isLoading={isSignUpPopupActive} />
        {!!isSignUpPopupActive && (
          <TextLink
            style={styles.marginTop10}
            text={t('common.tryAgain', { defaultValue: 'Try again' })}
            onPress={() => {
              setTermsAndConditionsAccepted(false);
              setIsSignUpPopupActive(false);
            }}
          />
        )}
      </View>
    </>
  );
};
