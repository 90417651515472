import React, { useMemo, useState } from 'react';
import { Platform, View } from 'react-native';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Icon, IconProps, StyleService, Text } from '@ui-kitten/components';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { showMessage } from 'react-native-flash-message';
import { ScrollView } from 'react-native-gesture-handler';

import { CREATE_ORGANIZATION_INVITATION_AS_PRIOJET_MUTATION } from '../../apollo/graphql-mutations';
import {
  ORGANIZATIONS_QUERY,
  ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY,
} from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { FormAutocomplete } from '../../components/common/form/form-autocomplete.component';
import { FormTextInputTopLabel } from '../../components/common/form/form-text-input-top-label.component';
import { FormSelect } from '../../components/common/form/FormSelect';
import { LoadingSpinner } from '../../components/common/loading-spinner.component';
import { TransparentModal } from '../../components/common/modal/transparent-modal.component';
import {
  CreateOrganizationInvitationAsPriojetMutation,
  CreateOrganizationInvitationAsPriojetMutationVariables,
  Organization,
  OrganizationInvitationType,
  OrganizationsQuery,
  OrganizationsQueryVariables,
} from '../../generated-graphql-types';
import { OrganizationType, OrganizationUserRoleType } from '../../graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import { RootStackParamList } from '../../navigation/app.navigator';

const getOrganizationInputTitle = (organization: Partial<Organization>): string => {
  if (!organization) {
    return `No organizations found (please check also the OrganizationType selection).`;
  }
  let title = organization.organizationType + ' | ' + organization.legalName;
  if (organization.organizationUsers && organization.organizationUsers.length > 0) {
    title += ' (' + organization.organizationUsers?.length + ' users)';
    const owners = organization.organizationUsers?.filter(
      (organizationUser) => organizationUser.roleType === OrganizationUserRoleType.OWNER,
    );
    if (owners.length > 0) {
      title +=
        ' - owned by ' +
        owners[0].user.firstNames +
        ' ' +
        owners[0].user.lastName +
        ' (' +
        owners[0].user.email +
        ')';
    }
    if (organization?.usedOrganizationInvitation?.usedAt) {
      title +=
        ' (Activated at: ' +
        organization?.usedOrganizationInvitation?.usedAt.toLocaleString(DateTime.DATETIME_SHORT) +
        ')';
    } else {
      title += ' (NOT ACTIVATED)';
    }
  } else {
    title += ' (0 users)';
  }
  return title;
};

type FilterableOrganization = Organization & { title: string; activatedAt?: DateTime };

const roleTypeData: { label: string; value: string }[] = Object.keys(OrganizationUserRoleType).map(
  (key) => ({ value: key, label: key.replaceAll('_', ' ') }),
);

const organizationInvitationTypeData: { label: string; value: string }[] = Object.values(
  OrganizationInvitationType,
).map((key) => ({
  value: key,
  label: key.replaceAll('_', ' '),
  disabled: [OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE].includes(
    key,
  ),
}));

const organizationTypeData: { label: string; value: string }[] = Object.keys(OrganizationType).map(
  (key) => ({ value: key, label: key.replaceAll('_', ' ') }),
);

export const PriojetCreateOrganizationInvitationScreen = () => {
  const isBackendReachable = useIsBackendReachable();

  const [closeModal, setCloseModal] = useState(false);
  const [organizations, setOrganizations] = useState<FilterableOrganization[]>();

  const handleCreateOrganizationInvitation = () => {
    const {
      invitedOrganizationType,
      invitedOrganizationId,
      invitingOrganizationId,
      organizationInvitationType,
      reservedComment,
      roleType,
    } = formik.values;

    let variables: CreateOrganizationInvitationAsPriojetMutationVariables | undefined;
    if (organizationInvitationType === OrganizationInvitationType.ACTIVATE_NEW_OWN_ORGANIZATION) {
      variables = { reservedComment, organizationInvitationType, invitedOrganizationType };
    } else if (
      organizationInvitationType ===
      OrganizationInvitationType.ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION
    ) {
      variables = {
        reservedComment,
        organizationInvitationType,
        invitedOrganizationType,
        invitingOrganizationId,
      };
    } else if (
      organizationInvitationType ===
      OrganizationInvitationType.CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION
    ) {
      variables = {
        reservedComment,
        organizationInvitationType,
        invitedOrganizationType,
        invitedOrganizationId,
        invitingOrganizationId,
      };
    } else if (
      organizationInvitationType ===
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE
    ) {
      variables = { reservedComment, organizationInvitationType, roleType, invitingOrganizationId };
    } else if (
      organizationInvitationType ===
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION
    ) {
      variables = {
        reservedComment,
        organizationInvitationType,
        roleType,
        invitedOrganizationType,
        invitedOrganizationId,
        invitingOrganizationId,
      };
    } else if (
      organizationInvitationType ===
      OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION
    ) {
      variables = {
        reservedComment,
        organizationInvitationType,
        roleType,
        invitedOrganizationId,
        invitingOrganizationId,
      };
    } else {
      variables = {
        reservedComment,
        organizationInvitationType,
        invitedOrganizationType,
        invitedOrganizationId,
        invitingOrganizationId,
        roleType,
      };
    }

    createOrganizationInvitation({ variables }).then((result) => {
      if (result?.data) {
        resetModal();
        setCloseModal(true);
      }
    });
  };

  const initialValues = {
    reservedComment: '',
    roleType: '',
    organizationInvitationType: organizationInvitationTypeData[0].value,
    invitedOrganizationType: organizationTypeData[0].value,
    invitedOrganizationId: '',
    invitingOrganizationId: '',
  };

  const formik: any = useFormik({
    initialValues,
    validationSchema: {},
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleCreateOrganizationInvitation,
  });

  const { invitedOrganizations, invitingOrganizations } = useMemo(() => {
    if (!organizations?.length) {
      return { invitedOrganizations: [], invitingOrganizations: [] };
    }

    let _invitedOrganizations: FilterableOrganization[] = [];
    if (
      [
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
      ].includes(formik.values.organizationInvitationType)
    ) {
      _invitedOrganizations = organizations.filter((item) => {
        if (formik.values.invitingOrganizationId) {
          return (
            item.organizationType === formik.values.invitedOrganizationType &&
            !item?.usedOrganizationInvitation?.usedAt &&
            item.id !== formik.values.invitingOrganizationId
          );
        }
        return (
          item.organizationType === formik.values.invitedOrganizationType &&
          !item?.usedOrganizationInvitation?.usedAt
        );
      });
    } else {
      _invitedOrganizations = organizations.filter(
        (item) => item.organizationType !== formik.values.invitedOrganizationType,
      );
    }

    let _invitingOrganizations: FilterableOrganization[] = [];
    if (
      [
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
      ].includes(formik.values.organizationInvitationType)
    ) {
      _invitingOrganizations = organizations.filter(
        (item) =>
          item.organizationType === formik.values.invitedOrganizationType &&
          item?.usedOrganizationInvitation?.usedAt,
      );
    } else {
      _invitingOrganizations = organizations.filter(
        (item) => item.organizationType === formik.values.invitedOrganizationType,
      );
    }

    return {
      invitedOrganizations: _invitedOrganizations,
      invitingOrganizations: _invitingOrganizations,
    };
  }, [
    formik.values.invitedOrganizationType,
    formik.values.invitingOrganizationId,
    formik.values.organizationInvitationType,
    organizations,
  ]);

  const [createOrganizationInvitation, { loading: createOrganizationInvitationLoading }] =
    useMutation<
      CreateOrganizationInvitationAsPriojetMutation,
      CreateOrganizationInvitationAsPriojetMutationVariables
    >(CREATE_ORGANIZATION_INVITATION_AS_PRIOJET_MUTATION, {
      refetchQueries: [ORGANIZATION_INVITATIONS_FOR_PRIOJET_QUERY],
      onError: (error) => {
        if (error) {
          showMessage({
            message: 'Error creating invitation',
            description: JSON.stringify(error),
            type: 'success',
            autoHide: false,
            hideOnPress: true,
            duration: 5000,
          });
        }
      },
      onCompleted: (data) => {
        if (data.createOrganizationInvitationAsPriojet) {
          showMessage({
            message: 'Organization Invitation successfully created.',
            description: `Organization Invitation with activation code ${data.createOrganizationInvitationAsPriojet.activationCode} and comment "${data.createOrganizationInvitationAsPriojet?.reservedComment}" was successfully created and can be shared to invite new Organizations.`,
            type: 'success',
            renderFlashMessageIcon: () => <Icon name="check-outline" />,
          });
          // navigation.navigate('PriojetOrganizationInvitationsScreen');
        }
      },
    });

  const { loading: organizationsLoading } = useQuery<
    OrganizationsQuery,
    OrganizationsQueryVariables
  >(ORGANIZATIONS_QUERY, {
    onCompleted: (data) => {
      if (data.organizations) {
        const _organizations: FilterableOrganization[] = [];
        for (const organization of data.organizations) {
          _organizations.push({
            ...organization,
            title: getOrganizationInputTitle(organization as Partial<Organization>),
            activatedAt: organization.usedOrganizationInvitation?.usedAt || undefined,
          } as FilterableOrganization);
        }
        setOrganizations(_organizations);
      }
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const resetModal = () => {
    formik.setValues({ ...initialValues });
  };

  const fieldProps = (field: string) => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);

    const onChangeText = (v: any): void => {
      let value = v;
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    return {
      errorMessage: fieldError || '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
      onChangeText,
    };
  };

  const onSelect =
    ({ valueKey }: any) =>
    (value: any) => {
      formik?.setFieldValue(valueKey, value);
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const onSelectInvitingOrganization = (value: any) => {
    formik?.setFieldValue('invitingOrganizationId', value);
    const found = invitingOrganizations?.find((item) => item.id === value);
    if (found) {
      formik?.setFieldValue('invitingOrganizationValue', found.name);
    }
    setTimeout(() => {
      if (formik?.errors.invitingOrganizationId) {
        formik.validateField('invitingOrganizationId');
      }
    }, 100);
  };

  const onSelectInvitedOrganization = (value: any) => {
    formik?.setFieldValue('invitedOrganizationId', value);
    const found = invitedOrganizations?.find((item) => item.id === value);
    if (found) {
      formik?.setFieldValue('invitedOrganizationValue', found.name);
    }
    setTimeout(() => {
      if (formik?.errors.invitingOrganizationId) {
        formik.validateField('invitedOrganizationId');
      }
    }, 100);
  };

  const isInvitedOrganizationTypeEnabled = useMemo((): boolean => {
    if (
      formik.values.organizationInvitationType &&
      [
        OrganizationInvitationType.ACTIVATE_NEW_OWN_ORGANIZATION,
        OrganizationInvitationType.ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION,
        OrganizationInvitationType.CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
      ].includes(formik.values.organizationInvitationType)
    ) {
      return true;
    }
    return false;
  }, [formik.values.organizationInvitationType]);

  const isInvitedOrganizationEnabled = useMemo((): boolean => {
    if (
      formik.values.organizationInvitationType &&
      [
        OrganizationInvitationType.CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
      ].includes(formik.values.organizationInvitationType) &&
      formik.values.invitedOrganizationType
    ) {
      return true;
    }
    return false;
  }, [formik.values.organizationInvitationType, formik.values.invitedOrganizationType]);

  const isInvitingOrganizationEnabled = useMemo((): boolean => {
    if (
      formik.values.organizationInvitationType &&
      [
        OrganizationInvitationType.ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION,
        OrganizationInvitationType.CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
      ].includes(formik.values.organizationInvitationType)
    ) {
      return true;
    }
    return false;
  }, [formik.values.organizationInvitationType]);

  const isOrganizationUserRoleTypeEnabled = useMemo((): boolean => {
    if (
      formik.values.organizationInvitationType &&
      [
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION,
        OrganizationInvitationType.JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION,
      ].includes(formik.values.organizationInvitationType)
    ) {
      return true;
    }
    return false;
  }, [formik.values.organizationInvitationType]);

  const renderAlertIcon = (props: IconProps) => (
    <Icon {...props} fill="#ccc" name="alert-circle-outline" />
  );

  if (organizationsLoading || createOrganizationInvitationLoading) {
    return (
      <TransparentModal
        navigateToScreenName={'PriojetOrganizationInvitationsScreen' as keyof RootStackParamList}
        title="Create Organization Invitation"
        closeModal={closeModal}
      >
        <LoadingSpinner />
      </TransparentModal>
    );
  }
  return (
    <TransparentModal
      navigateToScreenName={'PriojetOrganizationInvitationsScreen' as keyof RootStackParamList}
      title="Create Organization Invitation"
      closeModal={closeModal}
    >
      <ScrollView showsVerticalScrollIndicator={false}>
        <FormTextInputTopLabel
          containerStyle={[styles.inputField, styles.inputElement]}
          label="Reserved Comment"
          placeholder="E.g. reserved for the super customer"
          {...fieldProps('reservedComment')}
        />

        <FormSelect
          appearance="default"
          containerStyle={styles.inputElement}
          label="Organization Invitation Type"
          onSelect={onSelect({
            options: organizationInvitationTypeData,
            fieldValueKey: 'value',
            valueKey: 'organizationInvitationType',
          })}
          items={organizationInvitationTypeData}
          value={formik.values.organizationInvitationType}
          fieldTitleKey="label"
          fieldValueKey="value"
          errorMessage={formik?.errors.organizationInvitationType}
        />

        {isInvitedOrganizationTypeEnabled && (
          <FormSelect
            appearance="default"
            containerStyle={styles.inputElement}
            label="Organization Invitation Type"
            onSelect={onSelect({
              options: organizationTypeData,
              fieldValueKey: 'value',
              valueKey: 'invitedOrganizationType',
            })}
            items={organizationTypeData}
            value={formik.values.invitedOrganizationType}
            fieldTitleKey="label"
            fieldValueKey="value"
            errorMessage={formik?.errors.invitedOrganizationType}
            caption={
              <View style={styles.captionContainer}>
                {renderAlertIcon(styles.captionIcon)}
                <Text selectable={true} style={styles.captionText}>
                  The OrganizationType of Organizations that can use this Organization Invitation.
                </Text>
              </View>
            }
          />
        )}

        {isInvitingOrganizationEnabled && (
          <View style={styles.inputElement}>
            <FormAutocomplete
              appearance="default"
              label="Inviting organization - The Organization that can be joined / connected to using this Organization Invitation"
              placeholder="Select the inviting Organization that will be joined / connected to using this OrganizationInvitation"
              onSelect={onSelectInvitingOrganization}
              items={invitingOrganizations}
              value={formik.values.value}
              fieldTitleKey="title"
              fieldValueKey="id"
              errorMessage={formik?.errors.invitingOrganizationId}
            />
            {!!formik.values.invitingOrganizationId &&
              !!formik.values.invitingOrganizationValue && (
                <Text style={globalStyle.padding10}>
                  Selected <Text status="info">{formik.values.invitingOrganizationValue}</Text> with
                  Organization ID <Text status="info">{formik.values.invitingOrganizationId}</Text>{' '}
                  as inviting Organization.
                </Text>
              )}
          </View>
        )}

        {isInvitedOrganizationEnabled && (
          <View style={styles.inputElement}>
            <FormAutocomplete
              appearance="default"
              label="Invited organization - The Organization that can use this Organization Invitation"
              placeholder="Select an Organization that can exclusively use this OrganizationInvitation (optional)"
              onSelect={onSelectInvitedOrganization}
              items={invitedOrganizations}
              value={formik.values.value}
              fieldTitleKey="title"
              fieldValueKey="id"
              errorMessage={formik?.errors.invitedOrganizationId}
            />
            {!!formik.values.invitedOrganizationId && !!formik.values.invitedOrganizationValue && (
              <Text style={globalStyle.padding10}>
                Selected <Text status="info">{formik.values.invitedOrganizationValue}</Text> with
                Organization ID <Text status="info">{formik.values.invitedOrganizationId}</Text> as
                invited Organization.
              </Text>
            )}
          </View>
        )}

        {isOrganizationUserRoleTypeEnabled && (
          <FormSelect
            appearance="default"
            containerStyle={styles.inputElement}
            label="The Role Type of the User joining the inviting Organization using this Organization Invitation"
            onSelect={onSelect({
              options: roleTypeData,
              fieldValueKey: 'value',
              valueKey: 'roleType',
            })}
            items={roleTypeData}
            value={formik.values.roleType}
            fieldTitleKey="label"
            fieldValueKey="value"
            errorMessage={formik?.errors.roleType}
            placeholder="Role Type"
            caption={
              <View style={styles.captionContainer}>
                {renderAlertIcon(styles.captionIcon)}
                <Text selectable={true} style={styles.captionText}>
                  Please select the Role Type with which the User will join the selected
                  Organization.
                </Text>
              </View>
            }
          />
        )}

        <Button
          style={[globalStyle.alignSelfEnd, globalStyle.marginTop20]}
          onPress={handleCreateOrganizationInvitation}
        >
          Create Organization Invitation
        </Button>
      </ScrollView>
    </TransparentModal>
  );
};

const styles = StyleService.create({
  container: {
    maxWidth: Platform.OS === 'web' ? 600 : 'auto',
  },
  inputField: {
    marginVertical: 5,
  },
  captionContainer: {
    paddingTop: 5,
    paddingLeft: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  captionIcon: {
    width: 10,
    height: 10,
  },
  captionText: {
    fontSize: 12,
    paddingLeft: 5,
  },
  inputElement: {
    marginBottom: 15,
  },
  button: {
    maxWidth: Platform.OS === 'web' ? 500 : 'auto',
  },
});
