import {
  USER_BACKGROUND_DOCUMENTS_QUERY,
  USER_FREQUENT_FLYER_DOCUMENTS_QUERY,
  USER_HEALTH_REPORTS_QUERY,
  USER_IDENTITY_DOCUMENTS_QUERY,
  USER_OTHER_DOCUMENTS_QUERY,
  USER_TRAVEL_DOCUMENTS_QUERY,
  USER_VACCINATION_DOCUMENTS_QUERY,
} from '../../../apollo/graphql-queries';
import i18n from '../../../i18n/i18n';

export const DOCUMENT_DATA: any = {
  UserBackgroundDocument: {
    query: USER_BACKGROUND_DOCUMENTS_QUERY,
    queryKey: 'userBackgroundDocuments',
    title: i18n.t('menuItems.backgroundDocument', {
      defaultValue: 'Background Documents',
    }),
  },
  UserFrequentFlyerDocument: {
    query: USER_FREQUENT_FLYER_DOCUMENTS_QUERY,
    queryKey: 'userFrequentFlyerDocuments',
    title: i18n.t('menuItems.frequentFlyerDocuments', {
      defaultValue: 'Frequent Flyer Documents',
    }),
  },
  UserHealthReport: {
    query: USER_HEALTH_REPORTS_QUERY,
    queryKey: 'userHealthReports',
    title: i18n.t('menuItems.healthTestReports', {
      defaultValue: 'Health Test Reports',
    }),
  },
  UserIdentityDocument: {
    query: USER_IDENTITY_DOCUMENTS_QUERY,
    queryKey: 'userIdentityDocuments',
    title: i18n.t('menuItems.identityDocument', { defaultValue: 'Identity Documents' }),
  },
  UserOtherDocument: {
    query: USER_OTHER_DOCUMENTS_QUERY,
    queryKey: 'userOtherDocuments',
    title: i18n.t('menuItems.otherDocument', { defaultValue: 'Other Documents' }),
  },
  UserTravelDocument: {
    query: USER_TRAVEL_DOCUMENTS_QUERY,
    queryKey: 'userTravelDocuments',
    title: i18n.t('menuItems.travelDocuments', { defaultValue: 'Travel Documents' }),
  },
  UserVaccinationDocument: {
    query: USER_VACCINATION_DOCUMENTS_QUERY,
    queryKey: 'userVaccinationDocuments',
    title: i18n.t('menuItems.vaccinationDocument', {
      defaultValue: 'Vaccination Documents',
    }),
  },
};
