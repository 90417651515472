export enum MutationUpdateType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface IEdge<T> {
  cursor: string;
  node: T;
}
