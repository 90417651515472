import React, { useState } from 'react';
import { Platform, Pressable, View } from 'react-native';

import { useActionSheet } from '@expo/react-native-action-sheet';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as ExpoDocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { globalStyle } from '../common/style';
import { CameraModal } from '../components/common/CameraModal';
import { pickImageOnFrontEnd } from '../utils/file-upload.util';

const generateFileName = (name: string) => {
  const fileName = name.split('.');
  fileName[fileName.length - 2] = `${fileName[fileName.length - 2]}_${new Date().valueOf()}`;
  return fileName.join('.');
};

export const useFileUploadActionSheet = ({ isModal = false, onSelectImage, onSelectPDF }: any) => {
  const { t } = useTranslation();
  const { showActionSheetWithOptions } = useActionSheet();
  const styles = useStyleSheet(themedStyles);
  const { bottom } = useSafeAreaInsets();

  const [visibleActionSheet, setVisibleActionSheet] = useState(false);
  const [visibleCamera, setVisibleCamera] = useState(false);

  const handleSelectTakePicture = async () => {
    setVisibleCamera(true);
  };

  const handleCloseCamera = async () => {
    setVisibleCamera(false);
  };

  const handleUploadCameraPicture = async (photo: any) => {
    try {
      if (photo) {
        onSelectImage({ ...photo, name: `${DateTime.now().toUnixInteger()}_camera.jpg` });
        setVisibleCamera(false);
        if (isModal) {
          setVisibleActionSheet(false);
        }
        return true;
      }
      if (isModal) {
        setVisibleActionSheet(false);
      }
      return false;
    } catch (e) {
      if (isModal) {
        setVisibleActionSheet(false);
      }
      return false;
    }
  };

  const handleSelectImageFile = async () => {
    const result: any = await pickImageOnFrontEnd();
    if (result !== null) {
      onSelectImage({
        ...result,
        name: generateFileName(result.fileName || 'image.jpeg'),
        originalName: result.fileName,
      });
    }
    if (isModal) {
      setVisibleActionSheet(false);
    }
  };

  const handleSelectPdfFile = async () => {
    const result = await ExpoDocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      multiple: false,
    });
    if (!result.canceled && result?.assets?.[0]) {
      const asset = result.assets[0];
      let base64 = asset.uri;
      if (!asset.uri.startsWith('data:application')) {
        base64 = await FileSystem.readAsStringAsync(base64, {
          encoding: FileSystem.EncodingType.Base64,
        });
      }
      onSelectPDF({
        ...asset,
        name: generateFileName(asset.name),
        originalName: asset.name,
        base64,
      });
      if (isModal) {
        setVisibleActionSheet(false);
      }
    }
  };

  const handleUploadFilesPress = () => {
    if (isModal) {
      setVisibleActionSheet(true);
    } else {
      const options = [
        t('common.takePhoto', { defaultValue: 'Take photo' }),
        t('common.addImage', { defaultValue: 'Add image' }),
        t('common.addPdfDocument', { defaultValue: 'Add PDF document' }),
        t('common.cancel', { defaultValue: 'Cancel' }),
      ];
      setVisibleActionSheet(true);
      showActionSheetWithOptions({ options, cancelButtonIndex: 3 }, (buttonIndex) => {
        if (Platform.OS === 'web') {
          if (buttonIndex === 0) {
            handleSelectTakePicture();
          } else if (buttonIndex === 1) {
            handleSelectImageFile();
          } else if (buttonIndex === 2) {
            handleSelectPdfFile();
          }
        } else {
          if (buttonIndex === 0) {
            handleSelectTakePicture();
          } else if (buttonIndex === 1) {
            handleSelectImageFile();
          } else if (buttonIndex === 2) {
            handleSelectPdfFile();
          }
        }
        setTimeout(() => {
          setVisibleActionSheet(false);
        }, 300);
      });
    }
  };

  const handleClose = () => {
    setVisibleActionSheet(false);
  };

  const renderCamera = () => (
    <CameraModal
      visible={visibleCamera}
      onClose={handleCloseCamera}
      onSelectPicture={handleUploadCameraPicture}
    />
  );

  const renderModal = () => (
    <Modal
      animationIn="slideInUp"
      isVisible={visibleActionSheet}
      style={[globalStyle.paddingLeft0, globalStyle.margin0, globalStyle.justifyContentEnd]}
      backdropOpacity={0.8}
      onBackdropPress={handleClose}
      useNativeDriver
      hideModalContentWhileAnimating
    >
      <View style={[styles.modalWeb, { paddingBottom: bottom }]}>
        <View style={styles.modalContainer}>
          <Pressable
            onPress={handleSelectTakePicture}
            style={[styles.button, styles.buttonBottomBorder]}
          >
            <Text style={styles.buttonText}>
              {t('common.takePhoto', { defaultValue: 'Take photo' })}
            </Text>
          </Pressable>
          <Pressable
            onPress={handleSelectImageFile}
            style={[styles.button, styles.buttonBottomBorder]}
          >
            <Text style={styles.buttonText}>
              {t('common.addImage', { defaultValue: 'Add image' })}
            </Text>
          </Pressable>
          <Pressable
            onPress={handleSelectPdfFile}
            style={[styles.button, styles.buttonBottomBorder]}
          >
            <Text style={styles.buttonText}>
              {t('common.addPdfDocument', { defaultValue: 'Add PDF document' })}
            </Text>
          </Pressable>
          <Pressable onPress={handleClose} style={styles.button}>
            <Text style={styles.buttonText}>{t('common.cancel', { defaultValue: 'Cancel' })}</Text>
          </Pressable>
        </View>
      </View>

      {renderCamera()}
    </Modal>
  );

  return {
    handleUploadFilesPress,
    renderCamera,
    renderModal,
    visibleActionSheet,
    visibleCamera,
  };
};

export const themedStyles = StyleService.create({
  modalWeb: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  modalContainer: {
    overflow: 'hidden',
    maxHeight: '100%',
    width: '100%',
    maxWidth: 1200,
  },
  button: {
    padding: 15,
  },
  buttonBottomBorder: {
    borderBottomColor: '#eaeaea',
    borderBottomWidth: 1,
  },
  buttonText: {
    color: 'black',
  },
});
