import React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';

import { Select, SelectItem, Text, useStyleSheet } from '@ui-kitten/components';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { Props } from './types';
import { FormSelectModal } from '../FormSelectModal';

export const FormSelect = (props: Props): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);

  const { handleClosePress, handleOpenPress, handleSelect, isVisible, opened, placeholder } =
    useHook(props);

  const renderChildren = () => {
    if (Platform.OS !== 'web') {
      return <></>;
    }
    if (props.children) {
      return props.children;
    }
    return props.items.map(renderItem);
  };

  const renderMultiSelectOption = (object: any) => (
    <SelectItem
      title={object[props.fieldTitleKey]}
      selected={props.value?.includes(object[props.fieldValueKey])}
      key={object[props.fieldTitleKey] + object[props.fieldValueKey]}
    />
  );

  const renderOption = (object: any) => (
    <SelectItem
      disabled={object.disabled}
      title={object[props.fieldTitleKey]}
      selected={props.value === object[props.fieldValueKey]}
      key={object[props.fieldTitleKey] + object[props.fieldValueKey]}
    />
  );

  const renderItem = (item: any) => {
    if (props.multiSelect) {
      return renderMultiSelectOption(item);
    }
    return renderOption(item);
  };

  return (
    <>
      <View style={[styles.container, props.containerStyle]}>
        <Select
          {...props}
          onSelect={handleSelect}
          placeholder={placeholder}
          value={undefined}
          status={props.errorMessage ? 'danger' : props.status}
          caption={undefined} // disable default caption.
          label={
            props.label ? (
              <View style={styles.labelContainer}>
                <Text selectable={true} style={styles.label}>
                  <>
                    {props.label as string}
                    {!!props.isMandatory && (
                      <Text selectable={true} style={styles.mandatoryStar}>
                        *
                      </Text>
                    )}
                  </>
                </Text>
              </View>
            ) : (
              <></>
            )
          }
        >
          {renderChildren()}
        </Select>
        {!!props.caption && !props.renderCaption && (
          <View style={styles.captionContainer}>
            <Text selectable={true} style={styles.caption}>
              {props.caption as string}
            </Text>
          </View>
        )}
        {!!props.renderCaption && props.renderCaption}

        {!!props.errorMessage && (
          <View style={styles.captionContainer}>
            <Text selectable={true} style={styles.errorMessageText}>
              {props.errorMessage}
            </Text>
          </View>
        )}

        {Platform.OS !== 'web' && (
          <TouchableOpacity style={styles.button} onPress={handleOpenPress} />
        )}
      </View>
      {opened && (
        <FormSelectModal
          items={props.items}
          fieldTitleKey={props.fieldTitleKey}
          fieldValueKey={props.fieldValueKey}
          handleClose={handleClosePress}
          title={props.label as string}
          multiSelect={props.multiSelect}
          onSelect={props.onSelect as any}
          value={props.value}
          isVisible={isVisible}
        />
      )}
    </>
  );
};
