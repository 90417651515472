import React, { Fragment, memo, useMemo } from 'react';
import { View } from 'react-native';

import { Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { styles as themedStyles } from './styles';
import { globalStyle } from '../../../../../common/style';
import { MessageType } from '../../../../../generated-graphql-types';

export const ChatMessageTrackingPointLegComponent: React.FC<any> = ({
  currentMessage,
  isCurrentUser,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { label, status }: { label: string; status: string } = useMemo(() => {
    let _status = t('jobs.flightInformationAdded', { defaultValue: 'Flight information added' });
    if (currentMessage.messageType === MessageType.TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE) {
      _status = t('jobs.flightDocumentsUpdated', { defaultValue: 'Flight documents updated' });
    } else if (currentMessage.messageType === MessageType.TRACKER_FLIGHT_DETAILS_REMOVED) {
      _status = t('jobs.flightInformationRemoved', { defaultValue: 'Flight information removed' });
    } else if (currentMessage.messageType === MessageType.TRACKER_FLIGHT_DETAILS_UPDATED) {
      _status = t('jobs.flightInformationUpdated', { defaultValue: 'Flight information updated' });
    }
    return { label: currentMessage.message, status: _status };
  }, [currentMessage, t]);

  return (
    <View
      key={Math.random()}
      style={[
        globalStyle.marginTop5,
        isCurrentUser ? globalStyle.alignItemsEnd : globalStyle.alignItemsStart,
      ]}
    >
      <Text
        style={[
          styles.messageStatusText,
          globalStyle.fontLatoBold,
          isCurrentUser ? styles.textRight : styles.textLeft,
        ]}
        selectable
      >
        {status}
      </Text>
      <View style={[globalStyle.marginTop10, globalStyle.marginBottom10]}>
        {label.split('.').map((text) => {
          if (!text.length) {
            return <Fragment key={text} />;
          }
          return (
            <Text
              key={text}
              style={[styles.messageText, isCurrentUser ? styles.textRight : styles.textLeft]}
              selectable
            >
              {text}.
            </Text>
          );
        })}
      </View>
    </View>
  );
};

const arePropsEqual = (prevProps: any, nextProps: any): boolean => {
  if (prevProps?.currentMessage?.messageType !== nextProps?.currentMessage?.messageType) {
    return false;
  }
  return true;
};

export const ChatMessageTrackingPointLeg = memo(
  ChatMessageTrackingPointLegComponent,
  arePropsEqual,
);
