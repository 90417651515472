import React from 'react';
import { View } from 'react-native';

import { useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../../common/style';
import { FormAirportSelect } from '../../../../components/common/form/FormAirportSelect';
import ContentBoxTextTitle from '../../../../components/content-box-text-title.component';

export const OBCBaseAirportsEdit = ({
  errors,
  onSelectAirport,
  values,
}: any): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const { airportData, handleAirportDataLoaded } = useHook();

  return (
    <ContentBoxTextTitle
      title={t('courierUserProfile.baseAirports', {
        defaultValue: 'Base Airports',
      }).toUpperCase()}
      Content={
        <View style={styles.row}>
          <FormAirportSelect
            containerStyle={[globalStyle.flex1, globalStyle.marginRight5, globalStyle.marginTop0]}
            disabled={!airportData?.length}
            fieldTitleKey="iataCode"
            fieldTitleKeyModal="formattedAddress"
            fieldValueKey="iataCode"
            hideIcon
            onDataLoadCompleted={handleAirportDataLoaded}
            onSelect={onSelectAirport('baseAirport1')}
            errorMessage={errors.baseAirport1}
            value={values.baseAirport1}
          />

          <FormAirportSelect
            containerStyle={[globalStyle.flex1, globalStyle.marginRight5, globalStyle.marginTop0]}
            data={airportData}
            disabled={!airportData?.length}
            fieldTitleKey="iataCode"
            fieldTitleKeyModal="formattedAddress"
            fieldValueKey="iataCode"
            hideIcon
            skipFetch
            loading={!airportData.length}
            onSelect={onSelectAirport('baseAirport2')}
            errorMessage={errors.baseAirport2}
            value={values.baseAirport2}
          />
          <FormAirportSelect
            containerStyle={[globalStyle.flex1, globalStyle.marginTop0]}
            data={airportData}
            disabled={!airportData?.length}
            fieldTitleKey="iataCode"
            fieldTitleKeyModal="formattedAddress"
            fieldValueKey="iataCode"
            hideIcon
            skipFetch
            loading={!airportData.length}
            onSelect={onSelectAirport('baseAirport3')}
            errorMessage={errors.baseAirport3}
            value={values.baseAirport3}
          />
        </View>
      }
      style={styles.contentBox}
    />
  );
};

const arePropsEqual = (prev: any, next: any) => {
  const keys: any[] = ['baseAirport1', 'baseAirport2', 'baseAirport3'];
  if (keys.some((key) => next?.values[key] !== prev?.values[key])) {
    return false;
  }
  if (keys.some((key) => next?.errors[key] !== prev?.errors[key])) {
    return false;
  }
  return true;
};

export default React.memo(OBCBaseAirportsEdit, arePropsEqual);
