import * as Clipboard from 'expo-clipboard';
import { showMessage } from 'react-native-flash-message';

import { CheckOutlineIcon } from '../components/icons.component';
import i18n from '../i18n/i18n';

export const SOCIAL_OPTIONS: {
  [key: string]: { label: string; placeholder: string; type: 'link' | 'handle' };
} = {
  linkedin: { label: 'LinkedIn', placeholder: 'LinkedIn link', type: 'link' },
  facebook: { label: 'Facebook', placeholder: 'Facebook username or link', type: 'link' },
  instagram: { label: 'Instagram', placeholder: 'Instagram username', type: 'handle' },
  twitter: { label: 'Twitter', placeholder: 'Twitter username', type: 'handle' },
  youtube: { label: 'YouTube', placeholder: 'YouTube link', type: 'link' },
  telegram: {
    label: 'Telegram',
    placeholder: 'Telegram username or phone',
    type: 'handle',
  },
  xing: { label: 'Xing', placeholder: 'Xing link', type: 'link' },
  wechat: { label: 'WeChat', placeholder: 'WeChatId', type: 'handle' },
  tiktok: { label: 'TikTok', placeholder: 'TikTok username', type: 'handle' },
};

export const SOCIAL_KEYS = [
  'linkedin',
  'twitter',
  'xing',
  'facebook',
  'instagram',
  'wechat',
  'telegram',
  'tiktok',
  'youtube',
];

export const createSocialURL = ({ platform, value }: { platform: string; value: string }) => {
  let copyText = value;

  switch (platform) {
    case 'facebook':
      if (value.includes('facebook.com')) {
        copyText = value;
      } else if (value) {
        copyText = `https://facebook.com/${value}`;
      }
      break;
    case 'instagram':
      if (value.includes('instagram.com')) {
        copyText = value;
      } else if (value) {
        copyText = `https://instagram.com/${value}`;
      }
      break;
    case 'twitter':
      if (value.includes('twitter.com')) {
        copyText = value;
      } else if (value) {
        copyText = `https://twitter.com/${value}`;
      }
      break;
    case 'tiktok':
      if (value.includes('tiktok.com')) {
        copyText = value.replace('@@', '@');
      } else if (value) {
        copyText = `https://tiktok.com/${value}`.replace('@@', '@');
      }
      break;
    default:
      copyText = value;
  }
  return copyText;
};

export const copySocialToClipboard =
  ({ platform, value }: { platform: string; value: string }) =>
  () => {
    const copyText = createSocialURL({ platform, value });
    Clipboard.setStringAsync(copyText);
    showMessage({
      message: i18n.t('common.Copied!', { defaultValue: 'Copied!' }),
      description: copyText,
      renderFlashMessageIcon: CheckOutlineIcon,
      type: 'success',
    });
  };
