import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import { DevicePermissionExpiration } from './types';
import { DateTime } from 'luxon';
import { JSONObject } from './types';
import * as Apollo from '@apollo/client';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: DateTime; output: DateTime };
  DevicePermissionExpiration: {
    input: DevicePermissionExpiration;
    output: DevicePermissionExpiration;
  };
  JSONObject: { input: JSONObject; output: JSONObject };
};

export type AddDeviceLogInput = {
  data?: InputMaybe<DeviceLogDataInput>;
  id: Scalars['String']['input'];
  isCurrentUserContextAvailable: Scalars['Boolean']['input'];
  timestamp: Scalars['DateTime']['input'];
  triggerType: DeviceLogTriggerType;
};

export type AddDeviceLogOutput = {
  __typename?: 'AddDeviceLogOutput';
  deviceLogIds: Array<Scalars['String']['output']>;
  lastDeviceLog?: Maybe<DeviceLogOutput>;
};

export type AgencyCourierJobChatInput = {
  courierJobId: Scalars['String']['input'];
};

/** Agency Courier User Profile Entity */
export type AgencyCourierUserProfile = {
  __typename?: 'AgencyCourierUserProfile';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Internal comment about Courier */
  internalComment?: Maybe<Scalars['String']['output']>;
  /** Internal comment about Courier */
  markedForAttention: Scalars['Boolean']['output'];
  organizationAgency: Organization;
  organizationCourierCategories?: Maybe<Array<OrganizationCourierCategory>>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type AgencyCourierUserProfileCreateOrUpdateInput = {
  /** Internal comment about Courier */
  internalComment?: InputMaybe<Scalars['String']['input']>;
  /** Internal comment about Courier */
  markedForAttention?: Scalars['Boolean']['input'];
  organizationCourierCategoryId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

/** Reason why this Job was declined by the Agency */
export enum AgencyDeclinedReasonType {
  AGENCY_CANCELLED = 'AGENCY_CANCELLED',
}

/** AgencyJob Entity */
export type AgencyJob = {
  __typename?: 'AgencyJob';
  acceptedCourierJobsChat?: Maybe<Chat>;
  /** Additional cost information more information details */
  additionalCostInformation?: Maybe<Scalars['String']['output']>;
  agencyJobChat?: Maybe<Chat>;
  agencyJobPlanning?: Maybe<AgencyJobPlanning>;
  /** AgencyJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_CLIENT_CONFIRMED=4) */
  agencyJobStatusType: AgencyJobStatusType;
  allCourierJobsChat?: Maybe<Chat>;
  cancelledByAgencyAt?: Maybe<Scalars['DateTime']['output']>;
  /** Local (endAirport) latest check-in time in UTC for the return flight */
  checkInTimeAtEndAirport?: Maybe<Scalars['DateTime']['output']>;
  /** Local (startAirport) latest check-in time in UTC */
  checkInTimeAtStartAirport?: Maybe<Scalars['DateTime']['output']>;
  /** Reason why this Job was declined by the Client */
  clientDeclinedReasonType?: Maybe<ClientDeclinedReasonType>;
  /** The fixed rate for the Job payable by Client as proposed by the Agency. */
  clientFixedRate?: Maybe<Scalars['Float']['output']>;
  clientJob: ClientJob;
  /** Any comment about the Job from the Agency */
  comment?: Maybe<Scalars['String']['output']>;
  confirmedFinishedByClientAt?: Maybe<Scalars['DateTime']['output']>;
  courierJobs?: Maybe<Array<CourierJob>>;
  createdAt: Scalars['DateTime']['output'];
  /** Currency in which the Agency pays the Courier. (EUR=0, USD=1) */
  currency: Currency;
  /** The daily rate for the Job for the Courier as proposed and payable by Agency. */
  dailyRate?: Maybe<Scalars['Float']['output']>;
  endAirport?: Maybe<Location>;
  /** External Job Reference from the Agency */
  externalJobReference: Scalars['String']['output'];
  finishedByAgencyAt?: Maybe<Scalars['DateTime']['output']>;
  /** The fixed rate for the Job for the Courier as proposed and payable by Agency. */
  fixedRate?: Maybe<Scalars['Float']['output']>;
  /** Whether an incident has occurred for on Job for the Agency */
  hasIncident: Scalars['Boolean']['output'];
  /** The hotel budget for the Job for the Courier as proposed and payable by Agency. */
  hotelBudget?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Internal agency job notes */
  internalNotes?: Maybe<Scalars['String']['output']>;
  /** Wether the accommodation booked by the Courier as proposed by Agency. */
  isAccommodationBookedByCourier: Scalars['Boolean']['output'];
  /** Whether the accommodation paid by the Courier as proposed by Agency. */
  isAccommodationPaidByCourier: Scalars['Boolean']['output'];
  /** Whether the additional costs are paid by the Courier */
  isAdditionalCostPaidByCourier: Scalars['Boolean']['output'];
  /** is car required for the job */
  isCarRequiredForJob: Scalars['Boolean']['output'];
  /** is driving license required for the job */
  isDrivingLicenseRequiredForJob: Scalars['Boolean']['output'];
  job: Job;
  /** Specific instructions or details provided by the Agency for the Courier */
  jobDetails?: Maybe<Scalars['String']['output']>;
  /** The kick off fee for the Job for the Courier as proposed and payable by Agency. */
  kickOffFee?: Maybe<Scalars['Float']['output']>;
  /** The number of Couriers required to do the job */
  numOfCouriersRequired: Scalars['Float']['output'];
  /** The number of items transported in the job */
  numOfItems: Scalars['Float']['output'];
  /** AgencyJob to Client relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, CLIENT_REQUESTED=3, CLIENT_ACCEPTED=4, CLIENT_DECLINED=5) */
  relationshipType: AgencyJobClientRelationshipType;
  responsibleAgencyOrganization: Organization;
  responsibleAgencyOrganizationUsers: Array<OrganizationUser>;
  responsibleAgencyUser: User;
  /** Local (endAirport) return time in UTC after finishing the job */
  returnTimeAtEndAirport?: Maybe<Scalars['DateTime']['output']>;
  /** Local (startAirport) return time in UTC after finishing the job */
  returnTimeAtStartAirport?: Maybe<Scalars['DateTime']['output']>;
  startAirport?: Maybe<Location>;
  startedByAgencyAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** The total weights of items transported in the job */
  weightOfItems?: Maybe<Scalars['Float']['output']>;
};

export type AgencyJobChatInput = {
  agencyJobId: Scalars['String']['input'];
};

/** The Client-Agency Relationship Type (Status) of this AgencyJob. */
export enum AgencyJobClientRelationshipType {
  AGENCY_ACCEPTED = 'AGENCY_ACCEPTED',
  AGENCY_DECLINED = 'AGENCY_DECLINED',
  AGENCY_REQUESTED = 'AGENCY_REQUESTED',
  CLIENT_ACCEPTED = 'CLIENT_ACCEPTED',
  CLIENT_DECLINED = 'CLIENT_DECLINED',
  CLIENT_REQUESTED = 'CLIENT_REQUESTED',
}

export type AgencyJobConnection = {
  __typename?: 'AgencyJobConnection';
  edges?: Maybe<Array<AgencyJobEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AgencyJobEdge = {
  __typename?: 'AgencyJobEdge';
  cursor: Scalars['String']['output'];
  node: AgencyJob;
};

export type AgencyJobInput = {
  /** Additional cost information more information details */
  additionalCostInformation?: InputMaybe<Scalars['String']['input']>;
  /** Local (endAirport) latest check-in time in UTC for the return flight */
  checkInTimeAtEndAirport?: InputMaybe<Scalars['DateTime']['input']>;
  /** Local (startAirport) latest check-in time in UTC */
  checkInTimeAtStartAirport?: InputMaybe<Scalars['DateTime']['input']>;
  /** The fixed rate for the Job payable by Client as proposed by the Agency. */
  clientFixedRate?: InputMaybe<Scalars['Float']['input']>;
  /** Any comment about the Job from the Agency */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Currency in which the Agency pays the Courier. (EUR=0, USD=1) */
  currency: Currency;
  /** The daily rate for the Job for the Courier as proposed and payable by Agency. */
  dailyRate?: InputMaybe<Scalars['Float']['input']>;
  endAirportSuggestedLocationInput?: InputMaybe<SuggestedLocationInput>;
  /** External Job Reference from the Agency */
  externalJobReference: Scalars['String']['input'];
  /** The fixed rate for the Job for the Courier as proposed and payable by Agency. */
  fixedRate?: InputMaybe<Scalars['Float']['input']>;
  /** The hotel budget for the Job for the Courier as proposed and payable by Agency. */
  hotelBudget?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Internal agency job notes */
  internalNotes?: InputMaybe<Scalars['String']['input']>;
  /** Wether the accommodation booked by the Courier as proposed by Agency. */
  isAccommodationBookedByCourier: Scalars['Boolean']['input'];
  /** Whether the accommodation paid by the Courier as proposed by Agency. */
  isAccommodationPaidByCourier: Scalars['Boolean']['input'];
  /** Whether the additional costs are paid by the Courier */
  isAdditionalCostPaidByCourier: Scalars['Boolean']['input'];
  /** is car required for the job */
  isCarRequiredForJob?: Scalars['Boolean']['input'];
  /** is driving license required for the job */
  isDrivingLicenseRequiredForJob: Scalars['Boolean']['input'];
  /** Specific instructions or details provided by the Agency for the Courier */
  jobDetails?: InputMaybe<Scalars['String']['input']>;
  /** The kick off fee for the Job for the Courier as proposed and payable by Agency. */
  kickOffFee?: InputMaybe<Scalars['Float']['input']>;
  /** The number of Couriers required to do the job */
  numOfCouriersRequired: Scalars['Float']['input'];
  /** The number of items transported in the job */
  numOfItems: Scalars['Float']['input'];
  responsibleAgencyOrganizationUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Local (endAirport) return time in UTC after finishing the job */
  returnTimeAtEndAirport?: InputMaybe<Scalars['DateTime']['input']>;
  /** Local (startAirport) return time in UTC after finishing the job */
  returnTimeAtStartAirport?: InputMaybe<Scalars['DateTime']['input']>;
  startAirportSuggestedLocationInput?: InputMaybe<SuggestedLocationInput>;
  /** The total weights of items transported in the job */
  weightOfItems?: InputMaybe<Scalars['Float']['input']>;
};

/** Courier Job Planning Entity. */
export type AgencyJobPlanning = {
  __typename?: 'AgencyJobPlanning';
  agencyJob: AgencyJob;
  agencyJobPlanningItems: Array<AgencyJobPlanningItem>;
  agencyJobPlanningLegs: Array<AgencyJobPlanningLeg>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Courier Job Planning File Entity. */
export type AgencyJobPlanningFile = {
  __typename?: 'AgencyJobPlanningFile';
  agencyJobPlanningItem?: Maybe<AgencyJobPlanningLeg>;
  agencyJobPlanningItemHistoryLog?: Maybe<AgencyJobPlanningItemHistoryLog>;
  agencyJobPlanningLeg?: Maybe<AgencyJobPlanningLeg>;
  createdAt: Scalars['DateTime']['output'];
  /** File Metadata */
  fileBlobMetadata: StoredAssetMetadata;
  /** File blob name in azure storage */
  fileBlobPath: Scalars['String']['output'];
  /** container name in azure storage */
  fileContainerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  storedAsset?: Maybe<StoredAsset>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AgencyJobPlanningForAgencyJobUpdateInput = {
  agencyJobPlanningItems: Array<AgencyJobPlanningItemInput>;
  agencyJobPlanningLegIdsToDelete: Array<Scalars['String']['input']>;
  agencyJobPlanningLegs: Array<AgencyJobPlanningLegInput>;
};

export enum AgencyJobPlanningFormType {
  GENERIC_FORM_A = 'GENERIC_FORM_A',
  GENERIC_FORM_B = 'GENERIC_FORM_B',
}

/** Courier Job Planning Entity. */
export type AgencyJobPlanningItem = {
  __typename?: 'AgencyJobPlanningItem';
  active: Scalars['Boolean']['output'];
  actualDateTime?: Maybe<Scalars['DateTime']['output']>;
  actualDateTimeSetAt?: Maybe<Scalars['DateTime']['output']>;
  agencyJobPlanning?: Maybe<AgencyJobPlanningItem>;
  agencyJobPlanningItemHistoryLogs?: Maybe<Array<AgencyJobPlanningItemHistoryLog>>;
  agencyJobPlanningLeg?: Maybe<AgencyJobPlanningLeg>;
  createdAt: Scalars['DateTime']['output'];
  files: Array<AgencyJobPlanningFile>;
  formType: AgencyJobPlanningFormType;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Label of the Courier job planning leg item */
  label: Scalars['String']['output'];
  location?: Maybe<Location>;
  /** Name of the Organization */
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order: Scalars['Float']['output'];
  targetDateTime?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AgencyJobPlanningItemHistoryLog = {
  __typename?: 'AgencyJobPlanningItemHistoryLog';
  active: Scalars['Boolean']['output'];
  actualDateTime?: Maybe<Scalars['DateTime']['output']>;
  actualDateTimeSetAt?: Maybe<Scalars['DateTime']['output']>;
  agencyJobPlanningItem: AgencyJobPlanningItem;
  agencyJobPlanningLeg?: Maybe<AgencyJobPlanningLeg>;
  chatMessage?: Maybe<ChatMessage>;
  createdAt: Scalars['DateTime']['output'];
  files: Array<AgencyJobPlanningFile>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Label of the Courier job planning leg item */
  label: Scalars['String']['output'];
  location?: Maybe<Location>;
  /** Name of the Organization */
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  order: Scalars['Float']['output'];
  targetDateTime?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AgencyJobPlanningItemInput = {
  active: Scalars['Boolean']['input'];
  field: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  targetDateTime?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AgencyJobPlanningItemUpdateInput = {
  actualDateTime?: InputMaybe<Scalars['DateTime']['input']>;
  agencyJobPlanningItemId: Scalars['String']['input'];
  files?: InputMaybe<Array<FilesUploadInput>>;
  isLegItem?: Scalars['Boolean']['input'];
  location?: InputMaybe<SuggestedLocationInput>;
  note: Scalars['String']['input'];
};

/** Courier Job Planning Left Entity. */
export type AgencyJobPlanningLeg = {
  __typename?: 'AgencyJobPlanningLeg';
  active: Scalars['Boolean']['output'];
  agencyJobPlanning?: Maybe<AgencyJobPlanning>;
  agencyJobPlanningItemHistoryLogs: Array<AgencyJobPlanningItemHistoryLog>;
  agencyJobPlanningItems: Array<AgencyJobPlanningItem>;
  createdAt: Scalars['DateTime']['output'];
  departureDate?: Maybe<Scalars['DateTime']['output']>;
  flightCarrierDesignation?: Maybe<Scalars['String']['output']>;
  flightNumber?: Maybe<Scalars['String']['output']>;
  flightTickets: Array<AgencyJobPlanningFile>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  order: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AgencyJobPlanningLegInput = {
  active: Scalars['Boolean']['input'];
  agencyJobPlanningItems: Array<AgencyJobPlanningItemInput>;
  departureDate?: InputMaybe<Scalars['DateTime']['input']>;
  flightCarrierDesignation: Scalars['String']['input'];
  flightNumber: Scalars['String']['input'];
  flightTicketIdsToDelete?: InputMaybe<Array<Scalars['String']['input']>>;
  flightTickets?: InputMaybe<Array<FilesUploadInput>>;
  id?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
};

/** The State of the AgencyJob. */
export enum AgencyJobStatusType {
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  FINISHED_CLIENT_CONFIRMED = 'FINISHED_CLIENT_CONFIRMED',
  NEW = 'NEW',
  STARTED = 'STARTED',
}

export type AgencyJobsForAgencyOrganizationInput = {
  agencyJobStatusType?: InputMaybe<Array<AgencyJobStatusType>>;
  agencyOrganizationId: Scalars['String']['input'];
};

export type AgencyOrganizationProfileInput = {
  /** compressed Logo blob name in azure storage */
  compressedLogoBlobName?: InputMaybe<Scalars['String']['input']>;
  compressedLogoMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Description and intro text of the Organization. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Primary email address of the Organization. */
  email?: InputMaybe<Scalars['String']['input']>;
  headquartersAddressLocation?: InputMaybe<LocationManualInput>;
  id: Scalars['ID']['input'];
  /** Invoice email address of the Organization. */
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressLocation?: InputMaybe<LocationManualInput>;
  /** Legal type of the organization or business entity. Can be blank. */
  legalEntityType?: InputMaybe<Scalars['String']['input']>;
  /** Legal Name of the Organization. For private individuals it's most of the times their name. */
  legalName: Scalars['String']['input'];
  /** original Logo container name in azure storage */
  logoContainerName?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Organization */
  name: Scalars['String']['input'];
  /** The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5) */
  organizationType: OrganizationType;
  /** original Logo blob name in azure storage */
  originalLogoBlobName?: InputMaybe<Scalars['String']['input']>;
  originalLogoMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Primary phone number of the Organization. */
  phone?: InputMaybe<Scalars['String']['input']>;
  registrationAddressLocation?: InputMaybe<LocationManualInput>;
  /** Registration number of the Organization. */
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  /** Short name of the Organization */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** Social profiles */
  socialProfiles?: InputMaybe<Array<SocialProfileInput>>;
  /** tiny Logo blob name in azure storage */
  tinyLogoBlobName?: InputMaybe<Scalars['String']['input']>;
  tinyLogoMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** VAT ID usually for Agency Organizations */
  vatId?: InputMaybe<Scalars['String']['input']>;
};

export type AgencyOrganizationVatIdInput = {
  id: Scalars['ID']['input'];
  /** VAT ID usually for Agency Organizations */
  vatId?: InputMaybe<Scalars['String']['input']>;
};

/** The type of Airline Alliance Status. */
export enum AirlineAllianceStatusType {
  NONE = 'NONE',
  ONEWORLD_EMERALD = 'ONEWORLD_EMERALD',
  ONEWORLD_RUBY = 'ONEWORLD_RUBY',
  ONEWORLD_SAPPHIRE = 'ONEWORLD_SAPPHIRE',
  SKYTEAM_ELITE = 'SKYTEAM_ELITE',
  SKYTEAM_ELITE_PLUS = 'SKYTEAM_ELITE_PLUS',
  STAR_ALLIANCE_GOLD = 'STAR_ALLIANCE_GOLD',
  STAR_ALLIANCE_SILVER = 'STAR_ALLIANCE_SILVER',
}

/** The type of Airline Alliance. */
export enum AirlineAllianceType {
  NONE = 'NONE',
  ONE_WORLD = 'ONE_WORLD',
  SKY_TEAM = 'SKY_TEAM',
  STAR_ALLIANCE = 'STAR_ALLIANCE',
}

/** The type of Airline Status. */
export enum AirlineStatusType {
  ALIST = 'ALIST',
  ALIST_PREFERRED = 'ALIST_PREFERRED',
  AMBASSADOR = 'AMBASSADOR',
  ANA_MILLION_MILER = 'ANA_MILLION_MILER',
  BASIC = 'BASIC',
  BLUE = 'BLUE',
  BRONZE = 'BRONZE',
  BURGUNDY = 'BURGUNDY',
  CLASSIC = 'CLASSIC',
  CLASSIC_PLUS = 'CLASSIC_PLUS',
  CONCIERGE = 'CONCIERGE',
  CONCIERGE_KEY = 'CONCIERGE_KEY',
  DIAMOND = 'DIAMOND',
  DIAMOND_MEDALLION = 'DIAMOND_MEDALLION',
  DIAMOND_PLUS = 'DIAMOND_PLUS',
  DYNASTY = 'DYNASTY',
  ELITE = 'ELITE',
  ELITE_GOLD = 'ELITE_GOLD',
  ELITE_PLUS = 'ELITE_PLUS',
  ELITE_SILVER = 'ELITE_SILVER',
  EMERALD = 'EMERALD',
  EXECUTIVE_PLATINUM = 'EXECUTIVE_PLATINUM',
  EXPLORER = 'EXPLORER',
  FRECCIA_ALATA_CLUB = 'FRECCIA_ALATA_CLUB',
  FRECCIA_ALATA_PLUS_CLUB = 'FRECCIA_ALATA_PLUS_CLUB',
  FRECCIA_ALATA_PLUS_PER_SEMPRE_CARD = 'FRECCIA_ALATA_PLUS_PER_SEMPRE_CARD',
  FREQUENT_TRAVELLER = 'FREQUENT_TRAVELLER',
  GLOBAL_SERVICES = 'GLOBAL_SERVICES',
  GOLD = 'GOLD',
  GOLDEN_EDGE_CLUB = 'GOLDEN_EDGE_CLUB',
  GOLD_MEDALLION = 'GOLD_MEDALLION',
  GOLD_SPARROW = 'GOLD_SPARROW',
  GREEN = 'GREEN',
  HON_CIRCLE = 'HON_CIRCLE',
  JGC_PREMIER = 'JGC_PREMIER',
  JMB_CRYSTAL = 'JMB_CRYSTAL',
  JMB_DIAMOND = 'JMB_DIAMOND',
  JMB_SAPPHIRE = 'JMB_SAPPHIRE',
  K1 = 'K1',
  K25 = 'K25',
  K35 = 'K35',
  K50 = 'K50',
  K75 = 'K75',
  KRISFLYER = 'KRISFLYER',
  MEMBER = 'MEMBER',
  MILLION_MILER_CLUB = 'MILLION_MILER_CLUB',
  MORNING_CALM_CLUB = 'MORNING_CALM_CLUB',
  MORNING_CALM_PREMIUM_CLUB = 'MORNING_CALM_PREMIUM_CLUB',
  MOSAIC = 'MOSAIC',
  MVP = 'MVP',
  MVP_GOLD = 'MVP_GOLD',
  MVP_GOLD_75 = 'MVP_GOLD_75',
  OK_PLUS_GOLD = 'OK_PLUS_GOLD',
  OK_PLUS_PLATINUM = 'OK_PLUS_PLATINUM',
  OK_PLUS_SILVER = 'OK_PLUS_SILVER',
  ONE = 'ONE',
  PARAGON = 'PARAGON',
  PLATINUM = 'PLATINUM',
  PLATINUM_HAWK = 'PLATINUM_HAWK',
  PLATINUM_LUMO = 'PLATINUM_LUMO',
  PLATINUM_MEDALLION = 'PLATINUM_MEDALLION',
  PLATINUM_ONE = 'PLATINUM_ONE',
  PLATINUM_PRO = 'PLATINUM_PRO',
  PREMIER_ELITE = 'PREMIER_ELITE',
  PRESIDENTS_CLUB = 'PRESIDENTS_CLUB',
  RED_PLUS = 'RED_PLUS',
  RESIDENTIAL = 'RESIDENTIAL',
  S7_PRIORITY_GOLD = 'S7_PRIORITY_GOLD',
  S7_PRIORITY_PLATINUM = 'S7_PRIORITY_PLATINUM',
  S7_PRIORITY_SILVER = 'S7_PRIORITY_SILVER',
  SENATOR = 'SENATOR',
  SILVER = 'SILVER',
  SILVER_EDGE_CLUB = 'SILVER_EDGE_CLUB',
  SILVER_JAY = 'SILVER_JAY',
  SILVER_MEDALLION = 'SILVER_MEDALLION',
  SKYPASS = 'SKYPASS',
  SUMA_GOLD = 'SUMA_GOLD',
  SUMA_PLATINUM = 'SUMA_PLATINUM',
  SUMA_SILVER = 'SUMA_SILVER',
  SUPER_ELITE = 'SUPER_ELITE',
  SUPER_FLYER = 'SUPER_FLYER',
  THE_MAHARAJAH_CLUB = 'THE_MAHARAJAH_CLUB',
  TITANIUM = 'TITANIUM',
  ULISSE = 'ULISSE',
}

/** The type of Airline. */
export enum AirlineType {
  A3 = 'A3',
  AA = 'AA',
  AC = 'AC',
  AF = 'AF',
  AI = 'AI',
  AM = 'AM',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AV = 'AV',
  AY = 'AY',
  AZ = 'AZ',
  B6 = 'B6',
  BA = 'BA',
  BR = 'BR',
  CA = 'CA',
  CI = 'CI',
  CM = 'CM',
  CX = 'CX',
  DL = 'DL',
  EI = 'EI',
  EK = 'EK',
  EN = 'EN',
  ET = 'ET',
  EY = 'EY',
  GA = 'GA',
  IB = 'IB',
  JL = 'JL',
  KE = 'KE',
  KL = 'KL',
  KQ = 'KQ',
  LH = 'LH',
  LO = 'LO',
  LX = 'LX',
  ME = 'ME',
  MF = 'MF',
  MH = 'MH',
  MS = 'MS',
  MU = 'MU',
  NH = 'NH',
  NZ = 'NZ',
  OK = 'OK',
  OS = 'OS',
  OU = 'OU',
  OZ = 'OZ',
  PR = 'PR',
  QF = 'QF',
  QR = 'QR',
  RJ = 'RJ',
  RO = 'RO',
  S7 = 'S7',
  SA = 'SA',
  SK = 'SK',
  SN = 'SN',
  SQ = 'SQ',
  SU = 'SU',
  SV = 'SV',
  TG = 'TG',
  TK = 'TK',
  TP = 'TP',
  TU = 'TU',
  UA = 'UA',
  UL = 'UL',
  UX = 'UX',
  VN = 'VN',
  WN = 'WN',
  ZH = 'ZH',
}

export type AirportLocationConnection = {
  __typename?: 'AirportLocationConnection';
  edges?: Maybe<Array<LocationEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type AirportLocationListInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AirportLocationSearchInput = {
  searchText: Scalars['String']['input'];
};

export type AirportLocationUpdateTimezoneInput = {
  airportLocationId?: InputMaybe<Scalars['ID']['input']>;
  timeZoneIdentifier?: InputMaybe<Scalars['String']['input']>;
  timeZoneRawOffset?: InputMaybe<Scalars['Float']['input']>;
};

export type AuthSignOutUserContextInput = {
  deviceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** Availabilities Entity */
export type Availability = {
  __typename?: 'Availability';
  availabilityLocations?: Maybe<Array<AvailabilityLocation>>;
  createdAt: Scalars['DateTime']['output'];
  /** End Time */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Is AdHoc Availability */
  isAdHocAvailability: Scalars['Boolean']['output'];
  lastDeviceLocationForDevice?: Maybe<DeviceLocation>;
  lastDeviceLocationWithLocationForUser?: Maybe<DeviceLocation>;
  lastLocation?: Maybe<Location>;
  lastLocationForDevice?: Maybe<Location>;
  lastLocationForUser?: Maybe<Location>;
  /** Time when the AgencyAdHocAvailabilityCreated Notification was sent */
  notificationAgencyAdHocAvailabilityCreatedSentAt?: Maybe<Scalars['DateTime']['output']>;
  /** Start Time */
  startTime: Scalars['DateTime']['output'];
  /** The time zones offset for daylight-savings time. 0 if no daylight saving time for startTime. */
  timeZoneDestinationOffset?: Maybe<Scalars['Float']['output']>;
  /** Intl Time Zone Identifier */
  timeZoneIdentifier?: Maybe<Scalars['String']['output']>;
  /** Raw offset to UTC time in seconds */
  timeZoneRawOffset?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

/** Filter types for courier availabilities */
export enum AvailabilityFilterType {
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  SCHEDULED = 'SCHEDULED',
}

/** AvailabilityLocations Entity */
export type AvailabilityLocation = {
  __typename?: 'AvailabilityLocation';
  availability: Availability;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  isDerivedFromDeviceLocation: Scalars['Boolean']['output'];
  location: Location;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Object with AvailabilityLocation and a relative distance */
export type AvailabilityLocationWithDistance = {
  __typename?: 'AvailabilityLocationWithDistance';
  availabilityLocation: AvailabilityLocation;
  distance: Scalars['Float']['output'];
  /** End Time */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  groupType?: Maybe<Scalars['String']['output']>;
  /** Start Time */
  startTime?: Maybe<Scalars['DateTime']['output']>;
};

/** Custom GQL ObjectType representing an available Courier at a given distance */
export type AvailableCourierOrganizationUsersWithDistance = {
  __typename?: 'AvailableCourierOrganizationUsersWithDistance';
  availabilityLocationsWithDistance: Array<AvailabilityLocationWithDistance>;
  courierJob?: Maybe<CourierJob>;
  displayAvailabilityLocationWithDistance?: Maybe<AvailabilityLocationWithDistance>;
  lastAvailabilityLocationWithDistance?: Maybe<AvailabilityLocationWithDistance>;
  organizationUsers: Array<OrganizationUser>;
  user: User;
};

export type AzureAdb2CToken = {
  __typename?: 'AzureADB2CToken';
  aud: Scalars['String']['output'];
  azp: Scalars['String']['output'];
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  emails: Array<Scalars['String']['output']>;
  exp: Scalars['Int']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  iat: Scalars['Int']['output'];
  iss: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nbf: Scalars['Int']['output'];
  oid: Scalars['String']['output'];
  sub: Scalars['String']['output'];
  tfp: Scalars['String']['output'];
  ver: Scalars['String']['output'];
};

/** Chat Table */
export type Chat = {
  __typename?: 'Chat';
  /** A Chat with for all the ACCEPTED CourierJobs connected to the AgencyJob */
  acceptedCourierJobsForAgencyJob: AgencyJob;
  /** A singular AgencyJob to AgencyJobChat */
  agencyJob?: Maybe<AgencyJob>;
  /** A Chat with for all the CourierJobs connected to the AgencyJob */
  allCourierJobsForAgencyJob: AgencyJob;
  /** ChatType */
  chatType: ChatType;
  /** A singular CourierJob to AgencyJob Chat */
  courierJob?: Maybe<CourierJob>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** File sent via chat */
export type ChatFile = {
  __typename?: 'ChatFile';
  chatMessage: ChatMessage;
  createdAt: Scalars['DateTime']['output'];
  /** File Metadata */
  fileBlobMetadata: StoredAssetMetadata;
  /** File blob name in azure storage */
  fileBlobPath: Scalars['String']['output'];
  /** container name in azure storage */
  fileContainerName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChatFileInput = {
  fileBlobMetadata: StoredAssetMetadataInput;
  /** File blob name in azure storage */
  fileBlobPath: Scalars['String']['input'];
  /** container name in azure storage */
  fileContainerName: Scalars['String']['input'];
};

/** Chat Message */
export type ChatMessage = {
  __typename?: 'ChatMessage';
  agencyJobPlanningItemHistoryLog?: Maybe<AgencyJobPlanningItemHistoryLog>;
  /** The chat reference */
  chat: Chat;
  chatFile?: Maybe<StoredAsset>;
  createdAt: Scalars['DateTime']['output'];
  /** The sender device  */
  device: Device;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Message Text */
  message?: Maybe<Scalars['String']['output']>;
  /** MessageType */
  messageType: MessageType;
  /** The sender of chat */
  sendingUser: User;
  updatedAt: Scalars['DateTime']['output'];
};

export type ChatMessageConnection = {
  __typename?: 'ChatMessageConnection';
  edges?: Maybe<Array<ChatMessageEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ChatMessageCreateEdge = {
  __typename?: 'ChatMessageCreateEdge';
  cursor: Scalars['String']['output'];
  node: ChatMessage;
};

export type ChatMessageEdge = {
  __typename?: 'ChatMessageEdge';
  cursor: Scalars['String']['output'];
  node: ChatMessage;
};

export type ChatMessagesByChatIdInput = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** The type of Chat between: (COURIER_JOB_AGENCY_JOB_CHAT=0, ALL_COURIER_JOBS_CHAT=1, ACCEPTED_COURIER_JOBS_CHAT=2, AGENCY_JOB_CHAT=3) */
export enum ChatType {
  ACCEPTED_COURIER_JOBS_CHAT = 'ACCEPTED_COURIER_JOBS_CHAT',
  AGENCY_JOB_CHAT = 'AGENCY_JOB_CHAT',
  ALL_COURIER_JOBS_CHAT = 'ALL_COURIER_JOBS_CHAT',
  COURIER_JOB_AGENCY_JOB_CHAT = 'COURIER_JOB_AGENCY_JOB_CHAT',
}

/** The reason why this Job was declined by the Client. */
export enum ClientDeclinedReasonType {
  CLIENT_CANCELLED = 'CLIENT_CANCELLED',
}

/** ClientJob Entity. This represents the Job data and specification as proposed by the CLIENT Organization which is the basis for the AgencyJobs. */
export type ClientJob = {
  __typename?: 'ClientJob';
  agencyJobs?: Maybe<Array<AgencyJob>>;
  /** Any comment about the Job from the Client */
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Currency in which the Client pays the Agency. (EUR=0, USD=1) */
  currency: Currency;
  /** External Job Reference from the Client */
  externalJobReference?: Maybe<Scalars['String']['output']>;
  /** The fixed rate for the job as proposed by Client. */
  fixedRate?: Maybe<Scalars['Float']['output']>;
  /** Whether an incident has occurred on this Job for the Client */
  hasIncident: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  job: Organization;
  /** Primary email address of the Client Organization. */
  organizationEmail?: Maybe<Scalars['String']['output']>;
  /** Legal Name of the Client Organization. */
  organizationLegalName?: Maybe<Scalars['String']['output']>;
  /** Name of the Client Organization */
  organizationName: Scalars['String']['output'];
  /** Primary phone number of the Client Organization. */
  organizationPhone?: Maybe<Scalars['String']['output']>;
  responsibleAgencyUser?: Maybe<User>;
  responsibleClientOrganization?: Maybe<Organization>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientJobByAgencyInput = {
  /** Currency in which the Client pays the Agency. (EUR=0, USD=1) */
  currency: Currency;
  /** External Job Reference from the Client */
  externalJobReference?: InputMaybe<Scalars['String']['input']>;
  /** The fixed rate for the job as proposed by Client. */
  fixedRate?: InputMaybe<Scalars['Float']['input']>;
  /** Primary email address of the Client Organization. */
  organizationEmail?: InputMaybe<Scalars['String']['input']>;
  /** Legal Name of the Client Organization. */
  organizationLegalName?: InputMaybe<Scalars['String']['input']>;
  /** Name of the Client Organization */
  organizationName: Scalars['String']['input'];
  /** Primary phone number of the Client Organization. */
  organizationPhone?: InputMaybe<Scalars['String']['input']>;
};

/** ConnectedOrganization ObjectType */
export type ConnectedOrganization = {
  __typename?: 'ConnectedOrganization';
  /** OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY) */
  connectionType: OrganizationOrganizationConnectionType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  organization: Organization;
  updatedAt: Scalars['DateTime']['output'];
  usedOrganizationInvitation: OrganizationInvitation;
};

/** Contact Entity. This represents any kind of contact that was imported to the application. Think of a lead. */
export type Contact = {
  __typename?: 'Contact';
  /** Bitrix24 data */
  bitrix24Data?: Maybe<ContactBitrix24Data>;
  /** History of changes to this Contact */
  changes: Array<ContactChanges>;
  createdAt: Scalars['DateTime']['output'];
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** First names */
  firstNames: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** ContactImportSource */
  importSource?: Maybe<UserContactImportSourceType>;
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Is this contact an Agency Contact */
  isAgencyContact: Scalars['Boolean']['output'];
  /** Is this contact a Client Contact */
  isClientContact: Scalars['Boolean']['output'];
  /** Is this contact a Courier Contact */
  isCourierContact: Scalars['Boolean']['output'];
  /** Is this contact an Other Contact */
  isOtherContact: Scalars['Boolean']['output'];
  /** Is this contact coming from PowerAutomate */
  isPowerAutomateContact: Scalars['Boolean']['output'];
  /** Is this contact a PRIOjet Contact */
  isPriojetContact: Scalars['Boolean']['output'];
  /** Is this contact a ServiceProvider Contact */
  isServiceProviderContact: Scalars['Boolean']['output'];
  /** Is this contact a Traveler Contact */
  isTravelerContact: Scalars['Boolean']['output'];
  /** Last name */
  lastName: Scalars['String']['output'];
  organizationContacts: Array<OrganizationContact>;
  /** Phone Number */
  phone: Scalars['String']['output'];
  /** Pipedrive data */
  pipedriveData?: Maybe<ContactPipedriveData>;
  /** When this contact was lastly synced with the CRM. */
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Bitrix24 Company. */
export type ContactBitrix24Company = {
  __typename?: 'ContactBitrix24Company';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Array<ContactBitrix24Email>>;
  id: Scalars['Float']['output'];
  organizationName: Scalars['String']['output'];
  organizationType: OrganizationType;
  phone: Array<ContactBitrix24Phone>;
  syncedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Bitrix24 Contact. */
export type ContactBitrix24Contact = {
  __typename?: 'ContactBitrix24Contact';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Array<ContactBitrix24Email>>;
  firstNames: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  phone: Array<ContactBitrix24Phone>;
  syncedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Bitrix24 Data for Contact (Bitrix Company and Contact). */
export type ContactBitrix24Data = {
  __typename?: 'ContactBitrix24Data';
  companies: Array<ContactBitrix24Company>;
  contact: ContactBitrix24Contact;
};

/** Bitrix24 Email. */
export type ContactBitrix24Email = {
  __typename?: 'ContactBitrix24Email';
  id?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
  valueType: Scalars['String']['output'];
};

/** Bitrix24 Phone. */
export type ContactBitrix24Phone = {
  __typename?: 'ContactBitrix24Phone';
  id?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
  valueType: Scalars['String']['output'];
};

/** Contact Changes. */
export type ContactChanges = {
  __typename?: 'ContactChanges';
  email?: Maybe<Scalars['String']['output']>;
  firstNames: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Pipedrive Data for Contact (Pipedrive Company and Person). */
export type ContactPipedriveData = {
  __typename?: 'ContactPipedriveData';
  organizations: Array<ContactPipedriveOrganization>;
  person: ContactPipedrivePerson;
};

/** Pipedrive Company. */
export type ContactPipedriveOrganization = {
  __typename?: 'ContactPipedriveOrganization';
  address?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Float']['output'];
  label: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  organizationType: OrganizationType;
  syncedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Pipedrive Person. */
export type ContactPipedrivePerson = {
  __typename?: 'ContactPipedrivePerson';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstNames: Scalars['String']['output'];
  id: Scalars['Float']['output'];
  label: Scalars['Float']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  syncedAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** CourierJob Entity */
export type CourierJob = {
  __typename?: 'CourierJob';
  /** Additional cost information more information details */
  additionalCostInformation?: Maybe<Scalars['String']['output']>;
  agencyJob: AgencyJob;
  cancelledByCourierAt?: Maybe<Scalars['DateTime']['output']>;
  /** Any comment about the Job from the Courier */
  comment?: Maybe<Scalars['String']['output']>;
  confirmedFinishedByAgencyAt?: Maybe<Scalars['DateTime']['output']>;
  courierJobAgencyJobChat?: Maybe<Chat>;
  /** CourierJobStatusType (NEW=0, STARTED=1, FINISHED=2, CANCELLED=3, FINISHED_AGENCY_CONFIRMED=4) */
  courierJobStatusType: CourierJobStatusType;
  createdAt: Scalars['DateTime']['output'];
  /** The daily rate for the Job for the Courier payable by Agency */
  dailyRate?: Maybe<Scalars['Float']['output']>;
  /** Reason why this Job was declined by the Agency (AGENCY_CANCELLED=0) */
  declineReasonType?: Maybe<AgencyDeclinedReasonType>;
  finishedByCourierAt?: Maybe<Scalars['DateTime']['output']>;
  /** The fixed rate for the Job for the Courier payable by Agency */
  fixedRate?: Maybe<Scalars['Float']['output']>;
  /** Whether an incident has occurred for on Job for the Courier */
  hasIncident: Scalars['Boolean']['output'];
  /** The hotel budget for the Job for the Courier payable by Agency */
  hotelBudget?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Wether the accommodation is booked by the Courier */
  isAccommodationBookedByCourier: Scalars['Boolean']['output'];
  /** Whether the accommodation is paid by the Courier */
  isAccommodationPaidByCourier: Scalars['Boolean']['output'];
  /** Whether the CourierJob is currently activated for the CourierOrganization */
  isActive: Scalars['Boolean']['output'];
  /** Whether the additional costs are paid by the Courier */
  isAdditionalCostPaidByCourier: Scalars['Boolean']['output'];
  job: Job;
  /** The kick off fee for the Job for the Courier payable by Agency */
  kickOffFee?: Maybe<Scalars['Float']['output']>;
  /** CourierJob to Agency relationship type (AGENCY_REQUESTED=0, AGENCY_ACCEPTED=1, AGENCY_DECLINED=2, COURIER_REQUESTED=3, COURIER_ACCEPTED=4, COURIER_DECLINED=5) */
  relationshipType: CourierJobAgencyRelationshipType;
  relationshipTypeChangedAt?: Maybe<Scalars['DateTime']['output']>;
  responsibleCourierOrganization: Organization;
  responsibleCourierUser: User;
  startedByCourierAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The Courier-Agency Relationship Type (Status) of this CourierJob. */
export enum CourierJobAgencyRelationshipType {
  AGENCY_ACCEPTED = 'AGENCY_ACCEPTED',
  AGENCY_DECLINED = 'AGENCY_DECLINED',
  AGENCY_REQUESTED = 'AGENCY_REQUESTED',
  COURIER_ACCEPTED = 'COURIER_ACCEPTED',
  COURIER_DECLINED = 'COURIER_DECLINED',
  COURIER_REQUESTED = 'COURIER_REQUESTED',
}

/** The State of the CourierJob. */
export enum CourierJobStatusType {
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  FINISHED_AGENCY_CONFIRMED = 'FINISHED_AGENCY_CONFIRMED',
  NEW = 'NEW',
  STARTED = 'STARTED',
}

export type CourierOrganizationProfileInput = {
  headquartersAddressLocation?: InputMaybe<LocationManualInput>;
  id: Scalars['ID']['input'];
  invoicingAddressLocation?: InputMaybe<LocationManualInput>;
  /** Legal Name of the Organization. For private individuals it's most of the times their name. */
  legalName: Scalars['String']['input'];
  /** Name of the Organization */
  name: Scalars['String']['input'];
  registrationAddressLocation?: InputMaybe<LocationManualInput>;
  /** Social profiles */
  socialProfiles?: InputMaybe<Array<SocialProfileInput>>;
};

/** Courier User Profile Entity */
export type CourierUserProfile = {
  __typename?: 'CourierUserProfile';
  /** City of birth */
  address?: Maybe<Scalars['String']['output']>;
  /** American Express Credit Card Currency (EUR=0, USD=1) */
  amexCreditCardCurrency?: Maybe<Currency>;
  /** American Express Credit Card Limit */
  amexCreditCardLimit?: Maybe<Scalars['Float']['output']>;
  /** Apartment/Suite/Other address attributes */
  apartmentOrSuite?: Maybe<Scalars['String']['output']>;
  baseAirportLocations: Array<Location>;
  /** Base airports, 3 capital letters */
  baseAirports?: Maybe<Array<Scalars['String']['output']>>;
  /** City */
  city?: Maybe<Scalars['String']['output']>;
  /** Country of residence, ISO 3166 Alpha-3 code */
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Diners Credit Card Currency (EUR=0, USD=1) */
  dinerCreditCardCurrency?: Maybe<Currency>;
  /** Diner's Credit Card Limit */
  dinerCreditCardLimit?: Maybe<Scalars['Float']['output']>;
  /** Emergency Contact Address */
  emergencyContactAddress?: Maybe<Scalars['String']['output']>;
  /** Emergency Contact Email */
  emergencyContactEmail?: Maybe<Scalars['String']['output']>;
  /** Emergency Contact Name */
  emergencyContactName?: Maybe<Scalars['String']['output']>;
  /** Emergency Contact Phone Number */
  emergencyContactPhone?: Maybe<Scalars['String']['output']>;
  /** Emergency Contact Relationship */
  emergencyContactRelationship?: Maybe<Scalars['String']['output']>;
  /** First OBC job date */
  firstObcJobDateAt?: Maybe<Scalars['DateTime']['output']>;
  /** American Express Credit Card */
  hasAmexCreditCard?: Maybe<Scalars['Boolean']['output']>;
  /** American Express debit/prepaid Card */
  hasAmexDebitCard?: Maybe<Scalars['Boolean']['output']>;
  /** Diner's Credit Card */
  hasDinerCreditCard?: Maybe<Scalars['Boolean']['output']>;
  /** Diner's debit/prepaid Card */
  hasDinerDebitCard?: Maybe<Scalars['Boolean']['output']>;
  /** Master Credit Card */
  hasMasterCreditCard?: Maybe<Scalars['Boolean']['output']>;
  /** Master Debit/Prepaid Card */
  hasMasterDebitCard?: Maybe<Scalars['Boolean']['output']>;
  /** Prior Medical (Stem cell) OBC Experience (Yes/No) */
  hasMedicalObcExperience?: Maybe<Scalars['Boolean']['output']>;
  /** Prior OBC Experience (Yes/No) */
  hasObcExperience?: Maybe<Scalars['Boolean']['output']>;
  /** Visa Credit Card */
  hasVisaCreditCard?: Maybe<Scalars['Boolean']['output']>;
  /** Visa Debit/Prepaid Card */
  hasVisaDebitCard?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Languages known */
  languagesKnown?: Maybe<Array<TLanguageKnown>>;
  /** Master Credit Card Currency (EUR=0, USD=1) */
  masterCreditCardCurrency?: Maybe<Currency>;
  /** Master Credit Card Limit */
  masterCreditCardLimit?: Maybe<Scalars['Float']['output']>;
  /** Number of Prior OBC Jobs */
  numberOfPastObcJobs?: Maybe<Scalars['Float']['output']>;
  /** On Board Experiences */
  onboardExperiences?: Maybe<Array<Scalars['String']['output']>>;
  /** Social profiles */
  socialProfiles: Array<SocialProfile>;
  /** Street */
  street?: Maybe<Scalars['String']['output']>;
  /** In which country is my business registered for tax purposes, ISO 3166 Alpha-3 code */
  taxCountry?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** Visa Credit Card Currency (EUR=0, USD=1) */
  visaCreditCardCurrency?: Maybe<Currency>;
  /** Visa Credit Card Limit */
  visaCreditCardLimit?: Maybe<Scalars['Float']['output']>;
  /** Zip Code */
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type CourierUserProfileInput = {
  /** City of birth */
  address?: InputMaybe<Scalars['String']['input']>;
  /** American Express Credit Card Currency (EUR=0, USD=1) */
  amexCreditCardCurrency?: InputMaybe<Currency>;
  /** American Express Credit Card Limit */
  amexCreditCardLimit?: InputMaybe<Scalars['Float']['input']>;
  /** Apartment/Suite/Other address attributes */
  apartmentOrSuite?: InputMaybe<Scalars['String']['input']>;
  /** Base airport - location ids */
  baseAirportLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Base airports, 3 capital letters */
  baseAirports?: InputMaybe<Array<Scalars['String']['input']>>;
  /** City */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Country of residence, ISO 3166 Alpha-3 code */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Diners Credit Card Currency (EUR=0, USD=1) */
  dinerCreditCardCurrency?: InputMaybe<Currency>;
  /** Diner's Credit Card Limit */
  dinerCreditCardLimit?: InputMaybe<Scalars['Float']['input']>;
  /** Emergency Contact Address */
  emergencyContactAddress?: InputMaybe<Scalars['String']['input']>;
  /** Emergency Contact Email */
  emergencyContactEmail?: InputMaybe<Scalars['String']['input']>;
  /** Emergency Contact Name */
  emergencyContactName?: InputMaybe<Scalars['String']['input']>;
  /** Emergency Contact Phone Number */
  emergencyContactPhone?: InputMaybe<Scalars['String']['input']>;
  /** Emergency Contact Relationship */
  emergencyContactRelationship?: InputMaybe<Scalars['String']['input']>;
  /** First OBC job date */
  firstObcJobDateAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** American Express Credit Card */
  hasAmexCreditCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** American Express debit/prepaid Card */
  hasAmexDebitCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Diner's Credit Card */
  hasDinerCreditCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Diner's debit/prepaid Card */
  hasDinerDebitCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Master Credit Card */
  hasMasterCreditCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Master Debit/Prepaid Card */
  hasMasterDebitCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Prior Medical (Stem cell) OBC Experience (Yes/No) */
  hasMedicalObcExperience?: InputMaybe<Scalars['Boolean']['input']>;
  /** Prior OBC Experience (Yes/No) */
  hasObcExperience?: InputMaybe<Scalars['Boolean']['input']>;
  /** Visa Credit Card */
  hasVisaCreditCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Visa Debit/Prepaid Card */
  hasVisaDebitCard?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  inactivatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Languages known */
  languagesKnown?: InputMaybe<Array<LanguagesKnownInput>>;
  /** Master Credit Card Currency (EUR=0, USD=1) */
  masterCreditCardCurrency?: InputMaybe<Currency>;
  /** Master Credit Card Limit */
  masterCreditCardLimit?: InputMaybe<Scalars['Float']['input']>;
  /** Number of Prior OBC Jobs */
  numberOfPastObcJobs?: InputMaybe<Scalars['Float']['input']>;
  /** On Board Experiences */
  onboardExperiences?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Social profiles */
  socialProfiles: Array<SocialProfileInput>;
  /** Street */
  street?: InputMaybe<Scalars['String']['input']>;
  /** In which country is my business registered for tax purposes, ISO 3166 Alpha-3 code */
  taxCountry?: InputMaybe<Scalars['String']['input']>;
  /** Visa Credit Card Currency (EUR=0, USD=1) */
  visaCreditCardCurrency?: InputMaybe<Currency>;
  /** Visa Credit Card Limit */
  visaCreditCardLimit?: InputMaybe<Scalars['Float']['input']>;
  /** Zip Code */
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateChatMessageInput = {
  chatFile?: InputMaybe<ChatFileInput>;
  chatId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<MessageType>;
  sendingUserId?: InputMaybe<Scalars['String']['input']>;
};

/** The Currency used for money transactions. */
export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}

export type DeleteChatMessageInput = {
  chatMessageId: Scalars['String']['input'];
};

/** Device Entity */
export type Device = {
  __typename?: 'Device';
  appSettings: DeviceAppSettings;
  createdAt: Scalars['DateTime']['output'];
  deviceLocations?: Maybe<Array<DeviceLocation>>;
  deviceLogs?: Maybe<Array<DeviceLog>>;
  deviceProperties: DeviceProperties;
  /** DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV) */
  deviceType: DeviceCategoryType;
  expoPushToken: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  localization: DeviceLocalization;
  permissions: DevicePermissions;
  updatedAt: Scalars['DateTime']['output'];
  userDevices?: Maybe<Array<UserDevice>>;
};

export type DeviceAppSettings = {
  __typename?: 'DeviceAppSettings';
  appLanguage?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
};

export type DeviceAppSettingsInput = {
  appLanguage?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
};

/** The release type of installed application on the Device. */
export enum DeviceApplicationReleaseType {
  AD_HOC = 'AD_HOC',
  APP_STORE = 'APP_STORE',
  DEVELOPMENT = 'DEVELOPMENT',
  ENTERPRISE = 'ENTERPRISE',
  SIMULATOR = 'SIMULATOR',
  UNKNOWN = 'UNKNOWN',
}

export type DeviceCanvas = {
  __typename?: 'DeviceCanvas';
  geometry: Scalars['String']['output'];
  text: Scalars['String']['output'];
  winding: Scalars['Boolean']['output'];
};

export type DeviceCanvasInput = {
  geometry: Scalars['String']['input'];
  text: Scalars['String']['input'];
  winding: Scalars['Boolean']['input'];
};

/** The category type of the Device. */
export enum DeviceCategoryType {
  DESKTOP = 'DESKTOP',
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  TV = 'TV',
  UNKNOWN = 'UNKNOWN',
}

export type DeviceInput = {
  appSettings?: InputMaybe<DeviceAppSettingsInput>;
  deviceProperties: DevicePropertiesInput;
  /** DeviceType (UNKNOWN, PHONE, TABLET, DESKTOP, TV) */
  deviceType: DeviceCategoryType;
  expoPushToken?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  localization?: InputMaybe<DeviceLocalizationInput>;
  permissions?: InputMaybe<DevicePermissionsInput>;
};

export type DeviceLocalization = {
  __typename?: 'DeviceLocalization';
  currency?: Maybe<Scalars['String']['output']>;
  decimalSeparator: Scalars['String']['output'];
  digitGroupingSeparator: Scalars['String']['output'];
  isMetric: Scalars['Boolean']['output'];
  isRTL: Scalars['Boolean']['output'];
  isoCurrencyCodes: Array<Scalars['String']['output']>;
  locale: Scalars['String']['output'];
  locales: Array<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
};

export type DeviceLocalizationInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  decimalSeparator: Scalars['String']['input'];
  digitGroupingSeparator: Scalars['String']['input'];
  isMetric: Scalars['Boolean']['input'];
  isRTL: Scalars['Boolean']['input'];
  isoCurrencyCodes: Array<Scalars['String']['input']>;
  locale: Scalars['String']['input'];
  locales: Array<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

/** DeviceLocation Entity */
export type DeviceLocation = {
  __typename?: 'DeviceLocation';
  accuracy?: Maybe<Scalars['Float']['output']>;
  altitude?: Maybe<Scalars['Float']['output']>;
  altitudeAccuracy?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  device: Device;
  heading?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Whether a location could be resolved for the coordinates. null=not resolved yet, false=not found and resolved, true=found and resolved */
  isLocationFound?: Maybe<Scalars['Boolean']['output']>;
  latitude: Scalars['Float']['output'];
  location?: Maybe<Location>;
  longitude: Scalars['Float']['output'];
  speed?: Maybe<Scalars['Float']['output']>;
  timestamp: Scalars['DateTime']['output'];
  timestampInMs: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type DeviceLocationInput = {
  accuracy?: InputMaybe<Scalars['Float']['input']>;
  altitude?: InputMaybe<Scalars['Float']['input']>;
  altitudeAccuracy?: InputMaybe<Scalars['Float']['input']>;
  heading?: InputMaybe<Scalars['Float']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  speed?: InputMaybe<Scalars['Float']['input']>;
  timestampInMs: Scalars['Float']['input'];
};

/** DeviceLog Entity */
export type DeviceLog = {
  __typename?: 'DeviceLog';
  accessTokenHash?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** JSON device log data */
  data?: Maybe<DeviceLogData>;
  device: Device;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  internetServiceProvider?: Maybe<Scalars['String']['output']>;
  ipAddress?: Maybe<Scalars['String']['output']>;
  ipAddressCity?: Maybe<Scalars['String']['output']>;
  ipAddressCountry?: Maybe<Scalars['String']['output']>;
  ipAddressLat?: Maybe<Scalars['Float']['output']>;
  ipAddressLong?: Maybe<Scalars['Float']['output']>;
  ipAddressRegion?: Maybe<Scalars['String']['output']>;
  /** The origin of the ip address (e.g. school, university, corporation) */
  ipAddressType?: Maybe<IpAddressType>;
  isAuthenticatedMutation: Scalars['Boolean']['output'];
  isCurrentUserContextAvailable: Scalars['Boolean']['output'];
  organization?: Maybe<Organization>;
  timestamp: Scalars['DateTime']['output'];
  /** What triggered adding this DeviceLog */
  triggerType: DeviceLogTriggerType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

/** (UNKNOWN=0, UNPLUGGED=1, CHARGING=2, FULL=3) */
export enum DeviceLogBatteryStateType {
  CHARGING = 'CHARGING',
  FULL = 'FULL',
  UNKNOWN = 'UNKNOWN',
  UNPLUGGED = 'UNPLUGGED',
}

export type DeviceLogData = {
  __typename?: 'DeviceLogData';
  androidId?: Maybe<Scalars['String']['output']>;
  appLastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  appState?: Maybe<Scalars['String']['output']>;
  appVersion?: Maybe<Scalars['String']['output']>;
  applicationId?: Maybe<Scalars['String']['output']>;
  availableLocationProviders?: Maybe<Scalars['JSONObject']['output']>;
  batteryLevel?: Maybe<Scalars['Float']['output']>;
  /** (UNKNOWN=0, UNPLUGGED=1, CHARGING=2, FULL=3) */
  batteryState?: Maybe<DeviceLogBatteryStateType>;
  carrier?: Maybe<Scalars['String']['output']>;
  freeDiskStorage?: Maybe<Scalars['Float']['output']>;
  ipAddress: Scalars['String']['output'];
  isAirplaneModeEnabled?: Maybe<Scalars['Boolean']['output']>;
  lowPowerMode?: Maybe<Scalars['Boolean']['output']>;
  maxMemory?: Maybe<Scalars['Float']['output']>;
  networkStateIsConnected?: Maybe<Scalars['Boolean']['output']>;
  networkStateIsInternetReachable?: Maybe<Scalars['Boolean']['output']>;
  /** NetworkStateType (connection type) of the clients connection */
  networkStateType?: Maybe<DeviceLogNetworkStateType>;
  totalDiskCapacity?: Maybe<Scalars['Float']['output']>;
  uptime?: Maybe<Scalars['Float']['output']>;
};

export type DeviceLogDataInput = {
  androidId?: InputMaybe<Scalars['String']['input']>;
  appLastUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  appState?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  availableLocationProviders?: InputMaybe<Scalars['JSONObject']['input']>;
  batteryLevel?: InputMaybe<Scalars['Float']['input']>;
  /** (UNKNOWN=0, UNPLUGGED=1, CHARGING=2, FULL=3) */
  batteryState?: InputMaybe<DeviceLogBatteryStateType>;
  carrier?: InputMaybe<Scalars['String']['input']>;
  freeDiskStorage?: InputMaybe<Scalars['Float']['input']>;
  ipAddress: Scalars['String']['input'];
  isAirplaneModeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lowPowerMode?: InputMaybe<Scalars['Boolean']['input']>;
  maxMemory?: InputMaybe<Scalars['Float']['input']>;
  networkStateIsConnected?: InputMaybe<Scalars['Boolean']['input']>;
  networkStateIsInternetReachable?: InputMaybe<Scalars['Boolean']['input']>;
  /** NetworkStateType (connection type) of the clients connection */
  networkStateType?: InputMaybe<DeviceLogNetworkStateType>;
  totalDiskCapacity?: InputMaybe<Scalars['Float']['input']>;
  uptime?: InputMaybe<Scalars['Float']['input']>;
};

/** NetworkStateType of the clients network connection. */
export enum DeviceLogNetworkStateType {
  BLUETOOTH = 'BLUETOOTH',
  CELLULAR = 'CELLULAR',
  ETHERNET = 'ETHERNET',
  NONE = 'NONE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN',
  VPN = 'VPN',
  WIFI = 'WIFI',
  WIMAX = 'WIMAX',
}

export type DeviceLogOutput = {
  __typename?: 'DeviceLogOutput';
  /** JSON device log data */
  data?: Maybe<DeviceLogData>;
  id: Scalars['ID']['output'];
  ipAddressCity?: Maybe<Scalars['String']['output']>;
  ipAddressCountry?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  /** What triggered adding this DeviceLog */
  triggerType: DeviceLogTriggerType;
  user?: Maybe<User>;
};

/** Type of the internet access point (private/residential, business/company, education/university). */
export enum DeviceLogTriggerType {
  ACCOUNT_SWITCH = 'ACCOUNT_SWITCH',
  ADHOC_LOCATION_DISABLED = 'ADHOC_LOCATION_DISABLED',
  ADHOC_LOCATION_ENABLED = 'ADHOC_LOCATION_ENABLED',
  APP_STATE_CHANGE = 'APP_STATE_CHANGE',
  CURRENT_USER_CONTEXT_CHANGE = 'CURRENT_USER_CONTEXT_CHANGE',
  DEVICE_LOG_DATA_CHANGE = 'DEVICE_LOG_DATA_CHANGE',
  LOGIN = 'LOGIN',
  PERIODIC_PING = 'PERIODIC_PING',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  UNKNOWN = 'UNKNOWN',
  USER_CONTEXTS_CHANGE = 'USER_CONTEXTS_CHANGE',
}

/** The status of a device permission. (Note: strings are lowercase, e.g. GRANTED = granted) */
export enum DevicePermissionStatus {
  DENIED = 'DENIED',
  GRANTED = 'GRANTED',
  UNDETERMINED = 'UNDETERMINED',
}

export type DevicePermissions = {
  __typename?: 'DevicePermissions';
  notifications: DevicePermissionsNotifications;
};

export type DevicePermissionsInput = {
  notifications?: InputMaybe<DevicePermissionsNotificationsInput>;
};

export type DevicePermissionsNotifications = {
  __typename?: 'DevicePermissionsNotifications';
  android?: Maybe<DevicePermissionsNotificationsAndroid>;
  canAskAgain: Scalars['Boolean']['output'];
  expires: Scalars['DevicePermissionExpiration']['output'];
  granted: Scalars['Boolean']['output'];
  ios?: Maybe<DevicePermissionsNotificationsIos>;
  status: DevicePermissionStatus;
};

export type DevicePermissionsNotificationsAndroid = {
  __typename?: 'DevicePermissionsNotificationsAndroid';
  importance: Scalars['Float']['output'];
  interruptionFilter?: Maybe<Scalars['Float']['output']>;
};

export type DevicePermissionsNotificationsAndroidInput = {
  importance: Scalars['Float']['input'];
  interruptionFilter?: InputMaybe<Scalars['Float']['input']>;
};

export type DevicePermissionsNotificationsInput = {
  android?: InputMaybe<DevicePermissionsNotificationsAndroidInput>;
  canAskAgain: Scalars['Boolean']['input'];
  expires: Scalars['DevicePermissionExpiration']['input'];
  granted: Scalars['Boolean']['input'];
  ios?: InputMaybe<DevicePermissionsNotificationsIosInput>;
  status: DevicePermissionStatus;
};

export type DevicePermissionsNotificationsIos = {
  __typename?: 'DevicePermissionsNotificationsIos';
  alertStyle: DevicePermissionsNotificationsIosAlertStyle;
  allowsAlert?: Maybe<Scalars['Boolean']['output']>;
  allowsAnnouncements?: Maybe<Scalars['Boolean']['output']>;
  allowsBadge?: Maybe<Scalars['Boolean']['output']>;
  allowsCriticalAlerts?: Maybe<Scalars['Boolean']['output']>;
  allowsDisplayInCarPlay?: Maybe<Scalars['Boolean']['output']>;
  allowsDisplayInNotificationCenter?: Maybe<Scalars['Boolean']['output']>;
  allowsDisplayOnLockScreen?: Maybe<Scalars['Boolean']['output']>;
  allowsPreviews?: Maybe<DevicePermissionsNotificationsIosAllowsPreviews>;
  allowsSound?: Maybe<Scalars['Boolean']['output']>;
  providesAppNotificationSettings?: Maybe<Scalars['Boolean']['output']>;
  status: DevicePermissionsNotificationsIosAuthorizationStatus;
};

/** iOS Notifications Permission Alert Style */
export enum DevicePermissionsNotificationsIosAlertStyle {
  ALERT = 'ALERT',
  BANNER = 'BANNER',
  NONE = 'NONE',
}

/** Whether previews are allowed for iOS Notifications */
export enum DevicePermissionsNotificationsIosAllowsPreviews {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  WHEN_AUTHENTICATED = 'WHEN_AUTHENTICATED',
}

/** Whether previews are allowed for iOS Notifications */
export enum DevicePermissionsNotificationsIosAuthorizationStatus {
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  EPHEMERAL = 'EPHEMERAL',
  NOT_DETERMINED = 'NOT_DETERMINED',
  PROVISIONAL = 'PROVISIONAL',
}

export type DevicePermissionsNotificationsIosInput = {
  alertStyle: DevicePermissionsNotificationsIosAlertStyle;
  allowsAlert?: InputMaybe<Scalars['Boolean']['input']>;
  allowsAnnouncements?: InputMaybe<Scalars['Boolean']['input']>;
  allowsBadge?: InputMaybe<Scalars['Boolean']['input']>;
  allowsCriticalAlerts?: InputMaybe<Scalars['Boolean']['input']>;
  allowsDisplayInCarPlay?: InputMaybe<Scalars['Boolean']['input']>;
  allowsDisplayInNotificationCenter?: InputMaybe<Scalars['Boolean']['input']>;
  allowsDisplayOnLockScreen?: InputMaybe<Scalars['Boolean']['input']>;
  allowsPreviews?: InputMaybe<DevicePermissionsNotificationsIosAllowsPreviews>;
  allowsSound?: InputMaybe<Scalars['Boolean']['input']>;
  providesAppNotificationSettings?: InputMaybe<Scalars['Boolean']['input']>;
  status: DevicePermissionsNotificationsIosAuthorizationStatus;
};

export type DeviceProperties = {
  __typename?: 'DeviceProperties';
  androidId?: Maybe<Scalars['String']['output']>;
  applicationId?: Maybe<Scalars['String']['output']>;
  applicationName?: Maybe<Scalars['String']['output']>;
  audio?: Maybe<Scalars['Float']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  canvas?: Maybe<DeviceCanvas>;
  chrome?: Maybe<Scalars['Boolean']['output']>;
  colorDepth?: Maybe<Scalars['Float']['output']>;
  cookiesEnabled?: Maybe<Scalars['Boolean']['output']>;
  cpuClass?: Maybe<Scalars['String']['output']>;
  designName?: Maybe<Scalars['String']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  deviceYearClass?: Maybe<Scalars['Float']['output']>;
  emptyValLength?: Maybe<Scalars['Float']['output']>;
  errorFF?: Maybe<Scalars['Boolean']['output']>;
  expoDeviceId?: Maybe<Scalars['String']['output']>;
  expoVersion?: Maybe<Scalars['String']['output']>;
  fonts?: Maybe<Array<Scalars['String']['output']>>;
  hardwareConcurrency?: Maybe<Scalars['Float']['output']>;
  indexedDb?: Maybe<Scalars['Boolean']['output']>;
  installReferrer?: Maybe<Scalars['String']['output']>;
  installationTime?: Maybe<Scalars['DateTime']['output']>;
  /** (UNKNOWN=0, SIMULATOR=1, ENTERPRISE=2, DEVELOPMENT=3, AD_HOC=4, APP_STORE=5) */
  iosApplicationReleaseType?: Maybe<DeviceApplicationReleaseType>;
  iosIdForVendor?: Maybe<Scalars['String']['output']>;
  iosPushNotificationServiceEnvironment?: Maybe<Scalars['String']['output']>;
  isDevice: Scalars['Boolean']['output'];
  isLimitAdTrackingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isRooted?: Maybe<Scalars['Boolean']['output']>;
  isSideLoadingEnabled?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Array<Scalars['String']['output']>>>;
  lastUpdateTime?: Maybe<Scalars['DateTime']['output']>;
  localStorage?: Maybe<Scalars['Boolean']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  maxMemory?: Maybe<Scalars['Float']['output']>;
  modelId?: Maybe<Scalars['String']['output']>;
  modelName?: Maybe<Scalars['String']['output']>;
  nativeApplicationVersion?: Maybe<Scalars['String']['output']>;
  nativeBuildVersion?: Maybe<Scalars['String']['output']>;
  openDatabase?: Maybe<Scalars['Boolean']['output']>;
  osBuildFingerprint?: Maybe<Scalars['String']['output']>;
  osBuildId?: Maybe<Scalars['String']['output']>;
  osCpu?: Maybe<Scalars['String']['output']>;
  osInternalBuild?: Maybe<Scalars['String']['output']>;
  osName?: Maybe<Scalars['String']['output']>;
  osVersion?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  platformApiLevel?: Maybe<Scalars['Float']['output']>;
  platformFeatures?: Maybe<Array<Scalars['String']['output']>>;
  pluginSupport?: Maybe<Scalars['Boolean']['output']>;
  plugins?: Maybe<Array<PluginData>>;
  productName?: Maybe<Scalars['String']['output']>;
  productSub?: Maybe<Scalars['String']['output']>;
  screenResolution?: Maybe<Array<Scalars['Float']['output']>>;
  screenResolutionAvailable?: Maybe<Array<Scalars['Float']['output']>>;
  sessionStorage?: Maybe<Scalars['Boolean']['output']>;
  supportedCpuArchitectures?: Maybe<Array<Scalars['String']['output']>>;
  timezone?: Maybe<Scalars['String']['output']>;
  timezoneOffset?: Maybe<Scalars['Float']['output']>;
  totalMemory?: Maybe<Scalars['Float']['output']>;
  touchSupport?: Maybe<TouchSupport>;
  userAgent?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Scalars['String']['output']>;
  webdriver?: Maybe<Scalars['Boolean']['output']>;
};

export type DevicePropertiesInput = {
  androidId?: InputMaybe<Scalars['String']['input']>;
  applicationId?: InputMaybe<Scalars['String']['input']>;
  applicationName?: InputMaybe<Scalars['String']['input']>;
  audio?: InputMaybe<Scalars['Float']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  canvas?: InputMaybe<DeviceCanvasInput>;
  chrome?: InputMaybe<Scalars['Boolean']['input']>;
  colorDepth?: InputMaybe<Scalars['Float']['input']>;
  cookiesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  cpuClass?: InputMaybe<Scalars['String']['input']>;
  designName?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  deviceYearClass?: InputMaybe<Scalars['Float']['input']>;
  emptyValLength?: InputMaybe<Scalars['Float']['input']>;
  errorFF?: InputMaybe<Scalars['Boolean']['input']>;
  expoDeviceId?: InputMaybe<Scalars['String']['input']>;
  expoVersion?: InputMaybe<Scalars['String']['input']>;
  fonts?: InputMaybe<Array<Scalars['String']['input']>>;
  hardwareConcurrency?: InputMaybe<Scalars['Float']['input']>;
  indexedDb?: InputMaybe<Scalars['Boolean']['input']>;
  installReferrer?: InputMaybe<Scalars['String']['input']>;
  installationTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** (UNKNOWN=0, SIMULATOR=1, ENTERPRISE=2, DEVELOPMENT=3, AD_HOC=4, APP_STORE=5) */
  iosApplicationReleaseType?: InputMaybe<DeviceApplicationReleaseType>;
  iosIdForVendor?: InputMaybe<Scalars['String']['input']>;
  iosPushNotificationServiceEnvironment?: InputMaybe<Scalars['String']['input']>;
  isDevice: Scalars['Boolean']['input'];
  isLimitAdTrackingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isRooted?: InputMaybe<Scalars['Boolean']['input']>;
  isSideLoadingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  lastUpdateTime?: InputMaybe<Scalars['DateTime']['input']>;
  localStorage?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  maxMemory?: InputMaybe<Scalars['Float']['input']>;
  modelId?: InputMaybe<Scalars['String']['input']>;
  modelName?: InputMaybe<Scalars['String']['input']>;
  nativeApplicationVersion?: InputMaybe<Scalars['String']['input']>;
  nativeBuildVersion?: InputMaybe<Scalars['String']['input']>;
  openDatabase?: InputMaybe<Scalars['Boolean']['input']>;
  osBuildFingerprint?: InputMaybe<Scalars['String']['input']>;
  osBuildId?: InputMaybe<Scalars['String']['input']>;
  osCpu?: InputMaybe<Scalars['String']['input']>;
  osInternalBuild?: InputMaybe<Scalars['String']['input']>;
  osName?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  platformApiLevel?: InputMaybe<Scalars['Float']['input']>;
  platformFeatures?: InputMaybe<Array<Scalars['String']['input']>>;
  pluginSupport?: InputMaybe<Scalars['Boolean']['input']>;
  plugins?: InputMaybe<Array<PluginDataInput>>;
  productName?: InputMaybe<Scalars['String']['input']>;
  productSub?: InputMaybe<Scalars['String']['input']>;
  screenResolution?: InputMaybe<Array<Scalars['Float']['input']>>;
  screenResolutionAvailable?: InputMaybe<Array<Scalars['Float']['input']>>;
  sessionStorage?: InputMaybe<Scalars['Boolean']['input']>;
  supportedCpuArchitectures?: InputMaybe<Array<Scalars['String']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  timezoneOffset?: InputMaybe<Scalars['Float']['input']>;
  totalMemory?: InputMaybe<Scalars['Float']['input']>;
  touchSupport?: InputMaybe<TouchSupportInput>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
  webdriver?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The physical feature of the Document. */
export enum DocumentFeature {
  BIOMETRIC = 'BIOMETRIC',
  DIGITAL_PHOTO = 'DIGITAL_PHOTO',
  MACHINE_READABLE = 'MACHINE_READABLE',
}

/** The state of verification process */
export enum DocumentVerificationStatus {
  FULLY_VERIFIED = 'FULLY_VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  PARTIALLY_VERIFIED = 'PARTIALLY_VERIFIED',
  REJECTED = 'REJECTED',
}

export type EntityDeletionReturnType = {
  __typename?: 'EntityDeletionReturnType';
  id: Scalars['ID']['output'];
};

export type ExpoContact = {
  __typename?: 'ExpoContact';
  addresses?: Maybe<Array<ExpoContactAddress>>;
  birthday?: Maybe<ExpoContactDate>;
  company?: Maybe<Scalars['String']['output']>;
  contactType: Scalars['String']['output'];
  dates?: Maybe<Array<ExpoContactDate>>;
  department?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<ExpoContactEmail>>;
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<ExpoContactImage>;
  imageAvailable?: Maybe<Scalars['Boolean']['output']>;
  instantMessageAddresses?: Maybe<Array<ExpoContactInstantMessageAddress>>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  lookupKey?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  namePrefix?: Maybe<Scalars['String']['output']>;
  nameSuffix?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  nonGregorianBirthday?: Maybe<ExpoContactDate>;
  note?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<ExpoContactPhoneNumber>>;
  phoneticFirstName?: Maybe<Scalars['String']['output']>;
  phoneticLastName?: Maybe<Scalars['String']['output']>;
  phoneticMiddleName?: Maybe<Scalars['String']['output']>;
  rawImage?: Maybe<ExpoContactImage>;
  relationships?: Maybe<Array<ExpoContactRelationship>>;
  socialProfiles?: Maybe<Array<ExpoContactSocialProfile>>;
  urlAddresses?: Maybe<Array<ExpoContactUrlAddress>>;
};

export type ExpoContactAddress = {
  __typename?: 'ExpoContactAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isoCountryCode?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  neighborhood?: Maybe<Scalars['String']['output']>;
  poBox?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isoCountryCode?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  neighborhood?: InputMaybe<Scalars['String']['input']>;
  poBox?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type ExpoContactDate = {
  __typename?: 'ExpoContactDate';
  day?: Maybe<Scalars['Float']['output']>;
  format?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  month?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type ExpoContactDateInput = {
  day?: InputMaybe<Scalars['Float']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['Float']['input']>;
  year?: InputMaybe<Scalars['Float']['input']>;
};

export type ExpoContactEmail = {
  __typename?: 'ExpoContactEmail';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactEmailInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ExpoContactImage = {
  __typename?: 'ExpoContactImage';
  base64?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type ExpoContactImageInput = {
  base64?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type ExpoContactInput = {
  addresses?: InputMaybe<Array<ExpoContactAddressInput>>;
  birthday?: InputMaybe<ExpoContactDateInput>;
  company?: InputMaybe<Scalars['String']['input']>;
  contactType: Scalars['String']['input'];
  dates?: InputMaybe<Array<ExpoContactDateInput>>;
  department?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<ExpoContactEmailInput>>;
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  image?: InputMaybe<ExpoContactImageInput>;
  imageAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  instantMessageAddresses?: InputMaybe<Array<ExpoContactInstantMessageAddressInput>>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  lookupKey?: InputMaybe<Scalars['String']['input']>;
  maidenName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  namePrefix?: InputMaybe<Scalars['String']['input']>;
  nameSuffix?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  nonGregorianBirthday?: InputMaybe<ExpoContactDateInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<ExpoContactPhoneNumberInput>>;
  phoneticFirstName?: InputMaybe<Scalars['String']['input']>;
  phoneticLastName?: InputMaybe<Scalars['String']['input']>;
  phoneticMiddleName?: InputMaybe<Scalars['String']['input']>;
  rawImage?: InputMaybe<ExpoContactImageInput>;
  relationships?: InputMaybe<Array<ExpoContactRelationshipInput>>;
  socialProfiles?: InputMaybe<Array<ExpoContactSocialProfileInput>>;
  urlAddresses?: InputMaybe<Array<ExpoContactUrlAddressInput>>;
};

export type ExpoContactInstantMessageAddress = {
  __typename?: 'ExpoContactInstantMessageAddress';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  localizedService?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactInstantMessageAddressInput = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  localizedService?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ExpoContactPhoneNumber = {
  __typename?: 'ExpoContactPhoneNumber';
  countryCode?: Maybe<Scalars['String']['output']>;
  digits?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactPhoneNumberInput = {
  countryCode?: InputMaybe<Scalars['String']['input']>;
  digits?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ExpoContactRelationship = {
  __typename?: 'ExpoContactRelationship';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactRelationshipInput = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ExpoContactSocialProfile = {
  __typename?: 'ExpoContactSocialProfile';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  localizedProfile?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactSocialProfileInput = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  localizedProfile?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type ExpoContactUrlAddress = {
  __typename?: 'ExpoContactUrlAddress';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ExpoContactUrlAddressInput = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FilesUploadInput = {
  base64: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
  mimeType: Scalars['String']['input'];
  name: Scalars['String']['input'];
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** The Gender of the User */
export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
}

/** The category types of the Health Tests */
export enum HealthTestType {
  ANTIGEN = 'ANTIGEN',
  PCR = 'PCR',
  PCR_RAPID = 'PCR_RAPID',
  RAPID = 'RAPID',
}

/** Organization subscription plan history entity */
export type HistoryLogPayment = {
  __typename?: 'HistoryLogPayment';
  alertId: Scalars['String']['output'];
  alertName: Scalars['String']['output'];
  balanceCurrency: Scalars['String']['output'];
  balanceEarnings: Scalars['String']['output'];
  balanceFee: Scalars['String']['output'];
  balanceGross: Scalars['String']['output'];
  balance_tax: Scalars['String']['output'];
  cancellationEffectiveDate: Scalars['String']['output'];
  checkout_id: Scalars['String']['output'];
  country: Scalars['String']['output'];
  coupon: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  customData: Scalars['String']['output'];
  customerName: Scalars['String']['output'];
  earnings: Scalars['String']['output'];
  email: Scalars['String']['output'];
  eventTime: Scalars['String']['output'];
  fee: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  initialPayment: Scalars['String']['output'];
  instalments: Scalars['String']['output'];
  linkedSubscriptions: Scalars['String']['output'];
  marketingConsent: Scalars['String']['output'];
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  nextBillDate: Scalars['String']['output'];
  nextPaymentAmount: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  organizationSubscriptionPlan?: Maybe<OrganizationSubscriptionPlan>;
  passthrough: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  paymentTax: Scalars['String']['output'];
  planName: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  receiptUrl: Scalars['String']['output'];
  saleGross: Scalars['String']['output'];
  source: Scalars['String']['output'];
  status: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  subscriptionPaymentId: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

/** The subcategory type of the Travel Document. */
export enum IdentityDocumentSubType {
  ALIENS_PASSPORT = 'ALIENS_PASSPORT',
  APEC_CARD = 'APEC_CARD',
  BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE',
  BRITISH_CITIZEN = 'BRITISH_CITIZEN',
  BRITISH_DEPENDENT_TERRITORIES_CITIZEN = 'BRITISH_DEPENDENT_TERRITORIES_CITIZEN',
  BRITISH_NATIONAL_OVERSEAS = 'BRITISH_NATIONAL_OVERSEAS',
  BRITISH_OVERSEAS_TERRITORIES_CITIZEN = 'BRITISH_OVERSEAS_TERRITORIES_CITIZEN',
  BRITISH_PROTECTED_PERSON = 'BRITISH_PROTECTED_PERSON',
  BRITISH_SUBJECT = 'BRITISH_SUBJECT',
  BRITSH_OVERSEAS_CITIZEN = 'BRITSH_OVERSEAS_CITIZEN',
  CAR_LICENSE = 'CAR_LICENSE',
  CDB = 'CDB',
  CERTIFICATEOF_CITIZENSHIP = 'CERTIFICATEOF_CITIZENSHIP',
  CERTIFICATEOF_IDENTITY = 'CERTIFICATEOF_IDENTITY',
  CERTIFICATEOF_NATURALIZATION = 'CERTIFICATEOF_NATURALIZATION',
  CNEXITAND_ENTRY_PERMIT = 'CNEXITAND_ENTRY_PERMIT',
  CNONE_WAY_PERMIT = 'CNONE_WAY_PERMIT',
  CONSULAR_PASSPORT = 'CONSULAR_PASSPORT',
  CREW_GENERAL_DECLARATION_FORM = 'CREW_GENERAL_DECLARATION_FORM',
  CREW_MEMBER_CERTIFICATE = 'CREW_MEMBER_CERTIFICATE',
  CREW_MEMBER_IDCARD = 'CREW_MEMBER_IDCARD',
  CREW_MEMBER_LICENCE = 'CREW_MEMBER_LICENCE',
  CTD1951 = 'CTD1951',
  CTD1954 = 'CTD1954',
  DIPLOMATIC_PASSPORT = 'DIPLOMATIC_PASSPORT',
  DOCUMENTOF_IDENTITY = 'DOCUMENTOF_IDENTITY',
  EEPTW = 'EEPTW',
  EMERGENCY_PASSPORT = 'EMERGENCY_PASSPORT',
  FORM_I327 = 'FORM_I327',
  FORM_I512 = 'FORM_I512',
  FORM_I571 = 'FORM_I571',
  GRPOLICE_ID = 'GRPOLICE_ID',
  HONGKONG_SAR_CHINA_PASSPORT = 'HONGKONG_SAR_CHINA_PASSPORT',
  HUI_XIANG_ZHENG = 'HUI_XIANG_ZHENG',
  ICRCTD = 'ICRCTD',
  INTERPOL_IDCARD = 'INTERPOL_IDCARD',
  INTERPOL_PASSPORT = 'INTERPOL_PASSPORT',
  LAISSEZ_PASSER = 'LAISSEZ_PASSER',
  LU_BAO_ZHENG = 'LU_BAO_ZHENG',
  LU_XING_ZHENG = 'LU_XING_ZHENG',
  MACAO_SAR_CHINA_PASSPORT = 'MACAO_SAR_CHINA_PASSPORT',
  MILITARY_IDENTITY_DOCUMENT = 'MILITARY_IDENTITY_DOCUMENT',
  MILITARY_ID_CARD = 'MILITARY_ID_CARD',
  NATIONAL_ID_CARD = 'NATIONAL_ID_CARD',
  NEXUS_CARD = 'NEXUS_CARD',
  NONE = 'NONE',
  NOTARIZED_AFFIDAVITOF_CITIZENSHIP = 'NOTARIZED_AFFIDAVITOF_CITIZENSHIP',
  OASOFFICIAL_TRAVEL_DOCUMENT = 'OASOFFICIAL_TRAVEL_DOCUMENT',
  OFFICIAL_PASSPORT = 'OFFICIAL_PASSPORT',
  OFFICIAL_PHOTO_ID = 'OFFICIAL_PHOTO_ID',
  OTHER_LICENSE = 'OTHER_LICENSE',
  PASSPORT = 'PASSPORT',
  PASSPORT_CARD = 'PASSPORT_CARD',
  PSTD = 'PSTD',
  PUBLIC_AFFAIRS_HK_MO_TRAVEL_PERMIT = 'PUBLIC_AFFAIRS_HK_MO_TRAVEL_PERMIT',
  SEAMAN_BOOK = 'SEAMAN_BOOK',
  SERVICE_PASSPORT = 'SERVICE_PASSPORT',
  SPECIAL_PASSPORT = 'SPECIAL_PASSPORT',
  TAI_BAO_ZHENG = 'TAI_BAO_ZHENG',
  TEMPORARY_PASSPORT = 'TEMPORARY_PASSPORT',
  TEMPORARY_TRAVEL_DOCUMENT = 'TEMPORARY_TRAVEL_DOCUMENT',
  TITREDE_VOYAGE = 'TITREDE_VOYAGE',
  TRANSPORTATION_LETTER = 'TRANSPORTATION_LETTER',
  TRAVEL_CERTIFICATE = 'TRAVEL_CERTIFICATE',
  TRAVEL_PERMIT = 'TRAVEL_PERMIT',
  TRAVEL_PERMIT_HKMO = 'TRAVEL_PERMIT_HKMO',
  TRUCK_LICENSE = 'TRUCK_LICENSE',
  UNMIKTRAVEL_DOCUMENT = 'UNMIKTRAVEL_DOCUMENT',
  UN_LAISSEZ_PASSER = 'UN_LAISSEZ_PASSER',
  VISIT_PERMIT_FOR_RESIDENTS_OF_MACAOTO_HKSAR = 'VISIT_PERMIT_FOR_RESIDENTS_OF_MACAOTO_HKSAR',
  VOTERS_REGISTRATION_CARD = 'VOTERS_REGISTRATION_CARD',
}

/** The category type of the Identity Document. */
export enum IdentityDocumentType {
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  NATIONAL_ID = 'NATIONAL_ID',
}

/** Type of the internet access point (private/residential, business/company, education/university). */
export enum IpAddressType {
  BUSINESS = 'BUSINESS',
  EDUCATION = 'EDUCATION',
  RESIDENTIAL = 'RESIDENTIAL',
}

/** Job Entity. This represents any kind of job that is being offered by AGENCY Organization, and potentially fulfilled by a user from a COURIER Organization. */
export type Job = {
  __typename?: 'Job';
  agencyJobs?: Maybe<Array<AgencyJob>>;
  clientJobs?: Maybe<Array<ClientJob>>;
  courierJobs?: Maybe<Array<CourierJob>>;
  createdAt: Scalars['DateTime']['output'];
  createdByOrganization: Organization;
  createdByUser: User;
  /** The description of the Job */
  description?: Maybe<Scalars['String']['output']>;
  /** The description of the items */
  descriptionOfItems?: Maybe<Scalars['String']['output']>;
  /** Drop-off Address Description */
  dropOffAddressDescription?: Maybe<Scalars['String']['output']>;
  dropOffLocation: Location;
  /** Local (dropOffLocation) drop-off time in UTC */
  dropOffTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2) */
  jobType: JobType;
  /** The number of items transported in the job */
  numOfItems: Scalars['Float']['output'];
  /** Pickup Address Description */
  pickupAddressDescription?: Maybe<Scalars['String']['output']>;
  pickupLocation: Location;
  /** Local (pickupLocation) pickup time in UTC */
  pickupTime: Scalars['DateTime']['output'];
  /** Unique Job Identifier generated by PRIOjet */
  priojetJobIdentifier: Scalars['String']['output'];
  /** The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6) */
  transportedGoodsContentType: TransportGoodsContentType;
  /** Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3) */
  typeOfDelivery: JobTypeOfDelivery;
  updatedAt: Scalars['DateTime']['output'];
  /** The total weights of items transported in the job */
  weightOfItems?: Maybe<Scalars['Float']['output']>;
};

/** Filter types for courier availabilities for certain job */
export enum JobAvailabilityFilterType {
  AVAILABLE = 'AVAILABLE',
  SCHEDULED = 'SCHEDULED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export type JobInput = {
  /** The description of the Job */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The description of the items */
  descriptionOfItems?: InputMaybe<Scalars['String']['input']>;
  /** Drop-off Address Description */
  dropOffAddressDescription?: InputMaybe<Scalars['String']['input']>;
  dropOffLocationSuggestedLocationInput?: InputMaybe<SuggestedLocationInput>;
  /** Local (dropOffLocation) drop-off time in UTC */
  dropOffTime?: InputMaybe<Scalars['DateTime']['input']>;
  /** Job Category Type (EMERGENCY=0, SHORT_NOTICE=1, REGULAR=2) */
  jobType?: JobType;
  /** The number of items transported in the job */
  numOfItems: Scalars['Float']['input'];
  /** Pickup Address Description */
  pickupAddressDescription?: InputMaybe<Scalars['String']['input']>;
  pickupLocationSuggestedLocationInput?: InputMaybe<SuggestedLocationInput>;
  /** Local (pickupLocation) pickup time in UTC */
  pickupTime: Scalars['DateTime']['input'];
  /** The content category of the transported goods. (AOG=0, AUTOMOTIVE_PARTS=1, DOCUMENTS=2, MEDICAL=3, OTHERS=4, PRODUCTION_MATERIALS=5, SPARE_PARTS=6) */
  transportedGoodsContentType: TransportGoodsContentType;
  /** Type of Delivery (DOOR_DOOR=0, AIRPORT_DOOR=1, DOOR_AIRPORT=2, AIRPORT_AIRPORT=3) */
  typeOfDelivery?: JobTypeOfDelivery;
  /** The total weights of items transported in the job */
  weightOfItems?: InputMaybe<Scalars['Float']['input']>;
};

/** The category type of the job. */
export enum JobType {
  EMERGENCY = 'EMERGENCY',
  REGULAR = 'REGULAR',
  SHORT_NOTICE = 'SHORT_NOTICE',
}

/** pickup or handover. */
export enum JobTypeOfDelivery {
  AIRPORT_AIRPORT = 'AIRPORT_AIRPORT',
  AIRPORT_DOOR = 'AIRPORT_DOOR',
  DOOR_AIRPORT = 'DOOR_AIRPORT',
  DOOR_DOOR = 'DOOR_DOOR',
}

/** The level of a language skill */
export enum LanguageSkillLevel {
  ADVANCED = 'ADVANCED',
  BASIC = 'BASIC',
  NATIVE = 'NATIVE',
}

export type LanguagesKnownInput = {
  language: Scalars['String']['input'];
  level: LanguageSkillLevel;
};

/** Location Entity */
export type Location = {
  __typename?: 'Location';
  /** Location Address Components */
  addressComponents?: Maybe<Array<LocationAddressComponent>>;
  addressName?: Maybe<Scalars['String']['output']>;
  airportLocationData?: Maybe<Scalars['JSONObject']['output']>;
  apartmentOrSuite?: Maybe<Scalars['String']['output']>;
  availabilityLocations?: Maybe<Array<AvailabilityLocation>>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdByUser?: Maybe<User>;
  /** Data Language in ISO 639-1 format */
  dataLanguage?: Maybe<Scalars['String']['output']>;
  deviceLocations?: Maybe<Array<DeviceLocation>>;
  /** The formatted address of the location */
  formattedAddress?: Maybe<Scalars['String']['output']>;
  formattedAirportAddress?: Maybe<Scalars['String']['output']>;
  formattedMinimalAddress?: Maybe<Scalars['String']['output']>;
  formattedShortAddress?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  iataCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  isPrivate: Scalars['Boolean']['output'];
  /** JSON Places Geocoding API Responses */
  jsonGeocodeResults: Scalars['JSONObject']['output'];
  locationGeometry: LocationGeometry;
  /** (SUBLOCALITY=0, LOCALITY=1, COUNTY=2, STATE=3, COUNTRY=4, DELIVERY_ADDRESS=5, STREET_ADDRESS=6, AIRPORT=7, AIRPORT_TERMINAL=8, GEO_PERIMETER=9, GEO_COORDINATES=10, POINT_OF_INTEREST=11, UNKNOWN=12, AIRPORT_LOCATION=13) */
  locationType: LocationType;
  /** The place_id from the Geocoding API */
  placeId?: Maybe<Scalars['String']['output']>;
  /** Geocoding API Plus Code */
  plusCode?: Maybe<LocationPlusCode>;
  postalCode?: Maybe<Scalars['String']['output']>;
  /** An array denoting all the localities contained in a postal code. Only present when types is postal_code that contains multiple localities. */
  postcodeLocalities?: Maybe<Array<Scalars['String']['output']>>;
  state?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  timeZoneIdentifier?: Maybe<Scalars['String']['output']>;
  timeZoneRawOffset?: Maybe<Scalars['Float']['output']>;
  /** JSON Places Geocoding API types */
  types: Array<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userRegisteredAddressLocation?: Maybe<User>;
};

export type LocationAddressComponent = {
  __typename?: 'LocationAddressComponent';
  longName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  types: Array<Scalars['String']['output']>;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor: Scalars['String']['output'];
  node: Location;
};

export type LocationGeometry = {
  __typename?: 'LocationGeometry';
  bounds?: Maybe<LocationGeometryLatLngBounds>;
  geoFence?: Maybe<LocationGeometryGeoFence>;
  location: LocationGeometryLocation;
  locationType: Scalars['String']['output'];
  viewport?: Maybe<LocationGeometryLatLngBounds>;
};

export type LocationGeometryCoords = {
  __typename?: 'LocationGeometryCoords';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type LocationGeometryGeoFence = {
  __typename?: 'LocationGeometryGeoFence';
  coords: Array<LocationGeometryCoords>;
  geoFenceType: Scalars['String']['output'];
  radius: Scalars['Float']['output'];
};

export type LocationGeometryLatLngBounds = {
  __typename?: 'LocationGeometryLatLngBounds';
  northeast: LocationGeometryCoords;
  southwest: LocationGeometryCoords;
};

export type LocationGeometryLocation = {
  __typename?: 'LocationGeometryLocation';
  alt: Scalars['Float']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type LocationManualInput = {
  addressName?: InputMaybe<Scalars['String']['input']>;
  apartmentOrSuite?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  dataLanguage?: InputMaybe<Scalars['String']['input']>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  streetName?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type LocationPlusCode = {
  __typename?: 'LocationPlusCode';
  compoundCode: Scalars['String']['output'];
  globalCode: Scalars['String']['output'];
};

/** (SUBLOCALITY=0, LOCALITY=1, COUNTY=2, STATE=3, COUNTRY=4, DELIVERY_ADDRESS=5, STREET_ADDRESS=6, AIRPORT=7, AIRPORT_TERMINAL=8, GEO_PERIMETER=9, GEO_COORDINATES=10, POINT_OF_INTEREST=11, UNKNOWN=12, AIRPORT_LOCATION=13) */
export enum LocationType {
  AIRPORT = 'AIRPORT',
  AIRPORT_LOCATION = 'AIRPORT_LOCATION',
  AIRPORT_TERMINAL = 'AIRPORT_TERMINAL',
  COUNTRY = 'COUNTRY',
  COUNTY = 'COUNTY',
  DELIVERY_ADDRESS = 'DELIVERY_ADDRESS',
  GEO_COORDINATES = 'GEO_COORDINATES',
  GEO_PERIMETER = 'GEO_PERIMETER',
  LOCALITY = 'LOCALITY',
  POINT_OF_INTEREST = 'POINT_OF_INTEREST',
  STATE = 'STATE',
  STREET_ADDRESS = 'STREET_ADDRESS',
  SUBLOCALITY = 'SUBLOCALITY',
  UNKNOWN = 'UNKNOWN',
}

/** The type of message text, file, location. */
export enum MessageType {
  FILE = 'FILE',
  LOCATION = 'LOCATION',
  TEXT = 'TEXT',
  TRACKER_COURIER_JOINED = 'TRACKER_COURIER_JOINED',
  TRACKER_COURIER_LEFT = 'TRACKER_COURIER_LEFT',
  TRACKER_COURIER_TRACKING_POINT_COMPLETED = 'TRACKER_COURIER_TRACKING_POINT_COMPLETED',
  TRACKER_COURIER_TRACKING_POINT_RESET = 'TRACKER_COURIER_TRACKING_POINT_RESET',
  TRACKER_FLIGHT_DETAILS_CREATED = 'TRACKER_FLIGHT_DETAILS_CREATED',
  TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE = 'TRACKER_FLIGHT_DETAILS_DOCUMENT_CHANGE',
  TRACKER_FLIGHT_DETAILS_REMOVED = 'TRACKER_FLIGHT_DETAILS_REMOVED',
  TRACKER_FLIGHT_DETAILS_UPDATED = 'TRACKER_FLIGHT_DETAILS_UPDATED',
}

export type MinSupportedVersion = {
  __typename?: 'MinSupportedVersion';
  currentAppVersion: Scalars['String']['output'];
  minSupportedAppVersion: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateAccount: UserContext;
  addDeviceLog: AddDeviceLogOutput;
  addDeviceLogUnauthenticated: AddDeviceLogOutput;
  addOrDisableCourierForCourierJob: Array<CourierJob>;
  addOrDisableCourierJob: Array<AvailableCourierOrganizationUsersWithDistance>;
  agencyCourierUserProfileCreateOrUpdate: AgencyCourierUserProfile;
  agencyJobPlanningForAgencyJobUpdate: AgencyJobPlanning;
  agencyJobPlanningItemReset: AgencyJobPlanningItem;
  agencyJobPlanningItemUpdate: AgencyJobPlanningItem;
  agencyJobUpdateInternalNotes: AgencyJob;
  airportLocationUpdateTimezone: Location;
  authSignOutUserContext: Scalars['Boolean']['output'];
  changeAgencyJobStatusTypeForAgencyOrganization: AgencyJob;
  connectWithAgencyOrganizationAsCourierOrganization: OrganizationOrganization;
  createAvailability: Availability;
  createChatMessage: ChatMessageCreateEdge;
  createDeviceLocations?: Maybe<DeviceLocation>;
  createOrUpdateAgencyEmployeeUserContact: UserContact;
  createOrUpdateCourierUserContact: UserContact;
  createOrUpdateJobForAgencyOrganization: AgencyJob;
  createOrganizationInvitationAsPriojet: OrganizationInvitation;
  createOrganizationInvitationSharing: OrganizationInvitationSharing;
  createUserBackgroundDocument: UserBackgroundDocument;
  createUserFrequentFlyerDocument: UserFrequentFlyerDocument;
  createUserHealthReport: UserHealthReport;
  createUserIdentityDocument: UserIdentityDocument;
  createUserOtherDocument: UserOtherDocument;
  createUserTravelDocument: UserTravelDocument;
  createUserVaccinationDocument: UserVaccinationDocument;
  deleteAgencyAccount: Scalars['Boolean']['output'];
  deleteAvailability?: Maybe<Availability>;
  deleteChatMessage: ChatMessage;
  deleteCourierAccount: Scalars['Boolean']['output'];
  deleteOrganizationLogo: Organization;
  deleteUserBackgroundDocument: EntityDeletionReturnType;
  deleteUserFrequentFlyerDocument: EntityDeletionReturnType;
  deleteUserHealthReport: EntityDeletionReturnType;
  deleteUserIdentityDocument: EntityDeletionReturnType;
  deleteUserOtherDocument: EntityDeletionReturnType;
  deleteUserProfilePicture: User;
  deleteUserTravelDocument: EntityDeletionReturnType;
  deleteUserVaccinationDocument: EntityDeletionReturnType;
  disableAdHocAvailability: Scalars['Boolean']['output'];
  findOrCreateAdHocAvailability: Availability;
  getOrCreateDevice: Device;
  markAllNewSystemNotificationsAsReadForOrganizationUser: Scalars['Float']['output'];
  markSystemNotificationAsReadForOrganizationUser: Scalars['Float']['output'];
  notificationSettingUpdate: NotificationSetting;
  organizationCourierCategoriesUpdate: Array<OrganizationCourierCategory>;
  organizationInvitationDelete: OrganizationInvitation;
  organizationSubscriptionPlanCancel: OrganizationSubscriptionPlan;
  organizationUserRemoveOrganizationUserFromOrganization: Scalars['Boolean']['output'];
  organizationUserUpdateProfile: OrganizationUser;
  registerExistingUserWithOwnedOrganization: OrganizationUser;
  restoreAgencyAccount: User;
  restoreCourierAccount: User;
  updateAgencyOrganizationProfile: Organization;
  updateAgencyOrganizationVatId: Organization;
  updateChatMessage: ChatMessageCreateEdge;
  updateCourierOrganizationProfile: Organization;
  updateCourierUserProfile: CourierUserProfile;
  updateDevice: Device;
  updateRelationshipTypeForCourierJobAsAgencyOrganization: CourierJob;
  updateRelationshipTypeForCourierJobAsCourierOrganization: CourierJob;
  updateUserProfile: User;
  userAccountRestore: User;
  userDeleteAccount: Scalars['Boolean']['output'];
  voidOrganizationInvitation: OrganizationInvitation;
};

export type MutationActivateAccountArgs = {
  activationCode: Scalars['String']['input'];
};

export type MutationAddDeviceLogArgs = {
  addDeviceLogInputs: Array<AddDeviceLogInput>;
};

export type MutationAddDeviceLogUnauthenticatedArgs = {
  addDeviceLogInputs: Array<AddDeviceLogInput>;
};

export type MutationAddOrDisableCourierForCourierJobArgs = {
  agencyJobId: Scalars['String']['input'];
  courierOrganizationUserIds: Array<Scalars['String']['input']>;
};

export type MutationAddOrDisableCourierJobArgs = {
  agencyJobId: Scalars['String']['input'];
  courierOrganizationUserIds: Array<Scalars['String']['input']>;
};

export type MutationAgencyCourierUserProfileCreateOrUpdateArgs = {
  data: AgencyCourierUserProfileCreateOrUpdateInput;
};

export type MutationAgencyJobPlanningForAgencyJobUpdateArgs = {
  agencyJobId: Scalars['String']['input'];
  agencyJobPlanningForAgencyJobUpdateInput: AgencyJobPlanningForAgencyJobUpdateInput;
  agencyJobPlanningId: Scalars['String']['input'];
};

export type MutationAgencyJobPlanningItemResetArgs = {
  agencyJobPlanningId: Scalars['String']['input'];
  agencyJobPlanningItemId: Scalars['String']['input'];
};

export type MutationAgencyJobPlanningItemUpdateArgs = {
  agencyJobPlanningId: Scalars['String']['input'];
  agencyJobPlanningItemUpdateInput: AgencyJobPlanningItemUpdateInput;
};

export type MutationAgencyJobUpdateInternalNotesArgs = {
  agencyJobId: Scalars['ID']['input'];
  internalNotes: Scalars['String']['input'];
};

export type MutationAirportLocationUpdateTimezoneArgs = {
  data: AirportLocationUpdateTimezoneInput;
};

export type MutationAuthSignOutUserContextArgs = {
  data: AuthSignOutUserContextInput;
};

export type MutationChangeAgencyJobStatusTypeForAgencyOrganizationArgs = {
  agencyJobId: Scalars['String']['input'];
  agencyJobStatusType: AgencyJobStatusType;
};

export type MutationConnectWithAgencyOrganizationAsCourierOrganizationArgs = {
  activationCode: Scalars['String']['input'];
  agencyOrganizationId: Scalars['String']['input'];
  courierOrganizationId: Scalars['String']['input'];
};

export type MutationCreateAvailabilityArgs = {
  endTime: Scalars['DateTime']['input'];
  startTime: Scalars['DateTime']['input'];
  suggestedLocationInput: SuggestedLocationInput;
  timeZoneDestinationOffset: Scalars['Float']['input'];
  timeZoneIdentifier: Scalars['String']['input'];
  timeZoneRawOffset: Scalars['Float']['input'];
};

export type MutationCreateChatMessageArgs = {
  createChatMessageInput: CreateChatMessageInput;
};

export type MutationCreateDeviceLocationsArgs = {
  deviceLocationInputs: Array<DeviceLocationInput>;
};

export type MutationCreateOrUpdateAgencyEmployeeUserContactArgs = {
  inviteUserContact: Scalars['Boolean']['input'];
  organizationUserRole: OrganizationUserRoleType;
  userContactInput: UserContactInput;
};

export type MutationCreateOrUpdateCourierUserContactArgs = {
  inviteUserContact: Scalars['Boolean']['input'];
  userContactInput: UserContactInput;
};

export type MutationCreateOrUpdateJobForAgencyOrganizationArgs = {
  agencyJobInput: AgencyJobInput;
  clientJobByAgencyInput: ClientJobByAgencyInput;
  courierOrganizationUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  jobInput: JobInput;
};

export type MutationCreateOrganizationInvitationAsPriojetArgs = {
  invitedOrganizationId?: InputMaybe<Scalars['String']['input']>;
  invitedOrganizationType?: InputMaybe<OrganizationType>;
  invitingOrganizationId?: InputMaybe<Scalars['String']['input']>;
  organizationInvitationType: OrganizationInvitationType;
  reservedComment: Scalars['String']['input'];
  roleType?: InputMaybe<OrganizationUserRoleType>;
};

export type MutationCreateOrganizationInvitationSharingArgs = {
  organizationInvitationSharingInput: OrganizationInvitationSharingInput;
};

export type MutationCreateUserBackgroundDocumentArgs = {
  userBackgroundDocumentInput: UserBackgroundDocumentInput;
};

export type MutationCreateUserFrequentFlyerDocumentArgs = {
  userFrequentFlyerDocumentInput: UserFrequentFlyerDocumentInput;
};

export type MutationCreateUserHealthReportArgs = {
  userHealthReportInput: UserHealthReportInput;
};

export type MutationCreateUserIdentityDocumentArgs = {
  userIdentityDocumentInput: UserIdentityDocumentInput;
};

export type MutationCreateUserOtherDocumentArgs = {
  userOtherDocumentInput: UserOtherDocumentInput;
};

export type MutationCreateUserTravelDocumentArgs = {
  mappedUserIdentityDocumentId: Scalars['String']['input'];
  userTravelDocumentInput: UserTravelDocumentInput;
};

export type MutationCreateUserVaccinationDocumentArgs = {
  userVaccinationDocumentInput: UserVaccinationDocumentInput;
};

export type MutationDeleteAgencyAccountArgs = {
  userId: Scalars['String']['input'];
};

export type MutationDeleteAvailabilityArgs = {
  availabilityId: Scalars['String']['input'];
};

export type MutationDeleteChatMessageArgs = {
  deleteChatMessageInput: DeleteChatMessageInput;
};

export type MutationDeleteCourierAccountArgs = {
  userId: Scalars['String']['input'];
};

export type MutationDeleteOrganizationLogoArgs = {
  organizationId: Scalars['String']['input'];
};

export type MutationDeleteUserBackgroundDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDeleteUserFrequentFlyerDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDeleteUserHealthReportArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDeleteUserIdentityDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDeleteUserOtherDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDeleteUserTravelDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationDeleteUserVaccinationDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type MutationFindOrCreateAdHocAvailabilityArgs = {
  deviceLocationId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationGetOrCreateDeviceArgs = {
  deviceInput: DeviceInput;
};

export type MutationMarkAllNewSystemNotificationsAsReadForOrganizationUserArgs = {
  organizationUserId: Scalars['String']['input'];
};

export type MutationMarkSystemNotificationAsReadForOrganizationUserArgs = {
  notificationId: Scalars['String']['input'];
  organizationUserId: Scalars['String']['input'];
};

export type MutationNotificationSettingUpdateArgs = {
  data: NotificationSettingUpdateInput;
};

export type MutationOrganizationCourierCategoriesUpdateArgs = {
  data: OrganizationCourierCategoriesUpdateInput;
};

export type MutationOrganizationInvitationDeleteArgs = {
  organizationInvitationId: Scalars['String']['input'];
};

export type MutationOrganizationSubscriptionPlanCancelArgs = {
  data: OrganizationSubscriptionPlanSingleInput;
};

export type MutationOrganizationUserRemoveOrganizationUserFromOrganizationArgs = {
  organizationUserId: Scalars['String']['input'];
};

export type MutationOrganizationUserUpdateProfileArgs = {
  organizationUserUpdateProfileInput: OrganizationUserUpdateProfileInput;
};

export type MutationRegisterExistingUserWithOwnedOrganizationArgs = {
  organizationType: OrganizationType;
};

export type MutationRestoreAgencyAccountArgs = {
  userId: Scalars['String']['input'];
};

export type MutationRestoreCourierAccountArgs = {
  userId: Scalars['String']['input'];
};

export type MutationUpdateAgencyOrganizationProfileArgs = {
  agencyOrganizationProfileInput: AgencyOrganizationProfileInput;
};

export type MutationUpdateAgencyOrganizationVatIdArgs = {
  agencyOrganizationVatIdInput: AgencyOrganizationVatIdInput;
};

export type MutationUpdateChatMessageArgs = {
  updateChatMessageInput: UpdateChatMessageInput;
};

export type MutationUpdateCourierOrganizationProfileArgs = {
  courierOrganizationProfileInput: CourierOrganizationProfileInput;
};

export type MutationUpdateCourierUserProfileArgs = {
  courierUserProfileInput: CourierUserProfileInput;
};

export type MutationUpdateDeviceArgs = {
  deviceInput: DeviceInput;
};

export type MutationUpdateRelationshipTypeForCourierJobAsAgencyOrganizationArgs = {
  agencyJobId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  relationshipType: CourierJobAgencyRelationshipType;
};

export type MutationUpdateRelationshipTypeForCourierJobAsCourierOrganizationArgs = {
  id: Scalars['String']['input'];
  relationshipType: CourierJobAgencyRelationshipType;
};

export type MutationUpdateUserProfileArgs = {
  userProfileInput: UserProfileInput;
};

export type MutationUserAccountRestoreArgs = {
  userId: Scalars['String']['input'];
};

export type MutationUserDeleteAccountArgs = {
  userId: Scalars['String']['input'];
};

export type MutationVoidOrganizationInvitationArgs = {
  id: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  agencyJob?: Maybe<AgencyJob>;
  batchId: Scalars['ID']['output'];
  chatMessage?: Maybe<ChatMessage>;
  clientJob?: Maybe<ClientJob>;
  /** The content of the Notification */
  content?: Maybe<Scalars['String']['output']>;
  /** The mime/type content type of the notification */
  contentType?: Maybe<Scalars['String']['output']>;
  courierJob?: Maybe<CourierJob>;
  createdAt: Scalars['DateTime']['output'];
  /** Additional data being used to create or used for the Notification */
  data?: Maybe<Scalars['JSONObject']['output']>;
  device?: Maybe<Device>;
  /** When this notification was first displayed in the app */
  firstDisplayedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  job?: Maybe<Job>;
  /** The language of the Notification (EN, DE, FR, EN, ...) */
  language: NotificationLanguage;
  /** When this notification was marked as read */
  markedAsReadAt?: Maybe<Scalars['DateTime']['output']>;
  /** The means type of the Notification, like how it was sent (EXPO_PUSH, EMAIL, SMS,...) */
  notificationMeansType: NotificationMeansType;
  /** NotificationType (see documentation) */
  notificationType: NotificationType;
  organization?: Maybe<Organization>;
  /** The context in which this Organization was sent to the device. (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5) */
  organizationContextType?: Maybe<OrganizationType>;
  organizationUser?: Maybe<OrganizationUser>;
  /** Received time (null if unknown) */
  receivedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The recipient's identifier or address (push key, phone number, email, etc.). */
  recipientIdentifierOrAddress?: Maybe<Scalars['String']['output']>;
  /** Meta data from the request of sending this notification. */
  requestMetaData: Scalars['JSONObject']['output'];
  /** Meta data from the response of sending this notification. */
  responseMetaData: Scalars['JSONObject']['output'];
  /** Sent time (null if unknown) */
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  /** Title of the Notification */
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

/** The language of the notification content that was sent out. */
export enum NotificationLanguage {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
}

/** The means of how the notification was sent (push, sms, email). */
export enum NotificationMeansType {
  EMAIL = 'EMAIL',
  EXPO_PUSH = 'EXPO_PUSH',
  FAX = 'FAX',
  LETTER = 'LETTER',
  PHONE_CALL = 'PHONE_CALL',
  SMS = 'SMS',
  SYSTEM = 'SYSTEM',
}

/** Notification setting entity */
export type NotificationSetting = {
  __typename?: 'NotificationSetting';
  createdAt: Scalars['DateTime']['output'];
  emailConnection: Scalars['Boolean']['output'];
  emailJob: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  pushAvailability: Scalars['Boolean']['output'];
  pushChat: Scalars['Boolean']['output'];
  pushConnection: Scalars['Boolean']['output'];
  pushJob: Scalars['Boolean']['output'];
  pushReminder: Scalars['Boolean']['output'];
  systemAvailability: Scalars['Boolean']['output'];
  systemChat: Scalars['Boolean']['output'];
  systemConnection: Scalars['Boolean']['output'];
  systemJob: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type NotificationSettingUpdateInput = {
  emailConnection?: Scalars['Boolean']['input'];
  emailJob?: Scalars['Boolean']['input'];
  pushAvailability?: Scalars['Boolean']['input'];
  pushChat?: Scalars['Boolean']['input'];
  pushConnection?: Scalars['Boolean']['input'];
  pushJob?: Scalars['Boolean']['input'];
  systemAvailability?: Scalars['Boolean']['input'];
  systemChat?: Scalars['Boolean']['input'];
  systemConnection?: Scalars['Boolean']['input'];
  systemJob?: Scalars['Boolean']['input'];
};

/** Filter types for system notification */
export enum NotificationSystemFilterType {
  AVAILABILITY = 'AVAILABILITY',
  CHAT = 'CHAT',
  CONNECTION = 'CONNECTION',
  JOB = 'JOB',
}

/** The type of the notification. */
export enum NotificationType {
  AGENCY_CHAT_NEW_CHAT_MESSAGE = 'AGENCY_CHAT_NEW_CHAT_MESSAGE',
  AGENCY_COURIER_ACCOUNT_DELETION = 'AGENCY_COURIER_ACCOUNT_DELETION',
  AGENCY_JOB_PLANNING_ITEM_MESSAGE = 'AGENCY_JOB_PLANNING_ITEM_MESSAGE',
  AGENCY_JOB_PLANNING_LEG_MESSAGE = 'AGENCY_JOB_PLANNING_LEG_MESSAGE',
  AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT = 'AGENCY_ORGANIZATION_AGENCY_JOB_ADD_REMOVE_AGENT',
  AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER = 'AVAILABILITY_AGENCY_AVAILABILITY_CREATED_BY_COURIER',
  CHAT_NEW_CHAT_MESSAGE = 'CHAT_NEW_CHAT_MESSAGE',
  CHAT_NEW_CHAT_MESSAGE_FOR_JOB = 'CHAT_NEW_CHAT_MESSAGE_FOR_JOB',
  COURIER_APP_REMINDER = 'COURIER_APP_REMINDER',
  JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_ACCEPTED_BY_COURIER',
  JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_DECLINED_BY_COURIER',
  JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER = 'JOB_AGENCY_COURIER_JOB_REQUESTED_BY_COURIER',
  JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_CANCELLED_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_FINISHED_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_RESET_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_RESET_BY_AGENCY',
  JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY = 'JOB_COURIER_AGENCY_JOB_STARTED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_ACCEPTED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_CONFIRMED_FINISHED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_DECLINED_BY_AGENCY',
  JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY = 'JOB_COURIER_COURIER_JOB_REQUESTED_BY_AGENCY',
  ORGANIZATION_ORGANIZATION_NEW_COURIER_AGENCY_CONNECTION = 'ORGANIZATION_ORGANIZATION_NEW_COURIER_AGENCY_CONNECTION',
}

export type OrderArgs = {
  direction: OrderDirection;
  field: Scalars['String']['input'];
  type: OrderType;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  asc = 'asc',
  desc = 'desc',
}

/** Order type - number, string or date */
export enum OrderType {
  date = 'date',
  number = 'number',
  string = 'string',
}

/** Organization Entity. This represents any form of organization that are the main entities (think business) that do business through their respective users. */
export type Organization = {
  __typename?: 'Organization';
  agencyCourierUserProfiles: Array<AgencyCourierUserProfile>;
  agencyJobs: Array<AgencyJob>;
  clientJobs?: Maybe<Array<ClientJob>>;
  /** compressed Logo blob name in azure storage */
  compressedLogoBlobName?: Maybe<Scalars['String']['output']>;
  /** compressed Logo Metadata */
  compressedLogoMetadata?: Maybe<StoredAssetMetadata>;
  connectedOrganizationOrganizations?: Maybe<Array<OrganizationOrganization>>;
  connectedOrganizations: Array<ConnectedOrganization>;
  connectingOrganizationOrganizations?: Maybe<Array<OrganizationOrganization>>;
  courierJobs?: Maybe<Array<CourierJob>>;
  createdAt: Scalars['DateTime']['output'];
  /** Description and intro text of the Organization. */
  description?: Maybe<Scalars['String']['output']>;
  /** Primary email address of the Organization. */
  email?: Maybe<Scalars['String']['output']>;
  headquartersAddressLocation?: Maybe<Location>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  incomingOrganizationInvitations: Array<OrganizationInvitation>;
  /** Invoice email address of the Organization. */
  invoiceEmail?: Maybe<Scalars['String']['output']>;
  invoicingAddressLocation?: Maybe<Location>;
  /** Legal type of the organization or business entity. Can be blank. */
  legalEntityType?: Maybe<Scalars['String']['output']>;
  /** Legal Name of the Organization. For private individuals it's most of the times their name. */
  legalName: Scalars['String']['output'];
  /** Link CO2 emissions of the Organization. */
  linkCO2Emissions?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<StoredAsset>;
  /** original Logo container name in azure storage */
  logoContainerName?: Maybe<Scalars['String']['output']>;
  /** Name of the Organization */
  name: Scalars['String']['output'];
  organizationContacts: Array<OrganizationContact>;
  organizationCourierCategories?: Maybe<Array<OrganizationCourierCategory>>;
  organizationSubscriptionPlan?: Maybe<OrganizationSubscriptionPlan>;
  organizationSubscriptionPlans?: Maybe<Array<OrganizationSubscriptionPlan>>;
  /** The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5) */
  organizationType: OrganizationType;
  organizationUserOwners: Array<OrganizationUser>;
  organizationUsers: Array<OrganizationUser>;
  /** original Logo blob name in azure storage */
  originalLogoBlobName?: Maybe<Scalars['String']['output']>;
  /** Original Logo Metadata */
  originalLogoMetadata?: Maybe<StoredAssetMetadata>;
  outgoingOrganizationInvitations: Array<OrganizationInvitation>;
  /** Primary phone number of the Organization. */
  phone?: Maybe<Scalars['String']['output']>;
  /** Pipedrive organization id */
  pipedriveOrganizationId?: Maybe<Scalars['Int']['output']>;
  registrationAddressLocation?: Maybe<Location>;
  /** Registration number of the Organization. */
  registrationNumber?: Maybe<Scalars['String']['output']>;
  /** Short name of the Organization */
  shortName?: Maybe<Scalars['String']['output']>;
  /** Social profiles */
  socialProfiles: Array<SocialProfile>;
  /** tiny Logo blob name in azure storage */
  tinyLogoBlobName?: Maybe<Scalars['String']['output']>;
  /** tiny Logo Metadata */
  tinyLogoMetadata?: Maybe<StoredAssetMetadata>;
  updatedAt: Scalars['DateTime']['output'];
  usedOrganizationInvitation?: Maybe<OrganizationInvitation>;
  /** VAT ID usually for Agency Organizations */
  vatId?: Maybe<Scalars['String']['output']>;
};

/** Organization Entity. This represents any form of organization that are the main entities (think business) that do business through their respective users. */
export type OrganizationConnectedOrganizationsArgs = {
  connectionTypes?: InputMaybe<Array<OrganizationOrganizationConnectionType>>;
};

/** Contact Entity. This represents any kind of contact that was imported to the application. Think of a lead. */
export type OrganizationContact = {
  __typename?: 'OrganizationContact';
  /** History of changes to this OrganizationContact */
  changes: Array<OrganizationContactChanges>;
  contact: Contact;
  createdAt: Scalars['DateTime']['output'];
  createdByUser?: Maybe<User>;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** First names */
  firstNames: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Is this contact an Agency Contact */
  isAgencyContact: Scalars['Boolean']['output'];
  /** Is this contact a Client Contact */
  isClientContact: Scalars['Boolean']['output'];
  /** Is this contact a Courier Contact */
  isCourierContact: Scalars['Boolean']['output'];
  /** Is this contact an Other Contact */
  isOtherContact: Scalars['Boolean']['output'];
  /** Is this contact coming from PowerAutomate */
  isPowerAutomateContact: Scalars['Boolean']['output'];
  /** Is this contact a PRIOjet Contact */
  isPriojetContact: Scalars['Boolean']['output'];
  /** Is this contact a ServiceProvider Contact */
  isServiceProviderContact: Scalars['Boolean']['output'];
  /** Is this contact a Traveler Contact */
  isTravelerContact: Scalars['Boolean']['output'];
  /** Last name */
  lastName: Scalars['String']['output'];
  organization: Organization;
  organizationInvitations: Array<OrganizationInvitation>;
  /** Phone Number */
  phone: Scalars['String']['output'];
  /** Phone Number (unformatted) */
  phoneUnformatted: Scalars['String']['output'];
  /** When this contact was lastly synced with the CRM. */
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userContacts: Array<UserContact>;
  userContactsForUser: Array<UserContact>;
};

/** Contact Entity. This represents any kind of contact that was imported to the application. Think of a lead. */
export type OrganizationContactUserContactsForUserArgs = {
  userId: Scalars['String']['input'];
};

/** OrganizationContact Changes. */
export type OrganizationContactChanges = {
  __typename?: 'OrganizationContactChanges';
  email: Scalars['String']['output'];
  firstNames: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationCourierCategoriesUpdateInput = {
  categories: Array<OrganizationCourierCategoryUpdate>;
};

/** AgencyCourierCategory Entity */
export type OrganizationCourierCategory = {
  __typename?: 'OrganizationCourierCategory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Default label */
  label: Scalars['String']['output'];
  /** Category order */
  order: Scalars['Float']['output'];
  organization: Organization;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationCourierCategoryUpdate = {
  id: Scalars['ID']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** OrganizationInvitation Entity */
export type OrganizationInvitation = {
  __typename?: 'OrganizationInvitation';
  activationCode?: Maybe<Scalars['String']['output']>;
  /** OrganizationInvitationBitrix24Deal Data */
  bitrix24Deal?: Maybe<OrganizationInvitationBitrix24Deal>;
  createdAt: Scalars['DateTime']['output'];
  createdByUser?: Maybe<User>;
  hasInvitationDeal: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  invitedOrganization?: Maybe<Organization>;
  /** Invited Organization Type (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5). The OrganizationType of the Organization that can be activated with this activationCode. */
  invitedOrganizationType?: Maybe<OrganizationType>;
  invitedUser?: Maybe<User>;
  invitingOrganization?: Maybe<Organization>;
  organizationContact?: Maybe<OrganizationContact>;
  organizationInvitationSharings: Array<OrganizationInvitationSharing>;
  /** The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. (ACTIVATE_NEW_OWN_ORGANIZATION=0, ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION=1, CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION=2, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE=3, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION=4, JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION=5, OTHER_ORGANIZATION_INVITATION=6, DEPRECATED_OLD_ORGANIZATION_INVITATION=7) */
  organizationInvitationType: OrganizationInvitationType;
  /** OrganizationInvitationPipedriveDeal Data */
  pipedriveDeal?: Maybe<OrganizationInvitationPipedriveDeal>;
  /** OrganizationInvitationPipedriveDeal Data */
  pipedriveDealOBCInitial?: Maybe<OrganizationInvitationPipedriveDeal>;
  reservedAt?: Maybe<Scalars['DateTime']['output']>;
  reservedComment?: Maybe<Scalars['String']['output']>;
  /** Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST). The RoleType of the OrganizationUser that can be created with this activationCode, if applicable. */
  roleType?: Maybe<OrganizationUserRoleType>;
  updatedAt: Scalars['DateTime']['output'];
  usedAt?: Maybe<Scalars['DateTime']['output']>;
  usedByUser?: Maybe<User>;
  usedForOrganization?: Maybe<Organization>;
  usedForOrganizationOrganization?: Maybe<OrganizationOrganization>;
  usedForOrganizationUser?: Maybe<OrganizationUser>;
  voidedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Bitrix24 Deal. */
export type OrganizationInvitationBitrix24Deal = {
  __typename?: 'OrganizationInvitationBitrix24Deal';
  activationCode: Scalars['String']['output'];
  bitrixDealCategoryId: Scalars['Float']['output'];
  bitrixInvitedCompanyId: Scalars['Float']['output'];
  bitrixInvitedContactId: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  invitingOrganizationEmailAddress: Scalars['String']['output'];
  invitingOrganizationName: Scalars['String']['output'];
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Pipedrive Deal. */
export type OrganizationInvitationPipedriveDeal = {
  __typename?: 'OrganizationInvitationPipedriveDeal';
  activationCode: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  invitingOrganizationEmail: Scalars['String']['output'];
  invitingOrganizationId: Scalars['String']['output'];
  invitingOrganizationName: Scalars['String']['output'];
  pipedriveOrganizationId: Scalars['Float']['output'];
  pipedrivePersonId: Scalars['Float']['output'];
  stageId: Scalars['Float']['output'];
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** OrganizationInvitationSharing Entity */
export type OrganizationInvitationSharing = {
  __typename?: 'OrganizationInvitationSharing';
  appName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  organizationInvitation: OrganizationInvitation;
  platform: Scalars['String']['output'];
  platformVersion?: Maybe<Scalars['String']['output']>;
  sharedAt: Scalars['DateTime']['output'];
  sharedByUser?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OrganizationInvitationSharingInput = {
  appName?: InputMaybe<Scalars['String']['input']>;
  inactivatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  organizationInvitationId: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  platformVersion?: InputMaybe<Scalars['String']['input']>;
  sharedAt: Scalars['DateTime']['input'];
};

/** The type of the OrganizationInvitation that distinguishes how the User/Organization is activated and connected. */
export enum OrganizationInvitationType {
  ACTIVATE_NEW_OWN_ORGANIZATION = 'ACTIVATE_NEW_OWN_ORGANIZATION',
  ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION = 'ACTIVATE_NEW_OWN_ORGANIZATION_AND_CONNECT_TO_OTHER_ORGANIZATION',
  CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION = 'CONNECT_OWN_ORGANIZATION_TO_OTHER_ORGANIZATION',
  DEPRECATED_OLD_ORGANIZATION_INVITATION = 'DEPRECATED_OLD_ORGANIZATION_INVITATION',
  JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE = 'JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE',
  JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION = 'JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_DUMMY_ORGANIZATION',
  JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION = 'JOIN_OTHER_ORGANIZATION_AS_USER_WITH_ROLE_TYPE_AND_DELETE_OWN_ORGANIZATION',
  OTHER_ORGANIZATION_INVITATION = 'OTHER_ORGANIZATION_INVITATION',
}

export type OrganizationInvitationUploadCsv = {
  __typename?: 'OrganizationInvitationUploadCSV';
  contacts: Array<OrganizationInvitationUploadCsvContact>;
  organizationInvitationsNotUsed: Array<Scalars['String']['output']>;
  organizationInvitationsUsed: Array<Scalars['String']['output']>;
};

export type OrganizationInvitationUploadCsvContact = {
  __typename?: 'OrganizationInvitationUploadCSVContact';
  email: Scalars['String']['output'];
  emailError?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  firstNameError?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  lastNameError?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  phoneNumberError?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type OrganizationInvitationUploadCsvInput = {
  emailField: Scalars['String']['input'];
  file: Scalars['String']['input'];
  firstNameField: Scalars['String']['input'];
  lastNameField: Scalars['String']['input'];
  phoneNumberField: Scalars['String']['input'];
};

/** OrganizationOrganization Entity */
export type OrganizationOrganization = {
  __typename?: 'OrganizationOrganization';
  connectedOrganization: Organization;
  connectingOrganization: Organization;
  /** OrganizationOrganizationConnectionType (COURIER_AGENCY, CUSTOMER_AGENCY) */
  connectionType: OrganizationOrganizationConnectionType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  usedOrganizationInvitation: OrganizationInvitation;
};

/** The type of the Organization to Organization connection. */
export enum OrganizationOrganizationConnectionType {
  COURIER_AGENCY = 'COURIER_AGENCY',
  CUSTOMER_AGENCY = 'CUSTOMER_AGENCY',
}

/** Organization subscription plan entity */
export type OrganizationSubscriptionPlan = {
  __typename?: 'OrganizationSubscriptionPlan';
  cancellationEffectiveDate: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  endTime: Scalars['DateTime']['output'];
  historyLogPayments?: Maybe<Array<HistoryLogPayment>>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  initialCreationDate: Scalars['DateTime']['output'];
  isActive: Scalars['Boolean']['output'];
  isMain: Scalars['Boolean']['output'];
  organization: Organization;
  passthrough: Scalars['String']['output'];
  paymentCancelUrl: Scalars['String']['output'];
  paymentCurrency: Scalars['String']['output'];
  paymentMarketingConsent: Scalars['Boolean']['output'];
  paymentNextBillingDate: Scalars['String']['output'];
  paymentNextPaymentAmount: Scalars['String']['output'];
  paymentPaymentTax: Scalars['String']['output'];
  paymentPlanId?: Maybe<Scalars['String']['output']>;
  paymentSaleGross: Scalars['String']['output'];
  paymentSubscriptionId: Scalars['String']['output'];
  paymentSubscriptionPaymentId: Scalars['String']['output'];
  paymentUnitPrice: Scalars['String']['output'];
  /** OrganizationSubscriptionPlanPipedriveDeal Data */
  pipedriveDeal?: Maybe<OrganizationSubscriptionPlanPipedriveDeal>;
  /** OrganizationSubscriptionPlanPipedriveDeal Cancelled Data */
  pipedriveDealCancelled?: Maybe<OrganizationSubscriptionPlanPipedriveDeal>;
  previousPaymentSubscriptionId: Scalars['String']['output'];
  prices?: Maybe<Array<SubscriptionPlanPrice>>;
  /** Recurring interval - month, year */
  recurringInterval?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
  subscriptionPlan: SubscriptionPlan;
  updatedAt: Scalars['DateTime']['output'];
};

/** Pipedrive Deal. */
export type OrganizationSubscriptionPlanPipedriveDeal = {
  __typename?: 'OrganizationSubscriptionPlanPipedriveDeal';
  cancellationEffectiveDate?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  nextBillingDate?: Maybe<Scalars['String']['output']>;
  pipedriveOrganizationId: Scalars['Float']['output'];
  pipedrivePersonId: Scalars['Float']['output'];
  subscriptionId: Scalars['String']['output'];
  subscriptionPlanId: Scalars['String']['output'];
  syncedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrganizationSubscriptionPlanSingleInput = {
  id: Scalars['String']['input'];
};

/** The type of the Organization: (PRIOJET=0, COURIER=1, AGENCY=2, CLIENT=3, TRAVELER=4, SERVICE_PROVIDER=5) */
export enum OrganizationType {
  AGENCY = 'AGENCY',
  CLIENT = 'CLIENT',
  COURIER = 'COURIER',
  PRIOJET = 'PRIOJET',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  TRAVELER = 'TRAVELER',
}

/** OrganizationUser Entity */
export type OrganizationUser = {
  __typename?: 'OrganizationUser';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  organization: Organization;
  /** Organization User Role Type (OWNER, STAFF, FOLLOWER, ADMIN, TAX_LEGAL, GUEST) */
  roleType: OrganizationUserRoleType;
  updatedAt: Scalars['DateTime']['output'];
  usedOrganizationInvitation?: Maybe<OrganizationInvitation>;
  user: User;
};

/** The user role type of the user to organization relationship. */
export enum OrganizationUserRoleType {
  ADMIN = 'ADMIN',
  FOLLOWER = 'FOLLOWER',
  GUEST = 'GUEST',
  OWNER = 'OWNER',
  STAFF = 'STAFF',
  TAX_LEGAL = 'TAX_LEGAL',
}

export type OrganizationUserUpdateProfileInput = {
  /** Country of birth */
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth */
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  /**  Secondary Email address */
  email2?: InputMaybe<Scalars['String']['input']>;
  /** First names */
  firstNames?: InputMaybe<Scalars['String']['input']>;
  /** Preferred Language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Nationalities, ISO 3166 Alpha-3 code */
  nationalities?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationUserId: Scalars['ID']['input'];
  /** Phone Number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Secondary Phone Number */
  phone2?: InputMaybe<Scalars['String']['input']>;
  /** City of birth */
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  roleType?: InputMaybe<Scalars['String']['input']>;
  /** Male/Female/Other */
  sex?: InputMaybe<Gender>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaginationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
};

export type PluginData = {
  __typename?: 'PluginData';
  description: Scalars['String']['output'];
  mimeTypes: Array<PluginMimeTypeData>;
  name: Scalars['String']['output'];
};

export type PluginDataInput = {
  description: Scalars['String']['input'];
  mimeTypes: Array<PluginMimeTypeDataInput>;
  name: Scalars['String']['input'];
};

export type PluginMimeTypeData = {
  __typename?: 'PluginMimeTypeData';
  suffixes: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type PluginMimeTypeDataInput = {
  suffixes: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations: Array<Availability>;
  activeAvailabilitiesForUser: Array<Availability>;
  agencyCourierJobChat: Chat;
  agencyJobChat: Chat;
  agencyJobForAgencyOrganization: AgencyJob;
  agencyJobPlanningForAgencyJob: AgencyJobPlanning;
  agencyJobsForAgencyOrganization: Array<AgencyJob>;
  agencyJobsForAgencyOrganizationPaginated: AgencyJobConnection;
  airportLocationList: Array<Location>;
  airportLocationListPaginated: AirportLocationConnection;
  airportLocationSearch: Array<Location>;
  allActiveAndUpcomingAvailabilitiesForPriojet: Array<Availability>;
  allUsersForPriojetOrganization: Array<User>;
  availabilitiesForConnectedCourierOrganizations: Array<Availability>;
  availabilitiesForUser: Array<Availability>;
  availability: Availability;
  availabilityFindActiveAdhocCurrentUser?: Maybe<Availability>;
  availableCourierUsersByDistanceForAgencyJob: Array<AvailableCourierOrganizationUsersWithDistance>;
  availableCourierUsersByDistanceForAgencyJobFiltered: Array<AvailableCourierOrganizationUsersWithDistance>;
  chatMessageEdge: ChatMessageCreateEdge;
  chatMessagesByChatId: ChatMessageConnection;
  connectedAgencyOrganizationForCourierOrganization: Organization;
  connectedAgencyOrganizationsForCourierOrganization: Array<ConnectedOrganization>;
  connectedCourierOrganizationsForAgencyOrganization: Array<ConnectedOrganization>;
  courierJobForAgencyOrganization: CourierJob;
  courierJobForCourierOrganization: CourierJob;
  courierJobsForAgencyJob: Array<CourierJob>;
  courierJobsForCourierOrganization: Array<CourierJob>;
  courierJobsForCourierOrganizationMinimal: Array<CourierJob>;
  courierOrganizationInvitationsForAgencyOrganization: Array<OrganizationInvitation>;
  courierUserProfileByUserId: CourierUserProfile;
  courierUserProfileForCourierOrganizationUser: CourierUserProfile;
  currentUserContext: UserContext;
  deletedAccountsPriojetOnly: Array<User>;
  employeeInvitationsForAgencyOrganization: Array<OrganizationInvitation>;
  latestOrNewSystemNotificationsForOrganizationUser: Array<Notification>;
  logoByOrganizationId: StoredAsset;
  minSupportedAppVersion: MinSupportedVersion;
  notificationSettingCurrentUser: NotificationSetting;
  numberOfNewNotificationsForOrganizationUser: Scalars['Float']['output'];
  organization: Organization;
  organizationCourierCategories: Array<OrganizationCourierCategory>;
  organizationInvitationByActivationCodeForCourierOrganization: OrganizationInvitation;
  organizationInvitationUploadCSV: OrganizationInvitationUploadCsv;
  organizationInvitationsForPriojetOrganization: Array<OrganizationInvitation>;
  organizationUserById: OrganizationUser;
  organizations: Array<Organization>;
  profilePictureById: StoredAsset;
  sendTestNotification: Scalars['Boolean']['output'];
  signInUserContext: UserContext;
  subscriptionPlansAgency: SubscriptionPlanPaddle;
  subscriptionPlansCourier: SubscriptionPlanPaddle;
  user: User;
  userBackgroundDocument: UserBackgroundDocument;
  userBackgroundDocuments: Array<UserBackgroundDocument>;
  userContexts: Array<UserContext>;
  userFrequentFlyerDocument: UserFrequentFlyerDocument;
  userFrequentFlyerDocuments: Array<UserFrequentFlyerDocument>;
  userHealthReport: UserHealthReport;
  userHealthReports: Array<UserHealthReport>;
  userIdentityDocument: UserIdentityDocument;
  userIdentityDocuments: Array<UserIdentityDocument>;
  userOtherDocument: UserOtherDocument;
  userOtherDocuments: Array<UserOtherDocument>;
  userTravelDocument: UserTravelDocument;
  userTravelDocuments: Array<UserTravelDocument>;
  userVaccinationDocument: UserVaccinationDocument;
  userVaccinationDocuments: Array<UserVaccinationDocument>;
  userWithAllUserDocuments: User;
  users: Array<User>;
};

export type QueryActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsArgs = {
  agencyOrganizationId: Scalars['String']['input'];
};

export type QueryActiveAvailabilitiesForUserArgs = {
  userId: Scalars['String']['input'];
};

export type QueryAgencyCourierJobChatArgs = {
  agencyCourierJobChatInput: AgencyCourierJobChatInput;
};

export type QueryAgencyJobChatArgs = {
  agencyJobChatInput: AgencyJobChatInput;
};

export type QueryAgencyJobForAgencyOrganizationArgs = {
  agencyJobId: Scalars['String']['input'];
};

export type QueryAgencyJobPlanningForAgencyJobArgs = {
  agencyJobId: Scalars['String']['input'];
};

export type QueryAgencyJobsForAgencyOrganizationArgs = {
  agencyOrganizationId: Scalars['String']['input'];
};

export type QueryAgencyJobsForAgencyOrganizationPaginatedArgs = {
  data: AgencyJobsForAgencyOrganizationInput;
};

export type QueryAirportLocationListArgs = {
  data?: InputMaybe<AirportLocationListInput>;
};

export type QueryAirportLocationListPaginatedArgs = {
  data?: InputMaybe<AirportLocationSearchInput>;
  orderBy?: InputMaybe<OrderArgs>;
  pagination?: InputMaybe<PaginationArgs>;
};

export type QueryAirportLocationSearchArgs = {
  data: AirportLocationSearchInput;
};

export type QueryAvailabilitiesForConnectedCourierOrganizationsArgs = {
  agencyOrganizationId: Scalars['String']['input'];
  filterType?: InputMaybe<Array<AvailabilityFilterType>>;
};

export type QueryAvailabilitiesForUserArgs = {
  userId: Scalars['String']['input'];
};

export type QueryAvailabilityArgs = {
  agencyOrganizationId?: InputMaybe<Scalars['String']['input']>;
  availabilityId: Scalars['String']['input'];
};

export type QueryAvailableCourierUsersByDistanceForAgencyJobArgs = {
  agencyJobId: Scalars['String']['input'];
};

export type QueryAvailableCourierUsersByDistanceForAgencyJobFilteredArgs = {
  agencyJobId: Scalars['String']['input'];
  filterType?: InputMaybe<Array<JobAvailabilityFilterType>>;
};

export type QueryChatMessageEdgeArgs = {
  chatMessageId: Scalars['String']['input'];
};

export type QueryChatMessagesByChatIdArgs = {
  chatMessagesByChatIdInput: ChatMessagesByChatIdInput;
  orderBy?: InputMaybe<OrderArgs>;
  pagination?: InputMaybe<PaginationArgs>;
};

export type QueryConnectedAgencyOrganizationForCourierOrganizationArgs = {
  agencyOrganizationId: Scalars['String']['input'];
  courierOrganizationId: Scalars['String']['input'];
};

export type QueryConnectedAgencyOrganizationsForCourierOrganizationArgs = {
  courierOrganizationId: Scalars['String']['input'];
};

export type QueryConnectedCourierOrganizationsForAgencyOrganizationArgs = {
  agencyOrganizationId: Scalars['String']['input'];
};

export type QueryCourierJobForAgencyOrganizationArgs = {
  agencyOrganizationId: Scalars['String']['input'];
  courierJobId: Scalars['String']['input'];
};

export type QueryCourierJobForCourierOrganizationArgs = {
  courierJobId: Scalars['String']['input'];
  courierOrganizationId: Scalars['String']['input'];
};

export type QueryCourierJobsForAgencyJobArgs = {
  agencyJobId: Scalars['String']['input'];
};

export type QueryCourierJobsForCourierOrganizationArgs = {
  courierOrganizationId: Scalars['String']['input'];
};

export type QueryCourierJobsForCourierOrganizationMinimalArgs = {
  courierOrganizationId: Scalars['String']['input'];
};

export type QueryCourierOrganizationInvitationsForAgencyOrganizationArgs = {
  agencyOrganizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type QueryCourierUserProfileByUserIdArgs = {
  userId: Scalars['String']['input'];
};

export type QueryCourierUserProfileForCourierOrganizationUserArgs = {
  organizationUserId: Scalars['String']['input'];
};

export type QueryEmployeeInvitationsForAgencyOrganizationArgs = {
  agencyOrganizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type QueryLatestOrNewSystemNotificationsForOrganizationUserArgs = {
  filterType?: InputMaybe<Array<NotificationSystemFilterType>>;
  organizationUserId: Scalars['String']['input'];
};

export type QueryLogoByOrganizationIdArgs = {
  organizationId: Scalars['String']['input'];
  userProfilePictureSize: UserProfilePictureSize;
};

export type QueryNumberOfNewNotificationsForOrganizationUserArgs = {
  organizationUserId: Scalars['String']['input'];
};

export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};

export type QueryOrganizationInvitationByActivationCodeForCourierOrganizationArgs = {
  activationCode: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type QueryOrganizationInvitationUploadCsvArgs = {
  data: OrganizationInvitationUploadCsvInput;
};

export type QueryOrganizationUserByIdArgs = {
  organizationUserId: Scalars['String']['input'];
};

export type QueryProfilePictureByIdArgs = {
  userId: Scalars['String']['input'];
  userProfilePictureSize: UserProfilePictureSize;
};

export type QueryUserArgs = {
  id: Scalars['String']['input'];
};

export type QueryUserBackgroundDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserBackgroundDocumentsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserFrequentFlyerDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserFrequentFlyerDocumentsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserHealthReportArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserHealthReportsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserIdentityDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserIdentityDocumentsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserOtherDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserOtherDocumentsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserTravelDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserTravelDocumentsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserVaccinationDocumentArgs = {
  documentId: Scalars['String']['input'];
};

export type QueryUserVaccinationDocumentsArgs = {
  userId: Scalars['String']['input'];
};

export type QueryUserWithAllUserDocumentsArgs = {
  userId: Scalars['String']['input'];
};

/** SAS Token for a document from azure */
export type SasTokenResponse = {
  __typename?: 'SasTokenResponse';
  expiresOn?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Scalars['String']['output']>;
  resource?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type SocialProfile = {
  __typename?: 'SocialProfile';
  platform: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SocialProfileInput = {
  platform: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

/** SAS Object for a document from azure */
export type StoredAsset = {
  __typename?: 'StoredAsset';
  cacheKey: Scalars['String']['output'];
  cacheTTL?: Maybe<Scalars['DateTime']['output']>;
  metadata: StoredAssetMetadata;
  sasToken: SasTokenResponse;
  sasUrl: Scalars['String']['output'];
};

/** Stored Asset Metadata */
export type StoredAssetMetadata = {
  __typename?: 'StoredAssetMetadata';
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type StoredAssetMetadataInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatMessageSent: ChatMessage;
};

export type SubscriptionChatMessageSentArgs = {
  chatId: Scalars['String']['input'];
};

/** Subscription feature entity */
export type SubscriptionFeature = {
  __typename?: 'SubscriptionFeature';
  /** feature max count */
  count: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** name */
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** name */
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3) */
  value: SubscriptionFeatureType;
};

/** Subscription feature entity */
export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  organizationSubscriptionPlans?: Maybe<Array<OrganizationSubscriptionPlan>>;
  planName: SubscriptionPlanName;
  prices: SubscriptionPlanPricePeriod;
  subscriptionFeatures?: Maybe<Array<SubscriptionFeature>>;
  updatedAt: Scalars['DateTime']['output'];
  /** (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5) */
  value: SubscriptionPlanType;
};

export type SubscriptionPlanName = {
  __typename?: 'SubscriptionPlanName';
  defaultValue: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type SubscriptionPlanPaddle = {
  __typename?: 'SubscriptionPlanPaddle';
  month: Array<SubscriptionPlanPaddleType>;
  year: Array<SubscriptionPlanPaddleType>;
};

export type SubscriptionPlanPaddleType = {
  __typename?: 'SubscriptionPlanPaddleType';
  /** JSON Object of the attributes that are affected */
  description: Scalars['JSONObject']['output'];
  /** JSON Object of the attributes that are affected */
  featureDescription: Array<Scalars['JSONObject']['output']>;
  isMain: Scalars['Boolean']['output'];
  /** JSON Object of the attributes that are affected */
  name: Scalars['JSONObject']['output'];
  organizationType: Scalars['Float']['output'];
  planId: Scalars['Int']['output'];
  /** JSON Object of the attributes that are affected */
  prices: Array<Scalars['JSONObject']['output']>;
  /** JSON Object of the attributes that are affected */
  recurringInterval: Scalars['JSONObject']['output'];
  subscriptionPlanType: Scalars['Int']['output'];
  trialPeriod: Scalars['Int']['output'];
};

export type SubscriptionPlanPrice = {
  __typename?: 'SubscriptionPlanPrice';
  currency: Scalars['String']['output'];
  recurringInterval: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SubscriptionPlanPricePeriod = {
  __typename?: 'SubscriptionPlanPricePeriod';
  month: Array<SubscriptionPlanPrice>;
  year: Array<SubscriptionPlanPrice>;
};

/** (COURIER01=0, AGENCY01=1, AGENCY02=2, AGENCY03=3, AGENCY_ADDITIONAL_SEATS=4, COURIER02=5) */
export enum SubscriptionPlanType {
  AGENCY01 = 'AGENCY01',
  AGENCY02 = 'AGENCY02',
  AGENCY03 = 'AGENCY03',
  AGENCY_ADDITIONAL_SEATS = 'AGENCY_ADDITIONAL_SEATS',
  COURIER01 = 'COURIER01',
  COURIER02 = 'COURIER02',
}

export type SuggestedLocationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formattedAddress: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  placeId: Scalars['String']['input'];
  types: Array<Scalars['String']['input']>;
};

export type TLanguageKnown = {
  __typename?: 'TLanguageKnown';
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  level: LanguageSkillLevel;
};

export type TouchSupport = {
  __typename?: 'TouchSupport';
  maxTouchPoints: Scalars['Float']['output'];
  touchEvent: Scalars['Boolean']['output'];
  touchStart: Scalars['Boolean']['output'];
};

export type TouchSupportInput = {
  maxTouchPoints: Scalars['Float']['input'];
  touchEvent: Scalars['Boolean']['input'];
  touchStart: Scalars['Boolean']['input'];
};

/** The content category of the transported goods. */
export enum TransportGoodsContentType {
  AOG = 'AOG',
  AUTOMOTIVE_PARTS = 'AUTOMOTIVE_PARTS',
  DOCUMENTS = 'DOCUMENTS',
  MEDICAL = 'MEDICAL',
  OTHERS = 'OTHERS',
  PRODUCTION_MATERIALS = 'PRODUCTION_MATERIALS',
  SPARE_PARTS = 'SPARE_PARTS',
}

/** The category type of the Travel Document. */
export enum TravelDocumentType {
  FIKTIONSBESCHEINIGUNG = 'FIKTIONSBESCHEINIGUNG',
  VISA = 'VISA',
}

export type UpdateChatMessageInput = {
  chatMessageId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

/** User Entity */
export type User = {
  __typename?: 'User';
  agencyCourierUserProfile?: Maybe<AgencyCourierUserProfile>;
  agencyCourierUserProfiles: Array<AgencyCourierUserProfile>;
  availabilities: Array<Availability>;
  /** compressed profile picture blob name in azure storage */
  compressedProfilePictureBlobName?: Maybe<Scalars['String']['output']>;
  /** compressed profile picture container name in azure storage */
  compressedProfilePictureContainerName?: Maybe<Scalars['String']['output']>;
  /** compressed Profile Picture Metadata */
  compressedProfilePictureMetadata?: Maybe<StoredAssetMetadata>;
  /** Amount of tries with false/wrong activation codes this user attempted */
  countOfActivationCodeAttempts: Scalars['Float']['output'];
  /** Country of birth */
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  courierUserProfile?: Maybe<CourierUserProfile>;
  createdAt: Scalars['DateTime']['output'];
  /** Date of birth */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  deviceLocations: Array<DeviceLocation>;
  deviceLogs: Array<DeviceLog>;
  /** Email address */
  email: Scalars['String']['output'];
  /**  Secondary Email address */
  email2?: Maybe<Scalars['String']['output']>;
  /** First names */
  firstNames: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  incomingOrganizationInvitations: Array<OrganizationInvitation>;
  /** Preferred Language */
  language?: Maybe<Scalars['String']['output']>;
  /** Last name */
  lastName: Scalars['String']['output'];
  locationsCreated: Array<Location>;
  /** Nationalities, ISO 3166 Alpha-3 code */
  nationalities?: Maybe<Array<Scalars['String']['output']>>;
  notificationSetting?: Maybe<NotificationSetting>;
  organizationContactsCreated: Array<OrganizationContact>;
  organizationInvitationSharings: Array<OrganizationContact>;
  organizationInvitationsCreated: Array<OrganizationInvitation>;
  organizationUsers: Array<OrganizationUser>;
  /** original profile picture blob name in azure storage */
  originalProfilePictureBlobName?: Maybe<Scalars['String']['output']>;
  /** original profile picture container name in azure storage */
  originalProfilePictureContainerName?: Maybe<Scalars['String']['output']>;
  /** Original Profile Picture Metadata */
  originalProfilePictureMetadata?: Maybe<StoredAssetMetadata>;
  /** Phone Number */
  phone?: Maybe<Scalars['String']['output']>;
  /** Secondary Phone Number */
  phone2?: Maybe<Scalars['String']['output']>;
  /** Pipedrive organization id */
  pipedrivePersonId?: Maybe<Scalars['Int']['output']>;
  /** City of birth */
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  profilePicture?: Maybe<StoredAsset>;
  /** Male/Female/Other */
  sex?: Maybe<Gender>;
  /** tiny profile picture blob name in azure storage */
  tinyProfilePictureBlobName?: Maybe<Scalars['String']['output']>;
  /** tiny profile picture container name in azure storage */
  tinyProfilePictureContainerName?: Maybe<Scalars['String']['output']>;
  /** tiny Profile Picture Metadata */
  tinyProfilePictureMetadata?: Maybe<StoredAssetMetadata>;
  updatedAt: Scalars['DateTime']['output'];
  userBackgroundDocument: Array<UserBackgroundDocument>;
  userContactsCreated: Array<UserContact>;
  userDevices: Array<UserDevice>;
  userFrequentFlyerDocuments: Array<UserFrequentFlyerDocument>;
  userHealthReports: Array<UserHealthReport>;
  userIdentityDocuments: Array<UserIdentityDocument>;
  userOtherDocuments: Array<UserOtherDocument>;
  userRegisteredAddressLocation?: Maybe<Location>;
  userTravelDocuments: Array<UserTravelDocument>;
  userVaccinationDocuments: Array<UserVaccinationDocument>;
};

/** User Background Document Entity */
export type UserBackgroundDocument = {
  __typename?: 'UserBackgroundDocument';
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** country issuing the document */
  countryOfIssue?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** issued date as per the document */
  dateOfIssue?: Maybe<Scalars['DateTime']['output']>;
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  /** expiry date as per the document */
  expiresOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Name of the the institution issuing the document */
  issuingInstitution?: Maybe<Scalars['String']['output']>;
  /** Name of the document */
  name?: Maybe<Scalars['String']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Background Document Entity */
export type UserBackgroundDocumentDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Background Document Entity */
export type UserBackgroundDocumentDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserBackgroundDocumentInput = {
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** country issuing the document */
  countryOfIssue?: InputMaybe<Scalars['String']['input']>;
  /** issued date as per the document */
  dateOfIssue?: InputMaybe<Scalars['DateTime']['input']>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** expiry date as per the document */
  expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Name of the the institution issuing the document */
  issuingInstitution?: InputMaybe<Scalars['String']['input']>;
  /** Name of the document */
  name?: InputMaybe<Scalars['String']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
};

/** User Contact Entity. The raw contact that users added from their devices. */
export type UserContact = {
  __typename?: 'UserContact';
  /** History of changes to this UserContact */
  changes: Array<UserContactChanges>;
  createdAt: Scalars['DateTime']['output'];
  createdByUser: User;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** JSON of output from Expo Contacts API */
  expoContact?: Maybe<ExpoContact>;
  /** The contact ID from the address book */
  expoContactId?: Maybe<Scalars['String']['output']>;
  /** First names */
  firstNames: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** UserContactImportSource */
  importSource?: Maybe<UserContactImportSourceType>;
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Is this contact an Agency Contact */
  isAgencyContact: Scalars['Boolean']['output'];
  /** Is this contact a Client Contact */
  isClientContact: Scalars['Boolean']['output'];
  /** Is this contact a Courier Contact */
  isCourierContact: Scalars['Boolean']['output'];
  /** Is this contact an Other Contact */
  isOtherContact: Scalars['Boolean']['output'];
  /** Is this contact a PRIOjet Contact */
  isPriojetContact: Scalars['Boolean']['output'];
  /** Is this contact a ServiceProvider Contact */
  isServiceProviderContact: Scalars['Boolean']['output'];
  /** Is this contact a Traveler Contact */
  isTravelerContact: Scalars['Boolean']['output'];
  /** Last name */
  lastName: Scalars['String']['output'];
  /** Phone Number */
  phone: Scalars['String']['output'];
  /** Phone Number (unformatted) */
  phoneUnformatted: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** UserContact Changes. */
export type UserContactChanges = {
  __typename?: 'UserContactChanges';
  email?: Maybe<Scalars['String']['output']>;
  firstNames: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Type of the input source when inviting courier/agency user. */
export enum UserContactImportSourceType {
  CSV = 'CSV',
  PhoneBook = 'PhoneBook',
  PowerAutomate = 'PowerAutomate',
}

export type UserContactInput = {
  /** Email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** JSON of output from Expo Contacts API */
  expoContact?: InputMaybe<ExpoContactInput>;
  /** The contact ID from the address book */
  expoContactId?: InputMaybe<Scalars['String']['input']>;
  /** First names */
  firstNames: Scalars['String']['input'];
  /** UserContactImportSource */
  importSource?: InputMaybe<UserContactImportSourceType>;
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Phone Number */
  phone: Scalars['String']['input'];
  /** Phone Number (unformatted) */
  phoneUnformatted: Scalars['String']['input'];
};

export type UserContext = {
  __typename?: 'UserContext';
  accessTokenHash: Scalars['String']['output'];
  device: Device;
  deviceId: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  organizationActivatedAt?: Maybe<Scalars['DateTime']['output']>;
  organizationSubscriptionPlan?: Maybe<OrganizationSubscriptionPlan>;
  organizationType?: Maybe<OrganizationType>;
  organizationUser?: Maybe<OrganizationUser>;
  role: Scalars['String']['output'];
  roleType?: Maybe<OrganizationUserRoleType>;
  sub: Scalars['String']['output'];
  token: AzureAdb2CToken;
  user?: Maybe<User>;
  userContextId: Scalars['String']['output'];
};

/** UserDevices Entity */
export type UserDevice = {
  __typename?: 'UserDevice';
  createdAt: Scalars['DateTime']['output'];
  device: Device;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastUsedAt: Scalars['DateTime']['output'];
  signedInAt: Scalars['DateTime']['output'];
  signedOutAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

/** User Test Document Entity */
export type UserFrequentFlyerDocument = {
  __typename?: 'UserFrequentFlyerDocument';
  /** Airline Alliance Status */
  airlineAllianceStatusType?: Maybe<AirlineAllianceStatusType>;
  /** Airline Alliance */
  airlineAllianceType?: Maybe<AirlineAllianceType>;
  /** Status of the airline */
  airlineStatusType?: Maybe<AirlineStatusType>;
  /** Frequent Flyer Airline */
  airlineType?: Maybe<AirlineType>;
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  createdAt: Scalars['DateTime']['output'];
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  /** expiry date as per the document */
  expiresOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Test Document Entity */
export type UserFrequentFlyerDocumentDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Test Document Entity */
export type UserFrequentFlyerDocumentDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserFrequentFlyerDocumentInput = {
  /** Airline Alliance Status */
  airlineAllianceStatusType?: InputMaybe<AirlineAllianceStatusType>;
  /** Airline Alliance */
  airlineAllianceType?: InputMaybe<AirlineAllianceType>;
  /** Status of the airline */
  airlineStatusType?: InputMaybe<AirlineStatusType>;
  /** Frequent Flyer Airline */
  airlineType?: InputMaybe<AirlineType>;
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** expiry date as per the document */
  expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
};

/** User Test Document Entity */
export type UserHealthReport = {
  __typename?: 'UserHealthReport';
  /** City of Test as per the document */
  cityOfTest?: Maybe<Scalars['String']['output']>;
  cityOfTestLocation?: Maybe<Location>;
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** country of test */
  countryOfTest?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Date of Test as per the document */
  dateTimeOfTest?: Maybe<Scalars['DateTime']['output']>;
  /** Description of test */
  description?: Maybe<Scalars['String']['output']>;
  /** Disease that test is for */
  diseaseType?: Maybe<VaccinationTargetDisease>;
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** is test complete */
  isResultPositive?: Maybe<Scalars['Boolean']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** Name of Test Centre as per the document */
  testCenter?: Maybe<Scalars['String']['output']>;
  /** Test Document Type/Method PCR/ Rapid/ Antigen/ PCR-Rapid for COVID 19 */
  testType?: Maybe<HealthTestType>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Test Document Entity */
export type UserHealthReportDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Test Document Entity */
export type UserHealthReportDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserHealthReportInput = {
  /** City of Test as per the document */
  cityOfTest?: InputMaybe<Scalars['String']['input']>;
  cityOfTestLocationInput?: InputMaybe<SuggestedLocationInput>;
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** country of test */
  countryOfTest?: InputMaybe<Scalars['String']['input']>;
  /** Date of Test as per the document */
  dateTimeOfTest?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description of test */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Disease that test is for */
  diseaseType?: InputMaybe<VaccinationTargetDisease>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** is test complete */
  isResultPositive?: InputMaybe<Scalars['Boolean']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Name of Test Centre as per the document */
  testCenter?: InputMaybe<Scalars['String']['input']>;
  /** Test Document Type/Method PCR/ Rapid/ Antigen/ PCR-Rapid for COVID 19 */
  testType?: InputMaybe<HealthTestType>;
};

/** User Identity Document Entity */
export type UserIdentityDocument = {
  __typename?: 'UserIdentityDocument';
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** Country of birth */
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  /** country issuing the document */
  countryOfIssue?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Date of Birth as per the document */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  /** issued date as per the document */
  dateOfIssue?: Maybe<Scalars['DateTime']['output']>;
  /** Description (for  licenses classes initially as text box in front end) */
  description?: Maybe<Scalars['String']['output']>;
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  /** DocumentFeature */
  documentFeature?: Maybe<DocumentFeature>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  /** IdentityDocumentSubType */
  documentSubType?: Maybe<IdentityDocumentSubType>;
  /** IdentityDocumentSubType */
  documentSubTypeMulti?: Maybe<Array<IdentityDocumentSubType>>;
  /** IdentityDocumentType */
  documentType: IdentityDocumentType;
  /** expiry date as per the document */
  expiresOn?: Maybe<Scalars['DateTime']['output']>;
  /** expiry date as per the document - for international driving permit */
  expiresOnInternational?: Maybe<Scalars['DateTime']['output']>;
  /** First names and middle names  as per the document */
  firstNames?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  isInternationalDrivingPermit: Scalars['Boolean']['output'];
  /** Last name as per the document */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Nationality as per the document ISO 3166-1 alpha-3 code */
  nationality?: Maybe<Scalars['String']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** Place of birth as per the document */
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  /** Place of issue of the document */
  placeOfIssue?: Maybe<Scalars['String']['output']>;
  /** sex/gender as per the document */
  sex?: Maybe<Gender>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Identity Document Entity */
export type UserIdentityDocumentDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Identity Document Entity */
export type UserIdentityDocumentDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserIdentityDocumentInput = {
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Country of birth */
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** country issuing the document */
  countryOfIssue?: InputMaybe<Scalars['String']['input']>;
  /** Date of Birth as per the document */
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  /** issued date as per the document */
  dateOfIssue?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description (for  licenses classes initially as text box in front end) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** DocumentFeature */
  documentFeature?: InputMaybe<DocumentFeature>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** IdentityDocumentSubType */
  documentSubType?: InputMaybe<IdentityDocumentSubType>;
  /** IdentityDocumentSubType */
  documentSubTypeMulti?: InputMaybe<Array<IdentityDocumentSubType>>;
  /** IdentityDocumentType */
  documentType: IdentityDocumentType;
  /** expiry date as per the document */
  expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** expiry date as per the document - for international driving permit */
  expiresOnInternational?: InputMaybe<Scalars['DateTime']['input']>;
  /** First names and middle names  as per the document */
  firstNames?: InputMaybe<Scalars['String']['input']>;
  isInternationalDrivingPermit?: Scalars['Boolean']['input'];
  /** Last name as per the document */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Nationality as per the document ISO 3166-1 alpha-3 code */
  nationality?: InputMaybe<Scalars['String']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Place of birth as per the document */
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Place of issue of the document */
  placeOfIssue?: InputMaybe<Scalars['String']['input']>;
  /** sex/gender as per the document */
  sex?: InputMaybe<Gender>;
};

/** User Other Document Entity */
export type UserOtherDocument = {
  __typename?: 'UserOtherDocument';
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** country issuing the document */
  countryOfIssue?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** issued date as per the document */
  dateOfIssue?: Maybe<Scalars['DateTime']['output']>;
  /** Description of the document */
  description?: Maybe<Scalars['String']['output']>;
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  /** expiry date as per the document */
  expiresOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Name of the document */
  name?: Maybe<Scalars['String']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** place/city issuing the document */
  placeOfIssue?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Other Document Entity */
export type UserOtherDocumentDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Other Document Entity */
export type UserOtherDocumentDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserOtherDocumentInput = {
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** country issuing the document */
  countryOfIssue?: InputMaybe<Scalars['String']['input']>;
  /** issued date as per the document */
  dateOfIssue?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description of the document */
  description?: InputMaybe<Scalars['String']['input']>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** expiry date as per the document */
  expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Name of the document */
  name?: InputMaybe<Scalars['String']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** place/city issuing the document */
  placeOfIssue?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfileInput = {
  /** Country of birth */
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth */
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /**  Secondary Email address */
  email2?: InputMaybe<Scalars['String']['input']>;
  /** First names */
  firstNames?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** Preferred Language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Nationalities, ISO 3166 Alpha-3 code */
  nationalities?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Phone Number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Secondary Phone Number */
  phone2?: InputMaybe<Scalars['String']['input']>;
  /** City of birth */
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Male/Female/Other */
  sex?: InputMaybe<Gender>;
  /** User registered address - residence address */
  userRegisteredAddressLocation?: InputMaybe<LocationManualInput>;
};

/** The size of the user profile picture (TINY, COMPRESSED, ORIGINAL) */
export enum UserProfilePictureSize {
  COMPRESSED = 'COMPRESSED',
  ORIGINAL = 'ORIGINAL',
  TINY = 'TINY',
}

/** User Travel Document Entity */
export type UserTravelDocument = {
  __typename?: 'UserTravelDocument';
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** country of birth */
  countryOfBirth?: Maybe<Scalars['String']['output']>;
  /** country issuing the document */
  countryOfIssue?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Date of Birth as per the document */
  dateOfBirth?: Maybe<Scalars['DateTime']['output']>;
  /** issued date as per the document */
  dateOfIssue?: Maybe<Scalars['DateTime']['output']>;
  /** Description (for visa type initially as text box in front end) */
  description?: Maybe<Scalars['String']['output']>;
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  /** Travel Document Sub Type */
  documentSubType?: Maybe<Scalars['String']['output']>;
  /** UserTravelDocumentSubTypeType */
  documentSubTypeMulti?: Maybe<Array<UserTravelDocumentSubTypeType>>;
  /** documentType */
  documentType: TravelDocumentType;
  /** expiry date as per the document */
  expiresOn?: Maybe<Scalars['DateTime']['output']>;
  /** First names and middle names  as per the document */
  firstNames?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last name as per the document */
  lastName?: Maybe<Scalars['String']['output']>;
  mappedIdentityDocument?: Maybe<UserIdentityDocument>;
  /** are multiple entries possible using this document */
  multipleEntriesAllowed?: Maybe<Scalars['Boolean']['output']>;
  /** nationality as on document */
  nationality?: Maybe<Scalars['String']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** Place of birth as per the document */
  placeOfBirth?: Maybe<Scalars['String']['output']>;
  /** Place of issue of the document */
  placeOfIssue?: Maybe<Scalars['String']['output']>;
  /** sex/gender as per the document */
  sex?: Maybe<Gender>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** Country where document is valid */
  validForCountry?: Maybe<Scalars['String']['output']>;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Travel Document Entity */
export type UserTravelDocumentDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Travel Document Entity */
export type UserTravelDocumentDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserTravelDocumentInput = {
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** country of birth */
  countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** country issuing the document */
  countryOfIssue?: InputMaybe<Scalars['String']['input']>;
  /** Date of Birth as per the document */
  dateOfBirth?: InputMaybe<Scalars['DateTime']['input']>;
  /** issued date as per the document */
  dateOfIssue?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description (for visa type initially as text box in front end) */
  description?: InputMaybe<Scalars['String']['input']>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** Travel Document Sub Type */
  documentSubType?: InputMaybe<Scalars['String']['input']>;
  /** UserTravelDocumentSubTypeType */
  documentSubTypeMulti?: InputMaybe<Array<UserTravelDocumentSubTypeType>>;
  /** documentType */
  documentType: TravelDocumentType;
  /** expiry date as per the document */
  expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** First names and middle names  as per the document */
  firstNames?: InputMaybe<Scalars['String']['input']>;
  /** Last name as per the document */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** are multiple entries possible using this document */
  multipleEntriesAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** nationality as on document */
  nationality?: InputMaybe<Scalars['String']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Place of birth as per the document */
  placeOfBirth?: InputMaybe<Scalars['String']['input']>;
  /** Place of issue of the document */
  placeOfIssue?: InputMaybe<Scalars['String']['input']>;
  /** sex/gender as per the document */
  sex?: InputMaybe<Gender>;
  /** Country where document is valid */
  validForCountry?: InputMaybe<Scalars['String']['input']>;
};

/** User travel document sub type */
export enum UserTravelDocumentSubTypeType {
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
  RESIDENCE_PERMIT = 'RESIDENCE_PERMIT',
  TOURIST = 'TOURIST',
}

/** User Vaccination Document Entity */
export type UserVaccinationDocument = {
  __typename?: 'UserVaccinationDocument';
  /** City of Vaccination as per the document */
  cityOfVaccination?: Maybe<Scalars['String']['output']>;
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Back Picture  Metadata */
  compressedDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** compressed Document Front Picture  Metadata */
  compressedDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  /** country of vaccination */
  countryOfVaccination?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Date of Vaccination as per the document */
  dateOfVaccination?: Maybe<Scalars['DateTime']['output']>;
  /** Description of Vaccine */
  description?: Maybe<Scalars['String']['output']>;
  /** Disease that vaccination is for */
  diseaseType?: Maybe<VaccinationTargetDisease>;
  documentBackFromAzure?: Maybe<StoredAsset>;
  documentBackFromAzureCompressed?: Maybe<StoredAsset>;
  documentBackFromAzureOriginal?: Maybe<StoredAsset>;
  /** container name in azure storage */
  documentContainerName?: Maybe<Scalars['String']['output']>;
  documentFrontFromAzure?: Maybe<StoredAsset>;
  documentFrontFromAzureCompressed?: Maybe<StoredAsset>;
  documentFrontFromAzureOriginal?: Maybe<StoredAsset>;
  /** document number as per the document */
  documentNumber?: Maybe<Scalars['String']['output']>;
  /** expiry date as per the document */
  expiresOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  inactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** is vaccination complete */
  isVaccinationComplete?: Maybe<Scalars['Boolean']['output']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Back Picture  Metadata */
  originalDocumentBackMetadata?: Maybe<StoredAssetMetadata>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: Maybe<Scalars['String']['output']>;
  /** original Document Front Picture  Metadata */
  originalDocumentFrontMetadata?: Maybe<StoredAssetMetadata>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  /** Name of Vaccination Centre as per the document */
  vaccinationCenter?: Maybe<Scalars['String']['output']>;
  /** Vaccination Manufacturer (example PFIZER-BIOnTech for COVID19 */
  vaccinationManufacturer?: Maybe<Scalars['String']['output']>;
  /** Vaccination Document Type/Method (example SARS-CoV-mRNA for COVID19 */
  vaccinationType?: Maybe<Scalars['String']['output']>;
  /** verification comments */
  verificationComments?: Maybe<Scalars['String']['output']>;
  /** DocumentVerificationStatus */
  verificationStatus?: Maybe<DocumentVerificationStatus>;
  /** The date of verification of the document */
  verifiedOn?: Maybe<Scalars['DateTime']['output']>;
  /** The user who verified the document */
  verifyingUser?: Maybe<User>;
};

/** User Vaccination Document Entity */
export type UserVaccinationDocumentDocumentBackFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

/** User Vaccination Document Entity */
export type UserVaccinationDocumentDocumentFrontFromAzureArgs = {
  documentSize?: InputMaybe<Scalars['String']['input']>;
};

export type UserVaccinationDocumentInput = {
  /** City of Vaccination as per the document */
  cityOfVaccination?: InputMaybe<Scalars['String']['input']>;
  /** compressed back page blob name in azure storage */
  compressedDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** compressed front page blob name in azure storage */
  compressedDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  compressedDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** country of vaccination */
  countryOfVaccination?: InputMaybe<Scalars['String']['input']>;
  /** Date of Vaccination as per the document */
  dateOfVaccination?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description of Vaccine */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Disease that vaccination is for */
  diseaseType?: InputMaybe<VaccinationTargetDisease>;
  /** container name in azure storage */
  documentContainerName?: InputMaybe<Scalars['String']['input']>;
  /** document number as per the document */
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  /** expiry date as per the document */
  expiresOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** is vaccination complete */
  isVaccinationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** original back page blob name in azure storage */
  originalDocumentBackFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentBackMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** original front page blob name in azure storage */
  originalDocumentFrontFilePath?: InputMaybe<Scalars['String']['input']>;
  originalDocumentFrontMetadata?: InputMaybe<StoredAssetMetadataInput>;
  /** Name of Vaccination Centre as per the document */
  vaccinationCenter?: InputMaybe<Scalars['String']['input']>;
  /** Vaccination Manufacturer (example PFIZER-BIOnTech for COVID19 */
  vaccinationManufacturer?: InputMaybe<Scalars['String']['input']>;
  /** Vaccination Document Type/Method (example SARS-CoV-mRNA for COVID19 */
  vaccinationType?: InputMaybe<Scalars['String']['input']>;
};

/** The disease targeted by Vaccine */
export enum VaccinationTargetDisease {
  CHOLERA = 'CHOLERA',
  COVID19 = 'COVID19',
  DENGUE = 'DENGUE',
  DIPHTHERIA = 'DIPHTHERIA',
  HEPATITIS_A = 'HEPATITIS_A',
  HEPATITIS_B = 'HEPATITIS_B',
  HEPATITIS_E = 'HEPATITIS_E',
  HIB = 'HIB',
  HPV = 'HPV',
  INFLUENZA = 'INFLUENZA',
  JAPANESE_ENCEPHALITIS = 'JAPANESE_ENCEPHALITIS',
  MALARIA = 'MALARIA',
  MEASLES = 'MEASLES',
  MENINGITIS = 'MENINGITIS',
  MUMPS = 'MUMPS',
  PERTUSSIS = 'PERTUSSIS',
  PNEUMOCOCCAL_DISEASE = 'PNEUMOCOCCAL_DISEASE',
  POLIO = 'POLIO',
  RABIES = 'RABIES',
  ROTAVIRUS = 'ROTAVIRUS',
  RUBELLA = 'RUBELLA',
  TB = 'TB',
  TETANUS = 'TETANUS',
  TICK_BORNE_ENCEPHALITIS = 'TICK_BORNE_ENCEPHALITIS',
  TYPHOID = 'TYPHOID',
  VARICELLA = 'VARICELLA',
  YELLOW_FEVER = 'YELLOW_FEVER',
}

/** (COURIER_AVAILABILITY=0, AGENCY_ORGANIZATION_USER_COUNT_BASIC=1, AGENCY_COURIER_AVAILABILITY_MAP=2, AGENCY_AGENCY_JOB_INTERNAL_NOTES=3) */
export enum SubscriptionFeatureType {
  AGENCY_AGENCY_JOB_INTERNAL_NOTES = 'AGENCY_AGENCY_JOB_INTERNAL_NOTES',
  AGENCY_COURIER_AVAILABILITY_MAP = 'AGENCY_COURIER_AVAILABILITY_MAP',
  AGENCY_ORGANIZATION_USER_COUNT_BASIC = 'AGENCY_ORGANIZATION_USER_COUNT_BASIC',
  COURIER_AVAILABILITY = 'COURIER_AVAILABILITY',
}

export type NewUserTravelDocumentFragment = {
  __typename?: 'UserTravelDocument';
  id: string;
  firstNames?: string | null;
  lastName?: string | null;
  placeOfBirth?: string | null;
  sex?: Gender | null;
  dateOfBirth?: DateTime | null;
  documentType: TravelDocumentType;
  documentNumber?: string | null;
  dateOfIssue?: DateTime | null;
  countryOfIssue?: string | null;
  expiresOn?: DateTime | null;
  nationality?: string | null;
  multipleEntriesAllowed?: boolean | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewUserIdentityDocumentFragment = {
  __typename?: 'UserIdentityDocument';
  id: string;
  firstNames?: string | null;
  lastName?: string | null;
  placeOfBirth?: string | null;
  sex?: Gender | null;
  dateOfBirth?: DateTime | null;
  documentType: IdentityDocumentType;
  documentSubType?: IdentityDocumentSubType | null;
  documentSubTypeMulti?: Array<IdentityDocumentSubType> | null;
  documentNumber?: string | null;
  dateOfIssue?: DateTime | null;
  countryOfIssue?: string | null;
  expiresOn?: DateTime | null;
  nationality?: string | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewUserFrequentFlyerDocumentFragment = {
  __typename?: 'UserFrequentFlyerDocument';
  id: string;
  airlineType?: AirlineType | null;
  airlineStatusType?: AirlineStatusType | null;
  airlineAllianceType?: AirlineAllianceType | null;
  airlineAllianceStatusType?: AirlineAllianceStatusType | null;
  documentNumber?: string | null;
  expiresOn?: DateTime | null;
  documentContainerName?: string | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentBackFilePath?: string | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentBackFilePath?: string | null;
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn?: DateTime | null;
  verificationStatus?: DocumentVerificationStatus | null;
  verificationComments?: string | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewUserVaccinationDocumentFragment = {
  __typename?: 'UserVaccinationDocument';
  id: string;
  diseaseType?: VaccinationTargetDisease | null;
  description?: string | null;
  vaccinationType?: string | null;
  vaccinationManufacturer?: string | null;
  dateOfVaccination?: DateTime | null;
  isVaccinationComplete?: boolean | null;
  vaccinationCenter?: string | null;
  cityOfVaccination?: string | null;
  countryOfVaccination?: string | null;
  expiresOn?: DateTime | null;
  documentNumber?: string | null;
  documentContainerName?: string | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentBackFilePath?: string | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentBackFilePath?: string | null;
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn?: DateTime | null;
  verificationStatus?: DocumentVerificationStatus | null;
  verificationComments?: string | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewUserHealthReportFragment = {
  __typename?: 'UserHealthReport';
  id: string;
  diseaseType?: VaccinationTargetDisease | null;
  description?: string | null;
  testType?: HealthTestType | null;
  dateTimeOfTest?: DateTime | null;
  isResultPositive?: boolean | null;
  testCenter?: string | null;
  cityOfTest?: string | null;
  countryOfTest?: string | null;
  documentContainerName?: string | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentBackFilePath?: string | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentBackFilePath?: string | null;
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn?: DateTime | null;
  verificationStatus?: DocumentVerificationStatus | null;
  verificationComments?: string | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewUserBackgroundDocumentFragment = {
  __typename?: 'UserBackgroundDocument';
  id: string;
  name?: string | null;
  issuingInstitution?: string | null;
  countryOfIssue?: string | null;
  dateOfIssue?: DateTime | null;
  expiresOn?: DateTime | null;
  documentContainerName?: string | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentBackFilePath?: string | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentBackFilePath?: string | null;
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn?: DateTime | null;
  verificationStatus?: DocumentVerificationStatus | null;
  verificationComments?: string | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewUserOtherDocumentFragment = {
  __typename?: 'UserOtherDocument';
  id: string;
  name?: string | null;
  description?: string | null;
  countryOfIssue?: string | null;
  placeOfIssue?: string | null;
  dateOfIssue?: DateTime | null;
  expiresOn?: DateTime | null;
  documentContainerName?: string | null;
  originalDocumentFrontFilePath?: string | null;
  originalDocumentBackFilePath?: string | null;
  compressedDocumentFrontFilePath?: string | null;
  compressedDocumentBackFilePath?: string | null;
  updatedAt: DateTime;
  createdAt: DateTime;
  verifiedOn?: DateTime | null;
  verificationStatus?: DocumentVerificationStatus | null;
  verificationComments?: string | null;
  documentFrontFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentFrontFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureCompressed?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
  documentBackFromAzureOriginal?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewChatMessageFragment = {
  __typename?: 'ChatMessage';
  id: string;
  messageType: MessageType;
  createdAt: DateTime;
  message?: string | null;
  sendingUser: {
    __typename?: 'User';
    id: string;
    firstNames: string;
    lastName: string;
    profilePicture?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type NewAgencyOrganizationProfileFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  legalName: string;
  organizationType: OrganizationType;
  description?: string | null;
  email?: string | null;
  legalEntityType?: string | null;
  phone?: string | null;
  vatId?: string | null;
  registrationNumber?: string | null;
  socialProfiles: Array<{
    __typename?: 'SocialProfile';
    platform: string;
    type: string;
    value: string;
  }>;
  headquartersAddressLocation?: {
    __typename?: 'Location';
    id: string;
    placeId?: string | null;
    formattedAddress?: string | null;
  } | null;
  invoicingAddressLocation?: {
    __typename?: 'Location';
    id: string;
    placeId?: string | null;
    formattedAddress?: string | null;
  } | null;
  registrationAddressLocation?: {
    __typename?: 'Location';
    id: string;
    placeId?: string | null;
    formattedAddress?: string | null;
  } | null;
  logo?: {
    __typename?: 'StoredAsset';
    sasUrl: string;
    cacheKey: string;
    cacheTTL?: DateTime | null;
    sasToken: {
      __typename?: 'SasTokenResponse';
      version?: string | null;
      signature?: string | null;
      expiresOn?: string | null;
      permissions?: string | null;
      resource?: string | null;
    };
    metadata: {
      __typename?: 'StoredAssetMetadata';
      fileName?: string | null;
      mimeType?: string | null;
      height?: number | null;
      width?: number | null;
      size?: number | null;
    };
  } | null;
};

export type NewCourierUserProfileFragment = {
  __typename?: 'CourierUserProfile';
  id: string;
  emergencyContactRelationship?: string | null;
  emergencyContactPhone?: string | null;
  emergencyContactName?: string | null;
  emergencyContactEmail?: string | null;
  emergencyContactAddress?: string | null;
  hasAmexCreditCard?: boolean | null;
  hasDinerCreditCard?: boolean | null;
  hasMasterCreditCard?: boolean | null;
  hasVisaCreditCard?: boolean | null;
  hasAmexDebitCard?: boolean | null;
  hasDinerDebitCard?: boolean | null;
  hasMasterDebitCard?: boolean | null;
  hasVisaDebitCard?: boolean | null;
  masterCreditCardLimit?: number | null;
  visaCreditCardLimit?: number | null;
  dinerCreditCardLimit?: number | null;
  amexCreditCardLimit?: number | null;
  onboardExperiences?: Array<string> | null;
  address?: string | null;
  street?: string | null;
  apartmentOrSuite?: string | null;
  city?: string | null;
  zipcode?: string | null;
  country?: string | null;
  languagesKnown?: Array<{
    __typename?: 'TLanguageKnown';
    language: string;
    level: LanguageSkillLevel;
  }> | null;
  socialProfiles: Array<{
    __typename?: 'SocialProfile';
    platform: string;
    type: string;
    value: string;
  }>;
};

export type MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables = Exact<{
  organizationUserId: Scalars['String']['input'];
}>;

export type MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation = {
  __typename?: 'Mutation';
  markAllNewSystemNotificationsAsReadForOrganizationUser: number;
};

export type MarkSystemNotificationAsReadForOrganizationUserMutationVariables = Exact<{
  notificationId: Scalars['String']['input'];
  organizationUserId: Scalars['String']['input'];
}>;

export type MarkSystemNotificationAsReadForOrganizationUserMutation = {
  __typename?: 'Mutation';
  markSystemNotificationAsReadForOrganizationUser: number;
};

export type ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables = Exact<{
  courierOrganizationId: Scalars['String']['input'];
  activationCode: Scalars['String']['input'];
  agencyOrganizationId: Scalars['String']['input'];
}>;

export type ConnectWithAgencyOrganizationAsCourierOrganizationMutation = {
  __typename?: 'Mutation';
  connectWithAgencyOrganizationAsCourierOrganization: {
    __typename?: 'OrganizationOrganization';
    id: string;
    connectionType: OrganizationOrganizationConnectionType;
    createdAt: DateTime;
    updatedAt: DateTime;
    connectingOrganization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      shortName?: string | null;
    };
    connectedOrganization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      shortName?: string | null;
    };
  };
};

export type AddDeviceLogUnauthenticatedMutationVariables = Exact<{
  addDeviceLogInputs: Array<AddDeviceLogInput> | AddDeviceLogInput;
}>;

export type AddDeviceLogUnauthenticatedMutation = {
  __typename?: 'Mutation';
  addDeviceLogUnauthenticated: {
    __typename?: 'AddDeviceLogOutput';
    deviceLogIds: Array<string>;
    lastDeviceLog?: {
      __typename?: 'DeviceLogOutput';
      id: string;
      data?: { __typename?: 'DeviceLogData'; uptime?: number | null } | null;
    } | null;
  };
};

export type AddDeviceLogMutationVariables = Exact<{
  addDeviceLogInputs: Array<AddDeviceLogInput> | AddDeviceLogInput;
}>;

export type AddDeviceLogMutation = {
  __typename?: 'Mutation';
  addDeviceLog: {
    __typename?: 'AddDeviceLogOutput';
    deviceLogIds: Array<string>;
    lastDeviceLog?: {
      __typename?: 'DeviceLogOutput';
      id: string;
      data?: { __typename?: 'DeviceLogData'; uptime?: number | null } | null;
    } | null;
  };
};

export type ActivateAccountMutationVariables = Exact<{
  activationCode: Scalars['String']['input'];
}>;

export type ActivateAccountMutation = {
  __typename?: 'Mutation';
  activateAccount: {
    __typename?: 'UserContext';
    sub: string;
    roleType?: OrganizationUserRoleType | null;
    organizationType?: OrganizationType | null;
    role: string;
    organizationActivatedAt?: DateTime | null;
    deviceId: string;
    userContextId: string;
    token: {
      __typename?: 'AzureADB2CToken';
      iss: string;
      exp: number;
      nbf: number;
      aud: string;
      oid: string;
      sub: string;
      city: string;
      country: string;
      name: string;
      givenName: string;
      familyName: string;
      emails: Array<string>;
      tfp: string;
      azp: string;
      ver: string;
      iat: number;
    };
    user?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    organizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
      usedOrganizationInvitation?: {
        __typename?: 'OrganizationInvitation';
        id: string;
        usedAt?: DateTime | null;
      } | null;
    } | null;
    device: { __typename?: 'Device'; id: string; deviceType: DeviceCategoryType };
    organizationSubscriptionPlan?: {
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      isActive: boolean;
      endTime: DateTime;
      prices?: Array<{
        __typename?: 'SubscriptionPlanPrice';
        currency: string;
        value: string;
      }> | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        planName: { __typename?: 'SubscriptionPlanName'; key: string; defaultValue: string };
        subscriptionFeatures?: Array<{
          __typename?: 'SubscriptionFeature';
          id: string;
          name: string;
          count: number;
          value: SubscriptionFeatureType;
        }> | null;
      };
    } | null;
  };
};

export type FindOrCreateAdHocAvailabilityMutationVariables = Exact<{
  deviceLocationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type FindOrCreateAdHocAvailabilityMutation = {
  __typename?: 'Mutation';
  findOrCreateAdHocAvailability: {
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    lastLocationForDevice?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
  };
};

export type CreateOrUpdateCourierUserContactMutationVariables = Exact<{
  userContactInput: UserContactInput;
  inviteUserContact: Scalars['Boolean']['input'];
}>;

export type CreateOrUpdateCourierUserContactMutation = {
  __typename?: 'Mutation';
  createOrUpdateCourierUserContact: {
    __typename?: 'UserContact';
    id: string;
    expoContactId?: string | null;
    phone: string;
    email?: string | null;
    firstNames: string;
    lastName: string;
  };
};

export type CreateAvailabilityMutationVariables = Exact<{
  suggestedLocationInput: SuggestedLocationInput;
  startTime: Scalars['DateTime']['input'];
  endTime: Scalars['DateTime']['input'];
  timeZoneIdentifier: Scalars['String']['input'];
  timeZoneDestinationOffset: Scalars['Float']['input'];
  timeZoneRawOffset: Scalars['Float']['input'];
}>;

export type CreateAvailabilityMutation = {
  __typename?: 'Mutation';
  createAvailability: {
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    availabilityLocations?: Array<{
      __typename?: 'AvailabilityLocation';
      id: string;
      location: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        formattedMinimalAddress?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
    }> | null;
    user: { __typename?: 'User'; id: string; email: string; firstNames: string; lastName: string };
    lastDeviceLocationForDevice?: {
      __typename?: 'DeviceLocation';
      id: string;
      timestamp: DateTime;
      location?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        formattedMinimalAddress?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
    } | null;
    lastDeviceLocationWithLocationForUser?: {
      __typename?: 'DeviceLocation';
      id: string;
      timestamp: DateTime;
      location?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        formattedMinimalAddress?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
    } | null;
  };
};

export type DeleteAvailabilityMutationVariables = Exact<{
  availabilityId: Scalars['String']['input'];
}>;

export type DeleteAvailabilityMutation = {
  __typename?: 'Mutation';
  deleteAvailability?: { __typename?: 'Availability'; id: string } | null;
};

export type DisableAdHocAvailabilityMutationVariables = Exact<{ [key: string]: never }>;

export type DisableAdHocAvailabilityMutation = {
  __typename?: 'Mutation';
  disableAdHocAvailability: boolean;
};

export type RegisterExistingUserWithOwnedOrganizationMutationVariables = Exact<{
  organizationType: OrganizationType;
}>;

export type RegisterExistingUserWithOwnedOrganizationMutation = {
  __typename?: 'Mutation';
  registerExistingUserWithOwnedOrganization: {
    __typename?: 'OrganizationUser';
    id: string;
    roleType: OrganizationUserRoleType;
    createdAt: DateTime;
    user: { __typename?: 'User'; id: string; firstNames: string; lastName: string; email: string };
    organization: { __typename?: 'Organization'; id: string; organizationType: OrganizationType };
  };
};

export type VoidOrganizationInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type VoidOrganizationInvitationMutation = {
  __typename?: 'Mutation';
  voidOrganizationInvitation: {
    __typename?: 'OrganizationInvitation';
    id: string;
    activationCode?: string | null;
    voidedAt?: DateTime | null;
    updatedAt: DateTime;
  };
};

export type CreateOrganizationInvitationSharingMutationVariables = Exact<{
  organizationInvitationSharingInput: OrganizationInvitationSharingInput;
}>;

export type CreateOrganizationInvitationSharingMutation = {
  __typename?: 'Mutation';
  createOrganizationInvitationSharing: {
    __typename?: 'OrganizationInvitationSharing';
    id: string;
    appName?: string | null;
    platformVersion?: string | null;
    platform: string;
    language?: string | null;
    sharedAt: DateTime;
    createdAt: DateTime;
    updatedAt: DateTime;
    sharedByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    organizationInvitation: {
      __typename?: 'OrganizationInvitation';
      id: string;
      activationCode?: string | null;
    };
  };
};

export type CreateOrganizationInvitationAsPriojetMutationVariables = Exact<{
  reservedComment: Scalars['String']['input'];
  organizationInvitationType: OrganizationInvitationType;
  invitedOrganizationId?: InputMaybe<Scalars['String']['input']>;
  invitingOrganizationId?: InputMaybe<Scalars['String']['input']>;
  roleType?: InputMaybe<OrganizationUserRoleType>;
  invitedOrganizationType?: InputMaybe<OrganizationType>;
}>;

export type CreateOrganizationInvitationAsPriojetMutation = {
  __typename?: 'Mutation';
  createOrganizationInvitationAsPriojet: {
    __typename?: 'OrganizationInvitation';
    id: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    activationCode?: string | null;
    reservedComment?: string | null;
    invitedOrganizationType?: OrganizationType | null;
    roleType?: OrganizationUserRoleType | null;
    voidedAt?: DateTime | null;
    usedAt?: DateTime | null;
    bitrix24Deal?: {
      __typename?: 'OrganizationInvitationBitrix24Deal';
      id: number;
      bitrixDealCategoryId: number;
      bitrixInvitedCompanyId: number;
      bitrixInvitedContactId: number;
    } | null;
    usedForOrganizationOrganization?: {
      __typename?: 'OrganizationOrganization';
      id: string;
      connectionType: OrganizationOrganizationConnectionType;
      connectedOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        organizationType: OrganizationType;
      };
      connectingOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        organizationType: OrganizationType;
      };
    } | null;
    organizationContact?: {
      __typename?: 'OrganizationContact';
      id: string;
      firstNames: string;
      lastName: string;
      phone: string;
      email?: string | null;
      isPriojetContact: boolean;
      isCourierContact: boolean;
      isAgencyContact: boolean;
      isClientContact: boolean;
      isTravelerContact: boolean;
      isServiceProviderContact: boolean;
      isOtherContact: boolean;
      organization: { __typename?: 'Organization'; id: string; name: string };
    } | null;
    invitedUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
    } | null;
    invitedOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    invitingOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      organizationType: OrganizationType;
    } | null;
    usedByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    createdByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    organizationInvitationSharings: Array<{
      __typename?: 'OrganizationInvitationSharing';
      id: string;
      appName?: string | null;
      platformVersion?: string | null;
      platform: string;
      language?: string | null;
      sharedAt: DateTime;
      sharedByUser?: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        email: string;
        lastName: string;
      } | null;
    }>;
  };
};

export type EditOrganizationInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type EditOrganizationInvitationMutation = {
  __typename?: 'Mutation';
  voidOrganizationInvitation: {
    __typename?: 'OrganizationInvitation';
    id: string;
    activationCode?: string | null;
    voidedAt?: DateTime | null;
    updatedAt: DateTime;
  };
};

export type GetOrCreateDeviceMutationVariables = Exact<{
  deviceInput: DeviceInput;
}>;

export type GetOrCreateDeviceMutation = {
  __typename?: 'Mutation';
  getOrCreateDevice: { __typename?: 'Device'; id: string; deviceType: DeviceCategoryType };
};

export type UpdateDeviceMutationVariables = Exact<{
  deviceInput: DeviceInput;
}>;

export type UpdateDeviceMutation = {
  __typename?: 'Mutation';
  updateDevice: { __typename?: 'Device'; id: string; deviceType: DeviceCategoryType };
};

export type CreateDeviceLocationsMutationVariables = Exact<{
  deviceLocationInputs: Array<DeviceLocationInput> | DeviceLocationInput;
}>;

export type CreateDeviceLocationsMutation = {
  __typename?: 'Mutation';
  createDeviceLocations?: {
    __typename?: 'DeviceLocation';
    id: string;
    latitude: number;
    longitude: number;
    altitude?: number | null;
    createdAt: DateTime;
    location?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
    } | null;
  } | null;
};

export type CreateUserIdentityDocumentMutationVariables = Exact<{
  userIdentityDocumentInput: UserIdentityDocumentInput;
}>;

export type CreateUserIdentityDocumentMutation = {
  __typename?: 'Mutation';
  createUserIdentityDocument: {
    __typename?: 'UserIdentityDocument';
    id: string;
    firstNames?: string | null;
    lastName?: string | null;
    placeOfBirth?: string | null;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    documentType: IdentityDocumentType;
    documentSubType?: IdentityDocumentSubType | null;
    documentSubTypeMulti?: Array<IdentityDocumentSubType> | null;
    documentNumber?: string | null;
    dateOfIssue?: DateTime | null;
    countryOfIssue?: string | null;
    expiresOn?: DateTime | null;
    nationality?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UpdateCourierUserProfileMutationVariables = Exact<{
  courierUserProfileInput: CourierUserProfileInput;
}>;

export type UpdateCourierUserProfileMutation = {
  __typename?: 'Mutation';
  updateCourierUserProfile: {
    __typename?: 'CourierUserProfile';
    id: string;
    emergencyContactRelationship?: string | null;
    emergencyContactPhone?: string | null;
    emergencyContactName?: string | null;
    emergencyContactEmail?: string | null;
    emergencyContactAddress?: string | null;
    hasAmexCreditCard?: boolean | null;
    hasDinerCreditCard?: boolean | null;
    hasMasterCreditCard?: boolean | null;
    hasVisaCreditCard?: boolean | null;
    hasAmexDebitCard?: boolean | null;
    hasDinerDebitCard?: boolean | null;
    hasMasterDebitCard?: boolean | null;
    hasVisaDebitCard?: boolean | null;
    amexCreditCardLimit?: number | null;
    dinerCreditCardLimit?: number | null;
    masterCreditCardLimit?: number | null;
    visaCreditCardLimit?: number | null;
    amexCreditCardCurrency?: Currency | null;
    dinerCreditCardCurrency?: Currency | null;
    masterCreditCardCurrency?: Currency | null;
    visaCreditCardCurrency?: Currency | null;
    hasObcExperience?: boolean | null;
    firstObcJobDateAt?: DateTime | null;
    numberOfPastObcJobs?: number | null;
    hasMedicalObcExperience?: boolean | null;
    onboardExperiences?: Array<string> | null;
    taxCountry?: string | null;
    baseAirports?: Array<string> | null;
    languagesKnown?: Array<{
      __typename?: 'TLanguageKnown';
      language: string;
      level: LanguageSkillLevel;
    }> | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
  };
};

export type UpdateUserProfileMutationVariables = Exact<{
  userProfileInput: UserProfileInput;
}>;

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation';
  updateUserProfile: {
    __typename?: 'User';
    id: string;
    firstNames: string;
    lastName: string;
    email: string;
    email2?: string | null;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    placeOfBirth?: string | null;
    countryOfBirth?: string | null;
    language?: string | null;
    nationalities?: Array<string> | null;
    phone?: string | null;
    phone2?: string | null;
    profilePicture?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    userRegisteredAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      addressName?: string | null;
      apartmentOrSuite?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
      state?: string | null;
      country?: string | null;
    } | null;
  };
};

export type DeleteUserProfilePictureMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteUserProfilePictureMutation = {
  __typename?: 'Mutation';
  deleteUserProfilePicture: {
    __typename?: 'User';
    id: string;
    firstNames: string;
    lastName: string;
    email: string;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    placeOfBirth?: string | null;
    countryOfBirth?: string | null;
    language?: string | null;
    nationalities?: Array<string> | null;
  };
};

export type CreateUserTravelDocumentMutationVariables = Exact<{
  userTravelDocumentInput: UserTravelDocumentInput;
  mappedUserIdentityDocumentId: Scalars['String']['input'];
}>;

export type CreateUserTravelDocumentMutation = {
  __typename?: 'Mutation';
  createUserTravelDocument: {
    __typename?: 'UserTravelDocument';
    id: string;
    documentSubType?: string | null;
    documentSubTypeMulti?: Array<UserTravelDocumentSubTypeType> | null;
    firstNames?: string | null;
    lastName?: string | null;
    placeOfBirth?: string | null;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    documentType: TravelDocumentType;
    documentNumber?: string | null;
    dateOfIssue?: DateTime | null;
    countryOfIssue?: string | null;
    validForCountry?: string | null;
    expiresOn?: DateTime | null;
    nationality?: string | null;
    multipleEntriesAllowed?: boolean | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type CreateUserFrequentFlyerDocumentMutationVariables = Exact<{
  userFrequentFlyerDocumentInput: UserFrequentFlyerDocumentInput;
}>;

export type CreateUserFrequentFlyerDocumentMutation = {
  __typename?: 'Mutation';
  createUserFrequentFlyerDocument: {
    __typename?: 'UserFrequentFlyerDocument';
    id: string;
    airlineType?: AirlineType | null;
    airlineStatusType?: AirlineStatusType | null;
    airlineAllianceStatusType?: AirlineAllianceStatusType | null;
    airlineAllianceType?: AirlineAllianceType | null;
    documentNumber?: string | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type CreateUserVaccinationDocumentMutationVariables = Exact<{
  userVaccinationDocumentInput: UserVaccinationDocumentInput;
}>;

export type CreateUserVaccinationDocumentMutation = {
  __typename?: 'Mutation';
  createUserVaccinationDocument: {
    __typename?: 'UserVaccinationDocument';
    id: string;
    diseaseType?: VaccinationTargetDisease | null;
    description?: string | null;
    vaccinationType?: string | null;
    vaccinationManufacturer?: string | null;
    dateOfVaccination?: DateTime | null;
    isVaccinationComplete?: boolean | null;
    vaccinationCenter?: string | null;
    cityOfVaccination?: string | null;
    countryOfVaccination?: string | null;
    expiresOn?: DateTime | null;
    documentNumber?: string | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type CreateUserHealthReportMutationVariables = Exact<{
  userHealthReportInput: UserHealthReportInput;
}>;

export type CreateUserHealthReportMutation = {
  __typename?: 'Mutation';
  createUserHealthReport: {
    __typename?: 'UserHealthReport';
    id: string;
    diseaseType?: VaccinationTargetDisease | null;
    description?: string | null;
    testType?: HealthTestType | null;
    dateTimeOfTest?: DateTime | null;
    isResultPositive?: boolean | null;
    testCenter?: string | null;
    cityOfTest?: string | null;
    countryOfTest?: string | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type CreateUserBackgroundDocumentMutationVariables = Exact<{
  userBackgroundDocumentInput: UserBackgroundDocumentInput;
}>;

export type CreateUserBackgroundDocumentMutation = {
  __typename?: 'Mutation';
  createUserBackgroundDocument: {
    __typename?: 'UserBackgroundDocument';
    id: string;
    name?: string | null;
    issuingInstitution?: string | null;
    countryOfIssue?: string | null;
    dateOfIssue?: DateTime | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type CreateUserOtherDocumentMutationVariables = Exact<{
  userOtherDocumentInput: UserOtherDocumentInput;
}>;

export type CreateUserOtherDocumentMutation = {
  __typename?: 'Mutation';
  createUserOtherDocument: {
    __typename?: 'UserOtherDocument';
    id: string;
    name?: string | null;
    description?: string | null;
    countryOfIssue?: string | null;
    placeOfIssue?: string | null;
    dateOfIssue?: DateTime | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  relationshipType: CourierJobAgencyRelationshipType;
}>;

export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation = {
  __typename?: 'Mutation';
  updateRelationshipTypeForCourierJobAsCourierOrganization: {
    __typename?: 'CourierJob';
    id: string;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    updatedAt: DateTime;
    agencyJob: { __typename?: 'AgencyJob'; id: string };
  };
};

export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  agencyJobId: Scalars['String']['input'];
  relationshipType: CourierJobAgencyRelationshipType;
}>;

export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation = {
  __typename?: 'Mutation';
  updateRelationshipTypeForCourierJobAsAgencyOrganization: {
    __typename?: 'CourierJob';
    id: string;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    updatedAt: DateTime;
    agencyJob: { __typename?: 'AgencyJob'; id: string };
  };
};

export type AddOrDisableCourierJobMutationVariables = Exact<{
  courierOrganizationUserIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  agencyJobId: Scalars['String']['input'];
}>;

export type AddOrDisableCourierJobMutation = {
  __typename?: 'Mutation';
  addOrDisableCourierJob: Array<{
    __typename?: 'AvailableCourierOrganizationUsersWithDistance';
    user: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
      phone?: string | null;
    };
    organizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
      user: { __typename?: 'User'; id: string };
    }>;
    courierJob?: {
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      courierJobStatusType: CourierJobStatusType;
      declineReasonType?: AgencyDeclinedReasonType | null;
      isActive: boolean;
    } | null;
    availabilityLocationsWithDistance: Array<{
      __typename?: 'AvailabilityLocationWithDistance';
      distance: number;
      availabilityLocation: {
        __typename?: 'AvailabilityLocation';
        id: string;
        timestamp?: DateTime | null;
        availability: {
          __typename?: 'Availability';
          id: string;
          isAdHocAvailability: boolean;
          startTime: DateTime;
          endTime?: DateTime | null;
        };
        location: {
          __typename?: 'Location';
          id: string;
          formattedShortAddress?: string | null;
          formattedMinimalAddress?: string | null;
        };
      };
    }>;
  }>;
};

export type AddOrDisableCourierForCourierJobMutationVariables = Exact<{
  courierOrganizationUserIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  agencyJobId: Scalars['String']['input'];
}>;

export type AddOrDisableCourierForCourierJobMutation = {
  __typename?: 'Mutation';
  addOrDisableCourierForCourierJob: Array<{
    __typename?: 'CourierJob';
    id: string;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    declineReasonType?: AgencyDeclinedReasonType | null;
    isActive: boolean;
  }>;
};

export type ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
  agencyJobStatusType: AgencyJobStatusType;
}>;

export type ChangeAgencyJobStatusTypeForAgencyOrganizationMutation = {
  __typename?: 'Mutation';
  changeAgencyJobStatusTypeForAgencyOrganization: {
    __typename?: 'AgencyJob';
    id: string;
    agencyJobStatusType: AgencyJobStatusType;
  };
};

export type CreateOrUpdateJobForAgencyOrganizationMutationVariables = Exact<{
  agencyJobInput: AgencyJobInput;
  clientJobByAgencyInput: ClientJobByAgencyInput;
  jobInput: JobInput;
  courierOrganizationUserIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type CreateOrUpdateJobForAgencyOrganizationMutation = {
  __typename?: 'Mutation';
  createOrUpdateJobForAgencyOrganization: {
    __typename?: 'AgencyJob';
    id: string;
    returnTimeAtStartAirport?: DateTime | null;
    checkInTimeAtStartAirport?: DateTime | null;
    returnTimeAtEndAirport?: DateTime | null;
    checkInTimeAtEndAirport?: DateTime | null;
    externalJobReference: string;
    internalNotes?: string | null;
    comment?: string | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    kickOffFee?: number | null;
    hotelBudget?: number | null;
    currency: Currency;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isDrivingLicenseRequiredForJob: boolean;
    isCarRequiredForJob: boolean;
    jobDetails?: string | null;
    numOfCouriersRequired: number;
    hasIncident: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    startAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
    } | null;
    endAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
    } | null;
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      numOfItems: number;
      weightOfItems?: number | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
    };
    clientJob: {
      __typename?: 'ClientJob';
      id: string;
      organizationName: string;
      organizationLegalName?: string | null;
      organizationPhone?: string | null;
      organizationEmail?: string | null;
      externalJobReference?: string | null;
      comment?: string | null;
      fixedRate?: number | null;
      currency: Currency;
      hasIncident: boolean;
    };
    courierJobs?: Array<{
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      courierJobStatusType: CourierJobStatusType;
      declineReasonType?: AgencyDeclinedReasonType | null;
      fixedRate?: number | null;
      dailyRate?: number | null;
      hotelBudget?: number | null;
      kickOffFee?: number | null;
      isAccommodationBookedByCourier: boolean;
      isAccommodationPaidByCourier: boolean;
      hasIncident: boolean;
      isAdditionalCostPaidByCourier: boolean;
      additionalCostInformation?: string | null;
      responsibleCourierOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        createdAt: DateTime;
        organizationType: OrganizationType;
      };
      responsibleCourierUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        email: string;
        phone?: string | null;
        lastName: string;
        sex?: Gender | null;
        dateOfBirth?: DateTime | null;
        placeOfBirth?: string | null;
        countryOfBirth?: string | null;
        language?: string | null;
        nationalities?: Array<string> | null;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    }> | null;
  };
};

export type CreateChatMessageMutationVariables = Exact<{
  createChatMessageInput: CreateChatMessageInput;
}>;

export type CreateChatMessageMutation = {
  __typename?: 'Mutation';
  createChatMessage: {
    __typename?: 'ChatMessageCreateEdge';
    cursor: string;
    node: {
      __typename?: 'ChatMessage';
      id: string;
      message?: string | null;
      messageType: MessageType;
      createdAt: DateTime;
      updatedAt: DateTime;
      chatFile?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      sendingUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    };
  };
};

export type UpdateChatMessageMutationVariables = Exact<{
  updateChatMessageInput: UpdateChatMessageInput;
}>;

export type UpdateChatMessageMutation = {
  __typename?: 'Mutation';
  updateChatMessage: {
    __typename?: 'ChatMessageCreateEdge';
    cursor: string;
    node: {
      __typename?: 'ChatMessage';
      id: string;
      message?: string | null;
      messageType: MessageType;
      createdAt: DateTime;
      updatedAt: DateTime;
      chatFile?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      sendingUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    };
  };
};

export type DeleteChatMessageMutationVariables = Exact<{
  deleteChatMessageInput: DeleteChatMessageInput;
}>;

export type DeleteChatMessageMutation = {
  __typename?: 'Mutation';
  deleteChatMessage: { __typename?: 'ChatMessage'; id: string };
};

export type UpdateAgencyOrganizationProfileMutationVariables = Exact<{
  agencyOrganizationProfileInput: AgencyOrganizationProfileInput;
}>;

export type UpdateAgencyOrganizationProfileMutation = {
  __typename?: 'Mutation';
  updateAgencyOrganizationProfile: {
    __typename?: 'Organization';
    id: string;
    name: string;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    invoiceEmail?: string | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
    headquartersAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    invoicingAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    registrationAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    logo?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UpdateAgencyOrganizationVatIdMutationVariables = Exact<{
  agencyOrganizationVatIdInput: AgencyOrganizationVatIdInput;
}>;

export type UpdateAgencyOrganizationVatIdMutation = {
  __typename?: 'Mutation';
  updateAgencyOrganizationVatId: {
    __typename?: 'Organization';
    id: string;
    name: string;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
  };
};

export type DeleteOrganizationLogoMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;

export type DeleteOrganizationLogoMutation = {
  __typename?: 'Mutation';
  deleteOrganizationLogo: {
    __typename?: 'Organization';
    id: string;
    name: string;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    logo?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UpdateCourierOrganizationProfileMutationVariables = Exact<{
  courierOrganizationProfileInput: CourierOrganizationProfileInput;
}>;

export type UpdateCourierOrganizationProfileMutation = {
  __typename?: 'Mutation';
  updateCourierOrganizationProfile: {
    __typename?: 'Organization';
    id: string;
    name: string;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
    headquartersAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    invoicingAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    registrationAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    logo?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type DeleteUserBackgroundDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DeleteUserBackgroundDocumentMutation = {
  __typename?: 'Mutation';
  deleteUserBackgroundDocument: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type DeleteUserFrequentFlyerDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DeleteUserFrequentFlyerDocumentMutation = {
  __typename?: 'Mutation';
  deleteUserFrequentFlyerDocument: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type DeleteUserHealthReportMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DeleteUserHealthReportMutation = {
  __typename?: 'Mutation';
  deleteUserHealthReport: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type DeleteUserIdentityDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DeleteUserIdentityDocumentMutation = {
  __typename?: 'Mutation';
  deleteUserIdentityDocument: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type UserOtherDocumentDeleteMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserOtherDocumentDeleteMutation = {
  __typename?: 'Mutation';
  deleteUserOtherDocument: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type DeleteUserTravelDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DeleteUserTravelDocumentMutation = {
  __typename?: 'Mutation';
  deleteUserTravelDocument: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type DeleteUserVaccinationDocumentMutationVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DeleteUserVaccinationDocumentMutation = {
  __typename?: 'Mutation';
  deleteUserVaccinationDocument: { __typename?: 'EntityDeletionReturnType'; id: string };
};

export type DeleteAgencyAccountMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type DeleteAgencyAccountMutation = { __typename?: 'Mutation'; deleteAgencyAccount: boolean };

export type DeleteCourierAccountMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type DeleteCourierAccountMutation = {
  __typename?: 'Mutation';
  deleteCourierAccount: boolean;
};

export type UserDeleteAccountMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserDeleteAccountMutation = { __typename?: 'Mutation'; userDeleteAccount: boolean };

export type RestoreAgencyAccountMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type RestoreAgencyAccountMutation = {
  __typename?: 'Mutation';
  restoreAgencyAccount: { __typename?: 'User'; id: string };
};

export type RestoreCourierAccountMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type RestoreCourierAccountMutation = {
  __typename?: 'Mutation';
  restoreCourierAccount: { __typename?: 'User'; id: string };
};

export type UserAccountRestoreMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserAccountRestoreMutation = {
  __typename?: 'Mutation';
  userAccountRestore: { __typename?: 'User'; id: string };
};

export type OrganizationUserUpdateProfileMutationVariables = Exact<{
  organizationUserUpdateProfileInput: OrganizationUserUpdateProfileInput;
}>;

export type OrganizationUserUpdateProfileMutation = {
  __typename?: 'Mutation';
  organizationUserUpdateProfile: {
    __typename?: 'OrganizationUser';
    id: string;
    roleType: OrganizationUserRoleType;
    user: {
      __typename?: 'User';
      firstNames: string;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      email: string;
      phone?: string | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
      } | null;
    };
    organization: { __typename?: 'Organization'; id: string };
  };
};

export type CreateOrUpdateAgencyEmployeeUserContactMutationVariables = Exact<{
  userContactInput: UserContactInput;
  inviteUserContact: Scalars['Boolean']['input'];
  organizationUserRole: OrganizationUserRoleType;
}>;

export type CreateOrUpdateAgencyEmployeeUserContactMutation = {
  __typename?: 'Mutation';
  createOrUpdateAgencyEmployeeUserContact: {
    __typename?: 'UserContact';
    id: string;
    expoContactId?: string | null;
    phone: string;
    email?: string | null;
    firstNames: string;
    lastName: string;
  };
};

export type OrganizationInvitationDeleteMutationVariables = Exact<{
  organizationInvitationId: Scalars['String']['input'];
}>;

export type OrganizationInvitationDeleteMutation = {
  __typename?: 'Mutation';
  organizationInvitationDelete: { __typename?: 'OrganizationInvitation'; id: string };
};

export type OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables = Exact<{
  organizationUserId: Scalars['String']['input'];
}>;

export type OrganizationUserRemoveOrganizationUserFromOrganizationMutation = {
  __typename?: 'Mutation';
  organizationUserRemoveOrganizationUserFromOrganization: boolean;
};

export type OrganizationSubscriptionPlanCancelMutationVariables = Exact<{
  data: OrganizationSubscriptionPlanSingleInput;
}>;

export type OrganizationSubscriptionPlanCancelMutation = {
  __typename?: 'Mutation';
  organizationSubscriptionPlanCancel: { __typename?: 'OrganizationSubscriptionPlan'; id: string };
};

export type AirportLocationUpdateTimezoneMutationVariables = Exact<{
  data: AirportLocationUpdateTimezoneInput;
}>;

export type AirportLocationUpdateTimezoneMutation = {
  __typename?: 'Mutation';
  airportLocationUpdateTimezone: {
    __typename?: 'Location';
    id: string;
    placeId?: string | null;
    country?: string | null;
    iataCode?: string | null;
    formattedAddress?: string | null;
    city?: string | null;
  };
};

export type OrganizationCourierCategoriesUpdateMutationVariables = Exact<{
  data: OrganizationCourierCategoriesUpdateInput;
}>;

export type OrganizationCourierCategoriesUpdateMutation = {
  __typename?: 'Mutation';
  organizationCourierCategoriesUpdate: Array<{
    __typename?: 'OrganizationCourierCategory';
    id: string;
    order: number;
    label: string;
  }>;
};

export type AgencyCourierUserProfileCreateOrUpdateMutationVariables = Exact<{
  data: AgencyCourierUserProfileCreateOrUpdateInput;
}>;

export type AgencyCourierUserProfileCreateOrUpdateMutation = {
  __typename?: 'Mutation';
  agencyCourierUserProfileCreateOrUpdate: {
    __typename?: 'AgencyCourierUserProfile';
    id: string;
    internalComment?: string | null;
    markedForAttention: boolean;
  };
};

export type AgencyJobUpdateInternalNotesMutationVariables = Exact<{
  agencyJobId: Scalars['ID']['input'];
  internalNotes: Scalars['String']['input'];
}>;

export type AgencyJobUpdateInternalNotesMutation = {
  __typename?: 'Mutation';
  agencyJobUpdateInternalNotes: {
    __typename?: 'AgencyJob';
    id: string;
    returnTimeAtStartAirport?: DateTime | null;
    checkInTimeAtStartAirport?: DateTime | null;
    returnTimeAtEndAirport?: DateTime | null;
    checkInTimeAtEndAirport?: DateTime | null;
    externalJobReference: string;
    comment?: string | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    kickOffFee?: number | null;
    hotelBudget?: number | null;
    currency: Currency;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    isDrivingLicenseRequiredForJob: boolean;
    isCarRequiredForJob: boolean;
    jobDetails?: string | null;
    numOfCouriersRequired: number;
    hasIncident: boolean;
    agencyJobStatusType: AgencyJobStatusType;
    internalNotes?: string | null;
    startAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      formattedAirportAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
    } | null;
    endAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      formattedAirportAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
    } | null;
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      numOfItems: number;
      weightOfItems?: number | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
    };
    clientJob: {
      __typename?: 'ClientJob';
      id: string;
      organizationName: string;
      organizationLegalName?: string | null;
      organizationPhone?: string | null;
      organizationEmail?: string | null;
      externalJobReference?: string | null;
      comment?: string | null;
      fixedRate?: number | null;
      currency: Currency;
      hasIncident: boolean;
    };
    courierJobs?: Array<{
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      courierJobStatusType: CourierJobStatusType;
      declineReasonType?: AgencyDeclinedReasonType | null;
      fixedRate?: number | null;
      dailyRate?: number | null;
      hotelBudget?: number | null;
      kickOffFee?: number | null;
      isAccommodationBookedByCourier: boolean;
      isAccommodationPaidByCourier: boolean;
      isAdditionalCostPaidByCourier: boolean;
      additionalCostInformation?: string | null;
      hasIncident: boolean;
      responsibleCourierOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        createdAt: DateTime;
        organizationType: OrganizationType;
      };
      responsibleCourierUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        email: string;
        phone?: string | null;
        lastName: string;
        sex?: Gender | null;
        dateOfBirth?: DateTime | null;
        placeOfBirth?: string | null;
        countryOfBirth?: string | null;
        language?: string | null;
        nationalities?: Array<string> | null;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
      courierJobAgencyJobChat?: { __typename?: 'Chat'; id: string } | null;
    }> | null;
  };
};

export type NotificationSettingUpdateMutationVariables = Exact<{
  data: NotificationSettingUpdateInput;
}>;

export type NotificationSettingUpdateMutation = {
  __typename?: 'Mutation';
  notificationSettingUpdate: {
    __typename?: 'NotificationSetting';
    id: string;
    emailConnection: boolean;
    emailJob: boolean;
    pushAvailability: boolean;
    pushChat: boolean;
    pushConnection: boolean;
    pushJob: boolean;
    systemAvailability: boolean;
    systemChat: boolean;
    systemConnection: boolean;
    systemJob: boolean;
  };
};

export type AuthSignOutUserContextMutationVariables = Exact<{
  data: AuthSignOutUserContextInput;
}>;

export type AuthSignOutUserContextMutation = {
  __typename?: 'Mutation';
  authSignOutUserContext: boolean;
};

export type AgencyJobPlanningForAgencyJobUpdateMutationVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
  agencyJobPlanningId: Scalars['String']['input'];
  agencyJobPlanningForAgencyJobUpdateInput: AgencyJobPlanningForAgencyJobUpdateInput;
}>;

export type AgencyJobPlanningForAgencyJobUpdateMutation = {
  __typename?: 'Mutation';
  agencyJobPlanningForAgencyJobUpdate: {
    __typename?: 'AgencyJobPlanning';
    id: string;
    agencyJobPlanningItems: Array<{
      __typename?: 'AgencyJobPlanningItem';
      id: string;
      active: boolean;
      actualDateTime?: DateTime | null;
      actualDateTimeSetAt?: DateTime | null;
      createdAt: DateTime;
      formType: AgencyJobPlanningFormType;
      inactivatedAt?: DateTime | null;
      label: string;
      name: string;
      order: number;
      targetDateTime?: DateTime | null;
      files: Array<{
        __typename?: 'AgencyJobPlanningFile';
        id: string;
        storedAsset?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      }>;
      location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
    }>;
    agencyJobPlanningLegs: Array<{
      __typename?: 'AgencyJobPlanningLeg';
      id: string;
      active: boolean;
      departureDate?: DateTime | null;
      order: number;
      flightCarrierDesignation?: string | null;
      flightNumber?: string | null;
      flightTickets: Array<{
        __typename?: 'AgencyJobPlanningFile';
        id: string;
        storedAsset?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      }>;
      agencyJobPlanningItems: Array<{
        __typename?: 'AgencyJobPlanningItem';
        id: string;
        active: boolean;
        actualDateTime?: DateTime | null;
        actualDateTimeSetAt?: DateTime | null;
        createdAt: DateTime;
        formType: AgencyJobPlanningFormType;
        inactivatedAt?: DateTime | null;
        label: string;
        name: string;
        order: number;
        targetDateTime?: DateTime | null;
        files: Array<{
          __typename?: 'AgencyJobPlanningFile';
          id: string;
          storedAsset?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        }>;
        location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
      }>;
    }>;
  };
};

export type AgencyJobPlanningItemUpdateMutationVariables = Exact<{
  agencyJobPlanningId: Scalars['String']['input'];
  agencyJobPlanningItemUpdateInput: AgencyJobPlanningItemUpdateInput;
}>;

export type AgencyJobPlanningItemUpdateMutation = {
  __typename?: 'Mutation';
  agencyJobPlanningItemUpdate: {
    __typename?: 'AgencyJobPlanningItem';
    id: string;
    active: boolean;
    actualDateTime?: DateTime | null;
    actualDateTimeSetAt?: DateTime | null;
    createdAt: DateTime;
    formType: AgencyJobPlanningFormType;
    inactivatedAt?: DateTime | null;
    label: string;
    name: string;
    order: number;
    targetDateTime?: DateTime | null;
    updatedAt: DateTime;
    files: Array<{
      __typename?: 'AgencyJobPlanningFile';
      id: string;
      storedAsset?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    }>;
    location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
  };
};

export type AgencyJobPlanningItemResetMutationVariables = Exact<{
  agencyJobPlanningId: Scalars['String']['input'];
  agencyJobPlanningItemId: Scalars['String']['input'];
}>;

export type AgencyJobPlanningItemResetMutation = {
  __typename?: 'Mutation';
  agencyJobPlanningItemReset: {
    __typename?: 'AgencyJobPlanningItem';
    id: string;
    active: boolean;
    actualDateTime?: DateTime | null;
    actualDateTimeSetAt?: DateTime | null;
    createdAt: DateTime;
    formType: AgencyJobPlanningFormType;
    inactivatedAt?: DateTime | null;
    label: string;
    name: string;
    order: number;
    targetDateTime?: DateTime | null;
    updatedAt: DateTime;
    files: Array<{
      __typename?: 'AgencyJobPlanningFile';
      id: string;
      storedAsset?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    }>;
    location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
  };
};

export type CreateChatMessageForAgencyMutationVariables = Exact<{
  createChatMessageInput: CreateChatMessageInput;
}>;

export type CreateChatMessageForAgencyMutation = {
  __typename?: 'Mutation';
  createChatMessage: {
    __typename?: 'ChatMessageCreateEdge';
    cursor: string;
    node: {
      __typename?: 'ChatMessage';
      id: string;
      message?: string | null;
      messageType: MessageType;
      createdAt: DateTime;
      updatedAt: DateTime;
      agencyJobPlanningItemHistoryLog?: {
        __typename?: 'AgencyJobPlanningItemHistoryLog';
        id: string;
        active: boolean;
        actualDateTime?: DateTime | null;
        label: string;
        targetDateTime?: DateTime | null;
        note?: string | null;
        agencyJobPlanningLeg?: {
          __typename?: 'AgencyJobPlanningLeg';
          id: string;
          flightCarrierDesignation?: string | null;
          flightNumber?: string | null;
          order: number;
        } | null;
        files: Array<{
          __typename?: 'AgencyJobPlanningFile';
          id: string;
          storedAsset?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            cacheKey: string;
            cacheTTL?: DateTime | null;
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        }>;
        location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
      } | null;
      chatFile?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      sendingUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    };
  };
};

export type UpdateChatMessageForAgencyMutationVariables = Exact<{
  updateChatMessageInput: UpdateChatMessageInput;
}>;

export type UpdateChatMessageForAgencyMutation = {
  __typename?: 'Mutation';
  updateChatMessage: {
    __typename?: 'ChatMessageCreateEdge';
    cursor: string;
    node: {
      __typename?: 'ChatMessage';
      id: string;
      message?: string | null;
      messageType: MessageType;
      createdAt: DateTime;
      updatedAt: DateTime;
      agencyJobPlanningItemHistoryLog?: {
        __typename?: 'AgencyJobPlanningItemHistoryLog';
        id: string;
        active: boolean;
        actualDateTime?: DateTime | null;
        label: string;
        targetDateTime?: DateTime | null;
        note?: string | null;
        agencyJobPlanningLeg?: {
          __typename?: 'AgencyJobPlanningLeg';
          id: string;
          flightCarrierDesignation?: string | null;
          flightNumber?: string | null;
          order: number;
        } | null;
        files: Array<{
          __typename?: 'AgencyJobPlanningFile';
          id: string;
          storedAsset?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            cacheKey: string;
            cacheTTL?: DateTime | null;
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        }>;
        location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
      } | null;
      chatFile?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      sendingUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    };
  };
};

export type ConnectedAgencyOrganizationForCourierOrganizationQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
  courierOrganizationId: Scalars['String']['input'];
}>;

export type ConnectedAgencyOrganizationForCourierOrganizationQuery = {
  __typename?: 'Query';
  connectedAgencyOrganizationForCourierOrganization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    legalName: string;
    shortName?: string | null;
    organizationType: OrganizationType;
    phone?: string | null;
    email?: string | null;
    description?: string | null;
    invoiceEmail?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    headquartersAddressLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      addressName?: string | null;
      apartmentOrSuite?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
      state?: string | null;
      country?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    invoicingAddressLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      addressName?: string | null;
      apartmentOrSuite?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
      state?: string | null;
      country?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    registrationAddressLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
    logo?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
}>;

export type ConnectedCourierOrganizationsForAgencyOrganizationQuery = {
  __typename?: 'Query';
  connectedCourierOrganizationsForAgencyOrganization: Array<{
    __typename?: 'ConnectedOrganization';
    id: string;
    connectionType: OrganizationOrganizationConnectionType;
    createdAt: DateTime;
    updatedAt: DateTime;
    usedOrganizationInvitation: {
      __typename?: 'OrganizationInvitation';
      id: string;
      usedAt?: DateTime | null;
      usedByUser?: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
      } | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      shortName?: string | null;
      organizationType: OrganizationType;
      description?: string | null;
      phone?: string | null;
      email?: string | null;
      organizationUserOwners: Array<{
        __typename?: 'OrganizationUser';
        id: string;
        roleType: OrganizationUserRoleType;
        user: {
          __typename?: 'User';
          id: string;
          firstNames: string;
          lastName: string;
          email: string;
          phone?: string | null;
        };
      }>;
    };
  }>;
};

export type ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables = Exact<{
  courierOrganizationId: Scalars['String']['input'];
}>;

export type ConnectedAgencyOrganizationsForCourierOrganizationQuery = {
  __typename?: 'Query';
  connectedAgencyOrganizationsForCourierOrganization: Array<{
    __typename?: 'ConnectedOrganization';
    id: string;
    connectionType: OrganizationOrganizationConnectionType;
    createdAt: DateTime;
    updatedAt: DateTime;
    usedOrganizationInvitation: {
      __typename?: 'OrganizationInvitation';
      id: string;
      usedAt?: DateTime | null;
      usedByUser?: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
      } | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      shortName?: string | null;
      organizationType: OrganizationType;
      description?: string | null;
      phone?: string | null;
      email?: string | null;
      organizationUserOwners: Array<{
        __typename?: 'OrganizationUser';
        id: string;
        roleType: OrganizationUserRoleType;
        user: {
          __typename?: 'User';
          id: string;
          firstNames: string;
          lastName: string;
          email: string;
          phone?: string | null;
        };
      }>;
    };
  }>;
};

export type OrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    legalName: string;
    createdAt: DateTime;
    organizationType: OrganizationType;
    usedOrganizationInvitation?: {
      __typename?: 'OrganizationInvitation';
      id: string;
      usedAt?: DateTime | null;
      organizationInvitationType: OrganizationInvitationType;
    } | null;
    organizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      createdAt: DateTime;
      user: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
        createdAt: DateTime;
      };
    }>;
  }>;
};

export type OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  activationCode: Scalars['String']['input'];
}>;

export type OrganizationInvitationByActivationCodeForCourierOrganizationQuery = {
  __typename?: 'Query';
  organizationInvitationByActivationCodeForCourierOrganization: {
    __typename?: 'OrganizationInvitation';
    id: string;
    activationCode?: string | null;
    organizationInvitationType: OrganizationInvitationType;
    invitingOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      shortName?: string | null;
      phone?: string | null;
      email?: string | null;
    } | null;
  };
};

export type AvailableCourierUsersByDistanceForAgencyJobQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type AvailableCourierUsersByDistanceForAgencyJobQuery = {
  __typename?: 'Query';
  availableCourierUsersByDistanceForAgencyJob: Array<{
    __typename?: 'AvailableCourierOrganizationUsersWithDistance';
    user: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
      phone?: string | null;
      agencyCourierUserProfile?: {
        __typename?: 'AgencyCourierUserProfile';
        internalComment?: string | null;
        markedForAttention: boolean;
        organizationCourierCategories?: Array<{
          __typename?: 'OrganizationCourierCategory';
          id: string;
          order: number;
          label: string;
        }> | null;
      } | null;
    };
    organizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
      user: { __typename?: 'User'; id: string };
    }>;
    courierJob?: {
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      declineReasonType?: AgencyDeclinedReasonType | null;
      isActive: boolean;
    } | null;
    availabilityLocationsWithDistance: Array<{
      __typename?: 'AvailabilityLocationWithDistance';
      distance: number;
      availabilityLocation: {
        __typename?: 'AvailabilityLocation';
        id: string;
        timestamp?: DateTime | null;
        availability: {
          __typename?: 'Availability';
          id: string;
          isAdHocAvailability: boolean;
          startTime: DateTime;
          endTime?: DateTime | null;
          timeZoneIdentifier?: string | null;
        };
        location: {
          __typename?: 'Location';
          id: string;
          formattedShortAddress?: string | null;
          formattedMinimalAddress?: string | null;
          formattedAddress?: string | null;
          timeZoneIdentifier?: string | null;
        };
      };
    }>;
  }>;
};

export type AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllActiveAndUpcomingAvailabilitiesForPriojetQuery = {
  __typename?: 'Query';
  allActiveAndUpcomingAvailabilitiesForPriojet: Array<{
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    createdAt: DateTime;
    lastLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      timeZoneIdentifier?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      firstNames: string;
      lastName: string;
      courierUserProfile?: {
        __typename?: 'CourierUserProfile';
        id: string;
        baseAirports?: Array<string> | null;
      } | null;
    };
    lastDeviceLocationWithLocationForUser?: {
      __typename?: 'DeviceLocation';
      id: string;
      timestamp: DateTime;
      location?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        formattedMinimalAddress?: string | null;
        timeZoneIdentifier?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
    } | null;
  }>;
};

export type ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
}>;

export type ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery = {
  __typename?: 'Query';
  activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations: Array<{
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    createdAt: DateTime;
    lastLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      timeZoneIdentifier?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      firstNames: string;
      lastName: string;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      courierUserProfile?: {
        __typename?: 'CourierUserProfile';
        id: string;
        baseAirports?: Array<string> | null;
      } | null;
    };
    lastDeviceLocationWithLocationForUser?: {
      __typename?: 'DeviceLocation';
      id: string;
      timestamp: DateTime;
      location?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        timeZoneIdentifier?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
    } | null;
  }>;
};

export type AvailabilitiesForUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type AvailabilitiesForUserQuery = {
  __typename?: 'Query';
  availabilitiesForUser: Array<{
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    createdAt: DateTime;
    lastLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      timeZoneIdentifier?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      firstNames: string;
      lastName: string;
      courierUserProfile?: {
        __typename?: 'CourierUserProfile';
        id: string;
        baseAirports?: Array<string> | null;
      } | null;
    };
    lastDeviceLocationWithLocationForUser?: {
      __typename?: 'DeviceLocation';
      id: string;
      timestamp: DateTime;
      location?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        formattedMinimalAddress?: string | null;
        timeZoneIdentifier?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
    } | null;
  }>;
};

export type ActiveAvailabilitiesForUserQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type ActiveAvailabilitiesForUserQuery = {
  __typename?: 'Query';
  activeAvailabilitiesForUser: Array<{
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    createdAt: DateTime;
    lastLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      timeZoneIdentifier?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    user: { __typename?: 'User'; id: string; email: string; firstNames: string; lastName: string };
    lastDeviceLocationWithLocationForUser?: {
      __typename?: 'DeviceLocation';
      id: string;
      timestamp: DateTime;
      location?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        formattedMinimalAddress?: string | null;
        timeZoneIdentifier?: string | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
    } | null;
  }>;
};

export type UserIdentityDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserIdentityDocumentsQuery = {
  __typename?: 'Query';
  userIdentityDocuments: Array<{
    __typename?: 'UserIdentityDocument';
    id: string;
    firstNames?: string | null;
    lastName?: string | null;
    placeOfBirth?: string | null;
    sex?: Gender | null;
    countryOfBirth?: string | null;
    dateOfBirth?: DateTime | null;
    documentType: IdentityDocumentType;
    description?: string | null;
    documentFeature?: DocumentFeature | null;
    documentSubType?: IdentityDocumentSubType | null;
    documentSubTypeMulti?: Array<IdentityDocumentSubType> | null;
    documentNumber?: string | null;
    dateOfIssue?: DateTime | null;
    placeOfIssue?: string | null;
    countryOfIssue?: string | null;
    expiresOn?: DateTime | null;
    isInternationalDrivingPermit: boolean;
    expiresOnInternational?: DateTime | null;
    nationality?: string | null;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  }>;
};

export type UserIdentityDocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserIdentityDocumentQuery = {
  __typename?: 'Query';
  userIdentityDocument: {
    __typename?: 'UserIdentityDocument';
    id: string;
    firstNames?: string | null;
    lastName?: string | null;
    countryOfBirth?: string | null;
    placeOfBirth?: string | null;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    documentType: IdentityDocumentType;
    documentFeature?: DocumentFeature | null;
    description?: string | null;
    documentSubType?: IdentityDocumentSubType | null;
    documentSubTypeMulti?: Array<IdentityDocumentSubType> | null;
    documentNumber?: string | null;
    dateOfIssue?: DateTime | null;
    placeOfIssue?: string | null;
    countryOfIssue?: string | null;
    expiresOn?: DateTime | null;
    isInternationalDrivingPermit: boolean;
    expiresOnInternational?: DateTime | null;
    nationality?: string | null;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UserTravelDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserTravelDocumentsQuery = {
  __typename?: 'Query';
  userTravelDocuments: Array<{
    __typename?: 'UserTravelDocument';
    id: string;
    firstNames?: string | null;
    lastName?: string | null;
    placeOfBirth?: string | null;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    documentType: TravelDocumentType;
    documentSubType?: string | null;
    description?: string | null;
    documentSubTypeMulti?: Array<UserTravelDocumentSubTypeType> | null;
    documentNumber?: string | null;
    dateOfIssue?: DateTime | null;
    placeOfIssue?: string | null;
    countryOfIssue?: string | null;
    validForCountry?: string | null;
    expiresOn?: DateTime | null;
    nationality?: string | null;
    countryOfBirth?: string | null;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    multipleEntriesAllowed?: boolean | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    mappedIdentityDocument?: {
      __typename?: 'UserIdentityDocument';
      id: string;
      nationality?: string | null;
      firstNames?: string | null;
      lastName?: string | null;
      placeOfBirth?: string | null;
      sex?: Gender | null;
      countryOfBirth?: string | null;
      dateOfBirth?: DateTime | null;
      documentNumber?: string | null;
    } | null;
  }>;
};

export type UserTravelDocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserTravelDocumentQuery = {
  __typename?: 'Query';
  userTravelDocument: {
    __typename?: 'UserTravelDocument';
    id: string;
    firstNames?: string | null;
    lastName?: string | null;
    placeOfBirth?: string | null;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    description?: string | null;
    documentType: TravelDocumentType;
    documentSubType?: string | null;
    documentSubTypeMulti?: Array<UserTravelDocumentSubTypeType> | null;
    documentNumber?: string | null;
    dateOfIssue?: DateTime | null;
    placeOfIssue?: string | null;
    countryOfIssue?: string | null;
    validForCountry?: string | null;
    expiresOn?: DateTime | null;
    nationality?: string | null;
    countryOfBirth?: string | null;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    multipleEntriesAllowed?: boolean | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    mappedIdentityDocument?: {
      __typename?: 'UserIdentityDocument';
      id: string;
      documentNumber?: string | null;
      firstNames?: string | null;
      lastName?: string | null;
      placeOfBirth?: string | null;
      sex?: Gender | null;
      countryOfBirth?: string | null;
      dateOfBirth?: DateTime | null;
      nationality?: string | null;
    } | null;
  };
};

export type NumberOfNewNotificationsForOrganizationUserQueryVariables = Exact<{
  organizationUserId: Scalars['String']['input'];
}>;

export type NumberOfNewNotificationsForOrganizationUserQuery = {
  __typename?: 'Query';
  numberOfNewNotificationsForOrganizationUser: number;
};

export type LatestOrNewSystemNotificationsForOrganizationUserQueryVariables = Exact<{
  filterType?: InputMaybe<Array<NotificationSystemFilterType> | NotificationSystemFilterType>;
  organizationUserId: Scalars['String']['input'];
}>;

export type LatestOrNewSystemNotificationsForOrganizationUserQuery = {
  __typename?: 'Query';
  latestOrNewSystemNotificationsForOrganizationUser: Array<{
    __typename?: 'Notification';
    id: string;
    notificationType: NotificationType;
    notificationMeansType: NotificationMeansType;
    organizationContextType?: OrganizationType | null;
    language: NotificationLanguage;
    batchId: string;
    title?: string | null;
    content?: string | null;
    contentType?: string | null;
    data?: JSONObject | null;
    sentAt?: DateTime | null;
    receivedAt?: DateTime | null;
    firstDisplayedAt?: DateTime | null;
    markedAsReadAt?: DateTime | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    job?: { __typename?: 'Job'; id: string } | null;
    agencyJob?: {
      __typename?: 'AgencyJob';
      id: string;
      agencyJobStatusType: AgencyJobStatusType;
      courierJobs?: Array<{
        __typename?: 'CourierJob';
        id: string;
        relationshipType: CourierJobAgencyRelationshipType;
        responsibleCourierUser: { __typename?: 'User'; id: string };
      }> | null;
    } | null;
    courierJob?: {
      __typename?: 'CourierJob';
      id: string;
      agencyJob: { __typename?: 'AgencyJob'; id: string };
    } | null;
    chatMessage?: {
      __typename?: 'ChatMessage';
      id: string;
      chat: {
        __typename?: 'Chat';
        id: string;
        agencyJob?: { __typename?: 'AgencyJob'; id: string } | null;
      };
    } | null;
  }>;
};

export type SignInUserContextQueryVariables = Exact<{ [key: string]: never }>;

export type SignInUserContextQuery = {
  __typename?: 'Query';
  signInUserContext: {
    __typename?: 'UserContext';
    sub: string;
    roleType?: OrganizationUserRoleType | null;
    organizationType?: OrganizationType | null;
    role: string;
    organizationActivatedAt?: DateTime | null;
    deviceId: string;
    userContextId: string;
    token: {
      __typename?: 'AzureADB2CToken';
      iss: string;
      exp: number;
      nbf: number;
      aud: string;
      oid: string;
      sub: string;
      city: string;
      country: string;
      name: string;
      givenName: string;
      familyName: string;
      emails: Array<string>;
      tfp: string;
      azp: string;
      ver: string;
      iat: number;
    };
    user?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    organizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
      usedOrganizationInvitation?: {
        __typename?: 'OrganizationInvitation';
        id: string;
        usedAt?: DateTime | null;
      } | null;
    } | null;
    device: { __typename?: 'Device'; id: string; deviceType: DeviceCategoryType };
    organizationSubscriptionPlan?: {
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      isActive: boolean;
      endTime: DateTime;
      prices?: Array<{
        __typename?: 'SubscriptionPlanPrice';
        currency: string;
        recurringInterval: string;
        value: string;
      }> | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        value: SubscriptionPlanType;
        planName: { __typename?: 'SubscriptionPlanName'; key: string; defaultValue: string };
        subscriptionFeatures?: Array<{
          __typename?: 'SubscriptionFeature';
          id: string;
          name: string;
          count: number;
          value: SubscriptionFeatureType;
        }> | null;
      };
    } | null;
  };
};

export type OrganizationInvitationsForPriojetOrganizationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrganizationInvitationsForPriojetOrganizationQuery = {
  __typename?: 'Query';
  organizationInvitationsForPriojetOrganization: Array<{
    __typename?: 'OrganizationInvitation';
    id: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    voidedAt?: DateTime | null;
    usedAt?: DateTime | null;
    reservedComment?: string | null;
    activationCode?: string | null;
    organizationInvitationType: OrganizationInvitationType;
    invitedOrganizationType?: OrganizationType | null;
    roleType?: OrganizationUserRoleType | null;
    bitrix24Deal?: {
      __typename?: 'OrganizationInvitationBitrix24Deal';
      id: number;
      bitrixDealCategoryId: number;
      bitrixInvitedCompanyId: number;
      bitrixInvitedContactId: number;
    } | null;
    organizationContact?: {
      __typename?: 'OrganizationContact';
      id: string;
      firstNames: string;
      lastName: string;
      phone: string;
      email?: string | null;
      isPriojetContact: boolean;
      isCourierContact: boolean;
      isAgencyContact: boolean;
      isClientContact: boolean;
      isTravelerContact: boolean;
      isServiceProviderContact: boolean;
      isOtherContact: boolean;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
    } | null;
    invitedUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
    } | null;
    invitedOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedForOrganizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      user: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
      };
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
    } | null;
    usedForOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
      createdAt: DateTime;
    } | null;
    usedForOrganizationOrganization?: {
      __typename?: 'OrganizationOrganization';
      id: string;
      connectionType: OrganizationOrganizationConnectionType;
      connectingOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
        createdAt: DateTime;
      };
      connectedOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
        createdAt: DateTime;
      };
    } | null;
    invitingOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
      createdAt: DateTime;
    } | null;
    usedByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
      createdAt: DateTime;
    } | null;
    createdByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
      createdAt: DateTime;
    } | null;
    organizationInvitationSharings: Array<{
      __typename?: 'OrganizationInvitationSharing';
      id: string;
      appName?: string | null;
      platformVersion?: string | null;
      platform: string;
      language?: string | null;
      sharedAt: DateTime;
      sharedByUser?: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        email: string;
        lastName: string;
      } | null;
    }>;
  }>;
};

export type CourierOrganizationInvitationsForAgencyOrganizationQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type CourierOrganizationInvitationsForAgencyOrganizationQuery = {
  __typename?: 'Query';
  courierOrganizationInvitationsForAgencyOrganization: Array<{
    __typename?: 'OrganizationInvitation';
    id: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    voidedAt?: DateTime | null;
    usedAt?: DateTime | null;
    reservedComment?: string | null;
    activationCode?: string | null;
    organizationInvitationType: OrganizationInvitationType;
    invitedOrganizationType?: OrganizationType | null;
    roleType?: OrganizationUserRoleType | null;
    hasInvitationDeal: boolean;
    organizationContact?: {
      __typename?: 'OrganizationContact';
      id: string;
      firstNames: string;
      lastName: string;
      phone: string;
      email?: string | null;
      isCourierContact: boolean;
      isAgencyContact: boolean;
      isClientContact: boolean;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
      userContactsForUser: Array<{
        __typename?: 'UserContact';
        id: string;
        expoContactId?: string | null;
        phone: string;
        phoneUnformatted: string;
        email?: string | null;
        firstNames: string;
        lastName: string;
        isCourierContact: boolean;
        isAgencyContact: boolean;
        isClientContact: boolean;
      }>;
    } | null;
    invitedUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
    } | null;
    invitedOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedForOrganizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      user: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
      };
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
    } | null;
    usedForOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedForOrganizationOrganization?: {
      __typename?: 'OrganizationOrganization';
      id: string;
      connectionType: OrganizationOrganizationConnectionType;
      connectingOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
      connectedOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
    } | null;
    invitingOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    createdByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
  }>;
};

export type EmployeeInvitationsForAgencyOrganizationQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;

export type EmployeeInvitationsForAgencyOrganizationQuery = {
  __typename?: 'Query';
  employeeInvitationsForAgencyOrganization: Array<{
    __typename?: 'OrganizationInvitation';
    id: string;
    createdAt: DateTime;
    updatedAt: DateTime;
    voidedAt?: DateTime | null;
    usedAt?: DateTime | null;
    reservedComment?: string | null;
    activationCode?: string | null;
    organizationInvitationType: OrganizationInvitationType;
    invitedOrganizationType?: OrganizationType | null;
    roleType?: OrganizationUserRoleType | null;
    hasInvitationDeal: boolean;
    organizationContact?: {
      __typename?: 'OrganizationContact';
      id: string;
      firstNames: string;
      lastName: string;
      phone: string;
      email?: string | null;
      isCourierContact: boolean;
      isAgencyContact: boolean;
      isClientContact: boolean;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
      userContactsForUser: Array<{
        __typename?: 'UserContact';
        id: string;
        expoContactId?: string | null;
        phone: string;
        phoneUnformatted: string;
        email?: string | null;
        firstNames: string;
        lastName: string;
        isCourierContact: boolean;
        isAgencyContact: boolean;
        isClientContact: boolean;
      }>;
    } | null;
    invitedUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
    } | null;
    invitedOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedForOrganizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      user: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
      };
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
    } | null;
    usedForOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedForOrganizationOrganization?: {
      __typename?: 'OrganizationOrganization';
      id: string;
      connectionType: OrganizationOrganizationConnectionType;
      connectingOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
      connectedOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        organizationType: OrganizationType;
      };
    } | null;
    invitingOrganization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
    } | null;
    usedByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    createdByUser?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
  }>;
};

export type CourierUserProfileByUserIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type CourierUserProfileByUserIdQuery = {
  __typename?: 'Query';
  courierUserProfileByUserId: {
    __typename?: 'CourierUserProfile';
    id: string;
    inactivatedAt?: DateTime | null;
    emergencyContactRelationship?: string | null;
    emergencyContactPhone?: string | null;
    emergencyContactName?: string | null;
    emergencyContactEmail?: string | null;
    emergencyContactAddress?: string | null;
    hasAmexCreditCard?: boolean | null;
    hasDinerCreditCard?: boolean | null;
    hasMasterCreditCard?: boolean | null;
    hasVisaCreditCard?: boolean | null;
    hasAmexDebitCard?: boolean | null;
    hasDinerDebitCard?: boolean | null;
    hasMasterDebitCard?: boolean | null;
    hasVisaDebitCard?: boolean | null;
    amexCreditCardLimit?: number | null;
    dinerCreditCardLimit?: number | null;
    masterCreditCardLimit?: number | null;
    visaCreditCardLimit?: number | null;
    amexCreditCardCurrency?: Currency | null;
    dinerCreditCardCurrency?: Currency | null;
    masterCreditCardCurrency?: Currency | null;
    visaCreditCardCurrency?: Currency | null;
    hasObcExperience?: boolean | null;
    firstObcJobDateAt?: DateTime | null;
    numberOfPastObcJobs?: number | null;
    hasMedicalObcExperience?: boolean | null;
    onboardExperiences?: Array<string> | null;
    taxCountry?: string | null;
    baseAirports?: Array<string> | null;
    languagesKnown?: Array<{
      __typename?: 'TLanguageKnown';
      language: string;
      level: LanguageSkillLevel;
    }> | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
    baseAirportLocations: Array<{ __typename?: 'Location'; id: string; iataCode?: string | null }>;
    user: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      email2?: string | null;
      phone?: string | null;
      phone2?: string | null;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      nationalities?: Array<string> | null;
      createdAt: DateTime;
      updatedAt: DateTime;
      inactivatedAt?: DateTime | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      userRegisteredAddressLocation?: {
        __typename?: 'Location';
        id: string;
        formattedAddress?: string | null;
        addressName?: string | null;
        apartmentOrSuite?: string | null;
        streetName?: string | null;
        houseNumber?: string | null;
        city?: string | null;
        postalCode?: string | null;
        state?: string | null;
        country?: string | null;
      } | null;
      organizationUsers: Array<{
        __typename?: 'OrganizationUser';
        id: string;
        organization: {
          __typename?: 'Organization';
          id: string;
          legalName: string;
          name: string;
          email?: string | null;
          phone?: string | null;
          headquartersAddressLocation?: {
            __typename?: 'Location';
            id: string;
            placeId?: string | null;
            formattedAddress?: string | null;
            formattedShortAddress?: string | null;
            addressName?: string | null;
            apartmentOrSuite?: string | null;
            streetName?: string | null;
            houseNumber?: string | null;
            city?: string | null;
            postalCode?: string | null;
            state?: string | null;
            country?: string | null;
          } | null;
        };
      }>;
      agencyCourierUserProfile?: {
        __typename?: 'AgencyCourierUserProfile';
        internalComment?: string | null;
        markedForAttention: boolean;
        organizationCourierCategories?: Array<{
          __typename?: 'OrganizationCourierCategory';
          id: string;
          label: string;
        }> | null;
      } | null;
    };
  };
};

export type CurrentUserContextQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserContextQuery = {
  __typename?: 'Query';
  currentUserContext: {
    __typename?: 'UserContext';
    sub: string;
    roleType?: OrganizationUserRoleType | null;
    organizationType?: OrganizationType | null;
    role: string;
    organizationActivatedAt?: DateTime | null;
    deviceId: string;
    userContextId: string;
    token: {
      __typename?: 'AzureADB2CToken';
      iss: string;
      exp: number;
      nbf: number;
      aud: string;
      oid: string;
      sub: string;
      city: string;
      country: string;
      name: string;
      givenName: string;
      familyName: string;
      emails: Array<string>;
      tfp: string;
      azp: string;
      ver: string;
      iat: number;
    };
    user?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    organizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
      usedOrganizationInvitation?: {
        __typename?: 'OrganizationInvitation';
        id: string;
        usedAt?: DateTime | null;
      } | null;
    } | null;
    device: { __typename?: 'Device'; id: string; deviceType: DeviceCategoryType };
    organizationSubscriptionPlan?: {
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      isActive: boolean;
      startTime: DateTime;
      endTime: DateTime;
      isMain: boolean;
      recurringInterval?: string | null;
      status: string;
      prices?: Array<{
        __typename?: 'SubscriptionPlanPrice';
        currency: string;
        recurringInterval: string;
        value: string;
      }> | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        value: SubscriptionPlanType;
        planName: { __typename?: 'SubscriptionPlanName'; key: string; defaultValue: string };
        subscriptionFeatures?: Array<{
          __typename?: 'SubscriptionFeature';
          id: string;
          name: string;
          count: number;
          value: SubscriptionFeatureType;
        }> | null;
      };
    } | null;
  };
};

export type UserContextsQueryVariables = Exact<{ [key: string]: never }>;

export type UserContextsQuery = {
  __typename?: 'Query';
  userContexts: Array<{
    __typename?: 'UserContext';
    sub: string;
    roleType?: OrganizationUserRoleType | null;
    organizationType?: OrganizationType | null;
    role: string;
    organizationActivatedAt?: DateTime | null;
    deviceId: string;
    userContextId: string;
    token: {
      __typename?: 'AzureADB2CToken';
      iss: string;
      exp: number;
      nbf: number;
      aud: string;
      oid: string;
      sub: string;
      city: string;
      country: string;
      name: string;
      givenName: string;
      familyName: string;
      emails: Array<string>;
      tfp: string;
      azp: string;
      ver: string;
      iat: number;
    };
    user?: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      lastName: string;
    } | null;
    organizationUser?: {
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
    } | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      organizationType: OrganizationType;
      usedOrganizationInvitation?: {
        __typename?: 'OrganizationInvitation';
        id: string;
        usedAt?: DateTime | null;
      } | null;
    } | null;
    device: { __typename?: 'Device'; id: string; deviceType: DeviceCategoryType };
    organizationSubscriptionPlan?: {
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      isActive: boolean;
      endTime: DateTime;
      prices?: Array<{
        __typename?: 'SubscriptionPlanPrice';
        currency: string;
        recurringInterval: string;
        value: string;
      }> | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        value: SubscriptionPlanType;
        planName: { __typename?: 'SubscriptionPlanName'; key: string; defaultValue: string };
        subscriptionFeatures?: Array<{
          __typename?: 'SubscriptionFeature';
          id: string;
          count: number;
          name: string;
          value: SubscriptionFeatureType;
        }> | null;
      };
    } | null;
  }>;
};

export type CourierUserProfileForCourierOrganizationUserQueryVariables = Exact<{
  organizationUserId: Scalars['String']['input'];
}>;

export type CourierUserProfileForCourierOrganizationUserQuery = {
  __typename?: 'Query';
  courierUserProfileForCourierOrganizationUser: {
    __typename?: 'CourierUserProfile';
    id: string;
    emergencyContactRelationship?: string | null;
    emergencyContactPhone?: string | null;
    emergencyContactName?: string | null;
    emergencyContactEmail?: string | null;
    emergencyContactAddress?: string | null;
    hasAmexCreditCard?: boolean | null;
    hasDinerCreditCard?: boolean | null;
    hasMasterCreditCard?: boolean | null;
    hasVisaCreditCard?: boolean | null;
    hasAmexDebitCard?: boolean | null;
    hasDinerDebitCard?: boolean | null;
    hasMasterDebitCard?: boolean | null;
    hasVisaDebitCard?: boolean | null;
    amexCreditCardLimit?: number | null;
    dinerCreditCardLimit?: number | null;
    masterCreditCardLimit?: number | null;
    visaCreditCardLimit?: number | null;
    amexCreditCardCurrency?: Currency | null;
    dinerCreditCardCurrency?: Currency | null;
    masterCreditCardCurrency?: Currency | null;
    visaCreditCardCurrency?: Currency | null;
    hasObcExperience?: boolean | null;
    firstObcJobDateAt?: DateTime | null;
    numberOfPastObcJobs?: number | null;
    hasMedicalObcExperience?: boolean | null;
    onboardExperiences?: Array<string> | null;
    taxCountry?: string | null;
    baseAirports?: Array<string> | null;
    languagesKnown?: Array<{
      __typename?: 'TLanguageKnown';
      language: string;
      level: LanguageSkillLevel;
    }> | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
  };
};

export type UserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UserQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    firstNames: string;
    lastName: string;
    sex?: Gender | null;
    dateOfBirth?: DateTime | null;
    placeOfBirth?: string | null;
    countryOfBirth?: string | null;
    language?: string | null;
    nationalities?: Array<string> | null;
    email: string;
    phone?: string | null;
    email2?: string | null;
    phone2?: string | null;
    profilePicture?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    userRegisteredAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      addressName?: string | null;
      apartmentOrSuite?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
      state?: string | null;
      country?: string | null;
    } | null;
  };
};

export type UserMinimalQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UserMinimalQuery = {
  __typename?: 'Query';
  user: { __typename?: 'User'; id: string; firstNames: string; lastName: string };
};

export type UserFrequentFlyerDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserFrequentFlyerDocumentsQuery = {
  __typename?: 'Query';
  userFrequentFlyerDocuments: Array<{
    __typename?: 'UserFrequentFlyerDocument';
    id: string;
    airlineType?: AirlineType | null;
    airlineStatusType?: AirlineStatusType | null;
    airlineAllianceStatusType?: AirlineAllianceStatusType | null;
    airlineAllianceType?: AirlineAllianceType | null;
    documentNumber?: string | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  }>;
};

export type UserFrequentFlyerDocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserFrequentFlyerDocumentQuery = {
  __typename?: 'Query';
  userFrequentFlyerDocument: {
    __typename?: 'UserFrequentFlyerDocument';
    id: string;
    airlineType?: AirlineType | null;
    airlineStatusType?: AirlineStatusType | null;
    airlineAllianceStatusType?: AirlineAllianceStatusType | null;
    airlineAllianceType?: AirlineAllianceType | null;
    documentNumber?: string | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UserVaccinationDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserVaccinationDocumentsQuery = {
  __typename?: 'Query';
  userVaccinationDocuments: Array<{
    __typename?: 'UserVaccinationDocument';
    id: string;
    diseaseType?: VaccinationTargetDisease | null;
    description?: string | null;
    vaccinationType?: string | null;
    vaccinationManufacturer?: string | null;
    dateOfVaccination?: DateTime | null;
    isVaccinationComplete?: boolean | null;
    vaccinationCenter?: string | null;
    cityOfVaccination?: string | null;
    countryOfVaccination?: string | null;
    expiresOn?: DateTime | null;
    documentNumber?: string | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  }>;
};

export type UserVaccinationDocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserVaccinationDocumentQuery = {
  __typename?: 'Query';
  userVaccinationDocument: {
    __typename?: 'UserVaccinationDocument';
    id: string;
    diseaseType?: VaccinationTargetDisease | null;
    description?: string | null;
    vaccinationType?: string | null;
    vaccinationManufacturer?: string | null;
    dateOfVaccination?: DateTime | null;
    isVaccinationComplete?: boolean | null;
    vaccinationCenter?: string | null;
    cityOfVaccination?: string | null;
    countryOfVaccination?: string | null;
    expiresOn?: DateTime | null;
    documentNumber?: string | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UserHealthReportsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserHealthReportsQuery = {
  __typename?: 'Query';
  userHealthReports: Array<{
    __typename?: 'UserHealthReport';
    id: string;
    diseaseType?: VaccinationTargetDisease | null;
    description?: string | null;
    testType?: HealthTestType | null;
    dateTimeOfTest?: DateTime | null;
    isResultPositive?: boolean | null;
    testCenter?: string | null;
    cityOfTest?: string | null;
    countryOfTest?: string | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    cityOfTestLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  }>;
};

export type UserHealthReportQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserHealthReportQuery = {
  __typename?: 'Query';
  userHealthReport: {
    __typename?: 'UserHealthReport';
    id: string;
    diseaseType?: VaccinationTargetDisease | null;
    description?: string | null;
    testType?: HealthTestType | null;
    dateTimeOfTest?: DateTime | null;
    isResultPositive?: boolean | null;
    testCenter?: string | null;
    cityOfTest?: string | null;
    countryOfTest?: string | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    cityOfTestLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UserBackgroundDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserBackgroundDocumentsQuery = {
  __typename?: 'Query';
  userBackgroundDocuments: Array<{
    __typename?: 'UserBackgroundDocument';
    id: string;
    name?: string | null;
    issuingInstitution?: string | null;
    countryOfIssue?: string | null;
    dateOfIssue?: DateTime | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  }>;
};

export type UserBackgroundDocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserBackgroundDocumentQuery = {
  __typename?: 'Query';
  userBackgroundDocument: {
    __typename?: 'UserBackgroundDocument';
    id: string;
    name?: string | null;
    issuingInstitution?: string | null;
    countryOfIssue?: string | null;
    dateOfIssue?: DateTime | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UserOtherDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserOtherDocumentsQuery = {
  __typename?: 'Query';
  userOtherDocuments: Array<{
    __typename?: 'UserOtherDocument';
    id: string;
    name?: string | null;
    description?: string | null;
    countryOfIssue?: string | null;
    placeOfIssue?: string | null;
    dateOfIssue?: DateTime | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  }>;
};

export type UserOtherDocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type UserOtherDocumentQuery = {
  __typename?: 'Query';
  userOtherDocument: {
    __typename?: 'UserOtherDocument';
    id: string;
    name?: string | null;
    description?: string | null;
    countryOfIssue?: string | null;
    placeOfIssue?: string | null;
    dateOfIssue?: DateTime | null;
    expiresOn?: DateTime | null;
    documentContainerName?: string | null;
    originalDocumentFrontFilePath?: string | null;
    originalDocumentBackFilePath?: string | null;
    compressedDocumentFrontFilePath?: string | null;
    compressedDocumentBackFilePath?: string | null;
    updatedAt: DateTime;
    createdAt: DateTime;
    verifiedOn?: DateTime | null;
    verificationStatus?: DocumentVerificationStatus | null;
    verificationComments?: string | null;
    documentFrontFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentFrontFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureCompressed?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    documentBackFromAzureOriginal?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type UserWithAllUserDocumentsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type UserWithAllUserDocumentsQuery = {
  __typename?: 'Query';
  userWithAllUserDocuments: {
    __typename?: 'User';
    id: string;
    userBackgroundDocument: Array<{ __typename?: 'UserBackgroundDocument'; id: string }>;
    userFrequentFlyerDocuments: Array<{ __typename?: 'UserFrequentFlyerDocument'; id: string }>;
    userHealthReports: Array<{ __typename?: 'UserHealthReport'; id: string }>;
    userIdentityDocuments: Array<{ __typename?: 'UserIdentityDocument'; id: string }>;
    userOtherDocuments: Array<{ __typename?: 'UserOtherDocument'; id: string }>;
    userTravelDocuments: Array<{ __typename?: 'UserTravelDocument'; id: string }>;
    userVaccinationDocuments: Array<{ __typename?: 'UserVaccinationDocument'; id: string }>;
  };
};

export type CourierJobForCourierOrganizationQueryVariables = Exact<{
  courierOrganizationId: Scalars['String']['input'];
  courierJobId: Scalars['String']['input'];
}>;

export type CourierJobForCourierOrganizationQuery = {
  __typename?: 'Query';
  courierJobForCourierOrganization: {
    __typename?: 'CourierJob';
    id: string;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    declineReasonType?: AgencyDeclinedReasonType | null;
    relationshipTypeChangedAt?: DateTime | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    hotelBudget?: number | null;
    kickOffFee?: number | null;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    hasIncident: boolean;
    responsibleCourierOrganization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      createdAt: DateTime;
      organizationType: OrganizationType;
    };
    responsibleCourierUser: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      phone?: string | null;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      nationalities?: Array<string> | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    };
    agencyJob: {
      __typename?: 'AgencyJob';
      id: string;
      returnTimeAtStartAirport?: DateTime | null;
      checkInTimeAtStartAirport?: DateTime | null;
      returnTimeAtEndAirport?: DateTime | null;
      checkInTimeAtEndAirport?: DateTime | null;
      externalJobReference: string;
      currency: Currency;
      isDrivingLicenseRequiredForJob: boolean;
      isCarRequiredForJob: boolean;
      jobDetails?: string | null;
      numOfCouriersRequired: number;
      hasIncident: boolean;
      agencyJobStatusType: AgencyJobStatusType;
      numOfItems: number;
      weightOfItems?: number | null;
      isAdditionalCostPaidByCourier: boolean;
      additionalCostInformation?: string | null;
      startAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
      endAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        formattedAirportAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
      responsibleAgencyOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
      responsibleAgencyOrganizationUsers: Array<{
        __typename?: 'OrganizationUser';
        id: string;
        roleType: OrganizationUserRoleType;
        organization: {
          __typename?: 'Organization';
          id: string;
          name: string;
          legalName: string;
          shortName?: string | null;
          organizationType: OrganizationType;
        };
        user: {
          __typename?: 'User';
          id: string;
          firstNames: string;
          lastName: string;
          email: string;
        };
      }>;
    };
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
    };
  };
};

export type CourierJobForAgencyOrganizationQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
  courierJobId: Scalars['String']['input'];
}>;

export type CourierJobForAgencyOrganizationQuery = {
  __typename?: 'Query';
  courierJobForAgencyOrganization: {
    __typename?: 'CourierJob';
    id: string;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    declineReasonType?: AgencyDeclinedReasonType | null;
    relationshipTypeChangedAt?: DateTime | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    hotelBudget?: number | null;
    kickOffFee?: number | null;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    hasIncident: boolean;
    responsibleCourierOrganization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      createdAt: DateTime;
      organizationType: OrganizationType;
    };
    responsibleCourierUser: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      phone?: string | null;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      nationalities?: Array<string> | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    };
    agencyJob: {
      __typename?: 'AgencyJob';
      id: string;
      returnTimeAtStartAirport?: DateTime | null;
      checkInTimeAtStartAirport?: DateTime | null;
      returnTimeAtEndAirport?: DateTime | null;
      checkInTimeAtEndAirport?: DateTime | null;
      externalJobReference: string;
      currency: Currency;
      isDrivingLicenseRequiredForJob: boolean;
      isCarRequiredForJob: boolean;
      jobDetails?: string | null;
      numOfCouriersRequired: number;
      hasIncident: boolean;
      agencyJobStatusType: AgencyJobStatusType;
      numOfItems: number;
      weightOfItems?: number | null;
      isAdditionalCostPaidByCourier: boolean;
      additionalCostInformation?: string | null;
      startAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
      endAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        formattedAirportAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      } | null;
      responsibleAgencyOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
    };
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
    };
  };
};

export type CourierJobsForCourierOrganizationQueryVariables = Exact<{
  courierOrganizationId: Scalars['String']['input'];
}>;

export type CourierJobsForCourierOrganizationQuery = {
  __typename?: 'Query';
  courierJobsForCourierOrganization: Array<{
    __typename?: 'CourierJob';
    id: string;
    declineReasonType?: AgencyDeclinedReasonType | null;
    relationshipTypeChangedAt?: DateTime | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    hotelBudget?: number | null;
    kickOffFee?: number | null;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    hasIncident: boolean;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    responsibleCourierOrganization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      legalName: string;
      createdAt: DateTime;
      organizationType: OrganizationType;
    };
    responsibleCourierUser: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      phone?: string | null;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      nationalities?: Array<string> | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    };
    agencyJob: {
      __typename?: 'AgencyJob';
      id: string;
      returnTimeAtStartAirport?: DateTime | null;
      checkInTimeAtStartAirport?: DateTime | null;
      returnTimeAtEndAirport?: DateTime | null;
      checkInTimeAtEndAirport?: DateTime | null;
      externalJobReference: string;
      currency: Currency;
      isDrivingLicenseRequiredForJob: boolean;
      isCarRequiredForJob: boolean;
      jobDetails?: string | null;
      numOfCouriersRequired: number;
      hasIncident: boolean;
      agencyJobStatusType: AgencyJobStatusType;
      relationshipType: AgencyJobClientRelationshipType;
      startAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      } | null;
      endAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        formattedAirportAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      } | null;
      responsibleAgencyOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
    };
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
    };
  }>;
};

export type CourierJobsForCourierOrganizationMinimalQueryVariables = Exact<{
  courierOrganizationId: Scalars['String']['input'];
}>;

export type CourierJobsForCourierOrganizationMinimalQuery = {
  __typename?: 'Query';
  courierJobsForCourierOrganizationMinimal: Array<{
    __typename?: 'CourierJob';
    id: string;
    courierJobStatusType: CourierJobStatusType;
    relationshipType: CourierJobAgencyRelationshipType;
    agencyJob: { __typename?: 'AgencyJob'; id: string; agencyJobStatusType: AgencyJobStatusType };
    job: { __typename?: 'Job'; id: string };
  }>;
};

export type AgencyJobsForAgencyOrganizationPaginatedQueryVariables = Exact<{
  data: AgencyJobsForAgencyOrganizationInput;
}>;

export type AgencyJobsForAgencyOrganizationPaginatedQuery = {
  __typename?: 'Query';
  agencyJobsForAgencyOrganizationPaginated: {
    __typename?: 'AgencyJobConnection';
    edges?: Array<{
      __typename?: 'AgencyJobEdge';
      node: {
        __typename?: 'AgencyJob';
        id: string;
        returnTimeAtStartAirport?: DateTime | null;
        checkInTimeAtStartAirport?: DateTime | null;
        returnTimeAtEndAirport?: DateTime | null;
        checkInTimeAtEndAirport?: DateTime | null;
        externalJobReference: string;
        hasIncident: boolean;
        agencyJobStatusType: AgencyJobStatusType;
        internalNotes?: string | null;
        startAirport?: {
          __typename?: 'Location';
          id: string;
          placeId?: string | null;
          formattedAddress?: string | null;
          formattedShortAddress?: string | null;
          formattedAirportAddress?: string | null;
          timeZoneIdentifier?: string | null;
          timeZoneRawOffset?: number | null;
        } | null;
        endAirport?: {
          __typename?: 'Location';
          id: string;
          placeId?: string | null;
          formattedAddress?: string | null;
          formattedShortAddress?: string | null;
          formattedAirportAddress?: string | null;
          timeZoneIdentifier?: string | null;
          timeZoneRawOffset?: number | null;
        } | null;
        job: {
          __typename?: 'Job';
          id: string;
          priojetJobIdentifier: string;
          pickupTime: DateTime;
          pickupAddressDescription?: string | null;
          dropOffTime?: DateTime | null;
          pickupLocation: {
            __typename?: 'Location';
            id: string;
            placeId?: string | null;
            formattedAddress?: string | null;
            formattedShortAddress?: string | null;
            timeZoneIdentifier?: string | null;
            timeZoneRawOffset?: number | null;
          };
          dropOffLocation: {
            __typename?: 'Location';
            id: string;
            placeId?: string | null;
            formattedAddress?: string | null;
            formattedShortAddress?: string | null;
            timeZoneIdentifier?: string | null;
            timeZoneRawOffset?: number | null;
          };
        };
        courierJobs?: Array<{
          __typename?: 'CourierJob';
          id: string;
          relationshipType: CourierJobAgencyRelationshipType;
        }> | null;
        responsibleAgencyOrganization: {
          __typename?: 'Organization';
          id: string;
          linkCO2Emissions?: string | null;
        };
      };
    }> | null;
  };
};

export type AgencyJobsForAgencyOrganizationMinimalQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
}>;

export type AgencyJobsForAgencyOrganizationMinimalQuery = {
  __typename?: 'Query';
  agencyJobsForAgencyOrganization: Array<{
    __typename?: 'AgencyJob';
    id: string;
    agencyJobStatusType: AgencyJobStatusType;
  }>;
};

export type AgencyJobsForAgencyOrganizationQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
}>;

export type AgencyJobsForAgencyOrganizationQuery = {
  __typename?: 'Query';
  agencyJobsForAgencyOrganization: Array<{
    __typename?: 'AgencyJob';
    id: string;
    returnTimeAtStartAirport?: DateTime | null;
    checkInTimeAtStartAirport?: DateTime | null;
    returnTimeAtEndAirport?: DateTime | null;
    checkInTimeAtEndAirport?: DateTime | null;
    externalJobReference: string;
    hasIncident: boolean;
    agencyJobStatusType: AgencyJobStatusType;
    internalNotes?: string | null;
    startAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      formattedAirportAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
    } | null;
    endAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      formattedAirportAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
    } | null;
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
    };
    courierJobs?: Array<{
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
    }> | null;
  }>;
};

export type CourierJobsForAgencyJobQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type CourierJobsForAgencyJobQuery = {
  __typename?: 'Query';
  courierJobsForAgencyJob: Array<{
    __typename?: 'CourierJob';
    id: string;
    relationshipType: CourierJobAgencyRelationshipType;
    courierJobStatusType: CourierJobStatusType;
    declineReasonType?: AgencyDeclinedReasonType | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    hotelBudget?: number | null;
    kickOffFee?: number | null;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    hasIncident: boolean;
    responsibleCourierOrganization: {
      __typename?: 'Organization';
      id: string;
      name: string;
      shortName?: string | null;
      legalName: string;
      createdAt: DateTime;
      organizationType: OrganizationType;
    };
    responsibleCourierUser: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      email: string;
      phone?: string | null;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      nationalities?: Array<string> | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    };
    agencyJob: {
      __typename?: 'AgencyJob';
      id: string;
      returnTimeAtStartAirport?: DateTime | null;
      checkInTimeAtStartAirport?: DateTime | null;
      returnTimeAtEndAirport?: DateTime | null;
      checkInTimeAtEndAirport?: DateTime | null;
      externalJobReference: string;
      currency: Currency;
      isDrivingLicenseRequiredForJob: boolean;
      isCarRequiredForJob: boolean;
      jobDetails?: string | null;
      numOfCouriersRequired: number;
      hasIncident: boolean;
      agencyJobStatusType: AgencyJobStatusType;
      startAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      } | null;
      endAirport?: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        formattedAirportAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      } | null;
    };
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
      };
    };
    courierJobAgencyJobChat?: { __typename?: 'Chat'; id: string } | null;
  }>;
};

export type CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type CourierJobsCourierOrganizationUsersForAgencyJobQuery = {
  __typename?: 'Query';
  courierJobsForAgencyJob: Array<{
    __typename?: 'CourierJob';
    id: string;
    responsibleCourierOrganization: {
      __typename?: 'Organization';
      id: string;
      organizationUsers: Array<{ __typename?: 'OrganizationUser'; id: string }>;
    };
  }>;
};

export type AgencyJobForAgencyOrganizationQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type AgencyJobForAgencyOrganizationQuery = {
  __typename?: 'Query';
  agencyJobForAgencyOrganization: {
    __typename?: 'AgencyJob';
    id: string;
    returnTimeAtStartAirport?: DateTime | null;
    checkInTimeAtStartAirport?: DateTime | null;
    returnTimeAtEndAirport?: DateTime | null;
    checkInTimeAtEndAirport?: DateTime | null;
    externalJobReference: string;
    comment?: string | null;
    fixedRate?: number | null;
    dailyRate?: number | null;
    kickOffFee?: number | null;
    hotelBudget?: number | null;
    currency: Currency;
    isAccommodationBookedByCourier: boolean;
    isAccommodationPaidByCourier: boolean;
    isAdditionalCostPaidByCourier: boolean;
    additionalCostInformation?: string | null;
    isDrivingLicenseRequiredForJob: boolean;
    isCarRequiredForJob: boolean;
    jobDetails?: string | null;
    numOfCouriersRequired: number;
    hasIncident: boolean;
    agencyJobStatusType: AgencyJobStatusType;
    internalNotes?: string | null;
    startAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedAirportAddress?: string | null;
      formattedShortAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      types: Array<string>;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    responsibleAgencyOrganizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
      user: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
      };
    }>;
    endAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      formattedAirportAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      types: Array<string>;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    job: {
      __typename?: 'Job';
      id: string;
      priojetJobIdentifier: string;
      typeOfDelivery: JobTypeOfDelivery;
      jobType: JobType;
      pickupTime: DateTime;
      pickupAddressDescription?: string | null;
      dropOffTime?: DateTime | null;
      dropOffAddressDescription?: string | null;
      numOfItems: number;
      weightOfItems?: number | null;
      descriptionOfItems?: string | null;
      description?: string | null;
      transportedGoodsContentType: TransportGoodsContentType;
      pickupLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        types: Array<string>;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
      dropOffLocation: {
        __typename?: 'Location';
        id: string;
        placeId?: string | null;
        formattedAddress?: string | null;
        formattedShortAddress?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        types: Array<string>;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
    };
    clientJob: {
      __typename?: 'ClientJob';
      id: string;
      organizationName: string;
      organizationLegalName?: string | null;
      organizationPhone?: string | null;
      organizationEmail?: string | null;
      externalJobReference?: string | null;
      comment?: string | null;
      fixedRate?: number | null;
      currency: Currency;
      hasIncident: boolean;
    };
    courierJobs?: Array<{
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      courierJobStatusType: CourierJobStatusType;
      declineReasonType?: AgencyDeclinedReasonType | null;
      fixedRate?: number | null;
      dailyRate?: number | null;
      hotelBudget?: number | null;
      kickOffFee?: number | null;
      isAccommodationBookedByCourier: boolean;
      isAccommodationPaidByCourier: boolean;
      isAdditionalCostPaidByCourier: boolean;
      additionalCostInformation?: string | null;
      hasIncident: boolean;
      responsibleCourierOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        createdAt: DateTime;
        organizationType: OrganizationType;
      };
      courierJobAgencyJobChat?: { __typename?: 'Chat'; id: string } | null;
      responsibleCourierUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        email: string;
        phone?: string | null;
        lastName: string;
        sex?: Gender | null;
        dateOfBirth?: DateTime | null;
        placeOfBirth?: string | null;
        countryOfBirth?: string | null;
        language?: string | null;
        nationalities?: Array<string> | null;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    }> | null;
  };
};

export type AgencyJobForAgencyOrganizationForPlanningQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type AgencyJobForAgencyOrganizationForPlanningQuery = {
  __typename?: 'Query';
  agencyJobForAgencyOrganization: {
    __typename?: 'AgencyJob';
    id: string;
    externalJobReference: string;
    startAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedAirportAddress?: string | null;
      formattedShortAddress?: string | null;
      iataCode?: string | null;
    } | null;
    endAirport?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedAirportAddress?: string | null;
      formattedShortAddress?: string | null;
      iataCode?: string | null;
    } | null;
    job: { __typename?: 'Job'; id: string; priojetJobIdentifier: string };
  };
};

export type AgencyJobForAgencyOrganizationInternalNotesQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type AgencyJobForAgencyOrganizationInternalNotesQuery = {
  __typename?: 'Query';
  agencyJobForAgencyOrganization: {
    __typename?: 'AgencyJob';
    id: string;
    internalNotes?: string | null;
  };
};

export type AgencyCourierJobChatQueryVariables = Exact<{
  agencyCourierJobChatInput: AgencyCourierJobChatInput;
}>;

export type AgencyCourierJobChatQuery = {
  __typename?: 'Query';
  agencyCourierJobChat: {
    __typename?: 'Chat';
    id: string;
    inactivatedAt?: DateTime | null;
    courierJob?: {
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      responsibleCourierOrganization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        createdAt: DateTime;
        organizationType: OrganizationType;
      };
      responsibleCourierUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        email: string;
        phone?: string | null;
        lastName: string;
      };
      job: { __typename?: 'Job'; id: string; priojetJobIdentifier: string };
      agencyJob: {
        __typename?: 'AgencyJob';
        id: string;
        agencyJobStatusType: AgencyJobStatusType;
        startedByAgencyAt?: DateTime | null;
        agencyJobChat?: { __typename?: 'Chat'; id: string } | null;
        endAirport?: {
          __typename?: 'Location';
          id: string;
          formattedShortAddress?: string | null;
          formattedAirportAddress?: string | null;
        } | null;
        responsibleAgencyOrganization: {
          __typename?: 'Organization';
          id: string;
          shortName?: string | null;
          legalName: string;
          organizationType: OrganizationType;
        };
      };
    } | null;
  };
};

export type AgencyJobChatQueryVariables = Exact<{
  agencyJobChatInput: AgencyJobChatInput;
}>;

export type AgencyJobChatQuery = {
  __typename?: 'Query';
  agencyJobChat: {
    __typename?: 'Chat';
    id: string;
    inactivatedAt?: DateTime | null;
    agencyJob?: {
      __typename?: 'AgencyJob';
      id: string;
      agencyJobStatusType: AgencyJobStatusType;
      externalJobReference: string;
      courierJobs?: Array<{
        __typename?: 'CourierJob';
        id: string;
        relationshipType: CourierJobAgencyRelationshipType;
        responsibleCourierUser: { __typename?: 'User'; id: string };
      }> | null;
      startAirport?: { __typename?: 'Location'; id: string; iataCode?: string | null } | null;
      endAirport?: { __typename?: 'Location'; id: string; iataCode?: string | null } | null;
      responsibleAgencyOrganization: {
        __typename?: 'Organization';
        id: string;
        shortName?: string | null;
        legalName: string;
        organizationType: OrganizationType;
      };
      job: { __typename?: 'Job'; id: string; priojetJobIdentifier: string };
    } | null;
  };
};

export type ChatMessagesByChatIdQueryVariables = Exact<{
  chatMessagesByChatIdInput: ChatMessagesByChatIdInput;
  pagination?: InputMaybe<PaginationArgs>;
  orderBy?: InputMaybe<OrderArgs>;
}>;

export type ChatMessagesByChatIdQuery = {
  __typename?: 'Query';
  chatMessagesByChatId: {
    __typename?: 'ChatMessageConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ChatMessageEdge';
      cursor: string;
      node: {
        __typename?: 'ChatMessage';
        id: string;
        message?: string | null;
        messageType: MessageType;
        createdAt: DateTime;
        updatedAt: DateTime;
        chatFile?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
        sendingUser: {
          __typename?: 'User';
          id: string;
          firstNames: string;
          lastName: string;
          profilePicture?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            cacheKey: string;
            cacheTTL?: DateTime | null;
            sasToken: {
              __typename?: 'SasTokenResponse';
              version?: string | null;
              signature?: string | null;
              expiresOn?: string | null;
              permissions?: string | null;
              resource?: string | null;
            };
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        };
      };
    }> | null;
  };
};

export type ChatMessageEdgeQueryVariables = Exact<{
  chatMessageId: Scalars['String']['input'];
}>;

export type ChatMessageEdgeQuery = {
  __typename?: 'Query';
  chatMessageEdge: {
    __typename?: 'ChatMessageCreateEdge';
    cursor: string;
    node: {
      __typename?: 'ChatMessage';
      id: string;
      message?: string | null;
      messageType: MessageType;
      createdAt: DateTime;
      updatedAt: DateTime;
      chatFile?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      sendingUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    };
  };
};

export type OrganizationProfileQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type OrganizationProfileQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    shortName?: string | null;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    invoiceEmail?: string | null;
    socialProfiles: Array<{
      __typename?: 'SocialProfile';
      platform: string;
      type: string;
      value: string;
    }>;
    headquartersAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      addressName?: string | null;
      apartmentOrSuite?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
      state?: string | null;
      country?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    invoicingAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      addressName?: string | null;
      apartmentOrSuite?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      city?: string | null;
      postalCode?: string | null;
      state?: string | null;
      country?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    registrationAddressLocation?: {
      __typename?: 'Location';
      id: string;
      placeId?: string | null;
      formattedAddress?: string | null;
      formattedShortAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    logo?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type OrganizationOrganizationUsersQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type OrganizationOrganizationUsersQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    shortName?: string | null;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    organizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      createdAt: DateTime;
      user: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        email: string;
        phone?: string | null;
        createdAt: DateTime;
      };
    }>;
  };
};

export type UserByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UserByIdQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    id: string;
    firstNames: string;
    lastName: string;
    profilePicture?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
  };
};

export type MinSupportedAppVersionQueryVariables = Exact<{ [key: string]: never }>;

export type MinSupportedAppVersionQuery = {
  __typename?: 'Query';
  minSupportedAppVersion: {
    __typename?: 'MinSupportedVersion';
    currentAppVersion: string;
    minSupportedAppVersion: string;
  };
};

export type DeletedAccountsPriojetOnlyQueryVariables = Exact<{ [key: string]: never }>;

export type DeletedAccountsPriojetOnlyQuery = {
  __typename?: 'Query';
  deletedAccountsPriojetOnly: Array<{
    __typename?: 'User';
    id: string;
    firstNames: string;
    lastName: string;
    email: string;
    dateOfBirth?: DateTime | null;
    inactivatedAt?: DateTime | null;
    profilePicture?: { __typename?: 'StoredAsset'; sasUrl: string } | null;
    organizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      user: { __typename?: 'User'; id: string };
      organization: {
        __typename?: 'Organization';
        id: string;
        organizationType: OrganizationType;
        name: string;
        phone?: string | null;
        inactivatedAt?: DateTime | null;
        logo?: { __typename?: 'StoredAsset'; sasUrl: string } | null;
        organizationUsers: Array<{
          __typename?: 'OrganizationUser';
          id: string;
          user: { __typename?: 'User'; id: string };
        }>;
      };
    }>;
  }>;
};

export type OrganizationUserByIdQueryVariables = Exact<{
  organizationUserId: Scalars['String']['input'];
}>;

export type OrganizationUserByIdQuery = {
  __typename?: 'Query';
  organizationUserById: {
    __typename?: 'OrganizationUser';
    id: string;
    roleType: OrganizationUserRoleType;
    user: {
      __typename?: 'User';
      firstNames: string;
      lastName: string;
      sex?: Gender | null;
      dateOfBirth?: DateTime | null;
      placeOfBirth?: string | null;
      countryOfBirth?: string | null;
      language?: string | null;
      email: string;
      phone?: string | null;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
      } | null;
    };
    organization: {
      __typename?: 'Organization';
      id: string;
      organizationUsers: Array<{
        __typename?: 'OrganizationUser';
        id: string;
        roleType: OrganizationUserRoleType;
      }>;
    };
  };
};

export type SubscriptionPlansAgencyQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansAgencyQuery = {
  __typename?: 'Query';
  subscriptionPlansAgency: {
    __typename?: 'SubscriptionPlanPaddle';
    month: Array<{
      __typename?: 'SubscriptionPlanPaddleType';
      description: JSONObject;
      featureDescription: Array<JSONObject>;
      name: JSONObject;
      planId: number;
      prices: Array<JSONObject>;
      recurringInterval: JSONObject;
      trialPeriod: number;
      isMain: boolean;
      subscriptionPlanType: number;
    }>;
    year: Array<{
      __typename?: 'SubscriptionPlanPaddleType';
      description: JSONObject;
      featureDescription: Array<JSONObject>;
      name: JSONObject;
      planId: number;
      prices: Array<JSONObject>;
      recurringInterval: JSONObject;
      trialPeriod: number;
      isMain: boolean;
      subscriptionPlanType: number;
    }>;
  };
};

export type SubscriptionPlansCourierQueryVariables = Exact<{ [key: string]: never }>;

export type SubscriptionPlansCourierQuery = {
  __typename?: 'Query';
  subscriptionPlansCourier: {
    __typename?: 'SubscriptionPlanPaddle';
    month: Array<{
      __typename?: 'SubscriptionPlanPaddleType';
      description: JSONObject;
      featureDescription: Array<JSONObject>;
      name: JSONObject;
      planId: number;
      prices: Array<JSONObject>;
      recurringInterval: JSONObject;
      trialPeriod: number;
      isMain: boolean;
      subscriptionPlanType: number;
    }>;
    year: Array<{
      __typename?: 'SubscriptionPlanPaddleType';
      description: JSONObject;
      featureDescription: Array<JSONObject>;
      name: JSONObject;
      planId: number;
      prices: Array<JSONObject>;
      recurringInterval: JSONObject;
      trialPeriod: number;
      isMain: boolean;
      subscriptionPlanType: number;
    }>;
  };
};

export type OrganizationSubscriptionPlansQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type OrganizationSubscriptionPlansQuery = {
  __typename?: 'Query';
  organization: {
    __typename?: 'Organization';
    id: string;
    name: string;
    shortName?: string | null;
    legalName: string;
    organizationType: OrganizationType;
    description?: string | null;
    email?: string | null;
    legalEntityType?: string | null;
    phone?: string | null;
    vatId?: string | null;
    registrationNumber?: string | null;
    organizationSubscriptionPlan?: {
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      isActive: boolean;
      startTime: DateTime;
      endTime: DateTime;
      isMain: boolean;
      recurringInterval?: string | null;
      status: string;
      previousPaymentSubscriptionId: string;
      prices?: Array<{
        __typename?: 'SubscriptionPlanPrice';
        currency: string;
        recurringInterval: string;
        value: string;
      }> | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        value: SubscriptionPlanType;
        planName: { __typename?: 'SubscriptionPlanName'; key: string; defaultValue: string };
        subscriptionFeatures?: Array<{
          __typename?: 'SubscriptionFeature';
          id: string;
          name: string;
          count: number;
          value: SubscriptionFeatureType;
        }> | null;
      };
    } | null;
    organizationSubscriptionPlans?: Array<{
      __typename?: 'OrganizationSubscriptionPlan';
      id: string;
      isActive: boolean;
      startTime: DateTime;
      endTime: DateTime;
      isMain: boolean;
      recurringInterval?: string | null;
      status: string;
      prices?: Array<{
        __typename?: 'SubscriptionPlanPrice';
        currency: string;
        recurringInterval: string;
        value: string;
      }> | null;
      subscriptionPlan: {
        __typename?: 'SubscriptionPlan';
        id: string;
        value: SubscriptionPlanType;
        planName: { __typename?: 'SubscriptionPlanName'; key: string; defaultValue: string };
        subscriptionFeatures?: Array<{
          __typename?: 'SubscriptionFeature';
          id: string;
          name: string;
          count: number;
          value: SubscriptionFeatureType;
        }> | null;
      };
    }> | null;
  };
};

export type AirportLocationListPaginatedQueryVariables = Exact<{
  data?: InputMaybe<AirportLocationSearchInput>;
  orderBy?: InputMaybe<OrderArgs>;
  pagination?: InputMaybe<PaginationArgs>;
}>;

export type AirportLocationListPaginatedQuery = {
  __typename?: 'Query';
  airportLocationListPaginated: {
    __typename?: 'AirportLocationConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'LocationEdge';
      node: {
        __typename?: 'Location';
        id: string;
        iataCode?: string | null;
        formattedAddress?: string | null;
        placeId?: string | null;
        timeZoneIdentifier?: string | null;
        timeZoneRawOffset?: number | null;
        locationGeometry: {
          __typename?: 'LocationGeometry';
          location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
        };
      };
    }> | null;
  };
};

export type AirportLocationListQueryVariables = Exact<{
  data?: InputMaybe<AirportLocationListInput>;
}>;

export type AirportLocationListQuery = {
  __typename?: 'Query';
  airportLocationList: Array<{
    __typename?: 'Location';
    id: string;
    iataCode?: string | null;
    formattedAddress?: string | null;
    placeId?: string | null;
    timeZoneIdentifier?: string | null;
    timeZoneRawOffset?: number | null;
    locationGeometry: {
      __typename?: 'LocationGeometry';
      location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
    };
  }>;
};

export type AirportLocationSearchQueryVariables = Exact<{
  data: AirportLocationSearchInput;
}>;

export type AirportLocationSearchQuery = {
  __typename?: 'Query';
  airportLocationSearch: Array<{
    __typename?: 'Location';
    id: string;
    iataCode?: string | null;
    formattedAddress?: string | null;
    placeId?: string | null;
    timeZoneIdentifier?: string | null;
    timeZoneRawOffset?: number | null;
    locationGeometry: {
      __typename?: 'LocationGeometry';
      location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
    };
  }>;
};

export type AvailabilitiesForConnectedCourierOrganizationsQueryVariables = Exact<{
  agencyOrganizationId: Scalars['String']['input'];
  filterType?: InputMaybe<Array<AvailabilityFilterType> | AvailabilityFilterType>;
}>;

export type AvailabilitiesForConnectedCourierOrganizationsQuery = {
  __typename?: 'Query';
  availabilitiesForConnectedCourierOrganizations: Array<{
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    createdAt: DateTime;
    lastLocation?: {
      __typename?: 'Location';
      id: string;
      timeZoneIdentifier?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    user: { __typename?: 'User'; id: string; firstNames: string; lastName: string };
  }>;
};

export type AvailabilityQueryVariables = Exact<{
  availabilityId: Scalars['String']['input'];
  agencyOrganizationId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AvailabilityQuery = {
  __typename?: 'Query';
  availability: {
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    timeZoneIdentifier?: string | null;
    timeZoneDestinationOffset?: number | null;
    timeZoneRawOffset?: number | null;
    createdAt: DateTime;
    lastLocation?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      timeZoneIdentifier?: string | null;
      timeZoneRawOffset?: number | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
    user: {
      __typename?: 'User';
      id: string;
      email: string;
      firstNames: string;
      lastName: string;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      courierUserProfile?: {
        __typename?: 'CourierUserProfile';
        id: string;
        baseAirports?: Array<string> | null;
      } | null;
      organizationUsers: Array<{
        __typename?: 'OrganizationUser';
        id: string;
        organization: { __typename?: 'Organization'; id: string };
      }>;
      agencyCourierUserProfile?: {
        __typename?: 'AgencyCourierUserProfile';
        internalComment?: string | null;
        markedForAttention: boolean;
        organizationCourierCategories?: Array<{
          __typename?: 'OrganizationCourierCategory';
          id: string;
          label: string;
          order: number;
        }> | null;
      } | null;
    };
  };
};

export type OrganizationCourierCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationCourierCategoriesQuery = {
  __typename?: 'Query';
  organizationCourierCategories: Array<{
    __typename?: 'OrganizationCourierCategory';
    id: string;
    order: number;
    label: string;
  }>;
};

export type OrganizationInvitationUploadCsvQueryVariables = Exact<{
  data: OrganizationInvitationUploadCsvInput;
}>;

export type OrganizationInvitationUploadCsvQuery = {
  __typename?: 'Query';
  organizationInvitationUploadCSV: {
    __typename?: 'OrganizationInvitationUploadCSV';
    organizationInvitationsNotUsed: Array<string>;
    organizationInvitationsUsed: Array<string>;
    contacts: Array<{
      __typename?: 'OrganizationInvitationUploadCSVContact';
      firstName: string;
      firstNameError?: string | null;
      lastName: string;
      lastNameError?: string | null;
      fullName: string;
      email: string;
      emailError?: string | null;
      phoneNumber: string;
      phoneNumberError?: string | null;
      status: string;
    }>;
  };
};

export type NotificationSettingCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationSettingCurrentUserQuery = {
  __typename?: 'Query';
  notificationSettingCurrentUser: {
    __typename?: 'NotificationSetting';
    id: string;
    emailConnection: boolean;
    emailJob: boolean;
    pushAvailability: boolean;
    pushChat: boolean;
    pushConnection: boolean;
    pushJob: boolean;
    systemAvailability: boolean;
    systemChat: boolean;
    systemConnection: boolean;
    systemJob: boolean;
  };
};

export type AvailabilityFindActiveAdhocCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type AvailabilityFindActiveAdhocCurrentUserQuery = {
  __typename?: 'Query';
  availabilityFindActiveAdhocCurrentUser?: {
    __typename?: 'Availability';
    id: string;
    isAdHocAvailability: boolean;
    startTime: DateTime;
    endTime?: DateTime | null;
    updatedAt: DateTime;
    lastLocationForDevice?: {
      __typename?: 'Location';
      id: string;
      formattedAddress?: string | null;
      formattedMinimalAddress?: string | null;
      locationGeometry: {
        __typename?: 'LocationGeometry';
        location: { __typename?: 'LocationGeometryLocation'; lat: number; lng: number };
      };
    } | null;
  } | null;
};

export type AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
  filterType?: InputMaybe<Array<JobAvailabilityFilterType> | JobAvailabilityFilterType>;
}>;

export type AvailableCourierUsersByDistanceForAgencyJobFilteredQuery = {
  __typename?: 'Query';
  availableCourierUsersByDistanceForAgencyJobFiltered: Array<{
    __typename?: 'AvailableCourierOrganizationUsersWithDistance';
    user: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      email: string;
      phone?: string | null;
      courierUserProfile?: {
        __typename?: 'CourierUserProfile';
        id: string;
        baseAirports?: Array<string> | null;
      } | null;
      agencyCourierUserProfile?: {
        __typename?: 'AgencyCourierUserProfile';
        internalComment?: string | null;
        markedForAttention: boolean;
        organizationCourierCategories?: Array<{
          __typename?: 'OrganizationCourierCategory';
          id: string;
          order: number;
          label: string;
        }> | null;
      } | null;
    };
    organizationUsers: Array<{
      __typename?: 'OrganizationUser';
      id: string;
      roleType: OrganizationUserRoleType;
      organization: {
        __typename?: 'Organization';
        id: string;
        name: string;
        legalName: string;
        shortName?: string | null;
        organizationType: OrganizationType;
      };
      user: { __typename?: 'User'; id: string };
    }>;
    courierJob?: {
      __typename?: 'CourierJob';
      id: string;
      relationshipType: CourierJobAgencyRelationshipType;
      declineReasonType?: AgencyDeclinedReasonType | null;
      isActive: boolean;
    } | null;
    availabilityLocationsWithDistance: Array<{
      __typename?: 'AvailabilityLocationWithDistance';
      groupType?: string | null;
      distance: number;
      availabilityLocation: {
        __typename?: 'AvailabilityLocation';
        id: string;
        timestamp?: DateTime | null;
        availability: {
          __typename?: 'Availability';
          id: string;
          isAdHocAvailability: boolean;
          startTime: DateTime;
          endTime?: DateTime | null;
          timeZoneIdentifier?: string | null;
        };
        location: {
          __typename?: 'Location';
          id: string;
          formattedShortAddress?: string | null;
          formattedMinimalAddress?: string | null;
          formattedAddress?: string | null;
          timeZoneIdentifier?: string | null;
        };
      };
    }>;
    displayAvailabilityLocationWithDistance?: {
      __typename?: 'AvailabilityLocationWithDistance';
      distance: number;
      availabilityLocation: {
        __typename?: 'AvailabilityLocation';
        id: string;
        timestamp?: DateTime | null;
        availability: {
          __typename?: 'Availability';
          id: string;
          isAdHocAvailability: boolean;
          startTime: DateTime;
          endTime?: DateTime | null;
          timeZoneIdentifier?: string | null;
        };
        location: {
          __typename?: 'Location';
          id: string;
          formattedShortAddress?: string | null;
          formattedMinimalAddress?: string | null;
          formattedAddress?: string | null;
          timeZoneIdentifier?: string | null;
        };
      };
    } | null;
    lastAvailabilityLocationWithDistance?: {
      __typename?: 'AvailabilityLocationWithDistance';
      distance: number;
      availabilityLocation: {
        __typename?: 'AvailabilityLocation';
        id: string;
        timestamp?: DateTime | null;
        availability: {
          __typename?: 'Availability';
          id: string;
          isAdHocAvailability: boolean;
          startTime: DateTime;
          endTime?: DateTime | null;
          timeZoneIdentifier?: string | null;
        };
        location: {
          __typename?: 'Location';
          id: string;
          formattedShortAddress?: string | null;
          formattedMinimalAddress?: string | null;
          formattedAddress?: string | null;
          timeZoneIdentifier?: string | null;
        };
      };
    } | null;
  }>;
};

export type AgencyJobPlanningForAgencyJobQueryVariables = Exact<{
  agencyJobId: Scalars['String']['input'];
}>;

export type AgencyJobPlanningForAgencyJobQuery = {
  __typename?: 'Query';
  agencyJobPlanningForAgencyJob: {
    __typename?: 'AgencyJobPlanning';
    id: string;
    agencyJobPlanningItems: Array<{
      __typename?: 'AgencyJobPlanningItem';
      id: string;
      active: boolean;
      actualDateTime?: DateTime | null;
      actualDateTimeSetAt?: DateTime | null;
      updatedAt: DateTime;
      formType: AgencyJobPlanningFormType;
      inactivatedAt?: DateTime | null;
      label: string;
      name: string;
      order: number;
      targetDateTime?: DateTime | null;
      files: Array<{
        __typename?: 'AgencyJobPlanningFile';
        id: string;
        storedAsset?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      }>;
      location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
    }>;
    agencyJobPlanningLegs: Array<{
      __typename?: 'AgencyJobPlanningLeg';
      id: string;
      active: boolean;
      departureDate?: DateTime | null;
      flightCarrierDesignation?: string | null;
      flightNumber?: string | null;
      order: number;
      updatedAt: DateTime;
      flightTickets: Array<{
        __typename?: 'AgencyJobPlanningFile';
        id: string;
        storedAsset?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      }>;
      agencyJobPlanningItems: Array<{
        __typename?: 'AgencyJobPlanningItem';
        id: string;
        active: boolean;
        actualDateTime?: DateTime | null;
        actualDateTimeSetAt?: DateTime | null;
        createdAt: DateTime;
        formType: AgencyJobPlanningFormType;
        inactivatedAt?: DateTime | null;
        label: string;
        name: string;
        order: number;
        targetDateTime?: DateTime | null;
        updatedAt: DateTime;
        files: Array<{
          __typename?: 'AgencyJobPlanningFile';
          id: string;
          storedAsset?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        }>;
        location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
      }>;
    }>;
  };
};

export type ChatMessagesByChatIdForAgencyQueryVariables = Exact<{
  chatMessagesByChatIdInput: ChatMessagesByChatIdInput;
  pagination?: InputMaybe<PaginationArgs>;
  orderBy?: InputMaybe<OrderArgs>;
}>;

export type ChatMessagesByChatIdForAgencyQuery = {
  __typename?: 'Query';
  chatMessagesByChatId: {
    __typename?: 'ChatMessageConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ChatMessageEdge';
      cursor: string;
      node: {
        __typename?: 'ChatMessage';
        id: string;
        message?: string | null;
        messageType: MessageType;
        createdAt: DateTime;
        updatedAt: DateTime;
        agencyJobPlanningItemHistoryLog?: {
          __typename?: 'AgencyJobPlanningItemHistoryLog';
          id: string;
          active: boolean;
          actualDateTime?: DateTime | null;
          label: string;
          targetDateTime?: DateTime | null;
          note?: string | null;
          agencyJobPlanningLeg?: {
            __typename?: 'AgencyJobPlanningLeg';
            id: string;
            flightCarrierDesignation?: string | null;
            flightNumber?: string | null;
            order: number;
          } | null;
          files: Array<{
            __typename?: 'AgencyJobPlanningFile';
            id: string;
            storedAsset?: {
              __typename?: 'StoredAsset';
              sasUrl: string;
              cacheKey: string;
              cacheTTL?: DateTime | null;
              metadata: {
                __typename?: 'StoredAssetMetadata';
                fileName?: string | null;
                mimeType?: string | null;
                height?: number | null;
                width?: number | null;
                size?: number | null;
              };
            } | null;
          }>;
          location?: {
            __typename?: 'Location';
            id: string;
            formattedAddress?: string | null;
          } | null;
        } | null;
        chatFile?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
        sendingUser: {
          __typename?: 'User';
          id: string;
          firstNames: string;
          lastName: string;
          profilePicture?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            cacheKey: string;
            cacheTTL?: DateTime | null;
            sasToken: {
              __typename?: 'SasTokenResponse';
              version?: string | null;
              signature?: string | null;
              expiresOn?: string | null;
              permissions?: string | null;
              resource?: string | null;
            };
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        };
      };
    }> | null;
  };
};

export type ChatMessageEdgeForAgencyQueryVariables = Exact<{
  chatMessageId: Scalars['String']['input'];
}>;

export type ChatMessageEdgeForAgencyQuery = {
  __typename?: 'Query';
  chatMessageEdge: {
    __typename?: 'ChatMessageCreateEdge';
    cursor: string;
    node: {
      __typename?: 'ChatMessage';
      id: string;
      message?: string | null;
      messageType: MessageType;
      createdAt: DateTime;
      updatedAt: DateTime;
      agencyJobPlanningItemHistoryLog?: {
        __typename?: 'AgencyJobPlanningItemHistoryLog';
        id: string;
        active: boolean;
        actualDateTime?: DateTime | null;
        label: string;
        targetDateTime?: DateTime | null;
        note?: string | null;
        agencyJobPlanningLeg?: {
          __typename?: 'AgencyJobPlanningLeg';
          id: string;
          flightCarrierDesignation?: string | null;
          flightNumber?: string | null;
          order: number;
        } | null;
        files: Array<{
          __typename?: 'AgencyJobPlanningFile';
          id: string;
          storedAsset?: {
            __typename?: 'StoredAsset';
            sasUrl: string;
            cacheKey: string;
            cacheTTL?: DateTime | null;
            metadata: {
              __typename?: 'StoredAssetMetadata';
              fileName?: string | null;
              mimeType?: string | null;
              height?: number | null;
              width?: number | null;
              size?: number | null;
            };
          } | null;
        }>;
        location?: { __typename?: 'Location'; id: string; formattedAddress?: string | null } | null;
      } | null;
      chatFile?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
      sendingUser: {
        __typename?: 'User';
        id: string;
        firstNames: string;
        lastName: string;
        profilePicture?: {
          __typename?: 'StoredAsset';
          sasUrl: string;
          cacheKey: string;
          cacheTTL?: DateTime | null;
          sasToken: {
            __typename?: 'SasTokenResponse';
            version?: string | null;
            signature?: string | null;
            expiresOn?: string | null;
            permissions?: string | null;
            resource?: string | null;
          };
          metadata: {
            __typename?: 'StoredAssetMetadata';
            fileName?: string | null;
            mimeType?: string | null;
            height?: number | null;
            width?: number | null;
            size?: number | null;
          };
        } | null;
      };
    };
  };
};

export type OnChatMessageSentSubscriptionVariables = Exact<{
  chatId: Scalars['String']['input'];
}>;

export type OnChatMessageSentSubscription = {
  __typename?: 'Subscription';
  chatMessageSent: {
    __typename?: 'ChatMessage';
    id: string;
    messageType: MessageType;
    createdAt: DateTime;
    message?: string | null;
    chatFile?: {
      __typename?: 'StoredAsset';
      sasUrl: string;
      cacheKey: string;
      cacheTTL?: DateTime | null;
      sasToken: {
        __typename?: 'SasTokenResponse';
        version?: string | null;
        signature?: string | null;
        expiresOn?: string | null;
        permissions?: string | null;
        resource?: string | null;
      };
      metadata: {
        __typename?: 'StoredAssetMetadata';
        fileName?: string | null;
        mimeType?: string | null;
        height?: number | null;
        width?: number | null;
        size?: number | null;
      };
    } | null;
    sendingUser: {
      __typename?: 'User';
      id: string;
      firstNames: string;
      lastName: string;
      profilePicture?: {
        __typename?: 'StoredAsset';
        sasUrl: string;
        cacheKey: string;
        cacheTTL?: DateTime | null;
        sasToken: {
          __typename?: 'SasTokenResponse';
          version?: string | null;
          signature?: string | null;
          expiresOn?: string | null;
          permissions?: string | null;
          resource?: string | null;
        };
        metadata: {
          __typename?: 'StoredAssetMetadata';
          fileName?: string | null;
          mimeType?: string | null;
          height?: number | null;
          width?: number | null;
          size?: number | null;
        };
      } | null;
    };
  };
};

export const NewUserTravelDocumentFragmentDoc = gql`
  fragment NewUserTravelDocument on UserTravelDocument {
    id
    firstNames
    lastName
    placeOfBirth
    sex
    dateOfBirth
    documentType
    documentNumber
    dateOfIssue
    countryOfIssue
    expiresOn
    nationality
    multipleEntriesAllowed
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewUserIdentityDocumentFragmentDoc = gql`
  fragment NewUserIdentityDocument on UserIdentityDocument {
    id
    firstNames
    lastName
    placeOfBirth
    sex
    dateOfBirth
    documentType
    documentSubType
    documentSubTypeMulti
    documentNumber
    dateOfIssue
    countryOfIssue
    expiresOn
    nationality
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewUserFrequentFlyerDocumentFragmentDoc = gql`
  fragment NewUserFrequentFlyerDocument on UserFrequentFlyerDocument {
    id
    airlineType
    airlineStatusType
    airlineAllianceType
    airlineAllianceStatusType
    documentNumber
    expiresOn
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewUserVaccinationDocumentFragmentDoc = gql`
  fragment NewUserVaccinationDocument on UserVaccinationDocument {
    id
    diseaseType
    description
    vaccinationType
    vaccinationManufacturer
    dateOfVaccination
    isVaccinationComplete
    vaccinationCenter
    cityOfVaccination
    countryOfVaccination
    expiresOn
    documentNumber
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewUserHealthReportFragmentDoc = gql`
  fragment NewUserHealthReport on UserHealthReport {
    id
    diseaseType
    description
    testType
    dateTimeOfTest
    isResultPositive
    testCenter
    cityOfTest
    countryOfTest
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewUserBackgroundDocumentFragmentDoc = gql`
  fragment NewUserBackgroundDocument on UserBackgroundDocument {
    id
    name
    issuingInstitution
    countryOfIssue
    dateOfIssue
    expiresOn
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewUserOtherDocumentFragmentDoc = gql`
  fragment NewUserOtherDocument on UserOtherDocument {
    id
    name
    description
    countryOfIssue
    placeOfIssue
    dateOfIssue
    expiresOn
    documentContainerName
    originalDocumentFrontFilePath
    originalDocumentBackFilePath
    compressedDocumentFrontFilePath
    compressedDocumentBackFilePath
    updatedAt
    createdAt
    verifiedOn
    verificationStatus
    verificationComments
    documentFrontFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentFrontFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureCompressed {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
    documentBackFromAzureOriginal {
      sasUrl
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewChatMessageFragmentDoc = gql`
  fragment NewChatMessage on ChatMessage {
    id
    messageType
    createdAt
    message
    sendingUser {
      id
      firstNames
      lastName
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export const NewAgencyOrganizationProfileFragmentDoc = gql`
  fragment NewAgencyOrganizationProfile on Organization {
    id
    name
    legalName
    organizationType
    description
    email
    legalEntityType
    phone
    vatId
    registrationNumber
    socialProfiles {
      platform
      type
      value
    }
    headquartersAddressLocation {
      id
      placeId
      formattedAddress
    }
    invoicingAddressLocation {
      id
      placeId
      formattedAddress
    }
    registrationAddressLocation {
      id
      placeId
      formattedAddress
    }
    logo {
      sasUrl
      cacheKey
      cacheTTL
      sasToken {
        version
        signature
        expiresOn
        permissions
        resource
      }
      metadata {
        fileName
        mimeType
        height
        width
        size
      }
    }
  }
`;
export const NewCourierUserProfileFragmentDoc = gql`
  fragment NewCourierUserProfile on CourierUserProfile {
    id
    emergencyContactRelationship
    emergencyContactPhone
    emergencyContactName
    emergencyContactEmail
    emergencyContactAddress
    hasAmexCreditCard
    hasDinerCreditCard
    hasMasterCreditCard
    hasVisaCreditCard
    hasAmexDebitCard
    hasDinerDebitCard
    hasMasterDebitCard
    hasVisaDebitCard
    masterCreditCardLimit
    visaCreditCardLimit
    dinerCreditCardLimit
    amexCreditCardLimit
    languagesKnown {
      language
      level
    }
    onboardExperiences
    address
    street
    apartmentOrSuite
    city
    zipcode
    country
    socialProfiles {
      platform
      type
      value
    }
  }
`;
export const MarkAllNewSystemNotificationsAsReadForOrganizationUserDocument = gql`
  mutation MarkAllNewSystemNotificationsAsReadForOrganizationUser($organizationUserId: String!) {
    markAllNewSystemNotificationsAsReadForOrganizationUser(organizationUserId: $organizationUserId)
  }
`;
export type MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationFn =
  Apollo.MutationFunction<
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables
  >;
export type MarkAllNewSystemNotificationsAsReadForOrganizationUserProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables
  >;
} & TChildProps;
export function withMarkAllNewSystemNotificationsAsReadForOrganizationUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserProps<TChildProps, TDataName>
  >(MarkAllNewSystemNotificationsAsReadForOrganizationUserDocument, {
    alias: 'markAllNewSystemNotificationsAsReadForOrganizationUser',
    ...operationOptions,
  });
}

/**
 * __useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation__
 *
 * To run a mutation, you first call `useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNewSystemNotificationsAsReadForOrganizationUserMutation, { data, loading, error }] = useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation({
 *   variables: {
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables
  >(MarkAllNewSystemNotificationsAsReadForOrganizationUserDocument, options);
}
export type MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationHookResult = ReturnType<
  typeof useMarkAllNewSystemNotificationsAsReadForOrganizationUserMutation
>;
export type MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationResult =
  Apollo.MutationResult<MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation>;
export type MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationOptions =
  Apollo.BaseMutationOptions<
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutation,
    MarkAllNewSystemNotificationsAsReadForOrganizationUserMutationVariables
  >;
export const MarkSystemNotificationAsReadForOrganizationUserDocument = gql`
  mutation MarkSystemNotificationAsReadForOrganizationUser(
    $notificationId: String!
    $organizationUserId: String!
  ) {
    markSystemNotificationAsReadForOrganizationUser(
      notificationId: $notificationId
      organizationUserId: $organizationUserId
    )
  }
`;
export type MarkSystemNotificationAsReadForOrganizationUserMutationFn = Apollo.MutationFunction<
  MarkSystemNotificationAsReadForOrganizationUserMutation,
  MarkSystemNotificationAsReadForOrganizationUserMutationVariables
>;
export type MarkSystemNotificationAsReadForOrganizationUserProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    MarkSystemNotificationAsReadForOrganizationUserMutation,
    MarkSystemNotificationAsReadForOrganizationUserMutationVariables
  >;
} & TChildProps;
export function withMarkSystemNotificationAsReadForOrganizationUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MarkSystemNotificationAsReadForOrganizationUserMutation,
    MarkSystemNotificationAsReadForOrganizationUserMutationVariables,
    MarkSystemNotificationAsReadForOrganizationUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    MarkSystemNotificationAsReadForOrganizationUserMutation,
    MarkSystemNotificationAsReadForOrganizationUserMutationVariables,
    MarkSystemNotificationAsReadForOrganizationUserProps<TChildProps, TDataName>
  >(MarkSystemNotificationAsReadForOrganizationUserDocument, {
    alias: 'markSystemNotificationAsReadForOrganizationUser',
    ...operationOptions,
  });
}

/**
 * __useMarkSystemNotificationAsReadForOrganizationUserMutation__
 *
 * To run a mutation, you first call `useMarkSystemNotificationAsReadForOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSystemNotificationAsReadForOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSystemNotificationAsReadForOrganizationUserMutation, { data, loading, error }] = useMarkSystemNotificationAsReadForOrganizationUserMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useMarkSystemNotificationAsReadForOrganizationUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkSystemNotificationAsReadForOrganizationUserMutation,
    MarkSystemNotificationAsReadForOrganizationUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkSystemNotificationAsReadForOrganizationUserMutation,
    MarkSystemNotificationAsReadForOrganizationUserMutationVariables
  >(MarkSystemNotificationAsReadForOrganizationUserDocument, options);
}
export type MarkSystemNotificationAsReadForOrganizationUserMutationHookResult = ReturnType<
  typeof useMarkSystemNotificationAsReadForOrganizationUserMutation
>;
export type MarkSystemNotificationAsReadForOrganizationUserMutationResult =
  Apollo.MutationResult<MarkSystemNotificationAsReadForOrganizationUserMutation>;
export type MarkSystemNotificationAsReadForOrganizationUserMutationOptions =
  Apollo.BaseMutationOptions<
    MarkSystemNotificationAsReadForOrganizationUserMutation,
    MarkSystemNotificationAsReadForOrganizationUserMutationVariables
  >;
export const ConnectWithAgencyOrganizationAsCourierOrganizationDocument = gql`
  mutation ConnectWithAgencyOrganizationAsCourierOrganization(
    $courierOrganizationId: String!
    $activationCode: String!
    $agencyOrganizationId: String!
  ) {
    connectWithAgencyOrganizationAsCourierOrganization(
      courierOrganizationId: $courierOrganizationId
      activationCode: $activationCode
      agencyOrganizationId: $agencyOrganizationId
    ) {
      id
      connectionType
      createdAt
      updatedAt
      connectingOrganization {
        id
        name
        shortName
      }
      connectedOrganization {
        id
        name
        shortName
      }
    }
  }
`;
export type ConnectWithAgencyOrganizationAsCourierOrganizationMutationFn = Apollo.MutationFunction<
  ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
  ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables
>;
export type ConnectWithAgencyOrganizationAsCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables
  >;
} & TChildProps;
export function withConnectWithAgencyOrganizationAsCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables,
    ConnectWithAgencyOrganizationAsCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables,
    ConnectWithAgencyOrganizationAsCourierOrganizationProps<TChildProps, TDataName>
  >(ConnectWithAgencyOrganizationAsCourierOrganizationDocument, {
    alias: 'connectWithAgencyOrganizationAsCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useConnectWithAgencyOrganizationAsCourierOrganizationMutation__
 *
 * To run a mutation, you first call `useConnectWithAgencyOrganizationAsCourierOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectWithAgencyOrganizationAsCourierOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectWithAgencyOrganizationAsCourierOrganizationMutation, { data, loading, error }] = useConnectWithAgencyOrganizationAsCourierOrganizationMutation({
 *   variables: {
 *      courierOrganizationId: // value for 'courierOrganizationId'
 *      activationCode: // value for 'activationCode'
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *   },
 * });
 */
export function useConnectWithAgencyOrganizationAsCourierOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables
  >(ConnectWithAgencyOrganizationAsCourierOrganizationDocument, options);
}
export type ConnectWithAgencyOrganizationAsCourierOrganizationMutationHookResult = ReturnType<
  typeof useConnectWithAgencyOrganizationAsCourierOrganizationMutation
>;
export type ConnectWithAgencyOrganizationAsCourierOrganizationMutationResult =
  Apollo.MutationResult<ConnectWithAgencyOrganizationAsCourierOrganizationMutation>;
export type ConnectWithAgencyOrganizationAsCourierOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    ConnectWithAgencyOrganizationAsCourierOrganizationMutation,
    ConnectWithAgencyOrganizationAsCourierOrganizationMutationVariables
  >;
export const AddDeviceLogUnauthenticatedDocument = gql`
  mutation AddDeviceLogUnauthenticated($addDeviceLogInputs: [AddDeviceLogInput!]!) {
    addDeviceLogUnauthenticated(addDeviceLogInputs: $addDeviceLogInputs) {
      deviceLogIds
      lastDeviceLog {
        id
        data {
          uptime
        }
      }
    }
  }
`;
export type AddDeviceLogUnauthenticatedMutationFn = Apollo.MutationFunction<
  AddDeviceLogUnauthenticatedMutation,
  AddDeviceLogUnauthenticatedMutationVariables
>;
export type AddDeviceLogUnauthenticatedProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AddDeviceLogUnauthenticatedMutation,
    AddDeviceLogUnauthenticatedMutationVariables
  >;
} & TChildProps;
export function withAddDeviceLogUnauthenticated<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddDeviceLogUnauthenticatedMutation,
    AddDeviceLogUnauthenticatedMutationVariables,
    AddDeviceLogUnauthenticatedProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddDeviceLogUnauthenticatedMutation,
    AddDeviceLogUnauthenticatedMutationVariables,
    AddDeviceLogUnauthenticatedProps<TChildProps, TDataName>
  >(AddDeviceLogUnauthenticatedDocument, {
    alias: 'addDeviceLogUnauthenticated',
    ...operationOptions,
  });
}

/**
 * __useAddDeviceLogUnauthenticatedMutation__
 *
 * To run a mutation, you first call `useAddDeviceLogUnauthenticatedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceLogUnauthenticatedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceLogUnauthenticatedMutation, { data, loading, error }] = useAddDeviceLogUnauthenticatedMutation({
 *   variables: {
 *      addDeviceLogInputs: // value for 'addDeviceLogInputs'
 *   },
 * });
 */
export function useAddDeviceLogUnauthenticatedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDeviceLogUnauthenticatedMutation,
    AddDeviceLogUnauthenticatedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddDeviceLogUnauthenticatedMutation,
    AddDeviceLogUnauthenticatedMutationVariables
  >(AddDeviceLogUnauthenticatedDocument, options);
}
export type AddDeviceLogUnauthenticatedMutationHookResult = ReturnType<
  typeof useAddDeviceLogUnauthenticatedMutation
>;
export type AddDeviceLogUnauthenticatedMutationResult =
  Apollo.MutationResult<AddDeviceLogUnauthenticatedMutation>;
export type AddDeviceLogUnauthenticatedMutationOptions = Apollo.BaseMutationOptions<
  AddDeviceLogUnauthenticatedMutation,
  AddDeviceLogUnauthenticatedMutationVariables
>;
export const AddDeviceLogDocument = gql`
  mutation AddDeviceLog($addDeviceLogInputs: [AddDeviceLogInput!]!) {
    addDeviceLog(addDeviceLogInputs: $addDeviceLogInputs) {
      deviceLogIds
      lastDeviceLog {
        id
        data {
          uptime
        }
      }
    }
  }
`;
export type AddDeviceLogMutationFn = Apollo.MutationFunction<
  AddDeviceLogMutation,
  AddDeviceLogMutationVariables
>;
export type AddDeviceLogProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<AddDeviceLogMutation, AddDeviceLogMutationVariables>;
} & TChildProps;
export function withAddDeviceLog<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddDeviceLogMutation,
    AddDeviceLogMutationVariables,
    AddDeviceLogProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddDeviceLogMutation,
    AddDeviceLogMutationVariables,
    AddDeviceLogProps<TChildProps, TDataName>
  >(AddDeviceLogDocument, {
    alias: 'addDeviceLog',
    ...operationOptions,
  });
}

/**
 * __useAddDeviceLogMutation__
 *
 * To run a mutation, you first call `useAddDeviceLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceLogMutation, { data, loading, error }] = useAddDeviceLogMutation({
 *   variables: {
 *      addDeviceLogInputs: // value for 'addDeviceLogInputs'
 *   },
 * });
 */
export function useAddDeviceLogMutation(
  baseOptions?: Apollo.MutationHookOptions<AddDeviceLogMutation, AddDeviceLogMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDeviceLogMutation, AddDeviceLogMutationVariables>(
    AddDeviceLogDocument,
    options,
  );
}
export type AddDeviceLogMutationHookResult = ReturnType<typeof useAddDeviceLogMutation>;
export type AddDeviceLogMutationResult = Apollo.MutationResult<AddDeviceLogMutation>;
export type AddDeviceLogMutationOptions = Apollo.BaseMutationOptions<
  AddDeviceLogMutation,
  AddDeviceLogMutationVariables
>;
export const ActivateAccountDocument = gql`
  mutation ActivateAccount($activationCode: String!) {
    activateAccount(activationCode: $activationCode) {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        endTime
        prices {
          currency
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;
export type ActivateAccountMutationFn = Apollo.MutationFunction<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;
export type ActivateAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
  >;
} & TChildProps;
export function withActivateAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ActivateAccountMutation,
    ActivateAccountMutationVariables,
    ActivateAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ActivateAccountMutation,
    ActivateAccountMutationVariables,
    ActivateAccountProps<TChildProps, TDataName>
  >(ActivateAccountDocument, {
    alias: 'activateAccount',
    ...operationOptions,
  });
}

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useActivateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateAccountMutation,
    ActivateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
    ActivateAccountDocument,
    options,
  );
}
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;
export const FindOrCreateAdHocAvailabilityDocument = gql`
  mutation FindOrCreateAdHocAvailability($deviceLocationId: String) {
    findOrCreateAdHocAvailability(deviceLocationId: $deviceLocationId) {
      id
      lastLocationForDevice {
        id
        formattedAddress
        formattedMinimalAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      isAdHocAvailability
      startTime
      endTime
      updatedAt
    }
  }
`;
export type FindOrCreateAdHocAvailabilityMutationFn = Apollo.MutationFunction<
  FindOrCreateAdHocAvailabilityMutation,
  FindOrCreateAdHocAvailabilityMutationVariables
>;
export type FindOrCreateAdHocAvailabilityProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    FindOrCreateAdHocAvailabilityMutation,
    FindOrCreateAdHocAvailabilityMutationVariables
  >;
} & TChildProps;
export function withFindOrCreateAdHocAvailability<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    FindOrCreateAdHocAvailabilityMutation,
    FindOrCreateAdHocAvailabilityMutationVariables,
    FindOrCreateAdHocAvailabilityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    FindOrCreateAdHocAvailabilityMutation,
    FindOrCreateAdHocAvailabilityMutationVariables,
    FindOrCreateAdHocAvailabilityProps<TChildProps, TDataName>
  >(FindOrCreateAdHocAvailabilityDocument, {
    alias: 'findOrCreateAdHocAvailability',
    ...operationOptions,
  });
}

/**
 * __useFindOrCreateAdHocAvailabilityMutation__
 *
 * To run a mutation, you first call `useFindOrCreateAdHocAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindOrCreateAdHocAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findOrCreateAdHocAvailabilityMutation, { data, loading, error }] = useFindOrCreateAdHocAvailabilityMutation({
 *   variables: {
 *      deviceLocationId: // value for 'deviceLocationId'
 *   },
 * });
 */
export function useFindOrCreateAdHocAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FindOrCreateAdHocAvailabilityMutation,
    FindOrCreateAdHocAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FindOrCreateAdHocAvailabilityMutation,
    FindOrCreateAdHocAvailabilityMutationVariables
  >(FindOrCreateAdHocAvailabilityDocument, options);
}
export type FindOrCreateAdHocAvailabilityMutationHookResult = ReturnType<
  typeof useFindOrCreateAdHocAvailabilityMutation
>;
export type FindOrCreateAdHocAvailabilityMutationResult =
  Apollo.MutationResult<FindOrCreateAdHocAvailabilityMutation>;
export type FindOrCreateAdHocAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  FindOrCreateAdHocAvailabilityMutation,
  FindOrCreateAdHocAvailabilityMutationVariables
>;
export const CreateOrUpdateCourierUserContactDocument = gql`
  mutation CreateOrUpdateCourierUserContact(
    $userContactInput: UserContactInput!
    $inviteUserContact: Boolean!
  ) {
    createOrUpdateCourierUserContact(
      userContactInput: $userContactInput
      inviteUserContact: $inviteUserContact
    ) {
      id
      expoContactId
      phone
      email
      firstNames
      lastName
    }
  }
`;
export type CreateOrUpdateCourierUserContactMutationFn = Apollo.MutationFunction<
  CreateOrUpdateCourierUserContactMutation,
  CreateOrUpdateCourierUserContactMutationVariables
>;
export type CreateOrUpdateCourierUserContactProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOrUpdateCourierUserContactMutation,
    CreateOrUpdateCourierUserContactMutationVariables
  >;
} & TChildProps;
export function withCreateOrUpdateCourierUserContact<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrUpdateCourierUserContactMutation,
    CreateOrUpdateCourierUserContactMutationVariables,
    CreateOrUpdateCourierUserContactProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrUpdateCourierUserContactMutation,
    CreateOrUpdateCourierUserContactMutationVariables,
    CreateOrUpdateCourierUserContactProps<TChildProps, TDataName>
  >(CreateOrUpdateCourierUserContactDocument, {
    alias: 'createOrUpdateCourierUserContact',
    ...operationOptions,
  });
}

/**
 * __useCreateOrUpdateCourierUserContactMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCourierUserContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCourierUserContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCourierUserContactMutation, { data, loading, error }] = useCreateOrUpdateCourierUserContactMutation({
 *   variables: {
 *      userContactInput: // value for 'userContactInput'
 *      inviteUserContact: // value for 'inviteUserContact'
 *   },
 * });
 */
export function useCreateOrUpdateCourierUserContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateCourierUserContactMutation,
    CreateOrUpdateCourierUserContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateCourierUserContactMutation,
    CreateOrUpdateCourierUserContactMutationVariables
  >(CreateOrUpdateCourierUserContactDocument, options);
}
export type CreateOrUpdateCourierUserContactMutationHookResult = ReturnType<
  typeof useCreateOrUpdateCourierUserContactMutation
>;
export type CreateOrUpdateCourierUserContactMutationResult =
  Apollo.MutationResult<CreateOrUpdateCourierUserContactMutation>;
export type CreateOrUpdateCourierUserContactMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateCourierUserContactMutation,
  CreateOrUpdateCourierUserContactMutationVariables
>;
export const CreateAvailabilityDocument = gql`
  mutation CreateAvailability(
    $suggestedLocationInput: SuggestedLocationInput!
    $startTime: DateTime!
    $endTime: DateTime!
    $timeZoneIdentifier: String!
    $timeZoneDestinationOffset: Float!
    $timeZoneRawOffset: Float!
  ) {
    createAvailability(
      suggestedLocationInput: $suggestedLocationInput
      startTime: $startTime
      endTime: $endTime
      timeZoneIdentifier: $timeZoneIdentifier
      timeZoneDestinationOffset: $timeZoneDestinationOffset
      timeZoneRawOffset: $timeZoneRawOffset
    ) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      availabilityLocations {
        id
        location {
          id
          formattedAddress
          formattedMinimalAddress
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
      }
      lastDeviceLocationForDevice {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type CreateAvailabilityMutationFn = Apollo.MutationFunction<
  CreateAvailabilityMutation,
  CreateAvailabilityMutationVariables
>;
export type CreateAvailabilityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateAvailabilityMutation,
    CreateAvailabilityMutationVariables
  >;
} & TChildProps;
export function withCreateAvailability<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateAvailabilityMutation,
    CreateAvailabilityMutationVariables,
    CreateAvailabilityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateAvailabilityMutation,
    CreateAvailabilityMutationVariables,
    CreateAvailabilityProps<TChildProps, TDataName>
  >(CreateAvailabilityDocument, {
    alias: 'createAvailability',
    ...operationOptions,
  });
}

/**
 * __useCreateAvailabilityMutation__
 *
 * To run a mutation, you first call `useCreateAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAvailabilityMutation, { data, loading, error }] = useCreateAvailabilityMutation({
 *   variables: {
 *      suggestedLocationInput: // value for 'suggestedLocationInput'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      timeZoneIdentifier: // value for 'timeZoneIdentifier'
 *      timeZoneDestinationOffset: // value for 'timeZoneDestinationOffset'
 *      timeZoneRawOffset: // value for 'timeZoneRawOffset'
 *   },
 * });
 */
export function useCreateAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAvailabilityMutation,
    CreateAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAvailabilityMutation, CreateAvailabilityMutationVariables>(
    CreateAvailabilityDocument,
    options,
  );
}
export type CreateAvailabilityMutationHookResult = ReturnType<typeof useCreateAvailabilityMutation>;
export type CreateAvailabilityMutationResult = Apollo.MutationResult<CreateAvailabilityMutation>;
export type CreateAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  CreateAvailabilityMutation,
  CreateAvailabilityMutationVariables
>;
export const DeleteAvailabilityDocument = gql`
  mutation DeleteAvailability($availabilityId: String!) {
    deleteAvailability(availabilityId: $availabilityId) {
      id
    }
  }
`;
export type DeleteAvailabilityMutationFn = Apollo.MutationFunction<
  DeleteAvailabilityMutation,
  DeleteAvailabilityMutationVariables
>;
export type DeleteAvailabilityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteAvailabilityMutation,
    DeleteAvailabilityMutationVariables
  >;
} & TChildProps;
export function withDeleteAvailability<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteAvailabilityMutation,
    DeleteAvailabilityMutationVariables,
    DeleteAvailabilityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteAvailabilityMutation,
    DeleteAvailabilityMutationVariables,
    DeleteAvailabilityProps<TChildProps, TDataName>
  >(DeleteAvailabilityDocument, {
    alias: 'deleteAvailability',
    ...operationOptions,
  });
}

/**
 * __useDeleteAvailabilityMutation__
 *
 * To run a mutation, you first call `useDeleteAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAvailabilityMutation, { data, loading, error }] = useDeleteAvailabilityMutation({
 *   variables: {
 *      availabilityId: // value for 'availabilityId'
 *   },
 * });
 */
export function useDeleteAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAvailabilityMutation,
    DeleteAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAvailabilityMutation, DeleteAvailabilityMutationVariables>(
    DeleteAvailabilityDocument,
    options,
  );
}
export type DeleteAvailabilityMutationHookResult = ReturnType<typeof useDeleteAvailabilityMutation>;
export type DeleteAvailabilityMutationResult = Apollo.MutationResult<DeleteAvailabilityMutation>;
export type DeleteAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  DeleteAvailabilityMutation,
  DeleteAvailabilityMutationVariables
>;
export const DisableAdHocAvailabilityDocument = gql`
  mutation DisableAdHocAvailability {
    disableAdHocAvailability
  }
`;
export type DisableAdHocAvailabilityMutationFn = Apollo.MutationFunction<
  DisableAdHocAvailabilityMutation,
  DisableAdHocAvailabilityMutationVariables
>;
export type DisableAdHocAvailabilityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DisableAdHocAvailabilityMutation,
    DisableAdHocAvailabilityMutationVariables
  >;
} & TChildProps;
export function withDisableAdHocAvailability<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DisableAdHocAvailabilityMutation,
    DisableAdHocAvailabilityMutationVariables,
    DisableAdHocAvailabilityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DisableAdHocAvailabilityMutation,
    DisableAdHocAvailabilityMutationVariables,
    DisableAdHocAvailabilityProps<TChildProps, TDataName>
  >(DisableAdHocAvailabilityDocument, {
    alias: 'disableAdHocAvailability',
    ...operationOptions,
  });
}

/**
 * __useDisableAdHocAvailabilityMutation__
 *
 * To run a mutation, you first call `useDisableAdHocAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableAdHocAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableAdHocAvailabilityMutation, { data, loading, error }] = useDisableAdHocAvailabilityMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableAdHocAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableAdHocAvailabilityMutation,
    DisableAdHocAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisableAdHocAvailabilityMutation,
    DisableAdHocAvailabilityMutationVariables
  >(DisableAdHocAvailabilityDocument, options);
}
export type DisableAdHocAvailabilityMutationHookResult = ReturnType<
  typeof useDisableAdHocAvailabilityMutation
>;
export type DisableAdHocAvailabilityMutationResult =
  Apollo.MutationResult<DisableAdHocAvailabilityMutation>;
export type DisableAdHocAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  DisableAdHocAvailabilityMutation,
  DisableAdHocAvailabilityMutationVariables
>;
export const RegisterExistingUserWithOwnedOrganizationDocument = gql`
  mutation RegisterExistingUserWithOwnedOrganization($organizationType: OrganizationType!) {
    registerExistingUserWithOwnedOrganization(organizationType: $organizationType) {
      id
      user {
        id
        firstNames
        lastName
        email
      }
      organization {
        id
        organizationType
      }
      roleType
      createdAt
    }
  }
`;
export type RegisterExistingUserWithOwnedOrganizationMutationFn = Apollo.MutationFunction<
  RegisterExistingUserWithOwnedOrganizationMutation,
  RegisterExistingUserWithOwnedOrganizationMutationVariables
>;
export type RegisterExistingUserWithOwnedOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    RegisterExistingUserWithOwnedOrganizationMutation,
    RegisterExistingUserWithOwnedOrganizationMutationVariables
  >;
} & TChildProps;
export function withRegisterExistingUserWithOwnedOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RegisterExistingUserWithOwnedOrganizationMutation,
    RegisterExistingUserWithOwnedOrganizationMutationVariables,
    RegisterExistingUserWithOwnedOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RegisterExistingUserWithOwnedOrganizationMutation,
    RegisterExistingUserWithOwnedOrganizationMutationVariables,
    RegisterExistingUserWithOwnedOrganizationProps<TChildProps, TDataName>
  >(RegisterExistingUserWithOwnedOrganizationDocument, {
    alias: 'registerExistingUserWithOwnedOrganization',
    ...operationOptions,
  });
}

/**
 * __useRegisterExistingUserWithOwnedOrganizationMutation__
 *
 * To run a mutation, you first call `useRegisterExistingUserWithOwnedOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterExistingUserWithOwnedOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerExistingUserWithOwnedOrganizationMutation, { data, loading, error }] = useRegisterExistingUserWithOwnedOrganizationMutation({
 *   variables: {
 *      organizationType: // value for 'organizationType'
 *   },
 * });
 */
export function useRegisterExistingUserWithOwnedOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterExistingUserWithOwnedOrganizationMutation,
    RegisterExistingUserWithOwnedOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterExistingUserWithOwnedOrganizationMutation,
    RegisterExistingUserWithOwnedOrganizationMutationVariables
  >(RegisterExistingUserWithOwnedOrganizationDocument, options);
}
export type RegisterExistingUserWithOwnedOrganizationMutationHookResult = ReturnType<
  typeof useRegisterExistingUserWithOwnedOrganizationMutation
>;
export type RegisterExistingUserWithOwnedOrganizationMutationResult =
  Apollo.MutationResult<RegisterExistingUserWithOwnedOrganizationMutation>;
export type RegisterExistingUserWithOwnedOrganizationMutationOptions = Apollo.BaseMutationOptions<
  RegisterExistingUserWithOwnedOrganizationMutation,
  RegisterExistingUserWithOwnedOrganizationMutationVariables
>;
export const VoidOrganizationInvitationDocument = gql`
  mutation VoidOrganizationInvitation($id: String!) {
    voidOrganizationInvitation(id: $id) {
      id
      activationCode
      voidedAt
      updatedAt
    }
  }
`;
export type VoidOrganizationInvitationMutationFn = Apollo.MutationFunction<
  VoidOrganizationInvitationMutation,
  VoidOrganizationInvitationMutationVariables
>;
export type VoidOrganizationInvitationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    VoidOrganizationInvitationMutation,
    VoidOrganizationInvitationMutationVariables
  >;
} & TChildProps;
export function withVoidOrganizationInvitation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    VoidOrganizationInvitationMutation,
    VoidOrganizationInvitationMutationVariables,
    VoidOrganizationInvitationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    VoidOrganizationInvitationMutation,
    VoidOrganizationInvitationMutationVariables,
    VoidOrganizationInvitationProps<TChildProps, TDataName>
  >(VoidOrganizationInvitationDocument, {
    alias: 'voidOrganizationInvitation',
    ...operationOptions,
  });
}

/**
 * __useVoidOrganizationInvitationMutation__
 *
 * To run a mutation, you first call `useVoidOrganizationInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidOrganizationInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidOrganizationInvitationMutation, { data, loading, error }] = useVoidOrganizationInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVoidOrganizationInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoidOrganizationInvitationMutation,
    VoidOrganizationInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VoidOrganizationInvitationMutation,
    VoidOrganizationInvitationMutationVariables
  >(VoidOrganizationInvitationDocument, options);
}
export type VoidOrganizationInvitationMutationHookResult = ReturnType<
  typeof useVoidOrganizationInvitationMutation
>;
export type VoidOrganizationInvitationMutationResult =
  Apollo.MutationResult<VoidOrganizationInvitationMutation>;
export type VoidOrganizationInvitationMutationOptions = Apollo.BaseMutationOptions<
  VoidOrganizationInvitationMutation,
  VoidOrganizationInvitationMutationVariables
>;
export const CreateOrganizationInvitationSharingDocument = gql`
  mutation CreateOrganizationInvitationSharing(
    $organizationInvitationSharingInput: OrganizationInvitationSharingInput!
  ) {
    createOrganizationInvitationSharing(
      organizationInvitationSharingInput: $organizationInvitationSharingInput
    ) {
      id
      appName
      platformVersion
      platform
      language
      sharedAt
      sharedByUser {
        id
        firstNames
        email
        lastName
      }
      organizationInvitation {
        id
        activationCode
      }
      createdAt
      updatedAt
    }
  }
`;
export type CreateOrganizationInvitationSharingMutationFn = Apollo.MutationFunction<
  CreateOrganizationInvitationSharingMutation,
  CreateOrganizationInvitationSharingMutationVariables
>;
export type CreateOrganizationInvitationSharingProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOrganizationInvitationSharingMutation,
    CreateOrganizationInvitationSharingMutationVariables
  >;
} & TChildProps;
export function withCreateOrganizationInvitationSharing<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrganizationInvitationSharingMutation,
    CreateOrganizationInvitationSharingMutationVariables,
    CreateOrganizationInvitationSharingProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrganizationInvitationSharingMutation,
    CreateOrganizationInvitationSharingMutationVariables,
    CreateOrganizationInvitationSharingProps<TChildProps, TDataName>
  >(CreateOrganizationInvitationSharingDocument, {
    alias: 'createOrganizationInvitationSharing',
    ...operationOptions,
  });
}

/**
 * __useCreateOrganizationInvitationSharingMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationInvitationSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationInvitationSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationInvitationSharingMutation, { data, loading, error }] = useCreateOrganizationInvitationSharingMutation({
 *   variables: {
 *      organizationInvitationSharingInput: // value for 'organizationInvitationSharingInput'
 *   },
 * });
 */
export function useCreateOrganizationInvitationSharingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationInvitationSharingMutation,
    CreateOrganizationInvitationSharingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationInvitationSharingMutation,
    CreateOrganizationInvitationSharingMutationVariables
  >(CreateOrganizationInvitationSharingDocument, options);
}
export type CreateOrganizationInvitationSharingMutationHookResult = ReturnType<
  typeof useCreateOrganizationInvitationSharingMutation
>;
export type CreateOrganizationInvitationSharingMutationResult =
  Apollo.MutationResult<CreateOrganizationInvitationSharingMutation>;
export type CreateOrganizationInvitationSharingMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationInvitationSharingMutation,
  CreateOrganizationInvitationSharingMutationVariables
>;
export const CreateOrganizationInvitationAsPriojetDocument = gql`
  mutation CreateOrganizationInvitationAsPriojet(
    $reservedComment: String!
    $organizationInvitationType: OrganizationInvitationType!
    $invitedOrganizationId: String
    $invitingOrganizationId: String
    $roleType: OrganizationUserRoleType
    $invitedOrganizationType: OrganizationType
  ) {
    createOrganizationInvitationAsPriojet(
      reservedComment: $reservedComment
      invitedOrganizationType: $invitedOrganizationType
      invitedOrganizationId: $invitedOrganizationId
      invitingOrganizationId: $invitingOrganizationId
      roleType: $roleType
      organizationInvitationType: $organizationInvitationType
    ) {
      id
      createdAt
      updatedAt
      activationCode
      reservedComment
      bitrix24Deal {
        id
        bitrixDealCategoryId
        bitrixInvitedCompanyId
        bitrixInvitedContactId
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectedOrganization {
          id
          name
          organizationType
        }
        connectingOrganization {
          id
          name
          organizationType
        }
      }
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isPriojetContact
        isCourierContact
        isAgencyContact
        isClientContact
        isTravelerContact
        isServiceProviderContact
        isOtherContact
        organization {
          id
          name
        }
      }
      invitedOrganizationType
      roleType
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      invitingOrganization {
        id
        name
        organizationType
      }
      usedByUser {
        id
        firstNames
        email
        lastName
      }
      createdByUser {
        id
        firstNames
        email
        lastName
      }
      createdAt
      voidedAt
      usedAt
      organizationInvitationSharings {
        id
        appName
        platformVersion
        platform
        language
        sharedAt
        sharedByUser {
          id
          firstNames
          email
          lastName
        }
      }
    }
  }
`;
export type CreateOrganizationInvitationAsPriojetMutationFn = Apollo.MutationFunction<
  CreateOrganizationInvitationAsPriojetMutation,
  CreateOrganizationInvitationAsPriojetMutationVariables
>;
export type CreateOrganizationInvitationAsPriojetProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOrganizationInvitationAsPriojetMutation,
    CreateOrganizationInvitationAsPriojetMutationVariables
  >;
} & TChildProps;
export function withCreateOrganizationInvitationAsPriojet<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrganizationInvitationAsPriojetMutation,
    CreateOrganizationInvitationAsPriojetMutationVariables,
    CreateOrganizationInvitationAsPriojetProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrganizationInvitationAsPriojetMutation,
    CreateOrganizationInvitationAsPriojetMutationVariables,
    CreateOrganizationInvitationAsPriojetProps<TChildProps, TDataName>
  >(CreateOrganizationInvitationAsPriojetDocument, {
    alias: 'createOrganizationInvitationAsPriojet',
    ...operationOptions,
  });
}

/**
 * __useCreateOrganizationInvitationAsPriojetMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationInvitationAsPriojetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationInvitationAsPriojetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationInvitationAsPriojetMutation, { data, loading, error }] = useCreateOrganizationInvitationAsPriojetMutation({
 *   variables: {
 *      reservedComment: // value for 'reservedComment'
 *      organizationInvitationType: // value for 'organizationInvitationType'
 *      invitedOrganizationId: // value for 'invitedOrganizationId'
 *      invitingOrganizationId: // value for 'invitingOrganizationId'
 *      roleType: // value for 'roleType'
 *      invitedOrganizationType: // value for 'invitedOrganizationType'
 *   },
 * });
 */
export function useCreateOrganizationInvitationAsPriojetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationInvitationAsPriojetMutation,
    CreateOrganizationInvitationAsPriojetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrganizationInvitationAsPriojetMutation,
    CreateOrganizationInvitationAsPriojetMutationVariables
  >(CreateOrganizationInvitationAsPriojetDocument, options);
}
export type CreateOrganizationInvitationAsPriojetMutationHookResult = ReturnType<
  typeof useCreateOrganizationInvitationAsPriojetMutation
>;
export type CreateOrganizationInvitationAsPriojetMutationResult =
  Apollo.MutationResult<CreateOrganizationInvitationAsPriojetMutation>;
export type CreateOrganizationInvitationAsPriojetMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationInvitationAsPriojetMutation,
  CreateOrganizationInvitationAsPriojetMutationVariables
>;
export const EditOrganizationInvitationDocument = gql`
  mutation EditOrganizationInvitation($id: String!) {
    voidOrganizationInvitation(id: $id) {
      id
      activationCode
      voidedAt
      updatedAt
    }
  }
`;
export type EditOrganizationInvitationMutationFn = Apollo.MutationFunction<
  EditOrganizationInvitationMutation,
  EditOrganizationInvitationMutationVariables
>;
export type EditOrganizationInvitationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    EditOrganizationInvitationMutation,
    EditOrganizationInvitationMutationVariables
  >;
} & TChildProps;
export function withEditOrganizationInvitation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EditOrganizationInvitationMutation,
    EditOrganizationInvitationMutationVariables,
    EditOrganizationInvitationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    EditOrganizationInvitationMutation,
    EditOrganizationInvitationMutationVariables,
    EditOrganizationInvitationProps<TChildProps, TDataName>
  >(EditOrganizationInvitationDocument, {
    alias: 'editOrganizationInvitation',
    ...operationOptions,
  });
}

/**
 * __useEditOrganizationInvitationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationInvitationMutation, { data, loading, error }] = useEditOrganizationInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditOrganizationInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditOrganizationInvitationMutation,
    EditOrganizationInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditOrganizationInvitationMutation,
    EditOrganizationInvitationMutationVariables
  >(EditOrganizationInvitationDocument, options);
}
export type EditOrganizationInvitationMutationHookResult = ReturnType<
  typeof useEditOrganizationInvitationMutation
>;
export type EditOrganizationInvitationMutationResult =
  Apollo.MutationResult<EditOrganizationInvitationMutation>;
export type EditOrganizationInvitationMutationOptions = Apollo.BaseMutationOptions<
  EditOrganizationInvitationMutation,
  EditOrganizationInvitationMutationVariables
>;
export const GetOrCreateDeviceDocument = gql`
  mutation GetOrCreateDevice($deviceInput: DeviceInput!) {
    getOrCreateDevice(deviceInput: $deviceInput) {
      id
      deviceType
    }
  }
`;
export type GetOrCreateDeviceMutationFn = Apollo.MutationFunction<
  GetOrCreateDeviceMutation,
  GetOrCreateDeviceMutationVariables
>;
export type GetOrCreateDeviceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    GetOrCreateDeviceMutation,
    GetOrCreateDeviceMutationVariables
  >;
} & TChildProps;
export function withGetOrCreateDevice<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetOrCreateDeviceMutation,
    GetOrCreateDeviceMutationVariables,
    GetOrCreateDeviceProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    GetOrCreateDeviceMutation,
    GetOrCreateDeviceMutationVariables,
    GetOrCreateDeviceProps<TChildProps, TDataName>
  >(GetOrCreateDeviceDocument, {
    alias: 'getOrCreateDevice',
    ...operationOptions,
  });
}

/**
 * __useGetOrCreateDeviceMutation__
 *
 * To run a mutation, you first call `useGetOrCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateDeviceMutation, { data, loading, error }] = useGetOrCreateDeviceMutation({
 *   variables: {
 *      deviceInput: // value for 'deviceInput'
 *   },
 * });
 */
export function useGetOrCreateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetOrCreateDeviceMutation,
    GetOrCreateDeviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetOrCreateDeviceMutation, GetOrCreateDeviceMutationVariables>(
    GetOrCreateDeviceDocument,
    options,
  );
}
export type GetOrCreateDeviceMutationHookResult = ReturnType<typeof useGetOrCreateDeviceMutation>;
export type GetOrCreateDeviceMutationResult = Apollo.MutationResult<GetOrCreateDeviceMutation>;
export type GetOrCreateDeviceMutationOptions = Apollo.BaseMutationOptions<
  GetOrCreateDeviceMutation,
  GetOrCreateDeviceMutationVariables
>;
export const UpdateDeviceDocument = gql`
  mutation UpdateDevice($deviceInput: DeviceInput!) {
    updateDevice(deviceInput: $deviceInput) {
      id
      deviceType
    }
  }
`;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables
>;
export type UpdateDeviceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
} & TChildProps;
export function withUpdateDevice<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables,
    UpdateDeviceProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables,
    UpdateDeviceProps<TChildProps, TDataName>
  >(UpdateDeviceDocument, {
    alias: 'updateDevice',
    ...operationOptions,
  });
}

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      deviceInput: // value for 'deviceInput'
 *   },
 * });
 */
export function useUpdateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(
    UpdateDeviceDocument,
    options,
  );
}
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables
>;
export const CreateDeviceLocationsDocument = gql`
  mutation CreateDeviceLocations($deviceLocationInputs: [DeviceLocationInput!]!) {
    createDeviceLocations(deviceLocationInputs: $deviceLocationInputs) {
      id
      location {
        id
        formattedAddress
        formattedMinimalAddress
      }
      latitude
      longitude
      altitude
      createdAt
    }
  }
`;
export type CreateDeviceLocationsMutationFn = Apollo.MutationFunction<
  CreateDeviceLocationsMutation,
  CreateDeviceLocationsMutationVariables
>;
export type CreateDeviceLocationsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateDeviceLocationsMutation,
    CreateDeviceLocationsMutationVariables
  >;
} & TChildProps;
export function withCreateDeviceLocations<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDeviceLocationsMutation,
    CreateDeviceLocationsMutationVariables,
    CreateDeviceLocationsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDeviceLocationsMutation,
    CreateDeviceLocationsMutationVariables,
    CreateDeviceLocationsProps<TChildProps, TDataName>
  >(CreateDeviceLocationsDocument, {
    alias: 'createDeviceLocations',
    ...operationOptions,
  });
}

/**
 * __useCreateDeviceLocationsMutation__
 *
 * To run a mutation, you first call `useCreateDeviceLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceLocationsMutation, { data, loading, error }] = useCreateDeviceLocationsMutation({
 *   variables: {
 *      deviceLocationInputs: // value for 'deviceLocationInputs'
 *   },
 * });
 */
export function useCreateDeviceLocationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeviceLocationsMutation,
    CreateDeviceLocationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDeviceLocationsMutation, CreateDeviceLocationsMutationVariables>(
    CreateDeviceLocationsDocument,
    options,
  );
}
export type CreateDeviceLocationsMutationHookResult = ReturnType<
  typeof useCreateDeviceLocationsMutation
>;
export type CreateDeviceLocationsMutationResult =
  Apollo.MutationResult<CreateDeviceLocationsMutation>;
export type CreateDeviceLocationsMutationOptions = Apollo.BaseMutationOptions<
  CreateDeviceLocationsMutation,
  CreateDeviceLocationsMutationVariables
>;
export const CreateUserIdentityDocumentDocument = gql`
  mutation CreateUserIdentityDocument($userIdentityDocumentInput: UserIdentityDocumentInput!) {
    createUserIdentityDocument(userIdentityDocumentInput: $userIdentityDocumentInput) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      countryOfIssue
      expiresOn
      nationality
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type CreateUserIdentityDocumentMutationFn = Apollo.MutationFunction<
  CreateUserIdentityDocumentMutation,
  CreateUserIdentityDocumentMutationVariables
>;
export type CreateUserIdentityDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserIdentityDocumentMutation,
    CreateUserIdentityDocumentMutationVariables
  >;
} & TChildProps;
export function withCreateUserIdentityDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserIdentityDocumentMutation,
    CreateUserIdentityDocumentMutationVariables,
    CreateUserIdentityDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserIdentityDocumentMutation,
    CreateUserIdentityDocumentMutationVariables,
    CreateUserIdentityDocumentProps<TChildProps, TDataName>
  >(CreateUserIdentityDocumentDocument, {
    alias: 'createUserIdentityDocument',
    ...operationOptions,
  });
}

/**
 * __useCreateUserIdentityDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUserIdentityDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserIdentityDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserIdentityDocumentMutation, { data, loading, error }] = useCreateUserIdentityDocumentMutation({
 *   variables: {
 *      userIdentityDocumentInput: // value for 'userIdentityDocumentInput'
 *   },
 * });
 */
export function useCreateUserIdentityDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserIdentityDocumentMutation,
    CreateUserIdentityDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserIdentityDocumentMutation,
    CreateUserIdentityDocumentMutationVariables
  >(CreateUserIdentityDocumentDocument, options);
}
export type CreateUserIdentityDocumentMutationHookResult = ReturnType<
  typeof useCreateUserIdentityDocumentMutation
>;
export type CreateUserIdentityDocumentMutationResult =
  Apollo.MutationResult<CreateUserIdentityDocumentMutation>;
export type CreateUserIdentityDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserIdentityDocumentMutation,
  CreateUserIdentityDocumentMutationVariables
>;
export const UpdateCourierUserProfileDocument = gql`
  mutation UpdateCourierUserProfile($courierUserProfileInput: CourierUserProfileInput!) {
    updateCourierUserProfile(courierUserProfileInput: $courierUserProfileInput) {
      id
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactAddress
      hasAmexCreditCard
      hasDinerCreditCard
      hasMasterCreditCard
      hasVisaCreditCard
      hasAmexDebitCard
      hasDinerDebitCard
      hasMasterDebitCard
      hasVisaDebitCard
      amexCreditCardLimit
      dinerCreditCardLimit
      masterCreditCardLimit
      visaCreditCardLimit
      amexCreditCardCurrency
      dinerCreditCardCurrency
      masterCreditCardCurrency
      visaCreditCardCurrency
      hasObcExperience
      firstObcJobDateAt
      numberOfPastObcJobs
      hasMedicalObcExperience
      languagesKnown {
        language
        level
      }
      onboardExperiences
      taxCountry
      socialProfiles {
        platform
        type
        value
      }
      baseAirports
      taxCountry
    }
  }
`;
export type UpdateCourierUserProfileMutationFn = Apollo.MutationFunction<
  UpdateCourierUserProfileMutation,
  UpdateCourierUserProfileMutationVariables
>;
export type UpdateCourierUserProfileProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateCourierUserProfileMutation,
    UpdateCourierUserProfileMutationVariables
  >;
} & TChildProps;
export function withUpdateCourierUserProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCourierUserProfileMutation,
    UpdateCourierUserProfileMutationVariables,
    UpdateCourierUserProfileProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCourierUserProfileMutation,
    UpdateCourierUserProfileMutationVariables,
    UpdateCourierUserProfileProps<TChildProps, TDataName>
  >(UpdateCourierUserProfileDocument, {
    alias: 'updateCourierUserProfile',
    ...operationOptions,
  });
}

/**
 * __useUpdateCourierUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCourierUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourierUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourierUserProfileMutation, { data, loading, error }] = useUpdateCourierUserProfileMutation({
 *   variables: {
 *      courierUserProfileInput: // value for 'courierUserProfileInput'
 *   },
 * });
 */
export function useUpdateCourierUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourierUserProfileMutation,
    UpdateCourierUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCourierUserProfileMutation,
    UpdateCourierUserProfileMutationVariables
  >(UpdateCourierUserProfileDocument, options);
}
export type UpdateCourierUserProfileMutationHookResult = ReturnType<
  typeof useUpdateCourierUserProfileMutation
>;
export type UpdateCourierUserProfileMutationResult =
  Apollo.MutationResult<UpdateCourierUserProfileMutation>;
export type UpdateCourierUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourierUserProfileMutation,
  UpdateCourierUserProfileMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation UpdateUserProfile($userProfileInput: UserProfileInput!) {
    updateUserProfile(userProfileInput: $userProfileInput) {
      id
      firstNames
      lastName
      email
      email2
      sex
      dateOfBirth
      placeOfBirth
      countryOfBirth
      language
      nationalities
      phone
      phone2
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      userRegisteredAddressLocation {
        id
        placeId
        formattedAddress
        formattedMinimalAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export type UpdateUserProfileProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >;
} & TChildProps;
export function withUpdateUserProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables,
    UpdateUserProfileProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables,
    UpdateUserProfileProps<TChildProps, TDataName>
  >(UpdateUserProfileDocument, {
    alias: 'updateUserProfile',
    ...operationOptions,
  });
}

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userProfileInput: // value for 'userProfileInput'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UpdateUserProfileDocument,
    options,
  );
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const DeleteUserProfilePictureDocument = gql`
  mutation deleteUserProfilePicture {
    deleteUserProfilePicture {
      id
      firstNames
      lastName
      email
      sex
      dateOfBirth
      placeOfBirth
      countryOfBirth
      language
      nationalities
    }
  }
`;
export type DeleteUserProfilePictureMutationFn = Apollo.MutationFunction<
  DeleteUserProfilePictureMutation,
  DeleteUserProfilePictureMutationVariables
>;
export type DeleteUserProfilePictureProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserProfilePictureMutation,
    DeleteUserProfilePictureMutationVariables
  >;
} & TChildProps;
export function withDeleteUserProfilePicture<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserProfilePictureMutation,
    DeleteUserProfilePictureMutationVariables,
    DeleteUserProfilePictureProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserProfilePictureMutation,
    DeleteUserProfilePictureMutationVariables,
    DeleteUserProfilePictureProps<TChildProps, TDataName>
  >(DeleteUserProfilePictureDocument, {
    alias: 'deleteUserProfilePicture',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserProfilePictureMutation__
 *
 * To run a mutation, you first call `useDeleteUserProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserProfilePictureMutation, { data, loading, error }] = useDeleteUserProfilePictureMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserProfilePictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserProfilePictureMutation,
    DeleteUserProfilePictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserProfilePictureMutation,
    DeleteUserProfilePictureMutationVariables
  >(DeleteUserProfilePictureDocument, options);
}
export type DeleteUserProfilePictureMutationHookResult = ReturnType<
  typeof useDeleteUserProfilePictureMutation
>;
export type DeleteUserProfilePictureMutationResult =
  Apollo.MutationResult<DeleteUserProfilePictureMutation>;
export type DeleteUserProfilePictureMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserProfilePictureMutation,
  DeleteUserProfilePictureMutationVariables
>;
export const CreateUserTravelDocumentDocument = gql`
  mutation CreateUserTravelDocument(
    $userTravelDocumentInput: UserTravelDocumentInput!
    $mappedUserIdentityDocumentId: String!
  ) {
    createUserTravelDocument(
      userTravelDocumentInput: $userTravelDocumentInput
      mappedUserIdentityDocumentId: $mappedUserIdentityDocumentId
    ) {
      id
      documentSubType
      documentSubTypeMulti
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentNumber
      dateOfIssue
      countryOfIssue
      validForCountry
      expiresOn
      nationality
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      multipleEntriesAllowed
    }
  }
`;
export type CreateUserTravelDocumentMutationFn = Apollo.MutationFunction<
  CreateUserTravelDocumentMutation,
  CreateUserTravelDocumentMutationVariables
>;
export type CreateUserTravelDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserTravelDocumentMutation,
    CreateUserTravelDocumentMutationVariables
  >;
} & TChildProps;
export function withCreateUserTravelDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserTravelDocumentMutation,
    CreateUserTravelDocumentMutationVariables,
    CreateUserTravelDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserTravelDocumentMutation,
    CreateUserTravelDocumentMutationVariables,
    CreateUserTravelDocumentProps<TChildProps, TDataName>
  >(CreateUserTravelDocumentDocument, {
    alias: 'createUserTravelDocument',
    ...operationOptions,
  });
}

/**
 * __useCreateUserTravelDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUserTravelDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTravelDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTravelDocumentMutation, { data, loading, error }] = useCreateUserTravelDocumentMutation({
 *   variables: {
 *      userTravelDocumentInput: // value for 'userTravelDocumentInput'
 *      mappedUserIdentityDocumentId: // value for 'mappedUserIdentityDocumentId'
 *   },
 * });
 */
export function useCreateUserTravelDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserTravelDocumentMutation,
    CreateUserTravelDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserTravelDocumentMutation,
    CreateUserTravelDocumentMutationVariables
  >(CreateUserTravelDocumentDocument, options);
}
export type CreateUserTravelDocumentMutationHookResult = ReturnType<
  typeof useCreateUserTravelDocumentMutation
>;
export type CreateUserTravelDocumentMutationResult =
  Apollo.MutationResult<CreateUserTravelDocumentMutation>;
export type CreateUserTravelDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserTravelDocumentMutation,
  CreateUserTravelDocumentMutationVariables
>;
export const CreateUserFrequentFlyerDocumentDocument = gql`
  mutation CreateUserFrequentFlyerDocument(
    $userFrequentFlyerDocumentInput: UserFrequentFlyerDocumentInput!
  ) {
    createUserFrequentFlyerDocument(
      userFrequentFlyerDocumentInput: $userFrequentFlyerDocumentInput
    ) {
      id
      airlineType
      airlineStatusType
      airlineAllianceStatusType
      airlineAllianceType
      documentNumber
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type CreateUserFrequentFlyerDocumentMutationFn = Apollo.MutationFunction<
  CreateUserFrequentFlyerDocumentMutation,
  CreateUserFrequentFlyerDocumentMutationVariables
>;
export type CreateUserFrequentFlyerDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserFrequentFlyerDocumentMutation,
    CreateUserFrequentFlyerDocumentMutationVariables
  >;
} & TChildProps;
export function withCreateUserFrequentFlyerDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserFrequentFlyerDocumentMutation,
    CreateUserFrequentFlyerDocumentMutationVariables,
    CreateUserFrequentFlyerDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserFrequentFlyerDocumentMutation,
    CreateUserFrequentFlyerDocumentMutationVariables,
    CreateUserFrequentFlyerDocumentProps<TChildProps, TDataName>
  >(CreateUserFrequentFlyerDocumentDocument, {
    alias: 'createUserFrequentFlyerDocument',
    ...operationOptions,
  });
}

/**
 * __useCreateUserFrequentFlyerDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUserFrequentFlyerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFrequentFlyerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFrequentFlyerDocumentMutation, { data, loading, error }] = useCreateUserFrequentFlyerDocumentMutation({
 *   variables: {
 *      userFrequentFlyerDocumentInput: // value for 'userFrequentFlyerDocumentInput'
 *   },
 * });
 */
export function useCreateUserFrequentFlyerDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFrequentFlyerDocumentMutation,
    CreateUserFrequentFlyerDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserFrequentFlyerDocumentMutation,
    CreateUserFrequentFlyerDocumentMutationVariables
  >(CreateUserFrequentFlyerDocumentDocument, options);
}
export type CreateUserFrequentFlyerDocumentMutationHookResult = ReturnType<
  typeof useCreateUserFrequentFlyerDocumentMutation
>;
export type CreateUserFrequentFlyerDocumentMutationResult =
  Apollo.MutationResult<CreateUserFrequentFlyerDocumentMutation>;
export type CreateUserFrequentFlyerDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFrequentFlyerDocumentMutation,
  CreateUserFrequentFlyerDocumentMutationVariables
>;
export const CreateUserVaccinationDocumentDocument = gql`
  mutation CreateUserVaccinationDocument(
    $userVaccinationDocumentInput: UserVaccinationDocumentInput!
  ) {
    createUserVaccinationDocument(userVaccinationDocumentInput: $userVaccinationDocumentInput) {
      id
      diseaseType
      description
      vaccinationType
      vaccinationManufacturer
      dateOfVaccination
      isVaccinationComplete
      vaccinationCenter
      cityOfVaccination
      countryOfVaccination
      expiresOn
      documentNumber
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type CreateUserVaccinationDocumentMutationFn = Apollo.MutationFunction<
  CreateUserVaccinationDocumentMutation,
  CreateUserVaccinationDocumentMutationVariables
>;
export type CreateUserVaccinationDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserVaccinationDocumentMutation,
    CreateUserVaccinationDocumentMutationVariables
  >;
} & TChildProps;
export function withCreateUserVaccinationDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserVaccinationDocumentMutation,
    CreateUserVaccinationDocumentMutationVariables,
    CreateUserVaccinationDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserVaccinationDocumentMutation,
    CreateUserVaccinationDocumentMutationVariables,
    CreateUserVaccinationDocumentProps<TChildProps, TDataName>
  >(CreateUserVaccinationDocumentDocument, {
    alias: 'createUserVaccinationDocument',
    ...operationOptions,
  });
}

/**
 * __useCreateUserVaccinationDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUserVaccinationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserVaccinationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserVaccinationDocumentMutation, { data, loading, error }] = useCreateUserVaccinationDocumentMutation({
 *   variables: {
 *      userVaccinationDocumentInput: // value for 'userVaccinationDocumentInput'
 *   },
 * });
 */
export function useCreateUserVaccinationDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserVaccinationDocumentMutation,
    CreateUserVaccinationDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserVaccinationDocumentMutation,
    CreateUserVaccinationDocumentMutationVariables
  >(CreateUserVaccinationDocumentDocument, options);
}
export type CreateUserVaccinationDocumentMutationHookResult = ReturnType<
  typeof useCreateUserVaccinationDocumentMutation
>;
export type CreateUserVaccinationDocumentMutationResult =
  Apollo.MutationResult<CreateUserVaccinationDocumentMutation>;
export type CreateUserVaccinationDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserVaccinationDocumentMutation,
  CreateUserVaccinationDocumentMutationVariables
>;
export const CreateUserHealthReportDocument = gql`
  mutation CreateUserHealthReport($userHealthReportInput: UserHealthReportInput!) {
    createUserHealthReport(userHealthReportInput: $userHealthReportInput) {
      id
      diseaseType
      description
      testType
      dateTimeOfTest
      isResultPositive
      testCenter
      cityOfTest
      countryOfTest
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type CreateUserHealthReportMutationFn = Apollo.MutationFunction<
  CreateUserHealthReportMutation,
  CreateUserHealthReportMutationVariables
>;
export type CreateUserHealthReportProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserHealthReportMutation,
    CreateUserHealthReportMutationVariables
  >;
} & TChildProps;
export function withCreateUserHealthReport<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserHealthReportMutation,
    CreateUserHealthReportMutationVariables,
    CreateUserHealthReportProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserHealthReportMutation,
    CreateUserHealthReportMutationVariables,
    CreateUserHealthReportProps<TChildProps, TDataName>
  >(CreateUserHealthReportDocument, {
    alias: 'createUserHealthReport',
    ...operationOptions,
  });
}

/**
 * __useCreateUserHealthReportMutation__
 *
 * To run a mutation, you first call `useCreateUserHealthReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserHealthReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserHealthReportMutation, { data, loading, error }] = useCreateUserHealthReportMutation({
 *   variables: {
 *      userHealthReportInput: // value for 'userHealthReportInput'
 *   },
 * });
 */
export function useCreateUserHealthReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserHealthReportMutation,
    CreateUserHealthReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserHealthReportMutation,
    CreateUserHealthReportMutationVariables
  >(CreateUserHealthReportDocument, options);
}
export type CreateUserHealthReportMutationHookResult = ReturnType<
  typeof useCreateUserHealthReportMutation
>;
export type CreateUserHealthReportMutationResult =
  Apollo.MutationResult<CreateUserHealthReportMutation>;
export type CreateUserHealthReportMutationOptions = Apollo.BaseMutationOptions<
  CreateUserHealthReportMutation,
  CreateUserHealthReportMutationVariables
>;
export const CreateUserBackgroundDocumentDocument = gql`
  mutation CreateUserBackgroundDocument(
    $userBackgroundDocumentInput: UserBackgroundDocumentInput!
  ) {
    createUserBackgroundDocument(userBackgroundDocumentInput: $userBackgroundDocumentInput) {
      id
      name
      issuingInstitution
      countryOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type CreateUserBackgroundDocumentMutationFn = Apollo.MutationFunction<
  CreateUserBackgroundDocumentMutation,
  CreateUserBackgroundDocumentMutationVariables
>;
export type CreateUserBackgroundDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserBackgroundDocumentMutation,
    CreateUserBackgroundDocumentMutationVariables
  >;
} & TChildProps;
export function withCreateUserBackgroundDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserBackgroundDocumentMutation,
    CreateUserBackgroundDocumentMutationVariables,
    CreateUserBackgroundDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserBackgroundDocumentMutation,
    CreateUserBackgroundDocumentMutationVariables,
    CreateUserBackgroundDocumentProps<TChildProps, TDataName>
  >(CreateUserBackgroundDocumentDocument, {
    alias: 'createUserBackgroundDocument',
    ...operationOptions,
  });
}

/**
 * __useCreateUserBackgroundDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUserBackgroundDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBackgroundDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBackgroundDocumentMutation, { data, loading, error }] = useCreateUserBackgroundDocumentMutation({
 *   variables: {
 *      userBackgroundDocumentInput: // value for 'userBackgroundDocumentInput'
 *   },
 * });
 */
export function useCreateUserBackgroundDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserBackgroundDocumentMutation,
    CreateUserBackgroundDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserBackgroundDocumentMutation,
    CreateUserBackgroundDocumentMutationVariables
  >(CreateUserBackgroundDocumentDocument, options);
}
export type CreateUserBackgroundDocumentMutationHookResult = ReturnType<
  typeof useCreateUserBackgroundDocumentMutation
>;
export type CreateUserBackgroundDocumentMutationResult =
  Apollo.MutationResult<CreateUserBackgroundDocumentMutation>;
export type CreateUserBackgroundDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserBackgroundDocumentMutation,
  CreateUserBackgroundDocumentMutationVariables
>;
export const CreateUserOtherDocumentDocument = gql`
  mutation CreateUserOtherDocument($userOtherDocumentInput: UserOtherDocumentInput!) {
    createUserOtherDocument(userOtherDocumentInput: $userOtherDocumentInput) {
      id
      name
      description
      countryOfIssue
      placeOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type CreateUserOtherDocumentMutationFn = Apollo.MutationFunction<
  CreateUserOtherDocumentMutation,
  CreateUserOtherDocumentMutationVariables
>;
export type CreateUserOtherDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateUserOtherDocumentMutation,
    CreateUserOtherDocumentMutationVariables
  >;
} & TChildProps;
export function withCreateUserOtherDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserOtherDocumentMutation,
    CreateUserOtherDocumentMutationVariables,
    CreateUserOtherDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserOtherDocumentMutation,
    CreateUserOtherDocumentMutationVariables,
    CreateUserOtherDocumentProps<TChildProps, TDataName>
  >(CreateUserOtherDocumentDocument, {
    alias: 'createUserOtherDocument',
    ...operationOptions,
  });
}

/**
 * __useCreateUserOtherDocumentMutation__
 *
 * To run a mutation, you first call `useCreateUserOtherDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserOtherDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserOtherDocumentMutation, { data, loading, error }] = useCreateUserOtherDocumentMutation({
 *   variables: {
 *      userOtherDocumentInput: // value for 'userOtherDocumentInput'
 *   },
 * });
 */
export function useCreateUserOtherDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserOtherDocumentMutation,
    CreateUserOtherDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUserOtherDocumentMutation,
    CreateUserOtherDocumentMutationVariables
  >(CreateUserOtherDocumentDocument, options);
}
export type CreateUserOtherDocumentMutationHookResult = ReturnType<
  typeof useCreateUserOtherDocumentMutation
>;
export type CreateUserOtherDocumentMutationResult =
  Apollo.MutationResult<CreateUserOtherDocumentMutation>;
export type CreateUserOtherDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateUserOtherDocumentMutation,
  CreateUserOtherDocumentMutationVariables
>;
export const UpdateRelationshipTypeForCourierJobAsCourierOrganizationDocument = gql`
  mutation UpdateRelationshipTypeForCourierJobAsCourierOrganization(
    $id: String!
    $relationshipType: CourierJobAgencyRelationshipType!
  ) {
    updateRelationshipTypeForCourierJobAsCourierOrganization(
      id: $id
      relationshipType: $relationshipType
    ) {
      id
      relationshipType
      courierJobStatusType
      updatedAt
      agencyJob {
        id
      }
    }
  }
`;
export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationFn =
  Apollo.MutationFunction<
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables
  >;
export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables
  >;
} & TChildProps;
export function withUpdateRelationshipTypeForCourierJobAsCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationProps<TChildProps, TDataName>
  >(UpdateRelationshipTypeForCourierJobAsCourierOrganizationDocument, {
    alias: 'updateRelationshipTypeForCourierJobAsCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationshipTypeForCourierJobAsCourierOrganizationMutation, { data, loading, error }] = useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      relationshipType: // value for 'relationshipType'
 *   },
 * });
 */
export function useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables
  >(UpdateRelationshipTypeForCourierJobAsCourierOrganizationDocument, options);
}
export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationHookResult = ReturnType<
  typeof useUpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation
>;
export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationResult =
  Apollo.MutationResult<UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation>;
export type UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsCourierOrganizationMutationVariables
  >;
export const UpdateRelationshipTypeForCourierJobAsAgencyOrganizationDocument = gql`
  mutation UpdateRelationshipTypeForCourierJobAsAgencyOrganization(
    $id: String!
    $agencyJobId: String!
    $relationshipType: CourierJobAgencyRelationshipType!
  ) {
    updateRelationshipTypeForCourierJobAsAgencyOrganization(
      id: $id
      agencyJobId: $agencyJobId
      relationshipType: $relationshipType
    ) {
      id
      relationshipType
      courierJobStatusType
      updatedAt
      agencyJob {
        id
      }
    }
  }
`;
export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationFn =
  Apollo.MutationFunction<
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables
  >;
export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables
  >;
} & TChildProps;
export function withUpdateRelationshipTypeForCourierJobAsAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationProps<TChildProps, TDataName>
  >(UpdateRelationshipTypeForCourierJobAsAgencyOrganizationDocument, {
    alias: 'updateRelationshipTypeForCourierJobAsAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelationshipTypeForCourierJobAsAgencyOrganizationMutation, { data, loading, error }] = useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      agencyJobId: // value for 'agencyJobId'
 *      relationshipType: // value for 'relationshipType'
 *   },
 * });
 */
export function useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables
  >(UpdateRelationshipTypeForCourierJobAsAgencyOrganizationDocument, options);
}
export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationHookResult = ReturnType<
  typeof useUpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation
>;
export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationResult =
  Apollo.MutationResult<UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation>;
export type UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutation,
    UpdateRelationshipTypeForCourierJobAsAgencyOrganizationMutationVariables
  >;
export const AddOrDisableCourierJobDocument = gql`
  mutation AddOrDisableCourierJob($courierOrganizationUserIds: [String!]!, $agencyJobId: String!) {
    addOrDisableCourierJob(
      courierOrganizationUserIds: $courierOrganizationUserIds
      agencyJobId: $agencyJobId
    ) {
      user {
        id
        firstNames
        lastName
        email
        phone
      }
      organizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
        }
      }
      courierJob {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        isActive
      }
      availabilityLocationsWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
          }
        }
        distance
      }
    }
  }
`;
export type AddOrDisableCourierJobMutationFn = Apollo.MutationFunction<
  AddOrDisableCourierJobMutation,
  AddOrDisableCourierJobMutationVariables
>;
export type AddOrDisableCourierJobProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    AddOrDisableCourierJobMutation,
    AddOrDisableCourierJobMutationVariables
  >;
} & TChildProps;
export function withAddOrDisableCourierJob<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddOrDisableCourierJobMutation,
    AddOrDisableCourierJobMutationVariables,
    AddOrDisableCourierJobProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddOrDisableCourierJobMutation,
    AddOrDisableCourierJobMutationVariables,
    AddOrDisableCourierJobProps<TChildProps, TDataName>
  >(AddOrDisableCourierJobDocument, {
    alias: 'addOrDisableCourierJob',
    ...operationOptions,
  });
}

/**
 * __useAddOrDisableCourierJobMutation__
 *
 * To run a mutation, you first call `useAddOrDisableCourierJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrDisableCourierJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrDisableCourierJobMutation, { data, loading, error }] = useAddOrDisableCourierJobMutation({
 *   variables: {
 *      courierOrganizationUserIds: // value for 'courierOrganizationUserIds'
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAddOrDisableCourierJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrDisableCourierJobMutation,
    AddOrDisableCourierJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOrDisableCourierJobMutation,
    AddOrDisableCourierJobMutationVariables
  >(AddOrDisableCourierJobDocument, options);
}
export type AddOrDisableCourierJobMutationHookResult = ReturnType<
  typeof useAddOrDisableCourierJobMutation
>;
export type AddOrDisableCourierJobMutationResult =
  Apollo.MutationResult<AddOrDisableCourierJobMutation>;
export type AddOrDisableCourierJobMutationOptions = Apollo.BaseMutationOptions<
  AddOrDisableCourierJobMutation,
  AddOrDisableCourierJobMutationVariables
>;
export const AddOrDisableCourierForCourierJobDocument = gql`
  mutation AddOrDisableCourierForCourierJob(
    $courierOrganizationUserIds: [String!]!
    $agencyJobId: String!
  ) {
    addOrDisableCourierForCourierJob(
      courierOrganizationUserIds: $courierOrganizationUserIds
      agencyJobId: $agencyJobId
    ) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      isActive
    }
  }
`;
export type AddOrDisableCourierForCourierJobMutationFn = Apollo.MutationFunction<
  AddOrDisableCourierForCourierJobMutation,
  AddOrDisableCourierForCourierJobMutationVariables
>;
export type AddOrDisableCourierForCourierJobProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AddOrDisableCourierForCourierJobMutation,
    AddOrDisableCourierForCourierJobMutationVariables
  >;
} & TChildProps;
export function withAddOrDisableCourierForCourierJob<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddOrDisableCourierForCourierJobMutation,
    AddOrDisableCourierForCourierJobMutationVariables,
    AddOrDisableCourierForCourierJobProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddOrDisableCourierForCourierJobMutation,
    AddOrDisableCourierForCourierJobMutationVariables,
    AddOrDisableCourierForCourierJobProps<TChildProps, TDataName>
  >(AddOrDisableCourierForCourierJobDocument, {
    alias: 'addOrDisableCourierForCourierJob',
    ...operationOptions,
  });
}

/**
 * __useAddOrDisableCourierForCourierJobMutation__
 *
 * To run a mutation, you first call `useAddOrDisableCourierForCourierJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrDisableCourierForCourierJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrDisableCourierForCourierJobMutation, { data, loading, error }] = useAddOrDisableCourierForCourierJobMutation({
 *   variables: {
 *      courierOrganizationUserIds: // value for 'courierOrganizationUserIds'
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAddOrDisableCourierForCourierJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrDisableCourierForCourierJobMutation,
    AddOrDisableCourierForCourierJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOrDisableCourierForCourierJobMutation,
    AddOrDisableCourierForCourierJobMutationVariables
  >(AddOrDisableCourierForCourierJobDocument, options);
}
export type AddOrDisableCourierForCourierJobMutationHookResult = ReturnType<
  typeof useAddOrDisableCourierForCourierJobMutation
>;
export type AddOrDisableCourierForCourierJobMutationResult =
  Apollo.MutationResult<AddOrDisableCourierForCourierJobMutation>;
export type AddOrDisableCourierForCourierJobMutationOptions = Apollo.BaseMutationOptions<
  AddOrDisableCourierForCourierJobMutation,
  AddOrDisableCourierForCourierJobMutationVariables
>;
export const ChangeAgencyJobStatusTypeForAgencyOrganizationDocument = gql`
  mutation ChangeAgencyJobStatusTypeForAgencyOrganization(
    $agencyJobId: String!
    $agencyJobStatusType: AgencyJobStatusType!
  ) {
    changeAgencyJobStatusTypeForAgencyOrganization(
      agencyJobId: $agencyJobId
      agencyJobStatusType: $agencyJobStatusType
    ) {
      id
      agencyJobStatusType
    }
  }
`;
export type ChangeAgencyJobStatusTypeForAgencyOrganizationMutationFn = Apollo.MutationFunction<
  ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
  ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables
>;
export type ChangeAgencyJobStatusTypeForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables
  >;
} & TChildProps;
export function withChangeAgencyJobStatusTypeForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables,
    ChangeAgencyJobStatusTypeForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables,
    ChangeAgencyJobStatusTypeForAgencyOrganizationProps<TChildProps, TDataName>
  >(ChangeAgencyJobStatusTypeForAgencyOrganizationDocument, {
    alias: 'changeAgencyJobStatusTypeForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useChangeAgencyJobStatusTypeForAgencyOrganizationMutation__
 *
 * To run a mutation, you first call `useChangeAgencyJobStatusTypeForAgencyOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAgencyJobStatusTypeForAgencyOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAgencyJobStatusTypeForAgencyOrganizationMutation, { data, loading, error }] = useChangeAgencyJobStatusTypeForAgencyOrganizationMutation({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *      agencyJobStatusType: // value for 'agencyJobStatusType'
 *   },
 * });
 */
export function useChangeAgencyJobStatusTypeForAgencyOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables
  >(ChangeAgencyJobStatusTypeForAgencyOrganizationDocument, options);
}
export type ChangeAgencyJobStatusTypeForAgencyOrganizationMutationHookResult = ReturnType<
  typeof useChangeAgencyJobStatusTypeForAgencyOrganizationMutation
>;
export type ChangeAgencyJobStatusTypeForAgencyOrganizationMutationResult =
  Apollo.MutationResult<ChangeAgencyJobStatusTypeForAgencyOrganizationMutation>;
export type ChangeAgencyJobStatusTypeForAgencyOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutation,
    ChangeAgencyJobStatusTypeForAgencyOrganizationMutationVariables
  >;
export const CreateOrUpdateJobForAgencyOrganizationDocument = gql`
  mutation CreateOrUpdateJobForAgencyOrganization(
    $agencyJobInput: AgencyJobInput!
    $clientJobByAgencyInput: ClientJobByAgencyInput!
    $jobInput: JobInput!
    $courierOrganizationUserIds: [String!]
  ) {
    createOrUpdateJobForAgencyOrganization(
      agencyJobInput: $agencyJobInput
      clientJobByAgencyInput: $clientJobByAgencyInput
      jobInput: $jobInput
      courierOrganizationUserIds: $courierOrganizationUserIds
    ) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      internalNotes
      comment
      fixedRate
      dailyRate
      kickOffFee
      hotelBudget
      currency
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isDrivingLicenseRequiredForJob
      isCarRequiredForJob
      jobDetails
      numOfCouriersRequired
      hasIncident
      isAdditionalCostPaidByCourier
      additionalCostInformation
      startAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        numOfItems
        weightOfItems
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      clientJob {
        id
        organizationName
        organizationLegalName
        organizationPhone
        organizationEmail
        externalJobReference
        comment
        fixedRate
        currency
        hasIncident
      }
      courierJobs {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        fixedRate
        dailyRate
        hotelBudget
        kickOffFee
        isAccommodationBookedByCourier
        isAccommodationPaidByCourier
        hasIncident
        isAdditionalCostPaidByCourier
        additionalCostInformation
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
          sex
          dateOfBirth
          placeOfBirth
          countryOfBirth
          language
          nationalities
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
      }
    }
  }
`;
export type CreateOrUpdateJobForAgencyOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrUpdateJobForAgencyOrganizationMutation,
  CreateOrUpdateJobForAgencyOrganizationMutationVariables
>;
export type CreateOrUpdateJobForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOrUpdateJobForAgencyOrganizationMutation,
    CreateOrUpdateJobForAgencyOrganizationMutationVariables
  >;
} & TChildProps;
export function withCreateOrUpdateJobForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrUpdateJobForAgencyOrganizationMutation,
    CreateOrUpdateJobForAgencyOrganizationMutationVariables,
    CreateOrUpdateJobForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrUpdateJobForAgencyOrganizationMutation,
    CreateOrUpdateJobForAgencyOrganizationMutationVariables,
    CreateOrUpdateJobForAgencyOrganizationProps<TChildProps, TDataName>
  >(CreateOrUpdateJobForAgencyOrganizationDocument, {
    alias: 'createOrUpdateJobForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useCreateOrUpdateJobForAgencyOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateJobForAgencyOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateJobForAgencyOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateJobForAgencyOrganizationMutation, { data, loading, error }] = useCreateOrUpdateJobForAgencyOrganizationMutation({
 *   variables: {
 *      agencyJobInput: // value for 'agencyJobInput'
 *      clientJobByAgencyInput: // value for 'clientJobByAgencyInput'
 *      jobInput: // value for 'jobInput'
 *      courierOrganizationUserIds: // value for 'courierOrganizationUserIds'
 *   },
 * });
 */
export function useCreateOrUpdateJobForAgencyOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateJobForAgencyOrganizationMutation,
    CreateOrUpdateJobForAgencyOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateJobForAgencyOrganizationMutation,
    CreateOrUpdateJobForAgencyOrganizationMutationVariables
  >(CreateOrUpdateJobForAgencyOrganizationDocument, options);
}
export type CreateOrUpdateJobForAgencyOrganizationMutationHookResult = ReturnType<
  typeof useCreateOrUpdateJobForAgencyOrganizationMutation
>;
export type CreateOrUpdateJobForAgencyOrganizationMutationResult =
  Apollo.MutationResult<CreateOrUpdateJobForAgencyOrganizationMutation>;
export type CreateOrUpdateJobForAgencyOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateJobForAgencyOrganizationMutation,
  CreateOrUpdateJobForAgencyOrganizationMutationVariables
>;
export const CreateChatMessageDocument = gql`
  mutation CreateChatMessage($createChatMessageInput: CreateChatMessageInput!) {
    createChatMessage(createChatMessageInput: $createChatMessageInput) {
      node {
        id
        message
        messageType
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
export type CreateChatMessageMutationFn = Apollo.MutationFunction<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>;
export type CreateChatMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateChatMessageMutation,
    CreateChatMessageMutationVariables
  >;
} & TChildProps;
export function withCreateChatMessage<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateChatMessageMutation,
    CreateChatMessageMutationVariables,
    CreateChatMessageProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateChatMessageMutation,
    CreateChatMessageMutationVariables,
    CreateChatMessageProps<TChildProps, TDataName>
  >(CreateChatMessageDocument, {
    alias: 'createChatMessage',
    ...operationOptions,
  });
}

/**
 * __useCreateChatMessageMutation__
 *
 * To run a mutation, you first call `useCreateChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMessageMutation, { data, loading, error }] = useCreateChatMessageMutation({
 *   variables: {
 *      createChatMessageInput: // value for 'createChatMessageInput'
 *   },
 * });
 */
export function useCreateChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatMessageMutation,
    CreateChatMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatMessageMutation, CreateChatMessageMutationVariables>(
    CreateChatMessageDocument,
    options,
  );
}
export type CreateChatMessageMutationHookResult = ReturnType<typeof useCreateChatMessageMutation>;
export type CreateChatMessageMutationResult = Apollo.MutationResult<CreateChatMessageMutation>;
export type CreateChatMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>;
export const UpdateChatMessageDocument = gql`
  mutation UpdateChatMessage($updateChatMessageInput: UpdateChatMessageInput!) {
    updateChatMessage(updateChatMessageInput: $updateChatMessageInput) {
      node {
        id
        message
        messageType
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
export type UpdateChatMessageMutationFn = Apollo.MutationFunction<
  UpdateChatMessageMutation,
  UpdateChatMessageMutationVariables
>;
export type UpdateChatMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChatMessageMutation,
    UpdateChatMessageMutationVariables
  >;
} & TChildProps;
export function withUpdateChatMessage<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChatMessageMutation,
    UpdateChatMessageMutationVariables,
    UpdateChatMessageProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChatMessageMutation,
    UpdateChatMessageMutationVariables,
    UpdateChatMessageProps<TChildProps, TDataName>
  >(UpdateChatMessageDocument, {
    alias: 'updateChatMessage',
    ...operationOptions,
  });
}

/**
 * __useUpdateChatMessageMutation__
 *
 * To run a mutation, you first call `useUpdateChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatMessageMutation, { data, loading, error }] = useUpdateChatMessageMutation({
 *   variables: {
 *      updateChatMessageInput: // value for 'updateChatMessageInput'
 *   },
 * });
 */
export function useUpdateChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatMessageMutation,
    UpdateChatMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChatMessageMutation, UpdateChatMessageMutationVariables>(
    UpdateChatMessageDocument,
    options,
  );
}
export type UpdateChatMessageMutationHookResult = ReturnType<typeof useUpdateChatMessageMutation>;
export type UpdateChatMessageMutationResult = Apollo.MutationResult<UpdateChatMessageMutation>;
export type UpdateChatMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatMessageMutation,
  UpdateChatMessageMutationVariables
>;
export const DeleteChatMessageDocument = gql`
  mutation DeleteChatMessage($deleteChatMessageInput: DeleteChatMessageInput!) {
    deleteChatMessage(deleteChatMessageInput: $deleteChatMessageInput) {
      id
    }
  }
`;
export type DeleteChatMessageMutationFn = Apollo.MutationFunction<
  DeleteChatMessageMutation,
  DeleteChatMessageMutationVariables
>;
export type DeleteChatMessageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteChatMessageMutation,
    DeleteChatMessageMutationVariables
  >;
} & TChildProps;
export function withDeleteChatMessage<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteChatMessageMutation,
    DeleteChatMessageMutationVariables,
    DeleteChatMessageProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteChatMessageMutation,
    DeleteChatMessageMutationVariables,
    DeleteChatMessageProps<TChildProps, TDataName>
  >(DeleteChatMessageDocument, {
    alias: 'deleteChatMessage',
    ...operationOptions,
  });
}

/**
 * __useDeleteChatMessageMutation__
 *
 * To run a mutation, you first call `useDeleteChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatMessageMutation, { data, loading, error }] = useDeleteChatMessageMutation({
 *   variables: {
 *      deleteChatMessageInput: // value for 'deleteChatMessageInput'
 *   },
 * });
 */
export function useDeleteChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteChatMessageMutation,
    DeleteChatMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>(
    DeleteChatMessageDocument,
    options,
  );
}
export type DeleteChatMessageMutationHookResult = ReturnType<typeof useDeleteChatMessageMutation>;
export type DeleteChatMessageMutationResult = Apollo.MutationResult<DeleteChatMessageMutation>;
export type DeleteChatMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteChatMessageMutation,
  DeleteChatMessageMutationVariables
>;
export const UpdateAgencyOrganizationProfileDocument = gql`
  mutation UpdateAgencyOrganizationProfile(
    $agencyOrganizationProfileInput: AgencyOrganizationProfileInput!
  ) {
    updateAgencyOrganizationProfile(
      agencyOrganizationProfileInput: $agencyOrganizationProfileInput
    ) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      socialProfiles {
        platform
        type
        value
      }
      headquartersAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoicingAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      registrationAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoiceEmail
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UpdateAgencyOrganizationProfileMutationFn = Apollo.MutationFunction<
  UpdateAgencyOrganizationProfileMutation,
  UpdateAgencyOrganizationProfileMutationVariables
>;
export type UpdateAgencyOrganizationProfileProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAgencyOrganizationProfileMutation,
    UpdateAgencyOrganizationProfileMutationVariables
  >;
} & TChildProps;
export function withUpdateAgencyOrganizationProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAgencyOrganizationProfileMutation,
    UpdateAgencyOrganizationProfileMutationVariables,
    UpdateAgencyOrganizationProfileProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAgencyOrganizationProfileMutation,
    UpdateAgencyOrganizationProfileMutationVariables,
    UpdateAgencyOrganizationProfileProps<TChildProps, TDataName>
  >(UpdateAgencyOrganizationProfileDocument, {
    alias: 'updateAgencyOrganizationProfile',
    ...operationOptions,
  });
}

/**
 * __useUpdateAgencyOrganizationProfileMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyOrganizationProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyOrganizationProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyOrganizationProfileMutation, { data, loading, error }] = useUpdateAgencyOrganizationProfileMutation({
 *   variables: {
 *      agencyOrganizationProfileInput: // value for 'agencyOrganizationProfileInput'
 *   },
 * });
 */
export function useUpdateAgencyOrganizationProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAgencyOrganizationProfileMutation,
    UpdateAgencyOrganizationProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAgencyOrganizationProfileMutation,
    UpdateAgencyOrganizationProfileMutationVariables
  >(UpdateAgencyOrganizationProfileDocument, options);
}
export type UpdateAgencyOrganizationProfileMutationHookResult = ReturnType<
  typeof useUpdateAgencyOrganizationProfileMutation
>;
export type UpdateAgencyOrganizationProfileMutationResult =
  Apollo.MutationResult<UpdateAgencyOrganizationProfileMutation>;
export type UpdateAgencyOrganizationProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgencyOrganizationProfileMutation,
  UpdateAgencyOrganizationProfileMutationVariables
>;
export const UpdateAgencyOrganizationVatIdDocument = gql`
  mutation UpdateAgencyOrganizationVatId(
    $agencyOrganizationVatIdInput: AgencyOrganizationVatIdInput!
  ) {
    updateAgencyOrganizationVatId(agencyOrganizationVatIdInput: $agencyOrganizationVatIdInput) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
    }
  }
`;
export type UpdateAgencyOrganizationVatIdMutationFn = Apollo.MutationFunction<
  UpdateAgencyOrganizationVatIdMutation,
  UpdateAgencyOrganizationVatIdMutationVariables
>;
export type UpdateAgencyOrganizationVatIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateAgencyOrganizationVatIdMutation,
    UpdateAgencyOrganizationVatIdMutationVariables
  >;
} & TChildProps;
export function withUpdateAgencyOrganizationVatId<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAgencyOrganizationVatIdMutation,
    UpdateAgencyOrganizationVatIdMutationVariables,
    UpdateAgencyOrganizationVatIdProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAgencyOrganizationVatIdMutation,
    UpdateAgencyOrganizationVatIdMutationVariables,
    UpdateAgencyOrganizationVatIdProps<TChildProps, TDataName>
  >(UpdateAgencyOrganizationVatIdDocument, {
    alias: 'updateAgencyOrganizationVatId',
    ...operationOptions,
  });
}

/**
 * __useUpdateAgencyOrganizationVatIdMutation__
 *
 * To run a mutation, you first call `useUpdateAgencyOrganizationVatIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgencyOrganizationVatIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgencyOrganizationVatIdMutation, { data, loading, error }] = useUpdateAgencyOrganizationVatIdMutation({
 *   variables: {
 *      agencyOrganizationVatIdInput: // value for 'agencyOrganizationVatIdInput'
 *   },
 * });
 */
export function useUpdateAgencyOrganizationVatIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAgencyOrganizationVatIdMutation,
    UpdateAgencyOrganizationVatIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAgencyOrganizationVatIdMutation,
    UpdateAgencyOrganizationVatIdMutationVariables
  >(UpdateAgencyOrganizationVatIdDocument, options);
}
export type UpdateAgencyOrganizationVatIdMutationHookResult = ReturnType<
  typeof useUpdateAgencyOrganizationVatIdMutation
>;
export type UpdateAgencyOrganizationVatIdMutationResult =
  Apollo.MutationResult<UpdateAgencyOrganizationVatIdMutation>;
export type UpdateAgencyOrganizationVatIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateAgencyOrganizationVatIdMutation,
  UpdateAgencyOrganizationVatIdMutationVariables
>;
export const DeleteOrganizationLogoDocument = gql`
  mutation deleteOrganizationLogo($organizationId: String!) {
    deleteOrganizationLogo(organizationId: $organizationId) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type DeleteOrganizationLogoMutationFn = Apollo.MutationFunction<
  DeleteOrganizationLogoMutation,
  DeleteOrganizationLogoMutationVariables
>;
export type DeleteOrganizationLogoProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteOrganizationLogoMutation,
    DeleteOrganizationLogoMutationVariables
  >;
} & TChildProps;
export function withDeleteOrganizationLogo<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteOrganizationLogoMutation,
    DeleteOrganizationLogoMutationVariables,
    DeleteOrganizationLogoProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteOrganizationLogoMutation,
    DeleteOrganizationLogoMutationVariables,
    DeleteOrganizationLogoProps<TChildProps, TDataName>
  >(DeleteOrganizationLogoDocument, {
    alias: 'deleteOrganizationLogo',
    ...operationOptions,
  });
}

/**
 * __useDeleteOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationLogoMutation, { data, loading, error }] = useDeleteOrganizationLogoMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeleteOrganizationLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrganizationLogoMutation,
    DeleteOrganizationLogoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrganizationLogoMutation,
    DeleteOrganizationLogoMutationVariables
  >(DeleteOrganizationLogoDocument, options);
}
export type DeleteOrganizationLogoMutationHookResult = ReturnType<
  typeof useDeleteOrganizationLogoMutation
>;
export type DeleteOrganizationLogoMutationResult =
  Apollo.MutationResult<DeleteOrganizationLogoMutation>;
export type DeleteOrganizationLogoMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrganizationLogoMutation,
  DeleteOrganizationLogoMutationVariables
>;
export const UpdateCourierOrganizationProfileDocument = gql`
  mutation UpdateCourierOrganizationProfile(
    $courierOrganizationProfileInput: CourierOrganizationProfileInput!
  ) {
    updateCourierOrganizationProfile(
      courierOrganizationProfileInput: $courierOrganizationProfileInput
    ) {
      id
      name
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      socialProfiles {
        platform
        type
        value
      }
      headquartersAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoicingAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      registrationAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UpdateCourierOrganizationProfileMutationFn = Apollo.MutationFunction<
  UpdateCourierOrganizationProfileMutation,
  UpdateCourierOrganizationProfileMutationVariables
>;
export type UpdateCourierOrganizationProfileProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateCourierOrganizationProfileMutation,
    UpdateCourierOrganizationProfileMutationVariables
  >;
} & TChildProps;
export function withUpdateCourierOrganizationProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCourierOrganizationProfileMutation,
    UpdateCourierOrganizationProfileMutationVariables,
    UpdateCourierOrganizationProfileProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCourierOrganizationProfileMutation,
    UpdateCourierOrganizationProfileMutationVariables,
    UpdateCourierOrganizationProfileProps<TChildProps, TDataName>
  >(UpdateCourierOrganizationProfileDocument, {
    alias: 'updateCourierOrganizationProfile',
    ...operationOptions,
  });
}

/**
 * __useUpdateCourierOrganizationProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCourierOrganizationProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourierOrganizationProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourierOrganizationProfileMutation, { data, loading, error }] = useUpdateCourierOrganizationProfileMutation({
 *   variables: {
 *      courierOrganizationProfileInput: // value for 'courierOrganizationProfileInput'
 *   },
 * });
 */
export function useUpdateCourierOrganizationProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourierOrganizationProfileMutation,
    UpdateCourierOrganizationProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCourierOrganizationProfileMutation,
    UpdateCourierOrganizationProfileMutationVariables
  >(UpdateCourierOrganizationProfileDocument, options);
}
export type UpdateCourierOrganizationProfileMutationHookResult = ReturnType<
  typeof useUpdateCourierOrganizationProfileMutation
>;
export type UpdateCourierOrganizationProfileMutationResult =
  Apollo.MutationResult<UpdateCourierOrganizationProfileMutation>;
export type UpdateCourierOrganizationProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourierOrganizationProfileMutation,
  UpdateCourierOrganizationProfileMutationVariables
>;
export const DeleteUserBackgroundDocumentDocument = gql`
  mutation DeleteUserBackgroundDocument($documentId: String!) {
    deleteUserBackgroundDocument(documentId: $documentId) {
      id
    }
  }
`;
export type DeleteUserBackgroundDocumentMutationFn = Apollo.MutationFunction<
  DeleteUserBackgroundDocumentMutation,
  DeleteUserBackgroundDocumentMutationVariables
>;
export type DeleteUserBackgroundDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserBackgroundDocumentMutation,
    DeleteUserBackgroundDocumentMutationVariables
  >;
} & TChildProps;
export function withDeleteUserBackgroundDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserBackgroundDocumentMutation,
    DeleteUserBackgroundDocumentMutationVariables,
    DeleteUserBackgroundDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserBackgroundDocumentMutation,
    DeleteUserBackgroundDocumentMutationVariables,
    DeleteUserBackgroundDocumentProps<TChildProps, TDataName>
  >(DeleteUserBackgroundDocumentDocument, {
    alias: 'deleteUserBackgroundDocument',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserBackgroundDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteUserBackgroundDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserBackgroundDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserBackgroundDocumentMutation, { data, loading, error }] = useDeleteUserBackgroundDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteUserBackgroundDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserBackgroundDocumentMutation,
    DeleteUserBackgroundDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserBackgroundDocumentMutation,
    DeleteUserBackgroundDocumentMutationVariables
  >(DeleteUserBackgroundDocumentDocument, options);
}
export type DeleteUserBackgroundDocumentMutationHookResult = ReturnType<
  typeof useDeleteUserBackgroundDocumentMutation
>;
export type DeleteUserBackgroundDocumentMutationResult =
  Apollo.MutationResult<DeleteUserBackgroundDocumentMutation>;
export type DeleteUserBackgroundDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserBackgroundDocumentMutation,
  DeleteUserBackgroundDocumentMutationVariables
>;
export const DeleteUserFrequentFlyerDocumentDocument = gql`
  mutation DeleteUserFrequentFlyerDocument($documentId: String!) {
    deleteUserFrequentFlyerDocument(documentId: $documentId) {
      id
    }
  }
`;
export type DeleteUserFrequentFlyerDocumentMutationFn = Apollo.MutationFunction<
  DeleteUserFrequentFlyerDocumentMutation,
  DeleteUserFrequentFlyerDocumentMutationVariables
>;
export type DeleteUserFrequentFlyerDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserFrequentFlyerDocumentMutation,
    DeleteUserFrequentFlyerDocumentMutationVariables
  >;
} & TChildProps;
export function withDeleteUserFrequentFlyerDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserFrequentFlyerDocumentMutation,
    DeleteUserFrequentFlyerDocumentMutationVariables,
    DeleteUserFrequentFlyerDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserFrequentFlyerDocumentMutation,
    DeleteUserFrequentFlyerDocumentMutationVariables,
    DeleteUserFrequentFlyerDocumentProps<TChildProps, TDataName>
  >(DeleteUserFrequentFlyerDocumentDocument, {
    alias: 'deleteUserFrequentFlyerDocument',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserFrequentFlyerDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteUserFrequentFlyerDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserFrequentFlyerDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserFrequentFlyerDocumentMutation, { data, loading, error }] = useDeleteUserFrequentFlyerDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteUserFrequentFlyerDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserFrequentFlyerDocumentMutation,
    DeleteUserFrequentFlyerDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserFrequentFlyerDocumentMutation,
    DeleteUserFrequentFlyerDocumentMutationVariables
  >(DeleteUserFrequentFlyerDocumentDocument, options);
}
export type DeleteUserFrequentFlyerDocumentMutationHookResult = ReturnType<
  typeof useDeleteUserFrequentFlyerDocumentMutation
>;
export type DeleteUserFrequentFlyerDocumentMutationResult =
  Apollo.MutationResult<DeleteUserFrequentFlyerDocumentMutation>;
export type DeleteUserFrequentFlyerDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserFrequentFlyerDocumentMutation,
  DeleteUserFrequentFlyerDocumentMutationVariables
>;
export const DeleteUserHealthReportDocument = gql`
  mutation DeleteUserHealthReport($documentId: String!) {
    deleteUserHealthReport(documentId: $documentId) {
      id
    }
  }
`;
export type DeleteUserHealthReportMutationFn = Apollo.MutationFunction<
  DeleteUserHealthReportMutation,
  DeleteUserHealthReportMutationVariables
>;
export type DeleteUserHealthReportProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserHealthReportMutation,
    DeleteUserHealthReportMutationVariables
  >;
} & TChildProps;
export function withDeleteUserHealthReport<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserHealthReportMutation,
    DeleteUserHealthReportMutationVariables,
    DeleteUserHealthReportProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserHealthReportMutation,
    DeleteUserHealthReportMutationVariables,
    DeleteUserHealthReportProps<TChildProps, TDataName>
  >(DeleteUserHealthReportDocument, {
    alias: 'deleteUserHealthReport',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserHealthReportMutation__
 *
 * To run a mutation, you first call `useDeleteUserHealthReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserHealthReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserHealthReportMutation, { data, loading, error }] = useDeleteUserHealthReportMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteUserHealthReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserHealthReportMutation,
    DeleteUserHealthReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserHealthReportMutation,
    DeleteUserHealthReportMutationVariables
  >(DeleteUserHealthReportDocument, options);
}
export type DeleteUserHealthReportMutationHookResult = ReturnType<
  typeof useDeleteUserHealthReportMutation
>;
export type DeleteUserHealthReportMutationResult =
  Apollo.MutationResult<DeleteUserHealthReportMutation>;
export type DeleteUserHealthReportMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserHealthReportMutation,
  DeleteUserHealthReportMutationVariables
>;
export const DeleteUserIdentityDocumentDocument = gql`
  mutation DeleteUserIdentityDocument($documentId: String!) {
    deleteUserIdentityDocument(documentId: $documentId) {
      id
    }
  }
`;
export type DeleteUserIdentityDocumentMutationFn = Apollo.MutationFunction<
  DeleteUserIdentityDocumentMutation,
  DeleteUserIdentityDocumentMutationVariables
>;
export type DeleteUserIdentityDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserIdentityDocumentMutation,
    DeleteUserIdentityDocumentMutationVariables
  >;
} & TChildProps;
export function withDeleteUserIdentityDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserIdentityDocumentMutation,
    DeleteUserIdentityDocumentMutationVariables,
    DeleteUserIdentityDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserIdentityDocumentMutation,
    DeleteUserIdentityDocumentMutationVariables,
    DeleteUserIdentityDocumentProps<TChildProps, TDataName>
  >(DeleteUserIdentityDocumentDocument, {
    alias: 'deleteUserIdentityDocument',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserIdentityDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteUserIdentityDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserIdentityDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserIdentityDocumentMutation, { data, loading, error }] = useDeleteUserIdentityDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteUserIdentityDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserIdentityDocumentMutation,
    DeleteUserIdentityDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserIdentityDocumentMutation,
    DeleteUserIdentityDocumentMutationVariables
  >(DeleteUserIdentityDocumentDocument, options);
}
export type DeleteUserIdentityDocumentMutationHookResult = ReturnType<
  typeof useDeleteUserIdentityDocumentMutation
>;
export type DeleteUserIdentityDocumentMutationResult =
  Apollo.MutationResult<DeleteUserIdentityDocumentMutation>;
export type DeleteUserIdentityDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserIdentityDocumentMutation,
  DeleteUserIdentityDocumentMutationVariables
>;
export const UserOtherDocumentDeleteDocument = gql`
  mutation UserOtherDocumentDelete($documentId: String!) {
    deleteUserOtherDocument(documentId: $documentId) {
      id
    }
  }
`;
export type UserOtherDocumentDeleteMutationFn = Apollo.MutationFunction<
  UserOtherDocumentDeleteMutation,
  UserOtherDocumentDeleteMutationVariables
>;
export type UserOtherDocumentDeleteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UserOtherDocumentDeleteMutation,
    UserOtherDocumentDeleteMutationVariables
  >;
} & TChildProps;
export function withUserOtherDocumentDelete<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserOtherDocumentDeleteMutation,
    UserOtherDocumentDeleteMutationVariables,
    UserOtherDocumentDeleteProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UserOtherDocumentDeleteMutation,
    UserOtherDocumentDeleteMutationVariables,
    UserOtherDocumentDeleteProps<TChildProps, TDataName>
  >(UserOtherDocumentDeleteDocument, {
    alias: 'userOtherDocumentDelete',
    ...operationOptions,
  });
}

/**
 * __useUserOtherDocumentDeleteMutation__
 *
 * To run a mutation, you first call `useUserOtherDocumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOtherDocumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOtherDocumentDeleteMutation, { data, loading, error }] = useUserOtherDocumentDeleteMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserOtherDocumentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserOtherDocumentDeleteMutation,
    UserOtherDocumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserOtherDocumentDeleteMutation,
    UserOtherDocumentDeleteMutationVariables
  >(UserOtherDocumentDeleteDocument, options);
}
export type UserOtherDocumentDeleteMutationHookResult = ReturnType<
  typeof useUserOtherDocumentDeleteMutation
>;
export type UserOtherDocumentDeleteMutationResult =
  Apollo.MutationResult<UserOtherDocumentDeleteMutation>;
export type UserOtherDocumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserOtherDocumentDeleteMutation,
  UserOtherDocumentDeleteMutationVariables
>;
export const DeleteUserTravelDocumentDocument = gql`
  mutation DeleteUserTravelDocument($documentId: String!) {
    deleteUserTravelDocument(documentId: $documentId) {
      id
    }
  }
`;
export type DeleteUserTravelDocumentMutationFn = Apollo.MutationFunction<
  DeleteUserTravelDocumentMutation,
  DeleteUserTravelDocumentMutationVariables
>;
export type DeleteUserTravelDocumentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserTravelDocumentMutation,
    DeleteUserTravelDocumentMutationVariables
  >;
} & TChildProps;
export function withDeleteUserTravelDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserTravelDocumentMutation,
    DeleteUserTravelDocumentMutationVariables,
    DeleteUserTravelDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserTravelDocumentMutation,
    DeleteUserTravelDocumentMutationVariables,
    DeleteUserTravelDocumentProps<TChildProps, TDataName>
  >(DeleteUserTravelDocumentDocument, {
    alias: 'deleteUserTravelDocument',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserTravelDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteUserTravelDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserTravelDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserTravelDocumentMutation, { data, loading, error }] = useDeleteUserTravelDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteUserTravelDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserTravelDocumentMutation,
    DeleteUserTravelDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserTravelDocumentMutation,
    DeleteUserTravelDocumentMutationVariables
  >(DeleteUserTravelDocumentDocument, options);
}
export type DeleteUserTravelDocumentMutationHookResult = ReturnType<
  typeof useDeleteUserTravelDocumentMutation
>;
export type DeleteUserTravelDocumentMutationResult =
  Apollo.MutationResult<DeleteUserTravelDocumentMutation>;
export type DeleteUserTravelDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserTravelDocumentMutation,
  DeleteUserTravelDocumentMutationVariables
>;
export const DeleteUserVaccinationDocumentDocument = gql`
  mutation DeleteUserVaccinationDocument($documentId: String!) {
    deleteUserVaccinationDocument(documentId: $documentId) {
      id
    }
  }
`;
export type DeleteUserVaccinationDocumentMutationFn = Apollo.MutationFunction<
  DeleteUserVaccinationDocumentMutation,
  DeleteUserVaccinationDocumentMutationVariables
>;
export type DeleteUserVaccinationDocumentProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteUserVaccinationDocumentMutation,
    DeleteUserVaccinationDocumentMutationVariables
  >;
} & TChildProps;
export function withDeleteUserVaccinationDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserVaccinationDocumentMutation,
    DeleteUserVaccinationDocumentMutationVariables,
    DeleteUserVaccinationDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserVaccinationDocumentMutation,
    DeleteUserVaccinationDocumentMutationVariables,
    DeleteUserVaccinationDocumentProps<TChildProps, TDataName>
  >(DeleteUserVaccinationDocumentDocument, {
    alias: 'deleteUserVaccinationDocument',
    ...operationOptions,
  });
}

/**
 * __useDeleteUserVaccinationDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteUserVaccinationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserVaccinationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserVaccinationDocumentMutation, { data, loading, error }] = useDeleteUserVaccinationDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteUserVaccinationDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserVaccinationDocumentMutation,
    DeleteUserVaccinationDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserVaccinationDocumentMutation,
    DeleteUserVaccinationDocumentMutationVariables
  >(DeleteUserVaccinationDocumentDocument, options);
}
export type DeleteUserVaccinationDocumentMutationHookResult = ReturnType<
  typeof useDeleteUserVaccinationDocumentMutation
>;
export type DeleteUserVaccinationDocumentMutationResult =
  Apollo.MutationResult<DeleteUserVaccinationDocumentMutation>;
export type DeleteUserVaccinationDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserVaccinationDocumentMutation,
  DeleteUserVaccinationDocumentMutationVariables
>;
export const DeleteAgencyAccountDocument = gql`
  mutation DeleteAgencyAccount($userId: String!) {
    deleteAgencyAccount(userId: $userId)
  }
`;
export type DeleteAgencyAccountMutationFn = Apollo.MutationFunction<
  DeleteAgencyAccountMutation,
  DeleteAgencyAccountMutationVariables
>;
export type DeleteAgencyAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteAgencyAccountMutation,
    DeleteAgencyAccountMutationVariables
  >;
} & TChildProps;
export function withDeleteAgencyAccount<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteAgencyAccountMutation,
    DeleteAgencyAccountMutationVariables,
    DeleteAgencyAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteAgencyAccountMutation,
    DeleteAgencyAccountMutationVariables,
    DeleteAgencyAccountProps<TChildProps, TDataName>
  >(DeleteAgencyAccountDocument, {
    alias: 'deleteAgencyAccount',
    ...operationOptions,
  });
}

/**
 * __useDeleteAgencyAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAgencyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgencyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgencyAccountMutation, { data, loading, error }] = useDeleteAgencyAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteAgencyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAgencyAccountMutation,
    DeleteAgencyAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAgencyAccountMutation, DeleteAgencyAccountMutationVariables>(
    DeleteAgencyAccountDocument,
    options,
  );
}
export type DeleteAgencyAccountMutationHookResult = ReturnType<
  typeof useDeleteAgencyAccountMutation
>;
export type DeleteAgencyAccountMutationResult = Apollo.MutationResult<DeleteAgencyAccountMutation>;
export type DeleteAgencyAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAgencyAccountMutation,
  DeleteAgencyAccountMutationVariables
>;
export const DeleteCourierAccountDocument = gql`
  mutation DeleteCourierAccount($userId: String!) {
    deleteCourierAccount(userId: $userId)
  }
`;
export type DeleteCourierAccountMutationFn = Apollo.MutationFunction<
  DeleteCourierAccountMutation,
  DeleteCourierAccountMutationVariables
>;
export type DeleteCourierAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    DeleteCourierAccountMutation,
    DeleteCourierAccountMutationVariables
  >;
} & TChildProps;
export function withDeleteCourierAccount<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCourierAccountMutation,
    DeleteCourierAccountMutationVariables,
    DeleteCourierAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCourierAccountMutation,
    DeleteCourierAccountMutationVariables,
    DeleteCourierAccountProps<TChildProps, TDataName>
  >(DeleteCourierAccountDocument, {
    alias: 'deleteCourierAccount',
    ...operationOptions,
  });
}

/**
 * __useDeleteCourierAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCourierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourierAccountMutation, { data, loading, error }] = useDeleteCourierAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteCourierAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCourierAccountMutation,
    DeleteCourierAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourierAccountMutation, DeleteCourierAccountMutationVariables>(
    DeleteCourierAccountDocument,
    options,
  );
}
export type DeleteCourierAccountMutationHookResult = ReturnType<
  typeof useDeleteCourierAccountMutation
>;
export type DeleteCourierAccountMutationResult =
  Apollo.MutationResult<DeleteCourierAccountMutation>;
export type DeleteCourierAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourierAccountMutation,
  DeleteCourierAccountMutationVariables
>;
export const UserDeleteAccountDocument = gql`
  mutation UserDeleteAccount($userId: String!) {
    userDeleteAccount(userId: $userId)
  }
`;
export type UserDeleteAccountMutationFn = Apollo.MutationFunction<
  UserDeleteAccountMutation,
  UserDeleteAccountMutationVariables
>;
export type UserDeleteAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UserDeleteAccountMutation,
    UserDeleteAccountMutationVariables
  >;
} & TChildProps;
export function withUserDeleteAccount<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserDeleteAccountMutation,
    UserDeleteAccountMutationVariables,
    UserDeleteAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UserDeleteAccountMutation,
    UserDeleteAccountMutationVariables,
    UserDeleteAccountProps<TChildProps, TDataName>
  >(UserDeleteAccountDocument, {
    alias: 'userDeleteAccount',
    ...operationOptions,
  });
}

/**
 * __useUserDeleteAccountMutation__
 *
 * To run a mutation, you first call `useUserDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteAccountMutation, { data, loading, error }] = useUserDeleteAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserDeleteAccountMutation,
    UserDeleteAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserDeleteAccountMutation, UserDeleteAccountMutationVariables>(
    UserDeleteAccountDocument,
    options,
  );
}
export type UserDeleteAccountMutationHookResult = ReturnType<typeof useUserDeleteAccountMutation>;
export type UserDeleteAccountMutationResult = Apollo.MutationResult<UserDeleteAccountMutation>;
export type UserDeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  UserDeleteAccountMutation,
  UserDeleteAccountMutationVariables
>;
export const RestoreAgencyAccountDocument = gql`
  mutation RestoreAgencyAccount($userId: String!) {
    restoreAgencyAccount(userId: $userId) {
      id
    }
  }
`;
export type RestoreAgencyAccountMutationFn = Apollo.MutationFunction<
  RestoreAgencyAccountMutation,
  RestoreAgencyAccountMutationVariables
>;
export type RestoreAgencyAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RestoreAgencyAccountMutation,
    RestoreAgencyAccountMutationVariables
  >;
} & TChildProps;
export function withRestoreAgencyAccount<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreAgencyAccountMutation,
    RestoreAgencyAccountMutationVariables,
    RestoreAgencyAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreAgencyAccountMutation,
    RestoreAgencyAccountMutationVariables,
    RestoreAgencyAccountProps<TChildProps, TDataName>
  >(RestoreAgencyAccountDocument, {
    alias: 'restoreAgencyAccount',
    ...operationOptions,
  });
}

/**
 * __useRestoreAgencyAccountMutation__
 *
 * To run a mutation, you first call `useRestoreAgencyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreAgencyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreAgencyAccountMutation, { data, loading, error }] = useRestoreAgencyAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRestoreAgencyAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreAgencyAccountMutation,
    RestoreAgencyAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreAgencyAccountMutation, RestoreAgencyAccountMutationVariables>(
    RestoreAgencyAccountDocument,
    options,
  );
}
export type RestoreAgencyAccountMutationHookResult = ReturnType<
  typeof useRestoreAgencyAccountMutation
>;
export type RestoreAgencyAccountMutationResult =
  Apollo.MutationResult<RestoreAgencyAccountMutation>;
export type RestoreAgencyAccountMutationOptions = Apollo.BaseMutationOptions<
  RestoreAgencyAccountMutation,
  RestoreAgencyAccountMutationVariables
>;
export const RestoreCourierAccountDocument = gql`
  mutation RestoreCourierAccount($userId: String!) {
    restoreCourierAccount(userId: $userId) {
      id
    }
  }
`;
export type RestoreCourierAccountMutationFn = Apollo.MutationFunction<
  RestoreCourierAccountMutation,
  RestoreCourierAccountMutationVariables
>;
export type RestoreCourierAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    RestoreCourierAccountMutation,
    RestoreCourierAccountMutationVariables
  >;
} & TChildProps;
export function withRestoreCourierAccount<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RestoreCourierAccountMutation,
    RestoreCourierAccountMutationVariables,
    RestoreCourierAccountProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RestoreCourierAccountMutation,
    RestoreCourierAccountMutationVariables,
    RestoreCourierAccountProps<TChildProps, TDataName>
  >(RestoreCourierAccountDocument, {
    alias: 'restoreCourierAccount',
    ...operationOptions,
  });
}

/**
 * __useRestoreCourierAccountMutation__
 *
 * To run a mutation, you first call `useRestoreCourierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCourierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCourierAccountMutation, { data, loading, error }] = useRestoreCourierAccountMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRestoreCourierAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestoreCourierAccountMutation,
    RestoreCourierAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RestoreCourierAccountMutation, RestoreCourierAccountMutationVariables>(
    RestoreCourierAccountDocument,
    options,
  );
}
export type RestoreCourierAccountMutationHookResult = ReturnType<
  typeof useRestoreCourierAccountMutation
>;
export type RestoreCourierAccountMutationResult =
  Apollo.MutationResult<RestoreCourierAccountMutation>;
export type RestoreCourierAccountMutationOptions = Apollo.BaseMutationOptions<
  RestoreCourierAccountMutation,
  RestoreCourierAccountMutationVariables
>;
export const UserAccountRestoreDocument = gql`
  mutation UserAccountRestore($userId: String!) {
    userAccountRestore(userId: $userId) {
      id
    }
  }
`;
export type UserAccountRestoreMutationFn = Apollo.MutationFunction<
  UserAccountRestoreMutation,
  UserAccountRestoreMutationVariables
>;
export type UserAccountRestoreProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    UserAccountRestoreMutation,
    UserAccountRestoreMutationVariables
  >;
} & TChildProps;
export function withUserAccountRestore<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserAccountRestoreMutation,
    UserAccountRestoreMutationVariables,
    UserAccountRestoreProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UserAccountRestoreMutation,
    UserAccountRestoreMutationVariables,
    UserAccountRestoreProps<TChildProps, TDataName>
  >(UserAccountRestoreDocument, {
    alias: 'userAccountRestore',
    ...operationOptions,
  });
}

/**
 * __useUserAccountRestoreMutation__
 *
 * To run a mutation, you first call `useUserAccountRestoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAccountRestoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAccountRestoreMutation, { data, loading, error }] = useUserAccountRestoreMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserAccountRestoreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserAccountRestoreMutation,
    UserAccountRestoreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserAccountRestoreMutation, UserAccountRestoreMutationVariables>(
    UserAccountRestoreDocument,
    options,
  );
}
export type UserAccountRestoreMutationHookResult = ReturnType<typeof useUserAccountRestoreMutation>;
export type UserAccountRestoreMutationResult = Apollo.MutationResult<UserAccountRestoreMutation>;
export type UserAccountRestoreMutationOptions = Apollo.BaseMutationOptions<
  UserAccountRestoreMutation,
  UserAccountRestoreMutationVariables
>;
export const OrganizationUserUpdateProfileDocument = gql`
  mutation organizationUserUpdateProfile(
    $organizationUserUpdateProfileInput: OrganizationUserUpdateProfileInput!
  ) {
    organizationUserUpdateProfile(
      organizationUserUpdateProfileInput: $organizationUserUpdateProfileInput
    ) {
      id
      roleType
      user {
        firstNames
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        email
        phone
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
        }
      }
      organization {
        id
      }
    }
  }
`;
export type OrganizationUserUpdateProfileMutationFn = Apollo.MutationFunction<
  OrganizationUserUpdateProfileMutation,
  OrganizationUserUpdateProfileMutationVariables
>;
export type OrganizationUserUpdateProfileProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    OrganizationUserUpdateProfileMutation,
    OrganizationUserUpdateProfileMutationVariables
  >;
} & TChildProps;
export function withOrganizationUserUpdateProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationUserUpdateProfileMutation,
    OrganizationUserUpdateProfileMutationVariables,
    OrganizationUserUpdateProfileProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    OrganizationUserUpdateProfileMutation,
    OrganizationUserUpdateProfileMutationVariables,
    OrganizationUserUpdateProfileProps<TChildProps, TDataName>
  >(OrganizationUserUpdateProfileDocument, {
    alias: 'organizationUserUpdateProfile',
    ...operationOptions,
  });
}

/**
 * __useOrganizationUserUpdateProfileMutation__
 *
 * To run a mutation, you first call `useOrganizationUserUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUserUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUserUpdateProfileMutation, { data, loading, error }] = useOrganizationUserUpdateProfileMutation({
 *   variables: {
 *      organizationUserUpdateProfileInput: // value for 'organizationUserUpdateProfileInput'
 *   },
 * });
 */
export function useOrganizationUserUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationUserUpdateProfileMutation,
    OrganizationUserUpdateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationUserUpdateProfileMutation,
    OrganizationUserUpdateProfileMutationVariables
  >(OrganizationUserUpdateProfileDocument, options);
}
export type OrganizationUserUpdateProfileMutationHookResult = ReturnType<
  typeof useOrganizationUserUpdateProfileMutation
>;
export type OrganizationUserUpdateProfileMutationResult =
  Apollo.MutationResult<OrganizationUserUpdateProfileMutation>;
export type OrganizationUserUpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  OrganizationUserUpdateProfileMutation,
  OrganizationUserUpdateProfileMutationVariables
>;
export const CreateOrUpdateAgencyEmployeeUserContactDocument = gql`
  mutation CreateOrUpdateAgencyEmployeeUserContact(
    $userContactInput: UserContactInput!
    $inviteUserContact: Boolean!
    $organizationUserRole: OrganizationUserRoleType!
  ) {
    createOrUpdateAgencyEmployeeUserContact(
      userContactInput: $userContactInput
      inviteUserContact: $inviteUserContact
      organizationUserRole: $organizationUserRole
    ) {
      id
      expoContactId
      phone
      email
      firstNames
      lastName
    }
  }
`;
export type CreateOrUpdateAgencyEmployeeUserContactMutationFn = Apollo.MutationFunction<
  CreateOrUpdateAgencyEmployeeUserContactMutation,
  CreateOrUpdateAgencyEmployeeUserContactMutationVariables
>;
export type CreateOrUpdateAgencyEmployeeUserContactProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateOrUpdateAgencyEmployeeUserContactMutation,
    CreateOrUpdateAgencyEmployeeUserContactMutationVariables
  >;
} & TChildProps;
export function withCreateOrUpdateAgencyEmployeeUserContact<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrUpdateAgencyEmployeeUserContactMutation,
    CreateOrUpdateAgencyEmployeeUserContactMutationVariables,
    CreateOrUpdateAgencyEmployeeUserContactProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrUpdateAgencyEmployeeUserContactMutation,
    CreateOrUpdateAgencyEmployeeUserContactMutationVariables,
    CreateOrUpdateAgencyEmployeeUserContactProps<TChildProps, TDataName>
  >(CreateOrUpdateAgencyEmployeeUserContactDocument, {
    alias: 'createOrUpdateAgencyEmployeeUserContact',
    ...operationOptions,
  });
}

/**
 * __useCreateOrUpdateAgencyEmployeeUserContactMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateAgencyEmployeeUserContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateAgencyEmployeeUserContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateAgencyEmployeeUserContactMutation, { data, loading, error }] = useCreateOrUpdateAgencyEmployeeUserContactMutation({
 *   variables: {
 *      userContactInput: // value for 'userContactInput'
 *      inviteUserContact: // value for 'inviteUserContact'
 *      organizationUserRole: // value for 'organizationUserRole'
 *   },
 * });
 */
export function useCreateOrUpdateAgencyEmployeeUserContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrUpdateAgencyEmployeeUserContactMutation,
    CreateOrUpdateAgencyEmployeeUserContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrUpdateAgencyEmployeeUserContactMutation,
    CreateOrUpdateAgencyEmployeeUserContactMutationVariables
  >(CreateOrUpdateAgencyEmployeeUserContactDocument, options);
}
export type CreateOrUpdateAgencyEmployeeUserContactMutationHookResult = ReturnType<
  typeof useCreateOrUpdateAgencyEmployeeUserContactMutation
>;
export type CreateOrUpdateAgencyEmployeeUserContactMutationResult =
  Apollo.MutationResult<CreateOrUpdateAgencyEmployeeUserContactMutation>;
export type CreateOrUpdateAgencyEmployeeUserContactMutationOptions = Apollo.BaseMutationOptions<
  CreateOrUpdateAgencyEmployeeUserContactMutation,
  CreateOrUpdateAgencyEmployeeUserContactMutationVariables
>;
export const OrganizationInvitationDeleteDocument = gql`
  mutation OrganizationInvitationDelete($organizationInvitationId: String!) {
    organizationInvitationDelete(organizationInvitationId: $organizationInvitationId) {
      id
    }
  }
`;
export type OrganizationInvitationDeleteMutationFn = Apollo.MutationFunction<
  OrganizationInvitationDeleteMutation,
  OrganizationInvitationDeleteMutationVariables
>;
export type OrganizationInvitationDeleteProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    OrganizationInvitationDeleteMutation,
    OrganizationInvitationDeleteMutationVariables
  >;
} & TChildProps;
export function withOrganizationInvitationDelete<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationInvitationDeleteMutation,
    OrganizationInvitationDeleteMutationVariables,
    OrganizationInvitationDeleteProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    OrganizationInvitationDeleteMutation,
    OrganizationInvitationDeleteMutationVariables,
    OrganizationInvitationDeleteProps<TChildProps, TDataName>
  >(OrganizationInvitationDeleteDocument, {
    alias: 'organizationInvitationDelete',
    ...operationOptions,
  });
}

/**
 * __useOrganizationInvitationDeleteMutation__
 *
 * To run a mutation, you first call `useOrganizationInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationInvitationDeleteMutation, { data, loading, error }] = useOrganizationInvitationDeleteMutation({
 *   variables: {
 *      organizationInvitationId: // value for 'organizationInvitationId'
 *   },
 * });
 */
export function useOrganizationInvitationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationInvitationDeleteMutation,
    OrganizationInvitationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationInvitationDeleteMutation,
    OrganizationInvitationDeleteMutationVariables
  >(OrganizationInvitationDeleteDocument, options);
}
export type OrganizationInvitationDeleteMutationHookResult = ReturnType<
  typeof useOrganizationInvitationDeleteMutation
>;
export type OrganizationInvitationDeleteMutationResult =
  Apollo.MutationResult<OrganizationInvitationDeleteMutation>;
export type OrganizationInvitationDeleteMutationOptions = Apollo.BaseMutationOptions<
  OrganizationInvitationDeleteMutation,
  OrganizationInvitationDeleteMutationVariables
>;
export const OrganizationUserRemoveOrganizationUserFromOrganizationDocument = gql`
  mutation OrganizationUserRemoveOrganizationUserFromOrganization($organizationUserId: String!) {
    organizationUserRemoveOrganizationUserFromOrganization(organizationUserId: $organizationUserId)
  }
`;
export type OrganizationUserRemoveOrganizationUserFromOrganizationMutationFn =
  Apollo.MutationFunction<
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables
  >;
export type OrganizationUserRemoveOrganizationUserFromOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables
  >;
} & TChildProps;
export function withOrganizationUserRemoveOrganizationUserFromOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables,
    OrganizationUserRemoveOrganizationUserFromOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables,
    OrganizationUserRemoveOrganizationUserFromOrganizationProps<TChildProps, TDataName>
  >(OrganizationUserRemoveOrganizationUserFromOrganizationDocument, {
    alias: 'organizationUserRemoveOrganizationUserFromOrganization',
    ...operationOptions,
  });
}

/**
 * __useOrganizationUserRemoveOrganizationUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useOrganizationUserRemoveOrganizationUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUserRemoveOrganizationUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUserRemoveOrganizationUserFromOrganizationMutation, { data, loading, error }] = useOrganizationUserRemoveOrganizationUserFromOrganizationMutation({
 *   variables: {
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useOrganizationUserRemoveOrganizationUserFromOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables
  >(OrganizationUserRemoveOrganizationUserFromOrganizationDocument, options);
}
export type OrganizationUserRemoveOrganizationUserFromOrganizationMutationHookResult = ReturnType<
  typeof useOrganizationUserRemoveOrganizationUserFromOrganizationMutation
>;
export type OrganizationUserRemoveOrganizationUserFromOrganizationMutationResult =
  Apollo.MutationResult<OrganizationUserRemoveOrganizationUserFromOrganizationMutation>;
export type OrganizationUserRemoveOrganizationUserFromOrganizationMutationOptions =
  Apollo.BaseMutationOptions<
    OrganizationUserRemoveOrganizationUserFromOrganizationMutation,
    OrganizationUserRemoveOrganizationUserFromOrganizationMutationVariables
  >;
export const OrganizationSubscriptionPlanCancelDocument = gql`
  mutation OrganizationSubscriptionPlanCancel($data: OrganizationSubscriptionPlanSingleInput!) {
    organizationSubscriptionPlanCancel(data: $data) {
      id
    }
  }
`;
export type OrganizationSubscriptionPlanCancelMutationFn = Apollo.MutationFunction<
  OrganizationSubscriptionPlanCancelMutation,
  OrganizationSubscriptionPlanCancelMutationVariables
>;
export type OrganizationSubscriptionPlanCancelProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    OrganizationSubscriptionPlanCancelMutation,
    OrganizationSubscriptionPlanCancelMutationVariables
  >;
} & TChildProps;
export function withOrganizationSubscriptionPlanCancel<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationSubscriptionPlanCancelMutation,
    OrganizationSubscriptionPlanCancelMutationVariables,
    OrganizationSubscriptionPlanCancelProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    OrganizationSubscriptionPlanCancelMutation,
    OrganizationSubscriptionPlanCancelMutationVariables,
    OrganizationSubscriptionPlanCancelProps<TChildProps, TDataName>
  >(OrganizationSubscriptionPlanCancelDocument, {
    alias: 'organizationSubscriptionPlanCancel',
    ...operationOptions,
  });
}

/**
 * __useOrganizationSubscriptionPlanCancelMutation__
 *
 * To run a mutation, you first call `useOrganizationSubscriptionPlanCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionPlanCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationSubscriptionPlanCancelMutation, { data, loading, error }] = useOrganizationSubscriptionPlanCancelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationSubscriptionPlanCancelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationSubscriptionPlanCancelMutation,
    OrganizationSubscriptionPlanCancelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationSubscriptionPlanCancelMutation,
    OrganizationSubscriptionPlanCancelMutationVariables
  >(OrganizationSubscriptionPlanCancelDocument, options);
}
export type OrganizationSubscriptionPlanCancelMutationHookResult = ReturnType<
  typeof useOrganizationSubscriptionPlanCancelMutation
>;
export type OrganizationSubscriptionPlanCancelMutationResult =
  Apollo.MutationResult<OrganizationSubscriptionPlanCancelMutation>;
export type OrganizationSubscriptionPlanCancelMutationOptions = Apollo.BaseMutationOptions<
  OrganizationSubscriptionPlanCancelMutation,
  OrganizationSubscriptionPlanCancelMutationVariables
>;
export const AirportLocationUpdateTimezoneDocument = gql`
  mutation AirportLocationUpdateTimezone($data: AirportLocationUpdateTimezoneInput!) {
    airportLocationUpdateTimezone(data: $data) {
      id
      placeId
      country
      iataCode
      formattedAddress
      city
    }
  }
`;
export type AirportLocationUpdateTimezoneMutationFn = Apollo.MutationFunction<
  AirportLocationUpdateTimezoneMutation,
  AirportLocationUpdateTimezoneMutationVariables
>;
export type AirportLocationUpdateTimezoneProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AirportLocationUpdateTimezoneMutation,
    AirportLocationUpdateTimezoneMutationVariables
  >;
} & TChildProps;
export function withAirportLocationUpdateTimezone<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AirportLocationUpdateTimezoneMutation,
    AirportLocationUpdateTimezoneMutationVariables,
    AirportLocationUpdateTimezoneProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AirportLocationUpdateTimezoneMutation,
    AirportLocationUpdateTimezoneMutationVariables,
    AirportLocationUpdateTimezoneProps<TChildProps, TDataName>
  >(AirportLocationUpdateTimezoneDocument, {
    alias: 'airportLocationUpdateTimezone',
    ...operationOptions,
  });
}

/**
 * __useAirportLocationUpdateTimezoneMutation__
 *
 * To run a mutation, you first call `useAirportLocationUpdateTimezoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAirportLocationUpdateTimezoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [airportLocationUpdateTimezoneMutation, { data, loading, error }] = useAirportLocationUpdateTimezoneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAirportLocationUpdateTimezoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AirportLocationUpdateTimezoneMutation,
    AirportLocationUpdateTimezoneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AirportLocationUpdateTimezoneMutation,
    AirportLocationUpdateTimezoneMutationVariables
  >(AirportLocationUpdateTimezoneDocument, options);
}
export type AirportLocationUpdateTimezoneMutationHookResult = ReturnType<
  typeof useAirportLocationUpdateTimezoneMutation
>;
export type AirportLocationUpdateTimezoneMutationResult =
  Apollo.MutationResult<AirportLocationUpdateTimezoneMutation>;
export type AirportLocationUpdateTimezoneMutationOptions = Apollo.BaseMutationOptions<
  AirportLocationUpdateTimezoneMutation,
  AirportLocationUpdateTimezoneMutationVariables
>;
export const OrganizationCourierCategoriesUpdateDocument = gql`
  mutation organizationCourierCategoriesUpdate($data: OrganizationCourierCategoriesUpdateInput!) {
    organizationCourierCategoriesUpdate(data: $data) {
      id
      order
      label
    }
  }
`;
export type OrganizationCourierCategoriesUpdateMutationFn = Apollo.MutationFunction<
  OrganizationCourierCategoriesUpdateMutation,
  OrganizationCourierCategoriesUpdateMutationVariables
>;
export type OrganizationCourierCategoriesUpdateProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    OrganizationCourierCategoriesUpdateMutation,
    OrganizationCourierCategoriesUpdateMutationVariables
  >;
} & TChildProps;
export function withOrganizationCourierCategoriesUpdate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationCourierCategoriesUpdateMutation,
    OrganizationCourierCategoriesUpdateMutationVariables,
    OrganizationCourierCategoriesUpdateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    OrganizationCourierCategoriesUpdateMutation,
    OrganizationCourierCategoriesUpdateMutationVariables,
    OrganizationCourierCategoriesUpdateProps<TChildProps, TDataName>
  >(OrganizationCourierCategoriesUpdateDocument, {
    alias: 'organizationCourierCategoriesUpdate',
    ...operationOptions,
  });
}

/**
 * __useOrganizationCourierCategoriesUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationCourierCategoriesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCourierCategoriesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCourierCategoriesUpdateMutation, { data, loading, error }] = useOrganizationCourierCategoriesUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationCourierCategoriesUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationCourierCategoriesUpdateMutation,
    OrganizationCourierCategoriesUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationCourierCategoriesUpdateMutation,
    OrganizationCourierCategoriesUpdateMutationVariables
  >(OrganizationCourierCategoriesUpdateDocument, options);
}
export type OrganizationCourierCategoriesUpdateMutationHookResult = ReturnType<
  typeof useOrganizationCourierCategoriesUpdateMutation
>;
export type OrganizationCourierCategoriesUpdateMutationResult =
  Apollo.MutationResult<OrganizationCourierCategoriesUpdateMutation>;
export type OrganizationCourierCategoriesUpdateMutationOptions = Apollo.BaseMutationOptions<
  OrganizationCourierCategoriesUpdateMutation,
  OrganizationCourierCategoriesUpdateMutationVariables
>;
export const AgencyCourierUserProfileCreateOrUpdateDocument = gql`
  mutation agencyCourierUserProfileCreateOrUpdate(
    $data: AgencyCourierUserProfileCreateOrUpdateInput!
  ) {
    agencyCourierUserProfileCreateOrUpdate(data: $data) {
      id
      internalComment
      markedForAttention
    }
  }
`;
export type AgencyCourierUserProfileCreateOrUpdateMutationFn = Apollo.MutationFunction<
  AgencyCourierUserProfileCreateOrUpdateMutation,
  AgencyCourierUserProfileCreateOrUpdateMutationVariables
>;
export type AgencyCourierUserProfileCreateOrUpdateProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AgencyCourierUserProfileCreateOrUpdateMutation,
    AgencyCourierUserProfileCreateOrUpdateMutationVariables
  >;
} & TChildProps;
export function withAgencyCourierUserProfileCreateOrUpdate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyCourierUserProfileCreateOrUpdateMutation,
    AgencyCourierUserProfileCreateOrUpdateMutationVariables,
    AgencyCourierUserProfileCreateOrUpdateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AgencyCourierUserProfileCreateOrUpdateMutation,
    AgencyCourierUserProfileCreateOrUpdateMutationVariables,
    AgencyCourierUserProfileCreateOrUpdateProps<TChildProps, TDataName>
  >(AgencyCourierUserProfileCreateOrUpdateDocument, {
    alias: 'agencyCourierUserProfileCreateOrUpdate',
    ...operationOptions,
  });
}

/**
 * __useAgencyCourierUserProfileCreateOrUpdateMutation__
 *
 * To run a mutation, you first call `useAgencyCourierUserProfileCreateOrUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyCourierUserProfileCreateOrUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyCourierUserProfileCreateOrUpdateMutation, { data, loading, error }] = useAgencyCourierUserProfileCreateOrUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAgencyCourierUserProfileCreateOrUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyCourierUserProfileCreateOrUpdateMutation,
    AgencyCourierUserProfileCreateOrUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AgencyCourierUserProfileCreateOrUpdateMutation,
    AgencyCourierUserProfileCreateOrUpdateMutationVariables
  >(AgencyCourierUserProfileCreateOrUpdateDocument, options);
}
export type AgencyCourierUserProfileCreateOrUpdateMutationHookResult = ReturnType<
  typeof useAgencyCourierUserProfileCreateOrUpdateMutation
>;
export type AgencyCourierUserProfileCreateOrUpdateMutationResult =
  Apollo.MutationResult<AgencyCourierUserProfileCreateOrUpdateMutation>;
export type AgencyCourierUserProfileCreateOrUpdateMutationOptions = Apollo.BaseMutationOptions<
  AgencyCourierUserProfileCreateOrUpdateMutation,
  AgencyCourierUserProfileCreateOrUpdateMutationVariables
>;
export const AgencyJobUpdateInternalNotesDocument = gql`
  mutation agencyJobUpdateInternalNotes($agencyJobId: ID!, $internalNotes: String!) {
    agencyJobUpdateInternalNotes(agencyJobId: $agencyJobId, internalNotes: $internalNotes) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      comment
      fixedRate
      dailyRate
      kickOffFee
      hotelBudget
      currency
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      isDrivingLicenseRequiredForJob
      isCarRequiredForJob
      jobDetails
      numOfCouriersRequired
      hasIncident
      agencyJobStatusType
      internalNotes
      startAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        numOfItems
        weightOfItems
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      clientJob {
        id
        organizationName
        organizationLegalName
        organizationPhone
        organizationEmail
        externalJobReference
        comment
        fixedRate
        currency
        hasIncident
      }
      courierJobs {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        fixedRate
        dailyRate
        hotelBudget
        kickOffFee
        isAccommodationBookedByCourier
        isAccommodationPaidByCourier
        isAdditionalCostPaidByCourier
        additionalCostInformation
        hasIncident
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
          sex
          dateOfBirth
          placeOfBirth
          countryOfBirth
          language
          nationalities
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        courierJobAgencyJobChat {
          id
        }
      }
    }
  }
`;
export type AgencyJobUpdateInternalNotesMutationFn = Apollo.MutationFunction<
  AgencyJobUpdateInternalNotesMutation,
  AgencyJobUpdateInternalNotesMutationVariables
>;
export type AgencyJobUpdateInternalNotesProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AgencyJobUpdateInternalNotesMutation,
    AgencyJobUpdateInternalNotesMutationVariables
  >;
} & TChildProps;
export function withAgencyJobUpdateInternalNotes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobUpdateInternalNotesMutation,
    AgencyJobUpdateInternalNotesMutationVariables,
    AgencyJobUpdateInternalNotesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AgencyJobUpdateInternalNotesMutation,
    AgencyJobUpdateInternalNotesMutationVariables,
    AgencyJobUpdateInternalNotesProps<TChildProps, TDataName>
  >(AgencyJobUpdateInternalNotesDocument, {
    alias: 'agencyJobUpdateInternalNotes',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobUpdateInternalNotesMutation__
 *
 * To run a mutation, you first call `useAgencyJobUpdateInternalNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobUpdateInternalNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyJobUpdateInternalNotesMutation, { data, loading, error }] = useAgencyJobUpdateInternalNotesMutation({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *      internalNotes: // value for 'internalNotes'
 *   },
 * });
 */
export function useAgencyJobUpdateInternalNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyJobUpdateInternalNotesMutation,
    AgencyJobUpdateInternalNotesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AgencyJobUpdateInternalNotesMutation,
    AgencyJobUpdateInternalNotesMutationVariables
  >(AgencyJobUpdateInternalNotesDocument, options);
}
export type AgencyJobUpdateInternalNotesMutationHookResult = ReturnType<
  typeof useAgencyJobUpdateInternalNotesMutation
>;
export type AgencyJobUpdateInternalNotesMutationResult =
  Apollo.MutationResult<AgencyJobUpdateInternalNotesMutation>;
export type AgencyJobUpdateInternalNotesMutationOptions = Apollo.BaseMutationOptions<
  AgencyJobUpdateInternalNotesMutation,
  AgencyJobUpdateInternalNotesMutationVariables
>;
export const NotificationSettingUpdateDocument = gql`
  mutation notificationSettingUpdate($data: NotificationSettingUpdateInput!) {
    notificationSettingUpdate(data: $data) {
      id
      emailConnection
      emailJob
      pushAvailability
      pushChat
      pushConnection
      pushJob
      systemAvailability
      systemChat
      systemConnection
      systemJob
    }
  }
`;
export type NotificationSettingUpdateMutationFn = Apollo.MutationFunction<
  NotificationSettingUpdateMutation,
  NotificationSettingUpdateMutationVariables
>;
export type NotificationSettingUpdateProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables
  >;
} & TChildProps;
export function withNotificationSettingUpdate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables,
    NotificationSettingUpdateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables,
    NotificationSettingUpdateProps<TChildProps, TDataName>
  >(NotificationSettingUpdateDocument, {
    alias: 'notificationSettingUpdate',
    ...operationOptions,
  });
}

/**
 * __useNotificationSettingUpdateMutation__
 *
 * To run a mutation, you first call `useNotificationSettingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationSettingUpdateMutation, { data, loading, error }] = useNotificationSettingUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNotificationSettingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NotificationSettingUpdateMutation,
    NotificationSettingUpdateMutationVariables
  >(NotificationSettingUpdateDocument, options);
}
export type NotificationSettingUpdateMutationHookResult = ReturnType<
  typeof useNotificationSettingUpdateMutation
>;
export type NotificationSettingUpdateMutationResult =
  Apollo.MutationResult<NotificationSettingUpdateMutation>;
export type NotificationSettingUpdateMutationOptions = Apollo.BaseMutationOptions<
  NotificationSettingUpdateMutation,
  NotificationSettingUpdateMutationVariables
>;
export const AuthSignOutUserContextDocument = gql`
  mutation authSignOutUserContext($data: AuthSignOutUserContextInput!) {
    authSignOutUserContext(data: $data)
  }
`;
export type AuthSignOutUserContextMutationFn = Apollo.MutationFunction<
  AuthSignOutUserContextMutation,
  AuthSignOutUserContextMutationVariables
>;
export type AuthSignOutUserContextProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
  [key in TDataName]: Apollo.MutationFunction<
    AuthSignOutUserContextMutation,
    AuthSignOutUserContextMutationVariables
  >;
} & TChildProps;
export function withAuthSignOutUserContext<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignOutUserContextMutation,
    AuthSignOutUserContextMutationVariables,
    AuthSignOutUserContextProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignOutUserContextMutation,
    AuthSignOutUserContextMutationVariables,
    AuthSignOutUserContextProps<TChildProps, TDataName>
  >(AuthSignOutUserContextDocument, {
    alias: 'authSignOutUserContext',
    ...operationOptions,
  });
}

/**
 * __useAuthSignOutUserContextMutation__
 *
 * To run a mutation, you first call `useAuthSignOutUserContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignOutUserContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignOutUserContextMutation, { data, loading, error }] = useAuthSignOutUserContextMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAuthSignOutUserContextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthSignOutUserContextMutation,
    AuthSignOutUserContextMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthSignOutUserContextMutation,
    AuthSignOutUserContextMutationVariables
  >(AuthSignOutUserContextDocument, options);
}
export type AuthSignOutUserContextMutationHookResult = ReturnType<
  typeof useAuthSignOutUserContextMutation
>;
export type AuthSignOutUserContextMutationResult =
  Apollo.MutationResult<AuthSignOutUserContextMutation>;
export type AuthSignOutUserContextMutationOptions = Apollo.BaseMutationOptions<
  AuthSignOutUserContextMutation,
  AuthSignOutUserContextMutationVariables
>;
export const AgencyJobPlanningForAgencyJobUpdateDocument = gql`
  mutation AgencyJobPlanningForAgencyJobUpdate(
    $agencyJobId: String!
    $agencyJobPlanningId: String!
    $agencyJobPlanningForAgencyJobUpdateInput: AgencyJobPlanningForAgencyJobUpdateInput!
  ) {
    agencyJobPlanningForAgencyJobUpdate(
      agencyJobId: $agencyJobId
      agencyJobPlanningId: $agencyJobPlanningId
      agencyJobPlanningForAgencyJobUpdateInput: $agencyJobPlanningForAgencyJobUpdateInput
    ) {
      id
      agencyJobPlanningItems {
        id
        active
        actualDateTime
        actualDateTimeSetAt
        createdAt
        files {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        formType
        id
        inactivatedAt
        label
        location {
          id
          formattedAddress
        }
        name
        order
        targetDateTime
      }
      agencyJobPlanningLegs {
        id
        active
        departureDate
        order
        flightCarrierDesignation
        flightNumber
        flightTickets {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        agencyJobPlanningItems {
          id
          active
          actualDateTime
          actualDateTimeSetAt
          createdAt
          files {
            id
            storedAsset {
              sasUrl
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          formType
          id
          inactivatedAt
          label
          location {
            id
            formattedAddress
          }
          name
          order
          targetDateTime
        }
      }
    }
  }
`;
export type AgencyJobPlanningForAgencyJobUpdateMutationFn = Apollo.MutationFunction<
  AgencyJobPlanningForAgencyJobUpdateMutation,
  AgencyJobPlanningForAgencyJobUpdateMutationVariables
>;
export type AgencyJobPlanningForAgencyJobUpdateProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AgencyJobPlanningForAgencyJobUpdateMutation,
    AgencyJobPlanningForAgencyJobUpdateMutationVariables
  >;
} & TChildProps;
export function withAgencyJobPlanningForAgencyJobUpdate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobPlanningForAgencyJobUpdateMutation,
    AgencyJobPlanningForAgencyJobUpdateMutationVariables,
    AgencyJobPlanningForAgencyJobUpdateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AgencyJobPlanningForAgencyJobUpdateMutation,
    AgencyJobPlanningForAgencyJobUpdateMutationVariables,
    AgencyJobPlanningForAgencyJobUpdateProps<TChildProps, TDataName>
  >(AgencyJobPlanningForAgencyJobUpdateDocument, {
    alias: 'agencyJobPlanningForAgencyJobUpdate',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobPlanningForAgencyJobUpdateMutation__
 *
 * To run a mutation, you first call `useAgencyJobPlanningForAgencyJobUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobPlanningForAgencyJobUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyJobPlanningForAgencyJobUpdateMutation, { data, loading, error }] = useAgencyJobPlanningForAgencyJobUpdateMutation({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *      agencyJobPlanningId: // value for 'agencyJobPlanningId'
 *      agencyJobPlanningForAgencyJobUpdateInput: // value for 'agencyJobPlanningForAgencyJobUpdateInput'
 *   },
 * });
 */
export function useAgencyJobPlanningForAgencyJobUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyJobPlanningForAgencyJobUpdateMutation,
    AgencyJobPlanningForAgencyJobUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AgencyJobPlanningForAgencyJobUpdateMutation,
    AgencyJobPlanningForAgencyJobUpdateMutationVariables
  >(AgencyJobPlanningForAgencyJobUpdateDocument, options);
}
export type AgencyJobPlanningForAgencyJobUpdateMutationHookResult = ReturnType<
  typeof useAgencyJobPlanningForAgencyJobUpdateMutation
>;
export type AgencyJobPlanningForAgencyJobUpdateMutationResult =
  Apollo.MutationResult<AgencyJobPlanningForAgencyJobUpdateMutation>;
export type AgencyJobPlanningForAgencyJobUpdateMutationOptions = Apollo.BaseMutationOptions<
  AgencyJobPlanningForAgencyJobUpdateMutation,
  AgencyJobPlanningForAgencyJobUpdateMutationVariables
>;
export const AgencyJobPlanningItemUpdateDocument = gql`
  mutation agencyJobPlanningItemUpdate(
    $agencyJobPlanningId: String!
    $agencyJobPlanningItemUpdateInput: AgencyJobPlanningItemUpdateInput!
  ) {
    agencyJobPlanningItemUpdate(
      agencyJobPlanningId: $agencyJobPlanningId
      agencyJobPlanningItemUpdateInput: $agencyJobPlanningItemUpdateInput
    ) {
      id
      active
      actualDateTime
      actualDateTimeSetAt
      createdAt
      files {
        id
        storedAsset {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      formType
      id
      inactivatedAt
      label
      location {
        id
        formattedAddress
      }
      name
      order
      targetDateTime
      updatedAt
    }
  }
`;
export type AgencyJobPlanningItemUpdateMutationFn = Apollo.MutationFunction<
  AgencyJobPlanningItemUpdateMutation,
  AgencyJobPlanningItemUpdateMutationVariables
>;
export type AgencyJobPlanningItemUpdateProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AgencyJobPlanningItemUpdateMutation,
    AgencyJobPlanningItemUpdateMutationVariables
  >;
} & TChildProps;
export function withAgencyJobPlanningItemUpdate<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobPlanningItemUpdateMutation,
    AgencyJobPlanningItemUpdateMutationVariables,
    AgencyJobPlanningItemUpdateProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AgencyJobPlanningItemUpdateMutation,
    AgencyJobPlanningItemUpdateMutationVariables,
    AgencyJobPlanningItemUpdateProps<TChildProps, TDataName>
  >(AgencyJobPlanningItemUpdateDocument, {
    alias: 'agencyJobPlanningItemUpdate',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobPlanningItemUpdateMutation__
 *
 * To run a mutation, you first call `useAgencyJobPlanningItemUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobPlanningItemUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyJobPlanningItemUpdateMutation, { data, loading, error }] = useAgencyJobPlanningItemUpdateMutation({
 *   variables: {
 *      agencyJobPlanningId: // value for 'agencyJobPlanningId'
 *      agencyJobPlanningItemUpdateInput: // value for 'agencyJobPlanningItemUpdateInput'
 *   },
 * });
 */
export function useAgencyJobPlanningItemUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyJobPlanningItemUpdateMutation,
    AgencyJobPlanningItemUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AgencyJobPlanningItemUpdateMutation,
    AgencyJobPlanningItemUpdateMutationVariables
  >(AgencyJobPlanningItemUpdateDocument, options);
}
export type AgencyJobPlanningItemUpdateMutationHookResult = ReturnType<
  typeof useAgencyJobPlanningItemUpdateMutation
>;
export type AgencyJobPlanningItemUpdateMutationResult =
  Apollo.MutationResult<AgencyJobPlanningItemUpdateMutation>;
export type AgencyJobPlanningItemUpdateMutationOptions = Apollo.BaseMutationOptions<
  AgencyJobPlanningItemUpdateMutation,
  AgencyJobPlanningItemUpdateMutationVariables
>;
export const AgencyJobPlanningItemResetDocument = gql`
  mutation agencyJobPlanningItemReset(
    $agencyJobPlanningId: String!
    $agencyJobPlanningItemId: String!
  ) {
    agencyJobPlanningItemReset(
      agencyJobPlanningId: $agencyJobPlanningId
      agencyJobPlanningItemId: $agencyJobPlanningItemId
    ) {
      id
      active
      actualDateTime
      actualDateTimeSetAt
      createdAt
      files {
        id
        storedAsset {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      formType
      id
      inactivatedAt
      label
      location {
        id
        formattedAddress
      }
      name
      order
      targetDateTime
      updatedAt
    }
  }
`;
export type AgencyJobPlanningItemResetMutationFn = Apollo.MutationFunction<
  AgencyJobPlanningItemResetMutation,
  AgencyJobPlanningItemResetMutationVariables
>;
export type AgencyJobPlanningItemResetProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    AgencyJobPlanningItemResetMutation,
    AgencyJobPlanningItemResetMutationVariables
  >;
} & TChildProps;
export function withAgencyJobPlanningItemReset<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobPlanningItemResetMutation,
    AgencyJobPlanningItemResetMutationVariables,
    AgencyJobPlanningItemResetProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AgencyJobPlanningItemResetMutation,
    AgencyJobPlanningItemResetMutationVariables,
    AgencyJobPlanningItemResetProps<TChildProps, TDataName>
  >(AgencyJobPlanningItemResetDocument, {
    alias: 'agencyJobPlanningItemReset',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobPlanningItemResetMutation__
 *
 * To run a mutation, you first call `useAgencyJobPlanningItemResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobPlanningItemResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agencyJobPlanningItemResetMutation, { data, loading, error }] = useAgencyJobPlanningItemResetMutation({
 *   variables: {
 *      agencyJobPlanningId: // value for 'agencyJobPlanningId'
 *      agencyJobPlanningItemId: // value for 'agencyJobPlanningItemId'
 *   },
 * });
 */
export function useAgencyJobPlanningItemResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AgencyJobPlanningItemResetMutation,
    AgencyJobPlanningItemResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AgencyJobPlanningItemResetMutation,
    AgencyJobPlanningItemResetMutationVariables
  >(AgencyJobPlanningItemResetDocument, options);
}
export type AgencyJobPlanningItemResetMutationHookResult = ReturnType<
  typeof useAgencyJobPlanningItemResetMutation
>;
export type AgencyJobPlanningItemResetMutationResult =
  Apollo.MutationResult<AgencyJobPlanningItemResetMutation>;
export type AgencyJobPlanningItemResetMutationOptions = Apollo.BaseMutationOptions<
  AgencyJobPlanningItemResetMutation,
  AgencyJobPlanningItemResetMutationVariables
>;
export const CreateChatMessageForAgencyDocument = gql`
  mutation CreateChatMessageForAgency($createChatMessageInput: CreateChatMessageInput!) {
    createChatMessage(createChatMessageInput: $createChatMessageInput) {
      node {
        id
        message
        messageType
        agencyJobPlanningItemHistoryLog {
          id
          active
          actualDateTime
          label
          targetDateTime
          note
          agencyJobPlanningLeg {
            id
            flightCarrierDesignation
            flightNumber
            order
          }
          files {
            id
            storedAsset {
              sasUrl
              cacheKey
              cacheTTL
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          location {
            id
            formattedAddress
          }
        }
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
export type CreateChatMessageForAgencyMutationFn = Apollo.MutationFunction<
  CreateChatMessageForAgencyMutation,
  CreateChatMessageForAgencyMutationVariables
>;
export type CreateChatMessageForAgencyProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    CreateChatMessageForAgencyMutation,
    CreateChatMessageForAgencyMutationVariables
  >;
} & TChildProps;
export function withCreateChatMessageForAgency<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateChatMessageForAgencyMutation,
    CreateChatMessageForAgencyMutationVariables,
    CreateChatMessageForAgencyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateChatMessageForAgencyMutation,
    CreateChatMessageForAgencyMutationVariables,
    CreateChatMessageForAgencyProps<TChildProps, TDataName>
  >(CreateChatMessageForAgencyDocument, {
    alias: 'createChatMessageForAgency',
    ...operationOptions,
  });
}

/**
 * __useCreateChatMessageForAgencyMutation__
 *
 * To run a mutation, you first call `useCreateChatMessageForAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMessageForAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMessageForAgencyMutation, { data, loading, error }] = useCreateChatMessageForAgencyMutation({
 *   variables: {
 *      createChatMessageInput: // value for 'createChatMessageInput'
 *   },
 * });
 */
export function useCreateChatMessageForAgencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatMessageForAgencyMutation,
    CreateChatMessageForAgencyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChatMessageForAgencyMutation,
    CreateChatMessageForAgencyMutationVariables
  >(CreateChatMessageForAgencyDocument, options);
}
export type CreateChatMessageForAgencyMutationHookResult = ReturnType<
  typeof useCreateChatMessageForAgencyMutation
>;
export type CreateChatMessageForAgencyMutationResult =
  Apollo.MutationResult<CreateChatMessageForAgencyMutation>;
export type CreateChatMessageForAgencyMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMessageForAgencyMutation,
  CreateChatMessageForAgencyMutationVariables
>;
export const UpdateChatMessageForAgencyDocument = gql`
  mutation UpdateChatMessageForAgency($updateChatMessageInput: UpdateChatMessageInput!) {
    updateChatMessage(updateChatMessageInput: $updateChatMessageInput) {
      node {
        id
        message
        messageType
        agencyJobPlanningItemHistoryLog {
          id
          active
          actualDateTime
          label
          targetDateTime
          note
          agencyJobPlanningLeg {
            id
            flightCarrierDesignation
            flightNumber
            order
          }
          files {
            id
            storedAsset {
              sasUrl
              cacheKey
              cacheTTL
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          location {
            id
            formattedAddress
          }
        }
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
export type UpdateChatMessageForAgencyMutationFn = Apollo.MutationFunction<
  UpdateChatMessageForAgencyMutation,
  UpdateChatMessageForAgencyMutationVariables
>;
export type UpdateChatMessageForAgencyProps<
  TChildProps = {},
  TDataName extends string = 'mutate',
> = {
  [key in TDataName]: Apollo.MutationFunction<
    UpdateChatMessageForAgencyMutation,
    UpdateChatMessageForAgencyMutationVariables
  >;
} & TChildProps;
export function withUpdateChatMessageForAgency<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateChatMessageForAgencyMutation,
    UpdateChatMessageForAgencyMutationVariables,
    UpdateChatMessageForAgencyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateChatMessageForAgencyMutation,
    UpdateChatMessageForAgencyMutationVariables,
    UpdateChatMessageForAgencyProps<TChildProps, TDataName>
  >(UpdateChatMessageForAgencyDocument, {
    alias: 'updateChatMessageForAgency',
    ...operationOptions,
  });
}

/**
 * __useUpdateChatMessageForAgencyMutation__
 *
 * To run a mutation, you first call `useUpdateChatMessageForAgencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatMessageForAgencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatMessageForAgencyMutation, { data, loading, error }] = useUpdateChatMessageForAgencyMutation({
 *   variables: {
 *      updateChatMessageInput: // value for 'updateChatMessageInput'
 *   },
 * });
 */
export function useUpdateChatMessageForAgencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatMessageForAgencyMutation,
    UpdateChatMessageForAgencyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChatMessageForAgencyMutation,
    UpdateChatMessageForAgencyMutationVariables
  >(UpdateChatMessageForAgencyDocument, options);
}
export type UpdateChatMessageForAgencyMutationHookResult = ReturnType<
  typeof useUpdateChatMessageForAgencyMutation
>;
export type UpdateChatMessageForAgencyMutationResult =
  Apollo.MutationResult<UpdateChatMessageForAgencyMutation>;
export type UpdateChatMessageForAgencyMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatMessageForAgencyMutation,
  UpdateChatMessageForAgencyMutationVariables
>;
export const ConnectedAgencyOrganizationForCourierOrganizationDocument = gql`
  query ConnectedAgencyOrganizationForCourierOrganization(
    $agencyOrganizationId: String!
    $courierOrganizationId: String!
  ) {
    connectedAgencyOrganizationForCourierOrganization(
      agencyOrganizationId: $agencyOrganizationId
      courierOrganizationId: $courierOrganizationId
    ) {
      id
      name
      legalName
      shortName
      organizationType
      phone
      email
      description
      headquartersAddressLocation {
        id
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      invoicingAddressLocation {
        id
        formattedAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      invoiceEmail
      registrationAddressLocation {
        id
        formattedAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      vatId
      registrationNumber
      socialProfiles {
        platform
        type
        value
      }
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type ConnectedAgencyOrganizationForCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables
  >;
} & TChildProps;
export function withConnectedAgencyOrganizationForCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables,
    ConnectedAgencyOrganizationForCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables,
    ConnectedAgencyOrganizationForCourierOrganizationProps<TChildProps, TDataName>
  >(ConnectedAgencyOrganizationForCourierOrganizationDocument, {
    alias: 'connectedAgencyOrganizationForCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useConnectedAgencyOrganizationForCourierOrganizationQuery__
 *
 * To run a query within a React component, call `useConnectedAgencyOrganizationForCourierOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAgencyOrganizationForCourierOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAgencyOrganizationForCourierOrganizationQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *      courierOrganizationId: // value for 'courierOrganizationId'
 *   },
 * });
 */
export function useConnectedAgencyOrganizationForCourierOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables
  >(ConnectedAgencyOrganizationForCourierOrganizationDocument, options);
}
export function useConnectedAgencyOrganizationForCourierOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectedAgencyOrganizationForCourierOrganizationQuery,
    ConnectedAgencyOrganizationForCourierOrganizationQueryVariables
  >(ConnectedAgencyOrganizationForCourierOrganizationDocument, options);
}
export type ConnectedAgencyOrganizationForCourierOrganizationQueryHookResult = ReturnType<
  typeof useConnectedAgencyOrganizationForCourierOrganizationQuery
>;
export type ConnectedAgencyOrganizationForCourierOrganizationLazyQueryHookResult = ReturnType<
  typeof useConnectedAgencyOrganizationForCourierOrganizationLazyQuery
>;
export type ConnectedAgencyOrganizationForCourierOrganizationQueryResult = Apollo.QueryResult<
  ConnectedAgencyOrganizationForCourierOrganizationQuery,
  ConnectedAgencyOrganizationForCourierOrganizationQueryVariables
>;
export function refetchConnectedAgencyOrganizationForCourierOrganizationQuery(
  variables: ConnectedAgencyOrganizationForCourierOrganizationQueryVariables,
) {
  return { query: ConnectedAgencyOrganizationForCourierOrganizationDocument, variables: variables };
}
export const ConnectedCourierOrganizationsForAgencyOrganizationDocument = gql`
  query ConnectedCourierOrganizationsForAgencyOrganization($agencyOrganizationId: String!) {
    connectedCourierOrganizationsForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
    ) {
      id
      connectionType
      createdAt
      updatedAt
      usedOrganizationInvitation {
        id
        usedAt
        usedByUser {
          id
          firstNames
          lastName
          email
        }
      }
      organization {
        id
        name
        legalName
        shortName
        organizationType
        description
        phone
        email
        organizationUserOwners {
          id
          roleType
          user {
            id
            firstNames
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;
export type ConnectedCourierOrganizationsForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables
  >;
} & TChildProps;
export function withConnectedCourierOrganizationsForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables,
    ConnectedCourierOrganizationsForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables,
    ConnectedCourierOrganizationsForAgencyOrganizationProps<TChildProps, TDataName>
  >(ConnectedCourierOrganizationsForAgencyOrganizationDocument, {
    alias: 'connectedCourierOrganizationsForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useConnectedCourierOrganizationsForAgencyOrganizationQuery__
 *
 * To run a query within a React component, call `useConnectedCourierOrganizationsForAgencyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedCourierOrganizationsForAgencyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedCourierOrganizationsForAgencyOrganizationQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *   },
 * });
 */
export function useConnectedCourierOrganizationsForAgencyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables
  >(ConnectedCourierOrganizationsForAgencyOrganizationDocument, options);
}
export function useConnectedCourierOrganizationsForAgencyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectedCourierOrganizationsForAgencyOrganizationQuery,
    ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables
  >(ConnectedCourierOrganizationsForAgencyOrganizationDocument, options);
}
export type ConnectedCourierOrganizationsForAgencyOrganizationQueryHookResult = ReturnType<
  typeof useConnectedCourierOrganizationsForAgencyOrganizationQuery
>;
export type ConnectedCourierOrganizationsForAgencyOrganizationLazyQueryHookResult = ReturnType<
  typeof useConnectedCourierOrganizationsForAgencyOrganizationLazyQuery
>;
export type ConnectedCourierOrganizationsForAgencyOrganizationQueryResult = Apollo.QueryResult<
  ConnectedCourierOrganizationsForAgencyOrganizationQuery,
  ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables
>;
export function refetchConnectedCourierOrganizationsForAgencyOrganizationQuery(
  variables: ConnectedCourierOrganizationsForAgencyOrganizationQueryVariables,
) {
  return {
    query: ConnectedCourierOrganizationsForAgencyOrganizationDocument,
    variables: variables,
  };
}
export const ConnectedAgencyOrganizationsForCourierOrganizationDocument = gql`
  query ConnectedAgencyOrganizationsForCourierOrganization($courierOrganizationId: String!) {
    connectedAgencyOrganizationsForCourierOrganization(
      courierOrganizationId: $courierOrganizationId
    ) {
      id
      connectionType
      createdAt
      updatedAt
      usedOrganizationInvitation {
        id
        usedAt
        usedByUser {
          id
          firstNames
          lastName
          email
        }
      }
      organization {
        id
        name
        legalName
        shortName
        organizationType
        description
        phone
        email
        organizationUserOwners {
          id
          roleType
          user {
            id
            firstNames
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;
export type ConnectedAgencyOrganizationsForCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables
  >;
} & TChildProps;
export function withConnectedAgencyOrganizationsForCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables,
    ConnectedAgencyOrganizationsForCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables,
    ConnectedAgencyOrganizationsForCourierOrganizationProps<TChildProps, TDataName>
  >(ConnectedAgencyOrganizationsForCourierOrganizationDocument, {
    alias: 'connectedAgencyOrganizationsForCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useConnectedAgencyOrganizationsForCourierOrganizationQuery__
 *
 * To run a query within a React component, call `useConnectedAgencyOrganizationsForCourierOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectedAgencyOrganizationsForCourierOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectedAgencyOrganizationsForCourierOrganizationQuery({
 *   variables: {
 *      courierOrganizationId: // value for 'courierOrganizationId'
 *   },
 * });
 */
export function useConnectedAgencyOrganizationsForCourierOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables
  >(ConnectedAgencyOrganizationsForCourierOrganizationDocument, options);
}
export function useConnectedAgencyOrganizationsForCourierOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectedAgencyOrganizationsForCourierOrganizationQuery,
    ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables
  >(ConnectedAgencyOrganizationsForCourierOrganizationDocument, options);
}
export type ConnectedAgencyOrganizationsForCourierOrganizationQueryHookResult = ReturnType<
  typeof useConnectedAgencyOrganizationsForCourierOrganizationQuery
>;
export type ConnectedAgencyOrganizationsForCourierOrganizationLazyQueryHookResult = ReturnType<
  typeof useConnectedAgencyOrganizationsForCourierOrganizationLazyQuery
>;
export type ConnectedAgencyOrganizationsForCourierOrganizationQueryResult = Apollo.QueryResult<
  ConnectedAgencyOrganizationsForCourierOrganizationQuery,
  ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables
>;
export function refetchConnectedAgencyOrganizationsForCourierOrganizationQuery(
  variables: ConnectedAgencyOrganizationsForCourierOrganizationQueryVariables,
) {
  return {
    query: ConnectedAgencyOrganizationsForCourierOrganizationDocument,
    variables: variables,
  };
}
export const OrganizationsDocument = gql`
  query Organizations {
    organizations {
      id
      name
      legalName
      createdAt
      organizationType
      usedOrganizationInvitation {
        id
        usedAt
        organizationInvitationType
      }
      organizationUsers {
        id
        roleType
        createdAt
        user {
          id
          firstNames
          lastName
          email
          createdAt
        }
      }
    }
  }
`;
export type OrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<OrganizationsQuery, OrganizationsQueryVariables>;
} & TChildProps;
export function withOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationsQuery,
    OrganizationsQueryVariables,
    OrganizationsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationsQuery,
    OrganizationsQueryVariables,
    OrganizationsProps<TChildProps, TDataName>
  >(OrganizationsDocument, {
    alias: 'organizations',
    ...operationOptions,
  });
}

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options,
  );
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options,
  );
}
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>;
export function refetchOrganizationsQuery(variables?: OrganizationsQueryVariables) {
  return { query: OrganizationsDocument, variables: variables };
}
export const OrganizationInvitationByActivationCodeForCourierOrganizationDocument = gql`
  query OrganizationInvitationByActivationCodeForCourierOrganization(
    $organizationId: String!
    $activationCode: String!
  ) {
    organizationInvitationByActivationCodeForCourierOrganization(
      organizationId: $organizationId
      activationCode: $activationCode
    ) {
      id
      activationCode
      organizationInvitationType
      invitingOrganization {
        id
        name
        legalName
        shortName
        phone
        email
      }
    }
  }
`;
export type OrganizationInvitationByActivationCodeForCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables
  >;
} & TChildProps;
export function withOrganizationInvitationByActivationCodeForCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables,
    OrganizationInvitationByActivationCodeForCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables,
    OrganizationInvitationByActivationCodeForCourierOrganizationProps<TChildProps, TDataName>
  >(OrganizationInvitationByActivationCodeForCourierOrganizationDocument, {
    alias: 'organizationInvitationByActivationCodeForCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useOrganizationInvitationByActivationCodeForCourierOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationInvitationByActivationCodeForCourierOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInvitationByActivationCodeForCourierOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInvitationByActivationCodeForCourierOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      activationCode: // value for 'activationCode'
 *   },
 * });
 */
export function useOrganizationInvitationByActivationCodeForCourierOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables
  >(OrganizationInvitationByActivationCodeForCourierOrganizationDocument, options);
}
export function useOrganizationInvitationByActivationCodeForCourierOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables
  >(OrganizationInvitationByActivationCodeForCourierOrganizationDocument, options);
}
export type OrganizationInvitationByActivationCodeForCourierOrganizationQueryHookResult =
  ReturnType<typeof useOrganizationInvitationByActivationCodeForCourierOrganizationQuery>;
export type OrganizationInvitationByActivationCodeForCourierOrganizationLazyQueryHookResult =
  ReturnType<typeof useOrganizationInvitationByActivationCodeForCourierOrganizationLazyQuery>;
export type OrganizationInvitationByActivationCodeForCourierOrganizationQueryResult =
  Apollo.QueryResult<
    OrganizationInvitationByActivationCodeForCourierOrganizationQuery,
    OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables
  >;
export function refetchOrganizationInvitationByActivationCodeForCourierOrganizationQuery(
  variables: OrganizationInvitationByActivationCodeForCourierOrganizationQueryVariables,
) {
  return {
    query: OrganizationInvitationByActivationCodeForCourierOrganizationDocument,
    variables: variables,
  };
}
export const AvailableCourierUsersByDistanceForAgencyJobDocument = gql`
  query AvailableCourierUsersByDistanceForAgencyJob($agencyJobId: String!) {
    availableCourierUsersByDistanceForAgencyJob(agencyJobId: $agencyJobId) {
      user {
        id
        firstNames
        lastName
        email
        phone
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            order
            label
          }
        }
      }
      organizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
        }
      }
      courierJob {
        id
        relationshipType
        declineReasonType
        isActive
      }
      availabilityLocationsWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        distance
      }
    }
  }
`;
export type AvailableCourierUsersByDistanceForAgencyJobProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables
  >;
} & TChildProps;
export function withAvailableCourierUsersByDistanceForAgencyJob<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables,
    AvailableCourierUsersByDistanceForAgencyJobProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables,
    AvailableCourierUsersByDistanceForAgencyJobProps<TChildProps, TDataName>
  >(AvailableCourierUsersByDistanceForAgencyJobDocument, {
    alias: 'availableCourierUsersByDistanceForAgencyJob',
    ...operationOptions,
  });
}

/**
 * __useAvailableCourierUsersByDistanceForAgencyJobQuery__
 *
 * To run a query within a React component, call `useAvailableCourierUsersByDistanceForAgencyJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCourierUsersByDistanceForAgencyJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCourierUsersByDistanceForAgencyJobQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAvailableCourierUsersByDistanceForAgencyJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables
  >(AvailableCourierUsersByDistanceForAgencyJobDocument, options);
}
export function useAvailableCourierUsersByDistanceForAgencyJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCourierUsersByDistanceForAgencyJobQuery,
    AvailableCourierUsersByDistanceForAgencyJobQueryVariables
  >(AvailableCourierUsersByDistanceForAgencyJobDocument, options);
}
export type AvailableCourierUsersByDistanceForAgencyJobQueryHookResult = ReturnType<
  typeof useAvailableCourierUsersByDistanceForAgencyJobQuery
>;
export type AvailableCourierUsersByDistanceForAgencyJobLazyQueryHookResult = ReturnType<
  typeof useAvailableCourierUsersByDistanceForAgencyJobLazyQuery
>;
export type AvailableCourierUsersByDistanceForAgencyJobQueryResult = Apollo.QueryResult<
  AvailableCourierUsersByDistanceForAgencyJobQuery,
  AvailableCourierUsersByDistanceForAgencyJobQueryVariables
>;
export function refetchAvailableCourierUsersByDistanceForAgencyJobQuery(
  variables: AvailableCourierUsersByDistanceForAgencyJobQueryVariables,
) {
  return { query: AvailableCourierUsersByDistanceForAgencyJobDocument, variables: variables };
}
export const AllActiveAndUpcomingAvailabilitiesForPriojetDocument = gql`
  query AllActiveAndUpcomingAvailabilitiesForPriojet {
    allActiveAndUpcomingAvailabilitiesForPriojet {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        courierUserProfile {
          id
          baseAirports
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type AllActiveAndUpcomingAvailabilitiesForPriojetProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables
  >;
} & TChildProps;
export function withAllActiveAndUpcomingAvailabilitiesForPriojet<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables,
    AllActiveAndUpcomingAvailabilitiesForPriojetProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables,
    AllActiveAndUpcomingAvailabilitiesForPriojetProps<TChildProps, TDataName>
  >(AllActiveAndUpcomingAvailabilitiesForPriojetDocument, {
    alias: 'allActiveAndUpcomingAvailabilitiesForPriojet',
    ...operationOptions,
  });
}

/**
 * __useAllActiveAndUpcomingAvailabilitiesForPriojetQuery__
 *
 * To run a query within a React component, call `useAllActiveAndUpcomingAvailabilitiesForPriojetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActiveAndUpcomingAvailabilitiesForPriojetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActiveAndUpcomingAvailabilitiesForPriojetQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllActiveAndUpcomingAvailabilitiesForPriojetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables
  >(AllActiveAndUpcomingAvailabilitiesForPriojetDocument, options);
}
export function useAllActiveAndUpcomingAvailabilitiesForPriojetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
    AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables
  >(AllActiveAndUpcomingAvailabilitiesForPriojetDocument, options);
}
export type AllActiveAndUpcomingAvailabilitiesForPriojetQueryHookResult = ReturnType<
  typeof useAllActiveAndUpcomingAvailabilitiesForPriojetQuery
>;
export type AllActiveAndUpcomingAvailabilitiesForPriojetLazyQueryHookResult = ReturnType<
  typeof useAllActiveAndUpcomingAvailabilitiesForPriojetLazyQuery
>;
export type AllActiveAndUpcomingAvailabilitiesForPriojetQueryResult = Apollo.QueryResult<
  AllActiveAndUpcomingAvailabilitiesForPriojetQuery,
  AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables
>;
export function refetchAllActiveAndUpcomingAvailabilitiesForPriojetQuery(
  variables?: AllActiveAndUpcomingAvailabilitiesForPriojetQueryVariables,
) {
  return { query: AllActiveAndUpcomingAvailabilitiesForPriojetDocument, variables: variables };
}
export const ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsDocument = gql`
  query ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations(
    $agencyOrganizationId: String!
  ) {
    activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations(
      agencyOrganizationId: $agencyOrganizationId
    ) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        profilePicture {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        courierUserProfile {
          id
          baseAirports
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >;
} & TChildProps;
export function withActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsProps<TChildProps, TDataName>
  >(ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsDocument, {
    alias: 'activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations',
    ...operationOptions,
  });
}

/**
 * __useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery__
 *
 * To run a query within a React component, call `useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *   },
 * });
 */
export function useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >(ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsDocument, options);
}
export function useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >(ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsDocument, options);
}
export type ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryHookResult =
  ReturnType<typeof useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery>;
export type ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsLazyQueryHookResult =
  ReturnType<typeof useActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsLazyQuery>;
export type ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryResult =
  Apollo.QueryResult<
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery,
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >;
export function refetchActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQuery(
  variables: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsQueryVariables,
) {
  return {
    query: ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsDocument,
    variables: variables,
  };
}
export const AvailabilitiesForUserDocument = gql`
  query AvailabilitiesForUser($userId: String!) {
    availabilitiesForUser(userId: $userId) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        courierUserProfile {
          id
          baseAirports
        }
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type AvailabilitiesForUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailabilitiesForUserQuery,
    AvailabilitiesForUserQueryVariables
  >;
} & TChildProps;
export function withAvailabilitiesForUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailabilitiesForUserQuery,
    AvailabilitiesForUserQueryVariables,
    AvailabilitiesForUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailabilitiesForUserQuery,
    AvailabilitiesForUserQueryVariables,
    AvailabilitiesForUserProps<TChildProps, TDataName>
  >(AvailabilitiesForUserDocument, {
    alias: 'availabilitiesForUser',
    ...operationOptions,
  });
}

/**
 * __useAvailabilitiesForUserQuery__
 *
 * To run a query within a React component, call `useAvailabilitiesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitiesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitiesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAvailabilitiesForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailabilitiesForUserQuery,
    AvailabilitiesForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilitiesForUserQuery, AvailabilitiesForUserQueryVariables>(
    AvailabilitiesForUserDocument,
    options,
  );
}
export function useAvailabilitiesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilitiesForUserQuery,
    AvailabilitiesForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilitiesForUserQuery, AvailabilitiesForUserQueryVariables>(
    AvailabilitiesForUserDocument,
    options,
  );
}
export type AvailabilitiesForUserQueryHookResult = ReturnType<typeof useAvailabilitiesForUserQuery>;
export type AvailabilitiesForUserLazyQueryHookResult = ReturnType<
  typeof useAvailabilitiesForUserLazyQuery
>;
export type AvailabilitiesForUserQueryResult = Apollo.QueryResult<
  AvailabilitiesForUserQuery,
  AvailabilitiesForUserQueryVariables
>;
export function refetchAvailabilitiesForUserQuery(variables: AvailabilitiesForUserQueryVariables) {
  return { query: AvailabilitiesForUserDocument, variables: variables };
}
export const ActiveAvailabilitiesForUserDocument = gql`
  query ActiveAvailabilitiesForUser($userId: String!) {
    activeAvailabilitiesForUser(userId: $userId) {
      id
      isAdHocAvailability
      startTime
      endTime
      updatedAt
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
      }
      lastDeviceLocationWithLocationForUser {
        id
        timestamp
        location {
          id
          formattedAddress
          formattedMinimalAddress
          timeZoneIdentifier
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type ActiveAvailabilitiesForUserProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables
  >;
} & TChildProps;
export function withActiveAvailabilitiesForUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables,
    ActiveAvailabilitiesForUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables,
    ActiveAvailabilitiesForUserProps<TChildProps, TDataName>
  >(ActiveAvailabilitiesForUserDocument, {
    alias: 'activeAvailabilitiesForUser',
    ...operationOptions,
  });
}

/**
 * __useActiveAvailabilitiesForUserQuery__
 *
 * To run a query within a React component, call `useActiveAvailabilitiesForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAvailabilitiesForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAvailabilitiesForUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useActiveAvailabilitiesForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables
  >(ActiveAvailabilitiesForUserDocument, options);
}
export function useActiveAvailabilitiesForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveAvailabilitiesForUserQuery,
    ActiveAvailabilitiesForUserQueryVariables
  >(ActiveAvailabilitiesForUserDocument, options);
}
export type ActiveAvailabilitiesForUserQueryHookResult = ReturnType<
  typeof useActiveAvailabilitiesForUserQuery
>;
export type ActiveAvailabilitiesForUserLazyQueryHookResult = ReturnType<
  typeof useActiveAvailabilitiesForUserLazyQuery
>;
export type ActiveAvailabilitiesForUserQueryResult = Apollo.QueryResult<
  ActiveAvailabilitiesForUserQuery,
  ActiveAvailabilitiesForUserQueryVariables
>;
export function refetchActiveAvailabilitiesForUserQuery(
  variables: ActiveAvailabilitiesForUserQueryVariables,
) {
  return { query: ActiveAvailabilitiesForUserDocument, variables: variables };
}
export const UserIdentityDocumentsDocument = gql`
  query UserIdentityDocuments($userId: String!) {
    userIdentityDocuments(userId: $userId) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      countryOfBirth
      dateOfBirth
      documentType
      description
      documentFeature
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      expiresOn
      isInternationalDrivingPermit
      expiresOnInternational
      nationality
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserIdentityDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserIdentityDocumentsQuery,
    UserIdentityDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserIdentityDocuments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserIdentityDocumentsQuery,
    UserIdentityDocumentsQueryVariables,
    UserIdentityDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserIdentityDocumentsQuery,
    UserIdentityDocumentsQueryVariables,
    UserIdentityDocumentsProps<TChildProps, TDataName>
  >(UserIdentityDocumentsDocument, {
    alias: 'userIdentityDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserIdentityDocumentsQuery__
 *
 * To run a query within a React component, call `useUserIdentityDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentityDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentityDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserIdentityDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserIdentityDocumentsQuery,
    UserIdentityDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserIdentityDocumentsQuery, UserIdentityDocumentsQueryVariables>(
    UserIdentityDocumentsDocument,
    options,
  );
}
export function useUserIdentityDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserIdentityDocumentsQuery,
    UserIdentityDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserIdentityDocumentsQuery, UserIdentityDocumentsQueryVariables>(
    UserIdentityDocumentsDocument,
    options,
  );
}
export type UserIdentityDocumentsQueryHookResult = ReturnType<typeof useUserIdentityDocumentsQuery>;
export type UserIdentityDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserIdentityDocumentsLazyQuery
>;
export type UserIdentityDocumentsQueryResult = Apollo.QueryResult<
  UserIdentityDocumentsQuery,
  UserIdentityDocumentsQueryVariables
>;
export function refetchUserIdentityDocumentsQuery(variables: UserIdentityDocumentsQueryVariables) {
  return { query: UserIdentityDocumentsDocument, variables: variables };
}
export const UserIdentityDocumentDocument = gql`
  query UserIdentityDocument($documentId: String!) {
    userIdentityDocument(documentId: $documentId) {
      id
      firstNames
      lastName
      countryOfBirth
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentFeature
      description
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      expiresOn
      isInternationalDrivingPermit
      expiresOnInternational
      nationality
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserIdentityDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserIdentityDocumentQuery,
    UserIdentityDocumentQueryVariables
  >;
} & TChildProps;
export function withUserIdentityDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserIdentityDocumentQuery,
    UserIdentityDocumentQueryVariables,
    UserIdentityDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserIdentityDocumentQuery,
    UserIdentityDocumentQueryVariables,
    UserIdentityDocumentProps<TChildProps, TDataName>
  >(UserIdentityDocumentDocument, {
    alias: 'userIdentityDocument',
    ...operationOptions,
  });
}

/**
 * __useUserIdentityDocumentQuery__
 *
 * To run a query within a React component, call `useUserIdentityDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentityDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentityDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserIdentityDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserIdentityDocumentQuery,
    UserIdentityDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserIdentityDocumentQuery, UserIdentityDocumentQueryVariables>(
    UserIdentityDocumentDocument,
    options,
  );
}
export function useUserIdentityDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserIdentityDocumentQuery,
    UserIdentityDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserIdentityDocumentQuery, UserIdentityDocumentQueryVariables>(
    UserIdentityDocumentDocument,
    options,
  );
}
export type UserIdentityDocumentQueryHookResult = ReturnType<typeof useUserIdentityDocumentQuery>;
export type UserIdentityDocumentLazyQueryHookResult = ReturnType<
  typeof useUserIdentityDocumentLazyQuery
>;
export type UserIdentityDocumentQueryResult = Apollo.QueryResult<
  UserIdentityDocumentQuery,
  UserIdentityDocumentQueryVariables
>;
export function refetchUserIdentityDocumentQuery(variables: UserIdentityDocumentQueryVariables) {
  return { query: UserIdentityDocumentDocument, variables: variables };
}
export const UserTravelDocumentsDocument = gql`
  query UserTravelDocuments($userId: String!) {
    userTravelDocuments(userId: $userId) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      documentType
      documentSubType
      description
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      validForCountry
      expiresOn
      nationality
      countryOfBirth
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      multipleEntriesAllowed
      mappedIdentityDocument {
        id
        nationality
        firstNames
        lastName
        placeOfBirth
        sex
        countryOfBirth
        dateOfBirth
        documentNumber
      }
    }
  }
`;
export type UserTravelDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserTravelDocumentsQuery,
    UserTravelDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserTravelDocuments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserTravelDocumentsQuery,
    UserTravelDocumentsQueryVariables,
    UserTravelDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserTravelDocumentsQuery,
    UserTravelDocumentsQueryVariables,
    UserTravelDocumentsProps<TChildProps, TDataName>
  >(UserTravelDocumentsDocument, {
    alias: 'userTravelDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserTravelDocumentsQuery__
 *
 * To run a query within a React component, call `useUserTravelDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTravelDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTravelDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTravelDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<UserTravelDocumentsQuery, UserTravelDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserTravelDocumentsQuery, UserTravelDocumentsQueryVariables>(
    UserTravelDocumentsDocument,
    options,
  );
}
export function useUserTravelDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserTravelDocumentsQuery,
    UserTravelDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserTravelDocumentsQuery, UserTravelDocumentsQueryVariables>(
    UserTravelDocumentsDocument,
    options,
  );
}
export type UserTravelDocumentsQueryHookResult = ReturnType<typeof useUserTravelDocumentsQuery>;
export type UserTravelDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserTravelDocumentsLazyQuery
>;
export type UserTravelDocumentsQueryResult = Apollo.QueryResult<
  UserTravelDocumentsQuery,
  UserTravelDocumentsQueryVariables
>;
export function refetchUserTravelDocumentsQuery(variables: UserTravelDocumentsQueryVariables) {
  return { query: UserTravelDocumentsDocument, variables: variables };
}
export const UserTravelDocumentDocument = gql`
  query UserTravelDocument($documentId: String!) {
    userTravelDocument(documentId: $documentId) {
      id
      firstNames
      lastName
      placeOfBirth
      sex
      dateOfBirth
      description
      documentType
      documentSubType
      documentSubTypeMulti
      documentNumber
      dateOfIssue
      placeOfIssue
      countryOfIssue
      validForCountry
      expiresOn
      nationality
      countryOfBirth
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      mappedIdentityDocument {
        id
        documentNumber
        firstNames
        lastName
        placeOfBirth
        sex
        countryOfBirth
        dateOfBirth
        nationality
      }
      multipleEntriesAllowed
    }
  }
`;
export type UserTravelDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserTravelDocumentQuery,
    UserTravelDocumentQueryVariables
  >;
} & TChildProps;
export function withUserTravelDocument<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserTravelDocumentQuery,
    UserTravelDocumentQueryVariables,
    UserTravelDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserTravelDocumentQuery,
    UserTravelDocumentQueryVariables,
    UserTravelDocumentProps<TChildProps, TDataName>
  >(UserTravelDocumentDocument, {
    alias: 'userTravelDocument',
    ...operationOptions,
  });
}

/**
 * __useUserTravelDocumentQuery__
 *
 * To run a query within a React component, call `useUserTravelDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTravelDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTravelDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserTravelDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<UserTravelDocumentQuery, UserTravelDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserTravelDocumentQuery, UserTravelDocumentQueryVariables>(
    UserTravelDocumentDocument,
    options,
  );
}
export function useUserTravelDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserTravelDocumentQuery,
    UserTravelDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserTravelDocumentQuery, UserTravelDocumentQueryVariables>(
    UserTravelDocumentDocument,
    options,
  );
}
export type UserTravelDocumentQueryHookResult = ReturnType<typeof useUserTravelDocumentQuery>;
export type UserTravelDocumentLazyQueryHookResult = ReturnType<
  typeof useUserTravelDocumentLazyQuery
>;
export type UserTravelDocumentQueryResult = Apollo.QueryResult<
  UserTravelDocumentQuery,
  UserTravelDocumentQueryVariables
>;
export function refetchUserTravelDocumentQuery(variables: UserTravelDocumentQueryVariables) {
  return { query: UserTravelDocumentDocument, variables: variables };
}
export const NumberOfNewNotificationsForOrganizationUserDocument = gql`
  query NumberOfNewNotificationsForOrganizationUser($organizationUserId: String!) {
    numberOfNewNotificationsForOrganizationUser(organizationUserId: $organizationUserId)
  }
`;
export type NumberOfNewNotificationsForOrganizationUserProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables
  >;
} & TChildProps;
export function withNumberOfNewNotificationsForOrganizationUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables,
    NumberOfNewNotificationsForOrganizationUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables,
    NumberOfNewNotificationsForOrganizationUserProps<TChildProps, TDataName>
  >(NumberOfNewNotificationsForOrganizationUserDocument, {
    alias: 'numberOfNewNotificationsForOrganizationUser',
    ...operationOptions,
  });
}

/**
 * __useNumberOfNewNotificationsForOrganizationUserQuery__
 *
 * To run a query within a React component, call `useNumberOfNewNotificationsForOrganizationUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfNewNotificationsForOrganizationUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfNewNotificationsForOrganizationUserQuery({
 *   variables: {
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useNumberOfNewNotificationsForOrganizationUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables
  >(NumberOfNewNotificationsForOrganizationUserDocument, options);
}
export function useNumberOfNewNotificationsForOrganizationUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NumberOfNewNotificationsForOrganizationUserQuery,
    NumberOfNewNotificationsForOrganizationUserQueryVariables
  >(NumberOfNewNotificationsForOrganizationUserDocument, options);
}
export type NumberOfNewNotificationsForOrganizationUserQueryHookResult = ReturnType<
  typeof useNumberOfNewNotificationsForOrganizationUserQuery
>;
export type NumberOfNewNotificationsForOrganizationUserLazyQueryHookResult = ReturnType<
  typeof useNumberOfNewNotificationsForOrganizationUserLazyQuery
>;
export type NumberOfNewNotificationsForOrganizationUserQueryResult = Apollo.QueryResult<
  NumberOfNewNotificationsForOrganizationUserQuery,
  NumberOfNewNotificationsForOrganizationUserQueryVariables
>;
export function refetchNumberOfNewNotificationsForOrganizationUserQuery(
  variables: NumberOfNewNotificationsForOrganizationUserQueryVariables,
) {
  return { query: NumberOfNewNotificationsForOrganizationUserDocument, variables: variables };
}
export const LatestOrNewSystemNotificationsForOrganizationUserDocument = gql`
  query LatestOrNewSystemNotificationsForOrganizationUser(
    $filterType: [NotificationSystemFilterType!]
    $organizationUserId: String!
  ) {
    latestOrNewSystemNotificationsForOrganizationUser(
      filterType: $filterType
      organizationUserId: $organizationUserId
    ) {
      id
      notificationType
      notificationMeansType
      organizationContextType
      language
      batchId
      job {
        id
      }
      agencyJob {
        id
        agencyJobStatusType
        courierJobs {
          id
          relationshipType
          responsibleCourierUser {
            id
          }
        }
      }
      courierJob {
        id
        agencyJob {
          id
        }
      }
      chatMessage {
        id
        chat {
          id
          agencyJob {
            id
          }
        }
      }
      title
      content
      contentType
      data
      sentAt
      receivedAt
      firstDisplayedAt
      markedAsReadAt
      createdAt
      updatedAt
    }
  }
`;
export type LatestOrNewSystemNotificationsForOrganizationUserProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables
  >;
} & TChildProps;
export function withLatestOrNewSystemNotificationsForOrganizationUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables,
    LatestOrNewSystemNotificationsForOrganizationUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables,
    LatestOrNewSystemNotificationsForOrganizationUserProps<TChildProps, TDataName>
  >(LatestOrNewSystemNotificationsForOrganizationUserDocument, {
    alias: 'latestOrNewSystemNotificationsForOrganizationUser',
    ...operationOptions,
  });
}

/**
 * __useLatestOrNewSystemNotificationsForOrganizationUserQuery__
 *
 * To run a query within a React component, call `useLatestOrNewSystemNotificationsForOrganizationUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOrNewSystemNotificationsForOrganizationUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOrNewSystemNotificationsForOrganizationUserQuery({
 *   variables: {
 *      filterType: // value for 'filterType'
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useLatestOrNewSystemNotificationsForOrganizationUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables
  >(LatestOrNewSystemNotificationsForOrganizationUserDocument, options);
}
export function useLatestOrNewSystemNotificationsForOrganizationUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LatestOrNewSystemNotificationsForOrganizationUserQuery,
    LatestOrNewSystemNotificationsForOrganizationUserQueryVariables
  >(LatestOrNewSystemNotificationsForOrganizationUserDocument, options);
}
export type LatestOrNewSystemNotificationsForOrganizationUserQueryHookResult = ReturnType<
  typeof useLatestOrNewSystemNotificationsForOrganizationUserQuery
>;
export type LatestOrNewSystemNotificationsForOrganizationUserLazyQueryHookResult = ReturnType<
  typeof useLatestOrNewSystemNotificationsForOrganizationUserLazyQuery
>;
export type LatestOrNewSystemNotificationsForOrganizationUserQueryResult = Apollo.QueryResult<
  LatestOrNewSystemNotificationsForOrganizationUserQuery,
  LatestOrNewSystemNotificationsForOrganizationUserQueryVariables
>;
export function refetchLatestOrNewSystemNotificationsForOrganizationUserQuery(
  variables: LatestOrNewSystemNotificationsForOrganizationUserQueryVariables,
) {
  return { query: LatestOrNewSystemNotificationsForOrganizationUserDocument, variables: variables };
}
export const SignInUserContextDocument = gql`
  query SignInUserContext {
    signInUserContext {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        endTime
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;
export type SignInUserContextProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SignInUserContextQuery,
    SignInUserContextQueryVariables
  >;
} & TChildProps;
export function withSignInUserContext<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SignInUserContextQuery,
    SignInUserContextQueryVariables,
    SignInUserContextProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SignInUserContextQuery,
    SignInUserContextQueryVariables,
    SignInUserContextProps<TChildProps, TDataName>
  >(SignInUserContextDocument, {
    alias: 'signInUserContext',
    ...operationOptions,
  });
}

/**
 * __useSignInUserContextQuery__
 *
 * To run a query within a React component, call `useSignInUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignInUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignInUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignInUserContextQuery(
  baseOptions?: Apollo.QueryHookOptions<SignInUserContextQuery, SignInUserContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SignInUserContextQuery, SignInUserContextQueryVariables>(
    SignInUserContextDocument,
    options,
  );
}
export function useSignInUserContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SignInUserContextQuery,
    SignInUserContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SignInUserContextQuery, SignInUserContextQueryVariables>(
    SignInUserContextDocument,
    options,
  );
}
export type SignInUserContextQueryHookResult = ReturnType<typeof useSignInUserContextQuery>;
export type SignInUserContextLazyQueryHookResult = ReturnType<typeof useSignInUserContextLazyQuery>;
export type SignInUserContextQueryResult = Apollo.QueryResult<
  SignInUserContextQuery,
  SignInUserContextQueryVariables
>;
export function refetchSignInUserContextQuery(variables?: SignInUserContextQueryVariables) {
  return { query: SignInUserContextDocument, variables: variables };
}
export const OrganizationInvitationsForPriojetOrganizationDocument = gql`
  query OrganizationInvitationsForPriojetOrganization {
    organizationInvitationsForPriojetOrganization {
      id
      createdAt
      updatedAt
      voidedAt
      usedAt
      reservedComment
      activationCode
      organizationInvitationType
      invitedOrganizationType
      roleType
      bitrix24Deal {
        id
        bitrixDealCategoryId
        bitrixInvitedCompanyId
        bitrixInvitedContactId
      }
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isPriojetContact
        isCourierContact
        isAgencyContact
        isClientContact
        isTravelerContact
        isServiceProviderContact
        isOtherContact
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationUser {
        id
        roleType
        user {
          id
          firstNames
          lastName
          email
        }
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      usedForOrganization {
        id
        name
        legalName
        organizationType
        createdAt
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectingOrganization {
          id
          name
          legalName
          organizationType
          createdAt
        }
        connectedOrganization {
          id
          name
          legalName
          organizationType
          createdAt
        }
      }
      invitingOrganization {
        id
        name
        legalName
        organizationType
        createdAt
      }
      usedByUser {
        id
        firstNames
        email
        lastName
        createdAt
      }
      createdByUser {
        id
        firstNames
        email
        lastName
        createdAt
      }
      organizationInvitationSharings {
        id
        appName
        platformVersion
        platform
        language
        sharedAt
        sharedByUser {
          id
          firstNames
          email
          lastName
        }
      }
    }
  }
`;
export type OrganizationInvitationsForPriojetOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables
  >;
} & TChildProps;
export function withOrganizationInvitationsForPriojetOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables,
    OrganizationInvitationsForPriojetOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables,
    OrganizationInvitationsForPriojetOrganizationProps<TChildProps, TDataName>
  >(OrganizationInvitationsForPriojetOrganizationDocument, {
    alias: 'organizationInvitationsForPriojetOrganization',
    ...operationOptions,
  });
}

/**
 * __useOrganizationInvitationsForPriojetOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationInvitationsForPriojetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInvitationsForPriojetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInvitationsForPriojetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationInvitationsForPriojetOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables
  >(OrganizationInvitationsForPriojetOrganizationDocument, options);
}
export function useOrganizationInvitationsForPriojetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationInvitationsForPriojetOrganizationQuery,
    OrganizationInvitationsForPriojetOrganizationQueryVariables
  >(OrganizationInvitationsForPriojetOrganizationDocument, options);
}
export type OrganizationInvitationsForPriojetOrganizationQueryHookResult = ReturnType<
  typeof useOrganizationInvitationsForPriojetOrganizationQuery
>;
export type OrganizationInvitationsForPriojetOrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationInvitationsForPriojetOrganizationLazyQuery
>;
export type OrganizationInvitationsForPriojetOrganizationQueryResult = Apollo.QueryResult<
  OrganizationInvitationsForPriojetOrganizationQuery,
  OrganizationInvitationsForPriojetOrganizationQueryVariables
>;
export function refetchOrganizationInvitationsForPriojetOrganizationQuery(
  variables?: OrganizationInvitationsForPriojetOrganizationQueryVariables,
) {
  return { query: OrganizationInvitationsForPriojetOrganizationDocument, variables: variables };
}
export const CourierOrganizationInvitationsForAgencyOrganizationDocument = gql`
  query CourierOrganizationInvitationsForAgencyOrganization(
    $agencyOrganizationId: String!
    $userId: String!
  ) {
    courierOrganizationInvitationsForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
      userId: $userId
    ) {
      id
      createdAt
      updatedAt
      voidedAt
      usedAt
      reservedComment
      activationCode
      organizationInvitationType
      invitedOrganizationType
      roleType
      hasInvitationDeal
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isCourierContact
        isAgencyContact
        isClientContact
        organization {
          id
          name
          legalName
          organizationType
        }
        userContactsForUser(userId: $userId) {
          id
          expoContactId
          phone
          phoneUnformatted
          email
          firstNames
          lastName
          isCourierContact
          isAgencyContact
          isClientContact
        }
      }
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationUser {
        id
        roleType
        user {
          id
          firstNames
          lastName
          email
        }
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      usedForOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectingOrganization {
          id
          name
          legalName
          organizationType
        }
        connectedOrganization {
          id
          name
          legalName
          organizationType
        }
      }
      invitingOrganization {
        id
        name
        legalName
        organizationType
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedByUser {
        id
        firstNames
        email
        lastName
      }
      createdByUser {
        id
        firstNames
        email
        lastName
      }
    }
  }
`;
export type CourierOrganizationInvitationsForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables
  >;
} & TChildProps;
export function withCourierOrganizationInvitationsForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables,
    CourierOrganizationInvitationsForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables,
    CourierOrganizationInvitationsForAgencyOrganizationProps<TChildProps, TDataName>
  >(CourierOrganizationInvitationsForAgencyOrganizationDocument, {
    alias: 'courierOrganizationInvitationsForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useCourierOrganizationInvitationsForAgencyOrganizationQuery__
 *
 * To run a query within a React component, call `useCourierOrganizationInvitationsForAgencyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierOrganizationInvitationsForAgencyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierOrganizationInvitationsForAgencyOrganizationQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCourierOrganizationInvitationsForAgencyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables
  >(CourierOrganizationInvitationsForAgencyOrganizationDocument, options);
}
export function useCourierOrganizationInvitationsForAgencyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierOrganizationInvitationsForAgencyOrganizationQuery,
    CourierOrganizationInvitationsForAgencyOrganizationQueryVariables
  >(CourierOrganizationInvitationsForAgencyOrganizationDocument, options);
}
export type CourierOrganizationInvitationsForAgencyOrganizationQueryHookResult = ReturnType<
  typeof useCourierOrganizationInvitationsForAgencyOrganizationQuery
>;
export type CourierOrganizationInvitationsForAgencyOrganizationLazyQueryHookResult = ReturnType<
  typeof useCourierOrganizationInvitationsForAgencyOrganizationLazyQuery
>;
export type CourierOrganizationInvitationsForAgencyOrganizationQueryResult = Apollo.QueryResult<
  CourierOrganizationInvitationsForAgencyOrganizationQuery,
  CourierOrganizationInvitationsForAgencyOrganizationQueryVariables
>;
export function refetchCourierOrganizationInvitationsForAgencyOrganizationQuery(
  variables: CourierOrganizationInvitationsForAgencyOrganizationQueryVariables,
) {
  return {
    query: CourierOrganizationInvitationsForAgencyOrganizationDocument,
    variables: variables,
  };
}
export const EmployeeInvitationsForAgencyOrganizationDocument = gql`
  query EmployeeInvitationsForAgencyOrganization($agencyOrganizationId: String!, $userId: String!) {
    employeeInvitationsForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
      userId: $userId
    ) {
      id
      createdAt
      updatedAt
      voidedAt
      usedAt
      reservedComment
      activationCode
      organizationInvitationType
      invitedOrganizationType
      roleType
      hasInvitationDeal
      organizationContact {
        id
        firstNames
        lastName
        phone
        email
        isCourierContact
        isAgencyContact
        isClientContact
        organization {
          id
          name
          legalName
          organizationType
        }
        userContactsForUser(userId: $userId) {
          id
          expoContactId
          phone
          phoneUnformatted
          email
          firstNames
          lastName
          isCourierContact
          isAgencyContact
          isClientContact
        }
      }
      invitedUser {
        id
        firstNames
        lastName
        email
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationUser {
        id
        roleType
        user {
          id
          firstNames
          lastName
          email
        }
        organization {
          id
          name
          legalName
          organizationType
        }
      }
      usedForOrganization {
        id
        name
        legalName
        organizationType
      }
      usedForOrganizationOrganization {
        id
        connectionType
        connectingOrganization {
          id
          name
          legalName
          organizationType
        }
        connectedOrganization {
          id
          name
          legalName
          organizationType
        }
      }
      invitingOrganization {
        id
        name
        legalName
        organizationType
      }
      invitedOrganization {
        id
        name
        legalName
        organizationType
      }
      usedByUser {
        id
        firstNames
        email
        lastName
      }
      createdByUser {
        id
        firstNames
        email
        lastName
      }
    }
  }
`;
export type EmployeeInvitationsForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables
  >;
} & TChildProps;
export function withEmployeeInvitationsForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables,
    EmployeeInvitationsForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables,
    EmployeeInvitationsForAgencyOrganizationProps<TChildProps, TDataName>
  >(EmployeeInvitationsForAgencyOrganizationDocument, {
    alias: 'employeeInvitationsForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useEmployeeInvitationsForAgencyOrganizationQuery__
 *
 * To run a query within a React component, call `useEmployeeInvitationsForAgencyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeInvitationsForAgencyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeInvitationsForAgencyOrganizationQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEmployeeInvitationsForAgencyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables
  >(EmployeeInvitationsForAgencyOrganizationDocument, options);
}
export function useEmployeeInvitationsForAgencyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmployeeInvitationsForAgencyOrganizationQuery,
    EmployeeInvitationsForAgencyOrganizationQueryVariables
  >(EmployeeInvitationsForAgencyOrganizationDocument, options);
}
export type EmployeeInvitationsForAgencyOrganizationQueryHookResult = ReturnType<
  typeof useEmployeeInvitationsForAgencyOrganizationQuery
>;
export type EmployeeInvitationsForAgencyOrganizationLazyQueryHookResult = ReturnType<
  typeof useEmployeeInvitationsForAgencyOrganizationLazyQuery
>;
export type EmployeeInvitationsForAgencyOrganizationQueryResult = Apollo.QueryResult<
  EmployeeInvitationsForAgencyOrganizationQuery,
  EmployeeInvitationsForAgencyOrganizationQueryVariables
>;
export function refetchEmployeeInvitationsForAgencyOrganizationQuery(
  variables: EmployeeInvitationsForAgencyOrganizationQueryVariables,
) {
  return { query: EmployeeInvitationsForAgencyOrganizationDocument, variables: variables };
}
export const CourierUserProfileByUserIdDocument = gql`
  query CourierUserProfileByUserId($userId: String!) {
    courierUserProfileByUserId(userId: $userId) {
      id
      inactivatedAt
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactAddress
      hasAmexCreditCard
      hasDinerCreditCard
      hasMasterCreditCard
      hasVisaCreditCard
      hasAmexDebitCard
      hasDinerDebitCard
      hasMasterDebitCard
      hasVisaDebitCard
      amexCreditCardLimit
      dinerCreditCardLimit
      masterCreditCardLimit
      visaCreditCardLimit
      amexCreditCardCurrency
      dinerCreditCardCurrency
      masterCreditCardCurrency
      visaCreditCardCurrency
      hasObcExperience
      firstObcJobDateAt
      numberOfPastObcJobs
      hasMedicalObcExperience
      languagesKnown {
        language
        level
      }
      onboardExperiences
      taxCountry
      socialProfiles {
        platform
        type
        value
      }
      baseAirports
      baseAirportLocations {
        id
        iataCode
      }
      user {
        id
        firstNames
        email
        email2
        phone
        phone2
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        createdAt
        updatedAt
        inactivatedAt
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        userRegisteredAddressLocation {
          id
          formattedAddress
          addressName
          apartmentOrSuite
          streetName
          houseNumber
          city
          postalCode
          state
          country
        }
        organizationUsers {
          id
          organization {
            id
            legalName
            name
            email
            phone
            headquartersAddressLocation {
              id
              placeId
              formattedAddress
              formattedShortAddress
              addressName
              apartmentOrSuite
              streetName
              houseNumber
              city
              postalCode
              state
              country
            }
          }
        }
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            label
          }
        }
      }
    }
  }
`;
export type CourierUserProfileByUserIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierUserProfileByUserIdQuery,
    CourierUserProfileByUserIdQueryVariables
  >;
} & TChildProps;
export function withCourierUserProfileByUserId<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierUserProfileByUserIdQuery,
    CourierUserProfileByUserIdQueryVariables,
    CourierUserProfileByUserIdProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierUserProfileByUserIdQuery,
    CourierUserProfileByUserIdQueryVariables,
    CourierUserProfileByUserIdProps<TChildProps, TDataName>
  >(CourierUserProfileByUserIdDocument, {
    alias: 'courierUserProfileByUserId',
    ...operationOptions,
  });
}

/**
 * __useCourierUserProfileByUserIdQuery__
 *
 * To run a query within a React component, call `useCourierUserProfileByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierUserProfileByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierUserProfileByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCourierUserProfileByUserIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierUserProfileByUserIdQuery,
    CourierUserProfileByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourierUserProfileByUserIdQuery, CourierUserProfileByUserIdQueryVariables>(
    CourierUserProfileByUserIdDocument,
    options,
  );
}
export function useCourierUserProfileByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierUserProfileByUserIdQuery,
    CourierUserProfileByUserIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierUserProfileByUserIdQuery,
    CourierUserProfileByUserIdQueryVariables
  >(CourierUserProfileByUserIdDocument, options);
}
export type CourierUserProfileByUserIdQueryHookResult = ReturnType<
  typeof useCourierUserProfileByUserIdQuery
>;
export type CourierUserProfileByUserIdLazyQueryHookResult = ReturnType<
  typeof useCourierUserProfileByUserIdLazyQuery
>;
export type CourierUserProfileByUserIdQueryResult = Apollo.QueryResult<
  CourierUserProfileByUserIdQuery,
  CourierUserProfileByUserIdQueryVariables
>;
export function refetchCourierUserProfileByUserIdQuery(
  variables: CourierUserProfileByUserIdQueryVariables,
) {
  return { query: CourierUserProfileByUserIdDocument, variables: variables };
}
export const CurrentUserContextDocument = gql`
  query CurrentUserContext {
    currentUserContext {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        startTime
        endTime
        isMain
        recurringInterval
        status
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;
export type CurrentUserContextProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CurrentUserContextQuery,
    CurrentUserContextQueryVariables
  >;
} & TChildProps;
export function withCurrentUserContext<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentUserContextQuery,
    CurrentUserContextQueryVariables,
    CurrentUserContextProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentUserContextQuery,
    CurrentUserContextQueryVariables,
    CurrentUserContextProps<TChildProps, TDataName>
  >(CurrentUserContextDocument, {
    alias: 'currentUserContext',
    ...operationOptions,
  });
}

/**
 * __useCurrentUserContextQuery__
 *
 * To run a query within a React component, call `useCurrentUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserContextQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserContextQuery, CurrentUserContextQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserContextQuery, CurrentUserContextQueryVariables>(
    CurrentUserContextDocument,
    options,
  );
}
export function useCurrentUserContextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserContextQuery,
    CurrentUserContextQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserContextQuery, CurrentUserContextQueryVariables>(
    CurrentUserContextDocument,
    options,
  );
}
export type CurrentUserContextQueryHookResult = ReturnType<typeof useCurrentUserContextQuery>;
export type CurrentUserContextLazyQueryHookResult = ReturnType<
  typeof useCurrentUserContextLazyQuery
>;
export type CurrentUserContextQueryResult = Apollo.QueryResult<
  CurrentUserContextQuery,
  CurrentUserContextQueryVariables
>;
export function refetchCurrentUserContextQuery(variables?: CurrentUserContextQueryVariables) {
  return { query: CurrentUserContextDocument, variables: variables };
}
export const UserContextsDocument = gql`
  query UserContexts {
    userContexts {
      sub
      token {
        iss
        exp
        nbf
        aud
        oid
        sub
        city
        country
        name
        givenName
        familyName
        emails
        tfp
        azp
        ver
        iat
      }
      user {
        id
        firstNames
        email
        lastName
      }
      organizationUser {
        id
        roleType
      }
      organization {
        id
        name
        legalName
        organizationType
        usedOrganizationInvitation {
          id
          usedAt
        }
        organizationType
      }
      roleType
      organizationType
      role
      organizationActivatedAt
      device {
        id
        deviceType
      }
      deviceId
      userContextId
      organizationSubscriptionPlan {
        id
        isActive
        endTime
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            count
            name
            value
          }
        }
      }
    }
  }
`;
export type UserContextsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<UserContextsQuery, UserContextsQueryVariables>;
} & TChildProps;
export function withUserContexts<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserContextsQuery,
    UserContextsQueryVariables,
    UserContextsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserContextsQuery,
    UserContextsQueryVariables,
    UserContextsProps<TChildProps, TDataName>
  >(UserContextsDocument, {
    alias: 'userContexts',
    ...operationOptions,
  });
}

/**
 * __useUserContextsQuery__
 *
 * To run a query within a React component, call `useUserContextsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContextsQuery(
  baseOptions?: Apollo.QueryHookOptions<UserContextsQuery, UserContextsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserContextsQuery, UserContextsQueryVariables>(
    UserContextsDocument,
    options,
  );
}
export function useUserContextsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserContextsQuery, UserContextsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserContextsQuery, UserContextsQueryVariables>(
    UserContextsDocument,
    options,
  );
}
export type UserContextsQueryHookResult = ReturnType<typeof useUserContextsQuery>;
export type UserContextsLazyQueryHookResult = ReturnType<typeof useUserContextsLazyQuery>;
export type UserContextsQueryResult = Apollo.QueryResult<
  UserContextsQuery,
  UserContextsQueryVariables
>;
export function refetchUserContextsQuery(variables?: UserContextsQueryVariables) {
  return { query: UserContextsDocument, variables: variables };
}
export const CourierUserProfileForCourierOrganizationUserDocument = gql`
  query CourierUserProfileForCourierOrganizationUser($organizationUserId: String!) {
    courierUserProfileForCourierOrganizationUser(organizationUserId: $organizationUserId) {
      id
      emergencyContactRelationship
      emergencyContactPhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactAddress
      hasAmexCreditCard
      hasDinerCreditCard
      hasMasterCreditCard
      hasVisaCreditCard
      hasAmexDebitCard
      hasDinerDebitCard
      hasMasterDebitCard
      hasVisaDebitCard
      amexCreditCardLimit
      dinerCreditCardLimit
      masterCreditCardLimit
      visaCreditCardLimit
      amexCreditCardCurrency
      dinerCreditCardCurrency
      masterCreditCardCurrency
      visaCreditCardCurrency
      hasObcExperience
      firstObcJobDateAt
      numberOfPastObcJobs
      hasMedicalObcExperience
      languagesKnown {
        language
        level
      }
      onboardExperiences
      taxCountry
      socialProfiles {
        platform
        type
        value
      }
      baseAirports
    }
  }
`;
export type CourierUserProfileForCourierOrganizationUserProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables
  >;
} & TChildProps;
export function withCourierUserProfileForCourierOrganizationUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables,
    CourierUserProfileForCourierOrganizationUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables,
    CourierUserProfileForCourierOrganizationUserProps<TChildProps, TDataName>
  >(CourierUserProfileForCourierOrganizationUserDocument, {
    alias: 'courierUserProfileForCourierOrganizationUser',
    ...operationOptions,
  });
}

/**
 * __useCourierUserProfileForCourierOrganizationUserQuery__
 *
 * To run a query within a React component, call `useCourierUserProfileForCourierOrganizationUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierUserProfileForCourierOrganizationUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierUserProfileForCourierOrganizationUserQuery({
 *   variables: {
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useCourierUserProfileForCourierOrganizationUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables
  >(CourierUserProfileForCourierOrganizationUserDocument, options);
}
export function useCourierUserProfileForCourierOrganizationUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierUserProfileForCourierOrganizationUserQuery,
    CourierUserProfileForCourierOrganizationUserQueryVariables
  >(CourierUserProfileForCourierOrganizationUserDocument, options);
}
export type CourierUserProfileForCourierOrganizationUserQueryHookResult = ReturnType<
  typeof useCourierUserProfileForCourierOrganizationUserQuery
>;
export type CourierUserProfileForCourierOrganizationUserLazyQueryHookResult = ReturnType<
  typeof useCourierUserProfileForCourierOrganizationUserLazyQuery
>;
export type CourierUserProfileForCourierOrganizationUserQueryResult = Apollo.QueryResult<
  CourierUserProfileForCourierOrganizationUserQuery,
  CourierUserProfileForCourierOrganizationUserQueryVariables
>;
export function refetchCourierUserProfileForCourierOrganizationUserQuery(
  variables: CourierUserProfileForCourierOrganizationUserQueryVariables,
) {
  return { query: CourierUserProfileForCourierOrganizationUserDocument, variables: variables };
}
export const UserDocument = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      firstNames
      lastName
      sex
      dateOfBirth
      placeOfBirth
      countryOfBirth
      language
      nationalities
      email
      phone
      email2
      phone2
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      userRegisteredAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
    }
  }
`;
export type UserProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<UserQuery, UserQueryVariables>;
} & TChildProps;
export function withUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserQuery,
    UserQueryVariables,
    UserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserQuery,
    UserQueryVariables,
    UserProps<TChildProps, TDataName>
  >(UserDocument, {
    alias: 'user',
    ...operationOptions,
  });
}

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export function refetchUserQuery(variables: UserQueryVariables) {
  return { query: UserDocument, variables: variables };
}
export const UserMinimalDocument = gql`
  query UserMinimal($id: String!) {
    user(id: $id) {
      id
      firstNames
      lastName
    }
  }
`;
export type UserMinimalProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<UserMinimalQuery, UserMinimalQueryVariables>;
} & TChildProps;
export function withUserMinimal<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserMinimalQuery,
    UserMinimalQueryVariables,
    UserMinimalProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserMinimalQuery,
    UserMinimalQueryVariables,
    UserMinimalProps<TChildProps, TDataName>
  >(UserMinimalDocument, {
    alias: 'userMinimal',
    ...operationOptions,
  });
}

/**
 * __useUserMinimalQuery__
 *
 * To run a query within a React component, call `useUserMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMinimalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserMinimalQuery(
  baseOptions: Apollo.QueryHookOptions<UserMinimalQuery, UserMinimalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserMinimalQuery, UserMinimalQueryVariables>(UserMinimalDocument, options);
}
export function useUserMinimalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserMinimalQuery, UserMinimalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserMinimalQuery, UserMinimalQueryVariables>(
    UserMinimalDocument,
    options,
  );
}
export type UserMinimalQueryHookResult = ReturnType<typeof useUserMinimalQuery>;
export type UserMinimalLazyQueryHookResult = ReturnType<typeof useUserMinimalLazyQuery>;
export type UserMinimalQueryResult = Apollo.QueryResult<
  UserMinimalQuery,
  UserMinimalQueryVariables
>;
export function refetchUserMinimalQuery(variables: UserMinimalQueryVariables) {
  return { query: UserMinimalDocument, variables: variables };
}
export const UserFrequentFlyerDocumentsDocument = gql`
  query UserFrequentFlyerDocuments($userId: String!) {
    userFrequentFlyerDocuments(userId: $userId) {
      id
      airlineType
      airlineStatusType
      airlineAllianceStatusType
      airlineAllianceType
      documentNumber
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserFrequentFlyerDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserFrequentFlyerDocumentsQuery,
    UserFrequentFlyerDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserFrequentFlyerDocuments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserFrequentFlyerDocumentsQuery,
    UserFrequentFlyerDocumentsQueryVariables,
    UserFrequentFlyerDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserFrequentFlyerDocumentsQuery,
    UserFrequentFlyerDocumentsQueryVariables,
    UserFrequentFlyerDocumentsProps<TChildProps, TDataName>
  >(UserFrequentFlyerDocumentsDocument, {
    alias: 'userFrequentFlyerDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserFrequentFlyerDocumentsQuery__
 *
 * To run a query within a React component, call `useUserFrequentFlyerDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFrequentFlyerDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFrequentFlyerDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserFrequentFlyerDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserFrequentFlyerDocumentsQuery,
    UserFrequentFlyerDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserFrequentFlyerDocumentsQuery, UserFrequentFlyerDocumentsQueryVariables>(
    UserFrequentFlyerDocumentsDocument,
    options,
  );
}
export function useUserFrequentFlyerDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFrequentFlyerDocumentsQuery,
    UserFrequentFlyerDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserFrequentFlyerDocumentsQuery,
    UserFrequentFlyerDocumentsQueryVariables
  >(UserFrequentFlyerDocumentsDocument, options);
}
export type UserFrequentFlyerDocumentsQueryHookResult = ReturnType<
  typeof useUserFrequentFlyerDocumentsQuery
>;
export type UserFrequentFlyerDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserFrequentFlyerDocumentsLazyQuery
>;
export type UserFrequentFlyerDocumentsQueryResult = Apollo.QueryResult<
  UserFrequentFlyerDocumentsQuery,
  UserFrequentFlyerDocumentsQueryVariables
>;
export function refetchUserFrequentFlyerDocumentsQuery(
  variables: UserFrequentFlyerDocumentsQueryVariables,
) {
  return { query: UserFrequentFlyerDocumentsDocument, variables: variables };
}
export const UserFrequentFlyerDocumentDocument = gql`
  query UserFrequentFlyerDocument($documentId: String!) {
    userFrequentFlyerDocument(documentId: $documentId) {
      id
      airlineType
      airlineStatusType
      airlineAllianceStatusType
      airlineAllianceType
      documentNumber
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserFrequentFlyerDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserFrequentFlyerDocumentQuery,
    UserFrequentFlyerDocumentQueryVariables
  >;
} & TChildProps;
export function withUserFrequentFlyerDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserFrequentFlyerDocumentQuery,
    UserFrequentFlyerDocumentQueryVariables,
    UserFrequentFlyerDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserFrequentFlyerDocumentQuery,
    UserFrequentFlyerDocumentQueryVariables,
    UserFrequentFlyerDocumentProps<TChildProps, TDataName>
  >(UserFrequentFlyerDocumentDocument, {
    alias: 'userFrequentFlyerDocument',
    ...operationOptions,
  });
}

/**
 * __useUserFrequentFlyerDocumentQuery__
 *
 * To run a query within a React component, call `useUserFrequentFlyerDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFrequentFlyerDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFrequentFlyerDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserFrequentFlyerDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserFrequentFlyerDocumentQuery,
    UserFrequentFlyerDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserFrequentFlyerDocumentQuery, UserFrequentFlyerDocumentQueryVariables>(
    UserFrequentFlyerDocumentDocument,
    options,
  );
}
export function useUserFrequentFlyerDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserFrequentFlyerDocumentQuery,
    UserFrequentFlyerDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserFrequentFlyerDocumentQuery,
    UserFrequentFlyerDocumentQueryVariables
  >(UserFrequentFlyerDocumentDocument, options);
}
export type UserFrequentFlyerDocumentQueryHookResult = ReturnType<
  typeof useUserFrequentFlyerDocumentQuery
>;
export type UserFrequentFlyerDocumentLazyQueryHookResult = ReturnType<
  typeof useUserFrequentFlyerDocumentLazyQuery
>;
export type UserFrequentFlyerDocumentQueryResult = Apollo.QueryResult<
  UserFrequentFlyerDocumentQuery,
  UserFrequentFlyerDocumentQueryVariables
>;
export function refetchUserFrequentFlyerDocumentQuery(
  variables: UserFrequentFlyerDocumentQueryVariables,
) {
  return { query: UserFrequentFlyerDocumentDocument, variables: variables };
}
export const UserVaccinationDocumentsDocument = gql`
  query UserVaccinationDocuments($userId: String!) {
    userVaccinationDocuments(userId: $userId) {
      id
      diseaseType
      description
      vaccinationType
      vaccinationManufacturer
      dateOfVaccination
      isVaccinationComplete
      vaccinationCenter
      cityOfVaccination
      countryOfVaccination
      expiresOn
      documentNumber
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserVaccinationDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserVaccinationDocumentsQuery,
    UserVaccinationDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserVaccinationDocuments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserVaccinationDocumentsQuery,
    UserVaccinationDocumentsQueryVariables,
    UserVaccinationDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserVaccinationDocumentsQuery,
    UserVaccinationDocumentsQueryVariables,
    UserVaccinationDocumentsProps<TChildProps, TDataName>
  >(UserVaccinationDocumentsDocument, {
    alias: 'userVaccinationDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserVaccinationDocumentsQuery__
 *
 * To run a query within a React component, call `useUserVaccinationDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVaccinationDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVaccinationDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserVaccinationDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserVaccinationDocumentsQuery,
    UserVaccinationDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserVaccinationDocumentsQuery, UserVaccinationDocumentsQueryVariables>(
    UserVaccinationDocumentsDocument,
    options,
  );
}
export function useUserVaccinationDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserVaccinationDocumentsQuery,
    UserVaccinationDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserVaccinationDocumentsQuery, UserVaccinationDocumentsQueryVariables>(
    UserVaccinationDocumentsDocument,
    options,
  );
}
export type UserVaccinationDocumentsQueryHookResult = ReturnType<
  typeof useUserVaccinationDocumentsQuery
>;
export type UserVaccinationDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserVaccinationDocumentsLazyQuery
>;
export type UserVaccinationDocumentsQueryResult = Apollo.QueryResult<
  UserVaccinationDocumentsQuery,
  UserVaccinationDocumentsQueryVariables
>;
export function refetchUserVaccinationDocumentsQuery(
  variables: UserVaccinationDocumentsQueryVariables,
) {
  return { query: UserVaccinationDocumentsDocument, variables: variables };
}
export const UserVaccinationDocumentDocument = gql`
  query UserVaccinationDocument($documentId: String!) {
    userVaccinationDocument(documentId: $documentId) {
      id
      diseaseType
      description
      vaccinationType
      vaccinationManufacturer
      dateOfVaccination
      isVaccinationComplete
      vaccinationCenter
      cityOfVaccination
      countryOfVaccination
      expiresOn
      documentNumber
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserVaccinationDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserVaccinationDocumentQuery,
    UserVaccinationDocumentQueryVariables
  >;
} & TChildProps;
export function withUserVaccinationDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserVaccinationDocumentQuery,
    UserVaccinationDocumentQueryVariables,
    UserVaccinationDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserVaccinationDocumentQuery,
    UserVaccinationDocumentQueryVariables,
    UserVaccinationDocumentProps<TChildProps, TDataName>
  >(UserVaccinationDocumentDocument, {
    alias: 'userVaccinationDocument',
    ...operationOptions,
  });
}

/**
 * __useUserVaccinationDocumentQuery__
 *
 * To run a query within a React component, call `useUserVaccinationDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserVaccinationDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserVaccinationDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserVaccinationDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserVaccinationDocumentQuery,
    UserVaccinationDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserVaccinationDocumentQuery, UserVaccinationDocumentQueryVariables>(
    UserVaccinationDocumentDocument,
    options,
  );
}
export function useUserVaccinationDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserVaccinationDocumentQuery,
    UserVaccinationDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserVaccinationDocumentQuery, UserVaccinationDocumentQueryVariables>(
    UserVaccinationDocumentDocument,
    options,
  );
}
export type UserVaccinationDocumentQueryHookResult = ReturnType<
  typeof useUserVaccinationDocumentQuery
>;
export type UserVaccinationDocumentLazyQueryHookResult = ReturnType<
  typeof useUserVaccinationDocumentLazyQuery
>;
export type UserVaccinationDocumentQueryResult = Apollo.QueryResult<
  UserVaccinationDocumentQuery,
  UserVaccinationDocumentQueryVariables
>;
export function refetchUserVaccinationDocumentQuery(
  variables: UserVaccinationDocumentQueryVariables,
) {
  return { query: UserVaccinationDocumentDocument, variables: variables };
}
export const UserHealthReportsDocument = gql`
  query UserHealthReports($userId: String!) {
    userHealthReports(userId: $userId) {
      id
      diseaseType
      description
      testType
      dateTimeOfTest
      isResultPositive
      testCenter
      cityOfTest
      cityOfTestLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      countryOfTest
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserHealthReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserHealthReportsQuery,
    UserHealthReportsQueryVariables
  >;
} & TChildProps;
export function withUserHealthReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserHealthReportsQuery,
    UserHealthReportsQueryVariables,
    UserHealthReportsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserHealthReportsQuery,
    UserHealthReportsQueryVariables,
    UserHealthReportsProps<TChildProps, TDataName>
  >(UserHealthReportsDocument, {
    alias: 'userHealthReports',
    ...operationOptions,
  });
}

/**
 * __useUserHealthReportsQuery__
 *
 * To run a query within a React component, call `useUserHealthReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHealthReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHealthReportsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserHealthReportsQuery(
  baseOptions: Apollo.QueryHookOptions<UserHealthReportsQuery, UserHealthReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHealthReportsQuery, UserHealthReportsQueryVariables>(
    UserHealthReportsDocument,
    options,
  );
}
export function useUserHealthReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserHealthReportsQuery,
    UserHealthReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHealthReportsQuery, UserHealthReportsQueryVariables>(
    UserHealthReportsDocument,
    options,
  );
}
export type UserHealthReportsQueryHookResult = ReturnType<typeof useUserHealthReportsQuery>;
export type UserHealthReportsLazyQueryHookResult = ReturnType<typeof useUserHealthReportsLazyQuery>;
export type UserHealthReportsQueryResult = Apollo.QueryResult<
  UserHealthReportsQuery,
  UserHealthReportsQueryVariables
>;
export function refetchUserHealthReportsQuery(variables: UserHealthReportsQueryVariables) {
  return { query: UserHealthReportsDocument, variables: variables };
}
export const UserHealthReportDocument = gql`
  query UserHealthReport($documentId: String!) {
    userHealthReport(documentId: $documentId) {
      id
      diseaseType
      description
      testType
      dateTimeOfTest
      isResultPositive
      testCenter
      cityOfTest
      cityOfTestLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      countryOfTest
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserHealthReportProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserHealthReportQuery,
    UserHealthReportQueryVariables
  >;
} & TChildProps;
export function withUserHealthReport<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserHealthReportQuery,
    UserHealthReportQueryVariables,
    UserHealthReportProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserHealthReportQuery,
    UserHealthReportQueryVariables,
    UserHealthReportProps<TChildProps, TDataName>
  >(UserHealthReportDocument, {
    alias: 'userHealthReport',
    ...operationOptions,
  });
}

/**
 * __useUserHealthReportQuery__
 *
 * To run a query within a React component, call `useUserHealthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHealthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHealthReportQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserHealthReportQuery(
  baseOptions: Apollo.QueryHookOptions<UserHealthReportQuery, UserHealthReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserHealthReportQuery, UserHealthReportQueryVariables>(
    UserHealthReportDocument,
    options,
  );
}
export function useUserHealthReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserHealthReportQuery, UserHealthReportQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserHealthReportQuery, UserHealthReportQueryVariables>(
    UserHealthReportDocument,
    options,
  );
}
export type UserHealthReportQueryHookResult = ReturnType<typeof useUserHealthReportQuery>;
export type UserHealthReportLazyQueryHookResult = ReturnType<typeof useUserHealthReportLazyQuery>;
export type UserHealthReportQueryResult = Apollo.QueryResult<
  UserHealthReportQuery,
  UserHealthReportQueryVariables
>;
export function refetchUserHealthReportQuery(variables: UserHealthReportQueryVariables) {
  return { query: UserHealthReportDocument, variables: variables };
}
export const UserBackgroundDocumentsDocument = gql`
  query UserBackgroundDocuments($userId: String!) {
    userBackgroundDocuments(userId: $userId) {
      id
      name
      issuingInstitution
      countryOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserBackgroundDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserBackgroundDocumentsQuery,
    UserBackgroundDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserBackgroundDocuments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserBackgroundDocumentsQuery,
    UserBackgroundDocumentsQueryVariables,
    UserBackgroundDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserBackgroundDocumentsQuery,
    UserBackgroundDocumentsQueryVariables,
    UserBackgroundDocumentsProps<TChildProps, TDataName>
  >(UserBackgroundDocumentsDocument, {
    alias: 'userBackgroundDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserBackgroundDocumentsQuery__
 *
 * To run a query within a React component, call `useUserBackgroundDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBackgroundDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBackgroundDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserBackgroundDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBackgroundDocumentsQuery,
    UserBackgroundDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserBackgroundDocumentsQuery, UserBackgroundDocumentsQueryVariables>(
    UserBackgroundDocumentsDocument,
    options,
  );
}
export function useUserBackgroundDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBackgroundDocumentsQuery,
    UserBackgroundDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserBackgroundDocumentsQuery, UserBackgroundDocumentsQueryVariables>(
    UserBackgroundDocumentsDocument,
    options,
  );
}
export type UserBackgroundDocumentsQueryHookResult = ReturnType<
  typeof useUserBackgroundDocumentsQuery
>;
export type UserBackgroundDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserBackgroundDocumentsLazyQuery
>;
export type UserBackgroundDocumentsQueryResult = Apollo.QueryResult<
  UserBackgroundDocumentsQuery,
  UserBackgroundDocumentsQueryVariables
>;
export function refetchUserBackgroundDocumentsQuery(
  variables: UserBackgroundDocumentsQueryVariables,
) {
  return { query: UserBackgroundDocumentsDocument, variables: variables };
}
export const UserBackgroundDocumentDocument = gql`
  query UserBackgroundDocument($documentId: String!) {
    userBackgroundDocument(documentId: $documentId) {
      id
      name
      issuingInstitution
      countryOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserBackgroundDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserBackgroundDocumentQuery,
    UserBackgroundDocumentQueryVariables
  >;
} & TChildProps;
export function withUserBackgroundDocument<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserBackgroundDocumentQuery,
    UserBackgroundDocumentQueryVariables,
    UserBackgroundDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserBackgroundDocumentQuery,
    UserBackgroundDocumentQueryVariables,
    UserBackgroundDocumentProps<TChildProps, TDataName>
  >(UserBackgroundDocumentDocument, {
    alias: 'userBackgroundDocument',
    ...operationOptions,
  });
}

/**
 * __useUserBackgroundDocumentQuery__
 *
 * To run a query within a React component, call `useUserBackgroundDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBackgroundDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBackgroundDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserBackgroundDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserBackgroundDocumentQuery,
    UserBackgroundDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserBackgroundDocumentQuery, UserBackgroundDocumentQueryVariables>(
    UserBackgroundDocumentDocument,
    options,
  );
}
export function useUserBackgroundDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserBackgroundDocumentQuery,
    UserBackgroundDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserBackgroundDocumentQuery, UserBackgroundDocumentQueryVariables>(
    UserBackgroundDocumentDocument,
    options,
  );
}
export type UserBackgroundDocumentQueryHookResult = ReturnType<
  typeof useUserBackgroundDocumentQuery
>;
export type UserBackgroundDocumentLazyQueryHookResult = ReturnType<
  typeof useUserBackgroundDocumentLazyQuery
>;
export type UserBackgroundDocumentQueryResult = Apollo.QueryResult<
  UserBackgroundDocumentQuery,
  UserBackgroundDocumentQueryVariables
>;
export function refetchUserBackgroundDocumentQuery(
  variables: UserBackgroundDocumentQueryVariables,
) {
  return { query: UserBackgroundDocumentDocument, variables: variables };
}
export const UserOtherDocumentsDocument = gql`
  query UserOtherDocuments($userId: String!) {
    userOtherDocuments(userId: $userId) {
      id
      name
      description
      countryOfIssue
      placeOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserOtherDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserOtherDocumentsQuery,
    UserOtherDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserOtherDocuments<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserOtherDocumentsQuery,
    UserOtherDocumentsQueryVariables,
    UserOtherDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserOtherDocumentsQuery,
    UserOtherDocumentsQueryVariables,
    UserOtherDocumentsProps<TChildProps, TDataName>
  >(UserOtherDocumentsDocument, {
    alias: 'userOtherDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserOtherDocumentsQuery__
 *
 * To run a query within a React component, call `useUserOtherDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOtherDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOtherDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserOtherDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<UserOtherDocumentsQuery, UserOtherDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserOtherDocumentsQuery, UserOtherDocumentsQueryVariables>(
    UserOtherDocumentsDocument,
    options,
  );
}
export function useUserOtherDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserOtherDocumentsQuery,
    UserOtherDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserOtherDocumentsQuery, UserOtherDocumentsQueryVariables>(
    UserOtherDocumentsDocument,
    options,
  );
}
export type UserOtherDocumentsQueryHookResult = ReturnType<typeof useUserOtherDocumentsQuery>;
export type UserOtherDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserOtherDocumentsLazyQuery
>;
export type UserOtherDocumentsQueryResult = Apollo.QueryResult<
  UserOtherDocumentsQuery,
  UserOtherDocumentsQueryVariables
>;
export function refetchUserOtherDocumentsQuery(variables: UserOtherDocumentsQueryVariables) {
  return { query: UserOtherDocumentsDocument, variables: variables };
}
export const UserOtherDocumentDocument = gql`
  query UserOtherDocument($documentId: String!) {
    userOtherDocument(documentId: $documentId) {
      id
      name
      description
      countryOfIssue
      placeOfIssue
      dateOfIssue
      expiresOn
      documentContainerName
      originalDocumentFrontFilePath
      originalDocumentBackFilePath
      compressedDocumentFrontFilePath
      compressedDocumentBackFilePath
      updatedAt
      createdAt
      verifiedOn
      verificationStatus
      verificationComments
      documentFrontFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentFrontFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureCompressed {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      documentBackFromAzureOriginal {
        sasUrl
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserOtherDocumentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserOtherDocumentQuery,
    UserOtherDocumentQueryVariables
  >;
} & TChildProps;
export function withUserOtherDocument<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserOtherDocumentQuery,
    UserOtherDocumentQueryVariables,
    UserOtherDocumentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserOtherDocumentQuery,
    UserOtherDocumentQueryVariables,
    UserOtherDocumentProps<TChildProps, TDataName>
  >(UserOtherDocumentDocument, {
    alias: 'userOtherDocument',
    ...operationOptions,
  });
}

/**
 * __useUserOtherDocumentQuery__
 *
 * To run a query within a React component, call `useUserOtherDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOtherDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOtherDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUserOtherDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<UserOtherDocumentQuery, UserOtherDocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserOtherDocumentQuery, UserOtherDocumentQueryVariables>(
    UserOtherDocumentDocument,
    options,
  );
}
export function useUserOtherDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserOtherDocumentQuery,
    UserOtherDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserOtherDocumentQuery, UserOtherDocumentQueryVariables>(
    UserOtherDocumentDocument,
    options,
  );
}
export type UserOtherDocumentQueryHookResult = ReturnType<typeof useUserOtherDocumentQuery>;
export type UserOtherDocumentLazyQueryHookResult = ReturnType<typeof useUserOtherDocumentLazyQuery>;
export type UserOtherDocumentQueryResult = Apollo.QueryResult<
  UserOtherDocumentQuery,
  UserOtherDocumentQueryVariables
>;
export function refetchUserOtherDocumentQuery(variables: UserOtherDocumentQueryVariables) {
  return { query: UserOtherDocumentDocument, variables: variables };
}
export const UserWithAllUserDocumentsDocument = gql`
  query userWithAllUserDocuments($userId: String!) {
    userWithAllUserDocuments(userId: $userId) {
      id
      userBackgroundDocument {
        id
      }
      userFrequentFlyerDocuments {
        id
      }
      userHealthReports {
        id
      }
      userIdentityDocuments {
        id
      }
      userOtherDocuments {
        id
      }
      userTravelDocuments {
        id
      }
      userVaccinationDocuments {
        id
      }
    }
  }
`;
export type UserWithAllUserDocumentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    UserWithAllUserDocumentsQuery,
    UserWithAllUserDocumentsQueryVariables
  >;
} & TChildProps;
export function withUserWithAllUserDocuments<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserWithAllUserDocumentsQuery,
    UserWithAllUserDocumentsQueryVariables,
    UserWithAllUserDocumentsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserWithAllUserDocumentsQuery,
    UserWithAllUserDocumentsQueryVariables,
    UserWithAllUserDocumentsProps<TChildProps, TDataName>
  >(UserWithAllUserDocumentsDocument, {
    alias: 'userWithAllUserDocuments',
    ...operationOptions,
  });
}

/**
 * __useUserWithAllUserDocumentsQuery__
 *
 * To run a query within a React component, call `useUserWithAllUserDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithAllUserDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithAllUserDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserWithAllUserDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserWithAllUserDocumentsQuery,
    UserWithAllUserDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserWithAllUserDocumentsQuery, UserWithAllUserDocumentsQueryVariables>(
    UserWithAllUserDocumentsDocument,
    options,
  );
}
export function useUserWithAllUserDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserWithAllUserDocumentsQuery,
    UserWithAllUserDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserWithAllUserDocumentsQuery, UserWithAllUserDocumentsQueryVariables>(
    UserWithAllUserDocumentsDocument,
    options,
  );
}
export type UserWithAllUserDocumentsQueryHookResult = ReturnType<
  typeof useUserWithAllUserDocumentsQuery
>;
export type UserWithAllUserDocumentsLazyQueryHookResult = ReturnType<
  typeof useUserWithAllUserDocumentsLazyQuery
>;
export type UserWithAllUserDocumentsQueryResult = Apollo.QueryResult<
  UserWithAllUserDocumentsQuery,
  UserWithAllUserDocumentsQueryVariables
>;
export function refetchUserWithAllUserDocumentsQuery(
  variables: UserWithAllUserDocumentsQueryVariables,
) {
  return { query: UserWithAllUserDocumentsDocument, variables: variables };
}
export const CourierJobForCourierOrganizationDocument = gql`
  query CourierJobForCourierOrganization($courierOrganizationId: String!, $courierJobId: String!) {
    courierJobForCourierOrganization(
      courierOrganizationId: $courierOrganizationId
      courierJobId: $courierJobId
    ) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      relationshipTypeChangedAt
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      responsibleCourierOrganization {
        id
        name
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        numOfItems
        weightOfItems
        numOfCouriersRequired
        isAdditionalCostPaidByCourier
        additionalCostInformation
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        responsibleAgencyOrganization {
          id
          name
          legalName
          shortName
          organizationType
        }
        responsibleAgencyOrganizationUsers {
          id
          roleType
          organization {
            id
            name
            legalName
            shortName
            organizationType
          }
          user {
            id
            firstNames
            lastName
            email
          }
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type CourierJobForCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables
  >;
} & TChildProps;
export function withCourierJobForCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables,
    CourierJobForCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables,
    CourierJobForCourierOrganizationProps<TChildProps, TDataName>
  >(CourierJobForCourierOrganizationDocument, {
    alias: 'courierJobForCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useCourierJobForCourierOrganizationQuery__
 *
 * To run a query within a React component, call `useCourierJobForCourierOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierJobForCourierOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierJobForCourierOrganizationQuery({
 *   variables: {
 *      courierOrganizationId: // value for 'courierOrganizationId'
 *      courierJobId: // value for 'courierJobId'
 *   },
 * });
 */
export function useCourierJobForCourierOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables
  >(CourierJobForCourierOrganizationDocument, options);
}
export function useCourierJobForCourierOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierJobForCourierOrganizationQuery,
    CourierJobForCourierOrganizationQueryVariables
  >(CourierJobForCourierOrganizationDocument, options);
}
export type CourierJobForCourierOrganizationQueryHookResult = ReturnType<
  typeof useCourierJobForCourierOrganizationQuery
>;
export type CourierJobForCourierOrganizationLazyQueryHookResult = ReturnType<
  typeof useCourierJobForCourierOrganizationLazyQuery
>;
export type CourierJobForCourierOrganizationQueryResult = Apollo.QueryResult<
  CourierJobForCourierOrganizationQuery,
  CourierJobForCourierOrganizationQueryVariables
>;
export function refetchCourierJobForCourierOrganizationQuery(
  variables: CourierJobForCourierOrganizationQueryVariables,
) {
  return { query: CourierJobForCourierOrganizationDocument, variables: variables };
}
export const CourierJobForAgencyOrganizationDocument = gql`
  query CourierJobForAgencyOrganization($agencyOrganizationId: String!, $courierJobId: String!) {
    courierJobForAgencyOrganization(
      agencyOrganizationId: $agencyOrganizationId
      courierJobId: $courierJobId
    ) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      relationshipTypeChangedAt
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      responsibleCourierOrganization {
        id
        name
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        numOfItems
        weightOfItems
        numOfCouriersRequired
        isAdditionalCostPaidByCourier
        additionalCostInformation
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        responsibleAgencyOrganization {
          id
          name
          legalName
          shortName
          organizationType
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type CourierJobForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables
  >;
} & TChildProps;
export function withCourierJobForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables,
    CourierJobForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables,
    CourierJobForAgencyOrganizationProps<TChildProps, TDataName>
  >(CourierJobForAgencyOrganizationDocument, {
    alias: 'courierJobForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useCourierJobForAgencyOrganizationQuery__
 *
 * To run a query within a React component, call `useCourierJobForAgencyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierJobForAgencyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierJobForAgencyOrganizationQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *      courierJobId: // value for 'courierJobId'
 *   },
 * });
 */
export function useCourierJobForAgencyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables
  >(CourierJobForAgencyOrganizationDocument, options);
}
export function useCourierJobForAgencyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierJobForAgencyOrganizationQuery,
    CourierJobForAgencyOrganizationQueryVariables
  >(CourierJobForAgencyOrganizationDocument, options);
}
export type CourierJobForAgencyOrganizationQueryHookResult = ReturnType<
  typeof useCourierJobForAgencyOrganizationQuery
>;
export type CourierJobForAgencyOrganizationLazyQueryHookResult = ReturnType<
  typeof useCourierJobForAgencyOrganizationLazyQuery
>;
export type CourierJobForAgencyOrganizationQueryResult = Apollo.QueryResult<
  CourierJobForAgencyOrganizationQuery,
  CourierJobForAgencyOrganizationQueryVariables
>;
export function refetchCourierJobForAgencyOrganizationQuery(
  variables: CourierJobForAgencyOrganizationQueryVariables,
) {
  return { query: CourierJobForAgencyOrganizationDocument, variables: variables };
}
export const CourierJobsForCourierOrganizationDocument = gql`
  query CourierJobsForCourierOrganization($courierOrganizationId: String!) {
    courierJobsForCourierOrganization(courierOrganizationId: $courierOrganizationId) {
      id
      declineReasonType
      relationshipTypeChangedAt
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      relationshipType
      courierJobStatusType
      responsibleCourierOrganization {
        id
        name
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        relationshipType
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        responsibleAgencyOrganization {
          id
          name
          legalName
          shortName
          organizationType
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
    }
  }
`;
export type CourierJobsForCourierOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables
  >;
} & TChildProps;
export function withCourierJobsForCourierOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables,
    CourierJobsForCourierOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables,
    CourierJobsForCourierOrganizationProps<TChildProps, TDataName>
  >(CourierJobsForCourierOrganizationDocument, {
    alias: 'courierJobsForCourierOrganization',
    ...operationOptions,
  });
}

/**
 * __useCourierJobsForCourierOrganizationQuery__
 *
 * To run a query within a React component, call `useCourierJobsForCourierOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierJobsForCourierOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierJobsForCourierOrganizationQuery({
 *   variables: {
 *      courierOrganizationId: // value for 'courierOrganizationId'
 *   },
 * });
 */
export function useCourierJobsForCourierOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables
  >(CourierJobsForCourierOrganizationDocument, options);
}
export function useCourierJobsForCourierOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierJobsForCourierOrganizationQuery,
    CourierJobsForCourierOrganizationQueryVariables
  >(CourierJobsForCourierOrganizationDocument, options);
}
export type CourierJobsForCourierOrganizationQueryHookResult = ReturnType<
  typeof useCourierJobsForCourierOrganizationQuery
>;
export type CourierJobsForCourierOrganizationLazyQueryHookResult = ReturnType<
  typeof useCourierJobsForCourierOrganizationLazyQuery
>;
export type CourierJobsForCourierOrganizationQueryResult = Apollo.QueryResult<
  CourierJobsForCourierOrganizationQuery,
  CourierJobsForCourierOrganizationQueryVariables
>;
export function refetchCourierJobsForCourierOrganizationQuery(
  variables: CourierJobsForCourierOrganizationQueryVariables,
) {
  return { query: CourierJobsForCourierOrganizationDocument, variables: variables };
}
export const CourierJobsForCourierOrganizationMinimalDocument = gql`
  query CourierJobsForCourierOrganizationMinimal($courierOrganizationId: String!) {
    courierJobsForCourierOrganizationMinimal(courierOrganizationId: $courierOrganizationId) {
      id
      courierJobStatusType
      relationshipType
      agencyJob {
        id
        agencyJobStatusType
      }
      job {
        id
      }
    }
  }
`;
export type CourierJobsForCourierOrganizationMinimalProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables
  >;
} & TChildProps;
export function withCourierJobsForCourierOrganizationMinimal<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables,
    CourierJobsForCourierOrganizationMinimalProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables,
    CourierJobsForCourierOrganizationMinimalProps<TChildProps, TDataName>
  >(CourierJobsForCourierOrganizationMinimalDocument, {
    alias: 'courierJobsForCourierOrganizationMinimal',
    ...operationOptions,
  });
}

/**
 * __useCourierJobsForCourierOrganizationMinimalQuery__
 *
 * To run a query within a React component, call `useCourierJobsForCourierOrganizationMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierJobsForCourierOrganizationMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierJobsForCourierOrganizationMinimalQuery({
 *   variables: {
 *      courierOrganizationId: // value for 'courierOrganizationId'
 *   },
 * });
 */
export function useCourierJobsForCourierOrganizationMinimalQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables
  >(CourierJobsForCourierOrganizationMinimalDocument, options);
}
export function useCourierJobsForCourierOrganizationMinimalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierJobsForCourierOrganizationMinimalQuery,
    CourierJobsForCourierOrganizationMinimalQueryVariables
  >(CourierJobsForCourierOrganizationMinimalDocument, options);
}
export type CourierJobsForCourierOrganizationMinimalQueryHookResult = ReturnType<
  typeof useCourierJobsForCourierOrganizationMinimalQuery
>;
export type CourierJobsForCourierOrganizationMinimalLazyQueryHookResult = ReturnType<
  typeof useCourierJobsForCourierOrganizationMinimalLazyQuery
>;
export type CourierJobsForCourierOrganizationMinimalQueryResult = Apollo.QueryResult<
  CourierJobsForCourierOrganizationMinimalQuery,
  CourierJobsForCourierOrganizationMinimalQueryVariables
>;
export function refetchCourierJobsForCourierOrganizationMinimalQuery(
  variables: CourierJobsForCourierOrganizationMinimalQueryVariables,
) {
  return { query: CourierJobsForCourierOrganizationMinimalDocument, variables: variables };
}
export const AgencyJobsForAgencyOrganizationPaginatedDocument = gql`
  query AgencyJobsForAgencyOrganizationPaginated($data: AgencyJobsForAgencyOrganizationInput!) {
    agencyJobsForAgencyOrganizationPaginated(data: $data) {
      edges {
        node {
          id
          returnTimeAtStartAirport
          checkInTimeAtStartAirport
          returnTimeAtEndAirport
          checkInTimeAtEndAirport
          externalJobReference
          hasIncident
          agencyJobStatusType
          internalNotes
          startAirport {
            id
            placeId
            formattedAddress
            formattedShortAddress
            formattedAirportAddress
            timeZoneIdentifier
            timeZoneRawOffset
          }
          endAirport {
            id
            placeId
            formattedAddress
            formattedShortAddress
            formattedAirportAddress
            timeZoneIdentifier
            timeZoneRawOffset
          }
          job {
            id
            priojetJobIdentifier
            pickupTime
            pickupAddressDescription
            dropOffTime
            pickupLocation {
              id
              placeId
              formattedAddress
              formattedShortAddress
              timeZoneIdentifier
              timeZoneRawOffset
            }
            dropOffLocation {
              id
              placeId
              formattedAddress
              formattedShortAddress
              timeZoneIdentifier
              timeZoneRawOffset
            }
          }
          courierJobs {
            id
            relationshipType
          }
          responsibleAgencyOrganization {
            id
            linkCO2Emissions
          }
        }
      }
    }
  }
`;
export type AgencyJobsForAgencyOrganizationPaginatedProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables
  >;
} & TChildProps;
export function withAgencyJobsForAgencyOrganizationPaginated<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables,
    AgencyJobsForAgencyOrganizationPaginatedProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables,
    AgencyJobsForAgencyOrganizationPaginatedProps<TChildProps, TDataName>
  >(AgencyJobsForAgencyOrganizationPaginatedDocument, {
    alias: 'agencyJobsForAgencyOrganizationPaginated',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobsForAgencyOrganizationPaginatedQuery__
 *
 * To run a query within a React component, call `useAgencyJobsForAgencyOrganizationPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobsForAgencyOrganizationPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobsForAgencyOrganizationPaginatedQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAgencyJobsForAgencyOrganizationPaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables
  >(AgencyJobsForAgencyOrganizationPaginatedDocument, options);
}
export function useAgencyJobsForAgencyOrganizationPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobsForAgencyOrganizationPaginatedQuery,
    AgencyJobsForAgencyOrganizationPaginatedQueryVariables
  >(AgencyJobsForAgencyOrganizationPaginatedDocument, options);
}
export type AgencyJobsForAgencyOrganizationPaginatedQueryHookResult = ReturnType<
  typeof useAgencyJobsForAgencyOrganizationPaginatedQuery
>;
export type AgencyJobsForAgencyOrganizationPaginatedLazyQueryHookResult = ReturnType<
  typeof useAgencyJobsForAgencyOrganizationPaginatedLazyQuery
>;
export type AgencyJobsForAgencyOrganizationPaginatedQueryResult = Apollo.QueryResult<
  AgencyJobsForAgencyOrganizationPaginatedQuery,
  AgencyJobsForAgencyOrganizationPaginatedQueryVariables
>;
export function refetchAgencyJobsForAgencyOrganizationPaginatedQuery(
  variables: AgencyJobsForAgencyOrganizationPaginatedQueryVariables,
) {
  return { query: AgencyJobsForAgencyOrganizationPaginatedDocument, variables: variables };
}
export const AgencyJobsForAgencyOrganizationMinimalDocument = gql`
  query AgencyJobsForAgencyOrganizationMinimal($agencyOrganizationId: String!) {
    agencyJobsForAgencyOrganization(agencyOrganizationId: $agencyOrganizationId) {
      id
      agencyJobStatusType
    }
  }
`;
export type AgencyJobsForAgencyOrganizationMinimalProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables
  >;
} & TChildProps;
export function withAgencyJobsForAgencyOrganizationMinimal<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables,
    AgencyJobsForAgencyOrganizationMinimalProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables,
    AgencyJobsForAgencyOrganizationMinimalProps<TChildProps, TDataName>
  >(AgencyJobsForAgencyOrganizationMinimalDocument, {
    alias: 'agencyJobsForAgencyOrganizationMinimal',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobsForAgencyOrganizationMinimalQuery__
 *
 * To run a query within a React component, call `useAgencyJobsForAgencyOrganizationMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobsForAgencyOrganizationMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobsForAgencyOrganizationMinimalQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *   },
 * });
 */
export function useAgencyJobsForAgencyOrganizationMinimalQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables
  >(AgencyJobsForAgencyOrganizationMinimalDocument, options);
}
export function useAgencyJobsForAgencyOrganizationMinimalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobsForAgencyOrganizationMinimalQuery,
    AgencyJobsForAgencyOrganizationMinimalQueryVariables
  >(AgencyJobsForAgencyOrganizationMinimalDocument, options);
}
export type AgencyJobsForAgencyOrganizationMinimalQueryHookResult = ReturnType<
  typeof useAgencyJobsForAgencyOrganizationMinimalQuery
>;
export type AgencyJobsForAgencyOrganizationMinimalLazyQueryHookResult = ReturnType<
  typeof useAgencyJobsForAgencyOrganizationMinimalLazyQuery
>;
export type AgencyJobsForAgencyOrganizationMinimalQueryResult = Apollo.QueryResult<
  AgencyJobsForAgencyOrganizationMinimalQuery,
  AgencyJobsForAgencyOrganizationMinimalQueryVariables
>;
export function refetchAgencyJobsForAgencyOrganizationMinimalQuery(
  variables: AgencyJobsForAgencyOrganizationMinimalQueryVariables,
) {
  return { query: AgencyJobsForAgencyOrganizationMinimalDocument, variables: variables };
}
export const AgencyJobsForAgencyOrganizationDocument = gql`
  query AgencyJobsForAgencyOrganization($agencyOrganizationId: String!) {
    agencyJobsForAgencyOrganization(agencyOrganizationId: $agencyOrganizationId) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      hasIncident
      agencyJobStatusType
      internalNotes
      startAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
      }
      job {
        id
        priojetJobIdentifier
        pickupTime
        pickupAddressDescription
        dropOffTime
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      courierJobs {
        id
        relationshipType
      }
    }
  }
`;
export type AgencyJobsForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables
  >;
} & TChildProps;
export function withAgencyJobsForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables,
    AgencyJobsForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables,
    AgencyJobsForAgencyOrganizationProps<TChildProps, TDataName>
  >(AgencyJobsForAgencyOrganizationDocument, {
    alias: 'agencyJobsForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobsForAgencyOrganizationQuery__
 *
 * To run a query within a React component, call `useAgencyJobsForAgencyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobsForAgencyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobsForAgencyOrganizationQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *   },
 * });
 */
export function useAgencyJobsForAgencyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables
  >(AgencyJobsForAgencyOrganizationDocument, options);
}
export function useAgencyJobsForAgencyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobsForAgencyOrganizationQuery,
    AgencyJobsForAgencyOrganizationQueryVariables
  >(AgencyJobsForAgencyOrganizationDocument, options);
}
export type AgencyJobsForAgencyOrganizationQueryHookResult = ReturnType<
  typeof useAgencyJobsForAgencyOrganizationQuery
>;
export type AgencyJobsForAgencyOrganizationLazyQueryHookResult = ReturnType<
  typeof useAgencyJobsForAgencyOrganizationLazyQuery
>;
export type AgencyJobsForAgencyOrganizationQueryResult = Apollo.QueryResult<
  AgencyJobsForAgencyOrganizationQuery,
  AgencyJobsForAgencyOrganizationQueryVariables
>;
export function refetchAgencyJobsForAgencyOrganizationQuery(
  variables: AgencyJobsForAgencyOrganizationQueryVariables,
) {
  return { query: AgencyJobsForAgencyOrganizationDocument, variables: variables };
}
export const CourierJobsForAgencyJobDocument = gql`
  query CourierJobsForAgencyJob($agencyJobId: String!) {
    courierJobsForAgencyJob(agencyJobId: $agencyJobId) {
      id
      relationshipType
      courierJobStatusType
      declineReasonType
      fixedRate
      dailyRate
      hotelBudget
      kickOffFee
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      hasIncident
      responsibleCourierOrganization {
        id
        name
        shortName
        legalName
        createdAt
        organizationType
      }
      responsibleCourierUser {
        id
        firstNames
        email
        phone
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        nationalities
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
      agencyJob {
        id
        returnTimeAtStartAirport
        checkInTimeAtStartAirport
        returnTimeAtEndAirport
        checkInTimeAtEndAirport
        externalJobReference
        currency
        isDrivingLicenseRequiredForJob
        isCarRequiredForJob
        jobDetails
        numOfCouriersRequired
        hasIncident
        agencyJobStatusType
        startAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        endAirport {
          id
          placeId
          formattedAddress
          formattedShortAddress
          formattedAirportAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
      courierJobAgencyJobChat {
        id
      }
    }
  }
`;
export type CourierJobsForAgencyJobProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierJobsForAgencyJobQuery,
    CourierJobsForAgencyJobQueryVariables
  >;
} & TChildProps;
export function withCourierJobsForAgencyJob<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierJobsForAgencyJobQuery,
    CourierJobsForAgencyJobQueryVariables,
    CourierJobsForAgencyJobProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierJobsForAgencyJobQuery,
    CourierJobsForAgencyJobQueryVariables,
    CourierJobsForAgencyJobProps<TChildProps, TDataName>
  >(CourierJobsForAgencyJobDocument, {
    alias: 'courierJobsForAgencyJob',
    ...operationOptions,
  });
}

/**
 * __useCourierJobsForAgencyJobQuery__
 *
 * To run a query within a React component, call `useCourierJobsForAgencyJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierJobsForAgencyJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierJobsForAgencyJobQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useCourierJobsForAgencyJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierJobsForAgencyJobQuery,
    CourierJobsForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourierJobsForAgencyJobQuery, CourierJobsForAgencyJobQueryVariables>(
    CourierJobsForAgencyJobDocument,
    options,
  );
}
export function useCourierJobsForAgencyJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierJobsForAgencyJobQuery,
    CourierJobsForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourierJobsForAgencyJobQuery, CourierJobsForAgencyJobQueryVariables>(
    CourierJobsForAgencyJobDocument,
    options,
  );
}
export type CourierJobsForAgencyJobQueryHookResult = ReturnType<
  typeof useCourierJobsForAgencyJobQuery
>;
export type CourierJobsForAgencyJobLazyQueryHookResult = ReturnType<
  typeof useCourierJobsForAgencyJobLazyQuery
>;
export type CourierJobsForAgencyJobQueryResult = Apollo.QueryResult<
  CourierJobsForAgencyJobQuery,
  CourierJobsForAgencyJobQueryVariables
>;
export function refetchCourierJobsForAgencyJobQuery(
  variables: CourierJobsForAgencyJobQueryVariables,
) {
  return { query: CourierJobsForAgencyJobDocument, variables: variables };
}
export const CourierJobsCourierOrganizationUsersForAgencyJobDocument = gql`
  query CourierJobsCourierOrganizationUsersForAgencyJob($agencyJobId: String!) {
    courierJobsForAgencyJob(agencyJobId: $agencyJobId) {
      id
      responsibleCourierOrganization {
        id
        organizationUsers {
          id
        }
      }
    }
  }
`;
export type CourierJobsCourierOrganizationUsersForAgencyJobProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables
  >;
} & TChildProps;
export function withCourierJobsCourierOrganizationUsersForAgencyJob<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables,
    CourierJobsCourierOrganizationUsersForAgencyJobProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables,
    CourierJobsCourierOrganizationUsersForAgencyJobProps<TChildProps, TDataName>
  >(CourierJobsCourierOrganizationUsersForAgencyJobDocument, {
    alias: 'courierJobsCourierOrganizationUsersForAgencyJob',
    ...operationOptions,
  });
}

/**
 * __useCourierJobsCourierOrganizationUsersForAgencyJobQuery__
 *
 * To run a query within a React component, call `useCourierJobsCourierOrganizationUsersForAgencyJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierJobsCourierOrganizationUsersForAgencyJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierJobsCourierOrganizationUsersForAgencyJobQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useCourierJobsCourierOrganizationUsersForAgencyJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables
  >(CourierJobsCourierOrganizationUsersForAgencyJobDocument, options);
}
export function useCourierJobsCourierOrganizationUsersForAgencyJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourierJobsCourierOrganizationUsersForAgencyJobQuery,
    CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables
  >(CourierJobsCourierOrganizationUsersForAgencyJobDocument, options);
}
export type CourierJobsCourierOrganizationUsersForAgencyJobQueryHookResult = ReturnType<
  typeof useCourierJobsCourierOrganizationUsersForAgencyJobQuery
>;
export type CourierJobsCourierOrganizationUsersForAgencyJobLazyQueryHookResult = ReturnType<
  typeof useCourierJobsCourierOrganizationUsersForAgencyJobLazyQuery
>;
export type CourierJobsCourierOrganizationUsersForAgencyJobQueryResult = Apollo.QueryResult<
  CourierJobsCourierOrganizationUsersForAgencyJobQuery,
  CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables
>;
export function refetchCourierJobsCourierOrganizationUsersForAgencyJobQuery(
  variables: CourierJobsCourierOrganizationUsersForAgencyJobQueryVariables,
) {
  return { query: CourierJobsCourierOrganizationUsersForAgencyJobDocument, variables: variables };
}
export const AgencyJobForAgencyOrganizationDocument = gql`
  query AgencyJobForAgencyOrganization($agencyJobId: String!) {
    agencyJobForAgencyOrganization(agencyJobId: $agencyJobId) {
      id
      returnTimeAtStartAirport
      checkInTimeAtStartAirport
      returnTimeAtEndAirport
      checkInTimeAtEndAirport
      externalJobReference
      comment
      fixedRate
      dailyRate
      kickOffFee
      hotelBudget
      currency
      isAccommodationBookedByCourier
      isAccommodationPaidByCourier
      isAdditionalCostPaidByCourier
      additionalCostInformation
      isDrivingLicenseRequiredForJob
      isCarRequiredForJob
      jobDetails
      numOfCouriersRequired
      hasIncident
      agencyJobStatusType
      internalNotes
      startAirport {
        id
        placeId
        formattedAddress
        formattedAirportAddress
        formattedShortAddress
        timeZoneIdentifier
        timeZoneRawOffset
        types
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      responsibleAgencyOrganizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
          firstNames
          lastName
          email
        }
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedShortAddress
        formattedAirportAddress
        timeZoneIdentifier
        timeZoneRawOffset
        types
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      job {
        id
        priojetJobIdentifier
        typeOfDelivery
        jobType
        pickupTime
        pickupAddressDescription
        dropOffTime
        dropOffAddressDescription
        numOfItems
        weightOfItems
        descriptionOfItems
        description
        transportedGoodsContentType
        pickupLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          types
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
        dropOffLocation {
          id
          placeId
          formattedAddress
          formattedShortAddress
          timeZoneIdentifier
          timeZoneRawOffset
          types
          locationGeometry {
            location {
              lat
              lng
            }
          }
        }
      }
      clientJob {
        id
        organizationName
        organizationLegalName
        organizationPhone
        organizationEmail
        externalJobReference
        comment
        fixedRate
        currency
        hasIncident
      }
      courierJobs {
        id
        relationshipType
        courierJobStatusType
        declineReasonType
        fixedRate
        dailyRate
        hotelBudget
        kickOffFee
        isAccommodationBookedByCourier
        isAccommodationPaidByCourier
        isAdditionalCostPaidByCourier
        additionalCostInformation
        hasIncident
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        courierJobAgencyJobChat {
          id
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
          sex
          dateOfBirth
          placeOfBirth
          countryOfBirth
          language
          nationalities
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
      }
    }
  }
`;
export type AgencyJobForAgencyOrganizationProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables
  >;
} & TChildProps;
export function withAgencyJobForAgencyOrganization<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables,
    AgencyJobForAgencyOrganizationProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables,
    AgencyJobForAgencyOrganizationProps<TChildProps, TDataName>
  >(AgencyJobForAgencyOrganizationDocument, {
    alias: 'agencyJobForAgencyOrganization',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobForAgencyOrganizationQuery__
 *
 * To run a query within a React component, call `useAgencyJobForAgencyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobForAgencyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobForAgencyOrganizationQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAgencyJobForAgencyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables
  >(AgencyJobForAgencyOrganizationDocument, options);
}
export function useAgencyJobForAgencyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobForAgencyOrganizationQuery,
    AgencyJobForAgencyOrganizationQueryVariables
  >(AgencyJobForAgencyOrganizationDocument, options);
}
export type AgencyJobForAgencyOrganizationQueryHookResult = ReturnType<
  typeof useAgencyJobForAgencyOrganizationQuery
>;
export type AgencyJobForAgencyOrganizationLazyQueryHookResult = ReturnType<
  typeof useAgencyJobForAgencyOrganizationLazyQuery
>;
export type AgencyJobForAgencyOrganizationQueryResult = Apollo.QueryResult<
  AgencyJobForAgencyOrganizationQuery,
  AgencyJobForAgencyOrganizationQueryVariables
>;
export function refetchAgencyJobForAgencyOrganizationQuery(
  variables: AgencyJobForAgencyOrganizationQueryVariables,
) {
  return { query: AgencyJobForAgencyOrganizationDocument, variables: variables };
}
export const AgencyJobForAgencyOrganizationForPlanningDocument = gql`
  query AgencyJobForAgencyOrganizationForPlanning($agencyJobId: String!) {
    agencyJobForAgencyOrganization(agencyJobId: $agencyJobId) {
      id
      externalJobReference
      startAirport {
        id
        placeId
        formattedAddress
        formattedAirportAddress
        formattedShortAddress
        iataCode
      }
      endAirport {
        id
        placeId
        formattedAddress
        formattedAirportAddress
        formattedShortAddress
        iataCode
      }
      job {
        id
        priojetJobIdentifier
      }
    }
  }
`;
export type AgencyJobForAgencyOrganizationForPlanningProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables
  >;
} & TChildProps;
export function withAgencyJobForAgencyOrganizationForPlanning<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables,
    AgencyJobForAgencyOrganizationForPlanningProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables,
    AgencyJobForAgencyOrganizationForPlanningProps<TChildProps, TDataName>
  >(AgencyJobForAgencyOrganizationForPlanningDocument, {
    alias: 'agencyJobForAgencyOrganizationForPlanning',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobForAgencyOrganizationForPlanningQuery__
 *
 * To run a query within a React component, call `useAgencyJobForAgencyOrganizationForPlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobForAgencyOrganizationForPlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobForAgencyOrganizationForPlanningQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAgencyJobForAgencyOrganizationForPlanningQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables
  >(AgencyJobForAgencyOrganizationForPlanningDocument, options);
}
export function useAgencyJobForAgencyOrganizationForPlanningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobForAgencyOrganizationForPlanningQuery,
    AgencyJobForAgencyOrganizationForPlanningQueryVariables
  >(AgencyJobForAgencyOrganizationForPlanningDocument, options);
}
export type AgencyJobForAgencyOrganizationForPlanningQueryHookResult = ReturnType<
  typeof useAgencyJobForAgencyOrganizationForPlanningQuery
>;
export type AgencyJobForAgencyOrganizationForPlanningLazyQueryHookResult = ReturnType<
  typeof useAgencyJobForAgencyOrganizationForPlanningLazyQuery
>;
export type AgencyJobForAgencyOrganizationForPlanningQueryResult = Apollo.QueryResult<
  AgencyJobForAgencyOrganizationForPlanningQuery,
  AgencyJobForAgencyOrganizationForPlanningQueryVariables
>;
export function refetchAgencyJobForAgencyOrganizationForPlanningQuery(
  variables: AgencyJobForAgencyOrganizationForPlanningQueryVariables,
) {
  return { query: AgencyJobForAgencyOrganizationForPlanningDocument, variables: variables };
}
export const AgencyJobForAgencyOrganizationInternalNotesDocument = gql`
  query AgencyJobForAgencyOrganizationInternalNotes($agencyJobId: String!) {
    agencyJobForAgencyOrganization(agencyJobId: $agencyJobId) {
      id
      internalNotes
    }
  }
`;
export type AgencyJobForAgencyOrganizationInternalNotesProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables
  >;
} & TChildProps;
export function withAgencyJobForAgencyOrganizationInternalNotes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables,
    AgencyJobForAgencyOrganizationInternalNotesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables,
    AgencyJobForAgencyOrganizationInternalNotesProps<TChildProps, TDataName>
  >(AgencyJobForAgencyOrganizationInternalNotesDocument, {
    alias: 'agencyJobForAgencyOrganizationInternalNotes',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobForAgencyOrganizationInternalNotesQuery__
 *
 * To run a query within a React component, call `useAgencyJobForAgencyOrganizationInternalNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobForAgencyOrganizationInternalNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobForAgencyOrganizationInternalNotesQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAgencyJobForAgencyOrganizationInternalNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables
  >(AgencyJobForAgencyOrganizationInternalNotesDocument, options);
}
export function useAgencyJobForAgencyOrganizationInternalNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobForAgencyOrganizationInternalNotesQuery,
    AgencyJobForAgencyOrganizationInternalNotesQueryVariables
  >(AgencyJobForAgencyOrganizationInternalNotesDocument, options);
}
export type AgencyJobForAgencyOrganizationInternalNotesQueryHookResult = ReturnType<
  typeof useAgencyJobForAgencyOrganizationInternalNotesQuery
>;
export type AgencyJobForAgencyOrganizationInternalNotesLazyQueryHookResult = ReturnType<
  typeof useAgencyJobForAgencyOrganizationInternalNotesLazyQuery
>;
export type AgencyJobForAgencyOrganizationInternalNotesQueryResult = Apollo.QueryResult<
  AgencyJobForAgencyOrganizationInternalNotesQuery,
  AgencyJobForAgencyOrganizationInternalNotesQueryVariables
>;
export function refetchAgencyJobForAgencyOrganizationInternalNotesQuery(
  variables: AgencyJobForAgencyOrganizationInternalNotesQueryVariables,
) {
  return { query: AgencyJobForAgencyOrganizationInternalNotesDocument, variables: variables };
}
export const AgencyCourierJobChatDocument = gql`
  query AgencyCourierJobChat($agencyCourierJobChatInput: AgencyCourierJobChatInput!) {
    agencyCourierJobChat(agencyCourierJobChatInput: $agencyCourierJobChatInput) {
      id
      inactivatedAt
      courierJob {
        id
        relationshipType
        responsibleCourierOrganization {
          id
          name
          legalName
          createdAt
          organizationType
        }
        responsibleCourierUser {
          id
          firstNames
          email
          phone
          lastName
        }
        job {
          id
          priojetJobIdentifier
        }
        agencyJob {
          id
          agencyJobStatusType
          startedByAgencyAt
          agencyJobChat {
            id
          }
          endAirport {
            id
            formattedShortAddress
            formattedAirportAddress
          }
          responsibleAgencyOrganization {
            id
            shortName
            legalName
            organizationType
          }
        }
      }
    }
  }
`;
export type AgencyCourierJobChatProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyCourierJobChatQuery,
    AgencyCourierJobChatQueryVariables
  >;
} & TChildProps;
export function withAgencyCourierJobChat<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyCourierJobChatQuery,
    AgencyCourierJobChatQueryVariables,
    AgencyCourierJobChatProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyCourierJobChatQuery,
    AgencyCourierJobChatQueryVariables,
    AgencyCourierJobChatProps<TChildProps, TDataName>
  >(AgencyCourierJobChatDocument, {
    alias: 'agencyCourierJobChat',
    ...operationOptions,
  });
}

/**
 * __useAgencyCourierJobChatQuery__
 *
 * To run a query within a React component, call `useAgencyCourierJobChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyCourierJobChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyCourierJobChatQuery({
 *   variables: {
 *      agencyCourierJobChatInput: // value for 'agencyCourierJobChatInput'
 *   },
 * });
 */
export function useAgencyCourierJobChatQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyCourierJobChatQuery,
    AgencyCourierJobChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyCourierJobChatQuery, AgencyCourierJobChatQueryVariables>(
    AgencyCourierJobChatDocument,
    options,
  );
}
export function useAgencyCourierJobChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyCourierJobChatQuery,
    AgencyCourierJobChatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyCourierJobChatQuery, AgencyCourierJobChatQueryVariables>(
    AgencyCourierJobChatDocument,
    options,
  );
}
export type AgencyCourierJobChatQueryHookResult = ReturnType<typeof useAgencyCourierJobChatQuery>;
export type AgencyCourierJobChatLazyQueryHookResult = ReturnType<
  typeof useAgencyCourierJobChatLazyQuery
>;
export type AgencyCourierJobChatQueryResult = Apollo.QueryResult<
  AgencyCourierJobChatQuery,
  AgencyCourierJobChatQueryVariables
>;
export function refetchAgencyCourierJobChatQuery(variables: AgencyCourierJobChatQueryVariables) {
  return { query: AgencyCourierJobChatDocument, variables: variables };
}
export const AgencyJobChatDocument = gql`
  query AgencyJobChat($agencyJobChatInput: AgencyJobChatInput!) {
    agencyJobChat(agencyJobChatInput: $agencyJobChatInput) {
      id
      inactivatedAt
      agencyJob {
        id
        agencyJobStatusType
        externalJobReference
        courierJobs {
          id
          relationshipType
          responsibleCourierUser {
            id
          }
        }
        startAirport {
          id
          iataCode
        }
        endAirport {
          id
          iataCode
        }
        responsibleAgencyOrganization {
          id
          shortName
          legalName
          organizationType
        }
        job {
          id
          priojetJobIdentifier
        }
      }
    }
  }
`;
export type AgencyJobChatProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<AgencyJobChatQuery, AgencyJobChatQueryVariables>;
} & TChildProps;
export function withAgencyJobChat<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobChatQuery,
    AgencyJobChatQueryVariables,
    AgencyJobChatProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobChatQuery,
    AgencyJobChatQueryVariables,
    AgencyJobChatProps<TChildProps, TDataName>
  >(AgencyJobChatDocument, {
    alias: 'agencyJobChat',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobChatQuery__
 *
 * To run a query within a React component, call `useAgencyJobChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobChatQuery({
 *   variables: {
 *      agencyJobChatInput: // value for 'agencyJobChatInput'
 *   },
 * });
 */
export function useAgencyJobChatQuery(
  baseOptions: Apollo.QueryHookOptions<AgencyJobChatQuery, AgencyJobChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AgencyJobChatQuery, AgencyJobChatQueryVariables>(
    AgencyJobChatDocument,
    options,
  );
}
export function useAgencyJobChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AgencyJobChatQuery, AgencyJobChatQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AgencyJobChatQuery, AgencyJobChatQueryVariables>(
    AgencyJobChatDocument,
    options,
  );
}
export type AgencyJobChatQueryHookResult = ReturnType<typeof useAgencyJobChatQuery>;
export type AgencyJobChatLazyQueryHookResult = ReturnType<typeof useAgencyJobChatLazyQuery>;
export type AgencyJobChatQueryResult = Apollo.QueryResult<
  AgencyJobChatQuery,
  AgencyJobChatQueryVariables
>;
export function refetchAgencyJobChatQuery(variables: AgencyJobChatQueryVariables) {
  return { query: AgencyJobChatDocument, variables: variables };
}
export const ChatMessagesByChatIdDocument = gql`
  query ChatMessagesByChatId(
    $chatMessagesByChatIdInput: ChatMessagesByChatIdInput!
    $pagination: PaginationArgs
    $orderBy: OrderArgs
  ) {
    chatMessagesByChatId(
      chatMessagesByChatIdInput: $chatMessagesByChatIdInput
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          message
          messageType
          chatFile {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
          sendingUser {
            id
            firstNames
            lastName
            profilePicture {
              sasUrl
              cacheKey
              cacheTTL
              sasToken {
                version
                signature
                expiresOn
                permissions
                resource
              }
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          createdAt
          updatedAt
        }
        cursor
      }
      totalCount
    }
  }
`;
export type ChatMessagesByChatIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ChatMessagesByChatIdQuery,
    ChatMessagesByChatIdQueryVariables
  >;
} & TChildProps;
export function withChatMessagesByChatId<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChatMessagesByChatIdQuery,
    ChatMessagesByChatIdQueryVariables,
    ChatMessagesByChatIdProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChatMessagesByChatIdQuery,
    ChatMessagesByChatIdQueryVariables,
    ChatMessagesByChatIdProps<TChildProps, TDataName>
  >(ChatMessagesByChatIdDocument, {
    alias: 'chatMessagesByChatId',
    ...operationOptions,
  });
}

/**
 * __useChatMessagesByChatIdQuery__
 *
 * To run a query within a React component, call `useChatMessagesByChatIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesByChatIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesByChatIdQuery({
 *   variables: {
 *      chatMessagesByChatIdInput: // value for 'chatMessagesByChatIdInput'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useChatMessagesByChatIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatMessagesByChatIdQuery,
    ChatMessagesByChatIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatMessagesByChatIdQuery, ChatMessagesByChatIdQueryVariables>(
    ChatMessagesByChatIdDocument,
    options,
  );
}
export function useChatMessagesByChatIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatMessagesByChatIdQuery,
    ChatMessagesByChatIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatMessagesByChatIdQuery, ChatMessagesByChatIdQueryVariables>(
    ChatMessagesByChatIdDocument,
    options,
  );
}
export type ChatMessagesByChatIdQueryHookResult = ReturnType<typeof useChatMessagesByChatIdQuery>;
export type ChatMessagesByChatIdLazyQueryHookResult = ReturnType<
  typeof useChatMessagesByChatIdLazyQuery
>;
export type ChatMessagesByChatIdQueryResult = Apollo.QueryResult<
  ChatMessagesByChatIdQuery,
  ChatMessagesByChatIdQueryVariables
>;
export function refetchChatMessagesByChatIdQuery(variables: ChatMessagesByChatIdQueryVariables) {
  return { query: ChatMessagesByChatIdDocument, variables: variables };
}
export const ChatMessageEdgeDocument = gql`
  query ChatMessageEdge($chatMessageId: String!) {
    chatMessageEdge(chatMessageId: $chatMessageId) {
      node {
        id
        message
        messageType
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
export type ChatMessageEdgeProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<ChatMessageEdgeQuery, ChatMessageEdgeQueryVariables>;
} & TChildProps;
export function withChatMessageEdge<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChatMessageEdgeQuery,
    ChatMessageEdgeQueryVariables,
    ChatMessageEdgeProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChatMessageEdgeQuery,
    ChatMessageEdgeQueryVariables,
    ChatMessageEdgeProps<TChildProps, TDataName>
  >(ChatMessageEdgeDocument, {
    alias: 'chatMessageEdge',
    ...operationOptions,
  });
}

/**
 * __useChatMessageEdgeQuery__
 *
 * To run a query within a React component, call `useChatMessageEdgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageEdgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageEdgeQuery({
 *   variables: {
 *      chatMessageId: // value for 'chatMessageId'
 *   },
 * });
 */
export function useChatMessageEdgeQuery(
  baseOptions: Apollo.QueryHookOptions<ChatMessageEdgeQuery, ChatMessageEdgeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatMessageEdgeQuery, ChatMessageEdgeQueryVariables>(
    ChatMessageEdgeDocument,
    options,
  );
}
export function useChatMessageEdgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatMessageEdgeQuery, ChatMessageEdgeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatMessageEdgeQuery, ChatMessageEdgeQueryVariables>(
    ChatMessageEdgeDocument,
    options,
  );
}
export type ChatMessageEdgeQueryHookResult = ReturnType<typeof useChatMessageEdgeQuery>;
export type ChatMessageEdgeLazyQueryHookResult = ReturnType<typeof useChatMessageEdgeLazyQuery>;
export type ChatMessageEdgeQueryResult = Apollo.QueryResult<
  ChatMessageEdgeQuery,
  ChatMessageEdgeQueryVariables
>;
export function refetchChatMessageEdgeQuery(variables: ChatMessageEdgeQueryVariables) {
  return { query: ChatMessageEdgeDocument, variables: variables };
}
export const OrganizationProfileDocument = gql`
  query OrganizationProfile($id: String!) {
    organization(id: $id) {
      id
      name
      shortName
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      invoiceEmail
      socialProfiles {
        platform
        type
        value
      }
      headquartersAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      invoicingAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
        addressName
        apartmentOrSuite
        streetName
        houseNumber
        city
        postalCode
        state
        country
      }
      registrationAddressLocation {
        id
        placeId
        formattedAddress
        formattedShortAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      invoiceEmail
      logo {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type OrganizationProfileProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationProfileQuery,
    OrganizationProfileQueryVariables
  >;
} & TChildProps;
export function withOrganizationProfile<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationProfileQuery,
    OrganizationProfileQueryVariables,
    OrganizationProfileProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationProfileQuery,
    OrganizationProfileQueryVariables,
    OrganizationProfileProps<TChildProps, TDataName>
  >(OrganizationProfileDocument, {
    alias: 'organizationProfile',
    ...operationOptions,
  });
}

/**
 * __useOrganizationProfileQuery__
 *
 * To run a query within a React component, call `useOrganizationProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationProfileQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationProfileQuery, OrganizationProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationProfileQuery, OrganizationProfileQueryVariables>(
    OrganizationProfileDocument,
    options,
  );
}
export function useOrganizationProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationProfileQuery,
    OrganizationProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationProfileQuery, OrganizationProfileQueryVariables>(
    OrganizationProfileDocument,
    options,
  );
}
export type OrganizationProfileQueryHookResult = ReturnType<typeof useOrganizationProfileQuery>;
export type OrganizationProfileLazyQueryHookResult = ReturnType<
  typeof useOrganizationProfileLazyQuery
>;
export type OrganizationProfileQueryResult = Apollo.QueryResult<
  OrganizationProfileQuery,
  OrganizationProfileQueryVariables
>;
export function refetchOrganizationProfileQuery(variables: OrganizationProfileQueryVariables) {
  return { query: OrganizationProfileDocument, variables: variables };
}
export const OrganizationOrganizationUsersDocument = gql`
  query OrganizationOrganizationUsers($id: String!) {
    organization(id: $id) {
      id
      name
      shortName
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      organizationUsers {
        id
        roleType
        createdAt
        user {
          id
          firstNames
          lastName
          email
          phone
          createdAt
        }
      }
    }
  }
`;
export type OrganizationOrganizationUsersProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >;
} & TChildProps;
export function withOrganizationOrganizationUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables,
    OrganizationOrganizationUsersProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables,
    OrganizationOrganizationUsersProps<TChildProps, TDataName>
  >(OrganizationOrganizationUsersDocument, {
    alias: 'organizationOrganizationUsers',
    ...operationOptions,
  });
}

/**
 * __useOrganizationOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useOrganizationOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationOrganizationUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >(OrganizationOrganizationUsersDocument, options);
}
export function useOrganizationOrganizationUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationOrganizationUsersQuery,
    OrganizationOrganizationUsersQueryVariables
  >(OrganizationOrganizationUsersDocument, options);
}
export type OrganizationOrganizationUsersQueryHookResult = ReturnType<
  typeof useOrganizationOrganizationUsersQuery
>;
export type OrganizationOrganizationUsersLazyQueryHookResult = ReturnType<
  typeof useOrganizationOrganizationUsersLazyQuery
>;
export type OrganizationOrganizationUsersQueryResult = Apollo.QueryResult<
  OrganizationOrganizationUsersQuery,
  OrganizationOrganizationUsersQueryVariables
>;
export function refetchOrganizationOrganizationUsersQuery(
  variables: OrganizationOrganizationUsersQueryVariables,
) {
  return { query: OrganizationOrganizationUsersDocument, variables: variables };
}
export const UserByIdDocument = gql`
  query UserById($id: String!) {
    user(id: $id) {
      id
      firstNames
      lastName
      profilePicture {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
    }
  }
`;
export type UserByIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<UserByIdQuery, UserByIdQueryVariables>;
} & TChildProps;
export function withUserById<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserByIdProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserByIdProps<TChildProps, TDataName>
  >(UserByIdDocument, {
    alias: 'userById',
    ...operationOptions,
  });
}

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export function refetchUserByIdQuery(variables: UserByIdQueryVariables) {
  return { query: UserByIdDocument, variables: variables };
}
export const MinSupportedAppVersionDocument = gql`
  query MinSupportedAppVersion {
    minSupportedAppVersion {
      currentAppVersion
      minSupportedAppVersion
    }
  }
`;
export type MinSupportedAppVersionProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    MinSupportedAppVersionQuery,
    MinSupportedAppVersionQueryVariables
  >;
} & TChildProps;
export function withMinSupportedAppVersion<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MinSupportedAppVersionQuery,
    MinSupportedAppVersionQueryVariables,
    MinSupportedAppVersionProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MinSupportedAppVersionQuery,
    MinSupportedAppVersionQueryVariables,
    MinSupportedAppVersionProps<TChildProps, TDataName>
  >(MinSupportedAppVersionDocument, {
    alias: 'minSupportedAppVersion',
    ...operationOptions,
  });
}

/**
 * __useMinSupportedAppVersionQuery__
 *
 * To run a query within a React component, call `useMinSupportedAppVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinSupportedAppVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinSupportedAppVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useMinSupportedAppVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MinSupportedAppVersionQuery,
    MinSupportedAppVersionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MinSupportedAppVersionQuery, MinSupportedAppVersionQueryVariables>(
    MinSupportedAppVersionDocument,
    options,
  );
}
export function useMinSupportedAppVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MinSupportedAppVersionQuery,
    MinSupportedAppVersionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MinSupportedAppVersionQuery, MinSupportedAppVersionQueryVariables>(
    MinSupportedAppVersionDocument,
    options,
  );
}
export type MinSupportedAppVersionQueryHookResult = ReturnType<
  typeof useMinSupportedAppVersionQuery
>;
export type MinSupportedAppVersionLazyQueryHookResult = ReturnType<
  typeof useMinSupportedAppVersionLazyQuery
>;
export type MinSupportedAppVersionQueryResult = Apollo.QueryResult<
  MinSupportedAppVersionQuery,
  MinSupportedAppVersionQueryVariables
>;
export function refetchMinSupportedAppVersionQuery(
  variables?: MinSupportedAppVersionQueryVariables,
) {
  return { query: MinSupportedAppVersionDocument, variables: variables };
}
export const DeletedAccountsPriojetOnlyDocument = gql`
  query DeletedAccountsPriojetOnly {
    deletedAccountsPriojetOnly {
      id
      firstNames
      lastName
      email
      dateOfBirth
      profilePicture {
        sasUrl
      }
      inactivatedAt
      organizationUsers {
        id
        user {
          id
        }
        organization {
          id
          organizationType
          name
          phone
          inactivatedAt
          logo {
            sasUrl
          }
          organizationUsers {
            id
            user {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeletedAccountsPriojetOnlyProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    DeletedAccountsPriojetOnlyQuery,
    DeletedAccountsPriojetOnlyQueryVariables
  >;
} & TChildProps;
export function withDeletedAccountsPriojetOnly<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeletedAccountsPriojetOnlyQuery,
    DeletedAccountsPriojetOnlyQueryVariables,
    DeletedAccountsPriojetOnlyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DeletedAccountsPriojetOnlyQuery,
    DeletedAccountsPriojetOnlyQueryVariables,
    DeletedAccountsPriojetOnlyProps<TChildProps, TDataName>
  >(DeletedAccountsPriojetOnlyDocument, {
    alias: 'deletedAccountsPriojetOnly',
    ...operationOptions,
  });
}

/**
 * __useDeletedAccountsPriojetOnlyQuery__
 *
 * To run a query within a React component, call `useDeletedAccountsPriojetOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedAccountsPriojetOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedAccountsPriojetOnlyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeletedAccountsPriojetOnlyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeletedAccountsPriojetOnlyQuery,
    DeletedAccountsPriojetOnlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeletedAccountsPriojetOnlyQuery, DeletedAccountsPriojetOnlyQueryVariables>(
    DeletedAccountsPriojetOnlyDocument,
    options,
  );
}
export function useDeletedAccountsPriojetOnlyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeletedAccountsPriojetOnlyQuery,
    DeletedAccountsPriojetOnlyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DeletedAccountsPriojetOnlyQuery,
    DeletedAccountsPriojetOnlyQueryVariables
  >(DeletedAccountsPriojetOnlyDocument, options);
}
export type DeletedAccountsPriojetOnlyQueryHookResult = ReturnType<
  typeof useDeletedAccountsPriojetOnlyQuery
>;
export type DeletedAccountsPriojetOnlyLazyQueryHookResult = ReturnType<
  typeof useDeletedAccountsPriojetOnlyLazyQuery
>;
export type DeletedAccountsPriojetOnlyQueryResult = Apollo.QueryResult<
  DeletedAccountsPriojetOnlyQuery,
  DeletedAccountsPriojetOnlyQueryVariables
>;
export function refetchDeletedAccountsPriojetOnlyQuery(
  variables?: DeletedAccountsPriojetOnlyQueryVariables,
) {
  return { query: DeletedAccountsPriojetOnlyDocument, variables: variables };
}
export const OrganizationUserByIdDocument = gql`
  query organizationUserById($organizationUserId: String!) {
    organizationUserById(organizationUserId: $organizationUserId) {
      id
      roleType
      user {
        firstNames
        lastName
        sex
        dateOfBirth
        placeOfBirth
        countryOfBirth
        language
        email
        phone
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
        }
      }
      organization {
        id
        organizationUsers {
          id
          roleType
        }
      }
    }
  }
`;
export type OrganizationUserByIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >;
} & TChildProps;
export function withOrganizationUserById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables,
    OrganizationUserByIdProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables,
    OrganizationUserByIdProps<TChildProps, TDataName>
  >(OrganizationUserByIdDocument, {
    alias: 'organizationUserById',
    ...operationOptions,
  });
}

/**
 * __useOrganizationUserByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUserByIdQuery({
 *   variables: {
 *      organizationUserId: // value for 'organizationUserId'
 *   },
 * });
 */
export function useOrganizationUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationUserByIdQuery, OrganizationUserByIdQueryVariables>(
    OrganizationUserByIdDocument,
    options,
  );
}
export function useOrganizationUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationUserByIdQuery,
    OrganizationUserByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationUserByIdQuery, OrganizationUserByIdQueryVariables>(
    OrganizationUserByIdDocument,
    options,
  );
}
export type OrganizationUserByIdQueryHookResult = ReturnType<typeof useOrganizationUserByIdQuery>;
export type OrganizationUserByIdLazyQueryHookResult = ReturnType<
  typeof useOrganizationUserByIdLazyQuery
>;
export type OrganizationUserByIdQueryResult = Apollo.QueryResult<
  OrganizationUserByIdQuery,
  OrganizationUserByIdQueryVariables
>;
export function refetchOrganizationUserByIdQuery(variables: OrganizationUserByIdQueryVariables) {
  return { query: OrganizationUserByIdDocument, variables: variables };
}
export const SubscriptionPlansAgencyDocument = gql`
  query subscriptionPlansAgency {
    subscriptionPlansAgency {
      month {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
      year {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
    }
  }
`;
export type SubscriptionPlansAgencyProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SubscriptionPlansAgencyQuery,
    SubscriptionPlansAgencyQueryVariables
  >;
} & TChildProps;
export function withSubscriptionPlansAgency<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscriptionPlansAgencyQuery,
    SubscriptionPlansAgencyQueryVariables,
    SubscriptionPlansAgencyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SubscriptionPlansAgencyQuery,
    SubscriptionPlansAgencyQueryVariables,
    SubscriptionPlansAgencyProps<TChildProps, TDataName>
  >(SubscriptionPlansAgencyDocument, {
    alias: 'subscriptionPlansAgency',
    ...operationOptions,
  });
}

/**
 * __useSubscriptionPlansAgencyQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansAgencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansAgencyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionPlansAgencyQuery,
    SubscriptionPlansAgencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionPlansAgencyQuery, SubscriptionPlansAgencyQueryVariables>(
    SubscriptionPlansAgencyDocument,
    options,
  );
}
export function useSubscriptionPlansAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionPlansAgencyQuery,
    SubscriptionPlansAgencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionPlansAgencyQuery, SubscriptionPlansAgencyQueryVariables>(
    SubscriptionPlansAgencyDocument,
    options,
  );
}
export type SubscriptionPlansAgencyQueryHookResult = ReturnType<
  typeof useSubscriptionPlansAgencyQuery
>;
export type SubscriptionPlansAgencyLazyQueryHookResult = ReturnType<
  typeof useSubscriptionPlansAgencyLazyQuery
>;
export type SubscriptionPlansAgencyQueryResult = Apollo.QueryResult<
  SubscriptionPlansAgencyQuery,
  SubscriptionPlansAgencyQueryVariables
>;
export function refetchSubscriptionPlansAgencyQuery(
  variables?: SubscriptionPlansAgencyQueryVariables,
) {
  return { query: SubscriptionPlansAgencyDocument, variables: variables };
}
export const SubscriptionPlansCourierDocument = gql`
  query subscriptionPlansCourier {
    subscriptionPlansCourier {
      month {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
      year {
        description
        featureDescription
        name
        planId
        prices
        recurringInterval
        trialPeriod
        isMain
        subscriptionPlanType
      }
    }
  }
`;
export type SubscriptionPlansCourierProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    SubscriptionPlansCourierQuery,
    SubscriptionPlansCourierQueryVariables
  >;
} & TChildProps;
export function withSubscriptionPlansCourier<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SubscriptionPlansCourierQuery,
    SubscriptionPlansCourierQueryVariables,
    SubscriptionPlansCourierProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SubscriptionPlansCourierQuery,
    SubscriptionPlansCourierQueryVariables,
    SubscriptionPlansCourierProps<TChildProps, TDataName>
  >(SubscriptionPlansCourierDocument, {
    alias: 'subscriptionPlansCourier',
    ...operationOptions,
  });
}

/**
 * __useSubscriptionPlansCourierQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansCourierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansCourierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansCourierQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansCourierQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubscriptionPlansCourierQuery,
    SubscriptionPlansCourierQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubscriptionPlansCourierQuery, SubscriptionPlansCourierQueryVariables>(
    SubscriptionPlansCourierDocument,
    options,
  );
}
export function useSubscriptionPlansCourierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubscriptionPlansCourierQuery,
    SubscriptionPlansCourierQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubscriptionPlansCourierQuery, SubscriptionPlansCourierQueryVariables>(
    SubscriptionPlansCourierDocument,
    options,
  );
}
export type SubscriptionPlansCourierQueryHookResult = ReturnType<
  typeof useSubscriptionPlansCourierQuery
>;
export type SubscriptionPlansCourierLazyQueryHookResult = ReturnType<
  typeof useSubscriptionPlansCourierLazyQuery
>;
export type SubscriptionPlansCourierQueryResult = Apollo.QueryResult<
  SubscriptionPlansCourierQuery,
  SubscriptionPlansCourierQueryVariables
>;
export function refetchSubscriptionPlansCourierQuery(
  variables?: SubscriptionPlansCourierQueryVariables,
) {
  return { query: SubscriptionPlansCourierDocument, variables: variables };
}
export const OrganizationSubscriptionPlansDocument = gql`
  query OrganizationSubscriptionPlans($id: String!) {
    organization(id: $id) {
      id
      name
      shortName
      legalName
      organizationType
      description
      email
      legalEntityType
      phone
      vatId
      registrationNumber
      organizationSubscriptionPlan {
        id
        isActive
        startTime
        endTime
        isMain
        recurringInterval
        status
        previousPaymentSubscriptionId
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
      organizationSubscriptionPlans {
        id
        isActive
        startTime
        endTime
        isMain
        recurringInterval
        status
        prices {
          currency
          recurringInterval
          value
        }
        subscriptionPlan {
          id
          planName {
            key
            defaultValue
          }
          value
          subscriptionFeatures {
            id
            name
            count
            value
          }
        }
      }
    }
  }
`;
export type OrganizationSubscriptionPlansProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables
  >;
} & TChildProps;
export function withOrganizationSubscriptionPlans<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables,
    OrganizationSubscriptionPlansProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables,
    OrganizationSubscriptionPlansProps<TChildProps, TDataName>
  >(OrganizationSubscriptionPlansDocument, {
    alias: 'organizationSubscriptionPlans',
    ...operationOptions,
  });
}

/**
 * __useOrganizationSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionPlansQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSubscriptionPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables
  >(OrganizationSubscriptionPlansDocument, options);
}
export function useOrganizationSubscriptionPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationSubscriptionPlansQuery,
    OrganizationSubscriptionPlansQueryVariables
  >(OrganizationSubscriptionPlansDocument, options);
}
export type OrganizationSubscriptionPlansQueryHookResult = ReturnType<
  typeof useOrganizationSubscriptionPlansQuery
>;
export type OrganizationSubscriptionPlansLazyQueryHookResult = ReturnType<
  typeof useOrganizationSubscriptionPlansLazyQuery
>;
export type OrganizationSubscriptionPlansQueryResult = Apollo.QueryResult<
  OrganizationSubscriptionPlansQuery,
  OrganizationSubscriptionPlansQueryVariables
>;
export function refetchOrganizationSubscriptionPlansQuery(
  variables: OrganizationSubscriptionPlansQueryVariables,
) {
  return { query: OrganizationSubscriptionPlansDocument, variables: variables };
}
export const AirportLocationListPaginatedDocument = gql`
  query AirportLocationListPaginated(
    $data: AirportLocationSearchInput
    $orderBy: OrderArgs
    $pagination: PaginationArgs
  ) {
    airportLocationListPaginated(data: $data, orderBy: $orderBy, pagination: $pagination) {
      totalCount
      edges {
        node {
          id
          iataCode
          formattedAddress
          placeId
          locationGeometry {
            location {
              lat
              lng
            }
          }
          timeZoneIdentifier
          timeZoneRawOffset
        }
      }
    }
  }
`;
export type AirportLocationListPaginatedProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables
  >;
} & TChildProps;
export function withAirportLocationListPaginated<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables,
    AirportLocationListPaginatedProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables,
    AirportLocationListPaginatedProps<TChildProps, TDataName>
  >(AirportLocationListPaginatedDocument, {
    alias: 'airportLocationListPaginated',
    ...operationOptions,
  });
}

/**
 * __useAirportLocationListPaginatedQuery__
 *
 * To run a query within a React component, call `useAirportLocationListPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirportLocationListPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirportLocationListPaginatedQuery({
 *   variables: {
 *      data: // value for 'data'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAirportLocationListPaginatedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables
  >(AirportLocationListPaginatedDocument, options);
}
export function useAirportLocationListPaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AirportLocationListPaginatedQuery,
    AirportLocationListPaginatedQueryVariables
  >(AirportLocationListPaginatedDocument, options);
}
export type AirportLocationListPaginatedQueryHookResult = ReturnType<
  typeof useAirportLocationListPaginatedQuery
>;
export type AirportLocationListPaginatedLazyQueryHookResult = ReturnType<
  typeof useAirportLocationListPaginatedLazyQuery
>;
export type AirportLocationListPaginatedQueryResult = Apollo.QueryResult<
  AirportLocationListPaginatedQuery,
  AirportLocationListPaginatedQueryVariables
>;
export function refetchAirportLocationListPaginatedQuery(
  variables?: AirportLocationListPaginatedQueryVariables,
) {
  return { query: AirportLocationListPaginatedDocument, variables: variables };
}
export const AirportLocationListDocument = gql`
  query AirportLocationList($data: AirportLocationListInput) {
    airportLocationList(data: $data) {
      id
      iataCode
      formattedAddress
      placeId
      locationGeometry {
        location {
          lat
          lng
        }
      }
      timeZoneIdentifier
      timeZoneRawOffset
    }
  }
`;
export type AirportLocationListProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AirportLocationListQuery,
    AirportLocationListQueryVariables
  >;
} & TChildProps;
export function withAirportLocationList<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AirportLocationListQuery,
    AirportLocationListQueryVariables,
    AirportLocationListProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AirportLocationListQuery,
    AirportLocationListQueryVariables,
    AirportLocationListProps<TChildProps, TDataName>
  >(AirportLocationListDocument, {
    alias: 'airportLocationList',
    ...operationOptions,
  });
}

/**
 * __useAirportLocationListQuery__
 *
 * To run a query within a React component, call `useAirportLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirportLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirportLocationListQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAirportLocationListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AirportLocationListQuery,
    AirportLocationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AirportLocationListQuery, AirportLocationListQueryVariables>(
    AirportLocationListDocument,
    options,
  );
}
export function useAirportLocationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AirportLocationListQuery,
    AirportLocationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AirportLocationListQuery, AirportLocationListQueryVariables>(
    AirportLocationListDocument,
    options,
  );
}
export type AirportLocationListQueryHookResult = ReturnType<typeof useAirportLocationListQuery>;
export type AirportLocationListLazyQueryHookResult = ReturnType<
  typeof useAirportLocationListLazyQuery
>;
export type AirportLocationListQueryResult = Apollo.QueryResult<
  AirportLocationListQuery,
  AirportLocationListQueryVariables
>;
export function refetchAirportLocationListQuery(variables?: AirportLocationListQueryVariables) {
  return { query: AirportLocationListDocument, variables: variables };
}
export const AirportLocationSearchDocument = gql`
  query AirportLocationSearch($data: AirportLocationSearchInput!) {
    airportLocationSearch(data: $data) {
      id
      iataCode
      formattedAddress
      placeId
      locationGeometry {
        location {
          lat
          lng
        }
      }
      timeZoneIdentifier
      timeZoneRawOffset
    }
  }
`;
export type AirportLocationSearchProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AirportLocationSearchQuery,
    AirportLocationSearchQueryVariables
  >;
} & TChildProps;
export function withAirportLocationSearch<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AirportLocationSearchQuery,
    AirportLocationSearchQueryVariables,
    AirportLocationSearchProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AirportLocationSearchQuery,
    AirportLocationSearchQueryVariables,
    AirportLocationSearchProps<TChildProps, TDataName>
  >(AirportLocationSearchDocument, {
    alias: 'airportLocationSearch',
    ...operationOptions,
  });
}

/**
 * __useAirportLocationSearchQuery__
 *
 * To run a query within a React component, call `useAirportLocationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirportLocationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirportLocationSearchQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAirportLocationSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    AirportLocationSearchQuery,
    AirportLocationSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AirportLocationSearchQuery, AirportLocationSearchQueryVariables>(
    AirportLocationSearchDocument,
    options,
  );
}
export function useAirportLocationSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AirportLocationSearchQuery,
    AirportLocationSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AirportLocationSearchQuery, AirportLocationSearchQueryVariables>(
    AirportLocationSearchDocument,
    options,
  );
}
export type AirportLocationSearchQueryHookResult = ReturnType<typeof useAirportLocationSearchQuery>;
export type AirportLocationSearchLazyQueryHookResult = ReturnType<
  typeof useAirportLocationSearchLazyQuery
>;
export type AirportLocationSearchQueryResult = Apollo.QueryResult<
  AirportLocationSearchQuery,
  AirportLocationSearchQueryVariables
>;
export function refetchAirportLocationSearchQuery(variables: AirportLocationSearchQueryVariables) {
  return { query: AirportLocationSearchDocument, variables: variables };
}
export const AvailabilitiesForConnectedCourierOrganizationsDocument = gql`
  query AvailabilitiesForConnectedCourierOrganizations(
    $agencyOrganizationId: String!
    $filterType: [AvailabilityFilterType!]
  ) {
    availabilitiesForConnectedCourierOrganizations(
      agencyOrganizationId: $agencyOrganizationId
      filterType: $filterType
    ) {
      id
      isAdHocAvailability
      startTime
      endTime
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        timeZoneIdentifier
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        firstNames
        lastName
      }
    }
  }
`;
export type AvailabilitiesForConnectedCourierOrganizationsProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >;
} & TChildProps;
export function withAvailabilitiesForConnectedCourierOrganizations<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables,
    AvailabilitiesForConnectedCourierOrganizationsProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables,
    AvailabilitiesForConnectedCourierOrganizationsProps<TChildProps, TDataName>
  >(AvailabilitiesForConnectedCourierOrganizationsDocument, {
    alias: 'availabilitiesForConnectedCourierOrganizations',
    ...operationOptions,
  });
}

/**
 * __useAvailabilitiesForConnectedCourierOrganizationsQuery__
 *
 * To run a query within a React component, call `useAvailabilitiesForConnectedCourierOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitiesForConnectedCourierOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitiesForConnectedCourierOrganizationsQuery({
 *   variables: {
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useAvailabilitiesForConnectedCourierOrganizationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >(AvailabilitiesForConnectedCourierOrganizationsDocument, options);
}
export function useAvailabilitiesForConnectedCourierOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailabilitiesForConnectedCourierOrganizationsQuery,
    AvailabilitiesForConnectedCourierOrganizationsQueryVariables
  >(AvailabilitiesForConnectedCourierOrganizationsDocument, options);
}
export type AvailabilitiesForConnectedCourierOrganizationsQueryHookResult = ReturnType<
  typeof useAvailabilitiesForConnectedCourierOrganizationsQuery
>;
export type AvailabilitiesForConnectedCourierOrganizationsLazyQueryHookResult = ReturnType<
  typeof useAvailabilitiesForConnectedCourierOrganizationsLazyQuery
>;
export type AvailabilitiesForConnectedCourierOrganizationsQueryResult = Apollo.QueryResult<
  AvailabilitiesForConnectedCourierOrganizationsQuery,
  AvailabilitiesForConnectedCourierOrganizationsQueryVariables
>;
export function refetchAvailabilitiesForConnectedCourierOrganizationsQuery(
  variables: AvailabilitiesForConnectedCourierOrganizationsQueryVariables,
) {
  return { query: AvailabilitiesForConnectedCourierOrganizationsDocument, variables: variables };
}
export const AvailabilityDocument = gql`
  query Availability($availabilityId: String!, $agencyOrganizationId: String) {
    availability(availabilityId: $availabilityId, agencyOrganizationId: $agencyOrganizationId) {
      id
      isAdHocAvailability
      startTime
      endTime
      timeZoneIdentifier
      timeZoneDestinationOffset
      timeZoneRawOffset
      createdAt
      lastLocation {
        id
        formattedAddress
        formattedMinimalAddress
        timeZoneIdentifier
        timeZoneRawOffset
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      user {
        id
        email
        firstNames
        lastName
        profilePicture {
          sasUrl
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        courierUserProfile {
          id
          baseAirports
        }
        organizationUsers {
          id
          organization {
            id
          }
        }
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            label
            order
          }
        }
      }
    }
  }
`;
export type AvailabilityProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<AvailabilityQuery, AvailabilityQueryVariables>;
} & TChildProps;
export function withAvailability<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailabilityQuery,
    AvailabilityQueryVariables,
    AvailabilityProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailabilityQuery,
    AvailabilityQueryVariables,
    AvailabilityProps<TChildProps, TDataName>
  >(AvailabilityDocument, {
    alias: 'availability',
    ...operationOptions,
  });
}

/**
 * __useAvailabilityQuery__
 *
 * To run a query within a React component, call `useAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityQuery({
 *   variables: {
 *      availabilityId: // value for 'availabilityId'
 *      agencyOrganizationId: // value for 'agencyOrganizationId'
 *   },
 * });
 */
export function useAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityQuery, AvailabilityQueryVariables>(
    AvailabilityDocument,
    options,
  );
}
export function useAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityQuery, AvailabilityQueryVariables>(
    AvailabilityDocument,
    options,
  );
}
export type AvailabilityQueryHookResult = ReturnType<typeof useAvailabilityQuery>;
export type AvailabilityLazyQueryHookResult = ReturnType<typeof useAvailabilityLazyQuery>;
export type AvailabilityQueryResult = Apollo.QueryResult<
  AvailabilityQuery,
  AvailabilityQueryVariables
>;
export function refetchAvailabilityQuery(variables: AvailabilityQueryVariables) {
  return { query: AvailabilityDocument, variables: variables };
}
export const OrganizationCourierCategoriesDocument = gql`
  query OrganizationCourierCategories {
    organizationCourierCategories {
      id
      order
      label
    }
  }
`;
export type OrganizationCourierCategoriesProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables
  >;
} & TChildProps;
export function withOrganizationCourierCategories<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables,
    OrganizationCourierCategoriesProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables,
    OrganizationCourierCategoriesProps<TChildProps, TDataName>
  >(OrganizationCourierCategoriesDocument, {
    alias: 'organizationCourierCategories',
    ...operationOptions,
  });
}

/**
 * __useOrganizationCourierCategoriesQuery__
 *
 * To run a query within a React component, call `useOrganizationCourierCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCourierCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationCourierCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationCourierCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables
  >(OrganizationCourierCategoriesDocument, options);
}
export function useOrganizationCourierCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationCourierCategoriesQuery,
    OrganizationCourierCategoriesQueryVariables
  >(OrganizationCourierCategoriesDocument, options);
}
export type OrganizationCourierCategoriesQueryHookResult = ReturnType<
  typeof useOrganizationCourierCategoriesQuery
>;
export type OrganizationCourierCategoriesLazyQueryHookResult = ReturnType<
  typeof useOrganizationCourierCategoriesLazyQuery
>;
export type OrganizationCourierCategoriesQueryResult = Apollo.QueryResult<
  OrganizationCourierCategoriesQuery,
  OrganizationCourierCategoriesQueryVariables
>;
export function refetchOrganizationCourierCategoriesQuery(
  variables?: OrganizationCourierCategoriesQueryVariables,
) {
  return { query: OrganizationCourierCategoriesDocument, variables: variables };
}
export const OrganizationInvitationUploadCsvDocument = gql`
  query organizationInvitationUploadCSV($data: OrganizationInvitationUploadCSVInput!) {
    organizationInvitationUploadCSV(data: $data) {
      contacts {
        firstName
        firstNameError
        lastName
        lastNameError
        fullName
        email
        emailError
        phoneNumber
        phoneNumberError
        status
      }
      organizationInvitationsNotUsed
      organizationInvitationsUsed
    }
  }
`;
export type OrganizationInvitationUploadCsvProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables
  >;
} & TChildProps;
export function withOrganizationInvitationUploadCsv<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables,
    OrganizationInvitationUploadCsvProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables,
    OrganizationInvitationUploadCsvProps<TChildProps, TDataName>
  >(OrganizationInvitationUploadCsvDocument, {
    alias: 'organizationInvitationUploadCsv',
    ...operationOptions,
  });
}

/**
 * __useOrganizationInvitationUploadCsvQuery__
 *
 * To run a query within a React component, call `useOrganizationInvitationUploadCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationInvitationUploadCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationInvitationUploadCsvQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationInvitationUploadCsvQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables
  >(OrganizationInvitationUploadCsvDocument, options);
}
export function useOrganizationInvitationUploadCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationInvitationUploadCsvQuery,
    OrganizationInvitationUploadCsvQueryVariables
  >(OrganizationInvitationUploadCsvDocument, options);
}
export type OrganizationInvitationUploadCsvQueryHookResult = ReturnType<
  typeof useOrganizationInvitationUploadCsvQuery
>;
export type OrganizationInvitationUploadCsvLazyQueryHookResult = ReturnType<
  typeof useOrganizationInvitationUploadCsvLazyQuery
>;
export type OrganizationInvitationUploadCsvQueryResult = Apollo.QueryResult<
  OrganizationInvitationUploadCsvQuery,
  OrganizationInvitationUploadCsvQueryVariables
>;
export function refetchOrganizationInvitationUploadCsvQuery(
  variables: OrganizationInvitationUploadCsvQueryVariables,
) {
  return { query: OrganizationInvitationUploadCsvDocument, variables: variables };
}
export const NotificationSettingCurrentUserDocument = gql`
  query notificationSettingCurrentUser {
    notificationSettingCurrentUser {
      id
      emailConnection
      emailJob
      pushAvailability
      pushChat
      pushConnection
      pushJob
      systemAvailability
      systemChat
      systemConnection
      systemJob
    }
  }
`;
export type NotificationSettingCurrentUserProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables
  >;
} & TChildProps;
export function withNotificationSettingCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables,
    NotificationSettingCurrentUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables,
    NotificationSettingCurrentUserProps<TChildProps, TDataName>
  >(NotificationSettingCurrentUserDocument, {
    alias: 'notificationSettingCurrentUser',
    ...operationOptions,
  });
}

/**
 * __useNotificationSettingCurrentUserQuery__
 *
 * To run a query within a React component, call `useNotificationSettingCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSettingCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables
  >(NotificationSettingCurrentUserDocument, options);
}
export function useNotificationSettingCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationSettingCurrentUserQuery,
    NotificationSettingCurrentUserQueryVariables
  >(NotificationSettingCurrentUserDocument, options);
}
export type NotificationSettingCurrentUserQueryHookResult = ReturnType<
  typeof useNotificationSettingCurrentUserQuery
>;
export type NotificationSettingCurrentUserLazyQueryHookResult = ReturnType<
  typeof useNotificationSettingCurrentUserLazyQuery
>;
export type NotificationSettingCurrentUserQueryResult = Apollo.QueryResult<
  NotificationSettingCurrentUserQuery,
  NotificationSettingCurrentUserQueryVariables
>;
export function refetchNotificationSettingCurrentUserQuery(
  variables?: NotificationSettingCurrentUserQueryVariables,
) {
  return { query: NotificationSettingCurrentUserDocument, variables: variables };
}
export const AvailabilityFindActiveAdhocCurrentUserDocument = gql`
  query availabilityFindActiveAdhocCurrentUser {
    availabilityFindActiveAdhocCurrentUser {
      id
      lastLocationForDevice {
        id
        formattedAddress
        formattedMinimalAddress
        locationGeometry {
          location {
            lat
            lng
          }
        }
      }
      isAdHocAvailability
      startTime
      endTime
      updatedAt
    }
  }
`;
export type AvailabilityFindActiveAdhocCurrentUserProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables
  >;
} & TChildProps;
export function withAvailabilityFindActiveAdhocCurrentUser<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables,
    AvailabilityFindActiveAdhocCurrentUserProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables,
    AvailabilityFindActiveAdhocCurrentUserProps<TChildProps, TDataName>
  >(AvailabilityFindActiveAdhocCurrentUserDocument, {
    alias: 'availabilityFindActiveAdhocCurrentUser',
    ...operationOptions,
  });
}

/**
 * __useAvailabilityFindActiveAdhocCurrentUserQuery__
 *
 * To run a query within a React component, call `useAvailabilityFindActiveAdhocCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityFindActiveAdhocCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityFindActiveAdhocCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailabilityFindActiveAdhocCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables
  >(AvailabilityFindActiveAdhocCurrentUserDocument, options);
}
export function useAvailabilityFindActiveAdhocCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailabilityFindActiveAdhocCurrentUserQuery,
    AvailabilityFindActiveAdhocCurrentUserQueryVariables
  >(AvailabilityFindActiveAdhocCurrentUserDocument, options);
}
export type AvailabilityFindActiveAdhocCurrentUserQueryHookResult = ReturnType<
  typeof useAvailabilityFindActiveAdhocCurrentUserQuery
>;
export type AvailabilityFindActiveAdhocCurrentUserLazyQueryHookResult = ReturnType<
  typeof useAvailabilityFindActiveAdhocCurrentUserLazyQuery
>;
export type AvailabilityFindActiveAdhocCurrentUserQueryResult = Apollo.QueryResult<
  AvailabilityFindActiveAdhocCurrentUserQuery,
  AvailabilityFindActiveAdhocCurrentUserQueryVariables
>;
export function refetchAvailabilityFindActiveAdhocCurrentUserQuery(
  variables?: AvailabilityFindActiveAdhocCurrentUserQueryVariables,
) {
  return { query: AvailabilityFindActiveAdhocCurrentUserDocument, variables: variables };
}
export const AvailableCourierUsersByDistanceForAgencyJobFilteredDocument = gql`
  query AvailableCourierUsersByDistanceForAgencyJobFiltered(
    $agencyJobId: String!
    $filterType: [JobAvailabilityFilterType!]
  ) {
    availableCourierUsersByDistanceForAgencyJobFiltered(
      agencyJobId: $agencyJobId
      filterType: $filterType
    ) {
      user {
        id
        firstNames
        lastName
        email
        phone
        courierUserProfile {
          id
          baseAirports
        }
        agencyCourierUserProfile {
          internalComment
          markedForAttention
          organizationCourierCategories {
            id
            order
            label
          }
        }
      }
      organizationUsers {
        id
        roleType
        organization {
          id
          name
          legalName
          shortName
          organizationType
        }
        user {
          id
        }
      }
      courierJob {
        id
        relationshipType
        declineReasonType
        isActive
      }
      availabilityLocationsWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        groupType
        distance
      }
      displayAvailabilityLocationWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        distance
      }
      lastAvailabilityLocationWithDistance {
        availabilityLocation {
          id
          timestamp
          availability {
            id
            isAdHocAvailability
            startTime
            endTime
            timeZoneIdentifier
          }
          location {
            id
            formattedShortAddress
            formattedMinimalAddress
            formattedAddress
            timeZoneIdentifier
          }
        }
        distance
      }
    }
  }
`;
export type AvailableCourierUsersByDistanceForAgencyJobFilteredProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables
  >;
} & TChildProps;
export function withAvailableCourierUsersByDistanceForAgencyJobFiltered<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables,
    AvailableCourierUsersByDistanceForAgencyJobFilteredProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables,
    AvailableCourierUsersByDistanceForAgencyJobFilteredProps<TChildProps, TDataName>
  >(AvailableCourierUsersByDistanceForAgencyJobFilteredDocument, {
    alias: 'availableCourierUsersByDistanceForAgencyJobFiltered',
    ...operationOptions,
  });
}

/**
 * __useAvailableCourierUsersByDistanceForAgencyJobFilteredQuery__
 *
 * To run a query within a React component, call `useAvailableCourierUsersByDistanceForAgencyJobFilteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCourierUsersByDistanceForAgencyJobFilteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCourierUsersByDistanceForAgencyJobFilteredQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *      filterType: // value for 'filterType'
 *   },
 * });
 */
export function useAvailableCourierUsersByDistanceForAgencyJobFilteredQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables
  >(AvailableCourierUsersByDistanceForAgencyJobFilteredDocument, options);
}
export function useAvailableCourierUsersByDistanceForAgencyJobFilteredLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
    AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables
  >(AvailableCourierUsersByDistanceForAgencyJobFilteredDocument, options);
}
export type AvailableCourierUsersByDistanceForAgencyJobFilteredQueryHookResult = ReturnType<
  typeof useAvailableCourierUsersByDistanceForAgencyJobFilteredQuery
>;
export type AvailableCourierUsersByDistanceForAgencyJobFilteredLazyQueryHookResult = ReturnType<
  typeof useAvailableCourierUsersByDistanceForAgencyJobFilteredLazyQuery
>;
export type AvailableCourierUsersByDistanceForAgencyJobFilteredQueryResult = Apollo.QueryResult<
  AvailableCourierUsersByDistanceForAgencyJobFilteredQuery,
  AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables
>;
export function refetchAvailableCourierUsersByDistanceForAgencyJobFilteredQuery(
  variables: AvailableCourierUsersByDistanceForAgencyJobFilteredQueryVariables,
) {
  return {
    query: AvailableCourierUsersByDistanceForAgencyJobFilteredDocument,
    variables: variables,
  };
}
export const AgencyJobPlanningForAgencyJobDocument = gql`
  query AgencyJobPlanningForAgencyJob($agencyJobId: String!) {
    agencyJobPlanningForAgencyJob(agencyJobId: $agencyJobId) {
      id
      agencyJobPlanningItems {
        id
        active
        actualDateTime
        actualDateTimeSetAt
        updatedAt
        files {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        formType
        id
        inactivatedAt
        label
        location {
          id
          formattedAddress
        }
        name
        order
        targetDateTime
      }
      agencyJobPlanningLegs {
        id
        active
        departureDate
        flightCarrierDesignation
        flightNumber
        order
        updatedAt
        flightTickets {
          id
          storedAsset {
            sasUrl
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        agencyJobPlanningItems {
          id
          active
          actualDateTime
          actualDateTimeSetAt
          createdAt
          files {
            id
            storedAsset {
              sasUrl
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          formType
          id
          inactivatedAt
          label
          location {
            id
            formattedAddress
          }
          name
          order
          targetDateTime
          updatedAt
        }
      }
    }
  }
`;
export type AgencyJobPlanningForAgencyJobProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables
  >;
} & TChildProps;
export function withAgencyJobPlanningForAgencyJob<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables,
    AgencyJobPlanningForAgencyJobProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables,
    AgencyJobPlanningForAgencyJobProps<TChildProps, TDataName>
  >(AgencyJobPlanningForAgencyJobDocument, {
    alias: 'agencyJobPlanningForAgencyJob',
    ...operationOptions,
  });
}

/**
 * __useAgencyJobPlanningForAgencyJobQuery__
 *
 * To run a query within a React component, call `useAgencyJobPlanningForAgencyJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyJobPlanningForAgencyJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyJobPlanningForAgencyJobQuery({
 *   variables: {
 *      agencyJobId: // value for 'agencyJobId'
 *   },
 * });
 */
export function useAgencyJobPlanningForAgencyJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables
  >(AgencyJobPlanningForAgencyJobDocument, options);
}
export function useAgencyJobPlanningForAgencyJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AgencyJobPlanningForAgencyJobQuery,
    AgencyJobPlanningForAgencyJobQueryVariables
  >(AgencyJobPlanningForAgencyJobDocument, options);
}
export type AgencyJobPlanningForAgencyJobQueryHookResult = ReturnType<
  typeof useAgencyJobPlanningForAgencyJobQuery
>;
export type AgencyJobPlanningForAgencyJobLazyQueryHookResult = ReturnType<
  typeof useAgencyJobPlanningForAgencyJobLazyQuery
>;
export type AgencyJobPlanningForAgencyJobQueryResult = Apollo.QueryResult<
  AgencyJobPlanningForAgencyJobQuery,
  AgencyJobPlanningForAgencyJobQueryVariables
>;
export function refetchAgencyJobPlanningForAgencyJobQuery(
  variables: AgencyJobPlanningForAgencyJobQueryVariables,
) {
  return { query: AgencyJobPlanningForAgencyJobDocument, variables: variables };
}
export const ChatMessagesByChatIdForAgencyDocument = gql`
  query ChatMessagesByChatIdForAgency(
    $chatMessagesByChatIdInput: ChatMessagesByChatIdInput!
    $pagination: PaginationArgs
    $orderBy: OrderArgs
  ) {
    chatMessagesByChatId(
      chatMessagesByChatIdInput: $chatMessagesByChatIdInput
      pagination: $pagination
      orderBy: $orderBy
    ) {
      edges {
        node {
          id
          message
          messageType
          agencyJobPlanningItemHistoryLog {
            id
            active
            actualDateTime
            label
            targetDateTime
            note
            agencyJobPlanningLeg {
              id
              flightCarrierDesignation
              flightNumber
              order
            }
            files {
              id
              storedAsset {
                sasUrl
                cacheKey
                cacheTTL
                metadata {
                  fileName
                  mimeType
                  height
                  width
                  size
                }
              }
            }
            location {
              id
              formattedAddress
            }
          }
          chatFile {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
          sendingUser {
            id
            firstNames
            lastName
            profilePicture {
              sasUrl
              cacheKey
              cacheTTL
              sasToken {
                version
                signature
                expiresOn
                permissions
                resource
              }
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          createdAt
          updatedAt
        }
        cursor
      }
      totalCount
    }
  }
`;
export type ChatMessagesByChatIdForAgencyProps<
  TChildProps = {},
  TDataName extends string = 'data',
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables
  >;
} & TChildProps;
export function withChatMessagesByChatIdForAgency<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables,
    ChatMessagesByChatIdForAgencyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables,
    ChatMessagesByChatIdForAgencyProps<TChildProps, TDataName>
  >(ChatMessagesByChatIdForAgencyDocument, {
    alias: 'chatMessagesByChatIdForAgency',
    ...operationOptions,
  });
}

/**
 * __useChatMessagesByChatIdForAgencyQuery__
 *
 * To run a query within a React component, call `useChatMessagesByChatIdForAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesByChatIdForAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesByChatIdForAgencyQuery({
 *   variables: {
 *      chatMessagesByChatIdInput: // value for 'chatMessagesByChatIdInput'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useChatMessagesByChatIdForAgencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables
  >(ChatMessagesByChatIdForAgencyDocument, options);
}
export function useChatMessagesByChatIdForAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ChatMessagesByChatIdForAgencyQuery,
    ChatMessagesByChatIdForAgencyQueryVariables
  >(ChatMessagesByChatIdForAgencyDocument, options);
}
export type ChatMessagesByChatIdForAgencyQueryHookResult = ReturnType<
  typeof useChatMessagesByChatIdForAgencyQuery
>;
export type ChatMessagesByChatIdForAgencyLazyQueryHookResult = ReturnType<
  typeof useChatMessagesByChatIdForAgencyLazyQuery
>;
export type ChatMessagesByChatIdForAgencyQueryResult = Apollo.QueryResult<
  ChatMessagesByChatIdForAgencyQuery,
  ChatMessagesByChatIdForAgencyQueryVariables
>;
export function refetchChatMessagesByChatIdForAgencyQuery(
  variables: ChatMessagesByChatIdForAgencyQueryVariables,
) {
  return { query: ChatMessagesByChatIdForAgencyDocument, variables: variables };
}
export const ChatMessageEdgeForAgencyDocument = gql`
  query ChatMessageEdgeForAgency($chatMessageId: String!) {
    chatMessageEdge(chatMessageId: $chatMessageId) {
      node {
        id
        message
        messageType
        agencyJobPlanningItemHistoryLog {
          id
          active
          actualDateTime
          label
          targetDateTime
          note
          agencyJobPlanningLeg {
            id
            flightCarrierDesignation
            flightNumber
            order
          }
          files {
            id
            storedAsset {
              sasUrl
              cacheKey
              cacheTTL
              metadata {
                fileName
                mimeType
                height
                width
                size
              }
            }
          }
          location {
            id
            formattedAddress
          }
        }
        chatFile {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
        sendingUser {
          id
          firstNames
          lastName
          profilePicture {
            sasUrl
            cacheKey
            cacheTTL
            sasToken {
              version
              signature
              expiresOn
              permissions
              resource
            }
            metadata {
              fileName
              mimeType
              height
              width
              size
            }
          }
        }
        createdAt
        updatedAt
      }
      cursor
    }
  }
`;
export type ChatMessageEdgeForAgencyProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ChatMessageEdgeForAgencyQuery,
    ChatMessageEdgeForAgencyQueryVariables
  >;
} & TChildProps;
export function withChatMessageEdgeForAgency<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data',
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChatMessageEdgeForAgencyQuery,
    ChatMessageEdgeForAgencyQueryVariables,
    ChatMessageEdgeForAgencyProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ChatMessageEdgeForAgencyQuery,
    ChatMessageEdgeForAgencyQueryVariables,
    ChatMessageEdgeForAgencyProps<TChildProps, TDataName>
  >(ChatMessageEdgeForAgencyDocument, {
    alias: 'chatMessageEdgeForAgency',
    ...operationOptions,
  });
}

/**
 * __useChatMessageEdgeForAgencyQuery__
 *
 * To run a query within a React component, call `useChatMessageEdgeForAgencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageEdgeForAgencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageEdgeForAgencyQuery({
 *   variables: {
 *      chatMessageId: // value for 'chatMessageId'
 *   },
 * });
 */
export function useChatMessageEdgeForAgencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChatMessageEdgeForAgencyQuery,
    ChatMessageEdgeForAgencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatMessageEdgeForAgencyQuery, ChatMessageEdgeForAgencyQueryVariables>(
    ChatMessageEdgeForAgencyDocument,
    options,
  );
}
export function useChatMessageEdgeForAgencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChatMessageEdgeForAgencyQuery,
    ChatMessageEdgeForAgencyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatMessageEdgeForAgencyQuery, ChatMessageEdgeForAgencyQueryVariables>(
    ChatMessageEdgeForAgencyDocument,
    options,
  );
}
export type ChatMessageEdgeForAgencyQueryHookResult = ReturnType<
  typeof useChatMessageEdgeForAgencyQuery
>;
export type ChatMessageEdgeForAgencyLazyQueryHookResult = ReturnType<
  typeof useChatMessageEdgeForAgencyLazyQuery
>;
export type ChatMessageEdgeForAgencyQueryResult = Apollo.QueryResult<
  ChatMessageEdgeForAgencyQuery,
  ChatMessageEdgeForAgencyQueryVariables
>;
export function refetchChatMessageEdgeForAgencyQuery(
  variables: ChatMessageEdgeForAgencyQueryVariables,
) {
  return { query: ChatMessageEdgeForAgencyDocument, variables: variables };
}
export const OnChatMessageSentDocument = gql`
  subscription OnChatMessageSent($chatId: String!) {
    chatMessageSent(chatId: $chatId) {
      id
      messageType
      createdAt
      message
      chatFile {
        sasUrl
        cacheKey
        cacheTTL
        sasToken {
          version
          signature
          expiresOn
          permissions
          resource
        }
        metadata {
          fileName
          mimeType
          height
          width
          size
        }
      }
      sendingUser {
        id
        firstNames
        lastName
        profilePicture {
          sasUrl
          cacheKey
          cacheTTL
          sasToken {
            version
            signature
            expiresOn
            permissions
            resource
          }
          metadata {
            fileName
            mimeType
            height
            width
            size
          }
        }
      }
    }
  }
`;
export type OnChatMessageSentProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    OnChatMessageSentSubscription,
    OnChatMessageSentSubscriptionVariables
  >;
} & TChildProps;
export function withOnChatMessageSent<TProps, TChildProps = {}, TDataName extends string = 'data'>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OnChatMessageSentSubscription,
    OnChatMessageSentSubscriptionVariables,
    OnChatMessageSentProps<TChildProps, TDataName>
  >,
) {
  return ApolloReactHoc.withSubscription<
    TProps,
    OnChatMessageSentSubscription,
    OnChatMessageSentSubscriptionVariables,
    OnChatMessageSentProps<TChildProps, TDataName>
  >(OnChatMessageSentDocument, {
    alias: 'onChatMessageSent',
    ...operationOptions,
  });
}

/**
 * __useOnChatMessageSentSubscription__
 *
 * To run a query within a React component, call `useOnChatMessageSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnChatMessageSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnChatMessageSentSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useOnChatMessageSentSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnChatMessageSentSubscription,
    OnChatMessageSentSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnChatMessageSentSubscription,
    OnChatMessageSentSubscriptionVariables
  >(OnChatMessageSentDocument, options);
}
export type OnChatMessageSentSubscriptionHookResult = ReturnType<
  typeof useOnChatMessageSentSubscription
>;
export type OnChatMessageSentSubscriptionResult =
  Apollo.SubscriptionResult<OnChatMessageSentSubscription>;
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AddDeviceLogInput: ResolverTypeWrapper<Partial<AddDeviceLogInput>>;
  AddDeviceLogOutput: ResolverTypeWrapper<Partial<AddDeviceLogOutput>>;
  AgencyCourierJobChatInput: ResolverTypeWrapper<Partial<AgencyCourierJobChatInput>>;
  AgencyCourierUserProfile: ResolverTypeWrapper<Partial<AgencyCourierUserProfile>>;
  AgencyCourierUserProfileCreateOrUpdateInput: ResolverTypeWrapper<
    Partial<AgencyCourierUserProfileCreateOrUpdateInput>
  >;
  AgencyDeclinedReasonType: ResolverTypeWrapper<Partial<AgencyDeclinedReasonType>>;
  AgencyJob: ResolverTypeWrapper<Partial<AgencyJob>>;
  AgencyJobChatInput: ResolverTypeWrapper<Partial<AgencyJobChatInput>>;
  AgencyJobClientRelationshipType: ResolverTypeWrapper<Partial<AgencyJobClientRelationshipType>>;
  AgencyJobConnection: ResolverTypeWrapper<Partial<AgencyJobConnection>>;
  AgencyJobEdge: ResolverTypeWrapper<Partial<AgencyJobEdge>>;
  AgencyJobInput: ResolverTypeWrapper<Partial<AgencyJobInput>>;
  AgencyJobPlanning: ResolverTypeWrapper<Partial<AgencyJobPlanning>>;
  AgencyJobPlanningFile: ResolverTypeWrapper<Partial<AgencyJobPlanningFile>>;
  AgencyJobPlanningForAgencyJobUpdateInput: ResolverTypeWrapper<
    Partial<AgencyJobPlanningForAgencyJobUpdateInput>
  >;
  AgencyJobPlanningFormType: ResolverTypeWrapper<Partial<AgencyJobPlanningFormType>>;
  AgencyJobPlanningItem: ResolverTypeWrapper<Partial<AgencyJobPlanningItem>>;
  AgencyJobPlanningItemHistoryLog: ResolverTypeWrapper<Partial<AgencyJobPlanningItemHistoryLog>>;
  AgencyJobPlanningItemInput: ResolverTypeWrapper<Partial<AgencyJobPlanningItemInput>>;
  AgencyJobPlanningItemUpdateInput: ResolverTypeWrapper<Partial<AgencyJobPlanningItemUpdateInput>>;
  AgencyJobPlanningLeg: ResolverTypeWrapper<Partial<AgencyJobPlanningLeg>>;
  AgencyJobPlanningLegInput: ResolverTypeWrapper<Partial<AgencyJobPlanningLegInput>>;
  AgencyJobStatusType: ResolverTypeWrapper<Partial<AgencyJobStatusType>>;
  AgencyJobsForAgencyOrganizationInput: ResolverTypeWrapper<
    Partial<AgencyJobsForAgencyOrganizationInput>
  >;
  AgencyOrganizationProfileInput: ResolverTypeWrapper<Partial<AgencyOrganizationProfileInput>>;
  AgencyOrganizationVatIdInput: ResolverTypeWrapper<Partial<AgencyOrganizationVatIdInput>>;
  AirlineAllianceStatusType: ResolverTypeWrapper<Partial<AirlineAllianceStatusType>>;
  AirlineAllianceType: ResolverTypeWrapper<Partial<AirlineAllianceType>>;
  AirlineStatusType: ResolverTypeWrapper<Partial<AirlineStatusType>>;
  AirlineType: ResolverTypeWrapper<Partial<AirlineType>>;
  AirportLocationConnection: ResolverTypeWrapper<Partial<AirportLocationConnection>>;
  AirportLocationListInput: ResolverTypeWrapper<Partial<AirportLocationListInput>>;
  AirportLocationSearchInput: ResolverTypeWrapper<Partial<AirportLocationSearchInput>>;
  AirportLocationUpdateTimezoneInput: ResolverTypeWrapper<
    Partial<AirportLocationUpdateTimezoneInput>
  >;
  AuthSignOutUserContextInput: ResolverTypeWrapper<Partial<AuthSignOutUserContextInput>>;
  Availability: ResolverTypeWrapper<Partial<Availability>>;
  AvailabilityFilterType: ResolverTypeWrapper<Partial<AvailabilityFilterType>>;
  AvailabilityLocation: ResolverTypeWrapper<Partial<AvailabilityLocation>>;
  AvailabilityLocationWithDistance: ResolverTypeWrapper<Partial<AvailabilityLocationWithDistance>>;
  AvailableCourierOrganizationUsersWithDistance: ResolverTypeWrapper<
    Partial<AvailableCourierOrganizationUsersWithDistance>
  >;
  AzureADB2CToken: ResolverTypeWrapper<Partial<AzureAdb2CToken>>;
  Boolean: ResolverTypeWrapper<Partial<Scalars['Boolean']['output']>>;
  Chat: ResolverTypeWrapper<Partial<Chat>>;
  ChatFile: ResolverTypeWrapper<Partial<ChatFile>>;
  ChatFileInput: ResolverTypeWrapper<Partial<ChatFileInput>>;
  ChatMessage: ResolverTypeWrapper<Partial<ChatMessage>>;
  ChatMessageConnection: ResolverTypeWrapper<Partial<ChatMessageConnection>>;
  ChatMessageCreateEdge: ResolverTypeWrapper<Partial<ChatMessageCreateEdge>>;
  ChatMessageEdge: ResolverTypeWrapper<Partial<ChatMessageEdge>>;
  ChatMessagesByChatIdInput: ResolverTypeWrapper<Partial<ChatMessagesByChatIdInput>>;
  ChatType: ResolverTypeWrapper<Partial<ChatType>>;
  ClientDeclinedReasonType: ResolverTypeWrapper<Partial<ClientDeclinedReasonType>>;
  ClientJob: ResolverTypeWrapper<Partial<ClientJob>>;
  ClientJobByAgencyInput: ResolverTypeWrapper<Partial<ClientJobByAgencyInput>>;
  ConnectedOrganization: ResolverTypeWrapper<Partial<ConnectedOrganization>>;
  Contact: ResolverTypeWrapper<Partial<Contact>>;
  ContactBitrix24Company: ResolverTypeWrapper<Partial<ContactBitrix24Company>>;
  ContactBitrix24Contact: ResolverTypeWrapper<Partial<ContactBitrix24Contact>>;
  ContactBitrix24Data: ResolverTypeWrapper<Partial<ContactBitrix24Data>>;
  ContactBitrix24Email: ResolverTypeWrapper<Partial<ContactBitrix24Email>>;
  ContactBitrix24Phone: ResolverTypeWrapper<Partial<ContactBitrix24Phone>>;
  ContactChanges: ResolverTypeWrapper<Partial<ContactChanges>>;
  ContactPipedriveData: ResolverTypeWrapper<Partial<ContactPipedriveData>>;
  ContactPipedriveOrganization: ResolverTypeWrapper<Partial<ContactPipedriveOrganization>>;
  ContactPipedrivePerson: ResolverTypeWrapper<Partial<ContactPipedrivePerson>>;
  CourierJob: ResolverTypeWrapper<Partial<CourierJob>>;
  CourierJobAgencyRelationshipType: ResolverTypeWrapper<Partial<CourierJobAgencyRelationshipType>>;
  CourierJobStatusType: ResolverTypeWrapper<Partial<CourierJobStatusType>>;
  CourierOrganizationProfileInput: ResolverTypeWrapper<Partial<CourierOrganizationProfileInput>>;
  CourierUserProfile: ResolverTypeWrapper<Partial<CourierUserProfile>>;
  CourierUserProfileInput: ResolverTypeWrapper<Partial<CourierUserProfileInput>>;
  CreateChatMessageInput: ResolverTypeWrapper<Partial<CreateChatMessageInput>>;
  Currency: ResolverTypeWrapper<Partial<Currency>>;
  DateTime: ResolverTypeWrapper<Partial<Scalars['DateTime']['output']>>;
  DeleteChatMessageInput: ResolverTypeWrapper<Partial<DeleteChatMessageInput>>;
  Device: ResolverTypeWrapper<Partial<Device>>;
  DeviceAppSettings: ResolverTypeWrapper<Partial<DeviceAppSettings>>;
  DeviceAppSettingsInput: ResolverTypeWrapper<Partial<DeviceAppSettingsInput>>;
  DeviceApplicationReleaseType: ResolverTypeWrapper<Partial<DeviceApplicationReleaseType>>;
  DeviceCanvas: ResolverTypeWrapper<Partial<DeviceCanvas>>;
  DeviceCanvasInput: ResolverTypeWrapper<Partial<DeviceCanvasInput>>;
  DeviceCategoryType: ResolverTypeWrapper<Partial<DeviceCategoryType>>;
  DeviceInput: ResolverTypeWrapper<Partial<DeviceInput>>;
  DeviceLocalization: ResolverTypeWrapper<Partial<DeviceLocalization>>;
  DeviceLocalizationInput: ResolverTypeWrapper<Partial<DeviceLocalizationInput>>;
  DeviceLocation: ResolverTypeWrapper<Partial<DeviceLocation>>;
  DeviceLocationInput: ResolverTypeWrapper<Partial<DeviceLocationInput>>;
  DeviceLog: ResolverTypeWrapper<Partial<DeviceLog>>;
  DeviceLogBatteryStateType: ResolverTypeWrapper<Partial<DeviceLogBatteryStateType>>;
  DeviceLogData: ResolverTypeWrapper<Partial<DeviceLogData>>;
  DeviceLogDataInput: ResolverTypeWrapper<Partial<DeviceLogDataInput>>;
  DeviceLogNetworkStateType: ResolverTypeWrapper<Partial<DeviceLogNetworkStateType>>;
  DeviceLogOutput: ResolverTypeWrapper<Partial<DeviceLogOutput>>;
  DeviceLogTriggerType: ResolverTypeWrapper<Partial<DeviceLogTriggerType>>;
  DevicePermissionExpiration: ResolverTypeWrapper<
    Partial<Scalars['DevicePermissionExpiration']['output']>
  >;
  DevicePermissionStatus: ResolverTypeWrapper<Partial<DevicePermissionStatus>>;
  DevicePermissions: ResolverTypeWrapper<Partial<DevicePermissions>>;
  DevicePermissionsInput: ResolverTypeWrapper<Partial<DevicePermissionsInput>>;
  DevicePermissionsNotifications: ResolverTypeWrapper<Partial<DevicePermissionsNotifications>>;
  DevicePermissionsNotificationsAndroid: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsAndroid>
  >;
  DevicePermissionsNotificationsAndroidInput: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsAndroidInput>
  >;
  DevicePermissionsNotificationsInput: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsInput>
  >;
  DevicePermissionsNotificationsIos: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsIos>
  >;
  DevicePermissionsNotificationsIosAlertStyle: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsIosAlertStyle>
  >;
  DevicePermissionsNotificationsIosAllowsPreviews: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsIosAllowsPreviews>
  >;
  DevicePermissionsNotificationsIosAuthorizationStatus: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsIosAuthorizationStatus>
  >;
  DevicePermissionsNotificationsIosInput: ResolverTypeWrapper<
    Partial<DevicePermissionsNotificationsIosInput>
  >;
  DeviceProperties: ResolverTypeWrapper<Partial<DeviceProperties>>;
  DevicePropertiesInput: ResolverTypeWrapper<Partial<DevicePropertiesInput>>;
  DocumentFeature: ResolverTypeWrapper<Partial<DocumentFeature>>;
  DocumentVerificationStatus: ResolverTypeWrapper<Partial<DocumentVerificationStatus>>;
  EntityDeletionReturnType: ResolverTypeWrapper<Partial<EntityDeletionReturnType>>;
  ExpoContact: ResolverTypeWrapper<Partial<ExpoContact>>;
  ExpoContactAddress: ResolverTypeWrapper<Partial<ExpoContactAddress>>;
  ExpoContactAddressInput: ResolverTypeWrapper<Partial<ExpoContactAddressInput>>;
  ExpoContactDate: ResolverTypeWrapper<Partial<ExpoContactDate>>;
  ExpoContactDateInput: ResolverTypeWrapper<Partial<ExpoContactDateInput>>;
  ExpoContactEmail: ResolverTypeWrapper<Partial<ExpoContactEmail>>;
  ExpoContactEmailInput: ResolverTypeWrapper<Partial<ExpoContactEmailInput>>;
  ExpoContactImage: ResolverTypeWrapper<Partial<ExpoContactImage>>;
  ExpoContactImageInput: ResolverTypeWrapper<Partial<ExpoContactImageInput>>;
  ExpoContactInput: ResolverTypeWrapper<Partial<ExpoContactInput>>;
  ExpoContactInstantMessageAddress: ResolverTypeWrapper<Partial<ExpoContactInstantMessageAddress>>;
  ExpoContactInstantMessageAddressInput: ResolverTypeWrapper<
    Partial<ExpoContactInstantMessageAddressInput>
  >;
  ExpoContactPhoneNumber: ResolverTypeWrapper<Partial<ExpoContactPhoneNumber>>;
  ExpoContactPhoneNumberInput: ResolverTypeWrapper<Partial<ExpoContactPhoneNumberInput>>;
  ExpoContactRelationship: ResolverTypeWrapper<Partial<ExpoContactRelationship>>;
  ExpoContactRelationshipInput: ResolverTypeWrapper<Partial<ExpoContactRelationshipInput>>;
  ExpoContactSocialProfile: ResolverTypeWrapper<Partial<ExpoContactSocialProfile>>;
  ExpoContactSocialProfileInput: ResolverTypeWrapper<Partial<ExpoContactSocialProfileInput>>;
  ExpoContactUrlAddress: ResolverTypeWrapper<Partial<ExpoContactUrlAddress>>;
  ExpoContactUrlAddressInput: ResolverTypeWrapper<Partial<ExpoContactUrlAddressInput>>;
  FilesUploadInput: ResolverTypeWrapper<Partial<FilesUploadInput>>;
  Float: ResolverTypeWrapper<Partial<Scalars['Float']['output']>>;
  Gender: ResolverTypeWrapper<Partial<Gender>>;
  HealthTestType: ResolverTypeWrapper<Partial<HealthTestType>>;
  HistoryLogPayment: ResolverTypeWrapper<Partial<HistoryLogPayment>>;
  ID: ResolverTypeWrapper<Partial<Scalars['ID']['output']>>;
  IdentityDocumentSubType: ResolverTypeWrapper<Partial<IdentityDocumentSubType>>;
  IdentityDocumentType: ResolverTypeWrapper<Partial<IdentityDocumentType>>;
  Int: ResolverTypeWrapper<Partial<Scalars['Int']['output']>>;
  IpAddressType: ResolverTypeWrapper<Partial<IpAddressType>>;
  JSONObject: ResolverTypeWrapper<Partial<Scalars['JSONObject']['output']>>;
  Job: ResolverTypeWrapper<Partial<Job>>;
  JobAvailabilityFilterType: ResolverTypeWrapper<Partial<JobAvailabilityFilterType>>;
  JobInput: ResolverTypeWrapper<Partial<JobInput>>;
  JobType: ResolverTypeWrapper<Partial<JobType>>;
  JobTypeOfDelivery: ResolverTypeWrapper<Partial<JobTypeOfDelivery>>;
  LanguageSkillLevel: ResolverTypeWrapper<Partial<LanguageSkillLevel>>;
  LanguagesKnownInput: ResolverTypeWrapper<Partial<LanguagesKnownInput>>;
  Location: ResolverTypeWrapper<Partial<Location>>;
  LocationAddressComponent: ResolverTypeWrapper<Partial<LocationAddressComponent>>;
  LocationEdge: ResolverTypeWrapper<Partial<LocationEdge>>;
  LocationGeometry: ResolverTypeWrapper<Partial<LocationGeometry>>;
  LocationGeometryCoords: ResolverTypeWrapper<Partial<LocationGeometryCoords>>;
  LocationGeometryGeoFence: ResolverTypeWrapper<Partial<LocationGeometryGeoFence>>;
  LocationGeometryLatLngBounds: ResolverTypeWrapper<Partial<LocationGeometryLatLngBounds>>;
  LocationGeometryLocation: ResolverTypeWrapper<Partial<LocationGeometryLocation>>;
  LocationManualInput: ResolverTypeWrapper<Partial<LocationManualInput>>;
  LocationPlusCode: ResolverTypeWrapper<Partial<LocationPlusCode>>;
  LocationType: ResolverTypeWrapper<Partial<LocationType>>;
  MessageType: ResolverTypeWrapper<Partial<MessageType>>;
  MinSupportedVersion: ResolverTypeWrapper<Partial<MinSupportedVersion>>;
  Mutation: ResolverTypeWrapper<{}>;
  Notification: ResolverTypeWrapper<Partial<Notification>>;
  NotificationLanguage: ResolverTypeWrapper<Partial<NotificationLanguage>>;
  NotificationMeansType: ResolverTypeWrapper<Partial<NotificationMeansType>>;
  NotificationSetting: ResolverTypeWrapper<Partial<NotificationSetting>>;
  NotificationSettingUpdateInput: ResolverTypeWrapper<Partial<NotificationSettingUpdateInput>>;
  NotificationSystemFilterType: ResolverTypeWrapper<Partial<NotificationSystemFilterType>>;
  NotificationType: ResolverTypeWrapper<Partial<NotificationType>>;
  OrderArgs: ResolverTypeWrapper<Partial<OrderArgs>>;
  OrderDirection: ResolverTypeWrapper<Partial<OrderDirection>>;
  OrderType: ResolverTypeWrapper<Partial<OrderType>>;
  Organization: ResolverTypeWrapper<Partial<Organization>>;
  OrganizationContact: ResolverTypeWrapper<Partial<OrganizationContact>>;
  OrganizationContactChanges: ResolverTypeWrapper<Partial<OrganizationContactChanges>>;
  OrganizationCourierCategoriesUpdateInput: ResolverTypeWrapper<
    Partial<OrganizationCourierCategoriesUpdateInput>
  >;
  OrganizationCourierCategory: ResolverTypeWrapper<Partial<OrganizationCourierCategory>>;
  OrganizationCourierCategoryUpdate: ResolverTypeWrapper<
    Partial<OrganizationCourierCategoryUpdate>
  >;
  OrganizationInvitation: ResolverTypeWrapper<Partial<OrganizationInvitation>>;
  OrganizationInvitationBitrix24Deal: ResolverTypeWrapper<
    Partial<OrganizationInvitationBitrix24Deal>
  >;
  OrganizationInvitationPipedriveDeal: ResolverTypeWrapper<
    Partial<OrganizationInvitationPipedriveDeal>
  >;
  OrganizationInvitationSharing: ResolverTypeWrapper<Partial<OrganizationInvitationSharing>>;
  OrganizationInvitationSharingInput: ResolverTypeWrapper<
    Partial<OrganizationInvitationSharingInput>
  >;
  OrganizationInvitationType: ResolverTypeWrapper<Partial<OrganizationInvitationType>>;
  OrganizationInvitationUploadCSV: ResolverTypeWrapper<Partial<OrganizationInvitationUploadCsv>>;
  OrganizationInvitationUploadCSVContact: ResolverTypeWrapper<
    Partial<OrganizationInvitationUploadCsvContact>
  >;
  OrganizationInvitationUploadCSVInput: ResolverTypeWrapper<
    Partial<OrganizationInvitationUploadCsvInput>
  >;
  OrganizationOrganization: ResolverTypeWrapper<Partial<OrganizationOrganization>>;
  OrganizationOrganizationConnectionType: ResolverTypeWrapper<
    Partial<OrganizationOrganizationConnectionType>
  >;
  OrganizationSubscriptionPlan: ResolverTypeWrapper<Partial<OrganizationSubscriptionPlan>>;
  OrganizationSubscriptionPlanPipedriveDeal: ResolverTypeWrapper<
    Partial<OrganizationSubscriptionPlanPipedriveDeal>
  >;
  OrganizationSubscriptionPlanSingleInput: ResolverTypeWrapper<
    Partial<OrganizationSubscriptionPlanSingleInput>
  >;
  OrganizationType: ResolverTypeWrapper<Partial<OrganizationType>>;
  OrganizationUser: ResolverTypeWrapper<Partial<OrganizationUser>>;
  OrganizationUserRoleType: ResolverTypeWrapper<Partial<OrganizationUserRoleType>>;
  OrganizationUserUpdateProfileInput: ResolverTypeWrapper<
    Partial<OrganizationUserUpdateProfileInput>
  >;
  PageInfo: ResolverTypeWrapper<Partial<PageInfo>>;
  PaginationArgs: ResolverTypeWrapper<Partial<PaginationArgs>>;
  PluginData: ResolverTypeWrapper<Partial<PluginData>>;
  PluginDataInput: ResolverTypeWrapper<Partial<PluginDataInput>>;
  PluginMimeTypeData: ResolverTypeWrapper<Partial<PluginMimeTypeData>>;
  PluginMimeTypeDataInput: ResolverTypeWrapper<Partial<PluginMimeTypeDataInput>>;
  Query: ResolverTypeWrapper<{}>;
  SasTokenResponse: ResolverTypeWrapper<Partial<SasTokenResponse>>;
  SocialProfile: ResolverTypeWrapper<Partial<SocialProfile>>;
  SocialProfileInput: ResolverTypeWrapper<Partial<SocialProfileInput>>;
  StoredAsset: ResolverTypeWrapper<Partial<StoredAsset>>;
  StoredAssetMetadata: ResolverTypeWrapper<Partial<StoredAssetMetadata>>;
  StoredAssetMetadataInput: ResolverTypeWrapper<Partial<StoredAssetMetadataInput>>;
  String: ResolverTypeWrapper<Partial<Scalars['String']['output']>>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionFeature: ResolverTypeWrapper<Partial<SubscriptionFeature>>;
  SubscriptionPlan: ResolverTypeWrapper<Partial<SubscriptionPlan>>;
  SubscriptionPlanName: ResolverTypeWrapper<Partial<SubscriptionPlanName>>;
  SubscriptionPlanPaddle: ResolverTypeWrapper<Partial<SubscriptionPlanPaddle>>;
  SubscriptionPlanPaddleType: ResolverTypeWrapper<Partial<SubscriptionPlanPaddleType>>;
  SubscriptionPlanPrice: ResolverTypeWrapper<Partial<SubscriptionPlanPrice>>;
  SubscriptionPlanPricePeriod: ResolverTypeWrapper<Partial<SubscriptionPlanPricePeriod>>;
  SubscriptionPlanType: ResolverTypeWrapper<Partial<SubscriptionPlanType>>;
  SuggestedLocationInput: ResolverTypeWrapper<Partial<SuggestedLocationInput>>;
  TLanguageKnown: ResolverTypeWrapper<Partial<TLanguageKnown>>;
  TouchSupport: ResolverTypeWrapper<Partial<TouchSupport>>;
  TouchSupportInput: ResolverTypeWrapper<Partial<TouchSupportInput>>;
  TransportGoodsContentType: ResolverTypeWrapper<Partial<TransportGoodsContentType>>;
  TravelDocumentType: ResolverTypeWrapper<Partial<TravelDocumentType>>;
  UpdateChatMessageInput: ResolverTypeWrapper<Partial<UpdateChatMessageInput>>;
  User: ResolverTypeWrapper<Partial<User>>;
  UserBackgroundDocument: ResolverTypeWrapper<Partial<UserBackgroundDocument>>;
  UserBackgroundDocumentInput: ResolverTypeWrapper<Partial<UserBackgroundDocumentInput>>;
  UserContact: ResolverTypeWrapper<Partial<UserContact>>;
  UserContactChanges: ResolverTypeWrapper<Partial<UserContactChanges>>;
  UserContactImportSourceType: ResolverTypeWrapper<Partial<UserContactImportSourceType>>;
  UserContactInput: ResolverTypeWrapper<Partial<UserContactInput>>;
  UserContext: ResolverTypeWrapper<Partial<UserContext>>;
  UserDevice: ResolverTypeWrapper<Partial<UserDevice>>;
  UserFrequentFlyerDocument: ResolverTypeWrapper<Partial<UserFrequentFlyerDocument>>;
  UserFrequentFlyerDocumentInput: ResolverTypeWrapper<Partial<UserFrequentFlyerDocumentInput>>;
  UserHealthReport: ResolverTypeWrapper<Partial<UserHealthReport>>;
  UserHealthReportInput: ResolverTypeWrapper<Partial<UserHealthReportInput>>;
  UserIdentityDocument: ResolverTypeWrapper<Partial<UserIdentityDocument>>;
  UserIdentityDocumentInput: ResolverTypeWrapper<Partial<UserIdentityDocumentInput>>;
  UserOtherDocument: ResolverTypeWrapper<Partial<UserOtherDocument>>;
  UserOtherDocumentInput: ResolverTypeWrapper<Partial<UserOtherDocumentInput>>;
  UserProfileInput: ResolverTypeWrapper<Partial<UserProfileInput>>;
  UserProfilePictureSize: ResolverTypeWrapper<Partial<UserProfilePictureSize>>;
  UserTravelDocument: ResolverTypeWrapper<Partial<UserTravelDocument>>;
  UserTravelDocumentInput: ResolverTypeWrapper<Partial<UserTravelDocumentInput>>;
  UserTravelDocumentSubTypeType: ResolverTypeWrapper<Partial<UserTravelDocumentSubTypeType>>;
  UserVaccinationDocument: ResolverTypeWrapper<Partial<UserVaccinationDocument>>;
  UserVaccinationDocumentInput: ResolverTypeWrapper<Partial<UserVaccinationDocumentInput>>;
  VaccinationTargetDisease: ResolverTypeWrapper<Partial<VaccinationTargetDisease>>;
  subscriptionFeatureType: ResolverTypeWrapper<Partial<SubscriptionFeatureType>>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AddDeviceLogInput: Partial<AddDeviceLogInput>;
  AddDeviceLogOutput: Partial<AddDeviceLogOutput>;
  AgencyCourierJobChatInput: Partial<AgencyCourierJobChatInput>;
  AgencyCourierUserProfile: Partial<AgencyCourierUserProfile>;
  AgencyCourierUserProfileCreateOrUpdateInput: Partial<AgencyCourierUserProfileCreateOrUpdateInput>;
  AgencyJob: Partial<AgencyJob>;
  AgencyJobChatInput: Partial<AgencyJobChatInput>;
  AgencyJobConnection: Partial<AgencyJobConnection>;
  AgencyJobEdge: Partial<AgencyJobEdge>;
  AgencyJobInput: Partial<AgencyJobInput>;
  AgencyJobPlanning: Partial<AgencyJobPlanning>;
  AgencyJobPlanningFile: Partial<AgencyJobPlanningFile>;
  AgencyJobPlanningForAgencyJobUpdateInput: Partial<AgencyJobPlanningForAgencyJobUpdateInput>;
  AgencyJobPlanningItem: Partial<AgencyJobPlanningItem>;
  AgencyJobPlanningItemHistoryLog: Partial<AgencyJobPlanningItemHistoryLog>;
  AgencyJobPlanningItemInput: Partial<AgencyJobPlanningItemInput>;
  AgencyJobPlanningItemUpdateInput: Partial<AgencyJobPlanningItemUpdateInput>;
  AgencyJobPlanningLeg: Partial<AgencyJobPlanningLeg>;
  AgencyJobPlanningLegInput: Partial<AgencyJobPlanningLegInput>;
  AgencyJobsForAgencyOrganizationInput: Partial<AgencyJobsForAgencyOrganizationInput>;
  AgencyOrganizationProfileInput: Partial<AgencyOrganizationProfileInput>;
  AgencyOrganizationVatIdInput: Partial<AgencyOrganizationVatIdInput>;
  AirportLocationConnection: Partial<AirportLocationConnection>;
  AirportLocationListInput: Partial<AirportLocationListInput>;
  AirportLocationSearchInput: Partial<AirportLocationSearchInput>;
  AirportLocationUpdateTimezoneInput: Partial<AirportLocationUpdateTimezoneInput>;
  AuthSignOutUserContextInput: Partial<AuthSignOutUserContextInput>;
  Availability: Partial<Availability>;
  AvailabilityLocation: Partial<AvailabilityLocation>;
  AvailabilityLocationWithDistance: Partial<AvailabilityLocationWithDistance>;
  AvailableCourierOrganizationUsersWithDistance: Partial<AvailableCourierOrganizationUsersWithDistance>;
  AzureADB2CToken: Partial<AzureAdb2CToken>;
  Boolean: Partial<Scalars['Boolean']['output']>;
  Chat: Partial<Chat>;
  ChatFile: Partial<ChatFile>;
  ChatFileInput: Partial<ChatFileInput>;
  ChatMessage: Partial<ChatMessage>;
  ChatMessageConnection: Partial<ChatMessageConnection>;
  ChatMessageCreateEdge: Partial<ChatMessageCreateEdge>;
  ChatMessageEdge: Partial<ChatMessageEdge>;
  ChatMessagesByChatIdInput: Partial<ChatMessagesByChatIdInput>;
  ClientJob: Partial<ClientJob>;
  ClientJobByAgencyInput: Partial<ClientJobByAgencyInput>;
  ConnectedOrganization: Partial<ConnectedOrganization>;
  Contact: Partial<Contact>;
  ContactBitrix24Company: Partial<ContactBitrix24Company>;
  ContactBitrix24Contact: Partial<ContactBitrix24Contact>;
  ContactBitrix24Data: Partial<ContactBitrix24Data>;
  ContactBitrix24Email: Partial<ContactBitrix24Email>;
  ContactBitrix24Phone: Partial<ContactBitrix24Phone>;
  ContactChanges: Partial<ContactChanges>;
  ContactPipedriveData: Partial<ContactPipedriveData>;
  ContactPipedriveOrganization: Partial<ContactPipedriveOrganization>;
  ContactPipedrivePerson: Partial<ContactPipedrivePerson>;
  CourierJob: Partial<CourierJob>;
  CourierOrganizationProfileInput: Partial<CourierOrganizationProfileInput>;
  CourierUserProfile: Partial<CourierUserProfile>;
  CourierUserProfileInput: Partial<CourierUserProfileInput>;
  CreateChatMessageInput: Partial<CreateChatMessageInput>;
  DateTime: Partial<Scalars['DateTime']['output']>;
  DeleteChatMessageInput: Partial<DeleteChatMessageInput>;
  Device: Partial<Device>;
  DeviceAppSettings: Partial<DeviceAppSettings>;
  DeviceAppSettingsInput: Partial<DeviceAppSettingsInput>;
  DeviceCanvas: Partial<DeviceCanvas>;
  DeviceCanvasInput: Partial<DeviceCanvasInput>;
  DeviceInput: Partial<DeviceInput>;
  DeviceLocalization: Partial<DeviceLocalization>;
  DeviceLocalizationInput: Partial<DeviceLocalizationInput>;
  DeviceLocation: Partial<DeviceLocation>;
  DeviceLocationInput: Partial<DeviceLocationInput>;
  DeviceLog: Partial<DeviceLog>;
  DeviceLogData: Partial<DeviceLogData>;
  DeviceLogDataInput: Partial<DeviceLogDataInput>;
  DeviceLogOutput: Partial<DeviceLogOutput>;
  DevicePermissionExpiration: Partial<Scalars['DevicePermissionExpiration']['output']>;
  DevicePermissions: Partial<DevicePermissions>;
  DevicePermissionsInput: Partial<DevicePermissionsInput>;
  DevicePermissionsNotifications: Partial<DevicePermissionsNotifications>;
  DevicePermissionsNotificationsAndroid: Partial<DevicePermissionsNotificationsAndroid>;
  DevicePermissionsNotificationsAndroidInput: Partial<DevicePermissionsNotificationsAndroidInput>;
  DevicePermissionsNotificationsInput: Partial<DevicePermissionsNotificationsInput>;
  DevicePermissionsNotificationsIos: Partial<DevicePermissionsNotificationsIos>;
  DevicePermissionsNotificationsIosInput: Partial<DevicePermissionsNotificationsIosInput>;
  DeviceProperties: Partial<DeviceProperties>;
  DevicePropertiesInput: Partial<DevicePropertiesInput>;
  EntityDeletionReturnType: Partial<EntityDeletionReturnType>;
  ExpoContact: Partial<ExpoContact>;
  ExpoContactAddress: Partial<ExpoContactAddress>;
  ExpoContactAddressInput: Partial<ExpoContactAddressInput>;
  ExpoContactDate: Partial<ExpoContactDate>;
  ExpoContactDateInput: Partial<ExpoContactDateInput>;
  ExpoContactEmail: Partial<ExpoContactEmail>;
  ExpoContactEmailInput: Partial<ExpoContactEmailInput>;
  ExpoContactImage: Partial<ExpoContactImage>;
  ExpoContactImageInput: Partial<ExpoContactImageInput>;
  ExpoContactInput: Partial<ExpoContactInput>;
  ExpoContactInstantMessageAddress: Partial<ExpoContactInstantMessageAddress>;
  ExpoContactInstantMessageAddressInput: Partial<ExpoContactInstantMessageAddressInput>;
  ExpoContactPhoneNumber: Partial<ExpoContactPhoneNumber>;
  ExpoContactPhoneNumberInput: Partial<ExpoContactPhoneNumberInput>;
  ExpoContactRelationship: Partial<ExpoContactRelationship>;
  ExpoContactRelationshipInput: Partial<ExpoContactRelationshipInput>;
  ExpoContactSocialProfile: Partial<ExpoContactSocialProfile>;
  ExpoContactSocialProfileInput: Partial<ExpoContactSocialProfileInput>;
  ExpoContactUrlAddress: Partial<ExpoContactUrlAddress>;
  ExpoContactUrlAddressInput: Partial<ExpoContactUrlAddressInput>;
  FilesUploadInput: Partial<FilesUploadInput>;
  Float: Partial<Scalars['Float']['output']>;
  HistoryLogPayment: Partial<HistoryLogPayment>;
  ID: Partial<Scalars['ID']['output']>;
  Int: Partial<Scalars['Int']['output']>;
  JSONObject: Partial<Scalars['JSONObject']['output']>;
  Job: Partial<Job>;
  JobInput: Partial<JobInput>;
  LanguagesKnownInput: Partial<LanguagesKnownInput>;
  Location: Partial<Location>;
  LocationAddressComponent: Partial<LocationAddressComponent>;
  LocationEdge: Partial<LocationEdge>;
  LocationGeometry: Partial<LocationGeometry>;
  LocationGeometryCoords: Partial<LocationGeometryCoords>;
  LocationGeometryGeoFence: Partial<LocationGeometryGeoFence>;
  LocationGeometryLatLngBounds: Partial<LocationGeometryLatLngBounds>;
  LocationGeometryLocation: Partial<LocationGeometryLocation>;
  LocationManualInput: Partial<LocationManualInput>;
  LocationPlusCode: Partial<LocationPlusCode>;
  MinSupportedVersion: Partial<MinSupportedVersion>;
  Mutation: {};
  Notification: Partial<Notification>;
  NotificationSetting: Partial<NotificationSetting>;
  NotificationSettingUpdateInput: Partial<NotificationSettingUpdateInput>;
  OrderArgs: Partial<OrderArgs>;
  Organization: Partial<Organization>;
  OrganizationContact: Partial<OrganizationContact>;
  OrganizationContactChanges: Partial<OrganizationContactChanges>;
  OrganizationCourierCategoriesUpdateInput: Partial<OrganizationCourierCategoriesUpdateInput>;
  OrganizationCourierCategory: Partial<OrganizationCourierCategory>;
  OrganizationCourierCategoryUpdate: Partial<OrganizationCourierCategoryUpdate>;
  OrganizationInvitation: Partial<OrganizationInvitation>;
  OrganizationInvitationBitrix24Deal: Partial<OrganizationInvitationBitrix24Deal>;
  OrganizationInvitationPipedriveDeal: Partial<OrganizationInvitationPipedriveDeal>;
  OrganizationInvitationSharing: Partial<OrganizationInvitationSharing>;
  OrganizationInvitationSharingInput: Partial<OrganizationInvitationSharingInput>;
  OrganizationInvitationUploadCSV: Partial<OrganizationInvitationUploadCsv>;
  OrganizationInvitationUploadCSVContact: Partial<OrganizationInvitationUploadCsvContact>;
  OrganizationInvitationUploadCSVInput: Partial<OrganizationInvitationUploadCsvInput>;
  OrganizationOrganization: Partial<OrganizationOrganization>;
  OrganizationSubscriptionPlan: Partial<OrganizationSubscriptionPlan>;
  OrganizationSubscriptionPlanPipedriveDeal: Partial<OrganizationSubscriptionPlanPipedriveDeal>;
  OrganizationSubscriptionPlanSingleInput: Partial<OrganizationSubscriptionPlanSingleInput>;
  OrganizationUser: Partial<OrganizationUser>;
  OrganizationUserUpdateProfileInput: Partial<OrganizationUserUpdateProfileInput>;
  PageInfo: Partial<PageInfo>;
  PaginationArgs: Partial<PaginationArgs>;
  PluginData: Partial<PluginData>;
  PluginDataInput: Partial<PluginDataInput>;
  PluginMimeTypeData: Partial<PluginMimeTypeData>;
  PluginMimeTypeDataInput: Partial<PluginMimeTypeDataInput>;
  Query: {};
  SasTokenResponse: Partial<SasTokenResponse>;
  SocialProfile: Partial<SocialProfile>;
  SocialProfileInput: Partial<SocialProfileInput>;
  StoredAsset: Partial<StoredAsset>;
  StoredAssetMetadata: Partial<StoredAssetMetadata>;
  StoredAssetMetadataInput: Partial<StoredAssetMetadataInput>;
  String: Partial<Scalars['String']['output']>;
  Subscription: {};
  SubscriptionFeature: Partial<SubscriptionFeature>;
  SubscriptionPlan: Partial<SubscriptionPlan>;
  SubscriptionPlanName: Partial<SubscriptionPlanName>;
  SubscriptionPlanPaddle: Partial<SubscriptionPlanPaddle>;
  SubscriptionPlanPaddleType: Partial<SubscriptionPlanPaddleType>;
  SubscriptionPlanPrice: Partial<SubscriptionPlanPrice>;
  SubscriptionPlanPricePeriod: Partial<SubscriptionPlanPricePeriod>;
  SuggestedLocationInput: Partial<SuggestedLocationInput>;
  TLanguageKnown: Partial<TLanguageKnown>;
  TouchSupport: Partial<TouchSupport>;
  TouchSupportInput: Partial<TouchSupportInput>;
  UpdateChatMessageInput: Partial<UpdateChatMessageInput>;
  User: Partial<User>;
  UserBackgroundDocument: Partial<UserBackgroundDocument>;
  UserBackgroundDocumentInput: Partial<UserBackgroundDocumentInput>;
  UserContact: Partial<UserContact>;
  UserContactChanges: Partial<UserContactChanges>;
  UserContactInput: Partial<UserContactInput>;
  UserContext: Partial<UserContext>;
  UserDevice: Partial<UserDevice>;
  UserFrequentFlyerDocument: Partial<UserFrequentFlyerDocument>;
  UserFrequentFlyerDocumentInput: Partial<UserFrequentFlyerDocumentInput>;
  UserHealthReport: Partial<UserHealthReport>;
  UserHealthReportInput: Partial<UserHealthReportInput>;
  UserIdentityDocument: Partial<UserIdentityDocument>;
  UserIdentityDocumentInput: Partial<UserIdentityDocumentInput>;
  UserOtherDocument: Partial<UserOtherDocument>;
  UserOtherDocumentInput: Partial<UserOtherDocumentInput>;
  UserProfileInput: Partial<UserProfileInput>;
  UserTravelDocument: Partial<UserTravelDocument>;
  UserTravelDocumentInput: Partial<UserTravelDocumentInput>;
  UserVaccinationDocument: Partial<UserVaccinationDocument>;
  UserVaccinationDocumentInput: Partial<UserVaccinationDocumentInput>;
}>;

export type AddDeviceLogOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddDeviceLogOutput'] = ResolversParentTypes['AddDeviceLogOutput'],
> = ResolversObject<{
  deviceLogIds?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  lastDeviceLog?: Resolver<Maybe<ResolversTypes['DeviceLogOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyCourierUserProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyCourierUserProfile'] = ResolversParentTypes['AgencyCourierUserProfile'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  internalComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  markedForAttention?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organizationAgency?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  organizationCourierCategories?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationCourierCategory']>>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyJob'] = ResolversParentTypes['AgencyJob'],
> = ResolversObject<{
  acceptedCourierJobsChat?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType>;
  additionalCostInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  agencyJobChat?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType>;
  agencyJobPlanning?: Resolver<Maybe<ResolversTypes['AgencyJobPlanning']>, ParentType, ContextType>;
  agencyJobStatusType?: Resolver<ResolversTypes['AgencyJobStatusType'], ParentType, ContextType>;
  allCourierJobsChat?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType>;
  cancelledByAgencyAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  checkInTimeAtEndAirport?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  checkInTimeAtStartAirport?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  clientDeclinedReasonType?: Resolver<
    Maybe<ResolversTypes['ClientDeclinedReasonType']>,
    ParentType,
    ContextType
  >;
  clientFixedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  clientJob?: Resolver<ResolversTypes['ClientJob'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confirmedFinishedByClientAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  courierJobs?: Resolver<Maybe<Array<ResolversTypes['CourierJob']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  dailyRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  endAirport?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  externalJobReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  finishedByAgencyAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fixedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hasIncident?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hotelBudget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  internalNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isAccommodationBookedByCourier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAccommodationPaidByCourier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdditionalCostPaidByCourier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCarRequiredForJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDrivingLicenseRequiredForJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  job?: Resolver<ResolversTypes['Job'], ParentType, ContextType>;
  jobDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kickOffFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numOfCouriersRequired?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  numOfItems?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  relationshipType?: Resolver<
    ResolversTypes['AgencyJobClientRelationshipType'],
    ParentType,
    ContextType
  >;
  responsibleAgencyOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  responsibleAgencyOrganizationUsers?: Resolver<
    Array<ResolversTypes['OrganizationUser']>,
    ParentType,
    ContextType
  >;
  responsibleAgencyUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  returnTimeAtEndAirport?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  returnTimeAtStartAirport?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startAirport?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  startedByAgencyAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weightOfItems?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyJobConnection'] = ResolversParentTypes['AgencyJobConnection'],
> = ResolversObject<{
  edges?: Resolver<Maybe<Array<ResolversTypes['AgencyJobEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AgencyJobEdge'] = ResolversParentTypes['AgencyJobEdge'],
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AgencyJob'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobPlanningResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyJobPlanning'] = ResolversParentTypes['AgencyJobPlanning'],
> = ResolversObject<{
  agencyJob?: Resolver<ResolversTypes['AgencyJob'], ParentType, ContextType>;
  agencyJobPlanningItems?: Resolver<
    Array<ResolversTypes['AgencyJobPlanningItem']>,
    ParentType,
    ContextType
  >;
  agencyJobPlanningLegs?: Resolver<
    Array<ResolversTypes['AgencyJobPlanningLeg']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobPlanningFileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyJobPlanningFile'] = ResolversParentTypes['AgencyJobPlanningFile'],
> = ResolversObject<{
  agencyJobPlanningItem?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningLeg']>,
    ParentType,
    ContextType
  >;
  agencyJobPlanningItemHistoryLog?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningItemHistoryLog']>,
    ParentType,
    ContextType
  >;
  agencyJobPlanningLeg?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningLeg']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  fileBlobMetadata?: Resolver<ResolversTypes['StoredAssetMetadata'], ParentType, ContextType>;
  fileBlobPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileContainerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  storedAsset?: Resolver<Maybe<ResolversTypes['StoredAsset']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobPlanningItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyJobPlanningItem'] = ResolversParentTypes['AgencyJobPlanningItem'],
> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  actualDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  actualDateTimeSetAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  agencyJobPlanning?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningItem']>,
    ParentType,
    ContextType
  >;
  agencyJobPlanningItemHistoryLogs?: Resolver<
    Maybe<Array<ResolversTypes['AgencyJobPlanningItemHistoryLog']>>,
    ParentType,
    ContextType
  >;
  agencyJobPlanningLeg?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningLeg']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['AgencyJobPlanningFile']>, ParentType, ContextType>;
  formType?: Resolver<ResolversTypes['AgencyJobPlanningFormType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  targetDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobPlanningItemHistoryLogResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyJobPlanningItemHistoryLog'] = ResolversParentTypes['AgencyJobPlanningItemHistoryLog'],
> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  actualDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  actualDateTimeSetAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  agencyJobPlanningItem?: Resolver<
    ResolversTypes['AgencyJobPlanningItem'],
    ParentType,
    ContextType
  >;
  agencyJobPlanningLeg?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningLeg']>,
    ParentType,
    ContextType
  >;
  chatMessage?: Resolver<Maybe<ResolversTypes['ChatMessage']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  files?: Resolver<Array<ResolversTypes['AgencyJobPlanningFile']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  targetDateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AgencyJobPlanningLegResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AgencyJobPlanningLeg'] = ResolversParentTypes['AgencyJobPlanningLeg'],
> = ResolversObject<{
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  agencyJobPlanning?: Resolver<Maybe<ResolversTypes['AgencyJobPlanning']>, ParentType, ContextType>;
  agencyJobPlanningItemHistoryLogs?: Resolver<
    Array<ResolversTypes['AgencyJobPlanningItemHistoryLog']>,
    ParentType,
    ContextType
  >;
  agencyJobPlanningItems?: Resolver<
    Array<ResolversTypes['AgencyJobPlanningItem']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  departureDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  flightCarrierDesignation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flightNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  flightTickets?: Resolver<Array<ResolversTypes['AgencyJobPlanningFile']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AirportLocationConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AirportLocationConnection'] = ResolversParentTypes['AirportLocationConnection'],
> = ResolversObject<{
  edges?: Resolver<Maybe<Array<ResolversTypes['LocationEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AvailabilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Availability'] = ResolversParentTypes['Availability'],
> = ResolversObject<{
  availabilityLocations?: Resolver<
    Maybe<Array<ResolversTypes['AvailabilityLocation']>>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isAdHocAvailability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastDeviceLocationForDevice?: Resolver<
    Maybe<ResolversTypes['DeviceLocation']>,
    ParentType,
    ContextType
  >;
  lastDeviceLocationWithLocationForUser?: Resolver<
    Maybe<ResolversTypes['DeviceLocation']>,
    ParentType,
    ContextType
  >;
  lastLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastLocationForDevice?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  lastLocationForUser?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  notificationAgencyAdHocAvailabilityCreatedSentAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  timeZoneDestinationOffset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timeZoneIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeZoneRawOffset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AvailabilityLocationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AvailabilityLocation'] = ResolversParentTypes['AvailabilityLocation'],
> = ResolversObject<{
  availability?: Resolver<ResolversTypes['Availability'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isDerivedFromDeviceLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AvailabilityLocationWithDistanceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AvailabilityLocationWithDistance'] = ResolversParentTypes['AvailabilityLocationWithDistance'],
> = ResolversObject<{
  availabilityLocation?: Resolver<ResolversTypes['AvailabilityLocation'], ParentType, ContextType>;
  distance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  groupType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AvailableCourierOrganizationUsersWithDistanceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AvailableCourierOrganizationUsersWithDistance'] = ResolversParentTypes['AvailableCourierOrganizationUsersWithDistance'],
> = ResolversObject<{
  availabilityLocationsWithDistance?: Resolver<
    Array<ResolversTypes['AvailabilityLocationWithDistance']>,
    ParentType,
    ContextType
  >;
  courierJob?: Resolver<Maybe<ResolversTypes['CourierJob']>, ParentType, ContextType>;
  displayAvailabilityLocationWithDistance?: Resolver<
    Maybe<ResolversTypes['AvailabilityLocationWithDistance']>,
    ParentType,
    ContextType
  >;
  lastAvailabilityLocationWithDistance?: Resolver<
    Maybe<ResolversTypes['AvailabilityLocationWithDistance']>,
    ParentType,
    ContextType
  >;
  organizationUsers?: Resolver<Array<ResolversTypes['OrganizationUser']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AzureAdb2CTokenResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AzureADB2CToken'] = ResolversParentTypes['AzureADB2CToken'],
> = ResolversObject<{
  aud?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  azp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emails?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  exp?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  familyName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  givenName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  iat?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  iss?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nbf?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  oid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sub?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tfp?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ver?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Chat'] = ResolversParentTypes['Chat'],
> = ResolversObject<{
  acceptedCourierJobsForAgencyJob?: Resolver<ResolversTypes['AgencyJob'], ParentType, ContextType>;
  agencyJob?: Resolver<Maybe<ResolversTypes['AgencyJob']>, ParentType, ContextType>;
  allCourierJobsForAgencyJob?: Resolver<ResolversTypes['AgencyJob'], ParentType, ContextType>;
  chatType?: Resolver<ResolversTypes['ChatType'], ParentType, ContextType>;
  courierJob?: Resolver<Maybe<ResolversTypes['CourierJob']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatFileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatFile'] = ResolversParentTypes['ChatFile'],
> = ResolversObject<{
  chatMessage?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  fileBlobMetadata?: Resolver<ResolversTypes['StoredAssetMetadata'], ParentType, ContextType>;
  fileBlobPath?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fileContainerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatMessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChatMessage'] = ResolversParentTypes['ChatMessage'],
> = ResolversObject<{
  agencyJobPlanningItemHistoryLog?: Resolver<
    Maybe<ResolversTypes['AgencyJobPlanningItemHistoryLog']>,
    ParentType,
    ContextType
  >;
  chat?: Resolver<ResolversTypes['Chat'], ParentType, ContextType>;
  chatFile?: Resolver<Maybe<ResolversTypes['StoredAsset']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  messageType?: Resolver<ResolversTypes['MessageType'], ParentType, ContextType>;
  sendingUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatMessageConnectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChatMessageConnection'] = ResolversParentTypes['ChatMessageConnection'],
> = ResolversObject<{
  edges?: Resolver<Maybe<Array<ResolversTypes['ChatMessageEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatMessageCreateEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChatMessageCreateEdge'] = ResolversParentTypes['ChatMessageCreateEdge'],
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChatMessageEdgeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ChatMessageEdge'] = ResolversParentTypes['ChatMessageEdge'],
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ChatMessage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ClientJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClientJob'] = ResolversParentTypes['ClientJob'],
> = ResolversObject<{
  agencyJobs?: Resolver<Maybe<Array<ResolversTypes['AgencyJob']>>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  externalJobReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fixedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hasIncident?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  job?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  organizationEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationLegalName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  responsibleAgencyUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  responsibleClientOrganization?: Resolver<
    Maybe<ResolversTypes['Organization']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConnectedOrganizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConnectedOrganization'] = ResolversParentTypes['ConnectedOrganization'],
> = ResolversObject<{
  connectionType?: Resolver<
    ResolversTypes['OrganizationOrganizationConnectionType'],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  usedOrganizationInvitation?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact'],
> = ResolversObject<{
  bitrix24Data?: Resolver<Maybe<ResolversTypes['ContactBitrix24Data']>, ParentType, ContextType>;
  changes?: Resolver<Array<ResolversTypes['ContactChanges']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  importSource?: Resolver<
    Maybe<ResolversTypes['UserContactImportSourceType']>,
    ParentType,
    ContextType
  >;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isAgencyContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isClientContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCourierContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOtherContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPowerAutomateContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPriojetContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isServiceProviderContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTravelerContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationContacts?: Resolver<
    Array<ResolversTypes['OrganizationContact']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pipedriveData?: Resolver<Maybe<ResolversTypes['ContactPipedriveData']>, ParentType, ContextType>;
  syncedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBitrix24CompanyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactBitrix24Company'] = ResolversParentTypes['ContactBitrix24Company'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<Array<ResolversTypes['ContactBitrix24Email']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  organizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationType?: Resolver<ResolversTypes['OrganizationType'], ParentType, ContextType>;
  phone?: Resolver<Array<ResolversTypes['ContactBitrix24Phone']>, ParentType, ContextType>;
  syncedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBitrix24ContactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactBitrix24Contact'] = ResolversParentTypes['ContactBitrix24Contact'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<Array<ResolversTypes['ContactBitrix24Email']>>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Array<ResolversTypes['ContactBitrix24Phone']>, ParentType, ContextType>;
  syncedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBitrix24DataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactBitrix24Data'] = ResolversParentTypes['ContactBitrix24Data'],
> = ResolversObject<{
  companies?: Resolver<Array<ResolversTypes['ContactBitrix24Company']>, ParentType, ContextType>;
  contact?: Resolver<ResolversTypes['ContactBitrix24Contact'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBitrix24EmailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactBitrix24Email'] = ResolversParentTypes['ContactBitrix24Email'],
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactBitrix24PhoneResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactBitrix24Phone'] = ResolversParentTypes['ContactBitrix24Phone'],
> = ResolversObject<{
  id?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactChangesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactChanges'] = ResolversParentTypes['ContactChanges'],
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactPipedriveDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactPipedriveData'] = ResolversParentTypes['ContactPipedriveData'],
> = ResolversObject<{
  organizations?: Resolver<
    Array<ResolversTypes['ContactPipedriveOrganization']>,
    ParentType,
    ContextType
  >;
  person?: Resolver<ResolversTypes['ContactPipedrivePerson'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactPipedriveOrganizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactPipedriveOrganization'] = ResolversParentTypes['ContactPipedriveOrganization'],
> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationType?: Resolver<ResolversTypes['OrganizationType'], ParentType, ContextType>;
  syncedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ContactPipedrivePersonResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ContactPipedrivePerson'] = ResolversParentTypes['ContactPipedrivePerson'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  syncedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CourierJobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CourierJob'] = ResolversParentTypes['CourierJob'],
> = ResolversObject<{
  additionalCostInformation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  agencyJob?: Resolver<ResolversTypes['AgencyJob'], ParentType, ContextType>;
  cancelledByCourierAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  confirmedFinishedByAgencyAt?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  courierJobAgencyJobChat?: Resolver<Maybe<ResolversTypes['Chat']>, ParentType, ContextType>;
  courierJobStatusType?: Resolver<ResolversTypes['CourierJobStatusType'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dailyRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  declineReasonType?: Resolver<
    Maybe<ResolversTypes['AgencyDeclinedReasonType']>,
    ParentType,
    ContextType
  >;
  finishedByCourierAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  fixedRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hasIncident?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hotelBudget?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isAccommodationBookedByCourier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAccommodationPaidByCourier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isAdditionalCostPaidByCourier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  job?: Resolver<ResolversTypes['Job'], ParentType, ContextType>;
  kickOffFee?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  relationshipType?: Resolver<
    ResolversTypes['CourierJobAgencyRelationshipType'],
    ParentType,
    ContextType
  >;
  relationshipTypeChangedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  responsibleCourierOrganization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType
  >;
  responsibleCourierUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  startedByCourierAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CourierUserProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CourierUserProfile'] = ResolversParentTypes['CourierUserProfile'],
> = ResolversObject<{
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amexCreditCardCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  amexCreditCardLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  apartmentOrSuite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  baseAirportLocations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  baseAirports?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dinerCreditCardCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  dinerCreditCardLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  emergencyContactAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emergencyContactEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emergencyContactName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emergencyContactPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emergencyContactRelationship?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstObcJobDateAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hasAmexCreditCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasAmexDebitCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasDinerCreditCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasDinerDebitCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMasterCreditCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMasterDebitCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasMedicalObcExperience?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasObcExperience?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasVisaCreditCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasVisaDebitCard?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  languagesKnown?: Resolver<
    Maybe<Array<ResolversTypes['TLanguageKnown']>>,
    ParentType,
    ContextType
  >;
  masterCreditCardCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  masterCreditCardLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  numberOfPastObcJobs?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  onboardExperiences?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  socialProfiles?: Resolver<Array<ResolversTypes['SocialProfile']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  taxCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  visaCreditCardCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  visaCreditCardLimit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device'],
> = ResolversObject<{
  appSettings?: Resolver<ResolversTypes['DeviceAppSettings'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deviceLocations?: Resolver<
    Maybe<Array<ResolversTypes['DeviceLocation']>>,
    ParentType,
    ContextType
  >;
  deviceLogs?: Resolver<Maybe<Array<ResolversTypes['DeviceLog']>>, ParentType, ContextType>;
  deviceProperties?: Resolver<ResolversTypes['DeviceProperties'], ParentType, ContextType>;
  deviceType?: Resolver<ResolversTypes['DeviceCategoryType'], ParentType, ContextType>;
  expoPushToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  localization?: Resolver<ResolversTypes['DeviceLocalization'], ParentType, ContextType>;
  permissions?: Resolver<ResolversTypes['DevicePermissions'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userDevices?: Resolver<Maybe<Array<ResolversTypes['UserDevice']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceAppSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceAppSettings'] = ResolversParentTypes['DeviceAppSettings'],
> = ResolversObject<{
  appLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceCanvasResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceCanvas'] = ResolversParentTypes['DeviceCanvas'],
> = ResolversObject<{
  geometry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  winding?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceLocalizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceLocalization'] = ResolversParentTypes['DeviceLocalization'],
> = ResolversObject<{
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  decimalSeparator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  digitGroupingSeparator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isMetric?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRTL?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isoCurrencyCodes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  locale?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locales?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceLocationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceLocation'] = ResolversParentTypes['DeviceLocation'],
> = ResolversObject<{
  accuracy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  altitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  altitudeAccuracy?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  heading?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isLocationFound?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  longitude?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  speed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  timestampInMs?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceLogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceLog'] = ResolversParentTypes['DeviceLog'],
> = ResolversObject<{
  accessTokenHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['DeviceLogData']>, ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  internetServiceProvider?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddressCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddressCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddressLat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ipAddressLong?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ipAddressRegion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddressType?: Resolver<Maybe<ResolversTypes['IpAddressType']>, ParentType, ContextType>;
  isAuthenticatedMutation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCurrentUserContextAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  triggerType?: Resolver<ResolversTypes['DeviceLogTriggerType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceLogDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceLogData'] = ResolversParentTypes['DeviceLogData'],
> = ResolversObject<{
  androidId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appLastUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  appState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  appVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  availableLocationProviders?: Resolver<
    Maybe<ResolversTypes['JSONObject']>,
    ParentType,
    ContextType
  >;
  batteryLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  batteryState?: Resolver<
    Maybe<ResolversTypes['DeviceLogBatteryStateType']>,
    ParentType,
    ContextType
  >;
  carrier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  freeDiskStorage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ipAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAirplaneModeEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  lowPowerMode?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  maxMemory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  networkStateIsConnected?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  networkStateIsInternetReachable?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  networkStateType?: Resolver<
    Maybe<ResolversTypes['DeviceLogNetworkStateType']>,
    ParentType,
    ContextType
  >;
  totalDiskCapacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  uptime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DeviceLogOutputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceLogOutput'] = ResolversParentTypes['DeviceLogOutput'],
> = ResolversObject<{
  data?: Resolver<Maybe<ResolversTypes['DeviceLogData']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ipAddressCity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddressCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  triggerType?: Resolver<ResolversTypes['DeviceLogTriggerType'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DevicePermissionExpirationScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DevicePermissionExpiration'], any> {
  name: 'DevicePermissionExpiration';
}

export type DevicePermissionsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DevicePermissions'] = ResolversParentTypes['DevicePermissions'],
> = ResolversObject<{
  notifications?: Resolver<
    ResolversTypes['DevicePermissionsNotifications'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DevicePermissionsNotificationsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DevicePermissionsNotifications'] = ResolversParentTypes['DevicePermissionsNotifications'],
> = ResolversObject<{
  android?: Resolver<
    Maybe<ResolversTypes['DevicePermissionsNotificationsAndroid']>,
    ParentType,
    ContextType
  >;
  canAskAgain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expires?: Resolver<ResolversTypes['DevicePermissionExpiration'], ParentType, ContextType>;
  granted?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ios?: Resolver<
    Maybe<ResolversTypes['DevicePermissionsNotificationsIos']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<ResolversTypes['DevicePermissionStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DevicePermissionsNotificationsAndroidResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DevicePermissionsNotificationsAndroid'] = ResolversParentTypes['DevicePermissionsNotificationsAndroid'],
> = ResolversObject<{
  importance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  interruptionFilter?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DevicePermissionsNotificationsIosResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DevicePermissionsNotificationsIos'] = ResolversParentTypes['DevicePermissionsNotificationsIos'],
> = ResolversObject<{
  alertStyle?: Resolver<
    ResolversTypes['DevicePermissionsNotificationsIosAlertStyle'],
    ParentType,
    ContextType
  >;
  allowsAlert?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowsAnnouncements?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowsBadge?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowsCriticalAlerts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowsDisplayInCarPlay?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowsDisplayInNotificationCenter?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  allowsDisplayOnLockScreen?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  allowsPreviews?: Resolver<
    Maybe<ResolversTypes['DevicePermissionsNotificationsIosAllowsPreviews']>,
    ParentType,
    ContextType
  >;
  allowsSound?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  providesAppNotificationSettings?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType
  >;
  status?: Resolver<
    ResolversTypes['DevicePermissionsNotificationsIosAuthorizationStatus'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DevicePropertiesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceProperties'] = ResolversParentTypes['DeviceProperties'],
> = ResolversObject<{
  androidId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicationId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  applicationName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  audio?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  brand?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  canvas?: Resolver<Maybe<ResolversTypes['DeviceCanvas']>, ParentType, ContextType>;
  chrome?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  colorDepth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cookiesEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cpuClass?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  designName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceYearClass?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  emptyValLength?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  errorFF?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  expoDeviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expoVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fonts?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  hardwareConcurrency?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  indexedDb?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  installReferrer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  installationTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  iosApplicationReleaseType?: Resolver<
    Maybe<ResolversTypes['DeviceApplicationReleaseType']>,
    ParentType,
    ContextType
  >;
  iosIdForVendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iosPushNotificationServiceEnvironment?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  isDevice?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLimitAdTrackingEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isRooted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isSideLoadingEnabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<Array<Array<ResolversTypes['String']>>>, ParentType, ContextType>;
  lastUpdateTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  localStorage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  manufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxMemory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  modelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nativeApplicationVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nativeBuildVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  openDatabase?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  osBuildFingerprint?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osBuildId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osCpu?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osInternalBuild?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  osVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platform?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  platformApiLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  platformFeatures?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  pluginSupport?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  plugins?: Resolver<Maybe<Array<ResolversTypes['PluginData']>>, ParentType, ContextType>;
  productName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  productSub?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  screenResolution?: Resolver<Maybe<Array<ResolversTypes['Float']>>, ParentType, ContextType>;
  screenResolutionAvailable?: Resolver<
    Maybe<Array<ResolversTypes['Float']>>,
    ParentType,
    ContextType
  >;
  sessionStorage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  supportedCpuArchitectures?: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezoneOffset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalMemory?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  touchSupport?: Resolver<Maybe<ResolversTypes['TouchSupport']>, ParentType, ContextType>;
  userAgent?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  webdriver?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EntityDeletionReturnTypeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EntityDeletionReturnType'] = ResolversParentTypes['EntityDeletionReturnType'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExpoContact'] = ResolversParentTypes['ExpoContact'],
> = ResolversObject<{
  addresses?: Resolver<Maybe<Array<ResolversTypes['ExpoContactAddress']>>, ParentType, ContextType>;
  birthday?: Resolver<Maybe<ResolversTypes['ExpoContactDate']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contactType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dates?: Resolver<Maybe<Array<ResolversTypes['ExpoContactDate']>>, ParentType, ContextType>;
  department?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<ResolversTypes['ExpoContactEmail']>>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['ExpoContactImage']>, ParentType, ContextType>;
  imageAvailable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  instantMessageAddresses?: Resolver<
    Maybe<Array<ResolversTypes['ExpoContactInstantMessageAddress']>>,
    ParentType,
    ContextType
  >;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lookupKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maidenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  middleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  namePrefix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameSuffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nonGregorianBirthday?: Resolver<
    Maybe<ResolversTypes['ExpoContactDate']>,
    ParentType,
    ContextType
  >;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumbers?: Resolver<
    Maybe<Array<ResolversTypes['ExpoContactPhoneNumber']>>,
    ParentType,
    ContextType
  >;
  phoneticFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneticLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneticMiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rawImage?: Resolver<Maybe<ResolversTypes['ExpoContactImage']>, ParentType, ContextType>;
  relationships?: Resolver<
    Maybe<Array<ResolversTypes['ExpoContactRelationship']>>,
    ParentType,
    ContextType
  >;
  socialProfiles?: Resolver<
    Maybe<Array<ResolversTypes['ExpoContactSocialProfile']>>,
    ParentType,
    ContextType
  >;
  urlAddresses?: Resolver<
    Maybe<Array<ResolversTypes['ExpoContactUrlAddress']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactAddressResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactAddress'] = ResolversParentTypes['ExpoContactAddress'],
> = ResolversObject<{
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isoCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  neighborhood?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  poBox?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactDateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactDate'] = ResolversParentTypes['ExpoContactDate'],
> = ResolversObject<{
  day?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  format?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  month?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  year?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactEmailResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactEmail'] = ResolversParentTypes['ExpoContactEmail'],
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactImageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactImage'] = ResolversParentTypes['ExpoContactImage'],
> = ResolversObject<{
  base64?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactInstantMessageAddressResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactInstantMessageAddress'] = ResolversParentTypes['ExpoContactInstantMessageAddress'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localizedService?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactPhoneNumberResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactPhoneNumber'] = ResolversParentTypes['ExpoContactPhoneNumber'],
> = ResolversObject<{
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  digits?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isPrimary?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactRelationshipResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactRelationship'] = ResolversParentTypes['ExpoContactRelationship'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactSocialProfileResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactSocialProfile'] = ResolversParentTypes['ExpoContactSocialProfile'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  localizedProfile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExpoContactUrlAddressResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExpoContactUrlAddress'] = ResolversParentTypes['ExpoContactUrlAddress'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HistoryLogPaymentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HistoryLogPayment'] = ResolversParentTypes['HistoryLogPayment'],
> = ResolversObject<{
  alertId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  alertName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balanceCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balanceEarnings?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balanceFee?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balanceGross?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  balance_tax?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cancellationEffectiveDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  checkout_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coupon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  earnings?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  eventTime?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fee?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  initialPayment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instalments?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkedSubscriptions?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  marketingConsent?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  nextBillDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextPaymentAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orderId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['OrganizationSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  passthrough?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMethod?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentTax?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  planName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  receiptUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  saleGross?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionPaymentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionPlanId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonObjectScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type JobResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job'],
> = ResolversObject<{
  agencyJobs?: Resolver<Maybe<Array<ResolversTypes['AgencyJob']>>, ParentType, ContextType>;
  clientJobs?: Resolver<Maybe<Array<ResolversTypes['ClientJob']>>, ParentType, ContextType>;
  courierJobs?: Resolver<Maybe<Array<ResolversTypes['CourierJob']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  createdByUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  descriptionOfItems?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dropOffAddressDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dropOffLocation?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  dropOffTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  jobType?: Resolver<ResolversTypes['JobType'], ParentType, ContextType>;
  numOfItems?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pickupAddressDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pickupLocation?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  pickupTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  priojetJobIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transportedGoodsContentType?: Resolver<
    ResolversTypes['TransportGoodsContentType'],
    ParentType,
    ContextType
  >;
  typeOfDelivery?: Resolver<ResolversTypes['JobTypeOfDelivery'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weightOfItems?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location'],
> = ResolversObject<{
  addressComponents?: Resolver<
    Maybe<Array<ResolversTypes['LocationAddressComponent']>>,
    ParentType,
    ContextType
  >;
  addressName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  airportLocationData?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  apartmentOrSuite?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  availabilityLocations?: Resolver<
    Maybe<Array<ResolversTypes['AvailabilityLocation']>>,
    ParentType,
    ContextType
  >;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  dataLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceLocations?: Resolver<
    Maybe<Array<ResolversTypes['DeviceLocation']>>,
    ParentType,
    ContextType
  >;
  formattedAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedAirportAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedMinimalAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formattedShortAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  iataCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isPrivate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  jsonGeocodeResults?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  locationGeometry?: Resolver<ResolversTypes['LocationGeometry'], ParentType, ContextType>;
  locationType?: Resolver<ResolversTypes['LocationType'], ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plusCode?: Resolver<Maybe<ResolversTypes['LocationPlusCode']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postcodeLocalities?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeZoneIdentifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeZoneRawOffset?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  types?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userRegisteredAddressLocation?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationAddressComponentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationAddressComponent'] = ResolversParentTypes['LocationAddressComponent'],
> = ResolversObject<{
  longName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  types?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationEdge'] = ResolversParentTypes['LocationEdge'],
> = ResolversObject<{
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Location'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationGeometryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationGeometry'] = ResolversParentTypes['LocationGeometry'],
> = ResolversObject<{
  bounds?: Resolver<Maybe<ResolversTypes['LocationGeometryLatLngBounds']>, ParentType, ContextType>;
  geoFence?: Resolver<Maybe<ResolversTypes['LocationGeometryGeoFence']>, ParentType, ContextType>;
  location?: Resolver<ResolversTypes['LocationGeometryLocation'], ParentType, ContextType>;
  locationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  viewport?: Resolver<
    Maybe<ResolversTypes['LocationGeometryLatLngBounds']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationGeometryCoordsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationGeometryCoords'] = ResolversParentTypes['LocationGeometryCoords'],
> = ResolversObject<{
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationGeometryGeoFenceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationGeometryGeoFence'] = ResolversParentTypes['LocationGeometryGeoFence'],
> = ResolversObject<{
  coords?: Resolver<Array<ResolversTypes['LocationGeometryCoords']>, ParentType, ContextType>;
  geoFenceType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  radius?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationGeometryLatLngBoundsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationGeometryLatLngBounds'] = ResolversParentTypes['LocationGeometryLatLngBounds'],
> = ResolversObject<{
  northeast?: Resolver<ResolversTypes['LocationGeometryCoords'], ParentType, ContextType>;
  southwest?: Resolver<ResolversTypes['LocationGeometryCoords'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationGeometryLocationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationGeometryLocation'] = ResolversParentTypes['LocationGeometryLocation'],
> = ResolversObject<{
  alt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationPlusCodeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LocationPlusCode'] = ResolversParentTypes['LocationPlusCode'],
> = ResolversObject<{
  compoundCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  globalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MinSupportedVersionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MinSupportedVersion'] = ResolversParentTypes['MinSupportedVersion'],
> = ResolversObject<{
  currentAppVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minSupportedAppVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = ResolversObject<{
  activateAccount?: Resolver<
    ResolversTypes['UserContext'],
    ParentType,
    ContextType,
    RequireFields<MutationActivateAccountArgs, 'activationCode'>
  >;
  addDeviceLog?: Resolver<
    ResolversTypes['AddDeviceLogOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationAddDeviceLogArgs, 'addDeviceLogInputs'>
  >;
  addDeviceLogUnauthenticated?: Resolver<
    ResolversTypes['AddDeviceLogOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationAddDeviceLogUnauthenticatedArgs, 'addDeviceLogInputs'>
  >;
  addOrDisableCourierForCourierJob?: Resolver<
    Array<ResolversTypes['CourierJob']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationAddOrDisableCourierForCourierJobArgs,
      'agencyJobId' | 'courierOrganizationUserIds'
    >
  >;
  addOrDisableCourierJob?: Resolver<
    Array<ResolversTypes['AvailableCourierOrganizationUsersWithDistance']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddOrDisableCourierJobArgs, 'agencyJobId' | 'courierOrganizationUserIds'>
  >;
  agencyCourierUserProfileCreateOrUpdate?: Resolver<
    ResolversTypes['AgencyCourierUserProfile'],
    ParentType,
    ContextType,
    RequireFields<MutationAgencyCourierUserProfileCreateOrUpdateArgs, 'data'>
  >;
  agencyJobPlanningForAgencyJobUpdate?: Resolver<
    ResolversTypes['AgencyJobPlanning'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAgencyJobPlanningForAgencyJobUpdateArgs,
      'agencyJobId' | 'agencyJobPlanningForAgencyJobUpdateInput' | 'agencyJobPlanningId'
    >
  >;
  agencyJobPlanningItemReset?: Resolver<
    ResolversTypes['AgencyJobPlanningItem'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAgencyJobPlanningItemResetArgs,
      'agencyJobPlanningId' | 'agencyJobPlanningItemId'
    >
  >;
  agencyJobPlanningItemUpdate?: Resolver<
    ResolversTypes['AgencyJobPlanningItem'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAgencyJobPlanningItemUpdateArgs,
      'agencyJobPlanningId' | 'agencyJobPlanningItemUpdateInput'
    >
  >;
  agencyJobUpdateInternalNotes?: Resolver<
    ResolversTypes['AgencyJob'],
    ParentType,
    ContextType,
    RequireFields<MutationAgencyJobUpdateInternalNotesArgs, 'agencyJobId' | 'internalNotes'>
  >;
  airportLocationUpdateTimezone?: Resolver<
    ResolversTypes['Location'],
    ParentType,
    ContextType,
    RequireFields<MutationAirportLocationUpdateTimezoneArgs, 'data'>
  >;
  authSignOutUserContext?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationAuthSignOutUserContextArgs, 'data'>
  >;
  changeAgencyJobStatusTypeForAgencyOrganization?: Resolver<
    ResolversTypes['AgencyJob'],
    ParentType,
    ContextType,
    RequireFields<
      MutationChangeAgencyJobStatusTypeForAgencyOrganizationArgs,
      'agencyJobId' | 'agencyJobStatusType'
    >
  >;
  connectWithAgencyOrganizationAsCourierOrganization?: Resolver<
    ResolversTypes['OrganizationOrganization'],
    ParentType,
    ContextType,
    RequireFields<
      MutationConnectWithAgencyOrganizationAsCourierOrganizationArgs,
      'activationCode' | 'agencyOrganizationId' | 'courierOrganizationId'
    >
  >;
  createAvailability?: Resolver<
    ResolversTypes['Availability'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateAvailabilityArgs,
      | 'endTime'
      | 'startTime'
      | 'suggestedLocationInput'
      | 'timeZoneDestinationOffset'
      | 'timeZoneIdentifier'
      | 'timeZoneRawOffset'
    >
  >;
  createChatMessage?: Resolver<
    ResolversTypes['ChatMessageCreateEdge'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateChatMessageArgs, 'createChatMessageInput'>
  >;
  createDeviceLocations?: Resolver<
    Maybe<ResolversTypes['DeviceLocation']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateDeviceLocationsArgs, 'deviceLocationInputs'>
  >;
  createOrUpdateAgencyEmployeeUserContact?: Resolver<
    ResolversTypes['UserContact'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateOrUpdateAgencyEmployeeUserContactArgs,
      'inviteUserContact' | 'organizationUserRole' | 'userContactInput'
    >
  >;
  createOrUpdateCourierUserContact?: Resolver<
    ResolversTypes['UserContact'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateOrUpdateCourierUserContactArgs,
      'inviteUserContact' | 'userContactInput'
    >
  >;
  createOrUpdateJobForAgencyOrganization?: Resolver<
    ResolversTypes['AgencyJob'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateOrUpdateJobForAgencyOrganizationArgs,
      'agencyJobInput' | 'clientJobByAgencyInput' | 'jobInput'
    >
  >;
  createOrganizationInvitationAsPriojet?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateOrganizationInvitationAsPriojetArgs,
      'organizationInvitationType' | 'reservedComment'
    >
  >;
  createOrganizationInvitationSharing?: Resolver<
    ResolversTypes['OrganizationInvitationSharing'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateOrganizationInvitationSharingArgs,
      'organizationInvitationSharingInput'
    >
  >;
  createUserBackgroundDocument?: Resolver<
    ResolversTypes['UserBackgroundDocument'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserBackgroundDocumentArgs, 'userBackgroundDocumentInput'>
  >;
  createUserFrequentFlyerDocument?: Resolver<
    ResolversTypes['UserFrequentFlyerDocument'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserFrequentFlyerDocumentArgs, 'userFrequentFlyerDocumentInput'>
  >;
  createUserHealthReport?: Resolver<
    ResolversTypes['UserHealthReport'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserHealthReportArgs, 'userHealthReportInput'>
  >;
  createUserIdentityDocument?: Resolver<
    ResolversTypes['UserIdentityDocument'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserIdentityDocumentArgs, 'userIdentityDocumentInput'>
  >;
  createUserOtherDocument?: Resolver<
    ResolversTypes['UserOtherDocument'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserOtherDocumentArgs, 'userOtherDocumentInput'>
  >;
  createUserTravelDocument?: Resolver<
    ResolversTypes['UserTravelDocument'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateUserTravelDocumentArgs,
      'mappedUserIdentityDocumentId' | 'userTravelDocumentInput'
    >
  >;
  createUserVaccinationDocument?: Resolver<
    ResolversTypes['UserVaccinationDocument'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserVaccinationDocumentArgs, 'userVaccinationDocumentInput'>
  >;
  deleteAgencyAccount?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAgencyAccountArgs, 'userId'>
  >;
  deleteAvailability?: Resolver<
    Maybe<ResolversTypes['Availability']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAvailabilityArgs, 'availabilityId'>
  >;
  deleteChatMessage?: Resolver<
    ResolversTypes['ChatMessage'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteChatMessageArgs, 'deleteChatMessageInput'>
  >;
  deleteCourierAccount?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteCourierAccountArgs, 'userId'>
  >;
  deleteOrganizationLogo?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteOrganizationLogoArgs, 'organizationId'>
  >;
  deleteUserBackgroundDocument?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserBackgroundDocumentArgs, 'documentId'>
  >;
  deleteUserFrequentFlyerDocument?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserFrequentFlyerDocumentArgs, 'documentId'>
  >;
  deleteUserHealthReport?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserHealthReportArgs, 'documentId'>
  >;
  deleteUserIdentityDocument?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserIdentityDocumentArgs, 'documentId'>
  >;
  deleteUserOtherDocument?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserOtherDocumentArgs, 'documentId'>
  >;
  deleteUserProfilePicture?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  deleteUserTravelDocument?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserTravelDocumentArgs, 'documentId'>
  >;
  deleteUserVaccinationDocument?: Resolver<
    ResolversTypes['EntityDeletionReturnType'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserVaccinationDocumentArgs, 'documentId'>
  >;
  disableAdHocAvailability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  findOrCreateAdHocAvailability?: Resolver<
    ResolversTypes['Availability'],
    ParentType,
    ContextType,
    Partial<MutationFindOrCreateAdHocAvailabilityArgs>
  >;
  getOrCreateDevice?: Resolver<
    ResolversTypes['Device'],
    ParentType,
    ContextType,
    RequireFields<MutationGetOrCreateDeviceArgs, 'deviceInput'>
  >;
  markAllNewSystemNotificationsAsReadForOrganizationUser?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType,
    RequireFields<
      MutationMarkAllNewSystemNotificationsAsReadForOrganizationUserArgs,
      'organizationUserId'
    >
  >;
  markSystemNotificationAsReadForOrganizationUser?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType,
    RequireFields<
      MutationMarkSystemNotificationAsReadForOrganizationUserArgs,
      'notificationId' | 'organizationUserId'
    >
  >;
  notificationSettingUpdate?: Resolver<
    ResolversTypes['NotificationSetting'],
    ParentType,
    ContextType,
    RequireFields<MutationNotificationSettingUpdateArgs, 'data'>
  >;
  organizationCourierCategoriesUpdate?: Resolver<
    Array<ResolversTypes['OrganizationCourierCategory']>,
    ParentType,
    ContextType,
    RequireFields<MutationOrganizationCourierCategoriesUpdateArgs, 'data'>
  >;
  organizationInvitationDelete?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType,
    RequireFields<MutationOrganizationInvitationDeleteArgs, 'organizationInvitationId'>
  >;
  organizationSubscriptionPlanCancel?: Resolver<
    ResolversTypes['OrganizationSubscriptionPlan'],
    ParentType,
    ContextType,
    RequireFields<MutationOrganizationSubscriptionPlanCancelArgs, 'data'>
  >;
  organizationUserRemoveOrganizationUserFromOrganization?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<
      MutationOrganizationUserRemoveOrganizationUserFromOrganizationArgs,
      'organizationUserId'
    >
  >;
  organizationUserUpdateProfile?: Resolver<
    ResolversTypes['OrganizationUser'],
    ParentType,
    ContextType,
    RequireFields<MutationOrganizationUserUpdateProfileArgs, 'organizationUserUpdateProfileInput'>
  >;
  registerExistingUserWithOwnedOrganization?: Resolver<
    ResolversTypes['OrganizationUser'],
    ParentType,
    ContextType,
    RequireFields<MutationRegisterExistingUserWithOwnedOrganizationArgs, 'organizationType'>
  >;
  restoreAgencyAccount?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationRestoreAgencyAccountArgs, 'userId'>
  >;
  restoreCourierAccount?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationRestoreCourierAccountArgs, 'userId'>
  >;
  updateAgencyOrganizationProfile?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAgencyOrganizationProfileArgs, 'agencyOrganizationProfileInput'>
  >;
  updateAgencyOrganizationVatId?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAgencyOrganizationVatIdArgs, 'agencyOrganizationVatIdInput'>
  >;
  updateChatMessage?: Resolver<
    ResolversTypes['ChatMessageCreateEdge'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateChatMessageArgs, 'updateChatMessageInput'>
  >;
  updateCourierOrganizationProfile?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCourierOrganizationProfileArgs, 'courierOrganizationProfileInput'>
  >;
  updateCourierUserProfile?: Resolver<
    ResolversTypes['CourierUserProfile'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCourierUserProfileArgs, 'courierUserProfileInput'>
  >;
  updateDevice?: Resolver<
    ResolversTypes['Device'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDeviceArgs, 'deviceInput'>
  >;
  updateRelationshipTypeForCourierJobAsAgencyOrganization?: Resolver<
    ResolversTypes['CourierJob'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateRelationshipTypeForCourierJobAsAgencyOrganizationArgs,
      'agencyJobId' | 'id' | 'relationshipType'
    >
  >;
  updateRelationshipTypeForCourierJobAsCourierOrganization?: Resolver<
    ResolversTypes['CourierJob'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpdateRelationshipTypeForCourierJobAsCourierOrganizationArgs,
      'id' | 'relationshipType'
    >
  >;
  updateUserProfile?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUserProfileArgs, 'userProfileInput'>
  >;
  userAccountRestore?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<MutationUserAccountRestoreArgs, 'userId'>
  >;
  userDeleteAccount?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUserDeleteAccountArgs, 'userId'>
  >;
  voidOrganizationInvitation?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType,
    RequireFields<MutationVoidOrganizationInvitationArgs, 'id'>
  >;
}>;

export type NotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification'],
> = ResolversObject<{
  agencyJob?: Resolver<Maybe<ResolversTypes['AgencyJob']>, ParentType, ContextType>;
  batchId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  chatMessage?: Resolver<Maybe<ResolversTypes['ChatMessage']>, ParentType, ContextType>;
  clientJob?: Resolver<Maybe<ResolversTypes['ClientJob']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contentType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courierJob?: Resolver<Maybe<ResolversTypes['CourierJob']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType>;
  firstDisplayedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>;
  language?: Resolver<ResolversTypes['NotificationLanguage'], ParentType, ContextType>;
  markedAsReadAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  notificationMeansType?: Resolver<
    ResolversTypes['NotificationMeansType'],
    ParentType,
    ContextType
  >;
  notificationType?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  organizationContextType?: Resolver<
    Maybe<ResolversTypes['OrganizationType']>,
    ParentType,
    ContextType
  >;
  organizationUser?: Resolver<Maybe<ResolversTypes['OrganizationUser']>, ParentType, ContextType>;
  receivedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  recipientIdentifierOrAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestMetaData?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  responseMetaData?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  sentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationSettingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NotificationSetting'] = ResolversParentTypes['NotificationSetting'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  emailConnection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  emailJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  pushAvailability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushChat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushConnection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pushReminder?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  systemAvailability?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  systemChat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  systemConnection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  systemJob?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization'],
> = ResolversObject<{
  agencyCourierUserProfiles?: Resolver<
    Array<ResolversTypes['AgencyCourierUserProfile']>,
    ParentType,
    ContextType
  >;
  agencyJobs?: Resolver<Array<ResolversTypes['AgencyJob']>, ParentType, ContextType>;
  clientJobs?: Resolver<Maybe<Array<ResolversTypes['ClientJob']>>, ParentType, ContextType>;
  compressedLogoBlobName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compressedLogoMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  connectedOrganizationOrganizations?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationOrganization']>>,
    ParentType,
    ContextType
  >;
  connectedOrganizations?: Resolver<
    Array<ResolversTypes['ConnectedOrganization']>,
    ParentType,
    ContextType,
    Partial<OrganizationConnectedOrganizationsArgs>
  >;
  connectingOrganizationOrganizations?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationOrganization']>>,
    ParentType,
    ContextType
  >;
  courierJobs?: Resolver<Maybe<Array<ResolversTypes['CourierJob']>>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  headquartersAddressLocation?: Resolver<
    Maybe<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  incomingOrganizationInvitations?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  invoiceEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoicingAddressLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  legalEntityType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  legalName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkCO2Emissions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['StoredAsset']>, ParentType, ContextType>;
  logoContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationContacts?: Resolver<
    Array<ResolversTypes['OrganizationContact']>,
    ParentType,
    ContextType
  >;
  organizationCourierCategories?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationCourierCategory']>>,
    ParentType,
    ContextType
  >;
  organizationSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['OrganizationSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  organizationSubscriptionPlans?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationSubscriptionPlan']>>,
    ParentType,
    ContextType
  >;
  organizationType?: Resolver<ResolversTypes['OrganizationType'], ParentType, ContextType>;
  organizationUserOwners?: Resolver<
    Array<ResolversTypes['OrganizationUser']>,
    ParentType,
    ContextType
  >;
  organizationUsers?: Resolver<Array<ResolversTypes['OrganizationUser']>, ParentType, ContextType>;
  originalLogoBlobName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalLogoMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  outgoingOrganizationInvitations?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pipedriveOrganizationId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  registrationAddressLocation?: Resolver<
    Maybe<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  registrationNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shortName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  socialProfiles?: Resolver<Array<ResolversTypes['SocialProfile']>, ParentType, ContextType>;
  tinyLogoBlobName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tinyLogoMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  usedOrganizationInvitation?: Resolver<
    Maybe<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  vatId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationContactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationContact'] = ResolversParentTypes['OrganizationContact'],
> = ResolversObject<{
  changes?: Resolver<Array<ResolversTypes['OrganizationContactChanges']>, ParentType, ContextType>;
  contact?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isAgencyContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isClientContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCourierContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOtherContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPowerAutomateContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPriojetContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isServiceProviderContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTravelerContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  organizationInvitations?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneUnformatted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  syncedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userContacts?: Resolver<Array<ResolversTypes['UserContact']>, ParentType, ContextType>;
  userContactsForUser?: Resolver<
    Array<ResolversTypes['UserContact']>,
    ParentType,
    ContextType,
    RequireFields<OrganizationContactUserContactsForUserArgs, 'userId'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationContactChangesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationContactChanges'] = ResolversParentTypes['OrganizationContactChanges'],
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationCourierCategoryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationCourierCategory'] = ResolversParentTypes['OrganizationCourierCategory'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationInvitationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationInvitation'] = ResolversParentTypes['OrganizationInvitation'],
> = ResolversObject<{
  activationCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bitrix24Deal?: Resolver<
    Maybe<ResolversTypes['OrganizationInvitationBitrix24Deal']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  hasInvitationDeal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  invitedOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  invitedOrganizationType?: Resolver<
    Maybe<ResolversTypes['OrganizationType']>,
    ParentType,
    ContextType
  >;
  invitedUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  invitingOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  organizationContact?: Resolver<
    Maybe<ResolversTypes['OrganizationContact']>,
    ParentType,
    ContextType
  >;
  organizationInvitationSharings?: Resolver<
    Array<ResolversTypes['OrganizationInvitationSharing']>,
    ParentType,
    ContextType
  >;
  organizationInvitationType?: Resolver<
    ResolversTypes['OrganizationInvitationType'],
    ParentType,
    ContextType
  >;
  pipedriveDeal?: Resolver<
    Maybe<ResolversTypes['OrganizationInvitationPipedriveDeal']>,
    ParentType,
    ContextType
  >;
  pipedriveDealOBCInitial?: Resolver<
    Maybe<ResolversTypes['OrganizationInvitationPipedriveDeal']>,
    ParentType,
    ContextType
  >;
  reservedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reservedComment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleType?: Resolver<Maybe<ResolversTypes['OrganizationUserRoleType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  usedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  usedByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  usedForOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  usedForOrganizationOrganization?: Resolver<
    Maybe<ResolversTypes['OrganizationOrganization']>,
    ParentType,
    ContextType
  >;
  usedForOrganizationUser?: Resolver<
    Maybe<ResolversTypes['OrganizationUser']>,
    ParentType,
    ContextType
  >;
  voidedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationInvitationBitrix24DealResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationInvitationBitrix24Deal'] = ResolversParentTypes['OrganizationInvitationBitrix24Deal'],
> = ResolversObject<{
  activationCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bitrixDealCategoryId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bitrixInvitedCompanyId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  bitrixInvitedContactId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invitingOrganizationEmailAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invitingOrganizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  syncedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationInvitationPipedriveDealResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationInvitationPipedriveDeal'] = ResolversParentTypes['OrganizationInvitationPipedriveDeal'],
> = ResolversObject<{
  activationCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invitingOrganizationEmail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invitingOrganizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invitingOrganizationName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pipedriveOrganizationId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pipedrivePersonId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  stageId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  syncedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationInvitationSharingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationInvitationSharing'] = ResolversParentTypes['OrganizationInvitationSharing'],
> = ResolversObject<{
  appName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationInvitation?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType
  >;
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platformVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sharedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  sharedByUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationInvitationUploadCsvResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationInvitationUploadCSV'] = ResolversParentTypes['OrganizationInvitationUploadCSV'],
> = ResolversObject<{
  contacts?: Resolver<
    Array<ResolversTypes['OrganizationInvitationUploadCSVContact']>,
    ParentType,
    ContextType
  >;
  organizationInvitationsNotUsed?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  organizationInvitationsUsed?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationInvitationUploadCsvContactResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationInvitationUploadCSVContact'] = ResolversParentTypes['OrganizationInvitationUploadCSVContact'],
> = ResolversObject<{
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  emailError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstNameError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastNameError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneNumberError?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationOrganizationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationOrganization'] = ResolversParentTypes['OrganizationOrganization'],
> = ResolversObject<{
  connectedOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  connectingOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  connectionType?: Resolver<
    ResolversTypes['OrganizationOrganizationConnectionType'],
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  usedOrganizationInvitation?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationSubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationSubscriptionPlan'] = ResolversParentTypes['OrganizationSubscriptionPlan'],
> = ResolversObject<{
  cancellationEffectiveDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  historyLogPayments?: Resolver<
    Maybe<Array<ResolversTypes['HistoryLogPayment']>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  initialCreationDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  passthrough?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentCancelUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentCurrency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentMarketingConsent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  paymentNextBillingDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentNextPaymentAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentPaymentTax?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentPlanId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  paymentSaleGross?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentSubscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentSubscriptionPaymentId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  paymentUnitPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pipedriveDeal?: Resolver<
    Maybe<ResolversTypes['OrganizationSubscriptionPlanPipedriveDeal']>,
    ParentType,
    ContextType
  >;
  pipedriveDealCancelled?: Resolver<
    Maybe<ResolversTypes['OrganizationSubscriptionPlanPipedriveDeal']>,
    ParentType,
    ContextType
  >;
  previousPaymentSubscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prices?: Resolver<Maybe<Array<ResolversTypes['SubscriptionPlanPrice']>>, ParentType, ContextType>;
  recurringInterval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionPlan?: Resolver<ResolversTypes['SubscriptionPlan'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationSubscriptionPlanPipedriveDealResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationSubscriptionPlanPipedriveDeal'] = ResolversParentTypes['OrganizationSubscriptionPlanPipedriveDeal'],
> = ResolversObject<{
  cancellationEffectiveDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nextBillingDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pipedriveOrganizationId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pipedrivePersonId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  subscriptionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subscriptionPlanId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  syncedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OrganizationUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['OrganizationUser'] = ResolversParentTypes['OrganizationUser'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  organization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType>;
  roleType?: Resolver<ResolversTypes['OrganizationUserRoleType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  usedOrganizationInvitation?: Resolver<
    Maybe<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = ResolversObject<{
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hasPreviousPage?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PluginDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PluginData'] = ResolversParentTypes['PluginData'],
> = ResolversObject<{
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mimeTypes?: Resolver<Array<ResolversTypes['PluginMimeTypeData']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PluginMimeTypeDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PluginMimeTypeData'] = ResolversParentTypes['PluginMimeTypeData'],
> = ResolversObject<{
  suffixes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = ResolversObject<{
  activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations?: Resolver<
    Array<ResolversTypes['Availability']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizationsArgs,
      'agencyOrganizationId'
    >
  >;
  activeAvailabilitiesForUser?: Resolver<
    Array<ResolversTypes['Availability']>,
    ParentType,
    ContextType,
    RequireFields<QueryActiveAvailabilitiesForUserArgs, 'userId'>
  >;
  agencyCourierJobChat?: Resolver<
    ResolversTypes['Chat'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyCourierJobChatArgs, 'agencyCourierJobChatInput'>
  >;
  agencyJobChat?: Resolver<
    ResolversTypes['Chat'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyJobChatArgs, 'agencyJobChatInput'>
  >;
  agencyJobForAgencyOrganization?: Resolver<
    ResolversTypes['AgencyJob'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyJobForAgencyOrganizationArgs, 'agencyJobId'>
  >;
  agencyJobPlanningForAgencyJob?: Resolver<
    ResolversTypes['AgencyJobPlanning'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyJobPlanningForAgencyJobArgs, 'agencyJobId'>
  >;
  agencyJobsForAgencyOrganization?: Resolver<
    Array<ResolversTypes['AgencyJob']>,
    ParentType,
    ContextType,
    RequireFields<QueryAgencyJobsForAgencyOrganizationArgs, 'agencyOrganizationId'>
  >;
  agencyJobsForAgencyOrganizationPaginated?: Resolver<
    ResolversTypes['AgencyJobConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryAgencyJobsForAgencyOrganizationPaginatedArgs, 'data'>
  >;
  airportLocationList?: Resolver<
    Array<ResolversTypes['Location']>,
    ParentType,
    ContextType,
    Partial<QueryAirportLocationListArgs>
  >;
  airportLocationListPaginated?: Resolver<
    ResolversTypes['AirportLocationConnection'],
    ParentType,
    ContextType,
    Partial<QueryAirportLocationListPaginatedArgs>
  >;
  airportLocationSearch?: Resolver<
    Array<ResolversTypes['Location']>,
    ParentType,
    ContextType,
    RequireFields<QueryAirportLocationSearchArgs, 'data'>
  >;
  allActiveAndUpcomingAvailabilitiesForPriojet?: Resolver<
    Array<ResolversTypes['Availability']>,
    ParentType,
    ContextType
  >;
  allUsersForPriojetOrganization?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  availabilitiesForConnectedCourierOrganizations?: Resolver<
    Array<ResolversTypes['Availability']>,
    ParentType,
    ContextType,
    RequireFields<QueryAvailabilitiesForConnectedCourierOrganizationsArgs, 'agencyOrganizationId'>
  >;
  availabilitiesForUser?: Resolver<
    Array<ResolversTypes['Availability']>,
    ParentType,
    ContextType,
    RequireFields<QueryAvailabilitiesForUserArgs, 'userId'>
  >;
  availability?: Resolver<
    ResolversTypes['Availability'],
    ParentType,
    ContextType,
    RequireFields<QueryAvailabilityArgs, 'availabilityId'>
  >;
  availabilityFindActiveAdhocCurrentUser?: Resolver<
    Maybe<ResolversTypes['Availability']>,
    ParentType,
    ContextType
  >;
  availableCourierUsersByDistanceForAgencyJob?: Resolver<
    Array<ResolversTypes['AvailableCourierOrganizationUsersWithDistance']>,
    ParentType,
    ContextType,
    RequireFields<QueryAvailableCourierUsersByDistanceForAgencyJobArgs, 'agencyJobId'>
  >;
  availableCourierUsersByDistanceForAgencyJobFiltered?: Resolver<
    Array<ResolversTypes['AvailableCourierOrganizationUsersWithDistance']>,
    ParentType,
    ContextType,
    RequireFields<QueryAvailableCourierUsersByDistanceForAgencyJobFilteredArgs, 'agencyJobId'>
  >;
  chatMessageEdge?: Resolver<
    ResolversTypes['ChatMessageCreateEdge'],
    ParentType,
    ContextType,
    RequireFields<QueryChatMessageEdgeArgs, 'chatMessageId'>
  >;
  chatMessagesByChatId?: Resolver<
    ResolversTypes['ChatMessageConnection'],
    ParentType,
    ContextType,
    RequireFields<QueryChatMessagesByChatIdArgs, 'chatMessagesByChatIdInput'>
  >;
  connectedAgencyOrganizationForCourierOrganization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<
      QueryConnectedAgencyOrganizationForCourierOrganizationArgs,
      'agencyOrganizationId' | 'courierOrganizationId'
    >
  >;
  connectedAgencyOrganizationsForCourierOrganization?: Resolver<
    Array<ResolversTypes['ConnectedOrganization']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryConnectedAgencyOrganizationsForCourierOrganizationArgs,
      'courierOrganizationId'
    >
  >;
  connectedCourierOrganizationsForAgencyOrganization?: Resolver<
    Array<ResolversTypes['ConnectedOrganization']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryConnectedCourierOrganizationsForAgencyOrganizationArgs,
      'agencyOrganizationId'
    >
  >;
  courierJobForAgencyOrganization?: Resolver<
    ResolversTypes['CourierJob'],
    ParentType,
    ContextType,
    RequireFields<QueryCourierJobForAgencyOrganizationArgs, 'agencyOrganizationId' | 'courierJobId'>
  >;
  courierJobForCourierOrganization?: Resolver<
    ResolversTypes['CourierJob'],
    ParentType,
    ContextType,
    RequireFields<
      QueryCourierJobForCourierOrganizationArgs,
      'courierJobId' | 'courierOrganizationId'
    >
  >;
  courierJobsForAgencyJob?: Resolver<
    Array<ResolversTypes['CourierJob']>,
    ParentType,
    ContextType,
    RequireFields<QueryCourierJobsForAgencyJobArgs, 'agencyJobId'>
  >;
  courierJobsForCourierOrganization?: Resolver<
    Array<ResolversTypes['CourierJob']>,
    ParentType,
    ContextType,
    RequireFields<QueryCourierJobsForCourierOrganizationArgs, 'courierOrganizationId'>
  >;
  courierJobsForCourierOrganizationMinimal?: Resolver<
    Array<ResolversTypes['CourierJob']>,
    ParentType,
    ContextType,
    RequireFields<QueryCourierJobsForCourierOrganizationMinimalArgs, 'courierOrganizationId'>
  >;
  courierOrganizationInvitationsForAgencyOrganization?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryCourierOrganizationInvitationsForAgencyOrganizationArgs,
      'agencyOrganizationId' | 'userId'
    >
  >;
  courierUserProfileByUserId?: Resolver<
    ResolversTypes['CourierUserProfile'],
    ParentType,
    ContextType,
    RequireFields<QueryCourierUserProfileByUserIdArgs, 'userId'>
  >;
  courierUserProfileForCourierOrganizationUser?: Resolver<
    ResolversTypes['CourierUserProfile'],
    ParentType,
    ContextType,
    RequireFields<QueryCourierUserProfileForCourierOrganizationUserArgs, 'organizationUserId'>
  >;
  currentUserContext?: Resolver<ResolversTypes['UserContext'], ParentType, ContextType>;
  deletedAccountsPriojetOnly?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  employeeInvitationsForAgencyOrganization?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType,
    RequireFields<
      QueryEmployeeInvitationsForAgencyOrganizationArgs,
      'agencyOrganizationId' | 'userId'
    >
  >;
  latestOrNewSystemNotificationsForOrganizationUser?: Resolver<
    Array<ResolversTypes['Notification']>,
    ParentType,
    ContextType,
    RequireFields<QueryLatestOrNewSystemNotificationsForOrganizationUserArgs, 'organizationUserId'>
  >;
  logoByOrganizationId?: Resolver<
    ResolversTypes['StoredAsset'],
    ParentType,
    ContextType,
    RequireFields<QueryLogoByOrganizationIdArgs, 'organizationId' | 'userProfilePictureSize'>
  >;
  minSupportedAppVersion?: Resolver<ResolversTypes['MinSupportedVersion'], ParentType, ContextType>;
  notificationSettingCurrentUser?: Resolver<
    ResolversTypes['NotificationSetting'],
    ParentType,
    ContextType
  >;
  numberOfNewNotificationsForOrganizationUser?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType,
    RequireFields<QueryNumberOfNewNotificationsForOrganizationUserArgs, 'organizationUserId'>
  >;
  organization?: Resolver<
    ResolversTypes['Organization'],
    ParentType,
    ContextType,
    RequireFields<QueryOrganizationArgs, 'id'>
  >;
  organizationCourierCategories?: Resolver<
    Array<ResolversTypes['OrganizationCourierCategory']>,
    ParentType,
    ContextType
  >;
  organizationInvitationByActivationCodeForCourierOrganization?: Resolver<
    ResolversTypes['OrganizationInvitation'],
    ParentType,
    ContextType,
    RequireFields<
      QueryOrganizationInvitationByActivationCodeForCourierOrganizationArgs,
      'activationCode' | 'organizationId'
    >
  >;
  organizationInvitationUploadCSV?: Resolver<
    ResolversTypes['OrganizationInvitationUploadCSV'],
    ParentType,
    ContextType,
    RequireFields<QueryOrganizationInvitationUploadCsvArgs, 'data'>
  >;
  organizationInvitationsForPriojetOrganization?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  organizationUserById?: Resolver<
    ResolversTypes['OrganizationUser'],
    ParentType,
    ContextType,
    RequireFields<QueryOrganizationUserByIdArgs, 'organizationUserId'>
  >;
  organizations?: Resolver<Array<ResolversTypes['Organization']>, ParentType, ContextType>;
  profilePictureById?: Resolver<
    ResolversTypes['StoredAsset'],
    ParentType,
    ContextType,
    RequireFields<QueryProfilePictureByIdArgs, 'userId' | 'userProfilePictureSize'>
  >;
  sendTestNotification?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  signInUserContext?: Resolver<ResolversTypes['UserContext'], ParentType, ContextType>;
  subscriptionPlansAgency?: Resolver<
    ResolversTypes['SubscriptionPlanPaddle'],
    ParentType,
    ContextType
  >;
  subscriptionPlansCourier?: Resolver<
    ResolversTypes['SubscriptionPlanPaddle'],
    ParentType,
    ContextType
  >;
  user?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<QueryUserArgs, 'id'>
  >;
  userBackgroundDocument?: Resolver<
    ResolversTypes['UserBackgroundDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryUserBackgroundDocumentArgs, 'documentId'>
  >;
  userBackgroundDocuments?: Resolver<
    Array<ResolversTypes['UserBackgroundDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserBackgroundDocumentsArgs, 'userId'>
  >;
  userContexts?: Resolver<Array<ResolversTypes['UserContext']>, ParentType, ContextType>;
  userFrequentFlyerDocument?: Resolver<
    ResolversTypes['UserFrequentFlyerDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryUserFrequentFlyerDocumentArgs, 'documentId'>
  >;
  userFrequentFlyerDocuments?: Resolver<
    Array<ResolversTypes['UserFrequentFlyerDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserFrequentFlyerDocumentsArgs, 'userId'>
  >;
  userHealthReport?: Resolver<
    ResolversTypes['UserHealthReport'],
    ParentType,
    ContextType,
    RequireFields<QueryUserHealthReportArgs, 'documentId'>
  >;
  userHealthReports?: Resolver<
    Array<ResolversTypes['UserHealthReport']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserHealthReportsArgs, 'userId'>
  >;
  userIdentityDocument?: Resolver<
    ResolversTypes['UserIdentityDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryUserIdentityDocumentArgs, 'documentId'>
  >;
  userIdentityDocuments?: Resolver<
    Array<ResolversTypes['UserIdentityDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserIdentityDocumentsArgs, 'userId'>
  >;
  userOtherDocument?: Resolver<
    ResolversTypes['UserOtherDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryUserOtherDocumentArgs, 'documentId'>
  >;
  userOtherDocuments?: Resolver<
    Array<ResolversTypes['UserOtherDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserOtherDocumentsArgs, 'userId'>
  >;
  userTravelDocument?: Resolver<
    ResolversTypes['UserTravelDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryUserTravelDocumentArgs, 'documentId'>
  >;
  userTravelDocuments?: Resolver<
    Array<ResolversTypes['UserTravelDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserTravelDocumentsArgs, 'userId'>
  >;
  userVaccinationDocument?: Resolver<
    ResolversTypes['UserVaccinationDocument'],
    ParentType,
    ContextType,
    RequireFields<QueryUserVaccinationDocumentArgs, 'documentId'>
  >;
  userVaccinationDocuments?: Resolver<
    Array<ResolversTypes['UserVaccinationDocument']>,
    ParentType,
    ContextType,
    RequireFields<QueryUserVaccinationDocumentsArgs, 'userId'>
  >;
  userWithAllUserDocuments?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    RequireFields<QueryUserWithAllUserDocumentsArgs, 'userId'>
  >;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
}>;

export type SasTokenResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SasTokenResponse'] = ResolversParentTypes['SasTokenResponse'],
> = ResolversObject<{
  expiresOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resource?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  version?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SocialProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialProfile'] = ResolversParentTypes['SocialProfile'],
> = ResolversObject<{
  platform?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StoredAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StoredAsset'] = ResolversParentTypes['StoredAsset'],
> = ResolversObject<{
  cacheKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cacheTTL?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['StoredAssetMetadata'], ParentType, ContextType>;
  sasToken?: Resolver<ResolversTypes['SasTokenResponse'], ParentType, ContextType>;
  sasUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StoredAssetMetadataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StoredAssetMetadata'] = ResolversParentTypes['StoredAssetMetadata'],
> = ResolversObject<{
  fileName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mimeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = ResolversObject<{
  chatMessageSent?: SubscriptionResolver<
    ResolversTypes['ChatMessage'],
    'chatMessageSent',
    ParentType,
    ContextType,
    RequireFields<SubscriptionChatMessageSentArgs, 'chatId'>
  >;
}>;

export type SubscriptionFeatureResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionFeature'] = ResolversParentTypes['SubscriptionFeature'],
> = ResolversObject<{
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['subscriptionFeatureType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlan'] = ResolversParentTypes['SubscriptionPlan'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationSubscriptionPlans?: Resolver<
    Maybe<Array<ResolversTypes['OrganizationSubscriptionPlan']>>,
    ParentType,
    ContextType
  >;
  planName?: Resolver<ResolversTypes['SubscriptionPlanName'], ParentType, ContextType>;
  prices?: Resolver<ResolversTypes['SubscriptionPlanPricePeriod'], ParentType, ContextType>;
  subscriptionFeatures?: Resolver<
    Maybe<Array<ResolversTypes['SubscriptionFeature']>>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['SubscriptionPlanType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanNameResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlanName'] = ResolversParentTypes['SubscriptionPlanName'],
> = ResolversObject<{
  defaultValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanPaddleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlanPaddle'] = ResolversParentTypes['SubscriptionPlanPaddle'],
> = ResolversObject<{
  month?: Resolver<Array<ResolversTypes['SubscriptionPlanPaddleType']>, ParentType, ContextType>;
  year?: Resolver<Array<ResolversTypes['SubscriptionPlanPaddleType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanPaddleTypeResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlanPaddleType'] = ResolversParentTypes['SubscriptionPlanPaddleType'],
> = ResolversObject<{
  description?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  featureDescription?: Resolver<Array<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  isMain?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  organizationType?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  planId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  prices?: Resolver<Array<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  recurringInterval?: Resolver<ResolversTypes['JSONObject'], ParentType, ContextType>;
  subscriptionPlanType?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trialPeriod?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanPriceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlanPrice'] = ResolversParentTypes['SubscriptionPlanPrice'],
> = ResolversObject<{
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recurringInterval?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionPlanPricePeriodResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubscriptionPlanPricePeriod'] = ResolversParentTypes['SubscriptionPlanPricePeriod'],
> = ResolversObject<{
  month?: Resolver<Array<ResolversTypes['SubscriptionPlanPrice']>, ParentType, ContextType>;
  year?: Resolver<Array<ResolversTypes['SubscriptionPlanPrice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TLanguageKnownResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TLanguageKnown'] = ResolversParentTypes['TLanguageKnown'],
> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  language?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['LanguageSkillLevel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TouchSupportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TouchSupport'] = ResolversParentTypes['TouchSupport'],
> = ResolversObject<{
  maxTouchPoints?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  touchEvent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  touchStart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = ResolversObject<{
  agencyCourierUserProfile?: Resolver<
    Maybe<ResolversTypes['AgencyCourierUserProfile']>,
    ParentType,
    ContextType
  >;
  agencyCourierUserProfiles?: Resolver<
    Array<ResolversTypes['AgencyCourierUserProfile']>,
    ParentType,
    ContextType
  >;
  availabilities?: Resolver<Array<ResolversTypes['Availability']>, ParentType, ContextType>;
  compressedProfilePictureBlobName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedProfilePictureContainerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedProfilePictureMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countOfActivationCodeAttempts?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countryOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courierUserProfile?: Resolver<
    Maybe<ResolversTypes['CourierUserProfile']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deviceLocations?: Resolver<Array<ResolversTypes['DeviceLocation']>, ParentType, ContextType>;
  deviceLogs?: Resolver<Array<ResolversTypes['DeviceLog']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  incomingOrganizationInvitations?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  language?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locationsCreated?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  nationalities?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  notificationSetting?: Resolver<
    Maybe<ResolversTypes['NotificationSetting']>,
    ParentType,
    ContextType
  >;
  organizationContactsCreated?: Resolver<
    Array<ResolversTypes['OrganizationContact']>,
    ParentType,
    ContextType
  >;
  organizationInvitationSharings?: Resolver<
    Array<ResolversTypes['OrganizationContact']>,
    ParentType,
    ContextType
  >;
  organizationInvitationsCreated?: Resolver<
    Array<ResolversTypes['OrganizationInvitation']>,
    ParentType,
    ContextType
  >;
  organizationUsers?: Resolver<Array<ResolversTypes['OrganizationUser']>, ParentType, ContextType>;
  originalProfilePictureBlobName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalProfilePictureContainerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalProfilePictureMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pipedrivePersonId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  placeOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profilePicture?: Resolver<Maybe<ResolversTypes['StoredAsset']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  tinyProfilePictureBlobName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tinyProfilePictureContainerName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  tinyProfilePictureMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  userBackgroundDocument?: Resolver<
    Array<ResolversTypes['UserBackgroundDocument']>,
    ParentType,
    ContextType
  >;
  userContactsCreated?: Resolver<Array<ResolversTypes['UserContact']>, ParentType, ContextType>;
  userDevices?: Resolver<Array<ResolversTypes['UserDevice']>, ParentType, ContextType>;
  userFrequentFlyerDocuments?: Resolver<
    Array<ResolversTypes['UserFrequentFlyerDocument']>,
    ParentType,
    ContextType
  >;
  userHealthReports?: Resolver<Array<ResolversTypes['UserHealthReport']>, ParentType, ContextType>;
  userIdentityDocuments?: Resolver<
    Array<ResolversTypes['UserIdentityDocument']>,
    ParentType,
    ContextType
  >;
  userOtherDocuments?: Resolver<
    Array<ResolversTypes['UserOtherDocument']>,
    ParentType,
    ContextType
  >;
  userRegisteredAddressLocation?: Resolver<
    Maybe<ResolversTypes['Location']>,
    ParentType,
    ContextType
  >;
  userTravelDocuments?: Resolver<
    Array<ResolversTypes['UserTravelDocument']>,
    ParentType,
    ContextType
  >;
  userVaccinationDocuments?: Resolver<
    Array<ResolversTypes['UserVaccinationDocument']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserBackgroundDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserBackgroundDocument'] = ResolversParentTypes['UserBackgroundDocument'],
> = ResolversObject<{
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countryOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfIssue?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserBackgroundDocumentDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserBackgroundDocumentDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  issuingInstitution?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserContactResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserContact'] = ResolversParentTypes['UserContact'],
> = ResolversObject<{
  changes?: Resolver<Array<ResolversTypes['UserContactChanges']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdByUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expoContact?: Resolver<Maybe<ResolversTypes['ExpoContact']>, ParentType, ContextType>;
  expoContactId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  importSource?: Resolver<
    Maybe<ResolversTypes['UserContactImportSourceType']>,
    ParentType,
    ContextType
  >;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isAgencyContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isClientContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isCourierContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOtherContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPriojetContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isServiceProviderContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTravelerContact?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phoneUnformatted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserContactChangesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserContactChanges'] = ResolversParentTypes['UserContactChanges'],
> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstNames?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserContextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserContext'] = ResolversParentTypes['UserContext'],
> = ResolversObject<{
  accessTokenHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  organizationActivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  organizationSubscriptionPlan?: Resolver<
    Maybe<ResolversTypes['OrganizationSubscriptionPlan']>,
    ParentType,
    ContextType
  >;
  organizationType?: Resolver<Maybe<ResolversTypes['OrganizationType']>, ParentType, ContextType>;
  organizationUser?: Resolver<Maybe<ResolversTypes['OrganizationUser']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roleType?: Resolver<Maybe<ResolversTypes['OrganizationUserRoleType']>, ParentType, ContextType>;
  sub?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['AzureADB2CToken'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userContextId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserDeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDevice'] = ResolversParentTypes['UserDevice'],
> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  device?: Resolver<ResolversTypes['Device'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastUsedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  signedInAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  signedOutAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserFrequentFlyerDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserFrequentFlyerDocument'] = ResolversParentTypes['UserFrequentFlyerDocument'],
> = ResolversObject<{
  airlineAllianceStatusType?: Resolver<
    Maybe<ResolversTypes['AirlineAllianceStatusType']>,
    ParentType,
    ContextType
  >;
  airlineAllianceType?: Resolver<
    Maybe<ResolversTypes['AirlineAllianceType']>,
    ParentType,
    ContextType
  >;
  airlineStatusType?: Resolver<Maybe<ResolversTypes['AirlineStatusType']>, ParentType, ContextType>;
  airlineType?: Resolver<Maybe<ResolversTypes['AirlineType']>, ParentType, ContextType>;
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserFrequentFlyerDocumentDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserFrequentFlyerDocumentDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserHealthReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserHealthReport'] = ResolversParentTypes['UserHealthReport'],
> = ResolversObject<{
  cityOfTest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cityOfTestLocation?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countryOfTest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateTimeOfTest?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  diseaseType?: Resolver<
    Maybe<ResolversTypes['VaccinationTargetDisease']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserHealthReportDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserHealthReportDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isResultPositive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  testCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  testType?: Resolver<Maybe<ResolversTypes['HealthTestType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserIdentityDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserIdentityDocument'] = ResolversParentTypes['UserIdentityDocument'],
> = ResolversObject<{
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countryOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateOfIssue?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserIdentityDocumentDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFeature?: Resolver<Maybe<ResolversTypes['DocumentFeature']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserIdentityDocumentDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentSubType?: Resolver<
    Maybe<ResolversTypes['IdentityDocumentSubType']>,
    ParentType,
    ContextType
  >;
  documentSubTypeMulti?: Resolver<
    Maybe<Array<ResolversTypes['IdentityDocumentSubType']>>,
    ParentType,
    ContextType
  >;
  documentType?: Resolver<ResolversTypes['IdentityDocumentType'], ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiresOnInternational?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstNames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isInternationalDrivingPermit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nationality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  placeOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserOtherDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserOtherDocument'] = ResolversParentTypes['UserOtherDocument'],
> = ResolversObject<{
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countryOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfIssue?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserOtherDocumentDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserOtherDocumentDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  placeOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserTravelDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserTravelDocument'] = ResolversParentTypes['UserTravelDocument'],
> = ResolversObject<{
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countryOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfBirth?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateOfIssue?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserTravelDocumentDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserTravelDocumentDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentSubType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentSubTypeMulti?: Resolver<
    Maybe<Array<ResolversTypes['UserTravelDocumentSubTypeType']>>,
    ParentType,
    ContextType
  >;
  documentType?: Resolver<ResolversTypes['TravelDocumentType'], ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstNames?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mappedIdentityDocument?: Resolver<
    Maybe<ResolversTypes['UserIdentityDocument']>,
    ParentType,
    ContextType
  >;
  multipleEntriesAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  nationality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  placeOfBirth?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeOfIssue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  validForCountry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserVaccinationDocumentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UserVaccinationDocument'] = ResolversParentTypes['UserVaccinationDocument'],
> = ResolversObject<{
  cityOfVaccination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compressedDocumentBackFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  compressedDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  countryOfVaccination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateOfVaccination?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  diseaseType?: Resolver<
    Maybe<ResolversTypes['VaccinationTargetDisease']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserVaccinationDocumentDocumentBackFromAzureArgs>
  >;
  documentBackFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentBackFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentContainerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentFrontFromAzure?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType,
    Partial<UserVaccinationDocumentDocumentFrontFromAzureArgs>
  >;
  documentFrontFromAzureCompressed?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentFrontFromAzureOriginal?: Resolver<
    Maybe<ResolversTypes['StoredAsset']>,
    ParentType,
    ContextType
  >;
  documentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiresOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inactivatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isVaccinationComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  originalDocumentBackFilePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  originalDocumentBackMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontFilePath?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  originalDocumentFrontMetadata?: Resolver<
    Maybe<ResolversTypes['StoredAssetMetadata']>,
    ParentType,
    ContextType
  >;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  vaccinationCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vaccinationManufacturer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vaccinationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verificationStatus?: Resolver<
    Maybe<ResolversTypes['DocumentVerificationStatus']>,
    ParentType,
    ContextType
  >;
  verifiedOn?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  verifyingUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  AddDeviceLogOutput?: AddDeviceLogOutputResolvers<ContextType>;
  AgencyCourierUserProfile?: AgencyCourierUserProfileResolvers<ContextType>;
  AgencyJob?: AgencyJobResolvers<ContextType>;
  AgencyJobConnection?: AgencyJobConnectionResolvers<ContextType>;
  AgencyJobEdge?: AgencyJobEdgeResolvers<ContextType>;
  AgencyJobPlanning?: AgencyJobPlanningResolvers<ContextType>;
  AgencyJobPlanningFile?: AgencyJobPlanningFileResolvers<ContextType>;
  AgencyJobPlanningItem?: AgencyJobPlanningItemResolvers<ContextType>;
  AgencyJobPlanningItemHistoryLog?: AgencyJobPlanningItemHistoryLogResolvers<ContextType>;
  AgencyJobPlanningLeg?: AgencyJobPlanningLegResolvers<ContextType>;
  AirportLocationConnection?: AirportLocationConnectionResolvers<ContextType>;
  Availability?: AvailabilityResolvers<ContextType>;
  AvailabilityLocation?: AvailabilityLocationResolvers<ContextType>;
  AvailabilityLocationWithDistance?: AvailabilityLocationWithDistanceResolvers<ContextType>;
  AvailableCourierOrganizationUsersWithDistance?: AvailableCourierOrganizationUsersWithDistanceResolvers<ContextType>;
  AzureADB2CToken?: AzureAdb2CTokenResolvers<ContextType>;
  Chat?: ChatResolvers<ContextType>;
  ChatFile?: ChatFileResolvers<ContextType>;
  ChatMessage?: ChatMessageResolvers<ContextType>;
  ChatMessageConnection?: ChatMessageConnectionResolvers<ContextType>;
  ChatMessageCreateEdge?: ChatMessageCreateEdgeResolvers<ContextType>;
  ChatMessageEdge?: ChatMessageEdgeResolvers<ContextType>;
  ClientJob?: ClientJobResolvers<ContextType>;
  ConnectedOrganization?: ConnectedOrganizationResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  ContactBitrix24Company?: ContactBitrix24CompanyResolvers<ContextType>;
  ContactBitrix24Contact?: ContactBitrix24ContactResolvers<ContextType>;
  ContactBitrix24Data?: ContactBitrix24DataResolvers<ContextType>;
  ContactBitrix24Email?: ContactBitrix24EmailResolvers<ContextType>;
  ContactBitrix24Phone?: ContactBitrix24PhoneResolvers<ContextType>;
  ContactChanges?: ContactChangesResolvers<ContextType>;
  ContactPipedriveData?: ContactPipedriveDataResolvers<ContextType>;
  ContactPipedriveOrganization?: ContactPipedriveOrganizationResolvers<ContextType>;
  ContactPipedrivePerson?: ContactPipedrivePersonResolvers<ContextType>;
  CourierJob?: CourierJobResolvers<ContextType>;
  CourierUserProfile?: CourierUserProfileResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Device?: DeviceResolvers<ContextType>;
  DeviceAppSettings?: DeviceAppSettingsResolvers<ContextType>;
  DeviceCanvas?: DeviceCanvasResolvers<ContextType>;
  DeviceLocalization?: DeviceLocalizationResolvers<ContextType>;
  DeviceLocation?: DeviceLocationResolvers<ContextType>;
  DeviceLog?: DeviceLogResolvers<ContextType>;
  DeviceLogData?: DeviceLogDataResolvers<ContextType>;
  DeviceLogOutput?: DeviceLogOutputResolvers<ContextType>;
  DevicePermissionExpiration?: GraphQLScalarType;
  DevicePermissions?: DevicePermissionsResolvers<ContextType>;
  DevicePermissionsNotifications?: DevicePermissionsNotificationsResolvers<ContextType>;
  DevicePermissionsNotificationsAndroid?: DevicePermissionsNotificationsAndroidResolvers<ContextType>;
  DevicePermissionsNotificationsIos?: DevicePermissionsNotificationsIosResolvers<ContextType>;
  DeviceProperties?: DevicePropertiesResolvers<ContextType>;
  EntityDeletionReturnType?: EntityDeletionReturnTypeResolvers<ContextType>;
  ExpoContact?: ExpoContactResolvers<ContextType>;
  ExpoContactAddress?: ExpoContactAddressResolvers<ContextType>;
  ExpoContactDate?: ExpoContactDateResolvers<ContextType>;
  ExpoContactEmail?: ExpoContactEmailResolvers<ContextType>;
  ExpoContactImage?: ExpoContactImageResolvers<ContextType>;
  ExpoContactInstantMessageAddress?: ExpoContactInstantMessageAddressResolvers<ContextType>;
  ExpoContactPhoneNumber?: ExpoContactPhoneNumberResolvers<ContextType>;
  ExpoContactRelationship?: ExpoContactRelationshipResolvers<ContextType>;
  ExpoContactSocialProfile?: ExpoContactSocialProfileResolvers<ContextType>;
  ExpoContactUrlAddress?: ExpoContactUrlAddressResolvers<ContextType>;
  HistoryLogPayment?: HistoryLogPaymentResolvers<ContextType>;
  JSONObject?: GraphQLScalarType;
  Job?: JobResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationAddressComponent?: LocationAddressComponentResolvers<ContextType>;
  LocationEdge?: LocationEdgeResolvers<ContextType>;
  LocationGeometry?: LocationGeometryResolvers<ContextType>;
  LocationGeometryCoords?: LocationGeometryCoordsResolvers<ContextType>;
  LocationGeometryGeoFence?: LocationGeometryGeoFenceResolvers<ContextType>;
  LocationGeometryLatLngBounds?: LocationGeometryLatLngBoundsResolvers<ContextType>;
  LocationGeometryLocation?: LocationGeometryLocationResolvers<ContextType>;
  LocationPlusCode?: LocationPlusCodeResolvers<ContextType>;
  MinSupportedVersion?: MinSupportedVersionResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  NotificationSetting?: NotificationSettingResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationContact?: OrganizationContactResolvers<ContextType>;
  OrganizationContactChanges?: OrganizationContactChangesResolvers<ContextType>;
  OrganizationCourierCategory?: OrganizationCourierCategoryResolvers<ContextType>;
  OrganizationInvitation?: OrganizationInvitationResolvers<ContextType>;
  OrganizationInvitationBitrix24Deal?: OrganizationInvitationBitrix24DealResolvers<ContextType>;
  OrganizationInvitationPipedriveDeal?: OrganizationInvitationPipedriveDealResolvers<ContextType>;
  OrganizationInvitationSharing?: OrganizationInvitationSharingResolvers<ContextType>;
  OrganizationInvitationUploadCSV?: OrganizationInvitationUploadCsvResolvers<ContextType>;
  OrganizationInvitationUploadCSVContact?: OrganizationInvitationUploadCsvContactResolvers<ContextType>;
  OrganizationOrganization?: OrganizationOrganizationResolvers<ContextType>;
  OrganizationSubscriptionPlan?: OrganizationSubscriptionPlanResolvers<ContextType>;
  OrganizationSubscriptionPlanPipedriveDeal?: OrganizationSubscriptionPlanPipedriveDealResolvers<ContextType>;
  OrganizationUser?: OrganizationUserResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PluginData?: PluginDataResolvers<ContextType>;
  PluginMimeTypeData?: PluginMimeTypeDataResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  SasTokenResponse?: SasTokenResponseResolvers<ContextType>;
  SocialProfile?: SocialProfileResolvers<ContextType>;
  StoredAsset?: StoredAssetResolvers<ContextType>;
  StoredAssetMetadata?: StoredAssetMetadataResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SubscriptionFeature?: SubscriptionFeatureResolvers<ContextType>;
  SubscriptionPlan?: SubscriptionPlanResolvers<ContextType>;
  SubscriptionPlanName?: SubscriptionPlanNameResolvers<ContextType>;
  SubscriptionPlanPaddle?: SubscriptionPlanPaddleResolvers<ContextType>;
  SubscriptionPlanPaddleType?: SubscriptionPlanPaddleTypeResolvers<ContextType>;
  SubscriptionPlanPrice?: SubscriptionPlanPriceResolvers<ContextType>;
  SubscriptionPlanPricePeriod?: SubscriptionPlanPricePeriodResolvers<ContextType>;
  TLanguageKnown?: TLanguageKnownResolvers<ContextType>;
  TouchSupport?: TouchSupportResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserBackgroundDocument?: UserBackgroundDocumentResolvers<ContextType>;
  UserContact?: UserContactResolvers<ContextType>;
  UserContactChanges?: UserContactChangesResolvers<ContextType>;
  UserContext?: UserContextResolvers<ContextType>;
  UserDevice?: UserDeviceResolvers<ContextType>;
  UserFrequentFlyerDocument?: UserFrequentFlyerDocumentResolvers<ContextType>;
  UserHealthReport?: UserHealthReportResolvers<ContextType>;
  UserIdentityDocument?: UserIdentityDocumentResolvers<ContextType>;
  UserOtherDocument?: UserOtherDocumentResolvers<ContextType>;
  UserTravelDocument?: UserTravelDocumentResolvers<ContextType>;
  UserVaccinationDocument?: UserVaccinationDocumentResolvers<ContextType>;
}>;

export const namedOperations = {
  Query: {
    ConnectedAgencyOrganizationForCourierOrganization:
      'ConnectedAgencyOrganizationForCourierOrganization',
    ConnectedCourierOrganizationsForAgencyOrganization:
      'ConnectedCourierOrganizationsForAgencyOrganization',
    ConnectedAgencyOrganizationsForCourierOrganization:
      'ConnectedAgencyOrganizationsForCourierOrganization',
    Organizations: 'Organizations',
    OrganizationInvitationByActivationCodeForCourierOrganization:
      'OrganizationInvitationByActivationCodeForCourierOrganization',
    AvailableCourierUsersByDistanceForAgencyJob: 'AvailableCourierUsersByDistanceForAgencyJob',
    AllActiveAndUpcomingAvailabilitiesForPriojet: 'AllActiveAndUpcomingAvailabilitiesForPriojet',
    ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations:
      'ActiveAndUpcomingAvailabilitiesForConnectedCourierOrganizations',
    AvailabilitiesForUser: 'AvailabilitiesForUser',
    ActiveAvailabilitiesForUser: 'ActiveAvailabilitiesForUser',
    UserIdentityDocuments: 'UserIdentityDocuments',
    UserIdentityDocument: 'UserIdentityDocument',
    UserTravelDocuments: 'UserTravelDocuments',
    UserTravelDocument: 'UserTravelDocument',
    NumberOfNewNotificationsForOrganizationUser: 'NumberOfNewNotificationsForOrganizationUser',
    LatestOrNewSystemNotificationsForOrganizationUser:
      'LatestOrNewSystemNotificationsForOrganizationUser',
    SignInUserContext: 'SignInUserContext',
    OrganizationInvitationsForPriojetOrganization: 'OrganizationInvitationsForPriojetOrganization',
    CourierOrganizationInvitationsForAgencyOrganization:
      'CourierOrganizationInvitationsForAgencyOrganization',
    EmployeeInvitationsForAgencyOrganization: 'EmployeeInvitationsForAgencyOrganization',
    CourierUserProfileByUserId: 'CourierUserProfileByUserId',
    CurrentUserContext: 'CurrentUserContext',
    UserContexts: 'UserContexts',
    CourierUserProfileForCourierOrganizationUser: 'CourierUserProfileForCourierOrganizationUser',
    User: 'User',
    UserMinimal: 'UserMinimal',
    UserFrequentFlyerDocuments: 'UserFrequentFlyerDocuments',
    UserFrequentFlyerDocument: 'UserFrequentFlyerDocument',
    UserVaccinationDocuments: 'UserVaccinationDocuments',
    UserVaccinationDocument: 'UserVaccinationDocument',
    UserHealthReports: 'UserHealthReports',
    UserHealthReport: 'UserHealthReport',
    UserBackgroundDocuments: 'UserBackgroundDocuments',
    UserBackgroundDocument: 'UserBackgroundDocument',
    UserOtherDocuments: 'UserOtherDocuments',
    UserOtherDocument: 'UserOtherDocument',
    userWithAllUserDocuments: 'userWithAllUserDocuments',
    CourierJobForCourierOrganization: 'CourierJobForCourierOrganization',
    CourierJobForAgencyOrganization: 'CourierJobForAgencyOrganization',
    CourierJobsForCourierOrganization: 'CourierJobsForCourierOrganization',
    CourierJobsForCourierOrganizationMinimal: 'CourierJobsForCourierOrganizationMinimal',
    AgencyJobsForAgencyOrganizationPaginated: 'AgencyJobsForAgencyOrganizationPaginated',
    AgencyJobsForAgencyOrganizationMinimal: 'AgencyJobsForAgencyOrganizationMinimal',
    AgencyJobsForAgencyOrganization: 'AgencyJobsForAgencyOrganization',
    CourierJobsForAgencyJob: 'CourierJobsForAgencyJob',
    CourierJobsCourierOrganizationUsersForAgencyJob:
      'CourierJobsCourierOrganizationUsersForAgencyJob',
    AgencyJobForAgencyOrganization: 'AgencyJobForAgencyOrganization',
    AgencyJobForAgencyOrganizationForPlanning: 'AgencyJobForAgencyOrganizationForPlanning',
    AgencyJobForAgencyOrganizationInternalNotes: 'AgencyJobForAgencyOrganizationInternalNotes',
    AgencyCourierJobChat: 'AgencyCourierJobChat',
    AgencyJobChat: 'AgencyJobChat',
    ChatMessagesByChatId: 'ChatMessagesByChatId',
    ChatMessageEdge: 'ChatMessageEdge',
    OrganizationProfile: 'OrganizationProfile',
    OrganizationOrganizationUsers: 'OrganizationOrganizationUsers',
    UserById: 'UserById',
    MinSupportedAppVersion: 'MinSupportedAppVersion',
    DeletedAccountsPriojetOnly: 'DeletedAccountsPriojetOnly',
    organizationUserById: 'organizationUserById',
    subscriptionPlansAgency: 'subscriptionPlansAgency',
    subscriptionPlansCourier: 'subscriptionPlansCourier',
    OrganizationSubscriptionPlans: 'OrganizationSubscriptionPlans',
    AirportLocationListPaginated: 'AirportLocationListPaginated',
    AirportLocationList: 'AirportLocationList',
    AirportLocationSearch: 'AirportLocationSearch',
    AvailabilitiesForConnectedCourierOrganizations:
      'AvailabilitiesForConnectedCourierOrganizations',
    Availability: 'Availability',
    OrganizationCourierCategories: 'OrganizationCourierCategories',
    organizationInvitationUploadCSV: 'organizationInvitationUploadCSV',
    notificationSettingCurrentUser: 'notificationSettingCurrentUser',
    availabilityFindActiveAdhocCurrentUser: 'availabilityFindActiveAdhocCurrentUser',
    AvailableCourierUsersByDistanceForAgencyJobFiltered:
      'AvailableCourierUsersByDistanceForAgencyJobFiltered',
    AgencyJobPlanningForAgencyJob: 'AgencyJobPlanningForAgencyJob',
    ChatMessagesByChatIdForAgency: 'ChatMessagesByChatIdForAgency',
    ChatMessageEdgeForAgency: 'ChatMessageEdgeForAgency',
  },
  Mutation: {
    MarkAllNewSystemNotificationsAsReadForOrganizationUser:
      'MarkAllNewSystemNotificationsAsReadForOrganizationUser',
    MarkSystemNotificationAsReadForOrganizationUser:
      'MarkSystemNotificationAsReadForOrganizationUser',
    ConnectWithAgencyOrganizationAsCourierOrganization:
      'ConnectWithAgencyOrganizationAsCourierOrganization',
    AddDeviceLogUnauthenticated: 'AddDeviceLogUnauthenticated',
    AddDeviceLog: 'AddDeviceLog',
    ActivateAccount: 'ActivateAccount',
    FindOrCreateAdHocAvailability: 'FindOrCreateAdHocAvailability',
    CreateOrUpdateCourierUserContact: 'CreateOrUpdateCourierUserContact',
    CreateAvailability: 'CreateAvailability',
    DeleteAvailability: 'DeleteAvailability',
    DisableAdHocAvailability: 'DisableAdHocAvailability',
    RegisterExistingUserWithOwnedOrganization: 'RegisterExistingUserWithOwnedOrganization',
    VoidOrganizationInvitation: 'VoidOrganizationInvitation',
    CreateOrganizationInvitationSharing: 'CreateOrganizationInvitationSharing',
    CreateOrganizationInvitationAsPriojet: 'CreateOrganizationInvitationAsPriojet',
    EditOrganizationInvitation: 'EditOrganizationInvitation',
    GetOrCreateDevice: 'GetOrCreateDevice',
    UpdateDevice: 'UpdateDevice',
    CreateDeviceLocations: 'CreateDeviceLocations',
    CreateUserIdentityDocument: 'CreateUserIdentityDocument',
    UpdateCourierUserProfile: 'UpdateCourierUserProfile',
    UpdateUserProfile: 'UpdateUserProfile',
    deleteUserProfilePicture: 'deleteUserProfilePicture',
    CreateUserTravelDocument: 'CreateUserTravelDocument',
    CreateUserFrequentFlyerDocument: 'CreateUserFrequentFlyerDocument',
    CreateUserVaccinationDocument: 'CreateUserVaccinationDocument',
    CreateUserHealthReport: 'CreateUserHealthReport',
    CreateUserBackgroundDocument: 'CreateUserBackgroundDocument',
    CreateUserOtherDocument: 'CreateUserOtherDocument',
    UpdateRelationshipTypeForCourierJobAsCourierOrganization:
      'UpdateRelationshipTypeForCourierJobAsCourierOrganization',
    UpdateRelationshipTypeForCourierJobAsAgencyOrganization:
      'UpdateRelationshipTypeForCourierJobAsAgencyOrganization',
    AddOrDisableCourierJob: 'AddOrDisableCourierJob',
    AddOrDisableCourierForCourierJob: 'AddOrDisableCourierForCourierJob',
    ChangeAgencyJobStatusTypeForAgencyOrganization:
      'ChangeAgencyJobStatusTypeForAgencyOrganization',
    CreateOrUpdateJobForAgencyOrganization: 'CreateOrUpdateJobForAgencyOrganization',
    CreateChatMessage: 'CreateChatMessage',
    UpdateChatMessage: 'UpdateChatMessage',
    DeleteChatMessage: 'DeleteChatMessage',
    UpdateAgencyOrganizationProfile: 'UpdateAgencyOrganizationProfile',
    UpdateAgencyOrganizationVatId: 'UpdateAgencyOrganizationVatId',
    deleteOrganizationLogo: 'deleteOrganizationLogo',
    UpdateCourierOrganizationProfile: 'UpdateCourierOrganizationProfile',
    DeleteUserBackgroundDocument: 'DeleteUserBackgroundDocument',
    DeleteUserFrequentFlyerDocument: 'DeleteUserFrequentFlyerDocument',
    DeleteUserHealthReport: 'DeleteUserHealthReport',
    DeleteUserIdentityDocument: 'DeleteUserIdentityDocument',
    UserOtherDocumentDelete: 'UserOtherDocumentDelete',
    DeleteUserTravelDocument: 'DeleteUserTravelDocument',
    DeleteUserVaccinationDocument: 'DeleteUserVaccinationDocument',
    DeleteAgencyAccount: 'DeleteAgencyAccount',
    DeleteCourierAccount: 'DeleteCourierAccount',
    UserDeleteAccount: 'UserDeleteAccount',
    RestoreAgencyAccount: 'RestoreAgencyAccount',
    RestoreCourierAccount: 'RestoreCourierAccount',
    UserAccountRestore: 'UserAccountRestore',
    organizationUserUpdateProfile: 'organizationUserUpdateProfile',
    CreateOrUpdateAgencyEmployeeUserContact: 'CreateOrUpdateAgencyEmployeeUserContact',
    OrganizationInvitationDelete: 'OrganizationInvitationDelete',
    OrganizationUserRemoveOrganizationUserFromOrganization:
      'OrganizationUserRemoveOrganizationUserFromOrganization',
    OrganizationSubscriptionPlanCancel: 'OrganizationSubscriptionPlanCancel',
    AirportLocationUpdateTimezone: 'AirportLocationUpdateTimezone',
    organizationCourierCategoriesUpdate: 'organizationCourierCategoriesUpdate',
    agencyCourierUserProfileCreateOrUpdate: 'agencyCourierUserProfileCreateOrUpdate',
    agencyJobUpdateInternalNotes: 'agencyJobUpdateInternalNotes',
    notificationSettingUpdate: 'notificationSettingUpdate',
    authSignOutUserContext: 'authSignOutUserContext',
    AgencyJobPlanningForAgencyJobUpdate: 'AgencyJobPlanningForAgencyJobUpdate',
    agencyJobPlanningItemUpdate: 'agencyJobPlanningItemUpdate',
    agencyJobPlanningItemReset: 'agencyJobPlanningItemReset',
    CreateChatMessageForAgency: 'CreateChatMessageForAgency',
    UpdateChatMessageForAgency: 'UpdateChatMessageForAgency',
  },
  Subscription: {
    OnChatMessageSent: 'OnChatMessageSent',
  },
  Fragment: {
    NewUserTravelDocument: 'NewUserTravelDocument',
    NewUserIdentityDocument: 'NewUserIdentityDocument',
    NewUserFrequentFlyerDocument: 'NewUserFrequentFlyerDocument',
    NewUserVaccinationDocument: 'NewUserVaccinationDocument',
    NewUserHealthReport: 'NewUserHealthReport',
    NewUserBackgroundDocument: 'NewUserBackgroundDocument',
    NewUserOtherDocument: 'NewUserOtherDocument',
    NewChatMessage: 'NewChatMessage',
    NewAgencyOrganizationProfile: 'NewAgencyOrganizationProfile',
    NewCourierUserProfile: 'NewCourierUserProfile',
  },
};

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;
