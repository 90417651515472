import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RefreshControl, ScrollView } from 'react-native';

import { useLazyQuery } from '@apollo/client';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Divider, StyleService, Tab, TabBar, Text, useStyleSheet } from '@ui-kitten/components';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { ACTIVE_AND_UPCOMING_AVAILABILITIES_FOR_CONNECTED_COURIER_ORGANIZATIONS_QUERY } from '../../apollo/graphql-queries';
import { globalStyle } from '../../common/style';
import { CourierAvailabilities } from '../../components/common/courier-availabilities/courier-availabilities.component';
import { TopNavigationWithoutAccessories } from '../../components/top-navigation-without-accessories.component';
import AppUserContext from '../../contexts/AppUserContext';
import { Availability, OrganizationType } from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';

const { Navigator, Screen } = createMaterialTopTabNavigator();

interface IAvailabilitiesData {
  availabilitiesActive: Availability[];
  availabilitiesUpcoming: Availability[];
  availabilitiesPast: Availability[];
}

const sortActiveAvailabilities = (a: Availability, b: Availability) => {
  if (a.isAdHocAvailability && !b.isAdHocAvailability) {
    return -1;
  } else if (!a.isAdHocAvailability && b.isAdHocAvailability) {
    return 1;
  } else if (Date.parse(a.startTime.toString()) < Date.parse(b.startTime.toString())) {
    return -1;
  }
  return 1;
};

export const AgencyCourierAvailabilitiesScreen: React.FC<{
  navigation: any;
}> = () => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const [currentTime, setCurrentTime] = useState(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(DateTime.now());
    }, 1000 * 60);
    return () => clearInterval(interval);
  });

  const [loadAvailabilities, { error, loading, refetch, data }] = useLazyQuery(
    ACTIVE_AND_UPCOMING_AVAILABILITIES_FOR_CONNECTED_COURIER_ORGANIZATIONS_QUERY,
    {
      variables: {
        agencyOrganizationId: appUserContext.currentUserContext?.organization?.id as string, // will always be a string in this case.
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
    },
  );

  useEffect(() => {
    if (appUserContext?.currentUserContext && !loading && !data) {
      loadAvailabilities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserContext?.currentUserContext, data, loading]);

  const { availabilitiesActive, availabilitiesUpcoming, availabilitiesPast }: IAvailabilitiesData =
    useMemo((): IAvailabilitiesData => {
      if (data?.activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations?.length) {
        const availabilitiesForUser =
          data.activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations as Availability[];
        const unsortedData = availabilitiesForUser.reduce(
          (acc: IAvailabilitiesData, current: Availability) => {
            if (!current.endTime || current.endTime >= currentTime) {
              if (current.isAdHocAvailability || current.startTime < currentTime) {
                acc.availabilitiesActive.push(current);
              } else {
                acc.availabilitiesUpcoming.push(current);
              }
            } else {
              acc.availabilitiesPast.push(current);
            }
            return acc;
          },
          {
            availabilitiesActive: [],
            availabilitiesUpcoming: [],
            availabilitiesPast: [],
          },
        ) as IAvailabilitiesData;

        return {
          availabilitiesActive: unsortedData.availabilitiesActive.sort(sortActiveAvailabilities),
          availabilitiesUpcoming:
            unsortedData.availabilitiesUpcoming.sort(sortActiveAvailabilities),
          availabilitiesPast: unsortedData.availabilitiesPast.sort((a, b) => {
            if (a.isAdHocAvailability && !b.isAdHocAvailability) {
              return -1;
            } else if (!a.isAdHocAvailability && b.isAdHocAvailability) {
              return 1;
            } else if (
              Date.parse(a.endTime?.toString() as string) >
              Date.parse(b.endTime?.toString() as string)
            ) {
              return -1;
            }
            return 1;
          }),
        };
      }
      return { availabilitiesActive: [], availabilitiesUpcoming: [], availabilitiesPast: [] };
    }, [currentTime, data?.activeAndUpcomingAvailabilitiesForConnectedCourierOrganizations]);

  const handleRefresh = () => {
    setCurrentTime(DateTime.now());
    refetch();
  };

  const renderTopTabBar = ({ navigation, state }: { navigation: any; state: any }) => {
    return (
      <TabBar
        selectedIndex={state.index}
        onSelect={(index) => navigation.navigate(state.routeNames[index])}
        style={globalStyle.height50}
      >
        <Tab title={t('common.active', { defaultValue: 'ACTIVE' }) as string} />
        <Tab title={t('common.upcoming', { defaultValue: 'UPCOMING' }) as string} />
        <Tab title={t('common.finished', { defaultValue: 'FINISHED' }) as string} />
      </TabBar>
    );
  };

  if (!appUserContext?.currentUserContext) {
    return null;
  }
  if (error) {
    return (
      <>
        <TopNavigationWithoutAccessories
          title={t('common.courierAvailabilities', {
            defaultValue: 'Courier Availabilities',
          })}
        />
        <Divider />
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          refreshControl={
            <RefreshControl
              onRefresh={refetch}
              refreshing={loading}
              title="Loading Courier Availabilities..."
            />
          }
          style={styles.padding20}
        >
          <Text selectable={true}>An error occurred: {error.message}</Text>
          <Text>Please pull down to refresh. Otherwise, please restart the app and try again.</Text>
        </ScrollView>
      </>
    );
  }
  return (
    <>
      <TopNavigationWithoutAccessories
        title={t('common.courierAvailabilities', {
          defaultValue: 'Courier Availabilities',
        })}
      />
      <Navigator tabBar={renderTopTabBar}>
        <Screen name="AgencyCourierActiveAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesActive}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={
                appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
              }
            />
          )}
        </Screen>
        <Screen name="AgencyCourierUpcomingAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesUpcoming}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={
                appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
              }
            />
          )}
        </Screen>
        <Screen name="AgencyCourierPastAvailabilitiesScreen">
          {() => (
            <CourierAvailabilities
              isLoading={loading}
              availabilities={availabilitiesPast}
              refetch={handleRefresh}
              showCreateAvailabilityLayer={
                appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER
              }
            />
          )}
        </Screen>
      </Navigator>
    </>
  );
};

const themedStyles = StyleService.create({
  padding20: {
    padding: 20,
  },
});
