import { useEffect, useMemo, useState } from 'react';

import { DateTime, Duration } from 'luxon';

import { convertDateToLuxon } from '../utils/date-time.util';

const useCountdown = (date?: DateTime | null): Duration | undefined => {
  const targetDate = useMemo(() => convertDateToLuxon(date), [date]);

  const [countDown, setCountDown] = useState<number | undefined>(
    targetDate ? targetDate?.toMillis() - DateTime.now().toMillis() : undefined,
  );

  useEffect(() => {
    let interval: any;
    if (targetDate) {
      const countDownMillis = targetDate.toMillis();
      interval = setInterval(() => {
        setCountDown(countDownMillis - DateTime.now().toMillis());
      }, 1000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [targetDate]);

  return targetDate ? getCountDownValues(countDown) : undefined;
};

const getCountDownValues = (countDownInMillis: number | undefined): Duration | undefined => {
  if (!countDownInMillis) {
    return undefined;
  }

  const days = Math.floor(countDownInMillis / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDownInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDownInMillis % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDownInMillis % (1000 * 60)) / 1000);

  return Duration.fromObject({ days, hours, minutes, seconds });
};

export { useCountdown };
