import React, { useContext, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Constants from 'expo-constants';
import * as ExpoImagePicker from 'expo-image-picker';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import * as Yup from 'yup';

import {
  COURIER_EDIT_PROFILE_INITIAL_VALUES,
  COURIER_EDIT_PROFILE_NUMBER_KEYS,
  COURIER_EDIT_PROFILE_NOT_UPLOADABLE_FIELDS,
  COURIER_EDIT_PROFILE_USER_FIELDS,
  COURIER_EDIT_PROFILE_LOCATION_FIELDS,
} from './constants';
import { getAuthorizationHeaders } from '../../../apollo/create-auth-link-with-headers';
import {
  DELETE_USER_PROFILE_PICTURE_MUTATION,
  UPDATE_COURIER_ORGANIZATION_PROFILE_MUTATION,
  UPDATE_COURIER_USER_PROFILE_MUTATION,
  UPDATE_USER_PROFILE_MUTATION,
} from '../../../apollo/graphql-mutations';
import {
  COURIER_USER_PROFILE_BY_USER_ID_QUERY,
  ORGANIZATION_PROFILE_QUERY,
  USER_QUERY,
} from '../../../apollo/graphql-queries';
import { CountryListData } from '../../../assets/constants/country.list.data';
import { LanguageListData } from '../../../assets/constants/language.list.data';
import { GooglePlacesAutocompleteLocation } from '../../../components/common/google-places-autocomplete.component';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  LanguageSkillLevel,
  LocationManualInput,
  SocialProfile,
  StoredAsset,
  User,
  Location,
} from '../../../generated-graphql-types';
import {
  CourierUserProfileByUserId,
  CourierUserProfileByUserId_courierUserProfileByUserId,
  LanguagesKnownInput,
  OrganizationProfile,
} from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { createSocialURL, SOCIAL_KEYS, SOCIAL_OPTIONS } from '../../../utils/social-media.util';
import { isValidEmail, isValidURL } from '../../../utils/validate-field.util';

export const useHook = () => {
  const { t } = useTranslation();

  const appUserContext = useContext(AppUserContext);
  const isBackendReachable = useIsBackendReachable();

  const headquartersAddressLocationRef: any = useRef(null);
  const residenceAddressRef: any = useRef(null);

  const [completedUserProfile, setCompletedUserProfile] = useState(0);
  const [completedCourierUserProfile, setCompletedCourierUserProfile] = useState(0);
  const [completedOrganizationProfile, setCompletedOrganizationProfile] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<
    { name: string; code: string } | undefined
  >();
  const [selectedLanguageLevel, setSelectedLanguageLevel] = useState<LanguageSkillLevel>();
  const [radioGroupVisible, setRadioGroupVisible] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState(false);
  const [temporaryProfilePicture, setTemporaryProfilePicture] = useState<
    ExpoImagePicker.ImagePickerAsset | undefined
  >();
  const [userProfilePicture, setUserProfilePicture] = useState<StoredAsset | null | undefined>();

  const [updateCourierUserProfileMutation] = useMutation(UPDATE_COURIER_USER_PROFILE_MUTATION);
  const [updateUserProfileMutation] = useMutation(UPDATE_USER_PROFILE_MUTATION);
  const [deleteUserProfilePictureMutation, { loading: loadingDeletePicture }] = useMutation(
    DELETE_USER_PROFILE_PICTURE_MUTATION,
  );
  const [updateCourierOrganization] = useMutation(UPDATE_COURIER_ORGANIZATION_PROFILE_MUTATION);

  // Profile Picture
  const handleAvatarButtonVisibility = () => {
    setRadioGroupVisible((prevState) => !prevState);
  };

  const uploadProfilePicture = async (image: ExpoImagePicker.ImagePickerAsset | undefined) => {
    if (image) {
      const authHeaders = await getAuthorizationHeaders();
      const lastIndexOfDot = image.uri.lastIndexOf('.');
      const lastIndexOfSlash = image.uri.lastIndexOf('/');
      const fileType = image.uri.substring(lastIndexOfDot + 1);
      const fileName = image.uri.substring(lastIndexOfSlash + 1);
      try {
        const response = await fetch(
          Constants.expoConfig?.extra?.priojetBackendUploadProfilePictureEndpoint,
          {
            method: 'post',
            headers: {
              ...authHeaders,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file: image?.base64,
              fileType,
              fileName,
              uri: image?.uri,
              exif: image?.exif,
              height: image?.height,
              width: image?.width,
            }),
          },
        );

        if (response) {
          response.json().then(function (data) {
            setUserProfilePicture(data);
            setTemporaryProfilePicture(undefined);
          });
        }
      } catch {
        showMessage({
          message: t('courierUserProfile.error', { defaultValue: 'Error' }),
          description: t('courierUserProfile.errorUploadingLogoImage', {
            defaultValue: 'Something went wrong with uploading logo image',
          }) as string,
          type: 'danger',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    }
  };

  const handleDeleteUserProfilePicture = async () => {
    try {
      await deleteUserProfilePictureMutation();
      setUserProfilePicture(undefined);
      setTemporaryProfilePicture(undefined);
    } catch {
      showMessage({
        message: t('courierUserProfile.error', { defaultValue: 'Error' }),
        description: t('courierUserProfile.errorDeletingProfilePicture', {
          defaultValue: 'Something went wrong with deleting profile picture',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const handleSave = async () => {
    const errors = await formik.validateForm();
    ['facebook', 'linkedin', 'youtube', 'xing'].forEach((field) => {
      const url = createSocialURL({ platform: field, value: formik.values[field] || '' });
      if (!isValidURL({ value: url, canBeEmpty: true })) {
        const errorField = t('courierUserProfile.invalidUrl', {
          defaultValue: 'Must be a valid URL',
        });
        errors[field] = errorField;
      } else if (errors.hasOwnProperty(field)) {
        delete errors[field];
      }
    });
    formik.setErrors(errors);
    if (Object.keys(errors).length) {
      showMessage({
        message: t('courierUserProfile.error', { defaultValue: 'Error' }),
        description:
          t('courierUserProfile.someFieldAreNotValid', {
            defaultValue: 'Some fields are not valid',
          }) +
          `.\n\n${Object.keys(errors)
            .map((key) => `${key} - ${errors[key]}`)
            .join('\n')}`,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const userProfileInput: any = {
      id: appUserContext.currentUserContext?.user?.id,
    };
    const courierOrganizationProfileInput: any = {
      id: appUserContext?.currentUserContext?.organization?.id,
      name: organizationData?.organization?.name,
    };
    const courierUserProfileInput: any = {
      id: courierUserProfileQueryData?.courierUserProfileByUserId.id,
    };

    const userRegisteredAddressLocation = await residenceAddressRef.current?.getValues();
    if (userRegisteredAddressLocation?.isValid) {
      const userRegisteredAddressLocationData: LocationManualInput = {};
      Object.keys(userRegisteredAddressLocation.values).forEach((locKey) => {
        const locationKey = locKey as keyof LocationManualInput;
        if (COURIER_EDIT_PROFILE_LOCATION_FIELDS.includes(locationKey)) {
          userRegisteredAddressLocationData[locationKey] =
            userRegisteredAddressLocation.values[locationKey];
        }
      });
      userProfileInput.userRegisteredAddressLocation = userRegisteredAddressLocationData;
    } else if (Object.keys(userRegisteredAddressLocation.errors).length) {
      showMessage({
        message: t('courierUserProfile.error', { defaultValue: 'Error' }),
        description:
          t('courierUserProfile.someFieldAreNotValid', {
            defaultValue: 'Some fields are not valid',
          }) +
          `.\n\n${Object.keys(userRegisteredAddressLocation.errors)
            .map(
              (key) =>
                `${t('courierUserProfile.residenceAddress', {
                  defaultValue: 'Residence address',
                })} -${key} - ${userRegisteredAddressLocation.errors[key].toLowerCase()}`,
            )
            .join('\n')}`,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const headquartersAddressLocationForm =
      await headquartersAddressLocationRef.current?.getValues();
    if (headquartersAddressLocationForm?.isValid) {
      const headquartersAddressLocation: LocationManualInput = {};
      Object.keys(headquartersAddressLocationForm.values).forEach((locKey) => {
        const locationKey = locKey as keyof LocationManualInput;
        if (COURIER_EDIT_PROFILE_LOCATION_FIELDS.includes(locationKey)) {
          headquartersAddressLocation[locationKey] =
            headquartersAddressLocationForm.values[locationKey];
        }
      });
      courierOrganizationProfileInput.headquartersAddressLocation = headquartersAddressLocation;
    } else if (Object.keys(headquartersAddressLocationForm?.errors).length) {
      showMessage({
        message: t('courierUserProfile.error', { defaultValue: 'Error' }),
        description:
          t('courierUserProfile.someFieldAreNotValid', {
            defaultValue: 'Some fields are not valid',
          }) +
          `.\n\n${Object.keys(headquartersAddressLocationForm?.errors)
            .map(
              (key) =>
                `${t('courierUserProfile.companyAddress', {
                  defaultValue: 'Company address',
                })} -${key} - ${headquartersAddressLocationForm?.errors[key].toLowerCase()}`,
            )
            .join('\n')}`,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const socialProfiles: SocialProfile[] = [];
    const baseAirportLocationIds: string[] = [];
    Object.keys(formik.values).forEach((key) => {
      if (COURIER_EDIT_PROFILE_USER_FIELDS.includes(key)) {
        userProfileInput[key] = formik.values[key];
      } else if (key === 'languagesKnown') {
        courierUserProfileInput[key] = formik.values[key].map((item: LanguagesKnownInput) => ({
          language: item.language,
          level: item.level,
        }));
      } else if (SOCIAL_KEYS.includes(key)) {
        socialProfiles.push({
          platform: key,
          value: formik.values[key],
          type: SOCIAL_OPTIONS[key].type,
        });
      } else if (key.includes('baseAirport')) {
        if (formik.values[key]?.id) {
          baseAirportLocationIds.push(formik.values[key].id);
        }
      } else if (key === 'legalName') {
        courierOrganizationProfileInput[key] = formik.values[key];
      } else if (!COURIER_EDIT_PROFILE_NOT_UPLOADABLE_FIELDS.includes(key)) {
        if (COURIER_EDIT_PROFILE_NUMBER_KEYS.includes(key)) {
          if (formik.values[key]) {
            courierUserProfileInput[key] = parseFloat(formik.values[key]);
          }
        } else {
          courierUserProfileInput[key] = formik.values[key];
        }
      }
    });
    courierUserProfileInput.baseAirportLocationIds = baseAirportLocationIds;
    courierUserProfileInput.socialProfiles = socialProfiles;

    setLoading(true);
    try {
      uploadProfilePicture(temporaryProfilePicture);
      await updateCourierUserProfileMutation({
        variables: { courierUserProfileInput },
      });
      await updateUserProfileMutation({
        variables: { userProfileInput },
      });
      await updateCourierOrganization({
        variables: { courierOrganizationProfileInput },
      });

      showMessage({
        message: t('courierUserProfile.success', { defaultValue: 'Success' }),
        description: t('courierUserProfile.profileUpdated', {
          defaultValue: 'Courier profile successfully updated',
        }) as string,
        type: 'success',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    } catch (e: any) {
      console.warn(e);
      let description = t('courierUserProfile.somethingWentWrong', {
        defaultValue: 'Something went wrong',
      });
      if (
        e.message.includes('duplicate key value violates unique constraint "user_email2_unique"')
      ) {
        description = t('courierUserProfile.emailAlreadyInUse', {
          defaultValue: 'Email already in use',
        });
        formik.setFieldError('email2', description);
      }

      showMessage({
        message: t('courierUserProfile.error', { defaultValue: 'Error' }),
        description: description,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    setLoading(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().test(
      'test-valid-email',
      t('courierUserProfile.invalidEmail', {
        defaultValue: 'Must be a valid email',
      }) as string,
      (value) => isValidEmail({ value, canBeEmpty: false }),
    ),
    firstNames: Yup.string().required(
      t('courierUserProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    lastName: Yup.string().required(
      t('courierUserProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    sex: Yup.string().required(
      t('courierUserProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    dateOfBirth: Yup.string().required(
      t('courierUserProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    placeOfBirth: Yup.string().required(
      t('courierUserProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    countryOfBirth: Yup.string().required(
      t('courierUserProfile.requiredField', { defaultValue: 'Must be set' }) as string,
    ),
    email2: Yup.string(),

    linkedin: Yup.string(),
    facebook: Yup.string(),
    youtube: Yup.string(),
    xing: Yup.string(),

    amexCreditCardLimit: Yup.string().matches(
      /^[0-9.,]+$/,
      t('courierUserProfile.numericalValue', {
        defaultValue: 'Must set a numerical value',
      }) as string,
    ),
    dinerCreditCardLimit: Yup.string().matches(
      /^[0-9.,]+$/,
      t('courierUserProfile.numericalValue', {
        defaultValue: 'Must set a numerical value',
      }) as string,
    ),
    masterCreditCardLimit: Yup.string().matches(
      /^[0-9.,]+$/,
      t('courierUserProfile.numericalValue', {
        defaultValue: 'Must set a numerical value',
      }) as string,
    ),
    visaCreditCardLimit: Yup.string().matches(
      /^[0-9.,]+$/,
      t('courierUserProfile.numericalValue', {
        defaultValue: 'Must set a numerical value',
      }) as string,
    ),

    numberOfPastObcJobs: Yup.string().matches(
      /^[0-9.,]+$/,
      t('courierUserProfile.numericalValue', {
        defaultValue: 'Must set a numerical value',
      }) as string,
    ),
  });

  const formik: any = useFormik({
    initialValues: COURIER_EDIT_PROFILE_INITIAL_VALUES,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const onCompletedOrganizationProfile = ({ organization }: OrganizationProfile) => {
    if (completedOrganizationProfile < 2) {
      setCompletedOrganizationProfile((prev) => prev + 1);
      formik.setFieldValue('legalName', organization.legalName);

      if (
        formik.values.userRegisteredAddressLocation?.placeId &&
        organization?.headquartersAddressLocation?.placeId &&
        formik.values.userRegisteredAddressLocation?.placeId ===
          organization?.headquartersAddressLocation?.placeId
      ) {
        formik.setFieldValue('headquartersAddressSame', true);
      }
    }
  };

  const onCompletedUserProfile = ({ user }: { user: User }) => {
    if (completedUserProfile < 2) {
      setCompletedUserProfile((prev) => prev + 1);
      const newValues: any = {};
      Object.keys(user).map((k) => {
        const key = k as keyof User;
        if (user[key]) {
          newValues[key] = user[key];
        }
      });

      if (
        formik.values.headquartersAddressLocation?.placeId &&
        user?.userRegisteredAddressLocation?.placeId &&
        formik.values.headquartersAddressLocation?.placeId ===
          user?.userRegisteredAddressLocation?.placeId
      ) {
        newValues.headquartersAddressSame = true;
      }

      if (user.countryOfBirth) {
        const countryOfBirth = CountryListData.find((item) => item.code === user.countryOfBirth);
        if (countryOfBirth) {
          newValues.countryOfBirth = countryOfBirth.code;
        }
      }
      formik.setValues({ ...formik.values, ...newValues });
      if (user.profilePicture) {
        setUserProfilePicture(user.profilePicture);
      }
    }
  };

  const onCompletedCourierUserProfile = ({
    courierUserProfileByUserId: courierUserProfile,
  }: CourierUserProfileByUserId) => {
    if (completedCourierUserProfile < 2) {
      setCompletedCourierUserProfile((prev) => prev + 1);
      const newValues: any = { languagesKnown: [] };

      Object.keys(courierUserProfile).map((k) => {
        const key = k as keyof CourierUserProfileByUserId_courierUserProfileByUserId;
        if (courierUserProfile[key]) {
          if (COURIER_EDIT_PROFILE_NUMBER_KEYS.includes(key)) {
            newValues[key] = `${courierUserProfile[key]}`;
          } else if (k === 'baseAirports') {
            const baseAirports: any = courierUserProfile?.baseAirportLocations;
            newValues.baseAirport1 = baseAirports[0];
            newValues.baseAirport2 = baseAirports[1];
            newValues.baseAirport3 = baseAirports[2];
          } else if (k === 'socialProfiles') {
            if (courierUserProfile.socialProfiles?.length) {
              courierUserProfile.socialProfiles.forEach(
                (item: { platform: string; value: string }) => {
                  newValues[item.platform] = item.value;
                },
              );
            }
          } else if (courierUserProfile[key]) {
            newValues[key] = courierUserProfile[key];
          }
        }
      });

      formik.setValues({ ...formik.values, ...newValues });
    }
  };

  const { data: organizationData, refetch: organizationDataRefetch } = useQuery<any>(
    ORGANIZATION_PROFILE_QUERY,
    {
      variables: { id: appUserContext.currentUserContext?.organization?.id },
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
      onCompleted: onCompletedOrganizationProfile,
    },
  );
  const { data: userProfileQueryData, refetch: userProfileQueryRefetch } = useQuery<any>(
    USER_QUERY,
    {
      variables: { id: appUserContext.currentUserContext?.user?.id },
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
      onCompleted: onCompletedUserProfile,
    },
  );
  const { data: courierUserProfileQueryData, refetch: courierUserProfileQueryRefetch } =
    useQuery<CourierUserProfileByUserId>(COURIER_USER_PROFILE_BY_USER_ID_QUERY, {
      variables: {
        userId: appUserContext.currentUserContext?.user?.id,
      },
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
      onCompleted: onCompletedCourierUserProfile,
    });

  const user = userProfileQueryData?.user || {};

  const profilePicture = useMemo(() => {
    if (temporaryProfilePicture) {
      return temporaryProfilePicture;
    }
    if (userProfilePicture && userProfilePicture.sasUrl) {
      return { uri: userProfilePicture.sasUrl };
    }
  }, [temporaryProfilePicture, userProfilePicture]);

  const nationalities = useMemo(() => {
    if (userProfileQueryData?.user?.nationalities?.length) {
      let _nationalities = '';
      CountryListData.forEach((item) => {
        if (userProfileQueryData.user.nationalities.includes(item.code)) {
          if (!_nationalities.length) {
            _nationalities = item.name;
          } else {
            _nationalities += `, ${item.name}`;
          }
        }
      });
      return _nationalities;
    }
    return '';
  }, [userProfileQueryData?.user?.nationalities]);

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    let { error: fieldError } = formik.getFieldMeta(field);

    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        caption: '',
        checked: data.value,
        errorMessage: fieldError,
        onChange,
        status: fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      if (type !== 'number' || !value.length || value.match(/^\d*(\.\d+)?$/)) {
        formik.setFieldValue(field, value);
      }
      if (['facebook', 'linkedin', 'youtube', 'xing'].includes(field)) {
        const url = createSocialURL({ platform: field, value: value || '' });
        if (!isValidURL({ value: url, canBeEmpty: true })) {
          fieldError = t('courierUserProfile.invalidUrl', {
            defaultValue: 'Must be a valid URL',
          }) as string;
        } else {
          fieldError = '';
        }
        formik.setFieldError(field, fieldError);
      } else if (fieldError) {
        setTimeout(() => {
          formik.validateField(field);
        }, 50);
      }
    };

    const common = {
      caption: '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value || '',
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const selectedLanguages = useMemo(() => {
    let data = formik.values.languagesKnown;
    if (data.length) {
      data = data.map((item: LanguagesKnownInput) => {
        const found = LanguageListData.find((x) => x.code === item.language);
        return { ...item, ...found };
      });
      return data;
    }
    return [];
  }, [formik.values.languagesKnown]);

  const languagesList = useMemo(() => {
    const data = formik.values.languagesKnown.map((item: LanguagesKnownInput) => item.language);
    if (data.length) {
      return LanguageListData.filter((item: any) => !data.includes(item.code));
    }
    return LanguageListData;
  }, [formik.values.languagesKnown]);

  const sameLocation = useMemo(
    () =>
      !!Object.keys(formik.values.userRegisteredAddressLocation).some(
        (key) =>
          (key === 'types' && formik.values.userRegisteredAddressLocation[key].length) ||
          (key !== 'types' && formik.values.userRegisteredAddressLocation[key]),
      ),
    [formik.values.userRegisteredAddressLocation],
  );

  // image picker
  const pickImageMobile = async () => {
    const { status } = await ExpoImagePicker.requestCameraPermissionsAsync();
    if (status !== 'granted') {
      showMessage({
        message: t('courierUserProfile.error', { defaultValue: 'Error' }),
        description: t('courierUserProfile.noCameraPermissions', {
          defaultValue: 'Sorry, we need camera roll permissions to make this work!',
        }) as string,
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    const camPermission = await ExpoImagePicker.getCameraPermissionsAsync();
    if (camPermission.status === 'granted') {
      const result = await ExpoImagePicker.launchCameraAsync({
        mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
        base64: true,
      });
      if (!result.canceled && result.assets?.length) {
        setTemporaryProfilePicture(result.assets[0]);
      }
    }
  };
  const pickImageWeb = async () => {
    const result = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
      base64: true,
    });
    if (!result.canceled && result.assets?.length) {
      setTemporaryProfilePicture(result.assets[0]);
    }
  };

  const addCourierUserProfileLanguagesKnown = (languageLevel: LanguageSkillLevel) => {
    if (selectedLanguage && languageLevel) {
      formik.setFieldValue('languagesKnown', [
        ...formik.values.languagesKnown,
        {
          language: selectedLanguage.code,
          level: languageLevel,
        },
      ]);
      setSelectedLanguage(undefined);
      setSelectedLanguageLevel(undefined);
    }
  };

  const handleRemoveLanguage = (language: LanguagesKnownInput) => () => {
    let filteredList = [...formik.values.languagesKnown].filter(
      (obj) => obj.language !== language.language,
    );
    formik.setFieldValue('languagesKnown', filteredList);
  };

  const handleObcExperiencePress = (v: any): void => {
    if (v) {
      formik.setFieldValue('hasObcExperience', true);
    } else {
      formik.setValues({
        ...formik.values,
        hasObcExperience: false,
        hasMedicalObcExperience: false,
        firstObcJobDateAt: null,
        numberOfPastObcJobs: '',
      });
    }
  };

  const handleSameHeadquartersAddress = async () => {
    if (!formik.values.headquartersAddressSame) {
      const residenceAddressForm = await residenceAddressRef.current?.getValues();
      headquartersAddressLocationRef.current.setValues({
        ...residenceAddressForm.values,
        id: organizationData?.organization?.headquartersAddressLocation?.id || '',
      });
    }
    formik.setFieldValue('headquartersAddressSame', !formik.values.headquartersAddressSame);
  };

  const handleHeadquartersAddressChange = (
    googlePlacesAutocompleteLocation: GooglePlacesAutocompleteLocation | undefined,
  ) => {
    const isHeadquartersAddressSame =
      !!formik.values.userRegisteredAddressLocation?.placeId &&
      !!googlePlacesAutocompleteLocation?.placeId &&
      formik.values.userRegisteredAddressLocation?.placeId ===
        googlePlacesAutocompleteLocation?.placeId;
    formik.setFieldValue('headquartersAddressSame', isHeadquartersAddressSame);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await userProfileQueryRefetch();
    await organizationDataRefetch();
    await courierUserProfileQueryRefetch();
    setRefreshing(false);
  };

  const handleSelectAutocomplete =
    ({ valueKey }: any) =>
    (value: any) => {
      if (value) {
        formik?.setFieldValue(valueKey, value);
        setTimeout(() => {
          if (formik.errors[valueKey]) {
            formik.validateField(valueKey);
          }
        }, 100);
      } else {
        formik?.setFieldValue(valueKey, null);
      }
    };

  const handleSelect =
    ({ autocomplete, options, fieldValueKey, valueKey }: any) =>
    (value: any) => {
      if (autocomplete) {
        handleSelectAutocomplete({ options, fieldValueKey, valueKey })(value);
        return;
      }
      if (value) {
        formik?.setFieldValue(valueKey, value);
        setTimeout(() => {
          if (formik.errors[valueKey]) {
            formik.validateField(valueKey);
          }
        }, 100);
      } else {
        formik?.setFieldValue(valueKey, null);
      }
    };

  const handleSelectAirport = (field: string) => (value: string, option: Location) => {
    if (value) {
      formik?.setFieldValue(field, option);
      setTimeout(() => {
        if (formik.errors[field]) {
          formik.validateField(field);
        }
      }, 100);
    } else {
      formik?.setFieldValue(field, null);
    }
  };

  return {
    addCourierUserProfileLanguagesKnown,
    fieldProps,
    formik,
    handleHeadquartersAddressChange,
    handleObcExperiencePress,
    handleRemoveLanguage,
    handleSameHeadquartersAddress,
    handleAvatarButtonVisibility,
    handleDeleteUserProfilePicture,
    handleRefresh,
    handleSelect,
    handleSelectAirport,
    handleSave,
    headquartersAddressLocationRef,
    isDataLoaded:
      completedUserProfile >= 1 &&
      completedCourierUserProfile >= 1 &&
      completedOrganizationProfile >= 1,
    languagesList,
    loading,
    loadingDeletePicture,
    nationalities,
    organizationData,
    pickImageMobile,
    pickImageWeb,
    profilePicture,
    radioGroupVisible,
    residenceAddressRef,
    refreshing,
    selectedLanguage,
    setSelectedLanguage,
    setSelectedLanguageLevel,
    sameLocation,
    selectedLanguages,
    selectedLanguageLevel,
    user,
    userProfilePicture,
  };
};
