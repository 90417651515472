import { useContext, useMemo, useReducer, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { INITIAL_STATE, reducer } from './reducer';
import { MUTATION_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB_UPDATE } from '../../../apollo/graphql-mutations';
import {
  QUERY_AGENCY_JOB_FOR_AGENCY_ORGANIZATION_FOR_PLANNING,
  QUERY_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB,
} from '../../../apollo/graphql-queries';
import { ConfirmModalContext } from '../../../components/common/modal/ConfirmModal';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  AgencyJob,
  AgencyJobPlanning,
  AgencyJobPlanningItem,
  AgencyJobPlanningLeg,
  SubscriptionPlanType,
} from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const route: any = useRoute();
  const navigation: any = useNavigation();
  const { showConfirmModal } = useContext(ConfirmModalContext);
  const appUserContext = useContext(AppUserContext);

  const [agencyJobPlanningUpdate] = useMutation(MUTATION_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB_UPDATE);

  const legModalRef = useRef<any>(null);
  const dateTimePickerModalRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [visiblePlanningLegModal, setVisiblePlanningLegModal] = useState(false);
  const [visiblePlanningDateTimePicker, setVisiblePlanningDateTimePicker] = useState(false);

  const handleChangeFieldValue =
    (payload: { currentIndex?: number; currentLegIndex?: number; currentLegItemIndex?: number }) =>
    () => {
      dispatch({ type: 'changeFieldActive', payload });
    };

  /*********************************************************************************************************************
   * DATA
   ********************************************************************************************************************/
  const { data: dataAgencyJob } = useQuery<{ agencyJobForAgencyOrganization: AgencyJob }>(
    QUERY_AGENCY_JOB_FOR_AGENCY_ORGANIZATION_FOR_PLANNING,
    {
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-only',
      variables: { agencyJobId: route.params?.agencyJobId },
    },
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, error } = useQuery<{
    agencyJobPlanningForAgencyJob: AgencyJobPlanning;
  }>(QUERY_AGENCY_JOB_PLANNING_FOR_AGENCY_JOB, {
    fetchPolicy: isBackendReachable ? 'network-only' : 'cache-first',
    variables: { agencyJobId: route.params?.agencyJobId },
    onCompleted: async (res: { agencyJobPlanningForAgencyJob: AgencyJobPlanning }) => {
      setIsLoading(false);
      if (res.agencyJobPlanningForAgencyJob) {
        dispatch({
          type: 'syncAPIData',
          payload: { agencyJobPlanning: res.agencyJobPlanningForAgencyJob },
        });
      }
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const handleBackPress = () => {
    if (navigation.canGoBack() && Platform.OS !== 'web') {
      navigation.goBack();
      setTimeout(() => {
        if (navigation?.setParams) {
          navigation.setParams({ refetch: true });
        }
      }, 300);
    } else {
      let routes = [];
      if (route.params.previousScreenName.includes('%2C')) {
        routes = route.params.previousScreenName.split('%2C');
      } else {
        routes = route.params.previousScreenName.split(',');
      }
      let newPreviousScreenName = '';
      const previousScreenNames = routes.slice(0, -1);
      if (previousScreenNames.length) {
        newPreviousScreenName = previousScreenNames.join(',');
      }
      navigation.navigate(routes[routes.length - 1], {
        agencyJobId: route.params?.agencyJobId || '',
        previousScreenName: newPreviousScreenName,
        previousTabScreenName: route.params?.previousTabScreenName,
        refetch: true,
      });
    }
  };

  const handleAddAnotherLegPress = () => {
    dispatch({ type: 'addAnotherLeg', payload: {} });
  };

  const { subtitle, title } = useMemo(() => {
    if (dataAgencyJob?.agencyJobForAgencyOrganization) {
      let _title = t('job', { defaultValue: 'Job ' });
      let _subtitle = '';

      const agencyJob = dataAgencyJob.agencyJobForAgencyOrganization;
      if (agencyJob?.startAirport?.iataCode && agencyJob?.endAirport?.iataCode) {
        _title += `${agencyJob?.startAirport.iataCode}-${agencyJob?.endAirport.iataCode}`;
      }
      if (agencyJob?.job.priojetJobIdentifier) {
        _title += ` (${agencyJob?.job.priojetJobIdentifier})`;
      }
      if (agencyJob.externalJobReference) {
        _subtitle = agencyJob.externalJobReference;
      }
      return { title: _title, subtitle: _subtitle };
    }
    return { title: t('job', { defaultValue: 'Job' }), subtitle: '' };
  }, [dataAgencyJob, t]);

  const handleMenuIconPress =
    ({
      item,
      currentIndex,
      currentLegIndex,
      currentLegItemIndex,
    }: {
      item: any;
      currentIndex?: number;
      currentLegIndex?: number;
      currentLegItemIndex?: number;
    }) =>
    () => {
      if (
        !appUserContext.currentUserContext?.organizationSubscriptionPlan?.subscriptionPlan ||
        ![SubscriptionPlanType.AGENCY02, SubscriptionPlanType.AGENCY03].includes(
          appUserContext.currentUserContext?.organizationSubscriptionPlan?.subscriptionPlan.value,
        )
      ) {
        showConfirmModal({
          confirmButtonStatus: 'primary',
          confirmButtonAppearance: 'filled',
          confirmButtonText: '',
          cancelButtonText: t('common.close', { defaultValue: 'Close' }),
          title: t('jobs.theRunwayIsClosed', { defaultValue: 'The runway is closed' }),
          text: t('jobs.theRunwayToSetTargetTimesIsClosedPleaseContact', {
            defaultValue:
              'The runway to set target times is closed. Please contact PRIOjet support at support@priojet.com.',
          }),
          visible: true,
        });
      } else {
        if (item.targetDateTime) {
          dateTimePickerModalRef.current?.setData({ dateTime: item.targetDateTime });
        }
        dispatch({
          type: 'changeCurrentIndexes',
          payload: { currentIndex, currentLegIndex, currentLegItemIndex },
        });
        setVisiblePlanningDateTimePicker(true);
      }
    };

  const handleLegItemPress = (payload: { currentLegIndex: number }) => () => {
    dispatch({ type: 'changeFieldActive', payload });
  };

  const handleLegMenuIconPress =
    ({
      item,
      currentIndex,
      currentLegIndex,
      currentLegItemIndex,
    }: {
      item: AgencyJobPlanningLeg;
      currentIndex?: number;
      currentLegIndex?: number;
      currentLegItemIndex?: number;
    }) =>
    () => {
      legModalRef.current?.setData(item);
      dispatch({
        type: 'changeCurrentIndexes',
        payload: { currentIndex, currentLegIndex, currentLegItemIndex },
      });
      setVisiblePlanningLegModal(true);
    };

  const handleLegModalCancel = () => {
    dispatch({
      type: 'changeCurrentIndexes',
      payload: {
        currentIndex: undefined,
        currentLegIndex: undefined,
        currentLegItemIndex: undefined,
      },
    });
    setVisiblePlanningLegModal(false);
  };

  const handleLegModalConfirm = (legData: any) => {
    dispatch({ type: 'changeLegData', payload: { legData } });
    setVisiblePlanningLegModal(false);
  };

  const handleDateModalCancel = () => {
    dispatch({
      type: 'changeCurrentIndexes',
      payload: {
        currentIndex: undefined,
        currentLegIndex: undefined,
        currentLegItemIndex: undefined,
      },
    });
    setVisiblePlanningDateTimePicker(false);
  };

  const handleSelectAllPress = () => {
    dispatch({ type: 'selectDeselectAll', payload: {} });
  };

  const handleDateModalConfirm = (targetDateTime: DateTime) => {
    dispatch({
      type: 'changeFieldTargetTime',
      payload: { targetDateTime },
    });
    setVisiblePlanningDateTimePicker(false);
  };

  const handleDateModalAndNextConfirm = (targetDateTime: DateTime) => {
    setVisiblePlanningDateTimePicker(false);
    dispatch({
      type: 'changeFieldTargetTime',
      payload: { targetDateTime },
    });
    setTimeout(() => {
      const { currentIndex, currentLegIndex, currentLegItemIndex } = state;
      let index: number | undefined;
      if (typeof currentLegIndex === 'number') {
        if (typeof currentLegItemIndex === 'number') {
          index = state.agencyJobPlanningAllItemsWithLegItems.findIndex(
            (item) =>
              item.legIndex === currentLegIndex && item.legItemIndex === currentLegItemIndex,
          );
        } else {
          index = state.agencyJobPlanningAllItemsWithLegItems.findIndex(
            (item) => item.legIndex === currentLegIndex && typeof item.legItemIndex === 'undefined',
          );
        }
      } else if (typeof currentIndex === 'number') {
        index = state.agencyJobPlanningAllItemsWithLegItems.findIndex(
          (item) => item.index === currentIndex,
        );
      }
      if (index === state.agencyJobPlanningAllItemsWithLegItems.length - 1) {
        index === undefined;
      }

      const found = state.agencyJobPlanningAllItemsWithLegItems.find(
        (item, itemIndex: number) =>
          (typeof index === 'undefined' || (typeof index === 'number' && itemIndex > index)) &&
          item.active &&
          !item.completed,
      );
      if (typeof found?.index === 'number') {
        const item = state.agencyJobPlanning?.agencyJobPlanningItems[found.index];
        if (item) {
          handleMenuIconPress({
            item,
            currentIndex: found.index,
            currentLegIndex: found.legIndex,
            currentLegItemIndex: found.legItemIndex,
          })();
        }
      } else if (typeof found?.legIndex === 'number') {
        if (typeof found?.legItemIndex === 'number') {
          const item =
            state.agencyJobPlanning?.agencyJobPlanningLegs[found.legIndex].agencyJobPlanningItems[
              found.legItemIndex
            ];
          if (item) {
            handleMenuIconPress({
              item,
              currentIndex: found.index,
              currentLegIndex: found.legIndex,
              currentLegItemIndex: found.legItemIndex,
            })();
          }
        } else {
          const item = state.agencyJobPlanning?.agencyJobPlanningLegs[found.legIndex];
          if (item) {
            handleLegMenuIconPress({
              item,
              currentIndex: found.index,
              currentLegIndex: found.legIndex,
              currentLegItemIndex: found.legItemIndex,
            })();
          }
        }
      }
    }, 500);
  };

  const handleRemoveLegItem =
    ({ currentLegIndex }: { currentLegIndex: number }) =>
    () => {
      dispatch({ type: 'removeLegItem', payload: { currentLegIndex } });
    };

  const handleSave = async () => {
    setIsSaving(true);
    const agencyJobPlanningForAgencyJobUpdateInput: any = {
      agencyJobPlanningItems: [],
      agencyJobPlanningLegs: [],
      agencyJobPlanningLegIdsToDelete: state.agencyJobPlanningLegIdsToDelete,
    };
    if (state.agencyJobPlanning?.agencyJobPlanningItems) {
      state.agencyJobPlanning?.agencyJobPlanningItems.forEach((item) => {
        agencyJobPlanningForAgencyJobUpdateInput.agencyJobPlanningItems.push({
          id: item.id,
          active: item.active,
          field: item.name,
          targetDateTime: item.targetDateTime,
        });
      });
    }
    if (state.agencyJobPlanning?.agencyJobPlanningLegs) {
      state.agencyJobPlanning?.agencyJobPlanningLegs.forEach((legItem, legIndex) => {
        const agencyJobPlanningLeg: any = legItem;
        const agencyJobPlanningItems: {
          id?: string;
          active: boolean;
          field: string;
          targetDateTime?: DateTime;
        }[] = [];
        agencyJobPlanningLeg.agencyJobPlanningItems.forEach((item: AgencyJobPlanningItem) => {
          agencyJobPlanningItems.push({
            id: item.id,
            active: item.active,
            field: item.name,
            targetDateTime: item.targetDateTime ? item.targetDateTime : undefined,
          });
        });
        const flightTickets: any[] = [];
        if (agencyJobPlanningLeg.flightTickets.length) {
          for (let i = 0; i < agencyJobPlanningLeg.flightTickets.length; i++) {
            const ticket: any = agencyJobPlanningLeg.flightTickets[i];
            if (ticket.base64) {
              flightTickets.push({
                base64: ticket.base64,
                height: ticket.height,
                mimeType: ticket.mimeType || ticket.fileType,
                name: ticket.name,
                width: ticket.width,
              });
            }
          }
        }
        agencyJobPlanningForAgencyJobUpdateInput.agencyJobPlanningLegs.push({
          id: agencyJobPlanningLeg.id,
          active: agencyJobPlanningLeg.active,
          agencyJobPlanningItems,
          departureDate: agencyJobPlanningLeg.departureDate,
          flightCarrierDesignation: agencyJobPlanningLeg.flightCarrierDesignation,
          flightNumber: agencyJobPlanningLeg.flightNumber,
          flightTickets,
          flightTicketIdsToDelete: agencyJobPlanningLeg.flightTicketIdsToDelete,
          order: legIndex + 1,
        });
      });
    }
    try {
      const res = await agencyJobPlanningUpdate({
        variables: {
          agencyJobId: route.params?.agencyJobId,
          agencyJobPlanningId: state.agencyJobPlanning?.id,
          agencyJobPlanningForAgencyJobUpdateInput,
        },
      });
      if (res.data.agencyJobPlanningForAgencyJobUpdate) {
        dispatch({
          type: 'syncAPIData',
          payload: { agencyJobPlanning: res.data.agencyJobPlanningForAgencyJobUpdate },
        });
        showMessage({
          message: t('jobs.success', { defaultValue: 'Success' }),
          description: t('jobs.planningSuccessfullySaved', {
            defaultValue: 'Planning successfully saved',
          }) as string,
          type: 'success',
          duration: 8000,
          autoHide: true,
          hideOnPress: true,
        });
      }
    } catch {
      showMessage({
        message: t('jobs.error', { defaultValue: 'error' }),
        description: t('jobs.planningSuccessfullyError', {
          defaultValue:
            'Something went wrong with saving planning. Please try again or contact support@priojet.com.',
        }) as string,
        type: 'danger',
        duration: 8000,
        autoHide: true,
        hideOnPress: true,
      });
    }
    setIsSaving(false);
  };

  const { currentItem } = useMemo(() => {
    let _currentItem;
    let _currentItemText = '-';
    if (state.agencyJobPlanning?.agencyJobPlanningItems?.length) {
      if (typeof state.currentIndex === 'number') {
        _currentItem = state.agencyJobPlanning.agencyJobPlanningItems[state.currentIndex];
        _currentItemText = _currentItem.label;
      }
      if (
        typeof state.currentLegIndex === 'number' &&
        typeof state.currentLegItemIndex === 'number'
      ) {
        _currentItem =
          state.agencyJobPlanning.agencyJobPlanningLegs[state.currentLegIndex]
            .agencyJobPlanningItems[state.currentLegItemIndex];
        _currentItemText = `Leg ${state.currentLegIndex + 1}: ${_currentItem.label}`;
      }
    }
    return { currentItem: _currentItem, currentItemText: _currentItemText };
  }, [state]);

  return {
    dateTimePickerModalRef,
    legModalRef,
    currentItem,
    handleAddAnotherLegPress,
    handleBackPress,
    handleChangeFieldValue,
    handleLegItemPress,
    handleLegMenuIconPress,
    handleMenuIconPress,
    handleLegModalCancel,
    handleLegModalConfirm,
    handleDateModalCancel,
    handleDateModalConfirm,
    handleDateModalAndNextConfirm,
    handleRemoveLegItem,
    handleSave,
    handleSelectAllPress,
    isLoading,
    isSaving,
    state,
    subtitle,
    title,
    visiblePlanningLegModal,
    visiblePlanningDateTimePicker,
  };
};
