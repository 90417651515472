import React, { useContext } from 'react';
import { View } from 'react-native';

import { useIsFocused, useNavigation } from '@react-navigation/core';
import { StyleService, useStyleSheet, useTheme } from '@ui-kitten/components';
import { Divider, Icon, Text, TopNavigation } from '@ui-kitten/components/ui';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { PriojetLogoSvg } from '../../assets/images/priojet-logo';
import { globalStyle } from '../../common/style';
import AppUserContext from '../../contexts/AppUserContext';
import { useNumberOfNewNotificationsForOrganizationUserQuery } from '../../generated-graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';

export const CommonHomeTopNavigation = () => {
  const theme = useTheme();
  const navigation: any = useNavigation();
  const styles = useStyleSheet(themedStyles);

  const renderTitle = () => {
    return <PriojetLogoSvg height={40} width={200} />;
  };

  const NotificationAndActivityBell = () => {
    const appUserContext = useContext(AppUserContext);
    const isBackendReachable = useIsBackendReachable();
    const isFocused = useIsFocused();

    const { data } = useNumberOfNewNotificationsForOrganizationUserQuery({
      variables: {
        organizationUserId: appUserContext.currentUserContext?.organizationUser?.id as string,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-only',
      pollInterval: isBackendReachable ? 10000 : 0,
      skip: !isFocused,
    });

    return (
      <TouchableOpacity onPress={() => navigation.navigate('CommonActivityScreen')}>
        <View style={styles.iconLayer}>
          <Icon
            animation="shake"
            name="bell-outline"
            style={globalStyle.size30}
            fill={theme['color-menu-item']}
          />
          {!!data?.numberOfNewNotificationsForOrganizationUser && (
            <View style={styles.redDotLayer}>
              <Text style={styles.redDotText}>
                {data?.numberOfNewNotificationsForOrganizationUser}
              </Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <>
      <TopNavigation
        title={renderTitle}
        alignment="center"
        accessoryRight={() => <NotificationAndActivityBell />}
      />
      <Divider />
    </>
  );
};

const themedStyles = StyleService.create({
  iconLayer: {
    marginRight: 10,
    height: 40,
    width: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  redDotLayer: {
    height: 18,
    width: 18,
    backgroundColor: 'red',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: -3,
    borderRadius: 9,
  },
  redDotText: { color: 'white', fontSize: 10 },
});
