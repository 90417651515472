import React, { useEffect, useState } from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button, Text } from '@ui-kitten/components';

import { RefreshOutlineIcon } from './icons.component';
import { GLOBAL_CONSTANTS } from '../globals';
import { useInterval } from '../hooks/useInterval';
import { loadCurrentUserContext } from '../modules/authentication/authentication.module';
import {
  findOrCreateDevice,
  getDevice,
  getDeviceIdFromStorage,
} from '../modules/device/device.module';
import { Device, LocalUserContext } from '../types';

export const AppLoadingComponent = ({
  setCurrentUserContext,
}: {
  setCurrentUserContext: (currentUserContext: LocalUserContext | null | undefined) => void;
}) => {
  const [error, setError] = useState<Error>();
  const [retryButtonDisabled, setRetryButtonDisabled] = useState<boolean>(false);
  const [showCheckIsBackendReachableButton, setShowCheckIsBackendReachableButton] =
    useState<boolean>(false);

  useEffect(() => {
    handleFindOrCreateDevice();
  }, []);

  useInterval(() => {
    setShowCheckIsBackendReachableButton(true);
    setRetryButtonDisabled(false);
  }, 3000);

  const handleFindOrCreateDevice = async (): Promise<void> => {
    const deviceId = await getDeviceIdFromStorage();
    if (deviceId) {
      const device: Device | null = await getDevice();
      const isDeviceSuccessfullyCalled = await AsyncStorage.getItem(
        GLOBAL_CONSTANTS.DEVICE_MUTATION_SUCCESSFULLY_CALLED_KEY,
      );
      if (
        device !== null &&
        device.isSynchronizedWithBackend &&
        isDeviceSuccessfullyCalled === 'true'
      ) {
        const loadedUserContext = await loadCurrentUserContext();
        if (loadedUserContext) {
          if (loadedUserContext && loadedUserContext?.user && device) {
            setCurrentUserContext({
              ...loadedUserContext,
              device,
              deviceId: device.id,
            });
          } else if (device) {
            setCurrentUserContext({
              ...loadedUserContext,
              device,
              deviceId: device.id,
            });
          } else {
            setCurrentUserContext(null);
          }
        }
      }
    }
    await findOrCreateDevice()
      .then((device) => {
        if (device) {
          setCurrentUserContext({ device, deviceId: device.id });
        }
      })
      .catch((e) => {
        setError(e);
      });
  };

  const retryIsBackendReachableCheck = () => {
    setRetryButtonDisabled(true);
    handleFindOrCreateDevice();
  };

  return (
    <ScrollView
      contentContainerStyle={themedStyles.contentContainerStyle}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
    >
      <View style={themedStyles.spinner}>
        <Image
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginBottom: 10,
          }}
          source={require('../assets/images/animated_logo.gif')}
        />
        <Text selectable={true} style={themedStyles.text}>
          Just a moment... Setting up your app...{' '}
        </Text>
        <Text selectable={true} style={themedStyles.text}>
          Trying to connect to PRIOjet Server. Please wait!
        </Text>

        {showCheckIsBackendReachableButton ? (
          <View style={{ padding: 20, alignItems: 'center' }}>
            <Text selectable={true}>
              Seems like the Internet or our servers are currently not reachable.{'\n'}
              Try again after some time.
            </Text>
            <Button
              appearance="outline"
              disabled={retryButtonDisabled}
              onPress={() => {
                retryIsBackendReachableCheck();
              }}
              accessoryLeft={RefreshOutlineIcon}
              style={{ width: '50%', maxWidth: 250, marginTop: 20 }}
            >
              Try again
            </Button>
          </View>
        ) : (
          <></>
        )}
        {error ? (
          <View style={{ padding: 20, alignItems: 'center' }}>
            <Text selectable={true}>Error: {error.message}</Text>
          </View>
        ) : (
          <></>
        )}
      </View>
    </ScrollView>
  );
};
const themedStyles = StyleSheet.create({
  contentContainerStyle: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: { justifyContent: 'center', textAlign: 'center' },
  spinner: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 30,
    padding: 8,
  },
});
