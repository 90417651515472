import React from 'react';

import { Menu, MenuItem } from '@ui-kitten/components/ui';
import { useTranslation } from 'react-i18next';

import { CommonMenuItems } from '../../components/common/menu/common.menu-items.component';
import { MenuCategory } from '../../components/common/menu/menu-category.component';
import { ForwardIcon } from '../../components/icons.component';
import { TopNavigationWithoutAccessories } from '../../components/top-navigation-without-accessories.component';

export const PriojetProfileScreen = ({
  navigation,
}: {
  navigation: any;
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <TopNavigationWithoutAccessories
        title={
          t('menuItems.PRIOjetProfile', { defaultValue: 'PRIOjet Profile' }) as string
        }
      />
      <Menu>
        <MenuCategory
          title={
            t('menuItems.UserManagement', { defaultValue: 'User Management' }) as string
          }
        />
        <MenuItem
          title={
            t('menuItems.managePRIOjetUsers', {
              defaultValue: 'Manage PRIOjet Users',
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('PriojetProfileNavigator', {
              screen: 'PriojetManagePriojetUsersScreen',
            });
          }}
        />
        <MenuItem
          title={t('menuItems.manageUsers', { defaultValue: 'Manage Users' }) as string}
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('PriojetProfileNavigator', {
              screen: 'PriojetManageUsersScreen',
            });
          }}
        />
        <MenuCategory
          title={
            t('menuItems.organizationManagement', {
              defaultValue: 'Organization Management',
            }) as string
          }
        />
        <MenuItem
          title={
            t('menuItems.manageOrganizations', {
              defaultValue: 'Manage Organizations',
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('PriojetProfileNavigator', {
              screen: 'PriojetManageOrganizationsScreen',
            });
          }}
        />
        <MenuItem
          title={
            t('menuItems.organizationInvitations', {
              defaultValue: 'Organization Invitations',
            }) as string
          }
          accessoryRight={ForwardIcon}
          onPress={() => {
            navigation.navigate('PriojetOrganizationInvitationsNavigator', {
              screen: 'PriojetOrganizationInvitationsScreen',
            });
          }}
        />

        <CommonMenuItems />
      </Menu>
    </>
  );
};
