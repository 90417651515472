import React, { useEffect, useMemo, useState } from 'react';
import { View, Linking, Platform } from 'react-native';

import { useMutation } from '@apollo/client';
import { ErrorResponse } from '@apollo/client/link/error';
import { useNavigation, useRoute } from '@react-navigation/core';
import { Input, StyleService, Text } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import { useTranslation, Trans } from 'react-i18next';
import { MessageOptions, showMessage } from 'react-native-flash-message';

import StandardButton, { StandardButtonStates } from './standard-button.component';
import { ACTIVATE_ACCOUNT_MUTATION } from '../apollo/graphql-mutations';
import { globalStyle } from '../common/style';
import AppUserContext from '../contexts/AppUserContext';
import {
  Organization,
  OrganizationSubscriptionPlan,
  OrganizationType,
  OrganizationUser,
  User,
} from '../generated-graphql-types';
import { ActivateAccount, ActivateAccountVariables } from '../graphql-types';
import i18n from '../i18n/i18n';
import { accountActivationNavigatorShouldBeActive } from '../navigation/app.navigator';
import { AuthenticationService } from '../services/authentication.service';
import { LocalUserContext } from '../types';

const errorDefaultValues: MessageOptions = {
  message: i18n.t('activation.errorMessage', {
    defaultValue: 'Activation error',
  }) as string,
  description: i18n.t(`activation.errorDescription`, {
    defaultValue:
      'Something went wrong with account activation. Please try again or reach out to support@priojet.com',
  }) as string,
  type: 'danger',
  autoHide: true,
  hideOnPress: true,
  duration: 8000,
};

const AccountActivationComponent = () => {
  const navigation: any = useNavigation();
  const route: any = useRoute();
  const appUserContext = React.useContext(AppUserContext);

  const { t } = useTranslation();
  const [isActivationSuccessful, setIsActivationSuccessful] = useState<boolean>(false);

  const hasPreviousScreenName = !!route.params?.previousScreenName;

  const [activateAccountButtonStates, setActivateAccountButtonStates] =
    useState<StandardButtonStates>({
      disabled: {
        isActive: true,
        text: t('activation.activateAccount', {
          defaultValue: 'Activate Account',
        }) as string,
      },
    });

  const [responseStatus, setResponseStatus] = React.useState<EvaStatus>();
  const [activationCode, setActivationCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [activateAccount] = useMutation<ActivateAccount, ActivateAccountVariables>(
    ACTIVATE_ACCOUNT_MUTATION,
  );

  useEffect(() => {
    // Only show Notification Permission Screen for mobile app users.
    if (
      // appUserContext.currentUserContext?.organizationActivatedAt &&
      appUserContext.currentUserContext?.organizationSubscriptionPlan?.isActive &&
      accountActivationNavigatorShouldBeActive(appUserContext.currentUserContext)
    ) {
      if (Platform.OS !== 'web') {
        navigation.navigate('CommonNotificationPermissionsScreen');
      }
    } else if (!accountActivationNavigatorShouldBeActive(appUserContext.currentUserContext)) {
      navigation.navigate('CommonProfileNavigator');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUserContext.currentUserContext]);

  useEffect(() => {
    const states: StandardButtonStates = {
      on: {
        isActive: activationCode?.length === 8,
        appearance: 'filled',
        onPress: async () => {
          handleActivateAccount();
        },
        text: t('activation.activateAccount', {
          defaultValue: 'Activate Account',
        }) as string,
      },
      disabled: {
        isActive: activationCode?.length !== 8 && !isActivationSuccessful,
        text: t('activation.activateAccount', {
          defaultValue: 'Activate Account',
        }) as string,
      },
    };
    setActivateAccountButtonStates(states);
    if (activationCode?.length !== 8) {
      setResponseStatus('info');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationCode]);

  const handleActivateAccount = async () => {
    setResponseStatus('basic');
    setIsLoading(true);
    try {
      const res = await activateAccount({ variables: { activationCode } });
      setIsLoading(false);
      if (res?.data?.activateAccount.organizationActivatedAt) {
        const { activateAccount: activateAccountData } = res.data;

        if (appUserContext.currentUserContext) {
          const currentUserId = appUserContext.currentUserContext.user?.id;
          const currentOrganizationId = appUserContext.currentUserContext.organization?.id;

          const newCurrentUserContext: LocalUserContext = {
            ...appUserContext.currentUserContext,
            sub: activateAccountData.sub || appUserContext.currentUserContext.sub,
            user: activateAccountData.user
              ? (activateAccountData.user as User)
              : appUserContext.currentUserContext.user,
            userContextId:
              activateAccountData.userContextId || appUserContext.currentUserContext.userContextId,
            role: activateAccountData.role || appUserContext.currentUserContext.role,
            organizationActivatedAt: activateAccountData.organizationActivatedAt
              ? activateAccountData.organizationActivatedAt
              : appUserContext.currentUserContext.organizationActivatedAt,
            deviceId: activateAccountData.deviceId || appUserContext.currentUserContext.deviceId,
            organizationUser: activateAccountData.organizationUser
              ? (activateAccountData.organizationUser as OrganizationUser)
              : appUserContext.currentUserContext.organizationUser,
            organization: activateAccountData.organization
              ? (activateAccountData.organization as Organization)
              : appUserContext.currentUserContext.organization,
            organizationType:
              activateAccountData.organizationType ||
              appUserContext.currentUserContext.organizationType,
            roleType: activateAccountData.roleType || appUserContext.currentUserContext.roleType,
            signUpOrganizationType:
              activateAccountData.organizationType ||
              appUserContext.currentUserContext.signUpOrganizationType,
            organizationSubscriptionPlan:
              activateAccountData.organizationSubscriptionPlan as OrganizationSubscriptionPlan,
          };

          if (
            activateAccountData.organization?.id &&
            appUserContext.currentUserContext?.organization &&
            activateAccountData.organization.id !==
              appUserContext.currentUserContext.organization.id
          ) {
            const authenticationService = new AuthenticationService();
            let localUserContexts = await authenticationService.loadLocalUserContextsAsync();
            if (currentUserId && currentOrganizationId) {
              localUserContexts = Object.fromEntries(
                Object.entries(localUserContexts).filter(
                  (entry) =>
                    entry[1].organization?.id !== currentOrganizationId &&
                    entry[1].user?.id !== currentUserId,
                ),
              );
            }

            const newLocalUserContexts =
              authenticationService.mergeLocalUserContextsWithLocalUserContext({
                localUserContexts,
                localUserContextToMerge: newCurrentUserContext,
              });
            await authenticationService.storeLocalUserContextsAsync({
              localUserContexts: newLocalUserContexts,
            });
          }
          appUserContext.refetchCurrentUserContext();

          showMessage({
            ...errorDefaultValues,
            type: 'success',
            message: t('activation.activationSuccess', {
              defaultValue: 'Activation success',
            }),
            description: t('activation.activationSuccessDescription', {
              defaultValue: 'Account activated successfully',
            }) as string,
          });
          setResponseStatus('success');
          setIsActivationSuccessful(true);
          setActivateAccountButtonStates({
            disabled: {
              isActive: true,
              text: t('activation.pleaseWait', { defaultValue: 'Please wait' }) as string,
            },
          });
          appUserContext.setCurrentUserContext(newCurrentUserContext);
        } else {
          setIsActivationSuccessful(false);
          showMessage({ ...errorDefaultValues });
          setResponseStatus('danger');
        }
      } else {
        setIsActivationSuccessful(false);
        showMessage({ ...errorDefaultValues });
        setResponseStatus('danger');
      }
    } catch (e) {
      const err = e as ErrorResponse;
      const error = err.graphQLErrors?.[0] as any;
      showMessage({
        ...errorDefaultValues,
        message: t(`error.internalCode.${error?.extensions?.internalCode}.message`, {
          defaultValue: 'BAD USER INPUT',
        }),
        description: t(`error.internalCode.${error?.extensions?.internalCode}.description`, {
          defaultValue: error?.message,
        }) as string,
      });
      setIsLoading(false);
      setActivateAccountButtonStates({
        on: {
          isActive: true,
          appearance: 'filled',
          onPress: async () => {
            handleActivateAccount();
          },
          text: t('activation.errorRetryMessage', {
            defaultValue: 'Error during activation. Retry',
          }) as string,
        },
      });
      setIsActivationSuccessful(false);
    }
  };

  const handleChangeInput = (input: string) => {
    setActivationCode(input);
  };

  const handleOpenWebApp = () => {
    Linking.openURL('https://app.priojet.com');
  };

  const activationTextKey = useMemo(() => {
    if (appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER) {
      return 'activation.activationScreenAgencyText';
    }
    if (appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY) {
      return 'activation.activationScreenPriojetText';
    }
    return 'activation.activationScreenText';
  }, [appUserContext.currentUserContext?.organizationType]);

  return (
    <View style={themedStyles.contentBox}>
      <View>
        <Text selectable={true} style={themedStyles.descriptionText}>
          {t(activationTextKey, { defaultValue: 'Account Activation' }) as string}
        </Text>
      </View>
      <View style={themedStyles.contentBoxContent}>
        <Input
          autoCapitalize="characters"
          style={themedStyles.activationCodeInput}
          placeholder={
            t('activation.enterActivationCode', {
              defaultValue: 'Enter 8 digit activation code',
            }) as string
          }
          maxLength={8}
          value={activationCode}
          status={responseStatus}
          onChangeText={(input) => handleChangeInput(input)}
        />
        <StandardButton
          style={themedStyles.standardButton}
          states={activateAccountButtonStates}
          isLoading={isLoading}
        />
      </View>

      {Platform.OS !== 'web' && !hasPreviousScreenName && (
        <View style={themedStyles.webLinkContainer}>
          <Text style={themedStyles.webLinkText}>
            <Trans
              i18nKey="common.ifYouAreANewAgencyVisitWebApp"
              defaults="If you are a new Agency, please visit <0>https://app.priojet.com</0>"
              components={[
                <Text
                  style={[globalStyle.fontLatoBold, globalStyle.textDecorationUnderline]}
                  onPress={handleOpenWebApp}
                />,
              ]}
            />
          </Text>
        </View>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  contentBox: {
    flex: 1,
    padding: 20,
    paddingTop: '10%',
  },
  contentBoxContent: {
    flex: 1,
    alignItems: 'center',
  },
  descriptionText: {
    textAlign: 'center',
    alignSelf: 'center',
    maxWidth: 500,
  },
  standardButton: {
    width: '80%',
    maxWidth: 400,
    marginTop: 10,
  },
  activationCodeInput: {
    marginTop: 5,
    width: '80%',
    maxWidth: 400,
    paddingTop: 15,
    paddingBottom: 5,
  },
  webLinkContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  webLinkText: {
    textAlign: 'center',
    maxWidth: 240,
  },
});

export default AccountActivationComponent;
