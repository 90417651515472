import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AvailabilityFilterType } from '../../../../../generated-graphql-types';

export const useHook = ({ activelySelectedFilterType, onFilterConfirm, isVisible }: any) => {
  const { t } = useTranslation();

  const [selectedFilterType, setSelectedFilterType] = useState<AvailabilityFilterType[]>([]);

  const options = useMemo(
    () => [
      {
        label: t('common.filterCurrentlyAvailable', { defaultValue: 'Currently available' }),
        value: AvailabilityFilterType.ACTIVE,
      },
      {
        label: t('common.filterPlannedAvailable', { defaultValue: 'Planned available' }),
        value: AvailabilityFilterType.SCHEDULED,
      },
      {
        label: t('common.filterLastFinishedAvailability', {
          defaultValue: 'Last finished availability',
        }),
        value: AvailabilityFilterType.FINISHED,
      },
    ],
    [t],
  );

  const handleFilterConfirm = () => {
    onFilterConfirm(selectedFilterType);
  };

  const handleFilterSelect = (value: AvailabilityFilterType) => () => {
    setSelectedFilterType((prev: AvailabilityFilterType[]) => {
      let filters: AvailabilityFilterType[] = [...prev, value];
      if (prev.includes(value)) {
        filters = prev.filter((item) => item !== value);
      }
      return filters;
    });
  };

  useEffect(() => {
    if (isVisible && selectedFilterType.join('_') !== activelySelectedFilterType.join('_')) {
      setSelectedFilterType(activelySelectedFilterType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return { handleFilterConfirm, handleFilterSelect, options, selectedFilterType };
};
