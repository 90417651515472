import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { useLazyQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { AGENCY_COURIER_JOB_CHAT } from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  Chat,
  OrganizationType,
  AgencyJobStatusType,
  CourierJobAgencyRelationshipType,
} from '../../../generated-graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';

export const useHook = () => {
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const route: any = useRoute();
  const navigation: any = useNavigation();
  const appUserContext = useContext(AppUserContext);

  const chatRef = useRef<any>(null);
  const refetchInterval = useRef<NodeJS.Timer | null>(null);

  const [chat, setChat] = useState<Chat | undefined>(undefined);
  const [courierJobId, setCourierJobId] = useState<string | undefined>();
  const [errorChat, setErrorChat] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  /*********************************************************************************************************************
   * DATA
   ********************************************************************************************************************/
  const errorCb = async (e: any) => {
    const error = e?.graphQLErrors?.[0];
    if (error.extensions.code === 'FORBIDDEN') {
      setErrorChat('FORBIDDEN');
    } else {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }) as string,
        description:
          error.message ||
          (t('common.somethingWentWrong', { defaultValue: 'Something went wrong' }) as string),
        type: 'danger',
        duration: 8000,
        autoHide: true,
        hideOnPress: true,
      });
    }
  };

  const [getOrCreateCourierJobAgencyJobChat, { data }] = useLazyQuery<{
    agencyCourierJobChat: Chat;
  }>(AGENCY_COURIER_JOB_CHAT, {
    context: { cb: errorCb },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const getChatData = async () => {
    try {
      const { data: agencyCourierJobChatData } = await getOrCreateCourierJobAgencyJobChat({
        variables: {
          agencyCourierJobChatInput: { courierJobId: route.params?.courierJobId },
        },
      });
      if (agencyCourierJobChatData?.agencyCourierJobChat.id) {
        if (!route.params?.chatId) {
          navigation.setParams({ chatId: agencyCourierJobChatData?.agencyCourierJobChat.id });
        }
        setChat(agencyCourierJobChatData.agencyCourierJobChat);
      }
      // eslint-disable-next-line no-empty
    } catch {}
    if (loading) {
      setLoading(false);
    }
  };

  useEffect((): any => {
    if (chat?.id && !refetchInterval.current) {
      refetchInterval.current = setInterval(() => {
        getChatData();
      }, 60 * 1000);
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat?.id, refetchInterval]);

  useEffect((): any => {
    if (
      (route.params?.courierJobId && route.params?.courierJobId !== courierJobId) ||
      (route.params?.chatId && route.params?.chatId !== chat?.id)
    ) {
      setLoading(true);
      setErrorChat('');
      setCourierJobId(route.params?.courierJobId);
      getChatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat?.id, courierJobId, route.params?.chatId, route.params?.courierJobId]);

  useEffect(() => {
    if (route.params?.refetchMessages && courierJobId) {
      navigation.setParams({ refetchMessages: false });
      if (chatRef.current?.getLatestMessages) {
        chatRef.current?.getLatestMessages();
      }
    }
  }, [courierJobId, navigation, route]);

  const onPressLeft = () => {
    if (Platform.OS === 'web') {
      if (route.params?.previousScreenName) {
        const { previousScreenName, ...rest } = route.params;
        if (previousScreenName.includes(',') || previousScreenName.includes('%2C')) {
          let routes = [];
          if (previousScreenName.includes('%2C')) {
            routes = previousScreenName.split('%2C');
          } else {
            routes = previousScreenName.split(',');
          }
          let newPreviousScreenName = '';
          const previousScreenNames = routes.slice(0, -1);
          if (previousScreenNames.length) {
            newPreviousScreenName = previousScreenNames.join(',');
          }
          navigation.navigate(routes[routes.length - 1], {
            agencyJobId: route.params?.agencyJobId || '',
            courierJobId: route.params?.courierJobId || '',
            previousScreenName: newPreviousScreenName,
          });
        } else {
          navigation.navigate(route.params?.previousScreenName, {
            agencyJobId: route.params?.agencyJobId || '',
            courierJobId: route.params?.courierJobId || '',
            ...rest,
          });
        }
      } else if (navigation.canGoBack()) {
        navigation.goBack();
      }
    } else {
      navigation.goBack();
    }
  };

  const handleTitlePress = () => {
    const { previousScreenName } = route.params;
    const isAgency =
      appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY;
    const isCourier =
      appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER;
    const agencyJobId = data?.agencyCourierJobChat.courierJob?.agencyJob?.id || '';
    if (previousScreenName.endsWith('AgencyCourierJobOverviewScreen')) {
      onPressLeft();
    } else if (isCourier) {
      navigation.navigate('CourierCourierJobScreen', {
        agencyJobId,
        courierJobId,
        previousScreenName: `${route.params?.previousScreenName},CourierCourierJobAgencyJobChatScreen`,
        previousNavigator: 'CourierHomeNavigator',
      });
    } else if (isAgency) {
      if (agencyJobId) {
        navigation.navigate('AgencyViewCourierJobScreen', {
          agencyJobId,
          courierJobId,
          previousScreenName: `${route.params?.previousScreenName},AgencyCourierJobAgencyJobChatScreen`,
          previousNavigator: 'AgencyHomeNavigator',
        });
      }
    }
  };

  const handleJobChatAndTrackingPress = () => {
    if (appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER) {
      navigation.navigate('CourierAgencyJobChatScreen', {
        chatId: chat?.courierJob?.agencyJob?.agencyJobChat?.id,
        agencyJobId: chat?.courierJob?.agencyJob?.id as string,
        courierJobId,
        previousScreenName: `${route.params?.previousScreenName},CourierCourierJobAgencyJobChatScreen`,
        previousNavigator: 'CourierHomeNavigator',
      });
    } else {
      navigation.navigate('AgencyAgencyJobChatScreen', {
        chatId: chat?.courierJob?.agencyJob?.agencyJobChat?.id,
        agencyJobId: chat?.courierJob?.agencyJob?.id as string,
        courierJobId,
        previousScreenName: `${route.params?.previousScreenName},AgencyCourierJobAgencyJobChatScreen`,
        previousNavigator: 'AgencyHomeNavigator',
      });
    }
  };

  const { title, subtitle } = useMemo(() => {
    if (data?.agencyCourierJobChat) {
      let _subtitle =
        data?.agencyCourierJobChat?.courierJob?.agencyJob?.endAirport?.formattedShortAddress || '';
      let _title = '';

      if (appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER) {
        _title =
          data.agencyCourierJobChat.courierJob?.agencyJob?.responsibleAgencyOrganization
            ?.shortName || t('common.chatWithAgency', { defaultValue: 'Chat with agency' });
      } else {
        _title = `${data.agencyCourierJobChat.courierJob?.responsibleCourierUser.firstNames} ${data.agencyCourierJobChat.courierJob?.responsibleCourierUser.lastName}`;
      }

      if (subtitle && subtitle.length && subtitle.startsWith(', ')) {
        _subtitle = subtitle.replace(', ', '');
      }
      if (data.agencyCourierJobChat.courierJob?.job.priojetJobIdentifier) {
        if (subtitle && subtitle.length) {
          _subtitle = `${data.agencyCourierJobChat.courierJob?.job.priojetJobIdentifier} - ${subtitle}`;
        } else {
          _subtitle = data.agencyCourierJobChat.courierJob?.job.priojetJobIdentifier;
        }
      }
      return {
        title: _title,
        subtitle: _subtitle,
      };
    }
    return { title: 'Chat', subtitle: '' };
  }, [appUserContext.currentUserContext?.organizationType, data?.agencyCourierJobChat, t]);

  const { disableInput, disableInputText } = useMemo(() => {
    if (data?.agencyCourierJobChat?.inactivatedAt) {
      if (appUserContext?.currentUserContext?.organizationType === OrganizationType.COURIER) {
        return {
          disableInput: true,
          disableInputText: t('common.chatDisabledCourierDeleted', {
            defaultValue:
              'New messages cannot be created because connected courier deleted account.',
          }),
        };
      }
      return {
        disableInput: true,
        disableInputText: t('common.chatDisabledAgencyDeleted', {
          defaultValue: 'New messages cannot be created because connected agency deleted account.',
        }),
      };
    }
    return { disableInput: false, disableInputText: '' };
  }, [
    appUserContext?.currentUserContext?.organizationType,
    data?.agencyCourierJobChat?.inactivatedAt,
    t,
  ]);

  const { agencyButtonEnabled, agencyJobStatusStarted, courierButtonEnabled, startedByAgencyAt } =
    useMemo(() => {
      return {
        agencyJobStatusStarted:
          chat?.courierJob?.agencyJob?.agencyJobStatusType === AgencyJobStatusType.STARTED,
        agencyButtonEnabled:
          appUserContext.currentUserContext?.organizationType === OrganizationType.AGENCY,
        courierButtonEnabled:
          appUserContext.currentUserContext?.organizationType === OrganizationType.COURIER &&
          chat?.courierJob?.relationshipType === CourierJobAgencyRelationshipType.AGENCY_ACCEPTED,
        startedByAgencyAt: chat?.courierJob?.agencyJob?.startedByAgencyAt
          ? chat?.courierJob?.agencyJob?.startedByAgencyAt.toFormat('dd MMM yyyy, HH:mm')
          : '',
      };
    }, [
      appUserContext.currentUserContext?.organizationType,
      chat?.courierJob?.agencyJob?.agencyJobStatusType,
      chat?.courierJob?.agencyJob?.startedByAgencyAt,
      chat?.courierJob?.relationshipType,
    ]);

  return {
    agencyButtonEnabled,
    agencyJobStatusStarted,
    chatId: chat?.id || route.params?.chatId,
    chatRef,
    courierButtonEnabled,
    disableInput,
    disableInputText,
    handleJobChatAndTrackingPress,
    handleTitlePress,
    errorChat,
    loading,
    onPressLeft,
    title,
    startedByAgencyAt,
    subtitle,
  };
};
