import React from 'react';

import { SwitchAccount } from '../../components/common/switch-account.component';
import { TopNavigationLogoOnly } from '../../components/top-navigation-logo-only.component';

export const CommonUnauthenticatedSwitchAccountScreen = ({
  navigation,
}: {
  navigation: any;
}) => {
  return (
    <>
      <TopNavigationLogoOnly />
      <SwitchAccount navigation={navigation} />
    </>
  );
};
