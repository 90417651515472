import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { useMutation } from '@apollo/client';
import { Text, Button, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { themedStyles } from './styles';
import { CREATE_OR_UPDATE_COURIER_USER_CONTACT_MUTATION } from '../../../../../apollo/graphql-mutations';
import { globalStyle } from '../../../../../common/style';
import { AnimatedFullscreenModal } from '../../../../../components/common/modal/animated-fullscreen-modal.component';
import {
  CreateOrUpdateCourierUserContactMutationVariables,
  UserContactImportSourceType,
} from '../../../../../generated-graphql-types';
import useDimensions from '../../../../../hooks/useDimensions';
import { ImportContact } from '../types';

export const InviteCourierUploadCSVModal = ({ contacts, onResetContacts }: any) => {
  const { t } = useTranslation();
  const modalRef = useRef<any>(null);
  const { dimensions } = useDimensions();
  const styles = useStyleSheet(themedStyles);

  const [createOrUpdateUserContactMutation] = useMutation(
    CREATE_OR_UPDATE_COURIER_USER_CONTACT_MUTATION,
  );

  const [countFailure, setCountFailure] = useState<ImportContact[]>([]);
  const [countSkipped, setCountSkipped] = useState<ImportContact[]>([]);
  const [countSuccess, setCountSuccess] = useState<ImportContact[]>([]);
  const [queue, setQueue] = useState<ImportContact[]>([]);
  const [uploading, setUploading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    if ((queue.length === 0 && !uploading) || totalCount === count) {
      const contactsImported = [...countSuccess];
      const contactsFailed = [...countFailure];
      const contactsSkipped = [...countSkipped];
      setCountFailure([]);
      setCountSuccess([]);
      toggleVisibility();
      setVisible(false);
      onResetContacts({ contactsFailed, contactsImported, contactsSkipped });
    } else {
      setQueue((prev) => {
        const rest = [...prev];
        rest.shift();
        setCountSkipped(rest);
        return [];
      });
    }
  };

  const toggleVisibility = () => {
    if (modalRef.current) {
      modalRef.current.toggleModal();
    }
  };

  const importContact = async (contact: ImportContact) => {
    const regex = /[^+^\d]+/gi; // Replacing all non numeric characters except for +
    const variables: CreateOrUpdateCourierUserContactMutationVariables = {
      inviteUserContact: true,
      userContactInput: {
        expoContactId: '',
        phone: contact.phoneNumber.replaceAll(regex, ''),
        phoneUnformatted: contact.phoneNumber,
        email: contact.email,
        firstNames: contact.firstName,
        lastName: contact.lastName,
        expoContact: null,
        importSource: UserContactImportSourceType.CSV,
      },
    };
    try {
      const result = await createOrUpdateUserContactMutation({
        variables,
        context: {
          cb: () => {
            setCountFailure((prev) => [...prev, { ...contact, status: 'inviteFailed' }]);
          },
        },
      });
      if (result) {
        setCountSuccess((prev) => [...prev, { ...contact, status: 'inviteSuccess' }]);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const importContacts = async () => {
    setUploading(true);
    const contact = queue[0];
    await importContact(contact);
    setUploading(false);
    setQueue((prev) => prev.filter((item) => item.index !== contact.index));
  };

  useEffect(() => {
    if (queue.length) {
      importContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countSuccess, countFailure, queue, visible]);

  useEffect(() => {
    if (visible && !contacts?.length) {
      setVisible(false);
      toggleVisibility();
    } else if (!visible && contacts?.length) {
      setVisible(true);
      toggleVisibility();
      setQueue(contacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts?.length, visible]);

  // RENDER
  const totalCount = contacts?.length || 0;
  const count = countFailure.length + countSuccess.length + countSkipped.length;
  let width = dimensions.screen.width - 30 - 60;
  if (width > 600) {
    width = 600;
  }

  const uploadingText = useMemo(() => {
    if (!queue.length && uploading) {
      return t('common.invitationCancelledUploadingStartedOne', {
        defaultValue: 'Invitation cancelled, waiting to finish last started one...',
      });
    }
    if (totalCount === count) {
      return t('common.invitationCompleted', { defaultValue: 'Invitation completed' });
    }
    return t('common.invitationInProgress', { defaultValue: 'Invitation in progress..' });
  }, [count, queue.length, totalCount, t, uploading]);

  const cancelDoneButtonText = useMemo(() => {
    if ((queue.length === 0 && !uploading) || totalCount === count) {
      return t('common.done', { defaultValue: 'Done' });
    }
    return t('common.cancel', { defaultValue: 'Cancel' });
  }, [count, totalCount, t, queue, uploading]);

  return (
    <AnimatedFullscreenModal
      ref={modalRef}
      fullHeight={false}
      fullWidth={false}
      onClose={handleClose}
    >
      <View style={[globalStyle.alignItemsCenter, globalStyle.justifyContentCenter]}>
        <View
          style={[
            globalStyle.flexRow,
            globalStyle.justifyContentBetween,
            globalStyle.width100Percent,
            globalStyle.marginBottom5,
          ]}
        >
          <Text>{uploadingText}</Text>
          {count !== totalCount && (
            <Text>
              {count}/{totalCount}
            </Text>
          )}
        </View>
        <View style={[styles.progressBarContainer, { width }]}>
          <View
            style={[
              styles.progressBar,
              {
                width: (width / totalCount) * count,
              },
            ]}
          />
        </View>
      </View>

      <View
        style={[
          globalStyle.flexRow,
          globalStyle.justifyContentBetween,
          globalStyle.alignItemsEnd,
          globalStyle.width100Percent,
          globalStyle.marginBottom5,
        ]}
      >
        <View>
          <Text style={[globalStyle.fontSize13, globalStyle.marginTop10]}>
            {countSuccess.length} {t('common.successful', { defaultValue: 'successful' })}
          </Text>
          <Text style={globalStyle.fontSize13}>
            {countFailure.length} {t('common.failed', { defaultValue: 'failed' })}
          </Text>
          {!!countSkipped.length && (
            <Text style={globalStyle.fontSize13}>
              {countSkipped.length} {t('common.skipped', { defaultValue: 'skipped' })}
            </Text>
          )}
          <Text style={globalStyle.fontSize13}>
            {totalCount - count} {t('common.remaining', { defaultValue: 'remaining' })}
          </Text>
        </View>
        <Button appearance="filled" size="medium" onPress={handleClose}>
          {cancelDoneButtonText}
        </Button>
      </View>
    </AnimatedFullscreenModal>
  );
};
