export const CountryListData = [
  {
    code2: 'AF',
    name: 'Afghanistan',
    code: 'AFG',
    number: '004',
  },
  {
    code2: 'AX',
    name: 'Åland Islands',
    code: 'ALA',
    number: '248',
  },
  {
    code2: 'AL',
    name: 'Albania',
    code: 'ALB',
    number: '008',
  },
  {
    code2: 'DZ',
    name: 'Algeria',
    code: 'DZA',
    number: '012',
  },
  {
    code2: 'AS',
    name: 'American Samoa',
    code: 'ASM',
    number: '016',
  },
  {
    code2: 'AD',
    name: 'Andorra',
    code: 'AND',
    number: '020',
  },
  {
    code2: 'AO',
    name: 'Angola',
    code: 'AGO',
    number: '024',
  },
  {
    code2: 'AI',
    name: 'Anguilla',
    code: 'AIA',
    number: '660',
  },
  {
    code2: 'AQ',
    name: 'Antarctica',
    code: 'ATA',
    number: '010',
  },
  {
    code2: 'AG',
    name: 'Antigua and Barbuda',
    code: 'ATG',
    number: '028',
  },
  {
    code2: 'AR',
    name: 'Argentina',
    code: 'ARG',
    number: '032',
  },
  {
    code2: 'AM',
    name: 'Armenia',
    code: 'ARM',
    number: '051',
  },
  {
    code2: 'AW',
    name: 'Aruba',
    code: 'ABW',
    number: '533',
  },
  {
    code2: 'AU',
    name: 'Australia',
    code: 'AUS',
    number: '036',
  },
  {
    code2: 'AT',
    name: 'Austria',
    code: 'AUT',
    number: '040',
  },
  {
    code2: 'AZ',
    name: 'Azerbaijan',
    code: 'AZE',
    number: '031',
  },
  {
    code2: 'BS',
    name: 'Bahamas',
    code: 'BHS',
    number: '044',
  },
  {
    code2: 'BH',
    name: 'Bahrain',
    code: 'BHR',
    number: '048',
  },
  {
    code2: 'BD',
    name: 'Bangladesh',
    code: 'BGD',
    number: '050',
  },
  {
    code2: 'BB',
    name: 'Barbados',
    code: 'BRB',
    number: '052',
  },
  {
    code2: 'BY',
    name: 'Belarus',
    code: 'BLR',
    number: '112',
  },
  {
    code2: 'BE',
    name: 'Belgium',
    code: 'BEL',
    number: '056',
  },
  {
    code2: 'BZ',
    name: 'Belize',
    code: 'BLZ',
    number: '084',
  },
  {
    code2: 'BJ',
    name: 'Benin',
    code: 'BEN',
    number: '204',
  },
  {
    code2: 'BM',
    name: 'Bermuda',
    code: 'BMU',
    number: '060',
  },
  {
    code2: 'BT',
    name: 'Bhutan',
    code: 'BTN',
    number: '064',
  },
  {
    code2: 'BO',
    name: 'Bolivia',
    code: 'BOL',
    number: '068',
  },
  {
    code2: 'BA',
    name: 'Bosnia and Herzegovina',
    code: 'BIH',
    number: '070',
  },
  {
    code2: 'BW',
    name: 'Botswana',
    code: 'BWA',
    number: '072',
  },
  {
    code2: 'BV',
    name: 'Bouvet Island',
    code: 'BVT',
    number: '074',
  },
  {
    code2: 'BR',
    name: 'Brazil',
    code: 'BRA',
    number: '076',
  },
  {
    code2: 'IO',
    name: 'British Indian Ocean Territory',
    code: 'IOT',
    number: '086',
  },
  {
    code2: 'VG',
    name: 'British Virgin Islands',
    code: 'VGB',
    number: '092',
  },
  {
    code2: 'BN',
    name: 'Brunei',
    code: 'BRN',
    number: '096',
  },
  {
    code2: 'BG',
    name: 'Bulgaria',
    code: 'BGR',
    number: '100',
  },
  {
    code2: 'BF',
    name: 'Burkina Faso',
    code: 'BFA',
    number: '854',
  },
  {
    code2: 'BI',
    name: 'Burundi',
    code: 'BDI',
    number: '108',
  },
  {
    code2: 'KH',
    name: 'Cambodia',
    code: 'KHM',
    number: '116',
  },
  {
    code2: 'CM',
    name: 'Cameroon',
    code: 'CMR',
    number: '120',
  },
  {
    code2: 'CA',
    name: 'Canada',
    code: 'CAN',
    number: '124',
  },
  {
    code2: 'CV',
    name: 'Cape Verde',
    code: 'CPV',
    number: '132',
  },
  {
    code2: 'BQ',
    name: 'Caribbean Netherlands',
    code: 'BES',
    number: '535',
  },
  {
    code2: 'KY',
    name: 'Cayman Islands',
    code: 'CYM',
    number: '136',
  },
  {
    code2: 'CF',
    name: 'Central African Republic',
    code: 'CAF',
    number: '140',
  },
  {
    code2: 'TD',
    name: 'Chad',
    code: 'TCD',
    number: '148',
  },
  {
    code2: 'CL',
    name: 'Chile',
    code: 'CHL',
    number: '152',
  },
  {
    code2: 'CN',
    name: 'China',
    code: 'CHN',
    number: '156',
  },
  {
    code2: 'CX',
    name: 'Christmas Island',
    code: 'CXR',
    number: '162',
  },
  {
    code2: 'CC',
    name: 'Cocos (Keeling) Islands',
    code: 'CCK',
    number: '166',
  },
  {
    code2: 'CO',
    name: 'Colombia',
    code: 'COL',
    number: '170',
  },
  {
    code2: 'KM',
    name: 'Comoros',
    code: 'COM',
    number: '174',
  },
  {
    code2: 'CK',
    name: 'Cook Islands',
    code: 'COK',
    number: '184',
  },
  {
    code2: 'CR',
    name: 'Costa Rica',
    code: 'CRI',
    number: '188',
  },
  {
    code2: 'HR',
    name: 'Croatia',
    code: 'HRV',
    number: '191',
  },
  {
    code2: 'CU',
    name: 'Cuba',
    code: 'CUB',
    number: '192',
  },
  {
    code2: 'CW',
    name: 'Curaçao',
    code: 'CUW',
    number: '531',
  },
  {
    code2: 'CY',
    name: 'Cyprus',
    code: 'CYP',
    number: '196',
  },
  {
    code2: 'CZ',
    name: 'Czechia',
    code: 'CZE',
    number: '203',
  },
  {
    code2: 'DK',
    name: 'Denmark',
    code: 'DNK',
    number: '208',
  },
  {
    code2: 'DJ',
    name: 'Djibouti',
    code: 'DJI',
    number: '262',
  },
  {
    code2: 'DM',
    name: 'Dominica',
    code: 'DMA',
    number: '212',
  },
  {
    code2: 'DO',
    name: 'Dominican Republic',
    code: 'DOM',
    number: '214',
  },
  {
    code2: 'CD',
    name: 'DR Congo',
    code: 'COD',
    number: '180',
  },
  {
    code2: 'EC',
    name: 'Ecuador',
    code: 'ECU',
    number: '218',
  },
  {
    code2: 'EG',
    name: 'Egypt',
    code: 'EGY',
    number: '818',
  },
  {
    code2: 'SV',
    name: 'El Salvador',
    code: 'SLV',
    number: '222',
  },
  {
    code2: 'GQ',
    name: 'Equatorial Guinea',
    code: 'GNQ',
    number: '226',
  },
  {
    code2: 'ER',
    name: 'Eritrea',
    code: 'ERI',
    number: '232',
  },
  {
    code2: 'EE',
    name: 'Estonia',
    code: 'EST',
    number: '233',
  },
  {
    code2: 'SZ',
    name: 'Eswatini',
    code: 'SWZ',
    number: '748',
  },
  {
    code2: 'ET',
    name: 'Ethiopia',
    code: 'ETH',
    number: '231',
  },
  {
    code2: 'FK',
    name: 'Falkland Islands',
    code: 'FLK',
    number: '238',
  },
  {
    code2: 'FO',
    name: 'Faroe Islands',
    code: 'FRO',
    number: '234',
  },
  {
    code2: 'FJ',
    name: 'Fiji',
    code: 'FJI',
    number: '242',
  },
  {
    code2: 'FI',
    name: 'Finland',
    code: 'FIN',
    number: '246',
  },
  {
    code2: 'FR',
    name: 'France',
    code: 'FRA',
    number: '250',
  },
  {
    code2: 'GF',
    name: 'French Guiana',
    code: 'GUF',
    number: '254',
  },
  {
    code2: 'PF',
    name: 'French Polynesia',
    code: 'PYF',
    number: '258',
  },
  {
    code2: 'TF',
    name: 'French Southern and Antarctic Lands',
    code: 'ATF',
    number: '260',
  },
  {
    code2: 'GA',
    name: 'Gabon',
    code: 'GAB',
    number: '266',
  },
  {
    code2: 'GM',
    name: 'Gambia',
    code: 'GMB',
    number: '270',
  },
  {
    code2: 'GE',
    name: 'Georgia',
    code: 'GEO',
    number: '268',
  },
  {
    code2: 'DE',
    name: 'Germany',
    code: 'DEU',
    number: '276',
  },
  {
    code2: 'GH',
    name: 'Ghana',
    code: 'GHA',
    number: '288',
  },
  {
    code2: 'GI',
    name: 'Gibraltar',
    code: 'GIB',
    number: '292',
  },
  {
    code2: 'GR',
    name: 'Greece',
    code: 'GRC',
    number: '300',
  },
  {
    code2: 'GL',
    name: 'Greenland',
    code: 'GRL',
    number: '304',
  },
  {
    code2: 'GD',
    name: 'Grenada',
    code: 'GRD',
    number: '308',
  },
  {
    code2: 'GP',
    name: 'Guadeloupe',
    code: 'GLP',
    number: '312',
  },
  {
    code2: 'GU',
    name: 'Guam',
    code: 'GUM',
    number: '316',
  },
  {
    code2: 'GT',
    name: 'Guatemala',
    code: 'GTM',
    number: '320',
  },
  {
    code2: 'GG',
    name: 'Guernsey',
    code: 'GGY',
    number: '831',
  },
  {
    code2: 'GN',
    name: 'Guinea',
    code: 'GIN',
    number: '324',
  },
  {
    code2: 'GW',
    name: 'Guinea-Bissau',
    code: 'GNB',
    number: '624',
  },
  {
    code2: 'GY',
    name: 'Guyana',
    code: 'GUY',
    number: '328',
  },
  {
    code2: 'HT',
    name: 'Haiti',
    code: 'HTI',
    number: '332',
  },
  {
    code2: 'HM',
    name: 'Heard Island and McDonald Islands',
    code: 'HMD',
    number: '334',
  },
  {
    code2: 'HN',
    name: 'Honduras',
    code: 'HND',
    number: '340',
  },
  {
    code2: 'HK',
    name: 'Hong Kong',
    code: 'HKG',
    number: '344',
  },
  {
    code2: 'HU',
    name: 'Hungary',
    code: 'HUN',
    number: '348',
  },
  {
    code2: 'IS',
    name: 'Iceland',
    code: 'ISL',
    number: '352',
  },
  {
    code2: 'IN',
    name: 'India',
    code: 'IND',
    number: '356',
  },
  {
    code2: 'ID',
    name: 'Indonesia',
    code: 'IDN',
    number: '360',
  },
  {
    code2: 'IR',
    name: 'Iran',
    code: 'IRN',
    number: '364',
  },
  {
    code2: 'IQ',
    name: 'Iraq',
    code: 'IRQ',
    number: '368',
  },
  {
    code2: 'IE',
    name: 'Ireland',
    code: 'IRL',
    number: '372',
  },
  {
    code2: 'IM',
    name: 'Isle of Man',
    code: 'IMN',
    number: '833',
  },
  {
    code2: 'IL',
    name: 'Israel',
    code: 'ISR',
    number: '376',
  },
  {
    code2: 'IT',
    name: 'Italy',
    code: 'ITA',
    number: '380',
  },
  {
    code2: 'CI',
    name: 'Ivory Coast',
    code: 'CIV',
    number: '384',
  },
  {
    code2: 'JM',
    name: 'Jamaica',
    code: 'JAM',
    number: '388',
  },
  {
    code2: 'JP',
    name: 'Japan',
    code: 'JPN',
    number: '392',
  },
  {
    code2: 'JE',
    name: 'Jersey',
    code: 'JEY',
    number: '832',
  },
  {
    code2: 'JO',
    name: 'Jordan',
    code: 'JOR',
    number: '400',
  },
  {
    code2: 'KZ',
    name: 'Kazakhstan',
    code: 'KAZ',
    number: '398',
  },
  {
    code2: 'KE',
    name: 'Kenya',
    code: 'KEN',
    number: '404',
  },
  {
    code2: 'KI',
    name: 'Kiribati',
    code: 'KIR',
    number: '296',
  },
  {
    code2: 'XK',
    name: 'Kosovo',
    code: 'UNK',
    number: '',
  },
  {
    code2: 'KW',
    name: 'Kuwait',
    code: 'KWT',
    number: '414',
  },
  {
    code2: 'KG',
    name: 'Kyrgyzstan',
    code: 'KGZ',
    number: '417',
  },
  {
    code2: 'LA',
    name: 'Laos',
    code: 'LAO',
    number: '418',
  },
  {
    code2: 'LV',
    name: 'Latvia',
    code: 'LVA',
    number: '428',
  },
  {
    code2: 'LB',
    name: 'Lebanon',
    code: 'LBN',
    number: '422',
  },
  {
    code2: 'LS',
    name: 'Lesotho',
    code: 'LSO',
    number: '426',
  },
  {
    code2: 'LR',
    name: 'Liberia',
    code: 'LBR',
    number: '430',
  },
  {
    code2: 'LY',
    name: 'Libya',
    code: 'LBY',
    number: '434',
  },
  {
    code2: 'LI',
    name: 'Liechtenstein',
    code: 'LIE',
    number: '438',
  },
  {
    code2: 'LT',
    name: 'Lithuania',
    code: 'LTU',
    number: '440',
  },
  {
    code2: 'LU',
    name: 'Luxembourg',
    code: 'LUX',
    number: '442',
  },
  {
    code2: 'MO',
    name: 'Macau',
    code: 'MAC',
    number: '446',
  },
  {
    code2: 'MG',
    name: 'Madagascar',
    code: 'MDG',
    number: '450',
  },
  {
    code2: 'MW',
    name: 'Malawi',
    code: 'MWI',
    number: '454',
  },
  {
    code2: 'MY',
    name: 'Malaysia',
    code: 'MYS',
    number: '458',
  },
  {
    code2: 'MV',
    name: 'Maldives',
    code: 'MDV',
    number: '462',
  },
  {
    code2: 'ML',
    name: 'Mali',
    code: 'MLI',
    number: '466',
  },
  {
    code2: 'MT',
    name: 'Malta',
    code: 'MLT',
    number: '470',
  },
  {
    code2: 'MH',
    name: 'Marshall Islands',
    code: 'MHL',
    number: '584',
  },
  {
    code2: 'MQ',
    name: 'Martinique',
    code: 'MTQ',
    number: '474',
  },
  {
    code2: 'MR',
    name: 'Mauritania',
    code: 'MRT',
    number: '478',
  },
  {
    code2: 'MU',
    name: 'Mauritius',
    code: 'MUS',
    number: '480',
  },
  {
    code2: 'YT',
    name: 'Mayotte',
    code: 'MYT',
    number: '175',
  },
  {
    code2: 'MX',
    name: 'Mexico',
    code: 'MEX',
    number: '484',
  },
  {
    code2: 'FM',
    name: 'Micronesia',
    code: 'FSM',
    number: '583',
  },
  {
    code2: 'MD',
    name: 'Moldova',
    code: 'MDA',
    number: '498',
  },
  {
    code2: 'MC',
    name: 'Monaco',
    code: 'MCO',
    number: '492',
  },
  {
    code2: 'MN',
    name: 'Mongolia',
    code: 'MNG',
    number: '496',
  },
  {
    code2: 'ME',
    name: 'Montenegro',
    code: 'MNE',
    number: '499',
  },
  {
    code2: 'MS',
    name: 'Montserrat',
    code: 'MSR',
    number: '500',
  },
  {
    code2: 'MA',
    name: 'Morocco',
    code: 'MAR',
    number: '504',
  },
  {
    code2: 'MZ',
    name: 'Mozambique',
    code: 'MOZ',
    number: '508',
  },
  {
    code2: 'MM',
    name: 'Myanmar',
    code: 'MMR',
    number: '104',
  },
  {
    code2: 'NA',
    name: 'Namibia',
    code: 'NAM',
    number: '516',
  },
  {
    code2: 'NR',
    name: 'Nauru',
    code: 'NRU',
    number: '520',
  },
  {
    code2: 'NP',
    name: 'Nepal',
    code: 'NPL',
    number: '524',
  },
  {
    code2: 'NL',
    name: 'Netherlands',
    code: 'NLD',
    number: '528',
  },
  {
    code2: 'NC',
    name: 'New Caledonia',
    code: 'NCL',
    number: '540',
  },
  {
    code2: 'NZ',
    name: 'New Zealand',
    code: 'NZL',
    number: '554',
  },
  {
    code2: 'NI',
    name: 'Nicaragua',
    code: 'NIC',
    number: '558',
  },
  {
    code2: 'NE',
    name: 'Niger',
    code: 'NER',
    number: '562',
  },
  {
    code2: 'NG',
    name: 'Nigeria',
    code: 'NGA',
    number: '566',
  },
  {
    code2: 'NU',
    name: 'Niue',
    code: 'NIU',
    number: '570',
  },
  {
    code2: 'NF',
    name: 'Norfolk Island',
    code: 'NFK',
    number: '574',
  },
  {
    code2: 'KP',
    name: 'North Korea',
    code: 'PRK',
    number: '408',
  },
  {
    code2: 'MK',
    name: 'North Macedonia',
    code: 'MKD',
    number: '807',
  },
  {
    code2: 'MP',
    name: 'Northern Mariana Islands',
    code: 'MNP',
    number: '580',
  },
  {
    code2: 'NO',
    name: 'Norway',
    code: 'NOR',
    number: '578',
  },
  {
    code2: 'OM',
    name: 'Oman',
    code: 'OMN',
    number: '512',
  },
  {
    code2: 'PK',
    name: 'Pakistan',
    code: 'PAK',
    number: '586',
  },
  {
    code2: 'PW',
    name: 'Palau',
    code: 'PLW',
    number: '585',
  },
  {
    code2: 'PS',
    name: 'Palestine',
    code: 'PSE',
    number: '275',
  },
  {
    code2: 'PA',
    name: 'Panama',
    code: 'PAN',
    number: '591',
  },
  {
    code2: 'PG',
    name: 'Papua New Guinea',
    code: 'PNG',
    number: '598',
  },
  {
    code2: 'PY',
    name: 'Paraguay',
    code: 'PRY',
    number: '600',
  },
  {
    code2: 'PE',
    name: 'Peru',
    code: 'PER',
    number: '604',
  },
  {
    code2: 'PH',
    name: 'Philippines',
    code: 'PHL',
    number: '608',
  },
  {
    code2: 'PN',
    name: 'Pitcairn Islands',
    code: 'PCN',
    number: '612',
  },
  {
    code2: 'PL',
    name: 'Poland',
    code: 'POL',
    number: '616',
  },
  {
    code2: 'PT',
    name: 'Portugal',
    code: 'PRT',
    number: '620',
  },
  {
    code2: 'PR',
    name: 'Puerto Rico',
    code: 'PRI',
    number: '630',
  },
  {
    code2: 'QA',
    name: 'Qatar',
    code: 'QAT',
    number: '634',
  },
  {
    code2: 'CG',
    name: 'Republic of the Congo',
    code: 'COG',
    number: '178',
  },
  {
    code2: 'RE',
    name: 'Réunion',
    code: 'REU',
    number: '638',
  },
  {
    code2: 'RO',
    name: 'Romania',
    code: 'ROU',
    number: '642',
  },
  {
    code2: 'RU',
    name: 'Russia',
    code: 'RUS',
    number: '643',
  },
  {
    code2: 'RW',
    name: 'Rwanda',
    code: 'RWA',
    number: '646',
  },
  {
    code2: 'BL',
    name: 'Saint Barthélemy',
    code: 'BLM',
    number: '652',
  },
  {
    code2: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SHN',
    number: '654',
  },
  {
    code2: 'KN',
    name: 'Saint Kitts and Nevis',
    code: 'KNA',
    number: '659',
  },
  {
    code2: 'LC',
    name: 'Saint Lucia',
    code: 'LCA',
    number: '662',
  },
  {
    code2: 'MF',
    name: 'Saint Martin',
    code: 'MAF',
    number: '663',
  },
  {
    code2: 'PM',
    name: 'Saint Pierre and Miquelon',
    code: 'SPM',
    number: '666',
  },
  {
    code2: 'VC',
    name: 'Saint Vincent and the Grenadines',
    code: 'VCT',
    number: '670',
  },
  {
    code2: 'WS',
    name: 'Samoa',
    code: 'WSM',
    number: '882',
  },
  {
    code2: 'SM',
    name: 'San Marino',
    code: 'SMR',
    number: '674',
  },
  {
    code2: 'ST',
    name: 'São Tomé and Príncipe',
    code: 'STP',
    number: '678',
  },
  {
    code2: 'SA',
    name: 'Saudi Arabia',
    code: 'SAU',
    number: '682',
  },
  {
    code2: 'SN',
    name: 'Senegal',
    code: 'SEN',
    number: '686',
  },
  {
    code2: 'RS',
    name: 'Serbia',
    code: 'SRB',
    number: '688',
  },
  {
    code2: 'SC',
    name: 'Seychelles',
    code: 'SYC',
    number: '690',
  },
  {
    code2: 'SL',
    name: 'Sierra Leone',
    code: 'SLE',
    number: '694',
  },
  {
    code2: 'SG',
    name: 'Singapore',
    code: 'SGP',
    number: '702',
  },
  {
    code2: 'SX',
    name: 'Sint Maarten',
    code: 'SXM',
    number: '534',
  },
  {
    code2: 'SK',
    name: 'Slovakia',
    code: 'SVK',
    number: '703',
  },
  {
    code2: 'SI',
    name: 'Slovenia',
    code: 'SVN',
    number: '705',
  },
  {
    code2: 'SB',
    name: 'Solomon Islands',
    code: 'SLB',
    number: '090',
  },
  {
    code2: 'SO',
    name: 'Somalia',
    code: 'SOM',
    number: '706',
  },
  {
    code2: 'ZA',
    name: 'South Africa',
    code: 'ZAF',
    number: '710',
  },
  {
    code2: 'GS',
    name: 'South Georgia',
    code: 'SGS',
    number: '239',
  },
  {
    code2: 'KR',
    name: 'South Korea',
    code: 'KOR',
    number: '410',
  },
  {
    code2: 'SS',
    name: 'South Sudan',
    code: 'SSD',
    number: '728',
  },
  {
    code2: 'ES',
    name: 'Spain',
    code: 'ESP',
    number: '724',
  },
  {
    code2: 'LK',
    name: 'Sri Lanka',
    code: 'LKA',
    number: '144',
  },
  {
    code2: 'SD',
    name: 'Sudan',
    code: 'SDN',
    number: '729',
  },
  {
    code2: 'SR',
    name: 'Suriname',
    code: 'SUR',
    number: '740',
  },
  {
    code2: 'SJ',
    name: 'Svalbard and Jan Mayen',
    code: 'SJM',
    number: '744',
  },
  {
    code2: 'SE',
    name: 'Sweden',
    code: 'SWE',
    number: '752',
  },
  {
    code2: 'CH',
    name: 'Switzerland',
    code: 'CHE',
    number: '756',
  },
  {
    code2: 'SY',
    name: 'Syria',
    code: 'SYR',
    number: '760',
  },
  {
    code2: 'TW',
    name: 'Taiwan',
    code: 'TWN',
    number: '158',
  },
  {
    code2: 'TJ',
    name: 'Tajikistan',
    code: 'TJK',
    number: '762',
  },
  {
    code2: 'TZ',
    name: 'Tanzania',
    code: 'TZA',
    number: '834',
  },
  {
    code2: 'TH',
    name: 'Thailand',
    code: 'THA',
    number: '764',
  },
  {
    code2: 'TL',
    name: 'Timor-Leste',
    code: 'TLS',
    number: '626',
  },
  {
    code2: 'TG',
    name: 'Togo',
    code: 'TGO',
    number: '768',
  },
  {
    code2: 'TK',
    name: 'Tokelau',
    code: 'TKL',
    number: '772',
  },
  {
    code2: 'TO',
    name: 'Tonga',
    code: 'TON',
    number: '776',
  },
  {
    code2: 'TT',
    name: 'Trinidad and Tobago',
    code: 'TTO',
    number: '780',
  },
  {
    code2: 'TN',
    name: 'Tunisia',
    code: 'TUN',
    number: '788',
  },
  {
    code2: 'TR',
    name: 'Turkey',
    code: 'TUR',
    number: '792',
  },
  {
    code2: 'TM',
    name: 'Turkmenistan',
    code: 'TKM',
    number: '795',
  },
  {
    code2: 'TC',
    name: 'Turks and Caicos Islands',
    code: 'TCA',
    number: '796',
  },
  {
    code2: 'TV',
    name: 'Tuvalu',
    code: 'TUV',
    number: '798',
  },
  {
    code2: 'UG',
    name: 'Uganda',
    code: 'UGA',
    number: '800',
  },
  {
    code2: 'UA',
    name: 'Ukraine',
    code: 'UKR',
    number: '804',
  },
  {
    code2: 'AE',
    name: 'United Arab Emirates',
    code: 'ARE',
    number: '784',
  },
  {
    code2: 'GB',
    name: 'United Kingdom',
    code: 'GBR',
    number: '826',
  },
  {
    code2: 'US',
    name: 'United States',
    code: 'USA',
    number: '840',
  },
  {
    code2: 'UM',
    name: 'United States Minor Outlying Islands',
    code: 'UMI',
    number: '581',
  },
  {
    code2: 'VI',
    name: 'United States Virgin Islands',
    code: 'VIR',
    number: '850',
  },
  {
    code2: 'UY',
    name: 'Uruguay',
    code: 'URY',
    number: '858',
  },
  {
    code2: 'UZ',
    name: 'Uzbekistan',
    code: 'UZB',
    number: '860',
  },
  {
    code2: 'VU',
    name: 'Vanuatu',
    code: 'VUT',
    number: '548',
  },
  {
    code2: 'VA',
    name: 'Vatican City',
    code: 'VAT',
    number: '336',
  },
  {
    code2: 'VE',
    name: 'Venezuela',
    code: 'VEN',
    number: '862',
  },
  {
    code2: 'VN',
    name: 'Vietnam',
    code: 'VNM',
    number: '704',
  },
  {
    code2: 'WF',
    name: 'Wallis and Futuna',
    code: 'WLF',
    number: '876',
  },
  {
    code2: 'EH',
    name: 'Western Sahara',
    code: 'ESH',
    number: '732',
  },
  {
    code2: 'YE',
    name: 'Yemen',
    code: 'YEM',
    number: '887',
  },
  {
    code2: 'ZM',
    name: 'Zambia',
    code: 'ZMB',
    number: '894',
  },
  {
    code2: 'ZW',
    name: 'Zimbabwe',
    code: 'ZWE',
    number: '716',
  },
];
