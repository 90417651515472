import React, { useContext } from 'react';
import { RefreshControl } from 'react-native';

import { ApolloQueryResult, useMutation } from '@apollo/client';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { FlatList } from 'react-native-gesture-handler';

import { MUTATION_ORGANIZATION_INVITATION_DELETE } from '../../../apollo/graphql-mutations';
import { globalStyle } from '../../../common/style';
import { AgencyEmployeeOrganizationInvitationCard } from '../../../components/common/Invitation/AgencyEmployeeOrganizationInvitationCard';
import { LoadingSpinner } from '../../../components/common/loading-spinner.component';
import { ConfirmModalContext } from '../../../components/common/modal/ConfirmModal';
import {
  EmployeeInvitationsForAgencyOrganizationQuery,
  Exact,
  OrganizationInvitation,
} from '../../../generated-graphql-types';

export const AgencyEmployeeInvitationOpenInvitationsScreen = ({
  organizationInvitations,
  isLoading,
  refetch,
}: {
  organizationInvitations: Array<Partial<OrganizationInvitation>>;
  isLoading: boolean;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            agencyOrganizationId: string;
            userId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<EmployeeInvitationsForAgencyOrganizationQuery>>;
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const { hideConfirmModal, showConfirmModal } = useContext(ConfirmModalContext);

  const [organizationInvitationDelete] = useMutation(MUTATION_ORGANIZATION_INVITATION_DELETE, {
    onCompleted: () => {
      refetch();
    },
  });

  const handleCancelInvitationPress = (item: Partial<OrganizationInvitation>) => async () => {
    showConfirmModal({
      confirmButtonStatus: 'danger',
      confirmButtonAppearance: 'filled',
      confirmButtonText: t('common.yes', { defaultValue: 'Yes' }),
      cancelButtonText: t('common.no', { defaultValue: 'No' }),
      title: t('common.cancelInvitation', { defaultValue: 'Cancel invitation' }),
      text: t('common.cancelInvitationConfirmModalText', {
        defaultValue: 'Are you sure you want to cancel selected invitation?',
      }),
      onConfirmPress: async () => {
        try {
          await organizationInvitationDelete({ variables: { organizationInvitationId: item.id } });
          await refetch();
          return true;
        } catch (e) {
          console.warn(e);
          hideConfirmModal();
          showMessage({
            message: t('common.error', { defaultValue: 'Error' }) as string,
            description: t('common.somethingWentWrong', {
              defaultValue: 'Something went wrong',
            }) as string,
            type: 'danger',
            duration: 5000,
            autoHide: true,
            hideOnPress: true,
          });
          return false;
        }
      },
      visible: true,
    });
  };

  const renderListHeaderComponent = () => {
    if (!organizationInvitations.length) {
      return <></>;
    }
    return (
      <Text style={[globalStyle.fontSize12, globalStyle.textAlignCenter]}>
        {
          t('common.contactPriojetToSendCodeToEmployee', {
            defaultValue:
              '*** please reach out to PRIOjet at support@priojet.com to send the code again to the employee.',
          }) as string
        }
      </Text>
    );
  };

  const renderListEmptyComponent = () => (
    <Text style={styles.empty}>
      {
        t('common.noEmployeeInvitationsFound', {
          defaultValue: 'No employee invitations found.',
        }) as string
      }
    </Text>
  );

  const keyExtractor = (item: Partial<OrganizationInvitation>) => item.id as string;

  const renderItem = ({ item }: { item: Partial<OrganizationInvitation> }) => (
    <AgencyEmployeeOrganizationInvitationCard
      onCancelInvitationPress={handleCancelInvitationPress(item)}
      organizationInvitation={item}
    />
  );

  if (isLoading || !organizationInvitations) {
    return <LoadingSpinner />;
  }
  return (
    <FlatList
      refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refetch} />}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      data={organizationInvitations}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ListHeaderComponent={renderListHeaderComponent()}
      ListEmptyComponent={renderListEmptyComponent()}
    />
  );
};

const themedStyles = StyleService.create({
  empty: {
    padding: 20,
    textAlign: 'center',
  },
  divider: {
    marginVertical: 5,
  },
  organizationName: {
    paddingTop: 5,
    fontWeight: 'bold',
  },
});
