import React from 'react';
import { Platform } from 'react-native';

import { RenderProp } from '@ui-kitten/components/devsupport';
import { Divider, Text, TopNavigation } from '@ui-kitten/components/ui';

export const TopNavigationBarWithAccessoryRight = ({
  title,
  accessoryRight,
}: {
  title: string;
  accessoryRight: RenderProp<object> | undefined;
}) => {
  return (
    <>
      <TopNavigation
        title={(props) => (
          <Text
            selectable={true}
            {...props}
            style={{
              fontFamily: 'Lato_700Bold',
              fontSize: Platform.OS === 'web' ? 18 : 16,
            }}
          >
            {title.toUpperCase()}
          </Text>
        )}
        alignment="center"
        accessoryRight={accessoryRight}
      />
      <Divider />
    </>
  );
};
