import React, { useMemo } from 'react';
import {
  Platform,
  StyleProp,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

import {
  ButtonElement,
  EvaProp,
  Icon,
  Popover,
  Text,
  TextElement,
  StyleService,
  useStyleSheet,
} from '@ui-kitten/components';
import TimeAgo, { LocaleData } from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import fr from 'javascript-time-ago/locale/fr';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../common/style';

TimeAgo.addLocale(de as LocaleData);
TimeAgo.addLocale(en as LocaleData);
TimeAgo.addLocale(fr as LocaleData);
TimeAgo.addLocale(es as LocaleData);

export const TimeAgoPopoverText = ({
  text,
  date: dateProp,
  language,
  inititalVisibility,
  size,
  textStyle,
  popoverTextStyle,
  eva,
  style,
}: {
  text: string;
  date: DateTime | string;
  language: string;
  inititalVisibility?: boolean;
  size?: number;
  textStyle?: StyleProp<TextStyle>;
  popoverTextStyle?: StyleProp<TextStyle>;
  eva?: EvaProp;
  style?: StyleProp<ViewStyle>;
}) => {
  const styles = useStyleSheet(themedStyles);
  const { i18n } = useTranslation();

  const [visible, setVisible] = React.useState<boolean>(inititalVisibility || false);

  const timeAgo = useMemo(() => new TimeAgo(language || i18n.language), [language, i18n.language]);

  const { date, timeAgoString } = useMemo((): { date: DateTime; timeAgoString: string } => {
    if (typeof dateProp === 'string') {
      return {
        date: DateTime.fromJSDate(new Date(dateProp)),
        timeAgoString: timeAgo.format(DateTime.fromJSDate(new Date(dateProp)).toMillis()),
      };
    }
    return { date: dateProp, timeAgoString: timeAgo.format(dateProp.toMillis()) };
  }, [dateProp, timeAgo]);

  const togglePopover = (): void => {
    setVisible(!visible);
  };

  if (!textStyle && eva?.style?.textStyle) {
    textStyle = eva?.style?.textStyle;
  }

  const renderPopoverContent = (): TextElement => {
    return (
      <Text selectable={true} style={[globalStyle.padding5, popoverTextStyle]}>
        {date.setLocale(language).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS)}
      </Text>
    );
  };

  const renderToggleButton = (): ButtonElement => (
    <TouchableWithoutFeedback
      hitSlop={{ top: 50, bottom: 50, right: 50, left: 50 }}
      onPress={() => setVisible(!visible)}
    >
      <View style={[globalStyle.alignSelfBaseline, globalStyle.paddingTop2, style]}>
        <Text selectable={true} style={styles.textStyle}>
          {text.split('<Popover>')[0]}
          {timeAgoString as string}
          <Icon
            name="clock-outline"
            height={size}
            width={size}
            fill={(styles.icon as any).fillColor}
          />
          {text.split('<Popover>')[1]}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );

  return (
    <Popover
      placement="bottom"
      contentContainerStyle={styles.popoverContentContainer}
      visible={visible}
      anchor={renderToggleButton}
      onBackdropPress={togglePopover}
      fullWidth={false}
    >
      {renderPopoverContent()}
    </Popover>
  );
};

TimeAgoPopoverText.defaultProps = {
  initialVisibility: false,
  size: 16,
};

const themedStyles = StyleService.create({
  popoverContentContainer: {
    marginTop: Platform.OS === 'web' ? 10 : -40,
    maxWidth: 200,
  },
  icon: {
    color: 'text-basic-color',
  },
  textStyle: {
    color: 'text-basic-color',
  },
});
