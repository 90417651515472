import React from 'react';
import { Platform } from 'react-native';

import { createStackNavigator } from '@react-navigation/stack';
import { useTranslation } from 'react-i18next';


import { CommonProfileNavigator } from './common.profile.navigator';
import { PriojetOrganizationInvitationsNavigator } from './priojet.organization-invitations.navigator';
import { PriojetManageOrganizationsScreen } from '../screens/priojet/priojet.manage-organizations.screen';
import { PriojetManagePriojetUsersScreen } from '../screens/priojet/priojet.manage-priojet-users.screen';
import { PriojetManageUsersScreen } from '../screens/priojet/priojet.manage-users.screen';
import { PriojetProfileScreen } from '../screens/priojet/priojet.profile.screen';

const StackNavigator = createStackNavigator();

export const PriojetProfileNavigator = () => {
  const [t] = useTranslation();

  return (
    <StackNavigator.Navigator
      screenOptions={{
        headerShown: false,
        headerStatusBarHeight: 0,
        title: t('menuItems.PRIOjetProfile', {
          defaultValue: 'PRIOjet Profile',
        }) as string,
        headerTitleAlign: 'center',
        headerTitleStyle: {
          fontSize: Platform.OS == 'web' ? 24 : 16,
          fontFamily: 'Lato_700Bold',
          textTransform: 'uppercase',
        },
      }}
      initialRouteName="PriojetProfileScreen"
    >
      <StackNavigator.Group>
        <StackNavigator.Screen
          name="PriojetProfileScreen"
          component={PriojetProfileScreen}
        />
        <StackNavigator.Screen
          name="PriojetManagePriojetUsersScreen"
          component={PriojetManagePriojetUsersScreen}
        />
        <StackNavigator.Screen
          name="PriojetManageUsersScreen"
          component={PriojetManageUsersScreen}
        />
        <StackNavigator.Screen
          name="PriojetManageOrganizationsScreen"
          component={PriojetManageOrganizationsScreen}
        />
        <StackNavigator.Screen
          options={{
            headerShown: false,
          }}
          name="PriojetOrganizationInvitationsNavigator"
          component={PriojetOrganizationInvitationsNavigator}
        />

        <StackNavigator.Screen
          name="CommonProfileNavigator"
          component={CommonProfileNavigator}
        />
      </StackNavigator.Group>
    </StackNavigator.Navigator>
  );
};
