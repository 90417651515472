import {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LayoutAnimation, Linking, Platform, FlatList } from 'react-native';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useActionSheet } from '@expo/react-native-action-sheet';
import Constants from 'expo-constants';
import * as ExpoDocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { IMessage } from 'react-native-gifted-chat';

import { MutationUpdateType } from './types';
import { addFieldsToGiftedMessages, sortMessageNodes, updateChatMessageCacheCRUD } from './util';
import {
  CREATE_CHAT_MESSAGE_MUTATION,
  CREATE_CHAT_MESSAGE_FOR_AGENCY_MUTATION,
  DELETE_CHAT_MESSAGE_MUTATION,
  UPDATE_CHAT_MESSAGE_MUTATION,
  UPDATE_CHAT_MESSAGE_FOR_AGENCY_MUTATION,
} from '../../../apollo/graphql-mutations';
import {
  CHAT_MESSAGES_BY_CHAT_ID_QUERY,
  CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY,
  CHAT_MESSAGE_EDGE_QUERY,
  CHAT_MESSAGE_EDGE_FOR_AGENCY_QUERY,
  QUERY_USER_BY_ID,
} from '../../../apollo/graphql-queries';
import AppUserContext from '../../../contexts/AppUserContext';
import {
  ChatMessage,
  ChatMessageEdge,
  ChatType,
  MessageType,
} from '../../../generated-graphql-types';
import { CreateChatMessage, CreateChatMessageVariables } from '../../../graphql-types';
import { useIsBackendReachable } from '../../../hooks/useIsBackendReachable';
import { pickImageOnFrontEnd, uploadFileToServer } from '../../../utils/file-upload.util';

const PER_PAGE = 50;

export const useHook = ({
  chatId,
  chatType,
  chatRef,
  disableInputMessage: disableInputMessageProp,
}: {
  chatId: string;
  chatType: ChatType;
  chatRef: any;
  disableInputMessage?: string;
}) => {
  const messageContainerRef = useRef<FlatList<IMessage> | null>(null);
  const refetchInterval = useRef<NodeJS.Timer | null>(null);

  const appUserContext = useContext(AppUserContext);
  const currentUser = {
    ...appUserContext.currentUserContext?.user,
    id: appUserContext.currentUserContext?.user?.id,
    _id: appUserContext.currentUserContext?.user?.id,
  };
  const user = useMemo(
    () => ({
      _id: appUserContext.currentUserContext?.user?.id || '',
      name: `${appUserContext.currentUserContext?.user?.firstNames || ''} ${
        appUserContext.currentUserContext?.user?.lastName || ''
      }`,
    }),
    [appUserContext.currentUserContext],
  );

  const { showActionSheetWithOptions } = useActionSheet();
  const isBackendReachable = useIsBackendReachable();
  const { t } = useTranslation();

  const [cursor, setCursor] = useState<string>('');
  const [currentChatId, setCurrentChatId] = useState<string>(chatId);
  const [failedMessages, setFailedMessages] = useState<ChatMessageEdge[]>([]);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [loadingLatestMessages, setLoadingLatestMessages] = useState<boolean>(false);
  const [editingMessageEdge, setEditingMessageEdge] = useState<any | undefined>();
  const [messageText, setMessageText] = useState<string>('');
  const [pdfUri, setPDFUri] = useState('');
  const [visibleCamera, setVisibleCamera] = useState(false);

  const initialVariables = useMemo(
    () => ({
      chatMessagesByChatIdInput: { id: chatId },
      pagination: { limit: PER_PAGE, after: '' },
      orderBy: { field: 'createdAt', direction: 'desc', type: 'date' },
    }),
    [chatId],
  );

  const isAgencyJobChat = useMemo(() => chatType === ChatType.AGENCY_JOB_CHAT, [chatType]);

  /*********************************************************************************************************************
   * MUTATIONS WITH UPDATES
   ********************************************************************************************************************/
  const onUpdate =
    (dataKey: string, _type: any) =>
    (cache: any, { data }: any): void => {
      let messageEdge = data[dataKey];
      if (_type === MutationUpdateType.DELETE) {
        messageEdge = { node: data[dataKey] };
      }
      updateChatMessageCacheCRUD({
        cache,
        chatType,
        initialVariables,
        messageEdge,
        type: _type,
      });
    };

  const [createChatMessage] = useMutation<CreateChatMessage, CreateChatMessageVariables>(
    isAgencyJobChat ? CREATE_CHAT_MESSAGE_FOR_AGENCY_MUTATION : CREATE_CHAT_MESSAGE_MUTATION,
    {
      update: onUpdate('createChatMessage', MutationUpdateType.CREATE),
      onError: () => {},
    },
  );
  const [updateChatMessage] = useMutation(
    isAgencyJobChat ? UPDATE_CHAT_MESSAGE_FOR_AGENCY_MUTATION : UPDATE_CHAT_MESSAGE_MUTATION,
    {
      update: onUpdate('updateChatMessage', MutationUpdateType.UPDATE),
      onError: () => {},
    },
  );
  const [deleteChatMessage] = useMutation(DELETE_CHAT_MESSAGE_MUTATION, {
    update: onUpdate('deleteChatMessage', MutationUpdateType.DELETE),
    onError: () => {},
  });

  /*********************************************************************************************************************
   * DATA
   ********************************************************************************************************************/
  const [fetchInitialMessages, { client, data: chatMessageData, fetchMore }] = useLazyQuery(
    isAgencyJobChat ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
    {
      variables: initialVariables,
      fetchPolicy: isBackendReachable ? 'network-only' : 'cache-only',
      onCompleted: (data) => {
        setCursor(data.chatMessagesByChatId?.edges?.[0].cursor);
      },
    },
  );

  const { data: currentUserData } = useQuery(QUERY_USER_BY_ID, {
    variables: { id: currentUser.id },
  });
  const [fetchChatMessageEdge] = useLazyQuery(
    isAgencyJobChat ? CHAT_MESSAGE_EDGE_FOR_AGENCY_QUERY : CHAT_MESSAGE_EDGE_QUERY,
  );
  const [
    fetchAdditionalChatMessage,
    { data: additionalChatMessageData, loading: isLoadingAdditionalMessages },
  ] = useLazyQuery(
    isAgencyJobChat ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );

  const { canLoadMore, messageEdges, messages, messageIds } = useMemo(() => {
    let edgeData = [];
    if (chatMessageData?.chatMessagesByChatId) {
      if (chatMessageData?.chatMessagesByChatId?.edges?.length) {
        const { edges, totalCount } = chatMessageData?.chatMessagesByChatId;

        edgeData = sortMessageNodes([...edges]);

        if (failedMessages.length) {
          edgeData = sortMessageNodes([...edgeData, ...failedMessages]);
        }
        let messageData = edgeData.map(({ node }: any) => addFieldsToGiftedMessages(node));
        const messageIdsData = messageData.map((item: any) => item.id);
        return {
          canLoadMore: totalCount > edges.length,
          messageEdges: edgeData,
          messages: messageData,
          messageIds: messageIdsData,
          totalCount: chatMessageData?.chatMessagesByChatId.totalCount + failedMessages,
        };
      }
      let _messages = [];
      if (failedMessages.length) {
        edgeData = sortMessageNodes([...failedMessages]);
        if (edgeData.length) {
          _messages = sortMessageNodes([...edgeData]).map(({ node }: any) =>
            addFieldsToGiftedMessages(node),
          );
        }
      } else if (chatId) {
        const sendingUser = {
          ...(currentUserData?.user || {}),
          _id: currentUserData?.user?.id || 'dummyId',
        };
        _messages = [{ _id: 'FIRST_MESSAGE', sendingUser, user }];
        return {
          canLoadMore: false,
          messageEdges: [],
          messages: _messages,
          messageIds: [],
          totalCount: failedMessages.length,
        };
      }
    }

    return {
      canLoadMore: false,
      messageEdges: [],
      messages: [],
      messageIds: [],
      totalCount: 0,
    };
  }, [chatMessageData?.chatMessagesByChatId, failedMessages, chatId, currentUserData?.user, user]);

  /**
   * Merging old message connection with new message connection
   */
  const mergeMessages = (data: any, replaceOld = false) => {
    const { edges, totalCount } = data;

    const existingMessageConnection: any = client.cache.readQuery({
      query: isAgencyJobChat
        ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY
        : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
      variables: initialVariables,
    });

    let mergedEdges = edges;
    if (replaceOld) {
      mergedEdges = sortMessageNodes(mergedEdges);
    } else if (existingMessageConnection.chatMessagesByChatId.edges?.length) {
      mergedEdges = sortMessageNodes(
        unionBy(existingMessageConnection.chatMessagesByChatId.edges, edges),
      );
    }

    client.cache.writeQuery({
      query: isAgencyJobChat
        ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY
        : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
      variables: initialVariables,
      data: {
        chatMessagesByChatId: {
          edges: mergedEdges,
          totalCount,
        },
      },
    });
  };

  /**
   * initialize fetching latest messages every 15s when chat is opened - imitate live sync
   */
  const getLatestMessages = useCallback(async () => {
    try {
      const { data } = await fetchAdditionalChatMessage({
        variables: {
          chatMessagesByChatIdInput: { id: chatId },
          pagination: {
            limit: 100,
            before: cursor || '',
          },
          orderBy: { field: 'createdAt', direction: 'asc', type: 'date' },
        },
      });
      if (
        data.chatMessagesByChatId?.edges?.length &&
        data.chatMessagesByChatId?.edges.some(({ node }: any) => !messageIds.includes(node.id))
      ) {
        const newCursor =
          data.chatMessagesByChatId.edges[data.chatMessagesByChatId.edges.length - 1].cursor;
        setCursor(newCursor);
        mergeMessages(data.chatMessagesByChatId);
      }
    } catch (e) {
      console.warn(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, cursor, messageEdges]);

  useEffect((): any => {
    if (chatId && additionalChatMessageData && !refetchInterval.current) {
      refetchInterval.current = setInterval(() => {
        getLatestMessages();
      }, 15 * 1000);
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current as any);
        refetchInterval.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalChatMessageData, chatId, refetchInterval]);

  /**
   * fetch initial chat data, refreshing cache on screen load
   */
  const fetchInitialData = async () => {
    try {
      setLoadingLatestMessages(true);
      const res = await fetchAdditionalChatMessage({ variables: initialVariables });
      if (res?.data?.chatMessagesByChatId) {
        mergeMessages(res.data.chatMessagesByChatId, true);
      }
      setLoadingLatestMessages(false);
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect((): any => {
    if (
      chatId &&
      isBackendReachable &&
      chatMessageData &&
      !additionalChatMessageData &&
      !isLoadingAdditionalMessages
    ) {
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    additionalChatMessageData,
    chatId,
    chatMessageData,
    isBackendReachable,
    isLoadingAdditionalMessages,
  ]);

  const getInitialData = async () => {
    if (chatId !== currentChatId) {
      messageContainerRef.current?.scrollToOffset({ offset: 0, animated: false });
      setCurrentChatId(chatId);
    }
    await fetchInitialMessages({
      variables: initialVariables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });
  };

  useEffect((): any => {
    if (chatId) {
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, isBackendReachable, initialVariables]);

  /*********************************************************************************************************************
   * LOADING MORE
   ********************************************************************************************************************/
  const onLoadMore = async (): Promise<void> => {
    if (
      isBackendReachable &&
      canLoadMore &&
      !loadingMoreMessages &&
      messageEdges?.length &&
      fetchMore
    ) {
      setLoadingMoreMessages(true);
      try {
        const { data: newData } = await fetchMore({
          variables: {
            ...initialVariables,
            pagination: {
              ...initialVariables.pagination,
              after: messageEdges[messageEdges.length - 1]?.cursor,
            },
          },
        });

        if (newData?.chatMessagesByChatId.edges) {
          const existingMessageConnection: any = client.cache.readQuery({
            query: isAgencyJobChat
              ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY
              : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
            variables: initialVariables,
          });
          if (existingMessageConnection?.chatMessagesByChatId) {
            client.cache.writeQuery({
              query: isAgencyJobChat
                ? CHAT_MESSAGES_BY_CHAT_ID_FOR_AGENCY_QUERY
                : CHAT_MESSAGES_BY_CHAT_ID_QUERY,
              variables: initialVariables,
              data: {
                chatMessagesByChatId: {
                  edges: sortMessageNodes(
                    unionBy(
                      existingMessageConnection.chatMessagesByChatId.edges,
                      newData.chatMessagesByChatId.edges,
                    ),
                  ),
                  totalCount: newData.chatMessagesByChatId.totalCount,
                },
              },
            });
          }
        }
      } catch (e) {
        console.warn(e);
      }
      setLoadingMoreMessages(false);
    }
  };

  /*********************************************************************************************************************
   * FILE UPLOAD
   ********************************************************************************************************************/
  const handleSelectTakePicture = async () => {
    setVisibleCamera(true);
  };

  const handleCloseCamera = async () => {
    setVisibleCamera(false);
  };

  const handleUploadCameraPicture = async (photo: any) => {
    try {
      if (photo) {
        const restEndpointUrl =
          Constants.expoConfig?.extra?.priojetBackendUploadChatDocumentEndpoint;
        setIsSending(true);
        return uploadFileToServer({
          document: { ...photo, documentType: 'IMAGE' },
          restEndpointUrl,
          documentType: 'IMAGE',
          documentCategory: 'chat-documents',
          objectId: chatId,
        })
          .then(async (data: any) => {
            await setTimeout(async () => {
              const res = await fetchChatMessageEdge({ variables: { chatMessageId: data.id } });
              if (res?.data) {
                onUpdate('chatMessageEdge', MutationUpdateType.CREATE)(client.cache, {
                  data: res.data,
                });
              }
            }, 200);
            setIsSending(false);
            return true;
          })
          .catch(() => {
            showMessage({
              message: t('common.error', { defaultValue: 'Error' }),
              description: t('common.somethingWentWrongUploadingImage', {
                defaultValue:
                  'Something went wrong while uploading image. You can try uploading it again.',
              }) as string,
              type: 'danger',
            });
            setIsSending(false);
            return false;
          });
      }
      return false;
    } catch (e) {
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }),
        description: t('common.somethingWentWrongUploadingImage', {
          defaultValue:
            'Something went wrong while uploading image. You can try uploading it again.',
        }) as string,
        type: 'danger',
      });
      return false;
    }
  };

  const handleSelectImageFile = async () => {
    const result: any = await pickImageOnFrontEnd();
    if (result !== null) {
      const restEndpointUrl = Constants.expoConfig?.extra?.priojetBackendUploadChatDocumentEndpoint;
      setIsSending(true);
      await uploadFileToServer({
        document: result,
        restEndpointUrl,
        documentType: 'IMAGE',
        documentCategory: 'chat-documents',
        objectId: chatId,
      }).then(async (data: any) => {
        await setTimeout(async () => {
          const res = await fetchChatMessageEdge({ variables: { chatMessageId: data.id } });
          if (res?.data) {
            onUpdate('chatMessageEdge', MutationUpdateType.CREATE)(client.cache, {
              data: res.data,
            });
          }
        }, 200);
      });
      setIsSending(false);
    }
  };

  const handleSelectPdfFile = async () => {
    const result = await ExpoDocumentPicker.getDocumentAsync({
      type: 'application/pdf',
      multiple: false,
    });
    if (!result.canceled && result?.assets?.[0]) {
      const asset = result.assets[0];
      let base64 = asset.uri;
      if (!asset.uri.startsWith('data:application')) {
        base64 = await FileSystem.readAsStringAsync(base64, {
          encoding: FileSystem.EncodingType.Base64,
        });
      }

      setIsSending(true);
      const restEndpointUrl = Constants.expoConfig?.extra?.priojetBackendUploadChatDocumentEndpoint;
      await uploadFileToServer({
        document: {
          uri: asset.uri,
          base64,
          width: 1,
          height: 1,
          fileName: asset.name,
          exif: {
            lastModified: asset.lastModified,
            size: asset.size,
            name: asset.name,
            output: result.output,
            mimeType: 'application/pdf',
          },
        },
        restEndpointUrl,
        documentType: 'PDF',
        documentCategory: 'chat-documents',
        objectId: chatId,
      }).then(async (data: any) => {
        await setTimeout(async () => {
          const res = await fetchChatMessageEdge({ variables: { chatMessageId: data.id } });
          if (res?.data) {
            onUpdate('chatMessageEdge', MutationUpdateType.CREATE)(client.cache, {
              data: res.data,
            });
          }
        }, 200);
      });
      setIsSending(false);
    }
  };

  /*********************************************************************************************************************
   * OTHER METHODS
   ********************************************************************************************************************/
  const handlePDFPress = (uri: string) => () => {
    if (Platform.OS === 'web' && uri) {
      Linking.openURL(uri);
    } else {
      setPDFUri(uri);
    }
  };

  const handleMessageDelete = async (message: any) => {
    deleteChatMessage({
      variables: { deleteChatMessageInput: { chatMessageId: message.id } },
    });
  };

  const handleMessageSend = debounce(async () => {
    setIsSending(true);
    const now: DateTime = DateTime.now();
    const createMessage: any = {
      node: {
        id: `id:${new Date().toISOString()}`,
        message: messageText,
        messageType: MessageType.TEXT,
        chatFile: null,
        sendingUser: currentUserData?.user
          ? currentUserData.user
          : {
              ...(appUserContext.currentUserContext?.user || {}),
            },
        createdAt: now,
        updatedAt: now,
        __typename: 'ChatMessage',
      },
      cursor: editingMessageEdge ? editingMessageEdge.cursor : `cursor:${new Date().toISOString()}`,
      __typename: 'ChatMessageEdge',
    };
    if (isAgencyJobChat) {
      createMessage.node.agencyJobPlanningItemHistoryLog = null;
    }

    if (chatId && messageText.length) {
      await getLatestMessages();
      let mutation: any = createChatMessage;
      let optimisticKey = 'createChatMessage';
      let chatMessageInput: any = {
        createChatMessageInput: {
          chatId,
          message: messageText,
          messageType: MessageType.TEXT,
        },
      };
      try {
        if (editingMessageEdge) {
          mutation = updateChatMessage;
          optimisticKey = 'updateChatMessage';
          chatMessageInput = {
            updateChatMessageInput: {
              chatMessageId: editingMessageEdge.node.id,
              message: messageText,
            },
          };
        }

        setMessageText('');
        setEditingMessageEdge(null);
        const res = await mutation({
          variables: { ...chatMessageInput },
          optimisticResponse: { [optimisticKey]: createMessage },
        });
        if (!res.data) {
          if (!editingMessageEdge) {
            setFailedMessages((prevState) => [
              ...prevState,
              {
                ...createMessage,
                node: { ...createMessage.node, errorWhileSending: true },
              },
            ]);
          }
          showMessage({
            message: t('common.error', { defaultValue: 'Error' }),
            description: t('common.somethingWentWrongSendingMessage', {
              defaultValue:
                'Something went wrong while sending message. You can try sending your message again.',
            }) as string,
            type: 'danger',
          });
        }
      } catch {
        setFailedMessages((prevState) => [
          ...prevState,
          { ...createMessage, node: { ...createMessage.node, errorWhileSending: true } },
        ]);
        showMessage({
          message: t('common.error', { defaultValue: 'Error' }),
          description: t('common.somethingWentWrongSendingMessage', {
            defaultValue:
              'Something went wrong while sending message. You can try sending your message again.',
          }) as string,
          type: 'danger',
        });
      }
    }
    setIsSending(false);
  }, 150);

  const handleMessageSendAgain = async (message: Partial<ChatMessage>): Promise<boolean> => {
    setFailedMessages((prevState) =>
      prevState.map((item) => {
        if (item.node.id === message.id) {
          return { ...item, resending: true };
        }
        return item;
      }),
    );
    try {
      const res = await createChatMessage({
        variables: {
          createChatMessageInput: {
            chatId,
            message: message.message,
            messageType: message.messageType,
          },
        },
      });
      if (res) {
        setFailedMessages((prevState) => prevState.filter((item) => item.node.id !== message.id));
      } else {
        setFailedMessages((prevState) =>
          prevState.map((item) => {
            if (item.node.id === message.id) {
              return { ...item, resending: false };
            }
            return item;
          }),
        );
        showMessage({
          message: t('common.error', { defaultValue: 'Error' }),
          description: t('common.somethingWentWrongSendingMessage', {
            defaultValue:
              'Something went wrong while sending message. You can try sending your message again.',
          }) as string,
          type: 'danger',
        });
      }
      return true;
    } catch {
      setFailedMessages((prevState) =>
        prevState.map((item) => {
          if (item.node.id === message.id) {
            return { ...item, resending: false };
          }
          return item;
        }),
      );
      showMessage({
        message: t('common.error', { defaultValue: 'Error' }),
        description: t('common.somethingWentWrongSendingMessage', {
          defaultValue:
            'Something went wrong while sending message. You can try sending your message again.',
        }) as string,
        type: 'danger',
      });
    }
    return false;
  };

  const handleEditPress = (message: any) => {
    const messageEdge = messageEdges.find(
      ({ node }: { node: ChatMessage }) => node.id === message.id,
    );
    setEditingMessageEdge(messageEdge);
    setMessageText(message.text);
  };

  const onActionPress = () => {
    const options = [
      t('common.takePhoto', { defaultValue: 'Take photo' }),
      t('common.addImage', { defaultValue: 'Add image' }),
      t('common.addPdfDocument', { defaultValue: 'Add PDF document' }),
      t('common.cancel', { defaultValue: 'Cancel' }),
    ];
    showActionSheetWithOptions({ options, cancelButtonIndex: 3 }, (buttonIndex) => {
      if (Platform.OS === 'web') {
        if (buttonIndex === 0) {
          handleSelectTakePicture();
        } else if (buttonIndex === 1) {
          handleSelectImageFile();
        } else if (buttonIndex === 2) {
          handleSelectPdfFile();
        }
      } else {
        if (buttonIndex === 0) {
          handleSelectTakePicture();
        } else if (buttonIndex === 1) {
          handleSelectImageFile();
        } else if (buttonIndex === 2) {
          handleSelectPdfFile();
        }
      }
    });
  };

  const openMessageOptions = useCallback(
    (message: any) => () => {
      if (message.user.id === currentUser.id) {
        // CHECKING IF MESSAGE REPLIED. IF YES, EDITING IS NOT ALLOWED
        let replied = false;
        const index = messages.findIndex((item: any) => item.id === message.id);

        if (index > 0 && index < messages.length - 1) {
          const newMessages = [...messages].slice(index - 1);
          if (newMessages.some((item) => item.user.id !== currentUser.id)) {
            replied = true;
          }
        }

        if (!replied) {
          let options = [
            t('common.edit', { defaultValue: 'Edit' }),
            t('common.delete', { defaultValue: 'Delete' }),
            t('common.cancel', { defaultValue: 'Cancel' }),
          ];
          let type = 'text';
          if (message.messageType === MessageType.TEXT) {
            type = 'text';
          }
          if (message.chatFile?.sasUrl) {
            type = 'document';
            options = [
              t('common.delete', { defaultValue: 'Delete' }),
              t('common.cancel', { defaultValue: 'Cancel' }),
            ];
          }

          showActionSheetWithOptions({ options, cancelButtonIndex: 2 }, (buttonIndex) => {
            if (type === 'text') {
              if (buttonIndex === 0 && handleEditPress) {
                handleEditPress(message);
              } else if (buttonIndex === 1) {
                handleMessageDelete(message);
                setEditingMessageEdge(null);
              } else {
                setEditingMessageEdge(null);
              }
            } else {
              if (buttonIndex === 0) {
                handleMessageDelete(message);
                setEditingMessageEdge(null);
              } else {
                setEditingMessageEdge(null);
              }
            }
          });
        } else {
          showMessage({
            message: t('common.messageAlreadyRepliedCannotBeEdited', {
              defaultValue: 'Message already has reply. Past messages cannot be edited or deleted.',
            }),
            description: t('common.messageAlreadyRepliedCannotBeEdited', {
              defaultValue: 'Message already has reply. Past messages cannot be edited or deleted.',
            }) as string,
            type: 'warning',
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, messages],
  );

  const handleMessageTextChange = (value: string) => {
    setMessageText((prevState: string) => {
      if ((prevState && !value) || (!prevState && value)) {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      }
      return value;
    });
  };

  const disableInputMessage = useMemo(() => {
    if (disableInputMessageProp) {
      return disableInputMessageProp;
    }
    if (!isBackendReachable) {
      return t('common.chatDisabledBackendNotReachable', {
        defaultValue: 'Sending of messages disabled without network connection...',
      });
    }
    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableInputMessageProp, isBackendReachable]);

  useImperativeHandle(chatRef, () => ({ getLatestMessages }));

  return {
    canLoadMore,
    currentUser,
    disableInput: !!disableInputMessage,
    disableInputMessage,
    handleCloseCamera,
    handleMessageDelete,
    handleMessageSend,
    handleMessageSendAgain,
    handlePDFPress,
    handleMessageTextChange,
    handleUploadCameraPicture,

    isSending,
    loadingMoreMessages,
    loadingLatestMessages,
    messageContainerRef,
    messages,
    messageText,
    onActionPress,
    onLoadMore,
    openMessageOptions,
    pdfUri,
    user,
    visibleCamera,
  };
};
