import React, { ReactElement } from 'react';
import { View } from 'react-native';

import {
  Button,
  CalendarViewModes,
  Icon,
  RangeCalendar,
  Spinner,
  StyleType,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import * as Localization from 'expo-localization';
import { DateTime, Duration, IANAZone } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import { Place } from 'react-native-google-places-autocomplete';

import { useHook } from './hook';
import { themedStyles } from './styles';
import { globalStyle } from '../../../common/style';
import { FormTimeSelect } from '../../../components/common/form/FormTimeSelect/form-time-select.component';
import { ThemedGooglePlacesAutocomplete } from '../../../components/common/google-places-autocomplete.component';
import { TransparentModal } from '../../../components/common/modal/transparent-modal.component';
import { MapViewComponent } from '../../../components/map-view.component';
import { getLocaleDateService } from '../../../i18n/calendar-translations';
import { getTimeZoneDifferenceInMinutesForTimestamp } from '../../../utils/date-time.util';

export const CourierCreateAvailabilityScreen = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const {
    currentLocationToAvailabilityLocationTimeZoneDifference,
    endTime,
    endTimePlaceholder,
    errorMessages,
    googlePlacesAutocompleteLocation,
    googlePlacesAutocompleteOnChangeText,
    googlePlacesAutocompleteOnPress,
    handleCreateAvailabilityButtonPressOn,
    handleChangeEnd,
    handleChangeStart,
    handleClose,
    handleSelectRange,
    isCreateAvailabilityButtonDisabled,
    loadingCreateAvailability,
    locationIANAZone,
    range,
    rangeCalendarMinDate,
    rangeCalendarMaxDate,
    selectedEnd,
    selectedStart,
    startTime,
    startTimePlaceholder,
    timezoneAheadOrBehind,
    timeZoneEqualToLaterThanOrEarlierThan,
    visible,
  } = useHook();

  const renderDayCell = ({ date }: { date: Date }, style: StyleType) => (
    <View
      style={[
        style.dayContainer,
        style.container,
        globalStyle.alignItemsCenter,
        globalStyle.justifyContentCenter,
        themedStyles.height45,
      ]}
    >
      <Text
        style={[
          style.text,
          globalStyle.fontSize18,
          globalStyle.textAlignCenter,
          globalStyle.justifyContentCenter,
        ]}
      >{`${date.getDate()}`}</Text>
    </View>
  );

  const renderStepIcon = (showChecked: boolean) => {
    return showChecked ? (
      <Icon name="checkmark-circle-outline" fill="#32a85e" style={themedStyles.icon} />
    ) : (
      <Icon name="question-mark-circle-outline" fill="orange" style={themedStyles.icon} />
    );
  };

  const renderRangeCalendarFooter = (): ReactElement => {
    if (!range || !range.startDate) {
      return (
        <View style={[styles.padding15, styles.alignCenter]}>
          <Text selectable={true}>
            {
              t('courierAvailability.selectStartAndEndDateAbove', {
                defaultValue: 'Select the start and end date above.',
              }) as string
            }
          </Text>
        </View>
      );
    }
    if (startTime && startTime.isValid && (!endTime || !endTime.isValid)) {
      return (
        <View style={[styles.padding15, styles.alignCenter]}>
          <Text selectable={true}>
            <Trans
              i18nKey="courierAvailability.availableFromStartDayOnly"
              t={t}
              components={{
                strong: <Text selectable={true} style={styles.fontBold} />,
              }}
              defaults={
                'Start day: <strong>{{startDateString}}</strong>. \nChoose an end date above.'
              }
              values={{
                startDateString: startTime.toLocaleString({
                  ...DateTime.DATETIME_FULL,
                  weekday: 'long',
                  month: 'long',
                }),
              }}
            />
          </Text>
        </View>
      );
    } else if (startTime && startTime.isValid && endTime && endTime.isValid) {
      return (
        <View style={[styles.padding15, styles.alignCenter]}>
          <Text selectable={true}>
            <Trans
              i18nKey="courierAvailability.availableFromToDate"
              t={t}
              components={{
                strong: <Text selectable={true} style={styles.fontBold} />,
              }}
              defaults="From <strong>{{startDateString}}</strong> to <strong>{{endDateString}}</strong>"
              values={{
                startDateString: startTime.toLocaleString({
                  ...DateTime.DATETIME_FULL,
                  weekday: 'long',
                  month: 'long',
                }),
                endDateString: endTime.toLocaleString({
                  ...DateTime.DATETIME_FULL,
                  weekday: 'long',
                  month: 'long',
                }),
              }}
            />
          </Text>
        </View>
      );
    }
    return <View style={[styles.padding15, styles.alignCenter]} />;
  };

  const predefinedPlaces: Place[] = [];
  if (!visible) {
    return <></>;
  }
  return (
    <TransparentModal
      title={t('courierAvailability.createAvailability', {
        defaultValue: 'Create Availability',
      })}
      onClose={handleClose}
    >
      <View style={styles.locationHeader}>
        {renderStepIcon(!!googlePlacesAutocompleteLocation)}
        <Text selectable={true} style={styles.font16Bold}>
          {
            t('courierAvailability.1ChooseAvailabilityLocation', {
              defaultValue:
                '1. In which location are you available? Search the name of the location below and select it accordingly.',
            }) as string
          }
        </Text>
      </View>

      <View>
        <ThemedGooglePlacesAutocomplete
          onChangeText={googlePlacesAutocompleteOnChangeText}
          onPress={googlePlacesAutocompleteOnPress}
          language="en"
          predefinedPlaces={predefinedPlaces}
          placeholderText={
            t('courierAvailability.locationSearchPlaceholderText', {
              defaultValue: 'Enter the location where you are available',
            }) as string
          }
          currentLocationText={
            t('courierAvailability.currentLocation', {
              defaultValue: 'Current location',
            }) as string
          }
        />
      </View>
      {!!googlePlacesAutocompleteLocation && (
        <View style={styles.googleMap}>
          <MapViewComponent
            latitude={googlePlacesAutocompleteLocation?.geometry.location.lat as number}
            longitude={googlePlacesAutocompleteLocation?.geometry.location.lng as number}
            height={150}
            showMarker
            markerTitle={googlePlacesAutocompleteLocation.formattedAddress}
            latitudeDelta={4}
            longitudeDelta={4}
          />
        </View>
      )}
      {!!locationIANAZone && (
        <>
          <View style={styles.datesHeader}>
            {renderStepIcon(
              range !== undefined &&
                range.startDate !== undefined &&
                range.startDate !== null &&
                range.endDate !== null &&
                range.endDate !== undefined,
            )}

            <Text selectable={true} style={styles.font16Bold}>
              {
                t('courierAvailability.2SelectDateRange', {
                  defaultValue:
                    '2. When are you available? Choose the days on which you are available in the calendar below.',
                }) as string
              }
            </Text>
          </View>

          <RangeCalendar
            style={styles.width100}
            startView={CalendarViewModes.DATE}
            min={rangeCalendarMinDate}
            max={rangeCalendarMaxDate}
            boundingMonth
            renderDay={renderDayCell}
            range={range}
            dateService={getLocaleDateService(i18n.language || Localization.locale)}
            onSelect={handleSelectRange}
            renderFooter={renderRangeCalendarFooter}
          />
        </>
      )}

      {!!range.startDate && !!range.endDate && !!locationIANAZone && (
        <View>
          <View style={styles.timeHeader}>
            <Icon name="checkmark-circle-outline" fill="#32a85e" style={themedStyles.icon} />
            <View style={styles.flex1}>
              <Text selectable={true} style={styles.font16Bold}>
                {
                  t('courierAvailability.3ChooseAvailabilityTime', {
                    defaultValue:
                      '3. In which time frame are you available?\n Select the starting and ending time for your Availability.',
                  }) as string
                }
              </Text>
            </View>
          </View>

          <View style={globalStyle.marginBottom10}>
            {!!googlePlacesAutocompleteLocation?.formattedAddress &&
              !!locationIANAZone &&
              !!startTime &&
              getTimeZoneDifferenceInMinutesForTimestamp(
                IANAZone.create(Localization.timezone),
                locationIANAZone,
                startTime,
              ) !== 0 && (
                <View style={styles.alertContainer}>
                  <Icon name="alert-triangle-outline" style={styles.alertIcon} fill="#ee2200" />
                  <View style={styles.flex1}>
                    <Text selectable={true} style={styles.font15Black}>
                      <Trans
                        i18nKey="courierAvailability.locationLocalTimeInfo"
                        t={t}
                        defaults={
                          'Please be aware that you have to specify the local time at your selected location <strong>{{locationName}}</strong>. The time zone difference to your local time zone <strong>{{currentTimeZoneName}}</strong> is exactly <strong>{{timeZoneOffsetInHours}}</strong> hours ({{locationTimeZoneName}}). Therefore it {{timeZoneEqualToLaterThanOrEarlierThan}} your current local time. \n\nExample: {{localTimeExample}} here is {{locationTimeExampleDateTime}} there. So you would have to also set {{locationTimeExampleTime}} below.'
                        }
                        components={{
                          strong: (
                            <Text selectable={true} style={[styles.font15Black, styles.fontBold]} />
                          ),
                        }}
                        values={{
                          locationName: googlePlacesAutocompleteLocation?.formattedAddress,
                          timeZoneOffsetInHours: Duration.fromObject({
                            minutes: currentLocationToAvailabilityLocationTimeZoneDifference,
                          }).toFormat('hh:mm'),
                          timeZoneAheadOrBehind: timezoneAheadOrBehind,
                          timeZoneEqualToLaterThanOrEarlierThan,
                          currentTimeZoneName: Localization.timezone,
                          locationTimeZoneName: locationIANAZone.name,
                          localTimeExample: Localization.timezone
                            ? DateTime.fromObject({ hour: 20, minute: 30 })
                                .setZone(Localization.timezone)
                                .setLocale(i18n.language)
                                .toLocaleString(DateTime.DATETIME_SHORT)
                            : '...',
                          locationTimeExampleDateTime: locationIANAZone
                            ? DateTime.fromObject({ hour: 20, minute: 30 })
                                .setZone(locationIANAZone)
                                .setLocale(i18n.language)
                                .toLocaleString(DateTime.DATETIME_SHORT)
                            : '...',
                          locationTimeExampleTime: locationIANAZone
                            ? DateTime.fromObject({ hour: 20, minute: 30 })
                                .setZone(locationIANAZone)
                                .setLocale(i18n.language)
                                .toLocaleString(DateTime.TIME_SIMPLE)
                            : '...',
                        }}
                      />
                    </Text>
                  </View>
                </View>
              )}
          </View>

          <View style={[globalStyle.marginTop20]}>
            <FormTimeSelect
              containerStyle={styles.select}
              label={startTimePlaceholder}
              labelUpperCase={false}
              labelStyle={styles.timeLabelColor}
              inputContainerStyle={styles.inputContainerStyle}
              onChange={handleChangeStart}
              value={selectedStart}
            />
          </View>

          {!!selectedStart && (
            <FormTimeSelect
              containerStyle={styles.select}
              label={endTimePlaceholder}
              labelUpperCase={false}
              labelStyle={styles.timeLabelColor}
              inputContainerStyle={styles.inputContainerStyle}
              onChange={handleChangeEnd}
              value={selectedEnd}
            />
          )}
        </View>
      )}

      {!!errorMessages?.length && (
        <View style={styles.errorContainer}>
          <Text>
            {
              t('courierAvailability.couldNotSaveAvailabilityErrorMessage', {
                defaultValue: 'Please fix the errors below:',
              }) as string
            }
          </Text>
          {errorMessages.map((e) => (
            <View style={[styles.flexRow, globalStyle.paddingTop10]} key={e}>
              <Text>{'\u2022'}</Text>
              <Text style={globalStyle.paddingLeft5}>{e}</Text>
            </View>
          ))}
        </View>
      )}

      <Button
        appearance="outline"
        style={styles.saveButton}
        accessoryLeft={() => {
          if (loadingCreateAvailability) {
            return <Spinner />;
          }
          return <></>;
        }}
        onPress={handleCreateAvailabilityButtonPressOn}
        disabled={isCreateAvailabilityButtonDisabled}
      >
        {loadingCreateAvailability
          ? t('courierAvailability.saveAndScheduleAvailability', {
              defaultValue: 'Save and schedule Availability',
            }) + '...'
          : (t('courierAvailability.saveAndScheduleAvailability', {
              defaultValue: 'Save and schedule Availability',
            }) as string)}
      </Button>
    </TransparentModal>
  );
};
