import React, { useEffect, useState } from 'react';
import { Platform, RefreshControl, ScrollView, View } from 'react-native';

import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as Contacts from 'expo-contacts';
import { PermissionStatus } from 'expo-modules-core';
import { useTranslation } from 'react-i18next';

import { PhoneBookList } from './PhoneBookList';
import { Props } from './types';
import { openAppSettings } from '../../../modules/device/device.module';
import { AppUserContact } from '../../../types';

export const PhoneBook: React.FC<Props> = ({
  importedContacts,
  isLoading,
  renderInviteButton,
  refetch,
}) => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);

  const [isContactsApiAvailable, setIsContactsApiAvailable] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [contactPermissionResponse, setContactPermissionResponse] =
    useState<Contacts.PermissionResponse>();
  const [contacts, setContacts] = useState<AppUserContact[]>([]);

  const requestContactPermissions = () => {
    Contacts.requestPermissionsAsync().then((permissionResponse) => {
      setContactPermissionResponse(permissionResponse);
    });
  };

  useEffect(() => {
    Contacts.isAvailableAsync().then((isApiAvailable: boolean) => {
      setIsContactsApiAvailable(isApiAvailable);
      if (isApiAvailable) {
        Contacts.getPermissionsAsync().then((permissionResponse: Contacts.PermissionResponse) => {
          setContactPermissionResponse(permissionResponse);
          loadContactsAsync();
        });
      }
    });
  }, []);

  const loadContactsAsync = async () => {
    const contactResponse = await Contacts.getContactsAsync({
      sort: Contacts.SortTypes.FirstName,
      rawContacts: true,
    });
    setContacts(contactResponse?.data);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await loadContactsAsync();
    await refetch();
    setIsRefreshing(false);
  };

  const renderHasNotPermissions = () => {
    if (contactPermissionResponse?.status === PermissionStatus.DENIED) {
      return (
        <>
          <Text selectable={true} style={styles.permissionText}>
            {
              t('common:allowAccessToContactsMessage', {
                defaultValue:
                  'It seems like you do not allow accessing your Contacts in the Settings. To load your Contacts here, please click on Open Settings and allow access to your Contacts. Don\'t worry, only those contacts that you actively invite by pressing the "Invite" button after loading the Contacts are submitted to our system.',
              }) as string
            }
          </Text>
          <Button
            onPress={() => {
              if (Platform.OS !== 'web') {
                openAppSettings();
              }
            }}
          >
            {
              t('common:openSettingsButton', {
                defaultValue: 'Open Settings',
              }) as string
            }
          </Button>
        </>
      );
    }
    return (
      <>
        <Text style={styles.permissionText}>
          {
            t('common:displayLoadContactsMessage', {
              defaultValue:
                'To display your contacts here, please press Load Contacts and allow  access to your contacts. Don\'t worry, only those contacts that you actively invite by pressing the "Invite" button after loading the Contacts are submitted to our system.',
            }) as string
          }
        </Text>
        <Button onPress={requestContactPermissions}>
          {t('common:loadContacts', { defaultValue: 'Load Contacts' }) as string}
        </Button>
      </>
    );
  };

  const hasPermissions = contactPermissionResponse?.granted === true;
  if (
    isContactsApiAvailable &&
    (!contactPermissionResponse || contactPermissionResponse?.granted === false)
  ) {
    return (
      <ScrollView
        style={styles.scrollView}
        refreshControl={
          <RefreshControl
            refreshing={isLoading}
            onRefresh={handleRefresh}
            title="Loading Contacts..."
          />
        }
      >
        <Text selectable={true}>
          {
            t('common:deviceHasContactsMessageTop', {
              defaultValue: 'This device has a phone book with contacts you could invite.',
            }) as string
          }
        </Text>
        {!hasPermissions && renderHasNotPermissions()}
        {hasPermissions && (
          <Text>
            {
              t('common:errorOccurredMessage', {
                defaultValue: 'An error occurred. Please contact the PRIOjet support.',
              }) as string
            }
          </Text>
        )}
      </ScrollView>
    );
  }
  if (isContactsApiAvailable && hasPermissions) {
    return (
      <PhoneBookList
        data={contacts}
        importedContacts={importedContacts}
        refetch={refetch as any}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={handleRefresh}
            title={t('loadingContacts', { defaultValue: 'Loading Contacts...' }) as string}
          />
        }
        renderInviteButton={renderInviteButton}
      />
    );
  }
  return (
    <View>
      <Text style={styles.noAccess}>
        {
          t('common:cannotAccessContactsMessage', {
            defaultValue: 'We cannot access contacts on this device. Please use a mobile phone.',
          }) as string
        }
      </Text>
    </View>
  );
};

const themedStyles = StyleService.create({
  scrollView: {
    padding: 10,
  },
  permissionText: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
  },
  noAccess: {
    padding: 20,
    textAlign: 'center',
  },
});
