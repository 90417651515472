import { StyleService } from '@ui-kitten/components';

export const themedStyles = StyleService.create({
  container: {
    marginTop: 10,
  },
  captionContainer: {},
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  errorMessageText: {
    color: 'color-danger-500',
    fontSize: 13,
    paddingVertical: 5,
  },
  labelContainer: {},
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  checkBox: {
    marginLeft: 10,
  },
  button: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  placeholder: {
    color: 'text-basic-color',
  },
});
