import React from 'react';
import { View } from 'react-native';

import { useNavigation } from '@react-navigation/core';
import { StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import { globalStyle } from '../../common/style';
import { CommonTermsAndConditionsSignupComponent } from '../../components/common/CommonTermsAndConditionsSignupComponent';
import { TransparentModal } from '../../components/common/modal/transparent-modal.component';
import { OrganizationType } from '../../generated-graphql-types';
import { RootStackParamList } from '../../navigation/app.navigator';

export const CourierTermsAndConditionsSignupScreen = (): React.ReactElement => {
  const { t } = useTranslation();
  const styles = useStyleSheet(themedStyles);
  const navigation: any = useNavigation();

  const handleModalClose = (params?: { reloadContext?: boolean }) => {
    navigation.navigate(
      'CommonSwitchAccountScreen',
      params?.reloadContext ? { reloadContext: params.reloadContext } : undefined,
    );
  };

  return (
    <TransparentModal
      title={t('common.signUpAsCourier', { defaultValue: 'Sign up as Courier' })}
      navigateToScreenName={'CommonSwitchAccountScreen' as keyof RootStackParamList}
      fullHeight={false}
    >
      <View style={[globalStyle.marginTop20, globalStyle.marginBottom15]}>
        <View>
          <Text style={styles.textWarning}>
            {
              t('signUp.introCourierWarning', {
                defaultValue:
                  "IMPORTANT: Invitation code is required, if you don't have it yet, get it from your Agency.",
              }) as string
            }
          </Text>

          <Text style={[styles.text, styles.marginTop10]}>
            {
              t('signUp.introCourierText1', {
                defaultValue:
                  'OBC Couriers use PRIOjet to connect to their agencies to find new jobs and manage the corresponding information and communication so that their job fulfillment runs smoothly.',
              }) as string
            }
          </Text>
          <Text style={[styles.text, styles.marginTop30]}>
            {
              t('signUp.introCourierText2', {
                defaultValue:
                  'Talk to PRIOjet to your Agencies or PRIOjet to learn more about our platform  and how we can help you to better manage your OBC jobs to save time and money. The future for OBC transportation is digital, mobile, multi-platform and communicates in real-time.',
              }) as string
            }
          </Text>
          <Text category="h6" status="primary" style={[styles.text, styles.marginTop30]}>
            {
              t('signUp.introCourierText3', {
                defaultValue: 'YOU ARE AN ON-BOARD COURIER (OBC)?',
              }) as string
            }
          </Text>
          <Text style={[styles.text, styles.marginTop5]}>
            {
              t('signUp.introCourierText4', {
                defaultValue:
                  'Sign up for free and connect to your agencies by using your invitation code.',
              }) as string
            }
          </Text>
          <CommonTermsAndConditionsSignupComponent
            organizationType={OrganizationType.COURIER}
            handleModalClose={handleModalClose}
          />
        </View>
      </View>
    </TransparentModal>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
    marginBottom: 15,
  },
  text: {
    fontSize: 18,
  },
  textWarning: {
    fontSize: 18,
    color: 'color-danger-500',
  },
  marginTop5: {
    marginTop: 5,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop15: {
    marginTop: 15,
  },
  marginTop30: {
    marginTop: 30,
  },
});
