import React from 'react';
import { ColorSchemeName, View } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { Text, Toggle } from '@ui-kitten/components';
import { useTranslation } from 'react-i18next';

import ContentBox from './content-box.component';
import ThemeContext from '../contexts/ThemeContext';
import { GLOBAL_CONSTANTS } from '../globals';
import { updateDevice } from '../modules/device/device.module';

const ThemeToggleBox = () => {
  const { t } = useTranslation();
  const themeContext = React.useContext(ThemeContext);
  const [themeName, setThemeName] = React.useState<NonNullable<ColorSchemeName>>();
  const [translatedThemeName, setTranslatedThemeName] = React.useState<string>();
  const [checked, setChecked] = React.useState<boolean>(false);

  React.useEffect(() => {
    AsyncStorage.getItem(GLOBAL_CONSTANTS.ACTIVE_THEME_NAME_STORAGE_KEY, (error, result) => {
      if (result && ['light', 'dark'].includes(result)) {
        setThemeName(result as NonNullable<ColorSchemeName>);
      } else if (themeContext.theme) {
        setThemeName(themeContext.theme as unknown as NonNullable<ColorSchemeName>);
      }
    });
  }, []);

  React.useEffect(() => {
    if (themeContext.theme) {
      setTranslatedThemeName(t(themeContext.theme) as string);
      setChecked(themeContext.theme === 'dark');
    }
  }, [t, themeContext]);

  React.useEffect(() => {
    const func = async () => {
      const _themeName = checked ? 'dark' : 'light';
      await AsyncStorage.setItem(GLOBAL_CONSTANTS.ACTIVE_THEME_NAME_STORAGE_KEY, _themeName);
      await updateDevice();
      setThemeName(_themeName);
    };
    func();
  }, [checked]);

  const onCheckedChange = async (isChecked: boolean) => {
    setChecked(isChecked);
    themeContext.toggleTheme();
  };

  return (
    <ContentBox
      Title={
        <View>
          <Text
            selectable={true}
            style={{
              fontFamily: 'Lato_700Bold',
              letterSpacing: 0.1,
              textTransform: 'uppercase',
            }}
          >
            {t('common.toggleTheme', { defaultValue: 'Toggle theme' }) as string}
          </Text>
        </View>
      }
      Content={
        <View>
          <Toggle checked={themeName === 'dark'} onChange={onCheckedChange}>
            {`${t('common.activeTheme', {
              defaultValue: 'Active theme',
            })}: ${translatedThemeName}`}
          </Toggle>
        </View>
      }
    />
  );
};

export { ThemeToggleBox };
