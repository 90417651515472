import { useState } from 'react';

const INITIAL_DATA = {
  cancelButtonAppearance: 'outline',
  cancelButtonStatus: 'primary',
  cancelButtonText: '',
  confirmButtonAppearance: 'filled',
  confirmButtonStatus: 'primary',
  confirmButtonText: '',
  inputLabel: false,
  showInput: false,
  onCancelPress: () => {},
  onConfirmPress: () => {},
  renderCustomButtons: null,
  errorText: '',
  text: '',
  title: '',
  visible: false,
};

export const useHook = () => {
  const [data, setData] = useState<any>(INITIAL_DATA);
  const [loading, setLoading] = useState(false);
  const [textValue, setTextValue] = useState('');

  const { onCancelPress, onConfirmPress } = data;

  const handleCancel = () => {
    setData(INITIAL_DATA);
    setTextValue('');
    setLoading(false);
    if (onCancelPress) {
      onCancelPress();
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (onConfirmPress) {
      try {
        const res = await onConfirmPress({ text: textValue });
        setLoading(false);
        if (res || res === undefined) {
          setData(INITIAL_DATA);
          setTextValue('');
        }
        // eslint-disable-next-line no-empty
      } catch {}
    } else {
      setData(INITIAL_DATA);
    }

    setLoading(false);
  };

  const hideConfirmModal = () => {
    setLoading(false);
    setData({ ...INITIAL_DATA, visible: false });
  };

  const showConfirmModal = (newData: any) => {
    setData({ ...INITIAL_DATA, ...newData, visible: true });
  };

  const updateConfirmModal = (newData: any) => {
    setData((prevState: any) => {
      if (prevState.visible) {
        return { ...prevState, ...newData };
      }
      return prevState;
    });
  };

  const handleTextChange = (t: string) => {
    setTextValue(t);
  };

  return {
    data,
    handleCancel,
    handleConfirm,
    handleTextChange,
    hideConfirmModal,
    loading,
    showConfirmModal,
    textValue,
    updateConfirmModal,
  };
};
