import { useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';

import { useQuery } from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { DateTime } from 'luxon';

import { QUERY_MIN_SUPPORTED_APP_VERSION } from '../../../../apollo/graphql-queries';
import { GLOBAL_CONSTANTS } from '../../../../globals';

const CURRENT_VERSION = Constants.expoConfig?.version;

const checkVersionGreaterThanOther = (version1: string, version2: string): boolean => {
  if (version1 === version2) {
    return false;
  }
  const version1Components = version1.split('.');
  const version2Components = version2.split('.');

  if (version1Components.length === 2) {
    version1Components.push('0');
  }
  if (version2Components.length === 2) {
    version2Components.push('0');
  }
  if (parseInt(version1Components[0], 10) > parseInt(version2Components[0], 10)) {
    return true;
  }
  if (
    parseInt(version1Components[0], 10) === parseInt(version2Components[0], 10) &&
    parseInt(version1Components[1], 10) > parseInt(version2Components[1], 10)
  ) {
    return true;
  }
  if (
    parseInt(version1Components[0], 10) === parseInt(version2Components[0], 10) &&
    parseInt(version1Components[1], 10) === parseInt(version2Components[1], 10) &&
    parseInt(version1Components[2] || '0', 10) > parseInt(version2Components[2] || '0', 10)
  ) {
    return true;
  }
  return false;
};

export const useHook = () => {
  const { data } = useQuery(QUERY_MIN_SUPPORTED_APP_VERSION, {
    fetchPolicy: 'network-only',
  });

  const [needsUpdate, setNeedsUpdate] = useState<'mandatory' | 'nonMandatory' | ''>('');

  const checkUpdate = async ({
    currentAppVersion,
    minSupportedAppVersion,
  }: {
    currentAppVersion: string;
    minSupportedAppVersion: string;
  }): Promise<void> => {
    if (CURRENT_VERSION && checkVersionGreaterThanOther(minSupportedAppVersion, CURRENT_VERSION)) {
      setNeedsUpdate('mandatory');
    } else if (
      CURRENT_VERSION &&
      checkVersionGreaterThanOther(currentAppVersion, CURRENT_VERSION)
    ) {
      let storage = await AsyncStorage.getItem(GLOBAL_CONSTANTS.MIN_SUPPORTED_VERSION);
      let storageMinSupportedAppVersion: { [key: string]: string } = {};
      if (storage) {
        storageMinSupportedAppVersion = JSON.parse(storage);
      }
      if (!storageMinSupportedAppVersion[currentAppVersion]) {
        setNeedsUpdate('nonMandatory');
      } else {
        const date1 = DateTime.now();
        const date2 = DateTime.fromJSDate(
          new Date(storageMinSupportedAppVersion[currentAppVersion]),
        );
        const diff = date1.diff(date2, ['days']).toObject();
        if (diff.days && diff.days < 100 && diff.days >= 6) {
          setNeedsUpdate('nonMandatory');
        }
      }
    }
  };

  useEffect(() => {
    if (data?.minSupportedAppVersion?.minSupportedAppVersion) {
      checkUpdate({
        currentAppVersion: data.minSupportedAppVersion.currentAppVersion,
        minSupportedAppVersion: data.minSupportedAppVersion.minSupportedAppVersion,
      });
    }
  }, [data]);

  const handleSkipUpdate = async (): Promise<void> => {
    let storage = await AsyncStorage.getItem(GLOBAL_CONSTANTS.MIN_SUPPORTED_VERSION);
    let storageMinSupportedAppVersion: { [key: string]: string } = {};
    if (storage) {
      storageMinSupportedAppVersion = JSON.parse(storage);
    }
    if (data?.minSupportedAppVersion.currentAppVersion) {
      if (storageMinSupportedAppVersion[data.minSupportedAppVersion.currentAppVersion]) {
        storageMinSupportedAppVersion[data.minSupportedAppVersion.currentAppVersion] =
          DateTime.now().minus({ days: 100 }).toString();
      } else {
        storageMinSupportedAppVersion[data.minSupportedAppVersion.currentAppVersion] =
          DateTime.now().toString();
      }
      AsyncStorage.setItem(
        GLOBAL_CONSTANTS.MIN_SUPPORTED_VERSION,
        JSON.stringify(storageMinSupportedAppVersion),
      );
    }
    setNeedsUpdate('');
  };

  const handleUpdate = () => {
    const url =
      Platform.OS === 'android'
        ? 'https://play.google.com/store/apps/details?id=com.priojet.app'
        : 'https://apps.apple.com/us/app/priojet/id1540437650';
    Linking.openURL(url);
  };

  return {
    handleSkipUpdate,
    handleUpdate,
    needsUpdate,
    minSupportedAppVersion: data?.minSupportedAppVersion?.minSupportedAppVersion,
    currentAppVersion: data?.minSupportedAppVersion?.currentAppVersion,
    installedAppVersion: CURRENT_VERSION,
  };
};
