import React, { useContext, useMemo, useState } from 'react';
import { ActivityIndicator, Platform, View } from 'react-native';

import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  CheckBox,
  Divider,
  Radio,
  RadioGroup,
  StyleService,
  Text,
  useStyleSheet,
} from '@ui-kitten/components';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';

import { CREATE_USER_IDENTITY_DOCUMENT_FRAGMENT } from '../../apollo/graphql-fragments';
import { CREATE_USER_IDENTITY_DOCUMENT_MUTATION } from '../../apollo/graphql-mutations';
import { COURIER_USER_PROFILE_BY_USER_ID_QUERY, USER_QUERY } from '../../apollo/graphql-queries';
import { CountryListData } from '../../assets/constants/country.list.data';
import { globalStyle } from '../../common/style';
import AppUserContext from '../../contexts/AppUserContext';
import { User } from '../../generated-graphql-types';
import { User_user } from '../../graphql-types';
import { useIsBackendReachable } from '../../hooks/useIsBackendReachable';
import {
  DocumentFeatureList,
  LicenseTypeSubList,
  PassportTypeList,
  sexList,
  UserDocument,
} from '../../types';
import { uploadDocumentsToServer } from '../../utils/file-upload.util';
import { CourierAddDocumentFile } from '../common/courier.add-document-image-pdf';
import { FormAutocomplete } from '../common/form/form-autocomplete.component';
import { FormTextDatepicker } from '../common/form/form-text-datepicker.component';
import { FormTextInputTopLabel } from '../common/form/form-text-input-top-label.component';
import { FormSelect } from '../common/form/FormSelect';
import ContentBox from '../content-box.component';
import { TopNavigationWithBackButton } from '../top-navigation-back-button.component';

const now = new Date();
const minimumBirthDay = new Date(1900, 1, 1);
const maximumBirthDay = new Date(2100, 1, 1);

export const CourierAddUserIdentityDocumentComponent = ({
  navigation,
  title,
}: {
  navigation: any;
  title: string;
  user?: User_user;
}): React.ReactElement => {
  useIsBackendReachable();
  const { t } = useTranslation();
  const isBackendReachable = useIsBackendReachable();
  const appUserContext = useContext(AppUserContext);
  const styles = useStyleSheet(themedStyles);

  const SexList = useMemo(() => {
    return sexList.map((sex) => ({
      ...sex,
      name: t(`common.${sex.name}`, { defaultValue: sex.name }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useQuery<{ user: User }>(USER_QUERY, {
    onCompleted: (data) => {
      if (!formik.values.firstNames && !formik.values.lastName) {
        const { user } = data;
        let countryOfBirth = formik.values.countryOfBirth;
        if (user.countryOfBirth) {
          const country = CountryListData.find((item) => item.code === user.countryOfBirth);
          if (country) {
            countryOfBirth = country.code;
          }
        }
        let sex = formik.values.sex;
        if (user && user.hasOwnProperty('sex') && typeof user.sex !== 'undefined') {
          sex = user.sex;
        }
        formik.setValues({
          ...formik.values,
          firstNames: user.firstNames,
          lastName: user.lastName,
          dateOfBirth: user.dateOfBirth,
          countryOfBirth,
          sex,
          placeOfBirth: user.placeOfBirth || '',
        });
      }
    },
    variables: {
      id: appUserContext.currentUserContext?.user?.id,
    },
    fetchPolicy: isBackendReachable ? 'cache-and-network' : 'cache-first',
  });

  const [uploadUserIdentityDocument] = useMutation<
    { createUserIdentityDocument: UserDocument },
    { userIdentityDocumentInput: UserDocument }
  >(CREATE_USER_IDENTITY_DOCUMENT_MUTATION, {
    refetchQueries: [COURIER_USER_PROFILE_BY_USER_ID_QUERY],
    onCompleted(data) {
      if (data.createUserIdentityDocument) {
        showMessage({
          message: t('document.success', { defaultValue: 'Success' }),
          description: t('document.documentCreatedSuccessfully', {
            defaultValue: 'Document created successfully.',
          }) as string,
          type: 'success',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
        formik.setValues({
          ...initialValues,
          firstNames: formik.values.firstNames,
          lastName: formik.values.lastName,
          dateOfBirth: formik.values.dateOfBirth,
          countryOfBirth: formik.values.countryOfBirth,
          sex: formik.values.sex,
          placeOfBirth: formik.values.placeOfBirth,
        });
        setDocumentTypeIndex(undefined);
        navigation.navigate('CourierProfileNavigator', {
          screen: 'CourierUserIdentityDocumentsScreen',
          userId: appUserContext.currentUserContext?.user?.id,
          readOnly: false,
        });
      }
    },
    update(cache, { data }) {
      if (data?.createUserIdentityDocument) {
        cache.modify({
          fields: {
            userIdentityDocuments(existingUserIdentityDocuments = []) {
              const newUserIdentityDocument = cache.writeFragment({
                data: data.createUserIdentityDocument,
                fragment: CREATE_USER_IDENTITY_DOCUMENT_FRAGMENT,
              });
              return [...existingUserIdentityDocuments, newUserIdentityDocument];
            },
          },
        });
      }
    },
  });

  const [documentTypeIndex, setDocumentTypeIndex] = useState<number | undefined>();
  const [hasOnlyFrontPage, setHasOnlyFrontPage] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length) {
      showMessage({
        message: t('document.formValidationError', {
          defaultValue: 'Form validation error',
        }),
        description:
          Object.keys(errors).length > 5
            ? (t('document.multipleInvalidField', {
                defaultValue:
                  'Multiple required fields are not filled out properly. Please check and try again.',
              }) as string)
            : Object.keys(errors)
                .map((key) => errors[key])
                .join('\n'),
        type: 'danger',
        duration: 5000,
        autoHide: true,
        hideOnPress: true,
      });
      return;
    }

    const { values } = formik;
    if (documentTypeIndex !== undefined) {
      let userIdentityDocumentInput = {
        documentType: documentTypeIndex === 0 ? 'DRIVERS_LICENSE' : 'NATIONAL_ID',
        documentSubType:
          documentTypeIndex === 0 ? values.documentSubType[0] : values.documentSubType,
        documentSubTypeMulti:
          documentTypeIndex === 0 ? values.documentSubType : [values.documentSubType],
        description: values.description,
        documentFeature: values.documentFeature || null,
        documentNumber: values.documentNumber,
        countryOfIssue: values.countryOfIssue,
        placeOfIssue: values.placeOfIssue,
        dateOfIssue: values.dateOfIssue,
        nationality: values.nationality,
        isInternationalDrivingPermit: values.isInternationalDrivingPermit,
        expiresOn: values.expiresOn,
        expiresOnInternational: values.expiresOnInternational,
        firstNames: values.firstNames,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth,
        placeOfBirth: values.placeOfBirth,
        sex: values.sex,
        countryOfBirth: values.countryOfBirth,
      };
      setIsSaving(true);
      let documentsToUpload = [{ key: 'front', fieldKey: 'Front', document: values.documentFront }];
      if (values.documentBack) {
        documentsToUpload.push({
          key: 'back',
          fieldKey: 'Back',
          document: values.documentBack,
        });
      }

      try {
        const documentRes = await uploadDocumentsToServer(documentsToUpload);
        if (documentRes?.length) {
          documentRes.forEach((item) => {
            userIdentityDocumentInput = {
              ...userIdentityDocumentInput,
              ...item,
            };
          });
          await uploadUserIdentityDocument({
            variables: { userIdentityDocumentInput },
          });
        }
      } catch (e) {
        showMessage({
          message: t('document.error', { defaultValue: 'Error' }),
          description: t('document.somethingWentWrongWithSaving', {
            defaultValue: 'Sorry, something went wrong with saving document.',
          }) as string,
          type: 'danger',
          duration: 5000,
          autoHide: true,
          hideOnPress: true,
        });
      }
      setIsSaving(false);
    }
  };

  const initialValues = {
    description: '',
    dateOfIssue: '',
    documentNumber: '',
    documentFront: null,
    documentBack: null,
    documentSubType: [],
    documentFeature: '',
    expiresOn: null,
    placeOfIssue: '',
    countryOfIssue: '',
    firstNames: '',
    lastName: '',
    sexValue: '',
    dateOfBirth: '',
    placeOfBirth: '',
    countryOfBirth: '',
    isInternationalDrivingPermit: false,
    expiresOnInternational: null,
  };

  const validationSchema = Yup.object().shape({
    documentFront: Yup.object()
      .nullable()
      .required(
        t('document.requiredFieldDocumentFront', {
          defaultValue: 'Front side of the document must be set',
        }) as string,
      ),
    documentBack: !hasOnlyFrontPage
      ? Yup.object()
          .nullable()
          .required(
            t('document.requiredFieldDocumentBack', {
              defaultValue: 'Back side of the document must be set',
            }) as string,
          )
      : Yup.object().nullable(),
    description:
      documentTypeIndex === 0
        ? Yup.string().required(
            t('document.requiredFieldLicenseClass', {
              defaultValue: 'License class must be set',
            }) as string,
          )
        : Yup.string(),
    documentNumber: Yup.string().required(
      t('document.requiredFieldDocumentNumber', {
        defaultValue: 'Document number must be set',
      }) as string,
    ),
    dateOfIssue: Yup.string().required(
      t('document.requiredFieldDateOfIssue', {
        defaultValue: 'Date of issue must be set',
      }) as string,
    ),
    placeOfIssue: Yup.string().required(
      t('document.requiredFieldPlaceOfIssue', {
        defaultValue: 'Place of issue must be set',
      }) as string,
    ),
    countryOfIssue: Yup.string().required(
      t('document.requiredFieldCountryOfIssue', {
        defaultValue: 'Country of issue must be set',
      }) as string,
    ),
    documentSubType:
      documentTypeIndex === 0
        ? Yup.array()
            .nullable()
            .required(
              t('document.requiredFieldDocumentDescription', {
                defaultValue: 'Document description must be set',
              }) as string,
            )
            .min(1, 'Document description must be set')
        : Yup.string().required(
            t('document.requiredFieldDocumentDescription', {
              defaultValue: 'Document description must be set',
            }) as string,
          ),
    documentFeature:
      documentTypeIndex === 1
        ? Yup.string().required(
            t('document.requiredFieldDocumentFeature', {
              defaultValue: 'Document feature must be set',
            }) as string,
          )
        : Yup.string(),
    countryOfBirth: Yup.string().required(
      t('document.requiredFieldCountryOfBirth', {
        defaultValue: 'Country of birth must be set',
      }) as string,
    ),
    firstNames: Yup.string().required(
      t('document.requiredFieldFirstNames', { defaultValue: 'First name must be set' }) as string,
    ),
    lastName: Yup.string().required(
      t('document.requiredFieldLastName', { defaultValue: 'Last name must be set' }) as string,
    ),
    sex: Yup.string().required(
      t('document.requiredFieldSex', { defaultValue: 'Sex must be set' }) as string,
    ),
    dateOfBirth: Yup.string()
      .nullable()
      .required(
        t('document.requiredFieldDateOfBirth', {
          defaultValue: 'Date of birth must be set',
        }) as string,
      ),
    placeOfBirth: Yup.string().required(
      t('document.requiredFieldPlaceOfBirth', {
        defaultValue: 'Place of birth must be set',
      }) as string,
    ),
    nationality:
      documentTypeIndex === 1
        ? Yup.string().required(
            t('document.requiredFieldNationality', {
              defaultValue: 'Nationality must be set',
            }) as string,
          )
        : Yup.string().nullable(),
  });

  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: handleSave,
  });

  const handleSelectDocument = (side: 'front' | 'back', result: any) => {
    if (side === 'front') {
      formik?.setFieldValue('documentFront', result);
    } else {
      formik?.setFieldValue('documentBack', result);
    }
  };

  const handleBackPageChange = () => {
    setHasOnlyFrontPage((prev) => {
      if (!prev) {
        formik.setFieldValue('documentBack', null);
      }
      return !prev;
    });
    formik.validateField('documentFront');
    formik.validateField('documentBack');
  };

  const fieldProps = (field: string, type = 'text') => {
    const data: any = formik.getFieldProps(field);
    const { error: fieldError } = formik.getFieldMeta(field);
    if (type === 'checkbox') {
      const onChange = (v: any): void => {
        formik.handleChange(field)({
          target: { value: v },
        });
        if (fieldError) {
          formik.validateField(field);
        }
      };
      return {
        checked: data.value,
        errorMessage: formik.touched[field] ? fieldError : '',
        onChange,
        status: formik.touched[field] && fieldError ? 'danger' : 'basic',
      };
    }

    const onChangeText = (v: any): void => {
      let value = v;
      if (field === 'description' && value.length > 400) {
        value = value.substring(0, 400);
      }
      if (field.includes('baseAirport')) {
        if (value.length > 3) {
          return;
        }
        value = value.toUpperCase();
      }
      formik.handleChange(field)({
        target: { value },
      });
      if (fieldError) {
        formik.validateField(field);
      }
    };
    const common = {
      errorMessage: fieldError || '',
      status: fieldError ? 'danger' : 'basic',
      value: data.value,
    };
    if (type === 'money-input') {
      return { ...common, onChangeValue: onChangeText };
    }
    return { ...common, onChangeText };
  };

  const onSelectDocumentSubType = (value: any) => {
    if (Platform.OS === 'web') {
      let values = [...formik.values.documentSubType];
      if (values.includes(value)) {
        values = values.filter((item) => item !== value);
      } else {
        values = [...values, value];
      }
      formik?.setFieldValue('documentSubType', values);
    } else {
      formik?.setFieldValue('documentSubType', value);
    }
    setTimeout(() => {
      if (formik?.errors.documentSubType) {
        formik.validateField('documentSubType');
      }
    }, 100);
  };

  const onSelect =
    ({ valueKey }: any) =>
    (value: any) => {
      formik?.setFieldValue(valueKey, value);
      setTimeout(() => {
        if (formik?.errors[valueKey]) {
          formik.validateField(valueKey);
        }
      }, 100);
    };

  const renderSelectAutocomplete = ({
    caption,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <FormAutocomplete
      appearance="default"
      caption={caption}
      label={label}
      containerStyle={styles.input}
      onSelect={onSelect({ options, valueKey })}
      items={options}
      value={formik.values[valueKey]}
      fieldTitleKey={fieldTitleKey}
      fieldValueKey={fieldValueKey}
      errorMessage={formik?.errors[valueKey]}
      isMandatory
    />
  );

  const renderSelect = ({
    caption,
    label,
    options,
    valueKey,
    fieldTitleKey,
    fieldValueKey,
  }: any) => (
    <FormSelect
      appearance="default"
      caption={caption}
      label={label}
      containerStyle={styles.input}
      onSelect={onSelect({ options, valueKey })}
      items={options}
      value={formik.values[valueKey]}
      fieldTitleKey={fieldTitleKey}
      fieldValueKey={fieldValueKey}
      errorMessage={formik?.errors[valueKey]}
      isMandatory
    />
  );

  const renderDriverLicenseFields = () => (
    <>
      <FormSelect
        appearance="default"
        label={
          t('document.documentDescription', { defaultValue: 'DOCUMENT DESCRIPTION' }) as string
        }
        containerStyle={styles.input}
        multiSelect
        onSelect={onSelectDocumentSubType}
        fieldTitleKey="name"
        fieldValueKey="value"
        items={LicenseTypeSubList}
        errorMessage={formik?.errors.documentSubType}
        value={formik.values.documentSubType}
        isMandatory
      />
      <FormTextInputTopLabel
        containerStyle={styles.input}
        label={t('LICENSE CLASS (A1/B1...)') as string}
        isMandatory
        {...fieldProps('description')}
      />
      <FormTextInputTopLabel
        containerStyle={styles.input}
        label={t('document.documentNumber', { defaultValue: 'DOCUMENT NUMBER' }) as string}
        isMandatory
        {...fieldProps('documentNumber')}
      />
      <FormTextDatepicker
        label={t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }) as string}
        containerStyle={styles.input}
        min={minimumBirthDay}
        max={now}
        date={formik.values.dateOfIssue && formik.values.dateOfIssue?.toJSDate()}
        onChange={(value: any) => {
          formik?.setFieldValue('dateOfIssue', DateTime.fromJSDate(value));
          setTimeout(() => {
            if (formik?.errors.dateOfIssue) {
              formik.validateField('dateOfIssue');
            }
          }, 100);
        }}
        errorMessage={formik?.errors.dateOfIssue}
        isMandatory
      />
      <FormTextDatepicker
        containerStyle={[styles.input, globalStyle.marginBottom20]}
        caption={renderExpiresOnCaption()}
        label={t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }) as string}
        min={now}
        max={maximumBirthDay}
        date={formik.values.expiresOn && formik.values.expiresOn?.toJSDate()}
        onChange={(value) => {
          formik?.setFieldValue('expiresOn', DateTime.fromJSDate(value));
          setTimeout(() => {
            if (formik?.errors.expiresOn) {
              formik.validateField('expiresOn');
            }
          }, 100);
        }}
        errorMessage={formik?.errors.expiresOn}
      />
      {renderSelectAutocomplete({
        label: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }) as string,
        valueKey: 'countryOfIssue',
        options: CountryListData,
        fieldTitleKey: 'name',
        fieldValueKey: 'code',
      })}
      <FormTextInputTopLabel
        containerStyle={styles.input}
        label={(
          t('document.placeOfIssue', { defaultValue: 'PLACE OF ISSUE' }) as string
        ).toUpperCase()}
        isMandatory
        {...fieldProps('placeOfIssue')}
      />

      <ContentBox
        style={[
          globalStyle.marginTop0,
          globalStyle.marginLeft0,
          globalStyle.marginRight0,
          globalStyle.paddingTop10,
          globalStyle.paddingLeft5,
          !formik.values.isInternationalDrivingPermit
            ? globalStyle.paddingBottom10
            : globalStyle.paddingBottom0,
        ]}
        Content={
          <>
            <CheckBox {...fieldProps('isInternationalDrivingPermit', 'checkbox')} status="primary">
              {
                t('document.iHoldInternationalDrivingPermit', {
                  defaultValue: 'I hold an International Driving Permit as an additional document',
                }) as string
              }
            </CheckBox>
            {formik.values.isInternationalDrivingPermit && (
              <FormTextDatepicker
                containerStyle={[styles.input, globalStyle.marginTop20]}
                label={
                  t('document.dateOfExpiryInternational', {
                    defaultValue: 'DATE OF EXPIRY (INTERNATIONAL)',
                  }) as string
                }
                min={now}
                max={maximumBirthDay}
                date={
                  formik.values.expiresOnInternational &&
                  formik.values.expiresOnInternational?.toJSDate()
                }
                onChange={(value) => {
                  formik?.setFieldValue('expiresOnInternational', DateTime.fromJSDate(value));
                  setTimeout(() => {
                    if (formik?.errors.expiresOnInternational) {
                      formik.validateField('expiresOnInternational');
                    }
                  }, 100);
                }}
                errorMessage={formik?.errors.expiresOnInternational}
              />
            )}
          </>
        }
      />
    </>
  );

  const renderDocumentSubTypeCaption = (): JSX.Element => (
    <Text style={[globalStyle.marginTop0, globalStyle.marginBottom10, styles.caption]}>
      {
        t('document.documentDescriptionCaption', {
          defaultValue:
            'Please select the appropriate document type from the list for the Identity Document you are holding',
        }) as string
      }
    </Text>
  );

  const renderExpiresOnCaption = (): JSX.Element => (
    <Text style={[globalStyle.marginTop0, globalStyle.marginBottom10, styles.caption]}>
      {
        t('document.documentExpiresOnCaption', {
          defaultValue: 'Leave blank if document does not have expiration',
        }) as string
      }
    </Text>
  );

  const renderNationalIdPasswordFields = () => (
    <>
      {renderSelect({
        label: t('document.documentDescription', {
          defaultValue: 'DOCUMENT DESCRIPTION',
        }) as string,
        caption: renderDocumentSubTypeCaption(),
        valueKey: 'documentSubType',
        options: PassportTypeList,
        fieldTitleKey: 'name',
        fieldValueKey: 'value',
      })}
      <FormTextInputTopLabel
        containerStyle={styles.input}
        label={t('document.documentNumber', { defaultValue: 'DOCUMENT NUMBER' }) as string}
        isMandatory
        {...fieldProps('documentNumber')}
      />
      {renderSelect({
        label: t('document.documentFeature', { defaultValue: 'DOCUMENT FEATURE' }),
        valueKey: 'documentFeature',
        options: DocumentFeatureList,
        fieldTitleKey: 'name',
        fieldValueKey: 'value',
      })}
      {renderSelectAutocomplete({
        label: t('document.nationality', { defaultValue: 'NATIONALITY' }),
        valueKey: 'nationality',
        options: CountryListData,
        fieldTitleKey: 'name',
        fieldValueKey: 'code',
      })}

      <FormTextDatepicker
        label={t('document.dateOfIssue', { defaultValue: 'DATE OF ISSUE' }) as string}
        containerStyle={styles.input}
        min={minimumBirthDay}
        max={now}
        date={formik.values.dateOfIssue && formik.values.dateOfIssue?.toJSDate()}
        onChange={(value: any) => {
          formik?.setFieldValue('dateOfIssue', DateTime.fromJSDate(value));
          setTimeout(() => {
            if (formik?.errors.dateOfIssue) {
              formik.validateField('dateOfIssue');
            }
          }, 100);
        }}
        errorMessage={formik?.errors.dateOfIssue}
        isMandatory
      />
      <FormTextDatepicker
        label={t('document.dateOfExpiry', { defaultValue: 'DATE OF EXPIRY' }) as string}
        containerStyle={[styles.input, globalStyle.marginBottom20]}
        caption={renderExpiresOnCaption()}
        min={now}
        max={maximumBirthDay}
        date={formik.values.dateOfIssue && formik.values.expiresOn?.toJSDate()}
        onChange={(value: any) => {
          formik?.setFieldValue('expiresOn', DateTime.fromJSDate(value));
          setTimeout(() => {
            if (formik?.errors.expiresOn) {
              formik.validateField('expiresOn');
            }
          }, 100);
        }}
        errorMessage={formik?.errors.expiresOn}
      />
      {renderSelectAutocomplete({
        label: t('document.countryOfIssue', { defaultValue: 'COUNTRY OF ISSUE' }) as string,
        valueKey: 'countryOfIssue',
        options: CountryListData,
        fieldTitleKey: 'name',
        fieldValueKey: 'code',
      })}
      <FormTextInputTopLabel
        containerStyle={styles.input}
        label={(
          t('document.placeOfIssue', { defaultValue: 'PLACE OF ISSUE' }) as string
        ).toUpperCase()}
        isMandatory
        {...fieldProps('placeOfIssue')}
      />
    </>
  );

  const renderProfileData = () => (
    <ContentBox
      Content={
        <>
          <Text
            selectable={true}
            style={[styles.descriptionText, globalStyle.marginBottom10]}
            category="s1"
          >
            The initial values of the First Names and Last Name below are coming from your user
            profile. Ensure that the information on the document matches the information below.
            Otherwise, you can change it here, but it will not update your names on your profile.
          </Text>

          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={(
              t('document.firstNames', { defaultValue: 'FIRST NAMES' }) as string
            ).toUpperCase()}
            isMandatory
            {...fieldProps('firstNames')}
          />
          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={(t('document.lastName', { defaultValue: 'LAST NAME' }) as string).toUpperCase()}
            isMandatory
            {...fieldProps('lastName')}
          />

          <Text selectable={true} style={[styles.descriptionText, globalStyle.marginTop10]}>
            The fields below take information from your profile. However, you can change the
            information below accordingly based on what is written on your identity document.
          </Text>
          <Text
            selectable={true}
            style={[styles.descriptionText, globalStyle.fontLatoBold, globalStyle.marginBottom20]}
          >
            IMPORTANT: Any changes in the fields below will reflect on your profile once it is saved
            and the last information will be used on the next documents that will be uploaded. If
            changes were made on the profile, it will not update the document information.
          </Text>

          {renderSelect({
            label: (t('document.sex', { defaultValue: 'Sex' }) as string).toUpperCase(),
            valueKey: 'sex',
            options: SexList,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}

          {renderSelectAutocomplete({
            label: (
              t('document.countryOfBirth', { defaultValue: 'Country of birth' }) as string
            ).toUpperCase(),
            valueKey: 'countryOfBirth',
            options: CountryListData,
            fieldTitleKey: 'name',
            fieldValueKey: 'code',
          })}

          <FormTextInputTopLabel
            containerStyle={styles.input}
            label={(
              t('document.placeOfBirth', { defaultValue: 'PLACE OF BIRTH' }) as string
            ).toUpperCase()}
            isMandatory
            {...fieldProps('placeOfBirth')}
          />
          <FormTextDatepicker
            label={(
              t('document.dateOfBirth', { defaultValue: 'DATE OF BIRTH' }) as string
            ).toUpperCase()}
            containerStyle={styles.input}
            min={minimumBirthDay}
            max={now}
            date={formik.values.dateOfBirth && formik.values.dateOfBirth?.toJSDate()}
            onChange={(value: Date) => {
              formik?.setFieldValue('dateOfBirth', DateTime.fromJSDate(value));
              setTimeout(() => {
                if (formik?.errors.dateOfBirth) {
                  formik.validateField('dateOfBirth');
                }
              }, 100);
            }}
            isMandatory
          />
        </>
      }
    />
  );

  return (
    <>
      <TopNavigationWithBackButton
        accessoryRight={() => (
          <Button onPress={handleSave} appearance="filled" style={styles.saveBtn}>
            {isSaving ? (
              <ActivityIndicator />
            ) : (
              (t('common.save', {
                defaultValue: t('common.save', { defaultValue: 'Save' }) as string,
              }) as string)
            )}
          </Button>
        )}
        title={title}
        navigation={navigation}
        onPressLeft={() => {
          navigation.navigate('CourierProfileNavigator', {
            screen: 'CourierUserIdentityDocumentsScreen',
          });
        }}
      />

      <KeyboardAwareScrollView
        contentContainerStyle={styles.contentContainer}
        enableAutomaticScroll
        enableOnAndroid
        extraScrollHeight={100}
      >
        <RadioGroup
          style={styles.radioContainer}
          selectedIndex={documentTypeIndex}
          onChange={(index) => {
            setDocumentTypeIndex(index);
            if (index !== documentTypeIndex) {
              if (index === 1) {
                formik?.setFieldValue('documentSubType', []);
              }
            }
          }}
        >
          <Radio style={styles.input}>Driver's License</Radio>
          <Radio style={styles.input}>National ID/Passport</Radio>
        </RadioGroup>

        {documentTypeIndex !== undefined && (
          <>
            <View style={globalStyle.padding10}>
              <CourierAddDocumentFile
                allowBackPage
                identityPlaceholder
                documentBack={formik.values.documentBack}
                documentFront={formik.values.documentFront}
                hasBackPage={!hasOnlyFrontPage}
                onBackPageChange={handleBackPageChange}
                onSelectDocument={handleSelectDocument}
              />
            </View>

            <Divider style={globalStyle.marginBottom10} />

            <View style={globalStyle.padding20}>
              {documentTypeIndex === 0 && renderDriverLicenseFields()}
              {documentTypeIndex === 1 && renderNationalIdPasswordFields()}
            </View>

            <Divider style={[globalStyle.marginBottom15]} />

            {renderProfileData()}
          </>
        )}
      </KeyboardAwareScrollView>
    </>
  );
};

const themedStyles = StyleService.create({
  scrollView: {},
  contentContainer: {
    paddingVertical: 24,
  },
  radioContainer: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 20,
  },
  descriptionText: {
    alignSelf: 'flex-start',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 0,
    color: 'text-basic-color',
    opacity: 0.9,
  },
  input: {
    flex: 1,
    marginTop: 10,
    marginBottom: 20,
  },
  checkBox: {
    margin: 5,
  },
  saveBtn: {
    height: 44,
    width: 75,
  },
  caption: {
    fontSize: 13,
    color: 'text-basic-color',
    opacity: 0.5,
  },
});
