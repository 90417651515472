import React, { useEffect, useMemo, useState } from 'react';
import { Platform } from 'react-native';

import { Icon } from '@ui-kitten/components';
import * as AuthSession from 'expo-auth-session';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';

import { AuthSessionResult } from './types';
import { createApolloClientNoPersist } from '../../../apollo/create-apollo-client-no-persist';
import { getAuthorizationHeaders } from '../../../apollo/create-auth-link-with-headers';
import { REGISTER_EXISTING_USER_WITH_OWNED_ORGANIZATION } from '../../../apollo/graphql-mutations';
import { OrganizationType, OrganizationUser } from '../../../generated-graphql-types';
import { authRequestConfigSignup, discoveryDocumentSignup } from '../../../globals';
import { useIsInternetAndBackendReachable } from '../../../hooks/useIsInternetAndBackendReachable';
import { storeUserContexts } from '../../../modules/authentication/authentication.module';
import { AuthenticationService } from '../../../services/authentication.service';
import { LocalUserContexts } from '../../../types';
import { StandardButtonStates } from '../../standard-button.component';

export const useHook = ({
  organizationType,
  handleModalClose,
}: {
  organizationType: OrganizationType;
  handleModalClose: (params?: { reloadContext?: boolean }) => void;
}) => {
  const { t, i18n }: any = useTranslation();

  const apolloClient = createApolloClientNoPersist();
  const isInternetAndBackendReachable = useIsInternetAndBackendReachable();

  const authenticationService = new AuthenticationService();
  const [authSessionResult, setAuthSessionResult] = useState<AuthSessionResult>();
  const [authRequest, _setAuthSessionResult, promptAsync] = AuthSession.useAuthRequest(
    authRequestConfigSignup,
    discoveryDocumentSignup,
  );

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState<boolean>(false);
  const [isSignUpPopupActive, setIsSignUpPopupActive] = useState<boolean>(false);
  const [localUserContexts, setLocalUserContexts] = useState<LocalUserContexts>({});
  const [signUpButtonStates, setSignUpButtonStates] = useState<StandardButtonStates>({});

  useEffect(() => {
    const func = async () => {
      const existingLocalUserContexts = await authenticationService.loadLocalUserContextsAsync();
      setLocalUserContexts(existingLocalUserContexts);
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authSessionResult && authRequest) {
      handleAuthRequestResponse(authRequest, authSessionResult);
    } else if (authSessionResult && !authRequest) {
      showMessage({
        message: t('common.signUpNotSuccessful', {
          defaultValue: 'Sign up was not successful.',
        }),
        description: t('common.signUpNotSuccessfulDescription', {
          defaultValue:
            'Sign up not successful. Please try again or contact the PRIOjet support support@priojet.com.',
        }) as string,
        renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
        type: 'warning',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authSessionResult, authRequest]);

  useEffect(() => {
    let buttonText = t('common.signUp', { defaultValue: 'Sign up' }) as string;
    if (organizationType === OrganizationType.COURIER) {
      buttonText = t('common.signUpAsCourier', { defaultValue: 'Sign up as Agency' }) as string;
    } else if (organizationType === OrganizationType.AGENCY) {
      buttonText = t('common.signUpAsAgency', { defaultValue: 'Sign up as Courier' }) as string;
    }
    setSignUpButtonStates({
      disabled: {
        isActive: isSignUpPopupActive || !termsAndConditionsAccepted,
        text: buttonText,
      },
      on: {
        isActive: !isSignUpPopupActive && termsAndConditionsAccepted,
        appearance: 'filled',
        onPress: handlePressSignupAsync,
        text: buttonText,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignUpPopupActive, termsAndConditionsAccepted]);

  const handlePressSignupAsync = async () => {
    if (promptAsync) {
      try {
        setIsSignUpPopupActive(true);
        const response = (await promptAsync()) as AuthSessionResult;

        if (response) {
          if (
            response.type === 'cancel' ||
            response.type === 'dismiss' ||
            response.type === 'error'
          ) {
            if (
              response.params?.error_description?.includes(
                'The user has cancelled multi-factor authentication',
              )
            ) {
              showMessage({
                message: t('common.signUpCancelledVerificationNeeded', {
                  defaultValue: 'Sign up successful, extra verification needed',
                }),
                description: t('common.signUpCancelledPhoneNumberDescription', {
                  defaultValue:
                    'Your account has been created without verifying your phone number. In order to use your account press Sign In button below.',
                }) as string,
                renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
                type: 'warning',
                autoHide: true,
                hideOnPress: true,
                duration: 20000,
              });
              setIsSignUpPopupActive(false);
              handleModalClose({ reloadContext: false });
            } else {
              showMessage({
                message: t('common.signUpCancelled', {
                  defaultValue: 'Sign up cancelled',
                }),
                description: t('common.signUpCancelledMessageDescription', {
                  defaultValue:
                    'Sign up cancelled. To try again, press the sign up button below. In case a popup does not open, please restart the app.',
                }) as string,
                renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
                type: 'warning',
                autoHide: true,
                hideOnPress: true,
                duration: 20000,
              });
              setIsSignUpPopupActive(false);
            }
          } else {
            handleModalClose({ reloadContext: true });
            Promise.all([setAuthSessionResult(response), setIsSignUpPopupActive(false)]);
          }
        }
      } catch (err) {
        console.warn('error upon signin in', err);
      }
    }
  };

  const handleAuthRequestResponse = async (
    _authRequest: AuthSession.AuthRequest,
    _authSessionResult: AuthSession.AuthSessionResult,
  ) => {
    if (!isInternetAndBackendReachable) {
      showMessage({
        message: 'Sign up failed',
        description: t('common.flashMessageProblemDuringSignUp', {
          defaultValue:
            'Our servers are currently not reachable. Please check your internet connection. Cannot proceed with sign up.',
        }) as string,
        renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
        type: 'warning',
      });
    }

    if (!organizationType) {
      showMessage({
        message: t('common.error'),
        description: t('common.problemOccurredDuringSignUpNotSuccessful', {
          defaultValue: 'A problem occurred during sign-up. Sign-up not successful.',
        }) as string,
        renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
        type: 'danger',
      });
      return;
    }

    const newLocalUserContext =
      await authenticationService.createLocalUserContextFromAuthRequestAndAuthSessionResultAsync({
        authRequest: _authRequest,
        authSessionResult: _authSessionResult,
        isSignUp: true,
      });
    if (newLocalUserContext) {
      // Register the newLocalUserContext with the backend.
      const authorizationHeaders = await getAuthorizationHeaders(newLocalUserContext);
      const result = await apolloClient.mutate({
        mutation: REGISTER_EXISTING_USER_WITH_OWNED_ORGANIZATION,
        fetchPolicy: 'network-only',
        variables: { organizationType },
        context: {
          headers: authorizationHeaders,
        },
      });

      if (result && result.data?.registerExistingUserWithOwnedOrganization) {
        const newLocalUserContexts =
          await authenticationService.getUpdatedLocalUserContextsFromMergedLocalUserContextWithOrganizationUserAsync(
            {
              localUserContexts: localUserContexts || {},
              localUserContext: newLocalUserContext,
              organizationUser: result.data
                ?.registerExistingUserWithOwnedOrganization as Partial<OrganizationUser>,
            },
          );

        showMessage({
          message: t('common.signUpSuccessful', { defaultValue: 'Sign-up successful' }),
          description: t('common.flashMessageSignUpSuccessful', {
            defaultValue:
              'We successfully created your account. You must activate it first before you can use it.',
          }) as string,
          renderFlashMessageIcon: () => <Icon name="checkmark-circle-outline" />,
          type: 'success',
        });
        setLocalUserContexts(newLocalUserContexts);
        await storeUserContexts(newLocalUserContexts);
        handleModalClose({ reloadContext: true });
      } else {
        showMessage({
          message: t('common.error'),
          description: t('common.problemOccurredDuringSignUpNotSuccessful', {
            defaultValue: 'A problem occurred during sign-up. Sign-up not successful.',
          }) as string,
          renderFlashMessageIcon: () => <Icon name="alert-triangle-outline" />,
          type: 'danger',
        });
      }
    }
  };

  const handleLinkPress = (e: any) => {
    if (Platform.OS === 'web' && e?.stopPropagation) {
      e.stopPropagation();
    }
  };

  const handleTermsAndConditionsChange = () => {
    setTermsAndConditionsAccepted((prev) => !prev);
  };

  const linkTermAndConditions = useMemo(() => {
    if (organizationType === OrganizationType.COURIER) {
      if (i18n.language === 'de') {
        return 'https://www.priojet.com/tcdocdec';
      }
      return 'https://www.priojet.com/tcdocenc';
    }
    if (i18n.language === 'de') {
      return 'https://www.priojet.com/tcdocdea';
    }
    return 'https://www.priojet.com/tcdocena';
  }, [i18n.language, organizationType]);

  const linkPrivacyPolicyPress = useMemo(() => {
    if (organizationType === OrganizationType.COURIER) {
      if (i18n.language === 'de') {
        return 'https://www.priojet.com/dpdocdec';
      }
      return 'https://www.priojet.com/dpdocenc';
    }
    if (i18n.language === 'de') {
      return 'https://www.priojet.com/dpdocdea';
    }
    return 'https://www.priojet.com/dpdocena';
  }, [i18n.language, organizationType]);

  return {
    handleLinkPress,
    handleTermsAndConditionsChange,
    isSignUpPopupActive,
    linkTermAndConditions,
    linkPrivacyPolicyPress,
    setTermsAndConditionsAccepted,
    setIsSignUpPopupActive,
    signUpButtonStates,
    termsAndConditionsAccepted,
  };
};
