import React, { Dispatch, SetStateAction } from 'react';
import { View } from 'react-native';

import { Button, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import * as WebBrowser from 'expo-web-browser';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from './common/loading-spinner.component';
import ContentBoxTextTitle from './content-box-text-title.component';
import { globalStyle } from '../common/style';
import { useIsInternetAndBackendReachable } from '../hooks/useIsInternetAndBackendReachable';
import { LocalUserContexts } from '../types';

WebBrowser.maybeCompleteAuthSession(); // needs to be called so the browser popup closes

export const SignupBox = ({
  navigation,

  isLoadingMessage,
}: {
  navigation: any;
  userContexts: LocalUserContexts;
  setUserContexts: (userContexts: LocalUserContexts) => Promise<void>;
  isLoadingMessage: string | undefined;
  setIsLoadingMessage: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const styles = useStyleSheet(themedStyles);
  const { t } = useTranslation();
  const isInternetAndBackendReachable = useIsInternetAndBackendReachable();

  if (isLoadingMessage) {
    return (
      <ContentBoxTextTitle
        title="Your Accounts"
        Content={
          <View style={globalStyle.marginBottom20}>
            <LoadingSpinner noText />
          </View>
        }
      />
    );
  }

  return (
    <ContentBoxTextTitle
      style={styles.content}
      title={t('common.signUp', { defaultValue: 'Sign up' })}
      Content={
        <View>
          <Button
            appearance="filled"
            onPress={() => navigation.navigate('AgencyTermsAndConditionsSignupScreen')}
            style={styles.button}
            disabled={!isInternetAndBackendReachable}
          >
            <Text>
              {
                t('common.signUpAsAnAgency', {
                  defaultValue: 'Sign up as an Agency',
                }) as string
              }
              {!isInternetAndBackendReachable ? ' (Connecting...)' : ''}
            </Text>
          </Button>
          <Button
            appearance="filled"
            onPress={() => navigation.navigate('CourierTermsAndConditionsSignupScreen')}
            style={styles.button}
            disabled={!isInternetAndBackendReachable}
          >
            <Text>
              {
                t('common.signUpAsACourier', {
                  defaultValue: 'Sign up as a Courier',
                }) as string
              }
              {!isInternetAndBackendReachable ? ' (Connecting...)' : ''}
            </Text>
          </Button>
        </View>
      }
    />
  );
};

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'background-basic-color-2',
    paddingTop: 5,
    paddingBottom: 15,
    borderRadius: 10,
  },

  button: {
    margin: 2,
    maxWidth: 500,
  },
});
