import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import { Datepicker, LayoutProps, StyleService, Text, useStyleSheet } from '@ui-kitten/components';
import { TFunctionDetailedResult } from 'i18next';

import { CalendarIcon } from '../../icons.component';

export interface FormInputProps extends LayoutProps {
  hint: string;
  value: string;
  placeholder?: string | undefined;
  isLoading?: boolean;
  isDisabled?: boolean;
  isEditMode?: boolean;
}

interface FormTextDatepickerProps extends Omit<FormInputProps, 'value' | 'hint'> {
  containerStyle?: StyleProp<ViewStyle>;
  min?: Date;
  max?: Date;
  date?: Date | null;
  onChange: (value: Date) => void;
  status?: string;
  children?: React.ReactNode;
  label: TFunctionDetailedResult | string;
  caption?: JSX.Element | TFunctionDetailedResult | string;
  errorMessage?: string;
  isMandatory?: boolean;
  placeholderDefault?: TFunctionDetailedResult | string;
  style?: StyleProp<ViewStyle>;
}

export const FormTextDatepicker = (props: FormTextDatepickerProps): React.ReactElement => {
  const styles = useStyleSheet(themedStyles);

  return (
    <View style={[styles.container, props.containerStyle]}>
      <Datepicker
        placement="top"
        placeholder={props.placeholder}
        caption={undefined} // disable default caption.
        label={
          <View style={styles.labelContainer}>
            <Text selectable={true} style={styles.label}>
              {props.label as string}
              {props.isMandatory ? (
                <Text selectable={true} style={styles.mandatoryStar}>
                  *
                </Text>
              ) : (
                <></>
              )}
            </Text>
          </View>
        }
        status={props.errorMessage ? 'danger' : props.status}
        appearance="default"
        disabled={props.isDisabled}
        min={props.min}
        max={props.max}
        date={props.date ? props.date : undefined}
        onSelect={(value) => props.onChange(value)}
        accessoryRight={CalendarIcon}
        style={props.style}
      />
      {!!props.caption && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.caption}>
            {props.caption as string}
          </Text>
        </View>
      )}
      {!!props.errorMessage && (
        <View style={styles.captionContainer}>
          <Text selectable={true} style={styles.errorMessageText}>
            {props.errorMessage}
          </Text>
        </View>
      )}
    </View>
  );
};

const themedStyles = StyleService.create({
  container: {
    marginTop: 20,
    paddingTop: 5,
  },
  captionContainer: {
    paddingTop: 5,
  },
  caption: {
    color: 'color-basic-600',
    fontSize: 13,
  },
  errorMessageText: {
    color: 'color-danger-500',
    fontSize: 13,
    paddingBottom: 5,
  },
  labelContainer: {
    paddingBottom: 5,
  },
  label: {
    color: 'color-primary-300',
    fontFamily: 'Lato_700Bold',
    fontSize: 13,
    textTransform: 'uppercase',
  },
  mandatoryStar: {
    color: 'color-danger-600',
  },
  checkBox: {
    marginLeft: 10,
  },
});
