import { Platform } from 'react-native';

import axios from 'axios';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Sharing from 'expo-sharing';

import i18n from '../../i18n/i18n';

const permissionErrorMessage = i18n.t('common.downloadNotPossibleWithoutPermission', {
  defaultValue:
    'The download is currently not possible. To enable it, enable permission in the device and app settings.',
});
const somethingWentWrongMessage = i18n.t('common.somethingWentWrongWithFileDownload', {
  defaultValue: 'Something went wrong with file download.',
});

const downloadFilePDFAndroidElevenOrLess = async (fileName: string, savedFileUri: string) => {
  const permissions = await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync();
  if (!permissions.granted) {
    return { error: permissionErrorMessage, showMessage: true, success: false };
  }

  const imageSource = await FileSystem.readAsStringAsync(savedFileUri, { encoding: 'base64' });
  try {
    return FileSystem.StorageAccessFramework.createFileAsync(
      permissions.directoryUri,
      fileName,
      'application/pdf',
    )
      .then(async (uri) => {
        await FileSystem.writeAsStringAsync(uri, imageSource, {
          encoding: FileSystem.EncodingType.Base64,
        });
        return { error: '', showMessage: true, success: true };
      })
      .catch(() => {
        return { error: somethingWentWrongMessage, showMessage: true, success: false };
      });
  } catch {
    return { error: somethingWentWrongMessage, showMessage: true, success: false };
  }
};

export const downloadFile = async ({
  fileName,
  mimeType,
  uri,
}: {
  fileName: string;
  mimeType: string;
  uri: string;
}): Promise<{ error: string; showMessage: boolean; success: boolean }> => {
  if (Platform.OS !== 'web') {
    const perm = await MediaLibrary.requestPermissionsAsync();
    if (perm.status !== 'granted' && Platform.OS === 'android') {
      return { error: permissionErrorMessage, showMessage: true, success: false };
    }
  }
  return FileSystem.downloadAsync(uri, FileSystem.documentDirectory + fileName)
    .then(async (savedFile) => {
      try {
        const imageFileExts = ['jpg', 'jpeg', 'png', 'gif', 'heic', 'webp', 'bmp'];
        if (
          Platform.OS === 'android' ||
          (Platform.OS === 'ios' && imageFileExts.some((x) => mimeType.includes(x)))
        ) {
          await MediaLibrary.saveToLibraryAsync(savedFile.uri);
          return { error: '', showMessage: true, success: true };
        }
        if (Platform.OS === 'ios') {
          const UTI = 'public.item';
          await Sharing.shareAsync(savedFile.uri, { UTI });
          return { error: '', showMessage: false, success: true };
        }
        return { error: '', showMessage: true, success: true };
      } catch (e: any) {
        if (e.code === 'E_UNABLE_TO_SAVE') {
          return downloadFilePDFAndroidElevenOrLess(fileName, savedFile.uri);
        }
        if (e.code === 'ERR_PERMISSIONS') {
          return { error: permissionErrorMessage, showMessage: true, success: false };
        }
        return {
          error: e.message || somethingWentWrongMessage,
          showMessage: true,
          success: false,
        };
      }
    })
    .catch((e) => {
      return { error: e.message || somethingWentWrongMessage, showMessage: true, success: false };
    });
};

export const downloadFileWeb = async ({
  fileName,
  uri,
}: {
  fileName: string;
  uri: string;
}): Promise<{ error: string; file: any; success: boolean }> => {
  return axios(uri, { method: 'GET', responseType: 'blob' } as any)
    .then((response) => {
      const href = URL.createObjectURL(response?.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      return { error: '', file: null, success: true };
    })
    .catch((e) => {
      return { error: e?.message || somethingWentWrongMessage, file: null, success: false };
    });
};
