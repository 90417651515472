import React from 'react';
import { Platform } from 'react-native';

import { NavigationProp } from '@react-navigation/native';
import { RenderProp } from '@ui-kitten/components/devsupport';
import { Divider, Text, TopNavigation, TopNavigationAction } from '@ui-kitten/components/ui';

import { ArrowIosBackIcon } from './icons.component';
import { OrganizationType } from '../graphql-types';
import { RootStackParamList } from '../navigation/app.navigator';
import { LocalUserContext } from '../types';

export const TopNavigationBackToProfileButton = ({
  navigation,
  title,
  alignment,
  accessoryLeft,
  accessoryRight,
  userContext,
}: {
  navigation: NavigationProp<RootStackParamList>;
  title: string;
  alignment?: 'center' | 'start';
  accessoryLeft?: RenderProp<object>;
  accessoryRight?: RenderProp<object>;
  userContext: LocalUserContext | null | undefined;
}) => {
  const onPressLeft = () => {
    if (userContext && userContext.organizationType === OrganizationType.COURIER) {
      navigation.navigate('CourierProfileNavigator', {
        screen: 'CourierProfileScreen',
      });
    } else if (userContext && userContext.organizationType === OrganizationType.AGENCY) {
      navigation.navigate('AgencyProfileNavigator', { screen: 'AgencyProfileScreen' });
    } else if (userContext && userContext.organizationType === OrganizationType.PRIOJET) {
      navigation.navigate('PriojetProfileNavigator', {
        screen: 'PriojetProfileScreen',
      });
    } else {
      navigation.navigate('CommonProfileNavigator', {
        screen: 'CommonSwitchAccountScreen',
      });
    }
  };

  const renderBackAction = (): React.ReactElement => (
    <TopNavigationAction icon={ArrowIosBackIcon} onPress={onPressLeft} />
  );

  return (
    <>
      <TopNavigation
        title={(props) => (
          <Text
            selectable={true}
            {...props}
            style={{
              fontFamily: 'Lato_700Bold',
              fontSize: Platform.OS === 'web' ? 18 : 16,
            }}
          >
            {title.toUpperCase()}
          </Text>
        )}
        accessoryLeft={accessoryLeft || renderBackAction}
        alignment={alignment || 'center'}
        accessoryRight={accessoryRight}
      />
      <Divider />
    </>
  );
};
