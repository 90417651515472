import React from 'react';


import { FontAwesome } from '@expo/vector-icons';
import { useTheme } from '@ui-kitten/components';
import Svg, { Path } from 'react-native-svg';

export const SOCIAL_PROFILE_COLORS = {
  link: '#517fa4',
  snapchat: '#fffc00',
  facebook: '#3b5998',
  quora: '#a82400',
  twitter: '#00aced',
  'google-plus-official': '#dd4b39',
  pinterest: '#cb2027',
  linkedin: '#007bb6',
  youtube: '#bb0000',
  vimeo: '#aad450',
  tumblr: '#32506d',
  instagram: '#517fa4',
  foursquare: '#0072b1',
  wordpress: '#21759b',
  stumbleupon: '#EB4823',
  github: '#000000',
  'github-alt': '#000000',
  twitch: '#6441A5',
  medium: '#02b875',
  soundcloud: '#f50',
  gitlab: '#e14329',
  angellist: '#1c4082',
  codepen: '#000000',
  wechat: '#F7F7F7',
  xing: '#02575C',
  telegram: '#2394DC',
  tiktok: '#100e0e',
};

export type SocialKeysType = keyof typeof SOCIAL_PROFILE_COLORS;

interface SocialIconProps {
  color?: string;
  fill?: string;
  name: SocialKeysType;
  size?: number;
  height?: number;
  width?: number;
}

const TikTok = ({
  fill = '#ffffff',
  height = 22,
  width = 22,
}: {
  fill?: string;
  height?: number;
  width?: number;
}) => (
  <Svg viewBox="0 0 2859 3333" width={width} height={height} fill={fill}>
    <Path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
  </Svg>
);

export const SocialIcon: React.FC<SocialIconProps> = ({ color, name, ...rest }) => {
  const theme = useTheme();

  if (name === 'tiktok') {
    return <TikTok {...rest} />;
  }
  if (name === 'wechat') {
    return (
      <FontAwesome
        color={color || theme['background-alternative-color-1']}
        name={name}
        {...rest}
      />
    );
  }
  return (
    <FontAwesome color={color || SOCIAL_PROFILE_COLORS[name]} name={name} {...rest} />
  );
};
